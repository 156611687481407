import { divIcon } from "leaflet";
import { useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { ImLocation } from "react-icons/im";
import { Marker, Popup, Tooltip, useMapEvents } from "react-leaflet";

export interface positionProps {
  lat: number;
  lng: number;
}

interface DragableMarkerProps {
  canOnClickMove?: boolean;
  position?: positionProps;
  setPosition?: any;
  popUpRenderProps?: () => JSX.Element;
  setNewPositionAction?: (position: positionProps) => void;
  tooltip?: React.ReactNode;
  onClickAction?: (value: any) => void;
  itemValue?: any;
  markerIcon?: React.ReactNode;
}

// 35.72391781007263, 51.33256302889833
const DragableMultiMarker = ({
  position = { lat: 35.72391781007263, lng: 51.33256302889833 },
  setPosition,
  canOnClickMove,
  popUpRenderProps,
  setNewPositionAction,
  tooltip,
  onClickAction,
  itemValue,
  markerIcon,
}: DragableMarkerProps) => {
  const markerRef = useRef<any>(null);

  const eventHandlers = useMemo(
    () => ({
      //handle draggable marker
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition && setPosition("lat", marker.getLatLng().lat);
          setPosition && setPosition("lng", marker.getLatLng().lng);
          setNewPositionAction &&
            setNewPositionAction({
              lat: marker.getLatLng().lat,
              lng: marker.getLatLng().lng,
            });
        }
      },

      click() {
      
        // itemValue.colorId="red"
        onClickAction && onClickAction(itemValue);
      },
    }),
    [setNewPositionAction, setPosition]
  );

  const map = useMapEvents({
    click(e) {
      if (canOnClickMove) {
        setPosition && setPosition("lat", e.latlng.lat);
        setPosition && setPosition("lng", e.latlng.lng);
        setNewPositionAction &&
          setNewPositionAction({ lat: e.latlng.lat, lng: e.latlng.lng });
      }
    },
  });

  const iconMarkup: any = renderToStaticMarkup(
    <>
      {markerIcon ? (
        markerIcon
      ) : (
        <ImLocation
          className="bg-none border-none absolute top-[-10px]   left-[-14px] bottom-0 m-auto z-0"
          size={"40px"}
        />
      )}
    </>
  );
  const customMarkerIcon: any = divIcon({
    html: iconMarkup,
  });


  return (
    <>
      <Marker
        icon={customMarkerIcon}
        // draggable
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      >
        {popUpRenderProps && <Popup>{popUpRenderProps()}</Popup>}
        {tooltip && <Tooltip>{tooltip}</Tooltip>}
      </Marker>
    </>
  );
};

export default DragableMultiMarker;
