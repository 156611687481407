import { Children, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { PropState } from "../../global/Interfaces/Interfaces";
import {
  Card,
  Typography,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { links } from "components/SidebarRoutes";
import { Link } from "react-router-dom";
import { hasPermission, hasPermissionParent } from "utils/HasPermission";
const DrawerMobile = ({ show, setShow }: PropState) => {
  const [open, setOpen] = useState(0);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <Drawer
      style={{ zIndex: "2000" }}
      open={show}
      onClose={() => setShow(!show)}
      direction="right"
    >
      <div>
        <Card className="h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
          <div className="mb-2 p-4">
            <Typography variant="h5" color="blue-gray">
              صفحات
            </Typography>
          </div>
          <ul className="flex justify-start items-start flex-col overflow-auto">
            {Children.toArray(
              links.map((item, index) => {
                const { label, childs } = item;
                return (
                  hasPermissionParent(childs) && (
                    <Accordion open={open === index}>
                      <li className="p-0">
                        <AccordionHeader
                          onClick={() => handleOpen(index)}
                          className="border-b-0 p-3 justify-end flex-row-reverse"
                        >
                          <Typography color="blue-gray" className="font-normal">
                            {label}
                          </Typography>
                        </AccordionHeader>
                      </li>

                      {Children.toArray(
                        item.childs.map(({ to, label, permissions }) => {
                          return (
                            hasPermission(String(permissions)) && (
                              <AccordionBody className="py-1">
                                <ul className="px-3">
                                  <li className="font-bold">
                                    <Link to={to}>{label}</Link>
                                  </li>
                                </ul>
                              </AccordionBody>
                            )
                          );
                        })
                      )}
                    </Accordion>
                  )
                );
              })
            )}
          </ul>
        </Card>
      </div>
    </Drawer>
  );
};

export default DrawerMobile;
