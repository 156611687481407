import { createSlice } from "@reduxjs/toolkit";

import { StateData } from "./state-model";

const initialState: StateData = {
  filter: {
    isActive: true,
  },
  selectedTable: [],
  selectedRow: [],
};

const ConsignmentReducer = createSlice({
  initialState: initialState,
  name: "consignment",
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    selectedTableAction: (state, { payload }) => {
      const convertData=payload?.map(item=>({...item,operation:''}))
      const premiere = convertData

      const check = premiere.filter((item) =>
        [4, 21, 24, 23].includes(item.statusId)
      );
      const selectedCards = [...state.selectedTable, ...check];
      if (check.length !== 0) {
        //@ts-ignore
        const jsonObject = selectedCards.map(JSON.stringify);

        const uniqueSet = new Set(jsonObject);
        //@ts-ignore
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return { ...state, selectedTable: uniqueArray };
      }
    },
    selectedRowAction: (state, { payload }) => {
      state.selectedRow = payload;
    },
    deleteSelected: (state, { payload }) => {
      const deselected = state.selectedTable.filter((item) => {
        return item?.id !== payload;
      });

      state.selectedTable = deselected;
    },
    deleteAllSelected: () => ({ ...initialState }),
    deselectSelected: (state, { payload }) => {
      const convertData=payload?.map(item=>({...item,operation:''}))
      const payloadData = structuredClone(convertData);
      const selectedCards = [...state.selectedTable];

      const ids = new Set(payloadData.map(({ id }) => id));

      const selected = selectedCards.filter(({ id }) => !ids.has(id));

      state.selectedTable = selected;
    },
  },
});
export const {
  setFilter,
  selectedTableAction,
  deleteSelected,
  deleteAllSelected,
  deselectSelected,
  selectedRowAction,
} = ConsignmentReducer.actions;
export default ConsignmentReducer.reducer;
