export const ConsignmentCardBody = ({
  item,
  hasAddress = true,
  hasHr = true,
  hasConsignment = true,
}) => {
  return (
    <>
      <div className="grid2  mt-6">
        {hasConsignment && (
          <span className="flex-start-center gap-1 text-white">
            <span>شماره مرسوله </span>
            <span> {item.cprNumber}</span>
          </span>
        )}

        <span className="flex-start-center gap-1 text-white">
          <span> گیرنده : </span>
          <span>{item.customerName}</span>
        </span>
        <span className="flex-start-center gap-1 text-white">
          <span>شماره تماس : </span>
          <span>{item.customerPhoneNumber}</span>
        </span>
        <span className="flex-start-center gap-1 text-white">
          <span> وزن : </span>
          <span>{item.weight}</span>
        </span>
        <span className="flex-start-center gap-1 text-white">
          <span> حجم : </span>
          <span>{item.volume}</span>
        </span>
        {hasAddress && (
          <span className="flex-start-center gap-1 col-span-2 flex-wrap text-white">
            <span className="min-w-[3rem]"> آدرس : </span>
            <span>{item.address}</span>
          </span>
        )}
      </div>
      {hasHr && <hr className="my-6" />}
    </>
  );
};
