import DeleteOperation from "components/tableOperation/DeleteOperation";
import {
  checkPlaningCondition,
  convertResponse,
} from "pages/optimePanel/utils";
import { Children } from "react";
import {  MdDeleteForever } from "react-icons/md";
import { useLocation, useParams } from "react-router-dom";

import { getAPI } from "services/CRUDServices";
import { deleteUrls, getUrls } from "services/api.enums";
import { ReactComponent as UndoDelete } from "../../../../../assets/icons/undoTtrash.svg";
import { toast } from "react-toastify";
interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
}

function InfoDeletedPins({ optimeData, setOptimeData }: MapOperationProps) {
  const { id } = useParams();
  const { state } = useLocation();
  function deletePin(pin) {
    getAPI(deleteUrls.undeleteByPudoPlaning + "/" + pin.planingId).then(
      (res) => {
        refetch();
      }
    );
  }

  function refetch() {
    toast.success("عملیات با موفقیت انجام شد", { position: "top-left" });
    getAPI(getUrls.planingConsignment + id).then((res) => {
      const convert = convertResponse(res);
      setOptimeData(convert);
    });
  }

  return (
    <>
      {Children.toArray(
        optimeData?.deleteConsignmentList.map((item) => (
          <div className="mt-4">
            {checkPlaningCondition(state) && (
              <div className="flex justify-end items-center my-4">
                <button
                  className=" border-none	text-[14px]  w-[20px] h-[18px]"
                  onClick={() => deletePin(item)}
                >
                  <UndoDelete title="" className="w-full h-full mt-[2px]" />
                </button>

                <DeleteOperation
                  itemId={item.planingId}
                  title={"حذف مرسوله"}
                  handleDeleteActionNewData={refetch}
                  route={deleteUrls.deletefromPlaning}
                  icon={
                    <MdDeleteForever
                      title="حذف مرسوله"
                      color="white"
                      size={25}
                    />
                  }
                />
              </div>
            )}

            <div className="grid2 ">
              <div className="flex-start-center gap-1 text-white">
                <span>شماره مرسوله </span>
                <span> {item.cprNumber}</span>
              </div>
              <span className="flex-start-center gap-1 text-white">
                <span> گیرنده : </span>
                <span>{item.customerName}</span>
              </span>
              <span className="flex-start-center gap-1 text-white">
                <span>شماره تماس : </span>
                <span>{item.phoneNumber}</span>
              </span>
              <span className="flex-start-center gap-1 text-white">
                <span> وزن : </span>
                <span>{item.weight}</span>
              </span>
              <span className="flex-start-center gap-1 text-white">
                <span> حجم : </span>
                <span>{item.volume}</span>
              </span>

              <span className="flex-start-center gap-1 col-span-2 text-white flex-wrap">
                <span className="min-w-[3rem]"> آدرس : </span>
                <span>{item.address}</span>
              </span>
            </div>
            <hr className="my-6" />
          </div>
        ))
      )}
    </>
  );
}

export default InfoDeletedPins;
