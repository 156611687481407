import { createSlice } from "@reduxjs/toolkit";
import { StateData } from "./state-model";

const initialState: StateData = {
  CurrentHub: {},
};
const CurrentHubReducer = createSlice({
  initialState: initialState,
  name: "currentHubInfo",
  reducers: {
    setCurrentHub: (state, action) => {
      state.CurrentHub = action.payload;
    },
  },
});
export const { setCurrentHub } = CurrentHubReducer.actions;
export default CurrentHubReducer.reducer;
