import { createSlice } from "@reduxjs/toolkit";
import { StateData } from "./state-model";

const initialState: StateData = {
  isSuperAdmin: undefined,
  perms: [],
};
const UserInfoReducer = createSlice({
  initialState: initialState,
  name: "userInfo",
  reducers: {
    getUserInfo: (state, action) => ({ ...state, ...action.payload }),
  },
});
export const { getUserInfo } = UserInfoReducer.actions;
export default UserInfoReducer.reducer;
