import DragableMultiMarker from "components/map/DragableMultiMarker";
import SimpleButton from "global/SimpleButton/SimpleButton";
import React, {
  Children,
  useEffect,
  useRef,
  useState
} from "react";
import { BsGeoFill } from "react-icons/bs";
import { FeatureGroup, Polygon, Popup, Tooltip } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ActionDeletedPolygon,
  ActionPolygon
} from "redux/optime/optime";
import { convertDriverData, getPinFromZone } from "../utils";
import AssignMdlTrip from "./AssignMdlTripForm";
import RenderPins from "./render-Assigned-pins";
interface PolygonProps {
  assignPolygon: any;

  enableEditHandler?: () => void;
  setEditCurrentPolygon?: React.Dispatch<any>;
  setCreatedPolygon?: any;
  setSelectedPins?: any;
  selectedPolygons: any;
  setSelectedPolygons: any;
  setAssignModal: React.Dispatch<any>;
  setOptimeData: any;
}

function AssignedMap({
  assignPolygon,
  setEditCurrentPolygon,
  setCreatedPolygon,
  setSelectedPins,
  selectedPolygons,
  setSelectedPolygons,
  setAssignModal,
  setOptimeData,
}: PolygonProps) {
  // const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const LeafletControllerRef = useRef([]);
  const featureGroupRef = useRef(null);

  const { editZone, multiSelect, deletedPoly } = useSelector(
    (state: any) => state.optimeModal
  );
  const [getIndex, setIndex] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (editZone) {
      handelEditPoly();
      setShowPopUp(true);
    } else {
      setShowPopUp(false);
      cancelAllEdit();
    }
  }, [editZone, getIndex]);

  useEffect(() => {
    assignPolygon?.polygons.forEach((element) => {
      element.selected = false;
    });
    setSelectedPolygons([]);
    setRender(!render);
  }, [multiSelect, editZone]);

  useEffect(() => {
    if (deletedPoly !== -1) {
      LeafletControllerRef.current.splice(deletedPoly, 1);
    }
    dispatch(ActionDeletedPolygon(-1));
    cancelAllEdit();
  }, [assignPolygon]);
  function polygonStatus(index) {
    LeafletControllerRef.current[
      index
    ]._toolbars.edit._modes?.edit?.handler?.enable();
  }

  function onSelectPolygon(polygon, index) {
    setIndex(index);
    if (editZone) {
      polygonStatus(index);
      setShowPopUp(true);
    }
    if (!editZone) {
      const temp = assignPolygon;
      const findPoly = temp.polygons.findIndex(
        (item: any) => item.polygonId === polygon.polygonId
      );

      if (!multiSelect) {
        temp.polygons.forEach((element) => {
          element.selected = false;
        });
      }

      temp.polygons[findPoly].selected = !temp?.polygons[findPoly]?.selected;
      const find = selectedPolygons.find(
        (item) => item.polygonId === temp.polygons[findPoly].polygonId
      );
      if (temp.polygons[findPoly].selected && !find) {
        setSelectedPolygons((prev) => [...prev, temp.polygons[findPoly]]);
      } else if (temp.polygons[findPoly].selected === false) {
        const finPoly = selectedPolygons.findIndex(
          (item: any) => item.polygonId === temp.polygons[findPoly].polygonId
        );

        selectedPolygons.splice(finPoly, 1);
      }
      setRender(!render);
      if (!editZone && !multiSelect) {
        dispatch(ActionPolygon(polygon));
      }
    }
  }
  const onMountedRect = (e: any) => {
    LeafletControllerRef.current.push(e);
  };
  const onSaveZoneHandler = (item) => {
    // @ts-ignore
    const selectedZone = structuredClone(item);
    let createdZone;
    const layers: any = Object.values(
      LeafletControllerRef.current[getIndex]._toolbars.edit.options.featureGroup
        ._layers
    )[0];
    const updatedZone = [
      layers?._latlngs[0].map((item: any) => {
        return [item.lat, item.lng];
      }),
    ];

    const flattenArr = updatedZone.flat();
    flattenArr.push(flattenArr[0]);
    createdZone = flattenArr;

    const pins = getPinFromZone(
      createdZone,
      assignPolygon.consignmentList,
      selectedZone
    );

    selectedZone.consignmnetList = pins;
    setCreatedPolygon(createdZone);
    setSelectedPins(pins);
    setEditCurrentPolygon({
      selectedZone: item,
      updatedZone: createdZone,
      mapAction: LeafletControllerRef?.current[getIndex],
    });
    setShowPopUp(false);
    if (pins.length === 0) {
      toast.warn("محدوده انتخاب شده هیچ مرسوله ای ندارد");
      cancelAllEdit();
      return;
    }
    setAssignModal((prev: boolean) => !prev);
  };

  const handelEditPoly = () => {};

  const handelCancelEdit = (index) => {
    LeafletControllerRef?.current[
      index
    ]?._toolbars?.edit?._modes.edit?.handler?.enable();

    LeafletControllerRef?.current[
      index
    ]?._toolbars?.edit?._modes?.edit?.handler?.revertLayers();
    LeafletControllerRef?.current[
      index
    ]?._toolbars?.edit?._modes?.edit?.handler?.disable();
  };

  function cancelAllEdit() {
    LeafletControllerRef?.current.forEach((element) => {
      element._toolbars?.edit?._modes?.edit?.handler?.enable();
    });
    LeafletControllerRef?.current.forEach((element) => {
      element._toolbars?.edit?._modes?.edit?.handler?.revertLayers();
    });
    LeafletControllerRef?.current.forEach((element) => {
      element._toolbars?.edit?._modes?.edit?.handler?.disable();
    });

    // LeafletControllerRef?.current.forEach((element) => {
    //   element._toolbars?.edit?._modes?.edit?.handler?.revertLayers();
    // });
  }

  // useMapEvents({

  //   click(e) {
  //     // dispatch(ActionModals(false))
  //       // if(selectedPolygons.length ===0){

  //       // }

  //   },
  // });

  return (
    <>
      {selectedPolygons.length !== 0 && multiSelect && (
        <div className=" fixed top-6 left-1/2 z-[9999]">
          <SimpleButton
            type="submit"
            text="ایجاد سفر مبادله"
            className="full-tomato-btn"
            handelClick={() => setOpen(true)}
          />
        </div>
      )}

      {Children.toArray(
        assignPolygon &&
          assignPolygon.polygons?.length !== 0 &&
          assignPolygon.polygons.map((item: any, index: number) => (
            <FeatureGroup ref={featureGroupRef}>
              <EditControl
                onMounted={onMountedRect as any}
                position="topright"
                draw={{
                  rectangle: false,
                  polyline: false,
                  marker: false,
                  circle: false,
                  circlemarker: false,
                  polygon: false,
                }}
              />

              <Polygon
                eventHandlers={{ click: () => onSelectPolygon(item, index) }}
                positions={item.polygonCordinate}
                fillColor={item.color}
                pathOptions={{
                  color: "black",
                  fillColor: item.selected ? "red" : item.color,
                }}
              >
                <Tooltip sticky>
                  {item.pudoVehicle &&
                    item.pudoVehicle.length !== 0 &&
                    convertDriverData(item.pudoVehicle[0])?.text}
                </Tooltip>
              </Polygon>
              {showPopUp && (
                <Popup>
                  <div className="flex flex-col gap-4">
                    <div className="flex w-full justify-evenly  gap-2">
                      <button
                        className="btn !px-[2rem]  full-gray-btn shadow-lg w-[40px]"
                        onClick={() => {
                          handelCancelEdit(index);
                          setShowPopUp(false);
                        }}
                      >
                        لغو
                      </button>
                      <button
                        className="btn w-[40px] !px-[2rem] shadow-lg  full-tomato-btn "
                        onClick={() => onSaveZoneHandler(item)}
                      >
                        ذخیره
                      </button>
                    </div>
                  </div>
                </Popup>
              )}
            </FeatureGroup>
          ))
      )}
      <RenderPins pins={assignPolygon} />
      {assignPolygon &&
        assignPolygon.station?.length !== 0 &&
        assignPolygon.station.map((pin: any, index: number) => (
          <DragableMultiMarker
            tooltip={pin.name + " " + pin.addressLine}
            position={{ lat: pin.locLate, lng: pin.locLong }}
            markerIcon={
              <BsGeoFill
                className={`bg-none  border-none absolute top-[-10px]   left-[-14px] bottom-0 m-auto z-0`}
                size={"40px"}
                style={{ fill: `black` }}
              />
            }
          />
        ))}

      <AssignMdlTrip
        open={open}
        setOpen={setOpen}
        polygons={selectedPolygons}
        setOptimeData={setOptimeData}
      />
    </>
  );
}

export default AssignedMap;
