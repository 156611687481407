import React from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
// import "leaflet.offline";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useLocation } from "react-router-dom";

interface MapComponentProps {
  children?: React.ReactNode;
  zoom?: number;
  setZoom?: (value: number) => void;
  isChildren?:boolean
}
const MapComponent = React.forwardRef<any, MapComponentProps>(
  ({ children, zoom = 6, setZoom,isChildren=true }, refMap) => {
    function MyComponent() {
      const mapEvents = useMapEvents({
        zoomend: () => {
          setZoom && setZoom(mapEvents.getZoom());
        },
      });
      return null;
    }
    const { state } = useLocation();
    return (
      <div className="w-full h-full" dir="ltr">
        <MapContainer
          center={state ?[state.city.lat,state.city.lng]: [35.7219,51.3347]}
          ref={refMap}
          zoom={zoom}
        >
          <MyComponent />
          <TileLayer url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png" />
          {children}
        </MapContainer>
      </div>
    );
  }
);

export default MapComponent;
