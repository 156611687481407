import {createSlice,createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit"
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";


export const productData: AsyncThunk<{}, {}, {}>=createAsyncThunk('productlists',async(body:any)=>{
    const data = await filterTableDataAPI(filterUrls.productDefine, body.pageNumber,
        {...body,     
         pageNumber: undefined,
         pageSize: undefined,}
         , true);
    
    return data.data;
})

const initialState:any= {
    productLists:[],
    fetchpost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{}    
}

const ProductDefineList = createSlice({
    initialState: initialState,
    name: "productlists",
    reducers: {
        clearProductDefine: (state) => {
            state.productLists = [];
          },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
    },
    extraReducers: {
        [productData.fulfilled as any]: (state, action) => {
            state.productLists = action.payload.payload;
            state.fetchPost = false;
        },
        [productData.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [productData.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter,clearProductDefine } = ProductDefineList.actions;
export default ProductDefineList.reducer