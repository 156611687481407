import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";
import { StateData } from "./state-model";

interface PersonnelDataBody {
  personelCode: string;
  name: string;
  nationalCode: string;
  mobile: string;
  email: string;
  username: string;
  isActive: boolean;
  pageNumber: number;
  hublist?: any;
  selectHub: any;
}
export const PersonnelData: AsyncThunk<{}, {}, {}> = createAsyncThunk("post", async (body: PersonnelDataBody) => {
  let { data } = await filterTableDataAPI(
    filterUrls.employee,
    body.pageNumber,
    {
      ...body,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );

  return data;
});

const initialState: StateData = {
  personnelList: [],
  fetchPost: false,
  errorMessage: null,
  isUpdating: false,
};

const PersonnelList = createSlice({
  initialState: initialState,
  name: "personnelList",
  reducers: {
    clearPersonnel: (state) => {
      state.personnelList = [];
    },
    updating: (state, action) => {
      state.isUpdating = action?.payload;
    }
  },
  extraReducers: {
    [PersonnelData.fulfilled as any]: (state, action) => {
      state.personnelList = action?.payload?.payload;
      state.fetchPost = false;
      state.errorMessage = null;
      state.isUpdating = false;
    },
    [PersonnelData.pending as any]: (state) => {
      state.fetchPost = true;
      state.personnelList = [];
      state.errorMessage = null;
      state.isUpdating = false;
    },
    [PersonnelData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
      state.personnelList = [];
      state.isUpdating = false;
    },
  },
});
export const { clearPersonnel, updating } = PersonnelList.actions;
export default PersonnelList.reducer;
