import { useEffect } from "react";
import { useQuery } from "react-query";
import { getConsignmentStatus } from "services/options";

export default function useGetConsignmentStatus() {
  const token = localStorage.getItem("token");
  const { data, isLoading } = useQuery(
    ["core-api-consignment-status", token],
    getConsignmentStatus
  );
  useEffect(() => {
    if (data && !isLoading) {
      localStorage.setItem(
        "coreApiConsignmentStatus",
        JSON.stringify(data.payload)
      );
    }
  }, [data, isLoading]);
}
