
import { lazy } from "react";

const Hub = lazy(() => import("pages/Hub"));
const OperationalScopeManagement = lazy(
  () => import("pages/map/zoneMap/OperationalScopeManagement")
);
const DeterminingAreas = lazy(
  () => import("pages/map/hubZoneOperation/DeterminingAreas")
);
const Planing = lazy(() => import("pages/Planing"));
const UnPlanedConsignemnt=lazy(()=>import("pages/unplaned-consignments")) 
export const childs: Array<ISideBarChild> = [
  {
    code: "1001",
    to: "/hub",
    component: <Hub />,
    label: "هاب",
    permissions: "view_hub",
  },
  {
    code: "100103",
    to: "/basic-information/map-zone",
    component: <OperationalScopeManagement />,
    label: "تعریف محدوده عملیاتی",
    permissions: "view_map_zone",
  },
  {
    code: "100104",
    to: "/basic-information/map-zone-operation",
    component: <DeterminingAreas />,
    label: "تعیین مناطق توزیع  / جمع آوری",
    permissions: "view_zone operation",
  },

  {
    code: "100105",
    to: "/planing",
    component: <Planing />,
    label: "برنامه ریزی",
    permissions: "view_planning",
  },
  {
    code: "",
    to: "/unplaned-consignments",
    component: <UnPlanedConsignemnt />,
    label: "تخصیص مرسوله",
    permissions: "view_assign_consignment_to_plan",
  },
];
