import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNewConsignments, setOrder } from "redux/OrderData/OrderData";
import { addOrderData } from "redux/order-management-new";
import { getAPI } from "services/CRUDServices";
import { getUrls } from "services/api.enums";
import { Collector } from "tools/functions/Methods";
import { fetchJsonWithKey } from "tools/helper/ConcatAPIs";
export const useGetMoreConsignmentInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { orderManagementData } = useSelector((state: any) => state.orderData);
  const fetchJsonWithKeys = async (url: string, item: any, key: string) =>
    url.includes("undefined") || url.includes("null")
      ? item
      : await getAPI(url)
          .then((res: any) => {
            return res.data.payload
              ? { ...item, [key]: res.data.payload }
              : { ...item, [key]: res.data };
          })
          .catch(() => {
            return item;
          });

  const handleGetData = async (
    id: number,
    order?: any,
    isNavigate?: boolean
  ) => {
    let res: any = await getAPI(`${getUrls.findByOrder}${id}`);

    let consignments: any = await Promise.all(
      res.data.payload.map((item: any) =>
        fetchJsonWithKeys(
          `${
            item?.receiverCustomerId ? getUrls.cusotmer : getUrls.findByProspect
          }/${
            item?.receiverCustomerId
              ? item?.receiverCustomerId
              : item?.receiverProspectId
          }`,
          item,
          "receiverDetails"
        )
      )
    ).then((values) => values);

    consignments = await Promise.all(
      consignments.map((item: any) =>
        fetchJsonWithKey(
          `${item?.destinationHubId ? getUrls.hub : getUrls.addressDetail}/${
            item?.destinationHubId
              ? item?.destinationHubId
              : item?.receiverAddressId
          }`,
          item,
          "recieverAddress"
        )
      )
    ).then((values) => values);
    // consignments = await Promise.all(
    //   consignments.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.findByConsignmentAllService}/${item?.id}`,
    //       item,
    //       "suggestionServicesContainer"
    //     )
    //   )
    // ).then((values) => values);

    consignments = await Promise.all(
      consignments.map((item: any) =>
        fetchJsonWithKey(
          `${getUrls.contentOfConsignment}${item?.contentId}`,
          item,
          "content"
        )
      )
    ).then((values) =>
      values.map((item: any) => ({
        ...item,
        serialNumber: item?.cprNumber,
        packingCost: item?.packingCost,
        consignmentId: item?.id,
        trackingCode: id,
        toCountryDevisionId: item?.recieverAddress?.selectCity?.id,
        toCountryDevisionName: `${item?.recieverAddress?.selectRegion?.text} - ${item?.recieverAddress?.selectCity?.text}`,
        receiverAddressId: item?.destinationHubId
          ? null
          : item?.recieverAddress?.id,
        deliveryAddressId: item?.destinationHubId
          ? null
          : item?.recieverAddress?.id,
        postBack: item.postBack,
        receiverAddressName: item?.recieverAddress?.address || null,
        receiverAddressTitle: item?.recieverAddress?.name || null,
        receiverAddressPostalCode: item?.recieverAddress?.postalCode || null,
        receiverAddressDetail: item?.recieverAddress?.addressDetail || null,
        reciverPhone:
          Array.isArray(item?.recieverAddress?.telephones) &&
          item?.recieverAddress?.telephones[0]?.telNumber,
        lat: item?.destinationHubId
          ? item?.recieverAddress?.locLate
          : item?.recieverAddress?.latitude,
        lng: item?.destinationHubId
          ? item?.recieverAddress?.locLong
          : item?.recieverAddress?.longtitude,

        deliveryHubId: item?.destinationHubId
          ? item?.recieverAddress?.id
          : null,
        deliveryHubName: item?.destinationHubId
          ? item?.recieverAddress?.name
          : null,
        deliveryHubAddress: item?.destinationHubId
          ? item?.recieverAddress?.addressLine
          : null,
        selectContentTypeId: item?.selectConsignmentType?.id,
        selectContentTypeName: item?.selectConsignmentType?.text,
        selectContent: item?.contentTypeId,
        content: { ...item?.content, text: item?.content?.name },

        receiverCustomerId: item?.receiverCustomerId,
        receiverProspectId: item?.receiverProspectId,
        receiverCustomerName: item?.receiverDetails?.name,
        receiverNationalCode: item?.receiverDetails?.nationalCode,
        receiverNationalId: item?.receiverDetails?.nationalId,
        receiverPhoneId:
          item?.receiverDetails?.telephones?.length > 0 &&
          item?.receiverDetails?.telephones.at(-1).id,
        receiverPhoneNumber:
          item?.receiverDetails?.telephones?.length > 0 &&
          item?.receiverDetails?.telephones.at(-1).telNumber,
        receiverCustomerType:
          item?.receiverDetails?.selectType?.id ||
          item?.receiverDetails?.selectCustomerType?.id,
        receiverCustomerTypeName: item?.receiverDetails?.selectType?.text,
        ////////////

        receiver: {
          name: item?.receiverDetails?.name,
          mobile:
            item?.receiverDetails?.telephones?.length > 0 &&
            item?.receiverDetails?.telephones.at(-1).telNumber,
          receiverCityId: item?.recieverAddress?.selectCity,
          address: item?.recieverAddress?.address,
        },

        contentType: item.contentTypeId,
        width: item.declarativeWidth,
        height: item.declarativeHeight,
        length: item.declarativeLength,
        weight: item.declarativeWeight,

        consignmentType: item.selectConsignmentType,

        cod: item.needToReciveCostOfGoods,
        pack: false,
        cprNumber: item.cprNumber,
        needToPack: item.needToPack,

        insurance: {
          id: 0,
          text: "",
        },
        costOfGoods: item.costOfGoods,
        value: item.declarativeValue,
        ////////////////
        typeOfPackage: item?.typeOfPackage,

        price: Collector(item.consignemtService, "price") || undefined,
        ServiceName: item?.consignemtService?.find((i: any) => i.type?.id === 0)
          ?.serviceName,
        ServiceId: item?.consignemtService?.find((i: any) => i.type?.id === 0)
          ?.serviceId,

        suggestionServices:
          item?.consignemtService.length > 0
            ? {
                id: item?.consignemtService?.find((i: any) => i.type?.id === 0)
                  .serviceId,
                name: item?.consignemtService?.find(
                  (i: any) => i.type?.id === 0
                ).serviceName,
                price: item?.consignemtService?.find(
                  (i: any) => i.type?.id === 0
                ).price,
                serviceType: item?.consignemtService?.find(
                  (i: any) => i.type?.id === 0
                ).type.id,
              }
            : null,
        supplementary: item?.consignemtService
          ?.filter((i: any) => i.type?.id !== 0)
          .map((item: any) => ({
            id: item.serviceId,
            name: item.serviceName,
            price: item.price,
            serviceType: item.type.id,
          })),
        // suggestionServicesContainer: item?.consignemtService.map(
        //   (item: any) => ({
        //     id: item.id,
        //     name: item.name,
        //     price: item.price,
        //     serviceType: item.serviceType,
        //   })
        // ),
      }))
    );

    //get packing cost for each consignment this section is add for print

    dispatch(setNewConsignments(consignments));
    if (isNavigate) {
      dispatch(
        addOrderData({
          name: order?.customer,
          nationalCode: order?.nationalCode,
          nationalId: order?.nationalId,
          mobile: order?.customerPhone,
          orderId: order?.id || id,
          openModal: false,
          easyOrder: order?.easyOrder,
          senderCustomerId: order?.selectCustomer?.id,
        })
      );
      dispatch(
        setOrder({
          orderId: order?.id,
          senderCustomerId: order?.selectCustomer?.id,
          inVoiceStatusId: order.inVoiceStatusId,
        })
      );
      isNavigate && navigate("/add-fast-order");
    }

    // dispatch(setFilter({ ...filter, orderDate: values.orderDate }));
    // if (
    //   order?.orderRegistrationHub &&
    //   order?.orderRegistrationHub?.id === order?.senderHubId
    // ) {
    //   navigate("/add-hub-order");
    // } else navigate("/add-order");
  };

  return { handleGetData };
};
