import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePayment } from "./utils/usePayment";
import CountdownTimer from "components/count-down";

const PaymentModal = ({ isOpen, setIsOpen }) => {
  const { orderData } = useSelector((state: any) => state.Order);

  const {
    handleClick,
    setIsLoading,
    isLoading,
    isLoadingPos,
    setIsLoadingPos,
    time,
  } = usePayment();
  const [startCountDown, setStartCountDown] = useState(false);
  useEffect(() => {
    !isOpen && setIsLoading(false);
    setIsLoadingPos(false);
  }, [isOpen]);

  return (
    <Modal visible={isOpen} setVisible={setIsOpen} title="پرداخت">
      <div className="text-base flex justify-between">
        <span>مبلغ پرداختی: </span>
        <span className="font-semibold">
          {orderData?.tashimPrice?.totalAmount
            // orderData?.sumOfDebtBalance + orderData?.sumOfPackage
            ?.toLocaleString() || "---"}{" "}
          ریال
        </span>
      </div>
      <div className="flex gap-3">
        <SimpleButton
          text=" پرداخت نقدی"
          loading={isLoading}
          className="w-36 full-tomato-btn mt-5"
          handelClick={() => handleClick(false, orderData)}
          disabled={isLoadingPos || isLoading}
        />
        <SimpleButton
          text=" پرداخت کارتخوان"
          loading={isLoadingPos}
          className="w-36 full-tomato-btn mt-5"
          handelClick={() => {
            handleClick(true, orderData);
            setStartCountDown(true);
          }}
          disabled={isLoadingPos || isLoading}
        />
      </div>

      {startCountDown && <CountdownTimer initialTime={time} />}
    </Modal>
  );
};

export default PaymentModal;
