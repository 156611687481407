import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addEditDataAPI, getAPI } from "services/CRUDServices";

export function usePayment() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPos, setIsLoadingPos] = useState(false);
  const { currenthub, userinfo } = useSelector((state: any) => state.userInfo);
  const [time, setTime] = useState(0);
  const { orderManagementData } = useSelector((state: any) => state.orderData);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleClick = (isPos?: boolean, orderData?: any) => {
    if (isPos) {
      setIsLoadingPos(true);
      setTime(60);
      addEditDataAPI(
        `/consignment-api/order/${
          orderData?.orderId || orderManagementData?.orderId
        }/delivery/payment`,
        "post",
        {
          userId: userinfo?.id,
          hubId: currenthub?.id,
          sharingDetails: orderData?.tashimPrice?.requestList,
        }
        // {
        //   sharingDetails: [
        //     {
        //       accountId: 23,
        //       amount: 20000,
        //       percentage: 100,
        //       payerId: 23,
        //       shareId: 23,
        //       accountNumber: "IR730130100000000374341709",
        //       type: "POST",
        //       consignmentId: null,
        //       accountNumberId: 341709,
        //     },
        //   ],
        // }

        // `consignment-api/flow/hubInvoiceComplete?orderId=${
        //   orderData?.orderId
        // }&hubId=${currenthub?.id}&amount=${
        //   orderData?.sumOfDebtBalance + orderData?.sumOfPackage
        // }&customerId=${orderData?.senderCustomerId}`
      )
        .then((res) => {
          setTime(0);
          toast.success("پرداخت با موفقیت انجام شد");
          state?.orderReceipt
            ? navigate("/order-receipt")
            : navigate("/order-management");
        })
        .catch(() => {
          setIsLoadingPos(false);
          setTime(0);
        });
      // axios
      //   .post("http://192.168.88.243:8000/bpmpospc/service", {
      //     ServiceCode: "4",

      //     TotalAmount: `${orderData?.sumOfDebtBalance}`,
      //     RequestList: orderData?.tashimPrice?.requestList?.map((item) => ({
      //       AccountID: item?.accountNumberId,
      //       Amount: item?.amount,
      //       PayerID: "111",
      //       // PayerID:orderManagementData?.senderCustomerId || orderData.senderCustomerId,
      //     })),
      //     PrintDetail: "3",
      //     PcID: "",
      //   })
      //   .then((res) => {

      //     if (res?.data?.ReturnCode === 100) {
      //       const { TraceNumber, ReturnCode } = res?.data;
      //       addEditDataAPI(
      //         `/consignment-api/flow/invoiceCompleteFromBank/${orderData?.orderId || orderManagementData?.orderId}`,
      //         "post",
      //         {
      //           requestList: orderData?.tashimPrice?.requestList,
      //           amount: orderData?.sumOfDebtBalance,
      //           bankStatus: ReturnCode,
      //           bankReference: TraceNumber,
      //           bankInfo: JSON.stringify(res?.data),
      //         }

      //         // `consignment-api/flow/hubInvoiceComplete?orderId=${
      //         //   orderData?.orderId
      //         // }&hubId=${currenthub?.id}&amount=${
      //         //   orderData?.sumOfDebtBalance + orderData?.sumOfPackage
      //         // }&customerId=${orderData?.senderCustomerId}`
      //       )
      //         .then(() => {
      //           toast.success("پرداخت با موفقیت انجام شد");
      //           navigate("/order-management");
      //           setIsLoading(false);

      //         })
      //         .catch(() => setIsLoadingPos(false));
      //     } else {
      //       setIsLoadingPos(false);
      //       toast.error("مشکلی در پرداخت بوجود آمده است");
      //     }
      //   })
      //   .catch(() => setIsLoadingPos(false));
    } else {
      setIsLoading(true);
      let apiUrl = `consignment-api/flow/hubInvoiceComplete?orderId=${
        orderData?.orderId || orderManagementData?.orderId
      }&hubId=${currenthub?.id}&amount=${
        orderData?.sumOfDebtBalance
        // orderData?.sumOfDebtBalance + orderData?.sumOfPackage
      }&customerId=${
        orderData?.senderCustomerId || orderManagementData?.senderCustomerId
      }`;

      if(state?.receiptId){
          apiUrl+= `&receiptId=${state.receiptId}`;
      }
      getAPI(apiUrl)
        .then(() => {
          toast.success("پرداخت با موفقیت انجام شد");
          state?.orderReceipt
            ? navigate("/order-receipt")
            : navigate("/order-management");
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return {
    isLoading,
    isLoadingPos,
    setIsLoading,
    setIsLoadingPos,
    handleClick,
    time,
  };
}
