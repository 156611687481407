import { FC } from "react";
import Select, { components } from "react-select";

interface InputSelectProps {
  label?: string;
  name?: string;
  values?: any;
  options?: any;
  handleChange?: any;
  error?: any;
  important?: boolean;
  wrapperClassName?: string;
  isDisabled?: boolean;
  positionFixed?: boolean;
  labelClassName?: string;
  closeMenuOnSelect?: boolean;
}
const MultiSelectAll: FC<InputSelectProps> = ({
  label,
  name,
  values,
  options,
  handleChange,
  error,
  important,
  isDisabled,
  wrapperClassName,
  positionFixed,
  labelClassName,
  closeMenuOnSelect = true,
}): JSX.Element => {
  const style = {
    control: (base: any, state: any) => ({
      ...base,
      border: state.isFocused
        ? error
          ? "1px solid  #d32f2f"
          : " 1px solid #ababab"
        : error
        ? "1px solid  #d32f2f"
        : " 1px solid #ababab",
      borderRadius: state.isFocused ? "0.5rem" : "0.5rem",
      "&:hover": {
        border: state.isFocused
          ? error
            ? "1px solid  #d32f2f"
            : " 1px solid #ababab"
          : error
          ? "1px solid  #d32f2f"
          : " 1px solid #ababab",
      },
      // This line disable the blue border
      boxShadow: "none",
      width: "100%",
      zIndex: 0,
      maxHeight: "10rem",
      overflow: "auto",
    }),
    option: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: state.isSelected
          ? "#FFEAE9"
          : state.isFocused
          ? "#F9FAFC"
          : "",
        color: "rgb(117 ,117 ,117)",
      };
    },
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        zIndex: 0,
      };
    },
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
  return (
    <div
      className={`relative h-fit custom-multi-select Max-md:w-full wrapperResponsive  ${wrapperClassName}`}
    >
      <label
        className={`${labelClassName} absolute top-[-16px] right-5 bg-white z-10  px-2  text-sm ${
          error ? "text-red" : "text-darkGray"
        }`}
      >
        {label}{" "}
        <span className="text-tomato font-extrabold text-lg h-4">
          {important ? "*" : " "}
        </span>
      </label>
      <Select
        components={{ Option, MultiValue }}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPosition={positionFixed ? "fixed" : "absolute"}
        isMulti
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        isLoading={options.length > 0 ? false : true}
        value={
          values
            ? values.map((item: any) => {
                return { value: item.id, label: item.text };
              })
            : []
        }
        onChange={(option) => {
          const isSelectAll = option.some((value) => value.value === "all");
          if (isSelectAll) {
            handleChange(
              name,
              options.map((item: any) => {
                return {
                  id: item.id,
                  text: item.text,
                };
              })
            );
          } else {
            handleChange(
              name,
              option.map((item: any) => {
                return {
                  id: item.value,
                  text: item.label,
                };
              })
            );
          }
        }}
        styles={style}
        options={[
          { label: "انتخاب همه", value: "all" },
          ...options.map((res: any) => {
            return {
              label: res.text,
              value: res.id,
            };
          }),
        ]}
        placeholder=""
        isRtl
        name={name}
        className="inputSelect focus:outline-none  "
      />
      <p className="text-red text-xs pr-3 h-4 mt-1">{error?.id || error}</p>
    </div>
  );
};
MultiSelectAll.defaultProps = {
  wrapperClassName: "w-60",
};
export default MultiSelectAll;
