import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import { filterUrls } from "../../../services/api.enums";
import { filterTableDataAPI } from "../../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";




export const filterBags=createAsyncThunk('filterData',async(body:any)=>{

    var data:any;
    try {
      data = await filterTableDataAPI(filterUrls.bag as any, body?.pageNumber || 1,{
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
      },true);
      } catch (error) {
        ErrorAlert(error?.response?.data?.errors?.message)
      }
    return data.data;
})

const initialState:any= {
    bagsData:[],
    fetchpost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{}    
}

const BagsLists= createSlice({
    initialState: initialState,
    name: "filterData",
    reducers: {
          clearBag: (state) => {
            state.bagsData = [];
          },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
    },
    extraReducers: {
        [filterBags.fulfilled as any]: (state, action) => {
            state.bagsData = action.payload.payload;
            state.fetchPost = false;
        },
        [filterBags.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [filterBags.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter,clearBag } = BagsLists.actions;
export default BagsLists.reducer