import { v4 as uuidv4 } from "uuid";

export const HubColumn = [
  {
    id: "0001",
    isRequire: true,
    Header: "کد هاب",
    accessor: "code",
    key: "code",
    header: "کد هاب",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "نام هاب",
    accessor: "name",
    key: "name",
    header: "نام هاب",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "نوع هاب",
    accessor: "hubType",
    key: "hubType",
    header: "نوع هاب",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "گونه هاب",
    accessor: "category",
    key: "category",
    header: "گونه هاب",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "هاب والد",
    accessor: "parentHub",
    key: "parentHub",
    header: "هاب والد",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "آدرس",
    accessor: "addressLine1",
    key: "addressLine1",
    header: "آدرس",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "شهر",
    accessor: "selectCity",
    key: "selectCity",
    header: "منطقه",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "امکان تحویل مرسوله دارد",
    accessor: "deliver",
    key: "deliver",
    header: "امکان تحویل مرسوله دارد",
  },

  {
    id: uuidv4(),
    isRequire: false,
    Header: "امکان ثبت سفارش",
    accessor: "isPossibleOrderRegistration",
    key: "isPossibleOrderRegistration",
    header: "امکان ثبت سفارش",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "امکان دریافت مرسوله",
    accessor: "isPossibleConsignmentStorage",
    key: "isPossibleConsignmentStorage",
    header: "امکان دریافت مرسوله",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "امکان جمع آوری مرسوله",
    accessor: "isPickupPossible",
    key: "isPickupPossible",
    header: "امکان جمع آوری مرسوله",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "امکان توزیع مرسوله",
    accessor: "isDeliveryPossible",
    key: "isDeliveryPossible",
    header: "امکان توزیع مرسوله",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "آخرین ویرایش توسط",
    accessor: "editBy",
    key: "editBy",
    header: "آخرین ویرایش توسط",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "تاریخ آخرین ویرایش",
    accessor: "EditTime",
    key: "EditTime",
    header: "تاریخ آخرین ویرایش",
  },
  {
    id: "0002",
    isRequire: true,
    Header: "عملیات",
    accessor: "operation",
    key: "",
    header: "",
  },
];

export const RoleColumn = [
  {
    Header: "عنوان نقش",
    header: "عنوان نقش",
    accessor: "name",
    key: "name",
  },
  {
    Header: "دسترسی ها",
    header: "دسترسی ها",
    accessor: "selectPermissions",
    key: "selectPermissions",
  },
  {
    Header: "عملیات",
    accessor: "operation",
  },
];
export const PersonnelColumn = [
  {
    id: "001",
    isRequire: true,
    Header: "کد پرسنلی",
    accessor: "personelCode",
    header: "کد پرسنلی",
    key: "personelCode",
  },
  {
    id: "002",
    isRequire: false,
    Header: "کد ملی",
    accessor: "nationalCode",
    header: "کد ملی",
    key: "nationalCode",
  },
  {
    id: "003",
    isRequire: false,
    Header: "نام و نام خانوادگی",
    accessor: "name",
    header: "نام و نام خانوادگی",
    key: "name",
  },
  {
    id: "004",
    isRequire: false,
    Header: "شماره موبایل",
    accessor: "mobile",
    header: "شماره موبایل",
    key: "mobile",
  },
  {
    id: "005",
    isRequire: false,
    Header: "پست الکترونیک",
    accessor: "email",
    header: "پست الکترونیک",
    key: "email",
  },
  {
    id: "006",
    isRequire: false,
    Header: "آخرین ورود",
    accessor: "lastlogin",
    header: "آخرین ورود",
    key: "lastlogin",
  },
  {
    id: "007",
    isRequire: false,
    Header: "ایجاد شده در ",
    accessor: "createDate",
    header: "ایجاد شده در ",
    key: "createDate",
  },

  {
    id: "010",
    isRequire: false,
    Header: "نام کاربری",
    accessor: "username",
    header: "نام کاربری",
    key: "username",
  },
  {
    id: "008",
    isRequire: false,
    Header: " آخرین بروز رسانی توسط ",
    accessor: "modifier",
    header: " آخرین بروز رسانی توسط ",
    key: "modifier",
  },
  {
    id: "009",
    isRequire: false,
    Header: "تاریخ آخرین بروز رسانی",
    accessor: "modifierDate",
    header: "تاریخ آخرین بروز رسانی",
    key: "modifierDate",
  },
  {
    id: "011",
    isRequire: true,
    Header: "عملیات",
    header: "عملیات",
    accessor: "operation",
  },
];

export const ProductInfoColumn = [
  {
    Header: "محصول",
    accessor: "product",
    header: "محصول",
    key: "product",
  },
  {
    Header: "محصولات قابل ارائه دراین محصول",
    accessor: "usingProducts",
    header: "محصولات قابل ارائه دراین محصول",
    key: "usingProducts",
  },
  {
    Header: "وزن (کیلوگرم)",
    accessor: "weight",
    header: "وزن (کیلوگرم)",
    key: "weight",
  },
  {
    Header: "ابعاد (سانتیمتر)",
    accessor: "dimension",
    header: "ابعاد (سانتیمتر)",
    key: "dimension",
  },
  {
    Header: "مدت ارائه خدمت	",
    accessor: "timeCommitment",
    header: "مدت ارائه خدمت	",
    key: "timeCommitment",
  },
  {
    Header: "مبدا",
    accessor: "from",
    header: "مبدا",
    key: "from",
  },
  {
    Header: "مقصد",
    accessor: "destination",
    header: "مقصد",
    key: "destination",
  },
  // {
  //   Header: "وضعیت",
  //   accessor: "status",
  // },
  {
    Header: "عملیات",
    accessor: "operation",
    header: "",
    key: "",
  },
];

export const ServiceProvisionColumns = [
  {
    Header: "کد",
    accessor: "code",
    key: "code",
    header: "کد",
  },
  {
    Header: "عنوان",
    accessor: "name",
    key: "name",
    header: "عنوان",
  },
  {
    Header: "سرویس",
    accessor: "service",
    key: "service",
    header: "سرویس",
  },
  {
    Header: "وضعیت",
    accessor: "isActive",
    key: "isActive",
    header: "وضعیت",
  },
  {
    Header: "تاریخ اعتبار از",
    accessor: "validDateFrom",
    key: "validDateFrom",
    header: "تاریخ اعتبار از",
  },
  {
    Header: "تاریخ اعتبار تا",
    accessor: "validDateTo",
    key: "validDateTo",
    header: "تاریخ اعتبار تا",
  },
  {
    Header: "نوع تخفیف",
    accessor: "type",
    key: "type",
    header: "نوع تخفیف",
  },
  {
    Header: "عملیات",
    accessor: "operation",
    key: "operation",
    header: "",
  },
];

export const HubCategoryColumn = [
  {
    Header: "عنوان",
    accessor: "name",
    key: "name",
    header: "عنوان",
  },
  {
    Header: "کد",
    accessor: "code",
    key: "code",
    header: "کد",
  },
  {
    Header: "توضیحات",
    accessor: "description",
    key: "description",
    header: "توضیحات",
  },
  {
    Header: "عملیات",
    accessor: "operation",
    key: "operation",
    header: "",
  },
];
export const FetchGeoColumn = [
  {
    Header: "کد",
    accessor: "code",
    key: "code",
    header: "کد",
  },
  {
    Header: "عنوان",
    accessor: "name",
    key: "name",
    header: "عنوان",
  },
  {
    Header: "وضعیت",
    accessor: "status",
    key: "status",
    header: "وضعیت",
  },
  {
    Header: "عملیات",
    accessor: "operation",
    key: "operation",
    header: "",
  },
];
export const AddGeoColumn = [
  {
    Header: "مبداء",
    accessor: "fromCountryDevision",
  },
  {
    Header: "مقصد",
    accessor: "toCountryDevision",
  },
  {
    Header: "عملیات",
    accessor: "operation",
  },
];

export const ProductGroupCulumn = [
  {
    Header: "کد",
    accessor: "code",
    key: "code",
    header: "کد",
  },
  {
    Header: "عنوان",
    accessor: "name",
    key: "name",
    header: "عنوان",
  },
  {
    Header: "توضیحات",
    accessor: "description",
    key: "description",
    header: "توضیحات",
  },
  {
    Header: "وضعیت",
    accessor: "isActive",
    key: "isActive",
    header: "وضعیت",
  },
  {
    Header: "عملیات",
    accessor: "operation",
    key: "operation",
    header: "عملیات",
  },
];

export const CRMCustomerColumn = [
  {
    Header: "کد",
    header: "کد",
    accessor: "code",
    key: "code",
  },
  {
    Header: "عنوان",
    header: "عنوان",
    accessor: "name",
    key: "name",
  },
  {
    Header: "توضیحات",
    header: "توضیحات",
    accessor: "description",
    key: "description",
  },
  {
    Header: "وضعیت",
    header: "وضعیت",
    accessor: "isActive",
    key: "isActive",
  },
  {
    Header: "عملیات",
    header: "عملیات",
    accessor: "operation",
    key: "operation",
  },
];

export const TripManagementColumns = [
  {
    //
    id: uuidv4(),
    isRequire: true,
    Header: "سفر",
    header: "سفر",
    accessor: "trip",
    key: "trip",
    type: "time",
  },
  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "زمان ایجاد",
    header: "زمان ایجاد",
    accessor: "createdAt",
    key: "TheAmountPayable",
    type: "time",
  },
  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "نوع سفر",
    header: "نوع سفر",
    accessor: "triptype",
    key: "TheAmountPayable",
    type: "time",
  },
  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "نوع شیفت",
    header: "نوع شیفت",
    accessor: "dispatchShiftDto",
    key: "dispatchShiftDto",
    type: "time",
  },
  {
    // Vehicle
    id: uuidv4(),
    isRequire: false,
    Header: "وسیله نقلیه",
    header: "وسیله نقلیه",
    accessor: "Vehicle",
    key: "Vehicle",
    type: "time",
  },
  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "نوع وسیله نقلیه",
    header: "نوع وسیله نقلیه",
    accessor: "Vehicletype",
    key: "Vehicletype",
    type: "time",
  },
  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "راننده",
    header: "راننده",
    accessor: "driver",
    key: "driver",
    type: "time",
  },
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "کارها",
  //   header: "کارها",
  //   accessor: "works",
  //   key: "TheAmountPayable",
  //   type: "time",
  // },
  // {
  //   //
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "مسیر",
  //   header: "مسیر",
  //   accessor: "direction",
  //   key: "direction",
  //   type: "time",
  // },
  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "هاب برنامه ریزی",
    header: "هاب برنامه ریزی",
    accessor: "PlanningHub",
    key: "PlanningHub",
    type: "time",
  },

  {
    //
    id: uuidv4(),
    isRequire: false,
    Header: "هاب جاری",
    header: "هاب جاری",
    accessor: "currentHub",
    key: "currentHub",
    type: "time",
  },
  {
    //
    id: uuidv4(),
    isRequire: true,
    Header: "وضعیت",
    header: "وضعیت",
    accessor: "status",
    key: "status",
    type: "time",
  },
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "برنامه ریزی شده در",
  //   header: "برنامه ریزی شده در",
  //   accessor: "Scheduledin",
  //   key: "Scheduledin",
  //   type: "time",
  // },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "مدل وسیله نقلیه",
    header: "مدل وسیله نقلیه",
    accessor: "Transportmodel",
    key: "Transportmodel",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "اخرین بروز رسانی",
    header: "اخرین بروز رسانی",
    accessor: "lastupdate",
    key: "lastupdate",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "شروع شده در تاریخ",
    header: "شروع شده در تاریخ",
    accessor: "startedondate",
    key: "startedondate",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "تخصیص شده در تاریخ",
    header: "تخصیص شده در تاریخ",
    accessor: "assignedindate",
    key: "assignedindate",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "تکمیل شده در تاریخ",
    header: "تکمیل شده در تاریخ",
    accessor: "Completedondate",
    key: "Completedondate",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "ایجاد کننده سفر",
    header: "ایجاد کننده سفر",
    accessor: "tripcreator",
    key: "tripcreator",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "فاصله برنامه ای (کیلومتر)",
    header: "فاصله برنامه ای (کیلومتر)",
    accessor: "programinterval",
    key: "programinterval",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "زمان سرویس وسیله نقلیه",
    header: "زمان سرویس وسیله نقلیه",
    accessor: "Vehicleservicetime",
    key: "Vehicleservicetime",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "مدت ارائه سرویس (دقیقه)",
    header: "مدت ارائه سرویس (دقیقه)",
    accessor: "Serviceprovisionperiod",
    key: "Serviceprovisionperiod",
    type: "time",
  },
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "راننده برنامه ریزی شده",
  //   header: "راننده برنامه ریزی شده",
  //   accessor: "programmeddriver",
  //   key: "TheAmountPayable",
  //   type: "time",
  // },
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "وسیله برنامه ریزی شده ",
  //   header: "وسیله برنامه ریزی شده ",
  //   accessor: "programmeddevice",
  //   key: "programmeddevice",
  //   type: "time",
  // },
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "وضعیت صورت حساب",
  //   header: "وضعیت صورت حساب",
  //   accessor: "BillStatus",
  //   key: "BillStatus",
  //   type: "time",
  // },
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "تحویل دستی است ؟ ",
  //   header: "تحویل دستی است ؟ ",
  //   accessor: "Isitmanualdelivery",
  //   key: "TheAmountPayable",
  //   type: "time",
  // },

  // بعدا اضافه شود
  // {
  //   id: uuidv4(),
  //   isRequire: false,
  //   Header: "قیمت کل",
  //   header: "قیمت کل",
  //   accessor: "totalPrice",
  //   key: "totalPrice",
  //   type: "time",
  // },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "حجم مرسولات(متر مکعب)",
    header: "حجم مرسولات(متر مکعب)",
    accessor: "Theweightoftheshipment",
    key: "Theweightoftheshipment",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "وزن مرسولات(کیلوگرم)",
    header: "وزن مرسولات(کیلوگرم)",
    accessor: "Shipmentweight",
    key: "Shipmentweight",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "ظرفیت حجمی وسیله نقیله(متر مکعب)",
    header: "ظرفیت حجمی وسیله نقیله(متر مکعب)",
    accessor: "Volumetriccapacityoftheconveyance",
    key: "Volumetriccapacityoftheconveyance",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "ظرفیت وزنی وسیله نقیله(کیلوگرم)",
    header: "ظرفیت وزنی وسیله نقیله(کیلوگرم)",
    accessor: "Weightcapacityofthetransportdevice",
    key: "Weightcapacityofthetransportdevice",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "درصد حجم قابل استفاده وسیله نقلیه",
    header: "درصد حجم قابل استفاده وسیله نقلیه",
    accessor: "Percentageofusablevolumeofthevehicle",
    key: "Percentageofusablevolumeofthevehicle",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "درصد وزن قابل استفاده وسیله نقلیه",
    header: "درصد وزن قابل استفاده وسیله نقلیه",
    accessor: "Usablevehicleweightpercentage",
    key: "TheAmountPayable",
    type: "time",
  },
];
export const ShelfManagementCol = [
  {
    id: uuidv4(),
    isRequire: false,
    Header: "شناسه قفسه",
    header: "شناسه قفسه",
    accessor: "test1",
    key: "test1",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "تعداد مرسولات",
    header: "تعداد مرسولات",
    accessor: "test2",
    key: "test2",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "زمان آخرین بروز رسانی",
    header: "زمان آخرین بروز رسانی",
    accessor: "test3",
    key: "test3",
    type: "time",
  },
];
export const TripMDLColumns = [
  {
    id: uuidv4(),
    isRequire: true,
    Header: "شماره سفر",
    header: "شماره سفر",
    accessor: "trip",
    key: "trip",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "زمان ایجاد",
    header: "زمان ایجاد",
    accessor: "createdAt",
    key: "createdAt",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "وسیله نقلیه",
    header: "وسیله نقلیه",
    accessor: "Vehicle",
    key: "Vehicle",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "مدل وسیله نقلیه",
    header: "مدل وسیله نقلیه",
    accessor: "Transportmodel",
    key: "Transportmodel",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "مسیر",
    header: "مسیر",
    accessor: "direction",
    key: "direction",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "وضعیت جاری",
    header: "وضعیت جاری",
    accessor: "selectStatus",
    key: "selectStatus",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "راننده 1",
    header: "راننده 1",
    accessor: "firstdriver",
    key: "firstdriver",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "راننده 2",
    header: "راننده 2",
    accessor: "seconddriver",
    key: "seconddriver",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "زمان شروع سفر",
    header: "زمان شروع سفر",
    accessor: "startTime",
    key: "startTime",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "زمان ورود به آخرین هاب",
    header: "زمان ورود به آخرین هاب",
    accessor: "ConnectioninstanceArrivalTime",
    key: "6",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "سفر اولیه",
    header: "سفر اولیه",
    accessor: "InitialTrip",
    key: "InitialTrip",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "زمان پایان سفر",
    header: "زمان پایان سفر",
    accessor: "endTime",
    key: "endTime",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "هاب مبداء",
    header: "هاب مبداء",
    accessor: "initHub",
    key: "initHub",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "هاب مقصد",
    header: "هاب مقصد",
    accessor: "destiationHub",
    key: "destiationHub",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "هاب جاری",
    header: "هاب جاری",
    accessor: "currentHub",
    key: "currentHub",
    type: "time",
  },

  {
    id: uuidv4(),
    isRequire: true,
    Header: "شماره درب",
    header: "شماره درب",
    accessor: "gateNumber",
    key: "gateNumber",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "شماره بارانداز",
    header: "شماره بارانداز",
    accessor: "docknumber",
    key: "docknumber",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "وزن بار",
    header: "وزن بار",
    accessor: "weightGoods",
    key: "weightGoods",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "حجم بار",
    header: "حجم بار",
    accessor: "valuesGoods",
    key: "valuesGoods",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "ظرفیت وزنی وسیله نقلیه",
    header: "ظرفیت وزنی وسیله نقلیه",
    accessor: "Weightcapacityofthetransportdevice",
    key: "Weightcapacityofthetransportdevice",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "ظرفیت حجمی وسیله نقلیه",
    header: "ظرفیت حجمی وسیله نقلیه",
    accessor: "Volumetriccapacityoftheconveyance",
    key: "Volumetriccapacityoftheconveyance",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "ظرفیت وزنی قابل تخصیص",
    header: "ظرفیت وزنی قابل تخصیص",
    accessor: "AssignableWeightCapacity",
    key: "AssignableWeightCapacity",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "ظرفیت حجمی قابل تخصیص",
    header: "ظرفیت حجمی قابل تخصیص",
    accessor: "AssignableValueCapacity",
    key: "AssignableValueCapacity",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "روش حمل",
    header: "روش حمل",
    accessor: "shippingMethod",
    key: "shippingMethod",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "حمل توسط شرکت حمل ",
    header: "حمل توسط شرکت حمل ",
    accessor: "Shippingbyshippingcompany",
    key: "Shippingbyshippingcompany",
    type: "time",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "شرکت حمل کننده ",
    header: "شرکت حمل کننده ",
    accessor: "shippingcompany",
    key: "shippingcompany",
    type: "time",
  },
  {
    accessor: "operation",
    Header: " عملیات",
    key: "operation",
    header: " عملیات",
  },
  // {
  //   id: uuidv4(),
  //   isRequire: true,
  //   Header: "شماره بارنامه",
  //   header: "شماره بارنامه",
  //   accessor: "6",
  //   key: "6",
  //   type: "time",
  // },
  // {
  //   id: uuidv4(),
  //   isRequire: true,
  //   Header: "شماره صورت حساب",
  //   header: "شماره صورت حساب",
  //   accessor: "6",
  //   key: "6",
  //   type: "time",
  // },
  // {
  //   id: uuidv4(),
  //   isRequire: true,
  //   Header: "وضعیت صورت حساب",
  //   header: "وضعیت صورت حساب",
  //   accessor: "6",
  //   key: "6",
  //   type: "time",
  // },
  // {
  //   id: uuidv4(),
  //   isRequire: true,
  //   Header: "مبلغ صورت حساب",
  //   header: "مبلغ صورت حساب",
  //   accessor: "Billamount",
  //   key: "Billamount",
  //   type: "time",
  // },
];

export const labelColumn = [
  {
    Header: "شماره درخواست",
    header: "شماره درخواست",
    accessor: "requestNo",
    key: "requestNo",
  },
  {
    Header: "تاریخ",
    header: "تاریخ",
    accessor: "requestDate",
    key: "requestDate",
  },
  {
    Header: "نوع",
    accessor: "requestType",
    header: "نوع",
    key: "requestType",
  },
  {
    Header: "هاب",
    accessor: "selectHub",
    header: "هاب",
    key: "selectHub",
  },
  {
    Header: "مشتری",
    accessor: "selectCustomer",
    header: "مشتری",
    key: "selectCustomer",
  },
  {
    Header: "وضعیت",
    accessor: "status",
    header: "وضعیت",
    key: "status",
  },
  {
    Header: "عملیات",
    accessor: "operation",
    header: "عملیات",
    key: "operation",
  },
];

export const BagMDLColumns = [
  {
    id: "0001",
    isRequire: true,
    key: "bagNumber",
    Header: "شماره کیسه",
    header: "شماره کیسه",
    accessor: "bagNumber",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "status",
    Header: "وضعیت",
    header: "وضعیت",
    accessor: "status",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "selectBagType",
    Header: "نوع کیسه",
    header: "نوع کیسه",
    accessor: "selectBagType",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "حاوی مرسولات",
    header: "حاوی مرسولات",
    accessor: "contain",
    key: "contain",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "weight",
    Header: "وزن (کیلو گرم)",
    header: "وزن (کیلو گرم)",
    accessor: "weight",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "volume",
    Header: "حجم",
    header: "حجم",
    accessor: "volume",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "cprNumber",
    Header: "تعداد مرسوله",
    header: "تعداد مرسوله",
    accessor: "cprNumber",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "tripNo",
    Header: "شماره سفر",
    header: "شماره سفر",
    accessor: "tripNo",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "selectSourceHub",
    Header: "هاب مبدا",
    header: "هاب مبدا",
    accessor: "selectSourceHub",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "selectDestinationHub",
    Header: "هاب مقصد",
    header: "هاب مقصد",
    accessor: "selectDestinationHub",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "selectCurrentHub",
    Header: "هاب جاری",
    header: "هاب جاری",
    accessor: "selectCurrentHub",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "weightCapacity",
    Header: "ظرفیت وزنی(کیلوگرم)",
    header: "ظرفیت وزنی(کیلوگرم)",
    accessor: "weightCapacity",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "volumeCapacity",
    Header: "ظرفیت حجمی (متر مکعب)",
    header: "ظرفیت حجمی (متر مکعب)",
    accessor: "volumeCapacity",
  },
  {
    id: uuidv4(),
    isRequire: false,
    key: "selectCarrier",
    Header: "وسیله نقلیه",
    header: "وسیله نقلیه",
    accessor: "selectCarrier",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "خطا",
    header: "خطا",
    accessor: "exeption",
    key: "exeption",
  },
  {
    id: "0002",
    isRequire: true,
    Header: "عملیات",
    header: "عملیات",
    key: "operation",
    accessor: "operation",
  },
];

export const MoveVehaicle = [
  {
    Header: "شماره مجوز",
    header: "شماره مجوز",
    accessor: "licenseNo",
    key: "licenseNo",
  },
  {
    Header: "تاریخ مجوز",
    header: "تاریخ مجوز",
    accessor: "licenseDate",
    key: "licenseDate",
  },
  {
    Header: "وضعیت",
    header: "وضعیت",
    accessor: "selectStatus",
    key: "selectStatus",
  },
  {
    Header: "هاب مبدا",
    header: "هاب مبدا",
    accessor: "selectSourceHub",
    key: "selectSourceHub",
  },
  {
    Header: "هاب مقصد",
    header: "هاب مقصد",
    accessor: "selectDestinationHub",
    key: "selectDestinationHub",
  },
  {
    Header: "از تاریخ",
    header: "از تاریخ",
    accessor: "validateFrom",
    key: "validateFrom",
  },
  {
    Header: "تا تاریخ",
    header: "تا تاریخ",
    accessor: "validateTo",
    key: "validateTo",
  },
  {
    Header: "عملیات",
    header: "عملیات",
    accessor: "operation",
  },
];

export const ModalTableVehicleMove = [
  {
    id: uuidv4(),
    Header: "شماره پلاک وسیله نقلیه",
    header: "شماره پلاک وسیله نقلیه",
    accessor: "placNumber",
  },
  {
    id: uuidv4(),
    Header: "مدل وسیله نقلیه",
    header: "مدل وسیله نقلیه",
    accessor: "vehicleMakeSelect",
  },
  {
    id: uuidv4(),
    Header: "عملیات",
    header: "عملیات",
    accessor: "opration",
  },
];

export const SecondModalTableVehicleMove = [
  {
    id: uuidv4(),
    Header: "شماره پلاک وسیله نقلیه",
    header: "شماره پلاک وسیله نقلیه",
    accessor: "placNumber",
  },
  {
    id: uuidv4(),
    Header: "مدل وسیله نقلیه",
    header: "مدل وسیله نقلیه",
    accessor: "vehicleMakeSelect",
  },
];
export const BarCodeCol = [
  {
    id: uuidv4(),
    isRequire: true,
    Header: "شماره بار کد",
    accessor: "labelNumber",
    key: "labelNumber",
    header: "شماره بار کد",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "وضعیت",
    accessor: "status",
    key: "status",
    header: "وضعیت",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "نوع",
    accessor: "type",
    key: "type",
    header: "نوع",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "هاب",
    accessor: "hub",
    key: "hub",
    header: "هاب",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "مشتری",
    accessor: "customer",
    key: "customer",
    header: "مشتری",
  },
  {
    id: uuidv4(),
    isRequire: false,
    Header: "مرسوله",
    accessor: "consignment",
    key: "consignment",
    header: "مرسوله",
  },
  {
    id: uuidv4(),
    isRequire: true,
    Header: "تصویر",
    accessor: "operation",
    key: "operation",
    header: "تصویر",
  },
];
