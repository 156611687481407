interface RadioProps {
  title?: string;
  handleChange?: any;
  name?: string;
  values?: any;
  wrapperClassName?: string;
  isDisabled?: boolean;
  labelClassName?: string;
  className?: string;
  checked?: any;
  errors?: any;
}

const Radio = ({
  title,
  handleChange = undefined,
  name,
  values,
  isDisabled,
  wrapperClassName,
  labelClassName,
  className,
  checked,
  errors,
}: RadioProps) => {
  return (
    <div className={`flex-start-center gap-2 ${wrapperClassName}`}>
      <input
        type="radio"
        className={`accent-tomato w-4 h-4 cursor-pointer ${className} ${
          errors ? "border-2 border-red outline outline-2 outline-red" : ""
        }`}
        onChange={() => handleChange?.(name, checked)}
        name={name}
        checked={values === checked}
        style={{ borderRadius: "50%" }}
        disabled={isDisabled}
      />
      <label className={`text-darkGray ${labelClassName}`}>{title}</label>
    </div>
  );
};

export default Radio;
