import { lazy } from "react";

const VehicleManagement = lazy(
  () => import("pages/TRMManagement/VehicleManagement/VehicleManagement")
);
const DriverManagement = lazy(
  () => import("pages/TRMManagement/DriverManagement")
);
const TimeShiftManagement = lazy(
  () => import("pages/TRMManagement/ShiftMnagement/index")
);
const TRMMoveVehicle = lazy(
  () => import("pages/TRMMoveVehicle/TRMMoveVehicle")
);

const ChangeDriver = lazy(() => import("pages/change-driver"));

export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/vehicle-management",
    component: <VehicleManagement />,
    label: "مدیریت نقلیه",
    permissions: "view_vehicle_management",
  },
  {
    to: "/driver-management",
    component: <DriverManagement />,
    label: "مدیریت رانندگان",
    permissions: "view_driver_management",
  },
  {
    code: "",
    to: "/time-shift-manage",
    component: <TimeShiftManagement />,

    label: "مدیریت شیفت",
    permissions: "view_shift_mnagement",
  },
  {
    code: "",
    to: "/Move-vehicle",
    component: <TRMMoveVehicle />,

    label: "جابجایی وسیله نقلیه",
    permissions: "view_vehicle_transfer",
  },
  {
    code: "",
    to: "/change-driver",
    component: <ChangeDriver />,
    label: "تعویض راننده",
    permissions: "",
  },
];
