import { Children, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionModals, ActionSidebar } from "redux/optime/optime";
import { ReactComponent as PolygonHide } from "../../../assets/icons/polygonHide.svg";
import { ReactComponent as EditPolygon } from "../../../assets/icons/editpolygon.svg";
import { ReactComponent as CreatePolygon } from "../../../assets/icons/createPolygon.svg";
import { ReactComponent as SidebarIcon } from "../../../assets/icons/iconSidebar.svg";
import { useLocation } from "react-router-dom";
import { checkPlaningCondition } from "../utils";

export default function Sidebar() {
  const dispatch = useDispatch();
  const {addZone,multiSelect,editZone}=useSelector((state:any)=>state.optimeModal)
  const {state}=useLocation()


  const sidebarItem = [
    {
      icon: (
        <button onClick={() => dispatch(ActionModals())}>
          <SidebarIcon />
        </button>
      ),
    },
    // {
    //   icon: (
    //     <button
    //       title="middmile"
    //       className={`block w-full py-2 ${
    //         activeButton.MotorcycleButton ? "bg-red" : ""
    //       }`}
    //       onClick={() =>
    //         setActiveButton({
    //           MotorcycleButton: !activeButton.MotorcycleButton,
    //         })
    //       }
    //     >
    //       <Motorcycle className="w-full" />
    //     </button>
    //   ),
    // },
    // {
    //   icon: (
    //     <button
    //       title="lastmile"
    //       className={`block w-full py-2 ${
    //         activeButton.MiddmileCarButton ? "bg-red" : ""
    //       }`}
    //       onClick={() =>
    //         setActiveButton({
    //           MiddmileCarButton: !activeButton.MiddmileCarButton,
    //         })
    //       }
    //     >
    //       <MiddmileCar className="w-full" />
    //     </button>
    //   ),
    // },
    // {
    //   icon: (
    //     <button
    //       title="merge"
    //       className={`block w-full py-2 ${
    //         activeButton.MergeButton ? "bg-red" : ""
    //       }`}
    //       onClick={() =>
    //         setActiveButton({ MergeButton: !activeButton.MergeButton })
    //       }
    //     >
    //       <MergeIcon className="w-full" />
    //     </button>
    //   ),
    // },

    {
      icon: (
        <button
          title="multi-select"
          className={`block w-full py-2 ${
            multiSelect ? "bg-red" : ""
          }`}
          onClick={() => {
            checkPlaningCondition(state) &&  dispatch(ActionSidebar("multiSelect"));
          }}
        >
          <PolygonHide className="w-full" />
        </button>
      ),
    },
    // {
    //   icon: (
    //     <button title="MultiSelect">
    //       <MultiSelect className="w-full" />
    //     </button>
    //   ),
    // },
    {
      icon: (
        <button
          title="Edit Polygon"
          className={`flex justify-center w-full  py-2 ${
            editZone ? "bg-red" : ""
          }`}
          onClick={() => {
            checkPlaningCondition(state) &&   dispatch(ActionSidebar("editZone"));
          
          }}
        >
          <EditPolygon />
        </button>
      ),
    },

    {
      icon: (
        <button
          title="Add Polygon"
          className={`flex justify-center w-full  py-2 ${
            addZone ? "bg-red" : ""
          }`}
          onClick={() => {
            checkPlaningCondition(state) &&   dispatch(ActionSidebar("addZone"));
        
          }}
        >
          <CreatePolygon />
        </button>
      ),
    },
  ];

  return (
    <ul>
      {Children.toArray(
        sidebarItem.map(({ icon }) => {
          return (
            <li className="flex justify-center items-center my-2 px-0 cursor-pointer hover:bg-blue-800">
              {icon}{" "}
            </li>
          );
        })
      )}
    </ul>
  );
}
