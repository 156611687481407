const DocsIcon = ({className} : any) => (
  <svg className={`text-tomato ${className}`} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2495 14V17.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5 19.8333V18.6667C24.5 18.0223 23.9777 17.5 23.3333 17.5H15.1667C14.5223 17.5 14 18.0223 14 18.6667V19.8333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0417 24.5H15.4583C14.6529 24.5 14 23.8471 14 23.0417V17.4462C14 16.9734 14.0958 16.5056 14.2816 16.0708L14.7882 14.8853C15.0177 14.3483 15.5453 14 16.1292 14H22.3697C22.9534 14 23.4809 14.3481 23.7105 14.8848L24.218 16.071C24.4041 16.5061 24.5 16.9744 24.5 17.4476V23.0417C24.5 23.8471 23.8471 24.5 23.0417 24.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.91708 3.5V5.6C9.88797 5.95341 10.1476 6.26491 10.5004 6.3H15.1671C15.52 6.26491 15.7795 5.95341 15.7504 5.6V3.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5005 24.5H8.16713C6.87847 24.5 5.8338 23.4553 5.8338 22.1667V5.83333C5.8338 4.54467 6.87847 3.5 8.16713 3.5H17.5005C18.7891 3.5 19.8338 4.54467 19.8338 5.83333V10.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocsIcon;
