import { addEditUrls } from 'services/api.enums';
import {createSlice,createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit"
import { filterDataAPI } from "../../services/CRUDServices";




export const HubTypeData: AsyncThunk<{}, {}, {}>=createAsyncThunk("hubType",async(body:any)=>{
     const params=`/filter?pageNumber=${body.pageNumbers}&pageSize=10`
    const {data}=await filterDataAPI(addEditUrls.hubCategory + params,{
      name:body?.name ? body?.name : "",
        code: body?.code ? body.code : "",
        description:body?.description ? body.description : "",
        isActive:body.isActive
})

    return data.payload
})

interface HubType{
    TableType:any,
    fetchpost:boolean,
    errorMessage:string,
    filter:object
}



const initialState:HubType={
    TableType:[],
    fetchpost: false,
    errorMessage: "",
    filter:{}
}


 const HubTypeTable=createSlice({
    name:"hubType",
    initialState,
    reducers:{
        clearHubType: (state) => {
            state.TableType = [];
          },
          deleteTable:(state,action)=>{
            state.TableType.content=state.TableType.content.filter((item:any)=>item.code !== action.payload)
          },
          filterTable:(state,action)=>{
            if(action.payload){
              state.TableType.content=state.TableType.content.filter((item:any)=>item.name.includes(action.payload))
            }
            },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
       
    },
    extraReducers: {
        [HubTypeData.fulfilled as any]: (state, action) => {
          state.TableType = action.payload;
          state.fetchpost = false;
        },
        [HubTypeData.pending as any]: (state) => {
          state.fetchpost = true;
        },
        [HubTypeData.rejected as any]: (state) => {
          state.fetchpost = false;
          state.errorMessage = "wrong";
        },
      },
 }) 

 export const {clearHubType,deleteTable,filterTable,setFilter}=HubTypeTable.actions
 export default HubTypeTable.reducer