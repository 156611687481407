import { FC } from "react";
import Switch from "react-switch";
interface CustomSwitchProps {
  active: boolean;
  handleChange?: (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>
  ) => void;
  label?: string;
  wrapperClassName?: string;
  disabled?: boolean;
}

const CustomSwitch: FC<CustomSwitchProps> = ({
  handleChange,
  active,
  label,
  wrapperClassName = "",
  disabled,
}): JSX.Element => {
  return (
    <div
      className={`flex justify-end items-center flex-row-reverse  w-32 mb-5 gap-2 ${wrapperClassName}`}
    >
      {label ?? <span>{label ? label : active ? "فعال" : "غیر فعال"}</span>}
      <Switch
        disabled={disabled}
        onChange={handleChange}
        checked={active}
        onColor="#FFEAE9"
        offColor="#F9FAFC"
        onHandleColor="#EF5644"
        offHandleColor="#e7e5e5"
        width={40}
        height={20}
      />
    </div>
  );
};

export default CustomSwitch;
