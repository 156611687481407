import { createSlice } from "@reduxjs/toolkit";



import { StateData } from "./state-model";



const initialState: StateData = {
  barcodeList: [],
  fetchPost: false,
  errorMessage: null,
  isUpdating: false,
  totalElements: 0,
  filter: {},
};

const BarcodeList = createSlice({
  initialState: initialState,
  name: "barcodeList",
  reducers: {
    clearBarcode: (state) => {
      state.barcodeList = [];
    },
    updating: (state, action) => {
      state.isUpdating = action?.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    fetchBarCodes: (state, action) => {
      state.barcodeList = action?.payload;
    },
    fetchTotalElements: (state, action) => {
      state.totalElements = action?.payload;
    },
  },
});
export const { clearBarcode, fetchTotalElements, fetchBarCodes, updating, setFilter } =
  BarcodeList.actions;
export default BarcodeList.reducer;
