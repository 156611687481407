import * as turf from "@turf/turf";
import { getPelak } from "tools/functions/Methods";

export function clearPolygon(featureGroupRef: any, size = 0 as number) {
  const drawnItems = featureGroupRef._layers;
  if (Object.keys(drawnItems).length > size) {
    Object.keys(drawnItems).forEach((layerid, index) => {
      if (index > 0) return;
      const layer = drawnItems[layerid];
      return featureGroupRef.removeLayer(layer);
    });
  }
}

export function convertResponse(response) {
  const convert = {
    consignmentList: response.data.payload.consignmentList,
    deleteConsignmentList: response.data.payload.deleteConsignmentList,

    polygons: response.data.payload.polygons
      .map((item, index) => ({
        ...item,

        polygonIndex: item.polygonIndex,
        polygonCordinate: item.polygonCordinate.map((item) => [
          item.locLong,
          item.locLate,
        ]),
      }))
      .sort((a, b) => a.polygonIndex - b.polygonIndex),
    station: response.data.payload.station,
  };
  return convert;
}

export function getPinFromZone(createdZone, consignmentList, selectedZone) {
  const polygon = turf.polygon([createdZone]);
  const pins = [...consignmentList, ...selectedZone.consignmnetList];
  const selectPins = [];
  pins.forEach((element) => {
    if (
      turf.booleanPointInPolygon([element.latitude, element.longitude], polygon)
    ) {
      selectPins.push(element);
    }
  });
  return selectPins;
}

export function convertDriverData(item) {
  return {
    ...item,
    id: item.vehicleId,
    text: item.driver?.text + "  " + getPelak(item?.carTagDto),
  };
}

export function convertAllDriverDataData(items) {
  return items.map((item) => ({
    ...item,
    text: item.text + "  " + getPelak(item.carTagDto),
  }));
}

export function getCachedData() {
  return JSON.parse(localStorage.getItem("planingData"));
}

export function checkPlaningCondition(state) {
  if (state.status < 1) return true;
  // if (state.status !== 3) return true;
}

export function checkConsignmentTypeText(pin) {
  if (pin?.consignmentType?.id === 3) return "مرسوله احراز هویتی";
  if (pin.selectPackage?.id===4 ) return "مرسوله ساز 4";
  return ""
}
export function checkConsignmentTypeColor(pin) {
  if (pin?.consignmentType?.id === 3) return "red";
  if (pin.selectPackage?.id===4 ) return "blue";
  return "#666"
}

export function checkConsignmentType(type) {
  if (type?.id === 3) return true;
}