import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI, getAPI } from "services/CRUDServices";
import { filterUrls, getUrls } from "services/api.enums";

export const filterReturnConsignmentModel: AsyncThunk<{}, {}, {}> =
  createAsyncThunk("returnconsignment", async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.returnConsignment,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true
    );
    const fetchJson = async (item: any) =>
      await getAPI(
        getUrls.addressDetails + "/" + item.selectReceiverAddress?.id,
        true
      ).then((res: any) => {
        return { ...item, ...res.data.payload,addressId:res.data.payload?.id,id:item.id};
      });
    let content: any = [];
    content = await Promise.all(
      data.data.content.map((item: any) => fetchJson(item))
    ).then((values) => values);

    data.data.content = content;
    return data.data;
  });

const initialState: any = {
  returnConsignmentData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const ReturnConsignmentLists = createSlice({
  initialState: initialState,
  name: "returnconsignment",
  reducers: {
    clearReturnConsignment: (state) => {
      state.returnConsignmentData = [];
    },
    setFilterReturnConsignment: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterReturnConsignmentModel.fulfilled as any]: (state, action) => {
      state.returnConsignmentData = action.payload;
      state.fetchPost = false;
    },
    [filterReturnConsignmentModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterReturnConsignmentModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterReturnConsignment, clearReturnConsignment } =
  ReturnConsignmentLists.actions;
export default ReturnConsignmentLists.reducer;
