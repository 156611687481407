import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";
import { ErrorAlert } from "global/alert/Alert";




    export const filterSalesChannel=createAsyncThunk('salechannel',async(body:any)=>{
        var data:any;
        try {
          data = await filterTableDataAPI(filterUrls.salesChannel as any, body?.pageNumber || 1,{
            ...body,
            pageNumber: undefined,
            pageSize: undefined,
          },true);
          } catch (error) {
            ErrorAlert(error?.response?.data?.errors?.message)
          }
        return data.data;
    })
    


const initialState:any= {
    sChannelLists:[],
    fetchpost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{}   
    
}

const SalesChannelList = createSlice({
    initialState: initialState,
    name: "salechannel",
    reducers: {
        updating: (state, action) => {
            state.isUpdating = action.payload;
          },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },

    },
    extraReducers: {
        [filterSalesChannel.fulfilled as any]: (state, action) => {
            state.sChannelLists = action.payload.payload;
            state.fetchPost = false;
        },
        [filterSalesChannel.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [filterSalesChannel.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});
export const {  setFilter } = SalesChannelList.actions;
export default SalesChannelList.reducer