import React, { useState } from "react";
import { addEditDataAPI } from "services/CRUDServices";

function useFetchIput(setFieldValue) {
  const [items, setItems] = useState();
  const handleSuggest = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setFieldValue(name, e.target.value);
    addEditDataAPI(
      `/customer-api/customer-management/customers/search`,
      "post",
      {
        pagination: {
          page: 1,
          size: 100,
        },
        searchCriteria: {
          code:e.target.value,
        },
      }
    ).then((res: any) => {
   
      setItems(
        res.data.response.items.map((item) => {
          return {
            label: item?.code,
            id: item?.id,
            mobile:item.mobile,
            name:item.name,
            nationalCode:item.nationalCode,
            nationalId:item.nationalId,
            code:item.code
          };
        })
      );
    });
  };

  return { handleSuggest, items };
}

export default useFetchIput;
