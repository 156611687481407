import { lazy } from "react";

const Contradiction = lazy(() => import("pages/Contradiction"));
export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/contradiction",
    component: <Contradiction />,
    label: "مغایرت گیری",
    permissions: "view_contradiction",
  },
];
