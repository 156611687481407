import OrderFactor from "pages/OrderManagement/views/Order/Elements/order-factor";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setNewConsignments, setOrder } from "redux/OrderData/OrderData";
import { getUrls } from "services/api.enums";
import { paymentBy } from "tools/functions/Methods";
import SimpleButton from "../../../../global/SimpleButton/SimpleButton";
import { addEditDataAPI, getAPI } from "../../../../services/CRUDServices";
import PaymentModal from "./payment/PaymentModal";
import { usePayment } from "./payment/utils/usePayment";

interface InvoiceProps {
  data: {
    invoicePrice: {
      invoicePrice?: number;
      pickupPrice?: number;
      postBackPrice?: number;
      taxPrice?: number;
      total: number;
      packagePrice?: number;
      codPrice?: number;
    };
    inVoiceNumber: number;
    inVoiceId: number;
    consignmentIntraCity: number;
    consignmentIntercity: number;
    sumOfBasePrice: number;
    taxPrice: number;
    pickupPrice: number;
    invoiceCode: string;
    consignmentExternalCity: number;
    supplementaryService: [{ price: number; serviceid: number }];
  };

  paymentMode?: any;
}

const OrderFacture = ({ data, paymentMode }: InvoiceProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { orderData, consignments } = useSelector((state: any) => state.Order);
  const { orderManagementData } = useSelector((state: any) => state.orderData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleClick } = usePayment();
  const handleSubmitOrderFacture = () => {
    if (!paymentMode) {
      if (
        consignments.every((item: any) => item.price) &&
        consignments.every(
          (item: any) => item?.receiverCustomerId || item?.receiverProspectId
        )
      ) {
        dispatch(
          setOrder({
            inVoiceNumber: data?.invoiceCode,
            inVoiceId: data?.inVoiceId,
            consignmentIntraCity: data?.consignmentIntraCity,
            consignmentIntercity: data?.consignmentIntercity,
            sumOfBasePrice: data?.invoicePrice?.total,
            // sumOfBasePrice: data?.sumOfBasePrice,
            taxPrice: data.invoicePrice?.taxPrice,
            // taxPrice: taxPrice,
            pickupPrice: data?.invoicePrice?.pickupPrice,
            // pickupPrice: data?.pickupPrice,
          })
        );
        setLoading(true);
        getAPI(getUrls.findAllInvoiceDetailsInOrder + orderData?.orderId)
          .then((res: any) => {
            const invoiceData = res.data.payload;
            getAPI(getUrls.invoiceAssign + orderData?.orderId)
              .then(() => {
                const newConsignemnts = consignments.map((item: any) => ({
                  ...item,
                  payBy: invoiceData.find(
                    (invoice: any) =>
                      invoice.consignment.id === item.consignmentId
                  )?.paymentBy?.id,
                  bill: invoiceData.find(
                    (invoice: any) =>
                      invoice.consignment.id === item.consignmentId
                  )?.bill,
                  invoiceDetailsId: invoiceData.find(
                    (invoice: any) =>
                      invoice.consignment.id === item.consignmentId
                  ).id,
                }));

                dispatch(setNewConsignments(newConsignemnts));
                if (orderManagementData?.easyOrder) {
                  let invoiceData = newConsignemnts.map((item: any) => ({
                    paymentBy: { id: paymentBy(item) },

                    invoiceDetailsId: item?.invoiceDetailsId,
                    orderId: orderManagementData?.orderId || orderData?.orderId,
                    customerId:
                      orderManagementData?.senderCustomerId ||
                      orderData?.senderCustomerId,
                    isAdminPanel: true,
                  }));

                  addEditDataAPI(
                    "consignment-api/invoice/invoiceDetailsPayment",
                    "post",
                    invoiceData
                  )
                    .then((res) => {
                      dispatch(setOrder(res.data.payload));
                      setLoading(false);
                      if (
                        consignments.some(
                          (item) =>
                            item.paymentBy === "SENDER" ||
                            item.paymentBy === null
                        )
                      ) {
                        setIsOpen(true);
                      } else {
                        handleClick(false, {
                          ...orderData,
                          ...res.data.payload,
                        });
                      }
                      // if (
                      //   !consignments.every((item) =>
                      //     item.supplementary.some((sub) => sub.serviceType === 1)
                      //   )
                      // ) {
                      //   setIsOpen(true);
                      //   // handleClick(true, res.data.payload);
                      // } else {
                      //   handleClick(false, { ...orderData, ...res.data.payload });
                      // }
                    })
                    .catch(() => setLoading(false));

                  return;
                }
                navigate("/consignments-payment");
                setLoading(false);
              })
              .catch(() => setLoading(false));
          })
          .catch(() => setLoading(false));
      } else {
        toast.error("ابتدا اطلاعات مرسوله را کامل کنید");
      }
    }
  };

  const extraPrice = data?.supplementaryService.find(
    (servcie: any) => servcie?.serviceid === 161
  )?.price;

  
  return (
    <div className="rounded-2xl w-80 bg-white border  shadow-sm p-4">
      <p className="text-center text-2xl mb-3 text-dark">صورت حساب</p>
      <OrderFactureElements
        title="مرسوله درون شهری"
        price={data?.consignmentIntraCity || 0}
        notToman
      />
      <OrderFactureElements
        title="مرسوله بین شهری"
        price={data?.consignmentExternalCity || 0}
        notToman
      />

      <OrderFactor invoicePrice={{ ...data?.invoicePrice, extraPrice }} />
      <SimpleButton
        handelClick={handleSubmitOrderFacture}
        disabled={orderData?.inVoiceStatusId === 2}
        loading={loading}
        type={paymentMode ? "submit" : "button"}
        className={
          orderData?.inVoiceStatusId === 2
            ? "opacity-100 border border-tomato bg-white text-tomato w-full text-base font-semibold"
            : "w-full full-tomato-btn"
        }
        text={
          orderData?.inVoiceStatusId === 2 ? "پرداخت شده" : "تایید و پرداخت"
        }
      />
      <PaymentModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default OrderFacture;

export const OrderFactureElements = ({ title, price, notToman }: any) => (
  <div className="flex-between-center my-5">
    <p>{title}</p>
    <p>
      {price.toLocaleString()} {notToman ? "" : "ریال"}
    </p>
  </div>
);
