import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  orderData: {
    orderId: undefined,
    senderCustomerId: undefined,
    senderCustomerName: undefined,
    senderPhoneId: undefined,
    fromCountryDevisionId: undefined,
    fromCountryDevisionName: undefined,

    // always fill
    pickupAddressId: undefined,
    // pickUpTimeFromDay: undefined,
    // pickUpTimeFromTime: undefined,
    // pickUpTimeToTime: undefined,
    pickUpDateDto: undefined,
    pickUpTimeFrom: undefined,
    pickUpTimeTo: undefined,
    // from address
    senderAddressId: undefined, // fill in address and also if choose hub and be default address
    senderAddressName: undefined,
    senderAddressTitle: undefined,
    senderAddressPostalCode: undefined,
    senderAddressDetail: undefined,
    senderLat: null,
    senderLng: null,

    // form hub
    pickupHubId: undefined,
    pickupHubName: undefined,
    pickupHubAddress: undefined,

    returnAddressId: undefined,
    returnAddressName: undefined,
    returnAddressTitle: undefined,
    returnAddressPostalCode: undefined,
    returnAddressDetail: undefined,
    returnLat: null,
    returnLng: null,

    returnHubId: undefined,
    returnHubName: undefined,
    returnHubAddress: undefined,

    // sender form office
    registrationHubId: undefined,
  },
  consignments: [],

  consignmentData: {
    consignmentId: undefined,
    toCountryDevisionId: undefined,
    toCountryDevisionName: undefined,

    receiverAddressId: undefined,
    receiverAddressName: undefined,
    receiverAddressTitle: undefined,
    receiverAddressPostalCode: undefined,
    receiverAddressDetail: undefined,
    lat: null,
    lng: null,
    deliveryAddressId: undefined,
    deliveryHubId: undefined,
    deliveryHubName: undefined,
    deliveryHubAddress: undefined,

    selectContentTypeId: undefined,
    selectContentTypeName: undefined,
    selectContent: undefined,
    content: { id: undefined },
    contentofOthers: undefined,
    declarativeWeight: "",
    declarativeVolume: "",
    declarativeLength: "",
    declarativeWidth: "",
    declarativeHeight: "",
    declarativeValue: "",
    numberOfPieces: "",

    receiverCustomerId: undefined,
    receiverProspectId: undefined,
    receiverCustomerName: undefined,
    receiverNationalCode: undefined,
    receiverNationalId: undefined,
    receiverPhoneId: undefined,
    receiverPhoneNumber: undefined,
    receiverCustomerType: undefined, //id
    receiverCustomerTypeName: undefined,

    price: undefined, // sender price
    ServiceName: undefined, // selected main service name
    ServiceId: undefined, // selected main service id
    suggestionServices: undefined, // selected main service
    suggestionServicesContainer: undefined, // all of main services
    supplementary: undefined, // selected extra service

    needToPack: false,
    typeOfPackage: null,
    needToReciveCostOfGoods: false,
    costOfGoods: "",
  },
  suggestionServices: [],
  EditedMode: false,
  ConsignmentStep: 1,
};

const OrderList = createSlice({
  initialState: initialState,
  name: "order",
  reducers: {
    setOrder: (state, action) => {
      state.orderData = { ...state.orderData, ...action.payload };
    },
    clearOrder: (state) => {
      state.orderData = {};
    },
    pushConsignments: (state, action) => {
      state.consignments = [...state.consignments, action.payload];
    },
    setNewConsignments: (state, action) => {
      state.consignments = action.payload;
    },
    setConsignmentStep: (state, action) => {
      state.ConsignmentStep = action.payload;
    },
    clearConsignments: (state) => {
      state.consignments = [];
    },
    setConsignment: (state, action) => {
      state.consignmentData = { ...state.consignmentData, ...action.payload };
    },
    clearConsignment: (state) => {
      state.consignmentData = initialState.consignmentData;
    },
    setSuggestionServices: (state, action) => {
      state.suggestionServices = action.payload;
    },
    clearSuggestionServices: (state) => {
      state.suggestionServices = [];
    },
    setEditedMode: (state, action) => {
      state.EditedMode = action.payload;
    },
    deleteConsignment: (state, action) => {
      state.consignments = state.consignments.filter(
        (i: any) => i.consignmentId !== action.payload
      );
    },
    editConsignments: (state, action) => {
      const newArray: Array<any> = [...state.consignments];
      const findIndex = state.consignments.findIndex(
        (i: any) => i.consignmentId === action.payload.consignmentId
      );
      if (findIndex < 0) {
        newArray.push(action.payload);
      } else {
        newArray[findIndex] = action.payload;
      }
      state.consignments = newArray;
    },
  },
});

export const {
  setOrder,
  setConsignment,
  setSuggestionServices,
  pushConsignments,
  setNewConsignments,
  clearConsignment,
  clearConsignments,
  clearOrder,
  clearSuggestionServices,
  setEditedMode,
  deleteConsignment,
  editConsignments,
  setConsignmentStep,
} = OrderList.actions;
export default OrderList.reducer;
