import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Modal from "../../../global/Modal/Modal";
import InputText from "../../../global/InputText/InputText";
import SimpleButton from "../../../global/SimpleButton/SimpleButton";
import { addEditDataAPI } from "../../../services/CRUDServices";
import { addEditUrls } from "../../../services/api.enums";
import { changePasswordValidation } from "global/Validation/Validation";

interface ChangePasswordModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const ChangePasswordModal = ({ open, setOpen }: ChangePasswordModalProps) => {
  const [Loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: changePasswordValidation,
    initialValues: {
      username: "",
      password: "",
      confirmPassword: "",
      tokenID: "",
      changePasswordType: {
        id: 0,
        text: "مشتریان",
      },
    },
    onSubmit: (values) => {
      setLoading(true);
      addEditDataAPI(addEditUrls.changePassword, "put", values)
        .then(() => {
          setOpen(false);
          handleReset();
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    },
  });
  const { values, errors, touched, handleChange, handleSubmit, handleReset }: any = formik;

  useEffect(() => {
    handleReset();
  }, [open, handleReset]);

  return (
    <Modal visible={open} setVisible={setOpen} title="تغییر رمز عبور">
      <form onSubmit={handleSubmit} className="flex gap-5 mt-8 flex-col">
        <InputText
          password
          important
          label="گذر واژه "
          values={values.password}
          name="password"
          handleChange={handleChange}
          error={touched.password && errors.password}
        />
        <InputText
          password
          important
          label="تایید گذرواژه"
          values={values.confirmPassword}
          name="confirmPassword"
          handleChange={handleChange}
          error={touched.confirmPassword && errors.confirmPassword}
        />
        <SimpleButton
          text="ثبت"
          className="full-tomato-btn w-full"
          loading={Loading}
          type="submit"
        />
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
