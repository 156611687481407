import { GiShoppingCart } from "react-icons/gi";
import { childs } from "./childs";

export const orderManagementRoutes: ISideBarRoutes = {
  parentName: "view_order_management",
  code: "1031",
  Icon: <GiShoppingCart size={25} />,
  label: " مدیریت سفارشات",
  childs: childs,
};
