import { MdDeliveryDining } from "react-icons/md";
import { childs } from "./childs";

export const flmManagementRoutes: ISideBarRoutes = {
  parentName: "view_flm_management",
  code: "",
  Icon: <MdDeliveryDining size={"25"} />,
  label: "FLM Management",
  childs: childs,
};
