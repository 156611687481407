import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterContradictionModel: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "contradiction",
  async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.contradiction,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true
    );

    return data.data;
  }
);

const initialState: any = {
  contradictionData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const ContradictionLists = createSlice({
  initialState: initialState,
  name: "contradiction",
  reducers: {
    clearContradiction: (state) => {
      state.contradiction = [];
    },
    setFilterContradiction: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterContradictionModel.fulfilled as any]: (state, action) => {
      state.contradictionData = action.payload;
      state.fetchPost = false;
    },
    [filterContradictionModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterContradictionModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterContradiction, clearContradiction } = ContradictionLists.actions;
export default ContradictionLists.reducer;
