import { MdPeopleAlt } from "react-icons/md";
import { childs } from "./childs";

export const employeeManagementRoutes: ISideBarRoutes = {
  parentName: "view_employee_management",
  code: "",
  Icon: <MdPeopleAlt size={"25"} />,
  label: "مدیریت پرسنل",
  childs: childs,
};
