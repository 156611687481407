import { useFormik } from "formik";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { addOrderValidation } from "global/Validation/Validation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setOrder } from "redux/OrderData/OrderData";
import { addOrderData } from "redux/order-management-new";
import { addEditDataAPI } from "services/CRUDServices";
import { addEditUrls } from "services/api.enums";
import { ContactNumberValidate } from "tools/validations/ErrorHelper";
import { useGetMoreConsignmentInfo } from "../hooks/useGetMoreConsignmentInfo";
import OrderHeader from "../order-header";
import ConsignmentInfo from "./consginemnt-info";
import Receiver from "./receiver";
import { clearOTP } from "redux/otp";

interface PostOrderFormProps {
  setOpenForm?: (val: boolean) => void;
  editModalData?: any;
  setEditModalData?: any;
}

export default function PostOrderForm({
  setOpenForm,
  editModalData,
  setEditModalData,
}: PostOrderFormProps) {
  const dispatch = useDispatch();
  const { orderManagementData } = useSelector((state: any) => state.orderData);
  const { consignments } = useSelector((state: any) => state.Order);
  const { handleGetData } = useGetMoreConsignmentInfo();
  const [isAddAndNew, setIsAndAndNew] = useState(false);
  const { state } = useLocation();
  const { customerId } = useSelector((state: any) => state.OTP);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: addOrderValidation(!!orderManagementData?.orderId),
    initialValues: editModalData
      ? {
          consignmentId: editModalData?.id,
          sender: {
            name: orderManagementData.name,
            nationalCode: orderManagementData.nationalCode ?? undefined,
            nationalId: orderManagementData.nationalId ?? undefined,
            mobile: orderManagementData.mobile,
            // mobile: "0" + orderManagementData.mobile,
          },
          receiver: {
            name: editModalData?.receiver?.name,
            mobile: "0" + editModalData?.receiver?.mobile,
            receiverCityId: editModalData?.receiver?.receiverCityId,
            address: editModalData?.receiver?.address,
          },
          contentType: editModalData?.contentTypeId,
          width: editModalData?.declarativeWidth,
          height: editModalData?.declarativeHeight,
          length: editModalData?.declarativeLength,
          weight: editModalData?.declarativeWeight,

          consignmentType: editModalData?.selectConsignmentType,

          cod: editModalData?.needToReciveCostOfGoods,
          packageId: editModalData?.typeOfPackage,
          cprNumber: editModalData?.cprNumber,
          needToPack: editModalData?.needToPack,
          postBack: editModalData?.postBack,
          insurance:
            editModalData?.value > 1000000
              ? { id: 1, text: "" }
              : {
                  id: 0,
                  text: "",
                },
          costOfGoods:
            editModalData?.costOfGoods === null
              ? ""
              : editModalData?.costOfGoods,
          value: editModalData?.declarativeValue,
          thirdPartyDelivery: editModalData?.thirdPartyDelivery,
          extraCosts: editModalData?.extraCosts,
          receiptId: state?.receiptId,
        }
      : {
          sender: orderManagementData
            ? orderManagementData
            : {
                name: "",
                nationalCode: "",
                // nationalId: "",
                mobile: "",
                senderCityId: undefined,
                code: "",
              },
          receiver: {
            name: "",
            mobile: "",
            receiverCityId: "",
            address: "",
            // order: "",
          },
          contentType: { id: 1, text: "غیر اسناد و مدارک" },
          width: "",
          height: "",
          length: "",
          weight: "",
          consignmentType: { id: 1, text: "بسته" },
          cod: false,
          packageId: {},
          cprNumber: "",
          needToPack: false,
          postBack: false,
          insurance: {
            id: 0,
            text: "",
          },
          costOfGoods: "",
          value: "",
          thirdPartyDelivery: false,
          extraCosts: "",
          receiptId: state?.receiptId,
        },

    validate: (values) => {
      const errors: any = {};

      const isValidCpr = String(values.cprNumber).length === 13 ? true : false;
      if (!isValidCpr) {
        errors.cprNumber = "شماره مرسوله باید 13 رقم باشد";
      }
   
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      addEditDataAPI(
        editModalData
          ? addEditUrls.editConsignment
          : addEditUrls.createConsignment,
        editModalData ? "put" : "post",
        {
          ...values,
          value: values.insurance?.id === 0 ? 1000000 : values.value,
          orderId: orderManagementData?.orderId,

          receiver: {
            ...values.receiver,
            receiverCityId: values.receiver?.receiverCityId?.id,
          },
          sender: {
            ...values.sender,
            code:!!customerId? values.sender.code || values.sender.mobile:undefined,
          },
          packageId: values.packageId?.id,
          customerId:customerId
        }
      )
        .then((res) => {
          setSubmitting(false);
          !isAddAndNew && setOpenForm && setOpenForm(false);
          setEditModalData && setEditModalData(null);
          const { id } = res.data?.payload?.order;
          handleGetData(orderManagementData?.orderId || id);
          if (!orderManagementData?.orderId) {
            dispatch(
              addOrderData({
                ...values.sender,
                orderId: orderManagementData?.orderId || id,
                openModal: isAddAndNew,
                easyOrder: true,
                senderCustomerId: res?.data?.payload?.sender?.id,
              })
            );

            dispatch(
              setOrder({
                orderId: orderManagementData?.orderId || id,
              })
            );

            setIsAndAndNew(false);
          }
          setValues({
            ...values,
            cprNumber: "",
            receiver: {
              name: "",
              mobile: "",
              receiverCityId: "",
              address: "",
            },
          });
        })
        .catch(() => {
          setSubmitting(false);
          setIsAndAndNew(false);
        });
    },
  });
  const { handleSubmit, setValues, isSubmitting } = formik;

  function submitAndNew() {
    if (!editModalData) {
      setIsAndAndNew(true);
      setTimeout(() => {
        handleSubmit();
      }, 200);
    } else {
      handleSubmit();
    }
  }

  function keyDown(e) {
    const code = e.keyCode;
    if (code === 120) {
      handleSubmit();
    } else if (code === 121) {
      submitAndNew();
    }
  }

  useEffect(() => {
    return () => {
      dispatch(clearOTP());
    };
  }, [dispatch]);


  return (
    <form onSubmit={handleSubmit} onKeyDown={keyDown} tabIndex={0}>
      {consignments.length === 0 ? <OrderHeader formik={formik} /> : null}
      <div className="bg-lightGray rounded-2xl my-4 p-8 max-w-6xl shadow ">
        <Receiver formik={formik} />
        <ConsignmentInfo formik={formik} submitAndNew={submitAndNew} />
        <div className="flex gap-6 justify-end">
          <SimpleButton
            type="submit"
            className="full-tomato-btn w-56 mt-6 mb-12"
            text={editModalData ? "ویرایش" : "ثبت و پرداخت"} //f9
            loading={isSubmitting}
          />
          {!editModalData && (
            <SimpleButton
              handelClick={submitAndNew}
              className="full-tomato-btn w-56 mt-6 mb-12"
              text="ثبت  و جدید" //f10
              loading={isSubmitting}
            />
          )}
        </div>
      </div>
    </form>
  );
}
