import { useEffect } from 'react'
import { Html5QrcodeScanner } from 'html5-qrcode'


import type { Html5QrcodeScannerConfig } from 'html5-qrcode/esm/html5-qrcode-scanner'
import { QrScannerProps } from './interface'

export default function QrScanner({
  id,
  fps,
  qrbox = { height: 200, width: 200 },
  aspectRatio = 1,
  verbose = false,
  onFailed = undefined,
  onSuccess,
}:QrScannerProps) {
  const qrcodeRegionId = id || 'scanner'

  const createConfig = () => {
    const config: Html5QrcodeScannerConfig = {
      fps: 10,
      showTorchButtonIfSupported: true,
      videoConstraints: {
        facingMode: { exact: 'environment' },
      },
    }
    
  
    if (fps) config.fps = fps
    if (qrbox) config.qrbox = qrbox
    if (aspectRatio) config.aspectRatio = aspectRatio

    return config
  }

  useEffect(() => {
    const config = createConfig()
    const scanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose)
    // scanner.start({ facingMode: { exact: "environment"} }, config);
    scanner.render(onSuccess, onFailed)

    return () => {
      scanner.clear()
    }
  }, [])

  return <div id={qrcodeRegionId} className='flex justify-center items-center flex-col' />
}
