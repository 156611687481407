import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { RiArrowDownSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "./views/ChangePasswordModal";
import ProfileModal from "./views/ProfileModal";
import { Helmet } from "react-helmet";
import { BiMenu } from "react-icons/bi";
import DrawerMobile from "components/DrawerMobile/DrawerMobile";
import { useAuth } from "react-oidc-context";
// import { mailLogout } from "index";
const Breadcrumb = ({
  curentPage,
  beforePage,
}: {
  curentPage?: string;
  beforePage?: string;
}) => {
  const auth = useAuth();

  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_OIDC);
    localStorage.clear();
    auth.clearStaleState();
    auth.removeUser();
    window.location.href = process.env.REACT_APP_LOGOUT;
  };

  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [OpenProfile, setOpenProfile] = useState(false);
  const [OpenChangePassword, setOpenChangePassword] = useState(false);
  const handleBlur = (e: {
    currentTarget: { contains: (arg0: any) => any };
    relatedTarget: any;
  }) => {
    if (!e.currentTarget.contains(e.relatedTarget)) setToggle(false);
  };

  const [drawerShow, setDrawerShow] = useState(false);

  return (
    <>
      <div className="flex-between-center mb-8 flex-wrap">
        <Helmet>
          <title>{curentPage}</title>
        </Helmet>
        <div className="flex-start-center">
          {beforePage && (
            <>
              <button className="border-none" onClick={() => navigate(-1)}>
                <h2 className="text-lg Max-sm:!text-[14px] Max-sm:font-bold">
                  {beforePage}
                </h2>
              </button>
              <span className="px-3">{"/"}</span>
            </>
          )}
          <h2 className="text-xl Max-sm:!text-[14px] Max-sm:font-bold">
            {curentPage}
          </h2>
        </div>
        <div className="flex-start-center  gap-3">
          <AiOutlineUser size={23} />
          <p className="text-base">{localStorage.getItem("userName") as any}</p>
          <div className="relative flex justify-center" onBlur={handleBlur}>
            <button
              className={`${
                toggle && "rotate-180"
              } transition-all duration-500`}
              onClick={() => setToggle(!toggle)}
            >
              <RiArrowDownSLine size={23} />
            </button>
            <button
              className="border-none hidden Max-md:block Max-sm:w-40% mr-4"
              onClick={() => setDrawerShow((before) => !before)}
            >
              <BiMenu className="" size={27} />
            </button>

            {toggle ? (
              <div className="ToggleContainer  !top-8 -right-32 w-36">
                <button
                  className="ToggleElements"
                  onClick={() => navigate("/")}
                >
                  داشبورد
                </button>
                <button
                  className="ToggleElements"
                  onClick={() => setOpenProfile(true)}
                >
                  پروفایل
                </button>
                <button
                  className="ToggleElements"
                  onClick={() => setOpenChangePassword(true)}
                >
                  تغییر رمز عبور
                </button>
                <button className="ToggleElements" onClick={handleLogout}>
                  خروج
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <DrawerMobile show={drawerShow} setShow={setDrawerShow} />
      <ProfileModal open={OpenProfile} setOpen={setOpenProfile} />
      <ChangePasswordModal
        open={OpenChangePassword}
        setOpen={setOpenChangePassword}
      />
    </>
  );
};

export default Breadcrumb;
