import { lazy } from "react";

const RullEngine = lazy(() => import("pages/RullEngine/RullEngine"));
export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/RullEngine",
    component: <RullEngine />,
    label: "رول انجین ها",
    permissions: "view_rule_engine",
  },
];
