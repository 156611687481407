import { lazy } from "react";

const CollectManagement = lazy(
  () => import("pages/CollectManagement/CollectManagement")
);
const DeliveryManagement = lazy(
  () => import("pages/DeliveryManagement/DeliveryManagement")
);
const TripManagement = lazy(
  () => import("pages/TripManagement/TripManagement")
);
export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/collect-management",
    component: <CollectManagement />,
    label: "مدیریت جمع‌آوری",
    permissions: "view_collect_management",
  },
  {
    code: "",
    to: "/delivery-management",
    component: <DeliveryManagement />,
    label: "مدیریت تحویل",
    permissions: "view_delivery_management",
  },
  {
    code: "",
    to: "/trip-management",
    component: <TripManagement />,
    label: "مدیریت سفر",
    permissions: "view_trip_management",
  },
];
