import { lazy } from "react";

const Personnel = lazy(() => import("pages/Personnel/Personnel"));
export const childs: Array<ISideBarChild> = [
  {
    code: "1002",
    to: "/personnel",
    component: <Personnel />,
    label: "پرسنل",
    permissions: "view_employee_management",
  },
];
