import { ClipLoader } from "react-spinners";

const Spinner = () => {
  return (
    <div className="h-20 cnetering w-full text-center mt-6">
      <ClipLoader color="#EF5644" />
    </div>
  );
};

export default Spinner;
