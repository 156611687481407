import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import { filterUrls } from "../../services/api.enums";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";




export const filterTimeShift=createAsyncThunk('filter',async(body:any)=>{


  
    let data:any ;
    try {
      data = await filterTableDataAPI(filterUrls.trmManagement as any, body?.pageNumber || 1,{
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
      },true,10,body.selecthub?false:true);
      } catch (error) {
        ErrorAlert(error?.response?.data?.errors?.message)
      }
    return data.data;
})



export interface StateData {
    timeShiftLists: Array<any>;
    fetchPost?: boolean;
    errorMessage: null | string;
    isUpdating: Boolean;
    filter: object;
  }
  

const initialState:StateData= {
    timeShiftLists:[],
    fetchPost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{}    
}

const timeShiftData = createSlice({
    initialState: initialState,
    name: "timeShiftLists",
    reducers: {
        updating: (state:any, action:any) => {
            state.isUpdating = action.payload;
          },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
          clearData:(state)=>{
            state.timeShiftLists=[]
          }
    },
    extraReducers: {
        [filterTimeShift.fulfilled as any]: (state, action) => {
    
            state.timeShiftLists = action.payload.payload;
            state.fetchPost = false;
        },
        [filterTimeShift.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [filterTimeShift.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter,clearData } = timeShiftData.actions;
export default timeShiftData.reducer