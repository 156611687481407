import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { fetchJsonWithKey } from "../../tools/helper/ConcatAPIs";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls, getUrls } from "../../services/api.enums";

export const vehiclePlanData: AsyncThunk<{}, {}, {}> = createAsyncThunk("vehiclePlanList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.vehiclePlan,
    body.pageNumber,
    {
      ...body,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );

  let content: any = [];

  content = await Promise.all(
    data.payload.content.map((item: any) =>
      fetchJsonWithKey(`${getUrls.vehicleinfo}/${item.selectvehicle.id}`, item, "selectvehicle")
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.drivers}${item?.selectfirstDriver?.id}`,
        item,
        "selectfirstDriver"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(`${getUrls.trip}/${item?.selecttrip?.id}`, item, "selecttrip")
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(`${getUrls.route}/${item?.selecttrip?.selectroute?.id}`, item, "selectroute")
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(`${getUrls.hub}${item?.selectResourceHub?.id}`, item, "selectResourceHub")
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.hub}${item?.selectdestinationHub?.id}`,
        item,
        "selectdestinationHub"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.dispatchShiftById}/${item?.selectdispatchShift?.id}`,
        item,
        "selectdispatchShift"
      )
    )
  ).then((values) => values);

  return { content, totalElements: data.payload.totalElements };
  // return content.filter(Boolean);
});

const initialState: any = {
  vehiclePlanList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const VehiclePlanList = createSlice({
  initialState: initialState,
  name: "vehiclePlanList",
  reducers: {
    clearVehiclePlan: (state) => {
      state.vehiclePlanList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [vehiclePlanData.fulfilled as any]: (state, action) => {
      state.vehiclePlanList = action.payload;
      state.fetchPost = false;
    },
    [vehiclePlanData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [vehiclePlanData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearVehiclePlan, setFilter } = VehiclePlanList.actions;
export default VehiclePlanList.reducer;
