import { MdManageAccounts } from "react-icons/md";
import { childs } from "./childs";

export const basicInformationRoutes: ISideBarRoutes = {
  parentName: "view_base_information_management",
  Icon: <MdManageAccounts size={"25"} />,
  code: "",
  label: "مدیریت اطلاعات پایه",
  childs: childs,
};
