import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterDataAPI } from "services/CRUDServices";
import { getUrls } from "services/api.enums";

export const filterPaymentActionModel: AsyncThunk<{}, {}, {}> =
  createAsyncThunk("paymentAction", async (body: any) => {
    const data = await filterDataAPI(getUrls.codPaymentHitory,body,true,false);

    return data.data;
  });

const initialState: any = {
  paymentActionData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const PaymentActionSlice = createSlice({
  initialState: initialState,
  name: "paymentAction",
  reducers: {
    clearPaymentAction: (state) => {
      state.paymentActionData = [];
    },
    setFilterPaymentAction: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterPaymentActionModel.fulfilled as any]: (state, action) => {
      state.paymentActionData = action.payload;
      state.fetchPost = false;
    },
    [filterPaymentActionModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterPaymentActionModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterPaymentAction, clearPaymentAction } =
  PaymentActionSlice.actions;
export default PaymentActionSlice.reducer;
