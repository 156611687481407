import { MdTimer } from "react-icons/md";
import { childs } from "./childs";

export const trmManagementRoutes: ISideBarRoutes = {
  parentName: "view_TRM_management",
  code: "",
  Icon: <MdTimer size={"25"} />,
  label: "TRM Management",
  childs: childs,
};
