import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addEditUrls, getUrls } from "../../services/api.enums";
import { filterDataAPI, filterTableDataAPI } from "../../services/CRUDServices";
import { getAPI } from "./../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";

export const BagData = createAsyncThunk("bags", async (body: any) => {
  try {
    let secondDataBag = addEditUrls.GetSecondDataBag;

    const firstApi: any = await filterTableDataAPI(getUrls.BagTable, body.pageNumber, {
      bagNumber: body?.bagNumber ? body?.bagNumber : "",
      selectBagType: body?.selectBagType ? body?.selectBagType : null,
      sourceHubName: body?.sourceHubName ? body?.sourceHubName : "",
      consignmentsDestinationHubName: body?.consignmentsDestinationHubName
        ? body?.consignmentsDestinationHubName
        : "",
      destinationHubName: body?.destinationHubName ? body?.destinationHubName : "",
      carrierNumber: body?.carrierNumber ? body?.carrierNumber : "",
      // hubList: body?.hublist ? body?.hublist : "",
      isActive: body?.isActive ? body?.isActive : null,
      cprNumber:body?.cprNumber ?? null,
      status: body?.statusBag ? body?.statusBag : null,
    });

    const firstApiResponse = firstApi.data.payload.content.map((item: any) => {
      return {
        ...item,
        mergeId: item.id,
        secondDataBag: `${secondDataBag}/${item.id}`,
      };
    });
   
    const fetchbag = async (item: any) =>
      await getAPI(item.secondDataBag, true)
        .then((res: any) => {
          return {
            secondData: res.data.payload,
            mergeId: item.id,
          };
        })
        .catch((error) => {
          ErrorAlert(error?.response?.data?.errors?.message);
        });


        const fetchTrip = async (item: any) =>await item?.selecttrip?.id && getAPI(getUrls.trip + `/${item?.selecttrip?.id}`)
          .then((res: any) => {
            return {
              tripName: res.data.payload?.tripNo,
              mergeId: item.id,
            };
          })
          .catch((error) => {
            ErrorAlert(error?.response?.data?.errors?.message);
          });


    const fetchException = async (item: any) =>
      await filterDataAPI(
        addEditUrls.BagException,
        {
          selectBag: { id: item.id },
        },
        true,
        false
      ).then((ress) => {
        return {
          allException: ress.data.payload,
          mergeId: item.id,
        };
      });

    let bagDatas: any = [];
    let bagException = [];
    let bagTrip:any = [];

    bagTrip = await Promise.all(
      firstApiResponse.filter((hub: any) => hub.id !== null).map((item: any) => fetchTrip(item))
    ).then((values) => values.filter(Boolean));

    bagDatas = await Promise.all(
      firstApiResponse.filter((hub: any) => hub.id !== null).map((item: any) => fetchbag(item))
    ).then((values) => values.filter(Boolean));

    bagException = await Promise.all(
      firstApiResponse
        .filter((hub: any) => hub.id !== null)
        .map((item: any) => fetchException(item))
    ).then((values) => values.filter(Boolean));

    let result = [] as any;
    result = [
      ...[...firstApiResponse, ...bagDatas, ...bagException,...bagTrip]
        .reduce(
          (acc, curr) => acc.set(curr.mergeId, { ...acc.get(curr.mergeId), ...curr }),
          new Map()
        )
        .values(),
    ];

    firstApi.data.payload.content = result;
    return firstApi.data.payload;
  } catch (err) {}
});

const initialState = {
  BagsLists: [],
  fetchpost: false,
  errorMessage: "",
  filter: [],
};

const BagsSlice = createSlice({
  name: "bags",
  initialState,
  reducers: {
    clearBag: (state) => {
      state.BagsLists = [];
    },
    changeSwitch: (state: any, { payload }) => {
      const filterSwitch = state.BagsLists.content?.filter((items) => items?.id === payload)[0];
      filterSwitch.isActive = !filterSwitch.isActive;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [BagData.fulfilled as any]: (state, action) => {
      state.BagsLists = action.payload;
      state.fetchpost = false;
    },
    [BagData.pending as any]: (state) => {
      state.fetchpost = true;
    },
    [BagData.rejected as any]: (state) => {
      state.fetchpost = false;
      state.errorMessage = "wrong";
    },
  },
});
export const { clearBag, changeSwitch, setFilter } = BagsSlice.actions;
export default BagsSlice.reducer;
