import { lazy } from "react";

const OrderManagement = lazy(
  () => import("pages/OrderManagement/OrderManagement")
);
const OrderReceipt = lazy(() => import("pages/order-receipt"));
export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/order-management",
    component: <OrderManagement />,
    label: " مدیریت سفارش",
    permissions: "view_order_management",
  },
  {
    code: "",
    to: "order-receipt",
    component: <OrderReceipt />,
    label: "رسید دریافت ",
    permissions: "receipt",
  },

];
