interface CheckboxProps {
  title?: string;
  handleChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  name?: string;
  blure?: React.FocusEventHandler<HTMLInputElement>;
  values?: boolean;
  WrapperClassName?: string;
  isDisabled?: boolean;
  labelClassName?: string;
  className?: string
}

const Checkbox = ({
  title,
  handleChange,
  name,
  blure,
  values,
  isDisabled,
  WrapperClassName,
  labelClassName,
  className
}: CheckboxProps) => {
  return (
    <div className={`flex-start-start gap-2 ${WrapperClassName}`}>
      <input
        type="checkbox"
        className={`accent-tomato w-[16px] h-4 mb-5 mt-1 ${className}`}
        onChange={handleChange}
        name={name}
        onBlur={blure}
        checked={values}
        style={{ borderRadius: "50%" }}
        disabled={isDisabled}
      />
      <label className={`text-darkGray mb-5 ${labelClassName}`}>{title}</label>
    </div>
  );
};

Checkbox.defaultProps = {
  WrapperClassName: "w-60",
};

export default Checkbox;
