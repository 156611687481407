import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";
import { ErrorAlert } from "global/alert/Alert";


export const ServiceData=createAsyncThunk('servicelist',async(body:any)=>{
    let data;
    try {
        data = await filterTableDataAPI(filterUrls.serviceDefine ,body?.pageNumber, {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
        });
      } catch (error) {
        ErrorAlert(error?.response?.data?.errors?.message)
      }
    return data.data;
    })



const initialState:any= {
    postLists:[],
    fetchpost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{} 
    
}

const ServiceDefineList = createSlice({
    initialState: initialState,
    name: "servicelist",
    reducers: {
        clearServiceDefine: (state) => {
            state.postLists = [];
          },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
    },
    extraReducers: {
        [ServiceData.fulfilled as any]: (state, action) => {
            state.postLists = action.payload.payload;
            state.fetchPost = false;
        },
        [ServiceData.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [ServiceData.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter,clearServiceDefine } = ServiceDefineList.actions;
export default ServiceDefineList.reducer