import * as turf from "@turf/turf";
import { useEffect, useRef, useState } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ActionMultiSelect
} from "redux/optime/optime";
import { getAPI } from "services/CRUDServices";
import { getUrls } from "services/api.enums";
import { clearPolygon, convertResponse } from "../utils";
import LoadingMap from "../utils/loading";
import AssignVehicle from "./AssignVehicleForm";
import AssignedMap from "./AssignedMap";
import RenderUnAssignPins from "./render-unAssign-pins";



interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
}
const MapOperation = ({ optimeData, setOptimeData }: MapOperationProps) => {
  const { editZone, addZone } = useSelector((state: any) => state.optimeModal);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedPins, setSelectedPins] = useState([]);
  const [createdPolygon, setCreatedPolygon] = useState<any>("");
  const consignemntRef = useRef(null);
  const { id } = useParams();
  const [editCurrentPolygon, setEditCurrentPolygon] = useState();
  const featureGroupRef = useRef(null);
  const LeafletAddControllerRef = useRef<any>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);



  function handlePolygonActions(e: any, isEdit: boolean) {
    if (!editZone) {
      clearPolygon(featureGroupRef.current, 1);
      let createdZone;
      if (isEdit) {
        const {
          layers: { _layers },
        } = e;
        const layers: any = Object.values(_layers)[0];
        const updatedZone = [
          layers?._latlngs[0].map((item: any) => {
            return [item.lat, item.lng];
          }),
        ];
        const flattenArr = updatedZone.flat();
        flattenArr.push(flattenArr[0]);
        createdZone = flattenArr;
      } else {
        createdZone = [
          ...e.layer._latlngs[0].map((item: any) => {
            return [item.lat, item.lng];
          }),
          [e.layer._latlngs[0][0].lat, e.layer._latlngs[0][0].lng],
        ];
      }
      if (createdZone[0] !== undefined) {
        const polygon = turf.polygon([createdZone]);

        const pins = consignemntRef.current.consignmentList;
        const selectPins = [];
        pins.forEach((element) => {
          if (!element.latitude || !element.longitude) {
            toast.error(`مرسوله ${element.cprNumber} موقعیت جغرافیایی ندارد`, {
              autoClose: false,
            });
            return;
          }
          if (
            turf.booleanPointInPolygon(
              [element.latitude, element.longitude],
              polygon
            )
          ) {
            selectPins.push(element);
          }
        });
        setSelectedPins(selectPins);
        setCreatedPolygon(createdZone);

        // if(selectPins.length===0) {
        //   toast.warn("هیچ مرسوله ای انتخاب نشده است")
        //       //@ts-ignore
        //   LeafletAddControllerRef?.current._toolbars.draw._modes.polygon.handler.completeShape()
        //   //     //@ts-ignore
        //   // LeafletAddControllerRef?.current._toolbars.draw._modes.polygon.handler.revertLayers()
        //       //@ts-ignore
        //   LeafletAddControllerRef?.current._toolbars.draw._modes.polygon.handler.disable()

        //   return
        // }
        // clearPolygon(featureGroupRef.current);
        setAssignModal(true);
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    getAPI(getUrls.planingConsignment + id)
      .then((res) => {
        const convert = convertResponse(res);
        setOptimeData(convert);
        consignemntRef.current = convert;
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id]);

  const onMountedRect = (e: any) => {
    LeafletAddControllerRef.current = e;
  };

  useEffect(() => {
    dispatch(ActionMultiSelect(optimeData?.polygons));
    consignemntRef.current = optimeData;
  }, [optimeData]);

  useEffect(() => {
    addZone &&
      LeafletAddControllerRef?.current._toolbars.draw._modes.polygon.handler.enable();

    !addZone &&
      LeafletAddControllerRef?.current._toolbars.draw._modes.polygon.handler.disable();
  }, [addZone]);



  return (
    <>
     <RenderUnAssignPins pins={optimeData?.consignmentList}/>
      <LoadingMap loading={loading} />
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          onCreated={(event) => handlePolygonActions(event, false)}
          onMounted={onMountedRect as any}
          onEdited={(event) => handlePolygonActions(event, true)}
          position="topleft"
          edit={{ edit: true }}
          draw={{
            rectangle: false,
            polyline: false,
            marker: false,
            circle: false,
            circlemarker: false,

            polygon: {
              allowIntersection: false,
              showLength: true,
              showArea: true,
            },
          }}
        />
      </FeatureGroup>
      <AssignVehicle
        setOpen={setAssignModal}
        open={assignModal}
        pins={selectedPins}
        polygon={createdPolygon}
        setOptimeData={setOptimeData}
        optimeData={optimeData}
        customId={optimeData?.polygons?.length}
        currentPolygon={LeafletAddControllerRef.current}
        featureGroupRef={featureGroupRef.current}
        editCurrentPolygon={editCurrentPolygon}
        setEditCurrentPolygon={setEditCurrentPolygon}
      />

      <AssignedMap
        selectedPolygons={selectedPolygons}
        setSelectedPolygons={setSelectedPolygons}
        setSelectedPins={setSelectedPins}
        setCreatedPolygon={setCreatedPolygon}
        setEditCurrentPolygon={setEditCurrentPolygon}
        assignPolygon={optimeData}
        setOptimeData={setOptimeData}
        setAssignModal={setAssignModal}
      />
    </>
  );
};

export default MapOperation;

