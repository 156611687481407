import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { fetchJsonWithKey } from "../../tools/helper/ConcatAPIs";
import { filterUrls, getUrls } from "../../services/api.enums";

export const deliveryExcelData: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "deliveryExcelList",
  async (body: any) => {
    let { data } = await filterTableDataAPI(
      filterUrls.filterDelivery,
      body.pageNumber,
      {
        ...body,
        pageNumber: body.pageNumber,
        pageSize: 10,
      },
      true,
      body.pageSize,
      false
    );

    // let content: any = [];

    // content = await Promise.all(
    //   data.payload.content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.telephone}/${item?.receiverPhoneId}`,
    //       item,
    //       "recieverPhone"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.address}${item?.receiverAddressId}`,
    //       item,
    //       "recieverAddress"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${
    //         item?.receiverProspectId ? getUrls.findByProspect : getUrls.cusotmer
    //       }/${
    //         item?.receiverProspectId
    //           ? item?.receiverProspectId
    //           : item?.receiverCustomerId
    //       }`,
    //       item,
    //       "receiverCustomer"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.hub}${item?.deliveryHubId}`,
    //       item,
    //       "deliveryHub"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.hub}${item?.currentHubId}`,
    //       item,
    //       "currentHub"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.countryDevision}${item?.selectRegion?.id}`,
    //       item,
    //       "selectRegion"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.findByTripNumber}${item?.deliveryTripNumber}`,
    //       item,
    //       "deliveryTripNumber"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.drivers}${item?.deliveryTripNumber?.selectfirstDriver?.id}`,
    //       item,
    //       "selectfirstDriver"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.contentOfConsignment}${item?.contentId}`,
    //       item,
    //       "contentId"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.findByConsignmentService}${item?.id}`,
    //       item,
    //       "serviceType"
    //     )
    //   )
    // ).then((values) => values);

    // content = await Promise.all(
    //   content.map((item: any) =>
    //     fetchJsonWithKey(
    //       `${getUrls.service}${item?.serviceType?.id}`,
    //       item,
    //       "serviceType"
    //     )
    //   )
    // ).then((values) => values);

    return { content:data.payload.content, totalElements: data.payload.totalElements };
  }
);

const initialState: any = {
  deliveryExcelList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const DeliveryExcelList = createSlice({
  initialState: initialState,
  name: "deliveryExcelList",
  reducers: {
    updating: (state: any, action: any) => {
      state.isUpdating = action.payload;
    },
    clearDelivery: (state) => {
      state.deliveryExcelList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [deliveryExcelData.fulfilled as any]: (state, action) => {
      state.deliveryExcelList = action.payload;
      state.fetchPost = false;
    },
    [deliveryExcelData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [deliveryExcelData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearDelivery, setFilter } = DeliveryExcelList.actions;
export default DeliveryExcelList.reducer;
