export const ConsignmentsPaymentColumns = [
  {
    accessor: "index",
    header: "ردیف",
    className: "col-span-2 ",
  },
  {
    accessor: "declarativeVolume",
    header: "ابعاد مرسوله",
  },
  {
    accessor: "declarativeValue",
    header: "ارزش مرسوله",
  },
  {
    accessor: "toCountryDevisionName",
    header: "مقصد",
  },
  {
    accessor: "receiverCustomerName",
    header: "تحویل گیرنده",
  },
  {
    accessor: "ServiceName",
    header: "سرویس ارسال",
  },
  {
    accessor: "price",
    header: "مبلغ",
  },
  {
    accessor: "payBySender",
    header: "پرداخت با فرستنده",
    className: "col-span-2 ",
    showheader: true,
  },
  {
    accessor: "payByReciever",
    header: "پرداخت با گیرنده",
    className: "col-span-2 ",
    showheader: true,
  },
  {
    accessor: "otherPrice",
    header: "سایر هزینه ها",
    className: "col-span-2 ",
    showheader: true,
  },
];
