import { FC } from "react";
import Select from "react-select";

interface InputSelectProps {
  label?: string;
  name?: string;
  // blure?: React.FocusEventHandler<HTMLInputElement>;
  values?: any;
  options?: any;
  handleChange?: any;
  error?: any;
  important?: boolean;
  wrapperClassName?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  positionFixed?: boolean;
  placeholder?: string;
  isCustomOption?: boolean;
  customFilter?: any;
  loading?: boolean;
  onFocus?: () => void;
  icon?:React.ReactNode
  handleKeyDown?:any
}

const InputSelect: FC<InputSelectProps> = ({
  handleKeyDown,
  label,
  name,
  // blure,
  isClearable,
  values,
  options,
  handleChange,
  error,
  important,
  wrapperClassName = "",
  isMulti,
  isDisabled,
  positionFixed,
  placeholder,
  isCustomOption,
  customFilter,
  loading,
  onFocus,
  icon
}): JSX.Element => {
  //This is custom Style for react-select
  const style = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base: any, state: any) => ({
      ...base,
      border: state.isFocused
        ? error
          ? "1px solid  #d32f2f"
          : " 1px solid #ababab"
        : error
        ? "1px solid  #d32f2f"
        : state.isDisabled
        ? "1px solid #ababab"
        : " 1px solid #ababab",
      borderRadius: state.isFocused ? "0.5rem" : "0.5rem",
      height: state.isFocused ? "2.5rem" : "2.5rem",
      "&:hover": {
        border: state.isFocused
          ? error
            ? "1px solid  #d32f2f"
            : " 1px solid #ababab"
          : error
          ? "1px solid  #d32f2f"
          : " 1px solid #ababab",
      },
      // This line disable the blue border

      background: state.isDisabled ? "#ffffff" : "",
      boxShadow: "none",
      width: "100%",
    }),
    option: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: state.isSelected
          ? "#FFEAE9"
          : state.isFocused
          ? "#F9FAFC"
          : "",
        color: state.isDisabled ? "#0000003d" : "rgb(117 ,117 ,117)",
        zIndex: 200,
      };
    },
  };

  return (
    <div
      className={`relative  Max-md:w-full wrapperResponsive ${wrapperClassName} ${
        isDisabled && "opacity-40"
      }`}
    >
      <label
        className={` absolute top-[-16px] right-5 bg-white z-10  px-2  text-sm ${
          error ? "text-red" : "text-darkGray"
        }`}
      >
        {label}{" "}
        <span className="text-tomato font-extrabold text-lg h-4">
          {important ? "*" : " "}
        </span>{" "}
      </label>
      <Select
        menuPosition={positionFixed ? "fixed" : "absolute"}
        isClearable={isClearable}
        isMulti={isMulti}
        isLoading={loading || (options ? false : true)}
        // value={

        //   options ? options.find((option: any) => option.label === values) : ""
        // }
        value={{
          value: values?.id,
          label: values?.text,
        }}
        // defaultInputValue={values?.text}
        onChange={(option: any) =>
          handleChange(name, {
            id: isCustomOption ? option?.id : option?.value,
            text: option?.label,
          })
        }
        styles={style}
        options={
          options
            ? isCustomOption
              ? options
              : options.map((res: any) => {
                  return {
                    label: res?.text,
                    value: res?.id,
                  };
                })
            : []
        }
        isDisabled={isDisabled}
        placeholder={placeholder}
        isRtl
        name={name}
        className=" focus:outline-none flex"
        filterOption={customFilter}
        onFocus={onFocus}
        onKeyDown={handleKeyDown}
      />

      <div className="absolute top-3 left-12">
        {icon}
      </div>
      <p className="text-red text-xs pr-3 h-4 mt-1">{error?.text}</p>
    </div>
  );
};

InputSelect.defaultProps = {
  wrapperClassName: "w-60",
};
export default InputSelect;
