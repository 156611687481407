import { ReturnText } from "../functions/Methods";
import { filterUrls, getUrls } from "../../services/api.enums";
import { filterTableDataAPI, getAPI } from "../../services/CRUDServices";
import { provinceLocation } from "../../models/Location";

export const getDriver = async (body?: any) => {
  try {
    let res = await filterTableDataAPI(
      `${filterUrls.driverMangement}`,
      1,
      body ?? {},
      true,
      1000,
      true
    );
    let content: any = await Promise.all(
      res.data.payload.content.map((item: any) =>
        fetchJson(`${getUrls.drivers}${item.selectdriver.id}`, item)
      )
    ).then((values) => {
      let arr = values
        .filter((i) => i !== undefined)
        .map((item) => ReturnText(item));
      return arr;
    });
    return content;
  } catch (error) {}
};

export const fetchJson = async (url: string, item: any) =>
  await getAPI(url)
    .then((res: any) => {
      return res.data.payload
        ? { ...item, ...res.data.payload }
        : { ...item, ...res.data };
    })
    .catch((e) => {
      return item;
    });

export const fetchJsonWithKey = async (url: string, item: any, key: string) =>
  url.includes("undefined") || url.includes("null")
    ? item
    : await getAPI(url)
        .then((res: any) => {
          return res.data.payload
            ? { ...item, [key]: res.data.payload }
            : { ...item, [key]: res.data };
        })
        .catch((e) => {
          return item;
        });

export const fetchJsonWithKeyAndFilter = async (
  url: string,
  item: any,
  key: string,
  array: any
) =>
  url.includes("undefined") || url.includes("null")
    ? item
    : await getAPI(url)
        .then((res: any) => {
          return res.data.payload
            ? {
                ...item,
                [key]:
                  res.data.payload.length > 0
                    ? array.filter(
                        (el1: any) =>
                          !res.data.payload.some(
                            (el2: any) => el2.id === el1.id
                          )
                      )
                    : array,
              }
            : {
                ...item,
                [key]:
                  res.data.length > 0
                    ? array.filter(
                        (el1: any) =>
                          !res.data.payload.some(
                            (el2: any) => el2.id === el1.id
                          )
                      )
                    : array,
              };
        })
        .catch((e) => {
          return item;
        });

export const getCities = async () => {
  return getAPI(`${getUrls.findByCity}`).then((res) =>
    res.data.payload.filter(
      (item: any) =>
        !Object.keys(provinceLocation).some((keyItem) =>
          item.text.includes(keyItem)
        )
    )
  );
};

// export const getDifferenceTwoData =(date1:any,date2:any)=>{
//   const convertDate1:any = new Date(date1.day+"/"+date1.month+"/"+date1.year);
// const convertDate2:any = new Date(date2.day+"/"+date2.month+"/"+date2.year);
// const diffTime = Math.abs(convertDate2 - convertDate1);
// const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
// }
