import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterConsignmentPlanning: AsyncThunk<{}, {}, {}> =
  createAsyncThunk("consignmentPlanning", async (body: any) => {
    const data = await filterDataAPI(
      filterUrls.getConsignemnt,
      // body.pageNumber,

      { ...body, pageNumber: undefined, pageSize: undefined },
      true
    );
    return   data.data.map(item=>({...item,region: item?.region?.text}));
  });

const initialState: any = {
  planingConsignmentData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
  consignemntsfilter: [],
  isFliter: false,
};

const PlaningConsignmentLists = createSlice({
  initialState: initialState,
  name: "consignmentPlanning",
  reducers: {
    clearConsignmentPlanningData: (state) => {
      state.planingConsignmentData = [];
      state.consignemntsfilter = [];
    },
    setFilterConsignmentPlanningData: (state, action) => {
      state.filter = action.payload;
    },
    deletePlaningConsignment: (state, action) => {
      if (state.consignemntsfilter.length !== 0) {
        state.consignemntsfilter = state.consignemntsfilter.filter(
          (item) => item.id !== action.payload
        );
        state.planingConsignmentData = state.planingConsignmentData.filter(
          (item) => item.id !== action.payload
        );
      } else {
        state.planingConsignmentData = state.planingConsignmentData.filter(
          (item) => item.id !== action.payload
        );
      }
    },
    addToPlaningConsignment: (state, action) => {
      state.planingConsignmentData = [
        ...state.planingConsignmentData,
        action.payload,
      ];
    },

    manualConsignmentFilter: (state, { payload }) => {
      //remove undefind object
      Object.keys(payload).forEach(
        (key) =>
          (payload[key] === undefined || payload[key] === "") &&
          delete payload[key]
      );

      const filteredData = state.planingConsignmentData.filter((item) =>
        Object.entries(payload).every(([key, value]) =>
          //@ts-ignore
          value.includes(item[key])
        )
      );
      state.consignemntsfilter = filteredData;
    },
    toggleIsFilter: (state, { payload }) => {
      state.isFliter = payload;
    },
  },
  extraReducers: {
    [filterConsignmentPlanning.fulfilled as any]: (state, action) => {
      state.planingConsignmentData = action.payload;
      state.fetchPost = false;
    },
    [filterConsignmentPlanning.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterConsignmentPlanning.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const {
  setFilterConsignmentPlanningData,
  clearConsignmentPlanningData,
  deletePlaningConsignment,
  addToPlaningConsignment,
  manualConsignmentFilter,
  toggleIsFilter,
} = PlaningConsignmentLists.actions;
export default PlaningConsignmentLists.reducer;

