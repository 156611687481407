import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidMap } from "react-icons/bi";
import { changeSelectTab } from "redux/optime/optime";

import InfoMdlPolygon from "pages/optimePanel/views/informationSidebar/views/InfoMdlPolygon";

import InfoPinList from "pages/optimePanel/views/informationSidebar/views/infoPinList";
import { MdDeliveryDining, MdWrongLocation } from "react-icons/md";
import { RiTruckFill } from "react-icons/ri";
import InfoNotAssignedPins from "pages/optimePanel/views/informationSidebar/views/infoNotAssignedPins";
import InfoDeletedPins from "pages/optimePanel/views/informationSidebar/views/infoDeletedpins";
import InfoPudoPolygon from "pages/optimePanel/views/informationSidebar/views/pudo-polygon-info";

interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
}

const OptimeModal = ({ optimeData, setOptimeData }: MapOperationProps) => {
  const dispatch = useDispatch();
  const { indexTab, show } = useSelector((state: any) => state.optimeModal);
  return (
    <div
      className={`w-[400px] h-full ease-in-out delay-100 Max-md:right-[48px] transition-all bg-[#1e293b] absolute top-0 ${
        show ? "right-[7rem]" : "right-[-400px]"
      } z-30`}
    >
      <div className="mt-5">
        <div className="custom-sidebar-optime">
          <Tabs
            className="w-full"
            selectedIndex={indexTab}
            onSelect={(index) => {
              dispatch(changeSelectTab(index));
            }}
          >
            <TabList className="flex justify-evenly items-center flex-row-reverse">
              <Tab>
                <RiTruckFill size={25} title="سفر مبادله" color="white" />
              </Tab>
              <Tab>
                <BiSolidMap size={25} color="white" />
              </Tab>
              <Tab>
                <MdDeliveryDining size={25} color="white" title="راننده FLM" />
              </Tab>
              <Tab>
                <MdWrongLocation size={25} color="white" title="" />
              </Tab>
              <Tab>
                <AiFillDelete size={20} color="white" />
              </Tab>
            </TabList>
            <TabPanel>
              <div className="spacing">
                <InfoMdlPolygon
                  optimeData={optimeData}
                  setOptimeData={setOptimeData}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="spacing">
                <InfoPinList
                  optimeData={optimeData}
                  setOptimeData={setOptimeData}
                />
              </div>
            </TabPanel>
            <TabPanel>
            <div className="spacing h-[85vh] overflow-y-auto">
                <InfoPudoPolygon
                  optimeData={optimeData}
                  setOptimeData={setOptimeData}
                />
                {/* <DataSidebarPolygon /> */}
                {/* {infoPolygon?.pudoVehicle?.[0]?.driver?.text} */}
              </div>
            </TabPanel>
            {/* <TabPanel>
              <div className="spacing">
                <InfoPinList
    
                />
              </div>
            </TabPanel> */}
            <TabPanel>
              <div className="spacing h-[85vh] overflow-y-auto">
                <InfoNotAssignedPins
                  optimeData={optimeData}
                  setOptimeData={setOptimeData}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="spacing h-[85vh] overflow-y-auto">
                <InfoDeletedPins
                  optimeData={optimeData}
                  setOptimeData={setOptimeData}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default OptimeModal;
