import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { filterUrls } from "../../../services/api.enums";
import { filterTableDataAPI, getAPI } from "../../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";



export const filterDriver = createAsyncThunk("filter", async (body: any) => {
  var data: any;
  try {

    data = await filterTableDataAPI(
     filterUrls.driverMangement as any,
      body?.pageNumber || 1,
      {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
      },
      true
    );
  } catch (error) {
 
  }

   let url=body?.filter ? 'resource-api/driver/': '/consignment-api/driverplan/selectIsPresonce/'
   let huburl="core-api/hub/"
  
   
   let ids
  //  if(body?.filter){
   ids= data.data.payload.content.map((item: any) => {
      return { ...item,driverId:item.id,huburl:`${huburl}${item.selectHub.id}`, url: `${url}${item.selectdriver.id}`,shift:item?.presenceAbsenceTimeFrom?item?.presenceAbsenceTimeFrom+"  "+item?.presenceAbsenceTimeTo:'' };
    });



  //  }
  //  else{
  //   ids=data.data.payload.content.map((item: any) => {
  //     return { ...item,driverId:item.id, url: `${url}${item.id}` };
  //   });
  //  }
 
  const fetchJson = async (item: any) =>
    await getAPI(item.url, true)
      .then((res: any) => {
      
        return { ...item, ...res.data.payload };
      })
      .catch((error) => {
        ErrorAlert(error?.response?.data?.errors?.message)
      });


   const fetchHub= async (item: any) =>
   
   await getAPI(item.huburl, true)
     .then((res: any) => {
       const driverId=item.driverId

       return {...res.data.payload,driverId,selectCurrentHub:{id:res.data.payload.id,text:res.data.payload.name}} ;
     })
     .catch((error) => {
      ErrorAlert(error?.response?.data?.errors?.message)
     });  
  let content: any = [];
  content = await Promise.all(ids.map((item: any) => fetchJson(item))).then((values) => values);
  const hubData= await Promise.all(ids.map((item: any) => fetchHub(item))).then((values) => values);
  
    //shift
    const fetchDispatchShift = async (item: any) =>

        await getAPI(`/core-api/calendar/findshiftbyid/${item.id}`, true)
            .then((res: any) => {
                return { ...item,text:res.data.timeFrom+" "+res.data.timeTo};
            })
            .catch((error) => {
              ErrorAlert(error?.response?.data?.errors?.message)
            });



    let shifts= content.map((item:any)=>item.selectdispatchShift.map((sub:any)=>({...sub,driverId:item.driverId}))).flat(1)
    let  dispatchShifts:any= await Promise.all(shifts.map((item: any) => fetchDispatchShift(item))).then((values) => values.filter(Boolean))
    let shiftsresult = dispatchShifts.reduce(function (r:any, a:any) {
        r[a.driverId] = r[a.driverId] || [];
        r[a.driverId].push(a);
        return r;
    }, Object.create(null));
   

   

    const finalShift=Object.entries(shiftsresult).map( ([key, val])=> {
        return {
            selectdispatchShift:val,
            driverId: +key,
        };
    });

  //end shift

  const arr1=content.filter(Boolean)
  const arr2=hubData.filter(Boolean)

  let result=[] as any
  // if(body?.filter){
    result = [
      ...[...arr2, ...arr1,...finalShift]
        .reduce(
          (acc, curr) => acc.set(curr.driverId, { ...acc.get(curr.driverId), ...curr }),
          new Map()
        )
        .values(),
    ];
  // }
  
  // else{
  //   result=arr1
  // }
   
  data.data.payload.content = result;
  // if (body?.isPresence) {
  //   return { content: data.data, type: "status" };
  // } else {
    return data.data;
  // }
});

export interface StateData {
  driverLists: Array<any>;
  changeStatusLists: Array<any>;
  fetchPost?: boolean;
  errorMessage: null | string;
  isUpdating: Boolean;
  filter: object;
}

const initialState: StateData = {
  driverLists: [],
  changeStatusLists: [],
  fetchPost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const DriverListsData = createSlice({
  initialState: initialState,
  name: "driverLists",
  reducers: {
    updating: (state: any, action: any) => {

      state.isUpdating = action.payload;
    },
    setFilter: (state, action) => {

      state.filter = action.payload;
    },
    clearData: (state: any) => {

      state.driverLists = [];
    },
  },
  extraReducers: {
    [filterDriver.fulfilled as any]: (state, action) => {
      // if (action.payload?.type) {
      //   state.changeStatusLists = action.payload.content.payload;
      //   state.fetchPost = false;
      // } else {
        state.driverLists = action.payload.payload;
        state.fetchPost = false;
      // }
    },
    [filterDriver.pending as any]: (state) => {

      state.fetchPost = true;
    },
    [filterDriver.rejected as any]: (state) => {

      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearData } = DriverListsData.actions;
export default DriverListsData.reducer;
