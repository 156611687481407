import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { filterUrls } from "../../../services/api.enums";
import { filterDataAPI, filterTableDataAPI, getAPI } from "../../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";



export const filterstatus = createAsyncThunk("filterstatus", async (body: any) => {
  
  var data: any;
  try {
    data = await filterTableDataAPI(
        filterUrls.filterpreson as any,
        // filterUrls.presence as any,
      body?.pageNumber || 1,
      {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
      },
      true
    );
  } catch (error) {
  }

   let url= 'resource-api/driver/'

   let ids
    const contentAnswer=data.data.payload.content || data.data.payload
    ids=contentAnswer.map((item: any) => {
      return { ...item,driverId:item.id, url:`${url}${item.selectdriver.id}` };
    });
  
 
  
  const fetchJson = async (item: any) =>
    await getAPI(item.url, true)
      .then((res: any) => {
      
        return { ...item, ...res.data.payload };
      })
      .catch((error) => {
        ErrorAlert(error?.response?.data?.errors?.message)
      });

  const fetchDispatchShift = async (item: any) =>
   await filterDataAPI("/consignment-api/driverplan/driverShift",{
    driverId:item.mainDriverId,
    datePlan:body.datePlan
    },true,false)
    // await getAPI(`/core-api/calendar/findshiftbyid/${item.id}`, true)
      .then((res: any) => {
          return { ...item,text:"",selectdispatchShift
          :res.data.payload.map((timeShift:any)=>timeShift)};
      })
      .catch((error) => {
        ErrorAlert(error?.response?.data?.errors?.message)
      });
   
  let content: any = [];
  content = await Promise.all(ids.map((item: any) => fetchJson(item))).then((values) => values);
  let mainData=ids.filter((item:any)=>item.selectdispatchShift !==null)

  // content.map((item)=>({...item,selectMainProspect:{id:item.selectMainProspect.id,text:item.name}}))

 let shifts= mainData.map((item:any)=>item.selectdispatchShift.map((sub:any)=>({...sub,driverId:item.driverId,mainDriverId:item.selectdriver.id }))).flat(1)

 let  dispatchShifts:any= await Promise.all(shifts.map((item: any) => fetchDispatchShift(item))).then((values) => values.filter(Boolean))
 


   
  const arr1=content.filter(Boolean)
//   const arr2=hubData.filter(Boolean)
  let result=[] as any
    result = [
      ...[...arr1, ...dispatchShifts]
        .reduce(
          (acc, curr) => acc.set(curr.driverId, { ...acc.get(curr.driverId), ...curr }),
          new Map()
        )
        .values(),
    ];
 
//  const final= result.map((item:any)=>({...item,selectdispatchShift:item.selectdispatchShift && item.selectdispatchShift.map((shift:any)=>({id:shift.id,text:shift.text}))}))
  data.data.payload.content = result;
    return data.data;
});

export interface StateData {
  driverListsStatus: Array<any>;
  changeStatusLists: Array<any>;
  fetchPost?: boolean;
  errorMessage: null | string;
  isUpdating: Boolean;
  filter: object;
}

const initialState: StateData = {
  driverListsStatus: [],
  changeStatusLists: [],
  fetchPost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const DriverListsStatus = createSlice({
  initialState: initialState,
  name: "driverListsStatus",
  reducers: {
    updating: (state: any, action: any) => {
      state.isUpdating = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearStatus: (state: any) => {
      state.driverListsStatus = [];
    },
  },
  extraReducers: {
    [filterstatus.fulfilled as any]: (state, action) => {
        state.driverListsStatus = action.payload;
        state.fetchPost = false;
    },
    [filterstatus.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterstatus.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearStatus } = DriverListsStatus.actions;
export default DriverListsStatus.reducer;
