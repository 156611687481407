import { createSlice } from "@reduxjs/toolkit";


const initialState = {
token: "",
};
const UserInfoReducer = createSlice({
  initialState: initialState,
  name: "auth",
  reducers: {
 
    setToken: (state, action) => {
        state.token=action.payload
    },
  },
});
export const {setToken} = UserInfoReducer.actions;
export default UserInfoReducer.reducer;