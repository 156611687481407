import {createSlice,createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit"
import { addEditUrls } from "../../services/api.enums";
import { addEditDataAPI } from "../../services/CRUDServices";


export const OverViewData: AsyncThunk<{}, {}, {}> = createAsyncThunk("OverView", async (body:any) => {

      const {data} = await   addEditDataAPI(addEditUrls.getAllDataOverview, "post", {
        selectHub:body.selectHub?.id ? body.selectHub : {
          id: null,
          text: null,
        },
        tripNo: "",
        selectVehicle: {
          id: null,
          text: null,
        },
        fromDate:body.fromDate?.day ? body.fromDate : {
          day: null,
          month: null,
          year: null,
        },
        toDate:body.toDate?.day ? body.toDate : {
          day: null,
          month: null,
          year: null,
        },
      })
      return data.payload
    })


const initialState = {
    postLists: [],
    fetchpost: false,
    errorMessage: "",
    selectedHub:{}
  };

  const clearState=initialState

  const OverViewAction=createSlice({
    initialState:initialState,
    name:'OverView',
    reducers:{
     
        selectActionHub:(state,{payload})=>({
          ...state,selectedHub:{...payload}
        }),
        clearAllOverview:()=>({...clearState})
    },
  extraReducers: {
    [OverViewData.fulfilled as any]: (state, action) => { 
      state.postLists = action.payload;
      state.fetchpost = false;
    },
    [OverViewData.pending as any]: (state) => {
      state.fetchpost = true;
    },
    [OverViewData.rejected as any]: (state) => {
      state.fetchpost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { selectActionHub,clearAllOverview } =OverViewAction.actions
export default OverViewAction.reducer