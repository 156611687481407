import { useEffect } from "react";
import { useQuery } from "react-query";
import { getServiceTypes } from "services/options";

export default function useGetServiceTypes() {
  const token = localStorage.getItem("token");
  const { data, isLoading } = useQuery(
    ["core-api-service-types", token],
    getServiceTypes
  );
  useEffect(() => {
    if (data && !isLoading) {
      localStorage.setItem("coreApiServiceTypes", JSON.stringify(data.payload));
    }
  }, [data, isLoading]);
}
