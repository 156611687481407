import { useSelector } from "react-redux";
import ConsignmentCard from "./consignments";
import PostOrderForm from "./form";

function PostOrderManagement() {
  const { consignments } = useSelector((state: any) => state.Order);
  return (
    <>{consignments.length === 0 ? <PostOrderForm /> : <ConsignmentCard />}</>
  );
}

export default PostOrderManagement;
