import { routes } from "pages/routes";
import { Route } from "react-router-dom";

const StaticLinksPermissionRoute = () => {
  return routes.map((route, index) => (
    <Route key={index} path={route.to} element={route.component} />
  ));
};

export default StaticLinksPermissionRoute;
