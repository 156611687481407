import React from "react";
import { FiSearch } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Actionpage, clearPaginate } from "redux/PaginationAction/PaginationAction";

export type SimpleButtonProps = {
  type?: "button" | "submit" | "reset" | undefined;
  text?: string;
  className?: string;
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  handelClick?: any;
  searchBtn?: boolean;
  loading?: boolean;
  disabled?: boolean;
  loadingColor?: string;
};

const SimpleButton = ({
  type = "button",
  text,
  icon,
  rightIcon,
  className,
  handelClick,
  searchBtn,
  loading,
  disabled,
  loadingColor = "#ffffff",
}: SimpleButtonProps) => {

  const dispatch = useDispatch<any>();

  const handelClear = () => {
    if ((type ==="submit" && text ==="جستجو") || searchBtn) {
      dispatch(clearPaginate(1));
      dispatch(Actionpage(1));
     
    }
  };

  const handelAction = () => {
    handelClear();
    handelClick && handelClick();
  };

  return (
    <button
      disabled={loading ? true : disabled}
      type={searchBtn ? "submit" : type}
      className={`btn ${searchBtn && "full-gray-btn mb-4"} ${
        disabled && "opacity-60"
      } ${className} Max-sm:w-full `}
      onClick={handelAction}
    >
      {rightIcon && <span>{rightIcon} </span>}
      <span>{searchBtn ? "جستجو" : text}</span>
      {icon && <span>{icon} </span>}
      {searchBtn && (
        <span>
          <FiSearch size={25} className="text-darkGray" />
        </span>
      )}
      {loading && <ClipLoader size={15} color={loadingColor} />}
    </button>
  );
};

export default SimpleButton;
