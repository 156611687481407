import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterUnPlanedConsignment: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "unplaned",
  async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.OutOfPlan,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true,
      10,
      false
    );

    return data.data;
  }
);

const initialState: any = {
  unPlanedData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const UnplanedSlice = createSlice({
  initialState: initialState,
  name: "unplaned",
  reducers: {
    clearUnPlanned: (state) => {
      state.contradiction = [];
    },
    setFilterUnPlanned: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterUnPlanedConsignment.fulfilled as any]: (state, action) => {
      state.unPlanedData = action.payload.payload;
      state.fetchPost = false;
    },
    [filterUnPlanedConsignment.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterUnPlanedConsignment.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterUnPlanned, clearUnPlanned } = UnplanedSlice.actions;
export default UnplanedSlice.reducer;
