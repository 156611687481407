import { FC, useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";

import { convertToObjects } from "../../../../tools/functions/Methods";
import { useFormik } from "formik";
import Provinces from "../../../../components/provinces/Provinces";
import CustomSwitch from "../../../../global/Switch/Switch";
import SimpleButton from "../../../../global/SimpleButton/SimpleButton";
import StaticTable from "../../../../components/staticTable/StaticTable";
import { AddGeoColumn } from "../../../../global/Column/Columns";
import TooltipWrapper from "../../../../global/tooltip/TooltipWrapper";
import { BiPlus, BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { deleteDataAPI } from "../../../../services/CRUDServices";
import { deleteUrls } from "../../../../services/api.enums";
import { useSelector } from "react-redux";
import Modal from "../../../../global/Modal/Modal";
import { Pagination } from "react-pagination-bar";
import { validationlocationForm } from "global/Validation/Validation";

interface LocationFormProps {
  tableList: Array<any>;
  formikTitle: any;
  setTableList: (values: any) => void;
  setDeletedList: (values: Array<any>) => void;
}

const LocationForm: FC<LocationFormProps> = ({
  tableList,
  formikTitle,
  setTableList,
  setDeletedList,
}): JSX.Element => {
  const { pageNumbers } = useSelector((state: any) => state.paginate);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageTable, setpageTable] = useState(1);
  const [currentList, setCurrentLists] = useState<any>([]);
  function paginate(array: Array<object>, page_size: number, page_number: number): Array<object> {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  useEffect(() => {
    if (currentList.length === 1 && pageTable > 1) {
      setpageTable(pageTable - 1);
      setCurrentLists(paginate(tableList, 10, pageTable - 1));
    } else {
      setCurrentLists(paginate(tableList, 10, pageTable));
    }
  }, [pageTable, tableList]);
  const handleDeleteItem = useCallback(async (id: number) => {
    try {
      await deleteDataAPI(deleteUrls.customDevisiondetail, id);

      toast.success("آیتم مورد نظر با موفقیت حذف شد ");
      return true;
    } catch (error) {
      // toast.error("مشکلی");
    }
    return false;
  }, []);

  const onDeleteHandler = async (id: number) => {
    if (typeof id === "number") {
      const isDeleted = await handleDeleteItem(id);
      isDeleted && setDeletedList(tableList.filter((item) => item.id !== id));
    } else {
      setDeletedList(tableList.filter((item) => item.id !== id));
      toast.success("آیتم مورد نظر با موفقیت حذف شد ");
    }
    setIsModalOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationlocationForm,
    initialValues: {
      id: "",
      fromCountryDevision: "",
      toCountryDevision: "",
      fromDestinationCity: "",
      fromSourceCity: "",
      fromSourceLocation: "",
      fromDestinationLocation: "",
    },
    onSubmit: async (values, { resetForm }) => {
      let fromCountryDevisiondsdsd: any = [];
      let toCountryDevisiond: any = [];
      let attributeDivition;
      fromCountryDevisiondsdsd =
        values.fromDestinationLocation?.length !== 0
          ? values.fromDestinationLocation
          : values.fromDestinationCity.length !== 0
          ? values.fromDestinationCity
          : values.fromCountryDevision.length !== 0
          ? values.fromCountryDevision
          : [];
      toCountryDevisiond =
        values.fromSourceLocation.length !== 0
          ? values.fromSourceLocation
          : values.fromSourceCity.length !== 0
          ? values.fromSourceCity
          : values.toCountryDevision.length !== 0
          ? values.toCountryDevision
          : [];
      attributeDivition =
        fromCountryDevisiondsdsd.length !== 0
          ? convertToObjects(fromCountryDevisiondsdsd, toCountryDevisiond, "from")
          : [];

      let data = {
        ...values,
        id: values?.id ? values.id : uuid(),
        customDevisionDetails: attributeDivition.map((item) => {
          return {
            ...item,
            id: "",
            isActive: true,
            customDevision: {
              id: "",
              text: "",
            },
          };
        }),
      };
      setTableList(data);
      resetForm();
    },
  });

  const { handleSubmit } = formik;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="w-10/12 Max-sm:w-full grid grid-cols-5  Max-lg:grid-cols-2 Max-sm:grid-cols-1 gap-2">
          <div className="col-span-4">
            <fieldset className="border rounded-xl p-6">
              <legend className="px-3"> جزئیات رده جغرافیایی</legend>
              <div className="grid grid-cols-2 Max-md:grid-cols-1 gap-6">
                <Provinces form={formik} />
              </div>
            </fieldset>
          </div>
          <div className={` col-span-1  h-[40px] mt-[10px]`}>
            <CustomSwitch
              active={true}
              handleChange={(value) => formikTitle.setFieldValue("isActive", value)}
            />
          </div>
        </div>
        <div className="w-full flex justify-end my-6">
          <SimpleButton
            icon={<BiPlus color="white" />}
            type="submit"
            text="درج در لیست"
            className="full-tomato-btn px-[50px] w-fit "
          />
        </div>
      </form>

      <StaticTable
        data={currentList?.map((item: any) => {
          return {
            fromCountryDevision: (
              <div className="w-full flex justify-center">
                <TooltipWrapper
                  textProps={item?.fromCountryDevision?.map((country: any) => {
                    return (
                      <div className="text-white" key={country.id}>
                        {country.text}
                      </div>
                    );
                  })}
                >
                  <div>
                    {item?.fromCountryDevision?.length !== 0 &&
                      item?.fromCountryDevision[item?.fromCountryDevision?.length - 1].text}
                  </div>
                </TooltipWrapper>

                {item?.fromSourceCity && item?.fromSourceCity.length !== 0 && (
                  <>
                    <span>&nbsp; - &nbsp;</span>
                    <TooltipWrapper
                      textProps={item?.fromSourceCity?.map((city: any) => {
                        return (
                          <div className="text-white" key={city.id}>
                            {city.text}
                          </div>
                        );
                      })}
                    >
                      <div>
                        {item?.fromSourceCity?.length !== 0 &&
                          item?.fromSourceCity[item?.fromSourceCity?.length - 1].text}
                      </div>
                    </TooltipWrapper>
                  </>
                )}
                {item?.fromSourceLocation && item?.fromSourceLocation.length !== 0 && (
                  <>
                    <span>&nbsp; - &nbsp;</span>
                    <TooltipWrapper
                      textProps={item?.fromSourceLocation?.map((location: any) => {
                        return (
                          <div className="text-white" key={location.id}>
                            {location.text}
                          </div>
                        );
                      })}
                    >
                      <div>
                        {item?.fromSourceLocation?.length !== 0 &&
                          item?.fromSourceLocation[item?.fromSourceLocation?.length - 1].text}
                      </div>
                    </TooltipWrapper>
                  </>
                )}
              </div>
            ),
            toCountryDevision: (
              <div className="w-full flex justify-center">
                <TooltipWrapper
                  textProps={item?.toCountryDevision?.map((country: any) => (
                    <div className="text-white" key={country.id}>
                      {country.text}
                    </div>
                  ))}
                >
                  <div>
                    {item?.toCountryDevision?.length !== 0 &&
                      item?.toCountryDevision[item?.toCountryDevision?.length - 1].text}
                  </div>
                </TooltipWrapper>
                {item?.fromDestinationCity && item?.fromDestinationCity.length !== 0 && (
                  <>
                    <span>&nbsp; - &nbsp;</span>
                    <TooltipWrapper
                      textProps={item?.fromDestinationCity?.map((city: any) => {
                        return (
                          <div className="text-white" key={city.id}>
                            {city.text}
                          </div>
                        );
                      })}
                    >
                      <div>
                        {item?.fromDestinationCity?.length !== 0 &&
                          item?.fromDestinationCity[item?.fromDestinationCity?.length - 1].text}
                      </div>
                    </TooltipWrapper>
                  </>
                )}
                {item?.fromDestinationLocation && item?.fromDestinationLocation.length !== 0 && (
                  <>
                    <span>&nbsp; - &nbsp;</span>
                    <TooltipWrapper
                      textProps={item?.fromDestinationLocation?.map((location: any) => {
                        return (
                          <div className="text-white" key={location.id}>
                            {location.text}
                          </div>
                        );
                      })}
                    >
                      <div>
                        {item?.fromDestinationLocation?.length !== 0 &&
                          item?.fromDestinationLocation[item?.fromDestinationLocation?.length - 1]
                            .text}
                      </div>
                    </TooltipWrapper>
                  </>
                )}
              </div>
            ),
            operation: (
              <div>
                <button
                  className=" border-none	text-[14px]  w-[20px] h-[20px]"
                  onClick={() => onDeleteHandler(item.id)}
                >
                  <BiTrash size={20} className="w-full h-full	" />
                </button>
                {/* <Modal visible={isModalOpen} setVisible={setIsModalOpen} title="حذف مشخصات محصول">
                    <p className="text-center">آیا از حذف این مورد اطمینان دارید؟</p>
                    <div className="flex w-full justify-center gap-4 mt-5">
                      <SimpleButton
                        type="submit"
                        text="خیر"
                        className="full-lightTomato-btn w-28 "
                        handelClick={() => setIsModalOpen(false)}
                      />
                      <SimpleButton
                        type="submit"
                        text="بله"
                        className="full-tomato-btn w-28 "
                        handelClick={() => onDeleteHandler(item.id)}
                      />
                    </div>
                  </Modal> */}
              </div>
            ),
          };
        })}
        column={AddGeoColumn}
        // pagination={tableList.length}
        selectable={false}
      />

      <div className="Pagination">
        <Pagination
          currentPage={pageTable}
          itemsPerPage={10}
          onPageChange={(pageNumber: any) => {
            setpageTable(pageNumber);
          }}
          totalItems={tableList.length || 10}
          pageNeighbours={2}
          customClassNames={{
            rpbItemClassNameActive: "pagination_style",
          }}
        />
      </div>
    </div>
  );
};

export default LocationForm;
