

function useSearchCity(setFieldValue,setDisableSupport,options) {


 function handleChangeCity(name, value) {

    setFieldValue(name, value);
    const isSupport = options.find(
      (city) => city.id === value.id
    )?.boxitSupport;

    setFieldValue("thirdPartyDelivery", !isSupport);
    setDisableSupport(isSupport);
    if (!isSupport) {
      setFieldValue("cod", false);
      setFieldValue("postBack", false);
    }
  }

  return {handleChangeCity}
}

export default useSearchCity;
