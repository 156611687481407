import { useFormik } from "formik";
import InputSelect from "global/InputSelect/InputSelect";
import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { assignVehiclePlaningValidation } from "global/Validation/Validation";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { addEditDataAPI, filterDataAPI, getAPI } from "services/CRUDServices";
import { addEditUrls, getUrls } from "services/api.enums";
import { convertResponse } from "../utils";
import { toast } from "react-toastify";
import { ActionSidebar } from "redux/optime/optime";

interface AssignVehicleProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  polygons: any;
  setOptimeData: any;
}

const AssignMdlTrip: FC<AssignVehicleProps> = ({
  open,
  setOpen,
  polygons,
  setOptimeData,
}) => {
  const [optionsData, setOptionsData] = useState<any>({
    stations: [],
    mdlVehicle: [],
  });

  const { id } = useParams();
  const { state } = useLocation();
  const [loading,setLoading]=useState(false)
  const dispatch=useDispatch()
  const { userinfo } = useSelector((state: any) => state.userInfo);
  const [vehicleInfo, setVehicleInfo] = useState({
    assignableWeight: "",
    assignableVolume: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: assignVehiclePlaningValidation,
    initialValues: {
      selectHub: state?.hub,
      consignmentList: polygons?.map(({ consignmnetList }) => consignmnetList),
      selectuser: userinfo,
      color: "",
      polygon: "",
      pudoVehicleId: { id: "" },
      pudoExecutationId: state?.pudoExecutationId,
      station: "",
    },
    onSubmit: (values, { resetForm }) => {
      const finalData = {
        selectStation: values?.station,
        selectMdlVehicle: values?.pudoVehicleId,
        selectExecutation: { id: state?.pudoExecutationId, text: "" },
        consignmentList: values.consignmentList,
      };
      setLoading(true)
      addEditDataAPI(addEditUrls.addMdlandStation, "post", finalData).then(
        (res) => {
          getAPI(getUrls.planingConsignment + id).then((res) => {
            const convert = convertResponse(res);
            setOptimeData(convert);
          });
          setOpen(false);
          resetForm();
          dispatch(ActionSidebar("multiSelect"))
          toast.success("سفر مبادله با موفقیت ایجاد شد",{position:"top-left"})
          setLoading(false)
        }
      ).catch(()=>setLoading(false));
    },
  });

  const { values, setFieldValue, handleSubmit, errors, touched, resetForm } =
    formik;

  function getVehicleInfo(id) {
    getAPI(getUrls.vehicleDetails + id).then((res) => {
      setVehicleInfo({
        assignableVolume: res.data.assignableVolume,
        assignableWeight: res.data.assignableWeight,
      });
    });
  }

  useEffect(() => {
    if (open) {
      filterDataAPI(getUrls.pudoStation, {}).then((res) => {
        const convertStation = res.data.payload?.content.map((item) => ({
          text: item.name + "" + item.addressLine,
          id: item.id,
        }));
        setOptionsData({ ...optionsData, stations: convertStation });
      });
    } else {
      resetForm();
    }
  }, [open]);
   
  return (
    <Modal visible={open} setVisible={setOpen} title={"ایجاد سفر مبادله"}>
      <form onSubmit={handleSubmit}>
        <div>
          <InputSelect
            wrapperClassName="w-full"
            label="وسایل نقلیه مبادله"
            important
            name="pudoVehicleId"
            handleChange={(name, value) => {
              const findVehicle = state.mdlVehicle.find(
                (item) => item.id === value.id
              );
              getVehicleInfo(findVehicle.vehicleId);
              setFieldValue("pudoVehicleId", value);
            }}
            values={values.pudoVehicleId}
            options={state?.mdlVehicle}
            error={touched.pudoVehicleId && errors.pudoVehicleId}
          />
          <InputSelect
            wrapperClassName="w-full"
            label="ایستگاه تحویل بار"
            important
            name="station"
            handleChange={setFieldValue}
            values={values.station}
            options={optionsData?.stations}
            error={touched.station && errors.station}
          />
          <div className="flex gap-4">
            <div className="flex gap-2">
              <p>وزن تخصیص یافته: 

                {polygons.map(item=>item.consignmnetList).flat(1).reduce((a,b)=>a+b.weight,0)}
              </p>
            </div>

            <div className="flex gap-2">
              <p>حجم تخصیص یافته: 
              {polygons.map(item=>item.consignmnetList).flat(1).reduce((a,b)=>a+b.volume,0)}
              </p>
            </div>

            <div className="flex gap-2">
              <p>تعداد: 
                {polygons.map(item=>item.consignmnetList).flat(1).length}
              </p>
            </div>
          </div>
          <div className="flex gap-4 mt-4">
            <div className="flex gap-2">
              <p>وزن قابل تخصیص: </p>
              {vehicleInfo.assignableWeight}
            </div>

            <div className="flex gap-2">
              <p>حجم قابل تخصیص: </p>
              {vehicleInfo.assignableVolume}
            </div>
          </div>
        </div>
        <div className="btnForm">
          <SimpleButton
            handelClick={() => setOpen(false)}
            text="لغو"
            className="full-lightTomato-btn"
          />
          <SimpleButton
            type="submit"
            text={"افزودن"}
            className="full-tomato-btn"
            loading={loading}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AssignMdlTrip;
