import { createSlice } from "@reduxjs/toolkit";


const initialState = {
 otpCode:"",
 customerId:"",
 isCustomerHasCode:false
};

const OTPSlice = createSlice({
  name: "OTP",
  initialState,

  reducers: {
    setOTP: (state,action) => {
      state.otpCode = action.payload;
    },
    setCustomerId: (state,action) => {
      state.customerId = action.payload;
    },
    setIsCustomerHasCode:(state,action)=>{
       state.isCustomerHasCode=action.payload
    },
    clearOTP:()=>{
      return initialState;
    }
  },


});

export const { setOTP,clearOTP,setCustomerId,setIsCustomerHasCode} = OTPSlice.actions;
export default OTPSlice.reducer;
