import { lazy } from "react";

const ProductDefine = lazy(
  () => import("pages/ServiceManagement/ProductDefine")
);
const ProductInfo = lazy(
  () => import("pages/ServiceManagement/ProductInfo/ProductInfo")
);
const ServiceDefinition = lazy(
  () => import("pages/ServiceManagement/ServiceDefinition")
);
const ServiceProvision = lazy(
  () => import("pages/ServiceProvision/ServiceProvision")
);
const Price = lazy(() => import("pages/Price/Price"));
export const childs: Array<ISideBarChild> = [
  {
    to: "/service-information/product",
    component: <ProductDefine />,
    code: "1004",
    label: "تعریف محصول",
    permissions: "view_product_def",
  },
  {
    to: "/service-information/product-info",
    component: <ProductInfo />,
    code: "",
    label: "تعریف مشخصات محصول",
    permissions: "view_product_attribute",
  },
  {
    to: "/service-information/service-definition",
    component: <ServiceDefinition />,
    code: "1005",
    label: "تعریف سرویس",
    permissions: "view_service_def",
  },
  {
    to: "/basic-information/service-provision",
    component: <ServiceProvision />,
    code: "1006",
    label: "ارائه سرویس",
    permissions: "view_serice_delivery",
  },
  {
    to: "/service-information/price",
    component: <Price />,
    code: "",
    label: "نرخ نامه",
    permissions: "view_pricelist",
  },
];
