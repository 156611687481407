import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "./../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";

export const addressCorrectionData: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "addressCorrectionList",
  async (body: any) => {
    let { data } = await filterTableDataAPI(
      filterUrls.addressCorrection,
      body.pageNumber,
      {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
      },
      true,
      10,
      false
    );
    return data;
  }
);

const initialState: any = {
  addressCorrectionList: [],
  fetchPost: false,
  errorMessage: null,
  filter: {},
};

const AddressCorrectionList = createSlice({
  initialState: initialState,
  name: "addressCorrectionList",
  reducers: {
    clearAddressCorrection: (state) => {
      state.addressCorrectionList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [addressCorrectionData.fulfilled as any]: (state, action) => {
      state.addressCorrectionList = action.payload;
      state.fetchPost = false;
    },
    [addressCorrectionData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [addressCorrectionData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearAddressCorrection } =
  AddressCorrectionList.actions;
export default AddressCorrectionList.reducer;
