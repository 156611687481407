import { NumericFormat } from "react-number-format";
type PropTime = {
  error?: any;
  label?: string;
  placeholder?: string;
  important?: boolean;
  readOnly?: boolean;
  className?: string;
  wrapperClassName?: string;
  format?: any;
  name: string;
  values: any;
  handleChange: any;
  notSeparator?: boolean;
  allowNegative?: boolean;
  prefix?:string
};
const CustomInput = (props: any) => {
  return (
    <input
      {...props}
      // style={{ textAlign: "center" }}
      className={`w-full appearance-none focus:outline-none focus:shadow-outline ${props.className}   `}
    />
  );
};
const InputNumber = ({
  wrapperClassName,
  className,
  readOnly,
  important,
  label,
  error,
  format = "",
  name,
  values,
  placeholder = "",
  handleChange,
  notSeparator,
  allowNegative,
  prefix
}: PropTime) => {
  return (
    <div className={`flex flex-col Max-md:w-full ${wrapperClassName}`}>
      <div
        className={`autocompleteWrapper bg-white ${className} ${
          error && "border-red"
        } ${readOnly && "opacity-40"} `}
      >
        <div
          className={`autocompleteLabel  ${error && "text-red"} top-[-17px]`}
        >
          {label}{" "}
          <span className="text-tomato font-extrabold text-lg h-4">
            {important ? "*" : " "}
          </span>
        </div>
        <NumericFormat
          displayType="input"
          className={"autocompleteInput"}
          customInput={CustomInput}
          format={format}
          name={name}
          prefix={prefix}
          mask=""
          placeholder={placeholder}
          onValueChange={({ value }) => handleChange(name, value)}
          value={values}
          readOnly={readOnly}
          allowLeadingZeros
          thousandSeparator={!notSeparator ? "," : ""}
          allowNegative={allowNegative ? true : false}
        />
      </div>
      <p className="text-red text-xs pr-3 h-4 mt-1">{error}</p>
    </div>
  );
};

InputNumber.defaultProps = {
  wrapperClassName: "w-60",
};

export default InputNumber;


