import { dashboardRoutes } from "./dashboard";
import { hubRoutes } from "./hub";
import { orderManagementRoutes } from "./orderManagement";
import { consignmentManagementRoutes } from "./consignmentManagement";
import { employeeManagementRoutes } from "./employeeManagement";
import { flmManagementRoutes } from "./flmManagement";
import { overviewroutes } from "./overview";
import { crmManagementRoutes } from "./crmManagement";
import { trmManagementRoutes } from "./trmManagement";
import { rolesRoutes } from "./roles";
import { serviceManagementRoutes } from "./serviceManagement";
import { basicInformationRoutes } from "./basicInformation";
import { rullEngineRoutes } from "./rullEngine";
import { contradictionRoutes } from "./contradiction";

export const links: Array<ISideBarRoutes> = [
  dashboardRoutes,
  hubRoutes,
  orderManagementRoutes,
  consignmentManagementRoutes,
  employeeManagementRoutes,
  flmManagementRoutes,
  overviewroutes,
  crmManagementRoutes,
  trmManagementRoutes,
  rolesRoutes,
  serviceManagementRoutes,
  basicInformationRoutes,
  rullEngineRoutes,
  contradictionRoutes,
];
