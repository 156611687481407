import {createSlice,createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit"
import { filterUrls } from "../../../services/api.enums";
import { filterTableDataAPI } from "../../../services/CRUDServices";


export const vehicleModel: AsyncThunk<{}, {}, {}>=createAsyncThunk('vehicleModelLists',async(body:any)=>{

    const data = await  filterTableDataAPI(
        filterUrls.VehicleMake,
        body.pageNumber,
        {...body, 
            pageNumber: undefined,
            pageSize: undefined,},
        true,
      );
   

    return data.data;
})

const initialState:any= {
    vehicleModelLists:[],
    fetchpost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{}     
}

const VehicleModelLists= createSlice({
    initialState: initialState,
    name: "vehicleModelLists",
    reducers: {
        setFilter: (state, action) => {
          
            state.filter = action.payload;
          },
          clearVehicleModel: (state) => {
            state.vehicleModelLists = [];
          },
    },
    extraReducers: {
        [vehicleModel.fulfilled as any]: (state, action) => {
            state.vehicleModelLists = action.payload.payload;
            state.fetchPost = false;
        },
        [vehicleModel.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [vehicleModel.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter ,clearVehicleModel} = VehicleModelLists.actions;
export default VehicleModelLists.reducer