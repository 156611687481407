import { MdDeviceHub } from "react-icons/md";
import { childs } from "./childs";

export const hubRoutes: ISideBarRoutes = {
  parentName: "view_hub_management",
  code: "Hub Manegment",
  Icon: <MdDeviceHub size={"25"} />,
  label: "مدیریت هاب",
  childs: childs,
};
