


import { ConsignmentCard } from "pages/optimePanel/components/consignment-card/index";
import { useSelector } from "react-redux";

const InfoPinList = ({ optimeData, setOptimeData }) => {
  const { selectedPin } = useSelector((state: any) => state.optimeModal);

  return (
    <>
      {selectedPin && selectedPin.length !== 0 && (
        <ConsignmentCard
          optimeData={optimeData}
          setOptimeData={setOptimeData}
          item={selectedPin}
          hasExtra
      
        />
      )}
    </>
  );
};

export default InfoPinList;
