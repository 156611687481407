export enum filterUrls {
  orderReceipt = "/consignment-api/receipt/filter",
  changeDriver = "/consignment-api/consignment/changeDriverFilter",
  OutOfPlan = "/consignment-api/pudoPlanning/filterByOutOfPlan",
  findActiveDriverInPlan = "/consignment-api/consignment/findActiveDriverInPlan",
  selectByHubInDefaultDriver = "/resource-api/driver/selectByHubInDefaultDriver?filter=",
  planningFilter = "consignment-api/pudoPlanningExecution/filter",
  pudoFilter = "/consignment-api/pudoPlanning/filter",
  getConsignemnt = "/consignment-api/pudoPlanning/pudoPlanningConsignmentList",
  mdlPudoPlanning = "consignment-api/pudoPlanning/filterMdlPudo",
  pickupdistributionprogramming = "consignment-api/pudoPlanning/filterPudo",
  bagConsignmentCount = "consignment-api/flow/bagConsignmentCount",
  Tripbybarcode = "consignment-api/flow/findallConsignmentinTrip",
  tripScanOut = "/consignment-api/flow/findallConsignmentinTripHubScanOut",
  settlement = "/consignment-api/invoice/paymentReport",
  OPTsettlement = "/customer-api/settlements/",
  deliveryExcel="/consignment-api/delivery/v1/s0/exportFilterDeliveryExcel?",
  returnConsignment = "/consignment-api/consignment/consignmentReturnFilter",
  contradiction = "consignment-api/consignment/consignmentContradictionFilter",
  contradictionTraking = "/consignment-api/consignment/trackingContradictionConsignmentsFilter",
  subsidiaryLedgerReport = "/customer-api/subsidiaryLedger/subsidiaryLedgerReport",
  paymentHistoryDetails = "/customer-api/agentJournalLedger/filter",
  rulePermissions = "resource-api/permission/fetchPermissions",
  rule = "resource-api/role/filter",
  customerSegment = "core-api/customersegment/filter",
  addressCorrection = "customer-api/address/addressCorrectionList",
  consignment = "consignment-api/consignment/filter",
  trmManagement = "core-api/calendar/filtertrm",
  driverMangement = "/consignment-api/driverplan/filter",
  hub = "core-api/hub/filter",
  Vehicle = "core-api/vehicle/filter",
  VehicleMake = "core-api/vehicleMake/filter",
  VehicleMakeDetails = "core-api/vehicleMake/",
  bag = "core-api/bag/filter",
  salesChannel = "core-api/saleschannel/filter",
  gate = "core-api/gate/filter",
  dock = "core-api/dock/filter",
  countryDevision = "core-api/countryDevision?filter",
  provincewithcode = "core-api/countryDevision/provincewithcode?filter=",
  vehiclePlan = "consignment-api/vehicleplan/filter",
  fetchPermissionsByUserName = "resource-api/permission/fetchPermissionsByUserName",
  customcountrydevision = "core-api/customcountrydevision/filter",
  countryDevisionNoFIlter = "core-api/countryDevision",
  trip = "/consignment-api/trip/filter",

  calendar = "/core-api/calendar/filter",
  dispatchShift = "core-api/calendar/dispatchshiftbydateandHub",
  dispatchShiftByData = "core-api/calendar/dispatchshiftbydate",
  exception = "core-api/exception/filter",
  driver = "resource-api/driver/select?filter=",
  driverByHub = "resource-api/driver/filter?=",
  pickup = "consignment-api/pickup/filter",
  delivery = "consignment-api/delivery/filter",
  // filterDelivery = "consignment-api/delivery/filterDelivery",
  filterDelivery ="consignment-api/delivery/s0/v1/filterDelivery",
  fetchPermissions = "resource-api/permission/fetchPermissions",
  customDevision = "core-api/customcountrydevision/select?filter=",
  productChecker = "core-api/productattribute/checkproduct",
  scanin = "consignment-api/flow/scanin",
  scanOut = "consignment-api/flow/scanOut",
  selectbyedit = "core-api/productattribute/findByAttribute",
  employee = "resource-api/employee/filter",
  labelFilter = "consignment-api/label/filter",
  labelManagement = "consignment-api/label/labelManagement",
  drivers = "resource-api/driver/filter",
  consetctioninsLoc = "consignment-api/flow/consetctioninsLoc",
  presence = "consignment-api/driverplan/presence",
  isPersence = "consignment-api/driverplan/selectIsPresonce",
  services = "core-api/service/filter",
  FilterSelectDock = "core-api/dock/select?filter=",
  SelectErrorCode = "core-api/exception/select?filter=",
  SelectErrorCodeBag = "core-api/exception/selectByType?filter=",
  getSelectBag = "/core-api/bag/selectBagTypes",
  selectexception = "core-api/exception/select?filter=",
  FilterBagConsigment = "/core-api/bag/select?filter=",
  consigmentFilterCode = "/consignment-api/consignment/findbylable",
  getCountOfVehicle = "consignment-api/trip/countofvehicleindock",
  filterpreson = "consignment-api/driverplan/filterpreson",
  productattribute = "productattribute/filter",
  driverReport = "consignment-api/driverplan/totalreport",
  orederfilter = "consignment-api/consignment/orederfilter",
  route = "core-api/route/filter",
  vendor = "core-api/vendor/filter",
  tripmanagementfilter = "consignment-api/trip/tripmanagementfilter",
  ConnectionInstanceMode = "consignment-api/pickup/ConnectionInstanceMode",
  routeFilter = "core-api/route/select?filter=",
  bagfindbyid = "core-api/bag/findbyid",
  Selectservice = "core-api/service/select?filter=",
  findbycartagnumberSuggest = "core-api/vehicle/findbycartagnumberSuggest",
  serviceDefine = "core-api/service/filter",
  productDefine = "core-api/product/filter",
  thirdParty = "customer-api/thirdParty/filter",
  customer = "customer-api/customer/filter",
  admVehicle = "core-api/admVehicle/filter",
  timeCommitment = "core-api/timecommitment/filter",
  priceList = "core-api/pricelist/filter",
  priceListEnhanced = "core-api/pricelist/filter-enhanced",
  productGroup = "core-api/productGroup/filter",
  selectByHub = "/resource-api/driver/selectByHubInDefaultDriver?filter=",
  findVehicleByType = "consignment-api/vehicleplan/findVehicleByType",
}

export enum addEditUrls {
  otp="/core-api/sms/otp",
  otpVerify="/core-api/sms/otp/verify",
  groupDeliveryOutOfPlanAdd = "/consignment-api/delivery/groupDeliveryOutOfPlanAdd",
  editConsignmentFromLastOfFinal = "/consignment-api/consignment/editConsignmentFromLastOfFinal/",
  addReceipt = "/consignment-api/receipt/",
  changeDriverAdd = "/consignment-api/consignment/changeDriverAdd",
  createConsignment = "/consignment-api/consignmentWizard/createConsignment",
  editConsignment = "/consignment-api/consignmentWizard/editConsignment",
  addConsignmentToDriver = "/consignment-api/consignment/addConsignmentToDriver",
  acceptDifficultAddress = "/customer-api/address/updateAddressFromPinIsDifficult",
  addPinToPolygon = "/consignment-api/pudoPlanningExecution/addPudoPlanningToPoligon",
  updateAddressInPlanning = "/consignment-api/pudoPlanning/updateAddressInPlanning",
  allocateLabel = "/consignment-api/label/createLabelRequestAndLabel",
  updateAllocateLabel = "/consignment-api/label/updateLabelRequestAndLabel",
  updateCustomerLabel = "/consignment-api/label/customerAssignLabel",
  consignmentReturn = "/consignment-api/consignment/consignmentReturn",
  changeStatusToFinished = "/consignment-api/driver/delivery/changeStatusToFinished",
  changeStatusToStarted = "/consignment-api/driver/delivery/changeStatusToStarted",
  deleteSubzonePudo = "/core-api/hub/zone/deleteSubzone",
  deleteSubzoneMdl = "/core-api/hub/zone/deleteSubzoneMdl",
  addMdlandStation = "/consignment-api/planing-api/assignStationToMdlVehicle",
  createVehiclePolygon = "/core-api/hub/zone/createVehiclePolygon",
  planningExecution = "/consignment-api/pudoPlanningExecution",
  startPlan = "/consignment-api/pudoPlanningExecution",
  consignmentReturnReject = "/consignment-api/consignment/consignmentReturnReject",
  consignmentReturnGroupReject = "/consignment-api/consignment/consignmentReturnGroupReject",
  consignmentReturnAccept = "/consignment-api/consignment/consignmentReturnAccept",
  consignmentGroupReturnAccept = "/consignment-api/consignment/consignmentReturnGroupAccept",
  contradictionReject = "/consignment-api/consignment/consignmentContradictionReject",
  contradictionAccept = "/consignment-api/consignment/consignmentContradictionAccept",
  contradictionGroupAccept = "/consignment-api/consignment/consignmentContradictionGroupAccept",
  contradictionGroupReject = "/consignment-api/consignment/consignmentContradictionGroupReject",
  FailPickup = "/consignment-api/consignment/failedPickup",
  FailedDelivery = "/consignment-api/consignment/failedDelivery",
  hub = "core-api/hub",
  rule = "resource-api/role",
  serviceProvision = "core-api/servicedeliveryservice",
  ruleToUsers = "resource-api/employee/roles2employees",
  ruleToUser = "resource-api/employee/role2user",
  customerSegment = "core-api/customersegment/",
  shiftManagement = "core-api/calendar/",
  calendar = "core-api/calendar/dispatcher",
  copyShifts = "core-api/calendar/copyshift",
  normalShiftManagement = "/core-api/calendar/createnormalshift",
  copyYear = "core-api/calendar/copyyearhubbyday",
  driverPlan = "consignment-api/driverplan",
  changeStatus = "consignment-api/driverplan/changeStatus",
  assignShift = "consignment-api/driverplan/assignshift",
  employee = "resource-api/employee",
  hubpermission = "resource-api/employee/hubpermission",
  productattribute = "core-api/productattribute",
  plural_scanIn = "consignment-api/flow/plural_scanIn",
  plural_scanOut = "consignment-api/flow/plural_scanOut",
  customcountrydevision = "core-api/customcountrydevision",
  consignmentCancel = "consignment-api/flow/cancel",
  hubZone = "/core-api/hub/zone",
  dynamicAllocated = "consignment-api/vehicleplan/assigndriverdinamic",
  pickupDynamicAllocated = "consignment-api/pickup/assigntripDynamic",
  deliveryDynamicAllocated = "consignment-api/delivery/driverautomaticassignment",
  deliveryRegionAllocated = "consignment-api/delivery/deliveryallocationreligion",
  getReady = "consignment-api/vehicleplan/changeassignstatus",
  assignDriver = "consignment-api/vehicleplan/assigndriver",
  DeliveryAssignDriver = "consignment-api/delivery/drivermanualassignment",
  computing = "consignment-api/vehicleplan/computing",
  thirdPartyComputing = "consignment-api/vehicleplan/computingforthirdparty",
  changePickupHub = "consignment-api/pickup/changehub",
  createthirdparty = "consignment-api/vehicleplan/createthirdparty",
  vehicleplan = "consignment-api/vehicleplan",
  countryDevision = "core-api/countryDevision",
  countryDevisioncreate = "core-api/countryDevision/create",
  personalizationform = "core-api/personalizationform",
  hubupdate = "core-api/hub/update",
  address = "customer-api/address",
  thirdParty = "customer-api/thirdParty",
  flowUpdatestatus = "consignment-api/flow/updatestatus",
  submitFirstProccess = "consignment-api/consignment/exchange/selectCustomerAndStartProcess",
  submitConsignmentDetails = "consignment-api/consignment/exchange/createConsignmentDetailAndContinueProcess",
  editConsignmentDetails = "consignment-api/consignment/exchange/updateConsignmentDetailAndContinueProcess",
  submitDestinationDetails = "consignment-api/consignment/exchange/selectReceiverAndContinueProcess",
  submitServices = "consignment-api/consignment/exchange/selectServicesAndShowPricesAndContinueProcess/",
  editServices = "consignment-api/consignment/exchange/updateSelectServicesAndShowPricesAndContinueProcess/",
  bagging = "consignment-api/flow/bagging",
  unbagging = "/consignment-api/flow/unbagging",
  prospect = "customer-api/prospect",
  addVehicleMove = "/consignment-api/vehicletransfer",
  telephone = "customer-api/telephone",
  sendVehicle = "consignment-api/vehicletransfer/send",
  updateDrivers = "resource-api/driver/driver",
  tripcancel = "consignment-api/trip/cancel",
  addOverViewpage = "consignment-api/trip/overviewtotalreport",
  allocationMoveVehicle = "consignment-api/vehicletransfer/allocation",
  getAllDataOverview = "consignment-api/trip/overviewtotalreport",
  GetSecondDataBag = "consignment-api/consignment/findByBagId",
  EnterDoor = "consignment-api/flow/enterGate",
  ExitDoor = "/consignment-api/flow/exitGate",
  GetPlaqueAndTripnumber = "consignment-api/flow/findByTripOrVehicle",
  labelallocation = "consignment-api/consignment/labelallocation",
  pickupManagementTotal = "consignment-api/pickup/pickuptotalreport",
  ExitModalDocl = "consignment-api/flow/exitDock",
  EnterModalDock = "consignment-api/flow/enterDock",
  deliveryTotal = "consignment-api/delivery/deliveryTotalReport",
  Barandaz = "consignment-api/flow/assignDock",
  createSubzone = "core-api/hub/zone/createSubzone",
  updateCountryDevision = "core-api/hub/zone/updateCountryDevision",
  createPickupinOrder = "consignment-api/pickup/createPickupinOrder",
  createDeliveryInOrder = "consignment-api/delivery/assigneDeliveryinOrder",
  createDeliveryAsOrder = "consignment-api/delivery/createDeliveryAsOrder",
  vendor = "core-api/vendor",
  vehicleMake = "core-api/vehicleMake",
  vehicle = "core-api/vehicle",
  route = "core-api/route",
  gate = "core-api/gate",
  dock = "core-api/dock",
  exception = "core-api/exception",
  bag = "core-api/bag",
  serviceDefine = "core-api/service",
  productDefine = "core-api/product",
  customer = "customer-api/customer/",
  admVehicle = "core-api/admVehicle/",
  salesChannel = "core-api/saleschannel",
  timeCommitment = "core-api/timecommitment",
  priceList = "core-api/pricelist",
  changePassword = "resource-api/changepassword/changepassword",
  recoveryCode = "resource-api/changepassword/forgotpassword",
  checkRecoveryCode = "resource-api/changepassword/checktoken",
  Type_Hub_table = "core-api/hubCategory",
  productGroup = "core-api/productGroup",
  hubCategory = "core-api/hubCategory",
  BagException = "/core-api/bag/listOfBagException",
  editReceiverAddress = "/consignment-api/consignment/exchange/editConsignmentReceiverAddress",
  checkSuggestionServicesInConsignment = "consignment-api/consignment/checkSuggestionServicesInConsignment",
  consignmentLabelStick = "consignment-api/consignment/ConsignmentLabelStick",
  updateAddressFromPin = "/customer-api/address/updateAddressFromPin",
  groupDeliveryConsignmentBagDeliveryHubCheck = "/consignment-api/delivery/v1/s0/groupDeliveryConsignmentBagDeliveryHubCheck",
}
export enum deleteUrls {
  postConsignment="/consignment-api/consignments/posts/",
  orderReceipe = "/consignment-api/receipt/delete",
  deleteConsignmentByOutOfPlan = "/consignment-api/pudoPlanning/deleteConsignmentByOutOfPlan",
  deleteConsignmentPlaning = "/consignment-api/pudoPlanning/new-process-planing-validation-delete",
  deletefromPlaning = "/consignment-api/pudoPlanning",
  deleteByPudoPlaning = "/consignment-api/pudoPlanning/deleteByPudoPlaning",
  undeleteByPudoPlaning = "/consignment-api/pudoPlanning/undeleteByPudoPlaning",
  salesChannel = "core-api/saleschannel",
  rule = "resource-api/role",
  personnel = "/resource-api/employee",
  servicedeliveryservice = "core-api/servicedeliveryservice",
  customersegment = "core-api/customersegment",
  timeShift = "core-api/calendar/",
  calendar = "core-api/calendar/day",
  removeCalendar = "core-api/calendar/calendar",
  driverPlan = "consignment-api/driverplan",
  assignShift = "consignment-api/driverplan/assignshift",
  employehub = "resource-api/employee/employehub",
  customcountrydevision = "core-api/customcountrydevision/",
  customDevisiondetail = "core-api/customDevisiondetail",
  deleteZone = "core-api/hub/zone/delete",
  deletepolygon = "core-api/hub/zone/deletepolygon",
  deleteBag = "core-api/bag",
  deleteVehicleMove = "consignment-api/vehicletransfer",
  EditSwitch = "core-api/bag/changestatus",
  DeleteVehicleEdit = "consignment-api/vehicletransfer/vehicletransferlicensedetail",
  deleteSubZonePolygon = "core-api/hub/zone/deleteSubZonePolygon",
  productattribute = "core-api/productattribute",
  customGeo = "core-api/customDevisiondetail",
  consignment = "consignment-api/consignment/deleteById",
  order = "consignment-api/consignment/deletebyorderid",
  vendor = "core-api/vendor",
  vehicleMake = "core-api/vehicleMake",
  vehicle = "core-api/vehicle",
  route = "core-api/route",
  gate = "core-api/gate",
  exception = "core-api/exception",
  dock = "core-api/dock",
  bag = "core-api/bag",
  serviceDefine = "core-api/service",
  productDefine = "core-api/product",
  telephone = "customer-api/telephone",
  priceListDetails = "core-api/pricelistdeteil",
  thirdParty = "customer-api/thirdParty",
  customer = "customer-api/customer",
  admVehicle = "core-api/admVehicle",
  timeCommitment = "core-api/timecommitment",
  priceList = "core-api/pricelist",
  address = "customer-api/address",
  connections = "core-api/connection",
  productGroup = "core-api/productGroup",
  deliverydiscount = "cor-api/servicedeliveryservice/deliverydiscount",
  hub = "core-api/hub",
  hubCategory = "core-api/hubCategory",
}
export enum selectUrls {
  selectNationalCode = "/customer-api/customer/findNationalCode?nationalCode=",
  selectContradictionType = "/consignment-api/consignment/contradictionTypeSelect",
  selectVehicleType = "/core-api/vehicleMake/",
  selectHubCategory = "core-api/hubCategory/select?filter=",
  rulesFilter = "resource-api/permission/select",
  roleSelect = "resource-api/role/select?filter=",
  customers = "customer-api/customer/select?filter=",
  customerSuggest = "customer-api/customer/selectSuggest?filter=",
  selectEmployee = "resource-api/employee/select?filter=",
  selectHub = "core-api/hub/select?filter=",
  MainSelectHub = `core-api/hub/selectmainhub?filter=`,
  product = "core-api/product/select?filter=",
  timecommitment = "core-api/timecommitment/select?filter=",
  hubZone = "/core-api/hub",
  findbystate = "core-api/hub/zone/findbycity",
  countrytype = "core-api/countryDevision/countrytype",
  BarandazSelect = "core-api/dock/select",
  SelectDock = "core-api/dock/select?filter=",
  exception = "core-api/exception/exceptiontype",
  selectFleetType = "core-api/vehicle/selectFleetTypes",
  selectVehicleMake = "core-api/vehicleMake/select?filter=",
  selectVehicleCategory = "core-api/vehicleCategory/select?filter=",
  selectfuelTypes = "core-api/vehicleMake/selectFuelTypes",
  selectVehicletype = "core-api/vehicle/vehicletype",
  selectBagTypes = "core-api/bag/selectBagTypes",
  selectProductGroup = "core-api/productGroup/select?filter=",
  selectProducts = "core-api/product/select?filter=",
  selectPriceLists = "core-api/pricelist/select?filter=",
  customerType = "customer-api/customer/selectbycustomertype?filter=&type=",
  route = "core-api/route/select?filter=",
  hub = "core-api/hub/select",
  timeUnitTypes = "core-api/timecommitment/selectTimeUnitTypes",
  vendor = "core-api/vendor/select?filter=",
  countryDevision = "core-api/countryDevision?filter=",
  saleschannel = "core-api/saleschannel/select?filter=",
  customersegment = "core-api/customersegment/select?filter=",
  findByCityInHub = "core-api/countryDevision/findByCityInHub",
}
export enum getUrls {
  consignmentInterception = "/consignment-api/delivery/v1/s0/consignmentInterception",
  settlementStatusSelect = "/consignment-api/invoice/settlementStatusSelect",
  findByConsignmentFromEdit = "/consignment-api/consignment/findByConsignmentFromEdit/",
  sendSmsFromReceipt = "/consignment-api/receipt/sendSmsFromReceipt/",
  sorterExcel = "/consignment-api/consignment/excelExportData/",
  genarallSorterExcel = "/consignment-api/pudoPlanning/pudoPlanningConsignmentListFromSorter/",
  paymentBY = "/consignment-api/invoice/paymentBySelect",
  driverChangeConsignmentBagScan = "/consignment-api/consignment/driverChangeConsignmentBagScan",
  changeDriverTripList = "/consignment-api/trip/changeDriverTripList",
  changeDriverList = "/consignment-api/vehicleplan/changeDriverList",
  findShiftByType = "/core-api/calendar/changeDriverShiftList",
  groupPaymentCostFromCOD = "/consignment-api/driver/delivery/groupPaymentCostFromCOD",
  failReason = "/consignment-api/driver/delivery/deliveryFailedReasonsList",
  acceptUnAssignedPin = "/consignment-api/pudoPlanning/new-process-planing-validation/",
  findAddressFromConsignment = "/consignment-api/pudoPlanning/findAddressFromConsignment/",
  addressDetails = "/customer-api/address/",
  newPlaning = "/consignment-api/pudoPlanning/new-process-planing/",
  finilizePlaning = "/consignment-api/pudoPlanning/finalize-planning/",
  getHub = "/core-api/hub/",
  pudoStation = "/core-api/pudoStation/filter",
  planingConsignment = "/consignment-api/planing-api/getPolygoneInPudoExecutation/",
  pudoReport = "/consignment-api/pudoPlanning/pudoPlanningReport/",
  viewPudoPlaning = "/consignment-api/pudoPlanningExecution/findByToken/",
  findCountryDevisionById = `core-api/countryDevision/findById/`,
  planingOptime = "/consignment-api/pudoPlanning/getDataInOptime/",
  // finilizePlaning="/consignment-api/pudoPlanning/processPlaning/",
  misMatchStatusSelect = "/consignment-api/hallFloor/misMatchStatusSelect",
  driverByHub = "/resource-api/driver/selectByHub",
  lastReturnGoodStatusSelect = "/consignment-api/consignment/LastReturnGoodStatusSelect",
  hubAdmin = "/resource-api/employee/external/findByHubIdSuggest/",
  findTrip = "consignment-api/trip",
  consigmentDate = "consignment-api/ConsignmentHistory/findAccept",
  deleteItemTable = "core-api/servicedeliveryservice/deliverydiscount",
  Filter_servicedeliverycustomers = "core-api/customersegment/select?filter=",
  Fliter_customer = "customer-api/customer/select?filter=",
  selectbycustomertype = "/customer-api/customer/selectbycustomertype",
  Fliter_Service = "core-api/service/select?filter=",
  Filter_saleschannel = "core-api/saleschannel/select?filter=",
  service_provision = "core-api/servicedeliveryservice",
  Product_Group = "core-api/productGroup",
  selectVendor = "core-api/vendor/select?filter=",
  selectBagTypes = "core-api/bag/selectBagTypes",
  hub = "core-api/hub/",
  allHub = "core-api/hub//select?filter=",
  customerSegment = "core-api/customersegment/",
  vehicleDetails = "/core-api/vehicle/",
  month = "core-api/calendar/month/",
  year = "core-api/calendar/year/",
  customerAddressByUsername = "customerAddressByUsername",
  customerPhoneByUsername = "customerPhoneByUsername",
  prospectAddressByUsername = "prospectAddressByUsername",
  prospectPhoneByUsername = "prospectPhoneByUsername",
  findByProspect = "customer-api/prospect/findById",
  countOfAllConsignment = "consignment-api/consignment/countOfAllConsignment",
  getConsignmentTypes = "consignment-api/consignment/getConsignmentTypes",
  getContentTypes = "consignment/getContentTypes",
  getContentTypes2 = "/consignment-api/consignment/getContentTypes",
  getCnDirectionTypes = "consignment-api/consignment/getCnDirectionTypes",
  employee = "resource-api/employee/",
  dispatchShift = "core-api/calendar/dispatchshift",
  countryDevision = "/core-api/countryDevision/",
  deliveryServiceType = "/consignment-api/delivery/servicetype",
  drivers = "resource-api/driver/",
  //change plZ
  findById = "consignment-api/vehicleplan/findbyid/",
  vehiclePlan = "consignment-api/vehicleplan/findbyid/",
  //
  countryDevisionFindbyhub = "core-api/countryDevision/findbyhub",
  personalizationform = "core-api/personalizationform/findbycode",
  hubzone = "core-api/hub/zone/findbyid",
  hubZone = "/core-api/hub/zone",
  employeefindbyhubid = "resource-api/employee/findbyhubid",
  cusotmer = "customer-api/customer/findCustomerByLazy",
  cities = "core-api/countryDevision/province/1/city?filter=",
  regions = "core-api/countryDevision/findbytype?type=2",
  customerSearch = "customer-api/customer/findcustomer?name=",
  vehicleinfo = "core-api/vehicle",
  route = "core-api/route",
  trip = "consignment-api/trip",
  vehicleplan = "consignment-api/vehicleplan/findbyid",
  totalreportTrip = "consignment-api/trip/totalreport",
  selectHubTypes = "core-api/hubCategory/selectHubTypes",
  hubCategory = "core-api/hubCategory/select?filter",
  Tripbybarcode = "consignment-api/flow/findallConsignmentinTrip",
  BagTable = "core-api/bag/filter",
  vehicleMove = "/consignment-api/vehicletransfer/filtervehicletransfer",
  vehicleList = "/consignment-api/vehicletransfer/readytousevehicle",
  LabelTable = "/consignment-api/labelRequest/filter",
  LableStatusFilter = "/consignment-api/labelRequest/labelrequeststatus",
  LableRequestType = "/consignment-api/labelRequest/labelrequesttype",
  CreateSaveRequest = "/consignment-api/labelRequest",
  SendRequestLable = "/consignment-api/labelRequest/changeStatusToAllocationWaiting",
  DeleteLabel = "/consignment-api/labelRequest",
  BarcodeTable = "/consignment-api/labelRequest/changeStatusToAllocated",
  reciveLable = "/consignment-api/labelRequest/receive",
  DownloadTable = "/consignment-api/labelRequest/download",
  GetPlaqueConsigment = "consignment-api/trip/findvehiclebytripno",
  hubLocation = "core-api/hub/hubLocation?filter=",
  bagConsignmentCount = "consignment-api/flow/bagConsignmentCount",
  bagNumberProduct = "consignment-api/flow/consinBag",
  consignmentapidelivery = "/consignment-api/delivery/findbyid",
  findAddressByCity = "customer-api/address/findbycity?userid=",
  triptotalreport = "/consignment-api/trip/triptotalreport",
  addressDetail = "customer-api/address",
  exceptionSeelect = "core-api/exception/select",
  finddestinationhubbytripid = "/consignment-api/trip/finddestinationhubbytripid",
  telephone = "customer-api/telephone/",
  findbyconsignmentidandtype = "consignment-api/failedReasons/findbyconsignmentidandtype",
  vehicleManagementTotal = "consignment-api/vehicleplan/toalrepo/",
  findGate = "core-api/gate",
  findDock = "core-api/dock",
  consignmentFindbylable = "consignment-api/consignment/findbylable",
  currenthubById = "consignment-api/trip/currenthub",
  nexthubhubById = "consignment-api/trip/nexthub",
  findBagNumber = "/core-api/bag/findbynumber",
  AddBagEstesnaModal = "/core-api/bag/createException",
  AddConsigmentEstesna = "/consignment-api/flow/createException",
  AddVehicleEstesna = "core-api/vehicle/createException",
  findByCity = "core-api/countryDevision/findbyCity?filter=",
  selectDok = "/core-api/exception/select?filter=",
  selectGate = "core-api/gate/select?filter=",
  cancelTrip = "consignment-api/trip/cancelthetrip",
  endTrip = "consignment-api/trip/endthetrip",
  GetVehicleByPlaque = "consignment-api/flow/findByTripOrVehicle",
  findConsignmentByPickup = "consignment-api/consignment/findconsignmentbypickup",
  findConsignmentByDelivery = "consignment-api/consignment/findconsignmentbydelivery/",
  address = "customer-api/address/",
  consignmentSelect = "consignment-api/consignment/select",
  consignmentSelectAll = "consignment-api/contentOfConsignment/findall",
  gethubBranch = "core-api/hub/selectbranchhub?filter=",
  getInvoiceStatus = "consignment-api/invoiceSelectStatus",
  getaddress = "customer-api/address/select?filter=",
  consignmentstatusselect = "consignment-api/consignment/consignmentstatusselect",
  sendertypeselect = "consignment-api/pickup/sendertypeselect",
  basetypeselect = "core-api/service/basetypeselect",
  additionaltypeselect = "core-api/service/additionaltypeselect",
  consignmentStatus = "consignment-api/consignment/consignmentstatusselect",
  dispatchShiftById = "core-api/calendar/findshiftbyid",
  findbyparentTripId = "consignment-api/trip/findbyparentId",
  getDriver = "resource-api/driver",
  findByRegion = "core-api/hub/zone/findByRegion",
  findByRegionCountryDevision = "core-api/hub/zone/findByRegionCountryDevision",
  findzonebyaddressidandtype = "consignment-api/consignment/findzonebyaddressidandtype",
  countryDevisionfindById = "core-api/countryDevision/findById",
  findPlaqueByBarandaz = "consignment-api/flow/findbytripOrvehicledockallocation",
  findPlaqueByEnterBarandaz = "consignment-api/flow/findbytriporvehiclescandockenter",
  findPlaqueByExitBarandaz = "consignment-api/flow/findbytriporvehiclescanexitdock",
  findPlaqueExitDoor = "consignment-api/flow/findbytriporvehicleexitgate",
  findMarsoule = "consignment-api/label?filter=",
  findByVehicle = "core-api/vehicle/",
  findByCityHub = "core-api/hub/findByCityinHub/",
  getStatusBag = "core-api/bag/reportTotal",
  invoiceReportinfo = "consignment-api/flow/invoiceReportinfo",
  invoiceReport = "consignment-api/flow/invoiceReport",
  contentOfConsignment = "consignment-api/contentOfConsignment/",
  findByConsignmentService = "consignment-api/consignment/findByConsignmentService/",
  service = "core-api/service/",
  findByTripNumber = "consignment-api/trip/findbyno/",
  findShiftInTrip = "consignment-api/vehicleplan/findShiftInTrip",
  findByPickupinTrip = "consignment-api/pickup/findByPickupinTrip",
  connection = "core-api/connection/filter",
  tripstatus = "consignment-api/trip/tripstatus",
  orderByid = "consignment-api/order/findbyid",
  triptype = "consignment-api/trip/triptype",
  findByOrder = "consignment-api/consignment/order/",
  ordersuggest = "consignment-api/order/select",
  thirdPartyCategory = "customer-api/thirdParty/thirdPartyCategory",
  thirdPartyType = "customer-api/thirdParty/thirdPartyType",
  thirdPartyParent = "customer-api/thirdParty/selecttype?filter=",
  customerType = "customer-api/customer/customerType",
  consignmentType = "core-api/pricelist/consignmenttype",
  userName = "resource-api/employee/info?username=",
  addressType = "customer-api/address/addressType",
  phoneType = "customer-api/telephone/phoneType",
  get_hub_type = "core-api/hubCategory/selectHubTypes",
  select_hub_category = "core-api/hubCategory/select?filter",
  get_select_province = "core-api/countryDevision?filter=",
  Mainhub = "core-api/hub",
  get_province_city = "core-api/countryDevision/province/1/city?filter=",
  Edithub_category = "core-api/hubCategory",
  pickupFailed = "consignment-api/pickup/pickupFailed/select?filter=",
  contentOfConsignments = "consignment-api/contentOfConsignment/select?filter=",
  findCustomer = "customer-api/customer/findcustomer/select?filter=",
  vehiclePlanByTripId = "consignment-api/vehicleplan/findByVehiclePlanInTrip/",
  findByVehicleId = "core-api/vehicle/findById/",
  invoiceStatus = "consignment-api/consignment/invoiceStatusSelect",
  findByConsignmentAllService = "consignment-api/consignment/suggest",
  findAllInvoiceDetailsInOrder = "consignment-api/invoice/findAllInvoiceDetailsInOrder/",
  invoiceAssign = "consignment-api/flow/invoiceAssign/",
  findByUser = "resource-api/employee/findByUser/",
  typesOfPackageList = "consignment-api/driver/pickup/typesOfPackageList/",
  suggestDetailsInConsignment = "consignment-api/consignment/suggestDetailsInConsignment/",
  vehicleStatus = "core-api/vehicle/vehicleStatus",
  addressCorrectionData = "customer-api/address/addressCorrectionData",
  cancelUpdateAddressStatus = "customer-api/address/cancelUpdateAddressStatus/",
  addressCorrectionDataIsDefault = "customer-api/address/addressCorrectionDataIsDefault",
  testTashim = "consignment-api/invoice/testTashim",
  paymentHistoryDetails = "/customer-api/agentJournalLedger/paymentHistoryDetails",
  getAddressForConsignmentByCprNumber = "consignment-api/consignment/getReceiverAddressIdByCprNumber",
  editAddressForConsignment = "consignment-api/consignment/addressCorrection",
  depositPaymentType = "/customer-api/subsidiaryLedger/depositPaymentType",
  depositPaymentViaSelect = "/customer-api/subsidiaryLedger/depositPaymentViaSelect",
  invoiceDetail = "/consignment-api/invoice/findByInvoiceDetailByConsignmentId/",
  codPaymentHitory = "/consignment-api/consignment/codPaymentHistory",
  checkTerminal = "/consignment-api/payments/saman-kish/first-available-terminal",
  terminals = "/consignment-api/payments/saman-kish/terminals",
  consignmentByCPR = "/consignment-api/consignments/barcodes/html?",
  exportPaymentReport = "/consignment-api/invoice/exportPaymentReport",
  getDrivers = "/resource-api/driver/select",
}
