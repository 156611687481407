const RotatoryBoxesIcon = ({ className }: any) => (
  <svg
    className={`text-tomato ${className}`}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33331 18.832L10.6666 21.1654L8.33331 23.4987"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 16.5V18.8333C2.5 20.1167 3.55 21.1667 4.83333 21.1667H10.6667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 9.4987V7.16536C23.5 5.88203 22.45 4.83203 21.1666 4.83203H15.3333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6666 7.16667L15.3333 4.83333L17.6666 2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8333 9.38229V4.94896C11.8333 4.48229 11.6 4.01563 11.1333 3.78229L7.28331 1.56563C6.81665 1.33229 6.34998 1.33229 5.88331 1.56563L2.03331 3.78229C1.56665 4.01563 1.33331 4.48229 1.33331 4.94896V9.38229C1.33331 9.84896 1.56665 10.3156 2.03331 10.549L5.88331 12.7656C6.34998 12.999 6.81665 12.999 7.28331 12.7656L11.1333 10.549C11.6 10.3156 11.8333 9.84896 11.8333 9.38229Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.56665 4.25L6.58332 7.16667L11.6 4.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.58333 7.16406V12.9974"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6666 21.0503V16.6169C24.6666 16.1503 24.4333 15.6836 23.9666 15.4503L20.1166 13.2336C19.65 13.0003 19.1833 13.0003 18.7166 13.2336L14.8666 15.4503C14.4 15.6836 14.1666 16.1503 14.1666 16.6169V21.0503C14.1666 21.5169 14.4 21.9836 14.8666 22.2169L18.7166 24.4336C19.1833 24.6669 19.65 24.6669 20.1166 24.4336L23.9666 22.2169C24.4333 21.9836 24.6666 21.5169 24.6666 21.0503Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4 15.9141L19.4166 18.8307L24.4333 15.9141"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4166 18.832V24.6654"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RotatoryBoxesIcon;
