import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterPlanning: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "planning",
  async (body: any) => {
    const data = await filterTableDataAPI(
      filterUrls.planningFilter,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true
    );

    data.data.content = data.data.content.map((item) => ({
      ...item,
      loading: false,
      finalizeLoading:false
    }));

    return data.data;
  }
);

const initialState: any = {
  planingData: [],
  fetchPost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const PlaningLists = createSlice({
  initialState: initialState,
  name: "planning",
  reducers: {
    clearPlanningData: (state) => {
      state.planingData = [];
    },
    setFilterPlanningData: (state, action) => {
      state.filter = action.payload;
    },
    updateLoading: (state, action) => {
      const loadType=action.payload.viewLoading ? "loading":"finalizeLoading"

      state.planingData.content = state.planingData.content.map((item) =>
        item.id === action.payload?.id
          ?loadType==="loading"? { ...item, loading: !item.loading }:{...item, finalizeLoading: !item.finalizeLoading }
          : item
      );
    },
  },
  extraReducers: {
    [filterPlanning.fulfilled as any]: (state, action) => {
      state.planingData = action.payload;
      state.fetchPost = false;
    },
    [filterPlanning.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterPlanning.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterPlanningData, clearPlanningData, updateLoading } =
  PlaningLists.actions;
export default PlaningLists.reducer;
