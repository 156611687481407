
import { getCurrentTime, getToday } from "./portal/helper";
import {QRCodeCanvas} from 'qrcode.react';

function TripPdf({ item }) {

  const { trip, connections } = item;
  const totalBox=connections.reduce(
       (acc, curr) => acc + curr?.bagCount,
      0
    );
  const totalOutBox=connections.reduce(
    (acc, curr) => acc + curr?.noBagConsignment,
   0
 );

  return (
    <>
      <QRCodeCanvas
        value={trip.tripNo}
        size={128}
        level={"H"}
        includeMargin={false}
        id="qrCodeEl"
      />
      <table hidden id="header-table-multi" className="border   w-full">
        <tbody>
          <tr>
            <td></td>
            <td className="centerText">رسید سفر </td>
            <td>{trip.tripNo} :شماره سفر</td>
          </tr>
          <tr>
            <td> </td>
            <td className="centerText"></td>
            <td>تاریخ : {getToday()}</td>
          </tr>
          <tr>
            <td></td>
            <td className="centerText"></td>
            <td>ساعت تنظیم:{getCurrentTime()}</td>
          </tr>
        </tbody>
      </table>
      <table hidden id="my-table-multi" className="border  w-full">
        <tbody>
          <tr>
            <td> تایید هاب مقصد </td>
            <td>توضیحات </td>
            <td className="center"> تعداد بسته خارج از باکس </td>
            <td> تعداد باکس </td>
            <td>هاب مقصد</td>
            <td> ردیف </td>
          </tr>
          {connections.map((item, index) => (
            <tr>
              <td>{}</td>
              <td>{}</td>
              <td className="center">{item?.noBagConsignment}</td>
              <td className="center">{item?.bagCount}</td>
              <td className="center">{item?.selectHub?.text}</td>
              <td>{index+1}</td>
            </tr>
          ))}
          <tr>
            <td className="total"> </td>
            <td className="total"> </td>
            <td className="center"> {totalOutBox} </td>
            <td> {totalBox} </td>
            <td> جمع</td>
            <td className="total"> </td>
          </tr>
        </tbody>
      </table>

      <table hidden id="footer-table" className="border  w-full">
        <tbody className="footer">
          <tr>
            <td className="footer">
              :نام راننده
              <br />
              <br />
              {trip.firstdriver}
            </td>
            <td className="footer">
              نوع وسیله نقلیه:{trip.Transportmodel}
              <br />
              <br />
              پلاک: {trip.Vehicle}
            </td>

            <td className="footer">
              نام صادر کننده
              <br />
              <br />
              {trip.author}
            </td>
            <td className="footer">
              هاب مبدا: {trip.initHub}
              <br />
              <br />
              {trip.initHubCode}: کد هاب
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default TripPdf;
