import MapComponent from "components/map/MapComponent";
import OptimeModal from "components/optimeModal/OptimeModal";
import { useRef, useState } from "react";
import Sidebar from "./views/Sidebar";
import MapOperation from "./views";
import { useSelector } from "react-redux";
import ChangeFlyView from "components/map/ChangeFlyView";

const OptimePanel = () => {
  const refMap = useRef({});
  const [optimeData, setOptimeData] = useState<any>();
  const { view } = useSelector((state: any) => state.optimeModal);

  return (
    <div className="w-full h-screen !p-[0px]">
      <div className="flex">
        <div className="w-[48px] h-screen bg-[#1e293b] z-40 border-l border-[#94a3b8]">
          <Sidebar />
        </div>

        <div className="w-[calc(100%-50px)] optime">
          <MapComponent zoom={view.zoom} ref={refMap}>
            <MapOperation
              optimeData={optimeData}
              setOptimeData={setOptimeData}
            />
            {view.lat && view.lng && (
              <ChangeFlyView center={[view.lat, view.lng]} zoom={view.zoom} />
            )}
          </MapComponent>
        </div>
      </div>
      <OptimeModal optimeData={optimeData} setOptimeData={setOptimeData} />
    </div>
  );
};

export default OptimePanel;
