import SimpleButton from "global/SimpleButton/SimpleButton";
import {
  checkPlaningCondition,
  convertDriverData,
  convertResponse,
} from "pages/optimePanel/utils";
import { ReactComponent as Motorcycle } from "../../../../../../assets/icons/motorcycle.svg";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { addEditDataAPI, getAPI } from "services/CRUDServices";
import { addEditUrls, getUrls } from "services/api.enums";
import {
  ActionDeletedPolygon,
  ActionModals,
  ActionSelectPins,
} from "redux/optime/optime";
import { useEffect, useState } from "react";
import { RiTruckFill } from "react-icons/ri";
import { toast } from "react-toastify";
import PudoConsignment from "./pudo-consignments";
import { Confirm } from "pages/optimePanel/components/confirm-modal";

interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
}

const InfoPudoPolygon = ({ optimeData, setOptimeData }: MapOperationProps) => {
  const { infoPolygon } = useSelector((state: any) => state.optimeModal);
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState({
    assignableWeight: "",
    assignableVolume: "",
  });

  const handelDelete = () => {
    const { consignmnetList, polygonId } = infoPolygon;
    const deleteBody = {
      id: polygonId,
      consignmentList: consignmnetList.map((item) => ({
        id: item.consignmentId,
      })),
    };
    const findIndex = optimeData.polygons.findIndex(
      (item) => item.polygonId === polygonId
    );
    setLoading(true);
    addEditDataAPI(addEditUrls.deleteSubzonePudo, "post", deleteBody)
      .then((res) => {
        setLoading(false);
        dispatch(ActionDeletedPolygon(findIndex));
        setOpen(false);
        dispatch(ActionSelectPins([]));
        getAPI(getUrls.planingConsignment + id).then((res) => {
          const convert = convertResponse(res);
          setOptimeData(convert);
          dispatch(ActionModals());
          toast.success("محدوده عملیاتی با موفقیت حذف شد", {
            position: "top-left",
          });
        });
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    const findVehicle = state.pudoVehicle.find(
      (item) => item.vehicleId === infoPolygon?.pudoVehicle?.[0].vehicleId
    );
    findVehicle?.id &&
      getAPI(getUrls.vehicleDetails + findVehicle.id).then((res) => {
        setVehicleInfo({
          assignableVolume: res.data.assignableVolume,
          assignableWeight: res.data.assignableWeight,
        });
      });
  }, [infoPolygon.polygonId]);

  return (
    <div className="mt-10">
      {infoPolygon && infoPolygon.length !== 0 && (
        <>
          {checkPlaningCondition(state) && (
            <div>
              <SimpleButton
                text="حذف محدوده عملیاتی"
                className="bg-red text-white blobk w-full"
                handelClick={() => setOpen(true)}
                // loading={loading}
              />
            </div>
          )}

          <div className="grid2 mt-4 gap-y-5">
            <span className="flex-start-center gap-2 text-white col-span-4">
              <RiTruckFill size={25} />
              <span>
                {infoPolygon?.pudoMdlVehicle
                  ? convertDriverData(infoPolygon?.pudoMdlVehicle?.[0])?.text
                  : ""}
              </span>
            </span>
            <span className="flex-start-center gap-2 text-white col-span-4">
              <Motorcycle />
              <span>
                {infoPolygon?.pudoVehicle
                  ? convertDriverData(infoPolygon?.pudoVehicle?.[0])?.text
                  : ""}
              </span>
            </span>

            <span className="flex-start-center gap-2 text-white col-span-4">
              <p className="text-white">ایستگاه: </p>
              <span>
                {infoPolygon?.station
                  ? infoPolygon?.station.map(
                      (item) => item.name + "-" + item.addressLine
                    )
                  : ""}
              </span>
            </span>
          </div>
          <div className="grid2 mt-8">
            <span className="flex-start-center gap-1 text-white ">
              <p className="text-white"> وزن قابل تخصیص : </p>
              <span>{vehicleInfo.assignableWeight}</span>
            </span>
            <span className="flex-start-center gap-1 text-white">
              <p className="text-white">حجم قابل تخصیص : </p>
              <span>{vehicleInfo.assignableVolume}</span>
            </span>

            <span className="flex-start-center gap-1 text-white">
              <p className="text-white"> وزن تخصیص یافته : </p>
              <span>
                {infoPolygon.consignmnetList
                  .reduce((acc, curr) => acc + curr.weight!, 0)
                  .toFixed(3)}
              </span>
            </span>
            <span className="flex-start-center gap-1 text-white">
              <p className="text-white">حجم تخصیص یافته : </p>
              <span>
                {infoPolygon.consignmnetList
                  .reduce((acc, curr) => acc + curr.volume!, 0)
                  .toFixed(3)}
              </span>
            </span>

            <span className="flex-start-center gap-1 text-white">
              <p className="text-white"> تعداد مرسولات : </p>
              <span>{infoPolygon.consignmnetList.length}</span>
            </span>
          </div>
        </>
      )}

      <PudoConsignment
        consignments={infoPolygon.consignmnetList}
        optimeData={optimeData}
        setOptimeData={setOptimeData}
      />

      <Confirm
        open={open}
        setOpen={setOpen}
        title={"آیا از حذف این مورد اطمینان دارید؟"}
        handleClick={handelDelete}
        loading={loading}
      />
    </div>
  );
};

export default InfoPudoPolygon;
