import React from 'react'

function useHandleKeyDown(startListening,stopListening) {
    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLInputElement>,
        fieldName: string,
        isNumber?: boolean,
        type?: string,
        option?: any
      ) => {
      
        if (event.key === "ArrowUp") {
          startListening(fieldName, isNumber, type, option);
          event.preventDefault();
        }
        if (event.key === "ArrowDown" || event.key ==="Tab") {
          stopListening();
        }
      };


      return {handleKeyDown}
}

export default useHandleKeyDown