import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { useFormik } from "formik";
import InputSelect from "global/InputSelect/InputSelect";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { Confirm } from "pages/optimePanel/components/confirm-modal";
import {
  checkPlaningCondition,
  convertDriverData,
  convertResponse,
} from "pages/optimePanel/utils";
import React, { useEffect, useState } from "react";
import { AiFillEdit, AiFillSave } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { RiTruckFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ActionSidebar } from "redux/optime/optime";
import { addEditDataAPI, filterDataAPI, getAPI } from "services/CRUDServices";
import { addEditUrls, getUrls } from "services/api.enums";
import { ReactComponent as Motorcycle } from "../../../../../assets/icons/motorcycle.svg";

interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
}

const InfoMdlPolygon = ({ optimeData, setOptimeData }: MapOperationProps) => {
  const [open, setOpen] = React.useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { state } = useLocation();
  const [showList, setShowList] = useState(false);
  const [edit, setEdit] = useState({ editDriver: false, editStation: false });
  const [loading, setLoading] = useState({ delete: false, save: false });
  const [currentIndex, setCurrentIndex] = useState();
  const [currentPolygons, setCurrentPolygons] = useState([]);
  const [selectPolygon, setSelectPolygon] = useState<any>();
  const [vehicleInfo, setVehicleInfo] = useState({
    assignableWeight: "",
    assignableVolume: "",
    assignableMDLWeight: "",
    assignableMDLVolume: "",
  });
  const { mdlTrips } = useSelector((state: any) => state.optimeModal);
  const { id } = useParams();
  const dispatch = useDispatch();
  const handelDelete = () => {
    // event.preventDefault();
    // event.stopPropagation();
    const body = {
      id: selectPolygon.polygonId,
      consignmentList: selectPolygon.consignmnetList.map((item) => ({
        id: item.consignmentId,
      })),
    };

    setLoading({ ...loading, delete: true });
    addEditDataAPI(addEditUrls.deleteSubzoneMdl, "post", body)
      .then(() => {
        // setOpen(false);
        getAPI(getUrls.planingConsignment + id).then((res) => {
          const convert = convertResponse(res);
          setOptimeData(convert);
          setLoading({ ...loading, delete: false });
          dispatch(ActionSidebar("multiSelect"));
          setOpenConfirm(false);
        });
      })
      .catch(() => setLoading({ ...loading, delete: false }));
    
  };
  const [optionsData, setOptionsData] = useState<any>({
    stations: [],
    mdlVehicle: [],
  });

  const CUSTOM_ANIMATION = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      station: mdlTrips?.map((item) => ({
        id: item.station[0].id,
        text: item.station[0].name,
      })),
      pudoVehicleId: mdlTrips?.map((item) => ({
        id: item.pudoVehicle[0].vehicleId,
        text: item.pudoMdlVehicle[0].driver.text,
      })),
    },
    onSubmit: (values) => {},
  });

  const { values, setFieldValue }: any = formik;

  useEffect(() => {
    filterDataAPI(getUrls.pudoStation, {}).then((res) => {
      const convertStation = res.data.payload?.content.map((item) => ({
        text: item.name + item.addressLine,
        id: item.id,
      }));
      setOptionsData({ ...optionsData, stations: convertStation });
    });
  }, [optimeData]);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
    setShowList(false);
  };

  function editStation(item, index) {
    const finalData = {
      selectStation: values.station?.[index],
      selectMdlVehicle: values.pudoVehicleId[index] || {
        id: item?.pudoMdlVehicle[0]?.vehicleId,
      },
      selectExecutation: { id: +id, text: "" },
      consignmentList: [item.consignmnetList],
    };

    setLoading({ ...loading, save: true });
    addEditDataAPI(addEditUrls.addMdlandStation, "post", finalData)
      .then((res) => {
        getAPI(getUrls.planingConsignment + id).then((res) => {
          const convert = convertResponse(res);
          setOptimeData(convert);
        });
        setLoading({ ...loading, save: false });
        setEdit({ ...edit, editDriver: false, editStation: false });
      })
      .catch(() => setLoading({ ...loading, save: false }));
  }

  function getVehicleInfo(data) {
    if (showList) return;

    const findMDLVehicle = state.mdlVehicle.find(
      (item) => item.vehicleId === data?.pudoMdlVehicle?.[0].vehicleId
    );

    const findpolygon = optimeData?.polygons?.filter(
      (trip) =>
        trip?.pudoMdlVehicle?.[0]?.vehicleId === findMDLVehicle?.vehicleId
    );
    setCurrentPolygons(findpolygon);

    //this section is commented for get assignedweight and assignedVolume
    // const findVehicle = state.pudoVehicle.filter((item) =>
    //   findpolygon.find((el) => el.pudoVehicle[0].vehicleId === item.vehicleId)
    // )

    // Promise.all(
    //   findVehicle.map((item) => {
    //     return new Promise((resolve) => {
    //       getAPI(getUrls.vehicleDetails + item.id).then((res) => {
    //         // console.log("res.data",res.data)
    //         setVehicleInfo({
    //           ...vehicleInfo,
    //           assignableVolume: res.data.assignableVolume,
    //           assignableWeight: res.data.assignableWeight,
    //         });
    //       });
    //     });
    //   })
    // ).then(() => {

    // });
  }

  function getMDLVehicleInfo(data) {
    const findVehicle = state.mdlVehicle.find(
      (item) => item.vehicleId === data?.pudoMdlVehicle?.[0].vehicleId
    );
    getAPI(getUrls.vehicleDetails + findVehicle.vehicleId).then((res) => {
      setVehicleInfo({
        ...vehicleInfo,
        assignableMDLVolume: res.data.assignableVolume,
        assignableMDLWeight: res.data.assignableWeight,
      });
    });
  }

  return (
    <div>
      {mdlTrips?.map((item, index) => (
        <Accordion
          open={open === item.polygonId}
          icon={
            <div className=" inline-flex gap-1 ">
              {loading.delete ? (
                <ClipLoader size={15} color="#ef5644" />
              ) : (
                checkPlaningCondition(state) && (
                  <BiTrash
                    onClick={() => {
                      setOpenConfirm(true);
                      setSelectPolygon(item);
                    }}
                    className="text-white"
                    size={20}
                  />
                )
              )}
            </div>
          }
          animate={CUSTOM_ANIMATION}
          className={`mb-2 rounded-lg border  border-blue-gray-100 px-4 z-[0] overflow-y-auto  ${
            showList && index === currentIndex ? "h-[70vh]" : ""
          }`}
        >
          <AccordionHeader
            onClick={() => {
              handleOpen(item.polygonId);
              setCurrentIndex(index);
              getMDLVehicleInfo(item);
            }}
            className={`border-b-0 transition-colors `}
          >
            <span className="text-white text-sm">
              {convertDriverData(item.pudoMdlVehicle[0])?.text}
            </span>
          </AccordionHeader>
          <AccordionBody className=" ">
            <div className="flex  gap-6 text-white flex-col">
              <div className="flex justify-between">
                <p className="text-white">راننده:</p>
                <div className="flex gap-2 ">
                  {edit.editDriver ? (
                    <InputSelect
                      options={state?.mdlVehicle}
                      values={values.pudoVehicleId[index]}
                      name={`pudoVehicleId.${index}`}
                      handleChange={setFieldValue}
                      wrapperClassName="w-[10rem]   z-[99999999]"
                      positionFixed
                    />
                  ) : (
                    <p className="text-white">
                      {convertDriverData(item?.pudoMdlVehicle[0])?.text}
                    </p>
                  )}
                  {edit.editDriver ? (
                    <div className=" flex gap-1 mt-2">
                      <MdCancel
                        color="white"
                        className="cursor-pointer"
                        size={25}
                        onClick={() => setEdit({ ...edit, editDriver: false })}
                      />
                      {loading.save ? (
                        <ClipLoader size={15} color="#ef5644" />
                      ) : (
                        <AiFillSave
                          color="white"
                          className="cursor-pointer"
                          size={25}
                          onClick={() => editStation(item, index)}
                        />
                      )}
                    </div>
                  ) : (
                    checkPlaningCondition(state) && (
                      <AiFillEdit
                        color="white"
                        className="cursor-pointer"
                        size={25}
                        onClick={() => setEdit({ ...edit, editDriver: true })}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="flex justify-between">
                <p className="text-white">ایستگاه:</p>

                <div className="flex gap-1">
                  {edit.editStation ? (
                    <InputSelect
                      options={optionsData?.stations || []}
                      values={values.station[index]}
                      name={`station.${index}`}
                      handleChange={setFieldValue}
                      wrapperClassName="w-[10rem]  "
                      positionFixed
                    />
                  ) : (
                    <p className="text-white">
                      {item?.station?.[0]?.name +
                        " " +
                        item?.station?.[0]?.addressLine}
                    </p>
                  )}

                  {edit.editStation ? (
                    <div className=" flex gap-1 mt-2">
                      <MdCancel
                        color="white"
                        className="cursor-pointer"
                        size={25}
                        onClick={() => setEdit({ ...edit, editStation: false })}
                      />
                      {loading.save ? (
                        <ClipLoader size={15} color="#ef5644" />
                      ) : (
                        <AiFillSave
                          color="white"
                          className="cursor-pointer "
                          size={25}
                          onClick={() => editStation(item, index)}
                        />
                      )}
                    </div>
                  ) : (
                    checkPlaningCondition(state) && (
                      <AiFillEdit
                        color="white"
                        className="cursor-pointer"
                        size={25}
                        onClick={() => setEdit({ ...edit, editStation: true })}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="grid2 justify-between mt-6">
              <span className=" gap-1 text-white">
                <span>وزن تخصیص یافته : </span>
                <span>
                  {" "}
                  {item.consignmnetList
                    .reduce((acc, curr) => acc + curr.weight!, 0)
                    .toFixed(3)}
                </span>
              </span>
              <span className=" gap-1 text-white">
                <span>حجم تخصیص یافته : </span>
                <span>
                  {" "}
                  {item.consignmnetList
                    .reduce((acc, curr) => acc + curr.volume!, 0)
                    .toFixed(3)}
                </span>
              </span>

              <span className=" gap-1 text-white">
                <span> وزن قابل تخصیص : </span>
                <span> {vehicleInfo.assignableMDLWeight}</span>
              </span>
              <span className=" gap-1 text-white">
                <span> حجم قابل تخصیص : </span>
                <span> {vehicleInfo.assignableMDLVolume}</span>
              </span>

              <span className=" gap-1 text-white">
                <span> تعداد : </span>
                <span> {item.consignmnetList.length}</span>
              </span>
            </div>

            {showList &&
              currentPolygons.map((polygon) => (
                <>
                  <hr className="my-2" />
                  <div className="grid2 mt-8 gap-y-5">
                    <span className="flex-start-center gap-2 text-white col-span-4">
                      <RiTruckFill size={25} />
                      <span>
                        {polygon?.pudoMdlVehicle
                          ? convertDriverData(polygon?.pudoMdlVehicle?.[0])
                              ?.text
                          : ""}
                      </span>
                    </span>
                    <span className="flex-start-center gap-2 text-white col-span-4">
                      <Motorcycle />
                      <span>
                        {polygon?.pudoVehicle
                          ? convertDriverData(polygon?.pudoVehicle?.[0])?.text
                          : ""}
                      </span>
                    </span>
                  </div>
                  <div className="grid2 mt-6">
                    <span className="flex-start-center flex-wrap gap-1 text-white ">
                      <p className="text-white"> وزن قابل تخصیص : </p>
                      <span>{(+vehicleInfo.assignableWeight).toFixed(2)}</span>
                    </span>
                    <span className="flex-start-center flex-wrap gap-1 text-white">
                      <p className="text-white">حجم قابل تخصیص : </p>
                      <span>{(+vehicleInfo.assignableVolume).toFixed(2)}</span>
                    </span>

                    <span className="flex-start-center flex-wrap gap-1 text-white">
                      <p className="text-white"> وزن تخصیص یافته : </p>
                      <span>
                        {polygon.consignmnetList
                          .reduce((acc, curr) => acc + curr.weight!, 0)
                          .toFixed(3)}
                      </span>
                    </span>
                    <span className="flex-start-center flex-wrap gap-1 text-white">
                      <p className="text-white">حجم تخصیص یافته : </p>
                      <span>
                        {polygon.consignmnetList
                          .reduce((acc, curr) => acc + curr.volume!, 0)
                          .toFixed(3)}
                      </span>
                    </span>

                    <span className="flex-start-center flex-wrap gap-1 text-white">
                      <p className="text-white"> تعداد مرسولات : </p>
                      <span>{polygon.consignmnetList.length}</span>
                    </span>
                  </div>
                  <hr className="my-2" />
                </>
              ))}

            <SimpleButton
              text={!showList ? "مشاهده همه" : "بستن"}
              className="bg-red text-white blobk w-full mt-6"
              handelClick={() => {
                setShowList(!showList);
                getVehicleInfo(item);
              }}
            />
          </AccordionBody>
        </Accordion>
      ))}

      <Confirm
        open={openConfirm}
        setOpen={setOpenConfirm}
        title={"آیا از حذف این مورد اطمینان دارید؟"}
        handleClick={handelDelete}
        loading={loading.delete}
      />
    </div>
  );
};

export default InfoMdlPolygon;
