import ConsignmentsPayment from "pages/ConsignmentManageOperation/view/AddOrderForm/payment/ConsignmentsPayment";
import { lazy } from "react";
import PostOrderManagement from "./order-management-easy";
import OrderReceipt from "./order-receipt";


const ChangeDriver=lazy(()=>import('./change-driver'))
const CollectManagementMap = lazy(
  () => import("pages/CollectManagement/map/CollectManagementMap")
);

const AddConsignment = lazy(
  () =>
    import(
      "pages/ConsignmentManageOperation/view/AddOrderForm/AddConsignmentForm/AddConsignmentForm"
    )
);
const AddOrder = lazy(
  () =>
    import("pages/ConsignmentManageOperation/view/AddOrderForm/AddOrderForm")
);
const CustomerManagement = lazy(
  () => import("pages/CustomerManagement/CustomerManagement")
);
const GeoWrapper = lazy(
  () => import("pages/CustomGeographic/views/AddGeo/GeoWrapper")
);
const GeoWrapperEdit = lazy(
  () => import("pages/CustomGeographic/views/editGeo/GeoWrapperEdit")
);
const DeliveryManageMentMap = lazy(
  () => import("pages/DeliveryManagement/Map/DeliveryManageMentMap")
);

const AddHubOrder = lazy(
  () => import("pages/OrderManagement/views/Order/AddHubOrder")
);

const TripManagementMap = lazy(
  () => import("pages/TripManagement/map/TripManagementMap")
);
const TripMap = lazy(() => import("pages/Trips/map/TripMap"));
const CaseTimeShift = lazy(
  () => import("pages/TRMManagement/ShiftMnagement/CaseShiftManagement")
);
const SampleTimeShift = lazy(
  () => import("pages/TRMManagement/ShiftMnagement/SampleShiftManagement")
);

export const routes = [
  {
    to: "/basic-information/custom-geographic-category/add",
    component: <GeoWrapper />,
  },
  {
    to: "/basic-information/custom-geographic-category/edit",
    component: <GeoWrapperEdit />,
  },
  {
    to: "/time-shift-manage/sample-time-shift/:id",
    component: <SampleTimeShift />,
  },
  {
    to: "/time-shift-manage/case-time-shift/:id",
    component: <CaseTimeShift />,
  },
  {
    to: "/trip-management-map",
    component: <TripManagementMap />,
  },
  {
    to: "/trip-map",
    component: <TripMap />,
  },
  {
    to: "/Collect-management-map",
    component: <CollectManagementMap />,
  },
  {
    to: "/delivery-management-map",
    component: <DeliveryManageMentMap />,
  },
  {
    to: "add-order",
    component: <AddOrder />,
  },
  {
    to: "add-hub-order",
    component: <AddHubOrder />,
  },
  {
    to: "add-consignment",
    component: <AddConsignment />,
  },
  {
    to: "consignments-payment",
    component: <ConsignmentsPayment />,
  },
  {
    to: "basic-information/customer-management/:openAddModal",
    component: <CustomerManagement />,
  },
  {
    to: "add-fast-order",
    component: <PostOrderManagement />,
  },
  {
    to:"change-driver",
    component: <ChangeDriver/>
  },

];
