import { QueryFunctionContext } from "react-query";
import request from "services/axios";
export const getExeptions = async (ctx: QueryFunctionContext) => {
  if (ctx.queryKey[1]) {
    const { data }: { data: IGetExceptions } = await request.get(
      `core-api/exception/select?filter=`
    );
    return data;
  }
};

export const getServiceTypes = async (ctx: QueryFunctionContext) => {
  if (ctx.queryKey[1]) {
    const { data }: { data: IServiceTypes } = await request.get(
      `/consignment-api/delivery/servicetype`
    );
    return data;
  }
};

export const getConsignmentStatus = async (ctx: QueryFunctionContext) => {
  if (ctx.queryKey[1]) {
    const { data }: { data: IServiceTypes } = await request.get(
      `consignment-api/consignment/consignmentstatusselect`
    );
    return data;
  }
};
export const getConsignmentTypes = async (ctx: QueryFunctionContext) => {
  if (ctx.queryKey[1]) {
    const { data }: { data: IServiceTypes } = await request.get(
      `consignment-api/consignment/getConsignmentTypes`
    );
    return data;
  }
};
