import { useEffect } from "react";
import { useQuery } from "react-query";
import { getExeptions } from "services/options";

export default function useGetExeptions() {
  const token = localStorage.getItem("token");
  const { data, isLoading } = useQuery(
    ["core-api-exceptions", token],
    getExeptions
  );
  useEffect(() => {
    if (data && !isLoading) {
      localStorage.setItem(
        "coreApiException",
        JSON.stringify(data.payload.content)
      );
    }
  }, [data, isLoading]);
}
