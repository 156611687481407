import { FC, useCallback, useEffect, useState } from "react";
import MultiSelect from "../../global/multiselect/MultiSelect";
import { filterUrls } from "../../services/api.enums";
import { filterDataAPI, getAPI } from "../../services/CRUDServices";
import MultiSelectAll from "global/multiselect/multi-select-all";

interface ProvincesProps {
  form: any;
  currentData?: any;
}
const Provinces: FC<ProvincesProps> = ({ form, currentData }): JSX.Element => {
  const [countryOptions, setCountryOptions] = useState([]);
  // const [locationOption, setLocationOption] = useState({
  //   fromLocation: [],
  //   toLocation: [],
  // });

  const [cityOption, setCityOption] = useState({
    fromCity: [],
    toCity: [],
  });

  const getOptionsData = useCallback(async () => {
    try {
      const res = await getAPI(filterUrls.countryDevision);
      setCountryOptions(res?.data?.payload?.content);
    } catch (error) {}
  }, []);

  const getOptionsFromCity = useCallback(
    async (data: any, type: "from" | "to") => {
      try {
        const res = await filterDataAPI(
          filterUrls.countryDevisionNoFIlter,
          data,
          true,
          false
        );

        if (type === "from") {
          setCityOption((prev) => {
            return { ...prev, fromCity: res?.data?.payload };
          });
        } else {
          setCityOption((prev) => {
            return { ...prev, toCity: res?.data?.payload };
          });
        }
      } catch (error) {}
    },
    []
  );
  // const getOptionsFromLocation = useCallback(async (data: any, type: "from" | "to") => {
  //   try {
  //     const res = await filterDataAPI(filterUrls.countryDevisionNoFIlter, data, true, false);
  //     if (type === "from") {
  //       setLocationOption((prev) => {
  //         return { ...prev, fromLocation: res?.data?.payload };
  //       });
  //     } else {
  //       setLocationOption((prev) => {
  //         return { ...prev, toLocation: res?.data?.payload };
  //       });
  //     }
  //   } catch (error) {}
  // }, []);
  useEffect(() => {
    getOptionsData();
  }, [getOptionsData]);
  useEffect(() => {
    if (currentData) {
      currentData.fromCountryDevision &&
        getOptionsFromCity(currentData?.fromCountryDevision, "from");
      currentData.toCountryDevision &&
        getOptionsFromCity(currentData?.toCountryDevision, "to");
      // currentData.fromSourceCity && getOptionsFromLocation(currentData?.fromSourceCity, "from");
      // currentData.fromDestinationCity &&
      //   getOptionsFromLocation(currentData?.fromDestinationCity, "to");
    }
  }, [currentData, getOptionsFromCity]);
  return (
    <>
      <div>
        <MultiSelectAll
          closeMenuOnSelect={false}
          labelClassName="!top-0"
          wrapperClassName="!w-full z-[300] py-4 "
          label="استان مبدا"
          name="fromCountryDevision"
          handleChange={(name: "string", value: any) => {
            getOptionsFromCity(value, "from");
            form.setFieldValue(name, value);
            form.setFieldValue("fromSourceCity", []);
          }}
          values={form.values.fromCountryDevision}
          options={countryOptions}
          error={
            form.touched.fromCountryDevision && form.errors.fromCountryDevision
          }
        />
        <MultiSelectAll
          closeMenuOnSelect={false}
          labelClassName="!top-0"
          wrapperClassName="!w-full z-[200] py-4 "
          label="شهر مبدا"
          name="fromSourceCity"
          handleChange={(name: "string", value: any) => {
            // getOptionsFromLocation(value, "from");
            form.setFieldValue(name, value);
            // form.setFieldValue("fromSourceLocation", []);
          }}
          values={form.values.fromSourceCity}
          options={cityOption.fromCity}
          error={form.touched.fromSourceCity && form.errors.fromSourceCity}
        />
      </div>
      <div>
        <MultiSelectAll
          closeMenuOnSelect={false}
          labelClassName="!top-0"
          wrapperClassName="!w-full z-[300] py-4 "
          label="استان مقصد"
          name="toCountryDevision"
          handleChange={(name: "string", value: any) => {
            getOptionsFromCity(value, "to");
            form.setFieldValue(name, value);
            form.setFieldValue("fromDestinationCity", []);
          }}
          values={form.values.toCountryDevision}
          options={countryOptions}
          error={
            form.touched.toCountryDevision && form.errors.toCountryDevision
          }
        />
        <MultiSelectAll
          closeMenuOnSelect={false}
          labelClassName="!top-0"
          wrapperClassName="!w-full z-[200] py-4 "
          label="شهر مقصد"
          name="fromDestinationCity"
          handleChange={(name: "string", value: any) => {
            // getOptionsFromLocation(value, "to");
            form.setFieldValue(name, value);
            // form.setFieldValue("fromDestinationLocation", []);
          }}
          values={form.values.fromDestinationCity}
          options={cityOption.toCity}
          error={
            form.touched.fromDestinationCity && form.errors.fromDestinationCity
          }
        />
        {/* <MultiSelect
          wrapperClassName="w-full z-[100] py-4 "
          label="منطقه"
          name="fromDestinationLocation"
          handleChange={form.setFieldValue}
          values={form.values.fromDestinationLocation}
          options={locationOption.toLocation}
          error={form.touched.fromDestinationLocation && form.errors.fromDestinationLocation}
        /> */}
      </div>
    </>
  );
};

export default Provinces;
