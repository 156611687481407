import React from "react";
import { ClipLoader } from "react-spinners";

function LoadingMap({ loading, loadingText = "... در حال بارگذاری " }) {
  return (
    <>
      {loading ? (
        <div className=" fixed   w-52  h-28 rounded-lg  left-[35%]  top-1/3 flex flex-col justify-center items-center bg-[#0000005c]  z-[9999]">
          <ClipLoader size={40} color="white" />
          <h1 className="te text-white mt-2 text-lg"> {loadingText} </h1>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default LoadingMap;
