import * as XLSX from "xlsx-js-style";
import stc from "string-to-color";
export const ExportExcel = (data: any) => {
  const flattenObj = (ob: any) => {
    // The object which contains the
    // final result
    let result = {};
    // loop through the object "ob"
    for (const i in ob) {
      // We check the type of the i using
      // typeof() function and recursively
      // call the function again
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          // Store temp in result
          result[i] = temp[j];
          // result[persianName[i]] = temp[j];
        }
      }
      // Else store ob[i] in result directly
      else {
        result[i] = ob[i];
        // result[persianName[i]?persianName[i]:i] = ob[i];
      }
    }
    return result;
  };
  let newArr: any = [];
  data.map((item: any) => newArr.push(flattenObj(item)));

  let web = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(web, ws, "myfile");
  XLSX.writeFile(web, "MyExcel.xlsx");
};

export const ReverseArray = (arr: []) => [...arr].reverse();

export const getPelak = (values: any) =>
  values?.vehicleNumber2
    ? `${values?.vehicleNumber3 ?? " "} - ${values?.vehicleNumber2 ?? " "}  ${
        values?.vehicleNumber1 ?? " "
      }  ${values?.vehicleNumber0 ?? " "}`
    : values?.selecttype?.id === 0
    ? `${values?.vehicleNumber1}-${values?.vehicleNumber0}`
    : "";

export const getAllPelak = (values: any) =>
  `${values?.vehicleNumber3 ?? " "} - ${values?.vehicleNumber2 ?? " "}  ${
    values?.vehicleNumber1 ?? " "
  }  ${values?.vehicleNumber0 ?? " "}`;

interface getDayProps {
  year: string | number;
  month: string | number;
  day: string | number;
}

export const getDay = (value: getDayProps, timeValue?: string) => {
  const date = value ? value.year + "/" + value.month + "/" + value.day : "";
  if (timeValue) return timeValue ? `(${timeValue} - ${date})` : "";
  return date;
};
export const convertToObjects = (from: any, to: any, type: any) => {
  let arr = [];
  for (let i = 0; i < from.length; i++) {
    for (let j = 0; j < to.length; j++) {
      arr.push({ fromCountryDevision: from[i], toCountryDevision: to[j] });
    }
  }
  return arr;
};
export const convertUsingProduct = (usingProduct: any, product: any) => {
  let arr = [];
  for (let x of usingProduct) {
    arr.push({
      parent: product,
      child: x,
    });
  }
  return arr;
};

export const DateCompare = (date1: any, date2: any) => {
  let errDate: any;
  let isValid = false;
  const date1Format = new Date(
    date1.year + "-" + date1.month + "-" + date1.day
  );
  const date2Format = new Date(
    date2.year + "-" + date2.month + "-" + date2.day
  );
  if (date1Format > date2Format) {
    errDate = "تاریخ شروع بزرگتر از تاریخ پایان است. ";
  } else {
    isValid = true;
  }
  return [isValid, errDate];
};

export const SplitString = (value: string) => {
  return value.split(/([0-9]+)/)[0];
};
export const SplitNumber = (value: string) => {
  return value.split(/([0-9]+)/)[1];
};

export const findNode = (tree: any, value: string) => {
  let result = null;
  if (value === tree.value) {
    return tree;
  } else {
    if (tree.children) {
      tree.children.some((node: any) => (result = findNode(node, value)));
    }
    return result;
  }
};

export const numberToPersian = (number: any) => {
  if (number === undefined || number === null) return "-";

  const persian: any = {
    0: "۰",
    1: "۱",
    2: "۲",
    3: "۳",
    4: "۴",
    5: "۵",
    6: "۶",
    7: "۷",
    8: "۸",
    9: "۹",
  };
  number = number?.toString().split("");
  let persianNumber = "";
  for (let i = 0; i < number.length; i++) {
    if (/[0-9]/.test(number[i])) number[i] = persian[number[i]];
  }
  for (let i = 0; i < number.length; i++) {
    persianNumber += number[i];
  }

  return persianNumber;
};

export const numberToENG = (number: any) => {
  const persian: any = {
    "۰": 0,
    "۱": 1,
    "۲": 2,
    "۳": 3,
    "۴": 4,
    "۵": 5,
    "۶": 6,
    "۷": 7,
    "۸": 8,
    "۹": 9,
  };
  number = number.toString().split("");
  let persianNumber = "";
  for (let i = 0; i < number.length; i++) {
    number[i] = persian[number[i]];
  }
  for (let i = 0; i < number.length; i++) {
    persianNumber += number[i];
  }
  return persianNumber;
};

export const isNotDescTopOS = () => {
  var userAgent: any = window.navigator.userAgent,
    platform: any = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"];

  if (macosPlatforms.indexOf(platform) !== -1) {
    return false;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    return true;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    return false;
  } else if (/Android/.test(userAgent)) {
    return true;
  } else if (/Linux/.test(platform)) {
    return false;
  }
  return false;
};

export const getToday = (obj?: boolean) => {
  let options: any = { year: "numeric", month: "numeric", day: "numeric" };
  let today = new Date().toLocaleDateString("fa-IR", options).split("/");
  if (obj)
    return {
      day: numberToENG(today[2]),
      month: numberToENG(today[1]),
      year: numberToENG(today[0]),
    };
  else return today;
};

export const ReturnText = (item: any) => {
  if (item) return { ...item, text: item.name };
};

export const Collector = (data: Array<any>, name: string) =>
  data.reduce((sum: any, current: any) => {
    sum = sum + current[name];
    return sum;
  }, 0);

export const returnPersionDate = (date: any) => ({
  day: Number(numberToENG(date.day)),
  month: Number(numberToENG(date.month)),
  year: Number(numberToENG(date.year)),
});

export const IncreaseTime = (time: any) => {
  if (time) {
    let newTime = time.split("");
    newTime[1] = Number(newTime[1]) + 1;
    return newTime.join("");
  }
};

export const checkTime = (timeStart, lists) => {
  let hasError;
  for (let x of lists) {
    hasError = timeStart[x].some(
      (item: any) =>
        item.timeFrom && item.timeTo && item.timeFrom >= item.timeTo
    );
    if (hasError) {
      return hasError;
    }
    //  checkforExist =timeStart[x].filter((item: any) =>(item.timeFrom && item.timeTo) && item.timeFrom > item.timeTo ).flat(1);
    //  error.push(checkforExist)
  }

  //   let result = error.flat(1).reduce(function (r, a) {
  //     r[a.name] = r[a.name] || [];
  //     r[a.name].push(a);
  //     return r;
  // }, Object.create(null));
  // return result
};

export function generateUniqueColor(id) {
  const color = stc(id);
  return color;
}
export const DateTimeConvertor = (item) =>
  `${item.hour}:${item.minute} - ${item.year}/${item.month}/${item.day}`;

export function calculateTax(data) {
  const extraPrice = data?.detailsService
    ? Collector(data?.detailsService, "price")
    : 0;
  return data?.sumOfBasePrice
    ? extraPrice > 0
      ? (Number(extraPrice) + Number(data?.sumOfBasePrice)) * 0.1
      : Number(data?.sumOfBasePrice) * 0.1
    : extraPrice > 0
    ? Number(extraPrice) * 0.1
    : 0;
}

export function calculateTaxPerConsignment(data) {
  const extraPrice = data.packingCost ? data.packingCost : 0;
  return data?.price
    ? extraPrice > 0
      ? (Number(extraPrice) + Number(data?.price)) * 0.1
      : Number(data?.price) * 0.1
    : extraPrice > 0
    ? Number(extraPrice) * 0.1
    : 0;
}

export function calculateOrderPrice(data) {
  const extraPrice = data?.detailsService
    ? Collector(data?.detailsService, "price")
    : 0;
  const taxPrice = calculateTax(data);
  return data?.sumOfBasePrice
    ? extraPrice > 0
      ? Number(extraPrice) + Number(data?.sumOfBasePrice) + Number(taxPrice)
      : Number(data?.sumOfBasePrice) + Number(taxPrice)
    : extraPrice > 0
    ? Number(extraPrice) + Number(taxPrice)
    : 0;
}

export function calculateOrderPricePerConsignment(data) {
  const extraPrice = data.packingCost ? data.packingCost : 0;
  const taxPrice = calculateTaxPerConsignment(data);
  return data?.price
    ? extraPrice > 0
      ? Number(extraPrice) + Number(data?.price) + Number(taxPrice)
      : Number(data?.price) + Number(taxPrice)
    : extraPrice > 0
    ? Number(extraPrice) + Number(taxPrice)
    : 0;
}

export function paymentBy(item) {
  const payerId =
    item.paymentBy === "SENDER" || item.paymentBy === null ? 0 : 1;
  return payerId;
}

export function searchByCity(option:{label:string}, rawInput:string) {
  if (!rawInput) {
    return true;
  }
  const [_, searchText] = option.label.split(" - ");
  return (
    searchText && searchText.toLowerCase().includes(rawInput.toLowerCase())
  );
}
