import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  orderManagementList: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const OrderManagementList = createSlice({
  initialState: initialState,
  name: "orderManagementList",
  reducers: {
    updating: (state: any, action: any) => {
      state.isUpdating = action.payload;
    },
    clearOrderManagement: (state) => {
      state.orderManagementList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { updating, clearOrderManagement, setFilter } = OrderManagementList.actions;
export default OrderManagementList.reducer;
