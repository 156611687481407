import { createSlice } from "@reduxjs/toolkit";

import { StateData } from "./state-model";

const initialState: StateData = {
  filter: {
    isActive: true,
  },
};

const ConsignmentOperationReducer = createSlice({
  initialState: initialState,
  name: "consignmentOperation",
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});
export const { setFilter } = ConsignmentOperationReducer.actions;
export default ConsignmentOperationReducer.reducer;
