import { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import InputText from "global/InputText/InputText";
import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { AddressValidation } from "global/Validation/Validation";
import TooltipWrapper from "global/tooltip/TooltipWrapper";
import { addEditDataAPI, getAPI } from "services/CRUDServices";
import { addEditUrls, getUrls } from "services/api.enums";
import AddressSearchMap from "components/AddressSearchMap/AddressSearchMap";

export interface addressModalType {
  isOpen: boolean;
  data: AddressType;
  isDifficult: boolean;
  isCloseAfterSave?: boolean;
  ignoreSubmit?: boolean;
}

interface AddressCorrectionFormProps {
  addressModal: addressModalType;
  setIsOpen: (value: addressModalType) => void;
  handleGetTableData: (lat?: number, lng?: number) => void;
  ignoreSubmit?: boolean;
}

const AddressCorrectionForm = ({
  addressModal,
  setIsOpen,
  handleGetTableData,
  ignoreSubmit,
}: AddressCorrectionFormProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: AddressValidation,
    initialValues: {
      ...addressModal?.data,
      staticAddress: addressModal?.data?.address,
      address: addressModal?.data?.dynamicAddress,
      lat: addressModal?.data?.latitude || null,
      lng: addressModal?.data?.longtitude || null,
    },
    onSubmit: (values: any) => {
      if (ignoreSubmit) {
        handleGetTableData({
          ...values,
          longtitude: values.lng,
          latitude: values.lat,
          postalCode: values.postalCode,
        });
        return;
      }
      addEditDataAPI(
        addressModal?.isDifficult
          ? addEditUrls.acceptDifficultAddress
          : addEditUrls.updateAddressFromPin,
        "put",
        {
          ...values,
          longtitude: values.lng,
          latitude: values.lat,
          postalCode: values.postalCode,
          isEdited: true,
          selectEditedStatus: { id: 2, text: "ویرایش شده" },
          selectRegChannel: { id: 2, text: "" },
        }
      ).then(() => {
        toast.success(`آدرس با موفقیت ویرایش شد`);

        handleGetTableData();
        if (addressModal.isCloseAfterSave) {
          setIsOpen({ isOpen: false, data: null, isDifficult: false });
          return;
        }
        if (addressModal.isDifficult) {
          getAPI(getUrls.addressCorrectionDataIsDefault)
            .then(({ data }) => {
              setIsOpen({ isOpen: true, data, isDifficult: true });
            })
            .catch(() =>
              setIsOpen({ isOpen: false, data: null, isDifficult: false })
            );
        } else {
          getAPI(getUrls.addressCorrectionData)
            .then(({ data }) => {
              setIsOpen({
                isOpen: true,
                data,
                isDifficult: false,
              });
            })
            .catch(() =>
              setIsOpen({ isOpen: false, data: null, isDifficult: false })
            );
        }
      });
    },
  });

  const { values, handleSubmit, handleChange, errors, touched, setFieldValue } =
    formik;

  useEffect(() => {
    if (!addressModal?.data?.address) {
      setFieldValue("staticAddress", values.address);
    }
  }, [values.address]);

  const handleCancel = () => {
    getAPI(getUrls.cancelUpdateAddressStatus + addressModal?.data.id).then(
      () => {
        toast.success("آدرس بعنوان دشوار ثبت شد");
        if (addressModal.isCloseAfterSave) {
          setIsOpen({ isOpen: false, data: null, isDifficult: false });
          return;
        }
        getAPI(getUrls.addressCorrectionData)
          .then(({ data }) => {
            setIsOpen({
              isOpen: true,
              data,
              isDifficult: addressModal.isDifficult,
            });
          })
          .catch(() =>
            setIsOpen({ isOpen: false, data: null, isDifficult: false })
          );
      }
    );
  };

  return (
    <Modal
      visible={addressModal.isOpen}
      setVisible={(falsy) =>
        setIsOpen({ isOpen: falsy, data: undefined, isDifficult: false })
      }
      title={addressModal?.isDifficult ? "اصلاح آدرس دشوار " : "اصلاح آدرس "}
    >
      <div className="flex Max-sm:flex-col-reverse gap-5">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full sm:w-96 gap-4 min-h-[35rem]"
        >
          {/* <p className="text-dark font-medium mb-3">
            {addressModal?.isDifficult ? "اصلاح آدرس دشوار " : "اصلاح آدرس "}{" "}
          </p> */}
          <InputText
            important
            readOnly
            wrapperClassName="w-full"
            values={values?.selectCity?.text}
            label="شهر"
          />
          <TooltipWrapper textProps={values.staticAddress} extraClass=" ">
            <InputText
              important
              readOnly
              wrapperClassName="w-full"
              values={values.staticAddress}
              label="آدرس اظهاری مشتری"
              classNames="opacity-100"
            />
          </TooltipWrapper>
          <TooltipWrapper textProps={values.address} extraClass=" ">
            <InputText
              important
              readOnly
              wrapperClassName="w-full"
              values={values.address}
              label="آدرس منطبق بر پین"
              classNames="opacity-100"
            />
          </TooltipWrapper>
          <InputText
            important
            // readOnly
            wrapperClassName="w-full"
            handleChange={handleChange}
            values={values.addressDetail}
            name="addressDetail"
            error={touched.addressDetail && errors.addressDetail}
            label="جزئیات"
          />
          <InputText
            wrapperClassName="w-full"
            handleChange={handleChange}
            values={values.postalCode}
            name="postalCode"
            error={touched.postalCode && errors.postalCode}
            label="کد پستی"
          />
          <div className="btnForm">
            <SimpleButton
              handelClick={handleCancel}
              className="full-lightTomato-btn w-full"
              text="لغو"
            />
            <SimpleButton
              type="submit"
              className="full-tomato-btn w-full"
              text="تایید"
            />
          </div>
        </form>
        <AddressSearchMap
          formik={formik}
          id={addressModal?.data?.selectCity?.id}
          // notSearch
          // notChangeAddress
        />
      </div>
    </Modal>
  );
};

export default AddressCorrectionForm;
