import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterPickupDistributionProgramming: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "pickupDistributionProgrammingModel",
  async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.pickupdistributionprogramming,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true,
      6
    );

    return data.data;
  }
);

const initialState: any = {
  pickupDistributionProgrammingData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const PickupDistributionProgrammingLists = createSlice({
  initialState: initialState,
  name: "pickupDistributionProgrammingModel",
  reducers: {
    clearPickupDistributionProgrammingData: (state) => {
      state.pickupDistributionProgrammingData = [];
    },
    setFilterPickupDistributionProgrammingData: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterPickupDistributionProgramming.fulfilled as any]: (state, action) => {
      state.pickupDistributionProgrammingData = action.payload;
      state.fetchPost = false;
    },
    [filterPickupDistributionProgramming.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterPickupDistributionProgramming.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterPickupDistributionProgrammingData, clearPickupDistributionProgrammingData } = PickupDistributionProgrammingLists.actions;
export default PickupDistributionProgrammingLists.reducer;
