import { FC } from "react";
import { useMap } from "react-leaflet";

interface ChangeFlyViewProps {
  center: any;
  zoom?: number;
}

const ChangeFlyView: FC<ChangeFlyViewProps> = ({ center, zoom }) => {
  const map = useMap();
  // map.setView(center, zoom);
  map.flyTo(center, zoom, {
    animate: true,
    duration: 0.9,
  });
  return null;
};

export default ChangeFlyView;
