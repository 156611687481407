import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearPaginate } from "redux/PaginationAction/PaginationAction";

export default function useClearPaginate() {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch(clearPaginate(1)); //clear pagination onchange page
  }, [dispatch, location.pathname]);

  return {};
}
