
import { lazy } from "react";

const ConsignmentManage = lazy(
  () => import("pages/ConsignmentManage/ConsignmentManage")
);
const LabelPrintRequest = lazy(
  () => import("pages/LabelPrintRequest/LabelPrintRequest")
);
const BarCodeManager = lazy(
  () => import("pages/BarCodeManager/BarCodeManager")
);
const Settlement = lazy(() => import("pages/Settlement"));
const ReturnConsignments = lazy(() => import("pages/ReturnConsignments"));
const LabelAllocate=lazy(()=>import("pages/label-allocate/LabelAllocate"))

export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/consignment-manage",
    component: <ConsignmentManage />,
    label: "مدیریت مرسوله",
    permissions: "view_consignment_management",
  },
  {
    code: "",
    to: "/label-print-request",
    component: <LabelPrintRequest />,
    label: "درخواست چاپ برچسب",
    permissions: "view_request_label_print_request",
  },
  {
    code: "",
    to: "/label-allocate",
    component: <LabelAllocate />,
    label: "تخصیص برچسب",
    permissions: "view_assign_label",
  },
  {
    code: "",
    to: "/barcode",
    component: <BarCodeManager />,
    label: "مدیریت بارکد",
    permissions: "view_label_management",
  },
  {
    code: "",
    to: "/settlement",
    component: <Settlement />,
    label: "تسویه حساب",
    permissions: "view_settlement",
  },
  {
    code: "",
    to: "/return-consignment",
    component: <ReturnConsignments />,
    label: "مرسولات  برگشتی",
    permissions:"view_return_consignment",
  },
];
