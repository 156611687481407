import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUrls } from "../../services/api.enums";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { getAPI } from "./../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";

export const DataLabels = createAsyncThunk("labels", async (body: any) => {
 

  try {
   

    let hubUrl = "core-api/hub/";
    let customerUrl = "resource-api/customer/";

    const firstApi: any = await filterTableDataAPI(getUrls.LabelTable, body.pageNumber, {
      requestDate: body?.requestDate ? body?.requestDate : null,
      requestNo: body?.requestNo ? body?.requestNo : null,
      selectRequestType: body?.selectRequestType ? body?.selectRequestType : null,
      selectLabelRequestStatus: body?.selectLabelRequestStatus
        ? body?.selectLabelRequestStatus
        : null,
      selectHub: body.selectHub ? body?.selectHub : null,
      selectCustomer: body.selectCustomer ? body?.selectCustomer : null,
    },true,10,true);

    const firstApiResponse = firstApi.data.payload.content.map((item: any) => {
      return {
        ...item,
        mergeId: item.id,
        hubUrl: `${hubUrl}${item.selectHub.id}`,
        customerUrl: `${customerUrl}${item.selectCustomer.id}`,
      };
    });

    const fetchHub = async (item: any) =>
      await getAPI(item.hubUrl, true)
        .then((res: any) => {
          return {
            mergeId: item.id,
            selectHub: { id: res.data.payload.id, text: res.data.payload.name },
          };
        })
        .catch((error) => {
          ErrorAlert(error?.response?.data?.errors?.message)
        });

    const fetchCustomer = async (item: any) =>
      await getAPI(item.customerUrl, true)
        .then((res: any) => {
          return {
            mergeId: item.id,
            selectCustomer: { id: res.data.payload.id, text: res.data.payload.name },
          };
        })
        .catch((error) => {
          ErrorAlert(error?.response?.data?.errors?.message)
        });

    let hubData: any = [];
    let customerData: any = [];
    hubData = await Promise.all(
      firstApiResponse
        .filter((hub: any) => hub.selectHub.id !== null)
        .map((item: any) => fetchHub(item))
    ).then((values) => values.filter(Boolean));

    customerData = await Promise.all(
      firstApiResponse
        .filter((customer: any) => customer.selectCustomer.id !== null)
        .map((item: any) => fetchCustomer(item))
    ).then((values) => values.filter(Boolean));


 

    let result = [] as any;
    // if(body?.filter){
    result = [
      ...[...firstApiResponse, ...hubData, ...customerData]
        .reduce(
          (acc, curr) => acc.set(curr.mergeId, { ...acc.get(curr.mergeId), ...curr }),
          new Map()
        )
        .values(),
    ];
    firstApi.data.payload.content = result;
   
    // firstApi.data.payload.content.map((items:any)=>{

    //  getAPI(`http://boxi.local:40000/core-api/hub/${items?.selectHub?.id}`).then(itemss=>secons.push(itemss.data.payload))

    // })

    return firstApi.data.payload;
  } catch (err) {
    console.error(err);
  }
});

const initialState: any = {
  LableData: [],
  fetchPost: false,
  errorMessage: null,
  filter:{}
};

const LableAction = createSlice({
  name: "labels",
  initialState,
  reducers: {
    clearlabel: (state) => {
      state.LableData = [];
    },
    setLabelFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [DataLabels.fulfilled as any]: (state, action) => {
      state.LableData = action.payload;
      state.fetchPost = false;
    },
    [DataLabels.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [DataLabels.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});
export const { clearlabel,setLabelFilter} = LableAction.actions;
export default LableAction.reducer;
