import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterUrls } from "../../../services/api.enums";

import { filterTableDataAPI, getAPI } from "../../../services/CRUDServices";

export const filterVehicleModel: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "vehicleData",
  async (body: any) => {
    const data = await filterTableDataAPI(
      filterUrls.Vehicle,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true
    );

    let url = "resource-api/driver/";
    let ids = data.data.payload.content.map((item: any) => {
      return { ...item, mainId: item.id, url: `${url}${item.fixedDriverId}` };
    });
    const fetchJson = async (item: any) =>
      item.fixedDriverId &&
      (await getAPI(item.url, true)
        .then((res: any) => {
          return {
            ...res.data.payload,
            mainId: item.id,
            driverName: res.data.payload.name,
          };
        })
        );

    let driver: any = [];

    driver = await Promise.all(ids.map((item: any) => fetchJson(item))).then(
      (values) => values.filter(Boolean)
    );
    let result = [] as any;
    result = [
      ...[...driver, ...ids]
        .reduce(
          (acc, curr) =>
            acc.set(curr.mainId, { ...acc.get(curr.mainId), ...curr }),
          new Map()
        )
        .values(),
    ];
    data.data.payload.content = result;
    return data.data;
  }
);

const initialState: any = {
  vehicleData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const VehicleLists = createSlice({
  initialState: initialState,
  name: "vehicleData",
  reducers: {
    clearVehicle: (state) => {
      state.vehicleData = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterVehicleModel.fulfilled as any]: (state, action) => {
      state.vehicleData = action.payload.payload;
      state.fetchPost = false;
    },
    [filterVehicleModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterVehicleModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearVehicle } = VehicleLists.actions;
export default VehicleLists.reducer;
