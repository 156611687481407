import { links } from "components/SidebarRoutes";
import { Fragment } from "react";
import { Route } from "react-router-dom";
import useGetConsignmentStatus from "utils/hooks/options/useGetConsignmentStatus";
import useGetConsignmentTypes from "utils/hooks/options/useGetConsignmentTypes";
import useGetExeptions from "utils/hooks/options/useGetExeptions";
import useGetServiceTypes from "utils/hooks/options/useGetServiceTypes";
import useSetPermissionRoutesDispatches from "utils/hooks/useSetPermissionRoutesDispatches";
const PermissionRoute = () => {
  useSetPermissionRoutesDispatches();
  useGetExeptions();
  useGetServiceTypes();
  useGetConsignmentStatus();
  useGetConsignmentTypes();
  return links.map(({ childs }) => {
    return childs.map(({ to, component }, index: number) => {
      return (
        <Fragment key={index}>
          <Route path={to} element={component} />
        </Fragment>
      );
    });
  });
};

export default PermissionRoute;
