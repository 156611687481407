import { OrderFactureElements } from "pages/ConsignmentManageOperation/view/AddOrderForm/OrderFacture";

interface InvoiceProps {
  invoicePrice: {
    invoicePrice?: number;
    pickupPrice?: number;
    postBackPrice?: number;
    taxPrice?: number;
    total: number;
    packagePrice?: number;
    codPrice?: number;
    extraPrice?:number
  };
}

export default function OrderFactor({ invoicePrice }: InvoiceProps) {
  return (
    <>
      <OrderFactureElements
        title="هزینه ارسال"
        price={invoicePrice?.invoicePrice || 0}
      />
      <OrderFactureElements
        title="هزینه کالا"
        price={invoicePrice?.codPrice || 0}
      />
      <OrderFactureElements
        title="هزینه بسته بندی"
        price={invoicePrice?.packagePrice || 0}
      />
      <OrderFactureElements
        title="هزینه جمع آوری"
        price={invoicePrice?.pickupPrice || 0}
      />
      <OrderFactureElements
        title="هزینه پس کرایه"
        price={invoicePrice?.postBackPrice || 0}
      />
      <OrderFactureElements
        title="مالیات بر ارزش افزوده "
        price={invoicePrice?.taxPrice || 0}
      />
      <OrderFactureElements
        title="خدمات جانبی"
        price={invoicePrice?.extraPrice || 0}
      />
      <div className="flex-between-center py-5 border-t border-darkBorder">
        <p className="text-base text-dark">هزینه سفارش</p>
        <p className="text-base text-dark">
          <OrderFactureElements title="  " price={invoicePrice?.total || 0} />
        </p>
      </div>
    </>
  );
}
