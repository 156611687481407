import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterPaymentDetailModel: AsyncThunk<{}, {}, {}> =
  createAsyncThunk("paymentDatail", async (body: any) => {
    const data = await filterTableDataAPI(
      filterUrls.paymentHistoryDetails,
      body.pageNumber,
      {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
        currentData: undefined,
        invoiceId: body?.currentData?.selectInvoice?.id,
      },
      true,
      10,
      false
    );

    return data.data.payload;
  });

const initialState: any = {
  paymentDetailData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const PaymentDetailSlice = createSlice({
  initialState: initialState,
  name: "paymentDatail",
  reducers: {
    clearPaymentDetail: (state) => {
      state.paymentDetailData = [];
    },
    setFilterPaymentDetail: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterPaymentDetailModel.fulfilled as any]: (state, action) => {
      state.paymentDetailData = action.payload;
      state.fetchPost = false;
    },
    [filterPaymentDetailModel.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterPaymentDetailModel.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterPaymentDetail, clearPaymentDetail } =
  PaymentDetailSlice.actions;
export default PaymentDetailSlice.reducer;
