import { MdPersonSearch } from "react-icons/md";
import { childs } from "./childs";

export const rolesRoutes: ISideBarRoutes = {
  parentName: "view_role",
  code: "1003",
  Icon: <MdPersonSearch size={"25"} />,
  label: "مدیریت نقش ها",
  childs: childs,
};
