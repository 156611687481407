import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "services/CRUDServices";
import { getUrls } from "services/api.enums";

export const updateOptime: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "planning",
  async (body: any) => {
    const data = await getAPI(getUrls.planingConsignment + body.id);

    return data.data;
  }
);

interface modalType {
  show: boolean;
  optimeData: any;
  fetchPost: boolean;
  editZone: boolean;
  addZone: boolean;
  hidePins: boolean;
  infoPolygon: any;
  indexTab: number;
  multiSelect: boolean;
  mdlTrips: any;
  selectedPin: any;
  deletedPoly: number;
  unAssignPind: any;
  selectedZones:[]
  view:{lat:number,lng:number,zoom:number}
}

const initialState: modalType = {
  show: false,
  optimeData: [],
  fetchPost: false,
  editZone: false,
  addZone: false,
  hidePins: false,
  infoPolygon: [],
  indexTab: 0,
  multiSelect: false,
  mdlTrips: [],
  selectedPin: [],
  deletedPoly: -1,
  unAssignPind: null,
  selectedZones:[],
  view:{lat:null,lng:null,zoom:11}
};

const ActionModal = createSlice({
  initialState: initialState,
  name: "OptimeModal",
  reducers: {
    ActionModals: (state) => {
      state.show = !state.show;
    },
    ActionPolygon: (state, { payload }) => {
      state.show = true;
      state.infoPolygon = { ...payload };
      state.indexTab = 2;
    },
    ActionSidebar: (state, { payload }) => {
      state.show = false;
      if (payload === "editZone") {
        state.editZone = !state.editZone;
        state.multiSelect = false;
        state.addZone = false;
      } else if (payload === "hidePins") {
        state.hidePins = true;
      } else if (payload === "multiSelect") {
        state.multiSelect = !state.multiSelect;
        state.editZone = false;
        state.addZone = false;
      } else if (payload === "addZone") {
        state.addZone = !state.addZone;
        state.editZone = false;
        state.multiSelect = false;
      }
    },
    changeSelectTab: (state, { payload }) => {
      state.indexTab = payload;
    },
    ActionMultiSelect: (state, { payload }) => {
      const data = structuredClone(payload);
      const convertData = data
        ?.filter((item) => item?.pudoMdlVehicle)
        .map((item) => ({ ...item, mdlId: item?.pudoMdlVehicle[0].vehicleId }));
      const storage = {};
      if (convertData) {
        convertData.forEach((obj) => {
          const key = obj.mdlId;

          if (storage.hasOwnProperty(key)) {
            storage[key].consignmnetList = storage[key].consignmnetList.concat(
              obj.consignmnetList
            );
          } else {
            storage[key] = { ...obj };
          }
        });
      }

      const joinedObjects = Object.values(storage);
      state.mdlTrips = joinedObjects;
    },
    ActionSelectPins: (state, { payload }) => {
      state.selectedPin = payload;
      state.show = true;
    },
    clearState: (state) => {
      state.show = false;
      state.selectedPin = [];
      state.infoPolygon = [];
      state.mdlTrips = [];
      state.addZone = false;
      state.view=initialState.view
    },
    ActionDeletedPolygon: (state, { payload }) => {
      state.deletedPoly = payload;
      state.infoPolygon = []
      // state.show = false;
  
    },
    ActionSelectUnAssignPin: (state, { payload }) => {
      state.unAssignPind = { ...payload };
      state.show = true;
    },
    ActionChangeView:(state,{payload})=>{
      state.view=payload
   
    }
  },
  extraReducers: {
    [updateOptime.fulfilled as any]: (state, action) => {
      state.optimeData = action.payload;
      state.fetchPost = false;
    },
    [updateOptime.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [updateOptime.rejected as any]: (state) => {
      state.fetchPost = false;
    },
  },
});

export const {
  ActionModals,
  ActionSidebar,
  ActionPolygon,
  changeSelectTab,
  ActionMultiSelect,
  ActionSelectPins,
  clearState,
  ActionDeletedPolygon,
  ActionSelectUnAssignPin,
  ActionChangeView
} = ActionModal.actions;
export default ActionModal.reducer;
