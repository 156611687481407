import {createSlice,createAsyncThunk, AsyncThunk} from "@reduxjs/toolkit"
import { filterDataAPI } from "../../services/CRUDServices";
import { getUrls } from "../../services/api.enums";


    export const ProductGroupsData: AsyncThunk<{}, {}, {}>=createAsyncThunk("ProductGroup",async(body:any)=>{
        const params=`/filter?pageNumber=${body.pageNumbers}&pageSize=10`
        const {data}=await filterDataAPI(getUrls.Product_Group + params , {
                code:body.code ? body.code : "",
                name: body.name ?  body.name : "",
                description: body?.description ,
                isActive: body.isActive,
        }) 
        return data.payload
    })

const initialState = {
  ProductLists: [],
  fetchpost: false,
  errorMessage: "",
};

const ProductGroups = createSlice({
  name: "ProductGroup",
  initialState,

  reducers: {
    clearProductData: (state) => {
      state.ProductLists = [];
    },
  },

  extraReducers: {
    [ProductGroupsData.fulfilled as any]: (state, action) => {
      state.ProductLists = action.payload;
      state.fetchpost = false;
    },
    [ProductGroupsData.pending as any]: (state) => {
      state.fetchpost = true;
    },
    [ProductGroupsData.rejected as any]: (state) => {
      state.fetchpost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearProductData } = ProductGroups.actions;
export default ProductGroups.reducer;
