import { useFormik } from "formik";
import InputSelect from "global/InputSelect/InputSelect";
import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { assignVehiclePlaningValidation } from "global/Validation/Validation";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { addEditDataAPI, getAPI } from "services/CRUDServices";
import { addEditUrls, getUrls } from "services/api.enums";
import { generateUniqueColor } from "tools/functions/Methods";
import { clearPolygon, convertDriverData, convertResponse } from "../utils";
import { ActionSidebar } from "redux/optime/optime";
import { toast } from "react-toastify";
interface AssignVehicleProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  pins: any;
  polygon: any;
  currentPolygon?: any;
  setOptimeData: React.Dispatch<any>;
  featureGroupRef?: any;
  editCurrentPolygon?: any;
  setEditCurrentPolygon: React.Dispatch<any>;
  customId?: number;
  optimeData?: any;
}

const AssignVehicle: FC<AssignVehicleProps> = ({
  open,
  setOpen,
  pins,
  polygon,
  setOptimeData,
  currentPolygon,
  featureGroupRef,
  editCurrentPolygon,
  setEditCurrentPolygon,
  customId,
  optimeData,
}) => {
  const { addZone } = useSelector((state: any) => state.optimeModal);
  const { state } = useLocation();
  const [convertOption, setConvertOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userinfo } = useSelector((state: any) => state.userInfo);
  const [vehicleInfo, setVehicleInfo] = useState({
    assignableWeight: "",
    assignableVolume: "",
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: assignVehiclePlaningValidation,
    initialValues: {
      selectHub: state?.hub,
      consignmentList: [],
      selectuser: userinfo,
      color: "",
      polygon: "",
      pudoVehicleId: editCurrentPolygon
        ? convertDriverData(editCurrentPolygon.selectedZone.pudoVehicle[0])
        : { id: "" },
      pudoExecutationId: state.pudoExecutationId,
      id: editCurrentPolygon
        ? editCurrentPolygon.selectedZone.polygonId
        : undefined,
    },

    onSubmit: (values) => {
      //@ts-ignore
      const finalData = {
        ...values,
        color: editCurrentPolygon
          ? editCurrentPolygon.selectedZone.color
          : generateUniqueColor(values.pudoVehicleId.id),
        pudoVehicleId: state.pudoVehicle.find(
          (item) => item.vehicleId === values.pudoVehicleId.vehicleId
        )?.vehicleId,
        polygon: `[[\n ${polygon.map(
          (item: any) => `\n ${JSON.stringify(item)}` //convert polygon coordinates for backend schema
        )},\n],\n];`,
        polygonIndex: editCurrentPolygon
          ? editCurrentPolygon.selectedZone.polygonIndex
          : customId,
        consignmentList: pins.map((item) => ({ id: item.consignmentId })),
      };
      if (pins.length === 0) {
        toast.warn("هیچ مرسوله ای انتخاب نشده است");
      } else {
        setLoading(true);
        addEditDataAPI(addEditUrls.createVehiclePolygon, "post", finalData)
          .then(() => {
            getAPI(getUrls.planingConsignment + id).then((res) => {
              const convert = convertResponse(res);
              setOptimeData(convert);
              setLoading(false);
              editCurrentPolygon.mapAction?._toolbars?.edit?._save(); //run when edit polygon
              editCurrentPolygon.mapAction?._toolbars?.edit?.disable(); //run when edit polygon
              currentPolygon._toolbars.edit._modes?.edit?.handler?.disable(); //run when add poylgon
              setOpen(false);
              addZone && dispatch(ActionSidebar("addZone"));
              toast.success("عملیات با موفقیت انجام شد",{position:"top-left"});
            });
          })
          .catch(() => setLoading(false));
      }
    },
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,

    errors,
    touched,
  } = formik;

  function getVehicleInfo(id) {
    getAPI(getUrls.vehicleDetails + id).then((res) => {
      setVehicleInfo({
        assignableVolume: res.data.assignableVolume,
        assignableWeight: res.data.assignableWeight,
      });
    });
  }

  useEffect(() => {
    if (!editCurrentPolygon?.selectedZone) return;
    const findVehicle = state.pudoVehicle.find(
      (item) =>
        item.vehicleId ===
        editCurrentPolygon?.selectedZone?.pudoVehicle[0]?.vehicleId
    );

    getVehicleInfo(findVehicle.id);
  }, [open]);

  useEffect(() => {
    if (!open) {
      resetForm();
      featureGroupRef && clearPolygon(featureGroupRef);
      setEditCurrentPolygon("");
      addZone && dispatch(ActionSidebar("addZone"));
      editCurrentPolygon?.mapAction?._toolbars?.edit?._modes.edit?.handler?.enable();
      editCurrentPolygon?.mapAction?._toolbars?.edit?._modes?.edit?.handler?.revertLayers();
      editCurrentPolygon?.mapAction?._toolbars?.edit?._modes?.edit?.handler?.disable();
    }
    if (open) {
      const data = state.pudoVehicle.map((item) => ({
        ...item,
        label: item.text,
        isDisabled: optimeData.polygons.some(
          (element) => element.pudoVehicle[0].vehicleId === item.vehicleId
        ),
      }));
      setConvertOption(data);
    }
  }, [open]);

  return (
    <Modal visible={open} setVisible={setOpen} title={"افزودن وسیله نقلیه"}>
      <form onSubmit={handleSubmit}>
        <div>
          <InputSelect
            wrapperClassName="z-[99] "
            label="وسایل نقلیه"
            important
            name="pudoVehicleId"
            handleChange={(name, value) => {
          
              getVehicleInfo(value.id);
              const findVehicle = state.pudoVehicle.find(
                (item) => item.id === value.id
              );

              setFieldValue("pudoVehicleId", findVehicle);
            }}
            isCustomOption
            values={values.pudoVehicleId}
            options={convertOption}
            error={touched.pudoVehicleId && errors.pudoVehicleId}
          />

          <div className="flex gap-4">
            <div className="flex gap-2">
              <p>وزن تخصیص یافته: </p>
              {pins.reduce((acc, curr) => acc + curr.weight!, 0)}
            </div>

            <div className="flex gap-2">
              <p>حجم تخصیص یافته: </p>
              {pins.reduce((acc, curr) => acc + curr.volume, 0)}
            </div>

            <div className="flex gap-2">
              <p>تعداد: </p>
              {pins.length}
            </div>
          </div>
          <div className="flex gap-4 mt-4">
            <div className="flex gap-2">
              <p>وزن قابل تخصیص: </p>
              {vehicleInfo.assignableWeight}
            </div>

            <div className="flex gap-2">
              <p>حجم قابل تخصیص: </p>
              {vehicleInfo.assignableVolume}
            </div>
          </div>
        </div>
        <div className="btnForm">
          <SimpleButton
            handelClick={() => setOpen(false)}
            text="لغو"
            className="full-lightTomato-btn"
          />
          <SimpleButton
            type="submit"
            text={"افزودن"}
            className="full-tomato-btn"
            loading={loading}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AssignVehicle;
