import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import { filterUrls } from "../../../services/api.enums";
import { filterTableDataAPI } from "../../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";


export const vendorData=createAsyncThunk('vendorLists',async(body:any)=>{

    let data ;
    try {
        data = await filterTableDataAPI(filterUrls.vendor,body.pageNumber, {
        ...body,    
          pageNumber: undefined,
          pageSize: undefined,
       
        },true);
      } catch (error) {
        ErrorAlert(error?.response?.data?.errors?.message)
      }
    return data.data;
})



export interface StateData {
    vendorLists: Array<any>;
    fetchPost?: boolean;
    errorMessage: null | string;
    isUpdating: Boolean;
    filter: object;
  }
  

const initialState:StateData= {
    vendorLists:[],
    fetchPost:false,
    errorMessage:null,
    isUpdating: false,
    filter:{}    
}

const VendorList = createSlice({
    initialState: initialState,
    name: "vendorLists",
    reducers: {
       
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
          clearVendor: (state) => {
            state.vendorLists = [];
          },
    },
    extraReducers: {
        [vendorData.fulfilled as any]: (state, action) => {
            state.vendorLists = action.payload.payload;
            state.fetchPost = false;
        },
        [vendorData.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [vendorData.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter,clearVendor } = VendorList.actions;
export default VendorList.reducer