import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterUrls } from "services/api.enums";
import { filterTableDataAPI } from "services/CRUDServices";


export const filterReceipt: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "receipt",
  async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.orderReceipt,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true,
      10,
      false
    );

    return data.data;
  }
);

const initialState: any = {
  receiptData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const ReceiptSlice = createSlice({
  initialState: initialState,
  name: "receipt",
  reducers: {
    clearReceipt: (state) => {
      state.receiptData = [];
    },
    setFilterReceipt: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterReceipt.fulfilled as any]: (state, action) => {
      state.receiptData = action.payload;
      state.fetchPost = false;
    },
    [filterReceipt.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterReceipt.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilterReceipt, clearReceipt } = ReceiptSlice.actions;

export default ReceiptSlice.reducer;
