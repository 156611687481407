import SimpleButton from "global/SimpleButton/SimpleButton";
import { ConsignmentCard } from "pages/optimePanel/components/consignment-card/index";
import { checkPlaningCondition } from "pages/optimePanel/utils";
import { Children } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActionSelectUnAssignPin } from "redux/optime/optime";

interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
}

function InfoNotAssignedPins({ optimeData, setOptimeData }: MapOperationProps) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { unAssignPind } = useSelector((state: any) => state.optimeModal);
  
  return (
    <>
      {unAssignPind && Object.values(unAssignPind).length !== 0 && (
        <SimpleButton
          text="مشاهده همه"
          className="bg-red text-white blobk w-full mt-6"
          handelClick={() => dispatch(ActionSelectUnAssignPin(undefined))}
        />
      )}
      {unAssignPind && Object.values(unAssignPind).length !== 0
        ? checkPlaningCondition(state) && (
            <ConsignmentCard
              optimeData={optimeData}
              setOptimeData={setOptimeData}
              item={unAssignPind}
              hasDelete
              
            />
          )
        : Children.toArray(
            optimeData?.consignmentList.map((item) => (
              <ConsignmentCard
                optimeData={optimeData}
                setOptimeData={setOptimeData}
                item={item}
                hasDelete
              />
            ))
          )}
    </>
  );
}

export default InfoNotAssignedPins;
