import { MdOutlineAllInbox } from "react-icons/md";
import { childs } from "./childs";

export const serviceManagementRoutes: ISideBarRoutes = {
  parentName: "view_service_management",
  Icon: <MdOutlineAllInbox size={"25"} />,
  code: "",
  label: "مدیریت سرویس",
  childs: childs,
};
