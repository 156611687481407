import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  orderManagementData: null,
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},

};

const OrderDataSlice = createSlice({
  initialState: initialState,
  name: "orderDataSlice",
  reducers: {
    // updating: (state: any, action: any) => {
    //   state.isUpdating = action.payload;
    // },
    clearEasyOrderData: (state) => {
      state.orderManagementData = null;
    },
    // setFilter: (state, action) => {
    //   state.filter = action.payload;
    // },
    addOrderData: (state, action) => {
      state.orderManagementData = action.payload;
    },
  },
});

export const { addOrderData,clearEasyOrderData } = OrderDataSlice.actions;
export default OrderDataSlice.reducer;
