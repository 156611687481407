import { lazy } from "react";
import { MdDashboard } from "react-icons/md";
const Dashboard = lazy(() => import("../../../pages/Dashboard/Dashboard"));
export const dashboardRoutes: ISideBarRoutes = {
  parentName: "Dashboard",
  code: "",
  Icon: <MdDashboard size={"25"} />,
  label: "داشبورد",
  childs: [
    {
      code: "",
      to: "/",
      component: <Dashboard />,
      label: "داشبورد",
      permissions: "view_dashboard",
    },
  ],
};
