import { AiOutlineEdit } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import DocsIcon from "../../../../../assets/icons/DocsIcon";
import RotatoryBoxes from "../../../../../assets/icons/RotatoryBoxesIcon";
import { deleteConsignment } from "../../../../../redux/OrderData/OrderData";
import { deleteUrls } from "../../../../../services/api.enums";
import { deleteDataAPI } from "../../../../../services/CRUDServices";
import { useState } from "react";

import PDFPortal from "components/pdf/portal";
import { BsUpcScan } from "react-icons/bs";

const ConsignmentElements = ({
  item,
  index,
  handleEditConsignment,
  handleOpenBarcodeModal,
}: any) => {
  const { orderData } = useSelector((state: any) => state.Order);
  const [show, setShow] = useState(false);
  const [printData, setPrintData] = useState({});
  const dispatch = useDispatch();

  const handleDeleteConsignment = () => {
    deleteDataAPI(deleteUrls.consignment, item.consignmentId).then(() => {
      dispatch(deleteConsignment(item.consignmentId));
    });
  };

 
  return (
    <div
      key={index}
      className="w-80 border p-4 rounded-2xl bg-white my-2 shadow-sm"
    >
      <div className="flex-between-center">
        <p className="font-medium text-dark text-base">
          مرسوله شماره {index + 1}
        </p>
        <div className="flex-end-center gap-2">
          <span onClick={handleOpenBarcodeModal}>
            <BsUpcScan
              size={19}
              className={` cursor-pointer ${
                item.serialNumber ? "text-green" : "text-darkBorder"
              }`}
            />
          </span>
          {/* <button
            className=" border-none	text-[14px] w-[20px] h-[20px] "
            onClick={() => {
              setPrintData({ ...orderData, consignments: item,perConsignment:true });
              setShow(true);
            }}
            disabled={show}
          >
            <IoIosPrint size={20} className="text-darkBorder cursor-pointer" />
          </button> */}
          {orderData?.inVoiceStatusId !== 2 && (
            <>
              <span onClick={() => handleEditConsignment({ ...item, index })}>
                <AiOutlineEdit
                  size={20}
                  className="text-darkBorder cursor-pointer"
                />
              </span>
              <span onClick={handleDeleteConsignment}>
                <BiTrash size={20} className="text-red cursor-pointer" />
              </span>
            </>
          )}
        </div>
      </div>
      <div className="flex-start-center gap-5 mt-5 ">
        <RotatoryBoxes />
        <p className="text-base">تحویل گیرنده</p>
      </div>
      <div className="border-r-2 border-dotted border-darkBorder px-3 py-1 mx-3 my-2">
        <p className="text-dark text-sm">
          {item.receiverCustomerName || "-----"}
        </p>
        <p className="text-dark text-sm">
          {" "}
          {item.toCountryDevisionName || "-----"}
        </p>
      </div>
      <div className="flex-start-center gap-5 mt-5 ">
        <DocsIcon />
        <p className="text-base">مشخصات مرسوله</p>
      </div>
      <div className="border-r-2 border-dotted border-darkBorder px-3 py-1 mx-3 my-2">
        <p className="text-dark text-sm">
          {" "}
          {item?.selectContentTypeName}
          {item.selectContentTypeId !== 2 &&
            ` ${item?.declarativeLength}*${item?.declarativeWidth}*${
              item?.declarativeHeight
            }
          به ارزش ${
            item?.declarativeValue && item?.declarativeValue.toLocaleString()
          } ریال`}
        </p>
      </div>
      <div className="flex-between-center gap-4 mt-5">
        <p className="text-base whitespace-nowrap">نوع مرسوله</p>
        <p className="text-base text-dark truncate">
          {(item?.selectContentTypeId || item?.selectContentTypeId?.id) !== 2
            ? item?.selectContent?.text
            : "-----"}
        </p>
      </div>
      <div className="flex-between-center gap-4 mt-1">
        <p className="text-base whitespace-nowrap">سرویس ارسال</p>
        <p className="text-base text-dark truncate">
          {item?.ServiceName || "-----"}
        </p>
      </div>
      
      <div className="flex-between-center gap-4 mt-1">
        <p className="text-base whitespace-nowrap">بارکد مشتری</p>
        <p className="text-base text-dark truncate">
          {item?.thirdPartyCprNumber || "-----"}
        </p>
      </div>
      <div className="flex-between-center gap-4 mt-1">
        <p className="text-base whitespace-nowrap"> مبلغ</p>
        <p className="text-base text-dark">
          {item?.price?.toLocaleString() || "-----"}
        </p>
      </div>
      <PDFPortal show={show} setShow={setShow} item={printData} />
    </div>
  );
};

export default ConsignmentElements;
