import { useEffect } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "redux/Authenticate/authenticate";

export default function useSetAuthentication() {
  const auth = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      navigate("/login");
    }
    if (auth?.user?.access_token) {
      dispatch(setToken(auth?.user?.access_token));
      localStorage.setItem("token", auth?.user?.access_token);
    }
  }, [auth]);
  return {};
}
