import { MdOutlinePolyline } from "react-icons/md";
import { childs } from "./childs";

export const contradictionRoutes: ISideBarRoutes = {
  parentName: "view_contradiction",
  Icon: <MdOutlinePolyline size={"25"} />,
  code: "",
  label: "contradiction",
  childs: childs,
};
