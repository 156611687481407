import type { Dispatch, SetStateAction } from "react";
import { getCurrentTime, getToday } from "./portal/helper";
import {
  calculateTax,
  calculateTaxPerConsignment,
  getDay,
} from "tools/functions/Methods";

interface ConsignmentProps {
  setShow?: Dispatch<SetStateAction<boolean>>;
  item: any;
}

function SingleConsignment({ item }: ConsignmentProps) {
  const renderEmpty = (item) => {
    return item || 0;
  };

  const costPrice = item.perConsignment
    ? item.packingCost || 0
    : item?.invoicePrices?.detailsService.length > 0
    ? item?.invoicePrices?.detailsService.find(
        (item: any) => item?.serviceid === 21
      )?.price
    : 0;

  const serviceCost = item.perConsignment
    ? item.consignments.price
    : item?.invoicePrices?.sumOfBasePrice;
  const sumOfCost = serviceCost + costPrice;
  const taxPrice = item.perConsignment
    ? calculateTaxPerConsignment({ ...item.consignments })
    : calculateTax(item.invoicePrices);

  const totalCost = item.perConsignment
    ? taxPrice + sumOfCost
    : taxPrice + sumOfCost;

  function calcTotal(invoicePrices) {
    return calculateSumOfCost(invoicePrices) + calculateTax(invoicePrices);
  }

  function calculateSumOfCost(item) {
    return item?.sumOfBasePrice + costPrice;
  }

  function removePerantes(item) {
    return item.replaceAll("(", "").replaceAll(")", "");
  }

  return (
    <>
      <table id="header-table" className="border   w-full">
        <tbody>
          <tr>
            <td>تاریخ چاپ :{getToday()}</td>
            <td className="head">رسید فرستنده</td>
          </tr>
          <tr>
            <td>زمان چاپ: {getCurrentTime()}</td>
            <td></td>
            <td className="logotext">باکسیت</td>
          </tr>
        </tbody>
      </table>
      <table id="my-table" className="border  w-full">
        <tbody>
          <tr>
            <td colSpan={2}>
              نام گیرنده: {item.consignments?.receiverCustomerName}
              <br />
              <br />
              تلفن: {renderEmpty(item.consignments?.receiverPhoneNumber)}
              <br />
              <br />
              مقصد:{" "}
              {item.consignments?.toCountryDevisionName &&
                item.consignments?.toCountryDevisionName + "-"}
              {removePerantes(item.consignments?.receiverAddressName) ||
                removePerantes(item.consignments?.deliveryHubAddress)}
            </td>
            <td colSpan={2}>
              نام فرستنده : {item?.senderCustomerName}
              <br />
              <br />
              تلفن: {item?.senderCustomerPhone}
              <br />
              <br />
              آدرس برگشت:
              {item?.senderDetails?.selectCity?.text
                ?.replaceAll(/[a-z]/gi, "")
                .replaceAll(/[0-9]/g, "")
                .replaceAll("-", "")
                .replaceAll("_", "")
                .trim("")}
              {" - "}
              {removePerantes(item?.returnDestination) ||
                removePerantes(item?.senderAddressName) ||
                removePerantes(item?.pickupHubAddress)}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <td className="right">هزینه بسته بندی:</td>
              <td className="left">
                {renderEmpty(costPrice)?.toLocaleString()} ریال
              </td>
              <br />
              <br />
              هزینه خدمات: {renderEmpty(serviceCost)?.toLocaleString()} ریال
              <br />
              <br />
              جمع هزینه:
              {renderEmpty(
                sumOfCost
                // calculateOrderPrice(item?.invoicePrices).toLocaleString()
              ).toLocaleString()}
              ریال
              <br />
              <br />
              مالیات بر ارزش افزوده: {renderEmpty(taxPrice.toLocaleString())}
              ریال
              <br />
              <br />
              مبلغ کل: {renderEmpty(totalCost).toLocaleString()}
              {/* {renderEmpty(
                calcTotal(item?.invoicePrices).toLocaleString()
              )}{" "} */}
              ریال
              <br />
              <br />
              <td>
                <b>وجه امانی: </b>
                {renderEmpty(
                  item?.consignments?.costOfGoods
                ).toLocaleString()}{" "}
                {item?.consignments?.costOfGoods && "ریال"}
              </td>
              <br />
              <br />
              {` نحوه پرداخت: ${
                PaymentEnum[item?.consignments?.paymentBy] || ""
              }`}
            </td>
            <td colSpan={2} dir="rtl">
              {renderEmpty(item.consignments?.cprNumber)} :کد مرسوله
              <br />
              <br />
              نوع مرسوله: {item.consignments?.selectContentTypeName}{" "}
              {item.consignments?.contentTypeId?.text &&
                " _ " + item.consignments?.contentTypeId?.text}
              <br />
              <br />
              {item.consignments?.selectPackage?.text
                ? ` سایز بسته بندی: ${item.consignments?.selectPackage?.text}`
                : "___  : سایز بسته بندی"}
              <br />
              <br />
              {item.consignments?.declarativeWeight} : (KG) وزن
              <br />
              <br />
              ارزش اظهار شده:{" "}
              {item.consignments?.declarativeValue?.toLocaleString()} ریال
              <br />
              <br />
              {item.consignments?.content?.name
                ? `محتویات : ${item.consignments?.content?.name}`
                : "___ : محتویات"}
              <br />
              <br />
              نوع خدمت: {item.consignments?.suggestionServices?.name}
              <br />
              <br />
              تاریخ و زمان تحویل: {getDay(item?.createdDate)} ساعت:{" "}
              {item?.createdDate?.hour}:{item?.createdDate?.minute}
            </td>
          </tr>
        </tbody>
      </table>
      <table hidden id="footer-table" className="border  w-full">
        <tbody>
          <tr>
            <td>
              نام نمایندگی: {item?.senderDetails?.name}
              <br />
              <br />
              {item?.senderDetails?.code} : کد نمایندگی
            </td>
            <td> {"https://boxit.ir"} :رهگیری مرسوله از طریق سایت </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default SingleConsignment;

const PaymentEnum = {
  SENDER: "پیش کرایه نقدی",
  RECIEVER: "پس کرایه",
};
