import AutocompleteInput from "global/Autocomplete/AutocompleteInput";

import useFetchIput from "../hooks/useFetchIput";

function ExtraInput({values,name,setFieldValue,placeholder,title,onSelect}) {
    const { handleSuggest,items } = useFetchIput(setFieldValue);
  return (
    <div className="flex justify-between  ">
      <h1 className=" text-lg font-semibold">{title}</h1>
      <div>
        <AutocompleteInput
          placeholder={placeholder}
          wrapperClassName="w-[22rem] "
          inputClassname={"bg-[#F2F2F2]"}
          className={"bg-[#F2F2F2] border-none rounded-3xl"}
          items={items}
          value={values}
          onChange={(e) => {
            handleSuggest(e,name);
          }}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
}

export default ExtraInput;
