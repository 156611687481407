import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";

export const priceData: AsyncThunk<{}, {}, {}> = createAsyncThunk("priceList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.priceListEnhanced,
    body.pageNumber,
    {
      ...body,
      priceListDetails: {
        product: body?.product || undefined,
        consignmentType: body?.consignmentType || undefined,
      },
      consignmentType: undefined,
      product: undefined,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );
  return data;
});

const initialState: any = {
  priceList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const PriceList = createSlice({
  initialState: initialState,
  name: "priceList",
  reducers: {
    clearPriceList: (state) => {
      state.priceList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [priceData.fulfilled as any]: (state, action) => {
      state.priceList = action.payload.payload;
      state.fetchPost = false;
    },
    [priceData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [priceData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearPriceList, setFilter } = PriceList.actions;
export default PriceList.reducer;
