import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";

export const serviceTimeData: AsyncThunk<{}, {}, {}> = createAsyncThunk("serviceTimeList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.timeCommitment,
    body.pageNumber,
    {
      ...body,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );
  return data;
});

const initialState: any = {
  serviceTimeList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const ServiceTimeList = createSlice({
  initialState: initialState,
  name: "serviceTimeList",
  reducers: {
    clearServiceTimeList: (state) => {
      state.serviceTimeList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [serviceTimeData.fulfilled as any]: (state, action) => {
      state.serviceTimeList = action.payload.payload;
      state.fetchPost = false;
    },
    [serviceTimeData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [serviceTimeData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearServiceTimeList, setFilter } = ServiceTimeList.actions;
export default ServiceTimeList.reducer;
