import { useDispatch, useSelector } from "react-redux";
import { ActionChangeView, ActionModals } from "redux/optime/optime";
import { addEditDataAPI, getAPI } from "services/CRUDServices";
import { addEditUrls, getUrls } from "services/api.enums";

import * as turf from "@turf/turf";
import AddressCorrectionForm, {
  addressModalType,
} from "pages/address-correction/views/AddressCorrectionForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { convertDriverData, convertResponse } from "pages/optimePanel/utils";
import { RiTruckFill } from "react-icons/ri";
import { ReactComponent as Motorcycle } from "../../../../assets/icons/motorcycle.svg";
import { Confirm } from "../confirm-modal";
import { ConsignmentCardBody } from "./card-body";
import { ConsignmentHeader } from "./header";
import ActionModal from "redux/ActionModal/ActionModal";

interface MapOperationProps {
  optimeData: any;
  setOptimeData: any;
  item?: any;
  hasExtra?: boolean;
  hasDelete?: boolean;
}

export const ConsignmentCard = ({
  optimeData,
  setOptimeData,
  item,
  hasExtra,
  hasDelete
}: MapOperationProps) => {
  const dispatch = useDispatch();
  const { view, selectedPin } = useSelector((state: any) => state.optimeModal);

  const { id } = useParams();
  const [inittilaAddress, setInitialAddress] = useState();
  const [polygontoAdd, setPolygonToAdd] = useState<any>();
  const [addressState, setAddressState] = useState<any>();
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [addressModal, setAddressModal] = useState<addressModalType>({
    isOpen: false,
    data: undefined,
    isDifficult: false,
    isCloseAfterSave: true,
  });
  const [consignmentInfo, setConsignmentInfo] = useState({
    consignmentId: "",
    planingId: "",
  });

  function updateAddress(addressData) {
    const { latitude, longtitude } = addressData;

    optimeData.polygons.forEach((element) => {
      const polygon = turf.polygon([element.polygonCordinate]);
      if (turf.booleanPointInPolygon([latitude, longtitude], polygon)) {
        setOpen(true);

        setAddressState(addressData);
        // acceptChange(addressData);
        setAdd(true);
        setPolygonToAdd(element);
        throw new Error("");
      }
    });
    optimeData.polygons.forEach((element) => {
      const polygon = turf.polygon([element.polygonCordinate]);
      if (!turf.booleanPointInPolygon([latitude, longtitude], polygon)) {
        // setOpen(true);
        setAddressState(addressData);

        setAdd(false);
        setPolygonToAdd("");
        acceptChange(addressData, true);
        throw new Error("");
      }
    });
    acceptChange(addressData);
  }

  function acceptChange(address, condition = false) {
    const finalAddress = address
      ? { ...address, address: inittilaAddress }
      : { ...addressState, address: inittilaAddress };
    addEditDataAPI(
      addEditUrls.updateAddressInPlanning +
        "/" +
        id +
        "/" +
        consignmentInfo.consignmentId,
      "put",
      { ...finalAddress }
    ).then(() => {
      getAPI(getUrls.planingConsignment + id).then((res) => {
        const convert = convertResponse(res);
        setOptimeData(convert);
        setAddressModal({ ...addressModal, isOpen: false, data: undefined });
      });
    });

    if (!condition && add) {
      addToPolygon();
      return;
    }
    if (condition) {
      deleteFromPolygon();
      return;
    }

    setOpen(false);
  }

  function addToPolygon() {
    addEditDataAPI(addEditUrls.addPinToPolygon, "post", {
      pudoId: consignmentInfo.planingId,
      mdlVehicleId: polygontoAdd?.pudoMdlVehicle?.[0]?.vehicleId,
      pudoVehicleId: polygontoAdd?.pudoVehicle?.[0]?.vehicleId,
      polygoneId: polygontoAdd.polygonId,
    });

    setOpen(false);
    dispatch(ActionModals());
  }

  function deleteFromPolygon() {
    console.log("delete");
    addEditDataAPI(addEditUrls.addPinToPolygon, "post", {
      pudoId: consignmentInfo.planingId,
      mdlVehicleId: null,
      pudoVehicleId: null,
      polygoneId: null,
    });
    setOpen(false);
    dispatch(ActionModals());
  }

  useEffect(() => {
    if (view.lat && view.lng) {
      dispatch(ActionChangeView({ lat: null, lng: null, zoom: 11 }));
    }
  }, [view]);

  return (
    <>
      <div className="mt-4">
        <div className="flex justify-end items-center gap-3">
          <ConsignmentHeader
            setOptimeData={setOptimeData}
            item={item}
            setInitialAddress={setInitialAddress}
            setConsignmentInfo={setConsignmentInfo}
            setAddressModal={setAddressModal}
            hasDelete={hasDelete}
 
          />
        </div>
        {hasExtra && (
          <div className="grid2  gap-y-5">
            <span className="flex-start-center gap-2 text-white col-span-4">
              <RiTruckFill size={25} />
              <span>
                {selectedPin?.pudoMdlVehicle
                  ? convertDriverData(selectedPin?.pudoMdlVehicle?.[0])?.text
                  : ""}
              </span>
            </span>
            <span className="flex-start-center gap-2 text-white col-span-4">
              <Motorcycle />
              <span>
                {selectedPin?.pudoVehicle
                  ? convertDriverData(selectedPin?.pudoVehicle?.[0])?.text
                  : ""}
              </span>
            </span>
          </div>
        )}
        <ConsignmentCardBody item={item} />
      </div>
      <AddressCorrectionForm
        addressModal={addressModal}
        setIsOpen={setAddressModal}
        ignoreSubmit
        handleGetTableData={updateAddress}
      />
      <Confirm
        open={open}
        setOpen={setOpen}
        title={
          add
            ? `مرسوله به راننده ${
                polygontoAdd && polygontoAdd?.pudoVehicle?.[0]?.driver?.text
              } تخصیص داده شود؟`
            : "مرسوله از منطقه تخصیص خارج شود؟"
        }
        handleClick={acceptChange}
      />
    </>
  );
};
