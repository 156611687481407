import { lazy } from "react";

const Roles = lazy(() => import("pages/Roles/Roles"));
export const childs: Array<ISideBarChild> = [
  {
    to: "/roles",
    component: <Roles />,
    code: "1003",
    label: "نقش",
    permissions: "view_role_management",
  },
];
