import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "./../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";

export const customerData: AsyncThunk<{}, {}, {}> = createAsyncThunk("customerList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.customer,
    body.pageNumber,
    {
      ...body,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );

  return data;
});

const initialState: any = {
  customerList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const CustomerList = createSlice({
  initialState: initialState,
  name: "customerList",
  reducers: {
    clearCustomer: (state) => {
      state.customerList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [customerData.fulfilled as any]: (state, action) => {
      state.customerList = action.payload.payload;
      state.fetchPost = false;
    },
    [customerData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [customerData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearCustomer } = CustomerList.actions;
export default CustomerList.reducer;
