import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"
import { ErrorAlert } from "global/alert/Alert";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";



export const filterRoute=createAsyncThunk('routeLists',async(body:any)=>{
    let data ;
    try {
        data = await filterTableDataAPI(filterUrls.route,body.pageNumber, {
        ...body,    
          pageNumber: undefined,
          pageSize: undefined,
       
        },true,10,false);
      } catch (error) {
        ErrorAlert(error?.response?.data?.errors?.message)
      }
    return data.data;
})

const initialState: any = {
  routeLists: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const RouteLists = createSlice({
  initialState: initialState,
  name: "routeLists",
  reducers: {
    clearRoute: (state) => {
      state.routeLists = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterRoute.fulfilled as any]: (state, action) => {
      state.routeLists = action.payload.payload;
      state.fetchPost = false;
    },
    [filterRoute.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterRoute.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearRoute } = RouteLists.actions;
export default RouteLists.reducer;
