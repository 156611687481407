import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterUrls, getUrls } from "../../services/api.enums";
import { fetchJsonWithKey } from "../../tools/helper/ConcatAPIs";
import { filterTableDataAPI } from "../../services/CRUDServices";

export const pickupData: AsyncThunk<{}, {}, {}> = createAsyncThunk("pickupList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.pickup,
    body.pageNumber,
    {
      ...body,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );



  let content: any = [];

  content = await Promise.all(
    data.payload.content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.cusotmer}/${item?.selectcustomer.id}`,
        item,
        "selectcustomer"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.hub}${item?.selectpudoHub?.id}`,
        item,
        "selectpudoHub"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.hub}${item?.selectallocatedHub?.id}`,
        item,
        "selectallocatedHub"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.findConsignmentByPickup}/${item.id}`,
        item,
        "Consignments"
      )
    )
  ).then((values) => values);



  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.address}${
          item?.Consignments && item?.Consignments.length > 0
            ? item?.Consignments[0]?.senderAddressId
            : undefined
        }`,
        item,
        "senderAddress"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.telephone}/${
          item?.Consignments && item?.Consignments.length > 0
            ? item?.Consignments[0]?.senderPhoneId
            : undefined
        }`,
        item,
        "senderPhone"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.address}${
          item?.Consignments && item?.Consignments.length > 0
            ? item?.Consignments[0]?.receiverAddressId
            : undefined
        }`,
        item,
        "recieverAddress"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.cusotmer}/${
          item?.Consignments && item?.Consignments.length > 0
            ? item?.Consignments[0]?.senderCustomerId
            : undefined
        }`,
        item,
        "senderCustomer"
      )
    )
  ).then((values) => values);



  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(`${getUrls.trip}/${item?.trip?.id}`, item, "trip")
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.drivers}${item?.trip?.selectfirstDriver?.id}`,
        item,
        "selectfirstDriver"
      )
    )
  ).then((values) => values);

  content = await Promise.all(
    content.map((item: any) =>
      fetchJsonWithKey(
        `${getUrls.countryDevision}${item?.pickupRegionId}`,
        item,
        "pickupRegionId"
      )
    )
  ).then((values) => values);

  return { content, totalElements: data.payload.totalElements };
});

const initialState: any = {
  pickupList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const PickupList = createSlice({
  initialState: initialState,
  name: "pickupList",
  reducers: {
    updating: (state: any, action: any) => {
      state.isUpdating = action.payload;
    },
    clearPickup: (state) => {
      state.pickupList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [pickupData.fulfilled as any]: (state, action) => {
      state.pickupList = action.payload;
      state.fetchPost = false;
    },
    [pickupData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [pickupData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearPickup, setFilter } = PickupList.actions;
export default PickupList.reducer;
