import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export const filterExchangeProgramming :AsyncThunk<{}, {}, {}>= createAsyncThunk(
  "exchangeProgramming",
  async (body: any) => {
    // delete body.pageNumber
    const data = await filterTableDataAPI(
      filterUrls.mdlPudoPlanning,
      body.pageNumber,
      { ...body, pageNumber: undefined, pageSize: undefined },
      true,
      6
    );

    return data.data;
  }
);

const initialState: any = {
  exchangeProgrammingData: [],
  fetchpost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const ExchangeProgrammingLists = createSlice({
  initialState: initialState,
  name: "exchangeProgramming",
  reducers: {
    clearExchangeProgrammingData: (state) => {
      state.exchangeProgrammingData = [];
    },
    setfilterExchangeProgrammingData: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [filterExchangeProgramming.fulfilled as any]: (state, action) => {
      state.exchangeProgrammingData = action.payload;
      state.fetchPost = false;
    },
    [filterExchangeProgramming.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterExchangeProgramming.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setfilterExchangeProgrammingData, clearExchangeProgrammingData } = ExchangeProgrammingLists.actions;
export default ExchangeProgrammingLists.reducer;
