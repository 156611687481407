import { lazy } from "react";

const MDLManagement = lazy(() => import("pages/MDLManagement/MDLManagement"));
const Trips = lazy(() => import("pages/Trips/Trips"));
const MDLbags = lazy(() => import("pages/MDLBags/MDLbags"));
export const childs: Array<ISideBarChild> = [
  {
    code: "1",
    to: "/overview",
    component: <MDLManagement />,
    label: "نگاه اجمالی",
    permissions: "view_overview",
  },
  {
    code: "2",
    to: "/trips",
    component: <Trips />,
    label: "سفرها",
    permissions: "view_trips",
  },
  {
    code: "1030",
    to: "/bags",
    component: <MDLbags />,
    label: "کیسه ها",
    permissions: "view_bags",
  },
];
