import { MdCardTravel } from "react-icons/md";
import { childs } from "./childs";

export const overviewroutes: ISideBarRoutes = {
  code: "",
  parentName: "MDL",
  Icon: <MdCardTravel size={"25"} />,
  label: "MDL Management",
  childs: childs,
};
