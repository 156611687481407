import { AsyncThunk, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "services/axios";

const initialState = {
  postLists: [],
  fetchpost: false,
  errorMessage: "",
  filter: {},
};

export const HubData: AsyncThunk<{}, {}, {}> = createAsyncThunk(
  "hubList",
  async (body: any) => {
    const hublist = JSON.parse(localStorage.getItem("hublist")!);
    const { data } = await request.post(
      `core-api/hub/filter?pageNumber=${body?.pageNumbers}&pageSize=${
        body?.pageSize || "10"
      }`,
      {
        ...body,
        hublist,
      }
    );
    return data;
  }
);

const HubList = createSlice({
  initialState: initialState,
  name: "hubList",
  reducers: {
    clearHub: (state) => ({
      ...state,
      postLists: [],
    }),
    deleteRow: (state: any, action: any) => {
      state.postLists.payload.content = state.postLists.payload.content.filter(
        (item: any) => item.id !== action.payload
      );
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },

  extraReducers: {
    [HubData.fulfilled as any]: (state, action) => {
      state.postLists = action.payload;
      state.fetchpost = false;
    },
    [HubData.pending as any]: (state) => {
      state.fetchpost = true;
    },
    [HubData.rejected as any]: (state) => {
      state.fetchpost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearHub, deleteRow, setFilter } = HubList.actions;
export default HubList.reducer;
