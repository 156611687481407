import { BiXCircle } from "react-icons/bi";
import { RenderModal } from "../../components/modal/RenderModal";
import "../../styles/modalAnimation.css";

interface ModalProps {
  title?: string;
  visible: Boolean;
  children: React.ReactNode;
  setVisible: (visible: boolean) => void;
  notClose?: boolean;
  handleClose?: any;
  className?: string
}

const Modal = ({ visible, setVisible, children, title, notClose, handleClose, className }: ModalProps) => {
  const onClose = () => (handleClose ? handleClose() : setVisible(false));

  return (
    <RenderModal>
      {visible && (
        <div
          // onClick={() => setVisible(!visible)}
          style={{ background: "rgba(0, 0, 0, 0.3)" }}
          className="fixed inset-0 flex  overflow-auto z-[999]"
        >
          <div className="m-auto max-sm:flex justify-center  Max-sm:w-full max-sm:h-full max-sm:m-0   max-w-[90%] ">
          {/* <div className="lg:flex lg:justify-center lg:items-center sm:inline	 w-full h-full m-4"> */}
            <div
              className={`bg-white rounded-xl p-5 my-10 ${
                visible ? "openAnimationChildren" : "closeAnimationChildren"
              } ${className}` }
               onClick={(event) => event.stopPropagation()}
            >
              <div className="flex-between-center mb-7">
                <span className="text-dark text-base">{title}</span>
                {!notClose && (
                  <BiXCircle size={25} className="text-darkGray cursor-pointer" onClick={onClose} />
                )}
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </RenderModal>
  );
};

export default Modal;
