import { divIcon } from "leaflet";
import { useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { ImLocation } from "react-icons/im";
import { Marker, Popup, useMapEvents } from "react-leaflet";

export interface positionProps {
  lat: number;
  lng: number;
}

interface DragableMarkerProps {
  canOnClickMove?: boolean;
  canDraggedMove?: boolean;
  position?: positionProps;
  setPosition?: any;
  popUpRenderProps?: () => JSX.Element;
  setNewPositionAction?: (position: positionProps) => void;
}

// 35.72391781007263, 51.33256302889833
const DragableMarker = ({
  position = { lat: 35.72391781007263, lng: 51.33256302889833 },
  setPosition,
  canOnClickMove,
  canDraggedMove = true,
  popUpRenderProps,
  setNewPositionAction,
}: DragableMarkerProps) => {
  const markerRef = useRef<any>(null);

  const eventHandlers = useMemo(
    () => ({
      //handle draggable marker

      dragend() {
        const marker = markerRef.current;
        if (marker != null && canDraggedMove) {
          setPosition && setPosition("lat", marker.getLatLng().lat);
          setPosition && setPosition("lng", marker.getLatLng().lng);
          setNewPositionAction &&
            setNewPositionAction({
              lat: marker.getLatLng().lat,
              lng: marker.getLatLng().lng,
            });
        }
      },
    }),
    [setNewPositionAction, setPosition]
  );

  useMapEvents({
    click(e) {
      if (canOnClickMove) {
        setPosition && setPosition("lat", e.latlng.lat);
        setPosition && setPosition("lng", e.latlng.lng);
        setNewPositionAction &&
          setNewPositionAction({ lat: e.latlng.lat, lng: e.latlng.lng });
      }
    },
  });

  const iconMarkup: any = renderToStaticMarkup(
    <span>
      <ImLocation
        className="bg-none border-none absolute top-[-10px] left-[-14px] bottom-0 m-auto "
        size={"40px"}
      />
    </span>
  );
  const customMarkerIcon: any = divIcon({
    html: iconMarkup,
  });
  return (
    <Marker
      icon={customMarkerIcon}
      draggable={canDraggedMove}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    >
      {popUpRenderProps && <Popup>{popUpRenderProps()}</Popup>}
    </Marker>
  );
};

export default DragableMarker;
