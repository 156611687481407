import { FC } from "react";
import { Tooltip } from "@material-tailwind/react";
interface TooltipWrapperProps {
  children?: any;
  textProps?: string;
  extraClass?: string;
}
const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  textProps,
  extraClass = "overflow-hidden",
}) => {
  return (
    <Tooltip className={textProps?.length === 0 ? "hidden" : ""} content={textProps}>
      <div className={` text-ellipsis ${extraClass}`}>{children}</div>
    </Tooltip>
  );
};

export default TooltipWrapper;
