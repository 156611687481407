import Barcode from "react-barcode";
import { toast } from "react-toastify";
import { BsUpcScan } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "global/Modal/Modal";
import { addEditUrls, filterUrls } from "services/api.enums";
import InputText from "global/InputText/InputText";
import { addEditDataAPI, filterTableDataAPI } from "services/CRUDServices";
import NewStaticTable from "components/staticTable/NewStaticTable";
import SimpleButton from "global/SimpleButton/SimpleButton";
import { editConsignments } from "redux/OrderData/OrderData";

const AttachBarcodeModal = ({ isOpen, setIsOpen, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const [labels, setLabels] = useState([]);
  const [serialNumber, setSerialNumber] = useState("");

  const dispatch = useDispatch();

  const { currenthub } = useSelector((state: any) => state.userInfo);

  useEffect(() => {
    if (isOpen && serialNumber.length > 11) {
      setIsLoading(true);
      filterTableDataAPI(
        filterUrls.labelManagement,
        1,
        {
          selectHub: currenthub,
          serialNumber,
        },
        true,
        3
      ).then((res) => {
        setLabels(res.data.content);
        setIsLoading(false);
      });
    }
  }, [currenthub, isOpen, serialNumber]);

  const filtered = () =>
    labels.filter((item) => item?.serialNumber.toString().includes(serialNumber.toString())) || [];

  const handleAttachLabel = () => {
    if (!serialNumber) {
      toast.warning("لطفا شماره بارکد را وارد نمایید");
      return;
    }
    if (filtered().length === 0) {
      toast.warning("لطفا شماره بارکد را از لیست انتخاب نمایید");
      return;
    }
    if (serialNumber) {
      setIsLoadingBtn(true);
      addEditDataAPI(addEditUrls.consignmentLabelStick, "post", {
        selectConsignment: { id: data.consignmentId },
        selectLabel: { text: serialNumber },
        selectHub: currenthub,
      }).then(() => {
        setIsOpen(false);
        toast.success("الصاق بارکد باموفقیت انجام شد");
        setSerialNumber("");
        dispatch(
          editConsignments({
            ...data,
            serialNumber,
          })
        );
        setIsLoadingBtn(false);
      }).catch(()=>{
        setIsLoadingBtn(false);
      });
    }
  };

  const handleSetRowSelected = (item: any) => setSerialNumber(item?.serialNumber);

  useEffect(() => {
    if (filtered().length === 1) {
      setSerialNumber(filtered()[0].serialNumber);
    }
  }, [labels]);

  useEffect(() => {
    if (!isOpen) {
      setLabels([]);
      setSerialNumber("");
    }
  }, [isOpen]);

  return (
    <Modal visible={isOpen} setVisible={setIsOpen} title="الصاق بارکد">
      {data?.serialNumber ? (
        <Barcode value={data?.serialNumber} />
      ) : (
        <>
          <InputText
            label="شماره بارکد"
            wrapperClassName="w-full"
            leftIcon={
              <div className="flex gap-3 ">
                <button
                // onClick={() => {
                //   setIsOpen(false);
                // }}
                >
                  <BsUpcScan size={30} className="text-[#EAB308] " />
                </button>
              </div>
            }
            placeholder="شماره بارکد را وارد کنید"
            handleChange={(e) => setSerialNumber(e.target.value)}
            values={serialNumber}
          />
          <NewStaticTable
            loading={isLoading}
            data={labels ? filtered() : []}
            column={labelsColumn}
            HoleClassName="max-h-[19rem] min-h-[19rem] w-full overflow-x-hidden !mt-0"
            setRowSelected={handleSetRowSelected}
          />
          <SimpleButton
            className="full-tomato-btn w-full mt-5"
            text="الصاق بارکد"
            handelClick={handleAttachLabel}
            loading={isLoadingBtn}
          />
        </>
      )}
    </Modal>
  );
};

export default AttachBarcodeModal;

export const labelsColumn = [
  {
    accessor: "serialNumber",
    Header: "شماره بارکد",
    header: "شماره بارکد",
  },
];
