import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addEditDataAPI } from "services/CRUDServices";
import Radio from "global/Radio/Radio";
import Checkbox from "components/checkbox/Checkbox";
import SimpleButton from "global/SimpleButton/SimpleButton";
import NewStaticTable from "components/staticTable/NewStaticTable";
import { ConsignmentsPaymentColumns } from "./ConsignemtsPaymentColumns";
import { setNewConsignments, setOrder } from "redux/OrderData/OrderData";
import PaymentModal from "./PaymentModal";
import { usePayment } from "./utils/usePayment";

const ConsignmentsPayment = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { consignments, orderData } = useSelector((state: any) => state.Order);
  const { handleClick } = usePayment();

  const formik = useFormik({
    enableReinitialize: true,
    // validationSchema: ConsignmentsPaymentValidation,
    initialValues: {
      consignments: consignments.map((consignment) => ({
        ...consignment,
        payBy: consignment.supplementary.some(
          (service) => service.id === 101
        )
          ? 1
          : 0,
      })),
    },

    onSubmit: (values: any) => {
      let data = values?.consignments.map((item: any) => ({
        paymentBy: { id:item.payBy },
        invoiceDetailsId: item?.invoiceDetailsId,
        orderId: orderData?.orderId,
        customerId: orderData?.senderCustomerId,
        // prospectId: item?.payBy === 0 ? null : item?.receiverProspectId || null,
        isAdminPanel: true,
      }));
      addEditDataAPI(
        "consignment-api/invoice/invoiceDetailsPayment",
        "post",
        data
      ).then((res) => {
        dispatch(setOrder(res.data.payload));
        dispatch(setNewConsignments(values.consignments));
        if (
          !values.consignments.every((item) =>
            item.supplementary.some((sub) => sub.serviceType === 1)
          )
        ) {
          setIsOpen(true);
        } else {
            handleClick(false,{...orderData,...res.data.payload})
         
        }
      });
    },
  });

  const { values, setFieldValue, handleSubmit, errors }: any = formik;

  const data = formik.values.consignments.map((item: any, index: number) => ({
    ...item,
    price: Number(item?.price).toLocaleString(),

    declarativeValue:
      (item?.selectContentTypeId || item?.selectContentTypeId?.id) !== 2
        ? Number(item?.declarativeValue).toLocaleString()
        : "-----",
    declarativeVolume:
      (item?.selectContentTypeId || item?.selectContentTypeId?.id) !== 2
        ? ` ${item.declarativeLength}*${item.declarativeWidth}*${item?.declarativeHeight}`
        : "-----",
    payBySender: (
      <Radio
        title="پرداخت با فرستنده"
        labelClassName="lg:hidden"
        name={`consignments.${index}.payBy`}
        values={0}
        checked={0}
        handleChange={setFieldValue}
        wrapperClassName=" max-lg:!autocompleteWrapper !justify-start lg:!justify-center"
        errors={
          errors?.consignments?.length > 0 && errors?.consignments[index]?.payBy
        }
        // isDisabled={item?.bill?.creditorsLedger > 0}
        isDisabled={true}
      />
    ),
    payByReciever: (
      <Radio
        title="پرداخت با گیرنده"
        labelClassName="lg:hidden"
        name={`consignments.${index}.payBy`}
        values={values.consignments[index].payBy}
        checked={1}
        handleChange={setFieldValue}
        wrapperClassName=" max-lg:!autocompleteWrapper !justify-start lg:!justify-center"
        errors={
          errors?.consignments?.length > 0 && errors?.consignments[index]?.payBy
        }
        // isDisabled={item?.bill?.creditorsLedger > 0}
        isDisabled={true}
      />
    ),
    otherPrice: (
      <Checkbox
        className="!m-0"
        title="COD"
        labelClassName="lg:hidden"
        values={item?.needToPack || item?.needToReciveCostOfGoods}
        // isDisabled={true}
        WrapperClassName=" w-full flex lg:!justify-center !mb-0 "
      />
    ),
  }));

  return (
    <>
      {/* // <DynamicHeader title="تایید و پرداخت"> */}
      <form className="content" onSubmit={handleSubmit}>
        <div className="flex justify-between items-center Max-md:flex-col gap-3">
          <h1 className="text-base text-dark font-semibold">
            مشخص کردن اطلاعات پرداخت مرسوله ها
          </h1>
          <div className="flex gap-3 Max-sm:flex-col Max-sm:w-full">
            <SimpleButton
              handelClick={() => navigate(-1)}
              className="line-tomato-btn w-full sm:w-44"
              text="بازگشت به مرحله قبل"
            />
            <SimpleButton
              text="پرداخت"
              className="full-tomato-btn w-full sm:w-44"
              type="submit"
            />
          </div>
        </div>
        <NewStaticTable data={data || []} column={ConsignmentsPaymentColumns} />
      </form>
      {/* // </DynamicHeader> */}
      <PaymentModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ConsignmentsPayment;