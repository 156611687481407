import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import SingleConsignment from "../SingleConsignment";
import { printDocument, printMultiDocument, printTripDocument } from "./helper";
import MultiConsignments from "../MultiConsignment";
import TripPdf from "../Trip";

function PDFPortal({ show, item, setShow, type = undefined }) {
  const isMulti = Boolean(item?.consignments?.length > 0);

  const [mount, setMount] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    setMount(true);
    containerRef.current = document.querySelector("#pdf-root");
    if (show && type === "trip") return printTripDocument(() => setShow(false));
    if (show && !isMulti) return printDocument(() => setShow(false));
    if (show && isMulti) return printMultiDocument(() => setShow(false),item);
    return () => {
      setMount(false);
    };
  }, [show]);

  return (
    containerRef.current &&
    show &&
    mount &&
    createPortal(
      type === "trip" ? (
        <TripPdf item={item} />
      ) : isMulti ? (
        <MultiConsignments item={item} />
      ) : (
        <SingleConsignment item={item} />
      ),
      containerRef.current
    )
  );
}

export default PDFPortal;
