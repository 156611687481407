import Modal from "global/Modal/Modal";
import SimpleButton from "global/SimpleButton/SimpleButton";

export const Confirm = ({ open, setOpen, title, handleClick,children=undefined,loading=undefined }) => {
  return (
    <Modal visible={open} setVisible={setOpen} title={title}>
      <div className="min-w-[300px] p-4">
        {children}
        <div className="flex justify-center items-center gap-4">
          <SimpleButton
            className="w-[120px] bg-white outline outline-gray-100 shadow-2xl"
            text="لغو"
            handelClick={() => setOpen(false)}
          />
          <SimpleButton
            className="w-[120px] bg-tomato text-white"
            text="تایید"
            handelClick={handleClick}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};
