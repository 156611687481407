import CustomDelete from "components/CustomDelete";
import {
  checkPlaningCondition,
  convertResponse,
} from "pages/optimePanel/utils";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { TbLiveView } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionChangeView, ActionSelectUnAssignPin } from "redux/optime/optime";
import {
  deleteDataAPI,
  fetchSearchAddressNew,
  getAPI,
} from "services/CRUDServices";
import { deleteUrls, getUrls } from "services/api.enums";
import { Confirm } from "../confirm-modal";

interface ConsignemntHeader {
  setOptimeData: any;
  item: any;
  setInitialAddress: any;
  setConsignmentInfo: any;
  setAddressModal: any;
  hasDelete?: boolean;
  customClass?: string;
}
export const ConsignmentHeader = ({
  setOptimeData,
  item,
  setInitialAddress,
  setConsignmentInfo,
  setAddressModal,
  hasDelete,
  customClass,
}: ConsignemntHeader) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const [openDelete, setOpenDelete] = useState(false);
  const [loading,setLoading]=useState(false)
  function deletePin(pin) {
    setLoading(true)
    deleteDataAPI(deleteUrls.deleteByPudoPlaning, pin.planingId).then(() => {
      toast.success("عملیات با موفقیت انجام شد", { position: "top-left" });
      dispatch(ActionSelectUnAssignPin({}));
      setLoading(false)
      setOpenDelete(false)
      getAPI(getUrls.planingConsignment + id).then((res) => {
        const convert = convertResponse(res);
        setOptimeData(convert);
     
      });
    }).catch(()=>setLoading(false));
  }

  function changeView(item) {
    dispatch(
      ActionChangeView({ lat: item.latitude, lng: item.longitude, zoom: 17 })
    );
  }

  return (
    <div className={`${customClass} flex justify-end items-center gap-3`}>
      {checkPlaningCondition(state) && (
        <>
          {hasDelete && (
            <MdDelete
              color="white"
              size={25}
              className=" cursor-pointer "
              onClick={() => setOpenDelete(true)}
            />
          )}
          {item.latitude && (
            <>
              <TbLiveView
                color="white"
                size={25}
                className=" cursor-pointer"
                onClick={() => changeView(item)}
                title="مشاهده در نقشه"
              />
              <AiFillEdit
                color="white"
                className="cursor-pointer"
                size={25}
                onClick={async (e) => {
                  e.stopPropagation();
                  const res = await fetchSearchAddressNew({
                    lat: item.latitude,
                    long: item.longitude,
                  });
                  const resData = await getAPI(
                    getUrls.findAddressFromConsignment +
                      "/" +
                      item.consignmentId
                  );
                  setInitialAddress(resData.data.payload.address);
                  setConsignmentInfo({
                    planingId: item.planingId,
                    consignmentId: item.consignmentId,
                  });
                  setAddressModal({
                    isOpen: true,
                    data: {
                      ...resData.data.payload,
                      dynamicAddress: res.data.address,
                    },
                    isDifficult: resData.data.payload.isDifficult,
                    isCloseAfterSave: true,
                  });
                }}
              />
            </>
          )}
        </>
      )}

      <Confirm
        title="آیا از حذف این مورد اطمینان دارید؟ "
        open={openDelete}
        setOpen={setOpenDelete}
        handleClick={() => deletePin(item)}
        loading={loading}
      />
    </div>
  );
};
