import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "./../../services/api.enums";

export const thirdPartyData: AsyncThunk<{}, {}, {}> = createAsyncThunk("thirdPartyList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.thirdParty,
    body.pageNumber,
    {
      ...body,
      telNumber: parseInt(body.telNumber),
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );
  return data;
});

const initialState: any = {
  thirdPartyList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const ThirdPartyList = createSlice({
  initialState: initialState,
  name: "thirdPartyList",
  reducers: {
    clearThirdParty: (state) => {
      state.thirdPartyList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [thirdPartyData.fulfilled as any]: (state, action) => {
      state.thirdPartyList = action.payload.payload;
      state.fetchPost = false;
    },
    [thirdPartyData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [thirdPartyData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearThirdParty, setFilter } = ThirdPartyList.actions;
export default ThirdPartyList.reducer;
