import { toast } from "react-toastify";
import { BiPlus } from "react-icons/bi";
import { ReverseArray } from "tools/functions/Methods";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import OrderHeader from "../order-header";
import SimpleButton from "global/SimpleButton/SimpleButton";
import ConsignmentElements from "pages/ConsignmentManageOperation/view/AddOrderForm/Elements/ConsignmentElements";
import OrderFacture from "pages/ConsignmentManageOperation/view/AddOrderForm/OrderFacture";
import { useEffect, useState } from "react";
import { getAPI } from "services/CRUDServices";
import { getUrls } from "services/api.enums";
import Modal from "global/Modal/Modal";
import PostOrderForm from "../form";
import AttachBarcodeModal from "pages/ConsignmentManageOperation/view/AddOrderForm/AttachBarcodeModal";
import { useSelector } from "react-redux";

const ConsignmentCard = () => {
  const [Invoice, setInvoice] = useState();
  const { orderManagementData } = useSelector((state: any) => state.orderData);
  const [openForm, setOpenForm] = useState(false);
  const [barcodeModal, setBarcodeModal] = useState({
    isOpen: false,
    data: null,
  });
  const [editModalData, setEditModalData] = useState(false);
  const { consignments, orderData } = useSelector((state: any) => state.Order);


  const handleEditConsignment = (item: any) => {

    setOpenForm(true);
    setEditModalData(item);
  };

  useEffect(() => {
    orderManagementData.orderId &&
      consignments.length > 0 &&
      getAPI(
        `${getUrls.invoiceReportinfo}/${orderManagementData.orderId}`
      ).then((res) => setInvoice(res.data.payload));
  }, [consignments?.length, orderManagementData?.orderId,!openForm]);

  const setCloseModal = () => setBarcodeModal({ isOpen: false, data: null });

  const handleOpenBarcodeModal = (item) => {
    if (item.ServiceId) {
      setBarcodeModal({
        isOpen: true,
        data: item,
      });
      return;
    }
    toast.error("لطفا ابتدا سرویس ارسال را انتخاب کنید");
  };

  useEffect(() => {
    if (orderManagementData?.openModal) {
      setOpenForm(true);
    }
  }, [orderManagementData?.openModal]);
  return (
    <>
      <Breadcrumb beforePage="مدیریت مرسوله" curentPage="سفارش" />
      <OrderHeader />
      <div className="bg-lightGray rounded-2xl my-8 max-w-6xl shadow">
        <div className="flex-start-start p-8 px-12 gap-14">
          <div className="w-full sm:w-[42rem]">
            <div className="flex-between-center mb-3 ">
              <p className="text-xl font-semibold text-dark">مرسوله ها</p>
              {orderData?.inVoiceStatusId !== 2 && (
                <SimpleButton
                  handelClick={() => {
                    setOpenForm(true);
                    setEditModalData(null);
                  }}
                  className="full-tomato-btn w-60 Max-sm:w-full"
                  text="افزودن مرسوله جدید"
                  icon={<BiPlus size={21} />}
                />
              )}
            </div>
            <div className="flex-between-start flex-wrap gap-4">
              {ReverseArray(consignments).map((item: any, index: number) => (
                <ConsignmentElements
                  item={item}
                  index={index}
                  handleEditConsignment={handleEditConsignment}
                  handleOpenBarcodeModal={() => handleOpenBarcodeModal(item)}
                />
              ))}
            </div>
          </div>
          <OrderFacture data={Invoice} />
        </div>
      </div>

      <AttachBarcodeModal
        isOpen={barcodeModal.isOpen}
        setIsOpen={setCloseModal}
        data={barcodeModal.data}
      />
      <Modal visible={openForm} setVisible={setOpenForm}>
        <PostOrderForm
          setOpenForm={setOpenForm}
          editModalData={editModalData}
          setEditModalData={setEditModalData}
        />
      </Modal>
    </>
  );
};

export default ConsignmentCard;
