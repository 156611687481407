import {createSlice} from "@reduxjs/toolkit"

interface pageNumberss{
    pageNumbers:number
    fixedPageNumbers:number
}


const initialState :pageNumberss= {
    pageNumbers:1,
    fixedPageNumbers:1

}

const pageNumber=createSlice({
    initialState,
    name:"pageNumber",
    reducers:{
        Actionpage:(state,action)=>{
            state.pageNumbers =action.payload
            state.fixedPageNumbers=action.payload
        },
        clearPaginate:(state,action)=>{
            state.pageNumbers=action.payload
        },
        // ActionFixedpage:(state,action)=>{
        //     state.fixedPageNumbers=action.payload
        // },
        clearFixedPaginate:(state)=>{
            state.fixedPageNumbers=1
        }
    }

})

export const {Actionpage,clearPaginate,clearFixedPaginate}=pageNumber.actions
export default pageNumber.reducer