import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import DoneRecovering from "./views/DoneRecovering";
import FirstStepRecovery from "./views/FirstStepRecovery";
import ThirdStepRecovery from "./views/ThirdStepRecovery";
import SecondStepRecovery from "./views/SecondStepRecovery";
import { addEditDataAPI } from "../../services/CRUDServices";
import { addEditUrls } from "../../services/api.enums";
import { ForgotPasswordValidation } from "global/Validation/Validation";

const ForgotPassword = () => {
  const [Step, setStep] = useState(1);
  const [timer, setTimer] = useState(120);
  const [data, setData] = useState({ mobile: "", username: "", tokenID: "" });
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ForgotPasswordValidation,
    initialValues: {
      username: "",
      changePasswordType: {
        id: 1,
        text: "پرسنلی",
      },
      mobile: "",
      tokenID: "",
      authenticationCode: "",
      expireTime: "",
    },
    onSubmit: (values) => {
      setLoading(true);
      addEditDataAPI(addEditUrls.recoveryCode, "post", {
        username: values.username,
        changePasswordType: {
          id: 1,
          text: "پرسنلی",
        },
      })
        .then((res) => {
          setData(res.data.payload);
          setStep(2);
          setTimer(120);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.errors?.details);
        })
        .finally(() => setLoading(false));
    },
  });
  const {handleSubmit } = formik;
  const handleSecondStep = (auth: any) => {
    setLoading(true);
    addEditDataAPI(addEditUrls.checkRecoveryCode, "post", {
      ...data,
      authenticationCode: auth,
    })
      .then(() => {
        setStep(3);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const handleThirdStep = (form: any) => {
    setLoading(true);
    addEditDataAPI(addEditUrls.changePassword, "post", {
      ...form,
      username: data.username,
      tokenID: data.tokenID,
      changePasswordType: {
        id: 1,
        text: "پرسنلی",
      },
    })
      .then(() => {
        setStep(4);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <div className="centering bg-[#e5e5e5] h-screen">
      <div className="w-[23rem] h-[23rem] xl:w-[25rem] xl:h-[27rem] bg-white flex-center-start flex-col p-8 rounded-2xl">
        <h1 className="text-center mb-10 mt-5 !font-Julee text-[3.5rem] tracking-[1rem] text-tomato">
          BOXI
        </h1>
        {Step === 1 && <FirstStepRecovery formik={formik} loading={loading} />}
        {Step === 2 && (
          <SecondStepRecovery
            phone={data.mobile}
            timer={timer}
            setTimer={setTimer}
            handleSubmit={handleSecondStep}
            handleResendCode={handleSubmit}
            ReturnToFirstStep={() => setStep(1)}
          />
        )}
        {Step === 3 && <ThirdStepRecovery handleChangePass={handleThirdStep} loading={loading} />}
        {Step === 4 && <DoneRecovering />}
      </div>
    </div>
  );
};

export default ForgotPassword;
