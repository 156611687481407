import { MdOutlineAllInbox } from "react-icons/md";
import { childs } from "./childs";

export const rullEngineRoutes: ISideBarRoutes = {
  parentName: "view_rule_engine",
  Icon: <MdOutlineAllInbox size={"25"} />,
  code: "",
  label: "RullEngine",
  childs: childs,
};
