import { TbMicrophone, TbMicrophoneOff } from "react-icons/tb";

export function RenderIcon({isListening,startListening,stopListening}) {
  return (
    <>
      {!isListening ? (
        <TbMicrophone
          onClick={startListening}
          className=" cursor-pointer"
        />
      ) : (
        <TbMicrophoneOff className=" cursor-pointer" onClick={stopListening} />
      )}
    </>
  );
}
