import { store } from "redux/store/Store";

export function hasPermissionParent(childs: { permissions }[]) {
  const { isSuperAdmin, perms } = store.getState().userInfo;
  const childPerms = childs.map(({ permissions }) => permissions);
  if (isSuperAdmin) return true;
  return childPerms.some((item) => perms.includes(item));
}

export function hasPermission(permission: string) {
  const { isSuperAdmin, perms } = store.getState().userInfo;

  if (isSuperAdmin) return true;
  return perms.includes(permission);
}
