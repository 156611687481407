import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { filterTableDataAPI } from "../../services/CRUDServices";
import { filterUrls } from "../../services/api.enums";

export const ADMVehicleData: AsyncThunk<{}, {}, {}> = createAsyncThunk("ADMVehicleList", async (body: any) => {
  let { data } = await filterTableDataAPI(
    filterUrls.admVehicle,
    body.pageNumber,
    {
      ...body,
      pageNumber: undefined,
      pageSize: undefined,
    },
    true,
    10,
    false
  );
  return data;
});

const initialState: any = {
  ADMVehicleList: [],
  fetchpost: false,
  errorMessage: null,
  filter: {},
};

const ADMVehicleList = createSlice({
  initialState: initialState,
  name: "ADMVehicleList",
  reducers: {
    clearADMVehicle: (state) => {
      state.ADMVehicleList = [];
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [ADMVehicleData.fulfilled as any]: (state, action) => {
      state.ADMVehicleList = action.payload.payload;
      state.fetchPost = false;
    },
    [ADMVehicleData.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [ADMVehicleData.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { clearADMVehicle, setFilter } = ADMVehicleList.actions;
export default ADMVehicleList.reducer;
