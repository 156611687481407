import {createSlice,createAsyncThunk} from "@reduxjs/toolkit"

import { filterTableDataAPI } from "../../../services/CRUDServices";
import { filterUrls } from "../../../services/api.enums";
import { ErrorAlert } from "global/alert/Alert";


export const filterException=createAsyncThunk('exception',async(body:any)=>{
    let data;
    try {
        data = await filterTableDataAPI(filterUrls.exception as any, body?.pageNumber || 1,{
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
        });
      } catch (error) {
        ErrorAlert(error?.response?.data?.errors?.message)
      }
    return data.data;
})

const initialState:any= {
    exceptionLists:[],
    fetchpost:false,
    errorMessage:null,
    isUpdating: false,    
    filter:{}
}

const ExceptionLists= createSlice({
    initialState: initialState,
    name: "exception",
    reducers: {
        clearException: (state) => {
            state.exceptionLists = [];
          },
          setFilter: (state, action) => {
            state.filter = action.payload;
          },
    },
    extraReducers: {
        [filterException.fulfilled as any]: (state, action) => {
            state.exceptionLists = action.payload.payload;
            state.fetchPost = false;
        },
        [filterException.pending as any]: (state) => {
            state.fetchPost = true;
        },
        [filterException.rejected as any]: (state) => {
            state.fetchPost = false;
            state.errorMessage = "wrong";
        },
    },
});

export const {  setFilter,clearException } = ExceptionLists.actions;
export default ExceptionLists.reducer