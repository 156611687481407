import { MdForwardToInbox } from "react-icons/md";
import { childs } from "./childs";

export const consignmentManagementRoutes: ISideBarRoutes = {
  parentName: "view_consignments _management",
  code: "",
  Icon: <MdForwardToInbox size={"25"} />,
  label: "مدیریت مرسوله",
  childs: childs,
};
