import { MdMarkunreadMailbox } from "react-icons/md";
import { childs } from "./childs";

export const crmManagementRoutes: ISideBarRoutes = {
  parentName: "view_crm_consignments_management",
  code: "",
  Icon: <MdMarkunreadMailbox size={"25"} />,
  label: "CRM Management",
  childs: childs,
};
