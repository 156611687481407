import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { filterUrls } from "../../../services/api.enums";
import { filterTableDataAPI, getAPI } from "../../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";



export const filterstate = createAsyncThunk("filterstatus", async (body: any) => {


  var data: any;
  try {
    data = await filterTableDataAPI(
        filterUrls.drivers as any,
      body?.pageNumber || 1,
      {
        ...body,
        pageNumber: undefined,
        pageSize: undefined,
      },
      true
    );
  } catch (error) {
  }

   let url= 'resource-api/driver/'
   let ids=data.data.payload.content.map((item: any) => {
      return { ...item,driverId:item.id, url:`${url}${item.id}` };
    });
  const fetchJson = async (item: any) =>
    await getAPI(item.url, true)
      .then((res: any) => {
      
        return { ...item, ...res.data.payload };
      })
      .catch((error) => {
        ErrorAlert(error?.response?.data?.errors?.message)
      });

  let content: any = [];
  content = await Promise.all(ids.map((item: any) => fetchJson(item))).then((values) => values);
  const arr1=content.filter(Boolean)
  data.data.payload.content = arr1;
   
    return data.data;
  
});

export interface StateData {
  driverListsState: Array<any>;
  changeStatusLists: Array<any>;
  fetchPost?: boolean;
  errorMessage: null | string;
  isUpdating: Boolean;
  filter: object;
}

const initialState: StateData = {
  driverListsState: [],
  changeStatusLists: [],
  fetchPost: false,
  errorMessage: null,
  isUpdating: false,
  filter: {},
};

const DriverListsState = createSlice({
  initialState: initialState,
  name: "driverListsState",
  reducers: {
    updating: (state: any, action: any) => {
      state.isUpdating = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearStateData: (state: any) => {
      state.driverListsState = [];
    },
  },
  extraReducers: {
    [filterstate.fulfilled as any]: (state, action) => {

        state.driverListsState = action.payload;
        state.fetchPost = false;
    },
    [filterstate.pending as any]: (state) => {
      state.fetchPost = true;
    },
    [filterstate.rejected as any]: (state) => {
      state.fetchPost = false;
      state.errorMessage = "wrong";
    },
  },
});

export const { setFilter, clearStateData } = DriverListsState.actions;
export default DriverListsState.reducer;
