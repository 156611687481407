export const provinceLocation: ProvinceLocationType = {
  Ardebil: { location: [38.25000246, 48.30003861], zoom: 8 },
  Alborz: { location: [35.90984159065909, 50.78547121877156], zoom: 9 },
  Bushehr: { location: [28.91997764, 50.83001339], zoom: 8 },
  ChaharMahallvaBakhtiari: { location: [32.32099805, 50.85399659], zoom: 8 },
  EastAzarbaijan: { location: [38.48290814, 47.06290482], zoom: 7 },
  Esfahan: { location: [32.01149436, 51.85971798], zoom: 7 },
  Fars: { location: [29.80144838, 52.82146806], zoom: 7 },
  Gilan: { location: [37.29998293, 49.62998328], zoom: 8 },
  Golestan: { location: [37.25182049, 55.17145382], zoom: 8 },
  Hamadan: { location: [34.31998395, 48.84997921], zoom: 8 },
  Hormozgan: { location: [27.20405978, 56.27213554], zoom: 7 },
  Ilam: { location: [33.63041363, 46.43002356], zoom: 8 },
  Kerman: { location: [29.46996991, 55.73002437], zoom: 7 },
  Kermanshah: { location: [34.5123753, 45.5772074], zoom: 8 },
  Khuzestan: { location: [31.97999758, 49.2999259], zoom: 8 },
  KohgiluyehAndBuyerAhmad: { location: [30.65900412, 51.59400361], zoom: 8 },
  Kordestan: { location: [35.30000165, 47.02001339], zoom: 8 },
  Lorestan: { location: [33.91995668, 48.8000081], zoom: 8 },
  Markazi: { location: [35.02182741, 50.33143917], zoom: 8 },
  Mazandaran: { location: [36.4713255, 52.36330481], zoom: 8 },
  NorthKhorasan: { location: [37.46999839, 57.32000484], zoom: 8 },
  Qazvin: { location: [36.27001996, 49.99998653], zoom: 8 },
  Qom: { location: [34.65001548, 50.95000606], zoom: 9 },
  RazaviKhorasan: { location: [36.22002301, 58.82001664], zoom: 6 },
  Semnan: { location: [36.42287884, 54.96288773], zoom: 7 },
  SistanAndBaluchestan: {
    location: [29.25869949801589, 60.317120320024806],
    zoom: 7,
  },
  SouthKhorasan: { location: [33.50649355, 60.89379187], zoom: 7 },
  Tehran: { location: [35.31658978, 51.64660437], zoom: 9 },
  WestAzarbaijan: { location: [37.52999473, 44.99998165], zoom: 7 },
  Yazd: { location: [31.42005292, 54.37000403], zoom: 8 },
  Zanjan: { location: [36.37002138, 48.50002641], zoom: 8 },
};

export const sampleDatajson: SampleDataJsonType = {
  Alborz: {
    properties: { value: "Alborz", name: "استان البرز, ایران", id: 1 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [51.2407494, 36.198365],
          [51.2407923, 36.1984343],
          [51.2491608, 36.2093771],
          [51.2189484, 36.2104852],
          [51.1986923, 36.2089616],
          [51.1828995, 36.2086846],
          [51.1351776, 36.2100697],
          [51.1037636, 36.2168562],
          [51.0217094, 36.242058],
          [50.9992218, 36.2488418],
          [50.9842873, 36.2624074],
          [50.9782791, 36.2653141],
          [50.950985, 36.2783234],
          [50.918026, 36.2873179],
          [50.9018898, 36.3116671],
          [50.9005165, 36.3396039],
          [50.89468, 36.3462409],
          [50.8818054, 36.349974],
          [50.8812904, 36.3474853],
          [50.8715057, 36.3357321],
          [50.8651543, 36.3347641],
          [50.8629227, 36.3319984],
          [50.8502197, 36.3310303],
          [50.8466148, 36.3268815],
          [50.8476448, 36.3248069],
          [50.8519363, 36.323009],
          [50.8527946, 36.3192746],
          [50.8488464, 36.3131886],
          [50.8452415, 36.3137419],
          [50.841465, 36.312497],
          [50.8390617, 36.3127737],
          [50.8339119, 36.3108371],
          [50.8289337, 36.3064105],
          [50.8277321, 36.3061338],
          [50.8213806, 36.3061338],
          [50.8169174, 36.302952],
          [50.7932281, 36.3023987],
          [50.7631874, 36.3062722],
          [50.7597542, 36.3073788],
          [50.7587242, 36.312497],
          [50.7544327, 36.31637],
          [50.7496262, 36.31637],
          [50.7453346, 36.3185831],
          [50.7307434, 36.318998],
          [50.7278252, 36.3170616],
          [50.7278252, 36.3141569],
          [50.7266235, 36.313327],
          [50.7219887, 36.313327],
          [50.7091141, 36.3152635],
          [50.7067108, 36.3184448],
          [50.7032776, 36.3213493],
          [50.6991577, 36.3213493],
          [50.6922913, 36.316785],
          [50.6897163, 36.3065488],
          [50.6773567, 36.2967264],
          [50.6725502, 36.2965881],
          [50.669117, 36.2936826],
          [50.6649971, 36.2928525],
          [50.6612206, 36.2889783],
          [50.6588173, 36.2855191],
          [50.656929, 36.2875946],
          [50.6509209, 36.2900852],
          [50.6413078, 36.2909154],
          [50.6394196, 36.2875946],
          [50.6280899, 36.2873179],
          [50.6213951, 36.2935443],
          [50.6121254, 36.2958963],
          [50.6076622, 36.296173],
          [50.6006241, 36.3015686],
          [50.5990791, 36.3011536],
          [50.5982208, 36.3004619],
          [50.5908394, 36.3012919],
          [50.5659485, 36.3071022],
          [50.5566788, 36.3098688],
          [50.5525589, 36.3091772],
          [50.5510139, 36.3057188],
          [50.5463791, 36.3026754],
          [50.5336761, 36.2993551],
          [50.5312729, 36.2946511],
          [50.5226898, 36.3019836],
          [50.5161667, 36.3069638],
          [50.5062103, 36.3105605],
          [50.5031204, 36.3184448],
          [50.5002022, 36.3198279],
          [50.4924774, 36.3185831],
          [50.474453, 36.3209344],
          [50.4634666, 36.3166467],
          [50.4595184, 36.3095922],
          [50.4572868, 36.3014303],
          [50.4473305, 36.2979716],
          [50.4406357, 36.2979716],
          [50.4323959, 36.2940977],
          [50.4229546, 36.2935443],
          [50.419178, 36.2902236],
          [50.4214096, 36.2741717],
          [50.4306793, 36.2689126],
          [50.4299927, 36.2506414],
          [50.4387474, 36.243581],
          [50.4332542, 36.2395659],
          [50.483036, 36.2254423],
          [50.5178833, 36.2020359],
          [50.5238914, 36.2006507],
          [50.5386543, 36.1861044],
          [50.667572, 36.1191569],
          [50.6734085, 36.1129167],
          [50.6758118, 36.1066759],
          [50.6737518, 36.1050116],
          [50.6612206, 36.098909],
          [50.602684, 36.0969671],
          [50.5571938, 36.0379949],
          [50.5529022, 36.0345246],
          [50.5542755, 36.0282776],
          [50.5465508, 36.0196699],
          [50.5285263, 36.0220302],
          [50.5103302, 36.0145326],
          [50.5026054, 36.0066176],
          [50.4933357, 35.9999518],
          [50.4801178, 36.0016183],
          [50.4572868, 36.0100892],
          [50.4469872, 36.010228],
          [50.4413223, 36.0084229],
          [50.4329109, 36.0046735],
          [50.4286194, 36.0038403],
          [50.4215813, 36.0038403],
          [50.4104233, 35.9941188],
          [50.3990936, 35.9520247],
          [50.3290558, 35.8535788],
          [50.2974701, 35.8215714],
          [50.2907753, 35.8203187],
          [50.2849388, 35.801247],
          [50.2856255, 35.7835633],
          [50.2758408, 35.7789677],
          [50.2634811, 35.7837025],
          [50.2533531, 35.7793855],
          [50.2439117, 35.7157161],
          [50.2433968, 35.6473927],
          [50.2384186, 35.6405571],
          [50.2269173, 35.6366508],
          [50.2056313, 35.6321863],
          [50.192585, 35.6303725],
          [50.1680374, 35.6235354],
          [50.1634026, 35.609441],
          [50.1682091, 35.5961815],
          [50.2088928, 35.5706335],
          [50.201683, 35.5273367],
          [50.3220177, 35.5433313],
          [50.3282833, 35.5466136],
          [50.3331757, 35.5509433],
          [50.3357506, 35.5718203],
          [50.3362656, 35.5799881],
          [50.3329182, 35.5910167],
          [50.3348923, 35.6104877],
          [50.349741, 35.6341395],
          [50.3505135, 35.6371391],
          [50.3547192, 35.6423707],
          [50.3681946, 35.6549948],
          [50.3733444, 35.6580634],
          [50.3897381, 35.6601555],
          [50.4003811, 35.6623173],
          [50.4288769, 35.665525],
          [50.4780579, 35.6744502],
          [50.5020905, 35.6769602],
          [50.5548763, 35.6778666],
          [50.5602837, 35.6784941],
          [50.6018257, 35.6854658],
          [50.6596327, 35.6841761],
          [50.7129335, 35.6833047],
          [50.7367086, 35.6821195],
          [50.7591748, 35.6985885],
          [50.7696891, 35.7041645],
          [50.7847524, 35.702091],
          [50.7872736, 35.7015944],
          [50.7965863, 35.6996427],
          [50.8002234, 35.6988586],
          [50.801897, 35.6984055],
          [50.8110595, 35.6939183],
          [50.8117247, 35.7048092],
          [50.815351, 35.7038335],
          [50.8164668, 35.7043562],
          [50.8265948, 35.7049835],
          [50.8285475, 35.7048964],
          [50.8298135, 35.7052797],
          [50.8319807, 35.706482],
          [50.8370662, 35.7079456],
          [50.8443403, 35.7087819],
          [50.8507347, 35.7100886],
          [50.8537388, 35.7120574],
          [50.8733082, 35.7196011],
          [50.8769131, 35.7123885],
          [50.8950233, 35.7188869],
          [50.8982205, 35.721831],
          [50.8991861, 35.7217962],
          [50.9027267, 35.7194269],
          [50.9000659, 35.7065691],
          [50.9047008, 35.6953647],
          [50.918777, 35.6917922],
          [50.9191847, 35.6927681],
          [50.9160733, 35.6943016],
          [50.9171033, 35.7021781],
          [50.9167385, 35.70521],
          [50.9196782, 35.7113779],
          [50.92206, 35.7147928],
          [50.9283257, 35.7200541],
          [50.9324455, 35.7221098],
          [50.9422088, 35.7200193],
          [50.9385824, 35.7106113],
          [50.9555125, 35.7069176],
          [50.9562206, 35.7072486],
          [50.9735584, 35.7041994],
          [50.9892654, 35.7011849],
          [50.9900808, 35.7018122],
          [50.9934282, 35.7010803],
          [50.9941149, 35.7021955],
          [50.9972906, 35.7005576],
          [51.0023975, 35.7071092],
          [51.0042429, 35.7066562],
          [51.005702, 35.712824],
          [51.0060883, 35.712824],
          [51.0071182, 35.7144269],
          [51.0066462, 35.7151238],
          [51.0084486, 35.7195489],
          [51.0119677, 35.7232072],
          [51.0170746, 35.7278409],
          [51.0256577, 35.7388492],
          [51.026988, 35.737839],
          [51.0287476, 35.7392672],
          [51.0302925, 35.7393717],
          [51.0350132, 35.7391627],
          [51.0377169, 35.738675],
          [51.0423517, 35.738048],
          [51.0493469, 35.738466],
          [51.0509348, 35.7393369],
          [51.0531235, 35.7376997],
          [51.0543251, 35.7355747],
          [51.056385, 35.7355747],
          [51.058445, 35.7355747],
          [51.0630798, 35.7343903],
          [51.0691738, 35.7345297],
          [51.0770702, 35.7343207],
          [51.0804176, 35.7333452],
          [51.0840225, 35.7334846],
          [51.0907173, 35.7352264],
          [51.0911465, 35.7402425],
          [51.0927773, 35.7402425],
          [51.0934639, 35.7422628],
          [51.0892582, 35.7430291],
          [51.0921764, 35.7486021],
          [51.0936356, 35.7561249],
          [51.0953522, 35.7619059],
          [51.1098576, 35.7683132],
          [51.1146641, 35.7777143],
          [51.1183548, 35.7836329],
          [51.1185265, 35.7917094],
          [51.1148357, 35.8123843],
          [51.1102867, 35.8217106],
          [51.1080551, 35.8423081],
          [51.1192131, 35.8469],
          [51.1229897, 35.8471783],
          [51.1248779, 35.8492655],
          [51.1269379, 35.8644305],
          [51.1808395, 35.9186669],
          [51.1828995, 35.9232544],
          [51.1818695, 35.9297877],
          [51.1530304, 35.9820346],
          [51.1523438, 35.9984241],
          [51.154747, 35.9992574],
          [51.210022, 35.9842571],
          [51.2529373, 35.9689761],
          [51.2678719, 35.96717],
          [51.3476944, 35.9249226],
          [51.3537025, 35.9220033],
          [51.3772202, 35.9211692],
          [51.3870049, 35.9262431],
          [51.3923264, 35.9310387],
          [51.4035702, 35.9524416],
          [51.4319801, 35.9947437],
          [51.4333534, 35.9971048],
          [51.4444256, 36.0049512],
          [51.4596176, 36.0232797],
          [51.4644241, 36.0242515],
          [51.4919758, 36.0534707],
          [51.4914608, 36.054234],
          [51.4994431, 36.0597851],
          [51.502018, 36.0603402],
          [51.5318871, 36.0796963],
          [51.5524864, 36.1016136],
          [51.5375519, 36.1025151],
          [51.5175533, 36.1077854],
          [51.5085411, 36.1117379],
          [51.5008163, 36.1174929],
          [51.4926624, 36.1216529],
          [51.4865685, 36.1262286],
          [51.4736938, 36.1299029],
          [51.4560986, 36.1459844],
          [51.4391041, 36.1551327],
          [51.4314651, 36.1601223],
          [51.4284611, 36.1602609],
          [51.40769, 36.156588],
          [51.4002228, 36.1562415],
          [51.3853741, 36.1583898],
          [51.38237, 36.1597065],
          [51.3703537, 36.1610924],
          [51.3561916, 36.1574889],
          [51.3534451, 36.1579047],
          [51.3502693, 36.1599837],
          [51.3460636, 36.1604688],
          [51.3442612, 36.1596372],
          [51.337738, 36.1533309],
          [51.3340473, 36.1541625],
          [51.3307858, 36.1537467],
          [51.3271809, 36.154509],
          [51.3064957, 36.1648344],
          [51.30023, 36.1667052],
          [51.297226, 36.1664974],
          [51.2927628, 36.1676753],
          [51.289072, 36.1678832],
          [51.2822914, 36.1667052],
          [51.27491, 36.1672596],
          [51.2646961, 36.1682989],
          [51.2491608, 36.1735648],
          [51.2453842, 36.1937242],
          [51.2426376, 36.1974646],
          [51.2407494, 36.198365],
        ],
      ],
    },
  },
  Ardebil: {
    properties: { value: "Ardebil", name: "استان اردبیل, ایران", id: 17 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [47.287172, 38.302263],
          [47.291062, 38.300314],
          [47.302172, 38.296703],
          [47.303561, 38.296425],
          [47.304672, 38.296425],
          [47.321895, 38.29143],
          [47.323561, 38.290595],
          [47.324951, 38.290317],
          [47.351064, 38.275594],
          [47.353288, 38.274481],
          [47.353288, 38.274202],
          [47.353841, 38.273924],
          [47.357731, 38.269477],
          [47.358007, 38.269199],
          [47.357731, 38.266145],
          [47.360507, 38.263091],
          [47.374946, 38.251985],
          [47.375788, 38.251422],
          [47.377731, 38.250317],
          [47.379121, 38.248925],
          [47.379954, 38.248646],
          [47.385788, 38.243921],
          [47.398568, 38.237813],
          [47.402734, 38.237256],
          [47.406067, 38.237256],
          [47.407177, 38.236977],
          [47.410791, 38.236977],
          [47.411624, 38.237256],
          [47.41301, 38.237256],
          [47.430234, 38.241145],
          [47.44968, 38.247811],
          [47.45079, 38.247811],
          [47.454957, 38.248646],
          [47.456903, 38.248646],
          [47.458014, 38.248925],
          [47.46079, 38.248925],
          [47.461347, 38.249203],
          [47.462457, 38.249203],
          [47.46357, 38.249482],
          [47.465513, 38.249482],
          [47.466347, 38.24976],
          [47.46746, 38.24976],
          [47.468013, 38.249482],
          [47.468847, 38.249482],
          [47.477737, 38.242538],
          [47.478847, 38.240589],
          [47.479127, 38.239762],
          [47.479403, 38.237813],
          [47.47968, 38.235315],
          [47.47996, 38.234266],
          [47.47996, 38.233645],
          [47.480237, 38.232261],
          [47.480513, 38.230312],
          [47.480793, 38.230033],
          [47.478013, 38.205868],
          [47.47468, 38.194755],
          [47.474404, 38.19365],
          [47.474127, 38.192536],
          [47.474127, 38.187531],
          [47.474404, 38.186148],
          [47.475237, 38.184198],
          [47.47607, 38.184198],
          [47.476347, 38.18392],
          [47.482737, 38.181979],
          [47.486904, 38.181979],
          [47.488017, 38.182536],
          [47.48885, 38.182536],
          [47.493017, 38.185034],
          [47.504683, 38.193919],
          [47.506073, 38.194476],
          [47.506627, 38.195033],
          [47.508573, 38.195869],
          [47.51024, 38.196147],
          [47.515516, 38.197261],
          [47.517183, 38.197261],
          [47.519126, 38.196983],
          [47.520793, 38.196426],
          [47.52135, 38.195869],
          [47.52663, 38.192536],
          [47.527463, 38.192536],
          [47.52774, 38.192257],
          [47.529963, 38.1917],
          [47.531906, 38.1917],
          [47.533016, 38.191422],
          [47.534683, 38.191422],
          [47.536073, 38.191143],
          [47.53635, 38.191422],
          [47.539406, 38.191422],
          [47.539683, 38.1917],
          [47.541629, 38.192536],
          [47.558573, 38.203092],
          [47.571353, 38.208365],
          [47.572462, 38.208365],
          [47.573019, 38.208644],
          [47.573852, 38.208644],
          [47.574686, 38.208922],
          [47.575796, 38.208922],
          [47.579686, 38.210036],
          [47.580799, 38.210036],
          [47.581075, 38.210315],
          [47.581909, 38.210315],
          [47.583019, 38.210872],
          [47.583852, 38.210872],
          [47.584966, 38.211429],
          [47.592742, 38.212812],
          [47.595242, 38.212812],
          [47.596075, 38.213091],
          [47.597186, 38.213091],
          [47.597742, 38.213369],
          [47.598575, 38.213369],
          [47.605522, 38.214762],
          [47.606075, 38.215031],
          [47.610799, 38.217259],
          [47.611632, 38.217259],
          [47.611909, 38.217538],
          [47.613022, 38.217538],
          [47.613298, 38.217816],
          [47.614689, 38.217816],
          [47.617189, 38.218364],
          [47.623299, 38.218095],
          [47.631912, 38.215867],
          [47.633298, 38.215867],
          [47.633855, 38.215588],
          [47.634965, 38.215588],
          [47.638022, 38.215031],
          [47.639968, 38.215031],
          [47.645521, 38.213926],
          [47.647188, 38.213926],
          [47.648302, 38.213648],
          [47.650245, 38.213648],
          [47.651355, 38.213369],
          [47.654692, 38.213369],
          [47.655801, 38.213091],
          [47.657745, 38.213091],
          [47.658858, 38.213369],
          [47.666358, 38.213369],
          [47.667468, 38.213648],
          [47.674968, 38.213648],
          [47.675525, 38.213926],
          [47.678025, 38.213926],
          [47.685966, 38.215531],
          [47.686915, 38.215588],
          [47.687468, 38.215867],
          [47.688581, 38.215867],
          [47.692191, 38.216424],
          [47.693024, 38.216702],
          [47.694691, 38.216702],
          [47.695525, 38.216981],
          [47.698025, 38.216981],
          [47.698581, 38.216981],
          [47.699414, 38.216702],
          [47.701358, 38.216702],
          [47.703304, 38.216145],
          [47.704414, 38.216145],
          [47.714137, 38.213091],
          [47.716638, 38.211699],
          [47.717748, 38.211429],
          [47.724694, 38.212256],
          [47.736084, 38.223368],
          [47.738304, 38.225875],
          [47.738584, 38.226423],
          [47.740804, 38.228929],
          [47.746637, 38.23281],
          [47.748307, 38.233367],
          [47.748584, 38.233088],
          [47.74886, 38.233367],
          [47.749417, 38.233088],
          [47.750527, 38.233088],
          [47.751084, 38.23281],
          [47.753584, 38.23281],
          [47.754141, 38.232531],
          [47.756361, 38.232262],
          [47.757474, 38.232531],
          [47.75775, 38.232262],
          [47.759694, 38.232531],
          [47.76275, 38.232531],
          [47.763307, 38.23281],
          [47.764694, 38.23281],
          [47.764974, 38.233088],
          [47.769226, 38.234231],
          [47.769974, 38.234481],
          [47.770807, 38.234481],
          [47.771084, 38.234759],
          [47.771917, 38.234759],
          [47.773307, 38.235873],
          [47.773307, 38.236421],
          [47.77775, 38.243653],
          [47.778583, 38.24615],
          [47.78053, 38.250596],
          [47.783583, 38.252815],
          [47.784417, 38.252815],
          [47.784697, 38.252537],
          [47.78525, 38.252537],
          [47.785807, 38.252537],
          [47.787473, 38.251423],
          [47.797473, 38.231983],
          [47.798587, 38.226423],
          [47.798864, 38.224761],
          [47.79914, 38.223925],
          [47.79914, 38.223368],
          [47.799973, 38.220035],
          [47.803307, 38.211699],
          [47.80414, 38.210594],
          [47.80831, 38.202535],
          [47.808863, 38.202257],
          [47.80942, 38.201143],
          [47.814696, 38.196983],
          [47.826643, 38.189203],
          [47.82692, 38.188925],
          [47.834143, 38.181423],
          [47.835533, 38.179761],
          [47.844977, 38.166428],
          [47.851923, 38.146977],
          [47.8522, 38.146699],
          [47.851923, 38.143922],
          [47.8522, 38.143644],
          [47.851923, 38.141981],
          [47.851367, 38.133365],
          [47.850533, 38.128927],
          [47.847756, 38.116419],
          [47.846367, 38.091701],
          [47.851643, 38.066972],
          [47.851924, 38.066702],
          [47.851924, 38.063917],
          [47.8522, 38.060592],
          [47.852477, 38.059477],
          [47.852757, 38.057806],
          [47.853033, 38.056144],
          [47.85331, 38.053924],
          [47.85359, 38.052253],
          [47.853867, 38.051417],
          [47.857849, 38.035862],
          [47.858034, 38.034478],
          [47.85831, 38.032528],
          [47.85859, 38.031144],
          [47.861924, 38.00892],
          [47.8622, 38.0067],
          [47.86248, 38.006421],
          [47.86859, 37.998639],
          [47.869147, 37.997812],
          [47.875257, 37.994756],
          [47.876367, 37.994756],
          [47.876647, 37.994478],
          [47.878313, 37.994478],
          [47.87859, 37.994199],
          [47.880537, 37.994199],
          [47.88109, 37.993921],
          [47.882756, 37.993921],
          [47.883313, 37.993642],
          [47.884703, 37.993642],
          [47.885257, 37.993363],
          [47.890537, 37.993363],
          [47.890813, 37.993085],
          [47.892203, 37.993085],
          [47.892756, 37.993363],
          [47.89609, 37.993363],
          [47.896923, 37.993642],
          [47.900813, 37.993642],
          [47.901093, 37.993363],
          [47.90248, 37.993363],
          [47.90276, 37.993085],
          [47.903593, 37.993085],
          [47.904147, 37.992806],
          [47.904703, 37.992806],
          [47.90526, 37.992528],
          [47.906093, 37.992528],
          [47.909703, 37.990865],
          [47.914703, 37.983091],
          [47.918316, 37.975308],
          [47.929426, 37.962528],
          [47.929703, 37.962249],
          [47.93665, 37.950026],
          [47.936926, 37.948642],
          [47.93776, 37.946692],
          [47.937206, 37.942809],
          [47.93665, 37.941416],
          [47.936093, 37.941137],
          [47.935817, 37.94058],
          [47.935259, 37.94031],
          [47.925816, 37.935034],
          [47.924983, 37.935034],
          [47.92387, 37.934477],
          [47.923037, 37.934477],
          [47.922483, 37.934198],
          [47.921926, 37.93392],
          [47.92137, 37.93392],
          [47.911927, 37.928365],
          [47.905537, 37.918083],
          [47.90137, 37.906973],
          [47.901094, 37.906416],
          [47.88998, 37.898362],
          [47.887757, 37.897805],
          [47.88748, 37.897247],
          [47.884704, 37.891971],
          [47.885537, 37.889751],
          [47.88887, 37.886137],
          [47.889423, 37.886137],
          [47.889423, 37.885859],
          [47.889981, 37.88558],
          [47.891647, 37.882803],
          [47.891924, 37.882524],
          [47.891647, 37.881697],
          [47.890814, 37.876421],
          [47.890814, 37.87225],
          [47.893037, 37.868357],
          [47.895537, 37.865859],
          [47.89637, 37.865032],
          [47.898037, 37.863638],
          [47.898314, 37.86336],
          [47.90026, 37.862524],
          [47.90387, 37.862524],
          [47.904427, 37.862802],
          [47.90526, 37.862802],
          [47.911647, 37.864474],
          [47.92637, 37.871135],
          [47.927203, 37.871135],
          [47.92776, 37.871414],
          [47.928593, 37.871414],
          [47.92915, 37.871692],
          [47.929983, 37.871692],
          [47.930537, 37.871971],
          [47.93137, 37.871971],
          [47.941373, 37.874191],
          [47.941927, 37.874191],
          [47.94276, 37.87447],
          [47.943873, 37.87447],
          [47.944426, 37.874749],
          [47.947206, 37.874749],
          [47.94804, 37.875027],
          [47.94915, 37.875027],
          [47.949983, 37.875306],
          [47.953593, 37.875306],
          [47.95415, 37.875027],
          [47.957206, 37.875027],
          [47.957483, 37.874749],
          [47.971096, 37.874749],
          [47.971373, 37.875027],
          [47.973316, 37.875027],
          [47.973873, 37.875306],
          [47.974983, 37.875306],
          [47.975263, 37.875585],
          [47.976096, 37.875585],
          [47.976653, 37.875863],
          [47.977486, 37.875863],
          [47.977486, 37.876142],
          [47.978039, 37.876142],
          [47.986096, 37.882803],
          [47.986653, 37.88336],
          [47.993319, 37.890308],
          [47.999403, 37.894957],
          [48.012486, 37.888637],
          [48.020265, 37.884196],
          [48.023599, 37.880861],
          [48.024152, 37.880861],
          [48.024432, 37.880583],
          [48.024985, 37.880583],
          [48.024986, 37.880304],
          [48.025542, 37.880304],
          [48.026099, 37.880025],
          [48.027209, 37.880025],
          [48.037765, 37.876969],
          [48.039989, 37.875585],
          [48.040266, 37.875306],
          [48.046656, 37.871135],
          [48.050265, 37.865302],
          [48.049709, 37.858362],
          [48.052766, 37.852528],
          [48.057212, 37.850029],
          [48.058322, 37.850029],
          [48.058598, 37.84975],
          [48.059155, 37.84975],
          [48.059432, 37.849472],
          [48.059989, 37.849472],
          [48.060265, 37.849193],
          [48.060822, 37.849193],
          [48.061655, 37.848636],
          [48.062489, 37.848636],
          [48.062765, 37.848357],
          [48.063879, 37.848087],
          [48.065545, 37.848087],
          [48.066099, 37.847809],
          [48.069155, 37.847809],
          [48.069989, 37.84753],
          [48.073879, 37.84753],
          [48.075545, 37.847809],
          [48.076102, 37.847809],
          [48.082769, 37.850029],
          [48.088602, 37.853643],
          [48.096378, 37.853643],
          [48.098551, 37.852731],
          [48.099158, 37.852528],
          [48.104712, 37.849193],
          [48.108048, 37.838918],
          [48.108325, 37.837525],
          [48.108602, 37.83641],
          [48.108882, 37.83614],
          [48.114715, 37.830306],
          [48.118048, 37.829192],
          [48.119158, 37.828913],
          [48.138605, 37.821694],
          [48.139158, 37.821137],
          [48.146104, 37.817802],
          [48.146381, 37.817244],
          [48.146938, 37.816966],
          [48.151105, 37.810304],
          [48.151105, 37.809747],
          [48.151105, 37.809468],
          [48.150271, 37.806421],
          [48.149438, 37.805863],
          [48.148881, 37.805306],
          [48.147771, 37.802528],
          [48.148048, 37.801134],
          [48.149161, 37.796415],
          [48.149438, 37.796136],
          [48.148328, 37.784467],
          [48.147495, 37.780583],
          [48.135272, 37.752245],
          [48.126658, 37.744746],
          [48.125825, 37.744189],
          [48.119158, 37.740584],
          [48.118325, 37.740583],
          [48.115268, 37.73919],
          [48.114435, 37.73919],
          [48.109159, 37.738084],
          [48.107769, 37.738084],
          [48.105269, 37.737527],
          [48.103602, 37.737526],
          [48.103045, 37.737248],
          [48.101659, 37.737248],
          [48.100545, 37.736969],
          [48.098879, 37.736969],
          [48.098326, 37.73669],
          [48.096379, 37.73669],
          [48.095269, 37.736412],
          [48.091379, 37.736412],
          [48.090269, 37.736133],
          [48.088045, 37.736133],
          [48.087489, 37.735854],
          [48.075269, 37.733912],
          [48.070822, 37.732249],
          [48.066656, 37.72947],
          [48.065266, 37.726692],
          [48.064432, 37.72502],
          [48.064156, 37.724741],
          [48.063599, 37.722799],
          [48.063599, 37.720578],
          [48.063322, 37.719751],
          [48.063322, 37.714743],
          [48.063879, 37.71058],
          [48.063046, 37.706408],
          [48.053879, 37.692525],
          [48.031067, 37.666227],
          [48.03082, 37.665855],
          [48.028876, 37.663077],
          [48.028043, 37.661413],
          [48.027766, 37.661134],
          [48.025543, 37.654462],
          [48.022486, 37.641405],
          [48.019987, 37.625857],
          [48.018877, 37.618079],
          [48.019153, 37.616685],
          [48.021653, 37.612242],
          [48.022766, 37.611963],
          [48.0236, 37.611136],
          [48.024153, 37.610857],
          [48.042767, 37.602521],
          [48.0436, 37.602521],
          [48.048043, 37.601127],
          [48.049156, 37.601127],
          [48.049433, 37.600848],
          [48.055266, 37.599185],
          [48.05638, 37.599185],
          [48.066933, 37.5953],
          [48.067766, 37.595021],
          [48.071656, 37.593078],
          [48.074713, 37.5903],
          [48.079156, 37.578357],
          [48.079156, 37.57252],
          [48.079713, 37.570299],
          [48.07888, 37.565019],
          [48.077213, 37.563077],
          [48.076936, 37.562519],
          [48.075269, 37.560577],
          [48.074156, 37.560019],
          [48.067766, 37.55474],
          [48.067213, 37.553912],
          [48.058046, 37.54446],
          [48.053047, 37.533353],
          [48.052767, 37.53141],
          [48.05999, 37.516965],
          [48.060267, 37.516408],
          [48.060266, 37.516129],
          [48.060545, 37.515571],
          [48.060823, 37.514735],
          [48.064713, 37.511128],
          [48.065823, 37.511128],
          [48.06638, 37.511686],
          [48.067213, 37.511686],
          [48.06749, 37.511965],
          [48.0686, 37.511965],
          [48.06888, 37.512243],
          [48.07027, 37.512243],
          [48.070823, 37.512522],
          [48.071656, 37.512522],
          [48.072213, 37.512243],
          [48.075546, 37.512243],
          [48.07638, 37.512522],
          [48.077489, 37.512243],
          [48.078046, 37.511686],
          [48.078603, 37.511686],
          [48.082213, 37.510292],
          [48.085546, 37.510292],
          [48.085823, 37.510571],
          [48.087213, 37.510571],
          [48.088326, 37.510292],
          [48.090826, 37.509743],
          [48.09666, 37.506406],
          [48.097493, 37.506406],
          [48.097769, 37.506127],
          [48.099436, 37.506127],
          [48.099713, 37.505849],
          [48.100269, 37.505849],
          [48.102769, 37.504185],
          [48.104713, 37.50002],
          [48.110624, 37.494376],
          [48.110826, 37.494183],
          [48.111103, 37.492798],
          [48.111383, 37.49224],
          [48.111936, 37.491961],
          [48.116383, 37.489461],
          [48.118603, 37.489461],
          [48.119159, 37.48974],
          [48.120269, 37.48974],
          [48.121103, 37.490018],
          [48.125549, 37.490018],
          [48.127216, 37.489182],
          [48.127493, 37.488633],
          [48.134439, 37.483632],
          [48.135273, 37.483632],
          [48.135549, 37.483353],
          [48.137216, 37.483353],
          [48.137773, 37.483632],
          [48.138606, 37.483632],
          [48.139159, 37.483911],
          [48.141939, 37.484181],
          [48.155272, 37.478352],
          [48.155549, 37.477795],
          [48.155829, 37.47641],
          [48.156106, 37.476131],
          [48.156105, 37.475294],
          [48.156105, 37.475015],
          [48.154163, 37.468629],
          [48.152772, 37.458906],
          [48.145829, 37.43335],
          [48.145549, 37.425569],
          [48.146382, 37.421683],
          [48.149439, 37.416124],
          [48.161939, 37.405014],
          [48.163606, 37.404178],
          [48.164439, 37.40335],
          [48.165829, 37.402235],
          [48.167496, 37.401128],
          [48.168329, 37.400013],
          [48.169719, 37.399185],
          [48.190909, 37.381441],
          [48.191385, 37.381121],
          [48.196662, 37.378351],
          [48.216385, 37.371127],
          [48.216942, 37.370569],
          [48.225555, 37.366125],
          [48.226665, 37.364182],
          [48.226665, 37.363624],
          [48.226942, 37.362517],
          [48.227775, 37.35918],
          [48.231388, 37.351956],
          [48.245555, 37.341681],
          [48.248332, 37.337237],
          [48.248612, 37.336958],
          [48.248888, 37.336122],
          [48.248888, 37.335294],
          [48.248612, 37.335015],
          [48.248332, 37.334457],
          [48.245832, 37.333351],
          [48.243888, 37.333351],
          [48.243888, 37.333072],
          [48.243332, 37.333072],
          [48.243055, 37.332235],
          [48.243332, 37.331956],
          [48.244445, 37.329455],
          [48.244165, 37.326963],
          [48.247222, 37.323904],
          [48.249722, 37.322788],
          [48.253612, 37.325011],
          [48.253888, 37.325568],
          [48.254722, 37.326405],
          [48.256945, 37.329455],
          [48.257778, 37.329455],
          [48.258335, 37.329734],
          [48.260002, 37.329455],
          [48.261945, 37.327233],
          [48.261668, 37.326405],
          [48.261945, 37.325847],
          [48.261945, 37.323067],
          [48.261388, 37.322788],
          [48.258612, 37.322519],
          [48.258612, 37.32224],
          [48.258055, 37.321961],
          [48.257779, 37.320845],
          [48.258335, 37.319738],
          [48.260002, 37.317516],
          [48.271388, 37.313342],
          [48.272778, 37.311956],
          [48.273055, 37.311677],
          [48.270835, 37.309455],
          [48.270278, 37.308069],
          [48.271112, 37.307233],
          [48.276388, 37.305289],
          [48.279725, 37.305289],
          [48.280278, 37.30501],
          [48.281112, 37.30501],
          [48.283058, 37.303067],
          [48.283058, 37.29918],
          [48.286945, 37.297237],
          [48.290835, 37.297237],
          [48.292501, 37.297237],
          [48.295558, 37.293899],
          [48.294725, 37.287511],
          [48.295278, 37.286404],
          [48.308058, 37.276399],
          [48.310281, 37.276399],
          [48.311391, 37.27612],
          [48.313335, 37.276957],
          [48.314724, 37.276957],
          [48.314724, 37.277236],
          [48.316115, 37.277236],
          [48.316671, 37.277236],
          [48.317505, 37.277236],
          [48.317781, 37.276957],
          [48.318338, 37.276957],
          [48.318614, 37.276678],
          [48.319448, 37.276399],
          [48.320005, 37.273907],
          [48.320281, 37.27307],
          [48.321391, 37.270848],
          [48.325281, 37.268904],
          [48.326391, 37.269453],
          [48.330004, 37.269453],
          [48.331391, 37.269174],
          [48.335281, 37.266403],
          [48.337781, 37.262795],
          [48.338061, 37.262237],
          [48.338061, 37.261958],
          [48.339448, 37.257513],
          [48.339321, 37.256875],
          [48.339171, 37.256118],
          [48.338338, 37.248344],
          [48.335561, 37.243899],
          [48.335004, 37.243899],
          [48.335004, 37.243621],
          [48.333338, 37.242514],
          [48.332505, 37.242514],
          [48.333058, 37.241398],
          [48.335004, 37.239454],
          [48.338895, 37.239454],
          [48.343338, 37.242235],
          [48.344728, 37.242235],
          [48.345005, 37.242514],
          [48.345838, 37.242514],
          [48.346114, 37.242235],
          [48.346671, 37.242235],
          [48.346948, 37.241956],
          [48.347781, 37.241956],
          [48.348614, 37.242235],
          [48.351671, 37.247238],
          [48.353338, 37.248065],
          [48.355838, 37.248066],
          [48.356671, 37.248344],
          [48.356951, 37.248065],
          [48.357784, 37.248065],
          [48.358618, 37.247508],
          [48.363617, 37.24668],
          [48.364171, 37.246959],
          [48.365284, 37.246959],
          [48.368061, 37.248344],
          [48.368894, 37.248344],
          [48.369171, 37.248623],
          [48.371394, 37.248623],
          [48.371674, 37.248344],
          [48.372508, 37.248344],
          [48.372507, 37.248066],
          [48.379174, 37.243063],
          [48.383894, 37.247508],
          [48.385561, 37.248623],
          [48.385561, 37.248902],
          [48.388341, 37.250009],
          [48.389174, 37.250009],
          [48.392231, 37.251683],
          [48.39445, 37.251683],
          [48.395007, 37.251683],
          [48.39945, 37.251683],
          [48.39945, 37.251404],
          [48.400007, 37.250846],
          [48.401117, 37.248623],
          [48.40195, 37.247238],
          [48.402507, 37.24668],
          [48.402507, 37.244736],
          [48.403064, 37.243621],
          [48.403897, 37.243063],
          [48.404731, 37.243063],
          [48.406117, 37.243621],
          [48.406674, 37.244179],
          [48.412782, 37.24668],
          [48.413246, 37.24668],
          [48.41334, 37.24668],
          [48.415287, 37.24668],
          [48.416954, 37.245843],
          [48.416397, 37.243351],
          [48.41723, 37.241677],
          [48.417787, 37.241677],
          [48.417787, 37.241398],
          [48.41834, 37.241119],
          [48.42362, 37.240849],
          [48.423064, 37.23779],
          [48.418897, 37.224176],
          [48.42084, 37.221953],
          [48.421673, 37.221674],
          [48.425563, 37.221674],
          [48.426397, 37.221395],
          [48.42862, 37.220847],
          [48.429453, 37.220568],
          [48.429453, 37.220289],
          [48.432787, 37.218066],
          [48.440843, 37.211677],
          [48.446677, 37.209176],
          [48.4489, 37.209176],
          [48.449177, 37.208897],
          [48.449734, 37.208897],
          [48.449733, 37.208618],
          [48.451953, 37.208348],
          [48.454177, 37.210013],
          [48.45501, 37.210013],
          [48.455287, 37.209734],
          [48.455843, 37.209734],
          [48.456677, 37.209455],
          [48.458067, 37.209176],
          [48.459733, 37.208348],
          [48.465843, 37.212514],
          [48.466957, 37.211677],
          [48.468066, 37.211677],
          [48.474176, 37.210292],
          [48.478066, 37.206953],
          [48.478066, 37.206404],
          [48.47751, 37.205567],
          [48.477233, 37.203624],
          [48.479176, 37.20168],
          [48.479733, 37.201401],
          [48.483623, 37.200006],
          [48.4839, 37.199457],
          [48.487513, 37.19584],
          [48.487789, 37.19557],
          [48.487513, 37.194454],
          [48.478343, 37.180839],
          [48.478623, 37.180569],
          [48.479733, 37.18029],
          [48.48029, 37.180569],
          [48.483623, 37.178896],
          [48.484456, 37.178626],
          [48.485013, 37.178626],
          [48.48529, 37.178347],
          [48.487233, 37.178347],
          [48.488067, 37.178068],
          [48.491956, 37.178068],
          [48.494456, 37.176403],
          [48.49418, 37.175845],
          [48.489456, 37.172227],
          [48.490566, 37.170563],
          [48.491123, 37.170563],
          [48.4914, 37.170284],
          [48.49279, 37.169735],
          [48.493899, 37.169735],
          [48.493899, 37.169456],
          [48.495846, 37.167233],
          [48.495013, 37.162788],
          [48.49529, 37.160286],
          [48.495566, 37.159449],
          [48.495846, 37.1589],
          [48.496123, 37.157784],
          [48.4964, 37.157514],
          [48.507513, 37.141676],
          [48.507513, 37.141118],
          [48.50918, 37.138346],
          [48.513623, 37.136123],
          [48.51529, 37.136123],
          [48.517789, 37.138067],
          [48.520846, 37.138067],
          [48.521679, 37.137788],
          [48.522793, 37.137788],
          [48.52446, 37.13723],
          [48.528626, 37.135286],
          [48.529459, 37.135286],
          [48.531679, 37.136402],
          [48.533069, 37.136402],
          [48.533346, 37.136123],
          [48.534179, 37.136123],
          [48.536679, 37.135007],
          [48.540849, 37.135007],
          [48.541403, 37.135286],
          [48.546403, 37.135286],
          [48.548902, 37.132505],
          [48.548349, 37.129176],
          [48.547793, 37.12806],
          [48.546959, 37.123065],
          [48.547236, 37.122507],
          [48.547236, 37.121958],
          [48.547516, 37.121121],
          [48.548902, 37.119726],
          [48.549736, 37.119456],
          [48.555849, 37.119726],
          [48.556126, 37.119456],
          [48.556683, 37.119456],
          [48.559739, 37.11834],
          [48.561682, 37.11834],
          [48.563626, 37.119177],
          [48.565292, 37.120284],
          [48.566682, 37.120284],
          [48.566682, 37.120005],
          [48.568716, 37.119243],
          [48.569459, 37.118898],
          [48.570572, 37.117512],
          [48.570292, 37.113615],
          [48.570572, 37.112229],
          [48.570849, 37.111393],
          [48.571126, 37.109728],
          [48.572239, 37.107235],
          [48.572516, 37.106956],
          [48.572792, 37.106677],
          [48.573349, 37.106677],
          [48.573349, 37.106398],
          [48.574459, 37.106398],
          [48.575016, 37.106119],
          [48.576126, 37.105012],
          [48.577516, 37.103059],
          [48.578072, 37.103059],
          [48.587238, 37.109452],
          [48.587515, 37.109728],
          [48.588629, 37.110286],
          [48.609738, 37.120005],
          [48.620018, 37.123623],
          [48.626128, 37.12473],
          [48.627795, 37.12473],
          [48.633908, 37.125846],
          [48.636408, 37.126116],
          [48.637241, 37.126116],
          [48.638352, 37.126395],
          [48.639741, 37.126395],
          [48.640298, 37.126674],
          [48.643075, 37.126674],
          [48.643908, 37.126953],
          [48.646685, 37.126953],
          [48.647241, 37.127232],
          [48.648075, 37.127232],
          [48.654741, 37.129176],
          [48.655855, 37.129176],
          [48.655855, 37.129455],
          [48.665298, 37.135844],
          [48.683631, 37.152232],
          [48.694744, 37.159449],
          [48.695854, 37.160007],
          [48.726226, 37.17964],
          [48.726967, 37.180012],
          [48.7278, 37.180291],
          [48.733634, 37.184178],
          [48.737524, 37.185564],
          [48.73919, 37.185564],
          [48.739467, 37.185843],
          [48.74308, 37.185843],
          [48.743633, 37.186122],
          [48.744467, 37.186122],
          [48.745024, 37.185843],
          [48.746133, 37.185843],
          [48.751137, 37.182793],
          [48.751137, 37.182514],
          [48.75169, 37.181119],
          [48.75197, 37.18084],
          [48.75197, 37.180291],
          [48.75197, 37.179454],
          [48.752247, 37.169456],
          [48.753913, 37.166676],
          [48.755304, 37.166676],
          [48.755304, 37.166397],
          [48.757247, 37.165839],
          [48.758913, 37.164732],
          [48.764747, 37.156678],
          [48.76808, 37.150288],
          [48.776413, 37.14695],
          [48.77697, 37.14668],
          [48.78336, 37.14362],
          [48.786693, 37.14029],
          [48.78725, 37.14029],
          [48.787527, 37.140011],
          [48.78836, 37.140011],
          [48.788636, 37.139732],
          [48.790026, 37.139174],
          [48.793916, 37.139174],
          [48.794193, 37.139453],
          [48.80197, 37.141955],
          [48.812806, 37.153348],
          [48.815306, 37.154176],
          [48.816416, 37.154176],
          [48.819193, 37.154176],
          [48.81975, 37.153897],
          [48.821693, 37.153897],
          [48.822249, 37.153618],
          [48.826696, 37.15279],
          [48.831973, 37.15279],
          [48.832806, 37.153069],
          [48.838639, 37.153069],
          [48.839473, 37.15279],
          [48.841416, 37.15279],
          [48.841972, 37.152511],
          [48.842806, 37.152511],
          [48.843083, 37.152232],
          [48.843639, 37.152232],
          [48.855029, 37.148066],
          [48.856139, 37.148066],
          [48.856419, 37.147787],
          [48.858086, 37.147787],
          [48.858639, 37.148066],
          [48.858919, 37.147787],
          [48.860862, 37.147787],
          [48.860862, 37.147508],
          [48.861419, 37.147508],
          [48.862253, 37.147508],
          [48.863919, 37.147508],
          [48.869752, 37.146401],
          [48.871696, 37.151396],
          [48.878086, 37.156957],
          [48.878642, 37.157236],
          [48.893918, 37.168062],
          [48.904475, 37.175846],
          [48.905589, 37.175846],
          [48.906422, 37.176125],
          [48.907532, 37.176125],
          [48.908365, 37.176404],
          [48.911975, 37.176674],
          [48.912808, 37.176404],
          [48.913922, 37.176674],
          [48.921422, 37.176404],
          [48.922255, 37.176674],
          [48.921422, 37.180012],
          [48.921145, 37.180291],
          [48.920865, 37.181677],
          [48.920588, 37.183342],
          [48.920312, 37.185286],
          [48.901422, 37.215008],
          [48.899475, 37.218346],
          [48.895309, 37.225562],
          [48.895308, 37.226399],
          [48.895032, 37.228622],
          [48.895032, 37.234453],
          [48.895308, 37.236126],
          [48.895585, 37.236954],
          [48.895308, 37.23807],
          [48.895865, 37.246402],
          [48.898085, 37.251405],
          [48.898365, 37.253348],
          [48.898642, 37.253627],
          [48.898365, 37.254455],
          [48.898642, 37.255571],
          [48.898365, 37.256399],
          [48.905865, 37.280845],
          [48.906141, 37.281403],
          [48.906975, 37.28529],
          [48.910865, 37.298345],
          [48.910308, 37.301125],
          [48.910588, 37.303626],
          [48.904755, 37.314459],
          [48.886975, 37.329186],
          [48.883365, 37.331957],
          [48.861695, 37.346127],
          [48.860775, 37.346743],
          [48.839749, 37.35946],
          [48.838082, 37.360296],
          [48.837249, 37.361124],
          [48.835582, 37.361961],
          [48.813916, 37.380574],
          [48.809749, 37.383902],
          [48.800302, 37.391684],
          [48.800025, 37.391963],
          [48.773913, 37.416962],
          [48.756413, 37.430014],
          [48.741413, 37.445018],
          [48.735023, 37.453627],
          [48.731966, 37.458349],
          [48.715299, 37.474189],
          [48.69752, 37.501964],
          [48.696686, 37.503628],
          [48.695853, 37.504465],
          [48.694466, 37.507244],
          [48.694186, 37.508071],
          [48.69252, 37.515024],
          [48.692243, 37.516409],
          [48.689186, 37.538354],
          [48.68891, 37.540018],
          [48.688076, 37.545298],
          [48.686687, 37.549183],
          [48.685853, 37.550577],
          [48.685576, 37.551962],
          [48.678077, 37.561963],
          [48.675853, 37.567521],
          [48.67613, 37.569185],
          [48.677796, 37.570857],
          [48.678077, 37.571406],
          [48.679186, 37.571685],
          [48.679186, 37.571964],
          [48.68002, 37.571964],
          [48.681963, 37.5728],
          [48.68363, 37.582522],
          [48.68002, 37.592522],
          [48.68002, 37.592801],
          [48.677796, 37.595859],
          [48.676129, 37.597243],
          [48.63863, 37.619744],
          [48.637797, 37.62058],
          [48.636963, 37.620859],
          [48.625294, 37.63308],
          [48.616407, 37.646415],
          [48.615017, 37.648079],
          [48.61446, 37.649194],
          [48.613903, 37.649742],
          [48.612237, 37.651972],
          [48.609737, 37.659472],
          [48.609737, 37.662799],
          [48.610017, 37.671413],
          [48.609737, 37.671692],
          [48.609737, 37.672241],
          [48.609737, 37.672519],
          [48.60696, 37.678355],
          [48.60585, 37.680028],
          [48.599737, 37.685306],
          [48.59668, 37.688075],
          [48.595847, 37.690584],
          [48.596127, 37.690854],
          [48.595847, 37.691411],
          [48.597513, 37.694468],
          [48.599184, 37.698353],
          [48.59835, 37.699468],
          [48.59835, 37.699746],
          [48.59807, 37.700583],
          [48.59807, 37.701419],
          [48.599184, 37.703082],
          [48.604736, 37.705582],
          [48.60557, 37.705582],
          [48.60696, 37.704746],
          [48.60835, 37.704746],
          [48.61085, 37.705582],
          [48.633906, 37.717522],
          [48.636406, 37.718359],
          [48.636683, 37.718637],
          [48.639463, 37.721694],
          [48.641406, 37.725021],
          [48.643629, 37.753082],
          [48.643906, 37.756417],
          [48.644183, 37.758081],
          [48.646683, 37.764194],
          [48.647124, 37.76478],
          [48.648906, 37.768078],
          [48.653073, 37.773913],
          [48.654183, 37.778912],
          [48.653906, 37.780026],
          [48.651963, 37.782526],
          [48.65113, 37.783083],
          [48.648073, 37.784468],
          [48.647239, 37.784468],
          [48.647239, 37.784746],
          [48.645296, 37.784746],
          [48.64474, 37.785025],
          [48.643073, 37.785025],
          [48.642516, 37.785304],
          [48.641129, 37.785304],
          [48.640016, 37.785583],
          [48.637796, 37.785582],
          [48.635849, 37.78614],
          [48.635573, 37.786419],
          [48.63474, 37.786419],
          [48.63224, 37.788639],
          [48.631683, 37.790302],
          [48.628626, 37.791974],
          [48.626406, 37.792244],
          [48.62446, 37.792802],
          [48.62224, 37.792802],
          [48.616406, 37.790581],
          [48.61446, 37.790024],
          [48.613903, 37.790024],
          [48.612517, 37.789745],
          [48.60835, 37.79086],
          [48.607236, 37.79086],
          [48.60696, 37.791138],
          [48.604736, 37.791138],
          [48.602237, 37.792802],
          [48.59196, 37.803077],
          [48.586127, 37.820032],
          [48.586127, 37.823637],
          [48.585847, 37.823916],
          [48.585293, 37.828635],
          [48.585013, 37.833364],
          [48.585013, 37.835305],
          [48.584737, 37.836141],
          [48.58418, 37.838919],
          [48.58418, 37.840025],
          [48.583347, 37.842524],
          [48.583231, 37.844384],
          [48.576956, 37.863918],
          [48.576957, 37.864754],
          [48.576403, 37.865581],
          [48.570847, 37.891694],
          [48.57057, 37.894471],
          [48.570846, 37.895029],
          [48.57057, 37.896422],
          [48.570846, 37.901698],
          [48.570846, 37.901977],
          [48.57418, 37.917527],
          [48.57529, 37.920583],
          [48.58418, 37.931971],
          [48.584736, 37.932528],
          [48.594736, 37.942253],
          [48.600849, 37.954476],
          [48.601402, 37.960588],
          [48.601126, 37.961424],
          [48.600849, 37.962808],
          [48.600293, 37.964749],
          [48.598903, 37.969755],
          [48.598626, 37.970034],
          [48.598903, 37.97531],
          [48.600016, 37.978087],
          [48.600228, 37.9783],
          [48.607516, 37.986418],
          [48.610569, 37.990588],
          [48.611682, 37.99642],
          [48.611959, 37.997256],
          [48.612516, 38.000033],
          [48.619739, 38.01142],
          [48.620572, 38.01337],
          [48.620849, 38.017809],
          [48.618072, 38.023363],
          [48.617792, 38.023642],
          [48.617516, 38.02392],
          [48.617239, 38.024477],
          [48.617239, 38.024756],
          [48.616959, 38.025034],
          [48.616959, 38.025583],
          [48.616406, 38.028647],
          [48.616406, 38.033922],
          [48.617239, 38.03864],
          [48.619459, 38.044203],
          [48.623905, 38.063092],
          [48.624182, 38.064754],
          [48.624738, 38.066704],
          [48.624739, 38.067252],
          [48.625292, 38.068088],
          [48.625292, 38.068366],
          [48.625572, 38.068645],
          [48.626125, 38.070038],
          [48.633625, 38.087812],
          [48.634459, 38.098648],
          [48.633072, 38.103644],
          [48.632792, 38.104758],
          [48.633072, 38.110311],
          [48.633348, 38.111147],
          [48.633072, 38.111982],
          [48.633072, 38.113088],
          [48.635848, 38.118371],
          [48.658628, 38.133924],
          [48.670295, 38.138371],
          [48.671128, 38.138371],
          [48.672795, 38.139198],
          [48.678908, 38.143924],
          [48.680851, 38.147257],
          [48.683074, 38.152262],
          [48.683628, 38.156143],
          [48.683351, 38.1567],
          [48.681685, 38.16059],
          [48.680575, 38.161905],
          [48.680018, 38.163088],
          [48.679461, 38.163645],
          [48.679184, 38.164481],
          [48.676961, 38.166152],
          [48.676408, 38.166152],
          [48.676128, 38.16643],
          [48.673351, 38.167814],
          [48.669461, 38.171983],
          [48.669185, 38.172818],
          [48.668628, 38.173097],
          [48.666961, 38.177256],
          [48.666685, 38.178649],
          [48.668351, 38.193922],
          [48.669461, 38.197264],
          [48.671404, 38.201423],
          [48.678908, 38.210039],
          [48.686408, 38.214764],
          [48.689461, 38.218097],
          [48.690018, 38.222256],
          [48.679741, 38.233647],
          [48.677518, 38.23698],
          [48.673908, 38.241148],
          [48.673908, 38.241984],
          [48.673074, 38.243924],
          [48.672794, 38.244202],
          [48.676408, 38.25226],
          [48.676684, 38.254757],
          [48.676408, 38.255314],
          [48.676127, 38.257821],
          [48.675294, 38.259761],
          [48.671404, 38.264764],
          [48.668628, 38.27143],
          [48.668628, 38.276702],
          [48.669184, 38.280313],
          [48.671684, 38.289763],
          [48.669461, 38.291981],
          [48.668627, 38.291981],
          [48.668071, 38.29226],
          [48.661128, 38.29226],
          [48.656684, 38.29032],
          [48.655018, 38.289763],
          [48.652794, 38.289206],
          [48.651961, 38.289206],
          [48.651405, 38.288927],
          [48.647238, 38.288927],
          [48.646681, 38.289206],
          [48.644738, 38.289206],
          [48.644181, 38.289484],
          [48.643071, 38.289484],
          [48.639181, 38.290877],
          [48.626405, 38.300874],
          [48.619181, 38.315317],
          [48.612235, 38.321434],
          [48.583068, 38.348926],
          [48.576678, 38.356426],
          [48.574455, 38.360593],
          [48.574455, 38.36088],
          [48.573902, 38.361707],
          [48.570845, 38.374765],
          [48.570845, 38.382264],
          [48.573902, 38.391712],
          [48.573345, 38.393652],
          [48.570568, 38.405874],
          [48.570288, 38.407266],
          [48.569455, 38.412547],
          [48.569178, 38.414208],
          [48.570012, 38.419488],
          [48.572512, 38.426708],
          [48.573068, 38.436434],
          [48.573621, 38.438373],
          [48.581958, 38.44421],
          [48.582234, 38.444767],
          [48.582791, 38.445045],
          [48.580724, 38.453987],
          [48.580595, 38.454811],
          [48.580906, 38.457999],
          [48.581314, 38.45952],
          [48.581826, 38.460338],
          [48.58191, 38.460805],
          [48.581521, 38.462774],
          [48.578853, 38.463734],
          [48.579433, 38.466817],
          [48.573725, 38.468723],
          [48.574343, 38.470934],
          [48.566362, 38.48001],
          [48.563222, 38.47965],
          [48.557139, 38.481169],
          [48.556716, 38.484149],
          [48.552933, 38.485971],
          [48.553047, 38.488658],
          [48.549694, 38.490723],
          [48.551137, 38.492791],
          [48.550737, 38.494092],
          [48.543336, 38.495848],
          [48.537639, 38.503451],
          [48.53233, 38.504435],
          [48.531068, 38.505909],
          [48.526674, 38.514254],
          [48.521925, 38.515759],
          [48.517609, 38.515437],
          [48.516738, 38.517483],
          [48.513304, 38.518379],
          [48.51396, 38.522308],
          [48.509365, 38.525671],
          [48.508275, 38.527929],
          [48.507789, 38.539505],
          [48.505328, 38.542671],
          [48.505388, 38.544125],
          [48.505508, 38.547],
          [48.495029, 38.553272],
          [48.48873, 38.552604],
          [48.483957, 38.554001],
          [48.476888, 38.551579],
          [48.472204, 38.553533],
          [48.470669, 38.556424],
          [48.468563, 38.557199],
          [48.468109, 38.561628],
          [48.467218, 38.562213],
          [48.464684, 38.561321],
          [48.461902, 38.5653],
          [48.462152, 38.57009],
          [48.459236, 38.577547],
          [48.454516, 38.581149],
          [48.454134, 38.583182],
          [48.456765, 38.585958],
          [48.454199, 38.587201],
          [48.453529, 38.589766],
          [48.458611, 38.598043],
          [48.455962, 38.604167],
          [48.459293, 38.607688],
          [48.454139, 38.609514],
          [48.451481, 38.610455],
          [48.447456, 38.610033],
          [48.444505, 38.612043],
          [48.441316, 38.612722],
          [48.435858, 38.611757],
          [48.431484, 38.613568],
          [48.42511, 38.613288],
          [48.421469, 38.610113],
          [48.417956, 38.609035],
          [48.416131, 38.605924],
          [48.409469, 38.604265],
          [48.407942, 38.602312],
          [48.390359, 38.602443],
          [48.388407, 38.605046],
          [48.385652, 38.605145],
          [48.381823, 38.603645],
          [48.380083, 38.601815],
          [48.375537, 38.600876],
          [48.374225, 38.600289],
          [48.373674, 38.598411],
          [48.366995, 38.598154],
          [48.361489, 38.594854],
          [48.356315, 38.597788],
          [48.350715, 38.595132],
          [48.344011, 38.599032],
          [48.342042, 38.599398],
          [48.341553, 38.599583],
          [48.340923, 38.599963],
          [48.339938, 38.600538],
          [48.339394, 38.600913],
          [48.33909, 38.601153],
          [48.338915, 38.601248],
          [48.33873, 38.601235],
          [48.338492, 38.601171],
          [48.338208, 38.600967],
          [48.337995, 38.600687],
          [48.337621, 38.6003],
          [48.336941, 38.599895],
          [48.335694, 38.599678],
          [48.333412, 38.599976],
          [48.332141, 38.601042],
          [48.329942, 38.602888],
          [48.32983, 38.603105],
          [48.329807, 38.60326],
          [48.329824, 38.603818],
          [48.329767, 38.603856],
          [48.329672, 38.603885],
          [48.328932, 38.603936],
          [48.328722, 38.603958],
          [48.328399, 38.604171],
          [48.328204, 38.604264],
          [48.327927, 38.604305],
          [48.326966, 38.604277],
          [48.326388, 38.60421],
          [48.325835, 38.604246],
          [48.321175, 38.602018],
          [48.310223, 38.607654],
          [48.308366, 38.6112],
          [48.306132, 38.612589],
          [48.307236, 38.620622],
          [48.303622, 38.622265],
          [48.302758, 38.625634],
          [48.300278, 38.629444],
          [48.296569, 38.631373],
          [48.296944, 38.633611],
          [48.293611, 38.637778],
          [48.29583, 38.642036],
          [48.295244, 38.645524],
          [48.294942, 38.647319],
          [48.285465, 38.649176],
          [48.283377, 38.65241],
          [48.278482, 38.65212],
          [48.275468, 38.654651],
          [48.268188, 38.657183],
          [48.258234, 38.657627],
          [48.252657, 38.661908],
          [48.253058, 38.682584],
          [48.251502, 38.684769],
          [48.250381, 38.685515],
          [48.249883, 38.686393],
          [48.247973, 38.689197],
          [48.248041, 38.691777],
          [48.25226, 38.696567],
          [48.2583, 38.698649],
          [48.259391, 38.708509],
          [48.253918, 38.713578],
          [48.249589, 38.716896],
          [48.246885, 38.721731],
          [48.249156, 38.726096],
          [48.243105, 38.728877],
          [48.242467, 38.731283],
          [48.235712, 38.732049],
          [48.232542, 38.73241],
          [48.223707, 38.73469],
          [48.220501, 38.737869],
          [48.21736, 38.736421],
          [48.206472, 38.739881],
          [48.202376, 38.743763],
          [48.200201, 38.744925],
          [48.200053, 38.746238],
          [48.199753, 38.748906],
          [48.190467, 38.751788],
          [48.191814, 38.756215],
          [48.172675, 38.761737],
          [48.163117, 38.759705],
          [48.148213, 38.762796],
          [48.14472, 38.766806],
          [48.136045, 38.76724],
          [48.132941, 38.769982],
          [48.129833, 38.768437],
          [48.121384, 38.769791],
          [48.12042, 38.771056],
          [48.115758, 38.770919],
          [48.111589, 38.769876],
          [48.10731, 38.769881],
          [48.1056, 38.773036],
          [48.104117, 38.774158],
          [48.102775, 38.775956],
          [48.101171, 38.776899],
          [48.102222, 38.779522],
          [48.103859, 38.780509],
          [48.10473, 38.7829],
          [48.107077, 38.786664],
          [48.107108, 38.788558],
          [48.09319, 38.789038],
          [48.088291, 38.793984],
          [48.078382, 38.793898],
          [48.079718, 38.800927],
          [48.068472, 38.803183],
          [48.061123, 38.808649],
          [48.048876, 38.811859],
          [48.044867, 38.816371],
          [48.040859, 38.815503],
          [48.035961, 38.824387],
          [48.034789, 38.830722],
          [48.020703, 38.83789],
          [48.012956, 38.88093],
          [48.011833, 38.890923],
          [48.010775, 38.900469],
          [48.01027, 38.907306],
          [48.019223, 38.912026],
          [48.022641, 38.909826],
          [48.028208, 38.899428],
          [48.038027, 38.9008],
          [48.049817, 38.904998],
          [48.05409, 38.908744],
          [48.062931, 38.910569],
          [48.069265, 38.910937],
          [48.079551, 38.917299],
          [48.078535, 38.920742],
          [48.079983, 38.924009],
          [48.078312, 38.927238],
          [48.079694, 38.930233],
          [48.082466, 38.933859],
          [48.081572, 38.937285],
          [48.084849, 38.939254],
          [48.087192, 38.944581],
          [48.088235, 38.946951],
          [48.107305, 38.943156],
          [48.111394, 38.946159],
          [48.122754, 38.946954],
          [48.124736, 38.94668],
          [48.127939, 38.945918],
          [48.129356, 38.946455],
          [48.131327, 38.946925],
          [48.134751, 38.948075],
          [48.138089, 38.947916],
          [48.139529, 38.946965],
          [48.142692, 38.946339],
          [48.143128, 38.946278],
          [48.143675, 38.946255],
          [48.144194, 38.946193],
          [48.144911, 38.945879],
          [48.14568, 38.945872],
          [48.147109, 38.94616],
          [48.149654, 38.947936],
          [48.151972, 38.949207],
          [48.152847, 38.951814],
          [48.152481, 38.952757],
          [48.154812, 38.953058],
          [48.156542, 38.954041],
          [48.158781, 38.953806],
          [48.159215, 38.953275],
          [48.16113, 38.952971],
          [48.163217, 38.9535],
          [48.165134, 38.954866],
          [48.168212, 38.955142],
          [48.171063, 38.955725],
          [48.173792, 38.955175],
          [48.176784, 38.954673],
          [48.178137, 38.955213],
          [48.179459, 38.956019],
          [48.182562, 38.955755],
          [48.185177, 38.956211],
          [48.190793, 38.956043],
          [48.206401, 38.953288],
          [48.208687, 38.954017],
          [48.210909, 38.955647],
          [48.212818, 38.955579],
          [48.217685, 38.955405],
          [48.229415, 38.95977],
          [48.232751, 38.959776],
          [48.235745, 38.961],
          [48.236955, 38.963775],
          [48.239557, 38.964973],
          [48.253291, 38.964558],
          [48.258565, 38.966726],
          [48.270753, 38.967302],
          [48.2773, 38.97258],
          [48.285308, 38.975547],
          [48.288828, 38.977536],
          [48.292591, 38.979663],
          [48.293624, 38.981529],
          [48.296206, 38.983356],
          [48.297383, 38.982947],
          [48.298423, 38.983681],
          [48.298277, 38.986048],
          [48.307795, 38.996159],
          [48.310605, 38.998282],
          [48.316722, 39.001941],
          [48.317124, 39.005237],
          [48.326223, 39.011399],
          [48.329097, 39.015785],
          [48.333223, 39.021166],
          [48.335266, 39.024919],
          [48.337463, 39.029259],
          [48.337049, 39.031956],
          [48.339235, 39.034108],
          [48.337925, 39.03737],
          [48.335155, 39.039354],
          [48.33444, 39.043653],
          [48.332753, 39.046577],
          [48.332429, 39.05095],
          [48.331105, 39.051451],
          [48.33097, 39.053111],
          [48.327953, 39.05243],
          [48.326489, 39.052685],
          [48.325876, 39.053492],
          [48.326622, 39.05578],
          [48.32737, 39.055817],
          [48.327689, 39.056661],
          [48.326663, 39.056883],
          [48.326747, 39.05729],
          [48.326467, 39.05772],
          [48.325226, 39.057184],
          [48.324057, 39.05733],
          [48.322984, 39.060188],
          [48.324773, 39.060083],
          [48.322586, 39.067492],
          [48.321306, 39.067065],
          [48.319455, 39.068136],
          [48.320097, 39.069395],
          [48.318652, 39.070997],
          [48.319304, 39.07203],
          [48.319245, 39.072225],
          [48.318294, 39.075311],
          [48.31678, 39.076419],
          [48.315412, 39.078507],
          [48.318087, 39.079135],
          [48.316639, 39.082565],
          [48.316745, 39.085132],
          [48.315465, 39.086208],
          [48.313598, 39.088319],
          [48.310985, 39.087367],
          [48.309811, 39.088568],
          [48.310878, 39.090141],
          [48.310291, 39.091176],
          [48.308291, 39.090304],
          [48.304851, 39.091135],
          [48.304589, 39.094138],
          [48.303304, 39.095895],
          [48.306078, 39.095854],
          [48.305971, 39.097178],
          [48.304211, 39.097923],
          [48.304522, 39.09954],
          [48.307411, 39.10198],
          [48.304851, 39.102849],
          [48.306676, 39.103985],
          [48.307407, 39.104364],
          [48.307656, 39.104326],
          [48.307829, 39.104339],
          [48.307938, 39.104416],
          [48.307931, 39.104609],
          [48.307836, 39.104931],
          [48.307742, 39.104964],
          [48.307271, 39.104969],
          [48.306766, 39.105175],
          [48.306358, 39.104996],
          [48.306144, 39.105001],
          [48.305758, 39.105374],
          [48.305706, 39.10558],
          [48.305954, 39.105729],
          [48.30615, 39.105843],
          [48.306091, 39.106043],
          [48.306133, 39.106283],
          [48.306682, 39.106496],
          [48.307193, 39.10662],
          [48.307516, 39.106853],
          [48.308048, 39.106789],
          [48.308269, 39.106835],
          [48.308332, 39.106954],
          [48.308354, 39.107262],
          [48.308256, 39.10748],
          [48.308067, 39.10748],
          [48.307891, 39.107687],
          [48.307867, 39.108198],
          [48.307793, 39.108527],
          [48.307597, 39.108639],
          [48.307317, 39.108603],
          [48.306893, 39.108542],
          [48.306697, 39.108264],
          [48.306391, 39.107895],
          [48.306104, 39.107763],
          [48.305823, 39.107839],
          [48.305641, 39.108011],
          [48.305732, 39.108488],
          [48.305621, 39.108699],
          [48.305119, 39.108937],
          [48.304675, 39.109074],
          [48.302883, 39.108973],
          [48.30283, 39.109287],
          [48.302974, 39.110405],
          [48.302928, 39.11083],
          [48.302674, 39.110992],
          [48.302283, 39.111048],
          [48.299566, 39.111503],
          [48.299197, 39.1139],
          [48.296544, 39.113452],
          [48.289264, 39.116915],
          [48.285863, 39.114769],
          [48.284156, 39.115555],
          [48.286023, 39.117541],
          [48.28307, 39.119575],
          [48.281276, 39.119238],
          [48.279302, 39.120355],
          [48.279996, 39.12259],
          [48.275819, 39.123428],
          [48.274608, 39.122135],
          [48.272262, 39.123004],
          [48.272899, 39.124674],
          [48.267728, 39.124038],
          [48.266415, 39.125987],
          [48.265061, 39.124038],
          [48.260687, 39.123293],
          [48.259461, 39.125279],
          [48.258714, 39.123831],
          [48.256804, 39.123764],
          [48.256506, 39.125655],
          [48.250873, 39.125652],
          [48.247246, 39.12859],
          [48.243961, 39.12757],
          [48.242499, 39.129914],
          [48.238766, 39.130245],
          [48.234979, 39.129665],
          [48.234137, 39.130614],
          [48.235885, 39.131527],
          [48.233912, 39.133596],
          [48.230659, 39.133771],
          [48.228922, 39.138062],
          [48.229218, 39.141373],
          [48.225857, 39.142376],
          [48.225004, 39.145014],
          [48.227082, 39.14705],
          [48.221004, 39.148534],
          [48.220999, 39.151247],
          [48.218497, 39.153576],
          [48.21647, 39.160111],
          [48.218415, 39.162744],
          [48.213838, 39.163202],
          [48.213492, 39.166707],
          [48.210017, 39.167224],
          [48.203616, 39.172351],
          [48.203385, 39.174635],
          [48.198771, 39.175866],
          [48.194282, 39.174832],
          [48.193162, 39.176528],
          [48.190068, 39.176238],
          [48.190691, 39.178462],
          [48.186068, 39.180042],
          [48.185491, 39.181801],
          [48.181451, 39.182541],
          [48.17787, 39.184099],
          [48.175667, 39.185623],
          [48.174916, 39.187711],
          [48.172156, 39.18817],
          [48.170309, 39.187312],
          [48.169931, 39.188512],
          [48.171642, 39.189756],
          [48.168959, 39.192089],
          [48.167324, 39.191169],
          [48.166568, 39.188941],
          [48.165109, 39.190604],
          [48.162838, 39.189284],
          [48.159987, 39.192941],
          [48.161871, 39.194028],
          [48.158154, 39.194847],
          [48.160856, 39.196939],
          [48.156879, 39.198597],
          [48.153799, 39.197694],
          [48.150283, 39.198255],
          [48.148611, 39.200065],
          [48.149856, 39.20099],
          [48.14609, 39.203056],
          [48.146596, 39.204069],
          [48.144812, 39.203995],
          [48.143482, 39.205291],
          [48.144866, 39.205886],
          [48.144455, 39.20664],
          [48.142173, 39.206581],
          [48.142152, 39.208095],
          [48.14085, 39.208201],
          [48.140875, 39.208413],
          [48.141211, 39.211158],
          [48.143934, 39.211654],
          [48.144581, 39.213096],
          [48.142306, 39.214807],
          [48.137082, 39.215515],
          [48.137171, 39.216668],
          [48.135778, 39.217967],
          [48.138521, 39.218828],
          [48.138768, 39.220549],
          [48.140625, 39.220964],
          [48.140943, 39.221991],
          [48.140961, 39.222243],
          [48.140736, 39.222393],
          [48.140586, 39.22266],
          [48.140666, 39.222748],
          [48.140776, 39.222786],
          [48.141278, 39.222915],
          [48.141503, 39.223062],
          [48.141534, 39.223223],
          [48.141529, 39.223516],
          [48.1416, 39.223752],
          [48.141882, 39.223929],
          [48.141987, 39.224161],
          [48.141987, 39.224364],
          [48.141798, 39.224588],
          [48.141538, 39.224752],
          [48.141296, 39.224724],
          [48.14097, 39.224537],
          [48.140415, 39.224267],
          [48.139504, 39.225038],
          [48.137671, 39.228132],
          [48.135752, 39.227261],
          [48.134553, 39.228702],
          [48.135688, 39.229857],
          [48.133023, 39.229951],
          [48.133686, 39.23332],
          [48.135948, 39.23408],
          [48.134873, 39.235012],
          [48.133782, 39.235419],
          [48.132987, 39.235385],
          [48.132674, 39.235409],
          [48.132986, 39.236248],
          [48.134424, 39.237441],
          [48.134581, 39.238161],
          [48.13358, 39.239227],
          [48.131296, 39.238489],
          [48.12998, 39.241136],
          [48.132791, 39.242449],
          [48.130342, 39.243938],
          [48.129471, 39.246393],
          [48.132981, 39.246997],
          [48.13262, 39.248364],
          [48.13006, 39.248344],
          [48.130588, 39.249349],
          [48.132705, 39.252538],
          [48.134507, 39.25282],
          [48.133519, 39.25431],
          [48.134959, 39.256541],
          [48.135997, 39.256922],
          [48.136681, 39.260298],
          [48.13472, 39.261321],
          [48.131531, 39.262004],
          [48.133264, 39.263902],
          [48.133566, 39.264342],
          [48.133338, 39.264841],
          [48.132893, 39.264996],
          [48.132302, 39.264996],
          [48.131711, 39.264794],
          [48.130483, 39.26464],
          [48.129801, 39.264978],
          [48.130802, 39.266907],
          [48.130458, 39.269004],
          [48.131723, 39.269099],
          [48.132771, 39.269927],
          [48.132156, 39.2708],
          [48.130196, 39.271026],
          [48.129773, 39.271376],
          [48.129969, 39.27162],
          [48.130672, 39.271724],
          [48.13121, 39.272583],
          [48.130803, 39.273709],
          [48.131574, 39.273713],
          [48.132919, 39.272746],
          [48.133589, 39.271951],
          [48.13594, 39.271468],
          [48.136412, 39.271571],
          [48.136613, 39.273071],
          [48.136939, 39.273497],
          [48.137395, 39.273138],
          [48.13769, 39.273232],
          [48.138353, 39.274238],
          [48.139324, 39.274541],
          [48.139969, 39.274422],
          [48.141475, 39.275449],
          [48.141001, 39.276201],
          [48.140818, 39.277122],
          [48.14155, 39.277668],
          [48.142283, 39.277932],
          [48.143714, 39.276564],
          [48.14526, 39.276581],
          [48.146244, 39.276313],
          [48.146988, 39.276706],
          [48.147167, 39.277921],
          [48.146346, 39.2787],
          [48.145685, 39.278399],
          [48.145077, 39.278727],
          [48.14254, 39.2788],
          [48.142634, 39.280045],
          [48.141675, 39.2807],
          [48.142047, 39.281573],
          [48.142858, 39.28189],
          [48.143606, 39.281766],
          [48.143749, 39.28317],
          [48.144248, 39.28385],
          [48.146455, 39.282761],
          [48.146634, 39.283162],
          [48.147405, 39.283001],
          [48.147917, 39.282013],
          [48.148518, 39.281959],
          [48.14911, 39.282151],
          [48.149533, 39.282351],
          [48.150261, 39.282062],
          [48.151291, 39.282406],
          [48.152159, 39.282981],
          [48.151966, 39.283841],
          [48.151304, 39.28402],
          [48.150653, 39.284854],
          [48.150699, 39.285254],
          [48.150926, 39.28543],
          [48.150924, 39.286114],
          [48.150464, 39.286728],
          [48.150731, 39.287037],
          [48.150904, 39.287465],
          [48.151732, 39.287494],
          [48.152163, 39.287156],
          [48.152601, 39.287081],
          [48.152858, 39.287287],
          [48.153076, 39.28769],
          [48.153904, 39.287916],
          [48.153998, 39.288195],
          [48.153775, 39.288331],
          [48.153791, 39.288697],
          [48.15398, 39.288887],
          [48.154242, 39.288923],
          [48.154074, 39.289455],
          [48.154015, 39.289791],
          [48.153649, 39.289837],
          [48.153311, 39.289731],
          [48.152755, 39.28925],
          [48.152137, 39.289595],
          [48.151838, 39.290011],
          [48.152075, 39.290702],
          [48.152433, 39.29096],
          [48.152864, 39.291161],
          [48.153607, 39.292018],
          [48.153544, 39.29224],
          [48.153155, 39.292489],
          [48.153421, 39.293152],
          [48.153917, 39.29343],
          [48.154243, 39.293745],
          [48.154617, 39.294609],
          [48.155792, 39.295171],
          [48.157243, 39.295271],
          [48.157853, 39.294866],
          [48.158591, 39.294728],
          [48.159274, 39.294973],
          [48.159767, 39.295383],
          [48.159056, 39.296121],
          [48.159499, 39.29619],
          [48.159792, 39.29612],
          [48.160259, 39.295794],
          [48.161019, 39.295499],
          [48.161447, 39.2953],
          [48.161678, 39.295623],
          [48.161571, 39.29603],
          [48.160955, 39.296534],
          [48.160304, 39.297003],
          [48.160055, 39.297514],
          [48.160455, 39.297882],
          [48.160989, 39.297989],
          [48.161771, 39.297884],
          [48.162024, 39.297383],
          [48.161924, 39.296888],
          [48.162341, 39.296327],
          [48.1628, 39.296152],
          [48.163339, 39.296239],
          [48.163597, 39.296302],
          [48.163835, 39.296734],
          [48.16389, 39.297087],
          [48.163164, 39.297697],
          [48.163028, 39.298125],
          [48.163261, 39.298663],
          [48.163305, 39.299186],
          [48.163136, 39.299397],
          [48.162675, 39.299603],
          [48.162398, 39.299888],
          [48.162535, 39.300141],
          [48.162866, 39.300237],
          [48.163546, 39.300245],
          [48.163849, 39.300164],
          [48.164135, 39.300208],
          [48.164396, 39.300548],
          [48.164711, 39.300792],
          [48.165264, 39.300926],
          [48.166953, 39.299747],
          [48.167419, 39.299742],
          [48.168166, 39.300477],
          [48.168137, 39.301061],
          [48.168669, 39.302152],
          [48.167596, 39.302553],
          [48.1683, 39.303051],
          [48.169201, 39.303274],
          [48.168611, 39.303921],
          [48.167864, 39.304147],
          [48.167631, 39.304478],
          [48.16879, 39.304777],
          [48.169752, 39.305321],
          [48.170169, 39.305256],
          [48.169681, 39.304571],
          [48.17047, 39.304117],
          [48.171219, 39.303784],
          [48.172108, 39.30417],
          [48.173505, 39.304077],
          [48.17431, 39.304504],
          [48.173943, 39.304815],
          [48.173276, 39.304972],
          [48.173378, 39.305764],
          [48.173657, 39.306254],
          [48.174337, 39.306085],
          [48.175947, 39.30555],
          [48.177094, 39.305829],
          [48.17907, 39.305846],
          [48.180685, 39.306212],
          [48.180816, 39.30673],
          [48.180172, 39.306746],
          [48.179691, 39.307252],
          [48.179595, 39.307875],
          [48.181229, 39.30884],
          [48.181667, 39.309018],
          [48.182515, 39.308633],
          [48.183169, 39.30863],
          [48.183476, 39.308965],
          [48.183298, 39.309437],
          [48.18303, 39.309611],
          [48.182691, 39.310096],
          [48.183349, 39.310552],
          [48.184242, 39.3108],
          [48.185835, 39.309463],
          [48.186175, 39.309349],
          [48.186433, 39.309514],
          [48.186479, 39.310667],
          [48.186044, 39.31182],
          [48.186173, 39.312538],
          [48.186997, 39.312913],
          [48.188033, 39.312826],
          [48.188781, 39.31224],
          [48.188888, 39.31142],
          [48.188533, 39.310577],
          [48.188733, 39.310352],
          [48.19195, 39.310826],
          [48.192549, 39.31042],
          [48.193198, 39.310802],
          [48.192881, 39.311267],
          [48.192858, 39.311476],
          [48.193963, 39.311577],
          [48.194049, 39.312131],
          [48.194307, 39.312478],
          [48.194593, 39.313282],
          [48.194622, 39.313591],
          [48.194758, 39.315059],
          [48.194272, 39.316496],
          [48.194416, 39.317274],
          [48.194835, 39.318064],
          [48.195598, 39.318878],
          [48.197339, 39.319707],
          [48.19819, 39.319889],
          [48.199538, 39.319835],
          [48.200852, 39.319444],
          [48.201229, 39.319992],
          [48.200996, 39.320443],
          [48.201232, 39.321049],
          [48.201728, 39.320973],
          [48.202221, 39.321434],
          [48.203249, 39.321598],
          [48.203656, 39.321857],
          [48.203565, 39.322474],
          [48.202657, 39.322972],
          [48.202749, 39.32329],
          [48.202941, 39.323369],
          [48.203083, 39.323428],
          [48.20415, 39.323201],
          [48.2049, 39.323295],
          [48.205149, 39.323544],
          [48.205885, 39.325471],
          [48.211001, 39.329108],
          [48.213344, 39.329802],
          [48.216069, 39.329687],
          [48.216459, 39.329272],
          [48.215599, 39.329063],
          [48.215155, 39.328619],
          [48.215687, 39.328439],
          [48.217244, 39.328803],
          [48.217709, 39.328211],
          [48.218042, 39.327919],
          [48.218564, 39.327822],
          [48.219427, 39.328239],
          [48.220173, 39.328565],
          [48.220821, 39.328188],
          [48.221198, 39.327903],
          [48.22148, 39.327857],
          [48.222018, 39.328315],
          [48.223148, 39.328829],
          [48.223869, 39.328553],
          [48.224289, 39.32833],
          [48.224636, 39.327866],
          [48.225118, 39.327482],
          [48.225717, 39.327473],
          [48.226108, 39.327637],
          [48.226501, 39.328265],
          [48.226532, 39.329499],
          [48.227952, 39.330119],
          [48.227908, 39.330647],
          [48.228106, 39.330905],
          [48.228629, 39.330585],
          [48.228929, 39.330092],
          [48.229111, 39.329896],
          [48.229335, 39.329869],
          [48.229493, 39.329997],
          [48.230199, 39.330033],
          [48.230316, 39.331296],
          [48.232858, 39.330475],
          [48.233932, 39.331307],
          [48.232169, 39.331822],
          [48.232094, 39.332666],
          [48.231336, 39.333259],
          [48.232344, 39.334187],
          [48.234075, 39.334296],
          [48.235783, 39.333599],
          [48.235686, 39.334942],
          [48.237177, 39.334707],
          [48.240071, 39.332304],
          [48.243719, 39.332445],
          [48.246016, 39.329536],
          [48.247812, 39.330709],
          [48.250089, 39.329872],
          [48.250331, 39.331646],
          [48.255207, 39.333486],
          [48.255193, 39.332518],
          [48.257368, 39.332421],
          [48.258463, 39.332126],
          [48.25855, 39.33311],
          [48.257816, 39.333631],
          [48.258737, 39.334963],
          [48.263213, 39.335436],
          [48.265317, 39.333445],
          [48.266799, 39.334122],
          [48.267311, 39.335436],
          [48.265033, 39.337392],
          [48.262848, 39.337398],
          [48.261435, 39.338443],
          [48.262485, 39.3399],
          [48.263992, 39.338376],
          [48.265868, 39.340478],
          [48.268424, 39.340527],
          [48.268738, 39.339126],
          [48.27256, 39.339851],
          [48.273293, 39.341264],
          [48.276784, 39.341411],
          [48.277541, 39.342711],
          [48.276859, 39.343896],
          [48.277389, 39.345017],
          [48.27833, 39.345531],
          [48.280768, 39.344101],
          [48.282066, 39.345901],
          [48.283294, 39.345238],
          [48.284291, 39.346718],
          [48.286332, 39.346909],
          [48.287618, 39.348056],
          [48.289322, 39.347691],
          [48.292532, 39.350074],
          [48.293504, 39.35061],
          [48.29358, 39.352474],
          [48.294148, 39.351517],
          [48.294437, 39.351062],
          [48.295357, 39.351149],
          [48.295832, 39.350634],
          [48.296582, 39.350287],
          [48.2974, 39.350253],
          [48.298002, 39.350387],
          [48.298238, 39.35132],
          [48.297689, 39.352296],
          [48.297679, 39.352825],
          [48.298451, 39.353007],
          [48.299083, 39.351811],
          [48.299491, 39.35158],
          [48.299734, 39.351653],
          [48.300203, 39.352717],
          [48.300659, 39.352309],
          [48.300909, 39.352283],
          [48.301043, 39.353963],
          [48.301457, 39.354042],
          [48.302049, 39.353759],
          [48.30233, 39.353688],
          [48.302562, 39.354519],
          [48.303131, 39.355292],
          [48.304951, 39.353945],
          [48.306764, 39.356384],
          [48.307396, 39.355732],
          [48.307673, 39.355751],
          [48.307716, 39.356526],
          [48.309431, 39.358031],
          [48.310035, 39.357731],
          [48.310425, 39.357953],
          [48.31167, 39.357994],
          [48.312003, 39.358506],
          [48.311343, 39.358384],
          [48.311037, 39.359245],
          [48.310413, 39.35955],
          [48.310402, 39.36023],
          [48.311522, 39.360506],
          [48.315051, 39.362226],
          [48.317792, 39.362336],
          [48.318766, 39.362514],
          [48.320569, 39.362842],
          [48.326179, 39.363293],
          [48.326726, 39.363221],
          [48.327057, 39.363414],
          [48.327125, 39.363602],
          [48.327334, 39.363654],
          [48.327544, 39.363591],
          [48.328381, 39.363858],
          [48.328988, 39.363931],
          [48.330097, 39.364323],
          [48.32984, 39.365195],
          [48.329874, 39.365722],
          [48.330401, 39.366051],
          [48.330975, 39.366025],
          [48.331529, 39.365419],
          [48.331948, 39.365216],
          [48.332671, 39.365472],
          [48.332853, 39.365785],
          [48.332956, 39.366506],
          [48.333191, 39.366615],
          [48.33361, 39.36637],
          [48.334393, 39.365915],
          [48.335751, 39.36637],
          [48.336805, 39.366516],
          [48.336927, 39.367451],
          [48.336899, 39.368108],
          [48.336947, 39.368337],
          [48.337176, 39.368484],
          [48.337587, 39.368396],
          [48.338437, 39.36827],
          [48.339383, 39.368639],
          [48.340004, 39.369179],
          [48.340443, 39.369459],
          [48.340682, 39.369415],
          [48.34072, 39.369134],
          [48.34074, 39.368588],
          [48.341179, 39.368329],
          [48.341542, 39.368307],
          [48.342164, 39.368917],
          [48.34263, 39.369394],
          [48.342703, 39.369617],
          [48.342521, 39.370226],
          [48.342507, 39.37044],
          [48.342655, 39.370666],
          [48.34288, 39.370688],
          [48.343424, 39.370902],
          [48.344422, 39.371297],
          [48.345102, 39.371311],
          [48.346419, 39.370987],
          [48.346749, 39.371098],
          [48.347408, 39.371268],
          [48.347814, 39.371493],
          [48.3481, 39.371548],
          [48.348215, 39.371493],
          [48.347962, 39.370728],
          [48.347628, 39.370219],
          [48.347747, 39.370038],
          [48.348444, 39.370067],
          [48.348917, 39.370012],
          [48.349104, 39.370145],
          [48.349357, 39.370437],
          [48.349813, 39.370595],
          [48.350751, 39.370381],
          [48.352229, 39.37027],
          [48.353019, 39.370259],
          [48.35376, 39.370494],
          [48.353924, 39.370546],
          [48.354168, 39.370515],
          [48.354262, 39.370431],
          [48.354505, 39.37016],
          [48.355764, 39.369819],
          [48.356613, 39.369987],
          [48.357698, 39.370083],
          [48.358792, 39.370112],
          [48.35926, 39.370234],
          [48.359914, 39.37036],
          [48.360475, 39.370537],
          [48.360728, 39.370633],
          [48.361301, 39.370822],
          [48.361496, 39.370983],
          [48.361524, 39.371223],
          [48.361267, 39.371909],
          [48.361214, 39.372478],
          [48.361357, 39.372929],
          [48.361806, 39.373412],
          [48.362337, 39.373759],
          [48.362795, 39.373977],
          [48.363555, 39.374162],
          [48.363956, 39.374376],
          [48.364596, 39.374863],
          [48.365238, 39.375601],
          [48.36536, 39.376373],
          [48.365211, 39.377031],
          [48.365461, 39.37758],
          [48.365819, 39.37783],
          [48.366231, 39.377924],
          [48.366819, 39.37795],
          [48.367197, 39.377809],
          [48.367413, 39.377517],
          [48.367528, 39.37712],
          [48.367643, 39.377068],
          [48.368064, 39.377647],
          [48.368387, 39.378351],
          [48.368622, 39.379836],
          [48.368381, 39.380682],
          [48.367956, 39.381375],
          [48.36582, 39.383946],
          [48.364187, 39.386181],
          [48.360428, 39.390921],
          [48.360179, 39.391235],
          [48.359022, 39.392781],
          [48.361059, 39.394062],
          [48.358652, 39.397302],
          [48.356195, 39.400608],
          [48.355012, 39.402201],
          [48.350422, 39.408946],
          [48.34446, 39.416345],
          [48.342928, 39.418247],
          [48.338232, 39.422535],
          [48.3322, 39.428704],
          [48.253893, 39.498307],
          [48.175548, 39.567873],
          [48.137086, 39.602],
          [48.095121, 39.638186],
          [48.032058, 39.692528],
          [47.99844, 39.704529],
          [47.989796, 39.707615],
          [47.985606, 39.709146],
          [47.982122, 39.709],
          [47.981523, 39.707216],
          [47.97989, 39.706148],
          [47.980629, 39.705087],
          [47.98048, 39.703909],
          [47.978581, 39.701656],
          [47.976073, 39.700909],
          [47.974088, 39.699345],
          [47.973603, 39.696999],
          [47.973701, 39.693952],
          [47.972952, 39.692862],
          [47.97167, 39.692025],
          [47.967123, 39.692878],
          [47.96352, 39.692216],
          [47.96166, 39.69005],
          [47.96089, 39.68593],
          [47.958083, 39.685151],
          [47.954802, 39.683688],
          [47.950018, 39.680467],
          [47.947699, 39.679678],
          [47.946476, 39.680154],
          [47.944545, 39.683004],
          [47.9428, 39.683468],
          [47.940618, 39.682911],
          [47.938939, 39.681353],
          [47.937335, 39.675045],
          [47.935323, 39.67208],
          [47.929384, 39.665821],
          [47.92805, 39.665868],
          [47.927292, 39.666607],
          [47.927634, 39.668232],
          [47.927339, 39.669416],
          [47.926109, 39.67025],
          [47.924771, 39.671087],
          [47.923449, 39.670712],
          [47.922055, 39.668677],
          [47.921608, 39.665637],
          [47.921091, 39.664278],
          [47.920402, 39.663201],
          [47.917423, 39.6615],
          [47.915663, 39.661408],
          [47.914574, 39.661351],
          [47.906709, 39.662587],
          [47.900777, 39.664883],
          [47.898986, 39.664934],
          [47.896101, 39.663166],
          [47.893478, 39.661846],
          [47.892077, 39.661908],
          [47.890776, 39.662594],
          [47.890463, 39.663666],
          [47.891328, 39.665471],
          [47.890546, 39.66625],
          [47.886929, 39.665829],
          [47.885277, 39.666145],
          [47.884231, 39.667166],
          [47.884226, 39.66957],
          [47.883644, 39.67029],
          [47.881938, 39.67066],
          [47.880002, 39.670673],
          [47.870584, 39.667358],
          [47.867082, 39.664869],
          [47.865372, 39.664547],
          [47.86378, 39.664542],
          [47.861228, 39.666555],
          [47.858251, 39.668046],
          [47.856465, 39.668421],
          [47.854964, 39.667359],
          [47.854291, 39.66488],
          [47.853162, 39.664265],
          [47.852196, 39.66424],
          [47.849098, 39.665723],
          [47.847556, 39.66598],
          [47.845699, 39.665481],
          [47.837628, 39.66163],
          [47.83774, 39.659841],
          [47.836926, 39.658883],
          [47.833735, 39.659678],
          [47.830926, 39.661164],
          [47.829286, 39.660604],
          [47.826528, 39.660613],
          [47.824532, 39.661616],
          [47.821799, 39.661496],
          [47.821327, 39.660229],
          [47.821864, 39.658998],
          [47.821138, 39.657879],
          [47.820016, 39.657006],
          [47.818717, 39.656358],
          [47.816691, 39.656013],
          [47.815163, 39.656437],
          [47.81265, 39.658977],
          [47.810311, 39.65966],
          [47.807322, 39.65919],
          [47.806456, 39.658358],
          [47.806043, 39.657497],
          [47.806623, 39.656021],
          [47.808676, 39.654619],
          [47.809163, 39.653285],
          [47.808152, 39.651213],
          [47.806409, 39.650559],
          [47.800962, 39.651664],
          [47.797784, 39.649861],
          [47.795184, 39.648985],
          [47.793997, 39.648295],
          [47.79442, 39.645353],
          [47.795347, 39.64451],
          [47.795214, 39.643686],
          [47.794342, 39.64229],
          [47.790225, 39.641568],
          [47.788965, 39.641754],
          [47.787073, 39.642902],
          [47.785891, 39.643075],
          [47.7846, 39.642096],
          [47.783965, 39.63999],
          [47.78327, 39.638985],
          [47.783305, 39.637574],
          [47.785976, 39.634309],
          [47.785189, 39.632972],
          [47.783172, 39.632044],
          [47.782295, 39.63067],
          [47.782755, 39.626944],
          [47.781806, 39.625424],
          [47.77814, 39.624387],
          [47.775121, 39.624463],
          [47.772442, 39.626715],
          [47.770304, 39.626563],
          [47.766766, 39.623293],
          [47.763898, 39.622615],
          [47.762679, 39.621605],
          [47.762277, 39.619715],
          [47.762378, 39.617082],
          [47.76007, 39.615473],
          [47.757694, 39.612311],
          [47.752969, 39.606023],
          [47.748803, 39.604832],
          [47.743811, 39.601998],
          [47.738205, 39.597853],
          [47.726763, 39.596552],
          [47.726049, 39.594857],
          [47.71588, 39.589455],
          [47.712285, 39.591947],
          [47.711074, 39.591942],
          [47.70623, 39.5898],
          [47.704342, 39.585313],
          [47.700032, 39.584934],
          [47.695386, 39.585696],
          [47.690822, 39.5876],
          [47.688767, 39.587182],
          [47.68721, 39.583311],
          [47.683929, 39.582163],
          [47.682179, 39.579786],
          [47.67982, 39.5781],
          [47.676487, 39.577494],
          [47.672467, 39.578891],
          [47.670143, 39.578271],
          [47.662494, 39.572019],
          [47.659767, 39.566563],
          [47.656738, 39.56422],
          [47.649391, 39.561696],
          [47.646671, 39.563239],
          [47.643957, 39.562956],
          [47.640307, 39.557774],
          [47.630545, 39.553927],
          [47.625677, 39.553614],
          [47.624319, 39.552806],
          [47.620079, 39.545941],
          [47.61742, 39.544701],
          [47.61297, 39.539045],
          [47.612052, 39.538849],
          [47.610343, 39.539445],
          [47.6095, 39.541039],
          [47.608545, 39.541898],
          [47.605297, 39.542684],
          [47.601585, 39.540673],
          [47.598294, 39.538285],
          [47.59563, 39.537732],
          [47.594475, 39.537344],
          [47.592615, 39.535388],
          [47.58655, 39.532885],
          [47.584219, 39.532016],
          [47.581408, 39.528715],
          [47.579766, 39.528577],
          [47.578185, 39.52805],
          [47.575794, 39.527899],
          [47.573029, 39.525643],
          [47.57057, 39.520264],
          [47.56666, 39.516176],
          [47.562937, 39.513775],
          [47.550984, 39.510387],
          [47.548959, 39.508795],
          [47.545971, 39.508323],
          [47.542588, 39.506796],
          [47.540091, 39.504433],
          [47.529631, 39.498786],
          [47.527161, 39.499015],
          [47.524019, 39.500258],
          [47.520491, 39.499987],
          [47.514069, 39.496702],
          [47.507712, 39.498215],
          [47.505004, 39.497435],
          [47.499821, 39.490183],
          [47.497974, 39.489087],
          [47.489911, 39.48788],
          [47.486501, 39.489048],
          [47.478645, 39.485483],
          [47.469285, 39.480179],
          [47.467315, 39.480619],
          [47.458673, 39.480432],
          [47.446548, 39.478362],
          [47.442691, 39.479128],
          [47.441165, 39.478198],
          [47.440327, 39.475627],
          [47.441463, 39.47177],
          [47.439445, 39.470671],
          [47.432382, 39.471783],
          [47.430031, 39.471148],
          [47.426248, 39.467795],
          [47.423046, 39.466562],
          [47.420585, 39.464804],
          [47.413174, 39.463911],
          [47.405658, 39.464019],
          [47.403619, 39.465016],
          [47.400788, 39.46779],
          [47.396734, 39.469644],
          [47.395017, 39.469119],
          [47.394247, 39.46694],
          [47.393089, 39.465847],
          [47.388474, 39.463954],
          [47.386789, 39.459958],
          [47.383105, 39.457354],
          [47.379722, 39.456651],
          [47.37757, 39.454905],
          [47.377131, 39.452323],
          [47.379729, 39.448092],
          [47.380262, 39.446113],
          [47.369816, 39.431978],
          [47.365236, 39.422963],
          [47.363399, 39.421726],
          [47.366336, 39.419788],
          [47.379673, 39.405897],
          [47.400506, 39.373399],
          [47.403283, 39.370342],
          [47.404116, 39.369509],
          [47.404949, 39.368397],
          [47.429396, 39.343121],
          [47.439111, 39.337267],
          [47.438785, 39.335218],
          [47.437401, 39.331955],
          [47.433832, 39.328586],
          [47.433832, 39.328586],
          [47.430553, 39.325018],
          [47.430007, 39.321582],
          [47.430007, 39.321582],
          [47.430793, 39.318638],
          [47.434293, 39.316038],
          [47.44126, 39.314672],
          [47.446929, 39.31239],
          [47.450857, 39.311302],
          [47.454152, 39.311248],
          [47.459138, 39.310637],
          [47.463594, 39.309788],
          [47.463594, 39.309788],
          [47.470014, 39.308393],
          [47.475647, 39.306535],
          [47.479112, 39.304358],
          [47.484064, 39.304171],
          [47.486726, 39.301743],
          [47.490669, 39.300441],
          [47.495364, 39.300028],
          [47.499937, 39.297691],
          [47.504935, 39.295436],
          [47.504935, 39.295436],
          [47.502455, 39.293121],
          [47.501899, 39.292843],
          [47.492054, 39.286605],
          [47.491346, 39.286173],
          [47.488614, 39.283952],
          [47.481778, 39.275862],
          [47.481777, 39.275861],
          [47.481343, 39.275344],
          [47.481343, 39.275344],
          [47.481343, 39.275344],
          [47.481342, 39.275344],
          [47.481342, 39.27534],
          [47.481066, 39.272565],
          [47.482176, 39.270619],
          [47.485232, 39.268674],
          [47.486622, 39.267284],
          [47.486899, 39.266451],
          [47.488289, 39.265061],
          [47.488846, 39.26395],
          [47.489399, 39.263671],
          [47.490513, 39.26117],
          [47.490513, 39.260892],
          [47.490789, 39.260058],
          [47.508012, 39.242845],
          [47.508288, 39.242554],
          [47.508012, 39.240344],
          [47.508846, 39.238954],
          [47.509956, 39.23812],
          [47.511069, 39.236731],
          [47.510513, 39.232562],
          [47.510513, 39.232284],
          [47.510512, 39.232283],
          [47.510236, 39.232006],
          [47.505237, 39.222125],
          [47.505236, 39.222122],
          [47.505237, 39.222121],
          [47.505789, 39.221453],
          [47.505789, 39.221175],
          [47.506623, 39.220619],
          [47.506623, 39.220342],
          [47.507179, 39.220064],
          [47.509956, 39.220064],
          [47.509956, 39.220342],
          [47.510513, 39.220342],
          [47.514956, 39.222009],
          [47.516902, 39.222009],
          [47.517736, 39.221731],
          [47.518289, 39.220064],
          [47.518569, 39.219786],
          [47.518289, 39.21923],
          [47.518289, 39.214227],
          [47.518846, 39.210058],
          [47.519123, 39.207843],
          [47.519402, 39.206175],
          [47.519679, 39.204508],
          [47.520236, 39.20145],
          [47.520789, 39.198393],
          [47.521069, 39.197559],
          [47.520789, 39.197003],
          [47.520512, 39.193954],
          [47.520512, 39.193398],
          [47.520236, 39.192287],
          [47.520512, 39.191453],
          [47.520236, 39.187283],
          [47.513569, 39.177008],
          [47.507456, 39.169225],
          [47.520513, 39.159227],
          [47.520513, 39.158949],
          [47.519123, 39.15173],
          [47.519402, 39.150896],
          [47.518289, 39.148116],
          [47.513289, 39.144503],
          [47.512179, 39.144502],
          [47.504123, 39.140342],
          [47.498013, 39.133114],
          [47.497456, 39.128397],
          [47.498013, 39.127841],
          [47.49829, 39.127563],
          [47.503846, 39.124783],
          [47.50468, 39.124783],
          [47.512179, 39.120335],
          [47.516069, 39.113672],
          [47.516069, 39.11256],
          [47.516623, 39.110058],
          [47.51718, 39.106444],
          [47.517456, 39.105897],
          [47.51718, 39.105619],
          [47.51579, 39.096722],
          [47.51579, 39.096175],
          [47.515513, 39.09561],
          [47.515236, 39.092005],
          [47.51718, 39.089503],
          [47.51857, 39.088391],
          [47.523846, 39.085611],
          [47.524403, 39.085333],
          [47.526626, 39.083118],
          [47.527459, 39.080615],
          [47.526903, 39.072006],
          [47.52607, 39.070337],
          [47.525793, 39.069503],
          [47.525793, 39.069225],
          [47.52107, 39.060059],
          [47.52079, 39.055611],
          [47.52107, 39.055055],
          [47.523846, 39.050615],
          [47.52357, 39.050059],
          [47.523846, 39.049225],
          [47.523293, 39.048113],
          [47.522737, 39.047834],
          [47.52246, 39.047278],
          [47.521903, 39.047],
          [47.517736, 39.047],
          [47.51579, 39.045888],
          [47.515236, 39.044507],
          [47.515236, 39.040336],
          [47.514956, 39.040058],
          [47.514123, 39.039502],
          [47.508846, 39.039502],
          [47.506623, 39.037834],
          [47.505237, 39.035331],
          [47.504957, 39.029501],
          [47.50857, 39.02533],
          [47.509123, 39.025331],
          [47.510237, 39.024783],
          [47.51107, 39.024783],
          [47.515237, 39.023393],
          [47.516347, 39.023393],
          [47.516347, 39.023115],
          [47.516903, 39.023115],
          [47.51829, 39.022559],
          [47.51857, 39.022281],
          [47.52218, 39.01811],
          [47.523013, 39.014226],
          [47.521903, 39.011168],
          [47.521346, 39.011167],
          [47.504957, 39.002278],
          [47.504403, 39.002278],
          [47.504403, 39.002],
          [47.503847, 39.002],
          [47.50218, 39.000054],
          [47.502737, 38.998672],
          [47.50357, 38.997004],
          [47.506623, 38.993667],
          [47.511623, 38.991165],
          [47.512736, 38.991164],
          [47.513847, 38.990608],
          [47.514956, 38.990608],
          [47.516347, 38.990052],
          [47.519403, 38.990052],
          [47.533293, 38.987558],
          [47.53468, 38.98728],
          [47.538013, 38.987281],
          [47.541626, 38.986724],
          [47.54496, 38.98589],
          [47.547183, 38.98589],
          [47.558293, 38.983387],
          [47.55857, 38.982553],
          [47.561626, 38.979503],
          [47.561906, 38.978669],
          [47.563016, 38.977557],
          [47.563016, 38.977279],
          [47.56274, 38.973386],
          [47.561906, 38.971726],
          [47.561349, 38.971448],
          [47.552736, 38.965608],
          [47.540793, 38.961446],
          [47.539403, 38.961446],
          [47.53857, 38.961168],
          [47.53468, 38.961167],
          [47.534127, 38.960889],
          [47.533013, 38.960889],
          [47.530513, 38.960333],
          [47.528847, 38.960333],
          [47.526627, 38.958665],
          [47.529126, 38.955336],
          [47.52968, 38.955337],
          [47.530237, 38.95478],
          [47.53496, 38.952834],
          [47.536347, 38.951165],
          [47.536347, 38.950053],
          [47.53218, 38.945612],
          [47.52996, 38.943387],
          [47.524127, 38.94005],
          [47.52107, 38.935887],
          [47.521347, 38.933384],
          [47.521623, 38.932837],
          [47.520237, 38.928944],
          [47.511624, 38.91589],
          [47.51329, 38.913109],
          [47.516347, 38.910328],
          [47.516624, 38.908112],
          [47.516904, 38.906999],
          [47.516904, 38.906443],
          [47.517457, 38.903662],
          [47.51968, 38.899221],
          [47.520237, 38.898943],
          [47.52079, 38.899221],
          [47.521623, 38.898943],
          [47.522737, 38.898943],
          [47.524127, 38.898387],
          [47.525237, 38.898387],
          [47.531627, 38.896162],
          [47.533847, 38.896162],
          [47.53468, 38.895883],
          [47.53857, 38.895883],
          [47.539404, 38.895605],
          [47.540517, 38.895605],
          [47.54496, 38.893111],
          [47.549683, 38.888392],
          [47.549684, 38.888114],
          [47.54996, 38.887558],
          [47.550237, 38.887001],
          [47.549683, 38.884498],
          [47.54746, 38.87811],
          [47.547737, 38.876719],
          [47.548017, 38.87339],
          [47.548293, 38.871165],
          [47.551627, 38.860327],
          [47.553017, 38.858389],
          [47.555517, 38.855885],
          [47.559407, 38.850609],
          [47.561073, 38.848106],
          [47.59635, 38.826714],
          [47.596906, 38.825054],
          [47.597057, 38.824751],
          [47.597183, 38.823664],
          [47.597463, 38.821995],
          [47.59774, 38.820604],
          [47.59802, 38.820326],
          [47.59802, 38.818944],
          [47.597183, 38.816997],
          [47.596906, 38.81644],
          [47.59663, 38.815884],
          [47.581073, 38.815884],
          [47.58024, 38.815606],
          [47.574407, 38.815606],
          [47.568294, 38.815884],
          [47.566907, 38.815884],
          [47.566074, 38.816162],
          [47.545794, 38.816162],
          [47.545517, 38.815884],
          [47.544403, 38.815884],
          [47.533571, 38.813658],
          [47.469678, 38.79144],
          [47.469401, 38.790883],
          [47.466901, 38.790049],
          [47.461901, 38.787276],
          [47.448568, 38.777826],
          [47.448568, 38.77727],
          [47.446621, 38.772549],
          [47.448568, 38.770333],
          [47.448845, 38.768942],
          [47.448012, 38.766716],
          [47.447455, 38.766438],
          [47.446345, 38.765325],
          [47.444121, 38.765325],
          [47.443565, 38.765047],
          [47.441898, 38.761718],
          [47.439398, 38.760327],
          [47.438565, 38.760327],
          [47.437178, 38.75977],
          [47.436065, 38.75977],
          [47.435788, 38.759492],
          [47.434678, 38.759492],
          [47.433012, 38.758379],
          [47.431345, 38.756441],
          [47.422175, 38.737827],
          [47.422455, 38.73588],
          [47.422732, 38.734498],
          [47.423009, 38.733107],
          [47.424122, 38.730603],
          [47.424398, 38.729768],
          [47.426898, 38.725326],
          [47.433012, 38.719214],
          [47.433288, 38.718101],
          [47.432732, 38.708381],
          [47.433012, 38.706989],
          [47.434398, 38.703381],
          [47.435789, 38.700877],
          [47.435789, 38.70033],
          [47.436065, 38.700052],
          [47.436622, 38.700051],
          [47.439679, 38.698382],
          [47.440512, 38.698382],
          [47.441622, 38.697826],
          [47.442455, 38.697826],
          [47.443012, 38.697547],
          [47.444122, 38.697547],
          [47.444678, 38.697269],
          [47.445788, 38.697269],
          [47.448569, 38.696434],
          [47.449122, 38.695878],
          [47.451345, 38.69227],
          [47.456069, 38.688662],
          [47.457735, 38.688662],
          [47.458288, 38.688383],
          [47.459402, 38.688383],
          [47.460789, 38.687827],
          [47.461902, 38.687827],
          [47.463012, 38.687549],
          [47.467458, 38.687549],
          [47.472458, 38.689496],
          [47.475792, 38.689496],
          [47.479678, 38.688662],
          [47.488015, 38.685879],
          [47.496901, 38.683384],
          [47.499125, 38.682549],
          [47.500791, 38.682271],
          [47.510791, 38.676993],
          [47.512738, 38.676437],
          [47.525794, 38.669768],
          [47.564954, 38.642279],
          [47.565241, 38.640604],
          [47.566627, 38.637274],
          [47.567184, 38.63477],
          [47.567184, 38.628657],
          [47.569961, 38.616161],
          [47.568851, 38.611708],
          [47.567879, 38.610465],
          [47.567461, 38.609769],
          [47.554961, 38.605326],
          [47.552738, 38.605326],
          [47.552461, 38.605047],
          [47.550518, 38.605047],
          [47.550238, 38.604769],
          [47.549128, 38.604769],
          [47.548018, 38.604491],
          [47.546071, 38.604491],
          [47.531348, 38.600604],
          [47.528571, 38.599491],
          [47.527738, 38.599491],
          [47.523848, 38.597821],
          [47.518291, 38.595603],
          [47.509405, 38.590325],
          [47.501902, 38.58449],
          [47.498568, 38.581159],
          [47.495792, 38.576993],
          [47.481069, 38.540597],
          [47.479959, 38.53477],
          [47.479679, 38.533935],
          [47.476345, 38.513931],
          [47.475792, 38.513096],
          [47.474679, 38.509487],
          [47.473292, 38.506991],
          [47.473292, 38.506156],
          [47.468846, 38.499215],
          [47.466902, 38.495318],
          [47.465793, 38.490595],
          [47.465793, 38.486708],
          [47.466069, 38.486429],
          [47.465793, 38.480871],
          [47.464123, 38.478096],
          [47.441066, 38.450036],
          [47.415233, 38.415597],
          [47.411343, 38.412544],
          [47.398844, 38.405315],
          [47.384677, 38.399208],
          [47.38023, 38.396146],
          [47.345784, 38.35698],
          [47.340231, 38.353369],
          [47.334951, 38.350594],
          [47.331617, 38.349202],
          [47.319118, 38.343373],
          [47.297452, 38.3367],
          [47.295228, 38.335873],
          [47.290781, 38.331984],
          [47.289395, 38.316706],
          [47.288838, 38.315592],
          [47.288838, 38.3142],
          [47.287449, 38.306979],
          [47.287172, 38.306152],
          [47.287172, 38.305317],
          [47.287172, 38.304482],
          [47.287172, 38.302263],
        ],
      ],
    },
  },
  WestAzarbaijan: {
    //Oromie
    properties: {
      value: "WestAzarbaijan",
      name: "استان آذربایجان غربی, ایران",
      id: 9,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [44.031891, 39.371726],
          [44.032053, 39.371012],
          [44.0328, 39.36994],
          [44.034196, 39.368803],
          [44.035268, 39.368024],
          [44.036762, 39.367147],
          [44.036956, 39.366758],
          [44.037184, 39.366043],
          [44.037606, 39.362471],
          [44.037701, 39.361264],
          [44.038672, 39.360866],
          [44.040819, 39.359988],
          [44.042538, 39.35919],
          [44.0436, 39.358638],
          [44.044743, 39.358296],
          [44.046381, 39.358284],
          [44.047026, 39.358473],
          [44.048096, 39.358433],
          [44.048948, 39.358154],
          [44.049696, 39.35747],
          [44.051243, 39.356838],
          [44.052445, 39.356556],
          [44.05258, 39.356494],
          [44.054055, 39.356194],
          [44.055563, 39.355608],
          [44.056524, 39.355489],
          [44.056526, 39.354544],
          [44.056556, 39.353451],
          [44.056933, 39.351627],
          [44.057237, 39.348808],
          [44.057016, 39.347631],
          [44.056863, 39.34718],
          [44.056556, 39.34662],
          [44.056532, 39.346282],
          [44.056644, 39.345706],
          [44.057295, 39.344018],
          [44.057402, 39.34359],
          [44.057783, 39.343191],
          [44.058191, 39.34306],
          [44.058595, 39.34309],
          [44.059258, 39.343369],
          [44.059916, 39.343535],
          [44.060556, 39.343633],
          [44.061786, 39.343459],
          [44.063776, 39.342343],
          [44.06446, 39.341845],
          [44.064379, 39.341573],
          [44.064463, 39.340581],
          [44.064763, 39.340074],
          [44.065039, 39.339783],
          [44.065384, 39.339581],
          [44.06608, 39.339373],
          [44.067919, 39.338498],
          [44.069565, 39.337567],
          [44.071689, 39.336866],
          [44.072236, 39.336547],
          [44.073218, 39.335624],
          [44.073907, 39.333915],
          [44.075646, 39.331796],
          [44.07584, 39.331382],
          [44.075846, 39.33093],
          [44.075732, 39.330278],
          [44.075859, 39.329868],
          [44.076226, 39.329651],
          [44.076549, 39.329229],
          [44.076866, 39.328594],
          [44.077357, 39.328092],
          [44.077517, 39.327524],
          [44.077345, 39.326549],
          [44.077472, 39.326186],
          [44.077736, 39.325843],
          [44.077994, 39.325649],
          [44.078344, 39.325531],
          [44.079053, 39.325417],
          [44.079528, 39.325228],
          [44.079706, 39.324988],
          [44.079527, 39.324008],
          [44.079078, 39.32218],
          [44.078702, 39.321227],
          [44.077864, 39.319942],
          [44.077747, 39.319432],
          [44.077808, 39.318873],
          [44.078331, 39.318109],
          [44.078874, 39.317466],
          [44.079099, 39.316926],
          [44.079467, 39.31626],
          [44.079587, 39.315847],
          [44.079586, 39.315404],
          [44.07944, 39.314354],
          [44.079387, 39.313479],
          [44.079293, 39.313257],
          [44.078611, 39.312654],
          [44.078536, 39.312072],
          [44.078185, 39.311656],
          [44.078047, 39.310606],
          [44.077657, 39.3098],
          [44.077543, 39.309563],
          [44.077233, 39.30918],
          [44.077028, 39.308774],
          [44.076948, 39.308355],
          [44.076714, 39.307918],
          [44.07732, 39.306212],
          [44.077635, 39.305539],
          [44.078235, 39.30504],
          [44.078761, 39.304339],
          [44.079135, 39.303182],
          [44.079255, 39.300487],
          [44.079101, 39.297585],
          [44.080472, 39.296001],
          [44.081001, 39.295171],
          [44.081029, 39.294717],
          [44.081251, 39.294128],
          [44.081687, 39.293783],
          [44.082156, 39.293527],
          [44.083845, 39.29312],
          [44.084971, 39.292735],
          [44.08584, 39.292536],
          [44.086361, 39.292329],
          [44.088223, 39.291374],
          [44.088833, 39.291166],
          [44.089303, 39.290844],
          [44.090044, 39.290201],
          [44.090506, 39.290029],
          [44.090896, 39.289996],
          [44.091222, 39.290022],
          [44.09163, 39.290201],
          [44.092139, 39.2905],
          [44.092936, 39.290794],
          [44.093601, 39.290935],
          [44.094469, 39.290913],
          [44.095576, 39.290747],
          [44.096016, 39.290607],
          [44.097659, 39.290317],
          [44.098369, 39.290075],
          [44.099689, 39.289779],
          [44.101041, 39.289588],
          [44.101409, 39.289473],
          [44.101914, 39.289021],
          [44.102878, 39.288366],
          [44.10295, 39.288047],
          [44.102889, 39.28754],
          [44.103067, 39.286687],
          [44.103576, 39.285978],
          [44.104004, 39.28553],
          [44.105228, 39.284863],
          [44.106121, 39.28455],
          [44.106754, 39.284371],
          [44.107087, 39.284355],
          [44.107811, 39.28443],
          [44.10806, 39.284297],
          [44.108132, 39.283983],
          [44.10811, 39.28366],
          [44.107798, 39.283466],
          [44.105889, 39.282725],
          [44.105372, 39.282276],
          [44.103937, 39.28052],
          [44.103465, 39.280148],
          [44.102524, 39.279552],
          [44.1015, 39.278434],
          [44.101395, 39.277781],
          [44.101208, 39.277365],
          [44.100794, 39.276934],
          [44.100198, 39.276465],
          [44.099759, 39.276217],
          [44.099187, 39.276099],
          [44.098477, 39.276066],
          [44.098098, 39.276091],
          [44.094451, 39.275908],
          [44.093797, 39.27558],
          [44.093445, 39.275102],
          [44.093107, 39.273592],
          [44.092531, 39.273075],
          [44.09226, 39.272559],
          [44.091222, 39.270144],
          [44.091075, 39.269657],
          [44.09097, 39.268939],
          [44.091113, 39.268199],
          [44.09183, 39.266789],
          [44.091947, 39.266258],
          [44.091831, 39.265497],
          [44.091835, 39.264596],
          [44.091769, 39.263681],
          [44.091559, 39.262814],
          [44.091315, 39.262369],
          [44.090693, 39.261987],
          [44.089863, 39.261666],
          [44.089541, 39.261498],
          [44.086743, 39.257932],
          [44.086329, 39.257517],
          [44.084628, 39.256475],
          [44.08438, 39.256214],
          [44.084307, 39.256022],
          [44.084395, 39.255854],
          [44.085027, 39.255568],
          [44.085436, 39.255326],
          [44.085833, 39.255002],
          [44.086461, 39.253855],
          [44.086646, 39.253471],
          [44.086721, 39.253039],
          [44.086173, 39.251767],
          [44.086069, 39.251228],
          [44.086131, 39.250762],
          [44.086403, 39.25046],
          [44.088568, 39.249107],
          [44.089081, 39.248551],
          [44.089265, 39.248172],
          [44.089255, 39.247418],
          [44.089445, 39.247049],
          [44.089792, 39.246844],
          [44.090133, 39.246785],
          [44.092256, 39.246929],
          [44.092746, 39.246884],
          [44.094338, 39.246267],
          [44.095377, 39.245808],
          [44.095918, 39.245713],
          [44.098093, 39.245729],
          [44.099145, 39.245634],
          [44.099526, 39.245401],
          [44.100298, 39.244594],
          [44.101992, 39.243241],
          [44.103047, 39.242793],
          [44.103231, 39.242573],
          [44.103279, 39.24225],
          [44.103132, 39.24174],
          [44.103021, 39.241055],
          [44.10271, 39.237853],
          [44.102706, 39.23752],
          [44.103069, 39.23702],
          [44.103099, 39.236565],
          [44.102954, 39.23583],
          [44.102597, 39.234731],
          [44.102123, 39.234205],
          [44.102244, 39.23359],
          [44.102226, 39.232732],
          [44.102382, 39.231393],
          [44.102144, 39.231183],
          [44.101906, 39.231056],
          [44.101538, 39.231026],
          [44.101311, 39.230883],
          [44.101135, 39.23057],
          [44.100763, 39.230366],
          [44.100128, 39.230277],
          [44.099854, 39.229925],
          [44.099594, 39.229038],
          [44.099368, 39.228638],
          [44.09894, 39.228262],
          [44.098711, 39.227863],
          [44.098807, 39.227308],
          [44.09913, 39.226961],
          [44.099928, 39.226565],
          [44.100489, 39.226174],
          [44.100648, 39.225276],
          [44.100312, 39.224505],
          [44.100012, 39.223991],
          [44.09917, 39.223146],
          [44.098482, 39.222085],
          [44.097941, 39.221411],
          [44.096748, 39.220835],
          [44.095997, 39.21979],
          [44.094984, 39.218044],
          [44.09431, 39.217195],
          [44.094051, 39.216368],
          [44.094084, 39.215529],
          [44.094586, 39.214419],
          [44.095543, 39.213022],
          [44.095628, 39.212273],
          [44.095903, 39.211829],
          [44.096672, 39.211133],
          [44.097315, 39.210194],
          [44.097408, 39.20971],
          [44.097651, 39.209227],
          [44.097435, 39.208634],
          [44.097009, 39.208228],
          [44.096812, 39.207874],
          [44.096662, 39.207552],
          [44.097005, 39.206521],
          [44.096993, 39.205019],
          [44.097257, 39.204492],
          [44.097585, 39.20414],
          [44.099734, 39.203681],
          [44.100499, 39.203623],
          [44.101668, 39.203162],
          [44.10301, 39.202732],
          [44.104586, 39.202114],
          [44.105866, 39.201717],
          [44.107323, 39.201463],
          [44.108141, 39.201174],
          [44.109256, 39.199879],
          [44.109898, 39.198892],
          [44.110085, 39.198178],
          [44.110054, 39.197489],
          [44.109818, 39.196547],
          [44.109982, 39.195651],
          [44.110541, 39.194908],
          [44.111323, 39.194142],
          [44.111675, 39.193641],
          [44.111774, 39.192736],
          [44.112279, 39.191838],
          [44.113229, 39.190854],
          [44.114932, 39.18949],
          [44.115356, 39.189012],
          [44.115888, 39.188723],
          [44.117081, 39.188482],
          [44.118396, 39.187957],
          [44.118947, 39.18783],
          [44.12119, 39.187692],
          [44.121774, 39.187475],
          [44.122538, 39.187053],
          [44.123181, 39.186798],
          [44.123505, 39.186449],
          [44.124147, 39.186044],
          [44.125796, 39.185454],
          [44.127795, 39.185249],
          [44.128514, 39.185284],
          [44.129585, 39.185176],
          [44.130342, 39.184633],
          [44.131081, 39.18368],
          [44.131692, 39.183097],
          [44.13202, 39.183003],
          [44.132546, 39.182979],
          [44.13303, 39.182817],
          [44.133657, 39.182713],
          [44.134529, 39.18268],
          [44.135785, 39.182498],
          [44.137134, 39.182701],
          [44.137483, 39.183038],
          [44.138424, 39.1837],
          [44.139434, 39.184215],
          [44.14082, 39.184754],
          [44.143583, 39.185582],
          [44.144167, 39.185591],
          [44.144875, 39.185496],
          [44.145516, 39.185221],
          [44.147004, 39.184305],
          [44.147983, 39.183625],
          [44.149493, 39.182376],
          [44.150016, 39.182066],
          [44.150846, 39.1818],
          [44.151856, 39.181541],
          [44.152609, 39.18128],
          [44.153479, 39.181058],
          [44.15542, 39.180438],
          [44.156325, 39.180367],
          [44.158451, 39.179853],
          [44.160062, 39.179341],
          [44.161533, 39.178811],
          [44.164571, 39.178119],
          [44.166381, 39.177625],
          [44.166834, 39.177333],
          [44.167868, 39.175928],
          [44.168446, 39.175565],
          [44.169174, 39.175217],
          [44.169934, 39.174983],
          [44.170663, 39.174624],
          [44.171207, 39.174183],
          [44.171704, 39.173635],
          [44.172261, 39.17312],
          [44.173148, 39.172587],
          [44.174107, 39.171676],
          [44.174672, 39.171344],
          [44.176635, 39.170625],
          [44.177933, 39.16976],
          [44.178484, 39.169239],
          [44.179968, 39.167181],
          [44.18094, 39.166102],
          [44.181817, 39.165453],
          [44.182155, 39.165294],
          [44.182581, 39.165207],
          [44.183004, 39.165048],
          [44.183262, 39.164765],
          [44.183413, 39.164204],
          [44.183462, 39.163279],
          [44.18373, 39.162891],
          [44.183921, 39.162419],
          [44.183817, 39.162041],
          [44.183952, 39.161498],
          [44.184252, 39.16107],
          [44.1845, 39.16088],
          [44.186238, 39.16029],
          [44.187479, 39.160003],
          [44.188078, 39.159914],
          [44.188506, 39.159625],
          [44.188911, 39.159083],
          [44.189398, 39.158673],
          [44.190214, 39.158188],
          [44.190131, 39.157865],
          [44.189509, 39.157334],
          [44.189848, 39.155968],
          [44.190365, 39.153169],
          [44.190775, 39.15269],
          [44.191516, 39.152327],
          [44.193102, 39.151823],
          [44.193775, 39.151367],
          [44.194567, 39.150504],
          [44.195448, 39.149665],
          [44.197258, 39.147721],
          [44.197932, 39.14683],
          [44.198714, 39.145397],
          [44.199975, 39.144187],
          [44.20071, 39.142978],
          [44.200853, 39.142092],
          [44.201039, 39.1402],
          [44.201444, 39.13981],
          [44.203558, 39.138486],
          [44.203927, 39.138349],
          [44.206395, 39.138175],
          [44.206871, 39.13799],
          [44.207545, 39.137361],
          [44.208918, 39.136307],
          [44.20938, 39.135884],
          [44.210167, 39.134534],
          [44.210815, 39.133723],
          [44.211486, 39.132738],
          [44.212302, 39.131879],
          [44.213566, 39.130305],
          [44.213453, 39.129681],
          [44.213118, 39.128757],
          [44.212641, 39.128036],
          [44.211468, 39.126875],
          [44.211245, 39.126002],
          [44.211299, 39.125179],
          [44.211598, 39.124347],
          [44.211074, 39.123706],
          [44.210555, 39.122798],
          [44.210221, 39.120607],
          [44.209884, 39.119426],
          [44.209514, 39.117691],
          [44.209375, 39.117463],
          [44.208286, 39.116801],
          [44.208045, 39.116521],
          [44.207534, 39.114823],
          [44.207592, 39.114085],
          [44.207874, 39.113821],
          [44.208507, 39.113065],
          [44.209833, 39.111783],
          [44.210021, 39.111321],
          [44.209849, 39.110777],
          [44.209813, 39.110469],
          [44.21052, 39.110164],
          [44.211132, 39.110189],
          [44.211544, 39.11008],
          [44.212845, 39.108936],
          [44.214664, 39.108342],
          [44.215173, 39.108092],
          [44.215275, 39.107707],
          [44.215295, 39.107158],
          [44.215533, 39.106864],
          [44.215846, 39.106775],
          [44.216525, 39.106417],
          [44.217061, 39.105692],
          [44.217521, 39.104633],
          [44.21804, 39.103744],
          [44.219012, 39.103404],
          [44.220236, 39.103106],
          [44.220918, 39.103272],
          [44.221374, 39.103451],
          [44.221771, 39.103337],
          [44.222834, 39.102561],
          [44.223337, 39.101967],
          [44.223495, 39.101432],
          [44.223365, 39.100861],
          [44.223977, 39.099901],
          [44.224562, 39.098846],
          [44.225788, 39.096252],
          [44.226025, 39.0954],
          [44.22591, 39.09505],
          [44.225576, 39.094855],
          [44.225047, 39.094699],
          [44.224291, 39.094546],
          [44.223782, 39.094502],
          [44.223291, 39.094356],
          [44.222913, 39.094091],
          [44.222601, 39.093709],
          [44.222483, 39.092975],
          [44.222232, 39.09226],
          [44.221455, 39.091802],
          [44.221569, 39.091181],
          [44.221212, 39.090764],
          [44.221007, 39.090704],
          [44.219422, 39.091164],
          [44.218044, 39.091036],
          [44.216861, 39.090836],
          [44.216016, 39.090363],
          [44.215651, 39.09029],
          [44.215224, 39.090136],
          [44.214642, 39.089735],
          [44.214206, 39.089152],
          [44.213518, 39.088588],
          [44.212319, 39.087815],
          [44.209227, 39.087675],
          [44.207982, 39.08896],
          [44.207056, 39.08936],
          [44.206763, 39.089646],
          [44.206325, 39.089907],
          [44.205782, 39.089956],
          [44.20517, 39.089673],
          [44.204808, 39.089187],
          [44.204387, 39.089037],
          [44.204183, 39.088561],
          [44.203065, 39.087546],
          [44.20261, 39.087347],
          [44.201959, 39.087256],
          [44.201339, 39.087497],
          [44.200656, 39.087428],
          [44.199993, 39.087206],
          [44.199372, 39.086876],
          [44.198924, 39.086817],
          [44.198242, 39.086819],
          [44.197557, 39.086398],
          [44.197224, 39.085835],
          [44.19677, 39.084616],
          [44.196217, 39.083722],
          [44.195139, 39.08161],
          [44.194736, 39.08102],
          [44.193921, 39.080568],
          [44.193522, 39.080146],
          [44.193037, 39.079756],
          [44.192705, 39.079314],
          [44.192566, 39.078813],
          [44.192567, 39.078149],
          [44.192251, 39.077641],
          [44.191797, 39.077198],
          [44.191172, 39.076892],
          [44.190921, 39.076619],
          [44.190988, 39.075284],
          [44.190867, 39.074591],
          [44.190965, 39.073746],
          [44.191928, 39.072558],
          [44.192009, 39.071867],
          [44.192189, 39.071095],
          [44.192269, 39.07075],
          [44.192878, 39.069824],
          [44.19325, 39.068976],
          [44.193651, 39.067946],
          [44.194235, 39.067299],
          [44.194449, 39.066861],
          [44.195499, 39.066443],
          [44.195601, 39.066279],
          [44.195568, 39.065792],
          [44.196443, 39.065162],
          [44.196724, 39.064618],
          [44.196869, 39.063648],
          [44.197535, 39.06292],
          [44.198673, 39.062474],
          [44.200029, 39.061625],
          [44.202357, 39.059071],
          [44.203238, 39.058279],
          [44.203875, 39.0577],
          [44.204921, 39.056358],
          [44.205512, 39.055468],
          [44.204984, 39.054548],
          [44.204323, 39.054089],
          [44.202999, 39.053507],
          [44.201906, 39.052881],
          [44.20035, 39.051316],
          [44.199604, 39.049992],
          [44.199105, 39.048521],
          [44.199019, 39.048499],
          [44.197926, 39.04749],
          [44.195907, 39.04617],
          [44.195144, 39.044945],
          [44.194807, 39.043897],
          [44.194655, 39.04205],
          [44.194772, 39.040501],
          [44.195408, 39.039285],
          [44.196467, 39.038494],
          [44.197931, 39.037118],
          [44.198881, 39.036507],
          [44.200306, 39.034794],
          [44.202209, 39.032107],
          [44.202622, 39.031012],
          [44.204071, 39.030316],
          [44.205483, 39.029258],
          [44.207143, 39.027638],
          [44.207948, 39.026201],
          [44.208492, 39.025445],
          [44.20943, 39.024351],
          [44.210202, 39.023315],
          [44.211387, 39.022216],
          [44.211654, 39.021729],
          [44.211677, 39.02095],
          [44.211498, 39.020498],
          [44.21068, 39.019953],
          [44.210012, 39.019693],
          [44.208794, 39.019368],
          [44.206188, 39.018148],
          [44.205148, 39.017884],
          [44.203223, 39.017249],
          [44.200491, 39.015751],
          [44.19943, 39.014823],
          [44.198577, 39.013773],
          [44.197873, 39.013083],
          [44.197016, 39.012687],
          [44.195756, 39.011832],
          [44.194761, 39.011513],
          [44.194597, 39.011257],
          [44.194466, 39.010704],
          [44.194229, 39.010545],
          [44.193726, 39.010495],
          [44.193139, 39.010216],
          [44.191646, 39.009142],
          [44.191078, 39.009006],
          [44.190191, 39.009199],
          [44.190133, 39.009503],
          [44.189823, 39.009756],
          [44.189303, 39.010889],
          [44.189119, 39.011036],
          [44.187983, 39.011098],
          [44.187304, 39.011393],
          [44.186851, 39.011413],
          [44.186203, 39.011267],
          [44.185072, 39.01086],
          [44.184361, 39.01073],
          [44.182991, 39.010666],
          [44.182485, 39.010703],
          [44.181593, 39.01098],
          [44.181272, 39.010866],
          [44.180953, 39.010683],
          [44.180039, 39.009926],
          [44.179665, 39.009408],
          [44.179511, 39.00898],
          [44.17944, 39.008272],
          [44.179286, 39.00789],
          [44.178104, 39.007026],
          [44.17724, 39.006065],
          [44.177005, 39.00566],
          [44.176839, 39.005133],
          [44.176872, 39.004655],
          [44.176802, 39.003228],
          [44.176234, 39.002168],
          [44.176121, 39.001826],
          [44.175487, 39.001185],
          [44.174769, 39.001058],
          [44.174201, 39.000727],
          [44.173903, 39.000497],
          [44.173656, 39.000333],
          [44.173621, 39.000201],
          [44.174174, 38.999168],
          [44.174136, 38.998362],
          [44.173748, 38.996861],
          [44.173246, 38.996007],
          [44.173099, 38.995316],
          [44.173002, 38.99415],
          [44.172533, 38.993553],
          [44.171997, 38.992719],
          [44.171873, 38.992433],
          [44.171794, 38.991791],
          [44.1721, 38.990336],
          [44.172835, 38.988624],
          [44.173, 38.98828],
          [44.176216, 38.986821],
          [44.176678, 38.98654],
          [44.177823, 38.986156],
          [44.178276, 38.98595],
          [44.180762, 38.985399],
          [44.181792, 38.98441],
          [44.182773, 38.983623],
          [44.18404, 38.982778],
          [44.184922, 38.982182],
          [44.186188, 38.981777],
          [44.187423, 38.98173],
          [44.189042, 38.981866],
          [44.189475, 38.981624],
          [44.189539, 38.981279],
          [44.189378, 38.98009],
          [44.189215, 38.979496],
          [44.188941, 38.97929],
          [44.187731, 38.978763],
          [44.187381, 38.978511],
          [44.187278, 38.978206],
          [44.187335, 38.977863],
          [44.187766, 38.97717],
          [44.18827, 38.97685],
          [44.18917, 38.976414],
          [44.191099, 38.975211],
          [44.191425, 38.974682],
          [44.190981, 38.974468],
          [44.188515, 38.974237],
          [44.187847, 38.973125],
          [44.186443, 38.971967],
          [44.186269, 38.970242],
          [44.186506, 38.969825],
          [44.188029, 38.968412],
          [44.188472, 38.967721],
          [44.188427, 38.967581],
          [44.187389, 38.967082],
          [44.186521, 38.966807],
          [44.185477, 38.966368],
          [44.18496, 38.965989],
          [44.184764, 38.96557],
          [44.184449, 38.964631],
          [44.182295, 38.961641],
          [44.181923, 38.961396],
          [44.181316, 38.959699],
          [44.18105, 38.958642],
          [44.180929, 38.958156],
          [44.180874, 38.957913],
          [44.180671, 38.957757],
          [44.180708, 38.957232],
          [44.180339, 38.956567],
          [44.18026, 38.956068],
          [44.18048, 38.955842],
          [44.18253, 38.954939],
          [44.182971, 38.954973],
          [44.183702, 38.95537],
          [44.184117, 38.955561],
          [44.184549, 38.95537],
          [44.185335, 38.954893],
          [44.187409, 38.953493],
          [44.189541, 38.952181],
          [44.189761, 38.951883],
          [44.191264, 38.951569],
          [44.19191, 38.951295],
          [44.193347, 38.950984],
          [44.195674, 38.951176],
          [44.196939, 38.950946],
          [44.198144, 38.950569],
          [44.199266, 38.95015],
          [44.199669, 38.949658],
          [44.200242, 38.948009],
          [44.200279, 38.947387],
          [44.20009, 38.946781],
          [44.199349, 38.945398],
          [44.198819, 38.944208],
          [44.198418, 38.94356],
          [44.197357, 38.942148],
          [44.196655, 38.9411],
          [44.195436, 38.940106],
          [44.194548, 38.939459],
          [44.194059, 38.938782],
          [44.192688, 38.936197],
          [44.192417, 38.936026],
          [44.190334, 38.935594],
          [44.190125, 38.935249],
          [44.189736, 38.934288],
          [44.189715, 38.934027],
          [44.192472, 38.932447],
          [44.195382, 38.931028],
          [44.195763, 38.93078],
          [44.196532, 38.928978],
          [44.196661, 38.926037],
          [44.197388, 38.924202],
          [44.197876, 38.923048],
          [44.199469, 38.921511],
          [44.20108, 38.921683],
          [44.202278, 38.921827],
          [44.203659, 38.921776],
          [44.209397, 38.921621],
          [44.209958, 38.921453],
          [44.211549, 38.920954],
          [44.212696, 38.920914],
          [44.213876, 38.920713],
          [44.214916, 38.920447],
          [44.21748, 38.918885],
          [44.218423, 38.917495],
          [44.218831, 38.916776],
          [44.219299, 38.915076],
          [44.219376, 38.914268],
          [44.219719, 38.91265],
          [44.219676, 38.91072],
          [44.219897, 38.909716],
          [44.220095, 38.909194],
          [44.220152, 38.907905],
          [44.219781, 38.906186],
          [44.220002, 38.905217],
          [44.22014, 38.904818],
          [44.220214, 38.904606],
          [44.22022, 38.904298],
          [44.21998, 38.903988],
          [44.219445, 38.903653],
          [44.218952, 38.903567],
          [44.218007, 38.903591],
          [44.217382, 38.903697],
          [44.216908, 38.903671],
          [44.216515, 38.903512],
          [44.215662, 38.902824],
          [44.214311, 38.90198],
          [44.213416, 38.901326],
          [44.212982, 38.900901],
          [44.211943, 38.898886],
          [44.211546, 38.898366],
          [44.210123, 38.8979],
          [44.209964, 38.897445],
          [44.210457, 38.896205],
          [44.210648, 38.894357],
          [44.210653, 38.892717],
          [44.211352, 38.890557],
          [44.211482, 38.890345],
          [44.211651, 38.890204],
          [44.213202, 38.889227],
          [44.214145, 38.888455],
          [44.21471, 38.888146],
          [44.216711, 38.887456],
          [44.217157, 38.887216],
          [44.218562, 38.885692],
          [44.21927, 38.885103],
          [44.220392, 38.88479],
          [44.222923, 38.884373],
          [44.224273, 38.883949],
          [44.226685, 38.883052],
          [44.230914, 38.881115],
          [44.234643, 38.878665],
          [44.235045, 38.876415],
          [44.239195, 38.873279],
          [44.239895, 38.872287],
          [44.241518, 38.871376],
          [44.244959, 38.870668],
          [44.245455, 38.870218],
          [44.245487, 38.868547],
          [44.245697, 38.867632],
          [44.245615, 38.866662],
          [44.244399, 38.865883],
          [44.243954, 38.865545],
          [44.243817, 38.865256],
          [44.243861, 38.861448],
          [44.243689, 38.860958],
          [44.242501, 38.859276],
          [44.241099, 38.858446],
          [44.241222, 38.856528],
          [44.242277, 38.856031],
          [44.243444, 38.855162],
          [44.244492, 38.853509],
          [44.24448, 38.85302],
          [44.24446, 38.852277],
          [44.24466, 38.852023],
          [44.245258, 38.851665],
          [44.245459, 38.850969],
          [44.245308, 38.850682],
          [44.246003, 38.850452],
          [44.246289, 38.85019],
          [44.247037, 38.8501],
          [44.248708, 38.849822],
          [44.251232, 38.849584],
          [44.253236, 38.849195],
          [44.254436, 38.848817],
          [44.256535, 38.847549],
          [44.256993, 38.846949],
          [44.257724, 38.847518],
          [44.257982, 38.848069],
          [44.259496, 38.848524],
          [44.26, 38.848795],
          [44.260355, 38.849376],
          [44.260811, 38.849658],
          [44.262394, 38.850137],
          [44.263292, 38.850352],
          [44.264136, 38.850822],
          [44.264951, 38.851175],
          [44.265788, 38.85162],
          [44.266089, 38.851854],
          [44.266288, 38.85233],
          [44.266606, 38.852761],
          [44.267527, 38.852766],
          [44.268393, 38.853097],
          [44.269861, 38.853454],
          [44.270696, 38.853806],
          [44.271429, 38.853873],
          [44.273966, 38.853933],
          [44.274906, 38.854253],
          [44.275398, 38.854341],
          [44.276512, 38.854196],
          [44.27748, 38.854231],
          [44.278663, 38.853155],
          [44.281026, 38.851286],
          [44.281554, 38.85049],
          [44.281699, 38.849856],
          [44.281699, 38.848899],
          [44.282041, 38.847847],
          [44.283746, 38.847514],
          [44.284404, 38.847337],
          [44.284951, 38.846893],
          [44.286293, 38.846053],
          [44.286899, 38.845827],
          [44.287605, 38.845742],
          [44.28828, 38.845743],
          [44.289147, 38.845612],
          [44.289747, 38.845449],
          [44.291589, 38.843796],
          [44.292635, 38.842976],
          [44.293602, 38.842385],
          [44.294854, 38.841764],
          [44.296437, 38.841066],
          [44.297898, 38.840818],
          [44.299245, 38.840856],
          [44.300988, 38.840802],
          [44.30106, 38.84019],
          [44.300636, 38.838591],
          [44.300713, 38.837598],
          [44.300687, 38.836599],
          [44.300449, 38.835752],
          [44.299088, 38.834359],
          [44.298537, 38.833657],
          [44.297958, 38.832254],
          [44.29757, 38.830688],
          [44.29722, 38.830018],
          [44.295928, 38.828407],
          [44.295027, 38.826325],
          [44.295117, 38.826187],
          [44.295668, 38.825786],
          [44.296415, 38.825371],
          [44.29789, 38.825054],
          [44.299553, 38.824765],
          [44.301045, 38.82461],
          [44.301818, 38.82438],
          [44.302198, 38.824167],
          [44.302707, 38.823407],
          [44.303648, 38.8224],
          [44.305047, 38.820263],
          [44.305216, 38.819731],
          [44.305254, 38.819243],
          [44.305107, 38.818862],
          [44.305045, 38.818452],
          [44.30515, 38.818102],
          [44.305439, 38.817667],
          [44.305713, 38.817098],
          [44.305971, 38.816299],
          [44.306066, 38.815769],
          [44.30657, 38.81571],
          [44.308245, 38.815073],
          [44.30907, 38.814547],
          [44.30954, 38.813994],
          [44.310394, 38.812431],
          [44.310736, 38.811974],
          [44.311067, 38.811713],
          [44.311611, 38.811451],
          [44.312294, 38.811222],
          [44.312682, 38.810963],
          [44.313285, 38.81037],
          [44.313546, 38.810249],
          [44.313713, 38.810061],
          [44.313801, 38.809695],
          [44.314018, 38.809379],
          [44.314601, 38.809012],
          [44.31482, 38.808795],
          [44.314906, 38.808167],
          [44.314833, 38.807806],
          [44.314836, 38.807253],
          [44.314744, 38.806927],
          [44.314419, 38.806489],
          [44.313181, 38.806041],
          [44.312522, 38.805664],
          [44.311999, 38.805288],
          [44.31146, 38.805137],
          [44.31056, 38.804989],
          [44.309804, 38.804704],
          [44.309093, 38.804613],
          [44.308011, 38.804641],
          [44.306813, 38.804481],
          [44.306047, 38.804479],
          [44.305316, 38.804285],
          [44.304486, 38.803963],
          [44.303855, 38.803553],
          [44.303149, 38.802953],
          [44.30281, 38.802524],
          [44.302564, 38.80197],
          [44.302388, 38.801023],
          [44.302091, 38.799919],
          [44.301724, 38.799185],
          [44.301248, 38.798749],
          [44.300927, 38.798147],
          [44.300878, 38.797729],
          [44.300659, 38.79718],
          [44.300526, 38.796469],
          [44.300284, 38.795181],
          [44.300433, 38.794641],
          [44.300829, 38.79421],
          [44.300885, 38.793812],
          [44.300444, 38.792921],
          [44.300348, 38.791895],
          [44.300227, 38.7915],
          [44.298121, 38.790429],
          [44.296412, 38.789645],
          [44.295213, 38.788971],
          [44.292061, 38.787048],
          [44.291202, 38.786597],
          [44.289582, 38.786242],
          [44.288838, 38.785976],
          [44.288589, 38.785669],
          [44.288309, 38.784471],
          [44.288081, 38.784119],
          [44.287557, 38.783713],
          [44.286498, 38.783104],
          [44.286327, 38.782668],
          [44.286508, 38.781983],
          [44.287067, 38.781379],
          [44.288171, 38.780676],
          [44.288723, 38.780483],
          [44.289534, 38.780368],
          [44.289791, 38.780085],
          [44.289988, 38.779509],
          [44.290432, 38.777449],
          [44.290533, 38.777068],
          [44.291149, 38.775714],
          [44.291225, 38.775287],
          [44.291336, 38.774985],
          [44.291073, 38.774093],
          [44.289351, 38.772609],
          [44.289054, 38.772133],
          [44.289016, 38.771786],
          [44.289374, 38.770405],
          [44.290131, 38.769166],
          [44.290414, 38.768853],
          [44.291354, 38.768321],
          [44.291986, 38.767815],
          [44.292126, 38.767562],
          [44.292232, 38.766482],
          [44.292412, 38.765703],
          [44.292504, 38.764863],
          [44.292505, 38.764195],
          [44.292396, 38.763867],
          [44.292177, 38.763695],
          [44.291607, 38.763391],
          [44.291275, 38.763116],
          [44.290992, 38.762726],
          [44.290012, 38.760191],
          [44.287676, 38.757564],
          [44.287521, 38.757173],
          [44.287561, 38.756815],
          [44.287776, 38.756248],
          [44.287743, 38.755751],
          [44.286385, 38.752511],
          [44.286128, 38.752279],
          [44.284949, 38.751733],
          [44.283579, 38.750906],
          [44.283105, 38.750462],
          [44.282165, 38.749434],
          [44.282127, 38.749387],
          [44.281434, 38.74853],
          [44.280205, 38.747548],
          [44.279019, 38.747252],
          [44.278833, 38.747206],
          [44.277897, 38.74687],
          [44.277373, 38.746497],
          [44.277137, 38.746134],
          [44.277046, 38.745704],
          [44.277307, 38.744738],
          [44.277189, 38.744025],
          [44.276552, 38.742626],
          [44.276669, 38.741955],
          [44.276937, 38.741052],
          [44.278539, 38.737571],
          [44.278897, 38.737228],
          [44.27936, 38.736985],
          [44.280224, 38.736684],
          [44.28084, 38.736418],
          [44.28101, 38.736056],
          [44.281019, 38.735696],
          [44.280888, 38.734737],
          [44.280622, 38.733776],
          [44.280147, 38.732925],
          [44.279674, 38.732408],
          [44.279035, 38.731927],
          [44.278193, 38.731076],
          [44.276154, 38.729443],
          [44.275859, 38.729362],
          [44.275532, 38.729362],
          [44.274315, 38.729648],
          [44.273857, 38.72958],
          [44.273659, 38.729406],
          [44.273653, 38.729112],
          [44.273917, 38.728517],
          [44.274358, 38.7271],
          [44.274204, 38.726712],
          [44.273669, 38.726177],
          [44.273167, 38.725903],
          [44.272387, 38.725731],
          [44.2714, 38.725612],
          [44.269901, 38.725607],
          [44.269493, 38.725465],
          [44.269158, 38.72502],
          [44.269088, 38.724526],
          [44.269506, 38.723056],
          [44.269444, 38.722187],
          [44.269198, 38.72203],
          [44.269106, 38.721972],
          [44.269003, 38.721964],
          [44.268573, 38.721934],
          [44.268459, 38.721937],
          [44.266291, 38.722007],
          [44.265348, 38.72198],
          [44.264498, 38.721776],
          [44.264164, 38.72106],
          [44.264015, 38.720252],
          [44.263536, 38.719334],
          [44.26333, 38.719183],
          [44.263015, 38.719182],
          [44.260247, 38.71945],
          [44.258452, 38.719785],
          [44.257486, 38.719897],
          [44.257176, 38.719932],
          [44.25612, 38.719864],
          [44.255012, 38.719547],
          [44.254297, 38.719036],
          [44.254067, 38.718334],
          [44.254182, 38.717862],
          [44.254624, 38.717438],
          [44.257356, 38.716589],
          [44.259005, 38.715924],
          [44.260113, 38.715338],
          [44.260899, 38.714702],
          [44.261013, 38.714177],
          [44.260869, 38.712763],
          [44.261155, 38.71135],
          [44.260986, 38.710575],
          [44.261063, 38.710062],
          [44.261351, 38.709425],
          [44.261258, 38.70853],
          [44.260871, 38.707613],
          [44.260701, 38.706252],
          [44.260769, 38.705287],
          [44.261237, 38.704179],
          [44.262236, 38.703627],
          [44.262841, 38.703629],
          [44.264414, 38.704107],
          [44.264981, 38.704609],
          [44.265548, 38.704888],
          [44.266152, 38.704973],
          [44.267789, 38.704895],
          [44.268537, 38.704676],
          [44.269143, 38.7044],
          [44.269858, 38.703764],
          [44.271429, 38.702604],
          [44.271323, 38.7023],
          [44.271042, 38.701799],
          [44.270512, 38.701102],
          [44.26946, 38.699167],
          [44.269405, 38.697249],
          [44.269475, 38.696289],
          [44.270071, 38.695526],
          [44.270277, 38.694593],
          [44.270539, 38.693958],
          [44.270986, 38.693399],
          [44.271689, 38.692627],
          [44.272386, 38.691969],
          [44.273261, 38.690935],
          [44.273592, 38.689911],
          [44.273578, 38.688849],
          [44.273377, 38.68832],
          [44.273091, 38.687934],
          [44.272605, 38.686498],
          [44.272129, 38.68583],
          [44.271875, 38.685213],
          [44.271837, 38.684796],
          [44.272058, 38.684453],
          [44.27344, 38.683034],
          [44.27534, 38.68097],
          [44.276359, 38.680282],
          [44.27693, 38.679991],
          [44.277715, 38.679787],
          [44.278065, 38.679564],
          [44.278702, 38.678482],
          [44.279649, 38.677793],
          [44.280047, 38.677294],
          [44.280444, 38.677018],
          [44.281041, 38.676809],
          [44.281845, 38.676315],
          [44.282397, 38.675668],
          [44.282641, 38.674969],
          [44.282596, 38.674242],
          [44.282321, 38.673554],
          [44.281219, 38.672629],
          [44.280806, 38.671291],
          [44.280102, 38.669623],
          [44.27993, 38.668908],
          [44.279898, 38.66803],
          [44.279992, 38.667301],
          [44.280239, 38.666761],
          [44.280885, 38.665696],
          [44.281389, 38.664474],
          [44.281571, 38.663031],
          [44.281521, 38.662423],
          [44.280959, 38.661414],
          [44.279602, 38.660225],
          [44.279401, 38.659589],
          [44.27949, 38.65862],
          [44.27946, 38.657628],
          [44.279261, 38.657191],
          [44.278628, 38.656678],
          [44.277831, 38.656334],
          [44.276499, 38.655987],
          [44.275108, 38.655692],
          [44.273525, 38.655561],
          [44.272863, 38.655409],
          [44.27235, 38.654967],
          [44.271532, 38.653806],
          [44.270465, 38.65271],
          [44.269603, 38.651424],
          [44.269313, 38.650853],
          [44.269444, 38.650271],
          [44.269729, 38.649903],
          [44.27028, 38.649554],
          [44.270875, 38.64902],
          [44.271302, 38.64825],
          [44.271718, 38.647013],
          [44.272119, 38.646248],
          [44.272662, 38.645662],
          [44.273809, 38.645216],
          [44.275132, 38.644842],
          [44.276263, 38.64424],
          [44.27748, 38.643455],
          [44.279193, 38.642692],
          [44.281401, 38.641539],
          [44.282864, 38.641099],
          [44.283704, 38.641123],
          [44.284309, 38.641231],
          [44.284821, 38.64123],
          [44.285732, 38.641008],
          [44.286655, 38.640586],
          [44.287565, 38.640264],
          [44.287971, 38.640214],
          [44.290125, 38.640494],
          [44.291098, 38.640494],
          [44.291749, 38.64034],
          [44.292382, 38.639902],
          [44.293349, 38.6389],
          [44.294, 38.638641],
          [44.294876, 38.638477],
          [44.296526, 38.638346],
          [44.299581, 38.637656],
          [44.3008, 38.637532],
          [44.301457, 38.637518],
          [44.302261, 38.637357],
          [44.303083, 38.637059],
          [44.303591, 38.636716],
          [44.30408, 38.636556],
          [44.305548, 38.636321],
          [44.306814, 38.635795],
          [44.307679, 38.635261],
          [44.308324, 38.634588],
          [44.311039, 38.632604],
          [44.311589, 38.631695],
          [44.311662, 38.63132],
          [44.311663, 38.630685],
          [44.311784, 38.630308],
          [44.312029, 38.630106],
          [44.31412, 38.629082],
          [44.315306, 38.628572],
          [44.316212, 38.628059],
          [44.317338, 38.627647],
          [44.317975, 38.627148],
          [44.31903, 38.625868],
          [44.319414, 38.625581],
          [44.320371, 38.625146],
          [44.321269, 38.624642],
          [44.32162, 38.624092],
          [44.321589, 38.623407],
          [44.321459, 38.622661],
          [44.320861, 38.620318],
          [44.320559, 38.619506],
          [44.320555, 38.618999],
          [44.321979, 38.615877],
          [44.321915, 38.615071],
          [44.321631, 38.613635],
          [44.32164, 38.613076],
          [44.321957, 38.612411],
          [44.322221, 38.611645],
          [44.322376, 38.610887],
          [44.322387, 38.610265],
          [44.322274, 38.6096],
          [44.322166, 38.609226],
          [44.321936, 38.609071],
          [44.321603, 38.608929],
          [44.321348, 38.608458],
          [44.320943, 38.608272],
          [44.319727, 38.607968],
          [44.318657, 38.607867],
          [44.318083, 38.607572],
          [44.317093, 38.607153],
          [44.316717, 38.606843],
          [44.31678, 38.606334],
          [44.317038, 38.606059],
          [44.31832, 38.605627],
          [44.319023, 38.605177],
          [44.319361, 38.604904],
          [44.319518, 38.604604],
          [44.319924, 38.603486],
          [44.32013, 38.602292],
          [44.320389, 38.601724],
          [44.320678, 38.600806],
          [44.320673, 38.600584],
          [44.320144, 38.600013],
          [44.319828, 38.599838],
          [44.318011, 38.59917],
          [44.317704, 38.59898],
          [44.317639, 38.598657],
          [44.317856, 38.598097],
          [44.318382, 38.597653],
          [44.318783, 38.597177],
          [44.319057, 38.596514],
          [44.319744, 38.595548],
          [44.32047, 38.594694],
          [44.320895, 38.59434],
          [44.321607, 38.594058],
          [44.321783, 38.593735],
          [44.321764, 38.593222],
          [44.321545, 38.592373],
          [44.321688, 38.591819],
          [44.3228, 38.589305],
          [44.322802, 38.588759],
          [44.322699, 38.588424],
          [44.322334, 38.587995],
          [44.321968, 38.58741],
          [44.321602, 38.586478],
          [44.321542, 38.586121],
          [44.321582, 38.585708],
          [44.321874, 38.58525],
          [44.323968, 38.5832],
          [44.324314, 38.58276],
          [44.324599, 38.582095],
          [44.32477, 38.580626],
          [44.324531, 38.578941],
          [44.324626, 38.578436],
          [44.324944, 38.577896],
          [44.324945, 38.577487],
          [44.324656, 38.577086],
          [44.323903, 38.576599],
          [44.323179, 38.576324],
          [44.321598, 38.575455],
          [44.320078, 38.574894],
          [44.318292, 38.574584],
          [44.317401, 38.574317],
          [44.317203, 38.574049],
          [44.316894, 38.572085],
          [44.31685, 38.570233],
          [44.316467, 38.568741],
          [44.316248, 38.568065],
          [44.315913, 38.567348],
          [44.315384, 38.565412],
          [44.315083, 38.564859],
          [44.314339, 38.564374],
          [44.314118, 38.564029],
          [44.313855, 38.563408],
          [44.313236, 38.562754],
          [44.312791, 38.561721],
          [44.312813, 38.561349],
          [44.313079, 38.560068],
          [44.313122, 38.559139],
          [44.31337, 38.558497],
          [44.313426, 38.557897],
          [44.313248, 38.556712],
          [44.313299, 38.555698],
          [44.313443, 38.555145],
          [44.313749, 38.554469],
          [44.314304, 38.553737],
          [44.315451, 38.552782],
          [44.316088, 38.552356],
          [44.316408, 38.552056],
          [44.316485, 38.551862],
          [44.316211, 38.550867],
          [44.31631, 38.54969],
          [44.316309, 38.549035],
          [44.316243, 38.548513],
          [44.316112, 38.548083],
          [44.315888, 38.547859],
          [44.315654, 38.547723],
          [44.315195, 38.547629],
          [44.314558, 38.547096],
          [44.314108, 38.546976],
          [44.31363, 38.546724],
          [44.313251, 38.546321],
          [44.313096, 38.545836],
          [44.313181, 38.545322],
          [44.313361, 38.544998],
          [44.313355, 38.543526],
          [44.313486, 38.542206],
          [44.31327, 38.541321],
          [44.31302, 38.539045],
          [44.312778, 38.538756],
          [44.312471, 38.538558],
          [44.311029, 38.537912],
          [44.310719, 38.537665],
          [44.310395, 38.537234],
          [44.310126, 38.536548],
          [44.31014, 38.536123],
          [44.310225, 38.535784],
          [44.31071, 38.535009],
          [44.310752, 38.534613],
          [44.310562, 38.533776],
          [44.310595, 38.533349],
          [44.310744, 38.532803],
          [44.310551, 38.532257],
          [44.310259, 38.531698],
          [44.309438, 38.530846],
          [44.309336, 38.530246],
          [44.309459, 38.52974],
          [44.309824, 38.529168],
          [44.310283, 38.52876],
          [44.310546, 38.528295],
          [44.310507, 38.527946],
          [44.310334, 38.52737],
          [44.310386, 38.526937],
          [44.311014, 38.526156],
          [44.311417, 38.525485],
          [44.311631, 38.525275],
          [44.31178, 38.524966],
          [44.311889, 38.52462],
          [44.311795, 38.524145],
          [44.311844, 38.523868],
          [44.311993, 38.523652],
          [44.312888, 38.523301],
          [44.313206, 38.522954],
          [44.313266, 38.52229],
          [44.313025, 38.521992],
          [44.312833, 38.521856],
          [44.312771, 38.521608],
          [44.313165, 38.519943],
          [44.313692, 38.519738],
          [44.314048, 38.519318],
          [44.314573, 38.518934],
          [44.314728, 38.51861],
          [44.314808, 38.51808],
          [44.314535, 38.517044],
          [44.314199, 38.516682],
          [44.313463, 38.516139],
          [44.313327, 38.5155],
          [44.313367, 38.515092],
          [44.313669, 38.514793],
          [44.314128, 38.514574],
          [44.314859, 38.514474],
          [44.31589, 38.514403],
          [44.316667, 38.514274],
          [44.317362, 38.5138],
          [44.317823, 38.51331],
          [44.318323, 38.512674],
          [44.318555, 38.512519],
          [44.319451, 38.512592],
          [44.319708, 38.512504],
          [44.319851, 38.512345],
          [44.319816, 38.51188],
          [44.320259, 38.511603],
          [44.32073, 38.511192],
          [44.32099, 38.510585],
          [44.321504, 38.50963],
          [44.321083, 38.509037],
          [44.320985, 38.508229],
          [44.321041, 38.507791],
          [44.32151, 38.506583],
          [44.322221, 38.505245],
          [44.322417, 38.50467],
          [44.32357, 38.503892],
          [44.324668, 38.503288],
          [44.325494, 38.502399],
          [44.32546, 38.502081],
          [44.323876, 38.499382],
          [44.323296, 38.49882],
          [44.322035, 38.498388],
          [44.32148, 38.497962],
          [44.32105, 38.497534],
          [44.319926, 38.496716],
          [44.319876, 38.496189],
          [44.319781, 38.49482],
          [44.319784, 38.494238],
          [44.319889, 38.493556],
          [44.3198, 38.493091],
          [44.319511, 38.492572],
          [44.319462, 38.491558],
          [44.320209, 38.491232],
          [44.321289, 38.48986],
          [44.321553, 38.489625],
          [44.322056, 38.489375],
          [44.322162, 38.489227],
          [44.322071, 38.488615],
          [44.322078, 38.488233],
          [44.322235, 38.487756],
          [44.322714, 38.487393],
          [44.322777, 38.486988],
          [44.32276, 38.486268],
          [44.322972, 38.48569],
          [44.322861, 38.484784],
          [44.32266, 38.484516],
          [44.322375, 38.48437],
          [44.322735, 38.483894],
          [44.322712, 38.483683],
          [44.322618, 38.483588],
          [44.322599, 38.483336],
          [44.322662, 38.483159],
          [44.32225, 38.482681],
          [44.322104, 38.482684],
          [44.321973, 38.482598],
          [44.320926, 38.482658],
          [44.320331, 38.482806],
          [44.319748, 38.482416],
          [44.319351, 38.482332],
          [44.319127, 38.481853],
          [44.318979, 38.481719],
          [44.318539, 38.481546],
          [44.318357, 38.481618],
          [44.318217, 38.48144],
          [44.318193, 38.481303],
          [44.318015, 38.481001],
          [44.317425, 38.480835],
          [44.317384, 38.480754],
          [44.317419, 38.480561],
          [44.316949, 38.480319],
          [44.316794, 38.480136],
          [44.316362, 38.47988],
          [44.315545, 38.479664],
          [44.315437, 38.479452],
          [44.315025, 38.479372],
          [44.314596, 38.479185],
          [44.314056, 38.479027],
          [44.313939, 38.478866],
          [44.313674, 38.478726],
          [44.3136, 38.478344],
          [44.312893, 38.477385],
          [44.312634, 38.477191],
          [44.312534, 38.477032],
          [44.311891, 38.476416],
          [44.311695, 38.476347],
          [44.311681, 38.476249],
          [44.311861, 38.476025],
          [44.311894, 38.475892],
          [44.311605, 38.475562],
          [44.3119, 38.475044],
          [44.312056, 38.474327],
          [44.312172, 38.474162],
          [44.31292, 38.473587],
          [44.312988, 38.473273],
          [44.313257, 38.472869],
          [44.313277, 38.470496],
          [44.313708, 38.466132],
          [44.313701, 38.465112],
          [44.313259, 38.463317],
          [44.312498, 38.461789],
          [44.311049, 38.460424],
          [44.309875, 38.458954],
          [44.309446, 38.457697],
          [44.309316, 38.45686],
          [44.309327, 38.456349],
          [44.309653, 38.455503],
          [44.309709, 38.454662],
          [44.309279, 38.452836],
          [44.309081, 38.452563],
          [44.308845, 38.451568],
          [44.308896, 38.450876],
          [44.308439, 38.450689],
          [44.307155, 38.450371],
          [44.305217, 38.449457],
          [44.304007, 38.448607],
          [44.30251, 38.447769],
          [44.301938, 38.447229],
          [44.302346, 38.446695],
          [44.303337, 38.445339],
          [44.304064, 38.444262],
          [44.304657, 38.443631],
          [44.305058, 38.443323],
          [44.305141, 38.443096],
          [44.304918, 38.441738],
          [44.305033, 38.4413],
          [44.30527, 38.440962],
          [44.30577, 38.440743],
          [44.306433, 38.44053],
          [44.307233, 38.440048],
          [44.307999, 38.439684],
          [44.308654, 38.439204],
          [44.308945, 38.43871],
          [44.309164, 38.437974],
          [44.309544, 38.437453],
          [44.309869, 38.43731],
          [44.310248, 38.437298],
          [44.310987, 38.437451],
          [44.311309, 38.437429],
          [44.312006, 38.437061],
          [44.312497, 38.436739],
          [44.312686, 38.436362],
          [44.312634, 38.43502],
          [44.312796, 38.434632],
          [44.31306, 38.434306],
          [44.313721, 38.434128],
          [44.31464, 38.433972],
          [44.315593, 38.433652],
          [44.316878, 38.433066],
          [44.317281, 38.432765],
          [44.317457, 38.432404],
          [44.317277, 38.430521],
          [44.316863, 38.429948],
          [44.316229, 38.429397],
          [44.316102, 38.429072],
          [44.316128, 38.428749],
          [44.316362, 38.428455],
          [44.317583, 38.427324],
          [44.317733, 38.426952],
          [44.317663, 38.426569],
          [44.317134, 38.426044],
          [44.316499, 38.42522],
          [44.316388, 38.424621],
          [44.316716, 38.423994],
          [44.31694, 38.423349],
          [44.317044, 38.422136],
          [44.317059, 38.421103],
          [44.316995, 38.420486],
          [44.316449, 38.419821],
          [44.316053, 38.418907],
          [44.316451, 38.418279],
          [44.317272, 38.41792],
          [44.317872, 38.417333],
          [44.318118, 38.416379],
          [44.318281, 38.415052],
          [44.318112, 38.414286],
          [44.316994, 38.41324],
          [44.31723, 38.412424],
          [44.317338, 38.411838],
          [44.318957, 38.409598],
          [44.319729, 38.408868],
          [44.319832, 38.408606],
          [44.319541, 38.408117],
          [44.318074, 38.407584],
          [44.316368, 38.40669],
          [44.314852, 38.40569],
          [44.313849, 38.404473],
          [44.312678, 38.401666],
          [44.311971, 38.401208],
          [44.311551, 38.400648],
          [44.311382, 38.399857],
          [44.311476, 38.39903],
          [44.311778, 38.398283],
          [44.311809, 38.397874],
          [44.311604, 38.397099],
          [44.3112, 38.396252],
          [44.31097, 38.395468],
          [44.310732, 38.395206],
          [44.310518, 38.395371],
          [44.310048, 38.395345],
          [44.309332, 38.395363],
          [44.308413, 38.395636],
          [44.307831, 38.395251],
          [44.30788, 38.39559],
          [44.307703, 38.395807],
          [44.307359, 38.39586],
          [44.30706, 38.395914],
          [44.306518, 38.395469],
          [44.306105, 38.39561],
          [44.305918, 38.395319],
          [44.305377, 38.395179],
          [44.305444, 38.394958],
          [44.304825, 38.395186],
          [44.304228, 38.394973],
          [44.304043, 38.394568],
          [44.303205, 38.394765],
          [44.303018, 38.39504],
          [44.302493, 38.395056],
          [44.301766, 38.394714],
          [44.300998, 38.39459],
          [44.300769, 38.394457],
          [44.300113, 38.394554],
          [44.300008, 38.394378],
          [44.299987, 38.394168],
          [44.300528, 38.393806],
          [44.302531, 38.393148],
          [44.303673, 38.392558],
          [44.30507, 38.390945],
          [44.30574, 38.390272],
          [44.306009, 38.389597],
          [44.306609, 38.38885],
          [44.307446, 38.388215],
          [44.308242, 38.387268],
          [44.308669, 38.386356],
          [44.309065, 38.384721],
          [44.309348, 38.383807],
          [44.309646, 38.382388],
          [44.311592, 38.381476],
          [44.311419, 38.380584],
          [44.311983, 38.379299],
          [44.313432, 38.376815],
          [44.314126, 38.37676],
          [44.314802, 38.376633],
          [44.315316, 38.375858],
          [44.315836, 38.37513],
          [44.316398, 38.374255],
          [44.316985, 38.373994],
          [44.318373, 38.373522],
          [44.319436, 38.372842],
          [44.320562, 38.372386],
          [44.321521, 38.372195],
          [44.322242, 38.371889],
          [44.322846, 38.371368],
          [44.323659, 38.371187],
          [44.324566, 38.371061],
          [44.325598, 38.370791],
          [44.327439, 38.370465],
          [44.327758, 38.370265],
          [44.328642, 38.369541],
          [44.328921, 38.369338],
          [44.330016, 38.369103],
          [44.330539, 38.369134],
          [44.331243, 38.369305],
          [44.332283, 38.369331],
          [44.333179, 38.369248],
          [44.334191, 38.369066],
          [44.334491, 38.369066],
          [44.335919, 38.369376],
          [44.336355, 38.369891],
          [44.33683, 38.370345],
          [44.337372, 38.37045],
          [44.337895, 38.370401],
          [44.338164, 38.370487],
          [44.338621, 38.370895],
          [44.339265, 38.371297],
          [44.340002, 38.37165],
          [44.341003, 38.37156],
          [44.34172, 38.37161],
          [44.342452, 38.371899],
          [44.343645, 38.371559],
          [44.346021, 38.370513],
          [44.347158, 38.37026],
          [44.348248, 38.37019],
          [44.349026, 38.369911],
          [44.35115, 38.369168],
          [44.351384, 38.369668],
          [44.351919, 38.370219],
          [44.352505, 38.370595],
          [44.353356, 38.37174],
          [44.353419, 38.372089],
          [44.353584, 38.372274],
          [44.355101, 38.374083],
          [44.355694, 38.374755],
          [44.356265, 38.375065],
          [44.357739, 38.375306],
          [44.358863, 38.375402],
          [44.36018, 38.375426],
          [44.361265, 38.375354],
          [44.361928, 38.375256],
          [44.362504, 38.375017],
          [44.363362, 38.374452],
          [44.363997, 38.373829],
          [44.36427, 38.3731],
          [44.364199, 38.372544],
          [44.364401, 38.37174],
          [44.364612, 38.370278],
          [44.36517, 38.369771],
          [44.367248, 38.368743],
          [44.367852, 38.368708],
          [44.368548, 38.368899],
          [44.3691, 38.368877],
          [44.369824, 38.368082],
          [44.369992, 38.367723],
          [44.370307, 38.366681],
          [44.371817, 38.363994],
          [44.372537, 38.363],
          [44.373577, 38.362004],
          [44.374784, 38.360642],
          [44.375633, 38.359994],
          [44.376431, 38.359532],
          [44.377249, 38.35928],
          [44.378084, 38.359616],
          [44.378895, 38.358908],
          [44.379645, 38.358592],
          [44.380653, 38.35857],
          [44.381803, 38.358609],
          [44.382935, 38.358883],
          [44.383699, 38.359184],
          [44.384628, 38.359648],
          [44.385569, 38.360313],
          [44.386451, 38.361304],
          [44.387716, 38.362447],
          [44.389871, 38.36365],
          [44.391478, 38.364384],
          [44.392027, 38.364529],
          [44.393479, 38.364451],
          [44.394489, 38.364552],
          [44.395167, 38.364721],
          [44.397366, 38.366012],
          [44.398336, 38.366357],
          [44.399217, 38.366834],
          [44.399845, 38.367268],
          [44.401588, 38.366162],
          [44.402158, 38.365917],
          [44.403043, 38.365869],
          [44.404221, 38.365962],
          [44.405034, 38.366136],
          [44.406342, 38.366492],
          [44.407277, 38.366805],
          [44.409046, 38.366819],
          [44.409813, 38.367008],
          [44.410486, 38.367454],
          [44.411063, 38.368013],
          [44.41259, 38.368883],
          [44.413402, 38.369088],
          [44.415368, 38.369131],
          [44.416117, 38.3692],
          [44.416887, 38.369571],
          [44.417249, 38.370148],
          [44.41808, 38.370537],
          [44.419328, 38.370868],
          [44.421271, 38.371678],
          [44.422757, 38.372735],
          [44.424371, 38.374074],
          [44.425361, 38.374508],
          [44.428045, 38.375137],
          [44.428678, 38.375396],
          [44.429446, 38.375977],
          [44.429837, 38.376402],
          [44.43053, 38.377001],
          [44.43144, 38.377597],
          [44.432663, 38.378197],
          [44.433113, 38.378489],
          [44.43366, 38.378729],
          [44.434135, 38.379003],
          [44.434681, 38.379442],
          [44.436527, 38.381111],
          [44.438033, 38.382321],
          [44.439595, 38.383467],
          [44.440663, 38.383875],
          [44.442045, 38.384205],
          [44.44358, 38.384668],
          [44.446633, 38.385278],
          [44.449371, 38.385492],
          [44.44962, 38.385012],
          [44.449813, 38.384345],
          [44.450002, 38.382492],
          [44.450371, 38.381099],
          [44.45113, 38.379699],
          [44.451591, 38.378635],
          [44.452572, 38.377459],
          [44.453234, 38.376833],
          [44.454867, 38.375711],
          [44.455876, 38.374669],
          [44.456494, 38.373913],
          [44.457693, 38.372593],
          [44.457913, 38.371747],
          [44.458067, 38.36941],
          [44.458436, 38.369034],
          [44.459556, 38.368728],
          [44.460356, 38.367436],
          [44.461176, 38.367078],
          [44.461904, 38.365876],
          [44.462322, 38.365729],
          [44.462557, 38.365334],
          [44.46262, 38.363148],
          [44.462406, 38.362878],
          [44.46235, 38.359769],
          [44.46272, 38.359407],
          [44.463526, 38.359166],
          [44.465172, 38.358972],
          [44.465971, 38.358948],
          [44.466647, 38.358878],
          [44.467544, 38.358666],
          [44.468208, 38.358363],
          [44.47061, 38.357269],
          [44.471264, 38.356885],
          [44.472397, 38.355781],
          [44.472778, 38.355601],
          [44.473208, 38.355685],
          [44.473666, 38.355876],
          [44.474052, 38.356148],
          [44.474865, 38.357011],
          [44.477057, 38.357663],
          [44.477772, 38.357697],
          [44.478283, 38.357807],
          [44.478827, 38.357792],
          [44.479412, 38.357665],
          [44.480519, 38.357778],
          [44.48147, 38.357804],
          [44.482761, 38.357772],
          [44.483145, 38.357859],
          [44.483702, 38.358188],
          [44.484146, 38.358282],
          [44.484858, 38.358324],
          [44.485496, 38.358446],
          [44.486106, 38.358435],
          [44.489419, 38.358044],
          [44.490448, 38.357865],
          [44.490751, 38.357705],
          [44.490731, 38.356835],
          [44.490641, 38.356333],
          [44.490835, 38.356116],
          [44.491474, 38.355767],
          [44.492167, 38.355189],
          [44.492694, 38.354653],
          [44.493527, 38.354222],
          [44.494532, 38.353824],
          [44.495357, 38.353346],
          [44.496856, 38.352404],
          [44.499019, 38.350906],
          [44.499122, 38.350532],
          [44.498907, 38.350099],
          [44.497546, 38.349419],
          [44.496145, 38.3489],
          [44.495171, 38.348154],
          [44.494496, 38.347817],
          [44.49363, 38.347523],
          [44.493341, 38.347358],
          [44.493121, 38.34687],
          [44.492963, 38.34634],
          [44.492992, 38.345961],
          [44.493137, 38.345703],
          [44.49375, 38.345184],
          [44.494641, 38.344538],
          [44.495497, 38.343712],
          [44.495827, 38.343588],
          [44.496117, 38.343586],
          [44.496549, 38.34374],
          [44.49782, 38.34443],
          [44.498033, 38.344657],
          [44.498305, 38.345066],
          [44.498522, 38.345541],
          [44.498874, 38.345785],
          [44.499599, 38.345922],
          [44.500209, 38.345921],
          [44.501072, 38.345704],
          [44.501424, 38.345673],
          [44.501677, 38.345703],
          [44.502039, 38.345872],
          [44.502397, 38.345886],
          [44.503473, 38.345759],
          [44.505917, 38.345923],
          [44.506931, 38.346089],
          [44.50739, 38.345967],
          [44.508233, 38.345214],
          [44.509102, 38.345228],
          [44.510058, 38.345175],
          [44.510353, 38.344748],
          [44.510684, 38.343295],
          [44.511047, 38.342469],
          [44.511387, 38.34199],
          [44.51206, 38.341489],
          [44.512531, 38.34126],
          [44.512547, 38.340875],
          [44.512475, 38.340432],
          [44.51222, 38.339928],
          [44.51178, 38.339374],
          [44.511215, 38.33885],
          [44.51076, 38.338595],
          [44.510452, 38.338492],
          [44.510378, 38.338364],
          [44.510336, 38.338064],
          [44.510586, 38.336097],
          [44.510538, 38.335745],
          [44.510361, 38.335341],
          [44.510003, 38.335215],
          [44.509145, 38.335064],
          [44.508245, 38.335043],
          [44.507692, 38.335119],
          [44.506814, 38.33498],
          [44.506102, 38.334783],
          [44.504838, 38.334649],
          [44.502595, 38.33476],
          [44.502011, 38.334733],
          [44.501459, 38.334566],
          [44.50099, 38.334338],
          [44.500778, 38.334085],
          [44.500308, 38.33388],
          [44.499647, 38.3338],
          [44.497551, 38.333712],
          [44.497376, 38.333092],
          [44.497588, 38.3321],
          [44.497577, 38.331765],
          [44.497427, 38.33139],
          [44.497168, 38.330981],
          [44.496897, 38.33008],
          [44.496986, 38.329542],
          [44.49717, 38.329108],
          [44.497617, 38.328425],
          [44.497735, 38.328096],
          [44.497752, 38.327661],
          [44.497835, 38.327329],
          [44.498073, 38.327037],
          [44.498194, 38.326779],
          [44.49813, 38.32594],
          [44.4982, 38.325708],
          [44.499047, 38.3249],
          [44.499243, 38.324566],
          [44.499361, 38.323986],
          [44.499422, 38.323245],
          [44.499343, 38.322563],
          [44.499134, 38.321843],
          [44.498585, 38.320925],
          [44.498443, 38.320597],
          [44.498412, 38.320177],
          [44.498332, 38.319939],
          [44.498027, 38.319619],
          [44.497573, 38.319293],
          [44.497287, 38.318876],
          [44.496729, 38.317745],
          [44.496467, 38.317509],
          [44.496405, 38.317298],
          [44.497255, 38.315548],
          [44.497549, 38.314725],
          [44.497556, 38.314091],
          [44.497444, 38.313302],
          [44.497583, 38.313075],
          [44.498694, 38.312329],
          [44.498886, 38.311967],
          [44.498862, 38.311715],
          [44.498551, 38.310859],
          [44.498412, 38.310102],
          [44.49845, 38.309436],
          [44.498334, 38.308938],
          [44.497925, 38.308486],
          [44.496029, 38.306803],
          [44.495688, 38.3062],
          [44.494929, 38.305357],
          [44.494019, 38.304875],
          [44.493489, 38.304411],
          [44.492951, 38.303696],
          [44.492569, 38.303404],
          [44.491549, 38.303175],
          [44.490102, 38.302657],
          [44.488802, 38.302581],
          [44.487591, 38.302616],
          [44.486132, 38.302193],
          [44.485015, 38.30212],
          [44.48379, 38.302317],
          [44.482648, 38.302177],
          [44.481581, 38.302252],
          [44.48069, 38.302241],
          [44.480111, 38.302325],
          [44.479261, 38.302642],
          [44.478858, 38.302747],
          [44.478288, 38.302652],
          [44.47789, 38.302368],
          [44.47633, 38.300737],
          [44.475511, 38.299578],
          [44.47526, 38.299124],
          [44.475327, 38.298839],
          [44.475712, 38.298455],
          [44.475754, 38.298053],
          [44.475328, 38.297426],
          [44.474198, 38.296049],
          [44.473474, 38.295313],
          [44.472598, 38.294914],
          [44.471976, 38.294824],
          [44.469857, 38.294999],
          [44.469225, 38.294927],
          [44.468383, 38.2946],
          [44.467287, 38.293558],
          [44.466662, 38.292684],
          [44.466238, 38.29228],
          [44.463756, 38.291189],
          [44.462942, 38.290451],
          [44.459392, 38.288408],
          [44.458385, 38.28777],
          [44.457848, 38.287178],
          [44.456677, 38.285426],
          [44.455872, 38.284366],
          [44.455056, 38.28399],
          [44.452255, 38.28398],
          [44.450494, 38.28383],
          [44.449162, 38.283338],
          [44.447527, 38.28181],
          [44.446619, 38.281218],
          [44.445713, 38.280755],
          [44.444471, 38.280033],
          [44.441117, 38.27775],
          [44.440787, 38.277368],
          [44.439642, 38.275096],
          [44.434734, 38.269706],
          [44.433956, 38.269153],
          [44.43298, 38.268739],
          [44.431788, 38.268831],
          [44.429843, 38.269371],
          [44.428881, 38.269253],
          [44.426182, 38.268749],
          [44.423504, 38.268234],
          [44.422532, 38.268003],
          [44.422004, 38.267368],
          [44.420851, 38.266426],
          [44.419371, 38.265407],
          [44.417674, 38.264326],
          [44.415243, 38.26234],
          [44.414295, 38.261721],
          [44.413471, 38.2611],
          [44.413431, 38.259433],
          [44.413186, 38.258144],
          [44.412704, 38.257362],
          [44.411182, 38.25597],
          [44.408666, 38.254265],
          [44.40732, 38.253851],
          [44.405669, 38.253619],
          [44.404303, 38.253097],
          [44.403041, 38.252458],
          [44.402269, 38.252],
          [44.401781, 38.251035],
          [44.401733, 38.250513],
          [44.401874, 38.248904],
          [44.402238, 38.246442],
          [44.402026, 38.245917],
          [44.401109, 38.244566],
          [44.400516, 38.2441],
          [44.399844, 38.243791],
          [44.399422, 38.243523],
          [44.399016, 38.242942],
          [44.398908, 38.241515],
          [44.398663, 38.240731],
          [44.398452, 38.239596],
          [44.398682, 38.238535],
          [44.399062, 38.237666],
          [44.39915, 38.237089],
          [44.40047, 38.236113],
          [44.402362, 38.235035],
          [44.405316, 38.233645],
          [44.406002, 38.232566],
          [44.406729, 38.231049],
          [44.407185, 38.228898],
          [44.408176, 38.226752],
          [44.409506, 38.224884],
          [44.410279, 38.222989],
          [44.410295, 38.22105],
          [44.41174, 38.21951],
          [44.411609, 38.21881],
          [44.410712, 38.217963],
          [44.408027, 38.215946],
          [44.407643, 38.214202],
          [44.407212, 38.213094],
          [44.407292, 38.212547],
          [44.406835, 38.211468],
          [44.406636, 38.210684],
          [44.40501, 38.207507],
          [44.40238, 38.201994],
          [44.402175, 38.201701],
          [44.401969, 38.201643],
          [44.400925, 38.201826],
          [44.400081, 38.201848],
          [44.399796, 38.201748],
          [44.399379, 38.201316],
          [44.398858, 38.200467],
          [44.398421, 38.199531],
          [44.398025, 38.198464],
          [44.398286, 38.197797],
          [44.398197, 38.19706],
          [44.398648, 38.19682],
          [44.399303, 38.196687],
          [44.400146, 38.196131],
          [44.400879, 38.194572],
          [44.400804, 38.193736],
          [44.400145, 38.192665],
          [44.399464, 38.191795],
          [44.398398, 38.19104],
          [44.397841, 38.190526],
          [44.396911, 38.189168],
          [44.397813, 38.188471],
          [44.398473, 38.187778],
          [44.399426, 38.185598],
          [44.399736, 38.185001],
          [44.399856, 38.184194],
          [44.400314, 38.183199],
          [44.400128, 38.181952],
          [44.399936, 38.180943],
          [44.400152, 38.179683],
          [44.39962, 38.178285],
          [44.398527, 38.175615],
          [44.398643, 38.174736],
          [44.398391, 38.173487],
          [44.397218, 38.171554],
          [44.395556, 38.170993],
          [44.393336, 38.170351],
          [44.392326, 38.169343],
          [44.391183, 38.167728],
          [44.391169, 38.167224],
          [44.391361, 38.166427],
          [44.391543, 38.165268],
          [44.391915, 38.164683],
          [44.392911, 38.164208],
          [44.39336, 38.163794],
          [44.393145, 38.16288],
          [44.393329, 38.161967],
          [44.394049, 38.161619],
          [44.394616, 38.160992],
          [44.394711, 38.160115],
          [44.394442, 38.15911],
          [44.394272, 38.158714],
          [44.393685, 38.157806],
          [44.393045, 38.155241],
          [44.392758, 38.154422],
          [44.392246, 38.153811],
          [44.391866, 38.153218],
          [44.391664, 38.152028],
          [44.391846, 38.151294],
          [44.392276, 38.15042],
          [44.39249, 38.149599],
          [44.392279, 38.148481],
          [44.392315, 38.147651],
          [44.392385, 38.147232],
          [44.392693, 38.146443],
          [44.392419, 38.146097],
          [44.391451, 38.145739],
          [44.390302, 38.145763],
          [44.388589, 38.145474],
          [44.387408, 38.145152],
          [44.386204, 38.144613],
          [44.385543, 38.144425],
          [44.385024, 38.144162],
          [44.38244, 38.14132],
          [44.381141, 38.139447],
          [44.38054, 38.13906],
          [44.379921, 38.138903],
          [44.376416, 38.138282],
          [44.374865, 38.138124],
          [44.373828, 38.138094],
          [44.37282, 38.138286],
          [44.37169, 38.138681],
          [44.370884, 38.138748],
          [44.370457, 38.138554],
          [44.37019, 38.138214],
          [44.370098, 38.137593],
          [44.369682, 38.13639],
          [44.369124, 38.135887],
          [44.368625, 38.135684],
          [44.367845, 38.135688],
          [44.366987, 38.135787],
          [44.365619, 38.135757],
          [44.363607, 38.134869],
          [44.36293, 38.134645],
          [44.362144, 38.134574],
          [44.361295, 38.13468],
          [44.3606, 38.135151],
          [44.359358, 38.135632],
          [44.358556, 38.13573],
          [44.357768, 38.135583],
          [44.356802, 38.13503],
          [44.356066, 38.134732],
          [44.355501, 38.134749],
          [44.353474, 38.135283],
          [44.352583, 38.135377],
          [44.351587, 38.135275],
          [44.351181, 38.134994],
          [44.351062, 38.133881],
          [44.350759, 38.133212],
          [44.349513, 38.131684],
          [44.350313, 38.130575],
          [44.351583, 38.129639],
          [44.353443, 38.127867],
          [44.354869, 38.126245],
          [44.35817, 38.124473],
          [44.359168, 38.123602],
          [44.359617, 38.122858],
          [44.35959, 38.12172],
          [44.358852, 38.119616],
          [44.358613, 38.116746],
          [44.358759, 38.1143],
          [44.359525, 38.112117],
          [44.360138, 38.111124],
          [44.362287, 38.108658],
          [44.362936, 38.10742],
          [44.364082, 38.106462],
          [44.364805, 38.105583],
          [44.364823, 38.104653],
          [44.364509, 38.103909],
          [44.363872, 38.103623],
          [44.362179, 38.103642],
          [44.360255, 38.103798],
          [44.358178, 38.10427],
          [44.356245, 38.104828],
          [44.354967, 38.105091],
          [44.353708, 38.10512],
          [44.35177, 38.104842],
          [44.350812, 38.104459],
          [44.349855, 38.104137],
          [44.348472, 38.104041],
          [44.347579, 38.103479],
          [44.343796, 38.101694],
          [44.341497, 38.100534],
          [44.340457, 38.099933],
          [44.339197, 38.099368],
          [44.338038, 38.099047],
          [44.33634, 38.098743],
          [44.335773, 38.098349],
          [44.335369, 38.098214],
          [44.334748, 38.098166],
          [44.332904, 38.098595],
          [44.332296, 38.098804],
          [44.331633, 38.098917],
          [44.331114, 38.098917],
          [44.330539, 38.098805],
          [44.329867, 38.09858],
          [44.329365, 38.098353],
          [44.329274, 38.098155],
          [44.328916, 38.09768],
          [44.328785, 38.097075],
          [44.328811, 38.095957],
          [44.329323, 38.095287],
          [44.329762, 38.094352],
          [44.331109, 38.092099],
          [44.331406, 38.090754],
          [44.331391, 38.089777],
          [44.331153, 38.088726],
          [44.330887, 38.088248],
          [44.330051, 38.08755],
          [44.329749, 38.087176],
          [44.329565, 38.086485],
          [44.329186, 38.085764],
          [44.328833, 38.084117],
          [44.328393, 38.083289],
          [44.328266, 38.082843],
          [44.328373, 38.08233],
          [44.328922, 38.080763],
          [44.328944, 38.080435],
          [44.328719, 38.079735],
          [44.328763, 38.078937],
          [44.328577, 38.078629],
          [44.328216, 38.078281],
          [44.327478, 38.078022],
          [44.325845, 38.077776],
          [44.324489, 38.077908],
          [44.322795, 38.078238],
          [44.322366, 38.078445],
          [44.322067, 38.078655],
          [44.321833, 38.078702],
          [44.32158, 38.078638],
          [44.321157, 38.078306],
          [44.320152, 38.077251],
          [44.317748, 38.074563],
          [44.317219, 38.074124],
          [44.315787, 38.073671],
          [44.314274, 38.07338],
          [44.311915, 38.073112],
          [44.311362, 38.072894],
          [44.311213, 38.07256],
          [44.311073, 38.071785],
          [44.311127, 38.071398],
          [44.311645, 38.070735],
          [44.311692, 38.070374],
          [44.311505, 38.069898],
          [44.311505, 38.069537],
          [44.31188, 38.068944],
          [44.312088, 38.068481],
          [44.312443, 38.068118],
          [44.312778, 38.068069],
          [44.313572, 38.068133],
          [44.315155, 38.068439],
          [44.315693, 38.068771],
          [44.315861, 38.068819],
          [44.316473, 38.068733],
          [44.317337, 38.068746],
          [44.318456, 38.068941],
          [44.319532, 38.069034],
          [44.319994, 38.069029],
          [44.320007, 38.068876],
          [44.319676, 38.06859],
          [44.319402, 38.068114],
          [44.31936, 38.067475],
          [44.319404, 38.066754],
          [44.319658, 38.065895],
          [44.319784, 38.064713],
          [44.319973, 38.06436],
          [44.320455, 38.064071],
          [44.321083, 38.063951],
          [44.321212, 38.06377],
          [44.32111, 38.06329],
          [44.320629, 38.062692],
          [44.319727, 38.061971],
          [44.31911, 38.061885],
          [44.318403, 38.061985],
          [44.317777, 38.061897],
          [44.317257, 38.061657],
          [44.316713, 38.061162],
          [44.316121, 38.060832],
          [44.315448, 38.060678],
          [44.314887, 38.06061],
          [44.314524, 38.060442],
          [44.313915, 38.059621],
          [44.313161, 38.059213],
          [44.311772, 38.058646],
          [44.311368, 38.058421],
          [44.310674, 38.058213],
          [44.309202, 38.058056],
          [44.307434, 38.057474],
          [44.305311, 38.056604],
          [44.30384, 38.055806],
          [44.302624, 38.054744],
          [44.302497, 38.05452],
          [44.302829, 38.052871],
          [44.304193, 38.049797],
          [44.304595, 38.048425],
          [44.305121, 38.047647],
          [44.306278, 38.046696],
          [44.306677, 38.046561],
          [44.306937, 38.046537],
          [44.307737, 38.04666],
          [44.308222, 38.046587],
          [44.308731, 38.046492],
          [44.308865, 38.0463],
          [44.308566, 38.045439],
          [44.308395, 38.044632],
          [44.308014, 38.043681],
          [44.307108, 38.042812],
          [44.30636, 38.041987],
          [44.305896, 38.040404],
          [44.304213, 38.037413],
          [44.303857, 38.036662],
          [44.303321, 38.036173],
          [44.302804, 38.035556],
          [44.301768, 38.033317],
          [44.301323, 38.032535],
          [44.300779, 38.032011],
          [44.299651, 38.031294],
          [44.298244, 38.030747],
          [44.296595, 38.029225],
          [44.29586, 38.028687],
          [44.295006, 38.028165],
          [44.294284, 38.027821],
          [44.293303, 38.027488],
          [44.292012, 38.027283],
          [44.291169, 38.026578],
          [44.290395, 38.025843],
          [44.289882, 38.024907],
          [44.289042, 38.024294],
          [44.288593, 38.023136],
          [44.287829, 38.02202],
          [44.286738, 38.021176],
          [44.285627, 38.020731],
          [44.282792, 38.020986],
          [44.281768, 38.020966],
          [44.280075, 38.020125],
          [44.279246, 38.019487],
          [44.278322, 38.018571],
          [44.27756, 38.018187],
          [44.276214, 38.017135],
          [44.275347, 38.016835],
          [44.274505, 38.0161],
          [44.27425, 38.015579],
          [44.274302, 38.014777],
          [44.274103, 38.013997],
          [44.273713, 38.013019],
          [44.273549, 38.012188],
          [44.272693, 38.011805],
          [44.272208, 38.011133],
          [44.270864, 38.010492],
          [44.270171, 38.010068],
          [44.26985, 38.00931],
          [44.269172, 38.008225],
          [44.269303, 38.007641],
          [44.269288, 38.006829],
          [44.268754, 38.005422],
          [44.267748, 38.003079],
          [44.267512, 38.001955],
          [44.267223, 38.001043],
          [44.266832, 38.00004],
          [44.266703, 37.999131],
          [44.266765, 37.998628],
          [44.267065, 37.998183],
          [44.267469, 37.998112],
          [44.267523, 37.997638],
          [44.267795, 37.996651],
          [44.267956, 37.996461],
          [44.267878, 37.99559],
          [44.267699, 37.995269],
          [44.267668, 37.995013],
          [44.26783, 37.9948],
          [44.268422, 37.994389],
          [44.268378, 37.994204],
          [44.26808, 37.993711],
          [44.267595, 37.993259],
          [44.267149, 37.992705],
          [44.266461, 37.991627],
          [44.265573, 37.991192],
          [44.264966, 37.990826],
          [44.264486, 37.990415],
          [44.263649, 37.989944],
          [44.262637, 37.988958],
          [44.26195, 37.988761],
          [44.26158, 37.98859],
          [44.261425, 37.988193],
          [44.261534, 37.987856],
          [44.261536, 37.987503],
          [44.261101, 37.986877],
          [44.260643, 37.986657],
          [44.260423, 37.985924],
          [44.260309, 37.985295],
          [44.260766, 37.984016],
          [44.260674, 37.983687],
          [44.260461, 37.983576],
          [44.259771, 37.983537],
          [44.259421, 37.98338],
          [44.259137, 37.982824],
          [44.258768, 37.982312],
          [44.257722, 37.981628],
          [44.256494, 37.981077],
          [44.25589, 37.980569],
          [44.254971, 37.979671],
          [44.254392, 37.979477],
          [44.254388, 37.979081],
          [44.25506, 37.978442],
          [44.255436, 37.977986],
          [44.256089, 37.977653],
          [44.256306, 37.97736],
          [44.256543, 37.976948],
          [44.256603, 37.97673],
          [44.256309, 37.976208],
          [44.255767, 37.97559],
          [44.255336, 37.975222],
          [44.254524, 37.974822],
          [44.254012, 37.974621],
          [44.253519, 37.974584],
          [44.253195, 37.974493],
          [44.252559, 37.974049],
          [44.251833, 37.973951],
          [44.250922, 37.973666],
          [44.250479, 37.973293],
          [44.249893, 37.972753],
          [44.248759, 37.971294],
          [44.249341, 37.970243],
          [44.249098, 37.969681],
          [44.248317, 37.969164],
          [44.246653, 37.968818],
          [44.245734, 37.968236],
          [44.244969, 37.966688],
          [44.244241, 37.965682],
          [44.243559, 37.96511],
          [44.242683, 37.965071],
          [44.241539, 37.965409],
          [44.240713, 37.965056],
          [44.240574, 37.964342],
          [44.241341, 37.961172],
          [44.241326, 37.960743],
          [44.241123, 37.960258],
          [44.240674, 37.956581],
          [44.240605, 37.955524],
          [44.240891, 37.95495],
          [44.241705, 37.954571],
          [44.242288, 37.954081],
          [44.243506, 37.95189],
          [44.246719, 37.951328],
          [44.247723, 37.951374],
          [44.248654, 37.951036],
          [44.248783, 37.950407],
          [44.24856, 37.949841],
          [44.2486, 37.949327],
          [44.248879, 37.948902],
          [44.249197, 37.947788],
          [44.249647, 37.946757],
          [44.249532, 37.945926],
          [44.249804, 37.945449],
          [44.250869, 37.944211],
          [44.251232, 37.94337],
          [44.250928, 37.942538],
          [44.250235, 37.942231],
          [44.249301, 37.942044],
          [44.248914, 37.941691],
          [44.248476, 37.940615],
          [44.247966, 37.939885],
          [44.247707, 37.939257],
          [44.247649, 37.938576],
          [44.246967, 37.937728],
          [44.246013, 37.937097],
          [44.246131, 37.936191],
          [44.246015, 37.933993],
          [44.246227, 37.933246],
          [44.246179, 37.932016],
          [44.245905, 37.931396],
          [44.245485, 37.931023],
          [44.244657, 37.930796],
          [44.243894, 37.929705],
          [44.243385, 37.929099],
          [44.242764, 37.928903],
          [44.242409, 37.929031],
          [44.24211, 37.929068],
          [44.241628, 37.928982],
          [44.241292, 37.929199],
          [44.241014, 37.929236],
          [44.240108, 37.929108],
          [44.238766, 37.928478],
          [44.23788, 37.928224],
          [44.236353, 37.927616],
          [44.235119, 37.927262],
          [44.234637, 37.926954],
          [44.234506, 37.92653],
          [44.234437, 37.925284],
          [44.234532, 37.924669],
          [44.234559, 37.923476],
          [44.234783, 37.922966],
          [44.234749, 37.922277],
          [44.23455, 37.921323],
          [44.234448, 37.920362],
          [44.234315, 37.91992],
          [44.233853, 37.919474],
          [44.233325, 37.919107],
          [44.232304, 37.918847],
          [44.230995, 37.9186],
          [44.229828, 37.918152],
          [44.229383, 37.917807],
          [44.229173, 37.917185],
          [44.229306, 37.916526],
          [44.229603, 37.915945],
          [44.230375, 37.915239],
          [44.230758, 37.914717],
          [44.230923, 37.913728],
          [44.23089, 37.912352],
          [44.230558, 37.911562],
          [44.230051, 37.911139],
          [44.229257, 37.910906],
          [44.226455, 37.910368],
          [44.225931, 37.9089],
          [44.224575, 37.907648],
          [44.224317, 37.907006],
          [44.225318, 37.906424],
          [44.226004, 37.905873],
          [44.226955, 37.905266],
          [44.227828, 37.904929],
          [44.228722, 37.904237],
          [44.22927, 37.903633],
          [44.229554, 37.902625],
          [44.230292, 37.901824],
          [44.231837, 37.900934],
          [44.232465, 37.900243],
          [44.232131, 37.899605],
          [44.23117, 37.898997],
          [44.230508, 37.898125],
          [44.22932, 37.895471],
          [44.22731, 37.89368],
          [44.226392, 37.892734],
          [44.226014, 37.8921],
          [44.225767, 37.890994],
          [44.225381, 37.890411],
          [44.224747, 37.889814],
          [44.223612, 37.889522],
          [44.222905, 37.888761],
          [44.222746, 37.888239],
          [44.223292, 37.887742],
          [44.223903, 37.8875],
          [44.224736, 37.886879],
          [44.226461, 37.886421],
          [44.227405, 37.886124],
          [44.228391, 37.885635],
          [44.229718, 37.885398],
          [44.231099, 37.884976],
          [44.23236, 37.88448],
          [44.233628, 37.884235],
          [44.234363, 37.884293],
          [44.235243, 37.884646],
          [44.236374, 37.884844],
          [44.237422, 37.884289],
          [44.23855, 37.884133],
          [44.239066, 37.883757],
          [44.239654, 37.882994],
          [44.239768, 37.882601],
          [44.239651, 37.881972],
          [44.239717, 37.880744],
          [44.24086, 37.879764],
          [44.241947, 37.878592],
          [44.242494, 37.877585],
          [44.242793, 37.876884],
          [44.243527, 37.876334],
          [44.244161, 37.875757],
          [44.246158, 37.875049],
          [44.247388, 37.874999],
          [44.248023, 37.875198],
          [44.248878, 37.87528],
          [44.249923, 37.8756],
          [44.25056, 37.875437],
          [44.25172, 37.875781],
          [44.25173, 37.873737],
          [44.252799, 37.873286],
          [44.254683, 37.873308],
          [44.255814, 37.872816],
          [44.256441, 37.871789],
          [44.25652, 37.871252],
          [44.257899, 37.87005],
          [44.258924, 37.869737],
          [44.260756, 37.868861],
          [44.262833, 37.868103],
          [44.26343, 37.868105],
          [44.264176, 37.868341],
          [44.266128, 37.867922],
          [44.267398, 37.867305],
          [44.26802, 37.867248],
          [44.269775, 37.867866],
          [44.270109, 37.868659],
          [44.270696, 37.869216],
          [44.274006, 37.870336],
          [44.275415, 37.870536],
          [44.277165, 37.87102],
          [44.279789, 37.871445],
          [44.280352, 37.871691],
          [44.280773, 37.872421],
          [44.281196, 37.872497],
          [44.282059, 37.872462],
          [44.284491, 37.873108],
          [44.286245, 37.872696],
          [44.287477, 37.872538],
          [44.288795, 37.871891],
          [44.289745, 37.870923],
          [44.290577, 37.868837],
          [44.291167, 37.868126],
          [44.291615, 37.867404],
          [44.292904, 37.866908],
          [44.294648, 37.867035],
          [44.296402, 37.867245],
          [44.299298, 37.868209],
          [44.300742, 37.868487],
          [44.302395, 37.868686],
          [44.302969, 37.86873],
          [44.304034, 37.868134],
          [44.304717, 37.867932],
          [44.305527, 37.868165],
          [44.30718, 37.868787],
          [44.308747, 37.869526],
          [44.309933, 37.870252],
          [44.310759, 37.871133],
          [44.311387, 37.871934],
          [44.312305, 37.87257],
          [44.313084, 37.873446],
          [44.314354, 37.874237],
          [44.315303, 37.874908],
          [44.316416, 37.876016],
          [44.317025, 37.876489],
          [44.318231, 37.876885],
          [44.319337, 37.877084],
          [44.320798, 37.877007],
          [44.323395, 37.876422],
          [44.326728, 37.875765],
          [44.329557, 37.875496],
          [44.331565, 37.874912],
          [44.333808, 37.873271],
          [44.335958, 37.8709],
          [44.337557, 37.870003],
          [44.338434, 37.869766],
          [44.339429, 37.869919],
          [44.340965, 37.869796],
          [44.345516, 37.868443],
          [44.348765, 37.866679],
          [44.349965, 37.866909],
          [44.351602, 37.866553],
          [44.353246, 37.865607],
          [44.354748, 37.864938],
          [44.356193, 37.863748],
          [44.357121, 37.863318],
          [44.358504, 37.86331],
          [44.359372, 37.863115],
          [44.360047, 37.862772],
          [44.361184, 37.862866],
          [44.362658, 37.862513],
          [44.365237, 37.862477],
          [44.367856, 37.862722],
          [44.369019, 37.863285],
          [44.370369, 37.863426],
          [44.371299, 37.863883],
          [44.373486, 37.863918],
          [44.37465, 37.864023],
          [44.377581, 37.864094],
          [44.379302, 37.863812],
          [44.380838, 37.863426],
          [44.381955, 37.86325],
          [44.383164, 37.862652],
          [44.384188, 37.862476],
          [44.384793, 37.862511],
          [44.386282, 37.862722],
          [44.387445, 37.862722],
          [44.388189, 37.862511],
          [44.389074, 37.862054],
          [44.390795, 37.860683],
          [44.391307, 37.860085],
          [44.391633, 37.859557],
          [44.392051, 37.85903],
          [44.392842, 37.858573],
          [44.393168, 37.85808],
          [44.394099, 37.857342],
          [44.395913, 37.856674],
          [44.396704, 37.856252],
          [44.397821, 37.855795],
          [44.398426, 37.855197],
          [44.399394, 37.854733],
          [44.399775, 37.854388],
          [44.400101, 37.853931],
          [44.400892, 37.853403],
          [44.401543, 37.852911],
          [44.402288, 37.852348],
          [44.402986, 37.852173],
          [44.403172, 37.851715],
          [44.403451, 37.851223],
          [44.403637, 37.85052],
          [44.40373, 37.849922],
          [44.404009, 37.849219],
          [44.404335, 37.84841],
          [44.4048, 37.847425],
          [44.404986, 37.847003],
          [44.40508, 37.846265],
          [44.405033, 37.845667],
          [44.404986, 37.844999],
          [44.4049, 37.844781],
          [44.404707, 37.844295],
          [44.404614, 37.8431],
          [44.404521, 37.842467],
          [44.404335, 37.841553],
          [44.404242, 37.840955],
          [44.403777, 37.840287],
          [44.402846, 37.839794],
          [44.401776, 37.839232],
          [44.400752, 37.838774],
          [44.399589, 37.838388],
          [44.398379, 37.838071],
          [44.397542, 37.838106],
          [44.396797, 37.837895],
          [44.396146, 37.837227],
          [44.396006, 37.836453],
          [44.395681, 37.836067],
          [44.395541, 37.835574],
          [44.395122, 37.834801],
          [44.394983, 37.834238],
          [44.394331, 37.833359],
          [44.393587, 37.83262],
          [44.393447, 37.832058],
          [44.392935, 37.831319],
          [44.392548, 37.830149],
          [44.392567, 37.829051],
          [44.392417, 37.82847],
          [44.392219, 37.827882],
          [44.392416, 37.827369],
          [44.393126, 37.826894],
          [44.393425, 37.826511],
          [44.393508, 37.826141],
          [44.393421, 37.825603],
          [44.393392, 37.825024],
          [44.393268, 37.824571],
          [44.393462, 37.82405],
          [44.394447, 37.823061],
          [44.39563, 37.822131],
          [44.396947, 37.820954],
          [44.397482, 37.820565],
          [44.398923, 37.820604],
          [44.400435, 37.820437],
          [44.401686, 37.819901],
          [44.402515, 37.819761],
          [44.404309, 37.819835],
          [44.405633, 37.819529],
          [44.40662, 37.819287],
          [44.407885, 37.818539],
          [44.409222, 37.818143],
          [44.410762, 37.81726],
          [44.412215, 37.816857],
          [44.413384, 37.816331],
          [44.414133, 37.816057],
          [44.414802, 37.81564],
          [44.415259, 37.814641],
          [44.415518, 37.81315],
          [44.415525, 37.811971],
          [44.415366, 37.810563],
          [44.4159, 37.809489],
          [44.416352, 37.808569],
          [44.417158, 37.808107],
          [44.417754, 37.807485],
          [44.418133, 37.806786],
          [44.417879, 37.806147],
          [44.418088, 37.805829],
          [44.418715, 37.805507],
          [44.420041, 37.805142],
          [44.421153, 37.804696],
          [44.421571, 37.804371],
          [44.422668, 37.803047],
          [44.42287, 37.801589],
          [44.423213, 37.800509],
          [44.423753, 37.799449],
          [44.426757, 37.798555],
          [44.429751, 37.798717],
          [44.434164, 37.799634],
          [44.435289, 37.800081],
          [44.436088, 37.800841],
          [44.437548, 37.80121],
          [44.438989, 37.801997],
          [44.440304, 37.802645],
          [44.441826, 37.803552],
          [44.443075, 37.804121],
          [44.443992, 37.804453],
          [44.44512, 37.804718],
          [44.446124, 37.805178],
          [44.44699, 37.806051],
          [44.447667, 37.806495],
          [44.448377, 37.806585],
          [44.44852, 37.805929],
          [44.448558, 37.805392],
          [44.448472, 37.804669],
          [44.448137, 37.803652],
          [44.447599, 37.801504],
          [44.447482, 37.800136],
          [44.447525, 37.798655],
          [44.447873, 37.797123],
          [44.448095, 37.795533],
          [44.448154, 37.793026],
          [44.448759, 37.79105],
          [44.449428, 37.789167],
          [44.449934, 37.787545],
          [44.449941, 37.785845],
          [44.448844, 37.782686],
          [44.448512, 37.782364],
          [44.447617, 37.782017],
          [44.446352, 37.780451],
          [44.445648, 37.7797],
          [44.444936, 37.779033],
          [44.443968, 37.777724],
          [44.443425, 37.776715],
          [44.443134, 37.774662],
          [44.443249, 37.773831],
          [44.44375, 37.773121],
          [44.444561, 37.771355],
          [44.444951, 37.770917],
          [44.446405, 37.770397],
          [44.447387, 37.77031],
          [44.449186, 37.76979],
          [44.449798, 37.76942],
          [44.450112, 37.76904],
          [44.450382, 37.76851],
          [44.450803, 37.767866],
          [44.451208, 37.767497],
          [44.451563, 37.76734],
          [44.452483, 37.766687],
          [44.453125, 37.766692],
          [44.453593, 37.766511],
          [44.454766, 37.766857],
          [44.455973, 37.766925],
          [44.458023, 37.767135],
          [44.459527, 37.766594],
          [44.460685, 37.766022],
          [44.462316, 37.765745],
          [44.463369, 37.765428],
          [44.464705, 37.765632],
          [44.465047, 37.765472],
          [44.465663, 37.765338],
          [44.466455, 37.76548],
          [44.466818, 37.765702],
          [44.468173, 37.764916],
          [44.469634, 37.7645],
          [44.471106, 37.764426],
          [44.471548, 37.764278],
          [44.471978, 37.763929],
          [44.472347, 37.763813],
          [44.472724, 37.763806],
          [44.473652, 37.764568],
          [44.473996, 37.764917],
          [44.474195, 37.765261],
          [44.474216, 37.765689],
          [44.47447, 37.76638],
          [44.475495, 37.767345],
          [44.476181, 37.76843],
          [44.476648, 37.769467],
          [44.477435, 37.770373],
          [44.47788, 37.770806],
          [44.479475, 37.771465],
          [44.480269, 37.771744],
          [44.480724, 37.771825],
          [44.481638, 37.771824],
          [44.482754, 37.771726],
          [44.483573, 37.771576],
          [44.484081, 37.771573],
          [44.484953, 37.771655],
          [44.485909, 37.771613],
          [44.486775, 37.771417],
          [44.487519, 37.771059],
          [44.4879, 37.770978],
          [44.488449, 37.771066],
          [44.489066, 37.771049],
          [44.490946, 37.770716],
          [44.491534, 37.770682],
          [44.491914, 37.77059],
          [44.49262, 37.770699],
          [44.493949, 37.77046],
          [44.49443, 37.770851],
          [44.495189, 37.772026],
          [44.495679, 37.772141],
          [44.496132, 37.77282],
          [44.496833, 37.773249],
          [44.497924, 37.773743],
          [44.498781, 37.773917],
          [44.499561, 37.774135],
          [44.501882, 37.774566],
          [44.50348, 37.774728],
          [44.506145, 37.775136],
          [44.507052, 37.775139],
          [44.507706, 37.775213],
          [44.509755, 37.775817],
          [44.510844, 37.775706],
          [44.512128, 37.775395],
          [44.512511, 37.775376],
          [44.513151, 37.775473],
          [44.517744, 37.776743],
          [44.518306, 37.777072],
          [44.518684, 37.777502],
          [44.518936, 37.778168],
          [44.518959, 37.779348],
          [44.519081, 37.780002],
          [44.519339, 37.780246],
          [44.521915, 37.780614],
          [44.522977, 37.780724],
          [44.523294, 37.78097],
          [44.523664, 37.781594],
          [44.524392, 37.78208],
          [44.527361, 37.782788],
          [44.529099, 37.782926],
          [44.529796, 37.783025],
          [44.530312, 37.783017],
          [44.532157, 37.782429],
          [44.53272, 37.782195],
          [44.533176, 37.78179],
          [44.533645, 37.78158],
          [44.534695, 37.781465],
          [44.53543, 37.781446],
          [44.537699, 37.781924],
          [44.538714, 37.781881],
          [44.539691, 37.781998],
          [44.540191, 37.781958],
          [44.54238, 37.781155],
          [44.544914, 37.779997],
          [44.545457, 37.779848],
          [44.546942, 37.779738],
          [44.547316, 37.7796],
          [44.548203, 37.779509],
          [44.548586, 37.779486],
          [44.549423, 37.779772],
          [44.549722, 37.779965],
          [44.550067, 37.780367],
          [44.551146, 37.780665],
          [44.551602, 37.780718],
          [44.552522, 37.780613],
          [44.554572, 37.780626],
          [44.554951, 37.780501],
          [44.555231, 37.780316],
          [44.555767, 37.779845],
          [44.557315, 37.779438],
          [44.558591, 37.778426],
          [44.559747, 37.777611],
          [44.560168, 37.777149],
          [44.56185, 37.776087],
          [44.56248, 37.77551],
          [44.56313, 37.774593],
          [44.563337, 37.773995],
          [44.563336, 37.77346],
          [44.563469, 37.772602],
          [44.563631, 37.772009],
          [44.563915, 37.771621],
          [44.564578, 37.771233],
          [44.565567, 37.770853],
          [44.566521, 37.770578],
          [44.567167, 37.77028],
          [44.567793, 37.769771],
          [44.568283, 37.769251],
          [44.5686, 37.768808],
          [44.568896, 37.768729],
          [44.569787, 37.768795],
          [44.57033, 37.768757],
          [44.571337, 37.768438],
          [44.571748, 37.768261],
          [44.572178, 37.768001],
          [44.572421, 37.767736],
          [44.572508, 37.767556],
          [44.572617, 37.76749],
          [44.573736, 37.767602],
          [44.574245, 37.767544],
          [44.574799, 37.767293],
          [44.575298, 37.766786],
          [44.575636, 37.766541],
          [44.576502, 37.766376],
          [44.577041, 37.766345],
          [44.577365, 37.766271],
          [44.578548, 37.765334],
          [44.578731, 37.765327],
          [44.579073, 37.765615],
          [44.579348, 37.765694],
          [44.579685, 37.765697],
          [44.580698, 37.765538],
          [44.581414, 37.76552],
          [44.583, 37.765723],
          [44.584134, 37.765657],
          [44.586029, 37.764708],
          [44.58708, 37.764008],
          [44.587864, 37.763364],
          [44.588603, 37.761614],
          [44.589209, 37.759467],
          [44.589897, 37.757696],
          [44.590723, 37.756265],
          [44.591943, 37.754752],
          [44.592556, 37.75368],
          [44.593588, 37.751221],
          [44.59361, 37.75008],
          [44.594773, 37.747841],
          [44.596771, 37.746371],
          [44.597188, 37.745888],
          [44.599226, 37.74532],
          [44.599925, 37.744992],
          [44.600523, 37.744631],
          [44.600872, 37.74406],
          [44.601246, 37.743705],
          [44.601386, 37.743149],
          [44.60193, 37.742562],
          [44.602257, 37.741769],
          [44.602327, 37.740919],
          [44.602782, 37.74024],
          [44.603664, 37.739939],
          [44.604421, 37.739751],
          [44.605876, 37.739567],
          [44.606718, 37.739228],
          [44.607409, 37.73876],
          [44.608335, 37.736576],
          [44.608896, 37.735641],
          [44.609198, 37.734629],
          [44.610411, 37.732899],
          [44.611015, 37.732438],
          [44.612824, 37.732274],
          [44.614305, 37.731802],
          [44.615314, 37.731429],
          [44.616202, 37.730903],
          [44.616411, 37.730706],
          [44.616619, 37.730212],
          [44.619394, 37.728205],
          [44.619361, 37.727898],
          [44.620469, 37.727196],
          [44.620623, 37.72702],
          [44.62071, 37.726472],
          [44.620502, 37.725847],
          [44.620623, 37.725002],
          [44.620283, 37.724103],
          [44.620579, 37.722425],
          [44.621676, 37.720363],
          [44.621632, 37.719726],
          [44.621687, 37.719507],
          [44.621632, 37.719244],
          [44.621228, 37.718904],
          [44.621137, 37.718711],
          [44.621518, 37.718152],
          [44.622411, 37.716699],
          [44.623348, 37.712681],
          [44.624147, 37.710233],
          [44.62482, 37.708965],
          [44.625948, 37.707857],
          [44.628159, 37.707109],
          [44.630412, 37.706348],
          [44.628967, 37.704479],
          [44.628506, 37.703047],
          [44.628187, 37.70281],
          [44.628077, 37.702143],
          [44.627414, 37.701583],
          [44.627326, 37.701165],
          [44.627017, 37.701078],
          [44.626979, 37.700697],
          [44.625822, 37.700086],
          [44.624729, 37.698147],
          [44.623504, 37.697172],
          [44.623326, 37.696624],
          [44.622435, 37.695974],
          [44.621591, 37.694966],
          [44.619942, 37.69361],
          [44.619211, 37.692995],
          [44.61836, 37.691901],
          [44.617675, 37.691536],
          [44.617318, 37.691015],
          [44.617328, 37.690691],
          [44.617203, 37.690432],
          [44.614339, 37.688934],
          [44.613513, 37.688821],
          [44.612471, 37.688213],
          [44.611611, 37.687739],
          [44.611075, 37.68774],
          [44.610879, 37.687144],
          [44.609739, 37.68622],
          [44.608357, 37.68539],
          [44.607594, 37.684927],
          [44.606778, 37.684444],
          [44.605968, 37.683695],
          [44.604917, 37.682173],
          [44.603406, 37.680613],
          [44.602707, 37.680097],
          [44.600682, 37.679407],
          [44.599126, 37.678222],
          [44.597642, 37.677144],
          [44.596781, 37.676991],
          [44.596319, 37.676866],
          [44.595772, 37.676778],
          [44.593748, 37.675623],
          [44.591811, 37.675805],
          [44.591032, 37.675711],
          [44.590702, 37.675049],
          [44.590206, 37.673655],
          [44.590031, 37.672608],
          [44.589563, 37.671031],
          [44.588167, 37.670398],
          [44.586259, 37.670011],
          [44.584957, 37.669659],
          [44.583561, 37.669343],
          [44.58263, 37.669062],
          [44.581932, 37.668956],
          [44.58156, 37.668569],
          [44.580397, 37.668288],
          [44.578117, 37.667866],
          [44.576582, 37.667198],
          [44.574348, 37.665861],
          [44.574116, 37.665053],
          [44.574022, 37.663752],
          [44.574209, 37.663013],
          [44.574209, 37.66245],
          [44.574116, 37.661642],
          [44.574348, 37.660833],
          [44.574441, 37.660024],
          [44.574395, 37.658371],
          [44.574162, 37.657633],
          [44.573371, 37.656648],
          [44.572627, 37.65591],
          [44.572534, 37.655312],
          [44.571789, 37.654292],
          [44.571463, 37.652604],
          [44.570998, 37.651795],
          [44.570672, 37.650846],
          [44.570114, 37.650107],
          [44.568904, 37.647329],
          [44.568765, 37.646309],
          [44.56816, 37.644762],
          [44.568113, 37.642828],
          [44.5683, 37.641773],
          [44.568625, 37.640894],
          [44.569602, 37.639522],
          [44.570696, 37.639131],
          [44.571563, 37.638341],
          [44.573031, 37.637498],
          [44.573783, 37.637275],
          [44.574597, 37.63737],
          [44.576552, 37.637922],
          [44.577597, 37.637989],
          [44.579217, 37.637777],
          [44.580945, 37.637394],
          [44.582272, 37.635982],
          [44.582734, 37.634932],
          [44.583004, 37.633544],
          [44.583129, 37.631979],
          [44.583325, 37.631357],
          [44.58383, 37.630616],
          [44.584748, 37.629867],
          [44.585554, 37.628977],
          [44.586209, 37.627342],
          [44.587175, 37.625776],
          [44.588964, 37.624258],
          [44.589981, 37.623221],
          [44.591185, 37.622093],
          [44.592089, 37.620843],
          [44.592841, 37.619162],
          [44.593237, 37.617728],
          [44.593926, 37.617004],
          [44.595271, 37.616294],
          [44.596401, 37.61624],
          [44.597389, 37.616794],
          [44.598716, 37.617183],
          [44.600141, 37.61677],
          [44.601798, 37.615461],
          [44.604408, 37.613057],
          [44.605249, 37.612452],
          [44.606395, 37.611935],
          [44.606499, 37.611671],
          [44.606778, 37.61139],
          [44.607569, 37.610862],
          [44.607057, 37.60942],
          [44.607662, 37.608928],
          [44.607988, 37.608084],
          [44.608686, 37.607627],
          [44.610175, 37.605974],
          [44.610873, 37.605447],
          [44.611384, 37.60513],
          [44.612408, 37.604743],
          [44.612873, 37.604321],
          [44.613897, 37.603161],
          [44.614409, 37.602176],
          [44.614455, 37.600629],
          [44.614083, 37.599855],
          [44.613525, 37.599011],
          [44.613059, 37.597499],
          [44.612827, 37.596972],
          [44.612268, 37.596057],
          [44.611989, 37.594897],
          [44.611989, 37.592787],
          [44.612222, 37.592365],
          [44.612408, 37.591873],
          [44.612082, 37.590958],
          [44.612055, 37.590374],
          [44.61158, 37.589518],
          [44.610733, 37.588263],
          [44.609173, 37.586551],
          [44.607488, 37.585559],
          [44.60605, 37.584537],
          [44.603847, 37.583555],
          [44.600985, 37.582708],
          [44.598904, 37.580766],
          [44.599985, 37.57911],
          [44.600488, 37.578497],
          [44.601146, 37.578233],
          [44.601288, 37.577706],
          [44.601872, 37.576961],
          [44.602315, 37.576601],
          [44.602757, 37.576544],
          [44.603794, 37.576892],
          [44.604764, 37.576821],
          [44.60518, 37.576844],
          [44.605968, 37.576717],
          [44.606289, 37.576249],
          [44.606597, 37.575975],
          [44.607556, 37.575707],
          [44.607937, 37.575226],
          [44.608061, 37.574855],
          [44.609006, 37.574158],
          [44.609521, 37.573477],
          [44.610126, 37.573115],
          [44.610998, 37.572296],
          [44.611979, 37.571963],
          [44.612846, 37.571352],
          [44.61285, 37.571097],
          [44.61067, 37.570843],
          [44.609555, 37.570967],
          [44.608767, 37.570942],
          [44.608267, 37.570738],
          [44.607988, 37.570351],
          [44.607941, 37.57],
          [44.608276, 37.569653],
          [44.608593, 37.569472],
          [44.608964, 37.569158],
          [44.609337, 37.568734],
          [44.60943, 37.568382],
          [44.610047, 37.56772],
          [44.6105, 37.567714],
          [44.610873, 37.567257],
          [44.611338, 37.56694],
          [44.611752, 37.56658],
          [44.612703, 37.566505],
          [44.613246, 37.566588],
          [44.613664, 37.566483],
          [44.613943, 37.56592],
          [44.614235, 37.56547],
          [44.614303, 37.564939],
          [44.613998, 37.564],
          [44.61322, 37.563483],
          [44.612875, 37.562451],
          [44.612757, 37.561916],
          [44.612377, 37.56166],
          [44.611937, 37.561294],
          [44.611396, 37.561347],
          [44.611083, 37.561623],
          [44.610243, 37.56182],
          [44.609784, 37.56182],
          [44.609593, 37.561658],
          [44.609325, 37.561201],
          [44.608878, 37.560656],
          [44.608106, 37.560353],
          [44.607249, 37.559849],
          [44.606209, 37.56006],
          [44.605674, 37.560021],
          [44.605185, 37.559772],
          [44.604796, 37.55982],
          [44.604082, 37.560329],
          [44.603657, 37.560294],
          [44.602922, 37.560039],
          [44.602597, 37.559819],
          [44.602664, 37.558978],
          [44.602627, 37.558377],
          [44.601782, 37.557458],
          [44.601565, 37.557108],
          [44.601314, 37.556618],
          [44.60125, 37.55609],
          [44.601371, 37.554896],
          [44.601788, 37.554283],
          [44.601773, 37.553859],
          [44.602372, 37.553392],
          [44.602823, 37.553436],
          [44.603289, 37.553331],
          [44.603568, 37.552663],
          [44.603815, 37.552252],
          [44.604219, 37.55203],
          [44.604766, 37.55131],
          [44.605389, 37.550839],
          [44.605947, 37.550492],
          [44.606639, 37.550201],
          [44.60715, 37.549885],
          [44.608144, 37.549525],
          [44.608833, 37.549451],
          [44.609505, 37.549687],
          [44.610161, 37.549835],
          [44.61062, 37.549736],
          [44.611211, 37.549687],
          [44.612222, 37.549463],
          [44.612873, 37.548935],
          [44.613047, 37.548398],
          [44.613441, 37.547753],
          [44.613229, 37.547098],
          [44.612965, 37.546551],
          [44.613097, 37.546104],
          [44.613059, 37.545595],
          [44.612588, 37.544878],
          [44.611867, 37.544382],
          [44.611342, 37.544184],
          [44.610161, 37.543638],
          [44.609523, 37.543133],
          [44.609849, 37.542746],
          [44.610407, 37.5425],
          [44.6105, 37.542219],
          [44.610361, 37.541867],
          [44.610407, 37.541593],
          [44.609915, 37.541221],
          [44.609227, 37.540948],
          [44.608259, 37.539821],
          [44.607144, 37.539474],
          [44.607029, 37.539114],
          [44.606964, 37.538315],
          [44.607197, 37.538104],
          [44.607243, 37.537612],
          [44.607144, 37.536648],
          [44.606499, 37.535396],
          [44.606359, 37.534834],
          [44.606313, 37.533603],
          [44.606127, 37.53304],
          [44.606127, 37.532091],
          [44.605755, 37.531704],
          [44.605615, 37.531458],
          [44.605615, 37.531247],
          [44.605382, 37.530825],
          [44.605357, 37.530314],
          [44.60516, 37.529818],
          [44.604865, 37.52957],
          [44.604638, 37.529278],
          [44.604701, 37.528405],
          [44.604307, 37.52791],
          [44.603586, 37.527662],
          [44.602634, 37.526645],
          [44.60247, 37.525852],
          [44.602208, 37.525282],
          [44.601913, 37.524786],
          [44.601474, 37.524249],
          [44.601421, 37.523621],
          [44.601159, 37.522927],
          [44.601288, 37.522174],
          [44.601846, 37.5214],
          [44.601986, 37.520767],
          [44.602125, 37.519677],
          [44.602079, 37.519115],
          [44.6018, 37.518482],
          [44.601893, 37.517919],
          [44.601765, 37.517512],
          [44.601009, 37.517286],
          [44.601175, 37.51667],
          [44.601126, 37.51616],
          [44.600798, 37.515491],
          [44.601027, 37.515119],
          [44.601519, 37.514722],
          [44.601454, 37.514202],
          [44.600798, 37.513755],
          [44.600273, 37.513483],
          [44.599847, 37.513458],
          [44.599191, 37.513532],
          [44.598076, 37.513309],
          [44.597584, 37.512665],
          [44.597125, 37.512367],
          [44.596928, 37.512045],
          [44.59637, 37.5115],
          [44.595583, 37.511425],
          [44.594862, 37.511227],
          [44.594435, 37.510979],
          [44.594107, 37.510706],
          [44.593648, 37.510459],
          [44.592533, 37.510459],
          [44.591779, 37.510582],
          [44.590959, 37.510285],
          [44.590204, 37.509938],
          [44.589417, 37.510112],
          [44.5889, 37.510282],
          [44.588026, 37.510288],
          [44.587145, 37.510372],
          [44.586499, 37.509587],
          [44.585811, 37.509065],
          [44.585567, 37.508705],
          [44.585019, 37.507377],
          [44.585358, 37.50543],
          [44.584929, 37.502982],
          [44.584245, 37.500509],
          [44.584273, 37.497958],
          [44.58422, 37.496133],
          [44.5842, 37.495437],
          [44.583446, 37.493095],
          [44.583291, 37.492973],
          [44.583137, 37.491213],
          [44.583684, 37.487859],
          [44.583057, 37.485858],
          [44.582373, 37.48401],
          [44.582671, 37.481883],
          [44.584426, 37.479768],
          [44.585689, 37.477674],
          [44.586158, 37.474339],
          [44.586187, 37.473204],
          [44.586374, 37.472156],
          [44.586663, 37.470969],
          [44.586452, 37.470029],
          [44.585967, 37.468725],
          [44.585706, 37.467805],
          [44.585867, 37.466699],
          [44.586454, 37.465432],
          [44.587607, 37.461664],
          [44.587551, 37.459888],
          [44.587122, 37.458352],
          [44.586908, 37.457294],
          [44.586625, 37.454062],
          [44.586448, 37.452705],
          [44.586252, 37.451861],
          [44.585099, 37.451132],
          [44.584179, 37.450422],
          [44.583229, 37.450159],
          [44.580687, 37.448733],
          [44.579829, 37.447606],
          [44.580404, 37.447],
          [44.581357, 37.446127],
          [44.582667, 37.444773],
          [44.583961, 37.444001],
          [44.585458, 37.441847],
          [44.587315, 37.439619],
          [44.58947, 37.438847],
          [44.591092, 37.438492],
          [44.592681, 37.438289],
          [44.594559, 37.438292],
          [44.596427, 37.438485],
          [44.598512, 37.438402],
          [44.601069, 37.437899],
          [44.605645, 37.4365],
          [44.606436, 37.436317],
          [44.607461, 37.436341],
          [44.608615, 37.436455],
          [44.613439, 37.436555],
          [44.614772, 37.43676],
          [44.616736, 37.437197],
          [44.617801, 37.437346],
          [44.619012, 37.437139],
          [44.622475, 37.435273],
          [44.622915, 37.434816],
          [44.625006, 37.433735],
          [44.627976, 37.432695],
          [44.628471, 37.432571],
          [44.629406, 37.432197],
          [44.630286, 37.431989],
          [44.630891, 37.431615],
          [44.631551, 37.431032],
          [44.632156, 37.430243],
          [44.632872, 37.429577],
          [44.633807, 37.428871],
          [44.634192, 37.428455],
          [44.635237, 37.426418],
          [44.635842, 37.425919],
          [44.636227, 37.425004],
          [44.636612, 37.424298],
          [44.636722, 37.423674],
          [44.636557, 37.423175],
          [44.636117, 37.422552],
          [44.635402, 37.422219],
          [44.634522, 37.420847],
          [44.634742, 37.419891],
          [44.634797, 37.419101],
          [44.635072, 37.418311],
          [44.635457, 37.417646],
          [44.636392, 37.41644],
          [44.637217, 37.415858],
          [44.637932, 37.414985],
          [44.638537, 37.414403],
          [44.639032, 37.413821],
          [44.639802, 37.412699],
          [44.640407, 37.412283],
          [44.641508, 37.411077],
          [44.642498, 37.410537],
          [44.643158, 37.409955],
          [44.644533, 37.408915],
          [44.645963, 37.408417],
          [44.646788, 37.407959],
          [44.647228, 37.407502],
          [44.648163, 37.407045],
          [44.648878, 37.406047],
          [44.649484, 37.405091],
          [44.650144, 37.4048],
          [44.650859, 37.403719],
          [44.651949, 37.402774],
          [44.653236, 37.402571],
          [44.654126, 37.402551],
          [44.654808, 37.402433],
          [44.655432, 37.402478],
          [44.655997, 37.402267],
          [44.656156, 37.401681],
          [44.657062, 37.400086],
          [44.657252, 37.399188],
          [44.65692, 37.398865],
          [44.656303, 37.398508],
          [44.655731, 37.398361],
          [44.655265, 37.398076],
          [44.654793, 37.397075],
          [44.654676, 37.396287],
          [44.654142, 37.395006],
          [44.65351, 37.393708],
          [44.653364, 37.392809],
          [44.653477, 37.392095],
          [44.65442, 37.389706],
          [44.654864, 37.388203],
          [44.655796, 37.386934],
          [44.657368, 37.385905],
          [44.659544, 37.385174],
          [44.6625, 37.384632],
          [44.664818, 37.384347],
          [44.66855, 37.383323],
          [44.66923, 37.383228],
          [44.671079, 37.383488],
          [44.673354, 37.384383],
          [44.674858, 37.384536],
          [44.67821, 37.383566],
          [44.679269, 37.3836],
          [44.680379, 37.383392],
          [44.681454, 37.382526],
          [44.682429, 37.382252],
          [44.683977, 37.382495],
          [44.684187, 37.382601],
          [44.684433, 37.382914],
          [44.684784, 37.383223],
          [44.685473, 37.383702],
          [44.686107, 37.384492],
          [44.686629, 37.384734],
          [44.687049, 37.384772],
          [44.687664, 37.384699],
          [44.689208, 37.384346],
          [44.689313, 37.384322],
          [44.690734, 37.384251],
          [44.69267, 37.384264],
          [44.694014, 37.383878],
          [44.697201, 37.383474],
          [44.698778, 37.382881],
          [44.700269, 37.382009],
          [44.701027, 37.381431],
          [44.702516, 37.381098],
          [44.704286, 37.380815],
          [44.705551, 37.380838],
          [44.706538, 37.381065],
          [44.708087, 37.380808],
          [44.711065, 37.380196],
          [44.712574, 37.379575],
          [44.713849, 37.378322],
          [44.716205, 37.376908],
          [44.717517, 37.376902],
          [44.718663, 37.376369],
          [44.719939, 37.37639],
          [44.721473, 37.375693],
          [44.721513, 37.374237],
          [44.721401, 37.373458],
          [44.721, 37.373045],
          [44.720774, 37.37268],
          [44.720991, 37.372029],
          [44.721769, 37.370617],
          [44.721843, 37.369861],
          [44.723871, 37.366433],
          [44.723957, 37.366126],
          [44.724021, 37.365598],
          [44.724429, 37.363517],
          [44.72445, 37.361709],
          [44.724536, 37.360447],
          [44.724322, 37.35968],
          [44.723855, 37.358534],
          [44.724304, 37.357998],
          [44.724425, 37.356909],
          [44.724069, 37.355904],
          [44.724409, 37.35487],
          [44.725291, 37.354073],
          [44.72617, 37.353651],
          [44.726063, 37.353158],
          [44.726661, 37.352533],
          [44.726854, 37.351885],
          [44.727304, 37.350367],
          [44.730351, 37.347996],
          [44.732776, 37.346392],
          [44.733441, 37.344857],
          [44.733291, 37.343612],
          [44.732347, 37.342622],
          [44.732434, 37.342232],
          [44.733424, 37.342024],
          [44.734139, 37.341775],
          [44.735239, 37.341525],
          [44.736669, 37.340777],
          [44.737384, 37.340527],
          [44.738209, 37.340029],
          [44.738869, 37.339738],
          [44.739695, 37.338573],
          [44.74019, 37.337991],
          [44.741015, 37.336536],
          [44.741565, 37.33583],
          [44.74261, 37.335164],
          [44.743215, 37.334624],
          [44.744975, 37.334458],
          [44.7458, 37.334084],
          [44.7469, 37.334042],
          [44.747781, 37.333793],
          [44.748771, 37.333418],
          [44.749761, 37.332629],
          [44.750283, 37.332064],
          [44.750833, 37.331856],
          [44.751713, 37.331357],
          [44.754023, 37.330817],
          [44.755233, 37.330276],
          [44.756444, 37.330235],
          [44.757159, 37.33011],
          [44.758864, 37.329985],
          [44.761394, 37.329486],
          [44.762604, 37.329486],
          [44.763319, 37.32957],
          [44.76519, 37.329985],
          [44.765905, 37.330069],
          [44.76695, 37.330069],
          [44.770635, 37.328406],
          [44.77124, 37.327948],
          [44.772121, 37.327366],
          [44.772946, 37.326909],
          [44.774376, 37.325911],
          [44.774761, 37.325329],
          [44.775091, 37.324581],
          [44.775641, 37.323708],
          [44.776026, 37.32325],
          [44.776631, 37.322793],
          [44.778391, 37.32192],
          [44.779216, 37.321297],
          [44.779986, 37.320839],
          [44.781197, 37.320424],
          [44.784057, 37.318677],
          [44.784937, 37.318303],
          [44.785982, 37.317513],
          [44.786697, 37.316807],
          [44.787467, 37.316225],
          [44.787907, 37.315809],
          [44.788678, 37.315227],
          [44.790218, 37.314229],
          [44.790878, 37.313897],
          [44.791923, 37.313689],
          [44.793793, 37.313023],
          [44.795993, 37.311319],
          [44.797314, 37.311111],
          [44.798139, 37.310903],
          [44.798524, 37.310654],
          [44.799184, 37.310113],
          [44.799294, 37.309947],
          [44.799514, 37.309282],
          [44.799514, 37.306995],
          [44.799569, 37.306538],
          [44.800351, 37.305092],
          [44.800999, 37.303628],
          [44.801879, 37.30263],
          [44.804574, 37.300718],
          [44.805015, 37.300053],
          [44.8054, 37.299263],
          [44.805565, 37.298639],
          [44.806005, 37.297766],
          [44.806808, 37.296743],
          [44.807064, 37.295791],
          [44.807333, 37.294786],
          [44.807557, 37.294052],
          [44.807933, 37.293324],
          [44.80826, 37.291987],
          [44.808535, 37.29153],
          [44.808755, 37.290906],
          [44.80881, 37.290408],
          [44.809415, 37.288869],
          [44.8098, 37.288204],
          [44.810295, 37.287622],
          [44.812385, 37.28546],
          [44.813376, 37.284504],
          [44.813761, 37.283631],
          [44.814256, 37.2828],
          [44.814751, 37.282218],
          [44.815026, 37.281345],
          [44.815191, 37.280472],
          [44.815136, 37.279599],
          [44.814641, 37.278642],
          [44.814586, 37.27806],
          [44.814201, 37.277561],
          [44.813926, 37.276813],
          [44.813541, 37.276314],
          [44.812826, 37.275649],
          [44.81178, 37.274859],
          [44.810845, 37.274527],
          [44.809745, 37.273986],
          [44.80881, 37.273612],
          [44.807765, 37.27303],
          [44.80639, 37.27249],
          [44.805235, 37.271783],
          [44.804299, 37.271325],
          [44.803749, 37.270785],
          [44.803419, 37.270286],
          [44.802979, 37.269829],
          [44.802759, 37.269081],
          [44.802319, 37.268166],
          [44.801879, 37.266004],
          [44.801659, 37.264216],
          [44.801659, 37.26351],
          [44.801549, 37.262678],
          [44.801219, 37.261847],
          [44.801219, 37.26168],
          [44.800918, 37.26047],
          [44.799844, 37.259477],
          [44.799349, 37.259144],
          [44.798799, 37.258646],
          [44.797644, 37.258105],
          [44.796489, 37.257648],
          [44.795058, 37.257149],
          [44.794453, 37.256983],
          [44.792253, 37.256609],
          [44.790383, 37.256692],
          [44.789338, 37.256858],
          [44.788458, 37.256733],
          [44.787302, 37.256401],
          [44.786257, 37.255943],
          [44.785267, 37.255444],
          [44.783892, 37.254655],
          [44.779161, 37.251703],
          [44.778446, 37.251246],
          [44.777126, 37.250248],
          [44.776466, 37.249874],
          [44.768354, 37.244641],
          [44.767652, 37.243722],
          [44.767045, 37.242454],
          [44.766875, 37.240463],
          [44.766453, 37.238402],
          [44.765282, 37.237354],
          [44.763324, 37.236709],
          [44.761688, 37.235872],
          [44.760765, 37.234867],
          [44.760086, 37.234025],
          [44.759764, 37.232387],
          [44.758921, 37.230239],
          [44.757568, 37.228341],
          [44.758795, 37.226155],
          [44.76043, 37.2246],
          [44.761791, 37.224196],
          [44.762648, 37.223499],
          [44.762412, 37.222607],
          [44.76155, 37.221818],
          [44.760612, 37.219223],
          [44.760624, 37.218277],
          [44.760459, 37.217279],
          [44.760349, 37.21545],
          [44.760514, 37.213163],
          [44.760459, 37.211916],
          [44.760679, 37.209546],
          [44.761229, 37.207093],
          [44.761559, 37.205971],
          [44.761944, 37.204973],
          [44.763264, 37.202687],
          [44.763924, 37.201232],
          [44.764474, 37.200483],
          [44.76519, 37.199735],
          [44.76596, 37.198571],
          [44.767115, 37.195578],
          [44.767445, 37.194123],
          [44.767885, 37.193208],
          [44.76805, 37.192626],
          [44.76794, 37.191878],
          [44.767665, 37.190838],
          [44.766732, 37.189153],
          [44.766512, 37.188229],
          [44.766566, 37.187513],
          [44.766345, 37.186515],
          [44.76629, 37.185933],
          [44.76662, 37.184893],
          [44.76596, 37.184103],
          [44.766125, 37.183106],
          [44.768325, 37.181235],
          [44.769205, 37.180985],
          [44.76992, 37.180528],
          [44.77058, 37.180362],
          [44.77135, 37.179905],
          [44.771845, 37.179156],
          [44.77212, 37.178491],
          [44.772065, 37.177535],
          [44.772725, 37.176911],
          [44.774431, 37.17608],
          [44.775586, 37.175872],
          [44.778226, 37.174874],
          [44.779326, 37.174292],
          [44.780371, 37.173627],
          [44.781086, 37.173003],
          [44.782792, 37.170925],
          [44.783562, 37.169678],
          [44.784222, 37.168472],
          [44.784772, 37.167724],
          [44.784992, 37.1671],
          [44.785212, 37.166352],
          [44.785267, 37.165686],
          [44.785267, 37.165063],
          [44.785047, 37.163317],
          [44.785102, 37.16261],
          [44.785707, 37.161197],
          [44.785817, 37.16049],
          [44.785817, 37.159492],
          [44.785707, 37.158827],
          [44.785212, 37.158286],
          [44.784607, 37.157372],
          [44.783562, 37.156208],
          [44.783452, 37.154961],
          [44.783731, 37.154278],
          [44.783925, 37.15336],
          [44.784314, 37.152405],
          [44.784508, 37.151267],
          [44.785984, 37.149896],
          [44.785522, 37.14939],
          [44.785085, 37.148463],
          [44.784914, 37.147667],
          [44.784809, 37.14649],
          [44.784835, 37.145635],
          [44.784929, 37.145086],
          [44.784671, 37.14464],
          [44.784415, 37.144088],
          [44.784306, 37.14385],
          [44.783599, 37.14377],
          [44.783271, 37.143654],
          [44.783001, 37.14359],
          [44.782558, 37.143604],
          [44.782103, 37.14359],
          [44.781804, 37.143652],
          [44.781477, 37.143593],
          [44.78116, 37.143648],
          [44.780703, 37.143678],
          [44.780406, 37.143901],
          [44.780149, 37.14396],
          [44.779868, 37.143539],
          [44.779666, 37.1433],
          [44.779384, 37.143073],
          [44.779167, 37.142775],
          [44.779071, 37.142426],
          [44.778944, 37.142318],
          [44.778878, 37.142199],
          [44.778795, 37.142019],
          [44.778768, 37.141955],
          [44.778642, 37.141807],
          [44.778461, 37.141573],
          [44.778279, 37.141332],
          [44.777901, 37.141198],
          [44.777677, 37.140876],
          [44.777503, 37.140573],
          [44.77735, 37.140409],
          [44.777072, 37.140359],
          [44.776574, 37.140312],
          [44.776133, 37.140106],
          [44.775722, 37.13993],
          [44.775387, 37.139805],
          [44.774956, 37.139824],
          [44.77464, 37.139733],
          [44.77427, 37.139611],
          [44.773898, 37.139506],
          [44.773305, 37.139497],
          [44.772841, 37.139444],
          [44.772516, 37.139341],
          [44.77215, 37.139211],
          [44.771821, 37.139015],
          [44.771397, 37.138908],
          [44.770692, 37.138789],
          [44.770196, 37.138623],
          [44.769815, 37.138526],
          [44.769472, 37.138473],
          [44.768797, 37.138488],
          [44.768325, 37.138503],
          [44.767727, 37.138573],
          [44.767649, 37.13841],
          [44.767325, 37.138182],
          [44.766969, 37.137946],
          [44.766602, 37.137681],
          [44.766448, 37.137487],
          [44.766184, 37.13732],
          [44.765889, 37.137172],
          [44.765542, 37.137046],
          [44.765382, 37.136802],
          [44.765013, 37.136784],
          [44.764798, 37.13663],
          [44.764402, 37.13651],
          [44.764113, 37.13651],
          [44.763897, 37.136382],
          [44.763892, 37.136192],
          [44.763853, 37.135993],
          [44.763938, 37.13583],
          [44.764041, 37.135675],
          [44.764197, 37.135316],
          [44.76437, 37.135031],
          [44.764417, 37.134602],
          [44.76449, 37.134283],
          [44.764568, 37.133966],
          [44.764638, 37.133712],
          [44.764466, 37.133517],
          [44.764375, 37.133322],
          [44.764324, 37.133047],
          [44.764284, 37.13274],
          [44.764127, 37.132512],
          [44.764183, 37.13225],
          [44.764208, 37.132053],
          [44.764331, 37.131828],
          [44.764423, 37.13162],
          [44.764519, 37.131355],
          [44.764588, 37.131124],
          [44.764584, 37.130774],
          [44.764605, 37.13046],
          [44.7646, 37.130461],
          [44.764647, 37.130085],
          [44.764663, 37.12985],
          [44.764715, 37.129568],
          [44.764386, 37.129344],
          [44.764163, 37.128999],
          [44.763969, 37.128862],
          [44.763538, 37.12857],
          [44.763129, 37.128507],
          [44.762931, 37.128452],
          [44.762711, 37.128391],
          [44.762336, 37.128333],
          [44.76199, 37.128332],
          [44.761385, 37.128319],
          [44.761005, 37.128241],
          [44.760493, 37.128107],
          [44.760182, 37.127938],
          [44.759826, 37.1278],
          [44.759369, 37.127573],
          [44.759129, 37.127335],
          [44.758939, 37.127241],
          [44.758533, 37.126979],
          [44.758229, 37.126788],
          [44.757935, 37.126614],
          [44.757734, 37.126466],
          [44.757392, 37.126081],
          [44.756874, 37.125872],
          [44.756563, 37.12576],
          [44.756262, 37.125053],
          [44.755729, 37.12496],
          [44.755599, 37.125098],
          [44.755497, 37.125305],
          [44.755093, 37.125443],
          [44.754589, 37.125338],
          [44.75417, 37.125626],
          [44.753781, 37.125648],
          [44.753478, 37.125636],
          [44.753227, 37.125625],
          [44.752813, 37.125385],
          [44.752477, 37.125189],
          [44.752162, 37.124838],
          [44.751936, 37.124578],
          [44.751818, 37.124224],
          [44.751647, 37.123962],
          [44.751462, 37.123777],
          [44.751035, 37.123564],
          [44.750738, 37.12332],
          [44.75054, 37.122939],
          [44.750227, 37.122627],
          [44.749926, 37.122503],
          [44.749643, 37.122295],
          [44.749347, 37.122],
          [44.749152, 37.121715],
          [44.748948, 37.12142],
          [44.748855, 37.121118],
          [44.748764, 37.120854],
          [44.748583, 37.120686],
          [44.748293, 37.120532],
          [44.747914, 37.12047],
          [44.747603, 37.120276],
          [44.747699, 37.119905],
          [44.747759, 37.119709],
          [44.747963, 37.119405],
          [44.748138, 37.119056],
          [44.748403, 37.11864],
          [44.748611, 37.118194],
          [44.749071, 37.117623],
          [44.749246, 37.117084],
          [44.749473, 37.116704],
          [44.7498, 37.116521],
          [44.749886, 37.116308],
          [44.74985, 37.115998],
          [44.750046, 37.115292],
          [44.750236, 37.115003],
          [44.750398, 37.114238],
          [44.749666, 37.112879],
          [44.750385, 37.112112],
          [44.751228, 37.111405],
          [44.751635, 37.111258],
          [44.75182, 37.111144],
          [44.752142, 37.110595],
          [44.75241, 37.110355],
          [44.752764, 37.110139],
          [44.753291, 37.109896],
          [44.754517, 37.109592],
          [44.754696, 37.109584],
          [44.755043, 37.109661],
          [44.757096, 37.108715],
          [44.75762, 37.108183],
          [44.758381, 37.107877],
          [44.758683, 37.107815],
          [44.758915, 37.10758],
          [44.759624, 37.10718],
          [44.760012, 37.107058],
          [44.76023, 37.10679],
          [44.760373, 37.106411],
          [44.760804, 37.106233],
          [44.761197, 37.105863],
          [44.761503, 37.105683],
          [44.762174, 37.105413],
          [44.76266, 37.105393],
          [44.763089, 37.105547],
          [44.763377, 37.105404],
          [44.763273, 37.104383],
          [44.763705, 37.103313],
          [44.763747, 37.102777],
          [44.76441, 37.101821],
          [44.765402, 37.100578],
          [44.765629, 37.100224],
          [44.765721, 37.099827],
          [44.766149, 37.099635],
          [44.766923, 37.099521],
          [44.767386, 37.099541],
          [44.767813, 37.099374],
          [44.768187, 37.099109],
          [44.768575, 37.099015],
          [44.768807, 37.098671],
          [44.769089, 37.098414],
          [44.769465, 37.09835],
          [44.770415, 37.098356],
          [44.771516, 37.098154],
          [44.772204, 37.097578],
          [44.772555, 37.097433],
          [44.772904, 37.097222],
          [44.773686, 37.096296],
          [44.774439, 37.096012],
          [44.774992, 37.095878],
          [44.77527, 37.095903],
          [44.77589, 37.096392],
          [44.776218, 37.096441],
          [44.77743, 37.096401],
          [44.777736, 37.096464],
          [44.778074, 37.096343],
          [44.778291, 37.096153],
          [44.778758, 37.095288],
          [44.779128, 37.094792],
          [44.779947, 37.094082],
          [44.780343, 37.093891],
          [44.781339, 37.092896],
          [44.78231, 37.092641],
          [44.783485, 37.092708],
          [44.783908, 37.092362],
          [44.783981, 37.091209],
          [44.784089, 37.090976],
          [44.784185, 37.090496],
          [44.784423, 37.090073],
          [44.784561, 37.089667],
          [44.784067, 37.089348],
          [44.783499, 37.089197],
          [44.781574, 37.088369],
          [44.780812, 37.087878],
          [44.780061, 37.087136],
          [44.77977, 37.08657],
          [44.778992, 37.085927],
          [44.778578, 37.085768],
          [44.778369, 37.085559],
          [44.77829, 37.085378],
          [44.778217, 37.084702],
          [44.778129, 37.084477],
          [44.777906, 37.084259],
          [44.777692, 37.083945],
          [44.777082, 37.083469],
          [44.777294, 37.082138],
          [44.77755, 37.081575],
          [44.777826, 37.081263],
          [44.778228, 37.080388],
          [44.778207, 37.079993],
          [44.778041, 37.079153],
          [44.777872, 37.078764],
          [44.777761, 37.078222],
          [44.777954, 37.077352],
          [44.777589, 37.076836],
          [44.777742, 37.076178],
          [44.777665, 37.075947],
          [44.777723, 37.075656],
          [44.778199, 37.075593],
          [44.778388, 37.075637],
          [44.779292, 37.075519],
          [44.780697, 37.075617],
          [44.781226, 37.075736],
          [44.781763, 37.075752],
          [44.782094, 37.075709],
          [44.782569, 37.075288],
          [44.782953, 37.07518],
          [44.783439, 37.074862],
          [44.784611, 37.074507],
          [44.78501, 37.074327],
          [44.78529, 37.07406],
          [44.785462, 37.073641],
          [44.785905, 37.073626],
          [44.786554, 37.073701],
          [44.786897, 37.073653],
          [44.787267, 37.073153],
          [44.787704, 37.073058],
          [44.788257, 37.072873],
          [44.7885, 37.07263],
          [44.789126, 37.072332],
          [44.789392, 37.072137],
          [44.789933, 37.071627],
          [44.790672, 37.071057],
          [44.791113, 37.070845],
          [44.791386, 37.070653],
          [44.792654, 37.070178],
          [44.793141, 37.069838],
          [44.794683, 37.069012],
          [44.795123, 37.068846],
          [44.795974, 37.068279],
          [44.797474, 37.06765],
          [44.798021, 37.067632],
          [44.798323, 37.067675],
          [44.798812, 37.067646],
          [44.799062, 37.067521],
          [44.799206, 37.067089],
          [44.799282, 37.066576],
          [44.799618, 37.065979],
          [44.799851, 37.065399],
          [44.799933, 37.064833],
          [44.799869, 37.064223],
          [44.799502, 37.063507],
          [44.799499, 37.063331],
          [44.800253, 37.062886],
          [44.800552, 37.062566],
          [44.801196, 37.062177],
          [44.801354, 37.062028],
          [44.801483, 37.061479],
          [44.801461, 37.06089],
          [44.801328, 37.06082],
          [44.801339, 37.060544],
          [44.801476, 37.060356],
          [44.801812, 37.060252],
          [44.802121, 37.060002],
          [44.802465, 37.059471],
          [44.802568, 37.059077],
          [44.802618, 37.058533],
          [44.802859, 37.057737],
          [44.802912, 37.057397],
          [44.803037, 37.057021],
          [44.803182, 37.056843],
          [44.802967, 37.056412],
          [44.803012, 37.056095],
          [44.802968, 37.055954],
          [44.802773, 37.055834],
          [44.802607, 37.055593],
          [44.802563, 37.055374],
          [44.802649, 37.054741],
          [44.802584, 37.054508],
          [44.802587, 37.053841],
          [44.802657, 37.053552],
          [44.803131, 37.052936],
          [44.803784, 37.052588],
          [44.803887, 37.052444],
          [44.803783, 37.052204],
          [44.803454, 37.051929],
          [44.803266, 37.051618],
          [44.803346, 37.051179],
          [44.803267, 37.051114],
          [44.802599, 37.050566],
          [44.802427, 37.050277],
          [44.802684, 37.049795],
          [44.802616, 37.049552],
          [44.80253, 37.049337],
          [44.802223, 37.049076],
          [44.801787, 37.048223],
          [44.801953, 37.04808],
          [44.802879, 37.048118],
          [44.803301, 37.048011],
          [44.803889, 37.04768],
          [44.804557, 37.047397],
          [44.804871, 37.04706],
          [44.805131, 37.046867],
          [44.805461, 37.046768],
          [44.805879, 37.046778],
          [44.806708, 37.046416],
          [44.806869, 37.046143],
          [44.806849, 37.045533],
          [44.806937, 37.044804],
          [44.80709, 37.044413],
          [44.807265, 37.044148],
          [44.807642, 37.04414],
          [44.808009, 37.044229],
          [44.808813, 37.044709],
          [44.80939, 37.044874],
          [44.809842, 37.044952],
          [44.810266, 37.044909],
          [44.81079, 37.044489],
          [44.811176, 37.044251],
          [44.811925, 37.043891],
          [44.812678, 37.043685],
          [44.812905, 37.04343],
          [44.813175, 37.042791],
          [44.813619, 37.042339],
          [44.814717, 37.041415],
          [44.815364, 37.041043],
          [44.815935, 37.040609],
          [44.815968, 37.040572],
          [44.816285, 37.040214],
          [44.81666, 37.039928],
          [44.81708, 37.039755],
          [44.818206, 37.039746],
          [44.819011, 37.039902],
          [44.819207, 37.040001],
          [44.819828, 37.040933],
          [44.820429, 37.041125],
          [44.820513, 37.041711],
          [44.821615, 37.042083],
          [44.822231, 37.042068],
          [44.82307, 37.04194],
          [44.82419, 37.042232],
          [44.825289, 37.042372],
          [44.825913, 37.042553],
          [44.826318, 37.042861],
          [44.82694, 37.043117],
          [44.827293, 37.04336],
          [44.827631, 37.0437],
          [44.828244, 37.044108],
          [44.829226, 37.04488],
          [44.829644, 37.045028],
          [44.830295, 37.045082],
          [44.83063, 37.045168],
          [44.832261, 37.045004],
          [44.832549, 37.045043],
          [44.833491, 37.045285],
          [44.834313, 37.045657],
          [44.834538, 37.045881],
          [44.835297, 37.046087],
          [44.835592, 37.046099],
          [44.836004, 37.046195],
          [44.836883, 37.04649],
          [44.837364, 37.046557],
          [44.838025, 37.046577],
          [44.839229, 37.046746],
          [44.839499, 37.046953],
          [44.839724, 37.047202],
          [44.84032, 37.047398],
          [44.841218, 37.047994],
          [44.841984, 37.048064],
          [44.842311, 37.048276],
          [44.842857, 37.048438],
          [44.843129, 37.04843],
          [44.843658, 37.048309],
          [44.84437, 37.048032],
          [44.845467, 37.047757],
          [44.845875, 37.04756],
          [44.846472, 37.047352],
          [44.847351, 37.047154],
          [44.848604, 37.046391],
          [44.849463, 37.046489],
          [44.849751, 37.046665],
          [44.850423, 37.046947],
          [44.850773, 37.047233],
          [44.85113, 37.047436],
          [44.851461, 37.047506],
          [44.851922, 37.04746],
          [44.853399, 37.047028],
          [44.854548, 37.04709],
          [44.85484, 37.046768],
          [44.855414, 37.046291],
          [44.856249, 37.046009],
          [44.856416, 37.045682],
          [44.857017, 37.044969],
          [44.857725, 37.044457],
          [44.858336, 37.044115],
          [44.85857, 37.043619],
          [44.858595, 37.043182],
          [44.858462, 37.042897],
          [44.858134, 37.041621],
          [44.857926, 37.041496],
          [44.857718, 37.04129],
          [44.85737, 37.040659],
          [44.857086, 37.04031],
          [44.857031, 37.039005],
          [44.856862, 37.03837],
          [44.856818, 37.037957],
          [44.856923, 37.03744],
          [44.857073, 37.037035],
          [44.857855, 37.036959],
          [44.858355, 37.037058],
          [44.85887, 37.037028],
          [44.85935, 37.036658],
          [44.859866, 37.036473],
          [44.860316, 37.036164],
          [44.860713, 37.035836],
          [44.860998, 37.035452],
          [44.861381, 37.035203],
          [44.862032, 37.034876],
          [44.863519, 37.034489],
          [44.864132, 37.034485],
          [44.864712, 37.034335],
          [44.86503, 37.034153],
          [44.865536, 37.033572],
          [44.865897, 37.033386],
          [44.866228, 37.033287],
          [44.867109, 37.032497],
          [44.867545, 37.032399],
          [44.867962, 37.03205],
          [44.86834, 37.031805],
          [44.86896, 37.031558],
          [44.870403, 37.031494],
          [44.871036, 37.031773],
          [44.871876, 37.031785],
          [44.8733, 37.03213],
          [44.874238, 37.032191],
          [44.874651, 37.032265],
          [44.875277, 37.032154],
          [44.876031, 37.031979],
          [44.876433, 37.031837],
          [44.877416, 37.031636],
          [44.877799, 37.031656],
          [44.879215, 37.031449],
          [44.88013, 37.031572],
          [44.880249, 37.031497],
          [44.880495, 37.031161],
          [44.880859, 37.030933],
          [44.88117, 37.030612],
          [44.881332, 37.030371],
          [44.881905, 37.030078],
          [44.88221, 37.030032],
          [44.882501, 37.030028],
          [44.883245, 37.030193],
          [44.883658, 37.030454],
          [44.88474, 37.030794],
          [44.885078, 37.030948],
          [44.885553, 37.03091],
          [44.887162, 37.031222],
          [44.889109, 37.030594],
          [44.889543, 37.030376],
          [44.890247, 37.029928],
          [44.890679, 37.029866],
          [44.89137, 37.029659],
          [44.891571, 37.029398],
          [44.891894, 37.029127],
          [44.892215, 37.029174],
          [44.892451, 37.029369],
          [44.893592, 37.02934],
          [44.893885, 37.029293],
          [44.894135, 37.028784],
          [44.895227, 37.028023],
          [44.895414, 37.027785],
          [44.895536, 37.027473],
          [44.895522, 37.027087],
          [44.895359, 37.02667],
          [44.895299, 37.026323],
          [44.895497, 37.025992],
          [44.895977, 37.025432],
          [44.896332, 37.025304],
          [44.896779, 37.02525],
          [44.897078, 37.025093],
          [44.897245, 37.024847],
          [44.897331, 37.024541],
          [44.897627, 37.024345],
          [44.898717, 37.02393],
          [44.899431, 37.023882],
          [44.899758, 37.023762],
          [44.900072, 37.023729],
          [44.900167, 37.023573],
          [44.90046, 37.022951],
          [44.900482, 37.022451],
          [44.900916, 37.021715],
          [44.900897, 37.021224],
          [44.900542, 37.020806],
          [44.900496, 37.020599],
          [44.900538, 37.020349],
          [44.900461, 37.020065],
          [44.900866, 37.019394],
          [44.900794, 37.019061],
          [44.901092, 37.018852],
          [44.901165, 37.018653],
          [44.900882, 37.01824],
          [44.900783, 37.017922],
          [44.901054, 37.01749],
          [44.901375, 37.017203],
          [44.901636, 37.016506],
          [44.901617, 37.016216],
          [44.901448, 37.015959],
          [44.901398, 37.015004],
          [44.900834, 37.013841],
          [44.900983, 37.013722],
          [44.902099, 37.013283],
          [44.902699, 37.012909],
          [44.90298, 37.01256],
          [44.903481, 37.012209],
          [44.903595, 37.011954],
          [44.903621, 37.011667],
          [44.903533, 37.011176],
          [44.903617, 37.010996],
          [44.903802, 37.010877],
          [44.904173, 37.010518],
          [44.90475, 37.010174],
          [44.905143, 37.010024],
          [44.905552, 37.009779],
          [44.905102, 37.009258],
          [44.90468, 37.008124],
          [44.904464, 37.007225],
          [44.904553, 37.006921],
          [44.904532, 37.005864],
          [44.904662, 37.005239],
          [44.904546, 37.00495],
          [44.90447, 37.004383],
          [44.904334, 37.004162],
          [44.904065, 37.004045],
          [44.90316, 37.003824],
          [44.901579, 37.003634],
          [44.900572, 37.002813],
          [44.899686, 37.002476],
          [44.899497, 37.002313],
          [44.899389, 37.002103],
          [44.899157, 37.001964],
          [44.898518, 37.0017],
          [44.898279, 37.001658],
          [44.897796, 36.999847],
          [44.896284, 36.998445],
          [44.895916, 36.998159],
          [44.895569, 36.998051],
          [44.895437, 36.997627],
          [44.895454, 36.997209],
          [44.895723, 36.996329],
          [44.89589, 36.996064],
          [44.895741, 36.995526],
          [44.895836, 36.99488],
          [44.895986, 36.994693],
          [44.89578, 36.993931],
          [44.895628, 36.993574],
          [44.895197, 36.993183],
          [44.894839, 36.992597],
          [44.894665, 36.992053],
          [44.894595, 36.991436],
          [44.894745, 36.990866],
          [44.89501, 36.990211],
          [44.895078, 36.989847],
          [44.894884, 36.989178],
          [44.894624, 36.988542],
          [44.894318, 36.988064],
          [44.894169, 36.987718],
          [44.894062, 36.986714],
          [44.893962, 36.986465],
          [44.892925, 36.985042],
          [44.892777, 36.984653],
          [44.892493, 36.98319],
          [44.89207, 36.982445],
          [44.891919, 36.981801],
          [44.891859, 36.981322],
          [44.891893, 36.980676],
          [44.892069, 36.979866],
          [44.892235, 36.978579],
          [44.891691, 36.977888],
          [44.891604, 36.977498],
          [44.891399, 36.977172],
          [44.890793, 36.976578],
          [44.89026, 36.976503],
          [44.889772, 36.976239],
          [44.889488, 36.975554],
          [44.889745, 36.973462],
          [44.889697, 36.972788],
          [44.889826, 36.972049],
          [44.889543, 36.971669],
          [44.888937, 36.971577],
          [44.88877, 36.971462],
          [44.888387, 36.970678],
          [44.888351, 36.970194],
          [44.888421, 36.967943],
          [44.888299, 36.967734],
          [44.888232, 36.967103],
          [44.887394, 36.964962],
          [44.887371, 36.964529],
          [44.887081, 36.964112],
          [44.886669, 36.963312],
          [44.885191, 36.96248],
          [44.885026, 36.962221],
          [44.884902, 36.961828],
          [44.884586, 36.961501],
          [44.884329, 36.961121],
          [44.884214, 36.960715],
          [44.883978, 36.960512],
          [44.883691, 36.96001],
          [44.883637, 36.959167],
          [44.883931, 36.957995],
          [44.883904, 36.957498],
          [44.88329, 36.956532],
          [44.882852, 36.95563],
          [44.882736, 36.955171],
          [44.882758, 36.954677],
          [44.882687, 36.954349],
          [44.882725, 36.954011],
          [44.882994, 36.953761],
          [44.883405, 36.953537],
          [44.883811, 36.953198],
          [44.884212, 36.952742],
          [44.88473, 36.951775],
          [44.885432, 36.950711],
          [44.885559, 36.950336],
          [44.885025, 36.948375],
          [44.884389, 36.947711],
          [44.884551, 36.947234],
          [44.884828, 36.946822],
          [44.886599, 36.945992],
          [44.887849, 36.945683],
          [44.888732, 36.94562],
          [44.889774, 36.945849],
          [44.890273, 36.945876],
          [44.891142, 36.945517],
          [44.891721, 36.945424],
          [44.892394, 36.94541],
          [44.893125, 36.945236],
          [44.894169, 36.945325],
          [44.894453, 36.945182],
          [44.89458, 36.94496],
          [44.894973, 36.944858],
          [44.896185, 36.944933],
          [44.896642, 36.943965],
          [44.896769, 36.943398],
          [44.896998, 36.942941],
          [44.897767, 36.941897],
          [44.898134, 36.94116],
          [44.898296, 36.940683],
          [44.898374, 36.940017],
          [44.898467, 36.939224],
          [44.898675, 36.938781],
          [44.899128, 36.938529],
          [44.899523, 36.938437],
          [44.899917, 36.938196],
          [44.900184, 36.937839],
          [44.900233, 36.937647],
          [44.900134, 36.936622],
          [44.899673, 36.935873],
          [44.899462, 36.935378],
          [44.89982, 36.934365],
          [44.900119, 36.933992],
          [44.900566, 36.933229],
          [44.900577, 36.932545],
          [44.900888, 36.931295],
          [44.901041, 36.930927],
          [44.901765, 36.930434],
          [44.902053, 36.929967],
          [44.902917, 36.929204],
          [44.903627, 36.927925],
          [44.903982, 36.926849],
          [44.904315, 36.926512],
          [44.904741, 36.925668],
          [44.905135, 36.925426],
          [44.905659, 36.924874],
          [44.90608, 36.924638],
          [44.906968, 36.924308],
          [44.907642, 36.923814],
          [44.908306, 36.923567],
          [44.908725, 36.923524],
          [44.909028, 36.923405],
          [44.909827, 36.922772],
          [44.910274, 36.922062],
          [44.910802, 36.920795],
          [44.910767, 36.919927],
          [44.910943, 36.918872],
          [44.911179, 36.918542],
          [44.912229, 36.917394],
          [44.912415, 36.917009],
          [44.912456, 36.916639],
          [44.912184, 36.91586],
          [44.911957, 36.915492],
          [44.911646, 36.915117],
          [44.911511, 36.91456],
          [44.911508, 36.91425],
          [44.911755, 36.913919],
          [44.911838, 36.913712],
          [44.911728, 36.912794],
          [44.911466, 36.912585],
          [44.910541, 36.912335],
          [44.910133, 36.912132],
          [44.909796, 36.911819],
          [44.909661, 36.911476],
          [44.908815, 36.910311],
          [44.9088, 36.910051],
          [44.908939, 36.909544],
          [44.908966, 36.909365],
          [44.909048, 36.908814],
          [44.909203, 36.908294],
          [44.909433, 36.907866],
          [44.909962, 36.907621],
          [44.910435, 36.907206],
          [44.91084, 36.906568],
          [44.911283, 36.905647],
          [44.911417, 36.905207],
          [44.911445, 36.903955],
          [44.911554, 36.903317],
          [44.911701, 36.902928],
          [44.911981, 36.902622],
          [44.912286, 36.901203],
          [44.912191, 36.900879],
          [44.911651, 36.899814],
          [44.91178, 36.89945],
          [44.911768, 36.899015],
          [44.91201, 36.898609],
          [44.912361, 36.898343],
          [44.91258, 36.897803],
          [44.912388, 36.897283],
          [44.911709, 36.896744],
          [44.911068, 36.895911],
          [44.910964, 36.895599],
          [44.911044, 36.895233],
          [44.911338, 36.894988],
          [44.911156, 36.894754],
          [44.911092, 36.894373],
          [44.909592, 36.893681],
          [44.909009, 36.893562],
          [44.908402, 36.893256],
          [44.908021, 36.892823],
          [44.907524, 36.890908],
          [44.906674, 36.889739],
          [44.906465, 36.889596],
          [44.905614, 36.888565],
          [44.905985, 36.88804],
          [44.905565, 36.887402],
          [44.905498, 36.887137],
          [44.905205, 36.886709],
          [44.904971, 36.886176],
          [44.904883, 36.885829],
          [44.904534, 36.885519],
          [44.904442, 36.885205],
          [44.90406, 36.884815],
          [44.904188, 36.884479],
          [44.904378, 36.884172],
          [44.904698, 36.883821],
          [44.905467, 36.882727],
          [44.905704, 36.882206],
          [44.90581, 36.88167],
          [44.905711, 36.881084],
          [44.905033, 36.880086],
          [44.904496, 36.87954],
          [44.90415, 36.879038],
          [44.903669, 36.878511],
          [44.902968, 36.877927],
          [44.90273, 36.877297],
          [44.902036, 36.876487],
          [44.900535, 36.875698],
          [44.900578, 36.875006],
          [44.901266, 36.874474],
          [44.901958, 36.874205],
          [44.90268, 36.874138],
          [44.903046, 36.873996],
          [44.903265, 36.873691],
          [44.903246, 36.873471],
          [44.902832, 36.872552],
          [44.902857, 36.87205],
          [44.903374, 36.870124],
          [44.903627, 36.868762],
          [44.903681, 36.868177],
          [44.903601, 36.867666],
          [44.902669, 36.866227],
          [44.902617, 36.865875],
          [44.902656, 36.86543],
          [44.902829, 36.864804],
          [44.902835, 36.864179],
          [44.902767, 36.863714],
          [44.903091, 36.863431],
          [44.903349, 36.863287],
          [44.903702, 36.861819],
          [44.903699, 36.86141],
          [44.903508, 36.861113],
          [44.903126, 36.860871],
          [44.902813, 36.860499],
          [44.902139, 36.859968],
          [44.901938, 36.859186],
          [44.901879, 36.858505],
          [44.901359, 36.857176],
          [44.900128, 36.856619],
          [44.899596, 36.856443],
          [44.89911, 36.856395],
          [44.898732, 36.856219],
          [44.898104, 36.856314],
          [44.897179, 36.855751],
          [44.896598, 36.855632],
          [44.896204, 36.855294],
          [44.895999, 36.854893],
          [44.895101, 36.85374],
          [44.894719, 36.853449],
          [44.892883, 36.853626],
          [44.89164, 36.853822],
          [44.891079, 36.853631],
          [44.890608, 36.85359],
          [44.889062, 36.853705],
          [44.888514, 36.853512],
          [44.887946, 36.853147],
          [44.886959, 36.852741],
          [44.886549, 36.852787],
          [44.8845, 36.852388],
          [44.883943, 36.852362],
          [44.883327, 36.852055],
          [44.882932, 36.851912],
          [44.882533, 36.851907],
          [44.881297, 36.852277],
          [44.88087, 36.85216],
          [44.880232, 36.851467],
          [44.879935, 36.851257],
          [44.879653, 36.850958],
          [44.87975, 36.850638],
          [44.879756, 36.850413],
          [44.87967, 36.850077],
          [44.879213, 36.849692],
          [44.878962, 36.849562],
          [44.878204, 36.8493],
          [44.8772, 36.849132],
          [44.876621, 36.849168],
          [44.876133, 36.849062],
          [44.875658, 36.848856],
          [44.874989, 36.848295],
          [44.874714, 36.84817],
          [44.874576, 36.847785],
          [44.874278, 36.847469],
          [44.874174, 36.847215],
          [44.873343, 36.846868],
          [44.872628, 36.846228],
          [44.87088, 36.845951],
          [44.870045, 36.845888],
          [44.869552, 36.845763],
          [44.868674, 36.845834],
          [44.867641, 36.8461],
          [44.867145, 36.846112],
          [44.865807, 36.845536],
          [44.86539, 36.845407],
          [44.864969, 36.845464],
          [44.864491, 36.845697],
          [44.862625, 36.84586],
          [44.861645, 36.84633],
          [44.86133, 36.846348],
          [44.861483, 36.845648],
          [44.861449, 36.845215],
          [44.861495, 36.844895],
          [44.861244, 36.844219],
          [44.86112, 36.843082],
          [44.861198, 36.842541],
          [44.861635, 36.841848],
          [44.861417, 36.841547],
          [44.861112, 36.83936],
          [44.861091, 36.83798],
          [44.861241, 36.837514],
          [44.86144, 36.837195],
          [44.862181, 36.836739],
          [44.862778, 36.835822],
          [44.863149, 36.835472],
          [44.863746, 36.835116],
          [44.864153, 36.834548],
          [44.864482, 36.834351],
          [44.865264, 36.83413],
          [44.865657, 36.833923],
          [44.865928, 36.833623],
          [44.866017, 36.832951],
          [44.86626, 36.832752],
          [44.86684, 36.831883],
          [44.865898, 36.831808],
          [44.865453, 36.831876],
          [44.864994, 36.832006],
          [44.863734, 36.831828],
          [44.861878, 36.831805],
          [44.861299, 36.831843],
          [44.860042, 36.832135],
          [44.859934, 36.832238],
          [44.859696, 36.832261],
          [44.85946, 36.83238],
          [44.859183, 36.832372],
          [44.858041, 36.832016],
          [44.857685, 36.831648],
          [44.857119, 36.831246],
          [44.856508, 36.830913],
          [44.85527, 36.83074],
          [44.853844, 36.830381],
          [44.852992, 36.830561],
          [44.852471, 36.830554],
          [44.851798, 36.829864],
          [44.850881, 36.828517],
          [44.850492, 36.828216],
          [44.849642, 36.827723],
          [44.849324, 36.827392],
          [44.849104, 36.826607],
          [44.848771, 36.825938],
          [44.847837, 36.825311],
          [44.84708, 36.824942],
          [44.845995, 36.823859],
          [44.845335, 36.823412],
          [44.844629, 36.822177],
          [44.843999, 36.821322],
          [44.843699, 36.820586],
          [44.843089, 36.820104],
          [44.842602, 36.819803],
          [44.84147, 36.818841],
          [44.840937, 36.817981],
          [44.840109, 36.817145],
          [44.839278, 36.817001],
          [44.839326, 36.816645],
          [44.838666, 36.81612],
          [44.838355, 36.815753],
          [44.838514, 36.815201],
          [44.838489, 36.814794],
          [44.838664, 36.814195],
          [44.839157, 36.813166],
          [44.839164, 36.812378],
          [44.839055, 36.811381],
          [44.839065, 36.810304],
          [44.839256, 36.809746],
          [44.839191, 36.809363],
          [44.838978, 36.809085],
          [44.838647, 36.808888],
          [44.838204, 36.808824],
          [44.837244, 36.808542],
          [44.837166, 36.808144],
          [44.837272, 36.807067],
          [44.837035, 36.806618],
          [44.837085, 36.806207],
          [44.83767, 36.805442],
          [44.83778, 36.804994],
          [44.837672, 36.804541],
          [44.837733, 36.804128],
          [44.837485, 36.803531],
          [44.837594, 36.803382],
          [44.838193, 36.803025],
          [44.838292, 36.802728],
          [44.838057, 36.802445],
          [44.837951, 36.802157],
          [44.838027, 36.800788],
          [44.837996, 36.800417],
          [44.837835, 36.799972],
          [44.837737, 36.799202],
          [44.8373, 36.798717],
          [44.836876, 36.798098],
          [44.836814, 36.797129],
          [44.837491, 36.795718],
          [44.837941, 36.794994],
          [44.838493, 36.794838],
          [44.838764, 36.794529],
          [44.838921, 36.794266],
          [44.839084, 36.793888],
          [44.839176, 36.79347],
          [44.839171, 36.793208],
          [44.839511, 36.792319],
          [44.839589, 36.791877],
          [44.839533, 36.791248],
          [44.839859, 36.790422],
          [44.840143, 36.790059],
          [44.840463, 36.789033],
          [44.840769, 36.788666],
          [44.841333, 36.787755],
          [44.8416, 36.787421],
          [44.841908, 36.78715],
          [44.842241, 36.786979],
          [44.842459, 36.786679],
          [44.842672, 36.785969],
          [44.842846, 36.785747],
          [44.843458, 36.785248],
          [44.843713, 36.784759],
          [44.843981, 36.783672],
          [44.843928, 36.78215],
          [44.844057, 36.781463],
          [44.844303, 36.78052],
          [44.844998, 36.778703],
          [44.845333, 36.778314],
          [44.845624, 36.77815],
          [44.845955, 36.77812],
          [44.846643, 36.778159],
          [44.847162, 36.777922],
          [44.847675, 36.777563],
          [44.848234, 36.77731],
          [44.849294, 36.777301],
          [44.849679, 36.777254],
          [44.850065, 36.777159],
          [44.850659, 36.776898],
          [44.851076, 36.77657],
          [44.852595, 36.775898],
          [44.85345, 36.77521],
          [44.853807, 36.775052],
          [44.854467, 36.774896],
          [44.854747, 36.774655],
          [44.855016, 36.774338],
          [44.854948, 36.774008],
          [44.855001, 36.773772],
          [44.855241, 36.773635],
          [44.856111, 36.773652],
          [44.856738, 36.773571],
          [44.858223, 36.773116],
          [44.858667, 36.773118],
          [44.858981, 36.773799],
          [44.85903, 36.774071],
          [44.859681, 36.77438],
          [44.859786, 36.774589],
          [44.85971, 36.775118],
          [44.860254, 36.775821],
          [44.860535, 36.776123],
          [44.860949, 36.776051],
          [44.861356, 36.776146],
          [44.862115, 36.776057],
          [44.862267, 36.776253],
          [44.862418, 36.77684],
          [44.862932, 36.778009],
          [44.862943, 36.778538],
          [44.863266, 36.778899],
          [44.863796, 36.779319],
          [44.864767, 36.779896],
          [44.865419, 36.779873],
          [44.865894, 36.779945],
          [44.866848, 36.779886],
          [44.867729, 36.780029],
          [44.868037, 36.779934],
          [44.868462, 36.779642],
          [44.868853, 36.779523],
          [44.86924, 36.779482],
          [44.86977, 36.7796],
          [44.870215, 36.779841],
          [44.870833, 36.779855],
          [44.871128, 36.780007],
          [44.871448, 36.780056],
          [44.871769, 36.77991],
          [44.872094, 36.779929],
          [44.872385, 36.779866],
          [44.873059, 36.779949],
          [44.873406, 36.780052],
          [44.873609, 36.779995],
          [44.873957, 36.779805],
          [44.874496, 36.779961],
          [44.874843, 36.779917],
          [44.875814, 36.779907],
          [44.876778, 36.780073],
          [44.877499, 36.78004],
          [44.879434, 36.779747],
          [44.8799, 36.779868],
          [44.880164, 36.779849],
          [44.880598, 36.779994],
          [44.881273, 36.780379],
          [44.882225, 36.781044],
          [44.882733, 36.781322],
          [44.883088, 36.78199],
          [44.884399, 36.782213],
          [44.885286, 36.782508],
          [44.886811, 36.78257],
          [44.887598, 36.782541],
          [44.888663, 36.782411],
          [44.889895, 36.782392],
          [44.890965, 36.782421],
          [44.891235, 36.782481],
          [44.89147, 36.782245],
          [44.891647, 36.781841],
          [44.891977, 36.781429],
          [44.892852, 36.780949],
          [44.893223, 36.780521],
          [44.893678, 36.780123],
          [44.894389, 36.77969],
          [44.895203, 36.779309],
          [44.895587, 36.779216],
          [44.896137, 36.779166],
          [44.896743, 36.778954],
          [44.897477, 36.778845],
          [44.899519, 36.779286],
          [44.900557, 36.779263],
          [44.902425, 36.779098],
          [44.902825, 36.779031],
          [44.903673, 36.778734],
          [44.904309, 36.778429],
          [44.904893, 36.778017],
          [44.905748, 36.777665],
          [44.906185, 36.777643],
          [44.906613, 36.777711],
          [44.90729, 36.778003],
          [44.907811, 36.77817],
          [44.909023, 36.778112],
          [44.910018, 36.777869],
          [44.910364, 36.777676],
          [44.910721, 36.777161],
          [44.910847, 36.776571],
          [44.91098, 36.776246],
          [44.911496, 36.776193],
          [44.91221, 36.776256],
          [44.913151, 36.776218],
          [44.914293, 36.776269],
          [44.915624, 36.776403],
          [44.916615, 36.776267],
          [44.917995, 36.775851],
          [44.91866, 36.775471],
          [44.919004, 36.775459],
          [44.919126, 36.775552],
          [44.919133, 36.775963],
          [44.919431, 36.776246],
          [44.920747, 36.77645],
          [44.92134, 36.776437],
          [44.921841, 36.776528],
          [44.92268, 36.776969],
          [44.924386, 36.77718],
          [44.924823, 36.777282],
          [44.925481, 36.777666],
          [44.927002, 36.778175],
          [44.927611, 36.778461],
          [44.928113, 36.77864],
          [44.928882, 36.779227],
          [44.930037, 36.779489],
          [44.930448, 36.779738],
          [44.93127, 36.781331],
          [44.931385, 36.781467],
          [44.931748, 36.781684],
          [44.932125, 36.78201],
          [44.932236, 36.782235],
          [44.932447, 36.783068],
          [44.932673, 36.783553],
          [44.932728, 36.783996],
          [44.932864, 36.784212],
          [44.933559, 36.784612],
          [44.934488, 36.784913],
          [44.934912, 36.784968],
          [44.935547, 36.784903],
          [44.936167, 36.784947],
          [44.936462, 36.785096],
          [44.937013, 36.785493],
          [44.93827, 36.786129],
          [44.938773, 36.786126],
          [44.940179, 36.785928],
          [44.940995, 36.78521],
          [44.941414, 36.784982],
          [44.942352, 36.784651],
          [44.942538, 36.784499],
          [44.943009, 36.783898],
          [44.943225, 36.783715],
          [44.943618, 36.783189],
          [44.944059, 36.782758],
          [44.944448, 36.782464],
          [44.945131, 36.781783],
          [44.945383, 36.781593],
          [44.945704, 36.781471],
          [44.945829, 36.781342],
          [44.946278, 36.781203],
          [44.946724, 36.780873],
          [44.947219, 36.780317],
          [44.948655, 36.77962],
          [44.949093, 36.77926],
          [44.949227, 36.779042],
          [44.949816, 36.778585],
          [44.949913, 36.778333],
          [44.951381, 36.777289],
          [44.951764, 36.77685],
          [44.951831, 36.776404],
          [44.952119, 36.775799],
          [44.952314, 36.775561],
          [44.952356, 36.77535],
          [44.952597, 36.774855],
          [44.953024, 36.774537],
          [44.953637, 36.774351],
          [44.953834, 36.7741],
          [44.954309, 36.773672],
          [44.954583, 36.773584],
          [44.955119, 36.773518],
          [44.955706, 36.773217],
          [44.95594, 36.773203],
          [44.956344, 36.773085],
          [44.957696, 36.772913],
          [44.958194, 36.772752],
          [44.958298, 36.772059],
          [44.958484, 36.771787],
          [44.958623, 36.771284],
          [44.959071, 36.770372],
          [44.959259, 36.770251],
          [44.959734, 36.769498],
          [44.959973, 36.769239],
          [44.960159, 36.768604],
          [44.960421, 36.768288],
          [44.960932, 36.767973],
          [44.961251, 36.76754],
          [44.961467, 36.766953],
          [44.96156, 36.766462],
          [44.96158, 36.76597],
          [44.961754, 36.765331],
          [44.961945, 36.765099],
          [44.962248, 36.764931],
          [44.962566, 36.764681],
          [44.962951, 36.764366],
          [44.963112, 36.764165],
          [44.963175, 36.76399],
          [44.963092, 36.763018],
          [44.963172, 36.762827],
          [44.963734, 36.762455],
          [44.964028, 36.762093],
          [44.964433, 36.761775],
          [44.965012, 36.761444],
          [44.965809, 36.760818],
          [44.966572, 36.759763],
          [44.967016, 36.75902],
          [44.967232, 36.758494],
          [44.967271, 36.75807],
          [44.967423, 36.757715],
          [44.967308, 36.757252],
          [44.967324, 36.756641],
          [44.967459, 36.756369],
          [44.967562, 36.755894],
          [44.967807, 36.755594],
          [44.967869, 36.755419],
          [44.96782, 36.755237],
          [44.967996, 36.754403],
          [44.967957, 36.753757],
          [44.968093, 36.753525],
          [44.968856, 36.752651],
          [44.968941, 36.752357],
          [44.968869, 36.751678],
          [44.969078, 36.751441],
          [44.970755, 36.750756],
          [44.971041, 36.750524],
          [44.971583, 36.750218],
          [44.972088, 36.750001],
          [44.972409, 36.749348],
          [44.972765, 36.749292],
          [44.973149, 36.749303],
          [44.974624, 36.749098],
          [44.975088, 36.748951],
          [44.975354, 36.74892],
          [44.975676, 36.748961],
          [44.976193, 36.74884],
          [44.976806, 36.748575],
          [44.978387, 36.748368],
          [44.978797, 36.748429],
          [44.979314, 36.748443],
          [44.980171, 36.748386],
          [44.981147, 36.74819],
          [44.982268, 36.748419],
          [44.982892, 36.748476],
          [44.983773, 36.74828],
          [44.984186, 36.748079],
          [44.984662, 36.747749],
          [44.9851, 36.747687],
          [44.985455, 36.747677],
          [44.985812, 36.747765],
          [44.986087, 36.747949],
          [44.986353, 36.748009],
          [44.986809, 36.747939],
          [44.987287, 36.747945],
          [44.987665, 36.747871],
          [44.988364, 36.747839],
          [44.989509, 36.747659],
          [44.989719, 36.747782],
          [44.990014, 36.748131],
          [44.990194, 36.748224],
          [44.990623, 36.748621],
          [44.990951, 36.749052],
          [44.991105, 36.749372],
          [44.991546, 36.750015],
          [44.99284, 36.750786],
          [44.993595, 36.750877],
          [44.994313, 36.750753],
          [44.99453, 36.750778],
          [44.994938, 36.751059],
          [44.996217, 36.752344],
          [44.997001, 36.75289],
          [44.997655, 36.753217],
          [44.998307, 36.753456],
          [44.999709, 36.753323],
          [45.001234, 36.752872],
          [45.001765, 36.752879],
          [45.002714, 36.753034],
          [45.003814, 36.753602],
          [45.004696, 36.753835],
          [45.00547, 36.753964],
          [45.006016, 36.754277],
          [45.006529, 36.754397],
          [45.006873, 36.754216],
          [45.007204, 36.754159],
          [45.008004, 36.75412],
          [45.008584, 36.753953],
          [45.009264, 36.753858],
          [45.009861, 36.753875],
          [45.01059, 36.753771],
          [45.011072, 36.753577],
          [45.012914, 36.753236],
          [45.013028, 36.752975],
          [45.012966, 36.752601],
          [45.013049, 36.751773],
          [45.012962, 36.750809],
          [45.012825, 36.75002],
          [45.012669, 36.749122],
          [45.012085, 36.747244],
          [45.012046, 36.74669],
          [45.012099, 36.746439],
          [45.012568, 36.746247],
          [45.013726, 36.746157],
          [45.014138, 36.74624],
          [45.01478, 36.746106],
          [45.015938, 36.745972],
          [45.016461, 36.745958],
          [45.016951, 36.74583],
          [45.017474, 36.745475],
          [45.018017, 36.744589],
          [45.018305, 36.744012],
          [45.018518, 36.743868],
          [45.01885, 36.743745],
          [45.019564, 36.743863],
          [45.020274, 36.743874],
          [45.020724, 36.743809],
          [45.022137, 36.743763],
          [45.023246, 36.743846],
          [45.023968, 36.743717],
          [45.024669, 36.743237],
          [45.02513, 36.74326],
          [45.025403, 36.743107],
          [45.02569, 36.742725],
          [45.026078, 36.742572],
          [45.026739, 36.742405],
          [45.027064, 36.742164],
          [45.027419, 36.742125],
          [45.028387, 36.741788],
          [45.028625, 36.741641],
          [45.028783, 36.741389],
          [45.029128, 36.741174],
          [45.029591, 36.74102],
          [45.029953, 36.74096],
          [45.030589, 36.740471],
          [45.031236, 36.739745],
          [45.031804, 36.738963],
          [45.032064, 36.738301],
          [45.032184, 36.737494],
          [45.032327, 36.737048],
          [45.032761, 36.736514],
          [45.033142, 36.736244],
          [45.033416, 36.735953],
          [45.033133, 36.735656],
          [45.032727, 36.735418],
          [45.032115, 36.735214],
          [45.031437, 36.734903],
          [45.031789, 36.734117],
          [45.03162, 36.733496],
          [45.031639, 36.73191],
          [45.031704, 36.731319],
          [45.031584, 36.730925],
          [45.031749, 36.730328],
          [45.032014, 36.729822],
          [45.032898, 36.728624],
          [45.033873, 36.727578],
          [45.034613, 36.72719],
          [45.035123, 36.727116],
          [45.035668, 36.726711],
          [45.036136, 36.726438],
          [45.037254, 36.726047],
          [45.037536, 36.725882],
          [45.037828, 36.724919],
          [45.038124, 36.724664],
          [45.038658, 36.724586],
          [45.039171, 36.724659],
          [45.039611, 36.724655],
          [45.039994, 36.724208],
          [45.040521, 36.724013],
          [45.040694, 36.723817],
          [45.039883, 36.722929],
          [45.039623, 36.72244],
          [45.0397, 36.721847],
          [45.039934, 36.721317],
          [45.040711, 36.720146],
          [45.041022, 36.719898],
          [45.041475, 36.719893],
          [45.041899, 36.720059],
          [45.042477, 36.720003],
          [45.043665, 36.71904],
          [45.044708, 36.718376],
          [45.045167, 36.718025],
          [45.045602, 36.717816],
          [45.046043, 36.717674],
          [45.046535, 36.715646],
          [45.047019, 36.71474],
          [45.047448, 36.714463],
          [45.047731, 36.713472],
          [45.047821, 36.712877],
          [45.048126, 36.712561],
          [45.049039, 36.711978],
          [45.049423, 36.711629],
          [45.049781, 36.710587],
          [45.05028, 36.710013],
          [45.050411, 36.709706],
          [45.050905, 36.709113],
          [45.051379, 36.708907],
          [45.0519, 36.708369],
          [45.052308, 36.708105],
          [45.05257, 36.707589],
          [45.053349, 36.706379],
          [45.054516, 36.70483],
          [45.054912, 36.704479],
          [45.055245, 36.703942],
          [45.055651, 36.703443],
          [45.056105, 36.703122],
          [45.057093, 36.702536],
          [45.057272, 36.702221],
          [45.057627, 36.701121],
          [45.057934, 36.700814],
          [45.058168, 36.700422],
          [45.058639, 36.699931],
          [45.059185, 36.699113],
          [45.059171, 36.698693],
          [45.059046, 36.698192],
          [45.059136, 36.697784],
          [45.059404, 36.697425],
          [45.059778, 36.697039],
          [45.060067, 36.696617],
          [45.06025, 36.696135],
          [45.060582, 36.695824],
          [45.061085, 36.695495],
          [45.061518, 36.695139],
          [45.062158, 36.693745],
          [45.062925, 36.692674],
          [45.063907, 36.690821],
          [45.064451, 36.690643],
          [45.06481, 36.690387],
          [45.065172, 36.690003],
          [45.065388, 36.689456],
          [45.065317, 36.688504],
          [45.065681, 36.687991],
          [45.065878, 36.686867],
          [45.06581, 36.685511],
          [45.065473, 36.684603],
          [45.065455, 36.684124],
          [45.065577, 36.68374],
          [45.06553, 36.682509],
          [45.065861, 36.680999],
          [45.066033, 36.680518],
          [45.066206, 36.680218],
          [45.066631, 36.679693],
          [45.067143, 36.679135],
          [45.067445, 36.678623],
          [45.067695, 36.678415],
          [45.067853, 36.678268],
          [45.067862, 36.677797],
          [45.067943, 36.677404],
          [45.068024, 36.676798],
          [45.068097, 36.676306],
          [45.068113, 36.675767],
          [45.068166, 36.675425],
          [45.068253, 36.675055],
          [45.068676, 36.674472],
          [45.068937, 36.673872],
          [45.069207, 36.673279],
          [45.068825, 36.672903],
          [45.067972, 36.670733],
          [45.067956, 36.670158],
          [45.067978, 36.669557],
          [45.068262, 36.668924],
          [45.068356, 36.667899],
          [45.068281, 36.667884],
          [45.068115, 36.666812],
          [45.068051, 36.666703],
          [45.06798, 36.665541],
          [45.06789, 36.664674],
          [45.067898, 36.663996],
          [45.067879, 36.663488],
          [45.067841, 36.662604],
          [45.067878, 36.662316],
          [45.068127, 36.661173],
          [45.068551, 36.65941],
          [45.068766, 36.659249],
          [45.068921, 36.659165],
          [45.069311, 36.658567],
          [45.06964, 36.657448],
          [45.070063, 36.6567],
          [45.070616, 36.655322],
          [45.070706, 36.65455],
          [45.070694, 36.65378],
          [45.070813, 36.653049],
          [45.070907, 36.652599],
          [45.071693, 36.652056],
          [45.071871, 36.651007],
          [45.0718, 36.650379],
          [45.071688, 36.649531],
          [45.071531, 36.648843],
          [45.071301, 36.648204],
          [45.071186, 36.646352],
          [45.071686, 36.645595],
          [45.071193, 36.645119],
          [45.070985, 36.64447],
          [45.071017, 36.64415],
          [45.070852, 36.643751],
          [45.070031, 36.643493],
          [45.06969, 36.64336],
          [45.069443, 36.643222],
          [45.068948, 36.643133],
          [45.068524, 36.643085],
          [45.067639, 36.64307],
          [45.067504, 36.642531],
          [45.067529, 36.642252],
          [45.067273, 36.641815],
          [45.067546, 36.641256],
          [45.068026, 36.640705],
          [45.068382, 36.640528],
          [45.068593, 36.640286],
          [45.068745, 36.638767],
          [45.069314, 36.63833],
          [45.069243, 36.638006],
          [45.06957, 36.637677],
          [45.069594, 36.637397],
          [45.069531, 36.637122],
          [45.069616, 36.636833],
          [45.069902, 36.63654],
          [45.070007, 36.636277],
          [45.069928, 36.635897],
          [45.069941, 36.63553],
          [45.070395, 36.634588],
          [45.07038, 36.634229],
          [45.070321, 36.633875],
          [45.070203, 36.633629],
          [45.069926, 36.633261],
          [45.070038, 36.632751],
          [45.069839, 36.631978],
          [45.070111, 36.631276],
          [45.070371, 36.630845],
          [45.070777, 36.630243],
          [45.070071, 36.629243],
          [45.068956, 36.628088],
          [45.068508, 36.627535],
          [45.068085, 36.626819],
          [45.067758, 36.626086],
          [45.067065, 36.624941],
          [45.066988, 36.624521],
          [45.067067, 36.623702],
          [45.067004, 36.623289],
          [45.066869, 36.622977],
          [45.066393, 36.62221],
          [45.066162, 36.621688],
          [45.065621, 36.619898],
          [45.06534, 36.619423],
          [45.064497, 36.61736],
          [45.064097, 36.616639],
          [45.063834, 36.616003],
          [45.063721, 36.615452],
          [45.063446, 36.615094],
          [45.062974, 36.61466],
          [45.062701, 36.614538],
          [45.061512, 36.614528],
          [45.061122, 36.614671],
          [45.060173, 36.614847],
          [45.059587, 36.614875],
          [45.057632, 36.615158],
          [45.057249, 36.61528],
          [45.056964, 36.615435],
          [45.056508, 36.615588],
          [45.056094, 36.615596],
          [45.054947, 36.615627],
          [45.054398, 36.615563],
          [45.053854, 36.615328],
          [45.053528, 36.615107],
          [45.053127, 36.615025],
          [45.052849, 36.614884],
          [45.052283, 36.614845],
          [45.051805, 36.614644],
          [45.05142, 36.614343],
          [45.05117, 36.614055],
          [45.050753, 36.613293],
          [45.050369, 36.612953],
          [45.049772, 36.612569],
          [45.049328, 36.612146],
          [45.048838, 36.611415],
          [45.048447, 36.611234],
          [45.047293, 36.610822],
          [45.046319, 36.610461],
          [45.045492, 36.610246],
          [45.044311, 36.609675],
          [45.043018, 36.608972],
          [45.042462, 36.608495],
          [45.041568, 36.608171],
          [45.040737, 36.608041],
          [45.040271, 36.607999],
          [45.039134, 36.607764],
          [45.03871, 36.607598],
          [45.037489, 36.607487],
          [45.036637, 36.607635],
          [45.035869, 36.607781],
          [45.033824, 36.607735],
          [45.033148, 36.607573],
          [45.032209, 36.607521],
          [45.0316, 36.607493],
          [45.030948, 36.607236],
          [45.030341, 36.606759],
          [45.030413, 36.60634],
          [45.030146, 36.605935],
          [45.029699, 36.60561],
          [45.028988, 36.605569],
          [45.02838, 36.605446],
          [45.028064, 36.605184],
          [45.027681, 36.604445],
          [45.027442, 36.603934],
          [45.027175, 36.603634],
          [45.026986, 36.603375],
          [45.02691, 36.602975],
          [45.027002, 36.602645],
          [45.027231, 36.602007],
          [45.027135, 36.601747],
          [45.026682, 36.601153],
          [45.026427, 36.600684],
          [45.026374, 36.60026],
          [45.026668, 36.598245],
          [45.026561, 36.597948],
          [45.026487, 36.597311],
          [45.026834, 36.596595],
          [45.027189, 36.596369],
          [45.027533, 36.596243],
          [45.027688, 36.595933],
          [45.027719, 36.594595],
          [45.027942, 36.594303],
          [45.028145, 36.594129],
          [45.028237, 36.593755],
          [45.028304, 36.5921],
          [45.028393, 36.591321],
          [45.028714, 36.590777],
          [45.028637, 36.589486],
          [45.029168, 36.588784],
          [45.029862, 36.587868],
          [45.030064, 36.587297],
          [45.030757, 36.586957],
          [45.03165, 36.586385],
          [45.032089, 36.585274],
          [45.032587, 36.584701],
          [45.033275, 36.583653],
          [45.03384, 36.583249],
          [45.034024, 36.5827],
          [45.034315, 36.582236],
          [45.035056, 36.581746],
          [45.035552, 36.581703],
          [45.035921, 36.581945],
          [45.036305, 36.582051],
          [45.037048, 36.581221],
          [45.037844, 36.580703],
          [45.039384, 36.579903],
          [45.039642, 36.579058],
          [45.039302, 36.578538],
          [45.039168, 36.577955],
          [45.038947, 36.577487],
          [45.038668, 36.576894],
          [45.038557, 36.576006],
          [45.038698, 36.575435],
          [45.038982, 36.574992],
          [45.038762, 36.573668],
          [45.038714, 36.57308],
          [45.038593, 36.5726],
          [45.038571, 36.572065],
          [45.038718, 36.571616],
          [45.038407, 36.571214],
          [45.038325, 36.570689],
          [45.038333, 36.57013],
          [45.038583, 36.569352],
          [45.038811, 36.568881],
          [45.038902, 36.568308],
          [45.039146, 36.567853],
          [45.038857, 36.56655],
          [45.038954, 36.565608],
          [45.038814, 36.564951],
          [45.038515, 36.564594],
          [45.037974, 36.564352],
          [45.037385, 36.56358],
          [45.037619, 36.563182],
          [45.037712, 36.562912],
          [45.037605, 36.562287],
          [45.037688, 36.561783],
          [45.03762, 36.561312],
          [45.037641, 36.560903],
          [45.037445, 36.560415],
          [45.036561, 36.560334],
          [45.035194, 36.560001],
          [45.034576, 36.5598],
          [45.033916, 36.559776],
          [45.032618, 36.560064],
          [45.031744, 36.560075],
          [45.031365, 36.559845],
          [45.030168, 36.558757],
          [45.028238, 36.557656],
          [45.027907, 36.557616],
          [45.026496, 36.557696],
          [45.024436, 36.55726],
          [45.023963, 36.55729],
          [45.023303, 36.557464],
          [45.022821, 36.557515],
          [45.022179, 36.557413],
          [45.020425, 36.556718],
          [45.019999, 36.556741],
          [45.019469, 36.556941],
          [45.019124, 36.556846],
          [45.018594, 36.556896],
          [45.017684, 36.557158],
          [45.017235, 36.557336],
          [45.016524, 36.557452],
          [45.015582, 36.557682],
          [45.015146, 36.557423],
          [45.01471, 36.557259],
          [45.014181, 36.556675],
          [45.01371, 36.556318],
          [45.013215, 36.556116],
          [45.012738, 36.556184],
          [45.011601, 36.556078],
          [45.010875, 36.555942],
          [45.010815, 36.555602],
          [45.010911, 36.555048],
          [45.010915, 36.554424],
          [45.010804, 36.553932],
          [45.010635, 36.553554],
          [45.010214, 36.553151],
          [45.009631, 36.552349],
          [45.009194, 36.552034],
          [45.008468, 36.551747],
          [45.007861, 36.551638],
          [45.007043, 36.551678],
          [45.006258, 36.551845],
          [45.005179, 36.551823],
          [45.004416, 36.551637],
          [45.004268, 36.55134],
          [45.004405, 36.550949],
          [45.004311, 36.550672],
          [45.00427, 36.55031],
          [45.004808, 36.549107],
          [45.006341, 36.547637],
          [45.006961, 36.547007],
          [45.007134, 36.546761],
          [45.005096, 36.542577],
          [45.004854, 36.542139],
          [45.00456, 36.54146],
          [45.003807, 36.54062],
          [45.003516, 36.540195],
          [45.003158, 36.539999],
          [45.002711, 36.539544],
          [45.002363, 36.539336],
          [45.002129, 36.538798],
          [45.001556, 36.538382],
          [45.001781, 36.537307],
          [45.002177, 36.536571],
          [45.002449, 36.53544],
          [45.003626, 36.535162],
          [45.004129, 36.534853],
          [45.004771, 36.534559],
          [45.005136, 36.53414],
          [45.00511, 36.533644],
          [45.004724, 36.532838],
          [45.004863, 36.532164],
          [45.004785, 36.531704],
          [45.004998, 36.53132],
          [45.006007, 36.530965],
          [45.006308, 36.530586],
          [45.006846, 36.530223],
          [45.007001, 36.529509],
          [45.006958, 36.527315],
          [45.007082, 36.526331],
          [45.007449, 36.524987],
          [45.007866, 36.524238],
          [45.00827, 36.523926],
          [45.008868, 36.523309],
          [45.009276, 36.523213],
          [45.009913, 36.523194],
          [45.010372, 36.522958],
          [45.01089, 36.522609],
          [45.01122, 36.5223],
          [45.011202, 36.521934],
          [45.011528, 36.521116],
          [45.01171, 36.520906],
          [45.01203, 36.520406],
          [45.012269, 36.520031],
          [45.012812, 36.519441],
          [45.013635, 36.519079],
          [45.014052, 36.518822],
          [45.014581, 36.518717],
          [45.014799, 36.518501],
          [45.015022, 36.518408],
          [45.015657, 36.517983],
          [45.015953, 36.517982],
          [45.016219, 36.518061],
          [45.016505, 36.517919],
          [45.01673, 36.517882],
          [45.017435, 36.51789],
          [45.017731, 36.517936],
          [45.019371, 36.517534],
          [45.020732, 36.517302],
          [45.020944, 36.517163],
          [45.021034, 36.516884],
          [45.021641, 36.516643],
          [45.022099, 36.516303],
          [45.022415, 36.516138],
          [45.02343, 36.515754],
          [45.023702, 36.515558],
          [45.026514, 36.514663],
          [45.02906, 36.514774],
          [45.030633, 36.515045],
          [45.031231, 36.515362],
          [45.031795, 36.514782],
          [45.034218, 36.511907],
          [45.034681, 36.511491],
          [45.03522, 36.511176],
          [45.035704, 36.51104],
          [45.0368, 36.510539],
          [45.037405, 36.510241],
          [45.037794, 36.50997],
          [45.038195, 36.509847],
          [45.03857, 36.509918],
          [45.038735, 36.510033],
          [45.039992, 36.51027],
          [45.040466, 36.510438],
          [45.041493, 36.51129],
          [45.042467, 36.511385],
          [45.042982, 36.51132],
          [45.044424, 36.511263],
          [45.044865, 36.511152],
          [45.045681, 36.510612],
          [45.045965, 36.510122],
          [45.046676, 36.509656],
          [45.047323, 36.509531],
          [45.0476, 36.509599],
          [45.048207, 36.509461],
          [45.048405, 36.509514],
          [45.048675, 36.509507],
          [45.048858, 36.509402],
          [45.049292, 36.509359],
          [45.049501, 36.509108],
          [45.049273, 36.508937],
          [45.049147, 36.508731],
          [45.049238, 36.508452],
          [45.049248, 36.508195],
          [45.049631, 36.507802],
          [45.049921, 36.507679],
          [45.049998, 36.507345],
          [45.05013, 36.507134],
          [45.050407, 36.506909],
          [45.051096, 36.506702],
          [45.051605, 36.506666],
          [45.052246, 36.506466],
          [45.053169, 36.506504],
          [45.053896, 36.506649],
          [45.053994, 36.505884],
          [45.053959, 36.504825],
          [45.053896, 36.504231],
          [45.053447, 36.502435],
          [45.053395, 36.501933],
          [45.053481, 36.501461],
          [45.053512, 36.500847],
          [45.053406, 36.500349],
          [45.053495, 36.499891],
          [45.053658, 36.499482],
          [45.053724, 36.499079],
          [45.054087, 36.498656],
          [45.054267, 36.498366],
          [45.054594, 36.498043],
          [45.054976, 36.497768],
          [45.055053, 36.497633],
          [45.055086, 36.497495],
          [45.055005, 36.497244],
          [45.0548, 36.497089],
          [45.054716, 36.496753],
          [45.054693, 36.495997],
          [45.05493, 36.49507],
          [45.055138, 36.494767],
          [45.05524, 36.494509],
          [45.055275, 36.494031],
          [45.055031, 36.493429],
          [45.054608, 36.492946],
          [45.054412, 36.492628],
          [45.054477, 36.49114],
          [45.054885, 36.490378],
          [45.055048, 36.489573],
          [45.05478, 36.48922],
          [45.054484, 36.488603],
          [45.054419, 36.487579],
          [45.054609, 36.486806],
          [45.054537, 36.486275],
          [45.054574, 36.48531],
          [45.052281, 36.484593],
          [45.053923, 36.482977],
          [45.05404, 36.482448],
          [45.054048, 36.48227],
          [45.054409, 36.481914],
          [45.054482, 36.481689],
          [45.054682, 36.481501],
          [45.054834, 36.481244],
          [45.054949, 36.48089],
          [45.055177, 36.480546],
          [45.055763, 36.480025],
          [45.056092, 36.479588],
          [45.05678, 36.478937],
          [45.056963, 36.477915],
          [45.057291, 36.477351],
          [45.057605, 36.477078],
          [45.057823, 36.47674],
          [45.057949, 36.476431],
          [45.05799, 36.476151],
          [45.058258, 36.475965],
          [45.058413, 36.475595],
          [45.059455, 36.475027],
          [45.059268, 36.474746],
          [45.059191, 36.474414],
          [45.058446, 36.473705],
          [45.057947, 36.472994],
          [45.058769, 36.471447],
          [45.05904, 36.470557],
          [45.059107, 36.470206],
          [45.059105, 36.469805],
          [45.059319, 36.468759],
          [45.059347, 36.468353],
          [45.059514, 36.467839],
          [45.059727, 36.466848],
          [45.060156, 36.466041],
          [45.060367, 36.465801],
          [45.060678, 36.465597],
          [45.06078, 36.465408],
          [45.060908, 36.464767],
          [45.061401, 36.464099],
          [45.061753, 36.463253],
          [45.062314, 36.46285],
          [45.062465, 36.462791],
          [45.062663, 36.46258],
          [45.063493, 36.462252],
          [45.063711, 36.46208],
          [45.064032, 36.461965],
          [45.064484, 36.461911],
          [45.065147, 36.461756],
          [45.065415, 36.461624],
          [45.065764, 36.461652],
          [45.066042, 36.461603],
          [45.066285, 36.461704],
          [45.066588, 36.46172],
          [45.066855, 36.461614],
          [45.067468, 36.461223],
          [45.067839, 36.461077],
          [45.068207, 36.461007],
          [45.068431, 36.460871],
          [45.068701, 36.460813],
          [45.06928, 36.460508],
          [45.069692, 36.460445],
          [45.069887, 36.46032],
          [45.070343, 36.460287],
          [45.070599, 36.459826],
          [45.07107, 36.459311],
          [45.071537, 36.459036],
          [45.072003, 36.458863],
          [45.072221, 36.458697],
          [45.07259, 36.458301],
          [45.072992, 36.458182],
          [45.07335, 36.457926],
          [45.073765, 36.457714],
          [45.074139, 36.456747],
          [45.074635, 36.456099],
          [45.074926, 36.455925],
          [45.075542, 36.455779],
          [45.076158, 36.455339],
          [45.076223, 36.455056],
          [45.076022, 36.45475],
          [45.075988, 36.454005],
          [45.075797, 36.453052],
          [45.075869, 36.452874],
          [45.076744, 36.452053],
          [45.076943, 36.45165],
          [45.0771, 36.451489],
          [45.077245, 36.451229],
          [45.077323, 36.450948],
          [45.077677, 36.450342],
          [45.077705, 36.450124],
          [45.078033, 36.449213],
          [45.078278, 36.44886],
          [45.078418, 36.447774],
          [45.078336, 36.446941],
          [45.078514, 36.446627],
          [45.078746, 36.445573],
          [45.078551, 36.445197],
          [45.078727, 36.444937],
          [45.07879, 36.444606],
          [45.078744, 36.443798],
          [45.078577, 36.443508],
          [45.078482, 36.442405],
          [45.078617, 36.442259],
          [45.078678, 36.44179],
          [45.078202, 36.441016],
          [45.078229, 36.440691],
          [45.078373, 36.440495],
          [45.078203, 36.440056],
          [45.078222, 36.439861],
          [45.077771, 36.439317],
          [45.077706, 36.439168],
          [45.077468, 36.439003],
          [45.076881, 36.438797],
          [45.077167, 36.438592],
          [45.07705, 36.438405],
          [45.076495, 36.438103],
          [45.076153, 36.438009],
          [45.076035, 36.437518],
          [45.076105, 36.437389],
          [45.076393, 36.437295],
          [45.076859, 36.437021],
          [45.076951, 36.436812],
          [45.076912, 36.436606],
          [45.077025, 36.436506],
          [45.077105, 36.436236],
          [45.077311, 36.435341],
          [45.077321, 36.434929],
          [45.077528, 36.434605],
          [45.077676, 36.433997],
          [45.077826, 36.433635],
          [45.077836, 36.433356],
          [45.077697, 36.433082],
          [45.077776, 36.43277],
          [45.078042, 36.432589],
          [45.078115, 36.432416],
          [45.077974, 36.431961],
          [45.078226, 36.43181],
          [45.078288, 36.431511],
          [45.078171, 36.43069],
          [45.078198, 36.43053],
          [45.078592, 36.430503],
          [45.078935, 36.430368],
          [45.080298, 36.429657],
          [45.081107, 36.428981],
          [45.081325, 36.42858],
          [45.081442, 36.427872],
          [45.081418, 36.427545],
          [45.081522, 36.427239],
          [45.081478, 36.426469],
          [45.081591, 36.425878],
          [45.081587, 36.42549],
          [45.082205, 36.425327],
          [45.084174, 36.424978],
          [45.084869, 36.424695],
          [45.085028, 36.424577],
          [45.085335, 36.424218],
          [45.085714, 36.423581],
          [45.085905, 36.423531],
          [45.086268, 36.423536],
          [45.086738, 36.423282],
          [45.086886, 36.423144],
          [45.086933, 36.42296],
          [45.086912, 36.422782],
          [45.087097, 36.422408],
          [45.087331, 36.422324],
          [45.087889, 36.422236],
          [45.088121, 36.422078],
          [45.088361, 36.422025],
          [45.089438, 36.422047],
          [45.089899, 36.421976],
          [45.090241, 36.421873],
          [45.090537, 36.421853],
          [45.090846, 36.421968],
          [45.09137, 36.422302],
          [45.09189, 36.422348],
          [45.092575, 36.422206],
          [45.092696, 36.422057],
          [45.092805, 36.421771],
          [45.093021, 36.421594],
          [45.093249, 36.421479],
          [45.093489, 36.421426],
          [45.094538, 36.420899],
          [45.094861, 36.420692],
          [45.09527, 36.420279],
          [45.095952, 36.419859],
          [45.096311, 36.419711],
          [45.096686, 36.419644],
          [45.096831, 36.419555],
          [45.09738, 36.419489],
          [45.09805, 36.419072],
          [45.099135, 36.418933],
          [45.100025, 36.418456],
          [45.100565, 36.418243],
          [45.100752, 36.418109],
          [45.101186, 36.417925],
          [45.101444, 36.417741],
          [45.101698, 36.417632],
          [45.10246, 36.416708],
          [45.103153, 36.416345],
          [45.103755, 36.416095],
          [45.103794, 36.416003],
          [45.104028, 36.415823],
          [45.10377, 36.415277],
          [45.103467, 36.414957],
          [45.10334, 36.413983],
          [45.103403, 36.413822],
          [45.10335, 36.413341],
          [45.103864, 36.413085],
          [45.104545, 36.412895],
          [45.104805, 36.412849],
          [45.10528, 36.412952],
          [45.106359, 36.412787],
          [45.106645, 36.41271],
          [45.106802, 36.412586],
          [45.107537, 36.412312],
          [45.108031, 36.412219],
          [45.108421, 36.41207],
          [45.108804, 36.411784],
          [45.108931, 36.411564],
          [45.10888, 36.411125],
          [45.109014, 36.410414],
          [45.109367, 36.409866],
          [45.109648, 36.409571],
          [45.109839, 36.409287],
          [45.110181, 36.409216],
          [45.111591, 36.40855],
          [45.112679, 36.407899],
          [45.112963, 36.407486],
          [45.112964, 36.407262],
          [45.113199, 36.407018],
          [45.113289, 36.4064],
          [45.1137, 36.405762],
          [45.114, 36.405735],
          [45.11445, 36.405437],
          [45.115119, 36.405414],
          [45.11573, 36.405541],
          [45.115912, 36.405647],
          [45.116141, 36.405708],
          [45.116863, 36.405694],
          [45.117018, 36.405691],
          [45.117622, 36.405819],
          [45.117738, 36.406236],
          [45.118163, 36.406272],
          [45.118408, 36.406218],
          [45.118639, 36.406252],
          [45.11915, 36.406476],
          [45.119533, 36.406525],
          [45.119847, 36.406768],
          [45.120123, 36.406911],
          [45.120402, 36.407363],
          [45.121001, 36.407828],
          [45.121644, 36.407863],
          [45.121972, 36.40805],
          [45.12255, 36.407974],
          [45.123169, 36.407678],
          [45.123778, 36.407634],
          [45.124088, 36.407457],
          [45.124548, 36.406917],
          [45.125311, 36.406329],
          [45.125492, 36.406062],
          [45.125789, 36.405951],
          [45.126332, 36.405887],
          [45.126773, 36.405574],
          [45.127915, 36.405047],
          [45.128682, 36.404879],
          [45.12914, 36.404888],
          [45.129748, 36.405005],
          [45.130105, 36.405011],
          [45.13055, 36.405156],
          [45.131182, 36.405566],
          [45.131892, 36.405723],
          [45.133542, 36.406305],
          [45.134911, 36.40698],
          [45.135272, 36.40691],
          [45.135616, 36.406983],
          [45.136124, 36.407197],
          [45.136527, 36.407216],
          [45.136665, 36.407085],
          [45.137096, 36.407254],
          [45.137317, 36.407401],
          [45.137759, 36.407145],
          [45.138086, 36.407045],
          [45.1385, 36.407023],
          [45.139334, 36.406634],
          [45.139699, 36.406579],
          [45.139973, 36.406468],
          [45.140277, 36.406231],
          [45.140657, 36.406079],
          [45.140997, 36.405961],
          [45.141342, 36.405983],
          [45.141529, 36.405814],
          [45.142085, 36.405642],
          [45.142488, 36.405193],
          [45.142811, 36.405183],
          [45.143164, 36.405089],
          [45.14338, 36.405097],
          [45.143585, 36.404987],
          [45.144131, 36.404806],
          [45.144398, 36.405067],
          [45.14492, 36.40524],
          [45.145872, 36.405467],
          [45.146292, 36.40547],
          [45.147005, 36.405609],
          [45.14715, 36.405531],
          [45.147226, 36.405387],
          [45.147619, 36.405197],
          [45.147977, 36.404846],
          [45.148562, 36.404731],
          [45.148706, 36.404627],
          [45.148966, 36.404575],
          [45.149108, 36.404341],
          [45.149525, 36.404413],
          [45.14984, 36.404419],
          [45.150683, 36.404619],
          [45.150936, 36.404793],
          [45.151016, 36.404914],
          [45.151361, 36.405062],
          [45.151638, 36.405273],
          [45.151891, 36.405301],
          [45.152248, 36.405572],
          [45.152483, 36.405686],
          [45.152628, 36.405854],
          [45.152816, 36.406624],
          [45.152992, 36.406875],
          [45.153411, 36.407119],
          [45.153885, 36.407259],
          [45.153886, 36.407656],
          [45.154149, 36.408564],
          [45.154163, 36.408614],
          [45.153993, 36.408942],
          [45.153848, 36.410248],
          [45.153642, 36.410707],
          [45.153677, 36.410947],
          [45.153868, 36.411351],
          [45.153802, 36.412047],
          [45.153928, 36.412317],
          [45.1539, 36.412641],
          [45.153825, 36.412847],
          [45.154087, 36.413197],
          [45.15437, 36.414113],
          [45.154589, 36.414445],
          [45.154638, 36.414608],
          [45.154871, 36.414901],
          [45.155234, 36.415813],
          [45.154797, 36.416006],
          [45.154256, 36.416144],
          [45.154108, 36.416275],
          [45.154153, 36.416424],
          [45.154369, 36.416516],
          [45.154783, 36.417016],
          [45.155122, 36.41706],
          [45.156013, 36.416817],
          [45.156575, 36.416769],
          [45.157748, 36.417004],
          [45.158513, 36.417008],
          [45.159227, 36.417152],
          [45.159933, 36.417188],
          [45.160147, 36.41715],
          [45.160644, 36.417155],
          [45.161917, 36.417017],
          [45.162415, 36.417132],
          [45.16271, 36.41709],
          [45.162965, 36.417148],
          [45.163706, 36.417549],
          [45.164222, 36.417619],
          [45.164412, 36.417543],
          [45.164634, 36.41755],
          [45.164815, 36.417591],
          [45.165324, 36.417824],
          [45.166741, 36.418224],
          [45.167495, 36.418272],
          [45.167911, 36.41824],
          [45.168244, 36.418306],
          [45.168429, 36.418419],
          [45.168558, 36.418683],
          [45.168765, 36.418766],
          [45.170068, 36.418622],
          [45.170678, 36.418649],
          [45.171143, 36.418613],
          [45.171846, 36.41841],
          [45.173522, 36.41811],
          [45.17412, 36.418061],
          [45.174523, 36.417942],
          [45.174963, 36.417926],
          [45.175434, 36.417973],
          [45.175991, 36.418176],
          [45.176605, 36.418469],
          [45.176965, 36.418394],
          [45.178106, 36.418274],
          [45.178689, 36.418253],
          [45.179211, 36.418014],
          [45.180113, 36.417848],
          [45.180516, 36.41835],
          [45.181508, 36.419108],
          [45.182247, 36.419169],
          [45.18357, 36.419962],
          [45.184409, 36.420272],
          [45.184643, 36.420403],
          [45.185161, 36.420958],
          [45.186674, 36.421487],
          [45.187364, 36.421802],
          [45.188823, 36.422111],
          [45.189654, 36.4225],
          [45.190218, 36.422604],
          [45.19058, 36.422518],
          [45.191142, 36.42247],
          [45.191326, 36.422353],
          [45.191883, 36.422186],
          [45.192482, 36.422262],
          [45.193451, 36.422585],
          [45.193821, 36.422754],
          [45.194233, 36.423083],
          [45.194687, 36.423341],
          [45.195357, 36.423587],
          [45.197007, 36.423972],
          [45.197529, 36.424312],
          [45.197705, 36.424374],
          [45.199106, 36.423889],
          [45.199614, 36.423809],
          [45.200107, 36.423575],
          [45.20051, 36.42321],
          [45.200821, 36.423223],
          [45.201477, 36.423487],
          [45.202262, 36.42366],
          [45.203625, 36.424211],
          [45.203817, 36.424328],
          [45.203929, 36.424637],
          [45.204079, 36.424762],
          [45.20428, 36.424804],
          [45.205313, 36.424698],
          [45.205665, 36.424578],
          [45.206379, 36.424226],
          [45.206901, 36.424107],
          [45.207109, 36.42409],
          [45.20759, 36.424151],
          [45.208077, 36.424043],
          [45.208866, 36.424163],
          [45.20923, 36.424166],
          [45.209555, 36.424249],
          [45.209823, 36.424368],
          [45.210319, 36.424745],
          [45.210558, 36.424853],
          [45.211159, 36.42508],
          [45.212368, 36.425413],
          [45.213442, 36.426501],
          [45.213998, 36.426746],
          [45.214725, 36.42716],
          [45.215343, 36.427712],
          [45.216053, 36.428092],
          [45.216684, 36.428794],
          [45.216852, 36.429104],
          [45.217372, 36.429476],
          [45.217758, 36.429569],
          [45.218117, 36.429832],
          [45.218834, 36.429941],
          [45.219927, 36.430033],
          [45.220337, 36.42996],
          [45.220739, 36.429945],
          [45.221212, 36.430143],
          [45.221407, 36.430166],
          [45.221443, 36.430013],
          [45.221572, 36.429818],
          [45.221881, 36.429637],
          [45.221957, 36.429373],
          [45.222126, 36.429207],
          [45.222426, 36.42908],
          [45.223097, 36.429018],
          [45.223594, 36.42884],
          [45.224327, 36.428757],
          [45.22494, 36.429023],
          [45.225222, 36.429213],
          [45.225429, 36.429255],
          [45.225795, 36.429555],
          [45.226097, 36.429663],
          [45.226529, 36.430139],
          [45.22677, 36.430315],
          [45.227214, 36.430894],
          [45.227421, 36.431061],
          [45.228686, 36.430955],
          [45.228899, 36.430996],
          [45.229071, 36.43142],
          [45.229323, 36.431552],
          [45.229538, 36.431743],
          [45.229764, 36.432058],
          [45.230067, 36.432709],
          [45.230206, 36.432836],
          [45.230686, 36.432975],
          [45.230985, 36.433198],
          [45.231457, 36.433307],
          [45.231804, 36.433276],
          [45.232662, 36.433045],
          [45.233884, 36.432592],
          [45.234801, 36.432705],
          [45.235503, 36.432914],
          [45.235633, 36.432926],
          [45.235801, 36.432941],
          [45.235922, 36.432991],
          [45.236121, 36.433226],
          [45.236262, 36.433291],
          [45.236467, 36.433584],
          [45.23653, 36.434102],
          [45.236723, 36.434077],
          [45.236907, 36.433974],
          [45.237324, 36.433529],
          [45.237614, 36.43308],
          [45.237731, 36.432991],
          [45.237942, 36.432943],
          [45.238363, 36.432988],
          [45.238846, 36.432782],
          [45.239185, 36.432434],
          [45.239379, 36.432102],
          [45.239611, 36.431935],
          [45.23985, 36.431856],
          [45.239893, 36.431603],
          [45.239729, 36.430499],
          [45.239752, 36.429351],
          [45.239809, 36.429022],
          [45.240056, 36.428367],
          [45.240501, 36.427415],
          [45.240876, 36.426873],
          [45.24174, 36.42604],
          [45.241843, 36.425823],
          [45.241962, 36.425719],
          [45.242146, 36.425644],
          [45.24231, 36.4255],
          [45.2424, 36.425343],
          [45.242482, 36.42491],
          [45.242454, 36.424607],
          [45.242308, 36.424315],
          [45.242226, 36.423812],
          [45.242005, 36.423371],
          [45.241973, 36.423184],
          [45.242193, 36.42304],
          [45.242414, 36.422672],
          [45.242585, 36.42249],
          [45.242637, 36.42225],
          [45.242491, 36.421932],
          [45.242115, 36.421435],
          [45.241908, 36.421017],
          [45.241847, 36.42071],
          [45.241532, 36.419894],
          [45.241444, 36.419502],
          [45.241483, 36.41878],
          [45.241623, 36.418353],
          [45.241969, 36.418108],
          [45.242223, 36.417995],
          [45.242773, 36.417845],
          [45.243399, 36.417759],
          [45.243846, 36.417768],
          [45.24416, 36.417707],
          [45.245337, 36.417314],
          [45.245572, 36.417199],
          [45.245762, 36.417144],
          [45.246083, 36.417128],
          [45.2463, 36.417058],
          [45.246795, 36.416646],
          [45.246917, 36.416468],
          [45.247456, 36.416178],
          [45.248104, 36.416042],
          [45.248651, 36.415705],
          [45.249614, 36.415772],
          [45.250049, 36.4157],
          [45.251332, 36.415199],
          [45.251866, 36.414895],
          [45.252237, 36.414505],
          [45.25256, 36.414432],
          [45.252746, 36.413908],
          [45.25264, 36.413493],
          [45.252603, 36.412998],
          [45.252399, 36.412304],
          [45.252417, 36.412133],
          [45.252349, 36.411806],
          [45.252418, 36.410953],
          [45.25235, 36.410667],
          [45.252444, 36.410107],
          [45.252231, 36.409775],
          [45.251938, 36.409634],
          [45.251682, 36.409617],
          [45.250869, 36.409307],
          [45.250622, 36.409132],
          [45.250452, 36.408922],
          [45.250865, 36.407261],
          [45.251001, 36.406903],
          [45.251155, 36.406708],
          [45.251307, 36.406383],
          [45.251333, 36.405804],
          [45.251545, 36.40551],
          [45.25188, 36.405189],
          [45.252302, 36.404947],
          [45.252502, 36.404676],
          [45.25259, 36.404368],
          [45.252808, 36.40401],
          [45.252916, 36.403683],
          [45.252933, 36.402808],
          [45.253289, 36.402222],
          [45.253848, 36.402164],
          [45.254386, 36.401995],
          [45.255188, 36.40204],
          [45.255991, 36.401959],
          [45.257828, 36.401903],
          [45.257999, 36.401971],
          [45.25855, 36.401805],
          [45.258797, 36.401834],
          [45.259157, 36.401676],
          [45.259711, 36.400442],
          [45.259888, 36.399485],
          [45.260107, 36.398994],
          [45.260582, 36.398716],
          [45.26116, 36.398564],
          [45.261368, 36.39845],
          [45.262531, 36.397597],
          [45.263342, 36.397001],
          [45.263775, 36.396819],
          [45.263951, 36.396656],
          [45.264081, 36.396439],
          [45.264132, 36.396193],
          [45.264299, 36.395916],
          [45.264725, 36.395382],
          [45.265557, 36.395201],
          [45.266409, 36.394884],
          [45.267344, 36.394717],
          [45.267426, 36.394522],
          [45.267758, 36.394247],
          [45.268104, 36.394234],
          [45.268059, 36.394673],
          [45.268439, 36.394803],
          [45.268771, 36.394877],
          [45.269622, 36.394824],
          [45.271192, 36.39423],
          [45.271583, 36.394012],
          [45.27199, 36.393884],
          [45.272269, 36.393713],
          [45.272404, 36.393518],
          [45.272289, 36.393021],
          [45.27194, 36.392774],
          [45.271823, 36.392619],
          [45.271796, 36.392403],
          [45.271915, 36.392222],
          [45.272678, 36.391733],
          [45.27283, 36.39094],
          [45.272808, 36.390613],
          [45.272102, 36.390298],
          [45.271808, 36.390218],
          [45.271407, 36.390213],
          [45.27116, 36.389981],
          [45.270878, 36.388675],
          [45.270561, 36.387817],
          [45.271572, 36.386523],
          [45.27226, 36.385117],
          [45.272322, 36.384571],
          [45.272219, 36.384099],
          [45.27228, 36.38384],
          [45.272553, 36.383648],
          [45.272836, 36.383548],
          [45.272888, 36.383335],
          [45.273246, 36.383194],
          [45.273682, 36.38278],
          [45.273783, 36.382657],
          [45.273765, 36.382269],
          [45.273838, 36.382113],
          [45.274585, 36.38193],
          [45.275016, 36.381523],
          [45.275652, 36.380816],
          [45.275735, 36.380664],
          [45.276027, 36.379728],
          [45.276053, 36.378532],
          [45.276161, 36.378276],
          [45.276336, 36.378058],
          [45.276328, 36.377442],
          [45.276426, 36.376961],
          [45.276349, 36.376841],
          [45.276431, 36.376469],
          [45.276292, 36.376313],
          [45.275994, 36.376217],
          [45.275615, 36.375981],
          [45.275396, 36.375756],
          [45.275277, 36.375408],
          [45.274908, 36.374944],
          [45.274693, 36.374735],
          [45.274367, 36.374583],
          [45.273919, 36.374492],
          [45.272901, 36.374558],
          [45.272444, 36.37438],
          [45.271736, 36.374242],
          [45.270939, 36.373904],
          [45.268957, 36.373673],
          [45.268716, 36.372801],
          [45.269016, 36.372317],
          [45.268858, 36.371943],
          [45.268644, 36.371204],
          [45.268645, 36.37105],
          [45.269571, 36.370874],
          [45.271093, 36.370327],
          [45.271359, 36.370131],
          [45.27154, 36.369856],
          [45.272039, 36.369304],
          [45.273448, 36.368541],
          [45.273735, 36.368346],
          [45.273931, 36.368107],
          [45.274389, 36.367893],
          [45.274677, 36.367655],
          [45.27492, 36.367259],
          [45.275163, 36.36705],
          [45.275619, 36.366771],
          [45.275637, 36.366607],
          [45.27527, 36.3661],
          [45.275254, 36.365662],
          [45.275036, 36.365309],
          [45.274685, 36.36503],
          [45.274077, 36.364846],
          [45.274016, 36.364438],
          [45.274116, 36.364233],
          [45.274097, 36.364049],
          [45.273796, 36.363865],
          [45.273716, 36.363725],
          [45.273797, 36.362989],
          [45.273718, 36.362645],
          [45.273554, 36.362349],
          [45.273344, 36.36211],
          [45.27242, 36.361492],
          [45.27226, 36.361163],
          [45.272062, 36.360972],
          [45.271595, 36.360857],
          [45.271478, 36.360674],
          [45.271478, 36.360409],
          [45.271827, 36.359752],
          [45.271725, 36.359097],
          [45.271792, 36.358545],
          [45.271913, 36.358116],
          [45.272151, 36.35793],
          [45.271989, 36.357512],
          [45.272078, 36.357287],
          [45.271859, 36.356902],
          [45.271875, 36.356464],
          [45.272037, 36.356192],
          [45.272054, 36.355594],
          [45.271814, 36.355019],
          [45.271835, 36.354784],
          [45.272057, 36.354623],
          [45.272357, 36.354487],
          [45.272614, 36.354281],
          [45.272751, 36.354092],
          [45.272996, 36.353916],
          [45.273185, 36.353436],
          [45.273552, 36.353117],
          [45.273839, 36.352978],
          [45.274147, 36.352476],
          [45.274427, 36.352256],
          [45.274903, 36.351531],
          [45.274882, 36.351392],
          [45.275092, 36.351101],
          [45.275301, 36.35054],
          [45.275408, 36.34922],
          [45.275377, 36.348856],
          [45.275461, 36.348505],
          [45.275347, 36.347164],
          [45.275225, 36.346778],
          [45.27491, 36.346586],
          [45.274603, 36.346216],
          [45.274512, 36.345847],
          [45.274002, 36.345083],
          [45.274576, 36.344325],
          [45.274816, 36.343892],
          [45.274873, 36.343689],
          [45.275074, 36.34339],
          [45.275463, 36.343332],
          [45.275728, 36.343178],
          [45.275931, 36.343115],
          [45.276101, 36.342902],
          [45.276579, 36.342759],
          [45.276744, 36.342611],
          [45.27765, 36.342445],
          [45.278003, 36.342452],
          [45.279089, 36.342602],
          [45.279767, 36.342584],
          [45.280165, 36.341939],
          [45.280575, 36.341631],
          [45.280851, 36.341582],
          [45.281368, 36.341586],
          [45.281634, 36.341518],
          [45.281808, 36.341412],
          [45.281964, 36.340966],
          [45.282255, 36.340587],
          [45.28208, 36.339889],
          [45.282108, 36.339557],
          [45.282345, 36.338877],
          [45.282897, 36.338264],
          [45.283176, 36.337801],
          [45.283335, 36.337114],
          [45.283314, 36.336817],
          [45.283087, 36.336262],
          [45.282884, 36.336009],
          [45.282604, 36.335839],
          [45.282032, 36.335677],
          [45.281493, 36.335006],
          [45.281242, 36.334799],
          [45.280615, 36.334873],
          [45.280325, 36.334833],
          [45.279811, 36.334672],
          [45.279485, 36.334757],
          [45.279143, 36.334909],
          [45.278866, 36.334899],
          [45.278653, 36.334685],
          [45.278059, 36.333857],
          [45.277272, 36.333557],
          [45.276725, 36.332968],
          [45.276336, 36.332796],
          [45.275892, 36.332666],
          [45.275383, 36.332102],
          [45.275068, 36.331863],
          [45.274662, 36.331812],
          [45.274341, 36.33189],
          [45.273831, 36.331691],
          [45.273311, 36.331419],
          [45.2733, 36.33023],
          [45.272746, 36.329149],
          [45.272158, 36.328202],
          [45.271999, 36.327834],
          [45.272078, 36.327386],
          [45.272262, 36.327037],
          [45.272293, 36.326581],
          [45.272234, 36.326259],
          [45.271952, 36.325853],
          [45.271612, 36.325672],
          [45.271404, 36.325425],
          [45.271324, 36.324983],
          [45.270967, 36.324183],
          [45.270852, 36.323673],
          [45.270675, 36.323361],
          [45.270015, 36.323237],
          [45.269501, 36.32342],
          [45.269205, 36.323408],
          [45.267824, 36.323508],
          [45.267375, 36.323894],
          [45.267125, 36.324034],
          [45.266485, 36.322851],
          [45.266455, 36.322518],
          [45.26655, 36.322115],
          [45.266549, 36.32174],
          [45.266798, 36.321428],
          [45.267089, 36.32136],
          [45.267501, 36.320836],
          [45.267593, 36.320616],
          [45.267537, 36.320337],
          [45.267354, 36.320122],
          [45.267319, 36.319705],
          [45.267215, 36.319439],
          [45.265429, 36.316244],
          [45.264594, 36.314564],
          [45.264513, 36.314144],
          [45.264336, 36.313752],
          [45.264492, 36.313108],
          [45.26484, 36.312182],
          [45.265006, 36.311156],
          [45.264941, 36.310776],
          [45.264855, 36.310507],
          [45.264622, 36.310237],
          [45.264335, 36.310122],
          [45.26321, 36.307769],
          [45.262833, 36.307343],
          [45.262569, 36.307127],
          [45.262324, 36.306774],
          [45.26222, 36.306422],
          [45.261902, 36.305744],
          [45.261832, 36.305285],
          [45.262006, 36.303897],
          [45.262138, 36.303461],
          [45.262119, 36.303202],
          [45.261714, 36.302673],
          [45.261686, 36.302463],
          [45.261523, 36.302278],
          [45.26112, 36.301985],
          [45.260973, 36.301614],
          [45.260964, 36.301289],
          [45.261456, 36.300778],
          [45.261883, 36.300637],
          [45.262042, 36.300518],
          [45.261885, 36.300187],
          [45.261869, 36.299943],
          [45.26192, 36.299773],
          [45.262005, 36.299699],
          [45.262651, 36.299418],
          [45.262791, 36.299045],
          [45.263176, 36.298944],
          [45.263709, 36.299042],
          [45.264317, 36.298913],
          [45.264589, 36.298757],
          [45.264621, 36.297787],
          [45.264734, 36.297552],
          [45.264973, 36.297306],
          [45.265234, 36.297163],
          [45.265347, 36.297014],
          [45.266135, 36.296997],
          [45.266595, 36.296889],
          [45.267584, 36.296317],
          [45.268228, 36.295719],
          [45.268745, 36.295435],
          [45.269566, 36.295396],
          [45.270913, 36.295457],
          [45.271313, 36.295595],
          [45.271694, 36.29593],
          [45.271875, 36.296509],
          [45.272152, 36.297151],
          [45.272249, 36.2973],
          [45.272451, 36.297404],
          [45.27273, 36.297483],
          [45.273097, 36.297525],
          [45.273353, 36.297501],
          [45.273625, 36.297576],
          [45.274009, 36.29761],
          [45.27464, 36.297836],
          [45.275036, 36.297867],
          [45.275319, 36.297962],
          [45.275525, 36.297973],
          [45.275854, 36.297845],
          [45.275977, 36.297255],
          [45.276195, 36.296943],
          [45.27647, 36.296663],
          [45.276732, 36.296579],
          [45.277113, 36.296313],
          [45.277253, 36.296085],
          [45.277449, 36.295905],
          [45.277515, 36.29569],
          [45.277641, 36.295539],
          [45.277766, 36.294642],
          [45.27782, 36.294483],
          [45.277947, 36.294337],
          [45.278466, 36.293864],
          [45.278942, 36.293511],
          [45.279528, 36.293252],
          [45.279882, 36.293178],
          [45.280286, 36.292929],
          [45.280666, 36.292519],
          [45.280947, 36.291895],
          [45.281323, 36.291608],
          [45.281785, 36.291162],
          [45.281828, 36.290983],
          [45.282001, 36.290786],
          [45.282329, 36.290486],
          [45.282832, 36.290139],
          [45.283374, 36.289426],
          [45.283338, 36.289036],
          [45.283589, 36.288477],
          [45.283801, 36.287737],
          [45.283914, 36.287561],
          [45.284033, 36.287465],
          [45.284238, 36.287412],
          [45.284462, 36.287415],
          [45.284712, 36.287451],
          [45.285289, 36.287633],
          [45.285641, 36.28764],
          [45.28642, 36.287443],
          [45.287126, 36.287318],
          [45.287717, 36.286966],
          [45.287781, 36.286681],
          [45.287891, 36.286603],
          [45.288629, 36.286536],
          [45.289003, 36.286411],
          [45.28978, 36.286037],
          [45.29006, 36.285832],
          [45.290842, 36.285537],
          [45.291094, 36.285353],
          [45.291621, 36.284825],
          [45.291751, 36.284668],
          [45.29193, 36.284271],
          [45.292692, 36.283627],
          [45.293464, 36.282771],
          [45.294368, 36.281632],
          [45.294659, 36.281399],
          [45.295055, 36.281186],
          [45.296045, 36.280803],
          [45.297068, 36.280022],
          [45.297468, 36.279827],
          [45.297995, 36.279701],
          [45.298752, 36.279448],
          [45.299083, 36.279249],
          [45.299484, 36.278795],
          [45.299658, 36.278362],
          [45.2998, 36.278189],
          [45.300519, 36.277845],
          [45.300658, 36.27767],
          [45.300772, 36.27745],
          [45.300835, 36.277177],
          [45.300962, 36.277019],
          [45.301088, 36.27693],
          [45.301613, 36.276826],
          [45.301893, 36.276633],
          [45.302013, 36.276426],
          [45.302045, 36.276228],
          [45.301931, 36.275883],
          [45.301809, 36.275512],
          [45.301854, 36.275377],
          [45.302168, 36.275189],
          [45.302591, 36.274764],
          [45.302823, 36.274674],
          [45.303288, 36.274707],
          [45.303664, 36.274851],
          [45.303864, 36.274867],
          [45.304103, 36.274809],
          [45.304209, 36.274706],
          [45.304313, 36.274037],
          [45.304393, 36.273872],
          [45.304553, 36.273747],
          [45.303226, 36.273334],
          [45.30242, 36.272975],
          [45.301956, 36.272828],
          [45.301472, 36.272674],
          [45.301064, 36.272401],
          [45.3009, 36.27209],
          [45.300356, 36.271598],
          [45.30019, 36.27125],
          [45.300133, 36.270925],
          [45.30024, 36.270113],
          [45.300062, 36.269735],
          [45.299958, 36.268927],
          [45.299766, 36.268756],
          [45.299251, 36.268702],
          [45.298709, 36.268814],
          [45.298278, 36.268998],
          [45.297732, 36.269095],
          [45.297301, 36.268983],
          [45.296966, 36.268772],
          [45.296737, 36.268759],
          [45.296436, 36.268915],
          [45.295771, 36.269028],
          [45.29525, 36.268974],
          [45.294786, 36.269003],
          [45.294437, 36.268881],
          [45.29364, 36.268952],
          [45.293222, 36.268897],
          [45.292805, 36.268728],
          [45.292168, 36.268555],
          [45.29178, 36.268516],
          [45.291504, 36.268376],
          [45.291159, 36.268118],
          [45.290159, 36.268026],
          [45.289653, 36.268072],
          [45.289129, 36.267674],
          [45.288404, 36.267452],
          [45.287649, 36.2673],
          [45.287015, 36.266991],
          [45.28645, 36.266913],
          [45.286051, 36.266946],
          [45.285783, 36.266837],
          [45.285205, 36.2664],
          [45.284751, 36.266179],
          [45.28451, 36.26593],
          [45.28431, 36.265571],
          [45.283959, 36.265348],
          [45.283381, 36.26518],
          [45.282949, 36.265003],
          [45.282634, 36.264768],
          [45.281561, 36.264337],
          [45.280976, 36.264435],
          [45.280158, 36.26417],
          [45.279595, 36.263563],
          [45.279301, 36.262801],
          [45.27898, 36.262566],
          [45.278604, 36.262169],
          [45.277206, 36.261429],
          [45.277039, 36.261162],
          [45.276529, 36.26095],
          [45.276252, 36.260773],
          [45.275307, 36.260843],
          [45.274926, 36.260781],
          [45.274543, 36.260649],
          [45.274217, 36.260453],
          [45.273766, 36.259951],
          [45.273061, 36.259342],
          [45.273032, 36.259088],
          [45.272878, 36.258764],
          [45.272407, 36.258195],
          [45.271206, 36.256999],
          [45.270569, 36.256556],
          [45.270258, 36.256131],
          [45.269815, 36.255724],
          [45.269181, 36.255383],
          [45.268092, 36.254583],
          [45.267397, 36.254337],
          [45.265754, 36.253206],
          [45.265897, 36.253103],
          [45.266247, 36.253089],
          [45.266565, 36.252818],
          [45.266976, 36.252314],
          [45.267831, 36.251025],
          [45.267973, 36.250935],
          [45.268601, 36.250829],
          [45.268849, 36.250726],
          [45.269085, 36.250506],
          [45.269951, 36.250217],
          [45.270311, 36.250272],
          [45.270814, 36.250064],
          [45.271338, 36.250074],
          [45.271718, 36.249985],
          [45.272418, 36.249737],
          [45.272714, 36.249555],
          [45.272894, 36.249312],
          [45.273531, 36.248919],
          [45.273627, 36.24874],
          [45.273666, 36.248441],
          [45.27481, 36.247623],
          [45.275677, 36.246925],
          [45.276466, 36.246132],
          [45.276808, 36.245569],
          [45.277458, 36.245028],
          [45.27814, 36.244723],
          [45.280247, 36.244096],
          [45.280762, 36.243767],
          [45.280961, 36.243705],
          [45.282006, 36.243617],
          [45.282361, 36.243738],
          [45.282897, 36.243804],
          [45.283427, 36.243986],
          [45.283924, 36.244232],
          [45.284211, 36.244246],
          [45.284647, 36.244174],
          [45.285105, 36.244028],
          [45.285791, 36.243626],
          [45.286275, 36.243416],
          [45.286866, 36.24329],
          [45.287564, 36.243209],
          [45.288151, 36.243062],
          [45.288524, 36.243028],
          [45.289057, 36.243079],
          [45.289436, 36.242807],
          [45.289698, 36.24241],
          [45.290551, 36.241497],
          [45.290992, 36.241328],
          [45.291229, 36.241086],
          [45.291667, 36.240906],
          [45.292014, 36.240779],
          [45.292311, 36.240754],
          [45.292786, 36.240626],
          [45.293863, 36.240748],
          [45.294357, 36.241033],
          [45.294515, 36.241021],
          [45.295407, 36.240583],
          [45.2958, 36.240464],
          [45.297322, 36.240141],
          [45.298529, 36.239759],
          [45.298873, 36.239833],
          [45.29956, 36.239641],
          [45.300651, 36.239469],
          [45.301526, 36.239406],
          [45.302233, 36.239426],
          [45.303854, 36.239322],
          [45.304572, 36.239389],
          [45.30489, 36.239252],
          [45.305008, 36.239112],
          [45.305308, 36.238978],
          [45.306191, 36.238951],
          [45.306473, 36.239004],
          [45.306842, 36.239008],
          [45.308054, 36.238772],
          [45.30879, 36.238809],
          [45.309689, 36.238255],
          [45.309939, 36.23819],
          [45.310386, 36.238202],
          [45.310976, 36.238308],
          [45.311289, 36.238382],
          [45.311689, 36.238565],
          [45.312069, 36.238589],
          [45.312404, 36.238424],
          [45.312344, 36.23773],
          [45.313153, 36.236651],
          [45.313771, 36.236229],
          [45.313794, 36.236049],
          [45.31405, 36.235626],
          [45.314093, 36.235467],
          [45.313991, 36.234131],
          [45.314358, 36.233359],
          [45.3147, 36.232882],
          [45.315063, 36.232651],
          [45.315024, 36.232323],
          [45.314806, 36.232178],
          [45.314924, 36.231596],
          [45.315091, 36.231264],
          [45.315227, 36.230404],
          [45.315148, 36.230168],
          [45.314955, 36.230012],
          [45.314944, 36.229756],
          [45.314761, 36.229175],
          [45.314667, 36.228976],
          [45.31475, 36.228438],
          [45.314621, 36.22832],
          [45.31436, 36.228215],
          [45.314142, 36.227893],
          [45.31403, 36.227583],
          [45.314053, 36.227363],
          [45.314229, 36.226954],
          [45.314321, 36.226425],
          [45.314208, 36.225846],
          [45.314234, 36.225577],
          [45.314148, 36.225123],
          [45.314464, 36.224231],
          [45.314889, 36.223572],
          [45.3152, 36.222787],
          [45.31525, 36.221726],
          [45.315335, 36.221518],
          [45.315504, 36.221319],
          [45.316144, 36.221037],
          [45.316553, 36.220968],
          [45.317456, 36.220932],
          [45.31797, 36.220328],
          [45.318532, 36.220264],
          [45.31914, 36.220491],
          [45.319733, 36.219778],
          [45.320048, 36.219586],
          [45.321107, 36.219486],
          [45.321465, 36.218845],
          [45.32161, 36.218323],
          [45.322044, 36.217677],
          [45.322193, 36.217232],
          [45.322437, 36.216846],
          [45.322378, 36.216636],
          [45.322573, 36.216296],
          [45.322607, 36.216064],
          [45.323007, 36.215715],
          [45.323576, 36.21578],
          [45.324157, 36.215908],
          [45.324467, 36.215902],
          [45.325005, 36.215737],
          [45.325264, 36.215702],
          [45.326247, 36.215904],
          [45.32732, 36.216299],
          [45.327617, 36.21623],
          [45.328116, 36.215852],
          [45.328225, 36.215608],
          [45.328412, 36.215467],
          [45.328579, 36.215223],
          [45.328868, 36.215242],
          [45.329949, 36.214729],
          [45.330202, 36.214255],
          [45.330328, 36.214131],
          [45.330546, 36.214017],
          [45.330655, 36.213735],
          [45.33047, 36.212791],
          [45.330842, 36.212022],
          [45.330884, 36.211011],
          [45.330989, 36.210639],
          [45.33093, 36.210499],
          [45.330778, 36.210381],
          [45.330934, 36.210214],
          [45.331009, 36.209857],
          [45.331097, 36.209707],
          [45.331074, 36.20955],
          [45.33147, 36.209453],
          [45.331798, 36.209165],
          [45.332211, 36.208985],
          [45.33241, 36.208703],
          [45.332842, 36.208422],
          [45.333199, 36.207774],
          [45.333526, 36.207384],
          [45.333657, 36.207012],
          [45.334033, 36.206741],
          [45.334329, 36.20663],
          [45.335035, 36.205877],
          [45.335138, 36.205634],
          [45.335478, 36.205241],
          [45.335688, 36.204882],
          [45.336004, 36.204633],
          [45.336378, 36.204417],
          [45.336499, 36.204202],
          [45.336511, 36.20406],
          [45.336439, 36.203885],
          [45.336479, 36.203342],
          [45.336439, 36.203236],
          [45.336542, 36.202515],
          [45.336647, 36.202212],
          [45.335923, 36.20198],
          [45.334956, 36.201829],
          [45.334727, 36.201677],
          [45.334243, 36.201108],
          [45.334134, 36.200735],
          [45.333887, 36.200419],
          [45.332746, 36.200138],
          [45.332448, 36.199933],
          [45.331919, 36.199361],
          [45.331243, 36.198767],
          [45.331015, 36.19862],
          [45.330766, 36.198535],
          [45.3303, 36.198059],
          [45.330236, 36.197856],
          [45.329302, 36.196476],
          [45.329036, 36.195858],
          [45.328638, 36.195364],
          [45.328379, 36.194884],
          [45.328145, 36.194743],
          [45.327637, 36.194179],
          [45.327271, 36.194023],
          [45.326969, 36.194034],
          [45.326152, 36.1942],
          [45.325053, 36.194243],
          [45.324776, 36.194319],
          [45.324647, 36.194085],
          [45.324701, 36.193651],
          [45.324626, 36.193455],
          [45.324508, 36.193347],
          [45.324199, 36.192844],
          [45.323988, 36.192717],
          [45.323719, 36.19236],
          [45.323769, 36.192281],
          [45.32449, 36.192122],
          [45.325132, 36.191681],
          [45.325277, 36.191521],
          [45.325366, 36.191312],
          [45.325372, 36.190211],
          [45.324417, 36.189747],
          [45.323958, 36.189442],
          [45.323191, 36.188606],
          [45.32284, 36.188019],
          [45.322627, 36.187885],
          [45.32229, 36.187778],
          [45.319812, 36.186354],
          [45.319092, 36.185864],
          [45.318299, 36.18571],
          [45.318, 36.185467],
          [45.317083, 36.184517],
          [45.316386, 36.183547],
          [45.316454, 36.183258],
          [45.3171, 36.182952],
          [45.317424, 36.182901],
          [45.31819, 36.182906],
          [45.318925, 36.182792],
          [45.319491, 36.182907],
          [45.319923, 36.18283],
          [45.320125, 36.182666],
          [45.320714, 36.182353],
          [45.320919, 36.181935],
          [45.321276, 36.181626],
          [45.321381, 36.181398],
          [45.321504, 36.18085],
          [45.321753, 36.180382],
          [45.321963, 36.180163],
          [45.322053, 36.179884],
          [45.322808, 36.179358],
          [45.322905, 36.179046],
          [45.323201, 36.178693],
          [45.323021, 36.178495],
          [45.322843, 36.177963],
          [45.322842, 36.177346],
          [45.322953, 36.177074],
          [45.322911, 36.176818],
          [45.322672, 36.176238],
          [45.322648, 36.175872],
          [45.322714, 36.175437],
          [45.322838, 36.1752],
          [45.322843, 36.174952],
          [45.322681, 36.174777],
          [45.322159, 36.174483],
          [45.321848, 36.174039],
          [45.321664, 36.17368],
          [45.321156, 36.173222],
          [45.32098, 36.172942],
          [45.320981, 36.172743],
          [45.3212, 36.172426],
          [45.32119, 36.171961],
          [45.321387, 36.171362],
          [45.32145, 36.170772],
          [45.321314, 36.170291],
          [45.320433, 36.168778],
          [45.320397, 36.168388],
          [45.320229, 36.167717],
          [45.320332, 36.167495],
          [45.320702, 36.167449],
          [45.324127, 36.167363],
          [45.324668, 36.167241],
          [45.325412, 36.166793],
          [45.325608, 36.166324],
          [45.325832, 36.166139],
          [45.325528, 36.165388],
          [45.325038, 36.164407],
          [45.325011, 36.16409],
          [45.325688, 36.163117],
          [45.326387, 36.16178],
          [45.326522, 36.160989],
          [45.326932, 36.160144],
          [45.327025, 36.159645],
          [45.327443, 36.159045],
          [45.327663, 36.15863],
          [45.327969, 36.157514],
          [45.328906, 36.156853],
          [45.329278, 36.156484],
          [45.327792, 36.154591],
          [45.327685, 36.154328],
          [45.326912, 36.153054],
          [45.326508, 36.151736],
          [45.326132, 36.151389],
          [45.325781, 36.151145],
          [45.325625, 36.150969],
          [45.325608, 36.150709],
          [45.325787, 36.150183],
          [45.325723, 36.149674],
          [45.325228, 36.148903],
          [45.324975, 36.148626],
          [45.324987, 36.148077],
          [45.325259, 36.147798],
          [45.325523, 36.147622],
          [45.325638, 36.147339],
          [45.325948, 36.147027],
          [45.326462, 36.146598],
          [45.326706, 36.146133],
          [45.326788, 36.145577],
          [45.326955, 36.144983],
          [45.327059, 36.143777],
          [45.327278, 36.143153],
          [45.327107, 36.141591],
          [45.32695, 36.140837],
          [45.327046, 36.139867],
          [45.327135, 36.139698],
          [45.327607, 36.139324],
          [45.32836, 36.13879],
          [45.328704, 36.138636],
          [45.328926, 36.138383],
          [45.329571, 36.136418],
          [45.329667, 36.136275],
          [45.329868, 36.136119],
          [45.33083, 36.135942],
          [45.331323, 36.135668],
          [45.331871, 36.135565],
          [45.332062, 36.13547],
          [45.333567, 36.135366],
          [45.333911, 36.13519],
          [45.334434, 36.134801],
          [45.334482, 36.134562],
          [45.334893, 36.134259],
          [45.335193, 36.134167],
          [45.335401, 36.134158],
          [45.335636, 36.134269],
          [45.335744, 36.134244],
          [45.335861, 36.134005],
          [45.336274, 36.133903],
          [45.336465, 36.133809],
          [45.336902, 36.133457],
          [45.337083, 36.133376],
          [45.337443, 36.13335],
          [45.337692, 36.133464],
          [45.338039, 36.133517],
          [45.338257, 36.133374],
          [45.338369, 36.133218],
          [45.338495, 36.132889],
          [45.339088, 36.132022],
          [45.338959, 36.131635],
          [45.338973, 36.13137],
          [45.339405, 36.130631],
          [45.339636, 36.130365],
          [45.339881, 36.130223],
          [45.340122, 36.129578],
          [45.340319, 36.129335],
          [45.340741, 36.129364],
          [45.34107, 36.129524],
          [45.341445, 36.12943],
          [45.341514, 36.129215],
          [45.341023, 36.128383],
          [45.34092, 36.127334],
          [45.340739, 36.126902],
          [45.340539, 36.126773],
          [45.34064, 36.126378],
          [45.340514, 36.126291],
          [45.34048, 36.125662],
          [45.340534, 36.125198],
          [45.340724, 36.124809],
          [45.340948, 36.124079],
          [45.341146, 36.123792],
          [45.341477, 36.123471],
          [45.341622, 36.122969],
          [45.341589, 36.122197],
          [45.341979, 36.122281],
          [45.342835, 36.12221],
          [45.343538, 36.121833],
          [45.344163, 36.121667],
          [45.344726, 36.121058],
          [45.344956, 36.120738],
          [45.345243, 36.120188],
          [45.345723, 36.119599],
          [45.345815, 36.118592],
          [45.345795, 36.117645],
          [45.345867, 36.117312],
          [45.346753, 36.116014],
          [45.346888, 36.115686],
          [45.347404, 36.115099],
          [45.347596, 36.114413],
          [45.347991, 36.114037],
          [45.348196, 36.113399],
          [45.34846, 36.112972],
          [45.34852, 36.112408],
          [45.348937, 36.111836],
          [45.349553, 36.110664],
          [45.349533, 36.110252],
          [45.34967, 36.109965],
          [45.349689, 36.109716],
          [45.349596, 36.109311],
          [45.34957, 36.108705],
          [45.350507, 36.108563],
          [45.352971, 36.108856],
          [45.353314, 36.108965],
          [45.353744, 36.108975],
          [45.354217, 36.109088],
          [45.355103, 36.109127],
          [45.355643, 36.10931],
          [45.356028, 36.109378],
          [45.356312, 36.109322],
          [45.356523, 36.109131],
          [45.356628, 36.108827],
          [45.357016, 36.108271],
          [45.357254, 36.108174],
          [45.357587, 36.108153],
          [45.357921, 36.10823],
          [45.35822, 36.108231],
          [45.358644, 36.107975],
          [45.359223, 36.107401],
          [45.359773, 36.107062],
          [45.360708, 36.106912],
          [45.361047, 36.106786],
          [45.361775, 36.106355],
          [45.362244, 36.106332],
          [45.36244, 36.106406],
          [45.362621, 36.106565],
          [45.362894, 36.10662],
          [45.363592, 36.106469],
          [45.364596, 36.106421],
          [45.365243, 36.106283],
          [45.365767, 36.106042],
          [45.366827, 36.105415],
          [45.367049, 36.105239],
          [45.367814, 36.104965],
          [45.368788, 36.10495],
          [45.369071, 36.104894],
          [45.370334, 36.104412],
          [45.370547, 36.104235],
          [45.370625, 36.104087],
          [45.370597, 36.103147],
          [45.370356, 36.102594],
          [45.370292, 36.10193],
          [45.370596, 36.101317],
          [45.370581, 36.101118],
          [45.370253, 36.100574],
          [45.370287, 36.100333],
          [45.370117, 36.099821],
          [45.370292, 36.099331],
          [45.370435, 36.09809],
          [45.370572, 36.098071],
          [45.371052, 36.097871],
          [45.371373, 36.09783],
          [45.371848, 36.097586],
          [45.37207, 36.097312],
          [45.372256, 36.096666],
          [45.372687, 36.096386],
          [45.373144, 36.096299],
          [45.373364, 36.096161],
          [45.373604, 36.095878],
          [45.373769, 36.095301],
          [45.373996, 36.095266],
          [45.374151, 36.095042],
          [45.37444, 36.095034],
          [45.374575, 36.094982],
          [45.374687, 36.094919],
          [45.374728, 36.094802],
          [45.374859, 36.094735],
          [45.374879, 36.09449],
          [45.37476, 36.094161],
          [45.374791, 36.093882],
          [45.374495, 36.093579],
          [45.374448, 36.093457],
          [45.374625, 36.093168],
          [45.374709, 36.092813],
          [45.375012, 36.092612],
          [45.37508, 36.092463],
          [45.375133, 36.092267],
          [45.375102, 36.092032],
          [45.37497, 36.091705],
          [45.375651, 36.091349],
          [45.377231, 36.090202],
          [45.377539, 36.089945],
          [45.378539, 36.088907],
          [45.378679, 36.08851],
          [45.379023, 36.088115],
          [45.379259, 36.087501],
          [45.379461, 36.087397],
          [45.379637, 36.087239],
          [45.379741, 36.087002],
          [45.379779, 36.086618],
          [45.379904, 36.086311],
          [45.379776, 36.085805],
          [45.380161, 36.085614],
          [45.380449, 36.085402],
          [45.380532, 36.085075],
          [45.380529, 36.084719],
          [45.380786, 36.084522],
          [45.381033, 36.084473],
          [45.381363, 36.08418],
          [45.381498, 36.08293],
          [45.381396, 36.081924],
          [45.38163, 36.081615],
          [45.381628, 36.081069],
          [45.381837, 36.080798],
          [45.381823, 36.080477],
          [45.381892, 36.080287],
          [45.381789, 36.079971],
          [45.381813, 36.079448],
          [45.381616, 36.079233],
          [45.38096, 36.078709],
          [45.380507, 36.077754],
          [45.380206, 36.077328],
          [45.380067, 36.077253],
          [45.3798, 36.077278],
          [45.379353, 36.077152],
          [45.378991, 36.077105],
          [45.378886, 36.076945],
          [45.378242, 36.076603],
          [45.377979, 36.076321],
          [45.37771, 36.076152],
          [45.377323, 36.075798],
          [45.377208, 36.0752],
          [45.3774, 36.074352],
          [45.377869, 36.073762],
          [45.378197, 36.073514],
          [45.378399, 36.073193],
          [45.378529, 36.072778],
          [45.379451, 36.071761],
          [45.379155, 36.07131],
          [45.37854, 36.070913],
          [45.378265, 36.0708],
          [45.378187, 36.070396],
          [45.377843, 36.069861],
          [45.377608, 36.069764],
          [45.377418, 36.069547],
          [45.377409, 36.069086],
          [45.377231, 36.068461],
          [45.37715, 36.067884],
          [45.376898, 36.067512],
          [45.376433, 36.066993],
          [45.37646, 36.06632],
          [45.376429, 36.066174],
          [45.376366, 36.06588],
          [45.376208, 36.065651],
          [45.376058, 36.065294],
          [45.376063, 36.064831],
          [45.375756, 36.063964],
          [45.375505, 36.063791],
          [45.375358, 36.063774],
          [45.374975, 36.063853],
          [45.374854, 36.063158],
          [45.374602, 36.062813],
          [45.374249, 36.062536],
          [45.3737, 36.061831],
          [45.373653, 36.061277],
          [45.373941, 36.060479],
          [45.373977, 36.060077],
          [45.373872, 36.058659],
          [45.373506, 36.057855],
          [45.373444, 36.057643],
          [45.373433, 36.057172],
          [45.373302, 36.056727],
          [45.372749, 36.055444],
          [45.372627, 36.054658],
          [45.372695, 36.054222],
          [45.372656, 36.05375],
          [45.37243, 36.052326],
          [45.372537, 36.051772],
          [45.372477, 36.051487],
          [45.371312, 36.05009],
          [45.371, 36.049918],
          [45.370518, 36.049792],
          [45.370202, 36.049575],
          [45.369787, 36.049382],
          [45.369463, 36.049111],
          [45.369372, 36.048815],
          [45.369342, 36.048352],
          [45.368816, 36.047739],
          [45.367868, 36.046793],
          [45.367491, 36.046504],
          [45.367055, 36.046259],
          [45.365791, 36.045075],
          [45.365233, 36.044656],
          [45.364846, 36.044279],
          [45.364213, 36.043464],
          [45.363478, 36.043057],
          [45.362495, 36.042904],
          [45.362064, 36.042731],
          [45.361944, 36.042596],
          [45.362005, 36.042184],
          [45.361972, 36.0419],
          [45.361707, 36.041101],
          [45.361625, 36.040681],
          [45.361292, 36.040105],
          [45.361184, 36.039773],
          [45.360858, 36.039169],
          [45.360558, 36.038292],
          [45.36023, 36.038032],
          [45.359979, 36.037715],
          [45.359445, 36.037415],
          [45.359123, 36.037338],
          [45.358538, 36.037058],
          [45.35758, 36.036505],
          [45.357195, 36.036239],
          [45.357119, 36.036086],
          [45.356894, 36.036053],
          [45.356718, 36.03589],
          [45.356361, 36.035708],
          [45.356154, 36.035667],
          [45.355977, 36.035498],
          [45.35536, 36.035229],
          [45.355201, 36.034946],
          [45.355002, 36.034803],
          [45.354958, 36.03466],
          [45.355, 36.034553],
          [45.354628, 36.034285],
          [45.354431, 36.03354],
          [45.354309, 36.033344],
          [45.35423, 36.03328],
          [45.353621, 36.033144],
          [45.353381, 36.033003],
          [45.353249, 36.032848],
          [45.353189, 36.032162],
          [45.352878, 36.031967],
          [45.35271, 36.031646],
          [45.352497, 36.031472],
          [45.352318, 36.031192],
          [45.352274, 36.031078],
          [45.352375, 36.030943],
          [45.352378, 36.030619],
          [45.352296, 36.030455],
          [45.351928, 36.030198],
          [45.351798, 36.029569],
          [45.351589, 36.029148],
          [45.351527, 36.028805],
          [45.351564, 36.028681],
          [45.35198, 36.028109],
          [45.352093, 36.027861],
          [45.35234, 36.027644],
          [45.352946, 36.027303],
          [45.353136, 36.027132],
          [45.353305, 36.026838],
          [45.353399, 36.026452],
          [45.353436, 36.026158],
          [45.353321, 36.025989],
          [45.352959, 36.025789],
          [45.352768, 36.025589],
          [45.352499, 36.025126],
          [45.35229, 36.025019],
          [45.351812, 36.02451],
          [45.351716, 36.024279],
          [45.351772, 36.024001],
          [45.352664, 36.022744],
          [45.352785, 36.022324],
          [45.353146, 36.021923],
          [45.353282, 36.020926],
          [45.353515, 36.020464],
          [45.353532, 36.019943],
          [45.353463, 36.019574],
          [45.353067, 36.019205],
          [45.352773, 36.019006],
          [45.352431, 36.018859],
          [45.351685, 36.018964],
          [45.350684, 36.018099],
          [45.35016, 36.017796],
          [45.349108, 36.016806],
          [45.348295, 36.016293],
          [45.34725, 36.01539],
          [45.346728, 36.015012],
          [45.346508, 36.014792],
          [45.34646, 36.014597],
          [45.346543, 36.014135],
          [45.346457, 36.013864],
          [45.346082, 36.013318],
          [45.345559, 36.01285],
          [45.345062, 36.011913],
          [45.344841, 36.01127],
          [45.344574, 36.01073],
          [45.344435, 36.010558],
          [45.344703, 36.009921],
          [45.344488, 36.009831],
          [45.343998, 36.009726],
          [45.343902, 36.009649],
          [45.343745, 36.00928],
          [45.343614, 36.009232],
          [45.34353, 36.009233],
          [45.343387, 36.00934],
          [45.343185, 36.009389],
          [45.342958, 36.009321],
          [45.34261, 36.008935],
          [45.342585, 36.008683],
          [45.342656, 36.008518],
          [45.34256, 36.008431],
          [45.342464, 36.008402],
          [45.341892, 36.008588],
          [45.341761, 36.008539],
          [45.341808, 36.008433],
          [45.342034, 36.008238],
          [45.342152, 36.008005],
          [45.342426, 36.007675],
          [45.343259, 36.007352],
          [45.34339, 36.007206],
          [45.343484, 36.006954],
          [45.343388, 36.00677],
          [45.342994, 36.006655],
          [45.342934, 36.006587],
          [45.342945, 36.006432],
          [45.343266, 36.006043],
          [45.343277, 36.005917],
          [45.343133, 36.005791],
          [45.342859, 36.00586],
          [45.342763, 36.005744],
          [45.342774, 36.005502],
          [45.342987, 36.005103],
          [45.342986, 36.004929],
          [45.342854, 36.004735],
          [45.342375, 36.004368],
          [45.342136, 36.004262],
          [45.342099, 36.00403],
          [45.341782, 36.003578],
          [45.342598, 36.003521],
          [45.343115, 36.003361],
          [45.34354, 36.003015],
          [45.34388, 36.002794],
          [45.344449, 36.002665],
          [45.344874, 36.002453],
          [45.345451, 36.001975],
          [45.345724, 36.001809],
          [45.345801, 36.001637],
          [45.345794, 36.001423],
          [45.345695, 36.001242],
          [45.345175, 36.000126],
          [45.344308, 35.999464],
          [45.343919, 35.999018],
          [45.343604, 35.998176],
          [45.343062, 35.997526],
          [45.342691, 35.997377],
          [45.342443, 35.997259],
          [45.34239, 35.997079],
          [45.342386, 35.996887],
          [45.342492, 35.996764],
          [45.34325, 35.99602],
          [45.344294, 35.99502],
          [45.345402, 35.993926],
          [45.346407, 35.992913],
          [45.347146, 35.992094],
          [45.348389, 35.990848],
          [45.348728, 35.990341],
          [45.349001, 35.990003],
          [45.349571, 35.989439],
          [45.350187, 35.988861],
          [45.350735, 35.988331],
          [45.350901, 35.988082],
          [45.350799, 35.987979],
          [45.350678, 35.987838],
          [45.350678, 35.987417],
          [45.350682, 35.986596],
          [45.350367, 35.985656],
          [45.349946, 35.984751],
          [45.349383, 35.984342],
          [45.348622, 35.983778],
          [45.347773, 35.982984],
          [45.347454, 35.982426],
          [45.347412, 35.982036],
          [45.347323, 35.981444],
          [45.347444, 35.98098],
          [45.347564, 35.980702],
          [45.34772, 35.980395],
          [45.348105, 35.979499],
          [45.348597, 35.978651],
          [45.348941, 35.97833],
          [45.349334, 35.977803],
          [45.349638, 35.977534],
          [45.350006, 35.977265],
          [45.350523, 35.976961],
          [45.351142, 35.976721],
          [45.351701, 35.976414],
          [45.352257, 35.975982],
          [45.352657, 35.975446],
          [45.353, 35.975194],
          [45.353552, 35.974759],
          [45.353881, 35.974553],
          [45.354179, 35.974464],
          [45.354402, 35.974306],
          [45.354695, 35.974215],
          [45.355159, 35.974114],
          [45.356069, 35.973937],
          [45.356518, 35.974066],
          [45.356731, 35.974206],
          [45.356946, 35.974501],
          [45.357116, 35.975017],
          [45.358001, 35.975893],
          [45.358443, 35.976116],
          [45.359211, 35.9763],
          [45.360015, 35.976277],
          [45.360893, 35.976162],
          [45.362103, 35.975844],
          [45.362181, 35.975793],
          [45.362213, 35.975727],
          [45.362391, 35.97526],
          [45.362598, 35.974941],
          [45.362858, 35.974694],
          [45.363135, 35.974586],
          [45.363582, 35.97427],
          [45.363651, 35.974135],
          [45.363903, 35.974069],
          [45.364253, 35.974082],
          [45.364659, 35.974112],
          [45.365155, 35.974079],
          [45.365509, 35.97402],
          [45.365887, 35.973921],
          [45.366204, 35.973882],
          [45.366456, 35.973921],
          [45.366659, 35.973964],
          [45.36681, 35.97404],
          [45.366907, 35.974128],
          [45.366919, 35.974263],
          [45.366814, 35.974586],
          [45.366423, 35.975356],
          [45.3662, 35.975945],
          [45.365253, 35.976892],
          [45.364911, 35.977228],
          [45.364253, 35.977557],
          [45.363923, 35.977711],
          [45.363196, 35.978813],
          [45.362736, 35.979702],
          [45.362756, 35.980277],
          [45.363017, 35.980889],
          [45.36361, 35.981485],
          [45.363774, 35.981894],
          [45.363883, 35.982189],
          [45.36398, 35.982797],
          [45.363992, 35.982896],
          [45.36407, 35.982998],
          [45.364131, 35.983051],
          [45.364297, 35.983074],
          [45.364444, 35.983074],
          [45.364582, 35.983057],
          [45.364757, 35.982968],
          [45.365273, 35.982225],
          [45.365537, 35.981932],
          [45.36581, 35.981528],
          [45.366017, 35.981189],
          [45.36618, 35.981087],
          [45.366484, 35.980998],
          [45.367659, 35.981258],
          [45.369009, 35.981722],
          [45.370469, 35.98236],
          [45.370961, 35.982567],
          [45.371953, 35.982735],
          [45.372656, 35.982913],
          [45.373075, 35.982968],
          [45.3734, 35.982873],
          [45.373847, 35.982603],
          [45.37455, 35.981932],
          [45.37575, 35.9806],
          [45.376164, 35.979274],
          [45.3764, 35.977211],
          [45.376416, 35.976583],
          [45.375571, 35.976106],
          [45.374018, 35.974543],
          [45.373587, 35.974092],
          [45.373396, 35.972691],
          [45.373619, 35.972434],
          [45.374241, 35.971727],
          [45.374351, 35.97121],
          [45.374205, 35.970085],
          [45.374363, 35.969601],
          [45.374998, 35.969453],
          [45.375522, 35.969506],
          [45.376193, 35.969756],
          [45.377055, 35.970591],
          [45.377449, 35.971078],
          [45.377908, 35.971546],
          [45.378689, 35.972704],
          [45.379287, 35.973391],
          [45.380181, 35.97423],
          [45.380762, 35.97448],
          [45.381522, 35.974586],
          [45.382376, 35.974816],
          [45.382807, 35.974724],
          [45.383633, 35.974388],
          [45.384141, 35.97398],
          [45.3851, 35.973803],
          [45.387336, 35.973523],
          [45.389129, 35.972829],
          [45.389898, 35.972539],
          [45.390324, 35.972121],
          [45.390702, 35.971503],
          [45.391064, 35.97045],
          [45.391109, 35.969647],
          [45.391345, 35.969101],
          [45.391662, 35.968614],
          [45.391951, 35.968252],
          [45.392337, 35.968038],
          [45.392569, 35.96789],
          [45.392995, 35.967795],
          [45.393382, 35.967814],
          [45.39393, 35.968038],
          [45.394487, 35.968459],
          [45.395378, 35.96893],
          [45.396679, 35.969506],
          [45.398028, 35.969812],
          [45.399061, 35.970151],
          [45.400952, 35.970624],
          [45.401765, 35.970828],
          [45.40398, 35.970736],
          [45.406135, 35.969917],
          [45.406542, 35.969874],
          [45.407363, 35.970243],
          [45.407802, 35.970618],
          [45.407993, 35.971029],
          [45.407973, 35.971342],
          [45.407794, 35.971555],
          [45.405712, 35.973368],
          [45.405334, 35.974072],
          [45.405395, 35.974665],
          [45.405692, 35.975119],
          [45.406094, 35.975504],
          [45.406757, 35.975879],
          [45.407802, 35.975727],
          [45.408351, 35.975852],
          [45.408769, 35.975889],
          [45.409147, 35.976076],
          [45.409278, 35.976221],
          [45.409639, 35.976964],
          [45.409643, 35.977224],
          [45.409664, 35.977392],
          [45.409176, 35.977859],
          [45.408469, 35.978419],
          [45.408054, 35.978738],
          [45.407773, 35.978938],
          [45.407517, 35.979251],
          [45.407456, 35.979511],
          [45.407521, 35.979955],
          [45.40792, 35.980231],
          [45.408322, 35.980406],
          [45.40905, 35.980406],
          [45.410038, 35.98005],
          [45.410371, 35.979797],
          [45.410664, 35.979692],
          [45.410827, 35.979929],
          [45.410888, 35.980156],
          [45.410961, 35.980935],
          [45.411131, 35.981748],
          [45.411514, 35.982314],
          [45.412006, 35.982669],
          [45.412786, 35.982959],
          [45.413615, 35.983265],
          [45.414689, 35.98337],
          [45.415376, 35.983179],
          [45.415973, 35.982893],
          [45.418368, 35.982255],
          [45.41901, 35.981722],
          [45.419453, 35.981554],
          [45.420136, 35.98159],
          [45.420714, 35.981722],
          [45.421356, 35.981942],
          [45.421706, 35.98261],
          [45.422486, 35.983054],
          [45.423832, 35.983505],
          [45.425401, 35.984636],
          [45.425938, 35.984939],
          [45.427003, 35.985001],
          [45.427621, 35.985123],
          [45.427962, 35.985393],
          [45.428105, 35.985781],
          [45.428137, 35.98609],
          [45.428145, 35.986232],
          [45.428202, 35.986314],
          [45.428332, 35.986373],
          [45.428544, 35.986452],
          [45.42856, 35.987002],
          [45.428637, 35.987406],
          [45.428942, 35.988031],
          [45.429219, 35.988725],
          [45.429544, 35.989409],
          [45.429987, 35.989785],
          [45.430349, 35.990229],
          [45.430751, 35.990396],
          [45.431121, 35.990581],
          [45.431292, 35.990548],
          [45.431715, 35.990541],
          [45.43217, 35.990429],
          [45.432817, 35.990436],
          [45.433154, 35.990715],
          [45.433699, 35.990877],
          [45.433691, 35.991127],
          [45.433435, 35.991456],
          [45.432682, 35.992696],
          [45.432207, 35.993304],
          [45.431967, 35.993778],
          [45.432069, 35.99439],
          [45.432239, 35.994784],
          [45.43304, 35.995271],
          [45.43391, 35.995906],
          [45.434666, 35.996021],
          [45.435431, 35.996544],
          [45.43665, 35.997199],
          [45.437459, 35.997837],
          [45.438793, 35.998498],
          [45.439496, 35.999188],
          [45.439695, 35.999327],
          [45.440297, 35.999392],
          [45.441488, 35.999251],
          [45.442257, 35.998814],
          [45.442952, 35.998537],
          [45.443741, 35.998064],
          [45.444708, 35.997643],
          [45.445188, 35.997551],
          [45.445668, 35.997551],
          [45.446176, 35.997692],
          [45.446688, 35.997531],
          [45.447013, 35.997208],
          [45.447367, 35.997031],
          [45.447416, 35.996712],
          [45.447717, 35.996422],
          [45.448261, 35.996126],
          [45.448619, 35.995978],
          [45.449168, 35.995834],
          [45.449371, 35.995804],
          [45.450022, 35.995972],
          [45.450847, 35.99586],
          [45.452331, 35.994909],
          [45.453327, 35.994357],
          [45.453526, 35.994159],
          [45.453673, 35.993936],
          [45.453994, 35.993163],
          [45.454583, 35.992439],
          [45.454588, 35.992127],
          [45.454258, 35.991666],
          [45.454286, 35.990554],
          [45.454339, 35.990354],
          [45.454408, 35.9901],
          [45.454681, 35.989975],
          [45.455185, 35.989863],
          [45.455677, 35.989867],
          [45.456185, 35.99013],
          [45.456945, 35.990715],
          [45.457177, 35.990952],
          [45.4581, 35.991163],
          [45.458262, 35.991182],
          [45.458323, 35.991271],
          [45.458364, 35.991386],
          [45.458425, 35.991623],
          [45.458844, 35.993337],
          [45.459171, 35.994121],
          [45.460114, 35.995438],
          [45.46056, 35.995776],
          [45.460819, 35.996103],
          [45.46148, 35.996159],
          [45.461821, 35.996048],
          [45.462528, 35.995929],
          [45.463955, 35.995951],
          [45.46555, 35.996412],
          [45.466038, 35.996363],
          [45.466804, 35.996246],
          [45.467717, 35.996329],
          [45.468102, 35.996539],
          [45.468368, 35.996747],
          [45.468894, 35.997174],
          [45.469391, 35.997408],
          [45.470236, 35.997858],
          [45.471173, 35.998336],
          [45.472711, 35.998626],
          [45.47362, 35.999076],
          [45.47407, 35.99942],
          [45.474362, 35.999469],
          [45.475021, 35.999667],
          [45.475614, 36.000047],
          [45.475719, 36.000194],
          [45.475787, 36.000342],
          [45.475915, 36.000412],
          [45.477309, 36.000869],
          [45.477925, 36.001145],
          [45.478378, 36.001534],
          [45.47861, 36.001827],
          [45.478843, 36.002236],
          [45.479107, 36.002325],
          [45.479674, 36.002172],
          [45.480095, 36.001991],
          [45.480473, 36.001706],
          [45.480779, 36.001392],
          [45.481045, 36.000846],
          [45.481176, 36.000737],
          [45.48157, 36.000544],
          [45.482075, 36.000404],
          [45.482409, 36.000487],
          [45.483104, 36.000901],
          [45.483532, 36.001027],
          [45.484225, 36.000992],
          [45.484825, 36.000954],
          [45.485192, 36.001052],
          [45.485757, 36.001358],
          [45.486768, 36.001791],
          [45.487613, 36.002074],
          [45.488375, 36.002244],
          [45.4888, 36.002133],
          [45.489129, 36.002153],
          [45.490726, 36.002312],
          [45.491716, 36.00238],
          [45.492538, 36.002325],
          [45.49318, 36.002116],
          [45.493745, 36.001827],
          [45.494334, 36.001734],
          [45.495865, 36.001687],
          [45.497663, 36.001755],
          [45.498782, 36.001919],
          [45.50007, 36.001956],
          [45.501758, 36.002644],
          [45.502717, 36.002837],
          [45.503401, 36.002693],
          [45.5042, 36.002242],
          [45.504525, 36.002166],
          [45.505257, 36.001987],
          [45.505724, 36.001785],
          [45.506239, 36.001365],
          [45.506277, 36.001052],
          [45.506206, 35.999556],
          [45.507084, 35.998943],
          [45.507792, 35.998602],
          [45.508641, 35.998386],
          [45.509435, 35.998257],
          [45.509958, 35.99825],
          [45.510496, 35.99809],
          [45.511482, 35.997671],
          [45.512398, 35.997012],
          [45.513975, 35.996129],
          [45.51458, 35.996057],
          [45.515451, 35.996213],
          [45.516608, 35.996217],
          [45.518043, 35.995861],
          [45.518318, 35.995893],
          [45.518749, 35.99539],
          [45.51895, 35.995234],
          [45.519296, 35.995195],
          [45.519684, 35.995287],
          [45.519973, 35.995243],
          [45.520469, 35.995068],
          [45.521045, 35.994801],
          [45.521337, 35.994698],
          [45.521742, 35.994673],
          [45.522414, 35.994751],
          [45.523012, 35.994864],
          [45.523549, 35.994917],
          [45.524218, 35.995099],
          [45.524477, 35.995136],
          [45.524772, 35.995278],
          [45.525348, 35.995445],
          [45.525779, 35.9958],
          [45.526192, 35.99589],
          [45.526484, 35.995819],
          [45.526724, 35.995742],
          [45.527217, 35.995507],
          [45.527509, 35.995356],
          [45.52774, 35.995217],
          [45.528308, 35.995095],
          [45.528489, 35.995045],
          [45.528828, 35.995126],
          [45.529145, 35.995161],
          [45.529766, 35.995079],
          [45.530458, 35.995099],
          [45.530764, 35.995133],
          [45.531137, 35.995135],
          [45.531686, 35.995354],
          [45.53223, 35.9955],
          [45.53264, 35.995526],
          [45.532735, 35.995536],
          [45.533061, 35.995571],
          [45.533424, 35.995772],
          [45.53341, 35.99601],
          [45.533347, 35.996232],
          [45.533793, 35.996626],
          [45.534097, 35.996847],
          [45.534333, 35.996983],
          [45.534856, 35.997282],
          [45.535228, 35.99737],
          [45.535664, 35.997459],
          [45.536043, 35.997467],
          [45.536632, 35.9974],
          [45.537214, 35.997465],
          [45.537806, 35.997501],
          [45.538144, 35.997761],
          [45.538438, 35.998035],
          [45.538823, 35.998281],
          [45.539573, 35.998668],
          [45.543385, 35.999406],
          [45.546488, 35.999197],
          [45.547025, 35.999197],
          [45.547475, 36.00032],
          [45.548807, 36.001612],
          [45.5503, 36.001954],
          [45.551106, 36.001301],
          [45.550578, 36.000092],
          [45.550917, 35.999332],
          [45.552363, 35.999267],
          [45.555381, 35.999373],
          [45.556771, 35.99572],
          [45.557974, 35.995103],
          [45.558487, 35.999971],
          [45.558768, 36.004415],
          [45.558487, 36.004694],
          [45.558211, 36.00692],
          [45.557654, 36.012751],
          [45.568199, 36.018559],
          [45.577697, 36.02567],
          [45.57742, 36.0265],
          [45.57742, 36.028167],
          [45.577143, 36.029285],
          [45.583729, 36.036508],
          [45.583729, 36.039004],
          [45.583453, 36.039563],
          [45.583176, 36.042618],
          [45.570774, 36.049793],
          [45.566993, 36.063723],
          [45.569213, 36.07123],
          [45.56977, 36.071509],
          [45.570046, 36.072059],
          [45.570603, 36.072338],
          [45.584593, 36.073875],
          [45.59349, 36.087477],
          [45.594323, 36.087477],
          [45.600688, 36.086229],
          [45.607384, 36.083041],
          [45.609328, 36.083041],
          [45.626736, 36.081986],
          [45.654049, 36.099697],
          [45.654751, 36.107747],
          [45.655028, 36.108306],
          [45.655585, 36.108306],
          [45.656141, 36.108865],
          [45.65056, 36.129286],
          [45.653175, 36.148356],
          [45.652341, 36.149473],
          [45.655398, 36.154464],
          [45.656231, 36.155023],
          [45.656508, 36.155582],
          [45.666673, 36.163914],
          [45.667229, 36.164752],
          [45.667786, 36.164752],
          [45.668896, 36.166139],
          [45.669453, 36.166419],
          [45.669637, 36.166698],
          [45.670006, 36.167257],
          [45.678772, 36.171091],
          [45.679048, 36.172199],
          [45.679605, 36.172479],
          [45.680715, 36.174975],
          [45.690236, 36.171661],
          [45.701051, 36.176113],
          [45.691452, 36.185828],
          [45.690937, 36.194833],
          [45.694265, 36.199231],
          [45.704264, 36.20618],
          [45.704821, 36.206451],
          [45.708707, 36.207838],
          [45.732384, 36.201475],
          [45.734884, 36.200925],
          [45.737384, 36.200925],
          [45.739051, 36.200636],
          [45.740441, 36.200636],
          [45.742941, 36.200086],
          [45.744607, 36.200086],
          [45.747107, 36.199528],
          [45.748774, 36.199528],
          [45.766584, 36.194791],
          [45.767974, 36.194232],
          [45.825691, 36.194971],
          [45.846656, 36.14599],
          [45.8486, 36.146269],
          [45.84999, 36.146269],
          [45.86895, 36.159776],
          [45.887042, 36.206707],
          [45.895334, 36.215304],
          [45.896443, 36.215304],
          [45.918325, 36.233048],
          [45.919715, 36.233318],
          [45.920272, 36.233048],
          [45.976008, 36.261365],
          [45.979341, 36.265257],
          [45.987905, 36.272348],
          [45.989596, 36.282885],
          [45.989319, 36.283165],
          [45.989226, 36.283444],
          [45.986819, 36.289282],
          [45.985706, 36.29094],
          [45.981216, 36.326743],
          [45.959338, 36.353592],
          [45.956921, 36.381965],
          [45.959553, 36.389299],
          [45.971603, 36.391441],
          [45.983963, 36.395862],
          [45.988054, 36.420247],
          [45.989997, 36.421364],
          [45.990277, 36.421923],
          [45.992397, 36.424982],
          [45.994341, 36.425262],
          [46.00529, 36.430237],
          [46.015453, 36.427766],
          [46.02823, 36.431648],
          [46.039924, 36.421835],
          [46.065787, 36.424299],
          [46.071167, 36.438133],
          [46.106186, 36.440066],
          [46.119102, 36.447005],
          [46.119935, 36.447284],
          [46.120769, 36.447401],
          [46.153398, 36.455864],
          [46.160898, 36.455026],
          [46.161455, 36.455027],
          [46.175772, 36.448672],
          [46.176881, 36.448672],
          [46.178548, 36.448113],
          [46.180215, 36.448113],
          [46.184384, 36.447005],
          [46.185771, 36.447005],
          [46.193743, 36.444672],
          [46.194938, 36.44423],
          [46.19648, 36.429846],
          [46.247964, 36.409985],
          [46.263556, 36.399368],
          [46.284651, 36.390528],
          [46.295404, 36.384423],
          [46.299634, 36.380816],
          [46.311407, 36.374373],
          [46.323881, 36.371336],
          [46.325824, 36.371056],
          [46.35001, 36.371977],
          [46.3514, 36.371977],
          [46.35251, 36.372535],
          [46.35501, 36.372806],
          [46.356119, 36.373085],
          [46.357509, 36.373085],
          [46.358343, 36.373364],
          [46.371676, 36.372535],
          [46.373623, 36.372256],
          [46.375289, 36.372535],
          [46.3764, 36.372256],
          [46.378066, 36.372535],
          [46.381123, 36.372535],
          [46.410026, 36.362417],
          [46.422729, 36.367393],
          [46.427325, 36.380003],
          [46.430935, 36.380841],
          [46.437138, 36.402202],
          [46.439915, 36.405256],
          [46.451305, 36.413877],
          [46.457418, 36.415814],
          [46.457695, 36.416093],
          [46.459085, 36.416093],
          [46.459638, 36.416372],
          [46.462138, 36.416372],
          [46.462695, 36.416651],
          [46.467418, 36.416652],
          [46.481585, 36.418039],
          [46.484642, 36.418039],
          [46.486308, 36.418318],
          [46.496308, 36.418318],
          [46.499641, 36.418597],
          [46.505474, 36.416093],
          [46.508808, 36.413598],
          [46.512975, 36.412211],
          [46.513532, 36.411931],
          [46.514364, 36.411931],
          [46.514921, 36.411652],
          [46.515754, 36.411652],
          [46.516031, 36.411373],
          [46.517698, 36.411373],
          [46.520198, 36.410823],
          [46.523531, 36.409157],
          [46.524088, 36.408878],
          [46.527978, 36.406103],
          [46.528531, 36.405964],
          [46.530197, 36.404707],
          [46.530478, 36.404428],
          [46.531031, 36.404148],
          [46.532144, 36.404148],
          [46.532698, 36.403869],
          [46.534921, 36.403869],
          [46.545477, 36.401932],
          [46.546034, 36.401653],
          [46.546867, 36.401653],
          [46.547421, 36.401374],
          [46.548254, 36.401374],
          [46.54881, 36.401095],
          [46.549921, 36.401095],
          [46.552701, 36.400545],
          [46.567701, 36.397212],
          [46.568257, 36.396933],
          [46.569091, 36.396933],
          [46.569644, 36.396654],
          [46.572144, 36.396654],
          [46.572977, 36.396375],
          [46.578534, 36.396374],
          [46.579367, 36.396095],
          [46.580757, 36.396374],
          [46.591314, 36.395825],
          [46.593814, 36.396654],
          [46.59798, 36.399149],
          [46.599923, 36.399149],
          [46.602423, 36.398041],
          [46.60298, 36.398041],
          [46.60437, 36.398041],
          [46.604923, 36.39832],
          [46.606037, 36.39832],
          [46.60659, 36.3986],
          [46.608257, 36.3986],
          [46.612703, 36.395267],
          [46.615203, 36.395267],
          [46.622146, 36.397483],
          [46.622703, 36.397762],
          [46.62548, 36.397762],
          [46.626036, 36.398041],
          [46.628536, 36.398041],
          [46.634094, 36.395825],
          [46.636593, 36.395825],
          [46.641593, 36.398041],
          [46.642703, 36.398041],
          [46.642983, 36.39832],
          [46.645203, 36.39832],
          [46.64687, 36.397762],
          [46.647427, 36.397762],
          [46.649649, 36.397212],
          [46.65187, 36.397212],
          [46.657707, 36.401374],
          [46.658816, 36.401933],
          [46.662706, 36.401933],
          [46.663539, 36.401654],
          [46.666873, 36.398041],
          [46.671872, 36.396095],
          [46.674373, 36.396095],
          [46.674649, 36.396375],
          [46.675483, 36.396375],
          [46.679096, 36.398321],
          [46.681873, 36.398321],
          [46.682429, 36.3986],
          [46.683539, 36.3986],
          [46.684649, 36.399149],
          [46.685483, 36.399149],
          [46.687706, 36.400266],
          [46.688539, 36.400266],
          [46.689096, 36.399987],
          [46.690206, 36.400546],
          [46.691873, 36.402762],
          [46.694653, 36.405536],
          [46.694929, 36.406104],
          [46.696319, 36.406653],
          [46.697706, 36.406104],
          [46.699096, 36.40387],
          [46.699929, 36.403599],
          [46.700206, 36.40332],
          [46.703539, 36.40332],
          [46.712152, 36.409428],
          [46.713819, 36.409986],
          [46.715762, 36.409986],
          [46.716042, 36.409707],
          [46.717709, 36.409707],
          [46.718262, 36.409428],
          [46.719376, 36.409428],
          [46.725485, 36.413878],
          [46.726042, 36.414706],
          [46.726595, 36.414706],
          [46.728542, 36.415265],
          [46.729375, 36.415265],
          [46.729652, 36.415544],
          [46.730765, 36.415265],
          [46.731875, 36.415265],
          [46.735485, 36.414148],
          [46.740486, 36.410266],
          [46.741042, 36.409707],
          [46.744652, 36.409158],
          [46.745208, 36.409707],
          [46.745765, 36.411373],
          [46.746598, 36.412761],
          [46.746598, 36.41304],
          [46.747708, 36.41304],
          [46.748266, 36.413319],
          [46.749098, 36.41304],
          [46.751875, 36.407212],
          [46.753265, 36.405825],
          [46.754932, 36.405257],
          [46.755209, 36.405536],
          [46.756041, 36.405536],
          [46.757709, 36.406653],
          [46.757988, 36.408878],
          [46.756041, 36.411653],
          [46.756875, 36.414148],
          [46.757988, 36.414427],
          [46.758822, 36.414427],
          [46.759375, 36.414707],
          [46.760765, 36.414707],
          [46.765208, 36.412761],
          [46.765765, 36.412761],
          [46.766042, 36.412482],
          [46.767155, 36.412482],
          [46.767432, 36.412211],
          [46.770765, 36.412482],
          [46.771878, 36.41304],
          [46.773265, 36.415815],
          [46.772988, 36.416094],
          [46.796321, 36.42221],
          [46.796878, 36.42249],
          [46.798268, 36.42249],
          [46.801045, 36.424156],
          [46.802155, 36.423877],
          [46.802988, 36.423877],
          [46.827004, 36.438643],
          [46.82756, 36.438643],
          [46.827837, 36.438364],
          [46.828394, 36.438364],
          [46.829504, 36.438094],
          [46.829781, 36.437814],
          [46.830894, 36.437814],
          [46.842441, 36.430399],
          [46.85299, 36.412212],
          [46.853824, 36.412212],
          [46.869818, 36.387238],
          [46.872318, 36.385572],
          [46.883427, 36.377238],
          [46.890454, 36.37122],
          [46.892121, 36.363725],
          [46.893084, 36.347678],
          [46.89253, 36.342119],
          [46.891141, 36.340172],
          [46.890864, 36.339343],
          [46.909419, 36.331202],
          [46.910252, 36.331202],
          [46.911362, 36.330644],
          [46.927757, 36.331169],
          [46.94767, 36.334073],
          [46.977065, 36.335199],
          [46.978178, 36.335488],
          [47.007999, 36.321658],
          [47.013556, 36.319982],
          [47.014946, 36.319982],
          [47.017999, 36.319154],
          [47.019665, 36.316649],
          [47.019665, 36.31582],
          [47.031056, 36.306099],
          [47.031336, 36.30582],
          [47.042169, 36.297487],
          [47.042169, 36.297207],
          [47.042722, 36.297207],
          [47.043556, 36.296369],
          [47.045502, 36.295261],
          [47.065779, 36.279711],
          [47.068835, 36.275819],
          [47.091135, 36.259442],
          [47.108616, 36.248877],
          [47.109169, 36.248328],
          [47.109169, 36.248049],
          [47.109726, 36.248049],
          [47.113616, 36.248877],
          [47.115559, 36.249436],
          [47.118616, 36.249436],
          [47.118892, 36.249715],
          [47.121673, 36.249715],
          [47.122506, 36.249995],
          [47.12445, 36.249995],
          [47.124726, 36.250274],
          [47.131062, 36.250538],
          [47.160508, 36.249957],
          [47.189955, 36.2483],
          [47.213844, 36.249678],
          [47.215231, 36.249958],
          [47.218844, 36.249958],
          [47.220234, 36.250237],
          [47.222454, 36.250237],
          [47.225787, 36.250795],
          [47.227733, 36.250795],
          [47.229401, 36.251075],
          [47.231344, 36.251075],
          [47.232454, 36.251354],
          [47.23551, 36.251354],
          [47.236067, 36.251354],
          [47.238844, 36.251354],
          [47.239677, 36.251633],
          [47.242457, 36.251633],
          [47.24829, 36.252462],
          [47.249957, 36.252462],
          [47.251067, 36.252741],
          [47.311336, 36.256064],
          [47.352447, 36.259501],
          [47.355537, 36.271127],
          [47.346349, 36.277757],
          [47.33599, 36.303343],
          [47.336543, 36.303622],
          [47.337933, 36.305289],
          [47.338766, 36.306118],
          [47.339323, 36.306406],
          [47.339548, 36.306555],
          [47.382385, 36.318852],
          [47.382661, 36.319131],
          [47.385162, 36.320239],
          [47.394885, 36.327464],
          [47.446518, 36.325084],
          [47.481537, 36.319275],
          [47.49424, 36.32868],
          [47.49321, 36.362417],
          [47.504883, 36.384531],
          [47.513155, 36.391032],
          [47.513181, 36.391114],
          [47.47467, 36.397244],
          [47.429695, 36.39531],
          [47.407744, 36.402379],
          [47.407744, 36.405722],
          [47.407744, 36.406001],
          [47.407744, 36.406831],
          [47.407463, 36.407669],
          [47.388153, 36.417139],
          [47.369613, 36.43178],
          [47.375106, 36.455532],
          [47.395019, 36.473203],
          [47.457812, 36.502418],
          [47.458645, 36.504363],
          [47.458645, 36.504642],
          [47.459755, 36.507146],
          [47.460312, 36.511037],
          [47.459755, 36.512703],
          [47.458921, 36.516584],
          [47.458644, 36.517971],
          [47.458365, 36.518809],
          [47.457812, 36.520475],
          [47.456978, 36.522699],
          [47.453365, 36.528805],
          [47.414245, 36.536123],
          [47.394332, 36.550466],
          [47.377166, 36.559843],
          [47.37024, 36.572637],
          [47.343478, 36.614683],
          [47.3232, 36.642734],
          [47.322921, 36.643572],
          [47.321254, 36.645796],
          [47.292031, 36.669308],
          [47.278698, 36.680981],
          [47.278422, 36.68126],
          [47.277865, 36.68154],
          [47.271345, 36.700833],
          [47.270231, 36.701112],
          [47.261578, 36.718508],
          [47.206535, 36.768593],
          [47.175505, 36.788606],
          [47.175229, 36.789443],
          [47.175229, 36.790271],
          [47.174396, 36.791109],
          [47.174396, 36.791388],
          [47.174115, 36.791667],
          [47.172449, 36.793882],
          [47.171896, 36.794998],
          [47.164395, 36.801661],
          [47.163839, 36.80194],
          [47.161892, 36.803885],
          [47.153282, 36.811385],
          [47.144949, 36.827501],
          [47.140503, 36.832218],
          [47.137449, 36.833056],
          [47.133282, 36.833605],
          [47.130782, 36.833056],
          [47.120502, 36.827501],
          [47.119946, 36.826942],
          [47.11828, 36.826942],
          [47.111059, 36.830274],
          [47.106893, 36.831939],
          [47.104946, 36.832497],
          [47.102446, 36.832497],
          [47.099389, 36.831381],
          [47.097446, 36.831381],
          [47.096336, 36.831111],
          [47.076613, 36.819443],
          [47.072167, 36.816669],
          [47.071057, 36.81639],
          [47.069667, 36.815274],
          [47.059943, 36.810835],
          [47.049387, 36.807216],
          [47.048553, 36.807216],
          [47.040777, 36.803326],
          [47.036887, 36.801382],
          [47.036333, 36.800832],
          [47.021887, 36.790829],
          [47.020497, 36.789164],
          [47.018554, 36.768608],
          [47.017997, 36.766105],
          [47.017721, 36.764997],
          [47.011607, 36.754435],
          [47.003831, 36.747223],
          [47.002998, 36.746665],
          [46.997998, 36.745278],
          [46.994941, 36.746944],
          [46.994665, 36.747502],
          [46.994108, 36.747772],
          [46.993831, 36.748331],
          [46.993274, 36.74861],
          [46.990218, 36.754435],
          [46.988831, 36.754994],
          [46.987165, 36.755273],
          [46.986607, 36.754994],
          [46.985498, 36.754994],
          [46.975774, 36.749717],
          [46.932995, 36.754714],
          [46.932438, 36.754714],
          [46.931049, 36.755273],
          [46.930495, 36.755552],
          [46.930215, 36.755831],
          [46.927995, 36.756659],
          [46.924662, 36.760558],
          [46.922995, 36.762773],
          [46.921965, 36.773287],
          [46.918632, 36.775502],
          [46.917522, 36.775781],
          [46.916965, 36.776339],
          [46.914745, 36.777176],
          [46.903355, 36.779949],
          [46.897242, 36.779949],
          [46.896132, 36.780228],
          [46.893632, 36.780228],
          [46.892522, 36.780508],
          [46.891409, 36.780508],
          [46.890575, 36.780787],
          [46.889742, 36.780787],
          [46.877993, 36.779997],
          [46.873546, 36.78639],
          [46.873269, 36.78666],
          [46.872992, 36.788884],
          [46.872992, 36.789163],
          [46.872992, 36.789442],
          [46.872712, 36.790001],
          [46.872712, 36.79041],
          [46.872435, 36.791108],
          [46.872159, 36.793053],
          [46.875212, 36.800832],
          [46.894111, 36.815332],
          [46.900291, 36.829348],
          [46.913564, 36.838953],
          [46.914954, 36.847279],
          [46.915234, 36.850339],
          [46.915788, 36.855893],
          [46.902098, 36.869902],
          [46.907474, 36.892726],
          [46.907474, 36.895786],
          [46.907751, 36.898559],
          [46.907474, 36.899117],
          [46.902752, 36.908839],
          [46.892291, 36.910262],
          [46.891738, 36.91082],
          [46.864235, 36.918598],
          [46.862292, 36.919435],
          [46.849235, 36.928599],
          [46.843678, 36.9386],
          [46.838955, 36.954434],
          [46.838955, 36.958322],
          [46.838679, 36.959151],
          [46.842178, 36.971124],
          [46.842178, 36.973617],
          [46.842178, 36.975562],
          [46.853599, 37.002827],
          [46.82579, 37.036818],
          [46.802709, 37.048613],
          [46.802432, 37.048892],
          [46.801901, 37.049297],
          [46.789375, 37.054166],
          [46.786599, 37.054445],
          [46.784932, 37.054445],
          [46.784096, 37.054724],
          [46.783262, 37.054724],
          [46.782709, 37.055003],
          [46.780762, 37.055003],
          [46.780209, 37.055282],
          [46.778262, 37.055282],
          [46.777985, 37.055561],
          [46.774929, 37.055561],
          [46.771319, 37.05611],
          [46.770762, 37.056389],
          [46.769652, 37.056389],
          [46.769095, 37.056668],
          [46.767429, 37.056668],
          [46.767152, 37.056947],
          [46.765486, 37.056947],
          [46.765205, 37.057226],
          [46.764372, 37.057226],
          [46.763819, 37.057505],
          [46.762152, 37.057505],
          [46.761596, 37.057784],
          [46.760206, 37.057784],
          [46.732983, 37.067783],
          [46.730483, 37.06889],
          [46.726316, 37.070276],
          [46.70937, 37.072499],
          [46.706317, 37.072229],
          [46.705483, 37.07195],
          [46.704649, 37.07195],
          [46.704093, 37.071671],
          [46.702983, 37.071671],
          [46.690888, 37.066883],
          [46.690055, 37.066883],
          [46.686165, 37.06466],
          [46.685055, 37.06439],
          [46.684774, 37.064112],
          [46.683941, 37.063553],
          [46.683388, 37.063553],
          [46.685021, 37.050935],
          [46.690443, 37.038266],
          [46.688776, 37.036043],
          [46.660353, 37.041821],
          [46.659243, 37.041821],
          [46.642147, 37.041115],
          [46.640757, 37.041115],
          [46.638257, 37.040557],
          [46.61659, 37.03636],
          [46.611034, 37.035282],
          [46.594921, 37.033059],
          [46.594088, 37.03278],
          [46.591311, 37.03278],
          [46.590754, 37.032501],
          [46.587977, 37.032501],
          [46.587145, 37.032222],
          [46.580198, 37.032222],
          [46.576865, 37.031952],
          [46.576031, 37.031673],
          [46.572975, 37.031673],
          [46.569088, 37.031115],
          [46.567698, 37.031115],
          [46.566588, 37.030836],
          [46.566032, 37.030836],
          [46.562698, 37.030278],
          [46.561308, 37.030278],
          [46.559365, 37.029729],
          [46.559088, 37.02945],
          [46.557974, 37.02945],
          [46.550751, 37.027227],
          [46.527415, 37.028232],
          [46.526305, 37.028232],
          [46.525748, 37.027953],
          [46.524915, 37.027953],
          [46.524358, 37.027674],
          [46.523248, 37.027674],
          [46.522972, 37.027395],
          [46.504097, 37.017631],
          [46.489855, 37.015866],
          [46.489019, 37.016146],
          [46.481242, 37.016146],
          [46.480966, 37.016425],
          [46.477632, 37.016425],
          [46.476798, 37.016704],
          [46.475685, 37.016704],
          [46.475132, 37.016983],
          [46.470966, 37.017532],
          [46.468462, 37.017532],
          [46.467629, 37.017811],
          [46.466242, 37.017811],
          [46.465686, 37.01809],
          [46.464576, 37.01809],
          [46.464296, 37.018369],
          [46.462909, 37.018369],
          [46.460129, 37.019476],
          [46.459019, 37.02115],
          [46.459019, 37.021429],
          [46.459296, 37.022257],
          [46.460686, 37.02503],
          [46.467076, 37.032536],
          [46.467076, 37.033923],
          [46.465129, 37.036983],
          [46.464852, 37.037532],
          [46.465129, 37.037811],
          [46.465409, 37.040313],
          [46.487972, 37.058611],
          [46.489915, 37.059727],
          [46.494915, 37.064723],
          [46.495195, 37.065839],
          [46.495195, 37.069727],
          [46.494638, 37.073615],
          [46.494362, 37.075001],
          [46.494082, 37.07528],
          [46.496028, 37.091948],
          [46.495749, 37.093613],
          [46.495195, 37.09445],
          [46.492138, 37.098059],
          [46.491029, 37.098617],
          [46.487416, 37.099724],
          [46.485195, 37.099724],
          [46.484082, 37.100003],
          [46.483805, 37.099724],
          [46.482972, 37.099724],
          [46.482415, 37.099445],
          [46.481582, 37.099445],
          [46.479358, 37.098617],
          [46.478249, 37.098617],
          [46.476858, 37.098059],
          [46.476025, 37.098059],
          [46.475472, 37.09778],
          [46.471025, 37.09778],
          [46.470192, 37.098059],
          [46.468525, 37.098059],
          [46.467692, 37.098338],
          [46.466306, 37.098338],
          [46.465472, 37.098617],
          [46.463802, 37.098617],
          [46.452692, 37.101668],
          [46.449359, 37.101668],
          [46.448802, 37.101389],
          [46.447692, 37.101389],
          [46.440746, 37.098617],
          [46.440469, 37.098338],
          [46.429079, 37.073615],
          [46.37991, 37.043616],
          [46.377134, 37.041114],
          [46.3546, 37.035407],
          [46.354047, 37.035128],
          [46.317463, 37.017664],
          [46.316977, 37.017345],
          [46.315431, 37.011243],
          [46.315154, 37.010406],
          [46.308141, 36.997187],
          [46.302031, 36.990527],
          [46.288371, 36.966469],
          [46.286981, 36.965362],
          [46.284204, 36.963967],
          [46.278648, 36.96258],
          [46.276982, 36.962859],
          [46.276428, 36.96258],
          [46.275871, 36.962859],
          [46.268238, 36.969718],
          [46.266852, 36.969439],
          [46.256015, 36.968611],
          [46.250738, 36.968611],
          [46.250738, 36.968889],
          [46.247962, 36.969718],
          [46.247405, 36.970276],
          [46.246572, 36.970276],
          [46.246295, 36.970555],
          [46.245738, 36.970555],
          [46.245738, 36.970276],
          [46.245182, 36.970555],
          [46.244629, 36.970276],
          [46.243515, 36.970276],
          [46.219349, 36.956944],
          [46.209069, 36.948609],
          [46.204068, 36.945557],
          [46.202959, 36.944999],
          [46.201569, 36.944999],
          [46.200459, 36.944441],
          [46.198793, 36.944441],
          [46.198512, 36.94472],
          [46.197402, 36.94472],
          [46.197402, 36.944999],
          [46.196521, 36.945294],
          [46.195736, 36.945557],
          [46.195179, 36.945557],
          [46.184069, 36.949725],
          [46.177402, 36.95527],
          [46.177123, 36.956107],
          [46.176569, 36.957502],
          [46.177679, 36.96528],
          [46.178512, 36.97889],
          [46.177955, 36.981392],
          [46.177955, 36.983616],
          [46.176012, 36.994444],
          [46.175456, 36.995551],
          [46.169622, 36.999449],
          [46.165179, 37.004444],
          [46.161845, 37.007784],
          [46.157684, 37.032159],
          [46.108233, 37.051941],
          [46.1074, 37.052778],
          [46.106843, 37.053057],
          [46.106426, 37.053476],
          [46.095126, 37.057424],
          [46.094846, 37.057703],
          [46.094293, 37.058252],
          [46.093875, 37.05895],
          [46.071286, 37.08139],
          [46.070453, 37.08139],
          [46.06545, 37.083613],
          [46.064616, 37.083613],
          [46.04934, 37.103053],
          [46.04934, 37.10361],
          [46.048783, 37.10389],
          [46.045173, 37.108614],
          [46.044893, 37.109442],
          [46.04434, 37.110279],
          [46.043783, 37.110558],
          [46.043783, 37.111116],
          [46.043191, 37.120255],
          [46.039301, 37.12831],
          [46.035688, 37.132755],
          [46.014337, 37.138061],
          [46.01406, 37.13834],
          [46.012117, 37.140283],
          [46.01017, 37.143892],
          [46.01017, 37.144999],
          [46.009337, 37.147222],
          [46.004613, 37.150561],
          [46.00406, 37.150561],
          [46.003504, 37.15111],
          [46.002947, 37.15111],
          [46.00267, 37.151389],
          [46.001837, 37.151388],
          [46.00128, 37.151667],
          [45.999665, 37.151667],
          [45.999337, 37.151946],
          [45.998504, 37.151946],
          [45.987947, 37.159173],
          [45.982671, 37.162223],
          [45.982114, 37.162223],
          [45.968144, 37.167416],
          [45.965088, 37.167416],
          [45.959254, 37.169918],
          [45.958698, 37.170476],
          [45.951197, 37.17214],
          [45.949808, 37.17214],
          [45.947864, 37.172698],
          [45.943421, 37.172698],
          [45.942588, 37.172977],
          [45.941197, 37.172977],
          [45.940364, 37.173256],
          [45.938975, 37.173256],
          [45.934251, 37.1752],
          [45.930085, 37.175749],
          [45.929808, 37.176028],
          [45.928141, 37.176028],
          [45.927864, 37.176306],
          [45.924808, 37.176306],
          [45.923974, 37.176028],
          [45.923418, 37.175749],
          [45.922584, 37.175749],
          [45.922031, 37.175479],
          [45.913221, 37.170277],
          [45.912664, 37.170556],
          [45.907664, 37.170556],
          [45.903221, 37.170007],
          [45.902945, 37.169728],
          [45.901831, 37.169728],
          [45.900998, 37.169449],
          [45.898498, 37.169449],
          [45.897665, 37.16917],
          [45.891275, 37.16917],
          [45.879249, 37.170086],
          [45.874414, 37.170475],
          [45.873858, 37.170335],
          [45.872471, 37.169917],
          [45.872192, 37.169638],
          [45.863302, 37.17072],
          [45.862189, 37.17072],
          [45.861635, 37.170441],
          [45.850799, 37.170441],
          [45.850522, 37.17072],
          [45.767157, 37.202224],
          [45.738144, 37.224861],
          [45.716407, 37.251005],
          [45.699762, 37.301974],
          [45.642122, 37.303642],
          [45.594445, 37.309922],
          [45.592229, 37.310886],
          [45.568329, 37.319786],
          [45.567625, 37.319959],
          [45.566448, 37.322401],
          [45.558281, 37.327187],
          [45.556482, 37.329002],
          [45.55517, 37.331976],
          [45.551817, 37.333207],
          [45.547613, 37.334865],
          [45.545712, 37.335708],
          [45.544244, 37.335721],
          [45.516357, 37.348327],
          [45.498361, 37.360486],
          [45.485939, 37.385714],
          [45.471038, 37.421981],
          [45.47102, 37.448492],
          [45.468128, 37.463085],
          [45.468718, 37.463814],
          [45.469843, 37.465205],
          [45.475158, 37.498832],
          [45.472267, 37.529275],
          [45.470285, 37.536297],
          [45.522537, 37.583222],
          [45.52185, 37.610424],
          [45.499191, 37.637073],
          [45.419922, 37.702268],
          [45.393104, 37.731082],
          [45.377476, 37.768285],
          [45.379252, 37.781453],
          [45.368904, 37.786558],
          [45.371647, 37.79188],
          [45.378341, 37.794593],
          [45.380942, 37.801427],
          [45.385894, 37.820091],
          [45.385267, 37.842333],
          [45.384434, 37.842611],
          [45.382461, 37.860489],
          [45.378681, 37.87449],
          [45.374904, 37.885066],
          [45.370102, 37.910076],
          [45.358641, 37.929773],
          [45.358641, 37.931724],
          [45.358641, 37.931724],
          [45.358641, 37.932559],
          [45.35836, 37.934222],
          [45.335768, 37.969708],
          [45.336044, 37.970544],
          [45.335768, 37.971092],
          [45.336044, 37.97137],
          [45.335491, 37.972763],
          [45.323475, 37.984632],
          [45.312635, 38.003712],
          [45.272598, 38.060257],
          [45.248222, 38.085933],
          [45.190514, 38.156132],
          [45.161161, 38.190148],
          [45.161161, 38.190148],
          [45.133586, 38.2178],
          [45.133569, 38.217821],
          [45.129089, 38.228202],
          [45.122909, 38.233057],
          [45.121536, 38.252471],
          [45.109014, 38.264989],
          [45.109014, 38.267765],
          [45.108734, 38.268043],
          [45.108734, 38.2686],
          [45.110049, 38.288093],
          [45.109901, 38.289026],
          [45.109067, 38.294029],
          [45.108787, 38.294308],
          [45.095121, 38.312254],
          [45.094844, 38.313368],
          [45.095121, 38.313924],
          [45.093731, 38.320311],
          [45.093523, 38.321146],
          [45.093454, 38.321972],
          [45.10614, 38.329084],
          [45.138433, 38.353063],
          [45.150648, 38.362188],
          [45.151481, 38.362188],
          [45.154258, 38.363581],
          [45.167871, 38.367192],
          [45.168424, 38.36747],
          [45.170924, 38.368027],
          [45.172038, 38.368027],
          [45.178999, 38.377597],
          [45.195946, 38.390369],
          [45.20256, 38.390917],
          [45.221858, 38.381341],
          [45.223868, 38.383125],
          [45.235425, 38.390513],
          [45.243587, 38.393474],
          [45.243238, 38.400917],
          [45.243795, 38.401474],
          [45.230198, 38.412711],
          [45.225735, 38.421991],
          [45.228374, 38.43072],
          [45.235434, 38.433535],
          [45.240434, 38.433804],
          [45.24182, 38.433535],
          [45.246824, 38.433804],
          [45.257548, 38.434235],
          [45.273509, 38.435895],
          [45.276289, 38.43673],
          [45.314999, 38.450228],
          [45.329761, 38.461654],
          [45.335604, 38.476431],
          [45.3265, 38.495385],
          [45.296116, 38.521041],
          [45.275001, 38.533396],
          [45.265254, 38.543176],
          [45.259701, 38.554017],
          [45.259421, 38.554295],
          [45.26865, 38.577695],
          [45.262351, 38.59004],
          [45.262071, 38.591979],
          [45.261794, 38.593927],
          [45.265809, 38.598582],
          [45.269167, 38.607027],
          [45.268054, 38.613141],
          [45.261667, 38.617863],
          [45.257904, 38.628372],
          [45.258184, 38.628928],
          [45.259017, 38.631702],
          [45.257904, 38.63532],
          [45.253461, 38.645319],
          [45.252627, 38.646154],
          [45.252627, 38.646711],
          [45.241019, 38.657473],
          [45.237405, 38.659142],
          [45.240186, 38.662473],
          [45.240463, 38.663029],
          [45.238795, 38.664142],
          [45.239629, 38.666368],
          [45.240186, 38.666368],
          [45.240186, 38.666647],
          [45.241852, 38.666647],
          [45.243242, 38.666916],
          [45.247685, 38.669421],
          [45.247409, 38.670812],
          [45.249247, 38.676833],
          [45.249247, 38.67712],
          [45.249526, 38.677668],
          [45.251193, 38.68212],
          [45.257522, 38.690635],
          [45.257802, 38.694522],
          [45.264191, 38.704807],
          [45.268904, 38.712673],
          [45.271403, 38.717124],
          [45.27307, 38.722402],
          [45.27346, 38.727823],
          [45.275127, 38.730318],
          [45.275407, 38.731431],
          [45.275684, 38.731988],
          [45.275684, 38.733101],
          [45.275127, 38.733379],
          [45.271516, 38.743098],
          [45.271362, 38.753891],
          [45.269141, 38.765002],
          [45.273285, 38.771484],
          [45.265747, 38.785261],
          [45.269418, 38.792508],
          [45.275598, 38.863099],
          [45.278173, 38.867031],
          [45.277419, 38.867743],
          [45.277699, 38.868021],
          [45.280682, 38.873106],
          [45.281516, 38.873662],
          [45.30636, 38.877317],
          [45.30636, 38.877874],
          [45.319812, 38.883686],
          [45.33622, 38.887752],
          [45.33622, 38.888308],
          [45.341713, 38.895612],
          [45.341434, 38.899717],
          [45.345898, 38.90466],
          [45.349331, 38.914678],
          [45.357742, 38.918418],
          [45.362377, 38.94419],
          [45.370617, 38.947795],
          [45.375494, 38.959335],
          [45.376328, 38.960995],
          [45.377907, 38.962552],
          [45.384021, 38.965602],
          [45.391521, 38.970886],
          [45.392631, 38.971442],
          [45.393464, 38.971442],
          [45.396245, 38.972554],
          [45.397631, 38.972554],
          [45.398464, 38.972832],
          [45.399854, 38.972832],
          [45.400688, 38.97311],
          [45.403744, 38.97311],
          [45.404297, 38.973379],
          [45.411416, 38.974059],
          [45.415859, 38.974615],
          [45.419472, 38.974615],
          [45.419749, 38.974893],
          [45.423451, 38.975227],
          [45.428329, 38.976731],
          [45.428606, 38.977287],
          [45.435828, 38.980624],
          [45.442077, 38.984216],
          [45.44319, 38.984494],
          [45.44958, 38.990325],
          [45.450133, 38.990602],
          [45.452905, 38.992997],
          [45.451275, 38.993802],
          [45.449469, 38.994459],
          [45.448779, 38.994588],
          [45.446414, 38.996843],
          [45.445431, 38.998285],
          [45.443594, 38.999562],
          [45.4404, 39.003296],
          [45.438327, 39.004424],
          [45.437941, 39.006386],
          [45.438852, 39.008706],
          [45.438612, 39.009704],
          [45.437712, 39.01022],
          [45.437189, 39.010872],
          [45.43689, 39.011778],
          [45.437059, 39.01276],
          [45.437482, 39.013617],
          [45.440011, 39.016968],
          [45.440179, 39.017896],
          [45.446515, 39.024481],
          [45.44648, 39.025146],
          [45.446941, 39.025789],
          [45.44806, 39.02621],
          [45.450106, 39.029028],
          [45.450552, 39.030628],
          [45.450486, 39.032183],
          [45.450073, 39.033388],
          [45.449797, 39.034433],
          [45.44977, 39.035094],
          [45.450708, 39.03789],
          [45.450428, 39.03873],
          [45.45035, 39.039505],
          [45.452479, 39.042452],
          [45.454338, 39.043958],
          [45.455993, 39.04502],
          [45.456612, 39.046982],
          [45.455584, 39.049068],
          [45.455739, 39.049601],
          [45.456675, 39.049801],
          [45.457033, 39.050244],
          [45.456903, 39.051128],
          [45.456282, 39.051908],
          [45.453702, 39.053821],
          [45.452701, 39.055054],
          [45.446566, 39.054836],
          [45.443887, 39.053867],
          [45.441841, 39.051774],
          [45.440709, 39.051408],
          [45.439314, 39.051476],
          [45.437331, 39.052684],
          [45.434978, 39.052733],
          [45.434413, 39.053213],
          [45.433266, 39.053349],
          [45.431826, 39.054916],
          [45.4321, 39.05618],
          [45.430347, 39.05951],
          [45.430387, 39.060639],
          [45.429125, 39.061195],
          [45.427787, 39.061175],
          [45.425447, 39.060906],
          [45.42238, 39.062504],
          [45.420186, 39.062916],
          [45.41844, 39.063845],
          [45.417762, 39.065173],
          [45.415826, 39.066387],
          [45.414142, 39.068299],
          [45.413053, 39.068613],
          [45.409303, 39.070928],
          [45.40649, 39.072232],
          [45.403084, 39.072779],
          [45.402055, 39.074888],
          [45.400546, 39.076826],
          [45.399916, 39.081634],
          [45.400663, 39.085929],
          [45.402092, 39.088302],
          [45.402429, 39.087903],
          [45.402411, 39.091674],
          [45.398717, 39.094991],
          [45.390115, 39.10188],
          [45.387049, 39.105195],
          [45.384084, 39.106918],
          [45.376111, 39.109025],
          [45.370898, 39.11182],
          [45.369123, 39.113329],
          [45.366396, 39.117312],
          [45.365212, 39.119822],
          [45.363889, 39.121201],
          [45.361842, 39.122879],
          [45.360189, 39.123534],
          [45.358501, 39.124027],
          [45.354688, 39.126394],
          [45.352965, 39.129383],
          [45.352587, 39.132955],
          [45.354036, 39.135192],
          [45.360395, 39.140191],
          [45.362214, 39.143968],
          [45.363199, 39.147283],
          [45.363165, 39.150858],
          [45.360758, 39.155953],
          [45.349781, 39.170574],
          [45.347652, 39.171053],
          [45.34498, 39.170931],
          [45.341481, 39.169509],
          [45.334764, 39.169037],
          [45.329266, 39.169877],
          [45.321512, 39.167935],
          [45.313492, 39.166138],
          [45.307509, 39.165777],
          [45.30424, 39.166023],
          [45.304037, 39.170006],
          [45.313591, 39.175294],
          [45.325009, 39.180619],
          [45.327581, 39.185903],
          [45.326012, 39.19023],
          [45.320116, 39.196181],
          [45.316613, 39.198498],
          [45.313379, 39.20019],
          [45.308499, 39.201626],
          [45.306088, 39.201775],
          [45.302592, 39.201228],
          [45.300922, 39.200619],
          [45.299343, 39.190398],
          [45.296351, 39.187832],
          [45.285656, 39.183344],
          [45.277045, 39.184576],
          [45.272909, 39.185898],
          [45.24763, 39.1856],
          [45.237282, 39.193566],
          [45.231129, 39.194092],
          [45.216843, 39.208721],
          [45.20365, 39.203651],
          [45.199421, 39.204784],
          [45.197945, 39.211562],
          [45.194625, 39.21423],
          [45.18995, 39.215351],
          [45.184771, 39.220523],
          [45.178149, 39.222527],
          [45.173438, 39.223156],
          [45.17109, 39.221693],
          [45.169554, 39.21857],
          [45.167539, 39.215778],
          [45.161233, 39.213035],
          [45.152932, 39.216351],
          [45.148067, 39.229162],
          [45.14829, 39.232025],
          [45.150881, 39.235741],
          [45.15221, 39.239977],
          [45.152038, 39.24151],
          [45.151415, 39.243149],
          [45.148376, 39.246257],
          [45.146897, 39.249229],
          [45.146217, 39.253868],
          [45.147808, 39.257603],
          [45.148695, 39.26108],
          [45.148409, 39.263487],
          [45.147642, 39.264332],
          [45.13699, 39.268006],
          [45.135821, 39.269253],
          [45.13469, 39.271386],
          [45.134504, 39.272043],
          [45.134743, 39.272763],
          [45.135942, 39.274113],
          [45.14026, 39.276827],
          [45.14194, 39.278844],
          [45.142576, 39.282538],
          [45.141981, 39.284471],
          [45.141021, 39.285888],
          [45.138903, 39.287084],
          [45.130944, 39.289161],
          [45.126673, 39.288276],
          [45.122007, 39.286835],
          [45.120663, 39.285581],
          [45.118996, 39.286125],
          [45.117819, 39.286835],
          [45.117549, 39.287447],
          [45.117951, 39.288663],
          [45.119006, 39.289577],
          [45.124589, 39.29148],
          [45.127072, 39.293052],
          [45.128832, 39.294699],
          [45.129387, 39.296333],
          [45.1286, 39.301274],
          [45.127327, 39.303596],
          [45.126773, 39.304142],
          [45.126039, 39.304507],
          [45.119605, 39.305826],
          [45.108732, 39.309231],
          [45.107877, 39.310265],
          [45.107382, 39.311527],
          [45.107045, 39.315574],
          [45.106754, 39.316653],
          [45.10332, 39.32218],
          [45.104092, 39.326872],
          [45.103536, 39.329116],
          [45.102127, 39.332658],
          [45.101332, 39.333318],
          [45.100216, 39.333693],
          [45.098066, 39.333267],
          [45.092216, 39.330568],
          [45.089615, 39.328858],
          [45.088737, 39.32874],
          [45.087698, 39.328858],
          [45.086278, 39.329214],
          [45.085172, 39.329666],
          [45.080321, 39.333571],
          [45.080014, 39.334801],
          [45.081273, 39.336781],
          [45.085506, 39.342076],
          [45.086332, 39.343804],
          [45.08703, 39.347382],
          [45.086704, 39.351247],
          [45.083043, 39.354497],
          [45.079212, 39.356448],
          [45.076406, 39.358477],
          [45.073387, 39.359896],
          [45.070156, 39.360546],
          [45.068145, 39.360571],
          [45.062096, 39.362291],
          [45.057143, 39.364346],
          [45.05662, 39.365154],
          [45.056631, 39.36606],
          [45.057728, 39.369712],
          [45.057632, 39.371587],
          [45.058891, 39.379951],
          [45.058121, 39.3812],
          [45.056345, 39.38214],
          [45.054499, 39.382369],
          [45.05244, 39.382168],
          [45.049216, 39.380281],
          [45.046779, 39.379563],
          [45.045914, 39.37953],
          [45.045259, 39.379687],
          [45.04275, 39.382217],
          [45.042, 39.384973],
          [45.041688, 39.386115],
          [45.04142, 39.388167],
          [45.041597, 39.390111],
          [45.041233, 39.39105],
          [45.040681, 39.391291],
          [45.040038, 39.391214],
          [45.036784, 39.390333],
          [45.03508, 39.390537],
          [45.033612, 39.391022],
          [45.029441, 39.394277],
          [45.026711, 39.397392],
          [45.02412, 39.398746],
          [45.022025, 39.400538],
          [45.022434, 39.402673],
          [45.021813, 39.404428],
          [45.019867, 39.405496],
          [45.017735, 39.405821],
          [45.010569, 39.404492],
          [45.0097, 39.404721],
          [45.009008, 39.405441],
          [45.008116, 39.406635],
          [45.007521, 39.408786],
          [45.006581, 39.409932],
          [45.005849, 39.410366],
          [45.005198, 39.410425],
          [45.002423, 39.409704],
          [44.99956, 39.408671],
          [44.998164, 39.409055],
          [44.996935, 39.409764],
          [44.995947, 39.412897],
          [44.988571, 39.415301],
          [44.985197, 39.416849],
          [44.983097, 39.418521],
          [44.970621, 39.419578],
          [44.966279, 39.420516],
          [44.965144, 39.421043],
          [44.96456, 39.421841],
          [44.963778, 39.423761],
          [44.964226, 39.426032],
          [44.965621, 39.429032],
          [44.96527, 39.432265],
          [44.96198, 39.43602],
          [44.960085, 39.439583],
          [44.960808, 39.442158],
          [44.962006, 39.442729],
          [44.964807, 39.442277],
          [44.966701, 39.4423],
          [44.969483, 39.44372],
          [44.973622, 39.444494],
          [44.976856, 39.446866],
          [44.97718, 39.448774],
          [44.97645, 39.450071],
          [44.975651, 39.450724],
          [44.974713, 39.451092],
          [44.970017, 39.451592],
          [44.961092, 39.450602],
          [44.950869, 39.452211],
          [44.948241, 39.451667],
          [44.947164, 39.451653],
          [44.946663, 39.452085],
          [44.946501, 39.456093],
          [44.94572, 39.457243],
          [44.942385, 39.458182],
          [44.939928, 39.459659],
          [44.93943, 39.46095],
          [44.939894, 39.462458],
          [44.942298, 39.464322],
          [44.943018, 39.465777],
          [44.943805, 39.467219],
          [44.944601, 39.467913],
          [44.9475, 39.469735],
          [44.948473, 39.471542],
          [44.947758, 39.472701],
          [44.943852, 39.473879],
          [44.938633, 39.477311],
          [44.936803, 39.479818],
          [44.936909, 39.480714],
          [44.938253, 39.481648],
          [44.942481, 39.481267],
          [44.944964, 39.481897],
          [44.947159, 39.481749],
          [44.94934, 39.482482],
          [44.950292, 39.483306],
          [44.950843, 39.484093],
          [44.950346, 39.484824],
          [44.948938, 39.485182],
          [44.945875, 39.484305],
          [44.942706, 39.483749],
          [44.940991, 39.484187],
          [44.9384, 39.486283],
          [44.937844, 39.487485],
          [44.938417, 39.488693],
          [44.940054, 39.489652],
          [44.94305, 39.489447],
          [44.946662, 39.487776],
          [44.949683, 39.487965],
          [44.951403, 39.489126],
          [44.951985, 39.490823],
          [44.95185, 39.492169],
          [44.947365, 39.497558],
          [44.940601, 39.502379],
          [44.939326, 39.504389],
          [44.938532, 39.50947],
          [44.936526, 39.511935],
          [44.935366, 39.51217],
          [44.934261, 39.511061],
          [44.932197, 39.511232],
          [44.929481, 39.511868],
          [44.927262, 39.513052],
          [44.925974, 39.514675],
          [44.923603, 39.516122],
          [44.918152, 39.517794],
          [44.917513, 39.520107],
          [44.916199, 39.521325],
          [44.912645, 39.522438],
          [44.909772, 39.525071],
          [44.909199, 39.528074],
          [44.914977, 39.529961],
          [44.917158, 39.531197],
          [44.918595, 39.533209],
          [44.918102, 39.534502],
          [44.912929, 39.537157],
          [44.90688, 39.53865],
          [44.906584, 39.539532],
          [44.910215, 39.545204],
          [44.910336, 39.546842],
          [44.911273, 39.548756],
          [44.908342, 39.556139],
          [44.908637, 39.55999],
          [44.910774, 39.561995],
          [44.912965, 39.563663],
          [44.91544, 39.565095],
          [44.915749, 39.565996],
          [44.915145, 39.567184],
          [44.911049, 39.568958],
          [44.909289, 39.570887],
          [44.905798, 39.575627],
          [44.903586, 39.576759],
          [44.890107, 39.579519],
          [44.88833, 39.581725],
          [44.888063, 39.583812],
          [44.891973, 39.588806],
          [44.898779, 39.595585],
          [44.899768, 39.59858],
          [44.900212, 39.601083],
          [44.898932, 39.603215],
          [44.897206, 39.605233],
          [44.895428, 39.605731],
          [44.893547, 39.60477],
          [44.891976, 39.601985],
          [44.892806, 39.599773],
          [44.892573, 39.597743],
          [44.890921, 39.596102],
          [44.876025, 39.593006],
          [44.873095, 39.593969],
          [44.872945, 39.596537],
          [44.873105, 39.599536],
          [44.873522, 39.600701],
          [44.880824, 39.6072],
          [44.881092, 39.60836],
          [44.880861, 39.609962],
          [44.87552, 39.615209],
          [44.872618, 39.615624],
          [44.868905, 39.615093],
          [44.8666, 39.613003],
          [44.866685, 39.607035],
          [44.866036, 39.605016],
          [44.864536, 39.603565],
          [44.861237, 39.602742],
          [44.855823, 39.602255],
          [44.853428, 39.602807],
          [44.840686, 39.611064],
          [44.837394, 39.61227],
          [44.82712, 39.610653],
          [44.824437, 39.610774],
          [44.821914, 39.61127],
          [44.819134, 39.613419],
          [44.816239, 39.615127],
          [44.812084, 39.619717],
          [44.809857, 39.624425],
          [44.80938, 39.627336],
          [44.808768, 39.627773],
          [44.808126, 39.62823],
          [44.807226, 39.628764],
          [44.80706, 39.628865],
          [44.806478, 39.629117],
          [44.806017, 39.629323],
          [44.804977, 39.629564],
          [44.804486, 39.630024],
          [44.804421, 39.630508],
          [44.804304, 39.630857],
          [44.804103, 39.631211],
          [44.803939, 39.631735],
          [44.803894, 39.632151],
          [44.803284, 39.632476],
          [44.803088, 39.632875],
          [44.803103, 39.63317],
          [44.803292, 39.633466],
          [44.803546, 39.633724],
          [44.803637, 39.634301],
          [44.803522, 39.634889],
          [44.80359, 39.635498],
          [44.803582, 39.636317],
          [44.803495, 39.637207],
          [44.803468, 39.63805],
          [44.803173, 39.63855],
          [44.80323, 39.639036],
          [44.803225, 39.639407],
          [44.803439, 39.640205],
          [44.803434, 39.640586],
          [44.803329, 39.641145],
          [44.803366, 39.641654],
          [44.803396, 39.641991],
          [44.803312, 39.642378],
          [44.803062, 39.642607],
          [44.802653, 39.642731],
          [44.802387, 39.642893],
          [44.802252, 39.643146],
          [44.802211, 39.643858],
          [44.801845, 39.644135],
          [44.801417, 39.644133],
          [44.801087, 39.644207],
          [44.801084, 39.644487],
          [44.800883, 39.644765],
          [44.800419, 39.645016],
          [44.799782, 39.645928],
          [44.799417, 39.64618],
          [44.799314, 39.64656],
          [44.799077, 39.647118],
          [44.798876, 39.647422],
          [44.798638, 39.648081],
          [44.79853, 39.648869],
          [44.798231, 39.64907],
          [44.79737, 39.649446],
          [44.796611, 39.649594],
          [44.796115, 39.649769],
          [44.795455, 39.649867],
          [44.794759, 39.650218],
          [44.793836, 39.650264],
          [44.792713, 39.650511],
          [44.792318, 39.650458],
          [44.791857, 39.650455],
          [44.789578, 39.651026],
          [44.789016, 39.651124],
          [44.7876, 39.651141],
          [44.787398, 39.65147],
          [44.787409, 39.652229],
          [44.787817, 39.653151],
          [44.787614, 39.653435],
          [44.787291, 39.653363],
          [44.786992, 39.653169],
          [44.786697, 39.653337],
          [44.78674, 39.653764],
          [44.786869, 39.654245],
          [44.786759, 39.654743],
          [44.786471, 39.65507],
          [44.786235, 39.655117],
          [44.785369, 39.654807],
          [44.784685, 39.654664],
          [44.784227, 39.654813],
          [44.783248, 39.655636],
          [44.782935, 39.656418],
          [44.782486, 39.656726],
          [44.780108, 39.657454],
          [44.778467, 39.659575],
          [44.775085, 39.660951],
          [44.774486, 39.660898],
          [44.773895, 39.660537],
          [44.770348, 39.661036],
          [44.770116, 39.661088],
          [44.769629, 39.66172],
          [44.769537, 39.661897],
          [44.769887, 39.662159],
          [44.770109, 39.662512],
          [44.770061, 39.66355],
          [44.769145, 39.664613],
          [44.767882, 39.665393],
          [44.767329, 39.665427],
          [44.764645, 39.665109],
          [44.764025, 39.665299],
          [44.762502, 39.666128],
          [44.760628, 39.667891],
          [44.760677, 39.668406],
          [44.762075, 39.669232],
          [44.76161, 39.670084],
          [44.761536, 39.670515],
          [44.761112, 39.670634],
          [44.760424, 39.670494],
          [44.759585, 39.670425],
          [44.758789, 39.670018],
          [44.757952, 39.66998],
          [44.756858, 39.670412],
          [44.756313, 39.670533],
          [44.755507, 39.67071],
          [44.754805, 39.670784],
          [44.754522, 39.671107],
          [44.754807, 39.671576],
          [44.754468, 39.672053],
          [44.754394, 39.67239],
          [44.754083, 39.672711],
          [44.754403, 39.673054],
          [44.754792, 39.67303],
          [44.755763, 39.672337],
          [44.756105, 39.671923],
          [44.756853, 39.67151],
          [44.757327, 39.671511],
          [44.757894, 39.671659],
          [44.758045, 39.671923],
          [44.75806, 39.672376],
          [44.758387, 39.67278],
          [44.758513, 39.673165],
          [44.758829, 39.673477],
          [44.758618, 39.674034],
          [44.758501, 39.674769],
          [44.758745, 39.675396],
          [44.758566, 39.675895],
          [44.758176, 39.676644],
          [44.757805, 39.676895],
          [44.75714, 39.677106],
          [44.756417, 39.677153],
          [44.755858, 39.677406],
          [44.7554, 39.677432],
          [44.755083, 39.677768],
          [44.754682, 39.677939],
          [44.754153, 39.678402],
          [44.754109, 39.678919],
          [44.754221, 39.679166],
          [44.753595, 39.680145],
          [44.753703, 39.681736],
          [44.753169, 39.682035],
          [44.752818, 39.682082],
          [44.752414, 39.682421],
          [44.751886, 39.682863],
          [44.75173, 39.683134],
          [44.751849, 39.683627],
          [44.751585, 39.683899],
          [44.751539, 39.684228],
          [44.751301, 39.684437],
          [44.751316, 39.685074],
          [44.750949, 39.685532],
          [44.749937, 39.686271],
          [44.750078, 39.686728],
          [44.749863, 39.686988],
          [44.749517, 39.68722],
          [44.749201, 39.687596],
          [44.748722, 39.687891],
          [44.74832, 39.687958],
          [44.747975, 39.688231],
          [44.747544, 39.688236],
          [44.746982, 39.688345],
          [44.745867, 39.689102],
          [44.744902, 39.689257],
          [44.743945, 39.68995],
          [44.743817, 39.690407],
          [44.743393, 39.690847],
          [44.743212, 39.69118],
          [44.742897, 39.691576],
          [44.742852, 39.691969],
          [44.742643, 39.69226],
          [44.742734, 39.692692],
          [44.74266, 39.692981],
          [44.741971, 39.693484],
          [44.741841, 39.693734],
          [44.741407, 39.693697],
          [44.74113, 39.693808],
          [44.740531, 39.693782],
          [44.740487, 39.693488],
          [44.739527, 39.693231],
          [44.739097, 39.69334],
          [44.738598, 39.693147],
          [44.738126, 39.693123],
          [44.737468, 39.693425],
          [44.73716, 39.693305],
          [44.736696, 39.693514],
          [44.736472, 39.693455],
          [44.736091, 39.693568],
          [44.73583, 39.69357],
          [44.735352, 39.693791],
          [44.734285, 39.693832],
          [44.733431, 39.69454],
          [44.733356, 39.69482],
          [44.732964, 39.695366],
          [44.732202, 39.696675],
          [44.731583, 39.697307],
          [44.730687, 39.698016],
          [44.730592, 39.698233],
          [44.730073, 39.698438],
          [44.729787, 39.69824],
          [44.729368, 39.698351],
          [44.728993, 39.698632],
          [44.728918, 39.698817],
          [44.728599, 39.698897],
          [44.728103, 39.699211],
          [44.727857, 39.699217],
          [44.727735, 39.699344],
          [44.727697, 39.699531],
          [44.727388, 39.69968],
          [44.727166, 39.70036],
          [44.7274, 39.700805],
          [44.727753, 39.700982],
          [44.727815, 39.701544],
          [44.728025, 39.70169],
          [44.728335, 39.702456],
          [44.727899, 39.702879],
          [44.727742, 39.703129],
          [44.727544, 39.703286],
          [44.726983, 39.703541],
          [44.726845, 39.703713],
          [44.726645, 39.703777],
          [44.726367, 39.703996],
          [44.726151, 39.704308],
          [44.725715, 39.704746],
          [44.725639, 39.705027],
          [44.72516, 39.705327],
          [44.724487, 39.705877],
          [44.724148, 39.705973],
          [44.723986, 39.705928],
          [44.723631, 39.706272],
          [44.723289, 39.706291],
          [44.722755, 39.706823],
          [44.722615, 39.70684],
          [44.722359, 39.70709],
          [44.722138, 39.707123],
          [44.721956, 39.707032],
          [44.721577, 39.707284],
          [44.721438, 39.707457],
          [44.720754, 39.707526],
          [44.720373, 39.707717],
          [44.719457, 39.707605],
          [44.7193, 39.707741],
          [44.718924, 39.707911],
          [44.718564, 39.708004],
          [44.718127, 39.7083],
          [44.717948, 39.708553],
          [44.717585, 39.708683],
          [44.717325, 39.708874],
          [44.717266, 39.708984],
          [44.717045, 39.709096],
          [44.716989, 39.709393],
          [44.716772, 39.709691],
          [44.716755, 39.709847],
          [44.716434, 39.709959],
          [44.71638, 39.710286],
          [44.716201, 39.710396],
          [44.716126, 39.710707],
          [44.715866, 39.710818],
          [44.715791, 39.711082],
          [44.715532, 39.711255],
          [44.715494, 39.711348],
          [44.715295, 39.711443],
          [44.715158, 39.711676],
          [44.714921, 39.711848],
          [44.714884, 39.712034],
          [44.715148, 39.71217],
          [44.715073, 39.712449],
          [44.715096, 39.712635],
          [44.714957, 39.712713],
          [44.714777, 39.712714],
          [44.714679, 39.712839],
          [44.714545, 39.713259],
          [44.714727, 39.713428],
          [44.714776, 39.713955],
          [44.714979, 39.714062],
          [44.715083, 39.714278],
          [44.71454, 39.714299],
          [44.713642, 39.7146],
          [44.7135, 39.714492],
          [44.71322, 39.714447],
          [44.713082, 39.714587],
          [44.712417, 39.714596],
          [44.712074, 39.714349],
          [44.712033, 39.714209],
          [44.71181, 39.714072],
          [44.711243, 39.713987],
          [44.711039, 39.713898],
          [44.711268, 39.713785],
          [44.71124, 39.713337],
          [44.710646, 39.71339],
          [44.710628, 39.713514],
          [44.710712, 39.713776],
          [44.710655, 39.713932],
          [44.710537, 39.71398],
          [44.710358, 39.713951],
          [44.710354, 39.713703],
          [44.710272, 39.713565],
          [44.710007, 39.71349],
          [44.709676, 39.71323],
          [44.709433, 39.713248],
          [44.709045, 39.713405],
          [44.708739, 39.713238],
          [44.708619, 39.713285],
          [44.708621, 39.713486],
          [44.708441, 39.713518],
          [44.708258, 39.713212],
          [44.708058, 39.713399],
          [44.7079, 39.71337],
          [44.707816, 39.713155],
          [44.7075, 39.713159],
          [44.707377, 39.713098],
          [44.707196, 39.713131],
          [44.707058, 39.713302],
          [44.706851, 39.713088],
          [44.706729, 39.713073],
          [44.706448, 39.713169],
          [44.706286, 39.713139],
          [44.706163, 39.713063],
          [44.70618, 39.712939],
          [44.705197, 39.712565],
          [44.70492, 39.712599],
          [44.704605, 39.712834],
          [44.704251, 39.712466],
          [44.703843, 39.71227],
          [44.703479, 39.711949],
          [44.703181, 39.712091],
          [44.703061, 39.712046],
          [44.702893, 39.711584],
          [44.703085, 39.711181],
          [44.703265, 39.711133],
          [44.703608, 39.711238],
          [44.703928, 39.711235],
          [44.704045, 39.711079],
          [44.703863, 39.710988],
          [44.703604, 39.711006],
          [44.703441, 39.710838],
          [44.703676, 39.710558],
          [44.703633, 39.710419],
          [44.703353, 39.710421],
          [44.703209, 39.710207],
          [44.702986, 39.710148],
          [44.702868, 39.710304],
          [44.702262, 39.710096],
          [44.701603, 39.710211],
          [44.701327, 39.71049],
          [44.700867, 39.710617],
          [44.700709, 39.710849],
          [44.700488, 39.710927],
          [44.699823, 39.710918],
          [44.699149, 39.711175],
          [44.698972, 39.711332],
          [44.698373, 39.711508],
          [44.697899, 39.712192],
          [44.697739, 39.712287],
          [44.696936, 39.712248],
          [44.696638, 39.712421],
          [44.696418, 39.712625],
          [44.695765, 39.71257],
          [44.695516, 39.712775],
          [44.695371, 39.712808],
          [44.694869, 39.712595],
          [44.694479, 39.712611],
          [44.69416, 39.712831],
          [44.693638, 39.713071],
          [44.693501, 39.713351],
          [44.693342, 39.713507],
          [44.691957, 39.713475],
          [44.69174, 39.713662],
          [44.690575, 39.713643],
          [44.69019, 39.713508],
          [44.690186, 39.713353],
          [44.690402, 39.713165],
          [44.690298, 39.712934],
          [44.68979, 39.712922],
          [44.689185, 39.713287],
          [44.688968, 39.713321],
          [44.688725, 39.713138],
          [44.688527, 39.713187],
          [44.688285, 39.713328],
          [44.688212, 39.713976],
          [44.689449, 39.715489],
          [44.690193, 39.71576],
          [44.690805, 39.716355],
          [44.690616, 39.717036],
          [44.690719, 39.717204],
          [44.690721, 39.717359],
          [44.690239, 39.717319],
          [44.689261, 39.717622],
          [44.689059, 39.717484],
          [44.68878, 39.717455],
          [44.688457, 39.717209],
          [44.687513, 39.716816],
          [44.687172, 39.716773],
          [44.686611, 39.716213],
          [44.686307, 39.716094],
          [44.686271, 39.71586],
          [44.685807, 39.715572],
          [44.685481, 39.715561],
          [44.685317, 39.715393],
          [44.684772, 39.715167],
          [44.684492, 39.715201],
          [44.68401, 39.715129],
          [44.683509, 39.715134],
          [44.682866, 39.715033],
          [44.682426, 39.714821],
          [44.681433, 39.715196],
          [44.681011, 39.715214],
          [44.679708, 39.715089],
          [44.679159, 39.714647],
          [44.678535, 39.714407],
          [44.678094, 39.714349],
          [44.677775, 39.71443],
          [44.677519, 39.714696],
          [44.677327, 39.715176],
          [44.676949, 39.715333],
          [44.676608, 39.715227],
          [44.676403, 39.714857],
          [44.67606, 39.714612],
          [44.675478, 39.714574],
          [44.674897, 39.714753],
          [44.674535, 39.71468],
          [44.67381, 39.714815],
          [44.673608, 39.714693],
          [44.673566, 39.714399],
          [44.673424, 39.714292],
          [44.673203, 39.714295],
          [44.672571, 39.714623],
          [44.671732, 39.714926],
          [44.671436, 39.71488],
          [44.671132, 39.714326],
          [44.670527, 39.714652],
          [44.668855, 39.714411],
          [44.668443, 39.714488],
          [44.667948, 39.714801],
          [44.667393, 39.71491],
          [44.667316, 39.715049],
          [44.667244, 39.71573],
          [44.666911, 39.716118],
          [44.666601, 39.716659],
          [44.666363, 39.7168],
          [44.665662, 39.716745],
          [44.665284, 39.716918],
          [44.664698, 39.717802],
          [44.66442, 39.717928],
          [44.663639, 39.717967],
          [44.663216, 39.71774],
          [44.662978, 39.717743],
          [44.662506, 39.718071],
          [44.662249, 39.71815],
          [44.661749, 39.71814],
          [44.661294, 39.718513],
          [44.66112, 39.718838],
          [44.661227, 39.719143],
          [44.661151, 39.719328],
          [44.660876, 39.719607],
          [44.660899, 39.719807],
          [44.661383, 39.720233],
          [44.661467, 39.720495],
          [44.66133, 39.720758],
          [44.660874, 39.721274],
          [44.660574, 39.721355],
          [44.660131, 39.721283],
          [44.65965, 39.721351],
          [44.659447, 39.721276],
          [44.659268, 39.72137],
          [44.659317, 39.721754],
          [44.65914, 39.721956],
          [44.658745, 39.722175],
          [44.658591, 39.722468],
          [44.658097, 39.722795],
          [44.657909, 39.723334],
          [44.65756, 39.723859],
          [44.657012, 39.724385],
          [44.65694, 39.724722],
          [44.656166, 39.725294],
          [44.655443, 39.725332],
          [44.655185, 39.725473],
          [44.655052, 39.72572],
          [44.654925, 39.726227],
          [44.654456, 39.726616],
          [44.654118, 39.726681],
          [44.653967, 39.726929],
          [44.653771, 39.727085],
          [44.653311, 39.727242],
          [44.652581, 39.72765],
          [44.652362, 39.727699],
          [44.652154, 39.727255],
          [44.651974, 39.727272],
          [44.651437, 39.727446],
          [44.650256, 39.727426],
          [44.65, 39.727614],
          [44.649745, 39.727955],
          [44.649427, 39.728066],
          [44.648946, 39.727961],
          [44.64854, 39.727503],
          [44.648199, 39.727353],
          [44.64784, 39.727355],
          [44.647625, 39.727541],
          [44.647836, 39.728323],
          [44.647719, 39.72854],
          [44.647443, 39.728663],
          [44.647007, 39.728635],
          [44.646244, 39.728801],
          [44.646179, 39.728927],
          [44.646386, 39.729049],
          [44.646766, 39.729184],
          [44.646817, 39.729415],
          [44.646649, 39.729635],
          [44.646734, 39.729939],
          [44.64673, 39.730464],
          [44.646563, 39.730968],
          [44.645802, 39.731943],
          [44.64573, 39.732385],
          [44.645412, 39.732486],
          [44.645271, 39.732659],
          [44.645145, 39.732733],
          [44.644471, 39.732696],
          [44.644105, 39.73275],
          [44.643885, 39.732899],
          [44.643516, 39.732744],
          [44.642998, 39.733165],
          [44.642685, 39.733562],
          [44.642147, 39.733854],
          [44.641929, 39.734088],
          [44.642014, 39.734377],
          [44.642636, 39.73448],
          [44.642993, 39.73478],
          [44.643022, 39.735514],
          [44.642725, 39.73581],
          [44.642587, 39.736117],
          [44.641717, 39.736395],
          [44.641179, 39.73667],
          [44.641011, 39.737137],
          [44.641112, 39.737442],
          [44.64104, 39.737919],
          [44.641492, 39.738306],
          [44.640933, 39.739105],
          [44.640539, 39.739341],
          [44.64026, 39.73976],
          [44.639635, 39.740361],
          [44.639434, 39.740707],
          [44.638945, 39.741007],
          [44.638566, 39.740794],
          [44.638108, 39.74087],
          [44.637778, 39.741181],
          [44.63764, 39.741451],
          [44.637466, 39.741601],
          [44.636994, 39.741295],
          [44.636737, 39.741388],
          [44.636546, 39.74154],
          [44.636123, 39.741478],
          [44.635857, 39.7416],
          [44.635491, 39.742126],
          [44.635399, 39.742519],
          [44.635028, 39.742925],
          [44.635011, 39.743302],
          [44.634554, 39.743465],
          [44.633069, 39.743345],
          [44.632877, 39.743396],
          [44.632561, 39.743633],
          [44.632392, 39.744211],
          [44.63214, 39.744372],
          [44.631616, 39.744451],
          [44.631471, 39.744391],
          [44.631452, 39.744146],
          [44.631275, 39.74405],
          [44.630703, 39.744093],
          [44.630499, 39.744279],
          [44.630569, 39.74473],
          [44.630877, 39.745069],
          [44.630883, 39.745423],
          [44.630194, 39.746016],
          [44.629689, 39.746241],
          [44.629281, 39.746601],
          [44.629126, 39.746887],
          [44.629208, 39.747085],
          [44.629706, 39.747287],
          [44.629821, 39.747468],
          [44.629826, 39.747809],
          [44.630119, 39.748217],
          [44.630154, 39.748445],
          [44.630015, 39.748679],
          [44.630097, 39.748838],
          [44.630579, 39.749116],
          [44.630969, 39.749455],
          [44.630924, 39.749651],
          [44.630624, 39.749844],
          [44.630542, 39.750077],
          [44.630784, 39.750306],
          [44.631341, 39.750332],
          [44.631543, 39.750991],
          [44.631516, 39.751262],
          [44.631728, 39.751554],
          [44.631652, 39.751801],
          [44.631337, 39.751966],
          [44.63118, 39.752114],
          [44.631137, 39.752396],
          [44.631237, 39.752712],
          [44.631527, 39.752905],
          [44.632409, 39.753313],
          [44.63254, 39.753532],
          [44.632577, 39.75391],
          [44.632424, 39.75529],
          [44.632434, 39.755867],
          [44.632293, 39.756037],
          [44.631992, 39.756107],
          [44.631083, 39.75589],
          [44.630445, 39.755852],
          [44.630018, 39.755979],
          [44.629639, 39.756229],
          [44.629579, 39.756399],
          [44.629662, 39.756619],
          [44.63008, 39.756884],
          [44.630764, 39.757754],
          [44.630884, 39.758187],
          [44.630849, 39.758519],
          [44.630355, 39.758916],
          [44.629773, 39.759101],
          [44.629225, 39.759101],
          [44.628592, 39.758975],
          [44.628129, 39.758819],
          [44.627837, 39.758639],
          [44.627412, 39.758603],
          [44.627223, 39.758715],
          [44.627162, 39.758851],
          [44.627212, 39.759022],
          [44.627505, 39.759277],
          [44.627555, 39.759461],
          [44.627425, 39.759623],
          [44.626909, 39.759837],
          [44.626622, 39.759877],
          [44.626275, 39.760064],
          [44.625918, 39.760555],
          [44.62594, 39.760824],
          [44.626086, 39.761042],
          [44.626533, 39.761293],
          [44.62668, 39.761451],
          [44.626731, 39.761743],
          [44.62667, 39.761987],
          [44.626386, 39.762206],
          [44.625948, 39.762313],
          [44.625336, 39.762513],
          [44.625033, 39.762824],
          [44.624336, 39.763315],
          [44.624057, 39.763622],
          [44.623969, 39.764834],
          [44.623814, 39.765165],
          [44.623575, 39.765551],
          [44.623128, 39.766013],
          [44.622905, 39.766066],
          [44.62189, 39.76605],
          [44.62167, 39.766161],
          [44.621619, 39.766635],
          [44.621611, 39.767001],
          [44.621632, 39.768714],
          [44.620767, 39.771085],
          [44.620322, 39.772169],
          [44.619936, 39.772882],
          [44.61954, 39.774512],
          [44.61831, 39.775896],
          [44.617909, 39.776393],
          [44.617603, 39.777297],
          [44.616436, 39.779469],
          [44.615798, 39.780206],
          [44.615406, 39.780572],
          [44.615609, 39.781046],
          [44.615544, 39.781825],
          [44.615107, 39.782159],
          [44.614695, 39.782462],
          [44.614266, 39.782152],
          [44.613772, 39.781957],
          [44.613338, 39.78165],
          [44.611786, 39.780803],
          [44.609337, 39.779054],
          [44.601955, 39.774204],
          [44.591394, 39.767092],
          [44.585006, 39.765525],
          [44.584256, 39.763933],
          [44.581135, 39.762115],
          [44.576848, 39.7575],
          [44.565778, 39.75036],
          [44.550833, 39.739589],
          [44.53065, 39.72585],
          [44.519837, 39.71917],
          [44.514307, 39.715655],
          [44.49333, 39.70185],
          [44.485526, 39.694694],
          [44.467197, 39.684102],
          [44.468677, 39.68236],
          [44.4691, 39.681894],
          [44.469704, 39.68158],
          [44.470125, 39.68115],
          [44.471775, 39.68066],
          [44.472608, 39.680185],
          [44.472826, 39.679921],
          [44.473191, 39.679772],
          [44.473595, 39.679434],
          [44.474432, 39.678294],
          [44.47489, 39.676908],
          [44.474803, 39.676321],
          [44.475355, 39.675613],
          [44.475493, 39.67473],
          [44.475765, 39.674142],
          [44.476605, 39.673617],
          [44.477117, 39.672696],
          [44.478542, 39.6711],
          [44.478797, 39.670538],
          [44.479402, 39.670125],
          [44.47976, 39.668105],
          [44.480101, 39.667369],
          [44.479997, 39.666835],
          [44.48035, 39.666704],
          [44.48039, 39.665857],
          [44.480825, 39.665471],
          [44.481091, 39.664406],
          [44.481361, 39.66414],
          [44.482542, 39.663621],
          [44.482558, 39.663144],
          [44.482847, 39.662799],
          [44.482263, 39.661042],
          [44.482134, 39.66],
          [44.481172, 39.658655],
          [44.480381, 39.658153],
          [44.480304, 39.657892],
          [44.48028, 39.657596],
          [44.479333, 39.656525],
          [44.478779, 39.655639],
          [44.479008, 39.655617],
          [44.478934, 39.655374],
          [44.478611, 39.65514],
          [44.478624, 39.654854],
          [44.477561, 39.653908],
          [44.47757, 39.653297],
          [44.478002, 39.653007],
          [44.477698, 39.652266],
          [44.477546, 39.652169],
          [44.477152, 39.651327],
          [44.476878, 39.64969],
          [44.477369, 39.648938],
          [44.477674, 39.647651],
          [44.478088, 39.647502],
          [44.478642, 39.647545],
          [44.47889, 39.646238],
          [44.484343, 39.647106],
          [44.484339, 39.646647],
          [44.484466, 39.646188],
          [44.485187, 39.645674],
          [44.485909, 39.645262],
          [44.487341, 39.643367],
          [44.487663, 39.642549],
          [44.487719, 39.64158],
          [44.487232, 39.639085],
          [44.486827, 39.63817],
          [44.486819, 39.637456],
          [44.486065, 39.634656],
          [44.485924, 39.63374],
          [44.485584, 39.632722],
          [44.484966, 39.630329],
          [44.484549, 39.628242],
          [44.483656, 39.62478],
          [44.482632, 39.62132],
          [44.481596, 39.616686],
          [44.481117, 39.614956],
          [44.480148, 39.610424],
          [44.477627, 39.609012],
          [44.477224, 39.608249],
          [44.474504, 39.606634],
          [44.466464, 39.600665],
          [44.465403, 39.600059],
          [44.46487, 39.599502],
          [44.462333, 39.596356],
          [44.460799, 39.59458],
          [44.45953, 39.592854],
          [44.453325, 39.585344],
          [44.449991, 39.581489],
          [44.446855, 39.577632],
          [44.445458, 39.576314],
          [44.443066, 39.574339],
          [44.441672, 39.573276],
          [44.440214, 39.57252],
          [44.439224, 39.572372],
          [44.437972, 39.57243],
          [44.435925, 39.572034],
          [44.431694, 39.570732],
          [44.429183, 39.570134],
          [44.427861, 39.569733],
          [44.426074, 39.568876],
          [44.42442, 39.568223],
          [44.422106, 39.567573],
          [44.422694, 39.567009],
          [44.423546, 39.566392],
          [44.424136, 39.566032],
          [44.424394, 39.56547],
          [44.423658, 39.564301],
          [44.42093, 39.561461],
          [44.418939, 39.559993],
          [44.418073, 39.558979],
          [44.416204, 39.55644],
          [44.416255, 39.554808],
          [44.417966, 39.554493],
          [44.419672, 39.553668],
          [44.420518, 39.552491],
          [44.420707, 39.551572],
          [44.420836, 39.551265],
          [44.421621, 39.550649],
          [44.422408, 39.550186],
          [44.423257, 39.549417],
          [44.423782, 39.549108],
          [44.423646, 39.548701],
          [44.423312, 39.548193],
          [44.423635, 39.547528],
          [44.424421, 39.546963],
          [44.424746, 39.546452],
          [44.425853, 39.545069],
          [44.426242, 39.544404],
          [44.426562, 39.543383],
          [44.426615, 39.541955],
          [44.426276, 39.540886],
          [44.426136, 39.540071],
          [44.42633, 39.539662],
          [44.427046, 39.538639],
          [44.427107, 39.538129],
          [44.426381, 39.53798],
          [44.425322, 39.537527],
          [44.425375, 39.53615],
          [44.425227, 39.534315],
          [44.425217, 39.533296],
          [44.425076, 39.532328],
          [44.425667, 39.53207],
          [44.426062, 39.532017],
          [44.426587, 39.531861],
          [44.427177, 39.531501],
          [44.4294, 39.529704],
          [44.429525, 39.52899],
          [44.429581, 39.527868],
          [44.429707, 39.527255],
          [44.430938, 39.525056],
          [44.432178, 39.523775],
          [44.432829, 39.522955],
          [44.433813, 39.522542],
          [44.434994, 39.521975],
          [44.435451, 39.521564],
          [44.435906, 39.52095],
          [44.435568, 39.519983],
          [44.435563, 39.519473],
          [44.434963, 39.518763],
          [44.434565, 39.518408],
          [44.433439, 39.517752],
          [44.43284, 39.517041],
          [44.432766, 39.516277],
          [44.43282, 39.515002],
          [44.432805, 39.51332],
          [44.432722, 39.511485],
          [44.43278, 39.51072],
          [44.432906, 39.510107],
          [44.433358, 39.509085],
          [44.43461, 39.509129],
          [44.43553, 39.508971],
          [44.437504, 39.508756],
          [44.438423, 39.508394],
          [44.438941, 39.507525],
          [44.439657, 39.506552],
          [44.440367, 39.505069],
          [44.440099, 39.504561],
          [44.439166, 39.503445],
          [44.437974, 39.502687],
          [44.437639, 39.502128],
          [44.438359, 39.501614],
          [44.439737, 39.501147],
          [44.438742, 39.500337],
          [44.438538, 39.499886],
          [44.435834, 39.497723],
          [44.433874, 39.495763],
          [44.4334, 39.493262],
          [44.432995, 39.492518],
          [44.429209, 39.490558],
          [44.427114, 39.488868],
          [44.425221, 39.488057],
          [44.424342, 39.488057],
          [44.420557, 39.486773],
          [44.4188, 39.486097],
          [44.417583, 39.48515],
          [44.416636, 39.48346],
          [44.415893, 39.482446],
          [44.415217, 39.480486],
          [44.414406, 39.479067],
          [44.413865, 39.477917],
          [44.413189, 39.476903],
          [44.412648, 39.475551],
          [44.411972, 39.474876],
          [44.410215, 39.474267],
          [44.408852, 39.473149],
          [44.408434, 39.471149],
          [44.410149, 39.470191],
          [44.411961, 39.469078],
          [44.413566, 39.468674],
          [44.414531, 39.467749],
          [44.416257, 39.467066],
          [44.41651, 39.466818],
          [44.416636, 39.466554],
          [44.417464, 39.466513],
          [44.417965, 39.466299],
          [44.418862, 39.465563],
          [44.419397, 39.465564],
          [44.419735, 39.465381],
          [44.420322, 39.464821],
          [44.420357, 39.464266],
          [44.420288, 39.463876],
          [44.420296, 39.462911],
          [44.420373, 39.462286],
          [44.420748, 39.461723],
          [44.421147, 39.461309],
          [44.421272, 39.461046],
          [44.421611, 39.460799],
          [44.421823, 39.460454],
          [44.421905, 39.460042],
          [44.422196, 39.459708],
          [44.42223, 39.459328],
          [44.421821, 39.459101],
          [44.421745, 39.45833],
          [44.421954, 39.458099],
          [44.422116, 39.457506],
          [44.422088, 39.456763],
          [44.422863, 39.455636],
          [44.422816, 39.455359],
          [44.422598, 39.455116],
          [44.422614, 39.454773],
          [44.42291, 39.454488],
          [44.422992, 39.454205],
          [44.422925, 39.453824],
          [44.422965, 39.453558],
          [44.424276, 39.452939],
          [44.424527, 39.452664],
          [44.424792, 39.451701],
          [44.424531, 39.451369],
          [44.424571, 39.451153],
          [44.424848, 39.45112],
          [44.425102, 39.45097],
          [44.4251, 39.450854],
          [44.424905, 39.450639],
          [44.424768, 39.449948],
          [44.424274, 39.449706],
          [44.424165, 39.449493],
          [44.424141, 39.449229],
          [44.424458, 39.449063],
          [44.424517, 39.448716],
          [44.424491, 39.448337],
          [44.424657, 39.448006],
          [44.425164, 39.44733],
          [44.425057, 39.446729],
          [44.425277, 39.446596],
          [44.425377, 39.446423],
          [44.425084, 39.446202],
          [44.425167, 39.446083],
          [44.425472, 39.44599],
          [44.425863, 39.445802],
          [44.42598, 39.445536],
          [44.426286, 39.445441],
          [44.426576, 39.445412],
          [44.426852, 39.445581],
          [44.427005, 39.445526],
          [44.427188, 39.445181],
          [44.427325, 39.445135],
          [44.4276, 39.445278],
          [44.427915, 39.445275],
          [44.428014, 39.445206],
          [44.427982, 39.444973],
          [44.428124, 39.444884],
          [44.428453, 39.444903],
          [44.428565, 39.444865],
          [44.428633, 39.444767],
          [44.428807, 39.444748],
          [44.429679, 39.444347],
          [44.430146, 39.444307],
          [44.430304, 39.444222],
          [44.430312, 39.44405],
          [44.430559, 39.443693],
          [44.430704, 39.443624],
          [44.430878, 39.443417],
          [44.431325, 39.443145],
          [44.4321, 39.44282],
          [44.432383, 39.442913],
          [44.432642, 39.442905],
          [44.432814, 39.44282],
          [44.432857, 39.442725],
          [44.432442, 39.4425],
          [44.43239, 39.442372],
          [44.432273, 39.442295],
          [44.431933, 39.442248],
          [44.431896, 39.442131],
          [44.431994, 39.441957],
          [44.43196, 39.441562],
          [44.432082, 39.441506],
          [44.432267, 39.441501],
          [44.432465, 39.441704],
          [44.432529, 39.441693],
          [44.43257, 39.441621],
          [44.432482, 39.441304],
          [44.432552, 39.441266],
          [44.43269, 39.441347],
          [44.432732, 39.441303],
          [44.432613, 39.441112],
          [44.432533, 39.441069],
          [44.43229, 39.441071],
          [44.432282, 39.441],
          [44.432437, 39.440928],
          [44.432409, 39.440894],
          [44.432316, 39.440912],
          [44.432383, 39.440693],
          [44.432326, 39.440618],
          [44.432031, 39.440489],
          [44.431966, 39.440414],
          [44.432016, 39.440126],
          [44.431845, 39.43981],
          [44.431841, 39.439557],
          [44.432079, 39.439498],
          [44.432544, 39.439602],
          [44.432606, 39.439515],
          [44.432583, 39.43942],
          [44.432452, 39.439343],
          [44.432356, 39.439188],
          [44.432377, 39.439127],
          [44.432514, 39.439099],
          [44.432476, 39.438893],
          [44.432642, 39.438709],
          [44.432583, 39.43862],
          [44.432262, 39.438499],
          [44.432261, 39.438421],
          [44.432638, 39.438132],
          [44.432681, 39.437973],
          [44.432345, 39.438039],
          [44.432242, 39.437983],
          [44.432408, 39.437773],
          [44.432656, 39.437726],
          [44.43272, 39.437618],
          [44.432508, 39.437569],
          [44.43252, 39.437365],
          [44.432476, 39.437315],
          [44.432243, 39.437385],
          [44.432255, 39.437157],
          [44.432414, 39.436964],
          [44.43241, 39.436769],
          [44.432337, 39.436741],
          [44.432062, 39.436803],
          [44.431975, 39.436933],
          [44.431902, 39.436927],
          [44.431863, 39.436766],
          [44.431669, 39.436558],
          [44.431675, 39.436497],
          [44.431533, 39.436318],
          [44.43154, 39.436252],
          [44.431837, 39.436003],
          [44.431878, 39.435863],
          [44.431529, 39.435714],
          [44.431361, 39.435747],
          [44.431193, 39.435686],
          [44.431258, 39.435417],
          [44.431421, 39.435204],
          [44.431273, 39.435078],
          [44.430685, 39.435006],
          [44.430661, 39.434955],
          [44.430753, 39.434836],
          [44.431075, 39.434664],
          [44.431102, 39.434568],
          [44.431035, 39.434517],
          [44.430636, 39.434565],
          [44.430733, 39.434302],
          [44.430673, 39.434263],
          [44.430431, 39.434265],
          [44.430244, 39.434449],
          [44.429889, 39.434606],
          [44.429721, 39.434332],
          [44.429757, 39.434104],
          [44.4297, 39.434066],
          [44.429513, 39.434076],
          [44.429456, 39.434027],
          [44.429439, 39.433925],
          [44.429304, 39.43376],
          [44.429277, 39.433574],
          [44.429172, 39.433499],
          [44.429136, 39.433421],
          [44.429196, 39.433251],
          [44.429104, 39.432976],
          [44.428967, 39.432959],
          [44.428682, 39.432768],
          [44.428837, 39.432617],
          [44.428792, 39.432493],
          [44.428291, 39.432478],
          [44.428116, 39.43234],
          [44.427928, 39.432316],
          [44.427889, 39.432219],
          [44.428087, 39.432064],
          [44.428135, 39.431967],
          [44.427913, 39.431868],
          [44.42774, 39.431914],
          [44.427596, 39.431825],
          [44.427683, 39.431667],
          [44.427706, 39.431443],
          [44.427579, 39.431254],
          [44.427581, 39.430567],
          [44.427466, 39.430318],
          [44.427251, 39.430117],
          [44.427246, 39.430028],
          [44.427403, 39.42981],
          [44.427382, 39.429749],
          [44.427108, 39.42928],
          [44.42673, 39.428872],
          [44.426792, 39.428345],
          [44.42676, 39.428177],
          [44.426433, 39.427852],
          [44.426422, 39.4277],
          [44.426269, 39.427724],
          [44.426158, 39.427668],
          [44.426147, 39.427543],
          [44.426017, 39.427317],
          [44.425861, 39.427267],
          [44.425831, 39.426998],
          [44.425918, 39.426828],
          [44.425873, 39.426636],
          [44.426091, 39.426189],
          [44.425969, 39.426034],
          [44.426012, 39.4259],
          [44.426118, 39.42581],
          [44.426272, 39.425279],
          [44.426245, 39.425215],
          [44.426119, 39.425213],
          [44.425956, 39.425123],
          [44.426039, 39.42494],
          [44.426, 39.424572],
          [44.425888, 39.424454],
          [44.426147, 39.423934],
          [44.42617, 39.42375],
          [44.426139, 39.423686],
          [44.425733, 39.423676],
          [44.425589, 39.423621],
          [44.425409, 39.423485],
          [44.425364, 39.423392],
          [44.425243, 39.423368],
          [44.425157, 39.423399],
          [44.425203, 39.423028],
          [44.424893, 39.422659],
          [44.424932, 39.422494],
          [44.424786, 39.422039],
          [44.424829, 39.421934],
          [44.425169, 39.421606],
          [44.425066, 39.421386],
          [44.42522, 39.421189],
          [44.425258, 39.421024],
          [44.425168, 39.420601],
          [44.425176, 39.420202],
          [44.42526, 39.420025],
          [44.425241, 39.419956],
          [44.424925, 39.41994],
          [44.424811, 39.419807],
          [44.424904, 39.419659],
          [44.424951, 39.419094],
          [44.425075, 39.418933],
          [44.42505, 39.41887],
          [44.424876, 39.418845],
          [44.424431, 39.418538],
          [44.424259, 39.417631],
          [44.423682, 39.417289],
          [44.423578, 39.417099],
          [44.423119, 39.414476],
          [44.421957, 39.411604],
          [44.416092, 39.411813],
          [44.414492, 39.411794],
          [44.412684, 39.411914],
          [44.411623, 39.412083],
          [44.410429, 39.412113],
          [44.40799, 39.412435],
          [44.398793, 39.412884],
          [44.397209, 39.41256],
          [44.39056, 39.411508],
          [44.389343, 39.412824],
          [44.385581, 39.412993],
          [44.38182, 39.411804],
          [44.379337, 39.411761],
          [44.378461, 39.412015],
          [44.378122, 39.411926],
          [44.377915, 39.411872],
          [44.377213, 39.411688],
          [44.377021, 39.411638],
          [44.376416, 39.411415],
          [44.376235, 39.411361],
          [44.3759, 39.41111],
          [44.37561, 39.410674],
          [44.374419, 39.409545],
          [44.37445, 39.408899],
          [44.374195, 39.408061],
          [44.37429, 39.407372],
          [44.373457, 39.407237],
          [44.373267, 39.40715],
          [44.370615, 39.405942],
          [44.368296, 39.4051],
          [44.366489, 39.40406],
          [44.365064, 39.403413],
          [44.364507, 39.403002],
          [44.364103, 39.402278],
          [44.354693, 39.397768],
          [44.35173, 39.396739],
          [44.348825, 39.396201],
          [44.345741, 39.395813],
          [44.343837, 39.394962],
          [44.341732, 39.39365],
          [44.339365, 39.391931],
          [44.337687, 39.391178],
          [44.33661, 39.389951],
          [44.334704, 39.388535],
          [44.333647, 39.387872],
          [44.330881, 39.388193],
          [44.327257, 39.389741],
          [44.325466, 39.390307],
          [44.322738, 39.390699],
          [44.318743, 39.391619],
          [44.316146, 39.390357],
          [44.315631, 39.390005],
          [44.312945, 39.388171],
          [44.312772, 39.387756],
          [44.311916, 39.386007],
          [44.309942, 39.385324],
          [44.308379, 39.384647],
          [44.306879, 39.384083],
          [44.306251, 39.38326],
          [44.306944, 39.381819],
          [44.306448, 39.379604],
          [44.30391, 39.379887],
          [44.299974, 39.379499],
          [44.29812, 39.378413],
          [44.296814, 39.377176],
          [44.295908, 39.376398],
          [44.2952, 39.375258],
          [44.292646, 39.375934],
          [44.289176, 39.377056],
          [44.287582, 39.377257],
          [44.285911, 39.377255],
          [44.286321, 39.379005],
          [44.285522, 39.380064],
          [44.284877, 39.380593],
          [44.28127, 39.382228],
          [44.280175, 39.383019],
          [44.279749, 39.383169],
          [44.278873, 39.382987],
          [44.277942, 39.382632],
          [44.276045, 39.382735],
          [44.275041, 39.383248],
          [44.275071, 39.384788],
          [44.274474, 39.386167],
          [44.273616, 39.38803],
          [44.271976, 39.39036],
          [44.270715, 39.394306],
          [44.270159, 39.395511],
          [44.267041, 39.397682],
          [44.265198, 39.398614],
          [44.263378, 39.39862],
          [44.261193, 39.398172],
          [44.259613, 39.397903],
          [44.257616, 39.398178],
          [44.25614, 39.398061],
          [44.253067, 39.397935],
          [44.250566, 39.397732],
          [44.248457, 39.397387],
          [44.246389, 39.398114],
          [44.24363, 39.40139],
          [44.242938, 39.404481],
          [44.240989, 39.407614],
          [44.240354, 39.410064],
          [44.239717, 39.411224],
          [44.238632, 39.41205],
          [44.233334, 39.413023],
          [44.233122, 39.415536],
          [44.227896, 39.415989],
          [44.226987, 39.415178],
          [44.226186, 39.415004],
          [44.225571, 39.415406],
          [44.224658, 39.41668],
          [44.223452, 39.417046],
          [44.221653, 39.416949],
          [44.218896, 39.417803],
          [44.215785, 39.417163],
          [44.211992, 39.416593],
          [44.209308, 39.415647],
          [44.207217, 39.415335],
          [44.200157, 39.412791],
          [44.197968, 39.412076],
          [44.191832, 39.410535],
          [44.192774, 39.406881],
          [44.192403, 39.405143],
          [44.190942, 39.404239],
          [44.19081, 39.402935],
          [44.190809, 39.402435],
          [44.18272, 39.402037],
          [44.180294, 39.401599],
          [44.17894, 39.401169],
          [44.177878, 39.401014],
          [44.171756, 39.401377],
          [44.166265, 39.401544],
          [44.165518, 39.401513],
          [44.160868, 39.401978],
          [44.157608, 39.401348],
          [44.155933, 39.400492],
          [44.154625, 39.399545],
          [44.153858, 39.399477],
          [44.149439, 39.399979],
          [44.148824, 39.399727],
          [44.148233, 39.399702],
          [44.145703, 39.399044],
          [44.143017, 39.398448],
          [44.141382, 39.397659],
          [44.140067, 39.396036],
          [44.138914, 39.395833],
          [44.138675, 39.394874],
          [44.137185, 39.3935],
          [44.134922, 39.393262],
          [44.133508, 39.392526],
          [44.133063, 39.392257],
          [44.132268, 39.391668],
          [44.130141, 39.389644],
          [44.129321, 39.389811],
          [44.127075, 39.389618],
          [44.126267, 39.389634],
          [44.124887, 39.38985],
          [44.124639, 39.390647],
          [44.12491, 39.391193],
          [44.127038, 39.393167],
          [44.128764, 39.394652],
          [44.129551, 39.395403],
          [44.130724, 39.396214],
          [44.132398, 39.398027],
          [44.13278, 39.398715],
          [44.132125, 39.400498],
          [44.131878, 39.403149],
          [44.131176, 39.40424],
          [44.130105, 39.405409],
          [44.128524, 39.40523],
          [44.127495, 39.404698],
          [44.12634, 39.404334],
          [44.124388, 39.404068],
          [44.123313, 39.403823],
          [44.122059, 39.403384],
          [44.120301, 39.402593],
          [44.11895, 39.402125],
          [44.117428, 39.401945],
          [44.113601, 39.402525],
          [44.112553, 39.402791],
          [44.111407, 39.40347],
          [44.110082, 39.404627],
          [44.108584, 39.405845],
          [44.107504, 39.406503],
          [44.10673, 39.406812],
          [44.104631, 39.407074],
          [44.103019, 39.40733],
          [44.101655, 39.407958],
          [44.10052, 39.408767],
          [44.099622, 39.409829],
          [44.098784, 39.410966],
          [44.098115, 39.411755],
          [44.096992, 39.412173],
          [44.093599, 39.411905],
          [44.091994, 39.411454],
          [44.090539, 39.411302],
          [44.089699, 39.411145],
          [44.088699, 39.410748],
          [44.088186, 39.410347],
          [44.087688, 39.409705],
          [44.086901, 39.409201],
          [44.085915, 39.408443],
          [44.084893, 39.407911],
          [44.084395, 39.407797],
          [44.083851, 39.407847],
          [44.083057, 39.408171],
          [44.081951, 39.408423],
          [44.075804, 39.410062],
          [44.07391, 39.410931],
          [44.072349, 39.411939],
          [44.071596, 39.412338],
          [44.071089, 39.412343],
          [44.070406, 39.412259],
          [44.069836, 39.411918],
          [44.069288, 39.411758],
          [44.06792, 39.411485],
          [44.066843, 39.411149],
          [44.064488, 39.408794],
          [44.062425, 39.407104],
          [44.061056, 39.406182],
          [44.059583, 39.405398],
          [44.059055, 39.405268],
          [44.058198, 39.405291],
          [44.055438, 39.406096],
          [44.053821, 39.406036],
          [44.052348, 39.405268],
          [44.051316, 39.404059],
          [44.051144, 39.403052],
          [44.051416, 39.402373],
          [44.051726, 39.401016],
          [44.05178, 39.399525],
          [44.051487, 39.398249],
          [44.052304, 39.396947],
          [44.052852, 39.395943],
          [44.052997, 39.395249],
          [44.05373, 39.394186],
          [44.054533, 39.393245],
          [44.054966, 39.392323],
          [44.056027, 39.390462],
          [44.057068, 39.388379],
          [44.05796, 39.386148],
          [44.057868, 39.386111],
          [44.057056, 39.386566],
          [44.055725, 39.38767],
          [44.054752, 39.388807],
          [44.054577, 39.388912],
          [44.054401, 39.388931],
          [44.053842, 39.388806],
          [44.052965, 39.388287],
          [44.051764, 39.38741],
          [44.049393, 39.386403],
          [44.046114, 39.385526],
          [44.044133, 39.384682],
          [44.042704, 39.384163],
          [44.04121, 39.382279],
          [44.040626, 39.38176],
          [44.040009, 39.380201],
          [44.039619, 39.379876],
          [44.038255, 39.379487],
          [44.037768, 39.379194],
          [44.036339, 39.377896],
          [44.034846, 39.377766],
          [44.034294, 39.377506],
          [44.034066, 39.376954],
          [44.033579, 39.3752],
          [44.032735, 39.372733],
          [44.031891, 39.371726],
        ],
      ],
    },
  },
  EastAzarbaijan: {
    properties: {
      value: "EastAzarbaijan",
      name: "استان آذربایجان شرقی, ایران",
      id: 18,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [45.093454, 38.321972],
          [45.093523, 38.321146],
          [45.093731, 38.320311],
          [45.095121, 38.313924],
          [45.094844, 38.313368],
          [45.095121, 38.312254],
          [45.108787, 38.294308],
          [45.109067, 38.294029],
          [45.109901, 38.289026],
          [45.110049, 38.288093],
          [45.108734, 38.2686],
          [45.108734, 38.268043],
          [45.109014, 38.267765],
          [45.109014, 38.264989],
          [45.121536, 38.252471],
          [45.122909, 38.233057],
          [45.129089, 38.228202],
          [45.133569, 38.217821],
          [45.133586, 38.2178],
          [45.161161, 38.190148],
          [45.161161, 38.190148],
          [45.190514, 38.156132],
          [45.248222, 38.085933],
          [45.272598, 38.060257],
          [45.312635, 38.003712],
          [45.323475, 37.984632],
          [45.335491, 37.972763],
          [45.336044, 37.97137],
          [45.335768, 37.971092],
          [45.336044, 37.970544],
          [45.335768, 37.969708],
          [45.35836, 37.934222],
          [45.358641, 37.932559],
          [45.358641, 37.931724],
          [45.358641, 37.931724],
          [45.358641, 37.929773],
          [45.370102, 37.910076],
          [45.374904, 37.885066],
          [45.378681, 37.87449],
          [45.382461, 37.860489],
          [45.384434, 37.842611],
          [45.385267, 37.842333],
          [45.385894, 37.820091],
          [45.380942, 37.801427],
          [45.378341, 37.794593],
          [45.371647, 37.79188],
          [45.368904, 37.786558],
          [45.379252, 37.781453],
          [45.377476, 37.768285],
          [45.393104, 37.731082],
          [45.419922, 37.702268],
          [45.499191, 37.637073],
          [45.52185, 37.610424],
          [45.522537, 37.583222],
          [45.470285, 37.536297],
          [45.472267, 37.529275],
          [45.475158, 37.498832],
          [45.469843, 37.465205],
          [45.468718, 37.463814],
          [45.468128, 37.463085],
          [45.47102, 37.448492],
          [45.471038, 37.421981],
          [45.485939, 37.385714],
          [45.498361, 37.360486],
          [45.516357, 37.348327],
          [45.544244, 37.335721],
          [45.545712, 37.335708],
          [45.547613, 37.334865],
          [45.551817, 37.333207],
          [45.55517, 37.331976],
          [45.556482, 37.329002],
          [45.558281, 37.327187],
          [45.566448, 37.322401],
          [45.567625, 37.319959],
          [45.568329, 37.319786],
          [45.592229, 37.310886],
          [45.594445, 37.309922],
          [45.642122, 37.303642],
          [45.699762, 37.301974],
          [45.716407, 37.251005],
          [45.738144, 37.224861],
          [45.767157, 37.202224],
          [45.850522, 37.17072],
          [45.850799, 37.170441],
          [45.861635, 37.170441],
          [45.862189, 37.17072],
          [45.863302, 37.17072],
          [45.872192, 37.169638],
          [45.872471, 37.169917],
          [45.873858, 37.170335],
          [45.874414, 37.170475],
          [45.879249, 37.170086],
          [45.891275, 37.16917],
          [45.897665, 37.16917],
          [45.898498, 37.169449],
          [45.900998, 37.169449],
          [45.901831, 37.169728],
          [45.902945, 37.169728],
          [45.903221, 37.170007],
          [45.907664, 37.170556],
          [45.912664, 37.170556],
          [45.913221, 37.170277],
          [45.922031, 37.175479],
          [45.922584, 37.175749],
          [45.923418, 37.175749],
          [45.923974, 37.176028],
          [45.924808, 37.176306],
          [45.927864, 37.176306],
          [45.928141, 37.176028],
          [45.929808, 37.176028],
          [45.930085, 37.175749],
          [45.934251, 37.1752],
          [45.938975, 37.173256],
          [45.940364, 37.173256],
          [45.941197, 37.172977],
          [45.942588, 37.172977],
          [45.943421, 37.172698],
          [45.947864, 37.172698],
          [45.949808, 37.17214],
          [45.951197, 37.17214],
          [45.958698, 37.170476],
          [45.959254, 37.169918],
          [45.965088, 37.167416],
          [45.968144, 37.167416],
          [45.982114, 37.162223],
          [45.982671, 37.162223],
          [45.987947, 37.159173],
          [45.998504, 37.151946],
          [45.999337, 37.151946],
          [45.999665, 37.151667],
          [46.00128, 37.151667],
          [46.001837, 37.151388],
          [46.00267, 37.151389],
          [46.002947, 37.15111],
          [46.003504, 37.15111],
          [46.00406, 37.150561],
          [46.004613, 37.150561],
          [46.009337, 37.147222],
          [46.01017, 37.144999],
          [46.01017, 37.143892],
          [46.012117, 37.140283],
          [46.01406, 37.13834],
          [46.014337, 37.138061],
          [46.035688, 37.132755],
          [46.039301, 37.12831],
          [46.043191, 37.120255],
          [46.043783, 37.111116],
          [46.043783, 37.110558],
          [46.04434, 37.110279],
          [46.044893, 37.109442],
          [46.045173, 37.108614],
          [46.048783, 37.10389],
          [46.04934, 37.10361],
          [46.04934, 37.103053],
          [46.064616, 37.083613],
          [46.06545, 37.083613],
          [46.070453, 37.08139],
          [46.071286, 37.08139],
          [46.093875, 37.05895],
          [46.094293, 37.058252],
          [46.094846, 37.057703],
          [46.095126, 37.057424],
          [46.106426, 37.053476],
          [46.106843, 37.053057],
          [46.1074, 37.052778],
          [46.108233, 37.051941],
          [46.157684, 37.032159],
          [46.161845, 37.007784],
          [46.165179, 37.004444],
          [46.169622, 36.999449],
          [46.175456, 36.995551],
          [46.176012, 36.994444],
          [46.177955, 36.983616],
          [46.177955, 36.981392],
          [46.178512, 36.97889],
          [46.177679, 36.96528],
          [46.176569, 36.957502],
          [46.177123, 36.956107],
          [46.177402, 36.95527],
          [46.184069, 36.949725],
          [46.195179, 36.945557],
          [46.195736, 36.945557],
          [46.196521, 36.945294],
          [46.197402, 36.944999],
          [46.197402, 36.94472],
          [46.198512, 36.94472],
          [46.198793, 36.944441],
          [46.200459, 36.944441],
          [46.201569, 36.944999],
          [46.202959, 36.944999],
          [46.204068, 36.945557],
          [46.209069, 36.948609],
          [46.219349, 36.956944],
          [46.243515, 36.970276],
          [46.244629, 36.970276],
          [46.245182, 36.970555],
          [46.245738, 36.970276],
          [46.245738, 36.970555],
          [46.246295, 36.970555],
          [46.246572, 36.970276],
          [46.247405, 36.970276],
          [46.247962, 36.969718],
          [46.250738, 36.968889],
          [46.250738, 36.968611],
          [46.256015, 36.968611],
          [46.266852, 36.969439],
          [46.268238, 36.969718],
          [46.275871, 36.962859],
          [46.276428, 36.96258],
          [46.276982, 36.962859],
          [46.278648, 36.96258],
          [46.284204, 36.963967],
          [46.286981, 36.965362],
          [46.288371, 36.966469],
          [46.302031, 36.990527],
          [46.308141, 36.997187],
          [46.315154, 37.010406],
          [46.315431, 37.011243],
          [46.316977, 37.017345],
          [46.317463, 37.017664],
          [46.354047, 37.035128],
          [46.3546, 37.035407],
          [46.377134, 37.041114],
          [46.37991, 37.043616],
          [46.429079, 37.073615],
          [46.440469, 37.098338],
          [46.440746, 37.098617],
          [46.447692, 37.101389],
          [46.448802, 37.101389],
          [46.449359, 37.101668],
          [46.452692, 37.101668],
          [46.463802, 37.098617],
          [46.465472, 37.098617],
          [46.466306, 37.098338],
          [46.467692, 37.098338],
          [46.468525, 37.098059],
          [46.470192, 37.098059],
          [46.471025, 37.09778],
          [46.475472, 37.09778],
          [46.476025, 37.098059],
          [46.476858, 37.098059],
          [46.478249, 37.098617],
          [46.479358, 37.098617],
          [46.481582, 37.099445],
          [46.482415, 37.099445],
          [46.482972, 37.099724],
          [46.483805, 37.099724],
          [46.484082, 37.100003],
          [46.485195, 37.099724],
          [46.487416, 37.099724],
          [46.491029, 37.098617],
          [46.492138, 37.098059],
          [46.495195, 37.09445],
          [46.495749, 37.093613],
          [46.496028, 37.091948],
          [46.494082, 37.07528],
          [46.494362, 37.075001],
          [46.494638, 37.073615],
          [46.495195, 37.069727],
          [46.495195, 37.065839],
          [46.494915, 37.064723],
          [46.489915, 37.059727],
          [46.487972, 37.058611],
          [46.465409, 37.040313],
          [46.465129, 37.037811],
          [46.464852, 37.037532],
          [46.465129, 37.036983],
          [46.467076, 37.033923],
          [46.467076, 37.032536],
          [46.460686, 37.02503],
          [46.459296, 37.022257],
          [46.459019, 37.021429],
          [46.459019, 37.02115],
          [46.460129, 37.019476],
          [46.462909, 37.018369],
          [46.464296, 37.018369],
          [46.464576, 37.01809],
          [46.465686, 37.01809],
          [46.466242, 37.017811],
          [46.467629, 37.017811],
          [46.468462, 37.017532],
          [46.470966, 37.017532],
          [46.475132, 37.016983],
          [46.475685, 37.016704],
          [46.476798, 37.016704],
          [46.477632, 37.016425],
          [46.480966, 37.016425],
          [46.481242, 37.016146],
          [46.489019, 37.016146],
          [46.489855, 37.015866],
          [46.504097, 37.017631],
          [46.522972, 37.027395],
          [46.523248, 37.027674],
          [46.524358, 37.027674],
          [46.524915, 37.027953],
          [46.525748, 37.027953],
          [46.526305, 37.028232],
          [46.527415, 37.028232],
          [46.550751, 37.027227],
          [46.557974, 37.02945],
          [46.559088, 37.02945],
          [46.559365, 37.029729],
          [46.561308, 37.030278],
          [46.562698, 37.030278],
          [46.566032, 37.030836],
          [46.566588, 37.030836],
          [46.567698, 37.031115],
          [46.569088, 37.031115],
          [46.572975, 37.031673],
          [46.576031, 37.031673],
          [46.576865, 37.031952],
          [46.580198, 37.032222],
          [46.587145, 37.032222],
          [46.587977, 37.032501],
          [46.590754, 37.032501],
          [46.591311, 37.03278],
          [46.594088, 37.03278],
          [46.594921, 37.033059],
          [46.611034, 37.035282],
          [46.61659, 37.03636],
          [46.638257, 37.040557],
          [46.640757, 37.041115],
          [46.642147, 37.041115],
          [46.659243, 37.041821],
          [46.660353, 37.041821],
          [46.688776, 37.036043],
          [46.690443, 37.038266],
          [46.685021, 37.050935],
          [46.683388, 37.063553],
          [46.683941, 37.063553],
          [46.684774, 37.064112],
          [46.685055, 37.06439],
          [46.686165, 37.06466],
          [46.690055, 37.066883],
          [46.690888, 37.066883],
          [46.702983, 37.071671],
          [46.704093, 37.071671],
          [46.704649, 37.07195],
          [46.705483, 37.07195],
          [46.706317, 37.072229],
          [46.70937, 37.072499],
          [46.726316, 37.070276],
          [46.730483, 37.06889],
          [46.732983, 37.067783],
          [46.760206, 37.057784],
          [46.761596, 37.057784],
          [46.762152, 37.057505],
          [46.763819, 37.057505],
          [46.764372, 37.057226],
          [46.765205, 37.057226],
          [46.765486, 37.056947],
          [46.767152, 37.056947],
          [46.767429, 37.056668],
          [46.769095, 37.056668],
          [46.769652, 37.056389],
          [46.770762, 37.056389],
          [46.771319, 37.05611],
          [46.774929, 37.055561],
          [46.777985, 37.055561],
          [46.778262, 37.055282],
          [46.780209, 37.055282],
          [46.780762, 37.055003],
          [46.782709, 37.055003],
          [46.783262, 37.054724],
          [46.784096, 37.054724],
          [46.784932, 37.054445],
          [46.786599, 37.054445],
          [46.789375, 37.054166],
          [46.801901, 37.049297],
          [46.802432, 37.048892],
          [46.802709, 37.048613],
          [46.82579, 37.036818],
          [46.853599, 37.002827],
          [46.842178, 36.975562],
          [46.842178, 36.973617],
          [46.842178, 36.971124],
          [46.838679, 36.959151],
          [46.838955, 36.958322],
          [46.838955, 36.954434],
          [46.843678, 36.9386],
          [46.849235, 36.928599],
          [46.862292, 36.919435],
          [46.864235, 36.918598],
          [46.891738, 36.91082],
          [46.892291, 36.910262],
          [46.902752, 36.908839],
          [46.907474, 36.899117],
          [46.907751, 36.898559],
          [46.907474, 36.895786],
          [46.907474, 36.892726],
          [46.902098, 36.869902],
          [46.915788, 36.855893],
          [46.915234, 36.850339],
          [46.914954, 36.847279],
          [46.913564, 36.838953],
          [46.900291, 36.829348],
          [46.894111, 36.815332],
          [46.875212, 36.800832],
          [46.872159, 36.793053],
          [46.872435, 36.791108],
          [46.872712, 36.79041],
          [46.872712, 36.790001],
          [46.872992, 36.789442],
          [46.872992, 36.789163],
          [46.872992, 36.788884],
          [46.873269, 36.78666],
          [46.873546, 36.78639],
          [46.877993, 36.779997],
          [46.889742, 36.780787],
          [46.890575, 36.780787],
          [46.891409, 36.780508],
          [46.892522, 36.780508],
          [46.893632, 36.780228],
          [46.896132, 36.780228],
          [46.897242, 36.779949],
          [46.903355, 36.779949],
          [46.914745, 36.777176],
          [46.916965, 36.776339],
          [46.917522, 36.775781],
          [46.918632, 36.775502],
          [46.921965, 36.773287],
          [46.922995, 36.762773],
          [46.924662, 36.760558],
          [46.927995, 36.756659],
          [46.930215, 36.755831],
          [46.930495, 36.755552],
          [46.931049, 36.755273],
          [46.932438, 36.754714],
          [46.932995, 36.754714],
          [46.975774, 36.749717],
          [46.985498, 36.754994],
          [46.986607, 36.754994],
          [46.987165, 36.755273],
          [46.988831, 36.754994],
          [46.990218, 36.754435],
          [46.993274, 36.74861],
          [46.993831, 36.748331],
          [46.994108, 36.747772],
          [46.994665, 36.747502],
          [46.994941, 36.746944],
          [46.997998, 36.745278],
          [47.002998, 36.746665],
          [47.003831, 36.747223],
          [47.011607, 36.754435],
          [47.017721, 36.764997],
          [47.017997, 36.766105],
          [47.018554, 36.768608],
          [47.020497, 36.789164],
          [47.021887, 36.790829],
          [47.036333, 36.800832],
          [47.036887, 36.801382],
          [47.040777, 36.803326],
          [47.048553, 36.807216],
          [47.049387, 36.807216],
          [47.059943, 36.810835],
          [47.069667, 36.815274],
          [47.071057, 36.81639],
          [47.072167, 36.816669],
          [47.076613, 36.819443],
          [47.096336, 36.831111],
          [47.097446, 36.831381],
          [47.099389, 36.831381],
          [47.102446, 36.832497],
          [47.104946, 36.832497],
          [47.106893, 36.831939],
          [47.111059, 36.830274],
          [47.11828, 36.826942],
          [47.119946, 36.826942],
          [47.120502, 36.827501],
          [47.130782, 36.833056],
          [47.133282, 36.833605],
          [47.137449, 36.833056],
          [47.140503, 36.832218],
          [47.144949, 36.827501],
          [47.153282, 36.811385],
          [47.161892, 36.803885],
          [47.163839, 36.80194],
          [47.164395, 36.801661],
          [47.171896, 36.794998],
          [47.172449, 36.793882],
          [47.174115, 36.791667],
          [47.178562, 36.791937],
          [47.178839, 36.792216],
          [47.179395, 36.792216],
          [47.181062, 36.792495],
          [47.182172, 36.792495],
          [47.190785, 36.795557],
          [47.198285, 36.799437],
          [47.209118, 36.811943],
          [47.223285, 36.825277],
          [47.226341, 36.827501],
          [47.240508, 36.838332],
          [47.241064, 36.839169],
          [47.243288, 36.84611],
          [47.256897, 36.865827],
          [47.257731, 36.866385],
          [47.269398, 36.87139],
          [47.291068, 36.878062],
          [47.295511, 36.883337],
          [47.299957, 36.894716],
          [47.300511, 36.895273],
          [47.302733, 36.897228],
          [47.3169, 36.904448],
          [47.318847, 36.905555],
          [47.37135, 36.936666],
          [47.372736, 36.937225],
          [47.395516, 36.946668],
          [47.401073, 36.947496],
          [47.407182, 36.949449],
          [47.408016, 36.949449],
          [47.408572, 36.949728],
          [47.409406, 36.949728],
          [47.411349, 36.950556],
          [47.412182, 36.950556],
          [47.428185, 36.957342],
          [47.428572, 36.957506],
          [47.429129, 36.957776],
          [47.437462, 36.96306],
          [47.438295, 36.963339],
          [47.450518, 36.976949],
          [47.450795, 36.977228],
          [47.458575, 36.98334],
          [47.486908, 36.996671],
          [47.487465, 36.996671],
          [47.492464, 37.000001],
          [47.493575, 37.001667],
          [47.493575, 37.001946],
          [47.494131, 37.002504],
          [47.509964, 37.017229],
          [47.512744, 37.021397],
          [47.515244, 37.036671],
          [47.514964, 37.039731],
          [47.514964, 37.050838],
          [47.515244, 37.055563],
          [47.515521, 37.056949],
          [47.517464, 37.061674],
          [47.524134, 37.067227],
          [47.529411, 37.068334],
          [47.53691, 37.062232],
          [47.537187, 37.061674],
          [47.538854, 37.060837],
          [47.541357, 37.060279],
          [47.542467, 37.060279],
          [47.5433, 37.060558],
          [47.547744, 37.060558],
          [47.5483, 37.060837],
          [47.549967, 37.060837],
          [47.556634, 37.057507],
          [47.557743, 37.057507],
          [47.558577, 37.057228],
          [47.559967, 37.057228],
          [47.563857, 37.058056],
          [47.5658, 37.058893],
          [47.56719, 37.058893],
          [47.567747, 37.059172],
          [47.56969, 37.059172],
          [47.575523, 37.055563],
          [47.58108, 37.053898],
          [47.581637, 37.053619],
          [47.58219, 37.053619],
          [47.58219, 37.05334],
          [47.582747, 37.05334],
          [47.584414, 37.052782],
          [47.59219, 37.047778],
          [47.59358, 37.045285],
          [47.59358, 37.044727],
          [47.593857, 37.044448],
          [47.594136, 37.043062],
          [47.594413, 37.041954],
          [47.59469, 37.04028],
          [47.596356, 37.037508],
          [47.59886, 37.037508],
          [47.604693, 37.041396],
          [47.610247, 37.048615],
          [47.611636, 37.050001],
          [47.61747, 37.055005],
          [47.624693, 37.056112],
          [47.62997, 37.058614],
          [47.631359, 37.064177],
          [47.630526, 37.066391],
          [47.63025, 37.067228],
          [47.62997, 37.070279],
          [47.63025, 37.073618],
          [47.632193, 37.076669],
          [47.633026, 37.078064],
          [47.633026, 37.078613],
          [47.633583, 37.078892],
          [47.633859, 37.079171],
          [47.637473, 37.081673],
          [47.648859, 37.087505],
          [47.649139, 37.087784],
          [47.658862, 37.098341],
          [47.663029, 37.099448],
          [47.663306, 37.099169],
          [47.666362, 37.099169],
          [47.668305, 37.098899],
          [47.678862, 37.090286],
          [47.679139, 37.089449],
          [47.684419, 37.087226],
          [47.68477, 37.087188],
          [47.685252, 37.086947],
          [47.687472, 37.086677],
          [47.687472, 37.086398],
          [47.693309, 37.086398],
          [47.693862, 37.086119],
          [47.696085, 37.086119],
          [47.696642, 37.086398],
          [47.697195, 37.086398],
          [47.701919, 37.087226],
          [47.703309, 37.087226],
          [47.703862, 37.087505],
          [47.704975, 37.087505],
          [47.705252, 37.087784],
          [47.706085, 37.087784],
          [47.717752, 37.093616],
          [47.719975, 37.093616],
          [47.721642, 37.093058],
          [47.726641, 37.087505],
          [47.726641, 37.086947],
          [47.729418, 37.084175],
          [47.737755, 37.080287],
          [47.740255, 37.07945],
          [47.742198, 37.078613],
          [47.759145, 37.076399],
          [47.759698, 37.07612],
          [47.764421, 37.07612],
          [47.764978, 37.076399],
          [47.766088, 37.076399],
          [47.768311, 37.077227],
          [47.772201, 37.080287],
          [47.773034, 37.081394],
          [47.775534, 37.090844],
          [47.775811, 37.091951],
          [47.777754, 37.110005],
          [47.778587, 37.119724],
          [47.778034, 37.123621],
          [47.778311, 37.13056],
          [47.778587, 37.131676],
          [47.778868, 37.132225],
          [47.792758, 37.143618],
          [47.793311, 37.143897],
          [47.793591, 37.144455],
          [47.805534, 37.150565],
          [47.8072, 37.151393],
          [47.84248, 37.169175],
          [47.843313, 37.169175],
          [47.843313, 37.169454],
          [47.844147, 37.169454],
          [47.846093, 37.170282],
          [47.847203, 37.170282],
          [47.84748, 37.170561],
          [47.84776, 37.17084],
          [47.850814, 37.171956],
          [47.85248, 37.171956],
          [47.85387, 37.172514],
          [47.85498, 37.172514],
          [47.856927, 37.173063],
          [47.85887, 37.173063],
          [47.859146, 37.173342],
          [47.860537, 37.173342],
          [47.860813, 37.173621],
          [47.86415, 37.173621],
          [47.87137, 37.174449],
          [47.871926, 37.174728],
          [47.876926, 37.174728],
          [47.87887, 37.174728],
          [47.88804, 37.174728],
          [47.889149, 37.174449],
          [47.892206, 37.174449],
          [47.894983, 37.174179],
          [47.896093, 37.1739],
          [47.898039, 37.1739],
          [47.900539, 37.173342],
          [47.902206, 37.173342],
          [47.904429, 37.172784],
          [47.905816, 37.172784],
          [47.927206, 37.16779],
          [47.927486, 37.167511],
          [47.928596, 37.167511],
          [47.928873, 37.167232],
          [47.929986, 37.167232],
          [47.930262, 37.166953],
          [47.932206, 37.166953],
          [47.932763, 37.166674],
          [47.938042, 37.166674],
          [47.938596, 37.166953],
          [47.946929, 37.165567],
          [47.947209, 37.165288],
          [47.948319, 37.165288],
          [47.972485, 37.155281],
          [47.975542, 37.153346],
          [47.983322, 37.147785],
          [47.987765, 37.138345],
          [47.988042, 37.138066],
          [47.988322, 37.13695],
          [47.991099, 37.130011],
          [47.999333, 37.112217],
          [48.022768, 37.131676],
          [48.033601, 37.145283],
          [48.041101, 37.14945],
          [48.044158, 37.150566],
          [48.044991, 37.150845],
          [48.047211, 37.151115],
          [48.049435, 37.151673],
          [48.050825, 37.151673],
          [48.051934, 37.151952],
          [48.053048, 37.151952],
          [48.053601, 37.152231],
          [48.055548, 37.152231],
          [48.056101, 37.15251],
          [48.064434, 37.15251],
          [48.081104, 37.150845],
          [48.082491, 37.151115],
          [48.083604, 37.151952],
          [48.089438, 37.153617],
          [48.089994, 37.153896],
          [48.093327, 37.153896],
          [48.094161, 37.154175],
          [48.094714, 37.153896],
          [48.095547, 37.153896],
          [48.095547, 37.154175],
          [48.096661, 37.154175],
          [48.099714, 37.15584],
          [48.105547, 37.164731],
          [48.105827, 37.16501],
          [48.109437, 37.171678],
          [48.116384, 37.187237],
          [48.116937, 37.188343],
          [48.131384, 37.204451],
          [48.13194, 37.205567],
          [48.140273, 37.213341],
          [48.150273, 37.221125],
          [48.15944, 37.223617],
          [48.160273, 37.223896],
          [48.161383, 37.223896],
          [48.16444, 37.224733],
          [48.16583, 37.225012],
          [48.173053, 37.226119],
          [48.173606, 37.226398],
          [48.176386, 37.226398],
          [48.177496, 37.226677],
          [48.184996, 37.226677],
          [48.18583, 37.226956],
          [48.186386, 37.226677],
          [48.187219, 37.226956],
          [48.191386, 37.226956],
          [48.201386, 37.228621],
          [48.202776, 37.22862],
          [48.204442, 37.229178],
          [48.205553, 37.229178],
          [48.206386, 37.229457],
          [48.207499, 37.229457],
          [48.213052, 37.231122],
          [48.219719, 37.232787],
          [48.225276, 37.235009],
          [48.233609, 37.237232],
          [48.234999, 37.237232],
          [48.235832, 37.237511],
          [48.236942, 37.237511],
          [48.237776, 37.23779],
          [48.239999, 37.23779],
          [48.240555, 37.238069],
          [48.241666, 37.238068],
          [48.246389, 37.238069],
          [48.247222, 37.23779],
          [48.248889, 37.23779],
          [48.249722, 37.237511],
          [48.256112, 37.237511],
          [48.256945, 37.23779],
          [48.257499, 37.237511],
          [48.258055, 37.23779],
          [48.259722, 37.23779],
          [48.260555, 37.238069],
          [48.260835, 37.23779],
          [48.261669, 37.238069],
          [48.264445, 37.238069],
          [48.265002, 37.23779],
          [48.267222, 37.23779],
          [48.268055, 37.237511],
          [48.270002, 37.237511],
          [48.270835, 37.237232],
          [48.273055, 37.237232],
          [48.273335, 37.237511],
          [48.275835, 37.237511],
          [48.294725, 37.245294],
          [48.308335, 37.245294],
          [48.317225, 37.245564],
          [48.318058, 37.245294],
          [48.320558, 37.245006],
          [48.331391, 37.242793],
          [48.331391, 37.242514],
          [48.332505, 37.242514],
          [48.333338, 37.242514],
          [48.335004, 37.243621],
          [48.335004, 37.243899],
          [48.335561, 37.243899],
          [48.338338, 37.248344],
          [48.339171, 37.256118],
          [48.339321, 37.256875],
          [48.339448, 37.257513],
          [48.338061, 37.261958],
          [48.338061, 37.262237],
          [48.337781, 37.262795],
          [48.335281, 37.266403],
          [48.331391, 37.269174],
          [48.330004, 37.269453],
          [48.326391, 37.269453],
          [48.325281, 37.268904],
          [48.321391, 37.270848],
          [48.320281, 37.27307],
          [48.320005, 37.273907],
          [48.319448, 37.276399],
          [48.318614, 37.276678],
          [48.318338, 37.276957],
          [48.317781, 37.276957],
          [48.317505, 37.277236],
          [48.316671, 37.277236],
          [48.316115, 37.277236],
          [48.314724, 37.277236],
          [48.314724, 37.276957],
          [48.313335, 37.276957],
          [48.311391, 37.27612],
          [48.310281, 37.276399],
          [48.308058, 37.276399],
          [48.295278, 37.286404],
          [48.294725, 37.287511],
          [48.295558, 37.293899],
          [48.292501, 37.297237],
          [48.290835, 37.297237],
          [48.286945, 37.297237],
          [48.283058, 37.29918],
          [48.283058, 37.303067],
          [48.281112, 37.30501],
          [48.280278, 37.30501],
          [48.279725, 37.305289],
          [48.276388, 37.305289],
          [48.271112, 37.307233],
          [48.270278, 37.308069],
          [48.270835, 37.309455],
          [48.273055, 37.311677],
          [48.272778, 37.311956],
          [48.271388, 37.313342],
          [48.260002, 37.317516],
          [48.258335, 37.319738],
          [48.257779, 37.320845],
          [48.258055, 37.321961],
          [48.258612, 37.32224],
          [48.258612, 37.322519],
          [48.261388, 37.322788],
          [48.261945, 37.323067],
          [48.261945, 37.325847],
          [48.261668, 37.326405],
          [48.261945, 37.327233],
          [48.260002, 37.329455],
          [48.258335, 37.329734],
          [48.257778, 37.329455],
          [48.256945, 37.329455],
          [48.254722, 37.326405],
          [48.253888, 37.325568],
          [48.253612, 37.325011],
          [48.249722, 37.322788],
          [48.247222, 37.323904],
          [48.244165, 37.326963],
          [48.244445, 37.329455],
          [48.243332, 37.331956],
          [48.243055, 37.332235],
          [48.243332, 37.333072],
          [48.243888, 37.333072],
          [48.243888, 37.333351],
          [48.245832, 37.333351],
          [48.248332, 37.334457],
          [48.248612, 37.335015],
          [48.248888, 37.335294],
          [48.248888, 37.336122],
          [48.248612, 37.336958],
          [48.248332, 37.337237],
          [48.245555, 37.341681],
          [48.231388, 37.351956],
          [48.227775, 37.35918],
          [48.226942, 37.362517],
          [48.226665, 37.363624],
          [48.226665, 37.364182],
          [48.225555, 37.366125],
          [48.216942, 37.370569],
          [48.216385, 37.371127],
          [48.196662, 37.378351],
          [48.191385, 37.381121],
          [48.190909, 37.381441],
          [48.169719, 37.399185],
          [48.168329, 37.400013],
          [48.167496, 37.401128],
          [48.165829, 37.402235],
          [48.164439, 37.40335],
          [48.163606, 37.404178],
          [48.161939, 37.405014],
          [48.149439, 37.416124],
          [48.146382, 37.421683],
          [48.145549, 37.425569],
          [48.145829, 37.43335],
          [48.152772, 37.458906],
          [48.154163, 37.468629],
          [48.156105, 37.475015],
          [48.156105, 37.475294],
          [48.156106, 37.476131],
          [48.155829, 37.47641],
          [48.155549, 37.477795],
          [48.155272, 37.478352],
          [48.141939, 37.484181],
          [48.139159, 37.483911],
          [48.138606, 37.483632],
          [48.137773, 37.483632],
          [48.137216, 37.483353],
          [48.135549, 37.483353],
          [48.135273, 37.483632],
          [48.134439, 37.483632],
          [48.127493, 37.488633],
          [48.127216, 37.489182],
          [48.125549, 37.490018],
          [48.121103, 37.490018],
          [48.120269, 37.48974],
          [48.119159, 37.48974],
          [48.118603, 37.489461],
          [48.116383, 37.489461],
          [48.111936, 37.491961],
          [48.111383, 37.49224],
          [48.111103, 37.492798],
          [48.110826, 37.494183],
          [48.110624, 37.494376],
          [48.104713, 37.50002],
          [48.102769, 37.504185],
          [48.100269, 37.505849],
          [48.099713, 37.505849],
          [48.099436, 37.506127],
          [48.097769, 37.506127],
          [48.097493, 37.506406],
          [48.09666, 37.506406],
          [48.090826, 37.509743],
          [48.088326, 37.510292],
          [48.087213, 37.510571],
          [48.085823, 37.510571],
          [48.085546, 37.510292],
          [48.082213, 37.510292],
          [48.078603, 37.511686],
          [48.078046, 37.511686],
          [48.077489, 37.512243],
          [48.07638, 37.512522],
          [48.075546, 37.512243],
          [48.072213, 37.512243],
          [48.071656, 37.512522],
          [48.070823, 37.512522],
          [48.07027, 37.512243],
          [48.06888, 37.512243],
          [48.0686, 37.511965],
          [48.06749, 37.511965],
          [48.067213, 37.511686],
          [48.06638, 37.511686],
          [48.065823, 37.511128],
          [48.064713, 37.511128],
          [48.060823, 37.514735],
          [48.060545, 37.515571],
          [48.060266, 37.516129],
          [48.060267, 37.516408],
          [48.05999, 37.516965],
          [48.052767, 37.53141],
          [48.053047, 37.533353],
          [48.058046, 37.54446],
          [48.067213, 37.553912],
          [48.067766, 37.55474],
          [48.074156, 37.560019],
          [48.075269, 37.560577],
          [48.076936, 37.562519],
          [48.077213, 37.563077],
          [48.07888, 37.565019],
          [48.079713, 37.570299],
          [48.079156, 37.57252],
          [48.079156, 37.578357],
          [48.074713, 37.5903],
          [48.071656, 37.593078],
          [48.067766, 37.595021],
          [48.066933, 37.5953],
          [48.05638, 37.599185],
          [48.055266, 37.599185],
          [48.049433, 37.600848],
          [48.049156, 37.601127],
          [48.048043, 37.601127],
          [48.0436, 37.602521],
          [48.042767, 37.602521],
          [48.024153, 37.610857],
          [48.0236, 37.611136],
          [48.022766, 37.611963],
          [48.021653, 37.612242],
          [48.019153, 37.616685],
          [48.018877, 37.618079],
          [48.019987, 37.625857],
          [48.022486, 37.641405],
          [48.025543, 37.654462],
          [48.027766, 37.661134],
          [48.028043, 37.661413],
          [48.028876, 37.663077],
          [48.03082, 37.665855],
          [48.031067, 37.666227],
          [48.053879, 37.692525],
          [48.063046, 37.706408],
          [48.063879, 37.71058],
          [48.063322, 37.714743],
          [48.063322, 37.719751],
          [48.063599, 37.720578],
          [48.063599, 37.722799],
          [48.064156, 37.724741],
          [48.064432, 37.72502],
          [48.065266, 37.726692],
          [48.066656, 37.72947],
          [48.070822, 37.732249],
          [48.075269, 37.733912],
          [48.087489, 37.735854],
          [48.088045, 37.736133],
          [48.090269, 37.736133],
          [48.091379, 37.736412],
          [48.095269, 37.736412],
          [48.096379, 37.73669],
          [48.098326, 37.73669],
          [48.098879, 37.736969],
          [48.100545, 37.736969],
          [48.101659, 37.737248],
          [48.103045, 37.737248],
          [48.103602, 37.737526],
          [48.105269, 37.737527],
          [48.107769, 37.738084],
          [48.109159, 37.738084],
          [48.114435, 37.73919],
          [48.115268, 37.73919],
          [48.118325, 37.740583],
          [48.119158, 37.740584],
          [48.125825, 37.744189],
          [48.126658, 37.744746],
          [48.135272, 37.752245],
          [48.147495, 37.780583],
          [48.148328, 37.784467],
          [48.149438, 37.796136],
          [48.149161, 37.796415],
          [48.148048, 37.801134],
          [48.147771, 37.802528],
          [48.148881, 37.805306],
          [48.149438, 37.805863],
          [48.150271, 37.806421],
          [48.151105, 37.809468],
          [48.151105, 37.809747],
          [48.151105, 37.810304],
          [48.146938, 37.816966],
          [48.146381, 37.817244],
          [48.146104, 37.817802],
          [48.139158, 37.821137],
          [48.138605, 37.821694],
          [48.119158, 37.828913],
          [48.118048, 37.829192],
          [48.114715, 37.830306],
          [48.108882, 37.83614],
          [48.108602, 37.83641],
          [48.108325, 37.837525],
          [48.108048, 37.838918],
          [48.104712, 37.849193],
          [48.099158, 37.852528],
          [48.098551, 37.852731],
          [48.096378, 37.853643],
          [48.088602, 37.853643],
          [48.082769, 37.850029],
          [48.076102, 37.847809],
          [48.075545, 37.847809],
          [48.073879, 37.84753],
          [48.069989, 37.84753],
          [48.069155, 37.847809],
          [48.066099, 37.847809],
          [48.065545, 37.848087],
          [48.063879, 37.848087],
          [48.062765, 37.848357],
          [48.062489, 37.848636],
          [48.061655, 37.848636],
          [48.060822, 37.849193],
          [48.060265, 37.849193],
          [48.059989, 37.849472],
          [48.059432, 37.849472],
          [48.059155, 37.84975],
          [48.058598, 37.84975],
          [48.058322, 37.850029],
          [48.057212, 37.850029],
          [48.052766, 37.852528],
          [48.049709, 37.858362],
          [48.050265, 37.865302],
          [48.046656, 37.871135],
          [48.040266, 37.875306],
          [48.039989, 37.875585],
          [48.037765, 37.876969],
          [48.027209, 37.880025],
          [48.026099, 37.880025],
          [48.025542, 37.880304],
          [48.024986, 37.880304],
          [48.024985, 37.880583],
          [48.024432, 37.880583],
          [48.024152, 37.880861],
          [48.023599, 37.880861],
          [48.020265, 37.884196],
          [48.012486, 37.888637],
          [47.999403, 37.894957],
          [47.993319, 37.890308],
          [47.986653, 37.88336],
          [47.986096, 37.882803],
          [47.978039, 37.876142],
          [47.977486, 37.876142],
          [47.977486, 37.875863],
          [47.976653, 37.875863],
          [47.976096, 37.875585],
          [47.975263, 37.875585],
          [47.974983, 37.875306],
          [47.973873, 37.875306],
          [47.973316, 37.875027],
          [47.971373, 37.875027],
          [47.971096, 37.874749],
          [47.957483, 37.874749],
          [47.957206, 37.875027],
          [47.95415, 37.875027],
          [47.953593, 37.875306],
          [47.949983, 37.875306],
          [47.94915, 37.875027],
          [47.94804, 37.875027],
          [47.947206, 37.874749],
          [47.944426, 37.874749],
          [47.943873, 37.87447],
          [47.94276, 37.87447],
          [47.941927, 37.874191],
          [47.941373, 37.874191],
          [47.93137, 37.871971],
          [47.930537, 37.871971],
          [47.929983, 37.871692],
          [47.92915, 37.871692],
          [47.928593, 37.871414],
          [47.92776, 37.871414],
          [47.927203, 37.871135],
          [47.92637, 37.871135],
          [47.911647, 37.864474],
          [47.90526, 37.862802],
          [47.904427, 37.862802],
          [47.90387, 37.862524],
          [47.90026, 37.862524],
          [47.898314, 37.86336],
          [47.898037, 37.863638],
          [47.89637, 37.865032],
          [47.895537, 37.865859],
          [47.893037, 37.868357],
          [47.890814, 37.87225],
          [47.890814, 37.876421],
          [47.891647, 37.881697],
          [47.891924, 37.882524],
          [47.891647, 37.882803],
          [47.889981, 37.88558],
          [47.889423, 37.885859],
          [47.889423, 37.886137],
          [47.88887, 37.886137],
          [47.885537, 37.889751],
          [47.884704, 37.891971],
          [47.88748, 37.897247],
          [47.887757, 37.897805],
          [47.88998, 37.898362],
          [47.901094, 37.906416],
          [47.90137, 37.906973],
          [47.905537, 37.918083],
          [47.911927, 37.928365],
          [47.92137, 37.93392],
          [47.921926, 37.93392],
          [47.922483, 37.934198],
          [47.923037, 37.934477],
          [47.92387, 37.934477],
          [47.924983, 37.935034],
          [47.925816, 37.935034],
          [47.935259, 37.94031],
          [47.935817, 37.94058],
          [47.936093, 37.941137],
          [47.93665, 37.941416],
          [47.937206, 37.942809],
          [47.93776, 37.946692],
          [47.936926, 37.948642],
          [47.93665, 37.950026],
          [47.929703, 37.962249],
          [47.929426, 37.962528],
          [47.918316, 37.975308],
          [47.914703, 37.983091],
          [47.909703, 37.990865],
          [47.906093, 37.992528],
          [47.90526, 37.992528],
          [47.904703, 37.992806],
          [47.904147, 37.992806],
          [47.903593, 37.993085],
          [47.90276, 37.993085],
          [47.90248, 37.993363],
          [47.901093, 37.993363],
          [47.900813, 37.993642],
          [47.896923, 37.993642],
          [47.89609, 37.993363],
          [47.892756, 37.993363],
          [47.892203, 37.993085],
          [47.890813, 37.993085],
          [47.890537, 37.993363],
          [47.885257, 37.993363],
          [47.884703, 37.993642],
          [47.883313, 37.993642],
          [47.882756, 37.993921],
          [47.88109, 37.993921],
          [47.880537, 37.994199],
          [47.87859, 37.994199],
          [47.878313, 37.994478],
          [47.876647, 37.994478],
          [47.876367, 37.994756],
          [47.875257, 37.994756],
          [47.869147, 37.997812],
          [47.86859, 37.998639],
          [47.86248, 38.006421],
          [47.8622, 38.0067],
          [47.861924, 38.00892],
          [47.85859, 38.031144],
          [47.85831, 38.032528],
          [47.858034, 38.034478],
          [47.857849, 38.035862],
          [47.853867, 38.051417],
          [47.85359, 38.052253],
          [47.85331, 38.053924],
          [47.853033, 38.056144],
          [47.852757, 38.057806],
          [47.852477, 38.059477],
          [47.8522, 38.060592],
          [47.851924, 38.063917],
          [47.851924, 38.066702],
          [47.851643, 38.066972],
          [47.846367, 38.091701],
          [47.847756, 38.116419],
          [47.850533, 38.128927],
          [47.851367, 38.133365],
          [47.851923, 38.141981],
          [47.8522, 38.143644],
          [47.851923, 38.143922],
          [47.8522, 38.146699],
          [47.851923, 38.146977],
          [47.844977, 38.166428],
          [47.835533, 38.179761],
          [47.834143, 38.181423],
          [47.82692, 38.188925],
          [47.826643, 38.189203],
          [47.814696, 38.196983],
          [47.80942, 38.201143],
          [47.808863, 38.202257],
          [47.80831, 38.202535],
          [47.80414, 38.210594],
          [47.803307, 38.211699],
          [47.799973, 38.220035],
          [47.79914, 38.223368],
          [47.79914, 38.223925],
          [47.798864, 38.224761],
          [47.798587, 38.226423],
          [47.797473, 38.231983],
          [47.787473, 38.251423],
          [47.785807, 38.252537],
          [47.78525, 38.252537],
          [47.784697, 38.252537],
          [47.784417, 38.252815],
          [47.783583, 38.252815],
          [47.78053, 38.250596],
          [47.778583, 38.24615],
          [47.77775, 38.243653],
          [47.773307, 38.236421],
          [47.773307, 38.235873],
          [47.771917, 38.234759],
          [47.771084, 38.234759],
          [47.770807, 38.234481],
          [47.769974, 38.234481],
          [47.769226, 38.234231],
          [47.764974, 38.233088],
          [47.764694, 38.23281],
          [47.763307, 38.23281],
          [47.76275, 38.232531],
          [47.759694, 38.232531],
          [47.75775, 38.232262],
          [47.757474, 38.232531],
          [47.756361, 38.232262],
          [47.754141, 38.232531],
          [47.753584, 38.23281],
          [47.751084, 38.23281],
          [47.750527, 38.233088],
          [47.749417, 38.233088],
          [47.74886, 38.233367],
          [47.748584, 38.233088],
          [47.748307, 38.233367],
          [47.746637, 38.23281],
          [47.740804, 38.228929],
          [47.738584, 38.226423],
          [47.738304, 38.225875],
          [47.736084, 38.223368],
          [47.724694, 38.212256],
          [47.717748, 38.211429],
          [47.716638, 38.211699],
          [47.714137, 38.213091],
          [47.704414, 38.216145],
          [47.703304, 38.216145],
          [47.701358, 38.216702],
          [47.699414, 38.216702],
          [47.698581, 38.216981],
          [47.698025, 38.216981],
          [47.695525, 38.216981],
          [47.694691, 38.216702],
          [47.693024, 38.216702],
          [47.692191, 38.216424],
          [47.688581, 38.215867],
          [47.687468, 38.215867],
          [47.686915, 38.215588],
          [47.685966, 38.215531],
          [47.678025, 38.213926],
          [47.675525, 38.213926],
          [47.674968, 38.213648],
          [47.667468, 38.213648],
          [47.666358, 38.213369],
          [47.658858, 38.213369],
          [47.657745, 38.213091],
          [47.655801, 38.213091],
          [47.654692, 38.213369],
          [47.651355, 38.213369],
          [47.650245, 38.213648],
          [47.648302, 38.213648],
          [47.647188, 38.213926],
          [47.645521, 38.213926],
          [47.639968, 38.215031],
          [47.638022, 38.215031],
          [47.634965, 38.215588],
          [47.633855, 38.215588],
          [47.633298, 38.215867],
          [47.631912, 38.215867],
          [47.623299, 38.218095],
          [47.617189, 38.218364],
          [47.614689, 38.217816],
          [47.613298, 38.217816],
          [47.613022, 38.217538],
          [47.611909, 38.217538],
          [47.611632, 38.217259],
          [47.610799, 38.217259],
          [47.606075, 38.215031],
          [47.605522, 38.214762],
          [47.598575, 38.213369],
          [47.597742, 38.213369],
          [47.597186, 38.213091],
          [47.596075, 38.213091],
          [47.595242, 38.212812],
          [47.592742, 38.212812],
          [47.584966, 38.211429],
          [47.583852, 38.210872],
          [47.583019, 38.210872],
          [47.581909, 38.210315],
          [47.581075, 38.210315],
          [47.580799, 38.210036],
          [47.579686, 38.210036],
          [47.575796, 38.208922],
          [47.574686, 38.208922],
          [47.573852, 38.208644],
          [47.573019, 38.208644],
          [47.572462, 38.208365],
          [47.571353, 38.208365],
          [47.558573, 38.203092],
          [47.541629, 38.192536],
          [47.539683, 38.1917],
          [47.539406, 38.191422],
          [47.53635, 38.191422],
          [47.536073, 38.191143],
          [47.534683, 38.191422],
          [47.533016, 38.191422],
          [47.531906, 38.1917],
          [47.529963, 38.1917],
          [47.52774, 38.192257],
          [47.527463, 38.192536],
          [47.52663, 38.192536],
          [47.52135, 38.195869],
          [47.520793, 38.196426],
          [47.519126, 38.196983],
          [47.517183, 38.197261],
          [47.515516, 38.197261],
          [47.51024, 38.196147],
          [47.508573, 38.195869],
          [47.506627, 38.195033],
          [47.506073, 38.194476],
          [47.504683, 38.193919],
          [47.493017, 38.185034],
          [47.48885, 38.182536],
          [47.488017, 38.182536],
          [47.486904, 38.181979],
          [47.482737, 38.181979],
          [47.476347, 38.18392],
          [47.47607, 38.184198],
          [47.475237, 38.184198],
          [47.474404, 38.186148],
          [47.474127, 38.187531],
          [47.474127, 38.192536],
          [47.474404, 38.19365],
          [47.47468, 38.194755],
          [47.478013, 38.205868],
          [47.480793, 38.230033],
          [47.480513, 38.230312],
          [47.480237, 38.232261],
          [47.47996, 38.233645],
          [47.47996, 38.234266],
          [47.47968, 38.235315],
          [47.479403, 38.237813],
          [47.479127, 38.239762],
          [47.478847, 38.240589],
          [47.477737, 38.242538],
          [47.468847, 38.249482],
          [47.468013, 38.249482],
          [47.46746, 38.24976],
          [47.466347, 38.24976],
          [47.465513, 38.249482],
          [47.46357, 38.249482],
          [47.462457, 38.249203],
          [47.461347, 38.249203],
          [47.46079, 38.248925],
          [47.458014, 38.248925],
          [47.456903, 38.248646],
          [47.454957, 38.248646],
          [47.45079, 38.247811],
          [47.44968, 38.247811],
          [47.430234, 38.241145],
          [47.41301, 38.237256],
          [47.411624, 38.237256],
          [47.410791, 38.236977],
          [47.407177, 38.236977],
          [47.406067, 38.237256],
          [47.402734, 38.237256],
          [47.398568, 38.237813],
          [47.385788, 38.243921],
          [47.379954, 38.248646],
          [47.379121, 38.248925],
          [47.377731, 38.250317],
          [47.375788, 38.251422],
          [47.374946, 38.251985],
          [47.360507, 38.263091],
          [47.357731, 38.266145],
          [47.358007, 38.269199],
          [47.357731, 38.269477],
          [47.353841, 38.273924],
          [47.353288, 38.274202],
          [47.353288, 38.274481],
          [47.351064, 38.275594],
          [47.324951, 38.290317],
          [47.323561, 38.290595],
          [47.321895, 38.29143],
          [47.304672, 38.296425],
          [47.303561, 38.296425],
          [47.302172, 38.296703],
          [47.291062, 38.300314],
          [47.287172, 38.302263],
          [47.287172, 38.304482],
          [47.287172, 38.305317],
          [47.287172, 38.306152],
          [47.287449, 38.306979],
          [47.288838, 38.3142],
          [47.288838, 38.315592],
          [47.289395, 38.316706],
          [47.290781, 38.331984],
          [47.295228, 38.335873],
          [47.297452, 38.3367],
          [47.319118, 38.343373],
          [47.331617, 38.349202],
          [47.334951, 38.350594],
          [47.340231, 38.353369],
          [47.345784, 38.35698],
          [47.38023, 38.396146],
          [47.384677, 38.399208],
          [47.398844, 38.405315],
          [47.411343, 38.412544],
          [47.415233, 38.415597],
          [47.441066, 38.450036],
          [47.464123, 38.478096],
          [47.465793, 38.480871],
          [47.466069, 38.486429],
          [47.465793, 38.486708],
          [47.465793, 38.490595],
          [47.466902, 38.495318],
          [47.468846, 38.499215],
          [47.473292, 38.506156],
          [47.473292, 38.506991],
          [47.474679, 38.509487],
          [47.475792, 38.513096],
          [47.476345, 38.513931],
          [47.479679, 38.533935],
          [47.479959, 38.53477],
          [47.481069, 38.540597],
          [47.495792, 38.576993],
          [47.498568, 38.581159],
          [47.501902, 38.58449],
          [47.509405, 38.590325],
          [47.518291, 38.595603],
          [47.523848, 38.597821],
          [47.527738, 38.599491],
          [47.528571, 38.599491],
          [47.531348, 38.600604],
          [47.546071, 38.604491],
          [47.548018, 38.604491],
          [47.549128, 38.604769],
          [47.550238, 38.604769],
          [47.550518, 38.605047],
          [47.552461, 38.605047],
          [47.552738, 38.605326],
          [47.554961, 38.605326],
          [47.567461, 38.609769],
          [47.567879, 38.610465],
          [47.568851, 38.611708],
          [47.569961, 38.616161],
          [47.567184, 38.628657],
          [47.567184, 38.63477],
          [47.566627, 38.637274],
          [47.565241, 38.640604],
          [47.564954, 38.642279],
          [47.525794, 38.669768],
          [47.512738, 38.676437],
          [47.510791, 38.676993],
          [47.500791, 38.682271],
          [47.499125, 38.682549],
          [47.496901, 38.683384],
          [47.488015, 38.685879],
          [47.479678, 38.688662],
          [47.475792, 38.689496],
          [47.472458, 38.689496],
          [47.467458, 38.687549],
          [47.463012, 38.687549],
          [47.461902, 38.687827],
          [47.460789, 38.687827],
          [47.459402, 38.688383],
          [47.458288, 38.688383],
          [47.457735, 38.688662],
          [47.456069, 38.688662],
          [47.451345, 38.69227],
          [47.449122, 38.695878],
          [47.448569, 38.696434],
          [47.445788, 38.697269],
          [47.444678, 38.697269],
          [47.444122, 38.697547],
          [47.443012, 38.697547],
          [47.442455, 38.697826],
          [47.441622, 38.697826],
          [47.440512, 38.698382],
          [47.439679, 38.698382],
          [47.436622, 38.700051],
          [47.436065, 38.700052],
          [47.435789, 38.70033],
          [47.435789, 38.700877],
          [47.434398, 38.703381],
          [47.433012, 38.706989],
          [47.432732, 38.708381],
          [47.433288, 38.718101],
          [47.433012, 38.719214],
          [47.426898, 38.725326],
          [47.424398, 38.729768],
          [47.424122, 38.730603],
          [47.423009, 38.733107],
          [47.422732, 38.734498],
          [47.422455, 38.73588],
          [47.422175, 38.737827],
          [47.431345, 38.756441],
          [47.433012, 38.758379],
          [47.434678, 38.759492],
          [47.435788, 38.759492],
          [47.436065, 38.75977],
          [47.437178, 38.75977],
          [47.438565, 38.760327],
          [47.439398, 38.760327],
          [47.441898, 38.761718],
          [47.443565, 38.765047],
          [47.444121, 38.765325],
          [47.446345, 38.765325],
          [47.447455, 38.766438],
          [47.448012, 38.766716],
          [47.448845, 38.768942],
          [47.448568, 38.770333],
          [47.446621, 38.772549],
          [47.448568, 38.77727],
          [47.448568, 38.777826],
          [47.461901, 38.787276],
          [47.466901, 38.790049],
          [47.469401, 38.790883],
          [47.469678, 38.79144],
          [47.533571, 38.813658],
          [47.544403, 38.815884],
          [47.545517, 38.815884],
          [47.545794, 38.816162],
          [47.566074, 38.816162],
          [47.566907, 38.815884],
          [47.568294, 38.815884],
          [47.574407, 38.815606],
          [47.58024, 38.815606],
          [47.581073, 38.815884],
          [47.59663, 38.815884],
          [47.596906, 38.81644],
          [47.597183, 38.816997],
          [47.59802, 38.818944],
          [47.59802, 38.820326],
          [47.59774, 38.820604],
          [47.597463, 38.821995],
          [47.597183, 38.823664],
          [47.597057, 38.824751],
          [47.596906, 38.825054],
          [47.59635, 38.826714],
          [47.561073, 38.848106],
          [47.559407, 38.850609],
          [47.555517, 38.855885],
          [47.553017, 38.858389],
          [47.551627, 38.860327],
          [47.548293, 38.871165],
          [47.548017, 38.87339],
          [47.547737, 38.876719],
          [47.54746, 38.87811],
          [47.549683, 38.884498],
          [47.550237, 38.887001],
          [47.54996, 38.887558],
          [47.549684, 38.888114],
          [47.549683, 38.888392],
          [47.54496, 38.893111],
          [47.540517, 38.895605],
          [47.539404, 38.895605],
          [47.53857, 38.895883],
          [47.53468, 38.895883],
          [47.533847, 38.896162],
          [47.531627, 38.896162],
          [47.525237, 38.898387],
          [47.524127, 38.898387],
          [47.522737, 38.898943],
          [47.521623, 38.898943],
          [47.52079, 38.899221],
          [47.520237, 38.898943],
          [47.51968, 38.899221],
          [47.517457, 38.903662],
          [47.516904, 38.906443],
          [47.516904, 38.906999],
          [47.516624, 38.908112],
          [47.516347, 38.910328],
          [47.51329, 38.913109],
          [47.511624, 38.91589],
          [47.520237, 38.928944],
          [47.521623, 38.932837],
          [47.521347, 38.933384],
          [47.52107, 38.935887],
          [47.524127, 38.94005],
          [47.52996, 38.943387],
          [47.53218, 38.945612],
          [47.536347, 38.950053],
          [47.536347, 38.951165],
          [47.53496, 38.952834],
          [47.530237, 38.95478],
          [47.52968, 38.955337],
          [47.529126, 38.955336],
          [47.526627, 38.958665],
          [47.528847, 38.960333],
          [47.530513, 38.960333],
          [47.533013, 38.960889],
          [47.534127, 38.960889],
          [47.53468, 38.961167],
          [47.53857, 38.961168],
          [47.539403, 38.961446],
          [47.540793, 38.961446],
          [47.552736, 38.965608],
          [47.561349, 38.971448],
          [47.561906, 38.971726],
          [47.56274, 38.973386],
          [47.563016, 38.977279],
          [47.563016, 38.977557],
          [47.561906, 38.978669],
          [47.561626, 38.979503],
          [47.55857, 38.982553],
          [47.558293, 38.983387],
          [47.547183, 38.98589],
          [47.54496, 38.98589],
          [47.541626, 38.986724],
          [47.538013, 38.987281],
          [47.53468, 38.98728],
          [47.533293, 38.987558],
          [47.519403, 38.990052],
          [47.516347, 38.990052],
          [47.514956, 38.990608],
          [47.513847, 38.990608],
          [47.512736, 38.991164],
          [47.511623, 38.991165],
          [47.506623, 38.993667],
          [47.50357, 38.997004],
          [47.502737, 38.998672],
          [47.50218, 39.000054],
          [47.503847, 39.002],
          [47.504403, 39.002],
          [47.504403, 39.002278],
          [47.504957, 39.002278],
          [47.521346, 39.011167],
          [47.521903, 39.011168],
          [47.523013, 39.014226],
          [47.52218, 39.01811],
          [47.51857, 39.022281],
          [47.51829, 39.022559],
          [47.516903, 39.023115],
          [47.516347, 39.023115],
          [47.516347, 39.023393],
          [47.515237, 39.023393],
          [47.51107, 39.024783],
          [47.510237, 39.024783],
          [47.509123, 39.025331],
          [47.50857, 39.02533],
          [47.504957, 39.029501],
          [47.505237, 39.035331],
          [47.506623, 39.037834],
          [47.508846, 39.039502],
          [47.514123, 39.039502],
          [47.514956, 39.040058],
          [47.515236, 39.040336],
          [47.515236, 39.044507],
          [47.51579, 39.045888],
          [47.517736, 39.047],
          [47.521903, 39.047],
          [47.52246, 39.047278],
          [47.522737, 39.047834],
          [47.523293, 39.048113],
          [47.523846, 39.049225],
          [47.52357, 39.050059],
          [47.523846, 39.050615],
          [47.52107, 39.055055],
          [47.52079, 39.055611],
          [47.52107, 39.060059],
          [47.525793, 39.069225],
          [47.525793, 39.069503],
          [47.52607, 39.070337],
          [47.526903, 39.072006],
          [47.527459, 39.080615],
          [47.526626, 39.083118],
          [47.524403, 39.085333],
          [47.523846, 39.085611],
          [47.51857, 39.088391],
          [47.51718, 39.089503],
          [47.515236, 39.092005],
          [47.515513, 39.09561],
          [47.51579, 39.096175],
          [47.51579, 39.096722],
          [47.51718, 39.105619],
          [47.517456, 39.105897],
          [47.51718, 39.106444],
          [47.516623, 39.110058],
          [47.516069, 39.11256],
          [47.516069, 39.113672],
          [47.512179, 39.120335],
          [47.50468, 39.124783],
          [47.503846, 39.124783],
          [47.49829, 39.127563],
          [47.498013, 39.127841],
          [47.497456, 39.128397],
          [47.498013, 39.133114],
          [47.504123, 39.140342],
          [47.512179, 39.144502],
          [47.513289, 39.144503],
          [47.518289, 39.148116],
          [47.519402, 39.150896],
          [47.519123, 39.15173],
          [47.520513, 39.158949],
          [47.520513, 39.159227],
          [47.507456, 39.169225],
          [47.513569, 39.177008],
          [47.520236, 39.187283],
          [47.520512, 39.191453],
          [47.520236, 39.192287],
          [47.520512, 39.193398],
          [47.520512, 39.193954],
          [47.520789, 39.197003],
          [47.521069, 39.197559],
          [47.520789, 39.198393],
          [47.520236, 39.20145],
          [47.519679, 39.204508],
          [47.519402, 39.206175],
          [47.519123, 39.207843],
          [47.518846, 39.210058],
          [47.518289, 39.214227],
          [47.518289, 39.21923],
          [47.518569, 39.219786],
          [47.518289, 39.220064],
          [47.517736, 39.221731],
          [47.516902, 39.222009],
          [47.514956, 39.222009],
          [47.510513, 39.220342],
          [47.509956, 39.220342],
          [47.509956, 39.220064],
          [47.507179, 39.220064],
          [47.506623, 39.220342],
          [47.506623, 39.220619],
          [47.505789, 39.221175],
          [47.505789, 39.221453],
          [47.505237, 39.222121],
          [47.505236, 39.222122],
          [47.505237, 39.222125],
          [47.510236, 39.232006],
          [47.510512, 39.232283],
          [47.510513, 39.232284],
          [47.510513, 39.232562],
          [47.511069, 39.236731],
          [47.509956, 39.23812],
          [47.508846, 39.238954],
          [47.508012, 39.240344],
          [47.508288, 39.242554],
          [47.508012, 39.242845],
          [47.490789, 39.260058],
          [47.490513, 39.260892],
          [47.490513, 39.26117],
          [47.489399, 39.263671],
          [47.488846, 39.26395],
          [47.488289, 39.265061],
          [47.486899, 39.266451],
          [47.486622, 39.267284],
          [47.485232, 39.268674],
          [47.482176, 39.270619],
          [47.481066, 39.272565],
          [47.481342, 39.27534],
          [47.481342, 39.275344],
          [47.481343, 39.275344],
          [47.481343, 39.275344],
          [47.481343, 39.275344],
          [47.481777, 39.275861],
          [47.481778, 39.275862],
          [47.488614, 39.283952],
          [47.491346, 39.286173],
          [47.492054, 39.286605],
          [47.501899, 39.292843],
          [47.502455, 39.293121],
          [47.504935, 39.295436],
          [47.504935, 39.295436],
          [47.499937, 39.297691],
          [47.495364, 39.300028],
          [47.490669, 39.300441],
          [47.486726, 39.301743],
          [47.484064, 39.304171],
          [47.479112, 39.304358],
          [47.475647, 39.306535],
          [47.470014, 39.308393],
          [47.463594, 39.309788],
          [47.463594, 39.309788],
          [47.459138, 39.310637],
          [47.454152, 39.311248],
          [47.450857, 39.311302],
          [47.446929, 39.31239],
          [47.44126, 39.314672],
          [47.434293, 39.316038],
          [47.430793, 39.318638],
          [47.430007, 39.321582],
          [47.430007, 39.321582],
          [47.430553, 39.325018],
          [47.433832, 39.328586],
          [47.433832, 39.328586],
          [47.437401, 39.331955],
          [47.438785, 39.335218],
          [47.439111, 39.337267],
          [47.429396, 39.343121],
          [47.404949, 39.368397],
          [47.404116, 39.369509],
          [47.403283, 39.370342],
          [47.400506, 39.373399],
          [47.379673, 39.405897],
          [47.366336, 39.419788],
          [47.363399, 39.421726],
          [47.360828, 39.42001],
          [47.359558, 39.418244],
          [47.355538, 39.418565],
          [47.353764, 39.418087],
          [47.349993, 39.412669],
          [47.346875, 39.412584],
          [47.345131, 39.414152],
          [47.343362, 39.414276],
          [47.341595, 39.413476],
          [47.339854, 39.411356],
          [47.341201, 39.406124],
          [47.33702, 39.404777],
          [47.335519, 39.403568],
          [47.334684, 39.39703],
          [47.3301, 39.390962],
          [47.325706, 39.383896],
          [47.322048, 39.382501],
          [47.320624, 39.381489],
          [47.318885, 39.378405],
          [47.318141, 39.374218],
          [47.315114, 39.369194],
          [47.310983, 39.36851],
          [47.307551, 39.368929],
          [47.304201, 39.368872],
          [47.300274, 39.368193],
          [47.297332, 39.367426],
          [47.296044, 39.36709],
          [47.28972, 39.363332],
          [47.279527, 39.361128],
          [47.275415, 39.358916],
          [47.27331, 39.356165],
          [47.270357, 39.352305],
          [47.267973, 39.350467],
          [47.262051, 39.349619],
          [47.259178, 39.347437],
          [47.255345, 39.348652],
          [47.251877, 39.348202],
          [47.25026, 39.346352],
          [47.248891, 39.340561],
          [47.245897, 39.337205],
          [47.239384, 39.336481],
          [47.236677, 39.334705],
          [47.232953, 39.33518],
          [47.226475, 39.333479],
          [47.224794, 39.33176],
          [47.223787, 39.325517],
          [47.218331, 39.322266],
          [47.215672, 39.318953],
          [47.208284, 39.318885],
          [47.206757, 39.320307],
          [47.206106, 39.320948],
          [47.202569, 39.319048],
          [47.199295, 39.318728],
          [47.196249, 39.319996],
          [47.19496, 39.319917],
          [47.192787, 39.318976],
          [47.188782, 39.315769],
          [47.182525, 39.315681],
          [47.17945, 39.3148],
          [47.177757, 39.312695],
          [47.172365, 39.30932],
          [47.166811, 39.307597],
          [47.165785, 39.307453],
          [47.165072, 39.307924],
          [47.163168, 39.311191],
          [47.161355, 39.311833],
          [47.159133, 39.311453],
          [47.157053, 39.309888],
          [47.152728, 39.302014],
          [47.148909, 39.301416],
          [47.148257, 39.300469],
          [47.148355, 39.297879],
          [47.146932, 39.296182],
          [47.145536, 39.295785],
          [47.142909, 39.296277],
          [47.140967, 39.295464],
          [47.139932, 39.293326],
          [47.139034, 39.292399],
          [47.13444, 39.292358],
          [47.132774, 39.291827],
          [47.130708, 39.289222],
          [47.12958, 39.285867],
          [47.127255, 39.281803],
          [47.126597, 39.279804],
          [47.125564, 39.278364],
          [47.124392, 39.278091],
          [47.123216, 39.278122],
          [47.120067, 39.280394],
          [47.116331, 39.280555],
          [47.113491, 39.279243],
          [47.11327, 39.27914],
          [47.111281, 39.274627],
          [47.109931, 39.273502],
          [47.106805, 39.272598],
          [47.104117, 39.272735],
          [47.101658, 39.274198],
          [47.100734, 39.276085],
          [47.09833, 39.276734],
          [47.096895, 39.275538],
          [47.094658, 39.265955],
          [47.092453, 39.26481],
          [47.090457, 39.264755],
          [47.088267, 39.266362],
          [47.086364, 39.266456],
          [47.084414, 39.265721],
          [47.081402, 39.263621],
          [47.076456, 39.256504],
          [47.075537, 39.252419],
          [47.075597, 39.249018],
          [47.072763, 39.245316],
          [47.070415, 39.244476],
          [47.064326, 39.245723],
          [47.063112, 39.245418],
          [47.062028, 39.243659],
          [47.059463, 39.242005],
          [47.058069, 39.239819],
          [47.057949, 39.238137],
          [47.060171, 39.233569],
          [47.059829, 39.232618],
          [47.05777, 39.23082],
          [47.057228, 39.227974],
          [47.057799, 39.2242],
          [47.057206, 39.221342],
          [47.054781, 39.215735],
          [47.054989, 39.213703],
          [47.056732, 39.210729],
          [47.060689, 39.206442],
          [47.060731, 39.206239],
          [47.060919, 39.205274],
          [47.060392, 39.204115],
          [47.057593, 39.203267],
          [47.056313, 39.201266],
          [47.055114, 39.195568],
          [47.053937, 39.193912],
          [47.048884, 39.190641],
          [47.045383, 39.187656],
          [47.040644, 39.184933],
          [47.036482, 39.183403],
          [47.03455, 39.179962],
          [47.033492, 39.178833],
          [47.025578, 39.17685],
          [47.022564, 39.175481],
          [47.02231, 39.174169],
          [47.021227, 39.173465],
          [47.021357, 39.170457],
          [47.019794, 39.167719],
          [47.01911, 39.167001],
          [47.015793, 39.166207],
          [47.006941, 39.167068],
          [47.003652, 39.166679],
          [46.999357, 39.167785],
          [46.997639, 39.16723],
          [46.995701, 39.165673],
          [46.993264, 39.164512],
          [46.993097, 39.163716],
          [46.9919, 39.162404],
          [46.991923, 39.159348],
          [46.993983, 39.155742],
          [46.993459, 39.154538],
          [46.988986, 39.152802],
          [46.984509, 39.150448],
          [46.982056, 39.149884],
          [46.976297, 39.14736],
          [46.968162, 39.14453],
          [46.96474, 39.142473],
          [46.963794, 39.14154],
          [46.963283, 39.140536],
          [46.962495, 39.140358],
          [46.960918, 39.141411],
          [46.959216, 39.141547],
          [46.956242, 39.141149],
          [46.953283, 39.140335],
          [46.951598, 39.139406],
          [46.949906, 39.139505],
          [46.948504, 39.140442],
          [46.948061, 39.14167],
          [46.947777, 39.147491],
          [46.946378, 39.148548],
          [46.944385, 39.148109],
          [46.938558, 39.151977],
          [46.938259, 39.15467],
          [46.93705, 39.157227],
          [46.925544, 39.164729],
          [46.911185, 39.158891],
          [46.866471, 39.14071],
          [46.845263, 39.139562],
          [46.839019, 39.137497],
          [46.840484, 39.133467],
          [46.841472, 39.130453],
          [46.839009, 39.123341],
          [46.836686, 39.121819],
          [46.834464, 39.120921],
          [46.833889, 39.120205],
          [46.833739, 39.118829],
          [46.834483, 39.115597],
          [46.834237, 39.114444],
          [46.833121, 39.113403],
          [46.832021, 39.112961],
          [46.830517, 39.112869],
          [46.826945, 39.114758],
          [46.825939, 39.114549],
          [46.82413, 39.113131],
          [46.82422, 39.112265],
          [46.822738, 39.110815],
          [46.820332, 39.110552],
          [46.818522, 39.110057],
          [46.815529, 39.109921],
          [46.814724, 39.10907],
          [46.814865, 39.107781],
          [46.815757, 39.106827],
          [46.817187, 39.106221],
          [46.819909, 39.106648],
          [46.82067, 39.10627],
          [46.821177, 39.105531],
          [46.820977, 39.10476],
          [46.820351, 39.104159],
          [46.817991, 39.10352],
          [46.813058, 39.103531],
          [46.811954, 39.103096],
          [46.811103, 39.101442],
          [46.813404, 39.097948],
          [46.813378, 39.097163],
          [46.812418, 39.096478],
          [46.801696, 39.093732],
          [46.799899, 39.092555],
          [46.797408, 39.091979],
          [46.796425, 39.090642],
          [46.796354, 39.088706],
          [46.795734, 39.088082],
          [46.791614, 39.087657],
          [46.790686, 39.08711],
          [46.789688, 39.081896],
          [46.788521, 39.08048],
          [46.78799, 39.078327],
          [46.786718, 39.077015],
          [46.781905, 39.075698],
          [46.77802, 39.072765],
          [46.777561, 39.071187],
          [46.775708, 39.069856],
          [46.774699, 39.069685],
          [46.773844, 39.069868],
          [46.771634, 39.071277],
          [46.770452, 39.071539],
          [46.769335, 39.071325],
          [46.768343, 39.070419],
          [46.768324, 39.068016],
          [46.767482, 39.064697],
          [46.767935, 39.058889],
          [46.766909, 39.057953],
          [46.761296, 39.058463],
          [46.759099, 39.057934],
          [46.758494, 39.057075],
          [46.757299, 39.049831],
          [46.758079, 39.045616],
          [46.758649, 39.043573],
          [46.761909, 39.038184],
          [46.761784, 39.036901],
          [46.756415, 39.030118],
          [46.753093, 39.025914],
          [46.751951, 39.025757],
          [46.75054, 39.027803],
          [46.749355, 39.028432],
          [46.747945, 39.02857],
          [46.743788, 39.026337],
          [46.739978, 39.020004],
          [46.738515, 39.018724],
          [46.736782, 39.01762],
          [46.734659, 39.016822],
          [46.731384, 39.016965],
          [46.728515, 39.016792],
          [46.725589, 39.016254],
          [46.722358, 39.014585],
          [46.720087, 39.014464],
          [46.717408, 39.014585],
          [46.715531, 39.01387],
          [46.714023, 39.01281],
          [46.707876, 39.013846],
          [46.707142, 39.014424],
          [46.706744, 39.018033],
          [46.706097, 39.018494],
          [46.70324, 39.018622],
          [46.699389, 39.019457],
          [46.696938, 39.01903],
          [46.695725, 39.018017],
          [46.69144, 39.012144],
          [46.687078, 39.008139],
          [46.685109, 39.007906],
          [46.683564, 39.007151],
          [46.68316, 39.006344],
          [46.683518, 39.004899],
          [46.688279, 39.002611],
          [46.688991, 39.00155],
          [46.688877, 38.996494],
          [46.690328, 38.994935],
          [46.689289, 38.99354],
          [46.685701, 38.992421],
          [46.683769, 38.992293],
          [46.681183, 38.990094],
          [46.678104, 38.9848],
          [46.675179, 38.983839],
          [46.674757, 38.983345],
          [46.674808, 38.980007],
          [46.673455, 38.977873],
          [46.667262, 38.974239],
          [46.663436, 38.970889],
          [46.663211, 38.96794],
          [46.662265, 38.966546],
          [46.660253, 38.965512],
          [46.657754, 38.963516],
          [46.656541, 38.962131],
          [46.656832, 38.958724],
          [46.656581, 38.957136],
          [46.656056, 38.955599],
          [46.654977, 38.954091],
          [46.652271, 38.952698],
          [46.648301, 38.951625],
          [46.641443, 38.951558],
          [46.640413, 38.951116],
          [46.639237, 38.949291],
          [46.63874, 38.947052],
          [46.638939, 38.945831],
          [46.640415, 38.94503],
          [46.64067, 38.94421],
          [46.63824, 38.940344],
          [46.636733, 38.939455],
          [46.632306, 38.939308],
          [46.628164, 38.937133],
          [46.626855, 38.935018],
          [46.627472, 38.930396],
          [46.625356, 38.929186],
          [46.622978, 38.928212],
          [46.621666, 38.927085],
          [46.620589, 38.924545],
          [46.619123, 38.923454],
          [46.613412, 38.920663],
          [46.609647, 38.921636],
          [46.607642, 38.921374],
          [46.607153, 38.920483],
          [46.607812, 38.917754],
          [46.604192, 38.914546],
          [46.60345, 38.912529],
          [46.603455, 38.909915],
          [46.600954, 38.906061],
          [46.598338, 38.905758],
          [46.592082, 38.902079],
          [46.588786, 38.89935],
          [46.587546, 38.898587],
          [46.585008, 38.898087],
          [46.582081, 38.895893],
          [46.574592, 38.891411],
          [46.571099, 38.891055],
          [46.568486, 38.890436],
          [46.566196, 38.888648],
          [46.566373, 38.88637],
          [46.565344, 38.8857],
          [46.55698, 38.885389],
          [46.554448, 38.884229],
          [46.552194, 38.882619],
          [46.550003, 38.879352],
          [46.54628, 38.878445],
          [46.543804, 38.876991],
          [46.540997, 38.871869],
          [46.539826, 38.870627],
          [46.533983, 38.867172],
          [46.533713, 38.867327],
          [46.530164, 38.869358],
          [46.52713, 38.87236],
          [46.524949, 38.8757],
          [46.523692, 38.878012],
          [46.523999, 38.881137],
          [46.525424, 38.885201],
          [46.52578, 38.886173],
          [46.525684, 38.886675],
          [46.525534, 38.887462],
          [46.525115, 38.887885],
          [46.524382, 38.888312],
          [46.5226, 38.888907],
          [46.519482, 38.889012],
          [46.517293, 38.888903],
          [46.514402, 38.888783],
          [46.512049, 38.887984],
          [46.509354, 38.887225],
          [46.507976, 38.886583],
          [46.506528, 38.885402],
          [46.505261, 38.885232],
          [46.504312, 38.885563],
          [46.502604, 38.887245],
          [46.50076, 38.88882],
          [46.498775, 38.889589],
          [46.498143, 38.889789],
          [46.491463, 38.889262],
          [46.491377, 38.889233],
          [46.490788, 38.889041],
          [46.490169, 38.888679],
          [46.489484, 38.887709],
          [46.489259, 38.887016],
          [46.489114, 38.886509],
          [46.488974, 38.886105],
          [46.488444, 38.886123],
          [46.486641, 38.886164],
          [46.485343, 38.885655],
          [46.483482, 38.885367],
          [46.482144, 38.885953],
          [46.480284, 38.887421],
          [46.478271, 38.88876],
          [46.474882, 38.889843],
          [46.473861, 38.890074],
          [46.472375, 38.889413],
          [46.470413, 38.889071],
          [46.46925, 38.889018],
          [46.468172, 38.888933],
          [46.466507, 38.888481],
          [46.46594, 38.888448],
          [46.46528, 38.888581],
          [46.464186, 38.889914],
          [46.463528, 38.891472],
          [46.462071, 38.893348],
          [46.461836, 38.893578],
          [46.461235, 38.893655],
          [46.460761, 38.894016],
          [46.459343, 38.894597],
          [46.458725, 38.895158],
          [46.458446, 38.895943],
          [46.458191, 38.897169],
          [46.456618, 38.898194],
          [46.455066, 38.898799],
          [46.4535, 38.899007],
          [46.452821, 38.89871],
          [46.451418, 38.897705],
          [46.450753, 38.897208],
          [46.449974, 38.897028],
          [46.446583, 38.89758],
          [46.445523, 38.89728],
          [46.443865, 38.897387],
          [46.443071, 38.897346],
          [46.442031, 38.89687],
          [46.441526, 38.896143],
          [46.441256, 38.895135],
          [46.440533, 38.894725],
          [46.439585, 38.894448],
          [46.438246, 38.894547],
          [46.436747, 38.895045],
          [46.435282, 38.895225],
          [46.432933, 38.894849],
          [46.431593, 38.894549],
          [46.430937, 38.894465],
          [46.430122, 38.893597],
          [46.429628, 38.893246],
          [46.429092, 38.892578],
          [46.4273, 38.892035],
          [46.426806, 38.891488],
          [46.426201, 38.890405],
          [46.426345, 38.88836],
          [46.425008, 38.885547],
          [46.423876, 38.884408],
          [46.422593, 38.883218],
          [46.421044, 38.883022],
          [46.419756, 38.883356],
          [46.418185, 38.884463],
          [46.416746, 38.886625],
          [46.41643, 38.887866],
          [46.416361, 38.888284],
          [46.416248, 38.888956],
          [46.416654, 38.890756],
          [46.416404, 38.892196],
          [46.416132, 38.892572],
          [46.415079, 38.892876],
          [46.413598, 38.89301],
          [46.410351, 38.893279],
          [46.409136, 38.894173],
          [46.40419, 38.897977],
          [46.402857, 38.899641],
          [46.401204, 38.900469],
          [46.39754, 38.902308],
          [46.394346, 38.903671],
          [46.393949, 38.904138],
          [46.393343, 38.904247],
          [46.391812, 38.904144],
          [46.389771, 38.905027],
          [46.38899, 38.905471],
          [46.387801, 38.905795],
          [46.384095, 38.90641],
          [46.383102, 38.906453],
          [46.382529, 38.906479],
          [46.381846, 38.906436],
          [46.380787, 38.90637],
          [46.377422, 38.906977],
          [46.374243, 38.908157],
          [46.37119, 38.909011],
          [46.369077, 38.909701],
          [46.368295, 38.910648],
          [46.368205, 38.912006],
          [46.368179, 38.91241],
          [46.365783, 38.914216],
          [46.364885, 38.914914],
          [46.36322, 38.916309],
          [46.361952, 38.916335],
          [46.359577, 38.915116],
          [46.358855, 38.915054],
          [46.356854, 38.916409],
          [46.353348, 38.916761],
          [46.350582, 38.916591],
          [46.34614, 38.91682],
          [46.345384, 38.916683],
          [46.339259, 38.916363],
          [46.336835, 38.915403],
          [46.336052, 38.913663],
          [46.335766, 38.913025],
          [46.336347, 38.911742],
          [46.335857, 38.910461],
          [46.333628, 38.909244],
          [46.329832, 38.908695],
          [46.32774, 38.908152],
          [46.326609, 38.907117],
          [46.321343, 38.903562],
          [46.31949, 38.902569],
          [46.319022, 38.902318],
          [46.317362, 38.901543],
          [46.316042, 38.9014],
          [46.315671, 38.901471],
          [46.313524, 38.901878],
          [46.309458, 38.903532],
          [46.307823, 38.904571],
          [46.305752, 38.905889],
          [46.303102, 38.906338],
          [46.302099, 38.905951],
          [46.30167, 38.905523],
          [46.300949, 38.902398],
          [46.300241, 38.901473],
          [46.297931, 38.900308],
          [46.296216, 38.900177],
          [46.293548, 38.901068],
          [46.292061, 38.899986],
          [46.291261, 38.89982],
          [46.289604, 38.900467],
          [46.288386, 38.900822],
          [46.285859, 38.900895],
          [46.283249, 38.900569],
          [46.282318, 38.900272],
          [46.278728, 38.897975],
          [46.277465, 38.895596],
          [46.276353, 38.895713],
          [46.271982, 38.895351],
          [46.270442, 38.893974],
          [46.269104, 38.893822],
          [46.267069, 38.892976],
          [46.266629, 38.891851],
          [46.267011, 38.888651],
          [46.267064, 38.888271],
          [46.267154, 38.887622],
          [46.266481, 38.88664],
          [46.262686, 38.884831],
          [46.259968, 38.884357],
          [46.257959, 38.881988],
          [46.255836, 38.881355],
          [46.253319, 38.880303],
          [46.249622, 38.878013],
          [46.248875, 38.875636],
          [46.247803, 38.874049],
          [46.243807, 38.872593],
          [46.240442, 38.871801],
          [46.23609, 38.869166],
          [46.233834, 38.868767],
          [46.231933, 38.868351],
          [46.230772, 38.86689],
          [46.228572, 38.866347],
          [46.226613, 38.866365],
          [46.224183, 38.86612],
          [46.218729, 38.865232],
          [46.216225, 38.864387],
          [46.216193, 38.862438],
          [46.21571, 38.86117],
          [46.214785, 38.858536],
          [46.214765, 38.858171],
          [46.21462, 38.855703],
          [46.211893, 38.853958],
          [46.210464, 38.853994],
          [46.209413, 38.853758],
          [46.207808, 38.853309],
          [46.20277, 38.851671],
          [46.195689, 38.852392],
          [46.193585, 38.85192],
          [46.191893, 38.85088],
          [46.19136, 38.848739],
          [46.19039, 38.846949],
          [46.186999, 38.843148],
          [46.184136, 38.841488],
          [46.181776, 38.840756],
          [46.178286, 38.840763],
          [46.175674, 38.840553],
          [46.173374, 38.840478],
          [46.171053, 38.841599],
          [46.169674, 38.842227],
          [46.167629, 38.842562],
          [46.166043, 38.843016],
          [46.164522, 38.84278],
          [46.162353, 38.841992],
          [46.161411, 38.841199],
          [46.15976, 38.841196],
          [46.158683, 38.841497],
          [46.157704, 38.841982],
          [46.155972, 38.84282],
          [46.155087, 38.84305],
          [46.154423, 38.843222],
          [46.152839, 38.842304],
          [46.150821, 38.841707],
          [46.147601, 38.841811],
          [46.144953, 38.842185],
          [46.140676, 38.844518],
          [46.139126, 38.845451],
          [46.136416, 38.846875],
          [46.135478, 38.846759],
          [46.130181, 38.846226],
          [46.129137, 38.846401],
          [46.128448, 38.847192],
          [46.128913, 38.849565],
          [46.128117, 38.851106],
          [46.123557, 38.853107],
          [46.121677, 38.854777],
          [46.118683, 38.856028],
          [46.117217, 38.856445],
          [46.115383, 38.857404],
          [46.114262, 38.858594],
          [46.112823, 38.858341],
          [46.11175, 38.858385],
          [46.111451, 38.858939],
          [46.113056, 38.862652],
          [46.113149, 38.863745],
          [46.11219, 38.865074],
          [46.110444, 38.865832],
          [46.103784, 38.866964],
          [46.101882, 38.866643],
          [46.098777, 38.867172],
          [46.097184, 38.867108],
          [46.095196, 38.866831],
          [46.093032, 38.867287],
          [46.091089, 38.868585],
          [46.086337, 38.869891],
          [46.082754, 38.871165],
          [46.080435, 38.871462],
          [46.079242, 38.872457],
          [46.077861, 38.874128],
          [46.075506, 38.875801],
          [46.072439, 38.877324],
          [46.071265, 38.878419],
          [46.069031, 38.882459],
          [46.06761, 38.885657],
          [46.067022, 38.886912],
          [46.06542, 38.887439],
          [46.062838, 38.887749],
          [46.060615, 38.887545],
          [46.058888, 38.887777],
          [46.056204, 38.885665],
          [46.055509, 38.884869],
          [46.054619, 38.884313],
          [46.054258, 38.883714],
          [46.054284, 38.881594],
          [46.054789, 38.880454],
          [46.054517, 38.878766],
          [46.054138, 38.87805],
          [46.051849, 38.876101],
          [46.048894, 38.874985],
          [46.046837, 38.874706],
          [46.044646, 38.873893],
          [46.043645, 38.873427],
          [46.041576, 38.871923],
          [46.040945, 38.871789],
          [46.040357, 38.871867],
          [46.037931, 38.872692],
          [46.034476, 38.874759],
          [46.03265, 38.87501],
          [46.029065, 38.874545],
          [46.028199, 38.874273],
          [46.027836, 38.873939],
          [46.027422, 38.872566],
          [46.024509, 38.872378],
          [46.020054, 38.873616],
          [46.01565, 38.873658],
          [46.012005, 38.871889],
          [46.006296, 38.872362],
          [46.004014, 38.873073],
          [45.999659, 38.875344],
          [45.997719, 38.875471],
          [45.994545, 38.87485],
          [45.987791, 38.875964],
          [45.979187, 38.878816],
          [45.975994, 38.88087],
          [45.972925, 38.883934],
          [45.968183, 38.887077],
          [45.967717, 38.887884],
          [45.967399, 38.88962],
          [45.966761, 38.890115],
          [45.964478, 38.89066],
          [45.962637, 38.890382],
          [45.959927, 38.88887],
          [45.958063, 38.888616],
          [45.956137, 38.88925],
          [45.947283, 38.895069],
          [45.945525, 38.895731],
          [45.944522, 38.896593],
          [45.943529, 38.896398],
          [45.942337, 38.89541],
          [45.94175, 38.894066],
          [45.934237, 38.889464],
          [45.930471, 38.886135],
          [45.928632, 38.883348],
          [45.928927, 38.882519],
          [45.927957, 38.879569],
          [45.92757, 38.879077],
          [45.926665, 38.877891],
          [45.924836, 38.877565],
          [45.924196, 38.877818],
          [45.923632, 38.877726],
          [45.922733, 38.876833],
          [45.922238, 38.87547],
          [45.91906, 38.873585],
          [45.91788, 38.873322],
          [45.912428, 38.874604],
          [45.910292, 38.873943],
          [45.908708, 38.873917],
          [45.903844, 38.877727],
          [45.900419, 38.877572],
          [45.897364, 38.876572],
          [45.895364, 38.876407],
          [45.894024, 38.876673],
          [45.892105, 38.878407],
          [45.891275, 38.881418],
          [45.891573, 38.883358],
          [45.890435, 38.88481],
          [45.885314, 38.886217],
          [45.88233, 38.886543],
          [45.880849, 38.888432],
          [45.879581, 38.889203],
          [45.875734, 38.889796],
          [45.873529, 38.889108],
          [45.871333, 38.888712],
          [45.869252, 38.888552],
          [45.868447, 38.889006],
          [45.865018, 38.891549],
          [45.860861, 38.893075],
          [45.8604, 38.893748],
          [45.861601, 38.895424],
          [45.862065, 38.897119],
          [45.86413, 38.899199],
          [45.864059, 38.900303],
          [45.861254, 38.90346],
          [45.857653, 38.904621],
          [45.856075, 38.906076],
          [45.851785, 38.908395],
          [45.849361, 38.909558],
          [45.846746, 38.910444],
          [45.844416, 38.910704],
          [45.843072, 38.910272],
          [45.839383, 38.907995],
          [45.835217, 38.904968],
          [45.83327, 38.902656],
          [45.832137, 38.902357],
          [45.830335, 38.902472],
          [45.826745, 38.902375],
          [45.82346, 38.903903],
          [45.821802, 38.904982],
          [45.820047, 38.905486],
          [45.815274, 38.905255],
          [45.813793, 38.906009],
          [45.812298, 38.907104],
          [45.811938, 38.908101],
          [45.811807, 38.909154],
          [45.81193, 38.910534],
          [45.811147, 38.912],
          [45.806204, 38.913509],
          [45.805287, 38.914077],
          [45.803646, 38.914116],
          [45.802433, 38.913403],
          [45.801302, 38.913365],
          [45.79983, 38.915039],
          [45.799784, 38.918036],
          [45.800772, 38.919339],
          [45.801069, 38.9204],
          [45.800908, 38.921873],
          [45.798725, 38.924554],
          [45.797997, 38.924928],
          [45.794126, 38.924446],
          [45.791757, 38.923692],
          [45.790151, 38.922205],
          [45.788529, 38.920703],
          [45.786435, 38.919524],
          [45.785821, 38.919459],
          [45.785301, 38.919898],
          [45.786173, 38.922939],
          [45.785674, 38.924367],
          [45.78418, 38.925182],
          [45.782249, 38.925629],
          [45.77687, 38.923419],
          [45.774522, 38.923347],
          [45.772434, 38.923695],
          [45.768493, 38.925392],
          [45.766781, 38.927155],
          [45.763785, 38.929009],
          [45.762751, 38.92925],
          [45.761636, 38.929185],
          [45.758943, 38.930181],
          [45.757512, 38.930233],
          [45.75357, 38.928935],
          [45.736821, 38.927535],
          [45.7356, 38.927984],
          [45.73213, 38.93078],
          [45.730349, 38.931457],
          [45.725519, 38.932164],
          [45.723415, 38.933634],
          [45.721631, 38.935468],
          [45.717434, 38.936482],
          [45.71321, 38.939446],
          [45.71017, 38.944965],
          [45.709396, 38.945086],
          [45.704112, 38.943825],
          [45.701795, 38.943637],
          [45.698858, 38.944239],
          [45.697818, 38.945069],
          [45.696702, 38.948076],
          [45.696192, 38.950327],
          [45.692446, 38.951495],
          [45.690013, 38.951406],
          [45.684288, 38.949094],
          [45.679346, 38.946558],
          [45.678701, 38.946449],
          [45.678099, 38.946584],
          [45.677136, 38.947988],
          [45.67595, 38.949005],
          [45.675297, 38.949443],
          [45.673922, 38.949565],
          [45.672509, 38.949112],
          [45.670521, 38.947152],
          [45.67008, 38.947015],
          [45.669391, 38.946801],
          [45.667711, 38.947206],
          [45.663487, 38.950588],
          [45.660556, 38.951705],
          [45.655227, 38.951825],
          [45.650193, 38.951368],
          [45.647385, 38.951878],
          [45.646195, 38.951536],
          [45.645108, 38.947509],
          [45.644387, 38.946394],
          [45.642162, 38.947225],
          [45.638591, 38.946986],
          [45.637079, 38.946651],
          [45.635611, 38.945456],
          [45.633487, 38.94475],
          [45.629942, 38.944695],
          [45.626665, 38.94367],
          [45.618314, 38.94265],
          [45.615871, 38.941417],
          [45.614574, 38.941694],
          [45.61408, 38.942511],
          [45.616288, 38.946481],
          [45.616334, 38.948138],
          [45.614766, 38.952512],
          [45.614774, 38.954721],
          [45.614415, 38.956346],
          [45.613081, 38.957885],
          [45.612499, 38.958108],
          [45.611813, 38.957973],
          [45.611037, 38.956858],
          [45.610249, 38.956245],
          [45.609356, 38.95617],
          [45.607352, 38.956751],
          [45.605574, 38.957849],
          [45.603413, 38.959252],
          [45.602908, 38.960455],
          [45.600773, 38.961776],
          [45.595145, 38.962254],
          [45.592843, 38.963264],
          [45.591186, 38.964492],
          [45.585342, 38.967214],
          [45.584141, 38.969381],
          [45.583855, 38.971796],
          [45.584314, 38.976112],
          [45.583579, 38.977269],
          [45.581898, 38.978503],
          [45.580174, 38.978969],
          [45.577809, 38.978645],
          [45.57022, 38.976102],
          [45.569294, 38.97607],
          [45.568494, 38.975534],
          [45.567336, 38.973822],
          [45.566099, 38.971171],
          [45.566361, 38.970593],
          [45.565676, 38.969068],
          [45.563446, 38.968261],
          [45.562493, 38.968922],
          [45.558524, 38.968151],
          [45.557644, 38.967805],
          [45.553142, 38.96449],
          [45.550872, 38.96402],
          [45.549516, 38.964116],
          [45.548145, 38.965025],
          [45.546695, 38.965436],
          [45.545096, 38.965503],
          [45.543958, 38.96459],
          [45.541503, 38.964829],
          [45.537248, 38.966418],
          [45.535743, 38.968081],
          [45.535652, 38.968867],
          [45.534274, 38.969879],
          [45.532215, 38.970353],
          [45.530667, 38.969685],
          [45.529166, 38.969833],
          [45.527755, 38.971528],
          [45.52633, 38.971912],
          [45.525019, 38.971808],
          [45.523297, 38.972362],
          [45.521598, 38.974134],
          [45.52045, 38.974591],
          [45.514872, 38.976008],
          [45.512366, 38.97706],
          [45.509252, 38.978563],
          [45.508192, 38.978859],
          [45.506776, 38.979338],
          [45.506408, 38.980489],
          [45.505015, 38.980666],
          [45.504389, 38.980439],
          [45.503667, 38.980279],
          [45.502972, 38.980351],
          [45.501975, 38.980701],
          [45.500786, 38.981862],
          [45.50081, 38.983082],
          [45.498392, 38.984078],
          [45.49734, 38.984162],
          [45.495709, 38.983945],
          [45.493977, 38.983253],
          [45.493297, 38.983238],
          [45.490747, 38.984169],
          [45.489201, 38.984843],
          [45.48681, 38.986655],
          [45.48258, 38.986599],
          [45.480246, 38.986177],
          [45.477642, 38.986339],
          [45.47241, 38.989745],
          [45.47089, 38.989014],
          [45.468587, 38.989138],
          [45.464401, 38.989967],
          [45.462045, 38.989985],
          [45.459037, 38.990321],
          [45.458, 38.990108],
          [45.454951, 38.990536],
          [45.454242, 38.991384],
          [45.453596, 38.992655],
          [45.452905, 38.992997],
          [45.450133, 38.990602],
          [45.44958, 38.990325],
          [45.44319, 38.984494],
          [45.442077, 38.984216],
          [45.435828, 38.980624],
          [45.428606, 38.977287],
          [45.428329, 38.976731],
          [45.423451, 38.975227],
          [45.419749, 38.974893],
          [45.419472, 38.974615],
          [45.415859, 38.974615],
          [45.411416, 38.974059],
          [45.404297, 38.973379],
          [45.403744, 38.97311],
          [45.400688, 38.97311],
          [45.399854, 38.972832],
          [45.398464, 38.972832],
          [45.397631, 38.972554],
          [45.396245, 38.972554],
          [45.393464, 38.971442],
          [45.392631, 38.971442],
          [45.391521, 38.970886],
          [45.384021, 38.965602],
          [45.377907, 38.962552],
          [45.376328, 38.960995],
          [45.375494, 38.959335],
          [45.370617, 38.947795],
          [45.362377, 38.94419],
          [45.357742, 38.918418],
          [45.349331, 38.914678],
          [45.345898, 38.90466],
          [45.341434, 38.899717],
          [45.341713, 38.895612],
          [45.33622, 38.888308],
          [45.33622, 38.887752],
          [45.319812, 38.883686],
          [45.30636, 38.877874],
          [45.30636, 38.877317],
          [45.281516, 38.873662],
          [45.280682, 38.873106],
          [45.277699, 38.868021],
          [45.277419, 38.867743],
          [45.278173, 38.867031],
          [45.275598, 38.863099],
          [45.269418, 38.792508],
          [45.265747, 38.785261],
          [45.273285, 38.771484],
          [45.269141, 38.765002],
          [45.271362, 38.753891],
          [45.271516, 38.743098],
          [45.275127, 38.733379],
          [45.275684, 38.733101],
          [45.275684, 38.731988],
          [45.275407, 38.731431],
          [45.275127, 38.730318],
          [45.27346, 38.727823],
          [45.27307, 38.722402],
          [45.271403, 38.717124],
          [45.268904, 38.712673],
          [45.264191, 38.704807],
          [45.257802, 38.694522],
          [45.257522, 38.690635],
          [45.251193, 38.68212],
          [45.249526, 38.677668],
          [45.249247, 38.67712],
          [45.249247, 38.676833],
          [45.247409, 38.670812],
          [45.247685, 38.669421],
          [45.243242, 38.666916],
          [45.241852, 38.666647],
          [45.240186, 38.666647],
          [45.240186, 38.666368],
          [45.239629, 38.666368],
          [45.238795, 38.664142],
          [45.240463, 38.663029],
          [45.240186, 38.662473],
          [45.237405, 38.659142],
          [45.241019, 38.657473],
          [45.252627, 38.646711],
          [45.252627, 38.646154],
          [45.253461, 38.645319],
          [45.257904, 38.63532],
          [45.259017, 38.631702],
          [45.258184, 38.628928],
          [45.257904, 38.628372],
          [45.261667, 38.617863],
          [45.268054, 38.613141],
          [45.269167, 38.607027],
          [45.265809, 38.598582],
          [45.261794, 38.593927],
          [45.262071, 38.591979],
          [45.262351, 38.59004],
          [45.26865, 38.577695],
          [45.259421, 38.554295],
          [45.259701, 38.554017],
          [45.265254, 38.543176],
          [45.275001, 38.533396],
          [45.296116, 38.521041],
          [45.3265, 38.495385],
          [45.335604, 38.476431],
          [45.329761, 38.461654],
          [45.314999, 38.450228],
          [45.276289, 38.43673],
          [45.273509, 38.435895],
          [45.257548, 38.434235],
          [45.246824, 38.433804],
          [45.24182, 38.433535],
          [45.240434, 38.433804],
          [45.235434, 38.433535],
          [45.228374, 38.43072],
          [45.225735, 38.421991],
          [45.230198, 38.412711],
          [45.243795, 38.401474],
          [45.243238, 38.400917],
          [45.243587, 38.393474],
          [45.235425, 38.390513],
          [45.223868, 38.383125],
          [45.221858, 38.381341],
          [45.20256, 38.390917],
          [45.195946, 38.390369],
          [45.178999, 38.377597],
          [45.172038, 38.368027],
          [45.170924, 38.368027],
          [45.168424, 38.36747],
          [45.167871, 38.367192],
          [45.154258, 38.363581],
          [45.151481, 38.362188],
          [45.150648, 38.362188],
          [45.138433, 38.353063],
          [45.10614, 38.329084],
          [45.093454, 38.321972],
        ],
      ],
    },
  },
  Bushehr: {
    properties: { value: "Bushehr", name: "استان بوشهر, ایران", id: 29 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [49.580143, 29.667443],
          [49.710257, 29.530084],
          [49.842624, 29.390154],
          [50.051949, 29.168477],
          [50.131398, 29.084213],
          [50.285706, 28.901516],
          [50.384085, 28.784869],
          [50.588131, 28.542517],
          [50.701792, 28.407276],
          [50.971351, 28.085847],
          [51.01892, 28.029025],
          [51.272406, 27.725722],
          [51.298849, 27.699037],
          [51.330161, 27.676922],
          [51.544838, 27.549153],
          [51.72273, 27.443164],
          [51.870506, 27.355041],
          [52.115046, 27.209061],
          [52.242959, 27.132625],
          [52.482398, 26.989407],
          [52.737921, 27.274307],
          [52.789951, 27.311903],
          [52.792139, 27.311762],
          [52.805162, 27.318487],
          [52.809259, 27.32037],
          [52.816567, 27.323728],
          [52.819876, 27.321198],
          [52.823991, 27.319775],
          [52.82724, 27.322004],
          [52.828442, 27.324213],
          [52.832561, 27.322423],
          [52.838715, 27.321752],
          [52.842046, 27.317391],
          [52.845225, 27.310063],
          [52.847172, 27.308957],
          [52.847728, 27.308957],
          [52.848005, 27.308678],
          [52.848282, 27.308957],
          [52.848838, 27.308957],
          [52.849115, 27.308678],
          [52.850505, 27.308678],
          [52.854115, 27.30562],
          [52.854948, 27.305063],
          [52.854948, 27.304793],
          [52.855228, 27.304514],
          [52.856338, 27.302014],
          [52.855505, 27.297841],
          [52.861338, 27.293399],
          [52.863838, 27.292283],
          [52.864395, 27.292284],
          [52.867451, 27.295341],
          [52.868005, 27.29562],
          [52.873005, 27.29562],
          [52.873285, 27.295341],
          [52.876895, 27.295341],
          [52.877451, 27.29562],
          [52.878004, 27.295341],
          [52.879394, 27.295341],
          [52.882728, 27.293399],
          [52.882728, 27.29312],
          [52.884118, 27.29312],
          [52.884394, 27.292841],
          [52.885228, 27.292841],
          [52.885228, 27.29312],
          [52.886061, 27.29312],
          [52.896341, 27.301178],
          [52.896618, 27.301735],
          [52.921897, 27.318957],
          [52.94162, 27.328679],
          [52.94162, 27.328949],
          [52.94273, 27.328949],
          [52.94273, 27.329237],
          [52.943287, 27.329237],
          [52.943843, 27.329516],
          [52.943843, 27.329785],
          [52.930787, 27.339229],
          [52.928007, 27.34118],
          [52.917731, 27.349509],
          [52.902451, 27.370626],
          [52.893008, 27.384235],
          [52.889674, 27.389235],
          [52.886061, 27.395073],
          [52.885784, 27.395343],
          [52.885784, 27.395815],
          [52.880504, 27.401738],
          [52.847728, 27.425349],
          [52.846895, 27.425628],
          [52.845225, 27.427292],
          [52.845225, 27.42757],
          [52.844672, 27.427849],
          [52.844369, 27.42815],
          [52.828282, 27.442016],
          [52.817312, 27.448598],
          [52.808836, 27.453683],
          [52.808836, 27.453962],
          [52.800779, 27.46007],
          [52.798836, 27.461455],
          [52.788836, 27.467851],
          [52.775779, 27.480903],
          [52.774669, 27.483125],
          [52.768556, 27.489512],
          [52.768017, 27.490331],
          [52.758, 27.500064],
          [52.754946, 27.502295],
          [52.754666, 27.502853],
          [52.746057, 27.507297],
          [52.745223, 27.507297],
          [52.742347, 27.508473],
          [52.73911, 27.509798],
          [52.73689, 27.510625],
          [52.73689, 27.510904],
          [52.687997, 27.536741],
          [52.687721, 27.537011],
          [52.687441, 27.53729],
          [52.687164, 27.53729],
          [52.685497, 27.538684],
          [52.666884, 27.548409],
          [52.660218, 27.551738],
          [52.647718, 27.557568],
          [52.640775, 27.559511],
          [52.640495, 27.55979],
          [52.637162, 27.55979],
          [52.636605, 27.560069],
          [52.635495, 27.560069],
          [52.634662, 27.560348],
          [52.633552, 27.560348],
          [52.632995, 27.560627],
          [52.631885, 27.560627],
          [52.629938, 27.561185],
          [52.629105, 27.561184],
          [52.623828, 27.563407],
          [52.613358, 27.571947],
          [52.602715, 27.580627],
          [52.601882, 27.581742],
          [52.597439, 27.586457],
          [52.563549, 27.624245],
          [52.563269, 27.624515],
          [52.551046, 27.648682],
          [52.551046, 27.648961],
          [52.55077, 27.64924],
          [52.550769, 27.649519],
          [52.548546, 27.653685],
          [52.542713, 27.663124],
          [52.54188, 27.665077],
          [52.54188, 27.665356],
          [52.541603, 27.665626],
          [52.541603, 27.665905],
          [52.541603, 27.666183],
          [52.541046, 27.66702],
          [52.54077, 27.667848],
          [52.54077, 27.668964],
          [52.527713, 27.704795],
          [52.527713, 27.705353],
          [52.527156, 27.705902],
          [52.527156, 27.70646],
          [52.526603, 27.707297],
          [52.513547, 27.732574],
          [52.51049, 27.742014],
          [52.509933, 27.743967],
          [52.509627, 27.746578],
          [52.50938, 27.747575],
          [52.508823, 27.752021],
          [52.508547, 27.757294],
          [52.508267, 27.762577],
          [52.5066, 27.775635],
          [52.5016, 27.786182],
          [52.5016, 27.78647],
          [52.501043, 27.787298],
          [52.486877, 27.809519],
          [52.464097, 27.837302],
          [52.458544, 27.846185],
          [52.458264, 27.846464],
          [52.457988, 27.847022],
          [52.455211, 27.855077],
          [52.455211, 27.856184],
          [52.449931, 27.871746],
          [52.449654, 27.872025],
          [52.442988, 27.883969],
          [52.442431, 27.885076],
          [52.441598, 27.885913],
          [52.436598, 27.895076],
          [52.436321, 27.895634],
          [52.427151, 27.912295],
          [52.407152, 27.940352],
          [52.406719, 27.940786],
          [52.402985, 27.947022],
          [52.402708, 27.947301],
          [52.401595, 27.948409],
          [52.393262, 27.96841],
          [52.391872, 27.97536],
          [52.391595, 27.977583],
          [52.391318, 27.979249],
          [52.391038, 27.980914],
          [52.390485, 27.983975],
          [52.390205, 27.985631],
          [52.389928, 27.987305],
          [52.389652, 27.988133],
          [52.389372, 27.98925],
          [52.387985, 27.993139],
          [52.387557, 27.993546],
          [52.382152, 27.998693],
          [52.380205, 28.001475],
          [52.378262, 28.003689],
          [52.377429, 28.004247],
          [52.376039, 28.005913],
          [52.368539, 28.012304],
          [52.364649, 28.014807],
          [52.364095, 28.014807],
          [52.363262, 28.015077],
          [52.339373, 28.022306],
          [52.335202, 28.023134],
          [52.333259, 28.023134],
          [52.31076, 28.027023],
          [52.30937, 28.027023],
          [52.30548, 28.027861],
          [52.30437, 28.027861],
          [52.302703, 28.028419],
          [52.301593, 28.028419],
          [52.29548, 28.030084],
          [52.29409, 28.030354],
          [52.283537, 28.035081],
          [52.278537, 28.037304],
          [52.2652, 28.044245],
          [52.234087, 28.072587],
          [52.233254, 28.073415],
          [52.229921, 28.079807],
          [52.220754, 28.091187],
          [52.220478, 28.091754],
          [52.217421, 28.095355],
          [52.199088, 28.111472],
          [52.179085, 28.123419],
          [52.170752, 28.130082],
          [52.160195, 28.143696],
          [52.159085, 28.144803],
          [52.158252, 28.145641],
          [52.143529, 28.170078],
          [52.141477, 28.174182],
          [52.141306, 28.174526],
          [52.141306, 28.174805],
          [52.125472, 28.199531],
          [52.121862, 28.211192],
          [52.121582, 28.213416],
          [52.121306, 28.220359],
          [52.121582, 28.22397],
          [52.121863, 28.228976],
          [52.123529, 28.258702],
          [52.123249, 28.25981],
          [52.122972, 28.262304],
          [52.112696, 28.294535],
          [52.112139, 28.295084],
          [52.111306, 28.297308],
          [52.106026, 28.306476],
          [52.104083, 28.310646],
          [52.097139, 28.324812],
          [52.080843, 28.363846],
          [52.05908, 28.415922],
          [52.056027, 28.422587],
          [52.055823, 28.423069],
          [52.05408, 28.428423],
          [52.05408, 28.429531],
          [52.05408, 28.42981],
          [52.051303, 28.440925],
          [52.051023, 28.441754],
          [52.05019, 28.446474],
          [52.049637, 28.450086],
          [52.04908, 28.453977],
          [52.048523, 28.457589],
          [52.04797, 28.460922],
          [52.043804, 28.486755],
          [52.03797, 28.51731],
          [52.037413, 28.521481],
          [52.03658, 28.528147],
          [52.036304, 28.529255],
          [52.036023, 28.531201],
          [52.03547, 28.5412],
          [52.021024, 28.598981],
          [52.020747, 28.601477],
          [52.02019, 28.603152],
          [52.019914, 28.605648],
          [52.013524, 28.630928],
          [52.0113, 28.63482],
          [52.010744, 28.635099],
          [52.010467, 28.636207],
          [52.01019, 28.637036],
          [52.009634, 28.638144],
          [52.009357, 28.638982],
          [52.009357, 28.639261],
          [52.007411, 28.645928],
          [52.007134, 28.646478],
          [52.001024, 28.6651],
          [52.001024, 28.665929],
          [51.999357, 28.669812],
          [51.999077, 28.671479],
          [51.99741, 28.67593],
          [51.993521, 28.688985],
          [51.993244, 28.690373],
          [51.993244, 28.690931],
          [51.993244, 28.691202],
          [51.992967, 28.69176],
          [51.992967, 28.69204],
          [51.992967, 28.69704],
          [51.992687, 28.69759],
          [51.981021, 28.743983],
          [51.980744, 28.744542],
          [51.980744, 28.74565],
          [51.978801, 28.757039],
          [51.974354, 28.779539],
          [51.970943, 28.789777],
          [51.960464, 28.821206],
          [51.959078, 28.823432],
          [51.944355, 28.838147],
          [51.943241, 28.839264],
          [51.932131, 28.852043],
          [51.923518, 28.864542],
          [51.906575, 28.89593],
          [51.896295, 28.922317],
          [51.896295, 28.922596],
          [51.896019, 28.923155],
          [51.895185, 28.925372],
          [51.895185, 28.926489],
          [51.888795, 28.9451],
          [51.888239, 28.945929],
          [51.887962, 28.947317],
          [51.876849, 28.961764],
          [51.867406, 28.979825],
          [51.847458, 28.996225],
          [51.842683, 29.000158],
          [51.837403, 29.005377],
          [51.836016, 29.007044],
          [51.835183, 29.007324],
          [51.833516, 29.008991],
          [51.79407, 29.044548],
          [51.794623, 29.046765],
          [51.794903, 29.047324],
          [51.81296, 29.065378],
          [51.815179, 29.068163],
          [51.81657, 29.076492],
          [51.816293, 29.076771],
          [51.81546, 29.085659],
          [51.813793, 29.088435],
          [51.813594, 29.08903],
          [51.81268, 29.090661],
          [51.81268, 29.09177],
          [51.81268, 29.092329],
          [51.81268, 29.092599],
          [51.81018, 29.098161],
          [51.809903, 29.098431],
          [51.809346, 29.099549],
          [51.807127, 29.102604],
          [51.80657, 29.103713],
          [51.795457, 29.115098],
          [51.760734, 29.138156],
          [51.75851, 29.139553],
          [51.726287, 29.17232],
          [51.725843, 29.172551],
          [51.724621, 29.173997],
          [51.724064, 29.174267],
          [51.720178, 29.181488],
          [51.711841, 29.192323],
          [51.711288, 29.193162],
          [51.711008, 29.193441],
          [51.710769, 29.193759],
          [51.710174, 29.19455],
          [51.709621, 29.19483],
          [51.709341, 29.19538],
          [51.708231, 29.195938],
          [51.677675, 29.229825],
          [51.677118, 29.230943],
          [51.676565, 29.231214],
          [51.676008, 29.232322],
          [51.674061, 29.23427],
          [51.666842, 29.245106],
          [51.666008, 29.245935],
          [51.665452, 29.247044],
          [51.662952, 29.250659],
          [51.635172, 29.288722],
          [51.634895, 29.289551],
          [51.633782, 29.29066],
          [51.633505, 29.291499],
          [51.632672, 29.292328],
          [51.622116, 29.312604],
          [51.621839, 29.313722],
          [51.620729, 29.317329],
          [51.592669, 29.382605],
          [51.592669, 29.382884],
          [51.592129, 29.383484],
          [51.590169, 29.385661],
          [51.588503, 29.388997],
          [51.587116, 29.391215],
          [51.587116, 29.391495],
          [51.586559, 29.391495],
          [51.586003, 29.392054],
          [51.582669, 29.393163],
          [51.581003, 29.393163],
          [51.581003, 29.393442],
          [51.579613, 29.393442],
          [51.579336, 29.393722],
          [51.577669, 29.393722],
          [51.577113, 29.393442],
          [51.574613, 29.39511],
          [51.574613, 29.395669],
          [51.574336, 29.39594],
          [51.571836, 29.400385],
          [51.569893, 29.400385],
          [51.569613, 29.400665],
          [51.56878, 29.400665],
          [51.567113, 29.404271],
          [51.56656, 29.404551],
          [51.56628, 29.40511],
          [51.56017, 29.408446],
          [51.559613, 29.408996],
          [51.557113, 29.409834],
          [51.555723, 29.410105],
          [51.554613, 29.410943],
          [51.553503, 29.411773],
          [51.551837, 29.414271],
          [51.552947, 29.421222],
          [51.55267, 29.421772],
          [51.55239, 29.42427],
          [51.551556, 29.425388],
          [51.53989, 29.433999],
          [51.531557, 29.438165],
          [51.530447, 29.438165],
          [51.52989, 29.438444],
          [51.524057, 29.438444],
          [51.523777, 29.438724],
          [51.522667, 29.438724],
          [51.52211, 29.439003],
          [51.521, 29.439003],
          [51.519334, 29.439833],
          [51.517944, 29.440942],
          [51.517944, 29.442051],
          [51.517667, 29.44233],
          [51.51739, 29.445108],
          [51.51711, 29.446226],
          [51.511834, 29.451221],
          [51.5085, 29.452889],
          [51.507667, 29.452889],
          [51.50739, 29.453169],
          [51.505444, 29.453169],
          [51.505167, 29.452889],
          [51.504334, 29.452889],
          [51.503777, 29.45261],
          [51.502387, 29.45261],
          [51.490444, 29.448723],
          [51.489887, 29.448444],
          [51.489611, 29.448723],
          [51.488501, 29.448723],
          [51.484611, 29.450662],
          [51.483497, 29.453719],
          [51.483221, 29.454557],
          [51.483221, 29.454828],
          [51.483221, 29.455946],
          [51.485721, 29.460112],
          [51.485997, 29.461221],
          [51.484611, 29.46233],
          [51.484054, 29.462609],
          [51.482387, 29.462609],
          [51.481278, 29.46178],
          [51.479331, 29.46178],
          [51.478778, 29.4615],
          [51.477664, 29.4615],
          [51.474611, 29.462889],
          [51.474611, 29.462609],
          [51.469888, 29.462609],
          [51.466275, 29.46178],
          [51.465721, 29.46178],
          [51.464331, 29.46178],
          [51.459888, 29.46178],
          [51.459331, 29.4615],
          [51.458498, 29.4615],
          [51.457388, 29.46205],
          [51.454331, 29.465666],
          [51.452941, 29.466225],
          [51.452108, 29.466225],
          [51.451555, 29.466496],
          [51.449888, 29.466225],
          [51.448221, 29.465666],
          [51.447108, 29.465666],
          [51.445998, 29.466496],
          [51.444332, 29.469003],
          [51.444051, 29.469553],
          [51.444331, 29.470662],
          [51.442108, 29.473998],
          [51.437942, 29.473998],
          [51.437385, 29.473718],
          [51.436275, 29.473998],
          [51.435998, 29.473718],
          [51.433498, 29.473718],
          [51.427662, 29.475107],
          [51.422662, 29.473439],
          [51.412385, 29.462609],
          [51.408772, 29.46178],
          [51.407939, 29.46205],
          [51.406829, 29.462889],
          [51.404052, 29.463998],
          [51.401829, 29.463998],
          [51.401272, 29.464278],
          [51.400995, 29.463998],
          [51.400439, 29.464278],
          [51.397939, 29.464278],
          [51.397386, 29.463998],
          [51.396272, 29.463998],
          [51.395995, 29.463718],
          [51.394885, 29.463719],
          [51.391549, 29.4615],
          [51.389882, 29.4615],
          [51.385162, 29.464278],
          [51.382662, 29.469002],
          [51.381549, 29.470941],
          [51.379329, 29.47178],
          [51.376829, 29.47178],
          [51.375996, 29.4715],
          [51.375439, 29.4715],
          [51.368772, 29.464557],
          [51.367659, 29.463998],
          [51.366826, 29.463998],
          [51.365716, 29.463439],
          [51.364606, 29.463439],
          [51.351999, 29.473467],
          [51.330713, 29.490391],
          [51.330436, 29.490941],
          [51.32988, 29.490941],
          [51.329603, 29.4915],
          [51.32766, 29.492609],
          [51.32738, 29.492889],
          [51.326547, 29.493168],
          [51.320993, 29.497614],
          [51.304323, 29.517885],
          [51.302937, 29.519833],
          [51.297937, 29.530113],
          [51.29738, 29.530663],
          [51.27849, 29.582614],
          [51.278214, 29.584003],
          [51.277657, 29.587339],
          [51.277657, 29.58761],
          [51.277377, 29.588169],
          [51.273085, 29.599433],
          [51.272412, 29.601198],
          [51.27071, 29.605664],
          [51.270434, 29.607332],
          [51.270157, 29.608171],
          [51.269877, 29.60983],
          [51.270434, 29.614835],
          [51.274044, 29.625946],
          [51.28488, 29.641782],
          [51.28738, 29.64483],
          [51.296547, 29.658448],
          [51.299603, 29.678443],
          [51.299323, 29.679002],
          [51.297937, 29.684557],
          [51.297937, 29.685116],
          [51.29738, 29.685667],
          [51.2971, 29.686505],
          [51.288072, 29.694123],
          [51.28738, 29.694839],
          [51.286823, 29.695109],
          [51.286547, 29.695398],
          [51.278214, 29.700665],
          [51.262377, 29.707339],
          [51.250711, 29.709278],
          [51.250434, 29.709557],
          [51.249044, 29.709557],
          [51.248767, 29.709837],
          [51.247377, 29.709837],
          [51.246821, 29.710117],
          [51.245154, 29.710117],
          [51.244601, 29.710396],
          [51.243767, 29.710396],
          [51.240987, 29.710676],
          [51.234598, 29.710946],
          [51.234044, 29.711226],
          [51.227931, 29.711226],
          [51.226821, 29.710946],
          [51.224044, 29.710946],
          [51.223488, 29.711226],
          [51.216821, 29.711226],
          [51.215988, 29.711505],
          [51.212098, 29.711505],
          [51.205155, 29.712056],
          [51.203765, 29.712335],
          [51.199875, 29.712335],
          [51.198485, 29.712615],
          [51.189598, 29.712615],
          [51.177375, 29.713724],
          [51.174318, 29.714283],
          [51.172652, 29.714283],
          [51.165428, 29.716502],
          [51.162372, 29.717611],
          [51.113759, 29.740944],
          [51.09376, 29.749836],
          [51.09015, 29.750946],
          [51.051257, 29.770951],
          [51.039867, 29.774279],
          [51.03959, 29.774558],
          [51.038757, 29.774558],
          [51.0382, 29.774558],
          [51.037647, 29.774838],
          [51.034867, 29.775668],
          [51.03459, 29.775947],
          [51.033757, 29.775947],
          [51.0332, 29.776227],
          [51.03181, 29.776227],
          [51.031534, 29.776507],
          [51.0257, 29.776507],
          [51.022644, 29.776777],
          [51.019591, 29.777336],
          [51.019034, 29.777616],
          [51.018201, 29.777616],
          [51.017924, 29.777895],
          [51.017367, 29.777895],
          [50.999868, 29.782892],
          [50.971255, 29.782062],
          [50.968475, 29.782062],
          [50.967365, 29.781783],
          [50.961808, 29.781783],
          [50.947642, 29.780953],
          [50.945142, 29.780673],
          [50.936252, 29.780673],
          [50.934585, 29.780394],
          [50.931809, 29.780394],
          [50.930142, 29.780114],
          [50.927362, 29.780114],
          [50.872916, 29.774558],
          [50.859026, 29.774558],
          [50.857916, 29.774279],
          [50.852083, 29.774279],
          [50.847636, 29.774008],
          [50.844303, 29.773449],
          [50.83736, 29.773449],
          [50.836527, 29.773728],
          [50.83458, 29.773728],
          [50.827637, 29.775117],
          [50.82683, 29.775437],
          [50.826247, 29.775668],
          [50.825694, 29.775947],
          [50.822637, 29.775947],
          [50.82069, 29.775397],
          [50.820137, 29.775668],
          [50.81958, 29.775397],
          [50.81847, 29.775667],
          [50.815414, 29.775397],
          [50.801801, 29.780114],
          [50.800691, 29.780114],
          [50.800134, 29.780394],
          [50.798747, 29.780394],
          [50.79819, 29.780673],
          [50.791247, 29.780673],
          [50.787914, 29.781223],
          [50.782911, 29.781783],
          [50.780411, 29.781783],
          [50.779024, 29.782062],
          [50.775134, 29.782062],
          [50.773744, 29.781783],
          [50.765411, 29.781783],
          [50.752635, 29.782892],
          [50.751244, 29.783172],
          [50.748465, 29.783172],
          [50.745688, 29.783451],
          [50.732355, 29.786509],
          [50.730408, 29.787339],
          [50.703742, 29.791506],
          [50.668462, 29.804836],
          [50.665686, 29.805675],
          [50.64763, 29.817058],
          [50.644849, 29.822064],
          [50.644016, 29.825392],
          [50.644016, 29.827061],
          [50.644573, 29.82873],
          [50.651516, 29.834566],
          [50.654296, 29.835395],
          [50.655962, 29.835395],
          [50.656519, 29.835675],
          [50.660129, 29.835675],
          [50.660686, 29.836225],
          [50.660686, 29.837064],
          [50.660406, 29.837344],
          [50.661239, 29.839003],
          [50.662072, 29.839003],
          [50.662629, 29.838724],
          [50.663462, 29.838724],
          [50.664296, 29.838173],
          [50.665686, 29.838173],
          [50.666519, 29.838724],
          [50.665962, 29.839283],
          [50.665129, 29.839283],
          [50.664572, 29.839842],
          [50.664852, 29.841502],
          [50.667072, 29.841231],
          [50.667629, 29.841502],
          [50.668186, 29.844009],
          [50.667072, 29.84456],
          [50.665962, 29.845678],
          [50.665406, 29.845949],
          [50.665686, 29.847067],
          [50.668186, 29.847338],
          [50.669019, 29.847067],
          [50.670686, 29.848456],
          [50.670962, 29.850395],
          [50.670406, 29.852344],
          [50.669019, 29.854283],
          [50.669572, 29.854842],
          [50.671519, 29.854842],
          [50.672072, 29.854563],
          [50.672906, 29.854563],
          [50.673186, 29.854842],
          [50.674019, 29.854842],
          [50.675409, 29.856231],
          [50.675129, 29.856511],
          [50.674852, 29.857341],
          [50.674576, 29.85845],
          [50.674296, 29.860669],
          [50.675129, 29.862897],
          [50.675962, 29.863177],
          [50.679019, 29.861788],
          [50.679852, 29.860949],
          [50.680409, 29.860669],
          [50.680962, 29.861788],
          [50.681519, 29.864007],
          [50.682909, 29.865675],
          [50.683742, 29.865116],
          [50.684295, 29.865116],
          [50.684852, 29.864566],
          [50.683742, 29.860669],
          [50.685128, 29.85928],
          [50.687352, 29.85928],
          [50.689852, 29.862338],
          [50.694019, 29.861228],
          [50.694575, 29.861508],
          [50.695409, 29.861508],
          [50.700685, 29.866785],
          [50.703465, 29.866785],
          [50.703465, 29.866505],
          [50.704575, 29.865955],
          [50.705685, 29.865396],
          [50.706242, 29.864837],
          [50.708742, 29.864836],
          [50.712632, 29.866226],
          [50.713742, 29.873171],
          [50.713465, 29.873451],
          [50.696242, 29.887612],
          [50.680129, 29.897616],
          [50.660406, 29.914845],
          [50.660129, 29.915115],
          [50.658186, 29.917343],
          [50.657353, 29.919003],
          [50.654849, 29.92373],
          [50.648182, 29.932895],
          [50.645682, 29.935402],
          [50.642349, 29.937342],
          [50.634293, 29.93929],
          [50.630683, 29.93929],
          [50.62874, 29.939561],
          [50.627906, 29.93984],
          [50.626793, 29.93984],
          [50.625959, 29.94012],
          [50.62485, 29.94012],
          [50.614293, 29.943449],
          [50.611793, 29.943449],
          [50.611236, 29.943728],
          [50.609016, 29.943728],
          [50.60846, 29.944008],
          [50.605403, 29.944008],
          [50.60457, 29.943728],
          [50.603183, 29.943728],
          [50.602903, 29.943449],
          [50.602627, 29.942069],
          [50.585127, 29.929286],
          [50.57929, 29.926228],
          [50.57818, 29.925398],
          [50.576237, 29.924839],
          [50.575404, 29.924839],
          [50.574847, 29.92456],
          [50.573737, 29.92456],
          [50.569847, 29.927058],
          [50.568737, 29.928177],
          [50.562904, 29.934013],
          [50.55568, 29.947896],
          [50.555401, 29.949014],
          [50.539844, 29.965955],
          [50.539291, 29.966785],
          [50.538734, 29.967065],
          [50.535678, 29.972622],
          [50.534291, 29.97623],
          [50.534011, 29.979008],
          [50.535124, 29.991791],
          [50.53482, 29.992891],
          [50.532624, 30.001786],
          [50.532344, 30.003175],
          [50.532067, 30.004014],
          [50.529844, 30.009842],
          [50.529011, 30.012061],
          [50.528457, 30.01456],
          [50.528457, 30.015399],
          [50.528177, 30.017348],
          [50.527901, 30.018177],
          [50.527624, 30.020397],
          [50.53929, 30.07318],
          [50.542624, 30.082346],
          [50.544847, 30.085675],
          [50.547624, 30.089013],
          [50.54818, 30.089843],
          [50.557347, 30.09596],
          [50.558734, 30.097069],
          [50.559567, 30.09734],
          [50.5604, 30.098179],
          [50.562624, 30.099289],
          [50.574846, 30.112623],
          [50.575403, 30.113733],
          [50.57679, 30.124568],
          [50.57707, 30.125398],
          [50.57679, 30.125957],
          [50.576513, 30.127347],
          [50.576237, 30.128177],
          [50.575403, 30.130676],
          [50.57457, 30.132895],
          [50.57179, 30.137902],
          [50.565403, 30.14401],
          [50.561237, 30.146229],
          [50.560476, 30.146713],
          [50.535321, 30.15311],
          [50.51487, 30.161662],
          [50.513893, 30.166061],
          [50.509071, 30.166158],
          [50.504421, 30.166118],
          [50.503083, 30.166057],
          [50.500351, 30.165933],
          [50.483677, 30.166209],
          [50.47683, 30.167037],
          [50.473194, 30.167521],
          [50.469357, 30.168469],
          [50.462879, 30.169569],
          [50.456196, 30.171134],
          [50.448303, 30.173122],
          [50.442204, 30.17473],
          [50.436729, 30.176058],
          [50.430252, 30.177575],
          [50.422943, 30.18007],
          [50.416576, 30.18219],
          [50.406063, 30.186785],
          [50.398781, 30.190219],
          [50.397022, 30.191049],
          [50.38829, 30.19508],
          [50.379929, 30.199064],
          [50.369873, 30.203571],
          [50.364819, 30.205917],
          [50.357861, 30.208649],
          [50.351166, 30.211378],
          [50.348217, 30.212647],
          [50.340092, 30.215618],
          [50.330967, 30.219062],
          [50.323948, 30.221751],
          [50.318615, 30.223686],
          [50.31107, 30.227721],
          [50.291855, 30.237493],
          [50.283505, 30.241725],
          [50.275898, 30.246089],
          [50.269507, 30.249961],
          [50.264118, 30.253707],
          [50.260243, 30.256629],
          [50.25647, 30.259367],
          [50.252697, 30.262664],
          [50.250024, 30.264237],
          [50.247303, 30.26495],
          [50.242928, 30.26647],
          [50.239399, 30.267954],
          [50.235497, 30.269662],
          [50.231804, 30.271423],
          [50.227058, 30.274003],
          [50.221519, 30.277358],
          [50.21624, 30.280905],
          [50.2118, 30.283998],
          [50.207413, 30.287604],
          [50.203396, 30.291211],
          [50.202481, 30.292189],
          [50.200695, 30.294096],
          [50.200007, 30.294962],
          [50.199272, 30.293106],
          [50.198806, 30.292025],
          [50.197872, 30.289857],
          [50.197548, 30.289107],
          [50.195145, 30.283477],
          [50.193416, 30.279615],
          [50.189087, 30.268916],
          [50.186436, 30.260967],
          [50.184666, 30.256133],
          [50.182896, 30.251202],
          [50.182551, 30.250137],
          [50.181457, 30.246417],
          [50.180569, 30.243143],
          [50.17936, 30.239778],
          [50.177616, 30.23577],
          [50.175926, 30.231899],
          [50.174343, 30.228167],
          [50.173203, 30.225816],
          [50.171898, 30.222911],
          [50.170162, 30.219317],
          [50.168812, 30.216922],
          [50.165993, 30.211303],
          [50.163403, 30.206695],
          [50.160545, 30.201951],
          [50.158016, 30.198036],
          [50.155228, 30.194307],
          [50.149551, 30.187264],
          [50.143993, 30.181048],
          [50.139034, 30.175944],
          [50.132484, 30.169916],
          [50.129292, 30.167111],
          [50.125939, 30.163705],
          [50.123432, 30.160803],
          [50.120827, 30.15855],
          [50.118912, 30.15657],
          [50.115551, 30.153554],
          [50.113946, 30.1521],
          [49.580143, 29.667443],
        ],
      ],
    },
  },
  ChaharMahallvaBakhtiari: {
    properties: {
      value: "ChaharMahallvaBakhtiari",
      name: "استان چهارمحال و بختیاری, ایران",
      id: 31,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [49.500077, 32.703093],
          [49.500565, 32.70295],
          [49.501919, 32.701789],
          [49.503384, 32.700542],
          [49.505009, 32.698741],
          [49.506798, 32.696846],
          [49.508636, 32.694814],
          [49.510262, 32.693099],
          [49.512428, 32.690421],
          [49.514374, 32.687558],
          [49.516048, 32.684971],
          [49.516643, 32.684446],
          [49.52076, 32.680806],
          [49.525955, 32.676179],
          [49.533798, 32.669006],
          [49.537044, 32.666092],
          [49.541962, 32.661141],
          [49.542446, 32.659942],
          [49.543797, 32.658966],
          [49.545853, 32.657623],
          [49.547474, 32.65628],
          [49.55045, 32.65392],
          [49.552284, 32.652065],
          [49.554012, 32.650216],
          [49.55563, 32.648227],
          [49.55703, 32.646054],
          [49.557835, 32.645039],
          [49.559132, 32.643742],
          [49.560047, 32.642539],
          [49.561504, 32.641151],
          [49.563282, 32.638885],
          [49.564953, 32.63671],
          [49.568036, 32.63314],
          [49.571308, 32.629353],
          [49.572979, 32.627457],
          [49.574055, 32.626069],
          [49.57502, 32.624497],
          [49.576149, 32.622647],
          [49.57679, 32.621121],
          [49.578293, 32.618946],
          [49.579151, 32.61756],
          [49.580498, 32.616031],
          [49.581898, 32.614552],
          [49.583298, 32.613068],
          [49.584965, 32.61131],
          [49.586849, 32.609227],
          [49.588898, 32.607604],
          [49.59008, 32.606492],
          [49.590836, 32.60561],
          [49.592125, 32.604454],
          [49.593311, 32.60325],
          [49.595463, 32.601395],
          [49.59713, 32.599727],
          [49.599228, 32.597687],
          [49.601868, 32.595324],
          [49.603428, 32.594257],
          [49.60521, 32.593093],
          [49.607582, 32.591884],
          [49.609631, 32.590492],
          [49.611351, 32.589145],
          [49.614094, 32.586826],
          [49.617214, 32.584183],
          [49.62146, 32.580476],
          [49.625492, 32.576995],
          [49.628876, 32.573749],
          [49.632046, 32.570782],
          [49.633545, 32.569157],
          [49.635742, 32.566425],
          [49.638794, 32.562672],
          [49.642437, 32.559009],
          [49.646515, 32.555253],
          [49.650429, 32.551633],
          [49.653805, 32.548386],
          [49.65799, 32.544766],
          [49.661362, 32.541332],
          [49.66291, 32.539292],
          [49.664456, 32.536977],
          [49.665905, 32.535862],
          [49.66848, 32.534047],
          [49.670628, 32.532699],
          [49.672939, 32.53135],
          [49.674607, 32.530601],
          [49.676216, 32.529671],
          [49.6782, 32.528366],
          [49.679916, 32.527019],
          [49.682224, 32.525436],
          [49.684155, 32.524227],
          [49.686409, 32.523015],
          [49.68953, 32.521798],
          [49.691677, 32.520537],
          [49.694363, 32.519093],
          [49.697846, 32.51686],
          [49.701874, 32.514759],
          [49.703102, 32.513782],
          [49.704338, 32.512992],
          [49.705513, 32.511834],
          [49.707272, 32.510066],
          [49.709025, 32.508079],
          [49.711163, 32.505657],
          [49.715221, 32.501804],
          [49.717481, 32.499608],
          [49.717706, 32.49941],
          [49.723385, 32.494418],
          [49.727341, 32.490643],
          [49.729244, 32.488612],
          [49.731205, 32.486634],
          [49.73251, 32.484604],
          [49.736046, 32.480594],
          [49.738167, 32.47857],
          [49.740452, 32.476356],
          [49.742524, 32.474455],
          [49.746201, 32.47177],
          [49.749868, 32.469809],
          [49.754074, 32.467332],
          [49.757443, 32.464954],
          [49.762653, 32.460789],
          [49.767084, 32.457621],
          [49.767265, 32.457492],
          [49.770741, 32.455162],
          [49.774593, 32.452228],
          [49.777199, 32.449939],
          [49.779751, 32.447738],
          [49.782139, 32.445633],
          [49.784527, 32.443153],
          [49.787731, 32.43962],
          [49.790341, 32.435992],
          [49.790989, 32.435261],
          [49.791969, 32.433972],
          [49.795662, 32.429012],
          [49.799355, 32.424465],
          [49.803154, 32.419229],
          [49.805, 32.41721],
          [49.806953, 32.41542],
          [49.810425, 32.412625],
          [49.814549, 32.409321],
          [49.816879, 32.40684],
          [49.819973, 32.403396],
          [49.822143, 32.400914],
          [49.824856, 32.397931],
          [49.828274, 32.393375],
          [49.832562, 32.387448],
          [49.835926, 32.382385],
          [49.838581, 32.379261],
          [49.840592, 32.376869],
          [49.843083, 32.374339],
          [49.846176, 32.371585],
          [49.852085, 32.366206],
          [49.855988, 32.362762],
          [49.858749, 32.360557],
          [49.864601, 32.356469],
          [49.86818, 32.354034],
          [49.871376, 32.35197],
          [49.872623, 32.350865],
          [49.873436, 32.3499],
          [49.875008, 32.347966],
          [49.876066, 32.34664],
          [49.876957, 32.345527],
          [49.878294, 32.344085],
          [49.879559, 32.34272],
          [49.881889, 32.340003],
          [49.884491, 32.337334],
          [49.886658, 32.335632],
          [49.888824, 32.333793],
          [49.891209, 32.331908],
          [49.892941, 32.330852],
          [49.893865, 32.330268],
          [49.895271, 32.32938],
          [49.897652, 32.328278],
          [49.898953, 32.32713],
          [49.900524, 32.325932],
          [49.902096, 32.32529],
          [49.903828, 32.323726],
          [49.905884, 32.322208],
          [49.90789, 32.320275],
          [49.90984, 32.317786],
          [49.911518, 32.316082],
          [49.913361, 32.3135],
          [49.914768, 32.311149],
          [49.915688, 32.308938],
          [49.91634, 32.307414],
          [49.917477, 32.305985],
          [49.91856, 32.303636],
          [49.919537, 32.301696],
          [49.920842, 32.298983],
          [49.920891, 32.298882],
          [49.921864, 32.296436],
          [49.923328, 32.292241],
          [49.92506, 32.287441],
          [49.926037, 32.28421],
          [49.926144, 32.282779],
          [49.92609, 32.280792],
          [49.926037, 32.279224],
          [49.925873, 32.277653],
          [49.925877, 32.276776],
          [49.925877, 32.275806],
          [49.92609, 32.273961],
          [49.926254, 32.272758],
          [49.926632, 32.271697],
          [49.927502, 32.268421],
          [49.927879, 32.266945],
          [49.927879, 32.266255],
          [49.928043, 32.265376],
          [49.928314, 32.264312],
          [49.928638, 32.2627],
          [49.928909, 32.261315],
          [49.929287, 32.259235],
          [49.929558, 32.257712],
          [49.929287, 32.256233],
          [49.929233, 32.255173],
          [49.928856, 32.253925],
          [49.928909, 32.25282],
          [49.928749, 32.251892],
          [49.928967, 32.251111],
          [49.928967, 32.250092],
          [49.928482, 32.24707],
          [49.928475, 32.243755],
          [49.928848, 32.240071],
          [49.929543, 32.234538],
          [49.92997, 32.230347],
          [49.93034, 32.224773],
          [49.931036, 32.217771],
          [49.931137, 32.216758],
          [49.931454, 32.212151],
          [49.931725, 32.210769],
          [49.931667, 32.209756],
          [49.931881, 32.208005],
          [49.932095, 32.206162],
          [49.932037, 32.204136],
          [49.932251, 32.202293],
          [49.932625, 32.200082],
          [49.932892, 32.197867],
          [49.933643, 32.195061],
          [49.934181, 32.192712],
          [49.934612, 32.190773],
          [49.935097, 32.189666],
          [49.936066, 32.187642],
          [49.936821, 32.186074],
          [49.937954, 32.184737],
          [49.939953, 32.182433],
          [49.944, 32.177551],
          [49.950318, 32.170502],
          [49.964134, 32.154876],
          [49.965451, 32.153399],
          [49.973091, 32.144828],
          [49.985172, 32.130997],
          [49.988033, 32.128002],
          [49.992886, 32.122331],
          [50.00027, 32.112543],
          [50.002861, 32.111145],
          [50.00428, 32.110096],
          [50.007275, 32.10809],
          [50.010159, 32.106549],
          [50.012337, 32.105136],
          [50.014027, 32.103997],
          [50.015934, 32.102539],
          [50.017956, 32.100708],
          [50.020138, 32.09893],
          [50.023029, 32.096555],
          [50.026459, 32.094231],
          [50.030319, 32.092141],
          [50.031792, 32.091187],
          [50.034077, 32.089822],
          [50.035271, 32.089138],
          [50.037392, 32.088142],
          [50.038345, 32.087583],
          [50.039024, 32.087184],
          [50.040653, 32.086414],
          [50.042232, 32.085411],
          [50.044514, 32.084233],
          [50.045712, 32.083457],
          [50.046745, 32.082913],
          [50.047832, 32.082321],
          [50.048916, 32.081822],
          [50.050224, 32.080909],
          [50.051476, 32.080091],
          [50.051807, 32.079448],
          [50.052414, 32.07821],
          [50.052914, 32.077199],
          [50.053627, 32.07605],
          [50.054619, 32.074582],
          [50.055992, 32.072517],
          [50.057362, 32.07059],
          [50.058712, 32.068812],
          [50.058788, 32.068711],
          [50.060158, 32.067104],
          [50.061745, 32.065273],
          [50.064693, 32.062253],
          [50.066662, 32.060237],
          [50.067429, 32.059136],
          [50.068523, 32.057899],
          [50.069614, 32.056802],
          [50.070599, 32.055794],
          [50.071636, 32.054879],
          [50.072781, 32.053967],
          [50.074356, 32.052826],
          [50.076153, 32.051685],
          [50.077404, 32.050774],
          [50.078819, 32.049722],
          [50.080021, 32.048673],
          [50.081165, 32.047847],
          [50.082146, 32.047073],
          [50.083183, 32.045926],
          [50.084381, 32.04506],
          [50.085739, 32.044147],
          [50.086941, 32.043141],
          [50.089768, 32.041409],
          [50.091019, 32.040405],
          [50.092602, 32.038939],
          [50.094185, 32.037476],
          [50.0956, 32.036239],
          [50.096908, 32.034958],
          [50.098003, 32.033858],
          [50.099312, 32.032623],
          [50.100018, 32.03212],
          [50.101105, 32.031345],
          [50.101921, 32.030887],
          [50.102466, 32.03048],
          [50.103012, 32.030021],
          [50.103722, 32.029334],
          [50.104427, 32.028649],
          [50.104973, 32.028145],
          [50.105842, 32.027642],
          [50.107201, 32.026821],
          [50.108288, 32.026229],
          [50.108997, 32.025406],
          [50.109657, 32.024579],
          [50.110584, 32.023708],
          [50.111076, 32.023021],
          [50.111733, 32.022336],
          [50.113041, 32.021003],
          [50.114403, 32.019768],
          [50.115982, 32.018443],
          [50.117455, 32.017161],
          [50.118218, 32.016429],
          [50.119415, 32.015515],
          [50.120068, 32.01501],
          [50.1217, 32.013916],
          [50.122788, 32.013188],
          [50.123657, 32.012592],
          [50.124744, 32.011722],
          [50.125565, 32.011036],
          [50.12643, 32.010582],
          [50.127895, 32.009899],
          [50.12925, 32.009448],
          [50.130444, 32.008812],
          [50.131367, 32.008313],
          [50.132725, 32.007488],
          [50.134136, 32.006717],
          [50.135548, 32.005944],
          [50.137665, 32.004756],
          [50.139294, 32.0038],
          [50.1413, 32.002983],
          [50.142929, 32.002162],
          [50.145203, 32.001442],
          [50.147209, 32.000626],
          [50.147276, 32.00062],
          [50.148354, 32.00053],
          [50.152559, 32.000177],
          [50.159264, 31.997529],
          [50.165016, 31.994905],
          [50.166252, 31.994169],
          [50.168022, 31.992974],
          [50.172905, 31.989661],
          [50.176231, 31.987545],
          [50.17886, 31.985656],
          [50.181435, 31.983634],
          [50.184601, 31.981239],
          [50.1861, 31.980319],
          [50.187119, 31.979491],
          [50.188889, 31.978431],
          [50.190926, 31.97673],
          [50.196178, 31.972587],
          [50.196181, 31.972585],
          [50.20116, 31.968351],
          [50.202664, 31.967246],
          [50.20454, 31.966142],
          [50.208023, 31.963703],
          [50.211239, 31.961355],
          [50.219811, 31.955048],
          [50.222271, 31.952974],
          [50.225807, 31.950673],
          [50.226922, 31.949714],
          [50.230194, 31.946897],
          [50.234638, 31.943349],
          [50.236832, 31.941461],
          [50.237526, 31.941093],
          [50.238384, 31.940771],
          [50.239029, 31.940495],
          [50.23962, 31.940172],
          [50.239723, 31.939572],
          [50.240093, 31.938424],
          [50.24009, 31.937502],
          [50.239975, 31.936213],
          [50.239697, 31.934601],
          [50.239551, 31.933443],
          [50.239471, 31.932805],
          [50.23914, 31.931007],
          [50.238972, 31.929994],
          [50.238919, 31.929441],
          [50.238961, 31.928902],
          [50.239231, 31.928015],
          [50.239712, 31.927138],
          [50.240108, 31.926635],
          [50.240509, 31.926126],
          [50.241741, 31.924884],
          [50.243076, 31.923778],
          [50.245163, 31.922579],
          [50.246342, 31.921934],
          [50.248375, 31.920275],
          [50.24987, 31.919077],
          [50.251118, 31.918548],
          [50.251816, 31.918092],
          [50.252357, 31.91736],
          [50.252625, 31.916761],
          [50.252731, 31.916027],
          [50.252731, 31.915431],
          [50.252624, 31.914835],
          [50.252354, 31.914146],
          [50.252029, 31.913366],
          [50.251766, 31.913097],
          [50.251488, 31.912814],
          [50.250786, 31.911942],
          [50.250355, 31.911344],
          [50.249924, 31.910701],
          [50.249653, 31.910059],
          [50.24976, 31.909324],
          [50.25003, 31.908635],
          [50.250622, 31.908131],
          [50.251324, 31.907353],
          [50.251676, 31.907085],
          [50.252346, 31.906574],
          [50.253315, 31.905657],
          [50.254395, 31.904834],
          [50.255257, 31.904052],
          [50.256439, 31.903046],
          [50.257519, 31.9019],
          [50.258488, 31.900708],
          [50.259133, 31.899974],
          [50.260048, 31.899104],
          [50.260675, 31.898606],
          [50.260857, 31.898462],
          [50.261719, 31.897774],
          [50.263311, 31.897002],
          [50.263607, 31.896858],
          [50.2649, 31.896356],
          [50.265816, 31.895669],
          [50.266604, 31.894998],
          [50.266892, 31.894753],
          [50.267647, 31.893972],
          [50.268398, 31.893561],
          [50.269478, 31.892965],
          [50.270122, 31.892644],
          [50.270952, 31.892351],
          [50.271416, 31.892187],
          [50.272331, 31.89182],
          [50.273087, 31.8915],
          [50.273842, 31.890952],
          [50.274758, 31.890172],
          [50.275833, 31.889393],
          [50.276856, 31.888249],
          [50.278091, 31.886828],
          [50.278523, 31.886185],
          [50.279225, 31.885497],
          [50.279869, 31.884993],
          [50.280621, 31.8838],
          [50.281536, 31.882197],
          [50.282772, 31.879673],
          [50.28363, 31.87807],
          [50.284275, 31.877244],
          [50.285244, 31.876557],
          [50.285999, 31.875776],
          [50.286427, 31.875228],
          [50.286533, 31.874676],
          [50.286694, 31.874127],
          [50.286886, 31.873176],
          [50.286907, 31.873072],
          [50.287014, 31.872245],
          [50.287392, 31.87165],
          [50.287712, 31.871236],
          [50.288254, 31.870917],
          [50.288868, 31.870729],
          [50.289005, 31.870687],
          [50.289974, 31.870595],
          [50.290352, 31.870412],
          [50.290565, 31.869818],
          [50.290672, 31.868761],
          [50.290726, 31.867385],
          [50.290668, 31.866469],
          [50.290287, 31.864403],
          [50.289963, 31.863301],
          [50.289692, 31.862794],
          [50.289314, 31.862384],
          [50.28775, 31.861784],
          [50.286404, 31.861416],
          [50.284355, 31.860768],
          [50.283606, 31.860658],
          [50.283116, 31.860585],
          [50.282417, 31.860171],
          [50.281876, 31.859572],
          [50.281445, 31.858884],
          [50.281445, 31.858242],
          [50.28112, 31.857554],
          [50.281063, 31.857003],
          [50.281494, 31.856178],
          [50.282246, 31.855077],
          [50.282513, 31.854116],
          [50.282997, 31.853243],
          [50.283051, 31.852233],
          [50.282993, 31.851407],
          [50.282993, 31.850627],
          [50.2831, 31.850077],
          [50.283585, 31.849527],
          [50.284123, 31.848885],
          [50.285088, 31.847923],
          [50.285786, 31.84719],
          [50.28627, 31.846822],
          [50.287293, 31.846503],
          [50.288261, 31.846227],
          [50.289054, 31.846276],
          [50.28907, 31.846277],
          [50.290146, 31.846093],
          [50.290577, 31.845911],
          [50.290848, 31.845589],
          [50.291008, 31.845313],
          [50.291008, 31.844808],
          [50.291168, 31.843938],
          [50.291756, 31.843159],
          [50.292401, 31.842332],
          [50.293209, 31.841279],
          [50.293961, 31.840133],
          [50.294033, 31.840064],
          [50.295303, 31.83885],
          [50.296215, 31.838025],
          [50.296646, 31.837245],
          [50.29686, 31.836603],
          [50.297344, 31.836191],
          [50.298313, 31.835917],
          [50.298699, 31.835829],
          [50.299118, 31.835734],
          [50.300034, 31.834909],
          [50.301109, 31.834405],
          [50.301807, 31.833534],
          [50.302452, 31.832755],
          [50.302642, 31.832471],
          [50.303097, 31.831793],
          [50.303791, 31.830647],
          [50.304382, 31.829867],
          [50.305458, 31.829043],
          [50.306534, 31.828677],
          [50.307339, 31.828081],
          [50.308148, 31.827716],
          [50.308899, 31.82767],
          [50.310139, 31.827442],
          [50.311268, 31.827076],
          [50.312073, 31.826711],
          [50.313042, 31.826251],
          [50.313473, 31.826069],
          [50.313523, 31.825611],
          [50.313469, 31.825198],
          [50.313469, 31.824508],
          [50.314056, 31.823776],
          [50.314972, 31.822676],
          [50.316689, 31.821532],
          [50.315609, 31.819833],
          [50.314743, 31.817489],
          [50.320156, 31.809604],
          [50.321606, 31.807128],
          [50.322514, 31.805432],
          [50.323639, 31.803781],
          [50.324657, 31.801993],
          [50.325623, 31.801031],
          [50.327072, 31.799656],
          [50.329968, 31.796355],
          [50.332272, 31.793422],
          [50.336937, 31.78806],
          [50.347082, 31.776803],
          [50.347172, 31.776599],
          [50.34815, 31.775618],
          [50.349936, 31.773635],
          [50.363613, 31.758451],
          [50.366611, 31.755473],
          [50.368912, 31.752859],
          [50.370521, 31.750014],
          [50.371787, 31.748117],
          [50.380605, 31.736963],
          [50.380605, 31.736963],
          [50.38628, 31.729785],
          [50.388466, 31.726159],
          [50.389374, 31.724553],
          [50.391819, 31.719368],
          [50.395752, 31.710877],
          [50.396759, 31.708398],
          [50.397524, 31.705806],
          [50.398341, 31.703469],
          [50.399036, 31.701746],
          [50.399563, 31.700278],
          [50.399626, 31.699791],
          [50.403896, 31.687566],
          [50.423248, 31.670069],
          [50.424637, 31.668738],
          [50.425272, 31.667998],
          [50.42588, 31.667375],
          [50.443787, 31.649037],
          [50.445389, 31.647474],
          [50.446404, 31.646511],
          [50.447037, 31.645226],
          [50.447456, 31.643895],
          [50.448139, 31.64192],
          [50.448452, 31.64059],
          [50.449295, 31.638295],
          [50.451767, 31.631366],
          [50.455131, 31.621544],
          [50.455921, 31.619524],
          [50.4566, 31.617321],
          [50.457394, 31.615486],
          [50.457653, 31.614477],
          [50.457542, 31.614156],
          [50.457005, 31.613836],
          [50.455494, 31.612828],
          [50.446648, 31.606418],
          [50.436348, 31.598952],
          [50.430634, 31.595058],
          [50.428994, 31.594113],
          [50.428966, 31.594097],
          [50.427944, 31.593734],
          [50.427634, 31.593645],
          [50.426979, 31.593459],
          [50.42569, 31.593277],
          [50.424831, 31.59323],
          [50.423599, 31.593324],
          [50.422688, 31.593692],
          [50.421566, 31.594244],
          [50.420765, 31.59475],
          [50.420235, 31.595533],
          [50.419491, 31.596495],
          [50.418854, 31.597689],
          [50.41827, 31.598515],
          [50.417683, 31.599157],
          [50.41739, 31.599347],
          [50.417256, 31.599435],
          [50.416721, 31.599572],
          [50.416397, 31.599573],
          [50.415913, 31.599345],
          [50.415785, 31.599152],
          [50.415699, 31.599022],
          [50.415535, 31.598655],
          [50.415424, 31.598014],
          [50.415314, 31.597556],
          [50.415096, 31.597142],
          [50.414612, 31.596914],
          [50.414127, 31.596777],
          [50.413269, 31.59678],
          [50.412735, 31.596962],
          [50.411934, 31.597376],
          [50.41124, 31.597973],
          [50.410652, 31.598615],
          [50.409904, 31.599261],
          [50.409317, 31.599764],
          [50.408623, 31.600178],
          [50.407768, 31.600271],
          [50.406693, 31.600272],
          [50.406105, 31.600227],
          [50.405567, 31.600089],
          [50.405189, 31.599907],
          [50.404061, 31.59945],
          [50.403309, 31.599176],
          [50.402393, 31.598946],
          [50.401535, 31.598764],
          [50.40073, 31.598767],
          [50.399925, 31.598766],
          [50.399178, 31.598907],
          [50.398586, 31.599274],
          [50.398163, 31.599734],
          [50.397576, 31.600146],
          [50.397148, 31.600514],
          [50.396664, 31.600514],
          [50.395969, 31.600608],
          [50.395432, 31.600883],
          [50.394951, 31.601113],
          [50.39431, 31.601435],
          [50.393665, 31.601529],
          [50.393385, 31.601555],
          [50.393185, 31.601574],
          [50.392593, 31.601483],
          [50.39238, 31.601392],
          [50.392322, 31.601299],
          [50.392269, 31.600978],
          [50.392376, 31.600701],
          [50.392586, 31.60029],
          [50.39259, 31.600284],
          [50.393013, 31.599739],
          [50.393223, 31.599187],
          [50.393543, 31.598821],
          [50.39381, 31.598408],
          [50.394238, 31.598085],
          [50.394821, 31.597442],
          [50.395622, 31.596571],
          [50.395992, 31.595975],
          [50.39642, 31.595377],
          [50.396576, 31.594643],
          [50.396572, 31.594094],
          [50.396461, 31.593359],
          [50.396244, 31.59281],
          [50.395916, 31.59203],
          [50.395485, 31.59125],
          [50.39505, 31.590562],
          [50.394566, 31.589921],
          [50.393864, 31.589235],
          [50.393109, 31.58873],
          [50.392197, 31.588318],
          [50.391014, 31.587771],
          [50.390316, 31.587497],
          [50.389347, 31.587176],
          [50.388489, 31.586809],
          [50.387627, 31.586491],
          [50.386608, 31.586216],
          [50.385693, 31.585758],
          [50.384724, 31.5853],
          [50.384239, 31.584889],
          [50.383808, 31.584522],
          [50.383537, 31.583927],
          [50.383213, 31.583285],
          [50.382942, 31.582458],
          [50.382614, 31.581681],
          [50.382503, 31.58113],
          [50.3825, 31.580352],
          [50.382496, 31.579709],
          [50.382332, 31.57925],
          [50.382225, 31.578975],
          [50.382061, 31.578378],
          [50.382004, 31.577828],
          [50.382, 31.577232],
          [50.381996, 31.576772],
          [50.381889, 31.576316],
          [50.381405, 31.575811],
          [50.38097, 31.575168],
          [50.380593, 31.574756],
          [50.380215, 31.574023],
          [50.379837, 31.573473],
          [50.379509, 31.572511],
          [50.379025, 31.571732],
          [50.37859, 31.571182],
          [50.378109, 31.570907],
          [50.377464, 31.570816],
          [50.376873, 31.570815],
          [50.376286, 31.571002],
          [50.375591, 31.571368],
          [50.375271, 31.571734],
          [50.375057, 31.572058],
          [50.374417, 31.572472],
          [50.373936, 31.572977],
          [50.373509, 31.573068],
          [50.373081, 31.573115],
          [50.372543, 31.573117],
          [50.372116, 31.572932],
          [50.371739, 31.572705],
          [50.371468, 31.572431],
          [50.371361, 31.57211],
          [50.371357, 31.571695],
          [50.371357, 31.571237],
          [50.371567, 31.570823],
          [50.37162, 31.570458],
          [50.371723, 31.569998],
          [50.37167, 31.569633],
          [50.371506, 31.569264],
          [50.371239, 31.569128],
          [50.370968, 31.569128],
          [50.370861, 31.569128],
          [50.370594, 31.569174],
          [50.370167, 31.569359],
          [50.369847, 31.56945],
          [50.369472, 31.569543],
          [50.368614, 31.569956],
          [50.36808, 31.570095],
          [50.3676, 31.570095],
          [50.367115, 31.569958],
          [50.366791, 31.569728],
          [50.366684, 31.569545],
          [50.366631, 31.569134],
          [50.366734, 31.568812],
          [50.36684, 31.5684],
          [50.366783, 31.567939],
          [50.366619, 31.567253],
          [50.366455, 31.566794],
          [50.366291, 31.56606],
          [50.366287, 31.56551],
          [50.366123, 31.564869],
          [50.365963, 31.5645],
          [50.365692, 31.564043],
          [50.365261, 31.563631],
          [50.364777, 31.563358],
          [50.364242, 31.563174],
          [50.363651, 31.562716],
          [50.36311, 31.562075],
          [50.362732, 31.561295],
          [50.362292, 31.560244],
          [50.361485, 31.558316],
          [50.361214, 31.557811],
          [50.360783, 31.557169],
          [50.360672, 31.55662],
          [50.360459, 31.556391],
          [50.360187, 31.556207],
          [50.359867, 31.556163],
          [50.359383, 31.556025],
          [50.359009, 31.555795],
          [50.358684, 31.555383],
          [50.358414, 31.555019],
          [50.358307, 31.554515],
          [50.358356, 31.554008],
          [50.35857, 31.553641],
          [50.35878, 31.552999],
          [50.35883, 31.552357],
          [50.358879, 31.551532],
          [50.358875, 31.550659],
          [50.358925, 31.549788],
          [50.358928, 31.549745],
          [50.358974, 31.549146],
          [50.359295, 31.548642],
          [50.359508, 31.548273],
          [50.360096, 31.547954],
          [50.36047, 31.547858],
          [50.361221, 31.547998],
          [50.361866, 31.548317],
          [50.362632, 31.548661],
          [50.362778, 31.548727],
          [50.363636, 31.54923],
          [50.364761, 31.549596],
          [50.365513, 31.549686],
          [50.366425, 31.549641],
          [50.3676, 31.549455],
          [50.368725, 31.549178],
          [50.37006, 31.548808],
          [50.371239, 31.548304],
          [50.373802, 31.5472],
          [50.37487, 31.546694],
          [50.375618, 31.54628],
          [50.376152, 31.545728],
          [50.376629, 31.544995],
          [50.37673, 31.544098],
          [50.376732, 31.544077],
          [50.376688, 31.54371],
          [50.376621, 31.543159],
          [50.376476, 31.542932],
          [50.37624, 31.542564],
          [50.375648, 31.54183],
          [50.375111, 31.541234],
          [50.373497, 31.539907],
          [50.372261, 31.538899],
          [50.370808, 31.5378],
          [50.370188, 31.537324],
          [50.369141, 31.536518],
          [50.367207, 31.535098],
          [50.365437, 31.534],
          [50.363556, 31.532719],
          [50.361565, 31.531116],
          [50.359795, 31.530018],
          [50.358772, 31.529239],
          [50.357536, 31.528369],
          [50.355606, 31.527503],
          [50.353676, 31.526358],
          [50.351956, 31.525213],
          [50.350292, 31.524255],
          [50.348896, 31.523154],
          [50.347229, 31.521826],
          [50.345779, 31.520819],
          [50.344276, 31.519812],
          [50.342987, 31.518576],
          [50.340946, 31.517018],
          [50.33939, 31.516011],
          [50.337246, 31.514687],
          [50.336485, 31.514194],
          [50.314412, 31.497448],
          [50.307672, 31.489564],
          [50.300392, 31.484298],
          [50.293242, 31.481571],
          [50.295108, 31.480707],
          [50.308721, 31.470153],
          [50.315668, 31.461261],
          [50.329278, 31.446265],
          [50.330668, 31.444875],
          [50.354281, 31.424869],
          [50.372614, 31.418757],
          [50.374281, 31.418486],
          [50.374837, 31.418486],
          [50.375114, 31.418207],
          [50.376224, 31.418207],
          [50.376224, 31.417927],
          [50.379557, 31.417367],
          [50.400947, 31.415146],
          [50.408446, 31.415146],
          [50.40928, 31.415426],
          [50.411783, 31.415426],
          [50.412617, 31.415706],
          [50.413727, 31.415706],
          [50.414283, 31.415986],
          [50.415117, 31.415986],
          [50.41595, 31.416266],
          [50.42956, 31.418487],
          [50.430393, 31.418487],
          [50.432616, 31.419037],
          [50.434559, 31.419037],
          [50.435116, 31.419317],
          [50.436226, 31.419317],
          [50.436783, 31.419597],
          [50.437616, 31.419597],
          [50.438173, 31.419877],
          [50.43984, 31.419877],
          [50.440116, 31.420012],
          [50.440393, 31.420148],
          [50.441783, 31.420148],
          [50.44234, 31.420428],
          [50.443726, 31.420428],
          [50.444283, 31.420707],
          [50.445393, 31.420707],
          [50.452896, 31.422648],
          [50.454283, 31.422648],
          [50.454563, 31.422928],
          [50.455673, 31.422928],
          [50.457063, 31.423488],
          [50.458173, 31.423759],
          [50.459563, 31.423759],
          [50.460949, 31.424319],
          [50.462063, 31.424319],
          [50.462339, 31.424598],
          [50.476229, 31.42765],
          [50.477339, 31.42821],
          [50.478151, 31.428919],
          [50.479562, 31.430151],
          [50.480396, 31.431261],
          [50.492062, 31.449877],
          [50.495675, 31.451818],
          [50.496509, 31.451818],
          [50.497619, 31.452377],
          [50.500395, 31.452648],
          [50.508175, 31.451818],
          [50.509285, 31.451258],
          [50.512342, 31.450427],
          [50.522618, 31.444875],
          [50.529565, 31.440154],
          [50.530398, 31.440154],
          [50.530955, 31.439874],
          [50.531508, 31.439874],
          [50.534288, 31.439874],
          [50.537898, 31.441264],
          [50.539008, 31.441264],
          [50.542345, 31.440154],
          [50.542621, 31.439594],
          [50.543731, 31.438483],
          [50.544012, 31.436822],
          [50.544288, 31.436542],
          [50.544012, 31.435152],
          [50.544288, 31.433762],
          [50.544565, 31.432931],
          [50.544845, 31.432651],
          [50.547065, 31.430431],
          [50.547621, 31.430431],
          [50.560678, 31.423488],
          [50.568178, 31.413485],
          [50.568178, 31.413205],
          [50.568734, 31.412375],
          [50.573734, 31.409044],
          [50.574568, 31.409044],
          [50.574844, 31.408764],
          [50.576788, 31.408764],
          [50.580401, 31.410154],
          [50.595124, 31.419597],
          [50.596791, 31.419597],
          [50.598177, 31.420148],
          [50.604567, 31.420148],
          [50.604847, 31.420428],
          [50.605401, 31.420428],
          [50.605957, 31.420708],
          [50.606234, 31.420708],
          [50.607067, 31.420428],
          [50.608457, 31.420428],
          [50.609014, 31.420148],
          [50.611514, 31.419597],
          [50.6129, 31.419597],
          [50.61318, 31.419317],
          [50.618181, 31.419317],
          [50.618737, 31.419037],
          [50.619847, 31.419038],
          [50.620404, 31.418758],
          [50.62179, 31.418487],
          [50.630124, 31.418758],
          [50.630124, 31.418758],
          [50.630124, 31.418758],
          [50.630124, 31.419037],
          [50.630124, 31.419038],
          [50.631514, 31.419877],
          [50.631514, 31.419877],
          [50.637903, 31.427379],
          [50.63957, 31.42765],
          [50.641237, 31.42682],
          [50.641793, 31.425989],
          [50.647347, 31.418207],
          [50.649013, 31.417367],
          [50.651513, 31.417097],
          [50.65207, 31.417367],
          [50.65457, 31.417097],
          [50.65457, 31.417096],
          [50.65457, 31.417096],
          [50.65457, 31.416817],
          [50.65457, 31.416817],
          [50.65568, 31.416817],
          [50.65596, 31.416537],
          [50.660127, 31.415426],
          [50.661793, 31.415426],
          [50.66235, 31.415147],
          [50.669016, 31.412926],
          [50.67096, 31.412655],
          [50.674016, 31.412655],
          [50.674293, 31.412375],
          [50.675126, 31.412375],
          [50.675683, 31.412095],
          [50.679016, 31.410705],
          [50.686793, 31.406263],
          [50.687356, 31.405983],
          [50.687906, 31.405983],
          [50.691239, 31.404322],
          [50.699296, 31.394039],
          [50.700963, 31.390988],
          [50.716519, 31.384597],
          [50.717629, 31.384037],
          [50.719852, 31.383206],
          [50.724296, 31.379315],
          [50.724579, 31.378482],
          [50.724019, 31.370983],
          [50.724572, 31.370152],
          [50.749852, 31.348756],
          [50.750686, 31.347374],
          [50.755409, 31.340704],
          [50.778746, 31.322366],
          [50.789298, 31.314316],
          [50.790131, 31.314316],
          [50.790694, 31.314037],
          [50.791528, 31.314036],
          [50.791798, 31.313756],
          [50.796245, 31.312926],
          [50.798745, 31.312095],
          [50.800694, 31.311256],
          [50.806251, 31.308205],
          [50.807911, 31.308205],
          [50.807911, 31.308205],
          [50.808188, 31.308484],
          [50.814021, 31.309595],
          [50.814026, 31.309595],
          [50.814026, 31.309595],
          [50.81405, 31.309595],
          [50.816801, 31.309595],
          [50.817078, 31.309315],
          [50.817917, 31.309315],
          [50.820417, 31.307925],
          [50.82653, 31.300983],
          [50.830411, 31.300983],
          [50.831244, 31.300703],
          [50.835968, 31.300432],
          [50.836244, 31.300143],
          [50.837634, 31.299592],
          [50.839305, 31.29904],
          [50.843191, 31.297651],
          [50.843744, 31.297092],
          [50.855691, 31.290709],
          [50.856807, 31.290709],
          [50.857087, 31.290421],
          [50.859857, 31.29042],
          [50.86014, 31.29071],
          [50.863747, 31.29265],
          [50.871804, 31.299592],
          [50.87819, 31.303483],
          [50.880143, 31.304594],
          [50.889033, 31.308205],
          [50.889033, 31.308205],
          [50.890694, 31.308205],
          [50.890694, 31.308205],
          [50.891253, 31.308485],
          [50.892363, 31.308755],
          [50.892363, 31.308755],
          [50.892366, 31.308755],
          [50.911809, 31.305145],
          [50.919893, 31.300931],
          [50.924589, 31.298482],
          [50.925423, 31.297652],
          [50.927366, 31.296532],
          [50.937089, 31.286539],
          [50.939036, 31.284318],
          [50.959592, 31.262923],
          [50.960425, 31.262652],
          [50.963202, 31.260423],
          [50.961376, 31.239882],
          [50.969442, 31.226557],
          [50.979163, 31.219503],
          [50.979163, 31.219503],
          [50.998246, 31.205648],
          [51.019595, 31.190145],
          [51.020423, 31.189033],
          [51.0207, 31.188762],
          [51.030977, 31.181811],
          [51.0407, 31.178751],
          [51.045702, 31.178753],
          [51.045983, 31.179041],
          [51.045984, 31.179042],
          [51.053761, 31.180423],
          [51.063403, 31.184947],
          [51.06952, 31.187817],
          [51.06952, 31.187817],
          [51.06952, 31.187817],
          [51.06952, 31.187817],
          [51.071504, 31.18875],
          [51.071533, 31.188764],
          [51.071534, 31.188764],
          [51.071534, 31.188764],
          [51.071534, 31.188764],
          [51.07154, 31.188764],
          [51.078478, 31.188764],
          [51.088758, 31.185424],
          [51.095701, 31.181813],
          [51.129039, 31.157366],
          [51.14876, 31.150147],
          [51.150427, 31.150147],
          [51.150983, 31.149867],
          [51.152921, 31.149867],
          [51.152927, 31.149867],
          [51.152927, 31.149867],
          [51.15376, 31.150146],
          [51.15376, 31.150147],
          [51.153766, 31.150147],
          [51.154866, 31.150145],
          [51.154871, 31.150145],
          [51.154872, 31.150145],
          [51.157928, 31.150695],
          [51.157928, 31.150695],
          [51.16015, 31.151534],
          [51.160151, 31.151535],
          [51.160151, 31.151535],
          [51.160152, 31.151535],
          [51.160152, 31.151535],
          [51.161538, 31.152365],
          [51.161538, 31.152365],
          [51.164595, 31.157646],
          [51.164318, 31.158477],
          [51.164318, 31.158477],
          [51.164317, 31.158478],
          [51.164316, 31.158478],
          [51.164038, 31.158756],
          [51.164038, 31.158757],
          [51.164038, 31.158757],
          [51.164595, 31.163477],
          [51.164595, 31.163477],
          [51.170708, 31.166529],
          [51.170708, 31.166529],
          [51.170985, 31.167088],
          [51.179041, 31.17487],
          [51.180149, 31.181813],
          [51.184873, 31.189875],
          [51.184874, 31.190983],
          [51.184596, 31.192366],
          [51.184318, 31.193204],
          [51.184317, 31.193206],
          [51.182928, 31.195984],
          [51.179041, 31.201256],
          [51.179041, 31.201257],
          [51.178761, 31.202646],
          [51.180426, 31.209869],
          [51.180426, 31.209869],
          [51.180426, 31.20987],
          [51.182926, 31.20987],
          [51.18404, 31.20959],
          [51.187362, 31.20959],
          [51.187367, 31.20959],
          [51.187373, 31.20959],
          [51.187373, 31.20959],
          [51.187374, 31.20959],
          [51.223763, 31.22376],
          [51.223763, 31.223761],
          [51.223764, 31.223761],
          [51.223769, 31.223761],
          [51.223774, 31.223761],
          [51.226819, 31.223763],
          [51.232691, 31.220402],
          [51.236542, 31.218202],
          [51.245432, 31.209319],
          [51.2521, 31.206808],
          [51.257929, 31.206258],
          [51.257933, 31.206257],
          [51.264877, 31.206257],
          [51.26599, 31.205977],
          [51.268487, 31.205977],
          [51.26849, 31.205977],
          [51.272656, 31.205977],
          [51.283491, 31.205977],
          [51.283492, 31.205978],
          [51.285429, 31.206259],
          [51.28543, 31.206259],
          [51.285431, 31.206259],
          [51.285436, 31.206259],
          [51.28544, 31.206259],
          [51.288211, 31.206259],
          [51.290155, 31.205979],
          [51.294598, 31.205979],
          [51.295431, 31.205699],
          [51.299045, 31.205429],
          [51.312103, 31.202087],
          [51.320158, 31.199037],
          [51.320712, 31.198476],
          [51.326824, 31.196537],
          [51.328491, 31.196537],
          [51.328768, 31.196257],
          [51.329866, 31.195989],
          [51.329868, 31.195989],
          [51.329872, 31.195988],
          [51.329876, 31.195987],
          [51.329877, 31.195986],
          [51.329878, 31.195987],
          [51.329878, 31.195988],
          [51.329878, 31.195989],
          [51.330711, 31.201538],
          [51.330991, 31.20987],
          [51.331544, 31.216812],
          [51.335434, 31.239317],
          [51.338767, 31.28709],
          [51.338491, 31.28876],
          [51.337101, 31.296532],
          [51.334324, 31.303764],
          [51.329877, 31.310706],
          [51.326267, 31.315156],
          [51.323767, 31.317928],
          [51.298488, 31.352928],
          [51.297935, 31.353208],
          [51.293488, 31.364041],
          [51.293211, 31.365702],
          [51.294044, 31.368483],
          [51.298211, 31.374594],
          [51.305988, 31.377375],
          [51.310434, 31.377926],
          [51.313767, 31.379316],
          [51.314044, 31.379876],
          [51.314044, 31.380359],
          [51.314321, 31.384597],
          [51.313767, 31.388488],
          [51.314044, 31.39348],
          [51.308487, 31.410706],
          [51.307378, 31.412656],
          [51.306821, 31.412926],
          [51.304321, 31.418208],
          [51.304321, 31.419598],
          [51.304044, 31.420149],
          [51.303768, 31.420988],
          [51.303487, 31.421539],
          [51.303211, 31.421819],
          [51.303073, 31.422099],
          [51.289044, 31.444036],
          [51.288488, 31.445156],
          [51.286821, 31.446817],
          [51.283211, 31.449598],
          [51.259598, 31.459321],
          [51.259598, 31.460711],
          [51.270155, 31.480708],
          [51.272374, 31.490152],
          [51.272931, 31.491262],
          [51.272931, 31.491542],
          [51.273488, 31.492932],
          [51.273765, 31.493763],
          [51.277098, 31.503206],
          [51.278488, 31.504317],
          [51.284044, 31.505707],
          [51.287654, 31.506817],
          [51.294878, 31.511819],
          [51.295711, 31.513489],
          [51.296544, 31.51571],
          [51.296544, 31.519321],
          [51.290711, 31.546821],
          [51.290431, 31.549873],
          [51.292377, 31.569879],
          [51.299321, 31.5971],
          [51.300711, 31.607103],
          [51.302101, 31.614326],
          [51.302292, 31.614901],
          [51.302377, 31.615996],
          [51.303487, 31.65016],
          [51.303767, 31.654322],
          [51.304044, 31.656263],
          [51.304321, 31.657383],
          [51.305434, 31.661825],
          [51.309044, 31.668488],
          [51.310154, 31.670158],
          [51.3121, 31.671828],
          [51.345157, 31.682942],
          [51.34599, 31.682942],
          [51.359046, 31.693217],
          [51.366823, 31.704051],
          [51.367811, 31.706022],
          [51.36877, 31.707934],
          [51.369046, 31.711545],
          [51.369046, 31.712656],
          [51.36849, 31.714326],
          [51.36238, 31.720989],
          [51.345433, 31.733214],
          [51.343213, 31.735444],
          [51.342657, 31.735715],
          [51.3421, 31.736825],
          [51.341547, 31.737105],
          [51.34099, 31.738216],
          [51.340157, 31.739055],
          [51.339323, 31.741547],
          [51.339047, 31.742107],
          [51.338767, 31.743217],
          [51.33849, 31.745989],
          [51.338213, 31.750991],
          [51.337933, 31.756553],
          [51.3371, 31.760995],
          [51.33099, 31.767938],
          [51.327933, 31.770439],
          [51.32571, 31.77182],
          [51.294877, 31.789607],
          [51.287931, 31.79655],
          [51.285431, 31.802103],
          [51.287377, 31.810445],
          [51.293211, 31.81961],
          [51.293487, 31.82016],
          [51.29571, 31.822102],
          [51.296264, 31.822381],
          [51.29682, 31.822941],
          [51.29821, 31.823212],
          [51.300987, 31.824332],
          [51.302934, 31.825162],
          [51.33071, 31.837667],
          [51.363769, 31.847112],
          [51.367379, 31.847662],
          [51.372103, 31.847662],
          [51.373213, 31.847383],
          [51.386826, 31.849053],
          [51.391546, 31.849053],
          [51.392383, 31.849053],
          [51.392659, 31.849333],
          [51.393492, 31.849333],
          [51.394049, 31.849333],
          [51.399882, 31.850714],
          [51.402102, 31.851834],
          [51.402936, 31.851834],
          [51.409602, 31.858497],
          [51.410992, 31.860438],
          [51.412106, 31.861829],
          [51.413216, 31.86405],
          [51.414049, 31.866831],
          [51.414325, 31.880446],
          [51.417382, 31.894053],
          [51.426272, 31.912662],
          [51.428215, 31.926828],
          [51.427659, 31.929609],
          [51.425438, 31.935442],
          [51.422382, 31.940444],
          [51.417939, 31.945166],
          [51.415159, 31.948498],
          [51.414325, 31.949328],
          [51.413492, 31.951279],
          [51.413215, 31.95238],
          [51.414605, 31.957391],
          [51.414882, 31.958502],
          [51.415007, 31.958677],
          [51.416185, 31.960325],
          [51.417659, 31.962384],
          [51.422624, 31.964328],
          [51.424048, 31.964886],
          [51.424941, 31.965357],
          [51.433323, 31.969784],
          [51.433511, 31.969884],
          [51.434048, 31.970168],
          [51.434228, 31.971413],
          [51.434328, 31.972109],
          [51.433888, 31.973509],
          [51.432745, 31.977146],
          [51.431272, 31.981833],
          [51.430715, 31.982664],
          [51.430438, 31.983774],
          [51.430162, 31.986276],
          [51.429882, 31.990727],
          [51.429605, 31.992388],
          [51.429325, 31.993779],
          [51.429325, 31.999891],
          [51.424882, 32.007106],
          [51.414325, 32.032387],
          [51.404049, 32.042662],
          [51.402935, 32.043502],
          [51.387936, 32.048775],
          [51.387659, 32.049055],
          [51.386826, 32.049055],
          [51.386546, 32.049335],
          [51.385436, 32.049335],
          [51.385159, 32.049615],
          [51.384326, 32.049615],
          [51.378492, 32.052667],
          [51.376826, 32.055728],
          [51.375992, 32.05822],
          [51.383492, 32.069334],
          [51.383769, 32.069894],
          [51.384326, 32.070165],
          [51.385436, 32.070725],
          [51.385992, 32.072386],
          [51.386269, 32.083501],
          [51.386546, 32.086002],
          [51.386269, 32.086553],
          [51.385712, 32.091556],
          [51.385159, 32.091276],
          [51.382379, 32.094887],
          [51.381826, 32.095998],
          [51.367656, 32.110165],
          [51.357379, 32.12295],
          [51.354323, 32.130724],
          [51.345713, 32.139618],
          [51.344599, 32.140449],
          [51.344231, 32.140636],
          [51.343766, 32.141],
          [51.343489, 32.140729],
          [51.342379, 32.141],
          [51.341823, 32.14128],
          [51.340989, 32.141279],
          [51.340989, 32.141559],
          [51.340156, 32.141559],
          [51.332656, 32.145171],
          [51.3321, 32.145171],
          [51.33099, 32.146002],
          [51.326823, 32.147672],
          [51.3021, 32.154887],
          [51.299877, 32.155446],
          [51.293487, 32.155997],
          [51.292653, 32.156277],
          [51.289043, 32.156277],
          [51.287653, 32.155997],
          [51.284597, 32.155997],
          [51.28432, 32.156277],
          [51.282653, 32.156277],
          [51.282097, 32.156557],
          [51.27793, 32.156557],
          [51.268487, 32.158778],
          [51.259317, 32.16267],
          [51.258207, 32.16323],
          [51.25654, 32.1635],
          [51.243484, 32.168503],
          [51.242374, 32.168783],
          [51.233761, 32.174336],
          [51.232094, 32.176557],
          [51.231541, 32.176837],
          [51.230984, 32.178227],
          [51.221818, 32.189333],
          [51.221818, 32.189613],
          [51.221263, 32.190165],
          [51.209318, 32.205171],
          [51.208761, 32.206281],
          [51.197095, 32.218227],
          [51.194038, 32.22267],
          [51.193761, 32.223229],
          [51.192371, 32.22434],
          [51.191815, 32.22462],
          [51.191815, 32.225171],
          [51.191538, 32.225442],
          [51.191538, 32.225722],
          [51.190705, 32.226561],
          [51.185148, 32.237396],
          [51.184315, 32.238787],
          [51.181258, 32.245171],
          [51.168481, 32.26267],
          [51.165705, 32.266561],
          [51.146258, 32.286011],
          [51.145702, 32.286281],
          [51.144312, 32.287672],
          [51.138202, 32.292123],
          [51.137369, 32.292394],
          [51.136535, 32.293225],
          [51.133759, 32.294615],
          [51.132645, 32.294895],
          [51.079589, 32.329893],
          [51.078476, 32.330724],
          [51.074309, 32.333505],
          [51.04653, 32.34267],
          [51.04181, 32.34434],
          [51.031806, 32.351004],
          [51.02264, 32.356286],
          [51.022087, 32.356837],
          [51.016253, 32.361009],
          [50.999894, 32.367952],
          [50.985417, 32.376006],
          [50.973194, 32.388232],
          [50.972084, 32.389894],
          [50.970971, 32.391284],
          [50.970694, 32.392124],
          [50.970417, 32.393785],
          [50.970417, 32.395176],
          [50.970137, 32.395447],
          [50.969861, 32.397117],
          [50.969584, 32.399618],
          [50.967361, 32.414616],
          [50.967637, 32.425452],
          [50.967917, 32.429343],
          [50.968471, 32.433506],
          [50.969861, 32.437948],
          [50.970137, 32.439619],
          [50.970417, 32.44017],
          [50.973194, 32.445732],
          [50.984584, 32.46045],
          [50.994307, 32.474066],
          [50.999307, 32.483511],
          [50.999792, 32.484062],
          [50.999863, 32.484622],
          [51.00514, 32.50129],
          [51.00903, 32.514897],
          [51.00903, 32.515177],
          [51.00903, 32.516568],
          [51.00903, 32.516839],
          [51.00903, 32.517678],
          [51.00875, 32.518229],
          [51.008197, 32.521561],
          [51.007917, 32.524062],
          [51.00014, 32.533507],
          [50.998473, 32.537679],
          [50.998197, 32.537958],
          [50.997917, 32.539349],
          [50.998197, 32.545182],
          [50.998197, 32.545733],
          [50.998473, 32.550735],
          [50.998197, 32.551286],
          [50.99653, 32.554627],
          [50.985974, 32.558509],
          [50.985417, 32.558789],
          [50.984584, 32.558789],
          [50.984027, 32.559069],
          [50.983193, 32.559069],
          [50.98264, 32.559349],
          [50.981527, 32.559349],
          [50.98014, 32.5599],
          [50.97764, 32.5599],
          [50.97597, 32.560731],
          [50.97597, 32.561011],
          [50.975417, 32.561291],
          [50.96597, 32.566844],
          [50.964861, 32.567403],
          [50.941527, 32.585733],
          [50.931804, 32.59851],
          [50.930691, 32.60018],
          [50.923471, 32.615738],
          [50.923191, 32.617959],
          [50.922914, 32.621291],
          [50.922638, 32.624623],
          [50.922358, 32.627955],
          [50.921804, 32.633788],
          [50.922081, 32.643512],
          [50.921804, 32.644072],
          [50.921524, 32.647124],
          [50.921248, 32.648795],
          [50.920971, 32.651016],
          [50.920691, 32.652677],
          [50.920414, 32.654348],
          [50.920414, 32.654627],
          [50.920138, 32.655178],
          [50.919858, 32.656009],
          [50.917741, 32.662789],
          [50.917081, 32.664903],
          [50.916801, 32.665174],
          [50.908107, 32.671777],
          [50.893744, 32.682682],
          [50.890135, 32.689626],
          [50.890135, 32.690185],
          [50.889858, 32.690465],
          [50.889578, 32.691576],
          [50.887911, 32.695179],
          [50.887358, 32.695738],
          [50.887358, 32.696289],
          [50.873745, 32.706574],
          [50.873745, 32.706854],
          [50.873188, 32.706854],
          [50.872355, 32.707684],
          [50.866245, 32.709905],
          [50.864855, 32.709905],
          [50.864022, 32.710185],
          [50.862635, 32.710185],
          [50.860412, 32.710736],
          [50.859578, 32.710736],
          [50.859298, 32.711016],
          [50.857632, 32.711016],
          [50.857078, 32.711296],
          [50.855132, 32.711296],
          [50.852912, 32.711847],
          [50.852355, 32.712127],
          [50.851245, 32.711567],
          [50.845965, 32.711567],
          [50.843189, 32.711296],
          [50.842909, 32.711016],
          [50.835409, 32.711016],
          [50.834575, 32.711296],
          [50.831799, 32.711296],
          [50.829022, 32.711296],
          [50.812352, 32.707964],
          [50.794576, 32.704072],
          [50.783463, 32.700732],
          [50.781519, 32.700461],
          [50.780963, 32.700181],
          [50.780129, 32.700181],
          [50.778739, 32.699621],
          [50.777073, 32.699621],
          [50.775686, 32.699621],
          [50.773463, 32.699621],
          [50.773186, 32.699901],
          [50.770686, 32.699901],
          [50.770129, 32.700181],
          [50.76902, 32.700181],
          [50.768463, 32.700461],
          [50.767073, 32.700461],
          [50.760683, 32.702682],
          [50.760683, 32.702962],
          [50.75985, 32.702962],
          [50.75735, 32.704343],
          [50.756796, 32.704343],
          [50.756516, 32.704903],
          [50.752906, 32.706294],
          [50.75235, 32.706573],
          [50.746793, 32.708515],
          [50.74624, 32.708515],
          [50.74596, 32.708795],
          [50.744573, 32.708795],
          [50.744017, 32.709075],
          [50.741793, 32.709075],
          [50.74124, 32.709345],
          [50.737073, 32.709075],
          [50.736517, 32.708795],
          [50.735127, 32.708795],
          [50.730127, 32.706853],
          [50.715503, 32.706038],
          [50.708304, 32.703378],
          [50.689097, 32.70966],
          [50.684291, 32.707463],
          [50.681922, 32.706381],
          [50.679528, 32.711874],
          [50.666589, 32.706588],
          [50.651846, 32.710515],
          [50.636676, 32.7064],
          [50.631172, 32.697607],
          [50.618191, 32.693549],
          [50.614758, 32.687898],
          [50.6058, 32.693211],
          [50.602391, 32.686943],
          [50.58718, 32.684057],
          [50.578879, 32.691238],
          [50.571756, 32.68672],
          [50.565016, 32.672331],
          [50.562695, 32.673044],
          [50.561325, 32.673464],
          [50.56221, 32.688309],
          [50.555423, 32.675153],
          [50.548673, 32.679906],
          [50.550452, 32.690453],
          [50.553953, 32.694255],
          [50.550751, 32.701578],
          [50.552626, 32.709655],
          [50.549885, 32.713897],
          [50.545131, 32.71665],
          [50.542381, 32.718243],
          [50.540953, 32.71907],
          [50.538453, 32.71907],
          [50.538172, 32.71935],
          [50.537339, 32.71935],
          [50.537339, 32.71907],
          [50.534286, 32.71907],
          [50.534286, 32.71879],
          [50.533483, 32.71879],
          [50.533062, 32.71879],
          [50.532339, 32.71879],
          [50.531784, 32.718654],
          [50.531229, 32.718519],
          [50.53024, 32.71832],
          [50.529994, 32.71827],
          [50.529839, 32.718239],
          [50.528835, 32.71807],
          [50.528708, 32.718049],
          [50.528173, 32.717959],
          [50.528097, 32.717959],
          [50.527063, 32.717959],
          [50.526606, 32.717807],
          [50.522063, 32.716289],
          [50.52123, 32.716289],
          [50.520043, 32.715732],
          [50.515946, 32.71381],
          [50.509553, 32.71081],
          [50.508793, 32.710453],
          [50.497363, 32.705088],
          [50.494006, 32.703512],
          [50.47845, 32.690736],
          [50.476783, 32.688794],
          [50.46956, 32.681842],
          [50.462061, 32.671286],
          [50.458447, 32.661291],
          [50.458171, 32.660731],
          [50.458447, 32.6599],
          [50.458171, 32.654627],
          [50.458447, 32.654067],
          [50.46005, 32.650593],
          [50.460114, 32.650456],
          [50.465114, 32.647684],
          [50.465434, 32.647522],
          [50.466227, 32.647124],
          [50.466784, 32.647124],
          [50.46706, 32.647124],
          [50.467617, 32.647124],
          [50.467894, 32.646844],
          [50.470393, 32.646844],
          [50.47095, 32.646573],
          [50.47845, 32.646844],
          [50.478727, 32.647124],
          [50.482617, 32.647124],
          [50.484284, 32.647404],
          [50.49095, 32.647404],
          [50.491507, 32.647124],
          [50.492893, 32.647124],
          [50.49345, 32.646844],
          [50.495393, 32.646844],
          [50.501507, 32.644902],
          [50.516783, 32.632957],
          [50.517063, 32.632126],
          [50.517616, 32.631566],
          [50.517896, 32.630735],
          [50.517616, 32.628514],
          [50.51595, 32.625462],
          [50.515949, 32.625182],
          [50.515673, 32.624622],
          [50.515116, 32.623792],
          [50.510552, 32.618175],
          [50.507897, 32.614907],
          [50.506783, 32.613236],
          [50.500116, 32.604351],
          [50.496227, 32.596848],
          [50.491227, 32.591286],
          [50.485117, 32.589073],
          [50.484284, 32.588794],
          [50.481227, 32.588794],
          [50.48095, 32.588514],
          [50.47956, 32.588514],
          [50.47845, 32.588794],
          [50.475117, 32.588794],
          [50.474561, 32.589073],
          [50.471227, 32.589073],
          [50.47095, 32.589344],
          [50.466227, 32.589073],
          [50.465671, 32.588794],
          [50.464281, 32.588794],
          [50.464004, 32.588514],
          [50.462337, 32.588514],
          [50.461781, 32.588234],
          [50.460394, 32.588234],
          [50.460114, 32.587954],
          [50.459004, 32.587954],
          [50.449004, 32.582681],
          [50.447614, 32.582121],
          [50.447057, 32.58157],
          [50.442891, 32.5799],
          [50.441781, 32.5799],
          [50.441504, 32.57962],
          [50.440391, 32.57962],
          [50.433724, 32.576568],
          [50.432891, 32.575737],
          [50.419001, 32.569344],
          [50.419001, 32.569064],
          [50.417334, 32.568793],
          [50.416225, 32.568233],
          [50.415446, 32.568121],
          [50.409835, 32.566012],
          [50.409558, 32.565732],
          [50.408724, 32.565452],
          [50.376502, 32.554626],
          [50.357332, 32.542951],
          [50.355665, 32.54212],
          [50.354832, 32.54184],
          [50.353165, 32.541373],
          [50.345942, 32.539348],
          [50.345389, 32.539068],
          [50.343722, 32.539068],
          [50.343442, 32.538788],
          [50.342609, 32.538788],
          [50.342332, 32.538508],
          [50.340109, 32.538508],
          [50.335942, 32.537958],
          [50.334556, 32.537398],
          [50.333166, 32.537398],
          [50.332886, 32.537118],
          [50.330109, 32.537118],
          [50.329552, 32.536838],
          [50.325386, 32.536838],
          [50.323719, 32.536838],
          [50.322052, 32.536838],
          [50.321499, 32.537118],
          [50.315386, 32.537118],
          [50.305109, 32.538229],
          [50.303996, 32.538508],
          [50.30261, 32.538508],
          [50.293439, 32.54073],
          [50.292053, 32.54101],
          [50.267883, 32.557398],
          [50.257327, 32.563511],
          [50.256773, 32.564071],
          [50.25594, 32.564351],
          [50.25455, 32.565732],
          [50.252883, 32.567123],
          [50.251217, 32.568793],
          [50.24344, 32.58296],
          [50.242883, 32.583791],
          [50.242607, 32.584902],
          [50.23066, 32.602121],
          [50.230383, 32.60296],
          [50.22927, 32.604071],
          [50.22927, 32.604622],
          [50.22066, 32.613796],
          [50.22038, 32.614067],
          [50.21927, 32.614906],
          [50.218708, 32.615457],
          [50.218713, 32.615457],
          [50.213714, 32.62073],
          [50.212604, 32.621841],
          [50.185942, 32.64248],
          [50.165934, 32.657959],
          [50.153711, 32.663792],
          [50.152324, 32.664062],
          [50.151491, 32.664342],
          [50.150658, 32.664342],
          [50.150101, 32.664622],
          [50.148991, 32.664622],
          [50.148434, 32.664902],
          [50.146491, 32.664902],
          [50.145658, 32.665173],
          [50.142044, 32.664902],
          [50.133988, 32.663791],
          [50.132601, 32.663232],
          [50.131768, 32.663232],
          [50.125378, 32.660451],
          [50.115378, 32.652956],
          [50.103432, 32.646292],
          [50.099265, 32.644902],
          [50.096765, 32.644351],
          [50.094822, 32.643511],
          [50.093989, 32.643511],
          [50.092875, 32.642961],
          [50.092322, 32.642961],
          [50.090932, 32.642401],
          [50.089542, 32.642401],
          [50.089265, 32.642121],
          [50.087042, 32.642121],
          [50.084819, 32.64157],
          [50.083709, 32.64157],
          [50.074542, 32.639069],
          [50.074429, 32.639006],
          [50.058986, 32.630455],
          [50.044819, 32.627683],
          [50.044539, 32.627403],
          [50.042039, 32.627403],
          [50.039263, 32.627954],
          [50.037039, 32.628513],
          [50.035373, 32.628513],
          [50.034819, 32.628793],
          [50.028983, 32.629064],
          [50.024816, 32.628793],
          [50.024263, 32.628793],
          [50.021206, 32.628233],
          [50.020096, 32.628233],
          [50.019816, 32.627953],
          [50.01815, 32.627953],
          [50.017596, 32.627683],
          [50.003427, 32.627953],
          [50.00315, 32.628233],
          [50.002316, 32.628233],
          [50.00176, 32.628513],
          [50.00065, 32.628513],
          [49.986483, 32.633236],
          [49.985927, 32.633236],
          [49.98537, 32.633786],
          [49.982317, 32.634617],
          [49.980023, 32.635987],
          [49.96648, 32.644071],
          [49.966204, 32.644351],
          [49.95037, 32.657958],
          [49.935091, 32.667674],
          [49.912035, 32.676568],
          [49.903978, 32.682121],
          [49.903701, 32.682961],
          [49.902868, 32.683791],
          [49.900924, 32.687403],
          [49.900924, 32.687963],
          [49.900644, 32.688514],
          [49.899535, 32.691015],
          [49.899535, 32.691845],
          [49.896201, 32.697958],
          [49.887588, 32.702121],
          [49.855641, 32.717958],
          [49.855365, 32.718238],
          [49.853975, 32.718789],
          [49.826752, 32.739629],
          [49.812586, 32.744622],
          [49.801195, 32.755737],
          [49.795362, 32.759349],
          [49.795085, 32.759629],
          [49.794529, 32.759629],
          [49.794252, 32.759909],
          [49.791196, 32.760179],
          [49.790639, 32.760459],
          [49.785639, 32.759909],
          [49.785083, 32.759629],
          [49.783416, 32.759629],
          [49.783139, 32.759349],
          [49.777582, 32.759349],
          [49.777306, 32.759629],
          [49.776473, 32.759629],
          [49.767306, 32.764071],
          [49.75314, 32.775737],
          [49.75286, 32.776017],
          [49.75286, 32.776297],
          [49.732303, 32.805732],
          [49.73175, 32.806572],
          [49.73175, 32.806852],
          [49.73175, 32.807132],
          [49.73147, 32.807683],
          [49.73175, 32.807963],
          [49.73175, 32.808243],
          [49.731193, 32.808513],
          [49.729803, 32.809073],
          [49.728136, 32.809073],
          [49.727303, 32.808793],
          [49.725636, 32.808793],
          [49.725636, 32.808513],
          [49.724803, 32.808513],
          [49.719246, 32.805732],
          [49.695914, 32.791015],
          [49.69369, 32.790184],
          [49.69258, 32.789624],
          [49.660077, 32.779348],
          [49.655634, 32.779348],
          [49.655078, 32.779069],
          [49.644244, 32.779069],
          [49.643968, 32.779348],
          [49.641188, 32.779348],
          [49.640634, 32.779629],
          [49.638688, 32.779899],
          [49.636468, 32.779899],
          [49.635631, 32.780179],
          [49.634521, 32.780179],
          [49.633688, 32.780459],
          [49.633131, 32.780459],
          [49.632578, 32.780739],
          [49.631464, 32.780739],
          [49.628688, 32.78157],
          [49.627578, 32.78157],
          [49.627298, 32.78185],
          [49.618964, 32.784622],
          [49.618468, 32.784788],
          [49.618131, 32.784901],
          [49.604242, 32.789624],
          [49.602575, 32.789624],
          [49.602298, 32.789904],
          [49.600355, 32.789904],
          [49.599798, 32.790184],
          [49.594242, 32.790184],
          [49.593685, 32.789904],
          [49.592851, 32.789904],
          [49.590632, 32.789073],
          [49.584518, 32.78185],
          [49.583408, 32.779348],
          [49.581742, 32.774066],
          [49.581185, 32.768793],
          [49.580909, 32.768513],
          [49.580075, 32.7624],
          [49.578962, 32.760179],
          [49.577575, 32.746292],
          [49.577852, 32.746012],
          [49.577575, 32.740179],
          [49.573962, 32.737407],
          [49.573128, 32.737407],
          [49.571742, 32.736847],
          [49.570352, 32.736847],
          [49.570075, 32.736567],
          [49.568962, 32.736567],
          [49.568129, 32.736287],
          [49.561742, 32.735177],
          [49.558406, 32.735177],
          [49.558129, 32.735457],
          [49.557572, 32.735177],
          [49.557019, 32.735457],
          [49.556186, 32.735177],
          [49.555905, 32.735457],
          [49.553686, 32.735457],
          [49.553129, 32.735737],
          [49.552019, 32.735737],
          [49.551462, 32.736016],
          [49.550072, 32.736287],
          [49.543962, 32.735737],
          [49.540906, 32.734897],
          [49.531182, 32.730184],
          [49.530349, 32.730184],
          [49.526463, 32.728793],
          [49.525906, 32.728793],
          [49.525349, 32.728513],
          [49.524516, 32.728513],
          [49.524239, 32.728233],
          [49.52146, 32.727962],
          [49.52146, 32.727682],
          [49.520626, 32.727682],
          [49.519793, 32.727402],
          [49.515906, 32.725181],
          [49.515533, 32.725181],
          [49.515778, 32.724825],
          [49.514697, 32.724213],
          [49.513042, 32.722944],
          [49.512013, 32.722127],
          [49.511877, 32.722037],
          [49.510766, 32.721514],
          [49.510184, 32.721228],
          [49.507206, 32.719594],
          [49.505588, 32.718885],
          [49.504757, 32.718446],
          [49.503494, 32.717779],
          [49.503436, 32.717741],
          [49.502148, 32.716895],
          [49.501544, 32.716547],
          [49.500826, 32.715671],
          [49.500449, 32.714785],
          [49.500289, 32.713888],
          [49.500239, 32.711347],
          [49.500206, 32.709632],
          [49.500077, 32.703093],
        ],
      ],
    },
  },
  Fars: {
    properties: { value: "Fars", name: "استان فارس, ایران", id: 16 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [50.603183, 29.943728],
          [50.60457, 29.943728],
          [50.605403, 29.944008],
          [50.60846, 29.944008],
          [50.609016, 29.943728],
          [50.611236, 29.943728],
          [50.611793, 29.943449],
          [50.614293, 29.943449],
          [50.62485, 29.94012],
          [50.625959, 29.94012],
          [50.626793, 29.93984],
          [50.627906, 29.93984],
          [50.62874, 29.939561],
          [50.630683, 29.93929],
          [50.634293, 29.93929],
          [50.642349, 29.937342],
          [50.645682, 29.935402],
          [50.648182, 29.932895],
          [50.654849, 29.92373],
          [50.657353, 29.919003],
          [50.658186, 29.917343],
          [50.660129, 29.915115],
          [50.660406, 29.914845],
          [50.680129, 29.897616],
          [50.696242, 29.887612],
          [50.713465, 29.873451],
          [50.713742, 29.873171],
          [50.712632, 29.866226],
          [50.708742, 29.864836],
          [50.706242, 29.864837],
          [50.705685, 29.865396],
          [50.704575, 29.865955],
          [50.703465, 29.866505],
          [50.703465, 29.866785],
          [50.700685, 29.866785],
          [50.695409, 29.861508],
          [50.694575, 29.861508],
          [50.694019, 29.861228],
          [50.689852, 29.862338],
          [50.687352, 29.85928],
          [50.685128, 29.85928],
          [50.683742, 29.860669],
          [50.684852, 29.864566],
          [50.684295, 29.865116],
          [50.683742, 29.865116],
          [50.682909, 29.865675],
          [50.681519, 29.864007],
          [50.680962, 29.861788],
          [50.680409, 29.860669],
          [50.679852, 29.860949],
          [50.679019, 29.861788],
          [50.675962, 29.863177],
          [50.675129, 29.862897],
          [50.674296, 29.860669],
          [50.674576, 29.85845],
          [50.674852, 29.857341],
          [50.675129, 29.856511],
          [50.675409, 29.856231],
          [50.674019, 29.854842],
          [50.673186, 29.854842],
          [50.672906, 29.854563],
          [50.672072, 29.854563],
          [50.671519, 29.854842],
          [50.669572, 29.854842],
          [50.669019, 29.854283],
          [50.670406, 29.852344],
          [50.670962, 29.850395],
          [50.670686, 29.848456],
          [50.669019, 29.847067],
          [50.668186, 29.847338],
          [50.665686, 29.847067],
          [50.665406, 29.845949],
          [50.665962, 29.845678],
          [50.667072, 29.84456],
          [50.668186, 29.844009],
          [50.667629, 29.841502],
          [50.667072, 29.841231],
          [50.664852, 29.841502],
          [50.664572, 29.839842],
          [50.665129, 29.839283],
          [50.665962, 29.839283],
          [50.666519, 29.838724],
          [50.665686, 29.838173],
          [50.664296, 29.838173],
          [50.663462, 29.838724],
          [50.662629, 29.838724],
          [50.662072, 29.839003],
          [50.661239, 29.839003],
          [50.660406, 29.837344],
          [50.660686, 29.837064],
          [50.660686, 29.836225],
          [50.660129, 29.835675],
          [50.656519, 29.835675],
          [50.655962, 29.835395],
          [50.654296, 29.835395],
          [50.651516, 29.834566],
          [50.644573, 29.82873],
          [50.644016, 29.827061],
          [50.644016, 29.825392],
          [50.644849, 29.822064],
          [50.64763, 29.817058],
          [50.665686, 29.805675],
          [50.668462, 29.804836],
          [50.703742, 29.791506],
          [50.730408, 29.787339],
          [50.732355, 29.786509],
          [50.745688, 29.783451],
          [50.748465, 29.783172],
          [50.751244, 29.783172],
          [50.752635, 29.782892],
          [50.765411, 29.781783],
          [50.773744, 29.781783],
          [50.775134, 29.782062],
          [50.779024, 29.782062],
          [50.780411, 29.781783],
          [50.782911, 29.781783],
          [50.787914, 29.781223],
          [50.791247, 29.780673],
          [50.79819, 29.780673],
          [50.798747, 29.780394],
          [50.800134, 29.780394],
          [50.800691, 29.780114],
          [50.801801, 29.780114],
          [50.815414, 29.775397],
          [50.81847, 29.775667],
          [50.81958, 29.775397],
          [50.820137, 29.775668],
          [50.82069, 29.775397],
          [50.822637, 29.775947],
          [50.825694, 29.775947],
          [50.826247, 29.775668],
          [50.82683, 29.775437],
          [50.827637, 29.775117],
          [50.83458, 29.773728],
          [50.836527, 29.773728],
          [50.83736, 29.773449],
          [50.844303, 29.773449],
          [50.847636, 29.774008],
          [50.852083, 29.774279],
          [50.857916, 29.774279],
          [50.859026, 29.774558],
          [50.872916, 29.774558],
          [50.927362, 29.780114],
          [50.930142, 29.780114],
          [50.931809, 29.780394],
          [50.934585, 29.780394],
          [50.936252, 29.780673],
          [50.945142, 29.780673],
          [50.947642, 29.780953],
          [50.961808, 29.781783],
          [50.967365, 29.781783],
          [50.968475, 29.782062],
          [50.971255, 29.782062],
          [50.999868, 29.782892],
          [51.017367, 29.777895],
          [51.017924, 29.777895],
          [51.018201, 29.777616],
          [51.019034, 29.777616],
          [51.019591, 29.777336],
          [51.022644, 29.776777],
          [51.0257, 29.776507],
          [51.031534, 29.776507],
          [51.03181, 29.776227],
          [51.0332, 29.776227],
          [51.033757, 29.775947],
          [51.03459, 29.775947],
          [51.034867, 29.775668],
          [51.037647, 29.774838],
          [51.0382, 29.774558],
          [51.038757, 29.774558],
          [51.03959, 29.774558],
          [51.039867, 29.774279],
          [51.051257, 29.770951],
          [51.09015, 29.750946],
          [51.09376, 29.749836],
          [51.113759, 29.740944],
          [51.162372, 29.717611],
          [51.165428, 29.716502],
          [51.172652, 29.714283],
          [51.174318, 29.714283],
          [51.177375, 29.713724],
          [51.189598, 29.712615],
          [51.198485, 29.712615],
          [51.199875, 29.712335],
          [51.203765, 29.712335],
          [51.205155, 29.712056],
          [51.212098, 29.711505],
          [51.215988, 29.711505],
          [51.216821, 29.711226],
          [51.223488, 29.711226],
          [51.224044, 29.710946],
          [51.226821, 29.710946],
          [51.227931, 29.711226],
          [51.234044, 29.711226],
          [51.234598, 29.710946],
          [51.240987, 29.710676],
          [51.243767, 29.710396],
          [51.244601, 29.710396],
          [51.245154, 29.710117],
          [51.246821, 29.710117],
          [51.247377, 29.709837],
          [51.248767, 29.709837],
          [51.249044, 29.709557],
          [51.250434, 29.709557],
          [51.250711, 29.709278],
          [51.262377, 29.707339],
          [51.278214, 29.700665],
          [51.286547, 29.695398],
          [51.286823, 29.695109],
          [51.28738, 29.694839],
          [51.288072, 29.694123],
          [51.2971, 29.686505],
          [51.29738, 29.685667],
          [51.297937, 29.685116],
          [51.297937, 29.684557],
          [51.299323, 29.679002],
          [51.299603, 29.678443],
          [51.296547, 29.658448],
          [51.28738, 29.64483],
          [51.28488, 29.641782],
          [51.274044, 29.625946],
          [51.270434, 29.614835],
          [51.269877, 29.60983],
          [51.270157, 29.608171],
          [51.270434, 29.607332],
          [51.27071, 29.605664],
          [51.272412, 29.601198],
          [51.273085, 29.599433],
          [51.277377, 29.588169],
          [51.277657, 29.58761],
          [51.277657, 29.587339],
          [51.278214, 29.584003],
          [51.27849, 29.582614],
          [51.29738, 29.530663],
          [51.297937, 29.530113],
          [51.302937, 29.519833],
          [51.304323, 29.517885],
          [51.320993, 29.497614],
          [51.326547, 29.493168],
          [51.32738, 29.492889],
          [51.32766, 29.492609],
          [51.329603, 29.4915],
          [51.32988, 29.490941],
          [51.330436, 29.490941],
          [51.330713, 29.490391],
          [51.351999, 29.473467],
          [51.364606, 29.463439],
          [51.365716, 29.463439],
          [51.366826, 29.463998],
          [51.367659, 29.463998],
          [51.368772, 29.464557],
          [51.375439, 29.4715],
          [51.375996, 29.4715],
          [51.376829, 29.47178],
          [51.379329, 29.47178],
          [51.381549, 29.470941],
          [51.382662, 29.469002],
          [51.385162, 29.464278],
          [51.389882, 29.4615],
          [51.391549, 29.4615],
          [51.394885, 29.463719],
          [51.395995, 29.463718],
          [51.396272, 29.463998],
          [51.397386, 29.463998],
          [51.397939, 29.464278],
          [51.400439, 29.464278],
          [51.400995, 29.463998],
          [51.401272, 29.464278],
          [51.401829, 29.463998],
          [51.404052, 29.463998],
          [51.406829, 29.462889],
          [51.407939, 29.46205],
          [51.408772, 29.46178],
          [51.412385, 29.462609],
          [51.422662, 29.473439],
          [51.427662, 29.475107],
          [51.433498, 29.473718],
          [51.435998, 29.473718],
          [51.436275, 29.473998],
          [51.437385, 29.473718],
          [51.437942, 29.473998],
          [51.442108, 29.473998],
          [51.444331, 29.470662],
          [51.444051, 29.469553],
          [51.444332, 29.469003],
          [51.445998, 29.466496],
          [51.447108, 29.465666],
          [51.448221, 29.465666],
          [51.449888, 29.466225],
          [51.451555, 29.466496],
          [51.452108, 29.466225],
          [51.452941, 29.466225],
          [51.454331, 29.465666],
          [51.457388, 29.46205],
          [51.458498, 29.4615],
          [51.459331, 29.4615],
          [51.459888, 29.46178],
          [51.464331, 29.46178],
          [51.465721, 29.46178],
          [51.466275, 29.46178],
          [51.469888, 29.462609],
          [51.474611, 29.462609],
          [51.474611, 29.462889],
          [51.477664, 29.4615],
          [51.478778, 29.4615],
          [51.479331, 29.46178],
          [51.481278, 29.46178],
          [51.482387, 29.462609],
          [51.484054, 29.462609],
          [51.484611, 29.46233],
          [51.485997, 29.461221],
          [51.485721, 29.460112],
          [51.483221, 29.455946],
          [51.483221, 29.454828],
          [51.483221, 29.454557],
          [51.483497, 29.453719],
          [51.484611, 29.450662],
          [51.488501, 29.448723],
          [51.489611, 29.448723],
          [51.489887, 29.448444],
          [51.490444, 29.448723],
          [51.502387, 29.45261],
          [51.503777, 29.45261],
          [51.504334, 29.452889],
          [51.505167, 29.452889],
          [51.505444, 29.453169],
          [51.50739, 29.453169],
          [51.507667, 29.452889],
          [51.5085, 29.452889],
          [51.511834, 29.451221],
          [51.51711, 29.446226],
          [51.51739, 29.445108],
          [51.517667, 29.44233],
          [51.517944, 29.442051],
          [51.517944, 29.440942],
          [51.519334, 29.439833],
          [51.521, 29.439003],
          [51.52211, 29.439003],
          [51.522667, 29.438724],
          [51.523777, 29.438724],
          [51.524057, 29.438444],
          [51.52989, 29.438444],
          [51.530447, 29.438165],
          [51.531557, 29.438165],
          [51.53989, 29.433999],
          [51.551556, 29.425388],
          [51.55239, 29.42427],
          [51.55267, 29.421772],
          [51.552947, 29.421222],
          [51.551837, 29.414271],
          [51.553503, 29.411773],
          [51.554613, 29.410943],
          [51.555723, 29.410105],
          [51.557113, 29.409834],
          [51.559613, 29.408996],
          [51.56017, 29.408446],
          [51.56628, 29.40511],
          [51.56656, 29.404551],
          [51.567113, 29.404271],
          [51.56878, 29.400665],
          [51.569613, 29.400665],
          [51.569893, 29.400385],
          [51.571836, 29.400385],
          [51.574336, 29.39594],
          [51.574613, 29.395669],
          [51.574613, 29.39511],
          [51.577113, 29.393442],
          [51.577669, 29.393722],
          [51.579336, 29.393722],
          [51.579613, 29.393442],
          [51.581003, 29.393442],
          [51.581003, 29.393163],
          [51.582669, 29.393163],
          [51.586003, 29.392054],
          [51.586559, 29.391495],
          [51.587116, 29.391495],
          [51.587116, 29.391215],
          [51.588503, 29.388997],
          [51.590169, 29.385661],
          [51.592129, 29.383484],
          [51.592669, 29.382884],
          [51.592669, 29.382605],
          [51.620729, 29.317329],
          [51.621839, 29.313722],
          [51.622116, 29.312604],
          [51.632672, 29.292328],
          [51.633505, 29.291499],
          [51.633782, 29.29066],
          [51.634895, 29.289551],
          [51.635172, 29.288722],
          [51.662952, 29.250659],
          [51.665452, 29.247044],
          [51.666008, 29.245935],
          [51.666842, 29.245106],
          [51.674061, 29.23427],
          [51.676008, 29.232322],
          [51.676565, 29.231214],
          [51.677118, 29.230943],
          [51.677675, 29.229825],
          [51.708231, 29.195938],
          [51.709341, 29.19538],
          [51.709621, 29.19483],
          [51.710174, 29.19455],
          [51.710769, 29.193759],
          [51.711008, 29.193441],
          [51.711288, 29.193162],
          [51.711841, 29.192323],
          [51.720178, 29.181488],
          [51.724064, 29.174267],
          [51.724621, 29.173997],
          [51.725843, 29.172551],
          [51.726287, 29.17232],
          [51.75851, 29.139553],
          [51.760734, 29.138156],
          [51.795457, 29.115098],
          [51.80657, 29.103713],
          [51.807127, 29.102604],
          [51.809346, 29.099549],
          [51.809903, 29.098431],
          [51.81018, 29.098161],
          [51.81268, 29.092599],
          [51.81268, 29.092329],
          [51.81268, 29.09177],
          [51.81268, 29.090661],
          [51.813594, 29.08903],
          [51.813793, 29.088435],
          [51.81546, 29.085659],
          [51.816293, 29.076771],
          [51.81657, 29.076492],
          [51.815179, 29.068163],
          [51.81296, 29.065378],
          [51.794903, 29.047324],
          [51.794623, 29.046765],
          [51.79407, 29.044548],
          [51.833516, 29.008991],
          [51.835183, 29.007324],
          [51.836016, 29.007044],
          [51.837403, 29.005377],
          [51.842683, 29.000158],
          [51.847458, 28.996225],
          [51.867406, 28.979825],
          [51.876849, 28.961764],
          [51.887962, 28.947317],
          [51.888239, 28.945929],
          [51.888795, 28.9451],
          [51.895185, 28.926489],
          [51.895185, 28.925372],
          [51.896019, 28.923155],
          [51.896295, 28.922596],
          [51.896295, 28.922317],
          [51.906575, 28.89593],
          [51.923518, 28.864542],
          [51.932131, 28.852043],
          [51.943241, 28.839264],
          [51.944355, 28.838147],
          [51.959078, 28.823432],
          [51.960464, 28.821206],
          [51.970943, 28.789777],
          [51.974354, 28.779539],
          [51.978801, 28.757039],
          [51.980744, 28.74565],
          [51.980744, 28.744542],
          [51.981021, 28.743983],
          [51.992687, 28.69759],
          [51.992967, 28.69704],
          [51.992967, 28.69204],
          [51.992967, 28.69176],
          [51.993244, 28.691202],
          [51.993244, 28.690931],
          [51.993244, 28.690373],
          [51.993521, 28.688985],
          [51.99741, 28.67593],
          [51.999077, 28.671479],
          [51.999357, 28.669812],
          [52.001024, 28.665929],
          [52.001024, 28.6651],
          [52.007134, 28.646478],
          [52.007411, 28.645928],
          [52.009357, 28.639261],
          [52.009357, 28.638982],
          [52.009634, 28.638144],
          [52.01019, 28.637036],
          [52.010467, 28.636207],
          [52.010744, 28.635099],
          [52.0113, 28.63482],
          [52.013524, 28.630928],
          [52.019914, 28.605648],
          [52.02019, 28.603152],
          [52.020747, 28.601477],
          [52.021024, 28.598981],
          [52.03547, 28.5412],
          [52.036023, 28.531201],
          [52.036304, 28.529255],
          [52.03658, 28.528147],
          [52.037413, 28.521481],
          [52.03797, 28.51731],
          [52.043804, 28.486755],
          [52.04797, 28.460922],
          [52.048523, 28.457589],
          [52.04908, 28.453977],
          [52.049637, 28.450086],
          [52.05019, 28.446474],
          [52.051023, 28.441754],
          [52.051303, 28.440925],
          [52.05408, 28.42981],
          [52.05408, 28.429531],
          [52.05408, 28.428423],
          [52.055823, 28.423069],
          [52.056027, 28.422587],
          [52.05908, 28.415922],
          [52.080843, 28.363846],
          [52.097139, 28.324812],
          [52.104083, 28.310646],
          [52.106026, 28.306476],
          [52.111306, 28.297308],
          [52.112139, 28.295084],
          [52.112696, 28.294535],
          [52.122972, 28.262304],
          [52.123249, 28.25981],
          [52.123529, 28.258702],
          [52.121863, 28.228976],
          [52.121582, 28.22397],
          [52.121306, 28.220359],
          [52.121582, 28.213416],
          [52.121862, 28.211192],
          [52.125472, 28.199531],
          [52.141306, 28.174805],
          [52.141306, 28.174526],
          [52.141477, 28.174182],
          [52.143529, 28.170078],
          [52.158252, 28.145641],
          [52.159085, 28.144803],
          [52.160195, 28.143696],
          [52.170752, 28.130082],
          [52.179085, 28.123419],
          [52.199088, 28.111472],
          [52.217421, 28.095355],
          [52.220478, 28.091754],
          [52.220754, 28.091187],
          [52.229921, 28.079807],
          [52.233254, 28.073415],
          [52.234087, 28.072587],
          [52.2652, 28.044245],
          [52.278537, 28.037304],
          [52.283537, 28.035081],
          [52.29409, 28.030354],
          [52.29548, 28.030084],
          [52.301593, 28.028419],
          [52.302703, 28.028419],
          [52.30437, 28.027861],
          [52.30548, 28.027861],
          [52.30937, 28.027023],
          [52.31076, 28.027023],
          [52.333259, 28.023134],
          [52.335202, 28.023134],
          [52.339373, 28.022306],
          [52.363262, 28.015077],
          [52.364095, 28.014807],
          [52.364649, 28.014807],
          [52.368539, 28.012304],
          [52.376039, 28.005913],
          [52.377429, 28.004247],
          [52.378262, 28.003689],
          [52.380205, 28.001475],
          [52.382152, 27.998693],
          [52.387557, 27.993546],
          [52.387985, 27.993139],
          [52.389372, 27.98925],
          [52.389652, 27.988133],
          [52.389928, 27.987305],
          [52.390205, 27.985631],
          [52.390485, 27.983975],
          [52.391038, 27.980914],
          [52.391318, 27.979249],
          [52.391595, 27.977583],
          [52.391872, 27.97536],
          [52.393262, 27.96841],
          [52.401595, 27.948409],
          [52.402708, 27.947301],
          [52.402985, 27.947022],
          [52.406719, 27.940786],
          [52.407152, 27.940352],
          [52.427151, 27.912295],
          [52.436321, 27.895634],
          [52.436598, 27.895076],
          [52.441598, 27.885913],
          [52.442431, 27.885076],
          [52.442988, 27.883969],
          [52.449654, 27.872025],
          [52.449931, 27.871746],
          [52.455211, 27.856184],
          [52.455211, 27.855077],
          [52.457988, 27.847022],
          [52.458264, 27.846464],
          [52.458544, 27.846185],
          [52.464097, 27.837302],
          [52.486877, 27.809519],
          [52.501043, 27.787298],
          [52.5016, 27.78647],
          [52.5016, 27.786182],
          [52.5066, 27.775635],
          [52.508267, 27.762577],
          [52.508547, 27.757294],
          [52.508823, 27.752021],
          [52.50938, 27.747575],
          [52.509627, 27.746578],
          [52.509933, 27.743967],
          [52.51049, 27.742014],
          [52.513547, 27.732574],
          [52.526603, 27.707297],
          [52.527156, 27.70646],
          [52.527156, 27.705902],
          [52.527713, 27.705353],
          [52.527713, 27.704795],
          [52.54077, 27.668964],
          [52.54077, 27.667848],
          [52.541046, 27.66702],
          [52.541603, 27.666183],
          [52.541603, 27.665905],
          [52.541603, 27.665626],
          [52.54188, 27.665356],
          [52.54188, 27.665077],
          [52.542713, 27.663124],
          [52.548546, 27.653685],
          [52.550769, 27.649519],
          [52.55077, 27.64924],
          [52.551046, 27.648961],
          [52.551046, 27.648682],
          [52.563269, 27.624515],
          [52.563549, 27.624245],
          [52.597439, 27.586457],
          [52.601882, 27.581742],
          [52.602715, 27.580627],
          [52.613358, 27.571947],
          [52.623828, 27.563407],
          [52.629105, 27.561184],
          [52.629938, 27.561185],
          [52.631885, 27.560627],
          [52.632995, 27.560627],
          [52.633552, 27.560348],
          [52.634662, 27.560348],
          [52.635495, 27.560069],
          [52.636605, 27.560069],
          [52.637162, 27.55979],
          [52.640495, 27.55979],
          [52.640775, 27.559511],
          [52.647718, 27.557568],
          [52.660218, 27.551738],
          [52.666884, 27.548409],
          [52.685497, 27.538684],
          [52.687164, 27.53729],
          [52.687441, 27.53729],
          [52.687721, 27.537011],
          [52.687997, 27.536741],
          [52.73689, 27.510904],
          [52.73689, 27.510625],
          [52.73911, 27.509798],
          [52.742347, 27.508473],
          [52.745223, 27.507297],
          [52.746057, 27.507297],
          [52.754666, 27.502853],
          [52.754946, 27.502295],
          [52.758, 27.500064],
          [52.768017, 27.490331],
          [52.768556, 27.489512],
          [52.774669, 27.483125],
          [52.775779, 27.480903],
          [52.788836, 27.467851],
          [52.798836, 27.461455],
          [52.800779, 27.46007],
          [52.808836, 27.453962],
          [52.808836, 27.453683],
          [52.817312, 27.448598],
          [52.828282, 27.442016],
          [52.844369, 27.42815],
          [52.844672, 27.427849],
          [52.845225, 27.42757],
          [52.845225, 27.427292],
          [52.846895, 27.425628],
          [52.847728, 27.425349],
          [52.880504, 27.401738],
          [52.918781, 27.374002],
          [52.918781, 27.373732],
          [52.919614, 27.373732],
          [52.919891, 27.373453],
          [52.920724, 27.373453],
          [52.927114, 27.371224],
          [53.004316, 27.348284],
          [53.01519, 27.341361],
          [53.020451, 27.327268],
          [53.030892, 27.322324],
          [53.083621, 27.300548],
          [53.108984, 27.283715],
          [53.112871, 27.283715],
          [53.113984, 27.283993],
          [53.13714, 27.276339],
          [53.13825, 27.276617],
          [53.13964, 27.276617],
          [53.140474, 27.276896],
          [53.14075, 27.276896],
          [53.141307, 27.276617],
          [53.141583, 27.276339],
          [53.152417, 27.271618],
          [53.170186, 27.268088],
          [53.170743, 27.267539],
          [53.1713, 27.267539],
          [53.179633, 27.262818],
          [53.180186, 27.262539],
          [53.197689, 27.256146],
          [53.229689, 27.239785],
          [53.269282, 27.235696],
          [53.270392, 27.23486],
          [53.276502, 27.232081],
          [53.308067, 27.222579],
          [53.33625, 27.208112],
          [53.337637, 27.207842],
          [53.346267, 27.198679],
          [53.34997, 27.19201],
          [53.350247, 27.191732],
          [53.35136, 27.191732],
          [53.351914, 27.191453],
          [53.352747, 27.191453],
          [53.353027, 27.191174],
          [53.35358, 27.191174],
          [53.35386, 27.190895],
          [53.354137, 27.190617],
          [53.354694, 27.190347],
          [53.356917, 27.186454],
          [53.36275, 27.170898],
          [53.374417, 27.148115],
          [53.37525, 27.147288],
          [53.384387, 27.130838],
          [53.410377, 27.124536],
          [53.409696, 27.11395],
          [53.410529, 27.11395],
          [53.415527, 27.106506],
          [53.446498, 27.106483],
          [53.446623, 27.106457],
          [53.446652, 27.106451],
          [53.446832, 27.106414],
          [53.448029, 27.106173],
          [53.449699, 27.106173],
          [53.473658, 27.106092],
          [53.475325, 27.106092],
          [53.477548, 27.105535],
          [53.478935, 27.105535],
          [53.479768, 27.105256],
          [53.481158, 27.105256],
          [53.481991, 27.104986],
          [53.483105, 27.104986],
          [53.483658, 27.104698],
          [53.484491, 27.104698],
          [53.496438, 27.102757],
          [53.501438, 27.102757],
          [53.504494, 27.102757],
          [53.505604, 27.102757],
          [53.506161, 27.103035],
          [53.506994, 27.103035],
          [53.507271, 27.103314],
          [53.508104, 27.103314],
          [53.508938, 27.104141],
          [53.511161, 27.104986],
          [53.511714, 27.105535],
          [53.512271, 27.105535],
          [53.536937, 27.091815],
          [53.537217, 27.092094],
          [53.537494, 27.091815],
          [53.580993, 27.1399],
          [53.581133, 27.13976],
          [53.58141, 27.139482],
          [53.581686, 27.139203],
          [53.581687, 27.138925],
          [53.582243, 27.138098],
          [53.604079, 27.125952],
          [53.626026, 27.114289],
          [53.642692, 27.108176],
          [53.677695, 27.098181],
          [53.682695, 27.09623],
          [53.683249, 27.095951],
          [53.684639, 27.095682],
          [53.751587, 27.068743],
          [53.80973, 27.068015],
          [53.810283, 27.068573],
          [53.843629, 27.071455],
          [53.844462, 27.072013],
          [53.871845, 27.085262],
          [53.873235, 27.08581],
          [53.873792, 27.086368],
          [53.906938, 27.111451],
          [53.911105, 27.113114],
          [53.913051, 27.113671],
          [53.918885, 27.115901],
          [53.933328, 27.123678],
          [53.934161, 27.124505],
          [53.964068, 27.14558],
          [53.949737, 27.166085],
          [53.891566, 27.222255],
          [53.8824, 27.237543],
          [53.881567, 27.238659],
          [53.895492, 27.283926],
          [53.965831, 27.335344],
          [53.97166, 27.33931],
          [53.977027, 27.341536],
          [53.977397, 27.341723],
          [53.978137, 27.342373],
          [53.978137, 27.342643],
          [53.995637, 27.348759],
          [53.995917, 27.349038],
          [54.0048, 27.352126],
          [54.012306, 27.352366],
          [54.015791, 27.353439],
          [54.034152, 27.358847],
          [54.044494, 27.361096],
          [54.079513, 27.362316],
          [54.126891, 27.380914],
          [54.181136, 27.411396],
          [54.195665, 27.427215],
          [54.212411, 27.437319],
          [54.213244, 27.437319],
          [54.214631, 27.437877],
          [54.216854, 27.438147],
          [54.222411, 27.439541],
          [54.234357, 27.441763],
          [54.236577, 27.441763],
          [54.268882, 27.446502],
          [54.28769, 27.449257],
          [54.288523, 27.449545],
          [54.293803, 27.449545],
          [54.305469, 27.447881],
          [54.306579, 27.448151],
          [54.310749, 27.44815],
          [54.319359, 27.449257],
          [54.321026, 27.449257],
          [54.322136, 27.449545],
          [54.322969, 27.449257],
          [54.323526, 27.448987],
          [54.324359, 27.448987],
          [54.324916, 27.448708],
          [54.327969, 27.448708],
          [54.328249, 27.448987],
          [54.328806, 27.448987],
          [54.329082, 27.448708],
          [54.329639, 27.448708],
          [54.329916, 27.448987],
          [54.333526, 27.448987],
          [54.333806, 27.448708],
          [54.336306, 27.448708],
          [54.337139, 27.448429],
          [54.339639, 27.448429],
          [54.340192, 27.44815],
          [54.347139, 27.44815],
          [54.348806, 27.447881],
          [54.349082, 27.447593],
          [54.350472, 27.447593],
          [54.364639, 27.443985],
          [54.366028, 27.443985],
          [54.367138, 27.443707],
          [54.371028, 27.443707],
          [54.373528, 27.443149],
          [54.377695, 27.443149],
          [54.378805, 27.442879],
          [54.380472, 27.442879],
          [54.385752, 27.443707],
          [54.387695, 27.443706],
          [54.390475, 27.444264],
          [54.392141, 27.444264],
          [54.501639, 27.452316],
          [54.501919, 27.452595],
          [54.503862, 27.452595],
          [54.504695, 27.452873],
          [54.508029, 27.452873],
          [54.508585, 27.453152],
          [54.516918, 27.453152],
          [54.518309, 27.453431],
          [54.523028, 27.453431],
          [54.523862, 27.45371],
          [54.534418, 27.45371],
          [54.534975, 27.453431],
          [54.537613, 27.453431],
          [54.588081, 27.46562],
          [54.589747, 27.465899],
          [54.596134, 27.465899],
          [54.5978, 27.466187],
          [54.603914, 27.466187],
          [54.608357, 27.466457],
          [54.610024, 27.466736],
          [54.611137, 27.466735],
          [54.61669, 27.467014],
          [54.61808, 27.467293],
          [54.62197, 27.467293],
          [54.62336, 27.467572],
          [54.62836, 27.467572],
          [54.628913, 27.467851],
          [54.641137, 27.467851],
          [54.641693, 27.467572],
          [54.646417, 27.467572],
          [54.64697, 27.467293],
          [54.651136, 27.467293],
          [54.65447, 27.466735],
          [54.65697, 27.466735],
          [54.71878, 27.474162],
          [54.874479, 27.442286],
          [54.878093, 27.442286],
          [54.878926, 27.442016],
          [54.880623, 27.431656],
          [54.882289, 27.431378],
          [54.885069, 27.431378],
          [54.890903, 27.430541],
          [54.893403, 27.430541],
          [54.920625, 27.427491],
          [54.924792, 27.427491],
          [54.925625, 27.427212],
          [54.929515, 27.427213],
          [54.945625, 27.425548],
          [54.952572, 27.425548],
          [54.981078, 27.426634],
          [55.022216, 27.424651],
          [55.026659, 27.424651],
          [55.028326, 27.424372],
          [55.032773, 27.424372],
          [55.037216, 27.423823],
          [55.039992, 27.423823],
          [55.040826, 27.423544],
          [55.043049, 27.423498],
          [55.131095, 27.416662],
          [55.131929, 27.416932],
          [55.133595, 27.416932],
          [55.133872, 27.41722],
          [55.134985, 27.41722],
          [55.137762, 27.418327],
          [55.138042, 27.418606],
          [55.138319, 27.418884],
          [55.140819, 27.419433],
          [55.143319, 27.420549],
          [55.143875, 27.420549],
          [55.143875, 27.420827],
          [55.144708, 27.420828],
          [55.146375, 27.421664],
          [55.146375, 27.421934],
          [55.20636, 27.421488],
          [55.22919, 27.417096],
          [55.23891, 27.4221],
          [55.243356, 27.424323],
          [55.274963, 27.45954],
          [55.287149, 27.485323],
          [55.287429, 27.485881],
          [55.287983, 27.485881],
          [55.293796, 27.507367],
          [55.294068, 27.507641],
          [55.319129, 27.540171],
          [55.321072, 27.542951],
          [55.323409, 27.556974],
          [55.326742, 27.560033],
          [55.331446, 27.571738],
          [55.336889, 27.578697],
          [55.339603, 27.590149],
          [55.343796, 27.601751],
          [55.344629, 27.602588],
          [55.352196, 27.623206],
          [55.352753, 27.623485],
          [55.374976, 27.639588],
          [55.396492, 27.677847],
          [55.397049, 27.678675],
          [55.399825, 27.681175],
          [55.401772, 27.683676],
          [55.403439, 27.686455],
          [55.403992, 27.686734],
          [55.441081, 27.699353],
          [55.465894, 27.705632],
          [55.46756, 27.709519],
          [55.482436, 27.71261],
          [55.491603, 27.723166],
          [55.492436, 27.723445],
          [55.49855, 27.729835],
          [55.50216, 27.732049],
          [55.500063, 27.742302],
          [55.505057, 27.751101],
          [55.512836, 27.756095],
          [55.51367, 27.756095],
          [55.516446, 27.75776],
          [55.51728, 27.758318],
          [55.535336, 27.772212],
          [55.540853, 27.773569],
          [55.541409, 27.774397],
          [55.541963, 27.774676],
          [55.541963, 27.774955],
          [55.543076, 27.776638],
          [55.561859, 27.788135],
          [55.566302, 27.794525],
          [55.567692, 27.796748],
          [55.567969, 27.798413],
          [55.568246, 27.799241],
          [55.579512, 27.803687],
          [55.656738, 27.825414],
          [55.727119, 27.819341],
          [55.732956, 27.829058],
          [55.729522, 27.842112],
          [55.713814, 27.85603],
          [55.713538, 27.856867],
          [55.716364, 27.86182],
          [55.715958, 27.863057],
          [55.715811, 27.863206],
          [55.710481, 27.870468],
          [55.710204, 27.870747],
          [55.710205, 27.871026],
          [55.698623, 27.878232],
          [55.693725, 27.904051],
          [55.676994, 27.906755],
          [55.677822, 27.936085],
          [55.677265, 27.936364],
          [55.676712, 27.937191],
          [55.685234, 27.944977],
          [55.688748, 27.956842],
          [55.684024, 27.964614],
          [55.681248, 27.967106],
          [55.679025, 27.969337],
          [55.625203, 27.981809],
          [55.624646, 27.982088],
          [55.624646, 27.982366],
          [55.579147, 27.995008],
          [55.524102, 27.990422],
          [55.480513, 27.981155],
          [55.465237, 27.98921],
          [55.462457, 27.990866],
          [55.427734, 28.006986],
          [55.402188, 28.009051],
          [55.397188, 28.010996],
          [55.394965, 28.01294],
          [55.381555, 28.027074],
          [55.381835, 28.029298],
          [55.381835, 28.029774],
          [55.385169, 28.039299],
          [55.385445, 28.040137],
          [55.385725, 28.041793],
          [55.386002, 28.043188],
          [55.386279, 28.044575],
          [55.386559, 28.045682],
          [55.399589, 28.078342],
          [55.409608, 28.082612],
          [55.408775, 28.093164],
          [55.408495, 28.095388],
          [55.416688, 28.111986],
          [55.416968, 28.112256],
          [55.417244, 28.11476],
          [55.417521, 28.118372],
          [55.417521, 28.119489],
          [55.417801, 28.119759],
          [55.418078, 28.122542],
          [55.418354, 28.124767],
          [55.418634, 28.126154],
          [55.407581, 28.157767],
          [55.407858, 28.163602],
          [55.408138, 28.166375],
          [55.408691, 28.169707],
          [55.408971, 28.171931],
          [55.409248, 28.174993],
          [55.409525, 28.177766],
          [55.412025, 28.189986],
          [55.418891, 28.213589],
          [55.418985, 28.213683],
          [55.420004, 28.215255],
          [55.420281, 28.216092],
          [55.405208, 28.250554],
          [55.405488, 28.251112],
          [55.405765, 28.254164],
          [55.406321, 28.258055],
          [55.407432, 28.262494],
          [55.407708, 28.264448],
          [55.404505, 28.26787],
          [55.404782, 28.269806],
          [55.405058, 28.272309],
          [55.405338, 28.274534],
          [55.405615, 28.2762],
          [55.405892, 28.279252],
          [55.405615, 28.282585],
          [55.405892, 28.283143],
          [55.405615, 28.285646],
          [55.405892, 28.286475],
          [55.406448, 28.296192],
          [55.407558, 28.301757],
          [55.407838, 28.303694],
          [55.408392, 28.306476],
          [55.408392, 28.307305],
          [55.408392, 28.307863],
          [55.408672, 28.308143],
          [55.408392, 28.308692],
          [55.408672, 28.308971],
          [55.408392, 28.309809],
          [55.408672, 28.310088],
          [55.408392, 28.312582],
          [55.408392, 28.314248],
          [55.408115, 28.315365],
          [55.408392, 28.315644],
          [55.408115, 28.31759],
          [55.407838, 28.318139],
          [55.408115, 28.318697],
          [55.404782, 28.352029],
          [55.405058, 28.354253],
          [55.406725, 28.360369],
          [55.407005, 28.362305],
          [55.407005, 28.380363],
          [55.406448, 28.386199],
          [55.406172, 28.387866],
          [55.406448, 28.388145],
          [55.406725, 28.392594],
          [55.407005, 28.39481],
          [55.407282, 28.397034],
          [55.407838, 28.418417],
          [55.408392, 28.420362],
          [55.408672, 28.423425],
          [55.408948, 28.42537],
          [55.409782, 28.433143],
          [55.410892, 28.440646],
          [55.410385, 28.462935],
          [55.410385, 28.463494],
          [55.411495, 28.467664],
          [55.411495, 28.468223],
          [55.429475, 28.489919],
          [55.437615, 28.491857],
          [55.438724, 28.493523],
          [55.447091, 28.512582],
          [55.452924, 28.518969],
          [55.454034, 28.522023],
          [55.457924, 28.537581],
          [55.457648, 28.540365],
          [55.455981, 28.548698],
          [55.452368, 28.556472],
          [55.434755, 28.586509],
          [55.434755, 28.586779],
          [55.431978, 28.589004],
          [55.400171, 28.633984],
          [55.399338, 28.634263],
          [55.397395, 28.636491],
          [55.397395, 28.636761],
          [55.397118, 28.63732],
          [55.372467, 28.664383],
          [55.377607, 28.672663],
          [55.255221, 28.764382],
          [55.254667, 28.768544],
          [55.254387, 28.769103],
          [55.254667, 28.769653],
          [55.254387, 28.774933],
          [55.254111, 28.778825],
          [55.253834, 28.780762],
          [55.253554, 28.781321],
          [55.253834, 28.78188],
          [55.253277, 28.78743],
          [55.253554, 28.787989],
          [55.253554, 28.790214],
          [55.253554, 28.792161],
          [55.254387, 28.799378],
          [55.254667, 28.800216],
          [55.254944, 28.806325],
          [55.255221, 28.8091],
          [55.255501, 28.811876],
          [55.255777, 28.815489],
          [55.256054, 28.817435],
          [55.256334, 28.819661],
          [55.256611, 28.820769],
          [55.256887, 28.824103],
          [55.257167, 28.827428],
          [55.257444, 28.834096],
          [55.257167, 28.840764],
          [55.257444, 28.841323],
          [55.257721, 28.847711],
          [55.259387, 28.857714],
          [55.259944, 28.858822],
          [55.260221, 28.86021],
          [55.260777, 28.862156],
          [55.271611, 28.904933],
          [55.272167, 28.908537],
          [55.272724, 28.911872],
          [55.273001, 28.91243],
          [55.273277, 28.913539],
          [55.273557, 28.914936],
          [55.273834, 28.917991],
          [55.274111, 28.919369],
          [55.274391, 28.921875],
          [55.275224, 28.928823],
          [55.275501, 28.929372],
          [55.275777, 28.93076],
          [55.276334, 28.932707],
          [55.276334, 28.933265],
          [55.27911, 28.944098],
          [55.279944, 28.948261],
          [55.2805, 28.953822],
          [55.280777, 28.956597],
          [55.281334, 28.96104],
          [55.28161, 28.962707],
          [55.28189, 28.964095],
          [55.282167, 28.966312],
          [55.288557, 28.989095],
          [55.288834, 28.993538],
          [55.28911, 28.995206],
          [55.28939, 28.996593],
          [55.289667, 28.99854],
          [55.289947, 28.999369],
          [55.290224, 29.000207],
          [55.291342, 29.002843],
          [55.278664, 29.009541],
          [55.278664, 29.012885],
          [55.278664, 29.015382],
          [55.277554, 29.03038],
          [55.277277, 29.033435],
          [55.275054, 29.045657],
          [55.270611, 29.062052],
          [55.266998, 29.075384],
          [55.262278, 29.094547],
          [55.261998, 29.094826],
          [55.261164, 29.096214],
          [55.260611, 29.096773],
          [55.250888, 29.115657],
          [55.249221, 29.118713],
          [55.230051, 29.150659],
          [55.228942, 29.152047],
          [55.219495, 29.170933],
          [55.199772, 29.203439],
          [55.195885, 29.208992],
          [55.187272, 29.220658],
          [55.167273, 29.24983],
          [55.165326, 29.252328],
          [55.164773, 29.252607],
          [55.164216, 29.253716],
          [55.128936, 29.288164],
          [55.125603, 29.29122],
          [55.081434, 29.327887],
          [55.047544, 29.348993],
          [55.035878, 29.355386],
          [55.027821, 29.359831],
          [55.013098, 29.367882],
          [54.983932, 29.394832],
          [54.968929, 29.419555],
          [54.963096, 29.427616],
          [54.953929, 29.442891],
          [54.950596, 29.447056],
          [54.949486, 29.448165],
          [54.948929, 29.448724],
          [54.948373, 29.448995],
          [54.948372, 29.449283],
          [54.947263, 29.449834],
          [54.937263, 29.459833],
          [54.935316, 29.462331],
          [54.913926, 29.484829],
          [54.906707, 29.493999],
          [54.882537, 29.527057],
          [54.88198, 29.527886],
          [54.879204, 29.531502],
          [54.835314, 29.579837],
          [54.816145, 29.593725],
          [54.798088, 29.602058],
          [54.791145, 29.604276],
          [54.784478, 29.605945],
          [54.774755, 29.608172],
          [54.773323, 29.608645],
          [54.773089, 29.608722],
          [54.738676, 29.612819],
          [54.738086, 29.612889],
          [54.737626, 29.613005],
          [54.736976, 29.613168],
          [54.720863, 29.613168],
          [54.719196, 29.613448],
          [54.69475, 29.613448],
          [54.686697, 29.612618],
          [54.683083, 29.612618],
          [54.680863, 29.612339],
          [54.675027, 29.612339],
          [54.673917, 29.612059],
          [54.66864, 29.612059],
          [54.66475, 29.6115],
          [54.661974, 29.6115],
          [54.655027, 29.61067],
          [54.65225, 29.61067],
          [54.650584, 29.610391],
          [54.644194, 29.610391],
          [54.643637, 29.610111],
          [54.640304, 29.610111],
          [54.638637, 29.610111],
          [54.636971, 29.610391],
          [54.636381, 29.610391],
          [54.635581, 29.610391],
          [54.635465, 29.610507],
          [54.635304, 29.61067],
          [54.634385, 29.61067],
          [54.633914, 29.61067],
          [54.631933, 29.611328],
          [54.631414, 29.6115],
          [54.618637, 29.611229],
          [54.615304, 29.6115],
          [54.592801, 29.614837],
          [54.592074, 29.614837],
          [54.591691, 29.614837],
          [54.584748, 29.616785],
          [54.571135, 29.62289],
          [54.566412, 29.624279],
          [54.563078, 29.625668],
          [54.562245, 29.625947],
          [54.561412, 29.626497],
          [54.559468, 29.627056],
          [54.558912, 29.627056],
          [54.558635, 29.627336],
          [54.556412, 29.627336],
          [54.544469, 29.628166],
          [54.543912, 29.628445],
          [54.542522, 29.628445],
          [54.496133, 29.639835],
          [54.494743, 29.639835],
          [54.493909, 29.640115],
          [54.491133, 29.640115],
          [54.486963, 29.640665],
          [54.485853, 29.640954],
          [54.481963, 29.640954],
          [54.481686, 29.640967],
          [54.47641, 29.641224],
          [54.472243, 29.640954],
          [54.47141, 29.640665],
          [54.47002, 29.640665],
          [54.463073, 29.639006],
          [54.44835, 29.633721],
          [54.44613, 29.632612],
          [54.405294, 29.619003],
          [54.399461, 29.617335],
          [54.367515, 29.610391],
          [54.366681, 29.610391],
          [54.357515, 29.609002],
          [54.347235, 29.607613],
          [54.345848, 29.607613],
          [54.345292, 29.607334],
          [54.343348, 29.607334],
          [54.341242, 29.60691],
          [54.340568, 29.606774],
          [54.339182, 29.606775],
          [54.338068, 29.606504],
          [54.336682, 29.606504],
          [54.335568, 29.606224],
          [54.333902, 29.606224],
          [54.331402, 29.605665],
          [54.329735, 29.605665],
          [54.328345, 29.605395],
          [54.326125, 29.605395],
          [54.319735, 29.604556],
          [54.316955, 29.604556],
          [54.300289, 29.602058],
          [54.295289, 29.601508],
          [54.291956, 29.601508],
          [54.290012, 29.601219],
          [54.285013, 29.601219],
          [54.280846, 29.600669],
          [54.278623, 29.600669],
          [54.276956, 29.60039],
          [54.273343, 29.60039],
          [54.272789, 29.60011],
          [54.267509, 29.60011],
          [54.266123, 29.59984],
          [54.264176, 29.59984],
          [54.24862, 29.596223],
          [54.242786, 29.596223],
          [54.220287, 29.626227],
          [54.219453, 29.626786],
          [54.217507, 29.629834],
          [54.215007, 29.633171],
          [54.19584, 29.660668],
          [54.195007, 29.661498],
          [54.195007, 29.662057],
          [54.194174, 29.662896],
          [54.170284, 29.709279],
          [54.167228, 29.715394],
          [54.120558, 29.772062],
          [54.107502, 29.786511],
          [54.106668, 29.78762],
          [54.106314, 29.788268],
          [54.105558, 29.789009],
          [54.105282, 29.789559],
          [54.103059, 29.792897],
          [54.092225, 29.822066],
          [54.091669, 29.825674],
          [54.089169, 29.853735],
          [54.089445, 29.854565],
          [54.089169, 29.855954],
          [54.089445, 29.856784],
          [54.089725, 29.859841],
          [54.090279, 29.864009],
          [54.092225, 29.874283],
          [54.099725, 29.892341],
          [54.099921, 29.892927],
          [54.100002, 29.893171],
          [54.100002, 29.89401],
          [54.103615, 29.909011],
          [54.103335, 29.912339],
          [54.100835, 29.922902],
          [54.100282, 29.924012],
          [54.098615, 29.933176],
          [54.098615, 29.934565],
          [54.098615, 29.936234],
          [54.098059, 29.938453],
          [54.047223, 29.990954],
          [54.038613, 29.996791],
          [54.03528, 29.99818],
          [54.03139, 29.999849],
          [54.003053, 30.015681],
          [53.99991, 30.017316],
          [53.995553, 30.020119],
          [53.995165, 30.020466],
          [53.991387, 30.023728],
          [53.991103, 30.023873],
          [53.99076, 30.024055],
          [53.988887, 30.025126],
          [53.98722, 30.027065],
          [53.972497, 30.03762],
          [53.97083, 30.038739],
          [53.958884, 30.046515],
          [53.955274, 30.050124],
          [53.939998, 30.061518],
          [53.930274, 30.074292],
          [53.914995, 30.094013],
          [53.911105, 30.09985],
          [53.910828, 30.100401],
          [53.908328, 30.102629],
          [53.908051, 30.103179],
          [53.907495, 30.103739],
          [53.905551, 30.106788],
          [53.904718, 30.108737],
          [53.902771, 30.112905],
          [53.902215, 30.114014],
          [53.900271, 30.119572],
          [53.895272, 30.129848],
          [53.894715, 30.132067],
          [53.890828, 30.140403],
          [53.890548, 30.141793],
          [53.879715, 30.164853],
          [53.878882, 30.165963],
          [53.876939, 30.171241],
          [53.874158, 30.176239],
          [53.873605, 30.176798],
          [53.870825, 30.182067],
          [53.868049, 30.186795],
          [53.867215, 30.187625],
          [53.864439, 30.193183],
          [53.864159, 30.193742],
          [53.864439, 30.202629],
          [53.876939, 30.221513],
          [53.881105, 30.2304],
          [53.886105, 30.238466],
          [53.887495, 30.242075],
          [53.889995, 30.251792],
          [53.891105, 30.255961],
          [53.891381, 30.25874],
          [53.891938, 30.260409],
          [53.892215, 30.264298],
          [53.892215, 30.264857],
          [53.893328, 30.265958],
          [53.895828, 30.273735],
          [53.896938, 30.275964],
          [53.897772, 30.278743],
          [53.907495, 30.292349],
          [53.908605, 30.294019],
          [53.908605, 30.294578],
          [53.910551, 30.298187],
          [53.914718, 30.307634],
          [53.917218, 30.311794],
          [53.925551, 30.334018],
          [53.926108, 30.349014],
          [53.925828, 30.350404],
          [53.921686, 30.366516],
          [53.915828, 30.389294],
          [53.914161, 30.393743],
          [53.905551, 30.413739],
          [53.898605, 30.445683],
          [53.898881, 30.449301],
          [53.901105, 30.464019],
          [53.904438, 30.470967],
          [53.904995, 30.471518],
          [53.905325, 30.472553],
          [53.909161, 30.484575],
          [53.909995, 30.486804],
          [53.928051, 30.525409],
          [53.929161, 30.533468],
          [53.930274, 30.536798],
          [53.930828, 30.540137],
          [53.930551, 30.541247],
          [53.930274, 30.543747],
          [53.929994, 30.546246],
          [53.929718, 30.548746],
          [53.929161, 30.555965],
          [53.929161, 30.557075],
          [53.925551, 30.569854],
          [53.910828, 30.591801],
          [53.879438, 30.630138],
          [53.87713, 30.63178],
          [53.866939, 30.639027],
          [53.866382, 30.639858],
          [53.865549, 30.640137],
          [53.858602, 30.644578],
          [53.854992, 30.647917],
          [53.840826, 30.655416],
          [53.839436, 30.656247],
          [53.838879, 30.656806],
          [53.836102, 30.657637],
          [53.835546, 30.657637],
          [53.834159, 30.657916],
          [53.785543, 30.674306],
          [53.783876, 30.675136],
          [53.73971, 30.687635],
          [53.734987, 30.689025],
          [53.714431, 30.693466],
          [53.663871, 30.704864],
          [53.661371, 30.705135],
          [53.659152, 30.705695],
          [53.657762, 30.705695],
          [53.653315, 30.706805],
          [53.649982, 30.707364],
          [53.633039, 30.709305],
          [53.629705, 30.709305],
          [53.611649, 30.711525],
          [53.608316, 30.711525],
          [53.606092, 30.711805],
          [53.599426, 30.711805],
          [53.597479, 30.712084],
          [53.592203, 30.712084],
          [53.56498, 30.714584],
          [53.563036, 30.714584],
          [53.561923, 30.714864],
          [53.553313, 30.715415],
          [53.529143, 30.717915],
          [53.506367, 30.722635],
          [53.504977, 30.722635],
          [53.496921, 30.725135],
          [53.492477, 30.725974],
          [53.492197, 30.726254],
          [53.490531, 30.726534],
          [53.471088, 30.731525],
          [53.460531, 30.736804],
          [53.458584, 30.737364],
          [53.449418, 30.746245],
          [53.449418, 30.746524],
          [53.448755, 30.747187],
          [53.444418, 30.751525],
          [53.443308, 30.754025],
          [53.432751, 30.766245],
          [53.424695, 30.777364],
          [53.395529, 30.812085],
          [53.388025, 30.817365],
          [53.383305, 30.820696],
          [53.377472, 30.825416],
          [53.363582, 30.833196],
          [53.299413, 30.863748],
          [53.2858, 30.870139],
          [53.279967, 30.872359],
          [53.277467, 30.873199],
          [53.274687, 30.87458],
          [53.273577, 30.875139],
          [53.27302, 30.875419],
          [53.272467, 30.875699],
          [53.263854, 30.880699],
          [53.262467, 30.882089],
          [53.253021, 30.8882],
          [53.247744, 30.89098],
          [53.246077, 30.89153],
          [53.244131, 30.89264],
          [53.24368, 30.892981],
          [53.243021, 30.89348],
          [53.241911, 30.893751],
          [53.241631, 30.89403],
          [53.240797, 30.89403],
          [53.238854, 30.894861],
          [53.237464, 30.894861],
          [53.226908, 30.89848],
          [53.225521, 30.898751],
          [53.221351, 30.900701],
          [53.218018, 30.901811],
          [53.147462, 30.934584],
          [53.145239, 30.935424],
          [53.143292, 30.936534],
          [53.142739, 30.937085],
          [53.142182, 30.937364],
          [53.138015, 30.938754],
          [53.137182, 30.938754],
          [53.123016, 30.946255],
          [53.118849, 30.948755],
          [53.096626, 30.961537],
          [53.095793, 30.962367],
          [53.081623, 30.969868],
          [53.052457, 30.987361],
          [53.052177, 30.98792],
          [53.034957, 30.996532],
          [53.025234, 31.000016],
          [53.019401, 31.001532],
          [52.993564, 31.005973],
          [52.985508, 31.008203],
          [52.981064, 31.009593],
          [52.977174, 31.010974],
          [52.969398, 31.014593],
          [52.948842, 31.035977],
          [52.941895, 31.059311],
          [52.935228, 31.074034],
          [52.933005, 31.076534],
          [52.929118, 31.081815],
          [52.928838, 31.082925],
          [52.927452, 31.084866],
          [52.916062, 31.111811],
          [52.911895, 31.121262],
          [52.899392, 31.136264],
          [52.895506, 31.140146],
          [52.895225, 31.140426],
          [52.894949, 31.140566],
          [52.894116, 31.141536],
          [52.892725, 31.142926],
          [52.889116, 31.145977],
          [52.888282, 31.146257],
          [52.887726, 31.147097],
          [52.876616, 31.154038],
          [52.861336, 31.15848],
          [52.856893, 31.16043],
          [52.855226, 31.16098],
          [52.83939, 31.171813],
          [52.833556, 31.179043],
          [52.831613, 31.186536],
          [52.831613, 31.191257],
          [52.833556, 31.195698],
          [52.850226, 31.213762],
          [52.850503, 31.214312],
          [52.868836, 31.223764],
          [52.875502, 31.226265],
          [52.878002, 31.226815],
          [52.906339, 31.230977],
          [52.907172, 31.231266],
          [52.908562, 31.231266],
          [52.912449, 31.232096],
          [52.918005, 31.232647],
          [52.924395, 31.233757],
          [52.928838, 31.234597],
          [52.945785, 31.240988],
          [52.955508, 31.25071],
          [52.971618, 31.277089],
          [52.974674, 31.295432],
          [52.976618, 31.303205],
          [52.977451, 31.307656],
          [52.977731, 31.308206],
          [52.977731, 31.308757],
          [52.977731, 31.310707],
          [52.978008, 31.311817],
          [52.978288, 31.32265],
          [52.977731, 31.325431],
          [52.968841, 31.361821],
          [52.968284, 31.362652],
          [52.968008, 31.364322],
          [52.965508, 31.369594],
          [52.962731, 31.374596],
          [52.951618, 31.388489],
          [52.950785, 31.38988],
          [52.934672, 31.404875],
          [52.906895, 31.422651],
          [52.904115, 31.423761],
          [52.889672, 31.428212],
          [52.888559, 31.428212],
          [52.888006, 31.428483],
          [52.887406, 31.428634],
          [52.865782, 31.436824],
          [52.864669, 31.437095],
          [52.848559, 31.447649],
          [52.845779, 31.450429],
          [52.81828, 31.479599],
          [52.805777, 31.487101],
          [52.80161, 31.491543],
          [52.8005, 31.493213],
          [52.800777, 31.494324],
          [52.801057, 31.494874],
          [52.8005, 31.494874],
          [52.79939, 31.495154],
          [52.79911, 31.494874],
          [52.797723, 31.494874],
          [52.79661, 31.494604],
          [52.794667, 31.494604],
          [52.793, 31.494874],
          [52.787167, 31.494874],
          [52.786334, 31.495154],
          [52.784387, 31.495154],
          [52.782444, 31.495434],
          [52.779387, 31.495434],
          [52.777444, 31.495705],
          [52.762164, 31.495705],
          [52.761054, 31.495994],
          [52.754944, 31.496265],
          [52.732998, 31.498486],
          [52.730498, 31.499045],
          [52.729108, 31.499045],
          [52.690772, 31.502936],
          [52.686605, 31.502936],
          [52.660215, 31.504877],
          [52.656325, 31.504877],
          [52.626049, 31.507938],
          [52.623269, 31.507938],
          [52.618549, 31.508489],
          [52.615493, 31.508489],
          [52.613826, 31.508769],
          [52.610769, 31.508769],
          [52.596323, 31.509879],
          [52.586603, 31.51043],
          [52.584656, 31.51043],
          [52.582436, 31.51071],
          [52.579656, 31.51071],
          [52.578546, 31.510989],
          [52.574933, 31.510989],
          [52.573823, 31.511269],
          [52.559657, 31.51238],
          [52.499654, 31.522934],
          [52.497154, 31.523493],
          [52.496598, 31.523493],
          [52.432985, 31.54266],
          [52.430205, 31.542931],
          [52.412982, 31.545432],
          [52.410759, 31.545432],
          [52.407705, 31.545992],
          [52.399926, 31.546822],
          [52.397706, 31.547373],
          [52.393539, 31.548213],
          [52.381036, 31.551544],
          [52.372979, 31.554605],
          [52.345203, 31.571821],
          [52.344923, 31.572101],
          [52.344646, 31.572381],
          [52.247421, 31.661546],
          [52.244641, 31.662656],
          [52.240751, 31.663767],
          [52.239364, 31.663767],
          [52.237418, 31.664326],
          [52.222698, 31.666547],
          [52.220751, 31.666547],
          [52.219918, 31.666827],
          [52.218808, 31.666827],
          [52.217695, 31.667107],
          [52.215475, 31.667107],
          [52.214918, 31.667378],
          [52.214085, 31.667378],
          [52.213808, 31.667658],
          [52.212695, 31.667658],
          [52.212141, 31.667938],
          [52.211028, 31.667938],
          [52.208808, 31.668488],
          [52.207141, 31.668488],
          [52.206585, 31.668768],
          [52.205195, 31.668768],
          [52.204641, 31.669048],
          [52.203223, 31.669201],
          [52.202099, 31.66964],
          [52.201305, 31.669328],
          [52.200751, 31.669328],
          [52.194362, 31.668218],
          [52.192695, 31.668218],
          [52.191862, 31.667938],
          [52.190472, 31.667938],
          [52.186305, 31.667378],
          [52.146302, 31.661545],
          [52.144916, 31.660995],
          [52.144082, 31.660995],
          [52.142692, 31.660435],
          [52.134082, 31.657934],
          [52.130469, 31.656553],
          [52.078247, 31.62071],
          [52.07269, 31.617107],
          [52.0463, 31.59571],
          [52.010464, 31.550433],
          [52.009631, 31.548492],
          [52.007964, 31.545991],
          [52.002964, 31.536268],
          [51.998798, 31.525434],
          [51.988241, 31.502376],
          [51.983798, 31.494323],
          [51.980465, 31.489042],
          [51.938795, 31.415987],
          [51.937962, 31.415428],
          [51.937682, 31.414597],
          [51.936572, 31.413487],
          [51.936572, 31.412656],
          [51.936572, 31.412376],
          [51.936572, 31.412096],
          [51.936295, 31.404043],
          [51.936572, 31.402933],
          [51.937405, 31.397092],
          [51.938239, 31.394871],
          [51.941852, 31.376536],
          [51.944352, 31.354878],
          [51.945185, 31.352378],
          [51.945462, 31.350148],
          [51.947962, 31.340985],
          [51.948795, 31.335984],
          [51.953519, 31.316538],
          [51.953519, 31.314868],
          [51.955185, 31.306256],
          [51.955462, 31.304044],
          [51.955739, 31.302374],
          [51.956295, 31.300984],
          [51.954629, 31.294872],
          [51.954629, 31.292931],
          [51.954352, 31.290981],
          [51.954629, 31.290422],
          [51.954629, 31.28821],
          [51.963518, 31.243759],
          [51.963519, 31.241818],
          [51.964075, 31.237927],
          [51.964629, 31.234316],
          [51.965185, 31.230976],
          [51.965462, 31.228485],
          [51.965742, 31.225984],
          [51.966019, 31.224594],
          [51.966295, 31.222093],
          [51.966575, 31.220423],
          [51.968242, 31.214032],
          [51.968518, 31.213481],
          [51.968518, 31.213202],
          [51.968518, 31.212922],
          [51.968242, 31.211261],
          [51.968518, 31.210981],
          [51.967962, 31.194317],
          [51.956852, 31.150978],
          [51.941019, 31.11292],
          [51.940186, 31.11181],
          [51.924906, 31.082924],
          [51.921849, 31.077093],
          [51.902406, 31.034587],
          [51.902126, 31.034307],
          [51.901293, 31.033206],
          [51.901016, 31.032926],
          [51.898516, 31.027365],
          [51.897683, 31.021254],
          [51.897403, 31.012363],
          [51.89657, 31.000123],
          [51.89796, 30.99181],
          [51.89796, 30.990971],
          [51.897683, 30.9907],
          [51.897683, 30.990149],
          [51.897683, 30.98959],
          [51.897683, 30.98931],
          [51.89546, 30.985419],
          [51.894903, 30.984029],
          [51.87296, 30.944585],
          [51.872403, 30.944314],
          [51.87157, 30.942644],
          [51.856014, 30.926532],
          [51.849904, 30.919582],
          [51.841567, 30.906531],
          [51.841291, 30.905971],
          [51.837124, 30.898479],
          [51.835458, 30.893479],
          [51.832681, 30.882639],
          [51.832401, 30.875978],
          [51.841567, 30.845967],
          [51.842401, 30.844035],
          [51.842681, 30.842357],
          [51.856291, 30.813195],
          [51.858514, 30.802635],
          [51.858514, 30.800415],
          [51.857957, 30.800414],
          [51.85768, 30.799855],
          [51.857404, 30.799584],
          [51.854904, 30.795135],
          [51.846571, 30.769033],
          [51.833514, 30.742083],
          [51.832681, 30.740414],
          [51.827401, 30.733473],
          [51.812958, 30.715143],
          [51.797678, 30.704304],
          [51.789345, 30.701253],
          [51.789345, 30.700974],
          [51.787398, 30.700974],
          [51.787398, 30.701253],
          [51.786565, 30.701253],
          [51.786288, 30.701524],
          [51.785455, 30.701524],
          [51.785178, 30.700694],
          [51.805455, 30.684304],
          [51.834068, 30.665975],
          [51.852124, 30.645967],
          [51.853514, 30.644297],
          [51.854904, 30.643187],
          [51.862957, 30.631527],
          [51.864347, 30.626518],
          [51.869347, 30.615968],
          [51.869904, 30.615689],
          [51.870461, 30.614858],
          [51.88046, 30.608749],
          [51.887127, 30.60097],
          [51.887404, 30.59986],
          [51.888237, 30.59819],
          [51.888237, 30.59347],
          [51.88796, 30.59319],
          [51.88796, 30.59236],
          [51.88796, 30.59208],
          [51.88796, 30.590691],
          [51.887684, 30.590131],
          [51.884627, 30.584301],
          [51.877961, 30.575412],
          [51.855738, 30.554024],
          [51.848238, 30.541246],
          [51.845458, 30.537907],
          [51.844068, 30.535416],
          [51.834348, 30.521248],
          [51.834348, 30.520969],
          [51.834348, 30.520689],
          [51.834068, 30.520138],
          [51.833514, 30.519299],
          [51.833514, 30.51846],
          [51.832958, 30.517359],
          [51.831848, 30.51208],
          [51.831291, 30.505411],
          [51.831568, 30.501242],
          [51.831848, 30.498462],
          [51.832124, 30.496802],
          [51.832401, 30.494853],
          [51.832681, 30.493743],
          [51.833515, 30.491243],
          [51.833515, 30.490133],
          [51.839068, 30.477355],
          [51.839624, 30.477076],
          [51.839901, 30.476245],
          [51.841014, 30.475135],
          [51.841015, 30.474576],
          [51.841568, 30.474016],
          [51.843234, 30.469577],
          [51.842958, 30.465687],
          [51.841848, 30.462628],
          [51.837401, 30.45374],
          [51.831568, 30.445682],
          [51.831291, 30.444572],
          [51.826291, 30.436802],
          [51.826291, 30.436523],
          [51.825735, 30.435963],
          [51.824901, 30.435404],
          [51.796845, 30.410959],
          [51.781565, 30.399021],
          [51.775792, 30.394911],
          [51.773758, 30.390498],
          [51.772642, 30.387072],
          [51.772428, 30.382872],
          [51.775281, 30.379372],
          [51.784315, 30.376911],
          [51.785431, 30.374653],
          [51.782942, 30.371579],
          [51.772104, 30.366488],
          [51.766977, 30.364952],
          [51.761827, 30.362878],
          [51.750846, 30.360815],
          [51.65906, 30.415967],
          [51.65795, 30.416246],
          [51.657674, 30.416526],
          [51.652117, 30.418466],
          [51.648784, 30.418466],
          [51.646561, 30.418746],
          [51.645451, 30.419296],
          [51.639617, 30.421796],
          [51.633781, 30.426795],
          [51.633227, 30.427914],
          [51.632114, 30.429015],
          [51.628228, 30.437632],
          [51.627394, 30.440132],
          [51.627394, 30.440403],
          [51.626004, 30.440962],
          [51.625171, 30.441242],
          [51.619338, 30.443741],
          [51.611281, 30.443741],
          [51.604058, 30.441242],
          [51.583781, 30.423465],
          [51.579058, 30.422355],
          [51.576278, 30.422355],
          [51.574612, 30.422355],
          [51.560722, 30.426515],
          [51.548779, 30.431794],
          [51.534889, 30.434853],
          [51.532946, 30.434853],
          [51.532665, 30.435133],
          [51.529056, 30.435683],
          [51.528499, 30.435403],
          [51.527946, 30.435683],
          [51.527112, 30.435683],
          [51.526279, 30.435403],
          [51.523499, 30.435403],
          [51.522942, 30.435683],
          [51.522109, 30.435683],
          [51.521276, 30.435683],
          [51.502666, 30.442072],
          [51.500719, 30.443182],
          [51.484053, 30.45124],
          [51.48322, 30.45124],
          [51.46822, 30.455968],
          [51.467663, 30.456239],
          [51.46711, 30.456519],
          [51.466553, 30.456519],
          [51.466273, 30.456799],
          [51.46544, 30.456799],
          [51.463773, 30.457909],
          [51.46072, 30.460968],
          [51.45933, 30.464018],
          [51.45933, 30.464577],
          [51.45933, 30.464857],
          [51.458497, 30.465128],
          [51.457387, 30.465128],
          [51.447107, 30.467907],
          [51.443497, 30.467907],
          [51.442384, 30.468187],
          [51.440717, 30.468187],
          [51.43933, 30.468466],
          [51.438497, 30.468187],
          [51.43183, 30.468187],
          [51.407104, 30.463467],
          [51.404884, 30.463467],
          [51.403494, 30.463187],
          [51.402661, 30.463467],
          [51.399327, 30.463738],
          [51.374328, 30.475965],
          [51.372381, 30.477914],
          [51.370158, 30.480964],
          [51.370158, 30.481524],
          [51.369881, 30.482354],
          [51.366548, 30.486244],
          [51.362381, 30.494573],
          [51.362105, 30.495962],
          [51.360158, 30.500411],
          [51.358771, 30.50541],
          [51.357105, 30.514579],
          [51.358771, 30.525688],
          [51.363771, 30.541245],
          [51.363491, 30.543745],
          [51.363215, 30.544575],
          [51.361548, 30.551795],
          [51.349601, 30.562633],
          [51.339048, 30.568472],
          [51.330712, 30.573192],
          [51.329878, 30.573471],
          [51.301545, 30.59069],
          [51.296822, 30.594029],
          [51.251819, 30.616518],
          [51.237653, 30.630687],
          [51.235986, 30.632916],
          [51.22793, 30.642356],
          [51.225153, 30.644856],
          [51.21932, 30.649025],
          [51.217653, 30.649576],
          [51.217097, 30.650136],
          [51.21543, 30.650695],
          [51.211263, 30.653195],
          [51.20904, 30.653466],
          [51.20904, 30.653745],
          [51.20793, 30.653745],
          [51.20654, 30.653466],
          [51.204597, 30.652915],
          [51.20293, 30.652915],
          [51.197097, 30.651525],
          [51.195707, 30.651525],
          [51.193764, 30.650966],
          [51.190983, 30.650695],
          [51.186817, 30.650695],
          [51.185984, 30.650415],
          [51.18404, 30.650415],
          [51.182374, 30.649856],
          [51.177374, 30.649856],
          [51.177094, 30.649305],
          [51.161817, 30.628747],
          [51.159037, 30.620688],
          [51.158761, 30.617908],
          [51.158484, 30.617358],
          [51.157094, 30.613468],
          [51.151538, 30.609299],
          [51.142647, 30.606799],
          [51.141538, 30.606799],
          [51.136538, 30.598469],
          [51.137094, 30.596249],
          [51.137648, 30.595689],
          [51.137928, 30.59541],
          [51.138205, 30.594859],
          [51.138761, 30.594029],
          [51.143761, 30.58764],
          [51.143204, 30.580691],
          [51.142928, 30.564023],
          [51.162371, 30.547634],
          [51.164037, 30.546245],
          [51.168484, 30.540135],
          [51.168761, 30.539855],
          [51.179041, 30.530687],
          [51.184874, 30.512909],
          [51.187097, 30.504851],
          [51.201264, 30.477634],
          [51.20265, 30.476245],
          [51.203207, 30.474855],
          [51.204597, 30.473465],
          [51.21432, 30.461518],
          [51.218487, 30.455968],
          [51.219597, 30.454299],
          [51.22043, 30.453739],
          [51.255433, 30.415966],
          [51.255433, 30.415686],
          [51.294879, 30.384574],
          [51.294879, 30.384023],
          [51.296546, 30.380405],
          [51.296822, 30.379015],
          [51.296822, 30.375965],
          [51.296546, 30.374576],
          [51.296266, 30.374296],
          [51.295156, 30.371796],
          [51.292656, 30.370687],
          [51.291266, 30.370687],
          [51.288766, 30.370127],
          [51.285989, 30.370127],
          [51.284323, 30.369577],
          [51.282656, 30.369577],
          [51.282379, 30.369297],
          [51.280989, 30.369297],
          [51.280713, 30.369017],
          [51.280156, 30.369017],
          [51.26821, 30.363739],
          [51.265156, 30.360129],
          [51.264323, 30.35846],
          [51.264323, 30.358189],
          [51.264043, 30.35791],
          [51.264043, 30.35763],
          [51.26099, 30.354021],
          [51.2596, 30.353181],
          [51.257376, 30.352351],
          [51.256266, 30.352351],
          [51.243486, 30.347073],
          [51.240919, 30.341513],
          [51.238487, 30.336245],
          [51.237653, 30.325128],
          [51.238763, 30.32124],
          [51.24043, 30.318181],
          [51.24071, 30.317621],
          [51.25182, 30.308463],
          [51.265433, 30.301795],
          [51.269043, 30.297356],
          [51.2721, 30.289019],
          [51.270709, 30.282351],
          [51.269043, 30.279572],
          [51.267933, 30.279012],
          [51.267656, 30.278742],
          [51.265433, 30.277352],
          [51.265433, 30.277072],
          [51.264876, 30.277073],
          [51.2646, 30.276793],
          [51.263766, 30.276793],
          [51.263766, 30.276513],
          [51.26349, 30.276233],
          [51.26321, 30.275963],
          [51.262933, 30.275683],
          [51.249043, 30.264017],
          [51.238763, 30.256798],
          [51.23793, 30.252071],
          [51.23543, 30.250681],
          [51.234597, 30.250681],
          [51.232654, 30.249851],
          [51.231544, 30.249851],
          [51.231263, 30.249571],
          [51.23043, 30.249571],
          [51.230154, 30.249292],
          [51.227654, 30.249021],
          [51.225154, 30.248182],
          [51.218487, 30.241794],
          [51.214874, 30.240684],
          [51.21293, 30.240684],
          [51.212374, 30.240404],
          [51.211541, 30.240404],
          [51.21154, 30.240125],
          [51.210987, 30.239854],
          [51.210707, 30.239295],
          [51.210154, 30.239015],
          [51.208207, 30.236795],
          [51.207654, 30.236795],
          [51.207374, 30.236516],
          [51.206264, 30.236516],
          [51.205987, 30.236236],
          [51.196817, 30.235406],
          [51.194874, 30.232347],
          [51.194597, 30.230958],
          [51.194597, 30.230678],
          [51.192931, 30.227349],
          [51.192374, 30.227069],
          [51.191264, 30.225959],
          [51.189874, 30.219012],
          [51.186264, 30.215683],
          [51.184041, 30.210955],
          [51.18365, 30.210516],
          [51.183208, 30.209845],
          [51.183208, 30.209295],
          [51.182094, 30.207905],
          [51.177928, 30.206236],
          [51.169318, 30.212065],
          [51.162651, 30.209015],
          [51.159318, 30.209015],
          [51.158485, 30.209295],
          [51.155151, 30.209566],
          [51.154595, 30.208736],
          [51.153761, 30.208456],
          [51.153205, 30.208185],
          [51.152371, 30.207905],
          [51.151818, 30.207905],
          [51.150985, 30.207626],
          [51.148761, 30.207626],
          [51.147095, 30.210684],
          [51.147371, 30.212624],
          [51.148262, 30.214089],
          [51.142371, 30.215683],
          [51.132095, 30.209295],
          [51.130428, 30.207346],
          [51.131815, 30.206796],
          [51.133482, 30.206796],
          [51.134038, 30.206516],
          [51.135148, 30.206516],
          [51.135428, 30.206796],
          [51.137095, 30.206796],
          [51.138205, 30.206236],
          [51.138205, 30.205686],
          [51.137372, 30.204017],
          [51.136815, 30.204017],
          [51.136538, 30.203737],
          [51.134872, 30.203187],
          [51.134315, 30.202627],
          [51.134595, 30.202068],
          [51.134595, 30.201238],
          [51.133762, 30.200679],
          [51.130982, 30.199289],
          [51.129315, 30.199289],
          [51.127092, 30.200958],
          [51.121539, 30.200958],
          [51.120982, 30.200679],
          [51.120148, 30.200678],
          [51.118205, 30.199009],
          [51.109039, 30.188183],
          [51.107925, 30.188183],
          [51.107649, 30.187903],
          [51.106535, 30.187623],
          [51.104592, 30.187623],
          [51.104036, 30.189013],
          [51.103482, 30.190122],
          [51.104035, 30.19707],
          [51.100425, 30.199569],
          [51.095702, 30.197349],
          [51.096259, 30.196239],
          [51.096536, 30.19513],
          [51.094592, 30.190953],
          [51.092092, 30.190952],
          [51.090149, 30.191512],
          [51.088479, 30.193461],
          [51.087369, 30.19402],
          [51.086812, 30.1979],
          [51.085979, 30.199848],
          [51.085146, 30.199569],
          [51.082092, 30.199009],
          [51.080146, 30.199009],
          [51.076536, 30.200128],
          [51.075426, 30.200399],
          [51.066256, 30.200399],
          [51.063479, 30.198459],
          [51.062923, 30.197349],
          [51.062369, 30.196239],
          [51.062646, 30.19596],
          [51.062369, 30.1954],
          [51.061256, 30.191512],
          [51.057646, 30.189843],
          [51.057089, 30.190122],
          [51.05598, 30.190122],
          [51.055703, 30.190402],
          [51.053203, 30.189572],
          [51.052923, 30.187073],
          [51.052646, 30.186513],
          [51.052923, 30.184853],
          [51.053203, 30.184574],
          [51.052923, 30.184014],
          [51.05237, 30.181795],
          [51.050423, 30.180676],
          [51.050146, 30.180956],
          [51.046256, 30.180956],
          [51.044866, 30.182345],
          [51.044313, 30.182345],
          [51.044313, 30.182625],
          [51.042923, 30.182625],
          [51.042646, 30.182345],
          [51.041813, 30.182345],
          [51.041813, 30.182066],
          [51.0382, 30.179566],
          [51.034313, 30.179296],
          [51.032643, 30.180956],
          [51.03209, 30.180956],
          [51.030977, 30.177347],
          [51.030423, 30.177067],
          [51.0282, 30.177067],
          [51.027923, 30.177347],
          [51.02709, 30.177347],
          [51.025977, 30.178186],
          [51.024867, 30.181795],
          [51.02459, 30.182625],
          [51.023477, 30.184014],
          [51.020143, 30.182345],
          [51.016534, 30.179566],
          [51.015423, 30.179296],
          [51.01431, 30.179296],
          [51.014033, 30.179016],
          [51.013477, 30.179016],
          [51.0132, 30.178736],
          [50.999853, 30.173458],
          [50.998754, 30.173458],
          [50.998754, 30.173738],
          [50.996254, 30.176237],
          [50.994867, 30.176797],
          [50.99153, 30.176797],
          [50.990362, 30.176344],
          [50.989587, 30.176237],
          [50.980697, 30.167071],
          [50.960698, 30.157904],
          [50.957921, 30.156786],
          [50.955974, 30.155955],
          [50.939865, 30.142062],
          [50.939308, 30.141791],
          [50.939031, 30.141232],
          [50.937361, 30.140122],
          [50.937361, 30.139842],
          [50.937085, 30.139572],
          [50.935418, 30.135954],
          [50.924861, 30.115122],
          [50.924585, 30.112623],
          [50.921808, 30.101229],
          [50.921528, 30.093732],
          [50.921808, 30.092343],
          [50.922085, 30.090403],
          [50.922362, 30.089844],
          [50.922362, 30.089014],
          [50.922642, 30.084846],
          [50.922918, 30.084007],
          [50.919585, 30.066234],
          [50.915972, 30.058449],
          [50.904585, 30.037339],
          [50.903195, 30.03484],
          [50.897362, 30.024844],
          [50.888195, 30.014849],
          [50.887082, 30.01456],
          [50.874582, 30.009563],
          [50.866806, 30.005675],
          [50.866526, 30.005124],
          [50.862639, 29.998728],
          [50.864855, 29.981822],
          [50.864859, 29.981787],
          [50.864859, 29.981228],
          [50.860416, 29.973732],
          [50.846526, 29.965676],
          [50.836526, 29.963177],
          [50.835413, 29.963457],
          [50.833746, 29.963457],
          [50.82347, 29.964846],
          [50.816247, 29.967065],
          [50.807357, 29.971512],
          [50.795134, 29.977619],
          [50.783744, 29.984015],
          [50.78291, 29.984286],
          [50.761244, 29.992062],
          [50.759854, 29.992062],
          [50.759301, 29.992341],
          [50.758187, 29.992341],
          [50.756801, 29.992621],
          [50.754301, 29.992621],
          [50.734298, 29.990952],
          [50.732911, 29.991232],
          [50.722075, 29.993451],
          [50.717075, 29.99484],
          [50.713741, 29.99484],
          [50.712632, 29.99512],
          [50.709851, 29.99512],
          [50.709018, 29.9954],
          [50.703742, 29.99567],
          [50.697075, 29.99567],
          [50.695409, 29.995959],
          [50.686795, 29.995959],
          [50.686519, 29.99567],
          [50.683742, 29.994561],
          [50.683462, 29.99401],
          [50.678742, 29.989843],
          [50.678185, 29.989283],
          [50.667072, 29.976789],
          [50.660962, 29.979288],
          [50.660129, 29.980118],
          [50.655962, 29.977899],
          [50.654572, 29.976789],
          [50.651516, 29.97734],
          [50.649849, 29.97734],
          [50.647906, 29.977899],
          [50.645962, 29.978729],
          [50.642906, 29.97706],
          [50.637906, 29.971512],
          [50.636516, 29.970953],
          [50.635959, 29.971233],
          [50.63374, 29.971232],
          [50.630406, 29.970403],
          [50.62735, 29.965955],
          [50.625959, 29.964566],
          [50.625406, 29.964846],
          [50.624573, 29.964846],
          [50.624293, 29.965125],
          [50.623459, 29.964846],
          [50.622906, 29.964286],
          [50.622626, 29.964007],
          [50.622073, 29.963177],
          [50.62235, 29.962338],
          [50.622626, 29.962067],
          [50.62235, 29.960678],
          [50.621793, 29.960398],
          [50.621516, 29.959839],
          [50.620126, 29.959839],
          [50.61874, 29.960398],
          [50.617349, 29.960399],
          [50.616793, 29.960119],
          [50.615959, 29.960119],
          [50.615126, 29.95734],
          [50.614293, 29.95762],
          [50.612626, 29.95734],
          [50.611236, 29.954562],
          [50.609293, 29.952343],
          [50.603183, 29.943728],
        ],
      ],
    },
  },
  Gilan: {
    properties: { value: "Gilan", name: "استان گیلان, ایران", id: 28 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [48.569178, 38.414208],
          [48.569455, 38.412547],
          [48.570288, 38.407266],
          [48.570568, 38.405874],
          [48.573345, 38.393652],
          [48.573902, 38.391712],
          [48.570845, 38.382264],
          [48.570845, 38.374765],
          [48.573902, 38.361707],
          [48.574455, 38.36088],
          [48.574455, 38.360593],
          [48.576678, 38.356426],
          [48.583068, 38.348926],
          [48.612235, 38.321434],
          [48.619181, 38.315317],
          [48.626405, 38.300874],
          [48.639181, 38.290877],
          [48.643071, 38.289484],
          [48.644181, 38.289484],
          [48.644738, 38.289206],
          [48.646681, 38.289206],
          [48.647238, 38.288927],
          [48.651405, 38.288927],
          [48.651961, 38.289206],
          [48.652794, 38.289206],
          [48.655018, 38.289763],
          [48.656684, 38.29032],
          [48.661128, 38.29226],
          [48.668071, 38.29226],
          [48.668627, 38.291981],
          [48.669461, 38.291981],
          [48.671684, 38.289763],
          [48.669184, 38.280313],
          [48.668628, 38.276702],
          [48.668628, 38.27143],
          [48.671404, 38.264764],
          [48.675294, 38.259761],
          [48.676127, 38.257821],
          [48.676408, 38.255314],
          [48.676684, 38.254757],
          [48.676408, 38.25226],
          [48.672794, 38.244202],
          [48.673074, 38.243924],
          [48.673908, 38.241984],
          [48.673908, 38.241148],
          [48.677518, 38.23698],
          [48.679741, 38.233647],
          [48.690018, 38.222256],
          [48.689461, 38.218097],
          [48.686408, 38.214764],
          [48.678908, 38.210039],
          [48.671404, 38.201423],
          [48.669461, 38.197264],
          [48.668351, 38.193922],
          [48.666685, 38.178649],
          [48.666961, 38.177256],
          [48.668628, 38.173097],
          [48.669185, 38.172818],
          [48.669461, 38.171983],
          [48.673351, 38.167814],
          [48.676128, 38.16643],
          [48.676408, 38.166152],
          [48.676961, 38.166152],
          [48.679184, 38.164481],
          [48.679461, 38.163645],
          [48.680018, 38.163088],
          [48.680575, 38.161905],
          [48.681685, 38.16059],
          [48.683351, 38.1567],
          [48.683628, 38.156143],
          [48.683074, 38.152262],
          [48.680851, 38.147257],
          [48.678908, 38.143924],
          [48.672795, 38.139198],
          [48.671128, 38.138371],
          [48.670295, 38.138371],
          [48.658628, 38.133924],
          [48.635848, 38.118371],
          [48.633072, 38.113088],
          [48.633072, 38.111982],
          [48.633348, 38.111147],
          [48.633072, 38.110311],
          [48.632792, 38.104758],
          [48.633072, 38.103644],
          [48.634459, 38.098648],
          [48.633625, 38.087812],
          [48.626125, 38.070038],
          [48.625572, 38.068645],
          [48.625292, 38.068366],
          [48.625292, 38.068088],
          [48.624739, 38.067252],
          [48.624738, 38.066704],
          [48.624182, 38.064754],
          [48.623905, 38.063092],
          [48.619459, 38.044203],
          [48.617239, 38.03864],
          [48.616406, 38.033922],
          [48.616406, 38.028647],
          [48.616959, 38.025583],
          [48.616959, 38.025034],
          [48.617239, 38.024756],
          [48.617239, 38.024477],
          [48.617516, 38.02392],
          [48.617792, 38.023642],
          [48.618072, 38.023363],
          [48.620849, 38.017809],
          [48.620572, 38.01337],
          [48.619739, 38.01142],
          [48.612516, 38.000033],
          [48.611959, 37.997256],
          [48.611682, 37.99642],
          [48.610569, 37.990588],
          [48.607516, 37.986418],
          [48.600228, 37.9783],
          [48.600016, 37.978087],
          [48.598903, 37.97531],
          [48.598626, 37.970034],
          [48.598903, 37.969755],
          [48.600293, 37.964749],
          [48.600849, 37.962808],
          [48.601126, 37.961424],
          [48.601402, 37.960588],
          [48.600849, 37.954476],
          [48.594736, 37.942253],
          [48.584736, 37.932528],
          [48.58418, 37.931971],
          [48.57529, 37.920583],
          [48.57418, 37.917527],
          [48.570846, 37.901977],
          [48.570846, 37.901698],
          [48.57057, 37.896422],
          [48.570846, 37.895029],
          [48.57057, 37.894471],
          [48.570847, 37.891694],
          [48.576403, 37.865581],
          [48.576957, 37.864754],
          [48.576956, 37.863918],
          [48.583231, 37.844384],
          [48.583347, 37.842524],
          [48.58418, 37.840025],
          [48.58418, 37.838919],
          [48.584737, 37.836141],
          [48.585013, 37.835305],
          [48.585013, 37.833364],
          [48.585293, 37.828635],
          [48.585847, 37.823916],
          [48.586127, 37.823637],
          [48.586127, 37.820032],
          [48.59196, 37.803077],
          [48.602237, 37.792802],
          [48.604736, 37.791138],
          [48.60696, 37.791138],
          [48.607236, 37.79086],
          [48.60835, 37.79086],
          [48.612517, 37.789745],
          [48.613903, 37.790024],
          [48.61446, 37.790024],
          [48.616406, 37.790581],
          [48.62224, 37.792802],
          [48.62446, 37.792802],
          [48.626406, 37.792244],
          [48.628626, 37.791974],
          [48.631683, 37.790302],
          [48.63224, 37.788639],
          [48.63474, 37.786419],
          [48.635573, 37.786419],
          [48.635849, 37.78614],
          [48.637796, 37.785582],
          [48.640016, 37.785583],
          [48.641129, 37.785304],
          [48.642516, 37.785304],
          [48.643073, 37.785025],
          [48.64474, 37.785025],
          [48.645296, 37.784746],
          [48.647239, 37.784746],
          [48.647239, 37.784468],
          [48.648073, 37.784468],
          [48.65113, 37.783083],
          [48.651963, 37.782526],
          [48.653906, 37.780026],
          [48.654183, 37.778912],
          [48.653073, 37.773913],
          [48.648906, 37.768078],
          [48.647124, 37.76478],
          [48.646683, 37.764194],
          [48.644183, 37.758081],
          [48.643906, 37.756417],
          [48.643629, 37.753082],
          [48.641406, 37.725021],
          [48.639463, 37.721694],
          [48.636683, 37.718637],
          [48.636406, 37.718359],
          [48.633906, 37.717522],
          [48.61085, 37.705582],
          [48.60835, 37.704746],
          [48.60696, 37.704746],
          [48.60557, 37.705582],
          [48.604736, 37.705582],
          [48.599184, 37.703082],
          [48.59807, 37.701419],
          [48.59807, 37.700583],
          [48.59835, 37.699746],
          [48.59835, 37.699468],
          [48.599184, 37.698353],
          [48.597513, 37.694468],
          [48.595847, 37.691411],
          [48.596127, 37.690854],
          [48.595847, 37.690584],
          [48.59668, 37.688075],
          [48.599737, 37.685306],
          [48.60585, 37.680028],
          [48.60696, 37.678355],
          [48.609737, 37.672519],
          [48.609737, 37.672241],
          [48.609737, 37.671692],
          [48.610017, 37.671413],
          [48.609737, 37.662799],
          [48.609737, 37.659472],
          [48.612237, 37.651972],
          [48.613903, 37.649742],
          [48.61446, 37.649194],
          [48.615017, 37.648079],
          [48.616407, 37.646415],
          [48.625294, 37.63308],
          [48.636963, 37.620859],
          [48.637797, 37.62058],
          [48.63863, 37.619744],
          [48.676129, 37.597243],
          [48.677796, 37.595859],
          [48.68002, 37.592801],
          [48.68002, 37.592522],
          [48.68363, 37.582522],
          [48.681963, 37.5728],
          [48.68002, 37.571964],
          [48.679186, 37.571964],
          [48.679186, 37.571685],
          [48.678077, 37.571406],
          [48.677796, 37.570857],
          [48.67613, 37.569185],
          [48.675853, 37.567521],
          [48.678077, 37.561963],
          [48.685576, 37.551962],
          [48.685853, 37.550577],
          [48.686687, 37.549183],
          [48.688076, 37.545298],
          [48.68891, 37.540018],
          [48.689186, 37.538354],
          [48.692243, 37.516409],
          [48.69252, 37.515024],
          [48.694186, 37.508071],
          [48.694466, 37.507244],
          [48.695853, 37.504465],
          [48.696686, 37.503628],
          [48.69752, 37.501964],
          [48.715299, 37.474189],
          [48.731966, 37.458349],
          [48.735023, 37.453627],
          [48.741413, 37.445018],
          [48.756413, 37.430014],
          [48.773913, 37.416962],
          [48.800025, 37.391963],
          [48.800302, 37.391684],
          [48.809749, 37.383902],
          [48.813916, 37.380574],
          [48.835582, 37.361961],
          [48.837249, 37.361124],
          [48.838082, 37.360296],
          [48.839749, 37.35946],
          [48.860775, 37.346743],
          [48.861695, 37.346127],
          [48.883365, 37.331957],
          [48.886975, 37.329186],
          [48.904755, 37.314459],
          [48.910588, 37.303626],
          [48.910308, 37.301125],
          [48.910865, 37.298345],
          [48.906975, 37.28529],
          [48.906141, 37.281403],
          [48.905865, 37.280845],
          [48.898365, 37.256399],
          [48.898642, 37.255571],
          [48.898365, 37.254455],
          [48.898642, 37.253627],
          [48.898365, 37.253348],
          [48.898085, 37.251405],
          [48.895865, 37.246402],
          [48.895308, 37.23807],
          [48.895585, 37.236954],
          [48.895308, 37.236126],
          [48.895032, 37.234453],
          [48.895032, 37.228622],
          [48.895308, 37.226399],
          [48.895309, 37.225562],
          [48.899475, 37.218346],
          [48.901422, 37.215008],
          [48.920312, 37.185286],
          [48.920588, 37.183342],
          [48.920865, 37.181677],
          [48.921145, 37.180291],
          [48.921422, 37.180012],
          [48.922255, 37.176674],
          [48.922255, 37.176125],
          [48.923088, 37.172516],
          [48.923365, 37.172228],
          [48.922812, 37.167234],
          [48.924755, 37.16223],
          [48.926145, 37.160008],
          [48.930865, 37.151675],
          [48.931422, 37.151396],
          [48.931978, 37.150289],
          [48.940312, 37.142783],
          [48.967535, 37.123903],
          [48.985591, 37.109728],
          [48.991424, 37.10612],
          [48.992258, 37.105292],
          [49.012538, 37.087508],
          [49.013371, 37.086122],
          [49.013928, 37.085843],
          [49.014761, 37.084457],
          [49.025038, 37.0739],
          [49.049204, 37.059175],
          [49.054484, 37.055845],
          [49.056707, 37.05445],
          [49.066427, 37.042228],
          [49.07365, 37.033614],
          [49.07615, 37.026116],
          [49.07754, 37.021949],
          [49.091707, 37.005837],
          [49.09254, 37.00473],
          [49.094764, 37.002133],
          [49.09504, 37.00167],
          [49.09532, 37.001391],
          [49.095874, 36.999735],
          [49.103374, 36.995558],
          [49.11532, 36.985288],
          [49.115597, 36.985009],
          [49.120044, 36.979725],
          [49.12032, 36.979446],
          [49.120597, 36.978897],
          [49.152267, 36.937228],
          [49.163933, 36.925841],
          [49.170046, 36.919449],
          [49.170323, 36.9189],
          [49.173933, 36.916947],
          [49.175046, 36.916947],
          [49.175599, 36.916668],
          [49.176713, 36.916668],
          [49.177546, 36.916389],
          [49.192269, 36.914453],
          [49.214493, 36.900833],
          [49.216993, 36.896674],
          [49.228382, 36.861672],
          [49.231436, 36.857503],
          [49.237549, 36.850004],
          [49.280052, 36.816116],
          [49.280329, 36.815837],
          [49.280605, 36.815558],
          [49.280885, 36.815279],
          [49.296719, 36.806392],
          [49.304219, 36.798892],
          [49.304714, 36.798643],
          [49.305609, 36.797776],
          [49.317832, 36.781389],
          [49.33215, 36.756772],
          [49.334529, 36.752679],
          [49.33994, 36.743371],
          [49.340608, 36.742222],
          [49.351587, 36.731254],
          [49.354585, 36.728257],
          [49.357296, 36.725549],
          [49.361261, 36.721585],
          [49.362315, 36.720532],
          [49.365521, 36.717327],
          [49.397721, 36.685121],
          [49.401261, 36.681578],
          [49.402073, 36.680765],
          [49.403944, 36.678892],
          [49.405891, 36.677776],
          [49.410891, 36.674444],
          [49.431171, 36.662773],
          [49.432004, 36.662773],
          [49.432281, 36.662494],
          [49.434504, 36.661945],
          [49.435057, 36.661386],
          [49.436724, 36.660837],
          [49.437142, 36.660418],
          [49.437838, 36.660279],
          [49.443671, 36.657775],
          [49.453671, 36.648058],
          [49.453947, 36.647221],
          [49.454504, 36.646663],
          [49.460894, 36.632776],
          [49.461727, 36.628886],
          [49.465337, 36.623888],
          [49.47506, 36.613054],
          [49.47895, 36.610829],
          [49.484227, 36.608056],
          [49.48534, 36.607497],
          [49.493117, 36.603607],
          [49.499784, 36.600833],
          [49.50923, 36.603328],
          [49.512007, 36.603328],
          [49.512564, 36.603607],
          [49.513117, 36.603328],
          [49.51395, 36.603607],
          [49.514783, 36.603607],
          [49.51534, 36.603328],
          [49.515617, 36.603607],
          [49.518673, 36.603607],
          [49.520897, 36.604165],
          [49.52312, 36.604165],
          [49.52423, 36.604444],
          [49.53312, 36.604444],
          [49.533396, 36.604724],
          [49.543396, 36.604723],
          [49.55312, 36.60666],
          [49.557286, 36.60666],
          [49.561733, 36.60666],
          [49.570066, 36.60611],
          [49.573676, 36.606381],
          [49.574509, 36.60666],
          [49.576732, 36.60666],
          [49.580066, 36.605831],
          [49.580899, 36.605831],
          [49.582009, 36.605273],
          [49.583122, 36.605273],
          [49.583676, 36.605003],
          [49.590066, 36.605003],
          [49.601179, 36.606939],
          [49.603123, 36.606939],
          [49.604232, 36.607218],
          [49.605622, 36.607218],
          [49.606179, 36.607497],
          [49.610899, 36.607497],
          [49.611456, 36.607218],
          [49.612845, 36.607218],
          [49.613679, 36.606939],
          [49.615622, 36.606939],
          [49.617012, 36.606381],
          [49.618122, 36.606111],
          [49.631179, 36.602499],
          [49.656182, 36.592773],
          [49.660625, 36.590278],
          [49.672292, 36.585001],
          [49.673405, 36.585001],
          [49.678405, 36.583605],
          [49.686181, 36.580831],
          [49.692291, 36.579444],
          [49.694166, 36.579061],
          [49.729795, 36.570834],
          [49.731461, 36.570834],
          [49.735074, 36.569996],
          [49.737294, 36.569996],
          [49.738961, 36.569717],
          [49.741741, 36.569717],
          [49.743408, 36.569447],
          [49.744241, 36.569168],
          [49.787661, 36.559383],
          [49.832208, 36.569206],
          [49.878335, 36.578183],
          [49.900847, 36.582571],
          [49.935983, 36.572496],
          [49.938971, 36.602638],
          [49.979897, 36.596222],
          [50.007314, 36.602658],
          [50.027309, 36.611327],
          [50.058154, 36.604062],
          [50.097564, 36.631352],
          [50.098423, 36.632498],
          [50.103423, 36.635002],
          [50.104813, 36.635551],
          [50.105367, 36.636109],
          [50.110923, 36.638883],
          [50.114813, 36.641116],
          [50.125646, 36.64778],
          [50.127313, 36.64833],
          [50.127593, 36.648888],
          [50.170926, 36.66861],
          [50.171482, 36.668889],
          [50.175093, 36.670005],
          [50.201206, 36.678056],
          [50.221485, 36.679722],
          [50.223985, 36.679452],
          [50.224819, 36.679173],
          [50.227595, 36.679173],
          [50.230375, 36.678614],
          [50.232042, 36.678614],
          [50.241765, 36.676111],
          [50.246485, 36.674166],
          [50.250652, 36.672221],
          [50.269822, 36.660838],
          [50.270655, 36.66028],
          [50.304821, 36.643332],
          [50.313154, 36.640837],
          [50.314268, 36.640837],
          [50.315934, 36.640279],
          [50.327324, 36.638613],
          [50.328434, 36.638334],
          [50.330934, 36.638334],
          [50.331491, 36.638055],
          [50.332601, 36.638055],
          [50.333157, 36.637776],
          [50.333711, 36.637776],
          [50.337324, 36.636947],
          [50.340381, 36.63583],
          [50.342881, 36.63583],
          [50.347324, 36.636947],
          [50.348714, 36.636947],
          [50.353437, 36.638613],
          [50.353991, 36.638613],
          [50.354824, 36.638883],
          [50.355657, 36.639442],
          [50.35649, 36.639442],
          [50.358714, 36.641117],
          [50.359824, 36.641666],
          [50.376213, 36.654994],
          [50.378714, 36.657227],
          [50.408993, 36.690555],
          [50.421216, 36.707223],
          [50.421773, 36.707781],
          [50.42455, 36.711392],
          [50.424826, 36.7125],
          [50.425383, 36.712779],
          [50.426493, 36.715282],
          [50.427883, 36.718056],
          [50.432329, 36.730005],
          [50.432606, 36.734165],
          [50.432883, 36.735002],
          [50.432606, 36.735839],
          [50.432883, 36.736947],
          [50.432606, 36.737505],
          [50.432329, 36.740278],
          [50.431773, 36.744447],
          [50.431773, 36.773333],
          [50.431496, 36.773612],
          [50.431216, 36.779725],
          [50.428439, 36.796112],
          [50.428996, 36.807781],
          [50.428996, 36.809725],
          [50.428716, 36.811112],
          [50.427606, 36.816676],
          [50.427326, 36.819449],
          [50.427574, 36.821515],
          [50.427574, 36.821517],
          [50.428716, 36.838338],
          [50.430106, 36.848619],
          [50.431216, 36.85223],
          [50.431457, 36.852539],
          [50.432606, 36.856668],
          [50.441773, 36.864167],
          [50.442329, 36.864725],
          [50.445663, 36.866391],
          [50.447329, 36.866949],
          [50.448718, 36.867785],
          [50.448719, 36.867786],
          [50.449516, 36.867786],
          [50.449552, 36.867786],
          [50.452329, 36.869173],
          [50.467329, 36.874728],
          [50.467724, 36.874926],
          [50.476772, 36.877508],
          [50.476775, 36.877509],
          [50.477851, 36.877509],
          [50.477885, 36.877509],
          [50.482881, 36.878895],
          [50.482885, 36.878896],
          [50.485109, 36.878896],
          [50.487332, 36.878338],
          [50.490108, 36.878068],
          [50.505108, 36.872225],
          [50.505381, 36.871953],
          [50.505388, 36.871946],
          [50.509275, 36.873062],
          [50.512888, 36.875007],
          [50.520108, 36.88084],
          [50.521775, 36.883343],
          [50.523165, 36.890563],
          [50.522331, 36.892229],
          [50.522055, 36.892508],
          [50.534831, 36.904733],
          [50.535665, 36.905003],
          [50.535944, 36.905561],
          [50.537055, 36.906119],
          [50.539831, 36.908343],
          [50.590947, 36.956674],
          [50.591224, 36.957233],
          [50.591781, 36.957512],
          [50.592057, 36.958061],
          [50.592614, 36.965464],
          [50.592639, 36.96563],
          [50.59267, 36.965836],
          [50.655001, 37.045403],
          [50.641812, 37.052893],
          [50.564837, 37.097994],
          [50.554192, 37.104229],
          [50.514469, 37.127491],
          [50.486526, 37.14385],
          [50.461695, 37.158275],
          [50.445717, 37.167555],
          [50.438808, 37.171567],
          [50.423662, 37.19912],
          [50.405036, 37.232991],
          [50.387685, 37.264531],
          [50.343121, 37.345472],
          [50.336093, 37.358229],
          [50.292419, 37.435975],
          [50.232569, 37.465511],
          [50.131749, 37.4834],
          [50.062817, 37.495629],
          [50.033611, 37.50081],
          [49.956775, 37.552123],
          [49.90182, 37.551415],
          [49.857295, 37.550841],
          [49.837863, 37.544288],
          [49.786931, 37.527112],
          [49.732213, 37.534263],
          [49.700348, 37.538426],
          [49.682241, 37.540792],
          [49.569803, 37.555482],
          [49.509808, 37.563319],
          [49.428642, 37.573921],
          [49.426056, 37.575045],
          [49.312541, 37.624386],
          [49.264008, 37.645471],
          [49.204304, 37.671401],
          [49.195713, 37.675132],
          [49.150247, 37.720777],
          [49.120497, 37.75063],
          [49.119099, 37.756635],
          [49.107745, 37.805392],
          [49.096233, 37.854793],
          [49.093147, 37.866317],
          [49.076131, 37.929825],
          [49.066308, 37.966461],
          [49.079221, 38.005631],
          [49.103055, 38.077874],
          [49.105457, 38.085149],
          [49.139835, 38.189216],
          [49.140233, 38.190419],
          [49.174774, 38.294827],
          [49.176137, 38.298946],
          [49.182987, 38.319631],
          [49.193356, 38.350936],
          [49.212399, 38.408387],
          [49.100387, 38.419722],
          [48.988271, 38.431066],
          [48.88229, 38.441787],
          [48.881676, 38.442042],
          [48.880966, 38.442229],
          [48.880122, 38.442225],
          [48.873879, 38.440198],
          [48.872761, 38.440242],
          [48.871708, 38.440561],
          [48.871105, 38.440989],
          [48.86937, 38.442864],
          [48.868855, 38.443863],
          [48.86801, 38.444488],
          [48.867587, 38.444549],
          [48.867028, 38.44438],
          [48.866653, 38.444406],
          [48.866033, 38.44429],
          [48.865697, 38.443952],
          [48.865758, 38.443545],
          [48.865478, 38.442749],
          [48.865149, 38.442411],
          [48.864481, 38.442253],
          [48.863409, 38.442682],
          [48.862729, 38.443476],
          [48.862732, 38.443945],
          [48.862465, 38.444452],
          [48.861944, 38.444925],
          [48.860543, 38.445758],
          [48.860126, 38.446163],
          [48.859031, 38.446444],
          [48.858258, 38.446388],
          [48.856833, 38.447082],
          [48.856172, 38.447508],
          [48.855456, 38.447721],
          [48.854987, 38.447732],
          [48.85366, 38.448795],
          [48.852766, 38.448505],
          [48.852042, 38.448728],
          [48.851498, 38.449086],
          [48.851395, 38.449761],
          [48.850817, 38.450346],
          [48.850375, 38.450208],
          [48.849886, 38.449916],
          [48.849178, 38.449266],
          [48.848255, 38.448914],
          [48.847755, 38.448632],
          [48.846643, 38.448706],
          [48.846233, 38.448923],
          [48.845484, 38.449258],
          [48.844429, 38.450188],
          [48.84409, 38.450308],
          [48.843128, 38.451415],
          [48.843133, 38.451661],
          [48.841855, 38.452174],
          [48.841153, 38.452275],
          [48.840588, 38.452203],
          [48.839825, 38.451926],
          [48.839306, 38.451805],
          [48.83846, 38.451987],
          [48.837334, 38.452069],
          [48.836207, 38.451802],
          [48.835521, 38.451714],
          [48.834187, 38.452039],
          [48.83384, 38.451806],
          [48.832739, 38.450195],
          [48.83142, 38.449491],
          [48.830165, 38.449232],
          [48.828532, 38.449452],
          [48.825596, 38.449187],
          [48.824014, 38.449208],
          [48.823326, 38.448712],
          [48.821094, 38.448092],
          [48.820199, 38.448181],
          [48.819782, 38.448575],
          [48.819573, 38.449736],
          [48.819266, 38.450161],
          [48.818435, 38.450656],
          [48.816924, 38.450755],
          [48.815262, 38.450415],
          [48.814493, 38.449791],
          [48.813629, 38.448028],
          [48.812286, 38.447667],
          [48.811593, 38.447615],
          [48.810625, 38.448045],
          [48.809892, 38.448803],
          [48.808994, 38.44926],
          [48.808035, 38.449092],
          [48.807211, 38.449279],
          [48.806504, 38.449303],
          [48.806005, 38.449138],
          [48.805049, 38.448976],
          [48.80427, 38.449188],
          [48.803556, 38.449083],
          [48.802447, 38.449477],
          [48.801967, 38.449837],
          [48.801788, 38.450403],
          [48.801289, 38.451179],
          [48.80053, 38.451505],
          [48.799996, 38.451483],
          [48.799388, 38.451228],
          [48.798792, 38.450821],
          [48.797886, 38.450534],
          [48.795693, 38.450857],
          [48.794167, 38.451249],
          [48.792683, 38.4514],
          [48.792182, 38.451608],
          [48.791324, 38.451748],
          [48.79027, 38.451144],
          [48.789171, 38.450709],
          [48.788389, 38.45095],
          [48.787768, 38.450595],
          [48.787278, 38.449807],
          [48.787244, 38.448822],
          [48.787028, 38.448011],
          [48.786271, 38.4472],
          [48.785327, 38.447146],
          [48.784111, 38.447399],
          [48.783681, 38.447299],
          [48.782779, 38.4469],
          [48.781439, 38.444947],
          [48.779925, 38.443738],
          [48.779515, 38.441604],
          [48.774256, 38.439693],
          [48.771948, 38.440154],
          [48.770041, 38.441344],
          [48.76731, 38.440739],
          [48.764888, 38.43793],
          [48.764257, 38.434572],
          [48.761433, 38.433276],
          [48.7598, 38.431183],
          [48.757879, 38.430585],
          [48.756268, 38.428928],
          [48.753334, 38.428241],
          [48.752473, 38.424992],
          [48.748788, 38.423533],
          [48.747852, 38.422502],
          [48.747897, 38.419044],
          [48.746853, 38.41715],
          [48.743119, 38.415835],
          [48.741367, 38.414463],
          [48.739824, 38.412044],
          [48.738303, 38.411121],
          [48.732759, 38.411672],
          [48.730878, 38.410784],
          [48.725906, 38.409945],
          [48.723112, 38.410113],
          [48.720735, 38.408523],
          [48.716998, 38.407974],
          [48.71353, 38.408388],
          [48.71118, 38.407974],
          [48.706797, 38.408359],
          [48.702364, 38.406648],
          [48.701295, 38.405322],
          [48.699393, 38.405314],
          [48.694313, 38.401152],
          [48.693667, 38.400274],
          [48.690639, 38.399274],
          [48.686078, 38.398891],
          [48.68156, 38.397423],
          [48.676488, 38.39707],
          [48.671087, 38.3959],
          [48.668673, 38.396953],
          [48.667339, 38.396469],
          [48.666469, 38.394238],
          [48.663513, 38.393491],
          [48.662272, 38.392955],
          [48.659283, 38.393366],
          [48.657024, 38.396031],
          [48.656085, 38.39815],
          [48.654485, 38.397857],
          [48.650875, 38.400015],
          [48.6459, 38.40141],
          [48.642185, 38.401147],
          [48.640957, 38.399606],
          [48.639016, 38.39914],
          [48.63713, 38.399516],
          [48.632508, 38.402033],
          [48.62897, 38.402176],
          [48.62829, 38.403845],
          [48.626276, 38.404535],
          [48.625037, 38.404618],
          [48.62361, 38.405292],
          [48.62233, 38.407414],
          [48.61916, 38.407808],
          [48.618405, 38.408697],
          [48.616642, 38.409272],
          [48.616212, 38.409941],
          [48.614596, 38.410358],
          [48.608165, 38.415412],
          [48.606214, 38.41624],
          [48.604837, 38.416249],
          [48.60383, 38.417054],
          [48.60309, 38.416813],
          [48.601878, 38.41736],
          [48.602248, 38.423766],
          [48.600974, 38.424233],
          [48.598269, 38.426072],
          [48.598122, 38.427941],
          [48.59423, 38.431558],
          [48.592536, 38.435219],
          [48.591972, 38.436948],
          [48.592885, 38.445937],
          [48.590418, 38.449526],
          [48.59158, 38.453076],
          [48.590855, 38.455654],
          [48.587397, 38.454623],
          [48.583297, 38.456565],
          [48.582806, 38.458536],
          [48.581826, 38.460338],
          [48.581314, 38.45952],
          [48.580906, 38.457999],
          [48.580595, 38.454811],
          [48.580724, 38.453987],
          [48.582791, 38.445045],
          [48.582234, 38.444767],
          [48.581958, 38.44421],
          [48.573621, 38.438373],
          [48.573068, 38.436434],
          [48.572512, 38.426708],
          [48.570012, 38.419488],
          [48.569178, 38.414208],
        ],
      ],
    },
  },
  Golestan: {
    properties: { value: "Golestan", name: "استان گلستان, ایران", id: 5 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [53.852669, 36.984442],
          [53.854805, 36.939096],
          [53.856537, 36.902308],
          [53.857416, 36.883628],
          [53.862177, 36.782372],
          [53.862215, 36.781559],
          [53.862215, 36.781123],
          [53.862491, 36.780844],
          [53.862215, 36.780565],
          [53.862492, 36.780007],
          [53.862215, 36.776954],
          [53.861658, 36.76362],
          [53.866105, 36.749447],
          [53.865272, 36.746116],
          [53.864438, 36.744729],
          [53.860825, 36.739723],
          [53.860548, 36.739173],
          [53.860268, 36.735842],
          [53.861102, 36.733339],
          [53.861658, 36.730007],
          [53.861935, 36.725],
          [53.862215, 36.723335],
          [53.862492, 36.723055],
          [53.862492, 36.705001],
          [53.862215, 36.704443],
          [53.860825, 36.698896],
          [53.860825, 36.697221],
          [53.861382, 36.696113],
          [53.861935, 36.694168],
          [53.862215, 36.693619],
          [53.862215, 36.692503],
          [53.862215, 36.690278],
          [53.861935, 36.686109],
          [53.864715, 36.679454],
          [53.869158, 36.67361],
          [53.871135, 36.672555],
          [53.873325, 36.671386],
          [53.874715, 36.671116],
          [53.882771, 36.669171],
          [53.887215, 36.669171],
          [53.888328, 36.66945],
          [53.891105, 36.66945],
          [53.892771, 36.66945],
          [53.893605, 36.669729],
          [53.894995, 36.669729],
          [53.895828, 36.670008],
          [53.897214, 36.670008],
          [53.904438, 36.671386],
          [53.906384, 36.671386],
          [53.913051, 36.673061],
          [53.920551, 36.675555],
          [53.923327, 36.675834],
          [53.924441, 36.676114],
          [53.926661, 36.676114],
          [53.928051, 36.676393],
          [53.929994, 36.676393],
          [53.932774, 36.676942],
          [53.938327, 36.676942],
          [53.939161, 36.676663],
          [53.946539, 36.676771],
          [53.947217, 36.676942],
          [53.949997, 36.676942],
          [53.951107, 36.676663],
          [53.96472, 36.675285],
          [53.973847, 36.669847],
          [53.97444, 36.66945],
          [53.99222, 36.654447],
          [53.99361, 36.653339],
          [53.996943, 36.651394],
          [54.000277, 36.650557],
          [54.003053, 36.650278],
          [54.00361, 36.649998],
          [54.00611, 36.649998],
          [54.006667, 36.649728],
          [54.007777, 36.649728],
          [54.008053, 36.649449],
          [54.008887, 36.649449],
          [54.009443, 36.64917],
          [54.010553, 36.64917],
          [54.011943, 36.648612],
          [54.013053, 36.648612],
          [54.01361, 36.648332],
          [54.015, 36.648332],
          [54.015833, 36.648062],
          [54.016943, 36.648062],
          [54.0275, 36.64528],
          [54.03139, 36.64528],
          [54.04778, 36.642227],
          [54.069956, 36.632359],
          [54.098336, 36.619722],
          [54.099169, 36.618884],
          [54.104449, 36.615831],
          [54.113059, 36.606384],
          [54.114725, 36.603331],
          [54.117225, 36.597775],
          [54.127505, 36.572494],
          [54.128059, 36.570279],
          [54.130005, 36.550555],
          [54.128895, 36.542782],
          [54.130562, 36.51805],
          [54.130282, 36.516113],
          [54.129449, 36.511385],
          [54.129172, 36.50916],
          [54.128895, 36.506665],
          [54.127782, 36.503891],
          [54.127782, 36.503332],
          [54.127225, 36.501666],
          [54.126949, 36.500558],
          [54.126672, 36.499162],
          [54.126392, 36.497217],
          [54.126115, 36.496667],
          [54.126115, 36.491659],
          [54.127225, 36.491659],
          [54.127505, 36.491938],
          [54.128339, 36.491938],
          [54.128895, 36.492218],
          [54.130005, 36.492218],
          [54.155285, 36.499721],
          [54.158062, 36.499721],
          [54.158618, 36.5],
          [54.160562, 36.5],
          [54.165838, 36.500558],
          [54.199174, 36.502774],
          [54.222507, 36.504999],
          [54.234454, 36.504999],
          [54.248901, 36.505548],
          [54.259734, 36.506386],
          [54.274734, 36.506386],
          [54.276677, 36.506665],
          [54.2914, 36.506665],
          [54.299457, 36.507494],
          [54.301957, 36.507494],
          [54.30918, 36.508331],
          [54.311123, 36.508331],
          [54.31279, 36.50861],
          [54.315013, 36.50861],
          [54.32307, 36.509998],
          [54.325013, 36.509998],
          [54.325846, 36.510277],
          [54.331126, 36.510277],
          [54.332793, 36.510556],
          [54.33446, 36.510556],
          [54.337793, 36.511385],
          [54.340016, 36.511385],
          [54.34057, 36.511664],
          [54.341403, 36.511664],
          [54.34196, 36.511943],
          [54.34446, 36.512222],
          [54.368982, 36.519124],
          [54.373073, 36.520274],
          [54.374739, 36.520274],
          [54.375849, 36.520554],
          [54.378629, 36.520554],
          [54.379182, 36.520833],
          [54.390572, 36.520833],
          [54.391682, 36.520554],
          [54.407795, 36.520554],
          [54.426962, 36.518329],
          [54.427518, 36.518329],
          [54.432242, 36.517221],
          [54.455022, 36.512222],
          [54.467798, 36.510835],
          [54.475855, 36.510835],
          [54.477245, 36.510556],
          [54.479745, 36.510835],
          [54.483631, 36.510835],
          [54.486411, 36.511105],
          [54.487801, 36.511385],
          [54.490578, 36.511385],
          [54.498634, 36.512501],
          [54.507244, 36.514447],
          [54.507801, 36.514726],
          [54.508911, 36.514726],
          [54.517524, 36.517221],
          [54.537247, 36.526111],
          [54.55697, 36.539999],
          [54.569194, 36.544169],
          [54.56947, 36.544448],
          [54.570261, 36.544761],
          [54.57086, 36.544998],
          [54.613083, 36.584725],
          [54.61614, 36.587778],
          [54.631696, 36.604169],
          [54.632806, 36.605835],
          [54.644753, 36.61611],
          [54.646696, 36.616669],
          [54.649473, 36.618056],
          [54.660309, 36.618884],
          [54.675586, 36.625278],
          [54.685309, 36.627782],
          [54.686419, 36.627782],
          [54.689476, 36.628889],
          [54.690309, 36.62889],
          [54.691142, 36.629448],
          [54.699476, 36.631663],
          [54.700866, 36.632501],
          [54.721979, 36.617506],
          [54.725312, 36.615831],
          [54.747255, 36.604169],
          [54.755035, 36.601944],
          [54.761702, 36.600837],
          [54.764202, 36.600837],
          [54.765592, 36.600557],
          [54.766978, 36.600557],
          [54.767811, 36.600278],
          [54.778091, 36.600278],
          [54.788925, 36.602782],
          [54.843371, 36.620838],
          [54.847817, 36.622225],
          [54.865317, 36.629448],
          [54.912263, 36.664443],
          [54.92282, 36.675006],
          [54.923653, 36.676663],
          [54.947266, 36.704722],
          [54.947266, 36.705001],
          [54.947543, 36.70528],
          [54.947823, 36.705559],
          [54.947823, 36.705838],
          [54.958933, 36.718337],
          [54.976989, 36.742226],
          [54.980603, 36.747232],
          [54.987269, 36.754723],
          [55.001436, 36.764169],
          [55.010045, 36.767221],
          [55.037549, 36.773065],
          [55.038382, 36.773335],
          [55.040605, 36.773335],
          [55.050049, 36.775009],
          [55.052548, 36.775009],
          [55.056162, 36.775559],
          [55.057548, 36.775559],
          [55.058382, 36.775838],
          [55.061162, 36.775838],
          [55.061995, 36.776117],
          [55.065605, 36.776117],
          [55.066715, 36.776396],
          [55.072828, 36.776396],
          [55.076162, 36.776675],
          [55.079218, 36.776675],
          [55.080885, 36.776954],
          [55.084218, 36.776954],
          [55.090885, 36.777503],
          [55.095608, 36.778899],
          [55.106994, 36.780844],
          [55.109775, 36.780844],
          [55.111164, 36.781123],
          [55.112551, 36.781123],
          [55.115331, 36.781123],
          [55.116164, 36.781123],
          [55.121998, 36.781123],
          [55.127831, 36.783059],
          [55.128108, 36.783338],
          [55.129774, 36.783338],
          [55.130331, 36.783617],
          [55.131164, 36.783617],
          [55.131721, 36.783896],
          [55.137274, 36.783896],
          [55.138664, 36.784175],
          [55.141721, 36.784175],
          [55.155331, 36.785562],
          [55.18061, 36.786669],
          [55.187834, 36.787227],
          [55.19061, 36.787228],
          [55.1945, 36.787786],
          [55.198667, 36.787786],
          [55.207557, 36.788614],
          [55.21728, 36.788614],
          [55.21978, 36.788893],
          [55.22339, 36.788893],
          [55.22589, 36.789172],
          [55.228113, 36.789172],
          [55.229223, 36.789451],
          [55.231723, 36.789451],
          [55.254226, 36.792225],
          [55.266169, 36.794728],
          [55.268393, 36.795565],
          [55.272836, 36.798059],
          [55.273116, 36.798338],
          [55.281173, 36.798338],
          [55.282006, 36.798617],
          [55.284506, 36.798617],
          [55.286726, 36.799175],
          [55.287282, 36.799175],
          [55.292559, 36.799725],
          [55.294782, 36.799725],
          [55.295339, 36.800004],
          [55.296726, 36.800004],
          [55.298392, 36.800562],
          [55.316449, 36.803065],
          [55.318396, 36.803065],
          [55.322006, 36.803614],
          [55.324505, 36.803614],
          [55.354228, 36.807783],
          [55.359508, 36.808341],
          [55.360342, 36.80862],
          [55.362285, 36.80862],
          [55.373675, 36.810565],
          [55.400898, 36.816957],
          [55.412844, 36.825285],
          [55.416454, 36.827779],
          [55.470624, 36.877232],
          [55.50007, 36.901116],
          [55.50618, 36.89945],
          [55.510903, 36.89945],
          [55.51285, 36.900009],
          [55.514237, 36.900009],
          [55.518403, 36.901953],
          [55.519518, 36.90251],
          [55.522017, 36.903618],
          [55.522293, 36.903897],
          [55.523683, 36.903898],
          [55.547573, 36.913899],
          [55.553406, 36.918346],
          [55.56035, 36.925287],
          [55.562573, 36.928347],
          [55.563686, 36.929455],
          [55.579239, 36.95556],
          [55.579519, 36.957234],
          [55.585076, 36.970845],
          [55.591463, 36.988901],
          [55.596186, 36.998343],
          [55.597019, 37.001953],
          [55.598686, 37.006399],
          [55.598686, 37.008344],
          [55.598963, 37.008623],
          [55.598686, 37.009172],
          [55.598963, 37.009451],
          [55.599243, 37.013069],
          [55.601743, 37.03279],
          [55.602019, 37.038064],
          [55.602576, 37.043906],
          [55.603686, 37.051124],
          [55.603966, 37.051952],
          [55.604187, 37.053065],
          [55.604187, 37.053068],
          [55.604519, 37.055849],
          [55.607019, 37.0689],
          [55.621466, 37.099176],
          [55.630356, 37.111955],
          [55.630632, 37.112234],
          [55.641665, 37.123418],
          [55.651189, 37.133068],
          [55.654799, 37.136128],
          [55.660355, 37.140844],
          [55.675635, 37.153623],
          [55.676745, 37.15446],
          [55.677025, 37.155288],
          [55.686745, 37.163909],
          [55.700635, 37.183067],
          [55.705915, 37.191958],
          [55.710635, 37.207795],
          [55.710915, 37.210846],
          [55.710915, 37.211682],
          [55.710915, 37.213068],
          [55.712025, 37.220573],
          [55.712302, 37.224181],
          [55.712582, 37.231128],
          [55.712302, 37.236958],
          [55.712582, 37.237795],
          [55.712858, 37.240018],
          [55.713135, 37.241682],
          [55.715081, 37.257797],
          [55.715358, 37.262242],
          [55.716472, 37.266408],
          [55.720081, 37.271132],
          [55.745081, 37.284736],
          [55.751347, 37.288552],
          [55.757861, 37.292519],
          [55.788974, 37.308075],
          [55.808626, 37.317863],
          [55.822307, 37.322245],
          [55.825364, 37.323073],
          [55.827864, 37.323352],
          [55.829254, 37.323909],
          [55.841753, 37.325574],
          [55.845364, 37.325574],
          [55.851477, 37.326132],
          [55.854253, 37.326132],
          [55.88259, 37.329191],
          [55.884533, 37.329191],
          [55.88759, 37.329739],
          [55.889813, 37.329739],
          [55.900366, 37.331134],
          [55.908423, 37.332341],
          [55.911479, 37.332798],
          [55.92037, 37.334463],
          [55.92148, 37.334463],
          [55.948982, 37.343072],
          [55.951482, 37.34363],
          [55.952592, 37.344188],
          [55.957592, 37.345295],
          [55.958982, 37.345852],
          [55.961482, 37.346131],
          [56.003985, 37.359464],
          [56.004819, 37.359743],
          [56.010652, 37.362793],
          [56.013985, 37.366409],
          [56.016485, 37.370295],
          [56.016485, 37.371132],
          [56.008985, 37.379184],
          [56.008985, 37.379463],
          [56.007595, 37.380578],
          [55.998429, 37.405578],
          [55.997315, 37.410301],
          [55.997315, 37.411407],
          [55.997039, 37.417524],
          [55.997872, 37.422795],
          [56.001205, 37.442522],
          [56.008985, 37.457805],
          [56.011485, 37.462797],
          [56.012319, 37.464191],
          [56.012541, 37.464524],
          [56.031762, 37.488639],
          [56.032042, 37.489466],
          [56.050052, 37.507478],
          [56.051765, 37.509191],
          [56.053432, 37.511134],
          [56.058432, 37.519193],
          [56.061208, 37.534743],
          [56.060931, 37.547245],
          [56.061208, 37.548081],
          [56.060931, 37.553082],
          [56.060375, 37.558918],
          [56.060655, 37.560025],
          [56.060931, 37.562246],
          [56.060655, 37.56391],
          [56.060931, 37.564189],
          [56.060655, 37.564746],
          [56.060931, 37.565025],
          [56.061208, 37.566968],
          [56.061488, 37.570026],
          [56.061208, 37.572247],
          [56.081765, 37.595863],
          [56.082598, 37.596412],
          [56.085931, 37.600027],
          [56.109268, 37.623363],
          [56.118711, 37.634748],
          [56.125934, 37.652247],
          [56.126768, 37.653641],
          [56.127878, 37.656968],
          [56.128711, 37.658083],
          [56.137048, 37.680302],
          [56.138434, 37.687252],
          [56.144268, 37.70225],
          [56.144687, 37.702881],
          [56.154547, 37.719199],
          [56.164824, 37.745309],
          [56.169271, 37.75947],
          [56.187589, 37.796822],
          [56.19066, 37.803082],
          [56.190937, 37.804196],
          [56.195937, 37.814472],
          [56.197327, 37.81725],
          [56.197884, 37.818922],
          [56.198994, 37.820585],
          [56.20427, 37.834474],
          [56.21566, 37.859482],
          [56.22594, 37.866979],
          [56.23344, 37.866979],
          [56.235107, 37.8667],
          [56.236217, 37.8667],
          [56.23705, 37.866422],
          [56.238997, 37.866422],
          [56.241773, 37.865307],
          [56.242607, 37.864759],
          [56.250107, 37.862808],
          [56.255663, 37.86226],
          [56.26233, 37.86226],
          [56.265663, 37.862808],
          [56.26761, 37.862808],
          [56.270386, 37.863644],
          [56.271496, 37.863644],
          [56.280386, 37.867258],
          [56.290386, 37.876148],
          [56.294833, 37.88253],
          [56.297609, 37.892534],
          [56.305389, 37.900309],
          [56.310389, 37.910314],
          [56.311499, 37.91559],
          [56.311499, 37.917531],
          [56.313337, 37.931188],
          [56.313443, 37.931975],
          [56.313723, 37.935588],
          [56.313999, 37.940037],
          [56.313723, 37.949205],
          [56.312056, 37.953645],
          [56.310943, 37.96559],
          [56.311223, 37.966983],
          [56.310109, 37.973373],
          [56.309556, 37.979205],
          [56.309833, 37.979754],
          [56.310109, 37.983645],
          [56.307902, 38.00029],
          [56.309, 38.007254],
          [56.304556, 38.025039],
          [56.304, 38.025587],
          [56.304, 38.026423],
          [56.29872, 38.034205],
          [56.295943, 38.042536],
          [56.295386, 38.04587],
          [56.294833, 38.049482],
          [56.29511, 38.050039],
          [56.295386, 38.053652],
          [56.294, 38.074481],
          [56.29372, 38.075874],
          [56.289987, 38.081936],
          [56.289289, 38.08412],
          [56.288976, 38.084658],
          [56.286591, 38.083062],
          [56.286327, 38.082705],
          [56.286179, 38.082156],
          [56.28598, 38.081862],
          [56.285693, 38.081705],
          [56.285121, 38.081627],
          [56.283464, 38.081604],
          [56.281237, 38.081152],
          [56.280692, 38.08081],
          [56.280784, 38.078993],
          [56.275071, 38.080458],
          [56.267222, 38.080833],
          [56.260833, 38.079444],
          [56.254444, 38.077778],
          [56.248056, 38.076389],
          [56.24358, 38.077735],
          [56.236906, 38.076585],
          [56.231691, 38.075196],
          [56.223995, 38.074456],
          [56.219757, 38.073922],
          [56.216111, 38.072222],
          [56.210556, 38.074722],
          [56.204722, 38.077222],
          [56.198889, 38.079722],
          [56.194167, 38.083056],
          [56.189444, 38.086111],
          [56.183611, 38.088611],
          [56.178889, 38.091667],
          [56.173056, 38.094444],
          [56.164444, 38.095],
          [56.1575, 38.094167],
          [56.151111, 38.092778],
          [56.143889, 38.091944],
          [56.136944, 38.091111],
          [56.13017, 38.090946],
          [56.123611, 38.088889],
          [56.117222, 38.0875],
          [56.11, 38.086667],
          [56.103889, 38.085],
          [56.096667, 38.084167],
          [56.093166, 38.087897],
          [56.086316, 38.086085],
          [56.082205, 38.08473],
          [56.073402, 38.083813],
          [56.064357, 38.082491],
          [56.054631, 38.081419],
          [56.03872, 38.080725],
          [56.032893, 38.079761],
          [56.025766, 38.078971],
          [56.021386, 38.077677],
          [56.016869, 38.079892],
          [56.01013, 38.078342],
          [56.001636, 38.079035],
          [55.998004, 38.07713],
          [55.99335, 38.078591],
          [55.985885, 38.076581],
          [55.985971, 38.077772],
          [55.983712, 38.079209],
          [55.979345, 38.078865],
          [55.976618, 38.079056],
          [55.971155, 38.083958],
          [55.9625, 38.078333],
          [55.956944, 38.080833],
          [55.951944, 38.083889],
          [55.946389, 38.086389],
          [55.940556, 38.088889],
          [55.934722, 38.091667],
          [55.928274, 38.092622],
          [55.927222, 38.092778],
          [55.919167, 38.092778],
          [55.911389, 38.092778],
          [55.906111, 38.091944],
          [55.898889, 38.091111],
          [55.891944, 38.090278],
          [55.883333, 38.091111],
          [55.875556, 38.092222],
          [55.868056, 38.093611],
          [55.861111, 38.095556],
          [55.854444, 38.0975],
          [55.848611, 38.1],
          [55.841944, 38.101944],
          [55.837222, 38.105],
          [55.8325, 38.108056],
          [55.8275, 38.111389],
          [55.821944, 38.113889],
          [55.816944, 38.116944],
          [55.812222, 38.12],
          [55.806389, 38.1225],
          [55.798611, 38.1225],
          [55.792222, 38.121111],
          [55.785278, 38.120278],
          [55.777222, 38.120278],
          [55.770556, 38.122222],
          [55.763889, 38.124167],
          [55.756111, 38.125278],
          [55.748333, 38.125278],
          [55.742778, 38.123056],
          [55.736389, 38.121389],
          [55.730278, 38.12],
          [55.723889, 38.118333],
          [55.716944, 38.1175],
          [55.709722, 38.116667],
          [55.703333, 38.115278],
          [55.698056, 38.113056],
          [55.691667, 38.111389],
          [55.684444, 38.110556],
          [55.682788, 38.110357],
          [55.6775, 38.109722],
          [55.670278, 38.108889],
          [55.6625, 38.108889],
          [55.654722, 38.108889],
          [55.646944, 38.108889],
          [55.638889, 38.108889],
          [55.631111, 38.108611],
          [55.624167, 38.107778],
          [55.616111, 38.107778],
          [55.61, 38.106389],
          [55.603611, 38.104722],
          [55.597222, 38.103333],
          [55.592778, 38.101389],
          [55.586389, 38.1],
          [55.579444, 38.099167],
          [55.571667, 38.099167],
          [55.564444, 38.098333],
          [55.556667, 38.098333],
          [55.549444, 38.0975],
          [55.5425, 38.096667],
          [55.536111, 38.095],
          [55.53, 38.093611],
          [55.523611, 38.091944],
          [55.516389, 38.091111],
          [55.507778, 38.091944],
          [55.5, 38.091667],
          [55.492222, 38.091667],
          [55.484444, 38.091667],
          [55.477222, 38.090833],
          [55.469444, 38.090833],
          [55.463056, 38.089167],
          [55.456111, 38.088333],
          [55.448889, 38.0875],
          [55.442778, 38.086111],
          [55.438056, 38.083056],
          [55.4325, 38.080556],
          [55.427778, 38.0775],
          [55.423056, 38.074444],
          [55.418889, 38.070833],
          [55.414167, 38.067778],
          [55.410278, 38.063889],
          [55.406389, 38.06],
          [55.402222, 38.056389],
          [55.398333, 38.0525],
          [55.394444, 38.048611],
          [55.389444, 38.045],
          [55.384722, 38.041944],
          [55.379444, 38.039444],
          [55.373889, 38.037222],
          [55.36756, 38.036174],
          [55.361944, 38.033333],
          [55.356389, 38.031111],
          [55.350833, 38.028889],
          [55.344722, 38.027222],
          [55.339167, 38.025],
          [55.332778, 38.023333],
          [55.3275, 38.021111],
          [55.321944, 38.018611],
          [55.316389, 38.016389],
          [55.313926, 38.00887],
          [55.303136, 38.00543],
          [55.295766, 38.002856],
          [55.289167, 38.000833],
          [55.283523, 37.997925],
          [55.273591, 37.990381],
          [55.273378, 37.990769],
          [55.272927, 37.991575],
          [55.272693, 37.992238],
          [55.272346, 37.992635],
          [55.271844, 37.992591],
          [55.271478, 37.992354],
          [55.271123, 37.991751],
          [55.270438, 37.991187],
          [55.270006, 37.991068],
          [55.269421, 37.991355],
          [55.268885, 37.991732],
          [55.26868, 37.992056],
          [55.268712, 37.992594],
          [55.268616, 37.992874],
          [55.268365, 37.993113],
          [55.267945, 37.993211],
          [55.267662, 37.993086],
          [55.267324, 37.992621],
          [55.266934, 37.991979],
          [55.266774, 37.991262],
          [55.266535, 37.990981],
          [55.266179, 37.990974],
          [55.265538, 37.991186],
          [55.264974, 37.991343],
          [55.26466, 37.991117],
          [55.264488, 37.990632],
          [55.264488, 37.990222],
          [55.264237, 37.989867],
          [55.26337, 37.989888],
          [55.262588, 37.990328],
          [55.261676, 37.990607],
          [55.261207, 37.9898],
          [55.261068, 37.988926],
          [55.261068, 37.988183],
          [55.261761, 37.987266],
          [55.261761, 37.986741],
          [55.261318, 37.98626],
          [55.260625, 37.986304],
          [55.259931, 37.987069],
          [55.259127, 37.987397],
          [55.258961, 37.987899],
          [55.258767, 37.988817],
          [55.258295, 37.988948],
          [55.257685, 37.988533],
          [55.257214, 37.987943],
          [55.257297, 37.987266],
          [55.257048, 37.986391],
          [55.256521, 37.986042],
          [55.255772, 37.985911],
          [55.25494, 37.985998],
          [55.253748, 37.986566],
          [55.252695, 37.98685],
          [55.25189, 37.986807],
          [55.250948, 37.986588],
          [55.250282, 37.986238],
          [55.250449, 37.98567],
          [55.251142, 37.985299],
          [55.251613, 37.984883],
          [55.251558, 37.984315],
          [55.251031, 37.983834],
          [55.250865, 37.983113],
          [55.251059, 37.982523],
          [55.25067, 37.982305],
          [55.249645, 37.982305],
          [55.248813, 37.982108],
          [55.247232, 37.981671],
          [55.246262, 37.980972],
          [55.245541, 37.980534],
          [55.244903, 37.98084],
          [55.244654, 37.98178],
          [55.243877, 37.982414],
          [55.243295, 37.98248],
          [55.242657, 37.982108],
          [55.242075, 37.981496],
          [55.241714, 37.980556],
          [55.241299, 37.980272],
          [55.240605, 37.980272],
          [55.239968, 37.980032],
          [55.239441, 37.979748],
          [55.238554, 37.979551],
          [55.23847, 37.979114],
          [55.238942, 37.978677],
          [55.23933, 37.978174],
          [55.239385, 37.977606],
          [55.238803, 37.977147],
          [55.237999, 37.976819],
          [55.236945, 37.976731],
          [55.236058, 37.977125],
          [55.235587, 37.977628],
          [55.234533, 37.977977],
          [55.233535, 37.978852],
          [55.233119, 37.97931],
          [55.232564, 37.979354],
          [55.231649, 37.979179],
          [55.23079, 37.978873],
          [55.230374, 37.978436],
          [55.230097, 37.978021],
          [55.22957, 37.978108],
          [55.229237, 37.978655],
          [55.229154, 37.979223],
          [55.228766, 37.979616],
          [55.227906, 37.979616],
          [55.22713, 37.979289],
          [55.226686, 37.97883],
          [55.22627, 37.977955],
          [55.225688, 37.977409],
          [55.224718, 37.977278],
          [55.223719, 37.976884],
          [55.223248, 37.976469],
          [55.223414, 37.975748],
          [55.223331, 37.974939],
          [55.222527, 37.974568],
          [55.221723, 37.974611],
          [55.220891, 37.974371],
          [55.220919, 37.973781],
          [55.221612, 37.973191],
          [55.22164, 37.972469],
          [55.221307, 37.972032],
          [55.220364, 37.97142],
          [55.21956, 37.971245],
          [55.219089, 37.971442],
          [55.218534, 37.971595],
          [55.218202, 37.971508],
          [55.218229, 37.97107],
          [55.218618, 37.970546],
          [55.219117, 37.96989],
          [55.219616, 37.969562],
          [55.219228, 37.969147],
          [55.218479, 37.968688],
          [55.21773, 37.96871],
          [55.216649, 37.968819],
          [55.216039, 37.968688],
          [55.215346, 37.968163],
          [55.215429, 37.967551],
          [55.2159, 37.967136],
          [55.215595, 37.966633],
          [55.214902, 37.966436],
          [55.213793, 37.966152],
          [55.212739, 37.965999],
          [55.211797, 37.966283],
          [55.21066, 37.966502],
          [55.209606, 37.966415],
          [55.208802, 37.966065],
          [55.208497, 37.965475],
          [55.208691, 37.965037],
          [55.208469, 37.964644],
          [55.208026, 37.964097],
          [55.207748, 37.963376],
          [55.207527, 37.962895],
          [55.206667, 37.962764],
          [55.206085, 37.962545],
          [55.205752, 37.961999],
          [55.206196, 37.96154],
          [55.207, 37.961321],
          [55.207443, 37.960884],
          [55.207305, 37.960556],
          [55.206833, 37.960206],
          [55.205835, 37.959528],
          [55.205669, 37.959026],
          [55.205946, 37.958217],
          [55.206667, 37.957845],
          [55.207416, 37.957714],
          [55.207554, 37.957364],
          [55.207332, 37.956949],
          [55.206556, 37.956752],
          [55.205502, 37.956708],
          [55.204504, 37.956665],
          [55.203894, 37.95638],
          [55.203867, 37.956031],
          [55.204144, 37.955593],
          [55.204532, 37.955112],
          [55.205114, 37.954588],
          [55.205669, 37.954413],
          [55.206223, 37.954128],
          [55.206112, 37.953844],
          [55.205447, 37.953669],
          [55.204587, 37.953582],
          [55.203756, 37.953538],
          [55.203451, 37.953035],
          [55.203645, 37.952664],
          [55.203783, 37.952161],
          [55.203977, 37.95157],
          [55.204393, 37.951002],
          [55.205142, 37.950696],
          [55.205752, 37.95028],
          [55.205586, 37.949865],
          [55.205087, 37.949471],
          [55.204477, 37.949209],
          [55.204117, 37.949202],
          [55.203312, 37.949187],
          [55.202591, 37.949187],
          [55.202037, 37.948947],
          [55.201704, 37.948641],
          [55.202009, 37.94816],
          [55.202064, 37.947591],
          [55.20151, 37.947307],
          [55.200761, 37.947416],
          [55.200012, 37.947657],
          [55.19943, 37.948269],
          [55.198959, 37.948881],
          [55.199125, 37.949362],
          [55.199319, 37.949712],
          [55.199236, 37.950062],
          [55.198682, 37.950127],
          [55.197933, 37.949996],
          [55.197351, 37.949471],
          [55.196879, 37.94899],
          [55.196741, 37.948247],
          [55.196796, 37.947635],
          [55.196463, 37.947197],
          [55.195798, 37.947307],
          [55.195243, 37.94781],
          [55.194772, 37.948072],
          [55.194467, 37.947897],
          [55.194023, 37.947679],
          [55.19333, 37.947504],
          [55.19272, 37.947569],
          [55.192387, 37.948028],
          [55.191888, 37.949012],
          [55.191306, 37.950324],
          [55.190946, 37.951549],
          [55.190585, 37.952051],
          [55.190308, 37.952445],
          [55.190336, 37.952817],
          [55.190585, 37.953166],
          [55.190502, 37.953538],
          [55.190114, 37.953757],
          [55.189615, 37.953582],
          [55.188753, 37.953193],
          [55.187209, 37.95339],
          [55.186444, 37.953643],
          [55.185717, 37.95398],
          [55.184989, 37.95434],
          [55.184352, 37.954511],
          [55.183757, 37.954397],
          [55.183168, 37.95416],
          [55.182685, 37.954202],
          [55.182366, 37.954607],
          [55.182156, 37.955181],
          [55.181532, 37.955821],
          [55.181141, 37.955829],
          [55.180637, 37.955617],
          [55.180124, 37.955134],
          [55.179691, 37.954993],
          [55.179233, 37.955416],
          [55.179136, 37.956011],
          [55.179287, 37.956394],
          [55.179426, 37.957058],
          [55.17935, 37.95742],
          [55.178954, 37.957627],
          [55.178255, 37.957619],
          [55.175702, 37.956962],
          [55.17514, 37.956981],
          [55.17471, 37.957253],
          [55.174179, 37.958108],
          [55.173851, 37.958189],
          [55.173411, 37.958027],
          [55.173024, 37.957704],
          [55.172723, 37.957304],
          [55.172261, 37.957296],
          [55.171767, 37.95761],
          [55.171356, 37.958152],
          [55.171069, 37.958708],
          [55.170671, 37.95921],
          [55.17037, 37.959643],
          [55.170187, 37.960177],
          [55.169909, 37.960536],
          [55.169586, 37.960613],
          [55.169006, 37.960324],
          [55.168631, 37.959883],
          [55.168351, 37.959371],
          [55.168319, 37.958597],
          [55.168362, 37.958036],
          [55.16819, 37.957542],
          [55.167687, 37.957256],
          [55.166611, 37.957117],
          [55.165848, 37.957168],
          [55.164989, 37.957925],
          [55.164376, 37.958265],
          [55.163952, 37.958197],
          [55.163904, 37.957942],
          [55.164011, 37.957321],
          [55.164365, 37.956751],
          [55.164887, 37.956285],
          [55.164935, 37.956011],
          [55.164355, 37.955705],
          [55.163538, 37.955476],
          [55.162633, 37.955146],
          [55.162174, 37.955186],
          [55.161873, 37.955518],
          [55.161625, 37.95615],
          [55.161304, 37.956343],
          [55.16081, 37.956437],
          [55.160166, 37.956336],
          [55.15966, 37.956224],
          [55.159488, 37.956045],
          [55.159617, 37.955833],
          [55.159779, 37.955586],
          [55.159779, 37.955322],
          [55.159703, 37.95505],
          [55.159394, 37.954526],
          [55.158908, 37.954327],
          [55.158457, 37.954208],
          [55.158128, 37.954028],
          [55.15806, 37.953808],
          [55.158275, 37.953604],
          [55.158543, 37.953324],
          [55.158436, 37.953068],
          [55.158049, 37.952983],
          [55.157619, 37.95306],
          [55.157222, 37.952898],
          [55.157071, 37.952635],
          [55.157033, 37.952065],
          [55.157093, 37.951775],
          [55.157319, 37.951554],
          [55.157684, 37.951376],
          [55.157813, 37.951086],
          [55.157506, 37.950779],
          [55.156975, 37.950389],
          [55.156599, 37.949981],
          [55.156154, 37.949654],
          [55.155578, 37.949683],
          [55.154998, 37.949955],
          [55.154783, 37.950304],
          [55.154461, 37.950721],
          [55.154051, 37.951027],
          [55.153774, 37.951137],
          [55.153355, 37.95095],
          [55.152957, 37.950516],
          [55.152259, 37.950465],
          [55.15169, 37.950559],
          [55.151154, 37.950736],
          [55.150723, 37.951052],
          [55.15039, 37.951359],
          [55.149681, 37.951427],
          [55.149273, 37.951384],
          [55.148735, 37.950772],
          [55.148413, 37.950576],
          [55.148139, 37.950601],
          [55.147736, 37.950933],
          [55.147414, 37.951469],
          [55.147356, 37.952231],
          [55.14706, 37.952447],
          [55.146584, 37.952632],
          [55.146179, 37.953034],
          [55.145771, 37.953179],
          [55.145384, 37.952864],
          [55.145201, 37.952158],
          [55.145352, 37.951486],
          [55.145835, 37.951086],
          [55.146285, 37.950555],
          [55.146716, 37.950047],
          [55.146724, 37.94961],
          [55.146373, 37.949332],
          [55.14582, 37.949106],
          [55.145152, 37.948908],
          [55.144476, 37.948898],
          [55.144015, 37.948783],
          [55.143497, 37.948485],
          [55.143204, 37.948617],
          [55.143004, 37.948789],
          [55.142644, 37.949431],
          [55.142337, 37.949977],
          [55.142159, 37.950113],
          [55.14195, 37.950153],
          [55.141783, 37.950127],
          [55.141565, 37.949935],
          [55.141239, 37.949352],
          [55.14098, 37.948948],
          [55.14083, 37.948637],
          [55.140805, 37.948485],
          [55.140746, 37.948226],
          [55.140738, 37.948014],
          [55.140566, 37.947801],
          [55.140219, 37.94769],
          [55.139659, 37.94773],
          [55.139266, 37.948048],
          [55.138815, 37.948299],
          [55.138029, 37.948604],
          [55.137351, 37.948816],
          [55.137104, 37.948664],
          [55.137034, 37.948326],
          [55.137335, 37.948014],
          [55.137368, 37.947591],
          [55.137025, 37.947227],
          [55.136626, 37.946989],
          [55.136014, 37.946869],
          [55.135495, 37.946849],
          [55.135167, 37.94733],
          [55.134619, 37.947648],
          [55.134017, 37.94805],
          [55.133482, 37.948649],
          [55.133153, 37.94916],
          [55.132906, 37.949623],
          [55.132712, 37.94983],
          [55.132351, 37.949814],
          [55.131997, 37.949539],
          [55.131736, 37.949126],
          [55.131423, 37.948855],
          [55.13102, 37.948617],
          [55.130605, 37.948511],
          [55.130124, 37.948575],
          [55.129401, 37.948855],
          [55.128659, 37.949131],
          [55.128224, 37.949258],
          [55.127883, 37.949284],
          [55.127656, 37.9492],
          [55.127562, 37.949004],
          [55.127615, 37.948675],
          [55.127883, 37.948257],
          [55.127944, 37.947927],
          [55.127936, 37.94769],
          [55.12787, 37.947457],
          [55.127642, 37.947319],
          [55.127415, 37.947266],
          [55.12692, 37.947049],
          [55.126813, 37.946636],
          [55.12688, 37.946143],
          [55.126886, 37.945608],
          [55.126786, 37.945301],
          [55.126452, 37.945142],
          [55.126055, 37.944995],
          [55.125555, 37.944665],
          [55.124894, 37.944435],
          [55.124043, 37.944559],
          [55.123181, 37.944628],
          [55.122619, 37.944655],
          [55.122305, 37.944611],
          [55.122204, 37.944395],
          [55.122385, 37.944083],
          [55.123033, 37.943532],
          [55.123642, 37.942901],
          [55.123856, 37.942615],
          [55.123816, 37.942403],
          [55.123663, 37.942198],
          [55.12312, 37.942006],
          [55.122478, 37.941974],
          [55.121554, 37.941982],
          [55.121388, 37.941757],
          [55.121288, 37.941476],
          [55.121375, 37.94083],
          [55.121776, 37.940231],
          [55.121963, 37.939675],
          [55.121797, 37.939422],
          [55.121267, 37.939214],
          [55.120518, 37.939214],
          [55.119715, 37.939355],
          [55.118558, 37.939585],
          [55.118184, 37.939559],
          [55.118017, 37.939336],
          [55.11799, 37.938812],
          [55.11803, 37.938229],
          [55.117836, 37.937837],
          [55.117439, 37.937693],
          [55.1169, 37.937636],
          [55.116478, 37.93772],
          [55.11609, 37.938192],
          [55.115669, 37.93869],
          [55.115294, 37.939077],
          [55.114933, 37.939114],
          [55.114605, 37.938865],
          [55.114358, 37.938471],
          [55.114124, 37.938049],
          [55.113716, 37.93754],
          [55.11296, 37.936862],
          [55.112371, 37.936485],
          [55.111695, 37.936412],
          [55.11096, 37.936491],
          [55.11049, 37.936421],
          [55.110358, 37.93629],
          [55.110297, 37.936046],
          [55.110057, 37.935527],
          [55.109642, 37.935114],
          [55.109314, 37.934716],
          [55.109307, 37.934388],
          [55.109415, 37.934075],
          [55.109334, 37.933837],
          [55.108471, 37.93371],
          [55.107696, 37.933588],
          [55.10714, 37.933509],
          [55.106826, 37.933312],
          [55.106752, 37.933037],
          [55.106906, 37.932528],
          [55.106986, 37.93211],
          [55.106918, 37.931806],
          [55.106672, 37.931601],
          [55.106237, 37.931548],
          [55.105642, 37.931644],
          [55.104913, 37.931829],
          [55.104197, 37.931887],
          [55.103615, 37.931877],
          [55.103394, 37.93178],
          [55.103368, 37.931522],
          [55.103434, 37.931283],
          [55.103662, 37.930928],
          [55.10401, 37.930446],
          [55.10415, 37.930049],
          [55.103938, 37.929884],
          [55.103354, 37.929837],
          [55.102752, 37.929922],
          [55.101996, 37.929847],
          [55.101394, 37.929726],
          [55.101269, 37.929623],
          [55.101341, 37.929424],
          [55.101521, 37.929233],
          [55.101883, 37.92892],
          [55.10207, 37.928645],
          [55.102424, 37.928322],
          [55.102866, 37.928231],
          [55.103247, 37.928216],
          [55.103364, 37.928143],
          [55.103414, 37.92803],
          [55.103428, 37.927834],
          [55.103394, 37.927654],
          [55.103039, 37.927167],
          [55.102563, 37.926943],
          [55.10205, 37.92679],
          [55.101501, 37.926729],
          [55.101013, 37.926716],
          [55.100145, 37.926763],
          [55.099502, 37.926878],
          [55.099087, 37.926899],
          [55.09874, 37.926685],
          [55.098496, 37.926146],
          [55.098265, 37.925532],
          [55.097946, 37.924878],
          [55.097636, 37.924528],
          [55.097113, 37.924511],
          [55.096283, 37.924702],
          [55.095525, 37.924793],
          [55.095294, 37.924643],
          [55.095204, 37.924416],
          [55.095264, 37.923989],
          [55.095328, 37.923589],
          [55.095165, 37.923063],
          [55.095033, 37.922761],
          [55.094934, 37.922273],
          [55.0949, 37.921751],
          [55.094806, 37.92151],
          [55.094523, 37.921378],
          [55.094138, 37.921357],
          [55.093376, 37.921507],
          [55.092417, 37.921656],
          [55.091244, 37.921754],
          [55.090932, 37.921751],
          [55.090741, 37.921659],
          [55.090705, 37.921507],
          [55.090863, 37.921235],
          [55.091124, 37.92071],
          [55.091483, 37.920264],
          [55.092002, 37.920167],
          [55.092567, 37.92015],
          [55.093145, 37.920082],
          [55.09347, 37.919845],
          [55.093543, 37.919363],
          [55.093476, 37.918767],
          [55.09317, 37.91839],
          [55.092701, 37.918097],
          [55.09189, 37.917813],
          [55.091214, 37.917688],
          [55.090218, 37.917519],
          [55.089653, 37.91708],
          [55.08935, 37.916625],
          [55.08889, 37.916178],
          [55.08867, 37.915805],
          [55.088692, 37.915234],
          [55.089037, 37.915167],
          [55.089549, 37.915366],
          [55.089873, 37.915325],
          [55.089998, 37.915118],
          [55.089831, 37.914695],
          [55.089319, 37.914331],
          [55.088023, 37.914381],
          [55.086584, 37.914711],
          [55.085609, 37.915383],
          [55.085159, 37.915971],
          [55.084532, 37.916244],
          [55.084118, 37.916231],
          [55.083884, 37.91607],
          [55.083853, 37.915639],
          [55.083058, 37.914745],
          [55.082251, 37.913894],
          [55.081752, 37.913834],
          [55.081303, 37.914091],
          [55.081135, 37.914629],
          [55.080675, 37.914944],
          [55.080069, 37.914853],
          [55.079561, 37.914416],
          [55.079317, 37.914008],
          [55.079432, 37.913553],
          [55.079724, 37.913164],
          [55.079986, 37.912451],
          [55.07986, 37.911864],
          [55.079683, 37.911093],
          [55.07966, 37.910482],
          [55.079881, 37.910406],
          [55.080247, 37.910605],
          [55.080675, 37.910597],
          [55.080749, 37.91034],
          [55.080585, 37.909897],
          [55.080487, 37.909272],
          [55.080759, 37.908377],
          [55.080926, 37.907375],
          [55.081024, 37.906975],
          [55.080864, 37.906638],
          [55.080529, 37.90658],
          [55.080111, 37.906373],
          [55.079524, 37.906004],
          [55.078752, 37.905802],
          [55.077979, 37.905835],
          [55.077645, 37.905653],
          [55.07737, 37.905234],
          [55.077174, 37.904642],
          [55.077392, 37.903505],
          [55.077289, 37.903119],
          [55.077038, 37.902978],
          [55.07668, 37.903077],
          [55.076349, 37.903152],
          [55.076162, 37.90303],
          [55.076066, 37.902845],
          [55.075981, 37.902235],
          [55.075707, 37.901763],
          [55.075263, 37.901416],
          [55.07485, 37.901423],
          [55.074689, 37.901605],
          [55.074577, 37.902246],
          [55.074633, 37.902971],
          [55.07409, 37.903187],
          [55.073346, 37.903348],
          [55.072763, 37.903302],
          [55.072351, 37.903081],
          [55.072377, 37.902928],
          [55.07249, 37.902788],
          [55.073023, 37.902793],
          [55.073207, 37.902716],
          [55.073367, 37.902428],
          [55.073191, 37.902008],
          [55.0728, 37.901656],
          [55.072388, 37.901788],
          [55.072024, 37.90183],
          [55.071458, 37.901291],
          [55.070831, 37.900947],
          [55.070164, 37.900808],
          [55.069426, 37.900357],
          [55.068949, 37.9002],
          [55.068532, 37.900284],
          [55.068281, 37.90041],
          [55.067855, 37.900457],
          [55.066952, 37.900324],
          [55.065948, 37.900079],
          [55.065363, 37.899973],
          [55.064753, 37.900026],
          [55.063816, 37.900086],
          [55.062729, 37.89994],
          [55.062026, 37.899717],
          [55.061709, 37.899489],
          [55.061935, 37.899145],
          [55.062321, 37.89898],
          [55.062645, 37.898608],
          [55.06293, 37.898297],
          [55.063097, 37.897945],
          [55.06307, 37.897641],
          [55.062294, 37.897601],
          [55.061567, 37.897495],
          [55.061048, 37.897336],
          [55.060626, 37.896869],
          [55.060062, 37.896368],
          [55.059987, 37.896044],
          [55.059744, 37.895706],
          [55.05951, 37.895375],
          [55.05941, 37.89505],
          [55.059181, 37.894818],
          [55.058339, 37.894785],
          [55.057921, 37.894897],
          [55.057599, 37.894884],
          [55.057286, 37.894679],
          [55.056951, 37.894142],
          [55.056764, 37.893614],
          [55.057122, 37.892559],
          [55.057093, 37.892075],
          [55.056877, 37.891824],
          [55.056583, 37.891582],
          [55.056594, 37.891383],
          [55.056829, 37.890802],
          [55.05699, 37.890204],
          [55.057466, 37.889729],
          [55.057471, 37.889352],
          [55.057038, 37.889068],
          [55.056567, 37.888983],
          [55.055577, 37.888864],
          [55.054582, 37.888932],
          [55.054357, 37.888919],
          [55.054213, 37.888798],
          [55.054255, 37.88861],
          [55.054384, 37.888334],
          [55.054277, 37.88799],
          [55.053934, 37.88774],
          [55.053715, 37.887414],
          [55.053667, 37.886977],
          [55.053774, 37.886896],
          [55.053939, 37.886837],
          [55.054368, 37.887074],
          [55.054736, 37.887253],
          [55.05547, 37.886968],
          [55.056572, 37.886421],
          [55.056701, 37.886311],
          [55.056715, 37.88613],
          [55.05631, 37.88584],
          [55.055577, 37.885569],
          [55.054848, 37.885326],
          [55.054202, 37.885081],
          [55.05346, 37.884293],
          [55.053134, 37.883864],
          [55.0528, 37.8838],
          [55.052356, 37.884033],
          [55.05212, 37.884466],
          [55.051852, 37.884555],
          [55.051489, 37.884411],
          [55.051162, 37.88422],
          [55.051087, 37.883876],
          [55.051253, 37.883554],
          [55.051617, 37.883414],
          [55.051895, 37.883219],
          [55.051909, 37.882879],
          [55.051355, 37.882587],
          [55.050648, 37.882375],
          [55.050054, 37.881955],
          [55.049423, 37.881751],
          [55.048957, 37.881705],
          [55.048371, 37.88167],
          [55.04715, 37.881087],
          [55.046155, 37.880815],
          [55.044956, 37.880632],
          [55.044474, 37.880723],
          [55.044282, 37.880994],
          [55.043906, 37.881312],
          [55.043516, 37.881483],
          [55.043387, 37.881379],
          [55.043279, 37.881021],
          [55.042911, 37.88063],
          [55.042192, 37.880385],
          [55.041481, 37.880027],
          [55.04103, 37.879616],
          [55.040612, 37.878973],
          [55.039867, 37.878145],
          [55.039199, 37.877644],
          [55.038747, 37.87721],
          [55.038496, 37.876965],
          [55.038387, 37.876627],
          [55.038588, 37.876229],
          [55.038688, 37.875971],
          [55.038596, 37.875679],
          [55.038237, 37.875447],
          [55.038003, 37.875089],
          [55.037861, 37.874725],
          [55.037677, 37.873896],
          [55.037783, 37.873565],
          [55.037969, 37.873485],
          [55.03878, 37.873605],
          [55.039675, 37.873651],
          [55.040461, 37.873605],
          [55.040634, 37.873464],
          [55.040494, 37.873147],
          [55.040068, 37.872842],
          [55.039173, 37.87273],
          [55.038538, 37.872292],
          [55.037886, 37.872126],
          [55.0372, 37.871828],
          [55.036891, 37.871669],
          [55.036586, 37.87159],
          [55.036213, 37.871888],
          [55.036046, 37.872392],
          [55.035954, 37.872842],
          [55.035712, 37.873088],
          [55.035367, 37.873106],
          [55.035118, 37.873008],
          [55.034892, 37.872643],
          [55.034466, 37.87202],
          [55.033993, 37.871679],
          [55.033512, 37.871297],
          [55.033008, 37.870962],
          [55.032596, 37.870827],
          [55.032254, 37.870636],
          [55.032113, 37.870409],
          [55.032227, 37.870186],
          [55.032431, 37.870021],
          [55.032789, 37.869999],
          [55.033164, 37.870144],
          [55.033544, 37.870436],
          [55.033902, 37.870555],
          [55.034143, 37.870551],
          [55.03456, 37.870415],
          [55.034897, 37.870216],
          [55.035293, 37.870114],
          [55.035574, 37.869981],
          [55.035727, 37.869698],
          [55.035775, 37.869261],
          [55.035582, 37.868824],
          [55.035433, 37.868549],
          [55.035534, 37.868175],
          [55.035859, 37.867686],
          [55.036273, 37.867289],
          [55.036605, 37.867242],
          [55.037049, 37.867314],
          [55.037423, 37.867187],
          [55.037611, 37.866813],
          [55.037611, 37.866427],
          [55.037541, 37.866033],
          [55.037669, 37.865855],
          [55.038051, 37.865656],
          [55.038584, 37.865409],
          [55.039221, 37.864858],
          [55.039532, 37.864238],
          [55.039635, 37.863527],
          [55.039371, 37.862618],
          [55.039409, 37.862075],
          [55.039414, 37.861243],
          [55.039255, 37.860677],
          [55.038997, 37.860378],
          [55.038547, 37.860272],
          [55.037974, 37.860293],
          [55.037471, 37.860407],
          [55.037161, 37.860433],
          [55.036868, 37.860329],
          [55.036123, 37.859868],
          [55.035807, 37.859788],
          [55.035625, 37.859678],
          [55.035577, 37.859559],
          [55.035663, 37.859376],
          [55.035813, 37.858986],
          [55.035764, 37.858553],
          [55.035128, 37.858399],
          [55.033763, 37.858379],
          [55.033383, 37.858507],
          [55.032677, 37.858736],
          [55.031841, 37.858704],
          [55.031029, 37.85854],
          [55.030611, 37.858311],
          [55.030574, 37.858014],
          [55.030611, 37.857671],
          [55.030504, 37.857233],
          [55.030472, 37.857017],
          [55.030654, 37.856809],
          [55.03121, 37.856436],
          [55.032425, 37.855916],
          [55.032805, 37.85577],
          [55.033067, 37.855566],
          [55.033057, 37.855252],
          [55.032709, 37.854938],
          [55.032163, 37.854595],
          [55.031002, 37.854081],
          [55.029819, 37.853623],
          [55.02922, 37.853415],
          [55.028845, 37.853084],
          [55.028532, 37.852897],
          [55.028219, 37.852927],
          [55.027481, 37.853258],
          [55.027145, 37.85329],
          [55.027063, 37.853126],
          [55.027085, 37.852846],
          [55.027224, 37.852524],
          [55.02747, 37.852151],
          [55.027545, 37.851828],
          [55.027459, 37.851556],
          [55.027224, 37.851416],
          [55.026889, 37.851303],
          [55.026384, 37.851242],
          [55.025816, 37.851349],
          [55.025019, 37.851298],
          [55.023718, 37.851296],
          [55.022777, 37.851196],
          [55.022178, 37.851035],
          [55.021664, 37.850767],
          [55.021525, 37.85049],
          [55.021696, 37.850313],
          [55.02206, 37.850326],
          [55.022397, 37.850441],
          [55.022686, 37.850394],
          [55.022788, 37.85022],
          [55.022659, 37.849927],
          [55.022252, 37.849588],
          [55.022081, 37.849146],
          [55.021792, 37.849015],
          [55.021284, 37.849087],
          [55.020711, 37.849023],
          [55.020101, 37.848879],
          [55.019737, 37.848582],
          [55.019802, 37.848298],
          [55.020155, 37.848043],
          [55.020915, 37.847721],
          [55.021225, 37.847491],
          [55.021214, 37.847292],
          [55.020405, 37.846491],
          [55.018945, 37.845382],
          [55.018528, 37.844958],
          [55.018485, 37.844742],
          [55.018828, 37.844589],
          [55.019288, 37.844652],
          [55.019685, 37.84459],
          [55.020133, 37.844347],
          [55.020759, 37.844041],
          [55.021439, 37.84394],
          [55.022076, 37.84377],
          [55.023101, 37.843395],
          [55.023291, 37.843214],
          [55.023323, 37.842963],
          [55.022948, 37.842904],
          [55.022383, 37.842899],
          [55.021792, 37.842985],
          [55.021305, 37.842866],
          [55.021048, 37.843044],
          [55.020693, 37.84334],
          [55.020422, 37.843524],
          [55.020149, 37.843405],
          [55.019946, 37.843116],
          [55.019866, 37.842611],
          [55.019766, 37.842243],
          [55.01939, 37.842144],
          [55.018769, 37.842174],
          [55.017736, 37.842437],
          [55.017024, 37.842645],
          [55.016546, 37.842764],
          [55.016034, 37.842798],
          [55.015301, 37.842785],
          [55.014729, 37.842993],
          [55.014135, 37.843324],
          [55.013664, 37.843405],
          [55.012963, 37.843388],
          [55.01241, 37.843343],
          [55.012086, 37.842914],
          [55.011828, 37.842582],
          [55.011101, 37.842281],
          [55.010646, 37.842212],
          [55.010094, 37.842217],
          [55.009337, 37.842357],
          [55.00904, 37.842378],
          [55.008757, 37.842276],
          [55.008553, 37.842064],
          [55.008591, 37.841763],
          [55.008516, 37.841308],
          [55.008189, 37.840625],
          [55.007746, 37.840072],
          [55.007463, 37.840041],
          [55.007254, 37.840062],
          [55.006391, 37.840315],
          [55.004968, 37.840602],
          [55.004117, 37.840928],
          [55.003755, 37.840914],
          [55.003522, 37.840788],
          [55.003254, 37.840252],
          [55.00251, 37.839648],
          [55.002333, 37.839416],
          [55.002346, 37.838864],
          [55.002183, 37.838021],
          [55.001924, 37.837469],
          [55.001487, 37.837252],
          [55.000716, 37.837082],
          [54.999736, 37.837097],
          [54.998665, 37.837128],
          [54.99841, 37.837004],
          [54.99834, 37.836806],
          [54.998469, 37.836545],
          [54.998808, 37.83623],
          [54.998847, 37.835911],
          [54.998606, 37.83556],
          [54.99822, 37.835405],
          [54.997449, 37.835436],
          [54.997002, 37.835337],
          [54.996764, 37.834822],
          [54.996229, 37.834506],
          [54.995627, 37.834156],
          [54.995343, 37.83398],
          [54.995347, 37.833769],
          [54.995735, 37.833568],
          [54.995908, 37.83345],
          [54.996124, 37.833117],
          [54.995921, 37.83267],
          [54.996012, 37.832278],
          [54.996195, 37.832108],
          [54.996835, 37.831922],
          [54.997883, 37.831434],
          [54.998634, 37.831011],
          [54.99932, 37.83048],
          [54.99979, 37.829798],
          [55.000084, 37.829009],
          [54.999934, 37.82806],
          [54.999568, 37.827538],
          [54.999059, 37.827064],
          [54.998229, 37.826677],
          [54.997556, 37.826548],
          [54.996831, 37.826697],
          [54.996171, 37.827089],
          [54.996217, 37.827384],
          [54.996772, 37.827544],
          [54.997341, 37.827714],
          [54.997824, 37.828142],
          [54.998079, 37.828534],
          [54.997844, 37.829024],
          [54.997804, 37.829613],
          [54.997615, 37.829768],
          [54.997184, 37.829613],
          [54.99672, 37.829597],
          [54.996282, 37.829582],
          [54.995975, 37.829153],
          [54.995579, 37.829145],
          [54.99429, 37.829437],
          [54.993571, 37.829468],
          [54.993674, 37.829021],
          [54.99406, 37.828449],
          [54.994108, 37.828045],
          [54.993954, 37.827549],
          [54.993761, 37.827366],
          [54.993413, 37.827221],
          [54.992902, 37.827244],
          [54.992216, 37.827618],
          [54.99164, 37.827774],
          [54.988758, 37.827857],
          [54.987448, 37.827625],
          [54.986338, 37.827366],
          [54.986261, 37.827031],
          [54.986714, 37.826703],
          [54.986685, 37.825963],
          [54.986204, 37.825239],
          [54.983124, 37.822704],
          [54.980967, 37.821591],
          [54.980343, 37.821258],
          [54.980302, 37.821161],
          [54.980323, 37.821053],
          [54.980759, 37.820967],
          [54.981087, 37.820848],
          [54.981251, 37.820697],
          [54.981155, 37.820411],
          [54.981005, 37.820244],
          [54.980678, 37.820109],
          [54.980384, 37.820077],
          [54.979217, 37.820465],
          [54.979026, 37.820659],
          [54.978937, 37.821376],
          [54.978793, 37.821537],
          [54.978138, 37.821476],
          [54.976507, 37.821667],
          [54.976324, 37.821652],
          [54.97616, 37.821454],
          [54.975832, 37.820841],
          [54.974779, 37.820104],
          [54.974123, 37.819448],
          [54.973698, 37.81893],
          [54.973737, 37.818602],
          [54.974094, 37.818556],
          [54.974683, 37.818647],
          [54.975281, 37.818297],
          [54.975781, 37.818058],
          [54.975822, 37.817824],
          [54.975522, 37.817473],
          [54.974963, 37.81713],
          [54.974103, 37.816695],
          [54.972974, 37.816482],
          [54.972588, 37.816611],
          [54.972079, 37.816781],
          [54.969511, 37.816285],
          [54.966279, 37.816458],
          [54.965908, 37.816359],
          [54.965143, 37.815467],
          [54.965131, 37.815026],
          [54.961946, 37.813076],
          [54.957964, 37.811818],
          [54.955973, 37.809867],
          [54.955097, 37.806973],
          [54.956052, 37.805085],
          [54.958123, 37.805777],
          [54.959158, 37.805022],
          [54.958282, 37.80326],
          [54.955176, 37.80326],
          [54.953982, 37.800995],
          [54.954619, 37.799359],
          [54.952309, 37.797974],
          [54.950314, 37.797113],
          [54.95007, 37.79697],
          [54.949828, 37.796818],
          [54.949535, 37.796694],
          [54.949321, 37.796652],
          [54.948954, 37.796589],
          [54.948547, 37.796501],
          [54.948125, 37.796471],
          [54.947774, 37.796488],
          [54.947459, 37.796489],
          [54.947212, 37.796442],
          [54.946912, 37.796352],
          [54.946649, 37.796262],
          [54.946511, 37.796249],
          [54.946326, 37.796255],
          [54.946121, 37.7962],
          [54.945924, 37.796099],
          [54.9458, 37.795975],
          [54.945739, 37.795864],
          [54.945628, 37.795719],
          [54.945578, 37.795608],
          [54.945557, 37.795503],
          [54.945574, 37.79537],
          [54.945564, 37.795221],
          [54.945491, 37.79508],
          [54.945385, 37.794936],
          [54.945163, 37.794782],
          [54.94499, 37.794633],
          [54.944856, 37.794459],
          [54.944747, 37.794276],
          [54.944656, 37.794062],
          [54.944591, 37.793923],
          [54.94449, 37.793828],
          [54.944365, 37.793773],
          [54.944227, 37.793753],
          [54.944068, 37.793756],
          [54.943918, 37.793779],
          [54.943736, 37.793838],
          [54.943555, 37.793937],
          [54.943252, 37.794125],
          [54.943065, 37.794277],
          [54.942844, 37.794477],
          [54.942697, 37.794583],
          [54.942588, 37.794628],
          [54.942395, 37.794669],
          [54.942227, 37.794666],
          [54.941951, 37.794598],
          [54.941644, 37.794557],
          [54.941435, 37.794566],
          [54.941194, 37.79462],
          [54.940955, 37.794671],
          [54.940824, 37.794686],
          [54.940712, 37.79467],
          [54.940602, 37.794625],
          [54.940549, 37.79457],
          [54.940528, 37.794514],
          [54.940531, 37.794438],
          [54.940562, 37.79436],
          [54.94066, 37.794234],
          [54.940822, 37.794081],
          [54.941082, 37.793853],
          [54.941245, 37.793681],
          [54.941358, 37.793522],
          [54.941382, 37.793434],
          [54.941358, 37.793339],
          [54.941293, 37.793261],
          [54.941144, 37.793179],
          [54.940956, 37.793087],
          [54.940829, 37.792981],
          [54.940804, 37.792887],
          [54.940802, 37.792619],
          [54.940675, 37.792222],
          [54.940547, 37.791948],
          [54.940388, 37.791788],
          [54.940167, 37.791638],
          [54.939895, 37.791505],
          [54.939633, 37.791414],
          [54.939231, 37.791286],
          [54.938886, 37.791165],
          [54.938595, 37.791048],
          [54.938171, 37.790815],
          [54.938003, 37.790688],
          [54.93793, 37.790583],
          [54.937911, 37.790515],
          [54.937914, 37.79044],
          [54.937966, 37.790402],
          [54.938088, 37.790377],
          [54.938318, 37.790361],
          [54.938549, 37.790388],
          [54.938911, 37.790451],
          [54.939245, 37.790461],
          [54.939697, 37.79047],
          [54.940218, 37.790527],
          [54.940829, 37.790597],
          [54.941303, 37.790646],
          [54.941792, 37.790648],
          [54.942109, 37.790612],
          [54.942213, 37.790537],
          [54.942276, 37.790443],
          [54.942271, 37.790304],
          [54.942229, 37.790162],
          [54.942093, 37.789984],
          [54.941881, 37.789786],
          [54.941609, 37.789598],
          [54.941432, 37.789507],
          [54.941141, 37.789426],
          [54.940981, 37.789356],
          [54.940607, 37.789291],
          [54.94028, 37.789239],
          [54.940011, 37.78918],
          [54.939646, 37.789081],
          [54.939352, 37.788987],
          [54.939136, 37.788884],
          [54.938965, 37.78878],
          [54.938872, 37.788731],
          [54.938797, 37.788718],
          [54.93869, 37.788723],
          [54.93857, 37.788749],
          [54.938367, 37.788824],
          [54.938095, 37.788944],
          [54.937908, 37.789052],
          [54.937719, 37.789108],
          [54.937394, 37.789177],
          [54.936935, 37.789259],
          [54.93665, 37.789341],
          [54.936395, 37.789464],
          [54.936148, 37.789609],
          [54.935987, 37.789741],
          [54.935813, 37.789924],
          [54.935709, 37.79013],
          [54.935668, 37.790275],
          [54.935607, 37.790475],
          [54.935534, 37.790663],
          [54.935475, 37.790711],
          [54.93542, 37.790728],
          [54.93525, 37.790732],
          [54.935099, 37.790709],
          [54.934884, 37.790628],
          [54.934621, 37.790533],
          [54.93434, 37.79042],
          [54.934211, 37.790364],
          [54.934176, 37.790316],
          [54.934166, 37.790259],
          [54.934179, 37.790196],
          [54.934246, 37.790105],
          [54.934358, 37.790032],
          [54.934479, 37.789892],
          [54.934553, 37.78985],
          [54.93466, 37.789693],
          [54.934737, 37.789544],
          [54.934796, 37.789374],
          [54.934887, 37.789305],
          [54.935105, 37.78918],
          [54.935205, 37.789056],
          [54.93525, 37.78895],
          [54.935254, 37.788843],
          [54.935204, 37.788752],
          [54.935093, 37.788677],
          [54.934934, 37.788621],
          [54.934579, 37.788568],
          [54.934232, 37.788554],
          [54.933912, 37.788522],
          [54.93356, 37.788421],
          [54.933374, 37.788334],
          [54.933268, 37.788238],
          [54.933136, 37.788096],
          [54.932947, 37.787831],
          [54.932755, 37.787523],
          [54.932566, 37.787384],
          [54.932329, 37.787218],
          [54.932159, 37.787067],
          [54.932054, 37.786923],
          [54.931957, 37.786769],
          [54.93186, 37.786593],
          [54.931801, 37.786372],
          [54.931703, 37.786186],
          [54.931635, 37.785948],
          [54.931585, 37.785645],
          [54.931528, 37.785458],
          [54.931507, 37.785333],
          [54.931526, 37.78518],
          [54.931551, 37.785045],
          [54.931545, 37.784827],
          [54.931507, 37.784645],
          [54.931449, 37.784564],
          [54.931341, 37.784506],
          [54.931201, 37.78449],
          [54.93106, 37.784514],
          [54.930804, 37.784609],
          [54.930575, 37.784707],
          [54.930409, 37.784741],
          [54.93023, 37.784747],
          [54.929987, 37.78475],
          [54.929743, 37.784781],
          [54.92954, 37.784832],
          [54.929403, 37.784905],
          [54.929314, 37.785002],
          [54.929253, 37.785125],
          [54.929172, 37.785295],
          [54.929047, 37.785417],
          [54.92884, 37.785555],
          [54.928707, 37.785595],
          [54.928568, 37.78561],
          [54.928471, 37.785593],
          [54.928422, 37.785545],
          [54.928382, 37.785451],
          [54.92838, 37.785302],
          [54.928431, 37.785163],
          [54.928509, 37.785081],
          [54.928565, 37.784935],
          [54.928568, 37.78486],
          [54.928527, 37.784704],
          [54.92854, 37.784479],
          [54.928501, 37.784176],
          [54.928453, 37.784007],
          [54.928389, 37.783948],
          [54.928298, 37.783911],
          [54.928213, 37.783923],
          [54.928088, 37.783997],
          [54.927943, 37.78408],
          [54.927794, 37.784188],
          [54.927491, 37.784349],
          [54.927341, 37.784508],
          [54.927293, 37.784647],
          [54.927225, 37.7848],
          [54.927241, 37.784909],
          [54.927269, 37.785078],
          [54.927234, 37.785373],
          [54.92713, 37.785749],
          [54.927036, 37.785967],
          [54.92687, 37.78629],
          [54.926657, 37.786632],
          [54.926594, 37.78676],
          [54.926565, 37.786907],
          [54.926577, 37.787109],
          [54.926539, 37.78726],
          [54.926456, 37.787379],
          [54.926365, 37.787448],
          [54.926259, 37.787476],
          [54.926144, 37.787474],
          [54.926018, 37.787421],
          [54.925795, 37.787316],
          [54.925635, 37.787223],
          [54.925421, 37.787031],
          [54.925306, 37.786968],
          [54.924933, 37.786865],
          [54.92468, 37.786778],
          [54.924483, 37.786757],
          [54.924313, 37.786771],
          [54.924093, 37.786823],
          [54.923786, 37.786867],
          [54.923428, 37.78689],
          [54.923077, 37.786971],
          [54.922719, 37.787059],
          [54.922537, 37.787076],
          [54.922456, 37.787071],
          [54.922368, 37.787031],
          [54.922147, 37.786911],
          [54.921744, 37.786735],
          [54.921512, 37.78664],
          [54.921369, 37.786618],
          [54.921277, 37.786574],
          [54.921193, 37.786507],
          [54.920927, 37.786404],
          [54.920587, 37.786271],
          [54.920259, 37.786128],
          [54.919922, 37.78594],
          [54.919724, 37.78584],
          [54.919491, 37.785746],
          [54.919232, 37.78562],
          [54.918941, 37.785432],
          [54.918666, 37.785276],
          [54.918306, 37.785087],
          [54.91806, 37.784992],
          [54.917808, 37.784924],
          [54.91761, 37.784899],
          [54.917161, 37.784877],
          [54.916751, 37.784862],
          [54.916466, 37.784823],
          [54.916236, 37.784783],
          [54.915971, 37.784696],
          [54.915864, 37.784627],
          [54.915833, 37.784578],
          [54.915819, 37.784509],
          [54.915832, 37.784434],
          [54.915909, 37.784355],
          [54.916092, 37.784211],
          [54.916293, 37.784017],
          [54.916362, 37.783896],
          [54.916438, 37.783667],
          [54.916465, 37.78348],
          [54.916446, 37.783281],
          [54.916348, 37.783112],
          [54.916208, 37.782973],
          [54.916094, 37.782909],
          [54.915905, 37.782863],
          [54.915578, 37.782808],
          [54.915274, 37.782789],
          [54.914931, 37.782775],
          [54.914629, 37.78277],
          [54.914386, 37.78278],
          [54.914187, 37.782821],
          [54.913866, 37.782927],
          [54.913624, 37.782969],
          [54.913303, 37.783044],
          [54.913035, 37.783108],
          [54.912803, 37.783124],
          [54.912565, 37.783116],
          [54.91232, 37.783068],
          [54.912156, 37.783006],
          [54.912095, 37.782939],
          [54.912055, 37.782849],
          [54.912032, 37.782668],
          [54.91197, 37.782415],
          [54.911889, 37.782259],
          [54.911768, 37.782143],
          [54.911516, 37.781985],
          [54.911227, 37.781876],
          [54.911011, 37.781738],
          [54.910955, 37.781665],
          [54.910951, 37.781571],
          [54.910969, 37.781479],
          [54.911069, 37.781377],
          [54.911212, 37.781281],
          [54.911443, 37.781167],
          [54.911638, 37.781113],
          [54.911802, 37.781098],
          [54.912125, 37.781023],
          [54.912483, 37.780929],
          [54.912556, 37.780894],
          [54.912585, 37.78086],
          [54.912581, 37.780747],
          [54.912532, 37.780453],
          [54.912407, 37.780223],
          [54.912345, 37.779977],
          [54.912192, 37.779721],
          [54.91203, 37.77949],
          [54.91186, 37.779232],
          [54.911629, 37.778919],
          [54.911429, 37.778657],
          [54.911212, 37.778429],
          [54.911034, 37.778215],
          [54.910854, 37.778039],
          [54.910688, 37.777931],
          [54.910549, 37.777889],
          [54.910417, 37.777885],
          [54.910238, 37.777917],
          [54.910145, 37.777978],
          [54.909954, 37.778176],
          [54.909695, 37.778426],
          [54.9096, 37.778532],
          [54.909544, 37.778641],
          [54.909491, 37.778822],
          [54.909456, 37.779105],
          [54.909419, 37.779291],
          [54.909356, 37.779411],
          [54.909294, 37.779466],
          [54.909231, 37.779488],
          [54.909123, 37.779481],
          [54.908999, 37.779445],
          [54.908807, 37.779349],
          [54.908653, 37.779316],
          [54.908456, 37.779325],
          [54.908286, 37.779377],
          [54.908077, 37.7795],
          [54.907919, 37.779562],
          [54.907789, 37.779582],
          [54.907635, 37.779576],
          [54.907536, 37.779553],
          [54.907451, 37.779488],
          [54.907406, 37.77942],
          [54.907392, 37.779349],
          [54.907398, 37.77924],
          [54.907478, 37.778958],
          [54.907594, 37.778662],
          [54.907679, 37.778425],
          [54.907706, 37.778157],
          [54.907669, 37.778003],
          [54.907602, 37.777941],
          [54.907467, 37.77788],
          [54.907303, 37.777855],
          [54.907121, 37.777866],
          [54.906967, 37.777905],
          [54.906712, 37.778004],
          [54.906472, 37.77807],
          [54.906158, 37.778121],
          [54.906011, 37.778121],
          [54.905923, 37.778102],
          [54.905778, 37.778023],
          [54.905479, 37.777851],
          [54.905294, 37.777702],
          [54.905105, 37.77753],
          [54.904811, 37.777294],
          [54.904617, 37.777186],
          [54.904417, 37.777119],
          [54.904092, 37.776988],
          [54.903779, 37.776855],
          [54.903606, 37.776747],
          [54.903509, 37.776649],
          [54.903387, 37.776468],
          [54.903248, 37.776255],
          [54.903128, 37.776059],
          [54.902886, 37.775782],
          [54.902786, 37.775624],
          [54.902726, 37.77547],
          [54.902695, 37.775277],
          [54.902674, 37.775055],
          [54.902679, 37.774791],
          [54.902645, 37.77461],
          [54.90249, 37.774355],
          [54.902274, 37.774067],
          [54.902053, 37.773867],
          [54.901836, 37.77373],
          [54.901644, 37.773657],
          [54.901338, 37.773629],
          [54.901073, 37.773583],
          [54.900652, 37.773503],
          [54.900344, 37.773418],
          [54.900218, 37.773359],
          [54.900156, 37.773283],
          [54.900099, 37.773189],
          [54.899821, 37.772982],
          [54.899595, 37.772881],
          [54.899412, 37.772836],
          [54.899121, 37.772844],
          [54.89872, 37.772865],
          [54.898407, 37.772891],
          [54.898321, 37.772882],
          [54.898204, 37.772837],
          [54.898098, 37.772776],
          [54.897977, 37.772637],
          [54.897721, 37.772369],
          [54.89751, 37.772179],
          [54.897233, 37.772013],
          [54.896916, 37.771823],
          [54.89687, 37.771776],
          [54.896858, 37.771723],
          [54.896885, 37.771576],
          [54.896951, 37.771386],
          [54.896953, 37.771227],
          [54.896888, 37.771087],
          [54.896786, 37.771],
          [54.896629, 37.770926],
          [54.896493, 37.770834],
          [54.896299, 37.770657],
          [54.89616, 37.770537],
          [54.896026, 37.770474],
          [54.895911, 37.770439],
          [54.895779, 37.77042],
          [54.895624, 37.770414],
          [54.895479, 37.770446],
          [54.895232, 37.770554],
          [54.894771, 37.770727],
          [54.894485, 37.770877],
          [54.894242, 37.771059],
          [54.893933, 37.771229],
          [54.893491, 37.771486],
          [54.89326, 37.771595],
          [54.893172, 37.771613],
          [54.893106, 37.771604],
          [54.893051, 37.771575],
          [54.89301, 37.771526],
          [54.892992, 37.771457],
          [54.892987, 37.771233],
          [54.893012, 37.770956],
          [54.893063, 37.770754],
          [54.89314, 37.770642],
          [54.893255, 37.770464],
          [54.893435, 37.770311],
          [54.893746, 37.770098],
          [54.893859, 37.769981],
          [54.893985, 37.769806],
          [54.894019, 37.769672],
          [54.894025, 37.769506],
          [54.893984, 37.769331],
          [54.893944, 37.769156],
          [54.893866, 37.768948],
          [54.893717, 37.768784],
          [54.893635, 37.76865],
          [54.893591, 37.768527],
          [54.893588, 37.768412],
          [54.893626, 37.768324],
          [54.893751, 37.768172],
          [54.893871, 37.767966],
          [54.893971, 37.767646],
          [54.894044, 37.767338],
          [54.894035, 37.76721],
          [54.893992, 37.767146],
          [54.893929, 37.767066],
          [54.893828, 37.766903],
          [54.893789, 37.766786],
          [54.893777, 37.766519],
          [54.893749, 37.766244],
          [54.893671, 37.765919],
          [54.893565, 37.765547],
          [54.89344, 37.765269],
          [54.893291, 37.765002],
          [54.893174, 37.764873],
          [54.893088, 37.764828],
          [54.892956, 37.764812],
          [54.89286, 37.76477],
          [54.892738, 37.764717],
          [54.892649, 37.764644],
          [54.892507, 37.764622],
          [54.892426, 37.764548],
          [54.892233, 37.764319],
          [54.89197, 37.764072],
          [54.89174, 37.763904],
          [54.891468, 37.763683],
          [54.891101, 37.763504],
          [54.890924, 37.763444],
          [54.890396, 37.763375],
          [54.89018, 37.763368],
          [54.889968, 37.763378],
          [54.889763, 37.763412],
          [54.889557, 37.763508],
          [54.889212, 37.763742],
          [54.889001, 37.76388],
          [54.888837, 37.764082],
          [54.8887, 37.764237],
          [54.888654, 37.764343],
          [54.888648, 37.76444],
          [54.888672, 37.764528],
          [54.888731, 37.764591],
          [54.88882, 37.764616],
          [54.88889, 37.764619],
          [54.888972, 37.764614],
          [54.889185, 37.764675],
          [54.889386, 37.764712],
          [54.889514, 37.76471],
          [54.889822, 37.764644],
          [54.890123, 37.764551],
          [54.890243, 37.764533],
          [54.890392, 37.764552],
          [54.890704, 37.764686],
          [54.890984, 37.764815],
          [54.89117, 37.764907],
          [54.891213, 37.764975],
          [54.891211, 37.765126],
          [54.89121, 37.765304],
          [54.891326, 37.765476],
          [54.891497, 37.76563],
          [54.891764, 37.765823],
          [54.892007, 37.766053],
          [54.892185, 37.766212],
          [54.892299, 37.766313],
          [54.892305, 37.766377],
          [54.892236, 37.766539],
          [54.892119, 37.76666],
          [54.892048, 37.766773],
          [54.892035, 37.76686],
          [54.892045, 37.766931],
          [54.892108, 37.767014],
          [54.892106, 37.767133],
          [54.892105, 37.767258],
          [54.892158, 37.767452],
          [54.892171, 37.767543],
          [54.89217, 37.767649],
          [54.892206, 37.767827],
          [54.892214, 37.767999],
          [54.892169, 37.768095],
          [54.892123, 37.768132],
          [54.892044, 37.768149],
          [54.891952, 37.768139],
          [54.891807, 37.76809],
          [54.891667, 37.767994],
          [54.891401, 37.76775],
          [54.891272, 37.767693],
          [54.891151, 37.767663],
          [54.890956, 37.767664],
          [54.890689, 37.767687],
          [54.890393, 37.767716],
          [54.890083, 37.767768],
          [54.889719, 37.767841],
          [54.889486, 37.767928],
          [54.889194, 37.768035],
          [54.888907, 37.768182],
          [54.888691, 37.768365],
          [54.888565, 37.768489],
          [54.888531, 37.768561],
          [54.888475, 37.768684],
          [54.888421, 37.76886],
          [54.888405, 37.768997],
          [54.888358, 37.769103],
          [54.888261, 37.769263],
          [54.888117, 37.769407],
          [54.887842, 37.769595],
          [54.887595, 37.769768],
          [54.88722, 37.769999],
          [54.887116, 37.770049],
          [54.88703, 37.770062],
          [54.886935, 37.770056],
          [54.886874, 37.770018],
          [54.886808, 37.769941],
          [54.886777, 37.769847],
          [54.886717, 37.769649],
          [54.886666, 37.769571],
          [54.88659, 37.769539],
          [54.886492, 37.769546],
          [54.886419, 37.76958],
          [54.886306, 37.769662],
          [54.886249, 37.769756],
          [54.886186, 37.769834],
          [54.88614, 37.770001],
          [54.886113, 37.770202],
          [54.886055, 37.770385],
          [54.886017, 37.770461],
          [54.885941, 37.770489],
          [54.885795, 37.770496],
          [54.885531, 37.770439],
          [54.885335, 37.770357],
          [54.885169, 37.770249],
          [54.885073, 37.770109],
          [54.884972, 37.76992],
          [54.884925, 37.769752],
          [54.884894, 37.769557],
          [54.88474, 37.769346],
          [54.884548, 37.769136],
          [54.884449, 37.76905],
          [54.884147, 37.768888],
          [54.883962, 37.768734],
          [54.883796, 37.768549],
          [54.883588, 37.768338],
          [54.883385, 37.768118],
          [54.883263, 37.768025],
          [54.883031, 37.767896],
          [54.882906, 37.767873],
          [54.882791, 37.767894],
          [54.882673, 37.767958],
          [54.882551, 37.768084],
          [54.882496, 37.768159],
          [54.882485, 37.768283],
          [54.882437, 37.768443],
          [54.882451, 37.768572],
          [54.882412, 37.768678],
          [54.882358, 37.768747],
          [54.882274, 37.768784],
          [54.882105, 37.768808],
          [54.881966, 37.7688],
          [54.881692, 37.768735],
          [54.88157, 37.768664],
          [54.881343, 37.768518],
          [54.8812, 37.768418],
          [54.881016, 37.768343],
          [54.880908, 37.768345],
          [54.880822, 37.768385],
          [54.880767, 37.76845],
          [54.880746, 37.768506],
          [54.880738, 37.768584],
          [54.880746, 37.76865],
          [54.880799, 37.768742],
          [54.880847, 37.768899],
          [54.880991, 37.769082],
          [54.881146, 37.769256],
          [54.881337, 37.769496],
          [54.881508, 37.769761],
          [54.881587, 37.769961],
          [54.881583, 37.770062],
          [54.881538, 37.770152],
          [54.881455, 37.770231],
          [54.881364, 37.770289],
          [54.881278, 37.770315],
          [54.881131, 37.770292],
          [54.881021, 37.770227],
          [54.880813, 37.770164],
          [54.880642, 37.770067],
          [54.880458, 37.769931],
          [54.88031, 37.76974],
          [54.880165, 37.769564],
          [54.87996, 37.769386],
          [54.879632, 37.769163],
          [54.879441, 37.769039],
          [54.879245, 37.768895],
          [54.879035, 37.76877],
          [54.878895, 37.768713],
          [54.878814, 37.768718],
          [54.878738, 37.768745],
          [54.878631, 37.768817],
          [54.878572, 37.768899],
          [54.87849, 37.769045],
          [54.878428, 37.769173],
          [54.878426, 37.769322],
          [54.878352, 37.769564],
          [54.878274, 37.769801],
          [54.878225, 37.770063],
          [54.878122, 37.770232],
          [54.878055, 37.770277],
          [54.877973, 37.770295],
          [54.877761, 37.770287],
          [54.877662, 37.770252],
          [54.877321, 37.770193],
          [54.876953, 37.770161],
          [54.876649, 37.770102],
          [54.876254, 37.770006],
          [54.87615, 37.769962],
          [54.876111, 37.769932],
          [54.876101, 37.769887],
          [54.876132, 37.769712],
          [54.876214, 37.769552],
          [54.876306, 37.769454],
          [54.876381, 37.76938],
          [54.876399, 37.769341],
          [54.876414, 37.769208],
          [54.87645, 37.769087],
          [54.8765, 37.768917],
          [54.876491, 37.76882],
          [54.876469, 37.768763],
          [54.876478, 37.768663],
          [54.87649, 37.768526],
          [54.876485, 37.768151],
          [54.876448, 37.767899],
          [54.876332, 37.767606],
          [54.876239, 37.76733],
          [54.876145, 37.76714],
          [54.875934, 37.766921],
          [54.875768, 37.766795],
          [54.875664, 37.766747],
          [54.875552, 37.766729],
          [54.87542, 37.766755],
          [54.875351, 37.766797],
          [54.87531, 37.766864],
          [54.875278, 37.766967],
          [54.875281, 37.767043],
          [54.87531, 37.767174],
          [54.875337, 37.767391],
          [54.87533, 37.767653],
          [54.875279, 37.767877],
          [54.875257, 37.768088],
          [54.87521, 37.768329],
          [54.875166, 37.768378],
          [54.875, 37.768477],
          [54.874842, 37.768555],
          [54.874653, 37.768604],
          [54.874341, 37.768607],
          [54.87401, 37.768623],
          [54.873748, 37.768674],
          [54.87344, 37.768752],
          [54.873286, 37.768785],
          [54.873009, 37.768804],
          [54.872629, 37.768814],
          [54.872315, 37.768799],
          [54.872193, 37.768769],
          [54.87214, 37.768733],
          [54.8721, 37.768684],
          [54.87208, 37.768617],
          [54.872087, 37.768528],
          [54.872113, 37.768452],
          [54.872197, 37.768305],
          [54.87229, 37.768198],
          [54.872447, 37.768054],
          [54.872646, 37.767872],
          [54.872818, 37.767711],
          [54.872951, 37.767563],
          [54.873134, 37.767362],
          [54.87326, 37.767149],
          [54.873458, 37.766884],
          [54.873517, 37.766728],
          [54.873541, 37.766571],
          [54.873539, 37.766409],
          [54.873496, 37.766329],
          [54.87334, 37.766175],
          [54.873211, 37.766096],
          [54.873136, 37.766079],
          [54.872953, 37.765979],
          [54.872876, 37.765959],
          [54.872712, 37.765946],
          [54.872546, 37.765948],
          [54.872426, 37.765996],
          [54.872207, 37.76612],
          [54.872029, 37.766259],
          [54.87186, 37.766403],
          [54.871762, 37.766456],
          [54.871573, 37.766509],
          [54.871423, 37.766545],
          [54.871279, 37.76655],
          [54.871179, 37.76654],
          [54.871106, 37.766504],
          [54.87106, 37.766438],
          [54.871051, 37.76637],
          [54.871067, 37.766312],
          [54.871126, 37.766233],
          [54.871231, 37.766136],
          [54.871339, 37.76604],
          [54.871383, 37.765986],
          [54.871402, 37.765913],
          [54.871391, 37.765787],
          [54.871311, 37.765611],
          [54.871227, 37.765392],
          [54.871124, 37.765215],
          [54.870991, 37.765054],
          [54.87083, 37.764851],
          [54.870714, 37.764661],
          [54.87066, 37.764523],
          [54.870641, 37.764384],
          [54.870642, 37.764163],
          [54.870651, 37.763888],
          [54.870601, 37.763635],
          [54.870494, 37.763307],
          [54.870381, 37.763033],
          [54.870206, 37.762723],
          [54.870079, 37.762548],
          [54.869938, 37.762411],
          [54.869817, 37.762328],
          [54.869736, 37.76229],
          [54.869663, 37.762287],
          [54.869575, 37.762299],
          [54.869489, 37.762354],
          [54.86938, 37.762535],
          [54.869266, 37.762814],
          [54.869295, 37.762992],
          [54.869368, 37.76323],
          [54.869379, 37.763554],
          [54.869354, 37.763599],
          [54.869281, 37.763693],
          [54.869259, 37.763798],
          [54.869278, 37.764034],
          [54.869334, 37.76425],
          [54.869443, 37.764461],
          [54.869635, 37.764748],
          [54.869765, 37.764995],
          [54.869917, 37.765179],
          [54.870065, 37.76535],
          [54.870145, 37.765482],
          [54.870168, 37.765555],
          [54.870145, 37.765619],
          [54.870079, 37.765675],
          [54.869993, 37.765698],
          [54.869865, 37.765706],
          [54.86966, 37.765679],
          [54.869371, 37.765657],
          [54.869192, 37.76566],
          [54.869107, 37.765691],
          [54.869026, 37.765762],
          [54.868959, 37.765868],
          [54.868947, 37.765947],
          [54.868971, 37.766072],
          [54.869065, 37.766281],
          [54.869146, 37.766525],
          [54.869272, 37.766763],
          [54.869392, 37.766931],
          [54.869441, 37.767081],
          [54.869442, 37.767146],
          [54.869409, 37.767201],
          [54.869307, 37.767244],
          [54.869123, 37.767276],
          [54.868973, 37.767277],
          [54.868881, 37.76726],
          [54.868649, 37.76718],
          [54.868352, 37.767112],
          [54.867966, 37.767044],
          [54.867614, 37.767045],
          [54.867051, 37.767096],
          [54.866702, 37.767113],
          [54.866437, 37.767091],
          [54.866205, 37.767025],
          [54.866049, 37.766937],
          [54.865867, 37.766857],
          [54.865804, 37.766799],
          [54.865778, 37.766734],
          [54.865759, 37.766632],
          [54.865762, 37.766494],
          [54.865801, 37.766438],
          [54.865817, 37.766323],
          [54.865796, 37.766097],
          [54.865741, 37.765794],
          [54.865693, 37.765595],
          [54.865683, 37.765415],
          [54.865699, 37.765256],
          [54.865746, 37.765109],
          [54.865843, 37.764857],
          [54.865965, 37.764676],
          [54.866112, 37.764536],
          [54.866261, 37.764307],
          [54.866281, 37.764231],
          [54.866252, 37.76403],
          [54.866193, 37.763857],
          [54.866184, 37.763616],
          [54.866156, 37.763417],
          [54.866086, 37.763144],
          [54.865881, 37.762775],
          [54.865719, 37.762669],
          [54.865461, 37.762489],
          [54.865319, 37.762348],
          [54.865216, 37.762155],
          [54.865069, 37.761935],
          [54.864904, 37.761738],
          [54.86474, 37.761614],
          [54.86456, 37.761496],
          [54.864352, 37.761416],
          [54.864007, 37.76135],
          [54.863908, 37.761352],
          [54.863768, 37.761406],
          [54.863497, 37.76154],
          [54.863409, 37.761609],
          [54.863368, 37.761676],
          [54.863345, 37.761752],
          [54.863347, 37.761853],
          [54.863378, 37.76194],
          [54.863466, 37.762095],
          [54.863576, 37.762285],
          [54.86361, 37.762384],
          [54.863592, 37.762461],
          [54.863548, 37.762497],
          [54.863448, 37.762523],
          [54.863276, 37.762529],
          [54.86315, 37.762511],
          [54.863049, 37.762458],
          [54.862881, 37.762361],
          [54.862826, 37.762338],
          [54.862765, 37.762264],
          [54.862635, 37.761999],
          [54.862507, 37.76173],
          [54.862383, 37.76151],
          [54.862206, 37.761338],
          [54.861928, 37.761074],
          [54.861761, 37.760929],
          [54.861693, 37.760833],
          [54.861621, 37.760685],
          [54.861521, 37.760472],
          [54.861458, 37.760229],
          [54.861349, 37.759954],
          [54.861087, 37.759659],
          [54.860903, 37.759497],
          [54.860833, 37.759415],
          [54.860811, 37.759352],
          [54.860805, 37.759308],
          [54.860808, 37.759257],
          [54.860846, 37.759209],
          [54.860939, 37.75913],
          [54.86109, 37.759017],
          [54.861163, 37.758925],
          [54.861178, 37.758863],
          [54.861167, 37.758785],
          [54.861079, 37.758689],
          [54.860859, 37.758566],
          [54.860603, 37.758413],
          [54.860327, 37.758225],
          [54.860168, 37.758122],
          [54.860065, 37.7581],
          [54.859945, 37.758105],
          [54.859618, 37.758171],
          [54.8593, 37.758268],
          [54.859002, 37.758441],
          [54.858666, 37.758628],
          [54.858405, 37.758741],
          [54.858148, 37.758844],
          [54.858017, 37.758893],
          [54.85794, 37.758907],
          [54.857877, 37.758894],
          [54.857802, 37.75885],
          [54.857768, 37.758788],
          [54.857752, 37.75871],
          [54.857755, 37.758614],
          [54.85778, 37.758525],
          [54.857888, 37.75835],
          [54.857989, 37.758268],
          [54.858135, 37.758183],
          [54.858252, 37.758081],
          [54.858302, 37.758014],
          [54.858317, 37.757942],
          [54.858301, 37.757861],
          [54.858255, 37.757799],
          [54.85816, 37.757746],
          [54.858016, 37.757724],
          [54.857638, 37.757728],
          [54.857447, 37.75778],
          [54.857276, 37.757879],
          [54.856947, 37.758091],
          [54.856821, 37.758153],
          [54.856645, 37.758205],
          [54.856461, 37.758232],
          [54.856295, 37.758224],
          [54.856144, 37.758192],
          [54.856066, 37.758163],
          [54.856036, 37.758121],
          [54.856013, 37.75804],
          [54.856008, 37.757946],
          [54.856042, 37.757772],
          [54.856064, 37.757645],
          [54.856068, 37.757546],
          [54.856021, 37.75749],
          [54.855943, 37.757474],
          [54.855794, 37.757439],
          [54.855585, 37.757359],
          [54.855467, 37.757225],
          [54.855406, 37.757063],
          [54.855376, 37.756857],
          [54.855382, 37.756556],
          [54.855363, 37.756217],
          [54.855318, 37.756067],
          [54.855205, 37.755934],
          [54.854929, 37.75564],
          [54.854791, 37.755439],
          [54.854655, 37.755168],
          [54.854542, 37.754926],
          [54.854414, 37.754682],
          [54.854369, 37.754555],
          [54.854362, 37.754474],
          [54.85438, 37.754415],
          [54.854418, 37.754365],
          [54.854481, 37.754328],
          [54.854574, 37.754336],
          [54.854648, 37.754366],
          [54.854772, 37.754422],
          [54.854952, 37.754429],
          [54.855163, 37.754416],
          [54.855452, 37.75436],
          [54.855785, 37.754313],
          [54.856092, 37.754243],
          [54.85621, 37.754173],
          [54.856269, 37.754101],
          [54.856273, 37.754034],
          [54.856245, 37.753971],
          [54.856107, 37.753842],
          [54.855978, 37.753757],
          [54.855703, 37.753626],
          [54.855462, 37.753479],
          [54.85535, 37.753379],
          [54.855243, 37.753228],
          [54.855146, 37.753049],
          [54.855033, 37.752789],
          [54.854927, 37.752534],
          [54.854838, 37.752275],
          [54.854822, 37.752145],
          [54.854847, 37.752067],
          [54.854883, 37.75203],
          [54.854976, 37.752007],
          [54.855138, 37.752018],
          [54.855304, 37.752004],
          [54.855469, 37.751947],
          [54.85552, 37.75191],
          [54.85555, 37.751857],
          [54.855518, 37.751726],
          [54.855358, 37.751514],
          [54.85513, 37.751268],
          [54.855046, 37.75115],
          [54.855005, 37.751073],
          [54.854983, 37.751006],
          [54.854981, 37.750931],
          [54.855067, 37.750804],
          [54.855238, 37.750684],
          [54.855451, 37.750556],
          [54.855709, 37.750395],
          [54.855812, 37.750301],
          [54.855833, 37.750237],
          [54.855804, 37.750161],
          [54.855726, 37.750055],
          [54.855618, 37.749967],
          [54.855369, 37.749852],
          [54.855118, 37.749769],
          [54.854817, 37.749745],
          [54.854466, 37.749727],
          [54.854109, 37.749708],
          [54.853745, 37.749708],
          [54.853543, 37.74968],
          [54.853443, 37.749639],
          [54.853409, 37.749592],
          [54.853396, 37.749537],
          [54.853404, 37.74948],
          [54.853444, 37.749425],
          [54.853558, 37.749361],
          [54.853658, 37.749328],
          [54.853804, 37.749332],
          [54.854154, 37.7493],
          [54.854605, 37.749234],
          [54.854839, 37.749157],
          [54.854963, 37.749078],
          [54.855017, 37.749003],
          [54.855036, 37.748903],
          [54.85502, 37.748784],
          [54.854923, 37.748657],
          [54.854805, 37.748602],
          [54.854571, 37.748551],
          [54.854459, 37.748489],
          [54.854127, 37.74842],
          [54.853827, 37.748407],
          [54.853301, 37.748374],
          [54.85288, 37.748391],
          [54.8523, 37.748517],
          [54.851747, 37.748648],
          [54.851402, 37.748745],
          [54.851097, 37.748896],
          [54.850898, 37.748993],
          [54.8508, 37.749085],
          [54.850714, 37.749262],
          [54.850691, 37.749395],
          [54.850637, 37.74962],
          [54.850484, 37.749959],
          [54.850403, 37.750022],
          [54.850313, 37.750051],
          [54.850253, 37.750054],
          [54.850178, 37.750039],
          [54.850091, 37.749997],
          [54.849995, 37.7499],
          [54.849872, 37.749743],
          [54.849781, 37.749555],
          [54.849676, 37.749351],
          [54.849562, 37.749218],
          [54.849465, 37.74912],
          [54.84938, 37.749103],
          [54.849269, 37.749118],
          [54.849152, 37.749201],
          [54.849078, 37.749382],
          [54.848943, 37.749585],
          [54.848872, 37.749631],
          [54.848789, 37.749651],
          [54.848701, 37.749687],
          [54.848575, 37.749705],
          [54.848504, 37.749698],
          [54.848424, 37.749659],
          [54.84838, 37.749608],
          [54.848346, 37.749498],
          [54.848331, 37.749357],
          [54.848357, 37.749228],
          [54.848468, 37.749024],
          [54.848685, 37.748735],
          [54.848865, 37.748504],
          [54.848997, 37.748348],
          [54.849035, 37.748272],
          [54.849007, 37.74819],
          [54.848889, 37.74805],
          [54.848671, 37.74795],
          [54.848408, 37.747887],
          [54.847906, 37.74776],
          [54.847808, 37.747715],
          [54.847777, 37.747676],
          [54.847772, 37.74763],
          [54.847796, 37.747556],
          [54.847947, 37.747391],
          [54.848178, 37.747206],
          [54.848413, 37.747049],
          [54.848644, 37.746846],
          [54.848732, 37.746738],
          [54.84875, 37.746666],
          [54.848719, 37.74659],
          [54.848599, 37.74648],
          [54.8484, 37.74639],
          [54.848181, 37.746349],
          [54.847821, 37.74636],
          [54.847573, 37.74642],
          [54.847342, 37.746515],
          [54.847096, 37.746631],
          [54.846893, 37.74669],
          [54.846796, 37.746706],
          [54.846691, 37.746701],
          [54.846556, 37.746646],
          [54.846357, 37.746573],
          [54.846078, 37.746521],
          [54.845842, 37.746413],
          [54.845791, 37.746357],
          [54.845789, 37.746285],
          [54.845805, 37.746202],
          [54.845848, 37.746146],
          [54.846013, 37.746065],
          [54.846249, 37.745972],
          [54.846497, 37.745897],
          [54.846524, 37.745837],
          [54.846427, 37.745693],
          [54.846262, 37.745545],
          [54.846088, 37.745475],
          [54.845891, 37.745475],
          [54.845677, 37.745499],
          [54.845413, 37.745557],
          [54.845216, 37.745665],
          [54.845026, 37.745815],
          [54.844824, 37.746005],
          [54.844623, 37.746133],
          [54.844544, 37.746158],
          [54.844467, 37.746154],
          [54.844397, 37.746133],
          [54.844339, 37.746097],
          [54.844298, 37.746011],
          [54.844258, 37.745845],
          [54.844276, 37.745709],
          [54.84424, 37.745402],
          [54.844167, 37.745088],
          [54.844055, 37.744741],
          [54.843946, 37.744495],
          [54.843863, 37.744442],
          [54.843752, 37.74442],
          [54.843541, 37.744434],
          [54.843378, 37.74449],
          [54.843246, 37.744598],
          [54.843024, 37.744854],
          [54.842794, 37.745016],
          [54.842557, 37.745078],
          [54.842224, 37.745082],
          [54.842015, 37.745053],
          [54.841743, 37.745026],
          [54.841568, 37.745046],
          [54.841436, 37.745089],
          [54.841247, 37.745202],
          [54.840943, 37.745467],
          [54.840637, 37.745692],
          [54.840497, 37.74576],
          [54.840412, 37.745775],
          [54.840312, 37.745758],
          [54.840213, 37.745694],
          [54.840164, 37.745614],
          [54.840081, 37.745413],
          [54.840044, 37.74525],
          [54.840077, 37.745117],
          [54.840162, 37.744965],
          [54.840233, 37.744714],
          [54.840356, 37.744457],
          [54.840385, 37.744344],
          [54.840364, 37.744203],
          [54.840279, 37.74406],
          [54.840176, 37.743976],
          [54.839972, 37.743856],
          [54.839919, 37.743794],
          [54.839908, 37.743728],
          [54.839921, 37.743663],
          [54.83997, 37.743587],
          [54.840061, 37.743529],
          [54.840266, 37.743455],
          [54.840447, 37.743317],
          [54.840658, 37.743186],
          [54.840947, 37.743041],
          [54.841183, 37.74287],
          [54.841371, 37.742742],
          [54.841483, 37.742693],
          [54.841599, 37.742691],
          [54.841719, 37.742726],
          [54.841794, 37.742806],
          [54.841835, 37.742929],
          [54.841869, 37.743119],
          [54.841881, 37.743334],
          [54.841912, 37.74346],
          [54.84196, 37.743516],
          [54.842052, 37.743562],
          [54.84221, 37.74357],
          [54.842427, 37.743533],
          [54.842691, 37.743442],
          [54.842956, 37.743275],
          [54.843036, 37.743185],
          [54.843086, 37.743034],
          [54.843145, 37.742787],
          [54.843144, 37.742633],
          [54.843071, 37.7423],
          [54.842992, 37.742014],
          [54.842847, 37.741704],
          [54.842679, 37.741387],
          [54.842501, 37.741142],
          [54.842325, 37.740859],
          [54.842276, 37.740755],
          [54.842278, 37.740666],
          [54.84233, 37.740536],
          [54.842399, 37.740488],
          [54.842564, 37.740461],
          [54.842848, 37.740422],
          [54.843183, 37.740442],
          [54.84347, 37.740461],
          [54.84375, 37.740436],
          [54.844122, 37.740362],
          [54.844369, 37.740319],
          [54.844595, 37.740335],
          [54.845004, 37.740347],
          [54.84536, 37.740315],
          [54.845645, 37.740246],
          [54.84573, 37.740184],
          [54.84586, 37.740011],
          [54.845873, 37.739856],
          [54.845808, 37.739686],
          [54.84569, 37.739527],
          [54.845519, 37.73941],
          [54.845301, 37.739328],
          [54.844888, 37.739286],
          [54.844482, 37.739218],
          [54.843757, 37.739131],
          [54.843169, 37.739099],
          [54.84282, 37.739046],
          [54.84256, 37.738981],
          [54.842252, 37.738867],
          [54.841996, 37.738804],
          [54.841638, 37.738732],
          [54.841507, 37.738731],
          [54.841124, 37.738769],
          [54.840857, 37.7388],
          [54.840529, 37.738828],
          [54.840311, 37.738829],
          [54.840238, 37.738811],
          [54.840185, 37.738768],
          [54.840138, 37.738662],
          [54.840127, 37.738461],
          [54.840047, 37.738262],
          [54.839722, 37.737874],
          [54.839529, 37.737618],
          [54.839452, 37.737413],
          [54.839433, 37.737332],
          [54.839439, 37.737284],
          [54.839478, 37.737234],
          [54.839605, 37.73717],
          [54.839728, 37.737147],
          [54.840008, 37.737155],
          [54.840448, 37.737132],
          [54.840698, 37.737044],
          [54.840732, 37.737011],
          [54.840734, 37.736819],
          [54.840734, 37.736719],
          [54.840697, 37.736582],
          [54.840608, 37.736502],
          [54.840464, 37.736411],
          [54.840311, 37.736228],
          [54.840185, 37.736117],
          [54.840066, 37.736065],
          [54.839859, 37.736022],
          [54.839493, 37.73588],
          [54.83915, 37.73575],
          [54.838816, 37.735599],
          [54.838744, 37.735551],
          [54.838712, 37.735493],
          [54.838674, 37.735364],
          [54.838681, 37.735254],
          [54.838743, 37.735122],
          [54.838914, 37.734956],
          [54.839067, 37.734847],
          [54.839109, 37.734782],
          [54.839233, 37.734681],
          [54.839271, 37.734627],
          [54.839282, 37.734579],
          [54.839269, 37.734504],
          [54.839208, 37.734432],
          [54.839, 37.734278],
          [54.838584, 37.734029],
          [54.838472, 37.733925],
          [54.838429, 37.733764],
          [54.83842, 37.733594],
          [54.838439, 37.733348],
          [54.838423, 37.733127],
          [54.838335, 37.732914],
          [54.838082, 37.732603],
          [54.837849, 37.732366],
          [54.837675, 37.732254],
          [54.837544, 37.732209],
          [54.837432, 37.732207],
          [54.83728, 37.732234],
          [54.837137, 37.732315],
          [54.837016, 37.732432],
          [54.83679, 37.732694],
          [54.83659, 37.732959],
          [54.83644, 37.733106],
          [54.83626, 37.733218],
          [54.836074, 37.733258],
          [54.835849, 37.733266],
          [54.835539, 37.733233],
          [54.835379, 37.73319],
          [54.835178, 37.733118],
          [54.835112, 37.733071],
          [54.835082, 37.733025],
          [54.835065, 37.7329],
          [54.83508, 37.73261],
          [54.835099, 37.732431],
          [54.835085, 37.732121],
          [54.835102, 37.732],
          [54.835157, 37.731831],
          [54.835341, 37.731662],
          [54.835576, 37.731449],
          [54.835784, 37.731235],
          [54.835812, 37.731181],
          [54.835812, 37.731134],
          [54.835753, 37.731055],
          [54.835657, 37.731],
          [54.835494, 37.730991],
          [54.8352, 37.731011],
          [54.834978, 37.731092],
          [54.834781, 37.731156],
          [54.834665, 37.731177],
          [54.834563, 37.731162],
          [54.834487, 37.731128],
          [54.834455, 37.73108],
          [54.834449, 37.73098],
          [54.834503, 37.730753],
          [54.83467, 37.730465],
          [54.834747, 37.73038],
          [54.834812, 37.73023],
          [54.834797, 37.73008],
          [54.834703, 37.729985],
          [54.834455, 37.729838],
          [54.834239, 37.729627],
          [54.834106, 37.729373],
          [54.833981, 37.729126],
          [54.833881, 37.728862],
          [54.833785, 37.728714],
          [54.833614, 37.728604],
          [54.833438, 37.728545],
          [54.83325, 37.728505],
          [54.83306, 37.728505],
          [54.832808, 37.728525],
          [54.832608, 37.728558],
          [54.832511, 37.728546],
          [54.832323, 37.728494],
          [54.832154, 37.728398],
          [54.832095, 37.728334],
          [54.83206, 37.728248],
          [54.832026, 37.728136],
          [54.832025, 37.728039],
          [54.832082, 37.72794],
          [54.83223, 37.727783],
          [54.832446, 37.727578],
          [54.832611, 37.727406],
          [54.832647, 37.727308],
          [54.832619, 37.727158],
          [54.832528, 37.727047],
          [54.832455, 37.726982],
          [54.832216, 37.726938],
          [54.832017, 37.726936],
          [54.831866, 37.726979],
          [54.831723, 37.72707],
          [54.831564, 37.727254],
          [54.831422, 37.727428],
          [54.831224, 37.727509],
          [54.8311, 37.727545],
          [54.830992, 37.727531],
          [54.830878, 37.727484],
          [54.830765, 37.727379],
          [54.830592, 37.727252],
          [54.830496, 37.727227],
          [54.830407, 37.727233],
          [54.830278, 37.727288],
          [54.830151, 37.727363],
          [54.830081, 37.727465],
          [54.830023, 37.727598],
          [54.829999, 37.727708],
          [54.83, 37.727911],
          [54.829945, 37.728296],
          [54.829848, 37.728369],
          [54.829745, 37.728394],
          [54.829632, 37.728392],
          [54.829489, 37.728356],
          [54.829373, 37.728285],
          [54.829183, 37.7281],
          [54.829049, 37.72793],
          [54.828831, 37.727743],
          [54.828781, 37.727625],
          [54.828745, 37.727494],
          [54.828738, 37.72741],
          [54.828752, 37.727359],
          [54.828838, 37.72727],
          [54.829064, 37.727033],
          [54.829201, 37.726914],
          [54.829281, 37.726736],
          [54.829289, 37.726416],
          [54.829331, 37.72609],
          [54.829365, 37.726005],
          [54.829454, 37.725887],
          [54.829595, 37.725794],
          [54.829707, 37.725654],
          [54.829823, 37.725464],
          [54.829842, 37.725341],
          [54.829804, 37.725244],
          [54.829694, 37.725158],
          [54.829464, 37.725097],
          [54.829027, 37.725015],
          [54.828621, 37.725043],
          [54.8283, 37.725083],
          [54.827878, 37.725181],
          [54.827657, 37.725299],
          [54.827504, 37.725455],
          [54.827389, 37.725671],
          [54.827239, 37.725859],
          [54.827104, 37.725958],
          [54.826731, 37.726055],
          [54.826404, 37.726124],
          [54.826291, 37.726195],
          [54.826237, 37.726286],
          [54.826242, 37.726351],
          [54.82633, 37.726528],
          [54.826498, 37.726798],
          [54.826512, 37.726911],
          [54.826438, 37.727007],
          [54.826333, 37.727044],
          [54.826175, 37.727056],
          [54.825924, 37.726988],
          [54.825675, 37.726827],
          [54.825334, 37.726635],
          [54.824998, 37.726485],
          [54.824668, 37.726357],
          [54.824428, 37.726234],
          [54.824374, 37.726185],
          [54.824356, 37.726123],
          [54.824355, 37.726021],
          [54.824414, 37.725854],
          [54.824553, 37.725576],
          [54.824715, 37.725353],
          [54.824948, 37.725016],
          [54.825113, 37.724747],
          [54.82512, 37.724614],
          [54.825043, 37.724434],
          [54.824917, 37.72416],
          [54.824747, 37.723871],
          [54.824584, 37.723588],
          [54.824312, 37.723167],
          [54.824098, 37.722879],
          [54.824021, 37.722715],
          [54.823961, 37.722529],
          [54.823939, 37.722391],
          [54.823962, 37.722262],
          [54.823872, 37.721839],
          [54.823725, 37.721465],
          [54.823656, 37.721268],
          [54.823636, 37.721094],
          [54.823656, 37.720743],
          [54.823617, 37.720515],
          [54.823516, 37.720246],
          [54.823329, 37.719802],
          [54.823265, 37.719559],
          [54.823256, 37.719405],
          [54.823267, 37.719283],
          [54.823302, 37.719198],
          [54.82339, 37.7191],
          [54.823543, 37.71881],
          [54.823561, 37.718699],
          [54.823513, 37.71863],
          [54.823345, 37.718537],
          [54.823225, 37.718404],
          [54.823173, 37.718256],
          [54.823196, 37.718055],
          [54.823243, 37.717857],
          [54.823328, 37.717707],
          [54.823484, 37.717594],
          [54.823617, 37.717555],
          [54.823846, 37.717554],
          [54.824051, 37.717582],
          [54.82443, 37.717646],
          [54.82464, 37.717692],
          [54.824728, 37.717687],
          [54.824823, 37.717656],
          [54.82494, 37.717577],
          [54.825001, 37.717488],
          [54.825036, 37.717233],
          [54.825055, 37.716964],
          [54.825057, 37.716587],
          [54.825069, 37.716406],
          [54.825117, 37.716296],
          [54.825279, 37.716177],
          [54.825538, 37.715991],
          [54.825673, 37.715843],
          [54.825684, 37.715743],
          [54.825595, 37.715453],
          [54.825355, 37.714896],
          [54.825093, 37.714329],
          [54.824987, 37.714263],
          [54.824868, 37.714244],
          [54.824695, 37.714266],
          [54.824513, 37.714344],
          [54.824286, 37.714526],
          [54.824138, 37.714683],
          [54.823905, 37.714912],
          [54.823732, 37.715151],
          [54.823586, 37.715313],
          [54.823438, 37.715387],
          [54.823314, 37.715397],
          [54.823182, 37.715361],
          [54.822989, 37.715252],
          [54.822669, 37.714977],
          [54.822479, 37.714854],
          [54.822244, 37.714764],
          [54.821938, 37.714651],
          [54.82183, 37.714595],
          [54.821775, 37.714549],
          [54.821751, 37.714504],
          [54.821748, 37.714454],
          [54.821792, 37.714318],
          [54.821922, 37.714034],
          [54.82199, 37.713782],
          [54.821968, 37.713661],
          [54.821889, 37.71359],
          [54.821792, 37.713556],
          [54.821591, 37.713552],
          [54.8214, 37.713611],
          [54.821137, 37.713732],
          [54.820905, 37.713936],
          [54.820741, 37.714038],
          [54.820525, 37.714097],
          [54.820391, 37.714092],
          [54.820192, 37.714025],
          [54.819923, 37.713919],
          [54.819842, 37.713874],
          [54.819778, 37.713788],
          [54.819717, 37.713687],
          [54.819707, 37.713637],
          [54.819716, 37.713561],
          [54.819787, 37.713446],
          [54.819912, 37.713315],
          [54.820242, 37.713025],
          [54.820383, 37.712869],
          [54.820523, 37.712713],
          [54.820652, 37.71246],
          [54.820758, 37.712289],
          [54.820835, 37.712228],
          [54.821072, 37.712098],
          [54.821187, 37.712002],
          [54.821229, 37.711938],
          [54.821243, 37.711867],
          [54.821248, 37.711785],
          [54.821216, 37.711715],
          [54.821083, 37.711658],
          [54.820822, 37.71156],
          [54.820466, 37.711427],
          [54.820153, 37.711311],
          [54.819891, 37.711179],
          [54.819854, 37.71114],
          [54.819844, 37.711085],
          [54.819847, 37.710979],
          [54.819877, 37.710882],
          [54.819997, 37.710706],
          [54.820218, 37.710453],
          [54.820379, 37.710154],
          [54.820508, 37.709941],
          [54.820624, 37.709683],
          [54.82077, 37.709534],
          [54.820909, 37.709448],
          [54.821431, 37.709255],
          [54.821598, 37.709133],
          [54.82164, 37.709016],
          [54.821625, 37.708905],
          [54.821522, 37.708764],
          [54.821341, 37.708655],
          [54.821183, 37.708622],
          [54.821005, 37.708635],
          [54.820805, 37.708729],
          [54.820423, 37.708985],
          [54.820235, 37.709096],
          [54.820125, 37.709121],
          [54.81997, 37.709084],
          [54.819764, 37.708991],
          [54.819694, 37.708915],
          [54.819664, 37.708802],
          [54.819667, 37.70866],
          [54.819702, 37.708558],
          [54.81992, 37.708358],
          [54.82017, 37.708182],
          [54.820355, 37.708066],
          [54.82049, 37.707957],
          [54.820585, 37.707813],
          [54.820626, 37.707668],
          [54.820585, 37.707449],
          [54.820585, 37.707367],
          [54.8207, 37.707218],
          [54.820811, 37.70713],
          [54.821029, 37.707076],
          [54.821199, 37.706965],
          [54.821265, 37.706835],
          [54.821464, 37.706265],
          [54.821621, 37.705945],
          [54.82185, 37.705714],
          [54.822118, 37.705446],
          [54.822226, 37.705173],
          [54.822394, 37.704852],
          [54.822525, 37.704678],
          [54.822716, 37.704592],
          [54.822975, 37.704553],
          [54.823126, 37.704457],
          [54.823175, 37.704347],
          [54.823156, 37.704199],
          [54.822999, 37.70396],
          [54.822881, 37.703832],
          [54.822734, 37.703514],
          [54.82258, 37.703203],
          [54.822444, 37.702968],
          [54.822215, 37.702863],
          [54.82191, 37.70279],
          [54.821522, 37.702764],
          [54.82126, 37.702731],
          [54.821177, 37.702694],
          [54.82114, 37.702643],
          [54.821145, 37.702529],
          [54.821189, 37.702344],
          [54.821288, 37.702114],
          [54.821283, 37.702063],
          [54.821167, 37.701965],
          [54.820983, 37.701919],
          [54.820705, 37.701891],
          [54.820299, 37.70188],
          [54.820212, 37.701877],
          [54.820126, 37.701802],
          [54.82007, 37.701709],
          [54.820066, 37.701607],
          [54.820087, 37.701492],
          [54.820149, 37.701425],
          [54.820243, 37.701381],
          [54.820433, 37.701349],
          [54.820793, 37.701346],
          [54.821082, 37.701341],
          [54.82124, 37.701318],
          [54.821327, 37.701244],
          [54.821415, 37.701081],
          [54.821498, 37.700937],
          [54.821626, 37.700798],
          [54.821804, 37.700741],
          [54.822083, 37.700667],
          [54.822259, 37.700651],
          [54.822481, 37.700675],
          [54.822692, 37.700638],
          [54.822862, 37.700571],
          [54.822945, 37.700504],
          [54.822948, 37.700423],
          [54.822946, 37.700281],
          [54.82288, 37.700161],
          [54.822661, 37.699962],
          [54.822407, 37.699765],
          [54.822318, 37.699684],
          [54.822258, 37.699602],
          [54.822237, 37.699478],
          [54.822249, 37.699365],
          [54.822304, 37.699206],
          [54.822309, 37.699096],
          [54.822286, 37.698956],
          [54.822212, 37.69884],
          [54.822075, 37.698741],
          [54.821917, 37.698708],
          [54.82166, 37.698702],
          [54.821377, 37.69871],
          [54.821094, 37.698757],
          [54.820803, 37.698847],
          [54.820533, 37.698944],
          [54.820223, 37.699077],
          [54.819878, 37.699238],
          [54.81968, 37.699339],
          [54.819494, 37.699398],
          [54.819283, 37.699416],
          [54.819063, 37.69938],
          [54.818762, 37.699274],
          [54.818619, 37.699245],
          [54.818503, 37.699262],
          [54.818123, 37.6994],
          [54.817851, 37.699483],
          [54.817679, 37.699494],
          [54.817541, 37.699435],
          [54.817387, 37.699287],
          [54.817335, 37.699181],
          [54.817324, 37.699065],
          [54.817398, 37.698889],
          [54.817532, 37.698641],
          [54.817602, 37.698448],
          [54.81759, 37.698353],
          [54.817476, 37.698249],
          [54.817313, 37.698225],
          [54.817119, 37.698238],
          [54.816638, 37.698352],
          [54.816264, 37.698517],
          [54.815974, 37.698639],
          [54.815876, 37.69866],
          [54.815768, 37.698648],
          [54.815612, 37.698553],
          [54.815501, 37.698435],
          [54.815447, 37.698347],
          [54.815411, 37.69822],
          [54.815408, 37.698102],
          [54.815424, 37.697908],
          [54.815455, 37.697799],
          [54.815555, 37.697605],
          [54.815647, 37.697504],
          [54.815735, 37.697452],
          [54.81584, 37.697452],
          [54.816071, 37.69752],
          [54.81644, 37.697631],
          [54.816574, 37.697646],
          [54.816776, 37.697642],
          [54.816938, 37.697583],
          [54.817167, 37.697458],
          [54.817623, 37.69734],
          [54.818139, 37.697226],
          [54.818247, 37.697164],
          [54.818298, 37.697055],
          [54.818314, 37.69694],
          [54.818245, 37.696673],
          [54.81817, 37.696404],
          [54.818173, 37.696291],
          [54.818227, 37.696158],
          [54.818438, 37.695882],
          [54.818537, 37.695697],
          [54.81858, 37.695535],
          [54.818549, 37.695374],
          [54.818402, 37.695085],
          [54.818159, 37.6948],
          [54.818011, 37.694629],
          [54.817845, 37.694345],
          [54.817737, 37.694227],
          [54.817467, 37.694069],
          [54.817073, 37.693822],
          [54.816898, 37.693692],
          [54.816773, 37.693648],
          [54.816664, 37.693642],
          [54.816544, 37.693681],
          [54.816466, 37.693742],
          [54.816358, 37.69393],
          [54.816207, 37.69409],
          [54.816137, 37.694116],
          [54.816002, 37.694108],
          [54.815918, 37.694062],
          [54.81579, 37.693923],
          [54.815499, 37.693568],
          [54.815256, 37.693415],
          [54.81511, 37.693296],
          [54.815082, 37.693216],
          [54.815075, 37.693137],
          [54.815125, 37.693036],
          [54.815268, 37.692824],
          [54.815364, 37.69267],
          [54.815378, 37.69258],
          [54.8153, 37.692388],
          [54.815169, 37.69219],
          [54.814974, 37.691875],
          [54.814882, 37.691645],
          [54.814806, 37.691374],
          [54.814778, 37.691139],
          [54.814775, 37.690941],
          [54.8147, 37.690807],
          [54.814618, 37.690739],
          [54.814442, 37.690688],
          [54.81416, 37.690643],
          [54.81365, 37.690466],
          [54.813463, 37.690387],
          [54.813281, 37.690274],
          [54.812976, 37.690227],
          [54.81285, 37.690228],
          [54.812611, 37.69028],
          [54.812381, 37.690277],
          [54.812217, 37.690238],
          [54.812154, 37.690163],
          [54.812138, 37.690097],
          [54.812189, 37.689932],
          [54.81232, 37.689809],
          [54.812472, 37.689678],
          [54.812525, 37.689563],
          [54.812503, 37.689443],
          [54.812312, 37.689251],
          [54.811961, 37.689008],
          [54.811713, 37.688919],
          [54.811381, 37.688926],
          [54.811029, 37.689046],
          [54.810787, 37.689105],
          [54.810477, 37.689128],
          [54.810326, 37.689077],
          [54.810164, 37.688954],
          [54.809909, 37.688639],
          [54.809569, 37.688431],
          [54.80944, 37.688297],
          [54.809432, 37.688217],
          [54.809481, 37.68811],
          [54.809596, 37.688023],
          [54.809889, 37.687943],
          [54.810308, 37.687842],
          [54.810512, 37.687738],
          [54.810678, 37.687605],
          [54.810746, 37.687504],
          [54.810983, 37.687279],
          [54.8113, 37.687003],
          [54.811596, 37.686664],
          [54.811737, 37.686475],
          [54.811848, 37.686416],
          [54.811971, 37.686417],
          [54.812114, 37.686445],
          [54.812342, 37.686518],
          [54.812564, 37.686529],
          [54.812717, 37.686491],
          [54.81283, 37.686432],
          [54.812892, 37.686354],
          [54.812912, 37.686271],
          [54.812872, 37.686088],
          [54.812752, 37.685912],
          [54.812535, 37.685749],
          [54.812164, 37.685547],
          [54.81181, 37.685456],
          [54.811583, 37.685369],
          [54.8115, 37.685292],
          [54.811439, 37.685203],
          [54.811438, 37.68509],
          [54.811508, 37.684943],
          [54.811624, 37.684822],
          [54.81177, 37.684772],
          [54.811985, 37.684765],
          [54.812196, 37.684807],
          [54.812471, 37.684903],
          [54.812649, 37.684935],
          [54.812752, 37.684927],
          [54.812835, 37.684881],
          [54.812991, 37.684711],
          [54.813278, 37.684359],
          [54.813359, 37.684223],
          [54.813367, 37.684085],
          [54.813308, 37.683861],
          [54.813113, 37.683702],
          [54.812896, 37.683582],
          [54.812746, 37.683566],
          [54.812464, 37.68343],
          [54.812085, 37.683384],
          [54.811664, 37.683327],
          [54.811326, 37.683305],
          [54.811107, 37.683325],
          [54.810562, 37.683343],
          [54.81012, 37.683328],
          [54.80974, 37.683206],
          [54.809529, 37.683199],
          [54.809369, 37.683236],
          [54.809178, 37.683335],
          [54.808981, 37.683505],
          [54.808799, 37.68362],
          [54.808503, 37.683888],
          [54.808225, 37.684081],
          [54.808074, 37.68413],
          [54.80797, 37.684121],
          [54.807902, 37.68408],
          [54.807856, 37.684009],
          [54.807833, 37.683936],
          [54.807837, 37.683851],
          [54.807883, 37.68373],
          [54.807972, 37.683624],
          [54.808123, 37.68347],
          [54.808161, 37.683352],
          [54.808194, 37.683147],
          [54.808224, 37.682946],
          [54.80829, 37.682759],
          [54.808316, 37.682554],
          [54.80827, 37.682434],
          [54.808119, 37.682324],
          [54.807962, 37.682298],
          [54.807676, 37.682277],
          [54.807539, 37.682233],
          [54.807475, 37.682183],
          [54.807458, 37.682117],
          [54.80747, 37.68205],
          [54.80754, 37.681987],
          [54.807711, 37.681927],
          [54.807956, 37.681858],
          [54.808233, 37.681812],
          [54.808579, 37.681791],
          [54.808796, 37.681754],
          [54.808989, 37.681665],
          [54.809171, 37.681514],
          [54.809266, 37.681368],
          [54.80931, 37.681183],
          [54.809301, 37.681031],
          [54.809237, 37.680842],
          [54.809079, 37.680659],
          [54.808797, 37.680473],
          [54.808515, 37.680373],
          [54.808247, 37.680302],
          [54.807896, 37.68016],
          [54.807571, 37.680066],
          [54.807422, 37.68004],
          [54.807151, 37.68005],
          [54.806829, 37.679987],
          [54.806618, 37.679914],
          [54.806242, 37.679908],
          [54.805923, 37.679906],
          [54.805717, 37.679856],
          [54.805477, 37.679742],
          [54.805235, 37.67955],
          [54.805057, 37.679378],
          [54.804831, 37.679143],
          [54.80455, 37.678925],
          [54.804248, 37.678713],
          [54.804182, 37.678628],
          [54.804128, 37.678509],
          [54.804031, 37.678274],
          [54.803886, 37.677945],
          [54.803745, 37.67758],
          [54.803623, 37.677235],
          [54.803463, 37.677081],
          [54.803347, 37.677038],
          [54.803084, 37.67698],
          [54.802688, 37.676943],
          [54.802309, 37.676925],
          [54.801849, 37.676918],
          [54.801496, 37.676911],
          [54.80105, 37.67686],
          [54.800621, 37.676815],
          [54.800256, 37.676791],
          [54.799955, 37.676771],
          [54.799758, 37.67672],
          [54.799703, 37.676665],
          [54.799685, 37.676555],
          [54.799739, 37.676369],
          [54.799865, 37.676196],
          [54.800141, 37.676046],
          [54.800445, 37.675895],
          [54.800578, 37.675767],
          [54.800762, 37.675501],
          [54.800902, 37.675392],
          [54.801214, 37.675245],
          [54.801467, 37.675089],
          [54.801841, 37.674894],
          [54.80202, 37.674746],
          [54.802082, 37.674588],
          [54.802178, 37.674294],
          [54.802271, 37.673928],
          [54.802279, 37.673682],
          [54.802262, 37.673301],
          [54.802305, 37.673096],
          [54.802379, 37.673001],
          [54.802548, 37.672968],
          [54.802686, 37.672996],
          [54.802997, 37.673094],
          [54.803094, 37.6731],
          [54.80322, 37.673067],
          [54.803316, 37.673011],
          [54.803406, 37.67285],
          [54.803429, 37.672723],
          [54.803361, 37.672563],
          [54.803201, 37.672421],
          [54.802887, 37.672266],
          [54.802702, 37.672245],
          [54.802455, 37.672288],
          [54.802181, 37.672412],
          [54.801946, 37.67258],
          [54.801697, 37.67277],
          [54.801485, 37.67288],
          [54.801354, 37.672895],
          [54.80111, 37.672869],
          [54.800704, 37.672754],
          [54.800357, 37.672621],
          [54.800036, 37.672456],
          [54.799796, 37.672368],
          [54.799689, 37.672378],
          [54.799546, 37.672433],
          [54.799462, 37.672512],
          [54.799386, 37.67265],
          [54.799313, 37.672878],
          [54.799202, 37.673063],
          [54.799135, 37.673115],
          [54.798924, 37.673161],
          [54.798627, 37.673225],
          [54.798353, 37.673316],
          [54.798036, 37.673443],
          [54.797914, 37.673443],
          [54.797777, 37.673394],
          [54.797733, 37.673323],
          [54.797753, 37.6732],
          [54.797823, 37.672971],
          [54.797966, 37.672695],
          [54.798063, 37.672454],
          [54.798116, 37.672162],
          [54.798193, 37.671991],
          [54.798326, 37.671801],
          [54.798633, 37.671492],
          [54.798909, 37.671232],
          [54.799125, 37.671021],
          [54.799255, 37.670826],
          [54.799518, 37.670545],
          [54.799788, 37.670316],
          [54.799962, 37.670158],
          [54.800041, 37.670046],
          [54.800044, 37.669917],
          [54.800049, 37.669687],
          [54.800102, 37.669509],
          [54.800226, 37.66935],
          [54.800338, 37.669303],
          [54.800474, 37.66931],
          [54.800554, 37.669351],
          [54.800651, 37.669426],
          [54.800868, 37.669514],
          [54.800999, 37.669538],
          [54.801156, 37.6695],
          [54.801251, 37.669427],
          [54.801281, 37.669291],
          [54.801322, 37.669192],
          [54.801386, 37.669112],
          [54.801383, 37.669064],
          [54.801338, 37.668986],
          [54.801331, 37.668818],
          [54.801288, 37.668723],
          [54.801235, 37.668641],
          [54.801166, 37.668318],
          [54.801093, 37.668198],
          [54.800949, 37.667914],
          [54.800874, 37.667801],
          [54.800831, 37.667645],
          [54.800788, 37.667569],
          [54.800805, 37.667466],
          [54.800899, 37.667378],
          [54.801104, 37.66732],
          [54.801278, 37.667322],
          [54.801421, 37.667351],
          [54.801576, 37.667435],
          [54.80172, 37.667485],
          [54.801917, 37.667582],
          [54.802245, 37.667653],
          [54.802515, 37.667667],
          [54.802812, 37.667654],
          [54.803181, 37.667568],
          [54.803254, 37.66752],
          [54.803336, 37.667469],
          [54.803376, 37.667388],
          [54.803354, 37.667249],
          [54.803222, 37.666923],
          [54.803084, 37.666587],
          [54.802934, 37.666269],
          [54.802774, 37.666104],
          [54.802497, 37.665929],
          [54.802373, 37.665794],
          [54.8023, 37.665654],
          [54.802291, 37.665528],
          [54.802319, 37.665271],
          [54.802395, 37.665062],
          [54.802461, 37.664802],
          [54.802496, 37.664502],
          [54.802399, 37.664209],
          [54.802296, 37.66391],
          [54.802306, 37.663791],
          [54.802357, 37.663725],
          [54.80248, 37.66367],
          [54.802658, 37.663657],
          [54.802805, 37.663602],
          [54.802876, 37.663548],
          [54.802917, 37.663493],
          [54.802899, 37.66332],
          [54.802862, 37.663089],
          [54.802811, 37.6627],
          [54.802829, 37.662619],
          [54.802973, 37.662506],
          [54.803217, 37.662309],
          [54.803354, 37.662086],
          [54.80358, 37.661731],
          [54.80376, 37.661505],
          [54.803957, 37.661384],
          [54.804307, 37.661238],
          [54.804661, 37.661088],
          [54.804727, 37.661033],
          [54.804753, 37.660944],
          [54.804751, 37.660787],
          [54.804711, 37.66064],
          [54.804476, 37.660387],
          [54.804263, 37.660247],
          [54.803889, 37.660056],
          [54.803634, 37.659951],
          [54.803494, 37.659935],
          [54.803324, 37.659971],
          [54.803185, 37.660015],
          [54.803085, 37.660093],
          [54.802899, 37.660183],
          [54.802755, 37.660191],
          [54.802643, 37.660265],
          [54.802526, 37.660369],
          [54.802235, 37.660485],
          [54.801991, 37.660556],
          [54.801766, 37.660578],
          [54.801543, 37.660561],
          [54.801157, 37.660459],
          [54.800701, 37.660276],
          [54.800402, 37.660185],
          [54.799983, 37.66011],
          [54.799646, 37.660023],
          [54.799565, 37.659979],
          [54.799527, 37.659927],
          [54.799531, 37.6598],
          [54.799585, 37.659548],
          [54.799608, 37.659451],
          [54.799589, 37.65934],
          [54.799572, 37.659169],
          [54.799534, 37.659032],
          [54.79941, 37.6588],
          [54.799325, 37.658754],
          [54.799198, 37.65862],
          [54.799025, 37.658497],
          [54.798872, 37.658453],
          [54.798648, 37.658513],
          [54.798305, 37.658585],
          [54.798011, 37.65861],
          [54.797802, 37.658588],
          [54.797509, 37.658486],
          [54.797172, 37.658329],
          [54.797022, 37.658225],
          [54.796992, 37.658116],
          [54.797007, 37.658023],
          [54.797099, 37.657932],
          [54.797261, 37.657794],
          [54.797478, 37.657676],
          [54.797572, 37.6576],
          [54.797616, 37.657491],
          [54.797601, 37.657401],
          [54.797489, 37.657303],
          [54.797261, 37.657246],
          [54.796979, 37.657132],
          [54.796675, 37.657016],
          [54.796318, 37.656891],
          [54.795987, 37.656795],
          [54.795651, 37.656791],
          [54.795255, 37.65677],
          [54.795032, 37.656735],
          [54.794916, 37.656692],
          [54.794853, 37.656623],
          [54.794795, 37.656414],
          [54.7947, 37.656149],
          [54.794623, 37.655867],
          [54.794502, 37.655632],
          [54.79439, 37.655501],
          [54.794362, 37.655393],
          [54.794355, 37.655264],
          [54.794399, 37.655136],
          [54.794571, 37.654921],
          [54.794662, 37.654669],
          [54.79467, 37.654497],
          [54.794596, 37.654223],
          [54.794465, 37.65388],
          [54.794297, 37.65363],
          [54.794131, 37.65357],
          [54.794012, 37.653563],
          [54.793865, 37.653583],
          [54.79366, 37.65367],
          [54.79344, 37.653808],
          [54.793053, 37.65402],
          [54.792589, 37.654214],
          [54.792161, 37.654452],
          [54.791812, 37.654641],
          [54.791696, 37.654649],
          [54.791545, 37.654635],
          [54.791464, 37.654564],
          [54.791435, 37.654461],
          [54.791423, 37.654337],
          [54.791454, 37.654215],
          [54.791567, 37.654092],
          [54.791782, 37.653892],
          [54.791917, 37.653754],
          [54.791983, 37.653606],
          [54.792046, 37.653332],
          [54.792091, 37.653049],
          [54.792208, 37.652853],
          [54.792409, 37.652709],
          [54.79271, 37.652569],
          [54.793172, 37.652381],
          [54.793598, 37.652204],
          [54.794123, 37.652088],
          [54.794386, 37.651966],
          [54.794482, 37.65186],
          [54.794505, 37.651712],
          [54.794453, 37.651513],
          [54.794305, 37.651305],
          [54.794213, 37.651109],
          [54.794196, 37.651],
          [54.79421, 37.65092],
          [54.794298, 37.650823],
          [54.794522, 37.650689],
          [54.794876, 37.650505],
          [54.795005, 37.650411],
          [54.795074, 37.650314],
          [54.795095, 37.650174],
          [54.795081, 37.649925],
          [54.794978, 37.649684],
          [54.794874, 37.649595],
          [54.794654, 37.649531],
          [54.794299, 37.64948],
          [54.793869, 37.649388],
          [54.793549, 37.649311],
          [54.793182, 37.649228],
          [54.792958, 37.649119],
          [54.792651, 37.648919],
          [54.792346, 37.648752],
          [54.792148, 37.648701],
          [54.791934, 37.648687],
          [54.791806, 37.648703],
          [54.791662, 37.648755],
          [54.791551, 37.648838],
          [54.791495, 37.648946],
          [54.791352, 37.649204],
          [54.791232, 37.649466],
          [54.791187, 37.649627],
          [54.791205, 37.649898],
          [54.791298, 37.650023],
          [54.791416, 37.650151],
          [54.791518, 37.650333],
          [54.791576, 37.650525],
          [54.791625, 37.650795],
          [54.791636, 37.651005],
          [54.791603, 37.65119],
          [54.79154, 37.651283],
          [54.791454, 37.651347],
          [54.791311, 37.651377],
          [54.791165, 37.651375],
          [54.790904, 37.651325],
          [54.7908, 37.651268],
          [54.790635, 37.651099],
          [54.7904, 37.650906],
          [54.790123, 37.650645],
          [54.789831, 37.650434],
          [54.789525, 37.650254],
          [54.789185, 37.650058],
          [54.788876, 37.649917],
          [54.788703, 37.649775],
          [54.788549, 37.649573],
          [54.788446, 37.649407],
          [54.78841, 37.649276],
          [54.788393, 37.648981],
          [54.788388, 37.64868],
          [54.788382, 37.648341],
          [54.788321, 37.64788],
          [54.788323, 37.64769],
          [54.788382, 37.647433],
          [54.788505, 37.647293],
          [54.788714, 37.64713],
          [54.788988, 37.646998],
          [54.789343, 37.646855],
          [54.789656, 37.646781],
          [54.790077, 37.646801],
          [54.790606, 37.646857],
          [54.790905, 37.646883],
          [54.791047, 37.646856],
          [54.791217, 37.646768],
          [54.7913, 37.646654],
          [54.791306, 37.646525],
          [54.791264, 37.646348],
          [54.79111, 37.646151],
          [54.790851, 37.645954],
          [54.790556, 37.645838],
          [54.790193, 37.645759],
          [54.789866, 37.645723],
          [54.789468, 37.645676],
          [54.789166, 37.645616],
          [54.789103, 37.645564],
          [54.789068, 37.645444],
          [54.789065, 37.645361],
          [54.789092, 37.645243],
          [54.78921, 37.645028],
          [54.789255, 37.644911],
          [54.789249, 37.644713],
          [54.78919, 37.644615],
          [54.789065, 37.644543],
          [54.788836, 37.644512],
          [54.788625, 37.644539],
          [54.788453, 37.644592],
          [54.78829, 37.644665],
          [54.788141, 37.64481],
          [54.787965, 37.645002],
          [54.787853, 37.645154],
          [54.78766, 37.645531],
          [54.787528, 37.645683],
          [54.78741, 37.64574],
          [54.787278, 37.645755],
          [54.787169, 37.645729],
          [54.787101, 37.645667],
          [54.787035, 37.645565],
          [54.787018, 37.645434],
          [54.786995, 37.645215],
          [54.78691, 37.644926],
          [54.78684, 37.644791],
          [54.786686, 37.644619],
          [54.786483, 37.644458],
          [54.786312, 37.644308],
          [54.786177, 37.64417],
          [54.786029, 37.644063],
          [54.785962, 37.643972],
          [54.78595, 37.643895],
          [54.785971, 37.643813],
          [54.786079, 37.643667],
          [54.786206, 37.643536],
          [54.786338, 37.643487],
          [54.786429, 37.64347],
          [54.786636, 37.643474],
          [54.786895, 37.643495],
          [54.787041, 37.643497],
          [54.787212, 37.643451],
          [54.787319, 37.643384],
          [54.787394, 37.643275],
          [54.787403, 37.64317],
          [54.787401, 37.642973],
          [54.787286, 37.642724],
          [54.787186, 37.642448],
          [54.787095, 37.642212],
          [54.78704, 37.642015],
          [54.787025, 37.641914],
          [54.787036, 37.641783],
          [54.78709, 37.641591],
          [54.787185, 37.641459],
          [54.787385, 37.641326],
          [54.787636, 37.64115],
          [54.787842, 37.64099],
          [54.787923, 37.640925],
          [54.78797, 37.640816],
          [54.787985, 37.640704],
          [54.787958, 37.640556],
          [54.787887, 37.640448],
          [54.787778, 37.640392],
          [54.7876, 37.640368],
          [54.787299, 37.640374],
          [54.787076, 37.640348],
          [54.786467, 37.640226],
          [54.785937, 37.640145],
          [54.785451, 37.640041],
          [54.785074, 37.639918],
          [54.784998, 37.639858],
          [54.784945, 37.639774],
          [54.784932, 37.639697],
          [54.784951, 37.639632],
          [54.785038, 37.639505],
          [54.785153, 37.639427],
          [54.785327, 37.639273],
          [54.785594, 37.63912],
          [54.785848, 37.639022],
          [54.786188, 37.638904],
          [54.786562, 37.638796],
          [54.78681, 37.638735],
          [54.787075, 37.638647],
          [54.787305, 37.638607],
          [54.787559, 37.638599],
          [54.787735, 37.638632],
          [54.787973, 37.638632],
          [54.788151, 37.638607],
          [54.788307, 37.638527],
          [54.788378, 37.638439],
          [54.788414, 37.638294],
          [54.788436, 37.638084],
          [54.788421, 37.637872],
          [54.788291, 37.637704],
          [54.788042, 37.637539],
          [54.787719, 37.637315],
          [54.787515, 37.63715],
          [54.787259, 37.636974],
          [54.787071, 37.636886],
          [54.786917, 37.636852],
          [54.786698, 37.636863],
          [54.786518, 37.636919],
          [54.786215, 37.63708],
          [54.785953, 37.637218],
          [54.785713, 37.637284],
          [54.78545, 37.637317],
          [54.785286, 37.637316],
          [54.785161, 37.63727],
          [54.785058, 37.637166],
          [54.784956, 37.636959],
          [54.78492, 37.636787],
          [54.784881, 37.636539],
          [54.784836, 37.636284],
          [54.784662, 37.635854],
          [54.784513, 37.635701],
          [54.784146, 37.635458],
          [54.784015, 37.635358],
          [54.783785, 37.635185],
          [54.783462, 37.634946],
          [54.783086, 37.63474],
          [54.782874, 37.63458],
          [54.782788, 37.634456],
          [54.782755, 37.63434],
          [54.782717, 37.634044],
          [54.782715, 37.633719],
          [54.782766, 37.633493],
          [54.782889, 37.633395],
          [54.783134, 37.633284],
          [54.78331, 37.633205],
          [54.783428, 37.633178],
          [54.783652, 37.63319],
          [54.783853, 37.633267],
          [54.784068, 37.63335],
          [54.784313, 37.633393],
          [54.784568, 37.633402],
          [54.784806, 37.633361],
          [54.784902, 37.633296],
          [54.784948, 37.633196],
          [54.784912, 37.632891],
          [54.784896, 37.632611],
          [54.784899, 37.632444],
          [54.784966, 37.632265],
          [54.7851, 37.632094],
          [54.785308, 37.631986],
          [54.785541, 37.631943],
          [54.785794, 37.631965],
          [54.786142, 37.632028],
          [54.786411, 37.632086],
          [54.78661, 37.6321],
          [54.786751, 37.632083],
          [54.786931, 37.632028],
          [54.787157, 37.631796],
          [54.786738, 37.631387],
          [54.786466, 37.631106],
          [54.786289, 37.630906],
          [54.786181, 37.630761],
          [54.786138, 37.63066],
          [54.786175, 37.630542],
          [54.786459, 37.630338],
          [54.786719, 37.630222],
          [54.787104, 37.630152],
          [54.78729, 37.630084],
          [54.787753, 37.62985],
          [54.788815, 37.628052],
          [54.787214, 37.625723],
          [54.787373, 37.621623],
          [54.789763, 37.618532],
          [54.792072, 37.618469],
          [54.794541, 37.61727],
          [54.79709, 37.614999],
          [54.799957, 37.616576],
          [54.804576, 37.61645],
          [54.806646, 37.617144],
          [54.807284, 37.615946],
          [54.809752, 37.616829],
          [54.809673, 37.615062],
          [54.811186, 37.613296],
          [54.812221, 37.610142],
          [54.815248, 37.60623],
          [54.813689, 37.600771],
          [54.812221, 37.595629],
          [54.81039, 37.584712],
          [54.808, 37.572026],
          [54.807587, 37.570236],
          [54.805658, 37.567705],
          [54.802858, 37.565147],
          [54.802425, 37.56243],
          [54.80139, 37.557822],
          [54.797966, 37.553655],
          [54.796528, 37.550109],
          [54.796293, 37.541531],
          [54.796213, 37.536479],
          [54.792515, 37.525489],
          [54.782716, 37.520431],
          [54.770583, 37.514509],
          [54.76825, 37.507846],
          [54.770631, 37.503669],
          [54.771572, 37.503122],
          [54.771553, 37.502843],
          [54.770942, 37.502528],
          [54.770537, 37.502327],
          [54.770551, 37.501075],
          [54.770565, 37.500588],
          [54.770505, 37.500114],
          [54.770023, 37.499623],
          [54.769854, 37.499179],
          [54.77003, 37.498486],
          [54.770062, 37.497985],
          [54.769691, 37.497613],
          [54.769183, 37.497681],
          [54.768094, 37.497727],
          [54.766402, 37.498048],
          [54.766031, 37.498321],
          [54.766298, 37.498947],
          [54.7665, 37.499551],
          [54.766213, 37.499903],
          [54.766001, 37.500095],
          [54.764571, 37.500397],
          [54.76356, 37.499795],
          [54.763565, 37.499408],
          [54.763926, 37.498323],
          [54.763917, 37.497475],
          [54.763604, 37.497059],
          [54.76275, 37.496903],
          [54.761817, 37.49681],
          [54.761181, 37.497059],
          [54.759679, 37.49778],
          [54.758781, 37.498197],
          [54.758424, 37.498224],
          [54.758321, 37.498178],
          [54.757929, 37.498084],
          [54.757579, 37.497729],
          [54.757294, 37.497421],
          [54.757266, 37.49717],
          [54.75757, 37.496622],
          [54.75772, 37.496104],
          [54.758061, 37.495534],
          [54.757882, 37.494771],
          [54.757864, 37.494325],
          [54.75815, 37.493813],
          [54.758067, 37.493294],
          [54.757864, 37.492805],
          [54.757597, 37.492556],
          [54.757348, 37.492454],
          [54.756998, 37.492447],
          [54.756593, 37.492644],
          [54.756175, 37.493078],
          [54.755881, 37.493682],
          [54.755663, 37.494193],
          [54.755414, 37.494237],
          [54.755147, 37.494106],
          [54.754663, 37.494012],
          [54.754364, 37.493862],
          [54.754084, 37.493501],
          [54.753843, 37.493067],
          [54.753589, 37.492777],
          [54.753211, 37.492523],
          [54.752552, 37.492252],
          [54.752044, 37.492133],
          [54.751484, 37.492035],
          [54.751423, 37.491954],
          [54.751464, 37.491709],
          [54.751385, 37.491558],
          [54.751249, 37.491472],
          [54.750646, 37.491434],
          [54.750018, 37.491348],
          [54.749634, 37.491601],
          [54.749237, 37.491859],
          [54.748585, 37.492107],
          [54.748195, 37.492531],
          [54.747752, 37.492562],
          [54.747139, 37.492324],
          [54.746636, 37.491991],
          [54.74647, 37.491795],
          [54.746332, 37.491423],
          [54.746194, 37.491265],
          [54.745825, 37.49124],
          [54.745314, 37.491324],
          [54.744614, 37.491532],
          [54.743873, 37.491792],
          [54.743463, 37.492201],
          [54.743262, 37.492585],
          [54.743075, 37.493073],
          [54.742842, 37.493253],
          [54.742213, 37.493351],
          [54.74148, 37.493518],
          [54.741093, 37.493518],
          [54.740623, 37.493509],
          [54.740377, 37.493449],
          [54.740185, 37.493251],
          [54.740076, 37.493056],
          [54.740164, 37.492667],
          [54.740293, 37.492383],
          [54.740445, 37.492173],
          [54.740428, 37.491846],
          [54.740251, 37.491731],
          [54.739916, 37.491636],
          [54.738458, 37.491613],
          [54.737557, 37.49157],
          [54.736953, 37.491606],
          [54.736253, 37.491844],
          [54.735839, 37.492051],
          [54.735304, 37.492239],
          [54.73496, 37.492193],
          [54.734514, 37.491986],
          [54.734144, 37.491849],
          [54.73391, 37.491944],
          [54.733565, 37.492274],
          [54.733329, 37.492491],
          [54.732876, 37.492764],
          [54.732647, 37.492724],
          [54.732571, 37.492517],
          [54.732609, 37.492284],
          [54.732653, 37.491971],
          [54.732641, 37.491647],
          [54.732418, 37.491516],
          [54.732125, 37.491485],
          [54.731551, 37.491546],
          [54.730876, 37.491596],
          [54.730423, 37.491536],
          [54.730003, 37.491389],
          [54.729748, 37.491202],
          [54.729608, 37.491005],
          [54.729474, 37.490651],
          [54.729353, 37.490252],
          [54.72913, 37.489852],
          [54.728805, 37.48967],
          [54.728603, 37.48953],
          [54.728605, 37.489415],
          [54.728949, 37.489183],
          [54.729535, 37.489087],
          [54.730185, 37.489163],
          [54.730516, 37.48937],
          [54.731027, 37.489623],
          [54.731712, 37.489509],
          [54.73219, 37.48938],
          [54.732821, 37.489402],
          [54.733226, 37.489266],
          [54.733424, 37.489137],
          [54.73324, 37.488637],
          [54.733181, 37.488519],
          [54.732902, 37.488401],
          [54.732604, 37.488208],
          [54.732199, 37.4881],
          [54.731658, 37.488022],
          [54.731009, 37.488014],
          [54.730388, 37.488093],
          [54.729748, 37.488157],
          [54.729309, 37.488057],
          [54.728988, 37.487845],
          [54.728467, 37.4878],
          [54.727648, 37.48775],
          [54.727394, 37.487532],
          [54.72756, 37.487193],
          [54.727898, 37.486819],
          [54.728503, 37.486475],
          [54.72886, 37.486293],
          [54.729026, 37.486162],
          [54.729038, 37.48603],
          [54.728892, 37.485853],
          [54.728427, 37.48556],
          [54.728058, 37.485203],
          [54.727624, 37.484969],
          [54.727222, 37.484751],
          [54.72698, 37.484503],
          [54.725802, 37.483093],
          [54.725457, 37.482628],
          [54.725349, 37.482248],
          [54.725158, 37.482021],
          [54.724725, 37.481763],
          [54.724355, 37.481505],
          [54.723852, 37.481379],
          [54.723335, 37.481261],
          [54.723312, 37.481064],
          [54.723561, 37.480869],
          [54.723733, 37.480522],
          [54.723701, 37.480182],
          [54.723507, 37.479921],
          [54.723242, 37.479585],
          [54.723138, 37.479389],
          [54.723115, 37.479142],
          [54.72303, 37.478938],
          [54.722886, 37.478745],
          [54.722678, 37.47867],
          [54.72248, 37.478716],
          [54.722178, 37.478924],
          [54.72182, 37.47932],
          [54.721419, 37.479858],
          [54.721154, 37.479916],
          [54.720891, 37.479783],
          [54.720686, 37.479578],
          [54.720585, 37.479446],
          [54.720533, 37.479204],
          [54.720543, 37.478887],
          [54.720627, 37.478628],
          [54.721155, 37.477889],
          [54.721198, 37.47768],
          [54.721222, 37.477554],
          [54.721139, 37.477424],
          [54.720905, 37.477191],
          [54.720443, 37.476848],
          [54.720004, 37.476662],
          [54.719305, 37.476585],
          [54.718819, 37.476588],
          [54.718612, 37.476513],
          [54.718484, 37.476383],
          [54.718359, 37.476242],
          [54.718331, 37.475866],
          [54.718432, 37.475396],
          [54.718515, 37.475092],
          [54.718687, 37.47498],
          [54.718792, 37.474935],
          [54.718841, 37.474823],
          [54.718722, 37.474707],
          [54.718488, 37.474644],
          [54.718144, 37.474613],
          [54.717775, 37.474762],
          [54.717404, 37.474926],
          [54.716964, 37.474992],
          [54.716681, 37.474919],
          [54.716421, 37.474685],
          [54.716241, 37.474363],
          [54.716285, 37.474107],
          [54.716426, 37.473817],
          [54.716615, 37.473597],
          [54.716911, 37.47317],
          [54.717224, 37.472918],
          [54.717572, 37.472754],
          [54.718162, 37.472568],
          [54.718277, 37.472407],
          [54.718224, 37.472208],
          [54.717985, 37.472055],
          [54.717587, 37.471812],
          [54.717053, 37.471357],
          [54.716991, 37.470991],
          [54.717116, 37.470722],
          [54.716997, 37.47041],
          [54.71647, 37.47014],
          [54.715871, 37.469474],
          [54.715129, 37.46866],
          [54.713614, 37.467244],
          [54.713551, 37.466915],
          [54.712859, 37.466497],
          [54.712433, 37.466115],
          [54.711953, 37.465878],
          [54.710405, 37.464486],
          [54.710032, 37.463991],
          [54.709543, 37.463513],
          [54.708991, 37.462897],
          [54.708663, 37.462745],
          [54.70813, 37.461993],
          [54.707115, 37.459043],
          [54.706427, 37.457915],
          [54.705098, 37.456711],
          [54.704343, 37.455685],
          [54.703651, 37.455116],
          [54.702546, 37.454546],
          [54.7008, 37.453341],
          [54.700232, 37.45249],
          [54.699277, 37.451699],
          [54.69781, 37.450283],
          [54.696497, 37.44961],
          [54.695674, 37.449481],
          [54.691388, 37.448183],
          [54.687676, 37.446858],
          [54.686633, 37.446149],
          [54.684611, 37.444415],
          [54.683701, 37.443065],
          [54.68186, 37.44189],
          [54.678473, 37.43974],
          [54.673668, 37.437055],
          [54.673434, 37.437011],
          [54.672648, 37.437137],
          [54.671349, 37.437047],
          [54.670341, 37.436855],
          [54.669913, 37.436633],
          [54.669347, 37.436345],
          [54.66878, 37.436261],
          [54.668335, 37.436255],
          [54.668055, 37.436351],
          [54.667738, 37.436537],
          [54.667458, 37.436825],
          [54.667171, 37.436987],
          [54.666851, 37.436999],
          [54.666275, 37.436744],
          [54.665566, 37.436319],
          [54.664844, 37.435649],
          [54.664362, 37.435607],
          [54.664094, 37.435681],
          [54.66388, 37.435936],
          [54.663666, 37.436382],
          [54.663438, 37.436935],
          [54.663171, 37.437498],
          [54.662836, 37.437636],
          [54.662542, 37.437509],
          [54.662274, 37.43719],
          [54.662019, 37.436696],
          [54.661944, 37.436628],
          [54.661821, 37.43658],
          [54.661498, 37.436552],
          [54.661096, 37.436701],
          [54.660762, 37.436892],
          [54.660427, 37.43719],
          [54.660219, 37.437621],
          [54.659878, 37.437645],
          [54.65945, 37.437475],
          [54.658961, 37.437066],
          [54.658419, 37.436848],
          [54.658005, 37.4367],
          [54.657443, 37.436551],
          [54.657068, 37.436615],
          [54.656934, 37.436848],
          [54.656961, 37.437103],
          [54.657188, 37.437475],
          [54.657817, 37.438474],
          [54.657831, 37.438825],
          [54.657175, 37.438942],
          [54.656693, 37.43892],
          [54.655997, 37.438889],
          [54.655676, 37.439101],
          [54.655248, 37.439462],
          [54.654953, 37.439919],
          [54.6549, 37.440344],
          [54.655047, 37.440631],
          [54.655408, 37.440801],
          [54.655837, 37.44096],
          [54.655997, 37.441247],
          [54.65589, 37.441513],
          [54.655596, 37.441736],
          [54.655127, 37.4418],
          [54.652481, 37.44188],
          [54.651883, 37.441966],
          [54.651491, 37.442104],
          [54.651317, 37.442176],
          [54.651098, 37.442164],
          [54.650977, 37.442038],
          [54.650969, 37.44184],
          [54.65109, 37.44166],
          [54.651256, 37.441384],
          [54.651219, 37.441096],
          [54.650992, 37.440898],
          [54.649315, 37.440388],
          [54.647925, 37.439927],
          [54.647555, 37.439837],
          [54.64717, 37.439813],
          [54.646958, 37.439879],
          [54.646612, 37.440075],
          [54.64621, 37.440245],
          [54.645825, 37.440352],
          [54.645581, 37.440312],
          [54.645425, 37.440263],
          [54.645372, 37.440071],
          [54.645319, 37.439789],
          [54.645266, 37.439603],
          [54.645055, 37.439567],
          [54.64473, 37.439573],
          [54.644352, 37.439717],
          [54.643982, 37.439939],
          [54.643808, 37.440197],
          [54.643801, 37.440466],
          [54.643952, 37.440928],
          [54.6442, 37.442092],
          [54.644246, 37.442386],
          [54.644125, 37.442722],
          [54.643831, 37.442871],
          [54.643468, 37.442836],
          [54.643166, 37.442602],
          [54.642698, 37.44226],
          [54.64226, 37.442092],
          [54.641776, 37.44208],
          [54.641466, 37.442356],
          [54.641323, 37.442686],
          [54.64127, 37.443075],
          [54.640975, 37.443225],
          [54.640462, 37.443279],
          [54.639744, 37.443231],
          [54.639188, 37.442933],
          [54.638732, 37.442638],
          [54.638029, 37.442116],
          [54.637266, 37.441786],
          [54.636655, 37.441636],
          [54.636111, 37.441612],
          [54.635778, 37.441666],
          [54.635499, 37.44181],
          [54.635416, 37.442056],
          [54.635484, 37.44223],
          [54.635673, 37.442278],
          [54.636383, 37.44244],
          [54.636851, 37.442752],
          [54.637206, 37.443327],
          [54.637455, 37.444035],
          [54.637348, 37.444903],
          [54.637061, 37.445346],
          [54.636636, 37.445643],
          [54.636395, 37.445782],
          [54.636256, 37.445969],
          [54.636241, 37.446099],
          [54.6364, 37.446371],
          [54.636513, 37.44655],
          [54.636629, 37.447062],
          [54.633885, 37.44766],
          [54.631425, 37.448168],
          [54.630909, 37.447918],
          [54.630458, 37.447963],
          [54.630294, 37.448276],
          [54.628656, 37.448559],
          [54.628142, 37.448418],
          [54.627333, 37.448365],
          [54.627047, 37.448233],
          [54.626662, 37.447559],
          [54.626463, 37.447],
          [54.626264, 37.446916],
          [54.625428, 37.447443],
          [54.625122, 37.44739],
          [54.62491, 37.447116],
          [54.624238, 37.446263],
          [54.623583, 37.445947],
          [54.623054, 37.445818],
          [54.622205, 37.445782],
          [54.621618, 37.445863],
          [54.620803, 37.446121],
          [54.620385, 37.446322],
          [54.620067, 37.446589],
          [54.618696, 37.448231],
          [54.615198, 37.449762],
          [54.614805, 37.449945],
          [54.614608, 37.450357],
          [54.613983, 37.4508],
          [54.613562, 37.451322],
          [54.61331, 37.451608],
          [54.613064, 37.452272],
          [54.612881, 37.452626],
          [54.612697, 37.453041],
          [54.612624, 37.453276],
          [54.612378, 37.453804],
          [54.612158, 37.454334],
          [54.612009, 37.454465],
          [54.61199, 37.454639],
          [54.611879, 37.454736],
          [54.611537, 37.454736],
          [54.611164, 37.454819],
          [54.610791, 37.455056],
          [54.610232, 37.455125],
          [54.609747, 37.455145],
          [54.609306, 37.455204],
          [54.608914, 37.455377],
          [54.608486, 37.455611],
          [54.608232, 37.455582],
          [54.607854, 37.455328],
          [54.60756, 37.455205],
          [54.60668, 37.455063],
          [54.606043, 37.455],
          [54.605718, 37.454972],
          [54.605599, 37.454972],
          [54.605476, 37.455055],
          [54.605437, 37.455219],
          [54.605564, 37.455568],
          [54.605604, 37.456043],
          [54.603809, 37.456973],
          [54.602906, 37.457224],
          [54.602218, 37.45732],
          [54.601466, 37.457207],
          [54.600689, 37.456994],
          [54.59962, 37.456772],
          [54.59894, 37.45685],
          [54.598514, 37.457039],
          [54.598222, 37.457355],
          [54.598017, 37.457922],
          [54.597973, 37.458341],
          [54.597712, 37.458578],
          [54.597339, 37.458647],
          [54.596894, 37.458695],
          [54.596739, 37.458823],
          [54.596689, 37.459129],
          [54.596534, 37.459381],
          [54.596267, 37.459401],
          [54.595937, 37.459238],
          [54.595434, 37.458858],
          [54.594887, 37.458488],
          [54.594533, 37.45836],
          [54.593975, 37.458191],
          [54.593756, 37.458365],
          [54.593607, 37.458695],
          [54.593557, 37.458957],
          [54.593569, 37.459307],
          [54.593426, 37.459524],
          [54.59278, 37.459884],
          [54.592494, 37.45985],
          [54.591984, 37.459926],
          [54.591046, 37.459914],
          [54.590207, 37.459756],
          [54.589339, 37.459506],
          [54.588452, 37.459403],
          [54.58776, 37.459424],
          [54.58757, 37.459475],
          [54.58739, 37.459611],
          [54.58727, 37.459837],
          [54.587171, 37.459966],
          [54.586973, 37.460048],
          [54.586762, 37.460031],
          [54.586453, 37.459902],
          [54.586255, 37.459738],
          [54.586216, 37.459492],
          [54.586216, 37.459178],
          [54.586276, 37.458908],
          [54.586388, 37.458683],
          [54.586577, 37.458509],
          [54.586594, 37.458323],
          [54.586381, 37.458145],
          [54.585939, 37.458012],
          [54.585728, 37.457998],
          [54.585436, 37.458134],
          [54.584752, 37.458759],
          [54.584374, 37.458943],
          [54.584, 37.458933],
          [54.582255, 37.458475],
          [54.581701, 37.457763],
          [54.581351, 37.457031],
          [54.581154, 37.456845],
          [54.579707, 37.456464],
          [54.579482, 37.456348],
          [54.579422, 37.455996],
          [54.57958, 37.455803],
          [54.579865, 37.455537],
          [54.579537, 37.454822],
          [54.579057, 37.453456],
          [54.578327, 37.452152],
          [54.578206, 37.451563],
          [54.578413, 37.451148],
          [54.578875, 37.450743],
          [54.578948, 37.450241],
          [54.578748, 37.449996],
          [54.57852, 37.449965],
          [54.578244, 37.44967],
          [54.578244, 37.448849],
          [54.578286, 37.448357],
          [54.578079, 37.448236],
          [54.577624, 37.448029],
          [54.577081, 37.447483],
          [54.57658, 37.447219],
          [54.576336, 37.447207],
          [54.576005, 37.447366],
          [54.575413, 37.447757],
          [54.575103, 37.447815],
          [54.574641, 37.447702],
          [54.574246, 37.447644],
          [54.573846, 37.447733],
          [54.573793, 37.447954],
          [54.573933, 37.448095],
          [54.574109, 37.448275],
          [54.574226, 37.448495],
          [54.574514, 37.44877],
          [54.574641, 37.449033],
          [54.57447, 37.449203],
          [54.574153, 37.449381],
          [54.573929, 37.449432],
          [54.573456, 37.449316],
          [54.573162, 37.449008],
          [54.573125, 37.448875],
          [54.573037, 37.448592],
          [54.572818, 37.448368],
          [54.57238, 37.448352],
          [54.571464, 37.448465],
          [54.570591, 37.448488],
          [54.569712, 37.448321],
          [54.569464, 37.448249],
          [54.569026, 37.448285],
          [54.568899, 37.448397],
          [54.569009, 37.448605],
          [54.569171, 37.448859],
          [54.569485, 37.449032],
          [54.569808, 37.449075],
          [54.570184, 37.449037],
          [54.570429, 37.449117],
          [54.570601, 37.449166],
          [54.57056, 37.449412],
          [54.570405, 37.450109],
          [54.57004, 37.450608],
          [54.569819, 37.45076],
          [54.569131, 37.450862],
          [54.568744, 37.450837],
          [54.568523, 37.450804],
          [54.56844, 37.45071],
          [54.568523, 37.450611],
          [54.568593, 37.450387],
          [54.568465, 37.450157],
          [54.568408, 37.449969],
          [54.568418, 37.449777],
          [54.56827, 37.449719],
          [54.567991, 37.449739],
          [54.567666, 37.449618],
          [54.567121, 37.449603],
          [54.566814, 37.449382],
          [54.566623, 37.449216],
          [54.566566, 37.449082],
          [54.566827, 37.448928],
          [54.567001, 37.44872],
          [54.567136, 37.448411],
          [54.567056, 37.448296],
          [54.566872, 37.448118],
          [54.566617, 37.448031],
          [54.566287, 37.448007],
          [54.565913, 37.448118],
          [54.565551, 37.448134],
          [54.565389, 37.448483],
          [54.565234, 37.448915],
          [54.565054, 37.448982],
          [54.56486, 37.448938],
          [54.564251, 37.448584],
          [54.563214, 37.448192],
          [54.563122, 37.448078],
          [54.563227, 37.447919],
          [54.563419, 37.44776],
          [54.563696, 37.447594],
          [54.563796, 37.447487],
          [54.563776, 37.44738],
          [54.563706, 37.447269],
          [54.563497, 37.447015],
          [54.563337, 37.446627],
          [54.563353, 37.446346],
          [54.56324, 37.445859],
          [54.56306, 37.445234],
          [54.562838, 37.444868],
          [54.562572, 37.444754],
          [54.562449, 37.444726],
          [54.56229, 37.444782],
          [54.561971, 37.445059],
          [54.56182, 37.445242],
          [54.561916, 37.445489],
          [54.56211, 37.445727],
          [54.562311, 37.445951],
          [54.562371, 37.446108],
          [54.562248, 37.446226],
          [54.562112, 37.446266],
          [54.561917, 37.446277],
          [54.56162, 37.446338],
          [54.561218, 37.446355],
          [54.560759, 37.446422],
          [54.560257, 37.446355],
          [54.559844, 37.446226],
          [54.559421, 37.446095],
          [54.558919, 37.446061],
          [54.558605, 37.44603],
          [54.55788, 37.446059],
          [54.557542, 37.446245],
          [54.557322, 37.446523],
          [54.557292, 37.446757],
          [54.557375, 37.446916],
          [54.557361, 37.447061],
          [54.557225, 37.447186],
          [54.557013, 37.447229],
          [54.556708, 37.447178],
          [54.556292, 37.447095],
          [54.556064, 37.446968],
          [54.555758, 37.446757],
          [54.555551, 37.446614],
          [54.555496, 37.446501],
          [54.555514, 37.446374],
          [54.555731, 37.446095],
          [54.556458, 37.445617],
          [54.556672, 37.445324],
          [54.556877, 37.444932],
          [54.557287, 37.444611],
          [54.557581, 37.444373],
          [54.557836, 37.444016],
          [54.557841, 37.443766],
          [54.557748, 37.443681],
          [54.557661, 37.4436],
          [54.557401, 37.443568],
          [54.557137, 37.44362],
          [54.556857, 37.443782],
          [54.556428, 37.444111],
          [54.555489, 37.444698],
          [54.554711, 37.444971],
          [54.554296, 37.4453],
          [54.553901, 37.445867],
          [54.553722, 37.446109],
          [54.553512, 37.446248],
          [54.553124, 37.446387],
          [54.552891, 37.44647],
          [54.552658, 37.44643],
          [54.552474, 37.446313],
          [54.5524, 37.446153],
          [54.552397, 37.445912],
          [54.552389, 37.445559],
          [54.552255, 37.445231],
          [54.55204, 37.444922],
          [54.552026, 37.444693],
          [54.552079, 37.444384],
          [54.55198, 37.444146],
          [54.551708, 37.443958],
          [54.551597, 37.443748],
          [54.551391, 37.443673],
          [54.551071, 37.443708],
          [54.550842, 37.443645],
          [54.550692, 37.443518],
          [54.550702, 37.443284],
          [54.550976, 37.442928],
          [54.550976, 37.442774],
          [54.550921, 37.442643],
          [54.550766, 37.442568],
          [54.550526, 37.442548],
          [54.550307, 37.442572],
          [54.549987, 37.442821],
          [54.549767, 37.443],
          [54.549553, 37.443462],
          [54.549393, 37.443911],
          [54.549233, 37.444034],
          [54.549019, 37.444066],
          [54.548724, 37.443995],
          [54.547634, 37.443364],
          [54.546227, 37.443167],
          [54.545959, 37.443089],
          [54.545839, 37.443002],
          [54.545766, 37.442929],
          [54.54571, 37.442838],
          [54.545682, 37.442717],
          [54.545959, 37.44237],
          [54.546691, 37.442001],
          [54.546708, 37.441794],
          [54.546569, 37.441615],
          [54.546194, 37.441504],
          [54.545645, 37.441457],
          [54.545091, 37.441354],
          [54.544886, 37.441263],
          [54.544727, 37.441037],
          [54.544717, 37.440823],
          [54.544861, 37.440529],
          [54.544851, 37.440216],
          [54.544528, 37.439823],
          [54.543943, 37.439177],
          [54.543498, 37.438626],
          [54.542854, 37.438385],
          [54.5421, 37.438151],
          [54.541736, 37.438044],
          [54.541641, 37.43783],
          [54.541814, 37.437424],
          [54.541876, 37.437152],
          [54.541716, 37.437041],
          [54.541376, 37.437033],
          [54.540752, 37.43708],
          [54.540413, 37.437005],
          [54.540193, 37.436767],
          [54.540008, 37.436426],
          [54.539699, 37.436161],
          [54.539559, 37.43576],
          [54.539309, 37.435558],
          [54.53866, 37.435574],
          [54.538091, 37.435764],
          [54.537599, 37.435963],
          [54.537387, 37.435998],
          [54.537178, 37.435958],
          [54.537063, 37.435728],
          [54.536998, 37.435427],
          [54.536798, 37.435352],
          [54.536548, 37.435411],
          [54.536284, 37.435602],
          [54.535939, 37.435907],
          [54.53572, 37.436347],
          [54.535445, 37.436414],
          [54.53521, 37.436387],
          [54.535131, 37.436264],
          [54.535106, 37.435982],
          [54.535076, 37.435804],
          [54.534886, 37.435669],
          [54.534756, 37.435633],
          [54.534252, 37.435772],
          [54.534017, 37.435768],
          [54.533877, 37.435657],
          [54.533643, 37.435344],
          [54.533388, 37.434884],
          [54.533258, 37.434547],
          [54.533072, 37.43414],
          [54.532917, 37.433961],
          [54.532715, 37.433854],
          [54.532565, 37.433816],
          [54.532255, 37.433849],
          [54.531965, 37.434016],
          [54.531701, 37.43423],
          [54.531518, 37.434297],
          [54.531125, 37.434261],
          [54.530766, 37.434074],
          [54.530451, 37.434003],
          [54.530017, 37.433801],
          [54.529997, 37.433575],
          [54.530127, 37.433317],
          [54.530436, 37.433178],
          [54.530471, 37.432925],
          [54.530401, 37.432722],
          [54.530216, 37.432619],
          [54.529857, 37.43256],
          [54.529547, 37.4326],
          [54.529333, 37.432543],
          [54.529098, 37.432397],
          [54.528843, 37.432152],
          [54.528529, 37.432021],
          [54.528179, 37.431985],
          [54.527635, 37.431981],
          [54.527111, 37.432104],
          [54.526067, 37.432548],
          [54.525708, 37.432806],
          [54.525234, 37.433341],
          [54.524866, 37.433598],
          [54.524545, 37.433733],
          [54.52416, 37.433797],
          [54.523516, 37.43384],
          [54.523147, 37.433805],
          [54.522832, 37.433844],
          [54.522453, 37.434058],
          [54.522218, 37.434328],
          [54.521968, 37.43474],
          [54.521804, 37.434907],
          [54.521489, 37.434978],
          [54.521249, 37.434915],
          [54.521015, 37.434732],
          [54.520878, 37.43448],
          [54.52083, 37.434123],
          [54.52068, 37.433778],
          [54.520221, 37.433453],
          [54.519871, 37.433195],
          [54.519008, 37.432886],
          [54.518445, 37.432678],
          [54.518299, 37.432625],
          [54.518059, 37.432486],
          [54.517934, 37.43218],
          [54.517954, 37.431693],
          [54.517794, 37.431253],
          [54.51753, 37.430983],
          [54.517315, 37.430924],
          [54.516976, 37.430944],
          [54.516646, 37.430995],
          [54.516466, 37.430916],
          [54.516142, 37.430595],
          [54.515299, 37.430822],
          [54.514771, 37.430965],
          [54.51451, 37.43086],
          [54.514622, 37.430425],
          [54.514377, 37.4303],
          [54.51367, 37.430225],
          [54.513139, 37.430021],
          [54.512962, 37.429663],
          [54.512962, 37.429241],
          [54.512929, 37.428652],
          [54.512567, 37.428058],
          [54.512181, 37.427776],
          [54.511786, 37.42777],
          [54.511247, 37.427962],
          [54.510949, 37.428058],
          [54.51078, 37.427911],
          [54.510772, 37.42754],
          [54.510675, 37.426875],
          [54.510425, 37.426446],
          [54.510078, 37.425785],
          [54.509791, 37.425055],
          [54.509484, 37.424309],
          [54.509045, 37.423893],
          [54.508618, 37.4234],
          [54.50818, 37.423088],
          [54.507479, 37.422781],
          [54.507018, 37.422347],
          [54.506806, 37.421677],
          [54.506608, 37.421302],
          [54.50629, 37.420959],
          [54.505942, 37.420728],
          [54.505413, 37.42047],
          [54.505122, 37.420452],
          [54.504758, 37.420511],
          [54.504456, 37.420714],
          [54.504308, 37.421298],
          [54.504173, 37.421481],
          [54.503829, 37.421576],
          [54.503269, 37.421704],
          [54.502778, 37.421842],
          [54.502601, 37.421759],
          [54.50256, 37.421548],
          [54.502593, 37.421017],
          [54.502834, 37.42031],
          [54.502818, 37.420138],
          [54.502822, 37.420016],
          [54.502657, 37.419872],
          [54.502391, 37.419744],
          [54.501993, 37.419738],
          [54.501582, 37.419674],
          [54.501337, 37.419536],
          [54.501123, 37.419329],
          [54.500954, 37.419044],
          [54.500705, 37.418948],
          [54.500362, 37.419054],
          [54.500036, 37.419313],
          [54.49963, 37.419891],
          [54.4994, 37.420045],
          [54.498768, 37.419959],
          [54.497983, 37.42007],
          [54.497372, 37.420463],
          [54.496606, 37.420534],
          [54.495693, 37.420946],
          [54.494581, 37.420956],
          [54.493849, 37.421074],
          [54.493704, 37.421049],
          [54.493647, 37.420927],
          [54.492625, 37.42055],
          [54.492339, 37.420572],
          [54.492114, 37.420566],
          [54.491639, 37.420352],
          [54.491272, 37.42039],
          [54.491031, 37.42016],
          [54.490734, 37.420077],
          [54.490161, 37.419965],
          [54.489493, 37.419674],
          [54.488672, 37.419262],
          [54.488067, 37.418777],
          [54.487407, 37.418244],
          [54.487088, 37.41785],
          [54.486712, 37.417498],
          [54.486615, 37.417037],
          [54.486393, 37.416752],
          [54.486166, 37.416607],
          [54.485634, 37.416487],
          [54.485572, 37.416126],
          [54.485646, 37.415732],
          [54.485503, 37.415529],
          [54.485099, 37.415425],
          [54.484718, 37.41505],
          [54.484701, 37.414837],
          [54.484501, 37.414399],
          [54.48408, 37.414037],
          [54.48342, 37.413281],
          [54.483084, 37.41277],
          [54.482731, 37.412431],
          [54.481774, 37.411911],
          [54.481273, 37.411468],
          [54.480111, 37.410874],
          [54.478871, 37.410102],
          [54.478649, 37.409786],
          [54.478096, 37.409356],
          [54.477242, 37.409051],
          [54.476556, 37.408599],
          [54.476258, 37.408438],
          [54.475961, 37.408404],
          [54.475681, 37.408216],
          [54.475382, 37.4081],
          [54.475223, 37.407933],
          [54.475196, 37.407804],
          [54.475285, 37.407636],
          [54.475155, 37.407385],
          [54.474986, 37.407268],
          [54.474931, 37.407023],
          [54.474769, 37.406956],
          [54.474389, 37.40688],
          [54.474232, 37.406642],
          [54.474033, 37.406503],
          [54.473889, 37.406391],
          [54.473748, 37.406085],
          [54.473541, 37.405883],
          [54.473193, 37.405777],
          [54.473044, 37.405689],
          [54.472963, 37.405395],
          [54.472818, 37.405235],
          [54.472555, 37.405056],
          [54.472389, 37.404677],
          [54.472193, 37.404259],
          [54.471993, 37.404062],
          [54.4717, 37.403874],
          [54.471437, 37.403897],
          [54.471119, 37.403968],
          [54.470937, 37.403797],
          [54.470826, 37.403568],
          [54.470486, 37.403465],
          [54.470145, 37.403536],
          [54.469727, 37.403494],
          [54.469453, 37.403424],
          [54.469064, 37.403471],
          [54.468798, 37.403403],
          [54.468609, 37.4032],
          [54.468312, 37.403065],
          [54.468061, 37.402872],
          [54.467831, 37.402734],
          [54.467483, 37.402728],
          [54.467065, 37.402587],
          [54.466602, 37.402519],
          [54.466398, 37.402534],
          [54.466139, 37.402578],
          [54.465876, 37.402454],
          [54.465321, 37.40229],
          [54.464958, 37.402166],
          [54.464516, 37.402039],
          [54.46377, 37.402129],
          [54.463309, 37.402101],
          [54.462758, 37.401936],
          [54.462268, 37.401736],
          [54.462004, 37.401538],
          [54.46166, 37.401085],
          [54.461433, 37.400417],
          [54.460836, 37.399794],
          [54.460023, 37.399286],
          [54.459299, 37.398983],
          [54.459051, 37.398635],
          [54.458886, 37.398435],
          [54.458283, 37.398176],
          [54.458153, 37.398],
          [54.457846, 37.39735],
          [54.457775, 37.39689],
          [54.45769, 37.396745],
          [54.45749, 37.396697],
          [54.457233, 37.396511],
          [54.456946, 37.396177],
          [54.4567, 37.39613],
          [54.456593, 37.396101],
          [54.456563, 37.396],
          [54.456559, 37.395916],
          [54.456509, 37.395854],
          [54.456316, 37.395836],
          [54.456207, 37.395869],
          [54.456144, 37.395847],
          [54.456123, 37.395781],
          [54.456151, 37.395476],
          [54.456159, 37.395283],
          [54.456103, 37.395148],
          [54.455987, 37.395067],
          [54.455788, 37.395011],
          [54.455597, 37.395028],
          [54.455406, 37.395007],
          [54.455194, 37.39494],
          [54.454963, 37.394955],
          [54.454856, 37.394811],
          [54.454775, 37.394784],
          [54.454573, 37.394764],
          [54.454372, 37.394651],
          [54.454133, 37.394691],
          [54.453939, 37.394665],
          [54.45367, 37.394611],
          [54.453515, 37.394414],
          [54.453396, 37.394396],
          [54.453248, 37.394431],
          [54.453218, 37.39457],
          [54.452941, 37.39469],
          [54.4527, 37.39467],
          [54.45213, 37.394808],
          [54.451915, 37.394734],
          [54.451735, 37.394775],
          [54.451531, 37.394822],
          [54.451338, 37.394826],
          [54.451171, 37.394614],
          [54.450853, 37.394523],
          [54.450471, 37.394439],
          [54.450219, 37.394208],
          [54.449663, 37.39391],
          [54.449501, 37.393655],
          [54.449246, 37.393496],
          [54.449109, 37.393237],
          [54.44859, 37.392796],
          [54.448239, 37.392502],
          [54.448006, 37.392387],
          [54.447857, 37.392205],
          [54.447836, 37.391837],
          [54.447617, 37.391432],
          [54.447513, 37.391384],
          [54.447384, 37.391419],
          [54.447321, 37.391499],
          [54.447124, 37.391513],
          [54.446602, 37.391493],
          [54.446384, 37.391528],
          [54.446206, 37.391484],
          [54.44608, 37.391466],
          [54.445858, 37.391493],
          [54.445684, 37.39139],
          [54.445677, 37.391016],
          [54.445503, 37.390734],
          [54.44521, 37.39039],
          [54.445045, 37.390312],
          [54.444899, 37.390334],
          [54.444699, 37.390396],
          [54.444573, 37.390299],
          [54.444555, 37.390128],
          [54.444218, 37.389763],
          [54.443766, 37.389566],
          [54.443489, 37.389366],
          [54.44327, 37.389178],
          [54.442926, 37.38919],
          [54.442552, 37.389319],
          [54.442241, 37.389396],
          [54.441848, 37.389142],
          [54.44148, 37.389022],
          [54.441206, 37.389054],
          [54.440817, 37.388948],
          [54.440547, 37.389019],
          [54.440395, 37.388913],
          [54.440299, 37.388666],
          [54.439855, 37.388492],
          [54.439332, 37.388348],
          [54.438805, 37.388239],
          [54.438433, 37.388099],
          [54.438064, 37.387806],
          [54.429709, 37.382206],
          [54.418857, 37.374927],
          [54.408209, 37.367778],
          [54.39766, 37.3607],
          [54.372661, 37.354648],
          [54.347644, 37.348629],
          [54.329693, 37.349536],
          [54.307477, 37.350652],
          [54.285193, 37.351769],
          [54.262637, 37.332185],
          [54.249137, 37.323784],
          [54.246327, 37.322923],
          [54.245839, 37.322486],
          [54.245475, 37.321997],
          [54.244678, 37.319986],
          [54.22425, 37.32169],
          [54.219601, 37.322078],
          [54.195082, 37.32413],
          [54.170575, 37.326156],
          [54.165492, 37.326584],
          [54.145775, 37.328244],
          [54.121005, 37.330299],
          [54.097691, 37.332254],
          [54.07393, 37.334223],
          [54.04928, 37.336291],
          [54.030556, 37.342222],
          [54.021944, 37.342778],
          [54.013333, 37.343333],
          [54.004722, 37.343889],
          [53.996111, 37.344722],
          [53.987778, 37.345278],
          [53.970634, 37.346067],
          [53.970649, 37.346037],
          [53.927724, 37.347262],
          [53.896656, 37.347357],
          [53.860757, 37.32222],
          [53.877741, 37.246931],
          [53.862375, 37.203783],
          [53.879775, 37.111224],
          [53.885883, 37.078707],
          [53.897152, 37.018676],
          [53.852669, 36.984442],
        ],
      ],
    },
  },
  Hamadan: {
    properties: { value: "Hamadan", name: "استان همدان, ایران", id: 14 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [47.797194, 34.851164],
          [47.801652, 34.842723],
          [47.800265, 34.834387],
          [47.801932, 34.832177],
          [47.807489, 34.821062],
          [47.811932, 34.816343],
          [47.813599, 34.814395],
          [47.813875, 34.813835],
          [47.848878, 34.764953],
          [47.848878, 34.764674],
          [47.849155, 34.763005],
          [47.851101, 34.759116],
          [47.851378, 34.758557],
          [47.853321, 34.754948],
          [47.854155, 34.754118],
          [47.855822, 34.750779],
          [47.857488, 34.744391],
          [47.857768, 34.744112],
          [47.857768, 34.741053],
          [47.857488, 34.740782],
          [47.857211, 34.738834],
          [47.857488, 34.738283],
          [47.857488, 34.738004],
          [47.857488, 34.737724],
          [47.858045, 34.737173],
          [47.859155, 34.734674],
          [47.862492, 34.730226],
          [47.864992, 34.722999],
          [47.865268, 34.722449],
          [47.863045, 34.694668],
          [47.863878, 34.691339],
          [47.863879, 34.691059],
          [47.864435, 34.688],
          [47.864158, 34.684391],
          [47.863602, 34.682722],
          [47.854712, 34.671894],
          [47.853878, 34.667446],
          [47.853602, 34.661338],
          [47.855545, 34.65356],
          [47.856935, 34.651061],
          [47.856935, 34.650781],
          [47.857212, 34.650501],
          [47.857489, 34.649942],
          [47.857769, 34.649671],
          [47.858602, 34.648281],
          [47.858879, 34.647722],
          [47.865545, 34.633276],
          [47.873045, 34.629387],
          [47.880268, 34.627998],
          [47.883325, 34.627998],
          [47.883882, 34.628277],
          [47.886658, 34.628277],
          [47.890825, 34.629116],
          [47.893882, 34.629117],
          [47.894435, 34.628837],
          [47.895268, 34.628837],
          [47.899438, 34.627727],
          [47.901381, 34.627168],
          [47.909991, 34.627168],
          [47.912214, 34.626888],
          [47.914991, 34.626888],
          [47.916658, 34.626608],
          [47.931381, 34.625498],
          [47.931938, 34.625778],
          [47.935828, 34.625778],
          [47.936938, 34.625498],
          [47.938604, 34.625498],
          [47.939438, 34.625219],
          [47.942494, 34.625219],
          [47.943051, 34.624939],
          [47.954717, 34.622719],
          [47.954994, 34.622169],
          [47.955271, 34.621609],
          [47.968327, 34.607753],
          [47.973327, 34.602444],
          [47.981107, 34.598555],
          [47.981941, 34.598555],
          [47.982217, 34.598275],
          [47.982774, 34.598275],
          [47.984164, 34.597996],
          [47.984441, 34.597725],
          [47.985274, 34.597725],
          [47.986107, 34.597166],
          [47.987217, 34.597165],
          [47.987497, 34.596886],
          [47.991384, 34.594666],
          [47.99305, 34.593276],
          [47.99944, 34.586608],
          [48.001107, 34.587438],
          [48.01333, 34.589387],
          [48.02111, 34.596606],
          [48.021943, 34.596606],
          [48.02222, 34.596326],
          [48.025277, 34.594946],
          [48.033053, 34.587998],
          [48.034443, 34.588557],
          [48.03722, 34.590777],
          [48.03972, 34.597445],
          [48.04, 34.600225],
          [48.03972, 34.601605],
          [48.038053, 34.607723],
          [48.037776, 34.608003],
          [48.042219, 34.623279],
          [48.043053, 34.623279],
          [48.043333, 34.623559],
          [48.044443, 34.623559],
          [48.045, 34.623279],
          [48.045833, 34.623279],
          [48.04611, 34.622999],
          [48.046667, 34.622999],
          [48.04972, 34.6205],
          [48.051666, 34.618],
          [48.052776, 34.61606],
          [48.068123, 34.59543],
          [48.06861, 34.594946],
          [48.06861, 34.594666],
          [48.085556, 34.57606],
          [48.086389, 34.574382],
          [48.087223, 34.571052],
          [48.083333, 34.554945],
          [48.084999, 34.550226],
          [48.097779, 34.538279],
          [48.100836, 34.530771],
          [48.101389, 34.52967],
          [48.101389, 34.525772],
          [48.101389, 34.521052],
          [48.098889, 34.510494],
          [48.09139, 34.498556],
          [48.090556, 34.493557],
          [48.090836, 34.492167],
          [48.097503, 34.479109],
          [48.102503, 34.47328],
          [48.103336, 34.471331],
          [48.103613, 34.471051],
          [48.105003, 34.467441],
          [48.09917, 34.454663],
          [48.095836, 34.450774],
          [48.093889, 34.445774],
          [48.093336, 34.443275],
          [48.091947, 34.434666],
          [48.09139, 34.434106],
          [48.09139, 34.433826],
          [48.090556, 34.432996],
          [48.090556, 34.432157],
          [48.085833, 34.418269],
          [48.083613, 34.416049],
          [48.08028, 34.414379],
          [48.075833, 34.413278],
          [48.074447, 34.413278],
          [48.07389, 34.412998],
          [48.06111, 34.408549],
          [48.057224, 34.401609],
          [48.055834, 34.39883],
          [48.055557, 34.39855],
          [48.047777, 34.390771],
          [48.045834, 34.389941],
          [48.044444, 34.389941],
          [48.044444, 34.389661],
          [48.040834, 34.389661],
          [48.040277, 34.389941],
          [48.034444, 34.390771],
          [48.019444, 34.396881],
          [48.018611, 34.39744],
          [48.017221, 34.39827],
          [48.016664, 34.39827],
          [48.014997, 34.39827],
          [48.014444, 34.39855],
          [48.013611, 34.39855],
          [48.013054, 34.39883],
          [48.001388, 34.40133],
          [47.998559, 34.401453],
          [47.990518, 34.401739],
          [47.990119, 34.401511],
          [47.989713, 34.401307],
          [47.989441, 34.40105],
          [47.988608, 34.397991],
          [47.993629, 34.364661],
          [47.978332, 34.359654],
          [47.977775, 34.359384],
          [47.963328, 34.355214],
          [47.952495, 34.345495],
          [47.950272, 34.339376],
          [47.945272, 34.326886],
          [47.940829, 34.319657],
          [47.916659, 34.297717],
          [47.913882, 34.295768],
          [47.913882, 34.295488],
          [47.906659, 34.281328],
          [47.901106, 34.273268],
          [47.892769, 34.267989],
          [47.891659, 34.267989],
          [47.891659, 34.267709],
          [47.88777, 34.261879],
          [47.886659, 34.255209],
          [47.887216, 34.254659],
          [47.887493, 34.254379],
          [47.887493, 34.254099],
          [47.889993, 34.250769],
          [47.889993, 34.250209],
          [47.900273, 34.238829],
          [47.900273, 34.238549],
          [47.903326, 34.238549],
          [47.904159, 34.238829],
          [47.904993, 34.238829],
          [47.907773, 34.239939],
          [47.908326, 34.239939],
          [47.911383, 34.240769],
          [47.923049, 34.244099],
          [47.931106, 34.245209],
          [47.931662, 34.245489],
          [47.934995, 34.245489],
          [47.935549, 34.245769],
          [47.938606, 34.245769],
          [47.943605, 34.244379],
          [47.944995, 34.244099],
          [47.964719, 34.231329],
          [47.969719, 34.229379],
          [47.970552, 34.229379],
          [47.971108, 34.229379],
          [47.971385, 34.229379],
          [47.972775, 34.229108],
          [47.985275, 34.222159],
          [47.999997, 34.218371],
          [48.000555, 34.220769],
          [48.004722, 34.220769],
          [48.005555, 34.221049],
          [48.007498, 34.221049],
          [48.007775, 34.220769],
          [48.010555, 34.220769],
          [48.011941, 34.22021],
          [48.013055, 34.219659],
          [48.019164, 34.217159],
          [48.021111, 34.217159],
          [48.021665, 34.216879],
          [48.026945, 34.216879],
          [48.027221, 34.217159],
          [48.028331, 34.217159],
          [48.028611, 34.216879],
          [48.038888, 34.216879],
          [48.042778, 34.216329],
          [48.043335, 34.216049],
          [48.044444, 34.216049],
          [48.045278, 34.215769],
          [48.046388, 34.215769],
          [48.046668, 34.215489],
          [48.047778, 34.215489],
          [48.053891, 34.212719],
          [48.054724, 34.2116],
          [48.055834, 34.20993],
          [48.070557, 34.199099],
          [48.072224, 34.195489],
          [48.072224, 34.194659],
          [48.081114, 34.177159],
          [48.081762, 34.176693],
          [48.082224, 34.176328],
          [48.082224, 34.176048],
          [48.090004, 34.170209],
          [48.09167, 34.168548],
          [48.093337, 34.167158],
          [48.100837, 34.153548],
          [48.10806, 34.146598],
          [48.110837, 34.145768],
          [48.112227, 34.145768],
          [48.11306, 34.145488],
          [48.123337, 34.145488],
          [48.123893, 34.145768],
          [48.125837, 34.145768],
          [48.126393, 34.145488],
          [48.127504, 34.145488],
          [48.12806, 34.145217],
          [48.128617, 34.145217],
          [48.12917, 34.145217],
          [48.12945, 34.144937],
          [48.135837, 34.142157],
          [48.136393, 34.141598],
          [48.13695, 34.141598],
          [48.137227, 34.141327],
          [48.137784, 34.141047],
          [48.144727, 34.133818],
          [48.152783, 34.122156],
          [48.153893, 34.120767],
          [48.167507, 34.105766],
          [48.167783, 34.104935],
          [48.168616, 34.104096],
          [48.168896, 34.103265],
          [48.169397, 34.102757],
          [48.17473, 34.091875],
          [48.176397, 34.087434],
          [48.17695, 34.086874],
          [48.17723, 34.086044],
          [48.186119, 34.080493],
          [48.186953, 34.079654],
          [48.188063, 34.079094],
          [48.191673, 34.079094],
          [48.193063, 34.078823],
          [48.214729, 34.067433],
          [48.215286, 34.067153],
          [48.217786, 34.065763],
          [48.230842, 34.054372],
          [48.234453, 34.051321],
          [48.235842, 34.050761],
          [48.240566, 34.049101],
          [48.246122, 34.049101],
          [48.253899, 34.050762],
          [48.255842, 34.051592],
          [48.274179, 34.054092],
          [48.275289, 34.054372],
          [48.286679, 34.054372],
          [48.288069, 34.054652],
          [48.289735, 34.054652],
          [48.290569, 34.054932],
          [48.293068, 34.054932],
          [48.298345, 34.056042],
          [48.299458, 34.056042],
          [48.301125, 34.056602],
          [48.302512, 34.056602],
          [48.303068, 34.056872],
          [48.304458, 34.056602],
          [48.305292, 34.055762],
          [48.305292, 34.055491],
          [48.305568, 34.055212],
          [48.305845, 34.054652],
          [48.305011, 34.04132],
          [48.304735, 34.0391],
          [48.304735, 34.03799],
          [48.303902, 34.028539],
          [48.303625, 34.026869],
          [48.303902, 34.026599],
          [48.304178, 34.022708],
          [48.304735, 34.021318],
          [48.322792, 34.016877],
          [48.323068, 34.016598],
          [48.325015, 34.016598],
          [48.331958, 34.014928],
          [48.332515, 34.014928],
          [48.332791, 34.014648],
          [48.333348, 34.014377],
          [48.352238, 34.006877],
          [48.379184, 34.001316],
          [48.38497, 34.001316],
          [48.388351, 34.001316],
          [48.390851, 34.001316],
          [48.39335, 34.001316],
          [48.394461, 34.001596],
          [48.397794, 34.001596],
          [48.398907, 34.001876],
          [48.40446, 34.001876],
          [48.405574, 34.002156],
          [48.410574, 34.002156],
          [48.41474, 34.002427],
          [48.41585, 34.002706],
          [48.420017, 34.002707],
          [48.443353, 34.008267],
          [48.44474, 34.008267],
          [48.445573, 34.008547],
          [48.45502, 34.008546],
          [48.45613, 34.008817],
          [48.460576, 34.008547],
          [48.470576, 34.006317],
          [48.471409, 34.006317],
          [48.471966, 34.006317],
          [48.472237, 34.006317],
          [48.473633, 34.006317],
          [48.474186, 34.006597],
          [48.47502, 34.006597],
          [48.475299, 34.006877],
          [48.476133, 34.006877],
          [48.481133, 34.009097],
          [48.483076, 34.010207],
          [48.511412, 34.044101],
          [48.519466, 34.050762],
          [48.520299, 34.056873],
          [48.520022, 34.057153],
          [48.519189, 34.060483],
          [48.518912, 34.063263],
          [48.518912, 34.065213],
          [48.518632, 34.066043],
          [48.519189, 34.087435],
          [48.519745, 34.093825],
          [48.521412, 34.100766],
          [48.525855, 34.106597],
          [48.526968, 34.107156],
          [48.534469, 34.107707],
          [48.536412, 34.107436],
          [48.540579, 34.105487],
          [48.541412, 34.105216],
          [48.541412, 34.104936],
          [48.545025, 34.102156],
          [48.545858, 34.101876],
          [48.546969, 34.100486],
          [48.548912, 34.096596],
          [48.548912, 34.095486],
          [48.549468, 34.094105],
          [48.550025, 34.091596],
          [48.552802, 34.077434],
          [48.553079, 34.076315],
          [48.556692, 34.069654],
          [48.559748, 34.067154],
          [48.560302, 34.066594],
          [48.564192, 34.065484],
          [48.565025, 34.065484],
          [48.567248, 34.064933],
          [48.573082, 34.063543],
          [48.574468, 34.063543],
          [48.575581, 34.063263],
          [48.580858, 34.063263],
          [48.581691, 34.063543],
          [48.585858, 34.063543],
          [48.586415, 34.063823],
          [48.587525, 34.063823],
          [48.588638, 34.064383],
          [48.589471, 34.064383],
          [48.599471, 34.070213],
          [48.614194, 34.093545],
          [48.614471, 34.093825],
          [48.617251, 34.096046],
          [48.621971, 34.096316],
          [48.623361, 34.095766],
          [48.624471, 34.095766],
          [48.624751, 34.095486],
          [48.625861, 34.095486],
          [48.633361, 34.089096],
          [48.633917, 34.089096],
          [48.634194, 34.088545],
          [48.646974, 34.082155],
          [48.647454, 34.078367],
          [48.657654, 34.07606],
          [48.659397, 34.079289],
          [48.661897, 34.078729],
          [48.668007, 34.078729],
          [48.66725, 34.078824],
          [48.67225, 34.079095],
          [48.680587, 34.081595],
          [48.68503, 34.083545],
          [48.709477, 34.091876],
          [48.71281, 34.094656],
          [48.715033, 34.097986],
          [48.715586, 34.103267],
          [48.715309, 34.104097],
          [48.715033, 34.105487],
          [48.7117, 34.116318],
          [48.713086, 34.121327],
          [48.713366, 34.122158],
          [48.714753, 34.122708],
          [48.715866, 34.123268],
          [48.716976, 34.123268],
          [48.717533, 34.123548],
          [48.721976, 34.123548],
          [48.722533, 34.123268],
          [48.723643, 34.123268],
          [48.730866, 34.121047],
          [48.747809, 34.097716],
          [48.749199, 34.096046],
          [48.749756, 34.095486],
          [48.750032, 34.095216],
          [48.753089, 34.091876],
          [48.758366, 34.084655],
          [48.759756, 34.080494],
          [48.765589, 34.072434],
          [48.779202, 34.064934],
          [48.780036, 34.064103],
          [48.794479, 34.056873],
          [48.796979, 34.055763],
          [48.804202, 34.052712],
          [48.813925, 34.049932],
          [48.814758, 34.049932],
          [48.840315, 34.037151],
          [48.840872, 34.036601],
          [48.841705, 34.036321],
          [48.843928, 34.033821],
          [48.845872, 34.02604],
          [48.845872, 34.017709],
          [48.846148, 34.017438],
          [48.846148, 34.015488],
          [48.846982, 34.013268],
          [48.847262, 34.012988],
          [48.849205, 34.006047],
          [48.851705, 34.002707],
          [48.854481, 34.001877],
          [48.861148, 34.001047],
          [48.867538, 34.002707],
          [48.868928, 34.002707],
          [48.872538, 34.003547],
          [48.874204, 34.003547],
          [48.878928, 34.004657],
          [48.882818, 34.004657],
          [48.890041, 34.003547],
          [48.891151, 34.003267],
          [48.893651, 34.003267],
          [48.897541, 34.004097],
          [48.898094, 34.004377],
          [48.899208, 34.004377],
          [48.904207, 34.006318],
          [48.910318, 34.011048],
          [48.911707, 34.014099],
          [48.912264, 34.018539],
          [48.911984, 34.019099],
          [48.911707, 34.020209],
          [48.913651, 34.02743],
          [48.91615, 34.02854],
          [48.920321, 34.02854],
          [48.92254, 34.02827],
          [48.928097, 34.026871],
          [48.930597, 34.0241],
          [48.930874, 34.02187],
          [48.941987, 34.013268],
          [48.943377, 34.012149],
          [48.943654, 34.011878],
          [48.94421, 34.011048],
          [48.94421, 34.010208],
          [48.944487, 34.009928],
          [48.944764, 34.006318],
          [48.947544, 34.002987],
          [48.948097, 34.002987],
          [48.948377, 34.002707],
          [48.950044, 34.002708],
          [48.96921, 34.008818],
          [48.969487, 34.009098],
          [48.977823, 34.009098],
          [48.980876, 34.009649],
          [48.981157, 34.009929],
          [48.98199, 34.009929],
          [48.982543, 34.010208],
          [48.983657, 34.010208],
          [48.9856, 34.010768],
          [49.003933, 34.020489],
          [49.011156, 34.02215],
          [49.015879, 34.02215],
          [49.016713, 34.02187],
          [49.017823, 34.02187],
          [49.035326, 34.015489],
          [49.036159, 34.015489],
          [49.036712, 34.015209],
          [49.037826, 34.015209],
          [49.038379, 34.014929],
          [49.038936, 34.015209],
          [49.040879, 34.015209],
          [49.042546, 34.015769],
          [49.043379, 34.016879],
          [49.043379, 34.01715],
          [49.046159, 34.016319],
          [49.046712, 34.016319],
          [49.046712, 34.016039],
          [49.055602, 34.015769],
          [49.061992, 34.014099],
          [49.062269, 34.013819],
          [49.063102, 34.013819],
          [49.063382, 34.013539],
          [49.064216, 34.013269],
          [49.067825, 34.013539],
          [49.068102, 34.013819],
          [49.070049, 34.013819],
          [49.070325, 34.014099],
          [49.073105, 34.014099],
          [49.073939, 34.014379],
          [49.075049, 34.014379],
          [49.075882, 34.01465],
          [49.077572, 34.01471],
          [49.078658, 34.014929],
          [49.079772, 34.014929],
          [49.080325, 34.015209],
          [49.081159, 34.015209],
          [49.091995, 34.02187],
          [49.097548, 34.028821],
          [49.101715, 34.040482],
          [49.103105, 34.044653],
          [49.107828, 34.048263],
          [49.117271, 34.051594],
          [49.121438, 34.053823],
          [49.122551, 34.054654],
          [49.141994, 34.076605],
          [49.147274, 34.080766],
          [49.153664, 34.083826],
          [49.167274, 34.093547],
          [49.171164, 34.099937],
          [49.171441, 34.100208],
          [49.172831, 34.101877],
          [49.173107, 34.102708],
          [49.173457, 34.10313],
          [49.17533, 34.111598],
          [49.175054, 34.112158],
          [49.173664, 34.116319],
          [49.171721, 34.117158],
          [49.168387, 34.117158],
          [49.160607, 34.116048],
          [49.159774, 34.115768],
          [49.157274, 34.115768],
          [49.153107, 34.116319],
          [49.152551, 34.116599],
          [49.150051, 34.116598],
          [49.139494, 34.119099],
          [49.139218, 34.119378],
          [49.138384, 34.119378],
          [49.116718, 34.135219],
          [49.111161, 34.142159],
          [49.110885, 34.14299],
          [49.110328, 34.14327],
          [49.109218, 34.145219],
          [49.108104, 34.14716],
          [49.104495, 34.151329],
          [49.100881, 34.15327],
          [49.100328, 34.15327],
          [49.100048, 34.15355],
          [49.099215, 34.15466],
          [49.098938, 34.15521],
          [49.098381, 34.15549],
          [49.097982, 34.155894],
          [49.093938, 34.16272],
          [49.093661, 34.164101],
          [49.093938, 34.169101],
          [49.094771, 34.17216],
          [49.099771, 34.178821],
          [49.102548, 34.179661],
          [49.105298, 34.180617],
          [49.107271, 34.181042],
          [49.110604, 34.182432],
          [49.111438, 34.182432],
          [49.119771, 34.186322],
          [49.123384, 34.191042],
          [49.124494, 34.198542],
          [49.124218, 34.199102],
          [49.123937, 34.200491],
          [49.123661, 34.201602],
          [49.123661, 34.201881],
          [49.124218, 34.202161],
          [49.124494, 34.202992],
          [49.124217, 34.203271],
          [49.115604, 34.220212],
          [49.115327, 34.221882],
          [49.116161, 34.225771],
          [49.116994, 34.227161],
          [49.118384, 34.229111],
          [49.118661, 34.229382],
          [49.118938, 34.229661],
          [49.123104, 34.237441],
          [49.123937, 34.242992],
          [49.123661, 34.243551],
          [49.122827, 34.246602],
          [49.121994, 34.247992],
          [49.121717, 34.248822],
          [49.119494, 34.251051],
          [49.113384, 34.254661],
          [49.110604, 34.255771],
          [49.109217, 34.255771],
          [49.106161, 34.256602],
          [49.103938, 34.256602],
          [49.103381, 34.256881],
          [49.101161, 34.256881],
          [49.100604, 34.257161],
          [49.099215, 34.257161],
          [49.098661, 34.257441],
          [49.097548, 34.257441],
          [49.096714, 34.257712],
          [49.093381, 34.257991],
          [49.093105, 34.258271],
          [49.091994, 34.258271],
          [49.083938, 34.262991],
          [49.079214, 34.273551],
          [49.078938, 34.27383],
          [49.070048, 34.28355],
          [49.065048, 34.285491],
          [49.064491, 34.285771],
          [49.064215, 34.28605],
          [49.063935, 34.286321],
          [49.050602, 34.290491],
          [49.045045, 34.29772],
          [49.043378, 34.29966],
          [49.040045, 34.30271],
          [49.038935, 34.30299],
          [49.036435, 34.30299],
          [49.036158, 34.30327],
          [49.033102, 34.30327],
          [49.025602, 34.30549],
          [49.020878, 34.309659],
          [49.015602, 34.319388],
          [49.010879, 34.322439],
          [49.008932, 34.324108],
          [49.008932, 34.325769],
          [49.012545, 34.329667],
          [49.014768, 34.333268],
          [49.015879, 34.338828],
          [49.015322, 34.341607],
          [49.015045, 34.343277],
          [49.015602, 34.344107],
          [49.016197, 34.344896],
          [49.009765, 34.354937],
          [49.009489, 34.355776],
          [49.009212, 34.356886],
          [49.009212, 34.361886],
          [49.012265, 34.378274],
          [49.007265, 34.384384],
          [48.992265, 34.389104],
          [48.991709, 34.389383],
          [48.990322, 34.389383],
          [48.989489, 34.389663],
          [48.985599, 34.389663],
          [48.983656, 34.389663],
          [48.975599, 34.386604],
          [48.974766, 34.386604],
          [48.968375, 34.384113],
          [48.964766, 34.383274],
          [48.960876, 34.382723],
          [48.960319, 34.382443],
          [48.958932, 34.382443],
          [48.958376, 34.382723],
          [48.956153, 34.382723],
          [48.954762, 34.382994],
          [48.952542, 34.384113],
          [48.950043, 34.388553],
          [48.949763, 34.389663],
          [48.951152, 34.399103],
          [48.951429, 34.399662],
          [48.952819, 34.401611],
          [48.955596, 34.404382],
          [48.966152, 34.409941],
          [48.977542, 34.41744],
          [48.981989, 34.419389],
          [48.982822, 34.419389],
          [48.984209, 34.41994],
          [48.985875, 34.41994],
          [48.986432, 34.42022],
          [48.987542, 34.42022],
          [48.988099, 34.420499],
          [48.994212, 34.420499],
          [48.994765, 34.420779],
          [48.996155, 34.420779],
          [48.998378, 34.421609],
          [49.000598, 34.42216],
          [49.003655, 34.423829],
          [49.005322, 34.42577],
          [49.007822, 34.431329],
          [49.010878, 34.436048],
          [49.015322, 34.445497],
          [49.015602, 34.447726],
          [49.014768, 34.451606],
          [49.007265, 34.458834],
          [49.007265, 34.459114],
          [49.006712, 34.459385],
          [49.003098, 34.462444],
          [49.003655, 34.465223],
          [49.003655, 34.465783],
          [49.010321, 34.471053],
          [49.011988, 34.472442],
          [49.019768, 34.479391],
          [49.031435, 34.495219],
          [49.033934, 34.496058],
          [49.049211, 34.498829],
          [49.053101, 34.501337],
          [49.054768, 34.505217],
          [49.054768, 34.507437],
          [49.054768, 34.507997],
          [49.053934, 34.510776],
          [49.044768, 34.521884],
          [49.040044, 34.530222],
          [49.039491, 34.53634],
          [49.033934, 34.544389],
          [49.033658, 34.54494],
          [49.033101, 34.54522],
          [49.031711, 34.548558],
          [49.031711, 34.551888],
          [49.031711, 34.554388],
          [49.031435, 34.554667],
          [49.029211, 34.560226],
          [49.028654, 34.560505],
          [49.028378, 34.561336],
          [49.020321, 34.569394],
          [49.019488, 34.569944],
          [49.008655, 34.58606],
          [49.007821, 34.58689],
          [49.005878, 34.590779],
          [49.001431, 34.597168],
          [49.001155, 34.597447],
          [49.002821, 34.599117],
          [49.009764, 34.601607],
          [49.009764, 34.601887],
          [49.011431, 34.601887],
          [49.018654, 34.606056],
          [49.019768, 34.607725],
          [49.020878, 34.609394],
          [49.023934, 34.612164],
          [49.031154, 34.613274],
          [49.031991, 34.613554],
          [49.035601, 34.613554],
          [49.038378, 34.614393],
          [49.039767, 34.614393],
          [49.044211, 34.616333],
          [49.055047, 34.632999],
          [49.060047, 34.634948],
          [49.06199, 34.634948],
          [49.062547, 34.634668],
          [49.06338, 34.634668],
          [49.067267, 34.632999],
          [49.06838, 34.632169],
          [49.070047, 34.62856],
          [49.070047, 34.622722],
          [49.074214, 34.605505],
          [49.074214, 34.593279],
          [49.073937, 34.592449],
          [49.074214, 34.591609],
          [49.073937, 34.590779],
          [49.074214, 34.590229],
          [49.075324, 34.588],
          [49.077547, 34.58606],
          [49.080047, 34.58523],
          [49.080324, 34.58495],
          [49.08088, 34.58495],
          [49.081157, 34.58467],
          [49.082547, 34.58467],
          [49.083104, 34.584391],
          [49.086437, 34.584391],
          [49.08699, 34.58467],
          [49.090047, 34.58467],
          [49.090604, 34.58495],
          [49.09338, 34.58495],
          [49.093937, 34.58523],
          [49.09727, 34.58523],
          [49.099494, 34.585501],
          [49.100327, 34.58578],
          [49.102827, 34.58578],
          [49.105604, 34.586331],
          [49.10616, 34.58661],
          [49.106993, 34.58661],
          [49.118103, 34.596058],
          [49.118937, 34.596329],
          [49.11977, 34.596888],
          [49.120327, 34.597168],
          [49.127826, 34.603006],
          [49.134773, 34.613554],
          [49.135606, 34.618273],
          [49.135326, 34.618833],
          [49.13505, 34.620502],
          [49.134773, 34.622171],
          [49.13505, 34.628],
          [49.135326, 34.629119],
          [49.132826, 34.636617],
          [49.13505, 34.648004],
          [49.133659, 34.655223],
          [49.133659, 34.659951],
          [49.133383, 34.660501],
          [49.133106, 34.66356],
          [49.132826, 34.665229],
          [49.13255, 34.666889],
          [49.132273, 34.668279],
          [49.131993, 34.669389],
          [49.131716, 34.671617],
          [49.131716, 34.673557],
          [49.131716, 34.674117],
          [49.131439, 34.674396],
          [49.131439, 34.676066],
          [49.131159, 34.676616],
          [49.128106, 34.686063],
          [49.124493, 34.694391],
          [49.123936, 34.69523],
          [49.121159, 34.699669],
          [49.105603, 34.72745],
          [49.105046, 34.728009],
          [49.102546, 34.737726],
          [49.102827, 34.750511],
          [49.103103, 34.753561],
          [49.10338, 34.75551],
          [49.10727, 34.764676],
          [49.10727, 34.764956],
          [49.108659, 34.766336],
          [49.108936, 34.766616],
          [49.115326, 34.772733],
          [49.139216, 34.788558],
          [49.142549, 34.791066],
          [49.149216, 34.797174],
          [49.154495, 34.80662],
          [49.157549, 34.828562],
          [49.158662, 34.8319],
          [49.158662, 34.83273],
          [49.161996, 34.839117],
          [49.163939, 34.841896],
          [49.167272, 34.846894],
          [49.167829, 34.849122],
          [49.168105, 34.849402],
          [49.163662, 34.85662],
          [49.162829, 34.858839],
          [49.163105, 34.860788],
          [49.168105, 34.866065],
          [49.169219, 34.866065],
          [49.169772, 34.866345],
          [49.175052, 34.866345],
          [49.177829, 34.865515],
          [49.178662, 34.865786],
          [49.178938, 34.865786],
          [49.180885, 34.864676],
          [49.191718, 34.862177],
          [49.192828, 34.862177],
          [49.195608, 34.861347],
          [49.196441, 34.861347],
          [49.201718, 34.858568],
          [49.202551, 34.857729],
          [49.204218, 34.853011],
          [49.204498, 34.851342],
          [49.205051, 34.848283],
          [49.205051, 34.848004],
          [49.205608, 34.847174],
          [49.205885, 34.846064],
          [49.206718, 34.843836],
          [49.209498, 34.842176],
          [49.211718, 34.842176],
          [49.218384, 34.846064],
          [49.218665, 34.846624],
          [49.225608, 34.850512],
          [49.229498, 34.853011],
          [49.233388, 34.861347],
          [49.233388, 34.861627],
          [49.234774, 34.863837],
          [49.253111, 34.883847],
          [49.254221, 34.884677],
          [49.267554, 34.889395],
          [49.26811, 34.889395],
          [49.268668, 34.889395],
          [49.269777, 34.889954],
          [49.270611, 34.889955],
          [49.270887, 34.890234],
          [49.271721, 34.890234],
          [49.273111, 34.890784],
          [49.273667, 34.891064],
          [49.292557, 34.906617],
          [49.29311, 34.906897],
          [49.29339, 34.907456],
          [49.296823, 34.908906],
          [49.298114, 34.909396],
          [49.303667, 34.911904],
          [49.3045, 34.911904],
          [49.305614, 34.912454],
          [49.306167, 34.912454],
          [49.307, 34.912454],
          [49.312557, 34.913564],
          [49.313947, 34.913564],
          [49.314224, 34.913843],
          [49.315613, 34.913843],
          [49.31589, 34.914123],
          [49.317557, 34.914123],
          [49.31839, 34.914403],
          [49.32117, 34.914403],
          [49.324223, 34.914953],
          [49.32478, 34.915233],
          [49.32589, 34.915232],
          [49.326446, 34.915512],
          [49.327837, 34.915512],
          [49.328946, 34.916063],
          [49.33117, 34.916062],
          [49.331723, 34.916342],
          [49.337836, 34.916342],
          [49.338393, 34.916622],
          [49.340059, 34.916622],
          [49.340893, 34.916342],
          [49.343393, 34.916342],
          [49.344226, 34.916062],
          [49.347003, 34.916063],
          [49.347836, 34.915783],
          [49.349503, 34.915783],
          [49.355893, 34.914682],
          [49.356726, 34.914403],
          [49.358393, 34.914403],
          [49.365893, 34.912734],
          [49.367836, 34.912454],
          [49.369503, 34.911904],
          [49.371169, 34.911904],
          [49.371449, 34.911624],
          [49.375339, 34.911624],
          [49.375893, 34.911904],
          [49.377839, 34.911904],
          [49.382283, 34.913004],
          [49.382559, 34.913284],
          [49.383393, 34.913284],
          [49.388116, 34.915233],
          [49.389782, 34.917452],
          [49.392006, 34.926067],
          [49.392282, 34.926897],
          [49.392006, 34.927456],
          [49.391729, 34.929116],
          [49.391449, 34.930235],
          [49.391172, 34.931624],
          [49.390896, 34.933564],
          [49.390616, 34.933843],
          [49.390339, 34.935233],
          [49.389782, 34.937452],
          [49.389506, 34.938571],
          [49.389226, 34.93968],
          [49.388949, 34.94079],
          [49.388673, 34.94162],
          [49.388392, 34.943289],
          [49.388949, 34.945228],
          [49.396729, 34.948007],
          [49.398672, 34.948846],
          [49.399506, 34.948846],
          [49.402282, 34.950235],
          [49.407282, 34.956071],
          [49.407562, 34.957452],
          [49.407839, 34.958011],
          [49.407839, 34.958291],
          [49.407839, 34.96051],
          [49.407562, 34.960789],
          [49.407282, 34.962738],
          [49.407005, 34.964957],
          [49.404782, 34.975791],
          [49.405062, 34.977731],
          [49.407562, 34.983567],
          [49.412839, 34.998289],
          [49.414228, 35.001068],
          [49.414228, 35.001627],
          [49.405895, 35.008843],
          [49.405062, 35.009682],
          [49.403948, 35.009953],
          [49.403672, 35.010233],
          [49.402839, 35.010233],
          [49.402562, 35.010512],
          [49.401729, 35.010512],
          [49.398672, 35.011901],
          [49.395896, 35.01274],
          [49.394782, 35.01274],
          [49.393116, 35.013291],
          [49.391172, 35.013291],
          [49.390896, 35.01357],
          [49.380059, 35.013841],
          [49.379226, 35.014121],
          [49.378392, 35.013841],
          [49.376449, 35.013841],
          [49.370336, 35.012181],
          [49.368392, 35.011622],
          [49.364782, 35.011351],
          [49.363669, 35.011072],
          [49.357836, 35.011071],
          [49.355616, 35.011901],
          [49.348393, 35.013291],
          [49.342279, 35.013841],
          [49.341169, 35.01357],
          [49.322003, 35.013841],
          [49.312556, 35.01274],
          [49.306723, 35.011622],
          [49.30589, 35.011622],
          [49.29728, 35.007734],
          [49.29339, 35.006345],
          [49.292557, 35.006065],
          [49.288943, 35.006065],
          [49.284223, 35.007734],
          [49.282833, 35.007734],
          [49.275887, 35.012181],
          [49.26561, 35.029401],
          [49.263944, 35.0319],
          [49.259777, 35.042183],
          [49.260887, 35.051619],
          [49.290056, 35.075793],
          [49.290333, 35.076073],
          [49.293943, 35.078571],
          [49.304223, 35.084398],
          [49.306446, 35.084957],
          [49.323946, 35.083298],
          [49.324223, 35.083018],
          [49.325056, 35.083018],
          [49.328113, 35.08107],
          [49.328113, 35.080511],
          [49.328389, 35.08024],
          [49.328946, 35.078012],
          [49.329503, 35.078012],
          [49.329779, 35.077732],
          [49.332836, 35.077732],
          [49.335613, 35.077462],
          [49.336726, 35.075513],
          [49.337836, 35.073015],
          [49.338393, 35.072455],
          [49.340613, 35.071626],
          [49.341726, 35.071626],
          [49.342003, 35.071905],
          [49.342836, 35.071905],
          [49.348946, 35.074404],
          [49.351726, 35.074404],
          [49.352002, 35.074124],
          [49.352559, 35.074124],
          [49.355059, 35.071626],
          [49.355336, 35.070516],
          [49.356449, 35.069127],
          [49.356726, 35.068568],
          [49.356449, 35.06385],
          [49.354782, 35.061072],
          [49.354502, 35.059403],
          [49.355336, 35.058294],
          [49.355616, 35.057464],
          [49.362836, 35.053567],
          [49.364226, 35.053567],
          [49.365059, 35.053288],
          [49.368116, 35.053287],
          [49.372006, 35.052737],
          [49.379506, 35.048849],
          [49.383672, 35.048849],
          [49.384225, 35.04857],
          [49.386449, 35.04857],
          [49.390615, 35.04746],
          [49.404229, 35.036627],
          [49.407005, 35.034678],
          [49.407562, 35.034678],
          [49.407838, 35.034399],
          [49.408949, 35.034399],
          [49.409505, 35.034119],
          [49.411172, 35.034119],
          [49.415062, 35.036627],
          [49.416172, 35.038007],
          [49.417005, 35.039955],
          [49.417285, 35.043573],
          [49.417562, 35.044953],
          [49.420618, 35.054126],
          [49.441175, 35.079401],
          [49.445341, 35.087177],
          [49.447285, 35.091903],
          [49.450341, 35.106903],
          [49.453675, 35.118575],
          [49.453951, 35.119676],
          [49.462841, 35.138293],
          [49.463118, 35.142739],
          [49.463397, 35.145517],
          [49.464508, 35.149405],
          [49.465897, 35.161626],
          [49.467841, 35.16941],
          [49.468121, 35.17024],
          [49.467841, 35.170799],
          [49.466174, 35.179124],
          [49.457008, 35.188017],
          [49.456731, 35.188297],
          [49.454231, 35.188567],
          [49.453951, 35.188847],
          [49.451174, 35.188847],
          [49.450898, 35.189127],
          [49.448951, 35.189127],
          [49.448118, 35.189406],
          [49.446451, 35.189406],
          [49.443951, 35.190236],
          [49.441451, 35.192743],
          [49.438674, 35.19857],
          [49.438394, 35.199959],
          [49.438118, 35.202187],
          [49.437841, 35.204965],
          [49.434508, 35.213848],
          [49.426451, 35.221912],
          [49.426171, 35.222182],
          [49.422285, 35.22413],
          [49.417838, 35.225799],
          [49.415895, 35.226069],
          [49.415338, 35.226349],
          [49.414228, 35.226349],
          [49.413395, 35.226629],
          [49.411451, 35.226629],
          [49.411172, 35.226908],
          [49.407838, 35.226908],
          [49.407281, 35.226629],
          [49.403671, 35.226628],
          [49.403115, 35.226349],
          [49.402005, 35.226349],
          [49.401448, 35.226069],
          [49.398948, 35.226069],
          [49.397005, 35.225799],
          [49.395895, 35.225519],
          [49.387838, 35.225519],
          [49.382005, 35.227179],
          [49.370335, 35.234962],
          [49.369225, 35.23691],
          [49.369225, 35.238849],
          [49.369225, 35.239139],
          [49.369225, 35.240238],
          [49.375892, 35.251629],
          [49.376448, 35.259133],
          [49.374782, 35.263299],
          [49.373672, 35.264959],
          [49.362282, 35.270523],
          [49.358668, 35.271353],
          [49.358115, 35.271353],
          [49.351725, 35.277188],
          [49.351445, 35.278018],
          [49.350892, 35.279686],
          [49.351169, 35.279966],
          [49.350612, 35.281355],
          [49.352558, 35.293575],
          [49.355615, 35.29969],
          [49.360059, 35.304965],
          [49.361168, 35.306634],
          [49.382558, 35.336079],
          [49.402561, 35.356911],
          [49.403394, 35.357749],
          [49.403394, 35.35802],
          [49.407004, 35.363855],
          [49.407281, 35.365523],
          [49.407004, 35.366912],
          [49.396171, 35.379131],
          [49.394228, 35.383018],
          [49.394504, 35.383856],
          [49.395338, 35.386354],
          [49.398394, 35.387743],
          [49.398948, 35.388023],
          [49.405338, 35.391639],
          [49.416171, 35.403858],
          [49.427561, 35.412749],
          [49.428674, 35.414408],
          [49.428951, 35.414968],
          [49.430894, 35.418854],
          [49.432284, 35.419413],
          [49.432284, 35.419684],
          [49.428394, 35.426636],
          [49.421728, 35.430523],
          [49.420894, 35.430523],
          [49.420061, 35.431073],
          [49.418951, 35.431073],
          [49.418671, 35.431361],
          [49.416171, 35.431912],
          [49.414784, 35.431911],
          [49.414227, 35.431632],
          [49.411451, 35.431361],
          [49.411451, 35.431073],
          [49.405614, 35.429414],
          [49.398948, 35.425798],
          [49.396171, 35.425248],
          [49.391171, 35.425248],
          [49.390614, 35.425527],
          [49.377558, 35.429693],
          [49.368668, 35.431632],
          [49.366448, 35.432191],
          [49.365892, 35.43247],
          [49.363668, 35.432741],
          [49.349778, 35.436916],
          [49.344778, 35.438025],
          [49.330612, 35.444969],
          [49.311722, 35.459694],
          [49.309779, 35.460523],
          [49.308665, 35.460803],
          [49.299222, 35.464139],
          [49.298665, 35.464689],
          [49.297832, 35.464689],
          [49.292275, 35.466907],
          [49.290609, 35.466907],
          [49.290056, 35.467187],
          [49.287275, 35.467187],
          [49.286722, 35.467466],
          [49.283942, 35.467466],
          [49.281999, 35.468026],
          [49.280889, 35.468026],
          [49.280056, 35.468576],
          [49.278942, 35.468576],
          [49.269219, 35.47275],
          [49.267276, 35.473021],
          [49.266719, 35.472751],
          [49.265609, 35.473021],
          [49.260053, 35.472751],
          [49.259499, 35.473021],
          [49.259219, 35.472751],
          [49.256996, 35.47275],
          [49.252276, 35.472471],
          [49.246163, 35.472471],
          [49.245609, 35.47275],
          [49.22283, 35.478025],
          [49.210883, 35.481082],
          [49.20783, 35.481082],
          [49.206997, 35.481641],
          [49.205606, 35.481912],
          [49.204773, 35.482471],
          [49.196993, 35.486078],
          [49.19616, 35.486078],
          [49.19505, 35.486637],
          [49.194217, 35.486637],
          [49.182827, 35.489973],
          [49.17977, 35.490523],
          [49.178103, 35.490523],
          [49.17755, 35.490803],
          [49.175603, 35.491073],
          [49.168937, 35.490802],
          [49.16866, 35.491073],
          [49.16755, 35.491073],
          [49.16727, 35.491361],
          [49.16088, 35.4933],
          [49.160327, 35.49358],
          [49.160047, 35.493859],
          [49.152547, 35.501911],
          [49.15227, 35.503029],
          [49.151994, 35.504409],
          [49.150327, 35.508863],
          [49.145327, 35.513859],
          [49.135324, 35.520801],
          [49.13477, 35.521919],
          [49.134214, 35.522469],
          [49.132547, 35.529692],
          [49.132271, 35.529971],
          [49.132547, 35.530801],
          [49.137824, 35.553856],
          [49.133937, 35.562746],
          [49.130047, 35.566921],
          [49.129214, 35.567191],
          [49.127271, 35.568859],
          [49.122824, 35.571357],
          [49.092267, 35.587469],
          [49.091158, 35.587469],
          [49.084211, 35.589137],
          [49.082821, 35.589137],
          [49.082544, 35.589416],
          [49.081988, 35.589416],
          [49.081434, 35.589696],
          [49.079488, 35.590255],
          [49.078378, 35.590255],
          [49.073101, 35.594141],
          [49.071711, 35.59525],
          [49.064211, 35.60386],
          [49.061988, 35.604969],
          [49.061431, 35.605248],
          [49.060677, 35.605501],
          [49.060321, 35.605528],
          [49.047541, 35.617194],
          [49.033931, 35.623307],
          [49.030318, 35.623866],
          [49.028375, 35.623866],
          [49.027818, 35.623578],
          [49.021985, 35.622748],
          [49.020875, 35.623028],
          [49.018932, 35.623028],
          [49.015318, 35.623866],
          [49.012262, 35.625246],
          [49.009209, 35.628302],
          [49.006985, 35.630529],
          [48.988652, 35.643584],
          [48.947539, 35.66303],
          [48.946706, 35.66303],
          [48.946426, 35.66331],
          [48.943926, 35.664418],
          [48.933092, 35.669972],
          [48.931983, 35.669972],
          [48.931149, 35.67039],
          [48.930869, 35.670531],
          [48.930036, 35.67081],
          [48.89337, 35.693862],
          [48.891703, 35.69553],
          [48.890589, 35.69608],
          [48.883923, 35.699416],
          [48.88309, 35.699416],
          [48.882813, 35.699695],
          [48.8742, 35.701084],
          [48.87198, 35.701084],
          [48.870313, 35.701363],
          [48.867813, 35.701363],
          [48.861423, 35.702472],
          [48.859477, 35.702472],
          [48.851423, 35.703869],
          [48.843924, 35.705248],
          [48.843087, 35.705248],
          [48.842534, 35.705528],
          [48.8417, 35.705528],
          [48.81892, 35.71164],
          [48.801697, 35.721358],
          [48.790864, 35.730526],
          [48.779751, 35.733582],
          [48.775031, 35.733862],
          [48.773084, 35.733862],
          [48.771974, 35.733582],
          [48.769198, 35.733582],
          [48.768084, 35.733303],
          [48.765584, 35.733032],
          [48.762808, 35.733032],
          [48.762251, 35.732753],
          [48.760861, 35.732753],
          [48.760584, 35.732474],
          [48.758918, 35.732474],
          [48.758361, 35.732194],
          [48.752528, 35.731085],
          [48.748641, 35.729417],
          [48.741971, 35.727191],
          [48.736971, 35.724414],
          [48.736695, 35.724144],
          [48.736324, 35.723958],
          [48.735028, 35.722746],
          [48.735028, 35.722476],
          [48.728915, 35.711919],
          [48.727528, 35.701913],
          [48.727805, 35.701083],
          [48.732805, 35.691644],
          [48.739195, 35.686641],
          [48.743638, 35.685253],
          [48.745861, 35.684973],
          [48.746695, 35.684694],
          [48.753085, 35.684135],
          [48.761975, 35.683585],
          [48.763084, 35.683026],
          [48.766694, 35.682196],
          [48.770031, 35.678581],
          [48.769198, 35.669142],
          [48.769751, 35.666365],
          [48.770031, 35.662471],
          [48.770308, 35.661912],
          [48.769751, 35.649975],
          [48.769198, 35.64581],
          [48.768918, 35.644972],
          [48.768918, 35.634414],
          [48.774198, 35.618862],
          [48.776698, 35.614138],
          [48.775031, 35.601362],
          [48.772251, 35.596917],
          [48.768918, 35.592193],
          [48.768365, 35.591354],
          [48.753085, 35.570247],
          [48.725582, 35.554964],
          [48.725028, 35.554875],
          [48.723915, 35.554694],
          [48.723362, 35.554414],
          [48.716416, 35.552187],
          [48.710306, 35.551078],
          [48.707805, 35.551078],
          [48.707249, 35.551358],
          [48.705582, 35.551358],
          [48.705025, 35.551637],
          [48.703916, 35.551637],
          [48.698639, 35.553026],
          [48.697249, 35.554414],
          [48.696692, 35.555523],
          [48.696139, 35.555803],
          [48.695859, 35.557191],
          [48.695582, 35.55803],
          [48.695306, 35.5583],
          [48.687802, 35.57719],
          [48.685582, 35.580805],
          [48.685302, 35.581085],
          [48.674749, 35.59441],
          [48.669469, 35.597187],
          [48.663913, 35.598864],
          [48.65058, 35.600803],
          [48.64808, 35.600803],
          [48.647246, 35.601082],
          [48.64419, 35.601082],
          [48.643356, 35.601362],
          [48.638356, 35.601362],
          [48.637246, 35.601082],
          [48.634466, 35.601082],
          [48.633356, 35.600803],
          [48.626133, 35.600803],
          [48.62558, 35.601082],
          [48.623913, 35.601082],
          [48.62308, 35.601362],
          [48.618633, 35.601632],
          [48.612523, 35.602471],
          [48.610856, 35.602471],
          [48.610023, 35.60275],
          [48.6078, 35.603021],
          [48.5953, 35.601362],
          [48.590577, 35.599694],
          [48.588633, 35.599694],
          [48.585585, 35.598585],
          [48.58502, 35.598305],
          [48.584467, 35.597746],
          [48.58002, 35.595249],
          [48.579187, 35.595249],
          [48.551407, 35.586359],
          [48.550297, 35.586359],
          [48.549741, 35.586079],
          [48.548907, 35.586079],
          [48.548631, 35.5858],
          [48.545021, 35.58525],
          [48.543074, 35.58525],
          [48.542797, 35.58497],
          [48.538074, 35.58497],
          [48.536407, 35.585529],
          [48.525574, 35.588306],
          [48.521128, 35.590254],
          [48.496128, 35.598864],
          [48.495018, 35.599973],
          [48.498351, 35.61997],
          [48.498071, 35.621359],
          [48.497795, 35.621918],
          [48.495851, 35.627751],
          [48.495295, 35.629689],
          [48.492518, 35.635252],
          [48.491961, 35.635523],
          [48.490571, 35.63747],
          [48.484181, 35.644691],
          [48.483904, 35.644971],
          [48.483628, 35.64525],
          [48.481681, 35.645809],
          [48.481128, 35.645809],
          [48.471961, 35.649975],
          [48.468071, 35.650525],
          [48.462238, 35.652192],
          [48.461681, 35.652751],
          [48.461125, 35.652751],
          [48.458071, 35.65414],
          [48.456958, 35.65414],
          [48.455848, 35.65469],
          [48.455015, 35.65469],
          [48.452792, 35.655249],
          [48.451405, 35.655249],
          [48.450848, 35.655528],
          [48.448905, 35.655799],
          [48.445015, 35.655799],
          [48.444735, 35.656087],
          [48.442515, 35.656087],
          [48.442235, 35.655799],
          [48.441402, 35.655799],
          [48.439182, 35.654969],
          [48.438068, 35.654969],
          [48.435015, 35.65414],
          [48.425845, 35.647468],
          [48.421678, 35.644691],
          [48.414179, 35.640806],
          [48.401679, 35.642473],
          [48.395289, 35.642473],
          [48.394736, 35.642753],
          [48.391679, 35.643023],
          [48.357509, 35.657475],
          [48.353066, 35.659693],
          [48.347509, 35.662199],
          [48.343343, 35.662199],
          [48.342233, 35.662199],
          [48.341399, 35.662749],
          [48.340566, 35.662749],
          [48.340286, 35.663029],
          [48.339733, 35.663029],
          [48.333063, 35.665526],
          [48.328063, 35.666635],
          [48.326396, 35.667473],
          [48.32501, 35.667753],
          [48.312506, 35.672188],
          [48.311953, 35.672747],
          [48.29612, 35.680528],
          [48.288063, 35.688028],
          [48.286116, 35.690246],
          [48.27056, 35.707195],
          [48.268893, 35.708033],
          [48.252227, 35.719419],
          [48.25195, 35.719689],
          [48.25167, 35.720248],
          [48.251117, 35.719978],
          [48.250004, 35.719419],
          [48.24917, 35.719419],
          [48.211114, 35.687469],
          [48.206391, 35.685801],
          [48.206114, 35.685531],
          [48.205281, 35.685531],
          [48.204724, 35.685252],
          [48.187225, 35.680248],
          [48.186391, 35.679698],
          [48.167778, 35.673306],
          [48.166668, 35.673306],
          [48.164445, 35.672747],
          [48.153055, 35.672747],
          [48.151945, 35.673027],
          [48.150279, 35.673027],
          [48.149168, 35.673306],
          [48.147779, 35.673306],
          [48.146668, 35.673586],
          [48.143889, 35.673856],
          [48.138332, 35.673856],
          [48.138055, 35.674135],
          [48.130278, 35.674135],
          [48.125275, 35.673585],
          [48.124722, 35.673585],
          [48.124165, 35.673306],
          [48.123332, 35.673306],
          [48.123055, 35.673026],
          [48.120555, 35.672188],
          [48.118609, 35.670809],
          [48.107499, 35.658025],
          [48.096942, 35.651912],
          [48.092499, 35.651912],
          [48.091943, 35.652192],
          [48.089999, 35.652192],
          [48.089443, 35.652471],
          [48.088332, 35.652471],
          [48.088052, 35.652751],
          [48.086662, 35.65303],
          [48.083609, 35.652192],
          [48.081663, 35.651912],
          [48.076663, 35.650244],
          [48.075553, 35.649415],
          [48.063606, 35.643861],
          [48.055553, 35.641075],
          [48.052219, 35.640525],
          [48.050347, 35.639642],
          [48.049163, 35.639137],
          [48.046383, 35.637189],
          [48.03944, 35.633863],
          [48.033883, 35.629968],
          [48.032496, 35.629688],
          [48.030827, 35.62747],
          [48.022493, 35.621637],
          [48.01055, 35.614974],
          [48.00555, 35.613586],
          [47.999192, 35.604671],
          [47.997738, 35.599653],
          [47.996937, 35.596915],
          [47.98416, 35.579695],
          [47.974158, 35.574132],
          [47.971104, 35.573302],
          [47.967771, 35.572473],
          [47.966657, 35.572473],
          [47.963324, 35.571634],
          [47.961658, 35.571634],
          [47.961104, 35.571355],
          [47.956938, 35.571084],
          [47.935825, 35.572193],
          [47.935825, 35.572472],
          [47.922491, 35.57552],
          [47.921101, 35.57552],
          [47.920825, 35.575799],
          [47.919711, 35.575799],
          [47.919158, 35.576079],
          [47.917211, 35.576079],
          [47.916935, 35.576358],
          [47.914711, 35.576629],
          [47.913878, 35.576629],
          [47.913321, 35.576917],
          [47.903602, 35.579965],
          [47.894155, 35.585528],
          [47.892488, 35.585528],
          [47.889155, 35.58441],
          [47.883045, 35.579135],
          [47.882212, 35.578017],
          [47.881141, 35.576979],
          [47.879708, 35.576079],
          [47.866099, 35.558299],
          [47.865542, 35.552465],
          [47.865822, 35.550526],
          [47.866099, 35.549688],
          [47.867489, 35.545522],
          [47.868042, 35.544413],
          [47.869155, 35.542745],
          [47.870542, 35.539968],
          [47.871099, 35.539409],
          [47.885265, 35.517183],
          [47.885545, 35.516913],
          [47.885545, 35.51024],
          [47.886099, 35.508861],
          [47.886098, 35.508302],
          [47.887212, 35.506634],
          [47.887489, 35.506354],
          [47.893879, 35.493298],
          [47.894155, 35.491629],
          [47.894432, 35.490241],
          [47.894712, 35.488582],
          [47.894989, 35.486914],
          [47.895265, 35.485245],
          [47.895545, 35.483577],
          [47.895822, 35.482739],
          [47.896099, 35.481079],
          [47.896379, 35.479961],
          [47.896655, 35.479132],
          [47.900822, 35.467184],
          [47.901655, 35.464686],
          [47.901935, 35.464407],
          [47.901935, 35.463848],
          [47.902488, 35.462748],
          [47.905269, 35.457743],
          [47.905822, 35.457464],
          [47.908878, 35.453577],
          [47.914988, 35.448573],
          [47.915268, 35.448023],
          [47.915822, 35.447743],
          [47.916102, 35.447193],
          [47.91728, 35.445769],
          [47.917482, 35.445525],
          [47.917769, 35.445236],
          [47.927492, 35.431909],
          [47.933602, 35.414965],
          [47.935545, 35.40968],
          [47.935545, 35.40913],
          [47.936102, 35.407462],
          [47.936658, 35.405794],
          [47.936935, 35.405514],
          [47.939992, 35.397182],
          [47.940549, 35.396073],
          [47.941382, 35.394963],
          [47.944715, 35.389679],
          [47.954435, 35.379408],
          [47.960548, 35.37663],
          [47.962215, 35.376071],
          [47.962772, 35.376071],
          [47.963325, 35.375792],
          [47.978328, 35.372184],
          [47.978739, 35.372054],
          [47.980271, 35.371914],
          [47.980548, 35.371625],
          [47.983048, 35.371075],
          [47.984438, 35.371075],
          [47.984715, 35.370796],
          [47.985271, 35.370796],
          [47.985548, 35.370516],
          [47.997495, 35.368018],
          [47.999984, 35.36923],
          [48.002218, 35.371355],
          [48.003605, 35.371625],
          [48.006104, 35.371625],
          [48.006384, 35.371914],
          [48.009438, 35.372464],
          [48.011104, 35.372464],
          [48.011384, 35.372744],
          [48.013607, 35.372744],
          [48.013884, 35.373023],
          [48.020274, 35.373023],
          [48.020828, 35.372744],
          [48.021941, 35.372744],
          [48.022494, 35.372464],
          [48.023328, 35.372464],
          [48.023608, 35.372184],
          [48.026661, 35.370796],
          [48.034164, 35.363022],
          [48.034164, 35.361634],
          [48.033884, 35.359686],
          [48.034164, 35.358856],
          [48.034441, 35.358017],
          [48.040551, 35.347187],
          [48.054721, 35.322188],
          [48.055274, 35.32052],
          [48.055274, 35.319961],
          [48.056664, 35.316903],
          [48.063887, 35.301626],
          [48.065932, 35.299071],
          [48.07722, 35.28496],
          [48.087497, 35.27274],
          [48.089444, 35.269682],
          [48.08972, 35.269403],
          [48.091944, 35.261069],
          [48.09222, 35.25913],
          [48.098054, 35.242463],
          [48.096387, 35.230793],
          [48.096387, 35.230513],
          [48.095834, 35.228294],
          [48.09611, 35.228015],
          [48.096387, 35.227735],
          [48.09722, 35.227176],
          [48.098054, 35.227176],
          [48.098887, 35.227176],
          [48.105001, 35.226626],
          [48.10889, 35.226626],
          [48.109444, 35.226906],
          [48.119723, 35.228854],
          [48.121667, 35.228854],
          [48.1225, 35.228574],
          [48.126947, 35.228574],
          [48.139723, 35.22218],
          [48.149058, 35.21165],
          [48.150614, 35.209873],
          [48.154446, 35.203853],
          [48.16139, 35.184407],
          [48.175836, 35.172736],
          [48.177537, 35.170798],
          [48.17778, 35.169958],
          [48.181113, 35.165232],
          [48.181253, 35.163707],
          [48.181393, 35.161065],
          [48.180836, 35.159955],
          [48.178336, 35.15329],
          [48.159723, 35.133564],
          [48.15917, 35.131345],
          [48.15778, 35.128846],
          [48.15639, 35.126348],
          [48.152503, 35.118013],
          [48.153614, 35.115515],
          [48.154723, 35.114406],
          [48.15528, 35.113296],
          [48.160557, 35.10912],
          [48.166114, 35.10746],
          [48.167227, 35.10746],
          [48.16778, 35.107181],
          [48.172227, 35.107181],
          [48.17278, 35.106901],
          [48.179447, 35.106901],
          [48.182226, 35.106901],
          [48.18306, 35.106901],
          [48.185003, 35.106342],
          [48.188616, 35.102175],
          [48.188893, 35.101065],
          [48.191393, 35.096068],
          [48.191669, 35.095238],
          [48.19195, 35.09329],
          [48.192226, 35.09274],
          [48.190836, 35.079399],
          [48.187503, 35.066897],
          [48.187503, 35.065508],
          [48.187227, 35.06079],
          [48.186393, 35.058842],
          [48.186116, 35.058291],
          [48.18556, 35.056072],
          [48.17806, 35.014677],
          [48.177227, 35.011619],
          [48.17667, 35.008011],
          [48.175837, 35.002175],
          [48.175837, 34.998846],
          [48.173337, 34.996068],
          [48.17278, 34.996068],
          [48.156391, 34.988842],
          [48.151391, 34.981616],
          [48.150281, 34.977178],
          [48.144167, 34.971062],
          [48.143891, 34.970782],
          [48.139167, 34.968563],
          [48.138614, 34.968563],
          [48.137224, 34.968563],
          [48.136391, 34.968843],
          [48.135281, 34.968843],
          [48.131114, 34.970232],
          [48.129167, 34.970232],
          [48.128614, 34.970512],
          [48.121391, 34.971621],
          [48.116111, 34.97301],
          [48.114725, 34.97301],
          [48.114445, 34.97329],
          [48.111668, 34.97329],
          [48.110558, 34.97301],
          [48.109168, 34.97301],
          [48.105835, 34.97218],
          [48.103335, 34.971062],
          [48.088612, 34.962176],
          [48.087498, 34.962176],
          [48.086111, 34.961897],
          [48.085831, 34.961617],
          [48.081665, 34.961617],
          [48.081388, 34.961337],
          [48.079998, 34.961337],
          [48.079722, 34.961617],
          [48.074445, 34.961617],
          [48.074165, 34.961896],
          [48.073055, 34.961896],
          [48.063608, 34.968563],
          [48.058055, 34.974399],
          [48.058055, 34.97495],
          [48.049165, 34.983285],
          [48.047775, 34.984395],
          [48.045275, 34.985504],
          [48.044442, 34.985504],
          [48.044165, 34.985784],
          [48.043609, 34.985784],
          [48.043052, 34.986063],
          [48.040832, 34.986063],
          [48.033332, 34.983565],
          [48.032775, 34.983565],
          [48.032219, 34.983285],
          [48.018052, 34.978287],
          [48.005552, 34.970782],
          [47.999987, 34.961272],
          [47.97194, 34.964675],
          [47.957216, 34.970232],
          [47.956106, 34.970232],
          [47.955826, 34.970511],
          [47.95277, 34.970511],
          [47.92277, 34.951892],
          [47.919994, 34.944946],
          [47.919714, 34.943006],
          [47.919994, 34.940787],
          [47.92138, 34.937169],
          [47.92166, 34.936069],
          [47.921937, 34.935789],
          [47.921937, 34.93523],
          [47.922353, 34.934535],
          [47.923047, 34.93412],
          [47.923047, 34.933841],
          [47.923604, 34.933561],
          [47.927213, 34.929952],
          [47.92777, 34.928843],
          [47.927494, 34.925234],
          [47.92666, 34.916339],
          [47.926104, 34.91523],
          [47.91777, 34.895509],
          [47.910271, 34.889113],
          [47.908604, 34.888003],
          [47.908604, 34.887732],
          [47.907491, 34.886893],
          [47.881934, 34.873289],
          [47.863601, 34.858006],
          [47.862211, 34.857456],
          [47.858601, 34.855778],
          [47.849155, 34.853008],
          [47.848045, 34.853008],
          [47.847211, 34.852728],
          [47.843878, 34.852728],
          [47.843321, 34.852449],
          [47.835265, 34.852449],
          [47.834711, 34.852169],
          [47.833878, 34.852169],
          [47.833598, 34.851889],
          [47.832028, 34.857794],
          [47.825696, 34.85678],
          [47.82315, 34.857378],
          [47.809281, 34.856287],
          [47.797194, 34.851164],
        ],
      ],
    },
  },
  Hormozgan: {
    properties: { value: "Hormozgan", name: "استان هرمزگان, ایران", id: 10 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [52.482398, 26.989407],
          [52.679224, 26.87154],
          [52.87576, 26.753725],
          [52.947775, 26.710524],
          [53.091341, 26.624352],
          [53.128221, 26.606177],
          [53.403101, 26.498771],
          [53.820725, 26.335397],
          [53.849842, 26.322268],
          [53.872151, 26.316015],
          [53.886571, 26.310408],
          [53.931385, 26.299322],
          [53.964239, 26.294627],
          [54.223545, 26.057602],
          [54.229433, 26.0419],
          [54.229769, 26.041004],
          [54.237539, 26.025021],
          [54.246854, 26.00965],
          [54.257572, 25.995128],
          [54.269695, 25.981449],
          [54.275906, 25.94679],
          [54.2726, 25.899481],
          [54.277745, 25.860539],
          [54.29362, 25.817864],
          [54.311464, 25.788443],
          [54.334468, 25.762125],
          [54.361988, 25.739645],
          [54.393254, 25.721629],
          [54.427392, 25.708581],
          [54.463449, 25.700865],
          [54.485702, 25.699389],
          [54.506416, 25.695063],
          [54.530063, 25.692518],
          [54.551914, 25.691983],
          [54.585941, 25.694941],
          [54.619064, 25.702604],
          [54.650496, 25.714791],
          [54.866976, 25.706924],
          [54.896813, 25.685971],
          [54.929404, 25.670206],
          [54.968466, 25.658728],
          [55.00088, 25.654342],
          [55.0378, 25.654675],
          [55.06943, 25.656594],
          [55.088435, 25.659734],
          [55.128555, 25.669982],
          [55.150441, 25.678086],
          [55.184721, 25.696223],
          [55.20774, 25.713211],
          [55.228231, 25.733033],
          [55.250204, 25.762243],
          [55.266185, 25.79449],
          [55.274481, 25.822442],
          [55.278423, 25.854536],
          [55.276624, 25.886787],
          [55.399602, 26.040778],
          [55.422999, 26.070052],
          [55.44999, 26.084741],
          [55.474619, 26.1029],
          [55.508783, 26.139838],
          [55.644723, 26.236203],
          [55.74303, 26.305841],
          [55.893341, 26.412236],
          [55.927538, 26.418812],
          [55.960177, 26.430118],
          [56.132255, 26.503741],
          [56.143123, 26.518065],
          [56.173678, 26.547232],
          [56.241734, 26.590287],
          [56.275067, 26.593065],
          [56.298146, 26.603391],
          [56.327845, 26.616676],
          [56.54625, 26.702674],
          [56.562289, 26.714804],
          [56.586822, 26.741972],
          [56.605738, 26.772549],
          [56.670716, 26.903069],
          [56.677186, 26.898466],
          [56.695109, 26.878392],
          [56.719697, 26.857623],
          [56.722923, 26.847899],
          [56.736064, 26.821545],
          [56.738642, 26.816375],
          [56.746401, 26.804095],
          [56.754495, 26.794017],
          [56.772265, 26.771888],
          [56.782063, 26.76257],
          [56.808456, 26.740794],
          [56.809567, 26.733535],
          [56.822048, 26.699289],
          [56.83173, 26.679422],
          [56.832535, 26.677771],
          [56.845201, 26.647081],
          [56.851357, 26.624517],
          [56.851934, 26.6214],
          [56.854923, 26.605226],
          [56.859186, 26.568724],
          [56.849999, 26.556577],
          [56.829643, 26.518852],
          [56.818457, 26.478145],
          [56.816008, 26.431808],
          [56.823646, 26.391457],
          [56.825124, 26.386666],
          [56.826624, 26.381802],
          [56.84134, 26.348248],
          [56.843323, 26.331272],
          [56.853129, 26.290912],
          [56.862243, 26.2726],
          [56.871362, 26.244885],
          [56.884449, 26.213641],
          [56.903092, 26.184784],
          [56.912486, 26.173865],
          [56.915878, 26.163726],
          [56.932721, 26.129331],
          [56.951417, 26.104854],
          [56.952132, 26.076686],
          [56.953672, 26.016008],
          [56.962442, 25.974327],
          [56.978051, 25.935722],
          [57.002019, 25.900799],
          [57.009129, 25.892477],
          [57.035794, 25.866397],
          [57.071228, 25.842645],
          [57.075818, 25.817984],
          [57.0773, 25.776065],
          [57.083587, 25.74541],
          [57.095989, 25.712988],
          [57.112581, 25.685279],
          [57.146293, 25.647588],
          [57.185862, 25.61904],
          [57.21681, 25.603977],
          [57.238027, 25.596233],
          [57.256187, 25.590935],
          [57.282635, 25.585335],
          [57.296652, 25.583358],
          [57.499027, 25.512164],
          [57.67806, 25.449145],
          [57.701304, 25.442243],
          [57.925033, 25.387742],
          [58.031375, 25.361828],
          [58.052994, 25.357574],
          [58.632055, 25.268867],
          [58.822991, 25.239603],
          [59.056986, 25.20373],
          [59.092164, 25.200822],
          [59.218776, 25.199403],
          [59.221111, 25.439389],
          [59.219038, 25.458169],
          [59.217991, 25.467651],
          [59.216053, 25.490308],
          [59.214939, 25.499747],
          [59.214939, 25.500859],
          [59.214659, 25.504193],
          [59.214659, 25.504749],
          [59.214659, 25.51253],
          [59.214106, 25.520858],
          [59.210773, 25.554753],
          [59.210493, 25.557801],
          [59.210773, 25.558079],
          [59.211053, 25.560303],
          [59.211326, 25.562248],
          [59.211606, 25.563638],
          [59.213826, 25.574199],
          [59.214106, 25.574746],
          [59.214106, 25.575311],
          [59.219386, 25.589754],
          [59.219939, 25.590588],
          [59.22022, 25.592255],
          [59.234386, 25.629195],
          [59.234939, 25.631419],
          [59.235219, 25.632253],
          [59.2355, 25.634199],
          [59.235773, 25.6367],
          [59.235499, 25.638637],
          [59.235219, 25.639193],
          [59.229106, 25.65197],
          [59.22494, 25.658086],
          [59.223553, 25.663914],
          [59.218553, 25.671976],
          [59.21244, 25.685866],
          [59.21216, 25.687534],
          [59.211886, 25.68809],
          [59.211327, 25.691417],
          [59.211886, 25.697255],
          [59.212993, 25.701147],
          [59.213826, 25.701981],
          [59.2205, 25.714473],
          [59.2205, 25.720033],
          [59.219387, 25.731422],
          [59.219107, 25.735306],
          [59.219107, 25.735862],
          [59.219107, 25.73892],
          [59.218833, 25.740588],
          [59.21772, 25.743359],
          [59.21022, 25.75448],
          [59.186327, 25.770032],
          [59.185774, 25.77031],
          [59.185494, 25.770588],
          [59.182994, 25.773368],
          [59.177714, 25.780588],
          [59.139935, 25.812815],
          [59.129101, 25.820314],
          [59.128268, 25.820583],
          [59.127995, 25.82087],
          [59.123268, 25.823086],
          [59.121602, 25.823086],
          [59.119935, 25.823364],
          [59.119662, 25.823642],
          [59.117995, 25.823642],
          [59.116329, 25.824198],
          [59.105215, 25.836702],
          [59.104382, 25.836702],
          [59.101882, 25.838093],
          [59.101049, 25.838093],
          [59.099655, 25.838649],
          [59.098822, 25.838649],
          [59.095769, 25.84003],
          [59.095489, 25.840317],
          [59.083549, 25.850866],
          [59.080769, 25.854481],
          [59.079382, 25.856697],
          [59.078549, 25.860312],
          [59.077989, 25.862537],
          [59.077716, 25.863084],
          [59.077989, 25.863371],
          [59.077436, 25.867811],
          [59.077156, 25.868646],
          [59.077436, 25.86948],
          [59.077716, 25.871427],
          [59.078269, 25.874755],
          [59.078269, 25.879195],
          [59.077989, 25.880308],
          [59.077716, 25.880864],
          [59.072156, 25.894482],
          [59.067436, 25.898644],
          [59.06549, 25.899201],
          [59.064376, 25.899201],
          [59.063823, 25.899479],
          [59.062156, 25.899479],
          [59.061876, 25.899201],
          [59.06077, 25.899201],
          [59.06049, 25.898922],
          [59.059656, 25.898922],
          [59.059376, 25.899201],
          [59.058543, 25.899201],
          [59.057989, 25.899479],
          [59.056876, 25.899479],
          [59.056323, 25.899757],
          [59.055489, 25.899757],
          [59.055209, 25.900035],
          [59.054376, 25.900035],
          [59.046603, 25.905866],
          [59.042156, 25.908091],
          [59.042156, 25.908369],
          [59.041323, 25.908647],
          [59.039377, 25.908647],
          [59.037437, 25.908091],
          [59.036603, 25.908091],
          [59.036603, 25.907813],
          [59.03549, 25.907813],
          [59.03521, 25.907535],
          [59.032437, 25.907535],
          [59.03021, 25.908926],
          [59.029377, 25.913644],
          [59.029103, 25.915035],
          [59.023544, 25.920866],
          [59.023263, 25.921145],
          [59.013824, 25.926698],
          [59.012157, 25.927254],
          [59.012157, 25.927541],
          [59.01049, 25.927811],
          [59.009377, 25.928367],
          [59.008544, 25.928367],
          [59.001044, 25.931704],
          [58.997711, 25.937814],
          [58.997711, 25.938092],
          [58.997711, 25.941699],
          [58.99799, 25.943368],
          [58.998264, 25.945315],
          [59.00243, 25.953928],
          [59.00243, 25.954484],
          [59.014657, 25.969198],
          [59.01521, 25.969755],
          [59.019097, 25.973371],
          [59.033544, 25.98281],
          [59.034104, 25.983375],
          [59.038823, 25.984757],
          [59.039103, 25.985035],
          [59.04021, 25.985035],
          [59.04049, 25.985313],
          [59.041603, 25.985313],
          [59.042157, 25.985591],
          [59.04327, 25.985591],
          [59.051323, 25.990311],
          [59.052156, 25.990867],
          [59.05799, 25.992536],
          [59.061323, 25.992536],
          [59.061876, 25.992258],
          [59.072716, 25.992258],
          [59.07299, 25.992536],
          [59.074103, 25.992536],
          [59.079936, 25.995865],
          [59.080489, 25.996152],
          [59.080769, 25.996708],
          [59.081883, 26.000064],
          [59.083549, 26.017535],
          [59.08355, 26.025315],
          [59.083269, 26.025871],
          [59.083269, 26.027809],
          [59.082989, 26.0292],
          [59.082716, 26.029756],
          [59.082716, 26.030313],
          [59.077989, 26.045317],
          [59.074936, 26.05115],
          [59.051876, 26.076986],
          [59.041324, 26.090591],
          [59.041044, 26.091426],
          [59.037157, 26.097815],
          [59.02243, 26.130035],
          [59.021877, 26.13087],
          [59.020491, 26.138651],
          [59.020764, 26.14059],
          [59.027157, 26.156423],
          [59.027437, 26.157257],
          [59.027991, 26.160318],
          [59.027991, 26.162266],
          [59.028271, 26.162535],
          [59.028544, 26.164483],
          [59.028824, 26.166987],
          [59.021044, 26.174482],
          [59.017991, 26.178377],
          [59.017711, 26.179212],
          [59.017431, 26.179481],
          [59.016757, 26.180058],
          [58.990765, 26.202263],
          [58.982985, 26.217263],
          [58.980765, 26.233376],
          [58.981045, 26.240315],
          [58.981045, 26.24115],
          [58.981318, 26.242541],
          [58.981878, 26.248376],
          [58.981598, 26.261708],
          [58.979652, 26.278926],
          [58.976045, 26.28671],
          [58.971045, 26.292546],
          [58.963545, 26.297816],
          [58.959932, 26.301703],
          [58.949652, 26.326429],
          [58.943266, 26.335596],
          [58.940206, 26.336709],
          [58.937152, 26.336153],
          [58.934933, 26.335874],
          [58.933266, 26.335875],
          [58.931872, 26.335596],
          [58.928266, 26.335596],
          [58.92632, 26.335327],
          [58.926039, 26.335039],
          [58.916319, 26.335596],
          [58.915766, 26.335875],
          [58.91132, 26.335875],
          [58.908539, 26.336431],
          [58.907153, 26.336431],
          [58.90632, 26.33671],
          [58.905206, 26.33671],
          [58.904926, 26.336988],
          [58.904093, 26.336988],
          [58.90354, 26.337266],
          [58.90076, 26.337266],
          [58.897153, 26.338658],
          [58.891873, 26.341989],
          [58.889373, 26.343372],
          [58.885996, 26.346851],
          [58.879654, 26.353383],
          [58.87632, 26.368378],
          [58.87604, 26.373658],
          [58.87604, 26.374214],
          [58.877707, 26.382816],
          [58.880207, 26.388931],
          [58.88326, 26.397543],
          [58.884927, 26.40866],
          [58.884654, 26.421429],
          [58.884374, 26.423656],
          [58.88326, 26.427544],
          [58.861874, 26.45171],
          [58.855761, 26.460601],
          [58.844094, 26.482541],
          [58.843821, 26.483093],
          [58.843814, 26.483107],
          [58.843795, 26.483107],
          [58.843261, 26.483107],
          [58.842802, 26.483338],
          [58.842708, 26.483385],
          [58.840449, 26.483789],
          [58.839648, 26.483933],
          [58.819648, 26.492546],
          [58.817981, 26.493659],
          [58.800482, 26.502263],
          [58.784649, 26.508658],
          [58.782702, 26.509772],
          [58.768816, 26.515044],
          [58.707143, 26.5306],
          [58.690477, 26.536717],
          [58.654364, 26.554492],
          [58.645198, 26.55866],
          [58.596305, 26.588939],
          [58.577419, 26.603662],
          [58.535193, 26.641715],
          [58.513891, 26.668606],
          [58.500194, 26.685888],
          [58.485467, 26.699212],
          [58.484634, 26.699769],
          [58.474634, 26.707271],
          [58.473801, 26.707828],
          [58.442688, 26.727549],
          [58.440741, 26.728384],
          [58.436575, 26.731439],
          [58.435468, 26.732275],
          [58.419374, 26.737859],
          [58.387409, 26.748942],
          [58.385182, 26.749778],
          [58.373349, 26.753189],
          [58.372683, 26.753381],
          [58.371754, 26.753381],
          [58.371016, 26.753381],
          [58.370931, 26.753467],
          [58.370743, 26.753659],
          [58.369701, 26.753659],
          [58.369349, 26.753659],
          [58.36909, 26.753746],
          [58.368516, 26.753938],
          [58.367156, 26.753938],
          [58.36713, 26.753938],
          [58.354975, 26.756532],
          [58.354076, 26.756723],
          [58.352683, 26.756723],
          [58.34963, 26.75728],
          [58.347843, 26.75728],
          [58.347683, 26.75728],
          [58.346985, 26.757455],
          [58.34657, 26.757559],
          [58.320737, 26.761998],
          [58.316017, 26.762833],
          [58.315171, 26.762833],
          [58.31463, 26.762833],
          [58.314455, 26.76292],
          [58.31407, 26.763112],
          [58.313267, 26.763112],
          [58.313237, 26.763112],
          [58.285254, 26.769087],
          [58.284624, 26.769222],
          [58.277404, 26.770606],
          [58.275726, 26.770606],
          [58.275184, 26.770606],
          [58.275009, 26.770693],
          [58.274624, 26.770884],
          [58.273872, 26.770884],
          [58.273518, 26.770884],
          [58.273256, 26.770972],
          [58.272684, 26.771163],
          [58.271092, 26.771163],
          [58.270738, 26.771163],
          [58.270476, 26.77125],
          [58.269905, 26.771441],
          [58.269145, 26.771441],
          [58.268791, 26.771441],
          [58.268529, 26.771529],
          [58.267958, 26.77172],
          [58.265812, 26.77172],
          [58.265458, 26.77172],
          [58.265196, 26.771807],
          [58.264624, 26.771998],
          [58.262946, 26.771998],
          [58.262404, 26.771998],
          [58.262228, 26.772086],
          [58.261844, 26.772277],
          [58.261092, 26.772277],
          [58.260738, 26.772277],
          [58.260476, 26.772365],
          [58.259904, 26.772556],
          [58.259338, 26.772556],
          [58.258791, 26.772556],
          [58.258617, 26.772643],
          [58.258238, 26.772834],
          [58.257671, 26.772834],
          [58.257125, 26.772834],
          [58.256951, 26.772922],
          [58.256571, 26.773113],
          [58.256005, 26.773113],
          [58.255458, 26.773113],
          [58.255284, 26.7732],
          [58.254905, 26.773391],
          [58.254071, 26.773391],
          [58.251571, 26.77367],
          [58.234625, 26.775889],
          [58.229065, 26.776159],
          [58.222754, 26.776159],
          [58.222398, 26.776159],
          [58.222135, 26.776247],
          [58.221565, 26.776437],
          [58.219335, 26.776437],
          [58.218792, 26.776437],
          [58.218615, 26.776525],
          [58.218232, 26.776716],
          [58.214613, 26.776716],
          [58.214065, 26.776716],
          [58.213891, 26.776804],
          [58.213512, 26.776994],
          [58.208588, 26.776994],
          [58.208232, 26.776994],
          [58.207399, 26.777273],
          [58.207224, 26.777361],
          [58.206845, 26.777552],
          [58.206838, 26.777552],
          [58.206292, 26.777552],
          [58.20556, 26.777918],
          [58.205179, 26.778109],
          [58.204447, 26.778109],
          [58.204066, 26.778109],
          [58.203512, 26.77783],
          [58.202399, 26.77783],
          [58.201846, 26.777552],
          [58.199702, 26.777552],
          [58.199346, 26.777552],
          [58.199082, 26.77764],
          [58.198512, 26.77783],
          [58.197669, 26.77783],
          [58.197126, 26.77783],
          [58.196949, 26.777918],
          [58.196565, 26.778109],
          [58.196467, 26.778109],
          [58.195732, 26.778109],
          [58.195644, 26.778197],
          [58.195452, 26.778387],
          [58.195358, 26.778387],
          [58.194619, 26.778387],
          [58.194532, 26.778475],
          [58.194346, 26.778666],
          [58.193869, 26.778666],
          [58.193512, 26.778666],
          [58.191846, 26.779223],
          [58.181286, 26.786717],
          [58.179066, 26.789215],
          [58.178512, 26.789215],
          [58.178512, 26.789493],
          [58.175179, 26.792558],
          [58.171286, 26.795056],
          [58.12434, 26.834774],
          [58.117674, 26.838665],
          [58.11116, 26.840417],
          [58.110454, 26.840606],
          [58.110076, 26.840606],
          [58.10934, 26.840607],
          [58.109251, 26.840695],
          [58.10906, 26.840885],
          [58.104894, 26.840885],
          [58.100727, 26.841164],
          [58.09951, 26.841164],
          [58.09934, 26.841164],
          [58.094894, 26.842278],
          [58.089061, 26.844498],
          [58.087674, 26.845333],
          [58.082947, 26.847284],
          [58.060448, 26.859775],
          [58.059614, 26.860611],
          [58.056841, 26.862273],
          [58.031282, 26.885613],
          [58.028782, 26.88839],
          [58.028228, 26.888668],
          [58.003502, 26.910337],
          [58.002389, 26.910615],
          [58.001555, 26.911451],
          [57.992277, 26.915424],
          [57.991836, 26.915613],
          [57.991245, 26.915613],
          [57.991002, 26.915613],
          [57.983635, 26.917644],
          [57.982949, 26.917832],
          [57.981836, 26.917832],
          [57.969056, 26.920052],
          [57.958776, 26.920052],
          [57.953223, 26.919774],
          [57.947943, 26.919504],
          [57.914057, 26.923666],
          [57.910809, 26.923666],
          [57.910444, 26.923666],
          [57.910174, 26.923756],
          [57.90961, 26.923944],
          [57.907664, 26.923944],
          [57.906277, 26.924223],
          [57.90433, 26.924223],
          [57.895038, 26.925768],
          [57.894331, 26.925886],
          [57.888807, 26.927648],
          [57.888217, 26.927836],
          [57.887326, 26.927836],
          [57.887111, 26.927836],
          [57.881939, 26.929201],
          [57.881831, 26.929229],
          [57.880444, 26.929499],
          [57.880439, 26.929499],
          [57.879884, 26.929499],
          [57.879704, 26.929589],
          [57.879331, 26.929777],
          [57.878995, 26.929777],
          [57.878497, 26.929777],
          [57.874051, 26.931719],
          [57.873498, 26.931997],
          [57.873069, 26.932645],
          [57.872944, 26.932833],
          [57.872559, 26.932833],
          [57.872111, 26.932833],
          [57.859812, 26.937643],
          [57.859331, 26.93783],
          [57.858773, 26.93783],
          [57.858218, 26.93783],
          [57.858038, 26.937921],
          [57.857665, 26.938109],
          [57.857571, 26.938109],
          [57.856831, 26.938109],
          [57.85674, 26.9382],
          [57.856551, 26.938388],
          [57.855804, 26.938388],
          [57.855718, 26.938388],
          [57.854061, 26.938757],
          [57.853218, 26.938945],
          [57.851271, 26.938945],
          [57.847105, 26.939781],
          [57.845343, 26.939781],
          [57.845165, 26.939781],
          [57.844802, 26.939872],
          [57.844052, 26.940059],
          [57.838771, 26.94006],
          [57.838218, 26.939781],
          [57.832665, 26.939781],
          [57.821832, 26.937552],
          [57.819881, 26.937552],
          [57.819325, 26.937552],
          [57.819144, 26.937643],
          [57.818772, 26.937831],
          [57.817845, 26.937831],
          [57.817105, 26.937831],
          [57.817014, 26.937922],
          [57.816825, 26.938109],
          [57.816155, 26.938109],
          [57.815992, 26.938109],
          [57.802386, 26.941444],
          [57.801552, 26.942001],
          [57.800439, 26.94228],
          [57.799606, 26.942837],
          [57.798492, 26.943115],
          [57.786272, 26.951447],
          [57.770159, 26.96839],
          [57.768213, 26.97061],
          [57.76294, 26.977837],
          [57.749606, 26.996174],
          [57.748769, 27.000176],
          [57.742942, 27.002831],
          [57.742934, 27.002834],
          [57.742933, 27.002835],
          [57.728767, 27.012004],
          [57.728213, 27.012282],
          [57.724327, 27.015339],
          [57.717381, 27.021442],
          [57.698487, 27.032841],
          [57.662102, 27.056726],
          [57.661541, 27.056726],
          [57.661541, 27.056818],
          [57.661541, 27.057004],
          [57.660808, 27.057004],
          [57.660435, 27.057004],
          [57.658768, 27.057562],
          [57.654321, 27.060061],
          [57.644602, 27.068673],
          [57.641822, 27.070615],
          [57.640155, 27.073393],
          [57.639414, 27.073764],
          [57.639042, 27.07395],
          [57.637655, 27.07395],
          [57.637102, 27.07395],
          [57.635439, 27.074136],
          [57.634602, 27.074229],
          [57.634509, 27.074321],
          [57.634322, 27.074508],
          [57.634209, 27.074508],
          [57.633489, 27.074508],
          [57.630989, 27.076728],
          [57.624595, 27.080054],
          [57.622929, 27.08089],
          [57.621262, 27.082284],
          [57.619042, 27.084226],
          [57.598209, 27.101172],
          [57.578763, 27.122282],
          [57.57821, 27.123945],
          [57.577096, 27.124781],
          [57.563483, 27.148113],
          [57.56265, 27.15117],
          [57.561538, 27.156119],
          [57.561263, 27.157841],
          [57.560983, 27.158398],
          [57.560983, 27.158947],
          [57.56015, 27.162561],
          [57.553203, 27.17478],
          [57.55237, 27.176453],
          [57.54765, 27.181452],
          [57.544037, 27.184509],
          [57.53265, 27.192844],
          [57.514582, 27.204484],
          [57.500395, 27.223476],
          [57.493917, 27.227601],
          [57.475183, 27.23222],
          [57.463609, 27.223032],
          [57.437925, 27.236177],
          [57.435978, 27.237283],
          [57.425088, 27.24256],
          [57.418199, 27.245897],
          [57.400146, 27.255897],
          [57.386813, 27.266175],
          [57.369313, 27.284512],
          [57.3632, 27.292003],
          [57.362366, 27.293397],
          [57.354867, 27.300061],
          [57.354033, 27.300618],
          [57.353753, 27.301176],
          [57.353473, 27.301455],
          [57.345974, 27.308955],
          [57.341184, 27.314614],
          [57.33542, 27.321456],
          [57.324587, 27.335899],
          [57.320694, 27.342006],
          [57.32042, 27.342564],
          [57.319307, 27.344794],
          [57.316527, 27.358123],
          [57.316254, 27.360623],
          [57.316254, 27.373952],
          [57.317087, 27.379232],
          [57.317361, 27.381454],
          [57.320694, 27.403679],
          [57.321527, 27.4059],
          [57.321807, 27.407564],
          [57.32264, 27.409516],
          [57.32486, 27.424232],
          [57.325141, 27.424789],
          [57.325421, 27.429233],
          [57.325141, 27.437571],
          [57.324587, 27.439235],
          [57.315141, 27.45423],
          [57.311527, 27.457567],
          [57.309834, 27.458978],
          [57.309836, 27.460787],
          [57.309844, 27.466186],
          [57.309008, 27.4685],
          [57.311041, 27.47331],
          [57.311989, 27.480197],
          [57.312977, 27.487368],
          [57.316422, 27.491849],
          [57.320652, 27.501314],
          [57.321448, 27.503094],
          [57.323508, 27.507702],
          [57.326089, 27.513474],
          [57.328198, 27.518191],
          [57.330405, 27.523126],
          [57.331476, 27.526332],
          [57.339984, 27.535809],
          [57.342514, 27.540413],
          [57.34588, 27.546539],
          [57.3468, 27.547729],
          [57.350205, 27.552314],
          [57.35468, 27.56143],
          [57.355434, 27.564121],
          [57.35646, 27.567778],
          [57.357781, 27.57033],
          [57.358099, 27.573202],
          [57.358713, 27.574488],
          [57.353308, 27.576535],
          [57.352778, 27.577347],
          [57.349783, 27.57803],
          [57.343758, 27.581488],
          [57.343242, 27.582698],
          [57.338971, 27.584704],
          [57.338953, 27.584713],
          [57.334909, 27.585486],
          [57.334006, 27.585659],
          [57.329562, 27.587184],
          [57.327477, 27.588081],
          [57.322003, 27.589218],
          [57.319317, 27.592112],
          [57.31248, 27.594399],
          [57.302366, 27.597783],
          [57.301578, 27.598046],
          [57.295352, 27.594711],
          [57.290767, 27.590587],
          [57.282938, 27.583545],
          [57.272658, 27.574295],
          [57.271549, 27.571838],
          [57.269721, 27.571371],
          [57.267592, 27.569987],
          [57.264244, 27.567812],
          [57.260817, 27.565585],
          [57.251685, 27.565174],
          [57.249133, 27.564001],
          [57.246423, 27.563661],
          [57.242152, 27.561954],
          [57.238255, 27.5611],
          [57.235428, 27.560481],
          [57.231867, 27.5597],
          [57.229841, 27.559948],
          [57.229041, 27.560046],
          [57.226113, 27.56187],
          [57.224883, 27.563721],
          [57.223975, 27.566607],
          [57.224088, 27.570747],
          [57.223852, 27.574029],
          [57.223051, 27.576841],
          [57.225851, 27.585561],
          [57.225839, 27.586906],
          [57.225826, 27.588457],
          [57.227739, 27.593078],
          [57.238528, 27.606577],
          [57.241891, 27.612616],
          [57.248035, 27.620238],
          [57.253185, 27.628144],
          [57.262201, 27.635814],
          [57.268791, 27.63972],
          [57.273282, 27.643636],
          [57.281252, 27.653405],
          [57.281808, 27.653963],
          [57.281808, 27.65452],
          [57.293751, 27.668404],
          [57.293751, 27.668962],
          [57.294585, 27.66979],
          [57.297642, 27.676179],
          [57.308755, 27.689793],
          [57.311255, 27.69368],
          [57.325694, 27.706458],
          [57.326254, 27.706737],
          [57.340141, 27.71507],
          [57.342641, 27.716743],
          [57.361534, 27.733688],
          [57.38042, 27.747295],
          [57.404313, 27.758687],
          [57.439866, 27.771188],
          [57.467646, 27.783688],
          [57.473205, 27.787575],
          [57.474872, 27.790077],
          [57.475145, 27.790905],
          [57.475145, 27.79257],
          [57.474872, 27.792708],
          [57.471153, 27.794175],
          [57.470979, 27.794244],
          [57.467373, 27.794793],
          [57.444593, 27.798123],
          [57.44184, 27.798548],
          [57.440979, 27.798681],
          [57.440422, 27.799054],
          [57.440146, 27.799239],
          [57.439581, 27.799239],
          [57.439033, 27.799239],
          [57.433759, 27.801741],
          [57.425146, 27.810075],
          [57.422366, 27.819795],
          [57.422093, 27.820911],
          [57.422366, 27.822018],
          [57.424313, 27.835077],
          [57.424593, 27.838128],
          [57.424593, 27.846741],
          [57.424593, 27.855634],
          [57.42348, 27.870637],
          [57.421813, 27.881465],
          [57.416577, 27.890552],
          [57.416533, 27.890628],
          [57.382486, 27.894747],
          [57.382087, 27.894795],
          [57.381712, 27.89489],
          [57.38098, 27.895075],
          [57.378777, 27.895075],
          [57.378754, 27.895075],
          [57.378283, 27.895169],
          [57.377367, 27.895354],
          [57.375166, 27.895354],
          [57.375147, 27.895354],
          [57.374674, 27.895448],
          [57.373754, 27.895633],
          [57.368481, 27.895633],
          [57.359588, 27.895912],
          [57.340421, 27.895912],
          [57.338201, 27.896182],
          [57.309862, 27.895912],
          [57.276252, 27.893968],
          [57.274862, 27.893689],
          [57.267086, 27.893689],
          [57.263752, 27.89341],
          [57.220416, 27.89341],
          [57.21875, 27.893689],
          [57.212916, 27.893689],
          [57.204252, 27.894768],
          [57.204027, 27.894796],
          [57.197637, 27.896182],
          [57.19458, 27.897298],
          [57.192913, 27.897856],
          [57.191803, 27.898684],
          [57.167081, 27.907577],
          [57.164581, 27.908684],
          [57.161801, 27.909242],
          [57.149301, 27.915633],
          [57.148191, 27.915912],
          [57.130135, 27.924526],
          [57.100132, 27.935634],
          [57.060132, 27.947849],
          [57.057076, 27.949244],
          [57.053742, 27.950082],
          [57.024573, 27.963413],
          [57.019853, 27.966195],
          [57.017906, 27.967023],
          [57.008183, 27.973972],
          [57.00735, 27.975358],
          [57.006516, 27.977582],
          [57.005963, 27.979805],
          [57.005406, 27.986188],
          [57.006762, 27.997834],
          [57.006797, 28.006749],
          [57.00513, 28.060638],
          [57.005406, 28.061197],
          [57.005407, 28.067859],
          [57.005407, 28.070082],
          [57.005683, 28.07092],
          [57.005963, 28.073414],
          [57.00624, 28.075917],
          [57.006797, 28.081471],
          [57.007073, 28.083974],
          [57.00735, 28.087305],
          [57.00763, 28.09286],
          [57.007073, 28.115918],
          [57.00735, 28.117026],
          [57.00763, 28.123697],
          [57.00735, 28.124805],
          [57.00763, 28.125912],
          [57.00735, 28.127578],
          [57.00763, 28.128136],
          [57.00735, 28.132305],
          [57.00763, 28.132584],
          [57.00735, 28.13425],
          [57.00763, 28.134529],
          [57.007907, 28.138698],
          [57.007907, 28.14536],
          [57.00763, 28.146198],
          [57.00763, 28.146468],
          [57.007073, 28.152582],
          [57.005963, 28.159803],
          [57.005683, 28.160082],
          [57.004574, 28.181476],
          [57.00485, 28.182304],
          [57.005407, 28.190913],
          [57.005407, 28.207022],
          [57.00513, 28.210912],
          [57.004017, 28.217863],
          [57.003183, 28.220637],
          [56.999931, 28.235436],
          [56.992627, 28.241195],
          [56.984578, 28.243796],
          [56.984017, 28.243978],
          [56.975684, 28.243978],
          [56.974294, 28.243978],
          [56.962403, 28.244235],
          [56.961794, 28.244248],
          [56.961599, 28.244346],
          [56.961237, 28.244527],
          [56.958765, 28.244527],
          [56.958181, 28.244527],
          [56.957987, 28.244625],
          [56.957628, 28.244806],
          [56.95525, 28.244806],
          [56.954848, 28.244806],
          [56.954555, 28.244904],
          [56.954014, 28.245085],
          [56.952556, 28.245085],
          [56.951794, 28.245085],
          [56.951673, 28.245206],
          [56.951514, 28.245364],
          [56.942512, 28.245622],
          [56.942071, 28.245635],
          [56.941778, 28.245733],
          [56.941238, 28.245914],
          [56.938225, 28.245914],
          [56.938181, 28.245914],
          [56.936304, 28.246291],
          [56.935405, 28.246472],
          [56.934876, 28.246472],
          [56.934291, 28.246472],
          [56.934097, 28.24657],
          [56.933738, 28.246751],
          [56.933028, 28.246751],
          [56.932625, 28.246751],
          [56.932331, 28.24685],
          [56.931791, 28.24703],
          [56.930528, 28.24703],
          [56.930125, 28.24703],
          [56.929831, 28.247129],
          [56.929291, 28.24731],
          [56.927905, 28.24731],
          [56.924571, 28.247859],
          [56.922752, 28.247859],
          [56.922348, 28.247859],
          [56.922055, 28.247957],
          [56.921515, 28.248138],
          [56.920805, 28.248138],
          [56.920401, 28.248138],
          [56.920108, 28.248236],
          [56.919568, 28.248417],
          [56.918305, 28.248417],
          [56.917902, 28.248417],
          [56.917608, 28.248516],
          [56.917068, 28.248696],
          [56.913765, 28.248696],
          [56.913182, 28.248696],
          [56.912985, 28.248795],
          [56.912625, 28.248976],
          [56.907625, 28.248976],
          [56.889925, 28.251114],
          [56.889292, 28.251191],
          [56.888998, 28.251289],
          [56.888459, 28.25147],
          [56.887197, 28.25147],
          [56.886792, 28.25147],
          [56.886498, 28.251569],
          [56.885959, 28.251749],
          [56.884693, 28.251749],
          [56.884289, 28.251749],
          [56.883994, 28.251848],
          [56.883455, 28.252028],
          [56.882015, 28.252028],
          [56.881789, 28.252028],
          [56.881397, 28.252127],
          [56.880679, 28.252307],
          [56.880153, 28.252307],
          [56.879569, 28.252307],
          [56.879372, 28.252406],
          [56.879012, 28.252587],
          [56.877719, 28.252587],
          [56.877622, 28.252587],
          [56.874549, 28.253242],
          [56.873736, 28.253415],
          [56.853465, 28.254507],
          [56.853179, 28.254523],
          [56.852785, 28.254622],
          [56.852066, 28.254802],
          [56.849815, 28.254802],
          [56.849566, 28.254802],
          [56.84915, 28.25491],
          [56.848456, 28.25509],
          [56.847899, 28.25509],
          [56.844193, 28.255348],
          [56.844009, 28.25536],
          [56.82762, 28.258971],
          [56.822066, 28.260637],
          [56.81512, 28.262033],
          [56.81401, 28.262582],
          [56.801787, 28.265086],
          [56.801589, 28.265185],
          [56.80123, 28.265365],
          [56.800064, 28.265365],
          [56.799843, 28.265365],
          [56.797234, 28.266014],
          [56.79651, 28.266194],
          [56.795343, 28.266194],
          [56.79512, 28.266194],
          [56.79128, 28.267151],
          [56.790677, 28.267301],
          [56.781661, 28.268078],
          [56.780954, 28.268139],
          [56.780755, 28.268238],
          [56.780397, 28.268418],
          [56.779695, 28.268418],
          [56.779287, 28.268418],
          [56.77899, 28.268517],
          [56.778454, 28.268697],
          [56.776737, 28.268697],
          [56.776507, 28.268697],
          [56.776111, 28.268797],
          [56.775397, 28.268976],
          [56.773483, 28.268976],
          [56.772897, 28.268976],
          [56.772699, 28.269076],
          [56.77234, 28.269255],
          [56.770397, 28.269255],
          [56.770117, 28.268976],
          [56.769007, 28.268976],
          [56.768451, 28.268697],
          [56.767897, 28.268697],
          [56.766784, 28.268139],
          [56.765117, 28.267869],
          [56.765117, 28.267589],
          [56.764284, 28.26759],
          [56.764007, 28.267301],
          [56.762617, 28.267301],
          [56.761507, 28.267031],
          [56.759564, 28.266752],
          [56.750951, 28.265644],
          [56.750394, 28.265365],
          [56.749007, 28.265365],
          [56.743451, 28.263141],
          [56.726504, 28.246193],
          [56.714561, 28.231749],
          [56.711505, 28.227859],
          [56.708448, 28.22369],
          [56.707338, 28.222582],
          [56.669559, 28.158417],
          [56.667615, 28.155364],
          [56.664559, 28.151754],
          [56.652059, 28.143137],
          [56.649559, 28.142588],
          [56.649279, 28.142309],
          [56.648446, 28.142309],
          [56.647892, 28.14202],
          [56.644559, 28.14202],
          [56.644002, 28.14175],
          [56.634836, 28.14202],
          [56.634556, 28.14175],
          [56.629324, 28.14175],
          [56.617613, 28.14202],
          [56.616779, 28.14175],
          [56.613723, 28.14175],
          [56.59789, 28.140085],
          [56.59539, 28.139526],
          [56.59261, 28.139526],
          [56.592056, 28.139247],
          [56.589557, 28.139247],
          [56.588723, 28.138968],
          [56.578678, 28.138968],
          [56.578443, 28.138968],
          [56.578042, 28.139069],
          [56.577333, 28.139247],
          [56.576179, 28.139247],
          [56.575943, 28.139247],
          [56.575541, 28.139348],
          [56.574833, 28.139526],
          [56.572465, 28.139526],
          [56.572053, 28.139526],
          [56.571752, 28.139627],
          [56.57122, 28.139805],
          [56.56622, 28.139806],
          [56.565667, 28.139526],
          [56.562887, 28.139526],
          [56.561777, 28.139247],
          [56.551777, 28.139247],
          [56.550664, 28.138968],
          [56.548164, 28.138968],
          [56.533997, 28.137032],
          [56.524551, 28.135358],
          [56.523998, 28.135358],
          [56.518441, 28.13425],
          [56.505941, 28.133422],
          [56.505108, 28.133134],
          [56.500661, 28.133134],
          [56.498995, 28.132855],
          [56.494275, 28.132855],
          [56.489828, 28.132585],
          [56.488161, 28.132305],
          [56.480938, 28.132305],
          [56.479272, 28.132026],
          [56.476772, 28.132026],
          [56.475105, 28.131747],
          [56.469552, 28.131747],
          [56.467605, 28.132026],
          [56.445382, 28.130919],
          [56.444549, 28.13064],
          [56.434549, 28.13064],
          [56.427326, 28.131747],
          [56.426806, 28.131747],
          [56.426216, 28.131747],
          [56.426012, 28.13185],
          [56.425659, 28.132026],
          [56.418716, 28.132026],
          [56.418159, 28.131747],
          [56.413436, 28.131747],
          [56.412603, 28.131747],
          [56.412046, 28.131747],
          [56.407879, 28.131747],
          [56.407603, 28.131468],
          [56.402326, 28.131468],
          [56.401769, 28.131189],
          [56.399027, 28.131189],
          [56.398436, 28.131189],
          [56.398232, 28.131292],
          [56.39788, 28.131468],
          [56.39149, 28.131468],
          [56.388711, 28.131701],
          [56.388156, 28.131747],
          [56.38785, 28.13185],
          [56.387323, 28.132026],
          [56.386177, 28.132026],
          [56.385936, 28.132026],
          [56.384416, 28.132408],
          [56.383713, 28.132585],
          [56.3824, 28.132585],
          [56.382323, 28.132585],
          [56.35371, 28.13841],
          [56.306781, 28.147751],
          [56.306211, 28.147864],
          [56.305401, 28.148],
          [56.304544, 28.148143],
          [56.302321, 28.148972],
          [56.297041, 28.150638],
          [56.255095, 28.161199],
          [56.254712, 28.161351],
          [56.232039, 28.16897],
          [56.218982, 28.173418],
          [56.201483, 28.180081],
          [56.197316, 28.182305],
          [56.135924, 28.211192],
          [56.123981, 28.218972],
          [56.120134, 28.222048],
          [56.119814, 28.222304],
          [56.119814, 28.222583],
          [56.119534, 28.223141],
          [56.104257, 28.234802],
          [56.101201, 28.237585],
          [56.073978, 28.262862],
          [56.055922, 28.294255],
          [56.055736, 28.294624],
          [56.046475, 28.324533],
          [56.045922, 28.325641],
          [56.045642, 28.327027],
          [56.044532, 28.33009],
          [56.036475, 28.376202],
          [56.036615, 28.376481],
          [56.036755, 28.37676],
          [56.036755, 28.37703],
          [56.036199, 28.390928],
          [56.036199, 28.392864],
          [56.036199, 28.401754],
          [56.033419, 28.446753],
          [56.033699, 28.447861],
          [56.033975, 28.452031],
          [56.034252, 28.45676],
          [56.033699, 28.466479],
          [56.033419, 28.467317],
          [56.033142, 28.470641],
          [56.033142, 28.473703],
          [56.033419, 28.474532],
          [56.034809, 28.489809],
          [56.034809, 28.505366],
          [56.035085, 28.506474],
          [56.035365, 28.508978],
          [56.035642, 28.511203],
          [56.036199, 28.516481],
          [56.036475, 28.518977],
          [56.036755, 28.520373],
          [56.037032, 28.525922],
          [56.037865, 28.528705],
          [56.037865, 28.529813],
          [56.038142, 28.530093],
          [56.038142, 28.532038],
          [56.038142, 28.532588],
          [56.038422, 28.533146],
          [56.038422, 28.533426],
          [56.038422, 28.533705],
          [56.038422, 28.537867],
          [56.038699, 28.538146],
          [56.038975, 28.542038],
          [56.038699, 28.555091],
          [56.038422, 28.555929],
          [56.038699, 28.556478],
          [56.044532, 28.585648],
          [56.045642, 28.591207],
          [56.045642, 28.591756],
          [56.048142, 28.602315],
          [56.048699, 28.606477],
          [56.054255, 28.641766],
          [56.054255, 28.655649],
          [56.057312, 28.673425],
          [56.057589, 28.685372],
          [56.057589, 28.695373],
          [56.057865, 28.696481],
          [56.058145, 28.700374],
          [56.058422, 28.704257],
          [56.058699, 28.709267],
          [56.058979, 28.713988],
          [56.059532, 28.720926],
          [56.059812, 28.723151],
          [56.060365, 28.729819],
          [56.060365, 28.741487],
          [56.059532, 28.752318],
          [56.058979, 28.757319],
          [56.057865, 28.764257],
          [56.057312, 28.76732],
          [56.054812, 28.792596],
          [56.052589, 28.81843],
          [56.051476, 28.830658],
          [56.050089, 28.840373],
          [56.049809, 28.842599],
          [56.048975, 28.862316],
          [56.049256, 28.862874],
          [56.049532, 28.867317],
          [56.049809, 28.869264],
          [56.054532, 28.878429],
          [56.054786, 28.879235],
          [56.054806, 28.879301],
          [56.054725, 28.879297],
          [56.053975, 28.879267],
          [56.053785, 28.879459],
          [56.053699, 28.879546],
          [56.053478, 28.879546],
          [56.053142, 28.879546],
          [56.051199, 28.880096],
          [56.051199, 28.880359],
          [56.051199, 28.880375],
          [56.046199, 28.881204],
          [56.046145, 28.881204],
          [56.045366, 28.881204],
          [56.04531, 28.88126],
          [56.045089, 28.881484],
          [56.032866, 28.880655],
          [56.023699, 28.879546],
          [56.020643, 28.878708],
          [56.019533, 28.878708],
          [56.018976, 28.878429],
          [56.017309, 28.878429],
          [56.01092, 28.876761],
          [56.00231, 28.875103],
          [56.00203, 28.874824],
          [56.00092, 28.874824],
          [56.000004, 28.874545],
          [55.99953, 28.874265],
          [55.99842, 28.874265],
          [55.99564, 28.873427],
          [55.994253, 28.873427],
          [55.979807, 28.870093],
          [55.901191, 28.848429],
          [55.899524, 28.8476],
          [55.898138, 28.847321],
          [55.897581, 28.847041],
          [55.892024, 28.845095],
          [55.891191, 28.845095],
          [55.886471, 28.843148],
          [55.884805, 28.842869],
          [55.875635, 28.839544],
          [55.868135, 28.83621],
          [55.845912, 28.828703],
          [55.845079, 28.828703],
          [55.843412, 28.827874],
          [55.815632, 28.819539],
          [55.805909, 28.815655],
          [55.805356, 28.815376],
          [55.720631, 28.779539],
          [55.720074, 28.778989],
          [55.718964, 28.77871],
          [55.718131, 28.778152],
          [55.717574, 28.777872],
          [55.645072, 28.738703],
          [55.640905, 28.737036],
          [55.640072, 28.736487],
          [55.617569, 28.727323],
          [55.600346, 28.72371],
          [55.598679, 28.72371],
          [55.587569, 28.720656],
          [55.587289, 28.720376],
          [55.586456, 28.720376],
          [55.584512, 28.719538],
          [55.583679, 28.719538],
          [55.561456, 28.708988],
          [55.5609, 28.708708],
          [55.548676, 28.70259],
          [55.53701, 28.697311],
          [55.51951, 28.693986],
          [55.518954, 28.693707],
          [55.51701, 28.693707],
          [55.516177, 28.693427],
          [55.512564, 28.693427],
          [55.512287, 28.693148],
          [55.50673, 28.693148],
          [55.505897, 28.692869],
          [55.504787, 28.692869],
          [55.503954, 28.692598],
          [55.500064, 28.69204],
          [55.462561, 28.695094],
          [55.458177, 28.695094],
          [55.457561, 28.695094],
          [55.457157, 28.695298],
          [55.457008, 28.695374],
          [55.448346, 28.69639],
          [55.447561, 28.696482],
          [55.439785, 28.700095],
          [55.437561, 28.701203],
          [55.427562, 28.709537],
          [55.42544, 28.710486],
          [55.425062, 28.710655],
          [55.424391, 28.710655],
          [55.423948, 28.710654],
          [55.420615, 28.711763],
          [55.394893, 28.722983],
          [55.394506, 28.723152],
          [55.394454, 28.723152],
          [55.393672, 28.723152],
          [55.393392, 28.723431],
          [55.382282, 28.726485],
          [55.379782, 28.727323],
          [55.372932, 28.729092],
          [55.372279, 28.729261],
          [55.371508, 28.729261],
          [55.370892, 28.729261],
          [55.370672, 28.729371],
          [55.370336, 28.72954],
          [55.370287, 28.72954],
          [55.369502, 28.72954],
          [55.369393, 28.729651],
          [55.369226, 28.729819],
          [55.369008, 28.729819],
          [55.368392, 28.729819],
          [55.368172, 28.72993],
          [55.367836, 28.730099],
          [55.367341, 28.730099],
          [55.366726, 28.730099],
          [55.366505, 28.730209],
          [55.366169, 28.730378],
          [55.365287, 28.730378],
          [55.364503, 28.730378],
          [55.364285, 28.730597],
          [55.364226, 28.730657],
          [55.362816, 28.730829],
          [55.362003, 28.730928],
          [55.361228, 28.731318],
          [55.360893, 28.731486],
          [55.360842, 28.731486],
          [55.360059, 28.731486],
          [55.359779, 28.731765],
          [55.359226, 28.731765],
          [55.358946, 28.732045],
          [55.349351, 28.736039],
          [55.348946, 28.736207],
          [55.348813, 28.736207],
          [55.348113, 28.736208],
          [55.347531, 28.736598],
          [55.347279, 28.736766],
          [55.346976, 28.736766],
          [55.346446, 28.736766],
          [55.344091, 28.737706],
          [55.34367, 28.737874],
          [55.343369, 28.737874],
          [55.342836, 28.737874],
          [55.341865, 28.738265],
          [55.341446, 28.738433],
          [55.341309, 28.738433],
          [55.340613, 28.738433],
          [55.33978, 28.738983],
          [55.334535, 28.740481],
          [55.333946, 28.74065],
          [55.333367, 28.74065],
          [55.332833, 28.74065],
          [55.331864, 28.74104],
          [55.331446, 28.741208],
          [55.328946, 28.741208],
          [55.32589, 28.741488],
          [55.323718, 28.741488],
          [55.323113, 28.741488],
          [55.322903, 28.74159],
          [55.322557, 28.741758],
          [55.322063, 28.741758],
          [55.321447, 28.741758],
          [55.321225, 28.741869],
          [55.32089, 28.742037],
          [55.320396, 28.742037],
          [55.31978, 28.742037],
          [55.319558, 28.742148],
          [55.319223, 28.742317],
          [55.318321, 28.742317],
          [55.318113, 28.742317],
          [55.301634, 28.746041],
          [55.30089, 28.746209],
          [55.299942, 28.746209],
          [55.2995, 28.746209],
          [55.298343, 28.746591],
          [55.297834, 28.746759],
          [55.297339, 28.746759],
          [55.29672, 28.746759],
          [55.2965, 28.74687],
          [55.296167, 28.747038],
          [55.29456, 28.747038],
          [55.293944, 28.747038],
          [55.293722, 28.747149],
          [55.293387, 28.747317],
          [55.283285, 28.747317],
          [55.282834, 28.747317],
          [55.282453, 28.747445],
          [55.282001, 28.747597],
          [55.273664, 28.747867],
          [55.273111, 28.747597],
          [55.272554, 28.747867],
          [55.271997, 28.747597],
          [55.266444, 28.747597],
          [55.266164, 28.747317],
          [55.265611, 28.747317],
          [55.263387, 28.747317],
          [55.260888, 28.747317],
          [55.260607, 28.747038],
          [55.248752, 28.747038],
          [55.247831, 28.747038],
          [55.247831, 28.746759],
          [55.248941, 28.741758],
          [55.251721, 28.726765],
          [55.251721, 28.724819],
          [55.251998, 28.72426],
          [55.251998, 28.72399],
          [55.253941, 28.721764],
          [55.254774, 28.721485],
          [55.258664, 28.718151],
          [55.285887, 28.704257],
          [55.303667, 28.69204],
          [55.307557, 28.689544],
          [55.350889, 28.660371],
          [55.365336, 28.647037],
          [55.368112, 28.643424],
          [55.368393, 28.642874],
          [55.378116, 28.630369],
          [55.423395, 28.588702],
          [55.426172, 28.586477],
          [55.426172, 28.586207],
          [55.447561, 28.560091],
          [55.451174, 28.552317],
          [55.452841, 28.543984],
          [55.453118, 28.5412],
          [55.449228, 28.525643],
          [55.448118, 28.522589],
          [55.442285, 28.516203],
          [55.431171, 28.509816],
          [55.430061, 28.508149],
          [55.426728, 28.502591],
          [55.415615, 28.467317],
          [55.415615, 28.466759],
          [55.414505, 28.462588],
          [55.414505, 28.46203],
          [55.410892, 28.440646],
          [55.409782, 28.433143],
          [55.408948, 28.42537],
          [55.408672, 28.423425],
          [55.408392, 28.420362],
          [55.407838, 28.418417],
          [55.407282, 28.397034],
          [55.407005, 28.39481],
          [55.406725, 28.392594],
          [55.406448, 28.388145],
          [55.406172, 28.387866],
          [55.406448, 28.386199],
          [55.407005, 28.380363],
          [55.407005, 28.362305],
          [55.406725, 28.360369],
          [55.405058, 28.354253],
          [55.404782, 28.352029],
          [55.408115, 28.318697],
          [55.407838, 28.318139],
          [55.408115, 28.31759],
          [55.408392, 28.315644],
          [55.408115, 28.315365],
          [55.408392, 28.314248],
          [55.408392, 28.312582],
          [55.408672, 28.310088],
          [55.408392, 28.309809],
          [55.408672, 28.308971],
          [55.408392, 28.308692],
          [55.408672, 28.308143],
          [55.408392, 28.307863],
          [55.408392, 28.307305],
          [55.408392, 28.306476],
          [55.407838, 28.303694],
          [55.407558, 28.301757],
          [55.406448, 28.296192],
          [55.405892, 28.286475],
          [55.405615, 28.285646],
          [55.405892, 28.283143],
          [55.405615, 28.282585],
          [55.405892, 28.279252],
          [55.405615, 28.2762],
          [55.405338, 28.274534],
          [55.405058, 28.272309],
          [55.404782, 28.269806],
          [55.404505, 28.26787],
          [55.402558, 28.258702],
          [55.402282, 28.256748],
          [55.401172, 28.252309],
          [55.400615, 28.248418],
          [55.400338, 28.245366],
          [55.400058, 28.244807],
          [55.403115, 28.213975],
          [55.402838, 28.213137],
          [55.401819, 28.211565],
          [55.401725, 28.211471],
          [55.401725, 28.194525],
          [55.399225, 28.182306],
          [55.398948, 28.179532],
          [55.398672, 28.176471],
          [55.398392, 28.174247],
          [55.397838, 28.170915],
          [55.397558, 28.168142],
          [55.397282, 28.162307],
          [55.397005, 28.160362],
          [55.396725, 28.158976],
          [55.396448, 28.156752],
          [55.396172, 28.15397],
          [55.395892, 28.1537],
          [55.395892, 28.152583],
          [55.395615, 28.148972],
          [55.395338, 28.146469],
          [55.395058, 28.146199],
          [55.399225, 28.098417],
          [55.399505, 28.096193],
          [55.400338, 28.085641],
          [55.400615, 28.067581],
          [55.400058, 28.063413],
          [55.399782, 28.062026],
          [55.399505, 28.06064],
          [55.399225, 28.058974],
          [55.398948, 28.057029],
          [55.398672, 28.055643],
          [55.398392, 28.054248],
          [55.398115, 28.052582],
          [55.396725, 28.046748],
          [55.396725, 28.045362],
          [55.396172, 28.041743],
          [55.395892, 28.040635],
          [55.395615, 28.039249],
          [55.395338, 28.037854],
          [55.395058, 28.036197],
          [55.394782, 28.03536],
          [55.391448, 28.025835],
          [55.391448, 28.025358],
          [55.391168, 28.023134],
          [55.390615, 28.020083],
          [55.390335, 28.018966],
          [55.386725, 28.005085],
          [55.386725, 27.998693],
          [55.388948, 27.996749],
          [55.393948, 27.994804],
          [55.406448, 27.987585],
          [55.441171, 27.971462],
          [55.443951, 27.969806],
          [55.459227, 27.961749],
          [55.467284, 27.955079],
          [55.488397, 27.933412],
          [55.490063, 27.932026],
          [55.490063, 27.931747],
          [55.49062, 27.931468],
          [55.500063, 27.923691],
          [55.50062, 27.923412],
          [55.500897, 27.922854],
          [55.51423, 27.902304],
          [55.516453, 27.900072],
          [55.51923, 27.897578],
          [55.523953, 27.889802],
          [55.534233, 27.875905],
          [55.534786, 27.875077],
          [55.535343, 27.874797],
          [55.545066, 27.860351],
          [55.575622, 27.820633],
          [55.575622, 27.820354],
          [55.575899, 27.820076],
          [55.579512, 27.810077],
          [55.579659, 27.809928],
          [55.580065, 27.808691],
          [55.578955, 27.806468],
          [55.579232, 27.805631],
          [55.579512, 27.803687],
          [55.579232, 27.799241],
          [55.578955, 27.798413],
          [55.578679, 27.796748],
          [55.577289, 27.794525],
          [55.572845, 27.788135],
          [55.569512, 27.784536],
          [55.568399, 27.782853],
          [55.568399, 27.782573],
          [55.567845, 27.782295],
          [55.567289, 27.781467],
          [55.547009, 27.766744],
          [55.528953, 27.752849],
          [55.528119, 27.752291],
          [55.525343, 27.750626],
          [55.524509, 27.750626],
          [55.51673, 27.745632],
          [55.500063, 27.742302],
          [55.491173, 27.738126],
          [55.487563, 27.735913],
          [55.48145, 27.729523],
          [55.480617, 27.729244],
          [55.47145, 27.718689],
          [55.46756, 27.709519],
          [55.465894, 27.705632],
          [55.45756, 27.688409],
          [55.444504, 27.651463],
          [55.443951, 27.651184],
          [55.442284, 27.648404],
          [55.440337, 27.645902],
          [55.437561, 27.643401],
          [55.437004, 27.642573],
          [55.410338, 27.619512],
          [55.388115, 27.603407],
          [55.387558, 27.603128],
          [55.380335, 27.599241],
          [55.379502, 27.598405],
          [55.366725, 27.589237],
          [55.363668, 27.58813],
          [55.331446, 27.571738],
          [55.322279, 27.563686],
          [55.318946, 27.560627],
          [55.312833, 27.554518],
          [55.310889, 27.551738],
          [55.293382, 27.528955],
          [55.29311, 27.52868],
          [55.276996, 27.511462],
          [55.276443, 27.511462],
          [55.276163, 27.510904],
          [55.27561, 27.510904],
          [55.275053, 27.510346],
          [55.274496, 27.510346],
          [55.273943, 27.510067],
          [55.273386, 27.509519],
          [55.27311, 27.50924],
          [55.26672, 27.504787],
          [55.25672, 27.495629],
          [55.226441, 27.481461],
          [55.204218, 27.47368],
          [55.199771, 27.471458],
          [55.190051, 27.466456],
          [55.188384, 27.465071],
          [55.187271, 27.464792],
          [55.186995, 27.464235],
          [55.184215, 27.46257],
          [55.168938, 27.452568],
          [55.140882, 27.435342],
          [55.140882, 27.435072],
          [55.139215, 27.434236],
          [55.138382, 27.434236],
          [55.138382, 27.433957],
          [55.137825, 27.433957],
          [55.135325, 27.432841],
          [55.132825, 27.432293],
          [55.132549, 27.432014],
          [55.132269, 27.431735],
          [55.129492, 27.430629],
          [55.128379, 27.430629],
          [55.128102, 27.430341],
          [55.126436, 27.430341],
          [55.125602, 27.430071],
          [55.119492, 27.430071],
          [55.118102, 27.430341],
          [55.083656, 27.430071],
          [55.082823, 27.429792],
          [55.07338, 27.429792],
          [55.071433, 27.429513],
          [55.068656, 27.429792],
          [55.06699, 27.429513],
          [55.065323, 27.429792],
          [55.053933, 27.429792],
          [55.05282, 27.430071],
          [55.050877, 27.430071],
          [55.049767, 27.430341],
          [55.04782, 27.430341],
          [55.046434, 27.430629],
          [55.044487, 27.430629],
          [55.0431, 27.430908],
          [55.040877, 27.430908],
          [55.03921, 27.431177],
          [55.036987, 27.431177],
          [55.03282, 27.431735],
          [55.030597, 27.431735],
          [55.02893, 27.432014],
          [55.024487, 27.432014],
          [55.022544, 27.432293],
          [55.014487, 27.432572],
          [55.008931, 27.432841],
          [55.000598, 27.432842],
          [54.998931, 27.43312],
          [54.994764, 27.43312],
          [54.993098, 27.433408],
          [54.977818, 27.434468],
          [54.975594, 27.434515],
          [54.974761, 27.434793],
          [54.971985, 27.434794],
          [54.967541, 27.435342],
          [54.963095, 27.435342],
          [54.961428, 27.435621],
          [54.956985, 27.435621],
          [54.955318, 27.435909],
          [54.948372, 27.435909],
          [54.932262, 27.437573],
          [54.928372, 27.437573],
          [54.927539, 27.437852],
          [54.923372, 27.437852],
          [54.896149, 27.440901],
          [54.893649, 27.440901],
          [54.887816, 27.441738],
          [54.885036, 27.441738],
          [54.883369, 27.442016],
          [54.878926, 27.442016],
          [54.878093, 27.442286],
          [54.874479, 27.442286],
          [54.868093, 27.442565],
          [54.866703, 27.442565],
          [54.86587, 27.442853],
          [54.86198, 27.442853],
          [54.85809, 27.443402],
          [54.85448, 27.443402],
          [54.853647, 27.443681],
          [54.847537, 27.443681],
          [54.846703, 27.443959],
          [54.841147, 27.443959],
          [54.837257, 27.444517],
          [54.835033, 27.444517],
          [54.832257, 27.445066],
          [54.82809, 27.445066],
          [54.8267, 27.445354],
          [54.82281, 27.445354],
          [54.821144, 27.445624],
          [54.816977, 27.445624],
          [54.816144, 27.445902],
          [54.810034, 27.445902],
          [54.809201, 27.446181],
          [54.805587, 27.446181],
          [54.804477, 27.44646],
          [54.800034, 27.44646],
          [54.798367, 27.446739],
          [54.795587, 27.446739],
          [54.793921, 27.447018],
          [54.789754, 27.447018],
          [54.788088, 27.447288],
          [54.785588, 27.447288],
          [54.784198, 27.447566],
          [54.780864, 27.447566],
          [54.775864, 27.448403],
          [54.773364, 27.448403],
          [54.772531, 27.448682],
          [54.769198, 27.448682],
          [54.768364, 27.448961],
          [54.738642, 27.451731],
          [54.732528, 27.452568],
          [54.728918, 27.453126],
          [54.726975, 27.453126],
          [54.724475, 27.453683],
          [54.722809, 27.453683],
          [54.720862, 27.454232],
          [54.719475, 27.454232],
          [54.686139, 27.462292],
          [54.684749, 27.462292],
          [54.65697, 27.466735],
          [54.65447, 27.466735],
          [54.651136, 27.467293],
          [54.64697, 27.467293],
          [54.646417, 27.467572],
          [54.641693, 27.467572],
          [54.641137, 27.467851],
          [54.628913, 27.467851],
          [54.62836, 27.467572],
          [54.62336, 27.467572],
          [54.62197, 27.467293],
          [54.61808, 27.467293],
          [54.61669, 27.467014],
          [54.611137, 27.466735],
          [54.610024, 27.466736],
          [54.608357, 27.466457],
          [54.603914, 27.466187],
          [54.5978, 27.466187],
          [54.596134, 27.465899],
          [54.589747, 27.465899],
          [54.588081, 27.46562],
          [54.533493, 27.462571],
          [54.530855, 27.462571],
          [54.530298, 27.46285],
          [54.519742, 27.462849],
          [54.518908, 27.462571],
          [54.514189, 27.462571],
          [54.512799, 27.462292],
          [54.504465, 27.462292],
          [54.503909, 27.462013],
          [54.500575, 27.462013],
          [54.499742, 27.461734],
          [54.497799, 27.461734],
          [54.497519, 27.461455],
          [54.456686, 27.453404],
          [54.455019, 27.453404],
          [54.45224, 27.452847],
          [54.450296, 27.452847],
          [54.445016, 27.452019],
          [54.44335, 27.452019],
          [54.44224, 27.452289],
          [54.438073, 27.452289],
          [54.435573, 27.452847],
          [54.431683, 27.452847],
          [54.430573, 27.453126],
          [54.429183, 27.453126],
          [54.415017, 27.456733],
          [54.413627, 27.456733],
          [54.41335, 27.457021],
          [54.411684, 27.45729],
          [54.404737, 27.45729],
          [54.404184, 27.457569],
          [54.401684, 27.457569],
          [54.40085, 27.457848],
          [54.398351, 27.457848],
          [54.39807, 27.458127],
          [54.39446, 27.458127],
          [54.394184, 27.457848],
          [54.393627, 27.457848],
          [54.39335, 27.458127],
          [54.392794, 27.458127],
          [54.392514, 27.457848],
          [54.389461, 27.457848],
          [54.388904, 27.458127],
          [54.388071, 27.458127],
          [54.387514, 27.458397],
          [54.386681, 27.458685],
          [54.385571, 27.458397],
          [54.383904, 27.458397],
          [54.375294, 27.45729],
          [54.371124, 27.45729],
          [54.370014, 27.457021],
          [54.358348, 27.458685],
          [54.353068, 27.458685],
          [54.352234, 27.458397],
          [54.333426, 27.455642],
          [54.301122, 27.450904],
          [54.298902, 27.450904],
          [54.286955, 27.448682],
          [54.281399, 27.447288],
          [54.279176, 27.447018],
          [54.277789, 27.44646],
          [54.276956, 27.44646],
          [54.253343, 27.440622],
          [54.236676, 27.436458],
          [54.235286, 27.436458],
          [54.157784, 27.421454],
          [54.153061, 27.420348],
          [54.109448, 27.408124],
          [54.107782, 27.407288],
          [54.106392, 27.407018],
          [54.096115, 27.402565],
          [54.053336, 27.38285],
          [54.048333, 27.380349],
          [54.01889, 27.368117],
          [54.01861, 27.367847],
          [54.015556, 27.366175],
          [54.01472, 27.366175],
          [54.010985, 27.365025],
          [54.0075, 27.363953],
          [53.999994, 27.363714],
          [53.99111, 27.360625],
          [53.99083, 27.360346],
          [53.97333, 27.354231],
          [53.97333, 27.353961],
          [53.97259, 27.353312],
          [53.97222, 27.353124],
          [53.968607, 27.350066],
          [53.967774, 27.346739],
          [53.967774, 27.346451],
          [53.967497, 27.345066],
          [53.967497, 27.344508],
          [53.967221, 27.34423],
          [53.966107, 27.340623],
          [53.965831, 27.335344],
          [53.966107, 27.33118],
          [53.966387, 27.330343],
          [53.966107, 27.322843],
          [53.966387, 27.320343],
          [53.966664, 27.319506],
          [53.97083, 27.307014],
          [53.971664, 27.305899],
          [53.98083, 27.29062],
          [53.984444, 27.286735],
          [53.986387, 27.284514],
          [53.996387, 27.27312],
          [53.99981, 27.274529],
          [54.003435, 27.275646],
          [54.006441, 27.275847],
          [54.009198, 27.276903],
          [54.01111, 27.277284],
          [54.01361, 27.276177],
          [54.015833, 27.274235],
          [54.020556, 27.266178],
          [54.021666, 27.26312],
          [54.024166, 27.259235],
          [54.027776, 27.250063],
          [54.03139, 27.243121],
          [54.034999, 27.235064],
          [54.034999, 27.230064],
          [54.033056, 27.227007],
          [54.030833, 27.224786],
          [54.029723, 27.222843],
          [54.02639, 27.219507],
          [54.023056, 27.213393],
          [54.01861, 27.210345],
          [54.015276, 27.207287],
          [54.011943, 27.205066],
          [54.000277, 27.193953],
          [53.992777, 27.189789],
          [53.992497, 27.189232],
          [53.99222, 27.188953],
          [53.98333, 27.179791],
          [53.98222, 27.178397],
          [53.934161, 27.124505],
          [53.933328, 27.123678],
          [53.918885, 27.115901],
          [53.913051, 27.113671],
          [53.911105, 27.113114],
          [53.906938, 27.111451],
          [53.859716, 27.090341],
          [53.859159, 27.089784],
          [53.857769, 27.089236],
          [53.828326, 27.062841],
          [53.827493, 27.062284],
          [53.827213, 27.062005],
          [53.826936, 27.061726],
          [53.826656, 27.061457],
          [53.824436, 27.058949],
          [53.811656, 27.051451],
          [53.811103, 27.050894],
          [53.805823, 27.048952],
          [53.7961, 27.045896],
          [53.794433, 27.045896],
          [53.793323, 27.045617],
          [53.785267, 27.045617],
          [53.78499, 27.045896],
          [53.7836, 27.045896],
          [53.77221, 27.048673],
          [53.771934, 27.048952],
          [53.771377, 27.048952],
          [53.7686, 27.050067],
          [53.757767, 27.052845],
          [53.690818, 27.079787],
          [53.689428, 27.080057],
          [53.688875, 27.080335],
          [53.683875, 27.082286],
          [53.648872, 27.092283],
          [53.632206, 27.098397],
          [53.579703, 27.125341],
          [53.56748, 27.135348],
          [53.566924, 27.136175],
          [53.566924, 27.136454],
          [53.566647, 27.136732],
          [53.56637, 27.13701],
          [53.56623, 27.13715],
          [53.550814, 27.150345],
          [53.549701, 27.151451],
          [53.543591, 27.157286],
          [53.541924, 27.157843],
          [53.540534, 27.157843],
          [53.540257, 27.158122],
          [53.538867, 27.158122],
          [53.538591, 27.158401],
          [53.538311, 27.158122],
          [53.537477, 27.158122],
          [53.536644, 27.157564],
          [53.536091, 27.157564],
          [53.530258, 27.150623],
          [53.523034, 27.137011],
          [53.521644, 27.133954],
          [53.518034, 27.125889],
          [53.500255, 27.103395],
          [53.499698, 27.103395],
          [53.499145, 27.102847],
          [53.496921, 27.102002],
          [53.496088, 27.101175],
          [53.495255, 27.101175],
          [53.494978, 27.100896],
          [53.494145, 27.100896],
          [53.493588, 27.100617],
          [53.492478, 27.100617],
          [53.489422, 27.100617],
          [53.484422, 27.100617],
          [53.472475, 27.102559],
          [53.471642, 27.102559],
          [53.471088, 27.102847],
          [53.469975, 27.102847],
          [53.469142, 27.103117],
          [53.467752, 27.103117],
          [53.466918, 27.103395],
          [53.465532, 27.103395],
          [53.463309, 27.103953],
          [53.461642, 27.103953],
          [53.449699, 27.106173],
          [53.448029, 27.106173],
          [53.446832, 27.106414],
          [53.446652, 27.106451],
          [53.446623, 27.106457],
          [53.446498, 27.106483],
          [53.410529, 27.11395],
          [53.409696, 27.11395],
          [53.40025, 27.120612],
          [53.398306, 27.122284],
          [53.38164, 27.13756],
          [53.37525, 27.147288],
          [53.374417, 27.148115],
          [53.36275, 27.170898],
          [53.356917, 27.186454],
          [53.354694, 27.190347],
          [53.354137, 27.190617],
          [53.35386, 27.190895],
          [53.35358, 27.191174],
          [53.353027, 27.191174],
          [53.352747, 27.191453],
          [53.351914, 27.191453],
          [53.35136, 27.191732],
          [53.350247, 27.191732],
          [53.34997, 27.19201],
          [53.349137, 27.19201],
          [53.34886, 27.19228],
          [53.348027, 27.19228],
          [53.347747, 27.192568],
          [53.347194, 27.192568],
          [53.346914, 27.192847],
          [53.34608, 27.192847],
          [53.345804, 27.193125],
          [53.344694, 27.193125],
          [53.344414, 27.193395],
          [53.34358, 27.193395],
          [53.338027, 27.195625],
          [53.336914, 27.195625],
          [53.328024, 27.198682],
          [53.326637, 27.198952],
          [53.277188, 27.222007],
          [53.271078, 27.224786],
          [53.269968, 27.225622],
          [53.229689, 27.239785],
          [53.183573, 27.251457],
          [53.174687, 27.253399],
          [53.157183, 27.259793],
          [53.15663, 27.260071],
          [53.148297, 27.264793],
          [53.14774, 27.264792],
          [53.147184, 27.265341],
          [53.14246, 27.266735],
          [53.131627, 27.271456],
          [53.13135, 27.271735],
          [53.130794, 27.272014],
          [53.130517, 27.272014],
          [53.129684, 27.271735],
          [53.128294, 27.271735],
          [53.127184, 27.271456],
          [53.116904, 27.271456],
          [53.115794, 27.271177],
          [53.107461, 27.271177],
          [53.106348, 27.270899],
          [53.102461, 27.270899],
          [53.092461, 27.27062],
          [53.090515, 27.270341],
          [53.081905, 27.270341],
          [53.080791, 27.27062],
          [53.077458, 27.27062],
          [53.076905, 27.270899],
          [53.074681, 27.270899],
          [53.051625, 27.274514],
          [53.050512, 27.275062],
          [53.049678, 27.275062],
          [53.043938, 27.277782],
          [53.033497, 27.282728],
          [53.019997, 27.289809],
          [53.009122, 27.296735],
          [53.008846, 27.297293],
          [52.999679, 27.304793],
          [52.97412, 27.313121],
          [52.952177, 27.327007],
          [52.945787, 27.329237],
          [52.944953, 27.329237],
          [52.944677, 27.329516],
          [52.943843, 27.329516],
          [52.943287, 27.329237],
          [52.94273, 27.329237],
          [52.94273, 27.328949],
          [52.94162, 27.328949],
          [52.94162, 27.328679],
          [52.921897, 27.318957],
          [52.896618, 27.301735],
          [52.896341, 27.301178],
          [52.886061, 27.29312],
          [52.885228, 27.29312],
          [52.885228, 27.292841],
          [52.884394, 27.292841],
          [52.884118, 27.29312],
          [52.882728, 27.29312],
          [52.882728, 27.293399],
          [52.879394, 27.295341],
          [52.878004, 27.295341],
          [52.877451, 27.29562],
          [52.876895, 27.295341],
          [52.873285, 27.295341],
          [52.873005, 27.29562],
          [52.868005, 27.29562],
          [52.867451, 27.295341],
          [52.864395, 27.292284],
          [52.863838, 27.292283],
          [52.861338, 27.293399],
          [52.855505, 27.297841],
          [52.856338, 27.302014],
          [52.855228, 27.304514],
          [52.854948, 27.304793],
          [52.854948, 27.305063],
          [52.854115, 27.30562],
          [52.850505, 27.308678],
          [52.849115, 27.308678],
          [52.848838, 27.308957],
          [52.848282, 27.308957],
          [52.848005, 27.308678],
          [52.847728, 27.308957],
          [52.847172, 27.308957],
          [52.845225, 27.310063],
          [52.842046, 27.317391],
          [52.838715, 27.321752],
          [52.832561, 27.322423],
          [52.828442, 27.324213],
          [52.82724, 27.322004],
          [52.823991, 27.319775],
          [52.819876, 27.321198],
          [52.816567, 27.323728],
          [52.809259, 27.32037],
          [52.805162, 27.318487],
          [52.792139, 27.311762],
          [52.789951, 27.311903],
          [52.737921, 27.274307],
          [52.482398, 26.989407],
        ],
      ],
    },
  },
  Ilam: {
    properties: { value: "Ilam", name: "استان ایلام, ایران", id: 3 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [45.69252, 33.69097],
          [45.694369, 33.690172],
          [45.69493, 33.688975],
          [45.694325, 33.688311],
          [45.694263, 33.687667],
          [45.694686, 33.687044],
          [45.694639, 33.686327],
          [45.694914, 33.686336],
          [45.695133, 33.686247],
          [45.695323, 33.686109],
          [45.695577, 33.686046],
          [45.695776, 33.685917],
          [45.695939, 33.685643],
          [45.696703, 33.685207],
          [45.696887, 33.685071],
          [45.697035, 33.684881],
          [45.697937, 33.685029],
          [45.698284, 33.685209],
          [45.698547, 33.685198],
          [45.698746, 33.685157],
          [45.698921, 33.684837],
          [45.699148, 33.684686],
          [45.699902, 33.684328],
          [45.699977, 33.684181],
          [45.699871, 33.683985],
          [45.699475, 33.68358],
          [45.69942, 33.6834],
          [45.699925, 33.683094],
          [45.700097, 33.682637],
          [45.700265, 33.68251],
          [45.70093, 33.681793],
          [45.701151, 33.681627],
          [45.701257, 33.68119],
          [45.701446, 33.681025],
          [45.701569, 33.680819],
          [45.70163, 33.680473],
          [45.701802, 33.680318],
          [45.702275, 33.680061],
          [45.702442, 33.679825],
          [45.702504, 33.679484],
          [45.702999, 33.678797],
          [45.70303, 33.678244],
          [45.702747, 33.677685],
          [45.705467, 33.675232],
          [45.705804, 33.675031],
          [45.706406, 33.674978],
          [45.706685, 33.674828],
          [45.706923, 33.674866],
          [45.707139, 33.674811],
          [45.708426, 33.674939],
          [45.708938, 33.675068],
          [45.709055, 33.674973],
          [45.70922, 33.674753],
          [45.709221, 33.674365],
          [45.70936, 33.674101],
          [45.70956, 33.673797],
          [45.70986, 33.673604],
          [45.71002, 33.673281],
          [45.710451, 33.672799],
          [45.710643, 33.672492],
          [45.710922, 33.672423],
          [45.711181, 33.672206],
          [45.711332, 33.671983],
          [45.711444, 33.671676],
          [45.712823, 33.67108],
          [45.713036, 33.670905],
          [45.713325, 33.670435],
          [45.714065, 33.669747],
          [45.714033, 33.669229],
          [45.714117, 33.669003],
          [45.714546, 33.668516],
          [45.714695, 33.668239],
          [45.714756, 33.667937],
          [45.714686, 33.667754],
          [45.714492, 33.66751],
          [45.714649, 33.667281],
          [45.714622, 33.667151],
          [45.714678, 33.666969],
          [45.714897, 33.66671],
          [45.715488, 33.666712],
          [45.715646, 33.666663],
          [45.716091, 33.666406],
          [45.716279, 33.666263],
          [45.716389, 33.666103],
          [45.716817, 33.665812],
          [45.717566, 33.665573],
          [45.717883, 33.665567],
          [45.718012, 33.665491],
          [45.718177, 33.665292],
          [45.71834, 33.66492],
          [45.718236, 33.664433],
          [45.718208, 33.663911],
          [45.718505, 33.663474],
          [45.718753, 33.662361],
          [45.719064, 33.661992],
          [45.719501, 33.661837],
          [45.719863, 33.661609],
          [45.72003, 33.661283],
          [45.721333, 33.660389],
          [45.721749, 33.660393],
          [45.734555, 33.65563],
          [45.747252, 33.649235],
          [45.77167, 33.636936],
          [45.772226, 33.63028],
          [45.772251, 33.629472],
          [45.771101, 33.62401],
          [45.770547, 33.623853],
          [45.769849, 33.623542],
          [45.76961, 33.623303],
          [45.769303, 33.62285],
          [45.768673, 33.622459],
          [45.767852, 33.622121],
          [45.767712, 33.621968],
          [45.767559, 33.621547],
          [45.767065, 33.620633],
          [45.767037, 33.620474],
          [45.767114, 33.619733],
          [45.766732, 33.618901],
          [45.766675, 33.61856],
          [45.766473, 33.618049],
          [45.766259, 33.61773],
          [45.766095, 33.617322],
          [45.766057, 33.617107],
          [45.766278, 33.616577],
          [45.766269, 33.616297],
          [45.765976, 33.615587],
          [45.765584, 33.615087],
          [45.764573, 33.614418],
          [45.763776, 33.614133],
          [45.762984, 33.613959],
          [45.76269, 33.613956],
          [45.761887, 33.614067],
          [45.761058, 33.613978],
          [45.760785, 33.61377],
          [45.759837, 33.6133],
          [45.759548, 33.613314],
          [45.759273, 33.613236],
          [45.759001, 33.612958],
          [45.758801, 33.612641],
          [45.758587, 33.611633],
          [45.758589, 33.610725],
          [45.758442, 33.610162],
          [45.757747, 33.609108],
          [45.757579, 33.608613],
          [45.757544, 33.608226],
          [45.757619, 33.607686],
          [45.757573, 33.607243],
          [45.757289, 33.60599],
          [45.757107, 33.60465],
          [45.756904, 33.604093],
          [45.756141, 33.602752],
          [45.75565, 33.602185],
          [45.755184, 33.601542],
          [45.754731, 33.600642],
          [45.754585, 33.600243],
          [45.754724, 33.598259],
          [45.754488, 33.598009],
          [45.754262, 33.597444],
          [45.754068, 33.596502],
          [45.753842, 33.596261],
          [45.7537, 33.595991],
          [45.753268, 33.593209],
          [45.753165, 33.592917],
          [45.757549, 33.594418],
          [45.774864, 33.602428],
          [45.788923, 33.607525],
          [45.80002, 33.612864],
          [45.801702, 33.612672],
          [45.801872, 33.612719],
          [45.802312, 33.61315],
          [45.803229, 33.613909],
          [45.803491, 33.61406],
          [45.803911, 33.614077],
          [45.804493, 33.61459],
          [45.804728, 33.614699],
          [45.805057, 33.615089],
          [45.805854, 33.615256],
          [45.80651, 33.615318],
          [45.806765, 33.615517],
          [45.807687, 33.615946],
          [45.808073, 33.616176],
          [45.80909, 33.617027],
          [45.809169, 33.617187],
          [45.809443, 33.617471],
          [45.809709, 33.617632],
          [45.81012, 33.617987],
          [45.810409, 33.618109],
          [45.810648, 33.618394],
          [45.811218, 33.618844],
          [45.811611, 33.619256],
          [45.811883, 33.619416],
          [45.812288, 33.619819],
          [45.812669, 33.619962],
          [45.812874, 33.62009],
          [45.812792, 33.620584],
          [45.812716, 33.620753],
          [45.812734, 33.620949],
          [45.81256, 33.621398],
          [45.812446, 33.621446],
          [45.812412, 33.621589],
          [45.812501, 33.622288],
          [45.812309, 33.622564],
          [45.812309, 33.622682],
          [45.8124, 33.623269],
          [45.81249, 33.623374],
          [45.812409, 33.623756],
          [45.812571, 33.62391],
          [45.812849, 33.623928],
          [45.812862, 33.624243],
          [45.812796, 33.624469],
          [45.812784, 33.624796],
          [45.812962, 33.625094],
          [45.812987, 33.625353],
          [45.812855, 33.625688],
          [45.81293, 33.625789],
          [45.812906, 33.626218],
          [45.81325, 33.627412],
          [45.813437, 33.627619],
          [45.813807, 33.627729],
          [45.814225, 33.627965],
          [45.814546, 33.628215],
          [45.814716, 33.628415],
          [45.81507, 33.628428],
          [45.815253, 33.628372],
          [45.815835, 33.628425],
          [45.816703, 33.628248],
          [45.817151, 33.627941],
          [45.817389, 33.62772],
          [45.817901, 33.627411],
          [45.818396, 33.627436],
          [45.819125, 33.627341],
          [45.819567, 33.627453],
          [45.819843, 33.627466],
          [45.820297, 33.627658],
          [45.820979, 33.62762],
          [45.821154, 33.627653],
          [45.821562, 33.627585],
          [45.82203, 33.62771],
          [45.822481, 33.627732],
          [45.822711, 33.627872],
          [45.822883, 33.627894],
          [45.823089, 33.628004],
          [45.82328, 33.628046],
          [45.823371, 33.628291],
          [45.823526, 33.628441],
          [45.824531, 33.628818],
          [45.824676, 33.628781],
          [45.825296, 33.628291],
          [45.825292, 33.628139],
          [45.825421, 33.627752],
          [45.82541, 33.627578],
          [45.825804, 33.627259],
          [45.826848, 33.627097],
          [45.827193, 33.627101],
          [45.827644, 33.627193],
          [45.827922, 33.627188],
          [45.828189, 33.626878],
          [45.828873, 33.626386],
          [45.828922, 33.626152],
          [45.829062, 33.626005],
          [45.829256, 33.625916],
          [45.829981, 33.625834],
          [45.830198, 33.62577],
          [45.830751, 33.625871],
          [45.831344, 33.625775],
          [45.832055, 33.625348],
          [45.832861, 33.625637],
          [45.833419, 33.625613],
          [45.833938, 33.62512],
          [45.834519, 33.625009],
          [45.834953, 33.625071],
          [45.835224, 33.624954],
          [45.835546, 33.62491],
          [45.835806, 33.625031],
          [45.836619, 33.624907],
          [45.836952, 33.625148],
          [45.837372, 33.625277],
          [45.838378, 33.625406],
          [45.838755, 33.625332],
          [45.839055, 33.625169],
          [45.839352, 33.625112],
          [45.839705, 33.625279],
          [45.840028, 33.625304],
          [45.840599, 33.625554],
          [45.841412, 33.625636],
          [45.841781, 33.625717],
          [45.842323, 33.626056],
          [45.843346, 33.626351],
          [45.843394, 33.626506],
          [45.843604, 33.62665],
          [45.84415, 33.626888],
          [45.844252, 33.627031],
          [45.844244, 33.62721],
          [45.844349, 33.627317],
          [45.845389, 33.627786],
          [45.845691, 33.628063],
          [45.84663, 33.62846],
          [45.847074, 33.628436],
          [45.847427, 33.628262],
          [45.848054, 33.628182],
          [45.848389, 33.628294],
          [45.848535, 33.628463],
          [45.848732, 33.628545],
          [45.848912, 33.628548],
          [45.849322, 33.628349],
          [45.849477, 33.628776],
          [45.849764, 33.628868],
          [45.850063, 33.628864],
          [45.85038, 33.628803],
          [45.850638, 33.628689],
          [45.850752, 33.628506],
          [45.851241, 33.628444],
          [45.851408, 33.628474],
          [45.851628, 33.628445],
          [45.851837, 33.628336],
          [45.852303, 33.628314],
          [45.8527, 33.628189],
          [45.852999, 33.628184],
          [45.85351, 33.628447],
          [45.85368, 33.628623],
          [45.853824, 33.628899],
          [45.854018, 33.629087],
          [45.854043, 33.629306],
          [45.854137, 33.629492],
          [45.854374, 33.629701],
          [45.85491, 33.629882],
          [45.855205, 33.630114],
          [45.8555, 33.630211],
          [45.855851, 33.630143],
          [45.856197, 33.629922],
          [45.85654, 33.629808],
          [45.856757, 33.629815],
          [45.856876, 33.629943],
          [45.85685, 33.63009],
          [45.857324, 33.630508],
          [45.857445, 33.630871],
          [45.857641, 33.631065],
          [45.857738, 33.631327],
          [45.857739, 33.631651],
          [45.857842, 33.631776],
          [45.857829, 33.631962],
          [45.858349, 33.632458],
          [45.858485, 33.632529],
          [45.858673, 33.63249],
          [45.858946, 33.632538],
          [45.860205, 33.63249],
          [45.861069, 33.632371],
          [45.861565, 33.632514],
          [45.862465, 33.632165],
          [45.862826, 33.631947],
          [45.863067, 33.631872],
          [45.86379, 33.632079],
          [45.864452, 33.63241],
          [45.865108, 33.632907],
          [45.866123, 33.633888],
          [45.867173, 33.633907],
          [45.86815, 33.633965],
          [45.868645, 33.634037],
          [45.869143, 33.634003],
          [45.869387, 33.634075],
          [45.869972, 33.634139],
          [45.870093, 33.634043],
          [45.870301, 33.633976],
          [45.870654, 33.634077],
          [45.870817, 33.634025],
          [45.87142, 33.634169],
          [45.871543, 33.634266],
          [45.871693, 33.634517],
          [45.872187, 33.634631],
          [45.872516, 33.634608],
          [45.872757, 33.634463],
          [45.873023, 33.634466],
          [45.873219, 33.634589],
          [45.873757, 33.634434],
          [45.873984, 33.634451],
          [45.874215, 33.634572],
          [45.874679, 33.634409],
          [45.875384, 33.63426],
          [45.875643, 33.634288],
          [45.875826, 33.634414],
          [45.875983, 33.634434],
          [45.876204, 33.634181],
          [45.87631, 33.634153],
          [45.877274, 33.634102],
          [45.877739, 33.633986],
          [45.877939, 33.633962],
          [45.878232, 33.634006],
          [45.878866, 33.633907],
          [45.879828, 33.633527],
          [45.879941, 33.633615],
          [45.880137, 33.633667],
          [45.880315, 33.633665],
          [45.880494, 33.633598],
          [45.880607, 33.633662],
          [45.881195, 33.633573],
          [45.881634, 33.633604],
          [45.881829, 33.633474],
          [45.882577, 33.633416],
          [45.882827, 33.633299],
          [45.883197, 33.633385],
          [45.883351, 33.633282],
          [45.88357, 33.633342],
          [45.883985, 33.633366],
          [45.884099, 33.633477],
          [45.884247, 33.63354],
          [45.884381, 33.633441],
          [45.884602, 33.633394],
          [45.884775, 33.633464],
          [45.884836, 33.633616],
          [45.885405, 33.633873],
          [45.885679, 33.634086],
          [45.886046, 33.634072],
          [45.886243, 33.633901],
          [45.886443, 33.633877],
          [45.88664, 33.633982],
          [45.887057, 33.634422],
          [45.887268, 33.634819],
          [45.887519, 33.634913],
          [45.887768, 33.635113],
          [45.887884, 33.635324],
          [45.887911, 33.635571],
          [45.887769, 33.635848],
          [45.887528, 33.636058],
          [45.887559, 33.636681],
          [45.887776, 33.636735],
          [45.888438, 33.636565],
          [45.889425, 33.636385],
          [45.889976, 33.636362],
          [45.890782, 33.636402],
          [45.891207, 33.636365],
          [45.89169, 33.636286],
          [45.892075, 33.636168],
          [45.892318, 33.63617],
          [45.892655, 33.636057],
          [45.893848, 33.636009],
          [45.89413, 33.635949],
          [45.894707, 33.636033],
          [45.894936, 33.635983],
          [45.895269, 33.635834],
          [45.895991, 33.635679],
          [45.896226, 33.635736],
          [45.896491, 33.635994],
          [45.897035, 33.635797],
          [45.89747, 33.635769],
          [45.898402, 33.6358],
          [45.898884, 33.635651],
          [45.899732, 33.635618],
          [45.899951, 33.635701],
          [45.900958, 33.635539],
          [45.901177, 33.635599],
          [45.901793, 33.636033],
          [45.901998, 33.636389],
          [45.901966, 33.636631],
          [45.902214, 33.636849],
          [45.903085, 33.637188],
          [45.903483, 33.637409],
          [45.903662, 33.637683],
          [45.903833, 33.637841],
          [45.904543, 33.637984],
          [45.904782, 33.638128],
          [45.905417, 33.63801],
          [45.906055, 33.638175],
          [45.906321, 33.638089],
          [45.907062, 33.638097],
          [45.916596, 33.627614],
          [45.905254, 33.606284],
          [45.906479, 33.606088],
          [45.906845, 33.606163],
          [45.907174, 33.606163],
          [45.908308, 33.605811],
          [45.909013, 33.605755],
          [45.909345, 33.605673],
          [45.910524, 33.605081],
          [45.910914, 33.605115],
          [45.911654, 33.605011],
          [45.912097, 33.604852],
          [45.912321, 33.604675],
          [45.913501, 33.604313],
          [45.913988, 33.604109],
          [45.914486, 33.604056],
          [45.915294, 33.603508],
          [45.915568, 33.603267],
          [45.915806, 33.603205],
          [45.916092, 33.603203],
          [45.91657, 33.602972],
          [45.916996, 33.60294],
          [45.91719, 33.602875],
          [45.917874, 33.602436],
          [45.918404, 33.602164],
          [45.918586, 33.601766],
          [45.919277, 33.601643],
          [45.919604, 33.601362],
          [45.91994, 33.601273],
          [45.920157, 33.601121],
          [45.920352, 33.600785],
          [45.920567, 33.600557],
          [45.920946, 33.600329],
          [45.92134, 33.599945],
          [45.921562, 33.599651],
          [45.9229, 33.599061],
          [45.923206, 33.599149],
          [45.923596, 33.599001],
          [45.923748, 33.598733],
          [45.924083, 33.598456],
          [45.924131, 33.598175],
          [45.924479, 33.597825],
          [45.925022, 33.597757],
          [45.925865, 33.597436],
          [45.926463, 33.59688],
          [45.926459, 33.596686],
          [45.926591, 33.596423],
          [45.926862, 33.596353],
          [45.927091, 33.596399],
          [45.927635, 33.596377],
          [45.927906, 33.596284],
          [45.928571, 33.596261],
          [45.928673, 33.596086],
          [45.929142, 33.595621],
          [45.929461, 33.595566],
          [45.929688, 33.595264],
          [45.929857, 33.59514],
          [45.930083, 33.595063],
          [45.930244, 33.594917],
          [45.930423, 33.594596],
          [45.930744, 33.594481],
          [45.930794, 33.594253],
          [45.93075, 33.594068],
          [45.931202, 33.593801],
          [45.931677, 33.593765],
          [45.931915, 33.593655],
          [45.932707, 33.593446],
          [45.933136, 33.593149],
          [45.93334, 33.59307],
          [45.933858, 33.592601],
          [45.934068, 33.592268],
          [45.934375, 33.592038],
          [45.934514, 33.591844],
          [45.934672, 33.591481],
          [45.935084, 33.591087],
          [45.935241, 33.590789],
          [45.93565, 33.590337],
          [45.935964, 33.590064],
          [45.936098, 33.589807],
          [45.936543, 33.589494],
          [45.937169, 33.588843],
          [45.937479, 33.588625],
          [45.937826, 33.588251],
          [45.937975, 33.587678],
          [45.938115, 33.587395],
          [45.938313, 33.587206],
          [45.938617, 33.587035],
          [45.938768, 33.586673],
          [45.939044, 33.586527],
          [45.939224, 33.586371],
          [45.939424, 33.586075],
          [45.939866, 33.585752],
          [45.940121, 33.585333],
          [45.940136, 33.585153],
          [45.940303, 33.584935],
          [45.940352, 33.584748],
          [45.940666, 33.584587],
          [45.940916, 33.584358],
          [45.941036, 33.584309],
          [45.941405, 33.583912],
          [45.941536, 33.58369],
          [45.941714, 33.583553],
          [45.942032, 33.583403],
          [45.942112, 33.583227],
          [45.942178, 33.582819],
          [45.942448, 33.582473],
          [45.942621, 33.582342],
          [45.943165, 33.582091],
          [45.94343, 33.581705],
          [45.943878, 33.581333],
          [45.944126, 33.581234],
          [45.944675, 33.580705],
          [45.94478, 33.580471],
          [45.944998, 33.580324],
          [45.945333, 33.58023],
          [45.945547, 33.579543],
          [45.94554, 33.579409],
          [45.945368, 33.579246],
          [45.945159, 33.578854],
          [45.944947, 33.578682],
          [45.945111, 33.578335],
          [45.945389, 33.578129],
          [45.945529, 33.57787],
          [45.945598, 33.577543],
          [45.945841, 33.57688],
          [45.946882, 33.575711],
          [45.947137, 33.575269],
          [45.947444, 33.575109],
          [45.948065, 33.575036],
          [45.94837, 33.574871],
          [45.948458, 33.574717],
          [45.948291, 33.574346],
          [45.948579, 33.574168],
          [45.948745, 33.573968],
          [45.949288, 33.573923],
          [45.949439, 33.573696],
          [45.949389, 33.57356],
          [45.949436, 33.573415],
          [45.949719, 33.572972],
          [45.949708, 33.572733],
          [45.950003, 33.572417],
          [45.949852, 33.572073],
          [45.949912, 33.571901],
          [45.950032, 33.571758],
          [45.950685, 33.57129],
          [45.951051, 33.570677],
          [45.951469, 33.570164],
          [45.951802, 33.569334],
          [45.951761, 33.568884],
          [45.952155, 33.568683],
          [45.953049, 33.568022],
          [45.953392, 33.56802],
          [45.95367, 33.568108],
          [45.953925, 33.568283],
          [45.954149, 33.568541],
          [45.9546, 33.568681],
          [45.955558, 33.569113],
          [45.955898, 33.569146],
          [45.956361, 33.569071],
          [45.957062, 33.569076],
          [45.957254, 33.569029],
          [45.957684, 33.568779],
          [45.957902, 33.568744],
          [45.958089, 33.568522],
          [45.958333, 33.568388],
          [45.958467, 33.56813],
          [45.958698, 33.568069],
          [45.958845, 33.567761],
          [45.958906, 33.567413],
          [45.959034, 33.56725],
          [45.959275, 33.567105],
          [45.959241, 33.566541],
          [45.959383, 33.566446],
          [45.959189, 33.56574],
          [45.959382, 33.565441],
          [45.959841, 33.565219],
          [45.959866, 33.565026],
          [45.959753, 33.564756],
          [45.959534, 33.564467],
          [45.959487, 33.564206],
          [45.959576, 33.564034],
          [45.960394, 33.56379],
          [45.960682, 33.563252],
          [45.961139, 33.563272],
          [45.961238, 33.562904],
          [45.961223, 33.562719],
          [45.961136, 33.562443],
          [45.960737, 33.561787],
          [45.961002, 33.56133],
          [45.96053, 33.56106],
          [45.96024, 33.560751],
          [45.959577, 33.560392],
          [45.959321, 33.560146],
          [45.959091, 33.559824],
          [45.959068, 33.559635],
          [45.959172, 33.559513],
          [45.959113, 33.559325],
          [45.958877, 33.559052],
          [45.958642, 33.558643],
          [45.958257, 33.558307],
          [45.95773, 33.557583],
          [45.957236, 33.557147],
          [45.957082, 33.557045],
          [45.956922, 33.557014],
          [45.95679, 33.556889],
          [45.95676, 33.556748],
          [45.956804, 33.556592],
          [45.957048, 33.556346],
          [45.957217, 33.556292],
          [45.956975, 33.555543],
          [45.957087, 33.555355],
          [45.956772, 33.555169],
          [45.956664, 33.555009],
          [45.95641, 33.554904],
          [45.956143, 33.554626],
          [45.955591, 33.55462],
          [45.955496, 33.554522],
          [45.955464, 33.554329],
          [45.955715, 33.553828],
          [45.95553, 33.553515],
          [45.955096, 33.553089],
          [45.954618, 33.552385],
          [45.953779, 33.551898],
          [45.953524, 33.551587],
          [45.953352, 33.551018],
          [45.953637, 33.550581],
          [45.953392, 33.550303],
          [45.953244, 33.550035],
          [45.953109, 33.549516],
          [45.952797, 33.549318],
          [45.952416, 33.548834],
          [45.952385, 33.548529],
          [45.952233, 33.548296],
          [45.951983, 33.548114],
          [45.951524, 33.547223],
          [45.950886, 33.546464],
          [45.950846, 33.546314],
          [45.950739, 33.546225],
          [45.950585, 33.546122],
          [45.950295, 33.546019],
          [45.950038, 33.545791],
          [45.949648, 33.545757],
          [45.949422, 33.545628],
          [45.949298, 33.54546],
          [45.948653, 33.545068],
          [45.94839, 33.544712],
          [45.94811, 33.544912],
          [45.947683, 33.545095],
          [45.947603, 33.545262],
          [45.947277, 33.545378],
          [45.946961, 33.545312],
          [45.924458, 33.530826],
          [45.895249, 33.512558],
          [45.863003, 33.493148],
          [45.873505, 33.493841],
          [45.874796, 33.493067],
          [45.878907, 33.490602],
          [45.879607, 33.490745],
          [45.881584, 33.4916],
          [45.882042, 33.491907],
          [45.883615, 33.492517],
          [45.884497, 33.492391],
          [45.887514, 33.493785],
          [45.889325, 33.493625],
          [45.889806, 33.49369],
          [45.890318, 33.493937],
          [45.890894, 33.493964],
          [45.891338, 33.493717],
          [45.891806, 33.493543],
          [45.892872, 33.492893],
          [45.893313, 33.492701],
          [45.893984, 33.49273],
          [45.89424, 33.492424],
          [45.895132, 33.492255],
          [45.895935, 33.492184],
          [45.896647, 33.492059],
          [45.898635, 33.492469],
          [45.899222, 33.492834],
          [45.899415, 33.492778],
          [45.899724, 33.492537],
          [45.900151, 33.492436],
          [45.900376, 33.491631],
          [45.900737, 33.491508],
          [45.901454, 33.491905],
          [45.902635, 33.492027],
          [45.904154, 33.490995],
          [45.905, 33.490055],
          [45.905232, 33.489955],
          [45.90554, 33.489638],
          [45.906038, 33.488907],
          [45.906681, 33.489031],
          [45.90769, 33.489724],
          [45.907991, 33.489829],
          [45.909336, 33.489833],
          [45.90978, 33.489764],
          [45.910271, 33.489792],
          [45.910761, 33.489642],
          [45.911697, 33.489582],
          [45.912217, 33.489633],
          [45.912669, 33.48974],
          [45.912964, 33.48994],
          [45.913385, 33.49048],
          [45.915795, 33.490662],
          [45.916186, 33.490847],
          [45.916687, 33.490908],
          [45.917105, 33.490845],
          [45.917815, 33.49051],
          [45.91795, 33.490285],
          [45.918214, 33.490038],
          [45.918573, 33.490082],
          [45.918827, 33.489979],
          [45.919311, 33.490096],
          [45.920044, 33.489987],
          [45.920341, 33.490105],
          [45.920773, 33.490062],
          [45.921179, 33.490227],
          [45.921675, 33.490271],
          [45.922366, 33.490472],
          [45.923107, 33.490385],
          [45.923405, 33.490639],
          [45.923737, 33.490796],
          [45.924123, 33.490746],
          [45.925132, 33.490469],
          [45.925462, 33.490556],
          [45.925937, 33.490492],
          [45.926891, 33.490683],
          [45.927204, 33.490673],
          [45.927653, 33.490727],
          [45.92858, 33.490705],
          [45.928815, 33.490984],
          [45.928789, 33.491256],
          [45.929463, 33.491142],
          [45.9299, 33.49127],
          [45.930286, 33.491504],
          [45.930861, 33.49133],
          [45.931301, 33.491445],
          [45.931667, 33.491311],
          [45.931968, 33.491286],
          [45.932308, 33.491358],
          [45.932519, 33.491566],
          [45.932987, 33.491756],
          [45.933292, 33.49182],
          [45.933395, 33.49193],
          [45.934085, 33.492095],
          [45.93453, 33.491411],
          [45.934957, 33.491139],
          [45.936015, 33.490699],
          [45.936239, 33.490429],
          [45.936603, 33.490119],
          [45.936945, 33.48992],
          [45.937166, 33.489434],
          [45.936938, 33.488571],
          [45.936998, 33.488368],
          [45.937522, 33.487637],
          [45.937558, 33.487414],
          [45.937712, 33.487177],
          [45.938264, 33.486983],
          [45.938909, 33.486631],
          [45.939226, 33.486269],
          [45.939231, 33.486],
          [45.939117, 33.485582],
          [45.9387, 33.48535],
          [45.938439, 33.485064],
          [45.938161, 33.484621],
          [45.938124, 33.48413],
          [45.938164, 33.483468],
          [45.938028, 33.483149],
          [45.938178, 33.482565],
          [45.938315, 33.482223],
          [45.938446, 33.482046],
          [45.939108, 33.48189],
          [45.939491, 33.481894],
          [45.940014, 33.482557],
          [45.94068, 33.482716],
          [45.942956, 33.483493],
          [45.943443, 33.484224],
          [45.943609, 33.484853],
          [45.944044, 33.485079],
          [45.944151, 33.485441],
          [45.944382, 33.485783],
          [45.944739, 33.486085],
          [45.945332, 33.486148],
          [45.945678, 33.486517],
          [45.945898, 33.486917],
          [45.946525, 33.486893],
          [45.946857, 33.487033],
          [45.94721, 33.487059],
          [45.947408, 33.48744],
          [45.947978, 33.487502],
          [45.948219, 33.487634],
          [45.94859, 33.488223],
          [45.949094, 33.488505],
          [45.949397, 33.489123],
          [45.950297, 33.489183],
          [45.95112, 33.48934],
          [45.951592, 33.489644],
          [45.952281, 33.489809],
          [45.952798, 33.48969],
          [45.953442, 33.489683],
          [45.953712, 33.489517],
          [45.953933, 33.489491],
          [45.954158, 33.489519],
          [45.954473, 33.489472],
          [45.954751, 33.48973],
          [45.954431, 33.490189],
          [45.954049, 33.490495],
          [45.953896, 33.490674],
          [45.953899, 33.490838],
          [45.954005, 33.49104],
          [45.953951, 33.49125],
          [45.953978, 33.492001],
          [45.954053, 33.49233],
          [45.954039, 33.492661],
          [45.95413, 33.492896],
          [45.954347, 33.492978],
          [45.9544, 33.493128],
          [45.954945, 33.493487],
          [45.955065, 33.493644],
          [45.955243, 33.494243],
          [45.955136, 33.494565],
          [45.955143, 33.49475],
          [45.95541, 33.494919],
          [45.955603, 33.495124],
          [45.9559, 33.495298],
          [45.956579, 33.49544],
          [45.956826, 33.495371],
          [45.956929, 33.495445],
          [45.957024, 33.496119],
          [45.957214, 33.496349],
          [45.957381, 33.497267],
          [45.957528, 33.497637],
          [45.957626, 33.497796],
          [45.957927, 33.497903],
          [45.958071, 33.498099],
          [45.958239, 33.498671],
          [45.958411, 33.498855],
          [45.958673, 33.498921],
          [45.959023, 33.498935],
          [45.959599, 33.498654],
          [45.959773, 33.498491],
          [45.959888, 33.498294],
          [45.960035, 33.498241],
          [45.960197, 33.498252],
          [45.960606, 33.498866],
          [45.960625, 33.499009],
          [45.960559, 33.49916],
          [45.960725, 33.499845],
          [45.961395, 33.500707],
          [45.961596, 33.500817],
          [45.961975, 33.50081],
          [45.962228, 33.501209],
          [45.962932, 33.501449],
          [45.963219, 33.501339],
          [45.963427, 33.501337],
          [45.963859, 33.501132],
          [45.964139, 33.501185],
          [45.964285, 33.501345],
          [45.96456, 33.501944],
          [45.964851, 33.502077],
          [45.96504, 33.502085],
          [45.965682, 33.501739],
          [45.965899, 33.5017],
          [45.966139, 33.501499],
          [45.966613, 33.501598],
          [45.967248, 33.501452],
          [45.967481, 33.501459],
          [45.968076, 33.501127],
          [45.968148, 33.500951],
          [45.968133, 33.500818],
          [45.968334, 33.50075],
          [45.968889, 33.501041],
          [45.969594, 33.500779],
          [45.970085, 33.500918],
          [45.970553, 33.501163],
          [45.971199, 33.501293],
          [45.97163, 33.501511],
          [45.972029, 33.501984],
          [45.972208, 33.502092],
          [45.972913, 33.502356],
          [45.973242, 33.502265],
          [45.973461, 33.502122],
          [45.973807, 33.5023],
          [45.973902, 33.502415],
          [45.974175, 33.502437],
          [45.974547, 33.502739],
          [45.975594, 33.503016],
          [45.975759, 33.503104],
          [45.976143, 33.503445],
          [45.976924, 33.503307],
          [45.977288, 33.503386],
          [45.977611, 33.503581],
          [45.977889, 33.503661],
          [45.977988, 33.503965],
          [45.978328, 33.504238],
          [45.978366, 33.504419],
          [45.978661, 33.504442],
          [45.978794, 33.504496],
          [45.978915, 33.505169],
          [45.979124, 33.505352],
          [45.979334, 33.50567],
          [45.979588, 33.505589],
          [45.979779, 33.505388],
          [45.980025, 33.505221],
          [45.980115, 33.505773],
          [45.980286, 33.505788],
          [45.980563, 33.505707],
          [45.981406, 33.505869],
          [45.981512, 33.506194],
          [45.982228, 33.506734],
          [45.982635, 33.506945],
          [45.982828, 33.507097],
          [45.983053, 33.507125],
          [45.983539, 33.50746],
          [45.984002, 33.507691],
          [45.984343, 33.507616],
          [45.984796, 33.507389],
          [45.985212, 33.50754],
          [45.985628, 33.507616],
          [45.986195, 33.507502],
          [45.987291, 33.506935],
          [45.988463, 33.506708],
          [45.98937, 33.507767],
          [45.98971, 33.507994],
          [45.98997, 33.508059],
          [45.990527, 33.507871],
          [45.990733, 33.507689],
          [45.990933, 33.507597],
          [45.991057, 33.507623],
          [45.991301, 33.507581],
          [45.991857, 33.507257],
          [45.992001, 33.507391],
          [45.992267, 33.507521],
          [45.992758, 33.507572],
          [45.99301, 33.50764],
          [45.99318, 33.507851],
          [45.993486, 33.507866],
          [45.993694, 33.507974],
          [45.994008, 33.508011],
          [45.994597, 33.50801],
          [45.994703, 33.508182],
          [45.99485, 33.508256],
          [45.995164, 33.508316],
          [45.995697, 33.50834],
          [45.996189, 33.508285],
          [45.997384, 33.510413],
          [45.998291, 33.511585],
          [45.998782, 33.511471],
          [45.998971, 33.511282],
          [45.999047, 33.510866],
          [45.999236, 33.510791],
          [45.999916, 33.511093],
          [46.000294, 33.511207],
          [46.000634, 33.511093],
          [46.000861, 33.510942],
          [46.00105, 33.510299],
          [46.001579, 33.509921],
          [46.002897, 33.509754],
          [46.003502, 33.509551],
          [46.003843, 33.509298],
          [46.004047, 33.509064],
          [46.003988, 33.508882],
          [46.00405, 33.508655],
          [46.004318, 33.508484],
          [46.004858, 33.5084],
          [46.005128, 33.508192],
          [46.005461, 33.508136],
          [46.005839, 33.507993],
          [46.006174, 33.507919],
          [46.00658, 33.50793],
          [46.007252, 33.50781],
          [46.007403, 33.507546],
          [46.007762, 33.507171],
          [46.00786, 33.506724],
          [46.00772, 33.506341],
          [46.007692, 33.50575],
          [46.007938, 33.505447],
          [46.00795, 33.505066],
          [46.008194, 33.504491],
          [46.008136, 33.504096],
          [46.007669, 33.503833],
          [46.007604, 33.503611],
          [46.007628, 33.503333],
          [46.00754, 33.503063],
          [46.007576, 33.502647],
          [46.007364, 33.502085],
          [46.007346, 33.501746],
          [46.007235, 33.501493],
          [46.007317, 33.501043],
          [46.007486, 33.500615],
          [46.007502, 33.500245],
          [46.007424, 33.500138],
          [46.007465, 33.49923],
          [46.007382, 33.498911],
          [46.007472, 33.498637],
          [46.007438, 33.498313],
          [46.007536, 33.497647],
          [46.00742, 33.497312],
          [46.007407, 33.497054],
          [46.007184, 33.496678],
          [46.006889, 33.496306],
          [46.006996, 33.495797],
          [46.006849, 33.495546],
          [46.006935, 33.495083],
          [46.00684, 33.494749],
          [46.007012, 33.493647],
          [46.006858, 33.493527],
          [46.006424, 33.491826],
          [46.005809, 33.490826],
          [46.00503, 33.490136],
          [46.004847, 33.489685],
          [46.004806, 33.489428],
          [46.004651, 33.489131],
          [46.004954, 33.488585],
          [46.005751, 33.488385],
          [46.006678, 33.488298],
          [46.007078, 33.488115],
          [46.007801, 33.487488],
          [46.007865, 33.486829],
          [46.008076, 33.486593],
          [46.008315, 33.486469],
          [46.008852, 33.486374],
          [46.009176, 33.486201],
          [46.009418, 33.485929],
          [46.009564, 33.485672],
          [46.009573, 33.485457],
          [46.009682, 33.485262],
          [46.009869, 33.485066],
          [46.010519, 33.484657],
          [46.011309, 33.484239],
          [46.012103, 33.484205],
          [46.01238, 33.484108],
          [46.012564, 33.483813],
          [46.013218, 33.483065],
          [46.013343, 33.482635],
          [46.013525, 33.482335],
          [46.013855, 33.482025],
          [46.014513, 33.481643],
          [46.014791, 33.48157],
          [46.015173, 33.481639],
          [46.015953, 33.481957],
          [46.016406, 33.481981],
          [46.016677, 33.482063],
          [46.016812, 33.482542],
          [46.016924, 33.482665],
          [46.017845, 33.482668],
          [46.018047, 33.482404],
          [46.018392, 33.482251],
          [46.018816, 33.482405],
          [46.019184, 33.482785],
          [46.019354, 33.483126],
          [46.019331, 33.483847],
          [46.01886, 33.484584],
          [46.018373, 33.485005],
          [46.018446, 33.485757],
          [46.018636, 33.486053],
          [46.018929, 33.486112],
          [46.019278, 33.486058],
          [46.019647, 33.485693],
          [46.020892, 33.485258],
          [46.021492, 33.485249],
          [46.023258, 33.484242],
          [46.023926, 33.483583],
          [46.024556, 33.482844],
          [46.025194, 33.481926],
          [46.025945, 33.481181],
          [46.026532, 33.480308],
          [46.026474, 33.480057],
          [46.026046, 33.479944],
          [46.026031, 33.479553],
          [46.026139, 33.479195],
          [46.026457, 33.478686],
          [46.026812, 33.47747],
          [46.027269, 33.47724],
          [46.02732, 33.476454],
          [46.028064, 33.474977],
          [46.028118, 33.474707],
          [46.027987, 33.474379],
          [46.027891, 33.47377],
          [46.027983, 33.473141],
          [46.028185, 33.472917],
          [46.02859, 33.472466],
          [46.029636, 33.471775],
          [46.030447, 33.471572],
          [46.031259, 33.471154],
          [46.031401, 33.469921],
          [46.031754, 33.469346],
          [46.032391, 33.468926],
          [46.03194, 33.468471],
          [46.031986, 33.46825],
          [46.032787, 33.467805],
          [46.033497, 33.467562],
          [46.033436, 33.467015],
          [46.03347, 33.466355],
          [46.033976, 33.465291],
          [46.034102, 33.464845],
          [46.034419, 33.464456],
          [46.034521, 33.463941],
          [46.034236, 33.46347],
          [46.03445, 33.463184],
          [46.03505, 33.463032],
          [46.035679, 33.462761],
          [46.035649, 33.462378],
          [46.035528, 33.461977],
          [46.035927, 33.461372],
          [46.036502, 33.461247],
          [46.036768, 33.460955],
          [46.036788, 33.460469],
          [46.036564, 33.460143],
          [46.036518, 33.45946],
          [46.036196, 33.458726],
          [46.036701, 33.458442],
          [46.037314, 33.458174],
          [46.037668, 33.457698],
          [46.037844, 33.45671],
          [46.03783, 33.456071],
          [46.038414, 33.455586],
          [46.039054, 33.4554],
          [46.03938, 33.455158],
          [46.039666, 33.454442],
          [46.039599, 33.45402],
          [46.038285, 33.453707],
          [46.038264, 33.453346],
          [46.038659, 33.45278],
          [46.039436, 33.452079],
          [46.040408, 33.451306],
          [46.041133, 33.450268],
          [46.041438, 33.449655],
          [46.042188, 33.449312],
          [46.042784, 33.449266],
          [46.042987, 33.448542],
          [46.043553, 33.447772],
          [46.044604, 33.447238],
          [46.043648, 33.445598],
          [46.042767, 33.445049],
          [46.042053, 33.444899],
          [46.041533, 33.444544],
          [46.041076, 33.44396],
          [46.040413, 33.443336],
          [46.039677, 33.443065],
          [46.038447, 33.443077],
          [46.037858, 33.442978],
          [46.037337, 33.442738],
          [46.036655, 33.442824],
          [46.036092, 33.442732],
          [46.035966, 33.442624],
          [46.036425, 33.441774],
          [46.036809, 33.441584],
          [46.037302, 33.441435],
          [46.038067, 33.441031],
          [46.038429, 33.440507],
          [46.038647, 33.439927],
          [46.039176, 33.439581],
          [46.039758, 33.438914],
          [46.040152, 33.437954],
          [46.040039, 33.437701],
          [46.040189, 33.437424],
          [46.040777, 33.437093],
          [46.040822, 33.436544],
          [46.041212, 33.436293],
          [46.041427, 33.435944],
          [46.041303, 33.435453],
          [46.041012, 33.435149],
          [46.040845, 33.434847],
          [46.040419, 33.434781],
          [46.040338, 33.434524],
          [46.040304, 33.434095],
          [46.040416, 33.433308],
          [46.040424, 33.432865],
          [46.0406, 33.432364],
          [46.040676, 33.431378],
          [46.041144, 33.431062],
          [46.041683, 33.430829],
          [46.043303, 33.430333],
          [46.044274, 33.429954],
          [46.044981, 33.429295],
          [46.045323, 33.429087],
          [46.045131, 33.428411],
          [46.045409, 33.427971],
          [46.045491, 33.427141],
          [46.045431, 33.426449],
          [46.045757, 33.424272],
          [46.04554, 33.423277],
          [46.045487, 33.422501],
          [46.045058, 33.421754],
          [46.045281, 33.421136],
          [46.045827, 33.419973],
          [46.046454, 33.419305],
          [46.047332, 33.41903],
          [46.047725, 33.418732],
          [46.048613, 33.417784],
          [46.048839, 33.417737],
          [46.049162, 33.417575],
          [46.049494, 33.417193],
          [46.049911, 33.416478],
          [46.049888, 33.416048],
          [46.050031, 33.415791],
          [46.049992, 33.415096],
          [46.05031, 33.414817],
          [46.051493, 33.415167],
          [46.051879, 33.415192],
          [46.052275, 33.415113],
          [46.053261, 33.415225],
          [46.053499, 33.415119],
          [46.053942, 33.415082],
          [46.054165, 33.414689],
          [46.054562, 33.414453],
          [46.054652, 33.414211],
          [46.054706, 33.413836],
          [46.055416, 33.412772],
          [46.05605, 33.412444],
          [46.056299, 33.412155],
          [46.0567, 33.411415],
          [46.057057, 33.411352],
          [46.057353, 33.411195],
          [46.057466, 33.410896],
          [46.057421, 33.410533],
          [46.057333, 33.410295],
          [46.058076, 33.410018],
          [46.058343, 33.409764],
          [46.059003, 33.409461],
          [46.059452, 33.409101],
          [46.05996, 33.408338],
          [46.060688, 33.40822],
          [46.061372, 33.407619],
          [46.061755, 33.406659],
          [46.062216, 33.406123],
          [46.063104, 33.405478],
          [46.064145, 33.40463],
          [46.064958, 33.403516],
          [46.065228, 33.403041],
          [46.065488, 33.401958],
          [46.065905, 33.401667],
          [46.066803, 33.401868],
          [46.067948, 33.401947],
          [46.068862, 33.402293],
          [46.069427, 33.40214],
          [46.070142, 33.40159],
          [46.070805, 33.40124],
          [46.070969, 33.399701],
          [46.070538, 33.399119],
          [46.069986, 33.398672],
          [46.069956, 33.397967],
          [46.070438, 33.397603],
          [46.072092, 33.397286],
          [46.072502, 33.39672],
          [46.072254, 33.396042],
          [46.072136, 33.395522],
          [46.072123, 33.394843],
          [46.072694, 33.393022],
          [46.073369, 33.392008],
          [46.073676, 33.391307],
          [46.074288, 33.390982],
          [46.075112, 33.390842],
          [46.075698, 33.391229],
          [46.076484, 33.391058],
          [46.076901, 33.390591],
          [46.077601, 33.389648],
          [46.077612, 33.389223],
          [46.077443, 33.388913],
          [46.077697, 33.388411],
          [46.078016, 33.387948],
          [46.07792, 33.362124],
          [46.078909, 33.361528],
          [46.079442, 33.361033],
          [46.079953, 33.360017],
          [46.079544, 33.359114],
          [46.078998, 33.358689],
          [46.078626, 33.358478],
          [46.077845, 33.357458],
          [46.079286, 33.356635],
          [46.08005, 33.356541],
          [46.081045, 33.356309],
          [46.082003, 33.355585],
          [46.08256, 33.354697],
          [46.082519, 33.353647],
          [46.0824, 33.353219],
          [46.081569, 33.352677],
          [46.080962, 33.351755],
          [46.08644, 33.34745],
          [46.087919, 33.346863],
          [46.088937, 33.34638],
          [46.089465, 33.345927],
          [46.089888, 33.345251],
          [46.089317, 33.34472],
          [46.08897, 33.3443],
          [46.088909, 33.344138],
          [46.089499, 33.343834],
          [46.090194, 33.343616],
          [46.090858, 33.343299],
          [46.091809, 33.342244],
          [46.092581, 33.34225],
          [46.094525, 33.339891],
          [46.094598, 33.339729],
          [46.095318, 33.339174],
          [46.095636, 33.339127],
          [46.095922, 33.339291],
          [46.096583, 33.33915],
          [46.097124, 33.339185],
          [46.098095, 33.338795],
          [46.098514, 33.338171],
          [46.098993, 33.337856],
          [46.099475, 33.337741],
          [46.099863, 33.337753],
          [46.100023, 33.337656],
          [46.100159, 33.337146],
          [46.1003, 33.336931],
          [46.102099, 33.335443],
          [46.102266, 33.334678],
          [46.102113, 33.334281],
          [46.102689, 33.334051],
          [46.103147, 33.333948],
          [46.103496, 33.333396],
          [46.104218, 33.332929],
          [46.104423, 33.332716],
          [46.10496, 33.33291],
          [46.105672, 33.332941],
          [46.107069, 33.332493],
          [46.10736, 33.332204],
          [46.107801, 33.33214],
          [46.108578, 33.3317],
          [46.10887, 33.33158],
          [46.108952, 33.331389],
          [46.109034, 33.330909],
          [46.109252, 33.330916],
          [46.109529, 33.330995],
          [46.109746, 33.331142],
          [46.110039, 33.331167],
          [46.110383, 33.331064],
          [46.110568, 33.330911],
          [46.111204, 33.330662],
          [46.111444, 33.330732],
          [46.111839, 33.330723],
          [46.112101, 33.330572],
          [46.11238, 33.330155],
          [46.112485, 33.329913],
          [46.112275, 33.329392],
          [46.112344, 33.328909],
          [46.112497, 33.328767],
          [46.113081, 33.328522],
          [46.113671, 33.328302],
          [46.114276, 33.328188],
          [46.114547, 33.32803],
          [46.114647, 33.327598],
          [46.114791, 33.327277],
          [46.114821, 33.326899],
          [46.114932, 33.326848],
          [46.11515, 33.326902],
          [46.115447, 33.326874],
          [46.115737, 33.326718],
          [46.116011, 33.326472],
          [46.116114, 33.326319],
          [46.115936, 33.325952],
          [46.115744, 33.325739],
          [46.115469, 33.32524],
          [46.115402, 33.324895],
          [46.115608, 33.324563],
          [46.115733, 33.324235],
          [46.116015, 33.32393],
          [46.116151, 33.323647],
          [46.116442, 33.323496],
          [46.116702, 33.323051],
          [46.116782, 33.322731],
          [46.116701, 33.322207],
          [46.116717, 33.321915],
          [46.116899, 33.321054],
          [46.116838, 33.320832],
          [46.116541, 33.320575],
          [46.116224, 33.320451],
          [46.11601, 33.319817],
          [46.115776, 33.319626],
          [46.115319, 33.319383],
          [46.114737, 33.319281],
          [46.114502, 33.319106],
          [46.114284, 33.31886],
          [46.114147, 33.318445],
          [46.114232, 33.31829],
          [46.114749, 33.318036],
          [46.114939, 33.317878],
          [46.115231, 33.317779],
          [46.115491, 33.317623],
          [46.115801, 33.317262],
          [46.115876, 33.316969],
          [46.116136, 33.316622],
          [46.116973, 33.316046],
          [46.117126, 33.315879],
          [46.117149, 33.315663],
          [46.117226, 33.315545],
          [46.117115, 33.315234],
          [46.116991, 33.315137],
          [46.116625, 33.315074],
          [46.116129, 33.314796],
          [46.115974, 33.314664],
          [46.11584, 33.314357],
          [46.11584, 33.314212],
          [46.11599, 33.313797],
          [46.116286, 33.313546],
          [46.116319, 33.313323],
          [46.116703, 33.312405],
          [46.116842, 33.312183],
          [46.11701, 33.312101],
          [46.117085, 33.311907],
          [46.117046, 33.311795],
          [46.116589, 33.311577],
          [46.1164, 33.311187],
          [46.116075, 33.311152],
          [46.115357, 33.311303],
          [46.115101, 33.311095],
          [46.115037, 33.310713],
          [46.11516, 33.310044],
          [46.115088, 33.309296],
          [46.115349, 33.308996],
          [46.115778, 33.308742],
          [46.116498, 33.308478],
          [46.116743, 33.308449],
          [46.116925, 33.308354],
          [46.117048, 33.308234],
          [46.117115, 33.307745],
          [46.117503, 33.307417],
          [46.117581, 33.307087],
          [46.11781, 33.306901],
          [46.117989, 33.306672],
          [46.118272, 33.30661],
          [46.118698, 33.306699],
          [46.118959, 33.306688],
          [46.119891, 33.306525],
          [46.120295, 33.306287],
          [46.121013, 33.306328],
          [46.121184, 33.306282],
          [46.121389, 33.306141],
          [46.121738, 33.305793],
          [46.122138, 33.305612],
          [46.122371, 33.305369],
          [46.12277, 33.305131],
          [46.123237, 33.304576],
          [46.123358, 33.304497],
          [46.123721, 33.304447],
          [46.123919, 33.304298],
          [46.124025, 33.304159],
          [46.123978, 33.30394],
          [46.124065, 33.303412],
          [46.124289, 33.302699],
          [46.124498, 33.302501],
          [46.125038, 33.30215],
          [46.125024, 33.302018],
          [46.124784, 33.301637],
          [46.125087, 33.300987],
          [46.125191, 33.300833],
          [46.125381, 33.300748],
          [46.125519, 33.300407],
          [46.125638, 33.30022],
          [46.125816, 33.300063],
          [46.125967, 33.299721],
          [46.126085, 33.299632],
          [46.126636, 33.299491],
          [46.126965, 33.299183],
          [46.127136, 33.299116],
          [46.127391, 33.299081],
          [46.127587, 33.299118],
          [46.127993, 33.299101],
          [46.128306, 33.299014],
          [46.128372, 33.298857],
          [46.128556, 33.298699],
          [46.128556, 33.29841],
          [46.128997, 33.298227],
          [46.129223, 33.298217],
          [46.129772, 33.298045],
          [46.129752, 33.297412],
          [46.129869, 33.296863],
          [46.129931, 33.296107],
          [46.130985, 33.296206],
          [46.131259, 33.296033],
          [46.131517, 33.295795],
          [46.131629, 33.29563],
          [46.13166, 33.295469],
          [46.131933, 33.295244],
          [46.132281, 33.294492],
          [46.132303, 33.293981],
          [46.132168, 33.293669],
          [46.132587, 33.29347],
          [46.132823, 33.293499],
          [46.133243, 33.293398],
          [46.133477, 33.293061],
          [46.133966, 33.292641],
          [46.134031, 33.292479],
          [46.13397, 33.292325],
          [46.134, 33.292185],
          [46.134161, 33.292099],
          [46.134336, 33.292072],
          [46.134525, 33.292126],
          [46.134977, 33.292034],
          [46.135467, 33.29162],
          [46.135842, 33.291071],
          [46.136089, 33.290933],
          [46.136164, 33.290785],
          [46.13615, 33.290632],
          [46.136513, 33.290536],
          [46.136845, 33.290336],
          [46.137292, 33.29025],
          [46.137487, 33.290163],
          [46.138589, 33.289519],
          [46.138805, 33.289444],
          [46.13947, 33.28934],
          [46.139517, 33.289197],
          [46.139795, 33.288924],
          [46.139859, 33.288705],
          [46.139997, 33.288551],
          [46.140034, 33.288198],
          [46.140327, 33.288031],
          [46.140447, 33.287875],
          [46.140603, 33.287893],
          [46.141055, 33.287656],
          [46.141313, 33.287589],
          [46.14189, 33.28705],
          [46.14215, 33.286895],
          [46.142435, 33.286647],
          [46.142812, 33.286465],
          [46.142883, 33.286369],
          [46.143026, 33.286359],
          [46.143804, 33.286498],
          [46.14388, 33.286137],
          [46.144082, 33.285744],
          [46.144287, 33.285552],
          [46.145378, 33.284697],
          [46.145565, 33.284576],
          [46.145936, 33.284442],
          [46.146308, 33.284074],
          [46.146711, 33.283805],
          [46.146974, 33.283706],
          [46.147104, 33.283522],
          [46.14747, 33.283197],
          [46.147657, 33.283127],
          [46.148095, 33.283146],
          [46.148547, 33.282797],
          [46.148862, 33.282667],
          [46.149218, 33.282642],
          [46.149545, 33.282441],
          [46.150742, 33.282533],
          [46.150838, 33.282351],
          [46.150941, 33.281866],
          [46.151069, 33.281752],
          [46.151268, 33.281665],
          [46.152002, 33.281555],
          [46.152101, 33.281452],
          [46.152625, 33.28125],
          [46.153019, 33.280968],
          [46.153357, 33.280856],
          [46.154, 33.280786],
          [46.154519, 33.280636],
          [46.155449, 33.28015],
          [46.156329, 33.2792],
          [46.157104, 33.278731],
          [46.157413, 33.278578],
          [46.157913, 33.278477],
          [46.158094, 33.278302],
          [46.159194, 33.277689],
          [46.159624, 33.277725],
          [46.159977, 33.27769],
          [46.160314, 33.277527],
          [46.160294, 33.277269],
          [46.160387, 33.277047],
          [46.160658, 33.276645],
          [46.160911, 33.276452],
          [46.161709, 33.276018],
          [46.162632, 33.275716],
          [46.163271, 33.275191],
          [46.163826, 33.274869],
          [46.164242, 33.274504],
          [46.164935, 33.274362],
          [46.165519, 33.274029],
          [46.165746, 33.273812],
          [46.16596, 33.273756],
          [46.16624, 33.273456],
          [46.166412, 33.273362],
          [46.166594, 33.273073],
          [46.16712, 33.272904],
          [46.167318, 33.272766],
          [46.167513, 33.272481],
          [46.167481, 33.272128],
          [46.167813, 33.271784],
          [46.168369, 33.271513],
          [46.168597, 33.271369],
          [46.168944, 33.271056],
          [46.16971, 33.2706],
          [46.170386, 33.27037],
          [46.170937, 33.270265],
          [46.171403, 33.270455],
          [46.171661, 33.270494],
          [46.171972, 33.270466],
          [46.172085, 33.270366],
          [46.172863, 33.2703],
          [46.173011, 33.270006],
          [46.173583, 33.269721],
          [46.173948, 33.269593],
          [46.174693, 33.269613],
          [46.174879, 33.269551],
          [46.175024, 33.269451],
          [46.175185, 33.269086],
          [46.175356, 33.268896],
          [46.176022, 33.268468],
          [46.176398, 33.268287],
          [46.176694, 33.268205],
          [46.176822, 33.268091],
          [46.177115, 33.268044],
          [46.177465, 33.268067],
          [46.177668, 33.268019],
          [46.178046, 33.2677],
          [46.178256, 33.267628],
          [46.178729, 33.267219],
          [46.178962, 33.266909],
          [46.179322, 33.266201],
          [46.179662, 33.265838],
          [46.180034, 33.265663],
          [46.180208, 33.265461],
          [46.180461, 33.265268],
          [46.180662, 33.26526],
          [46.181257, 33.265409],
          [46.181481, 33.265551],
          [46.182566, 33.26605],
          [46.182769, 33.266071],
          [46.182927, 33.26602],
          [46.183117, 33.265877],
          [46.183263, 33.265851],
          [46.183659, 33.266019],
          [46.184276, 33.26597],
          [46.185126, 33.265681],
          [46.185306, 33.265432],
          [46.185636, 33.26531],
          [46.185836, 33.265297],
          [46.186449, 33.265073],
          [46.18676, 33.265159],
          [46.187213, 33.265118],
          [46.187426, 33.264943],
          [46.187869, 33.26484],
          [46.188028, 33.26463],
          [46.188247, 33.264522],
          [46.188496, 33.264477],
          [46.188962, 33.26453],
          [46.189199, 33.264465],
          [46.189827, 33.26397],
          [46.189986, 33.262541],
          [46.189894, 33.262191],
          [46.190014, 33.261685],
          [46.190529, 33.261373],
          [46.190639, 33.261216],
          [46.19086, 33.260766],
          [46.191531, 33.259828],
          [46.191642, 33.259515],
          [46.191669, 33.25923],
          [46.191586, 33.258927],
          [46.191596, 33.258789],
          [46.191162, 33.258317],
          [46.190696, 33.258338],
          [46.190376, 33.258213],
          [46.189845, 33.257739],
          [46.189334, 33.257734],
          [46.189229, 33.257605],
          [46.188976, 33.257611],
          [46.188887, 33.257519],
          [46.18883, 33.257358],
          [46.188652, 33.25722],
          [46.188603, 33.257063],
          [46.188629, 33.256928],
          [46.188562, 33.256684],
          [46.188676, 33.256521],
          [46.188813, 33.256462],
          [46.188972, 33.256462],
          [46.189103, 33.256381],
          [46.189245, 33.255992],
          [46.189345, 33.255939],
          [46.189484, 33.255983],
          [46.189566, 33.255956],
          [46.189866, 33.255493],
          [46.190007, 33.255405],
          [46.190342, 33.25535],
          [46.190663, 33.25515],
          [46.190896, 33.254909],
          [46.190931, 33.254806],
          [46.190855, 33.254666],
          [46.190719, 33.254519],
          [46.190173, 33.25443],
          [46.190004, 33.254466],
          [46.189854, 33.254823],
          [46.189446, 33.254936],
          [46.189232, 33.254901],
          [46.189119, 33.254745],
          [46.189039, 33.254474],
          [46.189067, 33.254344],
          [46.189372, 33.254227],
          [46.189414, 33.2541],
          [46.189372, 33.253925],
          [46.188979, 33.253142],
          [46.188843, 33.253109],
          [46.188685, 33.25316],
          [46.188522, 33.253332],
          [46.18828, 33.253472],
          [46.188159, 33.253471],
          [46.188046, 33.253406],
          [46.188002, 33.253295],
          [46.18815, 33.252858],
          [46.188125, 33.252766],
          [46.187827, 33.252655],
          [46.187794, 33.252581],
          [46.187964, 33.252294],
          [46.187964, 33.252134],
          [46.187883, 33.252024],
          [46.187748, 33.251974],
          [46.187377, 33.252058],
          [46.187243, 33.252036],
          [46.187204, 33.251987],
          [46.187223, 33.251869],
          [46.187322, 33.251743],
          [46.187841, 33.251383],
          [46.18792, 33.251232],
          [46.187916, 33.250914],
          [46.187983, 33.25081],
          [46.188618, 33.250525],
          [46.188621, 33.250394],
          [46.18849, 33.25015],
          [46.188549, 33.250065],
          [46.188666, 33.250027],
          [46.189027, 33.250065],
          [46.189109, 33.250016],
          [46.189008, 33.249624],
          [46.189057, 33.249433],
          [46.189017, 33.249287],
          [46.18864, 33.249002],
          [46.188618, 33.248852],
          [46.188754, 33.248674],
          [46.18878, 33.248515],
          [46.188678, 33.248329],
          [46.188699, 33.248194],
          [46.188881, 33.248093],
          [46.188928, 33.247868],
          [46.188688, 33.247434],
          [46.188745, 33.247332],
          [46.187525, 33.243189],
          [46.187508, 33.242966],
          [46.187604, 33.242852],
          [46.188098, 33.24282],
          [46.188121, 33.242742],
          [46.188066, 33.242633],
          [46.187717, 33.242547],
          [46.187615, 33.242452],
          [46.187632, 33.242375],
          [46.187741, 33.242309],
          [46.188099, 33.242222],
          [46.188119, 33.241968],
          [46.188188, 33.241893],
          [46.187981, 33.241743],
          [46.187969, 33.241631],
          [46.188049, 33.24148],
          [46.188199, 33.241356],
          [46.188243, 33.241212],
          [46.188387, 33.241089],
          [46.18853, 33.241052],
          [46.18873, 33.241107],
          [46.188905, 33.241075],
          [46.189088, 33.240974],
          [46.189171, 33.240675],
          [46.189185, 33.240376],
          [46.189407, 33.240348],
          [46.189584, 33.240253],
          [46.189679, 33.240111],
          [46.189719, 33.239887],
          [46.190419, 33.239744],
          [46.190786, 33.239713],
          [46.190883, 33.239741],
          [46.190881, 33.240105],
          [46.190984, 33.240155],
          [46.191075, 33.240139],
          [46.191237, 33.240013],
          [46.191577, 33.239906],
          [46.191469, 33.239601],
          [46.191571, 33.239395],
          [46.191711, 33.239279],
          [46.191803, 33.23929],
          [46.191932, 33.239666],
          [46.192001, 33.239687],
          [46.19226, 33.239561],
          [46.192494, 33.239508],
          [46.192553, 33.239469],
          [46.192433, 33.239171],
          [46.192485, 33.239105],
          [46.192766, 33.238992],
          [46.192827, 33.238611],
          [46.193356, 33.238567],
          [46.193522, 33.238383],
          [46.193588, 33.23795],
          [46.193407, 33.237499],
          [46.193258, 33.237349],
          [46.193021, 33.237227],
          [46.192859, 33.236801],
          [46.192856, 33.236579],
          [46.192355, 33.235889],
          [46.192295, 33.235462],
          [46.192332, 33.235296],
          [46.192159, 33.234991],
          [46.192126, 33.234798],
          [46.192158, 33.23457],
          [46.192279, 33.234383],
          [46.192413, 33.234012],
          [46.192171, 33.233293],
          [46.192255, 33.233181],
          [46.192161, 33.232907],
          [46.192178, 33.232807],
          [46.192297, 33.232706],
          [46.192319, 33.232554],
          [46.192137, 33.231938],
          [46.191937, 33.231695],
          [46.191999, 33.231456],
          [46.191755, 33.231102],
          [46.191856, 33.230867],
          [46.191856, 33.230565],
          [46.192163, 33.230499],
          [46.192235, 33.230435],
          [46.192236, 33.230349],
          [46.192181, 33.230262],
          [46.191863, 33.230149],
          [46.191723, 33.229895],
          [46.19152, 33.229732],
          [46.191516, 33.229511],
          [46.191288, 33.229233],
          [46.191059, 33.228654],
          [46.190696, 33.228121],
          [46.19033, 33.227948],
          [46.189727, 33.227931],
          [46.189576, 33.22789],
          [46.189462, 33.227638],
          [46.189341, 33.227591],
          [46.189155, 33.227585],
          [46.188909, 33.227709],
          [46.188566, 33.227759],
          [46.188457, 33.227665],
          [46.188018, 33.22755],
          [46.187754, 33.227421],
          [46.187573, 33.227368],
          [46.187308, 33.227376],
          [46.187147, 33.227206],
          [46.186776, 33.227153],
          [46.186487, 33.227028],
          [46.186006, 33.226944],
          [46.185676, 33.226742],
          [46.185587, 33.226348],
          [46.185331, 33.225899],
          [46.185354, 33.225473],
          [46.185028, 33.225168],
          [46.184871, 33.224735],
          [46.184923, 33.224412],
          [46.184748, 33.223726],
          [46.18467, 33.223604],
          [46.184325, 33.223461],
          [46.184009, 33.222749],
          [46.183633, 33.222379],
          [46.18331, 33.221708],
          [46.182929, 33.221202],
          [46.182785, 33.220818],
          [46.182744, 33.220204],
          [46.182696, 33.220099],
          [46.182562, 33.220004],
          [46.182387, 33.219688],
          [46.182397, 33.219333],
          [46.182791, 33.21854],
          [46.183078, 33.218154],
          [46.183657, 33.217732],
          [46.184062, 33.217294],
          [46.184194, 33.216776],
          [46.184414, 33.216348],
          [46.18507, 33.215638],
          [46.185245, 33.215066],
          [46.185357, 33.214808],
          [46.185552, 33.214599],
          [46.185724, 33.214086],
          [46.186071, 33.213623],
          [46.186304, 33.213146],
          [46.186616, 33.212742],
          [46.186981, 33.212131],
          [46.186872, 33.211801],
          [46.186274, 33.210931],
          [46.186249, 33.210767],
          [46.186331, 33.210497],
          [46.186312, 33.210186],
          [46.186239, 33.209967],
          [46.186122, 33.209842],
          [46.185476, 33.209538],
          [46.18451, 33.209175],
          [46.184139, 33.209142],
          [46.18385, 33.209176],
          [46.183666, 33.209308],
          [46.18343, 33.209751],
          [46.183284, 33.209881],
          [46.183061, 33.209944],
          [46.182637, 33.209979],
          [46.182352, 33.20993],
          [46.182079, 33.209781],
          [46.181918, 33.209641],
          [46.181853, 33.209473],
          [46.181917, 33.208467],
          [46.181835, 33.208308],
          [46.181594, 33.208135],
          [46.181222, 33.207957],
          [46.180927, 33.20773],
          [46.180599, 33.207306],
          [46.180548, 33.207071],
          [46.180584, 33.206344],
          [46.180683, 33.206065],
          [46.180822, 33.205907],
          [46.181573, 33.2055],
          [46.181738, 33.205203],
          [46.182103, 33.2049],
          [46.183233, 33.20422],
          [46.197343, 33.201279],
          [46.205533, 33.202433],
          [46.214986, 33.203587],
          [46.215438, 33.203438],
          [46.215605, 33.203216],
          [46.21552, 33.203069],
          [46.214884, 33.202682],
          [46.214819, 33.202513],
          [46.214041, 33.201576],
          [46.213915, 33.201017],
          [46.213797, 33.200794],
          [46.213622, 33.200604],
          [46.213472, 33.200503],
          [46.213145, 33.200445],
          [46.212649, 33.200236],
          [46.212484, 33.200248],
          [46.212286, 33.200353],
          [46.211769, 33.200404],
          [46.211559, 33.200395],
          [46.211492, 33.200267],
          [46.211529, 33.200069],
          [46.211447, 33.199694],
          [46.211475, 33.199608],
          [46.211614, 33.199595],
          [46.212062, 33.199312],
          [46.212461, 33.19866],
          [46.212366, 33.19802],
          [46.212346, 33.197072],
          [46.211971, 33.197043],
          [46.210848, 33.197103],
          [46.209393, 33.197275],
          [46.208911, 33.197238],
          [46.208547, 33.197117],
          [46.208216, 33.196873],
          [46.207907, 33.19676],
          [46.207416, 33.196789],
          [46.206804, 33.196653],
          [46.206275, 33.196422],
          [46.205739, 33.196116],
          [46.205312, 33.195785],
          [46.205022, 33.195453],
          [46.204883, 33.195036],
          [46.204759, 33.194842],
          [46.204015, 33.194626],
          [46.203057, 33.194029],
          [46.202108, 33.193633],
          [46.199953, 33.192441],
          [46.198729, 33.192007],
          [46.198319, 33.191737],
          [46.198122, 33.191371],
          [46.19806, 33.191045],
          [46.198132, 33.190282],
          [46.198115, 33.189693],
          [46.198182, 33.189274],
          [46.198333, 33.188875],
          [46.199255, 33.187787],
          [46.19934, 33.187406],
          [46.199361, 33.186861],
          [46.199276, 33.186429],
          [46.19901, 33.185686],
          [46.198294, 33.183953],
          [46.198186, 33.183577],
          [46.198193, 33.182982],
          [46.198079, 33.182538],
          [46.197528, 33.181554],
          [46.196846, 33.18078],
          [46.196626, 33.180616],
          [46.196337, 33.180551],
          [46.195158, 33.180429],
          [46.194346, 33.180393],
          [46.193847, 33.180319],
          [46.193436, 33.180142],
          [46.19182, 33.179889],
          [46.191203, 33.179737],
          [46.189447, 33.179014],
          [46.188777, 33.178691],
          [46.188271, 33.178519],
          [46.186831, 33.177601],
          [46.186203, 33.17698],
          [46.184972, 33.175373],
          [46.184332, 33.173348],
          [46.18377, 33.172466],
          [46.18335, 33.171918],
          [46.183143, 33.171467],
          [46.182367, 33.170611],
          [46.182039, 33.170378],
          [46.181568, 33.170241],
          [46.181215, 33.170013],
          [46.180844, 33.16955],
          [46.180607, 33.169156],
          [46.180273, 33.16878],
          [46.179987, 33.168325],
          [46.179969, 33.168089],
          [46.180028, 33.167806],
          [46.18057, 33.167012],
          [46.180611, 33.166348],
          [46.180485, 33.166074],
          [46.179324, 33.165088],
          [46.178338, 33.164578],
          [46.177856, 33.16421],
          [46.176863, 33.163171],
          [46.176611, 33.162623],
          [46.176363, 33.161447],
          [46.176149, 33.161037],
          [46.175782, 33.160783],
          [46.174883, 33.160378],
          [46.17363, 33.159671],
          [46.172986, 33.15928],
          [46.172557, 33.15896],
          [46.171865, 33.15863],
          [46.171016, 33.15767],
          [46.170327, 33.157444],
          [46.169729, 33.157103],
          [46.169254, 33.157094],
          [46.168924, 33.157019],
          [46.168739, 33.156912],
          [46.167859, 33.155505],
          [46.167468, 33.155185],
          [46.16714, 33.154546],
          [46.166877, 33.15422],
          [46.166601, 33.15399],
          [46.166451, 33.153674],
          [46.166058, 33.153325],
          [46.165645, 33.153091],
          [46.165102, 33.152902],
          [46.164662, 33.152829],
          [46.164397, 33.15283],
          [46.163148, 33.153191],
          [46.162348, 33.153107],
          [46.161463, 33.152636],
          [46.161286, 33.152604],
          [46.160924, 33.152435],
          [46.160677, 33.152218],
          [46.159978, 33.151406],
          [46.15977, 33.150804],
          [46.159742, 33.150344],
          [46.159731, 33.149991],
          [46.159944, 33.14925],
          [46.159903, 33.148263],
          [46.159841, 33.147914],
          [46.159589, 33.147252],
          [46.159262, 33.146944],
          [46.159031, 33.146614],
          [46.158892, 33.146252],
          [46.158854, 33.145889],
          [46.158859, 33.145618],
          [46.159211, 33.144786],
          [46.159477, 33.143749],
          [46.159525, 33.143377],
          [46.159462, 33.143062],
          [46.159296, 33.142694],
          [46.158474, 33.142277],
          [46.158096, 33.141967],
          [46.15741, 33.141544],
          [46.155484, 33.140682],
          [46.154868, 33.140379],
          [46.154548, 33.140147],
          [46.154415, 33.139756],
          [46.154124, 33.13937],
          [46.153567, 33.138908],
          [46.153338, 33.13854],
          [46.153116, 33.137905],
          [46.152926, 33.137662],
          [46.151543, 33.136988],
          [46.151213, 33.13667],
          [46.151126, 33.136458],
          [46.151179, 33.136152],
          [46.151072, 33.13574],
          [46.15023, 33.135178],
          [46.150064, 33.134909],
          [46.150079, 33.134625],
          [46.150014, 33.134399],
          [46.149808, 33.13412],
          [46.149506, 33.133835],
          [46.149088, 33.133565],
          [46.148394, 33.132917],
          [46.146658, 33.132028],
          [46.146284, 33.131905],
          [46.145962, 33.131496],
          [46.145651, 33.131395],
          [46.145394, 33.131378],
          [46.144558, 33.131516],
          [46.144079, 33.131547],
          [46.143475, 33.131485],
          [46.142814, 33.13135],
          [46.142373, 33.13121],
          [46.141871, 33.130932],
          [46.141572, 33.130707],
          [46.141375, 33.130388],
          [46.141111, 33.130202],
          [46.140076, 33.129965],
          [46.139795, 33.129809],
          [46.139355, 33.129504],
          [46.138669, 33.12891],
          [46.138087, 33.128254],
          [46.137781, 33.128174],
          [46.137507, 33.128187],
          [46.137202, 33.128134],
          [46.137001, 33.12797],
          [46.136675, 33.126791],
          [46.136691, 33.126006],
          [46.136643, 33.125264],
          [46.136515, 33.124922],
          [46.136116, 33.124505],
          [46.135115, 33.123915],
          [46.134744, 33.123802],
          [46.134363, 33.123746],
          [46.133939, 33.123504],
          [46.133792, 33.123353],
          [46.133143, 33.123022],
          [46.132979, 33.122852],
          [46.13259, 33.122814],
          [46.131108, 33.123072],
          [46.130685, 33.123073],
          [46.130212, 33.122982],
          [46.129979, 33.122862],
          [46.129314, 33.122204],
          [46.128854, 33.121483],
          [46.12859, 33.121297],
          [46.128093, 33.120698],
          [46.127855, 33.120127],
          [46.12766, 33.120011],
          [46.127131, 33.119848],
          [46.126926, 33.119596],
          [46.126569, 33.119001],
          [46.126539, 33.118742],
          [46.126743, 33.118238],
          [46.125454, 33.11708],
          [46.124974, 33.116986],
          [46.124116, 33.116895],
          [46.123875, 33.116672],
          [46.123716, 33.116428],
          [46.123581, 33.116005],
          [46.123463, 33.115893],
          [46.123236, 33.115821],
          [46.122731, 33.115996],
          [46.122681, 33.116192],
          [46.122794, 33.116696],
          [46.122741, 33.116904],
          [46.12255, 33.117168],
          [46.122304, 33.117364],
          [46.121957, 33.117484],
          [46.121696, 33.117528],
          [46.121359, 33.11747],
          [46.120803, 33.117278],
          [46.120356, 33.117189],
          [46.119847, 33.116934],
          [46.119545, 33.116721],
          [46.119352, 33.11649],
          [46.119253, 33.116231],
          [46.119269, 33.11593],
          [46.119196, 33.115579],
          [46.1191, 33.11538],
          [46.118899, 33.115145],
          [46.118584, 33.114928],
          [46.118323, 33.114824],
          [46.118063, 33.114797],
          [46.117769, 33.11488],
          [46.11753, 33.114888],
          [46.115952, 33.114776],
          [46.115363, 33.114915],
          [46.11428, 33.114929],
          [46.113899, 33.115022],
          [46.113201, 33.1154],
          [46.11272, 33.116],
          [46.1125, 33.116126],
          [46.111529, 33.116887],
          [46.109862, 33.118031],
          [46.109047, 33.118446],
          [46.108693, 33.118931],
          [46.108699, 33.119266],
          [46.108827, 33.119636],
          [46.108802, 33.120048],
          [46.108695, 33.120413],
          [46.108137, 33.120916],
          [46.107854, 33.121307],
          [46.107716, 33.121942],
          [46.107742, 33.122379],
          [46.107698, 33.122601],
          [46.107488, 33.122786],
          [46.106891, 33.123163],
          [46.106685, 33.123248],
          [46.1065, 33.123241],
          [46.105944, 33.123071],
          [46.10528, 33.122997],
          [46.10512, 33.12287],
          [46.104845, 33.122421],
          [46.104169, 33.122034],
          [46.103763, 33.121883],
          [46.10134, 33.121657],
          [46.101038, 33.121687],
          [46.100738, 33.121798],
          [46.100516, 33.121941],
          [46.100402, 33.122131],
          [46.10002, 33.122439],
          [46.099535, 33.122202],
          [46.098619, 33.12189],
          [46.098055, 33.12181],
          [46.096854, 33.121425],
          [46.096391, 33.121322],
          [46.0954, 33.121204],
          [46.094771, 33.120975],
          [46.094336, 33.120735],
          [46.093556, 33.120146],
          [46.092916, 33.119583],
          [46.092668, 33.119482],
          [46.092267, 33.119424],
          [46.091605, 33.119454],
          [46.089846, 33.119059],
          [46.089646, 33.119116],
          [46.089435, 33.119251],
          [46.089299, 33.119506],
          [46.089305, 33.120183],
          [46.089071, 33.120471],
          [46.088767, 33.12076],
          [46.0879, 33.121337],
          [46.087633, 33.121454],
          [46.086626, 33.121544],
          [46.085983, 33.121791],
          [46.085721, 33.121974],
          [46.085432, 33.122321],
          [46.084599, 33.122711],
          [46.084131, 33.122835],
          [46.083685, 33.123038],
          [46.083187, 33.123189],
          [46.082481, 33.123221],
          [46.082132, 33.123143],
          [46.081772, 33.123143],
          [46.081209, 33.123284],
          [46.080975, 33.123456],
          [46.080266, 33.124227],
          [46.080015, 33.124402],
          [46.079666, 33.124517],
          [46.078178, 33.124848],
          [46.077295, 33.124806],
          [46.076973, 33.124662],
          [46.076752, 33.124204],
          [46.075791, 33.123839],
          [46.075158, 33.123666],
          [46.074561, 33.123585],
          [46.074087, 33.123606],
          [46.073807, 33.123698],
          [46.073637, 33.12382],
          [46.073541, 33.123986],
          [46.07351, 33.124184],
          [46.07357, 33.124581],
          [46.073467, 33.124742],
          [46.07252, 33.125521],
          [46.072348, 33.12581],
          [46.072116, 33.126059],
          [46.07176, 33.12629],
          [46.070415, 33.126806],
          [46.069898, 33.126933],
          [46.067836, 33.12712],
          [46.067099, 33.126959],
          [46.066592, 33.126665],
          [46.066366, 33.126114],
          [46.065926, 33.125445],
          [46.06575, 33.125039],
          [46.065671, 33.12476],
          [46.065694, 33.124558],
          [46.065642, 33.124335],
          [46.065542, 33.124171],
          [46.065402, 33.124068],
          [46.065207, 33.124024],
          [46.064716, 33.124129],
          [46.063284, 33.124214],
          [46.062633, 33.124165],
          [46.062092, 33.124054],
          [46.061519, 33.124063],
          [46.059988, 33.124396],
          [46.059222, 33.124703],
          [46.058826, 33.12498],
          [46.058553, 33.125098],
          [46.057315, 33.125293],
          [46.055127, 33.125992],
          [46.054541, 33.12624],
          [46.054174, 33.126507],
          [46.05386, 33.126853],
          [46.053639, 33.127304],
          [46.053174, 33.128255],
          [46.046832, 33.11135],
          [46.04037, 33.094539],
          [46.04071, 33.094524],
          [46.040996, 33.094288],
          [46.041245, 33.094538],
          [46.041634, 33.094334],
          [46.041757, 33.094346],
          [46.041841, 33.094475],
          [46.041971, 33.094535],
          [46.042122, 33.094532],
          [46.042239, 33.094473],
          [46.042301, 33.094347],
          [46.042471, 33.094296],
          [46.042541, 33.094102],
          [46.042634, 33.094047],
          [46.04284, 33.094062],
          [46.043047, 33.09401],
          [46.043214, 33.093899],
          [46.043577, 33.093837],
          [46.04369, 33.093591],
          [46.04399, 33.093529],
          [46.044161, 33.093528],
          [46.044197, 33.093692],
          [46.044063, 33.093809],
          [46.044013, 33.093955],
          [46.044354, 33.094316],
          [46.044634, 33.094274],
          [46.044988, 33.094302],
          [46.045823, 33.09406],
          [46.04595, 33.093895],
          [46.045971, 33.093781],
          [46.046537, 33.093525],
          [46.046744, 33.093522],
          [46.047461, 33.093362],
          [46.04758, 33.093479],
          [46.047836, 33.093441],
          [46.048062, 33.093507],
          [46.048116, 33.093619],
          [46.04821, 33.093664],
          [46.048353, 33.093678],
          [46.048537, 33.093608],
          [46.048702, 33.093613],
          [46.048853, 33.093923],
          [46.04895, 33.094006],
          [46.049193, 33.093871],
          [46.049388, 33.093843],
          [46.050059, 33.094087],
          [46.05022, 33.094269],
          [46.051022, 33.094364],
          [46.051585, 33.094146],
          [46.051761, 33.093973],
          [46.051864, 33.093763],
          [46.051863, 33.093592],
          [46.051767, 33.093366],
          [46.051817, 33.093219],
          [46.052221, 33.092974],
          [46.052295, 33.092631],
          [46.052493, 33.092564],
          [46.052671, 33.092423],
          [46.052799, 33.092115],
          [46.053117, 33.092049],
          [46.053416, 33.091888],
          [46.053464, 33.091759],
          [46.053966, 33.091524],
          [46.054159, 33.091442],
          [46.054594, 33.091533],
          [46.054904, 33.091656],
          [46.055082, 33.091538],
          [46.055316, 33.091564],
          [46.055866, 33.091272],
          [46.0561, 33.091078],
          [46.056289, 33.091029],
          [46.056331, 33.090851],
          [46.056471, 33.090661],
          [46.056693, 33.090469],
          [46.056945, 33.090393],
          [46.057076, 33.090288],
          [46.057509, 33.090352],
          [46.058233, 33.090289],
          [46.058455, 33.090417],
          [46.058615, 33.090445],
          [46.059279, 33.090232],
          [46.059654, 33.090212],
          [46.059813, 33.09029],
          [46.059972, 33.090268],
          [46.060142, 33.090074],
          [46.060208, 33.089914],
          [46.060317, 33.089823],
          [46.060471, 33.089786],
          [46.060541, 33.089592],
          [46.060837, 33.089564],
          [46.061056, 33.089603],
          [46.061449, 33.089558],
          [46.061573, 33.089455],
          [46.061691, 33.089446],
          [46.061801, 33.089647],
          [46.061931, 33.089735],
          [46.062318, 33.089835],
          [46.062523, 33.089993],
          [46.062913, 33.090081],
          [46.06365, 33.089806],
          [46.064043, 33.089833],
          [46.064104, 33.089971],
          [46.064267, 33.090015],
          [46.064449, 33.089984],
          [46.06478, 33.089701],
          [46.065061, 33.089907],
          [46.065128, 33.090359],
          [46.065419, 33.090458],
          [46.065151, 33.091005],
          [46.065189, 33.091175],
          [46.065578, 33.091428],
          [46.065735, 33.091594],
          [46.066276, 33.091657],
          [46.066659, 33.091624],
          [46.066838, 33.091704],
          [46.067382, 33.091584],
          [46.067502, 33.091414],
          [46.067661, 33.091371],
          [46.06824, 33.091382],
          [46.068461, 33.091262],
          [46.068552, 33.090982],
          [46.068766, 33.090879],
          [46.069168, 33.090921],
          [46.069592, 33.090606],
          [46.069817, 33.090573],
          [46.070018, 33.090445],
          [46.070102, 33.090331],
          [46.070443, 33.090185],
          [46.070517, 33.089985],
          [46.070767, 33.089854],
          [46.070772, 33.089655],
          [46.070868, 33.089489],
          [46.070899, 33.089269],
          [46.071251, 33.089093],
          [46.07185, 33.089206],
          [46.072025, 33.089127],
          [46.072188, 33.089121],
          [46.072365, 33.089317],
          [46.072573, 33.089336],
          [46.072741, 33.089423],
          [46.073253, 33.089424],
          [46.073401, 33.089531],
          [46.073442, 33.08964],
          [46.073624, 33.089802],
          [46.07348, 33.089953],
          [46.073451, 33.090085],
          [46.073484, 33.090333],
          [46.073582, 33.090465],
          [46.074049, 33.090656],
          [46.074163, 33.090802],
          [46.074511, 33.090924],
          [46.074741, 33.090885],
          [46.074826, 33.09092],
          [46.07482, 33.091042],
          [46.074898, 33.091172],
          [46.075011, 33.091219],
          [46.075469, 33.09118],
          [46.075625, 33.091269],
          [46.075694, 33.091439],
          [46.07582, 33.091483],
          [46.076521, 33.091022],
          [46.076695, 33.091108],
          [46.076884, 33.091104],
          [46.077075, 33.090966],
          [46.077379, 33.09087],
          [46.077742, 33.09083],
          [46.07798, 33.090729],
          [46.078208, 33.090365],
          [46.078493, 33.090101],
          [46.078969, 33.090059],
          [46.079259, 33.089838],
          [46.079337, 33.089704],
          [46.07969, 33.089627],
          [46.079793, 33.089659],
          [46.079911, 33.089892],
          [46.080026, 33.089945],
          [46.080262, 33.089904],
          [46.080507, 33.090044],
          [46.080662, 33.090079],
          [46.080788, 33.09003],
          [46.080873, 33.089872],
          [46.081025, 33.089802],
          [46.081135, 33.08981],
          [46.081265, 33.089992],
          [46.081371, 33.090349],
          [46.081836, 33.090556],
          [46.082013, 33.090559],
          [46.082226, 33.090815],
          [46.082451, 33.090975],
          [46.082939, 33.09103],
          [46.083214, 33.090994],
          [46.083338, 33.091177],
          [46.083619, 33.091261],
          [46.083756, 33.091397],
          [46.084369, 33.091497],
          [46.084754, 33.091227],
          [46.084971, 33.091212],
          [46.086144, 33.090742],
          [46.086347, 33.090768],
          [46.086453, 33.090667],
          [46.086487, 33.090457],
          [46.086834, 33.090283],
          [46.08717, 33.089586],
          [46.087295, 33.089437],
          [46.087516, 33.08929],
          [46.087727, 33.088961],
          [46.087948, 33.088885],
          [46.088148, 33.088879],
          [46.088489, 33.08849],
          [46.088445, 33.088222],
          [46.088907, 33.088032],
          [46.089013, 33.087904],
          [46.08905, 33.087732],
          [46.08868, 33.087333],
          [46.088812, 33.087255],
          [46.088844, 33.086897],
          [46.08893, 33.08686],
          [46.089536, 33.08669],
          [46.089785, 33.086868],
          [46.089766, 33.086987],
          [46.09002, 33.087258],
          [46.090252, 33.087324],
          [46.090502, 33.087336],
          [46.091145, 33.087574],
          [46.091085, 33.087804],
          [46.091126, 33.087913],
          [46.091488, 33.087967],
          [46.09169, 33.087916],
          [46.091908, 33.087928],
          [46.09244, 33.088152],
          [46.092664, 33.088136],
          [46.092771, 33.088377],
          [46.093167, 33.08837],
          [46.09348, 33.08809],
          [46.093769, 33.088135],
          [46.093938, 33.088078],
          [46.094334, 33.087878],
          [46.094458, 33.08772],
          [46.094617, 33.087654],
          [46.094935, 33.087611],
          [46.095252, 33.087446],
          [46.09544, 33.087172],
          [46.09565, 33.087058],
          [46.095798, 33.086631],
          [46.096085, 33.086472],
          [46.096182, 33.086334],
          [46.09637, 33.086329],
          [46.096632, 33.086411],
          [46.096909, 33.086116],
          [46.097054, 33.085827],
          [46.09722, 33.085782],
          [46.097223, 33.0856],
          [46.097331, 33.08546],
          [46.097546, 33.085385],
          [46.09761, 33.085026],
          [46.098044, 33.084991],
          [46.098202, 33.085184],
          [46.098324, 33.085241],
          [46.098528, 33.08525],
          [46.099139, 33.084997],
          [46.099372, 33.084775],
          [46.099376, 33.084648],
          [46.09981, 33.08464],
          [46.09985, 33.084743],
          [46.1001, 33.084998],
          [46.100254, 33.085033],
          [46.100403, 33.085024],
          [46.100496, 33.084942],
          [46.100695, 33.084979],
          [46.100865, 33.084951],
          [46.101108, 33.0846],
          [46.10127, 33.08454],
          [46.101559, 33.084535],
          [46.101715, 33.084695],
          [46.101903, 33.084641],
          [46.101999, 33.084548],
          [46.102223, 33.084559],
          [46.102406, 33.084412],
          [46.102402, 33.084253],
          [46.102568, 33.084092],
          [46.102803, 33.083997],
          [46.103189, 33.083926],
          [46.103516, 33.083776],
          [46.103607, 33.08381],
          [46.103793, 33.084423],
          [46.10392, 33.084523],
          [46.104152, 33.084539],
          [46.104535, 33.084435],
          [46.105142, 33.08453],
          [46.105332, 33.084459],
          [46.105465, 33.084243],
          [46.105678, 33.084113],
          [46.106312, 33.084098],
          [46.106496, 33.084028],
          [46.10669, 33.083609],
          [46.106647, 33.083468],
          [46.106851, 33.082992],
          [46.106786, 33.082838],
          [46.106609, 33.082791],
          [46.106496, 33.082695],
          [46.106599, 33.082484],
          [46.106601, 33.082032],
          [46.106651, 33.081885],
          [46.107008, 33.081753],
          [46.10718, 33.081586],
          [46.10729, 33.081016],
          [46.107465, 33.080914],
          [46.107654, 33.080887],
          [46.107834, 33.081066],
          [46.108284, 33.080901],
          [46.108689, 33.080832],
          [46.108784, 33.080733],
          [46.109135, 33.080602],
          [46.109814, 33.080127],
          [46.109868, 33.079633],
          [46.109978, 33.079471],
          [46.110158, 33.079484],
          [46.110254, 33.079584],
          [46.111022, 33.079767],
          [46.111508, 33.079717],
          [46.111993, 33.079563],
          [46.112135, 33.079428],
          [46.112304, 33.07935],
          [46.112413, 33.079381],
          [46.112589, 33.079621],
          [46.112732, 33.079635],
          [46.112908, 33.079511],
          [46.113032, 33.07933],
          [46.113129, 33.078977],
          [46.113492, 33.07843],
          [46.113742, 33.078178],
          [46.113761, 33.077982],
          [46.113719, 33.077824],
          [46.113646, 33.077759],
          [46.113746, 33.077516],
          [46.113668, 33.077359],
          [46.113774, 33.077208],
          [46.113994, 33.077083],
          [46.11419, 33.077011],
          [46.114356, 33.077015],
          [46.114707, 33.076862],
          [46.114968, 33.076624],
          [46.115087, 33.076428],
          [46.115118, 33.075943],
          [46.115015, 33.075839],
          [46.114816, 33.075779],
          [46.114858, 33.075381],
          [46.115145, 33.074726],
          [46.115549, 33.074518],
          [46.115754, 33.07446],
          [46.115682, 33.074179],
          [46.115838, 33.074113],
          [46.116232, 33.074151],
          [46.116455, 33.074101],
          [46.116518, 33.073951],
          [46.116439, 33.073765],
          [46.116507, 33.07354],
          [46.117045, 33.073243],
          [46.117229, 33.073246],
          [46.117825, 33.073483],
          [46.117946, 33.073486],
          [46.118013, 33.073334],
          [46.1181, 33.073264],
          [46.118301, 33.07329],
          [46.118438, 33.073186],
          [46.118709, 33.073144],
          [46.118998, 33.073177],
          [46.119192, 33.073027],
          [46.11964, 33.073026],
          [46.119804, 33.072871],
          [46.119872, 33.072526],
          [46.119977, 33.072484],
          [46.120088, 33.072519],
          [46.120378, 33.072506],
          [46.120666, 33.072242],
          [46.12108, 33.072126],
          [46.121729, 33.072151],
          [46.121939, 33.072041],
          [46.122207, 33.07201],
          [46.122415, 33.071921],
          [46.122792, 33.072009],
          [46.122958, 33.072421],
          [46.123206, 33.072717],
          [46.123341, 33.072754],
          [46.123501, 33.07263],
          [46.123628, 33.07261],
          [46.124291, 33.072863],
          [46.124813, 33.072887],
          [46.124947, 33.073018],
          [46.125126, 33.073095],
          [46.125204, 33.073229],
          [46.12569, 33.073269],
          [46.126144, 33.073465],
          [46.12672, 33.073475],
          [46.126958, 33.073533],
          [46.127064, 33.073668],
          [46.127433, 33.0737],
          [46.127697, 33.073941],
          [46.127999, 33.07403],
          [46.128239, 33.073998],
          [46.128394, 33.073854],
          [46.128947, 33.073717],
          [46.129284, 33.073733],
          [46.129422, 33.073858],
          [46.129619, 33.073864],
          [46.129751, 33.073959],
          [46.130314, 33.074034],
          [46.130354, 33.074106],
          [46.131133, 33.074075],
          [46.131318, 33.074036],
          [46.131521, 33.073895],
          [46.131881, 33.073839],
          [46.132034, 33.073758],
          [46.132158, 33.073604],
          [46.13234, 33.073184],
          [46.132167, 33.073059],
          [46.132205, 33.073001],
          [46.132555, 33.072906],
          [46.13275, 33.072735],
          [46.132789, 33.072583],
          [46.13305, 33.072569],
          [46.133287, 33.072454],
          [46.133567, 33.072447],
          [46.133801, 33.072348],
          [46.134319, 33.072378],
          [46.134524, 33.072347],
          [46.134648, 33.072265],
          [46.134716, 33.072114],
          [46.135067, 33.071898],
          [46.135207, 33.071981],
          [46.135569, 33.072029],
          [46.135777, 33.07182],
          [46.13597, 33.071811],
          [46.136168, 33.071916],
          [46.136269, 33.071859],
          [46.136342, 33.071733],
          [46.136361, 33.071568],
          [46.136751, 33.071618],
          [46.136898, 33.071532],
          [46.13716, 33.071596],
          [46.137313, 33.071515],
          [46.137598, 33.071727],
          [46.138031, 33.071812],
          [46.138872, 33.071578],
          [46.13939, 33.07166],
          [46.139546, 33.071589],
          [46.139916, 33.0716],
          [46.140173, 33.071545],
          [46.140601, 33.071583],
          [46.141056, 33.071325],
          [46.141114, 33.071107],
          [46.141216, 33.070956],
          [46.141351, 33.07092],
          [46.141508, 33.070953],
          [46.141571, 33.071074],
          [46.141774, 33.071157],
          [46.141994, 33.071165],
          [46.142443, 33.07095],
          [46.143761, 33.071425],
          [46.143972, 33.071398],
          [46.144157, 33.071427],
          [46.144241, 33.071294],
          [46.144417, 33.071215],
          [46.145557, 33.070987],
          [46.145956, 33.070951],
          [46.146221, 33.070686],
          [46.146408, 33.070605],
          [46.147037, 33.070571],
          [46.147089, 33.070474],
          [46.147573, 33.070384],
          [46.147877, 33.070431],
          [46.148127, 33.07081],
          [46.148362, 33.070931],
          [46.148436, 33.071269],
          [46.148631, 33.071317],
          [46.148679, 33.071398],
          [46.148853, 33.071429],
          [46.149031, 33.071527],
          [46.149619, 33.071368],
          [46.149963, 33.071462],
          [46.150411, 33.071487],
          [46.150541, 33.07155],
          [46.150986, 33.071612],
          [46.15123, 33.071334],
          [46.151265, 33.071188],
          [46.151939, 33.071225],
          [46.152175, 33.071178],
          [46.152761, 33.071255],
          [46.152921, 33.071178],
          [46.153151, 33.071206],
          [46.153669, 33.071063],
          [46.153794, 33.071133],
          [46.153847, 33.07126],
          [46.154024, 33.071327],
          [46.154433, 33.071311],
          [46.15527, 33.071646],
          [46.155286, 33.072004],
          [46.155067, 33.072121],
          [46.155052, 33.072228],
          [46.155266, 33.072404],
          [46.155614, 33.072543],
          [46.156604, 33.072531],
          [46.15683, 33.072466],
          [46.157215, 33.07225],
          [46.157395, 33.072013],
          [46.157733, 33.07194],
          [46.157989, 33.07176],
          [46.158782, 33.071789],
          [46.158971, 33.071666],
          [46.159114, 33.071467],
          [46.159574, 33.071229],
          [46.159859, 33.070976],
          [46.160672, 33.070846],
          [46.160806, 33.07107],
          [46.161201, 33.071067],
          [46.161332, 33.071135],
          [46.161471, 33.071286],
          [46.161667, 33.071293],
          [46.162873, 33.071076],
          [46.163232, 33.070577],
          [46.16363, 33.070463],
          [46.163654, 33.070298],
          [46.163853, 33.069949],
          [46.164199, 33.069765],
          [46.164373, 33.069576],
          [46.164625, 33.069428],
          [46.164311, 33.069069],
          [46.164144, 33.06897],
          [46.164059, 33.068831],
          [46.16369, 33.068701],
          [46.163598, 33.068558],
          [46.16342, 33.068481],
          [46.163179, 33.068461],
          [46.162653, 33.06803],
          [46.16256, 33.067805],
          [46.162103, 33.067379],
          [46.161857, 33.067261],
          [46.161496, 33.066899],
          [46.160933, 33.066557],
          [46.160838, 33.066348],
          [46.16087, 33.066097],
          [46.160521, 33.065447],
          [46.159614, 33.065252],
          [46.159404, 33.065139],
          [46.159101, 33.06517],
          [46.158861, 33.065076],
          [46.158734, 33.065122],
          [46.158673, 33.065277],
          [46.158513, 33.065401],
          [46.158269, 33.065387],
          [46.158098, 33.065345],
          [46.157899, 33.06511],
          [46.157346, 33.065007],
          [46.15643, 33.065284],
          [46.156434, 33.064886],
          [46.156133, 33.064755],
          [46.155702, 33.064702],
          [46.155647, 33.064642],
          [46.155729, 33.064332],
          [46.155333, 33.063678],
          [46.155222, 33.06357],
          [46.154516, 33.063449],
          [46.154294, 33.063457],
          [46.153853, 33.063583],
          [46.153431, 33.063548],
          [46.152999, 33.063296],
          [46.152787, 33.063105],
          [46.152467, 33.062575],
          [46.151663, 33.061968],
          [46.151472, 33.061669],
          [46.151389, 33.061389],
          [46.151399, 33.061116],
          [46.151471, 33.060886],
          [46.151911, 33.060322],
          [46.152069, 33.060042],
          [46.152267, 33.05986],
          [46.152306, 33.059682],
          [46.152273, 33.05867],
          [46.152199, 33.058405],
          [46.152024, 33.058202],
          [46.151866, 33.058111],
          [46.151483, 33.058045],
          [46.150889, 33.057573],
          [46.149996, 33.057173],
          [46.149819, 33.056981],
          [46.149673, 33.056701],
          [46.149548, 33.05637],
          [46.14945, 33.055081],
          [46.149854, 33.053897],
          [46.14986, 33.053656],
          [46.149643, 33.053345],
          [46.148889, 33.05285],
          [46.148747, 33.052689],
          [46.14866, 33.052489],
          [46.148558, 33.051837],
          [46.148581, 33.051546],
          [46.148357, 33.051012],
          [46.14791, 33.050527],
          [46.147694, 33.050441],
          [46.147593, 33.050451],
          [46.147554, 33.050603],
          [46.147261, 33.05094],
          [46.146586, 33.051243],
          [46.146289, 33.051299],
          [46.146015, 33.051278],
          [46.14509, 33.050935],
          [46.144924, 33.050768],
          [46.144544, 33.050132],
          [46.14398, 33.049541],
          [46.143591, 33.049256],
          [46.14341, 33.049049],
          [46.143361, 33.048916],
          [46.143413, 33.048162],
          [46.143239, 33.047766],
          [46.142995, 33.04773],
          [46.142824, 33.047809],
          [46.142149, 33.048544],
          [46.141976, 33.048639],
          [46.141867, 33.04864],
          [46.14171, 33.048439],
          [46.141704, 33.048268],
          [46.141812, 33.047944],
          [46.141686, 33.047389],
          [46.141683, 33.047108],
          [46.141542, 33.046754],
          [46.141222, 33.046688],
          [46.139982, 33.046884],
          [46.139826, 33.046877],
          [46.139719, 33.046789],
          [46.139517, 33.046372],
          [46.139373, 33.04547],
          [46.138908, 33.043647],
          [46.138979, 33.043],
          [46.139192, 33.042127],
          [46.13919, 33.041855],
          [46.138983, 33.041292],
          [46.138476, 33.040363],
          [46.137662, 33.039372],
          [46.136929, 33.038796],
          [46.136012, 33.038217],
          [46.135728, 33.037916],
          [46.134531, 33.036979],
          [46.133808, 33.036594],
          [46.13182, 33.03583],
          [46.131667, 33.035692],
          [46.13143, 33.035275],
          [46.131322, 33.034963],
          [46.131304, 33.034569],
          [46.131387, 33.03417],
          [46.131564, 33.033824],
          [46.131962, 33.033518],
          [46.132476, 33.033261],
          [46.133059, 33.033103],
          [46.133492, 33.033068],
          [46.134634, 33.032558],
          [46.134874, 33.032333],
          [46.135108, 33.031963],
          [46.135291, 33.031549],
          [46.135376, 33.031228],
          [46.13536, 33.031037],
          [46.135287, 33.030897],
          [46.135103, 33.030727],
          [46.134298, 33.030209],
          [46.133923, 33.030006],
          [46.132879, 33.029624],
          [46.132753, 33.029529],
          [46.1323, 33.028195],
          [46.132169, 33.027954],
          [46.131454, 33.027307],
          [46.131383, 33.02713],
          [46.131363, 33.026753],
          [46.131561, 33.026425],
          [46.131818, 33.026275],
          [46.133032, 33.025848],
          [46.133265, 33.025718],
          [46.133512, 33.025451],
          [46.133729, 33.025057],
          [46.133815, 33.024715],
          [46.133727, 33.024176],
          [46.133592, 33.02392],
          [46.133351, 33.023701],
          [46.133038, 33.023541],
          [46.132628, 33.023458],
          [46.132115, 33.023474],
          [46.131191, 33.023643],
          [46.130259, 33.024],
          [46.128791, 33.023861],
          [46.128511, 33.023768],
          [46.12833, 33.023587],
          [46.128187, 33.023223],
          [46.128212, 33.023063],
          [46.128474, 33.022788],
          [46.128721, 33.02264],
          [46.130097, 33.022073],
          [46.130344, 33.021811],
          [46.130313, 33.021508],
          [46.130071, 33.021117],
          [46.129911, 33.020975],
          [46.129293, 33.020788],
          [46.128807, 33.020748],
          [46.125558, 33.020695],
          [46.125216, 33.020659],
          [46.125033, 33.020515],
          [46.124853, 33.020094],
          [46.125013, 33.018707],
          [46.124425, 33.018161],
          [46.123662, 33.017872],
          [46.123389, 33.017835],
          [46.12328, 33.017857],
          [46.123173, 33.017942],
          [46.122516, 33.018899],
          [46.122417, 33.019431],
          [46.122437, 33.020122],
          [46.122315, 33.02048],
          [46.121812, 33.020635],
          [46.121651, 33.020728],
          [46.12135, 33.021035],
          [46.120736, 33.02184],
          [46.120427, 33.022132],
          [46.11945, 33.022737],
          [46.11909, 33.022886],
          [46.118796, 33.022932],
          [46.118652, 33.022881],
          [46.118589, 33.022786],
          [46.118478, 33.021879],
          [46.118243, 33.021441],
          [46.117994, 33.020754],
          [46.11776, 33.020466],
          [46.117738, 33.020083],
          [46.117952, 33.019748],
          [46.117963, 33.019553],
          [46.117883, 33.019237],
          [46.117794, 33.019151],
          [46.117486, 33.019016],
          [46.116973, 33.018886],
          [46.11552, 33.018593],
          [46.114889, 33.018544],
          [46.114381, 33.01868],
          [46.113771, 33.018988],
          [46.113255, 33.019401],
          [46.112972, 33.019539],
          [46.112334, 33.019684],
          [46.111627, 33.019631],
          [46.111515, 33.019596],
          [46.111079, 33.01923],
          [46.111029, 33.019092],
          [46.111191, 33.018247],
          [46.111144, 33.018046],
          [46.109822, 33.017362],
          [46.109592, 33.017262],
          [46.109423, 33.017252],
          [46.108814, 33.017444],
          [46.108587, 33.01772],
          [46.108373, 33.019125],
          [46.108378, 33.020189],
          [46.108299, 33.020655],
          [46.108228, 33.020864],
          [46.108119, 33.020965],
          [46.107598, 33.021238],
          [46.106679, 33.021233],
          [46.106062, 33.021125],
          [46.105899, 33.02104],
          [46.105511, 33.020662],
          [46.105137, 33.019905],
          [46.10494, 33.019147],
          [46.104701, 33.018767],
          [46.104381, 33.018555],
          [46.104043, 33.018435],
          [46.10312, 33.018342],
          [46.102916, 33.018379],
          [46.102742, 33.018468],
          [46.102421, 33.018737],
          [46.101299, 33.019259],
          [46.100726, 33.019629],
          [46.100479, 33.020111],
          [46.100301, 33.020259],
          [46.099948, 33.020318],
          [46.099603, 33.020027],
          [46.099162, 33.019813],
          [46.098959, 33.019437],
          [46.098868, 33.019083],
          [46.099053, 33.018853],
          [46.099161, 33.018535],
          [46.099338, 33.018293],
          [46.099563, 33.018088],
          [46.100124, 33.017774],
          [46.100196, 33.017595],
          [46.100227, 33.017357],
          [46.100026, 33.016643],
          [46.099915, 33.015699],
          [46.099778, 33.015317],
          [46.099379, 33.014623],
          [46.098585, 33.01371],
          [46.097849, 33.013198],
          [46.097163, 33.012982],
          [46.096495, 33.01285],
          [46.096429, 33.012756],
          [46.096445, 33.012351],
          [46.09632, 33.011847],
          [46.096701, 33.010288],
          [46.097017, 33.009486],
          [46.096942, 33.009056],
          [46.096821, 33.008892],
          [46.096607, 33.008772],
          [46.095898, 33.008529],
          [46.095238, 33.008507],
          [46.094435, 33.008624],
          [46.093717, 33.008645],
          [46.093604, 33.008567],
          [46.093591, 33.008486],
          [46.094025, 33.007806],
          [46.094825, 33.007128],
          [46.095652, 33.006568],
          [46.09581, 33.006415],
          [46.095818, 33.006276],
          [46.095786, 33.006185],
          [46.095452, 33.005832],
          [46.095063, 33.005231],
          [46.094855, 33.005063],
          [46.09397, 33.00459],
          [46.093688, 33.004363],
          [46.09353, 33.004114],
          [46.093482, 33.003904],
          [46.093566, 33.003797],
          [46.093754, 33.003684],
          [46.094599, 33.003354],
          [46.095612, 33.003117],
          [46.098003, 33.00278],
          [46.098343, 33.00264],
          [46.098742, 33.002405],
          [46.099084, 33.002096],
          [46.099364, 33.00171],
          [46.099575, 33.001273],
          [46.099781, 33.000358],
          [46.099575, 32.999358],
          [46.099088, 32.99843],
          [46.098561, 32.99788],
          [46.097888, 32.99747],
          [46.097484, 32.997276],
          [46.09723, 32.996901],
          [46.097136, 32.996596],
          [46.097143, 32.996456],
          [46.097333, 32.996226],
          [46.097546, 32.995662],
          [46.097987, 32.995079],
          [46.098157, 32.994946],
          [46.098242, 32.994697],
          [46.098159, 32.994425],
          [46.097921, 32.994127],
          [46.096693, 32.993214],
          [46.096202, 32.992778],
          [46.096086, 32.992605],
          [46.096033, 32.992408],
          [46.09608, 32.992011],
          [46.095987, 32.991637],
          [46.096097, 32.9911],
          [46.096, 32.99053],
          [46.095939, 32.990398],
          [46.095618, 32.990106],
          [46.095442, 32.98983],
          [46.095278, 32.989431],
          [46.095135, 32.989032],
          [46.095103, 32.988767],
          [46.095147, 32.988406],
          [46.095099, 32.988063],
          [46.094033, 32.985127],
          [46.094107, 32.984979],
          [46.095709, 32.984173],
          [46.095919, 32.984124],
          [46.096286, 32.984175],
          [46.09639, 32.984125],
          [46.09647, 32.983975],
          [46.09669, 32.983774],
          [46.097443, 32.983341],
          [46.097641, 32.983161],
          [46.097772, 32.982926],
          [46.097817, 32.982679],
          [46.097787, 32.982466],
          [46.097583, 32.982075],
          [46.09743, 32.981967],
          [46.096988, 32.982059],
          [46.096756, 32.981984],
          [46.096602, 32.98185],
          [46.096511, 32.98168],
          [46.10218, 32.977612],
          [46.109093, 32.974259],
          [46.119994, 32.968885],
          [46.133748, 32.960869],
          [46.137802, 32.95993],
          [46.159405, 32.955131],
          [46.173556, 32.953285],
          [46.199846, 32.959124],
          [46.207354, 32.960539],
          [46.209876, 32.961094],
          [46.2205, 32.963433],
          [46.244134, 32.97019],
          [46.253742, 32.968606],
          [46.266369, 32.972447],
          [46.282369, 32.968902],
          [46.298142, 32.968288],
          [46.312327, 32.968816],
          [46.322019, 32.965782],
          [46.331533, 32.96523],
          [46.344807, 32.958594],
          [46.347508, 32.960324],
          [46.359192, 32.954786],
          [46.387556, 32.948836],
          [46.410102, 32.947231],
          [46.415413, 32.941534],
          [46.422569, 32.937529],
          [46.427617, 32.935532],
          [46.431613, 32.934585],
          [46.436942, 32.933396],
          [46.441183, 32.931093],
          [46.444382, 32.930514],
          [46.447421, 32.933275],
          [46.451022, 32.931069],
          [46.449409, 32.927365],
          [46.461767, 32.921565],
          [46.462092, 32.921379],
          [46.471655, 32.917659],
          [46.480506, 32.916202],
          [46.491513, 32.902993],
          [46.504161, 32.902106],
          [46.510712, 32.900282],
          [46.514261, 32.899337],
          [46.518693, 32.898156],
          [46.527683, 32.89241],
          [46.555702, 32.874702],
          [46.565411, 32.867624],
          [46.573649, 32.861617],
          [46.585221, 32.851784],
          [46.588738, 32.851383],
          [46.592412, 32.850565],
          [46.592096, 32.847863],
          [46.601585, 32.840752],
          [46.613722, 32.832479],
          [46.622615, 32.822218],
          [46.632469, 32.817805],
          [46.647307, 32.814454],
          [46.655987, 32.80735],
          [46.688649, 32.797894],
          [46.691232, 32.79335],
          [46.698361, 32.789195],
          [46.707899, 32.785457],
          [46.720615, 32.78596],
          [46.734397, 32.775398],
          [46.74046, 32.768775],
          [46.751356, 32.760343],
          [46.758459, 32.751283],
          [46.758474, 32.751162],
          [46.766326, 32.742083],
          [46.766299, 32.742033],
          [46.78366, 32.730313],
          [46.783801, 32.730157],
          [46.787791, 32.722788],
          [46.791315, 32.711954],
          [46.804238, 32.705439],
          [46.811891, 32.701022],
          [46.825896, 32.694731],
          [46.830258, 32.686325],
          [46.836375, 32.672572],
          [46.842392, 32.666982],
          [46.857348, 32.655488],
          [46.872086, 32.646476],
          [46.887238, 32.631954],
          [46.914126, 32.618334],
          [46.922161, 32.615281],
          [46.939698, 32.605766],
          [46.95577, 32.597626],
          [46.973419, 32.581341],
          [47.017411, 32.555623],
          [47.022638, 32.550797],
          [47.033145, 32.54454],
          [47.04803, 32.531239],
          [47.052131, 32.527159],
          [47.055363, 32.526151],
          [47.079571, 32.511717],
          [47.085498, 32.508512],
          [47.090497, 32.505231],
          [47.095036, 32.502058],
          [47.103401, 32.497732],
          [47.107323, 32.49395],
          [47.110917, 32.489598],
          [47.135073, 32.476113],
          [47.149805, 32.469279],
          [47.168401, 32.457281],
          [47.180773, 32.455747],
          [47.180318, 32.45638],
          [47.180128, 32.456956],
          [47.180155, 32.458067],
          [47.180347, 32.459176],
          [47.180466, 32.459368],
          [47.180712, 32.459505],
          [47.182457, 32.459528],
          [47.18284, 32.459586],
          [47.182998, 32.460307],
          [47.183001, 32.460977],
          [47.18328, 32.461468],
          [47.18354, 32.46168],
          [47.183951, 32.461824],
          [47.184437, 32.461891],
          [47.185716, 32.461599],
          [47.187808, 32.461217],
          [47.188571, 32.461171],
          [47.189159, 32.461248],
          [47.189736, 32.461399],
          [47.190226, 32.461639],
          [47.190396, 32.461831],
          [47.190475, 32.462995],
          [47.19212, 32.462932],
          [47.194748, 32.462831],
          [47.194912, 32.4625],
          [47.195005, 32.462125],
          [47.195167, 32.462039],
          [47.195531, 32.46201],
          [47.195944, 32.461908],
          [47.196138, 32.461924],
          [47.196277, 32.461866],
          [47.197523, 32.462079],
          [47.197653, 32.46195],
          [47.198556, 32.461823],
          [47.198721, 32.461874],
          [47.198921, 32.46204],
          [47.1993, 32.461888],
          [47.20016, 32.461396],
          [47.200323, 32.461346],
          [47.200567, 32.46139],
          [47.200811, 32.461621],
          [47.201089, 32.461654],
          [47.201226, 32.46174],
          [47.201591, 32.461651],
          [47.201781, 32.461716],
          [47.202074, 32.461567],
          [47.202453, 32.461692],
          [47.202646, 32.461613],
          [47.202674, 32.461488],
          [47.202842, 32.461365],
          [47.203046, 32.461302],
          [47.203452, 32.461477],
          [47.203637, 32.461489],
          [47.203777, 32.461556],
          [47.203945, 32.461649],
          [47.204045, 32.461795],
          [47.204203, 32.461878],
          [47.204298, 32.461784],
          [47.204295, 32.461377],
          [47.204542, 32.461247],
          [47.204753, 32.461219],
          [47.204947, 32.461422],
          [47.205137, 32.461481],
          [47.20543, 32.461488],
          [47.205575, 32.461638],
          [47.205867, 32.461423],
          [47.206059, 32.461374],
          [47.206214, 32.461229],
          [47.206363, 32.461241],
          [47.206729, 32.461128],
          [47.2071, 32.461153],
          [47.207449, 32.461336],
          [47.207557, 32.461301],
          [47.207727, 32.461124],
          [47.207913, 32.461051],
          [47.207838, 32.460824],
          [47.207885, 32.46075],
          [47.208173, 32.460674],
          [47.208449, 32.460821],
          [47.208676, 32.46052],
          [47.208843, 32.460518],
          [47.2091, 32.46074],
          [47.209245, 32.460705],
          [47.209413, 32.460582],
          [47.209524, 32.460378],
          [47.2098, 32.460309],
          [47.21022, 32.460452],
          [47.21034, 32.460372],
          [47.210526, 32.46033],
          [47.210583, 32.460201],
          [47.211138, 32.460268],
          [47.211263, 32.460406],
          [47.211458, 32.460432],
          [47.21161, 32.460596],
          [47.212359, 32.460389],
          [47.21269, 32.460722],
          [47.212801, 32.460914],
          [47.212798, 32.461023],
          [47.21261, 32.461185],
          [47.212476, 32.461674],
          [47.2126, 32.461861],
          [47.212698, 32.462223],
          [47.212977, 32.462661],
          [47.213088, 32.462931],
          [47.212958, 32.463102],
          [47.213011, 32.463234],
          [47.213621, 32.4631],
          [47.214032, 32.46319],
          [47.214223, 32.463075],
          [47.21459, 32.462947],
          [47.215062, 32.462802],
          [47.215345, 32.462795],
          [47.215641, 32.462708],
          [47.2166, 32.462341],
          [47.216809, 32.462547],
          [47.217131, 32.462645],
          [47.218307, 32.462698],
          [47.218643, 32.462686],
          [47.219575, 32.462416],
          [47.21999, 32.462384],
          [47.220165, 32.462445],
          [47.220524, 32.462258],
          [47.220881, 32.462166],
          [47.221157, 32.462148],
          [47.221316, 32.462218],
          [47.222361, 32.462365],
          [47.222671, 32.462335],
          [47.222714, 32.462061],
          [47.222931, 32.461888],
          [47.223141, 32.461797],
          [47.223432, 32.461749],
          [47.223463, 32.461876],
          [47.223917, 32.462095],
          [47.224148, 32.462058],
          [47.22448, 32.462165],
          [47.224529, 32.462457],
          [47.224434, 32.46279],
          [47.224693, 32.462868],
          [47.224759, 32.462929],
          [47.224774, 32.463052],
          [47.225063, 32.463221],
          [47.225336, 32.463206],
          [47.225781, 32.463265],
          [47.226605, 32.463148],
          [47.227301, 32.463106],
          [47.227773, 32.462915],
          [47.228019, 32.462881],
          [47.228259, 32.462908],
          [47.228501, 32.463066],
          [47.228675, 32.463122],
          [47.228969, 32.463073],
          [47.229266, 32.463127],
          [47.229386, 32.463203],
          [47.229355, 32.463406],
          [47.229435, 32.463555],
          [47.229594, 32.463612],
          [47.22985, 32.464068],
          [47.230132, 32.464088],
          [47.230245, 32.464195],
          [47.230974, 32.464526],
          [47.231321, 32.464757],
          [47.23161, 32.465351],
          [47.231606, 32.465544],
          [47.232075, 32.465647],
          [47.232773, 32.466725],
          [47.233422, 32.466817],
          [47.233689, 32.466779],
          [47.234292, 32.466531],
          [47.234619, 32.466465],
          [47.235139, 32.466476],
          [47.235485, 32.466402],
          [47.23578, 32.466234],
          [47.235884, 32.465941],
          [47.236105, 32.465773],
          [47.237224, 32.465368],
          [47.237816, 32.465223],
          [47.238389, 32.465183],
          [47.239383, 32.465314],
          [47.24006, 32.465342],
          [47.240341, 32.465241],
          [47.240675, 32.465235],
          [47.240905, 32.465101],
          [47.241953, 32.464978],
          [47.24213, 32.464865],
          [47.242309, 32.464854],
          [47.242542, 32.464941],
          [47.242915, 32.464916],
          [47.243997, 32.464691],
          [47.2443, 32.464468],
          [47.244413, 32.46394],
          [47.244645, 32.463392],
          [47.244942, 32.463356],
          [47.245207, 32.463426],
          [47.245398, 32.463587],
          [47.246859, 32.46363],
          [47.247204, 32.463729],
          [47.24736, 32.463925],
          [47.247368, 32.46414],
          [47.247459, 32.464335],
          [47.247618, 32.464327],
          [47.247915, 32.4642],
          [47.248188, 32.464185],
          [47.248355, 32.464212],
          [47.248465, 32.464278],
          [47.248382, 32.464507],
          [47.248449, 32.464784],
          [47.248642, 32.464921],
          [47.248837, 32.465272],
          [47.248982, 32.465333],
          [47.249651, 32.465175],
          [47.249995, 32.465149],
          [47.250381, 32.465212],
          [47.250744, 32.465206],
          [47.25096, 32.465105],
          [47.251122, 32.465114],
          [47.251385, 32.465233],
          [47.251429, 32.465388],
          [47.251568, 32.465569],
          [47.252047, 32.465473],
          [47.252245, 32.465506],
          [47.252449, 32.465719],
          [47.25267, 32.46579],
          [47.252749, 32.465903],
          [47.252952, 32.46599],
          [47.253155, 32.466197],
          [47.25348, 32.466305],
          [47.253583, 32.466462],
          [47.254059, 32.466594],
          [47.254044, 32.466771],
          [47.253955, 32.466917],
          [47.254056, 32.467092],
          [47.25455, 32.467569],
          [47.254618, 32.467852],
          [47.254894, 32.468028],
          [47.25516, 32.468075],
          [47.255472, 32.467976],
          [47.255907, 32.46809],
          [47.256373, 32.467936],
          [47.256668, 32.468074],
          [47.256868, 32.468299],
          [47.257116, 32.46845],
          [47.257822, 32.468718],
          [47.258085, 32.468873],
          [47.258374, 32.468952],
          [47.258662, 32.46942],
          [47.258809, 32.469756],
          [47.258869, 32.470094],
          [47.259074, 32.470402],
          [47.259233, 32.471209],
          [47.259419, 32.471527],
          [47.259652, 32.47174],
          [47.259916, 32.471865],
          [47.260048, 32.472173],
          [47.260774, 32.472402],
          [47.261239, 32.472638],
          [47.261229, 32.472807],
          [47.261115, 32.47309],
          [47.261112, 32.473348],
          [47.261449, 32.473508],
          [47.262066, 32.473617],
          [47.262366, 32.473652],
          [47.262688, 32.4736],
          [47.26294, 32.473648],
          [47.263076, 32.473758],
          [47.263401, 32.473867],
          [47.263831, 32.474198],
          [47.26467, 32.474444],
          [47.264928, 32.475122],
          [47.265457, 32.475197],
          [47.265671, 32.47542],
          [47.265837, 32.475716],
          [47.265979, 32.475759],
          [47.266384, 32.475687],
          [47.266717, 32.4758],
          [47.266937, 32.476016],
          [47.267843, 32.476149],
          [47.26816, 32.476014],
          [47.268523, 32.475948],
          [47.26885, 32.476033],
          [47.269211, 32.476261],
          [47.269313, 32.476473],
          [47.269175, 32.476747],
          [47.268286, 32.477198],
          [47.267559, 32.477779],
          [47.267154, 32.478031],
          [47.267211, 32.478177],
          [47.267627, 32.478211],
          [47.268298, 32.478425],
          [47.268468, 32.478583],
          [47.268717, 32.47859],
          [47.268886, 32.478868],
          [47.269515, 32.479299],
          [47.270298, 32.478949],
          [47.270958, 32.478907],
          [47.271126, 32.47903],
          [47.271188, 32.479409],
          [47.271443, 32.479679],
          [47.271635, 32.47999],
          [47.271651, 32.480239],
          [47.271893, 32.480427],
          [47.272325, 32.480494],
          [47.272643, 32.480634],
          [47.272826, 32.48088],
          [47.272764, 32.481406],
          [47.273286, 32.481488],
          [47.273611, 32.481357],
          [47.273911, 32.481452],
          [47.274439, 32.481467],
          [47.274814, 32.481328],
          [47.27524, 32.481342],
          [47.275605, 32.481498],
          [47.275914, 32.481418],
          [47.27619, 32.481414],
          [47.27635, 32.481172],
          [47.276617, 32.481069],
          [47.277261, 32.481173],
          [47.277367, 32.481432],
          [47.277294, 32.481851],
          [47.277495, 32.482082],
          [47.277793, 32.482291],
          [47.277894, 32.482497],
          [47.277641, 32.482928],
          [47.277828, 32.483221],
          [47.277929, 32.483486],
          [47.27772, 32.483766],
          [47.277731, 32.483962],
          [47.278353, 32.484334],
          [47.278463, 32.48452],
          [47.278311, 32.484796],
          [47.278445, 32.48499],
          [47.278512, 32.485237],
          [47.278839, 32.485352],
          [47.279731, 32.485487],
          [47.280054, 32.485644],
          [47.280273, 32.48595],
          [47.280333, 32.486319],
          [47.281976, 32.488519],
          [47.282013, 32.489114],
          [47.281902, 32.489487],
          [47.281492, 32.490231],
          [47.28138, 32.49079],
          [47.281435, 32.49112],
          [47.281797, 32.491744],
          [47.281832, 32.492008],
          [47.281865, 32.492356],
          [47.281824, 32.492549],
          [47.281633, 32.492754],
          [47.281658, 32.492978],
          [47.281822, 32.493053],
          [47.281969, 32.493059],
          [47.28213, 32.493182],
          [47.282348, 32.493602],
          [47.282315, 32.493859],
          [47.282409, 32.493976],
          [47.282605, 32.494094],
          [47.282671, 32.494245],
          [47.282381, 32.494886],
          [47.28253, 32.495017],
          [47.282896, 32.495149],
          [47.282985, 32.495273],
          [47.282871, 32.495735],
          [47.283269, 32.496269],
          [47.283461, 32.49637],
          [47.28368, 32.49628],
          [47.2842, 32.496171],
          [47.284497, 32.496194],
          [47.284863, 32.49614],
          [47.284995, 32.495933],
          [47.285304, 32.495948],
          [47.285872, 32.496125],
          [47.286909, 32.495656],
          [47.28727, 32.495579],
          [47.287534, 32.495314],
          [47.287827, 32.49514],
          [47.2887, 32.495045],
          [47.289123, 32.495048],
          [47.289462, 32.494399],
          [47.289761, 32.494248],
          [47.29051, 32.494065],
          [47.290636, 32.493799],
          [47.290776, 32.493656],
          [47.291417, 32.493563],
          [47.291625, 32.493463],
          [47.292737, 32.493209],
          [47.292969, 32.493202],
          [47.293174, 32.49309],
          [47.293687, 32.493072],
          [47.294049, 32.493121],
          [47.294445, 32.493314],
          [47.294703, 32.493235],
          [47.294928, 32.492989],
          [47.295654, 32.492618],
          [47.295834, 32.492457],
          [47.295895, 32.492255],
          [47.296083, 32.491979],
          [47.296811, 32.491404],
          [47.297069, 32.491386],
          [47.297245, 32.491303],
          [47.297361, 32.491056],
          [47.297368, 32.490875],
          [47.297798, 32.490787],
          [47.298085, 32.49095],
          [47.298906, 32.491031],
          [47.299424, 32.49094],
          [47.299672, 32.490672],
          [47.300027, 32.490512],
          [47.300163, 32.490292],
          [47.30054, 32.490044],
          [47.300684, 32.489853],
          [47.300933, 32.48974],
          [47.301652, 32.49003],
          [47.301961, 32.490016],
          [47.302382, 32.490097],
          [47.302816, 32.489966],
          [47.303072, 32.489336],
          [47.303715, 32.489159],
          [47.303691, 32.488911],
          [47.303868, 32.488679],
          [47.304175, 32.488532],
          [47.305129, 32.488442],
          [47.305525, 32.488569],
          [47.305903, 32.488567],
          [47.306115, 32.488479],
          [47.306643, 32.488015],
          [47.306704, 32.487663],
          [47.306899, 32.487505],
          [47.307505, 32.487658],
          [47.307821, 32.487552],
          [47.308089, 32.487334],
          [47.30821, 32.487171],
          [47.308629, 32.487186],
          [47.309243, 32.486834],
          [47.309507, 32.486839],
          [47.309749, 32.486997],
          [47.310412, 32.487026],
          [47.310532, 32.487163],
          [47.310857, 32.487391],
          [47.311388, 32.487538],
          [47.311582, 32.487375],
          [47.31171, 32.48697],
          [47.311948, 32.486811],
          [47.312295, 32.486467],
          [47.312381, 32.486129],
          [47.312754, 32.486014],
          [47.313379, 32.485618],
          [47.313576, 32.485316],
          [47.313966, 32.48518],
          [47.314428, 32.485134],
          [47.315601, 32.485494],
          [47.315804, 32.485341],
          [47.315942, 32.484947],
          [47.316386, 32.484886],
          [47.316635, 32.484288],
          [47.316876, 32.48402],
          [47.317938, 32.483889],
          [47.318571, 32.483587],
          [47.318907, 32.483376],
          [47.319348, 32.483424],
          [47.320285, 32.482839],
          [47.321043, 32.483051],
          [47.321413, 32.482805],
          [47.321641, 32.482539],
          [47.321743, 32.482211],
          [47.322104, 32.482044],
          [47.322446, 32.482012],
          [47.322563, 32.481681],
          [47.322428, 32.481337],
          [47.323344, 32.4803],
          [47.323606, 32.48027],
          [47.323865, 32.480047],
          [47.3243, 32.479796],
          [47.324614, 32.479739],
          [47.324961, 32.479454],
          [47.325816, 32.479225],
          [47.326351, 32.478663],
          [47.326614, 32.478548],
          [47.326707, 32.478299],
          [47.32699, 32.478205],
          [47.327193, 32.478322],
          [47.327625, 32.478329],
          [47.328036, 32.47828],
          [47.328207, 32.478054],
          [47.328592, 32.477931],
          [47.329388, 32.476943],
          [47.329861, 32.476758],
          [47.330262, 32.476729],
          [47.331674, 32.476815],
          [47.331872, 32.476759],
          [47.33234, 32.476976],
          [47.333016, 32.477093],
          [47.333319, 32.47684],
          [47.333873, 32.476659],
          [47.334105, 32.476412],
          [47.334589, 32.476063],
          [47.334762, 32.475783],
          [47.335137, 32.475589],
          [47.335624, 32.475229],
          [47.33607, 32.475414],
          [47.336554, 32.475712],
          [47.33715, 32.476494],
          [47.337596, 32.476903],
          [47.338192, 32.477015],
          [47.338899, 32.477052],
          [47.339793, 32.477276],
          [47.340091, 32.476978],
          [47.340426, 32.476829],
          [47.340649, 32.476345],
          [47.340947, 32.476159],
          [47.341654, 32.475973],
          [47.342101, 32.476084],
          [47.342585, 32.476047],
          [47.343032, 32.475786],
          [47.343702, 32.475675],
          [47.344186, 32.475786],
          [47.34467, 32.475824],
          [47.345228, 32.475935],
          [47.345787, 32.4756],
          [47.346308, 32.475451],
          [47.346866, 32.475451],
          [47.348169, 32.475153],
          [47.349063, 32.475079],
          [47.351483, 32.475042],
          [47.352525, 32.474967],
          [47.354349, 32.474707],
          [47.354684, 32.474521],
          [47.354871, 32.474334],
          [47.355641, 32.473242],
          [47.355758, 32.472941],
          [47.356071, 32.472794],
          [47.3563, 32.472744],
          [47.35702, 32.472075],
          [47.357598, 32.471723],
          [47.357633, 32.471357],
          [47.358006, 32.470942],
          [47.358629, 32.470834],
          [47.358825, 32.470653],
          [47.358865, 32.470484],
          [47.35911, 32.470504],
          [47.359509, 32.470828],
          [47.359754, 32.470848],
          [47.3601, 32.471073],
          [47.360452, 32.470932],
          [47.360736, 32.470718],
          [47.360791, 32.470253],
          [47.360994, 32.47016],
          [47.36118, 32.470387],
          [47.361594, 32.470529],
          [47.361947, 32.470424],
          [47.362347, 32.470454],
          [47.362509, 32.470518],
          [47.363042, 32.470101],
          [47.363264, 32.470148],
          [47.36348, 32.470377],
          [47.363969, 32.470261],
          [47.364326, 32.470412],
          [47.364942, 32.470245],
          [47.365274, 32.470383],
          [47.365571, 32.470406],
          [47.366285, 32.470649],
          [47.366661, 32.470606],
          [47.367003, 32.470694],
          [47.36746, 32.470655],
          [47.367823, 32.470739],
          [47.367756, 32.471008],
          [47.367863, 32.471206],
          [47.368329, 32.471568],
          [47.368713, 32.471439],
          [47.36891, 32.471563],
          [47.369417, 32.472211],
          [47.369761, 32.472251],
          [47.369936, 32.472672],
          [47.370133, 32.472825],
          [47.370123, 32.473265],
          [47.370389, 32.473461],
          [47.371163, 32.473802],
          [47.371213, 32.474009],
          [47.371095, 32.474424],
          [47.370719, 32.474738],
          [47.370613, 32.474989],
          [47.37065, 32.475175],
          [47.370812, 32.475334],
          [47.371146, 32.475297],
          [47.371572, 32.475162],
          [47.372005, 32.474965],
          [47.372778, 32.474755],
          [47.373092, 32.474727],
          [47.373494, 32.474524],
          [47.374483, 32.474362],
          [47.374767, 32.474213],
          [47.375399, 32.47351],
          [47.375706, 32.473394],
          [47.376101, 32.473342],
          [47.376529, 32.473362],
          [47.376807, 32.47355],
          [47.37746, 32.473689],
          [47.37777, 32.473854],
          [47.378021, 32.473867],
          [47.378, 32.473481],
          [47.378522, 32.473197],
          [47.378916, 32.473199],
          [47.379353, 32.473139],
          [47.379567, 32.473212],
          [47.379738, 32.473076],
          [47.379768, 32.472808],
          [47.37988, 32.472609],
          [47.379818, 32.4722],
          [47.379953, 32.472064],
          [47.379866, 32.471826],
          [47.380081, 32.471509],
          [47.380377, 32.471557],
          [47.380623, 32.471493],
          [47.380884, 32.471216],
          [47.381149, 32.471227],
          [47.381687, 32.471343],
          [47.382233, 32.470948],
          [47.382428, 32.471],
          [47.382955, 32.470919],
          [47.383116, 32.470743],
          [47.383315, 32.470633],
          [47.383858, 32.470526],
          [47.383977, 32.470326],
          [47.384162, 32.469763],
          [47.384001, 32.469369],
          [47.38429, 32.469148],
          [47.384615, 32.469077],
          [47.384709, 32.468894],
          [47.384632, 32.468666],
          [47.384735, 32.468398],
          [47.384932, 32.468156],
          [47.385517, 32.467772],
          [47.386, 32.467088],
          [47.38626, 32.466991],
          [47.386499, 32.467012],
          [47.386796, 32.466766],
          [47.387062, 32.466722],
          [47.38738, 32.466802],
          [47.387523, 32.466911],
          [47.387593, 32.467049],
          [47.387746, 32.467138],
          [47.387946, 32.467153],
          [47.388438, 32.466929],
          [47.388797, 32.466966],
          [47.389094, 32.466869],
          [47.38975, 32.46678],
          [47.391033, 32.465845],
          [47.391536, 32.465697],
          [47.39207, 32.465105],
          [47.392309, 32.464977],
          [47.392622, 32.464883],
          [47.393188, 32.464545],
          [47.393437, 32.464007],
          [47.3944, 32.463068],
          [47.394682, 32.462353],
          [47.394536, 32.461971],
          [47.394477, 32.461502],
          [47.394183, 32.460504],
          [47.394213, 32.460284],
          [47.394345, 32.460093],
          [47.394624, 32.459858],
          [47.394609, 32.459345],
          [47.394785, 32.459286],
          [47.394888, 32.459184],
          [47.395152, 32.458494],
          [47.395299, 32.458406],
          [47.39615, 32.458288],
          [47.396678, 32.457907],
          [47.397221, 32.45776],
          [47.397676, 32.457482],
          [47.397969, 32.457232],
          [47.398277, 32.456836],
          [47.398864, 32.456234],
          [47.400214, 32.454415],
          [47.400493, 32.45421],
          [47.401124, 32.454107],
          [47.40152, 32.453813],
          [47.402146, 32.453755],
          [47.402442, 32.453211],
          [47.402631, 32.453],
          [47.402952, 32.452456],
          [47.403493, 32.451924],
          [47.405102, 32.449928],
          [47.4058, 32.448927],
          [47.406047, 32.448712],
          [47.407331, 32.447243],
          [47.40763, 32.446797],
          [47.408062, 32.446304],
          [47.408783, 32.445297],
          [47.40866, 32.444909],
          [47.408957, 32.444428],
          [47.40924, 32.444264],
          [47.409575, 32.444353],
          [47.410094, 32.443873],
          [47.410901, 32.44288],
          [47.411182, 32.442419],
          [47.41105, 32.44226],
          [47.411021, 32.442094],
          [47.411178, 32.441901],
          [47.411897, 32.441602],
          [47.412551, 32.440899],
          [47.412634, 32.440684],
          [47.412574, 32.440484],
          [47.412605, 32.440205],
          [47.412822, 32.440081],
          [47.413162, 32.440083],
          [47.413797, 32.439332],
          [47.414152, 32.439046],
          [47.414463, 32.43862],
          [47.414556, 32.438153],
          [47.415065, 32.437903],
          [47.415435, 32.437541],
          [47.415816, 32.437251],
          [47.415958, 32.436998],
          [47.415738, 32.43666],
          [47.41584, 32.436443],
          [47.416698, 32.436091],
          [47.416977, 32.435887],
          [47.417887, 32.434836],
          [47.418065, 32.434725],
          [47.418109, 32.434546],
          [47.418417, 32.434371],
          [47.418558, 32.434118],
          [47.418958, 32.433899],
          [47.41901, 32.433701],
          [47.418989, 32.433437],
          [47.419559, 32.432543],
          [47.420241, 32.431812],
          [47.420493, 32.431265],
          [47.420623, 32.430837],
          [47.421076, 32.430477],
          [47.421454, 32.42988],
          [47.421646, 32.429053],
          [47.42198, 32.428555],
          [47.422107, 32.428196],
          [47.422552, 32.427643],
          [47.422907, 32.427016],
          [47.423182, 32.426688],
          [47.423466, 32.426238],
          [47.423694, 32.426129],
          [47.423913, 32.425828],
          [47.423802, 32.4253],
          [47.423925, 32.425107],
          [47.424122, 32.424855],
          [47.424519, 32.424705],
          [47.425032, 32.423964],
          [47.425283, 32.423891],
          [47.425905, 32.423461],
          [47.42614, 32.423088],
          [47.426584, 32.42269],
          [47.42666, 32.422557],
          [47.426652, 32.42237],
          [47.426776, 32.422234],
          [47.427012, 32.422129],
          [47.427095, 32.421995],
          [47.427108, 32.421833],
          [47.427329, 32.421594],
          [47.427446, 32.42114],
          [47.427729, 32.420924],
          [47.42801, 32.420595],
          [47.428689, 32.420115],
          [47.428973, 32.419745],
          [47.42952, 32.419295],
          [47.429845, 32.418764],
          [47.430189, 32.418332],
          [47.430383, 32.418144],
          [47.430597, 32.41806],
          [47.430863, 32.418047],
          [47.431762, 32.418144],
          [47.431761, 32.417825],
          [47.432104, 32.41757],
          [47.432207, 32.417329],
          [47.432466, 32.417152],
          [47.432753, 32.417084],
          [47.433006, 32.416965],
          [47.433356, 32.416954],
          [47.433549, 32.416817],
          [47.433661, 32.416609],
          [47.434293, 32.416189],
          [47.434598, 32.41561],
          [47.434751, 32.415424],
          [47.435052, 32.41533],
          [47.43516, 32.415163],
          [47.435365, 32.415046],
          [47.435342, 32.414823],
          [47.435428, 32.414704],
          [47.435908, 32.414471],
          [47.436033, 32.414312],
          [47.436282, 32.414154],
          [47.436455, 32.413809],
          [47.436465, 32.413608],
          [47.436902, 32.413479],
          [47.436945, 32.413272],
          [47.437059, 32.413069],
          [47.437268, 32.412969],
          [47.437473, 32.412665],
          [47.437696, 32.412488],
          [47.43775, 32.412376],
          [47.438432, 32.411645],
          [47.438684, 32.41152],
          [47.439024, 32.411209],
          [47.439142, 32.410892],
          [47.439378, 32.410787],
          [47.439607, 32.410575],
          [47.439829, 32.410125],
          [47.440862, 32.409811],
          [47.441316, 32.409188],
          [47.44162, 32.408866],
          [47.441866, 32.40872],
          [47.442267, 32.408637],
          [47.442943, 32.407833],
          [47.443207, 32.407729],
          [47.443607, 32.407721],
          [47.443818, 32.407655],
          [47.443904, 32.407189],
          [47.444154, 32.407059],
          [47.444351, 32.406779],
          [47.444676, 32.406619],
          [47.444856, 32.406404],
          [47.44503, 32.406299],
          [47.445446, 32.406169],
          [47.445714, 32.40595],
          [47.445802, 32.405729],
          [47.44567, 32.405518],
          [47.445434, 32.405355],
          [47.445579, 32.405118],
          [47.446068, 32.404627],
          [47.44682, 32.404271],
          [47.447138, 32.403844],
          [47.447464, 32.403689],
          [47.447617, 32.403399],
          [47.447904, 32.403223],
          [47.448485, 32.403034],
          [47.448777, 32.402771],
          [47.44954, 32.40251],
          [47.450001, 32.402075],
          [47.45014, 32.401731],
          [47.450364, 32.401634],
          [47.451067, 32.401566],
          [47.451222, 32.401339],
          [47.451605, 32.400975],
          [47.451788, 32.400457],
          [47.452047, 32.40036],
          [47.452326, 32.400316],
          [47.452391, 32.400031],
          [47.4525, 32.399921],
          [47.453267, 32.399619],
          [47.453503, 32.399332],
          [47.453777, 32.399238],
          [47.453991, 32.399234],
          [47.454142, 32.398859],
          [47.454345, 32.398681],
          [47.454304, 32.39834],
          [47.454502, 32.397854],
          [47.454682, 32.39772],
          [47.455631, 32.397426],
          [47.455851, 32.397261],
          [47.456058, 32.397019],
          [47.456249, 32.396957],
          [47.456713, 32.397006],
          [47.456907, 32.396846],
          [47.457238, 32.396365],
          [47.457543, 32.39602],
          [47.458011, 32.395794],
          [47.458712, 32.395641],
          [47.459187, 32.395392],
          [47.459508, 32.394844],
          [47.459788, 32.394749],
          [47.460698, 32.39473],
          [47.460948, 32.394863],
          [47.461339, 32.394691],
          [47.461635, 32.394444],
          [47.461395, 32.394002],
          [47.461571, 32.393725],
          [47.461963, 32.393553],
          [47.462241, 32.393293],
          [47.462647, 32.393044],
          [47.462851, 32.392842],
          [47.462984, 32.392608],
          [47.463535, 32.392356],
          [47.463909, 32.392119],
          [47.464202, 32.392021],
          [47.464448, 32.391795],
          [47.464584, 32.39152],
          [47.465348, 32.391316],
          [47.464667, 32.38988],
          [47.464456, 32.389632],
          [47.464157, 32.389468],
          [47.464039, 32.388964],
          [47.464194, 32.388578],
          [47.464115, 32.387668],
          [47.463868, 32.387415],
          [47.463467, 32.387338],
          [47.463175, 32.38715],
          [47.463081, 32.386659],
          [47.463173, 32.386215],
          [47.463084, 32.385746],
          [47.462936, 32.38531],
          [47.46297, 32.384831],
          [47.462633, 32.384315],
          [47.462327, 32.384152],
          [47.462187, 32.383909],
          [47.462455, 32.38324],
          [47.462517, 32.382892],
          [47.462404, 32.382542],
          [47.46257, 32.38209],
          [47.462972, 32.382116],
          [47.463315, 32.381679],
          [47.463787, 32.381418],
          [47.463845, 32.380951],
          [47.463996, 32.380656],
          [47.464249, 32.380429],
          [47.464275, 32.380105],
          [47.464113, 32.379832],
          [47.464174, 32.379747],
          [47.464617, 32.379822],
          [47.464842, 32.379942],
          [47.465016, 32.379973],
          [47.465165, 32.379879],
          [47.465122, 32.379715],
          [47.465008, 32.37957],
          [47.465055, 32.379459],
          [47.465418, 32.379309],
          [47.465866, 32.379247],
          [47.466219, 32.378505],
          [47.466076, 32.37812],
          [47.466023, 32.377571],
          [47.465712, 32.377101],
          [47.46537, 32.376774],
          [47.464671, 32.376407],
          [47.464356, 32.375944],
          [47.464032, 32.375289],
          [47.463648, 32.374832],
          [47.463082, 32.374602],
          [47.462451, 32.374628],
          [47.461803, 32.37488],
          [47.461342, 32.375156],
          [47.46089, 32.375202],
          [47.460614, 32.374812],
          [47.460307, 32.37311],
          [47.460191, 32.37272],
          [47.460029, 32.372526],
          [47.459273, 32.37205],
          [47.458351, 32.371306],
          [47.457992, 32.370942],
          [47.457559, 32.370375],
          [47.455816, 32.368694],
          [47.454348, 32.367192],
          [47.453962, 32.366935],
          [47.453523, 32.366876],
          [47.45293, 32.366651],
          [47.452815, 32.366369],
          [47.452919, 32.365945],
          [47.452933, 32.36539],
          [47.452673, 32.364979],
          [47.452015, 32.36448],
          [47.452059, 32.364119],
          [47.451935, 32.363382],
          [47.451624, 32.362884],
          [47.451261, 32.362475],
          [47.450715, 32.361027],
          [47.450737, 32.360344],
          [47.451042, 32.359497],
          [47.451204, 32.358185],
          [47.449528, 32.35753],
          [47.448624, 32.357228],
          [47.447463, 32.356921],
          [47.442853, 32.356102],
          [47.442002, 32.355843],
          [47.44079, 32.355579],
          [47.439885, 32.355038],
          [47.439498, 32.354245],
          [47.438947, 32.353413],
          [47.4382, 32.352628],
          [47.437619, 32.352343],
          [47.436768, 32.352374],
          [47.435855, 32.352302],
          [47.435418, 32.352089],
          [47.435256, 32.351867],
          [47.435689, 32.351379],
          [47.436411, 32.350772],
          [47.437408, 32.350584],
          [47.438893, 32.350406],
          [47.439692, 32.350201],
          [47.440243, 32.349949],
          [47.440903, 32.349268],
          [47.441884, 32.34862],
          [47.442563, 32.348249],
          [47.443023, 32.348179],
          [47.44341, 32.348201],
          [47.444334, 32.348476],
          [47.444787, 32.34851],
          [47.445238, 32.348247],
          [47.445553, 32.347969],
          [47.445726, 32.347437],
          [47.446504, 32.346757],
          [47.446813, 32.346616],
          [47.44717, 32.346576],
          [47.447361, 32.346776],
          [47.447656, 32.346895],
          [47.448143, 32.346877],
          [47.448801, 32.346503],
          [47.449432, 32.346294],
          [47.450394, 32.346181],
          [47.450991, 32.345812],
          [47.451647, 32.345593],
          [47.452382, 32.345092],
          [47.453067, 32.344771],
          [47.453456, 32.344325],
          [47.453734, 32.344088],
          [47.454261, 32.342979],
          [47.454962, 32.341897],
          [47.455094, 32.341291],
          [47.455274, 32.340815],
          [47.455777, 32.340246],
          [47.456201, 32.339589],
          [47.456577, 32.338382],
          [47.457148, 32.337382],
          [47.457174, 32.337337],
          [47.457481, 32.336672],
          [47.457958, 32.33468],
          [47.45795, 32.33407],
          [47.457678, 32.333036],
          [47.457371, 32.332745],
          [47.456467, 32.332623],
          [47.456096, 32.332494],
          [47.455812, 32.332198],
          [47.455833, 32.331754],
          [47.456207, 32.331435],
          [47.456999, 32.330985],
          [47.45764, 32.330311],
          [47.458179, 32.329629],
          [47.458932, 32.328393],
          [47.459159, 32.327887],
          [47.459225, 32.327384],
          [47.459856, 32.32673],
          [47.460068, 32.326113],
          [47.460472, 32.325701],
          [47.461685, 32.325226],
          [47.462086, 32.324859],
          [47.461425, 32.324121],
          [47.461363, 32.323653],
          [47.461707, 32.322811],
          [47.462449, 32.321678],
          [47.462703, 32.321047],
          [47.461949, 32.319551],
          [47.461788, 32.318359],
          [47.461743, 32.316214],
          [47.46314, 32.314316],
          [47.463502, 32.31427],
          [47.46397, 32.314331],
          [47.46411, 32.314747],
          [47.464127, 32.315247],
          [47.464451, 32.315693],
          [47.46509, 32.316114],
          [47.465718, 32.316436],
          [47.46654, 32.316768],
          [47.468638, 32.317212],
          [47.470145, 32.317296],
          [47.470707, 32.31705],
          [47.471757, 32.316038],
          [47.472066, 32.315587],
          [47.472551, 32.315104],
          [47.473119, 32.314675],
          [47.473713, 32.313971],
          [47.474256, 32.313823],
          [47.475003, 32.313696],
          [47.475283, 32.313395],
          [47.475548, 32.313007],
          [47.476829, 32.312294],
          [47.477303, 32.311656],
          [47.477676, 32.310953],
          [47.478003, 32.310567],
          [47.478385, 32.310392],
          [47.478857, 32.310295],
          [47.479053, 32.310399],
          [47.479108, 32.310609],
          [47.479294, 32.310822],
          [47.479183, 32.31098],
          [47.479292, 32.311017],
          [47.479524, 32.310969],
          [47.479962, 32.311206],
          [47.480154, 32.31121],
          [47.480298, 32.311379],
          [47.48033, 32.311732],
          [47.480496, 32.311841],
          [47.480773, 32.311848],
          [47.481362, 32.31202],
          [47.481721, 32.311786],
          [47.482416, 32.311782],
          [47.482646, 32.312307],
          [47.48303, 32.312371],
          [47.483434, 32.313004],
          [47.483578, 32.313173],
          [47.483751, 32.313275],
          [47.484251, 32.313865],
          [47.484433, 32.314034],
          [47.484981, 32.314314],
          [47.485191, 32.314358],
          [47.485912, 32.314249],
          [47.486542, 32.31391],
          [47.487402, 32.313718],
          [47.488851, 32.313586],
          [47.489288, 32.313589],
          [47.489372, 32.313732],
          [47.489618, 32.313883],
          [47.489892, 32.31391],
          [47.490107, 32.313909],
          [47.490456, 32.313696],
          [47.490704, 32.313677],
          [47.49118, 32.313976],
          [47.491421, 32.314021],
          [47.491618, 32.313866],
          [47.491603, 32.313636],
          [47.491453, 32.313386],
          [47.49153, 32.313214],
          [47.492215, 32.31262],
          [47.49228, 32.312256],
          [47.492257, 32.311996],
          [47.492379, 32.311848],
          [47.492626, 32.311741],
          [47.49285, 32.31172],
          [47.493038, 32.311649],
          [47.493012, 32.311464],
          [47.492843, 32.311199],
          [47.492691, 32.311087],
          [47.492473, 32.311044],
          [47.492704, 32.310644],
          [47.493903, 32.310462],
          [47.494041, 32.310349],
          [47.494719, 32.310134],
          [47.495026, 32.309758],
          [47.495489, 32.309367],
          [47.49583, 32.308745],
          [47.495997, 32.308596],
          [47.496775, 32.308413],
          [47.49714, 32.308058],
          [47.497241, 32.307656],
          [47.497411, 32.307374],
          [47.49776, 32.307186],
          [47.498208, 32.306741],
          [47.498594, 32.305709],
          [47.498589, 32.304992],
          [47.498746, 32.304687],
          [47.499053, 32.304431],
          [47.499328, 32.304369],
          [47.500547, 32.304322],
          [47.501576, 32.30451],
          [47.502388, 32.304572],
          [47.502872, 32.304813],
          [47.503212, 32.305086],
          [47.503251, 32.305495],
          [47.503502, 32.305664],
          [47.503962, 32.305758],
          [47.504392, 32.305442],
          [47.505379, 32.305153],
          [47.50629, 32.30533],
          [47.506693, 32.305176],
          [47.507228, 32.304942],
          [47.507696, 32.304481],
          [47.508077, 32.303866],
          [47.508427, 32.303772],
          [47.509183, 32.303681],
          [47.509893, 32.303963],
          [47.510735, 32.304228],
          [47.511411, 32.304353],
          [47.511985, 32.304528],
          [47.513467, 32.304666],
          [47.515225, 32.304661],
          [47.51617, 32.304706],
          [47.516875, 32.304593],
          [47.517342, 32.304566],
          [47.517911, 32.304603],
          [47.51833, 32.304887],
          [47.518891, 32.305014],
          [47.519494, 32.304516],
          [47.520189, 32.303611],
          [47.520613, 32.302755],
          [47.521044, 32.302212],
          [47.521542, 32.30172],
          [47.521772, 32.301056],
          [47.521833, 32.300038],
          [47.521498, 32.29894],
          [47.521711, 32.297749],
          [47.521613, 32.2972],
          [47.520965, 32.296397],
          [47.521262, 32.295985],
          [47.52145, 32.295624],
          [47.521196, 32.29504],
          [47.520761, 32.29452],
          [47.52057, 32.293855],
          [47.52137, 32.292768],
          [47.521431, 32.291372],
          [47.521616, 32.290798],
          [47.52166, 32.290066],
          [47.521643, 32.289565],
          [47.521554, 32.289286],
          [47.520525, 32.289475],
          [47.519884, 32.28925],
          [47.519237, 32.289202],
          [47.517334, 32.28895],
          [47.516831, 32.289071],
          [47.516478, 32.289216],
          [47.515333, 32.288904],
          [47.514356, 32.288707],
          [47.513762, 32.28875],
          [47.512889, 32.288717],
          [47.512149, 32.288578],
          [47.511377, 32.288609],
          [47.510749, 32.288696],
          [47.510166, 32.288661],
          [47.509742, 32.288271],
          [47.509416, 32.287731],
          [47.509297, 32.286815],
          [47.509108, 32.286213],
          [47.509249, 32.285791],
          [47.509533, 32.2853],
          [47.509321, 32.284758],
          [47.509157, 32.284076],
          [47.509136, 32.283331],
          [47.509038, 32.282524],
          [47.508382, 32.281312],
          [47.507767, 32.280982],
          [47.507293, 32.280953],
          [47.506767, 32.280757],
          [47.506108, 32.280869],
          [47.505744, 32.280883],
          [47.505479, 32.280716],
          [47.505525, 32.280368],
          [47.505306, 32.280118],
          [47.504867, 32.280051],
          [47.504204, 32.280233],
          [47.503689, 32.280255],
          [47.503344, 32.280455],
          [47.502999, 32.280567],
          [47.502687, 32.28034],
          [47.502216, 32.27963],
          [47.502683, 32.279018],
          [47.502731, 32.278708],
          [47.502511, 32.278571],
          [47.502111, 32.278415],
          [47.501645, 32.27848],
          [47.501086, 32.278794],
          [47.500588, 32.278794],
          [47.499889, 32.278113],
          [47.499849, 32.277875],
          [47.500087, 32.277763],
          [47.500522, 32.277786],
          [47.500968, 32.277505],
          [47.501187, 32.277233],
          [47.50153, 32.277027],
          [47.501953, 32.277034],
          [47.502089, 32.276739],
          [47.502122, 32.276544],
          [47.502681, 32.275764],
          [47.503194, 32.275415],
          [47.504141, 32.274597],
          [47.504154, 32.274123],
          [47.504899, 32.273234],
          [47.505387, 32.272795],
          [47.505953, 32.272473],
          [47.506332, 32.272198],
          [47.506567, 32.271785],
          [47.507065, 32.271293],
          [47.507422, 32.27082],
          [47.507797, 32.270734],
          [47.50849, 32.270346],
          [47.508879, 32.269962],
          [47.509227, 32.269484],
          [47.509778, 32.268989],
          [47.510623, 32.268045],
          [47.511616, 32.267402],
          [47.511871, 32.266948],
          [47.512883, 32.265968],
          [47.512968, 32.265537],
          [47.513166, 32.265099],
          [47.513258, 32.264692],
          [47.513709, 32.264341],
          [47.514286, 32.263747],
          [47.514906, 32.26334],
          [47.515291, 32.262913],
          [47.515836, 32.262545],
          [47.515865, 32.262275],
          [47.515954, 32.262033],
          [47.516304, 32.261851],
          [47.516541, 32.261909],
          [47.516777, 32.262106],
          [47.517168, 32.262225],
          [47.51752, 32.262257],
          [47.517775, 32.262381],
          [47.518181, 32.262328],
          [47.518489, 32.26187],
          [47.518887, 32.261434],
          [47.519178, 32.260973],
          [47.519265, 32.260605],
          [47.519946, 32.260143],
          [47.520108, 32.259799],
          [47.520104, 32.259492],
          [47.519728, 32.259112],
          [47.519877, 32.258865],
          [47.520391, 32.258465],
          [47.520621, 32.258002],
          [47.520969, 32.25755],
          [47.522094, 32.256537],
          [47.522631, 32.255931],
          [47.523497, 32.254807],
          [47.523563, 32.254506],
          [47.523515, 32.253947],
          [47.52377, 32.253638],
          [47.523772, 32.253297],
          [47.524011, 32.252927],
          [47.524906, 32.252346],
          [47.525025, 32.251865],
          [47.525273, 32.2515],
          [47.525527, 32.251247],
          [47.52659, 32.250868],
          [47.526682, 32.25026],
          [47.526983, 32.249892],
          [47.52748, 32.249545],
          [47.52782, 32.248917],
          [47.528171, 32.248622],
          [47.528696, 32.248409],
          [47.529161, 32.248112],
          [47.529369, 32.247684],
          [47.529729, 32.247192],
          [47.530526, 32.246647],
          [47.530754, 32.24626],
          [47.530851, 32.245846],
          [47.531741, 32.24507],
          [47.532525, 32.244099],
          [47.533046, 32.243843],
          [47.533447, 32.243362],
          [47.533581, 32.242822],
          [47.53382, 32.242395],
          [47.533917, 32.24195],
          [47.533907, 32.241536],
          [47.534187, 32.24097],
          [47.535254, 32.239999],
          [47.537019, 32.237871],
          [47.537776, 32.237232],
          [47.538391, 32.236109],
          [47.538623, 32.23586],
          [47.539488, 32.23563],
          [47.539938, 32.235247],
          [47.539912, 32.234887],
          [47.539954, 32.234464],
          [47.540156, 32.234302],
          [47.540597, 32.234179],
          [47.54096, 32.233988],
          [47.541314, 32.23356],
          [47.541433, 32.233168],
          [47.541208, 32.23278],
          [47.541108, 32.23237],
          [47.541377, 32.231969],
          [47.541681, 32.231644],
          [47.541915, 32.231574],
          [47.542209, 32.231644],
          [47.542511, 32.232091],
          [47.542491, 32.232274],
          [47.54309, 32.232629],
          [47.543449, 32.232688],
          [47.544042, 32.232629],
          [47.544179, 32.232755],
          [47.544416, 32.232826],
          [47.545122, 32.232804],
          [47.545392, 32.233001],
          [47.546335, 32.23305],
          [47.54656, 32.232905],
          [47.54672, 32.232858],
          [47.546959, 32.232891],
          [47.54717, 32.232995],
          [47.54739, 32.233003],
          [47.548712, 32.232662],
          [47.549344, 32.23271],
          [47.550074, 32.232995],
          [47.551132, 32.233306],
          [47.552055, 32.233367],
          [47.552543, 32.233261],
          [47.553477, 32.233189],
          [47.553944, 32.233086],
          [47.554523, 32.23302],
          [47.556252, 32.231917],
          [47.556837, 32.231699],
          [47.557252, 32.231748],
          [47.557755, 32.231696],
          [47.558137, 32.231448],
          [47.558474, 32.231161],
          [47.560095, 32.230823],
          [47.560549, 32.230412],
          [47.560774, 32.230088],
          [47.561168, 32.2298],
          [47.561713, 32.229561],
          [47.562925, 32.229193],
          [47.563337, 32.229015],
          [47.56371, 32.228646],
          [47.564563, 32.227119],
          [47.565223, 32.226075],
          [47.565506, 32.225864],
          [47.565986, 32.225769],
          [47.566522, 32.225541],
          [47.56696, 32.225089],
          [47.567118, 32.224669],
          [47.566886, 32.223095],
          [47.56713, 32.222324],
          [47.567456, 32.221496],
          [47.567692, 32.220717],
          [47.567893, 32.220454],
          [47.568747, 32.218866],
          [47.569285, 32.217754],
          [47.569513, 32.217052],
          [47.570273, 32.21515],
          [47.570527, 32.214263],
          [47.570578, 32.213414],
          [47.570558, 32.212534],
          [47.570467, 32.212168],
          [47.570489, 32.211838],
          [47.570415, 32.211516],
          [47.570488, 32.210988],
          [47.57042, 32.210797],
          [47.570176, 32.210482],
          [47.569369, 32.209601],
          [47.56916, 32.20894],
          [47.569242, 32.208448],
          [47.569504, 32.208005],
          [47.569456, 32.207735],
          [47.569261, 32.207604],
          [47.568913, 32.207548],
          [47.568537, 32.207384],
          [47.568372, 32.206979],
          [47.568424, 32.206743],
          [47.568662, 32.206493],
          [47.568626, 32.206353],
          [47.568375, 32.206166],
          [47.568029, 32.206006],
          [47.56774, 32.205954],
          [47.567408, 32.205829],
          [47.566567, 32.205295],
          [47.566257, 32.205166],
          [47.565611, 32.204479],
          [47.565043, 32.20357],
          [47.564889, 32.203163],
          [47.564807, 32.201439],
          [47.564859, 32.200613],
          [47.564776, 32.199282],
          [47.564876, 32.198116],
          [47.564893, 32.197249],
          [47.56477, 32.196326],
          [47.564619, 32.195645],
          [47.564687, 32.195057],
          [47.565245, 32.194475],
          [47.565381, 32.193709],
          [47.565278, 32.193232],
          [47.564708, 32.192942],
          [47.564148, 32.192367],
          [47.563446, 32.191817],
          [47.562793, 32.191126],
          [47.562157, 32.190782],
          [47.560477, 32.19018],
          [47.560079, 32.189978],
          [47.559733, 32.189865],
          [47.559361, 32.189823],
          [47.558821, 32.189541],
          [47.558538, 32.189469],
          [47.557933, 32.188071],
          [47.557814, 32.187903],
          [47.557431, 32.187929],
          [47.557123, 32.188155],
          [47.556817, 32.188124],
          [47.556594, 32.187975],
          [47.556201, 32.188098],
          [47.555927, 32.188057],
          [47.555392, 32.187794],
          [47.555002, 32.187708],
          [47.554783, 32.187553],
          [47.554586, 32.187503],
          [47.55408, 32.187566],
          [47.553696, 32.187521],
          [47.553271, 32.187347],
          [47.553145, 32.187025],
          [47.552922, 32.186658],
          [47.55295, 32.186394],
          [47.553245, 32.185774],
          [47.553078, 32.185771],
          [47.551857, 32.186169],
          [47.550783, 32.185884],
          [47.550202, 32.185856],
          [47.549793, 32.18574],
          [47.549172, 32.184935],
          [47.549097, 32.184627],
          [47.549158, 32.184404],
          [47.549541, 32.183811],
          [47.548646, 32.183508],
          [47.5484, 32.183359],
          [47.548254, 32.183177],
          [47.547908, 32.182497],
          [47.547207, 32.182387],
          [47.546881, 32.182152],
          [47.546338, 32.181985],
          [47.5462, 32.181835],
          [47.546203, 32.18156],
          [47.546331, 32.181353],
          [47.545712, 32.180595],
          [47.544929, 32.180131],
          [47.544503, 32.180126],
          [47.544176, 32.180039],
          [47.543865, 32.180142],
          [47.542733, 32.180051],
          [47.541924, 32.179705],
          [47.541509, 32.179675],
          [47.541174, 32.179452],
          [47.540849, 32.17909],
          [47.5406, 32.178671],
          [47.54024, 32.178372],
          [47.540138, 32.177984],
          [47.539982, 32.177809],
          [47.539182, 32.177711],
          [47.538822, 32.177587],
          [47.538581, 32.177436],
          [47.53833, 32.176924],
          [47.538121, 32.176805],
          [47.537649, 32.176649],
          [47.537421, 32.176458],
          [47.53707, 32.176087],
          [47.536329, 32.175128],
          [47.536359, 32.174873],
          [47.536699, 32.174552],
          [47.537228, 32.174141],
          [47.537456, 32.174029],
          [47.537727, 32.17399],
          [47.537663, 32.173789],
          [47.537448, 32.173478],
          [47.536981, 32.173141],
          [47.536462, 32.172918],
          [47.536033, 32.172858],
          [47.534687, 32.173278],
          [47.534291, 32.173453],
          [47.534108, 32.173325],
          [47.534026, 32.173103],
          [47.533787, 32.172981],
          [47.533331, 32.173034],
          [47.532894, 32.172947],
          [47.53263, 32.172683],
          [47.532226, 32.172613],
          [47.531649, 32.172665],
          [47.531084, 32.172657],
          [47.530628, 32.172517],
          [47.530374, 32.172359],
          [47.530215, 32.17217],
          [47.530152, 32.171974],
          [47.529662, 32.171358],
          [47.529199, 32.170911],
          [47.528784, 32.170707],
          [47.528653, 32.17058],
          [47.528443, 32.17057],
          [47.528032, 32.170686],
          [47.527892, 32.170546],
          [47.527865, 32.170399],
          [47.52796, 32.17026],
          [47.528294, 32.169982],
          [47.528611, 32.169879],
          [47.528922, 32.169426],
          [47.528851, 32.169203],
          [47.529068, 32.168635],
          [47.529038, 32.16839],
          [47.529218, 32.168136],
          [47.529581, 32.167121],
          [47.529321, 32.166549],
          [47.52968, 32.165909],
          [47.529735, 32.165361],
          [47.529856, 32.165207],
          [47.53027, 32.164972],
          [47.530649, 32.164885],
          [47.530829, 32.164655],
          [47.531046, 32.16454],
          [47.531074, 32.164365],
          [47.531168, 32.164287],
          [47.531376, 32.164226],
          [47.531521, 32.16404],
          [47.531835, 32.16388],
          [47.531936, 32.163696],
          [47.531773, 32.163211],
          [47.531719, 32.162682],
          [47.532002, 32.162381],
          [47.532371, 32.161866],
          [47.532417, 32.161608],
          [47.532329, 32.16125],
          [47.532328, 32.160575],
          [47.532496, 32.159147],
          [47.532596, 32.15894],
          [47.532955, 32.158758],
          [47.533311, 32.158713],
          [47.533583, 32.158527],
          [47.533803, 32.158186],
          [47.53374, 32.157342],
          [47.533896, 32.15628],
          [47.534111, 32.156029],
          [47.534489, 32.15581],
          [47.534852, 32.155684],
          [47.535067, 32.155499],
          [47.535217, 32.155198],
          [47.535171, 32.154961],
          [47.535049, 32.154737],
          [47.534715, 32.154362],
          [47.534377, 32.153516],
          [47.53421, 32.153253],
          [47.534159, 32.152932],
          [47.533785, 32.152559],
          [47.533438, 32.152291],
          [47.533311, 32.151893],
          [47.533211, 32.151104],
          [47.532828, 32.150001],
          [47.532492, 32.14973],
          [47.532076, 32.149502],
          [47.531769, 32.14858],
          [47.531247, 32.148319],
          [47.530558, 32.147691],
          [47.530093, 32.147387],
          [47.529527, 32.146827],
          [47.529113, 32.146306],
          [47.528995, 32.145968],
          [47.529037, 32.145626],
          [47.529587, 32.145253],
          [47.529625, 32.144505],
          [47.529792, 32.143965],
          [47.529785, 32.143702],
          [47.529683, 32.143446],
          [47.529479, 32.143261],
          [47.529208, 32.142908],
          [47.529117, 32.142584],
          [47.529067, 32.142201],
          [47.529201, 32.141752],
          [47.529706, 32.141383],
          [47.529855, 32.141163],
          [47.530375, 32.141046],
          [47.530507, 32.14098],
          [47.530707, 32.141001],
          [47.530963, 32.141144],
          [47.531249, 32.141182],
          [47.531659, 32.141085],
          [47.531934, 32.141126],
          [47.53238, 32.141098],
          [47.532944, 32.140822],
          [47.533072, 32.140634],
          [47.533478, 32.140585],
          [47.533874, 32.140259],
          [47.534312, 32.140067],
          [47.534651, 32.140158],
          [47.535318, 32.140119],
          [47.535876, 32.140189],
          [47.536073, 32.140064],
          [47.536111, 32.139816],
          [47.536295, 32.139339],
          [47.536584, 32.139174],
          [47.536886, 32.139101],
          [47.537129, 32.138912],
          [47.537353, 32.138829],
          [47.537479, 32.138651],
          [47.537873, 32.138448],
          [47.538579, 32.13834],
          [47.538906, 32.138405],
          [47.539108, 32.138387],
          [47.539664, 32.138274],
          [47.540836, 32.137866],
          [47.541253, 32.137881],
          [47.541706, 32.137596],
          [47.542553, 32.137327],
          [47.543242, 32.13735],
          [47.543528, 32.137086],
          [47.543711, 32.137039],
          [47.543925, 32.136916],
          [47.544534, 32.136788],
          [47.544915, 32.136753],
          [47.545016, 32.136678],
          [47.545428, 32.136543],
          [47.546297, 32.136355],
          [47.546483, 32.136236],
          [47.546805, 32.136174],
          [47.546928, 32.136095],
          [47.547756, 32.135838],
          [47.54895, 32.135559],
          [47.550073, 32.135074],
          [47.550635, 32.134638],
          [47.551281, 32.134343],
          [47.551301, 32.134094],
          [47.551452, 32.133666],
          [47.551587, 32.133528],
          [47.551878, 32.133433],
          [47.552284, 32.132756],
          [47.552622, 32.131926],
          [47.552779, 32.131695],
          [47.552693, 32.131587],
          [47.552426, 32.131555],
          [47.552276, 32.131398],
          [47.552238, 32.131192],
          [47.552409, 32.130793],
          [47.552548, 32.130669],
          [47.552844, 32.130616],
          [47.553332, 32.130358],
          [47.553786, 32.130272],
          [47.554368, 32.12989],
          [47.554372, 32.129535],
          [47.554161, 32.128869],
          [47.554159, 32.1286],
          [47.554245, 32.128405],
          [47.554576, 32.128332],
          [47.554734, 32.128214],
          [47.554835, 32.127964],
          [47.554554, 32.127835],
          [47.55442, 32.127675],
          [47.554436, 32.127497],
          [47.554815, 32.12717],
          [47.555249, 32.126492],
          [47.555254, 32.126076],
          [47.555433, 32.125907],
          [47.555737, 32.125819],
          [47.556116, 32.125515],
          [47.556433, 32.125043],
          [47.55666, 32.124596],
          [47.557143, 32.124231],
          [47.55728, 32.123795],
          [47.557244, 32.123528],
          [47.557449, 32.123217],
          [47.557424, 32.122863],
          [47.557657, 32.122613],
          [47.558766, 32.122032],
          [47.55906, 32.122018],
          [47.559349, 32.121937],
          [47.559415, 32.121623],
          [47.559573, 32.121524],
          [47.560002, 32.12141],
          [47.560038, 32.121285],
          [47.559745, 32.120983],
          [47.559696, 32.120624],
          [47.559808, 32.119395],
          [47.559745, 32.118919],
          [47.559194, 32.118853],
          [47.559147, 32.118479],
          [47.559037, 32.11813],
          [47.559226, 32.117803],
          [47.559169, 32.117455],
          [47.559214, 32.117083],
          [47.559422, 32.116696],
          [47.559353, 32.116501],
          [47.559077, 32.116347],
          [47.558674, 32.115994],
          [47.558833, 32.115271],
          [47.558923, 32.11518],
          [47.559259, 32.115148],
          [47.560071, 32.115177],
          [47.560396, 32.115062],
          [47.560709, 32.11503],
          [47.561026, 32.115077],
          [47.561406, 32.115061],
          [47.562212, 32.114832],
          [47.562436, 32.114636],
          [47.56265, 32.114295],
          [47.56298, 32.114241],
          [47.56332, 32.114114],
          [47.563592, 32.113952],
          [47.56438, 32.113197],
          [47.564552, 32.11311],
          [47.564995, 32.112657],
          [47.565404, 32.112532],
          [47.565708, 32.112076],
          [47.565933, 32.112017],
          [47.566477, 32.112161],
          [47.566739, 32.112133],
          [47.567336, 32.111936],
          [47.567557, 32.112014],
          [47.567571, 32.112196],
          [47.567977, 32.112431],
          [47.568347, 32.112416],
          [47.568535, 32.112501],
          [47.56937, 32.112446],
          [47.569644, 32.112354],
          [47.570011, 32.112331],
          [47.570603, 32.112202],
          [47.570854, 32.112261],
          [47.570996, 32.112386],
          [47.571361, 32.112444],
          [47.571701, 32.112468],
          [47.572654, 32.112425],
          [47.572982, 32.112257],
          [47.57347, 32.111802],
          [47.574501, 32.111575],
          [47.574991, 32.111279],
          [47.575216, 32.11122],
          [47.575469, 32.111241],
          [47.575729, 32.111379],
          [47.578357, 32.111659],
          [47.57847, 32.111828],
          [47.578208, 32.112267],
          [47.577928, 32.112317],
          [47.577867, 32.112521],
          [47.577928, 32.112751],
          [47.577738, 32.11305],
          [47.577834, 32.113283],
          [47.577981, 32.113408],
          [47.578029, 32.113522],
          [47.577992, 32.113949],
          [47.578051, 32.114194],
          [47.578416, 32.114379],
          [47.578779, 32.11447],
          [47.57894, 32.114667],
          [47.578978, 32.114963],
          [47.578922, 32.115209],
          [47.578753, 32.115414],
          [47.578675, 32.116009],
          [47.579268, 32.116885],
          [47.57961, 32.116942],
          [47.580013, 32.116903],
          [47.580581, 32.116598],
          [47.580927, 32.116475],
          [47.581251, 32.116464],
          [47.581524, 32.116321],
          [47.582277, 32.116219],
          [47.582583, 32.116075],
          [47.582924, 32.115995],
          [47.583064, 32.115828],
          [47.583567, 32.11578],
          [47.583687, 32.115603],
          [47.583666, 32.115437],
          [47.583524, 32.115203],
          [47.584773, 32.115021],
          [47.585669, 32.115088],
          [47.585957, 32.115027],
          [47.586439, 32.114681],
          [47.586773, 32.114682],
          [47.586938, 32.114591],
          [47.587141, 32.11438],
          [47.587143, 32.11413],
          [47.587083, 32.113923],
          [47.587648, 32.113737],
          [47.587859, 32.113709],
          [47.588242, 32.114075],
          [47.588496, 32.11419],
          [47.588955, 32.115071],
          [47.589111, 32.115227],
          [47.589476, 32.115393],
          [47.589564, 32.115571],
          [47.589183, 32.115908],
          [47.589353, 32.116416],
          [47.589674, 32.116439],
          [47.590173, 32.116717],
          [47.590571, 32.116466],
          [47.590891, 32.116352],
          [47.591198, 32.11632],
          [47.591341, 32.116578],
          [47.591255, 32.116773],
          [47.591278, 32.116944],
          [47.591472, 32.117027],
          [47.592287, 32.11696],
          [47.592809, 32.117155],
          [47.593055, 32.117087],
          [47.593257, 32.117154],
          [47.593411, 32.117131],
          [47.593621, 32.117188],
          [47.594348, 32.117199],
          [47.594533, 32.117227],
          [47.594782, 32.117343],
          [47.595189, 32.117317],
          [47.595493, 32.11747],
          [47.595626, 32.117408],
          [47.595749, 32.117268],
          [47.5959, 32.117231],
          [47.596232, 32.117247],
          [47.596547, 32.117181],
          [47.596834, 32.117224],
          [47.59704, 32.117309],
          [47.597505, 32.117155],
          [47.597654, 32.117005],
          [47.597946, 32.116896],
          [47.598503, 32.117046],
          [47.598743, 32.116956],
          [47.598766, 32.116503],
          [47.599258, 32.116277],
          [47.599665, 32.116322],
          [47.599878, 32.116128],
          [47.599931, 32.115854],
          [47.600197, 32.115579],
          [47.600744, 32.115457],
          [47.600947, 32.115312],
          [47.601261, 32.114788],
          [47.601858, 32.114421],
          [47.602111, 32.114314],
          [47.602345, 32.114375],
          [47.602515, 32.114345],
          [47.60288, 32.11384],
          [47.60356, 32.113481],
          [47.604056, 32.113147],
          [47.604287, 32.113086],
          [47.604641, 32.112862],
          [47.604747, 32.11272],
          [47.604907, 32.112611],
          [47.605206, 32.112505],
          [47.606277, 32.111609],
          [47.606844, 32.111455],
          [47.607013, 32.111571],
          [47.60717, 32.111537],
          [47.607622, 32.111617],
          [47.607899, 32.111732],
          [47.608033, 32.111869],
          [47.608164, 32.111907],
          [47.608371, 32.111798],
          [47.608488, 32.111636],
          [47.608441, 32.11146],
          [47.608482, 32.111226],
          [47.608731, 32.111082],
          [47.609327, 32.11097],
          [47.609651, 32.110784],
          [47.610009, 32.110729],
          [47.61006, 32.110512],
          [47.610252, 32.110326],
          [47.610776, 32.110095],
          [47.610947, 32.10996],
          [47.611278, 32.109868],
          [47.611724, 32.109924],
          [47.611965, 32.109881],
          [47.612246, 32.109901],
          [47.612522, 32.109837],
          [47.612658, 32.109747],
          [47.612706, 32.109493],
          [47.613109, 32.109217],
          [47.613738, 32.109142],
          [47.613864, 32.108968],
          [47.613952, 32.108697],
          [47.614509, 32.108091],
          [47.61496, 32.107717],
          [47.615345, 32.107586],
          [47.615554, 32.107402],
          [47.616126, 32.107049],
          [47.616556, 32.106896],
          [47.617118, 32.106417],
          [47.617339, 32.106344],
          [47.617477, 32.106107],
          [47.617598, 32.106043],
          [47.617712, 32.10589],
          [47.618756, 32.10539],
          [47.619572, 32.104832],
          [47.62147, 32.103803],
          [47.621454, 32.103507],
          [47.621848, 32.10292],
          [47.622215, 32.10258],
          [47.622541, 32.102372],
          [47.622673, 32.102394],
          [47.622745, 32.1025],
          [47.623193, 32.1023],
          [47.623378, 32.101983],
          [47.623951, 32.101621],
          [47.624115, 32.101437],
          [47.624028, 32.101344],
          [47.624032, 32.101101],
          [47.624237, 32.100744],
          [47.624284, 32.100406],
          [47.625351, 32.099217],
          [47.62579, 32.098911],
          [47.626123, 32.098562],
          [47.627187, 32.097925],
          [47.627653, 32.097325],
          [47.628403, 32.096849],
          [47.628581, 32.096389],
          [47.62881, 32.096002],
          [47.629037, 32.095868],
          [47.629439, 32.095728],
          [47.630388, 32.095107],
          [47.630702, 32.094829],
          [47.630908, 32.094616],
          [47.631015, 32.094287],
          [47.631393, 32.094013],
          [47.63168, 32.093398],
          [47.6316, 32.093217],
          [47.631684, 32.093062],
          [47.632448, 32.092733],
          [47.633123, 32.092374],
          [47.633434, 32.092085],
          [47.633598, 32.091762],
          [47.633474, 32.091413],
          [47.633368, 32.091277],
          [47.633574, 32.091178],
          [47.633626, 32.090952],
          [47.634094, 32.090872],
          [47.634238, 32.090965],
          [47.634547, 32.090785],
          [47.634452, 32.090617],
          [47.634786, 32.090206],
          [47.635153, 32.090077],
          [47.635222, 32.089864],
          [47.635372, 32.089816],
          [47.635508, 32.089621],
          [47.635825, 32.089682],
          [47.63631, 32.089388],
          [47.636406, 32.089206],
          [47.636576, 32.089304],
          [47.63676, 32.089312],
          [47.637005, 32.089175],
          [47.637217, 32.088961],
          [47.637419, 32.088873],
          [47.637593, 32.088583],
          [47.637744, 32.088611],
          [47.638134, 32.088418],
          [47.639074, 32.088109],
          [47.639746, 32.087827],
          [47.640243, 32.087505],
          [47.640619, 32.087182],
          [47.641479, 32.086981],
          [47.641939, 32.086776],
          [47.642036, 32.086535],
          [47.642084, 32.086173],
          [47.642325, 32.085883],
          [47.642711, 32.085642],
          [47.643267, 32.085473],
          [47.644256, 32.085062],
          [47.644497, 32.084869],
          [47.645414, 32.084459],
          [47.645511, 32.084314],
          [47.646235, 32.084194],
          [47.64679, 32.083639],
          [47.647104, 32.083542],
          [47.647683, 32.082987],
          [47.64831, 32.08289],
          [47.648576, 32.082963],
          [47.64901, 32.08318],
          [47.649252, 32.083156],
          [47.649541, 32.082987],
          [47.650072, 32.082529],
          [47.650386, 32.082384],
          [47.650555, 32.082094],
          [47.650965, 32.083276],
          [47.651042, 32.082347],
          [47.65071, 32.081339],
          [47.650692, 32.079986],
          [47.650982, 32.077695],
          [47.651158, 32.076972],
          [47.651213, 32.076333],
          [47.651296, 32.076009],
          [47.651611, 32.075292],
          [47.652696, 32.07363],
          [47.653355, 32.072836],
          [47.653669, 32.072299],
          [47.653816, 32.071958],
          [47.65414, 32.070687],
          [47.65409, 32.070521],
          [47.653978, 32.070407],
          [47.653459, 32.070249],
          [47.653337, 32.070121],
          [47.65315, 32.069727],
          [47.652719, 32.069217],
          [47.652698, 32.068741],
          [47.652526, 32.067911],
          [47.652245, 32.067163],
          [47.65195, 32.066649],
          [47.651526, 32.066138],
          [47.650133, 32.064961],
          [47.649937, 32.064698],
          [47.649216, 32.063219],
          [47.648629, 32.062196],
          [47.648467, 32.062133],
          [47.647993, 32.062141],
          [47.647789, 32.062034],
          [47.647548, 32.061974],
          [47.647319, 32.061541],
          [47.647088, 32.061356],
          [47.646836, 32.060767],
          [47.64657, 32.060475],
          [47.646016, 32.060091],
          [47.646058, 32.059732],
          [47.646158, 32.059498],
          [47.646696, 32.058885],
          [47.647166, 32.058624],
          [47.647668, 32.058255],
          [47.648035, 32.057796],
          [47.648316, 32.057302],
          [47.649011, 32.056433],
          [47.650231, 32.055418],
          [47.650855, 32.055078],
          [47.65209, 32.053885],
          [47.652556, 32.053564],
          [47.653248, 32.053294],
          [47.653458, 32.053142],
          [47.653578, 32.052419],
          [47.653915, 32.052131],
          [47.65425, 32.051978],
          [47.654606, 32.051903],
          [47.655172, 32.051584],
          [47.655544, 32.051475],
          [47.656158, 32.051426],
          [47.657255, 32.051124],
          [47.658461, 32.050431],
          [47.658726, 32.050342],
          [47.659045, 32.050408],
          [47.65938, 32.050306],
          [47.660582, 32.049408],
          [47.661217, 32.049251],
          [47.661912, 32.048806],
          [47.662449, 32.048564],
          [47.6627, 32.048518],
          [47.663834, 32.048633],
          [47.664111, 32.048542],
          [47.66444, 32.048871],
          [47.664699, 32.049126],
          [47.66514, 32.049372],
          [47.665632, 32.049667],
          [47.700187, 32.064853],
          [47.75008, 32.086754],
          [47.755869, 32.088843],
          [47.760529, 32.09077],
          [47.767029, 32.093346],
          [47.772446, 32.095966],
          [47.778298, 32.098774],
          [47.781876, 32.100429],
          [47.783994, 32.101491],
          [47.785179, 32.102084],
          [47.788433, 32.103465],
          [47.793037, 32.105858],
          [47.798187, 32.108619],
          [47.802036, 32.111011],
          [47.805073, 32.113037],
          [47.810063, 32.116073],
          [47.810798, 32.116606],
          [47.8144, 32.119203],
          [47.81863, 32.121872],
          [47.821396, 32.124128],
          [47.82655, 32.127944],
          [47.830944, 32.131072],
          [47.838814, 32.13747],
          [47.846359, 32.14465],
          [47.850376, 32.148009],
          [47.857655, 32.155051],
          [47.865044, 32.162738],
          [47.86858, 32.16734],
          [47.872986, 32.173188],
          [47.878262, 32.180091],
          [47.880219, 32.182301],
          [47.881687, 32.184098],
          [47.883591, 32.185844],
          [47.886089, 32.187686],
          [47.890053, 32.190221],
          [47.890423, 32.19042],
          [47.895432, 32.193118],
          [47.903198, 32.19754],
          [47.907597, 32.199933],
          [47.911888, 32.20251],
          [47.915855, 32.205181],
          [47.918247, 32.206978],
          [47.921291, 32.209466],
          [47.925045, 32.212598],
          [47.92831, 32.21568],
          [47.931629, 32.219044],
          [47.93528, 32.223141],
          [47.938549, 32.228248],
          [47.941715, 32.233543],
          [47.942245, 32.234338],
          [47.943405, 32.236077],
          [47.945313, 32.238472],
          [47.948582, 32.24165],
          [47.951737, 32.244091],
          [47.955219, 32.246762],
          [47.95648, 32.247636],
          [47.959874, 32.249987],
          [47.962059, 32.252044],
          [47.964363, 32.253731],
          [47.968189, 32.255592],
          [47.972786, 32.258137],
          [47.975132, 32.259133],
          [47.975948, 32.259403],
          [47.977035, 32.25967],
          [47.97818, 32.259891],
          [47.979595, 32.260342],
          [47.9814, 32.261249],
          [47.983093, 32.262067],
          [47.984081, 32.262749],
          [47.985024, 32.263894],
          [47.985642, 32.265182],
          [47.986099, 32.266701],
          [47.986675, 32.268639],
          [47.986923, 32.27039],
          [47.987061, 32.272003],
          [47.987194, 32.273297],
          [47.987099, 32.274174],
          [47.987003, 32.274913],
          [47.986645, 32.276075],
          [47.986122, 32.277092],
          [47.985863, 32.27774],
          [47.984955, 32.278534],
          [47.983616, 32.279514],
          [47.981037, 32.280784],
          [47.980618, 32.281006],
          [47.977223, 32.282803],
          [47.97234, 32.285618],
          [47.968155, 32.288148],
          [47.965645, 32.290155],
          [47.963028, 32.292438],
          [47.962444, 32.293366],
          [47.961975, 32.294387],
          [47.961777, 32.295403],
          [47.961899, 32.296281],
          [47.962143, 32.297711],
          [47.962551, 32.299506],
          [47.962788, 32.300567],
          [47.963364, 32.302639],
          [47.96389, 32.304896],
          [47.96431, 32.307063],
          [47.964722, 32.309093],
          [47.96558, 32.311532],
          [47.967075, 32.316646],
          [47.969574, 32.323045],
          [47.97171, 32.327457],
          [47.974606, 32.331726],
          [47.976048, 32.333564],
          [47.977859, 32.334748],
          [47.981258, 32.336799],
          [47.984806, 32.338015],
          [47.989014, 32.339599],
          [47.996393, 32.342404],
          [48.000004, 32.343779],
          [48.000812, 32.344244],
          [48.004601, 32.346135],
          [48.008514, 32.347367],
          [48.013565, 32.347714],
          [48.017593, 32.348202],
          [48.020977, 32.348369],
          [48.026081, 32.349128],
          [48.029091, 32.34953],
          [48.031296, 32.350116],
          [48.032265, 32.350342],
          [48.033505, 32.351165],
          [48.034264, 32.352177],
          [48.034859, 32.353004],
          [48.035454, 32.353969],
          [48.035626, 32.355351],
          [48.035637, 32.357285],
          [48.035439, 32.359775],
          [48.034596, 32.362129],
          [48.033272, 32.365409],
          [48.032486, 32.368271],
          [48.031651, 32.372329],
          [48.031124, 32.374266],
          [48.030491, 32.376301],
          [48.029274, 32.379486],
          [48.027142, 32.38263],
          [48.025707, 32.385264],
          [48.024326, 32.388176],
          [48.023312, 32.389702],
          [48.022892, 32.391548],
          [48.022106, 32.395194],
          [48.021423, 32.398011],
          [48.020008, 32.405021],
          [48.01955, 32.409679],
          [48.019402, 32.411983],
          [48.018723, 32.41554],
          [48.018204, 32.418906],
          [48.018894, 32.427059],
          [48.019291, 32.430608],
          [48.019367, 32.43448],
          [48.019714, 32.438209],
          [48.020161, 32.441204],
          [48.021259, 32.444611],
          [48.022087, 32.448108],
          [48.022533, 32.450641],
          [48.022923, 32.452805],
          [48.022884, 32.454925],
          [48.022892, 32.456677],
          [48.022472, 32.458844],
          [48.022385, 32.462025],
          [48.022129, 32.464931],
          [48.021885, 32.469174],
          [48.02169, 32.472584],
          [48.021709, 32.475857],
          [48.021507, 32.478627],
          [48.021355, 32.480515],
          [48.020939, 32.482822],
          [48.02084, 32.484898],
          [48.020588, 32.487709],
          [48.020382, 32.489602],
          [48.020119, 32.491214],
          [48.020241, 32.493519],
          [48.02047, 32.495731],
          [48.020657, 32.50006],
          [48.020657, 32.501602],
          [48.020679, 32.503029],
          [48.020592, 32.504414],
          [48.020504, 32.505708],
          [48.020355, 32.506676],
          [48.0201, 32.507598],
          [48.019898, 32.508526],
          [48.019896, 32.508535],
          [48.019798, 32.509034],
          [48.019104, 32.509777],
          [48.018246, 32.510333],
          [48.017555, 32.511261],
          [48.016811, 32.51242],
          [48.016338, 32.513439],
          [48.015976, 32.514458],
          [48.01593, 32.515012],
          [48.01572, 32.515426],
          [48.015396, 32.515614],
          [48.014965, 32.5158],
          [48.014702, 32.516265],
          [48.014549, 32.51696],
          [48.014561, 32.51765],
          [48.014839, 32.518108],
          [48.015232, 32.518752],
          [48.015449, 32.51913],
          [48.015732, 32.519623],
          [48.016182, 32.520497],
          [48.016304, 32.521465],
          [48.016266, 32.522388],
          [48.016064, 32.523357],
          [48.015698, 32.524238],
          [48.014683, 32.525165],
          [48.012978, 32.52712],
          [48.011916, 32.527959],
          [48.011154, 32.528562],
          [48.009815, 32.529632],
          [48.009175, 32.530221],
          [48.007942, 32.531352],
          [48.006496, 32.532612],
          [48.005741, 32.533031],
          [48.004337, 32.533412],
          [48.00293, 32.533699],
          [48.001091, 32.534038],
          [47.999989, 32.534275],
          [47.997536, 32.534938],
          [47.996937, 32.5351],
          [47.993561, 32.53547],
          [47.989803, 32.535702],
          [47.986805, 32.535792],
          [47.984627, 32.535934],
          [47.984269, 32.535978],
          [47.982395, 32.536209],
          [47.981361, 32.536304],
          [47.980599, 32.536351],
          [47.979942, 32.536166],
          [47.979397, 32.535939],
          [47.978958, 32.535478],
          [47.978684, 32.534923],
          [47.978569, 32.534328],
          [47.978565, 32.53373],
          [47.978561, 32.533038],
          [47.978775, 32.532119],
          [47.979206, 32.531246],
          [47.979858, 32.530509],
          [47.980375, 32.529624],
          [47.98045, 32.529496],
          [47.980827, 32.528531],
          [47.981041, 32.528072],
          [47.981037, 32.527519],
          [47.980984, 32.527058],
          [47.980652, 32.526367],
          [47.980213, 32.525863],
          [47.97945, 32.52568],
          [47.979143, 32.52563],
          [47.977215, 32.525313],
          [47.971653, 32.524995],
          [47.968712, 32.524766],
          [47.965984, 32.524448],
          [47.963749, 32.52422],
          [47.962277, 32.523849],
          [47.96064, 32.5233],
          [47.958893, 32.522288],
          [47.957363, 32.521415],
          [47.956596, 32.520912],
          [47.955505, 32.520176],
          [47.954956, 32.519624],
          [47.954681, 32.519166],
          [47.954678, 32.518336],
          [47.954781, 32.51691],
          [47.954884, 32.516083],
          [47.95488, 32.515578],
          [47.954609, 32.515346],
          [47.95417, 32.515164],
          [47.953842, 32.515118],
          [47.952648, 32.515305],
          [47.950905, 32.515671],
          [47.949165, 32.515994],
          [47.946552, 32.516228],
          [47.944713, 32.516425],
          [47.944428, 32.516456],
          [47.942577, 32.51701],
          [47.940567, 32.517841],
          [47.939373, 32.518806],
          [47.938831, 32.519498],
          [47.938835, 32.520095],
          [47.938999, 32.520832],
          [47.939331, 32.521611],
          [47.939934, 32.522346],
          [47.940701, 32.523267],
          [47.941467, 32.523727],
          [47.942448, 32.524001],
          [47.94381, 32.524093],
          [47.946426, 32.524414],
          [47.947682, 32.524505],
          [47.948498, 32.524779],
          [47.949101, 32.525283],
          [47.949814, 32.525929],
          [47.95047, 32.526849],
          [47.950913, 32.527813],
          [47.95097, 32.528687],
          [47.951084, 32.529652],
          [47.951359, 32.530434],
          [47.951306, 32.530433],
          [47.951416, 32.530708],
          [47.951798, 32.530941],
          [47.952183, 32.531171],
          [47.953163, 32.531352],
          [47.953598, 32.531491],
          [47.954084, 32.53187],
          [47.954365, 32.532089],
          [47.955025, 32.532917],
          [47.95541, 32.534111],
          [47.95531, 32.53494],
          [47.955432, 32.535125],
          [47.955834, 32.535732],
          [47.955278, 32.538504],
          [47.955082, 32.538765],
          [47.954444, 32.539615],
          [47.954444, 32.539895],
          [47.952501, 32.539895],
          [47.951391, 32.539895],
          [47.951098, 32.539796],
          [47.950699, 32.539909],
          [47.94939, 32.540001],
          [47.948193, 32.540092],
          [47.947647, 32.540002],
          [47.947212, 32.53991],
          [47.946884, 32.539726],
          [47.94661, 32.539452],
          [47.946549, 32.538116],
          [47.946434, 32.537105],
          [47.946381, 32.536922],
          [47.946045, 32.535771],
          [47.945713, 32.53453],
          [47.944836, 32.533246],
          [47.944397, 32.532555],
          [47.943848, 32.532138],
          [47.943302, 32.531773],
          [47.942482, 32.531498],
          [47.941341, 32.531498],
          [47.93974, 32.531573],
          [47.939377, 32.53159],
          [47.937744, 32.532005],
          [47.937189, 32.532188],
          [47.936058, 32.532559],
          [47.934483, 32.533204],
          [47.933777, 32.533939],
          [47.9328, 32.53495],
          [47.932423, 32.535829],
          [47.93232, 32.536514],
          [47.93232, 32.537158],
          [47.932541, 32.537574],
          [47.933033, 32.538032],
          [47.933689, 32.538769],
          [47.933925, 32.539005],
          [47.934239, 32.539317],
          [47.934677, 32.540238],
          [47.934681, 32.540796],
          [47.934685, 32.54148],
          [47.934486, 32.542072],
          [47.93436, 32.542446],
          [47.933605, 32.543872],
          [47.932873, 32.544826],
          [47.932793, 32.54493],
          [47.932144, 32.545757],
          [47.931816, 32.546218],
          [47.931713, 32.546864],
          [47.931824, 32.547413],
          [47.932316, 32.547828],
          [47.933514, 32.548058],
          [47.934605, 32.54824],
          [47.935753, 32.548379],
          [47.936897, 32.548377],
          [47.937603, 32.548379],
          [47.938095, 32.548607],
          [47.938316, 32.549018],
          [47.938591, 32.549572],
          [47.938816, 32.550675],
          [47.938931, 32.551873],
          [47.938717, 32.552884],
          [47.939102, 32.553574],
          [47.939323, 32.554168],
          [47.939598, 32.55495],
          [47.939766, 32.555916],
          [47.93988, 32.556652],
          [47.939884, 32.557389],
          [47.939884, 32.557941],
          [47.939941, 32.5584],
          [47.940109, 32.559044],
          [47.940384, 32.559364],
          [47.940765, 32.559689],
          [47.942074, 32.559689],
          [47.943001, 32.559872],
          [47.943029, 32.559884],
          [47.943657, 32.560147],
          [47.944206, 32.560604],
          [47.94426, 32.56102],
          [47.944046, 32.561572],
          [47.943394, 32.562264],
          [47.942524, 32.56263],
          [47.941365, 32.562834],
          [47.940945, 32.562907],
          [47.94091, 32.562907],
          [47.93898, 32.562909],
          [47.935055, 32.562908],
          [47.933311, 32.562816],
          [47.931564, 32.562359],
          [47.930035, 32.56153],
          [47.928997, 32.561027],
          [47.928013, 32.56052],
          [47.926758, 32.560016],
          [47.925663, 32.559372],
          [47.924896, 32.558824],
          [47.924187, 32.558177],
          [47.923748, 32.557533],
          [47.923416, 32.556892],
          [47.922924, 32.556249],
          [47.922264, 32.555419],
          [47.921883, 32.554821],
          [47.921062, 32.554364],
          [47.920296, 32.553906],
          [47.919205, 32.553719],
          [47.918224, 32.553814],
          [47.917519, 32.554088],
          [47.916973, 32.55446],
          [47.916388, 32.555078],
          [47.916321, 32.555149],
          [47.916218, 32.555974],
          [47.915962, 32.558121],
          [47.915955, 32.558181],
          [47.915474, 32.560254],
          [47.914772, 32.561909],
          [47.91423, 32.562874],
          [47.913521, 32.563287],
          [47.912922, 32.56338],
          [47.911671, 32.563564],
          [47.911124, 32.563494],
          [47.910252, 32.563382],
          [47.909435, 32.56338],
          [47.909206, 32.563387],
          [47.907799, 32.563428],
          [47.906708, 32.563473],
          [47.904964, 32.56366],
          [47.903439, 32.564258],
          [47.902569, 32.564762],
          [47.90192, 32.565543],
          [47.901158, 32.566142],
          [47.900726, 32.566925],
          [47.90071, 32.566976],
          [47.900509, 32.567616],
          [47.90062, 32.568306],
          [47.901279, 32.569039],
          [47.902592, 32.569867],
          [47.903553, 32.570254],
          [47.903736, 32.570328],
          [47.905155, 32.570832],
          [47.905922, 32.571153],
          [47.90625, 32.571659],
          [47.906418, 32.572258],
          [47.906258, 32.573316],
          [47.906208, 32.574372],
          [47.906323, 32.575382],
          [47.906597, 32.576119],
          [47.906715, 32.576316],
          [47.907089, 32.576947],
          [47.907368, 32.577682],
          [47.907806, 32.578369],
          [47.908299, 32.578647],
          [47.9095, 32.579289],
          [47.910126, 32.579495],
          [47.910484, 32.579613],
          [47.911629, 32.579795],
          [47.912285, 32.579795],
          [47.912827, 32.579518],
          [47.913536, 32.579197],
          [47.914517, 32.579196],
          [47.916206, 32.579197],
          [47.917005, 32.579335],
          [47.918064, 32.579518],
          [47.919212, 32.579974],
          [47.920016, 32.580279],
          [47.920795, 32.580574],
          [47.922161, 32.580939],
          [47.922813, 32.580987],
          [47.923265, 32.580911],
          [47.923801, 32.58067],
          [47.924065, 32.580386],
          [47.9245, 32.579743],
          [47.92477, 32.579148],
          [47.925148, 32.578549],
          [47.925747, 32.57841],
          [47.92651, 32.578131],
          [47.92749, 32.578133],
          [47.928421, 32.578133],
          [47.929619, 32.578316],
          [47.930058, 32.578498],
          [47.930142, 32.578561],
          [47.93055, 32.578868],
          [47.931152, 32.57951],
          [47.932083, 32.580706],
          [47.932485, 32.581584],
          [47.932526, 32.581673],
          [47.932858, 32.582409],
          [47.932858, 32.582867],
          [47.932697, 32.583557],
          [47.93232, 32.584064],
          [47.93161, 32.584522],
          [47.930576, 32.585029],
          [47.928997, 32.585675],
          [47.928056, 32.586088],
          [47.927528, 32.58632],
          [47.926605, 32.586917],
          [47.924706, 32.588802],
          [47.923077, 32.590505],
          [47.920792, 32.592621],
          [47.918022, 32.59483],
          [47.916607, 32.595933],
          [47.915901, 32.596944],
          [47.915199, 32.598691],
          [47.914822, 32.599933],
          [47.914612, 32.601174],
          [47.914558, 32.601817],
          [47.914726, 32.602416],
          [47.915218, 32.602782],
          [47.915981, 32.602875],
          [47.916483, 32.60291],
          [47.916637, 32.602921],
          [47.917618, 32.602693],
          [47.918873, 32.602322],
          [47.920723, 32.60154],
          [47.923065, 32.600667],
          [47.924862, 32.600254],
          [47.926609, 32.599884],
          [47.928951, 32.599468],
          [47.930481, 32.599377],
          [47.931572, 32.599422],
          [47.932388, 32.599606],
          [47.932991, 32.599835],
          [47.933594, 32.600341],
          [47.934143, 32.600985],
          [47.934475, 32.601859],
          [47.934971, 32.603054],
          [47.935265, 32.604463],
          [47.935307, 32.604663],
          [47.935967, 32.605858],
          [47.936627, 32.607055],
          [47.937557, 32.608205],
          [47.938045, 32.608757],
          [47.938071, 32.608786],
          [47.938328, 32.609077],
          [47.939312, 32.609951],
          [47.940353, 32.610362],
          [47.941338, 32.610826],
          [47.942429, 32.610915],
          [47.943356, 32.610915],
          [47.9445, 32.610734],
          [47.94574, 32.610451],
          [47.945919, 32.61041],
          [47.947552, 32.609996],
          [47.948589, 32.609765],
          [47.949352, 32.60958],
          [47.950115, 32.609718],
          [47.950791, 32.609891],
          [47.951374, 32.61004],
          [47.954544, 32.611282],
          [47.95602, 32.611694],
          [47.95755, 32.61234],
          [47.958862, 32.612705],
          [47.960064, 32.612935],
          [47.961483, 32.612933],
          [47.963829, 32.612706],
          [47.965248, 32.612472],
          [47.967484, 32.612517],
          [47.970215, 32.612655],
          [47.972561, 32.612838],
          [47.974579, 32.612655],
          [47.975616, 32.612427],
          [47.977085, 32.611827],
          [47.978554, 32.610722],
          [47.979372, 32.610155],
          [47.979748, 32.609894],
          [47.980728, 32.609345],
          [47.981819, 32.609065],
          [47.982964, 32.608883],
          [47.984547, 32.60902],
          [47.984786, 32.609095],
          [47.985859, 32.609432],
          [47.987007, 32.61003],
          [47.987667, 32.610722],
          [47.988327, 32.611412],
          [47.988712, 32.612149],
          [47.989208, 32.612835],
          [47.989319, 32.61348],
          [47.989216, 32.614352],
          [47.988999, 32.614906],
          [47.988296, 32.615734],
          [47.9865, 32.616841],
          [47.985142, 32.617622],
          [47.983563, 32.618312],
          [47.982143, 32.618816],
          [47.980949, 32.619599],
          [47.979481, 32.620749],
          [47.978508, 32.622085],
          [47.977581, 32.622818],
          [47.976223, 32.623509],
          [47.974804, 32.623878],
          [47.973332, 32.62434],
          [47.971317, 32.625214],
          [47.969303, 32.626224],
          [47.963966, 32.62885],
          [47.962605, 32.629398],
          [47.961136, 32.630318],
          [47.959667, 32.63115],
          [47.958363, 32.632205],
          [47.956951, 32.633446],
          [47.955482, 32.634551],
          [47.954666, 32.635334],
          [47.954559, 32.635978],
          [47.954838, 32.637218],
          [47.955387, 32.637955],
          [47.956486, 32.638599],
          [47.956907, 32.638788],
          [47.957909, 32.63924],
          [47.959278, 32.640208],
          [47.960648, 32.640988],
          [47.961193, 32.641404],
          [47.96207, 32.642277],
          [47.96246, 32.642967],
          [47.962406, 32.643796],
          [47.962193, 32.644347],
          [47.961743, 32.644795],
          [47.961594, 32.644943],
          [47.961269, 32.645449],
          [47.960945, 32.64605],
          [47.960949, 32.646923],
          [47.961063, 32.647888],
          [47.961506, 32.648944],
          [47.962112, 32.650235],
          [47.962883, 32.65129],
          [47.963982, 32.652347],
          [47.964835, 32.653012],
          [47.965462, 32.653501],
          [47.96656, 32.65442],
          [47.967274, 32.655246],
          [47.96777, 32.655891],
          [47.967991, 32.656532],
          [47.968212, 32.657178],
          [47.968651, 32.657777],
          [47.969147, 32.65828],
          [47.969391, 32.658729],
          [47.95889, 32.666839],
          [47.958614, 32.667119],
          [47.9525, 32.674894],
          [47.950557, 32.679065],
          [47.951944, 32.686009],
          [47.951667, 32.686289],
          [47.950277, 32.69018],
          [47.94111, 32.698785],
          [47.928887, 32.707119],
          [47.92861, 32.707399],
          [47.918054, 32.712672],
          [47.917221, 32.713512],
          [47.916111, 32.714063],
          [47.910278, 32.721286],
          [47.910554, 32.723508],
          [47.916111, 32.727679],
          [47.917221, 32.727679],
          [47.922221, 32.729621],
          [47.922777, 32.7299],
          [47.923887, 32.731011],
          [47.924721, 32.737124],
          [47.924444, 32.737404],
          [47.927501, 32.746289],
          [47.930554, 32.747119],
          [47.930834, 32.747399],
          [47.931667, 32.747399],
          [47.935, 32.749901],
          [47.935277, 32.755174],
          [47.935, 32.756004],
          [47.939444, 32.762397],
          [47.94, 32.762397],
          [47.94, 32.762677],
          [47.940834, 32.762677],
          [47.941944, 32.763228],
          [47.943334, 32.763788],
          [47.94861, 32.765178],
          [47.958057, 32.766009],
          [47.958614, 32.766289],
          [47.963057, 32.766289],
          [47.963613, 32.766569],
          [47.982503, 32.76684],
          [47.983056, 32.76712],
          [47.990836, 32.76712],
          [47.991113, 32.7674],
          [47.99167, 32.76712],
          [47.99528, 32.767679],
          [47.996946, 32.767679],
          [47.998893, 32.76851],
          [48.006393, 32.774343],
          [48.006669, 32.774623],
          [48.010002, 32.779345],
          [48.012503, 32.790461],
          [48.011669, 32.793792],
          [48.011393, 32.794072],
          [48.009446, 32.800736],
          [48.003059, 32.811011],
          [48.002779, 32.811291],
          [48.003336, 32.811851],
          [48.003336, 32.812953],
          [48.003336, 32.813232],
          [48.003336, 32.813512],
          [48.003336, 32.813792],
          [48.002779, 32.815183],
          [48.002503, 32.816564],
          [48.002503, 32.816844],
          [48.002503, 32.817124],
          [48.001393, 32.819345],
          [48.001113, 32.820456],
          [48.000836, 32.821287],
          [48.000559, 32.822406],
          [48.000279, 32.823237],
          [47.995279, 32.839065],
          [47.995279, 32.841286],
          [47.995279, 32.841846],
          [47.99417, 32.843517],
          [47.991389, 32.846298],
          [47.989723, 32.846298],
          [47.989723, 32.846018],
          [47.988613, 32.846018],
          [47.988336, 32.845738],
          [47.987503, 32.845738],
          [47.986389, 32.844898],
          [47.98528, 32.844898],
          [47.985003, 32.844618],
          [47.984723, 32.844618],
          [47.983336, 32.844618],
          [47.982503, 32.844898],
          [47.98139, 32.846018],
          [47.98139, 32.846568],
          [47.981113, 32.846848],
          [47.982223, 32.85018],
          [47.982779, 32.85046],
          [47.983336, 32.85185],
          [47.983336, 32.852681],
          [47.983336, 32.852961],
          [47.981113, 32.855182],
          [47.980003, 32.855182],
          [47.979723, 32.855462],
          [47.978056, 32.855462],
          [47.977223, 32.855733],
          [47.976946, 32.856013],
          [47.974723, 32.856013],
          [47.973333, 32.856564],
          [47.965833, 32.864907],
          [47.96528, 32.866008],
          [47.965, 32.867408],
          [47.964723, 32.868239],
          [47.964446, 32.87074],
          [47.964166, 32.872681],
          [47.96389, 32.873792],
          [47.963056, 32.874902],
          [47.959166, 32.878234],
          [47.958056, 32.877963],
          [47.954723, 32.878234],
          [47.94389, 32.883236],
          [47.936667, 32.889349],
          [47.93611, 32.88962],
          [47.934443, 32.892401],
          [47.93472, 32.894071],
          [47.935, 32.894902],
          [47.935553, 32.898793],
          [47.934443, 32.899904],
          [47.931386, 32.901565],
          [47.926387, 32.900184],
          [47.92361, 32.901565],
          [47.923053, 32.901565],
          [47.919444, 32.905457],
          [47.91472, 32.907958],
          [47.912497, 32.907958],
          [47.910554, 32.907407],
          [47.910277, 32.907127],
          [47.90611, 32.907127],
          [47.904997, 32.907678],
          [47.904444, 32.908789],
          [47.90472, 32.91157],
          [47.902777, 32.913791],
          [47.901664, 32.913791],
          [47.89972, 32.91213],
          [47.896664, 32.91129],
          [47.894164, 32.91129],
          [47.893607, 32.91157],
          [47.892497, 32.91157],
          [47.88944, 32.914071],
          [47.889164, 32.914902],
          [47.888887, 32.915732],
          [47.887774, 32.918793],
          [47.887497, 32.919073],
          [47.886107, 32.920184],
          [47.87944, 32.922956],
          [47.877497, 32.926847],
          [47.877497, 32.932409],
          [47.874164, 32.936572],
          [47.871941, 32.938233],
          [47.870274, 32.938793],
          [47.869441, 32.938793],
          [47.869164, 32.939073],
          [47.867774, 32.939073],
          [47.863331, 32.934901],
          [47.862774, 32.934901],
          [47.861384, 32.93463],
          [47.860551, 32.93463],
          [47.860274, 32.93435],
          [47.857774, 32.93435],
          [47.857217, 32.93407],
          [47.856384, 32.93435],
          [47.856108, 32.93407],
          [47.855551, 32.93435],
          [47.853327, 32.93435],
          [47.849161, 32.936292],
          [47.848608, 32.936851],
          [47.847774, 32.937122],
          [47.837494, 32.948237],
          [47.833328, 32.951018],
          [47.832218, 32.951849],
          [47.829994, 32.953239],
          [47.825828, 32.958241],
          [47.825828, 32.959072],
          [47.821104, 32.965456],
          [47.811105, 32.971018],
          [47.809715, 32.971848],
          [47.799992, 32.974069],
          [47.798048, 32.974629],
          [47.796938, 32.974629],
          [47.791105, 32.97657],
          [47.790548, 32.97657],
          [47.790272, 32.97685],
          [47.788881, 32.97741],
          [47.784992, 32.978512],
          [47.784715, 32.978792],
          [47.780825, 32.978792],
          [47.780269, 32.979071],
          [47.778048, 32.979071],
          [47.776382, 32.979631],
          [47.776004, 32.979902],
          [47.772215, 32.983243],
          [47.771382, 32.984633],
          [47.761659, 32.993798],
          [47.753325, 32.99824],
          [47.751935, 32.99824],
          [47.750545, 32.99824],
          [47.749992, 32.99796],
          [47.749159, 32.99796],
          [47.744436, 32.995188],
          [47.744156, 32.994908],
          [47.742212, 32.994909],
          [47.730822, 32.999351],
          [47.730269, 33.000741],
          [47.728599, 33.002682],
          [47.728046, 33.002682],
          [47.727489, 33.003242],
          [47.726932, 33.003242],
          [47.726656, 33.003522],
          [47.723599, 33.003522],
          [47.723046, 33.003242],
          [47.721656, 33.003242],
          [47.721099, 33.002962],
          [47.718046, 33.002962],
          [47.717489, 33.003242],
          [47.712213, 33.004904],
          [47.711656, 33.004903],
          [47.711379, 33.005183],
          [47.708323, 33.005183],
          [47.708043, 33.004903],
          [47.706376, 33.004903],
          [47.691656, 33.002132],
          [47.690543, 33.002402],
          [47.687209, 33.005183],
          [47.679986, 33.008795],
          [47.67971, 33.009075],
          [47.6786, 33.009346],
          [47.678319, 33.009626],
          [47.67721, 33.009626],
          [47.676933, 33.009906],
          [47.6761, 33.009906],
          [47.67582, 33.010185],
          [47.674986, 33.010186],
          [47.661653, 33.01768],
          [47.660543, 33.01907],
          [47.65721, 33.020181],
          [47.653873, 33.023512],
          [47.65054, 33.025733],
          [47.650263, 33.026013],
          [47.648873, 33.026013],
          [47.648597, 33.026293],
          [47.646654, 33.026293],
          [47.64582, 33.026853],
          [47.641653, 33.032686],
          [47.64082, 33.033237],
          [47.64054, 33.033796],
          [47.637207, 33.034347],
          [47.636653, 33.034627],
          [47.63415, 33.034627],
          [47.633597, 33.034907],
          [47.632207, 33.034907],
          [47.630817, 33.034347],
          [47.628873, 33.032686],
          [47.62804, 33.032406],
          [47.62554, 33.031846],
          [47.624707, 33.031846],
          [47.62443, 33.032126],
          [47.623874, 33.032126],
          [47.619707, 33.036288],
          [47.612484, 33.038798],
          [47.611374, 33.038798],
          [47.601927, 33.046572],
          [47.60026, 33.047123],
          [47.599984, 33.047403],
          [47.597761, 33.047403],
          [47.593317, 33.045182],
          [47.592204, 33.045182],
          [47.587484, 33.049073],
          [47.587204, 33.049353],
          [47.579984, 33.060179],
          [47.579704, 33.060739],
          [47.578871, 33.061019],
          [47.578594, 33.061569],
          [47.575538, 33.06268],
          [47.566928, 33.071574],
          [47.564428, 33.074075],
          [47.562204, 33.076296],
          [47.556091, 33.079077],
          [47.552205, 33.08602],
          [47.549981, 33.08713],
          [47.549148, 33.08713],
          [47.546924, 33.08546],
          [47.54584, 33.081849],
          [47.544065, 33.081597],
          [47.543244, 33.081301],
          [47.541999, 33.080894],
          [47.540334, 33.081424],
          [47.539566, 33.08283],
          [47.537645, 33.084457],
          [47.532833, 33.083583],
          [47.529148, 33.08574],
          [47.530545, 33.088101],
          [47.527758, 33.090462],
          [47.525613, 33.092263],
          [47.525539, 33.093477],
          [47.525988, 33.095846],
          [47.525456, 33.099039],
          [47.52264, 33.101352],
          [47.523617, 33.104269],
          [47.523306, 33.10537],
          [47.522231, 33.106232],
          [47.519127, 33.10699],
          [47.514636, 33.104198],
          [47.51333, 33.102243],
          [47.511645, 33.101577],
          [47.510721, 33.101761],
          [47.51018, 33.101859],
          [47.509231, 33.101995],
          [47.508651, 33.102119],
          [47.508335, 33.102384],
          [47.508061, 33.102614],
          [47.50575, 33.103622],
          [47.501339, 33.104915],
          [47.499645, 33.10733],
          [47.495528, 33.112895],
          [47.492226, 33.11344],
          [47.489662, 33.114959],
          [47.491489, 33.117093],
          [47.491922, 33.118244],
          [47.486645, 33.120745],
          [47.484422, 33.123246],
          [47.482744, 33.123991],
          [47.481473, 33.12555],
          [47.481853, 33.127848],
          [47.484775, 33.12927],
          [47.487262, 33.129922],
          [47.488648, 33.129211],
          [47.489419, 33.129444],
          [47.489859, 33.12972],
          [47.490123, 33.131407],
          [47.488053, 33.132712],
          [47.479422, 33.134902],
          [47.476922, 33.137692],
          [47.476645, 33.138514],
          [47.476365, 33.140184],
          [47.475812, 33.142414],
          [47.475812, 33.142965],
          [47.474145, 33.144626],
          [47.474145, 33.144906],
          [47.473312, 33.144906],
          [47.473032, 33.145186],
          [47.472755, 33.145457],
          [47.471922, 33.145457],
          [47.471922, 33.145737],
          [47.471089, 33.145737],
          [47.471089, 33.146026],
          [47.470532, 33.146026],
          [47.469979, 33.146297],
          [47.46714, 33.147845],
          [47.460809, 33.151299],
          [47.456365, 33.151299],
          [47.456089, 33.151579],
          [47.453865, 33.151579],
          [47.450532, 33.152129],
          [47.449975, 33.152409],
          [47.449422, 33.152409],
          [47.449142, 33.152689],
          [47.448589, 33.152689],
          [47.448589, 33.152969],
          [47.447475, 33.152969],
          [47.445809, 33.15352],
          [47.444419, 33.15352],
          [47.444142, 33.1538],
          [47.441366, 33.153799],
          [47.440809, 33.15352],
          [47.440252, 33.15352],
          [47.439142, 33.15324],
          [47.437476, 33.15324],
          [47.435809, 33.15519],
          [47.435809, 33.159072],
          [47.437199, 33.169907],
          [47.436919, 33.170467],
          [47.433309, 33.172408],
          [47.432752, 33.172408],
          [47.431366, 33.174069],
          [47.430809, 33.174349],
          [47.430809, 33.174909],
          [47.430252, 33.17546],
          [47.430252, 33.17602],
          [47.429976, 33.176299],
          [47.429976, 33.176579],
          [47.429419, 33.176859],
          [47.425529, 33.176859],
          [47.425253, 33.176579],
          [47.424142, 33.176579],
          [47.423863, 33.176299],
          [47.422476, 33.176299],
          [47.422195, 33.176019],
          [47.418863, 33.176019],
          [47.415809, 33.17713],
          [47.414976, 33.17797],
          [47.415252, 33.1788],
          [47.418863, 33.180462],
          [47.419976, 33.181301],
          [47.419695, 33.181572],
          [47.419419, 33.182132],
          [47.419419, 33.182412],
          [47.415809, 33.185743],
          [47.406919, 33.188804],
          [47.406086, 33.188804],
          [47.403029, 33.190745],
          [47.399696, 33.197688],
          [47.395253, 33.19907],
          [47.39192, 33.196849],
          [47.390529, 33.196578],
          [47.389416, 33.196578],
          [47.387749, 33.197409],
          [47.387196, 33.197959],
          [47.386363, 33.198239],
          [47.380806, 33.204631],
          [47.374973, 33.209354],
          [47.371916, 33.210464],
          [47.368026, 33.210464],
          [47.36025, 33.203521],
          [47.358307, 33.20241],
          [47.357193, 33.20241],
          [47.35664, 33.202131],
          [47.35386, 33.206022],
          [47.353583, 33.206293],
          [47.353307, 33.208803],
          [47.353027, 33.210464],
          [47.35275, 33.213245],
          [47.35275, 33.214626],
          [47.352474, 33.215186],
          [47.351083, 33.217407],
          [47.350803, 33.217967],
          [47.346916, 33.219077],
          [47.344416, 33.219077],
          [47.338303, 33.216297],
          [47.336083, 33.216297],
          [47.333027, 33.217407],
          [47.33136, 33.219357],
          [47.33108, 33.221849],
          [47.328304, 33.22491],
          [47.326361, 33.22491],
          [47.325804, 33.22463],
          [47.324693, 33.22463],
          [47.319971, 33.222409],
          [47.316914, 33.221298],
          [47.311913, 33.220468],
          [47.30997, 33.222689],
          [47.310524, 33.22658],
          [47.310804, 33.227131],
          [47.310524, 33.227691],
          [47.309413, 33.229912],
          [47.308581, 33.230183],
          [47.308304, 33.230472],
          [47.30747, 33.230472],
          [47.303304, 33.227411],
          [47.302191, 33.227411],
          [47.301914, 33.227131],
          [47.301357, 33.227131],
          [47.299137, 33.227411],
          [47.29719, 33.229632],
          [47.297471, 33.232133],
          [47.29719, 33.232413],
          [47.2883, 33.237965],
          [47.286081, 33.237965],
          [47.280801, 33.233794],
          [47.277191, 33.231022],
          [47.274967, 33.231022],
          [47.273301, 33.231573],
          [47.271358, 33.232963],
          [47.270244, 33.234634],
          [47.269411, 33.236855],
          [47.266077, 33.245188],
          [47.259411, 33.24963],
          [47.258858, 33.25019],
          [47.255244, 33.251571],
          [47.255244, 33.251851],
          [47.254688, 33.251851],
          [47.254411, 33.252131],
          [47.252468, 33.252131],
          [47.250521, 33.251021],
          [47.247468, 33.246299],
          [47.247468, 33.246019],
          [47.246354, 33.245188],
          [47.246354, 33.244358],
          [47.245521, 33.244078],
          [47.242745, 33.244078],
          [47.239688, 33.244628],
          [47.232465, 33.247969],
          [47.231911, 33.248799],
          [47.230521, 33.250461],
          [47.230521, 33.2513],
          [47.230245, 33.251571],
          [47.233021, 33.259914],
          [47.232745, 33.260185],
          [47.232465, 33.262965],
          [47.228855, 33.268247],
          [47.228855, 33.268798],
          [47.228298, 33.269078],
          [47.228298, 33.269629],
          [47.225244, 33.27324],
          [47.222188, 33.27463],
          [47.219688, 33.27519],
          [47.217741, 33.27519],
          [47.217465, 33.27547],
          [47.214132, 33.27519],
          [47.213855, 33.27491],
          [47.212188, 33.27491],
          [47.211632, 33.27463],
          [47.204408, 33.27463],
          [47.201355, 33.275741],
          [47.195518, 33.282684],
          [47.200799, 33.290746],
          [47.203022, 33.293247],
          [47.203855, 33.294358],
          [47.204408, 33.29852],
          [47.204131, 33.29908],
          [47.201908, 33.302411],
          [47.201075, 33.302411],
          [47.200798, 33.302691],
          [47.199685, 33.302691],
          [47.199131, 33.302411],
          [47.197741, 33.302411],
          [47.191075, 33.29908],
          [47.189408, 33.29908],
          [47.189132, 33.2988],
          [47.188019, 33.2988],
          [47.180518, 33.301572],
          [47.180518, 33.30186],
          [47.180242, 33.30186],
          [47.179962, 33.30186],
          [47.178852, 33.303242],
          [47.177742, 33.304632],
          [47.177462, 33.304912],
          [47.178852, 33.312965],
          [47.178295, 33.314356],
          [47.176075, 33.315467],
          [47.175795, 33.315746],
          [47.174685, 33.315746],
          [47.164685, 33.321858],
          [47.162185, 33.326851],
          [47.161905, 33.327691],
          [47.161629, 33.328522],
          [47.160519, 33.330463],
          [47.153019, 33.337415],
          [47.152186, 33.337686],
          [47.151352, 33.338525],
          [47.148572, 33.339915],
          [47.143572, 33.341297],
          [47.143015, 33.341577],
          [47.141072, 33.341577],
          [47.140239, 33.341297],
          [47.139129, 33.341297],
          [47.111906, 33.331582],
          [47.111442, 33.331582],
          [47.110793, 33.331582],
          [47.11024, 33.331582],
          [47.109682, 33.331582],
          [47.109406, 33.331853],
          [47.10885, 33.331853],
          [47.108573, 33.332133],
          [47.107739, 33.332133],
          [47.107182, 33.332413],
          [47.10635, 33.332413],
          [47.102183, 33.335744],
          [47.10135, 33.337965],
          [47.101349, 33.340746],
          [47.102183, 33.346019],
          [47.101903, 33.346298],
          [47.100236, 33.349079],
          [47.100236, 33.349359],
          [47.099683, 33.349359],
          [47.099126, 33.34991],
          [47.094402, 33.3513],
          [47.093293, 33.3513],
          [47.092459, 33.35158],
          [47.085793, 33.35158],
          [47.082737, 33.35158],
          [47.081903, 33.35186],
          [47.080237, 33.35186],
          [47.079126, 33.352131],
          [47.074126, 33.357963],
          [47.070236, 33.359913],
          [47.068289, 33.360184],
          [47.068013, 33.360464],
          [47.06357, 33.360464],
          [47.063014, 33.360744],
          [47.061346, 33.360744],
          [47.057737, 33.362414],
          [47.05468, 33.369077],
          [47.052737, 33.372138],
          [47.051347, 33.372688],
          [47.05079, 33.372688],
          [47.050513, 33.372968],
          [47.047457, 33.372968],
          [47.04079, 33.375189],
          [47.032457, 33.380471],
          [47.023567, 33.384353],
          [47.02301, 33.384633],
          [47.02051, 33.384633],
          [47.020234, 33.384353],
          [47.013844, 33.384353],
          [47.013291, 33.384082],
          [47.010233, 33.384082],
          [47.00662, 33.390745],
          [47.006344, 33.391585],
          [47.006067, 33.392686],
          [47.006067, 33.393806],
          [47.005787, 33.394076],
          [47.006067, 33.397968],
          [47.009401, 33.402969],
          [47.01551, 33.40575],
          [47.0244, 33.413523],
          [47.02551, 33.416024],
          [47.02579, 33.417965],
          [47.026066, 33.420186],
          [47.02551, 33.421577],
          [47.025234, 33.421856],
          [47.023566, 33.424077],
          [47.017733, 33.426027],
          [47.011343, 33.435471],
          [47.0069, 33.436861],
          [47.002453, 33.436861],
          [47.0019, 33.437132],
          [46.997734, 33.436861],
          [46.996344, 33.437132],
          [46.995234, 33.437972],
          [46.995234, 33.438243],
          [46.994954, 33.440193],
          [46.995234, 33.442693],
          [46.994954, 33.443244],
          [46.994677, 33.445745],
          [46.9944, 33.447135],
          [46.99301, 33.447686],
          [46.99301, 33.447975],
          [46.991897, 33.447975],
          [46.986897, 33.443524],
          [46.983011, 33.442973],
          [46.981897, 33.443244],
          [46.973564, 33.448246],
          [46.971064, 33.450196],
          [46.970507, 33.450196],
          [46.962731, 33.45908],
          [46.962174, 33.46019],
          [46.960788, 33.461301],
          [46.960788, 33.46158],
          [46.960231, 33.46158],
          [46.960231, 33.46186],
          [46.959674, 33.46186],
          [46.959398, 33.46214],
          [46.957454, 33.46214],
          [46.955788, 33.462411],
          [46.955508, 33.462691],
          [46.954398, 33.462691],
          [46.954117, 33.462971],
          [46.952451, 33.462971],
          [46.946618, 33.464361],
          [46.929951, 33.475195],
          [46.928008, 33.476856],
          [46.920784, 33.479357],
          [46.920228, 33.479636],
          [46.919395, 33.479637],
          [46.914118, 33.483248],
          [46.909118, 33.492691],
          [46.906061, 33.495752],
          [46.904951, 33.496023],
          [46.904672, 33.496302],
          [46.903285, 33.496302],
          [46.902728, 33.496582],
          [46.900785, 33.496582],
          [46.896338, 33.497973],
          [46.894671, 33.498803],
          [46.891615, 33.501864],
          [46.890781, 33.503525],
          [46.889948, 33.505755],
          [46.886895, 33.509086],
          [46.878558, 33.511857],
          [46.878005, 33.511857],
          [46.874672, 33.515198],
          [46.866615, 33.51908],
          [46.865782, 33.51908],
          [46.863839, 33.51992],
          [46.861615, 33.522131],
          [46.861892, 33.524912],
          [46.862449, 33.526302],
          [46.863005, 33.529354],
          [46.862725, 33.529643],
          [46.861615, 33.532414],
          [46.844244, 33.550988],
          [46.841612, 33.553801],
          [46.841058, 33.555471],
          [46.840502, 33.556302],
          [46.840226, 33.557422],
          [46.839668, 33.559922],
          [46.839392, 33.561583],
          [46.839392, 33.565195],
          [46.839392, 33.567135],
          [46.840225, 33.571866],
          [46.842725, 33.574638],
          [46.851335, 33.576579],
          [46.859115, 33.575197],
          [46.864392, 33.577698],
          [46.864392, 33.578529],
          [46.864115, 33.578809],
          [46.864392, 33.579639],
          [46.862172, 33.58269],
          [46.861615, 33.58353],
          [46.858002, 33.588531],
          [46.858002, 33.592973],
          [46.845782, 33.610189],
          [46.845782, 33.610478],
          [46.849392, 33.610478],
          [46.852725, 33.610749],
          [46.853001, 33.611029],
          [46.853558, 33.611029],
          [46.854948, 33.611029],
          [46.872724, 33.618251],
          [46.884671, 33.620192],
          [46.886615, 33.619921],
          [46.898281, 33.61631],
          [46.898561, 33.61603],
          [46.899951, 33.61603],
          [46.900505, 33.61575],
          [46.904395, 33.61575],
          [46.909117, 33.616581],
          [46.909394, 33.616861],
          [46.910227, 33.616861],
          [46.91606, 33.619361],
          [46.916617, 33.619361],
          [46.919394, 33.620192],
          [46.927174, 33.620192],
          [46.927727, 33.620472],
          [46.933561, 33.620472],
          [46.934117, 33.620752],
          [46.936061, 33.620752],
          [46.936617, 33.621031],
          [46.938564, 33.621031],
          [46.939117, 33.620752],
          [46.941064, 33.620752],
          [46.941617, 33.620472],
          [46.942451, 33.620472],
          [46.961064, 33.609088],
          [46.961064, 33.608808],
          [46.96162, 33.608808],
          [46.961897, 33.608528],
          [46.963007, 33.608528],
          [46.963564, 33.608248],
          [46.965787, 33.607698],
          [46.96662, 33.607418],
          [46.967173, 33.607418],
          [46.967454, 33.607138],
          [46.968287, 33.607138],
          [46.97412, 33.604366],
          [46.974953, 33.604087],
          [46.978287, 33.600755],
          [46.978563, 33.599356],
          [46.978844, 33.597695],
          [46.98023, 33.592974],
          [46.985787, 33.588803],
          [46.992177, 33.586302],
          [46.993286, 33.586302],
          [46.993567, 33.586031],
          [46.99662, 33.586031],
          [46.9969, 33.586582],
          [46.997176, 33.586862],
          [46.998484, 33.589716],
          [46.998567, 33.590193],
          [47.001502, 33.593098],
          [47.004964, 33.593542],
          [47.009187, 33.594083],
          [47.013566, 33.594644],
          [47.014123, 33.594364],
          [47.01579, 33.594364],
          [47.023843, 33.591863],
          [47.024956, 33.591303],
          [47.033847, 33.584641],
          [47.0344, 33.584361],
          [47.037456, 33.584361],
          [47.038013, 33.584641],
          [47.038566, 33.588252],
          [47.033566, 33.592694],
          [47.032732, 33.594364],
          [47.032732, 33.594644],
          [47.0319, 33.596305],
          [47.028842, 33.602137],
          [47.027176, 33.606308],
          [47.028842, 33.6152],
          [47.023566, 33.620752],
          [47.022456, 33.622142],
          [47.022456, 33.622422],
          [47.021899, 33.622973],
          [47.022456, 33.628254],
          [47.031346, 33.64547],
          [47.031346, 33.645749],
          [47.040513, 33.652972],
          [47.041346, 33.656032],
          [47.042179, 33.657702],
          [47.046622, 33.660753],
          [47.047456, 33.661304],
          [47.056346, 33.662694],
          [47.060236, 33.664644],
          [47.060512, 33.666585],
          [47.060236, 33.666865],
          [47.059679, 33.668806],
          [47.058012, 33.669366],
          [47.056346, 33.670476],
          [47.055236, 33.671586],
          [47.062179, 33.680478],
          [47.067455, 33.682419],
          [47.068569, 33.682419],
          [47.071069, 33.68409],
          [47.071625, 33.68659],
          [47.071902, 33.687701],
          [47.071625, 33.688251],
          [47.062455, 33.696583],
          [47.062455, 33.697974],
          [47.062179, 33.698254],
          [47.062455, 33.699644],
          [47.064955, 33.702695],
          [47.066069, 33.704645],
          [47.066345, 33.708807],
          [47.068011, 33.709917],
          [47.070512, 33.709646],
          [47.072179, 33.708807],
          [47.082735, 33.698533],
          [47.084678, 33.697423],
          [47.086625, 33.696584],
          [47.089402, 33.696584],
          [47.097458, 33.703255],
          [47.103568, 33.705476],
          [47.108848, 33.706026],
          [47.109681, 33.706306],
          [47.111348, 33.706306],
          [47.111625, 33.706026],
          [47.112181, 33.706026],
          [47.113571, 33.703535],
          [47.114405, 33.702695],
          [47.115238, 33.702695],
          [47.116348, 33.702145],
          [47.117738, 33.702145],
          [47.118015, 33.701865],
          [47.118571, 33.701865],
          [47.119124, 33.702424],
          [47.121624, 33.706306],
          [47.131904, 33.703255],
          [47.134681, 33.703255],
          [47.138571, 33.704085],
          [47.140237, 33.704085],
          [47.141071, 33.704365],
          [47.142461, 33.704365],
          [47.143294, 33.704645],
          [47.148018, 33.704645],
          [47.151904, 33.703535],
          [47.151904, 33.703255],
          [47.152737, 33.703255],
          [47.153294, 33.702975],
          [47.155794, 33.702975],
          [47.157461, 33.703535],
          [47.160517, 33.707417],
          [47.161627, 33.709087],
          [47.16246, 33.709917],
          [47.16885, 33.712418],
          [47.17135, 33.713528],
          [47.17885, 33.7227],
          [47.18274, 33.7227],
          [47.183297, 33.72298],
          [47.184964, 33.72298],
          [47.185517, 33.7227],
          [47.186351, 33.7227],
          [47.18663, 33.72242],
          [47.188297, 33.72242],
          [47.18885, 33.7227],
          [47.189964, 33.7227],
          [47.194407, 33.724921],
          [47.196907, 33.725751],
          [47.199963, 33.725472],
          [47.200516, 33.725201],
          [47.200516, 33.72159],
          [47.201353, 33.719369],
          [47.203574, 33.717699],
          [47.204406, 33.717699],
          [47.204687, 33.717419],
          [47.20802, 33.717419],
          [47.208853, 33.717699],
          [47.209964, 33.717699],
          [47.21163, 33.718259],
          [47.21274, 33.718259],
          [47.227743, 33.726031],
          [47.230796, 33.727701],
          [47.23413, 33.728532],
          [47.235796, 33.728532],
          [47.236077, 33.728252],
          [47.237186, 33.727421],
          [47.239409, 33.725751],
          [47.239966, 33.725751],
          [47.240243, 33.725472],
          [47.241076, 33.725472],
          [47.241353, 33.725751],
          [47.241909, 33.725751],
          [47.252742, 33.744086],
          [47.256909, 33.745476],
          [47.258022, 33.746027],
          [47.258576, 33.746027],
          [47.262466, 33.742976],
          [47.263856, 33.742145],
          [47.271076, 33.737695],
          [47.271909, 33.736864],
          [47.272742, 33.736584],
          [47.278023, 33.730193],
          [47.279966, 33.729372],
          [47.287466, 33.727701],
          [47.295802, 33.725201],
          [47.296636, 33.724921],
          [47.297746, 33.724921],
          [47.298022, 33.724641],
          [47.299412, 33.724641],
          [47.299689, 33.724361],
          [47.301079, 33.724361],
          [47.301355, 33.724091],
          [47.303855, 33.723811],
          [47.308302, 33.724361],
          [47.308855, 33.724641],
          [47.309969, 33.724641],
          [47.311079, 33.725201],
          [47.311912, 33.725201],
          [47.319692, 33.729083],
          [47.324412, 33.731033],
          [47.326912, 33.731033],
          [47.331911, 33.728532],
          [47.333302, 33.728532],
          [47.346358, 33.733253],
          [47.346834, 33.733453],
          [47.347468, 33.733533],
          [47.348859, 33.733813],
          [47.354138, 33.730482],
          [47.359971, 33.729372],
          [47.376081, 33.734364],
          [47.376638, 33.734644],
          [47.381915, 33.734644],
          [47.387471, 33.734093],
          [47.388581, 33.733813],
          [47.395804, 33.733813],
          [47.401638, 33.734364],
          [47.401917, 33.734644],
          [47.404694, 33.734644],
          [47.407194, 33.735204],
          [47.408028, 33.735204],
          [47.411361, 33.737144],
          [47.413027, 33.742145],
          [47.413584, 33.743806],
          [47.423584, 33.755199],
          [47.424417, 33.75964],
          [47.42414, 33.75992],
          [47.423861, 33.76159],
          [47.423584, 33.762701],
          [47.425251, 33.765201],
          [47.426361, 33.766312],
          [47.426361, 33.766591],
          [47.427751, 33.766591],
          [47.434697, 33.764091],
          [47.43553, 33.764091],
          [47.436084, 33.763811],
          [47.437197, 33.763811],
          [47.437473, 33.764091],
          [47.438307, 33.764091],
          [47.44553, 33.769092],
          [47.446363, 33.770202],
          [47.447197, 33.772703],
          [47.444417, 33.776314],
          [47.444417, 33.776585],
          [47.443583, 33.777144],
          [47.441363, 33.780755],
          [47.441084, 33.781035],
          [47.443307, 33.785196],
          [47.444417, 33.784926],
          [47.444697, 33.784646],
          [47.44553, 33.784646],
          [47.44803, 33.783535],
          [47.448307, 33.783256],
          [47.44942, 33.783256],
          [47.449697, 33.782976],
          [47.451363, 33.782976],
          [47.45164, 33.783256],
          [47.452473, 33.783256],
          [47.45442, 33.784646],
          [47.454697, 33.788257],
          [47.45442, 33.791037],
          [47.45442, 33.792147],
          [47.453587, 33.794928],
          [47.45414, 33.798809],
          [47.454973, 33.80159],
          [47.457473, 33.8052],
          [47.46164, 33.807141],
          [47.464973, 33.808531],
          [47.472476, 33.814643],
          [47.47303, 33.815762],
          [47.474143, 33.816872],
          [47.477476, 33.820474],
          [47.487199, 33.826035],
          [47.488033, 33.826035],
          [47.488586, 33.826315],
          [47.490533, 33.826315],
          [47.493309, 33.825475],
          [47.494419, 33.825475],
          [47.499699, 33.822975],
          [47.502476, 33.822424],
          [47.504423, 33.822424],
          [47.504976, 33.822144],
          [47.506366, 33.822144],
          [47.511089, 33.824925],
          [47.511366, 33.825475],
          [47.513309, 33.827425],
          [47.513866, 33.828256],
          [47.514699, 33.832146],
          [47.509976, 33.837427],
          [47.508032, 33.842419],
          [47.507756, 33.85326],
          [47.506642, 33.855481],
          [47.506365, 33.856311],
          [47.506089, 33.857142],
          [47.505809, 33.857431],
          [47.505809, 33.857701],
          [47.505809, 33.857981],
          [47.503813, 33.86452],
          [47.502756, 33.867983],
          [47.503032, 33.872704],
          [47.503309, 33.872984],
          [47.503032, 33.873534],
          [47.503309, 33.875204],
          [47.503589, 33.876585],
          [47.503309, 33.877145],
          [47.503589, 33.879366],
          [47.506089, 33.887986],
          [47.500252, 33.894368],
          [47.498866, 33.896038],
          [47.498866, 33.896869],
          [47.498586, 33.897148],
          [47.499419, 33.9002],
          [47.499976, 33.900759],
          [47.506089, 33.90465],
          [47.506922, 33.90715],
          [47.506642, 33.90771],
          [47.506365, 33.90854],
          [47.502755, 33.91271],
          [47.491919, 33.919372],
          [47.490809, 33.920202],
          [47.489699, 33.920482],
          [47.483862, 33.924932],
          [47.482752, 33.926873],
          [47.482476, 33.927704],
          [47.482196, 33.928543],
          [47.482196, 33.930484],
          [47.482196, 33.931314],
          [47.485532, 33.934645],
          [47.486086, 33.936315],
          [47.485809, 33.936595],
          [47.486086, 33.937425],
          [47.485532, 33.939095],
          [47.481086, 33.941875],
          [47.480529, 33.941875],
          [47.480253, 33.942146],
          [47.479976, 33.942146],
          [47.477196, 33.942146],
          [47.476919, 33.942426],
          [47.465809, 33.943536],
          [47.465253, 33.943816],
          [47.463029, 33.943816],
          [47.462473, 33.944096],
          [47.460529, 33.944096],
          [47.455806, 33.945206],
          [47.454973, 33.945206],
          [47.454419, 33.945486],
          [47.452473, 33.946036],
          [47.438306, 33.950477],
          [47.437196, 33.951037],
          [47.430806, 33.951868],
          [47.429973, 33.952147],
          [47.427473, 33.952147],
          [47.426916, 33.952427],
          [47.424416, 33.952427],
          [47.423583, 33.952147],
          [47.422193, 33.952147],
          [47.42136, 33.952427],
          [47.418583, 33.951317],
          [47.417193, 33.951037],
          [47.40497, 33.937145],
          [47.40386, 33.935764],
          [47.395803, 33.930763],
          [47.39497, 33.930763],
          [47.394694, 33.930484],
          [47.39136, 33.930484],
          [47.378861, 33.939375],
          [47.375804, 33.941036],
          [47.370527, 33.943816],
          [47.36969, 33.944646],
          [47.342468, 33.957148],
          [47.338857, 33.958818],
          [47.329967, 33.961318],
          [47.329135, 33.961318],
          [47.327467, 33.962149],
          [47.326911, 33.962148],
          [47.326635, 33.962428],
          [47.325524, 33.962428],
          [47.324967, 33.962708],
          [47.322191, 33.962149],
          [47.321911, 33.961869],
          [47.320524, 33.961869],
          [47.319134, 33.961038],
          [47.318577, 33.960758],
          [47.318024, 33.960488],
          [47.314968, 33.957148],
          [47.314411, 33.956038],
          [47.313301, 33.952707],
          [47.319691, 33.939095],
          [47.350524, 33.90965],
          [47.353581, 33.90715],
          [47.359138, 33.902709],
          [47.359691, 33.902709],
          [47.37747, 33.884926],
          [47.379414, 33.881875],
          [47.381914, 33.877705],
          [47.382194, 33.876585],
          [47.383027, 33.875204],
          [47.383027, 33.874094],
          [47.383304, 33.873534],
          [47.383581, 33.870474],
          [47.386637, 33.861312],
          [47.383028, 33.853811],
          [47.380804, 33.8527],
          [47.378861, 33.85131],
          [47.372471, 33.85131],
          [47.371361, 33.85103],
          [47.360524, 33.842698],
          [47.360247, 33.842148],
          [47.358857, 33.839927],
          [47.344135, 33.816872],
          [47.343581, 33.816583],
          [47.343301, 33.816033],
          [47.341358, 33.814922],
          [47.337192, 33.813812],
          [47.335524, 33.813812],
          [47.318858, 33.821034],
          [47.315801, 33.819643],
          [47.311635, 33.816312],
          [47.309135, 33.812142],
          [47.308855, 33.801869],
          [47.307745, 33.796308],
          [47.304412, 33.791867],
          [47.295245, 33.788536],
          [47.293578, 33.788536],
          [47.293299, 33.788256],
          [47.288299, 33.788256],
          [47.287466, 33.788536],
          [47.285245, 33.788536],
          [47.284132, 33.788816],
          [47.280799, 33.789646],
          [47.251633, 33.802979],
          [47.249966, 33.80325],
          [47.249133, 33.80381],
          [47.246076, 33.80381],
          [47.245243, 33.80409],
          [47.244409, 33.80409],
          [47.243852, 33.804369],
          [47.239133, 33.804369],
          [47.238019, 33.80409],
          [47.236076, 33.80409],
          [47.234686, 33.80381],
          [47.234129, 33.80381],
          [47.233576, 33.80381],
          [47.231076, 33.803539],
          [47.230796, 33.80325],
          [47.219963, 33.802979],
          [47.213853, 33.802428],
          [47.21163, 33.802699],
          [47.206906, 33.80381],
          [47.194963, 33.809921],
          [47.192183, 33.812142],
          [47.190796, 33.813261],
          [47.19024, 33.813261],
          [47.189683, 33.813812],
          [47.183296, 33.815761],
          [47.17774, 33.816583],
          [47.177184, 33.816872],
          [47.16774, 33.816872],
          [47.160237, 33.816312],
          [47.154684, 33.815481],
          [47.153293, 33.815482],
          [47.152184, 33.815202],
          [47.14996, 33.815202],
          [47.147184, 33.814642],
          [47.140237, 33.814642],
          [47.139681, 33.814371],
          [47.13746, 33.814642],
          [47.129961, 33.819923],
          [47.12968, 33.820203],
          [47.126904, 33.823254],
          [47.126904, 33.823813],
          [47.112738, 33.838536],
          [47.109404, 33.840206],
          [47.106348, 33.840477],
          [47.102458, 33.836866],
          [47.099681, 33.830484],
          [47.099401, 33.829925],
          [47.096068, 33.825474],
          [47.094681, 33.824924],
          [47.093848, 33.824924],
          [47.092734, 33.824364],
          [47.090515, 33.824364],
          [47.052178, 33.833255],
          [47.047179, 33.831865],
          [47.046622, 33.831865],
          [47.044398, 33.830204],
          [47.039955, 33.823813],
          [47.030789, 33.814371],
          [47.020789, 33.811311],
          [47.019398, 33.811311],
          [47.018566, 33.811031],
          [47.009952, 33.811031],
          [47.004952, 33.80992],
          [47.004399, 33.8102],
          [47.002452, 33.8102],
          [46.999607, 33.810751],
          [46.998286, 33.811311],
          [46.997732, 33.811311],
          [46.997175, 33.81159],
          [46.996899, 33.805479],
          [46.997732, 33.800749],
          [46.997732, 33.795477],
          [46.998009, 33.795197],
          [46.999546, 33.786585],
          [46.999119, 33.783534],
          [46.998843, 33.781864],
          [46.993566, 33.772972],
          [46.988286, 33.76242],
          [46.987453, 33.758529],
          [46.979396, 33.745475],
          [46.978563, 33.744365],
          [46.977453, 33.743535],
          [46.97523, 33.742144],
          [46.974676, 33.741865],
          [46.97412, 33.742144],
          [46.968563, 33.742144],
          [46.967173, 33.742415],
          [46.966896, 33.742695],
          [46.965787, 33.742695],
          [46.962453, 33.744365],
          [46.961896, 33.744365],
          [46.948563, 33.757148],
          [46.94773, 33.757698],
          [46.947173, 33.758529],
          [46.946617, 33.758809],
          [46.944949, 33.759919],
          [46.944673, 33.760479],
          [46.938006, 33.76353],
          [46.936897, 33.76353],
          [46.93634, 33.76381],
          [46.935506, 33.76381],
          [46.924394, 33.768531],
          [46.920227, 33.768531],
          [46.919673, 33.768251],
          [46.918007, 33.768251],
          [46.91717, 33.76798],
          [46.91495, 33.7652],
          [46.91467, 33.76492],
          [46.91467, 33.764369],
          [46.913837, 33.76325],
          [46.914117, 33.762699],
          [46.914117, 33.761309],
          [46.913283, 33.757698],
          [46.900504, 33.744085],
          [46.900228, 33.743534],
          [46.896894, 33.742415],
          [46.894947, 33.741864],
          [46.88967, 33.740483],
          [46.888876, 33.740078],
          [46.873837, 33.733812],
          [46.872724, 33.733812],
          [46.871891, 33.733532],
          [46.869671, 33.733532],
          [46.868838, 33.733252],
          [46.863004, 33.733252],
          [46.862725, 33.733532],
          [46.859667, 33.733532],
          [46.859114, 33.733812],
          [46.858281, 33.733812],
          [46.842725, 33.741305],
          [46.838835, 33.743254],
          [46.833835, 33.746026],
          [46.830778, 33.748255],
          [46.829945, 33.748535],
          [46.829392, 33.749086],
          [46.779942, 33.76714],
          [46.779109, 33.76714],
          [46.777166, 33.7677],
          [46.770499, 33.769361],
          [46.768832, 33.770201],
          [46.766608, 33.770471],
          [46.766332, 33.770751],
          [46.765218, 33.770751],
          [46.764665, 33.771031],
          [46.763552, 33.771031],
          [46.762718, 33.771311],
          [46.760499, 33.771311],
          [46.759942, 33.771031],
          [46.758832, 33.771031],
          [46.755499, 33.7677],
          [46.752443, 33.757418],
          [46.751332, 33.754367],
          [46.750499, 33.752976],
          [46.739386, 33.742694],
          [46.736329, 33.742144],
          [46.736052, 33.741864],
          [46.734109, 33.741864],
          [46.733552, 33.741584],
          [46.732443, 33.741584],
          [46.731886, 33.741864],
          [46.73133, 33.741864],
          [46.729943, 33.741864],
          [46.729662, 33.742144],
          [46.72883, 33.742144],
          [46.727967, 33.742144],
          [46.726886, 33.742144],
          [46.726886, 33.741864],
          [46.725219, 33.741864],
          [46.725219, 33.742144],
          [46.724106, 33.742144],
          [46.714386, 33.748535],
          [46.713553, 33.748806],
          [46.712996, 33.749366],
          [46.705217, 33.752146],
          [46.702717, 33.752976],
          [46.695216, 33.757147],
          [46.693273, 33.759368],
          [46.671327, 33.789365],
          [46.67077, 33.790475],
          [46.667993, 33.793256],
          [46.66577, 33.794926],
          [46.66077, 33.798257],
          [46.641121, 33.814491],
          [46.635213, 33.819371],
          [46.621604, 33.830203],
          [46.621324, 33.830754],
          [46.621047, 33.831034],
          [46.62077, 33.831313],
          [46.61688, 33.833254],
          [46.61688, 33.833534],
          [46.616324, 33.833534],
          [46.616047, 33.833814],
          [46.61549, 33.834085],
          [46.590767, 33.844926],
          [46.589934, 33.845197],
          [46.589934, 33.845477],
          [46.583544, 33.848816],
          [46.582434, 33.849087],
          [46.581601, 33.849647],
          [46.560211, 33.86103],
          [46.559934, 33.86131],
          [46.559378, 33.86131],
          [46.559101, 33.861589],
          [46.558264, 33.86159],
          [46.541878, 33.869641],
          [46.541598, 33.869921],
          [46.501318, 33.897976],
          [46.499652, 33.900756],
          [46.484929, 33.905757],
          [46.472705, 33.910758],
          [46.471872, 33.910758],
          [46.471039, 33.911318],
          [46.469652, 33.911868],
          [46.468262, 33.912979],
          [46.39298, 33.951865],
          [46.392146, 33.952424],
          [46.355756, 33.970486],
          [46.34909, 33.972707],
          [46.30742, 33.991039],
          [46.306864, 33.99131],
          [46.298531, 33.99548],
          [46.298254, 33.9952],
          [46.297697, 33.99493],
          [46.295197, 33.9927],
          [46.291864, 33.982419],
          [46.289087, 33.965756],
          [46.291031, 33.960485],
          [46.291307, 33.959645],
          [46.291307, 33.958956],
          [46.291031, 33.957425],
          [46.291307, 33.957145],
          [46.290474, 33.954365],
          [46.281588, 33.950204],
          [46.279921, 33.950763],
          [46.278807, 33.950763],
          [46.278254, 33.950763],
          [46.246308, 33.958815],
          [46.245195, 33.959365],
          [46.233805, 33.961866],
          [46.232418, 33.961866],
          [46.230472, 33.962425],
          [46.229085, 33.962425],
          [46.225195, 33.963815],
          [46.215195, 33.965476],
          [46.214639, 33.965756],
          [46.213248, 33.965756],
          [46.202415, 33.969646],
          [46.200195, 33.970197],
          [46.195472, 33.972147],
          [46.194916, 33.972418],
          [46.179636, 33.980198],
          [46.168249, 33.987979],
          [46.165192, 33.988809],
          [46.164635, 33.989089],
          [46.157412, 33.989089],
          [46.156579, 33.988809],
          [46.153802, 33.988809],
          [46.149359, 33.989929],
          [46.148526, 33.989929],
          [46.141579, 33.9952],
          [46.140189, 33.99576],
          [46.140189, 33.99603],
          [46.127384, 33.999929],
          [46.111023, 34.014922],
          [46.100743, 34.020203],
          [46.099356, 34.020203],
          [46.096576, 34.021033],
          [46.094633, 34.021033],
          [46.093523, 34.021593],
          [46.086577, 34.024364],
          [46.084633, 34.026313],
          [46.084076, 34.027423],
          [46.082133, 34.029644],
          [46.0813, 34.029924],
          [46.080466, 34.030763],
          [46.077686, 34.032144],
          [46.074633, 34.034365],
          [46.070743, 34.036314],
          [46.069353, 34.036314],
          [46.069077, 34.036594],
          [46.058797, 34.037704],
          [46.05491, 34.037704],
          [46.05491, 34.037424],
          [46.03852, 34.016032],
          [46.022964, 33.99992],
          [46.013798, 33.984648],
          [46.013241, 33.984648],
          [45.983238, 33.972417],
          [45.982404, 33.972417],
          [45.981018, 33.971866],
          [45.979905, 33.971866],
          [45.979351, 33.971586],
          [45.969905, 33.969646],
          [45.968238, 33.969646],
          [45.967681, 33.969366],
          [45.964349, 33.968815],
          [45.961572, 33.968815],
          [45.960738, 33.968535],
          [45.954348, 33.968535],
          [45.952958, 33.968256],
          [45.947405, 33.968256],
          [45.946292, 33.967976],
          [45.944625, 33.967976],
          [45.943515, 33.967696],
          [45.937959, 33.967425],
          [45.937405, 33.967425],
          [45.935459, 33.967696],
          [45.934902, 33.967425],
          [45.933792, 33.967696],
          [45.932682, 33.967425],
          [45.932125, 33.967696],
          [45.927959, 33.967696],
          [45.927402, 33.967976],
          [45.926016, 33.967976],
          [45.925735, 33.968256],
          [45.924348, 33.968256],
          [45.923516, 33.968535],
          [45.921569, 33.968535],
          [45.921292, 33.968815],
          [45.919349, 33.968815],
          [45.918515, 33.969095],
          [45.912402, 33.970756],
          [45.87629, 33.988538],
          [45.875733, 33.988808],
          [45.87379, 33.989368],
          [45.867956, 33.989368],
          [45.867956, 33.989088],
          [45.8674, 33.989088],
          [45.857681, 33.979095],
          [45.857399, 33.978816],
          [45.851843, 33.969366],
          [45.849899, 33.963535],
          [45.849899, 33.962975],
          [45.846009, 33.955203],
          [45.844624, 33.949092],
          [45.85379, 33.926589],
          [45.857676, 33.922698],
          [45.85879, 33.922148],
          [45.859343, 33.921588],
          [45.888513, 33.906315],
          [45.889346, 33.905476],
          [45.928793, 33.882972],
          [45.929626, 33.882701],
          [45.951572, 33.87548],
          [45.952682, 33.87548],
          [45.954625, 33.874641],
          [45.955739, 33.874641],
          [45.972682, 33.866869],
          [45.972682, 33.864648],
          [45.972405, 33.864359],
          [45.969348, 33.858808],
          [45.963239, 33.852976],
          [45.962682, 33.852696],
          [45.962129, 33.852146],
          [45.961296, 33.851586],
          [45.958796, 33.850755],
          [45.957962, 33.849925],
          [45.942126, 33.841864],
          [45.940739, 33.841864],
          [45.939906, 33.841584],
          [45.938239, 33.841584],
          [45.937682, 33.841304],
          [45.932402, 33.841034],
          [45.923236, 33.838253],
          [45.922126, 33.838253],
          [45.921569, 33.837973],
          [45.917126, 33.837423],
          [45.913793, 33.837423],
          [45.91296, 33.837702],
          [45.909903, 33.838253],
          [45.909626, 33.838533],
          [45.89768, 33.843254],
          [45.888513, 33.848255],
          [45.887679, 33.848255],
          [45.879067, 33.849925],
          [45.87268, 33.849925],
          [45.872123, 33.849645],
          [45.87129, 33.849645],
          [45.861847, 33.844364],
          [45.859624, 33.843254],
          [45.857957, 33.842144],
          [45.85101, 33.835473],
          [45.850457, 33.832692],
          [45.850457, 33.828811],
          [45.855734, 33.814088],
          [45.8549, 33.805747],
          [45.855177, 33.805476],
          [45.8549, 33.804086],
          [45.85101, 33.794923],
          [45.847677, 33.790473],
          [45.821011, 33.761026],
          [45.819344, 33.758805],
          [45.801842, 33.732969],
          [45.789065, 33.724637],
          [45.787955, 33.724637],
          [45.787122, 33.724357],
          [45.785732, 33.723807],
          [45.785175, 33.723807],
          [45.780451, 33.722416],
          [45.779065, 33.721026],
          [45.778508, 33.719636],
          [45.778232, 33.718255],
          [45.771565, 33.711863],
          [45.769065, 33.711863],
          [45.768508, 33.711583],
          [45.767118, 33.706862],
          [45.770175, 33.706023],
          [45.770452, 33.705472],
          [45.770175, 33.704082],
          [45.762952, 33.700751],
          [45.758228, 33.69797],
          [45.757119, 33.69797],
          [45.756562, 33.69769],
          [45.755452, 33.69769],
          [45.755452, 33.69797],
          [45.754619, 33.698801],
          [45.754062, 33.69936],
          [45.751562, 33.69936],
          [45.751285, 33.69964],
          [45.749619, 33.701021],
          [45.749342, 33.70131],
          [45.749342, 33.702971],
          [45.748509, 33.703802],
          [45.747395, 33.703802],
          [45.747119, 33.704082],
          [45.746562, 33.703802],
          [45.742673, 33.702141],
          [45.739619, 33.699911],
          [45.737396, 33.69964],
          [45.736286, 33.700471],
          [45.736286, 33.70075],
          [45.736005, 33.702141],
          [45.735172, 33.703251],
          [45.732952, 33.703251],
          [45.731839, 33.702421],
          [45.728786, 33.703531],
          [45.725729, 33.703802],
          [45.720173, 33.70075],
          [45.713506, 33.696309],
          [45.710729, 33.696309],
          [45.709339, 33.696309],
          [45.708783, 33.696029],
          [45.707116, 33.696029],
          [45.706559, 33.695749],
          [45.705449, 33.695749],
          [45.704893, 33.695469],
          [45.700726, 33.694359],
          [45.699616, 33.694359],
          [45.69934, 33.694079],
          [45.698314, 33.693593],
          [45.69252, 33.69097],
        ],
      ],
    },
  },
  Esfahan: {
    properties: { value: "Esfahan", name: "استان اصفهان, ایران", id: 8 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [49.637301, 32.974633],
          [49.639242, 32.970504],
          [49.639521, 32.969911],
          [49.639876, 32.969327],
          [49.642876, 32.964398],
          [49.650801, 32.951376],
          [49.655077, 32.944349],
          [49.655911, 32.943239],
          [49.656693, 32.941576],
          [49.657693, 32.939453],
          [49.658134, 32.938516],
          [49.658411, 32.937406],
          [49.65869, 32.936575],
          [49.658967, 32.932683],
          [49.660479, 32.931008],
          [49.661467, 32.929912],
          [49.662577, 32.929911],
          [49.662577, 32.929632],
          [49.66341, 32.929632],
          [49.66369, 32.929352],
          [49.664244, 32.929352],
          [49.665077, 32.927961],
          [49.665357, 32.92713],
          [49.66591, 32.92546],
          [49.666744, 32.924079],
          [49.6673, 32.923239],
          [49.670634, 32.921018],
          [49.671467, 32.921018],
          [49.67619, 32.916295],
          [49.676744, 32.915299],
          [49.67758, 32.913794],
          [49.678134, 32.913514],
          [49.678536, 32.912792],
          [49.680913, 32.908521],
          [49.680357, 32.902409],
          [49.680913, 32.901569],
          [49.680913, 32.900738],
          [49.681467, 32.900187],
          [49.6823, 32.899077],
          [49.686747, 32.897127],
          [49.688134, 32.897126],
          [49.688134, 32.896847],
          [49.70008, 32.893795],
          [49.702856, 32.893244],
          [49.703137, 32.892964],
          [49.70369, 32.892964],
          [49.70369, 32.892684],
          [49.704247, 32.892684],
          [49.70619, 32.891294],
          [49.71286, 32.88463],
          [49.71397, 32.88407],
          [49.71897, 32.880468],
          [49.719526, 32.879908],
          [49.72008, 32.879908],
          [49.72036, 32.879628],
          [49.721746, 32.879628],
          [49.728413, 32.876016],
          [49.729526, 32.876016],
          [49.732303, 32.874906],
          [49.740083, 32.86463],
          [49.746193, 32.859077],
          [49.747303, 32.857958],
          [49.748693, 32.855737],
          [49.748973, 32.854906],
          [49.749526, 32.853515],
          [49.749249, 32.852405],
          [49.744803, 32.84352],
          [49.74397, 32.840739],
          [49.743693, 32.834075],
          [49.74397, 32.833245],
          [49.74425, 32.832965],
          [49.745359, 32.829904],
          [49.745359, 32.825741],
          [49.745359, 32.825182],
          [49.743969, 32.82101],
          [49.737026, 32.814346],
          [49.735916, 32.811854],
          [49.735916, 32.811574],
          [49.735207, 32.809874],
          [49.735083, 32.809344],
          [49.733137, 32.808243],
          [49.73175, 32.808243],
          [49.73175, 32.807963],
          [49.73147, 32.807683],
          [49.73175, 32.807132],
          [49.73175, 32.806852],
          [49.73175, 32.806572],
          [49.732303, 32.805732],
          [49.75286, 32.776297],
          [49.75286, 32.776017],
          [49.75314, 32.775737],
          [49.767306, 32.764071],
          [49.776473, 32.759629],
          [49.777306, 32.759629],
          [49.777582, 32.759349],
          [49.783139, 32.759349],
          [49.783416, 32.759629],
          [49.785083, 32.759629],
          [49.785639, 32.759909],
          [49.790639, 32.760459],
          [49.791196, 32.760179],
          [49.794252, 32.759909],
          [49.794529, 32.759629],
          [49.795085, 32.759629],
          [49.795362, 32.759349],
          [49.801195, 32.755737],
          [49.812586, 32.744622],
          [49.826752, 32.739629],
          [49.853975, 32.718789],
          [49.855365, 32.718238],
          [49.855641, 32.717958],
          [49.887588, 32.702121],
          [49.896201, 32.697958],
          [49.899535, 32.691845],
          [49.899535, 32.691015],
          [49.900644, 32.688514],
          [49.900924, 32.687963],
          [49.900924, 32.687403],
          [49.902868, 32.683791],
          [49.903701, 32.682961],
          [49.903978, 32.682121],
          [49.912035, 32.676568],
          [49.935091, 32.667674],
          [49.95037, 32.657958],
          [49.966204, 32.644351],
          [49.96648, 32.644071],
          [49.980023, 32.635987],
          [49.982317, 32.634617],
          [49.98537, 32.633786],
          [49.985927, 32.633236],
          [49.986483, 32.633236],
          [50.00065, 32.628513],
          [50.00176, 32.628513],
          [50.002316, 32.628233],
          [50.00315, 32.628233],
          [50.003427, 32.627953],
          [50.017596, 32.627683],
          [50.01815, 32.627953],
          [50.019816, 32.627953],
          [50.020096, 32.628233],
          [50.021206, 32.628233],
          [50.024263, 32.628793],
          [50.024816, 32.628793],
          [50.028983, 32.629064],
          [50.034819, 32.628793],
          [50.035373, 32.628513],
          [50.037039, 32.628513],
          [50.039263, 32.627954],
          [50.042039, 32.627403],
          [50.044539, 32.627403],
          [50.044819, 32.627683],
          [50.058986, 32.630455],
          [50.074429, 32.639006],
          [50.074542, 32.639069],
          [50.083709, 32.64157],
          [50.084819, 32.64157],
          [50.087042, 32.642121],
          [50.089265, 32.642121],
          [50.089542, 32.642401],
          [50.090932, 32.642401],
          [50.092322, 32.642961],
          [50.092875, 32.642961],
          [50.093989, 32.643511],
          [50.094822, 32.643511],
          [50.096765, 32.644351],
          [50.099265, 32.644902],
          [50.103432, 32.646292],
          [50.115378, 32.652956],
          [50.125378, 32.660451],
          [50.131768, 32.663232],
          [50.132601, 32.663232],
          [50.133988, 32.663791],
          [50.142044, 32.664902],
          [50.145658, 32.665173],
          [50.146491, 32.664902],
          [50.148434, 32.664902],
          [50.148991, 32.664622],
          [50.150101, 32.664622],
          [50.150658, 32.664342],
          [50.151491, 32.664342],
          [50.152324, 32.664062],
          [50.153711, 32.663792],
          [50.165934, 32.657959],
          [50.185942, 32.64248],
          [50.212604, 32.621841],
          [50.213714, 32.62073],
          [50.218713, 32.615457],
          [50.218708, 32.615457],
          [50.21927, 32.614906],
          [50.22038, 32.614067],
          [50.22066, 32.613796],
          [50.22927, 32.604622],
          [50.22927, 32.604071],
          [50.230383, 32.60296],
          [50.23066, 32.602121],
          [50.242607, 32.584902],
          [50.242883, 32.583791],
          [50.24344, 32.58296],
          [50.251217, 32.568793],
          [50.252883, 32.567123],
          [50.25455, 32.565732],
          [50.25594, 32.564351],
          [50.256773, 32.564071],
          [50.257327, 32.563511],
          [50.267883, 32.557398],
          [50.292053, 32.54101],
          [50.293439, 32.54073],
          [50.30261, 32.538508],
          [50.303996, 32.538508],
          [50.305109, 32.538229],
          [50.315386, 32.537118],
          [50.321499, 32.537118],
          [50.322052, 32.536838],
          [50.323719, 32.536838],
          [50.325386, 32.536838],
          [50.329552, 32.536838],
          [50.330109, 32.537118],
          [50.332886, 32.537118],
          [50.333166, 32.537398],
          [50.334556, 32.537398],
          [50.335942, 32.537958],
          [50.340109, 32.538508],
          [50.342332, 32.538508],
          [50.342609, 32.538788],
          [50.343442, 32.538788],
          [50.343722, 32.539068],
          [50.345389, 32.539068],
          [50.345942, 32.539348],
          [50.353165, 32.541373],
          [50.354832, 32.54184],
          [50.355665, 32.54212],
          [50.357332, 32.542951],
          [50.376502, 32.554626],
          [50.408724, 32.565452],
          [50.409558, 32.565732],
          [50.409835, 32.566012],
          [50.415446, 32.568121],
          [50.416225, 32.568233],
          [50.417334, 32.568793],
          [50.419001, 32.569064],
          [50.419001, 32.569344],
          [50.432891, 32.575737],
          [50.433724, 32.576568],
          [50.440391, 32.57962],
          [50.441504, 32.57962],
          [50.441781, 32.5799],
          [50.442891, 32.5799],
          [50.447057, 32.58157],
          [50.447614, 32.582121],
          [50.449004, 32.582681],
          [50.459004, 32.587954],
          [50.460114, 32.587954],
          [50.460394, 32.588234],
          [50.461781, 32.588234],
          [50.462337, 32.588514],
          [50.464004, 32.588514],
          [50.464281, 32.588794],
          [50.465671, 32.588794],
          [50.466227, 32.589073],
          [50.47095, 32.589344],
          [50.471227, 32.589073],
          [50.474561, 32.589073],
          [50.475117, 32.588794],
          [50.47845, 32.588794],
          [50.47956, 32.588514],
          [50.48095, 32.588514],
          [50.481227, 32.588794],
          [50.484284, 32.588794],
          [50.485117, 32.589073],
          [50.491227, 32.591286],
          [50.496227, 32.596848],
          [50.500116, 32.604351],
          [50.506783, 32.613236],
          [50.507897, 32.614907],
          [50.510552, 32.618175],
          [50.515116, 32.623792],
          [50.515673, 32.624622],
          [50.515949, 32.625182],
          [50.51595, 32.625462],
          [50.517616, 32.628514],
          [50.517896, 32.630735],
          [50.517616, 32.631566],
          [50.517063, 32.632126],
          [50.516783, 32.632957],
          [50.501507, 32.644902],
          [50.495393, 32.646844],
          [50.49345, 32.646844],
          [50.492893, 32.647124],
          [50.491507, 32.647124],
          [50.49095, 32.647404],
          [50.484284, 32.647404],
          [50.482617, 32.647124],
          [50.478727, 32.647124],
          [50.47845, 32.646844],
          [50.47095, 32.646573],
          [50.470393, 32.646844],
          [50.467894, 32.646844],
          [50.467617, 32.647124],
          [50.46706, 32.647124],
          [50.466784, 32.647124],
          [50.466227, 32.647124],
          [50.465434, 32.647522],
          [50.465114, 32.647684],
          [50.460114, 32.650456],
          [50.46005, 32.650593],
          [50.458447, 32.654067],
          [50.458171, 32.654627],
          [50.458447, 32.6599],
          [50.458171, 32.660731],
          [50.458447, 32.661291],
          [50.462061, 32.671286],
          [50.46956, 32.681842],
          [50.476783, 32.688794],
          [50.47845, 32.690736],
          [50.494006, 32.703512],
          [50.497363, 32.705088],
          [50.508793, 32.710453],
          [50.509553, 32.71081],
          [50.515946, 32.71381],
          [50.520043, 32.715732],
          [50.52123, 32.716289],
          [50.522063, 32.716289],
          [50.526606, 32.717807],
          [50.527063, 32.717959],
          [50.528097, 32.717959],
          [50.528173, 32.717959],
          [50.528708, 32.718049],
          [50.528835, 32.71807],
          [50.529839, 32.718239],
          [50.529994, 32.71827],
          [50.53024, 32.71832],
          [50.531229, 32.718519],
          [50.531784, 32.718654],
          [50.532339, 32.71879],
          [50.533062, 32.71879],
          [50.533483, 32.71879],
          [50.534286, 32.71879],
          [50.534286, 32.71907],
          [50.537339, 32.71907],
          [50.537339, 32.71935],
          [50.538172, 32.71935],
          [50.538453, 32.71907],
          [50.540953, 32.71907],
          [50.542381, 32.718243],
          [50.545131, 32.71665],
          [50.549885, 32.713897],
          [50.552626, 32.709655],
          [50.550751, 32.701578],
          [50.553953, 32.694255],
          [50.550452, 32.690453],
          [50.548673, 32.679906],
          [50.555423, 32.675153],
          [50.56221, 32.688309],
          [50.561325, 32.673464],
          [50.562695, 32.673044],
          [50.565016, 32.672331],
          [50.571756, 32.68672],
          [50.578879, 32.691238],
          [50.58718, 32.684057],
          [50.602391, 32.686943],
          [50.6058, 32.693211],
          [50.614758, 32.687898],
          [50.618191, 32.693549],
          [50.631172, 32.697607],
          [50.636676, 32.7064],
          [50.651846, 32.710515],
          [50.666589, 32.706588],
          [50.679528, 32.711874],
          [50.681922, 32.706381],
          [50.684291, 32.707463],
          [50.689097, 32.70966],
          [50.708304, 32.703378],
          [50.715503, 32.706038],
          [50.730127, 32.706853],
          [50.735127, 32.708795],
          [50.736517, 32.708795],
          [50.737073, 32.709075],
          [50.74124, 32.709345],
          [50.741793, 32.709075],
          [50.744017, 32.709075],
          [50.744573, 32.708795],
          [50.74596, 32.708795],
          [50.74624, 32.708515],
          [50.746793, 32.708515],
          [50.75235, 32.706573],
          [50.752906, 32.706294],
          [50.756516, 32.704903],
          [50.756796, 32.704343],
          [50.75735, 32.704343],
          [50.75985, 32.702962],
          [50.760683, 32.702962],
          [50.760683, 32.702682],
          [50.767073, 32.700461],
          [50.768463, 32.700461],
          [50.76902, 32.700181],
          [50.770129, 32.700181],
          [50.770686, 32.699901],
          [50.773186, 32.699901],
          [50.773463, 32.699621],
          [50.775686, 32.699621],
          [50.777073, 32.699621],
          [50.778739, 32.699621],
          [50.780129, 32.700181],
          [50.780963, 32.700181],
          [50.781519, 32.700461],
          [50.783463, 32.700732],
          [50.794576, 32.704072],
          [50.812352, 32.707964],
          [50.829022, 32.711296],
          [50.831799, 32.711296],
          [50.834575, 32.711296],
          [50.835409, 32.711016],
          [50.842909, 32.711016],
          [50.843189, 32.711296],
          [50.845965, 32.711567],
          [50.851245, 32.711567],
          [50.852355, 32.712127],
          [50.852912, 32.711847],
          [50.855132, 32.711296],
          [50.857078, 32.711296],
          [50.857632, 32.711016],
          [50.859298, 32.711016],
          [50.859578, 32.710736],
          [50.860412, 32.710736],
          [50.862635, 32.710185],
          [50.864022, 32.710185],
          [50.864855, 32.709905],
          [50.866245, 32.709905],
          [50.872355, 32.707684],
          [50.873188, 32.706854],
          [50.873745, 32.706854],
          [50.873745, 32.706574],
          [50.887358, 32.696289],
          [50.887358, 32.695738],
          [50.887911, 32.695179],
          [50.889578, 32.691576],
          [50.889858, 32.690465],
          [50.890135, 32.690185],
          [50.890135, 32.689626],
          [50.893744, 32.682682],
          [50.908107, 32.671777],
          [50.916801, 32.665174],
          [50.917081, 32.664903],
          [50.917741, 32.662789],
          [50.919858, 32.656009],
          [50.920138, 32.655178],
          [50.920414, 32.654627],
          [50.920414, 32.654348],
          [50.920691, 32.652677],
          [50.920971, 32.651016],
          [50.921248, 32.648795],
          [50.921524, 32.647124],
          [50.921804, 32.644072],
          [50.922081, 32.643512],
          [50.921804, 32.633788],
          [50.922358, 32.627955],
          [50.922638, 32.624623],
          [50.922914, 32.621291],
          [50.923191, 32.617959],
          [50.923471, 32.615738],
          [50.930691, 32.60018],
          [50.931804, 32.59851],
          [50.941527, 32.585733],
          [50.964861, 32.567403],
          [50.96597, 32.566844],
          [50.975417, 32.561291],
          [50.97597, 32.561011],
          [50.97597, 32.560731],
          [50.97764, 32.5599],
          [50.98014, 32.5599],
          [50.981527, 32.559349],
          [50.98264, 32.559349],
          [50.983193, 32.559069],
          [50.984027, 32.559069],
          [50.984584, 32.558789],
          [50.985417, 32.558789],
          [50.985974, 32.558509],
          [50.99653, 32.554627],
          [50.998197, 32.551286],
          [50.998473, 32.550735],
          [50.998197, 32.545733],
          [50.998197, 32.545182],
          [50.997917, 32.539349],
          [50.998197, 32.537958],
          [50.998473, 32.537679],
          [51.00014, 32.533507],
          [51.007917, 32.524062],
          [51.008197, 32.521561],
          [51.00875, 32.518229],
          [51.00903, 32.517678],
          [51.00903, 32.516839],
          [51.00903, 32.516568],
          [51.00903, 32.515177],
          [51.00903, 32.514897],
          [51.00514, 32.50129],
          [50.999863, 32.484622],
          [50.999792, 32.484062],
          [50.999307, 32.483511],
          [50.994307, 32.474066],
          [50.984584, 32.46045],
          [50.973194, 32.445732],
          [50.970417, 32.44017],
          [50.970137, 32.439619],
          [50.969861, 32.437948],
          [50.968471, 32.433506],
          [50.967917, 32.429343],
          [50.967637, 32.425452],
          [50.967361, 32.414616],
          [50.969584, 32.399618],
          [50.969861, 32.397117],
          [50.970137, 32.395447],
          [50.970417, 32.395176],
          [50.970417, 32.393785],
          [50.970694, 32.392124],
          [50.970971, 32.391284],
          [50.972084, 32.389894],
          [50.973194, 32.388232],
          [50.985417, 32.376006],
          [50.999894, 32.367952],
          [51.016253, 32.361009],
          [51.022087, 32.356837],
          [51.02264, 32.356286],
          [51.031806, 32.351004],
          [51.04181, 32.34434],
          [51.04653, 32.34267],
          [51.074309, 32.333505],
          [51.078476, 32.330724],
          [51.079589, 32.329893],
          [51.132645, 32.294895],
          [51.133759, 32.294615],
          [51.136535, 32.293225],
          [51.137369, 32.292394],
          [51.138202, 32.292123],
          [51.144312, 32.287672],
          [51.145702, 32.286281],
          [51.146258, 32.286011],
          [51.165705, 32.266561],
          [51.168481, 32.26267],
          [51.181258, 32.245171],
          [51.184315, 32.238787],
          [51.185148, 32.237396],
          [51.190705, 32.226561],
          [51.191538, 32.225722],
          [51.191538, 32.225442],
          [51.191815, 32.225171],
          [51.191815, 32.22462],
          [51.192371, 32.22434],
          [51.193761, 32.223229],
          [51.194038, 32.22267],
          [51.197095, 32.218227],
          [51.208761, 32.206281],
          [51.209318, 32.205171],
          [51.221263, 32.190165],
          [51.221818, 32.189613],
          [51.221818, 32.189333],
          [51.230984, 32.178227],
          [51.231541, 32.176837],
          [51.232094, 32.176557],
          [51.233761, 32.174336],
          [51.242374, 32.168783],
          [51.243484, 32.168503],
          [51.25654, 32.1635],
          [51.258207, 32.16323],
          [51.259317, 32.16267],
          [51.268487, 32.158778],
          [51.27793, 32.156557],
          [51.282097, 32.156557],
          [51.282653, 32.156277],
          [51.28432, 32.156277],
          [51.284597, 32.155997],
          [51.287653, 32.155997],
          [51.289043, 32.156277],
          [51.292653, 32.156277],
          [51.293487, 32.155997],
          [51.299877, 32.155446],
          [51.3021, 32.154887],
          [51.326823, 32.147672],
          [51.33099, 32.146002],
          [51.3321, 32.145171],
          [51.332656, 32.145171],
          [51.340156, 32.141559],
          [51.340989, 32.141559],
          [51.340989, 32.141279],
          [51.341823, 32.14128],
          [51.342379, 32.141],
          [51.343489, 32.140729],
          [51.343766, 32.141],
          [51.344231, 32.140636],
          [51.344599, 32.140449],
          [51.345713, 32.139618],
          [51.354323, 32.130724],
          [51.357379, 32.12295],
          [51.367656, 32.110165],
          [51.381826, 32.095998],
          [51.382379, 32.094887],
          [51.385159, 32.091276],
          [51.385712, 32.091556],
          [51.386269, 32.086553],
          [51.386546, 32.086002],
          [51.386269, 32.083501],
          [51.385992, 32.072386],
          [51.385436, 32.070725],
          [51.384326, 32.070165],
          [51.383769, 32.069894],
          [51.383492, 32.069334],
          [51.375992, 32.05822],
          [51.376826, 32.055728],
          [51.378492, 32.052667],
          [51.384326, 32.049615],
          [51.385159, 32.049615],
          [51.385436, 32.049335],
          [51.386546, 32.049335],
          [51.386826, 32.049055],
          [51.387659, 32.049055],
          [51.387936, 32.048775],
          [51.402935, 32.043502],
          [51.404049, 32.042662],
          [51.414325, 32.032387],
          [51.424882, 32.007106],
          [51.429325, 31.999891],
          [51.429325, 31.993779],
          [51.429605, 31.992388],
          [51.429882, 31.990727],
          [51.430162, 31.986276],
          [51.430438, 31.983774],
          [51.430715, 31.982664],
          [51.431272, 31.981833],
          [51.432745, 31.977146],
          [51.433888, 31.973509],
          [51.434328, 31.972109],
          [51.434228, 31.971413],
          [51.434048, 31.970168],
          [51.433511, 31.969884],
          [51.433323, 31.969784],
          [51.424941, 31.965357],
          [51.424048, 31.964886],
          [51.422624, 31.964328],
          [51.417659, 31.962384],
          [51.416185, 31.960325],
          [51.415007, 31.958677],
          [51.414882, 31.958502],
          [51.414605, 31.957391],
          [51.413215, 31.95238],
          [51.413492, 31.951279],
          [51.414325, 31.949328],
          [51.415159, 31.948498],
          [51.417939, 31.945166],
          [51.422382, 31.940444],
          [51.425438, 31.935442],
          [51.427659, 31.929609],
          [51.428215, 31.926828],
          [51.426272, 31.912662],
          [51.417382, 31.894053],
          [51.414325, 31.880446],
          [51.414049, 31.866831],
          [51.413216, 31.86405],
          [51.412106, 31.861829],
          [51.410992, 31.860438],
          [51.409602, 31.858497],
          [51.402936, 31.851834],
          [51.402102, 31.851834],
          [51.399882, 31.850714],
          [51.394049, 31.849333],
          [51.393492, 31.849333],
          [51.392659, 31.849333],
          [51.392383, 31.849053],
          [51.391546, 31.849053],
          [51.386826, 31.849053],
          [51.373213, 31.847383],
          [51.372103, 31.847662],
          [51.367379, 31.847662],
          [51.363769, 31.847112],
          [51.33071, 31.837667],
          [51.302934, 31.825162],
          [51.300987, 31.824332],
          [51.29821, 31.823212],
          [51.29682, 31.822941],
          [51.296264, 31.822381],
          [51.29571, 31.822102],
          [51.293487, 31.82016],
          [51.293211, 31.81961],
          [51.287377, 31.810445],
          [51.285431, 31.802103],
          [51.287931, 31.79655],
          [51.294877, 31.789607],
          [51.32571, 31.77182],
          [51.327933, 31.770439],
          [51.33099, 31.767938],
          [51.3371, 31.760995],
          [51.337933, 31.756553],
          [51.338213, 31.750991],
          [51.33849, 31.745989],
          [51.338767, 31.743217],
          [51.339047, 31.742107],
          [51.339323, 31.741547],
          [51.340157, 31.739055],
          [51.34099, 31.738216],
          [51.341547, 31.737105],
          [51.3421, 31.736825],
          [51.342657, 31.735715],
          [51.343213, 31.735444],
          [51.345433, 31.733214],
          [51.36238, 31.720989],
          [51.36849, 31.714326],
          [51.369046, 31.712656],
          [51.369046, 31.711545],
          [51.36877, 31.707934],
          [51.367811, 31.706022],
          [51.366823, 31.704051],
          [51.359046, 31.693217],
          [51.34599, 31.682942],
          [51.345157, 31.682942],
          [51.3121, 31.671828],
          [51.310154, 31.670158],
          [51.309044, 31.668488],
          [51.305434, 31.661825],
          [51.304321, 31.657383],
          [51.304044, 31.656263],
          [51.303767, 31.654322],
          [51.303487, 31.65016],
          [51.302377, 31.615996],
          [51.302292, 31.614901],
          [51.302101, 31.614326],
          [51.300711, 31.607103],
          [51.299321, 31.5971],
          [51.292377, 31.569879],
          [51.290431, 31.549873],
          [51.290711, 31.546821],
          [51.296544, 31.519321],
          [51.296544, 31.51571],
          [51.295711, 31.513489],
          [51.294878, 31.511819],
          [51.287654, 31.506817],
          [51.284044, 31.505707],
          [51.278488, 31.504317],
          [51.277098, 31.503206],
          [51.273765, 31.493763],
          [51.273488, 31.492932],
          [51.272931, 31.491542],
          [51.272931, 31.491262],
          [51.272374, 31.490152],
          [51.270155, 31.480708],
          [51.259598, 31.460711],
          [51.259598, 31.459321],
          [51.283211, 31.449598],
          [51.286821, 31.446817],
          [51.288488, 31.445156],
          [51.289044, 31.444036],
          [51.303073, 31.422099],
          [51.303211, 31.421819],
          [51.303487, 31.421539],
          [51.303768, 31.420988],
          [51.304044, 31.420149],
          [51.304321, 31.419598],
          [51.304321, 31.418208],
          [51.306821, 31.412926],
          [51.307378, 31.412656],
          [51.308487, 31.410706],
          [51.314044, 31.39348],
          [51.313767, 31.388488],
          [51.314321, 31.384597],
          [51.314044, 31.380359],
          [51.314044, 31.379876],
          [51.313767, 31.379316],
          [51.310434, 31.377926],
          [51.305988, 31.377375],
          [51.298211, 31.374594],
          [51.294044, 31.368483],
          [51.293211, 31.365702],
          [51.293488, 31.364041],
          [51.297935, 31.353208],
          [51.298488, 31.352928],
          [51.323767, 31.317928],
          [51.326267, 31.315156],
          [51.329877, 31.310706],
          [51.334324, 31.303764],
          [51.337101, 31.296532],
          [51.338491, 31.28876],
          [51.338767, 31.28709],
          [51.335434, 31.239317],
          [51.331544, 31.216812],
          [51.330991, 31.20987],
          [51.330711, 31.201538],
          [51.329878, 31.195989],
          [51.329878, 31.195988],
          [51.329878, 31.195987],
          [51.329877, 31.195986],
          [51.329877, 31.195985],
          [51.329879, 31.194314],
          [51.329601, 31.193477],
          [51.329046, 31.189315],
          [51.329023, 31.188942],
          [51.327659, 31.154306],
          [51.327101, 31.121261],
          [51.329878, 31.102088],
          [51.330434, 31.101257],
          [51.330991, 31.099596],
          [51.335434, 31.088755],
          [51.335991, 31.086255],
          [51.337102, 31.082921],
          [51.337657, 31.080974],
          [51.337934, 31.078762],
          [51.338214, 31.078483],
          [51.338216, 31.077921],
          [51.338214, 31.077372],
          [51.338214, 31.07737],
          [51.338214, 31.076533],
          [51.338491, 31.075982],
          [51.338491, 31.074872],
          [51.342101, 31.062641],
          [51.359047, 31.028755],
          [51.359881, 31.027924],
          [51.362937, 31.022924],
          [51.364047, 31.021805],
          [51.375994, 31.001251],
          [51.375994, 31.000972],
          [51.377104, 30.99959],
          [51.37738, 30.997361],
          [51.377661, 30.99653],
          [51.378351, 30.995839],
          [51.379327, 30.99487],
          [51.407103, 30.971257],
          [51.40877, 30.970417],
          [51.411273, 30.966527],
          [51.411273, 30.966247],
          [51.411827, 30.965146],
          [51.413733, 30.962877],
          [51.414327, 30.962086],
          [51.414883, 30.961806],
          [51.461829, 30.919862],
          [51.461829, 30.919582],
          [51.46246, 30.918931],
          [51.463219, 30.918472],
          [51.465163, 30.916811],
          [51.469053, 30.912921],
          [51.508499, 30.878478],
          [51.523499, 30.869307],
          [51.539332, 30.862087],
          [51.545721, 30.858476],
          [51.566278, 30.847366],
          [51.573225, 30.842916],
          [51.577668, 30.839856],
          [51.594614, 30.828755],
          [51.594614, 30.828475],
          [51.596558, 30.826805],
          [51.598501, 30.825695],
          [51.602391, 30.822355],
          [51.658466, 30.778523],
          [51.669893, 30.769583],
          [51.670173, 30.769033],
          [51.677673, 30.760973],
          [51.677673, 30.760693],
          [51.67823, 30.760413],
          [51.679063, 30.759583],
          [51.680173, 30.758753],
          [51.681006, 30.758473],
          [51.723509, 30.730693],
          [51.739899, 30.719024],
          [51.743785, 30.717083],
          [51.746566, 30.715414],
          [51.777122, 30.702084],
          [51.783232, 30.702084],
          [51.783512, 30.701804],
          [51.784898, 30.701804],
          [51.785455, 30.701524],
          [51.786288, 30.701524],
          [51.786565, 30.701253],
          [51.787398, 30.701253],
          [51.787398, 30.700974],
          [51.789345, 30.700974],
          [51.789345, 30.701253],
          [51.797678, 30.704304],
          [51.812958, 30.715143],
          [51.827401, 30.733473],
          [51.832681, 30.740414],
          [51.833514, 30.742083],
          [51.846571, 30.769033],
          [51.854904, 30.795135],
          [51.857404, 30.799584],
          [51.85768, 30.799855],
          [51.857957, 30.800414],
          [51.858514, 30.800415],
          [51.858514, 30.802635],
          [51.856291, 30.813195],
          [51.842681, 30.842357],
          [51.842401, 30.844035],
          [51.841567, 30.845967],
          [51.832401, 30.875978],
          [51.832681, 30.882639],
          [51.835458, 30.893479],
          [51.837124, 30.898479],
          [51.841291, 30.905971],
          [51.841567, 30.906531],
          [51.849904, 30.919582],
          [51.856014, 30.926532],
          [51.87157, 30.942644],
          [51.872403, 30.944314],
          [51.87296, 30.944585],
          [51.894903, 30.984029],
          [51.89546, 30.985419],
          [51.897683, 30.98931],
          [51.897683, 30.98959],
          [51.897683, 30.990149],
          [51.897683, 30.9907],
          [51.89796, 30.990971],
          [51.89796, 30.99181],
          [51.89657, 31.000123],
          [51.897403, 31.012363],
          [51.897683, 31.021254],
          [51.898516, 31.027365],
          [51.901016, 31.032926],
          [51.901293, 31.033206],
          [51.902126, 31.034307],
          [51.902406, 31.034587],
          [51.921849, 31.077093],
          [51.924906, 31.082924],
          [51.940186, 31.11181],
          [51.941019, 31.11292],
          [51.956852, 31.150978],
          [51.967962, 31.194317],
          [51.968518, 31.210981],
          [51.968242, 31.211261],
          [51.968518, 31.212922],
          [51.968518, 31.213202],
          [51.968518, 31.213481],
          [51.968242, 31.214032],
          [51.966575, 31.220423],
          [51.966295, 31.222093],
          [51.966019, 31.224594],
          [51.965742, 31.225984],
          [51.965462, 31.228485],
          [51.965185, 31.230976],
          [51.964629, 31.234316],
          [51.964075, 31.237927],
          [51.963519, 31.241818],
          [51.963518, 31.243759],
          [51.954629, 31.28821],
          [51.954629, 31.290422],
          [51.954352, 31.290981],
          [51.954629, 31.292931],
          [51.954629, 31.294872],
          [51.956295, 31.300984],
          [51.955739, 31.302374],
          [51.955462, 31.304044],
          [51.955185, 31.306256],
          [51.953519, 31.314868],
          [51.953519, 31.316538],
          [51.948795, 31.335984],
          [51.947962, 31.340985],
          [51.945462, 31.350148],
          [51.945185, 31.352378],
          [51.944352, 31.354878],
          [51.941852, 31.376536],
          [51.938239, 31.394871],
          [51.937405, 31.397092],
          [51.936572, 31.402933],
          [51.936295, 31.404043],
          [51.936572, 31.412096],
          [51.936572, 31.412376],
          [51.936572, 31.412656],
          [51.936572, 31.413487],
          [51.937682, 31.414597],
          [51.937962, 31.415428],
          [51.938795, 31.415987],
          [51.980465, 31.489042],
          [51.983798, 31.494323],
          [51.988241, 31.502376],
          [51.998798, 31.525434],
          [52.002964, 31.536268],
          [52.007964, 31.545991],
          [52.009631, 31.548492],
          [52.010464, 31.550433],
          [52.0463, 31.59571],
          [52.07269, 31.617107],
          [52.078247, 31.62071],
          [52.130469, 31.656553],
          [52.134082, 31.657934],
          [52.142692, 31.660435],
          [52.144082, 31.660995],
          [52.144916, 31.660995],
          [52.146302, 31.661545],
          [52.186305, 31.667378],
          [52.190472, 31.667938],
          [52.191862, 31.667938],
          [52.192695, 31.668218],
          [52.194362, 31.668218],
          [52.200751, 31.669328],
          [52.201305, 31.669328],
          [52.201862, 31.669599],
          [52.202138, 31.669328],
          [52.203223, 31.669201],
          [52.204641, 31.669048],
          [52.205195, 31.668768],
          [52.206585, 31.668768],
          [52.207141, 31.668488],
          [52.208808, 31.668488],
          [52.211028, 31.667938],
          [52.212141, 31.667938],
          [52.212695, 31.667658],
          [52.213808, 31.667658],
          [52.214085, 31.667378],
          [52.214918, 31.667378],
          [52.215475, 31.667107],
          [52.217695, 31.667107],
          [52.218808, 31.666827],
          [52.219918, 31.666827],
          [52.220751, 31.666547],
          [52.222698, 31.666547],
          [52.237418, 31.664326],
          [52.239364, 31.663767],
          [52.240751, 31.663767],
          [52.244641, 31.662656],
          [52.247421, 31.661546],
          [52.344646, 31.572381],
          [52.344923, 31.572101],
          [52.345203, 31.571821],
          [52.372979, 31.554605],
          [52.381036, 31.551544],
          [52.393539, 31.548213],
          [52.397706, 31.547373],
          [52.399926, 31.546822],
          [52.407705, 31.545992],
          [52.410759, 31.545432],
          [52.412982, 31.545432],
          [52.430205, 31.542931],
          [52.432985, 31.54266],
          [52.496598, 31.523493],
          [52.497154, 31.523493],
          [52.499654, 31.522934],
          [52.559657, 31.51238],
          [52.573823, 31.511269],
          [52.574933, 31.510989],
          [52.578546, 31.510989],
          [52.579656, 31.51071],
          [52.582436, 31.51071],
          [52.584656, 31.51043],
          [52.586603, 31.51043],
          [52.596323, 31.509879],
          [52.610769, 31.508769],
          [52.613826, 31.508769],
          [52.615493, 31.508489],
          [52.618549, 31.508489],
          [52.623269, 31.507938],
          [52.626049, 31.507938],
          [52.656325, 31.504877],
          [52.660215, 31.504877],
          [52.686605, 31.502936],
          [52.690772, 31.502936],
          [52.729108, 31.499045],
          [52.730498, 31.499045],
          [52.732998, 31.498486],
          [52.754944, 31.496265],
          [52.761054, 31.495994],
          [52.762164, 31.495705],
          [52.777444, 31.495705],
          [52.779387, 31.495434],
          [52.782444, 31.495434],
          [52.784387, 31.495154],
          [52.786334, 31.495154],
          [52.787167, 31.494874],
          [52.793, 31.494874],
          [52.794667, 31.494604],
          [52.79661, 31.494604],
          [52.797723, 31.494874],
          [52.79911, 31.494874],
          [52.79939, 31.495154],
          [52.8005, 31.494874],
          [52.801057, 31.494874],
          [52.805777, 31.494603],
          [52.806057, 31.494324],
          [52.807723, 31.494324],
          [52.81328, 31.493213],
          [52.829113, 31.492103],
          [52.83078, 31.491823],
          [52.83217, 31.491823],
          [52.833003, 31.491543],
          [52.836056, 31.491543],
          [52.836889, 31.491263],
          [52.841889, 31.491263],
          [52.849113, 31.490712],
          [52.849946, 31.490433],
          [52.853559, 31.490433],
          [52.857726, 31.489873],
          [52.858559, 31.490153],
          [52.871616, 31.490153],
          [52.872726, 31.489873],
          [52.880782, 31.489873],
          [52.883282, 31.489602],
          [52.886892, 31.489602],
          [52.888282, 31.489322],
          [52.892725, 31.489322],
          [52.901615, 31.489042],
          [52.921338, 31.489873],
          [52.922728, 31.490153],
          [52.936061, 31.490153],
          [52.938562, 31.489873],
          [52.941061, 31.490153],
          [52.946061, 31.490153],
          [52.977451, 31.493493],
          [52.979397, 31.493493],
          [52.982454, 31.494044],
          [52.984397, 31.494044],
          [52.985231, 31.494324],
          [52.987174, 31.494324],
          [52.993841, 31.495434],
          [52.996621, 31.496265],
          [52.997731, 31.496265],
          [53.006344, 31.498766],
          [53.007177, 31.499325],
          [53.009397, 31.499596],
          [53.011344, 31.500427],
          [53.052457, 31.509879],
          [53.057456, 31.511269],
          [53.05968, 31.51154],
          [53.083289, 31.520153],
          [53.091346, 31.523494],
          [53.129125, 31.53988],
          [53.130792, 31.54071],
          [53.132739, 31.54127],
          [53.133572, 31.541821],
          [53.135515, 31.542381],
          [53.183295, 31.56682],
          [53.184961, 31.56766],
          [53.186075, 31.567931],
          [53.194408, 31.573212],
          [53.202908, 31.588926],
          [53.203574, 31.590158],
          [53.203574, 31.590438],
          [53.203294, 31.590718],
          [53.203419, 31.591828],
          [53.203574, 31.59321],
          [53.203851, 31.596821],
          [53.198018, 31.613488],
          [53.186908, 31.627933],
          [53.180238, 31.637657],
          [53.177185, 31.641268],
          [53.140239, 31.680434],
          [53.102459, 31.704603],
          [53.081903, 31.716268],
          [53.081069, 31.717108],
          [53.079403, 31.717938],
          [53.075789, 31.72099],
          [53.062456, 31.734325],
          [53.05468, 31.744329],
          [53.053013, 31.74599],
          [53.04079, 31.763497],
          [53.0294, 31.784886],
          [53.027453, 31.792109],
          [53.0219, 31.817661],
          [53.019953, 31.822942],
          [53.009677, 31.854607],
          [53.009397, 31.857658],
          [53.00912, 31.86183],
          [53.009397, 31.879888],
          [53.01662, 31.90627],
          [53.0169, 31.90683],
          [53.01801, 31.911552],
          [53.018843, 31.920446],
          [53.031067, 31.961835],
          [53.032177, 31.967388],
          [53.037457, 31.982114],
          [53.037457, 31.982394],
          [53.037457, 31.982664],
          [53.037457, 31.982665],
          [53.038566, 31.983496],
          [53.042456, 31.989888],
          [53.039957, 32.000046],
          [53.041066, 32.007107],
          [53.040233, 32.009608],
          [53.039956, 32.011278],
          [53.039676, 32.013779],
          [53.0394, 32.01517],
          [53.039123, 32.018781],
          [53.04079, 32.034339],
          [53.041623, 32.03795],
          [53.0419, 32.038501],
          [53.0419, 32.038781],
          [53.041623, 32.039052],
          [53.042176, 32.046835],
          [53.0419, 32.047106],
          [53.041623, 32.049056],
          [53.042733, 32.059891],
          [53.04301, 32.062392],
          [53.044123, 32.082952],
          [53.0444, 32.086834],
          [53.049123, 32.128504],
          [53.048843, 32.130446],
          [53.049123, 32.142951],
          [53.0494, 32.146843],
          [53.050233, 32.153506],
          [53.050509, 32.154888],
          [53.052456, 32.158779],
          [53.052733, 32.159339],
          [53.052953, 32.159853],
          [53.053566, 32.16128],
          [53.053846, 32.16156],
          [53.053846, 32.16212],
          [53.054123, 32.175727],
          [53.056066, 32.20017],
          [53.05579, 32.20184],
          [53.052176, 32.224621],
          [53.051899, 32.242671],
          [53.052733, 32.257118],
          [53.051066, 32.270174],
          [53.051899, 32.279619],
          [53.051623, 32.28045],
          [53.052176, 32.286012],
          [53.052456, 32.286283],
          [53.052733, 32.287113],
          [53.053013, 32.288224],
          [53.052733, 32.288784],
          [53.056066, 32.296007],
          [53.058289, 32.304621],
          [53.058013, 32.305732],
          [53.058289, 32.310175],
          [53.058566, 32.313786],
          [53.058846, 32.332116],
          [53.058566, 32.332956],
          [53.058846, 32.336008],
          [53.058566, 32.337118],
          [53.055789, 32.368784],
          [53.055513, 32.372396],
          [53.055233, 32.377399],
          [53.054956, 32.384893],
          [53.052456, 32.404342],
          [53.051066, 32.414618],
          [53.050509, 32.415458],
          [53.050233, 32.417119],
          [53.044123, 32.431286],
          [53.042456, 32.435178],
          [53.04079, 32.440171],
          [53.038079, 32.4521],
          [53.035233, 32.464623],
          [53.01301, 32.504623],
          [53.011063, 32.508795],
          [53.010787, 32.509066],
          [53.013843, 32.512678],
          [53.014677, 32.514348],
          [53.014677, 32.514619],
          [53.015233, 32.515179],
          [53.015233, 32.515459],
          [53.016066, 32.515459],
          [53.022176, 32.51879],
          [53.023286, 32.519901],
          [53.02551, 32.521291],
          [53.027453, 32.522682],
          [53.0319, 32.526294],
          [53.095792, 32.55601],
          [53.114402, 32.569346],
          [53.117182, 32.572118],
          [53.124125, 32.579071],
          [53.160515, 32.605464],
          [53.184127, 32.61629],
          [53.186627, 32.617681],
          [53.220517, 32.629907],
          [53.284409, 32.648236],
          [53.288299, 32.649347],
          [53.376638, 32.676291],
          [53.443584, 32.690467],
          [53.446084, 32.690738],
          [53.461084, 32.694629],
          [53.462474, 32.69518],
          [53.47664, 32.697682],
          [53.477197, 32.697961],
          [53.47914, 32.697961],
          [53.482754, 32.697961],
          [53.483587, 32.698241],
          [53.48414, 32.697961],
          [53.49081, 32.697961],
          [53.491643, 32.698241],
          [53.495253, 32.698241],
          [53.499143, 32.698512],
          [53.502477, 32.698792],
          [53.508033, 32.698792],
          [53.509977, 32.699072],
          [53.521086, 32.699072],
          [53.522476, 32.699352],
          [53.527476, 32.699352],
          [53.52859, 32.699072],
          [53.537476, 32.699072],
          [53.539699, 32.698792],
          [53.541089, 32.699072],
          [53.543313, 32.698792],
          [53.545813, 32.698792],
          [53.546923, 32.698512],
          [53.549423, 32.698512],
          [53.631372, 32.685185],
          [53.664984, 32.681573],
          [53.668038, 32.681573],
          [53.669984, 32.681293],
          [53.688041, 32.681293],
          [53.690541, 32.681013],
          [53.694707, 32.681013],
          [53.697764, 32.681293],
          [53.701374, 32.681293],
          [53.703874, 32.681573],
          [53.704698, 32.681688],
          [53.706651, 32.681844],
          [53.707207, 32.681937],
          [53.708317, 32.682124],
          [53.709431, 32.682404],
          [53.711097, 32.681844],
          [53.711931, 32.681844],
          [53.712484, 32.682124],
          [53.713317, 32.682124],
          [53.713597, 32.681844],
          [53.71554, 32.681844],
          [53.716654, 32.681573],
          [53.717764, 32.681573],
          [53.718597, 32.681293],
          [53.71943, 32.681293],
          [53.719987, 32.681013],
          [53.72193, 32.681013],
          [53.722487, 32.680733],
          [53.72443, 32.680733],
          [53.724707, 32.680462],
          [53.72693, 32.680462],
          [53.73471, 32.679352],
          [53.736377, 32.679352],
          [53.737763, 32.679072],
          [53.741377, 32.679072],
          [53.74221, 32.678792],
          [53.746097, 32.678792],
          [53.747487, 32.678512],
          [53.76332, 32.678512],
          [53.764433, 32.678792],
          [53.766653, 32.678792],
          [53.767766, 32.679072],
          [53.770543, 32.679072],
          [53.775823, 32.679623],
          [53.776376, 32.679903],
          [53.783043, 32.679903],
          [53.784156, 32.679623],
          [53.790546, 32.679623],
          [53.791933, 32.679352],
          [53.793599, 32.679352],
          [53.794713, 32.679623],
          [53.797213, 32.679623],
          [53.800266, 32.679623],
          [53.800546, 32.679903],
          [53.804156, 32.679903],
          [53.812489, 32.680733],
          [53.813323, 32.681013],
          [53.816102, 32.681013],
          [53.816656, 32.681293],
          [53.818879, 32.681293],
          [53.819436, 32.681573],
          [53.821656, 32.681573],
          [53.836936, 32.684345],
          [53.842492, 32.686016],
          [53.853602, 32.691849],
          [53.862061, 32.700922],
          [53.872772, 32.712409],
          [53.880825, 32.722133],
          [53.910551, 32.760743],
          [53.921941, 32.7763],
          [53.941384, 32.798801],
          [53.957774, 32.807135],
          [53.959441, 32.808246],
          [53.989443, 32.819912],
          [53.9975, 32.822693],
          [53.99972, 32.823523],
          [54.001127, 32.823499],
          [54.005277, 32.821293],
          [54.01639, 32.824354],
          [54.0175, 32.824905],
          [54.02, 32.825745],
          [54.02139, 32.826295],
          [54.056946, 32.83574],
          [54.058613, 32.83658],
          [54.140281, 32.861022],
          [54.141671, 32.861582],
          [54.145838, 32.861582],
          [54.146395, 32.861853],
          [54.148618, 32.861853],
          [54.149171, 32.862133],
          [54.150005, 32.862133],
          [54.150561, 32.862412],
          [54.152228, 32.862412],
          [54.160838, 32.865184],
          [54.161671, 32.865184],
          [54.162785, 32.865744],
          [54.163618, 32.865744],
          [54.168894, 32.868245],
          [54.170841, 32.868796],
          [54.206674, 32.887406],
          [54.224177, 32.8988],
          [54.22501, 32.899631],
          [54.226397, 32.900742],
          [54.24751, 32.916859],
          [54.261957, 32.930466],
          [54.272233, 32.942971],
          [54.283623, 32.96019],
          [54.290289, 32.971025],
          [54.2914, 32.972686],
          [54.299179, 32.993245],
          [54.299456, 32.994076],
          [54.299736, 32.995746],
          [54.301403, 32.999358],
          [54.301403, 33.006301],
          [54.301123, 33.010473],
          [54.301403, 33.010744],
          [54.301679, 33.012685],
          [54.301956, 33.014355],
          [54.302513, 33.017136],
          [54.304736, 33.02547],
          [54.304736, 33.026301],
          [54.305569, 33.027691],
          [54.307513, 33.030192],
          [54.348349, 33.052963],
          [54.354533, 33.054769],
          [54.375015, 33.060746],
          [54.375848, 33.061297],
          [54.381128, 33.062688],
          [54.389738, 33.065189],
          [54.453907, 33.076583],
          [54.455854, 33.076583],
          [54.471411, 33.079635],
          [54.473077, 33.079635],
          [54.475577, 33.080195],
          [54.477244, 33.080195],
          [54.478077, 33.080475],
          [54.479744, 33.080475],
          [54.507523, 33.085748],
          [54.50919, 33.085748],
          [54.52669, 33.088799],
          [54.535856, 33.089639],
          [54.543636, 33.09019],
          [54.54558, 33.09047],
          [54.548356, 33.09047],
          [54.550023, 33.09075],
          [54.557246, 33.09075],
          [54.558913, 33.09047],
          [54.564193, 33.09047],
          [54.609749, 33.084917],
          [54.622805, 33.081305],
          [54.627529, 33.080195],
          [54.628915, 33.079635],
          [54.631415, 33.079355],
          [54.632805, 33.078804],
          [54.667251, 33.072692],
          [54.668085, 33.072412],
          [54.670308, 33.072412],
          [54.688364, 33.069911],
          [54.698088, 33.068249],
          [54.704198, 33.06713],
          [54.721421, 33.065189],
          [54.724197, 33.065189],
          [54.725864, 33.064918],
          [54.731977, 33.064918],
          [54.733367, 33.065189],
          [54.73531, 33.064918],
          [54.740587, 33.064918],
          [54.744754, 33.065748],
          [54.751977, 33.0688],
          [54.752533, 33.06936],
          [54.75309, 33.06936],
          [54.770033, 33.095192],
          [54.770985, 33.096699],
          [54.771029, 33.096776],
          [54.77559, 33.104636],
          [54.7867, 33.115191],
          [54.787813, 33.115751],
          [54.790313, 33.117972],
          [54.79948, 33.124364],
          [54.800036, 33.125195],
          [54.877815, 33.171585],
          [54.878649, 33.171856],
          [54.883929, 33.174357],
          [54.886152, 33.175188],
          [54.888372, 33.176587],
          [54.897262, 33.180199],
          [54.898095, 33.179919],
          [54.899485, 33.179919],
          [54.899762, 33.179639],
          [54.901152, 33.179639],
          [54.912818, 33.175188],
          [54.954764, 33.148255],
          [54.961711, 33.143524],
          [54.990601, 33.126026],
          [54.99171, 33.125746],
          [55.01949, 33.150476],
          [55.027267, 33.15964],
          [55.03338, 33.167134],
          [55.077269, 33.211862],
          [55.104492, 33.236312],
          [55.107826, 33.238804],
          [55.109772, 33.240754],
          [55.137272, 33.261033],
          [55.154495, 33.275749],
          [55.175608, 33.291865],
          [55.175885, 33.292145],
          [55.176718, 33.292416],
          [55.183385, 33.298528],
          [55.222554, 33.324088],
          [55.224777, 33.325199],
          [55.228111, 33.32686],
          [55.232834, 33.329361],
          [55.254224, 33.338254],
          [55.259224, 33.339924],
          [55.260057, 33.340475],
          [55.268667, 33.342976],
          [55.26978, 33.343535],
          [55.323393, 33.35409],
          [55.324783, 33.35464],
          [55.362282, 33.359642],
          [55.364229, 33.360193],
          [55.367006, 33.360473],
          [55.375896, 33.363253],
          [55.377286, 33.363253],
          [55.378396, 33.363533],
          [55.380339, 33.363533],
          [55.381729, 33.363813],
          [55.383672, 33.363813],
          [55.385895, 33.364364],
          [55.386452, 33.364924],
          [55.387285, 33.366314],
          [55.392285, 33.375478],
          [55.394509, 33.377979],
          [55.400342, 33.384642],
          [55.402009, 33.386312],
          [55.416175, 33.406309],
          [55.488401, 33.502143],
          [55.496177, 33.517978],
          [55.496458, 33.518809],
          [55.496734, 33.519929],
          [55.496734, 33.52159],
          [55.497844, 33.529092],
          [55.497567, 33.538535],
          [55.495344, 33.567424],
          [55.493678, 33.581869],
          [55.493958, 33.582149],
          [55.493958, 33.583259],
          [55.493401, 33.58687],
          [55.493124, 33.58715],
          [55.493124, 33.587981],
          [55.492568, 33.596864],
          [55.492844, 33.597704],
          [55.493124, 33.602425],
          [55.493401, 33.606876],
          [55.493678, 33.612708],
          [55.493401, 33.625202],
          [55.493678, 33.626592],
          [55.492291, 33.678817],
          [55.492011, 33.687149],
          [55.491734, 33.698262],
          [55.491734, 33.715757],
          [55.491178, 33.736042],
          [55.491178, 33.738262],
          [55.490344, 33.780763],
          [55.489791, 33.818541],
          [55.490625, 33.829933],
          [55.490625, 33.851318],
          [55.489511, 33.871321],
          [55.489235, 33.875492],
          [55.488401, 33.900487],
          [55.487568, 33.92494],
          [55.487288, 33.929932],
          [55.484511, 33.947434],
          [55.484788, 33.949384],
          [55.485068, 33.954376],
          [55.485345, 33.957436],
          [55.482568, 33.997991],
          [55.482288, 34.009102],
          [55.482011, 34.010772],
          [55.482288, 34.011052],
          [55.482568, 34.012993],
          [55.482288, 34.013273],
          [55.482568, 34.013823],
          [55.482011, 34.018273],
          [55.482011, 34.038826],
          [55.482288, 34.039106],
          [55.482011, 34.039656],
          [55.482288, 34.040487],
          [55.482568, 34.048827],
          [55.482845, 34.050767],
          [55.482568, 34.051047],
          [55.482845, 34.051598],
          [55.483121, 34.053827],
          [55.483401, 34.062718],
          [55.483678, 34.065489],
          [55.482568, 34.09244],
          [55.482011, 34.102161],
          [55.480901, 34.111602],
          [55.479235, 34.150223],
          [55.480068, 34.158825],
          [55.480345, 34.162444],
          [55.480622, 34.169105],
          [55.478678, 34.211326],
          [55.478122, 34.220775],
          [55.477845, 34.222996],
          [55.477845, 34.243275],
          [55.478678, 34.249386],
          [55.478955, 34.250216],
          [55.478678, 34.251606],
          [55.478678, 34.251885],
          [55.473955, 34.251055],
          [55.470622, 34.250775],
          [55.468678, 34.250775],
          [55.468122, 34.251055],
          [55.463955, 34.251055],
          [55.462845, 34.250775],
          [55.458399, 34.250775],
          [55.457565, 34.251055],
          [55.447842, 34.251055],
          [55.441175, 34.251326],
          [55.440622, 34.251606],
          [55.437289, 34.251606],
          [55.435899, 34.251886],
          [55.425899, 34.251886],
          [55.424232, 34.252165],
          [55.423399, 34.251886],
          [55.422009, 34.252165],
          [55.415066, 34.252165],
          [55.413399, 34.252445],
          [55.394229, 34.252445],
          [55.392839, 34.252165],
          [55.390619, 34.252165],
          [55.386729, 34.251606],
          [55.379506, 34.251606],
          [55.377563, 34.251886],
          [55.374506, 34.251886],
          [55.372563, 34.252165],
          [55.36645, 34.252165],
          [55.364506, 34.252445],
          [55.359783, 34.252445],
          [55.358116, 34.252165],
          [55.353673, 34.252165],
          [55.35256, 34.252445],
          [55.350893, 34.252445],
          [55.34034, 34.252716],
          [55.334503, 34.253275],
          [55.328393, 34.253275],
          [55.325894, 34.253555],
          [55.311727, 34.253555],
          [55.297557, 34.253826],
          [55.284224, 34.252716],
          [55.280891, 34.252716],
          [55.278667, 34.252445],
          [55.265334, 34.252445],
          [55.263114, 34.252165],
          [55.259224, 34.252165],
          [55.256724, 34.252445],
          [55.232555, 34.252445],
          [55.228111, 34.252716],
          [55.227001, 34.252996],
          [55.213388, 34.252996],
          [55.210888, 34.252716],
          [55.208388, 34.252716],
          [55.198389, 34.251326],
          [55.190889, 34.251326],
          [55.189499, 34.251606],
          [55.183109, 34.251606],
          [55.178385, 34.251055],
          [55.176165, 34.250496],
          [55.175332, 34.250496],
          [55.174219, 34.249945],
          [55.171166, 34.249386],
          [55.170052, 34.249386],
          [55.170052, 34.249665],
          [55.169499, 34.249386],
          [55.168385, 34.249386],
          [55.167781, 34.249183],
          [55.166162, 34.249106],
          [55.163662, 34.248555],
          [55.162829, 34.248276],
          [55.159776, 34.248276],
          [55.159496, 34.247996],
          [55.155609, 34.247996],
          [55.155329, 34.247716],
          [55.151996, 34.247716],
          [55.151442, 34.247445],
          [55.142552, 34.247445],
          [55.135886, 34.247445],
          [55.131996, 34.247716],
          [55.130886, 34.247445],
          [55.10866, 34.247445],
          [55.09616, 34.247716],
          [55.094773, 34.247996],
          [55.08616, 34.247996],
          [55.08505, 34.248276],
          [55.08366, 34.247996],
          [55.083103, 34.248276],
          [55.05477, 34.248276],
          [55.046437, 34.248826],
          [55.045047, 34.249106],
          [55.044214, 34.248826],
          [55.042824, 34.249106],
          [55.027547, 34.249106],
          [55.025881, 34.248826],
          [55.018934, 34.248555],
          [55.017547, 34.248826],
          [55.016714, 34.248555],
          [55.010877, 34.248826],
          [55.009211, 34.249106],
          [54.973098, 34.249106],
          [54.970878, 34.248826],
          [54.967265, 34.248826],
          [54.964488, 34.248555],
          [54.936985, 34.248555],
          [54.934208, 34.248276],
          [54.926432, 34.248276],
          [54.923932, 34.247996],
          [54.921152, 34.248276],
          [54.892819, 34.248276],
          [54.890039, 34.247996],
          [54.884486, 34.247996],
          [54.882539, 34.248276],
          [54.867539, 34.248276],
          [54.851983, 34.246886],
          [54.849483, 34.246886],
          [54.848373, 34.246606],
          [54.842536, 34.246606],
          [54.840316, 34.246326],
          [54.83476, 34.246326],
          [54.823093, 34.246326],
          [54.813647, 34.246326],
          [54.811427, 34.246055],
          [54.798923, 34.246055],
          [54.791704, 34.246326],
          [54.788924, 34.246606],
          [54.784757, 34.246606],
          [54.78198, 34.246886],
          [54.777534, 34.246886],
          [54.77448, 34.247166],
          [54.77031, 34.247166],
          [54.767534, 34.246886],
          [54.755867, 34.246886],
          [54.740867, 34.247716],
          [54.715588, 34.246055],
          [54.704755, 34.246056],
          [54.689475, 34.247716],
          [54.687808, 34.247996],
          [54.684198, 34.247996],
          [54.680585, 34.248276],
          [54.656419, 34.246326],
          [54.651139, 34.246326],
          [54.647805, 34.246606],
          [54.632805, 34.246606],
          [54.630582, 34.246886],
          [54.628362, 34.246886],
          [54.623362, 34.247716],
          [54.620582, 34.247716],
          [54.615582, 34.248556],
          [54.612806, 34.248826],
          [54.612526, 34.249106],
          [54.611692, 34.249106],
          [54.611416, 34.249106],
          [54.610306, 34.249106],
          [54.610026, 34.248826],
          [54.600859, 34.248826],
          [54.600026, 34.248555],
          [54.598916, 34.248826],
          [54.598359, 34.248556],
          [54.590583, 34.248826],
          [54.589193, 34.249106],
          [54.588636, 34.248826],
          [54.586969, 34.249106],
          [54.55669, 34.249106],
          [54.554746, 34.249386],
          [54.54447, 34.249386],
          [54.542523, 34.249666],
          [54.533357, 34.249666],
          [54.531133, 34.249945],
          [54.529747, 34.249666],
          [54.52419, 34.249666],
          [54.521967, 34.249386],
          [54.519743, 34.249386],
          [54.518634, 34.249106],
          [54.51419, 34.249106],
          [54.513077, 34.248826],
          [54.504467, 34.248826],
          [54.50252, 34.249106],
          [54.499744, 34.249106],
          [54.497801, 34.249386],
          [54.49502, 34.249386],
          [54.492801, 34.249666],
          [54.490854, 34.249666],
          [54.489467, 34.249945],
          [54.487244, 34.249945],
          [54.474464, 34.250496],
          [54.471687, 34.250776],
          [54.448351, 34.250776],
          [54.445575, 34.250496],
          [54.438631, 34.250496],
          [54.437241, 34.250216],
          [54.431408, 34.250216],
          [54.430018, 34.250496],
          [54.406685, 34.250496],
          [54.403905, 34.250216],
          [54.399462, 34.250216],
          [54.396962, 34.249945],
          [54.377795, 34.250216],
          [54.376405, 34.249945],
          [54.373905, 34.249945],
          [54.371405, 34.249666],
          [54.362792, 34.249666],
          [54.360849, 34.249945],
          [54.359384, 34.249946],
          [54.348069, 34.249945],
          [54.336126, 34.251056],
          [54.331679, 34.251056],
          [54.321126, 34.250216],
          [54.310013, 34.250216],
          [54.308623, 34.250496],
          [54.306123, 34.250496],
          [54.303623, 34.250776],
          [54.291956, 34.250776],
          [54.290846, 34.250496],
          [54.29029, 34.250776],
          [54.28918, 34.250496],
          [54.28779, 34.250496],
          [54.284457, 34.249666],
          [54.280847, 34.249666],
          [54.28029, 34.249666],
          [54.276677, 34.249666],
          [54.276123, 34.249945],
          [54.275843, 34.249666],
          [54.27529, 34.249945],
          [54.27501, 34.249666],
          [54.2664, 34.249666],
          [54.26279, 34.249106],
          [54.260287, 34.249106],
          [54.2589, 34.248826],
          [54.25751, 34.249106],
          [54.2539, 34.249106],
          [54.247787, 34.249386],
          [54.23973, 34.249386],
          [54.222507, 34.250216],
          [54.221674, 34.250496],
          [54.216397, 34.250496],
          [54.203897, 34.251326],
          [54.200284, 34.250776],
          [54.196398, 34.250776],
          [54.195841, 34.250496],
          [54.191398, 34.250496],
          [54.190841, 34.250216],
          [54.188341, 34.250216],
          [54.187784, 34.250216],
          [54.186951, 34.250216],
          [54.186508, 34.250496],
          [54.185284, 34.250496],
          [54.185284, 34.250216],
          [54.184174, 34.250216],
          [54.18417, 34.250212],
          [54.183894, 34.249945],
          [54.183061, 34.249945],
          [54.181674, 34.249386],
          [54.181118, 34.249386],
          [54.180841, 34.249106],
          [54.179451, 34.249106],
          [54.179174, 34.248826],
          [54.175008, 34.248826],
          [54.174451, 34.248556],
          [54.168618, 34.248826],
          [54.167785, 34.249106],
          [54.162785, 34.249106],
          [54.153338, 34.250216],
          [54.152785, 34.250496],
          [54.150562, 34.250496],
          [54.149452, 34.250776],
          [54.137228, 34.250776],
          [54.136672, 34.251056],
          [54.133062, 34.251056],
          [54.122225, 34.251606],
          [54.118059, 34.251606],
          [54.116115, 34.251886],
          [54.107502, 34.251886],
          [54.105282, 34.251606],
          [54.099725, 34.251606],
          [54.097782, 34.251326],
          [54.091669, 34.251326],
          [54.089726, 34.251056],
          [54.086946, 34.251056],
          [54.085002, 34.250776],
          [54.082226, 34.250776],
          [54.080559, 34.250496],
          [54.066389, 34.250496],
          [54.064446, 34.250216],
          [54.061669, 34.250216],
          [54.051113, 34.248556],
          [54.043889, 34.248556],
          [54.042499, 34.248276],
          [54.035833, 34.248276],
          [54.034446, 34.247996],
          [54.032776, 34.248276],
          [54.028056, 34.248276],
          [54.020833, 34.248826],
          [54.019166, 34.249106],
          [54.016667, 34.249106],
          [54.015, 34.249386],
          [54.012777, 34.249386],
          [54.011667, 34.249666],
          [54.01, 34.249666],
          [54.008887, 34.249945],
          [54.00472, 34.249945],
          [54.004443, 34.249666],
          [54.000277, 34.249106],
          [53.994163, 34.247996],
          [53.986387, 34.247996],
          [53.985554, 34.247716],
          [53.98083, 34.247716],
          [53.980277, 34.247446],
          [53.97722, 34.247445],
          [53.976664, 34.247166],
          [53.959164, 34.247166],
          [53.946664, 34.247716],
          [53.944164, 34.247996],
          [53.940831, 34.247996],
          [53.938884, 34.247716],
          [53.938051, 34.247996],
          [53.931941, 34.247996],
          [53.922218, 34.248276],
          [53.918604, 34.248276],
          [53.885271, 34.251326],
          [53.843602, 34.252716],
          [53.841379, 34.252445],
          [53.805546, 34.252445],
          [53.804432, 34.252716],
          [53.802213, 34.252445],
          [53.796379, 34.252445],
          [53.794156, 34.252166],
          [53.788043, 34.252166],
          [53.786933, 34.251886],
          [53.781099, 34.251886],
          [53.778876, 34.251606],
          [53.775543, 34.251606],
          [53.773323, 34.251326],
          [53.768043, 34.251326],
          [53.7661, 34.251055],
          [53.762766, 34.251056],
          [53.751377, 34.249945],
          [53.736097, 34.249945],
          [53.724987, 34.250216],
          [53.716654, 34.250216],
          [53.71443, 34.250496],
          [53.701097, 34.250496],
          [53.698317, 34.250216],
          [53.665538, 34.250216],
          [53.662761, 34.250496],
          [53.618868, 34.250496],
          [53.616648, 34.250776],
          [53.614701, 34.250776],
          [53.613591, 34.251056],
          [53.601925, 34.251056],
          [53.599425, 34.250776],
          [53.589978, 34.250776],
          [53.585812, 34.251326],
          [53.584978, 34.251055],
          [53.583035, 34.251326],
          [53.581369, 34.251056],
          [53.574979, 34.251056],
          [53.572479, 34.250776],
          [53.564422, 34.250776],
          [53.531366, 34.252716],
          [53.520253, 34.251326],
          [53.519143, 34.251326],
          [53.518866, 34.251606],
          [53.517476, 34.251606],
          [53.515533, 34.251606],
          [53.514699, 34.251886],
          [53.510533, 34.251886],
          [53.510253, 34.252166],
          [53.508309, 34.252166],
          [53.507753, 34.252445],
          [53.502753, 34.252445],
          [53.502196, 34.252166],
          [53.485806, 34.252166],
          [53.484973, 34.252445],
          [53.481087, 34.252445],
          [53.47942, 34.252166],
          [53.477753, 34.252166],
          [53.477197, 34.252445],
          [53.47553, 34.252166],
          [53.47414, 34.252166],
          [53.473587, 34.252445],
          [53.47192, 34.252166],
          [53.47053, 34.252445],
          [53.46664, 34.252445],
          [53.457473, 34.252716],
          [53.45553, 34.252996],
          [53.448584, 34.252996],
          [53.44664, 34.253276],
          [53.429694, 34.253276],
          [53.428027, 34.252996],
          [53.408861, 34.252996],
          [53.408304, 34.253276],
          [53.404414, 34.253276],
          [53.403027, 34.252996],
          [53.399138, 34.252996],
          [53.391081, 34.252166],
          [53.387748, 34.252166],
          [53.385248, 34.251886],
          [53.367191, 34.251886],
          [53.364691, 34.251606],
          [53.346358, 34.251606],
          [53.344135, 34.251886],
          [53.326355, 34.251886],
          [53.324412, 34.251606],
          [53.321912, 34.251606],
          [53.319968, 34.251886],
          [53.306632, 34.251886],
          [53.304965, 34.252166],
          [53.303855, 34.251886],
          [53.301912, 34.251886],
          [53.295799, 34.251606],
          [53.294132, 34.251606],
          [53.292742, 34.251326],
          [53.290522, 34.251326],
          [53.288856, 34.251055],
          [53.271076, 34.251055],
          [53.267466, 34.251606],
          [53.260243, 34.251606],
          [53.259409, 34.251326],
          [53.258019, 34.251326],
          [53.257186, 34.251055],
          [53.255519, 34.251055],
          [53.254963, 34.250776],
          [53.251909, 34.250776],
          [53.250796, 34.250496],
          [53.247186, 34.250496],
          [53.245796, 34.250216],
          [53.239129, 34.250216],
          [53.214963, 34.251326],
          [53.21385, 34.251606],
          [53.207463, 34.251055],
          [53.207183, 34.250776],
          [53.203573, 34.250776],
          [53.202183, 34.250776],
          [53.201627, 34.250496],
          [53.19385, 34.250496],
          [53.19274, 34.250776],
          [53.188017, 34.250776],
          [53.186907, 34.251055],
          [53.18357, 34.251055],
          [53.18107, 34.251326],
          [53.18107, 34.251606],
          [53.17996, 34.251606],
          [53.179403, 34.251886],
          [53.178293, 34.251886],
          [53.175517, 34.252716],
          [53.175237, 34.252996],
          [53.174127, 34.252996],
          [53.17357, 34.253276],
          [53.170237, 34.253276],
          [53.156904, 34.249106],
          [53.149681, 34.249106],
          [53.146347, 34.248826],
          [53.140791, 34.248826],
          [53.138847, 34.249106],
          [53.135237, 34.249106],
          [53.114958, 34.252445],
          [53.107178, 34.252716],
          [53.101625, 34.251886],
          [53.099958, 34.251886],
          [53.098845, 34.251886],
          [53.096068, 34.251886],
          [53.095511, 34.252166],
          [53.091625, 34.252165],
          [53.091068, 34.251886],
          [53.085788, 34.251886],
          [53.085235, 34.251606],
          [53.068012, 34.251606],
          [53.066622, 34.251326],
          [53.055512, 34.251326],
          [53.054678, 34.251606],
          [53.050508, 34.251606],
          [53.048842, 34.251886],
          [53.038289, 34.251886],
          [53.028842, 34.252165],
          [53.021619, 34.252165],
          [53.019676, 34.252445],
          [52.998009, 34.252445],
          [52.996062, 34.252165],
          [52.975229, 34.252165],
          [52.973839, 34.251886],
          [52.972449, 34.252165],
          [52.970783, 34.251886],
          [52.969673, 34.252165],
          [52.95606, 34.252165],
          [52.95384, 34.251886],
          [52.950506, 34.251886],
          [52.929393, 34.249945],
          [52.918837, 34.249945],
          [52.913837, 34.249386],
          [52.91106, 34.249386],
          [52.909394, 34.249106],
          [52.905504, 34.249106],
          [52.904394, 34.248826],
          [52.899947, 34.248826],
          [52.898557, 34.248555],
          [52.89217, 34.248555],
          [52.886337, 34.248826],
          [52.885224, 34.249106],
          [52.884114, 34.249106],
          [52.883837, 34.249386],
          [52.882724, 34.249386],
          [52.882167, 34.249386],
          [52.882167, 34.249106],
          [52.880501, 34.249106],
          [52.867447, 34.257445],
          [52.864947, 34.259945],
          [52.862168, 34.262445],
          [52.862168, 34.262996],
          [52.841611, 34.283004],
          [52.816331, 34.298058],
          [52.815498, 34.298554],
          [52.814945, 34.298834],
          [52.814945, 34.299114],
          [52.767719, 34.323002],
          [52.766609, 34.323833],
          [52.761052, 34.326612],
          [52.748552, 34.333273],
          [52.677993, 34.36439],
          [52.67327, 34.36661],
          [52.64577, 34.377448],
          [52.609657, 34.387447],
          [52.579378, 34.399946],
          [52.579098, 34.400226],
          [52.578264, 34.400226],
          [52.577711, 34.400506],
          [52.576321, 34.400776],
          [52.566878, 34.404115],
          [52.565764, 34.404115],
          [52.563544, 34.404946],
          [52.516318, 34.414665],
          [52.502512, 34.418413],
          [52.499929, 34.419114],
          [52.488819, 34.421063],
          [52.482982, 34.421884],
          [52.482149, 34.422164],
          [52.479649, 34.422164],
          [52.459092, 34.424944],
          [52.456316, 34.425503],
          [52.453259, 34.425503],
          [52.450482, 34.426054],
          [52.448536, 34.426053],
          [52.447426, 34.426333],
          [52.444649, 34.426333],
          [52.444093, 34.426613],
          [52.436869, 34.426613],
          [52.430759, 34.427443],
          [52.429646, 34.427173],
          [52.417426, 34.427173],
          [52.416036, 34.427443],
          [52.410203, 34.428274],
          [52.409646, 34.428553],
          [52.40909, 34.428274],
          [52.40687, 34.428273],
          [52.406036, 34.428273],
          [52.40548, 34.428553],
          [52.40409, 34.428553],
          [52.403813, 34.428833],
          [52.402287, 34.429113],
          [52.399923, 34.429113],
          [52.39937, 34.429392],
          [52.39548, 34.429392],
          [52.395203, 34.429113],
          [52.39437, 34.429393],
          [52.39298, 34.429113],
          [52.388533, 34.428833],
          [52.379923, 34.428003],
          [52.378813, 34.427723],
          [52.376867, 34.427723],
          [52.354644, 34.424393],
          [52.3527, 34.424393],
          [52.332421, 34.420504],
          [52.331311, 34.420504],
          [52.329644, 34.419944],
          [52.328531, 34.419944],
          [52.312974, 34.415504],
          [52.302974, 34.414114],
          [52.291584, 34.411894],
          [52.287418, 34.411335],
          [52.286865, 34.411055],
          [52.285474, 34.410775],
          [52.284085, 34.410495],
          [52.280198, 34.410225],
          [52.261585, 34.407445],
          [52.259638, 34.407445],
          [52.253528, 34.406606],
          [52.247418, 34.405505],
          [52.232972, 34.403556],
          [52.230749, 34.403556],
          [52.229362, 34.403276],
          [52.225472, 34.403276],
          [52.224082, 34.402996],
          [52.221859, 34.402996],
          [52.217972, 34.402446],
          [52.214639, 34.402446],
          [52.213525, 34.402166],
          [52.208249, 34.402166],
          [52.206582, 34.401895],
          [52.191582, 34.401895],
          [52.190749, 34.401615],
          [52.187692, 34.401616],
          [52.187136, 34.401336],
          [52.183802, 34.401336],
          [52.182969, 34.401056],
          [52.179359, 34.401056],
          [52.178246, 34.401336],
          [52.175193, 34.401336],
          [52.173803, 34.401616],
          [52.161427, 34.401616],
          [52.160746, 34.401615],
          [52.158523, 34.401336],
          [52.155189, 34.401336],
          [52.15297, 34.401615],
          [52.149356, 34.401615],
          [52.145989, 34.402187],
          [52.14408, 34.402446],
          [52.142413, 34.402446],
          [52.1413, 34.402725],
          [52.139633, 34.402725],
          [52.135466, 34.403556],
          [52.134356, 34.403556],
          [52.130746, 34.404666],
          [52.129913, 34.404666],
          [52.129356, 34.404666],
          [52.121577, 34.407165],
          [52.120743, 34.407165],
          [52.12019, 34.407445],
          [52.1188, 34.407445],
          [52.118523, 34.407725],
          [52.116857, 34.407725],
          [52.10991, 34.409674],
          [52.1088, 34.409674],
          [52.107133, 34.410225],
          [52.106577, 34.410225],
          [52.10241, 34.411335],
          [52.067964, 34.419393],
          [52.066574, 34.419393],
          [52.055741, 34.422444],
          [52.015741, 34.430502],
          [52.014628, 34.430502],
          [51.977682, 34.442441],
          [51.967125, 34.446331],
          [51.906569, 34.472446],
          [51.877956, 34.482725],
          [51.877676, 34.483004],
          [51.873233, 34.484944],
          [51.861566, 34.488834],
          [51.861286, 34.489393],
          [51.8599, 34.489953],
          [51.858786, 34.489953],
          [51.856843, 34.490503],
          [51.83462, 34.505501],
          [51.83462, 34.505781],
          [51.834168, 34.505956],
          [51.83212, 34.505501],
          [51.82851, 34.505501],
          [51.809897, 34.509446],
          [51.800782, 34.510984],
          [51.716838, 34.498561],
          [51.714338, 34.498002],
          [51.712948, 34.498002],
          [51.710448, 34.497451],
          [51.707668, 34.497172],
          [51.694892, 34.495223],
          [51.683778, 34.494113],
          [51.681002, 34.494113],
          [51.679059, 34.493833],
          [51.668222, 34.492723],
          [51.633776, 34.488003],
          [51.632666, 34.487453],
          [51.631832, 34.487453],
          [51.630443, 34.487173],
          [51.629333, 34.486614],
          [51.628499, 34.486614],
          [51.606276, 34.482174],
          [51.604609, 34.482174],
          [51.58322, 34.477725],
          [51.572663, 34.476056],
          [51.515437, 34.469107],
          [51.506213, 34.46835],
          [51.505437, 34.468286],
          [51.501827, 34.468286],
          [51.438768, 34.458558],
          [51.432102, 34.457168],
          [51.412379, 34.454109],
          [51.405432, 34.452728],
          [51.390709, 34.450229],
          [51.379599, 34.448],
          [51.325986, 34.44106],
          [51.323561, 34.44106],
          [51.322096, 34.44161],
          [51.312096, 34.44134],
          [51.31154, 34.44161],
          [51.311049, 34.44161],
          [51.31015, 34.44161],
          [51.306816, 34.44217],
          [51.30015, 34.44272],
          [51.29626, 34.44272],
          [51.294593, 34.443],
          [51.292093, 34.443],
          [51.28654, 34.443839],
          [51.256814, 34.446059],
          [51.231538, 34.44661],
          [51.230981, 34.44633],
          [51.209591, 34.4455],
          [51.202091, 34.44522],
          [51.197368, 34.44522],
          [51.195978, 34.44494],
          [51.188758, 34.444669],
          [51.180702, 34.444669],
          [51.179312, 34.44439],
          [51.177368, 34.44439],
          [51.147365, 34.439111],
          [51.145975, 34.438551],
          [51.143199, 34.437721],
          [51.127365, 34.432721],
          [51.122919, 34.430222],
          [51.120142, 34.428552],
          [51.103196, 34.421062],
          [51.101809, 34.420782],
          [51.101253, 34.420782],
          [51.100142, 34.420502],
          [51.081806, 34.413003],
          [51.067916, 34.408004],
          [51.06153, 34.404114],
          [51.02125, 34.382447],
          [51.019027, 34.380217],
          [51.01847, 34.379667],
          [51.011804, 34.372447],
          [51.011527, 34.372168],
          [51.003194, 34.35327],
          [51.002637, 34.34272],
          [51.002914, 34.3405],
          [51.003194, 34.34022],
          [51.003194, 34.339381],
          [51.003747, 34.337441],
          [51.004304, 34.336051],
          [51.004304, 34.334941],
          [51.00486, 34.334111],
          [51.010137, 34.316882],
          [51.010414, 34.316052],
          [51.013194, 34.302993],
          [51.01347, 34.298832],
          [51.014584, 34.294112],
          [51.014027, 34.287443],
          [51.01211, 34.279489],
          [51.008471, 34.264384],
          [51.004861, 34.259384],
          [51.003747, 34.259104],
          [51.003471, 34.258274],
          [51.003194, 34.256884],
          [50.999861, 34.252164],
          [50.994861, 34.234664],
          [50.989574, 34.227708],
          [50.989304, 34.227435],
          [50.989024, 34.226884],
          [50.985691, 34.223554],
          [50.963748, 34.206054],
          [50.960968, 34.203834],
          [50.939302, 34.189934],
          [50.922635, 34.171324],
          [50.921245, 34.169383],
          [50.918188, 34.165493],
          [50.910412, 34.152993],
          [50.908189, 34.151883],
          [50.918189, 34.148272],
          [50.918465, 34.147993],
          [50.918745, 34.147713],
          [50.919302, 34.147713],
          [50.921522, 34.145493],
          [50.921802, 34.144653],
          [50.922912, 34.143552],
          [50.923188, 34.142713],
          [50.923745, 34.142442],
          [50.924022, 34.141602],
          [50.951802, 34.11494],
          [50.951801, 34.114661],
          [50.952635, 34.112431],
          [50.952911, 34.111601],
          [50.961248, 34.10522],
          [50.968748, 34.097439],
          [50.969301, 34.097439],
          [50.980691, 34.0916],
          [50.981524, 34.090769],
          [50.983748, 34.088269],
          [50.983748, 34.087989],
          [50.985971, 34.084938],
          [50.993191, 34.081328],
          [50.994024, 34.081328],
          [50.994304, 34.081048],
          [50.999861, 34.079658],
          [51.000023, 34.078891],
          [51.000414, 34.077158],
          [51.001804, 34.076608],
          [51.020965, 34.065491],
          [51.021251, 34.065217],
          [51.021804, 34.065217],
          [51.024695, 34.063547],
          [51.041289, 34.048193],
          [51.052648, 34.038727],
          [51.050657, 34.032355],
          [51.044464, 34.02552],
          [51.036002, 34.014617],
          [51.033981, 34.003195],
          [51.020619, 33.994852],
          [51.020036, 33.981835],
          [51.021251, 33.978827],
          [51.021528, 33.978547],
          [51.022917, 33.972987],
          [51.025418, 33.967706],
          [51.024304, 33.958265],
          [51.025418, 33.955764],
          [51.027361, 33.953544],
          [51.035141, 33.949383],
          [51.035417, 33.949103],
          [51.038751, 33.946323],
          [51.039027, 33.946043],
          [51.048474, 33.936872],
          [51.048751, 33.936601],
          [51.048751, 33.934931],
          [51.048751, 33.934791],
          [51.048407, 33.933056],
          [51.048194, 33.92882],
          [51.045141, 33.923269],
          [51.044946, 33.919793],
          [51.044861, 33.918268],
          [51.045417, 33.916048],
          [51.046023, 33.914733],
          [51.047084, 33.912428],
          [51.047641, 33.912157],
          [51.047641, 33.911597],
          [51.048154, 33.910763],
          [51.049861, 33.907987],
          [51.049584, 33.907716],
          [51.048194, 33.902715],
          [51.047361, 33.902435],
          [51.046807, 33.902435],
          [51.043474, 33.898816],
          [51.042641, 33.896324],
          [51.039027, 33.888263],
          [51.039307, 33.886873],
          [51.040141, 33.882712],
          [51.041527, 33.876321],
          [51.041807, 33.87549],
          [51.037918, 33.862709],
          [51.037361, 33.862158],
          [51.035974, 33.860208],
          [51.032084, 33.852986],
          [51.032638, 33.849656],
          [51.035141, 33.846596],
          [51.036251, 33.846045],
          [51.048474, 33.833822],
          [51.048474, 33.833263],
          [51.050417, 33.829652],
          [51.050694, 33.827982],
          [51.050974, 33.827431],
          [51.049584, 33.82021],
          [51.048751, 33.81882],
          [51.046807, 33.815768],
          [51.023751, 33.786042],
          [51.009305, 33.77298],
          [50.999862, 33.767708],
          [50.991248, 33.764097],
          [50.989305, 33.763537],
          [50.979025, 33.757986],
          [50.976248, 33.754095],
          [50.974582, 33.749094],
          [50.967082, 33.742703],
          [50.965969, 33.741872],
          [50.954579, 33.73493],
          [50.954302, 33.73465],
          [50.953745, 33.73465],
          [50.903746, 33.710484],
          [50.89569, 33.708263],
          [50.89291, 33.707424],
          [50.892633, 33.707153],
          [50.8918, 33.707153],
          [50.887076, 33.705762],
          [50.88569, 33.705763],
          [50.885133, 33.705483],
          [50.882076, 33.705483],
          [50.8818, 33.705203],
          [50.880133, 33.705203],
          [50.879576, 33.704923],
          [50.878186, 33.704923],
          [50.8768, 33.705203],
          [50.873466, 33.705203],
          [50.872633, 33.704923],
          [50.866243, 33.704372],
          [50.862077, 33.704372],
          [50.86152, 33.704652],
          [50.849577, 33.705762],
          [50.837907, 33.706873],
          [50.83263, 33.707983],
          [50.83013, 33.707983],
          [50.829297, 33.708263],
          [50.825407, 33.708263],
          [50.82485, 33.708543],
          [50.823184, 33.708814],
          [50.820407, 33.708814],
          [50.820131, 33.709094],
          [50.819574, 33.709094],
          [50.819297, 33.709373],
          [50.818464, 33.709373],
          [50.818184, 33.709653],
          [50.807074, 33.711874],
          [50.803184, 33.712984],
          [50.802074, 33.713535],
          [50.776238, 33.722427],
          [50.775128, 33.722427],
          [50.774571, 33.722707],
          [50.773738, 33.722707],
          [50.772904, 33.722987],
          [50.769851, 33.723537],
          [50.767348, 33.723537],
          [50.767071, 33.723817],
          [50.764848, 33.723817],
          [50.762628, 33.724368],
          [50.759295, 33.724368],
          [50.758738, 33.724368],
          [50.758461, 33.724097],
          [50.754848, 33.723257],
          [50.754015, 33.723537],
          [50.752628, 33.723537],
          [50.752071, 33.723817],
          [50.744571, 33.723817],
          [50.744291, 33.724097],
          [50.742072, 33.724368],
          [50.738182, 33.724368],
          [50.737905, 33.724648],
          [50.737072, 33.724648],
          [50.735125, 33.725207],
          [50.733738, 33.725207],
          [50.730402, 33.726318],
          [50.729292, 33.726318],
          [50.724292, 33.727708],
          [50.718735, 33.727708],
          [50.715682, 33.726598],
          [50.704012, 33.727148],
          [50.700679, 33.726318],
          [50.690402, 33.720766],
          [50.659566, 33.69659],
          [50.655676, 33.69576],
          [50.64651, 33.691869],
          [50.642623, 33.689648],
          [50.62901, 33.679646],
          [50.612896, 33.671873],
          [50.61151, 33.670763],
          [50.609563, 33.669923],
          [50.608453, 33.669093],
          [50.607063, 33.668542],
          [50.595673, 33.66215],
          [50.593454, 33.66048],
          [50.588174, 33.654928],
          [50.58762, 33.654648],
          [50.587341, 33.654368],
          [50.587064, 33.654089],
          [50.586787, 33.653818],
          [50.586787, 33.653538],
          [50.583174, 33.646036],
          [50.57984, 33.643536],
          [50.577617, 33.636593],
          [50.577897, 33.636313],
          [50.577617, 33.634093],
          [50.575674, 33.631592],
          [50.57484, 33.631312],
          [50.574284, 33.630761],
          [50.563174, 33.628261],
          [50.561231, 33.628261],
          [50.560117, 33.627981],
          [50.558727, 33.628261],
          [50.542894, 33.629931],
          [50.537618, 33.630482],
          [50.529284, 33.633253],
          [50.528171, 33.633253],
          [50.526785, 33.633813],
          [50.525671, 33.633813],
          [50.525118, 33.634093],
          [50.512614, 33.636034],
          [50.483725, 33.634363],
          [50.473725, 33.631312],
          [50.469558, 33.631312],
          [50.468725, 33.631032],
          [50.467335, 33.631032],
          [50.466782, 33.631312],
          [50.465669, 33.631312],
          [50.464279, 33.631872],
          [50.459835, 33.632702],
          [50.457335, 33.633253],
          [50.455945, 33.633253],
          [50.455669, 33.633542],
          [50.455112, 33.633542],
          [50.454002, 33.634363],
          [50.453725, 33.634652],
          [50.452335, 33.633813],
          [50.449559, 33.633253],
          [50.444835, 33.631592],
          [50.432056, 33.62465],
          [50.425389, 33.6227],
          [50.422056, 33.622429],
          [50.421499, 33.6227],
          [50.420666, 33.622429],
          [50.419279, 33.622429],
          [50.418166, 33.622149],
          [50.416499, 33.622149],
          [50.415666, 33.621869],
          [50.414279, 33.621869],
          [50.413446, 33.621589],
          [50.411779, 33.621589],
          [50.411223, 33.621309],
          [50.406776, 33.621038],
          [50.400666, 33.621038],
          [50.400109, 33.620759],
          [50.399276, 33.621038],
          [50.398723, 33.620759],
          [50.393443, 33.620758],
          [50.392889, 33.620479],
          [50.390943, 33.620479],
          [50.388443, 33.620479],
          [50.388443, 33.620199],
          [50.386499, 33.619928],
          [50.384274, 33.619926],
          [50.384, 33.619648],
          [50.383166, 33.619928],
          [50.382333, 33.619928],
          [50.382053, 33.619648],
          [50.380943, 33.619648],
          [50.38011, 33.619368],
          [50.378719, 33.619368],
          [50.378166, 33.619648],
          [50.377053, 33.619648],
          [50.368163, 33.617707],
          [50.366776, 33.617707],
          [50.365943, 33.617427],
          [50.360943, 33.617427],
          [50.35983, 33.617147],
          [50.357887, 33.617147],
          [50.357053, 33.616868],
          [50.353443, 33.616868],
          [50.352607, 33.616588],
          [50.348164, 33.616317],
          [50.347607, 33.616588],
          [50.337054, 33.615757],
          [50.337053, 33.615477],
          [50.331217, 33.613536],
          [50.328163, 33.609925],
          [50.326774, 33.607145],
          [50.32594, 33.604924],
          [50.318441, 33.595761],
          [50.316497, 33.59465],
          [50.295104, 33.584927],
          [50.294271, 33.584927],
          [50.293718, 33.584647],
          [50.292884, 33.584647],
          [50.292328, 33.584368],
          [50.289551, 33.584368],
          [50.288994, 33.584088],
          [50.278718, 33.582977],
          [50.275104, 33.582697],
          [50.273161, 33.582427],
          [50.261495, 33.583537],
          [50.258995, 33.584367],
          [50.256214, 33.586588],
          [50.248715, 33.600482],
          [50.243438, 33.604373],
          [50.240938, 33.604644],
          [50.240382, 33.604373],
          [50.238991, 33.604373],
          [50.238158, 33.604093],
          [50.235381, 33.603533],
          [50.232882, 33.602703],
          [50.230658, 33.600202],
          [50.228715, 33.593251],
          [50.225935, 33.588809],
          [50.215378, 33.579926],
          [50.211212, 33.577705],
          [50.210102, 33.575755],
          [50.209825, 33.574374],
          [50.209825, 33.570753],
          [50.209545, 33.568533],
          [50.204825, 33.55964],
          [50.203712, 33.556038],
          [50.203435, 33.552427],
          [50.203992, 33.550757],
          [50.20469, 33.549595],
          [50.206492, 33.546595],
          [50.206492, 33.544645],
          [50.206492, 33.544094],
          [50.205659, 33.542424],
          [50.191769, 33.53104],
          [50.190655, 33.53104],
          [50.188435, 33.53048],
          [50.186769, 33.53048],
          [50.185936, 33.5302],
          [50.183989, 33.5302],
          [50.183156, 33.52992],
          [50.176213, 33.52853],
          [50.149266, 33.522978],
          [50.143153, 33.520477],
          [50.138709, 33.519087],
          [50.09732, 33.506863],
          [50.08454, 33.504922],
          [50.079817, 33.504371],
          [50.078984, 33.504082],
          [50.076484, 33.503811],
          [50.073707, 33.503811],
          [50.072597, 33.503531],
          [50.071207, 33.503531],
          [50.054541, 33.50103],
          [50.052594, 33.50103],
          [50.051484, 33.500751],
          [50.034261, 33.499649],
          [50.028428, 33.499649],
          [50.027038, 33.49936],
          [49.987315, 33.494088],
          [49.985368, 33.492977],
          [49.983702, 33.492418],
          [49.974535, 33.480194],
          [49.973425, 33.477142],
          [49.973425, 33.476582],
          [49.973145, 33.475752],
          [49.972035, 33.467699],
          [49.972312, 33.466308],
          [49.990368, 33.440199],
          [49.990925, 33.435197],
          [49.991202, 33.433256],
          [49.991482, 33.432696],
          [49.990368, 33.419921],
          [49.989815, 33.419641],
          [49.985648, 33.417141],
          [49.982592, 33.417141],
          [49.982315, 33.416861],
          [49.981202, 33.416861],
          [49.980925, 33.416581],
          [49.978425, 33.41631],
          [49.974812, 33.41631],
          [49.974535, 33.41603],
          [49.968702, 33.41603],
          [49.965645, 33.417141],
          [49.963702, 33.417141],
          [49.962592, 33.416861],
          [49.960646, 33.41631],
          [49.959812, 33.41575],
          [49.959259, 33.413529],
          [49.957869, 33.407977],
          [49.950089, 33.395193],
          [49.947869, 33.38742],
          [49.948422, 33.38714],
          [49.948702, 33.38658],
          [49.951479, 33.383528],
          [49.951479, 33.382978],
          [49.952036, 33.382698],
          [49.962036, 33.376306],
          [49.976758, 33.372144],
          [49.990369, 33.373534],
          [49.995925, 33.374915],
          [49.997592, 33.374915],
          [49.998705, 33.375195],
          [50.002315, 33.375475],
          [50.002871, 33.375475],
          [50.003425, 33.375755],
          [50.006482, 33.375755],
          [50.010092, 33.374365],
          [50.010648, 33.373805],
          [50.015371, 33.371584],
          [50.018148, 33.368532],
          [50.019815, 33.364081],
          [50.019538, 33.36159],
          [50.018428, 33.36019],
          [50.016762, 33.36047],
          [50.016205, 33.36019],
          [50.013982, 33.359359],
          [50.013705, 33.358809],
          [50.012592, 33.357969],
          [50.007038, 33.348805],
          [50.005925, 33.344083],
          [49.995925, 33.323246],
          [49.995372, 33.316303],
          [49.995649, 33.315472],
          [49.995925, 33.314641],
          [49.996759, 33.312971],
          [49.998149, 33.31103],
          [49.998425, 33.31047],
          [50.000372, 33.307419],
          [50.000092, 33.306859],
          [50.000092, 33.306028],
          [49.998705, 33.303527],
          [49.997039, 33.301577],
          [49.970926, 33.280748],
          [49.967869, 33.279078],
          [49.967312, 33.278247],
          [49.962036, 33.270474],
          [49.961479, 33.269914],
          [49.959536, 33.268803],
          [49.958979, 33.268524],
          [49.957869, 33.267693],
          [49.957036, 33.267413],
          [49.947312, 33.257409],
          [49.946479, 33.249916],
          [49.945089, 33.248805],
          [49.942869, 33.248805],
          [49.942313, 33.249085],
          [49.940923, 33.249085],
          [49.937589, 33.247144],
          [49.935923, 33.242413],
          [49.936199, 33.241582],
          [49.938423, 33.239361],
          [49.93898, 33.239361],
          [49.938979, 33.239081],
          [49.939536, 33.239081],
          [49.940089, 33.238801],
          [49.941756, 33.237691],
          [49.942313, 33.23714],
          [49.946203, 33.234359],
          [49.947313, 33.232689],
          [49.947036, 33.229917],
          [49.946479, 33.229917],
          [49.944256, 33.228806],
          [49.943703, 33.229077],
          [49.939256, 33.229077],
          [49.938423, 33.229357],
          [49.932866, 33.229357],
          [49.932033, 33.229637],
          [49.929813, 33.229637],
          [49.92898, 33.229917],
          [49.92509, 33.230188],
          [49.920646, 33.230188],
          [49.91509, 33.228527],
          [49.90509, 33.224084],
          [49.89981, 33.221854],
          [49.898977, 33.221854],
          [49.89481, 33.221024],
          [49.889534, 33.219633],
          [49.887587, 33.217972],
          [49.87731, 33.204077],
          [49.87703, 33.202966],
          [49.871477, 33.191301],
          [49.871197, 33.187689],
          [49.872587, 33.182688],
          [49.88092, 33.171853],
          [49.88231, 33.168521],
          [49.88842, 33.151863],
          [49.8887, 33.150753],
          [49.890367, 33.144911],
          [49.890644, 33.144631],
          [49.890367, 33.1438],
          [49.88981, 33.14074],
          [49.889254, 33.139909],
          [49.8887, 33.139909],
          [49.88842, 33.139629],
          [49.880364, 33.137968],
          [49.877587, 33.134907],
          [49.87703, 33.134085],
          [49.876754, 33.132135],
          [49.877587, 33.130194],
          [49.877864, 33.129634],
          [49.878977, 33.128524],
          [49.878977, 33.128244],
          [49.87953, 33.127973],
          [49.9187, 33.100471],
          [49.91898, 33.099631],
          [49.919813, 33.09713],
          [49.920367, 33.096299],
          [49.9212, 33.094358],
          [49.921757, 33.094078],
          [49.922033, 33.093239],
          [49.922867, 33.092417],
          [49.923147, 33.091577],
          [49.92398, 33.090187],
          [49.924257, 33.089636],
          [49.922033, 33.073249],
          [49.92148, 33.072138],
          [49.91481, 33.065745],
          [49.914257, 33.065746],
          [49.910367, 33.064075],
          [49.909534, 33.063804],
          [49.908423, 33.063804],
          [49.907034, 33.063244],
          [49.905367, 33.063244],
          [49.90481, 33.062965],
          [49.9037, 33.062965],
          [49.901477, 33.062414],
          [49.900087, 33.062414],
          [49.898977, 33.062134],
          [49.897587, 33.062134],
          [49.896754, 33.061854],
          [49.895644, 33.061854],
          [49.89481, 33.062134],
          [49.892867, 33.062134],
          [49.892587, 33.062414],
          [49.871477, 33.069077],
          [49.869811, 33.069077],
          [49.868977, 33.069357],
          [49.865364, 33.069357],
          [49.864811, 33.069077],
          [49.863698, 33.069077],
          [49.859808, 33.066856],
          [49.858974, 33.065745],
          [49.857031, 33.062965],
          [49.856474, 33.062414],
          [49.854254, 33.057132],
          [49.849808, 33.05352],
          [49.848974, 33.05352],
          [49.841198, 33.050188],
          [49.839808, 33.050188],
          [49.838974, 33.049908],
          [49.837308, 33.049908],
          [49.836474, 33.050188],
          [49.834531, 33.050188],
          [49.809808, 33.056581],
          [49.785639, 33.061023],
          [49.782862, 33.061574],
          [49.780915, 33.061574],
          [49.780362, 33.061854],
          [49.779529, 33.061854],
          [49.766192, 33.064355],
          [49.762026, 33.066025],
          [49.754806, 33.067966],
          [49.745635, 33.071578],
          [49.744526, 33.071578],
          [49.742582, 33.072138],
          [49.737302, 33.073799],
          [49.728136, 33.082693],
          [49.726192, 33.083523],
          [49.724802, 33.084074],
          [49.724246, 33.084354],
          [49.722026, 33.084354],
          [49.718834, 33.08227],
          [49.717859, 33.081582],
          [49.712579, 33.07519],
          [49.70369, 33.071578],
          [49.701746, 33.071578],
          [49.70119, 33.071858],
          [49.69897, 33.071858],
          [49.698413, 33.071578],
          [49.696746, 33.071578],
          [49.69647, 33.071298],
          [49.695636, 33.071298],
          [49.690913, 33.069908],
          [49.685633, 33.066305],
          [49.6798, 33.056852],
          [49.671743, 33.051299],
          [49.668967, 33.050188],
          [49.66591, 33.043245],
          [49.665634, 33.040193],
          [49.66591, 33.037132],
          [49.66869, 33.027408],
          [49.667857, 33.022135],
          [49.655634, 33.014072],
          [49.653967, 33.01158],
          [49.653967, 33.010469],
          [49.653687, 33.010189],
          [49.655077, 33.007408],
          [49.664523, 33.001855],
          [49.670633, 32.998795],
          [49.683413, 32.994073],
          [49.68619, 32.993522],
          [49.691747, 32.993522],
          [49.692023, 32.993802],
          [49.69897, 32.993802],
          [49.699246, 32.993522],
          [49.699803, 32.993522],
          [49.700357, 32.992962],
          [49.700913, 32.992962],
          [49.704803, 32.99019],
          [49.705913, 32.9888],
          [49.705913, 32.98852],
          [49.70647, 32.987409],
          [49.70758, 32.984637],
          [49.70758, 32.984357],
          [49.70758, 32.983797],
          [49.70758, 32.983518],
          [49.707856, 32.983247],
          [49.707856, 32.982407],
          [49.70508, 32.980186],
          [49.699803, 32.977965],
          [49.695913, 32.977965],
          [49.695357, 32.977685],
          [49.694803, 32.977965],
          [49.69369, 32.977685],
          [49.686467, 32.975744],
          [49.685357, 32.974904],
          [49.684246, 32.973522],
          [49.680357, 32.971292],
          [49.67869, 32.971021],
          [49.678414, 32.970741],
          [49.6773, 32.970741],
          [49.676467, 32.970461],
          [49.672577, 32.970461],
          [49.672024, 32.970741],
          [49.670357, 32.970741],
          [49.65091, 32.978244],
          [49.645354, 32.978795],
          [49.641744, 32.978795],
          [49.640911, 32.978515],
          [49.640634, 32.978245],
          [49.640077, 32.978244],
          [49.639521, 32.977685],
          [49.637577, 32.977414],
          [49.637577, 32.976854],
          [49.637301, 32.974633],
        ],
      ],
    },
  },
  Kerman: {
    properties: { value: "Kerman", name: "استان کرمان, ایران", id: 23 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [54.336682, 30.380966],
          [54.340848, 30.375407],
          [54.344104, 30.371608],
          [54.344182, 30.371518],
          [54.357515, 30.357352],
          [54.392794, 30.319572],
          [54.395571, 30.316242],
          [54.403071, 30.307075],
          [54.426684, 30.286791],
          [54.42835, 30.285961],
          [54.433907, 30.282352],
          [54.439879, 30.276934],
          [54.440297, 30.276794],
          [54.44113, 30.275964],
          [54.441684, 30.275684],
          [54.520299, 30.221242],
          [54.521966, 30.219573],
          [54.528356, 30.213735],
          [54.529189, 30.212346],
          [54.540579, 30.198461],
          [54.543355, 30.193182],
          [54.545022, 30.189294],
          [54.547802, 30.178188],
          [54.547522, 30.176239],
          [54.547245, 30.175679],
          [54.548079, 30.160125],
          [54.547802, 30.159575],
          [54.550855, 30.142902],
          [54.552245, 30.115954],
          [54.552245, 30.10207],
          [54.551969, 30.100401],
          [54.550302, 30.071793],
          [54.550022, 30.049565],
          [54.549745, 30.041788],
          [54.547522, 30.015121],
          [54.547245, 30.011793],
          [54.546412, 30.004846],
          [54.546689, 30.000047],
          [54.540855, 29.983737],
          [54.539746, 29.976512],
          [54.540022, 29.966228],
          [54.539465, 29.959841],
          [54.539745, 29.948457],
          [54.541965, 29.929568],
          [54.542245, 29.91095],
          [54.541412, 29.897338],
          [54.541132, 29.896508],
          [54.541412, 29.893171],
          [54.541132, 29.891782],
          [54.541689, 29.875401],
          [54.542522, 29.867617],
          [54.542522, 29.860121],
          [54.542799, 29.859562],
          [54.543912, 29.852896],
          [54.545022, 29.845391],
          [54.545302, 29.844841],
          [54.547802, 29.834288],
          [54.548912, 29.823175],
          [54.548912, 29.817619],
          [54.549189, 29.813732],
          [54.548912, 29.812893],
          [54.551135, 29.784842],
          [54.550855, 29.783733],
          [54.552802, 29.761779],
          [54.553635, 29.748449],
          [54.556968, 29.731502],
          [54.557245, 29.727335],
          [54.558355, 29.70817],
          [54.558912, 29.706222],
          [54.559468, 29.701505],
          [54.561135, 29.692062],
          [54.561412, 29.687336],
          [54.561968, 29.667892],
          [54.562522, 29.654842],
          [54.562245, 29.651776],
          [54.561968, 29.650667],
          [54.562245, 29.629834],
          [54.561968, 29.627056],
          [54.561412, 29.626497],
          [54.562245, 29.625947],
          [54.563078, 29.625668],
          [54.566412, 29.624279],
          [54.571135, 29.62289],
          [54.584748, 29.616785],
          [54.591691, 29.614837],
          [54.592074, 29.614837],
          [54.592801, 29.614837],
          [54.615304, 29.6115],
          [54.618637, 29.611229],
          [54.631414, 29.6115],
          [54.631933, 29.611328],
          [54.633914, 29.61067],
          [54.634385, 29.61067],
          [54.635304, 29.61067],
          [54.635465, 29.610507],
          [54.635581, 29.610391],
          [54.636381, 29.610391],
          [54.636971, 29.610391],
          [54.638637, 29.610111],
          [54.640304, 29.610111],
          [54.643637, 29.610111],
          [54.644194, 29.610391],
          [54.650584, 29.610391],
          [54.65225, 29.61067],
          [54.655027, 29.61067],
          [54.661974, 29.6115],
          [54.66475, 29.6115],
          [54.66864, 29.612059],
          [54.673917, 29.612059],
          [54.675027, 29.612339],
          [54.680863, 29.612339],
          [54.683083, 29.612618],
          [54.686697, 29.612618],
          [54.69475, 29.613448],
          [54.719196, 29.613448],
          [54.720863, 29.613168],
          [54.736976, 29.613168],
          [54.737626, 29.613005],
          [54.738086, 29.612889],
          [54.738676, 29.612819],
          [54.773089, 29.608722],
          [54.773323, 29.608645],
          [54.774755, 29.608172],
          [54.784478, 29.605945],
          [54.791145, 29.604276],
          [54.798088, 29.602058],
          [54.816145, 29.593725],
          [54.835314, 29.579837],
          [54.879204, 29.531502],
          [54.88198, 29.527886],
          [54.882537, 29.527057],
          [54.906707, 29.493999],
          [54.913926, 29.484829],
          [54.935316, 29.462331],
          [54.937263, 29.459833],
          [54.947263, 29.449834],
          [54.948372, 29.449283],
          [54.948373, 29.448995],
          [54.948929, 29.448724],
          [54.949486, 29.448165],
          [54.950596, 29.447056],
          [54.953929, 29.442891],
          [54.963096, 29.427616],
          [54.968929, 29.419555],
          [54.983932, 29.394832],
          [55.013098, 29.367882],
          [55.027821, 29.359831],
          [55.035878, 29.355386],
          [55.047544, 29.348993],
          [55.081434, 29.327887],
          [55.125603, 29.29122],
          [55.128936, 29.288164],
          [55.164216, 29.253716],
          [55.164773, 29.252607],
          [55.165326, 29.252328],
          [55.167273, 29.24983],
          [55.187272, 29.220658],
          [55.195885, 29.208992],
          [55.199772, 29.203439],
          [55.219495, 29.170933],
          [55.228942, 29.152047],
          [55.230051, 29.150659],
          [55.249221, 29.118713],
          [55.250888, 29.115657],
          [55.260611, 29.096773],
          [55.261164, 29.096214],
          [55.261998, 29.094826],
          [55.262278, 29.094547],
          [55.266998, 29.075384],
          [55.270611, 29.062052],
          [55.275054, 29.045657],
          [55.277277, 29.033435],
          [55.277554, 29.03038],
          [55.278664, 29.015382],
          [55.278664, 29.012885],
          [55.278664, 29.009541],
          [55.280054, 28.999266],
          [55.280611, 28.995103],
          [55.280334, 28.994264],
          [55.280054, 28.993435],
          [55.279777, 28.991488],
          [55.279497, 28.9901],
          [55.279221, 28.988433],
          [55.278944, 28.98399],
          [55.272554, 28.961206],
          [55.272277, 28.958989],
          [55.271997, 28.957601],
          [55.271721, 28.955933],
          [55.271164, 28.95149],
          [55.270887, 28.948714],
          [55.270331, 28.943154],
          [55.269497, 28.93899],
          [55.266721, 28.928157],
          [55.266721, 28.927598],
          [55.266164, 28.925652],
          [55.265888, 28.924264],
          [55.265611, 28.923714],
          [55.264778, 28.916766],
          [55.264498, 28.914261],
          [55.264221, 28.912882],
          [55.263944, 28.909827],
          [55.263664, 28.90843],
          [55.263388, 28.907321],
          [55.263111, 28.906762],
          [55.262554, 28.903428],
          [55.261998, 28.899823],
          [55.251164, 28.857044],
          [55.250608, 28.855098],
          [55.250331, 28.85371],
          [55.249774, 28.852602],
          [55.248108, 28.842599],
          [55.247831, 28.83621],
          [55.247554, 28.835651],
          [55.247831, 28.828983],
          [55.247554, 28.822315],
          [55.247274, 28.818989],
          [55.246998, 28.815655],
          [55.246721, 28.814547],
          [55.246441, 28.812321],
          [55.246164, 28.810375],
          [55.245888, 28.806761],
          [55.245608, 28.803986],
          [55.245331, 28.801211],
          [55.245054, 28.795101],
          [55.244774, 28.794263],
          [55.243941, 28.787045],
          [55.243941, 28.785099],
          [55.243941, 28.782873],
          [55.243664, 28.782315],
          [55.244221, 28.776764],
          [55.243941, 28.776205],
          [55.244221, 28.775647],
          [55.244498, 28.773709],
          [55.244774, 28.769817],
          [55.245054, 28.764537],
          [55.244774, 28.763987],
          [55.245054, 28.763428],
          [55.245608, 28.759265],
          [55.247831, 28.747038],
          [55.248752, 28.747038],
          [55.260607, 28.747038],
          [55.260888, 28.747317],
          [55.263387, 28.747317],
          [55.265611, 28.747317],
          [55.266164, 28.747317],
          [55.266444, 28.747597],
          [55.271997, 28.747597],
          [55.272554, 28.747867],
          [55.273111, 28.747597],
          [55.273664, 28.747867],
          [55.282001, 28.747597],
          [55.282453, 28.747445],
          [55.282834, 28.747317],
          [55.283285, 28.747317],
          [55.293387, 28.747317],
          [55.293722, 28.747149],
          [55.293944, 28.747038],
          [55.29456, 28.747038],
          [55.296167, 28.747038],
          [55.2965, 28.74687],
          [55.29672, 28.746759],
          [55.297339, 28.746759],
          [55.297834, 28.746759],
          [55.298343, 28.746591],
          [55.2995, 28.746209],
          [55.299942, 28.746209],
          [55.30089, 28.746209],
          [55.301634, 28.746041],
          [55.318113, 28.742317],
          [55.318321, 28.742317],
          [55.319223, 28.742317],
          [55.319558, 28.742148],
          [55.31978, 28.742037],
          [55.320396, 28.742037],
          [55.32089, 28.742037],
          [55.321225, 28.741869],
          [55.321447, 28.741758],
          [55.322063, 28.741758],
          [55.322557, 28.741758],
          [55.322903, 28.74159],
          [55.323113, 28.741488],
          [55.323718, 28.741488],
          [55.32589, 28.741488],
          [55.328946, 28.741208],
          [55.331446, 28.741208],
          [55.331864, 28.74104],
          [55.332833, 28.74065],
          [55.333367, 28.74065],
          [55.333946, 28.74065],
          [55.334535, 28.740481],
          [55.33978, 28.738983],
          [55.340613, 28.738433],
          [55.341309, 28.738433],
          [55.341446, 28.738433],
          [55.341865, 28.738265],
          [55.342836, 28.737874],
          [55.343369, 28.737874],
          [55.34367, 28.737874],
          [55.344091, 28.737706],
          [55.346446, 28.736766],
          [55.346976, 28.736766],
          [55.347279, 28.736766],
          [55.347531, 28.736598],
          [55.348113, 28.736208],
          [55.348813, 28.736207],
          [55.348946, 28.736207],
          [55.349351, 28.736039],
          [55.358946, 28.732045],
          [55.359226, 28.731765],
          [55.359779, 28.731765],
          [55.360059, 28.731486],
          [55.360842, 28.731486],
          [55.360893, 28.731486],
          [55.361228, 28.731318],
          [55.362003, 28.730928],
          [55.362816, 28.730829],
          [55.364226, 28.730657],
          [55.364285, 28.730597],
          [55.364503, 28.730378],
          [55.365287, 28.730378],
          [55.366169, 28.730378],
          [55.366505, 28.730209],
          [55.366726, 28.730099],
          [55.367341, 28.730099],
          [55.367836, 28.730099],
          [55.368172, 28.72993],
          [55.368392, 28.729819],
          [55.369008, 28.729819],
          [55.369226, 28.729819],
          [55.369393, 28.729651],
          [55.369502, 28.72954],
          [55.370287, 28.72954],
          [55.370336, 28.72954],
          [55.370672, 28.729371],
          [55.370892, 28.729261],
          [55.371508, 28.729261],
          [55.372279, 28.729261],
          [55.372932, 28.729092],
          [55.379782, 28.727323],
          [55.382282, 28.726485],
          [55.393392, 28.723431],
          [55.393672, 28.723152],
          [55.394454, 28.723152],
          [55.394506, 28.723152],
          [55.394893, 28.722983],
          [55.420615, 28.711763],
          [55.423948, 28.710654],
          [55.424391, 28.710655],
          [55.425062, 28.710655],
          [55.42544, 28.710486],
          [55.427562, 28.709537],
          [55.437561, 28.701203],
          [55.439785, 28.700095],
          [55.447561, 28.696482],
          [55.448346, 28.69639],
          [55.457008, 28.695374],
          [55.457157, 28.695298],
          [55.457561, 28.695094],
          [55.458177, 28.695094],
          [55.462561, 28.695094],
          [55.500064, 28.69204],
          [55.503954, 28.692598],
          [55.504787, 28.692869],
          [55.505897, 28.692869],
          [55.50673, 28.693148],
          [55.512287, 28.693148],
          [55.512564, 28.693427],
          [55.516177, 28.693427],
          [55.51701, 28.693707],
          [55.518954, 28.693707],
          [55.51951, 28.693986],
          [55.53701, 28.697311],
          [55.548676, 28.70259],
          [55.5609, 28.708708],
          [55.561456, 28.708988],
          [55.583679, 28.719538],
          [55.584512, 28.719538],
          [55.586456, 28.720376],
          [55.587289, 28.720376],
          [55.587569, 28.720656],
          [55.598679, 28.72371],
          [55.600346, 28.72371],
          [55.617569, 28.727323],
          [55.640072, 28.736487],
          [55.640905, 28.737036],
          [55.645072, 28.738703],
          [55.717574, 28.777872],
          [55.718131, 28.778152],
          [55.718964, 28.77871],
          [55.720074, 28.778989],
          [55.720631, 28.779539],
          [55.805356, 28.815376],
          [55.805909, 28.815655],
          [55.815632, 28.819539],
          [55.843412, 28.827874],
          [55.845079, 28.828703],
          [55.845912, 28.828703],
          [55.868135, 28.83621],
          [55.875635, 28.839544],
          [55.884805, 28.842869],
          [55.886471, 28.843148],
          [55.891191, 28.845095],
          [55.892024, 28.845095],
          [55.897581, 28.847041],
          [55.898138, 28.847321],
          [55.899524, 28.8476],
          [55.901191, 28.848429],
          [55.979807, 28.870093],
          [55.994253, 28.873427],
          [55.99564, 28.873427],
          [55.99842, 28.874265],
          [55.99953, 28.874265],
          [56.000004, 28.874545],
          [56.00092, 28.874824],
          [56.00203, 28.874824],
          [56.00231, 28.875103],
          [56.01092, 28.876761],
          [56.017309, 28.878429],
          [56.018976, 28.878429],
          [56.019533, 28.878708],
          [56.020643, 28.878708],
          [56.023699, 28.879546],
          [56.032866, 28.880655],
          [56.045089, 28.881484],
          [56.04531, 28.88126],
          [56.045366, 28.881204],
          [56.046145, 28.881204],
          [56.046199, 28.881204],
          [56.051199, 28.880375],
          [56.051199, 28.880359],
          [56.051199, 28.880096],
          [56.053142, 28.879546],
          [56.053478, 28.879546],
          [56.053699, 28.879546],
          [56.053785, 28.879459],
          [56.053975, 28.879267],
          [56.054725, 28.879297],
          [56.054806, 28.879301],
          [56.054786, 28.879235],
          [56.054532, 28.878429],
          [56.049809, 28.869264],
          [56.049532, 28.867317],
          [56.049256, 28.862874],
          [56.048975, 28.862316],
          [56.049809, 28.842599],
          [56.050089, 28.840373],
          [56.051476, 28.830658],
          [56.052589, 28.81843],
          [56.054812, 28.792596],
          [56.057312, 28.76732],
          [56.057865, 28.764257],
          [56.058979, 28.757319],
          [56.059532, 28.752318],
          [56.060365, 28.741487],
          [56.060365, 28.729819],
          [56.059812, 28.723151],
          [56.059532, 28.720926],
          [56.058979, 28.713988],
          [56.058699, 28.709267],
          [56.058422, 28.704257],
          [56.058145, 28.700374],
          [56.057865, 28.696481],
          [56.057589, 28.695373],
          [56.057589, 28.685372],
          [56.057312, 28.673425],
          [56.054255, 28.655649],
          [56.054255, 28.641766],
          [56.048699, 28.606477],
          [56.048142, 28.602315],
          [56.045642, 28.591756],
          [56.045642, 28.591207],
          [56.044532, 28.585648],
          [56.038699, 28.556478],
          [56.038422, 28.555929],
          [56.038699, 28.555091],
          [56.038975, 28.542038],
          [56.038699, 28.538146],
          [56.038422, 28.537867],
          [56.038422, 28.533705],
          [56.038422, 28.533426],
          [56.038422, 28.533146],
          [56.038142, 28.532588],
          [56.038142, 28.532038],
          [56.038142, 28.530093],
          [56.037865, 28.529813],
          [56.037865, 28.528705],
          [56.037032, 28.525922],
          [56.036755, 28.520373],
          [56.036475, 28.518977],
          [56.036199, 28.516481],
          [56.035642, 28.511203],
          [56.035365, 28.508978],
          [56.035085, 28.506474],
          [56.034809, 28.505366],
          [56.034809, 28.489809],
          [56.033419, 28.474532],
          [56.033142, 28.473703],
          [56.033142, 28.470641],
          [56.033419, 28.467317],
          [56.033699, 28.466479],
          [56.034252, 28.45676],
          [56.033975, 28.452031],
          [56.033699, 28.447861],
          [56.033419, 28.446753],
          [56.036199, 28.401754],
          [56.036199, 28.392864],
          [56.036199, 28.390928],
          [56.036755, 28.37703],
          [56.036755, 28.37676],
          [56.036615, 28.376481],
          [56.036475, 28.376202],
          [56.044532, 28.33009],
          [56.045642, 28.327027],
          [56.045922, 28.325641],
          [56.046475, 28.324533],
          [56.055736, 28.294624],
          [56.055922, 28.294255],
          [56.073978, 28.262862],
          [56.101201, 28.237585],
          [56.104257, 28.234802],
          [56.119534, 28.223141],
          [56.119814, 28.222583],
          [56.119814, 28.222304],
          [56.120134, 28.222048],
          [56.123981, 28.218972],
          [56.135924, 28.211192],
          [56.197316, 28.182305],
          [56.201483, 28.180081],
          [56.218982, 28.173418],
          [56.232039, 28.16897],
          [56.254712, 28.161351],
          [56.255095, 28.161199],
          [56.297041, 28.150638],
          [56.302321, 28.148972],
          [56.304544, 28.148143],
          [56.305401, 28.148],
          [56.306211, 28.147864],
          [56.306781, 28.147751],
          [56.35371, 28.13841],
          [56.382323, 28.132585],
          [56.3824, 28.132585],
          [56.383713, 28.132585],
          [56.384416, 28.132408],
          [56.385936, 28.132026],
          [56.386177, 28.132026],
          [56.387323, 28.132026],
          [56.38785, 28.13185],
          [56.388156, 28.131747],
          [56.388711, 28.131701],
          [56.39149, 28.131468],
          [56.39788, 28.131468],
          [56.398232, 28.131292],
          [56.398436, 28.131189],
          [56.399027, 28.131189],
          [56.401769, 28.131189],
          [56.402326, 28.131468],
          [56.407603, 28.131468],
          [56.407879, 28.131747],
          [56.412046, 28.131747],
          [56.412603, 28.131747],
          [56.413436, 28.131747],
          [56.418159, 28.131747],
          [56.418716, 28.132026],
          [56.425659, 28.132026],
          [56.426012, 28.13185],
          [56.426216, 28.131747],
          [56.426806, 28.131747],
          [56.427326, 28.131747],
          [56.434549, 28.13064],
          [56.444549, 28.13064],
          [56.445382, 28.130919],
          [56.467605, 28.132026],
          [56.469552, 28.131747],
          [56.475105, 28.131747],
          [56.476772, 28.132026],
          [56.479272, 28.132026],
          [56.480938, 28.132305],
          [56.488161, 28.132305],
          [56.489828, 28.132585],
          [56.494275, 28.132855],
          [56.498995, 28.132855],
          [56.500661, 28.133134],
          [56.505108, 28.133134],
          [56.505941, 28.133422],
          [56.518441, 28.13425],
          [56.523998, 28.135358],
          [56.524551, 28.135358],
          [56.533997, 28.137032],
          [56.548164, 28.138968],
          [56.550664, 28.138968],
          [56.551777, 28.139247],
          [56.561777, 28.139247],
          [56.562887, 28.139526],
          [56.565667, 28.139526],
          [56.56622, 28.139806],
          [56.57122, 28.139805],
          [56.571752, 28.139627],
          [56.572053, 28.139526],
          [56.572465, 28.139526],
          [56.574833, 28.139526],
          [56.575541, 28.139348],
          [56.575943, 28.139247],
          [56.576179, 28.139247],
          [56.577333, 28.139247],
          [56.578042, 28.139069],
          [56.578443, 28.138968],
          [56.578678, 28.138968],
          [56.588723, 28.138968],
          [56.589557, 28.139247],
          [56.592056, 28.139247],
          [56.59261, 28.139526],
          [56.59539, 28.139526],
          [56.59789, 28.140085],
          [56.613723, 28.14175],
          [56.616779, 28.14175],
          [56.617613, 28.14202],
          [56.629324, 28.14175],
          [56.634556, 28.14175],
          [56.634836, 28.14202],
          [56.644002, 28.14175],
          [56.644559, 28.14202],
          [56.647892, 28.14202],
          [56.648446, 28.142309],
          [56.649279, 28.142309],
          [56.649559, 28.142588],
          [56.652059, 28.143137],
          [56.664559, 28.151754],
          [56.667615, 28.155364],
          [56.669559, 28.158417],
          [56.707338, 28.222582],
          [56.708448, 28.22369],
          [56.711505, 28.227859],
          [56.714561, 28.231749],
          [56.726504, 28.246193],
          [56.743451, 28.263141],
          [56.749007, 28.265365],
          [56.750394, 28.265365],
          [56.750951, 28.265644],
          [56.759564, 28.266752],
          [56.761507, 28.267031],
          [56.762617, 28.267301],
          [56.764007, 28.267301],
          [56.764284, 28.26759],
          [56.765117, 28.267589],
          [56.765117, 28.267869],
          [56.766784, 28.268139],
          [56.767897, 28.268697],
          [56.768451, 28.268697],
          [56.769007, 28.268976],
          [56.770117, 28.268976],
          [56.770397, 28.269255],
          [56.77234, 28.269255],
          [56.772699, 28.269076],
          [56.772897, 28.268976],
          [56.773483, 28.268976],
          [56.775397, 28.268976],
          [56.776111, 28.268797],
          [56.776507, 28.268697],
          [56.776737, 28.268697],
          [56.778454, 28.268697],
          [56.77899, 28.268517],
          [56.779287, 28.268418],
          [56.779695, 28.268418],
          [56.780397, 28.268418],
          [56.780755, 28.268238],
          [56.780954, 28.268139],
          [56.781661, 28.268078],
          [56.790677, 28.267301],
          [56.79128, 28.267151],
          [56.79512, 28.266194],
          [56.795343, 28.266194],
          [56.79651, 28.266194],
          [56.797234, 28.266014],
          [56.799843, 28.265365],
          [56.800064, 28.265365],
          [56.80123, 28.265365],
          [56.801589, 28.265185],
          [56.801787, 28.265086],
          [56.81401, 28.262582],
          [56.81512, 28.262033],
          [56.822066, 28.260637],
          [56.82762, 28.258971],
          [56.844009, 28.25536],
          [56.844193, 28.255348],
          [56.847899, 28.25509],
          [56.848456, 28.25509],
          [56.84915, 28.25491],
          [56.849566, 28.254802],
          [56.849815, 28.254802],
          [56.852066, 28.254802],
          [56.852785, 28.254622],
          [56.853179, 28.254523],
          [56.853465, 28.254507],
          [56.873736, 28.253415],
          [56.874549, 28.253242],
          [56.877622, 28.252587],
          [56.877719, 28.252587],
          [56.879012, 28.252587],
          [56.879372, 28.252406],
          [56.879569, 28.252307],
          [56.880153, 28.252307],
          [56.880679, 28.252307],
          [56.881397, 28.252127],
          [56.881789, 28.252028],
          [56.882015, 28.252028],
          [56.883455, 28.252028],
          [56.883994, 28.251848],
          [56.884289, 28.251749],
          [56.884693, 28.251749],
          [56.885959, 28.251749],
          [56.886498, 28.251569],
          [56.886792, 28.25147],
          [56.887197, 28.25147],
          [56.888459, 28.25147],
          [56.888998, 28.251289],
          [56.889292, 28.251191],
          [56.889925, 28.251114],
          [56.907625, 28.248976],
          [56.912625, 28.248976],
          [56.912985, 28.248795],
          [56.913182, 28.248696],
          [56.913765, 28.248696],
          [56.917068, 28.248696],
          [56.917608, 28.248516],
          [56.917902, 28.248417],
          [56.918305, 28.248417],
          [56.919568, 28.248417],
          [56.920108, 28.248236],
          [56.920401, 28.248138],
          [56.920805, 28.248138],
          [56.921515, 28.248138],
          [56.922055, 28.247957],
          [56.922348, 28.247859],
          [56.922752, 28.247859],
          [56.924571, 28.247859],
          [56.927905, 28.24731],
          [56.929291, 28.24731],
          [56.929831, 28.247129],
          [56.930125, 28.24703],
          [56.930528, 28.24703],
          [56.931791, 28.24703],
          [56.932331, 28.24685],
          [56.932625, 28.246751],
          [56.933028, 28.246751],
          [56.933738, 28.246751],
          [56.934097, 28.24657],
          [56.934291, 28.246472],
          [56.934876, 28.246472],
          [56.935405, 28.246472],
          [56.936304, 28.246291],
          [56.938181, 28.245914],
          [56.938225, 28.245914],
          [56.941238, 28.245914],
          [56.941778, 28.245733],
          [56.942071, 28.245635],
          [56.942512, 28.245622],
          [56.951514, 28.245364],
          [56.951673, 28.245206],
          [56.951794, 28.245085],
          [56.952556, 28.245085],
          [56.954014, 28.245085],
          [56.954555, 28.244904],
          [56.954848, 28.244806],
          [56.95525, 28.244806],
          [56.957628, 28.244806],
          [56.957987, 28.244625],
          [56.958181, 28.244527],
          [56.958765, 28.244527],
          [56.961237, 28.244527],
          [56.961599, 28.244346],
          [56.961794, 28.244248],
          [56.962403, 28.244235],
          [56.974294, 28.243978],
          [56.975684, 28.243978],
          [56.984017, 28.243978],
          [56.984578, 28.243796],
          [56.992627, 28.241195],
          [56.999931, 28.235436],
          [57.003183, 28.220637],
          [57.004017, 28.217863],
          [57.00513, 28.210912],
          [57.005407, 28.207022],
          [57.005407, 28.190913],
          [57.00485, 28.182304],
          [57.004574, 28.181476],
          [57.005683, 28.160082],
          [57.005963, 28.159803],
          [57.007073, 28.152582],
          [57.00763, 28.146468],
          [57.00763, 28.146198],
          [57.007907, 28.14536],
          [57.007907, 28.138698],
          [57.00763, 28.134529],
          [57.00735, 28.13425],
          [57.00763, 28.132584],
          [57.00735, 28.132305],
          [57.00763, 28.128136],
          [57.00735, 28.127578],
          [57.00763, 28.125912],
          [57.00735, 28.124805],
          [57.00763, 28.123697],
          [57.00735, 28.117026],
          [57.007073, 28.115918],
          [57.00763, 28.09286],
          [57.00735, 28.087305],
          [57.007073, 28.083974],
          [57.006797, 28.081471],
          [57.00624, 28.075917],
          [57.005963, 28.073414],
          [57.005683, 28.07092],
          [57.005407, 28.070082],
          [57.005407, 28.067859],
          [57.005406, 28.061197],
          [57.00513, 28.060638],
          [57.006797, 28.006749],
          [57.006762, 27.997834],
          [57.005406, 27.986188],
          [57.005963, 27.979805],
          [57.006516, 27.977582],
          [57.00735, 27.975358],
          [57.008183, 27.973972],
          [57.017906, 27.967023],
          [57.019853, 27.966195],
          [57.024573, 27.963413],
          [57.053742, 27.950082],
          [57.057076, 27.949244],
          [57.060132, 27.947849],
          [57.100132, 27.935634],
          [57.130135, 27.924526],
          [57.148191, 27.915912],
          [57.149301, 27.915633],
          [57.161801, 27.909242],
          [57.164581, 27.908684],
          [57.167081, 27.907577],
          [57.191803, 27.898684],
          [57.192913, 27.897856],
          [57.19458, 27.897298],
          [57.197637, 27.896182],
          [57.204027, 27.894796],
          [57.204252, 27.894768],
          [57.212916, 27.893689],
          [57.21875, 27.893689],
          [57.220416, 27.89341],
          [57.263752, 27.89341],
          [57.267086, 27.893689],
          [57.274862, 27.893689],
          [57.276252, 27.893968],
          [57.309862, 27.895912],
          [57.338201, 27.896182],
          [57.340421, 27.895912],
          [57.359588, 27.895912],
          [57.368481, 27.895633],
          [57.373754, 27.895633],
          [57.374674, 27.895448],
          [57.375147, 27.895354],
          [57.375166, 27.895354],
          [57.377367, 27.895354],
          [57.378283, 27.895169],
          [57.378754, 27.895075],
          [57.378777, 27.895075],
          [57.38098, 27.895075],
          [57.381712, 27.89489],
          [57.382087, 27.894795],
          [57.382486, 27.894747],
          [57.416533, 27.890628],
          [57.416577, 27.890552],
          [57.421813, 27.881465],
          [57.42348, 27.870637],
          [57.424593, 27.855634],
          [57.424593, 27.846741],
          [57.424593, 27.838128],
          [57.424313, 27.835077],
          [57.422366, 27.822018],
          [57.422093, 27.820911],
          [57.422366, 27.819795],
          [57.425146, 27.810075],
          [57.433759, 27.801741],
          [57.439033, 27.799239],
          [57.439581, 27.799239],
          [57.440146, 27.799239],
          [57.440422, 27.799054],
          [57.440979, 27.798681],
          [57.44184, 27.798548],
          [57.444593, 27.798123],
          [57.467373, 27.794793],
          [57.470979, 27.794244],
          [57.471153, 27.794175],
          [57.474872, 27.792708],
          [57.475145, 27.79257],
          [57.475145, 27.790905],
          [57.474872, 27.790077],
          [57.473205, 27.787575],
          [57.467646, 27.783688],
          [57.439866, 27.771188],
          [57.404313, 27.758687],
          [57.38042, 27.747295],
          [57.361534, 27.733688],
          [57.342641, 27.716743],
          [57.340141, 27.71507],
          [57.326254, 27.706737],
          [57.325694, 27.706458],
          [57.311255, 27.69368],
          [57.308755, 27.689793],
          [57.297642, 27.676179],
          [57.294585, 27.66979],
          [57.293751, 27.668962],
          [57.293751, 27.668404],
          [57.281808, 27.65452],
          [57.281808, 27.653963],
          [57.281252, 27.653405],
          [57.273282, 27.643636],
          [57.268791, 27.63972],
          [57.262201, 27.635814],
          [57.253185, 27.628144],
          [57.248035, 27.620238],
          [57.241891, 27.612616],
          [57.238528, 27.606577],
          [57.227739, 27.593078],
          [57.225826, 27.588457],
          [57.225839, 27.586906],
          [57.225851, 27.585561],
          [57.223051, 27.576841],
          [57.223852, 27.574029],
          [57.224088, 27.570747],
          [57.223975, 27.566607],
          [57.224883, 27.563721],
          [57.226113, 27.56187],
          [57.229041, 27.560046],
          [57.229841, 27.559948],
          [57.231867, 27.5597],
          [57.235428, 27.560481],
          [57.238255, 27.5611],
          [57.242152, 27.561954],
          [57.246423, 27.563661],
          [57.249133, 27.564001],
          [57.251685, 27.565174],
          [57.260817, 27.565585],
          [57.264244, 27.567812],
          [57.267592, 27.569987],
          [57.269721, 27.571371],
          [57.271549, 27.571838],
          [57.272658, 27.574295],
          [57.282938, 27.583545],
          [57.290767, 27.590587],
          [57.295352, 27.594711],
          [57.301578, 27.598046],
          [57.302366, 27.597783],
          [57.31248, 27.594399],
          [57.319317, 27.592112],
          [57.322003, 27.589218],
          [57.327477, 27.588081],
          [57.329562, 27.587184],
          [57.334006, 27.585659],
          [57.334909, 27.585486],
          [57.338953, 27.584713],
          [57.338971, 27.584704],
          [57.343242, 27.582698],
          [57.343758, 27.581488],
          [57.349783, 27.57803],
          [57.352778, 27.577347],
          [57.353308, 27.576535],
          [57.358713, 27.574488],
          [57.358099, 27.573202],
          [57.357781, 27.57033],
          [57.35646, 27.567778],
          [57.355434, 27.564121],
          [57.35468, 27.56143],
          [57.350205, 27.552314],
          [57.3468, 27.547729],
          [57.34588, 27.546539],
          [57.342514, 27.540413],
          [57.339984, 27.535809],
          [57.331476, 27.526332],
          [57.330405, 27.523126],
          [57.328198, 27.518191],
          [57.326089, 27.513474],
          [57.323508, 27.507702],
          [57.321448, 27.503094],
          [57.320652, 27.501314],
          [57.316422, 27.491849],
          [57.312977, 27.487368],
          [57.311989, 27.480197],
          [57.311041, 27.47331],
          [57.309008, 27.4685],
          [57.309844, 27.466186],
          [57.309836, 27.460787],
          [57.309834, 27.458978],
          [57.311527, 27.457567],
          [57.315141, 27.45423],
          [57.324587, 27.439235],
          [57.325141, 27.437571],
          [57.325421, 27.429233],
          [57.325141, 27.424789],
          [57.32486, 27.424232],
          [57.32264, 27.409516],
          [57.321807, 27.407564],
          [57.321527, 27.4059],
          [57.320694, 27.403679],
          [57.317361, 27.381454],
          [57.317087, 27.379232],
          [57.316254, 27.373952],
          [57.316254, 27.360623],
          [57.316527, 27.358123],
          [57.319307, 27.344794],
          [57.32042, 27.342564],
          [57.320694, 27.342006],
          [57.324587, 27.335899],
          [57.33542, 27.321456],
          [57.341184, 27.314614],
          [57.345974, 27.308955],
          [57.353473, 27.301455],
          [57.353753, 27.301176],
          [57.354033, 27.300618],
          [57.354867, 27.300061],
          [57.362366, 27.293397],
          [57.3632, 27.292003],
          [57.369313, 27.284512],
          [57.386813, 27.266175],
          [57.400146, 27.255897],
          [57.418199, 27.245897],
          [57.425088, 27.24256],
          [57.435978, 27.237283],
          [57.437925, 27.236177],
          [57.463609, 27.223032],
          [57.475183, 27.23222],
          [57.493917, 27.227601],
          [57.500395, 27.223476],
          [57.514582, 27.204484],
          [57.53265, 27.192844],
          [57.544037, 27.184509],
          [57.54765, 27.181452],
          [57.55237, 27.176453],
          [57.553203, 27.17478],
          [57.56015, 27.162561],
          [57.560983, 27.158947],
          [57.560983, 27.158398],
          [57.561263, 27.157841],
          [57.561538, 27.156119],
          [57.56265, 27.15117],
          [57.563483, 27.148113],
          [57.577096, 27.124781],
          [57.57821, 27.123945],
          [57.578763, 27.122282],
          [57.598209, 27.101172],
          [57.619042, 27.084226],
          [57.621262, 27.082284],
          [57.622929, 27.08089],
          [57.624595, 27.080054],
          [57.630989, 27.076728],
          [57.633489, 27.074508],
          [57.634209, 27.074508],
          [57.634322, 27.074508],
          [57.634509, 27.074321],
          [57.634602, 27.074229],
          [57.635439, 27.074136],
          [57.637102, 27.07395],
          [57.637655, 27.07395],
          [57.639042, 27.07395],
          [57.639414, 27.073764],
          [57.640155, 27.073393],
          [57.641822, 27.070615],
          [57.644602, 27.068673],
          [57.654321, 27.060061],
          [57.658768, 27.057562],
          [57.660435, 27.057004],
          [57.660808, 27.057004],
          [57.661541, 27.057004],
          [57.661541, 27.056818],
          [57.661541, 27.056726],
          [57.662102, 27.056726],
          [57.698487, 27.032841],
          [57.717381, 27.021442],
          [57.724327, 27.015339],
          [57.728213, 27.012282],
          [57.728767, 27.012004],
          [57.742933, 27.002835],
          [57.742934, 27.002834],
          [57.742942, 27.002831],
          [57.748769, 27.000176],
          [57.749606, 26.996174],
          [57.76294, 26.977837],
          [57.768213, 26.97061],
          [57.770159, 26.96839],
          [57.786272, 26.951447],
          [57.798492, 26.943115],
          [57.799606, 26.942837],
          [57.800439, 26.94228],
          [57.801552, 26.942001],
          [57.802386, 26.941444],
          [57.815992, 26.938109],
          [57.816155, 26.938109],
          [57.816825, 26.938109],
          [57.817014, 26.937922],
          [57.817105, 26.937831],
          [57.817845, 26.937831],
          [57.818772, 26.937831],
          [57.819144, 26.937643],
          [57.819325, 26.937552],
          [57.819881, 26.937552],
          [57.821832, 26.937552],
          [57.832665, 26.939781],
          [57.838218, 26.939781],
          [57.838771, 26.94006],
          [57.844052, 26.940059],
          [57.844802, 26.939872],
          [57.845165, 26.939781],
          [57.845343, 26.939781],
          [57.847105, 26.939781],
          [57.851271, 26.938945],
          [57.853218, 26.938945],
          [57.854061, 26.938757],
          [57.855718, 26.938388],
          [57.855804, 26.938388],
          [57.856551, 26.938388],
          [57.85674, 26.9382],
          [57.856831, 26.938109],
          [57.857571, 26.938109],
          [57.857665, 26.938109],
          [57.858038, 26.937921],
          [57.858218, 26.93783],
          [57.858773, 26.93783],
          [57.859331, 26.93783],
          [57.859812, 26.937643],
          [57.872111, 26.932833],
          [57.872559, 26.932833],
          [57.872944, 26.932833],
          [57.873069, 26.932645],
          [57.873498, 26.931997],
          [57.874051, 26.931719],
          [57.878497, 26.929777],
          [57.878995, 26.929777],
          [57.879331, 26.929777],
          [57.879704, 26.929589],
          [57.879884, 26.929499],
          [57.880439, 26.929499],
          [57.880444, 26.929499],
          [57.881831, 26.929229],
          [57.881939, 26.929201],
          [57.887111, 26.927836],
          [57.887326, 26.927836],
          [57.888217, 26.927836],
          [57.888807, 26.927648],
          [57.894331, 26.925886],
          [57.895038, 26.925768],
          [57.90433, 26.924223],
          [57.906277, 26.924223],
          [57.907664, 26.923944],
          [57.90961, 26.923944],
          [57.910174, 26.923756],
          [57.910444, 26.923666],
          [57.910809, 26.923666],
          [57.914057, 26.923666],
          [57.947943, 26.919504],
          [57.953223, 26.919774],
          [57.958776, 26.920052],
          [57.969056, 26.920052],
          [57.981836, 26.917832],
          [57.982949, 26.917832],
          [57.983635, 26.917644],
          [57.991002, 26.915613],
          [57.991245, 26.915613],
          [57.991836, 26.915613],
          [57.992277, 26.915424],
          [58.001555, 26.911451],
          [58.002389, 26.910615],
          [58.003502, 26.910337],
          [58.028228, 26.888668],
          [58.028782, 26.88839],
          [58.031282, 26.885613],
          [58.056841, 26.862273],
          [58.059614, 26.860611],
          [58.060448, 26.859775],
          [58.082947, 26.847284],
          [58.087674, 26.845333],
          [58.089061, 26.844498],
          [58.094894, 26.842278],
          [58.09934, 26.841164],
          [58.09951, 26.841164],
          [58.100727, 26.841164],
          [58.104894, 26.840885],
          [58.10906, 26.840885],
          [58.109251, 26.840695],
          [58.10934, 26.840607],
          [58.110076, 26.840606],
          [58.110454, 26.840606],
          [58.11116, 26.840417],
          [58.117674, 26.838665],
          [58.12434, 26.834774],
          [58.171286, 26.795056],
          [58.175179, 26.792558],
          [58.178512, 26.789493],
          [58.178512, 26.789215],
          [58.179066, 26.789215],
          [58.181286, 26.786717],
          [58.191846, 26.779223],
          [58.193512, 26.778666],
          [58.193869, 26.778666],
          [58.194346, 26.778666],
          [58.194532, 26.778475],
          [58.194619, 26.778387],
          [58.195358, 26.778387],
          [58.195452, 26.778387],
          [58.195644, 26.778197],
          [58.195732, 26.778109],
          [58.196467, 26.778109],
          [58.196565, 26.778109],
          [58.196949, 26.777918],
          [58.197126, 26.77783],
          [58.197669, 26.77783],
          [58.198512, 26.77783],
          [58.199082, 26.77764],
          [58.199346, 26.777552],
          [58.199702, 26.777552],
          [58.201846, 26.777552],
          [58.202399, 26.77783],
          [58.203512, 26.77783],
          [58.204066, 26.778109],
          [58.204447, 26.778109],
          [58.205179, 26.778109],
          [58.20556, 26.777918],
          [58.206292, 26.777552],
          [58.206838, 26.777552],
          [58.206845, 26.777552],
          [58.207224, 26.777361],
          [58.207399, 26.777273],
          [58.208232, 26.776994],
          [58.208588, 26.776994],
          [58.213512, 26.776994],
          [58.213891, 26.776804],
          [58.214065, 26.776716],
          [58.214613, 26.776716],
          [58.218232, 26.776716],
          [58.218615, 26.776525],
          [58.218792, 26.776437],
          [58.219335, 26.776437],
          [58.221565, 26.776437],
          [58.222135, 26.776247],
          [58.222398, 26.776159],
          [58.222754, 26.776159],
          [58.229065, 26.776159],
          [58.234625, 26.775889],
          [58.251571, 26.77367],
          [58.254071, 26.773391],
          [58.254905, 26.773391],
          [58.255284, 26.7732],
          [58.255458, 26.773113],
          [58.256005, 26.773113],
          [58.256571, 26.773113],
          [58.256951, 26.772922],
          [58.257125, 26.772834],
          [58.257671, 26.772834],
          [58.258238, 26.772834],
          [58.258617, 26.772643],
          [58.258791, 26.772556],
          [58.259338, 26.772556],
          [58.259904, 26.772556],
          [58.260476, 26.772365],
          [58.260738, 26.772277],
          [58.261092, 26.772277],
          [58.261844, 26.772277],
          [58.262228, 26.772086],
          [58.262404, 26.771998],
          [58.262946, 26.771998],
          [58.264624, 26.771998],
          [58.265196, 26.771807],
          [58.265458, 26.77172],
          [58.265812, 26.77172],
          [58.267958, 26.77172],
          [58.268529, 26.771529],
          [58.268791, 26.771441],
          [58.269145, 26.771441],
          [58.269905, 26.771441],
          [58.270476, 26.77125],
          [58.270738, 26.771163],
          [58.271092, 26.771163],
          [58.272684, 26.771163],
          [58.273256, 26.770972],
          [58.273518, 26.770884],
          [58.273872, 26.770884],
          [58.274624, 26.770884],
          [58.275009, 26.770693],
          [58.275184, 26.770606],
          [58.275726, 26.770606],
          [58.277404, 26.770606],
          [58.284624, 26.769222],
          [58.285254, 26.769087],
          [58.313237, 26.763112],
          [58.313267, 26.763112],
          [58.31407, 26.763112],
          [58.314455, 26.76292],
          [58.31463, 26.762833],
          [58.315171, 26.762833],
          [58.316017, 26.762833],
          [58.320737, 26.761998],
          [58.34657, 26.757559],
          [58.346985, 26.757455],
          [58.347683, 26.75728],
          [58.347843, 26.75728],
          [58.34963, 26.75728],
          [58.352683, 26.756723],
          [58.354076, 26.756723],
          [58.354975, 26.756532],
          [58.36713, 26.753938],
          [58.367156, 26.753938],
          [58.368516, 26.753938],
          [58.36909, 26.753746],
          [58.369349, 26.753659],
          [58.369701, 26.753659],
          [58.370743, 26.753659],
          [58.370931, 26.753467],
          [58.371016, 26.753381],
          [58.371754, 26.753381],
          [58.372683, 26.753381],
          [58.373349, 26.753189],
          [58.385182, 26.749778],
          [58.387409, 26.748942],
          [58.419374, 26.737859],
          [58.435468, 26.732275],
          [58.436575, 26.731439],
          [58.440741, 26.728384],
          [58.442688, 26.727549],
          [58.473801, 26.707828],
          [58.474634, 26.707271],
          [58.484634, 26.699769],
          [58.485467, 26.699212],
          [58.500194, 26.685888],
          [58.513891, 26.668606],
          [58.535193, 26.641715],
          [58.577419, 26.603662],
          [58.596305, 26.588939],
          [58.645198, 26.55866],
          [58.654364, 26.554492],
          [58.690477, 26.536717],
          [58.707143, 26.5306],
          [58.768816, 26.515044],
          [58.782702, 26.509772],
          [58.784649, 26.508658],
          [58.800482, 26.502263],
          [58.817981, 26.493659],
          [58.819648, 26.492546],
          [58.839648, 26.483933],
          [58.840449, 26.483789],
          [58.842708, 26.483385],
          [58.842802, 26.483338],
          [58.843261, 26.483107],
          [58.843795, 26.483107],
          [58.843814, 26.483107],
          [58.844374, 26.483107],
          [58.849928, 26.483654],
          [58.852148, 26.484211],
          [58.853261, 26.484211],
          [58.855208, 26.484768],
          [58.855761, 26.485046],
          [58.857427, 26.485046],
          [58.878261, 26.494216],
          [58.879374, 26.494486],
          [58.890207, 26.501158],
          [58.897987, 26.508658],
          [58.904927, 26.512825],
          [58.920767, 26.514765],
          [58.922433, 26.515044],
          [58.92382, 26.515044],
          [58.92421, 26.514847],
          [58.924373, 26.514765],
          [58.924754, 26.514841],
          [58.925766, 26.515044],
          [58.927433, 26.515044],
          [58.927823, 26.514847],
          [58.927986, 26.514765],
          [58.928519, 26.514765],
          [58.93104, 26.514765],
          [58.936873, 26.514217],
          [58.937575, 26.514042],
          [58.939099, 26.51366],
          [58.939237, 26.51366],
          [58.940486, 26.51366],
          [58.941133, 26.513464],
          [58.956046, 26.508936],
          [58.957986, 26.508101],
          [58.964379, 26.506439],
          [58.967986, 26.505047],
          [58.968399, 26.505047],
          [58.968819, 26.505047],
          [58.96931, 26.504851],
          [58.971599, 26.503934],
          [58.981599, 26.501715],
          [58.981635, 26.501715],
          [58.983546, 26.501715],
          [58.984932, 26.501437],
          [58.986879, 26.501437],
          [58.987748, 26.50124],
          [58.995492, 26.499488],
          [58.995519, 26.499494],
          [58.997992, 26.500045],
          [58.999098, 26.500045],
          [58.999659, 26.500323],
          [59.000355, 26.500323],
          [59.002159, 26.501437],
          [59.007712, 26.510598],
          [59.007712, 26.513382],
          [59.007432, 26.515043],
          [59.007158, 26.515322],
          [59.007712, 26.516992],
          [59.008265, 26.517271],
          [59.008265, 26.517549],
          [59.008545, 26.517828],
          [59.009098, 26.518932],
          [59.009378, 26.520603],
          [59.009098, 26.523378],
          [59.006325, 26.526432],
          [59.006045, 26.528103],
          [59.013545, 26.534219],
          [59.027712, 26.538108],
          [59.031878, 26.540048],
          [59.032158, 26.540596],
          [59.033271, 26.541162],
          [59.035491, 26.543659],
          [59.037438, 26.547548],
          [59.037438, 26.550602],
          [59.035211, 26.560877],
          [59.034938, 26.561156],
          [59.034938, 26.561434],
          [59.028545, 26.566438],
          [59.026325, 26.568099],
          [59.004379, 26.585327],
          [59.002992, 26.586989],
          [59.000492, 26.59422],
          [58.999659, 26.596439],
          [58.999099, 26.604209],
          [59.001046, 26.615878],
          [59.002432, 26.618654],
          [59.011879, 26.630601],
          [59.014932, 26.636719],
          [59.017432, 26.651443],
          [59.017712, 26.656159],
          [59.017432, 26.659771],
          [59.016045, 26.662547],
          [59.002992, 26.674774],
          [58.997992, 26.68533],
          [58.996046, 26.711438],
          [58.995766, 26.714224],
          [58.996046, 26.714772],
          [58.996599, 26.723109],
          [58.996599, 26.730324],
          [58.996326, 26.734215],
          [58.993546, 26.748941],
          [58.992993, 26.750612],
          [58.992432, 26.751439],
          [58.990493, 26.758384],
          [58.990493, 26.758663],
          [58.990493, 26.75922],
          [58.990213, 26.759499],
          [58.989968, 26.75958],
          [58.989379, 26.759777],
          [58.989071, 26.759777],
          [58.988546, 26.759777],
          [58.988183, 26.759956],
          [58.987432, 26.760325],
          [58.987235, 26.760128],
          [58.987153, 26.760047],
          [58.986599, 26.760047],
          [58.986599, 26.760128],
          [58.986599, 26.760325],
          [58.985186, 26.760325],
          [58.984653, 26.760325],
          [58.98449, 26.760407],
          [58.984099, 26.760604],
          [58.982884, 26.760604],
          [58.982153, 26.760604],
          [58.982072, 26.760686],
          [58.98188, 26.760882],
          [58.980106, 26.760882],
          [58.97938, 26.760882],
          [58.979297, 26.760964],
          [58.9791, 26.761161],
          [58.977967, 26.761161],
          [58.977433, 26.761161],
          [58.97727, 26.761243],
          [58.97688, 26.761439],
          [58.976575, 26.761439],
          [58.976046, 26.761439],
          [58.975882, 26.761521],
          [58.975486, 26.761718],
          [58.974273, 26.761718],
          [58.973546, 26.761718],
          [58.973464, 26.7618],
          [58.973266, 26.761997],
          [58.972175, 26.761997],
          [58.971879, 26.761997],
          [58.958547, 26.766166],
          [58.957153, 26.766992],
          [58.956046, 26.767271],
          [58.905761, 26.78783],
          [58.899374, 26.791164],
          [58.886321, 26.800051],
          [58.862981, 26.81867],
          [58.861315, 26.819496],
          [58.860208, 26.820611],
          [58.853542, 26.824771],
          [58.851042, 26.830334],
          [58.850762, 26.831161],
          [58.850208, 26.833111],
          [58.850481, 26.836166],
          [58.850208, 26.846725],
          [58.849375, 26.857554],
          [58.850208, 26.861993],
          [58.864375, 26.908664],
          [58.876595, 26.933946],
          [58.897988, 26.969224],
          [58.898821, 26.969782],
          [58.911874, 26.988667],
          [58.912988, 26.99993],
          [58.908541, 27.005332],
          [58.904928, 27.007274],
          [58.890762, 27.018941],
          [58.889375, 27.020335],
          [58.876875, 27.037279],
          [58.875762, 27.0395],
          [58.874648, 27.040893],
          [58.871315, 27.047276],
          [58.870208, 27.048669],
          [58.859929, 27.071449],
          [58.859376, 27.074227],
          [58.855209, 27.08728],
          [58.850482, 27.096729],
          [58.848542, 27.101728],
          [58.838536, 27.13395],
          [58.838536, 27.134229],
          [58.838536, 27.134498],
          [58.838262, 27.13515],
          [58.835203, 27.150889],
          [58.834929, 27.154503],
          [58.834649, 27.157281],
          [58.833536, 27.164223],
          [58.833005, 27.183222],
          [58.831036, 27.253674],
          [58.83187, 27.262001],
          [58.835483, 27.292001],
          [58.836869, 27.301731],
          [58.854096, 27.393126],
          [58.862429, 27.426172],
          [58.865763, 27.437281],
          [58.870763, 27.462009],
          [58.874096, 27.475619],
          [58.874649, 27.476734],
          [58.874649, 27.477013],
          [58.874649, 27.477291],
          [58.874649, 27.478119],
          [58.874929, 27.478677],
          [58.87521, 27.479793],
          [58.875483, 27.481178],
          [58.878823, 27.495346],
          [58.879096, 27.497289],
          [58.886043, 27.514507],
          [58.890489, 27.532842],
          [58.890489, 27.533678],
          [58.890489, 27.533957],
          [58.891043, 27.534785],
          [58.891414, 27.535807],
          [58.897156, 27.554235],
          [58.898542, 27.558958],
          [58.901043, 27.576457],
          [58.901043, 27.594514],
          [58.900763, 27.598679],
          [58.901043, 27.599516],
          [58.900763, 27.603124],
          [58.901043, 27.60424],
          [58.90021, 27.625348],
          [58.901954, 27.637795],
          [58.902429, 27.641183],
          [58.902989, 27.645628],
          [58.905763, 27.670624],
          [58.905763, 27.679794],
          [58.904929, 27.703954],
          [58.904929, 27.704242],
          [58.905209, 27.705628],
          [58.904929, 27.711459],
          [58.904929, 27.713961],
          [58.903823, 27.749236],
          [58.903263, 27.759792],
          [58.90299, 27.761736],
          [58.903263, 27.768962],
          [58.90299, 27.784792],
          [58.90243, 27.795349],
          [58.90271, 27.797572],
          [58.902429, 27.800353],
          [58.90271, 27.802576],
          [58.902156, 27.818955],
          [58.900763, 27.837298],
          [58.898823, 27.848962],
          [58.89743, 27.854515],
          [58.89521, 27.867575],
          [58.893544, 27.875631],
          [58.891877, 27.881184],
          [58.89049, 27.898124],
          [58.890763, 27.898961],
          [58.928823, 27.96368],
          [58.931876, 27.971746],
          [58.932156, 27.972574],
          [58.932436, 27.973132],
          [58.932436, 27.974519],
          [58.93271, 27.975626],
          [58.93271, 27.981189],
          [58.93299, 27.981747],
          [58.93271, 27.985078],
          [58.933543, 27.987301],
          [58.933823, 27.988967],
          [58.934376, 27.993684],
          [58.933823, 27.997852],
          [58.933823, 27.999796],
          [58.93299, 28.004522],
          [58.931603, 28.022302],
          [58.931876, 28.022572],
          [58.933823, 28.029801],
          [58.94827, 28.050912],
          [58.951043, 28.054523],
          [58.952156, 28.055351],
          [58.95355, 28.057304],
          [58.953823, 28.057575],
          [58.956603, 28.059798],
          [58.956883, 28.060077],
          [58.978269, 28.077579],
          [58.978549, 28.078137],
          [58.978939, 28.078524],
          [58.979265, 28.078849],
          [58.980769, 28.080352],
          [59.005496, 28.097017],
          [59.006097, 28.097551],
          [59.014555, 28.107066],
          [59.033904, 28.11513],
          [59.052985, 28.115186],
          [59.072092, 28.115238],
          [59.072711, 28.115039],
          [59.07303, 28.11504],
          [59.082551, 28.111983],
          [59.092964, 28.111737],
          [59.115467, 28.111202],
          [59.124515, 28.112935],
          [59.125193, 28.112687],
          [59.1255, 28.112575],
          [59.125896, 28.112575],
          [59.126438, 28.112377],
          [59.128105, 28.112376],
          [59.141991, 28.119048],
          [59.146438, 28.123217],
          [59.146991, 28.124045],
          [59.148384, 28.127376],
          [59.149491, 28.134327],
          [59.150051, 28.143493],
          [59.150052, 28.151552],
          [59.154492, 28.189882],
          [59.154772, 28.192656],
          [59.155052, 28.196267],
          [59.164772, 28.229881],
          [59.164772, 28.231826],
          [59.165052, 28.232934],
          [59.164772, 28.235716],
          [59.164219, 28.237103],
          [59.162826, 28.245712],
          [59.161439, 28.250719],
          [59.153939, 28.265442],
          [59.14894, 28.272656],
          [59.12616, 28.298493],
          [59.124774, 28.314883],
          [59.125047, 28.315162],
          [59.125327, 28.318774],
          [59.125881, 28.325438],
          [59.125881, 28.330166],
          [59.126161, 28.331274],
          [59.125048, 28.342108],
          [59.125048, 28.348215],
          [59.125048, 28.354879],
          [59.122828, 28.362102],
          [59.122548, 28.363219],
          [59.116442, 28.37155],
          [59.099495, 28.387942],
          [59.06755, 28.409054],
          [59.066436, 28.409334],
          [59.062823, 28.411549],
          [59.061436, 28.414612],
          [59.061716, 28.414882],
          [59.061989, 28.416278],
          [59.062897, 28.416772],
          [59.062348, 28.417184],
          [59.064109, 28.418142],
          [59.064382, 28.418692],
          [59.071889, 28.422583],
          [59.073828, 28.42369],
          [59.147447, 28.453973],
          [59.148834, 28.45481],
          [59.150501, 28.45509],
          [59.162167, 28.459809],
          [59.164947, 28.460638],
          [59.165781, 28.461197],
          [59.16772, 28.461746],
          [59.172167, 28.463971],
          [59.182167, 28.467313],
          [59.193833, 28.469528],
          [59.207166, 28.472303],
          [59.212727, 28.473699],
          [59.221893, 28.475365],
          [59.230506, 28.476473],
          [59.242726, 28.477311],
          [59.279392, 28.481752],
          [59.299399, 28.48286],
          [59.302732, 28.483139],
          [59.307452, 28.483139],
          [59.319672, 28.483976],
          [59.323005, 28.483976],
          [59.324671, 28.484256],
          [59.331065, 28.484256],
          [59.332451, 28.483976],
          [59.336898, 28.483976],
          [59.339678, 28.483697],
          [59.343845, 28.483697],
          [59.346898, 28.483418],
          [59.349678, 28.483418],
          [59.351064, 28.483139],
          [59.361618, 28.483139],
          [59.364677, 28.483418],
          [59.369678, 28.483418],
          [59.373291, 28.483697],
          [59.394957, 28.483697],
          [59.399397, 28.483418],
          [59.40607, 28.483418],
          [59.434403, 28.481472],
          [59.43607, 28.481472],
          [59.439403, 28.480914],
          [59.446903, 28.480364],
          [59.45107, 28.479526],
          [59.468569, 28.477869],
          [59.469287, 28.477689],
          [59.469683, 28.47759],
          [59.469841, 28.477585],
          [59.478849, 28.47731],
          [59.480516, 28.477031],
          [59.483569, 28.477031],
          [59.507182, 28.47314],
          [59.509402, 28.47314],
          [59.513015, 28.472581],
          [59.515235, 28.472581],
          [59.516038, 28.47238],
          [59.516348, 28.472302],
          [59.516486, 28.472302],
          [59.525242, 28.472302],
          [59.526075, 28.472581],
          [59.529128, 28.472581],
          [59.529961, 28.472861],
          [59.532742, 28.472861],
          [59.533848, 28.47314],
          [59.536348, 28.47314],
          [59.547742, 28.47759],
          [59.556355, 28.484255],
          [59.560794, 28.489804],
          [59.563854, 28.503694],
          [59.564408, 28.505081],
          [59.564408, 28.506468],
          [59.565241, 28.509811],
          [59.565794, 28.515089],
          [59.566074, 28.518422],
          [59.566074, 28.525917],
          [59.564961, 28.538691],
          [59.560521, 28.561194],
          [59.558575, 28.56786],
          [59.558295, 28.569806],
          [59.550241, 28.594535],
          [59.546075, 28.623697],
          [59.545795, 28.634535],
          [59.546075, 28.635923],
          [59.546075, 28.643418],
          [59.553848, 28.660365],
          [59.555795, 28.664257],
          [59.562742, 28.690368],
          [59.563022, 28.706757],
          [59.562742, 28.713983],
          [59.562188, 28.720092],
          [59.561624, 28.724018],
          [59.556355, 28.757872],
          [59.556075, 28.766477],
          [59.555515, 28.771758],
          [59.552182, 28.789815],
          [59.551909, 28.798421],
          [59.551629, 28.800367],
          [59.551577, 28.800523],
          [59.551454, 28.800891],
          [59.551349, 28.801205],
          [59.551349, 28.801764],
          [59.551349, 28.803152],
          [59.551629, 28.803422],
          [59.551076, 28.80954],
          [59.550796, 28.809811],
          [59.550796, 28.812534],
          [59.550516, 28.815929],
          [59.540796, 28.86259],
          [59.540242, 28.864257],
          [59.539409, 28.870367],
          [59.534409, 28.884254],
          [59.531909, 28.887589],
          [59.531349, 28.889535],
          [59.526349, 28.897313],
          [59.525796, 28.897592],
          [59.514963, 28.916761],
          [59.500517, 28.947592],
          [59.496903, 28.96093],
          [59.505517, 28.99982],
          [59.508297, 29.004263],
          [59.52941, 29.032871],
          [59.536076, 29.042596],
          [59.537463, 29.045093],
          [59.537463, 29.045372],
          [59.564129, 29.069267],
          [59.564963, 29.070934],
          [59.565523, 29.071484],
          [59.565523, 29.072043],
          [59.566076, 29.072322],
          [59.570796, 29.082039],
          [59.569409, 29.08843],
          [59.569409, 29.090656],
          [59.569689, 29.090927],
          [59.569409, 29.092324],
          [59.552743, 29.120655],
          [59.55135, 29.123431],
          [59.528297, 29.16093],
          [59.523973, 29.170652],
          [59.52385, 29.170927],
          [59.523017, 29.171766],
          [59.517464, 29.183439],
          [59.51607, 29.187875],
          [59.51413, 29.192039],
          [59.510797, 29.203154],
          [59.509404, 29.207049],
          [59.508851, 29.209267],
          [59.494404, 29.244542],
          [59.491904, 29.25344],
          [59.484684, 29.276213],
          [59.483851, 29.280108],
          [59.480798, 29.298157],
          [59.474125, 29.328432],
          [59.473571, 29.32955],
          [59.469958, 29.353992],
          [59.466905, 29.363161],
          [59.465518, 29.367327],
          [59.464685, 29.370663],
          [59.463572, 29.374269],
          [59.462185, 29.397883],
          [59.461905, 29.40066],
          [59.462458, 29.404826],
          [59.462739, 29.414266],
          [59.463292, 29.418161],
          [59.463292, 29.433715],
          [59.461905, 29.438719],
          [59.461625, 29.439269],
          [59.461625, 29.439549],
          [59.461625, 29.439828],
          [59.460792, 29.441217],
          [59.460792, 29.442047],
          [59.459685, 29.443715],
          [59.457459, 29.461496],
          [59.458019, 29.466221],
          [59.458019, 29.471775],
          [59.458019, 29.472046],
          [59.457739, 29.480107],
          [59.455239, 29.504273],
          [59.456072, 29.572888],
          [59.456352, 29.574548],
          [59.456072, 29.577334],
          [59.456352, 29.578993],
          [59.456073, 29.588165],
          [59.455792, 29.591781],
          [59.451906, 29.610944],
          [59.451906, 29.611495],
          [59.449959, 29.621496],
          [59.449959, 29.623994],
          [59.449126, 29.629549],
          [59.448853, 29.639],
          [59.448019, 29.650661],
          [59.447739, 29.672333],
          [59.447459, 29.674551],
          [59.447739, 29.693725],
          [59.447459, 29.699001],
          [59.448573, 29.711781],
          [59.448853, 29.72733],
          [59.448573, 29.729557],
          [59.448853, 29.730387],
          [59.448573, 29.735393],
          [59.446626, 29.745666],
          [59.446626, 29.747055],
          [59.446073, 29.750392],
          [59.444959, 29.754838],
          [59.438847, 29.814836],
          [59.433574, 29.871779],
          [59.433574, 29.872338],
          [59.433014, 29.888727],
          [59.43246, 29.902059],
          [59.43218, 29.907896],
          [59.43274, 29.910394],
          [59.434127, 29.919288],
          [59.434407, 29.920668],
          [59.43468, 29.923167],
          [59.43496, 29.925675],
          [59.43524, 29.929003],
          [59.435513, 29.931222],
          [59.43996, 29.95123],
          [59.44024, 29.952899],
          [59.440513, 29.954559],
          [59.440513, 29.954838],
          [59.443294, 29.968451],
          [59.446907, 29.980954],
          [59.447187, 29.982613],
          [59.44746, 29.983732],
          [59.44774, 29.985112],
          [59.44802, 29.986501],
          [59.448293, 29.98817],
          [59.448854, 29.990949],
          [59.449127, 29.992618],
          [59.449407, 29.993448],
          [59.44746, 30.000051],
          [59.44496, 30.017615],
          [59.441627, 30.035116],
          [59.440794, 30.037065],
          [59.438574, 30.052067],
          [59.438294, 30.054566],
          [59.435794, 30.06928],
          [59.435241, 30.070949],
          [59.434407, 30.099006],
          [59.433847, 30.104563],
          [59.432181, 30.118727],
          [59.431907, 30.120676],
          [59.431908, 30.123455],
          [59.431627, 30.128733],
          [59.431347, 30.130122],
          [59.431627, 30.131232],
          [59.431347, 30.13484],
          [59.430514, 30.138178],
          [59.429681, 30.144566],
          [59.429407, 30.145676],
          [59.429681, 30.151504],
          [59.429128, 30.178732],
          [59.428574, 30.18457],
          [59.426348, 30.192627],
          [59.426348, 30.193457],
          [59.426074, 30.193736],
          [59.426074, 30.194567],
          [59.425794, 30.209011],
          [59.425794, 30.21151],
          [59.426075, 30.234842],
          [59.424961, 30.252626],
          [59.426348, 30.259573],
          [59.426908, 30.263182],
          [59.427181, 30.265402],
          [59.427741, 30.270401],
          [59.428015, 30.2729],
          [59.428848, 30.280957],
          [59.429128, 30.282626],
          [59.430795, 30.291794],
          [59.431348, 30.295403],
          [59.432181, 30.299842],
          [59.434961, 30.313178],
          [59.435241, 30.314847],
          [59.435794, 30.318177],
          [59.436075, 30.320676],
          [59.436348, 30.324294],
          [59.438575, 30.335961],
          [59.438848, 30.337901],
          [59.439128, 30.342629],
          [59.439408, 30.345679],
          [59.441075, 30.357906],
          [59.441348, 30.359566],
          [59.441628, 30.362074],
          [59.441908, 30.364844],
          [59.442181, 30.368733],
          [59.443295, 30.374572],
          [59.444408, 30.377342],
          [59.445241, 30.38068],
          [59.446075, 30.38262],
          [59.446075, 30.38346],
          [59.453021, 30.417902],
          [59.453021, 30.422631],
          [59.453295, 30.423461],
          [59.453575, 30.42707],
          [59.453855, 30.429849],
          [59.459408, 30.454015],
          [59.459688, 30.455125],
          [59.460241, 30.459014],
          [59.460241, 30.460124],
          [59.466355, 30.482621],
          [59.466628, 30.48346],
          [59.467188, 30.485129],
          [59.468021, 30.489849],
          [59.468021, 30.498737],
          [59.468295, 30.499577],
          [59.464962, 30.514845],
          [59.464961, 30.515405],
          [59.463575, 30.516795],
          [59.455241, 30.525124],
          [59.454408, 30.526234],
          [59.411909, 30.569848],
          [59.410796, 30.571238],
          [59.409129, 30.572628],
          [59.36079, 30.624023],
          [59.359123, 30.625963],
          [59.355237, 30.630133],
          [59.296904, 30.6943],
          [59.296071, 30.69569],
          [59.295238, 30.69624],
          [59.294958, 30.6968],
          [59.292738, 30.6993],
          [59.253285, 30.754019],
          [59.250785, 30.757079],
          [59.193839, 30.816801],
          [59.193286, 30.817081],
          [59.189953, 30.81986],
          [59.165226, 30.841522],
          [59.14912, 30.855133],
          [59.133007, 30.866524],
          [59.131061, 30.867643],
          [59.119947, 30.874024],
          [59.078561, 30.907917],
          [59.078001, 30.908747],
          [59.077448, 30.909586],
          [59.076335, 30.917638],
          [59.076061, 30.920138],
          [59.076895, 30.924028],
          [59.077168, 30.950141],
          [59.076895, 30.952361],
          [59.076895, 30.954862],
          [59.074115, 30.985695],
          [59.073562, 30.988196],
          [59.070502, 30.996807],
          [59.070502, 30.997087],
          [59.068002, 31.000083],
          [59.058555, 31.008478],
          [59.056888, 31.009588],
          [59.054115, 31.011249],
          [59.027731, 31.024857],
          [59.027722, 31.024861],
          [59.027294, 31.024947],
          [59.026336, 31.025141],
          [59.008556, 31.033752],
          [59.004943, 31.035142],
          [58.992443, 31.041804],
          [58.96939, 31.050415],
          [58.96411, 31.052645],
          [58.936884, 31.065147],
          [58.900771, 31.084861],
          [58.871051, 31.100974],
          [58.869385, 31.101525],
          [58.829106, 31.125419],
          [58.820772, 31.13097],
          [58.780493, 31.155416],
          [58.732714, 31.192643],
          [58.727987, 31.195983],
          [58.722987, 31.199035],
          [58.669375, 31.241535],
          [58.668952, 31.241957],
          [58.658261, 31.249867],
          [58.635208, 31.264871],
          [58.634648, 31.265141],
          [58.621876, 31.274034],
          [58.608262, 31.285977],
          [58.538817, 31.346814],
          [58.514371, 31.371541],
          [58.499924, 31.392377],
          [58.465478, 31.417366],
          [58.465198, 31.417925],
          [58.464644, 31.418205],
          [58.463258, 31.419316],
          [58.423532, 31.448765],
          [58.423259, 31.449029],
          [58.421305, 31.450426],
          [58.421032, 31.450706],
          [58.399919, 31.469313],
          [58.392972, 31.474314],
          [58.389919, 31.476544],
          [58.307694, 31.534595],
          [58.302967, 31.537926],
          [58.279081, 31.553491],
          [58.277967, 31.553762],
          [58.234355, 31.579881],
          [58.229355, 31.582653],
          [58.141576, 31.625989],
          [58.138516, 31.627659],
          [58.078237, 31.658771],
          [58.068237, 31.663213],
          [58.067124, 31.664044],
          [58.019905, 31.683491],
          [58.005458, 31.690434],
          [57.994898, 31.693766],
          [57.994345, 31.694046],
          [57.993512, 31.694326],
          [57.992958, 31.694597],
          [57.992778, 31.694687],
          [57.992398, 31.694877],
          [57.991669, 31.694877],
          [57.991292, 31.694876],
          [57.986611, 31.696357],
          [57.986012, 31.696547],
          [57.985966, 31.696547],
          [57.985178, 31.696547],
          [57.985088, 31.696637],
          [57.984898, 31.696827],
          [57.983986, 31.696827],
          [57.983512, 31.696827],
          [57.966565, 31.70321],
          [57.964066, 31.70405],
          [57.944346, 31.710713],
          [57.866287, 31.72849],
          [57.864804, 31.728988],
          [57.86462, 31.72905],
          [57.858142, 31.730086],
          [57.857674, 31.730161],
          [57.785448, 31.746268],
          [57.757948, 31.753491],
          [57.756555, 31.75405],
          [57.749335, 31.755441],
          [57.748237, 31.755804],
          [57.747669, 31.755992],
          [57.746971, 31.755992],
          [57.746555, 31.755992],
          [57.742388, 31.757382],
          [57.741275, 31.757942],
          [57.739889, 31.758213],
          [57.739553, 31.758382],
          [57.739055, 31.758493],
          [57.738775, 31.758764],
          [57.732942, 31.760154],
          [57.726417, 31.761916],
          [57.725722, 31.762104],
          [57.725011, 31.762104],
          [57.724609, 31.762104],
          [57.690442, 31.773219],
          [57.688776, 31.773769],
          [57.683679, 31.775382],
          [57.683496, 31.77544],
          [57.682676, 31.775573],
          [57.681829, 31.775711],
          [57.666276, 31.781552],
          [57.617937, 31.798219],
          [57.600166, 31.802195],
          [57.599331, 31.802382],
          [57.598589, 31.802382],
          [57.598217, 31.802382],
          [57.590991, 31.804603],
          [57.585991, 31.807104],
          [57.583491, 31.807664],
          [57.579051, 31.809325],
          [57.568491, 31.812106],
          [57.567104, 31.812656],
          [57.560438, 31.814887],
          [57.551271, 31.817108],
          [57.539878, 31.820999],
          [57.537658, 31.822101],
          [57.533492, 31.823491],
          [57.511825, 31.828222],
          [57.504702, 31.830136],
          [57.504598, 31.830163],
          [57.502507, 31.830541],
          [57.501545, 31.830714],
          [57.500712, 31.831274],
          [57.496545, 31.832385],
          [57.475836, 31.836363],
          [57.474879, 31.836547],
          [57.448766, 31.838768],
          [57.445986, 31.839048],
          [57.44299, 31.839048],
          [57.442933, 31.839048],
          [57.442462, 31.839142],
          [57.441539, 31.839328],
          [57.435433, 31.839328],
          [57.425153, 31.839879],
          [57.423206, 31.840159],
          [57.420153, 31.840158],
          [57.417933, 31.840438],
          [57.412344, 31.840438],
          [57.4121, 31.840438],
          [57.411723, 31.840533],
          [57.410986, 31.840718],
          [57.404873, 31.840718],
          [57.402654, 31.840998],
          [57.39932, 31.840998],
          [57.395153, 31.841549],
          [57.39182, 31.841549],
          [57.385987, 31.842109],
          [57.383732, 31.842109],
          [57.383487, 31.842109],
          [57.383109, 31.842204],
          [57.382374, 31.842389],
          [57.37626, 31.842389],
          [57.371858, 31.842641],
          [57.37154, 31.84266],
          [57.371162, 31.842755],
          [57.370427, 31.842939],
          [57.368001, 31.842939],
          [57.367927, 31.842939],
          [57.339314, 31.848772],
          [57.328481, 31.854605],
          [57.294592, 31.86655],
          [57.292092, 31.867661],
          [57.253778, 31.878209],
          [57.253755, 31.878216],
          [57.238756, 31.880996],
          [57.234032, 31.881547],
          [57.203476, 31.887109],
          [57.200143, 31.88738],
          [57.19542, 31.88822],
          [57.192366, 31.8885],
          [57.179587, 31.890441],
          [57.170143, 31.890992],
          [57.16764, 31.89128],
          [57.163754, 31.89128],
          [57.144584, 31.892942],
          [57.103477, 31.899472],
          [57.102637, 31.899605],
          [57.064305, 31.912941],
          [57.049302, 31.919334],
          [57.034025, 31.928778],
          [57.016802, 31.936001],
          [57.005969, 31.938493],
          [57.000229, 31.942874],
          [57.000136, 31.942945],
          [56.984812, 31.944857],
          [56.984579, 31.944886],
          [56.940468, 31.953869],
          [56.939577, 31.95405],
          [56.93791, 31.95405],
          [56.913187, 31.958222],
          [56.911782, 31.958222],
          [56.91152, 31.958222],
          [56.911128, 31.95832],
          [56.910407, 31.958502],
          [56.908464, 31.958502],
          [56.897567, 31.959582],
          [56.89735, 31.959603],
          [56.896817, 31.959714],
          [56.895964, 31.959892],
          [56.885131, 31.960163],
          [56.884017, 31.959892],
          [56.867074, 31.959892],
          [56.858738, 31.959053],
          [56.855213, 31.959053],
          [56.855128, 31.959053],
          [56.85454, 31.959171],
          [56.853738, 31.959332],
          [56.845961, 31.960163],
          [56.827348, 31.959892],
          [56.825681, 31.960163],
          [56.773459, 31.955721],
          [56.766789, 31.95405],
          [56.750956, 31.951279],
          [56.745956, 31.950719],
          [56.715676, 31.946557],
          [56.67484, 31.939053],
          [56.672897, 31.939053],
          [56.66984, 31.938494],
          [56.650117, 31.935442],
          [56.637894, 31.93211],
          [56.631227, 31.93016],
          [56.630951, 31.929889],
          [56.630394, 31.929889],
          [56.630117, 31.929609],
          [56.629284, 31.929609],
          [56.627618, 31.929058],
          [56.626227, 31.929058],
          [56.624284, 31.928498],
          [56.622894, 31.928499],
          [56.609004, 31.924336],
          [56.607894, 31.923496],
          [56.604838, 31.922106],
          [56.604561, 31.917944],
          [56.604284, 31.915163],
          [56.604004, 31.912662],
          [56.603728, 31.911831],
          [56.604284, 31.904328],
          [56.604838, 31.894333],
          [56.606228, 31.875164],
          [56.606504, 31.868212],
          [56.605951, 31.863779],
          [56.605951, 31.860718],
          [56.605118, 31.854606],
          [56.604838, 31.852935],
          [56.604561, 31.851274],
          [56.599838, 31.829054],
          [56.599561, 31.823492],
          [56.599281, 31.821831],
          [56.599004, 31.821271],
          [56.599004, 31.82016],
          [56.598448, 31.816549],
          [56.592061, 31.784885],
          [56.590671, 31.778773],
          [56.590395, 31.778493],
          [56.585395, 31.762656],
          [56.579838, 31.745439],
          [56.579558, 31.744608],
          [56.577058, 31.737656],
          [56.576781, 31.737385],
          [56.576781, 31.737105],
          [56.576781, 31.732654],
          [56.579281, 31.714606],
          [56.579281, 31.711545],
          [56.579004, 31.711274],
          [56.578448, 31.710994],
          [56.577058, 31.710994],
          [56.576504, 31.710714],
          [56.572891, 31.710714],
          [56.572615, 31.710435],
          [56.569907, 31.710435],
          [56.569281, 31.710434],
          [56.56908, 31.710536],
          [56.568725, 31.710714],
          [56.566781, 31.710714],
          [56.565948, 31.710435],
          [56.558448, 31.710434],
          [56.557058, 31.710155],
          [56.538725, 31.707663],
          [56.504555, 31.690436],
          [56.495112, 31.685714],
          [56.417607, 31.639326],
          [56.408164, 31.632374],
          [56.400383, 31.627101],
          [56.367051, 31.604883],
          [56.315381, 31.578493],
          [56.305935, 31.574042],
          [56.304825, 31.573771],
          [56.273435, 31.561267],
          [56.255655, 31.553494],
          [56.254822, 31.553494],
          [56.247046, 31.551543],
          [56.245656, 31.551543],
          [56.244546, 31.551264],
          [56.242042, 31.551264],
          [56.241766, 31.550993],
          [56.239542, 31.551264],
          [56.238989, 31.550993],
          [56.237599, 31.550993],
          [56.232599, 31.551264],
          [56.232043, 31.550993],
          [56.231489, 31.550993],
          [56.230932, 31.550713],
          [56.229542, 31.550713],
          [56.229266, 31.550433],
          [56.226766, 31.550433],
          [56.224823, 31.549873],
          [56.223152, 31.549602],
          [56.220933, 31.549602],
          [56.220653, 31.549323],
          [56.218986, 31.549323],
          [56.218153, 31.549043],
          [56.216766, 31.549043],
          [56.215933, 31.548763],
          [56.212876, 31.548492],
          [56.209342, 31.548474],
          [56.178671, 31.548318],
          [56.178429, 31.548317],
          [56.160502, 31.552608],
          [56.151077, 31.560119],
          [56.146939, 31.566504],
          [56.14341, 31.57195],
          [56.139341, 31.580684],
          [56.134284, 31.5851],
          [56.128949, 31.587826],
          [56.125159, 31.589715],
          [56.121878, 31.590506],
          [56.121195, 31.590671],
          [56.115575, 31.590862],
          [56.1137, 31.590709],
          [56.112387, 31.590716],
          [56.111017, 31.590085],
          [56.107804, 31.588605],
          [56.099566, 31.586764],
          [56.093491, 31.587345],
          [56.087544, 31.585668],
          [56.06281, 31.578688],
          [56.059222, 31.576543],
          [56.058148, 31.576543],
          [56.056758, 31.576272],
          [56.053702, 31.576272],
          [56.053425, 31.575992],
          [56.052592, 31.575992],
          [56.048978, 31.575153],
          [56.042035, 31.573491],
          [56.035089, 31.570431],
          [56.029255, 31.564878],
          [56.028979, 31.564048],
          [56.025922, 31.559317],
          [56.023979, 31.554324],
          [56.022312, 31.544881],
          [56.022035, 31.542931],
          [56.021755, 31.54099],
          [56.021479, 31.538489],
          [56.021202, 31.535717],
          [56.020922, 31.525985],
          [56.020645, 31.521823],
          [56.020089, 31.509039],
          [56.019812, 31.508488],
          [56.020089, 31.506818],
          [56.020645, 31.495434],
          [56.020369, 31.492653],
          [56.020089, 31.490432],
          [56.019812, 31.487651],
          [56.019535, 31.486821],
          [56.019535, 31.485431],
          [56.018145, 31.477377],
          [56.017869, 31.475987],
          [56.012865, 31.464874],
          [55.996476, 31.448488],
          [55.967033, 31.432933],
          [55.965643, 31.431543],
          [55.963976, 31.430712],
          [55.959253, 31.424871],
          [55.956476, 31.419599],
          [55.955643, 31.417658],
          [55.955643, 31.416818],
          [55.946196, 31.395431],
          [55.945086, 31.39182],
          [55.945086, 31.39071],
          [55.943537, 31.387481],
          [55.93842, 31.376816],
          [55.934806, 31.373205],
          [55.926753, 31.367373],
          [55.845638, 31.325151],
          [55.843691, 31.3246],
          [55.834248, 31.320989],
          [55.829805, 31.318759],
          [55.823135, 31.314868],
          [55.820358, 31.313207],
          [55.772855, 31.291261],
          [55.765912, 31.288481],
          [55.765632, 31.28793],
          [55.759799, 31.28487],
          [55.759522, 31.284599],
          [55.758965, 31.284039],
          [55.756465, 31.282929],
          [55.755632, 31.282649],
          [55.755079, 31.282369],
          [55.740076, 31.274597],
          [55.719519, 31.267375],
          [55.718133, 31.266544],
          [55.684796, 31.255982],
          [55.67813, 31.254592],
          [55.671183, 31.253761],
          [55.668683, 31.253761],
          [55.64896, 31.25071],
          [55.63646, 31.249871],
          [55.607014, 31.245709],
          [55.600904, 31.243759],
          [55.581181, 31.231816],
          [55.581181, 31.231536],
          [55.580348, 31.230977],
          [55.505622, 31.190986],
          [55.500066, 31.188486],
          [55.494789, 31.185976],
          [55.492566, 31.185425],
          [55.491732, 31.184866],
          [55.488676, 31.184035],
          [55.487842, 31.183485],
          [55.485066, 31.182925],
          [55.46062, 31.175983],
          [55.460063, 31.175423],
          [55.45951, 31.175423],
          [55.44312, 31.168482],
          [55.424783, 31.161811],
          [55.419507, 31.16015],
          [55.399507, 31.151818],
          [55.376727, 31.144596],
          [55.366171, 31.142096],
          [55.326168, 31.129593],
          [55.325058, 31.129593],
          [55.315058, 31.127093],
          [55.312835, 31.126253],
          [55.289222, 31.117922],
          [55.288112, 31.117922],
          [55.287835, 31.117642],
          [55.277832, 31.115141],
          [55.276999, 31.114871],
          [55.276445, 31.114591],
          [55.276165, 31.114311],
          [55.275332, 31.114311],
          [55.275151, 31.114128],
          [55.275055, 31.114031],
          [55.270889, 31.11265],
          [55.269499, 31.11265],
          [55.26926, 31.112529],
          [55.268946, 31.11237],
          [55.267832, 31.11237],
          [55.267556, 31.11209],
          [55.266999, 31.11209],
          [55.266722, 31.11181],
          [55.265612, 31.11126],
          [55.264779, 31.1107],
          [55.264222, 31.11042],
          [55.263946, 31.11015],
          [55.259499, 31.1082],
          [55.21061, 31.091257],
          [55.18894, 31.085145],
          [55.184773, 31.084315],
          [55.133661, 31.072364],
          [55.108938, 31.070143],
          [55.101991, 31.069312],
          [55.097825, 31.068473],
          [55.076715, 31.065702],
          [55.067825, 31.064032],
          [55.057545, 31.062371],
          [55.055602, 31.062091],
          [55.054492, 31.061811],
          [55.042545, 31.059871],
          [55.040602, 31.059871],
          [55.039769, 31.059591],
          [55.038655, 31.059591],
          [55.021712, 31.05626],
          [54.96532, 31.034867],
          [54.965043, 31.034587],
          [54.95643, 31.030697],
          [54.95143, 31.029586],
          [54.949207, 31.029587],
          [54.948937, 31.029313],
          [54.94893, 31.029307],
          [54.948097, 31.029036],
          [54.94754, 31.028756],
          [54.94643, 31.028196],
          [54.943373, 31.027646],
          [54.939763, 31.027646],
          [54.93865, 31.027366],
          [54.93504, 31.027095],
          [54.927174, 31.027095],
          [54.926707, 31.027095],
          [54.926382, 31.027201],
          [54.925874, 31.027366],
          [54.924764, 31.027366],
          [54.915317, 31.028476],
          [54.886536, 31.033319],
          [54.885594, 31.033477],
          [54.837975, 31.042617],
          [54.837815, 31.042648],
          [54.805589, 31.047929],
          [54.793963, 31.049545],
          [54.793646, 31.049589],
          [54.784479, 31.051259],
          [54.719113, 31.065538],
          [54.718363, 31.065702],
          [54.716353, 31.065702],
          [54.715863, 31.065702],
          [54.715517, 31.065818],
          [54.71503, 31.065982],
          [54.711697, 31.065982],
          [54.71114, 31.065702],
          [54.70892, 31.065702],
          [54.696974, 31.062371],
          [54.689474, 31.059591],
          [54.684197, 31.057921],
          [54.681974, 31.057091],
          [54.680307, 31.056811],
          [54.668361, 31.0532],
          [54.666417, 31.05292],
          [54.655304, 31.05042],
          [54.653918, 31.05042],
          [54.653084, 31.05014],
          [54.645028, 31.04903],
          [54.643084, 31.048479],
          [54.641418, 31.048479],
          [54.640584, 31.048199],
          [54.612805, 31.042919],
          [54.580578, 31.031257],
          [54.577245, 31.030146],
          [54.543636, 31.020705],
          [54.543079, 31.020145],
          [54.538632, 31.018204],
          [54.538079, 31.018204],
          [54.535299, 31.015975],
          [54.534746, 31.015424],
          [54.533356, 31.014314],
          [54.533079, 31.014034],
          [54.533079, 31.013754],
          [54.532246, 31.012924],
          [54.531966, 31.012084],
          [54.525579, 31.002092],
          [54.525856, 30.999872],
          [54.525299, 30.991811],
          [54.525579, 30.990971],
          [54.526689, 30.973199],
          [54.526413, 30.971258],
          [54.528632, 30.941535],
          [54.529746, 30.924033],
          [54.530856, 30.918193],
          [54.538632, 30.8907],
          [54.541412, 30.870139],
          [54.543912, 30.831256],
          [54.543912, 30.829026],
          [54.546689, 30.804305],
          [54.546689, 30.801255],
          [54.547522, 30.794585],
          [54.555302, 30.743754],
          [54.556412, 30.729024],
          [54.555579, 30.725694],
          [54.555579, 30.724304],
          [54.555579, 30.723745],
          [54.555579, 30.722914],
          [54.555022, 30.721525],
          [54.555855, 30.713745],
          [54.556135, 30.708745],
          [54.555855, 30.707635],
          [54.556135, 30.707084],
          [54.555855, 30.705694],
          [54.556135, 30.704305],
          [54.555855, 30.703754],
          [54.556412, 30.691525],
          [54.557245, 30.687635],
          [54.557245, 30.685975],
          [54.557802, 30.683746],
          [54.562522, 30.650967],
          [54.563079, 30.649577],
          [54.565579, 30.619859],
          [54.565302, 30.609859],
          [54.565025, 30.60791],
          [54.563079, 30.596801],
          [54.559469, 30.582632],
          [54.558635, 30.580972],
          [54.557522, 30.578192],
          [54.552522, 30.563744],
          [54.530022, 30.531528],
          [54.513909, 30.512081],
          [54.48002, 30.478466],
          [54.476686, 30.475687],
          [54.450854, 30.45513],
          [54.450574, 30.45458],
          [54.45002, 30.4543],
          [54.44974, 30.454021],
          [54.344184, 30.392773],
          [54.342515, 30.391803],
          [54.340568, 30.389854],
          [54.338068, 30.386795],
          [54.337515, 30.386515],
          [54.336682, 30.385125],
          [54.336682, 30.380966],
        ],
      ],
    },
  },
  Kermanshah: {
    properties: { value: "Kermanshah", name: "استان کرمانشاه, ایران", id: 27 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [45.407661, 33.977255],
          [45.409135, 33.976761],
          [45.412044, 33.975201],
          [45.413407, 33.974283],
          [45.414953, 33.973082],
          [45.415933, 33.97188],
          [45.41714, 33.969983],
          [45.417241, 33.969639],
          [45.417227, 33.969391],
          [45.417496, 33.96895],
          [45.41758, 33.967746],
          [45.41777, 33.967058],
          [45.418269, 33.966132],
          [45.419089, 33.965108],
          [45.419189, 33.964506],
          [45.419134, 33.963786],
          [45.420033, 33.962923],
          [45.420788, 33.961857],
          [45.420788, 33.961738],
          [45.420159, 33.959753],
          [45.420158, 33.959634],
          [45.420467, 33.959365],
          [45.420736, 33.959009],
          [45.421109, 33.958782],
          [45.421534, 33.958856],
          [45.42165, 33.958813],
          [45.421804, 33.958661],
          [45.421985, 33.958672],
          [45.422459, 33.958036],
          [45.423217, 33.957571],
          [45.424016, 33.957321],
          [45.42448, 33.957287],
          [45.424814, 33.957028],
          [45.426294, 33.956454],
          [45.426447, 33.956066],
          [45.427181, 33.955903],
          [45.427758, 33.955267],
          [45.428531, 33.954931],
          [45.429868, 33.954096],
          [45.429993, 33.953966],
          [45.429931, 33.953094],
          [45.429965, 33.952571],
          [45.429797, 33.951842],
          [45.42977, 33.951392],
          [45.429673, 33.951097],
          [45.429849, 33.950997],
          [45.430047, 33.950486],
          [45.42986, 33.94952],
          [45.429898, 33.949417],
          [45.430128, 33.949161],
          [45.430167, 33.949037],
          [45.430189, 33.948449],
          [45.430831, 33.94776],
          [45.431054, 33.946971],
          [45.431109, 33.946416],
          [45.431065, 33.946079],
          [45.431309, 33.945793],
          [45.431354, 33.945628],
          [45.431545, 33.945556],
          [45.43182, 33.945599],
          [45.432113, 33.945737],
          [45.432294, 33.945764],
          [45.432815, 33.94632],
          [45.433255, 33.946508],
          [45.433615, 33.946719],
          [45.434125, 33.946804],
          [45.434432, 33.94674],
          [45.434719, 33.94648],
          [45.435418, 33.94634],
          [45.435637, 33.946666],
          [45.435832, 33.946727],
          [45.436051, 33.947031],
          [45.436382, 33.947283],
          [45.436987, 33.947385],
          [45.437145, 33.947484],
          [45.437523, 33.947437],
          [45.437754, 33.947507],
          [45.437968, 33.947458],
          [45.438258, 33.947541],
          [45.438418, 33.947668],
          [45.438565, 33.947951],
          [45.439061, 33.947954],
          [45.439371, 33.948048],
          [45.439549, 33.948014],
          [45.439903, 33.947512],
          [45.440173, 33.947297],
          [45.440454, 33.9472],
          [45.441518, 33.947265],
          [45.441824, 33.947768],
          [45.441985, 33.94776],
          [45.442122, 33.94765],
          [45.442278, 33.94705],
          [45.442744, 33.9469],
          [45.442935, 33.946781],
          [45.443399, 33.946813],
          [45.443601, 33.946762],
          [45.443768, 33.946586],
          [45.443933, 33.946546],
          [45.444036, 33.946622],
          [45.444112, 33.946846],
          [45.444106, 33.947107],
          [45.444259, 33.947393],
          [45.444477, 33.947571],
          [45.444516, 33.947949],
          [45.444787, 33.947975],
          [45.445105, 33.948128],
          [45.445275, 33.948105],
          [45.445464, 33.947764],
          [45.445672, 33.947619],
          [45.445986, 33.947545],
          [45.446582, 33.947607],
          [45.446817, 33.947671],
          [45.44706, 33.947817],
          [45.44726, 33.947803],
          [45.447413, 33.947593],
          [45.447416, 33.947416],
          [45.447698, 33.947134],
          [45.448013, 33.94699],
          [45.448838, 33.946741],
          [45.449027, 33.946632],
          [45.449832, 33.946557],
          [45.449976, 33.946432],
          [45.450033, 33.946246],
          [45.449997, 33.945928],
          [45.450064, 33.945782],
          [45.450808, 33.945217],
          [45.450955, 33.945008],
          [45.451211, 33.94485],
          [45.451516, 33.944806],
          [45.451774, 33.944871],
          [45.451935, 33.945026],
          [45.452186, 33.945134],
          [45.453776, 33.945373],
          [45.454183, 33.94559],
          [45.454678, 33.945751],
          [45.454967, 33.945756],
          [45.455638, 33.945642],
          [45.455837, 33.945647],
          [45.456092, 33.945912],
          [45.456384, 33.94593],
          [45.456733, 33.94605],
          [45.457005, 33.946052],
          [45.457161, 33.945953],
          [45.457312, 33.94578],
          [45.457722, 33.944972],
          [45.457929, 33.944943],
          [45.458565, 33.945012],
          [45.458907, 33.945096],
          [45.459415, 33.945102],
          [45.459796, 33.945161],
          [45.460045, 33.945237],
          [45.46035, 33.945545],
          [45.460637, 33.945614],
          [45.460839, 33.94573],
          [45.460991, 33.945752],
          [45.461817, 33.945576],
          [45.4621, 33.945888],
          [45.462289, 33.945996],
          [45.462849, 33.94605],
          [45.463045, 33.946139],
          [45.463461, 33.946609],
          [45.463504, 33.946718],
          [45.463545, 33.946842],
          [45.463389, 33.947396],
          [45.463729, 33.94792],
          [45.463884, 33.948406],
          [45.46428, 33.948717],
          [45.46455, 33.948813],
          [45.464788, 33.948862],
          [45.465235, 33.948493],
          [45.466001, 33.948254],
          [45.46634, 33.948282],
          [45.466964, 33.94865],
          [45.467282, 33.94871],
          [45.467576, 33.94883],
          [45.467778, 33.949038],
          [45.467977, 33.94914],
          [45.468224, 33.949114],
          [45.468456, 33.948998],
          [45.468593, 33.948818],
          [45.468779, 33.948417],
          [45.468979, 33.948357],
          [45.469594, 33.948336],
          [45.469907, 33.94842],
          [45.47052, 33.948294],
          [45.470793, 33.948305],
          [45.471056, 33.948462],
          [45.47117, 33.948657],
          [45.471379, 33.948776],
          [45.471663, 33.948832],
          [45.471919, 33.949125],
          [45.472184, 33.949291],
          [45.473508, 33.949411],
          [45.474152, 33.949344],
          [45.474485, 33.949439],
          [45.474843, 33.949474],
          [45.47539, 33.949288],
          [45.476809, 33.949211],
          [45.477187, 33.949141],
          [45.477469, 33.949165],
          [45.477686, 33.94932],
          [45.478243, 33.949527],
          [45.478718, 33.949534],
          [45.479102, 33.949657],
          [45.480022, 33.949569],
          [45.480349, 33.949405],
          [45.480919, 33.948413],
          [45.481348, 33.948112],
          [45.481825, 33.947886],
          [45.48205, 33.947627],
          [45.482369, 33.94752],
          [45.482605, 33.947519],
          [45.482901, 33.947624],
          [45.483668, 33.948122],
          [45.484144, 33.94837],
          [45.48461, 33.948503],
          [45.485038, 33.94874],
          [45.48528, 33.948975],
          [45.485958, 33.949218],
          [45.486531, 33.949705],
          [45.486876, 33.949899],
          [45.487917, 33.95013],
          [45.488377, 33.950121],
          [45.489298, 33.949726],
          [45.490154, 33.94963],
          [45.490857, 33.94972],
          [45.491341, 33.949619],
          [45.49185, 33.949629],
          [45.492336, 33.949797],
          [45.492728, 33.950072],
          [45.493461, 33.950334],
          [45.493806, 33.950408],
          [45.494109, 33.950591],
          [45.494439, 33.951052],
          [45.494657, 33.951258],
          [45.494866, 33.951308],
          [45.495619, 33.951298],
          [45.49603, 33.951412],
          [45.496382, 33.951448],
          [45.496688, 33.951454],
          [45.497967, 33.950932],
          [45.498517, 33.950876],
          [45.49893, 33.950976],
          [45.499409, 33.951024],
          [45.500405, 33.95097],
          [45.500965, 33.950884],
          [45.50126, 33.951105],
          [45.501437, 33.951397],
          [45.501506, 33.95183],
          [45.501646, 33.951989],
          [45.502122, 33.952213],
          [45.502228, 33.952326],
          [45.502385, 33.952394],
          [45.502405, 33.952525],
          [45.502324, 33.952941],
          [45.50246, 33.953086],
          [45.502731, 33.953182],
          [45.502947, 33.953165],
          [45.503305, 33.952968],
          [45.503704, 33.952936],
          [45.503865, 33.952882],
          [45.50395, 33.952767],
          [45.50405, 33.952454],
          [45.504411, 33.952219],
          [45.504295, 33.951825],
          [45.504337, 33.951716],
          [45.50494, 33.95141],
          [45.505265, 33.951353],
          [45.505311, 33.95129],
          [45.505201, 33.951062],
          [45.505191, 33.950925],
          [45.505291, 33.950825],
          [45.5055, 33.950759],
          [45.505701, 33.950773],
          [45.505803, 33.950728],
          [45.505864, 33.95063],
          [45.506253, 33.950512],
          [45.506395, 33.950396],
          [45.506459, 33.950265],
          [45.506755, 33.949948],
          [45.506741, 33.9497],
          [45.507023, 33.949367],
          [45.507042, 33.949257],
          [45.507154, 33.94916],
          [45.50762, 33.948941],
          [45.507662, 33.948619],
          [45.507771, 33.948486],
          [45.508306, 33.948343],
          [45.508371, 33.948217],
          [45.508389, 33.947913],
          [45.508715, 33.947744],
          [45.508976, 33.947465],
          [45.509183, 33.94739],
          [45.509367, 33.947379],
          [45.509586, 33.94719],
          [45.509663, 33.947039],
          [45.509943, 33.946887],
          [45.510033, 33.946771],
          [45.510033, 33.946488],
          [45.510136, 33.94635],
          [45.510448, 33.946217],
          [45.510638, 33.945977],
          [45.51148, 33.945656],
          [45.511585, 33.945574],
          [45.511539, 33.945335],
          [45.511359, 33.945077],
          [45.511404, 33.944935],
          [45.51139, 33.944664],
          [45.511445, 33.944585],
          [45.511757, 33.944498],
          [45.511863, 33.944425],
          [45.511918, 33.943948],
          [45.512078, 33.943625],
          [45.512067, 33.943506],
          [45.511889, 33.94328],
          [45.511906, 33.943166],
          [45.512095, 33.942894],
          [45.51211, 33.94265],
          [45.511789, 33.942364],
          [45.511941, 33.942177],
          [45.512282, 33.942024],
          [45.512329, 33.941887],
          [45.512266, 33.941721],
          [45.512314, 33.941449],
          [45.51245, 33.941357],
          [45.512764, 33.941279],
          [45.51286, 33.941184],
          [45.512946, 33.940767],
          [45.512945, 33.940619],
          [45.512862, 33.940511],
          [45.512545, 33.940363],
          [45.512526, 33.940209],
          [45.512633, 33.939737],
          [45.512884, 33.939446],
          [45.512981, 33.939259],
          [45.512944, 33.938917],
          [45.512768, 33.938579],
          [45.512861, 33.938425],
          [45.513305, 33.938065],
          [45.513868, 33.937923],
          [45.514869, 33.937418],
          [45.515112, 33.936498],
          [45.515711, 33.936007],
          [45.516127, 33.935884],
          [45.516383, 33.935708],
          [45.516517, 33.935371],
          [45.516435, 33.934884],
          [45.516317, 33.934578],
          [45.516478, 33.934214],
          [45.51675, 33.933984],
          [45.516783, 33.93384],
          [45.516604, 33.933586],
          [45.516415, 33.933454],
          [45.516308, 33.933267],
          [45.516311, 33.93271],
          [45.516366, 33.932492],
          [45.516561, 33.932294],
          [45.516652, 33.932038],
          [45.517044, 33.931766],
          [45.516962, 33.931519],
          [45.517054, 33.931217],
          [45.517274, 33.930959],
          [45.517263, 33.930724],
          [45.517329, 33.930574],
          [45.517375, 33.930177],
          [45.517226, 33.92984],
          [45.51722, 33.92972],
          [45.517334, 33.929581],
          [45.517739, 33.929344],
          [45.51781, 33.92924],
          [45.517806, 33.929078],
          [45.51767, 33.92884],
          [45.517761, 33.928418],
          [45.518058, 33.928198],
          [45.518148, 33.928058],
          [45.518235, 33.927877],
          [45.518276, 33.927481],
          [45.518613, 33.927218],
          [45.518622, 33.927068],
          [45.518491, 33.926802],
          [45.518353, 33.926648],
          [45.518367, 33.926539],
          [45.518531, 33.926188],
          [45.518553, 33.925952],
          [45.518962, 33.925399],
          [45.518977, 33.925035],
          [45.519298, 33.924538],
          [45.519266, 33.924121],
          [45.519312, 33.924007],
          [45.519737, 33.923827],
          [45.519747, 33.923394],
          [45.51986, 33.923301],
          [45.520002, 33.923237],
          [45.520406, 33.92325],
          [45.520636, 33.923176],
          [45.520893, 33.922861],
          [45.52097, 33.922547],
          [45.521256, 33.921903],
          [45.521334, 33.921612],
          [45.521631, 33.921323],
          [45.521923, 33.921201],
          [45.521962, 33.921056],
          [45.521921, 33.920956],
          [45.521701, 33.920718],
          [45.521685, 33.920414],
          [45.52146, 33.920205],
          [45.521454, 33.920062],
          [45.522019, 33.919662],
          [45.522357, 33.919639],
          [45.522434, 33.919538],
          [45.522636, 33.919417],
          [45.522796, 33.919145],
          [45.523298, 33.919124],
          [45.523616, 33.91923],
          [45.524088, 33.919269],
          [45.52427, 33.919253],
          [45.524387, 33.919178],
          [45.52446, 33.919036],
          [45.524433, 33.918874],
          [45.524597, 33.91862],
          [45.524385, 33.918209],
          [45.524953, 33.917853],
          [45.525002, 33.917659],
          [45.524937, 33.917317],
          [45.525269, 33.917082],
          [45.525303, 33.916896],
          [45.525484, 33.91669],
          [45.525517, 33.916523],
          [45.525937, 33.916487],
          [45.526603, 33.916633],
          [45.526894, 33.916623],
          [45.527867, 33.916218],
          [45.527749, 33.915987],
          [45.527746, 33.915575],
          [45.52851, 33.914931],
          [45.528552, 33.914804],
          [45.528503, 33.914622],
          [45.528521, 33.914419],
          [45.528956, 33.914186],
          [45.529097, 33.914047],
          [45.528928, 33.913723],
          [45.528898, 33.913477],
          [45.528969, 33.913308],
          [45.52909, 33.913177],
          [45.529727, 33.91299],
          [45.530977, 33.912262],
          [45.531701, 33.911968],
          [45.532488, 33.911844],
          [45.532747, 33.911751],
          [45.53359, 33.911952],
          [45.534079, 33.911761],
          [45.534552, 33.911735],
          [45.534699, 33.911665],
          [45.535543, 33.910794],
          [45.535633, 33.910678],
          [45.535627, 33.910465],
          [45.535935, 33.910081],
          [45.536025, 33.909802],
          [45.536063, 33.90949],
          [45.536008, 33.909263],
          [45.535743, 33.908745],
          [45.535405, 33.908281],
          [45.535218, 33.908112],
          [45.534362, 33.907714],
          [45.534209, 33.907479],
          [45.534164, 33.907222],
          [45.534397, 33.906317],
          [45.534559, 33.906147],
          [45.534771, 33.906043],
          [45.535011, 33.90599],
          [45.535532, 33.906044],
          [45.535871, 33.906002],
          [45.536274, 33.906016],
          [45.536828, 33.905693],
          [45.536899, 33.905589],
          [45.536909, 33.905258],
          [45.53703, 33.904919],
          [45.537549, 33.904338],
          [45.538286, 33.903323],
          [45.538727, 33.902324],
          [45.539542, 33.901667],
          [45.539925, 33.901251],
          [45.54043, 33.900872],
          [45.54049, 33.900698],
          [45.540658, 33.900502],
          [45.540887, 33.900374],
          [45.541341, 33.899887],
          [45.541588, 33.89969],
          [45.5418, 33.899422],
          [45.54265, 33.89936],
          [45.542868, 33.899239],
          [45.543108, 33.898747],
          [45.543158, 33.898444],
          [45.543278, 33.898272],
          [45.543498, 33.898073],
          [45.543978, 33.8979],
          [45.544594, 33.897542],
          [45.544715, 33.897403],
          [45.544826, 33.896498],
          [45.545205, 33.896089],
          [45.545226, 33.895834],
          [45.545369, 33.895763],
          [45.545344, 33.895509],
          [45.545522, 33.895328],
          [45.545936, 33.895105],
          [45.546094, 33.89484],
          [45.546373, 33.894692],
          [45.546483, 33.89456],
          [45.546373, 33.894172],
          [45.5464, 33.893998],
          [45.54664, 33.893857],
          [45.546782, 33.893693],
          [45.546856, 33.893407],
          [45.547134, 33.893227],
          [45.547111, 33.892864],
          [45.547287, 33.892618],
          [45.547451, 33.892521],
          [45.547785, 33.892557],
          [45.548556, 33.892382],
          [45.549084, 33.89213],
          [45.549363, 33.892158],
          [45.549519, 33.892232],
          [45.549721, 33.892239],
          [45.549926, 33.892174],
          [45.550251, 33.891768],
          [45.550216, 33.891527],
          [45.55026, 33.891345],
          [45.550485, 33.891108],
          [45.550627, 33.890856],
          [45.550729, 33.890489],
          [45.551122, 33.889826],
          [45.551222, 33.889219],
          [45.551415, 33.888845],
          [45.55158, 33.888808],
          [45.551859, 33.888518],
          [45.551946, 33.888319],
          [45.551965, 33.887965],
          [45.552066, 33.887884],
          [45.552301, 33.887836],
          [45.552399, 33.887684],
          [45.55245, 33.887326],
          [45.55267, 33.887215],
          [45.552818, 33.887077],
          [45.552893, 33.88694],
          [45.55284, 33.88674],
          [45.553389, 33.886003],
          [45.553589, 33.885337],
          [45.553824, 33.885087],
          [45.554105, 33.8846],
          [45.55425, 33.884479],
          [45.554362, 33.884013],
          [45.554659, 33.88383],
          [45.554856, 33.88347],
          [45.554953, 33.88305],
          [45.555282, 33.882632],
          [45.555672, 33.881926],
          [45.555972, 33.881611],
          [45.556076, 33.881425],
          [45.556129, 33.88099],
          [45.556287, 33.880697],
          [45.55639, 33.880594],
          [45.556605, 33.880489],
          [45.556912, 33.88003],
          [45.556946, 33.879741],
          [45.557124, 33.87939],
          [45.557345, 33.879076],
          [45.557778, 33.87879],
          [45.558045, 33.878415],
          [45.558105, 33.878159],
          [45.558807, 33.877448],
          [45.558918, 33.877151],
          [45.559086, 33.876955],
          [45.559854, 33.876185],
          [45.559922, 33.875873],
          [45.560124, 33.875453],
          [45.560482, 33.875041],
          [45.560494, 33.874487],
          [45.56084, 33.874373],
          [45.561028, 33.874119],
          [45.561117, 33.873782],
          [45.561574, 33.873279],
          [45.562095, 33.873176],
          [45.562296, 33.873008],
          [45.562357, 33.872839],
          [45.562262, 33.872455],
          [45.562326, 33.872269],
          [45.562499, 33.872095],
          [45.562651, 33.872033],
          [45.562878, 33.871833],
          [45.562971, 33.87166],
          [45.562895, 33.870955],
          [45.563059, 33.870886],
          [45.56336, 33.870893],
          [45.563844, 33.870594],
          [45.563982, 33.870244],
          [45.563857, 33.869828],
          [45.563992, 33.869635],
          [45.564237, 33.869514],
          [45.564327, 33.869254],
          [45.564339, 33.869044],
          [45.564641, 33.868869],
          [45.564803, 33.86869],
          [45.565037, 33.868134],
          [45.565035, 33.867806],
          [45.565203, 33.867665],
          [45.565419, 33.867653],
          [45.565534, 33.867602],
          [45.565624, 33.867487],
          [45.565658, 33.867274],
          [45.565865, 33.866987],
          [45.565846, 33.866702],
          [45.565941, 33.866554],
          [45.566119, 33.866011],
          [45.566114, 33.865799],
          [45.566605, 33.865503],
          [45.566767, 33.865152],
          [45.566978, 33.865057],
          [45.56712, 33.864794],
          [45.56712, 33.864317],
          [45.567196, 33.864169],
          [45.56753, 33.863914],
          [45.567752, 33.86365],
          [45.567733, 33.863092],
          [45.568, 33.862864],
          [45.568209, 33.86276],
          [45.568297, 33.862623],
          [45.568357, 33.862116],
          [45.568703, 33.861726],
          [45.568774, 33.861414],
          [45.568977, 33.861122],
          [45.569067, 33.860862],
          [45.569458, 33.860511],
          [45.569555, 33.859962],
          [45.56996, 33.859685],
          [45.57026, 33.859267],
          [45.570297, 33.85908],
          [45.570474, 33.858982],
          [45.570727, 33.858594],
          [45.571288, 33.857979],
          [45.5715, 33.8575],
          [45.571821, 33.857031],
          [45.57204, 33.856855],
          [45.572112, 33.856499],
          [45.572302, 33.856242],
          [45.572306, 33.856069],
          [45.572401, 33.85595],
          [45.572789, 33.855698],
          [45.572934, 33.855547],
          [45.573065, 33.855146],
          [45.573383, 33.85484],
          [45.573414, 33.854668],
          [45.573361, 33.854397],
          [45.5734, 33.85432],
          [45.573623, 33.854203],
          [45.573844, 33.853908],
          [45.574047, 33.853776],
          [45.574431, 33.853393],
          [45.574688, 33.852789],
          [45.574854, 33.852657],
          [45.575384, 33.851666],
          [45.575636, 33.851402],
          [45.57596, 33.850914],
          [45.576072, 33.850622],
          [45.57633, 33.850413],
          [45.576525, 33.850043],
          [45.576936, 33.849634],
          [45.576992, 33.849283],
          [45.577071, 33.849194],
          [45.577454, 33.849009],
          [45.577706, 33.848786],
          [45.577846, 33.848529],
          [45.57784, 33.848267],
          [45.578089, 33.848108],
          [45.578249, 33.847936],
          [45.578307, 33.847697],
          [45.578485, 33.847516],
          [45.578527, 33.847293],
          [45.578479, 33.847158],
          [45.578525, 33.847041],
          [45.579015, 33.846464],
          [45.579368, 33.846294],
          [45.579443, 33.846184],
          [45.579653, 33.845492],
          [45.580179, 33.844871],
          [45.580657, 33.844523],
          [45.580984, 33.844105],
          [45.580965, 33.843835],
          [45.581053, 33.843569],
          [45.581417, 33.84343],
          [45.581828, 33.843175],
          [45.582018, 33.842743],
          [45.58215, 33.842566],
          [45.582302, 33.842129],
          [45.582814, 33.841344],
          [45.582779, 33.841062],
          [45.582596, 33.840732],
          [45.582648, 33.840565],
          [45.582863, 33.840532],
          [45.583092, 33.840603],
          [45.583204, 33.840596],
          [45.583296, 33.840448],
          [45.583475, 33.84036],
          [45.583522, 33.840229],
          [45.58378, 33.840077],
          [45.583808, 33.839938],
          [45.584188, 33.839685],
          [45.584271, 33.839073],
          [45.584463, 33.838682],
          [45.584906, 33.83835],
          [45.585216, 33.837932],
          [45.585237, 33.837795],
          [45.585345, 33.837641],
          [45.585671, 33.837325],
          [45.58579, 33.836899],
          [45.585421, 33.836682],
          [45.585102, 33.836122],
          [45.585078, 33.835553],
          [45.585192, 33.835369],
          [45.585224, 33.834915],
          [45.585156, 33.834778],
          [45.58514, 33.834548],
          [45.585032, 33.834425],
          [45.585087, 33.834263],
          [45.584997, 33.83394],
          [45.585037, 33.83374],
          [45.584865, 33.833137],
          [45.584621, 33.832984],
          [45.584667, 33.832779],
          [45.58463, 33.832621],
          [45.584693, 33.832515],
          [45.585248, 33.832429],
          [45.585652, 33.832451],
          [45.585835, 33.83251],
          [45.586178, 33.832495],
          [45.58634, 33.832317],
          [45.586714, 33.832165],
          [45.586795, 33.832057],
          [45.586802, 33.831853],
          [45.586734, 33.831661],
          [45.586784, 33.831487],
          [45.587218, 33.830869],
          [45.587553, 33.830551],
          [45.587875, 33.830471],
          [45.588292, 33.829944],
          [45.588444, 33.829868],
          [45.588681, 33.82987],
          [45.588837, 33.829823],
          [45.589629, 33.829279],
          [45.589836, 33.828877],
          [45.590059, 33.828648],
          [45.590132, 33.828442],
          [45.590555, 33.827966],
          [45.59117, 33.827793],
          [45.59152, 33.827564],
          [45.591907, 33.827641],
          [45.592006, 33.827581],
          [45.592087, 33.827295],
          [45.592363, 33.826992],
          [45.592835, 33.826875],
          [45.593189, 33.826491],
          [45.593599, 33.826365],
          [45.593762, 33.826235],
          [45.593918, 33.82568],
          [45.594034, 33.825492],
          [45.594187, 33.825378],
          [45.594385, 33.825126],
          [45.594521, 33.824864],
          [45.59445, 33.824713],
          [45.5945, 33.824599],
          [45.595228, 33.824568],
          [45.59538, 33.824475],
          [45.595474, 33.823914],
          [45.595743, 33.823636],
          [45.595895, 33.823358],
          [45.596428, 33.823261],
          [45.596683, 33.823025],
          [45.59683, 33.82281],
          [45.597348, 33.822576],
          [45.597567, 33.82234],
          [45.597654, 33.82214],
          [45.597635, 33.821916],
          [45.597785, 33.821627],
          [45.598279, 33.821605],
          [45.59873, 33.821384],
          [45.598786, 33.821091],
          [45.599169, 33.820933],
          [45.599251, 33.820726],
          [45.599201, 33.820361],
          [45.599725, 33.819836],
          [45.599818, 33.819606],
          [45.600151, 33.819343],
          [45.600409, 33.819281],
          [45.600628, 33.819135],
          [45.601013, 33.818566],
          [45.601207, 33.818456],
          [45.599542, 33.810392],
          [45.603272, 33.803023],
          [45.607034, 33.800299],
          [45.609719, 33.798976],
          [45.629784, 33.789094],
          [45.629744, 33.788826],
          [45.630014, 33.788712],
          [45.63036, 33.788378],
          [45.63048, 33.788224],
          [45.630437, 33.788125],
          [45.630489, 33.788002],
          [45.630955, 33.787654],
          [45.631047, 33.787501],
          [45.631504, 33.78733],
          [45.631803, 33.787157],
          [45.631943, 33.786915],
          [45.632179, 33.78676],
          [45.632297, 33.786742],
          [45.632417, 33.786617],
          [45.632588, 33.786289],
          [45.632912, 33.786088],
          [45.633123, 33.786012],
          [45.633312, 33.785794],
          [45.633396, 33.785568],
          [45.633625, 33.78537],
          [45.633824, 33.785324],
          [45.633996, 33.785211],
          [45.634172, 33.784972],
          [45.634168, 33.784866],
          [45.63497, 33.784214],
          [45.635245, 33.783871],
          [45.635427, 33.78379],
          [45.635732, 33.783407],
          [45.635781, 33.783273],
          [45.636013, 33.783153],
          [45.636137, 33.782947],
          [45.636351, 33.782837],
          [45.636577, 33.782532],
          [45.636589, 33.78218],
          [45.636979, 33.782085],
          [45.637077, 33.781997],
          [45.637134, 33.781845],
          [45.637413, 33.781721],
          [45.637484, 33.781543],
          [45.637585, 33.781444],
          [45.637578, 33.781191],
          [45.637685, 33.780927],
          [45.638153, 33.780608],
          [45.638466, 33.780184],
          [45.638493, 33.779834],
          [45.63876, 33.77951],
          [45.639132, 33.779215],
          [45.639246, 33.77895],
          [45.639607, 33.778477],
          [45.639731, 33.778203],
          [45.639944, 33.777997],
          [45.640069, 33.777746],
          [45.640211, 33.777599],
          [45.640282, 33.777399],
          [45.640289, 33.777053],
          [45.640332, 33.776921],
          [45.64052, 33.776674],
          [45.640588, 33.77635],
          [45.6408, 33.776093],
          [45.641203, 33.775741],
          [45.641328, 33.775491],
          [45.641693, 33.774989],
          [45.641795, 33.774708],
          [45.642428, 33.774158],
          [45.64246, 33.773825],
          [45.642663, 33.773192],
          [45.643015, 33.772991],
          [45.643222, 33.772741],
          [45.643433, 33.772343],
          [45.643764, 33.772],
          [45.643841, 33.771844],
          [45.643833, 33.771727],
          [45.644034, 33.771337],
          [45.644158, 33.770855],
          [45.644758, 33.769848],
          [45.644999, 33.769597],
          [45.645245, 33.768758],
          [45.645402, 33.768619],
          [45.645517, 33.768404],
          [45.645666, 33.767911],
          [45.646047, 33.767485],
          [45.646419, 33.766542],
          [45.646417, 33.766305],
          [45.646715, 33.766082],
          [45.64672, 33.765962],
          [45.646994, 33.765704],
          [45.647097, 33.765542],
          [45.64706, 33.765397],
          [45.647115, 33.765189],
          [45.647269, 33.764966],
          [45.64735, 33.764639],
          [45.647983, 33.763768],
          [45.648138, 33.763686],
          [45.648214, 33.763411],
          [45.647746, 33.763302],
          [45.647625, 33.763205],
          [45.647337, 33.762859],
          [45.647108, 33.762676],
          [45.646857, 33.762038],
          [45.646668, 33.76178],
          [45.646513, 33.761605],
          [45.646079, 33.761447],
          [45.645943, 33.760501],
          [45.646108, 33.760214],
          [45.646136, 33.759982],
          [45.646081, 33.759601],
          [45.645954, 33.759384],
          [45.646129, 33.758897],
          [45.646034, 33.758691],
          [45.645814, 33.75847],
          [45.645651, 33.758184],
          [45.645298, 33.757955],
          [45.645272, 33.757763],
          [45.64536, 33.757514],
          [45.645363, 33.757316],
          [45.645046, 33.757069],
          [45.644582, 33.75681],
          [45.644408, 33.756548],
          [45.644196, 33.756376],
          [45.644045, 33.756282],
          [45.643797, 33.756256],
          [45.643247, 33.755754],
          [45.642766, 33.755241],
          [45.6427, 33.75495],
          [45.642739, 33.754663],
          [45.64261, 33.75455],
          [45.642018, 33.754438],
          [45.641355, 33.754193],
          [45.641215, 33.754032],
          [45.640831, 33.753962],
          [45.64061, 33.753645],
          [45.640445, 33.753622],
          [45.640322, 33.753519],
          [45.640297, 33.753028],
          [45.640126, 33.7528],
          [45.640029, 33.752574],
          [45.639819, 33.752399],
          [45.639717, 33.752216],
          [45.639928, 33.75155],
          [45.639931, 33.751386],
          [45.639837, 33.751244],
          [45.639688, 33.751158],
          [45.639646, 33.751051],
          [45.639919, 33.750711],
          [45.639995, 33.750341],
          [45.639896, 33.750101],
          [45.639957, 33.749914],
          [45.640166, 33.749714],
          [45.640216, 33.749608],
          [45.640146, 33.749486],
          [45.640006, 33.749385],
          [45.640138, 33.74911],
          [45.64009, 33.748973],
          [45.639958, 33.748853],
          [45.639833, 33.748545],
          [45.639705, 33.748413],
          [45.639545, 33.747829],
          [45.639219, 33.747466],
          [45.639026, 33.746653],
          [45.639092, 33.746391],
          [45.638957, 33.74582],
          [45.639093, 33.745652],
          [45.639323, 33.745481],
          [45.639375, 33.745262],
          [45.639528, 33.745108],
          [45.639785, 33.745118],
          [45.640212, 33.744384],
          [45.640251, 33.744099],
          [45.640049, 33.743671],
          [45.64004, 33.743549],
          [45.640037, 33.743307],
          [45.640187, 33.742656],
          [45.640466, 33.742324],
          [45.640502, 33.741984],
          [45.640587, 33.741854],
          [45.640779, 33.741714],
          [45.641172, 33.740681],
          [45.640754, 33.740359],
          [45.64046, 33.74022],
          [45.640283, 33.740041],
          [45.640118, 33.739832],
          [45.639855, 33.739337],
          [45.640061, 33.739014],
          [45.640002, 33.738653],
          [45.640092, 33.738285],
          [45.640055, 33.738022],
          [45.640318, 33.737867],
          [45.640437, 33.737714],
          [45.640551, 33.737262],
          [45.640719, 33.736975],
          [45.640854, 33.736874],
          [45.6413, 33.736875],
          [45.641818, 33.73698],
          [45.642088, 33.736689],
          [45.642136, 33.736087],
          [45.641998, 33.735527],
          [45.641847, 33.734418],
          [45.64196, 33.734311],
          [45.642104, 33.733966],
          [45.642367, 33.733743],
          [45.642898, 33.733555],
          [45.643349, 33.733214],
          [45.643621, 33.733103],
          [45.643705, 33.732922],
          [45.643938, 33.732785],
          [45.644369, 33.732778],
          [45.644677, 33.732685],
          [45.644806, 33.732693],
          [45.646508, 33.732149],
          [45.646565, 33.729599],
          [45.646649, 33.729271],
          [45.646893, 33.728985],
          [45.647241, 33.728215],
          [45.647451, 33.728021],
          [45.647933, 33.727749],
          [45.648184, 33.727716],
          [45.648447, 33.727748],
          [45.648934, 33.727656],
          [45.649699, 33.727022],
          [45.650396, 33.727013],
          [45.650822, 33.726854],
          [45.652005, 33.726679],
          [45.652096, 33.72657],
          [45.652542, 33.72648],
          [45.652783, 33.726274],
          [45.652825, 33.726091],
          [45.652929, 33.725957],
          [45.653047, 33.725917],
          [45.653814, 33.725864],
          [45.653834, 33.725725],
          [45.654019, 33.725609],
          [45.654343, 33.725685],
          [45.654808, 33.725563],
          [45.654996, 33.725063],
          [45.654954, 33.724784],
          [45.655053, 33.724702],
          [45.655238, 33.724631],
          [45.655432, 33.72466],
          [45.655665, 33.724568],
          [45.655997, 33.724507],
          [45.656587, 33.724484],
          [45.656857, 33.724379],
          [45.657157, 33.724466],
          [45.657637, 33.724025],
          [45.658065, 33.723804],
          [45.65833, 33.723401],
          [45.658861, 33.723436],
          [45.658963, 33.723364],
          [45.659005, 33.723204],
          [45.65891, 33.722907],
          [45.658935, 33.722642],
          [45.659165, 33.722699],
          [45.659405, 33.722682],
          [45.659675, 33.722304],
          [45.659885, 33.722118],
          [45.659809, 33.721981],
          [45.659344, 33.721694],
          [45.65916, 33.721136],
          [45.659195, 33.721009],
          [45.659329, 33.720927],
          [45.659566, 33.720899],
          [45.659736, 33.720826],
          [45.659877, 33.720655],
          [45.659881, 33.720539],
          [45.659661, 33.720426],
          [45.659553, 33.72029],
          [45.659542, 33.720057],
          [45.65965, 33.719773],
          [45.659868, 33.71968],
          [45.660066, 33.719298],
          [45.660479, 33.717989],
          [45.660431, 33.717808],
          [45.6605, 33.717684],
          [45.660211, 33.717066],
          [45.660228, 33.716926],
          [45.660364, 33.716848],
          [45.660602, 33.716799],
          [45.661139, 33.715858],
          [45.661384, 33.715725],
          [45.661687, 33.715411],
          [45.662163, 33.715362],
          [45.662388, 33.715556],
          [45.662937, 33.715809],
          [45.663089, 33.715762],
          [45.663229, 33.715634],
          [45.663138, 33.715183],
          [45.662924, 33.714959],
          [45.662881, 33.714624],
          [45.663042, 33.714323],
          [45.663159, 33.714227],
          [45.663322, 33.714216],
          [45.663591, 33.71427],
          [45.664123, 33.714165],
          [45.664255, 33.714072],
          [45.664184, 33.713688],
          [45.663868, 33.713218],
          [45.663758, 33.712836],
          [45.664127, 33.712344],
          [45.664503, 33.712032],
          [45.664529, 33.711944],
          [45.664438, 33.711558],
          [45.664633, 33.711065],
          [45.664727, 33.710652],
          [45.665042, 33.710423],
          [45.665105, 33.710257],
          [45.665334, 33.709985],
          [45.665357, 33.709254],
          [45.665605, 33.708935],
          [45.668773, 33.706713],
          [45.669, 33.70672],
          [45.669059, 33.706687],
          [45.669106, 33.706422],
          [45.669244, 33.706298],
          [45.66945, 33.706181],
          [45.669687, 33.706155],
          [45.669855, 33.706204],
          [45.670072, 33.706137],
          [45.67017, 33.706012],
          [45.670227, 33.705822],
          [45.670483, 33.705713],
          [45.670878, 33.70567],
          [45.670955, 33.705438],
          [45.670954, 33.705207],
          [45.67108, 33.704992],
          [45.67173, 33.704683],
          [45.672224, 33.704763],
          [45.673041, 33.704329],
          [45.673136, 33.703924],
          [45.672974, 33.703389],
          [45.673287, 33.702991],
          [45.673246, 33.702785],
          [45.673126, 33.702661],
          [45.673066, 33.702505],
          [45.673212, 33.702248],
          [45.673839, 33.701551],
          [45.673762, 33.700802],
          [45.674029, 33.70062],
          [45.673943, 33.70034],
          [45.674095, 33.700024],
          [45.674055, 33.699533],
          [45.674334, 33.69907],
          [45.674509, 33.698905],
          [45.674718, 33.698829],
          [45.675134, 33.698717],
          [45.675491, 33.698722],
          [45.675695, 33.698846],
          [45.675879, 33.698884],
          [45.676085, 33.69886],
          [45.676337, 33.698968],
          [45.676782, 33.699227],
          [45.677114, 33.699574],
          [45.677422, 33.699511],
          [45.67775, 33.699328],
          [45.677448, 33.69897],
          [45.677238, 33.698521],
          [45.677268, 33.698335],
          [45.677367, 33.697964],
          [45.677858, 33.697655],
          [45.678043, 33.697371],
          [45.678268, 33.697193],
          [45.678401, 33.696865],
          [45.678403, 33.696329],
          [45.678673, 33.695514],
          [45.678895, 33.695348],
          [45.679157, 33.695294],
          [45.679403, 33.695314],
          [45.679973, 33.695005],
          [45.68019, 33.695086],
          [45.680415, 33.695061],
          [45.680635, 33.694978],
          [45.680981, 33.694934],
          [45.681223, 33.694813],
          [45.681885, 33.694173],
          [45.682408, 33.694016],
          [45.682549, 33.693889],
          [45.682705, 33.693638],
          [45.683044, 33.693398],
          [45.683079, 33.693239],
          [45.688797, 33.690916],
          [45.689217, 33.690886],
          [45.690207, 33.690935],
          [45.691379, 33.691167],
          [45.69252, 33.69097],
          [45.698314, 33.693593],
          [45.69934, 33.694079],
          [45.699616, 33.694359],
          [45.700726, 33.694359],
          [45.704893, 33.695469],
          [45.705449, 33.695749],
          [45.706559, 33.695749],
          [45.707116, 33.696029],
          [45.708783, 33.696029],
          [45.709339, 33.696309],
          [45.710729, 33.696309],
          [45.713506, 33.696309],
          [45.720173, 33.70075],
          [45.725729, 33.703802],
          [45.728786, 33.703531],
          [45.731839, 33.702421],
          [45.732952, 33.703251],
          [45.735172, 33.703251],
          [45.736005, 33.702141],
          [45.736286, 33.70075],
          [45.736286, 33.700471],
          [45.737396, 33.69964],
          [45.739619, 33.699911],
          [45.742673, 33.702141],
          [45.746562, 33.703802],
          [45.747119, 33.704082],
          [45.747395, 33.703802],
          [45.748509, 33.703802],
          [45.749342, 33.702971],
          [45.749342, 33.70131],
          [45.749619, 33.701021],
          [45.751285, 33.69964],
          [45.751562, 33.69936],
          [45.754062, 33.69936],
          [45.754619, 33.698801],
          [45.755452, 33.69797],
          [45.755452, 33.69769],
          [45.756562, 33.69769],
          [45.757119, 33.69797],
          [45.758228, 33.69797],
          [45.762952, 33.700751],
          [45.770175, 33.704082],
          [45.770452, 33.705472],
          [45.770175, 33.706023],
          [45.767118, 33.706862],
          [45.768508, 33.711583],
          [45.769065, 33.711863],
          [45.771565, 33.711863],
          [45.778232, 33.718255],
          [45.778508, 33.719636],
          [45.779065, 33.721026],
          [45.780451, 33.722416],
          [45.785175, 33.723807],
          [45.785732, 33.723807],
          [45.787122, 33.724357],
          [45.787955, 33.724637],
          [45.789065, 33.724637],
          [45.801842, 33.732969],
          [45.819344, 33.758805],
          [45.821011, 33.761026],
          [45.847677, 33.790473],
          [45.85101, 33.794923],
          [45.8549, 33.804086],
          [45.855177, 33.805476],
          [45.8549, 33.805747],
          [45.855734, 33.814088],
          [45.850457, 33.828811],
          [45.850457, 33.832692],
          [45.85101, 33.835473],
          [45.857957, 33.842144],
          [45.859624, 33.843254],
          [45.861847, 33.844364],
          [45.87129, 33.849645],
          [45.872123, 33.849645],
          [45.87268, 33.849925],
          [45.879067, 33.849925],
          [45.887679, 33.848255],
          [45.888513, 33.848255],
          [45.89768, 33.843254],
          [45.909626, 33.838533],
          [45.909903, 33.838253],
          [45.91296, 33.837702],
          [45.913793, 33.837423],
          [45.917126, 33.837423],
          [45.921569, 33.837973],
          [45.922126, 33.838253],
          [45.923236, 33.838253],
          [45.932402, 33.841034],
          [45.937682, 33.841304],
          [45.938239, 33.841584],
          [45.939906, 33.841584],
          [45.940739, 33.841864],
          [45.942126, 33.841864],
          [45.957962, 33.849925],
          [45.958796, 33.850755],
          [45.961296, 33.851586],
          [45.962129, 33.852146],
          [45.962682, 33.852696],
          [45.963239, 33.852976],
          [45.969348, 33.858808],
          [45.972405, 33.864359],
          [45.972682, 33.864648],
          [45.972682, 33.866869],
          [45.955739, 33.874641],
          [45.954625, 33.874641],
          [45.952682, 33.87548],
          [45.951572, 33.87548],
          [45.929626, 33.882701],
          [45.928793, 33.882972],
          [45.889346, 33.905476],
          [45.888513, 33.906315],
          [45.859343, 33.921588],
          [45.85879, 33.922148],
          [45.857676, 33.922698],
          [45.85379, 33.926589],
          [45.844624, 33.949092],
          [45.846009, 33.955203],
          [45.849899, 33.962975],
          [45.849899, 33.963535],
          [45.851843, 33.969366],
          [45.857399, 33.978816],
          [45.857681, 33.979095],
          [45.8674, 33.989088],
          [45.867956, 33.989088],
          [45.867956, 33.989368],
          [45.87379, 33.989368],
          [45.875733, 33.988808],
          [45.87629, 33.988538],
          [45.912402, 33.970756],
          [45.918515, 33.969095],
          [45.919349, 33.968815],
          [45.921292, 33.968815],
          [45.921569, 33.968535],
          [45.923516, 33.968535],
          [45.924348, 33.968256],
          [45.925735, 33.968256],
          [45.926016, 33.967976],
          [45.927402, 33.967976],
          [45.927959, 33.967696],
          [45.932125, 33.967696],
          [45.932682, 33.967425],
          [45.933792, 33.967696],
          [45.934902, 33.967425],
          [45.935459, 33.967696],
          [45.937405, 33.967425],
          [45.937959, 33.967425],
          [45.943515, 33.967696],
          [45.944625, 33.967976],
          [45.946292, 33.967976],
          [45.947405, 33.968256],
          [45.952958, 33.968256],
          [45.954348, 33.968535],
          [45.960738, 33.968535],
          [45.961572, 33.968815],
          [45.964349, 33.968815],
          [45.967681, 33.969366],
          [45.968238, 33.969646],
          [45.969905, 33.969646],
          [45.979351, 33.971586],
          [45.979905, 33.971866],
          [45.981018, 33.971866],
          [45.982404, 33.972417],
          [45.983238, 33.972417],
          [46.013241, 33.984648],
          [46.013798, 33.984648],
          [46.022964, 33.99992],
          [46.03852, 34.016032],
          [46.05491, 34.037424],
          [46.05491, 34.037704],
          [46.058797, 34.037704],
          [46.069077, 34.036594],
          [46.069353, 34.036314],
          [46.070743, 34.036314],
          [46.074633, 34.034365],
          [46.077686, 34.032144],
          [46.080466, 34.030763],
          [46.0813, 34.029924],
          [46.082133, 34.029644],
          [46.084076, 34.027423],
          [46.084633, 34.026313],
          [46.086577, 34.024364],
          [46.093523, 34.021593],
          [46.094633, 34.021033],
          [46.096576, 34.021033],
          [46.099356, 34.020203],
          [46.100743, 34.020203],
          [46.111023, 34.014922],
          [46.127384, 33.999929],
          [46.140189, 33.99603],
          [46.140189, 33.99576],
          [46.141579, 33.9952],
          [46.148526, 33.989929],
          [46.149359, 33.989929],
          [46.153802, 33.988809],
          [46.156579, 33.988809],
          [46.157412, 33.989089],
          [46.164635, 33.989089],
          [46.165192, 33.988809],
          [46.168249, 33.987979],
          [46.179636, 33.980198],
          [46.194916, 33.972418],
          [46.195472, 33.972147],
          [46.200195, 33.970197],
          [46.202415, 33.969646],
          [46.213248, 33.965756],
          [46.214639, 33.965756],
          [46.215195, 33.965476],
          [46.225195, 33.963815],
          [46.229085, 33.962425],
          [46.230472, 33.962425],
          [46.232418, 33.961866],
          [46.233805, 33.961866],
          [46.245195, 33.959365],
          [46.246308, 33.958815],
          [46.278254, 33.950763],
          [46.278807, 33.950763],
          [46.279921, 33.950763],
          [46.281588, 33.950204],
          [46.290474, 33.954365],
          [46.291307, 33.957145],
          [46.291031, 33.957425],
          [46.291307, 33.958956],
          [46.291307, 33.959645],
          [46.291031, 33.960485],
          [46.289087, 33.965756],
          [46.291864, 33.982419],
          [46.295197, 33.9927],
          [46.297697, 33.99493],
          [46.298254, 33.9952],
          [46.298531, 33.99548],
          [46.306864, 33.99131],
          [46.30742, 33.991039],
          [46.34909, 33.972707],
          [46.355756, 33.970486],
          [46.392146, 33.952424],
          [46.39298, 33.951865],
          [46.468262, 33.912979],
          [46.469652, 33.911868],
          [46.471039, 33.911318],
          [46.471872, 33.910758],
          [46.472705, 33.910758],
          [46.484929, 33.905757],
          [46.499652, 33.900756],
          [46.501318, 33.897976],
          [46.541598, 33.869921],
          [46.541878, 33.869641],
          [46.558264, 33.86159],
          [46.559101, 33.861589],
          [46.559378, 33.86131],
          [46.559934, 33.86131],
          [46.560211, 33.86103],
          [46.581601, 33.849647],
          [46.582434, 33.849087],
          [46.583544, 33.848816],
          [46.589934, 33.845477],
          [46.589934, 33.845197],
          [46.590767, 33.844926],
          [46.61549, 33.834085],
          [46.616047, 33.833814],
          [46.616324, 33.833534],
          [46.61688, 33.833534],
          [46.61688, 33.833254],
          [46.62077, 33.831313],
          [46.621047, 33.831034],
          [46.621324, 33.830754],
          [46.621604, 33.830203],
          [46.635213, 33.819371],
          [46.641121, 33.814491],
          [46.66077, 33.798257],
          [46.66577, 33.794926],
          [46.667993, 33.793256],
          [46.67077, 33.790475],
          [46.671327, 33.789365],
          [46.693273, 33.759368],
          [46.695216, 33.757147],
          [46.702717, 33.752976],
          [46.705217, 33.752146],
          [46.712996, 33.749366],
          [46.713553, 33.748806],
          [46.714386, 33.748535],
          [46.724106, 33.742144],
          [46.725219, 33.742144],
          [46.725219, 33.741864],
          [46.726886, 33.741864],
          [46.726886, 33.742144],
          [46.727967, 33.742144],
          [46.72883, 33.742144],
          [46.729662, 33.742144],
          [46.729943, 33.741864],
          [46.73133, 33.741864],
          [46.731886, 33.741864],
          [46.732443, 33.741584],
          [46.733552, 33.741584],
          [46.734109, 33.741864],
          [46.736052, 33.741864],
          [46.736329, 33.742144],
          [46.739386, 33.742694],
          [46.750499, 33.752976],
          [46.751332, 33.754367],
          [46.752443, 33.757418],
          [46.755499, 33.7677],
          [46.758832, 33.771031],
          [46.759942, 33.771031],
          [46.760499, 33.771311],
          [46.762718, 33.771311],
          [46.763552, 33.771031],
          [46.764665, 33.771031],
          [46.765218, 33.770751],
          [46.766332, 33.770751],
          [46.766608, 33.770471],
          [46.768832, 33.770201],
          [46.770499, 33.769361],
          [46.777166, 33.7677],
          [46.779109, 33.76714],
          [46.779942, 33.76714],
          [46.829392, 33.749086],
          [46.829945, 33.748535],
          [46.830778, 33.748255],
          [46.833835, 33.746026],
          [46.838835, 33.743254],
          [46.842725, 33.741305],
          [46.858281, 33.733812],
          [46.859114, 33.733812],
          [46.859667, 33.733532],
          [46.862725, 33.733532],
          [46.863004, 33.733252],
          [46.868838, 33.733252],
          [46.869671, 33.733532],
          [46.871891, 33.733532],
          [46.872724, 33.733812],
          [46.873837, 33.733812],
          [46.888876, 33.740078],
          [46.88967, 33.740483],
          [46.894947, 33.741864],
          [46.896894, 33.742415],
          [46.900228, 33.743534],
          [46.900504, 33.744085],
          [46.913283, 33.757698],
          [46.914117, 33.761309],
          [46.914117, 33.762699],
          [46.913837, 33.76325],
          [46.91467, 33.764369],
          [46.91467, 33.76492],
          [46.91495, 33.7652],
          [46.91717, 33.76798],
          [46.918007, 33.768251],
          [46.919673, 33.768251],
          [46.920227, 33.768531],
          [46.924394, 33.768531],
          [46.935506, 33.76381],
          [46.93634, 33.76381],
          [46.936897, 33.76353],
          [46.938006, 33.76353],
          [46.944673, 33.760479],
          [46.944949, 33.759919],
          [46.946617, 33.758809],
          [46.947173, 33.758529],
          [46.94773, 33.757698],
          [46.948563, 33.757148],
          [46.961896, 33.744365],
          [46.962453, 33.744365],
          [46.965787, 33.742695],
          [46.966896, 33.742695],
          [46.967173, 33.742415],
          [46.968563, 33.742144],
          [46.97412, 33.742144],
          [46.974676, 33.741865],
          [46.97523, 33.742144],
          [46.977453, 33.743535],
          [46.978563, 33.744365],
          [46.979396, 33.745475],
          [46.987453, 33.758529],
          [46.988286, 33.76242],
          [46.993566, 33.772972],
          [46.998843, 33.781864],
          [46.999119, 33.783534],
          [46.999546, 33.786585],
          [46.998009, 33.795197],
          [46.997732, 33.795477],
          [46.997732, 33.800749],
          [46.996899, 33.805479],
          [46.997175, 33.81159],
          [46.997732, 33.811311],
          [46.998286, 33.811311],
          [46.999607, 33.810751],
          [47.002452, 33.8102],
          [47.004399, 33.8102],
          [47.004952, 33.80992],
          [47.009952, 33.811031],
          [47.018566, 33.811031],
          [47.019398, 33.811311],
          [47.020789, 33.811311],
          [47.030789, 33.814371],
          [47.039955, 33.823813],
          [47.044398, 33.830204],
          [47.046622, 33.831865],
          [47.047179, 33.831865],
          [47.052178, 33.833255],
          [47.090515, 33.824364],
          [47.092734, 33.824364],
          [47.093848, 33.824924],
          [47.094681, 33.824924],
          [47.096068, 33.825474],
          [47.099401, 33.829925],
          [47.099681, 33.830484],
          [47.102458, 33.836866],
          [47.106348, 33.840477],
          [47.109404, 33.840206],
          [47.112738, 33.838536],
          [47.126904, 33.823813],
          [47.126904, 33.823254],
          [47.12968, 33.820203],
          [47.129961, 33.819923],
          [47.13746, 33.814642],
          [47.139681, 33.814371],
          [47.140237, 33.814642],
          [47.147184, 33.814642],
          [47.14996, 33.815202],
          [47.152184, 33.815202],
          [47.153293, 33.815482],
          [47.154684, 33.815481],
          [47.160237, 33.816312],
          [47.16774, 33.816872],
          [47.177184, 33.816872],
          [47.17774, 33.816583],
          [47.183296, 33.815761],
          [47.189683, 33.813812],
          [47.19024, 33.813261],
          [47.190796, 33.813261],
          [47.192183, 33.812142],
          [47.194963, 33.809921],
          [47.206906, 33.80381],
          [47.21163, 33.802699],
          [47.213853, 33.802428],
          [47.219963, 33.802979],
          [47.230796, 33.80325],
          [47.231076, 33.803539],
          [47.233576, 33.80381],
          [47.234129, 33.80381],
          [47.234686, 33.80381],
          [47.236076, 33.80409],
          [47.238019, 33.80409],
          [47.239133, 33.804369],
          [47.243852, 33.804369],
          [47.244409, 33.80409],
          [47.245243, 33.80409],
          [47.246076, 33.80381],
          [47.249133, 33.80381],
          [47.249966, 33.80325],
          [47.251633, 33.802979],
          [47.280799, 33.789646],
          [47.284132, 33.788816],
          [47.285245, 33.788536],
          [47.287466, 33.788536],
          [47.288299, 33.788256],
          [47.293299, 33.788256],
          [47.293578, 33.788536],
          [47.295245, 33.788536],
          [47.304412, 33.791867],
          [47.307745, 33.796308],
          [47.308855, 33.801869],
          [47.309135, 33.812142],
          [47.311635, 33.816312],
          [47.315801, 33.819643],
          [47.318858, 33.821034],
          [47.335524, 33.813812],
          [47.337192, 33.813812],
          [47.341358, 33.814922],
          [47.343301, 33.816033],
          [47.343581, 33.816583],
          [47.344135, 33.816872],
          [47.358857, 33.839927],
          [47.360247, 33.842148],
          [47.360524, 33.842698],
          [47.371361, 33.85103],
          [47.372471, 33.85131],
          [47.378861, 33.85131],
          [47.380804, 33.8527],
          [47.383028, 33.853811],
          [47.386637, 33.861312],
          [47.383581, 33.870474],
          [47.383304, 33.873534],
          [47.383027, 33.874094],
          [47.383027, 33.875204],
          [47.382194, 33.876585],
          [47.381914, 33.877705],
          [47.379414, 33.881875],
          [47.37747, 33.884926],
          [47.359691, 33.902709],
          [47.359138, 33.902709],
          [47.353581, 33.90715],
          [47.350524, 33.90965],
          [47.319691, 33.939095],
          [47.313301, 33.952707],
          [47.314411, 33.956038],
          [47.314968, 33.957148],
          [47.318024, 33.960488],
          [47.318577, 33.960758],
          [47.319134, 33.961038],
          [47.320524, 33.961869],
          [47.321911, 33.961869],
          [47.322191, 33.962149],
          [47.324967, 33.962708],
          [47.325524, 33.962428],
          [47.326635, 33.962428],
          [47.326911, 33.962148],
          [47.327467, 33.962149],
          [47.329135, 33.961318],
          [47.329967, 33.961318],
          [47.338857, 33.958818],
          [47.342468, 33.957148],
          [47.36969, 33.944646],
          [47.370527, 33.943816],
          [47.375804, 33.941036],
          [47.378861, 33.939375],
          [47.39136, 33.930484],
          [47.394694, 33.930484],
          [47.39497, 33.930763],
          [47.395803, 33.930763],
          [47.40386, 33.935764],
          [47.40497, 33.937145],
          [47.417193, 33.951037],
          [47.418583, 33.951317],
          [47.42136, 33.952427],
          [47.422193, 33.952147],
          [47.423583, 33.952147],
          [47.424416, 33.952427],
          [47.426916, 33.952427],
          [47.427473, 33.952147],
          [47.429973, 33.952147],
          [47.430806, 33.951868],
          [47.437196, 33.951037],
          [47.438306, 33.950477],
          [47.452473, 33.946036],
          [47.454419, 33.945486],
          [47.454973, 33.945206],
          [47.455806, 33.945206],
          [47.460529, 33.944096],
          [47.462473, 33.944096],
          [47.463029, 33.943816],
          [47.465253, 33.943816],
          [47.465809, 33.943536],
          [47.476919, 33.942426],
          [47.477196, 33.942146],
          [47.479976, 33.942146],
          [47.480253, 33.943536],
          [47.481642, 33.944926],
          [47.483029, 33.945757],
          [47.485532, 33.946316],
          [47.487476, 33.946316],
          [47.492752, 33.948537],
          [47.493866, 33.952148],
          [47.492475, 33.954368],
          [47.492752, 33.956318],
          [47.493032, 33.956598],
          [47.494142, 33.958538],
          [47.493309, 33.959928],
          [47.492752, 33.963539],
          [47.492475, 33.964929],
          [47.497475, 33.97187],
          [47.498309, 33.972981],
          [47.498585, 33.975761],
          [47.498309, 33.976311],
          [47.498032, 33.977151],
          [47.496085, 33.979651],
          [47.488309, 33.984652],
          [47.484142, 33.988813],
          [47.481642, 33.992703],
          [47.481362, 33.999924],
          [47.481362, 34.001874],
          [47.480809, 34.003264],
          [47.479142, 34.003815],
          [47.478862, 34.004094],
          [47.477196, 34.004094],
          [47.476919, 34.004374],
          [47.476362, 34.004374],
          [47.474976, 34.006044],
          [47.473029, 34.010485],
          [47.467476, 34.007705],
          [47.466642, 34.007705],
          [47.464419, 34.009095],
          [47.463586, 34.010765],
          [47.463586, 34.011315],
          [47.459696, 34.020486],
          [47.458306, 34.022147],
          [47.449696, 34.026037],
          [47.449139, 34.026597],
          [47.448586, 34.026597],
          [47.445249, 34.031038],
          [47.445529, 34.035208],
          [47.443029, 34.038268],
          [47.442473, 34.038818],
          [47.441083, 34.040479],
          [47.440806, 34.041039],
          [47.437196, 34.046039],
          [47.436083, 34.048819],
          [47.436083, 34.049379],
          [47.434973, 34.05159],
          [47.432749, 34.05465],
          [47.432473, 34.055489],
          [47.42636, 34.060481],
          [47.423583, 34.064651],
          [47.423306, 34.066041],
          [47.423583, 34.068261],
          [47.425526, 34.074652],
          [47.424139, 34.076312],
          [47.426639, 34.084373],
          [47.428029, 34.087152],
          [47.428122, 34.087432],
          [47.428863, 34.088822],
          [47.429139, 34.089653],
          [47.431916, 34.096314],
          [47.432196, 34.096594],
          [47.433582, 34.098823],
          [47.433862, 34.099374],
          [47.442472, 34.103264],
          [47.453585, 34.106044],
          [47.467475, 34.107705],
          [47.473028, 34.108535],
          [47.475809, 34.109374],
          [47.476918, 34.109374],
          [47.477475, 34.109654],
          [47.478862, 34.109654],
          [47.481642, 34.110485],
          [47.482475, 34.110485],
          [47.486919, 34.113265],
          [47.486919, 34.113544],
          [47.488032, 34.115485],
          [47.489142, 34.121875],
          [47.488865, 34.122435],
          [47.476642, 34.154656],
          [47.476642, 34.158817],
          [47.479418, 34.172708],
          [47.483585, 34.178818],
          [47.483862, 34.179377],
          [47.486085, 34.181318],
          [47.488308, 34.182988],
          [47.497198, 34.186318],
          [47.497751, 34.186598],
          [47.499141, 34.186598],
          [47.499698, 34.186318],
          [47.501918, 34.186318],
          [47.510808, 34.184937],
          [47.511641, 34.184657],
          [47.518031, 34.184657],
          [47.520808, 34.184657],
          [47.529144, 34.185208],
          [47.547477, 34.179928],
          [47.551367, 34.179657],
          [47.552477, 34.179378],
          [47.554144, 34.179378],
          [47.555254, 34.179657],
          [47.557754, 34.179657],
          [47.559424, 34.180208],
          [47.560534, 34.180208],
          [47.564977, 34.182148],
          [47.576367, 34.193548],
          [47.57859, 34.196598],
          [47.594423, 34.213268],
          [47.61248, 34.222158],
          [47.614146, 34.222158],
          [47.614703, 34.222429],
          [47.618036, 34.222158],
          [47.619703, 34.221599],
          [47.62137, 34.221599],
          [47.622203, 34.221319],
          [47.62748, 34.221319],
          [47.628036, 34.221599],
          [47.629426, 34.221599],
          [47.638609, 34.226566],
          [47.639703, 34.227158],
          [47.640536, 34.227158],
          [47.649703, 34.234938],
          [47.649983, 34.235489],
          [47.651926, 34.237438],
          [47.694428, 34.276048],
          [47.695262, 34.277438],
          [47.697485, 34.282717],
          [47.697762, 34.287437],
          [47.698039, 34.289098],
          [47.697762, 34.289657],
          [47.683595, 34.327436],
          [47.683595, 34.328546],
          [47.683595, 34.329385],
          [47.683315, 34.329935],
          [47.683315, 34.332435],
          [47.683315, 34.332715],
          [47.681095, 34.340215],
          [47.680815, 34.341604],
          [47.680539, 34.342435],
          [47.679705, 34.346324],
          [47.679429, 34.347714],
          [47.679149, 34.349384],
          [47.678872, 34.351053],
          [47.679149, 34.356053],
          [47.679982, 34.359383],
          [47.692762, 34.373272],
          [47.693038, 34.373552],
          [47.694985, 34.375212],
          [47.695262, 34.375492],
          [47.696928, 34.376602],
          [47.699985, 34.377992],
          [47.700261, 34.378271],
          [47.701095, 34.378271],
          [47.701651, 34.377992],
          [47.704705, 34.377992],
          [47.705261, 34.377712],
          [47.708595, 34.377712],
          [47.723874, 34.374102],
          [47.725261, 34.374102],
          [47.728041, 34.373272],
          [47.729151, 34.373272],
          [47.730818, 34.372712],
          [47.731931, 34.372712],
          [47.761931, 34.355494],
          [47.763041, 34.354934],
          [47.765264, 34.353544],
          [47.765821, 34.353544],
          [47.766097, 34.353265],
          [47.780544, 34.342994],
          [47.784987, 34.340765],
          [47.796654, 34.336605],
          [47.80499, 34.334656],
          [47.809157, 34.333546],
          [47.828047, 34.323826],
          [47.833047, 34.318826],
          [47.838046, 34.303827],
          [47.839157, 34.299657],
          [47.839157, 34.299107],
          [47.846103, 34.283268],
          [47.846103, 34.282438],
          [47.847213, 34.279938],
          [47.84777, 34.279658],
          [47.848323, 34.278548],
          [47.852213, 34.274658],
          [47.874713, 34.263548],
          [47.886383, 34.255489],
          [47.886659, 34.255209],
          [47.88777, 34.261879],
          [47.891659, 34.267709],
          [47.891659, 34.267989],
          [47.892769, 34.267989],
          [47.901106, 34.273268],
          [47.906659, 34.281328],
          [47.913882, 34.295488],
          [47.913882, 34.295768],
          [47.916659, 34.297717],
          [47.940829, 34.319657],
          [47.945272, 34.326886],
          [47.950272, 34.339376],
          [47.952495, 34.345495],
          [47.963328, 34.355214],
          [47.977775, 34.359384],
          [47.978332, 34.359654],
          [47.993629, 34.364661],
          [47.988608, 34.397991],
          [47.989441, 34.40105],
          [47.989713, 34.401307],
          [47.990119, 34.401511],
          [47.990518, 34.401739],
          [47.998559, 34.401453],
          [48.001388, 34.40133],
          [48.013054, 34.39883],
          [48.013611, 34.39855],
          [48.014444, 34.39855],
          [48.014997, 34.39827],
          [48.016664, 34.39827],
          [48.017221, 34.39827],
          [48.018611, 34.39744],
          [48.019444, 34.396881],
          [48.034444, 34.390771],
          [48.040277, 34.389941],
          [48.040834, 34.389661],
          [48.044444, 34.389661],
          [48.044444, 34.389941],
          [48.045834, 34.389941],
          [48.047777, 34.390771],
          [48.055557, 34.39855],
          [48.055834, 34.39883],
          [48.057224, 34.401609],
          [48.06111, 34.408549],
          [48.07389, 34.412998],
          [48.074447, 34.413278],
          [48.075833, 34.413278],
          [48.08028, 34.414379],
          [48.083613, 34.416049],
          [48.085833, 34.418269],
          [48.090556, 34.432157],
          [48.090556, 34.432996],
          [48.09139, 34.433826],
          [48.09139, 34.434106],
          [48.091947, 34.434666],
          [48.093336, 34.443275],
          [48.093889, 34.445774],
          [48.095836, 34.450774],
          [48.09917, 34.454663],
          [48.105003, 34.467441],
          [48.103613, 34.471051],
          [48.103336, 34.471331],
          [48.102503, 34.47328],
          [48.097503, 34.479109],
          [48.090836, 34.492167],
          [48.090556, 34.493557],
          [48.09139, 34.498556],
          [48.098889, 34.510494],
          [48.101389, 34.521052],
          [48.101389, 34.525772],
          [48.101389, 34.52967],
          [48.100836, 34.530771],
          [48.097779, 34.538279],
          [48.084999, 34.550226],
          [48.083333, 34.554945],
          [48.087223, 34.571052],
          [48.086389, 34.574382],
          [48.085556, 34.57606],
          [48.06861, 34.594666],
          [48.06861, 34.594946],
          [48.068123, 34.59543],
          [48.052776, 34.61606],
          [48.051666, 34.618],
          [48.04972, 34.6205],
          [48.046667, 34.622999],
          [48.04611, 34.622999],
          [48.045833, 34.623279],
          [48.045, 34.623279],
          [48.044443, 34.623559],
          [48.043333, 34.623559],
          [48.043053, 34.623279],
          [48.042219, 34.623279],
          [48.037776, 34.608003],
          [48.038053, 34.607723],
          [48.03972, 34.601605],
          [48.04, 34.600225],
          [48.03972, 34.597445],
          [48.03722, 34.590777],
          [48.034443, 34.588557],
          [48.033053, 34.587998],
          [48.025277, 34.594946],
          [48.02222, 34.596326],
          [48.021943, 34.596606],
          [48.02111, 34.596606],
          [48.01333, 34.589387],
          [48.001107, 34.587438],
          [47.99944, 34.586608],
          [47.99305, 34.593276],
          [47.991384, 34.594666],
          [47.987497, 34.596886],
          [47.987217, 34.597165],
          [47.986107, 34.597166],
          [47.985274, 34.597725],
          [47.984441, 34.597725],
          [47.984164, 34.597996],
          [47.982774, 34.598275],
          [47.982217, 34.598275],
          [47.981941, 34.598555],
          [47.981107, 34.598555],
          [47.973327, 34.602444],
          [47.968327, 34.607753],
          [47.955271, 34.621609],
          [47.954994, 34.622169],
          [47.954717, 34.622719],
          [47.943051, 34.624939],
          [47.942494, 34.625219],
          [47.939438, 34.625219],
          [47.938604, 34.625498],
          [47.936938, 34.625498],
          [47.935828, 34.625778],
          [47.931938, 34.625778],
          [47.931381, 34.625498],
          [47.916658, 34.626608],
          [47.914991, 34.626888],
          [47.912214, 34.626888],
          [47.909991, 34.627168],
          [47.901381, 34.627168],
          [47.899438, 34.627727],
          [47.895268, 34.628837],
          [47.894435, 34.628837],
          [47.893882, 34.629117],
          [47.890825, 34.629116],
          [47.886658, 34.628277],
          [47.883882, 34.628277],
          [47.883325, 34.627998],
          [47.880268, 34.627998],
          [47.873045, 34.629387],
          [47.865545, 34.633276],
          [47.858879, 34.647722],
          [47.858602, 34.648281],
          [47.857769, 34.649671],
          [47.857489, 34.649942],
          [47.857212, 34.650501],
          [47.856935, 34.650781],
          [47.856935, 34.651061],
          [47.855545, 34.65356],
          [47.853602, 34.661338],
          [47.853878, 34.667446],
          [47.854712, 34.671894],
          [47.863602, 34.682722],
          [47.864158, 34.684391],
          [47.864435, 34.688],
          [47.863879, 34.691059],
          [47.863878, 34.691339],
          [47.863045, 34.694668],
          [47.865268, 34.722449],
          [47.864992, 34.722999],
          [47.862492, 34.730226],
          [47.859155, 34.734674],
          [47.858045, 34.737173],
          [47.857488, 34.737724],
          [47.857488, 34.738004],
          [47.857488, 34.738283],
          [47.857211, 34.738834],
          [47.857488, 34.740782],
          [47.857768, 34.741053],
          [47.857768, 34.744112],
          [47.857488, 34.744391],
          [47.855822, 34.750779],
          [47.854155, 34.754118],
          [47.853321, 34.754948],
          [47.851378, 34.758557],
          [47.851101, 34.759116],
          [47.849155, 34.763005],
          [47.848878, 34.764674],
          [47.848878, 34.764953],
          [47.813875, 34.813835],
          [47.813599, 34.814395],
          [47.811932, 34.816343],
          [47.807489, 34.821062],
          [47.801932, 34.832177],
          [47.800265, 34.834387],
          [47.801652, 34.842723],
          [47.797194, 34.851164],
          [47.809281, 34.856287],
          [47.82315, 34.857378],
          [47.825696, 34.85678],
          [47.832028, 34.857794],
          [47.833598, 34.851889],
          [47.833878, 34.852169],
          [47.834711, 34.852169],
          [47.835265, 34.852449],
          [47.843321, 34.852449],
          [47.843878, 34.852728],
          [47.847211, 34.852728],
          [47.848045, 34.853008],
          [47.849155, 34.853008],
          [47.858601, 34.855778],
          [47.862211, 34.857456],
          [47.863601, 34.858006],
          [47.881934, 34.873289],
          [47.907491, 34.886893],
          [47.908604, 34.887732],
          [47.908604, 34.888003],
          [47.910271, 34.889113],
          [47.91777, 34.895509],
          [47.926104, 34.91523],
          [47.92666, 34.916339],
          [47.927494, 34.925234],
          [47.92777, 34.928843],
          [47.927213, 34.929952],
          [47.923604, 34.933561],
          [47.923047, 34.933841],
          [47.923047, 34.93412],
          [47.922353, 34.934535],
          [47.919437, 34.933561],
          [47.918327, 34.933561],
          [47.900271, 34.928013],
          [47.899437, 34.927733],
          [47.898047, 34.927733],
          [47.896934, 34.927453],
          [47.895267, 34.927453],
          [47.894157, 34.927174],
          [47.891101, 34.927174],
          [47.889991, 34.926894],
          [47.883601, 34.926894],
          [47.883324, 34.927174],
          [47.880547, 34.927174],
          [47.862768, 34.931892],
          [47.862491, 34.932171],
          [47.861658, 34.932171],
          [47.861378, 34.932451],
          [47.860821, 34.932451],
          [47.860544, 34.932731],
          [47.859987, 34.932731],
          [47.858601, 34.93329],
          [47.839988, 34.938838],
          [47.832211, 34.945225],
          [47.814431, 34.968012],
          [47.813598, 34.969681],
          [47.805265, 34.980515],
          [47.805265, 34.984953],
          [47.806098, 34.988841],
          [47.809431, 34.997727],
          [47.811374, 34.998845],
          [47.811375, 34.999396],
          [47.811098, 35.000325],
          [47.810821, 35.001344],
          [47.810541, 35.002174],
          [47.805541, 35.011619],
          [47.804151, 35.013008],
          [47.803875, 35.013838],
          [47.803041, 35.014956],
          [47.791098, 35.021902],
          [47.790265, 35.021902],
          [47.789985, 35.022182],
          [47.788875, 35.022732],
          [47.786932, 35.023841],
          [47.780262, 35.030787],
          [47.778318, 35.033286],
          [47.778042, 35.033566],
          [47.777485, 35.033845],
          [47.776928, 35.034396],
          [47.765818, 35.043569],
          [47.762485, 35.044679],
          [47.753318, 35.046068],
          [47.745539, 35.047457],
          [47.736929, 35.051895],
          [47.734705, 35.054403],
          [47.733039, 35.056342],
          [47.731096, 35.060509],
          [47.730262, 35.061339],
          [47.728592, 35.062178],
          [47.727482, 35.062178],
          [47.727205, 35.062458],
          [47.726092, 35.062458],
          [47.725539, 35.062737],
          [47.723592, 35.062737],
          [47.722759, 35.062458],
          [47.720815, 35.062458],
          [47.709702, 35.058841],
          [47.708592, 35.058841],
          [47.707759, 35.058561],
          [47.704426, 35.058011],
          [47.702202, 35.058011],
          [47.701369, 35.057731],
          [47.691369, 35.057731],
          [47.690812, 35.058011],
          [47.688593, 35.05829],
          [47.675813, 35.061069],
          [47.668313, 35.062038],
          [47.667756, 35.062178],
          [47.667479, 35.062458],
          [47.666646, 35.062737],
          [47.665536, 35.063567],
          [47.65498, 35.067175],
          [47.653033, 35.067175],
          [47.652756, 35.067455],
          [47.650813, 35.067455],
          [47.650533, 35.067734],
          [47.648866, 35.067734],
          [47.64859, 35.068014],
          [47.64609, 35.068564],
          [47.645533, 35.069403],
          [47.64498, 35.069403],
          [47.629977, 35.071063],
          [47.6272, 35.071063],
          [47.626366, 35.071343],
          [47.6222, 35.071343],
          [47.621643, 35.071622],
          [47.616643, 35.071622],
          [47.61609, 35.071902],
          [47.608587, 35.071902],
          [47.608034, 35.072181],
          [47.59442, 35.072452],
          [47.591364, 35.072181],
          [47.568307, 35.073011],
          [47.560254, 35.073011],
          [47.559974, 35.072731],
          [47.558308, 35.072731],
          [47.557751, 35.072452],
          [47.546918, 35.070233],
          [47.544698, 35.068844],
          [47.544141, 35.068844],
          [47.544141, 35.068564],
          [47.535528, 35.063846],
          [47.534418, 35.063846],
          [47.533861, 35.063567],
          [47.527751, 35.063567],
          [47.527475, 35.063846],
          [47.525251, 35.063846],
          [47.524694, 35.064126],
          [47.513028, 35.066616],
          [47.507195, 35.068014],
          [47.504695, 35.068284],
          [47.487195, 35.063287],
          [47.470805, 35.051065],
          [47.469139, 35.050794],
          [47.467192, 35.050794],
          [47.466639, 35.050514],
          [47.464416, 35.050514],
          [47.460525, 35.050226],
          [47.451635, 35.050226],
          [47.451359, 35.050514],
          [47.449416, 35.050514],
          [47.449136, 35.050794],
          [47.441636, 35.051615],
          [47.434412, 35.051615],
          [47.434136, 35.051894],
          [47.429693, 35.051894],
          [47.429136, 35.052174],
          [47.426912, 35.052174],
          [47.425522, 35.052733],
          [47.425246, 35.052733],
          [47.424689, 35.053013],
          [47.420522, 35.056341],
          [47.420246, 35.056071],
          [47.410803, 35.060509],
          [47.409969, 35.060509],
          [47.409689, 35.060788],
          [47.409136, 35.060788],
          [47.408856, 35.061068],
          [47.404689, 35.061618],
          [47.399689, 35.061618],
          [47.399413, 35.061339],
          [47.38719, 35.05829],
          [47.385523, 35.05746],
          [47.38469, 35.05746],
          [47.378576, 35.055511],
          [47.376357, 35.054952],
          [47.376357, 35.054672],
          [47.371633, 35.053843],
          [47.3658, 35.053843],
          [47.365243, 35.053563],
          [47.3633, 35.053563],
          [47.3608, 35.052733],
          [47.356077, 35.045787],
          [47.356077, 35.042729],
          [47.359967, 35.033005],
          [47.360243, 35.032175],
          [47.361353, 35.028838],
          [47.361633, 35.028567],
          [47.359967, 35.022451],
          [47.354967, 35.018004],
          [47.34941, 35.018004],
          [47.34913, 35.018284],
          [47.337743, 35.018284],
          [47.334687, 35.017724],
          [47.333297, 35.017454],
          [47.331353, 35.017454],
          [47.331074, 35.017174],
          [47.329407, 35.017174],
          [47.329131, 35.016895],
          [47.323574, 35.016894],
          [47.323297, 35.017174],
          [47.321643, 35.017174],
          [47.320797, 35.017174],
          [47.32052, 35.016895],
          [47.317188, 35.016065],
          [47.315797, 35.015235],
          [47.308298, 35.00634],
          [47.308017, 35.002173],
          [47.308298, 35.001893],
          [47.308851, 35.000215],
          [47.308851, 34.997726],
          [47.309407, 34.995786],
          [47.309407, 34.991619],
          [47.309684, 34.991068],
          [47.310241, 34.989399],
          [47.311631, 34.986062],
          [47.324687, 34.968841],
          [47.33274, 34.954669],
          [47.33274, 34.95106],
          [47.332464, 34.950231],
          [47.332741, 34.949392],
          [47.333021, 34.947452],
          [47.332741, 34.946063],
          [47.326908, 34.938837],
          [47.326631, 34.938566],
          [47.306908, 34.929951],
          [47.306074, 34.929951],
          [47.304964, 34.929392],
          [47.296908, 34.928011],
          [47.296074, 34.927732],
          [47.294408, 34.927732],
          [47.293574, 34.927452],
          [47.292184, 34.927452],
          [47.291351, 34.927172],
          [47.288018, 34.927452],
          [47.285241, 34.927452],
          [47.284685, 34.927732],
          [47.281908, 34.927732],
          [47.270795, 34.933009],
          [47.267184, 34.939946],
          [47.267184, 34.940785],
          [47.266905, 34.941065],
          [47.266628, 34.941895],
          [47.265238, 34.945503],
          [47.260238, 34.95051],
          [47.253015, 34.952729],
          [47.247738, 34.952729],
          [47.247461, 34.953009],
          [47.244961, 34.953288],
          [47.243295, 34.953288],
          [47.243015, 34.953009],
          [47.242182, 34.953009],
          [47.224682, 34.940226],
          [47.224405, 34.939946],
          [47.220239, 34.938286],
          [47.218647, 34.937829],
          [47.218292, 34.937727],
          [47.217458, 34.937727],
          [47.217182, 34.937447],
          [47.215515, 34.937447],
          [47.215236, 34.937168],
          [47.214126, 34.937168],
          [47.213849, 34.937447],
          [47.213015, 34.937447],
          [47.210515, 34.938837],
          [47.210515, 34.940785],
          [47.210236, 34.941615],
          [47.204125, 34.944394],
          [47.203292, 34.944394],
          [47.202736, 34.944673],
          [47.201349, 34.944394],
          [47.195235, 34.944394],
          [47.194402, 34.944114],
          [47.191626, 34.946622],
          [47.188845, 34.954669],
          [47.189402, 34.958836],
          [47.189126, 34.959396],
          [47.186902, 34.966612],
          [47.184959, 34.96912],
          [47.181902, 34.972178],
          [47.179956, 34.972178],
          [47.179402, 34.972449],
          [47.175236, 34.972178],
          [47.17329, 34.972449],
          [47.168846, 34.970509],
          [47.159956, 34.962454],
          [47.159679, 34.961064],
          [47.167736, 34.947731],
          [47.16829, 34.944673],
          [47.168569, 34.943564],
          [47.169956, 34.940226],
          [47.174122, 34.933559],
          [47.174403, 34.93134],
          [47.17468, 34.929391],
          [47.173846, 34.919396],
          [47.174123, 34.918836],
          [47.177736, 34.911899],
          [47.17329, 34.903284],
          [47.169956, 34.902174],
          [47.169122, 34.902174],
          [47.168569, 34.901894],
          [47.167736, 34.901894],
          [47.167179, 34.901615],
          [47.165236, 34.901615],
          [47.164679, 34.901335],
          [47.164123, 34.901615],
          [47.161623, 34.901615],
          [47.161066, 34.901894],
          [47.160233, 34.901894],
          [47.159956, 34.902174],
          [47.1594, 34.902174],
          [47.1594, 34.902454],
          [47.157457, 34.903004],
          [47.153013, 34.904953],
          [47.149956, 34.904953],
          [47.13829, 34.901615],
          [47.137177, 34.901615],
          [47.136343, 34.901335],
          [47.13301, 34.901335],
          [47.12162, 34.905782],
          [47.119677, 34.905782],
          [47.11912, 34.906062],
          [47.11801, 34.906062],
          [47.117453, 34.906342],
          [47.11412, 34.906342],
          [47.113287, 34.906062],
          [47.1119, 34.906062],
          [47.111343, 34.905782],
          [47.110234, 34.905782],
          [47.10412, 34.901615],
          [47.101897, 34.899395],
          [47.095787, 34.893838],
          [47.09412, 34.892728],
          [47.091064, 34.89161],
          [47.088564, 34.891339],
          [47.088011, 34.891059],
          [47.085787, 34.891059],
          [47.08523, 34.891339],
          [47.08384, 34.891339],
          [47.083564, 34.891059],
          [47.082454, 34.891059],
          [47.081897, 34.89078],
          [47.081064, 34.89078],
          [47.075787, 34.884113],
          [47.074674, 34.876616],
          [47.074954, 34.876066],
          [47.075507, 34.872448],
          [47.075787, 34.871338],
          [47.073007, 34.864951],
          [47.072731, 34.864392],
          [47.071897, 34.855226],
          [47.071621, 34.852447],
          [47.071897, 34.852167],
          [47.072175, 34.851058],
          [47.074954, 34.847449],
          [47.077174, 34.84578],
          [47.079397, 34.84439],
          [47.082731, 34.842171],
          [47.102731, 34.823559],
          [47.106344, 34.819391],
          [47.109121, 34.816892],
          [47.12301, 34.807726],
          [47.123567, 34.807726],
          [47.123844, 34.807446],
          [47.1244, 34.807446],
          [47.1244, 34.807166],
          [47.12412, 34.805506],
          [47.125234, 34.803557],
          [47.124677, 34.800219],
          [47.1244, 34.798839],
          [47.123287, 34.797729],
          [47.12301, 34.79689],
          [47.115787, 34.790502],
          [47.114677, 34.789952],
          [47.1069, 34.788283],
          [47.106344, 34.788003],
          [47.103011, 34.788003],
          [47.093011, 34.790782],
          [47.087454, 34.792722],
          [47.078564, 34.79717],
          [47.078287, 34.79689],
          [47.076621, 34.79689],
          [47.076341, 34.79661],
          [47.075508, 34.79661],
          [47.067175, 34.792451],
          [47.061064, 34.784394],
          [47.060232, 34.781336],
          [47.063564, 34.777727],
          [47.064951, 34.777168],
          [47.089398, 34.76967],
          [47.093288, 34.765222],
          [47.093564, 34.764392],
          [47.093844, 34.763562],
          [47.088564, 34.757725],
          [47.085231, 34.756335],
          [47.084674, 34.756335],
          [47.081064, 34.755505],
          [47.070231, 34.754116],
          [47.048561, 34.760503],
          [47.046062, 34.760503],
          [47.043841, 34.759944],
          [47.042452, 34.759944],
          [47.041618, 34.759664],
          [47.040785, 34.759664],
          [47.033562, 34.756885],
          [47.023839, 34.750507],
          [47.023561, 34.750227],
          [47.023285, 34.749947],
          [47.022729, 34.749947],
          [47.022452, 34.749667],
          [47.016062, 34.746609],
          [47.015228, 34.746609],
          [47.014672, 34.746329],
          [47.014396, 34.746609],
          [47.013285, 34.746059],
          [47.009395, 34.746329],
          [47.008838, 34.746609],
          [47.005229, 34.746609],
          [46.988283, 34.752167],
          [46.986892, 34.752446],
          [46.986339, 34.753006],
          [46.985782, 34.753006],
          [46.985225, 34.753556],
          [46.963559, 34.756885],
          [46.962449, 34.757165],
          [46.956059, 34.757165],
          [46.955226, 34.756885],
          [46.951893, 34.756885],
          [46.947726, 34.756615],
          [46.926613, 34.751896],
          [46.902446, 34.763282],
          [46.902167, 34.763562],
          [46.901056, 34.763562],
          [46.898833, 34.764392],
          [46.898277, 34.764392],
          [46.897724, 34.764392],
          [46.894943, 34.764392],
          [46.890223, 34.763562],
          [46.889943, 34.763282],
          [46.88911, 34.763282],
          [46.888834, 34.763002],
          [46.888, 34.763002],
          [46.887444, 34.762722],
          [46.886334, 34.762722],
          [46.8655, 34.757165],
          [46.865221, 34.756885],
          [46.86411, 34.756885],
          [46.863277, 34.756614],
          [46.859387, 34.755505],
          [46.847444, 34.753556],
          [46.845498, 34.753556],
          [46.786051, 34.73967],
          [46.785775, 34.73939],
          [46.782995, 34.73939],
          [46.774662, 34.74161],
          [46.773552, 34.74161],
          [46.772995, 34.74189],
          [46.771885, 34.74189],
          [46.769938, 34.74244],
          [46.769273, 34.74265],
          [46.768272, 34.742999],
          [46.767719, 34.743279],
          [46.764662, 34.746058],
          [46.736882, 34.784944],
          [46.735492, 34.787443],
          [46.735492, 34.787723],
          [46.733272, 34.79411],
          [46.732715, 34.797728],
          [46.733272, 34.803836],
          [46.733548, 34.804946],
          [46.745937, 34.824032],
          [46.746325, 34.824388],
          [46.748828, 34.827726],
          [46.749381, 34.828836],
          [46.749381, 34.829116],
          [46.749662, 34.829666],
          [46.749662, 34.831335],
          [46.749381, 34.831615],
          [46.743548, 34.839951],
          [46.742158, 34.840781],
          [46.728825, 34.847168],
          [46.728268, 34.847719],
          [46.718269, 34.850227],
          [46.680859, 34.857032],
          [46.669656, 34.859392],
          [46.660213, 34.862171],
          [46.658546, 34.862722],
          [46.658266, 34.863001],
          [46.639933, 34.869397],
          [46.639656, 34.869668],
          [46.634933, 34.872447],
          [46.629653, 34.875785],
          [46.619933, 34.884671],
          [46.600486, 34.898835],
          [46.599653, 34.899674],
          [46.58132, 34.90717],
          [46.556223, 34.921901],
          [46.555207, 34.922732],
          [46.553072, 34.925657],
          [46.550535, 34.928523],
          [46.547802, 34.93161],
          [46.546317, 34.933287],
          [46.54604, 34.933557],
          [46.543817, 34.936065],
          [46.542707, 34.938005],
          [46.54215, 34.938564],
          [46.539651, 34.946061],
          [46.53937, 34.954117],
          [46.539094, 34.954946],
          [46.537704, 34.959673],
          [46.537427, 34.959953],
          [46.52576, 34.977724],
          [46.518537, 34.991337],
          [46.516594, 34.998003],
          [46.51826, 35.00384],
          [46.518537, 35.006338],
          [46.518813, 35.006618],
          [46.518813, 35.006898],
          [46.518813, 35.007727],
          [46.518802, 35.008531],
          [46.518537, 35.009117],
          [46.501037, 35.034951],
          [46.500757, 35.035231],
          [46.499924, 35.036061],
          [46.499647, 35.036341],
          [46.491314, 35.034113],
          [46.490481, 35.034113],
          [46.490204, 35.033842],
          [46.488257, 35.033842],
          [46.48687, 35.033283],
          [46.483814, 35.033283],
          [46.477424, 35.035511],
          [46.47659, 35.035511],
          [46.448811, 35.052171],
          [46.446591, 35.05384],
          [46.415478, 35.089948],
          [46.415478, 35.090507],
          [46.405198, 35.101061],
          [46.405033, 35.101396],
          [46.404921, 35.101899],
          [46.403532, 35.103559],
          [46.401864, 35.106617],
          [46.401308, 35.107726],
          [46.401308, 35.108006],
          [46.383808, 35.13134],
          [46.375475, 35.141343],
          [46.373532, 35.145231],
          [46.373251, 35.14551],
          [46.363808, 35.154954],
          [46.363668, 35.155234],
          [46.363528, 35.155513],
          [46.363528, 35.156063],
          [46.347139, 35.170513],
          [46.342695, 35.172731],
          [46.342139, 35.172731],
          [46.341585, 35.173291],
          [46.341028, 35.173291],
          [46.337972, 35.17468],
          [46.320195, 35.182175],
          [46.319362, 35.182175],
          [46.280193, 35.200781],
          [46.277416, 35.2019],
          [46.257693, 35.212732],
          [46.257136, 35.213011],
          [46.2188, 35.236623],
          [46.217133, 35.237733],
          [46.1838, 35.263292],
          [46.17852, 35.266349],
          [46.177797, 35.266787],
          [46.17713, 35.267179],
          [46.176854, 35.267459],
          [46.15791, 35.28328],
          [46.157574, 35.282994],
          [46.156862, 35.282538],
          [46.154925, 35.282048],
          [46.153841, 35.281633],
          [46.152081, 35.280891],
          [46.150954, 35.280463],
          [46.149884, 35.280149],
          [46.14894, 35.279894],
          [46.149354, 35.278609],
          [46.149417, 35.277494],
          [46.148946, 35.27667],
          [46.148411, 35.275765],
          [46.14836, 35.27488],
          [46.148257, 35.274006],
          [46.148019, 35.273283],
          [46.14763, 35.272411],
          [46.146861, 35.271749],
          [46.14571, 35.271336],
          [46.144589, 35.269836],
          [46.143793, 35.26947],
          [46.143388, 35.268342],
          [46.143128, 35.267841],
          [46.142335, 35.267037],
          [46.141423, 35.266168],
          [46.141355, 35.266103],
          [46.140659, 35.265655],
          [46.139295, 35.265013],
          [46.137757, 35.264633],
          [46.136155, 35.264567],
          [46.135614, 35.264339],
          [46.135869, 35.262928],
          [46.135787, 35.261977],
          [46.135723, 35.261594],
          [46.135407, 35.261436],
          [46.134224, 35.260905],
          [46.133109, 35.260475],
          [46.132441, 35.260032],
          [46.131939, 35.259546],
          [46.1312, 35.258743],
          [46.130642, 35.258299],
          [46.130036, 35.257833],
          [46.129497, 35.257261],
          [46.12945, 35.256241],
          [46.129013, 35.255442],
          [46.128418, 35.254516],
          [46.12751, 35.254442],
          [46.126494, 35.254523],
          [46.125903, 35.253857],
          [46.125413, 35.253515],
          [46.125343, 35.252561],
          [46.12527, 35.251796],
          [46.124713, 35.251061],
          [46.12431, 35.250484],
          [46.124122, 35.250145],
          [46.123926, 35.249382],
          [46.123557, 35.248882],
          [46.123637, 35.247827],
          [46.123503, 35.247437],
          [46.123098, 35.246953],
          [46.122881, 35.246413],
          [46.122686, 35.246149],
          [46.121943, 35.245627],
          [46.121249, 35.24524],
          [46.120762, 35.245105],
          [46.12033, 35.245074],
          [46.11973, 35.244628],
          [46.118659, 35.244408],
          [46.118106, 35.244286],
          [46.117418, 35.244117],
          [46.117034, 35.24391],
          [46.116477, 35.243872],
          [46.115551, 35.243634],
          [46.115039, 35.243608],
          [46.114284, 35.243494],
          [46.113888, 35.243445],
          [46.112243, 35.242327],
          [46.109939, 35.241793],
          [46.109087, 35.241425],
          [46.108801, 35.241301],
          [46.108403, 35.241129],
          [46.108525, 35.241085],
          [46.108685, 35.241052],
          [46.108855, 35.241012],
          [46.109043, 35.240952],
          [46.109132, 35.240913],
          [46.109174, 35.24087],
          [46.109219, 35.240746],
          [46.109302, 35.240582],
          [46.10944, 35.240364],
          [46.10951, 35.240243],
          [46.10957, 35.240173],
          [46.109688, 35.240137],
          [46.109909, 35.240108],
          [46.110084, 35.24013],
          [46.110283, 35.240186],
          [46.110358, 35.240185],
          [46.110448, 35.240169],
          [46.110533, 35.240141],
          [46.110617, 35.24009],
          [46.110743, 35.240031],
          [46.11087, 35.239972],
          [46.110931, 35.239959],
          [46.111035, 35.239927],
          [46.111086, 35.239908],
          [46.111161, 35.239845],
          [46.111244, 35.23974],
          [46.111314, 35.239658],
          [46.11137, 35.239611],
          [46.111393, 35.239572],
          [46.111425, 35.239525],
          [46.111561, 35.239435],
          [46.111655, 35.239376],
          [46.111753, 35.239328],
          [46.111904, 35.239273],
          [46.112045, 35.239225],
          [46.112129, 35.239197],
          [46.112312, 35.239083],
          [46.112448, 35.239004],
          [46.112612, 35.238956],
          [46.112753, 35.238912],
          [46.11281, 35.23892],
          [46.11289, 35.238907],
          [46.112951, 35.238884],
          [46.113054, 35.238817],
          [46.113171, 35.238731],
          [46.113245, 35.238668],
          [46.113325, 35.238621],
          [46.113419, 35.238597],
          [46.113494, 35.238584],
          [46.11356, 35.23856],
          [46.113611, 35.238521],
          [46.113751, 35.238392],
          [46.113802, 35.23833],
          [46.113816, 35.238283],
          [46.113786, 35.238202],
          [46.11378, 35.238109],
          [46.113775, 35.238036],
          [46.113746, 35.23799],
          [46.113702, 35.23792],
          [46.113654, 35.237812],
          [46.1136, 35.237673],
          [46.113519, 35.237647],
          [46.113434, 35.237621],
          [46.113358, 35.237587],
          [46.113272, 35.237537],
          [46.113229, 35.237522],
          [46.113197, 35.237538],
          [46.113164, 35.23755],
          [46.113116, 35.237538],
          [46.113069, 35.237535],
          [46.113022, 35.237539],
          [46.112994, 35.23752],
          [46.112989, 35.237489],
          [46.113002, 35.237462],
          [46.113026, 35.237446],
          [46.113072, 35.237411],
          [46.113156, 35.23731],
          [46.113197, 35.237212],
          [46.11322, 35.23715],
          [46.113304, 35.237053],
          [46.113453, 35.236912],
          [46.113616, 35.236759],
          [46.113663, 35.23674],
          [46.11378, 35.236704],
          [46.113881, 35.236412],
          [46.113881, 35.23641],
          [46.113879, 35.23641],
          [46.113714, 35.236398],
          [46.113633, 35.236341],
          [46.113242, 35.236372],
          [46.111685, 35.236781],
          [46.11157, 35.236604],
          [46.111529, 35.236364],
          [46.111606, 35.23612],
          [46.111975, 35.236105],
          [46.112174, 35.235239],
          [46.112337, 35.234892],
          [46.112839, 35.234646],
          [46.113178, 35.234241],
          [46.113962, 35.233651],
          [46.115327, 35.233397],
          [46.114944, 35.232819],
          [46.115245, 35.232647],
          [46.115719, 35.233164],
          [46.116322, 35.232755],
          [46.117938, 35.231958],
          [46.118554, 35.232293],
          [46.118865, 35.232744],
          [46.119162, 35.23335],
          [46.119333, 35.233309],
          [46.11947, 35.233338],
          [46.119889, 35.233312],
          [46.120678, 35.232893],
          [46.121094, 35.232627],
          [46.121383, 35.232444],
          [46.121529, 35.232413],
          [46.121694, 35.232471],
          [46.121795, 35.232545],
          [46.121917, 35.232724],
          [46.12213, 35.232984],
          [46.122352, 35.233184],
          [46.122683, 35.233421],
          [46.123163, 35.233776],
          [46.123471, 35.234252],
          [46.123578, 35.234782],
          [46.123622, 35.235254],
          [46.123623, 35.235359],
          [46.123596, 35.235411],
          [46.123462, 35.235585],
          [46.1235, 35.235667],
          [46.123618, 35.235673],
          [46.123755, 35.235664],
          [46.12391, 35.235633],
          [46.124316, 35.235394],
          [46.124653, 35.235282],
          [46.124753, 35.235266],
          [46.124936, 35.235309],
          [46.125321, 35.23544],
          [46.12546, 35.235581],
          [46.125791, 35.23575],
          [46.126151, 35.235994],
          [46.126484, 35.236313],
          [46.12722, 35.23577],
          [46.127224, 35.235767],
          [46.127074, 35.235454],
          [46.127008, 35.235297],
          [46.126812, 35.235],
          [46.126529, 35.234656],
          [46.126524, 35.23465],
          [46.12654, 35.234478],
          [46.12652, 35.234306],
          [46.126453, 35.234104],
          [46.126331, 35.233888],
          [46.12609, 35.233576],
          [46.125692, 35.233176],
          [46.12552, 35.232958],
          [46.125377, 35.232856],
          [46.124852, 35.232532],
          [46.124476, 35.232371],
          [46.124164, 35.232216],
          [46.123914, 35.232001],
          [46.123673, 35.231727],
          [46.123469, 35.231452],
          [46.12348, 35.231447],
          [46.131258, 35.22771],
          [46.13169, 35.227824],
          [46.132558, 35.227977],
          [46.133344, 35.228252],
          [46.134671, 35.228662],
          [46.135449, 35.228658],
          [46.136813, 35.2288],
          [46.138738, 35.2287],
          [46.14005, 35.228302],
          [46.141081, 35.228176],
          [46.141803, 35.22812],
          [46.142923, 35.228269],
          [46.144479, 35.228468],
          [46.146011, 35.228225],
          [46.147393, 35.228084],
          [46.148208, 35.227968],
          [46.14965, 35.227348],
          [46.150633, 35.226649],
          [46.15136, 35.226062],
          [46.152408, 35.22585],
          [46.153205, 35.225717],
          [46.154726, 35.225435],
          [46.15548, 35.225445],
          [46.156023, 35.225684],
          [46.158043, 35.225877],
          [46.158944, 35.225277],
          [46.160211, 35.224368],
          [46.16232, 35.222943],
          [46.162962, 35.222944],
          [46.163428, 35.223197],
          [46.1641, 35.223609],
          [46.164509, 35.224154],
          [46.165024, 35.224637],
          [46.166131, 35.22434],
          [46.167485, 35.2241],
          [46.168431, 35.224064],
          [46.16901, 35.223984],
          [46.16959, 35.224402],
          [46.170031, 35.224816],
          [46.170251, 35.225221],
          [46.170643, 35.225406],
          [46.171219, 35.225367],
          [46.171745, 35.225391],
          [46.172418, 35.225355],
          [46.174727, 35.227353],
          [46.176822, 35.228746],
          [46.177792, 35.228359],
          [46.178566, 35.227867],
          [46.179189, 35.22747],
          [46.179595, 35.227224],
          [46.179908, 35.227104],
          [46.180427, 35.226817],
          [46.180501, 35.226621],
          [46.18068, 35.22651],
          [46.181278, 35.226387],
          [46.182291, 35.225939],
          [46.182716, 35.225662],
          [46.183919, 35.225267],
          [46.183967, 35.225235],
          [46.184435, 35.224684],
          [46.185128, 35.223888],
          [46.18554, 35.223407],
          [46.185758, 35.223295],
          [46.186267, 35.222337],
          [46.18673, 35.222074],
          [46.187197, 35.221405],
          [46.187683, 35.220768],
          [46.188154, 35.220466],
          [46.188649, 35.219654],
          [46.188804, 35.219287],
          [46.189418, 35.218296],
          [46.189443, 35.218023],
          [46.189354, 35.21782],
          [46.189324, 35.217603],
          [46.189287, 35.217379],
          [46.189407, 35.217023],
          [46.189846, 35.216692],
          [46.190326, 35.216433],
          [46.194178, 35.210945],
          [46.194385, 35.209715],
          [46.194911, 35.207934],
          [46.195376, 35.20694],
          [46.195218, 35.206212],
          [46.194745, 35.205481],
          [46.194432, 35.204741],
          [46.194216, 35.203951],
          [46.193999, 35.203057],
          [46.19478, 35.202668],
          [46.19587, 35.202602],
          [46.196877, 35.202295],
          [46.198116, 35.201932],
          [46.199285, 35.201811],
          [46.200277, 35.201513],
          [46.201247, 35.201229],
          [46.200824, 35.20095],
          [46.200714, 35.200833],
          [46.200739, 35.200632],
          [46.19976, 35.199504],
          [46.197437, 35.197706],
          [46.196876, 35.196702],
          [46.19694, 35.195837],
          [46.196635, 35.195272],
          [46.19636, 35.194566],
          [46.197228, 35.193314],
          [46.197917, 35.192368],
          [46.198418, 35.191061],
          [46.197327, 35.189479],
          [46.196528, 35.188424],
          [46.196082, 35.186654],
          [46.195818, 35.185496],
          [46.196014, 35.184791],
          [46.194975, 35.184013],
          [46.194476, 35.184013],
          [46.193655, 35.184204],
          [46.191982, 35.18411],
          [46.190305, 35.183913],
          [46.187766, 35.183715],
          [46.18683, 35.183833],
          [46.185251, 35.184326],
          [46.184195, 35.183404],
          [46.183721, 35.183078],
          [46.182878, 35.182782],
          [46.181808, 35.181634],
          [46.181048, 35.181072],
          [46.180397, 35.180697],
          [46.179787, 35.180189],
          [46.17914, 35.179918],
          [46.178542, 35.179992],
          [46.177666, 35.179494],
          [46.177152, 35.179217],
          [46.176806, 35.178867],
          [46.17641, 35.178443],
          [46.176066, 35.177738],
          [46.176021, 35.176955],
          [46.17577, 35.17643],
          [46.175343, 35.175991],
          [46.174736, 35.175806],
          [46.174098, 35.175564],
          [46.173505, 35.175387],
          [46.172979, 35.175249],
          [46.172651, 35.174916],
          [46.172422, 35.174689],
          [46.172048, 35.174386],
          [46.171455, 35.173979],
          [46.170928, 35.173747],
          [46.169963, 35.173631],
          [46.169295, 35.173469],
          [46.168465, 35.173264],
          [46.168001, 35.173062],
          [46.166786, 35.172785],
          [46.166203, 35.172502],
          [46.165878, 35.172043],
          [46.165507, 35.171385],
          [46.165358, 35.170976],
          [46.164991, 35.170421],
          [46.164753, 35.169894],
          [46.164574, 35.169699],
          [46.164153, 35.169416],
          [46.163628, 35.169329],
          [46.163233, 35.169135],
          [46.162888, 35.168701],
          [46.162506, 35.168232],
          [46.161946, 35.167527],
          [46.161814, 35.167084],
          [46.161694, 35.166815],
          [46.161539, 35.166657],
          [46.160877, 35.166337],
          [46.160715, 35.166108],
          [46.160377, 35.165516],
          [46.160026, 35.165469],
          [46.159453, 35.165402],
          [46.159074, 35.165215],
          [46.158641, 35.165028],
          [46.158421, 35.164924],
          [46.157905, 35.164638],
          [46.157437, 35.164291],
          [46.156963, 35.164236],
          [46.156918, 35.163859],
          [46.156868, 35.163556],
          [46.156729, 35.16327],
          [46.156705, 35.162962],
          [46.156818, 35.162388],
          [46.15692, 35.162097],
          [46.15686, 35.161806],
          [46.156635, 35.161276],
          [46.156402, 35.16081],
          [46.156146, 35.160495],
          [46.156101, 35.160295],
          [46.155921, 35.159965],
          [46.155373, 35.15957],
          [46.154944, 35.159215],
          [46.154758, 35.159001],
          [46.154892, 35.158589],
          [46.155127, 35.1582],
          [46.155261, 35.157653],
          [46.155225, 35.157034],
          [46.154997, 35.15663],
          [46.154635, 35.156001],
          [46.154474, 35.155553],
          [46.155113, 35.155239],
          [46.155717, 35.154914],
          [46.156333, 35.154357],
          [46.156147, 35.153914],
          [46.15632, 35.153317],
          [46.156587, 35.152765],
          [46.156918, 35.151972],
          [46.157436, 35.151538],
          [46.158401, 35.150976],
          [46.158965, 35.150513],
          [46.159755, 35.149818],
          [46.160254, 35.149138],
          [46.160019, 35.14862],
          [46.159683, 35.148122],
          [46.159307, 35.147674],
          [46.159362, 35.147225],
          [46.159518, 35.146299],
          [46.159411, 35.145214],
          [46.159672, 35.144371],
          [46.159822, 35.143425],
          [46.159705, 35.14303],
          [46.159245, 35.14239],
          [46.15924, 35.141557],
          [46.15956, 35.140911],
          [46.160176, 35.140625],
          [46.160945, 35.139768],
          [46.160904, 35.13963],
          [46.16046, 35.139132],
          [46.160076, 35.138611],
          [46.159662, 35.138128],
          [46.159227, 35.137524],
          [46.159008, 35.137108],
          [46.158781, 35.136526],
          [46.158415, 35.136065],
          [46.157987, 35.135804],
          [46.157834, 35.135427],
          [46.157321, 35.135015],
          [46.156967, 35.134687],
          [46.156144, 35.134367],
          [46.155701, 35.133785],
          [46.155696, 35.133181],
          [46.155908, 35.132577],
          [46.156136, 35.131803],
          [46.155577, 35.130421],
          [46.154958, 35.129519],
          [46.155059, 35.128999],
          [46.155748, 35.128594],
          [46.156438, 35.128148],
          [46.156677, 35.127456],
          [46.157397, 35.12716],
          [46.158438, 35.126532],
          [46.158756, 35.126199],
          [46.159041, 35.125842],
          [46.159946, 35.12476],
          [46.16118, 35.124011],
          [46.161895, 35.123696],
          [46.163021, 35.123312],
          [46.163861, 35.122826],
          [46.164582, 35.122395],
          [46.16536, 35.122109],
          [46.166094, 35.121634],
          [46.16663, 35.121395],
          [46.167151, 35.120971],
          [46.167826, 35.120747],
          [46.168428, 35.120141],
          [46.168952, 35.119727],
          [46.169356, 35.119273],
          [46.170107, 35.118899],
          [46.17061, 35.118458],
          [46.171481, 35.117987],
          [46.173209, 35.118175],
          [46.174479, 35.118367],
          [46.175525, 35.118436],
          [46.176493, 35.118249],
          [46.177218, 35.117692],
          [46.178299, 35.117067],
          [46.179298, 35.116165],
          [46.180194, 35.115825],
          [46.181389, 35.11549],
          [46.183031, 35.115027],
          [46.183591, 35.114189],
          [46.184374, 35.113558],
          [46.185696, 35.11347],
          [46.186931, 35.113273],
          [46.188086, 35.113258],
          [46.189334, 35.112559],
          [46.19021, 35.111514],
          [46.191291, 35.110888],
          [46.190978, 35.110675],
          [46.19044, 35.110488],
          [46.190021, 35.110237],
          [46.18917, 35.109921],
          [46.188534, 35.109631],
          [46.187869, 35.10914],
          [46.187323, 35.108547],
          [46.186432, 35.108021],
          [46.185558, 35.10756],
          [46.184706, 35.107174],
          [46.183945, 35.106966],
          [46.183207, 35.106842],
          [46.182365, 35.106395],
          [46.18154, 35.105768],
          [46.180782, 35.105235],
          [46.179887, 35.104553],
          [46.178728, 35.104087],
          [46.177256, 35.103466],
          [46.175598, 35.102763],
          [46.174411, 35.102293],
          [46.173461, 35.101798],
          [46.173054, 35.101454],
          [46.172878, 35.100928],
          [46.172586, 35.100032],
          [46.172292, 35.099568],
          [46.171792, 35.099134],
          [46.170817, 35.098888],
          [46.169637, 35.098633],
          [46.168411, 35.098456],
          [46.167493, 35.098121],
          [46.166904, 35.097615],
          [46.166585, 35.097009],
          [46.166302, 35.096199],
          [46.166024, 35.095595],
          [46.16537, 35.094759],
          [46.164669, 35.093951],
          [46.163367, 35.092541],
          [46.162499, 35.091334],
          [46.16211, 35.090623],
          [46.162042, 35.089949],
          [46.161838, 35.08937],
          [46.161168, 35.088527],
          [46.160466, 35.087905],
          [46.159739, 35.087387],
          [46.158812, 35.086877],
          [46.157965, 35.086607],
          [46.157138, 35.086501],
          [46.155625, 35.086505],
          [46.154615, 35.086375],
          [46.153463, 35.086409],
          [46.152154, 35.08661],
          [46.151371, 35.086661],
          [46.149615, 35.086663],
          [46.148544, 35.086701],
          [46.147257, 35.086977],
          [46.145951, 35.087089],
          [46.145318, 35.086955],
          [46.144559, 35.086708],
          [46.143714, 35.086585],
          [46.142722, 35.08647],
          [46.142182, 35.086426],
          [46.141397, 35.086086],
          [46.140645, 35.085719],
          [46.139709, 35.085035],
          [46.138959, 35.084629],
          [46.137724, 35.084227],
          [46.136531, 35.083876],
          [46.134708, 35.083499],
          [46.132374, 35.083033],
          [46.130131, 35.082509],
          [46.128699, 35.082126],
          [46.127785, 35.081761],
          [46.126972, 35.081416],
          [46.126264, 35.080727],
          [46.125705, 35.079941],
          [46.125211, 35.079388],
          [46.124639, 35.079085],
          [46.124255, 35.078776],
          [46.123897, 35.078177],
          [46.123564, 35.077907],
          [46.123129, 35.077755],
          [46.122628, 35.077703],
          [46.121698, 35.077665],
          [46.121033, 35.077606],
          [46.120534, 35.077417],
          [46.119815, 35.077025],
          [46.119155, 35.076552],
          [46.118528, 35.075956],
          [46.118149, 35.07538],
          [46.117441, 35.074643],
          [46.116964, 35.074243],
          [46.116562, 35.074065],
          [46.11622, 35.074003],
          [46.115812, 35.074041],
          [46.1154, 35.07416],
          [46.114914, 35.074498],
          [46.114393, 35.075],
          [46.113801, 35.075731],
          [46.113185, 35.076182],
          [46.112867, 35.076587],
          [46.112235, 35.076983],
          [46.111832, 35.077089],
          [46.111416, 35.077051],
          [46.11065, 35.076922],
          [46.109835, 35.076862],
          [46.109081, 35.076918],
          [46.108409, 35.077125],
          [46.107733, 35.077313],
          [46.107109, 35.077354],
          [46.106173, 35.077248],
          [46.105371, 35.077039],
          [46.104636, 35.076924],
          [46.104076, 35.076953],
          [46.10331, 35.077158],
          [46.102595, 35.077403],
          [46.101842, 35.077802],
          [46.101226, 35.078204],
          [46.100778, 35.078486],
          [46.100017, 35.07871],
          [46.09934, 35.078948],
          [46.098703, 35.078991],
          [46.098216, 35.078986],
          [46.097937, 35.079147],
          [46.09781, 35.079417],
          [46.097769, 35.079798],
          [46.097655, 35.080114],
          [46.097414, 35.080699],
          [46.097145, 35.081614],
          [46.096929, 35.082431],
          [46.096708, 35.082944],
          [46.096675, 35.083353],
          [46.096617, 35.084394],
          [46.096426, 35.085157],
          [46.096134, 35.085752],
          [46.096013, 35.08609],
          [46.095586, 35.086397],
          [46.095082, 35.086719],
          [46.09472, 35.087112],
          [46.09431, 35.087387],
          [46.093678, 35.087733],
          [46.092804, 35.08794],
          [46.092129, 35.088039],
          [46.091579, 35.088007],
          [46.090823, 35.087867],
          [46.090111, 35.087689],
          [46.089526, 35.087722],
          [46.089066, 35.087575],
          [46.088063, 35.087374],
          [46.086611, 35.087161],
          [46.085513, 35.086959],
          [46.084932, 35.086913],
          [46.084289, 35.086742],
          [46.082902, 35.086516],
          [46.082047, 35.086218],
          [46.081471, 35.085995],
          [46.080761, 35.085876],
          [46.080206, 35.085727],
          [46.079551, 35.085371],
          [46.078979, 35.084921],
          [46.078557, 35.084433],
          [46.078355, 35.084049],
          [46.078149, 35.083893],
          [46.077576, 35.083875],
          [46.07707, 35.083805],
          [46.076196, 35.083726],
          [46.075315, 35.083669],
          [46.074478, 35.083672],
          [46.074055, 35.083753],
          [46.073488, 35.083852],
          [46.072968, 35.083686],
          [46.072396, 35.083383],
          [46.071919, 35.083131],
          [46.071563, 35.082777],
          [46.07132, 35.082392],
          [46.071067, 35.081783],
          [46.07103, 35.081221],
          [46.071082, 35.080828],
          [46.071198, 35.080521],
          [46.071304, 35.080128],
          [46.071593, 35.079573],
          [46.071872, 35.079029],
          [46.071959, 35.078472],
          [46.071887, 35.078025],
          [46.071698, 35.077737],
          [46.071391, 35.077413],
          [46.070888, 35.077166],
          [46.07043, 35.07693],
          [46.070045, 35.076621],
          [46.069762, 35.07638],
          [46.06958, 35.076023],
          [46.069725, 35.075289],
          [46.069993, 35.074658],
          [46.070403, 35.074197],
          [46.071152, 35.073593],
          [46.07186, 35.072555],
          [46.072309, 35.071654],
          [46.072703, 35.070411],
          [46.07279, 35.069521],
          [46.072661, 35.069016],
          [46.072415, 35.068671],
          [46.071982, 35.06843],
          [46.071465, 35.067842],
          [46.070937, 35.067069],
          [46.070432, 35.066234],
          [46.069483, 35.065169],
          [46.068968, 35.064451],
          [46.068642, 35.063775],
          [46.068522, 35.063307],
          [46.068591, 35.062445],
          [46.068711, 35.061377],
          [46.068793, 35.060157],
          [46.068904, 35.05916],
          [46.069138, 35.058403],
          [46.069475, 35.057839],
          [46.069817, 35.057423],
          [46.070291, 35.057117],
          [46.071032, 35.056792],
          [46.071745, 35.056679],
          [46.072237, 35.056716],
          [46.07297, 35.057026],
          [46.073702, 35.057574],
          [46.074381, 35.058021],
          [46.07499, 35.058577],
          [46.075521, 35.059015],
          [46.076044, 35.059346],
          [46.076646, 35.059556],
          [46.077341, 35.059713],
          [46.078111, 35.05973],
          [46.078819, 35.059658],
          [46.079436, 35.059331],
          [46.079848, 35.058805],
          [46.080153, 35.058316],
          [46.080326, 35.057468],
          [46.080338, 35.056733],
          [46.08028, 35.05592],
          [46.080062, 35.055466],
          [46.079527, 35.05476],
          [46.078955, 35.0541],
          [46.078442, 35.053699],
          [46.077815, 35.053046],
          [46.077133, 35.052214],
          [46.076197, 35.051784],
          [46.074786, 35.051285],
          [46.073417, 35.051136],
          [46.072111, 35.050652],
          [46.070932, 35.050188],
          [46.069377, 35.049581],
          [46.068791, 35.04921],
          [46.068136, 35.048651],
          [46.067842, 35.048218],
          [46.067901, 35.047615],
          [46.067891, 35.047002],
          [46.067943, 35.045925],
          [46.067899, 35.044514],
          [46.067835, 35.043602],
          [46.067703, 35.043067],
          [46.067362, 35.042868],
          [46.066848, 35.042586],
          [46.066218, 35.041667],
          [46.065763, 35.041336],
          [46.064951, 35.040909],
          [46.063893, 35.040239],
          [46.063041, 35.03959],
          [46.062211, 35.039056],
          [46.061258, 35.03853],
          [46.060161, 35.038144],
          [46.059426, 35.037953],
          [46.058619, 35.037981],
          [46.057784, 35.038161],
          [46.05715, 35.03858],
          [46.056476, 35.039153],
          [46.056158, 35.039694],
          [46.056209, 35.040162],
          [46.05649, 35.040528],
          [46.056645, 35.041189],
          [46.056524, 35.042009],
          [46.056169, 35.04309],
          [46.055928, 35.044116],
          [46.055372, 35.045088],
          [46.054722, 35.045915],
          [46.053838, 35.046697],
          [46.052981, 35.047376],
          [46.052413, 35.047973],
          [46.052267, 35.04847],
          [46.052342, 35.049063],
          [46.052754, 35.049717],
          [46.053037, 35.050587],
          [46.053136, 35.051563],
          [46.053047, 35.052062],
          [46.05274, 35.052542],
          [46.052321, 35.052841],
          [46.051701, 35.053029],
          [46.051055, 35.053014],
          [46.050433, 35.052816],
          [46.049966, 35.052418],
          [46.049577, 35.051712],
          [46.049037, 35.050918],
          [46.048618, 35.050414],
          [46.04809, 35.050054],
          [46.047605, 35.049808],
          [46.047095, 35.049792],
          [46.046559, 35.050008],
          [46.045886, 35.050522],
          [46.044502, 35.051653],
          [46.043566, 35.052334],
          [46.043046, 35.052577],
          [46.042538, 35.052631],
          [46.042093, 35.052547],
          [46.041597, 35.05242],
          [46.040972, 35.051966],
          [46.040491, 35.051371],
          [46.039996, 35.051007],
          [46.039213, 35.050794],
          [46.038417, 35.05076],
          [46.036491, 35.051019],
          [46.034218, 35.051546],
          [46.033087, 35.051898],
          [46.03184, 35.052355],
          [46.030229, 35.052489],
          [46.029638, 35.052773],
          [46.028967, 35.053425],
          [46.027924, 35.054012],
          [46.026516, 35.054674],
          [46.026189, 35.054827],
          [46.02578, 35.055243],
          [46.02565, 35.055579],
          [46.025844, 35.055838],
          [46.02651, 35.056088],
          [46.027318, 35.056497],
          [46.027634, 35.056996],
          [46.027597, 35.057596],
          [46.027251, 35.058171],
          [46.026509, 35.058803],
          [46.026308, 35.059288],
          [46.026023, 35.059973],
          [46.02548, 35.060824],
          [46.024601, 35.061387],
          [46.022486, 35.062526],
          [46.020596, 35.063789],
          [46.020148, 35.064079],
          [46.019688, 35.064377],
          [46.018427, 35.064945],
          [46.017426, 35.065372],
          [46.0168, 35.065983],
          [46.016609, 35.066647],
          [46.016823, 35.067264],
          [46.017168, 35.067963],
          [46.01712, 35.068895],
          [46.016688, 35.069615],
          [46.016051, 35.070473],
          [46.015202, 35.071155],
          [46.014528, 35.071353],
          [46.013628, 35.071515],
          [46.012241, 35.071437],
          [46.010592, 35.071455],
          [46.009465, 35.071467],
          [46.006257, 35.071256],
          [46.004692, 35.071075],
          [46.003996, 35.070766],
          [46.003617, 35.070377],
          [46.003031, 35.069067],
          [46.002938, 35.068912],
          [46.002396, 35.068011],
          [46.001681, 35.066862],
          [46.001338, 35.065838],
          [46.001164, 35.064596],
          [46.000927, 35.063179],
          [46.000785, 35.062529],
          [46.000474, 35.062098],
          [46.000087, 35.061829],
          [45.9997, 35.061788],
          [45.99946, 35.061762],
          [45.998198, 35.061935],
          [45.996314, 35.062491],
          [45.994864, 35.063102],
          [45.994151, 35.063641],
          [45.992982, 35.064433],
          [45.991255, 35.065451],
          [45.990025, 35.066461],
          [45.989827, 35.066785],
          [45.989489, 35.067339],
          [45.988, 35.069154],
          [45.987705, 35.069333],
          [45.98748, 35.069391],
          [45.987299, 35.069437],
          [45.986952, 35.06941],
          [45.986613, 35.069314],
          [45.986177, 35.069099],
          [45.985136, 35.068391],
          [45.984104, 35.068733],
          [45.983653, 35.068882],
          [45.983136, 35.069085],
          [45.982779, 35.069291],
          [45.982527, 35.069514],
          [45.982313, 35.069793],
          [45.982127, 35.070115],
          [45.981991, 35.070511],
          [45.981925, 35.070837],
          [45.98194, 35.071196],
          [45.981889, 35.071462],
          [45.981806, 35.071697],
          [45.981564, 35.071913],
          [45.981265, 35.072103],
          [45.980663, 35.072384],
          [45.980393, 35.072443],
          [45.979815, 35.072499],
          [45.979156, 35.072555],
          [45.978438, 35.072474],
          [45.977482, 35.072405],
          [45.976809, 35.07228],
          [45.976208, 35.072247],
          [45.975752, 35.072276],
          [45.975422, 35.072414],
          [45.975145, 35.072589],
          [45.974762, 35.07291],
          [45.974208, 35.073287],
          [45.97383, 35.073506],
          [45.973306, 35.073632],
          [45.972824, 35.073703],
          [45.972305, 35.073702],
          [45.971652, 35.073584],
          [45.971083, 35.073361],
          [45.970502, 35.073235],
          [45.970029, 35.073194],
          [45.969556, 35.073279],
          [45.969143, 35.073453],
          [45.968724, 35.073727],
          [45.968279, 35.074221],
          [45.967873, 35.074697],
          [45.967756, 35.075116],
          [45.967675, 35.07556],
          [45.96769, 35.076065],
          [45.967801, 35.076533],
          [45.968027, 35.077021],
          [45.968136, 35.077431],
          [45.968119, 35.077832],
          [45.967996, 35.078205],
          [45.967743, 35.078554],
          [45.967422, 35.078921],
          [45.967035, 35.079252],
          [45.966428, 35.079691],
          [45.965901, 35.079927],
          [45.965341, 35.080085],
          [45.964693, 35.080207],
          [45.963133, 35.080613],
          [45.9621, 35.080924],
          [45.960454, 35.081309],
          [45.95949, 35.081524],
          [45.958756, 35.08163],
          [45.958085, 35.081708],
          [45.957445, 35.081891],
          [45.956901, 35.082136],
          [45.956295, 35.082454],
          [45.95608, 35.082728],
          [45.955866, 35.083034],
          [45.955689, 35.083438],
          [45.955403, 35.083976],
          [45.954977, 35.084492],
          [45.954597, 35.084948],
          [45.953658, 35.085887],
          [45.953064, 35.086449],
          [45.952744, 35.086852],
          [45.952498, 35.087226],
          [45.951972, 35.088038],
          [45.951513, 35.088472],
          [45.950971, 35.088899],
          [45.950346, 35.089362],
          [45.949636, 35.089809],
          [45.948892, 35.090189],
          [45.948347, 35.090407],
          [45.947628, 35.090641],
          [45.946702, 35.090865],
          [45.94599, 35.09103],
          [45.945343, 35.091088],
          [45.944707, 35.091061],
          [45.944116, 35.090968],
          [45.943475, 35.090727],
          [45.942883, 35.090357],
          [45.942321, 35.089913],
          [45.942093, 35.089494],
          [45.941749, 35.088686],
          [45.941326, 35.088202],
          [45.940677, 35.087386],
          [45.939528, 35.085974],
          [45.938086, 35.084228],
          [45.937163, 35.082979],
          [45.936463, 35.082131],
          [45.935524, 35.081462],
          [45.934776, 35.081135],
          [45.934056, 35.080997],
          [45.933332, 35.080892],
          [45.932798, 35.080904],
          [45.932116, 35.081115],
          [45.93146, 35.081553],
          [45.930665, 35.082204],
          [45.929683, 35.082967],
          [45.92876, 35.083797],
          [45.928075, 35.084638],
          [45.927158, 35.085714],
          [45.926556, 35.086665],
          [45.925903, 35.087757],
          [45.925582, 35.0886],
          [45.925179, 35.089657],
          [45.92485, 35.090884],
          [45.924571, 35.091992],
          [45.924277, 35.093066],
          [45.922425, 35.095389],
          [45.921987, 35.095489],
          [45.921986, 35.092095],
          [45.922083, 35.091989],
          [45.922215, 35.091824],
          [45.922333, 35.091548],
          [45.922449, 35.091239],
          [45.922598, 35.090744],
          [45.922649, 35.090279],
          [45.922637, 35.089763],
          [45.922532, 35.089143],
          [45.92226, 35.08872],
          [45.92191, 35.088532],
          [45.921437, 35.088219],
          [45.921317, 35.087816],
          [45.921395, 35.087064],
          [45.921689, 35.086288],
          [45.922123, 35.085629],
          [45.922367, 35.085218],
          [45.922672, 35.08486],
          [45.922897, 35.084573],
          [45.923035, 35.084035],
          [45.923179, 35.083475],
          [45.923345, 35.083005],
          [45.923507, 35.082662],
          [45.923554, 35.08236],
          [45.923754, 35.081874],
          [45.923664, 35.081513],
          [45.923567, 35.081174],
          [45.923516, 35.080922],
          [45.923424, 35.080551],
          [45.923409, 35.080197],
          [45.923421, 35.079797],
          [45.9235, 35.079029],
          [45.923411, 35.078374],
          [45.923298, 35.077871],
          [45.92315, 35.077426],
          [45.922974, 35.07707],
          [45.922797, 35.076757],
          [45.922651, 35.07627],
          [45.922436, 35.075952],
          [45.922299, 35.075652],
          [45.922236, 35.075375],
          [45.922248, 35.075174],
          [45.922194, 35.074985],
          [45.9221, 35.074855],
          [45.922146, 35.074569],
          [45.922141, 35.07435],
          [45.922122, 35.074228],
          [45.922102, 35.074095],
          [45.922215, 35.073782],
          [45.922294, 35.07348],
          [45.922266, 35.072971],
          [45.92222, 35.072419],
          [45.922217, 35.072037],
          [45.922117, 35.071511],
          [45.922232, 35.071155],
          [45.922504, 35.070786],
          [45.922785, 35.070427],
          [45.922982, 35.070203],
          [45.923027, 35.069891],
          [45.922946, 35.069712],
          [45.922853, 35.069142],
          [45.922576, 35.068354],
          [45.922667, 35.068002],
          [45.922956, 35.067752],
          [45.923274, 35.06759],
          [45.923437, 35.067377],
          [45.923269, 35.066904],
          [45.922962, 35.066415],
          [45.922729, 35.065802],
          [45.922646, 35.065635],
          [45.922441, 35.065199],
          [45.922237, 35.064906],
          [45.922017, 35.064413],
          [45.92201, 35.063848],
          [45.921741, 35.062884],
          [45.921331, 35.062098],
          [45.92122, 35.061098],
          [45.921221, 35.059873],
          [45.921064, 35.058662],
          [45.921292, 35.057775],
          [45.921119, 35.05751],
          [45.920826, 35.056935],
          [45.920668, 35.056336],
          [45.92053, 35.055909],
          [45.920292, 35.055508],
          [45.920406, 35.055382],
          [45.920578, 35.05516],
          [45.920766, 35.054943],
          [45.920932, 35.054827],
          [45.921204, 35.05473],
          [45.921483, 35.054682],
          [45.921756, 35.054653],
          [45.921855, 35.054642],
          [45.922128, 35.054549],
          [45.922506, 35.054291],
          [45.922809, 35.054207],
          [45.92313, 35.054133],
          [45.923578, 35.054075],
          [45.923938, 35.053986],
          [45.924286, 35.053825],
          [45.924616, 35.053722],
          [45.924795, 35.053682],
          [45.925127, 35.053653],
          [45.925538, 35.053615],
          [45.925952, 35.053507],
          [45.926382, 35.053351],
          [45.926848, 35.053229],
          [45.927246, 35.053097],
          [45.927579, 35.053095],
          [45.927922, 35.052999],
          [45.928302, 35.052971],
          [45.928589, 35.052802],
          [45.92903, 35.052584],
          [45.929191, 35.052382],
          [45.929392, 35.052062],
          [45.929394, 35.051808],
          [45.929526, 35.051514],
          [45.929691, 35.051311],
          [45.929687, 35.051054],
          [45.929865, 35.05077],
          [45.930147, 35.050428],
          [45.930435, 35.05007],
          [45.930678, 35.04983],
          [45.930798, 35.049621],
          [45.930986, 35.049506],
          [45.931183, 35.049413],
          [45.931278, 35.049295],
          [45.93135, 35.049149],
          [45.93133, 35.048959],
          [45.931265, 35.048717],
          [45.931277, 35.048439],
          [45.931381, 35.048182],
          [45.93151, 35.047921],
          [45.931548, 35.04768],
          [45.931537, 35.047419],
          [45.931399, 35.047208],
          [45.931271, 35.046949],
          [45.931244, 35.046646],
          [45.931178, 35.046289],
          [45.931114, 35.045964],
          [45.930963, 35.045635],
          [45.930795, 35.045347],
          [45.930543, 35.045045],
          [45.930254, 35.045094],
          [45.929841, 35.045124],
          [45.92938, 35.045088],
          [45.928947, 35.045143],
          [45.928264, 35.045256],
          [45.927851, 35.045483],
          [45.927535, 35.045574],
          [45.927202, 35.045732],
          [45.926949, 35.045886],
          [45.926672, 35.045961],
          [45.926356, 35.046011],
          [45.926063, 35.046089],
          [45.92583, 35.046193],
          [45.925601, 35.046292],
          [45.925379, 35.046307],
          [45.924995, 35.04617],
          [45.924548, 35.04604],
          [45.924198, 35.045975],
          [45.923952, 35.045981],
          [45.923633, 35.04593],
          [45.923339, 35.045874],
          [45.923056, 35.045817],
          [45.922631, 35.045687],
          [45.922362, 35.045646],
          [45.922033, 35.045688],
          [45.92169, 35.045738],
          [45.921464, 35.045735],
          [45.921138, 35.045814],
          [45.92081, 35.04588],
          [45.920389, 35.045851],
          [45.919971, 35.045816],
          [45.919434, 35.04589],
          [45.919014, 35.045953],
          [45.918657, 35.045926],
          [45.918027, 35.045924],
          [45.917405, 35.045934],
          [45.917002, 35.045961],
          [45.916548, 35.045952],
          [45.916252, 35.04591],
          [45.915885, 35.045886],
          [45.915478, 35.045937],
          [45.915034, 35.04599],
          [45.914746, 35.04598],
          [45.914385, 35.045959],
          [45.914132, 35.045979],
          [45.913789, 35.045854],
          [45.913433, 35.045657],
          [45.913152, 35.045498],
          [45.912812, 35.045409],
          [45.912572, 35.045547],
          [45.912294, 35.045751],
          [45.911887, 35.045871],
          [45.91136, 35.045962],
          [45.910916, 35.046015],
          [45.910577, 35.046105],
          [45.910225, 35.046336],
          [45.909887, 35.046384],
          [45.909595, 35.046425],
          [45.909343, 35.046561],
          [45.909111, 35.046798],
          [45.908924, 35.04689],
          [45.908605, 35.046949],
          [45.908195, 35.046969],
          [45.907759, 35.047099],
          [45.907383, 35.047278],
          [45.907025, 35.047377],
          [45.906461, 35.047446],
          [45.906156, 35.047456],
          [45.905835, 35.04753],
          [45.905483, 35.047608],
          [45.904996, 35.047664],
          [45.904661, 35.04768],
          [45.904235, 35.047569],
          [45.903818, 35.047471],
          [45.903302, 35.047476],
          [45.902976, 35.047417],
          [45.902655, 35.047316],
          [45.902457, 35.047193],
          [45.902114, 35.047045],
          [45.901996, 35.047001],
          [45.901765, 35.046916],
          [45.901167, 35.046806],
          [45.900587, 35.04668],
          [45.900242, 35.046634],
          [45.89987, 35.046458],
          [45.899484, 35.046359],
          [45.899068, 35.046283],
          [45.898168, 35.046062],
          [45.88039, 35.037985],
          [45.88039, 35.037984],
          [45.880089, 35.03728],
          [45.879941, 35.037181],
          [45.879832, 35.037113],
          [45.879807, 35.036878],
          [45.879752, 35.036675],
          [45.87966, 35.036434],
          [45.879586, 35.036267],
          [45.879562, 35.035949],
          [45.879489, 35.035524],
          [45.879334, 35.035155],
          [45.879238, 35.034918],
          [45.879125, 35.034703],
          [45.879074, 35.03456],
          [45.879039, 35.034331],
          [45.879105, 35.03414],
          [45.878922, 35.033813],
          [45.878764, 35.033564],
          [45.87858, 35.033209],
          [45.878379, 35.033008],
          [45.878009, 35.032814],
          [45.877819, 35.032612],
          [45.877681, 35.032488],
          [45.877539, 35.032329],
          [45.877312, 35.032087],
          [45.877198, 35.031991],
          [45.876813, 35.031763],
          [45.876525, 35.031513],
          [45.876671, 35.031318],
          [45.876815, 35.030962],
          [45.876943, 35.030806],
          [45.877112, 35.030639],
          [45.877296, 35.030511],
          [45.877448, 35.030342],
          [45.87745, 35.030153],
          [45.877517, 35.029815],
          [45.877504, 35.02939],
          [45.877558, 35.029104],
          [45.877489, 35.028936],
          [45.877531, 35.028649],
          [45.877609, 35.028285],
          [45.877729, 35.028095],
          [45.877783, 35.027768],
          [45.877677, 35.027515],
          [45.877618, 35.027253],
          [45.877661, 35.027034],
          [45.877549, 35.02669],
          [45.877367, 35.026256],
          [45.877221, 35.025687],
          [45.877185, 35.025344],
          [45.877207, 35.02506],
          [45.87719, 35.02475],
          [45.877182, 35.024567],
          [45.87721, 35.024396],
          [45.877218, 35.024211],
          [45.877396, 35.023969],
          [45.877504, 35.023775],
          [45.8776, 35.023552],
          [45.877683, 35.023386],
          [45.877809, 35.023157],
          [45.877899, 35.022994],
          [45.878051, 35.022784],
          [45.878254, 35.022667],
          [45.878369, 35.0225],
          [45.878386, 35.022221],
          [45.878577, 35.022055],
          [45.878686, 35.021907],
          [45.87893, 35.021695],
          [45.879027, 35.021632],
          [45.879084, 35.021448],
          [45.879243, 35.021176],
          [45.879234, 35.020985],
          [45.87913, 35.020915],
          [45.878976, 35.020859],
          [45.878792, 35.020895],
          [45.878675, 35.020837],
          [45.878624, 35.020717],
          [45.878675, 35.020597],
          [45.878747, 35.020364],
          [45.87876, 35.020265],
          [45.878773, 35.020162],
          [45.87873, 35.019967],
          [45.878704, 35.019796],
          [45.878746, 35.01966],
          [45.878862, 35.019475],
          [45.878919, 35.019226],
          [45.878843, 35.019073],
          [45.87878, 35.018969],
          [45.878589, 35.01896],
          [45.878469, 35.018866],
          [45.878424, 35.018772],
          [45.878387, 35.018686],
          [45.878249, 35.018604],
          [45.878091, 35.018535],
          [45.878055, 35.01843],
          [45.878064, 35.018249],
          [45.878135, 35.018012],
          [45.878365, 35.017876],
          [45.878526, 35.017632],
          [45.878616, 35.017474],
          [45.878752, 35.017326],
          [45.878908, 35.017171],
          [45.879062, 35.017144],
          [45.879179, 35.017179],
          [45.879305, 35.01719],
          [45.879533, 35.017022],
          [45.87957, 35.016845],
          [45.879581, 35.016715],
          [45.879777, 35.016506],
          [45.879854, 35.016337],
          [45.879791, 35.016232],
          [45.879624, 35.016169],
          [45.879644, 35.015986],
          [45.87969, 35.015868],
          [45.879964, 35.015669],
          [45.880049, 35.015553],
          [45.8801, 35.015324],
          [45.880124, 35.015117],
          [45.880039, 35.014772],
          [45.879902, 35.014543],
          [45.879696, 35.014472],
          [45.879558, 35.014459],
          [45.879417, 35.01441],
          [45.879133, 35.014394],
          [45.878845, 35.014383],
          [45.878565, 35.014293],
          [45.878456, 35.014137],
          [45.878359, 35.013984],
          [45.878324, 35.013865],
          [45.87837, 35.013701],
          [45.878579, 35.013545],
          [45.878723, 35.013429],
          [45.878772, 35.013255],
          [45.87894, 35.013065],
          [45.878973, 35.012806],
          [45.87913, 35.012632],
          [45.879093, 35.012482],
          [45.878972, 35.012231],
          [45.878883, 35.012154],
          [45.878777, 35.011988],
          [45.87862, 35.011983],
          [45.878356, 35.012005],
          [45.878185, 35.011924],
          [45.878098, 35.01181],
          [45.878037, 35.011627],
          [45.877644, 35.011474],
          [45.877561, 35.011336],
          [45.87741, 35.011247],
          [45.877288, 35.011079],
          [45.877042, 35.010997],
          [45.876834, 35.010918],
          [45.87649, 35.01077],
          [45.876195, 35.010599],
          [45.875954, 35.010452],
          [45.875704, 35.010334],
          [45.875403, 35.010247],
          [45.875029, 35.010127],
          [45.875061, 35.009975],
          [45.875046, 35.009761],
          [45.875106, 35.009566],
          [45.87513, 35.009317],
          [45.875225, 35.009225],
          [45.87521, 35.009124],
          [45.875262, 35.008974],
          [45.875292, 35.008829],
          [45.875312, 35.008689],
          [45.875325, 35.008499],
          [45.875327, 35.008157],
          [45.875263, 35.007803],
          [45.875227, 35.00764],
          [45.875196, 35.007477],
          [45.875344, 35.007395],
          [45.875566, 35.007383],
          [45.875876, 35.007341],
          [45.87611, 35.007308],
          [45.876387, 35.007137],
          [45.87664, 35.007068],
          [45.876802, 35.006866],
          [45.877151, 35.006517],
          [45.877362, 35.006245],
          [45.877753, 35.006189],
          [45.878018, 35.006146],
          [45.878241, 35.005918],
          [45.878512, 35.005701],
          [45.878783, 35.005441],
          [45.879078, 35.005276],
          [45.879285, 35.005159],
          [45.879403, 35.005007],
          [45.879543, 35.004742],
          [45.879715, 35.004515],
          [45.879777, 35.004382],
          [45.879826, 35.004068],
          [45.879924, 35.003793],
          [45.879956, 35.003656],
          [45.880285, 35.003494],
          [45.88071, 35.003305],
          [45.880818, 35.00307],
          [45.88083, 35.00281],
          [45.880905, 35.002553],
          [45.881005, 35.002352],
          [45.881337, 35.002461],
          [45.88158, 35.002615],
          [45.881755, 35.002705],
          [45.881943, 35.002779],
          [45.882183, 35.002877],
          [45.882381, 35.002836],
          [45.882503, 35.002571],
          [45.882886, 35.002418],
          [45.882989, 35.002268],
          [45.883047, 35.002103],
          [45.883006, 35.001857],
          [45.883048, 35.001714],
          [45.883101, 35.001568],
          [45.883577, 35.001623],
          [45.883898, 35.001556],
          [45.884163, 35.001687],
          [45.884364, 35.001768],
          [45.884583, 35.001766],
          [45.884573, 35.001552],
          [45.884484, 35.001231],
          [45.884476, 35.001026],
          [45.884498, 35.000872],
          [45.884885, 35.000906],
          [45.885136, 35.000856],
          [45.885291, 35.000669],
          [45.885301, 35.000489],
          [45.885389, 35.000417],
          [45.885522, 35.000407],
          [45.885695, 35.000465],
          [45.885727, 35.000328],
          [45.885771, 35.000105],
          [45.886155, 34.999914],
          [45.886175, 34.999751],
          [45.886236, 34.999421],
          [45.886231, 34.999183],
          [45.88644, 34.999052],
          [45.886384, 34.998795],
          [45.886393, 34.998545],
          [45.886466, 34.998414],
          [45.886334, 34.998143],
          [45.886271, 34.997986],
          [45.886209, 34.997725],
          [45.886239, 34.997405],
          [45.886284, 34.997055],
          [45.886217, 34.996819],
          [45.886161, 34.99651],
          [45.88615, 34.996297],
          [45.886017, 34.996129],
          [45.88628, 34.995927],
          [45.886483, 34.995862],
          [45.886523, 34.995583],
          [45.886887, 34.995246],
          [45.887036, 34.995168],
          [45.886997, 34.994973],
          [45.886919, 34.994739],
          [45.886846, 34.994541],
          [45.886717, 34.994152],
          [45.886612, 34.993964],
          [45.886591, 34.993795],
          [45.886564, 34.993603],
          [45.886649, 34.993363],
          [45.886936, 34.993189],
          [45.887392, 34.992971],
          [45.887692, 34.992692],
          [45.887968, 34.992493],
          [45.887876, 34.99227],
          [45.887791, 34.992028],
          [45.887779, 34.991721],
          [45.888069, 34.991467],
          [45.88821, 34.991315],
          [45.888202, 34.991003],
          [45.888163, 34.990677],
          [45.888195, 34.990428],
          [45.888095, 34.990282],
          [45.8881, 34.990084],
          [45.888245, 34.989814],
          [45.888346, 34.989707],
          [45.888372, 34.989609],
          [45.888567, 34.989428],
          [45.88881, 34.989277],
          [45.888997, 34.98915],
          [45.889214, 34.988923],
          [45.889509, 34.988842],
          [45.889706, 34.988732],
          [45.889744, 34.988598],
          [45.889643, 34.988359],
          [45.889483, 34.988242],
          [45.889379, 34.988012],
          [45.889019, 34.987904],
          [45.888872, 34.987705],
          [45.888529, 34.987556],
          [45.888522, 34.987421],
          [45.888652, 34.987271],
          [45.888645, 34.98709],
          [45.888485, 34.986776],
          [45.888282, 34.98649],
          [45.888404, 34.986308],
          [45.888623, 34.986133],
          [45.888469, 34.985842],
          [45.888438, 34.985655],
          [45.888408, 34.985497],
          [45.888294, 34.985316],
          [45.888344, 34.985073],
          [45.888373, 34.984795],
          [45.888179, 34.984741],
          [45.888089, 34.984612],
          [45.888032, 34.984519],
          [45.88791, 34.984354],
          [45.887629, 34.984184],
          [45.887447, 34.98387],
          [45.887476, 34.983654],
          [45.887504, 34.983391],
          [45.887474, 34.983256],
          [45.887509, 34.983062],
          [45.88745, 34.982684],
          [45.887365, 34.982352],
          [45.887566, 34.982105],
          [45.88762, 34.981922],
          [45.887925, 34.9816],
          [45.888213, 34.981403],
          [45.888447, 34.981371],
          [45.888827, 34.981101],
          [45.889237, 34.980811],
          [45.889181, 34.980639],
          [45.889099, 34.980147],
          [45.889449, 34.979845],
          [45.889692, 34.979629],
          [45.889595, 34.979276],
          [45.889535, 34.978846],
          [45.889742, 34.97849],
          [45.88979, 34.978219],
          [45.889838, 34.978109],
          [45.889892, 34.977981],
          [45.889935, 34.977757],
          [45.889913, 34.977475],
          [45.889873, 34.977211],
          [45.889997, 34.976949],
          [45.890112, 34.976629],
          [45.889976, 34.976443],
          [45.890567, 34.976013],
          [45.890866, 34.975753],
          [45.89084, 34.975654],
          [45.890731, 34.975318],
          [45.890879, 34.975062],
          [45.891123, 34.974896],
          [45.891203, 34.974769],
          [45.891337, 34.974483],
          [45.891229, 34.974216],
          [45.891106, 34.973892],
          [45.891094, 34.97365],
          [45.890964, 34.973298],
          [45.890723, 34.973173],
          [45.890541, 34.972882],
          [45.890292, 34.97266],
          [45.89022, 34.972556],
          [45.889933, 34.97234],
          [45.889772, 34.972196],
          [45.889608, 34.972061],
          [45.889574, 34.971889],
          [45.889475, 34.971766],
          [45.889301, 34.971549],
          [45.889139, 34.97125],
          [45.889335, 34.971051],
          [45.889683, 34.970937],
          [45.889772, 34.970865],
          [45.889906, 34.97062],
          [45.889846, 34.970411],
          [45.889949, 34.969938],
          [45.889937, 34.969781],
          [45.890081, 34.969356],
          [45.890688, 34.969154],
          [45.891158, 34.969013],
          [45.891351, 34.968824],
          [45.891624, 34.96892],
          [45.891844, 34.968899],
          [45.891966, 34.968737],
          [45.892092, 34.968419],
          [45.892274, 34.968185],
          [45.8926, 34.967985],
          [45.893025, 34.967689],
          [45.893044, 34.967329],
          [45.893065, 34.966908],
          [45.893062, 34.966548],
          [45.893312, 34.966274],
          [45.893551, 34.96602],
          [45.893624, 34.965824],
          [45.893655, 34.965636],
          [45.893784, 34.965416],
          [45.893839, 34.965195],
          [45.893952, 34.964978],
          [45.894164, 34.964902],
          [45.894389, 34.964707],
          [45.89435, 34.964489],
          [45.894237, 34.964205],
          [45.894262, 34.963952],
          [45.894307, 34.963775],
          [45.894392, 34.963605],
          [45.894739, 34.963398],
          [45.895061, 34.963382],
          [45.895352, 34.963484],
          [45.895604, 34.963388],
          [45.89584, 34.963364],
          [45.895843, 34.963246],
          [45.896062, 34.963048],
          [45.89621, 34.962702],
          [45.896041, 34.962531],
          [45.895929, 34.962336],
          [45.895995, 34.962066],
          [45.896146, 34.961716],
          [45.896166, 34.961379],
          [45.896417, 34.960908],
          [45.89658, 34.960733],
          [45.896725, 34.960534],
          [45.896864, 34.960438],
          [45.897, 34.960222],
          [45.897312, 34.959992],
          [45.897018, 34.959595],
          [45.89683, 34.959498],
          [45.896489, 34.959377],
          [45.896297, 34.959223],
          [45.896151, 34.959161],
          [45.895983, 34.959036],
          [45.895684, 34.958856],
          [45.895446, 34.958786],
          [45.895214, 34.958673],
          [45.895051, 34.958501],
          [45.895118, 34.958324],
          [45.895141, 34.958044],
          [45.89518, 34.957606],
          [45.895062, 34.957388],
          [45.895245, 34.95705],
          [45.895206, 34.956833],
          [45.895059, 34.956634],
          [45.894918, 34.95657],
          [45.8947, 34.956553],
          [45.894615, 34.956423],
          [45.894503, 34.956293],
          [45.894445, 34.956177],
          [45.894462, 34.955916],
          [45.894322, 34.955834],
          [45.89414, 34.955848],
          [45.893951, 34.9559],
          [45.89391, 34.955786],
          [45.893739, 34.955582],
          [45.893496, 34.955424],
          [45.893314, 34.955312],
          [45.893117, 34.955244],
          [45.892721, 34.955151],
          [45.892475, 34.955312],
          [45.892153, 34.955506],
          [45.892133, 34.955229],
          [45.892174, 34.955062],
          [45.892059, 34.954834],
          [45.891866, 34.9547],
          [45.89181, 34.954462],
          [45.891716, 34.954272],
          [45.891636, 34.954113],
          [45.891619, 34.953896],
          [45.891466, 34.953413],
          [45.891806, 34.953333],
          [45.892119, 34.9533],
          [45.892308, 34.953205],
          [45.892751, 34.953173],
          [45.893287, 34.953067],
          [45.893597, 34.952936],
          [45.893943, 34.952729],
          [45.893897, 34.95255],
          [45.893657, 34.952166],
          [45.893628, 34.951881],
          [45.893527, 34.951576],
          [45.893388, 34.951409],
          [45.893466, 34.951188],
          [45.893504, 34.951008],
          [45.89351, 34.950834],
          [45.89365, 34.950724],
          [45.893867, 34.95069],
          [45.894148, 34.950621],
          [45.894222, 34.950364],
          [45.894162, 34.950042],
          [45.894153, 34.949898],
          [45.894145, 34.949782],
          [45.893783, 34.949688],
          [45.893432, 34.949704],
          [45.893021, 34.949881],
          [45.892841, 34.949989],
          [45.892414, 34.950018],
          [45.892209, 34.949919],
          [45.892075, 34.949836],
          [45.891941, 34.949625],
          [45.891725, 34.949552],
          [45.891327, 34.949492],
          [45.890911, 34.949431],
          [45.890542, 34.949445],
          [45.890222, 34.949381],
          [45.890471, 34.948905],
          [45.890734, 34.948506],
          [45.891041, 34.947884],
          [45.891459, 34.947364],
          [45.891535, 34.946961],
          [45.891698, 34.946374],
          [45.891207, 34.946411],
          [45.891005, 34.94648],
          [45.890569, 34.946493],
          [45.890209, 34.946604],
          [45.889952, 34.946636],
          [45.88969, 34.946777],
          [45.889527, 34.946582],
          [45.88937, 34.946389],
          [45.889207, 34.946325],
          [45.889101, 34.946156],
          [45.88894, 34.946012],
          [45.888729, 34.945741],
          [45.888371, 34.945486],
          [45.888108, 34.94543],
          [45.887854, 34.945382],
          [45.887529, 34.945281],
          [45.88729, 34.945273],
          [45.886991, 34.945228],
          [45.886475, 34.944997],
          [45.885932, 34.944837],
          [45.885626, 34.944784],
          [45.885166, 34.945012],
          [45.88444, 34.945339],
          [45.884268, 34.945351],
          [45.883559, 34.945441],
          [45.882828, 34.945446],
          [45.882485, 34.945451],
          [45.88213, 34.945538],
          [45.881906, 34.945476],
          [45.881633, 34.945117],
          [45.881382, 34.944867],
          [45.881456, 34.944629],
          [45.881537, 34.944309],
          [45.881516, 34.943704],
          [45.881448, 34.943529],
          [45.881293, 34.943299],
          [45.88138, 34.94296],
          [45.881257, 34.942569],
          [45.880945, 34.942279],
          [45.880864, 34.942031],
          [45.880651, 34.941797],
          [45.880484, 34.94178],
          [45.880186, 34.94174],
          [45.879983, 34.941542],
          [45.879812, 34.941428],
          [45.879572, 34.941326],
          [45.879479, 34.940986],
          [45.879353, 34.940718],
          [45.879205, 34.940627],
          [45.879045, 34.94053],
          [45.879078, 34.94035],
          [45.879123, 34.940089],
          [45.879191, 34.939852],
          [45.879117, 34.939631],
          [45.878989, 34.939396],
          [45.878881, 34.939242],
          [45.878723, 34.939172],
          [45.878634, 34.939071],
          [45.878474, 34.938997],
          [45.87818, 34.938928],
          [45.877996, 34.938736],
          [45.877973, 34.938473],
          [45.878022, 34.938206],
          [45.878135, 34.937947],
          [45.878196, 34.937786],
          [45.878005, 34.937661],
          [45.877974, 34.937474],
          [45.877933, 34.93736],
          [45.87769, 34.937249],
          [45.877545, 34.937101],
          [45.877188, 34.936959],
          [45.876982, 34.936753],
          [45.876757, 34.936667],
          [45.876431, 34.936603],
          [45.876145, 34.9365],
          [45.875884, 34.93643],
          [45.875597, 34.936495],
          [45.875367, 34.93641],
          [45.875143, 34.936263],
          [45.874992, 34.936027],
          [45.874882, 34.935799],
          [45.874703, 34.935737],
          [45.874328, 34.93564],
          [45.874163, 34.935633],
          [45.87386, 34.93557],
          [45.873591, 34.935642],
          [45.873423, 34.93578],
          [45.873337, 34.935945],
          [45.873266, 34.936216],
          [45.873098, 34.936241],
          [45.872927, 34.936277],
          [45.872635, 34.936413],
          [45.872427, 34.936348],
          [45.87235, 34.936118],
          [45.872306, 34.935924],
          [45.87214, 34.935719],
          [45.871948, 34.935562],
          [45.871785, 34.935371],
          [45.871831, 34.935091],
          [45.871767, 34.934755],
          [45.871685, 34.934569],
          [45.87168, 34.934373],
          [45.871656, 34.934021],
          [45.871515, 34.933474],
          [45.871482, 34.933237],
          [45.871541, 34.932898],
          [45.871824, 34.932599],
          [45.871968, 34.932306],
          [45.872162, 34.932032],
          [45.872343, 34.931924],
          [45.872428, 34.931773],
          [45.872389, 34.931579],
          [45.872459, 34.931346],
          [45.872628, 34.931044],
          [45.872594, 34.930867],
          [45.872627, 34.930711],
          [45.872571, 34.930365],
          [45.87295, 34.930086],
          [45.873065, 34.929724],
          [45.872794, 34.929613],
          [45.872604, 34.929469],
          [45.872265, 34.929398],
          [45.871989, 34.929331],
          [45.871829, 34.929172],
          [45.871675, 34.928989],
          [45.871537, 34.928934],
          [45.871352, 34.928958],
          [45.871136, 34.928973],
          [45.871005, 34.928814],
          [45.870803, 34.928579],
          [45.870528, 34.928323],
          [45.870294, 34.928309],
          [45.869804, 34.928355],
          [45.869401, 34.928385],
          [45.869122, 34.928159],
          [45.868869, 34.927984],
          [45.868676, 34.927695],
          [45.868549, 34.927507],
          [45.86845, 34.9273],
          [45.868177, 34.927269],
          [45.868036, 34.927201],
          [45.867806, 34.927139],
          [45.867611, 34.927062],
          [45.867601, 34.926937],
          [45.867648, 34.926638],
          [45.867523, 34.926417],
          [45.867498, 34.926276],
          [45.867365, 34.926065],
          [45.867546, 34.925873],
          [45.867741, 34.925778],
          [45.867923, 34.92552],
          [45.868116, 34.925396],
          [45.868222, 34.925199],
          [45.86823, 34.924968],
          [45.868534, 34.92466],
          [45.868488, 34.924397],
          [45.868626, 34.923969],
          [45.868579, 34.923767],
          [45.868778, 34.923272],
          [45.868755, 34.922877],
          [45.869009, 34.92264],
          [45.869133, 34.922463],
          [45.869088, 34.922138],
          [45.86924, 34.921787],
          [45.869228, 34.921391],
          [45.868983, 34.921187],
          [45.868794, 34.921108],
          [45.868568, 34.920999],
          [45.868514, 34.920788],
          [45.868593, 34.92046],
          [45.868634, 34.92027],
          [45.868467, 34.920169],
          [45.86818, 34.920169],
          [45.868049, 34.92008],
          [45.86799, 34.919936],
          [45.868041, 34.919744],
          [45.867919, 34.919663],
          [45.867795, 34.91953],
          [45.867591, 34.919351],
          [45.867437, 34.919084],
          [45.867365, 34.918759],
          [45.867417, 34.918506],
          [45.867522, 34.918211],
          [45.867468, 34.918047],
          [45.867326, 34.91789],
          [45.866998, 34.917668],
          [45.86711, 34.917554],
          [45.867291, 34.917404],
          [45.86749, 34.917303],
          [45.867572, 34.917119],
          [45.867396, 34.916917],
          [45.867288, 34.916631],
          [45.867116, 34.916489],
          [45.867137, 34.916307],
          [45.867261, 34.916004],
          [45.86735, 34.915758],
          [45.867416, 34.915512],
          [45.867627, 34.915216],
          [45.867716, 34.915083],
          [45.867802, 34.914847],
          [45.867875, 34.914651],
          [45.867932, 34.914346],
          [45.867859, 34.914022],
          [45.867826, 34.913587],
          [45.867826, 34.913371],
          [45.867893, 34.913064],
          [45.867919, 34.912792],
          [45.868007, 34.912458],
          [45.868139, 34.912181],
          [45.86815, 34.91157],
          [45.868043, 34.911355],
          [45.867939, 34.911125],
          [45.868029, 34.910865],
          [45.868199, 34.910516],
          [45.868373, 34.91034],
          [45.868543, 34.910257],
          [45.868766, 34.91025],
          [45.868942, 34.91034],
          [45.869071, 34.910317],
          [45.869098, 34.910223],
          [45.869061, 34.91008],
          [45.869113, 34.909977],
          [45.869117, 34.90984],
          [45.86921, 34.909613],
          [45.869061, 34.909316],
          [45.868912, 34.909179],
          [45.868573, 34.908959],
          [45.868371, 34.908742],
          [45.868203, 34.908331],
          [45.868186, 34.908067],
          [45.86819, 34.907757],
          [45.868217, 34.907579],
          [45.868193, 34.907288],
          [45.868296, 34.906881],
          [45.868416, 34.906559],
          [45.868481, 34.906224],
          [45.868529, 34.906037],
          [45.868506, 34.905882],
          [45.868419, 34.905654],
          [45.868278, 34.905459],
          [45.868049, 34.905228],
          [45.867757, 34.905145],
          [45.867474, 34.90514],
          [45.867325, 34.905129],
          [45.867107, 34.905112],
          [45.866795, 34.905191],
          [45.866507, 34.905102],
          [45.866227, 34.905064],
          [45.866031, 34.905179],
          [45.865809, 34.905256],
          [45.865554, 34.905274],
          [45.865244, 34.904945],
          [45.865021, 34.904713],
          [45.864969, 34.904141],
          [45.864768, 34.903606],
          [45.864607, 34.903372],
          [45.864462, 34.903094],
          [45.864771, 34.902743],
          [45.864935, 34.902442],
          [45.864923, 34.902219],
          [45.864971, 34.902056],
          [45.864945, 34.901979],
          [45.86484, 34.90193],
          [45.864688, 34.901809],
          [45.864589, 34.901736],
          [45.864355, 34.901656],
          [45.86407, 34.901617],
          [45.863993, 34.90154],
          [45.863909, 34.901375],
          [45.863809, 34.901258],
          [45.863771, 34.901134],
          [45.863738, 34.900987],
          [45.863889, 34.900871],
          [45.86395, 34.900711],
          [45.864024, 34.900514],
          [45.864176, 34.900334],
          [45.864247, 34.900083],
          [45.864226, 34.899898],
          [45.864192, 34.899747],
          [45.86419, 34.899459],
          [45.864528, 34.899355],
          [45.864817, 34.899299],
          [45.86491, 34.899175],
          [45.8649, 34.89901],
          [45.864973, 34.898853],
          [45.864983, 34.898739],
          [45.865056, 34.898497],
          [45.86499, 34.898401],
          [45.864778, 34.898182],
          [45.864914, 34.898005],
          [45.864826, 34.897782],
          [45.864766, 34.897622],
          [45.86475, 34.897193],
          [45.864856, 34.896949],
          [45.865005, 34.896712],
          [45.864933, 34.89659],
          [45.864986, 34.89649],
          [45.86504, 34.896328],
          [45.864775, 34.89619],
          [45.864273, 34.896045],
          [45.86406, 34.895952],
          [45.863895, 34.895783],
          [45.8636, 34.895836],
          [45.86314, 34.895611],
          [45.863051, 34.895469],
          [45.862945, 34.895308],
          [45.862699, 34.895028],
          [45.862559, 34.894931],
          [45.862317, 34.894794],
          [45.862128, 34.894836],
          [45.861829, 34.894831],
          [45.861401, 34.894942],
          [45.861193, 34.894934],
          [45.86092, 34.894919],
          [45.860592, 34.895107],
          [45.860271, 34.895254],
          [45.860137, 34.895462],
          [45.859806, 34.89566],
          [45.859775, 34.895863],
          [45.859632, 34.89632],
          [45.859258, 34.896265],
          [45.858961, 34.896119],
          [45.858721, 34.896031],
          [45.858419, 34.895864],
          [45.858122, 34.895778],
          [45.857791, 34.895849],
          [45.857576, 34.895833],
          [45.857387, 34.89579],
          [45.857084, 34.895556],
          [45.856848, 34.895377],
          [45.856576, 34.895367],
          [45.856213, 34.895291],
          [45.855849, 34.895666],
          [45.855456, 34.895843],
          [45.855128, 34.896072],
          [45.854907, 34.896147],
          [45.854652, 34.896197],
          [45.854371, 34.896111],
          [45.854085, 34.895892],
          [45.853766, 34.895905],
          [45.853462, 34.895847],
          [45.853159, 34.895745],
          [45.852859, 34.895803],
          [45.852562, 34.895801],
          [45.852283, 34.895635],
          [45.852027, 34.89564],
          [45.851785, 34.89557],
          [45.85155, 34.895418],
          [45.851197, 34.895381],
          [45.850948, 34.895196],
          [45.850851, 34.895132],
          [45.850632, 34.895018],
          [45.850322, 34.894951],
          [45.849851, 34.894576],
          [45.849629, 34.894516],
          [45.849484, 34.894766],
          [45.849276, 34.894911],
          [45.849179, 34.895234],
          [45.848988, 34.895528],
          [45.848607, 34.895686],
          [45.848235, 34.89599],
          [45.848102, 34.896198],
          [45.847757, 34.896515],
          [45.84757, 34.896651],
          [45.847363, 34.89671],
          [45.847047, 34.896771],
          [45.846819, 34.89686],
          [45.846522, 34.897034],
          [45.846328, 34.897253],
          [45.846047, 34.897464],
          [45.845953, 34.89767],
          [45.845648, 34.897944],
          [45.845423, 34.898217],
          [45.845104, 34.898265],
          [45.844845, 34.898283],
          [45.844282, 34.898252],
          [45.844035, 34.898161],
          [45.843842, 34.898146],
          [45.843609, 34.898236],
          [45.843458, 34.898421],
          [45.843208, 34.898667],
          [45.843007, 34.898685],
          [45.84284, 34.89853],
          [45.842637, 34.898431],
          [45.842562, 34.8983],
          [45.842436, 34.898192],
          [45.842206, 34.898102],
          [45.841865, 34.897852],
          [45.841619, 34.897679],
          [45.841358, 34.897667],
          [45.841166, 34.897782],
          [45.841088, 34.897985],
          [45.840897, 34.898149],
          [45.840683, 34.898312],
          [45.840358, 34.898401],
          [45.840295, 34.898682],
          [45.840213, 34.89876],
          [45.83999, 34.898911],
          [45.839594, 34.898864],
          [45.839412, 34.89887],
          [45.839136, 34.898734],
          [45.838925, 34.898713],
          [45.838693, 34.898637],
          [45.838437, 34.898646],
          [45.838116, 34.89877],
          [45.83789, 34.898824],
          [45.837599, 34.898785],
          [45.837238, 34.898759],
          [45.836768, 34.898648],
          [45.836503, 34.898664],
          [45.835915, 34.898646],
          [45.835821, 34.899027],
          [45.835591, 34.899301],
          [45.83523, 34.899406],
          [45.834935, 34.89948],
          [45.83458, 34.899457],
          [45.834382, 34.899596],
          [45.834246, 34.899795],
          [45.834166, 34.900012],
          [45.834017, 34.900398],
          [45.833928, 34.901034],
          [45.833961, 34.901456],
          [45.834181, 34.901812],
          [45.834466, 34.902239],
          [45.834506, 34.902409],
          [45.834577, 34.902637],
          [45.834481, 34.902686],
          [45.83424, 34.902616],
          [45.833962, 34.902602],
          [45.833737, 34.902619],
          [45.83355, 34.902796],
          [45.833388, 34.902987],
          [45.833149, 34.903119],
          [45.832981, 34.903238],
          [45.832899, 34.903446],
          [45.832767, 34.903663],
          [45.832543, 34.903873],
          [45.832126, 34.903898],
          [45.831813, 34.903926],
          [45.831648, 34.903712],
          [45.831468, 34.903443],
          [45.831363, 34.903245],
          [45.831178, 34.90313],
          [45.831005, 34.903232],
          [45.830942, 34.903468],
          [45.830841, 34.903841],
          [45.830637, 34.903999],
          [45.830382, 34.904138],
          [45.830065, 34.904257],
          [45.829912, 34.904221],
          [45.829753, 34.904011],
          [45.829549, 34.904015],
          [45.829437, 34.904197],
          [45.829285, 34.904336],
          [45.829175, 34.904482],
          [45.828931, 34.904489],
          [45.828663, 34.904365],
          [45.828507, 34.904357],
          [45.828289, 34.904269],
          [45.828036, 34.904264],
          [45.827884, 34.904188],
          [45.82772, 34.904086],
          [45.827634, 34.90398],
          [45.827469, 34.903766],
          [45.827318, 34.903694],
          [45.827129, 34.903714],
          [45.826999, 34.903827],
          [45.827011, 34.904198],
          [45.826884, 34.904495],
          [45.826919, 34.904646],
          [45.826846, 34.904911],
          [45.826717, 34.905136],
          [45.826596, 34.905306],
          [45.826543, 34.90545],
          [45.826353, 34.905663],
          [45.826089, 34.905894],
          [45.825993, 34.906181],
          [45.825763, 34.906586],
          [45.825705, 34.906839],
          [45.825655, 34.90719],
          [45.825625, 34.907532],
          [45.825606, 34.907783],
          [45.825466, 34.90801],
          [45.825117, 34.908184],
          [45.824994, 34.908408],
          [45.824894, 34.908633],
          [45.824756, 34.90872],
          [45.824532, 34.908737],
          [45.824312, 34.908812],
          [45.823982, 34.908861],
          [45.823753, 34.908798],
          [45.823522, 34.908681],
          [45.823312, 34.908732],
          [45.823127, 34.908855],
          [45.82312, 34.909085],
          [45.823148, 34.909278],
          [45.823087, 34.909586],
          [45.823113, 34.909815],
          [45.823051, 34.909947],
          [45.822814, 34.910174],
          [45.822554, 34.910444],
          [45.822408, 34.91065],
          [45.822139, 34.910841],
          [45.821821, 34.910938],
          [45.821298, 34.910949],
          [45.82053, 34.911247],
          [45.820041, 34.911428],
          [45.819456, 34.911509],
          [45.818866, 34.911658],
          [45.817818, 34.911933],
          [45.817497, 34.9118],
          [45.81728, 34.911587],
          [45.817074, 34.911308],
          [45.816825, 34.911186],
          [45.816417, 34.911074],
          [45.816311, 34.911191],
          [45.816178, 34.911489],
          [45.816073, 34.911674],
          [45.815904, 34.911812],
          [45.815771, 34.912024],
          [45.815539, 34.912074],
          [45.815274, 34.912134],
          [45.815042, 34.912229],
          [45.81478, 34.912276],
          [45.814457, 34.91218],
          [45.814152, 34.91213],
          [45.813775, 34.912174],
          [45.813579, 34.912065],
          [45.813316, 34.911913],
          [45.813121, 34.911651],
          [45.812889, 34.91149],
          [45.812668, 34.911389],
          [45.812305, 34.911422],
          [45.811929, 34.91134],
          [45.811629, 34.911029],
          [45.81091, 34.91067],
          [45.810575, 34.910765],
          [45.80984, 34.9108],
          [45.80929, 34.910884],
          [45.808836, 34.911306],
          [45.80857, 34.911447],
          [45.807792, 34.911405],
          [45.807338, 34.911458],
          [45.806984, 34.911377],
          [45.8067, 34.911274],
          [45.806171, 34.911286],
          [45.805595, 34.911379],
          [45.80513, 34.911439],
          [45.804433, 34.911679],
          [45.804029, 34.911925],
          [45.803524, 34.912113],
          [45.8032, 34.912121],
          [45.80241, 34.912139],
          [45.801946, 34.912028],
          [45.80151, 34.911875],
          [45.801162, 34.911878],
          [45.800666, 34.911903],
          [45.79988, 34.911831],
          [45.799234, 34.911684],
          [45.798639, 34.911555],
          [45.798202, 34.911524],
          [45.79802, 34.911871],
          [45.797698, 34.912211],
          [45.797286, 34.912405],
          [45.796719, 34.91238],
          [45.795592, 34.911796],
          [45.794816, 34.911699],
          [45.794449, 34.91176],
          [45.794171, 34.911854],
          [45.793938, 34.911859],
          [45.793662, 34.911791],
          [45.792912, 34.911703],
          [45.792188, 34.911795],
          [45.791625, 34.911916],
          [45.791121, 34.91183],
          [45.790552, 34.911688],
          [45.789945, 34.911709],
          [45.789475, 34.912008],
          [45.789303, 34.912178],
          [45.78882, 34.912322],
          [45.788431, 34.912296],
          [45.78826, 34.911646],
          [45.78897, 34.909701],
          [45.789568, 34.90846],
          [45.789792, 34.907679],
          [45.790035, 34.905994],
          [45.790483, 34.904446],
          [45.789904, 34.903803],
          [45.790558, 34.901306],
          [45.790782, 34.900229],
          [45.790917, 34.899747],
          [45.791163, 34.899595],
          [45.791116, 34.899073],
          [45.791187, 34.898737],
          [45.79142, 34.898408],
          [45.791758, 34.897871],
          [45.792088, 34.89739],
          [45.792171, 34.896926],
          [45.792099, 34.89657],
          [45.792365, 34.895804],
          [45.792545, 34.895016],
          [45.792871, 34.894347],
          [45.793134, 34.893572],
          [45.793346, 34.893337],
          [45.793453, 34.892877],
          [45.793634, 34.892567],
          [45.793733, 34.892252],
          [45.793871, 34.891176],
          [45.793934, 34.890724],
          [45.793985, 34.8904],
          [45.793866, 34.890079],
          [45.793715, 34.889683],
          [45.793922, 34.889341],
          [45.794191, 34.888718],
          [45.794466, 34.888246],
          [45.794439, 34.887712],
          [45.794364, 34.887257],
          [45.794199, 34.88672],
          [45.794395, 34.886038],
          [45.794791, 34.885851],
          [45.794951, 34.885589],
          [45.79513, 34.884541],
          [45.795419, 34.884292],
          [45.795581, 34.883867],
          [45.79588, 34.883617],
          [45.796109, 34.882668],
          [45.796045, 34.882167],
          [45.79629, 34.881539],
          [45.796383, 34.880983],
          [45.796195, 34.880449],
          [45.796261, 34.880006],
          [45.796396, 34.879181],
          [45.796531, 34.878654],
          [45.796701, 34.87839],
          [45.796982, 34.878026],
          [45.796949, 34.877555],
          [45.797067, 34.876923],
          [45.797431, 34.87657],
          [45.797512, 34.876142],
          [45.797723, 34.875646],
          [45.797964, 34.875172],
          [45.798022, 34.874658],
          [45.798038, 34.8739],
          [45.797845, 34.87326],
          [45.797971, 34.872914],
          [45.798204, 34.872414],
          [45.798052, 34.871712],
          [45.797807, 34.871135],
          [45.797894, 34.870858],
          [45.797775, 34.86998],
          [45.797893, 34.869303],
          [45.797871, 34.868398],
          [45.797825, 34.867795],
          [45.797816, 34.866771],
          [45.797735, 34.86612],
          [45.797716, 34.865188],
          [45.797622, 34.864791],
          [45.797656, 34.864227],
          [45.797426, 34.863791],
          [45.797644, 34.863366],
          [45.797939, 34.862234],
          [45.79774, 34.861874],
          [45.797186, 34.861207],
          [45.796913, 34.860913],
          [45.796751, 34.860088],
          [45.796651, 34.859278],
          [45.796356, 34.858943],
          [45.796315, 34.858357],
          [45.795935, 34.857615],
          [45.795986, 34.857291],
          [45.795752, 34.856882],
          [45.795173, 34.856211],
          [45.794597, 34.855809],
          [45.793831, 34.855162],
          [45.793461, 34.854805],
          [45.793041, 34.854384],
          [45.792569, 34.85364],
          [45.792074, 34.853026],
          [45.791532, 34.852537],
          [45.791054, 34.852073],
          [45.790518, 34.851646],
          [45.789429, 34.850777],
          [45.787894, 34.850382],
          [45.787209, 34.849999],
          [45.786707, 34.849575],
          [45.786506, 34.849206],
          [45.786083, 34.848687],
          [45.784851, 34.848401],
          [45.784427, 34.848224],
          [45.783909, 34.84774],
          [45.783184, 34.847463],
          [45.78281, 34.847089],
          [45.782425, 34.846329],
          [45.781915, 34.8457],
          [45.781299, 34.845017],
          [45.780314, 34.844282],
          [45.779676, 34.843559],
          [45.779056, 34.842733],
          [45.778639, 34.84224],
          [45.778273, 34.841981],
          [45.778091, 34.841338],
          [45.777718, 34.841008],
          [45.777125, 34.840241],
          [45.7769, 34.839912],
          [45.776809, 34.839136],
          [45.776367, 34.838809],
          [45.776185, 34.838122],
          [45.77595, 34.837885],
          [45.775825, 34.837457],
          [45.775507, 34.836992],
          [45.775255, 34.836686],
          [45.774805, 34.836198],
          [45.774364, 34.835746],
          [45.77384, 34.835667],
          [45.773193, 34.835557],
          [45.773108, 34.835149],
          [45.772402, 34.83495],
          [45.771779, 34.835015],
          [45.771355, 34.835269],
          [45.770807, 34.835413],
          [45.769886, 34.835575],
          [45.768689, 34.835513],
          [45.768116, 34.835161],
          [45.767934, 34.834768],
          [45.767866, 34.834554],
          [45.767678, 34.834313],
          [45.767025, 34.834098],
          [45.764366, 34.832868],
          [45.762188, 34.832648],
          [45.760078, 34.832209],
          [45.75886, 34.832065],
          [45.758165, 34.832083],
          [45.75756, 34.832049],
          [45.757145, 34.831423],
          [45.756527, 34.831032],
          [45.756108, 34.830653],
          [45.755812, 34.830309],
          [45.755617, 34.830089],
          [45.755236, 34.829505],
          [45.754962, 34.829204],
          [45.754581, 34.829016],
          [45.753623, 34.828685],
          [45.752932, 34.82876],
          [45.752077, 34.828825],
          [45.751136, 34.828906],
          [45.750382, 34.828964],
          [45.749524, 34.828794],
          [45.748259, 34.828769],
          [45.747247, 34.828956],
          [45.747244, 34.828957],
          [45.736536, 34.831227],
          [45.736536, 34.831228],
          [45.736322, 34.831555],
          [45.736163, 34.831914],
          [45.736097, 34.832584],
          [45.735962, 34.833656],
          [45.735947, 34.834043],
          [45.736095, 34.834474],
          [45.73619, 34.835009],
          [45.736197, 34.835783],
          [45.736078, 34.836587],
          [45.735993, 34.837153],
          [45.735908, 34.837749],
          [45.736307, 34.838703],
          [45.736452, 34.839605],
          [45.736632, 34.840591],
          [45.736914, 34.841491],
          [45.737233, 34.842843],
          [45.73738, 34.843998],
          [45.73735, 34.844422],
          [45.737353, 34.844788],
          [45.73739, 34.845098],
          [45.737053, 34.845608],
          [45.736852, 34.846089],
          [45.73689, 34.846417],
          [45.736542, 34.845895],
          [45.736132, 34.845427],
          [45.735976, 34.844796],
          [45.735975, 34.844162],
          [45.736054, 34.84342],
          [45.735884, 34.842814],
          [45.735771, 34.841935],
          [45.735827, 34.841477],
          [45.735699, 34.840647],
          [45.735665, 34.840239],
          [45.735533, 34.839966],
          [45.735386, 34.839823],
          [45.735044, 34.83966],
          [45.734668, 34.839625],
          [45.734367, 34.839503],
          [45.733885, 34.839419],
          [45.733643, 34.83926],
          [45.733508, 34.839273],
          [45.733312, 34.839225],
          [45.733206, 34.839077],
          [45.732679, 34.838956],
          [45.732348, 34.838908],
          [45.731628, 34.839024],
          [45.731146, 34.838965],
          [45.731085, 34.838879],
          [45.730663, 34.838745],
          [45.730497, 34.838622],
          [45.730226, 34.838599],
          [45.729881, 34.838576],
          [45.72955, 34.838578],
          [45.729401, 34.83874],
          [45.729475, 34.838905],
          [45.729447, 34.839048],
          [45.729275, 34.839192],
          [45.729015, 34.839241],
          [45.728783, 34.839171],
          [45.728523, 34.839268],
          [45.728351, 34.83946],
          [45.728092, 34.839557],
          [45.727773, 34.839511],
          [45.727163, 34.839252],
          [45.727045, 34.838991],
          [45.727043, 34.838728],
          [45.726925, 34.838514],
          [45.72649, 34.838397],
          [45.725996, 34.838138],
          [45.72556, 34.83795],
          [45.724953, 34.837953],
          [45.724635, 34.838075],
          [45.724403, 34.838004],
          [45.724171, 34.83791],
          [45.723939, 34.837864],
          [45.723853, 34.837936],
          [45.723621, 34.837961],
          [45.723387, 34.837653],
          [45.723154, 34.837511],
          [45.722895, 34.837584],
          [45.722546, 34.837419],
          [45.722373, 34.837515],
          [45.722521, 34.837849],
          [45.722369, 34.838006],
          [45.714734, 34.830856],
          [45.71363, 34.83029],
          [45.712986, 34.829483],
          [45.711677, 34.828631],
          [45.703344, 34.825292],
          [45.699191, 34.824222],
          [45.698577, 34.823511],
          [45.696957, 34.822559],
          [45.696912, 34.822065],
          [45.696639, 34.821208],
          [45.695421, 34.820544],
          [45.69479, 34.819794],
          [45.694063, 34.819115],
          [45.693804, 34.818615],
          [45.693469, 34.817885],
          [45.693146, 34.817502],
          [45.69281, 34.81706],
          [45.69224, 34.816237],
          [45.691683, 34.815564],
          [45.691121, 34.81551],
          [45.690435, 34.815604],
          [45.689876, 34.815711],
          [45.689538, 34.815161],
          [45.689365, 34.8146],
          [45.689303, 34.813786],
          [45.689137, 34.812911],
          [45.689001, 34.812262],
          [45.689008, 34.81175],
          [45.689184, 34.811442],
          [45.689656, 34.811099],
          [45.690312, 34.810823],
          [45.690749, 34.810719],
          [45.691018, 34.810573],
          [45.691153, 34.810128],
          [45.691304, 34.809511],
          [45.691628, 34.808961],
          [45.691734, 34.808539],
          [45.691802, 34.808151],
          [45.691943, 34.807732],
          [45.691845, 34.807453],
          [45.691862, 34.807083],
          [45.691996, 34.806683],
          [45.691952, 34.806243],
          [45.692038, 34.805833],
          [45.691944, 34.805124],
          [45.691832, 34.804633],
          [45.691781, 34.804212],
          [45.691656, 34.803615],
          [45.69174, 34.803144],
          [45.691762, 34.802647],
          [45.692019, 34.802297],
          [45.692463, 34.801933],
          [45.692613, 34.801557],
          [45.692777, 34.801044],
          [45.693018, 34.800724],
          [45.693203, 34.80045],
          [45.693419, 34.800018],
          [45.69352, 34.799624],
          [45.693676, 34.799372],
          [45.694123, 34.79907],
          [45.694448, 34.799201],
          [45.694839, 34.79967],
          [45.69508, 34.799995],
          [45.695803, 34.800023],
          [45.696475, 34.800068],
          [45.697062, 34.800042],
          [45.69733, 34.799483],
          [45.697676, 34.798961],
          [45.698116, 34.798671],
          [45.698523, 34.798387],
          [45.698546, 34.797913],
          [45.69838, 34.797532],
          [45.698493, 34.797103],
          [45.698755, 34.796733],
          [45.699069, 34.796089],
          [45.699537, 34.795521],
          [45.699827, 34.795086],
          [45.700136, 34.794767],
          [45.700642, 34.794431],
          [45.700753, 34.794079],
          [45.700951, 34.793507],
          [45.701006, 34.792942],
          [45.701166, 34.792393],
          [45.70164, 34.791892],
          [45.701977, 34.791509],
          [45.702165, 34.791024],
          [45.703093, 34.790503],
          [45.703631, 34.790119],
          [45.70402, 34.789633],
          [45.703809, 34.789209],
          [45.703547, 34.788759],
          [45.702956, 34.788163],
          [45.702115, 34.7879],
          [45.701418, 34.787629],
          [45.700942, 34.787302],
          [45.700551, 34.786961],
          [45.700275, 34.786411],
          [45.700096, 34.786024],
          [45.699862, 34.785596],
          [45.699414, 34.785554],
          [45.699012, 34.785513],
          [45.698162, 34.784996],
          [45.697499, 34.7846],
          [45.697184, 34.784032],
          [45.696918, 34.783651],
          [45.696518, 34.783576],
          [45.695982, 34.783669],
          [45.695567, 34.783751],
          [45.695212, 34.783505],
          [45.69486, 34.783225],
          [45.6944, 34.783092],
          [45.693756, 34.782897],
          [45.693283, 34.782843],
          [45.692806, 34.782773],
          [45.692869, 34.78254],
          [45.693362, 34.78224],
          [45.693793, 34.781918],
          [45.694124, 34.781424],
          [45.694459, 34.780862],
          [45.694833, 34.780361],
          [45.69532, 34.779952],
          [45.695938, 34.779358],
          [45.69647, 34.779034],
          [45.696879, 34.778672],
          [45.697267, 34.777965],
          [45.697618, 34.776904],
          [45.69791, 34.775957],
          [45.698113, 34.775316],
          [45.698772, 34.772848],
          [45.69876, 34.77002],
          [45.69774, 34.768675],
          [45.695866, 34.769858],
          [45.695024, 34.770697],
          [45.691336, 34.770762],
          [45.690301, 34.770311],
          [45.689902, 34.769203],
          [45.689245, 34.768353],
          [45.68831, 34.767723],
          [45.685778, 34.766807],
          [45.684417, 34.766089],
          [45.681982, 34.764773],
          [45.680427, 34.763909],
          [45.678303, 34.762538],
          [45.678033, 34.761672],
          [45.677687, 34.759991],
          [45.677092, 34.757696],
          [45.676851, 34.756552],
          [45.676161, 34.755921],
          [45.675925, 34.755399],
          [45.676349, 34.754616],
          [45.676562, 34.75393],
          [45.675838, 34.752563],
          [45.675393, 34.751667],
          [45.675072, 34.750127],
          [45.674665, 34.748166],
          [45.674281, 34.747508],
          [45.673629, 34.747067],
          [45.673355, 34.74644],
          [45.673206, 34.745305],
          [45.672843, 34.743516],
          [45.672406, 34.741962],
          [45.671867, 34.741091],
          [45.671586, 34.739792],
          [45.671526, 34.738266],
          [45.671174, 34.737209],
          [45.670295, 34.736842],
          [45.668881, 34.73644],
          [45.667636, 34.735436],
          [45.666274, 34.734326],
          [45.665828, 34.733166],
          [45.665262, 34.731762],
          [45.664527, 34.730482],
          [45.663802, 34.729541],
          [45.6629, 34.728828],
          [45.662093, 34.728474],
          [45.661453, 34.728338],
          [45.660325, 34.728219],
          [45.659447, 34.727895],
          [45.658598, 34.727428],
          [45.657437, 34.727136],
          [45.656871, 34.726594],
          [45.656191, 34.725655],
          [45.655791, 34.725366],
          [45.654705, 34.72489],
          [45.653868, 34.724208],
          [45.653259, 34.72311],
          [45.654146, 34.722776],
          [45.654645, 34.722629],
          [45.655151, 34.722464],
          [45.655282, 34.72198],
          [45.655153, 34.721568],
          [45.655334, 34.721015],
          [45.655827, 34.720801],
          [45.656375, 34.720757],
          [45.656868, 34.720713],
          [45.657091, 34.720538],
          [45.657058, 34.719937],
          [45.657217, 34.719559],
          [45.657298, 34.719136],
          [45.657549, 34.718853],
          [45.657854, 34.718432],
          [45.658188, 34.718255],
          [45.658837, 34.718295],
          [45.659606, 34.718109],
          [45.660235, 34.718205],
          [45.660637, 34.71816],
          [45.661057, 34.717967],
          [45.661401, 34.717352],
          [45.661839, 34.717096],
          [45.662498, 34.717007],
          [45.662636, 34.716675],
          [45.662769, 34.716413],
          [45.662987, 34.715786],
          [45.663343, 34.715476],
          [45.663753, 34.715371],
          [45.664375, 34.715484],
          [45.665058, 34.71557],
          [45.665685, 34.716049],
          [45.666548, 34.715924],
          [45.667083, 34.715139],
          [45.667854, 34.71427],
          [45.668478, 34.7137],
          [45.669367, 34.713417],
          [45.66944, 34.712747],
          [45.669947, 34.712027],
          [45.670793, 34.711836],
          [45.671307, 34.711661],
          [45.671838, 34.711423],
          [45.672028, 34.710861],
          [45.67213, 34.710476],
          [45.672655, 34.710128],
          [45.672926, 34.709663],
          [45.673656, 34.709373],
          [45.674287, 34.708955],
          [45.674833, 34.70886],
          [45.675607, 34.708998],
          [45.675803, 34.709408],
          [45.676198, 34.709723],
          [45.677188, 34.710002],
          [45.677799, 34.709853],
          [45.678304, 34.709688],
          [45.67875, 34.70955],
          [45.679617, 34.709613],
          [45.679932, 34.709361],
          [45.680156, 34.709015],
          [45.680721, 34.708822],
          [45.681224, 34.708649],
          [45.681958, 34.708973],
          [45.682645, 34.70899],
          [45.682896, 34.708792],
          [45.683555, 34.708532],
          [45.684389, 34.708122],
          [45.684589, 34.707771],
          [45.684834, 34.707464],
          [45.685032, 34.70719],
          [45.686132, 34.707032],
          [45.68694, 34.706567],
          [45.687437, 34.706369],
          [45.68783, 34.706112],
          [45.688254, 34.706021],
          [45.688755, 34.706096],
          [45.689566, 34.705903],
          [45.690547, 34.705544],
          [45.691145, 34.70526],
          [45.691345, 34.704867],
          [45.691443, 34.704466],
          [45.691658, 34.704087],
          [45.691524, 34.703529],
          [45.691449, 34.702894],
          [45.691341, 34.70252],
          [45.691186, 34.701967],
          [45.691038, 34.701566],
          [45.690908, 34.700982],
          [45.690961, 34.700495],
          [45.69136, 34.700178],
          [45.691988, 34.699923],
          [45.69234, 34.699469],
          [45.692755, 34.698824],
          [45.692939, 34.698195],
          [45.693366, 34.697471],
          [45.694079, 34.696937],
          [45.694704, 34.696589],
          [45.695595, 34.696399],
          [45.696099, 34.696354],
          [45.696776, 34.696466],
          [45.697603, 34.696501],
          [45.698497, 34.696456],
          [45.698971, 34.696339],
          [45.699793, 34.696101],
          [45.70106, 34.695462],
          [45.702218, 34.694534],
          [45.702625, 34.693772],
          [45.703342, 34.691359],
          [45.702681, 34.690982],
          [45.702295, 34.690662],
          [45.701814, 34.690226],
          [45.701489, 34.689461],
          [45.701194, 34.688903],
          [45.700996, 34.688337],
          [45.700838, 34.687932],
          [45.700365, 34.687229],
          [45.700148, 34.686462],
          [45.700048, 34.68586],
          [45.699849, 34.68563],
          [45.699767, 34.68514],
          [45.69954, 34.684508],
          [45.699428, 34.684068],
          [45.699228, 34.683537],
          [45.699187, 34.683208],
          [45.698923, 34.682388],
          [45.698528, 34.682114],
          [45.698521, 34.681363],
          [45.698057, 34.680994],
          [45.698078, 34.680566],
          [45.697815, 34.679958],
          [45.697757, 34.679561],
          [45.697828, 34.679203],
          [45.697744, 34.678828],
          [45.697953, 34.678498],
          [45.698043, 34.678172],
          [45.698037, 34.677766],
          [45.6978, 34.677561],
          [45.697681, 34.677263],
          [45.697585, 34.676758],
          [45.697584, 34.676201],
          [45.697524, 34.675839],
          [45.697482, 34.675289],
          [45.697377, 34.67475],
          [45.697089, 34.674049],
          [45.696995, 34.673721],
          [45.69687, 34.673318],
          [45.696825, 34.67276],
          [45.696785, 34.672218],
          [45.696747, 34.671773],
          [45.696864, 34.671123],
          [45.69697, 34.670219],
          [45.696837, 34.66957],
          [45.697333, 34.66964],
          [45.697995, 34.669635],
          [45.698562, 34.669604],
          [45.699233, 34.669509],
          [45.699477, 34.669527],
          [45.699865, 34.669643],
          [45.700388, 34.669647],
          [45.700777, 34.669514],
          [45.701193, 34.669183],
          [45.701364, 34.668877],
          [45.701741, 34.668694],
          [45.702141, 34.668684],
          [45.702534, 34.668648],
          [45.703127, 34.668462],
          [45.703681, 34.668335],
          [45.704238, 34.668305],
          [45.704504, 34.668408],
          [45.704881, 34.668694],
          [45.705307, 34.668617],
          [45.705682, 34.668638],
          [45.70592, 34.668719],
          [45.706271, 34.668647],
          [45.706636, 34.668634],
          [45.707037, 34.668713],
          [45.707669, 34.669059],
          [45.708233, 34.669188],
          [45.708979, 34.669301],
          [45.709583, 34.669281],
          [45.71035, 34.669266],
          [45.711139, 34.66908],
          [45.711053, 34.668528],
          [45.711076, 34.668064],
          [45.711102, 34.667103],
          [45.710918, 34.666596],
          [45.710733, 34.666124],
          [45.710717, 34.665808],
          [45.710574, 34.665418],
          [45.710461, 34.664845],
          [45.710393, 34.664238],
          [45.710366, 34.663578],
          [45.710591, 34.663183],
          [45.710472, 34.662293],
          [45.710672, 34.661584],
          [45.710993, 34.661012],
          [45.711239, 34.660233],
          [45.711446, 34.659682],
          [45.712102, 34.658846],
          [45.712412, 34.658188],
          [45.712436, 34.657555],
          [45.712801, 34.656772],
          [45.713123, 34.656076],
          [45.713548, 34.655273],
          [45.713571, 34.654853],
          [45.713331, 34.654294],
          [45.713578, 34.653134],
          [45.713816, 34.652577],
          [45.714271, 34.65176],
          [45.71464, 34.651038],
          [45.714959, 34.650201],
          [45.715205, 34.649378],
          [45.716127, 34.647332],
          [45.717246, 34.645306],
          [45.718632, 34.643284],
          [45.718687, 34.642841],
          [45.718608, 34.642574],
          [45.718646, 34.642257],
          [45.719248, 34.641334],
          [45.71932, 34.641064],
          [45.719621, 34.640797],
          [45.719765, 34.640426],
          [45.719765, 34.639531],
          [45.719676, 34.639052],
          [45.720123, 34.638803],
          [45.72168, 34.638478],
          [45.722263, 34.638249],
          [45.72288, 34.637517],
          [45.723417, 34.637155],
          [45.723787, 34.636132],
          [45.724602, 34.635666],
          [45.724757, 34.635337],
          [45.724888, 34.632612],
          [45.724728, 34.632198],
          [45.724117, 34.631636],
          [45.72422, 34.631316],
          [45.724633, 34.630976],
          [45.725073, 34.630701],
          [45.72551, 34.630244],
          [45.725931, 34.629803],
          [45.726163, 34.629265],
          [45.726781, 34.628153],
          [45.72705, 34.626696],
          [45.726993, 34.625963],
          [45.727455, 34.625472],
          [45.728421, 34.624235],
          [45.728503, 34.62345],
          [45.728847, 34.62237],
          [45.729365, 34.621674],
          [45.729534, 34.620678],
          [45.729822, 34.619971],
          [45.729958, 34.619394],
          [45.729473, 34.618314],
          [45.729505, 34.616524],
          [45.729013, 34.615841],
          [45.727553, 34.615052],
          [45.726839, 34.614954],
          [45.725611, 34.614557],
          [45.724874, 34.6145],
          [45.724378, 34.614229],
          [45.724237, 34.613933],
          [45.72442, 34.613624],
          [45.725077, 34.613076],
          [45.725569, 34.612481],
          [45.726385, 34.611741],
          [45.726283, 34.611303],
          [45.726232, 34.610722],
          [45.725613, 34.610569],
          [45.72526, 34.610258],
          [45.724917, 34.609519],
          [45.724569, 34.608931],
          [45.724046, 34.608425],
          [45.722933, 34.607501],
          [45.723439, 34.607255],
          [45.72375, 34.606976],
          [45.724224, 34.606184],
          [45.724371, 34.60565],
          [45.724896, 34.605013],
          [45.725157, 34.604369],
          [45.725156, 34.603981],
          [45.725707, 34.603406],
          [45.726163, 34.603095],
          [45.726719, 34.601981],
          [45.726998, 34.601664],
          [45.726972, 34.601011],
          [45.727308, 34.60019],
          [45.728016, 34.599461],
          [45.728629, 34.598569],
          [45.729219, 34.598226],
          [45.729454, 34.597909],
          [45.729324, 34.597528],
          [45.729077, 34.595974],
          [45.729285, 34.595549],
          [45.729757, 34.594876],
          [45.729984, 34.594186],
          [45.731064, 34.591965],
          [45.73129, 34.591395],
          [45.731724, 34.590707],
          [45.731843, 34.590148],
          [45.732437, 34.590118],
          [45.733202, 34.589947],
          [45.733825, 34.589733],
          [45.734593, 34.589575],
          [45.73573, 34.589659],
          [45.736833, 34.589824],
          [45.737044, 34.589667],
          [45.737208, 34.589369],
          [45.737188, 34.589037],
          [45.737556, 34.588472],
          [45.737817, 34.588209],
          [45.737788, 34.587968],
          [45.738119, 34.587641],
          [45.739151, 34.586847],
          [45.739377, 34.586621],
          [45.739509, 34.586202],
          [45.739826, 34.585818],
          [45.739942, 34.585334],
          [45.739882, 34.584971],
          [45.739723, 34.584738],
          [45.739642, 34.584334],
          [45.739832, 34.584099],
          [45.739963, 34.583673],
          [45.740056, 34.582798],
          [45.740061, 34.582565],
          [45.739979, 34.582244],
          [45.739806, 34.581871],
          [45.73987, 34.580926],
          [45.740291, 34.58027],
          [45.740793, 34.57933],
          [45.740226, 34.577974],
          [45.7394, 34.577312],
          [45.739147, 34.57667],
          [45.739144, 34.576274],
          [45.739346, 34.574932],
          [45.739507, 34.574411],
          [45.740124, 34.573324],
          [45.741249, 34.570811],
          [45.740924, 34.570398],
          [45.740407, 34.570257],
          [45.740048, 34.570007],
          [45.73999, 34.569487],
          [45.740377, 34.567799],
          [45.739996, 34.566402],
          [45.739501, 34.566093],
          [45.739036, 34.565689],
          [45.738243, 34.565112],
          [45.736843, 34.56426],
          [45.736266, 34.563585],
          [45.736351, 34.563324],
          [45.736192, 34.562747],
          [45.736108, 34.562037],
          [45.735494, 34.561391],
          [45.735019, 34.560735],
          [45.735042, 34.559588],
          [45.734617, 34.558319],
          [45.734671, 34.558018],
          [45.734969, 34.557563],
          [45.735844, 34.556542],
          [45.735993, 34.556061],
          [45.73596, 34.555633],
          [45.736011, 34.555281],
          [45.736268, 34.55484],
          [45.736015, 34.554237],
          [45.73659, 34.554136],
          [45.738862, 34.554027],
          [45.739382, 34.554092],
          [45.740059, 34.5541],
          [45.74036, 34.553969],
          [45.740512, 34.553649],
          [45.740614, 34.553231],
          [45.740519, 34.553017],
          [45.740595, 34.552761],
          [45.741119, 34.55237],
          [45.741461, 34.552294],
          [45.741868, 34.552051],
          [45.742121, 34.551743],
          [45.742445, 34.551486],
          [45.742489, 34.551313],
          [45.742464, 34.551098],
          [45.742634, 34.550863],
          [45.74268, 34.550515],
          [45.742787, 34.550263],
          [45.742757, 34.550074],
          [45.742896, 34.549835],
          [45.743302, 34.54954],
          [45.74335, 34.549297],
          [45.743222, 34.549061],
          [45.742935, 34.548875],
          [45.7428, 34.548658],
          [45.742844, 34.548485],
          [45.743103, 34.548062],
          [45.743131, 34.547243],
          [45.742629, 34.546641],
          [45.742579, 34.546403],
          [45.742778, 34.545735],
          [45.742861, 34.544943],
          [45.742732, 34.544751],
          [45.742217, 34.544423],
          [45.742171, 34.544298],
          [45.741914, 34.544064],
          [45.741357, 34.544013],
          [45.741306, 34.543862],
          [45.741336, 34.54363],
          [45.741236, 34.543531],
          [45.74094, 34.543398],
          [45.740951, 34.54316],
          [45.741114, 34.542944],
          [45.740988, 34.542769],
          [45.741039, 34.541912],
          [45.741226, 34.541762],
          [45.741096, 34.541422],
          [45.741291, 34.541219],
          [45.741726, 34.541016],
          [45.741813, 34.540478],
          [45.741955, 34.540256],
          [45.742248, 34.539994],
          [45.742191, 34.539766],
          [45.742223, 34.53949],
          [45.742294, 34.539391],
          [45.742598, 34.539321],
          [45.742782, 34.539076],
          [45.742895, 34.538849],
          [45.742839, 34.53854],
          [45.742818, 34.538178],
          [45.743204, 34.538035],
          [45.742676, 34.537769],
          [45.742296, 34.537442],
          [45.741457, 34.536784],
          [45.74081, 34.536521],
          [45.73972, 34.53665],
          [45.739239, 34.537],
          [45.738691, 34.537215],
          [45.737775, 34.537328],
          [45.736963, 34.538043],
          [45.736556, 34.538264],
          [45.735346, 34.538305],
          [45.734316, 34.538325],
          [45.733364, 34.53868],
          [45.73282, 34.538709],
          [45.732307, 34.538526],
          [45.732107, 34.538174],
          [45.732386, 34.536845],
          [45.732294, 34.536525],
          [45.732028, 34.536218],
          [45.731419, 34.53599],
          [45.730986, 34.536032],
          [45.730429, 34.536576],
          [45.72983, 34.536967],
          [45.728666, 34.537436],
          [45.727744, 34.537584],
          [45.72724, 34.537778],
          [45.726677, 34.537897],
          [45.725542, 34.537632],
          [45.725179, 34.537194],
          [45.724383, 34.537125],
          [45.724221, 34.537224],
          [45.724305, 34.53755],
          [45.723973, 34.538525],
          [45.723669, 34.538628],
          [45.723435, 34.538493],
          [45.723144, 34.538493],
          [45.722847, 34.538583],
          [45.722437, 34.539211],
          [45.721906, 34.539566],
          [45.721403, 34.539766],
          [45.721125, 34.539588],
          [45.721041, 34.539067],
          [45.720683, 34.538333],
          [45.720177, 34.537923],
          [45.719127, 34.537963],
          [45.717977, 34.537863],
          [45.717439, 34.537986],
          [45.717348, 34.538206],
          [45.717652, 34.538604],
          [45.717817, 34.539012],
          [45.718062, 34.53949],
          [45.717942, 34.539922],
          [45.717225, 34.540155],
          [45.716408, 34.539973],
          [45.715427, 34.540234],
          [45.714149, 34.540962],
          [45.71389, 34.540987],
          [45.713054, 34.540432],
          [45.712749, 34.540489],
          [45.712347, 34.540833],
          [45.712279, 34.541186],
          [45.712404, 34.541647],
          [45.712913, 34.542229],
          [45.712931, 34.54264],
          [45.71268, 34.542871],
          [45.711204, 34.542919],
          [45.71081, 34.542823],
          [45.710505, 34.542464],
          [45.710165, 34.542341],
          [45.709507, 34.54266],
          [45.709017, 34.543532],
          [45.709009, 34.543889],
          [45.70924, 34.544142],
          [45.709992, 34.544084],
          [45.710668, 34.544183],
          [45.711307, 34.544709],
          [45.711589, 34.545419],
          [45.711437, 34.545801],
          [45.711099, 34.545834],
          [45.710571, 34.545687],
          [45.709679, 34.545818],
          [45.708863, 34.545991],
          [45.707879, 34.545555],
          [45.707213, 34.545461],
          [45.706583, 34.545875],
          [45.706169, 34.546105],
          [45.705707, 34.546171],
          [45.705121, 34.545933],
          [45.704722, 34.54592],
          [45.70437, 34.545781],
          [45.704146, 34.545906],
          [45.703944, 34.546379],
          [45.703857, 34.54689],
          [45.703972, 34.54766],
          [45.703625, 34.548561],
          [45.702267, 34.548764],
          [45.700442, 34.547505],
          [45.698624, 34.548783],
          [45.699807, 34.550081],
          [45.699686, 34.551058],
          [45.698138, 34.551093],
          [45.696378, 34.55073],
          [45.695761, 34.551608],
          [45.695244, 34.553073],
          [45.693697, 34.55364],
          [45.693031, 34.555304],
          [45.691926, 34.555621],
          [45.690706, 34.555469],
          [45.690547, 34.554889],
          [45.690065, 34.554071],
          [45.689222, 34.554491],
          [45.688314, 34.555307],
          [45.687576, 34.557767],
          [45.686767, 34.55761],
          [45.685594, 34.55699],
          [45.685189, 34.555307],
          [45.684087, 34.555404],
          [45.683718, 34.555919],
          [45.684416, 34.557639],
          [45.684288, 34.558961],
          [45.683472, 34.559577],
          [45.682351, 34.559612],
          [45.68151, 34.558057],
          [45.680556, 34.555787],
          [45.679448, 34.555568],
          [45.677421, 34.556657],
          [45.676186, 34.556768],
          [45.675665, 34.556254],
          [45.674872, 34.555558],
          [45.674034, 34.554387],
          [45.67423, 34.55368],
          [45.673618, 34.552991],
          [45.670567, 34.552056],
          [45.66806, 34.553944],
          [45.666198, 34.55374],
          [45.66532, 34.552709],
          [45.665688, 34.551596],
          [45.664375, 34.550322],
          [45.66361, 34.551303],
          [45.66226, 34.552221],
          [45.660921, 34.554262],
          [45.659705, 34.554753],
          [45.657542, 34.554474],
          [45.65582, 34.555609],
          [45.654655, 34.555167],
          [45.655098, 34.553539],
          [45.653526, 34.553273],
          [45.65247, 34.554652],
          [45.652774, 34.555709],
          [45.651947, 34.556201],
          [45.650934, 34.556162],
          [45.650907, 34.555349],
          [45.650466, 34.554768],
          [45.65123, 34.553622],
          [45.651056, 34.552765],
          [45.650118, 34.553143],
          [45.64866, 34.553124],
          [45.648637, 34.552302],
          [45.647851, 34.550882],
          [45.645982, 34.551491],
          [45.645407, 34.552212],
          [45.642057, 34.553334],
          [45.641093, 34.553548],
          [45.640531, 34.553835],
          [45.641784, 34.556231],
          [45.640689, 34.558756],
          [45.639894, 34.558913],
          [45.639254, 34.557915],
          [45.639583, 34.557025],
          [45.638213, 34.556533],
          [45.636587, 34.556185],
          [45.635737, 34.556436],
          [45.635273, 34.557781],
          [45.633713, 34.559011],
          [45.632703, 34.558368],
          [45.632086, 34.556748],
          [45.632671, 34.554285],
          [45.632247, 34.552052],
          [45.631159, 34.551173],
          [45.629591, 34.550711],
          [45.629249, 34.549797],
          [45.627689, 34.548803],
          [45.626205, 34.549306],
          [45.626222, 34.550584],
          [45.625484, 34.553187],
          [45.62465, 34.55338],
          [45.622797, 34.550732],
          [45.618424, 34.550256],
          [45.616241, 34.551705],
          [45.615256, 34.551932],
          [45.61467, 34.55141],
          [45.613236, 34.551059],
          [45.609356, 34.550907],
          [45.607493, 34.548606],
          [45.600094, 34.551812],
          [45.597661, 34.549553],
          [45.594687, 34.549889],
          [45.594228, 34.552808],
          [45.595385, 34.553685],
          [45.594959, 34.554733],
          [45.594101, 34.555419],
          [45.59377, 34.555489],
          [45.593017, 34.555647],
          [45.592114, 34.555611],
          [45.591535, 34.555595],
          [45.591131, 34.555603],
          [45.590776, 34.555649],
          [45.590625, 34.555699],
          [45.59048, 34.555802],
          [45.589697, 34.556578],
          [45.589558, 34.557337],
          [45.589005, 34.557788],
          [45.588762, 34.558726],
          [45.587875, 34.558634],
          [45.587737, 34.558401],
          [45.584783, 34.556811],
          [45.581821, 34.557792],
          [45.581679, 34.559174],
          [45.580482, 34.559669],
          [45.579079, 34.557728],
          [45.577143, 34.55875],
          [45.57634, 34.561199],
          [45.57447, 34.562121],
          [45.575264, 34.565394],
          [45.57356, 34.56574],
          [45.571805, 34.562885],
          [45.565839, 34.565882],
          [45.566517, 34.570218],
          [45.565501, 34.57335],
          [45.562616, 34.57461],
          [45.559745, 34.573843],
          [45.558158, 34.576214],
          [45.560419, 34.579384],
          [45.555801, 34.579963],
          [45.55454, 34.582384],
          [45.558711, 34.583398],
          [45.553948, 34.588736],
          [45.556913, 34.59016],
          [45.556213, 34.592446],
          [45.553978, 34.591686],
          [45.551364, 34.593442],
          [45.546778, 34.594002],
          [45.543243, 34.596624],
          [45.540385, 34.594953],
          [45.537265, 34.592148],
          [45.53381, 34.593787],
          [45.534711, 34.596518],
          [45.537162, 34.598429],
          [45.537527, 34.600549],
          [45.535282, 34.602312],
          [45.531463, 34.601898],
          [45.531175, 34.60147],
          [45.530931, 34.601323],
          [45.530469, 34.601127],
          [45.530239, 34.600863],
          [45.52989, 34.600833],
          [45.529725, 34.600486],
          [45.529817, 34.600299],
          [45.529646, 34.600158],
          [45.529644, 34.600086],
          [45.52957, 34.600012],
          [45.529269, 34.600019],
          [45.529074, 34.599875],
          [45.529041, 34.599702],
          [45.529097, 34.599559],
          [45.528963, 34.599336],
          [45.529045, 34.599253],
          [45.52904, 34.59917],
          [45.528934, 34.598946],
          [45.528836, 34.598902],
          [45.528922, 34.598667],
          [45.528872, 34.5986],
          [45.528666, 34.59856],
          [45.528643, 34.598446],
          [45.528675, 34.598341],
          [45.529194, 34.59825],
          [45.529139, 34.598161],
          [45.529166, 34.5981],
          [45.529315, 34.598089],
          [45.52956, 34.598175],
          [45.529833, 34.598077],
          [45.529972, 34.59784],
          [45.530131, 34.597763],
          [45.53076, 34.597022],
          [45.530463, 34.596584],
          [45.530611, 34.595935],
          [45.530473, 34.59578],
          [45.53041, 34.595404],
          [45.530426, 34.595321],
          [45.530711, 34.595355],
          [45.530791, 34.595329],
          [45.530752, 34.595028],
          [45.530821, 34.594895],
          [45.530979, 34.594795],
          [45.530893, 34.59465],
          [45.530558, 34.594629],
          [45.530537, 34.594485],
          [45.530546, 34.594248],
          [45.530667, 34.593957],
          [45.530782, 34.593857],
          [45.530896, 34.593837],
          [45.530943, 34.593767],
          [45.530653, 34.593439],
          [45.530636, 34.593289],
          [45.530898, 34.593042],
          [45.530882, 34.592958],
          [45.530689, 34.59282],
          [45.530676, 34.592705],
          [45.531059, 34.592402],
          [45.531026, 34.592146],
          [45.531062, 34.59201],
          [45.531375, 34.591773],
          [45.531174, 34.591645],
          [45.531, 34.591603],
          [45.530965, 34.591507],
          [45.531067, 34.591379],
          [45.531269, 34.591279],
          [45.531342, 34.591118],
          [45.531409, 34.590874],
          [45.531381, 34.59072],
          [45.531465, 34.590142],
          [45.531568, 34.589972],
          [45.531499, 34.589768],
          [45.531314, 34.589679],
          [45.531294, 34.58958],
          [45.531543, 34.58941],
          [45.531486, 34.589231],
          [45.531521, 34.589156],
          [45.531765, 34.58909],
          [45.531696, 34.58895],
          [45.531142, 34.588862],
          [45.531282, 34.588602],
          [45.531432, 34.588556],
          [45.531814, 34.588584],
          [45.532093, 34.588425],
          [45.532108, 34.588339],
          [45.532007, 34.58828],
          [45.531481, 34.588198],
          [45.531099, 34.587425],
          [45.530974, 34.586919],
          [45.530923, 34.586867],
          [45.530239, 34.586798],
          [45.530081, 34.586689],
          [45.530061, 34.586612],
          [45.530134, 34.586474],
          [45.530372, 34.586303],
          [45.530356, 34.586219],
          [45.530191, 34.586084],
          [45.53028, 34.585776],
          [45.530118, 34.585611],
          [45.529376, 34.585125],
          [45.529278, 34.584747],
          [45.528715, 34.584516],
          [45.52863, 34.584417],
          [45.52863, 34.584037],
          [45.528429, 34.583673],
          [45.52857, 34.583443],
          [45.528531, 34.583355],
          [45.528359, 34.583298],
          [45.528346, 34.583141],
          [45.528467, 34.582872],
          [45.528411, 34.582783],
          [45.528026, 34.582802],
          [45.527872, 34.582897],
          [45.527888, 34.583111],
          [45.52777, 34.583177],
          [45.527586, 34.583202],
          [45.527428, 34.582755],
          [45.52761, 34.582533],
          [45.52761, 34.582385],
          [45.527353, 34.582122],
          [45.527177, 34.58148],
          [45.527153, 34.58126],
          [45.527297, 34.581125],
          [45.527232, 34.581019],
          [45.527054, 34.580894],
          [45.526883, 34.580863],
          [45.526802, 34.580505],
          [45.527271, 34.580472],
          [45.527514, 34.580578],
          [45.527669, 34.580524],
          [45.527775, 34.580327],
          [45.527769, 34.580176],
          [45.52732, 34.579905],
          [45.527121, 34.57972],
          [45.527099, 34.579401],
          [45.527003, 34.57922],
          [45.526749, 34.579033],
          [45.526532, 34.578733],
          [45.526523, 34.578214],
          [45.52639, 34.578161],
          [45.525973, 34.578246],
          [45.525851, 34.57813],
          [45.525364, 34.577923],
          [45.525179, 34.577751],
          [45.524626, 34.577517],
          [45.524313, 34.577185],
          [45.52421, 34.577229],
          [45.524127, 34.577453],
          [45.524136, 34.577527],
          [45.524348, 34.577699],
          [45.524326, 34.577806],
          [45.524091, 34.577862],
          [45.523983, 34.577797],
          [45.523882, 34.57745],
          [45.523779, 34.577343],
          [45.523698, 34.577091],
          [45.523511, 34.576828],
          [45.523202, 34.576487],
          [45.522937, 34.576427],
          [45.522834, 34.576324],
          [45.522891, 34.575995],
          [45.522951, 34.575912],
          [45.52289, 34.575611],
          [45.52292, 34.575454],
          [45.523009, 34.575316],
          [45.5231, 34.575266],
          [45.523098, 34.575153],
          [45.522847, 34.57479],
          [45.52281, 34.574626],
          [45.522888, 34.574422],
          [45.523027, 34.574329],
          [45.523172, 34.574323],
          [45.523338, 34.574423],
          [45.523554, 34.574438],
          [45.523745, 34.574423],
          [45.52378, 34.574368],
          [45.523731, 34.574285],
          [45.523583, 34.574194],
          [45.523142, 34.574039],
          [45.523061, 34.573784],
          [45.523084, 34.573495],
          [45.522775, 34.57337],
          [45.522642, 34.573147],
          [45.522492, 34.573025],
          [45.522554, 34.572908],
          [45.52272, 34.572837],
          [45.522872, 34.572837],
          [45.523021, 34.572909],
          [45.523152, 34.573042],
          [45.523599, 34.573116],
          [45.523722, 34.573067],
          [45.523701, 34.572965],
          [45.523561, 34.572841],
          [45.523395, 34.572783],
          [45.523409, 34.572606],
          [45.523324, 34.57228],
          [45.52334, 34.572177],
          [45.523474, 34.571988],
          [45.523395, 34.571912],
          [45.523264, 34.571905],
          [45.522847, 34.572059],
          [45.522703, 34.572022],
          [45.522646, 34.571924],
          [45.522318, 34.571839],
          [45.522235, 34.571749],
          [45.522193, 34.571401],
          [45.521986, 34.571368],
          [45.521945, 34.571274],
          [45.522033, 34.570807],
          [45.522148, 34.57069],
          [45.522383, 34.570583],
          [45.522639, 34.570368],
          [45.523013, 34.570503],
          [45.523141, 34.570419],
          [45.52302, 34.570158],
          [45.522962, 34.569852],
          [45.522842, 34.569679],
          [45.522881, 34.569541],
          [45.522988, 34.569437],
          [45.523271, 34.569388],
          [45.52332, 34.569309],
          [45.523283, 34.569185],
          [45.523138, 34.569063],
          [45.52302, 34.568999],
          [45.522859, 34.568981],
          [45.522832, 34.56892],
          [45.522998, 34.568833],
          [45.523023, 34.568682],
          [45.522645, 34.568489],
          [45.522555, 34.56837],
          [45.522559, 34.568056],
          [45.522407, 34.567696],
          [45.522306, 34.567617],
          [45.522304, 34.567529],
          [45.522679, 34.567316],
          [45.522858, 34.566972],
          [45.52314, 34.566961],
          [45.523266, 34.566908],
          [45.523355, 34.566776],
          [45.523553, 34.566691],
          [45.523635, 34.566572],
          [45.523807, 34.56597],
          [45.523756, 34.565921],
          [45.523655, 34.565943],
          [45.523401, 34.566061],
          [45.52323, 34.565944],
          [45.522843, 34.565819],
          [45.522848, 34.565733],
          [45.523488, 34.565053],
          [45.523508, 34.564925],
          [45.523238, 34.56442],
          [45.523366, 34.564212],
          [45.52302, 34.564018],
          [45.522958, 34.563901],
          [45.522966, 34.563768],
          [45.523053, 34.563668],
          [45.523036, 34.563563],
          [45.52269, 34.563059],
          [45.522351, 34.562933],
          [45.522309, 34.562771],
          [45.522313, 34.56263],
          [45.522329, 34.562083],
          [45.522407, 34.561907],
          [45.522415, 34.561647],
          [45.522521, 34.561578],
          [45.522951, 34.561541],
          [45.523015, 34.561352],
          [45.5232, 34.56132],
          [45.523347, 34.560935],
          [45.523236, 34.56061],
          [45.523252, 34.560422],
          [45.523187, 34.560337],
          [45.522875, 34.560191],
          [45.522816, 34.56005],
          [45.522446, 34.559845],
          [45.522345, 34.559519],
          [45.521856, 34.559454],
          [45.521755, 34.559232],
          [45.521674, 34.559169],
          [45.521562, 34.559131],
          [45.521192, 34.559153],
          [45.521078, 34.559107],
          [45.520845, 34.559102],
          [45.520705, 34.558914],
          [45.520418, 34.558864],
          [45.520368, 34.558795],
          [45.52046, 34.558547],
          [45.520452, 34.558413],
          [45.520183, 34.55792],
          [45.520049, 34.557835],
          [45.519757, 34.557747],
          [45.519336, 34.55774],
          [45.5189, 34.557283],
          [45.518705, 34.557176],
          [45.518606, 34.557064],
          [45.518417, 34.556647],
          [45.518308, 34.55658],
          [45.517952, 34.556477],
          [45.517602, 34.556376],
          [45.517531, 34.556299],
          [45.517516, 34.556183],
          [45.517403, 34.556117],
          [45.516999, 34.556074],
          [45.51692, 34.555872],
          [45.516452, 34.555695],
          [45.51633, 34.555517],
          [45.516274, 34.555307],
          [45.516166, 34.555288],
          [45.515959, 34.555378],
          [45.51551, 34.555155],
          [45.51535, 34.555121],
          [45.515237, 34.554963],
          [45.515024, 34.554849],
          [45.514997, 34.554771],
          [45.515069, 34.554578],
          [45.515019, 34.554485],
          [45.515102, 34.554347],
          [45.515092, 34.554231],
          [45.515253, 34.55413],
          [45.515277, 34.554061],
          [45.515135, 34.553977],
          [45.515041, 34.553794],
          [45.514952, 34.553746],
          [45.514772, 34.553284],
          [45.514668, 34.553234],
          [45.514372, 34.552897],
          [45.51411, 34.552734],
          [45.513874, 34.55265],
          [45.51377, 34.552469],
          [45.513516, 34.55241],
          [45.513377, 34.552317],
          [45.513154, 34.552065],
          [45.51199, 34.551635],
          [45.512834, 34.551049],
          [45.513001, 34.550999],
          [45.513608, 34.551044],
          [45.513728, 34.551005],
          [45.513903, 34.55081],
          [45.514411, 34.550473],
          [45.515108, 34.550182],
          [45.514243, 34.54943],
          [45.513989, 34.549123],
          [45.513945, 34.548807],
          [45.51399, 34.548131],
          [45.513584, 34.547218],
          [45.513572, 34.546868],
          [45.513627, 34.546533],
          [45.513721, 34.546333],
          [45.514646, 34.545727],
          [45.51559, 34.545349],
          [45.516067, 34.544686],
          [45.516585, 34.544481],
          [45.517149, 34.544057],
          [45.517429, 34.543918],
          [45.517623, 34.543742],
          [45.518587, 34.543635],
          [45.518939, 34.543438],
          [45.519272, 34.54336],
          [45.519635, 34.543191],
          [45.520067, 34.543068],
          [45.520303, 34.542952],
          [45.520447, 34.542662],
          [45.520641, 34.542443],
          [45.521183, 34.542227],
          [45.521258, 34.542178],
          [45.521331, 34.542077],
          [45.521333, 34.54189],
          [45.521221, 34.541555],
          [45.52119, 34.541298],
          [45.5212, 34.541223],
          [45.521658, 34.540588],
          [45.521855, 34.540447],
          [45.522573, 34.540119],
          [45.52263, 34.540001],
          [45.522694, 34.539973],
          [45.522711, 34.539578],
          [45.522725, 34.53947],
          [45.522866, 34.539177],
          [45.522888, 34.538895],
          [45.522976, 34.538617],
          [45.52314, 34.538376],
          [45.523218, 34.538096],
          [45.523031, 34.537465],
          [45.523057, 34.537109],
          [45.522996, 34.536987],
          [45.522773, 34.536823],
          [45.522461, 34.53648],
          [45.522504, 34.536226],
          [45.522788, 34.535851],
          [45.522852, 34.535602],
          [45.523079, 34.535261],
          [45.523107, 34.535096],
          [45.523273, 34.534816],
          [45.523506, 34.534548],
          [45.523952, 34.534332],
          [45.52413, 34.534085],
          [45.524117, 34.533617],
          [45.524498, 34.53301],
          [45.524255, 34.532596],
          [45.524291, 34.532469],
          [45.524489, 34.532246],
          [45.524775, 34.531787],
          [45.525025, 34.531604],
          [45.525536, 34.531566],
          [45.525938, 34.531352],
          [45.526642, 34.530573],
          [45.526665, 34.529909],
          [45.526766, 34.529538],
          [45.526666, 34.529078],
          [45.526975, 34.528433],
          [45.527084, 34.528052],
          [45.526959, 34.527343],
          [45.526676, 34.52704],
          [45.526654, 34.526869],
          [45.526698, 34.526462],
          [45.526857, 34.526196],
          [45.526855, 34.525814],
          [45.526853, 34.525518],
          [45.527002, 34.52507],
          [45.52669, 34.523957],
          [45.526443, 34.523749],
          [45.526389, 34.523548],
          [45.526364, 34.522981],
          [45.526392, 34.522812],
          [45.526714, 34.522231],
          [45.52677, 34.521966],
          [45.526837, 34.521548],
          [45.526781, 34.521247],
          [45.526573, 34.520676],
          [45.526616, 34.520487],
          [45.52679, 34.520202],
          [45.526742, 34.519934],
          [45.526817, 34.519623],
          [45.527062, 34.519388],
          [45.527029, 34.519193],
          [45.527093, 34.51894],
          [45.527058, 34.518715],
          [45.527208, 34.518293],
          [45.527205, 34.518163],
          [45.527041, 34.517999],
          [45.52636, 34.517591],
          [45.526341, 34.516711],
          [45.526413, 34.516431],
          [45.526581, 34.516185],
          [45.526562, 34.515918],
          [45.526676, 34.515736],
          [45.526629, 34.51516],
          [45.526432, 34.514683],
          [45.526215, 34.514483],
          [45.526123, 34.514288],
          [45.525927, 34.513524],
          [45.525909, 34.513213],
          [45.525068, 34.511732],
          [45.525167, 34.511552],
          [45.525492, 34.511232],
          [45.525692, 34.511129],
          [45.525991, 34.510669],
          [45.526305, 34.510072],
          [45.52637, 34.50985],
          [45.526408, 34.509322],
          [45.526189, 34.509153],
          [45.52597, 34.508897],
          [45.525929, 34.508664],
          [45.526057, 34.508232],
          [45.526002, 34.507483],
          [45.526242, 34.506905],
          [45.52651, 34.505757],
          [45.526393, 34.504947],
          [45.525959, 34.504543],
          [45.525669, 34.504367],
          [45.525522, 34.504188],
          [45.525481, 34.504024],
          [45.525606, 34.503871],
          [45.525877, 34.503689],
          [45.525914, 34.503384],
          [45.526342, 34.502836],
          [45.526377, 34.502631],
          [45.526168, 34.502012],
          [45.526188, 34.50147],
          [45.526306, 34.501218],
          [45.526533, 34.500925],
          [45.526606, 34.50074],
          [45.526597, 34.500585],
          [45.52644, 34.500272],
          [45.526045, 34.499819],
          [45.525152, 34.499228],
          [45.525054, 34.498781],
          [45.525307, 34.497788],
          [45.525704, 34.497074],
          [45.525603, 34.496858],
          [45.525577, 34.496653],
          [45.525624, 34.496211],
          [45.525516, 34.495961],
          [45.525772, 34.495485],
          [45.525955, 34.494898],
          [45.525918, 34.494725],
          [45.525524, 34.494163],
          [45.525506, 34.494013],
          [45.525671, 34.493707],
          [45.525705, 34.493163],
          [45.525542, 34.492799],
          [45.52544, 34.492692],
          [45.525151, 34.492589],
          [45.524988, 34.492451],
          [45.524926, 34.492303],
          [45.524979, 34.491983],
          [45.524904, 34.491458],
          [45.524647, 34.490977],
          [45.52413, 34.490328],
          [45.524183, 34.490025],
          [45.523731, 34.489267],
          [45.523891, 34.488983],
          [45.523864, 34.488791],
          [45.523427, 34.488005],
          [45.52359, 34.487646],
          [45.523632, 34.48734],
          [45.523946, 34.4869],
          [45.52374, 34.486564],
          [45.523184, 34.485102],
          [45.522577, 34.484649],
          [45.522516, 34.484505],
          [45.522517, 34.483466],
          [45.522213, 34.482569],
          [45.521943, 34.48219],
          [45.52134, 34.481827],
          [45.521232, 34.481716],
          [45.521199, 34.481586],
          [45.521281, 34.481261],
          [45.521255, 34.480982],
          [45.521152, 34.480871],
          [45.520618, 34.480664],
          [45.520497, 34.48022],
          [45.52004, 34.479958],
          [45.519785, 34.479873],
          [45.519381, 34.479539],
          [45.519059, 34.47951],
          [45.518316, 34.478863],
          [45.517956, 34.47841],
          [45.518091, 34.478034],
          [45.51835, 34.477545],
          [45.518099, 34.477076],
          [45.517643, 34.476749],
          [45.517478, 34.476398],
          [45.517377, 34.475978],
          [45.517209, 34.475705],
          [45.516112, 34.475157],
          [45.515929, 34.474678],
          [45.515813, 34.474512],
          [45.515156, 34.474257],
          [45.514539, 34.473736],
          [45.514068, 34.473568],
          [45.513681, 34.473649],
          [45.513393, 34.473594],
          [45.513126, 34.473458],
          [45.512519, 34.472526],
          [45.511285, 34.472301],
          [45.510789, 34.472059],
          [45.510362, 34.472006],
          [45.509799, 34.471999],
          [45.509553, 34.471825],
          [45.50938, 34.471484],
          [45.50908, 34.471283],
          [45.508009, 34.471305],
          [45.507672, 34.470935],
          [45.507301, 34.470866],
          [45.506602, 34.470966],
          [45.505975, 34.470759],
          [45.50532, 34.470608],
          [45.504473, 34.470616],
          [45.504239, 34.470536],
          [45.50396, 34.47075],
          [45.50359, 34.470863],
          [45.502951, 34.470832],
          [45.502162, 34.471423],
          [45.501892, 34.471496],
          [45.50159, 34.471426],
          [45.50114, 34.47149],
          [45.500911, 34.471661],
          [45.500509, 34.471675],
          [45.500302, 34.472035],
          [45.500073, 34.472164],
          [45.499703, 34.472234],
          [45.499172, 34.472692],
          [45.49792, 34.473244],
          [45.497695, 34.473659],
          [45.496734, 34.473917],
          [45.495858, 34.474242],
          [45.495325, 34.474109],
          [45.494922, 34.474114],
          [45.494473, 34.47423],
          [45.493535, 34.474363],
          [45.492828, 34.474377],
          [45.492708, 34.474464],
          [45.492654, 34.474758],
          [45.492547, 34.474991],
          [45.492287, 34.475159],
          [45.491651, 34.475093],
          [45.491142, 34.475113],
          [45.491034, 34.47525],
          [45.49078, 34.475399],
          [45.490488, 34.47551],
          [45.490198, 34.475534],
          [45.489099, 34.475429],
          [45.488659, 34.475448],
          [45.488264, 34.4754],
          [45.487887, 34.475436],
          [45.487317, 34.475674],
          [45.487009, 34.475622],
          [45.486622, 34.475703],
          [45.486392, 34.475823],
          [45.485788, 34.475813],
          [45.485593, 34.476006],
          [45.485219, 34.476103],
          [45.484942, 34.476281],
          [45.483415, 34.476611],
          [45.483152, 34.476762],
          [45.482461, 34.476582],
          [45.482116, 34.476535],
          [45.48202, 34.47587],
          [45.481882, 34.47569],
          [45.480821, 34.475396],
          [45.478921, 34.475597],
          [45.478271, 34.475742],
          [45.477758, 34.475606],
          [45.477255, 34.475651],
          [45.475841, 34.475913],
          [45.475126, 34.475893],
          [45.474825, 34.475823],
          [45.474507, 34.475676],
          [45.474165, 34.475647],
          [45.473815, 34.47581],
          [45.473424, 34.476326],
          [45.473065, 34.476534],
          [45.472629, 34.476526],
          [45.47222, 34.476463],
          [45.471845, 34.476507],
          [45.471689, 34.476651],
          [45.471661, 34.476977],
          [45.471478, 34.477221],
          [45.47131, 34.477358],
          [45.47096, 34.477425],
          [45.470784, 34.477572],
          [45.470604, 34.477876],
          [45.470337, 34.47801],
          [45.469923, 34.47806],
          [45.469512, 34.477892],
          [45.469043, 34.477832],
          [45.468759, 34.477502],
          [45.467564, 34.476425],
          [45.467424, 34.476333],
          [45.466721, 34.47616],
          [45.466197, 34.475588],
          [45.466086, 34.475319],
          [45.466127, 34.475081],
          [45.466315, 34.474797],
          [45.466201, 34.474615],
          [45.466019, 34.474507],
          [45.465686, 34.474403],
          [45.465565, 34.474437],
          [45.465271, 34.474779],
          [45.465021, 34.474973],
          [45.464901, 34.475231],
          [45.464557, 34.47552],
          [45.464362, 34.475543],
          [45.464046, 34.475506],
          [45.463394, 34.475218],
          [45.463008, 34.474872],
          [45.462573, 34.474373],
          [45.462404, 34.474259],
          [45.462122, 34.474165],
          [45.461668, 34.474147],
          [45.461139, 34.473611],
          [45.461268, 34.473501],
          [45.461232, 34.473244],
          [45.461113, 34.472964],
          [45.460932, 34.472739],
          [45.460692, 34.471693],
          [45.460744, 34.47156],
          [45.461087, 34.471121],
          [45.46165, 34.470831],
          [45.462408, 34.470958],
          [45.462906, 34.47078],
          [45.463227, 34.470748],
          [45.46353, 34.470603],
          [45.463587, 34.47047],
          [45.463114, 34.470101],
          [45.462912, 34.470043],
          [45.462748, 34.469855],
          [45.462691, 34.469717],
          [45.462736, 34.469375],
          [45.462685, 34.46924],
          [45.462451, 34.469066],
          [45.462226, 34.468693],
          [45.462218, 34.468531],
          [45.462319, 34.468448],
          [45.462657, 34.468401],
          [45.462626, 34.468172],
          [45.462716, 34.468082],
          [45.463017, 34.467946],
          [45.462859, 34.467641],
          [45.462843, 34.467264],
          [45.462758, 34.467177],
          [45.462628, 34.467165],
          [45.462296, 34.467336],
          [45.462187, 34.467283],
          [45.462081, 34.466966],
          [45.461962, 34.466832],
          [45.461781, 34.466758],
          [45.461693, 34.466758],
          [45.46154, 34.466921],
          [45.461363, 34.466989],
          [45.461211, 34.466963],
          [45.460943, 34.46657],
          [45.460823, 34.466509],
          [45.459791, 34.466576],
          [45.459462, 34.466493],
          [45.459015, 34.46628],
          [45.458641, 34.4657],
          [45.457724, 34.465997],
          [45.457354, 34.466202],
          [45.457015, 34.46632],
          [45.456543, 34.466443],
          [45.456209, 34.466451],
          [45.455552, 34.466571],
          [45.455279, 34.466666],
          [45.454803, 34.466939],
          [45.454432, 34.467091],
          [45.454122, 34.466824],
          [45.453908, 34.46675],
          [45.453418, 34.466246],
          [45.453046, 34.465962],
          [45.45276, 34.465876],
          [45.45251, 34.465835],
          [45.452104, 34.465936],
          [45.451893, 34.466058],
          [45.451628, 34.466307],
          [45.451399, 34.466367],
          [45.450851, 34.466165],
          [45.450445, 34.465943],
          [45.450071, 34.465597],
          [45.450227, 34.465233],
          [45.450172, 34.464967],
          [45.449996, 34.464737],
          [45.450318, 34.464153],
          [45.450114, 34.463664],
          [45.449033, 34.463741],
          [45.448618, 34.463576],
          [45.448215, 34.463567],
          [45.447438, 34.463882],
          [45.446986, 34.463999],
          [45.446471, 34.464073],
          [45.446191, 34.463994],
          [45.445971, 34.464036],
          [45.445334, 34.464033],
          [45.444928, 34.464097],
          [45.444657, 34.463934],
          [45.444488, 34.463757],
          [45.444479, 34.463677],
          [45.444552, 34.463562],
          [45.444457, 34.463408],
          [45.444279, 34.463425],
          [45.444099, 34.463605],
          [45.443855, 34.463598],
          [45.443789, 34.463473],
          [45.443943, 34.46314],
          [45.443934, 34.463054],
          [45.443663, 34.462794],
          [45.443512, 34.462735],
          [45.443354, 34.461838],
          [45.443388, 34.461413],
          [45.443323, 34.461287],
          [45.44306, 34.46108],
          [45.443032, 34.460667],
          [45.442941, 34.460633],
          [45.44261, 34.46065],
          [45.442182, 34.460527],
          [45.44204, 34.460411],
          [45.441833, 34.460013],
          [45.441742, 34.460002],
          [45.441567, 34.460091],
          [45.441474, 34.460069],
          [45.441668, 34.459507],
          [45.441656, 34.459202],
          [45.441584, 34.459135],
          [45.44149, 34.459132],
          [45.441217, 34.459245],
          [45.441123, 34.459441],
          [45.44076, 34.459477],
          [45.440675, 34.459447],
          [45.440632, 34.45923],
          [45.440497, 34.459072],
          [45.44042, 34.459055],
          [45.440259, 34.459161],
          [45.440162, 34.459147],
          [45.440097, 34.459041],
          [45.439814, 34.458932],
          [45.439987, 34.458783],
          [45.440082, 34.458395],
          [45.440015, 34.458331],
          [45.439826, 34.458299],
          [45.439852, 34.458131],
          [45.439741, 34.457973],
          [45.44006, 34.457716],
          [45.440077, 34.457603],
          [45.44004, 34.457466],
          [45.439794, 34.45715],
          [45.439895, 34.456888],
          [45.439838, 34.456601],
          [45.43999, 34.4565],
          [45.440068, 34.456222],
          [45.440038, 34.455873],
          [45.439938, 34.455768],
          [45.43993, 34.455654],
          [45.440124, 34.455517],
          [45.440294, 34.455506],
          [45.440364, 34.455458],
          [45.440356, 34.455368],
          [45.440082, 34.455055],
          [45.439969, 34.45471],
          [45.439922, 34.454425],
          [45.439824, 34.454255],
          [45.439841, 34.453823],
          [45.439989, 34.453501],
          [45.440117, 34.453354],
          [45.440335, 34.453237],
          [45.440432, 34.452952],
          [45.440549, 34.452875],
          [45.440735, 34.452843],
          [45.440993, 34.453027],
          [45.441162, 34.453082],
          [45.441287, 34.453019],
          [45.441375, 34.452843],
          [45.441374, 34.45259],
          [45.441801, 34.45253],
          [45.441912, 34.452691],
          [45.441984, 34.452682],
          [45.442139, 34.452125],
          [45.442232, 34.452048],
          [45.442415, 34.452],
          [45.442665, 34.451776],
          [45.442664, 34.451692],
          [45.44256, 34.451663],
          [45.442114, 34.45163],
          [45.442049, 34.451501],
          [45.441964, 34.451294],
          [45.44197, 34.451178],
          [45.442218, 34.450962],
          [45.442284, 34.450849],
          [45.442278, 34.450743],
          [45.442209, 34.450622],
          [45.442001, 34.450592],
          [45.441939, 34.450328],
          [45.441977, 34.450273],
          [45.442228, 34.450244],
          [45.442292, 34.450171],
          [45.442337, 34.450053],
          [45.442271, 34.449821],
          [45.442288, 34.449708],
          [45.443012, 34.44916],
          [45.443192, 34.44883],
          [45.443453, 34.448508],
          [45.443164, 34.448366],
          [45.442976, 34.447866],
          [45.442858, 34.447721],
          [45.442762, 34.447339],
          [45.442494, 34.447156],
          [45.442254, 34.446863],
          [45.44193, 34.446894],
          [45.441755, 34.44681],
          [45.441961, 34.446556],
          [45.441903, 34.446216],
          [45.44195, 34.446156],
          [45.442179, 34.446095],
          [45.442085, 34.44582],
          [45.442315, 34.445686],
          [45.442367, 34.445576],
          [45.44211, 34.445054],
          [45.442157, 34.44452],
          [45.442281, 34.444377],
          [45.442417, 34.444321],
          [45.442656, 34.444036],
          [45.442635, 34.443978],
          [45.442538, 34.443964],
          [45.441894, 34.443992],
          [45.441828, 34.443912],
          [45.441771, 34.443674],
          [45.441802, 34.443533],
          [45.441855, 34.443426],
          [45.441997, 34.443373],
          [45.442043, 34.443282],
          [45.441932, 34.443048],
          [45.441961, 34.442922],
          [45.442032, 34.442809],
          [45.442133, 34.442769],
          [45.442269, 34.442781],
          [45.44238, 34.442847],
          [45.442452, 34.44296],
          [45.442344, 34.443208],
          [45.442384, 34.443337],
          [45.442462, 34.443384],
          [45.442779, 34.443216],
          [45.44299, 34.442966],
          [45.442873, 34.442652],
          [45.442706, 34.442356],
          [45.4426, 34.44227],
          [45.442153, 34.442402],
          [45.442142, 34.442319],
          [45.44218, 34.442188],
          [45.442341, 34.442059],
          [45.442623, 34.441666],
          [45.442851, 34.441479],
          [45.442898, 34.441365],
          [45.442702, 34.441077],
          [45.442562, 34.440988],
          [45.442476, 34.440758],
          [45.442509, 34.440624],
          [45.442756, 34.440507],
          [45.442852, 34.440391],
          [45.442823, 34.439851],
          [45.442858, 34.439559],
          [45.442918, 34.439313],
          [45.44306, 34.439084],
          [45.44324, 34.438926],
          [45.443472, 34.438807],
          [45.443737, 34.438726],
          [45.444574, 34.4386],
          [45.445074, 34.438832],
          [45.445861, 34.43885],
          [45.446166, 34.43903],
          [45.446271, 34.439142],
          [45.446556, 34.438941],
          [45.447041, 34.438336],
          [45.447557, 34.438029],
          [45.448014, 34.437654],
          [45.448199, 34.437268],
          [45.448371, 34.437136],
          [45.448559, 34.437098],
          [45.448679, 34.437129],
          [45.448923, 34.436822],
          [45.44885, 34.436778],
          [45.449004, 34.436732],
          [45.44909, 34.436773],
          [45.449158, 34.436732],
          [45.44913, 34.436623],
          [45.449184, 34.4366],
          [45.449315, 34.436611],
          [45.449429, 34.436723],
          [45.449596, 34.436652],
          [45.449649, 34.436392],
          [45.44992, 34.436256],
          [45.450087, 34.436113],
          [45.450239, 34.435842],
          [45.450266, 34.435696],
          [45.450691, 34.435634],
          [45.450768, 34.435578],
          [45.450745, 34.435525],
          [45.450835, 34.435499],
          [45.45132, 34.435489],
          [45.451427, 34.435328],
          [45.451736, 34.435357],
          [45.452061, 34.43531],
          [45.452169, 34.435201],
          [45.452422, 34.435114],
          [45.452744, 34.435195],
          [45.452867, 34.435289],
          [45.453062, 34.435318],
          [45.453456, 34.435268],
          [45.453961, 34.435074],
          [45.454308, 34.435109],
          [45.454299, 34.432754],
          [45.455941, 34.432991],
          [45.455834, 34.432545],
          [45.455908, 34.432347],
          [45.455887, 34.431699],
          [45.455907, 34.4316],
          [45.456015, 34.431498],
          [45.456109, 34.431129],
          [45.456082, 34.430796],
          [45.456139, 34.430438],
          [45.455985, 34.430327],
          [45.455829, 34.430066],
          [45.455712, 34.429716],
          [45.455725, 34.428555],
          [45.456279, 34.428027],
          [45.456533, 34.427867],
          [45.456655, 34.42771],
          [45.456814, 34.427325],
          [45.456791, 34.42691],
          [45.457062, 34.42669],
          [45.45721, 34.426368],
          [45.456998, 34.425554],
          [45.457034, 34.425239],
          [45.456882, 34.424968],
          [45.457009, 34.424763],
          [45.457096, 34.424434],
          [45.457093, 34.424273],
          [45.456964, 34.424045],
          [45.45696, 34.423927],
          [45.457091, 34.423665],
          [45.457129, 34.423388],
          [45.456998, 34.423175],
          [45.456983, 34.422925],
          [45.456897, 34.422767],
          [45.456476, 34.422264],
          [45.456733, 34.42177],
          [45.456719, 34.421033],
          [45.455552, 34.419845],
          [45.455619, 34.419387],
          [45.455994, 34.418942],
          [45.455517, 34.418319],
          [45.455585, 34.417912],
          [45.455556, 34.417505],
          [45.4562, 34.416448],
          [45.456823, 34.416562],
          [45.457139, 34.416546],
          [45.457305, 34.416451],
          [45.457446, 34.41648],
          [45.458558, 34.415834],
          [45.462498, 34.4025],
          [45.463904, 34.398679],
          [45.467309, 34.382565],
          [45.468143, 34.381996],
          [45.467746, 34.379146],
          [45.480033, 34.365339],
          [45.496178, 34.336867],
          [45.498573, 34.335315],
          [45.498748, 34.335731],
          [45.49888, 34.336551],
          [45.499178, 34.337214],
          [45.501694, 34.339771],
          [45.5025, 34.340803],
          [45.502698, 34.341604],
          [45.502572, 34.34211],
          [45.502554, 34.343031],
          [45.502458, 34.343688],
          [45.502477, 34.344176],
          [45.502749, 34.344887],
          [45.502986, 34.345336],
          [45.503702, 34.346278],
          [45.503841, 34.34642],
          [45.504303, 34.346679],
          [45.504725, 34.347012],
          [45.505748, 34.348133],
          [45.50608, 34.349017],
          [45.506138, 34.349444],
          [45.506049, 34.349722],
          [45.50603, 34.350093],
          [45.506161, 34.35045],
          [45.506941, 34.351595],
          [45.507263, 34.351958],
          [45.507602, 34.352186],
          [45.508163, 34.352325],
          [45.508321, 34.352322],
          [45.508716, 34.352178],
          [45.509234, 34.351837],
          [45.510214, 34.350914],
          [45.510931, 34.350337],
          [45.512206, 34.349703],
          [45.513878, 34.348639],
          [45.514671, 34.348254],
          [45.515017, 34.347991],
          [45.515191, 34.34794],
          [45.515861, 34.347844],
          [45.51672, 34.347876],
          [45.517892, 34.348095],
          [45.519559, 34.348509],
          [45.520327, 34.348606],
          [45.520838, 34.348594],
          [45.521135, 34.348506],
          [45.521612, 34.348535],
          [45.521823, 34.348602],
          [45.522075, 34.348821],
          [45.522312, 34.348883],
          [45.522366, 34.349002],
          [45.522481, 34.349066],
          [45.523849, 34.348991],
          [45.524258, 34.349017],
          [45.524462, 34.349057],
          [45.524547, 34.349148],
          [45.524837, 34.349166],
          [45.525079, 34.349137],
          [45.525365, 34.349006],
          [45.526125, 34.348863],
          [45.526854, 34.348448],
          [45.527141, 34.348184],
          [45.527306, 34.348121],
          [45.527624, 34.347644],
          [45.527949, 34.346974],
          [45.528076, 34.346818],
          [45.528429, 34.346539],
          [45.528561, 34.346064],
          [45.528958, 34.345814],
          [45.529265, 34.345702],
          [45.529518, 34.345676],
          [45.52996, 34.345688],
          [45.530337, 34.345783],
          [45.530837, 34.345936],
          [45.530927, 34.346026],
          [45.531117, 34.346092],
          [45.532337, 34.34613],
          [45.532661, 34.346202],
          [45.533008, 34.346352],
          [45.533169, 34.346522],
          [45.533319, 34.346848],
          [45.533336, 34.347578],
          [45.53379, 34.349074],
          [45.534288, 34.35046],
          [45.534347, 34.350964],
          [45.534266, 34.351409],
          [45.53454, 34.351529],
          [45.535126, 34.352068],
          [45.535361, 34.352077],
          [45.535735, 34.352186],
          [45.535874, 34.3521],
          [45.535945, 34.351953],
          [45.536408, 34.351871],
          [45.536853, 34.351332],
          [45.537206, 34.351212],
          [45.537494, 34.351176],
          [45.537621, 34.351224],
          [45.537661, 34.351304],
          [45.537418, 34.351647],
          [45.537653, 34.351928],
          [45.537719, 34.352077],
          [45.537813, 34.352139],
          [45.538075, 34.352107],
          [45.538192, 34.351902],
          [45.538227, 34.351533],
          [45.538277, 34.351475],
          [45.538381, 34.351468],
          [45.538573, 34.351587],
          [45.538746, 34.351605],
          [45.538865, 34.351546],
          [45.538917, 34.351019],
          [45.539088, 34.350733],
          [45.53943, 34.350342],
          [45.539692, 34.350283],
          [45.539766, 34.350353],
          [45.539515, 34.350615],
          [45.539455, 34.350766],
          [45.539482, 34.350925],
          [45.539533, 34.350963],
          [45.539948, 34.350838],
          [45.540139, 34.35084],
          [45.540261, 34.350775],
          [45.540608, 34.350379],
          [45.5412, 34.349858],
          [45.541287, 34.349731],
          [45.541218, 34.349433],
          [45.541246, 34.349351],
          [45.54135, 34.349298],
          [45.541532, 34.349328],
          [45.541741, 34.349295],
          [45.541965, 34.349146],
          [45.542102, 34.348802],
          [45.542225, 34.34865],
          [45.542494, 34.348512],
          [45.542948, 34.348532],
          [45.543143, 34.348483],
          [45.543345, 34.34851],
          [45.543353, 34.348813],
          [45.543417, 34.348862],
          [45.54354, 34.348825],
          [45.544039, 34.3484],
          [45.544413, 34.347532],
          [45.544716, 34.347198],
          [45.54474, 34.346939],
          [45.544835, 34.346824],
          [45.545057, 34.346821],
          [45.545282, 34.346381],
          [45.545651, 34.34599],
          [45.54609, 34.345675],
          [45.546188, 34.345664],
          [45.546316, 34.345894],
          [45.546416, 34.345938],
          [45.546657, 34.345927],
          [45.546805, 34.345835],
          [45.546887, 34.345713],
          [45.546765, 34.345464],
          [45.546758, 34.34532],
          [45.546904, 34.345151],
          [45.54717, 34.345023],
          [45.547252, 34.344646],
          [45.547035, 34.344262],
          [45.54774, 34.343951],
          [45.548098, 34.343944],
          [45.548222, 34.343838],
          [45.548285, 34.343701],
          [45.548257, 34.343605],
          [45.54805, 34.343465],
          [45.548041, 34.343153],
          [45.548086, 34.343073],
          [45.548183, 34.343058],
          [45.54846, 34.343114],
          [45.548488, 34.343073],
          [45.548405, 34.342832],
          [45.548817, 34.342717],
          [45.548932, 34.342621],
          [45.549042, 34.342163],
          [45.548973, 34.34191],
          [45.549041, 34.341817],
          [45.549562, 34.341535],
          [45.549768, 34.341557],
          [45.549848, 34.341626],
          [45.549868, 34.3418],
          [45.549946, 34.341865],
          [45.550038, 34.341851],
          [45.550407, 34.341279],
          [45.550551, 34.341215],
          [45.550611, 34.340996],
          [45.550843, 34.340791],
          [45.550978, 34.340756],
          [45.551168, 34.340777],
          [45.551301, 34.340874],
          [45.551381, 34.340875],
          [45.551447, 34.340819],
          [45.551443, 34.34046],
          [45.551579, 34.34043],
          [45.551816, 34.340474],
          [45.551933, 34.340428],
          [45.551851, 34.340259],
          [45.551693, 34.340102],
          [45.551782, 34.340034],
          [45.552404, 34.339999],
          [45.552705, 34.340179],
          [45.552974, 34.3402],
          [45.553209, 34.34014],
          [45.553728, 34.339758],
          [45.553892, 34.339532],
          [45.553877, 34.339444],
          [45.553816, 34.339412],
          [45.55353, 34.33943],
          [45.55345, 34.339361],
          [45.55344, 34.339181],
          [45.553476, 34.338993],
          [45.55356, 34.338925],
          [45.554082, 34.338784],
          [45.554169, 34.338724],
          [45.554181, 34.338663],
          [45.554124, 34.3386],
          [45.5539, 34.338453],
          [45.553858, 34.338319],
          [45.554098, 34.338145],
          [45.553937, 34.337797],
          [45.553974, 34.33766],
          [45.554634, 34.337223],
          [45.555223, 34.337235],
          [45.555386, 34.337296],
          [45.555559, 34.337291],
          [45.55572, 34.337211],
          [45.555935, 34.336977],
          [45.55631, 34.336949],
          [45.55646, 34.336889],
          [45.556602, 34.336526],
          [45.556572, 34.336235],
          [45.556685, 34.335881],
          [45.55685, 34.335773],
          [45.557231, 34.335658],
          [45.557276, 34.335583],
          [45.55726, 34.33549],
          [45.557041, 34.335275],
          [45.557036, 34.335184],
          [45.557125, 34.335138],
          [45.557504, 34.335151],
          [45.557603, 34.335054],
          [45.55759, 34.334956],
          [45.557265, 34.334671],
          [45.557254, 34.334582],
          [45.557358, 34.334483],
          [45.557732, 34.334361],
          [45.55829, 34.334418],
          [45.558397, 34.334378],
          [45.558804, 34.333968],
          [45.558758, 34.333748],
          [45.558799, 34.333719],
          [45.559088, 34.333687],
          [45.559312, 34.333561],
          [45.559591, 34.333467],
          [45.559734, 34.333467],
          [45.560339, 34.333226],
          [45.560661, 34.333156],
          [45.560926, 34.332978],
          [45.561153, 34.332701],
          [45.561437, 34.332625],
          [45.561625, 34.332209],
          [45.561807, 34.331623],
          [45.574748, 34.316232],
          [45.583566, 34.302663],
          [45.585172, 34.296881],
          [45.585698, 34.291206],
          [45.585535, 34.288766],
          [45.58562, 34.286706],
          [45.585121, 34.282757],
          [45.584384, 34.278574],
          [45.583216, 34.273258],
          [45.582681, 34.268939],
          [45.582136, 34.26712],
          [45.581739, 34.264227],
          [45.581528, 34.261019],
          [45.581548, 34.257146],
          [45.581633, 34.256153],
          [45.581709, 34.255266],
          [45.581772, 34.2551],
          [45.582085, 34.252976],
          [45.582679, 34.250156],
          [45.582813, 34.249059],
          [45.582854, 34.248159],
          [45.582752, 34.247025],
          [45.582462, 34.246049],
          [45.582049, 34.245308],
          [45.581786, 34.244617],
          [45.58149, 34.241788],
          [45.581097, 34.23956],
          [45.578745, 34.232701],
          [45.578221, 34.231653],
          [45.575087, 34.22711],
          [45.573724, 34.225232],
          [45.572126, 34.222567],
          [45.571544, 34.221293],
          [45.570871, 34.219301],
          [45.570332, 34.216252],
          [45.568655, 34.212268],
          [45.566803, 34.210203],
          [45.561731, 34.205817],
          [45.557137, 34.200427],
          [45.556792, 34.200076],
          [45.556031, 34.199023],
          [45.555602, 34.198242],
          [45.555169, 34.196953],
          [45.554975, 34.196132],
          [45.554874, 34.195116],
          [45.555007, 34.193746],
          [45.555702, 34.19206],
          [45.556726, 34.19006],
          [45.558314, 34.187861],
          [45.559949, 34.185702],
          [45.561305, 34.184209],
          [45.56425, 34.180596],
          [45.567476, 34.176942],
          [45.569557, 34.175118],
          [45.571272, 34.173872],
          [45.572253, 34.172655],
          [45.573325, 34.170928],
          [45.573751, 34.169802],
          [45.573889, 34.16899],
          [45.574623, 34.167197],
          [45.574732, 34.166289],
          [45.574782, 34.163526],
          [45.575099, 34.161725],
          [45.575079, 34.158673],
          [45.575167, 34.157773],
          [45.575585, 34.156871],
          [45.576275, 34.154559],
          [45.578897, 34.149925],
          [45.580234, 34.148057],
          [45.580832, 34.147356],
          [45.581162, 34.146888],
          [45.581594, 34.14613],
          [45.56802, 34.139953],
          [45.560063, 34.138826],
          [45.548712, 34.130668],
          [45.544936, 34.128266],
          [45.544597, 34.127378],
          [45.544052, 34.127099],
          [45.543266, 34.126281],
          [45.543052, 34.125964],
          [45.542794, 34.125258],
          [45.542685, 34.125102],
          [45.541953, 34.124861],
          [45.540434, 34.124638],
          [45.5395, 34.124395],
          [45.538495, 34.123953],
          [45.537587, 34.123305],
          [45.536689, 34.123038],
          [45.536116, 34.123008],
          [45.535993, 34.123076],
          [45.535935, 34.123223],
          [45.535955, 34.123384],
          [45.536068, 34.123509],
          [45.536133, 34.123801],
          [45.535976, 34.123932],
          [45.535742, 34.124],
          [45.535485, 34.123961],
          [45.535287, 34.123859],
          [45.535124, 34.123676],
          [45.534866, 34.123516],
          [45.533118, 34.122881],
          [45.531799, 34.122342],
          [45.531374, 34.122218],
          [45.530789, 34.121901],
          [45.530478, 34.121621],
          [45.530106, 34.121097],
          [45.529987, 34.120725],
          [45.52997, 34.119959],
          [45.530101, 34.119285],
          [45.530238, 34.119068],
          [45.531055, 34.118401],
          [45.531664, 34.118087],
          [45.532864, 34.117832],
          [45.53323, 34.117712],
          [45.533548, 34.117546],
          [45.53379, 34.117393],
          [45.53394, 34.117209],
          [45.533955, 34.117064],
          [45.533847, 34.116815],
          [45.532966, 34.116647],
          [45.532368, 34.116483],
          [45.531869, 34.116482],
          [45.531238, 34.116244],
          [45.531119, 34.116089],
          [45.531098, 34.115759],
          [45.530585, 34.114524],
          [45.530286, 34.114371],
          [45.529628, 34.114324],
          [45.528618, 34.114528],
          [45.528057, 34.114772],
          [45.527818, 34.115005],
          [45.527725, 34.115233],
          [45.527731, 34.11545],
          [45.528259, 34.116224],
          [45.528292, 34.11685],
          [45.52822, 34.117075],
          [45.528232, 34.117557],
          [45.52837, 34.117989],
          [45.528353, 34.118125],
          [45.528058, 34.1183],
          [45.527446, 34.11841],
          [45.526778, 34.118604],
          [45.525834, 34.118624],
          [45.525379, 34.118536],
          [45.524806, 34.118226],
          [45.523655, 34.117865],
          [45.523438, 34.117726],
          [45.523412, 34.117419],
          [45.523483, 34.117164],
          [45.523756, 34.116654],
          [45.523998, 34.115856],
          [45.524227, 34.115549],
          [45.524317, 34.115312],
          [45.524304, 34.115181],
          [45.523813, 34.114263],
          [45.52359, 34.114022],
          [45.523205, 34.113745],
          [45.522393, 34.113624],
          [45.522101, 34.113621],
          [45.521652, 34.11375],
          [45.521497, 34.113721],
          [45.521118, 34.11335],
          [45.521025, 34.113195],
          [45.520995, 34.112987],
          [45.52102, 34.111907],
          [45.521084, 34.111457],
          [45.521248, 34.111169],
          [45.521428, 34.111025],
          [45.521473, 34.110774],
          [45.521409, 34.110344],
          [45.521283, 34.110208],
          [45.521108, 34.110134],
          [45.520581, 34.11015],
          [45.520258, 34.110201],
          [45.51956, 34.110422],
          [45.519024, 34.110853],
          [45.518405, 34.111622],
          [45.518157, 34.111767],
          [45.516518, 34.112386],
          [45.515654, 34.112557],
          [45.515284, 34.112585],
          [45.515069, 34.112525],
          [45.514983, 34.112423],
          [45.514948, 34.112242],
          [45.515068, 34.111925],
          [45.515004, 34.111686],
          [45.514449, 34.110667],
          [45.514183, 34.110638],
          [45.513895, 34.110701],
          [45.513283, 34.110696],
          [45.512441, 34.110006],
          [45.511729, 34.109549],
          [45.511585, 34.109403],
          [45.511076, 34.108497],
          [45.51075, 34.108175],
          [45.510052, 34.107867],
          [45.509912, 34.107746],
          [45.509925, 34.107351],
          [45.509828, 34.107165],
          [45.509618, 34.10697],
          [45.50935, 34.106462],
          [45.509196, 34.106307],
          [45.508596, 34.106012],
          [45.508382, 34.105827],
          [45.508076, 34.105293],
          [45.50785, 34.104672],
          [45.507683, 34.104479],
          [45.507134, 34.104247],
          [45.506814, 34.104194],
          [45.505976, 34.104294],
          [45.505668, 34.104177],
          [45.505336, 34.104122],
          [45.504828, 34.103928],
          [45.504428, 34.103696],
          [45.503966, 34.103245],
          [45.503922, 34.103078],
          [45.504109, 34.102744],
          [45.504614, 34.102339],
          [45.504976, 34.101961],
          [45.505896, 34.100968],
          [45.506403, 34.100209],
          [45.506835, 34.0999],
          [45.50698, 34.099681],
          [45.506952, 34.098952],
          [45.506804, 34.098457],
          [45.50668, 34.098382],
          [45.50646, 34.098372],
          [45.505842, 34.098526],
          [45.505182, 34.098813],
          [45.50494, 34.098779],
          [45.504658, 34.09864],
          [45.503962, 34.098686],
          [45.503493, 34.098657],
          [45.503068, 34.098567],
          [45.501267, 34.097843],
          [45.49933, 34.096742],
          [45.498354, 34.095889],
          [45.49761, 34.095098],
          [45.497205, 34.094911],
          [45.496962, 34.094873],
          [45.496476, 34.094886],
          [45.49632, 34.094808],
          [45.496159, 34.094395],
          [45.496249, 34.094077],
          [45.496244, 34.093921],
          [45.496025, 34.093281],
          [45.496002, 34.092913],
          [45.496185, 34.092022],
          [45.496148, 34.091862],
          [45.495961, 34.091722],
          [45.495364, 34.091556],
          [45.494608, 34.091482],
          [45.494097, 34.091499],
          [45.493746, 34.091451],
          [45.493556, 34.091365],
          [45.493427, 34.091224],
          [45.493267, 34.090726],
          [45.493289, 34.088827],
          [45.493188, 34.088673],
          [45.492854, 34.088448],
          [45.492771, 34.088184],
          [45.492616, 34.087958],
          [45.492436, 34.08787],
          [45.491309, 34.087572],
          [45.490226, 34.087371],
          [45.48985, 34.087103],
          [45.489827, 34.086915],
          [45.489973, 34.086521],
          [45.490119, 34.086306],
          [45.49067, 34.085893],
          [45.491601, 34.085489],
          [45.492215, 34.085321],
          [45.494383, 34.084352],
          [45.495378, 34.083727],
          [45.495542, 34.0835],
          [45.49559, 34.083273],
          [45.495543, 34.083075],
          [45.495451, 34.082955],
          [45.494196, 34.082517],
          [45.492941, 34.082467],
          [45.492094, 34.082479],
          [45.491455, 34.082338],
          [45.490886, 34.082266],
          [45.490068, 34.082041],
          [45.489726, 34.081785],
          [45.489536, 34.08161],
          [45.489183, 34.080945],
          [45.488961, 34.079889],
          [45.488841, 34.079805],
          [45.488102, 34.079894],
          [45.487859, 34.079811],
          [45.487569, 34.079637],
          [45.48704, 34.079175],
          [45.486473, 34.078432],
          [45.486016, 34.078115],
          [45.485909, 34.077908],
          [45.485918, 34.077451],
          [45.485969, 34.077282],
          [45.486191, 34.076939],
          [45.486172, 34.076427],
          [45.485888, 34.075721],
          [45.485705, 34.075526],
          [45.485519, 34.075461],
          [45.485313, 34.075489],
          [45.484619, 34.075879],
          [45.484401, 34.076083],
          [45.484181, 34.076502],
          [45.484026, 34.077152],
          [45.483794, 34.077792],
          [45.483679, 34.077984],
          [45.483679, 34.078954],
          [45.483596, 34.079597],
          [45.483203, 34.080816],
          [45.482993, 34.080939],
          [45.482428, 34.081067],
          [45.482059, 34.08108],
          [45.481195, 34.080929],
          [45.480941, 34.080821],
          [45.480472, 34.080452],
          [45.479496, 34.080073],
          [45.479353, 34.079894],
          [45.479268, 34.079693],
          [45.479159, 34.079026],
          [45.479137, 34.07814],
          [45.479241, 34.076979],
          [45.47916, 34.076634],
          [45.478987, 34.076487],
          [45.478536, 34.07633],
          [45.477013, 34.076134],
          [45.476242, 34.075906],
          [45.474756, 34.075866],
          [45.474335, 34.075949],
          [45.473963, 34.075838],
          [45.473516, 34.075608],
          [45.47339, 34.075364],
          [45.473584, 34.0749],
          [45.473604, 34.074198],
          [45.473556, 34.073994],
          [45.473469, 34.07387],
          [45.473302, 34.073839],
          [45.472962, 34.073675],
          [45.472521, 34.072856],
          [45.472503, 34.072555],
          [45.472272, 34.071734],
          [45.471609, 34.071188],
          [45.471522, 34.07102],
          [45.471551, 34.070742],
          [45.472076, 34.070184],
          [45.472326, 34.069647],
          [45.472375, 34.069383],
          [45.472318, 34.069096],
          [45.472181, 34.068853],
          [45.47209, 34.068734],
          [45.471953, 34.068672],
          [45.471106, 34.068648],
          [45.470598, 34.068378],
          [45.470514, 34.06818],
          [45.4706, 34.068074],
          [45.470582, 34.067794],
          [45.470356, 34.06733],
          [45.46984, 34.066532],
          [45.469603, 34.06629],
          [45.469187, 34.06605],
          [45.468792, 34.066173],
          [45.468588, 34.06616],
          [45.46821, 34.06595],
          [45.467853, 34.065559],
          [45.467185, 34.064992],
          [45.466321, 34.064062],
          [45.466126, 34.06373],
          [45.466166, 34.063384],
          [45.466595, 34.063225],
          [45.46835, 34.06207],
          [45.470072, 34.061089],
          [45.470921, 34.06056],
          [45.472049, 34.05975],
          [45.472266, 34.059707],
          [45.472927, 34.059883],
          [45.473588, 34.05997],
          [45.475087, 34.05995],
          [45.477328, 34.05985],
          [45.477637, 34.059811],
          [45.477936, 34.05969],
          [45.478804, 34.059012],
          [45.478939, 34.058753],
          [45.479079, 34.05825],
          [45.479278, 34.057253],
          [45.478977, 34.056355],
          [45.478976, 34.05617],
          [45.479331, 34.055316],
          [45.479258, 34.054153],
          [45.479295, 34.053547],
          [45.479206, 34.053415],
          [45.478808, 34.0533],
          [45.478056, 34.052759],
          [45.477335, 34.052394],
          [45.476696, 34.051862],
          [45.475835, 34.051059],
          [45.475704, 34.050797],
          [45.475678, 34.050325],
          [45.475879, 34.049896],
          [45.476658, 34.049003],
          [45.477241, 34.048555],
          [45.478713, 34.047612],
          [45.479175, 34.04724],
          [45.47946, 34.046941],
          [45.479697, 34.046533],
          [45.479786, 34.046148],
          [45.479783, 34.045752],
          [45.479697, 34.045434],
          [45.479526, 34.045045],
          [45.479228, 34.044699],
          [45.47876, 34.044286],
          [45.4787, 34.044141],
          [45.478783, 34.043889],
          [45.478905, 34.043778],
          [45.479202, 34.04367],
          [45.479381, 34.043528],
          [45.479641, 34.043037],
          [45.479655, 34.042872],
          [45.479422, 34.042356],
          [45.479231, 34.042103],
          [45.479028, 34.041896],
          [45.478119, 34.041295],
          [45.477973, 34.041057],
          [45.477891, 34.040863],
          [45.477874, 34.040614],
          [45.477921, 34.040452],
          [45.478201, 34.040157],
          [45.478249, 34.040004],
          [45.478221, 34.039841],
          [45.478139, 34.039739],
          [45.477747, 34.039759],
          [45.477251, 34.039316],
          [45.477031, 34.039054],
          [45.476913, 34.038825],
          [45.47686, 34.038577],
          [45.476865, 34.037451],
          [45.477088, 34.036759],
          [45.477641, 34.035848],
          [45.477693, 34.035575],
          [45.477662, 34.035328],
          [45.47757, 34.035143],
          [45.477411, 34.035005],
          [45.476918, 34.034755],
          [45.475854, 34.034553],
          [45.475113, 34.034509],
          [45.474634, 34.03461],
          [45.473849, 34.034872],
          [45.473307, 34.035292],
          [45.472716, 34.035432],
          [45.472442, 34.035549],
          [45.471915, 34.035627],
          [45.471712, 34.035574],
          [45.471546, 34.035454],
          [45.471291, 34.035158],
          [45.470846, 34.034887],
          [45.469952, 34.034553],
          [45.469822, 34.034472],
          [45.469725, 34.034311],
          [45.469698, 34.034196],
          [45.469765, 34.034075],
          [45.470035, 34.033804],
          [45.472362, 34.033505],
          [45.472759, 34.033099],
          [45.473044, 34.032265],
          [45.473128, 34.031316],
          [45.473088, 34.030493],
          [45.473134, 34.029567],
          [45.473077, 34.029192],
          [45.472859, 34.02867],
          [45.47216, 34.027679],
          [45.471437, 34.02708],
          [45.470871, 34.026837],
          [45.470687, 34.026729],
          [45.470577, 34.026596],
          [45.470533, 34.026435],
          [45.470567, 34.026196],
          [45.47064, 34.025743],
          [45.470746, 34.025475],
          [45.47183, 34.02412],
          [45.471984, 34.024012],
          [45.472162, 34.023958],
          [45.472371, 34.02397],
          [45.472566, 34.024054],
          [45.474042, 34.025039],
          [45.474933, 34.025789],
          [45.475217, 34.025961],
          [45.4754, 34.025994],
          [45.475523, 34.025958],
          [45.475625, 34.025805],
          [45.475726, 34.025467],
          [45.475736, 34.025152],
          [45.475667, 34.024925],
          [45.475486, 34.024675],
          [45.474927, 34.023989],
          [45.47434, 34.023426],
          [45.474016, 34.023128],
          [45.473263, 34.022609],
          [45.472598, 34.02201],
          [45.472012, 34.021633],
          [45.471849, 34.021437],
          [45.471534, 34.020996],
          [45.471315, 34.020584],
          [45.47109, 34.019944],
          [45.470984, 34.019382],
          [45.470831, 34.019026],
          [45.470646, 34.018755],
          [45.470296, 34.018394],
          [45.470076, 34.018313],
          [45.469755, 34.018367],
          [45.469518, 34.019022],
          [45.469346, 34.019124],
          [45.46904, 34.01922],
          [45.468813, 34.019242],
          [45.468199, 34.019129],
          [45.467705, 34.0191],
          [45.467194, 34.018954],
          [45.466859, 34.018926],
          [45.466762, 34.018646],
          [45.466768, 34.018517],
          [45.467103, 34.017984],
          [45.467182, 34.017442],
          [45.467352, 34.017107],
          [45.467485, 34.016684],
          [45.467547, 34.016229],
          [45.467553, 34.01587],
          [45.467342, 34.015268],
          [45.467302, 34.015009],
          [45.467299, 34.014656],
          [45.467433, 34.014013],
          [45.467377, 34.013819],
          [45.467236, 34.013624],
          [45.46696, 34.013376],
          [45.466265, 34.012918],
          [45.46585, 34.012572],
          [45.465673, 34.012225],
          [45.465698, 34.012062],
          [45.465834, 34.011785],
          [45.466332, 34.011226],
          [45.466926, 34.010715],
          [45.467071, 34.010498],
          [45.467094, 34.010305],
          [45.466848, 34.009822],
          [45.466398, 34.009349],
          [45.464627, 34.008392],
          [45.463753, 34.008187],
          [45.463083, 34.008172],
          [45.462397, 34.008115],
          [45.461866, 34.007994],
          [45.461573, 34.008009],
          [45.460787, 34.007707],
          [45.46025, 34.007582],
          [45.45933, 34.007456],
          [45.459017, 34.007292],
          [45.458543, 34.007123],
          [45.458372, 34.00696],
          [45.458141, 34.006876],
          [45.457601, 34.00681],
          [45.456975, 34.006916],
          [45.454269, 34.006845],
          [45.45233, 34.006611],
          [45.452094, 34.00633],
          [45.451861, 34.005836],
          [45.451011, 34.004763],
          [45.450036, 34.003952],
          [45.449296, 34.003395],
          [45.448786, 34.003073],
          [45.448534, 34.002745],
          [45.448319, 34.002571],
          [45.447562, 34.002101],
          [45.446512, 34.001328],
          [45.446276, 34.001201],
          [45.445896, 34.001115],
          [45.445751, 34.000974],
          [45.445315, 34.000763],
          [45.444948, 34.000193],
          [45.444493, 33.999699],
          [45.444031, 33.999311],
          [45.443681, 33.998655],
          [45.442952, 33.998145],
          [45.442635, 33.998036],
          [45.441638, 33.997329],
          [45.441063, 33.997026],
          [45.440009, 33.996593],
          [45.439837, 33.996562],
          [45.439699, 33.996606],
          [45.439614, 33.996694],
          [45.438941, 33.997072],
          [45.438045, 33.997338],
          [45.437821, 33.997395],
          [45.437439, 33.997415],
          [45.437157, 33.997498],
          [45.436383, 33.997561],
          [45.435312, 33.997488],
          [45.434607, 33.997257],
          [45.434167, 33.996893],
          [45.434066, 33.996692],
          [45.434029, 33.996266],
          [45.433893, 33.996159],
          [45.433605, 33.996058],
          [45.433149, 33.996013],
          [45.432521, 33.99649],
          [45.431771, 33.996611],
          [45.431436, 33.996601],
          [45.431351, 33.996557],
          [45.431275, 33.996436],
          [45.430716, 33.996267],
          [45.430756, 33.995267],
          [45.430629, 33.994775],
          [45.430691, 33.993799],
          [45.43062, 33.993474],
          [45.430646, 33.993135],
          [45.430753, 33.992624],
          [45.431049, 33.992106],
          [45.431132, 33.991581],
          [45.431397, 33.990975],
          [45.431346, 33.990533],
          [45.431297, 33.99035],
          [45.431215, 33.99027],
          [45.430811, 33.990218],
          [45.429623, 33.990274],
          [45.42895, 33.990184],
          [45.428395, 33.990164],
          [45.427843, 33.990272],
          [45.427027, 33.990875],
          [45.426338, 33.991273],
          [45.425688, 33.991797],
          [45.425324, 33.992026],
          [45.424706, 33.991963],
          [45.423634, 33.991749],
          [45.423014, 33.991408],
          [45.422075, 33.990794],
          [45.421545, 33.990541],
          [45.42117, 33.9901],
          [45.420996, 33.989783],
          [45.420832, 33.989155],
          [45.420845, 33.988672],
          [45.420731, 33.98729],
          [45.42107, 33.986373],
          [45.421377, 33.98591],
          [45.421692, 33.98508],
          [45.422084, 33.984386],
          [45.422164, 33.984155],
          [45.422238, 33.983494],
          [45.422671, 33.982473],
          [45.422722, 33.982219],
          [45.422673, 33.981847],
          [45.42236, 33.981457],
          [45.421581, 33.980859],
          [45.4211, 33.98064],
          [45.419718, 33.979667],
          [45.419418, 33.979544],
          [45.41865, 33.979325],
          [45.418186, 33.97927],
          [45.417743, 33.979281],
          [45.417082, 33.979392],
          [45.416361, 33.979323],
          [45.415795, 33.979007],
          [45.415461, 33.978926],
          [45.413963, 33.978741],
          [45.412714, 33.978377],
          [45.411889, 33.978246],
          [45.411551, 33.978227],
          [45.410902, 33.978341],
          [45.410642, 33.978336],
          [45.407661, 33.977255],
        ],
      ],
    },
  },
  RazaviKhorasan: {
    properties: {
      value: "RazaviKhorasan",
      name: "استان خراسان رضوی, ایران",
      id: 19,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [56.221837, 34.74464],
          [56.226223, 34.726045],
          [56.223723, 34.722995],
          [56.228436, 34.711898],
          [56.227974, 34.710417],
          [56.241213, 34.704401],
          [56.241769, 34.704401],
          [56.242046, 34.704121],
          [56.242879, 34.704121],
          [56.243993, 34.703561],
          [56.264272, 34.698843],
          [56.265382, 34.698843],
          [56.271216, 34.697173],
          [56.272326, 34.697173],
          [56.274549, 34.696343],
          [56.275659, 34.696343],
          [56.277326, 34.695784],
          [56.278715, 34.695784],
          [56.282049, 34.695233],
          [56.291215, 34.694124],
          [56.292605, 34.693844],
          [56.296215, 34.693844],
          [56.297605, 34.693564],
          [56.299829, 34.693564],
          [56.301219, 34.693285],
          [56.305105, 34.693285],
          [56.306495, 34.693005],
          [56.309828, 34.693005],
          [56.312328, 34.692734],
          [56.313162, 34.692454],
          [56.318995, 34.692454],
          [56.377054, 34.686066],
          [56.383998, 34.685227],
          [56.388997, 34.685227],
          [56.395944, 34.684947],
          [56.399834, 34.684677],
          [56.402887, 34.684117],
          [56.407054, 34.684117],
          [56.407887, 34.683838],
          [56.410667, 34.683838],
          [56.410944, 34.683558],
          [56.411777, 34.683838],
          [56.412611, 34.683558],
          [56.415944, 34.683558],
          [56.418444, 34.683008],
          [56.419277, 34.683008],
          [56.419834, 34.682728],
          [56.420944, 34.682728],
          [56.421777, 34.682448],
          [56.42539, 34.682448],
          [56.432334, 34.681898],
          [56.436224, 34.682448],
          [56.438447, 34.682448],
          [56.446223, 34.683558],
          [56.451223, 34.684677],
          [56.454837, 34.684677],
          [56.45789, 34.684947],
          [56.458447, 34.685227],
          [56.461503, 34.685227],
          [56.462337, 34.685507],
          [56.463723, 34.685507],
          [56.464837, 34.685787],
          [56.466223, 34.685786],
          [56.467337, 34.686066],
          [56.469837, 34.686066],
          [56.472893, 34.686896],
          [56.473446, 34.686896],
          [56.477336, 34.688006],
          [56.479003, 34.688565],
          [56.480113, 34.688565],
          [56.480946, 34.688845],
          [56.482337, 34.688845],
          [56.485113, 34.689675],
          [56.487893, 34.690226],
          [56.560396, 34.715787],
          [56.566229, 34.717727],
          [56.611508, 34.732451],
          [56.625678, 34.740508],
          [56.635678, 34.744677],
          [56.637345, 34.744948],
          [56.637898, 34.745507],
          [56.644568, 34.747456],
          [56.645678, 34.747456],
          [56.646235, 34.747735],
          [56.649288, 34.747735],
          [56.666511, 34.752174],
          [56.672344, 34.754394],
          [56.73985, 34.782733],
          [56.745683, 34.78968],
          [56.747903, 34.793289],
          [56.749017, 34.796897],
          [56.749017, 34.797177],
          [56.751793, 34.805234],
          [56.753183, 34.806623],
          [56.75346, 34.807453],
          [56.782349, 34.829395],
          [56.78263, 34.829954],
          [56.78374, 34.830513],
          [56.79013, 34.837451],
          [56.795406, 34.845237],
          [56.797629, 34.847727],
          [56.798463, 34.848286],
          [56.801519, 34.853573],
          [56.808739, 34.863289],
          [56.809296, 34.864679],
          [56.817909, 34.876903],
          [56.829852, 34.890237],
          [56.835409, 34.894675],
          [56.849576, 34.914955],
          [56.850966, 34.918014],
          [56.851522, 34.919123],
          [56.851799, 34.920512],
          [56.852076, 34.921902],
          [56.844299, 34.935235],
          [56.839576, 34.937175],
          [56.829852, 34.938844],
          [56.824853, 34.937175],
          [56.821796, 34.936904],
          [56.820686, 34.937454],
          [56.819853, 34.939123],
          [56.820963, 34.941902],
          [56.821796, 34.944401],
          [56.824019, 34.946629],
          [56.827353, 34.948848],
          [56.833743, 34.954405],
          [56.864022, 34.973845],
          [56.864575, 34.974405],
          [56.879855, 34.979961],
          [56.895969, 34.98246],
          [56.901245, 34.98246],
          [56.902635, 34.98218],
          [56.905135, 34.98218],
          [56.906245, 34.981901],
          [56.910412, 34.981901],
          [56.916245, 34.981351],
          [56.919858, 34.981351],
          [56.924581, 34.981621],
          [56.925968, 34.981901],
          [56.929858, 34.981901],
          [56.931248, 34.98218],
          [56.933191, 34.98218],
          [56.944855, 34.979335],
          [56.953745, 34.981283],
          [56.954578, 34.981283],
          [56.955135, 34.981563],
          [56.956245, 34.981563],
          [56.969948, 34.997098],
          [56.982427, 35.002281],
          [57.001037, 35.006449],
          [57.00986, 35.005237],
          [57.040127, 35.012364],
          [57.048463, 35.016253],
          [57.051797, 35.017362],
          [57.053183, 35.018192],
          [57.055963, 35.019022],
          [57.132109, 35.061013],
          [57.162259, 35.071582],
          [57.166148, 35.072412],
          [57.168648, 35.073251],
          [57.172538, 35.073801],
          [57.222514, 35.0959],
          [57.225571, 35.09618],
          [57.237514, 35.097569],
          [57.239181, 35.097568],
          [57.26335, 35.100067],
          [57.271541, 35.100019],
          [57.275431, 35.100019],
          [57.278207, 35.100298],
          [57.284874, 35.100299],
          [57.287654, 35.100578],
          [57.291821, 35.100578],
          [57.294597, 35.100849],
          [57.298767, 35.100849],
          [57.30154, 35.101128],
          [57.31216, 35.102252],
          [57.339847, 35.105371],
          [57.353096, 35.110641],
          [57.369575, 35.106709],
          [57.381539, 35.116371],
          [57.389872, 35.114144],
          [57.392099, 35.113873],
          [57.404045, 35.111646],
          [57.452095, 35.1269],
          [57.460899, 35.122998],
          [57.464235, 35.116266],
          [57.466735, 35.115716],
          [57.484248, 35.093307],
          [57.486219, 35.077423],
          [57.491832, 35.06718],
          [57.495646, 35.054541],
          [57.503358, 35.039168],
          [57.509471, 35.037228],
          [57.526925, 35.012817],
          [57.528038, 35.012817],
          [57.528871, 35.012538],
          [57.530538, 35.012538],
          [57.530811, 35.012258],
          [57.531924, 35.012258],
          [57.532758, 35.012538],
          [57.533311, 35.012258],
          [57.534144, 35.012538],
          [57.536371, 35.012538],
          [57.538038, 35.011707],
          [57.542759, 35.011806],
          [57.559776, 35.015517],
          [57.571535, 35.008403],
          [57.590453, 34.998838],
          [57.614879, 34.994707],
          [57.644216, 34.978745],
          [57.655866, 34.971873],
          [57.670032, 34.961878],
          [57.672812, 34.960489],
          [57.673645, 34.959659],
          [57.676145, 34.958261],
          [57.679526, 34.955784],
          [57.690865, 34.947708],
          [57.695835, 34.938608],
          [57.700757, 34.931489],
          [57.704335, 34.926069],
          [57.72131, 34.923097],
          [57.743928, 34.923192],
          [57.759598, 34.914551],
          [57.762165, 34.908176],
          [57.762508, 34.895506],
          [57.75341, 34.879454],
          [57.755044, 34.854218],
          [57.760448, 34.854101],
          [57.765427, 34.856355],
          [57.794952, 34.856637],
          [57.824135, 34.867905],
          [57.865333, 34.865933],
          [57.950199, 34.890657],
          [57.949494, 34.890192],
          [57.949366, 34.890108],
          [57.948812, 34.890108],
          [57.942924, 34.874102],
          [57.924385, 34.849594],
          [57.928505, 34.820568],
          [57.917862, 34.787303],
          [57.902376, 34.759186],
          [57.90043, 34.758355],
          [57.88793, 34.751685],
          [57.887096, 34.750846],
          [57.882096, 34.747515],
          [57.87515, 34.741676],
          [57.87014, 34.724966],
          [57.857437, 34.705776],
          [57.856233, 34.674368],
          [57.855119, 34.672978],
          [57.85065, 34.660807],
          [57.846217, 34.654922],
          [57.845022, 34.618823],
          [57.841316, 34.582853],
          [57.841316, 34.582293],
          [57.839369, 34.579791],
          [57.838816, 34.578399],
          [57.838536, 34.577848],
          [57.838256, 34.577568],
          [57.836036, 34.573394],
          [57.829089, 34.565055],
          [57.807976, 34.541411],
          [57.807976, 34.540851],
          [57.795876, 34.530816],
          [57.789769, 34.524702],
          [57.789769, 34.524422],
          [57.787403, 34.520529],
          [57.787823, 34.520529],
          [57.785253, 34.511154],
          [57.812269, 34.499124],
          [57.812542, 34.498293],
          [57.818367, 34.49704],
          [57.818646, 34.496209],
          [57.823093, 34.489268],
          [57.820243, 34.449747],
          [57.805557, 34.435966],
          [57.806391, 34.434847],
          [57.801475, 34.425603],
          [57.801132, 34.408326],
          [57.795302, 34.398868],
          [57.792016, 34.385852],
          [57.792569, 34.385012],
          [57.774903, 34.366651],
          [57.774217, 34.366229],
          [57.76959, 34.359414],
          [57.7698, 34.356758],
          [57.767771, 34.354921],
          [57.76695, 34.354623],
          [57.771353, 34.349699],
          [57.771907, 34.34914],
          [57.772187, 34.34886],
          [57.845705, 34.344187],
          [57.846539, 34.344467],
          [57.8624, 34.342438],
          [57.864067, 34.342438],
          [57.864346, 34.342718],
          [57.86518, 34.342718],
          [57.865733, 34.342997],
          [57.867127, 34.342997],
          [57.86768, 34.343277],
          [57.873513, 34.343827],
          [57.87518, 34.343828],
          [57.875733, 34.343828],
          [57.876293, 34.344107],
          [57.881013, 34.344107],
          [57.881846, 34.344387],
          [57.882407, 34.344107],
          [57.88324, 34.344387],
          [57.894012, 34.337583],
          [57.894565, 34.337304],
          [57.902735, 34.322135],
          [57.903288, 34.321856],
          [57.904681, 34.321856],
          [57.907181, 34.321305],
          [57.909962, 34.320186],
          [57.916011, 34.32161],
          [57.916291, 34.32133],
          [57.926011, 34.31661],
          [57.926844, 34.31661],
          [57.928511, 34.316051],
          [57.929625, 34.316051],
          [57.930738, 34.315491],
          [57.931571, 34.315491],
          [57.933518, 34.314661],
          [57.934351, 34.314661],
          [57.952124, 34.306881],
          [57.952404, 34.306331],
          [57.952957, 34.306331],
          [57.967911, 34.293722],
          [57.968471, 34.293442],
          [58.002148, 34.282468],
          [58.013343, 34.278112],
          [58.013897, 34.277562],
          [58.033295, 34.284503],
          [58.034688, 34.283953],
          [58.035521, 34.283953],
          [58.037461, 34.283393],
          [58.038575, 34.283393],
          [58.039408, 34.283114],
          [58.044135, 34.282284],
          [58.089585, 34.254216],
          [58.105273, 34.244876],
          [58.133248, 34.237163],
          [58.136022, 34.237163],
          [58.136582, 34.236883],
          [58.144915, 34.236883],
          [58.146022, 34.236603],
          [58.153802, 34.236603],
          [58.155188, 34.236883],
          [58.157968, 34.236883],
          [58.159081, 34.237163],
          [58.159635, 34.236883],
          [58.160468, 34.237163],
          [58.164362, 34.237163],
          [58.165195, 34.237442],
          [58.178528, 34.239383],
          [58.179361, 34.239383],
          [58.179915, 34.239383],
          [58.180195, 34.239663],
          [58.181028, 34.239662],
          [58.181581, 34.239942],
          [58.196714, 34.231306],
          [58.216896, 34.224294],
          [58.236124, 34.239459],
          [58.236397, 34.24001],
          [58.239457, 34.244181],
          [58.239137, 34.261763],
          [58.24025, 34.262873],
          [58.247747, 34.26845],
          [58.248307, 34.269009],
          [58.248307, 34.26928],
          [58.272657, 34.264491],
          [58.291038, 34.282049],
          [58.275261, 34.297501],
          [58.267193, 34.306293],
          [58.276942, 34.310838],
          [58.277215, 34.311118],
          [58.277775, 34.311118],
          [58.278328, 34.310838],
          [58.282495, 34.310838],
          [58.283328, 34.310558],
          [58.303907, 34.312073],
          [58.303907, 34.311793],
          [58.305013, 34.311793],
          [58.32166, 34.315259],
          [58.322036, 34.314881],
          [58.322494, 34.31442],
          [58.345462, 34.319263],
          [58.350756, 34.318235],
          [58.359569, 34.311711],
          [58.36312, 34.306818],
          [58.3647, 34.301568],
          [58.362465, 34.29268],
          [58.39053, 34.273045],
          [58.393231, 34.263027],
          [58.397402, 34.262543],
          [58.403213, 34.256505],
          [58.403891, 34.252589],
          [58.410778, 34.2461],
          [58.417221, 34.218948],
          [58.418055, 34.218669],
          [58.418328, 34.218109],
          [58.426872, 34.208272],
          [58.434562, 34.17936],
          [58.435115, 34.177699],
          [58.443728, 34.159079],
          [58.457984, 34.14522],
          [58.461281, 34.137221],
          [58.462114, 34.136391],
          [58.462947, 34.136111],
          [58.455677, 34.119195],
          [58.462481, 34.10403],
          [58.463314, 34.10375],
          [58.464868, 34.086297],
          [58.465974, 34.086297],
          [58.466254, 34.086017],
          [58.468201, 34.085746],
          [58.470749, 34.085084],
          [58.472921, 34.084452],
          [58.475148, 34.083805],
          [58.480536, 34.081842],
          [58.498764, 34.080531],
          [58.512497, 34.090199],
          [58.524513, 34.0811],
          [58.542709, 34.076266],
          [58.556099, 34.058633],
          [58.573673, 34.0513],
          [58.600731, 34.056926],
          [58.623982, 34.057914],
          [58.634735, 34.063793],
          [58.644561, 34.073658],
          [58.646228, 34.074208],
          [58.647341, 34.074497],
          [58.648175, 34.074497],
          [58.650955, 34.076157],
          [58.655955, 34.083653],
          [58.656228, 34.083923],
          [58.657895, 34.087541],
          [58.669208, 34.087873],
          [58.672541, 34.09703],
          [58.684703, 34.105881],
          [58.686191, 34.108283],
          [58.694078, 34.106439],
          [58.705775, 34.099937],
          [58.707715, 34.099937],
          [58.708548, 34.100208],
          [58.711881, 34.099937],
          [58.712714, 34.099657],
          [58.713828, 34.099657],
          [58.714381, 34.099377],
          [58.715214, 34.099377],
          [58.716327, 34.098827],
          [58.718548, 34.098267],
          [58.748827, 34.089377],
          [58.808, 34.094657],
          [58.811333, 34.098547],
          [58.821333, 34.106327],
          [58.824946, 34.108268],
          [58.846893, 34.112988],
          [58.847726, 34.113268],
          [58.876313, 34.087962],
          [58.877706, 34.087682],
          [58.879092, 34.087682],
          [58.879646, 34.087402],
          [58.881039, 34.087402],
          [58.887706, 34.086012],
          [58.889372, 34.086012],
          [58.893812, 34.084901],
          [58.894925, 34.084901],
          [58.95813, 34.079394],
          [58.974178, 34.094941],
          [58.989458, 34.098831],
          [58.996451, 34.121184],
          [58.997284, 34.121743],
          [58.999231, 34.122294],
          [59.031191, 34.133806],
          [59.031471, 34.134077],
          [59.031744, 34.134356],
          [59.032304, 34.134636],
          [59.032304, 34.134916],
          [59.032857, 34.134916],
          [59.033137, 34.135186],
          [59.048137, 34.133806],
          [59.048971, 34.133526],
          [59.052577, 34.133526],
          [59.053137, 34.133246],
          [59.05369, 34.133526],
          [59.054524, 34.133246],
          [59.06675, 34.133246],
          [59.067857, 34.133526],
          [59.075917, 34.133526],
          [59.07675, 34.133246],
          [59.101903, 34.121878],
          [59.103289, 34.122158],
          [59.111349, 34.121607],
          [59.113289, 34.121048],
          [59.115516, 34.121048],
          [59.119956, 34.120488],
          [59.134254, 34.113795],
          [59.155235, 34.118548],
          [59.162462, 34.119098],
          [59.162736, 34.119378],
          [59.164129, 34.119378],
          [59.164402, 34.119658],
          [59.165795, 34.119658],
          [59.179916, 34.124595],
          [59.189912, 34.117521],
          [59.190465, 34.117521],
          [59.213469, 34.115854],
          [59.214029, 34.116134],
          [59.214582, 34.116134],
          [59.214862, 34.116414],
          [59.219582, 34.116965],
          [59.221529, 34.116134],
          [59.222362, 34.116134],
          [59.224862, 34.115024],
          [59.249028, 34.094464],
          [59.250142, 34.093904],
          [59.250415, 34.093354],
          [59.257642, 34.089182],
          [59.259028, 34.088631],
          [59.290794, 34.086094],
          [59.291907, 34.085539],
          [59.303014, 34.080541],
          [59.346633, 34.108267],
          [59.347193, 34.107987],
          [59.349413, 34.107987],
          [59.349966, 34.107707],
          [59.375332, 34.116892],
          [59.375885, 34.117172],
          [59.376446, 34.117172],
          [59.376719, 34.116892],
          [59.376999, 34.116622],
          [59.377279, 34.116342],
          [59.378112, 34.115512],
          [59.378112, 34.115232],
          [59.404449, 34.108678],
          [59.423675, 34.086787],
          [59.457321, 34.080531],
          [59.467277, 34.061762],
          [59.491653, 34.052375],
          [59.563237, 34.050374],
          [59.563237, 34.050094],
          [59.565183, 34.047315],
          [59.583323, 34.054931],
          [59.59749, 34.050483],
          [59.598876, 34.050483],
          [59.60277, 34.049924],
          [59.606656, 34.049924],
          [59.608323, 34.050203],
          [59.625549, 34.050203],
          [59.626656, 34.050483],
          [59.628049, 34.050483],
          [59.629156, 34.050203],
          [59.629989, 34.050483],
          [59.630549, 34.050203],
          [59.635969, 34.057599],
          [59.636803, 34.057319],
          [59.640416, 34.057319],
          [59.640969, 34.05704],
          [59.646936, 34.049094],
          [59.654352, 34.040028],
          [59.655186, 34.039477],
          [59.661859, 34.037528],
          [59.663526, 34.036698],
          [59.666026, 34.035868],
          [59.673245, 34.034479],
          [59.674632, 34.034479],
          [59.675745, 34.034199],
          [59.681935, 34.041596],
          [59.683048, 34.041316],
          [59.695548, 34.040206],
          [59.722441, 34.02911],
          [59.723548, 34.028831],
          [59.737004, 34.025416],
          [59.751171, 34.025416],
          [59.752557, 34.025696],
          [59.759224, 34.025696],
          [59.760617, 34.025976],
          [59.80638, 34.005486],
          [59.807213, 34.005766],
          [59.812767, 34.005766],
          [59.81332, 34.006046],
          [59.817487, 34.006317],
          [59.847547, 33.995804],
          [59.84838, 33.996084],
          [59.887279, 33.984959],
          [59.896166, 33.985509],
          [59.900893, 33.985509],
          [59.903946, 33.986069],
          [59.906166, 33.986069],
          [59.909779, 33.986629],
          [59.923392, 33.987459],
          [59.947005, 33.98773],
          [59.948112, 33.987459],
          [59.953112, 33.987459],
          [59.953945, 33.987179],
          [59.956725, 33.987179],
          [59.957559, 33.9869],
          [59.959778, 33.986629],
          [59.962278, 33.986629],
          [59.963111, 33.986349],
          [59.965058, 33.986349],
          [59.965338, 33.986069],
          [59.966725, 33.986069],
          [59.967285, 33.985789],
          [59.969225, 33.985509],
          [59.971172, 33.985509],
          [59.978118, 33.984399],
          [59.980058, 33.984128],
          [59.981171, 33.984128],
          [59.981452, 33.983848],
          [59.982558, 33.983848],
          [59.985792, 33.982923],
          [59.986171, 33.982737],
          [60.011944, 33.983263],
          [60.092468, 33.972976],
          [60.119933, 33.957031],
          [60.088056, 33.927705],
          [60.092143, 33.908964],
          [60.11469, 33.904755],
          [60.115243, 33.903645],
          [60.115803, 33.903366],
          [60.116356, 33.902256],
          [60.119409, 33.898366],
          [60.133673, 33.884107],
          [60.135242, 33.882538],
          [60.136909, 33.882818],
          [60.137469, 33.882818],
          [60.143856, 33.883928],
          [60.145522, 33.883928],
          [60.146076, 33.884207],
          [60.147742, 33.884207],
          [60.153582, 33.885868],
          [60.154416, 33.886427],
          [60.157189, 33.887537],
          [60.171082, 33.891706],
          [60.184142, 33.902796],
          [60.185808, 33.903355],
          [60.186362, 33.903905],
          [60.218308, 33.927789],
          [60.260568, 33.934095],
          [60.262508, 33.936036],
          [60.281295, 33.933107],
          [60.33348, 33.952474],
          [60.385278, 33.931828],
          [60.396945, 33.936551],
          [60.400551, 33.937382],
          [60.414998, 33.940995],
          [60.533076, 34.00025],
          [60.517603, 34.061757],
          [60.519342, 34.066273],
          [60.518075, 34.070365],
          [60.51788, 34.072411],
          [60.517977, 34.075236],
          [60.519439, 34.077282],
          [60.518757, 34.081179],
          [60.519147, 34.082641],
          [60.520413, 34.086051],
          [60.520316, 34.09024],
          [60.520998, 34.093261],
          [60.522459, 34.095502],
          [60.521095, 34.100958],
          [60.522654, 34.106511],
          [60.525674, 34.110019],
          [60.528305, 34.115377],
          [60.528597, 34.118787],
          [60.530867, 34.122647],
          [60.531618, 34.125412],
          [60.533761, 34.128433],
          [60.533859, 34.134181],
          [60.535125, 34.139637],
          [60.536392, 34.143632],
          [60.536976, 34.148698],
          [60.537422, 34.150697],
          [60.537991, 34.151212],
          [60.538363, 34.15184],
          [60.538645, 34.152204],
          [60.539007, 34.152323],
          [60.5399, 34.153008],
          [60.540809, 34.154076],
          [60.541025, 34.154717],
          [60.541289, 34.15517],
          [60.541832, 34.155828],
          [60.543006, 34.156298],
          [60.543292, 34.156559],
          [60.543409, 34.157441],
          [60.54382, 34.158399],
          [60.544884, 34.160104],
          [60.546077, 34.160466],
          [60.546719, 34.160967],
          [60.547109, 34.161365],
          [60.54802, 34.161922],
          [60.548589, 34.162359],
          [60.549418, 34.162901],
          [60.550573, 34.163371],
          [60.551236, 34.164082],
          [60.551847, 34.165385],
          [60.552249, 34.165871],
          [60.553875, 34.166998],
          [60.554707, 34.168353],
          [60.554832, 34.170323],
          [60.555394, 34.171126],
          [60.555569, 34.171677],
          [60.555514, 34.172402],
          [60.555807, 34.172746],
          [60.55607, 34.173517],
          [60.556518, 34.174452],
          [60.557455, 34.17597],
          [60.557639, 34.176199],
          [60.557658, 34.176567],
          [60.557999, 34.177489],
          [60.558159, 34.177698],
          [60.558242, 34.177995],
          [60.55851, 34.178499],
          [60.558746, 34.178725],
          [60.558773, 34.179007],
          [60.558636, 34.17934],
          [60.558641, 34.179654],
          [60.558887, 34.180163],
          [60.558886, 34.180485],
          [60.558538, 34.181745],
          [60.558256, 34.182089],
          [60.558119, 34.182517],
          [60.558286, 34.182797],
          [60.558572, 34.182935],
          [60.558916, 34.183538],
          [60.558912, 34.183874],
          [60.558846, 34.184107],
          [60.55934, 34.184823],
          [60.560024, 34.185332],
          [60.56067, 34.185693],
          [60.56109, 34.186065],
          [60.561441, 34.186613],
          [60.561845, 34.187612],
          [60.562257, 34.188304],
          [60.562629, 34.189086],
          [60.563501, 34.19001],
          [60.565261, 34.192074],
          [60.565496, 34.192557],
          [60.565676, 34.192708],
          [60.566147, 34.192799],
          [60.566573, 34.192786],
          [60.567065, 34.192893],
          [60.567522, 34.193112],
          [60.567988, 34.19342],
          [60.568494, 34.193571],
          [60.570696, 34.195585],
          [60.57199, 34.196867],
          [60.572697, 34.197463],
          [60.573477, 34.198268],
          [60.573887, 34.19877],
          [60.574326, 34.198905],
          [60.575368, 34.200042],
          [60.576014, 34.200248],
          [60.57677, 34.200385],
          [60.577765, 34.200883],
          [60.578171, 34.201315],
          [60.578553, 34.201496],
          [60.578999, 34.201628],
          [60.579738, 34.202442],
          [60.580202, 34.202718],
          [60.580632, 34.203423],
          [60.580968, 34.204227],
          [60.581084, 34.204663],
          [60.581633, 34.205335],
          [60.581626, 34.206246],
          [60.581927, 34.207174],
          [60.582415, 34.208358],
          [60.582812, 34.20885],
          [60.583481, 34.209106],
          [60.584044, 34.209449],
          [60.584483, 34.209869],
          [60.584796, 34.210841],
          [60.585105, 34.211402],
          [60.585601, 34.211818],
          [60.585831, 34.212179],
          [60.585931, 34.21266],
          [60.586545, 34.213094],
          [60.5872, 34.213307],
          [60.587616, 34.213355],
          [60.588539, 34.213804],
          [60.588709, 34.213799],
          [60.588945, 34.213862],
          [60.589708, 34.21382],
          [60.590055, 34.214027],
          [60.590143, 34.214187],
          [60.590453, 34.214471],
          [60.590592, 34.214482],
          [60.590977, 34.214771],
          [60.590997, 34.215028],
          [60.591083, 34.215145],
          [60.591468, 34.215337],
          [60.591654, 34.215492],
          [60.59175, 34.215903],
          [60.591746, 34.216401],
          [60.60229, 34.22639],
          [60.620695, 34.243825],
          [60.633958, 34.252868],
          [60.64059, 34.258294],
          [60.642629, 34.258951],
          [60.644407, 34.259816],
          [60.645202, 34.260147],
          [60.645864, 34.260778],
          [60.646716, 34.26107],
          [60.647652, 34.261249],
          [60.64867, 34.261371],
          [60.649524, 34.261287],
          [60.650294, 34.261615],
          [60.65094, 34.261955],
          [60.651254, 34.26212],
          [60.652607, 34.263176],
          [60.653456, 34.263742],
          [60.65478, 34.264476],
          [60.655171, 34.264901],
          [60.65546, 34.265381],
          [60.657025, 34.266399],
          [60.65735, 34.26711],
          [60.657587, 34.267258],
          [60.657954, 34.267302],
          [60.659029, 34.267235],
          [60.65968, 34.267426],
          [60.661672, 34.267809],
          [60.663668, 34.268786],
          [60.664366, 34.269084],
          [60.664996, 34.269618],
          [60.665939, 34.270763],
          [60.666498, 34.27113],
          [60.668959, 34.272035],
          [60.66949, 34.272504],
          [60.669679, 34.272857],
          [60.670531, 34.273789],
          [60.671018, 34.274473],
          [60.6713, 34.275018],
          [60.671421, 34.275392],
          [60.671481, 34.275952],
          [60.671443, 34.277219],
          [60.671478, 34.27783],
          [60.671647, 34.278862],
          [60.671616, 34.279389],
          [60.671253, 34.279574],
          [60.670894, 34.279996],
          [60.670076, 34.281337],
          [60.669904, 34.281867],
          [60.670017, 34.282671],
          [60.670137, 34.28315],
          [60.671032, 34.285968],
          [60.670956, 34.286574],
          [60.67163, 34.289187],
          [60.671705, 34.289315],
          [60.672506, 34.289963],
          [60.672781, 34.290704],
          [60.673001, 34.291879],
          [60.672799, 34.29307],
          [60.67301, 34.293743],
          [60.672032, 34.302754],
          [60.683032, 34.308633],
          [60.686496, 34.310708],
          [60.696298, 34.310742],
          [60.737434, 34.307223],
          [60.784324, 34.310058],
          [60.834018, 34.310593],
          [60.882301, 34.303396],
          [60.918373, 34.304756],
          [60.897928, 34.324435],
          [60.897209, 34.32447],
          [60.896348, 34.324638],
          [60.896256, 34.325084],
          [60.89628, 34.325435],
          [60.896177, 34.325554],
          [60.89602, 34.325901],
          [60.895609, 34.327118],
          [60.895162, 34.327661],
          [60.895114, 34.327844],
          [60.895222, 34.328838],
          [60.895552, 34.329663],
          [60.895889, 34.329988],
          [60.896126, 34.330093],
          [60.896321, 34.33031],
          [60.896519, 34.330843],
          [60.896515, 34.330991],
          [60.896418, 34.331084],
          [60.896218, 34.331151],
          [60.895706, 34.331618],
          [60.895885, 34.332768],
          [60.895667, 34.333301],
          [60.89545, 34.333568],
          [60.895282, 34.333712],
          [60.895069, 34.33375],
          [60.894855, 34.333588],
          [60.894609, 34.333379],
          [60.894124, 34.332767],
          [60.893768, 34.332152],
          [60.893392, 34.332026],
          [60.89302, 34.332058],
          [60.892615, 34.332001],
          [60.891384, 34.332051],
          [60.889923, 34.332619],
          [60.889228, 34.332965],
          [60.888963, 34.333121],
          [60.88883, 34.333397],
          [60.888279, 34.333821],
          [60.88772, 34.3339],
          [60.887111, 34.334163],
          [60.886427, 34.334764],
          [60.88581, 34.33508],
          [60.885547, 34.335314],
          [60.88549, 34.335557],
          [60.885172, 34.336213],
          [60.884743, 34.336585],
          [60.884829, 34.336873],
          [60.884637, 34.337223],
          [60.884655, 34.337392],
          [60.884879, 34.337687],
          [60.88535, 34.337875],
          [60.886021, 34.338218],
          [60.886493, 34.338551],
          [60.886551, 34.338689],
          [60.886533, 34.338994],
          [60.886309, 34.339606],
          [60.88601, 34.339967],
          [60.885636, 34.34028],
          [60.885579, 34.340428],
          [60.885374, 34.34046],
          [60.885053, 34.340789],
          [60.884934, 34.340827],
          [60.884317, 34.340713],
          [60.883865, 34.340739],
          [60.883698, 34.340828],
          [60.883621, 34.340931],
          [60.883605, 34.341146],
          [60.883363, 34.341308],
          [60.883296, 34.341417],
          [60.883221, 34.341669],
          [60.883033, 34.341991],
          [60.882884, 34.342573],
          [60.882934, 34.342794],
          [60.883053, 34.342991],
          [60.88313, 34.343496],
          [60.883115, 34.343762],
          [60.883044, 34.343954],
          [60.882832, 34.344268],
          [60.882823, 34.344506],
          [60.882943, 34.34497],
          [60.882706, 34.345242],
          [60.88216, 34.345634],
          [60.881894, 34.34595],
          [60.881552, 34.346687],
          [60.88137, 34.347275],
          [60.881314, 34.347877],
          [60.881097, 34.348308],
          [60.881021, 34.348134],
          [60.8811, 34.347659],
          [60.881017, 34.347423],
          [60.880803, 34.347124],
          [60.880323, 34.346992],
          [60.879891, 34.346803],
          [60.879266, 34.346778],
          [60.879122, 34.346804],
          [60.878733, 34.347194],
          [60.878344, 34.347256],
          [60.877599, 34.347255],
          [60.877116, 34.347501],
          [60.876647, 34.347542],
          [60.87622, 34.347322],
          [60.875766, 34.347268],
          [60.874213, 34.347791],
          [60.873756, 34.347714],
          [60.873546, 34.347737],
          [60.873159, 34.347841],
          [60.872367, 34.347925],
          [60.87168, 34.348169],
          [60.871173, 34.348205],
          [60.871119, 34.348253],
          [60.871107, 34.348762],
          [60.871328, 34.34917],
          [60.871165, 34.349311],
          [60.870672, 34.349489],
          [60.870213, 34.349736],
          [60.869843, 34.350063],
          [60.869387, 34.350379],
          [60.86918, 34.350599],
          [60.868998, 34.351389],
          [60.868831, 34.351459],
          [60.868433, 34.351446],
          [60.867978, 34.351608],
          [60.867577, 34.351882],
          [60.867485, 34.352025],
          [60.867471, 34.352226],
          [60.867602, 34.352597],
          [60.867599, 34.352757],
          [60.867522, 34.353902],
          [60.867454, 34.354432],
          [60.867331, 34.3547],
          [60.86735, 34.354871],
          [60.867438, 34.355187],
          [60.867528, 34.35594],
          [60.867925, 34.356538],
          [60.868089, 34.356923],
          [60.867967, 34.357341],
          [60.867688, 34.357781],
          [60.867553, 34.357848],
          [60.866977, 34.35796],
          [60.866873, 34.358122],
          [60.866682, 34.358843],
          [60.866648, 34.359337],
          [60.866729, 34.359582],
          [60.866703, 34.359828],
          [60.866638, 34.359959],
          [60.866412, 34.360229],
          [60.865904, 34.360118],
          [60.865742, 34.360142],
          [60.865206, 34.360597],
          [60.864972, 34.36065],
          [60.86458, 34.360177],
          [60.864361, 34.360145],
          [60.863606, 34.36046],
          [60.863436, 34.360568],
          [60.863157, 34.360859],
          [60.86302, 34.361051],
          [60.863128, 34.361403],
          [60.863114, 34.361715],
          [60.862789, 34.362625],
          [60.862773, 34.362785],
          [60.862875, 34.363075],
          [60.86281, 34.363347],
          [60.862718, 34.363425],
          [60.862153, 34.363493],
          [60.862001, 34.363656],
          [60.861839, 34.363677],
          [60.86135, 34.363523],
          [60.861216, 34.363526],
          [60.861028, 34.363739],
          [60.861261, 34.364294],
          [60.861535, 34.364667],
          [60.861722, 34.365178],
          [60.861714, 34.365393],
          [60.861604, 34.365454],
          [60.86078, 34.365477],
          [60.860625, 34.365566],
          [60.860469, 34.36582],
          [60.860462, 34.366258],
          [60.860411, 34.366356],
          [60.859693, 34.366359],
          [60.859273, 34.366492],
          [60.859224, 34.366586],
          [60.859143, 34.367348],
          [60.85893, 34.367702],
          [60.858834, 34.367783],
          [60.858531, 34.36776],
          [60.857176, 34.367884],
          [60.85658, 34.367852],
          [60.855985, 34.367942],
          [60.855011, 34.367854],
          [60.854229, 34.367919],
          [60.853923, 34.367987],
          [60.853466, 34.367994],
          [60.852514, 34.367959],
          [60.851906, 34.367834],
          [60.850855, 34.367905],
          [60.850088, 34.368068],
          [60.849798, 34.368217],
          [60.849631, 34.368448],
          [60.849552, 34.368837],
          [60.849302, 34.369298],
          [60.84887, 34.370411],
          [60.848547, 34.370733],
          [60.848311, 34.371566],
          [60.847872, 34.372138],
          [60.8479, 34.372325],
          [60.848172, 34.373021],
          [60.848487, 34.373777],
          [60.848704, 34.374151],
          [60.848838, 34.374644],
          [60.849186, 34.375187],
          [60.849283, 34.37633],
          [60.849752, 34.377088],
          [60.849885, 34.377979],
          [60.850123, 34.378823],
          [60.850143, 34.37971],
          [60.85007, 34.380362],
          [60.85026, 34.380765],
          [60.850057, 34.381226],
          [60.849864, 34.382122],
          [60.849786, 34.382199],
          [60.849626, 34.382239],
          [60.84863, 34.382284],
          [60.848429, 34.38256],
          [60.848475, 34.383334],
          [60.848387, 34.38342],
          [60.847742, 34.383749],
          [60.847539, 34.384003],
          [60.847094, 34.384271],
          [60.846922, 34.385567],
          [60.846833, 34.385844],
          [60.846562, 34.386319],
          [60.846383, 34.386504],
          [60.846342, 34.386803],
          [60.846406, 34.387161],
          [60.846715, 34.387531],
          [60.84677, 34.387664],
          [60.846782, 34.38815],
          [60.84672, 34.388345],
          [60.846497, 34.38854],
          [60.846038, 34.389107],
          [60.845898, 34.38935],
          [60.845526, 34.389677],
          [60.845453, 34.389822],
          [60.845365, 34.390279],
          [60.845919, 34.391202],
          [60.846072, 34.391617],
          [60.846062, 34.391701],
          [60.845741, 34.392108],
          [60.84546, 34.39224],
          [60.845241, 34.392516],
          [60.84445, 34.393185],
          [60.844279, 34.393253],
          [60.844024, 34.393207],
          [60.843867, 34.393252],
          [60.843575, 34.393453],
          [60.843514, 34.393647],
          [60.843684, 34.394272],
          [60.843704, 34.394407],
          [60.843653, 34.394567],
          [60.843077, 34.394697],
          [60.842895, 34.394781],
          [60.84265, 34.394931],
          [60.842321, 34.395293],
          [60.842322, 34.3955],
          [60.842141, 34.395985],
          [60.842237, 34.396238],
          [60.842413, 34.396467],
          [60.842292, 34.396704],
          [60.841432, 34.397313],
          [60.840365, 34.397751],
          [60.84003, 34.398003],
          [60.839882, 34.398373],
          [60.839699, 34.398608],
          [60.839662, 34.398782],
          [60.839719, 34.399014],
          [60.840053, 34.399636],
          [60.840093, 34.399952],
          [60.839557, 34.400275],
          [60.839164, 34.400381],
          [60.838638, 34.40101],
          [60.838627, 34.401124],
          [60.838804, 34.401518],
          [60.838769, 34.401684],
          [60.838616, 34.401803],
          [60.838169, 34.401954],
          [60.838074, 34.402041],
          [60.838103, 34.40237],
          [60.838289, 34.402496],
          [60.838651, 34.403053],
          [60.838753, 34.403117],
          [60.839318, 34.403183],
          [60.840387, 34.403463],
          [60.840937, 34.403783],
          [60.841111, 34.403965],
          [60.841367, 34.404506],
          [60.842705, 34.40504],
          [60.843687, 34.405856],
          [60.843833, 34.406143],
          [60.843893, 34.406706],
          [60.843873, 34.40705],
          [60.843754, 34.407548],
          [60.843599, 34.407853],
          [60.842749, 34.408339],
          [60.84244, 34.40872],
          [60.842043, 34.40956],
          [60.841881, 34.410356],
          [60.841701, 34.410641],
          [60.840972, 34.41105],
          [60.840119, 34.411228],
          [60.839814, 34.411398],
          [60.839603, 34.411719],
          [60.839172, 34.411708],
          [60.838599, 34.412024],
          [60.838305, 34.41229],
          [60.83806, 34.412382],
          [60.837562, 34.41247],
          [60.836761, 34.412391],
          [60.835723, 34.412655],
          [60.835501, 34.412824],
          [60.835383, 34.412998],
          [60.835229, 34.413402],
          [60.834877, 34.413748],
          [60.83468, 34.414022],
          [60.834591, 34.41434],
          [60.834475, 34.414447],
          [60.834007, 34.414478],
          [60.833654, 34.414649],
          [60.832984, 34.414749],
          [60.832599, 34.415013],
          [60.8322, 34.415195],
          [60.831626, 34.415245],
          [60.830901, 34.415409],
          [60.829791, 34.415857],
          [60.829733, 34.416101],
          [60.829851, 34.416517],
          [60.830164, 34.417291],
          [60.830508, 34.417778],
          [60.830551, 34.417967],
          [60.830447, 34.418532],
          [60.830404, 34.419169],
          [60.830454, 34.420072],
          [60.830015, 34.42013],
          [60.829861, 34.420206],
          [60.829554, 34.420394],
          [60.828952, 34.420916],
          [60.829082, 34.421378],
          [60.829062, 34.421507],
          [60.828956, 34.421659],
          [60.828844, 34.422315],
          [60.828682, 34.422409],
          [60.828492, 34.4224],
          [60.828159, 34.422283],
          [60.827622, 34.42264],
          [60.827637, 34.422933],
          [60.826762, 34.423126],
          [60.826635, 34.423236],
          [60.826603, 34.423353],
          [60.82694, 34.423776],
          [60.827261, 34.424585],
          [60.827308, 34.424977],
          [60.827278, 34.425283],
          [60.827495, 34.425669],
          [60.827512, 34.426035],
          [60.828181, 34.426566],
          [60.828257, 34.426675],
          [60.828034, 34.426775],
          [60.827902, 34.42698],
          [60.827874, 34.427412],
          [60.827808, 34.427541],
          [60.827239, 34.427921],
          [60.82722, 34.428035],
          [60.827295, 34.428341],
          [60.82725, 34.42847],
          [60.827433, 34.428762],
          [60.827414, 34.428826],
          [60.826937, 34.429053],
          [60.826113, 34.429053],
          [60.825609, 34.428624],
          [60.825455, 34.428592],
          [60.825221, 34.428785],
          [60.82507, 34.428826],
          [60.824572, 34.429102],
          [60.824004, 34.429203],
          [60.823391, 34.429142],
          [60.822808, 34.429275],
          [60.822457, 34.429279],
          [60.822148, 34.429559],
          [60.822255, 34.42993],
          [60.822086, 34.43051],
          [60.822112, 34.430822],
          [60.822228, 34.430921],
          [60.822227, 34.431235],
          [60.822103, 34.431407],
          [60.821739, 34.431678],
          [60.821348, 34.43186],
          [60.821064, 34.432256],
          [60.821183, 34.432484],
          [60.82115, 34.43298],
          [60.821272, 34.433268],
          [60.821364, 34.433692],
          [60.821234, 34.433979],
          [60.821223, 34.434147],
          [60.821279, 34.434291],
          [60.821612, 34.434472],
          [60.821876, 34.434683],
          [60.821739, 34.434984],
          [60.821682, 34.435326],
          [60.821721, 34.435837],
          [60.821196, 34.436185],
          [60.821331, 34.436605],
          [60.821487, 34.436784],
          [60.821506, 34.436876],
          [60.821305, 34.437213],
          [60.821189, 34.437762],
          [60.821248, 34.438108],
          [60.821666, 34.438339],
          [60.82173, 34.43852],
          [60.82189, 34.438719],
          [60.821506, 34.439513],
          [60.821365, 34.4396],
          [60.821249, 34.43991],
          [60.82128, 34.439998],
          [60.821222, 34.440224],
          [60.821156, 34.440333],
          [60.820998, 34.440408],
          [60.82103, 34.440689],
          [60.821201, 34.441111],
          [60.821367, 34.44124],
          [60.821625, 34.441661],
          [60.82169, 34.441862],
          [60.821639, 34.442314],
          [60.82124, 34.442531],
          [60.820816, 34.442897],
          [60.820647, 34.442868],
          [60.820458, 34.442899],
          [60.820113, 34.443038],
          [60.819761, 34.443688],
          [60.819719, 34.444321],
          [60.819147, 34.444483],
          [60.818896, 34.44434],
          [60.818304, 34.444488],
          [60.817877, 34.444739],
          [60.817099, 34.444959],
          [60.817045, 34.445051],
          [60.816607, 34.445279],
          [60.816527, 34.44538],
          [60.816367, 34.446284],
          [60.816142, 34.44676],
          [60.815622, 34.447592],
          [60.815685, 34.44785],
          [60.815656, 34.448253],
          [60.815405, 34.448366],
          [60.815302, 34.448524],
          [60.815099, 34.449242],
          [60.814725, 34.44995],
          [60.814567, 34.450472],
          [60.814585, 34.45089],
          [60.814508, 34.450817],
          [60.814489, 34.45054],
          [60.814361, 34.450349],
          [60.814062, 34.45022],
          [60.813668, 34.450693],
          [60.81313, 34.45157],
          [60.812992, 34.45174],
          [60.81285, 34.451831],
          [60.812205, 34.453348],
          [60.811957, 34.453599],
          [60.811729, 34.453968],
          [60.811601, 34.453962],
          [60.811348, 34.454217],
          [60.811215, 34.454785],
          [60.811396, 34.455261],
          [60.811088, 34.455565],
          [60.810792, 34.455751],
          [60.810696, 34.455924],
          [60.810424, 34.456093],
          [60.810188, 34.456533],
          [60.809671, 34.456823],
          [60.809584, 34.45702],
          [60.809553, 34.457356],
          [60.809583, 34.457805],
          [60.809144, 34.458333],
          [60.80894, 34.458685],
          [60.809045, 34.459171],
          [60.808932, 34.459256],
          [60.808811, 34.459557],
          [60.808812, 34.459693],
          [60.809023, 34.460319],
          [60.808642, 34.460408],
          [60.808555, 34.460519],
          [60.808474, 34.460911],
          [60.808374, 34.461131],
          [60.807977, 34.461219],
          [60.807639, 34.46145],
          [60.807459, 34.461703],
          [60.80739, 34.461911],
          [60.807362, 34.462389],
          [60.807435, 34.462496],
          [60.80747, 34.46273],
          [60.807454, 34.463055],
          [60.807608, 34.463268],
          [60.807727, 34.463348],
          [60.807854, 34.46373],
          [60.807655, 34.464124],
          [60.807563, 34.464505],
          [60.807415, 34.464736],
          [60.80739, 34.46495],
          [60.807601, 34.466344],
          [60.807704, 34.466704],
          [60.808064, 34.467548],
          [60.808645, 34.468636],
          [60.808756, 34.469008],
          [60.808711, 34.469515],
          [60.808613, 34.469721],
          [60.808642, 34.470057],
          [60.808858, 34.470381],
          [60.809271, 34.471493],
          [60.809274, 34.471653],
          [60.808798, 34.471701],
          [60.808545, 34.471846],
          [60.807819, 34.471973],
          [60.807416, 34.471888],
          [60.806705, 34.471912],
          [60.806441, 34.47201],
          [60.806357, 34.472122],
          [60.806073, 34.472262],
          [60.805708, 34.472162],
          [60.803932, 34.472194],
          [60.803754, 34.472311],
          [60.803585, 34.472892],
          [60.803372, 34.47305],
          [60.802743, 34.473018],
          [60.802393, 34.473087],
          [60.802088, 34.473226],
          [60.801661, 34.473509],
          [60.801542, 34.47396],
          [60.801142, 34.474348],
          [60.801075, 34.474626],
          [60.800906, 34.475008],
          [60.800831, 34.475064],
          [60.800364, 34.474966],
          [60.80024, 34.474866],
          [60.799737, 34.474766],
          [60.79923, 34.474186],
          [60.798949, 34.473942],
          [60.797746, 34.473151],
          [60.797528, 34.473042],
          [60.797128, 34.472947],
          [60.796075, 34.473385],
          [60.795587, 34.473502],
          [60.795337, 34.473683],
          [60.794946, 34.473812],
          [60.794446, 34.473779],
          [60.794209, 34.473806],
          [60.793964, 34.47392],
          [60.793602, 34.474238],
          [60.793479, 34.474412],
          [60.79338, 34.474864],
          [60.792778, 34.475289],
          [60.792452, 34.47541],
          [60.791904, 34.475395],
          [60.791716, 34.475431],
          [60.791046, 34.475696],
          [60.790596, 34.475822],
          [60.790455, 34.476061],
          [60.790219, 34.476202],
          [60.790074, 34.476355],
          [60.789936, 34.476961],
          [60.789762, 34.477471],
          [60.789617, 34.477702],
          [60.788204, 34.479516],
          [60.787299, 34.481016],
          [60.787096, 34.48147],
          [60.786463, 34.482361],
          [60.786546, 34.482717],
          [60.787014, 34.483541],
          [60.787014, 34.483671],
          [60.786485, 34.483774],
          [60.784613, 34.48375],
          [60.784307, 34.483781],
          [60.783791, 34.483919],
          [60.783585, 34.484065],
          [60.783185, 34.484485],
          [60.782969, 34.485179],
          [60.782883, 34.485362],
          [60.782734, 34.485498],
          [60.78253, 34.485536],
          [60.78177, 34.485479],
          [60.78114, 34.485161],
          [60.780835, 34.485257],
          [60.780411, 34.485484],
          [60.780206, 34.485523],
          [60.779687, 34.485479],
          [60.779424, 34.485857],
          [60.779077, 34.486356],
          [60.77898, 34.486566],
          [60.77849, 34.486623],
          [60.778181, 34.486705],
          [60.777797, 34.486709],
          [60.777212, 34.486396],
          [60.777104, 34.486392],
          [60.777055, 34.486326],
          [60.77663, 34.48631],
          [60.776112, 34.486475],
          [60.775715, 34.486686],
          [60.774981, 34.486922],
          [60.774813, 34.487152],
          [60.774483, 34.48742],
          [60.774236, 34.487789],
          [60.774182, 34.488012],
          [60.773695, 34.488553],
          [60.773602, 34.488718],
          [60.751371, 34.507627],
          [60.741861, 34.510827],
          [60.739083, 34.518086],
          [60.742346, 34.526945],
          [60.790337, 34.537761],
          [60.822836, 34.544868],
          [60.825974, 34.539144],
          [60.83095, 34.544876],
          [60.84865, 34.542077],
          [60.897272, 34.56726],
          [60.903606, 34.580335],
          [60.910524, 34.596911],
          [60.915095, 34.607009],
          [60.918034, 34.613896],
          [60.921434, 34.621316],
          [60.922214, 34.623018],
          [60.922306, 34.622878],
          [60.922848, 34.622432],
          [60.923185, 34.622264],
          [60.923434, 34.622231],
          [60.924173, 34.622262],
          [60.924961, 34.622179],
          [60.925485, 34.621959],
          [60.926355, 34.621497],
          [60.926526, 34.621535],
          [60.926876, 34.621424],
          [60.927289, 34.621367],
          [60.927646, 34.621383],
          [60.927917, 34.621346],
          [60.928187, 34.621188],
          [60.92871, 34.621089],
          [60.929401, 34.621432],
          [60.929556, 34.621436],
          [60.929666, 34.621401],
          [60.929897, 34.621185],
          [60.93003, 34.621131],
          [60.930266, 34.621157],
          [60.930645, 34.621086],
          [60.930703, 34.621182],
          [60.930654, 34.621366],
          [60.93069, 34.621484],
          [60.930936, 34.621898],
          [60.931029, 34.622002],
          [60.931343, 34.622142],
          [60.9318, 34.622076],
          [60.932474, 34.621876],
          [60.932662, 34.621742],
          [60.932814, 34.621511],
          [60.93298, 34.621451],
          [60.933582, 34.621527],
          [60.933941, 34.621717],
          [60.935302, 34.62154],
          [60.935707, 34.621819],
          [60.935923, 34.621876],
          [60.936511, 34.622236],
          [60.936791, 34.622464],
          [60.937039, 34.622843],
          [60.937444, 34.623014],
          [60.93777, 34.623044],
          [60.938147, 34.623152],
          [60.938299, 34.623837],
          [60.938541, 34.624089],
          [60.938789, 34.624207],
          [60.938931, 34.624383],
          [60.939049, 34.624383],
          [60.939032, 34.624099],
          [60.939072, 34.624012],
          [60.93949, 34.623577],
          [60.940163, 34.623183],
          [60.940386, 34.622839],
          [60.940398, 34.622315],
          [60.940298, 34.621995],
          [60.940346, 34.621878],
          [60.940757, 34.621773],
          [60.941412, 34.621826],
          [60.941822, 34.621916],
          [60.942267, 34.621834],
          [60.942633, 34.621849],
          [60.942793, 34.62187],
          [60.942914, 34.62196],
          [60.943369, 34.621985],
          [60.943873, 34.621837],
          [60.944075, 34.621484],
          [60.944429, 34.62124],
          [60.945096, 34.6212],
          [60.945882, 34.620945],
          [60.946214, 34.621024],
          [60.946565, 34.621028],
          [60.9469, 34.621197],
          [60.946951, 34.621022],
          [60.947158, 34.620737],
          [60.94727, 34.620618],
          [60.94748, 34.620555],
          [60.94883, 34.620614],
          [60.948997, 34.620667],
          [60.949146, 34.620833],
          [60.949385, 34.620972],
          [60.949605, 34.620903],
          [60.949819, 34.620678],
          [60.950001, 34.620596],
          [60.950325, 34.620562],
          [60.950508, 34.62046],
          [60.950953, 34.620386],
          [60.951072, 34.620289],
          [60.951362, 34.620165],
          [60.951981, 34.619996],
          [60.952342, 34.620035],
          [60.952679, 34.620139],
          [60.953089, 34.620332],
          [60.953292, 34.620384],
          [60.953364, 34.620358],
          [60.953354, 34.620161],
          [60.953154, 34.619941],
          [60.95288, 34.619446],
          [60.952891, 34.619073],
          [60.952943, 34.619012],
          [60.954024, 34.618622],
          [60.954812, 34.618612],
          [60.955276, 34.618522],
          [60.955564, 34.618309],
          [60.955808, 34.618019],
          [60.956509, 34.617403],
          [60.956933, 34.616827],
          [60.957133, 34.616715],
          [60.957415, 34.61667],
          [60.957575, 34.616675],
          [60.958055, 34.616942],
          [60.958322, 34.617251],
          [60.958705, 34.617572],
          [60.959121, 34.617739],
          [60.95957, 34.617833],
          [60.9597, 34.617921],
          [60.960223, 34.61845],
          [60.960387, 34.61926],
          [60.960543, 34.619409],
          [60.960805, 34.619422],
          [60.961321, 34.619161],
          [60.96148, 34.619132],
          [60.961952, 34.619168],
          [60.962251, 34.619296],
          [60.962458, 34.619316],
          [60.962544, 34.619307],
          [60.962825, 34.6191],
          [60.963561, 34.619094],
          [60.963936, 34.619185],
          [60.964095, 34.619293],
          [60.964375, 34.619894],
          [60.965126, 34.619756],
          [60.966728, 34.619935],
          [60.967264, 34.619857],
          [60.968087, 34.619934],
          [60.968591, 34.620076],
          [60.969367, 34.620446],
          [60.969912, 34.620869],
          [60.970249, 34.621026],
          [60.971409, 34.62063],
          [60.971811, 34.620115],
          [60.972235, 34.620006],
          [60.972886, 34.619926],
          [60.973992, 34.620225],
          [60.97487, 34.62079],
          [60.975053, 34.62108],
          [60.975293, 34.621261],
          [60.975501, 34.621327],
          [60.976534, 34.621476],
          [60.976804, 34.621576],
          [60.977235, 34.621617],
          [60.977416, 34.621549],
          [60.978275, 34.620797],
          [60.978453, 34.620718],
          [60.978626, 34.620715],
          [60.978845, 34.620807],
          [60.979089, 34.620947],
          [60.979262, 34.62113],
          [60.979441, 34.621181],
          [60.98008, 34.62114],
          [60.980409, 34.621176],
          [60.981149, 34.620871],
          [60.981239, 34.62073],
          [60.981229, 34.620477],
          [60.981543, 34.619407],
          [60.982116, 34.6193],
          [60.982285, 34.61933],
          [60.982592, 34.619759],
          [60.982858, 34.620036],
          [60.98306, 34.620144],
          [60.983204, 34.620135],
          [60.983717, 34.61994],
          [60.984492, 34.619758],
          [60.984687, 34.619816],
          [60.984934, 34.62],
          [60.984991, 34.620268],
          [60.98514, 34.620386],
          [60.985177, 34.620492],
          [60.985246, 34.620975],
          [60.985913, 34.621712],
          [61.000798, 34.642976],
          [61.000713, 34.643149],
          [61.000292, 34.643673],
          [61.000316, 34.64378],
          [61.000387, 34.643843],
          [61.000653, 34.643922],
          [61.000802, 34.644127],
          [61.000921, 34.64558],
          [61.001124, 34.646033],
          [61.001539, 34.646377],
          [61.00145, 34.646606],
          [61.001534, 34.646926],
          [61.001444, 34.647031],
          [61.001419, 34.647395],
          [61.001919, 34.648622],
          [61.00184, 34.648728],
          [61.001708, 34.648731],
          [61.001676, 34.648767],
          [61.00133, 34.649554],
          [61.000742, 34.650024],
          [61.000491, 34.650325],
          [61.000326, 34.650445],
          [60.999829, 34.650619],
          [60.999644, 34.6508],
          [60.999608, 34.651185],
          [60.999742, 34.652063],
          [60.9997, 34.652424],
          [60.999483, 34.65325],
          [60.999146, 34.65373],
          [60.999251, 34.654057],
          [60.999277, 34.654428],
          [60.99925, 34.655814],
          [61.000064, 34.684581],
          [61.000721, 34.69551],
          [61.002394, 34.706235],
          [61.002773, 34.708694],
          [61.002809, 34.709045],
          [61.002802, 34.709217],
          [61.003456, 34.709975],
          [61.003844, 34.711239],
          [61.003819, 34.711317],
          [61.004046, 34.711486],
          [61.00435, 34.711859],
          [61.004814, 34.712127],
          [61.005063, 34.712411],
          [61.005866, 34.712623],
          [61.006146, 34.712796],
          [61.006263, 34.713143],
          [61.006246, 34.713282],
          [61.006449, 34.713481],
          [61.006588, 34.713557],
          [61.00678, 34.713584],
          [61.007471, 34.714004],
          [61.007551, 34.714223],
          [61.007521, 34.715361],
          [61.007598, 34.715508],
          [61.008088, 34.71575],
          [61.008301, 34.716067],
          [61.008454, 34.716195],
          [61.008869, 34.71641],
          [61.0093, 34.717081],
          [61.009983, 34.717495],
          [61.010126, 34.717649],
          [61.010276, 34.718033],
          [61.010604, 34.718298],
          [61.010771, 34.718941],
          [61.01075, 34.719596],
          [61.010968, 34.72001],
          [61.010977, 34.720125],
          [61.010885, 34.720345],
          [61.01103, 34.720961],
          [61.01137, 34.721306],
          [61.011651, 34.721733],
          [61.011962, 34.722057],
          [61.012034, 34.722263],
          [61.012331, 34.722735],
          [61.012466, 34.722857],
          [61.01303, 34.723013],
          [61.013323, 34.723202],
          [61.013299, 34.723674],
          [61.012963, 34.724349],
          [61.012945, 34.725395],
          [61.013082, 34.726783],
          [61.013318, 34.727629],
          [61.013514, 34.728068],
          [61.013534, 34.728291],
          [61.01421, 34.729024],
          [61.014356, 34.72933],
          [61.014298, 34.729606],
          [61.014338, 34.730123],
          [61.014421, 34.730576],
          [61.014513, 34.730718],
          [61.01453, 34.731051],
          [61.014416, 34.73125],
          [61.014272, 34.731752],
          [61.014593, 34.732442],
          [61.014867, 34.732687],
          [61.015217, 34.732879],
          [61.016372, 34.733215],
          [61.016828, 34.733608],
          [61.017145, 34.733767],
          [61.017399, 34.733868],
          [61.018262, 34.734064],
          [61.01855, 34.734266],
          [61.01883, 34.734675],
          [61.018971, 34.735403],
          [61.019051, 34.735526],
          [61.019529, 34.735892],
          [61.019695, 34.736232],
          [61.019595, 34.73689],
          [61.01959, 34.737235],
          [61.019665, 34.737733],
          [61.019933, 34.738123],
          [61.020191, 34.73835],
          [61.020612, 34.738588],
          [61.02106, 34.738697],
          [61.022327, 34.738593],
          [61.022884, 34.738672],
          [61.023219, 34.738777],
          [61.023803, 34.739134],
          [61.023988, 34.739408],
          [61.024592, 34.740031],
          [61.024859, 34.740685],
          [61.025098, 34.740871],
          [61.025696, 34.741173],
          [61.02596, 34.741456],
          [61.026006, 34.74173],
          [61.025553, 34.742311],
          [61.025526, 34.742472],
          [61.025676, 34.743044],
          [61.025948, 34.743564],
          [61.026136, 34.744174],
          [61.02627, 34.744372],
          [61.02661, 34.744621],
          [61.026969, 34.744786],
          [61.027573, 34.74491],
          [61.028258, 34.744878],
          [61.028546, 34.744939],
          [61.028936, 34.745119],
          [61.029153, 34.745328],
          [61.029192, 34.745464],
          [61.029156, 34.745623],
          [61.029036, 34.745813],
          [61.028968, 34.746284],
          [61.029271, 34.746669],
          [61.029525, 34.746864],
          [61.030244, 34.747283],
          [61.030838, 34.74754],
          [61.031538, 34.74777],
          [61.032785, 34.748047],
          [61.033749, 34.748577],
          [61.034179, 34.748928],
          [61.034237, 34.749124],
          [61.034182, 34.749268],
          [61.034113, 34.749346],
          [61.033885, 34.74944],
          [61.033596, 34.749768],
          [61.033615, 34.749998],
          [61.033819, 34.750452],
          [61.03378, 34.750519],
          [61.0338, 34.750694],
          [61.034008, 34.751164],
          [61.034396, 34.751447],
          [61.035009, 34.751696],
          [61.035204, 34.751818],
          [61.03531, 34.75197],
          [61.035408, 34.752383],
          [61.035393, 34.752653],
          [61.035368, 34.752889],
          [61.035039, 34.753467],
          [61.035032, 34.753612],
          [61.035119, 34.753781],
          [61.035805, 34.753878],
          [61.036404, 34.754298],
          [61.036938, 34.754475],
          [61.03713, 34.754614],
          [61.037259, 34.754715],
          [61.037308, 34.754825],
          [61.037394, 34.755338],
          [61.037504, 34.755534],
          [61.037916, 34.755877],
          [61.038136, 34.756316],
          [61.038311, 34.75653],
          [61.03899, 34.757091],
          [61.039542, 34.757356],
          [61.039755, 34.75769],
          [61.04006, 34.757927],
          [61.041361, 34.758441],
          [61.041437, 34.758505],
          [61.041542, 34.758745],
          [61.041589, 34.759024],
          [61.041566, 34.759372],
          [61.041506, 34.759515],
          [61.04115, 34.759658],
          [61.041054, 34.759784],
          [61.041276, 34.760528],
          [61.041284, 34.760734],
          [61.041014, 34.76131],
          [61.040724, 34.761633],
          [61.040717, 34.761747],
          [61.040805, 34.761904],
          [61.041116, 34.76218],
          [61.041738, 34.763157],
          [61.04181, 34.763613],
          [61.04168, 34.763936],
          [61.041696, 34.76405],
          [61.042027, 34.764294],
          [61.042196, 34.764314],
          [61.043165, 34.764799],
          [61.04377, 34.766295],
          [61.044145, 34.766745],
          [61.04429, 34.767153],
          [61.044233, 34.767264],
          [61.044424, 34.767728],
          [61.044435, 34.768055],
          [61.044619, 34.768337],
          [61.044651, 34.768574],
          [61.044633, 34.769129],
          [61.044439, 34.769343],
          [61.044339, 34.769596],
          [61.044349, 34.76976],
          [61.044471, 34.76994],
          [61.044588, 34.769991],
          [61.045495, 34.770093],
          [61.04612, 34.770268],
          [61.046548, 34.770596],
          [61.046679, 34.770778],
          [61.04668, 34.770875],
          [61.046417, 34.771229],
          [61.046184, 34.771916],
          [61.046128, 34.772265],
          [61.046201, 34.773257],
          [61.046457, 34.773949],
          [61.047015, 34.774629],
          [61.047415, 34.775649],
          [61.047879, 34.776165],
          [61.048043, 34.776212],
          [61.048217, 34.776376],
          [61.048455, 34.776459],
          [61.048777, 34.776765],
          [61.048787, 34.777104],
          [61.048669, 34.777238],
          [61.04864, 34.777533],
          [61.048728, 34.777816],
          [61.049062, 34.778057],
          [61.049536, 34.778566],
          [61.049611, 34.778717],
          [61.049592, 34.779035],
          [61.049655, 34.779283],
          [61.049899, 34.77966],
          [61.050061, 34.77976],
          [61.050086, 34.779891],
          [61.050253, 34.780059],
          [61.050549, 34.780153],
          [61.05081, 34.780107],
          [61.051145, 34.780228],
          [61.051557, 34.78024],
          [61.05198, 34.780473],
          [61.052247, 34.780529],
          [61.052392, 34.78061],
          [61.052484, 34.780879],
          [61.052701, 34.780865],
          [61.053049, 34.781006],
          [61.053111, 34.781218],
          [61.053357, 34.781381],
          [61.053457, 34.781504],
          [61.053606, 34.781983],
          [61.053854, 34.782246],
          [61.054885, 34.782398],
          [61.054975, 34.782428],
          [61.055241, 34.782684],
          [61.055385, 34.783595],
          [61.054768, 34.783832],
          [61.054835, 34.784179],
          [61.054689, 34.785226],
          [61.054803, 34.785412],
          [61.055029, 34.785397],
          [61.055158, 34.785469],
          [61.055252, 34.785651],
          [61.055717, 34.786084],
          [61.055816, 34.786331],
          [61.055888, 34.786598],
          [61.055831, 34.78672],
          [61.055846, 34.787268],
          [61.055931, 34.78785],
          [61.055865, 34.787987],
          [61.055861, 34.78819],
          [61.055923, 34.788341],
          [61.05669, 34.788662],
          [61.057085, 34.789001],
          [61.057156, 34.789312],
          [61.057688, 34.789529],
          [61.057976, 34.789561],
          [61.05809, 34.789661],
          [61.058202, 34.790276],
          [61.058074, 34.790935],
          [61.058112, 34.791021],
          [61.058517, 34.791229],
          [61.058617, 34.791322],
          [61.059308, 34.792586],
          [61.059339, 34.7929],
          [61.059145, 34.79308],
          [61.05919, 34.7942],
          [61.059581, 34.794697],
          [61.059717, 34.794792],
          [61.060057, 34.794915],
          [61.060149, 34.795054],
          [61.060095, 34.795222],
          [61.059939, 34.795424],
          [61.059911, 34.795615],
          [61.060437, 34.795965],
          [61.060585, 34.796151],
          [61.060701, 34.796577],
          [61.060671, 34.796695],
          [61.060404, 34.796982],
          [61.060482, 34.797146],
          [61.060779, 34.797404],
          [61.060787, 34.797543],
          [61.060706, 34.797666],
          [61.060401, 34.797884],
          [61.060554, 34.798358],
          [61.061117, 34.798571],
          [61.061295, 34.798733],
          [61.061393, 34.7989],
          [61.061391, 34.799191],
          [61.061995, 34.7998],
          [61.062158, 34.800064],
          [61.062251, 34.800866],
          [61.0618, 34.801108],
          [61.061746, 34.801226],
          [61.061917, 34.801381],
          [61.061856, 34.801505],
          [61.06149, 34.80181],
          [61.061247, 34.802238],
          [61.061202, 34.802755],
          [61.061323, 34.803182],
          [61.062014, 34.804227],
          [61.062566, 34.804664],
          [61.062538, 34.804887],
          [61.062579, 34.804955],
          [61.062933, 34.805211],
          [61.063205, 34.80529],
          [61.06347, 34.805792],
          [61.063723, 34.806003],
          [61.064488, 34.806223],
          [61.064979, 34.806075],
          [61.065212, 34.806169],
          [61.065604, 34.806926],
          [61.065832, 34.807189],
          [61.06609, 34.807687],
          [61.066034, 34.808499],
          [61.066128, 34.808933],
          [61.066297, 34.809182],
          [61.066367, 34.809455],
          [61.06628, 34.809815],
          [61.06634, 34.810271],
          [61.066805, 34.810842],
          [61.066955, 34.811335],
          [61.067674, 34.811676],
          [61.068078, 34.81229],
          [61.068151, 34.812705],
          [61.068056, 34.813192],
          [61.068268, 34.814087],
          [61.068116, 34.814507],
          [61.067565, 34.814981],
          [61.067505, 34.815225],
          [61.067607, 34.815734],
          [61.067535, 34.815993],
          [61.067586, 34.816161],
          [61.067759, 34.816284],
          [61.068308, 34.816434],
          [61.068367, 34.816621],
          [61.068199, 34.817151],
          [61.068055, 34.817383],
          [61.067568, 34.818095],
          [61.067131, 34.818493],
          [61.067168, 34.818779],
          [61.067021, 34.819284],
          [61.066967, 34.819708],
          [61.067084, 34.820088],
          [61.067158, 34.820632],
          [61.066945, 34.821132],
          [61.067172, 34.821958],
          [61.067555, 34.82257],
          [61.067932, 34.823542],
          [61.067941, 34.824022],
          [61.06785, 34.824589],
          [61.06787, 34.825255],
          [61.068012, 34.825559],
          [61.068318, 34.825868],
          [61.068766, 34.827769],
          [61.068636, 34.828276],
          [61.068393, 34.828532],
          [61.067112, 34.829243],
          [61.066892, 34.829474],
          [61.066794, 34.829677],
          [61.067169, 34.83099],
          [61.067481, 34.831373],
          [61.067791, 34.832683],
          [61.067651, 34.833071],
          [61.067395, 34.833354],
          [61.065878, 34.833984],
          [61.064997, 34.834454],
          [61.064644, 34.834909],
          [61.064356, 34.835451],
          [61.064226, 34.836321],
          [61.063971, 34.836865],
          [61.063633, 34.837081],
          [61.06258, 34.837494],
          [61.062474, 34.837682],
          [61.062515, 34.837904],
          [61.063134, 34.839029],
          [61.063761, 34.839741],
          [61.065014, 34.84078],
          [61.065314, 34.841226],
          [61.0657, 34.841533],
          [61.066463, 34.841778],
          [61.06749, 34.841929],
          [61.068177, 34.841905],
          [61.069823, 34.841532],
          [61.070346, 34.841515],
          [61.071, 34.84165],
          [61.073156, 34.842323],
          [61.074026, 34.842763],
          [61.074625, 34.843494],
          [61.075061, 34.844264],
          [61.075205, 34.844972],
          [61.075097, 34.845454],
          [61.074815, 34.845972],
          [61.074058, 34.846671],
          [61.073122, 34.847386],
          [61.072336, 34.848212],
          [61.071507, 34.848995],
          [61.070576, 34.849509],
          [61.069707, 34.850184],
          [61.06895, 34.850819],
          [61.068582, 34.851283],
          [61.068522, 34.851713],
          [61.068579, 34.852179],
          [61.068573, 34.852855],
          [61.068719, 34.854221],
          [61.069047, 34.855602],
          [61.069363, 34.856509],
          [61.069481, 34.85724],
          [61.069431, 34.857904],
          [61.069538, 34.85869],
          [61.069666, 34.859033],
          [61.069719, 34.859636],
          [61.06965, 34.860081],
          [61.069081, 34.860886],
          [61.068882, 34.861281],
          [61.069083, 34.862167],
          [61.068522, 34.8633],
          [61.068423, 34.863866],
          [61.068561, 34.864505],
          [61.068876, 34.865026],
          [61.069926, 34.865854],
          [61.070641, 34.866242],
          [61.070883, 34.866686],
          [61.070779, 34.867215],
          [61.070569, 34.867694],
          [61.069815, 34.868356],
          [61.069949, 34.869343],
          [61.070114, 34.869786],
          [61.070489, 34.870174],
          [61.071095, 34.87033],
          [61.0731, 34.870519],
          [61.073858, 34.870678],
          [61.074825, 34.870739],
          [61.075633, 34.870889],
          [61.076308, 34.871154],
          [61.076852, 34.871603],
          [61.077454, 34.872218],
          [61.078009, 34.873203],
          [61.078892, 34.875047],
          [61.080628, 34.875928],
          [61.080891, 34.876279],
          [61.080661, 34.876776],
          [61.080534, 34.877275],
          [61.080132, 34.878005],
          [61.079815, 34.878237],
          [61.079166, 34.878585],
          [61.07871, 34.878955],
          [61.078384, 34.879347],
          [61.077791, 34.880421],
          [61.077291, 34.880985],
          [61.076679, 34.881479],
          [61.075842, 34.881879],
          [61.075155, 34.881998],
          [61.073965, 34.881795],
          [61.072925, 34.881753],
          [61.072245, 34.881787],
          [61.069945, 34.882107],
          [61.068946, 34.88208],
          [61.068318, 34.882159],
          [61.067635, 34.8825],
          [61.067509, 34.88291],
          [61.067432, 34.883452],
          [61.066912, 34.88443],
          [61.066461, 34.884935],
          [61.066326, 34.885356],
          [61.066255, 34.885856],
          [61.065471, 34.887341],
          [61.065348, 34.888083],
          [61.065382, 34.888853],
          [61.065737, 34.88977],
          [61.065978, 34.890126],
          [61.067264, 34.8936],
          [61.067346, 34.89427],
          [61.067265, 34.894868],
          [61.067017, 34.895174],
          [61.066305, 34.895595],
          [61.064268, 34.897043],
          [61.063434, 34.897782],
          [61.062821, 34.898138],
          [61.061859, 34.898582],
          [61.06102, 34.898909],
          [61.05996, 34.899485],
          [61.05896, 34.899779],
          [61.058108, 34.900429],
          [61.057264, 34.901209],
          [61.056726, 34.901914],
          [61.056299, 34.902733],
          [61.055835, 34.904285],
          [61.055943, 34.904862],
          [61.056459, 34.90588],
          [61.056967, 34.906687],
          [61.057683, 34.907504],
          [61.059004, 34.908482],
          [61.060468, 34.909426],
          [61.061085, 34.909771],
          [61.062307, 34.909902],
          [61.063063, 34.909898],
          [61.064037, 34.909618],
          [61.06486, 34.909321],
          [61.065664, 34.90889],
          [61.066851, 34.908021],
          [61.067395, 34.907565],
          [61.068328, 34.90704],
          [61.068821, 34.906891],
          [61.069619, 34.907038],
          [61.070365, 34.907437],
          [61.071108, 34.907595],
          [61.072229, 34.907732],
          [61.073211, 34.908002],
          [61.073857, 34.908623],
          [61.074152, 34.909435],
          [61.073938, 34.909892],
          [61.073422, 34.910586],
          [61.073163, 34.91106],
          [61.072521, 34.911988],
          [61.071751, 34.912945],
          [61.070548, 34.913624],
          [61.069371, 34.913999],
          [61.066566, 34.914179],
          [61.065434, 34.91411],
          [61.064665, 34.914323],
          [61.06398, 34.914673],
          [61.062989, 34.915313],
          [61.062477, 34.915891],
          [61.062268, 34.916383],
          [61.062174, 34.916794],
          [61.062264, 34.917265],
          [61.062533, 34.917793],
          [61.062937, 34.918336],
          [61.063579, 34.918876],
          [61.064456, 34.919317],
          [61.065765, 34.919859],
          [61.066486, 34.920027],
          [61.067387, 34.920108],
          [61.06846, 34.920121],
          [61.069157, 34.920212],
          [61.070088, 34.920544],
          [61.071057, 34.921251],
          [61.071645, 34.922115],
          [61.071934, 34.922723],
          [61.072084, 34.923728],
          [61.072026, 34.925064],
          [61.071827, 34.926475],
          [61.071991, 34.927729],
          [61.071868, 34.929477],
          [61.072033, 34.930112],
          [61.072492, 34.930725],
          [61.07312, 34.931193],
          [61.07394, 34.931543],
          [61.075141, 34.931671],
          [61.075811, 34.931802],
          [61.076483, 34.932068],
          [61.077034, 34.932703],
          [61.077587, 34.93364],
          [61.078286, 34.934453],
          [61.079105, 34.935173],
          [61.079553, 34.935903],
          [61.079774, 34.936471],
          [61.079892, 34.937053],
          [61.079863, 34.937524],
          [61.079941, 34.937787],
          [61.081844, 34.939069],
          [61.082684, 34.939721],
          [61.083066, 34.940061],
          [61.083638, 34.940825],
          [61.083992, 34.941145],
          [61.084818, 34.941669],
          [61.085111, 34.941957],
          [61.085295, 34.942353],
          [61.08549, 34.943138],
          [61.085398, 34.943804],
          [61.085458, 34.944957],
          [61.085309, 34.945542],
          [61.085001, 34.945987],
          [61.08474, 34.946611],
          [61.083719, 34.948674],
          [61.083496, 34.949558],
          [61.083462, 34.950015],
          [61.083507, 34.950546],
          [61.083628, 34.951059],
          [61.08406, 34.951611],
          [61.084939, 34.952367],
          [61.085432, 34.952681],
          [61.087278, 34.95338],
          [61.087863, 34.953547],
          [61.08863, 34.953617],
          [61.089203, 34.953555],
          [61.090567, 34.953098],
          [61.09108, 34.952701],
          [61.091472, 34.952471],
          [61.092313, 34.951544],
          [61.092941, 34.9512],
          [61.093339, 34.951107],
          [61.094993, 34.951132],
          [61.096086, 34.950895],
          [61.096432, 34.950934],
          [61.096816, 34.95112],
          [61.097467, 34.951861],
          [61.097682, 34.952707],
          [61.0982, 34.953575],
          [61.098405, 34.954121],
          [61.098517, 34.954796],
          [61.098408, 34.95538],
          [61.097578, 34.956337],
          [61.097394, 34.956881],
          [61.096969, 34.957294],
          [61.096696, 34.95775],
          [61.096521, 34.959224],
          [61.097063, 34.960706],
          [61.097179, 34.961345],
          [61.097189, 34.96233],
          [61.09691, 34.963417],
          [61.096856, 34.963935],
          [61.097113, 34.964293],
          [61.097365, 34.964431],
          [61.098258, 34.964763],
          [61.099458, 34.965379],
          [61.100443, 34.965995],
          [61.100829, 34.966507],
          [61.101017, 34.967227],
          [61.101068, 34.967626],
          [61.101228, 34.968143],
          [61.101594, 34.968812],
          [61.101819, 34.96988],
          [61.10171, 34.970194],
          [61.101522, 34.970497],
          [61.100746, 34.971234],
          [61.100487, 34.971759],
          [61.100269, 34.972027],
          [61.099845, 34.972216],
          [61.099272, 34.972032],
          [61.099092, 34.972085],
          [61.097787, 34.972974],
          [61.095761, 34.974158],
          [61.095639, 34.974839],
          [61.095691, 34.975292],
          [61.096651, 34.976574],
          [61.096752, 34.97718],
          [61.096692, 34.97765],
          [61.09594, 34.978345],
          [61.095612, 34.979175],
          [61.095682, 34.979497],
          [61.096069, 34.979591],
          [61.096788, 34.979727],
          [61.097184, 34.979748],
          [61.097971, 34.979641],
          [61.098277, 34.979507],
          [61.098641, 34.979197],
          [61.098841, 34.978744],
          [61.099426, 34.978466],
          [61.100095, 34.97834],
          [61.100596, 34.978321],
          [61.103186, 34.978843],
          [61.103744, 34.978915],
          [61.105675, 34.979397],
          [61.106245, 34.979678],
          [61.10703, 34.980284],
          [61.107438, 34.980743],
          [61.10788, 34.982106],
          [61.10763, 34.98318],
          [61.107252, 34.983935],
          [61.106774, 34.98449],
          [61.106292, 34.984935],
          [61.105591, 34.985825],
          [61.105004, 34.986158],
          [61.104459, 34.98638],
          [61.103687, 34.986583],
          [61.10313, 34.98698],
          [61.102756, 34.987471],
          [61.10264, 34.987771],
          [61.102616, 34.988324],
          [61.102736, 34.988958],
          [61.102749, 34.989481],
          [61.102611, 34.990054],
          [61.102188, 34.990947],
          [61.101999, 34.99199],
          [61.102301, 34.993064],
          [61.103031, 34.994767],
          [61.104199, 34.996717],
          [61.104357, 34.997247],
          [61.104544, 34.997465],
          [61.105014, 34.99777],
          [61.105631, 34.998301],
          [61.106453, 34.99875],
          [61.107415, 34.999552],
          [61.107741, 35.00008],
          [61.108024, 35.000422],
          [61.108897, 35.000931],
          [61.109751, 35.001319],
          [61.110793, 35.001624],
          [61.11172, 35.001743],
          [61.112581, 35.001681],
          [61.113761, 35.001687],
          [61.115223, 35.001221],
          [61.116405, 35.000975],
          [61.117775, 35.001112],
          [61.118333, 35.001263],
          [61.120314, 35.00204],
          [61.12101, 35.002528],
          [61.121411, 35.003062],
          [61.121625, 35.003515],
          [61.121788, 35.004703],
          [61.12175, 35.00503],
          [61.121491, 35.00556],
          [61.121087, 35.005951],
          [61.119844, 35.006179],
          [61.11565, 35.006273],
          [61.113961, 35.007413],
          [61.112881, 35.008306],
          [61.112691, 35.010369],
          [61.112567, 35.011003],
          [61.112524, 35.012824],
          [61.112141, 35.014094],
          [61.11153, 35.015271],
          [61.111125, 35.015918],
          [61.110078, 35.017235],
          [61.109328, 35.0184],
          [61.108478, 35.019049],
          [61.105294, 35.020691],
          [61.102814, 35.021779],
          [61.10052, 35.023246],
          [61.099881, 35.023816],
          [61.099702, 35.024205],
          [61.099759, 35.024785],
          [61.100206, 35.025682],
          [61.100693, 35.026379],
          [61.10098, 35.027278],
          [61.101031, 35.027684],
          [61.101416, 35.028071],
          [61.101807, 35.02809],
          [61.102058, 35.027988],
          [61.103221, 35.028379],
          [61.103713, 35.028768],
          [61.104268, 35.02902],
          [61.105038, 35.029211],
          [61.105972, 35.029263],
          [61.107539, 35.029655],
          [61.108665, 35.030129],
          [61.109483, 35.031078],
          [61.110361, 35.032276],
          [61.110732, 35.033199],
          [61.110743, 35.034869],
          [61.11044, 35.036956],
          [61.110878, 35.037628],
          [61.111338, 35.037833],
          [61.11219, 35.037952],
          [61.11302, 35.037885],
          [61.114271, 35.037489],
          [61.115275, 35.037386],
          [61.116185, 35.03796],
          [61.116746, 35.038556],
          [61.116705, 35.042758],
          [61.116809, 35.04368],
          [61.116287, 35.044572],
          [61.115135, 35.045681],
          [61.114496, 35.046602],
          [61.113656, 35.04761],
          [61.113039, 35.048829],
          [61.111713, 35.050731],
          [61.110934, 35.052576],
          [61.11037, 35.054139],
          [61.110326, 35.055369],
          [61.110572, 35.057271],
          [61.111194, 35.059421],
          [61.1117, 35.060622],
          [61.11231, 35.061334],
          [61.113033, 35.06168],
          [61.114581, 35.06185],
          [61.11619, 35.061506],
          [61.117647, 35.060364],
          [61.11826, 35.059282],
          [61.118978, 35.058781],
          [61.12089, 35.058961],
          [61.12559, 35.058395],
          [61.125863, 35.058524],
          [61.125996, 35.058858],
          [61.126387, 35.060489],
          [61.126598, 35.062675],
          [61.126221, 35.065033],
          [61.125425, 35.066554],
          [61.124127, 35.067799],
          [61.12309, 35.068281],
          [61.120462, 35.069069],
          [61.119808, 35.069456],
          [61.119108, 35.069958],
          [61.117761, 35.070695],
          [61.117325, 35.071142],
          [61.117081, 35.071946],
          [61.117072, 35.072717],
          [61.117244, 35.073585],
          [61.117421, 35.073975],
          [61.118131, 35.07464],
          [61.118772, 35.07514],
          [61.119393, 35.075327],
          [61.121833, 35.075723],
          [61.123332, 35.075607],
          [61.124499, 35.075425],
          [61.127063, 35.075489],
          [61.12873, 35.075273],
          [61.130176, 35.075002],
          [61.131712, 35.074542],
          [61.133063, 35.074211],
          [61.135061, 35.073529],
          [61.137309, 35.073001],
          [61.138139, 35.072758],
          [61.13948, 35.072695],
          [61.140979, 35.073357],
          [61.141944, 35.074176],
          [61.142664, 35.075226],
          [61.14285, 35.075976],
          [61.142871, 35.076594],
          [61.143378, 35.077192],
          [61.144047, 35.07773],
          [61.144474, 35.07836],
          [61.145245, 35.079184],
          [61.145623, 35.079698],
          [61.145771, 35.080611],
          [61.145566, 35.082557],
          [61.145356, 35.083504],
          [61.144381, 35.085194],
          [61.143586, 35.086042],
          [61.14242, 35.086968],
          [61.141201, 35.087784],
          [61.137328, 35.089664],
          [61.136248, 35.090336],
          [61.134825, 35.091495],
          [61.133743, 35.091967],
          [61.132712, 35.092101],
          [61.131844, 35.091945],
          [61.131237, 35.092111],
          [61.12995, 35.092831],
          [61.128493, 35.093496],
          [61.127747, 35.093782],
          [61.126247, 35.094962],
          [61.126077, 35.095384],
          [61.126057, 35.09568],
          [61.126248, 35.096077],
          [61.126467, 35.096313],
          [61.126795, 35.09647],
          [61.127213, 35.096981],
          [61.127562, 35.097541],
          [61.127948, 35.097868],
          [61.128594, 35.09817],
          [61.129075, 35.098452],
          [61.129872, 35.098788],
          [61.132096, 35.099239],
          [61.132798, 35.09955],
          [61.133328, 35.099947],
          [61.133753, 35.100135],
          [61.13436, 35.100315],
          [61.135544, 35.100521],
          [61.136036, 35.100433],
          [61.136883, 35.100493],
          [61.137267, 35.100745],
          [61.138123, 35.101013],
          [61.139069, 35.101389],
          [61.139845, 35.10182],
          [61.140424, 35.102418],
          [61.141005, 35.103246],
          [61.141347, 35.104039],
          [61.141486, 35.104899],
          [61.141374, 35.10566],
          [61.141248, 35.106133],
          [61.140463, 35.107281],
          [61.139885, 35.107818],
          [61.138931, 35.108388],
          [61.138169, 35.108576],
          [61.137503, 35.108569],
          [61.136252, 35.108226],
          [61.135466, 35.10787],
          [61.134856, 35.107482],
          [61.134805, 35.107264],
          [61.134723, 35.107179],
          [61.134365, 35.107009],
          [61.133949, 35.10691],
          [61.133348, 35.106666],
          [61.132034, 35.106307],
          [61.13149, 35.106422],
          [61.131031, 35.106666],
          [61.130521, 35.107322],
          [61.1304, 35.107791],
          [61.130459, 35.108038],
          [61.130451, 35.109574],
          [61.130112, 35.11028],
          [61.130119, 35.110554],
          [61.130254, 35.111624],
          [61.130462, 35.11215],
          [61.130776, 35.112808],
          [61.13112, 35.113374],
          [61.131452, 35.113722],
          [61.13176, 35.115599],
          [61.131861, 35.116753],
          [61.131575, 35.117576],
          [61.131543, 35.118783],
          [61.131799, 35.121657],
          [61.131974, 35.122213],
          [61.132424, 35.122817],
          [61.133188, 35.123487],
          [61.133295, 35.123942],
          [61.133225, 35.124273],
          [61.132905, 35.124538],
          [61.13255, 35.125224],
          [61.13231, 35.126083],
          [61.131921, 35.126563],
          [61.131313, 35.127749],
          [61.130796, 35.128382],
          [61.130611, 35.129029],
          [61.130705, 35.130806],
          [61.130864, 35.131885],
          [61.131176, 35.132613],
          [61.131758, 35.133082],
          [61.132655, 35.1336],
          [61.133109, 35.133988],
          [61.133297, 35.134476],
          [61.133175, 35.134714],
          [61.132679, 35.135392],
          [61.13257, 35.135873],
          [61.131827, 35.136785],
          [61.131046, 35.137043],
          [61.130404, 35.137075],
          [61.129965, 35.137002],
          [61.129563, 35.136715],
          [61.129047, 35.135893],
          [61.128684, 35.135587],
          [61.127726, 35.135031],
          [61.126929, 35.13482],
          [61.12507, 35.134661],
          [61.124396, 35.134778],
          [61.124237, 35.135018],
          [61.124121, 35.135367],
          [61.121931, 35.138475],
          [61.121189, 35.139171],
          [61.120285, 35.140488],
          [61.11973, 35.141498],
          [61.119484, 35.142081],
          [61.11949, 35.143872],
          [61.119413, 35.144598],
          [61.11907, 35.145119],
          [61.118254, 35.14558],
          [61.117376, 35.145931],
          [61.116076, 35.146308],
          [61.114777, 35.14654],
          [61.114128, 35.146893],
          [61.113807, 35.14725],
          [61.113208, 35.147915],
          [61.11227, 35.149178],
          [61.111148, 35.151072],
          [61.110727, 35.151924],
          [61.110771, 35.152834],
          [61.110961, 35.15346],
          [61.111561, 35.154685],
          [61.112308, 35.155929],
          [61.112524, 35.156621],
          [61.1124, 35.157331],
          [61.112086, 35.158001],
          [61.111567, 35.158617],
          [61.111116, 35.15904],
          [61.110536, 35.159295],
          [61.108683, 35.15935],
          [61.107841, 35.159693],
          [61.107329, 35.160098],
          [61.106976, 35.16091],
          [61.10695, 35.16149],
          [61.107251, 35.163263],
          [61.107074, 35.163698],
          [61.106646, 35.163899],
          [61.106169, 35.164275],
          [61.104788, 35.164952],
          [61.104042, 35.165269],
          [61.102201, 35.165584],
          [61.101702, 35.165883],
          [61.099838, 35.166049],
          [61.098543, 35.165972],
          [61.097327, 35.165391],
          [61.096679, 35.164973],
          [61.095884, 35.163929],
          [61.095362, 35.162739],
          [61.095285, 35.16178],
          [61.095349, 35.160251],
          [61.095124, 35.159873],
          [61.094547, 35.159399],
          [61.093875, 35.159089],
          [61.093155, 35.15902],
          [61.092805, 35.159261],
          [61.092621, 35.159481],
          [61.092408, 35.159897],
          [61.091981, 35.160232],
          [61.0919, 35.160532],
          [61.091245, 35.160993],
          [61.08838, 35.162616],
          [61.087926, 35.163115],
          [61.087453, 35.163958],
          [61.087117, 35.164807],
          [61.086733, 35.166334],
          [61.086745, 35.16683],
          [61.087116, 35.167422],
          [61.087836, 35.168091],
          [61.089166, 35.16873],
          [61.090201, 35.168802],
          [61.090913, 35.169057],
          [61.092027, 35.169666],
          [61.093251, 35.170405],
          [61.094096, 35.170827],
          [61.094973, 35.171144],
          [61.09608, 35.171749],
          [61.098099, 35.172885],
          [61.098976, 35.173504],
          [61.099101, 35.174027],
          [61.0989, 35.174661],
          [61.09738, 35.176688],
          [61.0967, 35.177474],
          [61.096494, 35.178068],
          [61.095008, 35.179328],
          [61.094002, 35.180503],
          [61.093135, 35.182166],
          [61.092787, 35.183125],
          [61.092985, 35.18393],
          [61.093458, 35.184751],
          [61.094231, 35.185501],
          [61.09513, 35.185916],
          [61.098282, 35.186819],
          [61.099279, 35.187408],
          [61.101538, 35.188973],
          [61.104071, 35.190187],
          [61.105658, 35.190795],
          [61.106329, 35.191167],
          [61.1071, 35.192817],
          [61.107689, 35.193484],
          [61.108151, 35.19422],
          [61.10851, 35.195051],
          [61.109266, 35.195743],
          [61.109693, 35.196595],
          [61.109851, 35.197064],
          [61.109957, 35.199773],
          [61.109806, 35.201814],
          [61.109833, 35.202794],
          [61.110141, 35.203554],
          [61.110612, 35.204021],
          [61.111437, 35.204611],
          [61.11226, 35.204929],
          [61.112915, 35.20492],
          [61.113543, 35.204711],
          [61.11377, 35.204425],
          [61.114606, 35.203821],
          [61.115108, 35.203814],
          [61.115627, 35.204255],
          [61.116699, 35.20569],
          [61.117029, 35.206763],
          [61.117303, 35.207207],
          [61.117521, 35.20776],
          [61.117573, 35.208329],
          [61.117794, 35.208871],
          [61.117854, 35.209644],
          [61.117812, 35.210814],
          [61.117684, 35.211099],
          [61.117097, 35.211391],
          [61.116637, 35.212],
          [61.11611, 35.21248],
          [61.115427, 35.212824],
          [61.115104, 35.212792],
          [61.114456, 35.212502],
          [61.113448, 35.212199],
          [61.112853, 35.212139],
          [61.112119, 35.212171],
          [61.111652, 35.212561],
          [61.110706, 35.213039],
          [61.109014, 35.213209],
          [61.107782, 35.213565],
          [61.105926, 35.214325],
          [61.103887, 35.214329],
          [61.103437, 35.214392],
          [61.103318, 35.214664],
          [61.102139, 35.215836],
          [61.101027, 35.216639],
          [61.100552, 35.217107],
          [61.100173, 35.217395],
          [61.099292, 35.217798],
          [61.098697, 35.218],
          [61.09836, 35.2185],
          [61.098543, 35.218948],
          [61.099028, 35.219659],
          [61.099493, 35.221077],
          [61.099343, 35.221459],
          [61.099399, 35.221699],
          [61.100019, 35.222971],
          [61.100033, 35.22382],
          [61.099817, 35.224335],
          [61.099427, 35.224954],
          [61.099119, 35.226033],
          [61.098725, 35.227932],
          [61.098999, 35.228334],
          [61.099428, 35.228759],
          [61.099857, 35.229446],
          [61.1009, 35.230456],
          [61.101633, 35.231585],
          [61.102326, 35.232358],
          [61.102524, 35.233162],
          [61.102585, 35.233852],
          [61.102344, 35.234429],
          [61.102053, 35.234467],
          [61.101494, 35.234296],
          [61.100681, 35.234443],
          [61.100235, 35.234885],
          [61.099992, 35.235241],
          [61.099071, 35.235699],
          [61.097957, 35.236384],
          [61.097411, 35.237078],
          [61.096282, 35.238902],
          [61.096081, 35.239557],
          [61.096334, 35.240447],
          [61.096664, 35.241109],
          [61.097291, 35.241475],
          [61.097854, 35.241578],
          [61.098856, 35.241642],
          [61.099195, 35.241989],
          [61.099426, 35.242457],
          [61.09926, 35.242955],
          [61.099148, 35.243791],
          [61.098894, 35.244129],
          [61.098695, 35.244599],
          [61.098411, 35.245611],
          [61.097821, 35.246043],
          [61.097704, 35.246387],
          [61.097838, 35.247246],
          [61.097564, 35.247948],
          [61.097825, 35.248651],
          [61.097661, 35.249607],
          [61.097363, 35.250334],
          [61.097458, 35.251032],
          [61.097229, 35.251396],
          [61.097172, 35.252081],
          [61.097231, 35.252505],
          [61.097433, 35.253066],
          [61.097341, 35.253302],
          [61.097459, 35.253934],
          [61.097328, 35.254404],
          [61.097131, 35.257991],
          [61.096817, 35.258419],
          [61.096324, 35.258501],
          [61.094706, 35.259342],
          [61.092795, 35.260078],
          [61.092337, 35.260476],
          [61.092063, 35.260875],
          [61.091916, 35.261224],
          [61.091731, 35.26203],
          [61.091693, 35.263115],
          [61.091775, 35.263737],
          [61.092187, 35.264389],
          [61.092973, 35.265128],
          [61.093787, 35.265753],
          [61.09447, 35.266514],
          [61.095031, 35.267452],
          [61.095503, 35.268826],
          [61.096494, 35.270702],
          [61.09658, 35.27134],
          [61.096947, 35.272146],
          [61.097688, 35.273022],
          [61.099247, 35.274456],
          [61.099545, 35.275091],
          [61.100263, 35.276026],
          [61.101093, 35.277287],
          [61.10163, 35.277873],
          [61.102125, 35.278209],
          [61.10417, 35.279121],
          [61.104894, 35.279572],
          [61.105324, 35.28003],
          [61.105561, 35.280579],
          [61.106446, 35.281462],
          [61.107142, 35.281773],
          [61.107967, 35.282413],
          [61.108955, 35.282812],
          [61.109716, 35.282921],
          [61.110355, 35.28258],
          [61.111029, 35.281321],
          [61.111461, 35.281082],
          [61.113192, 35.281502],
          [61.114047, 35.281632],
          [61.115045, 35.281906],
          [61.115594, 35.282219],
          [61.116036, 35.282316],
          [61.116569, 35.282217],
          [61.116925, 35.28195],
          [61.117334, 35.281768],
          [61.117687, 35.280918],
          [61.117678, 35.280202],
          [61.117784, 35.279765],
          [61.117771, 35.279248],
          [61.118479, 35.278707],
          [61.118849, 35.278541],
          [61.119579, 35.278371],
          [61.119975, 35.277952],
          [61.120575, 35.277789],
          [61.12123, 35.277707],
          [61.121616, 35.277708],
          [61.122856, 35.277958],
          [61.123522, 35.278218],
          [61.124426, 35.278852],
          [61.124858, 35.279235],
          [61.125545, 35.280185],
          [61.125909, 35.280467],
          [61.126431, 35.280638],
          [61.126784, 35.280651],
          [61.127106, 35.280579],
          [61.128535, 35.279918],
          [61.128898, 35.279598],
          [61.129427, 35.27886],
          [61.129662, 35.278623],
          [61.12993, 35.27853],
          [61.13124, 35.278457],
          [61.13193, 35.278465],
          [61.132931, 35.278358],
          [61.133271, 35.278425],
          [61.135024, 35.279064],
          [61.136387, 35.279324],
          [61.136894, 35.279552],
          [61.137458, 35.279729],
          [61.138054, 35.279872],
          [61.138507, 35.279931],
          [61.139347, 35.279945],
          [61.140009, 35.280258],
          [61.140492, 35.280429],
          [61.140979, 35.280754],
          [61.141102, 35.281056],
          [61.141166, 35.281822],
          [61.14127, 35.282135],
          [61.141782, 35.282691],
          [61.142211, 35.282993],
          [61.142871, 35.28329],
          [61.143317, 35.283223],
          [61.144064, 35.282908],
          [61.144432, 35.282894],
          [61.14517, 35.283167],
          [61.146243, 35.283397],
          [61.146719, 35.283541],
          [61.147477, 35.283617],
          [61.148052, 35.283812],
          [61.149263, 35.283935],
          [61.149983, 35.284073],
          [61.151017, 35.284388],
          [61.151692, 35.284461],
          [61.152161, 35.284437],
          [61.153828, 35.284702],
          [61.154804, 35.284697],
          [61.155421, 35.284545],
          [61.155767, 35.284299],
          [61.155778, 35.283989],
          [61.155578, 35.283616],
          [61.155603, 35.283316],
          [61.155877, 35.282516],
          [61.156302, 35.280094],
          [61.156232, 35.27893],
          [61.155991, 35.278275],
          [61.155693, 35.27669],
          [61.155796, 35.276266],
          [61.155996, 35.275844],
          [61.156348, 35.275572],
          [61.156678, 35.275472],
          [61.157048, 35.275465],
          [61.157867, 35.275352],
          [61.15837, 35.275484],
          [61.158588, 35.275593],
          [61.159366, 35.276587],
          [61.159747, 35.277244],
          [61.160083, 35.278227],
          [61.160168, 35.278952],
          [61.160342, 35.279402],
          [61.160786, 35.28038],
          [61.161102, 35.280799],
          [61.161543, 35.281073],
          [61.161952, 35.281225],
          [61.162608, 35.281434],
          [61.163113, 35.281464],
          [61.163507, 35.281567],
          [61.16391, 35.281926],
          [61.164156, 35.282237],
          [61.164516, 35.282966],
          [61.164887, 35.283088],
          [61.165851, 35.283299],
          [61.166135, 35.283456],
          [61.166683, 35.283986],
          [61.167062, 35.284505],
          [61.167127, 35.284873],
          [61.167029, 35.284995],
          [61.166033, 35.285379],
          [61.164838, 35.285636],
          [61.164665, 35.285823],
          [61.16471, 35.28609],
          [61.164891, 35.286423],
          [61.165167, 35.287238],
          [61.165319, 35.287516],
          [61.165904, 35.287997],
          [61.166541, 35.288423],
          [61.167302, 35.288859],
          [61.168302, 35.289353],
          [61.16857, 35.289969],
          [61.168976, 35.290183],
          [61.169188, 35.290211],
          [61.169559, 35.290079],
          [61.170313, 35.289929],
          [61.170796, 35.289997],
          [61.171372, 35.29027],
          [61.172501, 35.290081],
          [61.17285, 35.289908],
          [61.174398, 35.289926],
          [61.174634, 35.290021],
          [61.175551, 35.290631],
          [61.176024, 35.291052],
          [61.176878, 35.292075],
          [61.177799, 35.292548],
          [61.178602, 35.292817],
          [61.179184, 35.29279],
          [61.179527, 35.292594],
          [61.179749, 35.292335],
          [61.180337, 35.291838],
          [61.181358, 35.291275],
          [61.181721, 35.29111],
          [61.182634, 35.290954],
          [61.183149, 35.291101],
          [61.183673, 35.291179],
          [61.184511, 35.29156],
          [61.184673, 35.29172],
          [61.184906, 35.291776],
          [61.185629, 35.291864],
          [61.186297, 35.291678],
          [61.186928, 35.291697],
          [61.187299, 35.291908],
          [61.187543, 35.291962],
          [61.188425, 35.291919],
          [61.189265, 35.292017],
          [61.189539, 35.292131],
          [61.189836, 35.292359],
          [61.190296, 35.292865],
          [61.190254, 35.293594],
          [61.189979, 35.294469],
          [61.189705, 35.296375],
          [61.189712, 35.297624],
          [61.189489, 35.298351],
          [61.189408, 35.298958],
          [61.189518, 35.299339],
          [61.190127, 35.299793],
          [61.190512, 35.300002],
          [61.191, 35.30013],
          [61.191679, 35.300557],
          [61.191882, 35.301104],
          [61.19132, 35.301647],
          [61.190622, 35.301773],
          [61.190261, 35.301548],
          [61.189869, 35.301535],
          [61.189501, 35.301707],
          [61.18839, 35.301619],
          [61.187884, 35.301807],
          [61.187616, 35.302169],
          [61.187427, 35.302737],
          [61.187322, 35.303254],
          [61.187862, 35.304519],
          [61.18812, 35.305368],
          [61.188345, 35.305874],
          [61.189362, 35.307542],
          [61.189484, 35.308052],
          [61.189523, 35.308473],
          [61.189246, 35.308841],
          [61.189325, 35.309295],
          [61.189258, 35.309631],
          [61.188859, 35.310049],
          [61.188834, 35.310561],
          [61.188547, 35.311212],
          [61.188007, 35.31196],
          [61.187529, 35.312812],
          [61.187313, 35.313645],
          [61.186979, 35.314445],
          [61.186497, 35.315145],
          [61.186242, 35.315751],
          [61.185968, 35.31611],
          [61.186126, 35.316489],
          [61.186919, 35.317396],
          [61.187776, 35.317575],
          [61.18849, 35.317227],
          [61.189995, 35.317018],
          [61.190415, 35.317116],
          [61.191093, 35.317939],
          [61.191312, 35.318504],
          [61.191225, 35.31891],
          [61.191343, 35.319504],
          [61.191225, 35.31969],
          [61.190712, 35.319814],
          [61.190365, 35.320666],
          [61.189933, 35.321108],
          [61.187412, 35.322182],
          [61.18671, 35.322531],
          [61.185993, 35.323111],
          [61.185257, 35.324177],
          [61.185078, 35.324846],
          [61.185159, 35.325246],
          [61.185675, 35.326519],
          [61.186088, 35.327072],
          [61.186416, 35.327635],
          [61.186415, 35.328134],
          [61.186048, 35.328653],
          [61.185474, 35.328893],
          [61.185058, 35.32929],
          [61.18443, 35.329694],
          [61.183671, 35.330315],
          [61.183065, 35.330657],
          [61.182642, 35.331191],
          [61.182278, 35.331862],
          [61.182201, 35.333209],
          [61.1823, 35.333937],
          [61.182268, 35.334402],
          [61.18217, 35.334744],
          [61.181936, 35.335219],
          [61.181326, 35.335848],
          [61.181007, 35.336305],
          [61.18072, 35.336623],
          [61.179995, 35.337031],
          [61.179495, 35.337501],
          [61.179112, 35.337781],
          [61.178348, 35.337944],
          [61.178152, 35.337917],
          [61.177916, 35.338053],
          [61.177751, 35.33836],
          [61.177557, 35.338481],
          [61.177273, 35.338559],
          [61.177049, 35.339042],
          [61.176743, 35.339331],
          [61.176522, 35.339664],
          [61.176557, 35.339754],
          [61.176467, 35.340395],
          [61.17613, 35.340996],
          [61.176029, 35.341335],
          [61.17575, 35.343314],
          [61.175845, 35.343594],
          [61.175915, 35.345223],
          [61.176002, 35.345896],
          [61.175913, 35.346474],
          [61.17571, 35.346882],
          [61.175874, 35.347272],
          [61.176484, 35.348231],
          [61.176984, 35.348543],
          [61.177271, 35.348603],
          [61.17764, 35.348946],
          [61.178359, 35.34994],
          [61.178213, 35.350639],
          [61.178081, 35.351843],
          [61.177184, 35.35252],
          [61.177103, 35.352766],
          [61.177085, 35.353591],
          [61.176977, 35.354108],
          [61.17672, 35.354598],
          [61.176201, 35.356365],
          [61.176287, 35.356743],
          [61.17652, 35.356987],
          [61.177685, 35.357816],
          [61.178585, 35.358233],
          [61.179049, 35.358272],
          [61.179306, 35.35815],
          [61.179439, 35.357947],
          [61.180162, 35.357442],
          [61.180515, 35.357325],
          [61.180779, 35.35729],
          [61.18172, 35.357548],
          [61.183596, 35.357778],
          [61.184444, 35.35781],
          [61.185537, 35.358022],
          [61.185812, 35.35814],
          [61.185885, 35.358331],
          [61.185528, 35.358835],
          [61.185396, 35.359224],
          [61.185369, 35.359776],
          [61.185435, 35.360198],
          [61.185628, 35.360629],
          [61.18588, 35.361455],
          [61.186252, 35.363453],
          [61.186425, 35.363909],
          [61.186386, 35.364126],
          [61.18616, 35.364341],
          [61.18607, 35.364642],
          [61.186044, 35.365037],
          [61.185204, 35.365915],
          [61.185048, 35.366707],
          [61.184784, 35.367375],
          [61.184549, 35.368186],
          [61.184035, 35.369109],
          [61.183979, 35.369543],
          [61.184018, 35.36991],
          [61.184208, 35.370729],
          [61.184398, 35.370953],
          [61.185109, 35.37139],
          [61.186266, 35.371597],
          [61.186931, 35.371773],
          [61.187427, 35.372151],
          [61.188718, 35.372949],
          [61.188981, 35.373296],
          [61.189072, 35.3737],
          [61.188965, 35.373908],
          [61.188998, 35.37419],
          [61.189177, 35.374656],
          [61.19013, 35.37538],
          [61.192089, 35.376035],
          [61.193096, 35.376261],
          [61.19394, 35.376183],
          [61.194486, 35.376058],
          [61.194986, 35.37557],
          [61.195564, 35.374421],
          [61.195917, 35.373992],
          [61.197727, 35.373163],
          [61.199122, 35.372872],
          [61.199646, 35.372677],
          [61.199826, 35.372689],
          [61.200429, 35.373588],
          [61.200455, 35.374249],
          [61.200244, 35.375681],
          [61.199448, 35.377334],
          [61.198845, 35.378009],
          [61.198517, 35.378307],
          [61.197859, 35.378451],
          [61.195366, 35.378622],
          [61.194911, 35.378816],
          [61.194306, 35.379152],
          [61.193958, 35.379462],
          [61.193372, 35.38026],
          [61.193012, 35.380473],
          [61.192981, 35.380707],
          [61.193781, 35.382305],
          [61.194183, 35.382709],
          [61.194697, 35.382774],
          [61.195043, 35.382753],
          [61.195533, 35.383005],
          [61.195901, 35.383378],
          [61.196019, 35.383681],
          [61.196471, 35.384016],
          [61.198791, 35.384735],
          [61.199501, 35.385186],
          [61.199845, 35.385676],
          [61.200129, 35.386341],
          [61.200346, 35.386971],
          [61.200558, 35.387843],
          [61.200489, 35.388443],
          [61.200264, 35.38896],
          [61.200001, 35.389289],
          [61.199609, 35.389558],
          [61.198731, 35.390049],
          [61.198114, 35.390149],
          [61.197759, 35.390087],
          [61.197344, 35.389798],
          [61.196991, 35.389468],
          [61.196215, 35.388434],
          [61.196045, 35.387815],
          [61.195876, 35.386545],
          [61.195715, 35.38605],
          [61.195531, 35.385595],
          [61.195153, 35.385218],
          [61.194783, 35.385052],
          [61.1945, 35.385129],
          [61.19368, 35.384982],
          [61.19302, 35.384944],
          [61.192491, 35.385065],
          [61.191753, 35.385422],
          [61.191556, 35.385619],
          [61.191503, 35.385985],
          [61.191915, 35.386394],
          [61.191961, 35.386668],
          [61.192417, 35.38741],
          [61.193456, 35.388503],
          [61.193827, 35.389104],
          [61.194147, 35.389457],
          [61.194422, 35.390176],
          [61.194433, 35.390358],
          [61.194178, 35.390726],
          [61.194063, 35.391011],
          [61.193838, 35.391225],
          [61.193632, 35.391546],
          [61.193281, 35.391895],
          [61.192903, 35.392559],
          [61.192601, 35.392926],
          [61.19189, 35.393543],
          [61.191715, 35.393755],
          [61.191631, 35.394319],
          [61.191024, 35.395318],
          [61.190263, 35.395789],
          [61.189996, 35.396193],
          [61.18911, 35.39677],
          [61.188515, 35.397006],
          [61.188287, 35.397223],
          [61.187932, 35.397433],
          [61.187391, 35.398387],
          [61.187338, 35.398589],
          [61.187459, 35.399592],
          [61.187829, 35.400039],
          [61.188524, 35.400423],
          [61.189449, 35.401243],
          [61.189812, 35.40143],
          [61.190421, 35.402006],
          [61.191045, 35.402699],
          [61.191635, 35.403134],
          [61.191886, 35.403412],
          [61.191967, 35.403788],
          [61.192683, 35.405126],
          [61.192967, 35.405442],
          [61.193368, 35.40567],
          [61.194011, 35.406705],
          [61.194462, 35.407062],
          [61.195094, 35.407508],
          [61.196505, 35.407413],
          [61.197657, 35.407447],
          [61.197985, 35.407637],
          [61.198784, 35.409177],
          [61.198834, 35.409564],
          [61.199057, 35.409885],
          [61.199345, 35.41098],
          [61.199523, 35.411393],
          [61.200189, 35.411875],
          [61.200604, 35.412072],
          [61.201413, 35.412207],
          [61.202138, 35.412421],
          [61.202967, 35.412595],
          [61.203666, 35.412864],
          [61.204343, 35.412993],
          [61.204998, 35.412956],
          [61.206776, 35.411862],
          [61.207294, 35.411702],
          [61.207893, 35.411737],
          [61.208275, 35.41193],
          [61.209416, 35.412009],
          [61.210451, 35.412346],
          [61.210822, 35.412517],
          [61.211056, 35.412915],
          [61.212085, 35.414176],
          [61.212878, 35.414843],
          [61.213233, 35.41533],
          [61.213236, 35.415785],
          [61.213607, 35.417215],
          [61.213585, 35.418471],
          [61.213784, 35.419018],
          [61.213965, 35.419356],
          [61.213947, 35.419646],
          [61.214398, 35.420043],
          [61.215675, 35.420455],
          [61.216857, 35.421184],
          [61.217279, 35.421264],
          [61.217724, 35.421155],
          [61.218581, 35.420569],
          [61.219044, 35.420391],
          [61.22015, 35.420138],
          [61.221007, 35.419887],
          [61.221761, 35.419749],
          [61.222139, 35.419858],
          [61.222419, 35.420358],
          [61.223292, 35.420564],
          [61.224768, 35.420759],
          [61.225988, 35.421101],
          [61.226716, 35.421256],
          [61.227161, 35.421655],
          [61.227087, 35.421967],
          [61.227093, 35.422664],
          [61.227361, 35.423042],
          [61.227475, 35.423486],
          [61.227716, 35.42397],
          [61.228156, 35.424196],
          [61.228409, 35.424401],
          [61.2286, 35.424711],
          [61.228774, 35.425255],
          [61.229538, 35.426125],
          [61.229881, 35.427097],
          [61.230348, 35.429118],
          [61.230627, 35.429709],
          [61.230973, 35.43033],
          [61.231695, 35.431158],
          [61.232309, 35.431726],
          [61.232771, 35.432481],
          [61.232936, 35.43312],
          [61.232796, 35.433371],
          [61.232433, 35.43356],
          [61.2321, 35.434014],
          [61.231505, 35.434573],
          [61.230983, 35.434941],
          [61.23043, 35.435222],
          [61.229781, 35.435422],
          [61.228537, 35.436057],
          [61.227888, 35.436882],
          [61.227105, 35.43748],
          [61.226853, 35.437846],
          [61.226265, 35.438261],
          [61.225401, 35.43855],
          [61.224636, 35.438971],
          [61.223997, 35.439468],
          [61.222863, 35.440747],
          [61.222568, 35.441177],
          [61.22207, 35.442909],
          [61.222263, 35.443312],
          [61.222625, 35.443882],
          [61.223197, 35.444385],
          [61.223783, 35.445258],
          [61.224273, 35.445739],
          [61.225093, 35.44631],
          [61.225919, 35.446736],
          [61.228331, 35.447815],
          [61.229067, 35.448427],
          [61.229713, 35.448775],
          [61.230346, 35.448978],
          [61.231184, 35.449082],
          [61.231924, 35.449096],
          [61.232685, 35.448947],
          [61.234745, 35.449269],
          [61.235515, 35.44951],
          [61.235808, 35.449811],
          [61.235825, 35.450172],
          [61.23563, 35.450752],
          [61.23547, 35.451707],
          [61.235072, 35.452966],
          [61.23507, 35.453237],
          [61.235305, 35.453695],
          [61.235502, 35.453951],
          [61.235755, 35.454717],
          [61.236028, 35.454957],
          [61.236692, 35.455832],
          [61.236868, 35.456571],
          [61.237027, 35.457996],
          [61.236847, 35.458675],
          [61.236403, 35.459461],
          [61.235663, 35.460022],
          [61.234992, 35.460389],
          [61.234034, 35.461607],
          [61.233745, 35.462285],
          [61.234173, 35.463115],
          [61.23455, 35.46362],
          [61.235041, 35.463946],
          [61.236181, 35.464413],
          [61.236356, 35.46468],
          [61.236865, 35.46514],
          [61.237514, 35.466027],
          [61.237398, 35.46636],
          [61.237526, 35.466697],
          [61.238575, 35.467901],
          [61.239065, 35.468688],
          [61.239737, 35.469238],
          [61.239959, 35.46961],
          [61.240165, 35.471235],
          [61.240523, 35.472258],
          [61.241883, 35.474048],
          [61.243371, 35.475529],
          [61.243436, 35.47594],
          [61.24364, 35.476137],
          [61.24427, 35.476297],
          [61.244766, 35.476335],
          [61.245667, 35.476539],
          [61.246266, 35.476731],
          [61.247033, 35.476854],
          [61.247559, 35.476755],
          [61.247767, 35.476838],
          [61.248302, 35.477132],
          [61.248747, 35.47749],
          [61.248842, 35.477785],
          [61.25028, 35.478986],
          [61.251465, 35.479367],
          [61.252343, 35.479534],
          [61.254106, 35.480032],
          [61.254802, 35.480435],
          [61.255524, 35.481009],
          [61.255894, 35.481726],
          [61.255828, 35.482378],
          [61.25478, 35.483433],
          [61.254161, 35.483762],
          [61.252921, 35.484271],
          [61.252777, 35.484503],
          [61.252696, 35.484808],
          [61.25252, 35.485153],
          [61.252612, 35.485624],
          [61.252745, 35.48586],
          [61.253121, 35.486094],
          [61.25326, 35.486413],
          [61.253346, 35.487309],
          [61.253289, 35.488662],
          [61.253345, 35.489315],
          [61.253736, 35.490073],
          [61.254294, 35.49058],
          [61.255341, 35.491101],
          [61.256323, 35.491457],
          [61.257366, 35.491706],
          [61.258001, 35.491808],
          [61.258626, 35.491788],
          [61.258984, 35.491992],
          [61.25987, 35.494055],
          [61.260569, 35.495384],
          [61.26087, 35.49627],
          [61.261056, 35.497002],
          [61.261192, 35.497752],
          [61.261227, 35.498536],
          [61.261056, 35.499054],
          [61.260377, 35.500013],
          [61.259547, 35.502136],
          [61.259306, 35.503088],
          [61.259206, 35.503762],
          [61.259266, 35.504342],
          [61.259482, 35.504768],
          [61.259949, 35.505074],
          [61.260457, 35.505303],
          [61.261708, 35.505515],
          [61.262407, 35.50576],
          [61.263108, 35.505824],
          [61.264037, 35.505815],
          [61.265528, 35.505702],
          [61.266293, 35.505774],
          [61.267065, 35.505983],
          [61.267308, 35.506411],
          [61.267983, 35.507253],
          [61.268643, 35.508658],
          [61.269219, 35.509133],
          [61.269371, 35.509554],
          [61.269416, 35.509901],
          [61.269394, 35.511116],
          [61.269746, 35.512653],
          [61.269724, 35.513036],
          [61.271097, 35.516148],
          [61.271406, 35.516523],
          [61.276263, 35.518207],
          [61.277778, 35.520278],
          [61.275266, 35.527485],
          [61.278113, 35.531525],
          [61.28101, 35.533978],
          [61.282579, 35.537903],
          [61.282601, 35.540618],
          [61.282525, 35.546756],
          [61.283264, 35.550214],
          [61.270421, 35.559803],
          [61.270093, 35.564049],
          [61.273798, 35.567738],
          [61.271647, 35.570835],
          [61.273639, 35.57511],
          [61.279336, 35.577478],
          [61.279967, 35.580609],
          [61.279145, 35.581739],
          [61.274079, 35.583493],
          [61.271031, 35.589194],
          [61.27422, 35.591503],
          [61.27443, 35.595426],
          [61.277345, 35.598846],
          [61.274257, 35.603181],
          [61.274095, 35.604842],
          [61.273759, 35.609412],
          [61.271017, 35.611296],
          [61.270837, 35.614466],
          [61.268492, 35.615763],
          [61.261093, 35.618486],
          [61.247128, 35.626585],
          [61.246284, 35.628605],
          [61.250375, 35.633426],
          [61.251064, 35.635087],
          [61.249668, 35.638357],
          [61.247379, 35.64046],
          [61.246793, 35.645202],
          [61.24163, 35.647681],
          [61.234047, 35.648308],
          [61.226226, 35.65069],
          [61.225007, 35.652999],
          [61.225429, 35.653914],
          [61.228946, 35.655477],
          [61.228852, 35.657115],
          [61.222694, 35.66109],
          [61.218964, 35.668338],
          [61.2246, 35.672904],
          [61.222933, 35.675389],
          [61.216655, 35.675801],
          [61.216236, 35.683253],
          [61.21909, 35.688781],
          [61.220785, 35.693158],
          [61.224866, 35.69811],
          [61.226132, 35.697881],
          [61.228449, 35.696996],
          [61.229462, 35.697576],
          [61.230252, 35.700594],
          [61.231526, 35.702414],
          [61.236389, 35.704167],
          [61.239722, 35.708611],
          [61.243889, 35.712222],
          [61.246389, 35.7175],
          [61.248333, 35.723611],
          [61.249722, 35.730278],
          [61.248889, 35.735833],
          [61.249444, 35.742222],
          [61.248611, 35.747778],
          [61.248889, 35.753889],
          [61.251111, 35.76],
          [61.253611, 35.765278],
          [61.255556, 35.771111],
          [61.255, 35.776944],
          [61.2525, 35.781389],
          [61.250833, 35.786389],
          [61.252222, 35.793056],
          [61.253056, 35.798611],
          [61.255833, 35.803889],
          [61.258611, 35.808889],
          [61.261944, 35.813333],
          [61.262222, 35.819722],
          [61.260556, 35.824722],
          [61.258889, 35.829722],
          [61.256389, 35.834167],
          [61.253889, 35.838611],
          [61.252222, 35.843611],
          [61.249444, 35.848333],
          [61.248056, 35.853333],
          [61.246389, 35.858333],
          [61.243611, 35.862778],
          [61.243056, 35.868333],
          [61.241389, 35.873611],
          [61.240833, 35.879167],
          [61.241111, 35.885278],
          [61.243889, 35.890556],
          [61.245, 35.897222],
          [61.239444, 35.898611],
          [61.233056, 35.8975],
          [61.2275, 35.898889],
          [61.225833, 35.903889],
          [61.226111, 35.91],
          [61.222531, 35.914426],
          [61.210671, 35.921824],
          [61.205404, 35.92712],
          [61.183628, 35.943041],
          [61.172054, 35.946734],
          [61.164129, 35.948884],
          [61.159296, 35.950893],
          [61.149377, 35.952244],
          [61.139183, 35.952644],
          [61.136305, 35.952646],
          [61.133933, 35.953158],
          [61.131827, 35.955235],
          [61.129379, 35.958619],
          [61.128313, 35.959114],
          [61.126476, 35.959329],
          [61.125883, 35.958924],
          [61.125316, 35.958256],
          [61.124753, 35.955715],
          [61.12336, 35.955585],
          [61.120773, 35.955844],
          [61.119497, 35.956629],
          [61.118611, 35.958082],
          [61.117384, 35.960559],
          [61.117463, 35.96138],
          [61.117168, 35.962131],
          [61.117091, 35.963128],
          [61.117298, 35.96319],
          [61.117556, 35.963214],
          [61.117996, 35.963156],
          [61.118334, 35.963165],
          [61.118564, 35.963246],
          [61.118705, 35.963339],
          [61.118835, 35.963465],
          [61.119069, 35.963729],
          [61.119276, 35.964023],
          [61.119522, 35.964396],
          [61.119684, 35.964791],
          [61.119823, 35.964904],
          [61.119962, 35.964996],
          [61.120156, 35.965169],
          [61.120561, 35.965782],
          [61.120799, 35.966538],
          [61.121216, 35.9669],
          [61.121343, 35.967053],
          [61.121719, 35.967298],
          [61.121816, 35.967441],
          [61.122014, 35.967797],
          [61.122219, 35.967939],
          [61.122436, 35.968048],
          [61.122558, 35.96811],
          [61.122885, 35.968213],
          [61.12325, 35.968252],
          [61.123948, 35.968259],
          [61.124772, 35.968226],
          [61.125476, 35.96809],
          [61.126344, 35.96797],
          [61.127154, 35.967753],
          [61.12753, 35.967566],
          [61.127674, 35.967473],
          [61.12773, 35.967411],
          [61.127984, 35.967329],
          [61.128236, 35.967285],
          [61.129074, 35.967045],
          [61.12927, 35.967022],
          [61.129552, 35.967039],
          [61.129887, 35.967082],
          [61.130055, 35.967162],
          [61.130377, 35.967377],
          [61.132248, 35.968351],
          [61.133458, 35.969579],
          [61.136641, 35.971574],
          [61.140851, 35.975009],
          [61.142745, 35.979461],
          [61.144033, 35.981529],
          [61.14649, 35.983193],
          [61.150751, 35.984283],
          [61.153706, 35.985096],
          [61.156505, 35.986551],
          [61.161645, 35.989496],
          [61.166667, 35.990833],
          [61.17, 35.995278],
          [61.170963, 35.997805],
          [61.172222, 36.001111],
          [61.17234, 36.00185],
          [61.173333, 36.008056],
          [61.171667, 36.013056],
          [61.169167, 36.0175],
          [61.166389, 36.021944],
          [61.165, 36.026944],
          [61.164167, 36.032778],
          [61.164722, 36.035278],
          [61.1675, 36.040556],
          [61.172222, 36.043333],
          [61.176944, 36.046389],
          [61.181944, 36.049167],
          [61.186667, 36.051944],
          [61.192222, 36.054167],
          [61.1832, 36.060567],
          [61.18286, 36.061279],
          [61.182658, 36.062143],
          [61.182566, 36.062635],
          [61.182566, 36.063268],
          [61.182521, 36.064445],
          [61.182594, 36.065139],
          [61.182824, 36.065915],
          [61.183106, 36.066463],
          [61.183118, 36.066664],
          [61.183103, 36.066994],
          [61.183136, 36.067175],
          [61.183297, 36.067335],
          [61.183493, 36.067557],
          [61.183646, 36.067769],
          [61.183971, 36.068167],
          [61.184396, 36.068576],
          [61.184887, 36.068984],
          [61.185392, 36.069373],
          [61.186122, 36.069784],
          [61.187126, 36.070348],
          [61.188127, 36.070948],
          [61.18892, 36.071407],
          [61.190206, 36.072165],
          [61.19073, 36.072333],
          [61.19107, 36.072559],
          [61.191791, 36.073089],
          [61.192416, 36.073382],
          [61.192948, 36.073519],
          [61.193356, 36.073871],
          [61.193676, 36.074285],
          [61.193918, 36.074532],
          [61.194112, 36.074696],
          [61.194303, 36.075173],
          [61.194628, 36.075742],
          [61.195002, 36.076416],
          [61.195427, 36.076973],
          [61.19589, 36.077549],
          [61.196104, 36.078012],
          [61.196195, 36.078508],
          [61.196209, 36.078956],
          [61.196303, 36.079573],
          [61.19644, 36.080161],
          [61.196423, 36.080334],
          [61.196174, 36.080569],
          [61.196127, 36.080676],
          [61.196152, 36.080784],
          [61.196269, 36.080991],
          [61.196355, 36.081488],
          [61.196427, 36.081805],
          [61.196629, 36.082103],
          [61.196748, 36.082496],
          [61.196802, 36.082871],
          [61.196938, 36.083321],
          [61.197127, 36.0837],
          [61.197343, 36.08408],
          [61.197647, 36.08459],
          [61.197774, 36.084679],
          [61.19812, 36.084888],
          [61.19864, 36.085116],
          [61.198868, 36.085379],
          [61.1992, 36.08586],
          [61.199522, 36.086229],
          [61.200235, 36.086881],
          [61.200923, 36.087485],
          [61.201699, 36.088174],
          [61.202429, 36.088703],
          [61.203188, 36.089123],
          [61.204027, 36.089676],
          [61.204293, 36.089776],
          [61.204837, 36.089917],
          [61.205102, 36.09011],
          [61.205435, 36.090373],
          [61.205715, 36.090654],
          [61.206063, 36.090952],
          [61.206468, 36.091415],
          [61.206864, 36.091745],
          [61.207172, 36.09189],
          [61.207292, 36.091989],
          [61.207369, 36.092203],
          [61.207384, 36.092747],
          [61.207198, 36.093301],
          [61.207001, 36.093771],
          [61.206662, 36.094435],
          [61.206516, 36.094939],
          [61.20626, 36.095684],
          [61.206157, 36.096117],
          [61.205927, 36.097193],
          [61.20562, 36.09805],
          [61.20554, 36.098649],
          [61.205512, 36.099166],
          [61.205569, 36.099714],
          [61.205734, 36.100242],
          [61.206033, 36.101021],
          [61.2064, 36.101901],
          [61.206977, 36.103029],
          [61.207679, 36.103798],
          [61.207957, 36.103969],
          [61.208231, 36.104142],
          [61.208332, 36.104251],
          [61.208449, 36.104427],
          [61.208592, 36.104471],
          [61.20882, 36.104505],
          [61.208983, 36.104518],
          [61.209056, 36.104568],
          [61.209099, 36.1047],
          [61.209221, 36.104865],
          [61.209375, 36.105001],
          [61.209557, 36.105265],
          [61.209763, 36.105555],
          [61.210022, 36.105888],
          [61.210156, 36.106071],
          [61.210407, 36.106261],
          [61.210559, 36.106493],
          [61.210738, 36.106725],
          [61.210866, 36.107168],
          [61.211642, 36.107911],
          [61.212246, 36.108375],
          [61.214929, 36.110306],
          [61.215999, 36.110749],
          [61.216453, 36.110731],
          [61.216876, 36.110569],
          [61.218234, 36.110752],
          [61.220196, 36.111521],
          [61.221321, 36.11148],
          [61.222834, 36.110751],
          [61.224092, 36.110387],
          [61.225539, 36.110455],
          [61.226948, 36.110971],
          [61.228434, 36.112268],
          [61.229707, 36.114546],
          [61.23051, 36.116162],
          [61.230976, 36.11759],
          [61.230957, 36.119506],
          [61.230356, 36.120599],
          [61.229285, 36.121798],
          [61.228105, 36.122179],
          [61.22572, 36.121303],
          [61.22297, 36.120831],
          [61.220667, 36.120159],
          [61.220178, 36.120103],
          [61.219668, 36.120205],
          [61.218204, 36.121583],
          [61.216938, 36.122967],
          [61.215108, 36.123385],
          [61.213275, 36.124083],
          [61.211376, 36.125885],
          [61.210608, 36.127745],
          [61.210495, 36.12928],
          [61.210751, 36.130769],
          [61.211888, 36.132727],
          [61.213153, 36.133899],
          [61.214583, 36.135052],
          [61.216549, 36.135894],
          [61.220089, 36.136811],
          [61.221551, 36.137584],
          [61.22348, 36.138581],
          [61.224679, 36.139665],
          [61.225131, 36.140555],
          [61.224958, 36.1418],
          [61.222964, 36.143123],
          [61.219259, 36.145157],
          [61.2161, 36.147789],
          [61.215668, 36.148936],
          [61.21576, 36.150257],
          [61.216343, 36.151458],
          [61.217293, 36.152225],
          [61.219822, 36.1535],
          [61.221772, 36.15505],
          [61.223827, 36.157362],
          [61.224696, 36.159478],
          [61.226264, 36.161298],
          [61.226652, 36.163513],
          [61.226436, 36.166582],
          [61.225256, 36.171339],
          [61.22262, 36.179108],
          [61.221136, 36.184014],
          [61.220105, 36.18468],
          [61.21839, 36.187266],
          [61.217759, 36.188899],
          [61.218357, 36.189826],
          [61.219005, 36.191054],
          [61.219328, 36.192308],
          [61.218446, 36.192882],
          [61.21784, 36.194038],
          [61.216917, 36.194776],
          [61.216237, 36.195174],
          [61.214676, 36.194906],
          [61.212543, 36.194979],
          [61.210081, 36.195732],
          [61.207773, 36.197354],
          [61.206966, 36.198371],
          [61.206288, 36.198819],
          [61.205855, 36.198867],
          [61.205294, 36.199098],
          [61.204481, 36.199834],
          [61.204402, 36.200562],
          [61.204013, 36.201323],
          [61.204071, 36.202251],
          [61.204559, 36.203893],
          [61.205071, 36.204874],
          [61.204724, 36.205601],
          [61.202292, 36.206229],
          [61.200332, 36.208028],
          [61.19994, 36.208665],
          [61.199796, 36.209433],
          [61.19999, 36.209878],
          [61.20033, 36.210142],
          [61.20187, 36.210453],
          [61.202394, 36.21174],
          [61.202458, 36.212451],
          [61.202164, 36.213008],
          [61.200772, 36.214223],
          [61.199725, 36.215902],
          [61.198906, 36.21764],
          [61.198021, 36.218966],
          [61.197199, 36.221107],
          [61.196124, 36.221955],
          [61.194311, 36.222526],
          [61.193303, 36.223766],
          [61.193086, 36.225383],
          [61.19184, 36.226781],
          [61.191404, 36.227863],
          [61.192006, 36.230416],
          [61.192173, 36.231309],
          [61.192037, 36.232136],
          [61.190756, 36.23391],
          [61.190305, 36.235184],
          [61.190469, 36.23646],
          [61.190527, 36.237673],
          [61.190016, 36.238086],
          [61.188917, 36.238124],
          [61.187598, 36.239773],
          [61.187972, 36.241127],
          [61.18803, 36.242145],
          [61.188647, 36.242682],
          [61.188926, 36.244206],
          [61.189399, 36.244713],
          [61.190508, 36.244985],
          [61.190971, 36.245677],
          [61.190691, 36.246618],
          [61.189669, 36.247419],
          [61.188917, 36.248159],
          [61.188639, 36.248824],
          [61.187537, 36.251137],
          [61.187403, 36.251687],
          [61.187403, 36.252255],
          [61.187037, 36.252371],
          [61.186429, 36.252387],
          [61.186072, 36.252566],
          [61.18586, 36.253157],
          [61.186159, 36.253973],
          [61.186844, 36.254389],
          [61.187297, 36.254747],
          [61.187254, 36.255128],
          [61.186897, 36.25533],
          [61.186355, 36.255934],
          [61.186382, 36.256874],
          [61.18638, 36.257124],
          [61.18735, 36.258063],
          [61.189272, 36.26167],
          [61.187183, 36.270349],
          [61.185062, 36.271158],
          [61.181865, 36.272541],
          [61.180923, 36.273848],
          [61.180264, 36.274873],
          [61.179319, 36.276381],
          [61.179396, 36.279522],
          [61.178837, 36.280051],
          [61.177468, 36.280377],
          [61.17646, 36.281786],
          [61.175495, 36.287516],
          [61.172901, 36.290479],
          [61.168196, 36.292279],
          [61.161451, 36.296805],
          [61.160568, 36.302746],
          [61.161506, 36.304057],
          [61.161468, 36.304834],
          [61.160104, 36.30657],
          [61.160159, 36.307889],
          [61.160432, 36.308575],
          [61.161129, 36.309137],
          [61.161376, 36.31026],
          [61.160994, 36.311402],
          [61.1596, 36.312344],
          [61.157284, 36.313159],
          [61.155094, 36.314463],
          [61.152711, 36.315006],
          [61.150901, 36.315758],
          [61.149653, 36.316655],
          [61.149169, 36.31748],
          [61.149304, 36.31834],
          [61.150114, 36.319119],
          [61.152427, 36.32033],
          [61.154206, 36.321522],
          [61.155923, 36.321714],
          [61.15737, 36.321266],
          [61.159421, 36.319921],
          [61.160248, 36.320023],
          [61.1609, 36.320215],
          [61.161121, 36.320777],
          [61.161032, 36.321574],
          [61.160593, 36.322099],
          [61.160414, 36.322697],
          [61.160616, 36.323395],
          [61.160593, 36.324473],
          [61.159975, 36.32681],
          [61.160054, 36.327607],
          [61.160459, 36.328975],
          [61.160447, 36.330569],
          [61.159975, 36.331511],
          [61.158761, 36.332063],
          [61.157052, 36.332308],
          [61.154196, 36.332375],
          [61.150758, 36.335032],
          [61.14911, 36.337215],
          [61.149654, 36.339054],
          [61.151464, 36.340494],
          [61.152255, 36.341649],
          [61.153375, 36.348147],
          [61.155781, 36.35023],
          [61.156389, 36.352409],
          [61.157476, 36.354995],
          [61.151111, 36.361944],
          [61.149444, 36.366944],
          [61.148056, 36.371944],
          [61.147222, 36.377778],
          [61.145556, 36.382778],
          [61.143889, 36.387778],
          [61.1425, 36.392778],
          [61.144444, 36.398889],
          [61.149167, 36.401667],
          [61.152938, 36.402838],
          [61.157326, 36.405054],
          [61.160474, 36.409808],
          [61.166353, 36.413586],
          [61.167355, 36.416101],
          [61.168578, 36.42008],
          [61.165, 36.425278],
          [61.166111, 36.432222],
          [61.166389, 36.438333],
          [61.166624, 36.441238],
          [61.163244, 36.44592],
          [61.162236, 36.452471],
          [61.166821, 36.454209],
          [61.173789, 36.457674],
          [61.175758, 36.464163],
          [61.174639, 36.466856],
          [61.173333, 36.47],
          [61.170344, 36.476086],
          [61.171667, 36.48],
          [61.168056, 36.483889],
          [61.156753, 36.486417],
          [61.158039, 36.48908],
          [61.158928, 36.491302],
          [61.160226, 36.493263],
          [61.161986, 36.497455],
          [61.162219, 36.499417],
          [61.161555, 36.502045],
          [61.160361, 36.503361],
          [61.158761, 36.504438],
          [61.157796, 36.505314],
          [61.156877, 36.507402],
          [61.157485, 36.508246],
          [61.15963, 36.508689],
          [61.161355, 36.509242],
          [61.162475, 36.509841],
          [61.163241, 36.510849],
          [61.164446, 36.51552],
          [61.165013, 36.516338],
          [61.166071, 36.517806],
          [61.166898, 36.519025],
          [61.16757, 36.519741],
          [61.168634, 36.520584],
          [61.169601, 36.521209],
          [61.170741, 36.521826],
          [61.173099, 36.522771],
          [61.175303, 36.52304],
          [61.176902, 36.523298],
          [61.178473, 36.523656],
          [61.180236, 36.524292],
          [61.181306, 36.524875],
          [61.182219, 36.525769],
          [61.182541, 36.526579],
          [61.182914, 36.527423],
          [61.182878, 36.528215],
          [61.182669, 36.528912],
          [61.182192, 36.529788],
          [61.18202, 36.52996],
          [61.181836, 36.530304],
          [61.181559, 36.530599],
          [61.1809, 36.531381],
          [61.179664, 36.532013],
          [61.178897, 36.532513],
          [61.177877, 36.533334],
          [61.177074, 36.534154],
          [61.176502, 36.534954],
          [61.176002, 36.536085],
          [61.175657, 36.536939],
          [61.175652, 36.537581],
          [61.176085, 36.538381],
          [61.176577, 36.539052],
          [61.177299, 36.539881],
          [61.178032, 36.540939],
          [61.17878, 36.54184],
          [61.180103, 36.543206],
          [61.181252, 36.544155],
          [61.182161, 36.54491],
          [61.183574, 36.545943],
          [61.184391, 36.546914],
          [61.185241, 36.548191],
          [61.185556, 36.549444],
          [61.18489, 36.553932],
          [61.184188, 36.558277],
          [61.186003, 36.564321],
          [61.183904, 36.572331],
          [61.181314, 36.575816],
          [61.179052, 36.582865],
          [61.177778, 36.590556],
          [61.174167, 36.594444],
          [61.170833, 36.598333],
          [61.169692, 36.602317],
          [61.167854, 36.608398],
          [61.165621, 36.610682],
          [61.16311, 36.615084],
          [61.154342, 36.620205],
          [61.152502, 36.624194],
          [61.149315, 36.629283],
          [61.146544, 36.633601],
          [61.146404, 36.637223],
          [61.145768, 36.641085],
          [61.146307, 36.644431],
          [61.146194, 36.646493],
          [61.138782, 36.645788],
          [61.128865, 36.644295],
          [61.101295, 36.643828],
          [61.076954, 36.642684],
          [61.062595, 36.641981],
          [61.037081, 36.640734],
          [61.015977, 36.639742],
          [61.007169, 36.6393],
          [60.996886, 36.638901],
          [60.979364, 36.638002],
          [60.952839, 36.636825],
          [60.928845, 36.635688],
          [60.904401, 36.634593],
          [60.880415, 36.633356],
          [60.853017, 36.632052],
          [60.825238, 36.630516],
          [60.807335, 36.629649],
          [60.784091, 36.62837],
          [60.757298, 36.62695],
          [60.738264, 36.625882],
          [60.715807, 36.62457],
          [60.693702, 36.62325],
          [60.675221, 36.622212],
          [60.653618, 36.624063],
          [60.636257, 36.625541],
          [60.608715, 36.627961],
          [60.585741, 36.628178],
          [60.571125, 36.628315],
          [60.544724, 36.628536],
          [60.512036, 36.627031],
          [60.488855, 36.62599],
          [60.451978, 36.626298],
          [60.434943, 36.626958],
          [60.422626, 36.627412],
          [60.389974, 36.628537],
          [60.360624, 36.629492],
          [60.358726, 36.629554],
          [60.345542, 36.629394],
          [60.344421, 36.632773],
          [60.344624, 36.638809],
          [60.34535, 36.642733],
          [60.342884, 36.642751],
          [60.340633, 36.643031],
          [60.336381, 36.644618],
          [60.331893, 36.650068],
          [60.325471, 36.658928],
          [60.319665, 36.664991],
          [60.31102, 36.674009],
          [60.307137, 36.679862],
          [60.302967, 36.686423],
          [60.29709, 36.696981],
          [60.293076, 36.705611],
          [60.289268, 36.713449],
          [60.283485, 36.723168],
          [60.277112, 36.732756],
          [60.268313, 36.742572],
          [60.266905, 36.75721],
          [60.259135, 36.763472],
          [60.251252, 36.77001],
          [60.244321, 36.777532],
          [60.244143, 36.779341],
          [60.245103, 36.781338],
          [60.243874, 36.78295],
          [60.240802, 36.784716],
          [60.238666, 36.785943],
          [60.231464, 36.791711],
          [60.22111, 36.8044],
          [60.217149, 36.812617],
          [60.214988, 36.820329],
          [60.213534, 36.821714],
          [60.209586, 36.822923],
          [60.204283, 36.823054],
          [60.197018, 36.829272],
          [60.191566, 36.83079],
          [60.186848, 36.833448],
          [60.169594, 36.847837],
          [60.163164, 36.856232],
          [60.15756, 36.866864],
          [60.154914, 36.869699],
          [60.152235, 36.873151],
          [60.146189, 36.878385],
          [60.144956, 36.880528],
          [60.144114, 36.883622],
          [60.142411, 36.884652],
          [60.138778, 36.888139],
          [60.135479, 36.891448],
          [60.135088, 36.893013],
          [60.135423, 36.895516],
          [60.133411, 36.89869],
          [60.130334, 36.900845],
          [60.126631, 36.903236],
          [60.12464, 36.905492],
          [60.124237, 36.906668],
          [60.12106, 36.909227],
          [60.110818, 36.916942],
          [60.107139, 36.921127],
          [60.104204, 36.924466],
          [60.094554, 36.936646],
          [60.093156, 36.939551],
          [60.092152, 36.94276],
          [60.091762, 36.944979],
          [60.091283, 36.947054],
          [60.091632, 36.950512],
          [60.092151, 36.953064],
          [60.092812, 36.956154],
          [60.092917, 36.958571],
          [60.092545, 36.962347],
          [60.091916, 36.965171],
          [60.091141, 36.967738],
          [60.089005, 36.971724],
          [60.086448, 36.975647],
          [60.084984, 36.977755],
          [60.083665, 36.979075],
          [60.08123, 36.981835],
          [60.07097, 36.99094],
          [60.063879, 36.998497],
          [60.054067, 37.008261],
          [60.043069, 37.020764],
          [60.035179, 37.024179],
          [60.029999, 37.026703],
          [60.016824, 37.035687],
          [60.014888, 37.036501],
          [60.012501, 37.037113],
          [60.009562, 37.037426],
          [60.007814, 37.038224],
          [60.00591, 37.039928],
          [60.005251, 37.040596],
          [60.004201, 37.041037],
          [60.001528, 37.041905],
          [59.981468, 37.047114],
          [59.968402, 37.043346],
          [59.960464, 37.043074],
          [59.949738, 37.042623],
          [59.931066, 37.042301],
          [59.918248, 37.045402],
          [59.911495, 37.050266],
          [59.9084, 37.051335],
          [59.906431, 37.051401],
          [59.903847, 37.051185],
          [59.90229, 37.051335],
          [59.900744, 37.051124],
          [59.899529, 37.051446],
          [59.898236, 37.052847],
          [59.895418, 37.055096],
          [59.894401, 37.057751],
          [59.893584, 37.058863],
          [59.891629, 37.059742],
          [59.89089, 37.0607],
          [59.887333, 37.062285],
          [59.883267, 37.06505],
          [59.879479, 37.067483],
          [59.876799, 37.068036],
          [59.873981, 37.067668],
          [59.872448, 37.0679],
          [59.861362, 37.076688],
          [59.859212, 37.078031],
          [59.857128, 37.07999],
          [59.854822, 37.081473],
          [59.853557, 37.08272],
          [59.849911, 37.085866],
          [59.848795, 37.087647],
          [59.848177, 37.088677],
          [59.846507, 37.090581],
          [59.843834, 37.092104],
          [59.841124, 37.094693],
          [59.839449, 37.096127],
          [59.83541, 37.099174],
          [59.832418, 37.100438],
          [59.831464, 37.100175],
          [59.830624, 37.099718],
          [59.829607, 37.097904],
          [59.82866, 37.097472],
          [59.82724, 37.098014],
          [59.825265, 37.099612],
          [59.82264, 37.102705],
          [59.818649, 37.104863],
          [59.814929, 37.105241],
          [59.812112, 37.10419],
          [59.804135, 37.11256],
          [59.803029, 37.111419],
          [59.801014, 37.109925],
          [59.799651, 37.109229],
          [59.79907, 37.111419],
          [59.797516, 37.112713],
          [59.794913, 37.113915],
          [59.791514, 37.114828],
          [59.785985, 37.118123],
          [59.782061, 37.120361],
          [59.771848, 37.12535],
          [59.766098, 37.125457],
          [59.762296, 37.126687],
          [59.76167, 37.125518],
          [59.759641, 37.123846],
          [59.758965, 37.122174],
          [59.759278, 37.120282],
          [59.757341, 37.118884],
          [59.756597, 37.116996],
          [59.75565, 37.112519],
          [59.755286, 37.108806],
          [59.749766, 37.108743],
          [59.747405, 37.10778],
          [59.745572, 37.10993],
          [59.742461, 37.110901],
          [59.736576, 37.112193],
          [59.734547, 37.112951],
          [59.732382, 37.114731],
          [59.729609, 37.118291],
          [59.726227, 37.122983],
          [59.726657, 37.126511],
          [59.722288, 37.127421],
          [59.714783, 37.130326],
          [59.710343, 37.130764],
          [59.685696, 37.138581],
          [59.682501, 37.140174],
          [59.678999, 37.14122],
          [59.667426, 37.141121],
          [59.664045, 37.142614],
          [59.661344, 37.143044],
          [59.657279, 37.142464],
          [59.653491, 37.14221],
          [59.652799, 37.139788],
          [59.651246, 37.137074],
          [59.650258, 37.134521],
          [59.649702, 37.132699],
          [59.647565, 37.130916],
          [59.646701, 37.129214],
          [59.645126, 37.125162],
          [59.645231, 37.122382],
          [59.646406, 37.120621],
          [59.643399, 37.119237],
          [59.641691, 37.120065],
          [59.64016, 37.119515],
          [59.637357, 37.120446],
          [59.626529, 37.118654],
          [59.626275, 37.119326],
          [59.6257, 37.120287],
          [59.62479, 37.121674],
          [59.623772, 37.122762],
          [59.623197, 37.122912],
          [59.622407, 37.122677],
          [59.62115, 37.121695],
          [59.620403, 37.120519],
          [59.619154, 37.120159],
          [59.617411, 37.120073],
          [59.615718, 37.12384],
          [59.614017, 37.125694],
          [59.612536, 37.126385],
          [59.611437, 37.12657],
          [59.610888, 37.126997],
          [59.60971, 37.128106],
          [59.608814, 37.128021],
          [59.608037, 37.12817],
          [59.607449, 37.128629],
          [59.606728, 37.129685],
          [59.605629, 37.129771],
          [59.604548, 37.130257],
          [59.603742, 37.129974],
          [59.603056, 37.130257],
          [59.602507, 37.130719],
          [59.601602, 37.131562],
          [59.600266, 37.13225],
          [59.599224, 37.13251],
          [59.597503, 37.131436],
          [59.596177, 37.131046],
          [59.595153, 37.131027],
          [59.593036, 37.132162],
          [59.590822, 37.13287],
          [59.588912, 37.133733],
          [59.588328, 37.133655],
          [59.587623, 37.133132],
          [59.58671, 37.13319],
          [59.585492, 37.133715],
          [59.584878, 37.13424],
          [59.584145, 37.134222],
          [59.583479, 37.133736],
          [59.582971, 37.131994],
          [59.581714, 37.13105],
          [59.579009, 37.130715],
          [59.575999, 37.131475],
          [59.571617, 37.132764],
          [59.567706, 37.133221],
          [59.564707, 37.13268],
          [59.562255, 37.131085],
          [59.560281, 37.131168],
          [59.558164, 37.131721],
          [59.557088, 37.132524],
          [59.556116, 37.133658],
          [59.553929, 37.135402],
          [59.552541, 37.136204],
          [59.550876, 37.138151],
          [59.550779, 37.13946],
          [59.551138, 37.142041],
          [59.551146, 37.142952],
          [59.550624, 37.143166],
          [59.549491, 37.143226],
          [59.548509, 37.143664],
          [59.547073, 37.144808],
          [59.546198, 37.14733],
          [59.546342, 37.153607],
          [59.544272, 37.156038],
          [59.543936, 37.158359],
          [59.545579, 37.160986],
          [59.54656, 37.162809],
          [59.548194, 37.164719],
          [59.547627, 37.168267],
          [59.548341, 37.170178],
          [59.551139, 37.170948],
          [59.552507, 37.172082],
          [59.552868, 37.174694],
          [59.552858, 37.175548],
          [59.552527, 37.175807],
          [59.552258, 37.176633],
          [59.552257, 37.176847],
          [59.551731, 37.177568],
          [59.550516, 37.177479],
          [59.545557, 37.176948],
          [59.542014, 37.178044],
          [59.540255, 37.179533],
          [59.538527, 37.182043],
          [59.53775, 37.185298],
          [59.535915, 37.185639],
          [59.533554, 37.185658],
          [59.531973, 37.186598],
          [59.530708, 37.187161],
          [59.529272, 37.186046],
          [59.528433, 37.18499],
          [59.527873, 37.185154],
          [59.526722, 37.186233],
          [59.523897, 37.188314],
          [59.522305, 37.190397],
          [59.511481, 37.194572],
          [59.500258, 37.200008],
          [59.491144, 37.20717],
          [59.489134, 37.207729],
          [59.487103, 37.208292],
          [59.485077, 37.209067],
          [59.482216, 37.210625],
          [59.474738, 37.214775],
          [59.474407, 37.218606],
          [59.474572, 37.21883],
          [59.475184, 37.219068],
          [59.478018, 37.218656],
          [59.48045, 37.219074],
          [59.482872, 37.219209],
          [59.484195, 37.219442],
          [59.486056, 37.220477],
          [59.485895, 37.221757],
          [59.487186, 37.222191],
          [59.488396, 37.224859],
          [59.490157, 37.226494],
          [59.491833, 37.230043],
          [59.49211, 37.232103],
          [59.491937, 37.23363],
          [59.488795, 37.240377],
          [59.483426, 37.248484],
          [59.477843, 37.25029],
          [59.473786, 37.252268],
          [59.472357, 37.252556],
          [59.46781, 37.257898],
          [59.465749, 37.260764],
          [59.465128, 37.263975],
          [59.46447, 37.266086],
          [59.451083, 37.26961],
          [59.450074, 37.271355],
          [59.450502, 37.273304],
          [59.451981, 37.276961],
          [59.452283, 37.278856],
          [59.449124, 37.279358],
          [59.446008, 37.280271],
          [59.440834, 37.282436],
          [59.438303, 37.284492],
          [59.437648, 37.284709],
          [59.436972, 37.285174],
          [59.435967, 37.286962],
          [59.435771, 37.287311],
          [59.435208, 37.287997],
          [59.434366, 37.289015],
          [59.433623, 37.290233],
          [59.432805, 37.291869],
          [59.432551, 37.292599],
          [59.433727, 37.293693],
          [59.434612, 37.294906],
          [59.43509, 37.295938],
          [59.43512, 37.296847],
          [59.435194, 37.304083],
          [59.433496, 37.305486],
          [59.430806, 37.307101],
          [59.428553, 37.307784],
          [59.426959, 37.308277],
          [59.425946, 37.307801],
          [59.423149, 37.305976],
          [59.417207, 37.308036],
          [59.415386, 37.3074],
          [59.414432, 37.306879],
          [59.41363, 37.306614],
          [59.411768, 37.30749],
          [59.409679, 37.307323],
          [59.408303, 37.307773],
          [59.405037, 37.310057],
          [59.399897, 37.315197],
          [59.396898, 37.317303],
          [59.398099, 37.31861],
          [59.399203, 37.319639],
          [59.400311, 37.323749],
          [59.396874, 37.326396],
          [59.396769, 37.327808],
          [59.392452, 37.332375],
          [59.393515, 37.337754],
          [59.39046, 37.342184],
          [59.389491, 37.351275],
          [59.390308, 37.360535],
          [59.387877, 37.373698],
          [59.382786, 37.39112],
          [59.377877, 37.40759],
          [59.382719, 37.420963],
          [59.390152, 37.423945],
          [59.399081, 37.427186],
          [59.402625, 37.4295],
          [59.405538, 37.431751],
          [59.411345, 37.435774],
          [59.404936, 37.441559],
          [59.400111, 37.445529],
          [59.396077, 37.448997],
          [59.391477, 37.446929],
          [59.3876, 37.445316],
          [59.384024, 37.447871],
          [59.381421, 37.449746],
          [59.385809, 37.461535],
          [59.388953, 37.469992],
          [59.392131, 37.479137],
          [59.388685, 37.484857],
          [59.38442, 37.492197],
          [59.377616, 37.497787],
          [59.36883, 37.50502],
          [59.361109, 37.511439],
          [59.357349, 37.51981],
          [59.353479, 37.528416],
          [59.345264, 37.529249],
          [59.337186, 37.530065],
          [59.329987, 37.529515],
          [59.319389, 37.530027],
          [59.308131, 37.524659],
          [59.298883, 37.520227],
          [59.292887, 37.517262],
          [59.2884, 37.514632],
          [59.284074, 37.51323],
          [59.283376, 37.512383],
          [59.28388, 37.511613],
          [59.283954, 37.510912],
          [59.283763, 37.510352],
          [59.283306, 37.510223],
          [59.282055, 37.510725],
          [59.281613, 37.511052],
          [59.281157, 37.511099],
          [59.280465, 37.510772],
          [59.279685, 37.509768],
          [59.278919, 37.509114],
          [59.277757, 37.508588],
          [59.276189, 37.508642],
          [59.2739, 37.508191],
          [59.272428, 37.508168],
          [59.271412, 37.508483],
          [59.269896, 37.509149],
          [59.268674, 37.509149],
          [59.268012, 37.509534],
          [59.267599, 37.51034],
          [59.266966, 37.510702],
          [59.266098, 37.510795],
          [59.265877, 37.510515],
          [59.266039, 37.510025],
          [59.265656, 37.509768],
          [59.264729, 37.509931],
          [59.263448, 37.510608],
          [59.262035, 37.51215],
          [59.260681, 37.512038],
          [59.260266, 37.512024],
          [59.259877, 37.512332],
          [59.260006, 37.512871],
          [59.260233, 37.513732],
          [59.259828, 37.514451],
          [59.259051, 37.514657],
          [59.257982, 37.514503],
          [59.256947, 37.514005],
          [59.256266, 37.51395],
          [59.255942, 37.514194],
          [59.255699, 37.515235],
          [59.25489, 37.515941],
          [59.254291, 37.51666],
          [59.253983, 37.51684],
          [59.253643, 37.516583],
          [59.252833, 37.516699],
          [59.251943, 37.517071],
          [59.251003, 37.517662],
          [59.249757, 37.518227],
          [59.248866, 37.518214],
          [59.247036, 37.51788],
          [59.246907, 37.51747],
          [59.246971, 37.516699],
          [59.246681, 37.516261],
          [59.24638, 37.516173],
          [59.245757, 37.516699],
          [59.245417, 37.517367],
          [59.245547, 37.517778],
          [59.246097, 37.518009],
          [59.246372, 37.518638],
          [59.246113, 37.519229],
          [59.245466, 37.519627],
          [59.243943, 37.519846],
          [59.241757, 37.520192],
          [59.240624, 37.520789],
          [59.239627, 37.520958],
          [59.238896, 37.52069],
          [59.238006, 37.519814],
          [59.237133, 37.519475],
          [59.236171, 37.518655],
          [59.235794, 37.517519],
          [59.235893, 37.5166],
          [59.235344, 37.515602],
          [59.234099, 37.514742],
          [59.232614, 37.514573],
          [59.231176, 37.514658],
          [59.230024, 37.514007],
          [59.22925, 37.51205],
          [59.221812, 37.515462],
          [59.213875, 37.520353],
          [59.210144, 37.522652],
          [59.207625, 37.526484],
          [59.204783, 37.528124],
          [59.202723, 37.528599],
          [59.200188, 37.532125],
          [59.194671, 37.535136],
          [59.1918, 37.536289],
          [59.190842, 37.538484],
          [59.189737, 37.540035],
          [59.188232, 37.540699],
          [59.186329, 37.541261],
          [59.183853, 37.541335],
          [59.182574, 37.540336],
          [59.182637, 37.538834],
          [59.180618, 37.538284],
          [59.178567, 37.539285],
          [59.174086, 37.542763],
          [59.167074, 37.54917],
          [59.154722, 37.571111],
          [59.147222, 37.5725],
          [59.140556, 37.574722],
          [59.133889, 37.576667],
          [59.128333, 37.579444],
          [59.122778, 37.581944],
          [59.118056, 37.585278],
          [59.113333, 37.588611],
          [59.108611, 37.591667],
          [59.105, 37.595556],
          [59.100278, 37.598889],
          [59.095556, 37.601944],
          [59.090833, 37.605278],
          [59.086389, 37.608611],
          [59.081667, 37.611944],
          [59.077778, 37.615556],
          [59.073333, 37.618889],
          [59.068611, 37.622778],
          [59.065, 37.626667],
          [59.060278, 37.63],
          [59.052778, 37.631389],
          [59.045556, 37.630833],
          [59.038611, 37.63],
          [59.030833, 37.630278],
          [59.024167, 37.632222],
          [59.018333, 37.635],
          [59.012778, 37.6375],
          [59.008056, 37.640833],
          [59.003611, 37.644167],
          [58.997778, 37.646667],
          [58.992222, 37.649444],
          [58.986667, 37.652222],
          [58.980833, 37.654722],
          [58.974167, 37.656667],
          [58.9675, 37.658889],
          [58.961111, 37.660833],
          [58.954444, 37.663056],
          [58.947778, 37.665],
          [58.941111, 37.667222],
          [58.933611, 37.668611],
          [58.926111, 37.67],
          [58.918889, 37.669444],
          [58.9125, 37.668056],
          [58.905556, 37.6675],
          [58.898611, 37.666667],
          [58.890833, 37.666944],
          [58.883889, 37.6675],
          [58.876389, 37.669167],
          [58.870556, 37.671667],
          [58.865, 37.674444],
          [58.860278, 37.6775],
          [58.855556, 37.680833],
          [58.851944, 37.684722],
          [58.848056, 37.688611],
          [58.843333, 37.691667],
          [58.839722, 37.695556],
          [58.836111, 37.699444],
          [58.830278, 37.701944],
          [58.822778, 37.703611],
          [58.815556, 37.702778],
          [58.813056, 37.6975],
          [58.813889, 37.691944],
          [58.814722, 37.686389],
          [58.814722, 37.680278],
          [58.812778, 37.674167],
          [58.808611, 37.670556],
          [58.804444, 37.666944],
          [58.798889, 37.664722],
          [58.793333, 37.6625],
          [58.786389, 37.661667],
          [58.778611, 37.661944],
          [58.770833, 37.663333],
          [58.763333, 37.664722],
          [58.755833, 37.666389],
          [58.748889, 37.666944],
          [58.743333, 37.664722],
          [58.738611, 37.661944],
          [58.735, 37.6575],
          [58.731667, 37.653056],
          [58.726111, 37.650833],
          [58.72, 37.649444],
          [58.712778, 37.648611],
          [58.704444, 37.649444],
          [58.696389, 37.649722],
          [58.688056, 37.650556],
          [58.680278, 37.651944],
          [58.672778, 37.653611],
          [58.665278, 37.655],
          [58.659444, 37.6575],
          [58.652778, 37.659722],
          [58.646389, 37.661667],
          [58.640556, 37.664444],
          [58.635, 37.666944],
          [58.629167, 37.669722],
          [58.623611, 37.672222],
          [58.618056, 37.675],
          [58.613333, 37.678056],
          [58.608611, 37.681389],
          [58.603889, 37.685278],
          [58.597462, 37.689857],
          [58.59644, 37.689213],
          [58.595967, 37.688716],
          [58.595603, 37.688776],
          [58.592311, 37.690152],
          [58.586791, 37.693377],
          [58.582182, 37.694941],
          [58.57617, 37.696568],
          [58.570377, 37.698538],
          [58.566271, 37.699895],
          [58.556651, 37.705503],
          [58.55, 37.705833],
          [58.544444, 37.703611],
          [58.539444, 37.700833],
          [58.534722, 37.697778],
          [58.531389, 37.693333],
          [58.527222, 37.689722],
          [58.524722, 37.684444],
          [58.522222, 37.679167],
          [58.518889, 37.674722],
          [58.516111, 37.669444],
          [58.513611, 37.664167],
          [58.510278, 37.659722],
          [58.506944, 37.655278],
          [58.502778, 37.651389],
          [58.498056, 37.647778],
          [58.4925, 37.645556],
          [58.489603, 37.644397],
          [58.486944, 37.643333],
          [58.480556, 37.641944],
          [58.474167, 37.640556],
          [58.467222, 37.64],
          [58.460278, 37.640556],
          [58.451667, 37.641389],
          [58.440636, 37.638343],
          [58.436919, 37.640333],
          [58.432246, 37.641459],
          [58.426976, 37.641158],
          [58.422165, 37.64065],
          [58.417974, 37.63929],
          [58.411848, 37.633934],
          [58.407639, 37.62974],
          [58.407296, 37.629653],
          [58.406246, 37.629474],
          [58.405666, 37.629375],
          [58.405222, 37.629261],
          [58.404247, 37.629159],
          [58.403294, 37.628966],
          [58.401624, 37.62849],
          [58.400195, 37.627997],
          [58.399869, 37.627981],
          [58.394565, 37.62886],
          [58.392123, 37.628903],
          [58.391804, 37.628672],
          [58.390303, 37.627277],
          [58.390132, 37.627201],
          [58.388751, 37.626985],
          [58.388434, 37.626985],
          [58.386803, 37.627372],
          [58.385729, 37.627775],
          [58.384582, 37.628356],
          [58.38384, 37.628956],
          [58.382119, 37.630677],
          [58.381569, 37.631601],
          [58.381582, 37.632259],
          [58.381639, 37.63284],
          [58.381141, 37.633285],
          [58.380635, 37.633712],
          [58.380793, 37.635023],
          [58.381023, 37.6356],
          [58.380988, 37.635797],
          [58.380871, 37.636002],
          [58.380667, 37.636429],
          [58.380559, 37.6365],
          [58.380439, 37.636516],
          [58.37799, 37.63633],
          [58.376868, 37.636552],
          [58.375921, 37.636612],
          [58.375249, 37.636546],
          [58.373959, 37.635919],
          [58.372259, 37.636347],
          [58.371576, 37.636402],
          [58.369702, 37.636348],
          [58.36838, 37.63618],
          [58.366943, 37.636774],
          [58.366652, 37.636981],
          [58.366309, 37.637247],
          [58.36192, 37.643642],
          [58.36369, 37.648621],
          [58.35839, 37.653964],
          [58.358178, 37.659549],
          [58.359651, 37.661107],
          [58.351667, 37.661944],
          [58.343889, 37.663333],
          [58.336111, 37.663333],
          [58.328333, 37.663333],
          [58.321389, 37.662778],
          [58.314167, 37.662222],
          [58.311205, 37.662222],
          [58.298912, 37.654711],
          [58.299905, 37.65253],
          [58.297905, 37.637371],
          [58.314249, 37.587499],
          [58.320788, 37.56726],
          [58.326206, 37.539024],
          [58.338652, 37.499986],
          [58.337917, 37.454938],
          [58.34942, 37.443296],
          [58.367943, 37.429292],
          [58.368223, 37.424569],
          [58.367663, 37.421241],
          [58.367943, 37.417066],
          [58.36386, 37.401536],
          [58.35886, 37.39487],
          [58.340567, 37.385917],
          [58.33668, 37.382031],
          [58.317987, 37.367792],
          [58.316321, 37.361962],
          [58.316041, 37.360856],
          [58.311921, 37.357491],
          [58.309664, 37.351952],
          [58.30925, 37.343141],
          [58.32354, 37.332517],
          [58.32983, 37.327215],
          [58.367625, 37.29765],
          [58.36905, 37.296515],
          [58.369883, 37.296245],
          [58.378233, 37.289921],
          [58.403233, 37.275751],
          [58.406566, 37.273528],
          [58.414326, 37.258865],
          [58.415159, 37.258865],
          [58.415433, 37.258586],
          [58.419112, 37.248548],
          [58.417726, 37.244382],
          [58.421246, 37.216933],
          [58.418466, 37.214161],
          [58.417632, 37.213882],
          [58.389653, 37.211958],
          [58.387713, 37.211122],
          [58.386879, 37.211122],
          [58.386879, 37.210843],
          [58.384933, 37.210015],
          [58.384653, 37.209736],
          [58.382706, 37.207792],
          [58.381872, 37.207513],
          [58.370683, 37.211016],
          [58.358586, 37.215378],
          [58.355813, 37.215378],
          [58.355253, 37.215099],
          [58.353866, 37.215099],
          [58.353313, 37.215378],
          [58.3497, 37.215378],
          [58.348586, 37.215657],
          [58.345533, 37.215657],
          [58.344146, 37.215378],
          [58.3372, 37.215378],
          [58.33664, 37.215099],
          [58.33414, 37.215099],
          [58.31785, 37.20739],
          [58.317297, 37.206832],
          [58.308964, 37.20128],
          [58.306184, 37.198779],
          [58.30535, 37.197115],
          [58.30507, 37.19572],
          [58.304237, 37.193219],
          [58.303964, 37.192949],
          [58.31076, 37.179456],
          [58.31076, 37.177512],
          [58.310487, 37.175289],
          [58.310207, 37.17501],
          [58.310433, 37.16507],
          [58.3096, 37.164791],
          [58.309047, 37.164233],
          [58.306827, 37.163684],
          [58.305433, 37.163684],
          [58.30516, 37.163405],
          [58.304047, 37.163405],
          [58.299374, 37.167514],
          [58.293177, 37.173259],
          [58.292623, 37.173538],
          [58.282787, 37.180955],
          [58.281681, 37.181512],
          [58.280287, 37.181791],
          [58.27862, 37.182628],
          [58.263244, 37.183023],
          [58.251268, 37.181394],
          [58.250714, 37.181394],
          [58.250154, 37.181394],
          [58.247623, 37.181394],
          [58.247101, 37.181394],
          [58.246821, 37.181115],
          [58.247448, 37.17803],
          [58.246614, 37.176645],
          [58.250847, 37.173272],
          [58.256721, 37.165242],
          [58.265991, 37.154844],
          [58.261528, 37.149919],
          [58.244362, 37.150193],
          [58.232345, 37.149372],
          [58.211765, 37.149704],
          [58.210098, 37.148039],
          [58.209545, 37.146923],
          [58.19561, 37.128298],
          [58.184148, 37.11215],
          [58.174921, 37.090848],
          [58.173434, 37.089099],
          [58.174894, 37.088419],
          [58.179061, 37.086475],
          [58.215387, 37.075371],
          [58.227001, 37.074414],
          [58.233032, 37.068602],
          [58.233032, 37.056821],
          [58.244094, 37.046954],
          [58.2477, 37.040843],
          [58.248261, 37.038898],
          [58.248534, 37.037782],
          [58.254587, 37.034091],
          [58.256354, 37.030452],
          [58.258573, 37.028229],
          [58.26503, 37.023833],
          [58.26725, 37.023833],
          [58.27514, 37.021878],
          [58.28939, 37.018758],
          [58.299576, 37.017108],
          [58.311589, 37.017317],
          [58.336062, 37.014252],
          [58.352861, 37.000185],
          [58.356231, 36.997688],
          [58.356784, 36.989912],
          [58.356638, 36.986024],
          [58.346275, 36.96011],
          [58.339689, 36.951326],
          [58.316159, 36.938125],
          [58.315586, 36.937562],
          [58.311979, 36.933942],
          [58.311979, 36.933384],
          [58.311419, 36.93089],
          [58.27973, 36.919846],
          [58.27695, 36.917073],
          [58.276397, 36.915956],
          [58.258813, 36.910835],
          [58.231153, 36.882401],
          [58.227267, 36.881563],
          [58.226153, 36.881563],
          [58.2256, 36.881284],
          [58.22338, 36.881284],
          [58.2231, 36.881005],
          [58.221153, 36.881005],
          [58.2206, 36.880726],
          [58.219767, 36.880726],
          [58.21782, 36.880168],
          [58.215047, 36.881097],
          [58.214487, 36.881284],
          [58.213282, 36.881328],
          [58.212401, 36.88186],
          [58.211153, 36.882122],
          [58.2106, 36.881843],
          [58.20532, 36.881842],
          [58.20504, 36.881563],
          [58.203934, 36.881563],
          [58.203374, 36.881284],
          [58.202267, 36.881284],
          [58.201707, 36.881005],
          [58.2006, 36.881005],
          [58.20032, 36.880726],
          [58.199487, 36.880726],
          [58.195953, 36.888134],
          [58.154641, 36.875564],
          [58.141844, 36.870937],
          [58.117611, 36.865645],
          [58.113445, 36.865645],
          [58.113172, 36.865366],
          [58.084509, 36.854653],
          [58.083955, 36.854374],
          [58.079789, 36.854374],
          [58.079236, 36.854095],
          [58.078402, 36.854095],
          [58.077009, 36.853546],
          [58.076176, 36.853267],
          [58.08506, 36.831272],
          [58.093918, 36.817642],
          [58.094668, 36.813768],
          [58.093282, 36.807384],
          [58.097401, 36.807334],
          [58.099035, 36.803944],
          [58.098762, 36.803394],
          [58.099035, 36.802836],
          [58.112738, 36.792262],
          [58.117177, 36.784483],
          [58.125511, 36.771426],
          [58.125511, 36.768932],
          [58.125237, 36.767537],
          [58.124958, 36.766429],
          [58.110466, 36.757591],
          [58.097761, 36.748542],
          [58.099136, 36.733107],
          [58.079665, 36.713979],
          [58.079392, 36.712871],
          [58.089641, 36.705277],
          [58.090748, 36.701117],
          [58.090896, 36.692374],
          [58.080597, 36.684114],
          [58.085625, 36.676745],
          [58.085905, 36.674521],
          [58.086178, 36.668966],
          [58.075655, 36.661709],
          [58.065139, 36.64544],
          [58.064032, 36.642658],
          [58.079142, 36.624637],
          [58.074975, 36.618532],
          [58.052889, 36.609918],
          [58.032859, 36.596052],
          [58.031192, 36.595772],
          [58.026192, 36.594386],
          [58.019762, 36.58996],
          [58.018095, 36.589401],
          [58.010869, 36.588294],
          [58.008929, 36.588294],
          [58.008649, 36.588242],
          [58.005869, 36.587735],
          [58.003522, 36.581948],
          [57.994652, 36.572556],
          [57.991872, 36.572556],
          [57.990765, 36.572277],
          [57.977882, 36.567866],
          [57.977049, 36.567586],
          [57.972883, 36.567316],
          [57.967602, 36.567316],
          [57.966216, 36.567037],
          [57.958989, 36.567037],
          [57.957603, 36.567316],
          [57.933436, 36.571757],
          [57.93205, 36.572315],
          [57.931216, 36.572315],
          [57.930103, 36.572874],
          [57.918417, 36.570938],
          [57.916743, 36.571776],
          [57.895947, 36.579067],
          [57.895154, 36.579386],
          [57.893207, 36.579944],
          [57.878963, 36.584837],
          [57.871464, 36.586504],
          [57.869524, 36.587053],
          [57.868411, 36.587053],
          [57.821571, 36.616108],
          [57.777398, 36.630274],
          [57.752125, 36.640558],
          [57.745732, 36.643332],
          [57.718232, 36.657497],
          [57.715732, 36.658335],
          [57.707952, 36.661946],
          [57.701839, 36.663612],
          [57.663742, 36.678513],
          [57.658506, 36.68056],
          [57.63962, 36.691384],
          [57.615823, 36.702026],
          [57.611937, 36.705637],
          [57.606937, 36.708969],
          [57.580884, 36.719856],
          [57.547074, 36.743382],
          [57.545408, 36.743652],
          [57.536794, 36.74704],
          [57.535688, 36.747263],
          [57.529024, 36.754179],
          [57.526798, 36.754458],
          [57.517388, 36.758611],
          [57.510168, 36.761393],
          [57.509608, 36.761952],
          [57.506275, 36.763059],
          [57.505721, 36.763617],
          [57.499335, 36.765832],
          [57.492108, 36.768336],
          [57.489888, 36.769164],
          [57.454889, 36.778618],
          [57.447109, 36.780005],
          [57.444889, 36.780005],
          [57.443775, 36.780284],
          [57.441829, 36.780284],
          [57.405716, 36.786118],
          [57.403496, 36.786947],
          [57.401829, 36.787226],
          [57.373816, 36.792013],
          [57.371876, 36.79312],
          [57.296737, 36.809267],
          [57.29563, 36.809547],
          [57.293684, 36.809547],
          [57.292851, 36.809826],
          [57.243263, 36.820776],
          [57.242894, 36.820962],
          [57.241504, 36.820962],
          [57.23706, 36.82207],
          [57.231781, 36.822907],
          [57.20684, 36.824127],
          [57.195938, 36.834766],
          [57.194548, 36.834766],
          [57.193161, 36.835045],
          [57.190382, 36.835045],
          [57.189548, 36.835324],
          [57.187882, 36.835324],
          [57.187048, 36.835604],
          [57.185105, 36.835603],
          [57.182048, 36.836153],
          [57.178438, 36.836153],
          [57.175105, 36.836711],
          [57.173158, 36.836711],
          [57.171768, 36.83699],
          [57.169825, 36.83699],
          [57.168435, 36.837269],
          [57.166215, 36.837269],
          [57.164825, 36.837548],
          [57.151252, 36.836179],
          [57.146874, 36.837449],
          [57.144649, 36.837179],
          [57.139217, 36.837207],
          [57.138651, 36.837321],
          [57.137198, 36.837149],
          [57.136365, 36.837428],
          [57.133649, 36.837574],
          [57.129762, 36.838681],
          [57.122262, 36.841463],
          [57.117816, 36.840931],
          [57.116706, 36.841489],
          [57.103372, 36.845658],
          [57.102259, 36.845658],
          [57.090781, 36.847616],
          [57.059793, 36.853618],
          [57.058959, 36.854177],
          [57.041683, 36.856556],
          [57.04057, 36.856556],
          [57.03807, 36.857114],
          [57.03557, 36.857114],
          [57.034736, 36.857393],
          [57.03196, 36.857393],
          [57.031127, 36.857114],
          [57.025293, 36.857114],
          [57.02446, 36.856835],
          [57.02307, 36.856835],
          [57.022237, 36.856556],
          [57.016567, 36.860123],
          [57.015734, 36.860123],
          [57.013787, 36.859564],
          [56.988487, 36.867811],
          [56.958275, 36.871107],
          [56.942857, 36.882038],
          [56.940586, 36.88148],
          [56.932955, 36.885367],
          [56.932084, 36.885148],
          [56.924338, 36.888583],
          [56.913271, 36.890647],
          [56.888969, 36.882375],
          [56.882299, 36.880989],
          [56.851922, 36.858965],
          [56.838523, 36.853712],
          [56.831639, 36.851239],
          [56.826953, 36.847376],
          [56.808816, 36.839615],
          [56.802393, 36.829431],
          [56.80045, 36.829152],
          [56.79656, 36.828045],
          [56.79545, 36.828045],
          [56.790363, 36.823918],
          [56.789253, 36.823639],
          [56.788116, 36.819479],
          [56.78756, 36.8192],
          [56.785616, 36.8192],
          [56.785059, 36.818921],
          [56.779299, 36.816677],
          [56.777353, 36.816119],
          [56.774576, 36.814732],
          [56.773186, 36.814453],
          [56.763463, 36.810564],
          [56.724297, 36.800003],
          [56.72263, 36.799174],
          [56.72124, 36.798895],
          [56.715683, 36.796113],
          [56.712907, 36.795285],
          [56.699924, 36.778218],
          [56.6922, 36.76708],
          [56.675272, 36.759174],
          [56.673329, 36.758345],
          [56.655186, 36.751412],
          [56.651852, 36.748918],
          [56.614264, 36.722728],
          [56.612598, 36.721062],
          [56.610097, 36.719676],
          [56.607321, 36.717451],
          [56.599126, 36.71063],
          [56.599209, 36.695096],
          [56.610489, 36.68494],
          [56.625136, 36.680666],
          [56.641059, 36.675978],
          [56.648323, 36.673665],
          [56.651099, 36.672459],
          [56.693563, 36.662542],
          [56.694953, 36.662542],
          [56.69523, 36.662822],
          [56.698007, 36.663651],
          [56.704397, 36.664488],
          [56.706063, 36.66393],
          [56.713466, 36.668876],
          [56.717913, 36.668876],
          [56.718746, 36.669156],
          [56.72458, 36.669156],
          [56.725413, 36.669435],
          [56.737893, 36.675793],
          [56.744216, 36.679658],
          [56.745326, 36.679658],
          [56.750326, 36.680217],
          [56.753103, 36.680217],
          [56.754216, 36.680496],
          [56.757273, 36.680496],
          [56.758106, 36.680217],
          [56.759493, 36.680217],
          [56.768615, 36.682818],
          [56.774665, 36.675982],
          [56.774665, 36.675423],
          [56.773311, 36.673522],
          [56.769825, 36.6671],
          [56.763953, 36.663986],
          [56.756018, 36.653682],
          [56.755462, 36.652007],
          [56.755462, 36.651728],
          [56.755742, 36.649792],
          [56.756852, 36.645063],
          [56.752872, 36.60627],
          [56.755649, 36.596262],
          [56.769262, 36.588767],
          [56.770095, 36.588767],
          [56.771485, 36.588209],
          [56.772319, 36.588209],
          [56.773152, 36.58793],
          [56.774262, 36.58793],
          [56.775652, 36.58765],
          [56.779262, 36.587092],
          [56.780095, 36.586812],
          [56.818768, 36.589815],
          [56.844911, 36.582279],
          [56.852134, 36.57728],
          [56.864357, 36.560608],
          [56.864634, 36.5595],
          [56.865191, 36.557824],
          [56.868244, 36.549222],
          [56.869634, 36.543106],
          [56.868817, 36.537055],
          [56.868468, 36.535837],
          [56.868191, 36.534441],
          [56.864025, 36.520831],
          [56.862358, 36.518607],
          [56.862358, 36.518327],
          [56.860968, 36.516112],
          [56.857911, 36.511104],
          [56.856801, 36.509996],
          [56.856245, 36.508888],
          [56.816798, 36.461942],
          [56.802355, 36.447494],
          [56.800408, 36.445549],
          [56.785352, 36.424405],
          [56.763129, 36.41302],
          [56.736828, 36.387249],
          [56.735995, 36.386691],
          [56.734885, 36.385862],
          [56.732105, 36.382249],
          [56.724573, 36.345269],
          [56.727629, 36.341107],
          [56.731239, 36.334161],
          [56.732072, 36.32444],
          [56.731796, 36.318882],
          [56.731516, 36.318323],
          [56.732072, 36.31499],
          [56.732929, 36.313495],
          [56.733182, 36.313053],
          [56.729965, 36.298526],
          [56.736532, 36.272913],
          [56.746256, 36.267901],
          [56.755145, 36.260681],
          [56.760979, 36.246233],
          [56.762645, 36.24178],
          [56.762645, 36.2415],
          [56.765145, 36.236777],
          [56.766795, 36.181374],
          [56.768925, 36.187446],
          [56.763229, 36.170501],
          [56.763092, 36.169952],
          [56.762815, 36.169393],
          [56.758177, 36.153543],
          [56.757812, 36.152448],
          [56.757535, 36.152177],
          [56.753368, 36.139402],
          [56.752812, 36.138844],
          [56.752812, 36.138294],
          [56.748645, 36.130511],
          [56.749532, 36.09499],
          [56.747865, 36.091097],
          [56.747032, 36.08943],
          [56.750401, 36.072476],
          [56.750125, 36.070809],
          [56.749848, 36.069412],
          [56.749291, 36.068034],
          [56.746791, 36.050535],
          [56.746515, 36.046084],
          [56.746235, 36.044426],
          [56.745125, 36.033586],
          [56.744848, 36.033037],
          [56.745125, 36.031919],
          [56.744848, 36.03109],
          [56.745125, 36.028865],
          [56.745125, 36.024702],
          [56.744291, 36.016925],
          [56.744015, 36.013871],
          [56.743735, 36.012762],
          [56.743458, 36.011654],
          [56.759627, 35.983039],
          [56.759351, 35.982489],
          [56.759907, 35.97526],
          [56.759351, 35.969708],
          [56.758794, 35.965535],
          [56.756457, 35.96165],
          [56.756178, 35.960812],
          [56.757848, 35.944706],
          [56.757567, 35.943867],
          [56.759514, 35.923867],
          [56.759514, 35.918594],
          [56.759514, 35.918315],
          [56.760624, 35.909707],
          [56.761734, 35.908319],
          [56.762014, 35.90803],
          [56.762014, 35.902478],
          [56.762291, 35.902199],
          [56.762847, 35.897205],
          [56.762567, 35.896646],
          [56.756257, 35.865525],
          [56.755981, 35.865254],
          [56.755981, 35.864416],
          [56.756814, 35.854419],
          [56.758481, 35.847469],
          [56.764034, 35.837751],
          [56.793087, 35.828622],
          [56.793367, 35.828343],
          [56.823367, 35.805298],
          [56.84309, 35.790571],
          [56.84448, 35.789192],
          [56.845036, 35.788904],
          [56.862813, 35.773357],
          [56.895476, 35.733889],
          [56.896589, 35.73306],
          [56.900756, 35.730834],
          [56.909922, 35.724452],
          [56.933255, 35.706396],
          [56.934089, 35.706396],
          [56.934922, 35.705837],
          [56.946312, 35.700564],
          [56.975155, 35.607728],
          [56.990275, 35.601028],
          [56.987932, 35.581227],
          [56.989878, 35.580118],
          [56.998738, 35.576781],
          [57.010711, 35.565949],
          [57.010711, 35.56567],
          [57.011268, 35.56539],
          [57.034864, 35.564138],
          [57.044031, 35.548305],
          [57.045141, 35.546916],
          [57.048198, 35.537746],
          [57.047921, 35.537196],
          [57.047921, 35.536358],
          [57.047641, 35.535808],
          [57.047641, 35.535528],
          [57.046254, 35.531642],
          [57.042921, 35.52497],
          [57.035277, 35.505836],
          [57.015248, 35.488587],
          [57.002431, 35.474729],
          [57.001874, 35.472781],
          [56.995418, 35.46636],
          [56.994585, 35.460526],
          [56.994305, 35.458308],
          [56.994028, 35.45581],
          [56.984138, 35.441547],
          [56.981359, 35.437939],
          [56.981358, 35.437659],
          [56.981638, 35.429047],
          [56.986915, 35.419324],
          [56.990627, 35.416826],
          [57.009391, 35.395391],
          [57.009668, 35.394002],
          [57.010224, 35.385659],
          [57.019584, 35.364136],
          [57.034307, 35.339409],
          [57.035417, 35.3383],
          [57.035974, 35.33802],
          [57.036254, 35.337461],
          [57.052364, 35.321084],
          [57.052087, 35.314969],
          [57.05014, 35.307186],
          [57.039307, 35.281078],
          [57.031807, 35.268857],
          [57.031531, 35.267748],
          [57.031251, 35.267468],
          [57.010694, 35.223573],
          [57.00023, 35.215249],
          [56.990694, 35.211912],
          [56.986804, 35.210802],
          [56.983751, 35.210802],
          [56.983195, 35.210523],
          [56.980971, 35.210523],
          [56.980695, 35.210802],
          [56.975138, 35.211632],
          [56.967638, 35.21413],
          [56.966528, 35.21413],
          [56.965695, 35.21441],
          [56.952358, 35.217747],
          [56.951525, 35.218018],
          [56.947358, 35.219686],
          [56.946525, 35.219686],
          [56.942638, 35.220795],
          [56.941525, 35.220795],
          [56.940968, 35.221075],
          [56.937082, 35.221075],
          [56.936802, 35.220795],
          [56.935415, 35.220795],
          [56.934858, 35.220516],
          [56.916525, 35.213301],
          [56.910969, 35.209134],
          [56.909859, 35.208854],
          [56.909302, 35.208304],
          [56.908192, 35.208015],
          [56.907635, 35.207465],
          [56.895135, 35.203857],
          [56.894022, 35.203858],
          [56.893469, 35.203578],
          [56.891802, 35.203578],
          [56.891246, 35.203298],
          [56.890412, 35.203298],
          [56.889855, 35.203019],
          [56.887636, 35.203019],
          [56.887079, 35.202739],
          [56.883746, 35.202739],
          [56.882632, 35.203019],
          [56.876522, 35.203019],
          [56.875132, 35.202739],
          [56.874022, 35.203019],
          [56.869579, 35.203019],
          [56.868466, 35.203298],
          [56.866246, 35.203298],
          [56.865409, 35.203578],
          [56.862909, 35.203578],
          [56.862356, 35.203298],
          [56.861243, 35.203298],
          [56.857356, 35.202469],
          [56.855689, 35.202469],
          [56.843743, 35.197743],
          [56.842909, 35.196904],
          [56.830686, 35.188299],
          [56.828743, 35.186351],
          [56.828743, 35.186071],
          [56.827076, 35.184683],
          [56.811243, 35.167464],
          [56.78235, 35.153574],
          [56.77513, 35.148298],
          [56.77374, 35.146909],
          [56.760864, 35.126087],
          [56.713365, 35.109231],
          [56.698642, 35.095341],
          [56.697251, 35.094232],
          [56.696975, 35.093673],
          [56.694475, 35.092013],
          [56.690878, 35.087266],
          [56.686955, 35.082211],
          [56.677225, 35.061059],
          [56.675835, 35.061059],
          [56.675001, 35.060779],
          [56.673335, 35.060779],
          [56.672501, 35.060499],
          [56.670558, 35.060499],
          [56.670002, 35.06022],
          [56.667225, 35.06022],
          [56.666668, 35.060499],
          [56.664725, 35.060499],
          [56.661556, 35.062855],
          [56.659889, 35.063125],
          [56.658222, 35.063694],
          [56.657901, 35.066622],
          [56.653178, 35.066622],
          [56.652068, 35.066902],
          [56.641235, 35.066902],
          [56.640122, 35.066622],
          [56.626512, 35.064962],
          [56.625399, 35.064403],
          [56.624012, 35.064133],
          [56.594842, 35.055518],
          [56.593732, 35.055518],
          [56.591509, 35.054959],
          [56.589566, 35.054959],
          [56.587066, 35.054409],
          [56.585119, 35.054409],
          [56.584009, 35.054129],
          [56.582062, 35.054129],
          [56.581509, 35.053849],
          [56.580119, 35.053849],
          [56.572343, 35.05302],
          [56.571786, 35.05302],
          [56.570396, 35.05302],
          [56.568729, 35.05274],
          [56.564562, 35.05274],
          [56.563729, 35.05246],
          [56.562063, 35.05246],
          [56.536783, 35.047743],
          [56.534563, 35.046904],
          [56.532896, 35.046624],
          [56.519007, 35.042186],
          [56.517893, 35.041347],
          [56.51706, 35.041347],
          [56.488447, 35.028574],
          [56.481503, 35.022188],
          [56.453724, 34.999961],
          [56.442891, 34.979682],
          [56.394488, 34.944245],
          [56.394488, 34.943695],
          [56.393931, 34.943136],
          [56.390321, 34.937588],
          [56.390041, 34.93675],
          [56.388375, 34.934531],
          [56.349092, 34.901935],
          [56.349092, 34.901656],
          [56.348815, 34.901376],
          [56.348535, 34.900546],
          [56.341312, 34.889994],
          [56.333812, 34.883608],
          [56.333535, 34.883329],
          [56.332425, 34.881669],
          [56.327979, 34.877502],
          [56.312596, 34.865969],
          [56.25798, 34.823039],
          [56.25548, 34.81999],
          [56.23159, 34.785832],
          [56.230757, 34.785273],
          [56.22378, 34.748258],
          [56.223504, 34.747139],
          [56.221837, 34.74464],
        ],
      ],
    },
  },
  SouthKhorasan: {
    properties: {
      value: "KhorasanSouth",
      name: "استان خراسان جنوبی, ایران",
      id: 1,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [55.386452, 33.364924],
          [55.388119, 33.364364],
          [55.408675, 33.348808],
          [55.409785, 33.347426],
          [55.428952, 33.32825],
          [55.471454, 33.291865],
          [55.478401, 33.285193],
          [55.481177, 33.282421],
          [55.500067, 33.262974],
          [55.511734, 33.251579],
          [55.53229, 33.220196],
          [55.533123, 33.218255],
          [55.573126, 33.149076],
          [55.574236, 33.147415],
          [55.601739, 33.094632],
          [55.602296, 33.092691],
          [55.636739, 33.036295],
          [55.640075, 33.032413],
          [55.640629, 33.031582],
          [55.654518, 33.010472],
          [55.661185, 33.0038],
          [55.667298, 32.998798],
          [55.667018, 32.996577],
          [55.667852, 32.993245],
          [55.668965, 32.986852],
          [55.669241, 32.98408],
          [55.674241, 32.955747],
          [55.682021, 32.937689],
          [55.686188, 32.931025],
          [55.691188, 32.924352],
          [55.697578, 32.914908],
          [55.698688, 32.913247],
          [55.699798, 32.911576],
          [55.700631, 32.911016],
          [55.717301, 32.895188],
          [55.719801, 32.891856],
          [55.721744, 32.889906],
          [55.724244, 32.886574],
          [55.782303, 32.815189],
          [55.806746, 32.788516],
          [55.80758, 32.787406],
          [55.822859, 32.774069],
          [55.827859, 32.771848],
          [55.829806, 32.770467],
          [55.831473, 32.769907],
          [55.835916, 32.767126],
          [55.849249, 32.762963],
          [55.850362, 32.762963],
          [55.850639, 32.762683],
          [55.851196, 32.762683],
          [55.851749, 32.762403],
          [55.852582, 32.762403],
          [55.852862, 32.762123],
          [55.854249, 32.761853],
          [55.855082, 32.761573],
          [55.855915, 32.761293],
          [55.857582, 32.760742],
          [55.860082, 32.759351],
          [55.876472, 32.753239],
          [55.879805, 32.752688],
          [55.881472, 32.752128],
          [55.885085, 32.751577],
          [55.898418, 32.750458],
          [55.907031, 32.750458],
          [55.908141, 32.750178],
          [55.937308, 32.748236],
          [55.963421, 32.742403],
          [55.965644, 32.741573],
          [55.972034, 32.739631],
          [55.9862, 32.731848],
          [55.99259, 32.727685],
          [56.002867, 32.722132],
          [56.030647, 32.708796],
          [56.037593, 32.705184],
          [56.038426, 32.704624],
          [56.051203, 32.698511],
          [56.053146, 32.697961],
          [56.082178, 32.683565],
          [56.085649, 32.681843],
          [56.111762, 32.661292],
          [56.113152, 32.660732],
          [56.135095, 32.646845],
          [56.168431, 32.628236],
          [56.180374, 32.621012],
          [56.181488, 32.620181],
          [56.184264, 32.618791],
          [56.199821, 32.609346],
          [56.200931, 32.608235],
          [56.221487, 32.593788],
          [56.240377, 32.576569],
          [56.248713, 32.563233],
          [56.261213, 32.53851],
          [56.262047, 32.537679],
          [56.273436, 32.51907],
          [56.27788, 32.512406],
          [56.299546, 32.483782],
          [56.304826, 32.478229],
          [56.305382, 32.477119],
          [56.311492, 32.466843],
          [56.320939, 32.455177],
          [56.362328, 32.405732],
          [56.373995, 32.395447],
          [56.374271, 32.395176],
          [56.374828, 32.394897],
          [56.375661, 32.394066],
          [56.382051, 32.388784],
          [56.400664, 32.369343],
          [56.409831, 32.36295],
          [56.416498, 32.354896],
          [56.479557, 32.291284],
          [56.48011, 32.290733],
          [56.480943, 32.290453],
          [56.481223, 32.289893],
          [56.48928, 32.284891],
          [56.493446, 32.281839],
          [56.493538, 32.281746],
          [56.49428, 32.281],
          [56.494556, 32.280178],
          [56.498723, 32.275726],
          [56.526223, 32.247392],
          [56.527336, 32.246841],
          [56.527612, 32.246282],
          [56.542059, 32.232945],
          [56.542612, 32.232674],
          [56.543169, 32.231835],
          [56.543726, 32.231564],
          [56.544559, 32.230173],
          [56.561502, 32.209333],
          [56.575672, 32.191555],
          [56.584005, 32.183501],
          [56.591228, 32.174056],
          [56.633451, 32.112946],
          [56.637341, 32.107673],
          [56.653451, 32.087384],
          [56.654564, 32.08128],
          [56.652897, 32.075998],
          [56.635951, 32.057669],
          [56.631227, 32.052387],
          [56.630951, 32.051836],
          [56.616228, 32.032938],
          [56.596781, 31.999611],
          [56.603171, 31.990447],
          [56.602895, 31.989327],
          [56.602618, 31.988776],
          [56.602618, 31.987946],
          [56.602338, 31.985725],
          [56.602061, 31.983494],
          [56.601505, 31.981002],
          [56.600948, 31.976271],
          [56.600671, 31.975441],
          [56.600395, 31.97461],
          [56.599004, 31.961554],
          [56.598728, 31.956272],
          [56.598448, 31.954611],
          [56.598171, 31.954051],
          [56.599281, 31.943496],
          [56.599838, 31.942385],
          [56.600114, 31.940715],
          [56.601228, 31.937943],
          [56.602618, 31.932381],
          [56.602895, 31.931279],
          [56.604838, 31.922106],
          [56.607894, 31.923496],
          [56.609004, 31.924336],
          [56.622894, 31.928499],
          [56.624284, 31.928498],
          [56.626227, 31.929058],
          [56.627618, 31.929058],
          [56.629284, 31.929609],
          [56.630117, 31.929609],
          [56.630394, 31.929889],
          [56.630951, 31.929889],
          [56.631227, 31.93016],
          [56.637894, 31.93211],
          [56.650117, 31.935442],
          [56.66984, 31.938494],
          [56.672897, 31.939053],
          [56.67484, 31.939053],
          [56.715676, 31.946557],
          [56.745956, 31.950719],
          [56.750956, 31.951279],
          [56.766789, 31.95405],
          [56.773459, 31.955721],
          [56.825681, 31.960163],
          [56.827348, 31.959892],
          [56.845961, 31.960163],
          [56.853738, 31.959332],
          [56.85454, 31.959171],
          [56.855128, 31.959053],
          [56.855213, 31.959053],
          [56.858738, 31.959053],
          [56.867074, 31.959892],
          [56.884017, 31.959892],
          [56.885131, 31.960163],
          [56.895964, 31.959892],
          [56.896817, 31.959714],
          [56.89735, 31.959603],
          [56.897567, 31.959582],
          [56.908464, 31.958502],
          [56.910407, 31.958502],
          [56.911128, 31.95832],
          [56.91152, 31.958222],
          [56.911782, 31.958222],
          [56.913187, 31.958222],
          [56.93791, 31.95405],
          [56.939577, 31.95405],
          [56.940468, 31.953869],
          [56.984579, 31.944886],
          [56.984812, 31.944857],
          [57.000136, 31.942945],
          [57.000229, 31.942874],
          [57.005969, 31.938493],
          [57.016802, 31.936001],
          [57.034025, 31.928778],
          [57.049302, 31.919334],
          [57.064305, 31.912941],
          [57.102637, 31.899605],
          [57.103477, 31.899472],
          [57.144584, 31.892942],
          [57.163754, 31.89128],
          [57.16764, 31.89128],
          [57.170143, 31.890992],
          [57.179587, 31.890441],
          [57.192366, 31.8885],
          [57.19542, 31.88822],
          [57.200143, 31.88738],
          [57.203476, 31.887109],
          [57.234032, 31.881547],
          [57.238756, 31.880996],
          [57.253755, 31.878216],
          [57.253778, 31.878209],
          [57.292092, 31.867661],
          [57.294592, 31.86655],
          [57.328481, 31.854605],
          [57.339314, 31.848772],
          [57.367927, 31.842939],
          [57.368001, 31.842939],
          [57.370427, 31.842939],
          [57.371162, 31.842755],
          [57.37154, 31.84266],
          [57.371858, 31.842641],
          [57.37626, 31.842389],
          [57.382374, 31.842389],
          [57.383109, 31.842204],
          [57.383487, 31.842109],
          [57.383732, 31.842109],
          [57.385987, 31.842109],
          [57.39182, 31.841549],
          [57.395153, 31.841549],
          [57.39932, 31.840998],
          [57.402654, 31.840998],
          [57.404873, 31.840718],
          [57.410986, 31.840718],
          [57.411723, 31.840533],
          [57.4121, 31.840438],
          [57.412344, 31.840438],
          [57.417933, 31.840438],
          [57.420153, 31.840158],
          [57.423206, 31.840159],
          [57.425153, 31.839879],
          [57.435433, 31.839328],
          [57.441539, 31.839328],
          [57.442462, 31.839142],
          [57.442933, 31.839048],
          [57.44299, 31.839048],
          [57.445986, 31.839048],
          [57.448766, 31.838768],
          [57.474879, 31.836547],
          [57.475836, 31.836363],
          [57.496545, 31.832385],
          [57.500712, 31.831274],
          [57.501545, 31.830714],
          [57.502507, 31.830541],
          [57.504598, 31.830163],
          [57.504702, 31.830136],
          [57.511825, 31.828222],
          [57.533492, 31.823491],
          [57.537658, 31.822101],
          [57.539878, 31.820999],
          [57.551271, 31.817108],
          [57.560438, 31.814887],
          [57.567104, 31.812656],
          [57.568491, 31.812106],
          [57.579051, 31.809325],
          [57.583491, 31.807664],
          [57.585991, 31.807104],
          [57.590991, 31.804603],
          [57.598217, 31.802382],
          [57.598589, 31.802382],
          [57.599331, 31.802382],
          [57.600166, 31.802195],
          [57.617937, 31.798219],
          [57.666276, 31.781552],
          [57.681829, 31.775711],
          [57.682676, 31.775573],
          [57.683496, 31.77544],
          [57.683679, 31.775382],
          [57.688776, 31.773769],
          [57.690442, 31.773219],
          [57.724609, 31.762104],
          [57.725011, 31.762104],
          [57.725722, 31.762104],
          [57.726417, 31.761916],
          [57.732942, 31.760154],
          [57.738775, 31.758764],
          [57.739055, 31.758493],
          [57.739553, 31.758382],
          [57.739889, 31.758213],
          [57.741275, 31.757942],
          [57.742388, 31.757382],
          [57.746555, 31.755992],
          [57.746971, 31.755992],
          [57.747669, 31.755992],
          [57.748237, 31.755804],
          [57.749335, 31.755441],
          [57.756555, 31.75405],
          [57.757948, 31.753491],
          [57.785448, 31.746268],
          [57.857674, 31.730161],
          [57.858142, 31.730086],
          [57.86462, 31.72905],
          [57.864804, 31.728988],
          [57.866287, 31.72849],
          [57.944346, 31.710713],
          [57.964066, 31.70405],
          [57.966565, 31.70321],
          [57.983512, 31.696827],
          [57.983986, 31.696827],
          [57.984898, 31.696827],
          [57.985088, 31.696637],
          [57.985178, 31.696547],
          [57.985966, 31.696547],
          [57.986012, 31.696547],
          [57.986611, 31.696357],
          [57.991292, 31.694876],
          [57.991669, 31.694877],
          [57.992398, 31.694877],
          [57.992778, 31.694687],
          [57.992958, 31.694597],
          [57.993512, 31.694326],
          [57.994345, 31.694046],
          [57.994898, 31.693766],
          [58.005458, 31.690434],
          [58.019905, 31.683491],
          [58.067124, 31.664044],
          [58.068237, 31.663213],
          [58.078237, 31.658771],
          [58.138516, 31.627659],
          [58.141576, 31.625989],
          [58.229355, 31.582653],
          [58.234355, 31.579881],
          [58.277967, 31.553762],
          [58.279081, 31.553491],
          [58.302967, 31.537926],
          [58.307694, 31.534595],
          [58.389919, 31.476544],
          [58.392972, 31.474314],
          [58.399919, 31.469313],
          [58.421032, 31.450706],
          [58.421305, 31.450426],
          [58.423259, 31.449029],
          [58.423532, 31.448765],
          [58.463258, 31.419316],
          [58.464644, 31.418205],
          [58.465198, 31.417925],
          [58.465478, 31.417366],
          [58.499924, 31.392377],
          [58.514371, 31.371541],
          [58.538817, 31.346814],
          [58.608262, 31.285977],
          [58.621876, 31.274034],
          [58.634648, 31.265141],
          [58.635208, 31.264871],
          [58.658261, 31.249867],
          [58.668952, 31.241957],
          [58.669375, 31.241535],
          [58.722987, 31.199035],
          [58.727987, 31.195983],
          [58.732714, 31.192643],
          [58.780493, 31.155416],
          [58.820772, 31.13097],
          [58.829106, 31.125419],
          [58.869385, 31.101525],
          [58.871051, 31.100974],
          [58.900771, 31.084861],
          [58.936884, 31.065147],
          [58.96411, 31.052645],
          [58.96939, 31.050415],
          [58.992443, 31.041804],
          [59.004943, 31.035142],
          [59.008556, 31.033752],
          [59.026336, 31.025141],
          [59.027294, 31.024947],
          [59.027722, 31.024861],
          [59.027731, 31.024857],
          [59.054115, 31.011249],
          [59.056888, 31.009588],
          [59.058555, 31.008478],
          [59.068002, 31.000083],
          [59.070502, 30.997087],
          [59.070502, 30.996807],
          [59.073562, 30.988196],
          [59.074115, 30.985695],
          [59.076895, 30.954862],
          [59.076895, 30.952361],
          [59.077168, 30.950141],
          [59.076895, 30.924028],
          [59.076061, 30.920138],
          [59.076335, 30.917638],
          [59.077448, 30.909586],
          [59.078001, 30.908747],
          [59.078561, 30.907917],
          [59.119947, 30.874024],
          [59.131061, 30.867643],
          [59.133007, 30.866524],
          [59.14912, 30.855133],
          [59.165226, 30.841522],
          [59.189953, 30.81986],
          [59.193286, 30.817081],
          [59.193839, 30.816801],
          [59.250785, 30.757079],
          [59.253285, 30.754019],
          [59.292738, 30.6993],
          [59.294958, 30.6968],
          [59.295238, 30.69624],
          [59.296071, 30.69569],
          [59.296904, 30.6943],
          [59.355237, 30.630133],
          [59.359123, 30.625963],
          [59.36079, 30.624023],
          [59.409129, 30.572628],
          [59.410796, 30.571238],
          [59.411909, 30.569848],
          [59.454408, 30.526234],
          [59.455241, 30.525124],
          [59.463575, 30.516795],
          [59.464052, 30.517078],
          [59.473855, 30.522904],
          [59.489134, 30.530963],
          [59.489688, 30.531522],
          [59.490241, 30.531522],
          [59.490801, 30.532073],
          [59.491634, 30.532352],
          [59.493854, 30.533733],
          [59.528581, 30.54874],
          [59.529967, 30.54902],
          [59.5358, 30.55179],
          [59.545247, 30.555129],
          [59.5458, 30.555129],
          [59.54608, 30.555409],
          [59.547187, 30.555409],
          [59.57636, 30.566518],
          [59.579966, 30.567908],
          [59.676639, 30.612904],
          [59.679699, 30.614853],
          [59.682199, 30.615963],
          [59.683865, 30.617073],
          [59.700805, 30.625683],
          [59.732751, 30.638741],
          [59.771644, 30.65708],
          [59.781644, 30.66152],
          [59.785257, 30.66319],
          [59.877483, 30.709849],
          [59.878036, 30.710408],
          [59.879703, 30.711518],
          [59.934155, 30.753188],
          [59.939155, 30.760408],
          [59.939155, 30.760688],
          [59.939708, 30.761247],
          [59.942209, 30.765688],
          [59.942489, 30.766798],
          [59.942762, 30.769027],
          [59.943322, 30.770688],
          [59.943595, 30.771798],
          [59.943875, 30.773468],
          [59.944155, 30.775137],
          [59.944429, 30.777078],
          [59.944709, 30.779578],
          [59.944989, 30.781518],
          [59.945262, 30.783748],
          [59.946095, 30.794578],
          [59.946655, 30.807358],
          [59.946375, 30.810969],
          [59.946655, 30.811799],
          [59.946929, 30.815409],
          [59.946929, 30.819299],
          [59.946929, 30.820409],
          [59.947489, 30.82513],
          [59.947489, 30.83319],
          [59.948875, 30.84458],
          [59.948875, 30.855691],
          [59.949429, 30.859861],
          [59.949429, 30.865412],
          [59.951929, 30.882353],
          [59.952489, 30.883473],
          [59.952489, 30.884303],
          [59.953322, 30.886253],
          [59.956375, 30.894304],
          [59.956929, 30.894854],
          [59.956929, 30.895134],
          [59.958322, 30.897354],
          [59.971095, 30.914025],
          [59.971655, 30.915136],
          [59.977489, 30.919856],
          [59.993602, 30.927357],
          [60.000541, 30.928747],
          [60.016935, 30.928747],
          [60.017215, 30.928467],
          [60.021101, 30.928467],
          [60.021655, 30.928187],
          [60.023321, 30.928187],
          [60.026381, 30.927917],
          [60.034435, 30.928187],
          [60.035268, 30.928467],
          [60.039714, 30.928467],
          [60.040268, 30.928747],
          [60.044154, 30.928747],
          [60.044434, 30.929027],
          [60.047494, 30.929027],
          [60.047768, 30.929306],
          [60.057494, 30.929306],
          [60.058601, 30.929027],
          [60.060267, 30.929306],
          [60.068327, 30.929306],
          [60.0686, 30.929027],
          [60.074714, 30.929027],
          [60.075827, 30.929306],
          [60.084161, 30.929306],
          [60.085274, 30.929586],
          [60.090827, 30.929586],
          [60.093327, 30.929857],
          [60.100827, 30.929586],
          [60.10666, 30.929857],
          [60.107213, 30.930137],
          [60.122773, 30.930137],
          [60.123607, 30.929857],
          [60.123887, 30.930137],
          [60.12472, 30.929857],
          [60.12722, 30.929857],
          [60.127773, 30.930137],
          [60.131939, 30.930137],
          [60.13666, 30.930687],
          [60.137493, 30.930967],
          [60.138886, 30.930967],
          [60.13944, 30.931247],
          [60.148886, 30.931247],
          [60.14972, 30.931527],
          [60.152773, 30.931527],
          [60.153326, 30.931806],
          [60.154993, 30.931527],
          [60.157493, 30.931527],
          [60.160833, 30.931527],
          [60.165553, 30.931527],
          [60.168053, 30.932357],
          [60.169439, 30.932357],
          [60.200832, 30.940137],
          [60.220552, 30.950689],
          [60.221945, 30.951249],
          [60.222218, 30.951808],
          [60.224718, 30.953189],
          [60.225552, 30.954029],
          [60.228332, 30.956249],
          [60.230279, 30.95819],
          [60.234998, 30.964301],
          [60.235552, 30.96514],
          [60.235552, 30.965691],
          [60.236392, 30.966521],
          [60.249998, 30.993194],
          [60.257498, 30.999305],
          [60.264725, 31.004025],
          [60.284171, 31.010687],
          [60.285558, 31.011526],
          [60.288338, 31.013467],
          [60.299171, 31.022638],
          [60.298891, 31.026528],
          [60.298058, 31.028189],
          [60.280278, 31.042081],
          [60.280005, 31.043191],
          [60.279445, 31.050692],
          [60.278058, 31.059024],
          [60.273891, 31.072086],
          [60.256665, 31.09486],
          [60.253612, 31.099301],
          [60.248612, 31.109863],
          [60.247499, 31.120975],
          [60.248612, 31.124306],
          [60.275559, 31.149581],
          [60.275559, 31.149861],
          [60.276112, 31.150141],
          [60.278058, 31.151811],
          [60.352231, 31.204583],
          [60.356397, 31.206804],
          [60.367784, 31.214305],
          [60.368064, 31.214865],
          [60.387783, 31.224587],
          [60.423616, 31.2357],
          [60.454456, 31.241251],
          [60.456676, 31.241531],
          [60.461956, 31.242642],
          [60.499456, 31.251253],
          [60.500009, 31.251533],
          [60.501402, 31.251533],
          [60.524182, 31.257085],
          [60.525015, 31.257085],
          [60.526682, 31.257916],
          [60.558068, 31.266537],
          [60.559461, 31.266537],
          [60.562235, 31.267367],
          [60.564735, 31.267638],
          [60.565015, 31.267918],
          [60.565295, 31.267918],
          [60.566401, 31.267918],
          [60.566961, 31.268198],
          [60.569533, 31.268198],
          [60.569734, 31.268198],
          [60.573068, 31.268748],
          [60.575015, 31.269308],
          [60.576408, 31.269308],
          [60.578628, 31.269868],
          [60.583628, 31.270698],
          [60.584331, 31.270836],
          [60.590113, 31.271967],
          [60.596408, 31.273199],
          [60.597241, 31.273199],
          [60.597794, 31.273479],
          [60.598907, 31.273479],
          [60.609671, 31.276528],
          [60.613977, 31.277748],
          [60.615574, 31.2782],
          [60.622458, 31.279058],
          [60.628907, 31.279861],
          [60.630323, 31.280169],
          [60.636316, 31.281473],
          [60.642959, 31.282919],
          [60.648074, 31.284032],
          [60.648627, 31.284311],
          [60.64974, 31.284312],
          [60.65252, 31.285142],
          [60.658354, 31.285972],
          [60.662822, 31.287512],
          [60.66724, 31.289033],
          [60.667836, 31.289702],
          [60.668732, 31.290709],
          [60.669466, 31.291533],
          [60.66974, 31.292084],
          [60.67002, 31.292364],
          [60.671572, 31.293102],
          [60.67709, 31.295728],
          [60.682933, 31.298508],
          [60.68818, 31.301005],
          [60.693339, 31.303459],
          [60.698633, 31.305977],
          [60.707067, 31.310353],
          [60.720997, 31.31758],
          [60.728689, 31.32157],
          [60.73732, 31.326047],
          [60.742416, 31.32869],
          [60.743079, 31.329034],
          [60.749745, 31.333475],
          [60.75184, 31.335176],
          [60.754192, 31.337086],
          [60.758093, 31.340697],
          [60.765313, 31.347381],
          [60.771199, 31.352829],
          [60.775923, 31.3572],
          [60.783743, 31.364438],
          [60.797172, 31.376865],
          [60.809875, 31.388619],
          [60.818767, 31.396845],
          [60.826835, 31.404309],
          [60.828638, 31.405977],
          [60.831418, 31.409308],
          [60.83453, 31.412841],
          [60.835585, 31.414038],
          [60.837251, 31.416259],
          [60.841542, 31.424764],
          [60.845849, 31.433301],
          [60.851114, 31.443736],
          [60.853084, 31.44764],
          [60.855561, 31.457559],
          [60.856971, 31.463205],
          [60.856971, 31.464586],
          [60.857531, 31.466536],
          [60.857313, 31.475852],
          [60.857251, 31.47848],
          [60.857531, 31.47876],
          [60.857251, 31.482371],
          [60.856971, 31.483202],
          [60.856971, 31.483481],
          [60.856057, 31.486519],
          [60.854993, 31.491869],
          [60.853881, 31.497459],
          [60.851494, 31.509457],
          [60.849372, 31.520125],
          [60.848697, 31.523514],
          [60.846055, 31.536792],
          [60.844555, 31.544329],
          [60.843585, 31.549205],
          [60.841914, 31.557599],
          [60.840923, 31.56258],
          [60.839722, 31.568611],
          [60.838087, 31.577348],
          [60.836923, 31.583566],
          [60.835334, 31.592056],
          [60.834328, 31.597429],
          [60.83228, 31.608366],
          [60.831613, 31.61193],
          [60.830457, 31.618101],
          [60.829752, 31.621867],
          [60.828349, 31.629361],
          [60.827581, 31.63346],
          [60.825736, 31.64331],
          [60.824391, 31.650493],
          [60.822545, 31.660344],
          [60.821868, 31.663958],
          [60.82025, 31.672592],
          [60.818952, 31.679524],
          [60.816815, 31.690925],
          [60.816248, 31.693954],
          [60.814987, 31.70068],
          [60.813484, 31.708701],
          [60.813634, 31.714876],
          [60.813879, 31.724937],
          [60.814132, 31.735337],
          [60.81424, 31.739807],
          [60.814381, 31.745593],
          [60.814508, 31.750812],
          [60.814666, 31.757289],
          [60.814807, 31.763091],
          [60.815018, 31.771754],
          [60.815165, 31.777795],
          [60.815336, 31.784818],
          [60.815489, 31.791109],
          [60.815622, 31.796582],
          [60.815777, 31.802927],
          [60.816014, 31.812666],
          [60.816134, 31.817589],
          [60.816276, 31.823422],
          [60.816353, 31.826596],
          [60.816543, 31.834359],
          [60.816679, 31.839974],
          [60.816825, 31.845933],
          [60.816964, 31.85164],
          [60.817128, 31.858385],
          [60.817244, 31.863159],
          [60.817429, 31.870723],
          [60.817544, 31.875479],
          [60.817826, 31.887011],
          [60.817896, 31.889886],
          [60.819128, 31.894975],
          [60.821305, 31.903969],
          [60.823033, 31.911104],
          [60.825045, 31.919414],
          [60.82584, 31.922696],
          [60.827474, 31.929441],
          [60.826878, 31.932633],
          [60.825814, 31.938338],
          [60.824964, 31.942893],
          [60.823317, 31.951718],
          [60.822702, 31.961064],
          [60.822495, 31.964201],
          [60.825404, 31.968745],
          [60.832519, 31.979859],
          [60.831009, 31.983354],
          [60.82773, 31.990945],
          [60.825045, 31.997161],
          [60.820142, 32.008506],
          [60.818752, 32.011724],
          [60.817342, 32.019225],
          [60.815967, 32.026539],
          [60.82252, 32.032861],
          [60.831697, 32.049208],
          [60.842715, 32.068832],
          [60.863963, 32.137362],
          [60.882921, 32.197736],
          [60.879197, 32.208809],
          [60.859096, 32.266492],
          [60.847419, 32.340298],
          [60.833986, 32.399888],
          [60.814771, 32.481295],
          [60.781609, 32.549446],
          [60.777896, 32.557074],
          [60.776419, 32.559335],
          [60.747844, 32.61853],
          [60.709581, 32.695856],
          [60.688699, 32.781736],
          [60.664926, 32.877604],
          [60.654175, 32.921431],
          [60.633741, 33.003909],
          [60.629414, 33.021233],
          [60.597884, 33.095297],
          [60.583378, 33.129905],
          [60.605848, 33.173132],
          [60.612902, 33.186878],
          [60.634055, 33.228141],
          [60.703837, 33.292373],
          [60.759272, 33.343636],
          [60.824355, 33.399798],
          [60.866484, 33.421487],
          [60.863425, 33.461623],
          [60.850923, 33.482001],
          [60.882486, 33.505244],
          [60.883291, 33.505223],
          [60.885861, 33.504599],
          [60.886468, 33.504322],
          [60.887021, 33.503592],
          [60.887756, 33.503252],
          [60.888371, 33.503197],
          [60.889941, 33.503515],
          [60.890928, 33.503381],
          [60.891488, 33.502999],
          [60.891772, 33.503059],
          [60.892293, 33.50296],
          [60.892743, 33.502604],
          [60.893201, 33.502446],
          [60.894162, 33.502774],
          [60.894977, 33.502486],
          [60.895971, 33.502545],
          [60.89845, 33.501841],
          [60.901613, 33.502037],
          [60.903217, 33.500758],
          [60.908218, 33.499486],
          [60.919476, 33.498232],
          [60.921568, 33.497454],
          [60.923521, 33.497603],
          [60.925341, 33.497231],
          [60.925887, 33.497752],
          [60.928708, 33.497203],
          [60.945904, 33.513494],
          [60.943957, 33.515084],
          [60.902795, 33.539967],
          [60.874223, 33.540529],
          [60.826228, 33.541358],
          [60.798662, 33.54186],
          [60.781188, 33.542307],
          [60.752915, 33.542918],
          [60.703418, 33.54552],
          [60.674197, 33.552769],
          [60.655016, 33.557473],
          [60.608664, 33.582288],
          [60.581277, 33.597023],
          [60.551464, 33.64003],
          [60.548531, 33.696811],
          [60.546894, 33.73141],
          [60.555266, 33.748965],
          [60.582214, 33.805444],
          [60.576597, 33.827195],
          [60.571356, 33.848033],
          [60.542001, 33.964751],
          [60.533076, 34.00025],
          [60.50618, 33.996327],
          [60.505671, 33.996227],
          [60.496685, 33.994933],
          [60.496411, 33.994653],
          [60.494185, 33.994653],
          [60.493632, 33.994373],
          [60.491685, 33.994373],
          [60.488912, 33.993814],
          [60.486965, 33.993814],
          [60.485852, 33.993534],
          [60.478632, 33.993534],
          [60.477245, 33.993813],
          [60.475018, 33.993814],
          [60.473352, 33.994093],
          [60.467519, 33.994093],
          [60.465852, 33.994373],
          [60.451132, 33.994373],
          [60.437799, 33.993263],
          [60.435852, 33.992983],
          [60.427799, 33.992983],
          [60.425572, 33.992703],
          [60.423625, 33.992983],
          [60.422792, 33.992703],
          [60.417519, 33.992703],
          [60.413626, 33.992153],
          [60.410853, 33.992153],
          [60.409186, 33.991873],
          [60.406126, 33.991873],
          [60.403906, 33.991593],
          [60.396406, 33.991593],
          [60.393906, 33.991873],
          [60.388626, 33.991873],
          [60.386679, 33.992153],
          [60.37196, 33.992153],
          [60.352513, 33.988263],
          [60.338067, 33.984652],
          [60.33446, 33.983822],
          [60.322794, 33.979101],
          [60.288067, 33.957979],
          [60.281681, 33.951868],
          [60.262508, 33.936036],
          [60.260568, 33.934095],
          [60.215561, 33.891318],
          [60.183615, 33.867424],
          [60.183062, 33.866873],
          [60.181395, 33.866314],
          [60.180008, 33.865483],
          [60.166115, 33.861313],
          [60.163342, 33.860203],
          [60.162509, 33.859643],
          [60.156668, 33.857982],
          [60.155002, 33.857982],
          [60.154448, 33.857702],
          [60.152782, 33.857702],
          [60.146396, 33.856592],
          [60.145835, 33.856592],
          [60.144169, 33.856312],
          [60.1426, 33.857881],
          [60.128335, 33.872145],
          [60.125282, 33.876036],
          [60.124729, 33.877146],
          [60.124169, 33.877426],
          [60.123616, 33.878536],
          [60.121669, 33.881037],
          [60.088056, 33.927705],
          [60.06111, 33.948818],
          [60.059723, 33.949377],
          [60.05861, 33.949928],
          [60.04861, 33.951869],
          [60.011944, 33.983263],
          [60.004718, 33.994934],
          [60.000341, 33.998016],
          [59.998292, 34.001869],
          [59.991664, 34.004655],
          [59.991285, 34.004841],
          [59.988051, 34.005766],
          [59.986945, 34.005765],
          [59.986665, 34.006045],
          [59.985551, 34.006045],
          [59.983612, 34.006316],
          [59.976665, 34.007426],
          [59.974718, 34.007426],
          [59.972778, 34.007706],
          [59.972218, 34.007986],
          [59.970832, 34.007986],
          [59.970551, 34.008266],
          [59.968605, 34.008266],
          [59.967771, 34.008546],
          [59.965271, 34.008546],
          [59.963052, 34.008816],
          [59.962218, 34.009096],
          [59.959438, 34.009096],
          [59.958605, 34.009376],
          [59.953605, 34.009376],
          [59.952498, 34.009647],
          [59.928885, 34.009376],
          [59.915272, 34.008546],
          [59.911659, 34.007986],
          [59.909439, 34.007986],
          [59.906386, 34.007427],
          [59.901659, 34.007426],
          [59.892773, 34.006876],
          [59.865546, 34.006046],
          [59.864713, 34.005766],
          [59.817487, 34.006317],
          [59.81332, 34.006046],
          [59.812767, 34.005766],
          [59.807213, 34.005766],
          [59.80638, 34.005486],
          [59.786101, 34.005486],
          [59.784714, 34.005206],
          [59.782767, 34.005206],
          [59.781654, 34.005486],
          [59.777487, 34.005486],
          [59.77304, 34.005766],
          [59.768321, 34.005766],
          [59.767207, 34.005486],
          [59.748601, 34.005486],
          [59.747207, 34.005206],
          [59.740541, 34.005206],
          [59.739155, 34.004927],
          [59.724988, 34.004927],
          [59.719428, 34.004927],
          [59.718321, 34.005207],
          [59.708595, 34.005206],
          [59.696095, 34.006317],
          [59.694981, 34.006597],
          [59.687762, 34.006597],
          [59.686649, 34.006876],
          [59.685262, 34.006876],
          [59.678042, 34.008266],
          [59.675542, 34.009097],
          [59.673875, 34.009927],
          [59.667202, 34.011877],
          [59.666369, 34.012428],
          [59.659982, 34.014097],
          [59.654702, 34.014648],
          [59.654149, 34.014928],
          [59.650536, 34.014928],
          [59.649702, 34.015208],
          [59.643596, 34.015208],
          [59.643036, 34.015488],
          [59.642202, 34.015208],
          [59.641096, 34.015487],
          [59.639702, 34.015488],
          [59.638596, 34.015208],
          [59.621369, 34.015208],
          [59.619703, 34.014928],
          [59.615816, 34.014928],
          [59.611923, 34.015488],
          [59.610536, 34.015488],
          [59.59637, 34.019938],
          [59.590536, 34.024369],
          [59.584143, 34.03271],
          [59.58387, 34.03354],
          [59.58331, 34.0341],
          [59.583036, 34.03437],
          [59.58109, 34.037151],
          [59.58109, 34.03743],
          [59.580749, 34.037802],
          [59.580257, 34.038541],
          [59.537758, 34.102156],
          [59.537758, 34.102436],
          [59.536924, 34.103266],
          [59.536644, 34.103546],
          [59.536364, 34.103817],
          [59.536091, 34.104096],
          [59.53553, 34.104096],
          [59.534977, 34.103817],
          [59.526364, 34.102986],
          [59.516364, 34.102986],
          [59.515251, 34.103266],
          [59.514144, 34.102986],
          [59.513584, 34.103266],
          [59.505531, 34.103266],
          [59.504978, 34.103546],
          [59.503864, 34.103546],
          [59.503864, 34.103266],
          [59.501084, 34.103266],
          [59.500531, 34.102986],
          [59.498311, 34.102986],
          [59.498031, 34.102707],
          [59.489144, 34.102707],
          [59.487751, 34.102986],
          [59.483311, 34.102986],
          [59.482478, 34.103266],
          [59.479971, 34.103266],
          [59.477751, 34.103266],
          [59.474418, 34.103266],
          [59.473032, 34.103546],
          [59.455532, 34.106046],
          [59.453585, 34.106046],
          [59.449972, 34.106877],
          [59.448305, 34.106877],
          [59.447199, 34.107156],
          [59.445532, 34.107156],
          [59.444699, 34.107436],
          [59.436085, 34.107707],
          [59.435252, 34.107436],
          [59.433858, 34.107707],
          [59.407472, 34.106877],
          [59.406086, 34.107156],
          [59.404412, 34.107156],
          [59.401359, 34.107707],
          [59.399692, 34.107707],
          [59.399139, 34.107987],
          [59.392746, 34.108537],
          [59.389412, 34.108537],
          [59.388579, 34.108826],
          [59.381913, 34.108826],
          [59.380806, 34.109106],
          [59.380246, 34.109106],
          [59.379413, 34.108826],
          [59.374973, 34.108826],
          [59.373859, 34.108537],
          [59.368026, 34.108267],
          [59.361079, 34.108267],
          [59.360526, 34.107987],
          [59.357746, 34.107987],
          [59.357193, 34.107707],
          [59.349966, 34.107707],
          [59.349413, 34.107987],
          [59.347193, 34.107987],
          [59.346633, 34.108267],
          [59.314687, 34.114657],
          [59.30358, 34.119653],
          [59.302467, 34.120208],
          [59.298853, 34.121607],
          [59.297467, 34.122158],
          [59.29024, 34.126328],
          [59.289967, 34.126878],
          [59.288854, 34.127438],
          [59.264688, 34.147989],
          [59.262188, 34.149099],
          [59.261354, 34.149099],
          [59.259407, 34.14993],
          [59.254688, 34.149379],
          [59.254408, 34.149099],
          [59.253854, 34.149099],
          [59.253294, 34.14882],
          [59.245521, 34.145769],
          [59.245241, 34.145489],
          [59.220795, 34.134379],
          [59.219961, 34.134379],
          [59.192182, 34.126048],
          [59.191628, 34.126048],
          [59.165795, 34.119658],
          [59.164402, 34.119658],
          [59.164129, 34.119378],
          [59.162736, 34.119378],
          [59.162462, 34.119098],
          [59.155235, 34.118548],
          [59.143569, 34.118548],
          [59.141902, 34.118827],
          [59.135236, 34.119378],
          [59.133849, 34.119378],
          [59.133016, 34.119658],
          [59.126622, 34.120208],
          [59.122736, 34.120208],
          [59.121903, 34.120488],
          [59.119956, 34.120488],
          [59.115516, 34.121048],
          [59.113289, 34.121048],
          [59.111349, 34.121607],
          [59.103289, 34.122158],
          [59.101903, 34.121878],
          [59.087736, 34.121878],
          [59.086903, 34.122158],
          [59.078843, 34.122158],
          [59.077737, 34.121878],
          [59.06551, 34.121878],
          [59.064676, 34.122158],
          [59.064123, 34.121878],
          [59.063563, 34.122158],
          [59.059957, 34.122158],
          [59.059123, 34.122438],
          [59.044124, 34.123819],
          [59.043844, 34.123548],
          [59.04329, 34.123548],
          [59.04329, 34.123268],
          [59.04273, 34.122988],
          [59.042457, 34.122709],
          [59.042177, 34.122438],
          [59.036897, 34.118548],
          [59.036623, 34.118268],
          [59.008844, 34.103818],
          [59.006897, 34.103267],
          [59.006064, 34.102708],
          [58.994951, 34.098547],
          [58.979671, 34.094657],
          [58.975784, 34.094657],
          [58.975231, 34.094377],
          [58.965504, 34.094377],
          [58.964118, 34.094656],
          [58.962171, 34.094657],
          [58.951058, 34.097157],
          [58.948005, 34.097437],
          [58.948004, 34.097716],
          [58.905225, 34.110488],
          [58.904112, 34.110488],
          [58.899672, 34.111598],
          [58.898005, 34.111598],
          [58.891339, 34.112988],
          [58.889946, 34.112988],
          [58.889392, 34.113268],
          [58.888006, 34.113268],
          [58.886612, 34.113548],
          [58.882726, 34.113548],
          [58.881892, 34.113828],
          [58.875779, 34.114098],
          [58.864666, 34.114098],
          [58.863833, 34.114378],
          [58.862999, 34.114098],
          [58.858559, 34.113828],
          [58.852166, 34.113268],
          [58.847726, 34.113268],
          [58.846893, 34.112988],
          [58.824946, 34.108268],
          [58.821333, 34.106327],
          [58.811333, 34.098547],
          [58.808, 34.094657],
          [58.793273, 34.083266],
          [58.791606, 34.082707],
          [58.789107, 34.082436],
          [58.787167, 34.082156],
          [58.786607, 34.082156],
          [58.785773, 34.081876],
          [58.78272, 34.081876],
          [58.78244, 34.082156],
          [58.781334, 34.082156],
          [58.7805, 34.082436],
          [58.77744, 34.082995],
          [58.748827, 34.089377],
          [58.718548, 34.098267],
          [58.716327, 34.098827],
          [58.715214, 34.099377],
          [58.714381, 34.099377],
          [58.713828, 34.099657],
          [58.712714, 34.099657],
          [58.711881, 34.099937],
          [58.708548, 34.100208],
          [58.707715, 34.099937],
          [58.705775, 34.099937],
          [58.695495, 34.096047],
          [58.685495, 34.089097],
          [58.667995, 34.071605],
          [58.666518, 34.069195],
          [58.657435, 34.054374],
          [58.654101, 34.045213],
          [58.649655, 34.035491],
          [58.647988, 34.031872],
          [58.647715, 34.031601],
          [58.642715, 34.024101],
          [58.639935, 34.02244],
          [58.639102, 34.02244],
          [58.637988, 34.022151],
          [58.636322, 34.0216],
          [58.616882, 34.029372],
          [58.601322, 34.037432],
          [58.578823, 34.052154],
          [58.518816, 34.089377],
          [58.50021, 34.109108],
          [58.497984, 34.109755],
          [58.495812, 34.110387],
          [58.493264, 34.111048],
          [58.491317, 34.111319],
          [58.491037, 34.111599],
          [58.48993, 34.111599],
          [58.486317, 34.112989],
          [58.485484, 34.113269],
          [58.466037, 34.127159],
          [58.465204, 34.127439],
          [58.464371, 34.12827],
          [58.462431, 34.13577],
          [58.462151, 34.13605],
          [58.461871, 34.13716],
          [58.457984, 34.14522],
          [58.454924, 34.157711],
          [58.454371, 34.159932],
          [58.445758, 34.178551],
          [58.445205, 34.180212],
          [58.439651, 34.194662],
          [58.426872, 34.208272],
          [58.416872, 34.213552],
          [58.406312, 34.219103],
          [58.406038, 34.219662],
          [58.405205, 34.219942],
          [58.396872, 34.224933],
          [58.396592, 34.225493],
          [58.395498, 34.234817],
          [58.398953, 34.238818],
          [58.39832, 34.242912],
          [58.394642, 34.249221],
          [58.387755, 34.25571],
          [58.387076, 34.259627],
          [58.381266, 34.265664],
          [58.377095, 34.266148],
          [58.374394, 34.276166],
          [58.375858, 34.279159],
          [58.374278, 34.284411],
          [58.370727, 34.289306],
          [58.361914, 34.295831],
          [58.35662, 34.296859],
          [58.344366, 34.296331],
          [58.344086, 34.297161],
          [58.344366, 34.297441],
          [58.341033, 34.305771],
          [58.340575, 34.306232],
          [58.340199, 34.30661],
          [58.3202, 34.31466],
          [58.319647, 34.31466],
          [58.313253, 34.31633],
          [58.312146, 34.31633],
          [58.312146, 34.31661],
          [58.280754, 34.32105],
          [58.27992, 34.32133],
          [58.275753, 34.32133],
          [58.2752, 34.32161],
          [58.27464, 34.32161],
          [58.274367, 34.32133],
          [58.267974, 34.32133],
          [58.267421, 34.32105],
          [58.265474, 34.32105],
          [58.26492, 34.32133],
          [58.264087, 34.32133],
          [58.251027, 34.31328],
          [58.240754, 34.301051],
          [58.240754, 34.30078],
          [58.240194, 34.300221],
          [58.226861, 34.281882],
          [58.225747, 34.280772],
          [58.217141, 34.266882],
          [58.214081, 34.262713],
          [58.213808, 34.262162],
          [58.196028, 34.245213],
          [58.189081, 34.241603],
          [58.186301, 34.240773],
          [58.185748, 34.240773],
          [58.185468, 34.240493],
          [58.184081, 34.240493],
          [58.181581, 34.239942],
          [58.181028, 34.239662],
          [58.180195, 34.239663],
          [58.179915, 34.239383],
          [58.179361, 34.239383],
          [58.178528, 34.239383],
          [58.165195, 34.237442],
          [58.164362, 34.237163],
          [58.160468, 34.237163],
          [58.159635, 34.236883],
          [58.159081, 34.237163],
          [58.157968, 34.236883],
          [58.155188, 34.236883],
          [58.153802, 34.236603],
          [58.146022, 34.236603],
          [58.144915, 34.236883],
          [58.136582, 34.236883],
          [58.136022, 34.237163],
          [58.133248, 34.237163],
          [58.127969, 34.237713],
          [58.127135, 34.237993],
          [58.125189, 34.237993],
          [58.124915, 34.238273],
          [58.122969, 34.238273],
          [58.122415, 34.238553],
          [58.118242, 34.238552],
          [58.117689, 34.238273],
          [58.114075, 34.238273],
          [58.113242, 34.237993],
          [58.112136, 34.237993],
          [58.111302, 34.237713],
          [58.109909, 34.237713],
          [58.107689, 34.237163],
          [58.106302, 34.237163],
          [58.105469, 34.236883],
          [58.090742, 34.235222],
          [58.089909, 34.234943],
          [58.071023, 34.234942],
          [58.063523, 34.235493],
          [58.06269, 34.235773],
          [58.061576, 34.235773],
          [58.059356, 34.236053],
          [58.054909, 34.236053],
          [58.049356, 34.236603],
          [58.048523, 34.236883],
          [58.046023, 34.236883],
          [58.041296, 34.237713],
          [58.040463, 34.237993],
          [58.03935, 34.237993],
          [58.03741, 34.238553],
          [58.036577, 34.238553],
          [58.035183, 34.239103],
          [58.010464, 34.258552],
          [58.00991, 34.259103],
          [57.964351, 34.298831],
          [57.963791, 34.299111],
          [57.952957, 34.306331],
          [57.952404, 34.306331],
          [57.952124, 34.306881],
          [57.934351, 34.314661],
          [57.933518, 34.314661],
          [57.931571, 34.315491],
          [57.930738, 34.315491],
          [57.929625, 34.316051],
          [57.928511, 34.316051],
          [57.926844, 34.31661],
          [57.926011, 34.31661],
          [57.916291, 34.32133],
          [57.916011, 34.32161],
          [57.908238, 34.32411],
          [57.907125, 34.324381],
          [57.896572, 34.32855],
          [57.893792, 34.329669],
          [57.891292, 34.33022],
          [57.889898, 34.33022],
          [57.889345, 34.3305],
          [57.877398, 34.3305],
          [57.876845, 34.330779],
          [57.862126, 34.330779],
          [57.861292, 34.3305],
          [57.860732, 34.330779],
          [57.859899, 34.3305],
          [57.855179, 34.3305],
          [57.854619, 34.33022],
          [57.854066, 34.33022],
          [57.852399, 34.33022],
          [57.846565, 34.329669],
          [57.846012, 34.32939],
          [57.844619, 34.32939],
          [57.844066, 34.32911],
          [57.843232, 34.32911],
          [57.842953, 34.32883],
          [57.841286, 34.32883],
          [57.832119, 34.32689],
          [57.831286, 34.32661],
          [57.830179, 34.32661],
          [57.829619, 34.32633],
          [57.821286, 34.32633],
          [57.820733, 34.32661],
          [57.817119, 34.32661],
          [57.816566, 34.32689],
          [57.8149, 34.32689],
          [57.813233, 34.32744],
          [57.812399, 34.32744],
          [57.8124, 34.32772],
          [57.811839, 34.32772],
          [57.811839, 34.328],
          [57.811006, 34.328],
          [57.809619, 34.32883],
          [57.808786, 34.32883],
          [57.807119, 34.329669],
          [57.806286, 34.329669],
          [57.79434, 34.33272],
          [57.793506, 34.33272],
          [57.766007, 34.347159],
          [57.765727, 34.347439],
          [57.765173, 34.347999],
          [57.749441, 34.366809],
          [57.750261, 34.367108],
          [57.75229, 34.368944],
          [57.75208, 34.3716],
          [57.756708, 34.378414],
          [57.757393, 34.378836],
          [57.740727, 34.395495],
          [57.740174, 34.396334],
          [57.73934, 34.396885],
          [57.731841, 34.404113],
          [57.700727, 34.422162],
          [57.699894, 34.422442],
          [57.694334, 34.428551],
          [57.694334, 34.428831],
          [57.693501, 34.42967],
          [57.690168, 34.437169],
          [57.689614, 34.439669],
          [57.688228, 34.442439],
          [57.681281, 34.451608],
          [57.669062, 34.469386],
          [57.668228, 34.470504],
          [57.655448, 34.490222],
          [57.646282, 34.511338],
          [57.641835, 34.518277],
          [57.641555, 34.519107],
          [57.640449, 34.520506],
          [57.624055, 34.536342],
          [57.591556, 34.564668],
          [57.590723, 34.565777],
          [57.581829, 34.575784],
          [57.581556, 34.576615],
          [57.55711, 34.598],
          [57.55669, 34.598],
          [57.559057, 34.601889],
          [57.559057, 34.602169],
          [57.565163, 34.608278],
          [57.573496, 34.623833],
          [57.582389, 34.63828],
          [57.58239, 34.638559],
          [57.590996, 34.650506],
          [57.590996, 34.651065],
          [57.612109, 34.674678],
          [57.619056, 34.683006],
          [57.621276, 34.687175],
          [57.621556, 34.687454],
          [57.621836, 34.688005],
          [57.622389, 34.689394],
          [57.624336, 34.691894],
          [57.624336, 34.692453],
          [57.636282, 34.70467],
          [57.641002, 34.708838],
          [57.642949, 34.710228],
          [57.642949, 34.710507],
          [57.643502, 34.710507],
          [57.652115, 34.719115],
          [57.652669, 34.719954],
          [57.653502, 34.720504],
          [57.656016, 34.72352],
          [57.660449, 34.729401],
          [57.673502, 34.744946],
          [57.674615, 34.746336],
          [57.702115, 34.774395],
          [57.709061, 34.780232],
          [57.714062, 34.783562],
          [57.714895, 34.784401],
          [57.727395, 34.791068],
          [57.729341, 34.791898],
          [57.785734, 34.819957],
          [57.786287, 34.819957],
          [57.786416, 34.820041],
          [57.787121, 34.820507],
          [57.786574, 34.822289],
          [57.786007, 34.823845],
          [57.78434, 34.826624],
          [57.749895, 34.867177],
          [57.739685, 34.881888],
          [57.719895, 34.909397],
          [57.711842, 34.919606],
          [57.711562, 34.919961],
          [57.704335, 34.926069],
          [57.694062, 34.931066],
          [57.680729, 34.935793],
          [57.672669, 34.938843],
          [57.66133, 34.94692],
          [57.657949, 34.949397],
          [57.655449, 34.950796],
          [57.654616, 34.951626],
          [57.651836, 34.953015],
          [57.637669, 34.96301],
          [57.621556, 34.976072],
          [57.572944, 35.000104],
          [57.566557, 35.010793],
          [57.562111, 35.021627],
          [57.56183, 35.027175],
          [57.561277, 35.031631],
          [57.561557, 35.032461],
          [57.558551, 35.050742],
          [57.558497, 35.05107],
          [57.552944, 35.059125],
          [57.546278, 35.063852],
          [57.544611, 35.064682],
          [57.542384, 35.064682],
          [57.541551, 35.064402],
          [57.540997, 35.064682],
          [57.540164, 35.064402],
          [57.539051, 35.064402],
          [57.538778, 35.064682],
          [57.537111, 35.064682],
          [57.536278, 35.064961],
          [57.535164, 35.064961],
          [57.521831, 35.069408],
          [57.515718, 35.071348],
          [57.511271, 35.071348],
          [57.507319, 35.070278],
          [57.497938, 35.06774],
          [57.496551, 35.06774],
          [57.495438, 35.06746],
          [57.493218, 35.06746],
          [57.491832, 35.06718],
          [57.489605, 35.06718],
          [57.488772, 35.06746],
          [57.485718, 35.06746],
          [57.484885, 35.06774],
          [57.483218, 35.06774],
          [57.454052, 35.072737],
          [57.452658, 35.072737],
          [57.450159, 35.073287],
          [57.438772, 35.075795],
          [57.433212, 35.077184],
          [57.379326, 35.08468],
          [57.36738, 35.086908],
          [57.365153, 35.087178],
          [57.35682, 35.089406],
          [57.3371, 35.093574],
          [57.333487, 35.094133],
          [57.331267, 35.094683],
          [57.317654, 35.096072],
          [57.30154, 35.096072],
          [57.298767, 35.095793],
          [57.294597, 35.095793],
          [57.291821, 35.095522],
          [57.287654, 35.095522],
          [57.284874, 35.095242],
          [57.278207, 35.095242],
          [57.275431, 35.094963],
          [57.271541, 35.094963],
          [57.239318, 35.090516],
          [57.215148, 35.088017],
          [57.213482, 35.088017],
          [57.201538, 35.086628],
          [57.198482, 35.086349],
          [57.162925, 35.077735],
          [57.159035, 35.077184],
          [57.156535, 35.076345],
          [57.152646, 35.075516],
          [57.127646, 35.066071],
          [57.048753, 35.027176],
          [57.045973, 35.026346],
          [57.044587, 35.025516],
          [57.041253, 35.024406],
          [57.032917, 35.020518],
          [57.00986, 35.005237],
          [57.000694, 34.998012],
          [56.982084, 34.993844],
          [56.962081, 34.986907],
          [56.960971, 34.986907],
          [56.960414, 34.986628],
          [56.959581, 34.986628],
          [56.950691, 34.984679],
          [56.949305, 34.984679],
          [56.933191, 34.98218],
          [56.931248, 34.98218],
          [56.929858, 34.981901],
          [56.925968, 34.981901],
          [56.924581, 34.981621],
          [56.919858, 34.981351],
          [56.916245, 34.981351],
          [56.910412, 34.981901],
          [56.906245, 34.981901],
          [56.905135, 34.98218],
          [56.902635, 34.98218],
          [56.901245, 34.98246],
          [56.895969, 34.98246],
          [56.879855, 34.979961],
          [56.864575, 34.974405],
          [56.864022, 34.973845],
          [56.833743, 34.954405],
          [56.827353, 34.948848],
          [56.824019, 34.946629],
          [56.821796, 34.944401],
          [56.820963, 34.941902],
          [56.819853, 34.939123],
          [56.820686, 34.937454],
          [56.821796, 34.936904],
          [56.824853, 34.937175],
          [56.829852, 34.938844],
          [56.839576, 34.937175],
          [56.844299, 34.935235],
          [56.852076, 34.921902],
          [56.851799, 34.920512],
          [56.851522, 34.919123],
          [56.850966, 34.918014],
          [56.849576, 34.914955],
          [56.835409, 34.894675],
          [56.829852, 34.890237],
          [56.817909, 34.876903],
          [56.809296, 34.864679],
          [56.808739, 34.863289],
          [56.801519, 34.853573],
          [56.798463, 34.848286],
          [56.797629, 34.847727],
          [56.795406, 34.845237],
          [56.79013, 34.837451],
          [56.78374, 34.830513],
          [56.78263, 34.829954],
          [56.782349, 34.829395],
          [56.75346, 34.807453],
          [56.753183, 34.806623],
          [56.751793, 34.805234],
          [56.749017, 34.797177],
          [56.749017, 34.796897],
          [56.747903, 34.793289],
          [56.745683, 34.78968],
          [56.73985, 34.782733],
          [56.672344, 34.754394],
          [56.666511, 34.752174],
          [56.649288, 34.747735],
          [56.646235, 34.747735],
          [56.645678, 34.747456],
          [56.644568, 34.747456],
          [56.637898, 34.745507],
          [56.637345, 34.744948],
          [56.635678, 34.744677],
          [56.625678, 34.740508],
          [56.611508, 34.732451],
          [56.566229, 34.717727],
          [56.560396, 34.715787],
          [56.487893, 34.690226],
          [56.485113, 34.689675],
          [56.482337, 34.688845],
          [56.480946, 34.688845],
          [56.480113, 34.688565],
          [56.479003, 34.688565],
          [56.477336, 34.688006],
          [56.473446, 34.686896],
          [56.472893, 34.686896],
          [56.469837, 34.686066],
          [56.467337, 34.686066],
          [56.466223, 34.685786],
          [56.464837, 34.685787],
          [56.463723, 34.685507],
          [56.462337, 34.685507],
          [56.461503, 34.685227],
          [56.458447, 34.685227],
          [56.45789, 34.684947],
          [56.454837, 34.684677],
          [56.451223, 34.684677],
          [56.446223, 34.683558],
          [56.438447, 34.682448],
          [56.436224, 34.682448],
          [56.432334, 34.681898],
          [56.42539, 34.682448],
          [56.421777, 34.682448],
          [56.420944, 34.682728],
          [56.419834, 34.682728],
          [56.419277, 34.683008],
          [56.418444, 34.683008],
          [56.415944, 34.683558],
          [56.412611, 34.683558],
          [56.411777, 34.683838],
          [56.410944, 34.683558],
          [56.410667, 34.683838],
          [56.407887, 34.683838],
          [56.407054, 34.684117],
          [56.402887, 34.684117],
          [56.399834, 34.684677],
          [56.395944, 34.684947],
          [56.388997, 34.685227],
          [56.383998, 34.685227],
          [56.377054, 34.686066],
          [56.318995, 34.692454],
          [56.313162, 34.692454],
          [56.312328, 34.692734],
          [56.309828, 34.693005],
          [56.306495, 34.693005],
          [56.305105, 34.693285],
          [56.301219, 34.693285],
          [56.299829, 34.693564],
          [56.297605, 34.693564],
          [56.296215, 34.693844],
          [56.292605, 34.693844],
          [56.291215, 34.694124],
          [56.282049, 34.695233],
          [56.278715, 34.695784],
          [56.277326, 34.695784],
          [56.275659, 34.696343],
          [56.274549, 34.696343],
          [56.272326, 34.697173],
          [56.271216, 34.697173],
          [56.265382, 34.698843],
          [56.264272, 34.698843],
          [56.243993, 34.703561],
          [56.242879, 34.704121],
          [56.242046, 34.704121],
          [56.241769, 34.704401],
          [56.241213, 34.704401],
          [56.227974, 34.710417],
          [56.227603, 34.709119],
          [56.227326, 34.70884],
          [56.227046, 34.70801],
          [56.225659, 34.706061],
          [56.21788, 34.693285],
          [56.16899, 34.632452],
          [56.163987, 34.626343],
          [56.163433, 34.625225],
          [56.1576, 34.616617],
          [56.135097, 34.578006],
          [56.133987, 34.576896],
          [56.118431, 34.554391],
          [56.118154, 34.553281],
          [56.116487, 34.550231],
          [56.113707, 34.545223],
          [56.066761, 34.471607],
          [56.063428, 34.467167],
          [56.062871, 34.466888],
          [55.998982, 34.385498],
          [55.995369, 34.380218],
          [55.992869, 34.375499],
          [55.992035, 34.374668],
          [55.957589, 34.314104],
          [55.951756, 34.302714],
          [55.948979, 34.298554],
          [55.948979, 34.298003],
          [55.943699, 34.290215],
          [55.921199, 34.247445],
          [55.920643, 34.247165],
          [55.920366, 34.246885],
          [55.920366, 34.246605],
          [55.919809, 34.246055],
          [55.919533, 34.245775],
          [55.9162, 34.244385],
          [55.915366, 34.244385],
          [55.915086, 34.244105],
          [55.914533, 34.244105],
          [55.913976, 34.243826],
          [55.911753, 34.243275],
          [55.903976, 34.243275],
          [55.90342, 34.243555],
          [55.901196, 34.243826],
          [55.900363, 34.243555],
          [55.89981, 34.243826],
          [55.89731, 34.243826],
          [55.896753, 34.244105],
          [55.892587, 34.244106],
          [55.891753, 34.244385],
          [55.881197, 34.244385],
          [55.880087, 34.244665],
          [55.877306, 34.244665],
          [55.876197, 34.244945],
          [55.869253, 34.244945],
          [55.86814, 34.244665],
          [55.866474, 34.244665],
          [55.86592, 34.244385],
          [55.86453, 34.244385],
          [55.86314, 34.244106],
          [55.861474, 34.244106],
          [55.86064, 34.244385],
          [55.856473, 34.244385],
          [55.85175, 34.243826],
          [55.84675, 34.243275],
          [55.816471, 34.243275],
          [55.815361, 34.242995],
          [55.810637, 34.242995],
          [55.810084, 34.242716],
          [55.807581, 34.242445],
          [55.799804, 34.242445],
          [55.798694, 34.242165],
          [55.797861, 34.242445],
          [55.797304, 34.242165],
          [55.794528, 34.242165],
          [55.793694, 34.242445],
          [55.791471, 34.242445],
          [55.791194, 34.242716],
          [55.789248, 34.242716],
          [55.788691, 34.242995],
          [55.786748, 34.242995],
          [55.786191, 34.243275],
          [55.782858, 34.243275],
          [55.782305, 34.243555],
          [55.777581, 34.243555],
          [55.773138, 34.243275],
          [55.763135, 34.243275],
          [55.762301, 34.243555],
          [55.753135, 34.244106],
          [55.752582, 34.243826],
          [55.752025, 34.244106],
          [55.749802, 34.244106],
          [55.749245, 34.243826],
          [55.748968, 34.243555],
          [55.746468, 34.242716],
          [55.726745, 34.242445],
          [55.725912, 34.242716],
          [55.723688, 34.242716],
          [55.722855, 34.242996],
          [55.710912, 34.242995],
          [55.709522, 34.242716],
          [55.678689, 34.242165],
          [55.677576, 34.242445],
          [55.669799, 34.242445],
          [55.668966, 34.242716],
          [55.659799, 34.242445],
          [55.658409, 34.242165],
          [55.654796, 34.242165],
          [55.653409, 34.242445],
          [55.649796, 34.242716],
          [55.612573, 34.240496],
          [55.606183, 34.240496],
          [55.605073, 34.240775],
          [55.60174, 34.240775],
          [55.600627, 34.240496],
          [55.595627, 34.240496],
          [55.59424, 34.240216],
          [55.58646, 34.239385],
          [55.581184, 34.239386],
          [55.500068, 34.252716],
          [55.488121, 34.252445],
          [55.486178, 34.252445],
          [55.485621, 34.252165],
          [55.482845, 34.252165],
          [55.482288, 34.251885],
          [55.481455, 34.252165],
          [55.479512, 34.251885],
          [55.478678, 34.251885],
          [55.478678, 34.251606],
          [55.478955, 34.250216],
          [55.478678, 34.249386],
          [55.477845, 34.243275],
          [55.477845, 34.222996],
          [55.478122, 34.220775],
          [55.478678, 34.211326],
          [55.480622, 34.169105],
          [55.480345, 34.162444],
          [55.480068, 34.158825],
          [55.479235, 34.150223],
          [55.480901, 34.111602],
          [55.482011, 34.102161],
          [55.482568, 34.09244],
          [55.483678, 34.065489],
          [55.483401, 34.062718],
          [55.483121, 34.053827],
          [55.482845, 34.051598],
          [55.482568, 34.051047],
          [55.482845, 34.050767],
          [55.482568, 34.048827],
          [55.482288, 34.040487],
          [55.482011, 34.039656],
          [55.482288, 34.039106],
          [55.482011, 34.038826],
          [55.482011, 34.018273],
          [55.482568, 34.013823],
          [55.482288, 34.013273],
          [55.482568, 34.012993],
          [55.482288, 34.011052],
          [55.482011, 34.010772],
          [55.482288, 34.009102],
          [55.482568, 33.997991],
          [55.485345, 33.957436],
          [55.485068, 33.954376],
          [55.484788, 33.949384],
          [55.484511, 33.947434],
          [55.487288, 33.929932],
          [55.487568, 33.92494],
          [55.488401, 33.900487],
          [55.489235, 33.875492],
          [55.489511, 33.871321],
          [55.490625, 33.851318],
          [55.490625, 33.829933],
          [55.489791, 33.818541],
          [55.490344, 33.780763],
          [55.491178, 33.738262],
          [55.491178, 33.736042],
          [55.491734, 33.715757],
          [55.491734, 33.698262],
          [55.492011, 33.687149],
          [55.492291, 33.678817],
          [55.493678, 33.626592],
          [55.493401, 33.625202],
          [55.493678, 33.612708],
          [55.493401, 33.606876],
          [55.493124, 33.602425],
          [55.492844, 33.597704],
          [55.492568, 33.596864],
          [55.493124, 33.587981],
          [55.493124, 33.58715],
          [55.493401, 33.58687],
          [55.493958, 33.583259],
          [55.493958, 33.582149],
          [55.493678, 33.581869],
          [55.495344, 33.567424],
          [55.497567, 33.538535],
          [55.497844, 33.529092],
          [55.496734, 33.52159],
          [55.496734, 33.519929],
          [55.496458, 33.518809],
          [55.496177, 33.517978],
          [55.488401, 33.502143],
          [55.416175, 33.406309],
          [55.402009, 33.386312],
          [55.400342, 33.384642],
          [55.394509, 33.377979],
          [55.392285, 33.375478],
          [55.387285, 33.366314],
          [55.386452, 33.364924],
        ],
      ],
    },
  },
  NorthKhorasan: {
    properties: {
      value: "KhorasanNorth",
      name: "استان خراسان شمالی, ایران",
      id: 4,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [55.908146, 37.210018],
          [55.908703, 37.205572],
          [55.920646, 37.200848],
          [55.922869, 37.200848],
          [55.923703, 37.200569],
          [55.928703, 37.200569],
          [55.934536, 37.200569],
          [55.935093, 37.200569],
          [55.937036, 37.200848],
          [55.937592, 37.200848],
          [55.938426, 37.201127],
          [55.951482, 37.204457],
          [55.953149, 37.205293],
          [55.955092, 37.205851],
          [55.975649, 37.216685],
          [56.008705, 37.234457],
          [56.009262, 37.23335],
          [56.010652, 37.231406],
          [56.012318, 37.229184],
          [56.058154, 37.169461],
          [56.059541, 37.16668],
          [56.062874, 37.160849],
          [56.062874, 37.157789],
          [56.062321, 37.156682],
          [56.059264, 37.153352],
          [56.036765, 37.141959],
          [56.021485, 37.135849],
          [56.008985, 37.128901],
          [56.003151, 37.125572],
          [55.994262, 37.119182],
          [55.994538, 37.118624],
          [56.001762, 37.115015],
          [56.003428, 37.115015],
          [56.003985, 37.114736],
          [56.004818, 37.114736],
          [56.034261, 37.107239],
          [56.038151, 37.105295],
          [56.039541, 37.104179],
          [56.040098, 37.100292],
          [56.038708, 37.095846],
          [56.031761, 37.086126],
          [56.026208, 37.080573],
          [56.025375, 37.07862],
          [56.026761, 37.070565],
          [56.031761, 37.062239],
          [56.032318, 37.053068],
          [56.032041, 37.051682],
          [56.030095, 37.048622],
          [56.023985, 37.041124],
          [56.022318, 37.037236],
          [56.018428, 37.026678],
          [56.017595, 37.023906],
          [56.015651, 37.015283],
          [56.015371, 37.013627],
          [56.015095, 37.011404],
          [56.014818, 37.009172],
          [56.014538, 37.006957],
          [56.013705, 37.005841],
          [56.009099, 37.004493],
          [56.000477, 37.003316],
          [55.99506, 37.003399],
          [55.983195, 37.005754],
          [55.979178, 36.996245],
          [55.978862, 36.982766],
          [55.981482, 36.973059],
          [55.982038, 36.970844],
          [55.981762, 36.967514],
          [55.981482, 36.964453],
          [55.981205, 36.960285],
          [55.980996, 36.958814],
          [55.980981, 36.958709],
          [55.980928, 36.958341],
          [55.980648, 36.957513],
          [55.980372, 36.953066],
          [55.980928, 36.938339],
          [55.980648, 36.937511],
          [55.984262, 36.900008],
          [55.984815, 36.898064],
          [55.986762, 36.894175],
          [55.993148, 36.88889],
          [56.004818, 36.879726],
          [56.005371, 36.87445],
          [56.002871, 36.872226],
          [55.999261, 36.868337],
          [55.997871, 36.865834],
          [55.997315, 36.864448],
          [55.998151, 36.861117],
          [55.998428, 36.860568],
          [56.012595, 36.848342],
          [56.013428, 36.844453],
          [56.013151, 36.841392],
          [56.012871, 36.841121],
          [56.013428, 36.836395],
          [56.013985, 36.835287],
          [56.014538, 36.835008],
          [56.014538, 36.83445],
          [56.015651, 36.833613],
          [56.017595, 36.833064],
          [56.018151, 36.833064],
          [56.018708, 36.832784],
          [56.019541, 36.833064],
          [56.020928, 36.833064],
          [56.021208, 36.833343],
          [56.029541, 36.835567],
          [56.058431, 36.843894],
          [56.059821, 36.843894],
          [56.074821, 36.847504],
          [56.076487, 36.848342],
          [56.10871, 36.862233],
          [56.114823, 36.865006],
          [56.129543, 36.873064],
          [56.13038, 36.872505],
          [56.138433, 36.870291],
          [56.14538, 36.871398],
          [56.15927, 36.87445],
          [56.163156, 36.87445],
          [56.16677, 36.875008],
          [56.168713, 36.875008],
          [56.187603, 36.881949],
          [56.189826, 36.883345],
          [56.195103, 36.886396],
          [56.210939, 36.896119],
          [56.212882, 36.897784],
          [56.216772, 36.900008],
          [56.219549, 36.900836],
          [56.221492, 36.900836],
          [56.221772, 36.900557],
          [56.226216, 36.899171],
          [56.231496, 36.894454],
          [56.240939, 36.873613],
          [56.244552, 36.868067],
          [56.254552, 36.850565],
          [56.264552, 36.83806],
          [56.270942, 36.831947],
          [56.277885, 36.82695],
          [56.282332, 36.822503],
          [56.287332, 36.81973],
          [56.291218, 36.817227],
          [56.292331, 36.816957],
          [56.308164, 36.809169],
          [56.312608, 36.808061],
          [56.317331, 36.80473],
          [56.335664, 36.780006],
          [56.340111, 36.778619],
          [56.342611, 36.776953],
          [56.351221, 36.766671],
          [56.352334, 36.762502],
          [56.354001, 36.759729],
          [56.354834, 36.75945],
          [56.365111, 36.753057],
          [56.36539, 36.751391],
          [56.365111, 36.742225],
          [56.364834, 36.741666],
          [56.36539, 36.738893],
          [56.367891, 36.73639],
          [56.369834, 36.7325],
          [56.369584, 36.731751],
          [56.369574, 36.731722],
          [56.369573, 36.731718],
          [56.369565, 36.731695],
          [56.369557, 36.731672],
          [56.367057, 36.727782],
          [56.367334, 36.726674],
          [56.37011, 36.725278],
          [56.372057, 36.72056],
          [56.368167, 36.712222],
          [56.36789, 36.711952],
          [56.365667, 36.705],
          [56.365111, 36.703334],
          [56.363724, 36.701119],
          [56.363444, 36.700003],
          [56.364001, 36.692502],
          [56.363724, 36.691664],
          [56.362057, 36.689449],
          [56.361224, 36.689449],
          [56.360667, 36.68917],
          [56.359277, 36.687225],
          [56.359001, 36.686387],
          [56.359557, 36.682776],
          [56.361224, 36.681669],
          [56.363167, 36.682227],
          [56.3715, 36.683335],
          [56.373167, 36.683893],
          [56.374277, 36.683893],
          [56.415113, 36.692781],
          [56.41706, 36.692781],
          [56.417893, 36.69306],
          [56.422337, 36.69306],
          [56.423726, 36.693339],
          [56.42706, 36.693339],
          [56.42817, 36.693618],
          [56.43206, 36.693888],
          [56.436226, 36.693888],
          [56.437336, 36.694167],
          [56.439003, 36.694167],
          [56.43956, 36.693888],
          [56.44067, 36.693888],
          [56.44206, 36.694167],
          [56.446503, 36.694167],
          [56.447616, 36.693888],
          [56.454274, 36.692714],
          [56.457059, 36.692222],
          [56.458173, 36.691664],
          [56.460116, 36.691115],
          [56.466783, 36.688332],
          [56.468173, 36.688062],
          [56.481506, 36.678616],
          [56.492896, 36.666666],
          [56.496506, 36.665],
          [56.497339, 36.665],
          [56.497896, 36.664721],
          [56.498729, 36.664721],
          [56.499562, 36.664442],
          [56.501786, 36.664442],
          [56.504839, 36.665],
          [56.507062, 36.665],
          [56.519842, 36.666945],
          [56.521785, 36.666945],
          [56.522895, 36.667224],
          [56.529842, 36.667224],
          [56.530675, 36.667503],
          [56.532065, 36.667503],
          [56.533452, 36.673609],
          [56.533732, 36.673888],
          [56.534285, 36.676392],
          [56.534842, 36.677499],
          [56.535119, 36.678337],
          [56.535399, 36.678616],
          [56.541232, 36.685],
          [56.559008, 36.698336],
          [56.563455, 36.702227],
          [56.568653, 36.706795],
          [56.581788, 36.718336],
          [56.592901, 36.726395],
          [56.595678, 36.728619],
          [56.598178, 36.730006],
          [56.599845, 36.731671],
          [56.647904, 36.765284],
          [56.651237, 36.767778],
          [56.658737, 36.772784],
          [56.660681, 36.773613],
          [56.712907, 36.795285],
          [56.715683, 36.796113],
          [56.72124, 36.798895],
          [56.72263, 36.799174],
          [56.724297, 36.800003],
          [56.763463, 36.810564],
          [56.773186, 36.814453],
          [56.774576, 36.814732],
          [56.777353, 36.816119],
          [56.779299, 36.816677],
          [56.787909, 36.818622],
          [56.797076, 36.821395],
          [56.797632, 36.821674],
          [56.799576, 36.821674],
          [56.800133, 36.821953],
          [56.801799, 36.821953],
          [56.815689, 36.826112],
          [56.816799, 36.826391],
          [56.817079, 36.826671],
          [56.818189, 36.826671],
          [56.822079, 36.827778],
          [56.824022, 36.828057],
          [56.827356, 36.829174],
          [56.852359, 36.835287],
          [56.860135, 36.836952],
          [56.861525, 36.836952],
          [56.874582, 36.840283],
          [56.901525, 36.84528],
          [56.908195, 36.846667],
          [56.920138, 36.848899],
          [56.921248, 36.849169],
          [56.938751, 36.852509],
          [56.939584, 36.852509],
          [56.940138, 36.852788],
          [56.941528, 36.852788],
          [56.955418, 36.85584],
          [56.983754, 36.861116],
          [56.984625, 36.861335],
          [56.997921, 36.864726],
          [57.000192, 36.865284],
          [57.010697, 36.869453],
          [57.012644, 36.870011],
          [57.013477, 36.870011],
          [57.017087, 36.870839],
          [57.01792, 36.871118],
          [57.01931, 36.871118],
          [57.020143, 36.871397],
          [57.025977, 36.871397],
          [57.02681, 36.871676],
          [57.029587, 36.871676],
          [57.03042, 36.871397],
          [57.03292, 36.871397],
          [57.03542, 36.870839],
          [57.036533, 36.870839],
          [57.046257, 36.86695],
          [57.04709, 36.866392],
          [57.082369, 36.857506],
          [57.092646, 36.854175],
          [57.093759, 36.854175],
          [57.107093, 36.850006],
          [57.108203, 36.849448],
          [57.112649, 36.847783],
          [57.120149, 36.845001],
          [57.124036, 36.843893],
          [57.131816, 36.842786],
          [57.132649, 36.842507],
          [57.133759, 36.842507],
          [57.134324, 36.842393],
          [57.141259, 36.842228],
          [57.142925, 36.841949],
          [57.148205, 36.841949],
          [57.160705, 36.84112],
          [57.162095, 36.840841],
          [57.164315, 36.840841],
          [57.165705, 36.840562],
          [57.167648, 36.840562],
          [57.169038, 36.840283],
          [57.170985, 36.840283],
          [57.174318, 36.839725],
          [57.177928, 36.839725],
          [57.180985, 36.839176],
          [57.182928, 36.839176],
          [57.183762, 36.838896],
          [57.185428, 36.838896],
          [57.186262, 36.838617],
          [57.189042, 36.838617],
          [57.190428, 36.838338],
          [57.191818, 36.838338],
          [57.210151, 36.834449],
          [57.215431, 36.833612],
          [57.219874, 36.832504],
          [57.221264, 36.832504],
          [57.221633, 36.832318],
          [57.265154, 36.822781],
          [57.297657, 36.818621],
          [57.29849, 36.818342],
          [57.300437, 36.818342],
          [57.301544, 36.818063],
          [57.30349, 36.818063],
          [57.34877, 36.809726],
          [57.35377, 36.807781],
          [57.355716, 36.807223],
          [57.360989, 36.805557],
          [57.375996, 36.799444],
          [57.377936, 36.798336],
          [57.379603, 36.797778],
          [57.388496, 36.792502],
          [57.401829, 36.787226],
          [57.403496, 36.786947],
          [57.405716, 36.786118],
          [57.441829, 36.780284],
          [57.443775, 36.780284],
          [57.444889, 36.780005],
          [57.447109, 36.780005],
          [57.454889, 36.778618],
          [57.489888, 36.769164],
          [57.492108, 36.768336],
          [57.499335, 36.765832],
          [57.505721, 36.763617],
          [57.506275, 36.763059],
          [57.509608, 36.761952],
          [57.510168, 36.761393],
          [57.517388, 36.758611],
          [57.529888, 36.755559],
          [57.532114, 36.75528],
          [57.544614, 36.75139],
          [57.545721, 36.751167],
          [57.554334, 36.747779],
          [57.556001, 36.747509],
          [57.58878, 36.734165],
          [57.60934, 36.717776],
          [57.61434, 36.714445],
          [57.618227, 36.710834],
          [57.63962, 36.691384],
          [57.658506, 36.68056],
          [57.663742, 36.678513],
          [57.701839, 36.663612],
          [57.707952, 36.661946],
          [57.715732, 36.658335],
          [57.718232, 36.657497],
          [57.745732, 36.643332],
          [57.752125, 36.640558],
          [57.777398, 36.630274],
          [57.821571, 36.616108],
          [57.883517, 36.601662],
          [57.88463, 36.601662],
          [57.88657, 36.601113],
          [57.89407, 36.599447],
          [57.921016, 36.590558],
          [57.922963, 36.59],
          [57.923756, 36.58968],
          [57.931849, 36.586388],
          [57.933523, 36.585551],
          [57.93491, 36.58528],
          [57.936023, 36.584722],
          [57.936856, 36.584722],
          [57.938243, 36.584164],
          [57.962409, 36.579724],
          [57.963796, 36.579444],
          [57.971023, 36.579444],
          [57.972409, 36.579724],
          [57.977689, 36.579724],
          [57.981856, 36.579994],
          [57.982689, 36.580273],
          [57.986302, 36.580273],
          [57.987409, 36.580552],
          [57.990189, 36.580552],
          [58.003522, 36.581948],
          [58.009636, 36.581948],
          [58.013802, 36.582218],
          [58.014915, 36.582497],
          [58.016855, 36.582497],
          [58.019635, 36.583005],
          [58.019915, 36.583056],
          [58.021855, 36.583056],
          [58.029082, 36.584163],
          [58.030748, 36.584722],
          [58.047135, 36.586667],
          [58.052135, 36.588054],
          [58.053801, 36.588333],
          [58.082414, 36.599996],
          [58.092141, 36.608335],
          [58.096308, 36.614441],
          [58.102141, 36.628607],
          [58.103248, 36.63139],
          [58.103808, 36.634164],
          [58.105748, 36.645556],
          [58.105474, 36.651112],
          [58.105194, 36.653336],
          [58.090748, 36.701117],
          [58.089641, 36.705277],
          [58.087975, 36.722503],
          [58.088248, 36.723611],
          [58.091581, 36.741664],
          [58.110195, 36.770279],
          [58.110474, 36.771387],
          [58.110748, 36.772782],
          [58.110748, 36.775277],
          [58.102415, 36.788332],
          [58.097975, 36.796111],
          [58.094915, 36.808059],
          [58.094642, 36.808617],
          [58.094915, 36.809166],
          [58.096028, 36.816954],
          [58.096028, 36.821951],
          [58.097415, 36.828335],
          [58.097695, 36.830558],
          [58.097415, 36.856667],
          [58.097695, 36.857504],
          [58.097975, 36.860007],
          [58.098248, 36.864446],
          [58.097975, 36.865283],
          [58.098248, 36.865562],
          [58.098528, 36.867786],
          [58.098808, 36.868893],
          [58.101581, 36.871396],
          [58.102415, 36.871675],
          [58.103808, 36.872224],
          [58.104642, 36.872224],
          [58.105195, 36.872503],
          [58.109361, 36.872503],
          [58.109915, 36.872782],
          [58.112142, 36.872782],
          [58.112415, 36.873061],
          [58.116581, 36.873061],
          [58.121588, 36.873889],
          [58.122975, 36.871117],
          [58.123255, 36.870558],
          [58.130755, 36.864725],
          [58.134361, 36.864725],
          [58.134641, 36.865004],
          [58.154641, 36.875564],
          [58.164921, 36.883342],
          [58.165754, 36.883891],
          [58.185754, 36.895279],
          [58.186587, 36.89528],
          [58.186867, 36.895558],
          [58.187974, 36.895559],
          [58.188534, 36.895838],
          [58.189641, 36.895838],
          [58.190201, 36.896117],
          [58.191307, 36.896117],
          [58.191587, 36.896396],
          [58.196867, 36.896396],
          [58.19742, 36.896675],
          [58.198668, 36.896413],
          [58.199549, 36.895881],
          [58.200754, 36.895838],
          [58.201314, 36.89565],
          [58.204087, 36.894721],
          [58.206034, 36.895279],
          [58.206867, 36.895279],
          [58.207421, 36.895559],
          [58.209367, 36.895559],
          [58.209647, 36.895838],
          [58.211867, 36.895838],
          [58.21242, 36.896117],
          [58.213534, 36.896117],
          [58.21742, 36.896954],
          [58.258813, 36.910835],
          [58.277427, 36.926112],
          [58.27798, 36.927228],
          [58.28076, 36.930001],
          [58.296313, 36.957231],
          [58.296873, 36.959725],
          [58.296873, 36.960283],
          [58.30048, 36.963901],
          [58.301053, 36.964464],
          [58.300206, 36.96806],
          [58.299926, 36.968339],
          [58.299646, 36.978619],
          [58.30048, 36.982507],
          [58.299926, 36.990284],
          [58.299646, 36.992508],
          [58.295206, 36.998899],
          [58.292706, 37.002787],
          [58.286873, 37.007513],
          [58.284927, 37.008341],
          [58.269647, 37.015848],
          [58.25798, 37.018899],
          [58.25576, 37.018899],
          [58.249647, 37.020006],
          [58.247427, 37.022229],
          [58.249094, 37.028062],
          [58.248534, 37.037782],
          [58.248261, 37.038898],
          [58.2477, 37.040843],
          [58.244094, 37.046954],
          [58.226314, 37.051949],
          [58.195474, 37.0614],
          [58.164641, 37.072506],
          [58.160475, 37.07445],
          [58.159015, 37.075131],
          [58.172422, 37.087509],
          [58.174921, 37.090848],
          [58.189641, 37.1064],
          [58.218814, 37.145008],
          [58.219368, 37.146124],
          [58.221034, 37.147788],
          [58.234368, 37.168621],
          [58.236314, 37.174183],
          [58.237148, 37.175568],
          [58.242701, 37.180842],
          [58.242981, 37.181121],
          [58.243503, 37.181121],
          [58.246034, 37.181121],
          [58.246594, 37.181121],
          [58.247148, 37.181121],
          [58.273814, 37.17196],
          [58.27548, 37.171123],
          [58.276874, 37.170844],
          [58.27798, 37.170286],
          [58.291593, 37.167793],
          [58.292147, 37.167514],
          [58.299374, 37.167514],
          [58.299927, 37.167235],
          [58.30104, 37.167235],
          [58.301314, 37.167514],
          [58.302707, 37.167514],
          [58.304927, 37.168063],
          [58.30548, 37.168621],
          [58.306313, 37.1689],
          [58.310207, 37.17501],
          [58.310487, 37.175289],
          [58.31076, 37.177512],
          [58.31076, 37.179456],
          [58.310487, 37.185017],
          [58.31076, 37.185287],
          [58.311594, 37.187789],
          [58.311873, 37.189183],
          [58.312707, 37.190848],
          [58.315487, 37.19335],
          [58.32382, 37.198902],
          [58.324373, 37.19946],
          [58.33826, 37.203068],
          [58.34076, 37.203068],
          [58.34132, 37.203347],
          [58.348266, 37.203347],
          [58.349653, 37.203626],
          [58.352706, 37.203626],
          [58.35382, 37.203347],
          [58.357433, 37.203347],
          [58.357986, 37.203068],
          [58.359373, 37.203068],
          [58.359933, 37.203347],
          [58.362706, 37.203347],
          [58.369653, 37.204454],
          [58.381872, 37.207513],
          [58.382706, 37.207792],
          [58.384653, 37.209736],
          [58.384933, 37.210015],
          [58.386879, 37.210843],
          [58.386879, 37.211122],
          [58.387713, 37.211122],
          [58.389653, 37.211958],
          [58.410766, 37.229739],
          [58.411599, 37.230018],
          [58.414379, 37.232789],
          [58.419099, 37.239736],
          [58.420485, 37.243902],
          [58.422986, 37.2564],
          [58.422713, 37.256679],
          [58.421879, 37.256679],
          [58.403819, 37.267791],
          [58.400486, 37.270013],
          [58.375486, 37.284184],
          [58.3716, 37.286686],
          [58.370767, 37.286956],
          [58.369341, 37.28809],
          [58.327427, 37.32585],
          [58.32354, 37.332517],
          [58.316874, 37.351679],
          [58.316041, 37.355854],
          [58.316041, 37.360856],
          [58.316321, 37.361962],
          [58.317987, 37.367792],
          [58.331874, 37.385577],
          [58.33576, 37.389463],
          [58.349934, 37.401689],
          [58.354934, 37.408355],
          [58.357987, 37.422792],
          [58.357707, 37.426966],
          [58.358267, 37.430295],
          [58.357987, 37.435017],
          [58.358346, 37.436208],
          [58.353023, 37.472923],
          [58.355819, 37.526674],
          [58.369465, 37.556989],
          [58.370913, 37.584675],
          [58.357508, 37.605452],
          [58.332924, 37.631934],
          [58.316041, 37.652802],
          [58.315048, 37.654983],
          [58.311205, 37.662222],
          [58.306389, 37.662222],
          [58.298889, 37.663611],
          [58.288205, 37.666237],
          [58.286489, 37.668119],
          [58.281689, 37.669368],
          [58.280324, 37.670858],
          [58.273254, 37.671792],
          [58.262829, 37.673752],
          [58.257876, 37.675684],
          [58.251672, 37.677248],
          [58.246089, 37.679052],
          [58.242101, 37.680074],
          [58.235703, 37.681714],
          [58.227047, 37.684037],
          [58.226061, 37.692836],
          [58.228974, 37.698716],
          [58.228275, 37.701719],
          [58.229662, 37.703049],
          [58.232577, 37.704333],
          [58.233429, 37.705795],
          [58.23355, 37.707152],
          [58.229322, 37.712968],
          [58.225951, 37.718526],
          [58.22679, 37.720161],
          [58.228102, 37.722586],
          [58.228771, 37.729696],
          [58.227415, 37.73114],
          [58.22537, 37.73516],
          [58.225852, 37.738695],
          [58.218094, 37.739647],
          [58.217061, 37.743182],
          [58.214678, 37.748246],
          [58.21366, 37.751723],
          [58.214034, 37.753582],
          [58.213913, 37.755818],
          [58.21277, 37.759957],
          [58.211462, 37.765438],
          [58.209712, 37.768317],
          [58.208303, 37.771096],
          [58.205405, 37.774369],
          [58.203254, 37.775364],
          [58.19899, 37.775219],
          [58.195412, 37.776874],
          [58.182006, 37.781742],
          [58.177533, 37.781537],
          [58.155062, 37.793617],
          [58.14632, 37.788923],
          [58.142184, 37.784637],
          [58.126145, 37.783166],
          [58.12308, 37.792674],
          [58.12155, 37.795655],
          [58.11617, 37.79592],
          [58.107316, 37.791403],
          [58.102458, 37.787742],
          [58.100123, 37.787866],
          [58.098949, 37.792379],
          [58.09485, 37.801433],
          [58.092019, 37.798635],
          [58.087053, 37.798166],
          [58.086009, 37.799462],
          [58.083326, 37.80306],
          [58.075505, 37.806027],
          [58.072134, 37.806397],
          [58.067539, 37.80492],
          [58.062514, 37.80338],
          [58.046068, 37.803356],
          [58.043605, 37.804887],
          [58.040687, 37.803759],
          [58.036935, 37.803915],
          [58.032503, 37.806189],
          [58.027855, 37.805903],
          [58.024201, 37.814051],
          [58.016779, 37.813806],
          [58.010288, 37.817371],
          [58.007672, 37.816191],
          [57.99893, 37.821326],
          [57.985147, 37.829646],
          [57.973007, 37.837683],
          [57.968219, 37.841604],
          [57.960148, 37.844628],
          [57.952452, 37.840497],
          [57.946365, 37.850796],
          [57.934585, 37.846865],
          [57.931104, 37.854345],
          [57.924029, 37.851289],
          [57.919698, 37.852731],
          [57.916594, 37.862028],
          [57.912493, 37.862327],
          [57.909649, 37.863098],
          [57.904363, 37.862527],
          [57.901414, 37.863743],
          [57.898464, 37.860992],
          [57.893481, 37.861313],
          [57.89049, 37.866024],
          [57.891577, 37.870762],
          [57.876912, 37.872015],
          [57.865964, 37.87268],
          [57.859255, 37.873965],
          [57.853992, 37.872009],
          [57.851143, 37.872308],
          [57.848896, 37.870332],
          [57.830253, 37.863493],
          [57.825474, 37.863247],
          [57.819142, 37.868518],
          [57.810229, 37.87303],
          [57.806551, 37.879742],
          [57.804535, 37.885309],
          [57.806047, 37.89234],
          [57.800997, 37.890982],
          [57.797154, 37.893472],
          [57.795835, 37.899553],
          [57.789125, 37.897975],
          [57.785997, 37.901198],
          [57.781413, 37.898225],
          [57.771854, 37.899318],
          [57.7646, 37.896844],
          [57.759608, 37.897714],
          [57.75414, 37.897495],
          [57.750499, 37.902627],
          [57.745, 37.906667],
          [57.74366, 37.911813],
          [57.738301, 37.915622],
          [57.733137, 37.918422],
          [57.726944, 37.92],
          [57.720368, 37.921856],
          [57.713594, 37.92246],
          [57.707525, 37.921724],
          [57.70058, 37.922138],
          [57.694917, 37.919684],
          [57.693826, 37.918911],
          [57.689698, 37.915987],
          [57.680106, 37.91787],
          [57.67339, 37.918959],
          [57.667876, 37.922073],
          [57.664019, 37.924489],
          [57.657541, 37.92343],
          [57.653063, 37.925617],
          [57.643889, 37.923889],
          [57.639943, 37.924595],
          [57.637182, 37.922834],
          [57.628644, 37.922218],
          [57.622222, 37.923889],
          [57.615294, 37.926618],
          [57.607778, 37.927222],
          [57.601446, 37.929114],
          [57.598178, 37.932064],
          [57.596634, 37.932847],
          [57.59199, 37.932862],
          [57.590874, 37.933448],
          [57.585736, 37.936212],
          [57.579624, 37.938042],
          [57.574248, 37.937588],
          [57.567318, 37.935797],
          [57.562241, 37.933478],
          [57.556265, 37.931969],
          [57.550436, 37.929432],
          [57.542974, 37.924781],
          [57.53406, 37.926586],
          [57.528978, 37.924572],
          [57.52423, 37.921986],
          [57.52203, 37.920028],
          [57.512192, 37.919263],
          [57.508774, 37.9206],
          [57.505794, 37.925137],
          [57.499738, 37.927688],
          [57.498761, 37.932825],
          [57.493851, 37.937113],
          [57.487918, 37.938163],
          [57.480556, 37.939167],
          [57.472778, 37.939167],
          [57.465833, 37.938611],
          [57.457778, 37.937778],
          [57.450278, 37.939167],
          [57.445556, 37.9425],
          [57.439722, 37.945],
          [57.435, 37.948333],
          [57.430278, 37.951389],
          [57.426389, 37.955278],
          [57.421667, 37.958333],
          [57.416944, 37.961667],
          [57.412222, 37.964722],
          [57.4075, 37.968056],
          [57.401667, 37.970556],
          [57.395, 37.9725],
          [57.386389, 37.973333],
          [57.378889, 37.974722],
          [57.375, 37.978611],
          [57.370278, 37.981667],
          [57.3625, 37.983056],
          [57.358813, 37.984899],
          [57.356944, 37.985833],
          [57.353056, 37.989444],
          [57.350278, 37.993889],
          [57.348333, 37.998889],
          [57.350278, 38.005],
          [57.351944, 38.010833],
          [57.353611, 38.016944],
          [57.356111, 38.022222],
          [57.358056, 38.028333],
          [57.359722, 38.034167],
          [57.361389, 38.040278],
          [57.363333, 38.046389],
          [57.365, 38.052222],
          [57.366944, 38.058333],
          [57.369444, 38.063611],
          [57.371111, 38.069722],
          [57.373056, 38.075556],
          [57.373889, 38.0825],
          [57.373056, 38.088056],
          [57.371111, 38.093056],
          [57.367222, 38.096667],
          [57.3625, 38.1],
          [57.357778, 38.103056],
          [57.353056, 38.106389],
          [57.349167, 38.11],
          [57.345556, 38.113889],
          [57.341667, 38.1175],
          [57.339722, 38.1225],
          [57.339722, 38.128889],
          [57.338056, 38.133889],
          [57.335, 38.138056],
          [57.332222, 38.1425],
          [57.328333, 38.146389],
          [57.324722, 38.15],
          [57.318889, 38.152778],
          [57.311389, 38.154167],
          [57.304444, 38.156111],
          [57.299722, 38.159167],
          [57.296375, 38.16153],
          [57.295, 38.1625],
          [57.292222, 38.166944],
          [57.288333, 38.170556],
          [57.2875, 38.176111],
          [57.288611, 38.183056],
          [57.288611, 38.189167],
          [57.288611, 38.195278],
          [57.286667, 38.200278],
          [57.284722, 38.205278],
          [57.281944, 38.209722],
          [57.28, 38.214722],
          [57.278333, 38.219722],
          [57.274444, 38.223333],
          [57.271667, 38.227778],
          [57.266667, 38.230833],
          [57.261944, 38.232778],
          [57.255278, 38.234722],
          [57.251389, 38.238611],
          [57.249444, 38.243611],
          [57.2475, 38.248611],
          [57.245556, 38.253333],
          [57.246667, 38.260278],
          [57.246667, 38.266389],
          [57.243889, 38.270833],
          [57.241673, 38.272133],
          [57.231736, 38.270865],
          [57.226464, 38.27288],
          [57.22282, 38.272649],
          [57.218506, 38.274033],
          [57.218082, 38.279444],
          [57.216285, 38.280455],
          [57.205288, 38.279655],
          [57.199668, 38.277977],
          [57.191678, 38.276843],
          [57.183675, 38.284178],
          [57.176111, 38.280278],
          [57.174833, 38.278209],
          [57.17331, 38.276676],
          [57.161111, 38.272222],
          [57.156944, 38.268333],
          [57.152778, 38.264722],
          [57.149722, 38.26],
          [57.147222, 38.255],
          [57.143889, 38.250278],
          [57.141389, 38.245],
          [57.138056, 38.240556],
          [57.134722, 38.236111],
          [57.13, 38.233056],
          [57.124444, 38.230833],
          [57.118889, 38.228611],
          [57.113333, 38.226389],
          [57.106944, 38.225],
          [57.101111, 38.221944],
          [57.095556, 38.219722],
          [57.090833, 38.216111],
          [57.086667, 38.212222],
          [57.083333, 38.207778],
          [57.080278, 38.203333],
          [57.076111, 38.199444],
          [57.070556, 38.197222],
          [57.067307, 38.195919],
          [57.065, 38.195],
          [57.058611, 38.193611],
          [57.050833, 38.193611],
          [57.044167, 38.195556],
          [57.036389, 38.196944],
          [57.030556, 38.199444],
          [57.025, 38.201944],
          [57.012233, 38.18719],
          [57.010992, 38.189702],
          [57.01016, 38.189802],
          [57.00893, 38.188985],
          [57.007284, 38.188809],
          [57.006435, 38.188861],
          [57.003917, 38.190492],
          [57.000895, 38.195958],
          [56.996649, 38.203531],
          [56.993859, 38.209603],
          [56.991999, 38.214344],
          [56.985225, 38.214531],
          [56.976615, 38.214363],
          [56.97, 38.214444],
          [56.967289, 38.211158],
          [56.962853, 38.211158],
          [56.95317, 38.213369],
          [56.946277, 38.214306],
          [56.942389, 38.214157],
          [56.936785, 38.214606],
          [56.934328, 38.214288],
          [56.930941, 38.216443],
          [56.92489, 38.21479],
          [56.918999, 38.215812],
          [56.909822, 38.214687],
          [56.901701, 38.217829],
          [56.897264, 38.220134],
          [56.885983, 38.21916],
          [56.877182, 38.222551],
          [56.869812, 38.225081],
          [56.862926, 38.22857],
          [56.856938, 38.229772],
          [56.851796, 38.230999],
          [56.840471, 38.2299],
          [56.834516, 38.234067],
          [56.830285, 38.237824],
          [56.825176, 38.239895],
          [56.82101, 38.241275],
          [56.817886, 38.243677],
          [56.814859, 38.245569],
          [56.803658, 38.247554],
          [56.799035, 38.251641],
          [56.792713, 38.251659],
          [56.78905, 38.258202],
          [56.786111, 38.261111],
          [56.780556, 38.264444],
          [56.774486, 38.266375],
          [56.771784, 38.268549],
          [56.766869, 38.269882],
          [56.763698, 38.272951],
          [56.762759, 38.27602],
          [56.763295, 38.278808],
          [56.762, 38.280053],
          [56.760794, 38.283402],
          [56.758069, 38.283735],
          [56.756389, 38.285],
          [56.753936, 38.284594],
          [56.750833, 38.282778],
          [56.744444, 38.281111],
          [56.738889, 38.278889],
          [56.734167, 38.275833],
          [56.727778, 38.274444],
          [56.722222, 38.272222],
          [56.715833, 38.270556],
          [56.709722, 38.269167],
          [56.7025, 38.268333],
          [56.695278, 38.266944],
          [56.687778, 38.268333],
          [56.679167, 38.269167],
          [56.671111, 38.269167],
          [56.664167, 38.268333],
          [56.658611, 38.266111],
          [56.653056, 38.263889],
          [56.648056, 38.260833],
          [56.643333, 38.257778],
          [56.64, 38.253333],
          [56.636111, 38.249444],
          [56.631389, 38.246389],
          [56.625833, 38.244167],
          [56.620278, 38.241944],
          [56.613889, 38.240556],
          [56.605278, 38.241111],
          [56.5975, 38.2425],
          [56.591667, 38.245278],
          [56.586944, 38.248333],
          [56.582222, 38.251389],
          [56.578333, 38.255278],
          [56.5725, 38.257778],
          [56.566944, 38.260278],
          [56.561111, 38.262778],
          [56.554444, 38.264722],
          [56.546667, 38.266111],
          [56.538889, 38.266111],
          [56.5325, 38.264722],
          [56.525278, 38.263889],
          [56.518333, 38.263056],
          [56.511111, 38.2625],
          [56.504167, 38.261667],
          [56.495556, 38.2625],
          [56.486944, 38.263056],
          [56.478889, 38.263056],
          [56.471111, 38.263056],
          [56.464167, 38.262222],
          [56.456944, 38.261667],
          [56.449722, 38.260833],
          [56.442778, 38.26],
          [56.436389, 38.258611],
          [56.430833, 38.256389],
          [56.424444, 38.254722],
          [56.419722, 38.251667],
          [56.414167, 38.249444],
          [56.409444, 38.246389],
          [56.404722, 38.243333],
          [56.399722, 38.240556],
          [56.395, 38.2375],
          [56.390278, 38.234444],
          [56.385556, 38.231389],
          [56.381389, 38.2275],
          [56.376667, 38.224444],
          [56.371944, 38.221667],
          [56.367222, 38.218611],
          [56.363056, 38.214722],
          [56.358333, 38.211667],
          [56.354444, 38.208056],
          [56.349444, 38.205],
          [56.344722, 38.201944],
          [56.34, 38.198889],
          [56.332779, 38.197554],
          [56.331744, 38.195501],
          [56.330556, 38.192778],
          [56.326389, 38.189167],
          [56.330954, 38.185799],
          [56.330883, 38.185708],
          [56.330784, 38.185663],
          [56.330298, 38.185582],
          [56.329482, 38.184973],
          [56.328877, 38.184189],
          [56.328654, 38.183995],
          [56.328161, 38.18381],
          [56.327327, 38.182869],
          [56.326916, 38.182712],
          [56.326552, 38.182375],
          [56.326429, 38.182343],
          [56.326047, 38.182334],
          [56.325924, 38.182264],
          [56.324903, 38.180783],
          [56.324721, 38.180534],
          [56.324274, 38.180391],
          [56.323423, 38.179856],
          [56.323165, 38.179763],
          [56.322666, 38.179791],
          [56.322002, 38.179939],
          [56.321094, 38.180217],
          [56.320629, 38.180326],
          [56.320178, 38.180308],
          [56.318897, 38.179487],
          [56.318521, 38.179072],
          [56.318919, 38.178398],
          [56.319119, 38.177475],
          [56.319101, 38.176137],
          [56.319511, 38.175581],
          [56.319487, 38.17462],
          [56.323468, 38.168508],
          [56.323638, 38.165285],
          [56.326087, 38.160598],
          [56.328018, 38.156507],
          [56.329862, 38.1526],
          [56.328975, 38.151447],
          [56.328234, 38.15132],
          [56.327745, 38.151464],
          [56.327346, 38.151434],
          [56.326969, 38.151057],
          [56.326745, 38.14966],
          [56.327248, 38.147964],
          [56.327622, 38.147312],
          [56.328219, 38.146835],
          [56.329427, 38.146853],
          [56.331453, 38.147205],
          [56.334489, 38.143822],
          [56.335771, 38.142891],
          [56.338698, 38.141744],
          [56.339805, 38.141437],
          [56.34082, 38.141405],
          [56.34173, 38.141553],
          [56.342253, 38.14177],
          [56.342723, 38.142472],
          [56.343192, 38.142855],
          [56.343433, 38.142915],
          [56.343765, 38.142835],
          [56.344868, 38.137157],
          [56.345408, 38.135132],
          [56.344818, 38.131483],
          [56.346147, 38.129387],
          [56.348551, 38.128677],
          [56.349812, 38.126605],
          [56.350963, 38.120381],
          [56.351151, 38.119492],
          [56.351891, 38.118088],
          [56.352079, 38.117164],
          [56.351895, 38.116916],
          [56.350937, 38.116728],
          [56.349831, 38.11661],
          [56.349106, 38.116381],
          [56.348272, 38.116051],
          [56.347984, 38.115759],
          [56.347583, 38.1154],
          [56.347194, 38.115129],
          [56.346491, 38.114835],
          [56.346084, 38.114843],
          [56.345387, 38.114933],
          [56.344976, 38.114804],
          [56.344638, 38.114403],
          [56.344401, 38.113772],
          [56.34431, 38.11274],
          [56.344343, 38.112286],
          [56.34421, 38.11209],
          [56.343679, 38.111894],
          [56.343015, 38.111513],
          [56.342442, 38.110791],
          [56.341908, 38.109904],
          [56.341615, 38.109747],
          [56.341092, 38.109719],
          [56.340582, 38.109636],
          [56.340066, 38.109391],
          [56.339606, 38.108901],
          [56.339315, 38.108344],
          [56.338856, 38.106328],
          [56.338313, 38.10605],
          [56.336949, 38.104943],
          [56.333038, 38.099215],
          [56.334003, 38.091177],
          [56.335248, 38.07725],
          [56.33448, 38.077912],
          [56.327075, 38.080826],
          [56.321063, 38.081017],
          [56.320569, 38.079545],
          [56.320477, 38.07882],
          [56.320213, 38.077767],
          [56.319926, 38.076921],
          [56.319657, 38.076747],
          [56.318742, 38.075819],
          [56.318531, 38.075812],
          [56.318393, 38.075839],
          [56.318311, 38.07589],
          [56.318273, 38.075967],
          [56.318316, 38.077028],
          [56.318122, 38.07746],
          [56.317337, 38.07803],
          [56.316556, 38.078991],
          [56.314971, 38.079957],
          [56.313826, 38.07977],
          [56.311913, 38.079604],
          [56.310598, 38.080066],
          [56.310011, 38.080806],
          [56.308539, 38.081586],
          [56.306606, 38.081822],
          [56.30522, 38.08221],
          [56.302793, 38.083057],
          [56.302173, 38.08282],
          [56.300905, 38.082451],
          [56.299761, 38.082261],
          [56.297494, 38.083061],
          [56.296808, 38.083102],
          [56.295909, 38.082871],
          [56.29503, 38.082268],
          [56.294801, 38.082242],
          [56.294719, 38.082351],
          [56.294573, 38.083067],
          [56.294003, 38.083418],
          [56.29371, 38.083506],
          [56.292048, 38.084028],
          [56.289473, 38.084991],
          [56.288976, 38.084658],
          [56.289289, 38.08412],
          [56.289987, 38.081936],
          [56.29372, 38.075874],
          [56.294, 38.074481],
          [56.295386, 38.053652],
          [56.29511, 38.050039],
          [56.294833, 38.049482],
          [56.295386, 38.04587],
          [56.295943, 38.042536],
          [56.29872, 38.034205],
          [56.304, 38.026423],
          [56.304, 38.025587],
          [56.304556, 38.025039],
          [56.309, 38.007254],
          [56.307902, 38.00029],
          [56.310109, 37.983645],
          [56.309833, 37.979754],
          [56.309556, 37.979205],
          [56.310109, 37.973373],
          [56.311223, 37.966983],
          [56.310943, 37.96559],
          [56.312056, 37.953645],
          [56.313723, 37.949205],
          [56.313999, 37.940037],
          [56.313723, 37.935588],
          [56.313443, 37.931975],
          [56.313337, 37.931188],
          [56.311499, 37.917531],
          [56.311499, 37.91559],
          [56.310389, 37.910314],
          [56.305389, 37.900309],
          [56.297609, 37.892534],
          [56.294833, 37.88253],
          [56.290386, 37.876148],
          [56.280386, 37.867258],
          [56.271496, 37.863644],
          [56.270386, 37.863644],
          [56.26761, 37.862808],
          [56.265663, 37.862808],
          [56.26233, 37.86226],
          [56.255663, 37.86226],
          [56.250107, 37.862808],
          [56.242607, 37.864759],
          [56.241773, 37.865307],
          [56.238997, 37.866422],
          [56.23705, 37.866422],
          [56.236217, 37.8667],
          [56.235107, 37.8667],
          [56.23344, 37.866979],
          [56.22594, 37.866979],
          [56.21566, 37.859482],
          [56.20427, 37.834474],
          [56.198994, 37.820585],
          [56.197884, 37.818922],
          [56.197327, 37.81725],
          [56.195937, 37.814472],
          [56.190937, 37.804196],
          [56.19066, 37.803082],
          [56.187589, 37.796822],
          [56.169271, 37.75947],
          [56.164824, 37.745309],
          [56.154547, 37.719199],
          [56.144687, 37.702881],
          [56.144268, 37.70225],
          [56.138434, 37.687252],
          [56.137048, 37.680302],
          [56.128711, 37.658083],
          [56.127878, 37.656968],
          [56.126768, 37.653641],
          [56.125934, 37.652247],
          [56.118711, 37.634748],
          [56.109268, 37.623363],
          [56.085931, 37.600027],
          [56.082598, 37.596412],
          [56.081765, 37.595863],
          [56.061208, 37.572247],
          [56.061488, 37.570026],
          [56.061208, 37.566968],
          [56.060931, 37.565025],
          [56.060655, 37.564746],
          [56.060931, 37.564189],
          [56.060655, 37.56391],
          [56.060931, 37.562246],
          [56.060655, 37.560025],
          [56.060375, 37.558918],
          [56.060931, 37.553082],
          [56.061208, 37.548081],
          [56.060931, 37.547245],
          [56.061208, 37.534743],
          [56.058432, 37.519193],
          [56.053432, 37.511134],
          [56.051765, 37.509191],
          [56.050052, 37.507478],
          [56.032042, 37.489466],
          [56.031762, 37.488639],
          [56.012541, 37.464524],
          [56.012319, 37.464191],
          [56.011485, 37.462797],
          [56.008985, 37.457805],
          [56.001205, 37.442522],
          [55.997872, 37.422795],
          [55.997039, 37.417524],
          [55.997315, 37.411407],
          [55.997315, 37.410301],
          [55.998429, 37.405578],
          [56.007595, 37.380578],
          [56.008985, 37.379463],
          [56.008985, 37.379184],
          [56.016485, 37.371132],
          [56.016485, 37.370295],
          [56.013985, 37.366409],
          [56.010652, 37.362793],
          [56.004819, 37.359743],
          [56.003985, 37.359464],
          [55.961482, 37.346131],
          [55.958982, 37.345852],
          [55.957592, 37.345295],
          [55.952592, 37.344188],
          [55.951482, 37.34363],
          [55.948982, 37.343072],
          [55.92148, 37.334463],
          [55.92037, 37.334463],
          [55.911479, 37.332798],
          [55.908423, 37.332341],
          [55.908423, 37.331962],
          [55.910923, 37.322524],
          [55.91398, 37.304737],
          [55.914256, 37.301408],
          [55.91537, 37.285015],
          [55.915089, 37.281965],
          [55.914813, 37.278906],
          [55.914256, 37.27196],
          [55.91398, 37.267236],
          [55.912313, 37.25391],
          [55.912036, 37.253631],
          [55.912036, 37.250014],
          [55.912036, 37.243626],
          [55.911756, 37.235573],
          [55.911479, 37.231128],
          [55.911203, 37.228626],
          [55.910923, 37.226961],
          [55.910646, 37.224181],
          [55.910369, 37.220573],
          [55.91009, 37.218629],
          [55.909813, 37.217234],
          [55.908146, 37.210018],
        ],
      ],
    },
  },
  Khuzestan: {
    properties: { value: "Khuzestan", name: "استان خوزستان, ایران", id: 26 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [47.664111, 32.048542],
          [47.664464, 32.048271],
          [47.665077, 32.048078],
          [47.667338, 32.046688],
          [47.667594, 32.046333],
          [47.667517, 32.04614],
          [47.667276, 32.045988],
          [47.666991, 32.045905],
          [47.666902, 32.045808],
          [47.666998, 32.045605],
          [47.667532, 32.044931],
          [47.667551, 32.044721],
          [47.667423, 32.044569],
          [47.66738, 32.044411],
          [47.667518, 32.044129],
          [47.667762, 32.043868],
          [47.668212, 32.043724],
          [47.66957, 32.042334],
          [47.669565, 32.042128],
          [47.669761, 32.041598],
          [47.669694, 32.041393],
          [47.669747, 32.041074],
          [47.670037, 32.040424],
          [47.670587, 32.039622],
          [47.670613, 32.039205],
          [47.670555, 32.038752],
          [47.67042, 32.038343],
          [47.670412, 32.037983],
          [47.670334, 32.037647],
          [47.67011, 32.036903],
          [47.67015, 32.036369],
          [47.669981, 32.035974],
          [47.670169, 32.035635],
          [47.669869, 32.035447],
          [47.669831, 32.035335],
          [47.670546, 32.034056],
          [47.670884, 32.033342],
          [47.670959, 32.032553],
          [47.671182, 32.032006],
          [47.671513, 32.031977],
          [47.671764, 32.031746],
          [47.671981, 32.031665],
          [47.672306, 32.031709],
          [47.672569, 32.031992],
          [47.67299, 32.032112],
          [47.673517, 32.03216],
          [47.675086, 32.032495],
          [47.675451, 32.032408],
          [47.675919, 32.031952],
          [47.676354, 32.031894],
          [47.677205, 32.031328],
          [47.677595, 32.031236],
          [47.677829, 32.03108],
          [47.678127, 32.030789],
          [47.678499, 32.030681],
          [47.678764, 32.030736],
          [47.678901, 32.030917],
          [47.679066, 32.031021],
          [47.679482, 32.031121],
          [47.679814, 32.031144],
          [47.680057, 32.031306],
          [47.680191, 32.031478],
          [47.680451, 32.031513],
          [47.680782, 32.031484],
          [47.681096, 32.031345],
          [47.682648, 32.031291],
          [47.68344, 32.030974],
          [47.684042, 32.030635],
          [47.684756, 32.030372],
          [47.686398, 32.030396],
          [47.686487, 32.030317],
          [47.686833, 32.030254],
          [47.687062, 32.030264],
          [47.687363, 32.030127],
          [47.687603, 32.029898],
          [47.687723, 32.029598],
          [47.687701, 32.029282],
          [47.687618, 32.029112],
          [47.687354, 32.028922],
          [47.687359, 32.028798],
          [47.688073, 32.028142],
          [47.68816, 32.027951],
          [47.688173, 32.027763],
          [47.688318, 32.027603],
          [47.688324, 32.027488],
          [47.688111, 32.027393],
          [47.688008, 32.027288],
          [47.688011, 32.027112],
          [47.688807, 32.026364],
          [47.688902, 32.026202],
          [47.688839, 32.025848],
          [47.688609, 32.025621],
          [47.688411, 32.025538],
          [47.688013, 32.025574],
          [47.687174, 32.025742],
          [47.686664, 32.025896],
          [47.686429, 32.026022],
          [47.686147, 32.026049],
          [47.685921, 32.025974],
          [47.685621, 32.025748],
          [47.685546, 32.025522],
          [47.685621, 32.025335],
          [47.685959, 32.025184],
          [47.686493, 32.024859],
          [47.686619, 32.024697],
          [47.686722, 32.024472],
          [47.686688, 32.02418],
          [47.686568, 32.023969],
          [47.685206, 32.023164],
          [47.68497, 32.022959],
          [47.68484, 32.022802],
          [47.684405, 32.021973],
          [47.683858, 32.021474],
          [47.683316, 32.020876],
          [47.683114, 32.020428],
          [47.68307, 32.019487],
          [47.682984, 32.019214],
          [47.682729, 32.018821],
          [47.682665, 32.018632],
          [47.682652, 32.018418],
          [47.682828, 32.018185],
          [47.683147, 32.018044],
          [47.6836, 32.018003],
          [47.684218, 32.017876],
          [47.68515, 32.017939],
          [47.685475, 32.017911],
          [47.68629, 32.017516],
          [47.687292, 32.017139],
          [47.687776, 32.01686],
          [47.688191, 32.016517],
          [47.688821, 32.015779],
          [47.689026, 32.015443],
          [47.689848, 32.013736],
          [47.690072, 32.013402],
          [47.690236, 32.013243],
          [47.690799, 32.013167],
          [47.691144, 32.013264],
          [47.691352, 32.013407],
          [47.691581, 32.01384],
          [47.691773, 32.014512],
          [47.691892, 32.014676],
          [47.692038, 32.014778],
          [47.692215, 32.014875],
          [47.692558, 32.014942],
          [47.694595, 32.015263],
          [47.694981, 32.015389],
          [47.69529, 32.015559],
          [47.695634, 32.015765],
          [47.695849, 32.016004],
          [47.696094, 32.016331],
          [47.696243, 32.016836],
          [47.696223, 32.017412],
          [47.696049, 32.018048],
          [47.696076, 32.018265],
          [47.696869, 32.019137],
          [47.69708, 32.019476],
          [47.69724, 32.019906],
          [47.697323, 32.02019],
          [47.697292, 32.020474],
          [47.697432, 32.021202],
          [47.697475, 32.022057],
          [47.697583, 32.022228],
          [47.69788, 32.022375],
          [47.698846, 32.022653],
          [47.699067, 32.022845],
          [47.699423, 32.02293],
          [47.699799, 32.022954],
          [47.700117, 32.022854],
          [47.700574, 32.02257],
          [47.700864, 32.022321],
          [47.701141, 32.021997],
          [47.701498, 32.021715],
          [47.70183, 32.021623],
          [47.702807, 32.021569],
          [47.703443, 32.021251],
          [47.703802, 32.021139],
          [47.704433, 32.021039],
          [47.706477, 32.020966],
          [47.708098, 32.020788],
          [47.708956, 32.020668],
          [47.70999, 32.02036],
          [47.711699, 32.019507],
          [47.712108, 32.01921],
          [47.712418, 32.018895],
          [47.712962, 32.018073],
          [47.713107, 32.017355],
          [47.712958, 32.016639],
          [47.712598, 32.016142],
          [47.712499, 32.015845],
          [47.712438, 32.015526],
          [47.7124, 32.01464],
          [47.712294, 32.014504],
          [47.712015, 32.014307],
          [47.71121, 32.013881],
          [47.710376, 32.013672],
          [47.710006, 32.013673],
          [47.709446, 32.013734],
          [47.709002, 32.013949],
          [47.708723, 32.014403],
          [47.708623, 32.014756],
          [47.708592, 32.015128],
          [47.70885, 32.015944],
          [47.70912, 32.016596],
          [47.709112, 32.016778],
          [47.708996, 32.01714],
          [47.708871, 32.017395],
          [47.70868, 32.017616],
          [47.708486, 32.017734],
          [47.707739, 32.017803],
          [47.707466, 32.01777],
          [47.706822, 32.017217],
          [47.706653, 32.017103],
          [47.706508, 32.017078],
          [47.706333, 32.017079],
          [47.705894, 32.017361],
          [47.70553, 32.017474],
          [47.705126, 32.017583],
          [47.704983, 32.017542],
          [47.704671, 32.017104],
          [47.704434, 32.016321],
          [47.703962, 32.015592],
          [47.703663, 32.015321],
          [47.703338, 32.015117],
          [47.702446, 32.014785],
          [47.701822, 32.014377],
          [47.701399, 32.013928],
          [47.701235, 32.013596],
          [47.701156, 32.013306],
          [47.701131, 32.012908],
          [47.701385, 32.011943],
          [47.701608, 32.011608],
          [47.701815, 32.0114],
          [47.702086, 32.011216],
          [47.702425, 32.011056],
          [47.702814, 32.010939],
          [47.703168, 32.0109],
          [47.703562, 32.010921],
          [47.70409, 32.011088],
          [47.704551, 32.011448],
          [47.705171, 32.011561],
          [47.705512, 32.011566],
          [47.705684, 32.011509],
          [47.705982, 32.011264],
          [47.707145, 32.010179],
          [47.707741, 32.009575],
          [47.70794, 32.009291],
          [47.708284, 32.00833],
          [47.708325, 32.00795],
          [47.708295, 32.007605],
          [47.708202, 32.007281],
          [47.708007, 32.006956],
          [47.707745, 32.00673],
          [47.706952, 32.006136],
          [47.706175, 32.005721],
          [47.706006, 32.005535],
          [47.705963, 32.005377],
          [47.706032, 32.005143],
          [47.706279, 32.004969],
          [47.706496, 32.004888],
          [47.706694, 32.00497],
          [47.706881, 32.004988],
          [47.70703, 32.004914],
          [47.70716, 32.004653],
          [47.707533, 32.004198],
          [47.708458, 32.003394],
          [47.709292, 32.002901],
          [47.710389, 32.002479],
          [47.71161, 32.002287],
          [47.712211, 32.002141],
          [47.712732, 32.001958],
          [47.71335, 32.001665],
          [47.713998, 32.001139],
          [47.71451, 32.000106],
          [47.714608, 31.999794],
          [47.714601, 31.999444],
          [47.714471, 31.999122],
          [47.714007, 31.998473],
          [47.71332, 31.998181],
          [47.712886, 31.998131],
          [47.712633, 31.998193],
          [47.712366, 31.998319],
          [47.712237, 31.998446],
          [47.712176, 31.998663],
          [47.712194, 31.998892],
          [47.712412, 31.999327],
          [47.712383, 31.999524],
          [47.712178, 31.999902],
          [47.710969, 32.000143],
          [47.710686, 32.000138],
          [47.710003, 31.999839],
          [47.709663, 31.999535],
          [47.709271, 31.999266],
          [47.709134, 31.999084],
          [47.708995, 31.998521],
          [47.708953, 31.99762],
          [47.70885, 31.997448],
          [47.708505, 31.99717],
          [47.707883, 31.996794],
          [47.707114, 31.996242],
          [47.70613, 31.995828],
          [47.705707, 31.995539],
          [47.705396, 31.994873],
          [47.705359, 31.994575],
          [47.705397, 31.994321],
          [47.70559, 31.99413],
          [47.706569, 31.994014],
          [47.706819, 31.993824],
          [47.707534, 31.992635],
          [47.707803, 31.991564],
          [47.707956, 31.9912],
          [47.708193, 31.990894],
          [47.708468, 31.990751],
          [47.708737, 31.990722],
          [47.709069, 31.99077],
          [47.709253, 31.990891],
          [47.709833, 31.991508],
          [47.710038, 31.991641],
          [47.710287, 31.991771],
          [47.710834, 31.991754],
          [47.71132, 31.991975],
          [47.711919, 31.992495],
          [47.712191, 31.992667],
          [47.712434, 31.992757],
          [47.712794, 31.992882],
          [47.71316, 31.992888],
          [47.713634, 31.992807],
          [47.71392, 31.992661],
          [47.714125, 31.992464],
          [47.714229, 31.99227],
          [47.714205, 31.992109],
          [47.713928, 31.991803],
          [47.713865, 31.991639],
          [47.713921, 31.991407],
          [47.714746, 31.990545],
          [47.715047, 31.989861],
          [47.715188, 31.989309],
          [47.715417, 31.988764],
          [47.716066, 31.987498],
          [47.716583, 31.986904],
          [47.7169, 31.986651],
          [47.717107, 31.986544],
          [47.717379, 31.986509],
          [47.717542, 31.986442],
          [47.717659, 31.986296],
          [47.717693, 31.98601],
          [47.717653, 31.985456],
          [47.71775, 31.984175],
          [47.717669, 31.983776],
          [47.717174, 31.982641],
          [47.71641, 31.981573],
          [47.714244, 31.97946],
          [47.713967, 31.979128],
          [47.713596, 31.97851],
          [47.712338, 31.975966],
          [47.711993, 31.974024],
          [47.711476, 31.971825],
          [47.711473, 31.971073],
          [47.711573, 31.970599],
          [47.711769, 31.970159],
          [47.712018, 31.969902],
          [47.712855, 31.969461],
          [47.713141, 31.96922],
          [47.71341, 31.96891],
          [47.714221, 31.9676],
          [47.714505, 31.967271],
          [47.715289, 31.966592],
          [47.717023, 31.965339],
          [47.717441, 31.965146],
          [47.719307, 31.964705],
          [47.720922, 31.964461],
          [47.72258, 31.964386],
          [47.723023, 31.964275],
          [47.724413, 31.963708],
          [47.724656, 31.963407],
          [47.72475, 31.963185],
          [47.725093, 31.962962],
          [47.726179, 31.960416],
          [47.726274, 31.959666],
          [47.726917, 31.957656],
          [47.727459, 31.956198],
          [47.728622, 31.953977],
          [47.728771, 31.953076],
          [47.728833, 31.951229],
          [47.728739, 31.948037],
          [47.729796, 31.946169],
          [47.730693, 31.94537],
          [47.730855, 31.944924],
          [47.73132, 31.944124],
          [47.731422, 31.943524],
          [47.731192, 31.942959],
          [47.731025, 31.942245],
          [47.730813, 31.941831],
          [47.730748, 31.941391],
          [47.731181, 31.941201],
          [47.731391, 31.941001],
          [47.731374, 31.940091],
          [47.731546, 31.939555],
          [47.731711, 31.939258],
          [47.732178, 31.9392],
          [47.732481, 31.93934],
          [47.732768, 31.939368],
          [47.733567, 31.938857],
          [47.734098, 31.938656],
          [47.734525, 31.93855],
          [47.734654, 31.938424],
          [47.734757, 31.938015],
          [47.735128, 31.937606],
          [47.735195, 31.937424],
          [47.73518, 31.937151],
          [47.734944, 31.936301],
          [47.734995, 31.936078],
          [47.735938, 31.934685],
          [47.736174, 31.934507],
          [47.736564, 31.934358],
          [47.736916, 31.934457],
          [47.737175, 31.934319],
          [47.738003, 31.933682],
          [47.738671, 31.933287],
          [47.73887, 31.932857],
          [47.739011, 31.932729],
          [47.73953, 31.932431],
          [47.740738, 31.931317],
          [47.740898, 31.93108],
          [47.741105, 31.930473],
          [47.741598, 31.93007],
          [47.741671, 31.929777],
          [47.741738, 31.929699],
          [47.742148, 31.929711],
          [47.742737, 31.929396],
          [47.743771, 31.928598],
          [47.74423, 31.927986],
          [47.74442, 31.92781],
          [47.744713, 31.927677],
          [47.745529, 31.926438],
          [47.745683, 31.926016],
          [47.746587, 31.924547],
          [47.747234, 31.924095],
          [47.747461, 31.923858],
          [47.747947, 31.922928],
          [47.748086, 31.922789],
          [47.748178, 31.922521],
          [47.749287, 31.92083],
          [47.749798, 31.919868],
          [47.750483, 31.918931],
          [47.750734, 31.918817],
          [47.751168, 31.918814],
          [47.751418, 31.918667],
          [47.751629, 31.918367],
          [47.752273, 31.91819],
          [47.752555, 31.917988],
          [47.753152, 31.917836],
          [47.753354, 31.917659],
          [47.753459, 31.917492],
          [47.753519, 31.917174],
          [47.753682, 31.916871],
          [47.754284, 31.916399],
          [47.754537, 31.91563],
          [47.754728, 31.91546],
          [47.754994, 31.915376],
          [47.755307, 31.915449],
          [47.755422, 31.915616],
          [47.755747, 31.915632],
          [47.75613, 31.915561],
          [47.756565, 31.915404],
          [47.756802, 31.915182],
          [47.756923, 31.914947],
          [47.757076, 31.914838],
          [47.757437, 31.914732],
          [47.757691, 31.914601],
          [47.758073, 31.914183],
          [47.758105, 31.913996],
          [47.758047, 31.913605],
          [47.758118, 31.913439],
          [47.758275, 31.913318],
          [47.759035, 31.913232],
          [47.759209, 31.913131],
          [47.759106, 31.912379],
          [47.759125, 31.912216],
          [47.759232, 31.912137],
          [47.759879, 31.912054],
          [47.760632, 31.911864],
          [47.760989, 31.911952],
          [47.761798, 31.912324],
          [47.761948, 31.912524],
          [47.762035, 31.912998],
          [47.762162, 31.913103],
          [47.762423, 31.91313],
          [47.763409, 31.912724],
          [47.76385, 31.912593],
          [47.764641, 31.912656],
          [47.765073, 31.91262],
          [47.766356, 31.912279],
          [47.767853, 31.91199],
          [47.768479, 31.911772],
          [47.769453, 31.911265],
          [47.769904, 31.911094],
          [47.770164, 31.910934],
          [47.77039, 31.910609],
          [47.770841, 31.909674],
          [47.771627, 31.908979],
          [47.771888, 31.908874],
          [47.772283, 31.9088],
          [47.773171, 31.908797],
          [47.774181, 31.908722],
          [47.775373, 31.90826],
          [47.775965, 31.908092],
          [47.776318, 31.908059],
          [47.777201, 31.908221],
          [47.777932, 31.908656],
          [47.778284, 31.908646],
          [47.778796, 31.908558],
          [47.78096, 31.90789],
          [47.781573, 31.90747],
          [47.783335, 31.905786],
          [47.783334, 31.896723],
          [47.784644, 31.896499],
          [47.785434, 31.89586],
          [47.785546, 31.895484],
          [47.785622, 31.894957],
          [47.785283, 31.893717],
          [47.785246, 31.892927],
          [47.785338, 31.89227],
          [47.785648, 31.892001],
          [47.785727, 31.891839],
          [47.785619, 31.891467],
          [47.78582, 31.890652],
          [47.785945, 31.89051],
          [47.786414, 31.890303],
          [47.786789, 31.889887],
          [47.786925, 31.889633],
          [47.787076, 31.888963],
          [47.787212, 31.888682],
          [47.787652, 31.888255],
          [47.787926, 31.888181],
          [47.788119, 31.888077],
          [47.788761, 31.88743],
          [47.789073, 31.887294],
          [47.789585, 31.887267],
          [47.789893, 31.887171],
          [47.790356, 31.88713],
          [47.790606, 31.886961],
          [47.791153, 31.886758],
          [47.791566, 31.886496],
          [47.791977, 31.886354],
          [47.792846, 31.886266],
          [47.793125, 31.886158],
          [47.793779, 31.885635],
          [47.794658, 31.885271],
          [47.795019, 31.885198],
          [47.795242, 31.885264],
          [47.795411, 31.885405],
          [47.795647, 31.885469],
          [47.7962, 31.885341],
          [47.796543, 31.885327],
          [47.796911, 31.885335],
          [47.797318, 31.885446],
          [47.797522, 31.885411],
          [47.797656, 31.885202],
          [47.797991, 31.885134],
          [47.798255, 31.885176],
          [47.798747, 31.88512],
          [47.799275, 31.885314],
          [47.800041, 31.885369],
          [47.800561, 31.885214],
          [47.801173, 31.885241],
          [47.801498, 31.885186],
          [47.802133, 31.885219],
          [47.802526, 31.885327],
          [47.802722, 31.885288],
          [47.803598, 31.884908],
          [47.804083, 31.884535],
          [47.804454, 31.884346],
          [47.805558, 31.883576],
          [47.805741, 31.88343],
          [47.806205, 31.882661],
          [47.806386, 31.882482],
          [47.806594, 31.882381],
          [47.80705, 31.882292],
          [47.807632, 31.882356],
          [47.80792, 31.882335],
          [47.808117, 31.882273],
          [47.808832, 31.881861],
          [47.809742, 31.881486],
          [47.80986, 31.881373],
          [47.810224, 31.880681],
          [47.810311, 31.880365],
          [47.810303, 31.880016],
          [47.810209, 31.879698],
          [47.810051, 31.879467],
          [47.809813, 31.879289],
          [47.809384, 31.879121],
          [47.808588, 31.878945],
          [47.808441, 31.878839],
          [47.808285, 31.87851],
          [47.808276, 31.878292],
          [47.808457, 31.877823],
          [47.808422, 31.877418],
          [47.80794, 31.876778],
          [47.807923, 31.876551],
          [47.808175, 31.875785],
          [47.808416, 31.875393],
          [47.808662, 31.874814],
          [47.808864, 31.874506],
          [47.808869, 31.874368],
          [47.80881, 31.874241],
          [47.808639, 31.874194],
          [47.807738, 31.874342],
          [47.807459, 31.874264],
          [47.806938, 31.873943],
          [47.806029, 31.873534],
          [47.805955, 31.873399],
          [47.805956, 31.87319],
          [47.806139, 31.872677],
          [47.806143, 31.872485],
          [47.806077, 31.872326],
          [47.805929, 31.872187],
          [47.805627, 31.87202],
          [47.805536, 31.871899],
          [47.805589, 31.871632],
          [47.80555, 31.87148],
          [47.805389, 31.871261],
          [47.805183, 31.871105],
          [47.804787, 31.870909],
          [47.804606, 31.870743],
          [47.804464, 31.870527],
          [47.804313, 31.869873],
          [47.804265, 31.86929],
          [47.804194, 31.869089],
          [47.803923, 31.868779],
          [47.803757, 31.868703],
          [47.803614, 31.868717],
          [47.803374, 31.868846],
          [47.80302, 31.868895],
          [47.802803, 31.86885],
          [47.802568, 31.868633],
          [47.801805, 31.868484],
          [47.80108, 31.868143],
          [47.799872, 31.868158],
          [47.799671, 31.868044],
          [47.799616, 31.867906],
          [47.799621, 31.867741],
          [47.79981, 31.867171],
          [47.79995, 31.867016],
          [47.800045, 31.866786],
          [47.800023, 31.866593],
          [47.79944, 31.865499],
          [47.799058, 31.864485],
          [47.799079, 31.864043],
          [47.799368, 31.863288],
          [47.79949, 31.863036],
          [47.799624, 31.862931],
          [47.799684, 31.862773],
          [47.799667, 31.86242],
          [47.799499, 31.861989],
          [47.799347, 31.861862],
          [47.799009, 31.860736],
          [47.79896, 31.860383],
          [47.799017, 31.860056],
          [47.799259, 31.859538],
          [47.799212, 31.859327],
          [47.799108, 31.859098],
          [47.798719, 31.858771],
          [47.798654, 31.858645],
          [47.798666, 31.858457],
          [47.799056, 31.857787],
          [47.799335, 31.857099],
          [47.799557, 31.856738],
          [47.79962, 31.856278],
          [47.799784, 31.856086],
          [47.800121, 31.855836],
          [47.800486, 31.855281],
          [47.800993, 31.854756],
          [47.801271, 31.854566],
          [47.801849, 31.854325],
          [47.802011, 31.854177],
          [47.802134, 31.853953],
          [47.802104, 31.853674],
          [47.802028, 31.853506],
          [47.801592, 31.853175],
          [47.801409, 31.852982],
          [47.80136, 31.852837],
          [47.801385, 31.852619],
          [47.801524, 31.852381],
          [47.801821, 31.852161],
          [47.802139, 31.851997],
          [47.802339, 31.851788],
          [47.802423, 31.851538],
          [47.802315, 31.851113],
          [47.802426, 31.850946],
          [47.802849, 31.850775],
          [47.803194, 31.850535],
          [47.803608, 31.850147],
          [47.804017, 31.849868],
          [47.80431, 31.849501],
          [47.804986, 31.848805],
          [47.805101, 31.848571],
          [47.805, 31.847882],
          [47.805172, 31.846905],
          [47.80504, 31.846621],
          [47.804867, 31.846486],
          [47.804829, 31.845967],
          [47.804956, 31.845759],
          [47.805232, 31.845559],
          [47.805358, 31.8454],
          [47.805351, 31.845029],
          [47.805429, 31.844813],
          [47.805615, 31.844573],
          [47.806028, 31.844179],
          [47.80555, 31.843631],
          [47.805337, 31.842578],
          [47.805441, 31.842412],
          [47.805678, 31.84224],
          [47.806318, 31.842142],
          [47.806635, 31.842049],
          [47.807301, 31.842105],
          [47.807513, 31.842047],
          [47.807807, 31.842106],
          [47.808611, 31.842073],
          [47.808908, 31.842197],
          [47.80915, 31.842232],
          [47.809666, 31.842221],
          [47.810771, 31.841955],
          [47.812406, 31.841845],
          [47.812783, 31.841781],
          [47.813152, 31.841608],
          [47.813832, 31.840928],
          [47.814042, 31.840601],
          [47.814296, 31.840339],
          [47.814767, 31.840072],
          [47.815176, 31.839581],
          [47.815899, 31.839101],
          [47.816613, 31.838447],
          [47.816887, 31.838401],
          [47.81743, 31.838439],
          [47.817538, 31.838552],
          [47.817561, 31.838904],
          [47.818008, 31.83949],
          [47.818161, 31.839767],
          [47.818886, 31.840123],
          [47.820027, 31.840179],
          [47.821045, 31.839965],
          [47.822015, 31.838923],
          [47.822481, 31.838306],
          [47.823075, 31.837687],
          [47.823607, 31.836925],
          [47.824122, 31.836339],
          [47.824419, 31.835606],
          [47.824538, 31.834877],
          [47.824615, 31.833908],
          [47.824948, 31.833016],
          [47.825222, 31.832504],
          [47.843989, 31.822082],
          [47.844525, 31.821891],
          [47.845302, 31.821377],
          [47.845392, 31.821214],
          [47.846293, 31.820961],
          [47.846694, 31.820782],
          [47.848556, 31.820145],
          [47.849628, 31.819725],
          [47.850297, 31.819637],
          [47.850516, 31.819501],
          [47.850706, 31.818937],
          [47.850977, 31.818617],
          [47.851403, 31.818483],
          [47.852366, 31.818373],
          [47.852568, 31.818223],
          [47.852865, 31.817345],
          [47.853084, 31.816896],
          [47.853237, 31.816344],
          [47.853647, 31.815836],
          [47.853815, 31.815686],
          [47.854137, 31.81551],
          [47.854399, 31.815022],
          [47.854614, 31.814793],
          [47.854718, 31.814573],
          [47.854954, 31.814351],
          [47.855132, 31.814003],
          [47.855497, 31.813869],
          [47.855645, 31.81369],
          [47.856029, 31.813027],
          [47.856069, 31.812763],
          [47.856002, 31.812369],
          [47.85614, 31.812176],
          [47.856187, 31.811943],
          [47.856094, 31.81134],
          [47.856123, 31.804563],
          [47.865855, 31.783613],
          [47.850052, 31.75],
          [47.818904, 31.6838],
          [47.784567, 31.610765],
          [47.783387, 31.608255],
          [47.749544, 31.536216],
          [47.716555, 31.466072],
          [47.70567, 31.442915],
          [47.682971, 31.394609],
          [47.682884, 31.320316],
          [47.682796, 31.244928],
          [47.683117, 31.21384],
          [47.683461, 31.195778],
          [47.682673, 31.167887],
          [47.682892, 31.080364],
          [47.682796, 31.053418],
          [47.687388, 31.002957],
          [47.696903, 30.998257],
          [47.731319, 30.997498],
          [47.776831, 30.997381],
          [47.869054, 30.997145],
          [48.005956, 30.996795],
          [48.02952, 30.99644],
          [48.031293, 30.996063],
          [48.03219, 30.995374],
          [48.03241, 30.994251],
          [48.032312, 30.986349],
          [48.03082, 30.865574],
          [48.029322, 30.744268],
          [48.027824, 30.622722],
          [48.026328, 30.501178],
          [48.026038, 30.492183],
          [48.026369, 30.485403],
          [48.026433, 30.482476],
          [48.024415, 30.477347],
          [48.026399, 30.477355],
          [48.028842, 30.477123],
          [48.030187, 30.476851],
          [48.034489, 30.476035],
          [48.036663, 30.475662],
          [48.038564, 30.475506],
          [48.04165, 30.474499],
          [48.047893, 30.471939],
          [48.053762, 30.468997],
          [48.057784, 30.467123],
          [48.062202, 30.465218],
          [48.069676, 30.462739],
          [48.078231, 30.460197],
          [48.085344, 30.458389],
          [48.09446, 30.45675],
          [48.099876, 30.455617],
          [48.104759, 30.454687],
          [48.10725, 30.454066],
          [48.10857, 30.453317],
          [48.108957, 30.452291],
          [48.108732, 30.450584],
          [48.114395, 30.449806],
          [48.119951, 30.448638],
          [48.125786, 30.4469],
          [48.131389, 30.445],
          [48.135833, 30.442222],
          [48.141389, 30.44],
          [48.146111, 30.436944],
          [48.151161, 30.43512],
          [48.157943, 30.430274],
          [48.161437, 30.427578],
          [48.163889, 30.424722],
          [48.166944, 30.420556],
          [48.17, 30.416389],
          [48.173889, 30.412778],
          [48.176944, 30.408333],
          [48.18, 30.404167],
          [48.182222, 30.399444],
          [48.183333, 30.393889],
          [48.184722, 30.388333],
          [48.186111, 30.382778],
          [48.186667, 30.376389],
          [48.187222, 30.370278],
          [48.187492, 30.369198],
          [48.188611, 30.364722],
          [48.189167, 30.358333],
          [48.188889, 30.351667],
          [48.190278, 30.346111],
          [48.191667, 30.340556],
          [48.193889, 30.335556],
          [48.196111, 30.330556],
          [48.198889, 30.326389],
          [48.202733, 30.320577],
          [48.208432, 30.315768],
          [48.216518, 30.315717],
          [48.22268, 30.318443],
          [48.227495, 30.321636],
          [48.233349, 30.327082],
          [48.236667, 30.331389],
          [48.240696, 30.334942],
          [48.246121, 30.338127],
          [48.249304, 30.339134],
          [48.252352, 30.340098],
          [48.260432, 30.339231],
          [48.2675, 30.338611],
          [48.272733, 30.336087],
          [48.277726, 30.332517],
          [48.279955, 30.329918],
          [48.281338, 30.328449],
          [48.28308, 30.326598],
          [48.287303, 30.32274],
          [48.29132, 30.318503],
          [48.295261, 30.31447],
          [48.298312, 30.310987],
          [48.301464, 30.307123],
          [48.305001, 30.303487],
          [48.308583, 30.29992],
          [48.313192, 30.295787],
          [48.316687, 30.292744],
          [48.320604, 30.288271],
          [48.324161, 30.284785],
          [48.327152, 30.281667],
          [48.331271, 30.277462],
          [48.333957, 30.273321],
          [48.337505, 30.269647],
          [48.340317, 30.265789],
          [48.344295, 30.261704],
          [48.349351, 30.258364],
          [48.352124, 30.255018],
          [48.356268, 30.250545],
          [48.358038, 30.247943],
          [48.363257, 30.243649],
          [48.366768, 30.240702],
          [48.371073, 30.23732],
          [48.378611, 30.235],
          [48.3825, 30.231667],
          [48.386389, 30.228056],
          [48.391111, 30.225],
          [48.395556, 30.222222],
          [48.399444, 30.218611],
          [48.404167, 30.215],
          [48.408056, 30.211389],
          [48.410833, 30.207222],
          [48.413056, 30.202222],
          [48.415796, 30.196427],
          [48.416232, 30.189628],
          [48.417346, 30.18392],
          [48.417526, 30.180043],
          [48.417666, 30.177019],
          [48.415957, 30.170565],
          [48.41344, 30.1645],
          [48.409478, 30.159389],
          [48.405867, 30.155319],
          [48.402421, 30.152208],
          [48.398255, 30.148291],
          [48.395033, 30.145388],
          [48.391409, 30.141347],
          [48.387778, 30.136944],
          [48.384669, 30.133918],
          [48.383332, 30.128322],
          [48.38275, 30.123195],
          [48.388505, 30.112026],
          [48.39623, 30.106413],
          [48.403605, 30.101343],
          [48.409583, 30.09837],
          [48.421151, 30.091305],
          [48.42504, 30.087887],
          [48.428769, 30.0845],
          [48.4325, 30.080833],
          [48.435278, 30.076667],
          [48.438333, 30.0725],
          [48.440556, 30.0675],
          [48.444132, 30.060711],
          [48.445239, 30.056105],
          [48.446896, 30.053996],
          [48.446994, 30.053528],
          [48.447649, 30.050388],
          [48.447849, 30.049431],
          [48.447988, 30.048767],
          [48.448184, 30.047829],
          [48.448209, 30.047151],
          [48.448355, 30.043232],
          [48.44842, 30.041514],
          [48.448383, 30.039884],
          [48.44833, 30.037506],
          [48.448259, 30.034359],
          [48.448212, 30.033527],
          [48.448077, 30.031135],
          [48.447974, 30.029301],
          [48.44786, 30.027272],
          [48.447572, 30.022172],
          [48.447679, 30.019741],
          [48.447926, 30.014093],
          [48.44857, 30.009262],
          [48.449879, 30.005026],
          [48.450985, 30.003052],
          [48.45166, 30.001848],
          [48.452884, 29.999999],
          [48.453419, 29.999191],
          [48.455011, 29.99742],
          [48.456059, 29.996255],
          [48.459733, 29.99347],
          [48.461995, 29.991756],
          [48.462925, 29.991051],
          [48.468515, 29.987684],
          [48.474167, 29.984586],
          [48.477321, 29.982857],
          [48.478742, 29.982078],
          [48.482076, 29.98025],
          [48.483271, 29.979535],
          [48.485049, 29.978472],
          [48.487769, 29.976846],
          [48.494264, 29.972963],
          [48.502271, 29.968092],
          [48.506101, 29.965762],
          [48.510239, 29.963245],
          [48.513666, 29.961895],
          [48.517265, 29.960479],
          [48.525355, 29.957649],
          [48.540269, 29.953035],
          [48.549456, 29.951057],
          [48.559807, 29.948828],
          [48.568883, 29.947238],
          [48.572745, 29.946643],
          [48.574848, 29.946382],
          [48.57992, 29.945403],
          [48.582358, 29.944932],
          [48.583639, 29.944523],
          [48.586435, 29.94363],
          [48.590026, 29.942499],
          [48.593516, 29.941399],
          [48.597082, 29.939806],
          [48.600003, 29.9385],
          [48.603001, 29.93716],
          [48.603773, 29.936676],
          [48.605336, 29.93607],
          [48.615025, 29.932315],
          [48.634193, 29.91915],
          [48.656213, 29.899765],
          [48.673171, 29.883029],
          [48.68168, 29.874631],
          [48.71644, 29.854441],
          [48.742347, 29.844879],
          [48.772265, 29.831997],
          [48.797419, 29.82109],
          [48.826035, 29.811784],
          [48.839029, 29.798037],
          [48.84127, 29.785771],
          [48.854465, 29.747704],
          [49.020384, 29.759099],
          [49.170672, 29.769419],
          [49.464301, 29.789578],
          [49.580143, 29.667443],
          [50.113946, 30.1521],
          [50.115551, 30.153554],
          [50.118912, 30.15657],
          [50.120827, 30.15855],
          [50.123432, 30.160803],
          [50.125939, 30.163705],
          [50.129292, 30.167111],
          [50.132484, 30.169916],
          [50.139034, 30.175944],
          [50.143993, 30.181048],
          [50.149551, 30.187264],
          [50.155228, 30.194307],
          [50.158016, 30.198036],
          [50.160545, 30.201951],
          [50.163403, 30.206695],
          [50.165993, 30.211303],
          [50.168812, 30.216922],
          [50.170162, 30.219317],
          [50.171898, 30.222911],
          [50.173203, 30.225816],
          [50.174343, 30.228167],
          [50.175926, 30.231899],
          [50.177616, 30.23577],
          [50.17936, 30.239778],
          [50.180569, 30.243143],
          [50.181457, 30.246417],
          [50.182551, 30.250137],
          [50.182896, 30.251202],
          [50.184666, 30.256133],
          [50.186436, 30.260967],
          [50.189087, 30.268916],
          [50.193416, 30.279615],
          [50.195145, 30.283477],
          [50.197548, 30.289107],
          [50.197872, 30.289857],
          [50.198806, 30.292025],
          [50.199272, 30.293106],
          [50.200007, 30.294962],
          [50.200695, 30.294096],
          [50.202481, 30.292189],
          [50.203396, 30.291211],
          [50.207413, 30.287604],
          [50.2118, 30.283998],
          [50.21624, 30.280905],
          [50.221519, 30.277358],
          [50.227058, 30.274003],
          [50.231804, 30.271423],
          [50.235497, 30.269662],
          [50.239399, 30.267954],
          [50.242928, 30.26647],
          [50.247303, 30.26495],
          [50.250024, 30.264237],
          [50.252697, 30.262664],
          [50.25647, 30.259367],
          [50.260243, 30.256629],
          [50.264118, 30.253707],
          [50.269507, 30.249961],
          [50.275898, 30.246089],
          [50.283505, 30.241725],
          [50.291855, 30.237493],
          [50.31107, 30.227721],
          [50.318615, 30.223686],
          [50.323948, 30.221751],
          [50.330967, 30.219062],
          [50.340092, 30.215618],
          [50.348217, 30.212647],
          [50.351166, 30.211378],
          [50.357861, 30.208649],
          [50.364819, 30.205917],
          [50.369873, 30.203571],
          [50.379929, 30.199064],
          [50.38829, 30.19508],
          [50.397022, 30.191049],
          [50.398781, 30.190219],
          [50.406063, 30.186785],
          [50.416576, 30.18219],
          [50.422943, 30.18007],
          [50.430252, 30.177575],
          [50.436729, 30.176058],
          [50.442204, 30.17473],
          [50.448303, 30.173122],
          [50.456196, 30.171134],
          [50.462879, 30.169569],
          [50.469357, 30.168469],
          [50.473194, 30.167521],
          [50.47683, 30.167037],
          [50.483677, 30.166209],
          [50.500351, 30.165933],
          [50.503083, 30.166057],
          [50.504421, 30.166118],
          [50.509071, 30.166158],
          [50.513893, 30.166061],
          [50.514355, 30.166051],
          [50.520584, 30.165517],
          [50.519814, 30.167705],
          [50.518017, 30.172546],
          [50.516987, 30.175293],
          [50.515556, 30.179897],
          [50.514328, 30.183899],
          [50.512337, 30.190876],
          [50.511482, 30.195196],
          [50.510834, 30.198866],
          [50.510342, 30.202442],
          [50.510002, 30.20583],
          [50.509945, 30.210281],
          [50.509648, 30.217253],
          [50.509602, 30.218351],
          [50.509377, 30.22758],
          [50.509933, 30.230215],
          [50.510113, 30.23239],
          [50.509972, 30.239023],
          [50.510292, 30.25008],
          [50.510479, 30.253937],
          [50.510708, 30.257565],
          [50.511105, 30.260028],
          [50.511398, 30.262495],
          [50.511368, 30.26598],
          [50.511753, 30.269934],
          [50.511952, 30.271142],
          [50.512032, 30.273886],
          [50.51247, 30.27756],
          [50.512608, 30.280257],
          [50.512783, 30.283883],
          [50.512966, 30.287276],
          [50.513512, 30.290719],
          [50.513966, 30.293279],
          [50.514202, 30.296161],
          [50.514385, 30.299415],
          [50.514679, 30.301739],
          [50.515369, 30.307226],
          [50.516205, 30.314063],
          [50.516495, 30.317549],
          [50.516583, 30.319686],
          [50.516453, 30.322242],
          [50.516485, 30.323034],
          [50.516541, 30.324473],
          [50.517117, 30.331214],
          [50.517666, 30.334979],
          [50.518055, 30.339025],
          [50.518452, 30.34228],
          [50.518437, 30.343907],
          [50.518532, 30.345301],
          [50.518623, 30.346881],
          [50.518822, 30.348648],
          [50.519001, 30.352459],
          [50.519089, 30.354596],
          [50.51907, 30.356967],
          [50.519005, 30.358498],
          [50.51897, 30.362913],
          [50.518898, 30.365189],
          [50.518875, 30.368068],
          [50.519066, 30.370484],
          [50.51936, 30.373554],
          [50.519657, 30.375831],
          [50.519909, 30.377551],
          [50.520252, 30.38099],
          [50.520603, 30.383455],
          [50.520695, 30.38499],
          [50.52068, 30.386988],
          [50.520977, 30.389498],
          [50.520759, 30.390425],
          [50.520489, 30.390981],
          [50.519745, 30.391443],
          [50.515866, 30.3938],
          [50.510548, 30.396983],
          [50.508633, 30.397953],
          [50.507042, 30.398646],
          [50.505764, 30.399244],
          [50.504593, 30.400216],
          [50.503525, 30.401004],
          [50.50209, 30.401786],
          [50.499929, 30.402389],
          [50.498531, 30.402848],
          [50.496327, 30.403963],
          [50.493069, 30.405358],
          [50.488232, 30.407498],
          [50.484135, 30.409497],
          [50.480091, 30.411728],
          [50.474999, 30.414469],
          [50.470486, 30.417534],
          [50.466709, 30.420181],
          [50.464665, 30.421529],
          [50.459431, 30.42612],
          [50.455765, 30.429417],
          [50.455713, 30.429464],
          [50.452622, 30.432294],
          [50.449486, 30.435588],
          [50.449124, 30.43601],
          [50.446663, 30.438881],
          [50.444107, 30.44208],
          [50.441231, 30.445513],
          [50.438984, 30.448296],
          [50.434704, 30.454093],
          [50.431679, 30.458547],
          [50.430321, 30.460402],
          [50.428597, 30.462813],
          [50.426041, 30.466664],
          [50.424168, 30.470094],
          [50.422035, 30.474312],
          [50.420269, 30.477789],
          [50.418598, 30.48034],
          [50.416249, 30.484099],
          [50.413208, 30.488275],
          [50.408695, 30.493847],
          [50.404431, 30.497613],
          [50.401211, 30.500125],
          [50.400311, 30.501914],
          [50.396809, 30.50763],
          [50.394314, 30.511964],
          [50.393406, 30.513764],
          [50.391182, 30.516896],
          [50.388954, 30.519889],
          [50.38752, 30.522147],
          [50.38683, 30.522929],
          [50.386566, 30.523157],
          [50.386459, 30.523527],
          [50.386456, 30.524084],
          [50.386662, 30.52427],
          [50.387081, 30.524459],
          [50.387863, 30.524745],
          [50.389435, 30.525036],
          [50.39378, 30.526187],
          [50.396766, 30.527193],
          [50.398018, 30.527614],
          [50.399326, 30.527812],
          [50.400688, 30.5281],
          [50.401947, 30.52839],
          [50.403465, 30.528497],
          [50.407192, 30.528527],
          [50.410599, 30.528743],
          [50.415581, 30.529161],
          [50.417, 30.529172],
          [50.420246, 30.530082],
          [50.423702, 30.53113],
          [50.424541, 30.531276],
          [50.42643, 30.531293],
          [50.427952, 30.531214],
          [50.429161, 30.530992],
          [50.430889, 30.531146],
          [50.431309, 30.53143],
          [50.432095, 30.531808],
          [50.432568, 30.53195],
          [50.433193, 30.532187],
          [50.43351, 30.532143],
          [50.433983, 30.531963],
          [50.434509, 30.531782],
          [50.435349, 30.531603],
          [50.436295, 30.531474],
          [50.437451, 30.531391],
          [50.438129, 30.531396],
          [50.438812, 30.531589],
          [50.439491, 30.531919],
          [50.440853, 30.532626],
          [50.441952, 30.533378],
          [50.442894, 30.533664],
          [50.443367, 30.533666],
          [50.443947, 30.533627],
          [50.444679, 30.533448],
          [50.446049, 30.532486],
          [50.446892, 30.532029],
          [50.447731, 30.53176],
          [50.448624, 30.531628],
          [50.450252, 30.531364],
          [50.451511, 30.531282],
          [50.452457, 30.531245],
          [50.453716, 30.531255],
          [50.455238, 30.531036],
          [50.456444, 30.530815],
          [50.45797, 30.530458],
          [50.460068, 30.529874],
          [50.461817, 30.529141],
          [50.462617, 30.529017],
          [50.466566, 30.528076],
          [50.470664, 30.5271],
          [50.474287, 30.526237],
          [50.475121, 30.526237],
          [50.475433, 30.526085],
          [50.47603, 30.52614],
          [50.476304, 30.526165],
          [50.476341, 30.526168],
          [50.477074, 30.526316],
          [50.477703, 30.526413],
          [50.47823, 30.526325],
          [50.479172, 30.526148],
          [50.479936, 30.526043],
          [50.480954, 30.525966],
          [50.486414, 30.527965],
          [50.489287, 30.529017],
          [50.49262, 30.527636],
          [50.492897, 30.527356],
          [50.493731, 30.527356],
          [50.493731, 30.527076],
          [50.495707, 30.527076],
          [50.496586, 30.526765],
          [50.497318, 30.526632],
          [50.498001, 30.526637],
          [50.498684, 30.527014],
          [50.49905, 30.527575],
          [50.500044, 30.528179],
          [50.500885, 30.528455],
          [50.501721, 30.528966],
          [50.502766, 30.529241],
          [50.503971, 30.529796],
          [50.505016, 30.530167],
          [50.506012, 30.530582],
          [50.506588, 30.531089],
          [50.506954, 30.531598],
          [50.507321, 30.532109],
          [50.507633, 30.532478],
          [50.507843, 30.533172],
          [50.507793, 30.533679],
          [50.507687, 30.534188],
          [50.50753, 30.534792],
          [50.50753, 30.535208],
          [50.507637, 30.535578],
          [50.50795, 30.535669],
          [50.508526, 30.535809],
          [50.509365, 30.535808],
          [50.510098, 30.535806],
          [50.510307, 30.535992],
          [50.510464, 30.536315],
          [50.510517, 30.536778],
          [50.510361, 30.537287],
          [50.509994, 30.537795],
          [50.509844, 30.538229],
          [50.513329, 30.540057],
          [50.514133, 30.539968],
          [50.515076, 30.539967],
          [50.515862, 30.540242],
          [50.517017, 30.541122],
          [50.517334, 30.541953],
          [50.518013, 30.542556],
          [50.518852, 30.543062],
          [50.519379, 30.543247],
          [50.520268, 30.543385],
          [50.521316, 30.543847],
          [50.522629, 30.544633],
          [50.523468, 30.545233],
          [50.524307, 30.54588],
          [50.525146, 30.54648],
          [50.52578, 30.547081],
          [50.526356, 30.547773],
          [50.526726, 30.548467],
          [50.526786, 30.548761],
          [50.526935, 30.549485],
          [50.526939, 30.5499],
          [50.5271, 30.550966],
          [50.527363, 30.551797],
          [50.527519, 30.552397],
          [50.527733, 30.552813],
          [50.528153, 30.552997],
          [50.528675, 30.552997],
          [50.530354, 30.552671],
          [50.530983, 30.552394],
          [50.531818, 30.551883],
          [50.532658, 30.551513],
          [50.532816, 30.551485],
          [50.53318, 30.551421],
          [50.53355, 30.551514],
          [50.533566, 30.551523],
          [50.53397, 30.551744],
          [50.53418, 30.551975],
          [50.53434, 30.55239],
          [50.534653, 30.552806],
          [50.535179, 30.553454],
          [50.535603, 30.554006],
          [50.535813, 30.554286],
          [50.535919, 30.554331],
          [50.536442, 30.554794],
          [50.537075, 30.555299],
          [50.537655, 30.555761],
          [50.538601, 30.556317],
          [50.539703, 30.557009],
          [50.540176, 30.557471],
          [50.540283, 30.557792],
          [50.540283, 30.557977],
          [50.540337, 30.558394],
          [50.540447, 30.558995],
          [50.540608, 30.560013],
          [50.541138, 30.561352],
          [50.541927, 30.562228],
          [50.542664, 30.562876],
          [50.543453, 30.563292],
          [50.544399, 30.563566],
          [50.545185, 30.563566],
          [50.546238, 30.563425],
          [50.547233, 30.563009],
          [50.548542, 30.562822],
          [50.549175, 30.562867],
          [50.549566, 30.563055],
          [50.549566, 30.566242],
          [50.54929, 30.566522],
          [50.548733, 30.568471],
          [50.54872, 30.568511],
          [50.548635, 30.568765],
          [50.548309, 30.569156],
          [50.546741, 30.571145],
          [50.544548, 30.574798],
          [50.542931, 30.577067],
          [50.541313, 30.580675],
          [50.540375, 30.582896],
          [50.538967, 30.586735],
          [50.537662, 30.59025],
          [50.536778, 30.592888],
          [50.535786, 30.595526],
          [50.535053, 30.597468],
          [50.534374, 30.598765],
          [50.533901, 30.599643],
          [50.532959, 30.600802],
          [50.530018, 30.604137],
          [50.52602, 30.608354],
          [50.523392, 30.611598],
          [50.51981, 30.61512],
          [50.516964, 30.617809],
          [50.513804, 30.620318],
          [50.512343, 30.620967],
          [50.51151, 30.621526],
          [50.50762, 30.622916],
          [50.502896, 30.624297],
          [50.49012, 30.629576],
          [50.489287, 30.629576],
          [50.464026, 30.642595],
          [50.453174, 30.648185],
          [50.423728, 30.665134],
          [50.419005, 30.670693],
          [50.416505, 30.674583],
          [50.416254, 30.675461],
          [50.414285, 30.682362],
          [50.414285, 30.686028],
          [50.414285, 30.689303],
          [50.415118, 30.697913],
          [50.415394, 30.698192],
          [50.415394, 30.704371],
          [50.415394, 30.707912],
          [50.415118, 30.708743],
          [50.414821, 30.709559],
          [50.411785, 30.717912],
          [50.396782, 30.727641],
          [50.394281, 30.728472],
          [50.392841, 30.731308],
          [50.392748, 30.731313],
          [50.390701, 30.731423],
          [50.388466, 30.731494],
          [50.384579, 30.731365],
          [50.381279, 30.731196],
          [50.377289, 30.730789],
          [50.373035, 30.730193],
          [50.370381, 30.729707],
          [50.368042, 30.729267],
          [50.365436, 30.728733],
          [50.363262, 30.728064],
          [50.360451, 30.727019],
          [50.358437, 30.726213],
          [50.356159, 30.725125],
          [50.354363, 30.724043],
          [50.352886, 30.722734],
          [50.351353, 30.721652],
          [50.349823, 30.72048],
          [50.348351, 30.719122],
          [50.347749, 30.718508],
          [50.346931, 30.717673],
          [50.345566, 30.715993],
          [50.344151, 30.714032],
          [50.342789, 30.712259],
          [50.3419, 30.711092],
          [50.34111, 30.710251],
          [50.340428, 30.709734],
          [50.339638, 30.709172],
          [50.338844, 30.708749],
          [50.337837, 30.708459],
          [50.336826, 30.708497],
          [50.335442, 30.708716],
          [50.334107, 30.709167],
          [50.333229, 30.709436],
          [50.33176, 30.709886],
          [50.32893, 30.710787],
          [50.32626, 30.711876],
          [50.323204, 30.713514],
          [50.320957, 30.714515],
          [50.317749, 30.716014],
          [50.315552, 30.717198],
          [50.313568, 30.718338],
          [50.300369, 30.725955],
          [50.292041, 30.730834],
          [50.281132, 30.737222],
          [50.26973, 30.74379],
          [50.262673, 30.747987],
          [50.261055, 30.74899],
          [50.259966, 30.750008],
          [50.258022, 30.751912],
          [50.256126, 30.753582],
          [50.254192, 30.755561],
          [50.254177, 30.755576],
          [50.252857, 30.756963],
          [50.251541, 30.758771],
          [50.249992, 30.761002],
          [50.249016, 30.763557],
          [50.246029, 30.767334],
          [50.243309, 30.771947],
          [50.240433, 30.777297],
          [50.237919, 30.781401],
          [50.237726, 30.781637],
          [50.237397, 30.782034],
          [50.236816, 30.782737],
          [50.235188, 30.784624],
          [50.232876, 30.787942],
          [50.231514, 30.790528],
          [50.231205, 30.792144],
          [50.230793, 30.794503],
          [50.230221, 30.796954],
          [50.230228, 30.798436],
          [50.230446, 30.800107],
          [50.230927, 30.801267],
          [50.231674, 30.802661],
          [50.232578, 30.803684],
          [50.234436, 30.805456],
          [50.236187, 30.806811],
          [50.238365, 30.808953],
          [50.240967, 30.811655],
          [50.242085, 30.812774],
          [50.24269, 30.81786],
          [50.243076, 30.821113],
          [50.243309, 30.82519],
          [50.243439, 30.829914],
          [50.243454, 30.832506],
          [50.243298, 30.833153],
          [50.242201, 30.835397],
          [50.241199, 30.837447],
          [50.237949, 30.844466],
          [50.234009, 30.851526],
          [50.23338, 30.853329],
          [50.232277, 30.855544],
          [50.230595, 30.858638],
          [50.229382, 30.860623],
          [50.228592, 30.862195],
          [50.22723, 30.865983],
          [50.225387, 30.869889],
          [50.225182, 30.870324],
          [50.224709, 30.871619],
          [50.224499, 30.872497],
          [50.224293, 30.873701],
          [50.224033, 30.875181],
          [50.223881, 30.877404],
          [50.223156, 30.881429],
          [50.221749, 30.88846],
          [50.221073, 30.892393],
          [50.220678, 30.894206],
          [50.220184, 30.896466],
          [50.219559, 30.899381],
          [50.219036, 30.901785],
          [50.218567, 30.904468],
          [50.218361, 30.906227],
          [50.218101, 30.907337],
          [50.217419, 30.909787],
          [50.215111, 30.918392],
          [50.213482, 30.924405],
          [50.211529, 30.929212],
          [50.210846, 30.931666],
          [50.210423, 30.932264],
          [50.209728, 30.932354],
          [50.209091, 30.932258],
          [50.204559, 30.92969],
          [50.197739, 30.925533],
          [50.193581, 30.92329],
          [50.186501, 30.919596],
          [50.180485, 30.916323],
          [50.174153, 30.912211],
          [50.168564, 30.908986],
          [50.164471, 30.906233],
          [50.160694, 30.903756],
          [50.156494, 30.900537],
          [50.152775, 30.897828],
          [50.149959, 30.89545],
          [50.146134, 30.892696],
          [50.143162, 30.890132],
          [50.138489, 30.886257],
          [50.135731, 30.883784],
          [50.13366, 30.882153],
          [50.132229, 30.880522],
          [50.130161, 30.878331],
          [50.128254, 30.876141],
          [50.126293, 30.873906],
          [50.124966, 30.872598],
          [50.123589, 30.871246],
          [50.122368, 30.870406],
          [50.120568, 30.869097],
          [50.119703, 30.868587],
          [50.11882, 30.868067],
          [50.117176, 30.866761],
          [50.114635, 30.864473],
          [50.111775, 30.862139],
          [50.109444, 30.860733],
          [50.105526, 30.858764],
          [50.102295, 30.856659],
          [50.098644, 30.85418],
          [50.096264, 30.852264],
          [50.089123, 30.846888],
          [50.086006, 30.844504],
          [50.080986, 30.840623],
          [50.076657, 30.836933],
          [50.072434, 30.83301],
          [50.070657, 30.830973],
          [50.069748, 30.829932],
          [50.06903, 30.830693],
          [50.068791, 30.830947],
          [50.065491, 30.834638],
          [50.061344, 30.838605],
          [50.057827, 30.842854],
          [50.052818, 30.848719],
          [50.047916, 30.853842],
          [50.043545, 30.858693],
          [50.04018, 30.862943],
          [50.035267, 30.868577],
          [50.033341, 30.871308],
          [50.030369, 30.871617],
          [50.025005, 30.872375],
          [50.019642, 30.873228],
          [50.013855, 30.873707],
          [50.008705, 30.873912],
          [50.003979, 30.874117],
          [50.000156, 30.874376],
          [49.992214, 30.876784],
          [49.985645, 30.877412],
          [49.978703, 30.877951],
          [49.974404, 30.877923],
          [49.968197, 30.877861],
          [49.961929, 30.877657],
          [49.95779, 30.877631],
          [49.955505, 30.877364],
          [49.954884, 30.878288],
          [49.952748, 30.881251],
          [49.951506, 30.883756],
          [49.951392, 30.883937],
          [49.951038, 30.8845],
          [49.950523, 30.88532],
          [49.949284, 30.887724],
          [49.948563, 30.889342],
          [49.947796, 30.891375],
          [49.947815, 30.892897],
          [49.947903, 30.895434],
          [49.94846, 30.897094],
          [49.94944, 30.898841],
          [49.950039, 30.900084],
          [49.950642, 30.90128],
          [49.950981, 30.902801],
          [49.951164, 30.90446],
          [49.951351, 30.906304],
          [49.951119, 30.908842],
          [49.951096, 30.918393],
          [49.950951, 30.923234],
          [49.951122, 30.926444],
          [49.951237, 30.928585],
          [49.951141, 30.929371],
          [49.950771, 30.929601],
          [49.950081, 30.929699],
          [49.948864, 30.930167],
          [49.947544, 30.930725],
          [49.946808, 30.931283],
          [49.945442, 30.932535],
          [49.94387, 30.934295],
          [49.943085, 30.935267],
          [49.942562, 30.935872],
          [49.942245, 30.936104],
          [49.941872, 30.936106],
          [49.941551, 30.935968],
          [49.941174, 30.935694],
          [49.940849, 30.935281],
          [49.940578, 30.934821],
          [49.940254, 30.934638],
          [49.939884, 30.934639],
          [49.939617, 30.934639],
          [49.939251, 30.93492],
          [49.939041, 30.935288],
          [49.938625, 30.936122],
          [49.938106, 30.937047],
          [49.93774, 30.937464],
          [49.937107, 30.937882],
          [49.936157, 30.938346],
          [49.935307, 30.938535],
          [49.934135, 30.938496],
          [49.933441, 30.938407],
          [49.93264, 30.938182],
          [49.932159, 30.937907],
          [49.931835, 30.937586],
          [49.931828, 30.937079],
          [49.932144, 30.936847],
          [49.932728, 30.936658],
          [49.932835, 30.936566],
          [49.934323, 30.936373],
          [49.93528, 30.93637],
          [49.935703, 30.936366],
          [49.935913, 30.936043],
          [49.935866, 30.935721],
          [49.935852, 30.935628],
          [49.935528, 30.935262],
          [49.935204, 30.934616],
          [49.934868, 30.933601],
          [49.934536, 30.932452],
          [49.934154, 30.931851],
          [49.93351, 30.931255],
          [49.932976, 30.931165],
          [49.932392, 30.931262],
          [49.932128, 30.931233],
          [49.931591, 30.931174],
          [49.931164, 30.931037],
          [49.930309, 30.930626],
          [49.928539, 30.929527],
          [49.927467, 30.928981],
          [49.926022, 30.928247],
          [49.92506, 30.927838],
          [49.924313, 30.927655],
          [49.923675, 30.927707],
          [49.923412, 30.927892],
          [49.923309, 30.928261],
          [49.923313, 30.928631],
          [49.923637, 30.928951],
          [49.924015, 30.92941],
          [49.924343, 30.929963],
          [49.924668, 30.930561],
          [49.924995, 30.931252],
          [49.925327, 30.932035],
          [49.925389, 30.932771],
          [49.925392, 30.933096],
          [49.925293, 30.933603],
          [49.92519, 30.933882],
          [49.924294, 30.934393],
          [49.92371, 30.93472],
          [49.923077, 30.934951],
          [49.922546, 30.935141],
          [49.921909, 30.935097],
          [49.921375, 30.935052],
          [49.920735, 30.934918],
          [49.920044, 30.934921],
          [49.919567, 30.935109],
          [49.918934, 30.935573],
          [49.918144, 30.936085],
          [49.916931, 30.937106],
          [49.913082, 30.93994],
          [49.903648, 30.947602],
          [49.897263, 30.952481],
          [49.89362, 30.95536],
          [49.890865, 30.956622],
          [49.890462, 30.956922],
          [49.888489, 30.958388],
          [49.887757, 30.959405],
          [49.887024, 30.960701],
          [49.886509, 30.96255],
          [49.886212, 30.96426],
          [49.886318, 30.964446],
          [49.88649, 30.965367],
          [49.886978, 30.966425],
          [49.887259, 30.966782],
          [49.887737, 30.96739],
          [49.888649, 30.968124],
          [49.889618, 30.968812],
          [49.891865, 30.969769],
          [49.894774, 30.970741],
          [49.89513, 30.97086],
          [49.89909, 30.972547],
          [49.906174, 30.97657],
          [49.914173, 30.98128],
          [49.917126, 30.982879],
          [49.919548, 30.984528],
          [49.922672, 30.987096],
          [49.928177, 30.991472],
          [49.934724, 30.994186],
          [49.939412, 30.995149],
          [49.946512, 30.99648],
          [49.953347, 30.99746],
          [49.961508, 31.00007],
          [49.964371, 31.003046],
          [49.970029, 31.009432],
          [49.97143, 31.012926],
          [49.971397, 31.015995],
          [49.970839, 31.018833],
          [49.970564, 31.019854],
          [49.967888, 31.023129],
          [49.964036, 31.028428],
          [49.963128, 31.029955],
          [49.961621, 31.031989],
          [49.959612, 31.034616],
          [49.959103, 31.035631],
          [49.958718, 31.03693],
          [49.957612, 31.0402],
          [49.958239, 31.04245],
          [49.958726, 31.043221],
          [49.959899, 31.044595],
          [49.961082, 31.045116],
          [49.961962, 31.046146],
          [49.963045, 31.046752],
          [49.964978, 31.04806],
          [49.965894, 31.048863],
          [49.966674, 31.050007],
          [49.966666, 31.050689],
          [49.965988, 31.052389],
          [49.964786, 31.053629],
          [49.963168, 31.056686],
          [49.962352, 31.058839],
          [49.960619, 31.060417],
          [49.960573, 31.060506],
          [49.959404, 31.062795],
          [49.957131, 31.065391],
          [49.955798, 31.066517],
          [49.954858, 31.067874],
          [49.953122, 31.069679],
          [49.952319, 31.070582],
          [49.951254, 31.071369],
          [49.949514, 31.073515],
          [49.947518, 31.074863],
          [49.948037, 31.075663],
          [49.948695, 31.075895],
          [49.949486, 31.076016],
          [49.950675, 31.076025],
          [49.952885, 31.076425],
          [49.95397, 31.07662],
          [49.956872, 31.076985],
          [49.959771, 31.077577],
          [49.961345, 31.078613],
          [49.963445, 31.07988],
          [49.965538, 31.081716],
          [49.967139, 31.083129],
          [49.968283, 31.084466],
          [49.970111, 31.086414],
          [49.971179, 31.087585],
          [49.972878, 31.089281],
          [49.974162, 31.090539],
          [49.975108, 31.091797],
          [49.975457, 31.092936],
          [49.975841, 31.093653],
          [49.976046, 31.09458],
          [49.977012, 31.095791],
          [49.977538, 31.096022],
          [49.978586, 31.096826],
          [49.980744, 31.097671],
          [49.982259, 31.099243],
          [49.980691, 31.100751],
          [49.979195, 31.101592],
          [49.978095, 31.102521],
          [49.977784, 31.103883],
          [49.977922, 31.104507],
          [49.977973, 31.104737],
          [49.979346, 31.106027],
          [49.980924, 31.106721],
          [49.983707, 31.109305],
          [49.986223, 31.111641],
          [49.988535, 31.115095],
          [49.990416, 31.12067],
          [49.990029, 31.123239],
          [49.989994, 31.125673],
          [49.989163, 31.127874],
          [49.98785, 31.130722],
          [49.985691, 31.134169],
          [49.980641, 31.143172],
          [49.977806, 31.149328],
          [49.975658, 31.154286],
          [49.974769, 31.156114],
          [49.973557, 31.158605],
          [49.971077, 31.16265],
          [49.967857, 31.167796],
          [49.963146, 31.173634],
          [49.961507, 31.175813],
          [49.958858, 31.179333],
          [49.955738, 31.183699],
          [49.954464, 31.185078],
          [49.953964, 31.189482],
          [49.953641, 31.193333],
          [49.953608, 31.193725],
          [49.953571, 31.194163],
          [49.953125, 31.198384],
          [49.953278, 31.204255],
          [49.953468, 31.208059],
          [49.954124, 31.210166],
          [49.95467, 31.211405],
          [49.955742, 31.21145],
          [49.95874, 31.21144],
          [49.96217, 31.211569],
          [49.966564, 31.21229],
          [49.974827, 31.214425],
          [49.978321, 31.215745],
          [49.981232, 31.218213],
          [49.982804, 31.219998],
          [49.984478, 31.221647],
          [49.985893, 31.224349],
          [49.987835, 31.226042],
          [49.990273, 31.229109],
          [49.991627, 31.230617],
          [49.996841, 31.232762],
          [50.000012, 31.23304],
          [50.006921, 31.230802],
          [50.011407, 31.229975],
          [50.014916, 31.229787],
          [50.020203, 31.229934],
          [50.023251, 31.229752],
          [50.026829, 31.229758],
          [50.030464, 31.229944],
          [50.033779, 31.230501],
          [50.036984, 31.231333],
          [50.039551, 31.232026],
          [50.042381, 31.232857],
          [50.04591, 31.234334],
          [50.058739, 31.240189],
          [50.066063, 31.244659],
          [50.070789, 31.247445],
          [50.0799, 31.250591],
          [50.089958, 31.253812],
          [50.093063, 31.255458],
          [50.095154, 31.256323],
          [50.097828, 31.257095],
          [50.099861, 31.257594],
          [50.101574, 31.257911],
          [50.102817, 31.258059],
          [50.104031, 31.258086],
          [50.105263, 31.258081],
          [50.106594, 31.257892],
          [50.107555, 31.257474],
          [50.109261, 31.256685],
          [50.111492, 31.255065],
          [50.11372, 31.25294],
          [50.116745, 31.249936],
          [50.117439, 31.249982],
          [50.119099, 31.250298],
          [50.120968, 31.250383],
          [50.122463, 31.25056],
          [50.124279, 31.250554],
          [50.126949, 31.250405],
          [50.129993, 31.250349],
          [50.132931, 31.250477],
          [50.135281, 31.250376],
          [50.139927, 31.25045],
          [50.142494, 31.250487],
          [50.145485, 31.250661],
          [50.148422, 31.250879],
          [50.150455, 31.251197],
          [50.150955, 31.251338],
          [50.152706, 31.251832],
          [50.155224, 31.253111],
          [50.157675, 31.254167],
          [50.160103, 31.255212],
          [50.163429, 31.256625],
          [50.16568, 31.257677],
          [50.169869, 31.260102],
          [50.176693, 31.264269],
          [50.180084, 31.267156],
          [50.183151, 31.269264],
          [50.189282, 31.273063],
          [50.194813, 31.276037],
          [50.196644, 31.277275],
          [50.198425, 31.278789],
          [50.200852, 31.281082],
          [50.203556, 31.283882],
          [50.20528, 31.28535],
          [50.206467, 31.286129],
          [50.207756, 31.286861],
          [50.209312, 31.287409],
          [50.210705, 31.287634],
          [50.211453, 31.287587],
          [50.212418, 31.287492],
          [50.213642, 31.287163],
          [50.215561, 31.286512],
          [50.216679, 31.286092],
          [50.21764, 31.285814],
          [50.218227, 31.285813],
          [50.218712, 31.285949],
          [50.219143, 31.286452],
          [50.219364, 31.287004],
          [50.219318, 31.287743],
          [50.219116, 31.288802],
          [50.217922, 31.292262],
          [50.216831, 31.295442],
          [50.216789, 31.296317],
          [50.21669, 31.297284],
          [50.216865, 31.298438],
          [50.217198, 31.299541],
          [50.217743, 31.30069],
          [50.218342, 31.3017],
          [50.219372, 31.302757],
          [50.220665, 31.303721],
          [50.221855, 31.30473],
          [50.223633, 31.305923],
          [50.224979, 31.306745],
          [50.226326, 31.30734],
          [50.227989, 31.307934],
          [50.229763, 31.308434],
          [50.231747, 31.308611],
          [50.234371, 31.308741],
          [50.236351, 31.308735],
          [50.246761, 31.306489],
          [50.255151, 31.31519],
          [50.271018, 31.330101],
          [50.27636, 31.335121],
          [50.288256, 31.344356],
          [50.296743, 31.352473],
          [50.297566, 31.353763],
          [50.297888, 31.354037],
          [50.298113, 31.35462],
          [50.298693, 31.356128],
          [50.299278, 31.357648],
          [50.299555, 31.357928],
          [50.299832, 31.358479],
          [50.299832, 31.358759],
          [50.299555, 31.359038],
          [50.297332, 31.361819],
          [50.296779, 31.362099],
          [50.277407, 31.375721],
          [50.275177, 31.378029],
          [50.270004, 31.383501],
          [50.264811, 31.391539],
          [50.260125, 31.396586],
          [50.260067, 31.396648],
          [50.256156, 31.404694],
          [50.251833, 31.410538],
          [50.247922, 31.418217],
          [50.248033, 31.42198],
          [50.248389, 31.434072],
          [50.249394, 31.437522],
          [50.250233, 31.44139],
          [50.25081, 31.442615],
          [50.252295, 31.445205],
          [50.254091, 31.448338],
          [50.256799, 31.450222],
          [50.258176, 31.451179],
          [50.261188, 31.455109],
          [50.261971, 31.456129],
          [50.265766, 31.461082],
          [50.269835, 31.466034],
          [50.271999, 31.469567],
          [50.277172, 31.473588],
          [50.278992, 31.474927],
          [50.280712, 31.476191],
          [50.285354, 31.478094],
          [50.290661, 31.480586],
          [50.293242, 31.481571],
          [50.300392, 31.484298],
          [50.307672, 31.489564],
          [50.314412, 31.497448],
          [50.336485, 31.514194],
          [50.337246, 31.514687],
          [50.33939, 31.516011],
          [50.340946, 31.517018],
          [50.342987, 31.518576],
          [50.344276, 31.519812],
          [50.345779, 31.520819],
          [50.347229, 31.521826],
          [50.348896, 31.523154],
          [50.350292, 31.524255],
          [50.351956, 31.525213],
          [50.353676, 31.526358],
          [50.355606, 31.527503],
          [50.357536, 31.528369],
          [50.358772, 31.529239],
          [50.359795, 31.530018],
          [50.361565, 31.531116],
          [50.363556, 31.532719],
          [50.365437, 31.534],
          [50.367207, 31.535098],
          [50.369141, 31.536518],
          [50.370188, 31.537324],
          [50.370808, 31.5378],
          [50.372261, 31.538899],
          [50.373497, 31.539907],
          [50.375111, 31.541234],
          [50.375648, 31.54183],
          [50.37624, 31.542564],
          [50.376476, 31.542932],
          [50.376621, 31.543159],
          [50.376688, 31.54371],
          [50.376732, 31.544077],
          [50.37673, 31.544098],
          [50.376629, 31.544995],
          [50.376152, 31.545728],
          [50.375618, 31.54628],
          [50.37487, 31.546694],
          [50.373802, 31.5472],
          [50.371239, 31.548304],
          [50.37006, 31.548808],
          [50.368725, 31.549178],
          [50.3676, 31.549455],
          [50.366425, 31.549641],
          [50.365513, 31.549686],
          [50.364761, 31.549596],
          [50.363636, 31.54923],
          [50.362778, 31.548727],
          [50.362632, 31.548661],
          [50.361866, 31.548317],
          [50.361221, 31.547998],
          [50.36047, 31.547858],
          [50.360096, 31.547954],
          [50.359508, 31.548273],
          [50.359295, 31.548642],
          [50.358974, 31.549146],
          [50.358928, 31.549745],
          [50.358925, 31.549788],
          [50.358875, 31.550659],
          [50.358879, 31.551532],
          [50.35883, 31.552357],
          [50.35878, 31.552999],
          [50.35857, 31.553641],
          [50.358356, 31.554008],
          [50.358307, 31.554515],
          [50.358414, 31.555019],
          [50.358684, 31.555383],
          [50.359009, 31.555795],
          [50.359383, 31.556025],
          [50.359867, 31.556163],
          [50.360187, 31.556207],
          [50.360459, 31.556391],
          [50.360672, 31.55662],
          [50.360783, 31.557169],
          [50.361214, 31.557811],
          [50.361485, 31.558316],
          [50.362292, 31.560244],
          [50.362732, 31.561295],
          [50.36311, 31.562075],
          [50.363651, 31.562716],
          [50.364242, 31.563174],
          [50.364777, 31.563358],
          [50.365261, 31.563631],
          [50.365692, 31.564043],
          [50.365963, 31.5645],
          [50.366123, 31.564869],
          [50.366287, 31.56551],
          [50.366291, 31.56606],
          [50.366455, 31.566794],
          [50.366619, 31.567253],
          [50.366783, 31.567939],
          [50.36684, 31.5684],
          [50.366734, 31.568812],
          [50.366631, 31.569134],
          [50.366684, 31.569545],
          [50.366791, 31.569728],
          [50.367115, 31.569958],
          [50.3676, 31.570095],
          [50.36808, 31.570095],
          [50.368614, 31.569956],
          [50.369472, 31.569543],
          [50.369847, 31.56945],
          [50.370167, 31.569359],
          [50.370594, 31.569174],
          [50.370861, 31.569128],
          [50.370968, 31.569128],
          [50.371239, 31.569128],
          [50.371506, 31.569264],
          [50.37167, 31.569633],
          [50.371723, 31.569998],
          [50.37162, 31.570458],
          [50.371567, 31.570823],
          [50.371357, 31.571237],
          [50.371357, 31.571695],
          [50.371361, 31.57211],
          [50.371468, 31.572431],
          [50.371739, 31.572705],
          [50.372116, 31.572932],
          [50.372543, 31.573117],
          [50.373081, 31.573115],
          [50.373509, 31.573068],
          [50.373936, 31.572977],
          [50.374417, 31.572472],
          [50.375057, 31.572058],
          [50.375271, 31.571734],
          [50.375591, 31.571368],
          [50.376286, 31.571002],
          [50.376873, 31.570815],
          [50.377464, 31.570816],
          [50.378109, 31.570907],
          [50.37859, 31.571182],
          [50.379025, 31.571732],
          [50.379509, 31.572511],
          [50.379837, 31.573473],
          [50.380215, 31.574023],
          [50.380593, 31.574756],
          [50.38097, 31.575168],
          [50.381405, 31.575811],
          [50.381889, 31.576316],
          [50.381996, 31.576772],
          [50.382, 31.577232],
          [50.382004, 31.577828],
          [50.382061, 31.578378],
          [50.382225, 31.578975],
          [50.382332, 31.57925],
          [50.382496, 31.579709],
          [50.3825, 31.580352],
          [50.382503, 31.58113],
          [50.382614, 31.581681],
          [50.382942, 31.582458],
          [50.383213, 31.583285],
          [50.383537, 31.583927],
          [50.383808, 31.584522],
          [50.384239, 31.584889],
          [50.384724, 31.5853],
          [50.385693, 31.585758],
          [50.386608, 31.586216],
          [50.387627, 31.586491],
          [50.388489, 31.586809],
          [50.389347, 31.587176],
          [50.390316, 31.587497],
          [50.391014, 31.587771],
          [50.392197, 31.588318],
          [50.393109, 31.58873],
          [50.393864, 31.589235],
          [50.394566, 31.589921],
          [50.39505, 31.590562],
          [50.395485, 31.59125],
          [50.395916, 31.59203],
          [50.396244, 31.59281],
          [50.396461, 31.593359],
          [50.396572, 31.594094],
          [50.396576, 31.594643],
          [50.39642, 31.595377],
          [50.395992, 31.595975],
          [50.395622, 31.596571],
          [50.394821, 31.597442],
          [50.394238, 31.598085],
          [50.39381, 31.598408],
          [50.393543, 31.598821],
          [50.393223, 31.599187],
          [50.393013, 31.599739],
          [50.39259, 31.600284],
          [50.392586, 31.60029],
          [50.392376, 31.600701],
          [50.392269, 31.600978],
          [50.392322, 31.601299],
          [50.39238, 31.601392],
          [50.392593, 31.601483],
          [50.393185, 31.601574],
          [50.393385, 31.601555],
          [50.393665, 31.601529],
          [50.39431, 31.601435],
          [50.394951, 31.601113],
          [50.395432, 31.600883],
          [50.395969, 31.600608],
          [50.396664, 31.600514],
          [50.397148, 31.600514],
          [50.397576, 31.600146],
          [50.398163, 31.599734],
          [50.398586, 31.599274],
          [50.399178, 31.598907],
          [50.399925, 31.598766],
          [50.40073, 31.598767],
          [50.401535, 31.598764],
          [50.402393, 31.598946],
          [50.403309, 31.599176],
          [50.404061, 31.59945],
          [50.405189, 31.599907],
          [50.405567, 31.600089],
          [50.406105, 31.600227],
          [50.406693, 31.600272],
          [50.407768, 31.600271],
          [50.408623, 31.600178],
          [50.409317, 31.599764],
          [50.409904, 31.599261],
          [50.410652, 31.598615],
          [50.41124, 31.597973],
          [50.411934, 31.597376],
          [50.412735, 31.596962],
          [50.413269, 31.59678],
          [50.414127, 31.596777],
          [50.414612, 31.596914],
          [50.415096, 31.597142],
          [50.415314, 31.597556],
          [50.415424, 31.598014],
          [50.415535, 31.598655],
          [50.415699, 31.599022],
          [50.415785, 31.599152],
          [50.415913, 31.599345],
          [50.416397, 31.599573],
          [50.416721, 31.599572],
          [50.417256, 31.599435],
          [50.41739, 31.599347],
          [50.417683, 31.599157],
          [50.41827, 31.598515],
          [50.418854, 31.597689],
          [50.419491, 31.596495],
          [50.420235, 31.595533],
          [50.420765, 31.59475],
          [50.421566, 31.594244],
          [50.422688, 31.593692],
          [50.423599, 31.593324],
          [50.424831, 31.59323],
          [50.42569, 31.593277],
          [50.426979, 31.593459],
          [50.427634, 31.593645],
          [50.427944, 31.593734],
          [50.428966, 31.594097],
          [50.428994, 31.594113],
          [50.430634, 31.595058],
          [50.436348, 31.598952],
          [50.446648, 31.606418],
          [50.455494, 31.612828],
          [50.457005, 31.613836],
          [50.457542, 31.614156],
          [50.457653, 31.614477],
          [50.457394, 31.615486],
          [50.4566, 31.617321],
          [50.455921, 31.619524],
          [50.455131, 31.621544],
          [50.451767, 31.631366],
          [50.449295, 31.638295],
          [50.448452, 31.64059],
          [50.448139, 31.64192],
          [50.447456, 31.643895],
          [50.447037, 31.645226],
          [50.446404, 31.646511],
          [50.445389, 31.647474],
          [50.443787, 31.649037],
          [50.42588, 31.667375],
          [50.425272, 31.667998],
          [50.424637, 31.668738],
          [50.423248, 31.670069],
          [50.403896, 31.687566],
          [50.399626, 31.699791],
          [50.399563, 31.700278],
          [50.399036, 31.701746],
          [50.398341, 31.703469],
          [50.397524, 31.705806],
          [50.396759, 31.708398],
          [50.395752, 31.710877],
          [50.391819, 31.719368],
          [50.389374, 31.724553],
          [50.388466, 31.726159],
          [50.38628, 31.729785],
          [50.380605, 31.736963],
          [50.380605, 31.736963],
          [50.371787, 31.748117],
          [50.370521, 31.750014],
          [50.368912, 31.752859],
          [50.366611, 31.755473],
          [50.363613, 31.758451],
          [50.349936, 31.773635],
          [50.34815, 31.775618],
          [50.347172, 31.776599],
          [50.347082, 31.776803],
          [50.336937, 31.78806],
          [50.332272, 31.793422],
          [50.329968, 31.796355],
          [50.327072, 31.799656],
          [50.325623, 31.801031],
          [50.324657, 31.801993],
          [50.323639, 31.803781],
          [50.322514, 31.805432],
          [50.321606, 31.807128],
          [50.320156, 31.809604],
          [50.314743, 31.817489],
          [50.315609, 31.819833],
          [50.316689, 31.821532],
          [50.314972, 31.822676],
          [50.314056, 31.823776],
          [50.313469, 31.824508],
          [50.313469, 31.825198],
          [50.313523, 31.825611],
          [50.313473, 31.826069],
          [50.313042, 31.826251],
          [50.312073, 31.826711],
          [50.311268, 31.827076],
          [50.310139, 31.827442],
          [50.308899, 31.82767],
          [50.308148, 31.827716],
          [50.307339, 31.828081],
          [50.306534, 31.828677],
          [50.305458, 31.829043],
          [50.304382, 31.829867],
          [50.303791, 31.830647],
          [50.303097, 31.831793],
          [50.302642, 31.832471],
          [50.302452, 31.832755],
          [50.301807, 31.833534],
          [50.301109, 31.834405],
          [50.300034, 31.834909],
          [50.299118, 31.835734],
          [50.298699, 31.835829],
          [50.298313, 31.835917],
          [50.297344, 31.836191],
          [50.29686, 31.836603],
          [50.296646, 31.837245],
          [50.296215, 31.838025],
          [50.295303, 31.83885],
          [50.294033, 31.840064],
          [50.293961, 31.840133],
          [50.293209, 31.841279],
          [50.292401, 31.842332],
          [50.291756, 31.843159],
          [50.291168, 31.843938],
          [50.291008, 31.844808],
          [50.291008, 31.845313],
          [50.290848, 31.845589],
          [50.290577, 31.845911],
          [50.290146, 31.846093],
          [50.28907, 31.846277],
          [50.289054, 31.846276],
          [50.288261, 31.846227],
          [50.287293, 31.846503],
          [50.28627, 31.846822],
          [50.285786, 31.84719],
          [50.285088, 31.847923],
          [50.284123, 31.848885],
          [50.283585, 31.849527],
          [50.2831, 31.850077],
          [50.282993, 31.850627],
          [50.282993, 31.851407],
          [50.283051, 31.852233],
          [50.282997, 31.853243],
          [50.282513, 31.854116],
          [50.282246, 31.855077],
          [50.281494, 31.856178],
          [50.281063, 31.857003],
          [50.28112, 31.857554],
          [50.281445, 31.858242],
          [50.281445, 31.858884],
          [50.281876, 31.859572],
          [50.282417, 31.860171],
          [50.283116, 31.860585],
          [50.283606, 31.860658],
          [50.284355, 31.860768],
          [50.286404, 31.861416],
          [50.28775, 31.861784],
          [50.289314, 31.862384],
          [50.289692, 31.862794],
          [50.289963, 31.863301],
          [50.290287, 31.864403],
          [50.290668, 31.866469],
          [50.290726, 31.867385],
          [50.290672, 31.868761],
          [50.290565, 31.869818],
          [50.290352, 31.870412],
          [50.289974, 31.870595],
          [50.289005, 31.870687],
          [50.288868, 31.870729],
          [50.288254, 31.870917],
          [50.287712, 31.871236],
          [50.287392, 31.87165],
          [50.287014, 31.872245],
          [50.286907, 31.873072],
          [50.286886, 31.873176],
          [50.286694, 31.874127],
          [50.286533, 31.874676],
          [50.286427, 31.875228],
          [50.285999, 31.875776],
          [50.285244, 31.876557],
          [50.284275, 31.877244],
          [50.28363, 31.87807],
          [50.282772, 31.879673],
          [50.281536, 31.882197],
          [50.280621, 31.8838],
          [50.279869, 31.884993],
          [50.279225, 31.885497],
          [50.278523, 31.886185],
          [50.278091, 31.886828],
          [50.276856, 31.888249],
          [50.275833, 31.889393],
          [50.274758, 31.890172],
          [50.273842, 31.890952],
          [50.273087, 31.8915],
          [50.272331, 31.89182],
          [50.271416, 31.892187],
          [50.270952, 31.892351],
          [50.270122, 31.892644],
          [50.269478, 31.892965],
          [50.268398, 31.893561],
          [50.267647, 31.893972],
          [50.266892, 31.894753],
          [50.266604, 31.894998],
          [50.265816, 31.895669],
          [50.2649, 31.896356],
          [50.263607, 31.896858],
          [50.263311, 31.897002],
          [50.261719, 31.897774],
          [50.260857, 31.898462],
          [50.260675, 31.898606],
          [50.260048, 31.899104],
          [50.259133, 31.899974],
          [50.258488, 31.900708],
          [50.257519, 31.9019],
          [50.256439, 31.903046],
          [50.255257, 31.904052],
          [50.254395, 31.904834],
          [50.253315, 31.905657],
          [50.252346, 31.906574],
          [50.251676, 31.907085],
          [50.251324, 31.907353],
          [50.250622, 31.908131],
          [50.25003, 31.908635],
          [50.24976, 31.909324],
          [50.249653, 31.910059],
          [50.249924, 31.910701],
          [50.250355, 31.911344],
          [50.250786, 31.911942],
          [50.251488, 31.912814],
          [50.251766, 31.913097],
          [50.252029, 31.913366],
          [50.252354, 31.914146],
          [50.252624, 31.914835],
          [50.252731, 31.915431],
          [50.252731, 31.916027],
          [50.252625, 31.916761],
          [50.252357, 31.91736],
          [50.251816, 31.918092],
          [50.251118, 31.918548],
          [50.24987, 31.919077],
          [50.248375, 31.920275],
          [50.246342, 31.921934],
          [50.245163, 31.922579],
          [50.243076, 31.923778],
          [50.241741, 31.924884],
          [50.240509, 31.926126],
          [50.240108, 31.926635],
          [50.239712, 31.927138],
          [50.239231, 31.928015],
          [50.238961, 31.928902],
          [50.238919, 31.929441],
          [50.238972, 31.929994],
          [50.23914, 31.931007],
          [50.239471, 31.932805],
          [50.239551, 31.933443],
          [50.239697, 31.934601],
          [50.239975, 31.936213],
          [50.24009, 31.937502],
          [50.240093, 31.938424],
          [50.239723, 31.939572],
          [50.23962, 31.940172],
          [50.239029, 31.940495],
          [50.238384, 31.940771],
          [50.237526, 31.941093],
          [50.236832, 31.941461],
          [50.234638, 31.943349],
          [50.230194, 31.946897],
          [50.226922, 31.949714],
          [50.225807, 31.950673],
          [50.222271, 31.952974],
          [50.219811, 31.955048],
          [50.211239, 31.961355],
          [50.208023, 31.963703],
          [50.20454, 31.966142],
          [50.202664, 31.967246],
          [50.20116, 31.968351],
          [50.196181, 31.972585],
          [50.196178, 31.972587],
          [50.190926, 31.97673],
          [50.188889, 31.978431],
          [50.187119, 31.979491],
          [50.1861, 31.980319],
          [50.184601, 31.981239],
          [50.181435, 31.983634],
          [50.17886, 31.985656],
          [50.176231, 31.987545],
          [50.172905, 31.989661],
          [50.168022, 31.992974],
          [50.166252, 31.994169],
          [50.165016, 31.994905],
          [50.159264, 31.997529],
          [50.152559, 32.000177],
          [50.148354, 32.00053],
          [50.147276, 32.00062],
          [50.147209, 32.000626],
          [50.145203, 32.001442],
          [50.142929, 32.002162],
          [50.1413, 32.002983],
          [50.139294, 32.0038],
          [50.137665, 32.004756],
          [50.135548, 32.005944],
          [50.134136, 32.006717],
          [50.132725, 32.007488],
          [50.131367, 32.008313],
          [50.130444, 32.008812],
          [50.12925, 32.009448],
          [50.127895, 32.009899],
          [50.12643, 32.010582],
          [50.125565, 32.011036],
          [50.124744, 32.011722],
          [50.123657, 32.012592],
          [50.122788, 32.013188],
          [50.1217, 32.013916],
          [50.120068, 32.01501],
          [50.119415, 32.015515],
          [50.118218, 32.016429],
          [50.117455, 32.017161],
          [50.115982, 32.018443],
          [50.114403, 32.019768],
          [50.113041, 32.021003],
          [50.111733, 32.022336],
          [50.111076, 32.023021],
          [50.110584, 32.023708],
          [50.109657, 32.024579],
          [50.108997, 32.025406],
          [50.108288, 32.026229],
          [50.107201, 32.026821],
          [50.105842, 32.027642],
          [50.104973, 32.028145],
          [50.104427, 32.028649],
          [50.103722, 32.029334],
          [50.103012, 32.030021],
          [50.102466, 32.03048],
          [50.101921, 32.030887],
          [50.101105, 32.031345],
          [50.100018, 32.03212],
          [50.099312, 32.032623],
          [50.098003, 32.033858],
          [50.096908, 32.034958],
          [50.0956, 32.036239],
          [50.094185, 32.037476],
          [50.092602, 32.038939],
          [50.091019, 32.040405],
          [50.089768, 32.041409],
          [50.086941, 32.043141],
          [50.085739, 32.044147],
          [50.084381, 32.04506],
          [50.083183, 32.045926],
          [50.082146, 32.047073],
          [50.081165, 32.047847],
          [50.080021, 32.048673],
          [50.078819, 32.049722],
          [50.077404, 32.050774],
          [50.076153, 32.051685],
          [50.074356, 32.052826],
          [50.072781, 32.053967],
          [50.071636, 32.054879],
          [50.070599, 32.055794],
          [50.069614, 32.056802],
          [50.068523, 32.057899],
          [50.067429, 32.059136],
          [50.066662, 32.060237],
          [50.064693, 32.062253],
          [50.061745, 32.065273],
          [50.060158, 32.067104],
          [50.058788, 32.068711],
          [50.058712, 32.068812],
          [50.057362, 32.07059],
          [50.055992, 32.072517],
          [50.054619, 32.074582],
          [50.053627, 32.07605],
          [50.052914, 32.077199],
          [50.052414, 32.07821],
          [50.051807, 32.079448],
          [50.051476, 32.080091],
          [50.050224, 32.080909],
          [50.048916, 32.081822],
          [50.047832, 32.082321],
          [50.046745, 32.082913],
          [50.045712, 32.083457],
          [50.044514, 32.084233],
          [50.042232, 32.085411],
          [50.040653, 32.086414],
          [50.039024, 32.087184],
          [50.038345, 32.087583],
          [50.037392, 32.088142],
          [50.035271, 32.089138],
          [50.034077, 32.089822],
          [50.031792, 32.091187],
          [50.030319, 32.092141],
          [50.026459, 32.094231],
          [50.023029, 32.096555],
          [50.020138, 32.09893],
          [50.017956, 32.100708],
          [50.015934, 32.102539],
          [50.014027, 32.103997],
          [50.012337, 32.105136],
          [50.010159, 32.106549],
          [50.007275, 32.10809],
          [50.00428, 32.110096],
          [50.002861, 32.111145],
          [50.00027, 32.112543],
          [49.992886, 32.122331],
          [49.988033, 32.128002],
          [49.985172, 32.130997],
          [49.973091, 32.144828],
          [49.965451, 32.153399],
          [49.964134, 32.154876],
          [49.950318, 32.170502],
          [49.944, 32.177551],
          [49.939953, 32.182433],
          [49.937954, 32.184737],
          [49.936821, 32.186074],
          [49.936066, 32.187642],
          [49.935097, 32.189666],
          [49.934612, 32.190773],
          [49.934181, 32.192712],
          [49.933643, 32.195061],
          [49.932892, 32.197867],
          [49.932625, 32.200082],
          [49.932251, 32.202293],
          [49.932037, 32.204136],
          [49.932095, 32.206162],
          [49.931881, 32.208005],
          [49.931667, 32.209756],
          [49.931725, 32.210769],
          [49.931454, 32.212151],
          [49.931137, 32.216758],
          [49.931036, 32.217771],
          [49.93034, 32.224773],
          [49.92997, 32.230347],
          [49.929543, 32.234538],
          [49.928848, 32.240071],
          [49.928475, 32.243755],
          [49.928482, 32.24707],
          [49.928967, 32.250092],
          [49.928967, 32.251111],
          [49.928749, 32.251892],
          [49.928909, 32.25282],
          [49.928856, 32.253925],
          [49.929233, 32.255173],
          [49.929287, 32.256233],
          [49.929558, 32.257712],
          [49.929287, 32.259235],
          [49.928909, 32.261315],
          [49.928638, 32.2627],
          [49.928314, 32.264312],
          [49.928043, 32.265376],
          [49.927879, 32.266255],
          [49.927879, 32.266945],
          [49.927502, 32.268421],
          [49.926632, 32.271697],
          [49.926254, 32.272758],
          [49.92609, 32.273961],
          [49.925877, 32.275806],
          [49.925877, 32.276776],
          [49.925873, 32.277653],
          [49.926037, 32.279224],
          [49.92609, 32.280792],
          [49.926144, 32.282779],
          [49.926037, 32.28421],
          [49.92506, 32.287441],
          [49.923328, 32.292241],
          [49.921864, 32.296436],
          [49.920891, 32.298882],
          [49.920842, 32.298983],
          [49.919537, 32.301696],
          [49.91856, 32.303636],
          [49.917477, 32.305985],
          [49.91634, 32.307414],
          [49.915688, 32.308938],
          [49.914768, 32.311149],
          [49.913361, 32.3135],
          [49.911518, 32.316082],
          [49.90984, 32.317786],
          [49.90789, 32.320275],
          [49.905884, 32.322208],
          [49.903828, 32.323726],
          [49.902096, 32.32529],
          [49.900524, 32.325932],
          [49.898953, 32.32713],
          [49.897652, 32.328278],
          [49.895271, 32.32938],
          [49.893865, 32.330268],
          [49.892941, 32.330852],
          [49.891209, 32.331908],
          [49.888824, 32.333793],
          [49.886658, 32.335632],
          [49.884491, 32.337334],
          [49.881889, 32.340003],
          [49.879559, 32.34272],
          [49.878294, 32.344085],
          [49.876957, 32.345527],
          [49.876066, 32.34664],
          [49.875008, 32.347966],
          [49.873436, 32.3499],
          [49.872623, 32.350865],
          [49.871376, 32.35197],
          [49.86818, 32.354034],
          [49.864601, 32.356469],
          [49.858749, 32.360557],
          [49.855988, 32.362762],
          [49.852085, 32.366206],
          [49.846176, 32.371585],
          [49.843083, 32.374339],
          [49.840592, 32.376869],
          [49.838581, 32.379261],
          [49.835926, 32.382385],
          [49.832562, 32.387448],
          [49.828274, 32.393375],
          [49.824856, 32.397931],
          [49.822143, 32.400914],
          [49.819973, 32.403396],
          [49.816879, 32.40684],
          [49.814549, 32.409321],
          [49.810425, 32.412625],
          [49.806953, 32.41542],
          [49.805, 32.41721],
          [49.803154, 32.419229],
          [49.799355, 32.424465],
          [49.795662, 32.429012],
          [49.791969, 32.433972],
          [49.790989, 32.435261],
          [49.790341, 32.435992],
          [49.787731, 32.43962],
          [49.784527, 32.443153],
          [49.782139, 32.445633],
          [49.779751, 32.447738],
          [49.777199, 32.449939],
          [49.774593, 32.452228],
          [49.770741, 32.455162],
          [49.767265, 32.457492],
          [49.767084, 32.457621],
          [49.762653, 32.460789],
          [49.757443, 32.464954],
          [49.754074, 32.467332],
          [49.749868, 32.469809],
          [49.746201, 32.47177],
          [49.742524, 32.474455],
          [49.740452, 32.476356],
          [49.738167, 32.47857],
          [49.736046, 32.480594],
          [49.73251, 32.484604],
          [49.731205, 32.486634],
          [49.729244, 32.488612],
          [49.727341, 32.490643],
          [49.723385, 32.494418],
          [49.717706, 32.49941],
          [49.717481, 32.499608],
          [49.715221, 32.501804],
          [49.711163, 32.505657],
          [49.709025, 32.508079],
          [49.707272, 32.510066],
          [49.705513, 32.511834],
          [49.704338, 32.512992],
          [49.703102, 32.513782],
          [49.701874, 32.514759],
          [49.697846, 32.51686],
          [49.694363, 32.519093],
          [49.691677, 32.520537],
          [49.68953, 32.521798],
          [49.686409, 32.523015],
          [49.684155, 32.524227],
          [49.682224, 32.525436],
          [49.679916, 32.527019],
          [49.6782, 32.528366],
          [49.676216, 32.529671],
          [49.674607, 32.530601],
          [49.672939, 32.53135],
          [49.670628, 32.532699],
          [49.66848, 32.534047],
          [49.665905, 32.535862],
          [49.664456, 32.536977],
          [49.66291, 32.539292],
          [49.661362, 32.541332],
          [49.65799, 32.544766],
          [49.653805, 32.548386],
          [49.650429, 32.551633],
          [49.646515, 32.555253],
          [49.642437, 32.559009],
          [49.638794, 32.562672],
          [49.635742, 32.566425],
          [49.633545, 32.569157],
          [49.632046, 32.570782],
          [49.628876, 32.573749],
          [49.625492, 32.576995],
          [49.62146, 32.580476],
          [49.617214, 32.584183],
          [49.614094, 32.586826],
          [49.611351, 32.589145],
          [49.609631, 32.590492],
          [49.607582, 32.591884],
          [49.60521, 32.593093],
          [49.603428, 32.594257],
          [49.601868, 32.595324],
          [49.599228, 32.597687],
          [49.59713, 32.599727],
          [49.595463, 32.601395],
          [49.593311, 32.60325],
          [49.592125, 32.604454],
          [49.590836, 32.60561],
          [49.59008, 32.606492],
          [49.588898, 32.607604],
          [49.586849, 32.609227],
          [49.584965, 32.61131],
          [49.583298, 32.613068],
          [49.581898, 32.614552],
          [49.580498, 32.616031],
          [49.579151, 32.61756],
          [49.578293, 32.618946],
          [49.57679, 32.621121],
          [49.576149, 32.622647],
          [49.57502, 32.624497],
          [49.574055, 32.626069],
          [49.572979, 32.627457],
          [49.571308, 32.629353],
          [49.568036, 32.63314],
          [49.564953, 32.63671],
          [49.563282, 32.638885],
          [49.561504, 32.641151],
          [49.560047, 32.642539],
          [49.559132, 32.643742],
          [49.557835, 32.645039],
          [49.55703, 32.646054],
          [49.55563, 32.648227],
          [49.554012, 32.650216],
          [49.552284, 32.652065],
          [49.55045, 32.65392],
          [49.547474, 32.65628],
          [49.545853, 32.657623],
          [49.543797, 32.658966],
          [49.542446, 32.659942],
          [49.541962, 32.661141],
          [49.537044, 32.666092],
          [49.533798, 32.669006],
          [49.525955, 32.676179],
          [49.52076, 32.680806],
          [49.516643, 32.684446],
          [49.516048, 32.684971],
          [49.514374, 32.687558],
          [49.512428, 32.690421],
          [49.510262, 32.693099],
          [49.508636, 32.694814],
          [49.506798, 32.696846],
          [49.505009, 32.698741],
          [49.503384, 32.700542],
          [49.501919, 32.701789],
          [49.500565, 32.70295],
          [49.500077, 32.703093],
          [49.500206, 32.709632],
          [49.500239, 32.711347],
          [49.500289, 32.713888],
          [49.500449, 32.714785],
          [49.500826, 32.715671],
          [49.501544, 32.716547],
          [49.502148, 32.716895],
          [49.503436, 32.717741],
          [49.503494, 32.717779],
          [49.504757, 32.718446],
          [49.505588, 32.718885],
          [49.507206, 32.719594],
          [49.510184, 32.721228],
          [49.510766, 32.721514],
          [49.511877, 32.722037],
          [49.512013, 32.722127],
          [49.513042, 32.722944],
          [49.514697, 32.724213],
          [49.515778, 32.724825],
          [49.515533, 32.725181],
          [49.514556, 32.726604],
          [49.511823, 32.729572],
          [49.509668, 32.731892],
          [49.50798, 32.733712],
          [49.507167, 32.734584],
          [49.506691, 32.735045],
          [49.505283, 32.737179],
          [49.504314, 32.738288],
          [49.504312, 32.73829],
          [49.500001, 32.741342],
          [49.499312, 32.741836],
          [49.498142, 32.742512],
          [49.495228, 32.744507],
          [49.492499, 32.74614],
          [49.490799, 32.747157],
          [49.487667, 32.74902],
          [49.485514, 32.75004],
          [49.482902, 32.751468],
          [49.475758, 32.755559],
          [49.471561, 32.757416],
          [49.465576, 32.760808],
          [49.462921, 32.762108],
          [49.458984, 32.76355],
          [49.455208, 32.765042],
          [49.451099, 32.766204],
          [49.449341, 32.766766],
          [49.445721, 32.768117],
          [49.441948, 32.77007],
          [49.438973, 32.771603],
          [49.438143, 32.772079],
          [49.437111, 32.772671],
          [49.436424, 32.773183],
          [49.435631, 32.773922],
          [49.435215, 32.774758],
          [49.434643, 32.775821],
          [49.43449, 32.776286],
          [49.434284, 32.776748],
          [49.433807, 32.777123],
          [49.433331, 32.777539],
          [49.432694, 32.777862],
          [49.432373, 32.778003],
          [49.431946, 32.778094],
          [49.430927, 32.778145],
          [49.430019, 32.778474],
          [49.429115, 32.778889],
          [49.428318, 32.779309],
          [49.427105, 32.780656],
          [49.425205, 32.782743],
          [49.424736, 32.783528],
          [49.424214, 32.784408],
          [49.423847, 32.785012],
          [49.423638, 32.785568],
          [49.423542, 32.786262],
          [49.42339, 32.786819],
          [49.42313, 32.787235],
          [49.422707, 32.787839],
          [49.422447, 32.788207],
          [49.421757, 32.788814],
          [49.420109, 32.79002],
          [49.418999, 32.791088],
          [49.418526, 32.791691],
          [49.418213, 32.792477],
          [49.418167, 32.792988],
          [49.418068, 32.793546],
          [49.418182, 32.793915],
          [49.418293, 32.794189],
          [49.418304, 32.794791],
          [49.418255, 32.795253],
          [49.418152, 32.795486],
          [49.417942, 32.795903],
          [49.417469, 32.796555],
          [49.417046, 32.797016],
          [49.416474, 32.798267],
          [49.415741, 32.799519],
          [49.415432, 32.800354],
          [49.415226, 32.80091],
          [49.41502, 32.801604],
          [49.414654, 32.802299],
          [49.414391, 32.802811],
          [49.413921, 32.803597],
          [49.413235, 32.804432],
          [49.412331, 32.805313],
          [49.411217, 32.806244],
          [49.410317, 32.80717],
          [49.409843, 32.808005],
          [49.40889, 32.808979],
          [49.408096, 32.809769],
          [49.40762, 32.810417],
          [49.407356, 32.810928],
          [49.407257, 32.81144],
          [49.407158, 32.811901],
          [49.406841, 32.812459],
          [49.406368, 32.813199],
          [49.405415, 32.814357],
          [49.404301, 32.815335],
          [49.403664, 32.816075],
          [49.402924, 32.816909],
          [49.402393, 32.817699],
          [49.401867, 32.818443],
          [49.401604, 32.818904],
          [49.401123, 32.819184],
          [49.400692, 32.819233],
          [49.400047, 32.819141],
          [49.39967, 32.819141],
          [49.399239, 32.819237],
          [49.398598, 32.819375],
          [49.398113, 32.81956],
          [49.397636, 32.81998],
          [49.396732, 32.820769],
          [49.395882, 32.821606],
          [49.393753, 32.823602],
          [49.391621, 32.825412],
          [49.390881, 32.826293],
          [49.389866, 32.827037],
          [49.389212, 32.827485],
          [49.388851, 32.827732],
          [49.387779, 32.828151],
          [49.387081, 32.828384],
          [49.386618, 32.828435],
          [49.386223, 32.828479],
          [49.385578, 32.828712],
          [49.385098, 32.829041],
          [49.383659, 32.830342],
          [49.383019, 32.830848],
          [49.382591, 32.831176],
          [49.381573, 32.831733],
          [49.380505, 32.832432],
          [49.379597, 32.83313],
          [49.378155, 32.834289],
          [49.3773, 32.834938],
          [49.376556, 32.835774],
          [49.376026, 32.836471],
          [49.375656, 32.83712],
          [49.375443, 32.837489],
          [49.375393, 32.83786],
          [49.37545, 32.838184],
          [49.375468, 32.838321],
          [49.375511, 32.838649],
          [49.375462, 32.838928],
          [49.375195, 32.839157],
          [49.374981, 32.839391],
          [49.372894, 32.840831],
          [49.372741, 32.840945],
          [49.371128, 32.842132],
          [49.369739, 32.843201],
          [49.369259, 32.843575],
          [49.368215, 32.84407],
          [49.367596, 32.844363],
          [49.367165, 32.844552],
          [49.366467, 32.844646],
          [49.366032, 32.844647],
          [49.365383, 32.84451],
          [49.365372, 32.844507],
          [49.364735, 32.844326],
          [49.364086, 32.844144],
          [49.363384, 32.843959],
          [49.362518, 32.843776],
          [49.361546, 32.843639],
          [49.361008, 32.843639],
          [49.359821, 32.843734],
          [49.358311, 32.843834],
          [49.356911, 32.843793],
          [49.354805, 32.843797],
          [49.353512, 32.843845],
          [49.35265, 32.843987],
          [49.351952, 32.844264],
          [49.351414, 32.844451],
          [49.35104, 32.84464],
          [49.350716, 32.844872],
          [49.350506, 32.845149],
          [49.350292, 32.845383],
          [49.350079, 32.845662],
          [49.349594, 32.845753],
          [49.349052, 32.845757],
          [49.348026, 32.845664],
          [49.347169, 32.845493],
          [49.347111, 32.845481],
          [49.346405, 32.845253],
          [49.345539, 32.845025],
          [49.345051, 32.844653],
          [49.344563, 32.844426],
          [49.344025, 32.844243],
          [49.34343, 32.844104],
          [49.342888, 32.843969],
          [49.342129, 32.843643],
          [49.341804, 32.843415],
          [49.341316, 32.843044],
          [49.3405, 32.842025],
          [49.340092, 32.841556],
          [49.339737, 32.841149],
          [49.339085, 32.840504],
          [49.338539, 32.839992],
          [49.338215, 32.839765],
          [49.33762, 32.839581],
          [49.337135, 32.839581],
          [49.33638, 32.83963],
          [49.335144, 32.839958],
          [49.333313, 32.840286],
          [49.331699, 32.840706],
          [49.331268, 32.840847],
          [49.330567, 32.840896],
          [49.330242, 32.840924],
          [49.330028, 32.840943],
          [49.32938, 32.840851],
          [49.328732, 32.840669],
          [49.328083, 32.840394],
          [49.328068, 32.840382],
          [49.327648, 32.840068],
          [49.327, 32.839841],
          [49.326675, 32.839699],
          [49.326244, 32.839702],
          [49.325867, 32.839704],
          [49.325643, 32.839774],
          [49.323952, 32.839348],
          [49.319938, 32.839348],
          [49.319504, 32.839255],
          [49.319016, 32.839165],
          [49.318745, 32.839164],
          [49.318424, 32.839351],
          [49.318104, 32.839584],
          [49.317944, 32.839954],
          [49.317749, 32.840179],
          [49.316267, 32.840784],
          [49.315415, 32.840934],
          [49.314503, 32.841258],
          [49.313427, 32.841773],
          [49.312511, 32.842286],
          [49.312392, 32.842365],
          [49.312037, 32.842599],
          [49.310791, 32.843555],
          [49.310581, 32.843728],
          [49.30983, 32.844421],
          [49.308758, 32.845262],
          [49.308383, 32.845436],
          [49.307358, 32.845912],
          [49.306335, 32.846606],
          [49.305153, 32.847213],
          [49.304237, 32.847633],
          [49.303486, 32.847961],
          [49.302841, 32.848473],
          [49.302521, 32.848888],
          [49.302197, 32.849168],
          [49.30181, 32.849268],
          [49.301659, 32.849307],
          [49.301117, 32.849262],
          [49.300633, 32.849079],
          [49.300198, 32.848895],
          [49.299666, 32.848712],
          [49.29966, 32.84871],
          [49.299011, 32.848622],
          [49.298416, 32.848668],
          [49.29734, 32.848854],
          [49.296591, 32.849072],
          [49.296372, 32.849136],
          [49.29551, 32.849693],
          [49.294166, 32.850623],
          [49.293411, 32.851089],
          [49.292442, 32.851419],
          [49.291256, 32.851468],
          [49.290337, 32.851421],
          [49.289742, 32.851147],
          [49.289147, 32.850918],
          [49.28828, 32.850503],
          [49.287308, 32.849761],
          [49.287165, 32.849666],
          [49.286549, 32.849255],
          [49.285904, 32.849117],
          [49.284661, 32.84912],
          [49.283798, 32.849217],
          [49.283043, 32.84954],
          [49.281807, 32.8501],
          [49.280567, 32.850753],
          [49.280029, 32.851449],
          [49.279728, 32.851974],
          [49.279656, 32.8521],
          [49.279331, 32.852562],
          [49.2789, 32.852748],
          [49.278633, 32.852749],
          [49.278362, 32.852657],
          [49.278198, 32.85243],
          [49.278039, 32.852309],
          [49.277767, 32.852103],
          [49.277493, 32.851734],
          [49.277008, 32.851504],
          [49.276306, 32.851506],
          [49.275551, 32.851554],
          [49.274689, 32.851605],
          [49.273445, 32.851791],
          [49.272423, 32.851933],
          [49.271019, 32.852028],
          [49.269726, 32.852265],
          [49.269241, 32.85245],
          [49.268753, 32.85273],
          [49.268433, 32.853332],
          [49.268055, 32.854078],
          [49.267842, 32.854634],
          [49.267467, 32.855515],
          [49.267307, 32.856117],
          [49.266769, 32.85672],
          [49.266174, 32.857278],
          [49.26569, 32.857884],
          [49.265259, 32.858163],
          [49.264126, 32.858627],
          [49.262455, 32.858911],
          [49.261857, 32.859111],
          [49.261212, 32.859328],
          [49.260349, 32.859982],
          [49.259487, 32.86077],
          [49.258732, 32.861373],
          [49.258412, 32.861702],
          [49.257652, 32.861843],
          [49.257008, 32.861843],
          [49.256573, 32.861519],
          [49.255871, 32.86092],
          [49.255547, 32.860271],
          [49.255329, 32.85944],
          [49.255219, 32.858745],
          [49.255161, 32.857864],
          [49.254837, 32.85745],
          [49.25436, 32.857038],
          [49.254353, 32.857032],
          [49.254329, 32.857027],
          [49.253811, 32.856896],
          [49.253326, 32.856896],
          [49.252785, 32.85699],
          [49.252087, 32.857594],
          [49.251328, 32.857876],
          [49.25005, 32.857788],
          [49.249233, 32.857513],
          [49.248577, 32.857242],
          [49.248085, 32.856922],
          [49.24781, 32.856603],
          [49.24786, 32.856186],
          [49.247803, 32.855678],
          [49.247853, 32.855034],
          [49.247841, 32.854997],
          [49.247742, 32.854667],
          [49.24736, 32.854439],
          [49.246704, 32.854305],
          [49.246163, 32.854305],
          [49.245563, 32.85431],
          [49.244312, 32.854642],
          [49.243443, 32.854828],
          [49.242409, 32.855343],
          [49.241596, 32.8559],
          [49.240758, 32.856319],
          [49.240566, 32.856415],
          [49.239532, 32.856926],
          [49.237744, 32.858089],
          [49.237202, 32.85814],
          [49.236874, 32.858093],
          [49.236435, 32.85796],
          [49.235779, 32.857457],
          [49.234848, 32.856815],
          [49.233696, 32.855622],
          [49.232441, 32.854756],
          [49.231781, 32.854298],
          [49.230747, 32.853982],
          [49.229874, 32.853847],
          [49.228836, 32.853576],
          [49.227474, 32.853401],
          [49.22644, 32.853314],
          [49.225784, 32.853088],
          [49.224857, 32.852863],
          [49.223767, 32.853008],
          [49.223061, 32.853196],
          [49.222244, 32.853198],
          [49.221535, 32.853066],
          [49.2211, 32.852792],
          [49.22044, 32.852334],
          [49.219841, 32.852016],
          [49.219349, 32.851926],
          [49.218807, 32.85202],
          [49.217991, 32.852166],
          [49.217121, 32.852494],
          [49.216145, 32.853005],
          [49.215492, 32.853607],
          [49.215168, 32.853977],
          [49.214958, 32.854673],
          [49.214474, 32.855964],
          [49.2141, 32.856705],
          [49.213722, 32.857167],
          [49.21307, 32.857586],
          [49.212315, 32.858468],
          [49.211338, 32.859394],
          [49.210041, 32.86069],
          [49.208523, 32.861854],
          [49.207279, 32.862874],
          [49.206249, 32.863664],
          [49.205059, 32.864548],
          [49.2043, 32.864968],
          [49.203754, 32.864922],
          [49.203369, 32.864651],
          [49.203095, 32.864326],
          [49.20282, 32.863869],
          [49.202763, 32.863406],
          [49.202705, 32.86304],
          [49.202452, 32.86301],
          [49.201723, 32.863239],
          [49.200275, 32.863765],
          [49.200043, 32.864021],
          [49.199612, 32.864624],
          [49.198963, 32.86546],
          [49.198315, 32.866199],
          [49.197613, 32.866665],
          [49.19669, 32.867039],
          [49.195984, 32.867322],
          [49.194737, 32.868389],
          [49.193272, 32.869321],
          [49.191811, 32.870154],
          [49.190235, 32.870995],
          [49.188824, 32.871789],
          [49.187092, 32.872952],
          [49.185303, 32.874161],
          [49.183891, 32.87495],
          [49.182537, 32.87579],
          [49.181343, 32.876487],
          [49.180477, 32.877232],
          [49.179177, 32.87802],
          [49.178039, 32.878861],
          [49.176846, 32.879743],
          [49.176033, 32.880349],
          [49.175171, 32.881276],
          [49.174141, 32.882294],
          [49.173275, 32.883129],
          [49.172573, 32.88364],
          [49.171974, 32.883922],
          [49.171215, 32.883971],
          [49.170669, 32.883977],
          [49.169903, 32.883655],
          [49.169521, 32.88343],
          [49.168919, 32.882831],
          [49.168423, 32.881868],
          [49.167927, 32.881089],
          [49.167431, 32.880626],
          [49.166996, 32.880446],
          [49.166233, 32.880223],
          [49.165249, 32.880089],
          [49.164486, 32.879769],
          [49.163612, 32.879452],
          [49.16301, 32.879132],
          [49.162411, 32.878769],
          [49.162079, 32.878449],
          [49.161968, 32.877805],
          [49.162075, 32.877524],
          [49.162231, 32.87683],
          [49.162445, 32.876046],
          [49.162872, 32.875169],
          [49.163047, 32.874812],
          [49.163143, 32.874616],
          [49.162975, 32.873874],
          [49.162479, 32.87328],
          [49.16182, 32.872547],
          [49.161438, 32.872086],
          [49.160889, 32.871861],
          [49.159691, 32.871636],
          [49.1586, 32.871365],
          [49.15707, 32.870869],
          [49.156086, 32.870456],
          [49.154995, 32.869957],
          [49.154285, 32.8695],
          [49.153683, 32.869413],
          [49.15314, 32.869345],
          [49.152267, 32.869236],
          [49.150852, 32.869152],
          [49.149162, 32.868976],
          [49.147202, 32.868897],
          [49.145348, 32.869001],
          [49.143063, 32.869014],
          [49.142572, 32.869072],
          [49.14143, 32.869206],
          [49.139309, 32.869495],
          [49.137077, 32.869831],
          [49.135067, 32.870536],
          [49.133278, 32.871193],
          [49.131702, 32.871895],
          [49.130402, 32.87273],
          [49.129372, 32.873475],
          [49.128399, 32.874355],
          [49.127804, 32.875099],
          [49.127048, 32.876026],
          [49.126564, 32.876903],
          [49.126408, 32.877507],
          [49.126198, 32.878703],
          [49.125931, 32.87963],
          [49.125749, 32.880153],
          [49.125561, 32.880691],
          [49.125023, 32.881574],
          [49.124538, 32.882313],
          [49.123672, 32.88324],
          [49.122536, 32.884262],
          [49.121182, 32.885193],
          [49.120262, 32.885892],
          [49.11874, 32.886451],
          [49.117222, 32.887199],
          [49.115757, 32.887948],
          [49.114891, 32.888458],
          [49.114132, 32.88874],
          [49.113423, 32.888885],
          [49.112553, 32.888935],
          [49.111626, 32.888848],
          [49.110809, 32.888759],
          [49.109883, 32.888443],
          [49.108681, 32.888035],
          [49.107422, 32.887398],
          [49.105892, 32.886624],
          [49.10387, 32.885757],
          [49.102345, 32.885261],
          [49.100979, 32.885037],
          [49.099945, 32.884906],
          [49.098419, 32.88487],
          [49.09684, 32.884972],
          [49.095047, 32.885213],
          [49.093578, 32.885544],
          [49.092545, 32.885871],
          [49.091515, 32.886478],
          [49.090866, 32.886851],
          [49.09016, 32.887318],
          [49.089458, 32.887921],
          [49.088757, 32.888618],
          [49.088055, 32.889451],
          [49.087463, 32.890332],
          [49.087193, 32.890842],
          [49.087036, 32.891579],
          [49.086823, 32.892135],
          [49.086555, 32.892643],
          [49.085907, 32.893478],
          [49.085152, 32.894542],
          [49.084721, 32.895099],
          [49.084072, 32.89552],
          [49.08326, 32.896031],
          [49.08223, 32.896732],
          [49.080982, 32.897567],
          [49.080498, 32.897988],
          [49.08012, 32.898357],
          [49.079029, 32.898365],
          [49.078594, 32.898369],
          [49.078213, 32.89814],
          [49.076466, 32.897412],
          [49.074989, 32.896774],
          [49.073952, 32.89655],
          [49.072861, 32.896324],
          [49.07172, 32.896331],
          [49.070957, 32.896385],
          [49.070198, 32.896619],
          [49.069328, 32.897178],
          [49.06918, 32.897382],
          [49.06879, 32.897918],
          [49.06847, 32.898612],
          [49.068452, 32.898712],
          [49.068314, 32.899491],
          [49.06805, 32.900509],
          [49.067726, 32.901063],
          [49.067242, 32.901619],
          [49.066754, 32.901902],
          [49.06583, 32.902138],
          [49.06469, 32.902375],
          [49.063435, 32.902335],
          [49.061695, 32.902532],
          [49.06028, 32.902726],
          [49.058868, 32.903011],
          [49.057728, 32.903386],
          [49.057076, 32.903804],
          [49.05648, 32.904225],
          [49.055943, 32.904735],
          [49.055344, 32.905109],
          [49.054966, 32.905155],
          [49.054421, 32.905113],
          [49.053822, 32.905025],
          [49.053166, 32.904938],
          [49.052784, 32.904754],
          [49.052128, 32.904342],
          [49.051636, 32.904069],
          [49.050537, 32.902924],
          [49.04999, 32.902344],
          [49.049713, 32.90205],
          [49.048836, 32.90141],
          [49.047634, 32.900676],
          [49.046375, 32.899903],
          [49.045448, 32.899539],
          [49.045233, 32.899458],
          [49.044354, 32.899128],
          [49.042992, 32.89877],
          [49.041191, 32.898411],
          [49.039883, 32.898185],
          [49.038685, 32.898193],
          [49.037979, 32.89843],
          [49.037109, 32.898712],
          [49.036293, 32.898902],
          [49.035534, 32.899276],
          [49.03429, 32.899975],
          [49.033261, 32.900627],
          [49.031742, 32.901466],
          [49.030495, 32.902305],
          [49.029301, 32.902776],
          [49.028107, 32.903199],
          [49.027512, 32.903338],
          [49.026638, 32.903347],
          [49.025822, 32.903305],
          [49.024731, 32.903125],
          [49.023476, 32.902763],
          [49.022331, 32.90231],
          [49.020908, 32.901809],
          [49.020035, 32.901356],
          [49.019051, 32.900715],
          [49.018284, 32.900165],
          [49.017681, 32.899616],
          [49.017132, 32.899341],
          [49.016369, 32.899208],
          [49.016363, 32.899207],
          [49.015553, 32.89912],
          [49.0149, 32.899078],
          [49.014465, 32.89922],
          [49.013867, 32.899407],
          [49.013489, 32.899547],
          [49.012947, 32.899967],
          [49.012886, 32.900011],
          [49.012295, 32.900432],
          [49.0117, 32.900761],
          [49.011269, 32.901313],
          [49.010948, 32.902057],
          [49.010788, 32.902519],
          [49.010361, 32.903213],
          [49.009983, 32.903771],
          [49.009334, 32.904374],
          [49.008686, 32.905026],
          [49.00782, 32.90577],
          [49.006785, 32.906455],
          [49.006413, 32.906701],
          [49.00544, 32.907261],
          [49.004734, 32.907817],
          [49.004414, 32.908329],
          [49.004307, 32.908699],
          [49.004364, 32.909252],
          [49.004532, 32.909622],
          [49.00486, 32.909896],
          [49.005573, 32.910446],
          [49.006615, 32.911132],
          [49.007435, 32.911727],
          [49.007845, 32.91198],
          [49.007874, 32.911998],
          [49.007984, 32.912459],
          [49.008045, 32.912968],
          [49.008045, 32.913292],
          [49.007889, 32.913987],
          [49.007675, 32.914403],
          [49.007244, 32.914959],
          [49.006649, 32.915241],
          [49.006104, 32.915383],
          [49.005886, 32.915382],
          [49.005611, 32.915292],
          [49.005123, 32.915158],
          [49.004082, 32.914609],
          [49.003587, 32.914353],
          [49.002934, 32.914016],
          [49.001404, 32.913333],
          [49.000037, 32.913065],
          [48.998699, 32.912866],
          [48.998518, 32.912888],
          [48.99794, 32.91296],
          [48.997398, 32.913102],
          [48.996044, 32.913337],
          [48.995049, 32.913344],
          [48.994957, 32.913345],
          [48.994305, 32.913208],
          [48.993759, 32.913071],
          [48.993488, 32.912933],
          [48.992832, 32.912566],
          [48.992012, 32.911967],
          [48.991085, 32.911328],
          [48.99054, 32.911098],
          [48.990158, 32.911054],
          [48.989834, 32.911098],
          [48.98962, 32.911286],
          [48.989403, 32.911427],
          [48.989025, 32.911797],
          [48.988434, 32.912171],
          [48.98811, 32.912403],
          [48.987515, 32.912636],
          [48.986919, 32.912823],
          [48.986431, 32.912919],
          [48.98616, 32.912964],
          [48.985565, 32.912968],
          [48.985073, 32.912922],
          [48.984585, 32.912835],
          [48.984367, 32.912602],
          [48.983986, 32.912372],
          [48.983711, 32.912052],
          [48.983601, 32.91182],
          [48.983544, 32.91154],
          [48.98354, 32.91122],
          [48.983646, 32.910897],
          [48.98375, 32.910619],
          [48.983963, 32.910203],
          [48.98407, 32.909878],
          [48.98412, 32.909509],
          [48.984116, 32.908997],
          [48.984112, 32.90858],
          [48.984055, 32.908164],
          [48.983887, 32.907891],
          [48.983505, 32.907429],
          [48.983067, 32.907109],
          [48.982685, 32.906972],
          [48.98225, 32.906834],
          [48.981274, 32.906697],
          [48.980675, 32.906608],
          [48.979916, 32.906611],
          [48.979481, 32.906566],
          [48.979153, 32.90652],
          [48.978825, 32.906337],
          [48.9785, 32.906156],
          [48.978279, 32.905927],
          [48.978168, 32.905693],
          [48.978165, 32.905418],
          [48.978271, 32.904953],
          [48.978481, 32.904305],
          [48.978531, 32.903706],
          [48.978548, 32.903547],
          [48.978581, 32.903244],
          [48.978519, 32.902641],
          [48.978405, 32.902089],
          [48.978131, 32.901717],
          [48.977859, 32.901442],
          [48.977421, 32.901165],
          [48.976986, 32.900937],
          [48.976383, 32.900616],
          [48.975788, 32.90062],
          [48.974976, 32.900852],
          [48.974294, 32.900944],
          [48.97427, 32.900947],
          [48.9734, 32.900768],
          [48.972801, 32.90063],
          [48.971935, 32.900544],
          [48.971119, 32.900546],
          [48.970143, 32.900506],
          [48.96949, 32.900459],
          [48.969108, 32.900277],
          [48.968887, 32.899816],
          [48.968884, 32.899444],
          [48.968876, 32.898754],
          [48.968819, 32.89843],
          [48.968544, 32.898013],
          [48.968162, 32.897786],
          [48.967835, 32.897648],
          [48.967293, 32.897648],
          [48.966751, 32.897743],
          [48.966316, 32.897792],
          [48.965504, 32.897797],
          [48.964798, 32.897704],
          [48.964253, 32.897571],
          [48.963764, 32.89748],
          [48.963272, 32.897109],
          [48.962833, 32.896836],
          [48.962399, 32.896698],
          [48.961746, 32.896611],
          [48.961258, 32.896564],
          [48.96077, 32.896428],
          [48.960335, 32.896245],
          [48.960114, 32.896016],
          [48.959622, 32.895694],
          [48.958859, 32.895373],
          [48.958424, 32.89519],
          [48.958042, 32.895008],
          [48.957604, 32.894638],
          [48.957004, 32.894135],
          [48.956947, 32.894073],
          [48.956566, 32.893672],
          [48.956016, 32.893257],
          [48.955254, 32.8928],
          [48.954384, 32.89257],
          [48.953896, 32.892575],
          [48.953407, 32.892529],
          [48.952973, 32.892716],
          [48.952648, 32.892902],
          [48.952271, 32.893042],
          [48.951782, 32.893186],
          [48.951404, 32.893371],
          [48.95092, 32.893742],
          [48.949352, 32.894535],
          [48.948704, 32.894767],
          [48.948162, 32.894909],
          [48.947731, 32.895004],
          [48.947079, 32.895009],
          [48.94643, 32.895242],
          [48.945725, 32.895291],
          [48.9454, 32.895335],
          [48.945337, 32.895463],
          [48.94524, 32.89566],
          [48.94508, 32.895984],
          [48.944595, 32.896587],
          [48.944439, 32.897098],
          [48.944119, 32.897605],
          [48.943901, 32.897935],
          [48.943417, 32.898213],
          [48.942928, 32.898354],
          [48.942063, 32.898495],
          [48.941357, 32.898452],
          [48.940487, 32.898406],
          [48.939888, 32.89832],
          [48.939022, 32.898231],
          [48.938477, 32.898141],
          [48.937664, 32.898326],
          [48.937012, 32.898331],
          [48.936688, 32.898193],
          [48.936577, 32.897965],
          [48.936409, 32.89764],
          [48.936241, 32.897042],
          [48.936131, 32.896503],
          [48.936127, 32.896483],
          [48.935959, 32.895978],
          [48.935795, 32.895702],
          [48.935413, 32.895471],
          [48.935032, 32.895286],
          [48.93438, 32.895291],
          [48.933731, 32.895339],
          [48.933079, 32.895572],
          [48.932648, 32.895759],
          [48.932213, 32.89581],
          [48.931675, 32.89632],
          [48.93166, 32.896327],
          [48.93108, 32.896598],
          [48.930325, 32.896974],
          [48.929565, 32.897114],
          [48.928429, 32.897305],
          [48.92794, 32.897304],
          [48.927506, 32.897213],
          [48.927342, 32.89703],
          [48.927174, 32.896755],
          [48.927063, 32.896293],
          [48.926891, 32.895553],
          [48.926723, 32.89486],
          [48.926392, 32.894124],
          [48.925896, 32.893478],
          [48.925129, 32.892692],
          [48.924801, 32.892373],
          [48.924202, 32.892052],
          [48.923492, 32.891544],
          [48.922348, 32.89104],
          [48.921528, 32.890583],
          [48.920166, 32.889848],
          [48.919182, 32.889207],
          [48.918251, 32.888377],
          [48.917324, 32.887825],
          [48.916287, 32.887184],
          [48.915975, 32.886939],
          [48.915523, 32.886585],
          [48.914269, 32.885804],
          [48.913506, 32.885482],
          [48.913177, 32.885346],
          [48.912743, 32.88535],
          [48.912365, 32.88549],
          [48.911877, 32.885581],
          [48.911659, 32.885677],
          [48.911212, 32.886019],
          [48.911175, 32.886048],
          [48.910584, 32.886513],
          [48.909828, 32.887211],
          [48.90913, 32.887813],
          [48.908753, 32.888045],
          [48.908153, 32.888141],
          [48.907177, 32.888145],
          [48.906689, 32.888008],
          [48.905815, 32.887597],
          [48.904892, 32.887417],
          [48.904125, 32.886773],
          [48.903416, 32.88631],
          [48.902706, 32.885854],
          [48.902114, 32.885445],
          [48.902107, 32.88544],
          [48.901562, 32.885165],
          [48.90091, 32.885075],
          [48.900639, 32.884983],
          [48.900475, 32.884845],
          [48.900147, 32.884891],
          [48.899876, 32.884941],
          [48.899498, 32.885128],
          [48.89896, 32.88536],
          [48.898037, 32.88569],
          [48.897011, 32.886108],
          [48.896416, 32.886479],
          [48.896095, 32.886807],
          [48.895718, 32.88704],
          [48.894314, 32.887783],
          [48.893284, 32.888248],
          [48.892315, 32.888808],
          [48.891231, 32.889462],
          [48.890099, 32.89016],
          [48.889397, 32.890626],
          [48.8893, 32.89073],
          [48.888969, 32.891088],
          [48.888485, 32.891506],
          [48.887947, 32.89211],
          [48.887299, 32.892574],
          [48.886921, 32.892899],
          [48.88664, 32.892987],
          [48.886326, 32.893085],
          [48.885784, 32.893277],
          [48.885407, 32.893413],
          [48.884594, 32.893509],
          [48.883781, 32.893701],
          [48.88324, 32.893795],
          [48.882534, 32.893889],
          [48.882157, 32.893982],
          [48.881939, 32.894168],
          [48.881832, 32.894448],
          [48.881729, 32.894771],
          [48.88168, 32.895232],
          [48.881684, 32.895694],
          [48.881741, 32.896203],
          [48.881908, 32.896618],
          [48.882073, 32.896896],
          [48.882618, 32.897216],
          [48.883057, 32.897587],
          [48.883385, 32.897953],
          [48.883385, 32.897953],
          [48.883549, 32.898091],
          [48.883659, 32.89846],
          [48.883721, 32.898921],
          [48.883671, 32.899525],
          [48.883511, 32.899804],
          [48.883347, 32.900035],
          [48.883026, 32.900359],
          [48.882595, 32.900731],
          [48.882053, 32.901012],
          [48.881458, 32.901246],
          [48.880379, 32.901852],
          [48.879353, 32.902317],
          [48.878647, 32.902503],
          [48.877728, 32.902645],
          [48.876804, 32.90265],
          [48.87561, 32.902657],
          [48.874958, 32.902797],
          [48.873985, 32.903218],
          [48.873337, 32.90345],
          [48.872959, 32.903777],
          [48.872639, 32.904331],
          [48.872318, 32.904842],
          [48.872215, 32.905399],
          [48.872326, 32.905906],
          [48.872383, 32.906277],
          [48.872551, 32.906642],
          [48.872719, 32.907105],
          [48.872723, 32.907428],
          [48.872723, 32.907752],
          [48.872673, 32.908076],
          [48.872513, 32.9084],
          [48.872135, 32.908588],
          [48.871647, 32.908683],
          [48.871105, 32.908683],
          [48.870178, 32.90846],
          [48.869743, 32.908322],
          [48.868545, 32.907726],
          [48.867783, 32.907407],
          [48.867401, 32.907269],
          [48.866802, 32.907223],
          [48.86626, 32.907318],
          [48.865993, 32.907597],
          [48.865669, 32.907921],
          [48.865292, 32.908153],
          [48.864967, 32.908387],
          [48.864536, 32.908573],
          [48.864048, 32.908714],
          [48.863506, 32.908763],
          [48.862961, 32.90881],
          [48.862095, 32.908908],
          [48.860847, 32.908914],
          [48.858948, 32.909012],
          [48.85843, 32.909053],
          [48.857701, 32.90911],
          [48.856617, 32.909348],
          [48.855858, 32.909534],
          [48.855209, 32.909863],
          [48.854401, 32.910375],
          [48.853863, 32.910839],
          [48.853432, 32.911393],
          [48.853165, 32.911995],
          [48.853008, 32.912689],
          [48.853012, 32.913245],
          [48.852973, 32.913538],
          [48.852913, 32.913985],
          [48.852753, 32.914494],
          [48.852539, 32.914818],
          [48.852161, 32.915192],
          [48.85173, 32.915606],
          [48.850975, 32.915844],
          [48.850487, 32.915889],
          [48.850373, 32.915926],
          [48.850052, 32.916031],
          [48.849514, 32.91645],
          [48.849136, 32.916867],
          [48.848869, 32.917377],
          [48.84882, 32.917655],
          [48.848602, 32.917888],
          [48.848442, 32.918118],
          [48.848282, 32.918536],
          [48.848232, 32.918813],
          [48.848297, 32.919968],
          [48.848297, 32.920246],
          [48.848301, 32.920428],
          [48.848354, 32.920616],
          [48.848686, 32.921123],
          [48.849289, 32.921996],
          [48.849617, 32.922322],
          [48.849731, 32.922737],
          [48.849789, 32.923062],
          [48.849686, 32.923417],
          [48.849682, 32.923432],
          [48.849632, 32.923939],
          [48.849525, 32.924264],
          [48.849312, 32.924587],
          [48.849152, 32.924774],
          [48.84872, 32.925008],
          [48.848679, 32.925027],
          [48.848232, 32.92524],
          [48.847477, 32.925523],
          [48.846771, 32.925892],
          [48.846504, 32.926033],
          [48.846233, 32.926266],
          [48.846073, 32.926682],
          [48.84597, 32.927143],
          [48.845863, 32.927562],
          [48.845871, 32.9283],
          [48.846035, 32.929187],
          [48.846043, 32.929227],
          [48.846153, 32.929734],
          [48.846157, 32.930241],
          [48.846001, 32.930655],
          [48.845677, 32.930844],
          [48.845078, 32.930847],
          [48.844536, 32.930851],
          [48.844208, 32.930715],
          [48.843609, 32.930486],
          [48.843121, 32.930256],
          [48.842581, 32.930007],
          [48.842518, 32.929978],
          [48.842087, 32.929936],
          [48.841488, 32.929985],
          [48.841164, 32.930123],
          [48.840733, 32.930265],
          [48.840355, 32.930542],
          [48.839813, 32.930871],
          [48.839382, 32.931382],
          [48.839062, 32.931661],
          [48.838902, 32.932075],
          [48.838848, 32.932353],
          [48.838634, 32.932679],
          [48.838368, 32.933139],
          [48.838318, 32.933742],
          [48.838375, 32.934158],
          [48.838486, 32.934433],
          [48.838818, 32.935036],
          [48.839474, 32.935773],
          [48.840023, 32.936416],
          [48.840298, 32.936879],
          [48.840519, 32.937295],
          [48.84058, 32.937801],
          [48.840594, 32.937867],
          [48.840691, 32.938308],
          [48.840588, 32.938815],
          [48.840538, 32.939327],
          [48.840378, 32.939743],
          [48.840218, 32.940067],
          [48.839893, 32.940346],
          [48.839516, 32.940623],
          [48.838978, 32.940812],
          [48.838597, 32.940815],
          [48.838162, 32.940724],
          [48.837723, 32.940448],
          [48.837071, 32.940083],
          [48.836361, 32.939622],
          [48.835705, 32.939347],
          [48.835052, 32.939164],
          [48.834511, 32.939213],
          [48.834187, 32.939309],
          [48.834051, 32.939403],
          [48.833916, 32.939496],
          [48.833374, 32.939683],
          [48.83289, 32.939868],
          [48.832294, 32.940101],
          [48.8317, 32.940567],
          [48.831162, 32.941123],
          [48.83057, 32.941726],
          [48.829869, 32.942424],
          [48.829548, 32.942653],
          [48.829334, 32.943073],
          [48.829228, 32.943443],
          [48.829231, 32.943858],
          [48.829342, 32.944275],
          [48.82962, 32.94492],
          [48.830116, 32.945797],
          [48.830772, 32.946579],
          [48.831051, 32.947132],
          [48.831075, 32.947202],
          [48.831162, 32.947456],
          [48.831112, 32.947827],
          [48.831005, 32.948105],
          [48.830898, 32.948337],
          [48.830738, 32.948522],
          [48.830303, 32.948753],
          [48.829819, 32.948941],
          [48.829494, 32.949127],
          [48.829224, 32.949314],
          [48.828846, 32.949501],
          [48.828522, 32.949642],
          [48.828251, 32.949874],
          [48.827927, 32.950107],
          [48.827331, 32.950433],
          [48.826576, 32.950805],
          [48.826145, 32.951084],
          [48.825657, 32.951179],
          [48.824734, 32.951321],
          [48.824303, 32.951552],
          [48.823544, 32.951699],
          [48.822784, 32.951839],
          [48.822243, 32.951981],
          [48.821537, 32.95198],
          [48.821102, 32.951751],
          [48.820446, 32.951431],
          [48.819737, 32.950927],
          [48.819332, 32.95074],
          [48.819244, 32.9507],
          [48.81881, 32.950607],
          [48.818161, 32.950656],
          [48.817783, 32.950888],
          [48.817082, 32.951492],
          [48.816965, 32.951629],
          [48.81633, 32.952374],
          [48.815899, 32.953022],
          [48.815147, 32.953857],
          [48.814339, 32.954372],
          [48.813637, 32.954838],
          [48.813042, 32.955441],
          [48.812614, 32.955902],
          [48.812344, 32.956231],
          [48.811913, 32.956692],
          [48.811211, 32.957158],
          [48.810562, 32.95744],
          [48.809696, 32.957625],
          [48.80883, 32.957817],
          [48.808235, 32.958051],
          [48.807693, 32.958327],
          [48.807262, 32.958653],
          [48.806995, 32.959118],
          [48.806942, 32.959489],
          [48.806565, 32.959629],
          [48.806347, 32.959629],
          [48.806076, 32.959629],
          [48.805801, 32.959491],
          [48.805313, 32.959354],
          [48.80455, 32.959129],
          [48.803681, 32.958854],
          [48.802811, 32.958717],
          [48.802105, 32.958721],
          [48.801506, 32.958771],
          [48.800911, 32.959004],
          [48.800426, 32.959376],
          [48.799778, 32.959843],
          [48.799294, 32.959933],
          [48.798477, 32.959892],
          [48.797661, 32.959758],
          [48.797279, 32.959481],
          [48.796459, 32.958885],
          [48.795639, 32.958194],
          [48.794876, 32.957966],
          [48.794064, 32.957833],
          [48.79303, 32.957837],
          [48.792107, 32.957931],
          [48.791622, 32.958261],
          [48.791302, 32.958767],
          [48.790924, 32.959141],
          [48.790546, 32.959324],
          [48.790218, 32.959327],
          [48.790054, 32.959094],
          [48.789074, 32.958732],
          [48.788204, 32.958457],
          [48.787499, 32.958413],
          [48.786957, 32.958554],
          [48.786358, 32.958602],
          [48.785599, 32.958699],
          [48.785221, 32.958839],
          [48.78479, 32.959025],
          [48.784626, 32.959117],
          [48.784138, 32.959305],
          [48.783165, 32.959587],
          [48.782459, 32.95987],
          [48.78154, 32.960289],
          [48.780514, 32.960755],
          [48.779865, 32.961312],
          [48.779221, 32.961823],
          [48.778736, 32.962196],
          [48.777924, 32.962799],
          [48.776955, 32.963684],
          [48.776631, 32.963962],
          [48.77647, 32.964333],
          [48.776257, 32.964424],
          [48.775986, 32.964473],
          [48.775604, 32.964336],
          [48.775112, 32.964154],
          [48.77446, 32.963971],
          [48.773972, 32.963807],
          [48.773915, 32.963787],
          [48.773319, 32.96379],
          [48.77272, 32.963795],
          [48.772179, 32.963937],
          [48.771748, 32.964168],
          [48.77137, 32.964538],
          [48.771156, 32.964863],
          [48.770996, 32.965279],
          [48.770729, 32.965791],
          [48.770351, 32.9663],
          [48.770255, 32.966415],
          [48.769653, 32.967136],
          [48.769169, 32.967461],
          [48.768303, 32.967835],
          [48.768218, 32.967845],
          [48.76749, 32.967929],
          [48.767056, 32.967935],
          [48.766514, 32.968074],
          [48.765862, 32.968215],
          [48.764971, 32.968345],
          [48.764885, 32.968358],
          [48.76429, 32.968361],
          [48.763802, 32.968316],
          [48.76342, 32.968178],
          [48.762821, 32.967949],
          [48.76244, 32.967674],
          [48.762219, 32.967444],
          [48.761997, 32.96712],
          [48.762051, 32.966752],
          [48.762157, 32.966427],
          [48.762314, 32.965965],
          [48.762353, 32.965926],
          [48.762962, 32.965314],
          [48.763447, 32.964757],
          [48.76371, 32.964062],
          [48.764034, 32.963737],
          [48.764679, 32.962948],
          [48.765324, 32.962157],
          [48.765862, 32.961373],
          [48.766289, 32.960678],
          [48.766449, 32.960121],
          [48.766388, 32.959336],
          [48.766109, 32.958828],
          [48.765781, 32.958412],
          [48.765453, 32.957951],
          [48.764851, 32.957538],
          [48.764088, 32.956986],
          [48.762833, 32.956208],
          [48.761848, 32.955471],
          [48.761135, 32.95469],
          [48.760586, 32.953857],
          [48.760307, 32.952935],
          [48.7603, 32.952285],
          [48.760406, 32.951825],
          [48.760674, 32.951176],
          [48.761265, 32.950526],
          [48.76202, 32.949967],
          [48.762665, 32.949318],
          [48.76326, 32.948806],
          [48.764172, 32.947693],
          [48.765034, 32.946626],
          [48.765457, 32.94556],
          [48.765778, 32.94473],
          [48.765877, 32.943848],
          [48.76598, 32.943153],
          [48.766025, 32.941952],
          [48.765961, 32.940937],
          [48.766007, 32.939963],
          [48.765945, 32.938946],
          [48.765774, 32.938023],
          [48.765606, 32.937332],
          [48.765652, 32.936359],
          [48.765648, 32.935897],
          [48.765755, 32.935527],
          [48.765915, 32.935108],
          [48.766342, 32.934505],
          [48.766934, 32.933578],
          [48.767521, 32.932743],
          [48.768112, 32.931678],
          [48.768482, 32.930844],
          [48.768963, 32.929921],
          [48.769337, 32.928852],
          [48.769436, 32.927882],
          [48.769589, 32.926494],
          [48.769577, 32.925202],
          [48.769241, 32.924229],
          [48.768967, 32.923584],
          [48.768688, 32.922986],
          [48.767975, 32.922063],
          [48.767425, 32.921327],
          [48.766663, 32.920727],
          [48.766117, 32.920314],
          [48.765243, 32.919899],
          [48.76421, 32.91972],
          [48.76334, 32.919585],
          [48.762421, 32.919637],
          [48.761661, 32.919686],
          [48.759598, 32.919879],
          [48.758461, 32.920163],
          [48.75716, 32.920212],
          [48.757115, 32.920218],
          [48.756508, 32.920306],
          [48.755802, 32.920124],
          [48.755146, 32.919849],
          [48.754605, 32.919452],
          [48.754272, 32.919209],
          [48.753345, 32.918473],
          [48.751446, 32.917111],
          [48.750601, 32.916861],
          [48.750003, 32.916683],
          [48.748577, 32.916191],
          [48.747807, 32.915134],
          [48.746376, 32.91357],
          [48.74588, 32.912373],
          [48.745602, 32.911498],
          [48.745602, 32.91105],
          [48.745602, 32.910808],
          [48.745708, 32.91021],
          [48.745815, 32.90952],
          [48.745922, 32.908832],
          [48.745976, 32.908416],
          [48.745865, 32.908002],
          [48.745426, 32.907588],
          [48.744328, 32.906853],
          [48.743725, 32.906482],
          [48.743286, 32.906115],
          [48.743012, 32.905518],
          [48.742516, 32.904735],
          [48.741745, 32.903908],
          [48.740814, 32.903217],
          [48.740154, 32.902619],
          [48.739933, 32.902114],
          [48.739495, 32.901195],
          [48.739109, 32.900413],
          [48.73867, 32.899907],
          [48.738064, 32.899632],
          [48.737354, 32.899491],
          [48.736587, 32.899445],
          [48.735657, 32.899582],
          [48.734837, 32.899673],
          [48.734345, 32.899997],
          [48.733909, 32.900689],
          [48.733639, 32.90147],
          [48.733639, 32.902127],
          [48.733639, 32.902343],
          [48.733532, 32.902986],
          [48.733479, 32.903492],
          [48.733151, 32.904],
          [48.732716, 32.904091],
          [48.731838, 32.904184],
          [48.730743, 32.904092],
          [48.729759, 32.90395],
          [48.72921, 32.903676],
          [48.728603, 32.902984],
          [48.727837, 32.902204],
          [48.727177, 32.901191],
          [48.726517, 32.900455],
          [48.725857, 32.899627],
          [48.72509, 32.899257],
          [48.724167, 32.898795],
          [48.723995, 32.898709],
          [48.723228, 32.898522],
          [48.722462, 32.898521],
          [48.721857, 32.898567],
          [48.721256, 32.898612],
          [48.720928, 32.898658],
          [48.72049, 32.898567],
          [48.720051, 32.898385],
          [48.719887, 32.898154],
          [48.719555, 32.897603],
          [48.719322, 32.897176],
          [48.718952, 32.896501],
          [48.718758, 32.896249],
          [48.718581, 32.89602],
          [48.718481, 32.896201],
          [48.718196, 32.896627],
          [48.715786, 32.900223],
          [48.71426, 32.902982],
          [48.712902, 32.905646],
          [48.711761, 32.909192],
          [48.711003, 32.912224],
          [48.710468, 32.917374],
          [48.710037, 32.92041],
          [48.709778, 32.924824],
          [48.709753, 32.928093],
          [48.709736, 32.930344],
          [48.709419, 32.935264],
          [48.709316, 32.936459],
          [48.70784, 32.937656],
          [48.705322, 32.939217],
          [48.702481, 32.941239],
          [48.699799, 32.942849],
          [48.697559, 32.94418],
          [48.695206, 32.945052],
          [48.692086, 32.946521],
          [48.690555, 32.947305],
          [48.689568, 32.947811],
          [48.687271, 32.949051],
          [48.684754, 32.94997],
          [48.681633, 32.950841],
          [48.678402, 32.951806],
          [48.675118, 32.953411],
          [48.67178, 32.955204],
          [48.668881, 32.956814],
          [48.666145, 32.958557],
          [48.660946, 32.962096],
          [48.65728, 32.964394],
          [48.653668, 32.966412],
          [48.64819, 32.968892],
          [48.644085, 32.970593],
          [48.640304, 32.972244],
          [48.636196, 32.973483],
          [48.632473, 32.974725],
          [48.629406, 32.97541],
          [48.623924, 32.9766],
          [48.615433, 32.978573],
          [48.609406, 32.97967],
          [48.604748, 32.980449],
          [48.603157, 32.980358],
          [48.602501, 32.980079],
          [48.601952, 32.97976],
          [48.601021, 32.978978],
          [48.598934, 32.976631],
          [48.596245, 32.973827],
          [48.594711, 32.972585],
          [48.593231, 32.971939],
          [48.592079, 32.971436],
          [48.590816, 32.970974],
          [48.59005, 32.970928],
          [48.589119, 32.970973],
          [48.587585, 32.971385],
          [48.584023, 32.972579],
          [48.575916, 32.975884],
          [48.572713, 32.977311],
          [48.570873, 32.978131],
          [48.565178, 32.980335],
          [48.561176, 32.981571],
          [48.557999, 32.982305],
          [48.55394, 32.983037],
          [48.552025, 32.983357],
          [48.549721, 32.983723],
          [48.547199, 32.98363],
          [48.544899, 32.983308],
          [48.543034, 32.982937],
          [48.541336, 32.982295],
          [48.539692, 32.98156],
          [48.537826, 32.980774],
          [48.536674, 32.980087],
          [48.535194, 32.979027],
          [48.534046, 32.978015],
          [48.53207, 32.976269],
          [48.528839, 32.974014],
          [48.526592, 32.972638],
          [48.522994, 32.970265],
          [48.522263, 32.969784],
          [48.517494, 32.967072],
          [48.514317, 32.965462],
          [48.50988, 32.963622],
          [48.507141, 32.962287],
          [48.504566, 32.96169],
          [48.502209, 32.961505],
          [48.499612, 32.961203],
          [48.492817, 32.962636],
          [48.487793, 32.963833],
          [48.483204, 32.96494],
          [48.480846, 32.965195],
          [48.479038, 32.96539],
          [48.475472, 32.965882],
          [48.4739, 32.9658],
          [48.469784, 32.965694],
          [48.46751, 32.965851],
          [48.466053, 32.966281],
          [48.462925, 32.967232],
          [48.459358, 32.968048],
          [48.456116, 32.968494],
          [48.453896, 32.968557],
          [48.449619, 32.968686],
          [48.44458, 32.968677],
          [48.439918, 32.968205],
          [48.436554, 32.967816],
          [48.434765, 32.967599],
          [48.432648, 32.967057],
          [48.43047, 32.966239],
          [48.427532, 32.965198],
          [48.425903, 32.964703],
          [48.423843, 32.964671],
          [48.422586, 32.964731],
          [48.421246, 32.964787],
          [48.417297, 32.965188],
          [48.413456, 32.965588],
          [48.409721, 32.965714],
          [48.406414, 32.965552],
          [48.403956, 32.965806],
          [48.403008, 32.965903],
          [48.397644, 32.965762],
          [48.393421, 32.965701],
          [48.389679, 32.965454],
          [48.386108, 32.965855],
          [48.383354, 32.96634],
          [48.380604, 32.967198],
          [48.378235, 32.968327],
          [48.37635, 32.969315],
          [48.374084, 32.970027],
          [48.371601, 32.970741],
          [48.369659, 32.971176],
          [48.366425, 32.972501],
          [48.361794, 32.974434],
          [48.3591, 32.975617],
          [48.357536, 32.976231],
          [48.355591, 32.976525],
          [48.352726, 32.977195],
          [48.347164, 32.978488],
          [48.343822, 32.97958],
          [48.341068, 32.980389],
          [48.340149, 32.980678],
          [48.337887, 32.981387],
          [48.335243, 32.982152],
          [48.334003, 32.982761],
          [48.332336, 32.983609],
          [48.330288, 32.984412],
          [48.328182, 32.98494],
          [48.326618, 32.985463],
          [48.325378, 32.986117],
          [48.323174, 32.987156],
          [48.320423, 32.98829],
          [48.313469, 32.990658],
          [48.306835, 32.992981],
          [48.304836, 32.993553],
          [48.302406, 32.994125],
          [48.299061, 32.994941],
          [48.296196, 32.995609],
          [48.29306, 32.99596],
          [48.290138, 32.996215],
          [48.288136, 32.996277],
          [48.286949, 32.996563],
          [48.285542, 32.996807],
          [48.282741, 32.997001],
          [48.281811, 32.997066],
          [48.279697, 32.997128],
          [48.277584, 32.996913],
          [48.275955, 32.996697],
          [48.275902, 32.996651],
          [48.274597, 32.996334],
          [48.273617, 32.995742],
          [48.273014, 32.995377],
          [48.272419, 32.995243],
          [48.271057, 32.994602],
          [48.269962, 32.99387],
          [48.268925, 32.993099],
          [48.268486, 32.992772],
          [48.26614, 32.991215],
          [48.263519, 32.989708],
          [48.262051, 32.988934],
          [48.261, 32.988378],
          [48.260414, 32.988069],
          [48.259319, 32.987195],
          [48.258007, 32.98614],
          [48.256912, 32.985084],
          [48.2551, 32.983155],
          [48.253178, 32.981042],
          [48.251427, 32.979482],
          [48.249947, 32.978103],
          [48.24965, 32.976574],
          [48.249139, 32.973943],
          [48.247082, 32.971465],
          [48.245125, 32.968296],
          [48.244129, 32.967381],
          [48.240486, 32.964397],
          [48.238666, 32.962975],
          [48.235844, 32.959987],
          [48.234812, 32.959166],
          [48.23452, 32.958934],
          [48.232102, 32.957375],
          [48.230396, 32.956089],
          [48.228687, 32.954716],
          [48.227306, 32.953475],
          [48.225426, 32.951317],
          [48.222599, 32.947918],
          [48.220554, 32.945713],
          [48.218948, 32.943879],
          [48.216465, 32.941303],
          [48.213486, 32.938413],
          [48.210346, 32.935521],
          [48.207363, 32.932262],
          [48.203941, 32.928401],
          [48.200688, 32.925006],
          [48.1992, 32.923539],
          [48.197373, 32.920917],
          [48.19434, 32.917565],
          [48.191467, 32.913754],
          [48.187996, 32.909896],
          [48.186169, 32.907001],
          [48.183361, 32.904015],
          [48.182057, 32.902673],
          [48.181709, 32.902316],
          [48.179688, 32.900941],
          [48.178047, 32.900162],
          [48.176743, 32.899754],
          [48.175549, 32.899756],
          [48.171864, 32.90004],
          [48.169102, 32.900555],
          [48.165749, 32.901576],
          [48.165225, 32.901846],
          [48.164174, 32.902386],
          [48.163322, 32.902824],
          [48.161003, 32.904029],
          [48.159493, 32.904998],
          [48.158608, 32.905983],
          [48.158207, 32.906429],
          [48.157574, 32.907952],
          [48.15548, 32.910028],
          [48.153706, 32.911736],
          [48.151505, 32.913902],
          [48.149673, 32.915291],
          [48.148491, 32.916581],
          [48.147305, 32.917275],
          [48.145683, 32.918061],
          [48.143681, 32.918804],
          [48.141518, 32.919498],
          [48.139725, 32.919734],
          [48.138535, 32.919876],
          [48.137665, 32.919881],
          [48.136849, 32.919743],
          [48.135651, 32.919377],
          [48.134117, 32.918323],
          [48.132748, 32.917176],
          [48.13105, 32.915661],
          [48.129513, 32.913917],
          [48.12809, 32.912633],
          [48.126549, 32.91061],
          [48.123852, 32.906612],
          [48.122585, 32.904636],
          [48.120606, 32.901741],
          [48.119507, 32.900181],
          [48.118683, 32.899032],
          [48.118733, 32.898479],
          [48.119099, 32.897189],
          [48.119469, 32.896175],
          [48.120216, 32.894794],
          [48.121231, 32.893089],
          [48.121979, 32.892024],
          [48.123051, 32.890507],
          [48.124397, 32.88935],
          [48.124547, 32.888992],
          [48.124821, 32.888337],
          [48.125355, 32.887463],
          [48.125565, 32.886632],
          [48.125355, 32.885995],
          [48.125172, 32.885437],
          [48.125385, 32.884883],
          [48.125759, 32.884496],
          [48.125919, 32.88433],
          [48.126621, 32.883915],
          [48.127316, 32.882992],
          [48.12801, 32.881653],
          [48.128594, 32.880409],
          [48.128532, 32.879582],
          [48.128525, 32.87889],
          [48.128567, 32.877739],
          [48.128559, 32.876682],
          [48.128277, 32.875718],
          [48.128208, 32.874291],
          [48.128525, 32.873321],
          [48.128677, 32.872402],
          [48.128883, 32.871296],
          [48.128822, 32.870469],
          [48.128277, 32.87024],
          [48.127625, 32.869919],
          [48.126381, 32.869969],
          [48.125675, 32.870061],
          [48.124699, 32.869881],
          [48.123993, 32.869378],
          [48.123661, 32.868915],
          [48.123005, 32.868228],
          [48.122623, 32.867676],
          [48.121964, 32.866802],
          [48.120766, 32.866069],
          [48.119537, 32.865538],
          [48.118755, 32.8652],
          [48.117336, 32.863964],
          [48.116135, 32.862998],
          [48.115154, 32.862451],
          [48.114601, 32.860885],
          [48.113892, 32.860291],
          [48.11415, 32.859774],
          [48.114422, 32.859231],
          [48.114143, 32.858311],
          [48.114132, 32.857022],
          [48.1138, 32.855964],
          [48.113518, 32.854812],
          [48.112755, 32.854125],
          [48.111935, 32.852977],
          [48.110462, 32.851875],
          [48.109261, 32.850589],
          [48.107357, 32.849263],
          [48.106936, 32.849103],
          [48.10627, 32.84885],
          [48.105236, 32.848024],
          [48.104309, 32.846965],
          [48.104084, 32.845955],
          [48.104504, 32.844574],
          [48.105194, 32.84296],
          [48.105026, 32.842224],
          [48.104908, 32.841026],
          [48.104355, 32.83965],
          [48.103203, 32.837811],
          [48.101837, 32.835927],
          [48.100529, 32.834411],
          [48.098248, 32.833085],
          [48.096615, 32.831845],
          [48.093956, 32.830242],
          [48.089615, 32.828137],
          [48.080555, 32.822868],
          [48.068035, 32.815998],
          [48.057945, 32.810181],
          [48.057566, 32.809962],
          [48.057459, 32.809901],
          [48.056278, 32.80922],
          [48.049889, 32.806194],
          [48.049027, 32.805785],
          [48.048809, 32.805417],
          [48.049023, 32.805095],
          [48.049996, 32.804954],
          [48.050153, 32.804961],
          [48.050968, 32.804997],
          [48.051777, 32.804906],
          [48.052635, 32.804486],
          [48.053604, 32.803795],
          [48.054409, 32.803239],
          [48.054676, 32.802456],
          [48.054833, 32.801583],
          [48.054722, 32.800659],
          [48.054447, 32.799785],
          [48.054386, 32.799096],
          [48.054361, 32.799026],
          [48.054222, 32.798637],
          [48.054054, 32.797535],
          [48.054207, 32.795278],
          [48.054367, 32.794605],
          [48.05447, 32.794171],
          [48.05452, 32.793525],
          [48.054512, 32.792053],
          [48.054344, 32.791226],
          [48.054456, 32.790338],
          [48.054619, 32.789628],
          [48.055005, 32.78795],
          [48.055087, 32.787869],
          [48.055187, 32.787266],
          [48.055237, 32.786622],
          [48.055344, 32.786255],
          [48.055557, 32.786159],
          [48.055935, 32.786388],
          [48.056259, 32.786712],
          [48.056509, 32.78698],
          [48.056858, 32.787355],
          [48.057186, 32.788135],
          [48.057838, 32.789467],
          [48.058437, 32.790157],
          [48.058979, 32.790751],
          [48.059197, 32.790984],
          [48.060547, 32.791347],
          [48.06168, 32.791435],
          [48.06292, 32.791664],
          [48.063892, 32.79166],
          [48.065403, 32.791565],
          [48.066155, 32.791332],
          [48.067284, 32.79073],
          [48.067764, 32.790268],
          [48.068085, 32.789669],
          [48.068241, 32.788886],
          [48.068237, 32.788334],
          [48.067909, 32.7876],
          [48.067528, 32.786541],
          [48.067036, 32.785669],
          [48.065949, 32.784381],
          [48.064056, 32.782871],
          [48.062264, 32.781033],
          [48.060154, 32.779523],
          [48.059506, 32.778969],
          [48.058693, 32.77842],
          [48.05849, 32.778334],
          [48.057831, 32.778053],
          [48.056912, 32.777686],
          [48.05621, 32.777321],
          [48.055126, 32.77659],
          [48.054802, 32.776034],
          [48.054581, 32.775531],
          [48.054253, 32.774196],
          [48.05381, 32.772082],
          [48.053768, 32.770679],
          [48.053742, 32.769824],
          [48.05357, 32.767758],
          [48.05373, 32.767205],
          [48.054051, 32.767016],
          [48.054482, 32.76688],
          [48.054966, 32.766968],
          [48.055451, 32.767151],
          [48.056099, 32.767612],
          [48.056534, 32.767886],
          [48.057236, 32.76839],
          [48.05756, 32.76862],
          [48.058045, 32.768803],
          [48.058747, 32.768752],
          [48.0602, 32.76852],
          [48.061493, 32.768562],
          [48.062782, 32.768603],
          [48.063538, 32.768513],
          [48.064449, 32.76823],
          [48.064987, 32.767864],
          [48.066113, 32.767124],
          [48.067024, 32.766521],
          [48.067616, 32.765877],
          [48.068092, 32.76509],
          [48.06868, 32.764263],
          [48.068783, 32.763339],
          [48.068668, 32.762421],
          [48.068398, 32.761872],
          [48.068176, 32.761364],
          [48.067852, 32.760906],
          [48.066986, 32.760448],
          [48.066395, 32.760265],
          [48.065586, 32.760174],
          [48.064617, 32.760178],
          [48.062893, 32.759906],
          [48.062622, 32.759918],
          [48.06171, 32.759957],
          [48.060955, 32.75982],
          [48.059609, 32.759502],
          [48.059013, 32.759042],
          [48.058792, 32.757984],
          [48.058838, 32.756878],
          [48.058927, 32.756628],
          [48.059315, 32.755544],
          [48.060013, 32.754807],
          [48.061028, 32.754112],
          [48.062366, 32.752866],
          [48.063221, 32.751576],
          [48.063644, 32.750743],
          [48.063501, 32.75012],
          [48.06345, 32.749183],
          [48.063442, 32.748586],
          [48.063915, 32.747657],
          [48.064121, 32.746963],
          [48.064541, 32.746177],
          [48.064747, 32.745483],
          [48.064899, 32.744743],
          [48.064892, 32.744236],
          [48.06461, 32.743596],
          [48.064331, 32.743184],
          [48.063884, 32.742938],
          [48.063839, 32.742913],
          [48.063183, 32.742641],
          [48.062527, 32.742465],
          [48.061821, 32.742379],
          [48.061169, 32.742386],
          [48.06041, 32.742303],
          [48.060078, 32.741982],
          [48.059853, 32.741523],
          [48.059792, 32.74097],
          [48.059998, 32.740373],
          [48.060963, 32.739624],
          [48.061689, 32.739235],
          [48.063599, 32.738212],
          [48.064842, 32.737831],
          [48.065601, 32.737682],
          [48.066414, 32.737584],
          [48.067116, 32.737301],
          [48.067814, 32.737015],
          [48.068459, 32.73655],
          [48.068619, 32.736407],
          [48.068668, 32.736039],
          [48.068665, 32.735714],
          [48.06844, 32.735211],
          [48.068111, 32.735077],
          [48.067783, 32.73508],
          [48.067348, 32.735084],
          [48.067074, 32.734902],
          [48.066746, 32.73454],
          [48.066655, 32.734079],
          [48.066628, 32.73394],
          [48.066616, 32.73325],
          [48.066548, 32.732509],
          [48.066536, 32.731774],
          [48.066578, 32.731038],
          [48.066944, 32.730201],
          [48.067539, 32.729965],
          [48.067811, 32.729926],
          [48.068569, 32.729816],
          [48.070087, 32.729709],
          [48.071499, 32.729832],
          [48.072319, 32.730144],
          [48.072868, 32.730556],
          [48.074287, 32.731094],
          [48.075764, 32.731679],
          [48.077671, 32.732167],
          [48.078594, 32.732155],
          [48.079304, 32.732329],
          [48.080063, 32.732232],
          [48.080547, 32.731948],
          [48.080975, 32.731579],
          [48.081127, 32.730929],
          [48.081543, 32.729911],
          [48.081963, 32.728893],
          [48.081951, 32.728111],
          [48.08194, 32.727376],
          [48.0816, 32.726592],
          [48.081429, 32.726043],
          [48.081421, 32.725491],
          [48.081634, 32.725259],
          [48.081955, 32.725068],
          [48.082497, 32.725063],
          [48.082878, 32.72506],
          [48.083584, 32.725052],
          [48.083908, 32.725048],
          [48.084339, 32.72472],
          [48.084599, 32.72398],
          [48.08458, 32.722877],
          [48.084568, 32.722093],
          [48.084938, 32.721581],
          [48.085415, 32.720749],
          [48.085949, 32.720096],
          [48.086048, 32.719682],
          [48.086044, 32.719406],
          [48.085983, 32.718945],
          [48.085869, 32.718483],
          [48.085419, 32.717704],
          [48.084866, 32.717019],
          [48.084206, 32.716472],
          [48.08355, 32.716109],
          [48.083042, 32.716079],
          [48.082951, 32.716073],
          [48.082024, 32.715806],
          [48.080284, 32.715684],
          [48.078659, 32.715885],
          [48.076599, 32.716182],
          [48.075359, 32.716656],
          [48.074116, 32.716854],
          [48.073246, 32.716771],
          [48.07243, 32.716731],
          [48.07188, 32.716417],
          [48.071007, 32.716103],
          [48.070564, 32.715417],
          [48.070339, 32.715003],
          [48.070107, 32.714085],
          [48.069881, 32.713532],
          [48.069653, 32.712794],
          [48.069267, 32.712479],
          [48.068665, 32.712162],
          [48.067898, 32.711845],
          [48.066975, 32.711765],
          [48.066158, 32.71159],
          [48.065235, 32.711598],
          [48.064583, 32.71156],
          [48.064091, 32.711334],
          [48.063812, 32.710967],
          [48.063698, 32.710414],
          [48.063793, 32.70963],
          [48.063995, 32.708751],
          [48.064503, 32.708266],
          [48.064529, 32.708241],
          [48.065662, 32.707722],
          [48.067715, 32.7071],
          [48.069336, 32.70676],
          [48.07058, 32.706428],
          [48.071819, 32.705722],
          [48.072731, 32.704975],
          [48.073746, 32.704045],
          [48.074436, 32.703209],
          [48.074856, 32.702236],
          [48.075337, 32.701583],
          [48.075977, 32.70098],
          [48.076466, 32.700928],
          [48.077171, 32.70092],
          [48.077606, 32.701056],
          [48.078152, 32.701234],
          [48.078922, 32.701874],
          [48.079853, 32.702324],
          [48.080467, 32.703285],
          [48.081406, 32.704289],
          [48.082172, 32.704697],
          [48.082607, 32.704876],
          [48.083263, 32.705146],
          [48.084457, 32.705136],
          [48.085274, 32.705128],
          [48.085922, 32.704983],
          [48.086563, 32.704285],
          [48.086601, 32.703452],
          [48.08675, 32.702439],
          [48.087055, 32.701283],
          [48.087479, 32.700542],
          [48.088005, 32.699525],
          [48.08897, 32.698731],
          [48.089554, 32.697803],
          [48.090027, 32.696923],
          [48.090397, 32.696228],
          [48.090439, 32.69563],
          [48.090321, 32.694847],
          [48.090195, 32.69397],
          [48.089954, 32.692547],
          [48.089726, 32.691766],
          [48.089169, 32.690894],
          [48.088612, 32.68998],
          [48.088314, 32.689622],
          [48.088116, 32.689384],
          [48.087399, 32.688885],
          [48.086529, 32.688664],
          [48.085384, 32.688489],
          [48.084675, 32.688267],
          [48.084023, 32.688043],
          [48.083366, 32.687863],
          [48.081837, 32.68737],
          [48.079819, 32.686653],
          [48.076763, 32.685764],
          [48.075623, 32.685589],
          [48.074909, 32.685272],
          [48.074581, 32.685048],
          [48.074463, 32.68454],
          [48.074455, 32.684033],
          [48.074394, 32.683484],
          [48.074432, 32.682698],
          [48.074492, 32.682117],
          [48.074528, 32.681774],
          [48.074516, 32.681129],
          [48.073856, 32.680447],
          [48.073727, 32.679294],
          [48.073227, 32.678608],
          [48.073059, 32.678242],
          [48.072994, 32.677598],
          [48.072769, 32.67709],
          [48.071896, 32.676686],
          [48.070919, 32.676693],
          [48.070098, 32.676638],
          [48.069721, 32.676613],
          [48.068798, 32.676483],
          [48.067654, 32.67622],
          [48.066887, 32.675857],
          [48.065842, 32.675129],
          [48.064911, 32.674542],
          [48.063004, 32.67419],
          [48.061863, 32.67397],
          [48.05941, 32.673534],
          [48.057129, 32.67337],
          [48.054687, 32.673348],
          [48.05257, 32.673553],
          [48.051056, 32.673842],
          [48.049976, 32.674317],
          [48.049225, 32.674737],
          [48.048477, 32.675528],
          [48.048, 32.676315],
          [48.047852, 32.677055],
          [48.047318, 32.67757],
          [48.046886, 32.677756],
          [48.046234, 32.677718],
          [48.0452, 32.677635],
          [48.04438, 32.677413],
          [48.043129, 32.677055],
          [48.041653, 32.676427],
          [48.040012, 32.675795],
          [48.038815, 32.675576],
          [48.037132, 32.6755],
          [48.035233, 32.675563],
          [48.03355, 32.675629],
          [48.03225, 32.675731],
          [48.030674, 32.675746],
          [48.029804, 32.67571],
          [48.029148, 32.675437],
          [48.028385, 32.675171],
          [48.027615, 32.674483],
          [48.027168, 32.673889],
          [48.026566, 32.673435],
          [48.026013, 32.672886],
          [48.025524, 32.672707],
          [48.024761, 32.672531],
          [48.023945, 32.672402],
          [48.023182, 32.672359],
          [48.022369, 32.672508],
          [48.021175, 32.672521],
          [48.020256, 32.672756],
          [48.018849, 32.672954],
          [48.017876, 32.673195],
          [48.015984, 32.673722],
          [48.014576, 32.673966],
          [48.013279, 32.674348],
          [48.011929, 32.67482],
          [48.010742, 32.675155],
          [48.010037, 32.675346],
          [48.009392, 32.675632],
          [48.008633, 32.675637],
          [48.008247, 32.675503],
          [48.007378, 32.675235],
          [48.006722, 32.675057],
          [48.006283, 32.674877],
          [48.005848, 32.674698],
          [48.005089, 32.674702],
          [48.004215, 32.674526],
          [48.003403, 32.674534],
          [48.002045, 32.674548],
          [48.001499, 32.674324],
          [48.001163, 32.673867],
          [48.000996, 32.673358],
          [48.001251, 32.672481],
          [48.001244, 32.671879],
          [48.00124, 32.671666],
          [48.001236, 32.671416],
          [48.001335, 32.670864],
          [48.00148, 32.669894],
          [48.001804, 32.6698],
          [48.002178, 32.669289],
          [48.002167, 32.668594],
          [48.001942, 32.668136],
          [48.001282, 32.66759],
          [47.999977, 32.666888],
          [47.999591, 32.666628],
          [47.999552, 32.666574],
          [47.999388, 32.666346],
          [47.998955, 32.664997],
          [47.998066, 32.662742],
          [47.997238, 32.661133],
          [47.996357, 32.659753],
          [47.995205, 32.65897],
          [47.994434, 32.65805],
          [47.994139, 32.657817],
          [47.993446, 32.657269],
          [47.991966, 32.656488],
          [47.990872, 32.655796],
          [47.990413, 32.655689],
          [47.989121, 32.655385],
          [47.987808, 32.654923],
          [47.986767, 32.654603],
          [47.985237, 32.654095],
          [47.984447, 32.653783],
          [47.984306, 32.653727],
          [47.983101, 32.653496],
          [47.982121, 32.653449],
          [47.981522, 32.653728],
          [47.980488, 32.65451],
          [47.979889, 32.655201],
          [47.978966, 32.655983],
          [47.97744, 32.656857],
          [47.97488, 32.657914],
          [47.973133, 32.658421],
          [47.972042, 32.658695],
          [47.971333, 32.658696],
          [47.970131, 32.658511],
          [47.969147, 32.65828],
          [47.968651, 32.657777],
          [47.968212, 32.657178],
          [47.967991, 32.656532],
          [47.96777, 32.655891],
          [47.967274, 32.655246],
          [47.96656, 32.65442],
          [47.965462, 32.653501],
          [47.964835, 32.653012],
          [47.963982, 32.652347],
          [47.962883, 32.65129],
          [47.962112, 32.650235],
          [47.961506, 32.648944],
          [47.961063, 32.647888],
          [47.960949, 32.646923],
          [47.960945, 32.64605],
          [47.961269, 32.645449],
          [47.961594, 32.644943],
          [47.961743, 32.644795],
          [47.962193, 32.644347],
          [47.962406, 32.643796],
          [47.96246, 32.642967],
          [47.96207, 32.642277],
          [47.961193, 32.641404],
          [47.960648, 32.640988],
          [47.959278, 32.640208],
          [47.957909, 32.63924],
          [47.956907, 32.638788],
          [47.956486, 32.638599],
          [47.955387, 32.637955],
          [47.954838, 32.637218],
          [47.954559, 32.635978],
          [47.954666, 32.635334],
          [47.955482, 32.634551],
          [47.956951, 32.633446],
          [47.958363, 32.632205],
          [47.959667, 32.63115],
          [47.961136, 32.630318],
          [47.962605, 32.629398],
          [47.963966, 32.62885],
          [47.969303, 32.626224],
          [47.971317, 32.625214],
          [47.973332, 32.62434],
          [47.974804, 32.623878],
          [47.976223, 32.623509],
          [47.977581, 32.622818],
          [47.978508, 32.622085],
          [47.979481, 32.620749],
          [47.980949, 32.619599],
          [47.982143, 32.618816],
          [47.983563, 32.618312],
          [47.985142, 32.617622],
          [47.9865, 32.616841],
          [47.988296, 32.615734],
          [47.988999, 32.614906],
          [47.989216, 32.614352],
          [47.989319, 32.61348],
          [47.989208, 32.612835],
          [47.988712, 32.612149],
          [47.988327, 32.611412],
          [47.987667, 32.610722],
          [47.987007, 32.61003],
          [47.985859, 32.609432],
          [47.984786, 32.609095],
          [47.984547, 32.60902],
          [47.982964, 32.608883],
          [47.981819, 32.609065],
          [47.980728, 32.609345],
          [47.979748, 32.609894],
          [47.979372, 32.610155],
          [47.978554, 32.610722],
          [47.977085, 32.611827],
          [47.975616, 32.612427],
          [47.974579, 32.612655],
          [47.972561, 32.612838],
          [47.970215, 32.612655],
          [47.967484, 32.612517],
          [47.965248, 32.612472],
          [47.963829, 32.612706],
          [47.961483, 32.612933],
          [47.960064, 32.612935],
          [47.958862, 32.612705],
          [47.95755, 32.61234],
          [47.95602, 32.611694],
          [47.954544, 32.611282],
          [47.951374, 32.61004],
          [47.950791, 32.609891],
          [47.950115, 32.609718],
          [47.949352, 32.60958],
          [47.948589, 32.609765],
          [47.947552, 32.609996],
          [47.945919, 32.61041],
          [47.94574, 32.610451],
          [47.9445, 32.610734],
          [47.943356, 32.610915],
          [47.942429, 32.610915],
          [47.941338, 32.610826],
          [47.940353, 32.610362],
          [47.939312, 32.609951],
          [47.938328, 32.609077],
          [47.938071, 32.608786],
          [47.938045, 32.608757],
          [47.937557, 32.608205],
          [47.936627, 32.607055],
          [47.935967, 32.605858],
          [47.935307, 32.604663],
          [47.935265, 32.604463],
          [47.934971, 32.603054],
          [47.934475, 32.601859],
          [47.934143, 32.600985],
          [47.933594, 32.600341],
          [47.932991, 32.599835],
          [47.932388, 32.599606],
          [47.931572, 32.599422],
          [47.930481, 32.599377],
          [47.928951, 32.599468],
          [47.926609, 32.599884],
          [47.924862, 32.600254],
          [47.923065, 32.600667],
          [47.920723, 32.60154],
          [47.918873, 32.602322],
          [47.917618, 32.602693],
          [47.916637, 32.602921],
          [47.916483, 32.60291],
          [47.915981, 32.602875],
          [47.915218, 32.602782],
          [47.914726, 32.602416],
          [47.914558, 32.601817],
          [47.914612, 32.601174],
          [47.914822, 32.599933],
          [47.915199, 32.598691],
          [47.915901, 32.596944],
          [47.916607, 32.595933],
          [47.918022, 32.59483],
          [47.920792, 32.592621],
          [47.923077, 32.590505],
          [47.924706, 32.588802],
          [47.926605, 32.586917],
          [47.927528, 32.58632],
          [47.928056, 32.586088],
          [47.928997, 32.585675],
          [47.930576, 32.585029],
          [47.93161, 32.584522],
          [47.93232, 32.584064],
          [47.932697, 32.583557],
          [47.932858, 32.582867],
          [47.932858, 32.582409],
          [47.932526, 32.581673],
          [47.932485, 32.581584],
          [47.932083, 32.580706],
          [47.931152, 32.57951],
          [47.93055, 32.578868],
          [47.930142, 32.578561],
          [47.930058, 32.578498],
          [47.929619, 32.578316],
          [47.928421, 32.578133],
          [47.92749, 32.578133],
          [47.92651, 32.578131],
          [47.925747, 32.57841],
          [47.925148, 32.578549],
          [47.92477, 32.579148],
          [47.9245, 32.579743],
          [47.924065, 32.580386],
          [47.923801, 32.58067],
          [47.923265, 32.580911],
          [47.922813, 32.580987],
          [47.922161, 32.580939],
          [47.920795, 32.580574],
          [47.920016, 32.580279],
          [47.919212, 32.579974],
          [47.918064, 32.579518],
          [47.917005, 32.579335],
          [47.916206, 32.579197],
          [47.914517, 32.579196],
          [47.913536, 32.579197],
          [47.912827, 32.579518],
          [47.912285, 32.579795],
          [47.911629, 32.579795],
          [47.910484, 32.579613],
          [47.910126, 32.579495],
          [47.9095, 32.579289],
          [47.908299, 32.578647],
          [47.907806, 32.578369],
          [47.907368, 32.577682],
          [47.907089, 32.576947],
          [47.906715, 32.576316],
          [47.906597, 32.576119],
          [47.906323, 32.575382],
          [47.906208, 32.574372],
          [47.906258, 32.573316],
          [47.906418, 32.572258],
          [47.90625, 32.571659],
          [47.905922, 32.571153],
          [47.905155, 32.570832],
          [47.903736, 32.570328],
          [47.903553, 32.570254],
          [47.902592, 32.569867],
          [47.901279, 32.569039],
          [47.90062, 32.568306],
          [47.900509, 32.567616],
          [47.90071, 32.566976],
          [47.900726, 32.566925],
          [47.901158, 32.566142],
          [47.90192, 32.565543],
          [47.902569, 32.564762],
          [47.903439, 32.564258],
          [47.904964, 32.56366],
          [47.906708, 32.563473],
          [47.907799, 32.563428],
          [47.909206, 32.563387],
          [47.909435, 32.56338],
          [47.910252, 32.563382],
          [47.911124, 32.563494],
          [47.911671, 32.563564],
          [47.912922, 32.56338],
          [47.913521, 32.563287],
          [47.91423, 32.562874],
          [47.914772, 32.561909],
          [47.915474, 32.560254],
          [47.915955, 32.558181],
          [47.915962, 32.558121],
          [47.916218, 32.555974],
          [47.916321, 32.555149],
          [47.916388, 32.555078],
          [47.916973, 32.55446],
          [47.917519, 32.554088],
          [47.918224, 32.553814],
          [47.919205, 32.553719],
          [47.920296, 32.553906],
          [47.921062, 32.554364],
          [47.921883, 32.554821],
          [47.922264, 32.555419],
          [47.922924, 32.556249],
          [47.923416, 32.556892],
          [47.923748, 32.557533],
          [47.924187, 32.558177],
          [47.924896, 32.558824],
          [47.925663, 32.559372],
          [47.926758, 32.560016],
          [47.928013, 32.56052],
          [47.928997, 32.561027],
          [47.930035, 32.56153],
          [47.931564, 32.562359],
          [47.933311, 32.562816],
          [47.935055, 32.562908],
          [47.93898, 32.562909],
          [47.94091, 32.562907],
          [47.940945, 32.562907],
          [47.941365, 32.562834],
          [47.942524, 32.56263],
          [47.943394, 32.562264],
          [47.944046, 32.561572],
          [47.94426, 32.56102],
          [47.944206, 32.560604],
          [47.943657, 32.560147],
          [47.943029, 32.559884],
          [47.943001, 32.559872],
          [47.942074, 32.559689],
          [47.940765, 32.559689],
          [47.940384, 32.559364],
          [47.940109, 32.559044],
          [47.939941, 32.5584],
          [47.939884, 32.557941],
          [47.939884, 32.557389],
          [47.93988, 32.556652],
          [47.939766, 32.555916],
          [47.939598, 32.55495],
          [47.939323, 32.554168],
          [47.939102, 32.553574],
          [47.938717, 32.552884],
          [47.938931, 32.551873],
          [47.938816, 32.550675],
          [47.938591, 32.549572],
          [47.938316, 32.549018],
          [47.938095, 32.548607],
          [47.937603, 32.548379],
          [47.936897, 32.548377],
          [47.935753, 32.548379],
          [47.934605, 32.54824],
          [47.933514, 32.548058],
          [47.932316, 32.547828],
          [47.931824, 32.547413],
          [47.931713, 32.546864],
          [47.931816, 32.546218],
          [47.932144, 32.545757],
          [47.932793, 32.54493],
          [47.932873, 32.544826],
          [47.933605, 32.543872],
          [47.93436, 32.542446],
          [47.934486, 32.542072],
          [47.934685, 32.54148],
          [47.934681, 32.540796],
          [47.934677, 32.540238],
          [47.934239, 32.539317],
          [47.933925, 32.539005],
          [47.933689, 32.538769],
          [47.933033, 32.538032],
          [47.932541, 32.537574],
          [47.93232, 32.537158],
          [47.93232, 32.536514],
          [47.932423, 32.535829],
          [47.9328, 32.53495],
          [47.933777, 32.533939],
          [47.934483, 32.533204],
          [47.936058, 32.532559],
          [47.937189, 32.532188],
          [47.937744, 32.532005],
          [47.939377, 32.53159],
          [47.93974, 32.531573],
          [47.941341, 32.531498],
          [47.942482, 32.531498],
          [47.943302, 32.531773],
          [47.943848, 32.532138],
          [47.944397, 32.532555],
          [47.944836, 32.533246],
          [47.945713, 32.53453],
          [47.946045, 32.535771],
          [47.946381, 32.536922],
          [47.946434, 32.537105],
          [47.946549, 32.538116],
          [47.94661, 32.539452],
          [47.946884, 32.539726],
          [47.947212, 32.53991],
          [47.947647, 32.540002],
          [47.948193, 32.540092],
          [47.94939, 32.540001],
          [47.950699, 32.539909],
          [47.951098, 32.539796],
          [47.951391, 32.539895],
          [47.952501, 32.539895],
          [47.954444, 32.539895],
          [47.954444, 32.539615],
          [47.955082, 32.538765],
          [47.955278, 32.538504],
          [47.955834, 32.535732],
          [47.955432, 32.535125],
          [47.95531, 32.53494],
          [47.95541, 32.534111],
          [47.955025, 32.532917],
          [47.954365, 32.532089],
          [47.954084, 32.53187],
          [47.953598, 32.531491],
          [47.953163, 32.531352],
          [47.952183, 32.531171],
          [47.951798, 32.530941],
          [47.951416, 32.530708],
          [47.951306, 32.530433],
          [47.951359, 32.530434],
          [47.951084, 32.529652],
          [47.95097, 32.528687],
          [47.950913, 32.527813],
          [47.95047, 32.526849],
          [47.949814, 32.525929],
          [47.949101, 32.525283],
          [47.948498, 32.524779],
          [47.947682, 32.524505],
          [47.946426, 32.524414],
          [47.94381, 32.524093],
          [47.942448, 32.524001],
          [47.941467, 32.523727],
          [47.940701, 32.523267],
          [47.939934, 32.522346],
          [47.939331, 32.521611],
          [47.938999, 32.520832],
          [47.938835, 32.520095],
          [47.938831, 32.519498],
          [47.939373, 32.518806],
          [47.940567, 32.517841],
          [47.942577, 32.51701],
          [47.944428, 32.516456],
          [47.944713, 32.516425],
          [47.946552, 32.516228],
          [47.949165, 32.515994],
          [47.950905, 32.515671],
          [47.952648, 32.515305],
          [47.953842, 32.515118],
          [47.95417, 32.515164],
          [47.954609, 32.515346],
          [47.95488, 32.515578],
          [47.954884, 32.516083],
          [47.954781, 32.51691],
          [47.954678, 32.518336],
          [47.954681, 32.519166],
          [47.954956, 32.519624],
          [47.955505, 32.520176],
          [47.956596, 32.520912],
          [47.957363, 32.521415],
          [47.958893, 32.522288],
          [47.96064, 32.5233],
          [47.962277, 32.523849],
          [47.963749, 32.52422],
          [47.965984, 32.524448],
          [47.968712, 32.524766],
          [47.971653, 32.524995],
          [47.977215, 32.525313],
          [47.979143, 32.52563],
          [47.97945, 32.52568],
          [47.980213, 32.525863],
          [47.980652, 32.526367],
          [47.980984, 32.527058],
          [47.981037, 32.527519],
          [47.981041, 32.528072],
          [47.980827, 32.528531],
          [47.98045, 32.529496],
          [47.980375, 32.529624],
          [47.979858, 32.530509],
          [47.979206, 32.531246],
          [47.978775, 32.532119],
          [47.978561, 32.533038],
          [47.978565, 32.53373],
          [47.978569, 32.534328],
          [47.978684, 32.534923],
          [47.978958, 32.535478],
          [47.979397, 32.535939],
          [47.979942, 32.536166],
          [47.980599, 32.536351],
          [47.981361, 32.536304],
          [47.982395, 32.536209],
          [47.984269, 32.535978],
          [47.984627, 32.535934],
          [47.986805, 32.535792],
          [47.989803, 32.535702],
          [47.993561, 32.53547],
          [47.996937, 32.5351],
          [47.997536, 32.534938],
          [47.999989, 32.534275],
          [48.001091, 32.534038],
          [48.00293, 32.533699],
          [48.004337, 32.533412],
          [48.005741, 32.533031],
          [48.006496, 32.532612],
          [48.007942, 32.531352],
          [48.009175, 32.530221],
          [48.009815, 32.529632],
          [48.011154, 32.528562],
          [48.011916, 32.527959],
          [48.012978, 32.52712],
          [48.014683, 32.525165],
          [48.015698, 32.524238],
          [48.016064, 32.523357],
          [48.016266, 32.522388],
          [48.016304, 32.521465],
          [48.016182, 32.520497],
          [48.015732, 32.519623],
          [48.015449, 32.51913],
          [48.015232, 32.518752],
          [48.014839, 32.518108],
          [48.014561, 32.51765],
          [48.014549, 32.51696],
          [48.014702, 32.516265],
          [48.014965, 32.5158],
          [48.015396, 32.515614],
          [48.01572, 32.515426],
          [48.01593, 32.515012],
          [48.015976, 32.514458],
          [48.016338, 32.513439],
          [48.016811, 32.51242],
          [48.017555, 32.511261],
          [48.018246, 32.510333],
          [48.019104, 32.509777],
          [48.019798, 32.509034],
          [48.019896, 32.508535],
          [48.019898, 32.508526],
          [48.0201, 32.507598],
          [48.020355, 32.506676],
          [48.020504, 32.505708],
          [48.020592, 32.504414],
          [48.020679, 32.503029],
          [48.020657, 32.501602],
          [48.020657, 32.50006],
          [48.02047, 32.495731],
          [48.020241, 32.493519],
          [48.020119, 32.491214],
          [48.020382, 32.489602],
          [48.020588, 32.487709],
          [48.02084, 32.484898],
          [48.020939, 32.482822],
          [48.021355, 32.480515],
          [48.021507, 32.478627],
          [48.021709, 32.475857],
          [48.02169, 32.472584],
          [48.021885, 32.469174],
          [48.022129, 32.464931],
          [48.022385, 32.462025],
          [48.022472, 32.458844],
          [48.022892, 32.456677],
          [48.022884, 32.454925],
          [48.022923, 32.452805],
          [48.022533, 32.450641],
          [48.022087, 32.448108],
          [48.021259, 32.444611],
          [48.020161, 32.441204],
          [48.019714, 32.438209],
          [48.019367, 32.43448],
          [48.019291, 32.430608],
          [48.018894, 32.427059],
          [48.018204, 32.418906],
          [48.018723, 32.41554],
          [48.019402, 32.411983],
          [48.01955, 32.409679],
          [48.020008, 32.405021],
          [48.021423, 32.398011],
          [48.022106, 32.395194],
          [48.022892, 32.391548],
          [48.023312, 32.389702],
          [48.024326, 32.388176],
          [48.025707, 32.385264],
          [48.027142, 32.38263],
          [48.029274, 32.379486],
          [48.030491, 32.376301],
          [48.031124, 32.374266],
          [48.031651, 32.372329],
          [48.032486, 32.368271],
          [48.033272, 32.365409],
          [48.034596, 32.362129],
          [48.035439, 32.359775],
          [48.035637, 32.357285],
          [48.035626, 32.355351],
          [48.035454, 32.353969],
          [48.034859, 32.353004],
          [48.034264, 32.352177],
          [48.033505, 32.351165],
          [48.032265, 32.350342],
          [48.031296, 32.350116],
          [48.029091, 32.34953],
          [48.026081, 32.349128],
          [48.020977, 32.348369],
          [48.017593, 32.348202],
          [48.013565, 32.347714],
          [48.008514, 32.347367],
          [48.004601, 32.346135],
          [48.000812, 32.344244],
          [48.000004, 32.343779],
          [47.996393, 32.342404],
          [47.989014, 32.339599],
          [47.984806, 32.338015],
          [47.981258, 32.336799],
          [47.977859, 32.334748],
          [47.976048, 32.333564],
          [47.974606, 32.331726],
          [47.97171, 32.327457],
          [47.969574, 32.323045],
          [47.967075, 32.316646],
          [47.96558, 32.311532],
          [47.964722, 32.309093],
          [47.96431, 32.307063],
          [47.96389, 32.304896],
          [47.963364, 32.302639],
          [47.962788, 32.300567],
          [47.962551, 32.299506],
          [47.962143, 32.297711],
          [47.961899, 32.296281],
          [47.961777, 32.295403],
          [47.961975, 32.294387],
          [47.962444, 32.293366],
          [47.963028, 32.292438],
          [47.965645, 32.290155],
          [47.968155, 32.288148],
          [47.97234, 32.285618],
          [47.977223, 32.282803],
          [47.980618, 32.281006],
          [47.981037, 32.280784],
          [47.983616, 32.279514],
          [47.984955, 32.278534],
          [47.985863, 32.27774],
          [47.986122, 32.277092],
          [47.986645, 32.276075],
          [47.987003, 32.274913],
          [47.987099, 32.274174],
          [47.987194, 32.273297],
          [47.987061, 32.272003],
          [47.986923, 32.27039],
          [47.986675, 32.268639],
          [47.986099, 32.266701],
          [47.985642, 32.265182],
          [47.985024, 32.263894],
          [47.984081, 32.262749],
          [47.983093, 32.262067],
          [47.9814, 32.261249],
          [47.979595, 32.260342],
          [47.97818, 32.259891],
          [47.977035, 32.25967],
          [47.975948, 32.259403],
          [47.975132, 32.259133],
          [47.972786, 32.258137],
          [47.968189, 32.255592],
          [47.964363, 32.253731],
          [47.962059, 32.252044],
          [47.959874, 32.249987],
          [47.95648, 32.247636],
          [47.955219, 32.246762],
          [47.951737, 32.244091],
          [47.948582, 32.24165],
          [47.945313, 32.238472],
          [47.943405, 32.236077],
          [47.942245, 32.234338],
          [47.941715, 32.233543],
          [47.938549, 32.228248],
          [47.93528, 32.223141],
          [47.931629, 32.219044],
          [47.92831, 32.21568],
          [47.925045, 32.212598],
          [47.921291, 32.209466],
          [47.918247, 32.206978],
          [47.915855, 32.205181],
          [47.911888, 32.20251],
          [47.907597, 32.199933],
          [47.903198, 32.19754],
          [47.895432, 32.193118],
          [47.890423, 32.19042],
          [47.890053, 32.190221],
          [47.886089, 32.187686],
          [47.883591, 32.185844],
          [47.881687, 32.184098],
          [47.880219, 32.182301],
          [47.878262, 32.180091],
          [47.872986, 32.173188],
          [47.86858, 32.16734],
          [47.865044, 32.162738],
          [47.857655, 32.155051],
          [47.850376, 32.148009],
          [47.846359, 32.14465],
          [47.838814, 32.13747],
          [47.830944, 32.131072],
          [47.82655, 32.127944],
          [47.821396, 32.124128],
          [47.81863, 32.121872],
          [47.8144, 32.119203],
          [47.810798, 32.116606],
          [47.810063, 32.116073],
          [47.805073, 32.113037],
          [47.802036, 32.111011],
          [47.798187, 32.108619],
          [47.793037, 32.105858],
          [47.788433, 32.103465],
          [47.785179, 32.102084],
          [47.783994, 32.101491],
          [47.781876, 32.100429],
          [47.778298, 32.098774],
          [47.772446, 32.095966],
          [47.767029, 32.093346],
          [47.760529, 32.09077],
          [47.755869, 32.088843],
          [47.75008, 32.086754],
          [47.700187, 32.064853],
          [47.665632, 32.049667],
          [47.66514, 32.049372],
          [47.664699, 32.049126],
          [47.66444, 32.048871],
          [47.664111, 32.048542],
        ],
      ],
    },
  },
  KohgiluyehAndBuyerAhmad: {
    properties: {
      value: "KohgiluyehAndBuyerAhmad",
      name: "استان کهگیلویه و بویر احمد, ایران",
      id: 12,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [49.886212, 30.96426],
          [49.886509, 30.96255],
          [49.887024, 30.960701],
          [49.887757, 30.959405],
          [49.888489, 30.958388],
          [49.890462, 30.956922],
          [49.890865, 30.956622],
          [49.89362, 30.95536],
          [49.897263, 30.952481],
          [49.903648, 30.947602],
          [49.913082, 30.93994],
          [49.916931, 30.937106],
          [49.918144, 30.936085],
          [49.918934, 30.935573],
          [49.919567, 30.935109],
          [49.920044, 30.934921],
          [49.920735, 30.934918],
          [49.921375, 30.935052],
          [49.921909, 30.935097],
          [49.922546, 30.935141],
          [49.923077, 30.934951],
          [49.92371, 30.93472],
          [49.924294, 30.934393],
          [49.92519, 30.933882],
          [49.925293, 30.933603],
          [49.925392, 30.933096],
          [49.925389, 30.932771],
          [49.925327, 30.932035],
          [49.924995, 30.931252],
          [49.924668, 30.930561],
          [49.924343, 30.929963],
          [49.924015, 30.92941],
          [49.923637, 30.928951],
          [49.923313, 30.928631],
          [49.923309, 30.928261],
          [49.923412, 30.927892],
          [49.923675, 30.927707],
          [49.924313, 30.927655],
          [49.92506, 30.927838],
          [49.926022, 30.928247],
          [49.927467, 30.928981],
          [49.928539, 30.929527],
          [49.930309, 30.930626],
          [49.931164, 30.931037],
          [49.931591, 30.931174],
          [49.932128, 30.931233],
          [49.932392, 30.931262],
          [49.932976, 30.931165],
          [49.93351, 30.931255],
          [49.934154, 30.931851],
          [49.934536, 30.932452],
          [49.934868, 30.933601],
          [49.935204, 30.934616],
          [49.935528, 30.935262],
          [49.935852, 30.935628],
          [49.935866, 30.935721],
          [49.935913, 30.936043],
          [49.935703, 30.936366],
          [49.93528, 30.93637],
          [49.934323, 30.936373],
          [49.932835, 30.936566],
          [49.932728, 30.936658],
          [49.932144, 30.936847],
          [49.931828, 30.937079],
          [49.931835, 30.937586],
          [49.932159, 30.937907],
          [49.93264, 30.938182],
          [49.933441, 30.938407],
          [49.934135, 30.938496],
          [49.935307, 30.938535],
          [49.936157, 30.938346],
          [49.937107, 30.937882],
          [49.93774, 30.937464],
          [49.938106, 30.937047],
          [49.938625, 30.936122],
          [49.939041, 30.935288],
          [49.939251, 30.93492],
          [49.939617, 30.934639],
          [49.939884, 30.934639],
          [49.940254, 30.934638],
          [49.940578, 30.934821],
          [49.940849, 30.935281],
          [49.941174, 30.935694],
          [49.941551, 30.935968],
          [49.941872, 30.936106],
          [49.942245, 30.936104],
          [49.942562, 30.935872],
          [49.943085, 30.935267],
          [49.94387, 30.934295],
          [49.945442, 30.932535],
          [49.946808, 30.931283],
          [49.947544, 30.930725],
          [49.948864, 30.930167],
          [49.950081, 30.929699],
          [49.950771, 30.929601],
          [49.951141, 30.929371],
          [49.951237, 30.928585],
          [49.951122, 30.926444],
          [49.950951, 30.923234],
          [49.951096, 30.918393],
          [49.951119, 30.908842],
          [49.951351, 30.906304],
          [49.951164, 30.90446],
          [49.950981, 30.902801],
          [49.950642, 30.90128],
          [49.950039, 30.900084],
          [49.94944, 30.898841],
          [49.94846, 30.897094],
          [49.947903, 30.895434],
          [49.947815, 30.892897],
          [49.947796, 30.891375],
          [49.948563, 30.889342],
          [49.949284, 30.887724],
          [49.950523, 30.88532],
          [49.951038, 30.8845],
          [49.951392, 30.883937],
          [49.951506, 30.883756],
          [49.952748, 30.881251],
          [49.954884, 30.878288],
          [49.955505, 30.877364],
          [49.95779, 30.877631],
          [49.961929, 30.877657],
          [49.968197, 30.877861],
          [49.974404, 30.877923],
          [49.978703, 30.877951],
          [49.985645, 30.877412],
          [49.992214, 30.876784],
          [50.000156, 30.874376],
          [50.003979, 30.874117],
          [50.008705, 30.873912],
          [50.013855, 30.873707],
          [50.019642, 30.873228],
          [50.025005, 30.872375],
          [50.030369, 30.871617],
          [50.033341, 30.871308],
          [50.035267, 30.868577],
          [50.04018, 30.862943],
          [50.043545, 30.858693],
          [50.047916, 30.853842],
          [50.052818, 30.848719],
          [50.057827, 30.842854],
          [50.061344, 30.838605],
          [50.065491, 30.834638],
          [50.068791, 30.830947],
          [50.06903, 30.830693],
          [50.069748, 30.829932],
          [50.070657, 30.830973],
          [50.072434, 30.83301],
          [50.076657, 30.836933],
          [50.080986, 30.840623],
          [50.086006, 30.844504],
          [50.089123, 30.846888],
          [50.096264, 30.852264],
          [50.098644, 30.85418],
          [50.102295, 30.856659],
          [50.105526, 30.858764],
          [50.109444, 30.860733],
          [50.111775, 30.862139],
          [50.114635, 30.864473],
          [50.117176, 30.866761],
          [50.11882, 30.868067],
          [50.119703, 30.868587],
          [50.120568, 30.869097],
          [50.122368, 30.870406],
          [50.123589, 30.871246],
          [50.124966, 30.872598],
          [50.126293, 30.873906],
          [50.128254, 30.876141],
          [50.130161, 30.878331],
          [50.132229, 30.880522],
          [50.13366, 30.882153],
          [50.135731, 30.883784],
          [50.138489, 30.886257],
          [50.143162, 30.890132],
          [50.146134, 30.892696],
          [50.149959, 30.89545],
          [50.152775, 30.897828],
          [50.156494, 30.900537],
          [50.160694, 30.903756],
          [50.164471, 30.906233],
          [50.168564, 30.908986],
          [50.174153, 30.912211],
          [50.180485, 30.916323],
          [50.186501, 30.919596],
          [50.193581, 30.92329],
          [50.197739, 30.925533],
          [50.204559, 30.92969],
          [50.209091, 30.932258],
          [50.209728, 30.932354],
          [50.210423, 30.932264],
          [50.210846, 30.931666],
          [50.211529, 30.929212],
          [50.213482, 30.924405],
          [50.215111, 30.918392],
          [50.217419, 30.909787],
          [50.218101, 30.907337],
          [50.218361, 30.906227],
          [50.218567, 30.904468],
          [50.219036, 30.901785],
          [50.219559, 30.899381],
          [50.220184, 30.896466],
          [50.220678, 30.894206],
          [50.221073, 30.892393],
          [50.221749, 30.88846],
          [50.223156, 30.881429],
          [50.223881, 30.877404],
          [50.224033, 30.875181],
          [50.224293, 30.873701],
          [50.224499, 30.872497],
          [50.224709, 30.871619],
          [50.225182, 30.870324],
          [50.225387, 30.869889],
          [50.22723, 30.865983],
          [50.228592, 30.862195],
          [50.229382, 30.860623],
          [50.230595, 30.858638],
          [50.232277, 30.855544],
          [50.23338, 30.853329],
          [50.234009, 30.851526],
          [50.237949, 30.844466],
          [50.241199, 30.837447],
          [50.242201, 30.835397],
          [50.243298, 30.833153],
          [50.243454, 30.832506],
          [50.243439, 30.829914],
          [50.243309, 30.82519],
          [50.243076, 30.821113],
          [50.24269, 30.81786],
          [50.242085, 30.812774],
          [50.240967, 30.811655],
          [50.238365, 30.808953],
          [50.236187, 30.806811],
          [50.234436, 30.805456],
          [50.232578, 30.803684],
          [50.231674, 30.802661],
          [50.230927, 30.801267],
          [50.230446, 30.800107],
          [50.230228, 30.798436],
          [50.230221, 30.796954],
          [50.230793, 30.794503],
          [50.231205, 30.792144],
          [50.231514, 30.790528],
          [50.232876, 30.787942],
          [50.235188, 30.784624],
          [50.236816, 30.782737],
          [50.237397, 30.782034],
          [50.237726, 30.781637],
          [50.237919, 30.781401],
          [50.240433, 30.777297],
          [50.243309, 30.771947],
          [50.246029, 30.767334],
          [50.249016, 30.763557],
          [50.249992, 30.761002],
          [50.251541, 30.758771],
          [50.252857, 30.756963],
          [50.254177, 30.755576],
          [50.254192, 30.755561],
          [50.256126, 30.753582],
          [50.258022, 30.751912],
          [50.259966, 30.750008],
          [50.261055, 30.74899],
          [50.262673, 30.747987],
          [50.26973, 30.74379],
          [50.281132, 30.737222],
          [50.292041, 30.730834],
          [50.300369, 30.725955],
          [50.313568, 30.718338],
          [50.315552, 30.717198],
          [50.317749, 30.716014],
          [50.320957, 30.714515],
          [50.323204, 30.713514],
          [50.32626, 30.711876],
          [50.32893, 30.710787],
          [50.33176, 30.709886],
          [50.333229, 30.709436],
          [50.334107, 30.709167],
          [50.335442, 30.708716],
          [50.336826, 30.708497],
          [50.337837, 30.708459],
          [50.338844, 30.708749],
          [50.339638, 30.709172],
          [50.340428, 30.709734],
          [50.34111, 30.710251],
          [50.3419, 30.711092],
          [50.342789, 30.712259],
          [50.344151, 30.714032],
          [50.345566, 30.715993],
          [50.346931, 30.717673],
          [50.347749, 30.718508],
          [50.348351, 30.719122],
          [50.349823, 30.72048],
          [50.351353, 30.721652],
          [50.352886, 30.722734],
          [50.354363, 30.724043],
          [50.356159, 30.725125],
          [50.358437, 30.726213],
          [50.360451, 30.727019],
          [50.363262, 30.728064],
          [50.365436, 30.728733],
          [50.368042, 30.729267],
          [50.370381, 30.729707],
          [50.373035, 30.730193],
          [50.377289, 30.730789],
          [50.381279, 30.731196],
          [50.384579, 30.731365],
          [50.388466, 30.731494],
          [50.390701, 30.731423],
          [50.392748, 30.731313],
          [50.392841, 30.731308],
          [50.394281, 30.728472],
          [50.396782, 30.727641],
          [50.411785, 30.717912],
          [50.414821, 30.709559],
          [50.415118, 30.708743],
          [50.415394, 30.707912],
          [50.415394, 30.704371],
          [50.415394, 30.698192],
          [50.415118, 30.697913],
          [50.414285, 30.689303],
          [50.414285, 30.686028],
          [50.414285, 30.682362],
          [50.416254, 30.675461],
          [50.416505, 30.674583],
          [50.419005, 30.670693],
          [50.423728, 30.665134],
          [50.453174, 30.648185],
          [50.464026, 30.642595],
          [50.489287, 30.629576],
          [50.49012, 30.629576],
          [50.502896, 30.624297],
          [50.50762, 30.622916],
          [50.51151, 30.621526],
          [50.512343, 30.620967],
          [50.513804, 30.620318],
          [50.516964, 30.617809],
          [50.51981, 30.61512],
          [50.523392, 30.611598],
          [50.52602, 30.608354],
          [50.530018, 30.604137],
          [50.532959, 30.600802],
          [50.533901, 30.599643],
          [50.534374, 30.598765],
          [50.535053, 30.597468],
          [50.535786, 30.595526],
          [50.536778, 30.592888],
          [50.537662, 30.59025],
          [50.538967, 30.586735],
          [50.540375, 30.582896],
          [50.541313, 30.580675],
          [50.542931, 30.577067],
          [50.544548, 30.574798],
          [50.546741, 30.571145],
          [50.548309, 30.569156],
          [50.548635, 30.568765],
          [50.54872, 30.568511],
          [50.548733, 30.568471],
          [50.54929, 30.566522],
          [50.549566, 30.566242],
          [50.549566, 30.563055],
          [50.549175, 30.562867],
          [50.548542, 30.562822],
          [50.547233, 30.563009],
          [50.546238, 30.563425],
          [50.545185, 30.563566],
          [50.544399, 30.563566],
          [50.543453, 30.563292],
          [50.542664, 30.562876],
          [50.541927, 30.562228],
          [50.541138, 30.561352],
          [50.540608, 30.560013],
          [50.540447, 30.558995],
          [50.540337, 30.558394],
          [50.540283, 30.557977],
          [50.540283, 30.557792],
          [50.540176, 30.557471],
          [50.539703, 30.557009],
          [50.538601, 30.556317],
          [50.537655, 30.555761],
          [50.537075, 30.555299],
          [50.536442, 30.554794],
          [50.535919, 30.554331],
          [50.535813, 30.554286],
          [50.535603, 30.554006],
          [50.535179, 30.553454],
          [50.534653, 30.552806],
          [50.53434, 30.55239],
          [50.53418, 30.551975],
          [50.53397, 30.551744],
          [50.533566, 30.551523],
          [50.53355, 30.551514],
          [50.53318, 30.551421],
          [50.532816, 30.551485],
          [50.532658, 30.551513],
          [50.531818, 30.551883],
          [50.530983, 30.552394],
          [50.530354, 30.552671],
          [50.528675, 30.552997],
          [50.528153, 30.552997],
          [50.527733, 30.552813],
          [50.527519, 30.552397],
          [50.527363, 30.551797],
          [50.5271, 30.550966],
          [50.526939, 30.5499],
          [50.526935, 30.549485],
          [50.526786, 30.548761],
          [50.526726, 30.548467],
          [50.526356, 30.547773],
          [50.52578, 30.547081],
          [50.525146, 30.54648],
          [50.524307, 30.54588],
          [50.523468, 30.545233],
          [50.522629, 30.544633],
          [50.521316, 30.543847],
          [50.520268, 30.543385],
          [50.519379, 30.543247],
          [50.518852, 30.543062],
          [50.518013, 30.542556],
          [50.517334, 30.541953],
          [50.517017, 30.541122],
          [50.515862, 30.540242],
          [50.515076, 30.539967],
          [50.514133, 30.539968],
          [50.513329, 30.540057],
          [50.509844, 30.538229],
          [50.509994, 30.537795],
          [50.510361, 30.537287],
          [50.510517, 30.536778],
          [50.510464, 30.536315],
          [50.510307, 30.535992],
          [50.510098, 30.535806],
          [50.509365, 30.535808],
          [50.508526, 30.535809],
          [50.50795, 30.535669],
          [50.507637, 30.535578],
          [50.50753, 30.535208],
          [50.50753, 30.534792],
          [50.507687, 30.534188],
          [50.507793, 30.533679],
          [50.507843, 30.533172],
          [50.507633, 30.532478],
          [50.507321, 30.532109],
          [50.506954, 30.531598],
          [50.506588, 30.531089],
          [50.506012, 30.530582],
          [50.505016, 30.530167],
          [50.503971, 30.529796],
          [50.502766, 30.529241],
          [50.501721, 30.528966],
          [50.500885, 30.528455],
          [50.500044, 30.528179],
          [50.49905, 30.527575],
          [50.498684, 30.527014],
          [50.498001, 30.526637],
          [50.497318, 30.526632],
          [50.496586, 30.526765],
          [50.495707, 30.527076],
          [50.493731, 30.527076],
          [50.493731, 30.527356],
          [50.492897, 30.527356],
          [50.49262, 30.527636],
          [50.489287, 30.529017],
          [50.486414, 30.527965],
          [50.480954, 30.525966],
          [50.479936, 30.526043],
          [50.479172, 30.526148],
          [50.47823, 30.526325],
          [50.477703, 30.526413],
          [50.477074, 30.526316],
          [50.476341, 30.526168],
          [50.476304, 30.526165],
          [50.47603, 30.52614],
          [50.475433, 30.526085],
          [50.475121, 30.526237],
          [50.474287, 30.526237],
          [50.470664, 30.5271],
          [50.466566, 30.528076],
          [50.462617, 30.529017],
          [50.461817, 30.529141],
          [50.460068, 30.529874],
          [50.45797, 30.530458],
          [50.456444, 30.530815],
          [50.455238, 30.531036],
          [50.453716, 30.531255],
          [50.452457, 30.531245],
          [50.451511, 30.531282],
          [50.450252, 30.531364],
          [50.448624, 30.531628],
          [50.447731, 30.53176],
          [50.446892, 30.532029],
          [50.446049, 30.532486],
          [50.444679, 30.533448],
          [50.443947, 30.533627],
          [50.443367, 30.533666],
          [50.442894, 30.533664],
          [50.441952, 30.533378],
          [50.440853, 30.532626],
          [50.439491, 30.531919],
          [50.438812, 30.531589],
          [50.438129, 30.531396],
          [50.437451, 30.531391],
          [50.436295, 30.531474],
          [50.435349, 30.531603],
          [50.434509, 30.531782],
          [50.433983, 30.531963],
          [50.43351, 30.532143],
          [50.433193, 30.532187],
          [50.432568, 30.53195],
          [50.432095, 30.531808],
          [50.431309, 30.53143],
          [50.430889, 30.531146],
          [50.429161, 30.530992],
          [50.427952, 30.531214],
          [50.42643, 30.531293],
          [50.424541, 30.531276],
          [50.423702, 30.53113],
          [50.420246, 30.530082],
          [50.417, 30.529172],
          [50.415581, 30.529161],
          [50.410599, 30.528743],
          [50.407192, 30.528527],
          [50.403465, 30.528497],
          [50.401947, 30.52839],
          [50.400688, 30.5281],
          [50.399326, 30.527812],
          [50.398018, 30.527614],
          [50.396766, 30.527193],
          [50.39378, 30.526187],
          [50.389435, 30.525036],
          [50.387863, 30.524745],
          [50.387081, 30.524459],
          [50.386662, 30.52427],
          [50.386456, 30.524084],
          [50.386459, 30.523527],
          [50.386566, 30.523157],
          [50.38683, 30.522929],
          [50.38752, 30.522147],
          [50.388954, 30.519889],
          [50.391182, 30.516896],
          [50.393406, 30.513764],
          [50.394314, 30.511964],
          [50.396809, 30.50763],
          [50.400311, 30.501914],
          [50.401211, 30.500125],
          [50.404431, 30.497613],
          [50.408695, 30.493847],
          [50.413208, 30.488275],
          [50.416249, 30.484099],
          [50.418598, 30.48034],
          [50.420269, 30.477789],
          [50.422035, 30.474312],
          [50.424168, 30.470094],
          [50.426041, 30.466664],
          [50.428597, 30.462813],
          [50.430321, 30.460402],
          [50.431679, 30.458547],
          [50.434704, 30.454093],
          [50.438984, 30.448296],
          [50.441231, 30.445513],
          [50.444107, 30.44208],
          [50.446663, 30.438881],
          [50.449124, 30.43601],
          [50.449486, 30.435588],
          [50.452622, 30.432294],
          [50.455713, 30.429464],
          [50.455765, 30.429417],
          [50.459431, 30.42612],
          [50.464665, 30.421529],
          [50.466709, 30.420181],
          [50.470486, 30.417534],
          [50.474999, 30.414469],
          [50.480091, 30.411728],
          [50.484135, 30.409497],
          [50.488232, 30.407498],
          [50.493069, 30.405358],
          [50.496327, 30.403963],
          [50.498531, 30.402848],
          [50.499929, 30.402389],
          [50.50209, 30.401786],
          [50.503525, 30.401004],
          [50.504593, 30.400216],
          [50.505764, 30.399244],
          [50.507042, 30.398646],
          [50.508633, 30.397953],
          [50.510548, 30.396983],
          [50.515866, 30.3938],
          [50.519745, 30.391443],
          [50.520489, 30.390981],
          [50.520759, 30.390425],
          [50.520977, 30.389498],
          [50.52068, 30.386988],
          [50.520695, 30.38499],
          [50.520603, 30.383455],
          [50.520252, 30.38099],
          [50.519909, 30.377551],
          [50.519657, 30.375831],
          [50.51936, 30.373554],
          [50.519066, 30.370484],
          [50.518875, 30.368068],
          [50.518898, 30.365189],
          [50.51897, 30.362913],
          [50.519005, 30.358498],
          [50.51907, 30.356967],
          [50.519089, 30.354596],
          [50.519001, 30.352459],
          [50.518822, 30.348648],
          [50.518623, 30.346881],
          [50.518532, 30.345301],
          [50.518437, 30.343907],
          [50.518452, 30.34228],
          [50.518055, 30.339025],
          [50.517666, 30.334979],
          [50.517117, 30.331214],
          [50.516541, 30.324473],
          [50.516485, 30.323034],
          [50.516453, 30.322242],
          [50.516583, 30.319686],
          [50.516495, 30.317549],
          [50.516205, 30.314063],
          [50.515369, 30.307226],
          [50.514679, 30.301739],
          [50.514385, 30.299415],
          [50.514202, 30.296161],
          [50.513966, 30.293279],
          [50.513512, 30.290719],
          [50.512966, 30.287276],
          [50.512783, 30.283883],
          [50.512608, 30.280257],
          [50.51247, 30.27756],
          [50.512032, 30.273886],
          [50.511952, 30.271142],
          [50.511753, 30.269934],
          [50.511368, 30.26598],
          [50.511398, 30.262495],
          [50.511105, 30.260028],
          [50.510708, 30.257565],
          [50.510479, 30.253937],
          [50.510292, 30.25008],
          [50.509972, 30.239023],
          [50.510113, 30.23239],
          [50.509933, 30.230215],
          [50.509377, 30.22758],
          [50.509602, 30.218351],
          [50.509648, 30.217253],
          [50.509945, 30.210281],
          [50.510002, 30.20583],
          [50.510342, 30.202442],
          [50.510834, 30.198866],
          [50.511482, 30.195196],
          [50.512337, 30.190876],
          [50.514328, 30.183899],
          [50.515556, 30.179897],
          [50.516987, 30.175293],
          [50.518017, 30.172546],
          [50.519814, 30.167705],
          [50.520584, 30.165517],
          [50.514355, 30.166051],
          [50.513893, 30.166061],
          [50.51487, 30.161662],
          [50.535321, 30.15311],
          [50.560476, 30.146713],
          [50.561237, 30.146229],
          [50.565403, 30.14401],
          [50.57179, 30.137902],
          [50.57457, 30.132895],
          [50.575403, 30.130676],
          [50.576237, 30.128177],
          [50.576513, 30.127347],
          [50.57679, 30.125957],
          [50.57707, 30.125398],
          [50.57679, 30.124568],
          [50.575403, 30.113733],
          [50.574846, 30.112623],
          [50.562624, 30.099289],
          [50.5604, 30.098179],
          [50.559567, 30.09734],
          [50.558734, 30.097069],
          [50.557347, 30.09596],
          [50.54818, 30.089843],
          [50.547624, 30.089013],
          [50.544847, 30.085675],
          [50.542624, 30.082346],
          [50.53929, 30.07318],
          [50.527624, 30.020397],
          [50.527901, 30.018177],
          [50.528177, 30.017348],
          [50.528457, 30.015399],
          [50.528457, 30.01456],
          [50.529011, 30.012061],
          [50.529844, 30.009842],
          [50.532067, 30.004014],
          [50.532344, 30.003175],
          [50.532624, 30.001786],
          [50.53482, 29.992891],
          [50.535124, 29.991791],
          [50.534011, 29.979008],
          [50.534291, 29.97623],
          [50.535678, 29.972622],
          [50.538734, 29.967065],
          [50.539291, 29.966785],
          [50.539844, 29.965955],
          [50.555401, 29.949014],
          [50.55568, 29.947896],
          [50.562904, 29.934013],
          [50.568737, 29.928177],
          [50.569847, 29.927058],
          [50.573737, 29.92456],
          [50.574847, 29.92456],
          [50.575404, 29.924839],
          [50.576237, 29.924839],
          [50.57818, 29.925398],
          [50.57929, 29.926228],
          [50.585127, 29.929286],
          [50.602627, 29.942069],
          [50.602903, 29.943449],
          [50.603183, 29.943728],
          [50.609293, 29.952343],
          [50.611236, 29.954562],
          [50.612626, 29.95734],
          [50.614293, 29.95762],
          [50.615126, 29.95734],
          [50.615959, 29.960119],
          [50.616793, 29.960119],
          [50.617349, 29.960399],
          [50.61874, 29.960398],
          [50.620126, 29.959839],
          [50.621516, 29.959839],
          [50.621793, 29.960398],
          [50.62235, 29.960678],
          [50.622626, 29.962067],
          [50.62235, 29.962338],
          [50.622073, 29.963177],
          [50.622626, 29.964007],
          [50.622906, 29.964286],
          [50.623459, 29.964846],
          [50.624293, 29.965125],
          [50.624573, 29.964846],
          [50.625406, 29.964846],
          [50.625959, 29.964566],
          [50.62735, 29.965955],
          [50.630406, 29.970403],
          [50.63374, 29.971232],
          [50.635959, 29.971233],
          [50.636516, 29.970953],
          [50.637906, 29.971512],
          [50.642906, 29.97706],
          [50.645962, 29.978729],
          [50.647906, 29.977899],
          [50.649849, 29.97734],
          [50.651516, 29.97734],
          [50.654572, 29.976789],
          [50.655962, 29.977899],
          [50.660129, 29.980118],
          [50.660962, 29.979288],
          [50.667072, 29.976789],
          [50.678185, 29.989283],
          [50.678742, 29.989843],
          [50.683462, 29.99401],
          [50.683742, 29.994561],
          [50.686519, 29.99567],
          [50.686795, 29.995959],
          [50.695409, 29.995959],
          [50.697075, 29.99567],
          [50.703742, 29.99567],
          [50.709018, 29.9954],
          [50.709851, 29.99512],
          [50.712632, 29.99512],
          [50.713741, 29.99484],
          [50.717075, 29.99484],
          [50.722075, 29.993451],
          [50.732911, 29.991232],
          [50.734298, 29.990952],
          [50.754301, 29.992621],
          [50.756801, 29.992621],
          [50.758187, 29.992341],
          [50.759301, 29.992341],
          [50.759854, 29.992062],
          [50.761244, 29.992062],
          [50.78291, 29.984286],
          [50.783744, 29.984015],
          [50.795134, 29.977619],
          [50.807357, 29.971512],
          [50.816247, 29.967065],
          [50.82347, 29.964846],
          [50.833746, 29.963457],
          [50.835413, 29.963457],
          [50.836526, 29.963177],
          [50.846526, 29.965676],
          [50.860416, 29.973732],
          [50.864859, 29.981228],
          [50.864859, 29.981787],
          [50.864855, 29.981822],
          [50.862639, 29.998728],
          [50.866526, 30.005124],
          [50.866806, 30.005675],
          [50.874582, 30.009563],
          [50.887082, 30.01456],
          [50.888195, 30.014849],
          [50.897362, 30.024844],
          [50.903195, 30.03484],
          [50.904585, 30.037339],
          [50.915972, 30.058449],
          [50.919585, 30.066234],
          [50.922918, 30.084007],
          [50.922642, 30.084846],
          [50.922362, 30.089014],
          [50.922362, 30.089844],
          [50.922085, 30.090403],
          [50.921808, 30.092343],
          [50.921528, 30.093732],
          [50.921808, 30.101229],
          [50.924585, 30.112623],
          [50.924861, 30.115122],
          [50.935418, 30.135954],
          [50.937085, 30.139572],
          [50.937361, 30.139842],
          [50.937361, 30.140122],
          [50.939031, 30.141232],
          [50.939308, 30.141791],
          [50.939865, 30.142062],
          [50.955974, 30.155955],
          [50.957921, 30.156786],
          [50.960698, 30.157904],
          [50.980697, 30.167071],
          [50.989587, 30.176237],
          [50.990362, 30.176344],
          [50.99153, 30.176797],
          [50.994867, 30.176797],
          [50.996254, 30.176237],
          [50.998754, 30.173738],
          [50.998754, 30.173458],
          [50.999853, 30.173458],
          [51.0132, 30.178736],
          [51.013477, 30.179016],
          [51.014033, 30.179016],
          [51.01431, 30.179296],
          [51.015423, 30.179296],
          [51.016534, 30.179566],
          [51.020143, 30.182345],
          [51.023477, 30.184014],
          [51.02459, 30.182625],
          [51.024867, 30.181795],
          [51.025977, 30.178186],
          [51.02709, 30.177347],
          [51.027923, 30.177347],
          [51.0282, 30.177067],
          [51.030423, 30.177067],
          [51.030977, 30.177347],
          [51.03209, 30.180956],
          [51.032643, 30.180956],
          [51.034313, 30.179296],
          [51.0382, 30.179566],
          [51.041813, 30.182066],
          [51.041813, 30.182345],
          [51.042646, 30.182345],
          [51.042923, 30.182625],
          [51.044313, 30.182625],
          [51.044313, 30.182345],
          [51.044866, 30.182345],
          [51.046256, 30.180956],
          [51.050146, 30.180956],
          [51.050423, 30.180676],
          [51.05237, 30.181795],
          [51.052923, 30.184014],
          [51.053203, 30.184574],
          [51.052923, 30.184853],
          [51.052646, 30.186513],
          [51.052923, 30.187073],
          [51.053203, 30.189572],
          [51.055703, 30.190402],
          [51.05598, 30.190122],
          [51.057089, 30.190122],
          [51.057646, 30.189843],
          [51.061256, 30.191512],
          [51.062369, 30.1954],
          [51.062646, 30.19596],
          [51.062369, 30.196239],
          [51.062923, 30.197349],
          [51.063479, 30.198459],
          [51.066256, 30.200399],
          [51.075426, 30.200399],
          [51.076536, 30.200128],
          [51.080146, 30.199009],
          [51.082092, 30.199009],
          [51.085146, 30.199569],
          [51.085979, 30.199848],
          [51.086812, 30.1979],
          [51.087369, 30.19402],
          [51.088479, 30.193461],
          [51.090149, 30.191512],
          [51.092092, 30.190952],
          [51.094592, 30.190953],
          [51.096536, 30.19513],
          [51.096259, 30.196239],
          [51.095702, 30.197349],
          [51.100425, 30.199569],
          [51.104035, 30.19707],
          [51.103482, 30.190122],
          [51.104036, 30.189013],
          [51.104592, 30.187623],
          [51.106535, 30.187623],
          [51.107649, 30.187903],
          [51.107925, 30.188183],
          [51.109039, 30.188183],
          [51.118205, 30.199009],
          [51.120148, 30.200678],
          [51.120982, 30.200679],
          [51.121539, 30.200958],
          [51.127092, 30.200958],
          [51.129315, 30.199289],
          [51.130982, 30.199289],
          [51.133762, 30.200679],
          [51.134595, 30.201238],
          [51.134595, 30.202068],
          [51.134315, 30.202627],
          [51.134872, 30.203187],
          [51.136538, 30.203737],
          [51.136815, 30.204017],
          [51.137372, 30.204017],
          [51.138205, 30.205686],
          [51.138205, 30.206236],
          [51.137095, 30.206796],
          [51.135428, 30.206796],
          [51.135148, 30.206516],
          [51.134038, 30.206516],
          [51.133482, 30.206796],
          [51.131815, 30.206796],
          [51.130428, 30.207346],
          [51.132095, 30.209295],
          [51.142371, 30.215683],
          [51.148262, 30.214089],
          [51.147371, 30.212624],
          [51.147095, 30.210684],
          [51.148761, 30.207626],
          [51.150985, 30.207626],
          [51.151818, 30.207905],
          [51.152371, 30.207905],
          [51.153205, 30.208185],
          [51.153761, 30.208456],
          [51.154595, 30.208736],
          [51.155151, 30.209566],
          [51.158485, 30.209295],
          [51.159318, 30.209015],
          [51.162651, 30.209015],
          [51.169318, 30.212065],
          [51.177928, 30.206236],
          [51.182094, 30.207905],
          [51.183208, 30.209295],
          [51.183208, 30.209845],
          [51.18365, 30.210516],
          [51.184041, 30.210955],
          [51.186264, 30.215683],
          [51.189874, 30.219012],
          [51.191264, 30.225959],
          [51.192374, 30.227069],
          [51.192931, 30.227349],
          [51.194597, 30.230678],
          [51.194597, 30.230958],
          [51.194874, 30.232347],
          [51.196817, 30.235406],
          [51.205987, 30.236236],
          [51.206264, 30.236516],
          [51.207374, 30.236516],
          [51.207654, 30.236795],
          [51.208207, 30.236795],
          [51.210154, 30.239015],
          [51.210707, 30.239295],
          [51.210987, 30.239854],
          [51.21154, 30.240125],
          [51.211541, 30.240404],
          [51.212374, 30.240404],
          [51.21293, 30.240684],
          [51.214874, 30.240684],
          [51.218487, 30.241794],
          [51.225154, 30.248182],
          [51.227654, 30.249021],
          [51.230154, 30.249292],
          [51.23043, 30.249571],
          [51.231263, 30.249571],
          [51.231544, 30.249851],
          [51.232654, 30.249851],
          [51.234597, 30.250681],
          [51.23543, 30.250681],
          [51.23793, 30.252071],
          [51.238763, 30.256798],
          [51.249043, 30.264017],
          [51.262933, 30.275683],
          [51.26321, 30.275963],
          [51.26349, 30.276233],
          [51.263766, 30.276513],
          [51.263766, 30.276793],
          [51.2646, 30.276793],
          [51.264876, 30.277073],
          [51.265433, 30.277072],
          [51.265433, 30.277352],
          [51.267656, 30.278742],
          [51.267933, 30.279012],
          [51.269043, 30.279572],
          [51.270709, 30.282351],
          [51.2721, 30.289019],
          [51.269043, 30.297356],
          [51.265433, 30.301795],
          [51.25182, 30.308463],
          [51.24071, 30.317621],
          [51.24043, 30.318181],
          [51.238763, 30.32124],
          [51.237653, 30.325128],
          [51.238487, 30.336245],
          [51.240919, 30.341513],
          [51.243486, 30.347073],
          [51.256266, 30.352351],
          [51.257376, 30.352351],
          [51.2596, 30.353181],
          [51.26099, 30.354021],
          [51.264043, 30.35763],
          [51.264043, 30.35791],
          [51.264323, 30.358189],
          [51.264323, 30.35846],
          [51.265156, 30.360129],
          [51.26821, 30.363739],
          [51.280156, 30.369017],
          [51.280713, 30.369017],
          [51.280989, 30.369297],
          [51.282379, 30.369297],
          [51.282656, 30.369577],
          [51.284323, 30.369577],
          [51.285989, 30.370127],
          [51.288766, 30.370127],
          [51.291266, 30.370687],
          [51.292656, 30.370687],
          [51.295156, 30.371796],
          [51.296266, 30.374296],
          [51.296546, 30.374576],
          [51.296822, 30.375965],
          [51.296822, 30.379015],
          [51.296546, 30.380405],
          [51.294879, 30.384023],
          [51.294879, 30.384574],
          [51.255433, 30.415686],
          [51.255433, 30.415966],
          [51.22043, 30.453739],
          [51.219597, 30.454299],
          [51.218487, 30.455968],
          [51.21432, 30.461518],
          [51.204597, 30.473465],
          [51.203207, 30.474855],
          [51.20265, 30.476245],
          [51.201264, 30.477634],
          [51.187097, 30.504851],
          [51.184874, 30.512909],
          [51.179041, 30.530687],
          [51.168761, 30.539855],
          [51.168484, 30.540135],
          [51.164037, 30.546245],
          [51.162371, 30.547634],
          [51.142928, 30.564023],
          [51.143204, 30.580691],
          [51.143761, 30.58764],
          [51.138761, 30.594029],
          [51.138205, 30.594859],
          [51.137928, 30.59541],
          [51.137648, 30.595689],
          [51.137094, 30.596249],
          [51.136538, 30.598469],
          [51.141538, 30.606799],
          [51.142647, 30.606799],
          [51.151538, 30.609299],
          [51.157094, 30.613468],
          [51.158484, 30.617358],
          [51.158761, 30.617908],
          [51.159037, 30.620688],
          [51.161817, 30.628747],
          [51.177094, 30.649305],
          [51.177374, 30.649856],
          [51.182374, 30.649856],
          [51.18404, 30.650415],
          [51.185984, 30.650415],
          [51.186817, 30.650695],
          [51.190983, 30.650695],
          [51.193764, 30.650966],
          [51.195707, 30.651525],
          [51.197097, 30.651525],
          [51.20293, 30.652915],
          [51.204597, 30.652915],
          [51.20654, 30.653466],
          [51.20793, 30.653745],
          [51.20904, 30.653745],
          [51.20904, 30.653466],
          [51.211263, 30.653195],
          [51.21543, 30.650695],
          [51.217097, 30.650136],
          [51.217653, 30.649576],
          [51.21932, 30.649025],
          [51.225153, 30.644856],
          [51.22793, 30.642356],
          [51.235986, 30.632916],
          [51.237653, 30.630687],
          [51.251819, 30.616518],
          [51.296822, 30.594029],
          [51.301545, 30.59069],
          [51.329878, 30.573471],
          [51.330712, 30.573192],
          [51.339048, 30.568472],
          [51.349601, 30.562633],
          [51.361548, 30.551795],
          [51.363215, 30.544575],
          [51.363491, 30.543745],
          [51.363771, 30.541245],
          [51.358771, 30.525688],
          [51.357105, 30.514579],
          [51.358771, 30.50541],
          [51.360158, 30.500411],
          [51.362105, 30.495962],
          [51.362381, 30.494573],
          [51.366548, 30.486244],
          [51.369881, 30.482354],
          [51.370158, 30.481524],
          [51.370158, 30.480964],
          [51.372381, 30.477914],
          [51.374328, 30.475965],
          [51.399327, 30.463738],
          [51.402661, 30.463467],
          [51.403494, 30.463187],
          [51.404884, 30.463467],
          [51.407104, 30.463467],
          [51.43183, 30.468187],
          [51.438497, 30.468187],
          [51.43933, 30.468466],
          [51.440717, 30.468187],
          [51.442384, 30.468187],
          [51.443497, 30.467907],
          [51.447107, 30.467907],
          [51.457387, 30.465128],
          [51.458497, 30.465128],
          [51.45933, 30.464857],
          [51.45933, 30.464577],
          [51.45933, 30.464018],
          [51.46072, 30.460968],
          [51.463773, 30.457909],
          [51.46544, 30.456799],
          [51.466273, 30.456799],
          [51.466553, 30.456519],
          [51.46711, 30.456519],
          [51.467663, 30.456239],
          [51.46822, 30.455968],
          [51.48322, 30.45124],
          [51.484053, 30.45124],
          [51.500719, 30.443182],
          [51.502666, 30.442072],
          [51.521276, 30.435683],
          [51.522109, 30.435683],
          [51.522942, 30.435683],
          [51.523499, 30.435403],
          [51.526279, 30.435403],
          [51.527112, 30.435683],
          [51.527946, 30.435683],
          [51.528499, 30.435403],
          [51.529056, 30.435683],
          [51.532665, 30.435133],
          [51.532946, 30.434853],
          [51.534889, 30.434853],
          [51.548779, 30.431794],
          [51.560722, 30.426515],
          [51.574612, 30.422355],
          [51.576278, 30.422355],
          [51.579058, 30.422355],
          [51.583781, 30.423465],
          [51.604058, 30.441242],
          [51.611281, 30.443741],
          [51.619338, 30.443741],
          [51.625171, 30.441242],
          [51.626004, 30.440962],
          [51.627394, 30.440403],
          [51.627394, 30.440132],
          [51.628228, 30.437632],
          [51.632114, 30.429015],
          [51.633227, 30.427914],
          [51.633781, 30.426795],
          [51.639617, 30.421796],
          [51.645451, 30.419296],
          [51.646561, 30.418746],
          [51.648784, 30.418466],
          [51.652117, 30.418466],
          [51.657674, 30.416526],
          [51.65795, 30.416246],
          [51.65906, 30.415967],
          [51.659479, 30.415687],
          [51.70323, 30.390403],
          [51.709063, 30.386514],
          [51.712953, 30.384024],
          [51.716843, 30.382075],
          [51.727676, 30.375406],
          [51.731009, 30.374017],
          [51.740733, 30.371797],
          [51.742119, 30.371797],
          [51.742953, 30.371517],
          [51.749066, 30.371517],
          [51.750176, 30.371797],
          [51.752123, 30.371797],
          [51.752956, 30.372077],
          [51.754066, 30.372077],
          [51.758789, 30.373737],
          [51.761289, 30.374856],
          [51.770732, 30.382075],
          [51.775732, 30.389293],
          [51.777122, 30.391802],
          [51.781565, 30.399021],
          [51.796845, 30.410959],
          [51.824901, 30.435404],
          [51.825735, 30.435963],
          [51.826291, 30.436523],
          [51.826291, 30.436802],
          [51.831291, 30.444572],
          [51.831568, 30.445682],
          [51.837401, 30.45374],
          [51.841848, 30.462628],
          [51.842958, 30.465687],
          [51.843234, 30.469577],
          [51.841568, 30.474016],
          [51.841015, 30.474576],
          [51.841014, 30.475135],
          [51.839901, 30.476245],
          [51.839624, 30.477076],
          [51.839068, 30.477355],
          [51.833515, 30.490133],
          [51.833515, 30.491243],
          [51.832681, 30.493743],
          [51.832401, 30.494853],
          [51.832124, 30.496802],
          [51.831848, 30.498462],
          [51.831568, 30.501242],
          [51.831291, 30.505411],
          [51.831848, 30.51208],
          [51.832958, 30.517359],
          [51.833514, 30.51846],
          [51.833514, 30.519299],
          [51.834068, 30.520138],
          [51.834348, 30.520689],
          [51.834348, 30.520969],
          [51.834348, 30.521248],
          [51.844068, 30.535416],
          [51.845458, 30.537907],
          [51.848238, 30.541246],
          [51.855738, 30.554024],
          [51.877961, 30.575412],
          [51.884627, 30.584301],
          [51.887684, 30.590131],
          [51.88796, 30.590691],
          [51.88796, 30.59208],
          [51.88796, 30.59236],
          [51.88796, 30.59319],
          [51.888237, 30.59347],
          [51.888237, 30.59819],
          [51.887404, 30.59986],
          [51.887127, 30.60097],
          [51.88046, 30.608749],
          [51.870461, 30.614858],
          [51.869904, 30.615689],
          [51.869347, 30.615968],
          [51.864347, 30.626518],
          [51.862957, 30.631527],
          [51.854904, 30.643187],
          [51.853514, 30.644297],
          [51.852124, 30.645967],
          [51.834068, 30.665975],
          [51.805455, 30.684304],
          [51.785178, 30.700694],
          [51.785455, 30.701524],
          [51.784898, 30.701804],
          [51.783512, 30.701804],
          [51.783232, 30.702084],
          [51.777122, 30.702084],
          [51.746566, 30.715414],
          [51.743785, 30.717083],
          [51.739899, 30.719024],
          [51.723509, 30.730693],
          [51.681006, 30.758473],
          [51.680173, 30.758753],
          [51.679063, 30.759583],
          [51.67823, 30.760413],
          [51.677673, 30.760693],
          [51.677673, 30.760973],
          [51.670173, 30.769033],
          [51.669893, 30.769583],
          [51.658466, 30.778523],
          [51.602391, 30.822355],
          [51.598501, 30.825695],
          [51.596558, 30.826805],
          [51.594614, 30.828475],
          [51.594614, 30.828755],
          [51.577668, 30.839856],
          [51.573225, 30.842916],
          [51.566278, 30.847366],
          [51.545721, 30.858476],
          [51.539332, 30.862087],
          [51.523499, 30.869307],
          [51.508499, 30.878478],
          [51.469053, 30.912921],
          [51.465163, 30.916811],
          [51.463219, 30.918472],
          [51.46246, 30.918931],
          [51.461829, 30.919582],
          [51.461829, 30.919862],
          [51.414883, 30.961806],
          [51.414327, 30.962086],
          [51.413733, 30.962877],
          [51.411827, 30.965146],
          [51.411273, 30.966247],
          [51.411273, 30.966527],
          [51.40877, 30.970417],
          [51.407103, 30.971257],
          [51.379327, 30.99487],
          [51.378351, 30.995839],
          [51.377661, 30.99653],
          [51.37738, 30.997361],
          [51.377104, 30.99959],
          [51.375994, 31.000972],
          [51.375994, 31.001251],
          [51.364047, 31.021805],
          [51.362937, 31.022924],
          [51.359881, 31.027924],
          [51.359047, 31.028755],
          [51.342101, 31.062641],
          [51.338491, 31.074872],
          [51.338491, 31.075982],
          [51.338214, 31.076533],
          [51.338214, 31.07737],
          [51.338214, 31.077372],
          [51.338216, 31.077921],
          [51.338214, 31.078483],
          [51.337934, 31.078762],
          [51.337657, 31.080974],
          [51.337102, 31.082921],
          [51.335991, 31.086255],
          [51.335434, 31.088755],
          [51.330991, 31.099596],
          [51.330434, 31.101257],
          [51.329878, 31.102088],
          [51.327101, 31.121261],
          [51.327659, 31.154306],
          [51.329023, 31.188942],
          [51.329046, 31.189315],
          [51.329601, 31.193477],
          [51.329879, 31.194314],
          [51.329877, 31.195985],
          [51.329877, 31.195986],
          [51.329876, 31.195987],
          [51.329872, 31.195988],
          [51.329868, 31.195989],
          [51.329866, 31.195989],
          [51.328768, 31.196257],
          [51.328491, 31.196537],
          [51.326824, 31.196537],
          [51.320712, 31.198476],
          [51.320158, 31.199037],
          [51.312103, 31.202087],
          [51.299045, 31.205429],
          [51.295431, 31.205699],
          [51.294598, 31.205979],
          [51.290155, 31.205979],
          [51.288211, 31.206259],
          [51.28544, 31.206259],
          [51.285436, 31.206259],
          [51.285431, 31.206259],
          [51.28543, 31.206259],
          [51.285429, 31.206259],
          [51.283492, 31.205978],
          [51.283491, 31.205977],
          [51.272656, 31.205977],
          [51.26849, 31.205977],
          [51.268487, 31.205977],
          [51.26599, 31.205977],
          [51.264877, 31.206257],
          [51.257933, 31.206257],
          [51.257929, 31.206258],
          [51.2521, 31.206808],
          [51.245432, 31.209319],
          [51.236542, 31.218202],
          [51.232691, 31.220402],
          [51.226819, 31.223763],
          [51.223774, 31.223761],
          [51.223769, 31.223761],
          [51.223764, 31.223761],
          [51.223763, 31.223761],
          [51.223763, 31.22376],
          [51.187374, 31.20959],
          [51.187373, 31.20959],
          [51.187373, 31.20959],
          [51.187367, 31.20959],
          [51.187362, 31.20959],
          [51.18404, 31.20959],
          [51.182926, 31.20987],
          [51.180426, 31.20987],
          [51.180426, 31.209869],
          [51.180426, 31.209869],
          [51.178761, 31.202646],
          [51.179041, 31.201257],
          [51.179041, 31.201256],
          [51.182928, 31.195984],
          [51.184317, 31.193206],
          [51.184318, 31.193204],
          [51.184596, 31.192366],
          [51.184874, 31.190983],
          [51.184873, 31.189875],
          [51.180149, 31.181813],
          [51.179041, 31.17487],
          [51.170985, 31.167088],
          [51.170708, 31.166529],
          [51.170708, 31.166529],
          [51.164595, 31.163477],
          [51.164595, 31.163477],
          [51.164038, 31.158757],
          [51.164038, 31.158757],
          [51.164038, 31.158756],
          [51.164316, 31.158478],
          [51.164317, 31.158478],
          [51.164318, 31.158477],
          [51.164318, 31.158477],
          [51.164595, 31.157646],
          [51.161538, 31.152365],
          [51.161538, 31.152365],
          [51.160152, 31.151535],
          [51.160152, 31.151535],
          [51.160151, 31.151535],
          [51.160151, 31.151535],
          [51.16015, 31.151534],
          [51.157928, 31.150695],
          [51.157928, 31.150695],
          [51.154872, 31.150145],
          [51.154871, 31.150145],
          [51.154866, 31.150145],
          [51.153766, 31.150147],
          [51.15376, 31.150147],
          [51.15376, 31.150146],
          [51.152927, 31.149867],
          [51.152927, 31.149867],
          [51.152921, 31.149867],
          [51.150983, 31.149867],
          [51.150427, 31.150147],
          [51.14876, 31.150147],
          [51.129039, 31.157366],
          [51.095701, 31.181813],
          [51.088758, 31.185424],
          [51.078478, 31.188764],
          [51.07154, 31.188764],
          [51.071534, 31.188764],
          [51.071534, 31.188764],
          [51.071534, 31.188764],
          [51.071533, 31.188764],
          [51.071504, 31.18875],
          [51.06952, 31.187817],
          [51.06952, 31.187817],
          [51.06952, 31.187817],
          [51.06952, 31.187817],
          [51.063403, 31.184947],
          [51.053761, 31.180423],
          [51.045984, 31.179042],
          [51.045983, 31.179041],
          [51.045702, 31.178753],
          [51.0407, 31.178751],
          [51.030977, 31.181811],
          [51.0207, 31.188762],
          [51.020423, 31.189033],
          [51.019595, 31.190145],
          [50.998246, 31.205648],
          [50.979163, 31.219503],
          [50.979163, 31.219503],
          [50.969442, 31.226557],
          [50.961376, 31.239882],
          [50.963202, 31.260423],
          [50.960425, 31.262652],
          [50.959592, 31.262923],
          [50.939036, 31.284318],
          [50.937089, 31.286539],
          [50.927366, 31.296532],
          [50.925423, 31.297652],
          [50.924589, 31.298482],
          [50.919893, 31.300931],
          [50.911809, 31.305145],
          [50.892366, 31.308755],
          [50.892363, 31.308755],
          [50.892363, 31.308755],
          [50.891253, 31.308485],
          [50.890694, 31.308205],
          [50.890694, 31.308205],
          [50.889033, 31.308205],
          [50.889033, 31.308205],
          [50.880143, 31.304594],
          [50.87819, 31.303483],
          [50.871804, 31.299592],
          [50.863747, 31.29265],
          [50.86014, 31.29071],
          [50.859857, 31.29042],
          [50.857087, 31.290421],
          [50.856807, 31.290709],
          [50.855691, 31.290709],
          [50.843744, 31.297092],
          [50.843191, 31.297651],
          [50.839305, 31.29904],
          [50.837634, 31.299592],
          [50.836244, 31.300143],
          [50.835968, 31.300432],
          [50.831244, 31.300703],
          [50.830411, 31.300983],
          [50.82653, 31.300983],
          [50.820417, 31.307925],
          [50.817917, 31.309315],
          [50.817078, 31.309315],
          [50.816801, 31.309595],
          [50.81405, 31.309595],
          [50.814026, 31.309595],
          [50.814026, 31.309595],
          [50.814021, 31.309595],
          [50.808188, 31.308484],
          [50.807911, 31.308205],
          [50.807911, 31.308205],
          [50.806251, 31.308205],
          [50.800694, 31.311256],
          [50.798745, 31.312095],
          [50.796245, 31.312926],
          [50.791798, 31.313756],
          [50.791528, 31.314036],
          [50.790694, 31.314037],
          [50.790131, 31.314316],
          [50.789298, 31.314316],
          [50.778746, 31.322366],
          [50.755409, 31.340704],
          [50.750686, 31.347374],
          [50.749852, 31.348756],
          [50.724572, 31.370152],
          [50.724019, 31.370983],
          [50.724579, 31.378482],
          [50.724296, 31.379315],
          [50.719852, 31.383206],
          [50.717629, 31.384037],
          [50.716519, 31.384597],
          [50.700963, 31.390988],
          [50.699296, 31.394039],
          [50.691239, 31.404322],
          [50.687906, 31.405983],
          [50.687356, 31.405983],
          [50.686793, 31.406263],
          [50.679016, 31.410705],
          [50.675683, 31.412095],
          [50.675126, 31.412375],
          [50.674293, 31.412375],
          [50.674016, 31.412655],
          [50.67096, 31.412655],
          [50.669016, 31.412926],
          [50.66235, 31.415147],
          [50.661793, 31.415426],
          [50.660127, 31.415426],
          [50.65596, 31.416537],
          [50.65568, 31.416817],
          [50.65457, 31.416817],
          [50.65457, 31.416817],
          [50.65457, 31.417096],
          [50.65457, 31.417096],
          [50.65457, 31.417097],
          [50.65207, 31.417367],
          [50.651513, 31.417097],
          [50.649013, 31.417367],
          [50.647347, 31.418207],
          [50.641793, 31.425989],
          [50.641237, 31.42682],
          [50.63957, 31.42765],
          [50.637903, 31.427379],
          [50.631514, 31.419877],
          [50.631514, 31.419877],
          [50.630124, 31.419038],
          [50.630124, 31.419037],
          [50.630124, 31.418758],
          [50.630124, 31.418758],
          [50.630124, 31.418758],
          [50.62179, 31.418487],
          [50.620404, 31.418758],
          [50.619847, 31.419038],
          [50.618737, 31.419037],
          [50.618181, 31.419317],
          [50.61318, 31.419317],
          [50.6129, 31.419597],
          [50.611514, 31.419597],
          [50.609014, 31.420148],
          [50.608457, 31.420428],
          [50.607067, 31.420428],
          [50.606234, 31.420708],
          [50.605957, 31.420708],
          [50.605401, 31.420428],
          [50.604847, 31.420428],
          [50.604567, 31.420148],
          [50.598177, 31.420148],
          [50.596791, 31.419597],
          [50.595124, 31.419597],
          [50.580401, 31.410154],
          [50.576788, 31.408764],
          [50.574844, 31.408764],
          [50.574568, 31.409044],
          [50.573734, 31.409044],
          [50.568734, 31.412375],
          [50.568178, 31.413205],
          [50.568178, 31.413485],
          [50.560678, 31.423488],
          [50.547621, 31.430431],
          [50.547065, 31.430431],
          [50.544845, 31.432651],
          [50.544565, 31.432931],
          [50.544288, 31.433762],
          [50.544012, 31.435152],
          [50.544288, 31.436542],
          [50.544012, 31.436822],
          [50.543731, 31.438483],
          [50.542621, 31.439594],
          [50.542345, 31.440154],
          [50.539008, 31.441264],
          [50.537898, 31.441264],
          [50.534288, 31.439874],
          [50.531508, 31.439874],
          [50.530955, 31.439874],
          [50.530398, 31.440154],
          [50.529565, 31.440154],
          [50.522618, 31.444875],
          [50.512342, 31.450427],
          [50.509285, 31.451258],
          [50.508175, 31.451818],
          [50.500395, 31.452648],
          [50.497619, 31.452377],
          [50.496509, 31.451818],
          [50.495675, 31.451818],
          [50.492062, 31.449877],
          [50.480396, 31.431261],
          [50.479562, 31.430151],
          [50.478151, 31.428919],
          [50.477339, 31.42821],
          [50.476229, 31.42765],
          [50.462339, 31.424598],
          [50.462063, 31.424319],
          [50.460949, 31.424319],
          [50.459563, 31.423759],
          [50.458173, 31.423759],
          [50.457063, 31.423488],
          [50.455673, 31.422928],
          [50.454563, 31.422928],
          [50.454283, 31.422648],
          [50.452896, 31.422648],
          [50.445393, 31.420707],
          [50.444283, 31.420707],
          [50.443726, 31.420428],
          [50.44234, 31.420428],
          [50.441783, 31.420148],
          [50.440393, 31.420148],
          [50.440116, 31.420012],
          [50.43984, 31.419877],
          [50.438173, 31.419877],
          [50.437616, 31.419597],
          [50.436783, 31.419597],
          [50.436226, 31.419317],
          [50.435116, 31.419317],
          [50.434559, 31.419037],
          [50.432616, 31.419037],
          [50.430393, 31.418487],
          [50.42956, 31.418487],
          [50.41595, 31.416266],
          [50.415117, 31.415986],
          [50.414283, 31.415986],
          [50.413727, 31.415706],
          [50.412617, 31.415706],
          [50.411783, 31.415426],
          [50.40928, 31.415426],
          [50.408446, 31.415146],
          [50.400947, 31.415146],
          [50.379557, 31.417367],
          [50.376224, 31.417927],
          [50.376224, 31.418207],
          [50.375114, 31.418207],
          [50.374837, 31.418486],
          [50.374281, 31.418486],
          [50.372614, 31.418757],
          [50.354281, 31.424869],
          [50.330668, 31.444875],
          [50.329278, 31.446265],
          [50.315668, 31.461261],
          [50.308721, 31.470153],
          [50.295108, 31.480707],
          [50.293242, 31.481571],
          [50.290661, 31.480586],
          [50.285354, 31.478094],
          [50.280712, 31.476191],
          [50.278992, 31.474927],
          [50.277172, 31.473588],
          [50.271999, 31.469567],
          [50.269835, 31.466034],
          [50.265766, 31.461082],
          [50.261971, 31.456129],
          [50.261188, 31.455109],
          [50.258176, 31.451179],
          [50.256799, 31.450222],
          [50.254091, 31.448338],
          [50.252295, 31.445205],
          [50.25081, 31.442615],
          [50.250233, 31.44139],
          [50.249394, 31.437522],
          [50.248389, 31.434072],
          [50.248033, 31.42198],
          [50.247922, 31.418217],
          [50.251833, 31.410538],
          [50.256156, 31.404694],
          [50.260067, 31.396648],
          [50.260125, 31.396586],
          [50.264811, 31.391539],
          [50.270004, 31.383501],
          [50.275177, 31.378029],
          [50.277407, 31.375721],
          [50.296779, 31.362099],
          [50.297332, 31.361819],
          [50.299555, 31.359038],
          [50.299832, 31.358759],
          [50.299832, 31.358479],
          [50.299555, 31.357928],
          [50.299278, 31.357648],
          [50.298693, 31.356128],
          [50.298113, 31.35462],
          [50.297888, 31.354037],
          [50.297566, 31.353763],
          [50.296743, 31.352473],
          [50.288256, 31.344356],
          [50.27636, 31.335121],
          [50.271018, 31.330101],
          [50.255151, 31.31519],
          [50.246761, 31.306489],
          [50.236351, 31.308735],
          [50.234371, 31.308741],
          [50.231747, 31.308611],
          [50.229763, 31.308434],
          [50.227989, 31.307934],
          [50.226326, 31.30734],
          [50.224979, 31.306745],
          [50.223633, 31.305923],
          [50.221855, 31.30473],
          [50.220665, 31.303721],
          [50.219372, 31.302757],
          [50.218342, 31.3017],
          [50.217743, 31.30069],
          [50.217198, 31.299541],
          [50.216865, 31.298438],
          [50.21669, 31.297284],
          [50.216789, 31.296317],
          [50.216831, 31.295442],
          [50.217922, 31.292262],
          [50.219116, 31.288802],
          [50.219318, 31.287743],
          [50.219364, 31.287004],
          [50.219143, 31.286452],
          [50.218712, 31.285949],
          [50.218227, 31.285813],
          [50.21764, 31.285814],
          [50.216679, 31.286092],
          [50.215561, 31.286512],
          [50.213642, 31.287163],
          [50.212418, 31.287492],
          [50.211453, 31.287587],
          [50.210705, 31.287634],
          [50.209312, 31.287409],
          [50.207756, 31.286861],
          [50.206467, 31.286129],
          [50.20528, 31.28535],
          [50.203556, 31.283882],
          [50.200852, 31.281082],
          [50.198425, 31.278789],
          [50.196644, 31.277275],
          [50.194813, 31.276037],
          [50.189282, 31.273063],
          [50.183151, 31.269264],
          [50.180084, 31.267156],
          [50.176693, 31.264269],
          [50.169869, 31.260102],
          [50.16568, 31.257677],
          [50.163429, 31.256625],
          [50.160103, 31.255212],
          [50.157675, 31.254167],
          [50.155224, 31.253111],
          [50.152706, 31.251832],
          [50.150955, 31.251338],
          [50.150455, 31.251197],
          [50.148422, 31.250879],
          [50.145485, 31.250661],
          [50.142494, 31.250487],
          [50.139927, 31.25045],
          [50.135281, 31.250376],
          [50.132931, 31.250477],
          [50.129993, 31.250349],
          [50.126949, 31.250405],
          [50.124279, 31.250554],
          [50.122463, 31.25056],
          [50.120968, 31.250383],
          [50.119099, 31.250298],
          [50.117439, 31.249982],
          [50.116745, 31.249936],
          [50.11372, 31.25294],
          [50.111492, 31.255065],
          [50.109261, 31.256685],
          [50.107555, 31.257474],
          [50.106594, 31.257892],
          [50.105263, 31.258081],
          [50.104031, 31.258086],
          [50.102817, 31.258059],
          [50.101574, 31.257911],
          [50.099861, 31.257594],
          [50.097828, 31.257095],
          [50.095154, 31.256323],
          [50.093063, 31.255458],
          [50.089958, 31.253812],
          [50.0799, 31.250591],
          [50.070789, 31.247445],
          [50.066063, 31.244659],
          [50.058739, 31.240189],
          [50.04591, 31.234334],
          [50.042381, 31.232857],
          [50.039551, 31.232026],
          [50.036984, 31.231333],
          [50.033779, 31.230501],
          [50.030464, 31.229944],
          [50.026829, 31.229758],
          [50.023251, 31.229752],
          [50.020203, 31.229934],
          [50.014916, 31.229787],
          [50.011407, 31.229975],
          [50.006921, 31.230802],
          [50.000012, 31.23304],
          [49.996841, 31.232762],
          [49.991627, 31.230617],
          [49.990273, 31.229109],
          [49.987835, 31.226042],
          [49.985893, 31.224349],
          [49.984478, 31.221647],
          [49.982804, 31.219998],
          [49.981232, 31.218213],
          [49.978321, 31.215745],
          [49.974827, 31.214425],
          [49.966564, 31.21229],
          [49.96217, 31.211569],
          [49.95874, 31.21144],
          [49.955742, 31.21145],
          [49.95467, 31.211405],
          [49.954124, 31.210166],
          [49.953468, 31.208059],
          [49.953278, 31.204255],
          [49.953125, 31.198384],
          [49.953571, 31.194163],
          [49.953608, 31.193725],
          [49.953641, 31.193333],
          [49.953964, 31.189482],
          [49.954464, 31.185078],
          [49.955738, 31.183699],
          [49.958858, 31.179333],
          [49.961507, 31.175813],
          [49.963146, 31.173634],
          [49.967857, 31.167796],
          [49.971077, 31.16265],
          [49.973557, 31.158605],
          [49.974769, 31.156114],
          [49.975658, 31.154286],
          [49.977806, 31.149328],
          [49.980641, 31.143172],
          [49.985691, 31.134169],
          [49.98785, 31.130722],
          [49.989163, 31.127874],
          [49.989994, 31.125673],
          [49.990029, 31.123239],
          [49.990416, 31.12067],
          [49.988535, 31.115095],
          [49.986223, 31.111641],
          [49.983707, 31.109305],
          [49.980924, 31.106721],
          [49.979346, 31.106027],
          [49.977973, 31.104737],
          [49.977922, 31.104507],
          [49.977784, 31.103883],
          [49.978095, 31.102521],
          [49.979195, 31.101592],
          [49.980691, 31.100751],
          [49.982259, 31.099243],
          [49.980744, 31.097671],
          [49.978586, 31.096826],
          [49.977538, 31.096022],
          [49.977012, 31.095791],
          [49.976046, 31.09458],
          [49.975841, 31.093653],
          [49.975457, 31.092936],
          [49.975108, 31.091797],
          [49.974162, 31.090539],
          [49.972878, 31.089281],
          [49.971179, 31.087585],
          [49.970111, 31.086414],
          [49.968283, 31.084466],
          [49.967139, 31.083129],
          [49.965538, 31.081716],
          [49.963445, 31.07988],
          [49.961345, 31.078613],
          [49.959771, 31.077577],
          [49.956872, 31.076985],
          [49.95397, 31.07662],
          [49.952885, 31.076425],
          [49.950675, 31.076025],
          [49.949486, 31.076016],
          [49.948695, 31.075895],
          [49.948037, 31.075663],
          [49.947518, 31.074863],
          [49.949514, 31.073515],
          [49.951254, 31.071369],
          [49.952319, 31.070582],
          [49.953122, 31.069679],
          [49.954858, 31.067874],
          [49.955798, 31.066517],
          [49.957131, 31.065391],
          [49.959404, 31.062795],
          [49.960573, 31.060506],
          [49.960619, 31.060417],
          [49.962352, 31.058839],
          [49.963168, 31.056686],
          [49.964786, 31.053629],
          [49.965988, 31.052389],
          [49.966666, 31.050689],
          [49.966674, 31.050007],
          [49.965894, 31.048863],
          [49.964978, 31.04806],
          [49.963045, 31.046752],
          [49.961962, 31.046146],
          [49.961082, 31.045116],
          [49.959899, 31.044595],
          [49.958726, 31.043221],
          [49.958239, 31.04245],
          [49.957612, 31.0402],
          [49.958718, 31.03693],
          [49.959103, 31.035631],
          [49.959612, 31.034616],
          [49.961621, 31.031989],
          [49.963128, 31.029955],
          [49.964036, 31.028428],
          [49.967888, 31.023129],
          [49.970564, 31.019854],
          [49.970839, 31.018833],
          [49.971397, 31.015995],
          [49.97143, 31.012926],
          [49.970029, 31.009432],
          [49.964371, 31.003046],
          [49.961508, 31.00007],
          [49.953347, 30.99746],
          [49.946512, 30.99648],
          [49.939412, 30.995149],
          [49.934724, 30.994186],
          [49.928177, 30.991472],
          [49.922672, 30.987096],
          [49.919548, 30.984528],
          [49.917126, 30.982879],
          [49.914173, 30.98128],
          [49.906174, 30.97657],
          [49.89909, 30.972547],
          [49.89513, 30.97086],
          [49.894774, 30.970741],
          [49.891865, 30.969769],
          [49.889618, 30.968812],
          [49.888649, 30.968124],
          [49.887737, 30.96739],
          [49.887259, 30.966782],
          [49.886978, 30.966425],
          [49.88649, 30.965367],
          [49.886318, 30.964446],
          [49.886212, 30.96426],
        ],
      ],
    },
  },
  Kordestan: {
    properties: { value: "Kordestan", name: "استان کردستان, ایران", id: 20 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [45.551544, 36.065804],
          [45.55571, 36.049142],
          [45.555987, 36.046086],
          [45.556263, 36.045528],
          [45.556264, 36.043032],
          [45.556544, 36.036642],
          [45.556821, 36.035524],
          [45.556821, 36.033857],
          [45.557097, 36.033028],
          [45.557654, 36.012751],
          [45.558211, 36.00692],
          [45.558487, 36.004694],
          [45.558768, 36.004415],
          [45.558487, 35.999971],
          [45.557974, 35.995103],
          [45.558451, 35.994858],
          [45.559502, 35.994892],
          [45.560241, 35.994731],
          [45.561292, 35.991828],
          [45.562121, 35.989948],
          [45.563105, 35.989822],
          [45.564206, 35.989786],
          [45.565369, 35.989207],
          [45.567144, 35.987631],
          [45.568368, 35.986426],
          [45.568867, 35.984745],
          [45.570559, 35.984648],
          [45.574054, 35.98288],
          [45.575726, 35.980252],
          [45.576332, 35.978044],
          [45.574528, 35.976515],
          [45.574916, 35.975008],
          [45.577513, 35.975279],
          [45.582028, 35.971977],
          [45.583614, 35.970625],
          [45.584918, 35.970749],
          [45.585506, 35.972109],
          [45.586965, 35.972878],
          [45.584976, 35.975046],
          [45.584485, 35.977244],
          [45.586124, 35.977708],
          [45.587724, 35.977489],
          [45.589397, 35.977581],
          [45.591251, 35.976829],
          [45.591299, 35.975386],
          [45.590632, 35.975231],
          [45.591133, 35.974477],
          [45.592752, 35.973691],
          [45.594325, 35.974328],
          [45.596068, 35.972695],
          [45.595549, 35.969825],
          [45.596082, 35.968673],
          [45.594274, 35.967209],
          [45.594931, 35.96528],
          [45.595284, 35.96493],
          [45.596019, 35.964837],
          [45.597479, 35.963996],
          [45.598612, 35.963324],
          [45.599173, 35.962067],
          [45.600269, 35.960271],
          [45.601714, 35.95964],
          [45.602317, 35.959581],
          [45.604202, 35.958059],
          [45.605318, 35.958155],
          [45.605973, 35.958416],
          [45.606542, 35.958482],
          [45.606421, 35.959671],
          [45.607613, 35.960793],
          [45.608323, 35.961113],
          [45.608938, 35.960812],
          [45.609368, 35.96029],
          [45.609525, 35.958717],
          [45.610237, 35.95747],
          [45.612762, 35.957121],
          [45.614664, 35.957],
          [45.616421, 35.956928],
          [45.617747, 35.955543],
          [45.618266, 35.955557],
          [45.619466, 35.956476],
          [45.619985, 35.956508],
          [45.620787, 35.958181],
          [45.621772, 35.958659],
          [45.622958, 35.957784],
          [45.622925, 35.957687],
          [45.622743, 35.957488],
          [45.622959, 35.956954],
          [45.623441, 35.956235],
          [45.623377, 35.955983],
          [45.623223, 35.955768],
          [45.622895, 35.955633],
          [45.622688, 35.955164],
          [45.62268, 35.954757],
          [45.622872, 35.954568],
          [45.623415, 35.953688],
          [45.624108, 35.952811],
          [45.624519, 35.952507],
          [45.624819, 35.952172],
          [45.624966, 35.951554],
          [45.625302, 35.951111],
          [45.625897, 35.950642],
          [45.626162, 35.950318],
          [45.626117, 35.949453],
          [45.625839, 35.949024],
          [45.625734, 35.948622],
          [45.625924, 35.948109],
          [45.626288, 35.947707],
          [45.626598, 35.947258],
          [45.626739, 35.946881],
          [45.627081, 35.946679],
          [45.627528, 35.946553],
          [45.627932, 35.946485],
          [45.628424, 35.946503],
          [45.628883, 35.946354],
          [45.629592, 35.945843],
          [45.630026, 35.945664],
          [45.631175, 35.945426],
          [45.63161, 35.945409],
          [45.632175, 35.945023],
          [45.632677, 35.944822],
          [45.633384, 35.944781],
          [45.634182, 35.944893],
          [45.635016, 35.945084],
          [45.635374, 35.9449],
          [45.636076, 35.944436],
          [45.636615, 35.944256],
          [45.636921, 35.94398],
          [45.637358, 35.943784],
          [45.637753, 35.943785],
          [45.638371, 35.94395],
          [45.639396, 35.944326],
          [45.639842, 35.944385],
          [45.641363, 35.942418],
          [45.641618, 35.941704],
          [45.642389, 35.94087],
          [45.643035, 35.940515],
          [45.643434, 35.939889],
          [45.644699, 35.938736],
          [45.645712, 35.938421],
          [45.646005, 35.938137],
          [45.646253, 35.937715],
          [45.646457, 35.93721],
          [45.646971, 35.936654],
          [45.647531, 35.935922],
          [45.647699, 35.935368],
          [45.64811, 35.935008],
          [45.648795, 35.934714],
          [45.649393, 35.934524],
          [45.649848, 35.934571],
          [45.650234, 35.934797],
          [45.650574, 35.935255],
          [45.651016, 35.935617],
          [45.65187, 35.935787],
          [45.653106, 35.935854],
          [45.654793, 35.934378],
          [45.655117, 35.933972],
          [45.655564, 35.933063],
          [45.655886, 35.93276],
          [45.656221, 35.932624],
          [45.656658, 35.932357],
          [45.657145, 35.931974],
          [45.658931, 35.930787],
          [45.65925, 35.930501],
          [45.659478, 35.930129],
          [45.659845, 35.929958],
          [45.660285, 35.929838],
          [45.660703, 35.929598],
          [45.661441, 35.929291],
          [45.661967, 35.929162],
          [45.66257, 35.928952],
          [45.662986, 35.9284],
          [45.663409, 35.92796],
          [45.665625, 35.926916],
          [45.666691, 35.925676],
          [45.667114, 35.925264],
          [45.667471, 35.924813],
          [45.667602, 35.924474],
          [45.667988, 35.924012],
          [45.668078, 35.923441],
          [45.668063, 35.923076],
          [45.667882, 35.92284],
          [45.668547, 35.922133],
          [45.669233, 35.921603],
          [45.669957, 35.920908],
          [45.669965, 35.919819],
          [45.670142, 35.91916],
          [45.670027, 35.917184],
          [45.669977, 35.916337],
          [45.670531, 35.916239],
          [45.670829, 35.916032],
          [45.670699, 35.915577],
          [45.670394, 35.91518],
          [45.670301, 35.914805],
          [45.670619, 35.913386],
          [45.671262, 35.912722],
          [45.671612, 35.911774],
          [45.671999, 35.911289],
          [45.67223, 35.911113],
          [45.673201, 35.910558],
          [45.674613, 35.909831],
          [45.67477, 35.909635],
          [45.674878, 35.908597],
          [45.674992, 35.90813],
          [45.675041, 35.907136],
          [45.674987, 35.906466],
          [45.675099, 35.906129],
          [45.675688, 35.905503],
          [45.675899, 35.905104],
          [45.676186, 35.90434],
          [45.676412, 35.903966],
          [45.676555, 35.903502],
          [45.677038, 35.902614],
          [45.677705, 35.902114],
          [45.67834, 35.901755],
          [45.679248, 35.901377],
          [45.679975, 35.899312],
          [45.680528, 35.897988],
          [45.680761, 35.897862],
          [45.68165, 35.897803],
          [45.682639, 35.897863],
          [45.68323, 35.898169],
          [45.683724, 35.898326],
          [45.684304, 35.89868],
          [45.685741, 35.898853],
          [45.686462, 35.899082],
          [45.687104, 35.899474],
          [45.68757, 35.899373],
          [45.688032, 35.899102],
          [45.689283, 35.899188],
          [45.689622, 35.899374],
          [45.690267, 35.89993],
          [45.691154, 35.90008],
          [45.692885, 35.899746],
          [45.69396, 35.899126],
          [45.694639, 35.897929],
          [45.695103, 35.897406],
          [45.695702, 35.897303],
          [45.696159, 35.897061],
          [45.69647, 35.896702],
          [45.69664, 35.896338],
          [45.697095, 35.895606],
          [45.697275, 35.89515],
          [45.697367, 35.894711],
          [45.697036, 35.894206],
          [45.696439, 35.893788],
          [45.696068, 35.893351],
          [45.695506, 35.892413],
          [45.695463, 35.89212],
          [45.695593, 35.89165],
          [45.695467, 35.891085],
          [45.69543, 35.890511],
          [45.695493, 35.890221],
          [45.696112, 35.889541],
          [45.696219, 35.888766],
          [45.695954, 35.88794],
          [45.696007, 35.88744],
          [45.696682, 35.886577],
          [45.696731, 35.885715],
          [45.697595, 35.885563],
          [45.698132, 35.885359],
          [45.698585, 35.88532],
          [45.699292, 35.885364],
          [45.699965, 35.885323],
          [45.700504, 35.885171],
          [45.701282, 35.885133],
          [45.701969, 35.884968],
          [45.70298, 35.88485],
          [45.703499, 35.884883],
          [45.704018, 35.885179],
          [45.704604, 35.88529],
          [45.70493, 35.885253],
          [45.705346, 35.884942],
          [45.706212, 35.883666],
          [45.706585, 35.883499],
          [45.707509, 35.883397],
          [45.708714, 35.882918],
          [45.709309, 35.882627],
          [45.709988, 35.882125],
          [45.710393, 35.881598],
          [45.710721, 35.881394],
          [45.71109, 35.881039],
          [45.711531, 35.880822],
          [45.711986, 35.880527],
          [45.712201, 35.880246],
          [45.712725, 35.879818],
          [45.714555, 35.877934],
          [45.714739, 35.877538],
          [45.71489, 35.877012],
          [45.71475, 35.876487],
          [45.714699, 35.875856],
          [45.71485, 35.87542],
          [45.715224, 35.874952],
          [45.715696, 35.874503],
          [45.716501, 35.873954],
          [45.717376, 35.872926],
          [45.717438, 35.872507],
          [45.717461, 35.872201],
          [45.717223, 35.871784],
          [45.716729, 35.871408],
          [45.716257, 35.870152],
          [45.715992, 35.869808],
          [45.715868, 35.869251],
          [45.71591, 35.868843],
          [45.715806, 35.868538],
          [45.715432, 35.868395],
          [45.715507, 35.867672],
          [45.717003, 35.866363],
          [45.717555, 35.866046],
          [45.717666, 35.865731],
          [45.716899, 35.864006],
          [45.716446, 35.86326],
          [45.716289, 35.86267],
          [45.716445, 35.862294],
          [45.717055, 35.861578],
          [45.718231, 35.861339],
          [45.71912, 35.861107],
          [45.719918, 35.861013],
          [45.72075, 35.861003],
          [45.722825, 35.861224],
          [45.723118, 35.861148],
          [45.723368, 35.860913],
          [45.723562, 35.859896],
          [45.724134, 35.858992],
          [45.724592, 35.8581],
          [45.724414, 35.857167],
          [45.724435, 35.856763],
          [45.724664, 35.85614],
          [45.724918, 35.855656],
          [45.724735, 35.855275],
          [45.724639, 35.854825],
          [45.724634, 35.854237],
          [45.724896, 35.853827],
          [45.72528, 35.853613],
          [45.72525, 35.853369],
          [45.725036, 35.852956],
          [45.725225, 35.852446],
          [45.726015, 35.851921],
          [45.72642, 35.851569],
          [45.72661, 35.850706],
          [45.727207, 35.849937],
          [45.727545, 35.849417],
          [45.727973, 35.848376],
          [45.728407, 35.847796],
          [45.728757, 35.84743],
          [45.730062, 35.846689],
          [45.730676, 35.846272],
          [45.731247, 35.845962],
          [45.732181, 35.845657],
          [45.733241, 35.84507],
          [45.733995, 35.844825],
          [45.734334, 35.844602],
          [45.734394, 35.844301],
          [45.734345, 35.84398],
          [45.734455, 35.843603],
          [45.734695, 35.84321],
          [45.735092, 35.842976],
          [45.735773, 35.84243],
          [45.736933, 35.842055],
          [45.737331, 35.841821],
          [45.737446, 35.841534],
          [45.737341, 35.841125],
          [45.737744, 35.839957],
          [45.738062, 35.839622],
          [45.738612, 35.839201],
          [45.738803, 35.838891],
          [45.738808, 35.838651],
          [45.738614, 35.837813],
          [45.738744, 35.837177],
          [45.73875, 35.836721],
          [45.738353, 35.836006],
          [45.738234, 35.835508],
          [45.738565, 35.835147],
          [45.738939, 35.834867],
          [45.739914, 35.834785],
          [45.740029, 35.834606],
          [45.739917, 35.834355],
          [45.739664, 35.834041],
          [45.739274, 35.833712],
          [45.739173, 35.83317],
          [45.738944, 35.83276],
          [45.738848, 35.831903],
          [45.738897, 35.830614],
          [45.738604, 35.83034],
          [45.738416, 35.82989],
          [45.738471, 35.82774],
          [45.739771, 35.82742],
          [45.740926, 35.827245],
          [45.7417, 35.827144],
          [45.743259, 35.827047],
          [45.743748, 35.827067],
          [45.744822, 35.826825],
          [45.745185, 35.826688],
          [45.74613, 35.826496],
          [45.746581, 35.826161],
          [45.747013, 35.825977],
          [45.747877, 35.825388],
          [45.748156, 35.824987],
          [45.748661, 35.824442],
          [45.749126, 35.823641],
          [45.749528, 35.822762],
          [45.750085, 35.822034],
          [45.750546, 35.820928],
          [45.75072, 35.820374],
          [45.751583, 35.818976],
          [45.751917, 35.818514],
          [45.75278, 35.818032],
          [45.753119, 35.817628],
          [45.753504, 35.816983],
          [45.753934, 35.816023],
          [45.754512, 35.815514],
          [45.754966, 35.815005],
          [45.755099, 35.814781],
          [45.755639, 35.813552],
          [45.755835, 35.812895],
          [45.755646, 35.811891],
          [45.755438, 35.811114],
          [45.755357, 35.810535],
          [45.755358, 35.809915],
          [45.755497, 35.808723],
          [45.755606, 35.808487],
          [45.756061, 35.808093],
          [45.756661, 35.807332],
          [45.75687, 35.806978],
          [45.757639, 35.806786],
          [45.758913, 35.806555],
          [45.760201, 35.806378],
          [45.760588, 35.805923],
          [45.760927, 35.804858],
          [45.761858, 35.803628],
          [45.762048, 35.803244],
          [45.762318, 35.802663],
          [45.762805, 35.801246],
          [45.762865, 35.800508],
          [45.763041, 35.799961],
          [45.763235, 35.799626],
          [45.763701, 35.799014],
          [45.764158, 35.799104],
          [45.767009, 35.801568],
          [45.770137, 35.805666],
          [45.771725, 35.808866],
          [45.773072, 35.813314],
          [45.780287, 35.81056],
          [45.791769, 35.812532],
          [45.791901, 35.812167],
          [45.791901, 35.812165],
          [45.791902, 35.812165],
          [45.792381, 35.812254],
          [45.792541, 35.812398],
          [45.792737, 35.81245],
          [45.793047, 35.812568],
          [45.793401, 35.812655],
          [45.793853, 35.812539],
          [45.794287, 35.812438],
          [45.794568, 35.812428],
          [45.794955, 35.812452],
          [45.795202, 35.812507],
          [45.795525, 35.812721],
          [45.795813, 35.812904],
          [45.795983, 35.813181],
          [45.796113, 35.813366],
          [45.796324, 35.813441],
          [45.796452, 35.813623],
          [45.796623, 35.813845],
          [45.796954, 35.814091],
          [45.797397, 35.814219],
          [45.797579, 35.814374],
          [45.797863, 35.814595],
          [45.798124, 35.814738],
          [45.79834, 35.814737],
          [45.79844, 35.814854],
          [45.79862, 35.815092],
          [45.798605, 35.815367],
          [45.79851, 35.815617],
          [45.798132, 35.816029],
          [45.798145, 35.816407],
          [45.798314, 35.816754],
          [45.798398, 35.817125],
          [45.798501, 35.817452],
          [45.79865, 35.817803],
          [45.798626, 35.818155],
          [45.798598, 35.818532],
          [45.798583, 35.818882],
          [45.798749, 35.819106],
          [45.798849, 35.819317],
          [45.798901, 35.819538],
          [45.799075, 35.819827],
          [45.799185, 35.820111],
          [45.799329, 35.820331],
          [45.799555, 35.820658],
          [45.799606, 35.820987],
          [45.799645, 35.821343],
          [45.799556, 35.821724],
          [45.799359, 35.822051],
          [45.79907, 35.822487],
          [45.799163, 35.82289],
          [45.799144, 35.823299],
          [45.79912, 35.823799],
          [45.799261, 35.824118],
          [45.799528, 35.824446],
          [45.799525, 35.824728],
          [45.799627, 35.825129],
          [45.799826, 35.825619],
          [45.800144, 35.826383],
          [45.80038, 35.827039],
          [45.800704, 35.826984],
          [45.8011, 35.826783],
          [45.801816, 35.826363],
          [45.802305, 35.826161],
          [45.802714, 35.825858],
          [45.803056, 35.82561],
          [45.803866, 35.825121],
          [45.80428, 35.824875],
          [45.804739, 35.82453],
          [45.805284, 35.82416],
          [45.805798, 35.823903],
          [45.806396, 35.823539],
          [45.807024, 35.823466],
          [45.807394, 35.823427],
          [45.807957, 35.823491],
          [45.808384, 35.823507],
          [45.8092, 35.82348],
          [45.80959, 35.823586],
          [45.810231, 35.823196],
          [45.810896, 35.822885],
          [45.811542, 35.822619],
          [45.812128, 35.822142],
          [45.812779, 35.821602],
          [45.813252, 35.821344],
          [45.813595, 35.82117],
          [45.814035, 35.820944],
          [45.814629, 35.820639],
          [45.815898, 35.820284],
          [45.816538, 35.820069],
          [45.817296, 35.819764],
          [45.81762, 35.819486],
          [45.818001, 35.819049],
          [45.818576, 35.818714],
          [45.819274, 35.818305],
          [45.81985, 35.817937],
          [45.820545, 35.817661],
          [45.821357, 35.817255],
          [45.822397, 35.816888],
          [45.823048, 35.816678],
          [45.823409, 35.816534],
          [45.823639, 35.816216],
          [45.823873, 35.815766],
          [45.824381, 35.815486],
          [45.824962, 35.8151],
          [45.82541, 35.814896],
          [45.826206, 35.81445],
          [45.827017, 35.813943],
          [45.827562, 35.813571],
          [45.828142, 35.813027],
          [45.828479, 35.812513],
          [45.829163, 35.811791],
          [45.829417, 35.811419],
          [45.829902, 35.810934],
          [45.830873, 35.809891],
          [45.831285, 35.809702],
          [45.832152, 35.80983],
          [45.832823, 35.809864],
          [45.833385, 35.81006],
          [45.833883, 35.810161],
          [45.834444, 35.810233],
          [45.834908, 35.810398],
          [45.835579, 35.810813],
          [45.836, 35.811351],
          [45.836439, 35.811803],
          [45.837103, 35.812269],
          [45.837631, 35.812679],
          [45.838336, 35.812996],
          [45.839039, 35.813379],
          [45.839865, 35.813623],
          [45.840683, 35.813876],
          [45.841407, 35.813958],
          [45.841405, 35.81395],
          [45.842039, 35.814074],
          [45.84248, 35.814111],
          [45.842918, 35.814174],
          [45.84354, 35.814176],
          [45.844261, 35.813977],
          [45.844791, 35.813856],
          [45.845246, 35.813908],
          [45.845604, 35.814086],
          [45.846095, 35.814353],
          [45.846442, 35.814683],
          [45.847159, 35.814931],
          [45.847912, 35.815239],
          [45.848621, 35.815497],
          [45.849232, 35.815683],
          [45.850033, 35.816023],
          [45.850746, 35.816487],
          [45.851345, 35.816783],
          [45.852072, 35.817071],
          [45.852417, 35.817393],
          [45.853224, 35.81764],
          [45.853957, 35.818026],
          [45.854684, 35.818272],
          [45.855327, 35.818428],
          [45.856012, 35.818401],
          [45.856534, 35.818249],
          [45.856986, 35.818251],
          [45.857375, 35.818166],
          [45.857953, 35.818077],
          [45.858501, 35.818177],
          [45.859157, 35.81823],
          [45.859729, 35.818308],
          [45.860197, 35.818407],
          [45.861004, 35.818654],
          [45.861444, 35.818841],
          [45.861812, 35.818868],
          [45.862402, 35.818669],
          [45.862824, 35.818404],
          [45.863627, 35.817866],
          [45.864251, 35.817222],
          [45.864812, 35.816913],
          [45.865282, 35.816829],
          [45.866706, 35.816252],
          [45.867674, 35.815929],
          [45.868671, 35.815714],
          [45.869133, 35.815464],
          [45.869686, 35.815082],
          [45.870262, 35.81468],
          [45.870816, 35.814317],
          [45.871392, 35.813859],
          [45.872014, 35.8134],
          [45.87222, 35.813096],
          [45.872839, 35.81241],
          [45.873554, 35.811894],
          [45.87388, 35.811614],
          [45.874114, 35.81132],
          [45.8744, 35.811338],
          [45.874868, 35.811362],
          [45.875358, 35.811473],
          [45.875917, 35.811578],
          [45.876395, 35.811832],
          [45.876772, 35.812123],
          [45.877195, 35.812131],
          [45.877859, 35.812174],
          [45.878564, 35.812417],
          [45.879193, 35.812633],
          [45.87944, 35.812733],
          [45.879776, 35.813023],
          [45.879976, 35.813365],
          [45.879955, 35.813783],
          [45.87979, 35.814113],
          [45.879596, 35.814673],
          [45.879594, 35.815128],
          [45.87969, 35.815648],
          [45.879826, 35.816168],
          [45.879885, 35.816545],
          [45.880106, 35.816932],
          [45.880192, 35.817147],
          [45.880255, 35.81754],
          [45.880281, 35.817718],
          [45.880432, 35.817911],
          [45.88076, 35.818129],
          [45.881102, 35.818285],
          [45.881334, 35.818521],
          [45.881411, 35.818779],
          [45.881331, 35.819084],
          [45.881349, 35.819346],
          [45.881352, 35.819743],
          [45.88136, 35.820155],
          [45.881461, 35.82035],
          [45.881526, 35.820561],
          [45.881544, 35.820897],
          [45.881613, 35.821124],
          [45.881799, 35.821336],
          [45.881886, 35.821592],
          [45.881814, 35.821813],
          [45.881718, 35.822063],
          [45.881744, 35.822315],
          [45.88186, 35.822566],
          [45.88183, 35.822911],
          [45.881889, 35.823139],
          [45.882051, 35.823488],
          [45.882121, 35.823872],
          [45.882051, 35.824142],
          [45.881861, 35.824412],
          [45.881911, 35.824716],
          [45.881881, 35.824987],
          [45.881853, 35.825266],
          [45.881679, 35.825557],
          [45.881594, 35.825731],
          [45.881659, 35.825941],
          [45.881759, 35.82617],
          [45.881769, 35.826591],
          [45.881733, 35.826838],
          [45.881833, 35.827298],
          [45.881954, 35.827837],
          [45.881935, 35.828148],
          [45.881978, 35.828545],
          [45.882194, 35.829214],
          [45.882226, 35.829721],
          [45.882067, 35.83015],
          [45.881905, 35.83043],
          [45.881711, 35.830675],
          [45.882179, 35.830767],
          [45.88259, 35.830884],
          [45.883117, 35.831136],
          [45.883691, 35.831412],
          [45.8842, 35.831553],
          [45.884828, 35.831653],
          [45.885341, 35.83185],
          [45.885664, 35.83182],
          [45.886018, 35.831676],
          [45.88672, 35.831637],
          [45.887245, 35.831766],
          [45.88773, 35.83201],
          [45.888034, 35.832365],
          [45.888145, 35.83279],
          [45.888276, 35.833369],
          [45.888423, 35.833853],
          [45.888872, 35.834576],
          [45.889092, 35.835146],
          [45.88933, 35.835521],
          [45.88978, 35.835516],
          [45.890716, 35.835448],
          [45.891478, 35.835373],
          [45.891989, 35.835405],
          [45.89238, 35.835519],
          [45.893038, 35.835696],
          [45.893671, 35.835778],
          [45.894012, 35.835778],
          [45.894512, 35.836193],
          [45.895008, 35.836824],
          [45.895664, 35.837415],
          [45.896036, 35.837649],
          [45.896501, 35.83769],
          [45.896827, 35.837622],
          [45.897175, 35.837516],
          [45.897932, 35.837186],
          [45.898427, 35.836931],
          [45.899089, 35.836797],
          [45.9, 35.836502],
          [45.900559, 35.836339],
          [45.901061, 35.836397],
          [45.9015, 35.836485],
          [45.90177, 35.836539],
          [45.902145, 35.836917],
          [45.902542, 35.837054],
          [45.903043, 35.837089],
          [45.903602, 35.837099],
          [45.904045, 35.83695],
          [45.904788, 35.836663],
          [45.905441, 35.836456],
          [45.90605, 35.836523],
          [45.906343, 35.836437],
          [45.906485, 35.836152],
          [45.906742, 35.835774],
          [45.907095, 35.835511],
          [45.907497, 35.835324],
          [45.908067, 35.835241],
          [45.908816, 35.834995],
          [45.90955, 35.83483],
          [45.910171, 35.834634],
          [45.910744, 35.83414],
          [45.911269, 35.833884],
          [45.911934, 35.833782],
          [45.912358, 35.833658],
          [45.912744, 35.833156],
          [45.91332, 35.832596],
          [45.914021, 35.832553],
          [45.914883, 35.832707],
          [45.91545, 35.832877],
          [45.915998, 35.832927],
          [45.916025, 35.832929],
          [45.916377, 35.83276],
          [45.916786, 35.832733],
          [45.917189, 35.832813],
          [45.917746, 35.83272],
          [45.918597, 35.832404],
          [45.919771, 35.831908],
          [45.920407, 35.831788],
          [45.921268, 35.831648],
          [45.921739, 35.831605],
          [45.92204, 35.831567],
          [45.922368, 35.831593],
          [45.922972, 35.831698],
          [45.923571, 35.831783],
          [45.924022, 35.831612],
          [45.9248, 35.831418],
          [45.924972, 35.831347],
          [45.925496, 35.831049],
          [45.926085, 35.831],
          [45.926428, 35.830837],
          [45.926969, 35.830603],
          [45.927879, 35.830383],
          [45.928193, 35.830259],
          [45.928973, 35.829882],
          [45.929272, 35.829762],
          [45.93006, 35.829417],
          [45.930307, 35.829289],
          [45.93069, 35.829044],
          [45.931308, 35.828666],
          [45.932005, 35.828339],
          [45.932248, 35.828097],
          [45.932344, 35.82785],
          [45.932387, 35.827573],
          [45.932417, 35.827054],
          [45.932339, 35.826258],
          [45.932379, 35.826084],
          [45.93276, 35.82572],
          [45.933114, 35.825443],
          [45.933394, 35.825132],
          [45.933539, 35.824863],
          [45.93417, 35.824321],
          [45.934583, 35.824116],
          [45.935183, 35.824031],
          [45.935854, 35.824048],
          [45.936148, 35.82402],
          [45.936574, 35.823886],
          [45.937388, 35.82366],
          [45.938274, 35.823615],
          [45.938791, 35.823484],
          [45.939586, 35.82351],
          [45.940173, 35.823759],
          [45.940909, 35.824136],
          [45.941035, 35.824447],
          [45.941132, 35.8248],
          [45.941601, 35.824903],
          [45.942041, 35.825052],
          [45.942722, 35.825469],
          [45.943018, 35.825664],
          [45.943557, 35.825616],
          [45.944113, 35.825552],
          [45.944431, 35.82537],
          [45.944703, 35.825316],
          [45.945358, 35.825482],
          [45.946092, 35.825619],
          [45.946377, 35.825753],
          [45.946856, 35.8258],
          [45.947273, 35.8259],
          [45.947811, 35.826093],
          [45.948035, 35.826128],
          [45.948368, 35.825942],
          [45.948808, 35.825765],
          [45.949097, 35.825335],
          [45.949414, 35.824896],
          [45.950017, 35.824074],
          [45.950304, 35.823563],
          [45.950447, 35.823477],
          [45.950814, 35.823363],
          [45.951142, 35.823257],
          [45.951591, 35.823111],
          [45.951869, 35.823082],
          [45.952341, 35.823047],
          [45.952592, 35.823068],
          [45.952945, 35.823152],
          [45.953173, 35.823177],
          [45.953518, 35.823118],
          [45.953861, 35.822989],
          [45.954188, 35.822837],
          [45.954344, 35.822803],
          [45.954629, 35.822838],
          [45.955184, 35.822948],
          [45.955474, 35.823057],
          [45.955589, 35.823191],
          [45.955575, 35.823749],
          [45.955933, 35.824353],
          [45.956233, 35.824791],
          [45.956394, 35.825194],
          [45.956566, 35.825699],
          [45.956704, 35.826495],
          [45.956829, 35.827071],
          [45.956844, 35.827453],
          [45.956778, 35.828025],
          [45.956735, 35.828493],
          [45.956741, 35.828711],
          [45.956595, 35.829034],
          [45.956362, 35.829402],
          [45.956352, 35.829419],
          [45.956364, 35.829973],
          [45.956302, 35.830772],
          [45.956278, 35.831791],
          [45.95624, 35.832663],
          [45.956216, 35.833492],
          [45.956186, 35.834168],
          [45.956185, 35.834181],
          [45.956291, 35.834375],
          [45.956385, 35.834812],
          [45.956375, 35.835117],
          [45.956466, 35.835388],
          [45.956665, 35.835652],
          [45.956904, 35.83582],
          [45.957136, 35.835994],
          [45.95743, 35.836143],
          [45.957682, 35.836147],
          [45.957999, 35.83611],
          [45.958402, 35.835944],
          [45.958652, 35.835961],
          [45.958987, 35.83609],
          [45.959293, 35.836282],
          [45.959511, 35.8365],
          [45.959594, 35.836707],
          [45.960135, 35.837086],
          [45.960472, 35.837243],
          [45.960817, 35.837374],
          [45.960859, 35.83761],
          [45.960942, 35.837946],
          [45.961106, 35.838327],
          [45.961362, 35.838633],
          [45.961675, 35.83887],
          [45.962239, 35.839281],
          [45.962628, 35.83985],
          [45.962634, 35.840408],
          [45.962781, 35.840875],
          [45.962904, 35.841232],
          [45.963195, 35.841692],
          [45.963531, 35.842247],
          [45.963729, 35.84314],
          [45.964013, 35.843593],
          [45.964361, 35.843579],
          [45.965023, 35.843523],
          [45.96555, 35.843333],
          [45.96583, 35.843175],
          [45.966061, 35.842911],
          [45.966369, 35.842837],
          [45.966654, 35.842661],
          [45.966937, 35.84263],
          [45.96738, 35.842564],
          [45.967381, 35.842564],
          [45.968191, 35.842189],
          [45.968616, 35.842052],
          [45.969102, 35.841953],
          [45.96937, 35.841788],
          [45.969583, 35.841395],
          [45.969837, 35.840869],
          [45.970228, 35.840274],
          [45.970655, 35.83995],
          [45.97076, 35.839661],
          [45.97079, 35.839237],
          [45.970695, 35.838875],
          [45.970485, 35.838552],
          [45.970443, 35.838237],
          [45.970481, 35.837902],
          [45.970718, 35.837715],
          [45.971155, 35.837526],
          [45.9713, 35.837237],
          [45.971427, 35.837013],
          [45.971669, 35.836883],
          [45.97196, 35.836788],
          [45.972343, 35.83668],
          [45.972701, 35.836742],
          [45.972925, 35.836698],
          [45.97333, 35.83654],
          [45.973639, 35.836496],
          [45.973831, 35.836537],
          [45.974102, 35.836558],
          [45.974201, 35.836439],
          [45.974094, 35.836183],
          [45.973996, 35.835846],
          [45.974012, 35.835446],
          [45.974047, 35.835041],
          [45.974138, 35.834874],
          [45.974359, 35.834703],
          [45.974587, 35.834691],
          [45.974823, 35.83469],
          [45.975151, 35.834775],
          [45.97545, 35.834882],
          [45.975603, 35.834893],
          [45.975884, 35.834971],
          [45.976131, 35.835034],
          [45.976428, 35.835017],
          [45.97658, 35.834792],
          [45.976765, 35.834598],
          [45.976851, 35.834428],
          [45.977182, 35.834239],
          [45.977625, 35.834168],
          [45.97802, 35.834045],
          [45.978349, 35.834076],
          [45.978762, 35.83443],
          [45.979063, 35.834602],
          [45.979416, 35.834877],
          [45.979701, 35.835065],
          [45.97996, 35.83525],
          [45.980202, 35.83543],
          [45.980586, 35.835611],
          [45.980881, 35.83576],
          [45.981168, 35.836035],
          [45.981258, 35.836208],
          [45.981477, 35.836467],
          [45.981615, 35.836726],
          [45.981791, 35.836844],
          [45.982103, 35.836961],
          [45.982306, 35.837107],
          [45.982442, 35.837416],
          [45.982594, 35.837635],
          [45.982836, 35.837943],
          [45.983062, 35.838118],
          [45.983354, 35.838313],
          [45.983678, 35.838556],
          [45.98411, 35.83879],
          [45.984646, 35.838958],
          [45.985132, 35.839169],
          [45.985411, 35.839391],
          [45.985546, 35.839502],
          [45.985899, 35.839739],
          [45.986225, 35.839999],
          [45.986239, 35.84001],
          [45.986248, 35.840004],
          [45.986914, 35.839462],
          [45.987358, 35.839126],
          [45.987947, 35.838628],
          [45.988478, 35.838279],
          [45.989022, 35.837869],
          [45.989801, 35.837797],
          [45.990545, 35.837742],
          [45.991063, 35.837769],
          [45.991521, 35.837442],
          [45.992225, 35.837125],
          [45.992579, 35.836849],
          [45.993091, 35.836597],
          [45.993954, 35.836372],
          [45.994454, 35.836249],
          [45.994819, 35.836345],
          [45.995247, 35.83656],
          [45.995568, 35.836628],
          [45.996118, 35.836498],
          [45.996496, 35.836277],
          [45.997127, 35.836041],
          [45.99774, 35.835784],
          [45.998293, 35.835607],
          [45.998696, 35.83551],
          [45.99932, 35.835555],
          [45.999737, 35.835469],
          [45.999878, 35.835155],
          [46.000213, 35.834789],
          [46.000587, 35.834487],
          [46.000917, 35.834157],
          [46.001422, 35.83387],
          [46.00181, 35.833765],
          [46.002459, 35.833934],
          [46.002831, 35.834064],
          [46.003311, 35.834155],
          [46.003721, 35.834279],
          [46.004352, 35.834486],
          [46.004807, 35.834709],
          [46.005101, 35.834956],
          [46.005395, 35.835331],
          [46.005452, 35.835673],
          [46.005495, 35.836133],
          [46.005254, 35.83652],
          [46.005462, 35.836721],
          [46.00586, 35.836789],
          [46.006392, 35.836697],
          [46.006681, 35.836734],
          [46.007475, 35.836987],
          [46.008022, 35.837032],
          [46.008732, 35.837181],
          [46.009602, 35.837305],
          [46.010574, 35.837426],
          [46.011399, 35.83751],
          [46.012173, 35.837602],
          [46.012829, 35.837806],
          [46.013305, 35.838002],
          [46.013906, 35.837934],
          [46.014174, 35.838056],
          [46.014308, 35.838211],
          [46.014458, 35.838445],
          [46.015064, 35.838586],
          [46.015849, 35.838607],
          [46.01624, 35.838454],
          [46.016423, 35.838029],
          [46.016429, 35.837748],
          [46.016555, 35.837426],
          [46.01691, 35.83722],
          [46.017462, 35.836658],
          [46.017801, 35.836314],
          [46.018256, 35.836035],
          [46.018698, 35.836003],
          [46.019159, 35.836189],
          [46.019691, 35.836412],
          [46.020152, 35.836412],
          [46.020747, 35.836065],
          [46.021257, 35.835742],
          [46.021835, 35.835374],
          [46.022588, 35.835108],
          [46.02312, 35.834957],
          [46.023886, 35.834817],
          [46.024303, 35.834602],
          [46.024647, 35.834468],
          [46.024842, 35.834671],
          [46.024967, 35.834967],
          [46.024986, 35.835373],
          [46.024723, 35.835716],
          [46.024624, 35.836046],
          [46.024598, 35.836424],
          [46.024798, 35.83665],
          [46.025128, 35.83695],
          [46.025512, 35.837137],
          [46.026001, 35.837459],
          [46.027397, 35.8379],
          [46.02801, 35.838017],
          [46.028662, 35.838011],
          [46.029285, 35.838114],
          [46.029735, 35.838373],
          [46.030072, 35.838707],
          [46.030704, 35.838727],
          [46.031259, 35.838561],
          [46.031728, 35.83835],
          [46.03222, 35.838251],
          [46.032615, 35.838366],
          [46.032801, 35.838653],
          [46.033078, 35.839066],
          [46.033679, 35.839313],
          [46.034029, 35.839586],
          [46.034398, 35.83995],
          [46.034936, 35.840324],
          [46.035648, 35.84074],
          [46.036218, 35.840957],
          [46.036806, 35.841078],
          [46.037227, 35.841259],
          [46.037738, 35.841566],
          [46.038161, 35.841945],
          [46.038457, 35.84239],
          [46.038632, 35.842994],
          [46.039008, 35.843392],
          [46.039362, 35.843688],
          [46.039713, 35.843961],
          [46.039927, 35.844197],
          [46.040152, 35.844793],
          [46.04041, 35.845057],
          [46.041348, 35.845008],
          [46.041948, 35.845185],
          [46.042342, 35.845358],
          [46.042814, 35.845286],
          [46.043134, 35.845098],
          [46.043747, 35.8449],
          [46.044261, 35.844973],
          [46.04462, 35.845163],
          [46.044984, 35.845505],
          [46.045488, 35.845778],
          [46.045678, 35.846018],
          [46.045659, 35.846301],
          [46.045511, 35.84658],
          [46.045695, 35.847042],
          [46.045966, 35.847363],
          [46.04623, 35.847906],
          [46.046341, 35.848262],
          [46.046829, 35.848643],
          [46.047053, 35.849111],
          [46.047113, 35.849405],
          [46.047141, 35.849856],
          [46.047253, 35.849967],
          [46.047738, 35.85008],
          [46.048101, 35.850422],
          [46.048535, 35.850787],
          [46.048767, 35.850985],
          [46.049223, 35.851336],
          [46.049545, 35.851532],
          [46.050039, 35.851787],
          [46.050566, 35.852111],
          [46.050832, 35.852457],
          [46.051097, 35.852764],
          [46.0516, 35.853088],
          [46.051863, 35.85324],
          [46.052292, 35.853353],
          [46.052938, 35.853695],
          [46.053396, 35.854194],
          [46.054018, 35.854498],
          [46.054658, 35.854745],
          [46.055328, 35.854815],
          [46.055805, 35.854965],
          [46.056173, 35.855203],
          [46.056849, 35.855439],
          [46.057487, 35.85568],
          [46.058257, 35.855936],
          [46.059, 35.85594],
          [46.059484, 35.856112],
          [46.059785, 35.856206],
          [46.060317, 35.856241],
          [46.061349, 35.856154],
          [46.062358, 35.856141],
          [46.063296, 35.855777],
          [46.063723, 35.855735],
          [46.064062, 35.855893],
          [46.06441, 35.856027],
          [46.065313, 35.856123],
          [46.065886, 35.856421],
          [46.066273, 35.856747],
          [46.066533, 35.856894],
          [46.066865, 35.856949],
          [46.067599, 35.857094],
          [46.068247, 35.857135],
          [46.068782, 35.857369],
          [46.069074, 35.857546],
          [46.069664, 35.857363],
          [46.070034, 35.857354],
          [46.070367, 35.857291],
          [46.071297, 35.857266],
          [46.07173, 35.857317],
          [46.072521, 35.85743],
          [46.07302, 35.857553],
          [46.073752, 35.857815],
          [46.074674, 35.858001],
          [46.075732, 35.858039],
          [46.076759, 35.858116],
          [46.077444, 35.85821],
          [46.078196, 35.858376],
          [46.078562, 35.85843],
          [46.078898, 35.858427],
          [46.079274, 35.85827],
          [46.079449, 35.858274],
          [46.079596, 35.858244],
          [46.079714, 35.85826],
          [46.079716, 35.85826],
          [46.079846, 35.858088],
          [46.07997, 35.857961],
          [46.080194, 35.857937],
          [46.08039, 35.857912],
          [46.080543, 35.857791],
          [46.080714, 35.857561],
          [46.080964, 35.857376],
          [46.081172, 35.857204],
          [46.081395, 35.85711],
          [46.081805, 35.856651],
          [46.082159, 35.856323],
          [46.082365, 35.856144],
          [46.08282, 35.856359],
          [46.083225, 35.856452],
          [46.083648, 35.856709],
          [46.083877, 35.857037],
          [46.08435, 35.857193],
          [46.08479, 35.857485],
          [46.085068, 35.857339],
          [46.085307, 35.857366],
          [46.085742, 35.857402],
          [46.086448, 35.857276],
          [46.087009, 35.857333],
          [46.087487, 35.857506],
          [46.088007, 35.857575],
          [46.088497, 35.857594],
          [46.088831, 35.857748],
          [46.089078, 35.857673],
          [46.089262, 35.857641],
          [46.08951, 35.857656],
          [46.089817, 35.857718],
          [46.089933, 35.857725],
          [46.090129, 35.857788],
          [46.090241, 35.857856],
          [46.090552, 35.85784],
          [46.090799, 35.85779],
          [46.091051, 35.857758],
          [46.091524, 35.857638],
          [46.091685, 35.857614],
          [46.092176, 35.857566],
          [46.092614, 35.857486],
          [46.092919, 35.857351],
          [46.093279, 35.857067],
          [46.093439, 35.856784],
          [46.093598, 35.856427],
          [46.093885, 35.856128],
          [46.094298, 35.855739],
          [46.094675, 35.85535],
          [46.095018, 35.855154],
          [46.095415, 35.854899],
          [46.095924, 35.854616],
          [46.095962, 35.854569],
          [46.096007, 35.854362],
          [46.096068, 35.854268],
          [46.096124, 35.854213],
          [46.096323, 35.854117],
          [46.096567, 35.853985],
          [46.096952, 35.853738],
          [46.097365, 35.853269],
          [46.097689, 35.853034],
          [46.098201, 35.852767],
          [46.098798, 35.852544],
          [46.099051, 35.85243],
          [46.099217, 35.852269],
          [46.099452, 35.852074],
          [46.099975, 35.85182],
          [46.100183, 35.851613],
          [46.100501, 35.851178],
          [46.100739, 35.850961],
          [46.101139, 35.850351],
          [46.101427, 35.850068],
          [46.101448, 35.849703],
          [46.101723, 35.849417],
          [46.101549, 35.849099],
          [46.101546, 35.848737],
          [46.101746, 35.848462],
          [46.102389, 35.848417],
          [46.102722, 35.848448],
          [46.103246, 35.848502],
          [46.104049, 35.848392],
          [46.104853, 35.848158],
          [46.10558, 35.848048],
          [46.106161, 35.848415],
          [46.106666, 35.848723],
          [46.107183, 35.848714],
          [46.108346, 35.847767],
          [46.109109, 35.847237],
          [46.109671, 35.84676],
          [46.110422, 35.846769],
          [46.110881, 35.846728],
          [46.111555, 35.846579],
          [46.112301, 35.846372],
          [46.112812, 35.846306],
          [46.113226, 35.846231],
          [46.113455, 35.84591],
          [46.114161, 35.845855],
          [46.114657, 35.845681],
          [46.115205, 35.845574],
          [46.115853, 35.845551],
          [46.116605, 35.845594],
          [46.117121, 35.845673],
          [46.117824, 35.845765],
          [46.11838, 35.845698],
          [46.118986, 35.84581],
          [46.119633, 35.845997],
          [46.120385, 35.846011],
          [46.120765, 35.845994],
          [46.121243, 35.845857],
          [46.121742, 35.845694],
          [46.122291, 35.845185],
          [46.123007, 35.84521],
          [46.123421, 35.845199],
          [46.123775, 35.845303],
          [46.124224, 35.84534],
          [46.124824, 35.845108],
          [46.125209, 35.844927],
          [46.125625, 35.844578],
          [46.126522, 35.844301],
          [46.127031, 35.843909],
          [46.127749, 35.843753],
          [46.128531, 35.843319],
          [46.129369, 35.843191],
          [46.129877, 35.843138],
          [46.130455, 35.843242],
          [46.131138, 35.843512],
          [46.1325, 35.843503],
          [46.133168, 35.843483],
          [46.134037, 35.843473],
          [46.13472, 35.843398],
          [46.135796, 35.84334],
          [46.136861, 35.843475],
          [46.137288, 35.843527],
          [46.137693, 35.842625],
          [46.137922, 35.841739],
          [46.138259, 35.840695],
          [46.138464, 35.840069],
          [46.138512, 35.839677],
          [46.138364, 35.839326],
          [46.137808, 35.839049],
          [46.13748, 35.838888],
          [46.137035, 35.838653],
          [46.136731, 35.838419],
          [46.136334, 35.837978],
          [46.136045, 35.83738],
          [46.13587, 35.836834],
          [46.135729, 35.836459],
          [46.135543, 35.835986],
          [46.135392, 35.835309],
          [46.135215, 35.835009],
          [46.135078, 35.834213],
          [46.135259, 35.833532],
          [46.135457, 35.83293],
          [46.135526, 35.832581],
          [46.135596, 35.832232],
          [46.136167, 35.831643],
          [46.136511, 35.831007],
          [46.136633, 35.830604],
          [46.136647, 35.83017],
          [46.13681, 35.829585],
          [46.137043, 35.828849],
          [46.137171, 35.828468],
          [46.137564, 35.827825],
          [46.137661, 35.827168],
          [46.137597, 35.826793],
          [46.137528, 35.826267],
          [46.137255, 35.82562],
          [46.136982, 35.82516],
          [46.137592, 35.824448],
          [46.137902, 35.823899],
          [46.138085, 35.823461],
          [46.138269, 35.823034],
          [46.138574, 35.822521],
          [46.139063, 35.821922],
          [46.139532, 35.821463],
          [46.139911, 35.821109],
          [46.140273, 35.820279],
          [46.140563, 35.81987],
          [46.141098, 35.819538],
          [46.1417, 35.819161],
          [46.142379, 35.818785],
          [46.142983, 35.818305],
          [46.143701, 35.818048],
          [46.1443, 35.817945],
          [46.145273, 35.817467],
          [46.145831, 35.816949],
          [46.146134, 35.816481],
          [46.146387, 35.815793],
          [46.146631, 35.815174],
          [46.14709, 35.814899],
          [46.147761, 35.814376],
          [46.148449, 35.814043],
          [46.148864, 35.813854],
          [46.149211, 35.813015],
          [46.14945, 35.812546],
          [46.149688, 35.811848],
          [46.150085, 35.810831],
          [46.150441, 35.810378],
          [46.151323, 35.809947],
          [46.151726, 35.809703],
          [46.152289, 35.80915],
          [46.152783, 35.808517],
          [46.153214, 35.808052],
          [46.153653, 35.807632],
          [46.153867, 35.806996],
          [46.154032, 35.806594],
          [46.154937, 35.806035],
          [46.155403, 35.805679],
          [46.156062, 35.805328],
          [46.156735, 35.805214],
          [46.157269, 35.805042],
          [46.157812, 35.804572],
          [46.158364, 35.804203],
          [46.158846, 35.80397],
          [46.15948, 35.803737],
          [46.160566, 35.803423],
          [46.161377, 35.803072],
          [46.162148, 35.802886],
          [46.16289, 35.802625],
          [46.163414, 35.802295],
          [46.164058, 35.801878],
          [46.164648, 35.801503],
          [46.165404, 35.801194],
          [46.165807, 35.801008],
          [46.166208, 35.800639],
          [46.166581, 35.80032],
          [46.16736, 35.80011],
          [46.167906, 35.799993],
          [46.168714, 35.80003],
          [46.16921, 35.800034],
          [46.169972, 35.799975],
          [46.170468, 35.799922],
          [46.17091, 35.799855],
          [46.171615, 35.799999],
          [46.171991, 35.800204],
          [46.172512, 35.800318],
          [46.173055, 35.800361],
          [46.173909, 35.800664],
          [46.174506, 35.800835],
          [46.175068, 35.801023],
          [46.17526, 35.801598],
          [46.175454, 35.801899],
          [46.175559, 35.802373],
          [46.175554, 35.802864],
          [46.175482, 35.803456],
          [46.175354, 35.803795],
          [46.175261, 35.804174],
          [46.175396, 35.804552],
          [46.175648, 35.804947],
          [46.176009, 35.805542],
          [46.176404, 35.80612],
          [46.176856, 35.806723],
          [46.177202, 35.807081],
          [46.177742, 35.807512],
          [46.178243, 35.807823],
          [46.178663, 35.808056],
          [46.178926, 35.80838],
          [46.179085, 35.808641],
          [46.179552, 35.809197],
          [46.180028, 35.809398],
          [46.180731, 35.80953],
          [46.181573, 35.809664],
          [46.182045, 35.8099],
          [46.182334, 35.810403],
          [46.182599, 35.810796],
          [46.182808, 35.811163],
          [46.182997, 35.811613],
          [46.183139, 35.811968],
          [46.183144, 35.81199],
          [46.183454, 35.812296],
          [46.183814, 35.812492],
          [46.184598, 35.812361],
          [46.184949, 35.812228],
          [46.185311, 35.811854],
          [46.185682, 35.811296],
          [46.186088, 35.810664],
          [46.186665, 35.810177],
          [46.187388, 35.809657],
          [46.18824, 35.809265],
          [46.188766, 35.80906],
          [46.189297, 35.809048],
          [46.189867, 35.809098],
          [46.190425, 35.808922],
          [46.190931, 35.808571],
          [46.191223, 35.808173],
          [46.19144, 35.807776],
          [46.19187, 35.807482],
          [46.192464, 35.807415],
          [46.19289, 35.807327],
          [46.193265, 35.806962],
          [46.193585, 35.806572],
          [46.193747, 35.806217],
          [46.193679, 35.805737],
          [46.193677, 35.805213],
          [46.193758, 35.804893],
          [46.194127, 35.80421],
          [46.19424, 35.803748],
          [46.194346, 35.803253],
          [46.194381, 35.802895],
          [46.194644, 35.802422],
          [46.194988, 35.80197],
          [46.195104, 35.80152],
          [46.195089, 35.801168],
          [46.195165, 35.800826],
          [46.19503, 35.800442],
          [46.195866, 35.800834],
          [46.196583, 35.800633],
          [46.197053, 35.800346],
          [46.19844, 35.800248],
          [46.199003, 35.800322],
          [46.199978, 35.800197],
          [46.200813, 35.800241],
          [46.201651, 35.800068],
          [46.20244, 35.799788],
          [46.202956, 35.799539],
          [46.203376, 35.799201],
          [46.204276, 35.798848],
          [46.204775, 35.798578],
          [46.205411, 35.798185],
          [46.206099, 35.797909],
          [46.20646, 35.797535],
          [46.206782, 35.797213],
          [46.207502, 35.796909],
          [46.207967, 35.796485],
          [46.208387, 35.796148],
          [46.209105, 35.795606],
          [46.209906, 35.795267],
          [46.210766, 35.794851],
          [46.211505, 35.794864],
          [46.212274, 35.794952],
          [46.21264, 35.795113],
          [46.213067, 35.795379],
          [46.213435, 35.795665],
          [46.21389, 35.796051],
          [46.214211, 35.796698],
          [46.21442, 35.797065],
          [46.214547, 35.797353],
          [46.215267, 35.798133],
          [46.215613, 35.798433],
          [46.215996, 35.798785],
          [46.216411, 35.798939],
          [46.216794, 35.799006],
          [46.217344, 35.79931],
          [46.21798, 35.799601],
          [46.218628, 35.799889],
          [46.21922, 35.800381],
          [46.21953, 35.800516],
          [46.220001, 35.800638],
          [46.221134, 35.800648],
          [46.221731, 35.800762],
          [46.22217, 35.801311],
          [46.222654, 35.801716],
          [46.223034, 35.802057],
          [46.223263, 35.802455],
          [46.223437, 35.802953],
          [46.223554, 35.803254],
          [46.223594, 35.803602],
          [46.224053, 35.804068],
          [46.224556, 35.804447],
          [46.224865, 35.80481],
          [46.224961, 35.80524],
          [46.225231, 35.805541],
          [46.225604, 35.805849],
          [46.225943, 35.806173],
          [46.226083, 35.806403],
          [46.226409, 35.806672],
          [46.226728, 35.807137],
          [46.226895, 35.807544],
          [46.227344, 35.807966],
          [46.227821, 35.808281],
          [46.228285, 35.808712],
          [46.228636, 35.808978],
          [46.229009, 35.809343],
          [46.22939, 35.80957],
          [46.229699, 35.809876],
          [46.229819, 35.810188],
          [46.229883, 35.810475],
          [46.230158, 35.811026],
          [46.230408, 35.81158],
          [46.230476, 35.812288],
          [46.230737, 35.812716],
          [46.231147, 35.812904],
          [46.231479, 35.812854],
          [46.231914, 35.812753],
          [46.232444, 35.812855],
          [46.233257, 35.813027],
          [46.23393, 35.813256],
          [46.234541, 35.813835],
          [46.235042, 35.81426],
          [46.235356, 35.814589],
          [46.235877, 35.814475],
          [46.236282, 35.814299],
          [46.236769, 35.814089],
          [46.23717, 35.814005],
          [46.237995, 35.814062],
          [46.238602, 35.814163],
          [46.239061, 35.814059],
          [46.239324, 35.8137],
          [46.239454, 35.813372],
          [46.239585, 35.812931],
          [46.239556, 35.812457],
          [46.239782, 35.812103],
          [46.240197, 35.811914],
          [46.240794, 35.811687],
          [46.241495, 35.81158],
          [46.241969, 35.811314],
          [46.242605, 35.811035],
          [46.243217, 35.81076],
          [46.244384, 35.810411],
          [46.245074, 35.810375],
          [46.245664, 35.810513],
          [46.246047, 35.810409],
          [46.246239, 35.810129],
          [46.246436, 35.809814],
          [46.24708, 35.809511],
          [46.247993, 35.809338],
          [46.248384, 35.809324],
          [46.248507, 35.809704],
          [46.248747, 35.810044],
          [46.249115, 35.81033],
          [46.249555, 35.81065],
          [46.249806, 35.81092],
          [46.250041, 35.811238],
          [46.250301, 35.811608],
          [46.250556, 35.8119],
          [46.250999, 35.812061],
          [46.251416, 35.812111],
          [46.25204, 35.812176],
          [46.252495, 35.812277],
          [46.253185, 35.812298],
          [46.253698, 35.812436],
          [46.25412, 35.812566],
          [46.254528, 35.812572],
          [46.255312, 35.812327],
          [46.255963, 35.812285],
          [46.256762, 35.81205],
          [46.257396, 35.811874],
          [46.257931, 35.811826],
          [46.2587, 35.81163],
          [46.259054, 35.811337],
          [46.259712, 35.811441],
          [46.260321, 35.811554],
          [46.26096, 35.811628],
          [46.261267, 35.811809],
          [46.26135, 35.812184],
          [46.26151, 35.812388],
          [46.261903, 35.812442],
          [46.262392, 35.812527],
          [46.26276, 35.812813],
          [46.26317, 35.813115],
          [46.263406, 35.813319],
          [46.263813, 35.813667],
          [46.264427, 35.814143],
          [46.264702, 35.814295],
          [46.265049, 35.814425],
          [46.265624, 35.814554],
          [46.265962, 35.814651],
          [46.266458, 35.814769],
          [46.267041, 35.814703],
          [46.267363, 35.814609],
          [46.267775, 35.814466],
          [46.268392, 35.814327],
          [46.269213, 35.814133],
          [46.269854, 35.814218],
          [46.270284, 35.814324],
          [46.270465, 35.814454],
          [46.270964, 35.814642],
          [46.271357, 35.814981],
          [46.271848, 35.815533],
          [46.272425, 35.815901],
          [46.27298, 35.816113],
          [46.273238, 35.81653],
          [46.273498, 35.816901],
          [46.273773, 35.817224],
          [46.27418, 35.817287],
          [46.274839, 35.817107],
          [46.275367, 35.817083],
          [46.275775, 35.817147],
          [46.276222, 35.817387],
          [46.277045, 35.817717],
          [46.277776, 35.818096],
          [46.2784, 35.818389],
          [46.279083, 35.818604],
          [46.279584, 35.818744],
          [46.279869, 35.818883],
          [46.280635, 35.819473],
          [46.280966, 35.819765],
          [46.281155, 35.820044],
          [46.281376, 35.820694],
          [46.281646, 35.82128],
          [46.281995, 35.821819],
          [46.282191, 35.822075],
          [46.282552, 35.822156],
          [46.282992, 35.822135],
          [46.283372, 35.822362],
          [46.28377, 35.822666],
          [46.283957, 35.822934],
          [46.284054, 35.823318],
          [46.284062, 35.823807],
          [46.284283, 35.824172],
          [46.284717, 35.824642],
          [46.285242, 35.825121],
          [46.285488, 35.825311],
          [46.285942, 35.825698],
          [46.286637, 35.826139],
          [46.287242, 35.826344],
          [46.288234, 35.826295],
          [46.288863, 35.826496],
          [46.289553, 35.826915],
          [46.289843, 35.827247],
          [46.290075, 35.827725],
          [46.289979, 35.82815],
          [46.289851, 35.828887],
          [46.289949, 35.829157],
          [46.290094, 35.829637],
          [46.290099, 35.830058],
          [46.289934, 35.830345],
          [46.289614, 35.830621],
          [46.289069, 35.831024],
          [46.288956, 35.831257],
          [46.289096, 35.831544],
          [46.289334, 35.832157],
          [46.289154, 35.83272],
          [46.289019, 35.833368],
          [46.289156, 35.833757],
          [46.28943, 35.834422],
          [46.2894, 35.834974],
          [46.290039, 35.834762],
          [46.290541, 35.834675],
          [46.290951, 35.834635],
          [46.291253, 35.834623],
          [46.291757, 35.834718],
          [46.292235, 35.834873],
          [46.292972, 35.835046],
          [46.293321, 35.835186],
          [46.294119, 35.835635],
          [46.294615, 35.835867],
          [46.295094, 35.835851],
          [46.295598, 35.835603],
          [46.29633, 35.835298],
          [46.29707, 35.835026],
          [46.297954, 35.834721],
          [46.298264, 35.834742],
          [46.299079, 35.834698],
          [46.299276, 35.83444],
          [46.299257, 35.833953],
          [46.299198, 35.833403],
          [46.299228, 35.832909],
          [46.298982, 35.832716],
          [46.299168, 35.831988],
          [46.299484, 35.83142],
          [46.299784, 35.830749],
          [46.299785, 35.829954],
          [46.299823, 35.829654],
          [46.300415, 35.829533],
          [46.300762, 35.829137],
          [46.300871, 35.828871],
          [46.300917, 35.828225],
          [46.301239, 35.827991],
          [46.302473, 35.827333],
          [46.302865, 35.826987],
          [46.303349, 35.826708],
          [46.303921, 35.826541],
          [46.304543, 35.826595],
          [46.305093, 35.826728],
          [46.305442, 35.826755],
          [46.306041, 35.826538],
          [46.306113, 35.826345],
          [46.30625, 35.826107],
          [46.306589, 35.825919],
          [46.306956, 35.825852],
          [46.307299, 35.82597],
          [46.307616, 35.826196],
          [46.307778, 35.826353],
          [46.308038, 35.826382],
          [46.308365, 35.826059],
          [46.308498, 35.825708],
          [46.308658, 35.825456],
          [46.309046, 35.825032],
          [46.309423, 35.824496],
          [46.309782, 35.824111],
          [46.310175, 35.823766],
          [46.310512, 35.823567],
          [46.310882, 35.823408],
          [46.311133, 35.822994],
          [46.31131, 35.822648],
          [46.311662, 35.822457],
          [46.311952, 35.822447],
          [46.312338, 35.822355],
          [46.312615, 35.821834],
          [46.312969, 35.821597],
          [46.313139, 35.821218],
          [46.313392, 35.820872],
          [46.313805, 35.820501],
          [46.314154, 35.820186],
          [46.31438, 35.819833],
          [46.314557, 35.819601],
          [46.315051, 35.81948],
          [46.315624, 35.819484],
          [46.316289, 35.819508],
          [46.316987, 35.819504],
          [46.317503, 35.81954],
          [46.317942, 35.819576],
          [46.318279, 35.819319],
          [46.318699, 35.819153],
          [46.319269, 35.819032],
          [46.319697, 35.818955],
          [46.320146, 35.81875],
          [46.320535, 35.818554],
          [46.320918, 35.818394],
          [46.321232, 35.818437],
          [46.321768, 35.818333],
          [46.322225, 35.818388],
          [46.322721, 35.81845],
          [46.323183, 35.818357],
          [46.323706, 35.818311],
          [46.32433, 35.818205],
          [46.324851, 35.818205],
          [46.32521, 35.818333],
          [46.325735, 35.818413],
          [46.326396, 35.818529],
          [46.327008, 35.818652],
          [46.327472, 35.818798],
          [46.328007, 35.819036],
          [46.328457, 35.819116],
          [46.328867, 35.819133],
          [46.329523, 35.819113],
          [46.329869, 35.818958],
          [46.330437, 35.818826],
          [46.330933, 35.818659],
          [46.331437, 35.818754],
          [46.331901, 35.818842],
          [46.332746, 35.818873],
          [46.333448, 35.819177],
          [46.334102, 35.819374],
          [46.334381, 35.819605],
          [46.334721, 35.819872],
          [46.335327, 35.820145],
          [46.335663, 35.820287],
          [46.336251, 35.820073],
          [46.337, 35.819902],
          [46.337575, 35.819746],
          [46.338425, 35.819799],
          [46.338992, 35.819838],
          [46.339444, 35.819929],
          [46.340244, 35.819902],
          [46.340366, 35.81947],
          [46.340374, 35.819064],
          [46.340514, 35.818693],
          [46.340554, 35.818418],
          [46.340369, 35.817919],
          [46.340121, 35.817432],
          [46.340011, 35.816879],
          [46.340035, 35.816301],
          [46.340054, 35.815717],
          [46.340324, 35.815177],
          [46.340563, 35.81459],
          [46.340729, 35.814016],
          [46.340834, 35.813299],
          [46.341068, 35.812402],
          [46.341262, 35.811755],
          [46.341207, 35.8111],
          [46.341243, 35.810658],
          [46.341559, 35.810273],
          [46.341827, 35.809686],
          [46.341927, 35.80953],
          [46.342535, 35.809129],
          [46.343213, 35.808513],
          [46.343758, 35.807851],
          [46.34407, 35.807252],
          [46.344359, 35.806127],
          [46.344525, 35.805565],
          [46.34444, 35.804862],
          [46.344243, 35.804435],
          [46.343769, 35.804142],
          [46.344477, 35.803335],
          [46.344994, 35.802558],
          [46.345574, 35.801797],
          [46.345923, 35.801026],
          [46.34598, 35.800368],
          [46.3459, 35.799947],
          [46.345561, 35.799452],
          [46.3452, 35.799028],
          [46.345005, 35.798914],
          [46.344771, 35.798836],
          [46.344631, 35.798749],
          [46.344594, 35.798417],
          [46.344424, 35.798052],
          [46.344277, 35.797789],
          [46.344302, 35.797272],
          [46.344189, 35.796708],
          [46.344408, 35.796151],
          [46.34447, 35.795515],
          [46.344372, 35.795131],
          [46.344497, 35.794724],
          [46.344827, 35.793979],
          [46.345218, 35.793452],
          [46.345754, 35.792721],
          [46.345936, 35.792169],
          [46.346148, 35.791578],
          [46.346025, 35.791255],
          [46.345561, 35.790767],
          [46.345463, 35.790269],
          [46.345554, 35.789594],
          [46.345699, 35.789218],
          [46.345968, 35.788767],
          [46.346292, 35.788342],
          [46.346386, 35.787906],
          [46.346823, 35.787418],
          [46.347062, 35.787119],
          [46.346927, 35.786855],
          [46.346652, 35.786589],
          [46.346293, 35.786233],
          [46.345974, 35.78594],
          [46.34564, 35.785751],
          [46.345124, 35.785602],
          [46.344857, 35.785483],
          [46.344668, 35.78509],
          [46.344295, 35.784554],
          [46.344022, 35.784242],
          [46.343781, 35.784017],
          [46.343273, 35.783957],
          [46.342841, 35.783898],
          [46.341959, 35.783302],
          [46.341473, 35.783],
          [46.341095, 35.782727],
          [46.340633, 35.782535],
          [46.340226, 35.78213],
          [46.339658, 35.781806],
          [46.339359, 35.781601],
          [46.338838, 35.781088],
          [46.338504, 35.780785],
          [46.338013, 35.780518],
          [46.337544, 35.78035],
          [46.337114, 35.780187],
          [46.336849, 35.780022],
          [46.336899, 35.779388],
          [46.337226, 35.778746],
          [46.337889, 35.778018],
          [46.33815, 35.777477],
          [46.338212, 35.776897],
          [46.33833, 35.776287],
          [46.338652, 35.775565],
          [46.338887, 35.77508],
          [46.339309, 35.77405],
          [46.339147, 35.77345],
          [46.338202, 35.772853],
          [46.337541, 35.772167],
          [46.336822, 35.771615],
          [46.336265, 35.770709],
          [46.335874, 35.77038],
          [46.334865, 35.769782],
          [46.334436, 35.769277],
          [46.334084, 35.768898],
          [46.333839, 35.768536],
          [46.333884, 35.768221],
          [46.333862, 35.767894],
          [46.333935, 35.767484],
          [46.334027, 35.766866],
          [46.334101, 35.766285],
          [46.334303, 35.765479],
          [46.334288, 35.765185],
          [46.334018, 35.764827],
          [46.333817, 35.764493],
          [46.333468, 35.763954],
          [46.333321, 35.763349],
          [46.333103, 35.763166],
          [46.33243, 35.763052],
          [46.331789, 35.763024],
          [46.331425, 35.762988],
          [46.330723, 35.762742],
          [46.330229, 35.762691],
          [46.329691, 35.762557],
          [46.329222, 35.762446],
          [46.328483, 35.762148],
          [46.327773, 35.762096],
          [46.327314, 35.761801],
          [46.326979, 35.761707],
          [46.326218, 35.761786],
          [46.325648, 35.762021],
          [46.324889, 35.762261],
          [46.32457, 35.762478],
          [46.3241, 35.762843],
          [46.323975, 35.762891],
          [46.323778, 35.762872],
          [46.323387, 35.762649],
          [46.322879, 35.762533],
          [46.322499, 35.762135],
          [46.322196, 35.761748],
          [46.321855, 35.761412],
          [46.321534, 35.761222],
          [46.321254, 35.761048],
          [46.32051, 35.760956],
          [46.320087, 35.760884],
          [46.319718, 35.760997],
          [46.319726, 35.761258],
          [46.319033, 35.761454],
          [46.318406, 35.761493],
          [46.31793, 35.761519],
          [46.317136, 35.761606],
          [46.316662, 35.761701],
          [46.316151, 35.761915],
          [46.315409, 35.762063],
          [46.314557, 35.762226],
          [46.313989, 35.762415],
          [46.313684, 35.762597],
          [46.313194, 35.762751],
          [46.313016, 35.762753],
          [46.312853, 35.762541],
          [46.312643, 35.762301],
          [46.31226, 35.762006],
          [46.311747, 35.761583],
          [46.311472, 35.761374],
          [46.310833, 35.761414],
          [46.310204, 35.761498],
          [46.3097, 35.761518],
          [46.309111, 35.761607],
          [46.30858, 35.76162],
          [46.308, 35.761925],
          [46.307312, 35.762086],
          [46.306516, 35.762105],
          [46.305853, 35.761978],
          [46.30577, 35.761717],
          [46.305603, 35.76148],
          [46.30521, 35.76097],
          [46.304741, 35.760346],
          [46.304395, 35.759704],
          [46.304105, 35.759372],
          [46.303914, 35.759024],
          [46.303797, 35.758666],
          [46.303924, 35.758271],
          [46.304328, 35.75797],
          [46.304551, 35.757662],
          [46.304576, 35.757374],
          [46.304387, 35.757095],
          [46.303935, 35.756776],
          [46.303606, 35.756382],
          [46.303395, 35.75606],
          [46.303206, 35.755781],
          [46.303401, 35.755341],
          [46.303619, 35.754727],
          [46.303806, 35.754311],
          [46.303821, 35.753978],
          [46.303625, 35.753723],
          [46.303348, 35.753446],
          [46.302795, 35.752846],
          [46.302584, 35.752525],
          [46.302179, 35.752359],
          [46.301759, 35.75224],
          [46.301135, 35.752005],
          [46.300386, 35.751435],
          [46.299586, 35.750861],
          [46.299028, 35.750468],
          [46.298586, 35.75025],
          [46.297714, 35.750041],
          [46.297184, 35.749939],
          [46.296732, 35.74962],
          [46.296307, 35.749309],
          [46.295479, 35.749127],
          [46.294757, 35.748735],
          [46.294305, 35.748474],
          [46.293851, 35.748201],
          [46.293291, 35.747853],
          [46.292788, 35.747417],
          [46.292346, 35.747085],
          [46.291901, 35.746742],
          [46.291398, 35.746648],
          [46.290899, 35.746576],
          [46.290268, 35.746535],
          [46.289293, 35.746546],
          [46.288733, 35.746484],
          [46.288524, 35.746173],
          [46.288274, 35.745904],
          [46.287711, 35.745716],
          [46.287053, 35.745554],
          [46.286599, 35.745452],
          [46.285884, 35.745207],
          [46.285427, 35.745151],
          [46.284803, 35.745144],
          [46.284204, 35.744961],
          [46.283793, 35.744887],
          [46.283489, 35.744831],
          [46.283013, 35.744174],
          [46.282519, 35.743611],
          [46.282136, 35.743088],
          [46.281638, 35.742445],
          [46.281601, 35.742052],
          [46.281513, 35.741541],
          [46.281435, 35.741017],
          [46.281467, 35.740534],
          [46.281082, 35.740114],
          [46.280856, 35.73967],
          [46.28082, 35.73939],
          [46.281422, 35.738729],
          [46.2818, 35.738375],
          [46.282176, 35.738067],
          [46.282262, 35.737655],
          [46.282707, 35.737143],
          [46.282744, 35.736738],
          [46.282713, 35.73631],
          [46.282862, 35.735839],
          [46.283032, 35.735803],
          [46.28343, 35.73581],
          [46.283745, 35.735697],
          [46.284108, 35.735583],
          [46.284318, 35.735337],
          [46.284925, 35.735099],
          [46.2853, 35.73455],
          [46.285701, 35.733815],
          [46.285469, 35.733726],
          [46.285213, 35.73373],
          [46.284657, 35.733632],
          [46.284198, 35.733565],
          [46.28326, 35.733571],
          [46.282415, 35.733369],
          [46.28151, 35.733095],
          [46.281238, 35.732982],
          [46.28089, 35.732735],
          [46.280215, 35.732309],
          [46.279675, 35.731944],
          [46.279234, 35.731515],
          [46.278758, 35.73097],
          [46.278534, 35.73058],
          [46.277931, 35.729954],
          [46.277799, 35.729531],
          [46.277535, 35.729187],
          [46.277041, 35.728866],
          [46.276381, 35.728708],
          [46.275683, 35.728727],
          [46.275201, 35.728594],
          [46.27433, 35.728493],
          [46.273646, 35.728333],
          [46.27261, 35.727887],
          [46.272118, 35.727766],
          [46.271503, 35.727773],
          [46.270873, 35.727952],
          [46.270128, 35.728429],
          [46.269193, 35.728942],
          [46.268513, 35.729179],
          [46.267973, 35.729255],
          [46.26717, 35.729248],
          [46.266123, 35.729185],
          [46.265089, 35.729061],
          [46.263694, 35.728987],
          [46.262499, 35.728865],
          [46.262069, 35.728745],
          [46.261089, 35.728142],
          [46.260631, 35.727946],
          [46.260046, 35.727537],
          [46.259515, 35.727402],
          [46.258892, 35.727369],
          [46.258307, 35.727211],
          [46.258095, 35.72701],
          [46.257746, 35.726799],
          [46.257289, 35.726733],
          [46.256738, 35.726691],
          [46.256246, 35.72669],
          [46.255816, 35.726701],
          [46.25526, 35.726438],
          [46.254767, 35.726187],
          [46.25428, 35.725965],
          [46.253902, 35.725607],
          [46.253469, 35.725157],
          [46.252844, 35.724734],
          [46.2527, 35.724313],
          [46.252492, 35.724132],
          [46.251982, 35.723853],
          [46.251799, 35.723384],
          [46.251454, 35.722985],
          [46.25097, 35.722748],
          [46.250558, 35.722591],
          [46.250537, 35.722176],
          [46.25042, 35.721924],
          [46.24989, 35.721471],
          [46.249443, 35.721203],
          [46.248845, 35.720856],
          [46.247981, 35.720276],
          [46.247244, 35.719658],
          [46.246417, 35.719274],
          [46.245879, 35.718918],
          [46.245178, 35.718737],
          [46.244445, 35.718519],
          [46.243997, 35.718302],
          [46.243683, 35.718085],
          [46.243359, 35.71788],
          [46.242674, 35.717716],
          [46.242036, 35.717415],
          [46.241309, 35.717037],
          [46.240691, 35.716964],
          [46.239872, 35.716999],
          [46.238785, 35.716732],
          [46.237319, 35.716417],
          [46.23651, 35.716191],
          [46.234944, 35.71568],
          [46.233992, 35.715283],
          [46.233493, 35.715073],
          [46.233374, 35.71534],
          [46.232993, 35.716036],
          [46.232491, 35.716523],
          [46.232409, 35.71658],
          [46.231821, 35.716989],
          [46.231002, 35.717251],
          [46.229934, 35.717185],
          [46.229285, 35.717124],
          [46.228126, 35.716993],
          [46.227347, 35.716918],
          [46.22679, 35.71698],
          [46.226104, 35.717038],
          [46.22519, 35.717098],
          [46.22437, 35.717348],
          [46.223898, 35.717568],
          [46.223202, 35.718038],
          [46.222495, 35.718169],
          [46.221527, 35.718271],
          [46.220986, 35.718581],
          [46.220401, 35.718977],
          [46.219529, 35.719395],
          [46.218703, 35.719623],
          [46.21776, 35.719721],
          [46.216657, 35.719843],
          [46.21658, 35.720534],
          [46.216386, 35.721251],
          [46.216295, 35.721609],
          [46.216152, 35.722407],
          [46.215818, 35.723281],
          [46.215004, 35.723217],
          [46.214315, 35.723234],
          [46.213595, 35.72295],
          [46.21276, 35.722968],
          [46.211838, 35.723234],
          [46.211059, 35.723353],
          [46.210242, 35.723595],
          [46.209301, 35.723824],
          [46.208433, 35.723728],
          [46.207603, 35.723509],
          [46.206495, 35.723495],
          [46.20587, 35.723789],
          [46.2051, 35.723699],
          [46.204111, 35.723669],
          [46.203246, 35.723524],
          [46.20234, 35.723029],
          [46.201485, 35.722744],
          [46.200513, 35.722544],
          [46.19956, 35.722322],
          [46.198851, 35.721514],
          [46.198377, 35.721255],
          [46.197637, 35.721062],
          [46.196996, 35.721014],
          [46.196387, 35.721],
          [46.195795, 35.720954],
          [46.195295, 35.720423],
          [46.194857, 35.719961],
          [46.194365, 35.719281],
          [46.1939, 35.718222],
          [46.193711, 35.71747],
          [46.193271, 35.717068],
          [46.192936, 35.716701],
          [46.192714, 35.716032],
          [46.192171, 35.715595],
          [46.19197, 35.71517],
          [46.191336, 35.714834],
          [46.190861, 35.714693],
          [46.190289, 35.714428],
          [46.189671, 35.714307],
          [46.188775, 35.714383],
          [46.188011, 35.71439],
          [46.187127, 35.71408],
          [46.18632, 35.713985],
          [46.185489, 35.713894],
          [46.184856, 35.713686],
          [46.184236, 35.713359],
          [46.183565, 35.713019],
          [46.182605, 35.712886],
          [46.181874, 35.712742],
          [46.1813, 35.712723],
          [46.180515, 35.712684],
          [46.179573, 35.712648],
          [46.179345, 35.712463],
          [46.179112, 35.711805],
          [46.178881, 35.711414],
          [46.178493, 35.711093],
          [46.177972, 35.710772],
          [46.1775, 35.710457],
          [46.176912, 35.710248],
          [46.176499, 35.710014],
          [46.17614, 35.709469],
          [46.175899, 35.709018],
          [46.175509, 35.708641],
          [46.1749, 35.708174],
          [46.174447, 35.707764],
          [46.173871, 35.707282],
          [46.173408, 35.70643],
          [46.173173, 35.704975],
          [46.173082, 35.704229],
          [46.173291, 35.703786],
          [46.173413, 35.703335],
          [46.173397, 35.70247],
          [46.173874, 35.701901],
          [46.174036, 35.701534],
          [46.174243, 35.700756],
          [46.174366, 35.700315],
          [46.174227, 35.699832],
          [46.173726, 35.699162],
          [46.173227, 35.698769],
          [46.172603, 35.698481],
          [46.171638, 35.698192],
          [46.170627, 35.697268],
          [46.170084, 35.696634],
          [46.1697, 35.695879],
          [46.169376, 35.695382],
          [46.168961, 35.694977],
          [46.168347, 35.694225],
          [46.167985, 35.693654],
          [46.167624, 35.693084],
          [46.167506, 35.692519],
          [46.167549, 35.691192],
          [46.167472, 35.690257],
          [46.167013, 35.689946],
          [46.166379, 35.689611],
          [46.165718, 35.68919],
          [46.165081, 35.688964],
          [46.163791, 35.688931],
          [46.162737, 35.688846],
          [46.161742, 35.688784],
          [46.160924, 35.688839],
          [46.159492, 35.688877],
          [46.159087, 35.689143],
          [46.157808, 35.689704],
          [46.157462, 35.689994],
          [46.15622, 35.691055],
          [46.156105, 35.691271],
          [46.155106, 35.691801],
          [46.153819, 35.692256],
          [46.152363, 35.692784],
          [46.151645, 35.692904],
          [46.15112, 35.692888],
          [46.150222, 35.693151],
          [46.148946, 35.693408],
          [46.147802, 35.693784],
          [46.147066, 35.694004],
          [46.146113, 35.694364],
          [46.145234, 35.694664],
          [46.144086, 35.695149],
          [46.143488, 35.695458],
          [46.143003, 35.695851],
          [46.142108, 35.696715],
          [46.141569, 35.697342],
          [46.140902, 35.697671],
          [46.140387, 35.697773],
          [46.139734, 35.697597],
          [46.138908, 35.696944],
          [46.137955, 35.696455],
          [46.137275, 35.69587],
          [46.136448, 35.695867],
          [46.135559, 35.696071],
          [46.134912, 35.695862],
          [46.133244, 35.695735],
          [46.132158, 35.695435],
          [46.131439, 35.695185],
          [46.130644, 35.695285],
          [46.129813, 35.69545],
          [46.128856, 35.695594],
          [46.127895, 35.695717],
          [46.127444, 35.69577],
          [46.126565, 35.695617],
          [46.125917, 35.69553],
          [46.125506, 35.695212],
          [46.125144, 35.694839],
          [46.124998, 35.694248],
          [46.124781, 35.693805],
          [46.124424, 35.693323],
          [46.123941, 35.692947],
          [46.123705, 35.692398],
          [46.123501, 35.691894],
          [46.123143, 35.691487],
          [46.1226, 35.691207],
          [46.122165, 35.690909],
          [46.121512, 35.690686],
          [46.12062, 35.690507],
          [46.119806, 35.690245],
          [46.11872, 35.689847],
          [46.117939, 35.689357],
          [46.116735, 35.688697],
          [46.115534, 35.688184],
          [46.113704, 35.687877],
          [46.112769, 35.687751],
          [46.111593, 35.687826],
          [46.11, 35.688137],
          [46.108664, 35.688139],
          [46.106717, 35.688109],
          [46.10617, 35.68794],
          [46.105556, 35.687866],
          [46.10511, 35.687901],
          [46.10405, 35.688009],
          [46.10265, 35.688056],
          [46.101857, 35.687842],
          [46.101044, 35.687718],
          [46.100057, 35.688151],
          [46.099377, 35.688285],
          [46.098695, 35.688469],
          [46.098397, 35.689151],
          [46.09824, 35.689675],
          [46.098265, 35.690194],
          [46.098312, 35.691035],
          [46.098242, 35.691696],
          [46.097896, 35.692443],
          [46.097282, 35.692864],
          [46.096563, 35.693096],
          [46.095871, 35.69327],
          [46.094939, 35.693399],
          [46.094106, 35.693356],
          [46.093391, 35.693357],
          [46.092658, 35.693448],
          [46.091812, 35.693161],
          [46.090866, 35.692741],
          [46.090239, 35.692759],
          [46.089186, 35.693189],
          [46.088649, 35.693647],
          [46.088041, 35.693878],
          [46.087207, 35.694072],
          [46.086522, 35.694153],
          [46.085708, 35.694216],
          [46.085061, 35.694166],
          [46.084173, 35.693999],
          [46.083229, 35.693824],
          [46.08205, 35.693692],
          [46.081197, 35.693554],
          [46.080059, 35.693258],
          [46.079068, 35.693218],
          [46.078318, 35.693223],
          [46.077262, 35.693339],
          [46.076641, 35.693268],
          [46.076158, 35.693157],
          [46.074958, 35.693038],
          [46.073856, 35.692924],
          [46.072481, 35.692661],
          [46.071764, 35.692534],
          [46.070859, 35.692197],
          [46.069903, 35.691787],
          [46.069389, 35.692006],
          [46.068948, 35.69246],
          [46.068341, 35.693055],
          [46.06784, 35.693272],
          [46.06735, 35.693487],
          [46.067166, 35.693896],
          [46.067143, 35.69444],
          [46.0666, 35.695057],
          [46.066182, 35.69529],
          [46.065442, 35.696033],
          [46.064289, 35.696684],
          [46.063132, 35.697915],
          [46.062517, 35.69933],
          [46.061902, 35.700594],
          [46.061558, 35.70117],
          [46.061249, 35.700885],
          [46.060822, 35.700693],
          [46.060212, 35.700433],
          [46.059853, 35.700369],
          [46.059639, 35.700242],
          [46.059287, 35.700118],
          [46.058473, 35.69993],
          [46.057808, 35.699761],
          [46.056884, 35.699781],
          [46.056253, 35.699715],
          [46.055262, 35.699764],
          [46.054989, 35.699762],
          [46.054362, 35.699598],
          [46.053536, 35.699564],
          [46.052636, 35.69958],
          [46.051854, 35.69988],
          [46.051369, 35.700084],
          [46.050664, 35.700078],
          [46.050228, 35.700138],
          [46.049721, 35.700302],
          [46.048942, 35.700763],
          [46.048518, 35.701151],
          [46.048307, 35.701815],
          [46.04789, 35.701971],
          [46.047478, 35.70221],
          [46.047047, 35.702299],
          [46.046499, 35.702556],
          [46.046319, 35.702832],
          [46.046309, 35.703143],
          [46.046485, 35.703862],
          [46.046388, 35.704219],
          [46.046529, 35.704559],
          [46.046699, 35.705126],
          [46.046798, 35.705432],
          [46.046734, 35.705957],
          [46.046026, 35.706438],
          [46.045373, 35.706568],
          [46.044382, 35.706645],
          [46.043831, 35.706648],
          [46.043354, 35.706684],
          [46.042848, 35.706729],
          [46.042465, 35.706728],
          [46.042115, 35.706255],
          [46.041591, 35.705584],
          [46.041426, 35.705282],
          [46.041002, 35.704864],
          [46.040779, 35.704723],
          [46.040387, 35.704314],
          [46.040081, 35.704061],
          [46.039734, 35.704109],
          [46.03902, 35.704267],
          [46.038225, 35.704411],
          [46.037405, 35.704854],
          [46.036668, 35.705133],
          [46.036137, 35.705206],
          [46.035721, 35.705278],
          [46.035072, 35.704708],
          [46.034675, 35.704035],
          [46.034487, 35.703466],
          [46.03423, 35.70268],
          [46.033959, 35.701941],
          [46.033451, 35.701681],
          [46.033034, 35.701448],
          [46.032804, 35.700821],
          [46.032366, 35.70066],
          [46.032127, 35.70056],
          [46.031844, 35.700511],
          [46.031404, 35.700375],
          [46.031273, 35.700147],
          [46.031283, 35.699777],
          [46.031449, 35.699311],
          [46.031345, 35.699006],
          [46.031322, 35.698714],
          [46.03161, 35.698369],
          [46.031902, 35.698019],
          [46.03185, 35.697495],
          [46.031696, 35.697182],
          [46.031957, 35.696703],
          [46.032197, 35.696065],
          [46.032081, 35.695673],
          [46.032088, 35.695318],
          [46.03227, 35.694934],
          [46.032554, 35.69427],
          [46.032719, 35.693677],
          [46.032542, 35.693283],
          [46.032269, 35.693104],
          [46.031668, 35.69271],
          [46.031427, 35.692448],
          [46.030938, 35.69228],
          [46.030314, 35.692071],
          [46.029563, 35.691806],
          [46.028721, 35.691838],
          [46.028353, 35.691938],
          [46.02779, 35.69206],
          [46.027083, 35.691922],
          [46.026629, 35.69196],
          [46.026225, 35.691878],
          [46.026084, 35.691184],
          [46.025899, 35.690811],
          [46.025689, 35.690461],
          [46.025664, 35.689948],
          [46.025576, 35.689636],
          [46.025118, 35.689202],
          [46.025308, 35.688734],
          [46.025517, 35.688449],
          [46.025946, 35.687903],
          [46.026262, 35.68752],
          [46.026235, 35.687056],
          [46.02622, 35.686832],
          [46.025845, 35.686151],
          [46.025326, 35.685805],
          [46.024807, 35.685724],
          [46.024284, 35.685599],
          [46.023713, 35.685565],
          [46.023286, 35.68555],
          [46.022608, 35.685526],
          [46.022235, 35.68539],
          [46.022095, 35.685208],
          [46.022133, 35.684888],
          [46.021906, 35.684634],
          [46.021695, 35.68452],
          [46.021072, 35.684857],
          [46.020456, 35.68508],
          [46.020059, 35.685213],
          [46.019557, 35.685396],
          [46.018733, 35.685696],
          [46.018124, 35.685712],
          [46.017747, 35.685621],
          [46.017343, 35.685421],
          [46.016977, 35.68523],
          [46.016551, 35.685043],
          [46.016181, 35.684922],
          [46.015596, 35.684846],
          [46.015186, 35.684681],
          [46.014762, 35.684592],
          [46.014311, 35.68461],
          [46.013923, 35.684373],
          [46.013646, 35.684018],
          [46.013431, 35.683649],
          [46.013311, 35.683115],
          [46.013334, 35.682693],
          [46.013252, 35.682198],
          [46.013165, 35.681709],
          [46.012892, 35.681348],
          [46.012495, 35.681004],
          [46.012036, 35.680659],
          [46.011743, 35.680375],
          [46.011432, 35.680069],
          [46.011072, 35.679907],
          [46.010733, 35.679851],
          [46.010027, 35.67951],
          [46.009443, 35.679138],
          [46.009149, 35.678707],
          [46.009225, 35.678249],
          [46.009269, 35.677864],
          [46.009082, 35.677423],
          [46.008904, 35.677083],
          [46.008632, 35.676845],
          [46.008499, 35.676578],
          [46.008209, 35.676407],
          [46.008478, 35.675848],
          [46.008575, 35.675338],
          [46.008581, 35.674924],
          [46.008533, 35.674385],
          [46.008882, 35.673894],
          [46.009176, 35.673583],
          [46.009717, 35.673292],
          [46.010305, 35.673054],
          [46.01087, 35.67273],
          [46.011084, 35.672651],
          [46.011279, 35.672177],
          [46.011378, 35.671887],
          [46.01159, 35.671494],
          [46.011874, 35.671106],
          [46.012136, 35.670843],
          [46.012546, 35.670684],
          [46.012854, 35.670558],
          [46.013049, 35.670201],
          [46.013162, 35.669925],
          [46.013459, 35.669594],
          [46.013635, 35.669387],
          [46.014011, 35.669055],
          [46.014182, 35.668732],
          [46.014565, 35.668261],
          [46.014832, 35.667604],
          [46.015083, 35.66723],
          [46.015208, 35.666829],
          [46.015079, 35.666464],
          [46.014817, 35.666126],
          [46.014543, 35.665765],
          [46.014431, 35.665422],
          [46.014369, 35.665175],
          [46.014186, 35.664718],
          [46.014214, 35.664527],
          [46.01452, 35.664367],
          [46.01494, 35.664255],
          [46.015185, 35.664206],
          [46.01544, 35.664028],
          [46.015475, 35.663783],
          [46.015351, 35.6635],
          [46.014528, 35.663047],
          [46.014365, 35.662917],
          [46.014095, 35.662301],
          [46.013646, 35.66161],
          [46.013363, 35.661084],
          [46.013367, 35.660326],
          [46.013292, 35.659928],
          [46.013229, 35.65955],
          [46.012988, 35.65899],
          [46.012934, 35.658826],
          [46.012936, 35.658477],
          [46.012973, 35.658312],
          [46.013036, 35.658142],
          [46.013086, 35.657988],
          [46.01309, 35.657829],
          [46.013057, 35.657755],
          [46.012958, 35.657687],
          [46.012762, 35.657546],
          [46.012682, 35.657409],
          [46.012642, 35.657276],
          [46.012627, 35.657133],
          [46.012547, 35.657002],
          [46.0125, 35.656854],
          [46.012542, 35.656626],
          [46.01256, 35.65644],
          [46.012559, 35.656177],
          [46.012523, 35.655976],
          [46.012491, 35.65574],
          [46.012419, 35.655385],
          [46.012449, 35.655054],
          [46.012721, 35.65432],
          [46.01272, 35.65432],
          [46.012694, 35.654305],
          [46.012621, 35.654282],
          [46.012528, 35.654256],
          [46.012461, 35.654235],
          [46.01234, 35.65419],
          [46.012214, 35.65412],
          [46.012082, 35.654019],
          [46.01203, 35.65399],
          [46.01193, 35.653979],
          [46.011815, 35.653972],
          [46.011687, 35.653964],
          [46.01158, 35.653944],
          [46.01144, 35.653924],
          [46.011317, 35.653921],
          [46.011248, 35.653923],
          [46.011147, 35.653934],
          [46.011033, 35.653931],
          [46.010912, 35.653914],
          [46.010849, 35.653898],
          [46.010848, 35.653898],
          [46.010848, 35.653894],
          [46.010857, 35.653828],
          [46.010875, 35.653782],
          [46.010896, 35.653754],
          [46.010939, 35.653758],
          [46.010986, 35.653771],
          [46.011064, 35.65378],
          [46.011178, 35.653781],
          [46.011256, 35.653757],
          [46.011293, 35.65372],
          [46.011314, 35.653689],
          [46.011297, 35.653651],
          [46.011282, 35.653604],
          [46.01126, 35.653529],
          [46.011297, 35.653497],
          [46.0113, 35.653448],
          [46.011332, 35.653395],
          [46.011355, 35.653342],
          [46.011376, 35.653273],
          [46.01139, 35.65322],
          [46.011404, 35.653182],
          [46.011436, 35.653155],
          [46.011465, 35.653131],
          [46.011514, 35.65311],
          [46.01156, 35.653079],
          [46.011559, 35.653042],
          [46.011562, 35.652999],
          [46.011565, 35.652942],
          [46.011561, 35.652889],
          [46.011569, 35.652846],
          [46.011572, 35.652811],
          [46.011518, 35.652718],
          [46.011424, 35.652619],
          [46.011349, 35.652495],
          [46.01131, 35.652379],
          [46.011314, 35.652307],
          [46.011336, 35.652242],
          [46.011345, 35.652178],
          [46.011325, 35.652121],
          [46.011269, 35.652069],
          [46.011236, 35.652016],
          [46.01124, 35.651948],
          [46.011248, 35.651892],
          [46.011243, 35.651839],
          [46.011219, 35.651775],
          [46.011177, 35.651711],
          [46.011138, 35.651632],
          [46.011119, 35.651579],
          [46.011091, 35.651556],
          [46.011036, 35.651553],
          [46.011003, 35.651531],
          [46.010993, 35.651482],
          [46.010993, 35.651429],
          [46.010955, 35.651376],
          [46.010922, 35.65132],
          [46.01093, 35.651252],
          [46.010939, 35.65121],
          [46.01098, 35.651149],
          [46.010997, 35.651089],
          [46.010987, 35.650979],
          [46.010937, 35.650922],
          [46.010814, 35.650824],
          [46.010769, 35.650711],
          [46.010646, 35.650564],
          [46.010601, 35.650451],
          [46.010609, 35.650366],
          [46.010668, 35.650273],
          [46.010737, 35.650237],
          [46.010814, 35.650166],
          [46.010865, 35.650059],
          [46.01082, 35.649932],
          [46.010854, 35.649882],
          [46.010862, 35.649818],
          [46.010965, 35.649718],
          [46.010973, 35.649626],
          [46.010841, 35.6495],
          [46.010787, 35.649401],
          [46.010699, 35.649253],
          [46.010696, 35.649048],
          [46.010737, 35.648877],
          [46.010727, 35.648729],
          [46.010657, 35.648666],
          [46.010483, 35.648667],
          [46.010345, 35.648767],
          [46.010147, 35.648889],
          [46.009949, 35.648962],
          [46.00981, 35.648977],
          [46.009749, 35.648949],
          [46.009695, 35.648801],
          [46.009667, 35.648624],
          [46.009648, 35.648497],
          [46.009578, 35.648469],
          [46.009527, 35.648533],
          [46.009459, 35.648619],
          [46.009398, 35.648612],
          [46.00938, 35.648542],
          [46.009258, 35.648522],
          [46.00917, 35.648444],
          [46.00916, 35.648317],
          [46.009184, 35.648161],
          [46.009209, 35.648069],
          [46.009121, 35.648006],
          [46.009, 35.648035],
          [46.008844, 35.648058],
          [46.008679, 35.648045],
          [46.008626, 35.647967],
          [46.008643, 35.647882],
          [46.008468, 35.647799],
          [46.008381, 35.647807],
          [46.008226, 35.647857],
          [46.008105, 35.647901],
          [46.007939, 35.647803],
          [46.007894, 35.647669],
          [46.007659, 35.647636],
          [46.007467, 35.647559],
          [46.007326, 35.647405],
          [46.007273, 35.64727],
          [46.007263, 35.647157],
          [46.007219, 35.647115],
          [46.00714, 35.647066],
          [46.007061, 35.646989],
          [46.007068, 35.646897],
          [46.007101, 35.646776],
          [46.007074, 35.646663],
          [46.007022, 35.646628],
          [46.006743, 35.646524],
          [46.006638, 35.646468],
          [46.006455, 35.646399],
          [46.006333, 35.646372],
          [46.006263, 35.646323],
          [46.006262, 35.646238],
          [46.00633, 35.646145],
          [46.006425, 35.64606],
          [46.006519, 35.645953],
          [46.006535, 35.645853],
          [46.006559, 35.645697],
          [46.006488, 35.645549],
          [46.006374, 35.645493],
          [46.00626, 35.64536],
          [46.006086, 35.645305],
          [46.00593, 35.645327],
          [46.005834, 35.645321],
          [46.005711, 35.645201],
          [46.005642, 35.645188],
          [46.005529, 35.645203],
          [46.005408, 35.645239],
          [46.005286, 35.645212],
          [46.005285, 35.645106],
          [46.005318, 35.645006],
          [46.005342, 35.644801],
          [46.005393, 35.64473],
          [46.005409, 35.644652],
          [46.00533, 35.644596],
          [46.005243, 35.644596],
          [46.005149, 35.64464],
          [46.005115, 35.644689],
          [46.005184, 35.644724],
          [46.005176, 35.64476],
          [46.005064, 35.644775],
          [46.005038, 35.644818],
          [46.00503, 35.644853],
          [46.00497, 35.644896],
          [46.004822, 35.644876],
          [46.004778, 35.644791],
          [46.004665, 35.644799],
          [46.004612, 35.644779],
          [46.004568, 35.644715],
          [46.004602, 35.644609],
          [46.004618, 35.644538],
          [46.004565, 35.644411],
          [46.004528, 35.644284],
          [46.004415, 35.644242],
          [46.004268, 35.64425],
          [46.004182, 35.644322],
          [46.004087, 35.644372],
          [46.003983, 35.644366],
          [46.003877, 35.644268],
          [46.003771, 35.644077],
          [46.003744, 35.644014],
          [46.003821, 35.64395],
          [46.003803, 35.643893],
          [46.00369, 35.643887],
          [46.003689, 35.643802],
          [46.003515, 35.643768],
          [46.003411, 35.64379],
          [46.003335, 35.643883],
          [46.00324, 35.64399],
          [46.003154, 35.644047],
          [46.00305, 35.644013],
          [46.002962, 35.643928],
          [46.0029, 35.643823],
          [46.002873, 35.643738],
          [46.002803, 35.643731],
          [46.0027, 35.643739],
          [46.002587, 35.643776],
          [46.002562, 35.643826],
          [46.002588, 35.643854],
          [46.002545, 35.643889],
          [46.002468, 35.643925],
          [46.00233, 35.644004],
          [46.002183, 35.644027],
          [46.002045, 35.644099],
          [46.001968, 35.644192],
          [46.001908, 35.644263],
          [46.001752, 35.644278],
          [46.001647, 35.644223],
          [46.001576, 35.644089],
          [46.001583, 35.643982],
          [46.001626, 35.643918],
          [46.001582, 35.643841],
          [46.001433, 35.643757],
          [46.001311, 35.643694],
          [46.001284, 35.643638],
          [46.001283, 35.643595],
          [46.001231, 35.643525],
          [46.001221, 35.643447],
          [46.001237, 35.643369],
          [46.001263, 35.643319],
          [46.001358, 35.643283],
          [46.001462, 35.643282],
          [46.001505, 35.643254],
          [46.001496, 35.643197],
          [46.001434, 35.643148],
          [46.001425, 35.643063],
          [46.001406, 35.642999],
          [46.001319, 35.642936],
          [46.001153, 35.642888],
          [46.001013, 35.642741],
          [46.000952, 35.642706],
          [46.000665, 35.642659],
          [46.000429, 35.642561],
          [46.000385, 35.642498],
          [46.000393, 35.642434],
          [46.000486, 35.642278],
          [46.000529, 35.642199],
          [46.000511, 35.642143],
          [46.000416, 35.642158],
          [46.000346, 35.642173],
          [46.00025, 35.642103],
          [46.000111, 35.642075],
          [45.99992, 35.642084],
          [45.999824, 35.642064],
          [45.999761, 35.64188],
          [45.999743, 35.641781],
          [45.99982, 35.641695],
          [45.999879, 35.641546],
          [45.99986, 35.641447],
          [45.999764, 35.641413],
          [45.99959, 35.641357],
          [45.999398, 35.641246],
          [45.999379, 35.64114],
          [45.9993, 35.641034],
          [45.999116, 35.640943],
          [45.998968, 35.640888],
          [45.99875, 35.640805],
          [45.998595, 35.640863],
          [45.998371, 35.640978],
          [45.998214, 35.640972],
          [45.998039, 35.640839],
          [45.997856, 35.640777],
          [45.99769, 35.640693],
          [45.997533, 35.64061],
          [45.997436, 35.640483],
          [45.997382, 35.64032],
          [45.997337, 35.640193],
          [45.997267, 35.640166],
          [45.997205, 35.640074],
          [45.997136, 35.640046],
          [45.996954, 35.640097],
          [45.996825, 35.640176],
          [45.996748, 35.640276],
          [45.996567, 35.640391],
          [45.996395, 35.640442],
          [45.99623, 35.640443],
          [45.996073, 35.640367],
          [45.995933, 35.640318],
          [45.995838, 35.640376],
          [45.995656, 35.64037],
          [45.9955, 35.640357],
          [45.995327, 35.640394],
          [45.995136, 35.640382],
          [45.995041, 35.640418],
          [45.995086, 35.640517],
          [45.995104, 35.640644],
          [45.995044, 35.64068],
          [45.994932, 35.640695],
          [45.994749, 35.640661],
          [45.994435, 35.640529],
          [45.994269, 35.640417],
          [45.994163, 35.640283],
          [45.994152, 35.640121],
          [45.994159, 35.639972],
          [45.99414, 35.639837],
          [45.994026, 35.639732],
          [45.994007, 35.639598],
          [45.994067, 35.639534],
          [45.994108, 35.639377],
          [45.994125, 35.639285],
          [45.994037, 35.639243],
          [45.993951, 35.639294],
          [45.993812, 35.639295],
          [45.993759, 35.639161],
          [45.993792, 35.63904],
          [45.99393, 35.638954],
          [45.994041, 35.638861],
          [45.994118, 35.638775],
          [45.99417, 35.638704],
          [45.994151, 35.638626],
          [45.994176, 35.638527],
          [45.994253, 35.638441],
          [45.994312, 35.638335],
          [45.994284, 35.638179],
          [45.994195, 35.637974],
          [45.994272, 35.63791],
          [45.994384, 35.637831],
          [45.994513, 35.637759],
          [45.994634, 35.637645],
          [45.994692, 35.637496],
          [45.994682, 35.637368],
          [45.994551, 35.63727],
          [45.994454, 35.637165],
          [45.994426, 35.636995],
          [45.994285, 35.636869],
          [45.994163, 35.636757],
          [45.993986, 35.636524],
          [45.993855, 35.636419],
          [45.993819, 35.636342],
          [45.993853, 35.636249],
          [45.99392, 35.636114],
          [45.994014, 35.635979],
          [45.994135, 35.635879],
          [45.994246, 35.635786],
          [45.994314, 35.635693],
          [45.994261, 35.635623],
          [45.994183, 35.635609],
          [45.993992, 35.635611],
          [45.993767, 35.635591],
          [45.993609, 35.635501],
          [45.993556, 35.635395],
          [45.99359, 35.635331],
          [45.993659, 35.635302],
          [45.993745, 35.635223],
          [45.993805, 35.635166],
          [45.993804, 35.635095],
          [45.993734, 35.635032],
          [45.993586, 35.635026],
          [45.993466, 35.635105],
          [45.99338, 35.635219],
          [45.993294, 35.635255],
          [45.993129, 35.635228],
          [45.992954, 35.635145],
          [45.992876, 35.635089],
          [45.992892, 35.635004],
          [45.992943, 35.634968],
          [45.993056, 35.634924],
          [45.993228, 35.634845],
          [45.993367, 35.634823],
          [45.993392, 35.634759],
          [45.993365, 35.634688],
          [45.993295, 35.634632],
          [45.993165, 35.634626],
          [45.993034, 35.634556],
          [45.992997, 35.634401],
          [45.993171, 35.634442],
          [45.993258, 35.634427],
          [45.993292, 35.634377],
          [45.993317, 35.634306],
          [45.993342, 35.634193],
          [45.993289, 35.634108],
          [45.993279, 35.634023],
          [45.993347, 35.633938],
          [45.993415, 35.633817],
          [45.993465, 35.633632],
          [45.993687, 35.63334],
          [45.993875, 35.63314],
          [45.994038, 35.632997],
          [45.994323, 35.632881],
          [45.994738, 35.632807],
          [45.995189, 35.632768],
          [45.995475, 35.632766],
          [45.995804, 35.632721],
          [45.996254, 35.632618],
          [45.99641, 35.632602],
          [45.99654, 35.632601],
          [45.996756, 35.632543],
          [45.997033, 35.632491],
          [45.99732, 35.63251],
          [45.99752, 35.632543],
          [45.997642, 35.632613],
          [45.997764, 35.63269],
          [45.997938, 35.632717],
          [45.998147, 35.632786],
          [45.998391, 35.632883],
          [45.998532, 35.632995],
          [45.998837, 35.633092],
          [45.999088, 35.633076],
          [45.999157, 35.633082],
          [45.999228, 35.633167],
          [45.999307, 35.633251],
          [45.999577, 35.633362],
          [45.99983, 35.633459],
          [46.000491, 35.633631],
          [46.0007, 35.633629],
          [46.000935, 35.633705],
          [46.001021, 35.633718],
          [46.001213, 35.633759],
          [46.001526, 35.63382],
          [46.001725, 35.633783],
          [46.001942, 35.63381],
          [46.002228, 35.633765],
          [46.002306, 35.633729],
          [46.002426, 35.633657],
          [46.002747, 35.633619],
          [46.002971, 35.633532],
          [46.003186, 35.633396],
          [46.003427, 35.633217],
          [46.003738, 35.63308],
          [46.0041, 35.632956],
          [46.004463, 35.632854],
          [46.004766, 35.632823],
          [46.005035, 35.6328],
          [46.005329, 35.632726],
          [46.005623, 35.632653],
          [46.005969, 35.632516],
          [46.006201, 35.632372],
          [46.00633, 35.6323],
          [46.006503, 35.63227],
          [46.006745, 35.632212],
          [46.006893, 35.632211],
          [46.007023, 35.632224],
          [46.007128, 35.632272],
          [46.007163, 35.632343],
          [46.00727, 35.632505],
          [46.007383, 35.632582],
          [46.007567, 35.632672],
          [46.007731, 35.632685],
          [46.008009, 35.63269],
          [46.008209, 35.632702],
          [46.008426, 35.6327],
          [46.008702, 35.632592],
          [46.008918, 35.632519],
          [46.009108, 35.632468],
          [46.009273, 35.632446],
          [46.009368, 35.632445],
          [46.009533, 35.632479],
          [46.009663, 35.632456],
          [46.009749, 35.632399],
          [46.009661, 35.632322],
          [46.009652, 35.63223],
          [46.009694, 35.632145],
          [46.009684, 35.632038],
          [46.009709, 35.631982],
          [46.009908, 35.631902],
          [46.009993, 35.631823],
          [46.010053, 35.631695],
          [46.010095, 35.63161],
          [46.010181, 35.631567],
          [46.01031, 35.631516],
          [46.010422, 35.631416],
          [46.010453, 35.631125],
          [46.010477, 35.630955],
          [46.010657, 35.630763],
          [46.010794, 35.630634],
          [46.010896, 35.63047],
          [46.011008, 35.630427],
          [46.011128, 35.630334],
          [46.011179, 35.630199],
          [46.011151, 35.630079],
          [46.011072, 35.629959],
          [46.011175, 35.629901],
          [46.011304, 35.629801],
          [46.011406, 35.629637],
          [46.011429, 35.629446],
          [46.011436, 35.629262],
          [46.011286, 35.629114],
          [46.011129, 35.628981],
          [46.011145, 35.628924],
          [46.011145, 35.628854],
          [46.011178, 35.628768],
          [46.011299, 35.628732],
          [46.011506, 35.628645],
          [46.011618, 35.628538],
          [46.011773, 35.628466],
          [46.011885, 35.628451],
          [46.012015, 35.628421],
          [46.01223, 35.62825],
          [46.012272, 35.6282],
          [46.012367, 35.628128],
          [46.012513, 35.628035],
          [46.01266, 35.627963],
          [46.012764, 35.627926],
          [46.012867, 35.627897],
          [46.012945, 35.627868],
          [46.013005, 35.627783],
          [46.013004, 35.627698],
          [46.012977, 35.627613],
          [46.01294, 35.627436],
          [46.012903, 35.627309],
          [46.012816, 35.627246],
          [46.012754, 35.62714],
          [46.012752, 35.627048],
          [46.012803, 35.626913],
          [46.012827, 35.626779],
          [46.012817, 35.626672],
          [46.012869, 35.626622],
          [46.012851, 35.62658],
          [46.01285, 35.626488],
          [46.012857, 35.626396],
          [46.012882, 35.626318],
          [46.012889, 35.626155],
          [46.012913, 35.626013],
          [46.012929, 35.625949],
          [46.012929, 35.625892],
          [46.012962, 35.625743],
          [46.012942, 35.625595],
          [46.012889, 35.625482],
          [46.012905, 35.625354],
          [46.012947, 35.625248],
          [46.012918, 35.625078],
          [46.012839, 35.624973],
          [46.012776, 35.624817],
          [46.012707, 35.624797],
          [46.012619, 35.624698],
          [46.012514, 35.624607],
          [46.012459, 35.624423],
          [46.012414, 35.624254],
          [46.012369, 35.624155],
          [46.012253, 35.623936],
          [46.012181, 35.62371],
          [46.012152, 35.623477],
          [46.012177, 35.623349],
          [46.012254, 35.623264],
          [46.012383, 35.62317],
          [46.012399, 35.623064],
          [46.012292, 35.622845],
          [46.012228, 35.622598],
          [46.012225, 35.622379],
          [46.012267, 35.62223],
          [46.012326, 35.622151],
          [46.012481, 35.622015],
          [46.012618, 35.621929],
          [46.012669, 35.621794],
          [46.012684, 35.621624],
          [46.012682, 35.621454],
          [46.012671, 35.621306],
          [46.012712, 35.621107],
          [46.012772, 35.621028],
          [46.012832, 35.62095],
          [46.012909, 35.620886],
          [46.013064, 35.620828],
          [46.013212, 35.620841],
          [46.013334, 35.620875],
          [46.013498, 35.620817],
          [46.013637, 35.620816],
          [46.013741, 35.620801],
          [46.013896, 35.620729],
          [46.014024, 35.620586],
          [46.014074, 35.620394],
          [46.014246, 35.620265],
          [46.014348, 35.620151],
          [46.014424, 35.619981],
          [46.014588, 35.619908],
          [46.014717, 35.619794],
          [46.014775, 35.619631],
          [46.014835, 35.619545],
          [46.01493, 35.619488],
          [46.015033, 35.619409],
          [46.015177, 35.619202],
          [46.015269, 35.618925],
          [46.015328, 35.618741],
          [46.015377, 35.618563],
          [46.015437, 35.618442],
          [46.015487, 35.618322],
          [46.01559, 35.618207],
          [46.015744, 35.618079],
          [46.015752, 35.617965],
          [46.01575, 35.617831],
          [46.015748, 35.617675],
          [46.015651, 35.617569],
          [46.015649, 35.617385],
          [46.015656, 35.617265],
          [46.01569, 35.617194],
          [46.015784, 35.617087],
          [46.015913, 35.617015],
          [46.016025, 35.616936],
          [46.01611, 35.616801],
          [46.016178, 35.61668],
          [46.016167, 35.616559],
          [46.016184, 35.616496],
          [46.016262, 35.616467],
          [46.016426, 35.616402],
          [46.016598, 35.616266],
          [46.016725, 35.616109],
          [46.016784, 35.615952],
          [46.016876, 35.615654],
          [46.016925, 35.615455],
          [46.016906, 35.615272],
          [46.016826, 35.615109],
          [46.016685, 35.614933],
          [46.016605, 35.614821],
          [46.016499, 35.614645],
          [46.016367, 35.61449],
          [46.016165, 35.614315],
          [46.016085, 35.614174],
          [46.016067, 35.614053],
          [46.016194, 35.613868],
          [46.016235, 35.613712],
          [46.016251, 35.613549],
          [46.016328, 35.613449],
          [46.016439, 35.613328],
          [46.016568, 35.613228],
          [46.016635, 35.613093],
          [46.016659, 35.612929],
          [46.016622, 35.612753],
          [46.016655, 35.612561],
          [46.016756, 35.612376],
          [46.016875, 35.61217],
          [46.016968, 35.612006],
          [46.017132, 35.611891],
          [46.017244, 35.611848],
          [46.017278, 35.61177],
          [46.017319, 35.611614],
          [46.017387, 35.611507],
          [46.017489, 35.611322],
          [46.017409, 35.611174],
          [46.017312, 35.611047],
          [46.017311, 35.610948],
          [46.01731, 35.610863],
          [46.01736, 35.610778],
          [46.017403, 35.610699],
          [46.01749, 35.610713],
          [46.017524, 35.610691],
          [46.017515, 35.610635],
          [46.017522, 35.61055],
          [46.01746, 35.610437],
          [46.017519, 35.610309],
          [46.01764, 35.61023],
          [46.017674, 35.610159],
          [46.017724, 35.610017],
          [46.017739, 35.609854],
          [46.017807, 35.609726],
          [46.017883, 35.609569],
          [46.017908, 35.60947],
          [46.017863, 35.609393],
          [46.017879, 35.609244],
          [46.017852, 35.609194],
          [46.017765, 35.60916],
          [46.017626, 35.609133],
          [46.017452, 35.609113],
          [46.017148, 35.60903],
          [46.016973, 35.608954],
          [46.016754, 35.608814],
          [46.016666, 35.608673],
          [46.01655, 35.608448],
          [46.016488, 35.608314],
          [46.016417, 35.608151],
          [46.016258, 35.607954],
          [46.016065, 35.607821],
          [46.015891, 35.607702],
          [46.015855, 35.607625],
          [46.015897, 35.607511],
          [46.015939, 35.607426],
          [46.015991, 35.607376],
          [46.015997, 35.607234],
          [46.016102, 35.60729],
          [46.016136, 35.607183],
          [46.01623, 35.607076],
          [46.016236, 35.606871],
          [46.016129, 35.606681],
          [46.015997, 35.60654],
          [46.015831, 35.606442],
          [46.015744, 35.606344],
          [46.015829, 35.606251],
          [46.01595, 35.606193],
          [46.015922, 35.606038],
          [46.015849, 35.605812],
          [46.015665, 35.605658],
          [46.015353, 35.605618],
          [46.015179, 35.605598],
          [46.015055, 35.605422],
          [46.014924, 35.605296],
          [46.014861, 35.60514],
          [46.014833, 35.604999],
          [46.014762, 35.604865],
          [46.014691, 35.604738],
          [46.014698, 35.604604],
          [46.014567, 35.604498],
          [46.014495, 35.604322],
          [46.014527, 35.604109],
          [46.014448, 35.603997],
          [46.014281, 35.603856],
          [46.014184, 35.603751],
          [46.014182, 35.603567],
          [46.014041, 35.603363],
          [46.013882, 35.603159],
          [46.013786, 35.603096],
          [46.013706, 35.602983],
          [46.013636, 35.602884],
          [46.013556, 35.602722],
          [46.013468, 35.602603],
          [46.013328, 35.602512],
          [46.013239, 35.602399],
          [46.013273, 35.602257],
          [46.013323, 35.602165],
          [46.013286, 35.601953],
          [46.013284, 35.601818],
          [46.013239, 35.601712],
          [46.013185, 35.60155],
          [46.013096, 35.60131],
          [46.013102, 35.601111],
          [46.013109, 35.60097],
          [46.013151, 35.600828],
          [46.013235, 35.6007],
          [46.013339, 35.600642],
          [46.013398, 35.6005],
          [46.013352, 35.600359],
          [46.013307, 35.600168],
          [46.013296, 35.600019],
          [46.013199, 35.599914],
          [46.01319, 35.599822],
          [46.01311, 35.599674],
          [46.013004, 35.599561],
          [46.012977, 35.599427],
          [46.012923, 35.599293],
          [46.012843, 35.599109],
          [46.012875, 35.598967],
          [46.01297, 35.598903],
          [46.013082, 35.598838],
          [46.013107, 35.598753],
          [46.013026, 35.598534],
          [46.012945, 35.598301],
          [46.01296, 35.598082],
          [46.01287, 35.597849],
          [46.012895, 35.597721],
          [46.012876, 35.597615],
          [46.012848, 35.597502],
          [46.012943, 35.597444],
          [46.013125, 35.597429],
          [46.013315, 35.597342],
          [46.013338, 35.597165],
          [46.013215, 35.597038],
          [46.013067, 35.59699],
          [46.013023, 35.596941],
          [46.013065, 35.596834],
          [46.013186, 35.596748],
          [46.013384, 35.596662],
          [46.013556, 35.596575],
          [46.013711, 35.596432],
          [46.013848, 35.596339],
          [46.01395, 35.596211],
          [46.013992, 35.596055],
          [46.013981, 35.595835],
          [46.013971, 35.59575],
          [46.014186, 35.595642],
          [46.014168, 35.595557],
          [46.014011, 35.595495],
          [46.014027, 35.595374],
          [46.014112, 35.595282],
          [46.014085, 35.595176],
          [46.014023, 35.595056],
          [46.013868, 35.594967],
          [46.014045, 35.594402],
          [46.014144, 35.594105],
          [46.014285, 35.593909],
          [46.014427, 35.59383],
          [46.014569, 35.593699],
          [46.014662, 35.593503],
          [46.01482, 35.593463],
          [46.014915, 35.593436],
          [46.014977, 35.593305],
          [46.015072, 35.593253],
          [46.015199, 35.593226],
          [46.015262, 35.593147],
          [46.01534, 35.593017],
          [46.015403, 35.592977],
          [46.015594, 35.592989],
          [46.015657, 35.592949],
          [46.01572, 35.592896],
          [46.015783, 35.592844],
          [46.015782, 35.592779],
          [46.015766, 35.592727],
          [46.015797, 35.592675],
          [46.015924, 35.592661],
          [46.016004, 35.592686],
          [46.016051, 35.592699],
          [46.016067, 35.592647],
          [46.016065, 35.592543],
          [46.016144, 35.592438],
          [46.016316, 35.592268],
          [46.016491, 35.59224],
          [46.01657, 35.592201],
          [46.016569, 35.592149],
          [46.016585, 35.592084],
          [46.01668, 35.59207],
          [46.016823, 35.59203],
          [46.017044, 35.591963],
          [46.017552, 35.591802],
          [46.018185, 35.591641],
          [46.018678, 35.591585],
          [46.019059, 35.59153],
          [46.01925, 35.591489],
          [46.019281, 35.591437],
          [46.019279, 35.591307],
          [46.019231, 35.591243],
          [46.019243, 35.590996],
          [46.019367, 35.590683],
          [46.019476, 35.590565],
          [46.019777, 35.590406],
          [46.020012, 35.59017],
          [46.020091, 35.590066],
          [46.020376, 35.589985],
          [46.020786, 35.589748],
          [46.021118, 35.589524],
          [46.021528, 35.589235],
          [46.021638, 35.58913],
          [46.02178, 35.589116],
          [46.021891, 35.589063],
          [46.022063, 35.588828],
          [46.022424, 35.588435],
          [46.022738, 35.58812],
          [46.022972, 35.587715],
          [46.023143, 35.587402],
          [46.023237, 35.587271],
          [46.023381, 35.586839],
          [46.023419, 35.586629],
          [46.023511, 35.586279],
          [46.023575, 35.586055],
          [46.023669, 35.585634],
          [46.023753, 35.58523],
          [46.024402, 35.584893],
          [46.024931, 35.58456],
          [46.025373, 35.584088],
          [46.025748, 35.583152],
          [46.025892, 35.582659],
          [46.026804, 35.582613],
          [46.027608, 35.582441],
          [46.02814, 35.582253],
          [46.028389, 35.581876],
          [46.028482, 35.581023],
          [46.028633, 35.580509],
          [46.028656, 35.580019],
          [46.028619, 35.57953],
          [46.028807, 35.579067],
          [46.029088, 35.578761],
          [46.029878, 35.578447],
          [46.030008, 35.578082],
          [46.030089, 35.577581],
          [46.029883, 35.577008],
          [46.029957, 35.576616],
          [46.029566, 35.576388],
          [46.02905, 35.576194],
          [46.028829, 35.575789],
          [46.028437, 35.575339],
          [46.02784, 35.575024],
          [46.027397, 35.574875],
          [46.026846, 35.574464],
          [46.026294, 35.574404],
          [46.0257, 35.574322],
          [46.025136, 35.574263],
          [46.024725, 35.573962],
          [46.024205, 35.573798],
          [46.023565, 35.574036],
          [46.023044, 35.574134],
          [46.02254, 35.574025],
          [46.02206, 35.573961],
          [46.021447, 35.574475],
          [46.020272, 35.574668],
          [46.019354, 35.57456],
          [46.01886, 35.574401],
          [46.018284, 35.574519],
          [46.018014, 35.574862],
          [46.01751, 35.574976],
          [46.016954, 35.575033],
          [46.016063, 35.574405],
          [46.015174, 35.574359],
          [46.014395, 35.574487],
          [46.013572, 35.573797],
          [46.013178, 35.573648],
          [46.012662, 35.573717],
          [46.012366, 35.574055],
          [46.01174, 35.574212],
          [46.010686, 35.573877],
          [46.009983, 35.573935],
          [46.0094, 35.573974],
          [46.008537, 35.574179],
          [46.007952, 35.574473],
          [46.007193, 35.57444],
          [46.006671, 35.574613],
          [46.006044, 35.574795],
          [46.005432, 35.575607],
          [46.004951, 35.576002],
          [46.004292, 35.576474],
          [46.004035, 35.576969],
          [46.003757, 35.577435],
          [46.003242, 35.578011],
          [46.002315, 35.578565],
          [46.001735, 35.578302],
          [46.000965, 35.578064],
          [46.000733, 35.577562],
          [46.000427, 35.577073],
          [45.999622, 35.576754],
          [45.998996, 35.576881],
          [45.998451, 35.576863],
          [45.997919, 35.576733],
          [45.997096, 35.576395],
          [45.996258, 35.576519],
          [45.995022, 35.576999],
          [45.994541, 35.577547],
          [45.993978, 35.578088],
          [45.993492, 35.578669],
          [45.992771, 35.579785],
          [45.992181, 35.58032],
          [45.991122, 35.581139],
          [45.990057, 35.581828],
          [45.989605, 35.582076],
          [45.988368, 35.58187],
          [45.987453, 35.582105],
          [45.986649, 35.582364],
          [45.986119, 35.582615],
          [45.98547, 35.583282],
          [45.985009, 35.583596],
          [45.984518, 35.583895],
          [45.983736, 35.583823],
          [45.982826, 35.583501],
          [45.981615, 35.583661],
          [45.980101, 35.583823],
          [45.976859, 35.585386],
          [45.976057, 35.585655],
          [45.975513, 35.58532],
          [45.974894, 35.585163],
          [45.974292, 35.58497],
          [45.97386, 35.584821],
          [45.973302, 35.584696],
          [45.973268, 35.583982],
          [45.973186, 35.583605],
          [45.973209, 35.583273],
          [45.973285, 35.58279],
          [45.972907, 35.582228],
          [45.972584, 35.581623],
          [45.972104, 35.581178],
          [45.971974, 35.58032],
          [45.971426, 35.58006],
          [45.97189, 35.578936],
          [45.973014, 35.578252],
          [45.973304, 35.577375],
          [45.974079, 35.577216],
          [45.974355, 35.57693],
          [45.974699, 35.576666],
          [45.975583, 35.576384],
          [45.976164, 35.576256],
          [45.976262, 35.575649],
          [45.975894, 35.574734],
          [45.976798, 35.573793],
          [45.977089, 35.573324],
          [45.977631, 35.572809],
          [45.977946, 35.572281],
          [45.977987, 35.571707],
          [45.978575, 35.57127],
          [45.978824, 35.570896],
          [45.978974, 35.570181],
          [45.979251, 35.569552],
          [45.979598, 35.568887],
          [45.979971, 35.568382],
          [45.979966, 35.567728],
          [45.980258, 35.566899],
          [45.980378, 35.565907],
          [45.980508, 35.565108],
          [45.980843, 35.564609],
          [45.981184, 35.564023],
          [45.981508, 35.563428],
          [45.982094, 35.562719],
          [45.982553, 35.562393],
          [45.982689, 35.561888],
          [45.982785, 35.56127],
          [45.984273, 35.560535],
          [45.985392, 35.560218],
          [45.986115, 35.559844],
          [45.986659, 35.559546],
          [45.987919, 35.559006],
          [45.988612, 35.556936],
          [45.98937, 35.555442],
          [45.990001, 35.554387],
          [45.990998, 35.553165],
          [45.991513, 35.552382],
          [45.99283, 35.551646],
          [45.993488, 35.551328],
          [45.994404, 35.551419],
          [45.995066, 35.550617],
          [45.995697, 35.549644],
          [45.995809, 35.549371],
          [45.996298, 35.549231],
          [45.996729, 35.549045],
          [45.996865, 35.548949],
          [45.997014, 35.548724],
          [45.997429, 35.548219],
          [45.997658, 35.548087],
          [45.99879, 35.547889],
          [45.999073, 35.547829],
          [45.998929, 35.547664],
          [45.998717, 35.547028],
          [45.998439, 35.546654],
          [45.998182, 35.546364],
          [45.99787, 35.544699],
          [45.9976, 35.543778],
          [45.997581, 35.54375],
          [45.997557, 35.54371],
          [45.997384, 35.543328],
          [45.997193, 35.5431],
          [45.997163, 35.542931],
          [45.99718, 35.542793],
          [45.996999, 35.542634],
          [45.997028, 35.542402],
          [45.997063, 35.534222],
          [45.997344, 35.534256],
          [45.99799, 35.534435],
          [45.998574, 35.534218],
          [45.999243, 35.534082],
          [45.999994, 35.534202],
          [46.000665, 35.5339],
          [46.000925, 35.533433],
          [46.001175, 35.532881],
          [46.001577, 35.532397],
          [46.001855, 35.531994],
          [46.002374, 35.531449],
          [46.002567, 35.531267],
          [46.00276, 35.530687],
          [46.003135, 35.530325],
          [46.003668, 35.530272],
          [46.004166, 35.530227],
          [46.004463, 35.529889],
          [46.004975, 35.529763],
          [46.00544, 35.529549],
          [46.005751, 35.529354],
          [46.00628, 35.529244],
          [46.006797, 35.529088],
          [46.007662, 35.528915],
          [46.007987, 35.528815],
          [46.008447, 35.528718],
          [46.008896, 35.528449],
          [46.009541, 35.528142],
          [46.009862, 35.527673],
          [46.010154, 35.52754],
          [46.010476, 35.527033],
          [46.010458, 35.526482],
          [46.011255, 35.52606],
          [46.01182, 35.525593],
          [46.012709, 35.525377],
          [46.012991, 35.525032],
          [46.013611, 35.524721],
          [46.014026, 35.524419],
          [46.014141, 35.524038],
          [46.014548, 35.523708],
          [46.014885, 35.523295],
          [46.015204, 35.522952],
          [46.015923, 35.52269],
          [46.016245, 35.522183],
          [46.016565, 35.521665],
          [46.017423, 35.52129],
          [46.017979, 35.520747],
          [46.017991, 35.520698],
          [46.017739, 35.520616],
          [46.01741, 35.520549],
          [46.017141, 35.520493],
          [46.016924, 35.520454],
          [46.016585, 35.520407],
          [46.016362, 35.52038],
          [46.01613, 35.52032],
          [46.015623, 35.520172],
          [46.015329, 35.520075],
          [46.015045, 35.519999],
          [46.014691, 35.519961],
          [46.014403, 35.519967],
          [46.014171, 35.519978],
          [46.014028, 35.519884],
          [46.013941, 35.519777],
          [46.013782, 35.519589],
          [46.013624, 35.519433],
          [46.01344, 35.519307],
          [46.013115, 35.51914],
          [46.013161, 35.519152],
          [46.012897, 35.519039],
          [46.012856, 35.519002],
          [46.012638, 35.518921],
          [46.012496, 35.518889],
          [46.012322, 35.518717],
          [46.012159, 35.51862],
          [46.011988, 35.518646],
          [46.011524, 35.518724],
          [46.011257, 35.518755],
          [46.010874, 35.518804],
          [46.010617, 35.51886],
          [46.010465, 35.518857],
          [46.010409, 35.518837],
          [46.010324, 35.518829],
          [46.010152, 35.518859],
          [46.009879, 35.518829],
          [46.009706, 35.518719],
          [46.009609, 35.518612],
          [46.009492, 35.518555],
          [46.0094, 35.518453],
          [46.009263, 35.518359],
          [46.009146, 35.518315],
          [46.008994, 35.518279],
          [46.008827, 35.518243],
          [46.008715, 35.518153],
          [46.008563, 35.518121],
          [46.00837, 35.518086],
          [46.008264, 35.518058],
          [46.008077, 35.518084],
          [46.007916, 35.518098],
          [46.00777, 35.518103],
          [46.007633, 35.518092],
          [46.007558, 35.518134],
          [46.007502, 35.518134],
          [46.007341, 35.518177],
          [46.007257, 35.518269],
          [46.007111, 35.518315],
          [46.006949, 35.518317],
          [46.006817, 35.518214],
          [46.006694, 35.518067],
          [46.006571, 35.517952],
          [46.00647, 35.517932],
          [46.006358, 35.51788],
          [46.006277, 35.51786],
          [46.006236, 35.517802],
          [46.006138, 35.51765],
          [46.006071, 35.517552],
          [46.005994, 35.517491],
          [46.005983, 35.517459],
          [46.005974, 35.517433],
          [46.005892, 35.517368],
          [46.005784, 35.517224],
          [46.005687, 35.51715],
          [46.005561, 35.517106],
          [46.005348, 35.517095],
          [46.00509, 35.517065],
          [46.004908, 35.517029],
          [46.004811, 35.516943],
          [46.004659, 35.51687],
          [46.004597, 35.516771],
          [46.00449, 35.516723],
          [46.004349, 35.516703],
          [46.004252, 35.516626],
          [46.004201, 35.516618],
          [46.004121, 35.516635],
          [46.003954, 35.516649],
          [46.003828, 35.516654],
          [46.003692, 35.516639],
          [46.00353, 35.516628],
          [46.003338, 35.516666],
          [46.003147, 35.516734],
          [46.002905, 35.516761],
          [46.002658, 35.516804],
          [46.002427, 35.51688],
          [46.002175, 35.516895],
          [46.002009, 35.517004],
          [46.001824, 35.51715],
          [46.001694, 35.517221],
          [46.001472, 35.517272],
          [46.001206, 35.517402],
          [46.000935, 35.517462],
          [46.000788, 35.517497],
          [46.000673, 35.51751],
          [46.000497, 35.517582],
          [46.000265, 35.517608],
          [46.000103, 35.517593],
          [46.000022, 35.51761],
          [45.999906, 35.517615],
          [45.999736, 35.517708],
          [45.999609, 35.517713],
          [45.999494, 35.517772],
          [45.999374, 35.517864],
          [45.999248, 35.517865],
          [45.999168, 35.517939],
          [45.998996, 35.517941],
          [45.998932, 35.518053],
          [45.998806, 35.518071],
          [45.99872, 35.518009],
          [45.998635, 35.518105],
          [45.998586, 35.51825],
          [45.998563, 35.518452],
          [45.998656, 35.518588],
          [45.998591, 35.518638],
          [45.998465, 35.51866],
          [45.998373, 35.518582],
          [45.998261, 35.518508],
          [45.9981, 35.518535],
          [45.997832, 35.51852],
          [45.997867, 35.518541],
          [45.997761, 35.518533],
          [45.997707, 35.518608],
          [45.997641, 35.518625],
          [45.997489, 35.518589],
          [45.997412, 35.518458],
          [45.99734, 35.51833],
          [45.997213, 35.518269],
          [45.997097, 35.518262],
          [45.997021, 35.518312],
          [45.996846, 35.518446],
          [45.996701, 35.518542],
          [45.99653, 35.518597],
          [45.996348, 35.518549],
          [45.99607, 35.518551],
          [45.995878, 35.518528],
          [45.995675, 35.518489],
          [45.995513, 35.518403],
          [45.99535, 35.518351],
          [45.995163, 35.51829],
          [45.995086, 35.518233],
          [45.99492, 35.518234],
          [45.994797, 35.518283],
          [45.994677, 35.518324],
          [45.994476, 35.518354],
          [45.994274, 35.518406],
          [45.994133, 35.518493],
          [45.994034, 35.518545],
          [45.993819, 35.518566],
          [45.993779, 35.518611],
          [45.993711, 35.51871],
          [45.993599, 35.518804],
          [45.993503, 35.51893],
          [45.993397, 35.519067],
          [45.993278, 35.519226],
          [45.993092, 35.519391],
          [45.992937, 35.519453],
          [45.992784, 35.519464],
          [45.992783, 35.519462],
          [45.985425, 35.496023],
          [45.986117, 35.495283],
          [45.986481, 35.49517],
          [45.986987, 35.495087],
          [45.987627, 35.494552],
          [45.987781, 35.493955],
          [45.987959, 35.492729],
          [45.988027, 35.491721],
          [45.988126, 35.490784],
          [45.988496, 35.489796],
          [45.988629, 35.489224],
          [45.988892, 35.488804],
          [45.989618, 35.488285],
          [45.990105, 35.48779],
          [45.990455, 35.487506],
          [45.990695, 35.486816],
          [45.990563, 35.486381],
          [45.990602, 35.48598],
          [45.990884, 35.485523],
          [45.991039, 35.485045],
          [45.991331, 35.484575],
          [45.991781, 35.484032],
          [45.992226, 35.483807],
          [45.992351, 35.483149],
          [45.992587, 35.482613],
          [45.99301, 35.48213],
          [45.993257, 35.48181],
          [45.994297, 35.481255],
          [45.994441, 35.4809],
          [45.994669, 35.480333],
          [45.994636, 35.479858],
          [45.994546, 35.479087],
          [45.99474, 35.478548],
          [45.995132, 35.477764],
          [45.995275, 35.477234],
          [45.995312, 35.476713],
          [45.995654, 35.475719],
          [45.995925, 35.47533],
          [45.99624, 35.475184],
          [45.996685, 35.474904],
          [45.996935, 35.474595],
          [45.997384, 35.474445],
          [45.998075, 35.474065],
          [45.998202, 35.473691],
          [45.998412, 35.473105],
          [45.998639, 35.472254],
          [45.998604, 35.471658],
          [45.999059, 35.471136],
          [45.99944, 35.470365],
          [45.999507, 35.470134],
          [46.000013, 35.469547],
          [46.000319, 35.46925],
          [46.000709, 35.468849],
          [46.001041, 35.468492],
          [46.00184, 35.468113],
          [46.002453, 35.467463],
          [46.002591, 35.466409],
          [46.002881, 35.465874],
          [46.00318, 35.465041],
          [46.003488, 35.46447],
          [46.003729, 35.464009],
          [46.003665, 35.463573],
          [46.003973, 35.462892],
          [46.003941, 35.461506],
          [46.004167, 35.460329],
          [46.004566, 35.458782],
          [46.00563, 35.457467],
          [46.006831, 35.456107],
          [46.008183, 35.454837],
          [46.009469, 35.452784],
          [46.009634, 35.452337],
          [46.009784, 35.451929],
          [46.010691, 35.450862],
          [46.011251, 35.45008],
          [46.011684, 35.448566],
          [46.012126, 35.446991],
          [46.013144, 35.445963],
          [46.013313, 35.445036],
          [46.01371, 35.444026],
          [46.015944, 35.442411],
          [46.016685, 35.441197],
          [46.017814, 35.440207],
          [46.019453, 35.439977],
          [46.020224, 35.439593],
          [46.021503, 35.438694],
          [46.02182, 35.437938],
          [46.023084, 35.436804],
          [46.024352, 35.436227],
          [46.025003, 35.435228],
          [46.026104, 35.434046],
          [46.02613, 35.433325],
          [46.026905, 35.431508],
          [46.027988, 35.429354],
          [46.028011, 35.428534],
          [46.028772, 35.426938],
          [46.029261, 35.425354],
          [46.029687, 35.423543],
          [46.029443, 35.422269],
          [46.029552, 35.421421],
          [46.030701, 35.42005],
          [46.031524, 35.41886],
          [46.032267, 35.417924],
          [46.032944, 35.417021],
          [46.033593, 35.41656],
          [46.034367, 35.415558],
          [46.034718, 35.414378],
          [46.035166, 35.4131],
          [46.03633, 35.411965],
          [46.037266, 35.410913],
          [46.0378, 35.410037],
          [46.038265, 35.408636],
          [46.038492, 35.407738],
          [46.03867, 35.40675],
          [46.038331, 35.405342],
          [46.039772, 35.404393],
          [46.040354, 35.404144],
          [46.041232, 35.403879],
          [46.042307, 35.403238],
          [46.043641, 35.401812],
          [46.044224, 35.400747],
          [46.044906, 35.399684],
          [46.04575, 35.398569],
          [46.04646, 35.398236],
          [46.04761, 35.397323],
          [46.048275, 35.395924],
          [46.048587, 35.395149],
          [46.049192, 35.394259],
          [46.049243, 35.393175],
          [46.05037, 35.392087],
          [46.050893, 35.390716],
          [46.050945, 35.389274],
          [46.050838, 35.387981],
          [46.051615, 35.387258],
          [46.052517, 35.386172],
          [46.054737, 35.385414],
          [46.056513, 35.384681],
          [46.058501, 35.383629],
          [46.05927, 35.382787],
          [46.060189, 35.3814],
          [46.061303, 35.380812],
          [46.062742, 35.379943],
          [46.06355, 35.379154],
          [46.064449, 35.378328],
          [46.064217, 35.377397],
          [46.063997, 35.376603],
          [46.064474, 35.373888],
          [46.065856, 35.373089],
          [46.066337, 35.372382],
          [46.066836, 35.37183],
          [46.0674, 35.371246],
          [46.067937, 35.371105],
          [46.06857, 35.370587],
          [46.068312, 35.369741],
          [46.067782, 35.369185],
          [46.067567, 35.368768],
          [46.068605, 35.368174],
          [46.069577, 35.367613],
          [46.070533, 35.366994],
          [46.071579, 35.366339],
          [46.072401, 35.365507],
          [46.073313, 35.364639],
          [46.073611, 35.364443],
          [46.074075, 35.364137],
          [46.07543, 35.363781],
          [46.076513, 35.363715],
          [46.077611, 35.363607],
          [46.078716, 35.363438],
          [46.080276, 35.362288],
          [46.080671, 35.362111],
          [46.081569, 35.361429],
          [46.083249, 35.361083],
          [46.083507, 35.361201],
          [46.084675, 35.360951],
          [46.08563, 35.361076],
          [46.086178, 35.360997],
          [46.086912, 35.361111],
          [46.087913, 35.360501],
          [46.089023, 35.36007],
          [46.090452, 35.359695],
          [46.091371, 35.359655],
          [46.092125, 35.359532],
          [46.092388, 35.359322],
          [46.092718, 35.359279],
          [46.093164, 35.358822],
          [46.093221, 35.358681],
          [46.093495, 35.358452],
          [46.093689, 35.358289],
          [46.093504, 35.358272],
          [46.092182, 35.357783],
          [46.092199, 35.357292],
          [46.092491, 35.355824],
          [46.092897, 35.354356],
          [46.092936, 35.352967],
          [46.093246, 35.35286],
          [46.09349, 35.352013],
          [46.093287, 35.351186],
          [46.093211, 35.350097],
          [46.09498, 35.349474],
          [46.09589, 35.349282],
          [46.096375, 35.349041],
          [46.096822, 35.348529],
          [46.09759, 35.348046],
          [46.0979, 35.347719],
          [46.098341, 35.347228],
          [46.099167, 35.346684],
          [46.099291, 35.346183],
          [46.100353, 35.346171],
          [46.101329, 35.345987],
          [46.102122, 35.345777],
          [46.102701, 35.345608],
          [46.103377, 35.34538],
          [46.104507, 35.344709],
          [46.105157, 35.344298],
          [46.105938, 35.343772],
          [46.106555, 35.343288],
          [46.107397, 35.342661],
          [46.107805, 35.342326],
          [46.108163, 35.341989],
          [46.108983, 35.341467],
          [46.109582, 35.341055],
          [46.110075, 35.340753],
          [46.110522, 35.340151],
          [46.110934, 35.339785],
          [46.111539, 35.339482],
          [46.113467, 35.338501],
          [46.114849, 35.338015],
          [46.115674, 35.337637],
          [46.116496, 35.337349],
          [46.117072, 35.33745],
          [46.117457, 35.337557],
          [46.118331, 35.337551],
          [46.119365, 35.337333],
          [46.120484, 35.337079],
          [46.121349, 35.337335],
          [46.122373, 35.33718],
          [46.123303, 35.336641],
          [46.124158, 35.336226],
          [46.125814, 35.335448],
          [46.127443, 35.334474],
          [46.127784, 35.333836],
          [46.127174, 35.332637],
          [46.126653, 35.331171],
          [46.126413, 35.32973],
          [46.126332, 35.329053],
          [46.126274, 35.328652],
          [46.126335, 35.328038],
          [46.126587, 35.327619],
          [46.126968, 35.327465],
          [46.128704, 35.326682],
          [46.12918, 35.32633],
          [46.129668, 35.325875],
          [46.130073, 35.325416],
          [46.130471, 35.325029],
          [46.130566, 35.324539],
          [46.130849, 35.324083],
          [46.131336, 35.323578],
          [46.131679, 35.323141],
          [46.132044, 35.322881],
          [46.132606, 35.322472],
          [46.133099, 35.322132],
          [46.133383, 35.321972],
          [46.133616, 35.321656],
          [46.134601, 35.320864],
          [46.135618, 35.320439],
          [46.136845, 35.319683],
          [46.137694, 35.318792],
          [46.138919, 35.318129],
          [46.139853, 35.317648],
          [46.14089, 35.316897],
          [46.14179, 35.315892],
          [46.142767, 35.31507],
          [46.14375, 35.314319],
          [46.144336, 35.313718],
          [46.144975, 35.31321],
          [46.145457, 35.312972],
          [46.145984, 35.311706],
          [46.146043, 35.311175],
          [46.146417, 35.310593],
          [46.147046, 35.309349],
          [46.147487, 35.307466],
          [46.14763, 35.306742],
          [46.147877, 35.305498],
          [46.148885, 35.304691],
          [46.149343, 35.303719],
          [46.149848, 35.302769],
          [46.149783, 35.302283],
          [46.149916, 35.30182],
          [46.15006, 35.301398],
          [46.150805, 35.300828],
          [46.151344, 35.299903],
          [46.151443, 35.299468],
          [46.151511, 35.298665],
          [46.151692, 35.297632],
          [46.151194, 35.29701],
          [46.150946, 35.296403],
          [46.150854, 35.295318],
          [46.150956, 35.294394],
          [46.150651, 35.29358],
          [46.15134, 35.292605],
          [46.151604, 35.291972],
          [46.152228, 35.291457],
          [46.153198, 35.290511],
          [46.153502, 35.289725],
          [46.154677, 35.288875],
          [46.155263, 35.288024],
          [46.156568, 35.286805],
          [46.157333, 35.285806],
          [46.158107, 35.285043],
          [46.158684, 35.284506],
          [46.158538, 35.284223],
          [46.158161, 35.283494],
          [46.15791, 35.28328],
          [46.176854, 35.267459],
          [46.17713, 35.267179],
          [46.177797, 35.266787],
          [46.17852, 35.266349],
          [46.1838, 35.263292],
          [46.217133, 35.237733],
          [46.2188, 35.236623],
          [46.257136, 35.213011],
          [46.257693, 35.212732],
          [46.277416, 35.2019],
          [46.280193, 35.200781],
          [46.319362, 35.182175],
          [46.320195, 35.182175],
          [46.337972, 35.17468],
          [46.341028, 35.173291],
          [46.341585, 35.173291],
          [46.342139, 35.172731],
          [46.342695, 35.172731],
          [46.347139, 35.170513],
          [46.363528, 35.156063],
          [46.363528, 35.155513],
          [46.363668, 35.155234],
          [46.363808, 35.154954],
          [46.373251, 35.14551],
          [46.373532, 35.145231],
          [46.375475, 35.141343],
          [46.383808, 35.13134],
          [46.401308, 35.108006],
          [46.401308, 35.107726],
          [46.401864, 35.106617],
          [46.403532, 35.103559],
          [46.404921, 35.101899],
          [46.405033, 35.101396],
          [46.405198, 35.101061],
          [46.415478, 35.090507],
          [46.415478, 35.089948],
          [46.446591, 35.05384],
          [46.448811, 35.052171],
          [46.47659, 35.035511],
          [46.477424, 35.035511],
          [46.483814, 35.033283],
          [46.48687, 35.033283],
          [46.488257, 35.033842],
          [46.490204, 35.033842],
          [46.490481, 35.034113],
          [46.491314, 35.034113],
          [46.499647, 35.036341],
          [46.499924, 35.036061],
          [46.500757, 35.035231],
          [46.501037, 35.034951],
          [46.518537, 35.009117],
          [46.518802, 35.008531],
          [46.518813, 35.007727],
          [46.518813, 35.006898],
          [46.518813, 35.006618],
          [46.518537, 35.006338],
          [46.51826, 35.00384],
          [46.516594, 34.998003],
          [46.518537, 34.991337],
          [46.52576, 34.977724],
          [46.537427, 34.959953],
          [46.537704, 34.959673],
          [46.539094, 34.954946],
          [46.53937, 34.954117],
          [46.539651, 34.946061],
          [46.54215, 34.938564],
          [46.542707, 34.938005],
          [46.543817, 34.936065],
          [46.54604, 34.933557],
          [46.546317, 34.933287],
          [46.547802, 34.93161],
          [46.550535, 34.928523],
          [46.553072, 34.925657],
          [46.555207, 34.922732],
          [46.556223, 34.921901],
          [46.58132, 34.90717],
          [46.599653, 34.899674],
          [46.600486, 34.898835],
          [46.619933, 34.884671],
          [46.629653, 34.875785],
          [46.634933, 34.872447],
          [46.639656, 34.869668],
          [46.639933, 34.869397],
          [46.658266, 34.863001],
          [46.658546, 34.862722],
          [46.660213, 34.862171],
          [46.669656, 34.859392],
          [46.680859, 34.857032],
          [46.718269, 34.850227],
          [46.728268, 34.847719],
          [46.728825, 34.847168],
          [46.742158, 34.840781],
          [46.743548, 34.839951],
          [46.749381, 34.831615],
          [46.749662, 34.831335],
          [46.749662, 34.829666],
          [46.749381, 34.829116],
          [46.749381, 34.828836],
          [46.748828, 34.827726],
          [46.746325, 34.824388],
          [46.745937, 34.824032],
          [46.733548, 34.804946],
          [46.733272, 34.803836],
          [46.732715, 34.797728],
          [46.733272, 34.79411],
          [46.735492, 34.787723],
          [46.735492, 34.787443],
          [46.736882, 34.784944],
          [46.764662, 34.746058],
          [46.767719, 34.743279],
          [46.768272, 34.742999],
          [46.769273, 34.74265],
          [46.769938, 34.74244],
          [46.771885, 34.74189],
          [46.772995, 34.74189],
          [46.773552, 34.74161],
          [46.774662, 34.74161],
          [46.782995, 34.73939],
          [46.785775, 34.73939],
          [46.786051, 34.73967],
          [46.845498, 34.753556],
          [46.847444, 34.753556],
          [46.859387, 34.755505],
          [46.863277, 34.756614],
          [46.86411, 34.756885],
          [46.865221, 34.756885],
          [46.8655, 34.757165],
          [46.886334, 34.762722],
          [46.887444, 34.762722],
          [46.888, 34.763002],
          [46.888834, 34.763002],
          [46.88911, 34.763282],
          [46.889943, 34.763282],
          [46.890223, 34.763562],
          [46.894943, 34.764392],
          [46.897724, 34.764392],
          [46.898277, 34.764392],
          [46.898833, 34.764392],
          [46.901056, 34.763562],
          [46.902167, 34.763562],
          [46.902446, 34.763282],
          [46.926613, 34.751896],
          [46.947726, 34.756615],
          [46.951893, 34.756885],
          [46.955226, 34.756885],
          [46.956059, 34.757165],
          [46.962449, 34.757165],
          [46.963559, 34.756885],
          [46.985225, 34.753556],
          [46.985782, 34.753006],
          [46.986339, 34.753006],
          [46.986892, 34.752446],
          [46.988283, 34.752167],
          [47.005229, 34.746609],
          [47.008838, 34.746609],
          [47.009395, 34.746329],
          [47.013285, 34.746059],
          [47.014396, 34.746609],
          [47.014672, 34.746329],
          [47.015228, 34.746609],
          [47.016062, 34.746609],
          [47.022452, 34.749667],
          [47.022729, 34.749947],
          [47.023285, 34.749947],
          [47.023561, 34.750227],
          [47.023839, 34.750507],
          [47.033562, 34.756885],
          [47.040785, 34.759664],
          [47.041618, 34.759664],
          [47.042452, 34.759944],
          [47.043841, 34.759944],
          [47.046062, 34.760503],
          [47.048561, 34.760503],
          [47.070231, 34.754116],
          [47.081064, 34.755505],
          [47.084674, 34.756335],
          [47.085231, 34.756335],
          [47.088564, 34.757725],
          [47.093844, 34.763562],
          [47.093564, 34.764392],
          [47.093288, 34.765222],
          [47.089398, 34.76967],
          [47.064951, 34.777168],
          [47.063564, 34.777727],
          [47.060232, 34.781336],
          [47.061064, 34.784394],
          [47.067175, 34.792451],
          [47.075508, 34.79661],
          [47.076341, 34.79661],
          [47.076621, 34.79689],
          [47.078287, 34.79689],
          [47.078564, 34.79717],
          [47.087454, 34.792722],
          [47.093011, 34.790782],
          [47.103011, 34.788003],
          [47.106344, 34.788003],
          [47.1069, 34.788283],
          [47.114677, 34.789952],
          [47.115787, 34.790502],
          [47.12301, 34.79689],
          [47.123287, 34.797729],
          [47.1244, 34.798839],
          [47.124677, 34.800219],
          [47.125234, 34.803557],
          [47.12412, 34.805506],
          [47.1244, 34.807166],
          [47.1244, 34.807446],
          [47.123844, 34.807446],
          [47.123567, 34.807726],
          [47.12301, 34.807726],
          [47.109121, 34.816892],
          [47.106344, 34.819391],
          [47.102731, 34.823559],
          [47.082731, 34.842171],
          [47.079397, 34.84439],
          [47.077174, 34.84578],
          [47.074954, 34.847449],
          [47.072175, 34.851058],
          [47.071897, 34.852167],
          [47.071621, 34.852447],
          [47.071897, 34.855226],
          [47.072731, 34.864392],
          [47.073007, 34.864951],
          [47.075787, 34.871338],
          [47.075507, 34.872448],
          [47.074954, 34.876066],
          [47.074674, 34.876616],
          [47.075787, 34.884113],
          [47.081064, 34.89078],
          [47.081897, 34.89078],
          [47.082454, 34.891059],
          [47.083564, 34.891059],
          [47.08384, 34.891339],
          [47.08523, 34.891339],
          [47.085787, 34.891059],
          [47.088011, 34.891059],
          [47.088564, 34.891339],
          [47.091064, 34.89161],
          [47.09412, 34.892728],
          [47.095787, 34.893838],
          [47.101897, 34.899395],
          [47.10412, 34.901615],
          [47.110234, 34.905782],
          [47.111343, 34.905782],
          [47.1119, 34.906062],
          [47.113287, 34.906062],
          [47.11412, 34.906342],
          [47.117453, 34.906342],
          [47.11801, 34.906062],
          [47.11912, 34.906062],
          [47.119677, 34.905782],
          [47.12162, 34.905782],
          [47.13301, 34.901335],
          [47.136343, 34.901335],
          [47.137177, 34.901615],
          [47.13829, 34.901615],
          [47.149956, 34.904953],
          [47.153013, 34.904953],
          [47.157457, 34.903004],
          [47.1594, 34.902454],
          [47.1594, 34.902174],
          [47.159956, 34.902174],
          [47.160233, 34.901894],
          [47.161066, 34.901894],
          [47.161623, 34.901615],
          [47.164123, 34.901615],
          [47.164679, 34.901335],
          [47.165236, 34.901615],
          [47.167179, 34.901615],
          [47.167736, 34.901894],
          [47.168569, 34.901894],
          [47.169122, 34.902174],
          [47.169956, 34.902174],
          [47.17329, 34.903284],
          [47.177736, 34.911899],
          [47.174123, 34.918836],
          [47.173846, 34.919396],
          [47.17468, 34.929391],
          [47.174403, 34.93134],
          [47.174122, 34.933559],
          [47.169956, 34.940226],
          [47.168569, 34.943564],
          [47.16829, 34.944673],
          [47.167736, 34.947731],
          [47.159679, 34.961064],
          [47.159956, 34.962454],
          [47.168846, 34.970509],
          [47.17329, 34.972449],
          [47.175236, 34.972178],
          [47.179402, 34.972449],
          [47.179956, 34.972178],
          [47.181902, 34.972178],
          [47.184959, 34.96912],
          [47.186902, 34.966612],
          [47.189126, 34.959396],
          [47.189402, 34.958836],
          [47.188845, 34.954669],
          [47.191626, 34.946622],
          [47.194402, 34.944114],
          [47.195235, 34.944394],
          [47.201349, 34.944394],
          [47.202736, 34.944673],
          [47.203292, 34.944394],
          [47.204125, 34.944394],
          [47.210236, 34.941615],
          [47.210515, 34.940785],
          [47.210515, 34.938837],
          [47.213015, 34.937447],
          [47.213849, 34.937447],
          [47.214126, 34.937168],
          [47.215236, 34.937168],
          [47.215515, 34.937447],
          [47.217182, 34.937447],
          [47.217458, 34.937727],
          [47.218292, 34.937727],
          [47.218647, 34.937829],
          [47.220239, 34.938286],
          [47.224405, 34.939946],
          [47.224682, 34.940226],
          [47.242182, 34.953009],
          [47.243015, 34.953009],
          [47.243295, 34.953288],
          [47.244961, 34.953288],
          [47.247461, 34.953009],
          [47.247738, 34.952729],
          [47.253015, 34.952729],
          [47.260238, 34.95051],
          [47.265238, 34.945503],
          [47.266628, 34.941895],
          [47.266905, 34.941065],
          [47.267184, 34.940785],
          [47.267184, 34.939946],
          [47.270795, 34.933009],
          [47.281908, 34.927732],
          [47.284685, 34.927732],
          [47.285241, 34.927452],
          [47.288018, 34.927452],
          [47.291351, 34.927172],
          [47.292184, 34.927452],
          [47.293574, 34.927452],
          [47.294408, 34.927732],
          [47.296074, 34.927732],
          [47.296908, 34.928011],
          [47.304964, 34.929392],
          [47.306074, 34.929951],
          [47.306908, 34.929951],
          [47.326631, 34.938566],
          [47.326908, 34.938837],
          [47.332741, 34.946063],
          [47.333021, 34.947452],
          [47.332741, 34.949392],
          [47.332464, 34.950231],
          [47.33274, 34.95106],
          [47.33274, 34.954669],
          [47.324687, 34.968841],
          [47.311631, 34.986062],
          [47.310241, 34.989399],
          [47.309684, 34.991068],
          [47.309407, 34.991619],
          [47.309407, 34.995786],
          [47.308851, 34.997726],
          [47.308851, 35.000215],
          [47.308298, 35.001893],
          [47.308017, 35.002173],
          [47.308298, 35.00634],
          [47.315797, 35.015235],
          [47.317188, 35.016065],
          [47.32052, 35.016895],
          [47.320797, 35.017174],
          [47.321643, 35.017174],
          [47.323297, 35.017174],
          [47.323574, 35.016894],
          [47.329131, 35.016895],
          [47.329407, 35.017174],
          [47.331074, 35.017174],
          [47.331353, 35.017454],
          [47.333297, 35.017454],
          [47.334687, 35.017724],
          [47.337743, 35.018284],
          [47.34913, 35.018284],
          [47.34941, 35.018004],
          [47.354967, 35.018004],
          [47.359967, 35.022451],
          [47.361633, 35.028567],
          [47.361353, 35.028838],
          [47.360243, 35.032175],
          [47.359967, 35.033005],
          [47.356077, 35.042729],
          [47.356077, 35.045787],
          [47.3608, 35.052733],
          [47.3633, 35.053563],
          [47.365243, 35.053563],
          [47.3658, 35.053843],
          [47.371633, 35.053843],
          [47.376357, 35.054672],
          [47.376357, 35.054952],
          [47.378576, 35.055511],
          [47.38469, 35.05746],
          [47.385523, 35.05746],
          [47.38719, 35.05829],
          [47.399413, 35.061339],
          [47.399689, 35.061618],
          [47.404689, 35.061618],
          [47.408856, 35.061068],
          [47.409136, 35.060788],
          [47.409689, 35.060788],
          [47.409969, 35.060509],
          [47.410803, 35.060509],
          [47.420246, 35.056071],
          [47.420522, 35.056341],
          [47.424689, 35.053013],
          [47.425246, 35.052733],
          [47.425522, 35.052733],
          [47.426912, 35.052174],
          [47.429136, 35.052174],
          [47.429693, 35.051894],
          [47.434136, 35.051894],
          [47.434412, 35.051615],
          [47.441636, 35.051615],
          [47.449136, 35.050794],
          [47.449416, 35.050514],
          [47.451359, 35.050514],
          [47.451635, 35.050226],
          [47.460525, 35.050226],
          [47.464416, 35.050514],
          [47.466639, 35.050514],
          [47.467192, 35.050794],
          [47.469139, 35.050794],
          [47.470805, 35.051065],
          [47.487195, 35.063287],
          [47.504695, 35.068284],
          [47.507195, 35.068014],
          [47.513028, 35.066616],
          [47.524694, 35.064126],
          [47.525251, 35.063846],
          [47.527475, 35.063846],
          [47.527751, 35.063567],
          [47.533861, 35.063567],
          [47.534418, 35.063846],
          [47.535528, 35.063846],
          [47.544141, 35.068564],
          [47.544141, 35.068844],
          [47.544698, 35.068844],
          [47.546918, 35.070233],
          [47.557751, 35.072452],
          [47.558308, 35.072731],
          [47.559974, 35.072731],
          [47.560254, 35.073011],
          [47.568307, 35.073011],
          [47.591364, 35.072181],
          [47.59442, 35.072452],
          [47.608034, 35.072181],
          [47.608587, 35.071902],
          [47.61609, 35.071902],
          [47.616643, 35.071622],
          [47.621643, 35.071622],
          [47.6222, 35.071343],
          [47.626366, 35.071343],
          [47.6272, 35.071063],
          [47.629977, 35.071063],
          [47.64498, 35.069403],
          [47.645533, 35.069403],
          [47.64609, 35.068564],
          [47.64859, 35.068014],
          [47.648866, 35.067734],
          [47.650533, 35.067734],
          [47.650813, 35.067455],
          [47.652756, 35.067455],
          [47.653033, 35.067175],
          [47.65498, 35.067175],
          [47.665536, 35.063567],
          [47.666646, 35.062737],
          [47.667479, 35.062458],
          [47.667756, 35.062178],
          [47.668313, 35.062038],
          [47.675813, 35.061069],
          [47.688593, 35.05829],
          [47.690812, 35.058011],
          [47.691369, 35.057731],
          [47.701369, 35.057731],
          [47.702202, 35.058011],
          [47.704426, 35.058011],
          [47.707759, 35.058561],
          [47.708592, 35.058841],
          [47.709702, 35.058841],
          [47.720815, 35.062458],
          [47.722759, 35.062458],
          [47.723592, 35.062737],
          [47.725539, 35.062737],
          [47.726092, 35.062458],
          [47.727205, 35.062458],
          [47.727482, 35.062178],
          [47.728592, 35.062178],
          [47.730262, 35.061339],
          [47.731096, 35.060509],
          [47.733039, 35.056342],
          [47.734705, 35.054403],
          [47.736929, 35.051895],
          [47.745539, 35.047457],
          [47.753318, 35.046068],
          [47.762485, 35.044679],
          [47.765818, 35.043569],
          [47.776928, 35.034396],
          [47.777485, 35.033845],
          [47.778042, 35.033566],
          [47.778318, 35.033286],
          [47.780262, 35.030787],
          [47.786932, 35.023841],
          [47.788875, 35.022732],
          [47.789985, 35.022182],
          [47.790265, 35.021902],
          [47.791098, 35.021902],
          [47.803041, 35.014956],
          [47.803875, 35.013838],
          [47.804151, 35.013008],
          [47.805541, 35.011619],
          [47.810541, 35.002174],
          [47.810821, 35.001344],
          [47.811098, 35.000325],
          [47.811375, 34.999396],
          [47.811374, 34.998845],
          [47.809431, 34.997727],
          [47.806098, 34.988841],
          [47.805265, 34.984953],
          [47.805265, 34.980515],
          [47.813598, 34.969681],
          [47.814431, 34.968012],
          [47.832211, 34.945225],
          [47.839988, 34.938838],
          [47.858601, 34.93329],
          [47.859987, 34.932731],
          [47.860544, 34.932731],
          [47.860821, 34.932451],
          [47.861378, 34.932451],
          [47.861658, 34.932171],
          [47.862491, 34.932171],
          [47.862768, 34.931892],
          [47.880547, 34.927174],
          [47.883324, 34.927174],
          [47.883601, 34.926894],
          [47.889991, 34.926894],
          [47.891101, 34.927174],
          [47.894157, 34.927174],
          [47.895267, 34.927453],
          [47.896934, 34.927453],
          [47.898047, 34.927733],
          [47.899437, 34.927733],
          [47.900271, 34.928013],
          [47.918327, 34.933561],
          [47.919437, 34.933561],
          [47.922353, 34.934535],
          [47.921937, 34.93523],
          [47.921937, 34.935789],
          [47.92166, 34.936069],
          [47.92138, 34.937169],
          [47.919994, 34.940787],
          [47.919714, 34.943006],
          [47.919994, 34.944946],
          [47.92277, 34.951892],
          [47.95277, 34.970511],
          [47.955826, 34.970511],
          [47.956106, 34.970232],
          [47.957216, 34.970232],
          [47.97194, 34.964675],
          [47.999987, 34.961272],
          [48.005552, 34.970782],
          [48.018052, 34.978287],
          [48.032219, 34.983285],
          [48.032775, 34.983565],
          [48.033332, 34.983565],
          [48.040832, 34.986063],
          [48.043052, 34.986063],
          [48.043609, 34.985784],
          [48.044165, 34.985784],
          [48.044442, 34.985504],
          [48.045275, 34.985504],
          [48.047775, 34.984395],
          [48.049165, 34.983285],
          [48.058055, 34.97495],
          [48.058055, 34.974399],
          [48.063608, 34.968563],
          [48.073055, 34.961896],
          [48.074165, 34.961896],
          [48.074445, 34.961617],
          [48.079722, 34.961617],
          [48.079998, 34.961337],
          [48.081388, 34.961337],
          [48.081665, 34.961617],
          [48.085831, 34.961617],
          [48.086111, 34.961897],
          [48.087498, 34.962176],
          [48.088612, 34.962176],
          [48.103335, 34.971062],
          [48.105835, 34.97218],
          [48.109168, 34.97301],
          [48.110558, 34.97301],
          [48.111668, 34.97329],
          [48.114445, 34.97329],
          [48.114725, 34.97301],
          [48.116111, 34.97301],
          [48.121391, 34.971621],
          [48.128614, 34.970512],
          [48.129167, 34.970232],
          [48.131114, 34.970232],
          [48.135281, 34.968843],
          [48.136391, 34.968843],
          [48.137224, 34.968563],
          [48.138614, 34.968563],
          [48.139167, 34.968563],
          [48.143891, 34.970782],
          [48.144167, 34.971062],
          [48.150281, 34.977178],
          [48.151391, 34.981616],
          [48.156391, 34.988842],
          [48.17278, 34.996068],
          [48.173337, 34.996068],
          [48.175837, 34.998846],
          [48.175837, 35.002175],
          [48.17667, 35.008011],
          [48.177227, 35.011619],
          [48.17806, 35.014677],
          [48.18556, 35.056072],
          [48.186116, 35.058291],
          [48.186393, 35.058842],
          [48.187227, 35.06079],
          [48.187503, 35.065508],
          [48.187503, 35.066897],
          [48.190836, 35.079399],
          [48.192226, 35.09274],
          [48.19195, 35.09329],
          [48.191669, 35.095238],
          [48.191393, 35.096068],
          [48.188893, 35.101065],
          [48.188616, 35.102175],
          [48.185003, 35.106342],
          [48.18306, 35.106901],
          [48.182226, 35.106901],
          [48.179447, 35.106901],
          [48.17278, 35.106901],
          [48.172227, 35.107181],
          [48.16778, 35.107181],
          [48.167227, 35.10746],
          [48.166114, 35.10746],
          [48.160557, 35.10912],
          [48.15528, 35.113296],
          [48.154723, 35.114406],
          [48.153614, 35.115515],
          [48.152503, 35.118013],
          [48.15639, 35.126348],
          [48.15778, 35.128846],
          [48.15917, 35.131345],
          [48.159723, 35.133564],
          [48.178336, 35.15329],
          [48.180836, 35.159955],
          [48.181393, 35.161065],
          [48.181253, 35.163707],
          [48.181113, 35.165232],
          [48.17778, 35.169958],
          [48.177537, 35.170798],
          [48.175836, 35.172736],
          [48.16139, 35.184407],
          [48.154446, 35.203853],
          [48.150614, 35.209873],
          [48.149058, 35.21165],
          [48.139723, 35.22218],
          [48.126947, 35.228574],
          [48.1225, 35.228574],
          [48.121667, 35.228854],
          [48.119723, 35.228854],
          [48.109444, 35.226906],
          [48.10889, 35.226626],
          [48.105001, 35.226626],
          [48.098887, 35.227176],
          [48.098054, 35.227176],
          [48.09722, 35.227176],
          [48.096387, 35.227735],
          [48.09611, 35.228015],
          [48.095834, 35.228294],
          [48.096387, 35.230513],
          [48.096387, 35.230793],
          [48.098054, 35.242463],
          [48.09222, 35.25913],
          [48.091944, 35.261069],
          [48.08972, 35.269403],
          [48.089444, 35.269682],
          [48.087497, 35.27274],
          [48.07722, 35.28496],
          [48.065932, 35.299071],
          [48.063887, 35.301626],
          [48.056664, 35.316903],
          [48.055274, 35.319961],
          [48.055274, 35.32052],
          [48.054721, 35.322188],
          [48.040551, 35.347187],
          [48.034441, 35.358017],
          [48.034164, 35.358856],
          [48.033884, 35.359686],
          [48.034164, 35.361634],
          [48.034164, 35.363022],
          [48.026661, 35.370796],
          [48.023608, 35.372184],
          [48.023328, 35.372464],
          [48.022494, 35.372464],
          [48.021941, 35.372744],
          [48.020828, 35.372744],
          [48.020274, 35.373023],
          [48.013884, 35.373023],
          [48.013607, 35.372744],
          [48.011384, 35.372744],
          [48.011104, 35.372464],
          [48.009438, 35.372464],
          [48.006384, 35.371914],
          [48.006104, 35.371625],
          [48.003605, 35.371625],
          [48.002218, 35.371355],
          [47.999984, 35.36923],
          [47.997495, 35.368018],
          [47.985548, 35.370516],
          [47.985271, 35.370796],
          [47.984715, 35.370796],
          [47.984438, 35.371075],
          [47.983048, 35.371075],
          [47.980548, 35.371625],
          [47.980271, 35.371914],
          [47.978739, 35.372054],
          [47.978328, 35.372184],
          [47.963325, 35.375792],
          [47.962772, 35.376071],
          [47.962215, 35.376071],
          [47.960548, 35.37663],
          [47.954435, 35.379408],
          [47.944715, 35.389679],
          [47.941382, 35.394963],
          [47.940549, 35.396073],
          [47.939992, 35.397182],
          [47.936935, 35.405514],
          [47.936658, 35.405794],
          [47.936102, 35.407462],
          [47.935545, 35.40913],
          [47.935545, 35.40968],
          [47.933602, 35.414965],
          [47.927492, 35.431909],
          [47.917769, 35.445236],
          [47.917482, 35.445525],
          [47.91728, 35.445769],
          [47.916102, 35.447193],
          [47.915822, 35.447743],
          [47.915268, 35.448023],
          [47.914988, 35.448573],
          [47.908878, 35.453577],
          [47.905822, 35.457464],
          [47.905269, 35.457743],
          [47.902488, 35.462748],
          [47.901935, 35.463848],
          [47.901935, 35.464407],
          [47.901655, 35.464686],
          [47.900822, 35.467184],
          [47.896655, 35.479132],
          [47.896379, 35.479961],
          [47.896099, 35.481079],
          [47.895822, 35.482739],
          [47.895545, 35.483577],
          [47.895265, 35.485245],
          [47.894989, 35.486914],
          [47.894712, 35.488582],
          [47.894432, 35.490241],
          [47.894155, 35.491629],
          [47.893879, 35.493298],
          [47.887489, 35.506354],
          [47.887212, 35.506634],
          [47.886098, 35.508302],
          [47.886099, 35.508861],
          [47.885545, 35.51024],
          [47.885545, 35.516913],
          [47.885265, 35.517183],
          [47.871099, 35.539409],
          [47.870542, 35.539968],
          [47.869155, 35.542745],
          [47.868042, 35.544413],
          [47.867489, 35.545522],
          [47.866099, 35.549688],
          [47.865822, 35.550526],
          [47.865542, 35.552465],
          [47.866099, 35.558299],
          [47.879708, 35.576079],
          [47.881141, 35.576979],
          [47.882212, 35.578017],
          [47.883045, 35.579135],
          [47.889155, 35.58441],
          [47.892488, 35.585528],
          [47.894155, 35.585528],
          [47.903602, 35.579965],
          [47.913321, 35.576917],
          [47.913878, 35.576629],
          [47.914711, 35.576629],
          [47.916935, 35.576358],
          [47.917211, 35.576079],
          [47.919158, 35.576079],
          [47.919711, 35.575799],
          [47.920825, 35.575799],
          [47.921101, 35.57552],
          [47.922491, 35.57552],
          [47.935825, 35.572472],
          [47.935825, 35.572193],
          [47.956938, 35.571084],
          [47.961104, 35.571355],
          [47.961658, 35.571634],
          [47.963324, 35.571634],
          [47.98416, 35.579695],
          [47.997738, 35.599653],
          [48.002689, 35.608666],
          [48.00555, 35.613586],
          [48.01055, 35.614974],
          [48.022493, 35.621637],
          [48.030827, 35.62747],
          [48.032496, 35.629688],
          [48.033883, 35.629968],
          [48.03944, 35.633863],
          [48.046383, 35.637189],
          [48.049163, 35.639137],
          [48.050347, 35.639642],
          [48.052219, 35.640525],
          [48.055553, 35.641075],
          [48.092499, 35.651912],
          [48.123332, 35.673306],
          [48.146668, 35.673586],
          [48.167778, 35.673306],
          [48.186391, 35.679698],
          [48.187225, 35.680248],
          [48.204724, 35.685252],
          [48.205281, 35.685531],
          [48.206114, 35.685531],
          [48.206391, 35.685801],
          [48.211114, 35.687469],
          [48.24917, 35.719419],
          [48.250004, 35.719419],
          [48.251117, 35.719978],
          [48.25167, 35.720248],
          [48.24917, 35.723584],
          [48.238337, 35.727749],
          [48.237781, 35.728028],
          [48.23667, 35.728028],
          [48.236114, 35.728308],
          [48.235004, 35.728308],
          [48.229171, 35.730255],
          [48.227504, 35.730255],
          [48.226394, 35.730525],
          [48.220004, 35.730525],
          [48.213614, 35.730525],
          [48.210557, 35.730255],
          [48.198891, 35.728587],
          [48.197781, 35.728308],
          [48.195001, 35.728308],
          [48.194448, 35.728587],
          [48.193614, 35.728587],
          [48.193058, 35.729146],
          [48.192501, 35.729146],
          [48.190558, 35.731084],
          [48.184168, 35.742199],
          [48.183615, 35.744976],
          [48.183891, 35.761085],
          [48.184724, 35.767476],
          [48.185001, 35.771362],
          [48.185001, 35.773309],
          [48.184724, 35.773859],
          [48.184448, 35.775256],
          [48.173334, 35.788309],
          [48.171668, 35.789138],
          [48.152501, 35.793303],
          [48.151388, 35.793591],
          [48.143888, 35.793591],
          [48.142778, 35.793303],
          [48.141388, 35.793303],
          [48.140278, 35.793591],
          [48.138888, 35.793303],
          [48.135555, 35.793303],
          [48.133331, 35.793032],
          [48.132221, 35.792753],
          [48.130832, 35.792753],
          [48.129998, 35.792473],
          [48.124722, 35.791923],
          [48.124442, 35.791635],
          [48.120275, 35.791635],
          [48.108889, 35.79497],
          [48.103332, 35.795809],
          [48.100832, 35.795809],
          [48.097218, 35.796367],
          [48.095275, 35.796367],
          [48.091665, 35.797197],
          [48.090552, 35.797197],
          [48.088609, 35.797756],
          [48.087495, 35.797756],
          [48.085829, 35.798305],
          [48.084442, 35.798306],
          [48.061386, 35.803858],
          [48.053053, 35.806635],
          [48.051662, 35.807473],
          [48.051106, 35.807473],
          [48.046663, 35.812196],
          [48.044996, 35.814702],
          [48.039996, 35.828584],
          [48.037216, 35.841086],
          [48.036939, 35.841366],
          [48.036939, 35.841915],
          [48.028882, 35.859141],
          [48.028606, 35.861088],
          [48.026939, 35.869137],
          [48.026659, 35.872481],
          [48.025826, 35.878313],
          [48.024439, 35.882756],
          [48.023883, 35.883586],
          [48.023606, 35.884974],
          [48.023049, 35.885812],
          [48.022492, 35.888029],
          [48.021659, 35.888867],
          [48.018049, 35.898593],
          [48.016939, 35.905533],
          [48.016659, 35.906083],
          [48.008602, 35.920531],
          [48.007493, 35.921649],
          [47.999992, 35.933032],
          [47.996175, 35.930935],
          [47.994963, 35.930157],
          [47.993602, 35.929977],
          [47.989712, 35.931365],
          [47.986103, 35.931365],
          [47.985546, 35.931644],
          [47.982213, 35.931644],
          [47.98166, 35.931365],
          [47.980546, 35.931365],
          [47.980546, 35.931085],
          [47.979713, 35.931085],
          [47.977769, 35.930256],
          [47.973323, 35.928868],
          [47.944157, 35.906641],
          [47.943047, 35.905812],
          [47.933043, 35.897475],
          [47.928324, 35.894419],
          [47.909711, 35.889147],
          [47.9086, 35.889417],
          [47.907211, 35.889417],
          [47.889988, 35.894978],
          [47.889154, 35.894978],
          [47.872207, 35.904424],
          [47.8719, 35.904725],
          [47.870821, 35.905812],
          [47.870541, 35.906082],
          [47.868321, 35.906921],
          [47.867764, 35.906921],
          [47.864708, 35.910256],
          [47.863874, 35.910805],
          [47.860818, 35.915808],
          [47.859984, 35.917475],
          [47.859984, 35.918034],
          [47.856098, 35.931923],
          [47.856374, 35.93414],
          [47.862207, 35.94136],
          [47.868321, 35.943586],
          [47.869154, 35.943586],
          [47.871931, 35.944145],
          [47.884431, 35.944694],
          [47.884711, 35.944974],
          [47.88582, 35.944974],
          [47.895264, 35.948029],
          [47.899434, 35.949976],
          [47.914153, 35.954419],
          [47.914433, 35.954699],
          [47.91582, 35.954699],
          [47.9161, 35.954978],
          [47.917767, 35.954978],
          [47.91999, 35.955537],
          [47.921377, 35.955537],
          [47.92499, 35.955807],
          [47.925823, 35.956087],
          [47.926657, 35.955807],
          [47.92749, 35.956087],
          [47.9311, 35.956087],
          [47.931657, 35.956366],
          [47.934157, 35.956366],
          [47.934433, 35.956646],
          [47.935266, 35.956646],
          [47.935823, 35.956925],
          [47.936933, 35.956925],
          [47.938046, 35.957475],
          [47.939157, 35.957475],
          [47.95749, 35.963306],
          [47.967213, 35.965532],
          [47.967489, 35.965812],
          [47.968323, 35.965812],
          [47.96888, 35.966091],
          [47.969989, 35.966091],
          [47.978603, 35.968588],
          [47.980269, 35.969426],
          [47.982213, 35.971084],
          [47.982493, 35.97331],
          [47.982213, 35.97359],
          [47.977769, 35.978312],
          [47.969436, 35.980809],
          [47.968603, 35.981088],
          [47.968323, 35.981367],
          [47.966379, 35.981367],
          [47.965823, 35.981647],
          [47.963046, 35.981647],
          [47.96277, 35.981926],
          [47.959989, 35.981926],
          [47.955266, 35.982476],
          [47.954713, 35.982755],
          [47.953323, 35.982755],
          [47.952766, 35.983035],
          [47.951933, 35.983035],
          [47.948323, 35.983593],
          [47.9461, 35.983864],
          [47.944156, 35.984422],
          [47.942766, 35.984423],
          [47.939157, 35.985252],
          [47.93888, 35.985531],
          [47.9361, 35.985531],
          [47.935543, 35.98581],
          [47.9336, 35.98581],
          [47.932767, 35.98609],
          [47.931376, 35.98609],
          [47.90971, 35.993309],
          [47.908997, 35.993658],
          [47.90832, 35.993868],
          [47.908043, 35.994147],
          [47.90402, 35.997655],
          [47.9036, 35.998311],
          [47.903044, 36.002204],
          [47.9236, 36.019976],
          [47.925266, 36.021085],
          [47.950266, 36.044696],
          [47.954156, 36.049418],
          [47.95499, 36.049977],
          [47.959156, 36.057763],
          [47.962489, 36.063035],
          [47.966656, 36.074426],
          [47.967769, 36.077201],
          [47.967769, 36.07776],
          [47.969156, 36.08332],
          [47.969712, 36.084149],
          [47.971936, 36.093314],
          [47.971936, 36.094143],
          [47.972769, 36.096369],
          [47.974436, 36.104425],
          [47.975269, 36.106921],
          [47.991935, 36.133594],
          [47.993882, 36.13637],
          [47.994715, 36.138875],
          [47.994992, 36.139704],
          [47.994715, 36.140821],
          [47.994992, 36.143596],
          [47.995269, 36.144705],
          [47.995269, 36.145263],
          [47.995269, 36.14693],
          [47.995825, 36.149147],
          [47.993882, 36.151373],
          [47.990825, 36.15304],
          [47.990602, 36.153262],
          [47.976935, 36.163871],
          [47.976102, 36.165817],
          [47.976102, 36.166376],
          [47.975826, 36.166646],
          [47.975269, 36.168313],
          [47.974989, 36.169421],
          [47.974712, 36.171097],
          [47.974435, 36.173872],
          [47.974435, 36.176098],
          [47.974435, 36.179153],
          [47.973879, 36.181649],
          [47.973879, 36.185262],
          [47.973602, 36.185541],
          [47.973322, 36.188596],
          [47.970822, 36.198038],
          [47.964712, 36.206932],
          [47.949989, 36.216374],
          [47.946932, 36.221933],
          [47.946932, 36.222213],
          [47.946932, 36.223042],
          [47.947212, 36.225538],
          [47.952765, 36.234151],
          [47.954155, 36.236097],
          [47.954712, 36.24026],
          [47.953879, 36.242206],
          [47.945546, 36.248594],
          [47.941655, 36.256928],
          [47.941655, 36.261099],
          [47.941932, 36.263595],
          [47.942212, 36.264983],
          [47.943046, 36.2661],
          [47.943322, 36.266649],
          [47.945822, 36.268595],
          [47.954432, 36.273596],
          [47.956102, 36.275542],
          [47.956379, 36.281371],
          [47.956102, 36.28165],
          [47.941379, 36.310264],
          [47.941098, 36.310543],
          [47.938599, 36.31248],
          [47.934708, 36.314705],
          [47.934155, 36.315264],
          [47.920266, 36.320264],
          [47.919709, 36.320264],
          [47.919432, 36.320543],
          [47.918599, 36.320543],
          [47.899709, 36.330813],
          [47.899432, 36.331102],
          [47.898596, 36.331102],
          [47.898042, 36.331381],
          [47.893319, 36.331381],
          [47.892762, 36.331381],
          [47.891652, 36.331651],
          [47.890819, 36.331651],
          [47.888596, 36.331651],
          [47.887486, 36.331381],
          [47.886376, 36.331381],
          [47.884986, 36.331381],
          [47.884986, 36.331102],
          [47.883596, 36.331102],
          [47.875819, 36.329156],
          [47.874706, 36.329156],
          [47.874153, 36.328876],
          [47.87082, 36.328876],
          [47.867486, 36.329426],
          [47.866929, 36.329705],
          [47.866096, 36.329705],
          [47.848316, 36.338876],
          [47.843596, 36.339984],
          [47.842206, 36.339984],
          [47.841093, 36.340264],
          [47.84026, 36.340264],
          [47.839983, 36.340543],
          [47.838593, 36.340543],
          [47.838317, 36.340822],
          [47.83554, 36.340822],
          [47.83526, 36.341101],
          [47.833873, 36.341101],
          [47.833316, 36.341381],
          [47.832207, 36.341381],
          [47.831926, 36.34166],
          [47.829706, 36.34166],
          [47.828317, 36.34193],
          [47.82776, 36.342209],
          [47.826926, 36.342209],
          [47.81665, 36.34666],
          [47.815537, 36.347209],
          [47.80026, 36.362767],
          [47.79637, 36.365542],
          [47.791927, 36.367488],
          [47.78776, 36.369434],
          [47.782203, 36.371938],
          [47.77109, 36.373595],
          [47.76859, 36.374154],
          [47.767481, 36.374154],
          [47.766647, 36.374433],
          [47.765257, 36.374433],
          [47.764424, 36.374712],
          [47.75998, 36.374712],
          [47.753314, 36.375262],
          [47.752481, 36.375541],
          [47.751367, 36.375541],
          [47.750534, 36.37582],
          [47.748867, 36.37582],
          [47.748314, 36.3761],
          [47.746367, 36.3761],
          [47.728311, 36.379433],
          [47.726644, 36.379433],
          [47.725534, 36.379712],
          [47.724701, 36.379712],
          [47.724144, 36.379991],
          [47.715254, 36.379991],
          [47.714701, 36.380271],
          [47.714144, 36.380271],
          [47.711368, 36.380271],
          [47.711088, 36.380271],
          [47.709978, 36.380271],
          [47.709421, 36.379991],
          [47.706921, 36.379991],
          [47.700254, 36.378883],
          [47.699145, 36.378325],
          [47.694421, 36.377766],
          [47.693588, 36.377487],
          [47.676921, 36.378036],
          [47.675808, 36.378325],
          [47.674141, 36.378325],
          [47.671085, 36.378883],
          [47.669975, 36.378883],
          [47.665531, 36.379712],
          [47.659699, 36.380541],
          [47.641362, 36.384982],
          [47.640809, 36.385261],
          [47.639695, 36.385261],
          [47.638862, 36.38554],
          [47.637752, 36.38554],
          [47.608862, 36.394152],
          [47.608582, 36.394431],
          [47.608029, 36.394431],
          [47.607195, 36.394711],
          [47.606916, 36.39499],
          [47.606082, 36.39499],
          [47.568583, 36.416654],
          [47.565249, 36.417213],
          [47.563583, 36.417483],
          [47.563026, 36.417762],
          [47.561636, 36.417762],
          [47.561083, 36.418042],
          [47.559416, 36.418042],
          [47.559136, 36.418321],
          [47.557469, 36.418321],
          [47.556912, 36.4186],
          [47.555526, 36.4186],
          [47.553579, 36.419159],
          [47.552469, 36.419159],
          [47.551913, 36.419438],
          [47.549413, 36.419438],
          [47.548859, 36.419717],
          [47.546913, 36.419717],
          [47.528303, 36.424987],
          [47.52719, 36.424987],
          [47.525246, 36.425545],
          [47.524413, 36.425545],
          [47.523856, 36.425824],
          [47.521913, 36.425824],
          [47.521633, 36.426104],
          [47.5183, 36.426104],
          [47.517746, 36.426374],
          [47.516913, 36.426104],
          [47.510523, 36.426104],
          [47.509967, 36.426104],
          [47.508023, 36.426104],
          [47.507747, 36.425824],
          [47.49691, 36.425824],
          [47.496076, 36.426103],
          [47.493023, 36.426104],
          [47.492467, 36.426374],
          [47.490524, 36.426374],
          [47.478577, 36.42804],
          [47.47802, 36.428319],
          [47.47552, 36.428319],
          [47.47441, 36.428599],
          [47.467467, 36.428599],
          [47.46191, 36.42777],
          [47.461354, 36.427491],
          [47.460244, 36.427491],
          [47.439687, 36.414708],
          [47.437187, 36.413042],
          [47.434964, 36.412213],
          [47.434407, 36.411655],
          [47.426631, 36.409709],
          [47.426074, 36.409709],
          [47.425518, 36.40943],
          [47.416631, 36.40943],
          [47.415798, 36.409709],
          [47.414408, 36.409709],
          [47.413298, 36.409988],
          [47.412184, 36.409988],
          [47.403294, 36.413042],
          [47.381628, 36.432211],
          [47.371904, 36.437768],
          [47.371351, 36.438048],
          [47.371351, 36.437768],
          [47.371351, 36.434426],
          [47.371905, 36.430265],
          [47.372185, 36.428598],
          [47.372738, 36.424707],
          [47.373018, 36.42304],
          [47.373295, 36.422212],
          [47.373018, 36.410546],
          [47.367462, 36.394152],
          [47.366628, 36.38971],
          [47.366474, 36.389102],
          [47.366239, 36.388351],
          [47.366213, 36.388268],
          [47.365795, 36.386936],
          [47.366072, 36.386377],
          [47.374685, 36.360541],
          [47.391075, 36.338596],
          [47.391908, 36.33638],
          [47.392185, 36.334434],
          [47.390241, 36.325542],
          [47.388019, 36.322209],
          [47.378295, 36.314983],
          [47.375795, 36.313875],
          [47.375518, 36.313596],
          [47.374911, 36.313195],
          [47.374685, 36.313046],
          [47.374129, 36.312758],
          [47.373295, 36.311929],
          [47.371905, 36.310262],
          [47.371352, 36.309983],
          [47.346349, 36.277757],
          [47.323016, 36.256927],
          [47.318849, 36.254431],
          [47.317736, 36.253872],
          [47.312736, 36.251376],
          [47.311902, 36.251376],
          [47.298569, 36.247764],
          [47.29746, 36.247763],
          [47.296903, 36.247484],
          [47.296069, 36.247484],
          [47.294956, 36.247205],
          [47.289403, 36.246646],
          [47.285236, 36.246646],
          [47.284403, 36.246367],
          [47.267734, 36.246097],
          [47.251067, 36.246097],
          [47.249957, 36.245817],
          [47.24829, 36.245817],
          [47.242457, 36.244988],
          [47.239677, 36.244988],
          [47.238844, 36.244709],
          [47.236067, 36.244709],
          [47.23551, 36.244709],
          [47.232454, 36.244709],
          [47.231344, 36.24443],
          [47.229401, 36.24443],
          [47.227733, 36.24415],
          [47.225787, 36.24415],
          [47.222454, 36.243592],
          [47.220234, 36.243592],
          [47.218844, 36.243312],
          [47.215231, 36.243312],
          [47.213844, 36.243033],
          [47.189955, 36.241654],
          [47.160508, 36.243312],
          [47.131062, 36.250538],
          [47.123281, 36.253872],
          [47.121895, 36.25443],
          [47.121615, 36.254701],
          [47.118838, 36.255538],
          [47.117728, 36.255538],
          [47.116895, 36.255818],
          [47.111338, 36.255818],
          [47.110782, 36.255538],
          [47.109671, 36.255538],
          [47.108282, 36.25498],
          [47.107171, 36.25498],
          [47.097725, 36.253043],
          [47.096059, 36.253043],
          [47.095782, 36.252763],
          [47.093838, 36.252763],
          [47.093006, 36.252484],
          [47.090225, 36.252484],
          [47.089948, 36.252205],
          [47.086892, 36.252205],
          [47.084948, 36.251646],
          [47.081058, 36.250817],
          [47.080502, 36.250817],
          [47.080502, 36.251097],
          [47.079949, 36.251646],
          [47.079669, 36.253592],
          [47.073282, 36.264701],
          [47.068835, 36.275819],
          [47.065779, 36.279711],
          [47.045502, 36.295261],
          [47.043556, 36.296369],
          [47.042722, 36.297207],
          [47.042169, 36.297207],
          [47.042169, 36.297487],
          [47.031336, 36.30582],
          [47.031056, 36.306099],
          [47.019665, 36.31582],
          [47.019665, 36.316649],
          [47.017999, 36.319154],
          [47.014946, 36.319982],
          [47.013556, 36.319982],
          [47.007999, 36.321658],
          [46.996889, 36.321658],
          [46.995776, 36.32137],
          [46.992999, 36.32137],
          [46.991886, 36.32109],
          [46.97661, 36.320541],
          [46.974386, 36.319982],
          [46.972996, 36.319982],
          [46.971886, 36.319703],
          [46.968553, 36.319433],
          [46.964663, 36.319433],
          [46.96383, 36.319153],
          [46.96022, 36.319153],
          [46.959943, 36.318874],
          [46.95883, 36.318874],
          [46.955219, 36.318874],
          [46.949663, 36.318874],
          [46.948553, 36.319153],
          [46.947163, 36.319153],
          [46.946054, 36.319433],
          [46.943273, 36.319703],
          [46.94272, 36.319433],
          [46.941886, 36.319703],
          [46.940496, 36.319703],
          [46.939664, 36.319982],
          [46.938273, 36.319982],
          [46.93744, 36.320261],
          [46.935217, 36.320261],
          [46.93494, 36.320541],
          [46.932163, 36.320541],
          [46.931606, 36.32082],
          [46.92355, 36.322207],
          [46.92244, 36.322766],
          [46.921607, 36.322766],
          [46.91494, 36.326378],
          [46.913827, 36.327487],
          [46.912993, 36.327766],
          [46.909661, 36.330811],
          [46.909383, 36.331649],
          [46.907161, 36.334432],
          [46.906607, 36.336658],
          [46.906327, 36.338045],
          [46.90605, 36.339153],
          [46.905774, 36.34082],
          [46.905493, 36.342766],
          [46.905217, 36.344153],
          [46.90494, 36.345261],
          [46.90466, 36.346657],
          [46.904384, 36.346928],
          [46.90494, 36.348594],
          [46.90494, 36.35082],
          [46.905217, 36.351648],
          [46.906607, 36.353594],
          [46.90716, 36.359153],
          [46.906884, 36.361098],
          [46.905217, 36.368594],
          [46.89716, 36.383043],
          [46.886051, 36.391376],
          [46.88355, 36.393042],
          [46.853824, 36.412212],
          [46.85299, 36.412212],
          [46.849657, 36.409158],
          [46.847157, 36.408041],
          [46.846324, 36.408041],
          [46.845214, 36.407491],
          [46.840767, 36.408041],
          [46.839101, 36.409158],
          [46.838824, 36.409428],
          [46.837157, 36.414148],
          [46.836324, 36.414986],
          [46.835767, 36.414986],
          [46.835491, 36.415265],
          [46.833824, 36.415265],
          [46.833548, 36.415544],
          [46.831601, 36.415544],
          [46.830214, 36.414707],
          [46.828267, 36.41304],
          [46.827714, 36.411932],
          [46.825491, 36.410545],
          [46.823548, 36.409707],
          [46.822434, 36.409707],
          [46.821324, 36.409158],
          [46.820491, 36.409158],
          [46.819101, 36.408599],
          [46.816325, 36.408599],
          [46.815768, 36.40832],
          [46.812157, 36.40832],
          [46.811601, 36.409158],
          [46.812991, 36.411932],
          [46.812991, 36.413599],
          [46.811878, 36.414427],
          [46.811044, 36.415265],
          [46.810768, 36.415815],
          [46.811044, 36.416652],
          [46.812435, 36.419715],
          [46.811325, 36.421102],
          [46.810211, 36.421102],
          [46.809935, 36.421382],
          [46.808825, 36.421652],
          [46.808267, 36.421652],
          [46.807991, 36.421931],
          [46.807435, 36.421931],
          [46.802988, 36.423877],
          [46.802155, 36.423877],
          [46.801045, 36.424156],
          [46.798268, 36.42249],
          [46.796878, 36.42249],
          [46.796321, 36.42221],
          [46.795768, 36.42249],
          [46.794934, 36.42249],
          [46.793268, 36.423318],
          [46.788821, 36.42693],
          [46.786878, 36.42693],
          [46.786601, 36.42721],
          [46.786322, 36.42693],
          [46.785212, 36.42693],
          [46.784654, 36.426651],
          [46.783822, 36.426651],
          [46.783545, 36.426372],
          [46.780211, 36.425543],
          [46.778821, 36.425543],
          [46.775211, 36.424435],
          [46.773821, 36.424435],
          [46.772155, 36.423877],
          [46.770211, 36.423318],
          [46.768545, 36.419985],
          [46.768821, 36.419715],
          [46.769655, 36.419436],
          [46.772988, 36.416094],
          [46.773265, 36.415815],
          [46.771878, 36.41304],
          [46.770765, 36.412482],
          [46.767432, 36.412211],
          [46.767155, 36.412482],
          [46.766042, 36.412482],
          [46.765765, 36.412761],
          [46.765208, 36.412761],
          [46.760765, 36.414707],
          [46.759375, 36.414707],
          [46.758822, 36.414427],
          [46.757988, 36.414427],
          [46.756875, 36.414148],
          [46.756041, 36.411653],
          [46.757988, 36.408878],
          [46.757709, 36.406653],
          [46.756041, 36.405536],
          [46.755209, 36.405536],
          [46.754932, 36.405257],
          [46.753265, 36.405825],
          [46.751875, 36.407212],
          [46.749098, 36.41304],
          [46.748266, 36.413319],
          [46.747708, 36.41304],
          [46.746598, 36.41304],
          [46.746598, 36.412761],
          [46.745765, 36.411373],
          [46.745208, 36.409707],
          [46.744652, 36.409158],
          [46.741042, 36.409707],
          [46.740486, 36.410266],
          [46.735485, 36.414148],
          [46.731875, 36.415265],
          [46.730765, 36.415265],
          [46.729652, 36.415544],
          [46.729375, 36.415265],
          [46.728542, 36.415265],
          [46.726595, 36.414706],
          [46.726042, 36.414706],
          [46.725485, 36.413878],
          [46.719376, 36.409428],
          [46.718262, 36.409428],
          [46.717709, 36.409707],
          [46.716042, 36.409707],
          [46.715762, 36.409986],
          [46.713819, 36.409986],
          [46.712152, 36.409428],
          [46.703539, 36.40332],
          [46.700206, 36.40332],
          [46.699929, 36.403599],
          [46.699096, 36.40387],
          [46.697706, 36.406104],
          [46.696319, 36.406653],
          [46.694929, 36.406104],
          [46.694653, 36.405536],
          [46.691873, 36.402762],
          [46.690206, 36.400546],
          [46.689096, 36.399987],
          [46.688539, 36.400266],
          [46.687706, 36.400266],
          [46.685483, 36.399149],
          [46.684649, 36.399149],
          [46.683539, 36.3986],
          [46.682429, 36.3986],
          [46.681873, 36.398321],
          [46.679096, 36.398321],
          [46.675483, 36.396375],
          [46.674649, 36.396375],
          [46.674373, 36.396095],
          [46.671872, 36.396095],
          [46.666873, 36.398041],
          [46.663539, 36.401654],
          [46.662706, 36.401933],
          [46.658816, 36.401933],
          [46.657707, 36.401374],
          [46.65187, 36.397212],
          [46.649649, 36.397212],
          [46.647427, 36.397762],
          [46.64687, 36.397762],
          [46.645203, 36.39832],
          [46.642983, 36.39832],
          [46.642703, 36.398041],
          [46.641593, 36.398041],
          [46.636593, 36.395825],
          [46.634094, 36.395825],
          [46.628536, 36.398041],
          [46.626036, 36.398041],
          [46.62548, 36.397762],
          [46.622703, 36.397762],
          [46.622146, 36.397483],
          [46.615203, 36.395267],
          [46.612703, 36.395267],
          [46.608257, 36.3986],
          [46.60659, 36.3986],
          [46.606037, 36.39832],
          [46.604923, 36.39832],
          [46.60437, 36.398041],
          [46.60298, 36.398041],
          [46.602423, 36.398041],
          [46.599923, 36.399149],
          [46.59798, 36.399149],
          [46.593814, 36.396654],
          [46.591314, 36.395825],
          [46.580757, 36.396374],
          [46.579367, 36.396095],
          [46.578534, 36.396374],
          [46.572977, 36.396375],
          [46.572144, 36.396654],
          [46.569644, 36.396654],
          [46.569091, 36.396933],
          [46.568257, 36.396933],
          [46.567701, 36.397212],
          [46.552701, 36.400545],
          [46.549921, 36.401095],
          [46.54881, 36.401095],
          [46.548254, 36.401374],
          [46.547421, 36.401374],
          [46.546867, 36.401653],
          [46.546034, 36.401653],
          [46.545477, 36.401932],
          [46.534921, 36.403869],
          [46.532698, 36.403869],
          [46.532144, 36.404148],
          [46.531031, 36.404148],
          [46.530478, 36.404428],
          [46.530197, 36.404707],
          [46.528531, 36.405964],
          [46.527978, 36.406103],
          [46.524088, 36.408878],
          [46.523531, 36.409157],
          [46.520198, 36.410823],
          [46.517698, 36.411373],
          [46.516031, 36.411373],
          [46.515754, 36.411652],
          [46.514921, 36.411652],
          [46.514364, 36.411931],
          [46.513532, 36.411931],
          [46.512975, 36.412211],
          [46.508808, 36.413598],
          [46.505474, 36.416093],
          [46.499641, 36.418597],
          [46.496308, 36.418318],
          [46.486308, 36.418318],
          [46.484642, 36.418039],
          [46.481585, 36.418039],
          [46.467418, 36.416652],
          [46.462695, 36.416651],
          [46.462138, 36.416372],
          [46.459638, 36.416372],
          [46.459085, 36.416093],
          [46.457695, 36.416093],
          [46.457418, 36.415814],
          [46.451305, 36.413877],
          [46.439915, 36.405256],
          [46.437138, 36.402202],
          [46.427416, 36.39387],
          [46.425749, 36.392203],
          [46.416859, 36.385816],
          [46.413249, 36.384979],
          [46.383526, 36.381934],
          [46.380469, 36.381934],
          [46.378803, 36.381654],
          [46.377693, 36.381934],
          [46.376026, 36.381654],
          [46.374079, 36.381934],
          [46.360746, 36.382762],
          [46.359913, 36.382483],
          [46.358523, 36.382483],
          [46.357413, 36.382204],
          [46.354913, 36.381934],
          [46.353803, 36.381375],
          [46.352413, 36.381375],
          [46.337954, 36.378153],
          [46.337413, 36.378033],
          [46.329357, 36.377483],
          [46.322134, 36.377483],
          [46.32019, 36.377763],
          [46.299634, 36.380816],
          [46.295744, 36.381933],
          [46.28991, 36.38387],
          [46.280188, 36.388041],
          [46.263556, 36.399368],
          [46.247964, 36.409985],
          [46.242684, 36.417209],
          [46.241297, 36.419984],
          [46.236851, 36.426929],
          [46.232407, 36.43137],
          [46.212961, 36.447485],
          [46.199628, 36.454429],
          [46.195461, 36.455817],
          [46.191848, 36.456934],
          [46.190653, 36.457376],
          [46.182681, 36.459708],
          [46.181294, 36.459708],
          [46.177125, 36.460816],
          [46.175458, 36.460816],
          [46.173791, 36.461374],
          [46.172682, 36.461374],
          [46.163515, 36.462482],
          [46.162958, 36.462482],
          [46.155458, 36.46332],
          [46.145459, 36.46332],
          [46.126846, 36.460816],
          [46.117679, 36.460104],
          [46.116845, 36.459987],
          [46.116012, 36.459708],
          [46.106569, 36.45832],
          [46.104065, 36.457483],
          [46.097679, 36.456375],
          [46.092399, 36.454708],
          [46.072956, 36.449709],
          [46.064757, 36.446396],
          [46.05851, 36.443872],
          [46.04962, 36.440269],
          [46.044343, 36.438323],
          [46.042396, 36.437206],
          [46.02823, 36.431648],
          [46.015453, 36.427766],
          [46.003231, 36.42637],
          [45.994341, 36.425262],
          [45.992397, 36.424982],
          [45.981008, 36.419713],
          [45.980727, 36.419154],
          [45.978784, 36.418037],
          [45.954746, 36.396761],
          [45.952114, 36.389427],
          [45.959338, 36.353592],
          [45.964061, 36.34415],
          [45.966284, 36.340817],
          [45.978227, 36.323592],
          [45.978508, 36.321655],
          [45.976841, 36.304979],
          [45.976285, 36.304141],
          [45.974618, 36.298871],
          [45.974618, 36.296646],
          [45.976008, 36.293312],
          [45.980728, 36.289141],
          [45.981841, 36.287483],
          [45.984248, 36.281645],
          [45.984341, 36.281366],
          [45.984618, 36.281087],
          [45.983785, 36.27387],
          [45.979341, 36.265257],
          [45.976008, 36.261365],
          [45.955172, 36.243588],
          [45.947115, 36.239146],
          [45.946282, 36.239146],
          [45.929059, 36.235263],
          [45.925172, 36.235263],
          [45.919892, 36.235263],
          [45.916839, 36.235263],
          [45.916282, 36.235533],
          [45.914892, 36.235263],
          [45.913502, 36.235533],
          [45.897112, 36.235263],
          [45.884059, 36.232199],
          [45.882392, 36.231371],
          [45.880446, 36.231091],
          [45.879336, 36.230533],
          [45.877389, 36.229983],
          [45.87628, 36.229983],
          [45.868503, 36.227758],
          [45.846556, 36.220811],
          [45.845166, 36.220811],
          [45.843223, 36.220531],
          [45.841833, 36.220261],
          [45.8385, 36.219702],
          [45.836276, 36.219702],
          [45.83489, 36.219423],
          [45.831, 36.219423],
          [45.830166, 36.219144],
          [45.826276, 36.219144],
          [45.82461, 36.219423],
          [45.82211, 36.219423],
          [45.81822, 36.219982],
          [45.8135, 36.221369],
          [45.806834, 36.224424],
          [45.801276, 36.226919],
          [45.799886, 36.227478],
          [45.748774, 36.23692],
          [45.747107, 36.23692],
          [45.744607, 36.237479],
          [45.742941, 36.237479],
          [45.740441, 36.238028],
          [45.739051, 36.238028],
          [45.737384, 36.238317],
          [45.734884, 36.238317],
          [45.732384, 36.238866],
          [45.703214, 36.23692],
          [45.699328, 36.235533],
          [45.698771, 36.235262],
          [45.688772, 36.228316],
          [45.687658, 36.228036],
          [45.687104, 36.227207],
          [45.683215, 36.224703],
          [45.677938, 36.220261],
          [45.677938, 36.219702],
          [45.674325, 36.213863],
          [45.673492, 36.208034],
          [45.673771, 36.206367],
          [45.674048, 36.205259],
          [45.681825, 36.185535],
          [45.681825, 36.179975],
          [45.680715, 36.174975],
          [45.679605, 36.172479],
          [45.679048, 36.172199],
          [45.678772, 36.171091],
          [45.674048, 36.164423],
          [45.670435, 36.159981],
          [45.670066, 36.159422],
          [45.669882, 36.159143],
          [45.669326, 36.158863],
          [45.668215, 36.157476],
          [45.667658, 36.157476],
          [45.667102, 36.156638],
          [45.645436, 36.144978],
          [45.645159, 36.144419],
          [45.644326, 36.14386],
          [45.641269, 36.138868],
          [45.642102, 36.137751],
          [45.646269, 36.129425],
          [45.660159, 36.118314],
          [45.663215, 36.117476],
          [45.667102, 36.116088],
          [45.674325, 36.112754],
          [45.675439, 36.111087],
          [45.675716, 36.110808],
          [45.674606, 36.107753],
          [45.672106, 36.105536],
          [45.671549, 36.104978],
          [45.670992, 36.104978],
          [45.670716, 36.104419],
          [45.669882, 36.104419],
          [45.669049, 36.103869],
          [45.665159, 36.102752],
          [45.664049, 36.102752],
          [45.654049, 36.099697],
          [45.63238, 36.093308],
          [45.630713, 36.093308],
          [45.62988, 36.093028],
          [45.628212, 36.093028],
          [45.612933, 36.090532],
          [45.610989, 36.090532],
          [45.601546, 36.088865],
          [45.594323, 36.087477],
          [45.59349, 36.087477],
          [45.56571, 36.07998],
          [45.555154, 36.074419],
          [45.554597, 36.07414],
          [45.55432, 36.07359],
          [45.553763, 36.073311],
          [45.551544, 36.065804],
        ],
      ],
    },
  },
  Lorestan: {
    properties: { value: "Lorestan", name: "استان لرستان", id: 13 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [46.839392, 33.561583],
          [46.839668, 33.559922],
          [46.840226, 33.557422],
          [46.840502, 33.556302],
          [46.841058, 33.555471],
          [46.841612, 33.553801],
          [46.844244, 33.550988],
          [46.861615, 33.532414],
          [46.862725, 33.529643],
          [46.863005, 33.529354],
          [46.862449, 33.526302],
          [46.861892, 33.524912],
          [46.861615, 33.522131],
          [46.863839, 33.51992],
          [46.865782, 33.51908],
          [46.866615, 33.51908],
          [46.874672, 33.515198],
          [46.878005, 33.511857],
          [46.878558, 33.511857],
          [46.886895, 33.509086],
          [46.889948, 33.505755],
          [46.890781, 33.503525],
          [46.891615, 33.501864],
          [46.894671, 33.498803],
          [46.896338, 33.497973],
          [46.900785, 33.496582],
          [46.902728, 33.496582],
          [46.903285, 33.496302],
          [46.904672, 33.496302],
          [46.904951, 33.496023],
          [46.906061, 33.495752],
          [46.909118, 33.492691],
          [46.914118, 33.483248],
          [46.919395, 33.479637],
          [46.920228, 33.479636],
          [46.920784, 33.479357],
          [46.928008, 33.476856],
          [46.929951, 33.475195],
          [46.946618, 33.464361],
          [46.952451, 33.462971],
          [46.954117, 33.462971],
          [46.954398, 33.462691],
          [46.955508, 33.462691],
          [46.955788, 33.462411],
          [46.957454, 33.46214],
          [46.959398, 33.46214],
          [46.959674, 33.46186],
          [46.960231, 33.46186],
          [46.960231, 33.46158],
          [46.960788, 33.46158],
          [46.960788, 33.461301],
          [46.962174, 33.46019],
          [46.962731, 33.45908],
          [46.970507, 33.450196],
          [46.971064, 33.450196],
          [46.973564, 33.448246],
          [46.981897, 33.443244],
          [46.983011, 33.442973],
          [46.986897, 33.443524],
          [46.991897, 33.447975],
          [46.99301, 33.447975],
          [46.99301, 33.447686],
          [46.9944, 33.447135],
          [46.994677, 33.445745],
          [46.994954, 33.443244],
          [46.995234, 33.442693],
          [46.994954, 33.440193],
          [46.995234, 33.438243],
          [46.995234, 33.437972],
          [46.996344, 33.437132],
          [46.997734, 33.436861],
          [47.0019, 33.437132],
          [47.002453, 33.436861],
          [47.0069, 33.436861],
          [47.011343, 33.435471],
          [47.017733, 33.426027],
          [47.023566, 33.424077],
          [47.025234, 33.421856],
          [47.02551, 33.421577],
          [47.026066, 33.420186],
          [47.02579, 33.417965],
          [47.02551, 33.416024],
          [47.0244, 33.413523],
          [47.01551, 33.40575],
          [47.009401, 33.402969],
          [47.006067, 33.397968],
          [47.005787, 33.394076],
          [47.006067, 33.393806],
          [47.006067, 33.392686],
          [47.006344, 33.391585],
          [47.00662, 33.390745],
          [47.010233, 33.384082],
          [47.013291, 33.384082],
          [47.013844, 33.384353],
          [47.020234, 33.384353],
          [47.02051, 33.384633],
          [47.02301, 33.384633],
          [47.023567, 33.384353],
          [47.032457, 33.380471],
          [47.04079, 33.375189],
          [47.047457, 33.372968],
          [47.050513, 33.372968],
          [47.05079, 33.372688],
          [47.051347, 33.372688],
          [47.052737, 33.372138],
          [47.05468, 33.369077],
          [47.057737, 33.362414],
          [47.061346, 33.360744],
          [47.063014, 33.360744],
          [47.06357, 33.360464],
          [47.068013, 33.360464],
          [47.068289, 33.360184],
          [47.070236, 33.359913],
          [47.074126, 33.357963],
          [47.079126, 33.352131],
          [47.080237, 33.35186],
          [47.081903, 33.35186],
          [47.082737, 33.35158],
          [47.085793, 33.35158],
          [47.092459, 33.35158],
          [47.093293, 33.3513],
          [47.094402, 33.3513],
          [47.099126, 33.34991],
          [47.099683, 33.349359],
          [47.100236, 33.349359],
          [47.100236, 33.349079],
          [47.101903, 33.346298],
          [47.102183, 33.346019],
          [47.101349, 33.340746],
          [47.10135, 33.337965],
          [47.102183, 33.335744],
          [47.10635, 33.332413],
          [47.107182, 33.332413],
          [47.107739, 33.332133],
          [47.108573, 33.332133],
          [47.10885, 33.331853],
          [47.109406, 33.331853],
          [47.109682, 33.331582],
          [47.11024, 33.331582],
          [47.110793, 33.331582],
          [47.111442, 33.331582],
          [47.111906, 33.331582],
          [47.139129, 33.341297],
          [47.140239, 33.341297],
          [47.141072, 33.341577],
          [47.143015, 33.341577],
          [47.143572, 33.341297],
          [47.148572, 33.339915],
          [47.151352, 33.338525],
          [47.152186, 33.337686],
          [47.153019, 33.337415],
          [47.160519, 33.330463],
          [47.161629, 33.328522],
          [47.161905, 33.327691],
          [47.162185, 33.326851],
          [47.164685, 33.321858],
          [47.174685, 33.315746],
          [47.175795, 33.315746],
          [47.176075, 33.315467],
          [47.178295, 33.314356],
          [47.178852, 33.312965],
          [47.177462, 33.304912],
          [47.177742, 33.304632],
          [47.178852, 33.303242],
          [47.179962, 33.30186],
          [47.180242, 33.30186],
          [47.180518, 33.30186],
          [47.180518, 33.301572],
          [47.188019, 33.2988],
          [47.189132, 33.2988],
          [47.189408, 33.29908],
          [47.191075, 33.29908],
          [47.197741, 33.302411],
          [47.199131, 33.302411],
          [47.199685, 33.302691],
          [47.200798, 33.302691],
          [47.201075, 33.302411],
          [47.201908, 33.302411],
          [47.204131, 33.29908],
          [47.204408, 33.29852],
          [47.203855, 33.294358],
          [47.203022, 33.293247],
          [47.200799, 33.290746],
          [47.195518, 33.282684],
          [47.201355, 33.275741],
          [47.204408, 33.27463],
          [47.211632, 33.27463],
          [47.212188, 33.27491],
          [47.213855, 33.27491],
          [47.214132, 33.27519],
          [47.217465, 33.27547],
          [47.217741, 33.27519],
          [47.219688, 33.27519],
          [47.222188, 33.27463],
          [47.225244, 33.27324],
          [47.228298, 33.269629],
          [47.228298, 33.269078],
          [47.228855, 33.268798],
          [47.228855, 33.268247],
          [47.232465, 33.262965],
          [47.232745, 33.260185],
          [47.233021, 33.259914],
          [47.230245, 33.251571],
          [47.230521, 33.2513],
          [47.230521, 33.250461],
          [47.231911, 33.248799],
          [47.232465, 33.247969],
          [47.239688, 33.244628],
          [47.242745, 33.244078],
          [47.245521, 33.244078],
          [47.246354, 33.244358],
          [47.246354, 33.245188],
          [47.247468, 33.246019],
          [47.247468, 33.246299],
          [47.250521, 33.251021],
          [47.252468, 33.252131],
          [47.254411, 33.252131],
          [47.254688, 33.251851],
          [47.255244, 33.251851],
          [47.255244, 33.251571],
          [47.258858, 33.25019],
          [47.259411, 33.24963],
          [47.266077, 33.245188],
          [47.269411, 33.236855],
          [47.270244, 33.234634],
          [47.271358, 33.232963],
          [47.273301, 33.231573],
          [47.274967, 33.231022],
          [47.277191, 33.231022],
          [47.280801, 33.233794],
          [47.286081, 33.237965],
          [47.2883, 33.237965],
          [47.29719, 33.232413],
          [47.297471, 33.232133],
          [47.29719, 33.229632],
          [47.299137, 33.227411],
          [47.301357, 33.227131],
          [47.301914, 33.227131],
          [47.302191, 33.227411],
          [47.303304, 33.227411],
          [47.30747, 33.230472],
          [47.308304, 33.230472],
          [47.308581, 33.230183],
          [47.309413, 33.229912],
          [47.310524, 33.227691],
          [47.310804, 33.227131],
          [47.310524, 33.22658],
          [47.30997, 33.222689],
          [47.311913, 33.220468],
          [47.316914, 33.221298],
          [47.319971, 33.222409],
          [47.324693, 33.22463],
          [47.325804, 33.22463],
          [47.326361, 33.22491],
          [47.328304, 33.22491],
          [47.33108, 33.221849],
          [47.33136, 33.219357],
          [47.333027, 33.217407],
          [47.336083, 33.216297],
          [47.338303, 33.216297],
          [47.344416, 33.219077],
          [47.346916, 33.219077],
          [47.350803, 33.217967],
          [47.351083, 33.217407],
          [47.352474, 33.215186],
          [47.35275, 33.214626],
          [47.35275, 33.213245],
          [47.353027, 33.210464],
          [47.353307, 33.208803],
          [47.353583, 33.206293],
          [47.35386, 33.206022],
          [47.35664, 33.202131],
          [47.357193, 33.20241],
          [47.358307, 33.20241],
          [47.36025, 33.203521],
          [47.368026, 33.210464],
          [47.371916, 33.210464],
          [47.374973, 33.209354],
          [47.380806, 33.204631],
          [47.386363, 33.198239],
          [47.387196, 33.197959],
          [47.387749, 33.197409],
          [47.389416, 33.196578],
          [47.390529, 33.196578],
          [47.39192, 33.196849],
          [47.395253, 33.19907],
          [47.399696, 33.197688],
          [47.403029, 33.190745],
          [47.406086, 33.188804],
          [47.406919, 33.188804],
          [47.415809, 33.185743],
          [47.419419, 33.182412],
          [47.419419, 33.182132],
          [47.419695, 33.181572],
          [47.419976, 33.181301],
          [47.418863, 33.180462],
          [47.415252, 33.1788],
          [47.414976, 33.17797],
          [47.415809, 33.17713],
          [47.418863, 33.176019],
          [47.422195, 33.176019],
          [47.422476, 33.176299],
          [47.423863, 33.176299],
          [47.424142, 33.176579],
          [47.425253, 33.176579],
          [47.425529, 33.176859],
          [47.429419, 33.176859],
          [47.429976, 33.176579],
          [47.429976, 33.176299],
          [47.430252, 33.17602],
          [47.430252, 33.17546],
          [47.430809, 33.174909],
          [47.430809, 33.174349],
          [47.431366, 33.174069],
          [47.432752, 33.172408],
          [47.433309, 33.172408],
          [47.436919, 33.170467],
          [47.437199, 33.169907],
          [47.435809, 33.159072],
          [47.435809, 33.15519],
          [47.437476, 33.15324],
          [47.439142, 33.15324],
          [47.440252, 33.15352],
          [47.440809, 33.15352],
          [47.441366, 33.153799],
          [47.444142, 33.1538],
          [47.444419, 33.15352],
          [47.445809, 33.15352],
          [47.447475, 33.152969],
          [47.448589, 33.152969],
          [47.448589, 33.152689],
          [47.449142, 33.152689],
          [47.449422, 33.152409],
          [47.449975, 33.152409],
          [47.450532, 33.152129],
          [47.453865, 33.151579],
          [47.456089, 33.151579],
          [47.456365, 33.151299],
          [47.460809, 33.151299],
          [47.46714, 33.147845],
          [47.469979, 33.146297],
          [47.470532, 33.146026],
          [47.471089, 33.146026],
          [47.471089, 33.145737],
          [47.471922, 33.145737],
          [47.471922, 33.145457],
          [47.472755, 33.145457],
          [47.473032, 33.145186],
          [47.473312, 33.144906],
          [47.474145, 33.144906],
          [47.474145, 33.144626],
          [47.475812, 33.142965],
          [47.475812, 33.142414],
          [47.476365, 33.140184],
          [47.476645, 33.138514],
          [47.476922, 33.137692],
          [47.479422, 33.134902],
          [47.488053, 33.132712],
          [47.490123, 33.131407],
          [47.489859, 33.12972],
          [47.489419, 33.129444],
          [47.488648, 33.129211],
          [47.487262, 33.129922],
          [47.484775, 33.12927],
          [47.481853, 33.127848],
          [47.481473, 33.12555],
          [47.482744, 33.123991],
          [47.484422, 33.123246],
          [47.486645, 33.120745],
          [47.491922, 33.118244],
          [47.491489, 33.117093],
          [47.489662, 33.114959],
          [47.492226, 33.11344],
          [47.495528, 33.112895],
          [47.499645, 33.10733],
          [47.501339, 33.104915],
          [47.50575, 33.103622],
          [47.508061, 33.102614],
          [47.508335, 33.102384],
          [47.508651, 33.102119],
          [47.509231, 33.101995],
          [47.51018, 33.101859],
          [47.510721, 33.101761],
          [47.511645, 33.101577],
          [47.51333, 33.102243],
          [47.514636, 33.104198],
          [47.519127, 33.10699],
          [47.522231, 33.106232],
          [47.523306, 33.10537],
          [47.523617, 33.104269],
          [47.52264, 33.101352],
          [47.525456, 33.099039],
          [47.525988, 33.095846],
          [47.525539, 33.093477],
          [47.525613, 33.092263],
          [47.527758, 33.090462],
          [47.530545, 33.088101],
          [47.529148, 33.08574],
          [47.532833, 33.083583],
          [47.537645, 33.084457],
          [47.539566, 33.08283],
          [47.540334, 33.081424],
          [47.541999, 33.080894],
          [47.543244, 33.081301],
          [47.544065, 33.081597],
          [47.54584, 33.081849],
          [47.546924, 33.08546],
          [47.549148, 33.08713],
          [47.549981, 33.08713],
          [47.552205, 33.08602],
          [47.556091, 33.079077],
          [47.562204, 33.076296],
          [47.564428, 33.074075],
          [47.566928, 33.071574],
          [47.575538, 33.06268],
          [47.578594, 33.061569],
          [47.578871, 33.061019],
          [47.579704, 33.060739],
          [47.579984, 33.060179],
          [47.587204, 33.049353],
          [47.587484, 33.049073],
          [47.592204, 33.045182],
          [47.593317, 33.045182],
          [47.597761, 33.047403],
          [47.599984, 33.047403],
          [47.60026, 33.047123],
          [47.601927, 33.046572],
          [47.611374, 33.038798],
          [47.612484, 33.038798],
          [47.619707, 33.036288],
          [47.623874, 33.032126],
          [47.62443, 33.032126],
          [47.624707, 33.031846],
          [47.62554, 33.031846],
          [47.62804, 33.032406],
          [47.628873, 33.032686],
          [47.630817, 33.034347],
          [47.632207, 33.034907],
          [47.633597, 33.034907],
          [47.63415, 33.034627],
          [47.636653, 33.034627],
          [47.637207, 33.034347],
          [47.64054, 33.033796],
          [47.64082, 33.033237],
          [47.641653, 33.032686],
          [47.64582, 33.026853],
          [47.646654, 33.026293],
          [47.648597, 33.026293],
          [47.648873, 33.026013],
          [47.650263, 33.026013],
          [47.65054, 33.025733],
          [47.653873, 33.023512],
          [47.65721, 33.020181],
          [47.660543, 33.01907],
          [47.661653, 33.01768],
          [47.674986, 33.010186],
          [47.67582, 33.010185],
          [47.6761, 33.009906],
          [47.676933, 33.009906],
          [47.67721, 33.009626],
          [47.678319, 33.009626],
          [47.6786, 33.009346],
          [47.67971, 33.009075],
          [47.679986, 33.008795],
          [47.687209, 33.005183],
          [47.690543, 33.002402],
          [47.691656, 33.002132],
          [47.706376, 33.004903],
          [47.708043, 33.004903],
          [47.708323, 33.005183],
          [47.711379, 33.005183],
          [47.711656, 33.004903],
          [47.712213, 33.004904],
          [47.717489, 33.003242],
          [47.718046, 33.002962],
          [47.721099, 33.002962],
          [47.721656, 33.003242],
          [47.723046, 33.003242],
          [47.723599, 33.003522],
          [47.726656, 33.003522],
          [47.726932, 33.003242],
          [47.727489, 33.003242],
          [47.728046, 33.002682],
          [47.728599, 33.002682],
          [47.730269, 33.000741],
          [47.730822, 32.999351],
          [47.742212, 32.994909],
          [47.744156, 32.994908],
          [47.744436, 32.995188],
          [47.749159, 32.99796],
          [47.749992, 32.99796],
          [47.750545, 32.99824],
          [47.751935, 32.99824],
          [47.753325, 32.99824],
          [47.761659, 32.993798],
          [47.771382, 32.984633],
          [47.772215, 32.983243],
          [47.776004, 32.979902],
          [47.776382, 32.979631],
          [47.778048, 32.979071],
          [47.780269, 32.979071],
          [47.780825, 32.978792],
          [47.784715, 32.978792],
          [47.784992, 32.978512],
          [47.788881, 32.97741],
          [47.790272, 32.97685],
          [47.790548, 32.97657],
          [47.791105, 32.97657],
          [47.796938, 32.974629],
          [47.798048, 32.974629],
          [47.799992, 32.974069],
          [47.809715, 32.971848],
          [47.811105, 32.971018],
          [47.821104, 32.965456],
          [47.825828, 32.959072],
          [47.825828, 32.958241],
          [47.829994, 32.953239],
          [47.832218, 32.951849],
          [47.833328, 32.951018],
          [47.837494, 32.948237],
          [47.847774, 32.937122],
          [47.848608, 32.936851],
          [47.849161, 32.936292],
          [47.853327, 32.93435],
          [47.855551, 32.93435],
          [47.856108, 32.93407],
          [47.856384, 32.93435],
          [47.857217, 32.93407],
          [47.857774, 32.93435],
          [47.860274, 32.93435],
          [47.860551, 32.93463],
          [47.861384, 32.93463],
          [47.862774, 32.934901],
          [47.863331, 32.934901],
          [47.867774, 32.939073],
          [47.869164, 32.939073],
          [47.869441, 32.938793],
          [47.870274, 32.938793],
          [47.871941, 32.938233],
          [47.874164, 32.936572],
          [47.877497, 32.932409],
          [47.877497, 32.926847],
          [47.87944, 32.922956],
          [47.886107, 32.920184],
          [47.887497, 32.919073],
          [47.887774, 32.918793],
          [47.888887, 32.915732],
          [47.889164, 32.914902],
          [47.88944, 32.914071],
          [47.892497, 32.91157],
          [47.893607, 32.91157],
          [47.894164, 32.91129],
          [47.896664, 32.91129],
          [47.89972, 32.91213],
          [47.901664, 32.913791],
          [47.902777, 32.913791],
          [47.90472, 32.91157],
          [47.904444, 32.908789],
          [47.904997, 32.907678],
          [47.90611, 32.907127],
          [47.910277, 32.907127],
          [47.910554, 32.907407],
          [47.912497, 32.907958],
          [47.91472, 32.907958],
          [47.919444, 32.905457],
          [47.923053, 32.901565],
          [47.92361, 32.901565],
          [47.926387, 32.900184],
          [47.931386, 32.901565],
          [47.934443, 32.899904],
          [47.935553, 32.898793],
          [47.935, 32.894902],
          [47.93472, 32.894071],
          [47.934443, 32.892401],
          [47.93611, 32.88962],
          [47.936667, 32.889349],
          [47.94389, 32.883236],
          [47.954723, 32.878234],
          [47.958056, 32.877963],
          [47.959166, 32.878234],
          [47.963056, 32.874902],
          [47.96389, 32.873792],
          [47.964166, 32.872681],
          [47.964446, 32.87074],
          [47.964723, 32.868239],
          [47.965, 32.867408],
          [47.96528, 32.866008],
          [47.965833, 32.864907],
          [47.973333, 32.856564],
          [47.974723, 32.856013],
          [47.976946, 32.856013],
          [47.977223, 32.855733],
          [47.978056, 32.855462],
          [47.979723, 32.855462],
          [47.980003, 32.855182],
          [47.981113, 32.855182],
          [47.983336, 32.852961],
          [47.983336, 32.852681],
          [47.983336, 32.85185],
          [47.982779, 32.85046],
          [47.982223, 32.85018],
          [47.981113, 32.846848],
          [47.98139, 32.846568],
          [47.98139, 32.846018],
          [47.982503, 32.844898],
          [47.983336, 32.844618],
          [47.984723, 32.844618],
          [47.985003, 32.844618],
          [47.98528, 32.844898],
          [47.986389, 32.844898],
          [47.987503, 32.845738],
          [47.988336, 32.845738],
          [47.988613, 32.846018],
          [47.989723, 32.846018],
          [47.989723, 32.846298],
          [47.991389, 32.846298],
          [47.99417, 32.843517],
          [47.995279, 32.841846],
          [47.995279, 32.841286],
          [47.995279, 32.839065],
          [48.000279, 32.823237],
          [48.000559, 32.822406],
          [48.000836, 32.821287],
          [48.001113, 32.820456],
          [48.001393, 32.819345],
          [48.002503, 32.817124],
          [48.002503, 32.816844],
          [48.002503, 32.816564],
          [48.002779, 32.815183],
          [48.003336, 32.813792],
          [48.003336, 32.813512],
          [48.003336, 32.813232],
          [48.003336, 32.812953],
          [48.003336, 32.811851],
          [48.002779, 32.811291],
          [48.003059, 32.811011],
          [48.009446, 32.800736],
          [48.011393, 32.794072],
          [48.011669, 32.793792],
          [48.012503, 32.790461],
          [48.010002, 32.779345],
          [48.006669, 32.774623],
          [48.006393, 32.774343],
          [47.998893, 32.76851],
          [47.996946, 32.767679],
          [47.99528, 32.767679],
          [47.99167, 32.76712],
          [47.991113, 32.7674],
          [47.990836, 32.76712],
          [47.983056, 32.76712],
          [47.982503, 32.76684],
          [47.963613, 32.766569],
          [47.963057, 32.766289],
          [47.958614, 32.766289],
          [47.958057, 32.766009],
          [47.94861, 32.765178],
          [47.943334, 32.763788],
          [47.941944, 32.763228],
          [47.940834, 32.762677],
          [47.94, 32.762677],
          [47.94, 32.762397],
          [47.939444, 32.762397],
          [47.935, 32.756004],
          [47.935277, 32.755174],
          [47.935, 32.749901],
          [47.931667, 32.747399],
          [47.930834, 32.747399],
          [47.930554, 32.747119],
          [47.927501, 32.746289],
          [47.924444, 32.737404],
          [47.924721, 32.737124],
          [47.923887, 32.731011],
          [47.922777, 32.7299],
          [47.922221, 32.729621],
          [47.917221, 32.727679],
          [47.916111, 32.727679],
          [47.910554, 32.723508],
          [47.910278, 32.721286],
          [47.916111, 32.714063],
          [47.917221, 32.713512],
          [47.918054, 32.712672],
          [47.92861, 32.707399],
          [47.928887, 32.707119],
          [47.94111, 32.698785],
          [47.950277, 32.69018],
          [47.951667, 32.686289],
          [47.951944, 32.686009],
          [47.950557, 32.679065],
          [47.9525, 32.674894],
          [47.958614, 32.667119],
          [47.95889, 32.666839],
          [47.969391, 32.658729],
          [47.969147, 32.65828],
          [47.970131, 32.658511],
          [47.971333, 32.658696],
          [47.972042, 32.658695],
          [47.973133, 32.658421],
          [47.97488, 32.657914],
          [47.97744, 32.656857],
          [47.978966, 32.655983],
          [47.979889, 32.655201],
          [47.980488, 32.65451],
          [47.981522, 32.653728],
          [47.982121, 32.653449],
          [47.983101, 32.653496],
          [47.984306, 32.653727],
          [47.984447, 32.653783],
          [47.985237, 32.654095],
          [47.986767, 32.654603],
          [47.987808, 32.654923],
          [47.989121, 32.655385],
          [47.990413, 32.655689],
          [47.990872, 32.655796],
          [47.991966, 32.656488],
          [47.993446, 32.657269],
          [47.994139, 32.657817],
          [47.994434, 32.65805],
          [47.995205, 32.65897],
          [47.996357, 32.659753],
          [47.997238, 32.661133],
          [47.998066, 32.662742],
          [47.998955, 32.664997],
          [47.999388, 32.666346],
          [47.999552, 32.666574],
          [47.999591, 32.666628],
          [47.999977, 32.666888],
          [48.001282, 32.66759],
          [48.001942, 32.668136],
          [48.002167, 32.668594],
          [48.002178, 32.669289],
          [48.001804, 32.6698],
          [48.00148, 32.669894],
          [48.001335, 32.670864],
          [48.001236, 32.671416],
          [48.00124, 32.671666],
          [48.001244, 32.671879],
          [48.001251, 32.672481],
          [48.000996, 32.673358],
          [48.001163, 32.673867],
          [48.001499, 32.674324],
          [48.002045, 32.674548],
          [48.003403, 32.674534],
          [48.004215, 32.674526],
          [48.005089, 32.674702],
          [48.005848, 32.674698],
          [48.006283, 32.674877],
          [48.006722, 32.675057],
          [48.007378, 32.675235],
          [48.008247, 32.675503],
          [48.008633, 32.675637],
          [48.009392, 32.675632],
          [48.010037, 32.675346],
          [48.010742, 32.675155],
          [48.011929, 32.67482],
          [48.013279, 32.674348],
          [48.014576, 32.673966],
          [48.015984, 32.673722],
          [48.017876, 32.673195],
          [48.018849, 32.672954],
          [48.020256, 32.672756],
          [48.021175, 32.672521],
          [48.022369, 32.672508],
          [48.023182, 32.672359],
          [48.023945, 32.672402],
          [48.024761, 32.672531],
          [48.025524, 32.672707],
          [48.026013, 32.672886],
          [48.026566, 32.673435],
          [48.027168, 32.673889],
          [48.027615, 32.674483],
          [48.028385, 32.675171],
          [48.029148, 32.675437],
          [48.029804, 32.67571],
          [48.030674, 32.675746],
          [48.03225, 32.675731],
          [48.03355, 32.675629],
          [48.035233, 32.675563],
          [48.037132, 32.6755],
          [48.038815, 32.675576],
          [48.040012, 32.675795],
          [48.041653, 32.676427],
          [48.043129, 32.677055],
          [48.04438, 32.677413],
          [48.0452, 32.677635],
          [48.046234, 32.677718],
          [48.046886, 32.677756],
          [48.047318, 32.67757],
          [48.047852, 32.677055],
          [48.048, 32.676315],
          [48.048477, 32.675528],
          [48.049225, 32.674737],
          [48.049976, 32.674317],
          [48.051056, 32.673842],
          [48.05257, 32.673553],
          [48.054687, 32.673348],
          [48.057129, 32.67337],
          [48.05941, 32.673534],
          [48.061863, 32.67397],
          [48.063004, 32.67419],
          [48.064911, 32.674542],
          [48.065842, 32.675129],
          [48.066887, 32.675857],
          [48.067654, 32.67622],
          [48.068798, 32.676483],
          [48.069721, 32.676613],
          [48.070098, 32.676638],
          [48.070919, 32.676693],
          [48.071896, 32.676686],
          [48.072769, 32.67709],
          [48.072994, 32.677598],
          [48.073059, 32.678242],
          [48.073227, 32.678608],
          [48.073727, 32.679294],
          [48.073856, 32.680447],
          [48.074516, 32.681129],
          [48.074528, 32.681774],
          [48.074492, 32.682117],
          [48.074432, 32.682698],
          [48.074394, 32.683484],
          [48.074455, 32.684033],
          [48.074463, 32.68454],
          [48.074581, 32.685048],
          [48.074909, 32.685272],
          [48.075623, 32.685589],
          [48.076763, 32.685764],
          [48.079819, 32.686653],
          [48.081837, 32.68737],
          [48.083366, 32.687863],
          [48.084023, 32.688043],
          [48.084675, 32.688267],
          [48.085384, 32.688489],
          [48.086529, 32.688664],
          [48.087399, 32.688885],
          [48.088116, 32.689384],
          [48.088314, 32.689622],
          [48.088612, 32.68998],
          [48.089169, 32.690894],
          [48.089726, 32.691766],
          [48.089954, 32.692547],
          [48.090195, 32.69397],
          [48.090321, 32.694847],
          [48.090439, 32.69563],
          [48.090397, 32.696228],
          [48.090027, 32.696923],
          [48.089554, 32.697803],
          [48.08897, 32.698731],
          [48.088005, 32.699525],
          [48.087479, 32.700542],
          [48.087055, 32.701283],
          [48.08675, 32.702439],
          [48.086601, 32.703452],
          [48.086563, 32.704285],
          [48.085922, 32.704983],
          [48.085274, 32.705128],
          [48.084457, 32.705136],
          [48.083263, 32.705146],
          [48.082607, 32.704876],
          [48.082172, 32.704697],
          [48.081406, 32.704289],
          [48.080467, 32.703285],
          [48.079853, 32.702324],
          [48.078922, 32.701874],
          [48.078152, 32.701234],
          [48.077606, 32.701056],
          [48.077171, 32.70092],
          [48.076466, 32.700928],
          [48.075977, 32.70098],
          [48.075337, 32.701583],
          [48.074856, 32.702236],
          [48.074436, 32.703209],
          [48.073746, 32.704045],
          [48.072731, 32.704975],
          [48.071819, 32.705722],
          [48.07058, 32.706428],
          [48.069336, 32.70676],
          [48.067715, 32.7071],
          [48.065662, 32.707722],
          [48.064529, 32.708241],
          [48.064503, 32.708266],
          [48.063995, 32.708751],
          [48.063793, 32.70963],
          [48.063698, 32.710414],
          [48.063812, 32.710967],
          [48.064091, 32.711334],
          [48.064583, 32.71156],
          [48.065235, 32.711598],
          [48.066158, 32.71159],
          [48.066975, 32.711765],
          [48.067898, 32.711845],
          [48.068665, 32.712162],
          [48.069267, 32.712479],
          [48.069653, 32.712794],
          [48.069881, 32.713532],
          [48.070107, 32.714085],
          [48.070339, 32.715003],
          [48.070564, 32.715417],
          [48.071007, 32.716103],
          [48.07188, 32.716417],
          [48.07243, 32.716731],
          [48.073246, 32.716771],
          [48.074116, 32.716854],
          [48.075359, 32.716656],
          [48.076599, 32.716182],
          [48.078659, 32.715885],
          [48.080284, 32.715684],
          [48.082024, 32.715806],
          [48.082951, 32.716073],
          [48.083042, 32.716079],
          [48.08355, 32.716109],
          [48.084206, 32.716472],
          [48.084866, 32.717019],
          [48.085419, 32.717704],
          [48.085869, 32.718483],
          [48.085983, 32.718945],
          [48.086044, 32.719406],
          [48.086048, 32.719682],
          [48.085949, 32.720096],
          [48.085415, 32.720749],
          [48.084938, 32.721581],
          [48.084568, 32.722093],
          [48.08458, 32.722877],
          [48.084599, 32.72398],
          [48.084339, 32.72472],
          [48.083908, 32.725048],
          [48.083584, 32.725052],
          [48.082878, 32.72506],
          [48.082497, 32.725063],
          [48.081955, 32.725068],
          [48.081634, 32.725259],
          [48.081421, 32.725491],
          [48.081429, 32.726043],
          [48.0816, 32.726592],
          [48.08194, 32.727376],
          [48.081951, 32.728111],
          [48.081963, 32.728893],
          [48.081543, 32.729911],
          [48.081127, 32.730929],
          [48.080975, 32.731579],
          [48.080547, 32.731948],
          [48.080063, 32.732232],
          [48.079304, 32.732329],
          [48.078594, 32.732155],
          [48.077671, 32.732167],
          [48.075764, 32.731679],
          [48.074287, 32.731094],
          [48.072868, 32.730556],
          [48.072319, 32.730144],
          [48.071499, 32.729832],
          [48.070087, 32.729709],
          [48.068569, 32.729816],
          [48.067811, 32.729926],
          [48.067539, 32.729965],
          [48.066944, 32.730201],
          [48.066578, 32.731038],
          [48.066536, 32.731774],
          [48.066548, 32.732509],
          [48.066616, 32.73325],
          [48.066628, 32.73394],
          [48.066655, 32.734079],
          [48.066746, 32.73454],
          [48.067074, 32.734902],
          [48.067348, 32.735084],
          [48.067783, 32.73508],
          [48.068111, 32.735077],
          [48.06844, 32.735211],
          [48.068665, 32.735714],
          [48.068668, 32.736039],
          [48.068619, 32.736407],
          [48.068459, 32.73655],
          [48.067814, 32.737015],
          [48.067116, 32.737301],
          [48.066414, 32.737584],
          [48.065601, 32.737682],
          [48.064842, 32.737831],
          [48.063599, 32.738212],
          [48.061689, 32.739235],
          [48.060963, 32.739624],
          [48.059998, 32.740373],
          [48.059792, 32.74097],
          [48.059853, 32.741523],
          [48.060078, 32.741982],
          [48.06041, 32.742303],
          [48.061169, 32.742386],
          [48.061821, 32.742379],
          [48.062527, 32.742465],
          [48.063183, 32.742641],
          [48.063839, 32.742913],
          [48.063884, 32.742938],
          [48.064331, 32.743184],
          [48.06461, 32.743596],
          [48.064892, 32.744236],
          [48.064899, 32.744743],
          [48.064747, 32.745483],
          [48.064541, 32.746177],
          [48.064121, 32.746963],
          [48.063915, 32.747657],
          [48.063442, 32.748586],
          [48.06345, 32.749183],
          [48.063501, 32.75012],
          [48.063644, 32.750743],
          [48.063221, 32.751576],
          [48.062366, 32.752866],
          [48.061028, 32.754112],
          [48.060013, 32.754807],
          [48.059315, 32.755544],
          [48.058927, 32.756628],
          [48.058838, 32.756878],
          [48.058792, 32.757984],
          [48.059013, 32.759042],
          [48.059609, 32.759502],
          [48.060955, 32.75982],
          [48.06171, 32.759957],
          [48.062622, 32.759918],
          [48.062893, 32.759906],
          [48.064617, 32.760178],
          [48.065586, 32.760174],
          [48.066395, 32.760265],
          [48.066986, 32.760448],
          [48.067852, 32.760906],
          [48.068176, 32.761364],
          [48.068398, 32.761872],
          [48.068668, 32.762421],
          [48.068783, 32.763339],
          [48.06868, 32.764263],
          [48.068092, 32.76509],
          [48.067616, 32.765877],
          [48.067024, 32.766521],
          [48.066113, 32.767124],
          [48.064987, 32.767864],
          [48.064449, 32.76823],
          [48.063538, 32.768513],
          [48.062782, 32.768603],
          [48.061493, 32.768562],
          [48.0602, 32.76852],
          [48.058747, 32.768752],
          [48.058045, 32.768803],
          [48.05756, 32.76862],
          [48.057236, 32.76839],
          [48.056534, 32.767886],
          [48.056099, 32.767612],
          [48.055451, 32.767151],
          [48.054966, 32.766968],
          [48.054482, 32.76688],
          [48.054051, 32.767016],
          [48.05373, 32.767205],
          [48.05357, 32.767758],
          [48.053742, 32.769824],
          [48.053768, 32.770679],
          [48.05381, 32.772082],
          [48.054253, 32.774196],
          [48.054581, 32.775531],
          [48.054802, 32.776034],
          [48.055126, 32.77659],
          [48.05621, 32.777321],
          [48.056912, 32.777686],
          [48.057831, 32.778053],
          [48.05849, 32.778334],
          [48.058693, 32.77842],
          [48.059506, 32.778969],
          [48.060154, 32.779523],
          [48.062264, 32.781033],
          [48.064056, 32.782871],
          [48.065949, 32.784381],
          [48.067036, 32.785669],
          [48.067528, 32.786541],
          [48.067909, 32.7876],
          [48.068237, 32.788334],
          [48.068241, 32.788886],
          [48.068085, 32.789669],
          [48.067764, 32.790268],
          [48.067284, 32.79073],
          [48.066155, 32.791332],
          [48.065403, 32.791565],
          [48.063892, 32.79166],
          [48.06292, 32.791664],
          [48.06168, 32.791435],
          [48.060547, 32.791347],
          [48.059197, 32.790984],
          [48.058979, 32.790751],
          [48.058437, 32.790157],
          [48.057838, 32.789467],
          [48.057186, 32.788135],
          [48.056858, 32.787355],
          [48.056509, 32.78698],
          [48.056259, 32.786712],
          [48.055935, 32.786388],
          [48.055557, 32.786159],
          [48.055344, 32.786255],
          [48.055237, 32.786622],
          [48.055187, 32.787266],
          [48.055087, 32.787869],
          [48.055005, 32.78795],
          [48.054619, 32.789628],
          [48.054456, 32.790338],
          [48.054344, 32.791226],
          [48.054512, 32.792053],
          [48.05452, 32.793525],
          [48.05447, 32.794171],
          [48.054367, 32.794605],
          [48.054207, 32.795278],
          [48.054054, 32.797535],
          [48.054222, 32.798637],
          [48.054361, 32.799026],
          [48.054386, 32.799096],
          [48.054447, 32.799785],
          [48.054722, 32.800659],
          [48.054833, 32.801583],
          [48.054676, 32.802456],
          [48.054409, 32.803239],
          [48.053604, 32.803795],
          [48.052635, 32.804486],
          [48.051777, 32.804906],
          [48.050968, 32.804997],
          [48.050153, 32.804961],
          [48.049996, 32.804954],
          [48.049023, 32.805095],
          [48.048809, 32.805417],
          [48.049027, 32.805785],
          [48.049889, 32.806194],
          [48.056278, 32.80922],
          [48.057459, 32.809901],
          [48.057566, 32.809962],
          [48.057945, 32.810181],
          [48.068035, 32.815998],
          [48.080555, 32.822868],
          [48.089615, 32.828137],
          [48.093956, 32.830242],
          [48.096615, 32.831845],
          [48.098248, 32.833085],
          [48.100529, 32.834411],
          [48.101837, 32.835927],
          [48.103203, 32.837811],
          [48.104355, 32.83965],
          [48.104908, 32.841026],
          [48.105026, 32.842224],
          [48.105194, 32.84296],
          [48.104504, 32.844574],
          [48.104084, 32.845955],
          [48.104309, 32.846965],
          [48.105236, 32.848024],
          [48.10627, 32.84885],
          [48.106936, 32.849103],
          [48.107357, 32.849263],
          [48.109261, 32.850589],
          [48.110462, 32.851875],
          [48.111935, 32.852977],
          [48.112755, 32.854125],
          [48.113518, 32.854812],
          [48.1138, 32.855964],
          [48.114132, 32.857022],
          [48.114143, 32.858311],
          [48.114422, 32.859231],
          [48.11415, 32.859774],
          [48.113892, 32.860291],
          [48.114601, 32.860885],
          [48.115154, 32.862451],
          [48.116135, 32.862998],
          [48.117336, 32.863964],
          [48.118755, 32.8652],
          [48.119537, 32.865538],
          [48.120766, 32.866069],
          [48.121964, 32.866802],
          [48.122623, 32.867676],
          [48.123005, 32.868228],
          [48.123661, 32.868915],
          [48.123993, 32.869378],
          [48.124699, 32.869881],
          [48.125675, 32.870061],
          [48.126381, 32.869969],
          [48.127625, 32.869919],
          [48.128277, 32.87024],
          [48.128822, 32.870469],
          [48.128883, 32.871296],
          [48.128677, 32.872402],
          [48.128525, 32.873321],
          [48.128208, 32.874291],
          [48.128277, 32.875718],
          [48.128559, 32.876682],
          [48.128567, 32.877739],
          [48.128525, 32.87889],
          [48.128532, 32.879582],
          [48.128594, 32.880409],
          [48.12801, 32.881653],
          [48.127316, 32.882992],
          [48.126621, 32.883915],
          [48.125919, 32.88433],
          [48.125759, 32.884496],
          [48.125385, 32.884883],
          [48.125172, 32.885437],
          [48.125355, 32.885995],
          [48.125565, 32.886632],
          [48.125355, 32.887463],
          [48.124821, 32.888337],
          [48.124547, 32.888992],
          [48.124397, 32.88935],
          [48.123051, 32.890507],
          [48.121979, 32.892024],
          [48.121231, 32.893089],
          [48.120216, 32.894794],
          [48.119469, 32.896175],
          [48.119099, 32.897189],
          [48.118733, 32.898479],
          [48.118683, 32.899032],
          [48.119507, 32.900181],
          [48.120606, 32.901741],
          [48.122585, 32.904636],
          [48.123852, 32.906612],
          [48.126549, 32.91061],
          [48.12809, 32.912633],
          [48.129513, 32.913917],
          [48.13105, 32.915661],
          [48.132748, 32.917176],
          [48.134117, 32.918323],
          [48.135651, 32.919377],
          [48.136849, 32.919743],
          [48.137665, 32.919881],
          [48.138535, 32.919876],
          [48.139725, 32.919734],
          [48.141518, 32.919498],
          [48.143681, 32.918804],
          [48.145683, 32.918061],
          [48.147305, 32.917275],
          [48.148491, 32.916581],
          [48.149673, 32.915291],
          [48.151505, 32.913902],
          [48.153706, 32.911736],
          [48.15548, 32.910028],
          [48.157574, 32.907952],
          [48.158207, 32.906429],
          [48.158608, 32.905983],
          [48.159493, 32.904998],
          [48.161003, 32.904029],
          [48.163322, 32.902824],
          [48.164174, 32.902386],
          [48.165225, 32.901846],
          [48.165749, 32.901576],
          [48.169102, 32.900555],
          [48.171864, 32.90004],
          [48.175549, 32.899756],
          [48.176743, 32.899754],
          [48.178047, 32.900162],
          [48.179688, 32.900941],
          [48.181709, 32.902316],
          [48.182057, 32.902673],
          [48.183361, 32.904015],
          [48.186169, 32.907001],
          [48.187996, 32.909896],
          [48.191467, 32.913754],
          [48.19434, 32.917565],
          [48.197373, 32.920917],
          [48.1992, 32.923539],
          [48.200688, 32.925006],
          [48.203941, 32.928401],
          [48.207363, 32.932262],
          [48.210346, 32.935521],
          [48.213486, 32.938413],
          [48.216465, 32.941303],
          [48.218948, 32.943879],
          [48.220554, 32.945713],
          [48.222599, 32.947918],
          [48.225426, 32.951317],
          [48.227306, 32.953475],
          [48.228687, 32.954716],
          [48.230396, 32.956089],
          [48.232102, 32.957375],
          [48.23452, 32.958934],
          [48.234812, 32.959166],
          [48.235844, 32.959987],
          [48.238666, 32.962975],
          [48.240486, 32.964397],
          [48.244129, 32.967381],
          [48.245125, 32.968296],
          [48.247082, 32.971465],
          [48.249139, 32.973943],
          [48.24965, 32.976574],
          [48.249947, 32.978103],
          [48.251427, 32.979482],
          [48.253178, 32.981042],
          [48.2551, 32.983155],
          [48.256912, 32.985084],
          [48.258007, 32.98614],
          [48.259319, 32.987195],
          [48.260414, 32.988069],
          [48.261, 32.988378],
          [48.262051, 32.988934],
          [48.263519, 32.989708],
          [48.26614, 32.991215],
          [48.268486, 32.992772],
          [48.268925, 32.993099],
          [48.269962, 32.99387],
          [48.271057, 32.994602],
          [48.272419, 32.995243],
          [48.273014, 32.995377],
          [48.273617, 32.995742],
          [48.274597, 32.996334],
          [48.275902, 32.996651],
          [48.275955, 32.996697],
          [48.277584, 32.996913],
          [48.279697, 32.997128],
          [48.281811, 32.997066],
          [48.282741, 32.997001],
          [48.285542, 32.996807],
          [48.286949, 32.996563],
          [48.288136, 32.996277],
          [48.290138, 32.996215],
          [48.29306, 32.99596],
          [48.296196, 32.995609],
          [48.299061, 32.994941],
          [48.302406, 32.994125],
          [48.304836, 32.993553],
          [48.306835, 32.992981],
          [48.313469, 32.990658],
          [48.320423, 32.98829],
          [48.323174, 32.987156],
          [48.325378, 32.986117],
          [48.326618, 32.985463],
          [48.328182, 32.98494],
          [48.330288, 32.984412],
          [48.332336, 32.983609],
          [48.334003, 32.982761],
          [48.335243, 32.982152],
          [48.337887, 32.981387],
          [48.340149, 32.980678],
          [48.341068, 32.980389],
          [48.343822, 32.97958],
          [48.347164, 32.978488],
          [48.352726, 32.977195],
          [48.355591, 32.976525],
          [48.357536, 32.976231],
          [48.3591, 32.975617],
          [48.361794, 32.974434],
          [48.366425, 32.972501],
          [48.369659, 32.971176],
          [48.371601, 32.970741],
          [48.374084, 32.970027],
          [48.37635, 32.969315],
          [48.378235, 32.968327],
          [48.380604, 32.967198],
          [48.383354, 32.96634],
          [48.386108, 32.965855],
          [48.389679, 32.965454],
          [48.393421, 32.965701],
          [48.397644, 32.965762],
          [48.403008, 32.965903],
          [48.403956, 32.965806],
          [48.406414, 32.965552],
          [48.409721, 32.965714],
          [48.413456, 32.965588],
          [48.417297, 32.965188],
          [48.421246, 32.964787],
          [48.422586, 32.964731],
          [48.423843, 32.964671],
          [48.425903, 32.964703],
          [48.427532, 32.965198],
          [48.43047, 32.966239],
          [48.432648, 32.967057],
          [48.434765, 32.967599],
          [48.436554, 32.967816],
          [48.439918, 32.968205],
          [48.44458, 32.968677],
          [48.449619, 32.968686],
          [48.453896, 32.968557],
          [48.456116, 32.968494],
          [48.459358, 32.968048],
          [48.462925, 32.967232],
          [48.466053, 32.966281],
          [48.46751, 32.965851],
          [48.469784, 32.965694],
          [48.4739, 32.9658],
          [48.475472, 32.965882],
          [48.479038, 32.96539],
          [48.480846, 32.965195],
          [48.483204, 32.96494],
          [48.487793, 32.963833],
          [48.492817, 32.962636],
          [48.499612, 32.961203],
          [48.502209, 32.961505],
          [48.504566, 32.96169],
          [48.507141, 32.962287],
          [48.50988, 32.963622],
          [48.514317, 32.965462],
          [48.517494, 32.967072],
          [48.522263, 32.969784],
          [48.522994, 32.970265],
          [48.526592, 32.972638],
          [48.528839, 32.974014],
          [48.53207, 32.976269],
          [48.534046, 32.978015],
          [48.535194, 32.979027],
          [48.536674, 32.980087],
          [48.537826, 32.980774],
          [48.539692, 32.98156],
          [48.541336, 32.982295],
          [48.543034, 32.982937],
          [48.544899, 32.983308],
          [48.547199, 32.98363],
          [48.549721, 32.983723],
          [48.552025, 32.983357],
          [48.55394, 32.983037],
          [48.557999, 32.982305],
          [48.561176, 32.981571],
          [48.565178, 32.980335],
          [48.570873, 32.978131],
          [48.572713, 32.977311],
          [48.575916, 32.975884],
          [48.584023, 32.972579],
          [48.587585, 32.971385],
          [48.589119, 32.970973],
          [48.59005, 32.970928],
          [48.590816, 32.970974],
          [48.592079, 32.971436],
          [48.593231, 32.971939],
          [48.594711, 32.972585],
          [48.596245, 32.973827],
          [48.598934, 32.976631],
          [48.601021, 32.978978],
          [48.601952, 32.97976],
          [48.602501, 32.980079],
          [48.603157, 32.980358],
          [48.604748, 32.980449],
          [48.609406, 32.97967],
          [48.615433, 32.978573],
          [48.623924, 32.9766],
          [48.629406, 32.97541],
          [48.632473, 32.974725],
          [48.636196, 32.973483],
          [48.640304, 32.972244],
          [48.644085, 32.970593],
          [48.64819, 32.968892],
          [48.653668, 32.966412],
          [48.65728, 32.964394],
          [48.660946, 32.962096],
          [48.666145, 32.958557],
          [48.668881, 32.956814],
          [48.67178, 32.955204],
          [48.675118, 32.953411],
          [48.678402, 32.951806],
          [48.681633, 32.950841],
          [48.684754, 32.94997],
          [48.687271, 32.949051],
          [48.689568, 32.947811],
          [48.690555, 32.947305],
          [48.692086, 32.946521],
          [48.695206, 32.945052],
          [48.697559, 32.94418],
          [48.699799, 32.942849],
          [48.702481, 32.941239],
          [48.705322, 32.939217],
          [48.70784, 32.937656],
          [48.709316, 32.936459],
          [48.709419, 32.935264],
          [48.709736, 32.930344],
          [48.709753, 32.928093],
          [48.709778, 32.924824],
          [48.710037, 32.92041],
          [48.710468, 32.917374],
          [48.711003, 32.912224],
          [48.711761, 32.909192],
          [48.712902, 32.905646],
          [48.71426, 32.902982],
          [48.715786, 32.900223],
          [48.718196, 32.896627],
          [48.718481, 32.896201],
          [48.718581, 32.89602],
          [48.718758, 32.896249],
          [48.718952, 32.896501],
          [48.719322, 32.897176],
          [48.719555, 32.897603],
          [48.719887, 32.898154],
          [48.720051, 32.898385],
          [48.72049, 32.898567],
          [48.720928, 32.898658],
          [48.721256, 32.898612],
          [48.721857, 32.898567],
          [48.722462, 32.898521],
          [48.723228, 32.898522],
          [48.723995, 32.898709],
          [48.724167, 32.898795],
          [48.72509, 32.899257],
          [48.725857, 32.899627],
          [48.726517, 32.900455],
          [48.727177, 32.901191],
          [48.727837, 32.902204],
          [48.728603, 32.902984],
          [48.72921, 32.903676],
          [48.729759, 32.90395],
          [48.730743, 32.904092],
          [48.731838, 32.904184],
          [48.732716, 32.904091],
          [48.733151, 32.904],
          [48.733479, 32.903492],
          [48.733532, 32.902986],
          [48.733639, 32.902343],
          [48.733639, 32.902127],
          [48.733639, 32.90147],
          [48.733909, 32.900689],
          [48.734345, 32.899997],
          [48.734837, 32.899673],
          [48.735657, 32.899582],
          [48.736587, 32.899445],
          [48.737354, 32.899491],
          [48.738064, 32.899632],
          [48.73867, 32.899907],
          [48.739109, 32.900413],
          [48.739495, 32.901195],
          [48.739933, 32.902114],
          [48.740154, 32.902619],
          [48.740814, 32.903217],
          [48.741745, 32.903908],
          [48.742516, 32.904735],
          [48.743012, 32.905518],
          [48.743286, 32.906115],
          [48.743725, 32.906482],
          [48.744328, 32.906853],
          [48.745426, 32.907588],
          [48.745865, 32.908002],
          [48.745976, 32.908416],
          [48.745922, 32.908832],
          [48.745815, 32.90952],
          [48.745708, 32.91021],
          [48.745602, 32.910808],
          [48.745602, 32.91105],
          [48.745602, 32.911498],
          [48.74588, 32.912373],
          [48.746376, 32.91357],
          [48.747807, 32.915134],
          [48.748577, 32.916191],
          [48.750003, 32.916683],
          [48.750601, 32.916861],
          [48.751446, 32.917111],
          [48.753345, 32.918473],
          [48.754272, 32.919209],
          [48.754605, 32.919452],
          [48.755146, 32.919849],
          [48.755802, 32.920124],
          [48.756508, 32.920306],
          [48.757115, 32.920218],
          [48.75716, 32.920212],
          [48.758461, 32.920163],
          [48.759598, 32.919879],
          [48.761661, 32.919686],
          [48.762421, 32.919637],
          [48.76334, 32.919585],
          [48.76421, 32.91972],
          [48.765243, 32.919899],
          [48.766117, 32.920314],
          [48.766663, 32.920727],
          [48.767425, 32.921327],
          [48.767975, 32.922063],
          [48.768688, 32.922986],
          [48.768967, 32.923584],
          [48.769241, 32.924229],
          [48.769577, 32.925202],
          [48.769589, 32.926494],
          [48.769436, 32.927882],
          [48.769337, 32.928852],
          [48.768963, 32.929921],
          [48.768482, 32.930844],
          [48.768112, 32.931678],
          [48.767521, 32.932743],
          [48.766934, 32.933578],
          [48.766342, 32.934505],
          [48.765915, 32.935108],
          [48.765755, 32.935527],
          [48.765648, 32.935897],
          [48.765652, 32.936359],
          [48.765606, 32.937332],
          [48.765774, 32.938023],
          [48.765945, 32.938946],
          [48.766007, 32.939963],
          [48.765961, 32.940937],
          [48.766025, 32.941952],
          [48.76598, 32.943153],
          [48.765877, 32.943848],
          [48.765778, 32.94473],
          [48.765457, 32.94556],
          [48.765034, 32.946626],
          [48.764172, 32.947693],
          [48.76326, 32.948806],
          [48.762665, 32.949318],
          [48.76202, 32.949967],
          [48.761265, 32.950526],
          [48.760674, 32.951176],
          [48.760406, 32.951825],
          [48.7603, 32.952285],
          [48.760307, 32.952935],
          [48.760586, 32.953857],
          [48.761135, 32.95469],
          [48.761848, 32.955471],
          [48.762833, 32.956208],
          [48.764088, 32.956986],
          [48.764851, 32.957538],
          [48.765453, 32.957951],
          [48.765781, 32.958412],
          [48.766109, 32.958828],
          [48.766388, 32.959336],
          [48.766449, 32.960121],
          [48.766289, 32.960678],
          [48.765862, 32.961373],
          [48.765324, 32.962157],
          [48.764679, 32.962948],
          [48.764034, 32.963737],
          [48.76371, 32.964062],
          [48.763447, 32.964757],
          [48.762962, 32.965314],
          [48.762353, 32.965926],
          [48.762314, 32.965965],
          [48.762157, 32.966427],
          [48.762051, 32.966752],
          [48.761997, 32.96712],
          [48.762219, 32.967444],
          [48.76244, 32.967674],
          [48.762821, 32.967949],
          [48.76342, 32.968178],
          [48.763802, 32.968316],
          [48.76429, 32.968361],
          [48.764885, 32.968358],
          [48.764971, 32.968345],
          [48.765862, 32.968215],
          [48.766514, 32.968074],
          [48.767056, 32.967935],
          [48.76749, 32.967929],
          [48.768218, 32.967845],
          [48.768303, 32.967835],
          [48.769169, 32.967461],
          [48.769653, 32.967136],
          [48.770255, 32.966415],
          [48.770351, 32.9663],
          [48.770729, 32.965791],
          [48.770996, 32.965279],
          [48.771156, 32.964863],
          [48.77137, 32.964538],
          [48.771748, 32.964168],
          [48.772179, 32.963937],
          [48.77272, 32.963795],
          [48.773319, 32.96379],
          [48.773915, 32.963787],
          [48.773972, 32.963807],
          [48.77446, 32.963971],
          [48.775112, 32.964154],
          [48.775604, 32.964336],
          [48.775986, 32.964473],
          [48.776257, 32.964424],
          [48.77647, 32.964333],
          [48.776631, 32.963962],
          [48.776955, 32.963684],
          [48.777924, 32.962799],
          [48.778736, 32.962196],
          [48.779221, 32.961823],
          [48.779865, 32.961312],
          [48.780514, 32.960755],
          [48.78154, 32.960289],
          [48.782459, 32.95987],
          [48.783165, 32.959587],
          [48.784138, 32.959305],
          [48.784626, 32.959117],
          [48.78479, 32.959025],
          [48.785221, 32.958839],
          [48.785599, 32.958699],
          [48.786358, 32.958602],
          [48.786957, 32.958554],
          [48.787499, 32.958413],
          [48.788204, 32.958457],
          [48.789074, 32.958732],
          [48.790054, 32.959094],
          [48.790218, 32.959327],
          [48.790546, 32.959324],
          [48.790924, 32.959141],
          [48.791302, 32.958767],
          [48.791622, 32.958261],
          [48.792107, 32.957931],
          [48.79303, 32.957837],
          [48.794064, 32.957833],
          [48.794876, 32.957966],
          [48.795639, 32.958194],
          [48.796459, 32.958885],
          [48.797279, 32.959481],
          [48.797661, 32.959758],
          [48.798477, 32.959892],
          [48.799294, 32.959933],
          [48.799778, 32.959843],
          [48.800426, 32.959376],
          [48.800911, 32.959004],
          [48.801506, 32.958771],
          [48.802105, 32.958721],
          [48.802811, 32.958717],
          [48.803681, 32.958854],
          [48.80455, 32.959129],
          [48.805313, 32.959354],
          [48.805801, 32.959491],
          [48.806076, 32.959629],
          [48.806347, 32.959629],
          [48.806565, 32.959629],
          [48.806942, 32.959489],
          [48.806995, 32.959118],
          [48.807262, 32.958653],
          [48.807693, 32.958327],
          [48.808235, 32.958051],
          [48.80883, 32.957817],
          [48.809696, 32.957625],
          [48.810562, 32.95744],
          [48.811211, 32.957158],
          [48.811913, 32.956692],
          [48.812344, 32.956231],
          [48.812614, 32.955902],
          [48.813042, 32.955441],
          [48.813637, 32.954838],
          [48.814339, 32.954372],
          [48.815147, 32.953857],
          [48.815899, 32.953022],
          [48.81633, 32.952374],
          [48.816965, 32.951629],
          [48.817082, 32.951492],
          [48.817783, 32.950888],
          [48.818161, 32.950656],
          [48.81881, 32.950607],
          [48.819244, 32.9507],
          [48.819332, 32.95074],
          [48.819737, 32.950927],
          [48.820446, 32.951431],
          [48.821102, 32.951751],
          [48.821537, 32.95198],
          [48.822243, 32.951981],
          [48.822784, 32.951839],
          [48.823544, 32.951699],
          [48.824303, 32.951552],
          [48.824734, 32.951321],
          [48.825657, 32.951179],
          [48.826145, 32.951084],
          [48.826576, 32.950805],
          [48.827331, 32.950433],
          [48.827927, 32.950107],
          [48.828251, 32.949874],
          [48.828522, 32.949642],
          [48.828846, 32.949501],
          [48.829224, 32.949314],
          [48.829494, 32.949127],
          [48.829819, 32.948941],
          [48.830303, 32.948753],
          [48.830738, 32.948522],
          [48.830898, 32.948337],
          [48.831005, 32.948105],
          [48.831112, 32.947827],
          [48.831162, 32.947456],
          [48.831075, 32.947202],
          [48.831051, 32.947132],
          [48.830772, 32.946579],
          [48.830116, 32.945797],
          [48.82962, 32.94492],
          [48.829342, 32.944275],
          [48.829231, 32.943858],
          [48.829228, 32.943443],
          [48.829334, 32.943073],
          [48.829548, 32.942653],
          [48.829869, 32.942424],
          [48.83057, 32.941726],
          [48.831162, 32.941123],
          [48.8317, 32.940567],
          [48.832294, 32.940101],
          [48.83289, 32.939868],
          [48.833374, 32.939683],
          [48.833916, 32.939496],
          [48.834051, 32.939403],
          [48.834187, 32.939309],
          [48.834511, 32.939213],
          [48.835052, 32.939164],
          [48.835705, 32.939347],
          [48.836361, 32.939622],
          [48.837071, 32.940083],
          [48.837723, 32.940448],
          [48.838162, 32.940724],
          [48.838597, 32.940815],
          [48.838978, 32.940812],
          [48.839516, 32.940623],
          [48.839893, 32.940346],
          [48.840218, 32.940067],
          [48.840378, 32.939743],
          [48.840538, 32.939327],
          [48.840588, 32.938815],
          [48.840691, 32.938308],
          [48.840594, 32.937867],
          [48.84058, 32.937801],
          [48.840519, 32.937295],
          [48.840298, 32.936879],
          [48.840023, 32.936416],
          [48.839474, 32.935773],
          [48.838818, 32.935036],
          [48.838486, 32.934433],
          [48.838375, 32.934158],
          [48.838318, 32.933742],
          [48.838368, 32.933139],
          [48.838634, 32.932679],
          [48.838848, 32.932353],
          [48.838902, 32.932075],
          [48.839062, 32.931661],
          [48.839382, 32.931382],
          [48.839813, 32.930871],
          [48.840355, 32.930542],
          [48.840733, 32.930265],
          [48.841164, 32.930123],
          [48.841488, 32.929985],
          [48.842087, 32.929936],
          [48.842518, 32.929978],
          [48.842581, 32.930007],
          [48.843121, 32.930256],
          [48.843609, 32.930486],
          [48.844208, 32.930715],
          [48.844536, 32.930851],
          [48.845078, 32.930847],
          [48.845677, 32.930844],
          [48.846001, 32.930655],
          [48.846157, 32.930241],
          [48.846153, 32.929734],
          [48.846043, 32.929227],
          [48.846035, 32.929187],
          [48.845871, 32.9283],
          [48.845863, 32.927562],
          [48.84597, 32.927143],
          [48.846073, 32.926682],
          [48.846233, 32.926266],
          [48.846504, 32.926033],
          [48.846771, 32.925892],
          [48.847477, 32.925523],
          [48.848232, 32.92524],
          [48.848679, 32.925027],
          [48.84872, 32.925008],
          [48.849152, 32.924774],
          [48.849312, 32.924587],
          [48.849525, 32.924264],
          [48.849632, 32.923939],
          [48.849682, 32.923432],
          [48.849686, 32.923417],
          [48.849789, 32.923062],
          [48.849731, 32.922737],
          [48.849617, 32.922322],
          [48.849289, 32.921996],
          [48.848686, 32.921123],
          [48.848354, 32.920616],
          [48.848301, 32.920428],
          [48.848297, 32.920246],
          [48.848297, 32.919968],
          [48.848232, 32.918813],
          [48.848282, 32.918536],
          [48.848442, 32.918118],
          [48.848602, 32.917888],
          [48.84882, 32.917655],
          [48.848869, 32.917377],
          [48.849136, 32.916867],
          [48.849514, 32.91645],
          [48.850052, 32.916031],
          [48.850373, 32.915926],
          [48.850487, 32.915889],
          [48.850975, 32.915844],
          [48.85173, 32.915606],
          [48.852161, 32.915192],
          [48.852539, 32.914818],
          [48.852753, 32.914494],
          [48.852913, 32.913985],
          [48.852973, 32.913538],
          [48.853012, 32.913245],
          [48.853008, 32.912689],
          [48.853165, 32.911995],
          [48.853432, 32.911393],
          [48.853863, 32.910839],
          [48.854401, 32.910375],
          [48.855209, 32.909863],
          [48.855858, 32.909534],
          [48.856617, 32.909348],
          [48.857701, 32.90911],
          [48.85843, 32.909053],
          [48.858948, 32.909012],
          [48.860847, 32.908914],
          [48.862095, 32.908908],
          [48.862961, 32.90881],
          [48.863506, 32.908763],
          [48.864048, 32.908714],
          [48.864536, 32.908573],
          [48.864967, 32.908387],
          [48.865292, 32.908153],
          [48.865669, 32.907921],
          [48.865993, 32.907597],
          [48.86626, 32.907318],
          [48.866802, 32.907223],
          [48.867401, 32.907269],
          [48.867783, 32.907407],
          [48.868545, 32.907726],
          [48.869743, 32.908322],
          [48.870178, 32.90846],
          [48.871105, 32.908683],
          [48.871647, 32.908683],
          [48.872135, 32.908588],
          [48.872513, 32.9084],
          [48.872673, 32.908076],
          [48.872723, 32.907752],
          [48.872723, 32.907428],
          [48.872719, 32.907105],
          [48.872551, 32.906642],
          [48.872383, 32.906277],
          [48.872326, 32.905906],
          [48.872215, 32.905399],
          [48.872318, 32.904842],
          [48.872639, 32.904331],
          [48.872959, 32.903777],
          [48.873337, 32.90345],
          [48.873985, 32.903218],
          [48.874958, 32.902797],
          [48.87561, 32.902657],
          [48.876804, 32.90265],
          [48.877728, 32.902645],
          [48.878647, 32.902503],
          [48.879353, 32.902317],
          [48.880379, 32.901852],
          [48.881458, 32.901246],
          [48.882053, 32.901012],
          [48.882595, 32.900731],
          [48.883026, 32.900359],
          [48.883347, 32.900035],
          [48.883511, 32.899804],
          [48.883671, 32.899525],
          [48.883721, 32.898921],
          [48.883659, 32.89846],
          [48.883549, 32.898091],
          [48.883385, 32.897953],
          [48.883385, 32.897953],
          [48.883057, 32.897587],
          [48.882618, 32.897216],
          [48.882073, 32.896896],
          [48.881908, 32.896618],
          [48.881741, 32.896203],
          [48.881684, 32.895694],
          [48.88168, 32.895232],
          [48.881729, 32.894771],
          [48.881832, 32.894448],
          [48.881939, 32.894168],
          [48.882157, 32.893982],
          [48.882534, 32.893889],
          [48.88324, 32.893795],
          [48.883781, 32.893701],
          [48.884594, 32.893509],
          [48.885407, 32.893413],
          [48.885784, 32.893277],
          [48.886326, 32.893085],
          [48.88664, 32.892987],
          [48.886921, 32.892899],
          [48.887299, 32.892574],
          [48.887947, 32.89211],
          [48.888485, 32.891506],
          [48.888969, 32.891088],
          [48.8893, 32.89073],
          [48.889397, 32.890626],
          [48.890099, 32.89016],
          [48.891231, 32.889462],
          [48.892315, 32.888808],
          [48.893284, 32.888248],
          [48.894314, 32.887783],
          [48.895718, 32.88704],
          [48.896095, 32.886807],
          [48.896416, 32.886479],
          [48.897011, 32.886108],
          [48.898037, 32.88569],
          [48.89896, 32.88536],
          [48.899498, 32.885128],
          [48.899876, 32.884941],
          [48.900147, 32.884891],
          [48.900475, 32.884845],
          [48.900639, 32.884983],
          [48.90091, 32.885075],
          [48.901562, 32.885165],
          [48.902107, 32.88544],
          [48.902114, 32.885445],
          [48.902706, 32.885854],
          [48.903416, 32.88631],
          [48.904125, 32.886773],
          [48.904892, 32.887417],
          [48.905815, 32.887597],
          [48.906689, 32.888008],
          [48.907177, 32.888145],
          [48.908153, 32.888141],
          [48.908753, 32.888045],
          [48.90913, 32.887813],
          [48.909828, 32.887211],
          [48.910584, 32.886513],
          [48.911175, 32.886048],
          [48.911212, 32.886019],
          [48.911659, 32.885677],
          [48.911877, 32.885581],
          [48.912365, 32.88549],
          [48.912743, 32.88535],
          [48.913177, 32.885346],
          [48.913506, 32.885482],
          [48.914269, 32.885804],
          [48.915523, 32.886585],
          [48.915975, 32.886939],
          [48.916287, 32.887184],
          [48.917324, 32.887825],
          [48.918251, 32.888377],
          [48.919182, 32.889207],
          [48.920166, 32.889848],
          [48.921528, 32.890583],
          [48.922348, 32.89104],
          [48.923492, 32.891544],
          [48.924202, 32.892052],
          [48.924801, 32.892373],
          [48.925129, 32.892692],
          [48.925896, 32.893478],
          [48.926392, 32.894124],
          [48.926723, 32.89486],
          [48.926891, 32.895553],
          [48.927063, 32.896293],
          [48.927174, 32.896755],
          [48.927342, 32.89703],
          [48.927506, 32.897213],
          [48.92794, 32.897304],
          [48.928429, 32.897305],
          [48.929565, 32.897114],
          [48.930325, 32.896974],
          [48.93108, 32.896598],
          [48.93166, 32.896327],
          [48.931675, 32.89632],
          [48.932213, 32.89581],
          [48.932648, 32.895759],
          [48.933079, 32.895572],
          [48.933731, 32.895339],
          [48.93438, 32.895291],
          [48.935032, 32.895286],
          [48.935413, 32.895471],
          [48.935795, 32.895702],
          [48.935959, 32.895978],
          [48.936127, 32.896483],
          [48.936131, 32.896503],
          [48.936241, 32.897042],
          [48.936409, 32.89764],
          [48.936577, 32.897965],
          [48.936688, 32.898193],
          [48.937012, 32.898331],
          [48.937664, 32.898326],
          [48.938477, 32.898141],
          [48.939022, 32.898231],
          [48.939888, 32.89832],
          [48.940487, 32.898406],
          [48.941357, 32.898452],
          [48.942063, 32.898495],
          [48.942928, 32.898354],
          [48.943417, 32.898213],
          [48.943901, 32.897935],
          [48.944119, 32.897605],
          [48.944439, 32.897098],
          [48.944595, 32.896587],
          [48.94508, 32.895984],
          [48.94524, 32.89566],
          [48.945337, 32.895463],
          [48.9454, 32.895335],
          [48.945725, 32.895291],
          [48.94643, 32.895242],
          [48.947079, 32.895009],
          [48.947731, 32.895004],
          [48.948162, 32.894909],
          [48.948704, 32.894767],
          [48.949352, 32.894535],
          [48.95092, 32.893742],
          [48.951404, 32.893371],
          [48.951782, 32.893186],
          [48.952271, 32.893042],
          [48.952648, 32.892902],
          [48.952973, 32.892716],
          [48.953407, 32.892529],
          [48.953896, 32.892575],
          [48.954384, 32.89257],
          [48.955254, 32.8928],
          [48.956016, 32.893257],
          [48.956566, 32.893672],
          [48.956947, 32.894073],
          [48.957004, 32.894135],
          [48.957604, 32.894638],
          [48.958042, 32.895008],
          [48.958424, 32.89519],
          [48.958859, 32.895373],
          [48.959622, 32.895694],
          [48.960114, 32.896016],
          [48.960335, 32.896245],
          [48.96077, 32.896428],
          [48.961258, 32.896564],
          [48.961746, 32.896611],
          [48.962399, 32.896698],
          [48.962833, 32.896836],
          [48.963272, 32.897109],
          [48.963764, 32.89748],
          [48.964253, 32.897571],
          [48.964798, 32.897704],
          [48.965504, 32.897797],
          [48.966316, 32.897792],
          [48.966751, 32.897743],
          [48.967293, 32.897648],
          [48.967835, 32.897648],
          [48.968162, 32.897786],
          [48.968544, 32.898013],
          [48.968819, 32.89843],
          [48.968876, 32.898754],
          [48.968884, 32.899444],
          [48.968887, 32.899816],
          [48.969108, 32.900277],
          [48.96949, 32.900459],
          [48.970143, 32.900506],
          [48.971119, 32.900546],
          [48.971935, 32.900544],
          [48.972801, 32.90063],
          [48.9734, 32.900768],
          [48.97427, 32.900947],
          [48.974294, 32.900944],
          [48.974976, 32.900852],
          [48.975788, 32.90062],
          [48.976383, 32.900616],
          [48.976986, 32.900937],
          [48.977421, 32.901165],
          [48.977859, 32.901442],
          [48.978131, 32.901717],
          [48.978405, 32.902089],
          [48.978519, 32.902641],
          [48.978581, 32.903244],
          [48.978548, 32.903547],
          [48.978531, 32.903706],
          [48.978481, 32.904305],
          [48.978271, 32.904953],
          [48.978165, 32.905418],
          [48.978168, 32.905693],
          [48.978279, 32.905927],
          [48.9785, 32.906156],
          [48.978825, 32.906337],
          [48.979153, 32.90652],
          [48.979481, 32.906566],
          [48.979916, 32.906611],
          [48.980675, 32.906608],
          [48.981274, 32.906697],
          [48.98225, 32.906834],
          [48.982685, 32.906972],
          [48.983067, 32.907109],
          [48.983505, 32.907429],
          [48.983887, 32.907891],
          [48.984055, 32.908164],
          [48.984112, 32.90858],
          [48.984116, 32.908997],
          [48.98412, 32.909509],
          [48.98407, 32.909878],
          [48.983963, 32.910203],
          [48.98375, 32.910619],
          [48.983646, 32.910897],
          [48.98354, 32.91122],
          [48.983544, 32.91154],
          [48.983601, 32.91182],
          [48.983711, 32.912052],
          [48.983986, 32.912372],
          [48.984367, 32.912602],
          [48.984585, 32.912835],
          [48.985073, 32.912922],
          [48.985565, 32.912968],
          [48.98616, 32.912964],
          [48.986431, 32.912919],
          [48.986919, 32.912823],
          [48.987515, 32.912636],
          [48.98811, 32.912403],
          [48.988434, 32.912171],
          [48.989025, 32.911797],
          [48.989403, 32.911427],
          [48.98962, 32.911286],
          [48.989834, 32.911098],
          [48.990158, 32.911054],
          [48.99054, 32.911098],
          [48.991085, 32.911328],
          [48.992012, 32.911967],
          [48.992832, 32.912566],
          [48.993488, 32.912933],
          [48.993759, 32.913071],
          [48.994305, 32.913208],
          [48.994957, 32.913345],
          [48.995049, 32.913344],
          [48.996044, 32.913337],
          [48.997398, 32.913102],
          [48.99794, 32.91296],
          [48.998518, 32.912888],
          [48.998699, 32.912866],
          [49.000037, 32.913065],
          [49.001404, 32.913333],
          [49.002934, 32.914016],
          [49.003587, 32.914353],
          [49.004082, 32.914609],
          [49.005123, 32.915158],
          [49.005611, 32.915292],
          [49.005886, 32.915382],
          [49.006104, 32.915383],
          [49.006649, 32.915241],
          [49.007244, 32.914959],
          [49.007675, 32.914403],
          [49.007889, 32.913987],
          [49.008045, 32.913292],
          [49.008045, 32.912968],
          [49.007984, 32.912459],
          [49.007874, 32.911998],
          [49.007845, 32.91198],
          [49.007435, 32.911727],
          [49.006615, 32.911132],
          [49.005573, 32.910446],
          [49.00486, 32.909896],
          [49.004532, 32.909622],
          [49.004364, 32.909252],
          [49.004307, 32.908699],
          [49.004414, 32.908329],
          [49.004734, 32.907817],
          [49.00544, 32.907261],
          [49.006413, 32.906701],
          [49.006785, 32.906455],
          [49.00782, 32.90577],
          [49.008686, 32.905026],
          [49.009334, 32.904374],
          [49.009983, 32.903771],
          [49.010361, 32.903213],
          [49.010788, 32.902519],
          [49.010948, 32.902057],
          [49.011269, 32.901313],
          [49.0117, 32.900761],
          [49.012295, 32.900432],
          [49.012886, 32.900011],
          [49.012947, 32.899967],
          [49.013489, 32.899547],
          [49.013867, 32.899407],
          [49.014465, 32.89922],
          [49.0149, 32.899078],
          [49.015553, 32.89912],
          [49.016363, 32.899207],
          [49.016369, 32.899208],
          [49.017132, 32.899341],
          [49.017681, 32.899616],
          [49.018284, 32.900165],
          [49.019051, 32.900715],
          [49.020035, 32.901356],
          [49.020908, 32.901809],
          [49.022331, 32.90231],
          [49.023476, 32.902763],
          [49.024731, 32.903125],
          [49.025822, 32.903305],
          [49.026638, 32.903347],
          [49.027512, 32.903338],
          [49.028107, 32.903199],
          [49.029301, 32.902776],
          [49.030495, 32.902305],
          [49.031742, 32.901466],
          [49.033261, 32.900627],
          [49.03429, 32.899975],
          [49.035534, 32.899276],
          [49.036293, 32.898902],
          [49.037109, 32.898712],
          [49.037979, 32.89843],
          [49.038685, 32.898193],
          [49.039883, 32.898185],
          [49.041191, 32.898411],
          [49.042992, 32.89877],
          [49.044354, 32.899128],
          [49.045233, 32.899458],
          [49.045448, 32.899539],
          [49.046375, 32.899903],
          [49.047634, 32.900676],
          [49.048836, 32.90141],
          [49.049713, 32.90205],
          [49.04999, 32.902344],
          [49.050537, 32.902924],
          [49.051636, 32.904069],
          [49.052128, 32.904342],
          [49.052784, 32.904754],
          [49.053166, 32.904938],
          [49.053822, 32.905025],
          [49.054421, 32.905113],
          [49.054966, 32.905155],
          [49.055344, 32.905109],
          [49.055943, 32.904735],
          [49.05648, 32.904225],
          [49.057076, 32.903804],
          [49.057728, 32.903386],
          [49.058868, 32.903011],
          [49.06028, 32.902726],
          [49.061695, 32.902532],
          [49.063435, 32.902335],
          [49.06469, 32.902375],
          [49.06583, 32.902138],
          [49.066754, 32.901902],
          [49.067242, 32.901619],
          [49.067726, 32.901063],
          [49.06805, 32.900509],
          [49.068314, 32.899491],
          [49.068452, 32.898712],
          [49.06847, 32.898612],
          [49.06879, 32.897918],
          [49.06918, 32.897382],
          [49.069328, 32.897178],
          [49.070198, 32.896619],
          [49.070957, 32.896385],
          [49.07172, 32.896331],
          [49.072861, 32.896324],
          [49.073952, 32.89655],
          [49.074989, 32.896774],
          [49.076466, 32.897412],
          [49.078213, 32.89814],
          [49.078594, 32.898369],
          [49.079029, 32.898365],
          [49.08012, 32.898357],
          [49.080498, 32.897988],
          [49.080982, 32.897567],
          [49.08223, 32.896732],
          [49.08326, 32.896031],
          [49.084072, 32.89552],
          [49.084721, 32.895099],
          [49.085152, 32.894542],
          [49.085907, 32.893478],
          [49.086555, 32.892643],
          [49.086823, 32.892135],
          [49.087036, 32.891579],
          [49.087193, 32.890842],
          [49.087463, 32.890332],
          [49.088055, 32.889451],
          [49.088757, 32.888618],
          [49.089458, 32.887921],
          [49.09016, 32.887318],
          [49.090866, 32.886851],
          [49.091515, 32.886478],
          [49.092545, 32.885871],
          [49.093578, 32.885544],
          [49.095047, 32.885213],
          [49.09684, 32.884972],
          [49.098419, 32.88487],
          [49.099945, 32.884906],
          [49.100979, 32.885037],
          [49.102345, 32.885261],
          [49.10387, 32.885757],
          [49.105892, 32.886624],
          [49.107422, 32.887398],
          [49.108681, 32.888035],
          [49.109883, 32.888443],
          [49.110809, 32.888759],
          [49.111626, 32.888848],
          [49.112553, 32.888935],
          [49.113423, 32.888885],
          [49.114132, 32.88874],
          [49.114891, 32.888458],
          [49.115757, 32.887948],
          [49.117222, 32.887199],
          [49.11874, 32.886451],
          [49.120262, 32.885892],
          [49.121182, 32.885193],
          [49.122536, 32.884262],
          [49.123672, 32.88324],
          [49.124538, 32.882313],
          [49.125023, 32.881574],
          [49.125561, 32.880691],
          [49.125749, 32.880153],
          [49.125931, 32.87963],
          [49.126198, 32.878703],
          [49.126408, 32.877507],
          [49.126564, 32.876903],
          [49.127048, 32.876026],
          [49.127804, 32.875099],
          [49.128399, 32.874355],
          [49.129372, 32.873475],
          [49.130402, 32.87273],
          [49.131702, 32.871895],
          [49.133278, 32.871193],
          [49.135067, 32.870536],
          [49.137077, 32.869831],
          [49.139309, 32.869495],
          [49.14143, 32.869206],
          [49.142572, 32.869072],
          [49.143063, 32.869014],
          [49.145348, 32.869001],
          [49.147202, 32.868897],
          [49.149162, 32.868976],
          [49.150852, 32.869152],
          [49.152267, 32.869236],
          [49.15314, 32.869345],
          [49.153683, 32.869413],
          [49.154285, 32.8695],
          [49.154995, 32.869957],
          [49.156086, 32.870456],
          [49.15707, 32.870869],
          [49.1586, 32.871365],
          [49.159691, 32.871636],
          [49.160889, 32.871861],
          [49.161438, 32.872086],
          [49.16182, 32.872547],
          [49.162479, 32.87328],
          [49.162975, 32.873874],
          [49.163143, 32.874616],
          [49.163047, 32.874812],
          [49.162872, 32.875169],
          [49.162445, 32.876046],
          [49.162231, 32.87683],
          [49.162075, 32.877524],
          [49.161968, 32.877805],
          [49.162079, 32.878449],
          [49.162411, 32.878769],
          [49.16301, 32.879132],
          [49.163612, 32.879452],
          [49.164486, 32.879769],
          [49.165249, 32.880089],
          [49.166233, 32.880223],
          [49.166996, 32.880446],
          [49.167431, 32.880626],
          [49.167927, 32.881089],
          [49.168423, 32.881868],
          [49.168919, 32.882831],
          [49.169521, 32.88343],
          [49.169903, 32.883655],
          [49.170669, 32.883977],
          [49.171215, 32.883971],
          [49.171974, 32.883922],
          [49.172573, 32.88364],
          [49.173275, 32.883129],
          [49.174141, 32.882294],
          [49.175171, 32.881276],
          [49.176033, 32.880349],
          [49.176846, 32.879743],
          [49.178039, 32.878861],
          [49.179177, 32.87802],
          [49.180477, 32.877232],
          [49.181343, 32.876487],
          [49.182537, 32.87579],
          [49.183891, 32.87495],
          [49.185303, 32.874161],
          [49.187092, 32.872952],
          [49.188824, 32.871789],
          [49.190235, 32.870995],
          [49.191811, 32.870154],
          [49.193272, 32.869321],
          [49.194737, 32.868389],
          [49.195984, 32.867322],
          [49.19669, 32.867039],
          [49.197613, 32.866665],
          [49.198315, 32.866199],
          [49.198963, 32.86546],
          [49.199612, 32.864624],
          [49.200043, 32.864021],
          [49.200275, 32.863765],
          [49.201723, 32.863239],
          [49.202452, 32.86301],
          [49.202705, 32.86304],
          [49.202763, 32.863406],
          [49.20282, 32.863869],
          [49.203095, 32.864326],
          [49.203369, 32.864651],
          [49.203754, 32.864922],
          [49.2043, 32.864968],
          [49.205059, 32.864548],
          [49.206249, 32.863664],
          [49.207279, 32.862874],
          [49.208523, 32.861854],
          [49.210041, 32.86069],
          [49.211338, 32.859394],
          [49.212315, 32.858468],
          [49.21307, 32.857586],
          [49.213722, 32.857167],
          [49.2141, 32.856705],
          [49.214474, 32.855964],
          [49.214958, 32.854673],
          [49.215168, 32.853977],
          [49.215492, 32.853607],
          [49.216145, 32.853005],
          [49.217121, 32.852494],
          [49.217991, 32.852166],
          [49.218807, 32.85202],
          [49.219349, 32.851926],
          [49.219841, 32.852016],
          [49.22044, 32.852334],
          [49.2211, 32.852792],
          [49.221535, 32.853066],
          [49.222244, 32.853198],
          [49.223061, 32.853196],
          [49.223767, 32.853008],
          [49.224857, 32.852863],
          [49.225784, 32.853088],
          [49.22644, 32.853314],
          [49.227474, 32.853401],
          [49.228836, 32.853576],
          [49.229874, 32.853847],
          [49.230747, 32.853982],
          [49.231781, 32.854298],
          [49.232441, 32.854756],
          [49.233696, 32.855622],
          [49.234848, 32.856815],
          [49.235779, 32.857457],
          [49.236435, 32.85796],
          [49.236874, 32.858093],
          [49.237202, 32.85814],
          [49.237744, 32.858089],
          [49.239532, 32.856926],
          [49.240566, 32.856415],
          [49.240758, 32.856319],
          [49.241596, 32.8559],
          [49.242409, 32.855343],
          [49.243443, 32.854828],
          [49.244312, 32.854642],
          [49.245563, 32.85431],
          [49.246163, 32.854305],
          [49.246704, 32.854305],
          [49.24736, 32.854439],
          [49.247742, 32.854667],
          [49.247841, 32.854997],
          [49.247853, 32.855034],
          [49.247803, 32.855678],
          [49.24786, 32.856186],
          [49.24781, 32.856603],
          [49.248085, 32.856922],
          [49.248577, 32.857242],
          [49.249233, 32.857513],
          [49.25005, 32.857788],
          [49.251328, 32.857876],
          [49.252087, 32.857594],
          [49.252785, 32.85699],
          [49.253326, 32.856896],
          [49.253811, 32.856896],
          [49.254329, 32.857027],
          [49.254353, 32.857032],
          [49.25436, 32.857038],
          [49.254837, 32.85745],
          [49.255161, 32.857864],
          [49.255219, 32.858745],
          [49.255329, 32.85944],
          [49.255547, 32.860271],
          [49.255871, 32.86092],
          [49.256573, 32.861519],
          [49.257008, 32.861843],
          [49.257652, 32.861843],
          [49.258412, 32.861702],
          [49.258732, 32.861373],
          [49.259487, 32.86077],
          [49.260349, 32.859982],
          [49.261212, 32.859328],
          [49.261857, 32.859111],
          [49.262455, 32.858911],
          [49.264126, 32.858627],
          [49.265259, 32.858163],
          [49.26569, 32.857884],
          [49.266174, 32.857278],
          [49.266769, 32.85672],
          [49.267307, 32.856117],
          [49.267467, 32.855515],
          [49.267842, 32.854634],
          [49.268055, 32.854078],
          [49.268433, 32.853332],
          [49.268753, 32.85273],
          [49.269241, 32.85245],
          [49.269726, 32.852265],
          [49.271019, 32.852028],
          [49.272423, 32.851933],
          [49.273445, 32.851791],
          [49.274689, 32.851605],
          [49.275551, 32.851554],
          [49.276306, 32.851506],
          [49.277008, 32.851504],
          [49.277493, 32.851734],
          [49.277767, 32.852103],
          [49.278039, 32.852309],
          [49.278198, 32.85243],
          [49.278362, 32.852657],
          [49.278633, 32.852749],
          [49.2789, 32.852748],
          [49.279331, 32.852562],
          [49.279656, 32.8521],
          [49.279728, 32.851974],
          [49.280029, 32.851449],
          [49.280567, 32.850753],
          [49.281807, 32.8501],
          [49.283043, 32.84954],
          [49.283798, 32.849217],
          [49.284661, 32.84912],
          [49.285904, 32.849117],
          [49.286549, 32.849255],
          [49.287165, 32.849666],
          [49.287308, 32.849761],
          [49.28828, 32.850503],
          [49.289147, 32.850918],
          [49.289742, 32.851147],
          [49.290337, 32.851421],
          [49.291256, 32.851468],
          [49.292442, 32.851419],
          [49.293411, 32.851089],
          [49.294166, 32.850623],
          [49.29551, 32.849693],
          [49.296372, 32.849136],
          [49.296591, 32.849072],
          [49.29734, 32.848854],
          [49.298416, 32.848668],
          [49.299011, 32.848622],
          [49.29966, 32.84871],
          [49.299666, 32.848712],
          [49.300198, 32.848895],
          [49.300633, 32.849079],
          [49.301117, 32.849262],
          [49.301659, 32.849307],
          [49.30181, 32.849268],
          [49.302197, 32.849168],
          [49.302521, 32.848888],
          [49.302841, 32.848473],
          [49.303486, 32.847961],
          [49.304237, 32.847633],
          [49.305153, 32.847213],
          [49.306335, 32.846606],
          [49.307358, 32.845912],
          [49.308383, 32.845436],
          [49.308758, 32.845262],
          [49.30983, 32.844421],
          [49.310581, 32.843728],
          [49.310791, 32.843555],
          [49.312037, 32.842599],
          [49.312392, 32.842365],
          [49.312511, 32.842286],
          [49.313427, 32.841773],
          [49.314503, 32.841258],
          [49.315415, 32.840934],
          [49.316267, 32.840784],
          [49.317749, 32.840179],
          [49.317944, 32.839954],
          [49.318104, 32.839584],
          [49.318424, 32.839351],
          [49.318745, 32.839164],
          [49.319016, 32.839165],
          [49.319504, 32.839255],
          [49.319938, 32.839348],
          [49.323952, 32.839348],
          [49.325643, 32.839774],
          [49.325867, 32.839704],
          [49.326244, 32.839702],
          [49.326675, 32.839699],
          [49.327, 32.839841],
          [49.327648, 32.840068],
          [49.328068, 32.840382],
          [49.328083, 32.840394],
          [49.328732, 32.840669],
          [49.32938, 32.840851],
          [49.330028, 32.840943],
          [49.330242, 32.840924],
          [49.330567, 32.840896],
          [49.331268, 32.840847],
          [49.331699, 32.840706],
          [49.333313, 32.840286],
          [49.335144, 32.839958],
          [49.33638, 32.83963],
          [49.337135, 32.839581],
          [49.33762, 32.839581],
          [49.338215, 32.839765],
          [49.338539, 32.839992],
          [49.339085, 32.840504],
          [49.339737, 32.841149],
          [49.340092, 32.841556],
          [49.3405, 32.842025],
          [49.341316, 32.843044],
          [49.341804, 32.843415],
          [49.342129, 32.843643],
          [49.342888, 32.843969],
          [49.34343, 32.844104],
          [49.344025, 32.844243],
          [49.344563, 32.844426],
          [49.345051, 32.844653],
          [49.345539, 32.845025],
          [49.346405, 32.845253],
          [49.347111, 32.845481],
          [49.347169, 32.845493],
          [49.348026, 32.845664],
          [49.349052, 32.845757],
          [49.349594, 32.845753],
          [49.350079, 32.845662],
          [49.350292, 32.845383],
          [49.350506, 32.845149],
          [49.350716, 32.844872],
          [49.35104, 32.84464],
          [49.351414, 32.844451],
          [49.351952, 32.844264],
          [49.35265, 32.843987],
          [49.353512, 32.843845],
          [49.354805, 32.843797],
          [49.356911, 32.843793],
          [49.358311, 32.843834],
          [49.359821, 32.843734],
          [49.361008, 32.843639],
          [49.361546, 32.843639],
          [49.362518, 32.843776],
          [49.363384, 32.843959],
          [49.364086, 32.844144],
          [49.364735, 32.844326],
          [49.365372, 32.844507],
          [49.365383, 32.84451],
          [49.366032, 32.844647],
          [49.366467, 32.844646],
          [49.367165, 32.844552],
          [49.367596, 32.844363],
          [49.368215, 32.84407],
          [49.369259, 32.843575],
          [49.369739, 32.843201],
          [49.371128, 32.842132],
          [49.372741, 32.840945],
          [49.372894, 32.840831],
          [49.374981, 32.839391],
          [49.375195, 32.839157],
          [49.375462, 32.838928],
          [49.375511, 32.838649],
          [49.375468, 32.838321],
          [49.37545, 32.838184],
          [49.375393, 32.83786],
          [49.375443, 32.837489],
          [49.375656, 32.83712],
          [49.376026, 32.836471],
          [49.376556, 32.835774],
          [49.3773, 32.834938],
          [49.378155, 32.834289],
          [49.379597, 32.83313],
          [49.380505, 32.832432],
          [49.381573, 32.831733],
          [49.382591, 32.831176],
          [49.383019, 32.830848],
          [49.383659, 32.830342],
          [49.385098, 32.829041],
          [49.385578, 32.828712],
          [49.386223, 32.828479],
          [49.386618, 32.828435],
          [49.387081, 32.828384],
          [49.387779, 32.828151],
          [49.388851, 32.827732],
          [49.389212, 32.827485],
          [49.389866, 32.827037],
          [49.390881, 32.826293],
          [49.391621, 32.825412],
          [49.393753, 32.823602],
          [49.395882, 32.821606],
          [49.396732, 32.820769],
          [49.397636, 32.81998],
          [49.398113, 32.81956],
          [49.398598, 32.819375],
          [49.399239, 32.819237],
          [49.39967, 32.819141],
          [49.400047, 32.819141],
          [49.400692, 32.819233],
          [49.401123, 32.819184],
          [49.401604, 32.818904],
          [49.401867, 32.818443],
          [49.402393, 32.817699],
          [49.402924, 32.816909],
          [49.403664, 32.816075],
          [49.404301, 32.815335],
          [49.405415, 32.814357],
          [49.406368, 32.813199],
          [49.406841, 32.812459],
          [49.407158, 32.811901],
          [49.407257, 32.81144],
          [49.407356, 32.810928],
          [49.40762, 32.810417],
          [49.408096, 32.809769],
          [49.40889, 32.808979],
          [49.409843, 32.808005],
          [49.410317, 32.80717],
          [49.411217, 32.806244],
          [49.412331, 32.805313],
          [49.413235, 32.804432],
          [49.413921, 32.803597],
          [49.414391, 32.802811],
          [49.414654, 32.802299],
          [49.41502, 32.801604],
          [49.415226, 32.80091],
          [49.415432, 32.800354],
          [49.415741, 32.799519],
          [49.416474, 32.798267],
          [49.417046, 32.797016],
          [49.417469, 32.796555],
          [49.417942, 32.795903],
          [49.418152, 32.795486],
          [49.418255, 32.795253],
          [49.418304, 32.794791],
          [49.418293, 32.794189],
          [49.418182, 32.793915],
          [49.418068, 32.793546],
          [49.418167, 32.792988],
          [49.418213, 32.792477],
          [49.418526, 32.791691],
          [49.418999, 32.791088],
          [49.420109, 32.79002],
          [49.421757, 32.788814],
          [49.422447, 32.788207],
          [49.422707, 32.787839],
          [49.42313, 32.787235],
          [49.42339, 32.786819],
          [49.423542, 32.786262],
          [49.423638, 32.785568],
          [49.423847, 32.785012],
          [49.424214, 32.784408],
          [49.424736, 32.783528],
          [49.425205, 32.782743],
          [49.427105, 32.780656],
          [49.428318, 32.779309],
          [49.429115, 32.778889],
          [49.430019, 32.778474],
          [49.430927, 32.778145],
          [49.431946, 32.778094],
          [49.432373, 32.778003],
          [49.432694, 32.777862],
          [49.433331, 32.777539],
          [49.433807, 32.777123],
          [49.434284, 32.776748],
          [49.43449, 32.776286],
          [49.434643, 32.775821],
          [49.435215, 32.774758],
          [49.435631, 32.773922],
          [49.436424, 32.773183],
          [49.437111, 32.772671],
          [49.438143, 32.772079],
          [49.438973, 32.771603],
          [49.441948, 32.77007],
          [49.445721, 32.768117],
          [49.449341, 32.766766],
          [49.451099, 32.766204],
          [49.455208, 32.765042],
          [49.458984, 32.76355],
          [49.462921, 32.762108],
          [49.465576, 32.760808],
          [49.471561, 32.757416],
          [49.475758, 32.755559],
          [49.482902, 32.751468],
          [49.485514, 32.75004],
          [49.487667, 32.74902],
          [49.490799, 32.747157],
          [49.492499, 32.74614],
          [49.495228, 32.744507],
          [49.498142, 32.742512],
          [49.499312, 32.741836],
          [49.500001, 32.741342],
          [49.504312, 32.73829],
          [49.504314, 32.738288],
          [49.505283, 32.737179],
          [49.506691, 32.735045],
          [49.507167, 32.734584],
          [49.50798, 32.733712],
          [49.509668, 32.731892],
          [49.511823, 32.729572],
          [49.514556, 32.726604],
          [49.515533, 32.725181],
          [49.515906, 32.725181],
          [49.519793, 32.727402],
          [49.520626, 32.727682],
          [49.52146, 32.727682],
          [49.52146, 32.727962],
          [49.524239, 32.728233],
          [49.524516, 32.728513],
          [49.525349, 32.728513],
          [49.525906, 32.728793],
          [49.526463, 32.728793],
          [49.530349, 32.730184],
          [49.531182, 32.730184],
          [49.540906, 32.734897],
          [49.543962, 32.735737],
          [49.550072, 32.736287],
          [49.551462, 32.736016],
          [49.552019, 32.735737],
          [49.553129, 32.735737],
          [49.553686, 32.735457],
          [49.555905, 32.735457],
          [49.556186, 32.735177],
          [49.557019, 32.735457],
          [49.557572, 32.735177],
          [49.558129, 32.735457],
          [49.558406, 32.735177],
          [49.561742, 32.735177],
          [49.568129, 32.736287],
          [49.568962, 32.736567],
          [49.570075, 32.736567],
          [49.570352, 32.736847],
          [49.571742, 32.736847],
          [49.573128, 32.737407],
          [49.573962, 32.737407],
          [49.577575, 32.740179],
          [49.577852, 32.746012],
          [49.577575, 32.746292],
          [49.578962, 32.760179],
          [49.580075, 32.7624],
          [49.580909, 32.768513],
          [49.581185, 32.768793],
          [49.581742, 32.774066],
          [49.583408, 32.779348],
          [49.584518, 32.78185],
          [49.590632, 32.789073],
          [49.592851, 32.789904],
          [49.593685, 32.789904],
          [49.594242, 32.790184],
          [49.599798, 32.790184],
          [49.600355, 32.789904],
          [49.602298, 32.789904],
          [49.602575, 32.789624],
          [49.604242, 32.789624],
          [49.618131, 32.784901],
          [49.618468, 32.784788],
          [49.618964, 32.784622],
          [49.627298, 32.78185],
          [49.627578, 32.78157],
          [49.628688, 32.78157],
          [49.631464, 32.780739],
          [49.632578, 32.780739],
          [49.633131, 32.780459],
          [49.633688, 32.780459],
          [49.634521, 32.780179],
          [49.635631, 32.780179],
          [49.636468, 32.779899],
          [49.638688, 32.779899],
          [49.640634, 32.779629],
          [49.641188, 32.779348],
          [49.643968, 32.779348],
          [49.644244, 32.779069],
          [49.655078, 32.779069],
          [49.655634, 32.779348],
          [49.660077, 32.779348],
          [49.69258, 32.789624],
          [49.69369, 32.790184],
          [49.695914, 32.791015],
          [49.719246, 32.805732],
          [49.724803, 32.808513],
          [49.725636, 32.808513],
          [49.725636, 32.808793],
          [49.727303, 32.808793],
          [49.728136, 32.809073],
          [49.729803, 32.809073],
          [49.731193, 32.808513],
          [49.73175, 32.808243],
          [49.733137, 32.808243],
          [49.735083, 32.809344],
          [49.735207, 32.809874],
          [49.735916, 32.811574],
          [49.735916, 32.811854],
          [49.737026, 32.814346],
          [49.743969, 32.82101],
          [49.745359, 32.825182],
          [49.745359, 32.825741],
          [49.745359, 32.829904],
          [49.74425, 32.832965],
          [49.74397, 32.833245],
          [49.743693, 32.834075],
          [49.74397, 32.840739],
          [49.744803, 32.84352],
          [49.749249, 32.852405],
          [49.749526, 32.853515],
          [49.748973, 32.854906],
          [49.748693, 32.855737],
          [49.747303, 32.857958],
          [49.746193, 32.859077],
          [49.740083, 32.86463],
          [49.732303, 32.874906],
          [49.729526, 32.876016],
          [49.728413, 32.876016],
          [49.721746, 32.879628],
          [49.72036, 32.879628],
          [49.72008, 32.879908],
          [49.719526, 32.879908],
          [49.71897, 32.880468],
          [49.71397, 32.88407],
          [49.71286, 32.88463],
          [49.70619, 32.891294],
          [49.704247, 32.892684],
          [49.70369, 32.892684],
          [49.70369, 32.892964],
          [49.703137, 32.892964],
          [49.702856, 32.893244],
          [49.70008, 32.893795],
          [49.688134, 32.896847],
          [49.688134, 32.897126],
          [49.686747, 32.897127],
          [49.6823, 32.899077],
          [49.681467, 32.900187],
          [49.680913, 32.900738],
          [49.680913, 32.901569],
          [49.680357, 32.902409],
          [49.680913, 32.908521],
          [49.678536, 32.912792],
          [49.678134, 32.913514],
          [49.67758, 32.913794],
          [49.676744, 32.915299],
          [49.67619, 32.916295],
          [49.671467, 32.921018],
          [49.670634, 32.921018],
          [49.6673, 32.923239],
          [49.666744, 32.924079],
          [49.66591, 32.92546],
          [49.665357, 32.92713],
          [49.665077, 32.927961],
          [49.664244, 32.929352],
          [49.66369, 32.929352],
          [49.66341, 32.929632],
          [49.662577, 32.929632],
          [49.662577, 32.929911],
          [49.661467, 32.929912],
          [49.660479, 32.931008],
          [49.658967, 32.932683],
          [49.65869, 32.936575],
          [49.658411, 32.937406],
          [49.658134, 32.938516],
          [49.657693, 32.939453],
          [49.656693, 32.941576],
          [49.655911, 32.943239],
          [49.655077, 32.944349],
          [49.650801, 32.951376],
          [49.642876, 32.964398],
          [49.639876, 32.969327],
          [49.639521, 32.969911],
          [49.639242, 32.970504],
          [49.637301, 32.974633],
          [49.637577, 32.976854],
          [49.637577, 32.977414],
          [49.639521, 32.977685],
          [49.640077, 32.978244],
          [49.640634, 32.978245],
          [49.640911, 32.978515],
          [49.641744, 32.978795],
          [49.645354, 32.978795],
          [49.65091, 32.978244],
          [49.670357, 32.970741],
          [49.672024, 32.970741],
          [49.672577, 32.970461],
          [49.676467, 32.970461],
          [49.6773, 32.970741],
          [49.678414, 32.970741],
          [49.67869, 32.971021],
          [49.680357, 32.971292],
          [49.684246, 32.973522],
          [49.685357, 32.974904],
          [49.686467, 32.975744],
          [49.69369, 32.977685],
          [49.694803, 32.977965],
          [49.695357, 32.977685],
          [49.695913, 32.977965],
          [49.699803, 32.977965],
          [49.70508, 32.980186],
          [49.707856, 32.982407],
          [49.707856, 32.983247],
          [49.70758, 32.983518],
          [49.70758, 32.983797],
          [49.70758, 32.984357],
          [49.70758, 32.984637],
          [49.70647, 32.987409],
          [49.705913, 32.98852],
          [49.705913, 32.9888],
          [49.704803, 32.99019],
          [49.700913, 32.992962],
          [49.700357, 32.992962],
          [49.699803, 32.993522],
          [49.699246, 32.993522],
          [49.69897, 32.993802],
          [49.692023, 32.993802],
          [49.691747, 32.993522],
          [49.68619, 32.993522],
          [49.683413, 32.994073],
          [49.670633, 32.998795],
          [49.664523, 33.001855],
          [49.655077, 33.007408],
          [49.653687, 33.010189],
          [49.653967, 33.010469],
          [49.653967, 33.01158],
          [49.655634, 33.014072],
          [49.667857, 33.022135],
          [49.66869, 33.027408],
          [49.66591, 33.037132],
          [49.665634, 33.040193],
          [49.66591, 33.043245],
          [49.668967, 33.050188],
          [49.671743, 33.051299],
          [49.6798, 33.056852],
          [49.685633, 33.066305],
          [49.690913, 33.069908],
          [49.695636, 33.071298],
          [49.69647, 33.071298],
          [49.696746, 33.071578],
          [49.698413, 33.071578],
          [49.69897, 33.071858],
          [49.70119, 33.071858],
          [49.701746, 33.071578],
          [49.70369, 33.071578],
          [49.712579, 33.07519],
          [49.717859, 33.081582],
          [49.718834, 33.08227],
          [49.722026, 33.084354],
          [49.724246, 33.084354],
          [49.724802, 33.084074],
          [49.726192, 33.083523],
          [49.728136, 33.082693],
          [49.737302, 33.073799],
          [49.742582, 33.072138],
          [49.744526, 33.071578],
          [49.745635, 33.071578],
          [49.754806, 33.067966],
          [49.762026, 33.066025],
          [49.766192, 33.064355],
          [49.779529, 33.061854],
          [49.780362, 33.061854],
          [49.780915, 33.061574],
          [49.782862, 33.061574],
          [49.785639, 33.061023],
          [49.809808, 33.056581],
          [49.834531, 33.050188],
          [49.836474, 33.050188],
          [49.837308, 33.049908],
          [49.838974, 33.049908],
          [49.839808, 33.050188],
          [49.841198, 33.050188],
          [49.848974, 33.05352],
          [49.849808, 33.05352],
          [49.854254, 33.057132],
          [49.856474, 33.062414],
          [49.857031, 33.062965],
          [49.858974, 33.065745],
          [49.859808, 33.066856],
          [49.863698, 33.069077],
          [49.864811, 33.069077],
          [49.865364, 33.069357],
          [49.868977, 33.069357],
          [49.869811, 33.069077],
          [49.871477, 33.069077],
          [49.892587, 33.062414],
          [49.892867, 33.062134],
          [49.89481, 33.062134],
          [49.895644, 33.061854],
          [49.896754, 33.061854],
          [49.897587, 33.062134],
          [49.898977, 33.062134],
          [49.900087, 33.062414],
          [49.901477, 33.062414],
          [49.9037, 33.062965],
          [49.90481, 33.062965],
          [49.905367, 33.063244],
          [49.907034, 33.063244],
          [49.908423, 33.063804],
          [49.909534, 33.063804],
          [49.910367, 33.064075],
          [49.914257, 33.065746],
          [49.91481, 33.065745],
          [49.92148, 33.072138],
          [49.922033, 33.073249],
          [49.924257, 33.089636],
          [49.92398, 33.090187],
          [49.923147, 33.091577],
          [49.922867, 33.092417],
          [49.922033, 33.093239],
          [49.921757, 33.094078],
          [49.9212, 33.094358],
          [49.920367, 33.096299],
          [49.919813, 33.09713],
          [49.91898, 33.099631],
          [49.9187, 33.100471],
          [49.87953, 33.127973],
          [49.878977, 33.128244],
          [49.878977, 33.128524],
          [49.877864, 33.129634],
          [49.877587, 33.130194],
          [49.876754, 33.132135],
          [49.87703, 33.134085],
          [49.877587, 33.134907],
          [49.880364, 33.137968],
          [49.88842, 33.139629],
          [49.8887, 33.139909],
          [49.889254, 33.139909],
          [49.88981, 33.14074],
          [49.890367, 33.1438],
          [49.890644, 33.144631],
          [49.890367, 33.144911],
          [49.8887, 33.150753],
          [49.88842, 33.151863],
          [49.88231, 33.168521],
          [49.88092, 33.171853],
          [49.872587, 33.182688],
          [49.871197, 33.187689],
          [49.871477, 33.191301],
          [49.87703, 33.202966],
          [49.87731, 33.204077],
          [49.887587, 33.217972],
          [49.889534, 33.219633],
          [49.89481, 33.221024],
          [49.898977, 33.221854],
          [49.89981, 33.221854],
          [49.90509, 33.224084],
          [49.91509, 33.228527],
          [49.920646, 33.230188],
          [49.92509, 33.230188],
          [49.92898, 33.229917],
          [49.929813, 33.229637],
          [49.932033, 33.229637],
          [49.932866, 33.229357],
          [49.938423, 33.229357],
          [49.939256, 33.229077],
          [49.943703, 33.229077],
          [49.944256, 33.228806],
          [49.946479, 33.229917],
          [49.947036, 33.229917],
          [49.947313, 33.232689],
          [49.946203, 33.234359],
          [49.942313, 33.23714],
          [49.941756, 33.237691],
          [49.940089, 33.238801],
          [49.939536, 33.239081],
          [49.938979, 33.239081],
          [49.93898, 33.239361],
          [49.938423, 33.239361],
          [49.936199, 33.241582],
          [49.935923, 33.242413],
          [49.937589, 33.247144],
          [49.940923, 33.249085],
          [49.942313, 33.249085],
          [49.942869, 33.248805],
          [49.945089, 33.248805],
          [49.946479, 33.249916],
          [49.947312, 33.257409],
          [49.957036, 33.267413],
          [49.957869, 33.267693],
          [49.958979, 33.268524],
          [49.959536, 33.268803],
          [49.961479, 33.269914],
          [49.962036, 33.270474],
          [49.967312, 33.278247],
          [49.967869, 33.279078],
          [49.970926, 33.280748],
          [49.997039, 33.301577],
          [49.998705, 33.303527],
          [50.000092, 33.306028],
          [50.000092, 33.306859],
          [50.000372, 33.307419],
          [49.998425, 33.31047],
          [49.998149, 33.31103],
          [49.996759, 33.312971],
          [49.995925, 33.314641],
          [49.995649, 33.315472],
          [49.995372, 33.316303],
          [49.995925, 33.323246],
          [50.005925, 33.344083],
          [50.007038, 33.348805],
          [50.012592, 33.357969],
          [50.013705, 33.358809],
          [50.013982, 33.359359],
          [50.016205, 33.36019],
          [50.016762, 33.36047],
          [50.018428, 33.36019],
          [50.019538, 33.36159],
          [50.019815, 33.364081],
          [50.018148, 33.368532],
          [50.015371, 33.371584],
          [50.010648, 33.373805],
          [50.010092, 33.374365],
          [50.006482, 33.375755],
          [50.003425, 33.375755],
          [50.002871, 33.375475],
          [50.002315, 33.375475],
          [49.998705, 33.375195],
          [49.997592, 33.374915],
          [49.995925, 33.374915],
          [49.990369, 33.373534],
          [49.976758, 33.372144],
          [49.962036, 33.376306],
          [49.952036, 33.382698],
          [49.951479, 33.382978],
          [49.951479, 33.383528],
          [49.948702, 33.38658],
          [49.948422, 33.38714],
          [49.947869, 33.38742],
          [49.946756, 33.386029],
          [49.943702, 33.385478],
          [49.943422, 33.385199],
          [49.942036, 33.385199],
          [49.937312, 33.384088],
          [49.934533, 33.384919],
          [49.927866, 33.392141],
          [49.925366, 33.395753],
          [49.925089, 33.396312],
          [49.922589, 33.399364],
          [49.920366, 33.401305],
          [49.9087, 33.407417],
          [49.907309, 33.407697],
          [49.906476, 33.408248],
          [49.898699, 33.410469],
          [49.898419, 33.410749],
          [49.897309, 33.410749],
          [49.88231, 33.41575],
          [49.862587, 33.419641],
          [49.861473, 33.419641],
          [49.858697, 33.420472],
          [49.857863, 33.420472],
          [49.857307, 33.420752],
          [49.856474, 33.420752],
          [49.85592, 33.421032],
          [49.854807, 33.421032],
          [49.852863, 33.421583],
          [49.85203, 33.421583],
          [49.848973, 33.422973],
          [49.84814, 33.422973],
          [49.840917, 33.426033],
          [49.83925, 33.426864],
          [49.835364, 33.427695],
          [49.806751, 33.437978],
          [49.796471, 33.446591],
          [49.795637, 33.447981],
          [49.794251, 33.451032],
          [49.794251, 33.451303],
          [49.792028, 33.460756],
          [49.792304, 33.469369],
          [49.792584, 33.47381],
          [49.792028, 33.475201],
          [49.791751, 33.476582],
          [49.791471, 33.477422],
          [49.789528, 33.484084],
          [49.787304, 33.490476],
          [49.786471, 33.491867],
          [49.786471, 33.492417],
          [49.786194, 33.492697],
          [49.786194, 33.492977],
          [49.785638, 33.493537],
          [49.785638, 33.493808],
          [49.777028, 33.509363],
          [49.774528, 33.512974],
          [49.773694, 33.513814],
          [49.770914, 33.517696],
          [49.762304, 33.526309],
          [49.715911, 33.565201],
          [49.715911, 33.56548],
          [49.715358, 33.56576],
          [49.715078, 33.566031],
          [49.714802, 33.566311],
          [49.713412, 33.566871],
          [49.712302, 33.568532],
          [49.706468, 33.571583],
          [49.704802, 33.571872],
          [49.700635, 33.572143],
          [49.691189, 33.571312],
          [49.690355, 33.571033],
          [49.688689, 33.571033],
          [49.688412, 33.571312],
          [49.686189, 33.571312],
          [49.684245, 33.571583],
          [49.660632, 33.579925],
          [49.651466, 33.586308],
          [49.650352, 33.588808],
          [49.650352, 33.589368],
          [49.647299, 33.599651],
          [49.636466, 33.624369],
          [49.636186, 33.625199],
          [49.630909, 33.63048],
          [49.617853, 33.636312],
          [49.617296, 33.636312],
          [49.61313, 33.636872],
          [49.610629, 33.637422],
          [49.609796, 33.637422],
          [49.603406, 33.641033],
          [49.595906, 33.655487],
          [49.59563, 33.656597],
          [49.595349, 33.658538],
          [49.595073, 33.662149],
          [49.594796, 33.66409],
          [49.595073, 33.666591],
          [49.597296, 33.672982],
          [49.59813, 33.673533],
          [49.60063, 33.676033],
          [49.604239, 33.678814],
          [49.609519, 33.680484],
          [49.610629, 33.680484],
          [49.615353, 33.681865],
          [49.622296, 33.685205],
          [49.631742, 33.691868],
          [49.633129, 33.696869],
          [49.632853, 33.697429],
          [49.632576, 33.69881],
          [49.632296, 33.700209],
          [49.632576, 33.708541],
          [49.632852, 33.712983],
          [49.633129, 33.713813],
          [49.631186, 33.719645],
          [49.627576, 33.723256],
          [49.623409, 33.724366],
          [49.621186, 33.725477],
          [49.620076, 33.727706],
          [49.620909, 33.735208],
          [49.621186, 33.736869],
          [49.620909, 33.737149],
          [49.620629, 33.738539],
          [49.620353, 33.739649],
          [49.619242, 33.74187],
          [49.619242, 33.74215],
          [49.618962, 33.742421],
          [49.617576, 33.742421],
          [49.616739, 33.742981],
          [49.615906, 33.74298],
          [49.615629, 33.74326],
          [49.613963, 33.74326],
          [49.612019, 33.74187],
          [49.609239, 33.737429],
          [49.609239, 33.737149],
          [49.608406, 33.736589],
          [49.607573, 33.735759],
          [49.607019, 33.735479],
          [49.603686, 33.735479],
          [49.600906, 33.733818],
          [49.600353, 33.733538],
          [49.599796, 33.732428],
          [49.598686, 33.726596],
          [49.598406, 33.726036],
          [49.598686, 33.725476],
          [49.598406, 33.719645],
          [49.59674, 33.717424],
          [49.595073, 33.717424],
          [49.594796, 33.717153],
          [49.59174, 33.717424],
          [49.580073, 33.720764],
          [49.579516, 33.720764],
          [49.576183, 33.721035],
          [49.573683, 33.720484],
          [49.57285, 33.720484],
          [49.572293, 33.720205],
          [49.570073, 33.720204],
          [49.569793, 33.719925],
          [49.568683, 33.719925],
          [49.561183, 33.716864],
          [49.545627, 33.692147],
          [49.544794, 33.691317],
          [49.543644, 33.691564],
          [49.52838, 33.687963],
          [49.51187, 33.688523],
          [49.496584, 33.691998],
          [49.489418, 33.696213],
          [49.487378, 33.697412],
          [49.472089, 33.700884],
          [49.455547, 33.70201],
          [49.437565, 33.700239],
          [49.435898, 33.699649],
          [49.433398, 33.701319],
          [49.433955, 33.706871],
          [49.431455, 33.710762],
          [49.431732, 33.714643],
          [49.432012, 33.715754],
          [49.433678, 33.718534],
          [49.433678, 33.719094],
          [49.433678, 33.720204],
          [49.433121, 33.722425],
          [49.433398, 33.722705],
          [49.433398, 33.724366],
          [49.433678, 33.726036],
          [49.433122, 33.727426],
          [49.431178, 33.732978],
          [49.431455, 33.735758],
          [49.431178, 33.736318],
          [49.421732, 33.74465],
          [49.418955, 33.74465],
          [49.417565, 33.74521],
          [49.416732, 33.74521],
          [49.415898, 33.745761],
          [49.414509, 33.747701],
          [49.413398, 33.748812],
          [49.410065, 33.750482],
          [49.406175, 33.750482],
          [49.405619, 33.750202],
          [49.404785, 33.750202],
          [49.392009, 33.74326],
          [49.375896, 33.736869],
          [49.375342, 33.736318],
          [49.370619, 33.736318],
          [49.368952, 33.736589],
          [49.368672, 33.736869],
          [49.368119, 33.737148],
          [49.367562, 33.737428],
          [49.367286, 33.737699],
          [49.366729, 33.737699],
          [49.366452, 33.737979],
          [49.365619, 33.737979],
          [49.365339, 33.738259],
          [49.360619, 33.738259],
          [49.359506, 33.737428],
          [49.358672, 33.733258],
          [49.358952, 33.732427],
          [49.362839, 33.728816],
          [49.363672, 33.727977],
          [49.363672, 33.727426],
          [49.365062, 33.723815],
          [49.365339, 33.721315],
          [49.364506, 33.716034],
          [49.381729, 33.695758],
          [49.381729, 33.694927],
          [49.382009, 33.694648],
          [49.379229, 33.691037],
          [49.379229, 33.690757],
          [49.377286, 33.689926],
          [49.375896, 33.688536],
          [49.368952, 33.685485],
          [49.368119, 33.684925],
          [49.367286, 33.684925],
          [49.358672, 33.680204],
          [49.355619, 33.676873],
          [49.354506, 33.669921],
          [49.356173, 33.66576],
          [49.356729, 33.664369],
          [49.357839, 33.661309],
          [49.356173, 33.657147],
          [49.351449, 33.652977],
          [49.34673, 33.651595],
          [49.34645, 33.651315],
          [49.344506, 33.651315],
          [49.34395, 33.651036],
          [49.33784, 33.651036],
          [49.337283, 33.650756],
          [49.335063, 33.650756],
          [49.33423, 33.650476],
          [49.329506, 33.650476],
          [49.32895, 33.650756],
          [49.328116, 33.650756],
          [49.323673, 33.654087],
          [49.32284, 33.655197],
          [49.321726, 33.656867],
          [49.315617, 33.659088],
          [49.314503, 33.658817],
          [49.30617, 33.654647],
          [49.303117, 33.653816],
          [49.302837, 33.653536],
          [49.302003, 33.653536],
          [49.297003, 33.650476],
          [49.29645, 33.650205],
          [49.294504, 33.642423],
          [49.29478, 33.642143],
          [49.295337, 33.640202],
          [49.29895, 33.636591],
          [49.28867, 33.612144],
          [49.288114, 33.608813],
          [49.287837, 33.607983],
          [49.28756, 33.607703],
          [49.287366, 33.607227],
          [49.285061, 33.604372],
          [49.284227, 33.604092],
          [49.280894, 33.604092],
          [49.266724, 33.610483],
          [49.265614, 33.610754],
          [49.258671, 33.612975],
          [49.258114, 33.613255],
          [49.257281, 33.613255],
          [49.256724, 33.613535],
          [49.255614, 33.613535],
          [49.253667, 33.614365],
          [49.253114, 33.614365],
          [49.252834, 33.614645],
          [49.252281, 33.614645],
          [49.251724, 33.615205],
          [49.246724, 33.617146],
          [49.240891, 33.617146],
          [49.240334, 33.616866],
          [49.239224, 33.616866],
          [49.222835, 33.612144],
          [49.219501, 33.612144],
          [49.219221, 33.612424],
          [49.218111, 33.612424],
          [49.217278, 33.612704],
          [49.216721, 33.612704],
          [49.215611, 33.612975],
          [49.212001, 33.618256],
          [49.211168, 33.620757],
          [49.205335, 33.624368],
          [49.204502, 33.625198],
          [49.204221, 33.626589],
          [49.204501, 33.6302],
          [49.208388, 33.644364],
          [49.208668, 33.646314],
          [49.208388, 33.646874],
          [49.206444, 33.651315],
          [49.204778, 33.652426],
          [49.202001, 33.657147],
          [49.201445, 33.658817],
          [49.201165, 33.659648],
          [49.200055, 33.664089],
          [49.199778, 33.66659],
          [49.198945, 33.671591],
          [49.196164, 33.676033],
          [49.195611, 33.676313],
          [49.195611, 33.676872],
          [49.195055, 33.677423],
          [49.195055, 33.677703],
          [49.194778, 33.678533],
          [49.195055, 33.686315],
          [49.185611, 33.696588],
          [49.185331, 33.697698],
          [49.184222, 33.699369],
          [49.179775, 33.702149],
          [49.170608, 33.70409],
          [49.169775, 33.70409],
          [49.166998, 33.70465],
          [49.146718, 33.718263],
          [49.141719, 33.721594],
          [49.138385, 33.721594],
          [49.130052, 33.718263],
          [49.129495, 33.717983],
          [49.128385, 33.717152],
          [49.128109, 33.716864],
          [49.125606, 33.716033],
          [49.125329, 33.715753],
          [49.124495, 33.715753],
          [49.123662, 33.715203],
          [49.121162, 33.715203],
          [49.120052, 33.715203],
          [49.119496, 33.715482],
          [49.118662, 33.715482],
          [49.112552, 33.719644],
          [49.111719, 33.719924],
          [49.111439, 33.720204],
          [49.108939, 33.720204],
          [49.108662, 33.719924],
          [49.107829, 33.719924],
          [49.101439, 33.717152],
          [49.101162, 33.716864],
          [49.096162, 33.716593],
          [49.094496, 33.716864],
          [49.089496, 33.718534],
          [49.085882, 33.719644],
          [49.085606, 33.719924],
          [49.085049, 33.719924],
          [49.082549, 33.719924],
          [49.075606, 33.717983],
          [49.07366, 33.700759],
          [49.070883, 33.689366],
          [49.070326, 33.688815],
          [49.069493, 33.688815],
          [49.065883, 33.690756],
          [49.06505, 33.691316],
          [49.052826, 33.702429],
          [49.050883, 33.703259],
          [49.04977, 33.703259],
          [49.049213, 33.703539],
          [49.04616, 33.703539],
          [49.04588, 33.703259],
          [49.044213, 33.703259],
          [49.044213, 33.702979],
          [49.04338, 33.702979],
          [49.043103, 33.702699],
          [49.04227, 33.702699],
          [49.034213, 33.697148],
          [49.032547, 33.696317],
          [49.030047, 33.695478],
          [49.026157, 33.695478],
          [49.02588, 33.695757],
          [49.02338, 33.695757],
          [49.02199, 33.696317],
          [49.021157, 33.696317],
          [49.016714, 33.699088],
          [49.016157, 33.700479],
          [49.01588, 33.700759],
          [49.015604, 33.701869],
          [49.015324, 33.704649],
          [49.0106, 33.711041],
          [49.010324, 33.711871],
          [49.0106, 33.714923],
          [49.01338, 33.718813],
          [49.015604, 33.720763],
          [49.016157, 33.721314],
          [49.01699, 33.727425],
          [49.00949, 33.734097],
          [49.0056, 33.740199],
          [49.005324, 33.741589],
          [49.00699, 33.750201],
          [49.00699, 33.750481],
          [49.009214, 33.752151],
          [49.010047, 33.753262],
          [49.012823, 33.756033],
          [49.013104, 33.756313],
          [49.013937, 33.760763],
          [49.013657, 33.761314],
          [49.011714, 33.767705],
          [49.010047, 33.770206],
          [49.010047, 33.770756],
          [49.0031, 33.778258],
          [48.99949, 33.779368],
          [48.997824, 33.779648],
          [48.997267, 33.779928],
          [48.996157, 33.779928],
          [48.9956, 33.779648],
          [48.990877, 33.779648],
          [48.9881, 33.781869],
          [48.983657, 33.794091],
          [48.983101, 33.794931],
          [48.982824, 33.795761],
          [48.982544, 33.797982],
          [48.982267, 33.798262],
          [48.982544, 33.802983],
          [48.98421, 33.806594],
          [48.984675, 33.807054],
          [48.984767, 33.807704],
          [48.984767, 33.807984],
          [48.992824, 33.814926],
          [48.994214, 33.821868],
          [48.993657, 33.826038],
          [48.993933, 33.829929],
          [48.9931, 33.83187],
          [48.9931, 33.832429],
          [48.99199, 33.833539],
          [48.991433, 33.83437],
          [48.988657, 33.83548],
          [48.988377, 33.83576],
          [48.987824, 33.83576],
          [48.987543, 33.83604],
          [48.98699, 33.83604],
          [48.98671, 33.83632],
          [48.985877, 33.83632],
          [48.9856, 33.836591],
          [48.98171, 33.83687],
          [48.980324, 33.83687],
          [48.980324, 33.83715],
          [48.978933, 33.83715],
          [48.9781, 33.83743],
          [48.975877, 33.839651],
          [48.975877, 33.841312],
          [48.997267, 33.866596],
          [48.9981, 33.866596],
          [49.000047, 33.866596],
          [49.001157, 33.866036],
          [49.00449, 33.866036],
          [49.006157, 33.867147],
          [49.006157, 33.867427],
          [49.006433, 33.869376],
          [49.0056, 33.871037],
          [48.997823, 33.879098],
          [48.997547, 33.879929],
          [48.997267, 33.882709],
          [48.994766, 33.88826],
          [48.993933, 33.889371],
          [48.993933, 33.89021],
          [48.993657, 33.890481],
          [48.994766, 33.89271],
          [48.99949, 33.895202],
          [49.011433, 33.901593],
          [49.012266, 33.906043],
          [49.011713, 33.907153],
          [49.009766, 33.909094],
          [49.002823, 33.912154],
          [49.001156, 33.911594],
          [49.000323, 33.911594],
          [48.999818, 33.911324],
          [48.9981, 33.910764],
          [48.996713, 33.910764],
          [48.996433, 33.910484],
          [48.995324, 33.910484],
          [48.995047, 33.910204],
          [48.994213, 33.910204],
          [48.993657, 33.909933],
          [48.98421, 33.912425],
          [48.983933, 33.912714],
          [48.982824, 33.912714],
          [48.982267, 33.912984],
          [48.980324, 33.912714],
          [48.96782, 33.905483],
          [48.966434, 33.904933],
          [48.96421, 33.904933],
          [48.963377, 33.905203],
          [48.962821, 33.905483],
          [48.960601, 33.907424],
          [48.959487, 33.907984],
          [48.957544, 33.910204],
          [48.955597, 33.916875],
          [48.955877, 33.919935],
          [48.956154, 33.921325],
          [48.95643, 33.921876],
          [48.956154, 33.922426],
          [48.95643, 33.922706],
          [48.957544, 33.927427],
          [48.963654, 33.936598],
          [48.963934, 33.939378],
          [48.96171, 33.942709],
          [48.961434, 33.94437],
          [48.961987, 33.94993],
          [48.96532, 33.956041],
          [48.965877, 33.956601],
          [48.968653, 33.959652],
          [48.98699, 33.972153],
          [48.987823, 33.972153],
          [48.9981, 33.977705],
          [49.008656, 33.981595],
          [49.008933, 33.981875],
          [49.010046, 33.981875],
          [49.010323, 33.982155],
          [49.01199, 33.982155],
          [49.012823, 33.982425],
          [49.01588, 33.982426],
          [49.018936, 33.982155],
          [49.019489, 33.981875],
          [49.021156, 33.981875],
          [49.022823, 33.981324],
          [49.030322, 33.976874],
          [49.030603, 33.976315],
          [49.037269, 33.975213],
          [49.037546, 33.974934],
          [49.038103, 33.975213],
          [49.038659, 33.974934],
          [49.041713, 33.976315],
          [49.052549, 33.985765],
          [49.052826, 33.986045],
          [49.055326, 33.988545],
          [49.056435, 33.994097],
          [49.056159, 33.994656],
          [49.054216, 33.999928],
          [49.054492, 34.001598],
          [49.070882, 34.010209],
          [49.077572, 34.01471],
          [49.075882, 34.01465],
          [49.075049, 34.014379],
          [49.073939, 34.014379],
          [49.073105, 34.014099],
          [49.070325, 34.014099],
          [49.070049, 34.013819],
          [49.068102, 34.013819],
          [49.067825, 34.013539],
          [49.064216, 34.013269],
          [49.063382, 34.013539],
          [49.063102, 34.013819],
          [49.062269, 34.013819],
          [49.061992, 34.014099],
          [49.055602, 34.015769],
          [49.046712, 34.016039],
          [49.046712, 34.016319],
          [49.046159, 34.016319],
          [49.043379, 34.01715],
          [49.043379, 34.016879],
          [49.042546, 34.015769],
          [49.040879, 34.015209],
          [49.038936, 34.015209],
          [49.038379, 34.014929],
          [49.037826, 34.015209],
          [49.036712, 34.015209],
          [49.036159, 34.015489],
          [49.035326, 34.015489],
          [49.017823, 34.02187],
          [49.016713, 34.02187],
          [49.015879, 34.02215],
          [49.011156, 34.02215],
          [49.003933, 34.020489],
          [48.9856, 34.010768],
          [48.983657, 34.010208],
          [48.982543, 34.010208],
          [48.98199, 34.009929],
          [48.981157, 34.009929],
          [48.980876, 34.009649],
          [48.977823, 34.009098],
          [48.969487, 34.009098],
          [48.96921, 34.008818],
          [48.950044, 34.002708],
          [48.948377, 34.002707],
          [48.948097, 34.002987],
          [48.947544, 34.002987],
          [48.944764, 34.006318],
          [48.944487, 34.009928],
          [48.94421, 34.010208],
          [48.94421, 34.011048],
          [48.943654, 34.011878],
          [48.943377, 34.012149],
          [48.941987, 34.013268],
          [48.930874, 34.02187],
          [48.930597, 34.0241],
          [48.928097, 34.026871],
          [48.92254, 34.02827],
          [48.920321, 34.02854],
          [48.91615, 34.02854],
          [48.913651, 34.02743],
          [48.911707, 34.020209],
          [48.911984, 34.019099],
          [48.912264, 34.018539],
          [48.911707, 34.014099],
          [48.910318, 34.011048],
          [48.904207, 34.006318],
          [48.899208, 34.004377],
          [48.898094, 34.004377],
          [48.897541, 34.004097],
          [48.893651, 34.003267],
          [48.891151, 34.003267],
          [48.890041, 34.003547],
          [48.882818, 34.004657],
          [48.878928, 34.004657],
          [48.874204, 34.003547],
          [48.872538, 34.003547],
          [48.868928, 34.002707],
          [48.867538, 34.002707],
          [48.861148, 34.001047],
          [48.854481, 34.001877],
          [48.851705, 34.002707],
          [48.849205, 34.006047],
          [48.847262, 34.012988],
          [48.846982, 34.013268],
          [48.846148, 34.015488],
          [48.846148, 34.017438],
          [48.845872, 34.017709],
          [48.845872, 34.02604],
          [48.843928, 34.033821],
          [48.841705, 34.036321],
          [48.840872, 34.036601],
          [48.840315, 34.037151],
          [48.814758, 34.049932],
          [48.813925, 34.049932],
          [48.804202, 34.052712],
          [48.796979, 34.055763],
          [48.794479, 34.056873],
          [48.780036, 34.064103],
          [48.779202, 34.064934],
          [48.765589, 34.072434],
          [48.759756, 34.080494],
          [48.758366, 34.084655],
          [48.753089, 34.091876],
          [48.750032, 34.095216],
          [48.749756, 34.095486],
          [48.749199, 34.096046],
          [48.747809, 34.097716],
          [48.730866, 34.121047],
          [48.723643, 34.123268],
          [48.722533, 34.123268],
          [48.721976, 34.123548],
          [48.717533, 34.123548],
          [48.716976, 34.123268],
          [48.715866, 34.123268],
          [48.714753, 34.122708],
          [48.713366, 34.122158],
          [48.713086, 34.121327],
          [48.7117, 34.116318],
          [48.715033, 34.105487],
          [48.715309, 34.104097],
          [48.715586, 34.103267],
          [48.715033, 34.097986],
          [48.71281, 34.094656],
          [48.709477, 34.091876],
          [48.68503, 34.083545],
          [48.680587, 34.081595],
          [48.67225, 34.079095],
          [48.66725, 34.078824],
          [48.668007, 34.078729],
          [48.661897, 34.078729],
          [48.659397, 34.079289],
          [48.657654, 34.07606],
          [48.647454, 34.078367],
          [48.646974, 34.082155],
          [48.634194, 34.088545],
          [48.633917, 34.089096],
          [48.633361, 34.089096],
          [48.625861, 34.095486],
          [48.624751, 34.095486],
          [48.624471, 34.095766],
          [48.623361, 34.095766],
          [48.621971, 34.096316],
          [48.617251, 34.096046],
          [48.614471, 34.093825],
          [48.614194, 34.093545],
          [48.599471, 34.070213],
          [48.589471, 34.064383],
          [48.588638, 34.064383],
          [48.587525, 34.063823],
          [48.586415, 34.063823],
          [48.585858, 34.063543],
          [48.581691, 34.063543],
          [48.580858, 34.063263],
          [48.575581, 34.063263],
          [48.574468, 34.063543],
          [48.573082, 34.063543],
          [48.567248, 34.064933],
          [48.565025, 34.065484],
          [48.564192, 34.065484],
          [48.560302, 34.066594],
          [48.559748, 34.067154],
          [48.556692, 34.069654],
          [48.553079, 34.076315],
          [48.552802, 34.077434],
          [48.550025, 34.091596],
          [48.549468, 34.094105],
          [48.548912, 34.095486],
          [48.548912, 34.096596],
          [48.546969, 34.100486],
          [48.545858, 34.101876],
          [48.545025, 34.102156],
          [48.541412, 34.104936],
          [48.541412, 34.105216],
          [48.540579, 34.105487],
          [48.536412, 34.107436],
          [48.534469, 34.107707],
          [48.526968, 34.107156],
          [48.525855, 34.106597],
          [48.521412, 34.100766],
          [48.519745, 34.093825],
          [48.519189, 34.087435],
          [48.518632, 34.066043],
          [48.518912, 34.065213],
          [48.518912, 34.063263],
          [48.519189, 34.060483],
          [48.520022, 34.057153],
          [48.520299, 34.056873],
          [48.519466, 34.050762],
          [48.511412, 34.044101],
          [48.483076, 34.010207],
          [48.481133, 34.009097],
          [48.476133, 34.006877],
          [48.475299, 34.006877],
          [48.47502, 34.006597],
          [48.474186, 34.006597],
          [48.473633, 34.006317],
          [48.472237, 34.006317],
          [48.471966, 34.006317],
          [48.471409, 34.006317],
          [48.470576, 34.006317],
          [48.460576, 34.008547],
          [48.45613, 34.008817],
          [48.45502, 34.008546],
          [48.445573, 34.008547],
          [48.44474, 34.008267],
          [48.443353, 34.008267],
          [48.420017, 34.002707],
          [48.41585, 34.002706],
          [48.41474, 34.002427],
          [48.410574, 34.002156],
          [48.405574, 34.002156],
          [48.40446, 34.001876],
          [48.398907, 34.001876],
          [48.397794, 34.001596],
          [48.394461, 34.001596],
          [48.39335, 34.001316],
          [48.390851, 34.001316],
          [48.388351, 34.001316],
          [48.38497, 34.001316],
          [48.379184, 34.001316],
          [48.352238, 34.006877],
          [48.333348, 34.014377],
          [48.332791, 34.014648],
          [48.332515, 34.014928],
          [48.331958, 34.014928],
          [48.325015, 34.016598],
          [48.323068, 34.016598],
          [48.322792, 34.016877],
          [48.304735, 34.021318],
          [48.304178, 34.022708],
          [48.303902, 34.026599],
          [48.303625, 34.026869],
          [48.303902, 34.028539],
          [48.304735, 34.03799],
          [48.304735, 34.0391],
          [48.305011, 34.04132],
          [48.305845, 34.054652],
          [48.305568, 34.055212],
          [48.305292, 34.055491],
          [48.305292, 34.055762],
          [48.304458, 34.056602],
          [48.303068, 34.056872],
          [48.302512, 34.056602],
          [48.301125, 34.056602],
          [48.299458, 34.056042],
          [48.298345, 34.056042],
          [48.293068, 34.054932],
          [48.290569, 34.054932],
          [48.289735, 34.054652],
          [48.288069, 34.054652],
          [48.286679, 34.054372],
          [48.275289, 34.054372],
          [48.274179, 34.054092],
          [48.255842, 34.051592],
          [48.253899, 34.050762],
          [48.246122, 34.049101],
          [48.240566, 34.049101],
          [48.235842, 34.050761],
          [48.234453, 34.051321],
          [48.230842, 34.054372],
          [48.217786, 34.065763],
          [48.215286, 34.067153],
          [48.214729, 34.067433],
          [48.193063, 34.078823],
          [48.191673, 34.079094],
          [48.188063, 34.079094],
          [48.186953, 34.079654],
          [48.186119, 34.080493],
          [48.17723, 34.086044],
          [48.17695, 34.086874],
          [48.176397, 34.087434],
          [48.17473, 34.091875],
          [48.169397, 34.102757],
          [48.168896, 34.103265],
          [48.168616, 34.104096],
          [48.167783, 34.104935],
          [48.167507, 34.105766],
          [48.153893, 34.120767],
          [48.152783, 34.122156],
          [48.144727, 34.133818],
          [48.137784, 34.141047],
          [48.137227, 34.141327],
          [48.13695, 34.141598],
          [48.136393, 34.141598],
          [48.135837, 34.142157],
          [48.12945, 34.144937],
          [48.12917, 34.145217],
          [48.128617, 34.145217],
          [48.12806, 34.145217],
          [48.127504, 34.145488],
          [48.126393, 34.145488],
          [48.125837, 34.145768],
          [48.123893, 34.145768],
          [48.123337, 34.145488],
          [48.11306, 34.145488],
          [48.112227, 34.145768],
          [48.110837, 34.145768],
          [48.10806, 34.146598],
          [48.100837, 34.153548],
          [48.093337, 34.167158],
          [48.09167, 34.168548],
          [48.090004, 34.170209],
          [48.082224, 34.176048],
          [48.082224, 34.176328],
          [48.081762, 34.176693],
          [48.081114, 34.177159],
          [48.072224, 34.194659],
          [48.072224, 34.195489],
          [48.070557, 34.199099],
          [48.055834, 34.20993],
          [48.054724, 34.2116],
          [48.053891, 34.212719],
          [48.047778, 34.215489],
          [48.046668, 34.215489],
          [48.046388, 34.215769],
          [48.045278, 34.215769],
          [48.044444, 34.216049],
          [48.043335, 34.216049],
          [48.042778, 34.216329],
          [48.038888, 34.216879],
          [48.028611, 34.216879],
          [48.028331, 34.217159],
          [48.027221, 34.217159],
          [48.026945, 34.216879],
          [48.021665, 34.216879],
          [48.021111, 34.217159],
          [48.019164, 34.217159],
          [48.013055, 34.219659],
          [48.011941, 34.22021],
          [48.010555, 34.220769],
          [48.007775, 34.220769],
          [48.007498, 34.221049],
          [48.005555, 34.221049],
          [48.004722, 34.220769],
          [48.000555, 34.220769],
          [47.999997, 34.218371],
          [47.985275, 34.222159],
          [47.972775, 34.229108],
          [47.971385, 34.229379],
          [47.971108, 34.229379],
          [47.970552, 34.229379],
          [47.969719, 34.229379],
          [47.964719, 34.231329],
          [47.944995, 34.244099],
          [47.943605, 34.244379],
          [47.938606, 34.245769],
          [47.935549, 34.245769],
          [47.934995, 34.245489],
          [47.931662, 34.245489],
          [47.931106, 34.245209],
          [47.923049, 34.244099],
          [47.911383, 34.240769],
          [47.908326, 34.239939],
          [47.907773, 34.239939],
          [47.904993, 34.238829],
          [47.904159, 34.238829],
          [47.903326, 34.238549],
          [47.900273, 34.238549],
          [47.900273, 34.238829],
          [47.889993, 34.250209],
          [47.889993, 34.250769],
          [47.887493, 34.254099],
          [47.887493, 34.254379],
          [47.887216, 34.254659],
          [47.886659, 34.255209],
          [47.886383, 34.255489],
          [47.874713, 34.263548],
          [47.852213, 34.274658],
          [47.848323, 34.278548],
          [47.84777, 34.279658],
          [47.847213, 34.279938],
          [47.846103, 34.282438],
          [47.846103, 34.283268],
          [47.839157, 34.299107],
          [47.839157, 34.299657],
          [47.838046, 34.303827],
          [47.833047, 34.318826],
          [47.828047, 34.323826],
          [47.809157, 34.333546],
          [47.80499, 34.334656],
          [47.796654, 34.336605],
          [47.784987, 34.340765],
          [47.780544, 34.342994],
          [47.766097, 34.353265],
          [47.765821, 34.353544],
          [47.765264, 34.353544],
          [47.763041, 34.354934],
          [47.761931, 34.355494],
          [47.731931, 34.372712],
          [47.730818, 34.372712],
          [47.729151, 34.373272],
          [47.728041, 34.373272],
          [47.725261, 34.374102],
          [47.723874, 34.374102],
          [47.708595, 34.377712],
          [47.705261, 34.377712],
          [47.704705, 34.377992],
          [47.701651, 34.377992],
          [47.701095, 34.378271],
          [47.700261, 34.378271],
          [47.699985, 34.377992],
          [47.696928, 34.376602],
          [47.695262, 34.375492],
          [47.694985, 34.375212],
          [47.693038, 34.373552],
          [47.692762, 34.373272],
          [47.679982, 34.359383],
          [47.679149, 34.356053],
          [47.678872, 34.351053],
          [47.679149, 34.349384],
          [47.679429, 34.347714],
          [47.679705, 34.346324],
          [47.680539, 34.342435],
          [47.680815, 34.341604],
          [47.681095, 34.340215],
          [47.683315, 34.332715],
          [47.683315, 34.332435],
          [47.683315, 34.329935],
          [47.683595, 34.329385],
          [47.683595, 34.328546],
          [47.683595, 34.327436],
          [47.697762, 34.289657],
          [47.698039, 34.289098],
          [47.697762, 34.287437],
          [47.697485, 34.282717],
          [47.695262, 34.277438],
          [47.694428, 34.276048],
          [47.651926, 34.237438],
          [47.649983, 34.235489],
          [47.649703, 34.234938],
          [47.640536, 34.227158],
          [47.639703, 34.227158],
          [47.638609, 34.226566],
          [47.629426, 34.221599],
          [47.628036, 34.221599],
          [47.62748, 34.221319],
          [47.622203, 34.221319],
          [47.62137, 34.221599],
          [47.619703, 34.221599],
          [47.618036, 34.222158],
          [47.614703, 34.222429],
          [47.614146, 34.222158],
          [47.61248, 34.222158],
          [47.594423, 34.213268],
          [47.57859, 34.196598],
          [47.576367, 34.193548],
          [47.564977, 34.182148],
          [47.560534, 34.180208],
          [47.559424, 34.180208],
          [47.557754, 34.179657],
          [47.555254, 34.179657],
          [47.554144, 34.179378],
          [47.552477, 34.179378],
          [47.551367, 34.179657],
          [47.547477, 34.179928],
          [47.529144, 34.185208],
          [47.520808, 34.184657],
          [47.518031, 34.184657],
          [47.511641, 34.184657],
          [47.510808, 34.184937],
          [47.501918, 34.186318],
          [47.499698, 34.186318],
          [47.499141, 34.186598],
          [47.497751, 34.186598],
          [47.497198, 34.186318],
          [47.488308, 34.182988],
          [47.486085, 34.181318],
          [47.483862, 34.179377],
          [47.483585, 34.178818],
          [47.479418, 34.172708],
          [47.476642, 34.158817],
          [47.476642, 34.154656],
          [47.488865, 34.122435],
          [47.489142, 34.121875],
          [47.488032, 34.115485],
          [47.486919, 34.113544],
          [47.486919, 34.113265],
          [47.482475, 34.110485],
          [47.481642, 34.110485],
          [47.478862, 34.109654],
          [47.477475, 34.109654],
          [47.476918, 34.109374],
          [47.475809, 34.109374],
          [47.473028, 34.108535],
          [47.467475, 34.107705],
          [47.453585, 34.106044],
          [47.442472, 34.103264],
          [47.433862, 34.099374],
          [47.433582, 34.098823],
          [47.432196, 34.096594],
          [47.431916, 34.096314],
          [47.429139, 34.089653],
          [47.428863, 34.088822],
          [47.428122, 34.087432],
          [47.428029, 34.087152],
          [47.426639, 34.084373],
          [47.424139, 34.076312],
          [47.425526, 34.074652],
          [47.423583, 34.068261],
          [47.423306, 34.066041],
          [47.423583, 34.064651],
          [47.42636, 34.060481],
          [47.432473, 34.055489],
          [47.432749, 34.05465],
          [47.434973, 34.05159],
          [47.436083, 34.049379],
          [47.436083, 34.048819],
          [47.437196, 34.046039],
          [47.440806, 34.041039],
          [47.441083, 34.040479],
          [47.442473, 34.038818],
          [47.443029, 34.038268],
          [47.445529, 34.035208],
          [47.445249, 34.031038],
          [47.448586, 34.026597],
          [47.449139, 34.026597],
          [47.449696, 34.026037],
          [47.458306, 34.022147],
          [47.459696, 34.020486],
          [47.463586, 34.011315],
          [47.463586, 34.010765],
          [47.464419, 34.009095],
          [47.466642, 34.007705],
          [47.467476, 34.007705],
          [47.473029, 34.010485],
          [47.474976, 34.006044],
          [47.476362, 34.004374],
          [47.476919, 34.004374],
          [47.477196, 34.004094],
          [47.478862, 34.004094],
          [47.479142, 34.003815],
          [47.480809, 34.003264],
          [47.481362, 34.001874],
          [47.481362, 33.999924],
          [47.481642, 33.992703],
          [47.484142, 33.988813],
          [47.488309, 33.984652],
          [47.496085, 33.979651],
          [47.498032, 33.977151],
          [47.498309, 33.976311],
          [47.498585, 33.975761],
          [47.498309, 33.972981],
          [47.497475, 33.97187],
          [47.492475, 33.964929],
          [47.492752, 33.963539],
          [47.493309, 33.959928],
          [47.494142, 33.958538],
          [47.493032, 33.956598],
          [47.492752, 33.956318],
          [47.492475, 33.954368],
          [47.493866, 33.952148],
          [47.492752, 33.948537],
          [47.487476, 33.946316],
          [47.485532, 33.946316],
          [47.483029, 33.945757],
          [47.481642, 33.944926],
          [47.480253, 33.943536],
          [47.479976, 33.942146],
          [47.480253, 33.942146],
          [47.480529, 33.941875],
          [47.481086, 33.941875],
          [47.485532, 33.939095],
          [47.486086, 33.937425],
          [47.485809, 33.936595],
          [47.486086, 33.936315],
          [47.485532, 33.934645],
          [47.482196, 33.931314],
          [47.482196, 33.930484],
          [47.482196, 33.928543],
          [47.482476, 33.927704],
          [47.482752, 33.926873],
          [47.483862, 33.924932],
          [47.489699, 33.920482],
          [47.490809, 33.920202],
          [47.491919, 33.919372],
          [47.502755, 33.91271],
          [47.506365, 33.90854],
          [47.506642, 33.90771],
          [47.506922, 33.90715],
          [47.506089, 33.90465],
          [47.499976, 33.900759],
          [47.499419, 33.9002],
          [47.498586, 33.897148],
          [47.498866, 33.896869],
          [47.498866, 33.896038],
          [47.500252, 33.894368],
          [47.506089, 33.887986],
          [47.503589, 33.879366],
          [47.503309, 33.877145],
          [47.503589, 33.876585],
          [47.503309, 33.875204],
          [47.503032, 33.873534],
          [47.503309, 33.872984],
          [47.503032, 33.872704],
          [47.502756, 33.867983],
          [47.503813, 33.86452],
          [47.505809, 33.857981],
          [47.505809, 33.857701],
          [47.505809, 33.857431],
          [47.506089, 33.857142],
          [47.506365, 33.856311],
          [47.506642, 33.855481],
          [47.507756, 33.85326],
          [47.508032, 33.842419],
          [47.509976, 33.837427],
          [47.514699, 33.832146],
          [47.513866, 33.828256],
          [47.513309, 33.827425],
          [47.511366, 33.825475],
          [47.511089, 33.824925],
          [47.506366, 33.822144],
          [47.504976, 33.822144],
          [47.504423, 33.822424],
          [47.502476, 33.822424],
          [47.499699, 33.822975],
          [47.494419, 33.825475],
          [47.493309, 33.825475],
          [47.490533, 33.826315],
          [47.488586, 33.826315],
          [47.488033, 33.826035],
          [47.487199, 33.826035],
          [47.477476, 33.820474],
          [47.474143, 33.816872],
          [47.47303, 33.815762],
          [47.472476, 33.814643],
          [47.464973, 33.808531],
          [47.46164, 33.807141],
          [47.457473, 33.8052],
          [47.454973, 33.80159],
          [47.45414, 33.798809],
          [47.453587, 33.794928],
          [47.45442, 33.792147],
          [47.45442, 33.791037],
          [47.454697, 33.788257],
          [47.45442, 33.784646],
          [47.452473, 33.783256],
          [47.45164, 33.783256],
          [47.451363, 33.782976],
          [47.449697, 33.782976],
          [47.44942, 33.783256],
          [47.448307, 33.783256],
          [47.44803, 33.783535],
          [47.44553, 33.784646],
          [47.444697, 33.784646],
          [47.444417, 33.784926],
          [47.443307, 33.785196],
          [47.441084, 33.781035],
          [47.441363, 33.780755],
          [47.443583, 33.777144],
          [47.444417, 33.776585],
          [47.444417, 33.776314],
          [47.447197, 33.772703],
          [47.446363, 33.770202],
          [47.44553, 33.769092],
          [47.438307, 33.764091],
          [47.437473, 33.764091],
          [47.437197, 33.763811],
          [47.436084, 33.763811],
          [47.43553, 33.764091],
          [47.434697, 33.764091],
          [47.427751, 33.766591],
          [47.426361, 33.766591],
          [47.426361, 33.766312],
          [47.425251, 33.765201],
          [47.423584, 33.762701],
          [47.423861, 33.76159],
          [47.42414, 33.75992],
          [47.424417, 33.75964],
          [47.423584, 33.755199],
          [47.413584, 33.743806],
          [47.413027, 33.742145],
          [47.411361, 33.737144],
          [47.408028, 33.735204],
          [47.407194, 33.735204],
          [47.404694, 33.734644],
          [47.401917, 33.734644],
          [47.401638, 33.734364],
          [47.395804, 33.733813],
          [47.388581, 33.733813],
          [47.387471, 33.734093],
          [47.381915, 33.734644],
          [47.376638, 33.734644],
          [47.376081, 33.734364],
          [47.359971, 33.729372],
          [47.354138, 33.730482],
          [47.348859, 33.733813],
          [47.347468, 33.733533],
          [47.346834, 33.733453],
          [47.346358, 33.733253],
          [47.333302, 33.728532],
          [47.331911, 33.728532],
          [47.326912, 33.731033],
          [47.324412, 33.731033],
          [47.319692, 33.729083],
          [47.311912, 33.725201],
          [47.311079, 33.725201],
          [47.309969, 33.724641],
          [47.308855, 33.724641],
          [47.308302, 33.724361],
          [47.303855, 33.723811],
          [47.301355, 33.724091],
          [47.301079, 33.724361],
          [47.299689, 33.724361],
          [47.299412, 33.724641],
          [47.298022, 33.724641],
          [47.297746, 33.724921],
          [47.296636, 33.724921],
          [47.295802, 33.725201],
          [47.287466, 33.727701],
          [47.279966, 33.729372],
          [47.278023, 33.730193],
          [47.272742, 33.736584],
          [47.271909, 33.736864],
          [47.271076, 33.737695],
          [47.263856, 33.742145],
          [47.262466, 33.742976],
          [47.258576, 33.746027],
          [47.258022, 33.746027],
          [47.256909, 33.745476],
          [47.252742, 33.744086],
          [47.241909, 33.725751],
          [47.241353, 33.725751],
          [47.241076, 33.725472],
          [47.240243, 33.725472],
          [47.239966, 33.725751],
          [47.239409, 33.725751],
          [47.237186, 33.727421],
          [47.236077, 33.728252],
          [47.235796, 33.728532],
          [47.23413, 33.728532],
          [47.230796, 33.727701],
          [47.227743, 33.726031],
          [47.21274, 33.718259],
          [47.21163, 33.718259],
          [47.209964, 33.717699],
          [47.208853, 33.717699],
          [47.20802, 33.717419],
          [47.204687, 33.717419],
          [47.204406, 33.717699],
          [47.203574, 33.717699],
          [47.201353, 33.719369],
          [47.200516, 33.72159],
          [47.200516, 33.725201],
          [47.199963, 33.725472],
          [47.196907, 33.725751],
          [47.194407, 33.724921],
          [47.189964, 33.7227],
          [47.18885, 33.7227],
          [47.188297, 33.72242],
          [47.18663, 33.72242],
          [47.186351, 33.7227],
          [47.185517, 33.7227],
          [47.184964, 33.72298],
          [47.183297, 33.72298],
          [47.18274, 33.7227],
          [47.17885, 33.7227],
          [47.17135, 33.713528],
          [47.16885, 33.712418],
          [47.16246, 33.709917],
          [47.161627, 33.709087],
          [47.160517, 33.707417],
          [47.157461, 33.703535],
          [47.155794, 33.702975],
          [47.153294, 33.702975],
          [47.152737, 33.703255],
          [47.151904, 33.703255],
          [47.151904, 33.703535],
          [47.148018, 33.704645],
          [47.143294, 33.704645],
          [47.142461, 33.704365],
          [47.141071, 33.704365],
          [47.140237, 33.704085],
          [47.138571, 33.704085],
          [47.134681, 33.703255],
          [47.131904, 33.703255],
          [47.121624, 33.706306],
          [47.119124, 33.702424],
          [47.118571, 33.701865],
          [47.118015, 33.701865],
          [47.117738, 33.702145],
          [47.116348, 33.702145],
          [47.115238, 33.702695],
          [47.114405, 33.702695],
          [47.113571, 33.703535],
          [47.112181, 33.706026],
          [47.111625, 33.706026],
          [47.111348, 33.706306],
          [47.109681, 33.706306],
          [47.108848, 33.706026],
          [47.103568, 33.705476],
          [47.097458, 33.703255],
          [47.089402, 33.696584],
          [47.086625, 33.696584],
          [47.084678, 33.697423],
          [47.082735, 33.698533],
          [47.072179, 33.708807],
          [47.070512, 33.709646],
          [47.068011, 33.709917],
          [47.066345, 33.708807],
          [47.066069, 33.704645],
          [47.064955, 33.702695],
          [47.062455, 33.699644],
          [47.062179, 33.698254],
          [47.062455, 33.697974],
          [47.062455, 33.696583],
          [47.071625, 33.688251],
          [47.071902, 33.687701],
          [47.071625, 33.68659],
          [47.071069, 33.68409],
          [47.068569, 33.682419],
          [47.067455, 33.682419],
          [47.062179, 33.680478],
          [47.055236, 33.671586],
          [47.056346, 33.670476],
          [47.058012, 33.669366],
          [47.059679, 33.668806],
          [47.060236, 33.666865],
          [47.060512, 33.666585],
          [47.060236, 33.664644],
          [47.056346, 33.662694],
          [47.047456, 33.661304],
          [47.046622, 33.660753],
          [47.042179, 33.657702],
          [47.041346, 33.656032],
          [47.040513, 33.652972],
          [47.031346, 33.645749],
          [47.031346, 33.64547],
          [47.022456, 33.628254],
          [47.021899, 33.622973],
          [47.022456, 33.622422],
          [47.022456, 33.622142],
          [47.023566, 33.620752],
          [47.028842, 33.6152],
          [47.027176, 33.606308],
          [47.028842, 33.602137],
          [47.0319, 33.596305],
          [47.032732, 33.594644],
          [47.032732, 33.594364],
          [47.033566, 33.592694],
          [47.038566, 33.588252],
          [47.038013, 33.584641],
          [47.037456, 33.584361],
          [47.0344, 33.584361],
          [47.033847, 33.584641],
          [47.024956, 33.591303],
          [47.023843, 33.591863],
          [47.01579, 33.594364],
          [47.014123, 33.594364],
          [47.013566, 33.594644],
          [47.009187, 33.594083],
          [47.004964, 33.593542],
          [47.001502, 33.593098],
          [46.998567, 33.590193],
          [46.998484, 33.589716],
          [46.997176, 33.586862],
          [46.9969, 33.586582],
          [46.99662, 33.586031],
          [46.993567, 33.586031],
          [46.993286, 33.586302],
          [46.992177, 33.586302],
          [46.985787, 33.588803],
          [46.98023, 33.592974],
          [46.978844, 33.597695],
          [46.978563, 33.599356],
          [46.978287, 33.600755],
          [46.974953, 33.604087],
          [46.97412, 33.604366],
          [46.968287, 33.607138],
          [46.967454, 33.607138],
          [46.967173, 33.607418],
          [46.96662, 33.607418],
          [46.965787, 33.607698],
          [46.963564, 33.608248],
          [46.963007, 33.608528],
          [46.961897, 33.608528],
          [46.96162, 33.608808],
          [46.961064, 33.608808],
          [46.961064, 33.609088],
          [46.942451, 33.620472],
          [46.941617, 33.620472],
          [46.941064, 33.620752],
          [46.939117, 33.620752],
          [46.938564, 33.621031],
          [46.936617, 33.621031],
          [46.936061, 33.620752],
          [46.934117, 33.620752],
          [46.933561, 33.620472],
          [46.927727, 33.620472],
          [46.927174, 33.620192],
          [46.919394, 33.620192],
          [46.916617, 33.619361],
          [46.91606, 33.619361],
          [46.910227, 33.616861],
          [46.909394, 33.616861],
          [46.909117, 33.616581],
          [46.904395, 33.61575],
          [46.900505, 33.61575],
          [46.899951, 33.61603],
          [46.898561, 33.61603],
          [46.898281, 33.61631],
          [46.886615, 33.619921],
          [46.884671, 33.620192],
          [46.872724, 33.618251],
          [46.854948, 33.611029],
          [46.853558, 33.611029],
          [46.853001, 33.611029],
          [46.852725, 33.610749],
          [46.849392, 33.610478],
          [46.845782, 33.610478],
          [46.845782, 33.610189],
          [46.858002, 33.592973],
          [46.858002, 33.588531],
          [46.861615, 33.58353],
          [46.862172, 33.58269],
          [46.864392, 33.579639],
          [46.864115, 33.578809],
          [46.864392, 33.578529],
          [46.864392, 33.577698],
          [46.859115, 33.575197],
          [46.851335, 33.576579],
          [46.842725, 33.574638],
          [46.840225, 33.571866],
          [46.839392, 33.567135],
          [46.839392, 33.565195],
          [46.839392, 33.561583],
        ],
      ],
    },
  },
  Markazi: {
    properties: { value: "Markazi", name: "استان مرکزی, ایران", id: 6 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [48.949763, 34.389663],
          [48.950043, 34.388553],
          [48.952542, 34.384113],
          [48.954762, 34.382994],
          [48.956153, 34.382723],
          [48.958376, 34.382723],
          [48.958932, 34.382443],
          [48.960319, 34.382443],
          [48.960876, 34.382723],
          [48.964766, 34.383274],
          [48.968375, 34.384113],
          [48.974766, 34.386604],
          [48.975599, 34.386604],
          [48.983656, 34.389663],
          [48.985599, 34.389663],
          [48.989489, 34.389663],
          [48.990322, 34.389383],
          [48.991709, 34.389383],
          [48.992265, 34.389104],
          [49.007265, 34.384384],
          [49.012265, 34.378274],
          [49.009212, 34.361886],
          [49.009212, 34.356886],
          [49.009489, 34.355776],
          [49.009765, 34.354937],
          [49.016197, 34.344896],
          [49.015602, 34.344107],
          [49.015045, 34.343277],
          [49.015322, 34.341607],
          [49.015879, 34.338828],
          [49.014768, 34.333268],
          [49.012545, 34.329667],
          [49.008932, 34.325769],
          [49.008932, 34.324108],
          [49.010879, 34.322439],
          [49.015602, 34.319388],
          [49.020878, 34.309659],
          [49.025602, 34.30549],
          [49.033102, 34.30327],
          [49.036158, 34.30327],
          [49.036435, 34.30299],
          [49.038935, 34.30299],
          [49.040045, 34.30271],
          [49.043378, 34.29966],
          [49.045045, 34.29772],
          [49.050602, 34.290491],
          [49.063935, 34.286321],
          [49.064215, 34.28605],
          [49.064491, 34.285771],
          [49.065048, 34.285491],
          [49.070048, 34.28355],
          [49.078938, 34.27383],
          [49.079214, 34.273551],
          [49.083938, 34.262991],
          [49.091994, 34.258271],
          [49.093105, 34.258271],
          [49.093381, 34.257991],
          [49.096714, 34.257712],
          [49.097548, 34.257441],
          [49.098661, 34.257441],
          [49.099215, 34.257161],
          [49.100604, 34.257161],
          [49.101161, 34.256881],
          [49.103381, 34.256881],
          [49.103938, 34.256602],
          [49.106161, 34.256602],
          [49.109217, 34.255771],
          [49.110604, 34.255771],
          [49.113384, 34.254661],
          [49.119494, 34.251051],
          [49.121717, 34.248822],
          [49.121994, 34.247992],
          [49.122827, 34.246602],
          [49.123661, 34.243551],
          [49.123937, 34.242992],
          [49.123104, 34.237441],
          [49.118938, 34.229661],
          [49.118661, 34.229382],
          [49.118384, 34.229111],
          [49.116994, 34.227161],
          [49.116161, 34.225771],
          [49.115327, 34.221882],
          [49.115604, 34.220212],
          [49.124217, 34.203271],
          [49.124494, 34.202992],
          [49.124218, 34.202161],
          [49.123661, 34.201881],
          [49.123661, 34.201602],
          [49.123937, 34.200491],
          [49.124218, 34.199102],
          [49.124494, 34.198542],
          [49.123384, 34.191042],
          [49.119771, 34.186322],
          [49.111438, 34.182432],
          [49.110604, 34.182432],
          [49.107271, 34.181042],
          [49.105298, 34.180617],
          [49.102548, 34.179661],
          [49.099771, 34.178821],
          [49.094771, 34.17216],
          [49.093938, 34.169101],
          [49.093661, 34.164101],
          [49.093938, 34.16272],
          [49.097982, 34.155894],
          [49.098381, 34.15549],
          [49.098938, 34.15521],
          [49.099215, 34.15466],
          [49.100048, 34.15355],
          [49.100328, 34.15327],
          [49.100881, 34.15327],
          [49.104495, 34.151329],
          [49.108104, 34.14716],
          [49.109218, 34.145219],
          [49.110328, 34.14327],
          [49.110885, 34.14299],
          [49.111161, 34.142159],
          [49.116718, 34.135219],
          [49.138384, 34.119378],
          [49.139218, 34.119378],
          [49.139494, 34.119099],
          [49.150051, 34.116598],
          [49.152551, 34.116599],
          [49.153107, 34.116319],
          [49.157274, 34.115768],
          [49.159774, 34.115768],
          [49.160607, 34.116048],
          [49.168387, 34.117158],
          [49.171721, 34.117158],
          [49.173664, 34.116319],
          [49.175054, 34.112158],
          [49.17533, 34.111598],
          [49.173457, 34.10313],
          [49.173107, 34.102708],
          [49.172831, 34.101877],
          [49.171441, 34.100208],
          [49.171164, 34.099937],
          [49.167274, 34.093547],
          [49.153664, 34.083826],
          [49.147274, 34.080766],
          [49.141994, 34.076605],
          [49.122551, 34.054654],
          [49.121438, 34.053823],
          [49.117271, 34.051594],
          [49.107828, 34.048263],
          [49.103105, 34.044653],
          [49.101715, 34.040482],
          [49.097548, 34.028821],
          [49.091995, 34.02187],
          [49.081159, 34.015209],
          [49.080325, 34.015209],
          [49.079772, 34.014929],
          [49.078658, 34.014929],
          [49.077572, 34.01471],
          [49.070882, 34.010209],
          [49.054492, 34.001598],
          [49.054216, 33.999928],
          [49.056159, 33.994656],
          [49.056435, 33.994097],
          [49.055326, 33.988545],
          [49.052826, 33.986045],
          [49.052549, 33.985765],
          [49.041713, 33.976315],
          [49.038659, 33.974934],
          [49.038103, 33.975213],
          [49.037546, 33.974934],
          [49.037269, 33.975213],
          [49.030603, 33.976315],
          [49.030322, 33.976874],
          [49.022823, 33.981324],
          [49.021156, 33.981875],
          [49.019489, 33.981875],
          [49.018936, 33.982155],
          [49.01588, 33.982426],
          [49.012823, 33.982425],
          [49.01199, 33.982155],
          [49.010323, 33.982155],
          [49.010046, 33.981875],
          [49.008933, 33.981875],
          [49.008656, 33.981595],
          [48.9981, 33.977705],
          [48.987823, 33.972153],
          [48.98699, 33.972153],
          [48.968653, 33.959652],
          [48.965877, 33.956601],
          [48.96532, 33.956041],
          [48.961987, 33.94993],
          [48.961434, 33.94437],
          [48.96171, 33.942709],
          [48.963934, 33.939378],
          [48.963654, 33.936598],
          [48.957544, 33.927427],
          [48.95643, 33.922706],
          [48.956154, 33.922426],
          [48.95643, 33.921876],
          [48.956154, 33.921325],
          [48.955877, 33.919935],
          [48.955597, 33.916875],
          [48.957544, 33.910204],
          [48.959487, 33.907984],
          [48.960601, 33.907424],
          [48.962821, 33.905483],
          [48.963377, 33.905203],
          [48.96421, 33.904933],
          [48.966434, 33.904933],
          [48.96782, 33.905483],
          [48.980324, 33.912714],
          [48.982267, 33.912984],
          [48.982824, 33.912714],
          [48.983933, 33.912714],
          [48.98421, 33.912425],
          [48.993657, 33.909933],
          [48.994213, 33.910204],
          [48.995047, 33.910204],
          [48.995324, 33.910484],
          [48.996433, 33.910484],
          [48.996713, 33.910764],
          [48.9981, 33.910764],
          [48.999818, 33.911324],
          [49.000323, 33.911594],
          [49.001156, 33.911594],
          [49.002823, 33.912154],
          [49.009766, 33.909094],
          [49.011713, 33.907153],
          [49.012266, 33.906043],
          [49.011433, 33.901593],
          [48.99949, 33.895202],
          [48.994766, 33.89271],
          [48.993657, 33.890481],
          [48.993933, 33.89021],
          [48.993933, 33.889371],
          [48.994766, 33.88826],
          [48.997267, 33.882709],
          [48.997547, 33.879929],
          [48.997823, 33.879098],
          [49.0056, 33.871037],
          [49.006433, 33.869376],
          [49.006157, 33.867427],
          [49.006157, 33.867147],
          [49.00449, 33.866036],
          [49.001157, 33.866036],
          [49.000047, 33.866596],
          [48.9981, 33.866596],
          [48.997267, 33.866596],
          [48.975877, 33.841312],
          [48.975877, 33.839651],
          [48.9781, 33.83743],
          [48.978933, 33.83715],
          [48.980324, 33.83715],
          [48.980324, 33.83687],
          [48.98171, 33.83687],
          [48.9856, 33.836591],
          [48.985877, 33.83632],
          [48.98671, 33.83632],
          [48.98699, 33.83604],
          [48.987543, 33.83604],
          [48.987824, 33.83576],
          [48.988377, 33.83576],
          [48.988657, 33.83548],
          [48.991433, 33.83437],
          [48.99199, 33.833539],
          [48.9931, 33.832429],
          [48.9931, 33.83187],
          [48.993933, 33.829929],
          [48.993657, 33.826038],
          [48.994214, 33.821868],
          [48.992824, 33.814926],
          [48.984767, 33.807984],
          [48.984767, 33.807704],
          [48.984675, 33.807054],
          [48.98421, 33.806594],
          [48.982544, 33.802983],
          [48.982267, 33.798262],
          [48.982544, 33.797982],
          [48.982824, 33.795761],
          [48.983101, 33.794931],
          [48.983657, 33.794091],
          [48.9881, 33.781869],
          [48.990877, 33.779648],
          [48.9956, 33.779648],
          [48.996157, 33.779928],
          [48.997267, 33.779928],
          [48.997824, 33.779648],
          [48.99949, 33.779368],
          [49.0031, 33.778258],
          [49.010047, 33.770756],
          [49.010047, 33.770206],
          [49.011714, 33.767705],
          [49.013657, 33.761314],
          [49.013937, 33.760763],
          [49.013104, 33.756313],
          [49.012823, 33.756033],
          [49.010047, 33.753262],
          [49.009214, 33.752151],
          [49.00699, 33.750481],
          [49.00699, 33.750201],
          [49.005324, 33.741589],
          [49.0056, 33.740199],
          [49.00949, 33.734097],
          [49.01699, 33.727425],
          [49.016157, 33.721314],
          [49.015604, 33.720763],
          [49.01338, 33.718813],
          [49.0106, 33.714923],
          [49.010324, 33.711871],
          [49.0106, 33.711041],
          [49.015324, 33.704649],
          [49.015604, 33.701869],
          [49.01588, 33.700759],
          [49.016157, 33.700479],
          [49.016714, 33.699088],
          [49.021157, 33.696317],
          [49.02199, 33.696317],
          [49.02338, 33.695757],
          [49.02588, 33.695757],
          [49.026157, 33.695478],
          [49.030047, 33.695478],
          [49.032547, 33.696317],
          [49.034213, 33.697148],
          [49.04227, 33.702699],
          [49.043103, 33.702699],
          [49.04338, 33.702979],
          [49.044213, 33.702979],
          [49.044213, 33.703259],
          [49.04588, 33.703259],
          [49.04616, 33.703539],
          [49.049213, 33.703539],
          [49.04977, 33.703259],
          [49.050883, 33.703259],
          [49.052826, 33.702429],
          [49.06505, 33.691316],
          [49.065883, 33.690756],
          [49.069493, 33.688815],
          [49.070326, 33.688815],
          [49.070883, 33.689366],
          [49.07366, 33.700759],
          [49.075606, 33.717983],
          [49.082549, 33.719924],
          [49.085049, 33.719924],
          [49.085606, 33.719924],
          [49.085882, 33.719644],
          [49.089496, 33.718534],
          [49.094496, 33.716864],
          [49.096162, 33.716593],
          [49.101162, 33.716864],
          [49.101439, 33.717152],
          [49.107829, 33.719924],
          [49.108662, 33.719924],
          [49.108939, 33.720204],
          [49.111439, 33.720204],
          [49.111719, 33.719924],
          [49.112552, 33.719644],
          [49.118662, 33.715482],
          [49.119496, 33.715482],
          [49.120052, 33.715203],
          [49.121162, 33.715203],
          [49.123662, 33.715203],
          [49.124495, 33.715753],
          [49.125329, 33.715753],
          [49.125606, 33.716033],
          [49.128109, 33.716864],
          [49.128385, 33.717152],
          [49.129495, 33.717983],
          [49.130052, 33.718263],
          [49.138385, 33.721594],
          [49.141719, 33.721594],
          [49.146718, 33.718263],
          [49.166998, 33.70465],
          [49.169775, 33.70409],
          [49.170608, 33.70409],
          [49.179775, 33.702149],
          [49.184222, 33.699369],
          [49.185331, 33.697698],
          [49.185611, 33.696588],
          [49.195055, 33.686315],
          [49.194778, 33.678533],
          [49.195055, 33.677703],
          [49.195055, 33.677423],
          [49.195611, 33.676872],
          [49.195611, 33.676313],
          [49.196164, 33.676033],
          [49.198945, 33.671591],
          [49.199778, 33.66659],
          [49.200055, 33.664089],
          [49.201165, 33.659648],
          [49.201445, 33.658817],
          [49.202001, 33.657147],
          [49.204778, 33.652426],
          [49.206444, 33.651315],
          [49.208388, 33.646874],
          [49.208668, 33.646314],
          [49.208388, 33.644364],
          [49.204501, 33.6302],
          [49.204221, 33.626589],
          [49.204502, 33.625198],
          [49.205335, 33.624368],
          [49.211168, 33.620757],
          [49.212001, 33.618256],
          [49.215611, 33.612975],
          [49.216721, 33.612704],
          [49.217278, 33.612704],
          [49.218111, 33.612424],
          [49.219221, 33.612424],
          [49.219501, 33.612144],
          [49.222835, 33.612144],
          [49.239224, 33.616866],
          [49.240334, 33.616866],
          [49.240891, 33.617146],
          [49.246724, 33.617146],
          [49.251724, 33.615205],
          [49.252281, 33.614645],
          [49.252834, 33.614645],
          [49.253114, 33.614365],
          [49.253667, 33.614365],
          [49.255614, 33.613535],
          [49.256724, 33.613535],
          [49.257281, 33.613255],
          [49.258114, 33.613255],
          [49.258671, 33.612975],
          [49.265614, 33.610754],
          [49.266724, 33.610483],
          [49.280894, 33.604092],
          [49.284227, 33.604092],
          [49.285061, 33.604372],
          [49.287366, 33.607227],
          [49.28756, 33.607703],
          [49.287837, 33.607983],
          [49.288114, 33.608813],
          [49.28867, 33.612144],
          [49.29895, 33.636591],
          [49.295337, 33.640202],
          [49.29478, 33.642143],
          [49.294504, 33.642423],
          [49.29645, 33.650205],
          [49.297003, 33.650476],
          [49.302003, 33.653536],
          [49.302837, 33.653536],
          [49.303117, 33.653816],
          [49.30617, 33.654647],
          [49.314503, 33.658817],
          [49.315617, 33.659088],
          [49.321726, 33.656867],
          [49.32284, 33.655197],
          [49.323673, 33.654087],
          [49.328116, 33.650756],
          [49.32895, 33.650756],
          [49.329506, 33.650476],
          [49.33423, 33.650476],
          [49.335063, 33.650756],
          [49.337283, 33.650756],
          [49.33784, 33.651036],
          [49.34395, 33.651036],
          [49.344506, 33.651315],
          [49.34645, 33.651315],
          [49.34673, 33.651595],
          [49.351449, 33.652977],
          [49.356173, 33.657147],
          [49.357839, 33.661309],
          [49.356729, 33.664369],
          [49.356173, 33.66576],
          [49.354506, 33.669921],
          [49.355619, 33.676873],
          [49.358672, 33.680204],
          [49.367286, 33.684925],
          [49.368119, 33.684925],
          [49.368952, 33.685485],
          [49.375896, 33.688536],
          [49.377286, 33.689926],
          [49.379229, 33.690757],
          [49.379229, 33.691037],
          [49.382009, 33.694648],
          [49.381729, 33.694927],
          [49.381729, 33.695758],
          [49.364506, 33.716034],
          [49.365339, 33.721315],
          [49.365062, 33.723815],
          [49.363672, 33.727426],
          [49.363672, 33.727977],
          [49.362839, 33.728816],
          [49.358952, 33.732427],
          [49.358672, 33.733258],
          [49.359506, 33.737428],
          [49.360619, 33.738259],
          [49.365339, 33.738259],
          [49.365619, 33.737979],
          [49.366452, 33.737979],
          [49.366729, 33.737699],
          [49.367286, 33.737699],
          [49.367562, 33.737428],
          [49.368119, 33.737148],
          [49.368672, 33.736869],
          [49.368952, 33.736589],
          [49.370619, 33.736318],
          [49.375342, 33.736318],
          [49.375896, 33.736869],
          [49.392009, 33.74326],
          [49.404785, 33.750202],
          [49.405619, 33.750202],
          [49.406175, 33.750482],
          [49.410065, 33.750482],
          [49.413398, 33.748812],
          [49.414509, 33.747701],
          [49.415898, 33.745761],
          [49.416732, 33.74521],
          [49.417565, 33.74521],
          [49.418955, 33.74465],
          [49.421732, 33.74465],
          [49.431178, 33.736318],
          [49.431455, 33.735758],
          [49.431178, 33.732978],
          [49.433122, 33.727426],
          [49.433678, 33.726036],
          [49.433398, 33.724366],
          [49.433398, 33.722705],
          [49.433121, 33.722425],
          [49.433678, 33.720204],
          [49.433678, 33.719094],
          [49.433678, 33.718534],
          [49.432012, 33.715754],
          [49.431732, 33.714643],
          [49.431455, 33.710762],
          [49.433955, 33.706871],
          [49.433398, 33.701319],
          [49.435898, 33.699649],
          [49.437565, 33.700239],
          [49.455547, 33.70201],
          [49.472089, 33.700884],
          [49.487378, 33.697412],
          [49.489418, 33.696213],
          [49.496584, 33.691998],
          [49.51187, 33.688523],
          [49.52838, 33.687963],
          [49.543644, 33.691564],
          [49.544794, 33.691317],
          [49.545627, 33.692147],
          [49.561183, 33.716864],
          [49.568683, 33.719925],
          [49.569793, 33.719925],
          [49.570073, 33.720204],
          [49.572293, 33.720205],
          [49.57285, 33.720484],
          [49.573683, 33.720484],
          [49.576183, 33.721035],
          [49.579516, 33.720764],
          [49.580073, 33.720764],
          [49.59174, 33.717424],
          [49.594796, 33.717153],
          [49.595073, 33.717424],
          [49.59674, 33.717424],
          [49.598406, 33.719645],
          [49.598686, 33.725476],
          [49.598406, 33.726036],
          [49.598686, 33.726596],
          [49.599796, 33.732428],
          [49.600353, 33.733538],
          [49.600906, 33.733818],
          [49.603686, 33.735479],
          [49.607019, 33.735479],
          [49.607573, 33.735759],
          [49.608406, 33.736589],
          [49.609239, 33.737149],
          [49.609239, 33.737429],
          [49.612019, 33.74187],
          [49.613963, 33.74326],
          [49.615629, 33.74326],
          [49.615906, 33.74298],
          [49.616739, 33.742981],
          [49.617576, 33.742421],
          [49.618962, 33.742421],
          [49.619242, 33.74215],
          [49.619242, 33.74187],
          [49.620353, 33.739649],
          [49.620629, 33.738539],
          [49.620909, 33.737149],
          [49.621186, 33.736869],
          [49.620909, 33.735208],
          [49.620076, 33.727706],
          [49.621186, 33.725477],
          [49.623409, 33.724366],
          [49.627576, 33.723256],
          [49.631186, 33.719645],
          [49.633129, 33.713813],
          [49.632852, 33.712983],
          [49.632576, 33.708541],
          [49.632296, 33.700209],
          [49.632576, 33.69881],
          [49.632853, 33.697429],
          [49.633129, 33.696869],
          [49.631742, 33.691868],
          [49.622296, 33.685205],
          [49.615353, 33.681865],
          [49.610629, 33.680484],
          [49.609519, 33.680484],
          [49.604239, 33.678814],
          [49.60063, 33.676033],
          [49.59813, 33.673533],
          [49.597296, 33.672982],
          [49.595073, 33.666591],
          [49.594796, 33.66409],
          [49.595073, 33.662149],
          [49.595349, 33.658538],
          [49.59563, 33.656597],
          [49.595906, 33.655487],
          [49.603406, 33.641033],
          [49.609796, 33.637422],
          [49.610629, 33.637422],
          [49.61313, 33.636872],
          [49.617296, 33.636312],
          [49.617853, 33.636312],
          [49.630909, 33.63048],
          [49.636186, 33.625199],
          [49.636466, 33.624369],
          [49.647299, 33.599651],
          [49.650352, 33.589368],
          [49.650352, 33.588808],
          [49.651466, 33.586308],
          [49.660632, 33.579925],
          [49.684245, 33.571583],
          [49.686189, 33.571312],
          [49.688412, 33.571312],
          [49.688689, 33.571033],
          [49.690355, 33.571033],
          [49.691189, 33.571312],
          [49.700635, 33.572143],
          [49.704802, 33.571872],
          [49.706468, 33.571583],
          [49.712302, 33.568532],
          [49.713412, 33.566871],
          [49.714802, 33.566311],
          [49.715078, 33.566031],
          [49.715358, 33.56576],
          [49.715911, 33.56548],
          [49.715911, 33.565201],
          [49.762304, 33.526309],
          [49.770914, 33.517696],
          [49.773694, 33.513814],
          [49.774528, 33.512974],
          [49.777028, 33.509363],
          [49.785638, 33.493808],
          [49.785638, 33.493537],
          [49.786194, 33.492977],
          [49.786194, 33.492697],
          [49.786471, 33.492417],
          [49.786471, 33.491867],
          [49.787304, 33.490476],
          [49.789528, 33.484084],
          [49.791471, 33.477422],
          [49.791751, 33.476582],
          [49.792028, 33.475201],
          [49.792584, 33.47381],
          [49.792304, 33.469369],
          [49.792028, 33.460756],
          [49.794251, 33.451303],
          [49.794251, 33.451032],
          [49.795637, 33.447981],
          [49.796471, 33.446591],
          [49.806751, 33.437978],
          [49.835364, 33.427695],
          [49.83925, 33.426864],
          [49.840917, 33.426033],
          [49.84814, 33.422973],
          [49.848973, 33.422973],
          [49.85203, 33.421583],
          [49.852863, 33.421583],
          [49.854807, 33.421032],
          [49.85592, 33.421032],
          [49.856474, 33.420752],
          [49.857307, 33.420752],
          [49.857863, 33.420472],
          [49.858697, 33.420472],
          [49.861473, 33.419641],
          [49.862587, 33.419641],
          [49.88231, 33.41575],
          [49.897309, 33.410749],
          [49.898419, 33.410749],
          [49.898699, 33.410469],
          [49.906476, 33.408248],
          [49.907309, 33.407697],
          [49.9087, 33.407417],
          [49.920366, 33.401305],
          [49.922589, 33.399364],
          [49.925089, 33.396312],
          [49.925366, 33.395753],
          [49.927866, 33.392141],
          [49.934533, 33.384919],
          [49.937312, 33.384088],
          [49.942036, 33.385199],
          [49.943422, 33.385199],
          [49.943702, 33.385478],
          [49.946756, 33.386029],
          [49.947869, 33.38742],
          [49.950089, 33.395193],
          [49.957869, 33.407977],
          [49.959259, 33.413529],
          [49.959812, 33.41575],
          [49.960646, 33.41631],
          [49.962592, 33.416861],
          [49.963702, 33.417141],
          [49.965645, 33.417141],
          [49.968702, 33.41603],
          [49.974535, 33.41603],
          [49.974812, 33.41631],
          [49.978425, 33.41631],
          [49.980925, 33.416581],
          [49.981202, 33.416861],
          [49.982315, 33.416861],
          [49.982592, 33.417141],
          [49.985648, 33.417141],
          [49.989815, 33.419641],
          [49.990368, 33.419921],
          [49.991482, 33.432696],
          [49.991202, 33.433256],
          [49.990925, 33.435197],
          [49.990368, 33.440199],
          [49.972312, 33.466308],
          [49.972035, 33.467699],
          [49.973145, 33.475752],
          [49.973425, 33.476582],
          [49.973425, 33.477142],
          [49.974535, 33.480194],
          [49.983702, 33.492418],
          [49.985368, 33.492977],
          [49.987315, 33.494088],
          [50.027038, 33.49936],
          [50.028428, 33.499649],
          [50.034261, 33.499649],
          [50.051484, 33.500751],
          [50.052594, 33.50103],
          [50.054541, 33.50103],
          [50.071207, 33.503531],
          [50.072597, 33.503531],
          [50.073707, 33.503811],
          [50.076484, 33.503811],
          [50.078984, 33.504082],
          [50.079817, 33.504371],
          [50.08454, 33.504922],
          [50.09732, 33.506863],
          [50.138709, 33.519087],
          [50.143153, 33.520477],
          [50.149266, 33.522978],
          [50.176213, 33.52853],
          [50.183156, 33.52992],
          [50.183989, 33.5302],
          [50.185936, 33.5302],
          [50.186769, 33.53048],
          [50.188435, 33.53048],
          [50.190655, 33.53104],
          [50.191769, 33.53104],
          [50.205659, 33.542424],
          [50.206492, 33.544094],
          [50.206492, 33.544645],
          [50.206492, 33.546595],
          [50.20469, 33.549595],
          [50.203992, 33.550757],
          [50.203435, 33.552427],
          [50.203712, 33.556038],
          [50.204825, 33.55964],
          [50.209545, 33.568533],
          [50.209825, 33.570753],
          [50.209825, 33.574374],
          [50.210102, 33.575755],
          [50.211212, 33.577705],
          [50.215378, 33.579926],
          [50.225935, 33.588809],
          [50.228715, 33.593251],
          [50.230658, 33.600202],
          [50.232882, 33.602703],
          [50.235381, 33.603533],
          [50.238158, 33.604093],
          [50.238991, 33.604373],
          [50.240382, 33.604373],
          [50.240938, 33.604644],
          [50.243438, 33.604373],
          [50.248715, 33.600482],
          [50.256214, 33.586588],
          [50.258995, 33.584367],
          [50.261495, 33.583537],
          [50.273161, 33.582427],
          [50.275104, 33.582697],
          [50.278718, 33.582977],
          [50.288994, 33.584088],
          [50.289551, 33.584368],
          [50.292328, 33.584368],
          [50.292884, 33.584647],
          [50.293718, 33.584647],
          [50.294271, 33.584927],
          [50.295104, 33.584927],
          [50.316497, 33.59465],
          [50.318441, 33.595761],
          [50.32594, 33.604924],
          [50.326774, 33.607145],
          [50.328163, 33.609925],
          [50.331217, 33.613536],
          [50.337053, 33.615477],
          [50.337054, 33.615757],
          [50.347607, 33.616588],
          [50.348164, 33.616317],
          [50.352607, 33.616588],
          [50.353443, 33.616868],
          [50.357053, 33.616868],
          [50.357887, 33.617147],
          [50.35983, 33.617147],
          [50.360943, 33.617427],
          [50.365943, 33.617427],
          [50.366776, 33.617707],
          [50.368163, 33.617707],
          [50.377053, 33.619648],
          [50.378166, 33.619648],
          [50.378719, 33.619368],
          [50.38011, 33.619368],
          [50.380943, 33.619648],
          [50.382053, 33.619648],
          [50.382333, 33.619928],
          [50.383166, 33.619928],
          [50.384, 33.619648],
          [50.384274, 33.619926],
          [50.386499, 33.619928],
          [50.388443, 33.620199],
          [50.388443, 33.620479],
          [50.390943, 33.620479],
          [50.392889, 33.620479],
          [50.393443, 33.620758],
          [50.398723, 33.620759],
          [50.399276, 33.621038],
          [50.400109, 33.620759],
          [50.400666, 33.621038],
          [50.406776, 33.621038],
          [50.411223, 33.621309],
          [50.411779, 33.621589],
          [50.413446, 33.621589],
          [50.414279, 33.621869],
          [50.415666, 33.621869],
          [50.416499, 33.622149],
          [50.418166, 33.622149],
          [50.419279, 33.622429],
          [50.420666, 33.622429],
          [50.421499, 33.6227],
          [50.422056, 33.622429],
          [50.425389, 33.6227],
          [50.432056, 33.62465],
          [50.444835, 33.631592],
          [50.449559, 33.633253],
          [50.452335, 33.633813],
          [50.453725, 33.634652],
          [50.454002, 33.634363],
          [50.455112, 33.633542],
          [50.455669, 33.633542],
          [50.455945, 33.633253],
          [50.457335, 33.633253],
          [50.459835, 33.632702],
          [50.464279, 33.631872],
          [50.465669, 33.631312],
          [50.466782, 33.631312],
          [50.467335, 33.631032],
          [50.468725, 33.631032],
          [50.469558, 33.631312],
          [50.473725, 33.631312],
          [50.483725, 33.634363],
          [50.512614, 33.636034],
          [50.525118, 33.634093],
          [50.525671, 33.633813],
          [50.526785, 33.633813],
          [50.528171, 33.633253],
          [50.529284, 33.633253],
          [50.537618, 33.630482],
          [50.542894, 33.629931],
          [50.558727, 33.628261],
          [50.560117, 33.627981],
          [50.561231, 33.628261],
          [50.563174, 33.628261],
          [50.574284, 33.630761],
          [50.57484, 33.631312],
          [50.575674, 33.631592],
          [50.577617, 33.634093],
          [50.577897, 33.636313],
          [50.577617, 33.636593],
          [50.57984, 33.643536],
          [50.583174, 33.646036],
          [50.586787, 33.653538],
          [50.586787, 33.653818],
          [50.587064, 33.654089],
          [50.587341, 33.654368],
          [50.58762, 33.654648],
          [50.588174, 33.654928],
          [50.593454, 33.66048],
          [50.595673, 33.66215],
          [50.607063, 33.668542],
          [50.608453, 33.669093],
          [50.609563, 33.669923],
          [50.61151, 33.670763],
          [50.612896, 33.671873],
          [50.62901, 33.679646],
          [50.642623, 33.689648],
          [50.64651, 33.691869],
          [50.655676, 33.69576],
          [50.659566, 33.69659],
          [50.690402, 33.720766],
          [50.700679, 33.726318],
          [50.704012, 33.727148],
          [50.715682, 33.726598],
          [50.718735, 33.727708],
          [50.724292, 33.727708],
          [50.729292, 33.726318],
          [50.730402, 33.726318],
          [50.733738, 33.725207],
          [50.735125, 33.725207],
          [50.737072, 33.724648],
          [50.737905, 33.724648],
          [50.738182, 33.724368],
          [50.742072, 33.724368],
          [50.744291, 33.724097],
          [50.744571, 33.723817],
          [50.752071, 33.723817],
          [50.752628, 33.723537],
          [50.754015, 33.723537],
          [50.754848, 33.723257],
          [50.758461, 33.724097],
          [50.758738, 33.724368],
          [50.759295, 33.724368],
          [50.762628, 33.724368],
          [50.764848, 33.723817],
          [50.767071, 33.723817],
          [50.767348, 33.723537],
          [50.769851, 33.723537],
          [50.772904, 33.722987],
          [50.773738, 33.722707],
          [50.774571, 33.722707],
          [50.775128, 33.722427],
          [50.776238, 33.722427],
          [50.802074, 33.713535],
          [50.803184, 33.712984],
          [50.807074, 33.711874],
          [50.818184, 33.709653],
          [50.818464, 33.709373],
          [50.819297, 33.709373],
          [50.819574, 33.709094],
          [50.820131, 33.709094],
          [50.820407, 33.708814],
          [50.823184, 33.708814],
          [50.82485, 33.708543],
          [50.825407, 33.708263],
          [50.829297, 33.708263],
          [50.83013, 33.707983],
          [50.83263, 33.707983],
          [50.837907, 33.706873],
          [50.849577, 33.705762],
          [50.86152, 33.704652],
          [50.862077, 33.704372],
          [50.866243, 33.704372],
          [50.872633, 33.704923],
          [50.873466, 33.705203],
          [50.8768, 33.705203],
          [50.878186, 33.704923],
          [50.879576, 33.704923],
          [50.880133, 33.705203],
          [50.8818, 33.705203],
          [50.882076, 33.705483],
          [50.885133, 33.705483],
          [50.88569, 33.705763],
          [50.887076, 33.705762],
          [50.8918, 33.707153],
          [50.892633, 33.707153],
          [50.89291, 33.707424],
          [50.89569, 33.708263],
          [50.903746, 33.710484],
          [50.953745, 33.73465],
          [50.954302, 33.73465],
          [50.954579, 33.73493],
          [50.965969, 33.741872],
          [50.967082, 33.742703],
          [50.974582, 33.749094],
          [50.976248, 33.754095],
          [50.979025, 33.757986],
          [50.989305, 33.763537],
          [50.991248, 33.764097],
          [50.999862, 33.767708],
          [51.009305, 33.77298],
          [51.023751, 33.786042],
          [51.046807, 33.815768],
          [51.048751, 33.81882],
          [51.049584, 33.82021],
          [51.050974, 33.827431],
          [51.050694, 33.827982],
          [51.050417, 33.829652],
          [51.048474, 33.833263],
          [51.048474, 33.833822],
          [51.036251, 33.846045],
          [51.035141, 33.846596],
          [51.032638, 33.849656],
          [51.032084, 33.852986],
          [51.035974, 33.860208],
          [51.037361, 33.862158],
          [51.037918, 33.862709],
          [51.041807, 33.87549],
          [51.041527, 33.876321],
          [51.040141, 33.882712],
          [51.039307, 33.886873],
          [51.039027, 33.888263],
          [51.042641, 33.896324],
          [51.043474, 33.898816],
          [51.046807, 33.902435],
          [51.047361, 33.902435],
          [51.048194, 33.902715],
          [51.049584, 33.907716],
          [51.049861, 33.907987],
          [51.048154, 33.910763],
          [51.047641, 33.911597],
          [51.047641, 33.912157],
          [51.047084, 33.912428],
          [51.046023, 33.914733],
          [51.045417, 33.916048],
          [51.044861, 33.918268],
          [51.044946, 33.919793],
          [51.045141, 33.923269],
          [51.048194, 33.92882],
          [51.048407, 33.933056],
          [51.048751, 33.934791],
          [51.048751, 33.934931],
          [51.048751, 33.936601],
          [51.048474, 33.936872],
          [51.039027, 33.946043],
          [51.038751, 33.946323],
          [51.035417, 33.949103],
          [51.035141, 33.949383],
          [51.027361, 33.953544],
          [51.025418, 33.955764],
          [51.024304, 33.958265],
          [51.025418, 33.967706],
          [51.022917, 33.972987],
          [51.021528, 33.978547],
          [51.021251, 33.978827],
          [51.020036, 33.981835],
          [51.020619, 33.994852],
          [51.033981, 34.003195],
          [51.036002, 34.014617],
          [51.044464, 34.02552],
          [51.050657, 34.032355],
          [51.052648, 34.038727],
          [51.041289, 34.048193],
          [51.024695, 34.063547],
          [51.021804, 34.065217],
          [51.021251, 34.065217],
          [51.020965, 34.065491],
          [51.001804, 34.076608],
          [51.000414, 34.077158],
          [51.000023, 34.078891],
          [50.999861, 34.079658],
          [50.994304, 34.081048],
          [50.994024, 34.081328],
          [50.993191, 34.081328],
          [50.985971, 34.084938],
          [50.983748, 34.087989],
          [50.983748, 34.088269],
          [50.981524, 34.090769],
          [50.980691, 34.0916],
          [50.969301, 34.097439],
          [50.968748, 34.097439],
          [50.961248, 34.10522],
          [50.952911, 34.111601],
          [50.952635, 34.112431],
          [50.951801, 34.114661],
          [50.951802, 34.11494],
          [50.924022, 34.141602],
          [50.923745, 34.142442],
          [50.923188, 34.142713],
          [50.922912, 34.143552],
          [50.921802, 34.144653],
          [50.921522, 34.145493],
          [50.919302, 34.147713],
          [50.918745, 34.147713],
          [50.918465, 34.147993],
          [50.918189, 34.148272],
          [50.908189, 34.151883],
          [50.907912, 34.152162],
          [50.905965, 34.152162],
          [50.905412, 34.151883],
          [50.897355, 34.157163],
          [50.897355, 34.157442],
          [50.896242, 34.158553],
          [50.894856, 34.161603],
          [50.895409, 34.167163],
          [50.894856, 34.168273],
          [50.894575, 34.169104],
          [50.890409, 34.174383],
          [50.890409, 34.175494],
          [50.890132, 34.175773],
          [50.889856, 34.178824],
          [50.889576, 34.180494],
          [50.888742, 34.184943],
          [50.888466, 34.185214],
          [50.885132, 34.191044],
          [50.884856, 34.191333],
          [50.883189, 34.192443],
          [50.875132, 34.194104],
          [50.873742, 34.193833],
          [50.872352, 34.193274],
          [50.870966, 34.193274],
          [50.870409, 34.192994],
          [50.869299, 34.192994],
          [50.863743, 34.195494],
          [50.863186, 34.196054],
          [50.860409, 34.196324],
          [50.857629, 34.196054],
          [50.843048, 34.194926],
          [50.840729, 34.197992],
          [50.834135, 34.202944],
          [50.832523, 34.202466],
          [50.829852, 34.20203],
          [50.826302, 34.201515],
          [50.824931, 34.201586],
          [50.821909, 34.201359],
          [50.81895, 34.203737],
          [50.817974, 34.204319],
          [50.816174, 34.204581],
          [50.815125, 34.204776],
          [50.812511, 34.206764],
          [50.811663, 34.207597],
          [50.810683, 34.211884],
          [50.809573, 34.216054],
          [50.80985, 34.222164],
          [50.80874, 34.224104],
          [50.806517, 34.225214],
          [50.80346, 34.226045],
          [50.80096, 34.229384],
          [50.80124, 34.232164],
          [50.80124, 34.233274],
          [50.79624, 34.236054],
          [50.791793, 34.236054],
          [50.788183, 34.234944],
          [50.78735, 34.234944],
          [50.786237, 34.234384],
          [50.785403, 34.234384],
          [50.783183, 34.233274],
          [50.772904, 34.224664],
          [50.771237, 34.224104],
          [50.770127, 34.224104],
          [50.76985, 34.223824],
          [50.765127, 34.223824],
          [50.765127, 34.224104],
          [50.764014, 34.224104],
          [50.763737, 34.224384],
          [50.760127, 34.223554],
          [50.75818, 34.223824],
          [50.75707, 34.223825],
          [50.75596, 34.223274],
          [50.755127, 34.223274],
          [50.746791, 34.218554],
          [50.745404, 34.218274],
          [50.744571, 34.218274],
          [50.74429, 34.217994],
          [50.740957, 34.217994],
          [50.740404, 34.217714],
          [50.739014, 34.217714],
          [50.731791, 34.215494],
          [50.730957, 34.215494],
          [50.730681, 34.215214],
          [50.727624, 34.215214],
          [50.726791, 34.215494],
          [50.725681, 34.215494],
          [50.722901, 34.216604],
          [50.721791, 34.217443],
          [50.720958, 34.217714],
          [50.715958, 34.222714],
          [50.714568, 34.225494],
          [50.708734, 34.229113],
          [50.697901, 34.230494],
          [50.695958, 34.230223],
          [50.695401, 34.229944],
          [50.693178, 34.229944],
          [50.692345, 34.229664],
          [50.691788, 34.229944],
          [50.689568, 34.229944],
          [50.686511, 34.230774],
          [50.673731, 34.244664],
          [50.662065, 34.252164],
          [50.658178, 34.254104],
          [50.653455, 34.255494],
          [50.651788, 34.255494],
          [50.650955, 34.255774],
          [50.646232, 34.255774],
          [50.645675, 34.256053],
          [50.639288, 34.256053],
          [50.638175, 34.255774],
          [50.637065, 34.255774],
          [50.636232, 34.255494],
          [50.633452, 34.255494],
          [50.632898, 34.255214],
          [50.632065, 34.255214],
          [50.630675, 34.254664],
          [50.629565, 34.254664],
          [50.626232, 34.252994],
          [50.609562, 34.231604],
          [50.607062, 34.228545],
          [50.595672, 34.216884],
          [50.587339, 34.214664],
          [50.586506, 34.214664],
          [50.586229, 34.214384],
          [50.584562, 34.214384],
          [50.584562, 34.214104],
          [50.583173, 34.214104],
          [50.582063, 34.213833],
          [50.575116, 34.209934],
          [50.557616, 34.204104],
          [50.554559, 34.202994],
          [50.535117, 34.195494],
          [50.534003, 34.195494],
          [50.531783, 34.194383],
          [50.51706, 34.189934],
          [50.50928, 34.189934],
          [50.50567, 34.189934],
          [50.499837, 34.189934],
          [50.499004, 34.190214],
          [50.496224, 34.190214],
          [50.49539, 34.190493],
          [50.494557, 34.190493],
          [50.494004, 34.190773],
          [50.481501, 34.189663],
          [50.479281, 34.189934],
          [50.478447, 34.190214],
          [50.477057, 34.190214],
          [50.474834, 34.190773],
          [50.473167, 34.190773],
          [50.472334, 34.190493],
          [50.467614, 34.190493],
          [50.466781, 34.190773],
          [50.465111, 34.190773],
          [50.442055, 34.201053],
          [50.441501, 34.201604],
          [50.438721, 34.202994],
          [50.432334, 34.206884],
          [50.428444, 34.211324],
          [50.428721, 34.215494],
          [50.431778, 34.224663],
          [50.432334, 34.225214],
          [50.435111, 34.237163],
          [50.435668, 34.246053],
          [50.435388, 34.246604],
          [50.434834, 34.249104],
          [50.424554, 34.277443],
          [50.419278, 34.284392],
          [50.418998, 34.284942],
          [50.417611, 34.287163],
          [50.415111, 34.291052],
          [50.414555, 34.292442],
          [50.396221, 34.313551],
          [50.383165, 34.323],
          [50.382332, 34.323271],
          [50.381775, 34.323831],
          [50.381218, 34.323831],
          [50.380942, 34.32411],
          [50.380942, 34.324941],
          [50.380385, 34.326051],
          [50.380385, 34.326331],
          [50.370108, 34.33466],
          [50.365942, 34.342719],
          [50.362608, 34.346059],
          [50.36219, 34.346194],
          [50.361495, 34.346889],
          [50.360108, 34.348829],
          [50.360108, 34.349389],
          [50.352885, 34.374107],
          [50.353162, 34.374387],
          [50.352329, 34.377717],
          [50.352605, 34.380496],
          [50.352885, 34.381606],
          [50.353162, 34.382166],
          [50.349552, 34.387996],
          [50.349272, 34.388275],
          [50.340385, 34.395775],
          [50.336219, 34.400224],
          [50.335662, 34.400504],
          [50.335385, 34.401054],
          [50.334829, 34.401334],
          [50.331772, 34.406884],
          [50.331496, 34.408003],
          [50.335385, 34.421332],
          [50.336219, 34.424111],
          [50.337052, 34.427441],
          [50.337885, 34.437169],
          [50.337605, 34.438],
          [50.337328, 34.439669],
          [50.335105, 34.442169],
          [50.331496, 34.442439],
          [50.329272, 34.442169],
          [50.312606, 34.43772],
          [50.311772, 34.43772],
          [50.310659, 34.437169],
          [50.309549, 34.437169],
          [50.308439, 34.43661],
          [50.307606, 34.43661],
          [50.307049, 34.43633],
          [50.305383, 34.43633],
          [50.305106, 34.43605],
          [50.304272, 34.43605],
          [50.300939, 34.43633],
          [50.291769, 34.43772],
          [50.291216, 34.438],
          [50.290383, 34.437999],
          [50.289826, 34.438279],
          [50.288993, 34.438279],
          [50.281493, 34.441609],
          [50.280936, 34.442169],
          [50.277046, 34.443558],
          [50.276213, 34.444389],
          [50.275103, 34.444939],
          [50.271213, 34.452447],
          [50.269826, 34.452998],
          [50.266493, 34.452727],
          [50.266213, 34.452447],
          [50.26038, 34.452998],
          [50.259826, 34.453277],
          [50.254546, 34.453277],
          [50.25427, 34.453557],
          [50.252046, 34.453557],
          [50.251213, 34.453837],
          [50.247047, 34.453837],
          [50.24399, 34.454108],
          [50.243713, 34.454387],
          [50.24149, 34.454387],
          [50.240657, 34.454667],
          [50.239823, 34.454667],
          [50.239546, 34.454947],
          [50.238433, 34.454947],
          [50.228157, 34.457997],
          [50.221767, 34.459116],
          [50.215657, 34.460217],
          [50.214824, 34.460497],
          [50.213433, 34.460497],
          [50.1951, 34.464386],
          [50.19371, 34.464386],
          [50.193153, 34.464666],
          [50.19121, 34.464666],
          [50.190654, 34.464945],
          [50.188711, 34.464945],
          [50.185654, 34.465505],
          [50.178431, 34.465505],
          [50.177877, 34.465785],
          [50.175377, 34.465785],
          [50.165377, 34.467725],
          [50.163707, 34.467725],
          [50.159541, 34.468555],
          [50.148154, 34.473284],
          [50.146488, 34.475224],
          [50.146208, 34.476054],
          [50.145931, 34.476334],
          [50.146764, 34.478003],
          [50.147874, 34.480503],
          [50.149821, 34.483553],
          [50.157597, 34.490781],
          [50.158987, 34.503559],
          [50.157041, 34.509118],
          [50.148431, 34.516337],
          [50.144264, 34.518006],
          [50.118151, 34.536893],
          [50.117594, 34.538282],
          [50.112594, 34.545221],
          [50.110095, 34.54967],
          [50.105114, 34.557358],
          [50.104538, 34.557999],
          [50.102594, 34.560787],
          [50.100094, 34.563277],
          [50.098705, 34.564667],
          [50.091205, 34.572454],
          [50.088705, 34.578834],
          [50.086481, 34.582391],
          [50.086467, 34.583089],
          [50.086205, 34.583562],
          [50.086481, 34.586062],
          [50.101485, 34.611056],
          [50.101485, 34.611616],
          [50.108151, 34.623833],
          [50.109248, 34.632399],
          [50.110928, 34.645507],
          [50.111208, 34.646337],
          [50.110928, 34.646616],
          [50.110651, 34.650785],
          [50.110374, 34.652454],
          [50.110094, 34.653835],
          [50.109674, 34.654329],
          [50.107594, 34.656614],
          [50.102594, 34.664392],
          [50.102318, 34.665501],
          [50.102038, 34.66939],
          [50.103151, 34.675508],
          [50.108151, 34.684395],
          [50.111484, 34.686335],
          [50.113428, 34.689953],
          [50.113984, 34.690504],
          [50.114541, 34.693283],
          [50.113428, 34.695232],
          [50.113428, 34.695511],
          [50.112874, 34.697171],
          [50.113151, 34.698561],
          [50.121761, 34.710227],
          [50.124541, 34.715785],
          [50.124817, 34.719394],
          [50.123151, 34.724952],
          [50.120651, 34.733009],
          [50.120094, 34.733839],
          [50.119817, 34.734678],
          [50.119541, 34.738838],
          [50.119817, 34.739397],
          [50.119541, 34.740227],
          [50.119817, 34.743557],
          [50.120094, 34.744116],
          [50.120374, 34.745226],
          [50.120374, 34.746615],
          [50.121484, 34.751063],
          [50.122041, 34.755511],
          [50.131484, 34.774675],
          [50.15954, 34.81356],
          [50.15982, 34.814399],
          [50.163153, 34.817178],
          [50.163707, 34.818008],
          [50.165586, 34.819744],
          [50.16732, 34.821346],
          [50.168153, 34.821346],
          [50.16843, 34.821626],
          [50.168987, 34.821626],
          [50.169263, 34.821346],
          [50.174263, 34.821346],
          [50.18232, 34.819397],
          [50.182876, 34.819397],
          [50.185377, 34.818567],
          [50.213989, 34.814679],
          [50.214823, 34.814399],
          [50.217876, 34.814399],
          [50.228713, 34.81301],
          [50.229266, 34.81328],
          [50.242602, 34.81301],
          [50.245656, 34.81384],
          [50.246212, 34.814119],
          [50.248436, 34.815229],
          [50.251489, 34.814679],
          [50.251769, 34.814399],
          [50.252322, 34.814399],
          [50.252602, 34.814119],
          [50.253436, 34.814119],
          [50.258992, 34.811341],
          [50.259825, 34.811341],
          [50.268712, 34.807172],
          [50.270379, 34.806342],
          [50.312048, 34.781342],
          [50.313715, 34.779953],
          [50.327881, 34.768007],
          [50.341218, 34.759951],
          [50.347885, 34.75912],
          [50.348438, 34.758841],
          [50.358718, 34.758841],
          [50.361774, 34.75912],
          [50.366494, 34.75912],
          [50.367051, 34.758841],
          [50.368441, 34.758841],
          [50.368994, 34.758561],
          [50.370941, 34.758561],
          [50.394831, 34.752733],
          [50.401221, 34.751903],
          [50.401497, 34.751623],
          [50.405941, 34.751623],
          [50.406774, 34.751903],
          [50.409274, 34.751903],
          [50.415387, 34.754672],
          [50.415664, 34.754952],
          [50.41761, 34.755782],
          [50.418164, 34.756062],
          [50.428443, 34.754122],
          [50.434, 34.757451],
          [50.439833, 34.775234],
          [50.440667, 34.777733],
          [50.448723, 34.803564],
          [50.45011, 34.810791],
          [50.451223, 34.818008],
          [50.450666, 34.820507],
          [50.450943, 34.824396],
          [50.451223, 34.826904],
          [50.458443, 34.83635],
          [50.464276, 34.841348],
          [50.468166, 34.844397],
          [50.481779, 34.856622],
          [50.488169, 34.859671],
          [50.489279, 34.86023],
          [50.490112, 34.86023],
          [50.490389, 34.86051],
          [50.491503, 34.86051],
          [50.494836, 34.861899],
          [50.501779, 34.863839],
          [50.508726, 34.865237],
          [50.516502, 34.865788],
          [50.517892, 34.865517],
          [50.518445, 34.865237],
          [50.520112, 34.865237],
          [50.520669, 34.864958],
          [50.522612, 34.864958],
          [50.530392, 34.862179],
          [50.536225, 34.859671],
          [50.542058, 34.857461],
          [50.542892, 34.857461],
          [50.543449, 34.857181],
          [50.544282, 34.857461],
          [50.544558, 34.857181],
          [50.546225, 34.857181],
          [50.546505, 34.857461],
          [50.547339, 34.857181],
          [50.554558, 34.857731],
          [50.561505, 34.855512],
          [50.561781, 34.855232],
          [50.569282, 34.855232],
          [50.569838, 34.854953],
          [50.572062, 34.854953],
          [50.572895, 34.855232],
          [50.574281, 34.855232],
          [50.575115, 34.855512],
          [50.576505, 34.855512],
          [50.580118, 34.856901],
          [50.580952, 34.857181],
          [50.582338, 34.857461],
          [50.582618, 34.857731],
          [50.583451, 34.857731],
          [50.583728, 34.858011],
          [50.584561, 34.858011],
          [50.584838, 34.858291],
          [50.585952, 34.858291],
          [50.592338, 34.86051],
          [50.598171, 34.862729],
          [50.599841, 34.863839],
          [50.600951, 34.863839],
          [50.602894, 34.864678],
          [50.621231, 34.867727],
          [50.622618, 34.867727],
          [50.622898, 34.868007],
          [50.625398, 34.868007],
          [50.626784, 34.868287],
          [50.629564, 34.868287],
          [50.639007, 34.869676],
          [50.639841, 34.869956],
          [50.642341, 34.869956],
          [50.642897, 34.870235],
          [50.649841, 34.870235],
          [50.650674, 34.869956],
          [50.65234, 34.869956],
          [50.653454, 34.869676],
          [50.65651, 34.869676],
          [50.659287, 34.869405],
          [50.660121, 34.869126],
          [50.66151, 34.869126],
          [50.662064, 34.868846],
          [50.664287, 34.868846],
          [50.66512, 34.868567],
          [50.67151, 34.868566],
          [50.672063, 34.868287],
          [50.675677, 34.868287],
          [50.676234, 34.868567],
          [50.689567, 34.868567],
          [50.699567, 34.868007],
          [50.7029, 34.868007],
          [50.703457, 34.868287],
          [50.70401, 34.868287],
          [50.705123, 34.868287],
          [50.704843, 34.871345],
          [50.704566, 34.872734],
          [50.704843, 34.873564],
          [50.70401, 34.876623],
          [50.70429, 34.876902],
          [50.70401, 34.877182],
          [50.703177, 34.879961],
          [50.7029, 34.881341],
          [50.702623, 34.88301],
          [50.702343, 34.885789],
          [50.702343, 34.888288],
          [50.702343, 34.889677],
          [50.702067, 34.889957],
          [50.702067, 34.890787],
          [50.702067, 34.893845],
          [50.70179, 34.894124],
          [50.70151, 34.896344],
          [50.701233, 34.898013],
          [50.700957, 34.899961],
          [50.700677, 34.901621],
          [50.700123, 34.90412],
          [50.699843, 34.905239],
          [50.698456, 34.911067],
          [50.698176, 34.913006],
          [50.696233, 34.923841],
          [50.694566, 34.929957],
          [50.694567, 34.931067],
          [50.69429, 34.931626],
          [50.69429, 34.931897],
          [50.69429, 34.932456],
          [50.693457, 34.935794],
          [50.693457, 34.948568],
          [50.692343, 34.951897],
          [50.68512, 34.965239],
          [50.684567, 34.965789],
          [50.684567, 34.966348],
          [50.682344, 34.968568],
          [50.682067, 34.969406],
          [50.680953, 34.970516],
          [50.680677, 34.971066],
          [50.660397, 34.987737],
          [50.65873, 34.988567],
          [50.653454, 34.994403],
          [50.64984, 34.99968],
          [50.650674, 35.008286],
          [50.65123, 35.010234],
          [50.651787, 35.013843],
          [50.65373, 35.01829],
          [50.654287, 35.019399],
          [50.66512, 35.029963],
          [50.67151, 35.031902],
          [50.695676, 35.043574],
          [50.695957, 35.044125],
          [50.697066, 35.044684],
          [50.7004, 35.047742],
          [50.719566, 35.070518],
          [50.751236, 35.093015],
          [50.753736, 35.094683],
          [50.754012, 35.095242],
          [50.756792, 35.096623],
          [50.757069, 35.096911],
          [50.758459, 35.097182],
          [50.767345, 35.105796],
          [50.770402, 35.110793],
          [50.770682, 35.111632],
          [50.772069, 35.11413],
          [50.806515, 35.152464],
          [50.815961, 35.157461],
          [50.816515, 35.158021],
          [50.819571, 35.15913],
          [50.820405, 35.159689],
          [50.823181, 35.160798],
          [50.831795, 35.163847],
          [50.832628, 35.164406],
          [50.833461, 35.164406],
          [50.847074, 35.170801],
          [50.849009, 35.173029],
          [50.855407, 35.179685],
          [50.857074, 35.181625],
          [50.861241, 35.189959],
          [50.879851, 35.209963],
          [50.885407, 35.218017],
          [50.886241, 35.219686],
          [50.887074, 35.220516],
          [50.890687, 35.22746],
          [50.890964, 35.228579],
          [50.89124, 35.228858],
          [50.893187, 35.233855],
          [50.895964, 35.249692],
          [50.899574, 35.263852],
          [50.920967, 35.308584],
          [50.92291, 35.312191],
          [50.947356, 35.379413],
          [50.948466, 35.381911],
          [50.956799, 35.397466],
          [50.956523, 35.397746],
          [50.955966, 35.398855],
          [50.955966, 35.399135],
          [50.956243, 35.399135],
          [50.961523, 35.417188],
          [50.992072, 35.437134],
          [50.954972, 35.453729],
          [50.928011, 35.466255],
          [50.921434, 35.468377],
          [50.911414, 35.474381],
          [50.895604, 35.485092],
          [50.877907, 35.484691],
          [50.876517, 35.484971],
          [50.875683, 35.484971],
          [50.87513, 35.484971],
          [50.873184, 35.484971],
          [50.86735, 35.483582],
          [50.86374, 35.482473],
          [50.863464, 35.482194],
          [50.86263, 35.482194],
          [50.86235, 35.481914],
          [50.861517, 35.481914],
          [50.857627, 35.480805],
          [50.851517, 35.480805],
          [50.845127, 35.481355],
          [50.822347, 35.484412],
          [50.821794, 35.484691],
          [50.811238, 35.484691],
          [50.810404, 35.484412],
          [50.809294, 35.484412],
          [50.808461, 35.484141],
          [50.801791, 35.483582],
          [50.800958, 35.483582],
          [50.800404, 35.483303],
          [50.799571, 35.483582],
          [50.798738, 35.483303],
          [50.790404, 35.483303],
          [50.789571, 35.483582],
          [50.787625, 35.483582],
          [50.786791, 35.483862],
          [50.784848, 35.483862],
          [50.784291, 35.484141],
          [50.782348, 35.484141],
          [50.778735, 35.484971],
          [50.777625, 35.484971],
          [50.775958, 35.48553],
          [50.774568, 35.48553],
          [50.772625, 35.48608],
          [50.771515, 35.48608],
          [50.771235, 35.486359],
          [50.768458, 35.486918],
          [50.767625, 35.486919],
          [50.724289, 35.505529],
          [50.714289, 35.508027],
          [50.714289, 35.508307],
          [50.713732, 35.508307],
          [50.713179, 35.508866],
          [50.712346, 35.509136],
          [50.711789, 35.509695],
          [50.711509, 35.509966],
          [50.710122, 35.510525],
          [50.709842, 35.510804],
          [50.709289, 35.510804],
          [50.709289, 35.511084],
          [50.708455, 35.511084],
          [50.707342, 35.511634],
          [50.706509, 35.511634],
          [50.675953, 35.527197],
          [50.674566, 35.528026],
          [50.673452, 35.528306],
          [50.672619, 35.528865],
          [50.671509, 35.529135],
          [50.670952, 35.529694],
          [50.667619, 35.530803],
          [50.664562, 35.531921],
          [50.630116, 35.546077],
          [50.627063, 35.546916],
          [50.625949, 35.547475],
          [50.59345, 35.55219],
          [50.592617, 35.55247],
          [50.591503, 35.55247],
          [50.587337, 35.552749],
          [50.576227, 35.553579],
          [50.575394, 35.553858],
          [50.570947, 35.553858],
          [50.556504, 35.554967],
          [50.555114, 35.555247],
          [50.546224, 35.555247],
          [50.538724, 35.554697],
          [50.538447, 35.554417],
          [50.536501, 35.554417],
          [50.533167, 35.553308],
          [50.521224, 35.553579],
          [50.520391, 35.553858],
          [50.517058, 35.553858],
          [50.513724, 35.553858],
          [50.510111, 35.553858],
          [50.509001, 35.554138],
          [50.495944, 35.554138],
          [50.494835, 35.554417],
          [50.486501, 35.554417],
          [50.485388, 35.554697],
          [50.480388, 35.554697],
          [50.478165, 35.554967],
          [50.477331, 35.555247],
          [50.475388, 35.555247],
          [50.446218, 35.560521],
          [50.445385, 35.56081],
          [50.442052, 35.56081],
          [50.439552, 35.56136],
          [50.437608, 35.56136],
          [50.430942, 35.562748],
          [50.427328, 35.562748],
          [50.426219, 35.563028],
          [50.421495, 35.564975],
          [50.419552, 35.565525],
          [50.419276, 35.565805],
          [50.417609, 35.565805],
          [50.417052, 35.566084],
          [50.416088, 35.566084],
          [50.416073, 35.566084],
          [50.404272, 35.566364],
          [50.396772, 35.568023],
          [50.394078, 35.568329],
          [50.381539, 35.569201],
          [50.362049, 35.570809],
          [50.353419, 35.570602],
          [50.343436, 35.5697],
          [50.339569, 35.568611],
          [50.33316, 35.566923],
          [50.322327, 35.565525],
          [50.299823, 35.56191],
          [50.294823, 35.56108],
          [50.291767, 35.56108],
          [50.289267, 35.56081],
          [50.28649, 35.560251],
          [50.285657, 35.559971],
          [50.28149, 35.559421],
          [50.2751, 35.558033],
          [50.2751, 35.557744],
          [50.273157, 35.557474],
          [50.230377, 35.541641],
          [50.229544, 35.541361],
          [50.229544, 35.541082],
          [50.228988, 35.540523],
          [50.224264, 35.538025],
          [50.213431, 35.533309],
          [50.205931, 35.528864],
          [50.194265, 35.52441],
          [50.192598, 35.524139],
          [50.190651, 35.524139],
          [50.189818, 35.52386],
          [50.187318, 35.52386],
          [50.177875, 35.522192],
          [50.150095, 35.518585],
          [50.146762, 35.518585],
          [50.146205, 35.518305],
          [50.136482, 35.518305],
          [50.135649, 35.518026],
          [50.134262, 35.518026],
          [50.130649, 35.517746],
          [50.129539, 35.517467],
          [50.127039, 35.517467],
          [50.126482, 35.517187],
          [50.101759, 35.51386],
          [50.068703, 35.507747],
          [50.05509, 35.506358],
          [50.043147, 35.505528],
          [50.035923, 35.505528],
          [50.035367, 35.505249],
          [50.02981, 35.505249],
          [50.029257, 35.504969],
          [50.025367, 35.504969],
          [50.024257, 35.50469],
          [50.021477, 35.50469],
          [50.020924, 35.50441],
          [50.01981, 35.50441],
          [50.01481, 35.50441],
          [50.0137, 35.50414],
          [50.010367, 35.50414],
          [50.009254, 35.50386],
          [50.005367, 35.50386],
          [50.004254, 35.503581],
          [49.99231, 35.503581],
          [49.990644, 35.50386],
          [49.988144, 35.50386],
          [49.983977, 35.50441],
          [49.981754, 35.50441],
          [49.972307, 35.506087],
          [49.925361, 35.519135],
          [49.921194, 35.520803],
          [49.919251, 35.522191],
          [49.904528, 35.538583],
          [49.904251, 35.539413],
          [49.903971, 35.539692],
          [49.903418, 35.539972],
          [49.902305, 35.540522],
          [49.890638, 35.544976],
          [49.880358, 35.546915],
          [49.872582, 35.546915],
          [49.871192, 35.546635],
          [49.850636, 35.543858],
          [49.830635, 35.534697],
          [49.821189, 35.528863],
          [49.817856, 35.526636],
          [49.808136, 35.519134],
          [49.807856, 35.518855],
          [49.807579, 35.518305],
          [49.802023, 35.51441],
          [49.796746, 35.51358],
          [49.792856, 35.51331],
          [49.79258, 35.51358],
          [49.790633, 35.51358],
          [49.790356, 35.51386],
          [49.7698, 35.521921],
          [49.766467, 35.52275],
          [49.756743, 35.52358],
          [49.752853, 35.52358],
          [49.75202, 35.523859],
          [49.75091, 35.523859],
          [49.750077, 35.524139],
          [49.74313, 35.524698],
          [49.732853, 35.526357],
          [49.728407, 35.528025],
          [49.727854, 35.528584],
          [49.71424, 35.534418],
          [49.712854, 35.534418],
          [49.712297, 35.534697],
          [49.712021, 35.534418],
          [49.705631, 35.534417],
          [49.70535, 35.534138],
          [49.703964, 35.534138],
          [49.703684, 35.533858],
          [49.701184, 35.533858],
          [49.699797, 35.533858],
          [49.699517, 35.534138],
          [49.698964, 35.534138],
          [49.698407, 35.533858],
          [49.697851, 35.533579],
          [49.696464, 35.533579],
          [49.695631, 35.533858],
          [49.693131, 35.533858],
          [49.692574, 35.534138],
          [49.687574, 35.534138],
          [49.687294, 35.533858],
          [49.686184, 35.533858],
          [49.685907, 35.533579],
          [49.683961, 35.533308],
          [49.682851, 35.532749],
          [49.681741, 35.532749],
          [49.679517, 35.53219],
          [49.673961, 35.529413],
          [49.670351, 35.523579],
          [49.669794, 35.518305],
          [49.672571, 35.512191],
          [49.672571, 35.511912],
          [49.673961, 35.509964],
          [49.677851, 35.504139],
          [49.692574, 35.48386],
          [49.693684, 35.481083],
          [49.694241, 35.477467],
          [49.693131, 35.471083],
          [49.692574, 35.470244],
          [49.692294, 35.469694],
          [49.683961, 35.460803],
          [49.684241, 35.459694],
          [49.684794, 35.457747],
          [49.685628, 35.451912],
          [49.684794, 35.449694],
          [49.684241, 35.449135],
          [49.684241, 35.448856],
          [49.682294, 35.447196],
          [49.681461, 35.447196],
          [49.681185, 35.446908],
          [49.678961, 35.446637],
          [49.678961, 35.446358],
          [49.677018, 35.446358],
          [49.676461, 35.446078],
          [49.675628, 35.446078],
          [49.675075, 35.445799],
          [49.671738, 35.44524],
          [49.668405, 35.44524],
          [49.654238, 35.449135],
          [49.647848, 35.451083],
          [49.644515, 35.451083],
          [49.643961, 35.450803],
          [49.641461, 35.450803],
          [49.640348, 35.450524],
          [49.637571, 35.450524],
          [49.636462, 35.450244],
          [49.633681, 35.450244],
          [49.630348, 35.449965],
          [49.630072, 35.449694],
          [49.628405, 35.449694],
          [49.622849, 35.448856],
          [49.622291, 35.448576],
          [49.616182, 35.446078],
          [49.607569, 35.43358],
          [49.599515, 35.428025],
          [49.598682, 35.428025],
          [49.595902, 35.426907],
          [49.592569, 35.425798],
          [49.585902, 35.42358],
          [49.579792, 35.419414],
          [49.577846, 35.417186],
          [49.572012, 35.410243],
          [49.568679, 35.408304],
          [49.567846, 35.408304],
          [49.567569, 35.408024],
          [49.559232, 35.405247],
          [49.558123, 35.405247],
          [49.550899, 35.403579],
          [49.542704, 35.402709],
          [49.505535, 35.403058],
          [49.484229, 35.407245],
          [49.46388, 35.412285],
          [49.44562, 35.415751],
          [49.432284, 35.419413],
          [49.430894, 35.418854],
          [49.428951, 35.414968],
          [49.428674, 35.414408],
          [49.427561, 35.412749],
          [49.416171, 35.403858],
          [49.405338, 35.391639],
          [49.398948, 35.388023],
          [49.398394, 35.387743],
          [49.395338, 35.386354],
          [49.394504, 35.383856],
          [49.394228, 35.383018],
          [49.396171, 35.379131],
          [49.407004, 35.366912],
          [49.407281, 35.365523],
          [49.407004, 35.363855],
          [49.403394, 35.35802],
          [49.403394, 35.357749],
          [49.402561, 35.356911],
          [49.382558, 35.336079],
          [49.361168, 35.306634],
          [49.360059, 35.304965],
          [49.355615, 35.29969],
          [49.352558, 35.293575],
          [49.350612, 35.281355],
          [49.351169, 35.279966],
          [49.350892, 35.279686],
          [49.351445, 35.278018],
          [49.351725, 35.277188],
          [49.358115, 35.271353],
          [49.358668, 35.271353],
          [49.362282, 35.270523],
          [49.373672, 35.264959],
          [49.374782, 35.263299],
          [49.376448, 35.259133],
          [49.375892, 35.251629],
          [49.369225, 35.240238],
          [49.369225, 35.239139],
          [49.369225, 35.238849],
          [49.369225, 35.23691],
          [49.370335, 35.234962],
          [49.382005, 35.227179],
          [49.387838, 35.225519],
          [49.395895, 35.225519],
          [49.397005, 35.225799],
          [49.398948, 35.226069],
          [49.401448, 35.226069],
          [49.402005, 35.226349],
          [49.403115, 35.226349],
          [49.403671, 35.226628],
          [49.407281, 35.226629],
          [49.407838, 35.226908],
          [49.411172, 35.226908],
          [49.411451, 35.226629],
          [49.413395, 35.226629],
          [49.414228, 35.226349],
          [49.415338, 35.226349],
          [49.415895, 35.226069],
          [49.417838, 35.225799],
          [49.422285, 35.22413],
          [49.426171, 35.222182],
          [49.426451, 35.221912],
          [49.434508, 35.213848],
          [49.437841, 35.204965],
          [49.438118, 35.202187],
          [49.438394, 35.199959],
          [49.438674, 35.19857],
          [49.441451, 35.192743],
          [49.443951, 35.190236],
          [49.446451, 35.189406],
          [49.448118, 35.189406],
          [49.448951, 35.189127],
          [49.450898, 35.189127],
          [49.451174, 35.188847],
          [49.453951, 35.188847],
          [49.454231, 35.188567],
          [49.456731, 35.188297],
          [49.457008, 35.188017],
          [49.466174, 35.179124],
          [49.467841, 35.170799],
          [49.468121, 35.17024],
          [49.467841, 35.16941],
          [49.465897, 35.161626],
          [49.464508, 35.149405],
          [49.463397, 35.145517],
          [49.463118, 35.142739],
          [49.462841, 35.138293],
          [49.453951, 35.119676],
          [49.453675, 35.118575],
          [49.450341, 35.106903],
          [49.447285, 35.091903],
          [49.445341, 35.087177],
          [49.441175, 35.079401],
          [49.420618, 35.054126],
          [49.417562, 35.044953],
          [49.417285, 35.043573],
          [49.417005, 35.039955],
          [49.416172, 35.038007],
          [49.415062, 35.036627],
          [49.411172, 35.034119],
          [49.409505, 35.034119],
          [49.408949, 35.034399],
          [49.407838, 35.034399],
          [49.407562, 35.034678],
          [49.407005, 35.034678],
          [49.404229, 35.036627],
          [49.390615, 35.04746],
          [49.386449, 35.04857],
          [49.384225, 35.04857],
          [49.383672, 35.048849],
          [49.379506, 35.048849],
          [49.372006, 35.052737],
          [49.368116, 35.053287],
          [49.365059, 35.053288],
          [49.364226, 35.053567],
          [49.362836, 35.053567],
          [49.355616, 35.057464],
          [49.355336, 35.058294],
          [49.354502, 35.059403],
          [49.354782, 35.061072],
          [49.356449, 35.06385],
          [49.356726, 35.068568],
          [49.356449, 35.069127],
          [49.355336, 35.070516],
          [49.355059, 35.071626],
          [49.352559, 35.074124],
          [49.352002, 35.074124],
          [49.351726, 35.074404],
          [49.348946, 35.074404],
          [49.342836, 35.071905],
          [49.342003, 35.071905],
          [49.341726, 35.071626],
          [49.340613, 35.071626],
          [49.338393, 35.072455],
          [49.337836, 35.073015],
          [49.336726, 35.075513],
          [49.335613, 35.077462],
          [49.332836, 35.077732],
          [49.329779, 35.077732],
          [49.329503, 35.078012],
          [49.328946, 35.078012],
          [49.328389, 35.08024],
          [49.328113, 35.080511],
          [49.328113, 35.08107],
          [49.325056, 35.083018],
          [49.324223, 35.083018],
          [49.323946, 35.083298],
          [49.306446, 35.084957],
          [49.304223, 35.084398],
          [49.293943, 35.078571],
          [49.290333, 35.076073],
          [49.290056, 35.075793],
          [49.260887, 35.051619],
          [49.259777, 35.042183],
          [49.263944, 35.0319],
          [49.26561, 35.029401],
          [49.275887, 35.012181],
          [49.282833, 35.007734],
          [49.284223, 35.007734],
          [49.288943, 35.006065],
          [49.292557, 35.006065],
          [49.29339, 35.006345],
          [49.29728, 35.007734],
          [49.30589, 35.011622],
          [49.306723, 35.011622],
          [49.312556, 35.01274],
          [49.322003, 35.013841],
          [49.341169, 35.01357],
          [49.342279, 35.013841],
          [49.348393, 35.013291],
          [49.355616, 35.011901],
          [49.357836, 35.011071],
          [49.363669, 35.011072],
          [49.364782, 35.011351],
          [49.368392, 35.011622],
          [49.370336, 35.012181],
          [49.376449, 35.013841],
          [49.378392, 35.013841],
          [49.379226, 35.014121],
          [49.380059, 35.013841],
          [49.390896, 35.01357],
          [49.391172, 35.013291],
          [49.393116, 35.013291],
          [49.394782, 35.01274],
          [49.395896, 35.01274],
          [49.398672, 35.011901],
          [49.401729, 35.010512],
          [49.402562, 35.010512],
          [49.402839, 35.010233],
          [49.403672, 35.010233],
          [49.403948, 35.009953],
          [49.405062, 35.009682],
          [49.405895, 35.008843],
          [49.414228, 35.001627],
          [49.414228, 35.001068],
          [49.412839, 34.998289],
          [49.407562, 34.983567],
          [49.405062, 34.977731],
          [49.404782, 34.975791],
          [49.407005, 34.964957],
          [49.407282, 34.962738],
          [49.407562, 34.960789],
          [49.407839, 34.96051],
          [49.407839, 34.958291],
          [49.407839, 34.958011],
          [49.407562, 34.957452],
          [49.407282, 34.956071],
          [49.402282, 34.950235],
          [49.399506, 34.948846],
          [49.398672, 34.948846],
          [49.396729, 34.948007],
          [49.388949, 34.945228],
          [49.388392, 34.943289],
          [49.388673, 34.94162],
          [49.388949, 34.94079],
          [49.389226, 34.93968],
          [49.389506, 34.938571],
          [49.389782, 34.937452],
          [49.390339, 34.935233],
          [49.390616, 34.933843],
          [49.390896, 34.933564],
          [49.391172, 34.931624],
          [49.391449, 34.930235],
          [49.391729, 34.929116],
          [49.392006, 34.927456],
          [49.392282, 34.926897],
          [49.392006, 34.926067],
          [49.389782, 34.917452],
          [49.388116, 34.915233],
          [49.383393, 34.913284],
          [49.382559, 34.913284],
          [49.382283, 34.913004],
          [49.377839, 34.911904],
          [49.375893, 34.911904],
          [49.375339, 34.911624],
          [49.371449, 34.911624],
          [49.371169, 34.911904],
          [49.369503, 34.911904],
          [49.367836, 34.912454],
          [49.365893, 34.912734],
          [49.358393, 34.914403],
          [49.356726, 34.914403],
          [49.355893, 34.914682],
          [49.349503, 34.915783],
          [49.347836, 34.915783],
          [49.347003, 34.916063],
          [49.344226, 34.916062],
          [49.343393, 34.916342],
          [49.340893, 34.916342],
          [49.340059, 34.916622],
          [49.338393, 34.916622],
          [49.337836, 34.916342],
          [49.331723, 34.916342],
          [49.33117, 34.916062],
          [49.328946, 34.916063],
          [49.327837, 34.915512],
          [49.326446, 34.915512],
          [49.32589, 34.915232],
          [49.32478, 34.915233],
          [49.324223, 34.914953],
          [49.32117, 34.914403],
          [49.31839, 34.914403],
          [49.317557, 34.914123],
          [49.31589, 34.914123],
          [49.315613, 34.913843],
          [49.314224, 34.913843],
          [49.313947, 34.913564],
          [49.312557, 34.913564],
          [49.307, 34.912454],
          [49.306167, 34.912454],
          [49.305614, 34.912454],
          [49.3045, 34.911904],
          [49.303667, 34.911904],
          [49.298114, 34.909396],
          [49.296823, 34.908906],
          [49.29339, 34.907456],
          [49.29311, 34.906897],
          [49.292557, 34.906617],
          [49.273667, 34.891064],
          [49.273111, 34.890784],
          [49.271721, 34.890234],
          [49.270887, 34.890234],
          [49.270611, 34.889955],
          [49.269777, 34.889954],
          [49.268668, 34.889395],
          [49.26811, 34.889395],
          [49.267554, 34.889395],
          [49.254221, 34.884677],
          [49.253111, 34.883847],
          [49.234774, 34.863837],
          [49.233388, 34.861627],
          [49.233388, 34.861347],
          [49.229498, 34.853011],
          [49.225608, 34.850512],
          [49.218665, 34.846624],
          [49.218384, 34.846064],
          [49.211718, 34.842176],
          [49.209498, 34.842176],
          [49.206718, 34.843836],
          [49.205885, 34.846064],
          [49.205608, 34.847174],
          [49.205051, 34.848004],
          [49.205051, 34.848283],
          [49.204498, 34.851342],
          [49.204218, 34.853011],
          [49.202551, 34.857729],
          [49.201718, 34.858568],
          [49.196441, 34.861347],
          [49.195608, 34.861347],
          [49.192828, 34.862177],
          [49.191718, 34.862177],
          [49.180885, 34.864676],
          [49.178938, 34.865786],
          [49.178662, 34.865786],
          [49.177829, 34.865515],
          [49.175052, 34.866345],
          [49.169772, 34.866345],
          [49.169219, 34.866065],
          [49.168105, 34.866065],
          [49.163105, 34.860788],
          [49.162829, 34.858839],
          [49.163662, 34.85662],
          [49.168105, 34.849402],
          [49.167829, 34.849122],
          [49.167272, 34.846894],
          [49.163939, 34.841896],
          [49.161996, 34.839117],
          [49.158662, 34.83273],
          [49.158662, 34.8319],
          [49.157549, 34.828562],
          [49.154495, 34.80662],
          [49.149216, 34.797174],
          [49.142549, 34.791066],
          [49.139216, 34.788558],
          [49.115326, 34.772733],
          [49.108936, 34.766616],
          [49.108659, 34.766336],
          [49.10727, 34.764956],
          [49.10727, 34.764676],
          [49.10338, 34.75551],
          [49.103103, 34.753561],
          [49.102827, 34.750511],
          [49.102546, 34.737726],
          [49.105046, 34.728009],
          [49.105603, 34.72745],
          [49.121159, 34.699669],
          [49.123936, 34.69523],
          [49.124493, 34.694391],
          [49.128106, 34.686063],
          [49.131159, 34.676616],
          [49.131439, 34.676066],
          [49.131439, 34.674396],
          [49.131716, 34.674117],
          [49.131716, 34.673557],
          [49.131716, 34.671617],
          [49.131993, 34.669389],
          [49.132273, 34.668279],
          [49.13255, 34.666889],
          [49.132826, 34.665229],
          [49.133106, 34.66356],
          [49.133383, 34.660501],
          [49.133659, 34.659951],
          [49.133659, 34.655223],
          [49.13505, 34.648004],
          [49.132826, 34.636617],
          [49.135326, 34.629119],
          [49.13505, 34.628],
          [49.134773, 34.622171],
          [49.13505, 34.620502],
          [49.135326, 34.618833],
          [49.135606, 34.618273],
          [49.134773, 34.613554],
          [49.127826, 34.603006],
          [49.120327, 34.597168],
          [49.11977, 34.596888],
          [49.118937, 34.596329],
          [49.118103, 34.596058],
          [49.106993, 34.58661],
          [49.10616, 34.58661],
          [49.105604, 34.586331],
          [49.102827, 34.58578],
          [49.100327, 34.58578],
          [49.099494, 34.585501],
          [49.09727, 34.58523],
          [49.093937, 34.58523],
          [49.09338, 34.58495],
          [49.090604, 34.58495],
          [49.090047, 34.58467],
          [49.08699, 34.58467],
          [49.086437, 34.584391],
          [49.083104, 34.584391],
          [49.082547, 34.58467],
          [49.081157, 34.58467],
          [49.08088, 34.58495],
          [49.080324, 34.58495],
          [49.080047, 34.58523],
          [49.077547, 34.58606],
          [49.075324, 34.588],
          [49.074214, 34.590229],
          [49.073937, 34.590779],
          [49.074214, 34.591609],
          [49.073937, 34.592449],
          [49.074214, 34.593279],
          [49.074214, 34.605505],
          [49.070047, 34.622722],
          [49.070047, 34.62856],
          [49.06838, 34.632169],
          [49.067267, 34.632999],
          [49.06338, 34.634668],
          [49.062547, 34.634668],
          [49.06199, 34.634948],
          [49.060047, 34.634948],
          [49.055047, 34.632999],
          [49.044211, 34.616333],
          [49.039767, 34.614393],
          [49.038378, 34.614393],
          [49.035601, 34.613554],
          [49.031991, 34.613554],
          [49.031154, 34.613274],
          [49.023934, 34.612164],
          [49.020878, 34.609394],
          [49.019768, 34.607725],
          [49.018654, 34.606056],
          [49.011431, 34.601887],
          [49.009764, 34.601887],
          [49.009764, 34.601607],
          [49.002821, 34.599117],
          [49.001155, 34.597447],
          [49.001431, 34.597168],
          [49.005878, 34.590779],
          [49.007821, 34.58689],
          [49.008655, 34.58606],
          [49.019488, 34.569944],
          [49.020321, 34.569394],
          [49.028378, 34.561336],
          [49.028654, 34.560505],
          [49.029211, 34.560226],
          [49.031435, 34.554667],
          [49.031711, 34.554388],
          [49.031711, 34.551888],
          [49.031711, 34.548558],
          [49.033101, 34.54522],
          [49.033658, 34.54494],
          [49.033934, 34.544389],
          [49.039491, 34.53634],
          [49.040044, 34.530222],
          [49.044768, 34.521884],
          [49.053934, 34.510776],
          [49.054768, 34.507997],
          [49.054768, 34.507437],
          [49.054768, 34.505217],
          [49.053101, 34.501337],
          [49.049211, 34.498829],
          [49.033934, 34.496058],
          [49.031435, 34.495219],
          [49.019768, 34.479391],
          [49.011988, 34.472442],
          [49.010321, 34.471053],
          [49.003655, 34.465783],
          [49.003655, 34.465223],
          [49.003098, 34.462444],
          [49.006712, 34.459385],
          [49.007265, 34.459114],
          [49.007265, 34.458834],
          [49.014768, 34.451606],
          [49.015602, 34.447726],
          [49.015322, 34.445497],
          [49.010878, 34.436048],
          [49.007822, 34.431329],
          [49.005322, 34.42577],
          [49.003655, 34.423829],
          [49.000598, 34.42216],
          [48.998378, 34.421609],
          [48.996155, 34.420779],
          [48.994765, 34.420779],
          [48.994212, 34.420499],
          [48.988099, 34.420499],
          [48.987542, 34.42022],
          [48.986432, 34.42022],
          [48.985875, 34.41994],
          [48.984209, 34.41994],
          [48.982822, 34.419389],
          [48.981989, 34.419389],
          [48.977542, 34.41744],
          [48.966152, 34.409941],
          [48.955596, 34.404382],
          [48.952819, 34.401611],
          [48.951429, 34.399662],
          [48.951152, 34.399103],
          [48.949763, 34.389663],
        ],
      ],
    },
  },
  Mazandaran: {
    properties: { value: "Mazandaran", name: "استان مازندران, ایران", id: 24 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [50.359824, 36.641666],
          [50.360657, 36.641387],
          [50.360937, 36.641117],
          [50.361771, 36.641117],
          [50.362047, 36.640837],
          [50.365937, 36.638883],
          [50.409797, 36.62237],
          [50.410383, 36.622223],
          [50.41094, 36.621944],
          [50.431496, 36.616387],
          [50.434273, 36.615829],
          [50.446216, 36.610552],
          [50.448996, 36.608886],
          [50.486219, 36.590829],
          [50.508713, 36.577226],
          [50.508713, 36.577226],
          [50.508722, 36.57722],
          [50.509832, 36.576662],
          [50.533165, 36.561108],
          [50.533999, 36.56055],
          [50.554555, 36.548607],
          [50.558445, 36.545554],
          [50.592044, 36.526885],
          [50.592044, 36.526885],
          [50.592058, 36.526877],
          [50.592891, 36.526667],
          [50.596781, 36.525829],
          [50.597892, 36.52528],
          [50.598725, 36.52528],
          [50.605948, 36.523055],
          [50.607058, 36.523055],
          [50.618171, 36.520831],
          [50.627895, 36.519164],
          [50.663727, 36.507771],
          [50.667451, 36.506049],
          [50.668451, 36.505546],
          [50.679841, 36.499998],
          [50.68595, 36.496386],
          [50.68734, 36.495828],
          [50.688451, 36.494999],
          [50.744843, 36.459159],
          [50.746233, 36.457772],
          [50.76401, 36.448332],
          [50.764843, 36.448332],
          [50.767066, 36.447494],
          [50.768733, 36.446386],
          [50.773456, 36.443053],
          [50.787346, 36.437495],
          [50.834569, 36.404716],
          [50.838736, 36.400554],
          [50.842349, 36.394716],
          [50.842626, 36.393599],
          [50.845125, 36.388888],
          [50.845126, 36.388329],
          [50.845682, 36.386663],
          [50.853182, 36.362493],
          [50.856515, 36.345548],
          [50.849016, 36.313602],
          [50.848739, 36.310269],
          [50.847916, 36.294086],
          [50.847913, 36.294016],
          [50.850126, 36.292764],
          [50.850682, 36.292764],
          [50.851239, 36.292214],
          [50.852072, 36.291935],
          [50.852906, 36.291106],
          [50.874015, 36.282214],
          [50.884852, 36.278601],
          [50.885405, 36.278042],
          [50.896519, 36.274988],
          [50.907908, 36.272772],
          [50.909018, 36.272772],
          [50.910962, 36.272213],
          [50.913185, 36.271934],
          [50.917352, 36.270826],
          [50.918187, 36.270825],
          [50.929032, 36.267404],
          [50.938465, 36.264429],
          [50.944575, 36.263321],
          [50.946241, 36.263321],
          [50.950688, 36.262213],
          [50.963744, 36.256933],
          [50.965131, 36.256654],
          [50.975131, 36.251933],
          [50.975964, 36.251383],
          [50.985134, 36.249716],
          [50.993188, 36.245545],
          [50.999857, 36.239157],
          [51.003191, 36.238877],
          [51.014857, 36.236382],
          [51.016247, 36.236382],
          [51.03597, 36.231102],
          [51.066803, 36.218875],
          [51.094583, 36.209433],
          [51.095973, 36.208595],
          [51.104583, 36.20554],
          [51.11375, 36.202765],
          [51.11653, 36.201657],
          [51.120416, 36.200548],
          [51.121249, 36.19999],
          [51.123473, 36.199431],
          [51.175976, 36.173598],
          [51.176532, 36.173319],
          [51.201532, 36.162488],
          [51.202642, 36.162488],
          [51.210422, 36.165273],
          [51.216812, 36.166931],
          [51.235145, 36.174157],
          [51.240145, 36.176932],
          [51.249591, 36.180546],
          [51.250701, 36.180546],
          [51.252091, 36.181104],
          [51.263758, 36.184159],
          [51.267924, 36.184159],
          [51.274868, 36.181933],
          [51.275424, 36.181375],
          [51.278204, 36.179708],
          [51.296538, 36.166651],
          [51.300704, 36.163597],
          [51.315984, 36.154433],
          [51.318204, 36.154433],
          [51.319037, 36.154153],
          [51.321261, 36.154153],
          [51.32237, 36.153874],
          [51.337094, 36.152766],
          [51.34126, 36.152766],
          [51.34265, 36.152486],
          [51.34876, 36.152486],
          [51.352093, 36.151928],
          [51.361264, 36.151378],
          [51.367373, 36.151378],
          [51.368763, 36.151099],
          [51.376263, 36.151099],
          [51.377653, 36.150819],
          [51.39293, 36.150819],
          [51.400986, 36.150819],
          [51.402653, 36.151099],
          [51.41321, 36.151099],
          [51.413766, 36.151378],
          [51.428766, 36.151378],
          [51.430156, 36.151099],
          [51.431823, 36.151099],
          [51.432933, 36.15082],
          [51.434599, 36.150819],
          [51.435709, 36.15054],
          [51.437376, 36.15054],
          [51.440709, 36.149991],
          [51.442656, 36.14999],
          [51.444876, 36.149432],
          [51.445709, 36.148594],
          [51.45929, 36.140103],
          [51.459297, 36.1401],
          [51.459879, 36.139709],
          [51.460432, 36.13943],
          [51.474045, 36.129986],
          [51.480436, 36.126103],
          [51.485989, 36.122768],
          [51.487379, 36.12166],
          [51.492935, 36.118876],
          [51.493769, 36.118047],
          [51.494325, 36.118046],
          [51.553215, 36.09915],
          [51.572938, 36.094987],
          [51.586551, 36.090265],
          [51.594327, 36.086092],
          [51.597941, 36.084155],
          [51.598494, 36.084155],
          [51.599051, 36.083596],
          [51.653777, 36.060265],
          [51.654887, 36.059707],
          [51.6935, 36.042764],
          [51.699613, 36.039709],
          [51.707113, 36.036934],
          [51.714534, 36.0335],
          [51.715446, 36.03304],
          [51.727419, 36.026636],
          [51.728503, 36.026101],
          [51.760449, 36.006653],
          [51.769892, 36.000227],
          [51.779892, 35.980814],
          [51.814338, 35.952479],
          [51.824618, 35.941925],
          [51.829135, 35.934602],
          [51.847398, 35.90498],
          [51.850732, 35.900536],
          [51.857398, 35.886088],
          [51.857398, 35.885538],
          [51.860731, 35.878877],
          [51.877674, 35.860586],
          [51.878231, 35.859985],
          [51.882121, 35.8572],
          [51.934067, 35.828869],
          [51.984347, 35.798591],
          [51.99296, 35.793308],
          [52.006293, 35.786647],
          [52.01046, 35.7847],
          [52.02685, 35.778588],
          [52.037683, 35.773864],
          [52.051573, 35.768591],
          [52.058796, 35.766644],
          [52.060186, 35.766094],
          [52.072686, 35.763317],
          [52.095742, 35.76165],
          [52.096575, 35.761361],
          [52.110189, 35.761361],
          [52.112132, 35.76165],
          [52.118522, 35.76165],
          [52.122688, 35.76192],
          [52.129355, 35.7622],
          [52.137412, 35.7622],
          [52.155188, 35.763029],
          [52.156021, 35.763317],
          [52.158521, 35.763029],
          [52.175191, 35.763029],
          [52.177135, 35.763317],
          [52.184078, 35.763317],
          [52.190748, 35.763588],
          [52.191858, 35.763867],
          [52.194634, 35.763867],
          [52.195468, 35.764147],
          [52.197968, 35.764147],
          [52.199081, 35.764426],
          [52.201024, 35.764426],
          [52.202414, 35.764697],
          [52.204637, 35.764697],
          [52.209081, 35.765256],
          [52.210747, 35.765256],
          [52.211304, 35.765535],
          [52.213524, 35.765535],
          [52.214638, 35.765814],
          [52.215471, 35.765535],
          [52.216581, 35.765535],
          [52.217137, 35.765814],
          [52.217414, 35.765535],
          [52.217971, 35.765535],
          [52.218247, 35.765814],
          [52.220191, 35.765814],
          [52.226027, 35.766923],
          [52.227414, 35.767482],
          [52.237414, 35.7697],
          [52.240747, 35.771088],
          [52.244637, 35.773035],
          [52.26575, 35.797482],
          [52.266583, 35.798591],
          [52.29214, 35.815538],
          [52.319643, 35.826372],
          [52.326586, 35.82832],
          [52.378256, 35.848592],
          [52.378532, 35.848871],
          [52.379365, 35.84943],
          [52.394369, 35.861644],
          [52.409088, 35.883033],
          [52.412425, 35.887476],
          [52.415202, 35.891091],
          [52.415758, 35.892209],
          [52.417656, 35.894107],
          [52.435758, 35.912209],
          [52.462704, 35.92526],
          [52.463538, 35.92526],
          [52.466314, 35.926378],
          [52.468538, 35.926927],
          [52.470204, 35.927766],
          [52.474094, 35.928874],
          [52.475204, 35.929424],
          [52.479094, 35.930262],
          [52.479648, 35.930262],
          [52.480516, 35.930437],
          [52.493537, 35.933318],
          [52.494371, 35.933318],
          [52.494927, 35.933597],
          [52.495761, 35.933597],
          [52.499927, 35.933867],
          [52.54104, 35.940537],
          [52.541873, 35.940258],
          [52.54354, 35.940537],
          [52.54604, 35.940537],
          [52.548263, 35.941096],
          [52.567986, 35.943313],
          [52.568543, 35.943043],
          [52.57493, 35.943043],
          [52.575763, 35.943313],
          [52.57771, 35.943313],
          [52.57993, 35.943043],
          [52.581043, 35.943313],
          [52.583266, 35.943043],
          [52.602419, 35.943043],
          [52.606599, 35.943043],
          [52.607709, 35.942763],
          [52.612989, 35.942763],
          [52.614933, 35.942484],
          [52.619656, 35.942484],
          [52.622713, 35.941925],
          [52.643546, 35.940537],
          [52.644379, 35.940817],
          [52.647712, 35.940258],
          [52.648822, 35.940537],
          [52.650212, 35.940258],
          [52.652436, 35.940258],
          [52.653822, 35.939978],
          [52.656045, 35.939978],
          [52.657159, 35.939708],
          [52.658545, 35.939708],
          [52.659102, 35.939429],
          [52.660212, 35.939428],
          [52.661679, 35.93904],
          [52.683269, 35.933318],
          [52.684102, 35.933318],
          [52.692435, 35.930821],
          [52.693548, 35.930821],
          [52.694938, 35.930262],
          [52.699382, 35.929424],
          [52.701605, 35.928595],
          [52.704938, 35.928036],
          [52.705772, 35.927486],
          [52.796607, 35.889424],
          [52.813885, 35.879815],
          [52.814664, 35.879428],
          [52.815777, 35.879148],
          [52.821887, 35.876372],
          [52.822997, 35.876093],
          [52.828178, 35.874102],
          [52.82911, 35.873875],
          [52.8705, 35.859426],
          [52.87189, 35.858588],
          [52.877446, 35.85665],
          [52.917169, 35.839146],
          [52.918002, 35.839146],
          [52.920783, 35.837757],
          [52.921616, 35.837757],
          [52.935782, 35.834143],
          [52.936892, 35.833872],
          [52.937449, 35.833872],
          [52.943006, 35.832755],
          [52.944949, 35.832755],
          [52.945506, 35.832484],
          [52.946892, 35.832484],
          [52.948006, 35.832205],
          [52.950782, 35.832205],
          [52.951616, 35.831925],
          [52.961339, 35.831925],
          [52.962729, 35.832205],
          [52.963282, 35.831925],
          [52.964395, 35.832205],
          [52.966339, 35.832205],
          [52.967449, 35.832484],
          [52.970782, 35.832484],
          [52.987452, 35.83554],
          [53.003842, 35.843589],
          [53.004675, 35.844428],
          [53.006895, 35.845536],
          [53.007175, 35.846095],
          [53.010785, 35.848313],
          [53.011061, 35.848592],
          [53.017451, 35.853595],
          [53.015231, 35.854983],
          [53.015785, 35.8572],
          [53.021341, 35.866376],
          [53.022451, 35.869982],
          [53.022731, 35.877201],
          [53.022451, 35.87804],
          [53.020785, 35.884151],
          [53.024675, 35.889153],
          [53.030508, 35.889153],
          [53.039398, 35.896652],
          [53.039955, 35.897202],
          [53.045231, 35.902205],
          [53.064954, 35.914985],
          [53.074401, 35.917482],
          [53.076344, 35.917482],
          [53.078011, 35.917761],
          [53.087454, 35.917761],
          [53.090511, 35.91832],
          [53.093844, 35.91832],
          [53.097457, 35.91887],
          [53.100234, 35.91887],
          [53.101557, 35.919092],
          [53.1019, 35.919149],
          [53.10329, 35.919149],
          [53.104124, 35.91887],
          [53.107457, 35.919149],
          [53.108567, 35.91887],
          [53.112457, 35.919149],
          [53.114957, 35.919149],
          [53.116624, 35.919429],
          [53.119123, 35.919429],
          [53.120513, 35.919708],
          [53.127457, 35.919708],
          [53.12857, 35.919429],
          [53.132457, 35.919429],
          [53.133013, 35.919708],
          [53.134123, 35.919708],
          [53.134957, 35.919708],
          [53.13857, 35.919708],
          [53.14607, 35.920817],
          [53.14857, 35.920817],
          [53.150513, 35.921096],
          [53.153847, 35.920817],
          [53.15718, 35.920817],
          [53.159403, 35.920537],
          [53.163013, 35.920537],
          [53.16746, 35.919979],
          [53.16857, 35.920267],
          [53.174403, 35.920267],
          [53.186906, 35.921655],
          [53.212739, 35.92526],
          [53.214682, 35.925819],
          [53.215796, 35.925819],
          [53.216349, 35.926098],
          [53.217462, 35.926099],
          [53.220516, 35.926928],
          [53.223296, 35.928316],
          [53.228296, 35.93248],
          [53.230519, 35.941367],
          [53.228296, 35.949433],
          [53.228019, 35.949983],
          [53.224962, 35.95804],
          [53.224686, 35.959149],
          [53.223572, 35.964151],
          [53.223375, 35.965539],
          [53.223296, 35.966098],
          [53.224686, 35.967486],
          [53.224686, 35.968044],
          [53.263575, 35.993315],
          [53.284408, 36.000228],
          [53.288855, 36.006095],
          [53.289131, 36.006654],
          [53.290242, 36.008321],
          [53.295521, 36.013873],
          [53.302151, 36.018845],
          [53.322188, 36.03387],
          [53.344967, 36.047767],
          [53.346077, 36.048596],
          [53.347467, 36.049155],
          [53.348129, 36.049598],
          [53.359414, 36.055265],
          [53.361081, 36.055823],
          [53.368857, 36.059428],
          [53.370524, 36.060266],
          [53.385524, 36.064988],
          [53.390524, 36.066376],
          [53.40108, 36.068881],
          [53.43164, 36.073324],
          [53.43775, 36.073324],
          [53.43914, 36.073603],
          [53.44414, 36.073604],
          [53.444973, 36.073324],
          [53.44636, 36.073604],
          [53.452473, 36.073604],
          [53.467196, 36.074703],
          [53.474696, 36.07582],
          [53.482473, 36.07637],
          [53.484139, 36.076658],
          [53.489696, 36.076658],
          [53.494976, 36.076929],
          [53.503586, 36.078046],
          [53.508309, 36.078596],
          [53.509142, 36.078875],
          [53.511366, 36.078875],
          [53.562755, 36.08749],
          [53.567202, 36.08749],
          [53.568035, 36.087761],
          [53.569145, 36.08749],
          [53.573588, 36.08749],
          [53.574978, 36.087211],
          [53.578588, 36.087211],
          [53.579978, 36.086932],
          [53.583592, 36.086932],
          [53.584425, 36.086652],
          [53.594145, 36.086652],
          [53.595811, 36.086382],
          [53.608868, 36.086382],
          [53.609981, 36.086652],
          [53.614701, 36.086652],
          [53.616648, 36.086932],
          [53.619424, 36.086932],
          [53.621091, 36.087211],
          [53.623871, 36.087211],
          [53.635538, 36.088599],
          [53.645814, 36.090545],
          [53.648594, 36.091095],
          [53.655261, 36.091933],
          [53.656371, 36.091933],
          [53.658317, 36.092492],
          [53.659427, 36.092492],
          [53.67915, 36.098322],
          [53.711373, 36.116381],
          [53.713873, 36.117489],
          [53.714987, 36.118048],
          [53.717487, 36.119156],
          [53.741653, 36.13971],
          [53.74193, 36.140269],
          [53.746096, 36.144153],
          [53.755266, 36.152767],
          [53.781099, 36.171374],
          [53.782316, 36.172108],
          [53.789989, 36.176654],
          [53.827769, 36.193603],
          [53.864438, 36.207218],
          [53.866381, 36.208047],
          [53.867215, 36.208596],
          [53.876381, 36.212768],
          [53.878881, 36.214435],
          [53.880825, 36.215273],
          [53.887495, 36.219994],
          [53.908051, 36.236663],
          [53.927774, 36.257493],
          [53.928327, 36.258602],
          [53.929717, 36.259989],
          [53.949164, 36.293324],
          [53.949717, 36.293604],
          [53.953884, 36.305829],
          [53.953884, 36.309721],
          [53.953884, 36.309991],
          [53.951107, 36.3161],
          [53.950831, 36.317217],
          [53.950274, 36.317775],
          [53.949997, 36.318604],
          [53.940274, 36.327496],
          [53.918051, 36.343883],
          [53.889715, 36.357766],
          [53.887495, 36.359441],
          [53.885271, 36.361108],
          [53.884438, 36.361387],
          [53.881658, 36.364162],
          [53.879991, 36.367216],
          [53.879991, 36.369441],
          [53.879991, 36.36999],
          [53.879715, 36.37027],
          [53.881105, 36.376656],
          [53.881381, 36.377774],
          [53.910551, 36.413887],
          [53.913328, 36.416662],
          [53.931661, 36.432498],
          [53.939161, 36.437497],
          [53.960554, 36.444721],
          [53.97083, 36.446946],
          [53.97222, 36.446946],
          [53.97333, 36.447217],
          [53.974164, 36.446946],
          [53.977774, 36.446946],
          [53.97833, 36.446667],
          [53.981664, 36.446667],
          [53.986968, 36.44633],
          [53.999934, 36.444787],
          [54.0125, 36.44555],
          [54.020833, 36.448334],
          [54.02639, 36.45],
          [54.026943, 36.450279],
          [54.027634, 36.450413],
          [54.029443, 36.451108],
          [54.03, 36.451387],
          [54.054169, 36.46361],
          [54.073059, 36.471942],
          [54.093059, 36.478887],
          [54.104169, 36.484165],
          [54.125559, 36.49111],
          [54.126115, 36.491659],
          [54.126115, 36.496667],
          [54.126392, 36.497217],
          [54.126672, 36.499162],
          [54.126949, 36.500558],
          [54.127225, 36.501666],
          [54.127782, 36.503332],
          [54.127782, 36.503891],
          [54.128895, 36.506665],
          [54.129172, 36.50916],
          [54.129449, 36.511385],
          [54.130282, 36.516113],
          [54.130562, 36.51805],
          [54.128895, 36.542782],
          [54.130005, 36.550555],
          [54.128059, 36.570279],
          [54.127505, 36.572494],
          [54.117225, 36.597775],
          [54.114725, 36.603331],
          [54.113059, 36.606384],
          [54.104449, 36.615831],
          [54.099169, 36.618884],
          [54.098336, 36.619722],
          [54.069956, 36.632359],
          [54.04778, 36.642227],
          [54.03139, 36.64528],
          [54.0275, 36.64528],
          [54.016943, 36.648062],
          [54.015833, 36.648062],
          [54.015, 36.648332],
          [54.01361, 36.648332],
          [54.013053, 36.648612],
          [54.011943, 36.648612],
          [54.010553, 36.64917],
          [54.009443, 36.64917],
          [54.008887, 36.649449],
          [54.008053, 36.649449],
          [54.007777, 36.649728],
          [54.006667, 36.649728],
          [54.00611, 36.649998],
          [54.00361, 36.649998],
          [54.003053, 36.650278],
          [54.000277, 36.650557],
          [53.996943, 36.651394],
          [53.99361, 36.653339],
          [53.99222, 36.654447],
          [53.97444, 36.66945],
          [53.973847, 36.669847],
          [53.96472, 36.675285],
          [53.951107, 36.676663],
          [53.949997, 36.676942],
          [53.947217, 36.676942],
          [53.946539, 36.676771],
          [53.939161, 36.676663],
          [53.938327, 36.676942],
          [53.932774, 36.676942],
          [53.929994, 36.676393],
          [53.928051, 36.676393],
          [53.926661, 36.676114],
          [53.924441, 36.676114],
          [53.923327, 36.675834],
          [53.920551, 36.675555],
          [53.913051, 36.673061],
          [53.906384, 36.671386],
          [53.904438, 36.671386],
          [53.897214, 36.670008],
          [53.895828, 36.670008],
          [53.894995, 36.669729],
          [53.893605, 36.669729],
          [53.892771, 36.66945],
          [53.891105, 36.66945],
          [53.888328, 36.66945],
          [53.887215, 36.669171],
          [53.882771, 36.669171],
          [53.874715, 36.671116],
          [53.873325, 36.671386],
          [53.871135, 36.672555],
          [53.869158, 36.67361],
          [53.864715, 36.679454],
          [53.861935, 36.686109],
          [53.862215, 36.690278],
          [53.862215, 36.692503],
          [53.862215, 36.693619],
          [53.861935, 36.694168],
          [53.861382, 36.696113],
          [53.860825, 36.697221],
          [53.860825, 36.698896],
          [53.862215, 36.704443],
          [53.862492, 36.705001],
          [53.862492, 36.723055],
          [53.862215, 36.723335],
          [53.861935, 36.725],
          [53.861658, 36.730007],
          [53.861102, 36.733339],
          [53.860268, 36.735842],
          [53.860548, 36.739173],
          [53.860825, 36.739723],
          [53.864438, 36.744729],
          [53.865272, 36.746116],
          [53.866105, 36.749447],
          [53.861658, 36.76362],
          [53.862215, 36.776954],
          [53.862492, 36.780007],
          [53.862215, 36.780565],
          [53.862491, 36.780844],
          [53.862215, 36.781123],
          [53.862215, 36.781559],
          [53.862177, 36.782372],
          [53.857416, 36.883628],
          [53.856537, 36.902308],
          [53.854805, 36.939096],
          [53.852669, 36.984442],
          [53.736989, 36.973881],
          [53.710911, 36.9715],
          [53.705234, 36.970982],
          [53.639773, 36.965004],
          [53.569596, 36.958596],
          [53.542664, 36.955914],
          [53.440559, 36.945743],
          [53.434128, 36.945103],
          [53.290567, 36.930801],
          [53.240494, 36.921789],
          [53.225488, 36.919089],
          [53.14281, 36.904206],
          [53.140883, 36.903859],
          [52.981613, 36.875181],
          [52.978698, 36.87456],
          [52.892789, 36.856247],
          [52.860759, 36.849418],
          [52.815244, 36.839713],
          [52.735744, 36.822758],
          [52.688563, 36.815226],
          [52.641203, 36.807664],
          [52.598863, 36.800903],
          [52.584716, 36.798644],
          [52.524657, 36.789052],
          [52.451886, 36.777429],
          [52.388717, 36.763014],
          [52.306633, 36.744277],
          [52.30253, 36.743341],
          [52.272327, 36.736445],
          [52.237559, 36.728506],
          [52.16798, 36.712617],
          [52.165207, 36.711811],
          [52.038575, 36.675003],
          [52.032247, 36.674365],
          [51.984583, 36.669554],
          [51.938562, 36.664909],
          [51.91645, 36.662677],
          [51.868637, 36.672],
          [51.771336, 36.69097],
          [51.666192, 36.711463],
          [51.623671, 36.719748],
          [51.487796, 36.774834],
          [51.485429, 36.77519],
          [51.423748, 36.784481],
          [51.355535, 36.794754],
          [51.308097, 36.801898],
          [51.274305, 36.806986],
          [51.250334, 36.810595],
          [51.216855, 36.815636],
          [51.195339, 36.822035],
          [51.125267, 36.842872],
          [51.082988, 36.855441],
          [51.050745, 36.865025],
          [51.014493, 36.8758],
          [50.977981, 36.88665],
          [50.955619, 36.893295],
          [50.838639, 36.944733],
          [50.810038, 36.957303],
          [50.803271, 36.961151],
          [50.72537, 37.005429],
          [50.655001, 37.045403],
          [50.59267, 36.965836],
          [50.592639, 36.96563],
          [50.592614, 36.965464],
          [50.592057, 36.958061],
          [50.591781, 36.957512],
          [50.591224, 36.957233],
          [50.590947, 36.956674],
          [50.539831, 36.908343],
          [50.537055, 36.906119],
          [50.535944, 36.905561],
          [50.535665, 36.905003],
          [50.534831, 36.904733],
          [50.522055, 36.892508],
          [50.522331, 36.892229],
          [50.523165, 36.890563],
          [50.521775, 36.883343],
          [50.520108, 36.88084],
          [50.512888, 36.875007],
          [50.509275, 36.873062],
          [50.505388, 36.871946],
          [50.505381, 36.871953],
          [50.505108, 36.872225],
          [50.490108, 36.878068],
          [50.487332, 36.878338],
          [50.485109, 36.878896],
          [50.482885, 36.878896],
          [50.482881, 36.878895],
          [50.477885, 36.877509],
          [50.477851, 36.877509],
          [50.476775, 36.877509],
          [50.476772, 36.877508],
          [50.467724, 36.874926],
          [50.467329, 36.874728],
          [50.452329, 36.869173],
          [50.449552, 36.867786],
          [50.449516, 36.867786],
          [50.448719, 36.867786],
          [50.448718, 36.867785],
          [50.447329, 36.866949],
          [50.445663, 36.866391],
          [50.442329, 36.864725],
          [50.441773, 36.864167],
          [50.432606, 36.856668],
          [50.431457, 36.852539],
          [50.431216, 36.85223],
          [50.430106, 36.848619],
          [50.428716, 36.838338],
          [50.427574, 36.821517],
          [50.427574, 36.821515],
          [50.427326, 36.819449],
          [50.427606, 36.816676],
          [50.428716, 36.811112],
          [50.428996, 36.809725],
          [50.428996, 36.807781],
          [50.428439, 36.796112],
          [50.431216, 36.779725],
          [50.431496, 36.773612],
          [50.431773, 36.773333],
          [50.431773, 36.744447],
          [50.432329, 36.740278],
          [50.432606, 36.737505],
          [50.432883, 36.736947],
          [50.432606, 36.735839],
          [50.432883, 36.735002],
          [50.432606, 36.734165],
          [50.432329, 36.730005],
          [50.427883, 36.718056],
          [50.426493, 36.715282],
          [50.425383, 36.712779],
          [50.424826, 36.7125],
          [50.42455, 36.711392],
          [50.421773, 36.707781],
          [50.421216, 36.707223],
          [50.408993, 36.690555],
          [50.378714, 36.657227],
          [50.376213, 36.654994],
          [50.359824, 36.641666],
        ],
      ],
    },
  },
  Qazvin: {
    properties: { value: "Qazvin", name: "استان قزوین, ایران", id: 22 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [48.724471, 35.751359],
          [48.724748, 35.749421],
          [48.730861, 35.733582],
          [48.732805, 35.729976],
          [48.736324, 35.723958],
          [48.736695, 35.724144],
          [48.736971, 35.724414],
          [48.741971, 35.727191],
          [48.748641, 35.729417],
          [48.752528, 35.731085],
          [48.758361, 35.732194],
          [48.758918, 35.732474],
          [48.760584, 35.732474],
          [48.760861, 35.732753],
          [48.762251, 35.732753],
          [48.762808, 35.733032],
          [48.765584, 35.733032],
          [48.768084, 35.733303],
          [48.769198, 35.733582],
          [48.771974, 35.733582],
          [48.773084, 35.733862],
          [48.775031, 35.733862],
          [48.779751, 35.733582],
          [48.790864, 35.730526],
          [48.801697, 35.721358],
          [48.81892, 35.71164],
          [48.8417, 35.705528],
          [48.842534, 35.705528],
          [48.843087, 35.705248],
          [48.843924, 35.705248],
          [48.851423, 35.703869],
          [48.859477, 35.702472],
          [48.861423, 35.702472],
          [48.867813, 35.701363],
          [48.870313, 35.701363],
          [48.87198, 35.701084],
          [48.8742, 35.701084],
          [48.882813, 35.699695],
          [48.88309, 35.699416],
          [48.883923, 35.699416],
          [48.890589, 35.69608],
          [48.891703, 35.69553],
          [48.89337, 35.693862],
          [48.930036, 35.67081],
          [48.930869, 35.670531],
          [48.931149, 35.67039],
          [48.931983, 35.669972],
          [48.933092, 35.669972],
          [48.943926, 35.664418],
          [48.946426, 35.66331],
          [48.946706, 35.66303],
          [48.947539, 35.66303],
          [48.988652, 35.643584],
          [49.006985, 35.630529],
          [49.009209, 35.628302],
          [49.012262, 35.625246],
          [49.015318, 35.623866],
          [49.018932, 35.623028],
          [49.020875, 35.623028],
          [49.021985, 35.622748],
          [49.027818, 35.623578],
          [49.028375, 35.623866],
          [49.030318, 35.623866],
          [49.033931, 35.623307],
          [49.047541, 35.617194],
          [49.060321, 35.605528],
          [49.060677, 35.605501],
          [49.061431, 35.605248],
          [49.061988, 35.604969],
          [49.064211, 35.60386],
          [49.071711, 35.59525],
          [49.073101, 35.594141],
          [49.078378, 35.590255],
          [49.079488, 35.590255],
          [49.081434, 35.589696],
          [49.081988, 35.589416],
          [49.082544, 35.589416],
          [49.082821, 35.589137],
          [49.084211, 35.589137],
          [49.091158, 35.587469],
          [49.092267, 35.587469],
          [49.122824, 35.571357],
          [49.127271, 35.568859],
          [49.129214, 35.567191],
          [49.130047, 35.566921],
          [49.133937, 35.562746],
          [49.137824, 35.553856],
          [49.132547, 35.530801],
          [49.132271, 35.529971],
          [49.132547, 35.529692],
          [49.134214, 35.522469],
          [49.13477, 35.521919],
          [49.135324, 35.520801],
          [49.145327, 35.513859],
          [49.150327, 35.508863],
          [49.151994, 35.504409],
          [49.15227, 35.503029],
          [49.152547, 35.501911],
          [49.160047, 35.493859],
          [49.160327, 35.49358],
          [49.16088, 35.4933],
          [49.16727, 35.491361],
          [49.16755, 35.491073],
          [49.16866, 35.491073],
          [49.168937, 35.490802],
          [49.175603, 35.491073],
          [49.17755, 35.490803],
          [49.178103, 35.490523],
          [49.17977, 35.490523],
          [49.182827, 35.489973],
          [49.194217, 35.486637],
          [49.19505, 35.486637],
          [49.19616, 35.486078],
          [49.196993, 35.486078],
          [49.204773, 35.482471],
          [49.205606, 35.481912],
          [49.206997, 35.481641],
          [49.20783, 35.481082],
          [49.210883, 35.481082],
          [49.22283, 35.478025],
          [49.245609, 35.47275],
          [49.246163, 35.472471],
          [49.252276, 35.472471],
          [49.256996, 35.47275],
          [49.259219, 35.472751],
          [49.259499, 35.473021],
          [49.260053, 35.472751],
          [49.265609, 35.473021],
          [49.266719, 35.472751],
          [49.267276, 35.473021],
          [49.269219, 35.47275],
          [49.278942, 35.468576],
          [49.280056, 35.468576],
          [49.280889, 35.468026],
          [49.281999, 35.468026],
          [49.283942, 35.467466],
          [49.286722, 35.467466],
          [49.287275, 35.467187],
          [49.290056, 35.467187],
          [49.290609, 35.466907],
          [49.292275, 35.466907],
          [49.297832, 35.464689],
          [49.298665, 35.464689],
          [49.299222, 35.464139],
          [49.308665, 35.460803],
          [49.309779, 35.460523],
          [49.311722, 35.459694],
          [49.330612, 35.444969],
          [49.344778, 35.438025],
          [49.349778, 35.436916],
          [49.363668, 35.432741],
          [49.365892, 35.43247],
          [49.366448, 35.432191],
          [49.368668, 35.431632],
          [49.377558, 35.429693],
          [49.390614, 35.425527],
          [49.391171, 35.425248],
          [49.396171, 35.425248],
          [49.398948, 35.425798],
          [49.405614, 35.429414],
          [49.411451, 35.431073],
          [49.411451, 35.431361],
          [49.414227, 35.431632],
          [49.414784, 35.431911],
          [49.416171, 35.431912],
          [49.418671, 35.431361],
          [49.418951, 35.431073],
          [49.420061, 35.431073],
          [49.420894, 35.430523],
          [49.421728, 35.430523],
          [49.428394, 35.426636],
          [49.432284, 35.419684],
          [49.432284, 35.419413],
          [49.44562, 35.415751],
          [49.46388, 35.412285],
          [49.484229, 35.407245],
          [49.505535, 35.403058],
          [49.542704, 35.402709],
          [49.550899, 35.403579],
          [49.558123, 35.405247],
          [49.559232, 35.405247],
          [49.567569, 35.408024],
          [49.567846, 35.408304],
          [49.568679, 35.408304],
          [49.572012, 35.410243],
          [49.577846, 35.417186],
          [49.579792, 35.419414],
          [49.585902, 35.42358],
          [49.592569, 35.425798],
          [49.595902, 35.426907],
          [49.598682, 35.428025],
          [49.599515, 35.428025],
          [49.607569, 35.43358],
          [49.616182, 35.446078],
          [49.622291, 35.448576],
          [49.622849, 35.448856],
          [49.628405, 35.449694],
          [49.630072, 35.449694],
          [49.630348, 35.449965],
          [49.633681, 35.450244],
          [49.636462, 35.450244],
          [49.637571, 35.450524],
          [49.640348, 35.450524],
          [49.641461, 35.450803],
          [49.643961, 35.450803],
          [49.644515, 35.451083],
          [49.647848, 35.451083],
          [49.654238, 35.449135],
          [49.668405, 35.44524],
          [49.671738, 35.44524],
          [49.675075, 35.445799],
          [49.675628, 35.446078],
          [49.676461, 35.446078],
          [49.677018, 35.446358],
          [49.678961, 35.446358],
          [49.678961, 35.446637],
          [49.681185, 35.446908],
          [49.681461, 35.447196],
          [49.682294, 35.447196],
          [49.684241, 35.448856],
          [49.684241, 35.449135],
          [49.684794, 35.449694],
          [49.685628, 35.451912],
          [49.684794, 35.457747],
          [49.684241, 35.459694],
          [49.683961, 35.460803],
          [49.692294, 35.469694],
          [49.692574, 35.470244],
          [49.693131, 35.471083],
          [49.694241, 35.477467],
          [49.693684, 35.481083],
          [49.692574, 35.48386],
          [49.677851, 35.504139],
          [49.673961, 35.509964],
          [49.672571, 35.511912],
          [49.672571, 35.512191],
          [49.669794, 35.518305],
          [49.670351, 35.523579],
          [49.673961, 35.529413],
          [49.679517, 35.53219],
          [49.681741, 35.532749],
          [49.682851, 35.532749],
          [49.683961, 35.533308],
          [49.685907, 35.533579],
          [49.686184, 35.533858],
          [49.687294, 35.533858],
          [49.687574, 35.534138],
          [49.692574, 35.534138],
          [49.693131, 35.533858],
          [49.695631, 35.533858],
          [49.696464, 35.533579],
          [49.697851, 35.533579],
          [49.698407, 35.533858],
          [49.698964, 35.534138],
          [49.699517, 35.534138],
          [49.699797, 35.533858],
          [49.701184, 35.533858],
          [49.703684, 35.533858],
          [49.703964, 35.534138],
          [49.70535, 35.534138],
          [49.705631, 35.534417],
          [49.712021, 35.534418],
          [49.712297, 35.534697],
          [49.712854, 35.534418],
          [49.71424, 35.534418],
          [49.727854, 35.528584],
          [49.728407, 35.528025],
          [49.732853, 35.526357],
          [49.74313, 35.524698],
          [49.750077, 35.524139],
          [49.75091, 35.523859],
          [49.75202, 35.523859],
          [49.752853, 35.52358],
          [49.756743, 35.52358],
          [49.766467, 35.52275],
          [49.7698, 35.521921],
          [49.790356, 35.51386],
          [49.790633, 35.51358],
          [49.79258, 35.51358],
          [49.792856, 35.51331],
          [49.796746, 35.51358],
          [49.802023, 35.51441],
          [49.807579, 35.518305],
          [49.807856, 35.518855],
          [49.808136, 35.519134],
          [49.817856, 35.526636],
          [49.821189, 35.528863],
          [49.830635, 35.534697],
          [49.850636, 35.543858],
          [49.871192, 35.546635],
          [49.872582, 35.546915],
          [49.880358, 35.546915],
          [49.890638, 35.544976],
          [49.902305, 35.540522],
          [49.903418, 35.539972],
          [49.903971, 35.539692],
          [49.904251, 35.539413],
          [49.904528, 35.538583],
          [49.919251, 35.522191],
          [49.921194, 35.520803],
          [49.925361, 35.519135],
          [49.972307, 35.506087],
          [49.981754, 35.50441],
          [49.983977, 35.50441],
          [49.988144, 35.50386],
          [49.990644, 35.50386],
          [49.99231, 35.503581],
          [50.004254, 35.503581],
          [50.005367, 35.50386],
          [50.009254, 35.50386],
          [50.010367, 35.50414],
          [50.0137, 35.50414],
          [50.01481, 35.50441],
          [50.01981, 35.50441],
          [50.020924, 35.50441],
          [50.021477, 35.50469],
          [50.024257, 35.50469],
          [50.025367, 35.504969],
          [50.029257, 35.504969],
          [50.02981, 35.505249],
          [50.035367, 35.505249],
          [50.035923, 35.505528],
          [50.043147, 35.505528],
          [50.05509, 35.506358],
          [50.068703, 35.507747],
          [50.101759, 35.51386],
          [50.126482, 35.517187],
          [50.127039, 35.517467],
          [50.129539, 35.517467],
          [50.130649, 35.517746],
          [50.134262, 35.518026],
          [50.135649, 35.518026],
          [50.136482, 35.518305],
          [50.146205, 35.518305],
          [50.146762, 35.518585],
          [50.150095, 35.518585],
          [50.177875, 35.522192],
          [50.187318, 35.52386],
          [50.189818, 35.52386],
          [50.190651, 35.524139],
          [50.192598, 35.524139],
          [50.194265, 35.52441],
          [50.205931, 35.528864],
          [50.213431, 35.533309],
          [50.224264, 35.538025],
          [50.228988, 35.540523],
          [50.229544, 35.541082],
          [50.229544, 35.541361],
          [50.229555, 35.541439],
          [50.230097, 35.545527],
          [50.230087, 35.545547],
          [50.229821, 35.546077],
          [50.229264, 35.548024],
          [50.228988, 35.549142],
          [50.228711, 35.549972],
          [50.228154, 35.551081],
          [50.224264, 35.554696],
          [50.182595, 35.582196],
          [50.179542, 35.584694],
          [50.167875, 35.59636],
          [50.164818, 35.602753],
          [50.164542, 35.603312],
          [50.163152, 35.610534],
          [50.163155, 35.610574],
          [50.163428, 35.61414],
          [50.165095, 35.619135],
          [50.168708, 35.624139],
          [50.179261, 35.628583],
          [50.183431, 35.629422],
          [50.193708, 35.631081],
          [50.193984, 35.631369],
          [50.200098, 35.631369],
          [50.203431, 35.63164],
          [50.204541, 35.631919],
          [50.205374, 35.631919],
          [50.205654, 35.632199],
          [50.207874, 35.632199],
          [50.209821, 35.632469],
          [50.210654, 35.632749],
          [50.212321, 35.632749],
          [50.212874, 35.633028],
          [50.213988, 35.633028],
          [50.226487, 35.636364],
          [50.227321, 35.636364],
          [50.238431, 35.641079],
          [50.240377, 35.642756],
          [50.241767, 35.644424],
          [50.243711, 35.647751],
          [50.244544, 35.674139],
          [50.244539, 35.67415],
          [50.244267, 35.674698],
          [50.243711, 35.68164],
          [50.243712, 35.681656],
          [50.243987, 35.684696],
          [50.24398, 35.684719],
          [50.243711, 35.685535],
          [50.243434, 35.689141],
          [50.243435, 35.689158],
          [50.243711, 35.693585],
          [50.243707, 35.693651],
          [50.243434, 35.698589],
          [50.243434, 35.698601],
          [50.243711, 35.716366],
          [50.245934, 35.727752],
          [50.24621, 35.729979],
          [50.250934, 35.750533],
          [50.250922, 35.750571],
          [50.249817, 35.754332],
          [50.249818, 35.754392],
          [50.250403, 35.806938],
          [50.291825, 35.819517],
          [50.297046, 35.822476],
          [50.315103, 35.842199],
          [50.315103, 35.842478],
          [50.328159, 35.853313],
          [50.330936, 35.856927],
          [50.331353, 35.857338],
          [50.331769, 35.857757],
          [50.332046, 35.858316],
          [50.333992, 35.861092],
          [50.334269, 35.861371],
          [50.373162, 35.898309],
          [50.376215, 35.902482],
          [50.376495, 35.903032],
          [50.388715, 35.919427],
          [50.392328, 35.926376],
          [50.392605, 35.929152],
          [50.393162, 35.93026],
          [50.393162, 35.930819],
          [50.393718, 35.931089],
          [50.398718, 35.951378],
          [50.398995, 35.952207],
          [50.401495, 35.961922],
          [50.405938, 35.974982],
          [50.406771, 35.977758],
          [50.416775, 35.993872],
          [50.45456, 36.005922],
          [50.47692, 36.007859],
          [50.526122, 36.012527],
          [50.547336, 36.018314],
          [50.561226, 36.043313],
          [50.562321, 36.05733],
          [50.572613, 36.078323],
          [50.602616, 36.097482],
          [50.683843, 36.111666],
          [50.622615, 36.154153],
          [50.607058, 36.163325],
          [50.600949, 36.16665],
          [50.573726, 36.17804],
          [50.572892, 36.178598],
          [50.564002, 36.181103],
          [50.545007, 36.189945],
          [50.537858, 36.190028],
          [50.531142, 36.190106],
          [50.525543, 36.195236],
          [50.512609, 36.209991],
          [50.478443, 36.224154],
          [50.475345, 36.225234],
          [50.472053, 36.22638],
          [50.470766, 36.22663],
          [50.470663, 36.22665],
          [50.460838, 36.231567],
          [50.449784, 36.235644],
          [50.443717, 36.24249],
          [50.440664, 36.245265],
          [50.432064, 36.245245],
          [50.429902, 36.254713],
          [50.4299, 36.254784],
          [50.429372, 36.26897],
          [50.429372, 36.268971],
          [50.425736, 36.271327],
          [50.418417, 36.276071],
          [50.411535, 36.292791],
          [50.409664, 36.302266],
          [50.409672, 36.302281],
          [50.413498, 36.309041],
          [50.421774, 36.315268],
          [50.426774, 36.322494],
          [50.429274, 36.324989],
          [50.43483, 36.328881],
          [50.469553, 36.339439],
          [50.471497, 36.33971],
          [50.472886, 36.340268],
          [50.481496, 36.341935],
          [50.483997, 36.341935],
          [50.485386, 36.342214],
          [50.493443, 36.342214],
          [50.49511, 36.341935],
          [50.495943, 36.342214],
          [50.5015, 36.341665],
          [50.504, 36.341106],
          [50.505666, 36.341106],
          [50.520943, 36.338322],
          [50.522609, 36.337494],
          [50.542336, 36.333043],
          [50.550389, 36.331656],
          [50.567892, 36.329431],
          [50.576225, 36.329431],
          [50.599282, 36.331656],
          [50.609558, 36.332773],
          [50.611505, 36.332773],
          [50.614558, 36.333323],
          [50.617338, 36.333323],
          [50.630671, 36.33471],
          [50.634005, 36.335269],
          [50.637062, 36.335269],
          [50.638451, 36.335548],
          [50.638728, 36.335269],
          [50.640118, 36.335269],
          [50.640671, 36.335548],
          [50.645395, 36.335548],
          [50.645951, 36.335269],
          [50.649284, 36.335269],
          [50.651785, 36.33471],
          [50.653451, 36.33471],
          [50.658728, 36.333602],
          [50.660394, 36.333323],
          [50.665929, 36.331707],
          [50.674841, 36.329161],
          [50.691507, 36.323881],
          [50.698805, 36.322336],
          [50.711787, 36.321665],
          [50.713177, 36.321377],
          [50.717344, 36.321377],
          [50.71901, 36.321098],
          [50.72151, 36.321098],
          [50.737772, 36.31915],
          [50.742344, 36.318602],
          [50.74512, 36.318602],
          [50.749843, 36.318053],
          [50.764843, 36.315269],
          [50.809846, 36.298602],
          [50.816236, 36.296097],
          [50.821236, 36.294439],
          [50.823179, 36.29444],
          [50.823736, 36.29416],
          [50.830403, 36.29416],
          [50.831516, 36.29444],
          [50.833459, 36.29444],
          [50.841516, 36.296097],
          [50.841516, 36.296377],
          [50.844016, 36.296377],
          [50.847913, 36.294016],
          [50.847916, 36.294086],
          [50.848739, 36.310269],
          [50.849016, 36.313602],
          [50.856515, 36.345548],
          [50.853182, 36.362493],
          [50.845682, 36.386663],
          [50.845126, 36.388329],
          [50.845125, 36.388888],
          [50.842626, 36.393599],
          [50.842349, 36.394716],
          [50.838736, 36.400554],
          [50.834569, 36.404716],
          [50.787346, 36.437495],
          [50.773456, 36.443053],
          [50.768733, 36.446386],
          [50.767066, 36.447494],
          [50.764843, 36.448332],
          [50.76401, 36.448332],
          [50.746233, 36.457772],
          [50.744843, 36.459159],
          [50.688451, 36.494999],
          [50.68734, 36.495828],
          [50.68595, 36.496386],
          [50.679841, 36.499998],
          [50.668451, 36.505546],
          [50.667451, 36.506049],
          [50.663727, 36.507771],
          [50.627895, 36.519164],
          [50.618171, 36.520831],
          [50.607058, 36.523055],
          [50.605948, 36.523055],
          [50.598725, 36.52528],
          [50.597892, 36.52528],
          [50.596781, 36.525829],
          [50.592891, 36.526667],
          [50.592058, 36.526877],
          [50.592044, 36.526885],
          [50.592044, 36.526885],
          [50.558445, 36.545554],
          [50.554555, 36.548607],
          [50.533999, 36.56055],
          [50.533165, 36.561108],
          [50.509832, 36.576662],
          [50.508722, 36.57722],
          [50.508713, 36.577226],
          [50.508713, 36.577226],
          [50.486219, 36.590829],
          [50.448996, 36.608886],
          [50.446216, 36.610552],
          [50.434273, 36.615829],
          [50.431496, 36.616387],
          [50.41094, 36.621944],
          [50.410383, 36.622223],
          [50.409797, 36.62237],
          [50.365937, 36.638883],
          [50.362047, 36.640837],
          [50.361771, 36.641117],
          [50.360937, 36.641117],
          [50.360657, 36.641387],
          [50.359824, 36.641666],
          [50.358714, 36.641117],
          [50.35649, 36.639442],
          [50.355657, 36.639442],
          [50.354824, 36.638883],
          [50.353991, 36.638613],
          [50.353437, 36.638613],
          [50.348714, 36.636947],
          [50.347324, 36.636947],
          [50.342881, 36.63583],
          [50.340381, 36.63583],
          [50.337324, 36.636947],
          [50.333711, 36.637776],
          [50.333157, 36.637776],
          [50.332601, 36.638055],
          [50.331491, 36.638055],
          [50.330934, 36.638334],
          [50.328434, 36.638334],
          [50.327324, 36.638613],
          [50.315934, 36.640279],
          [50.314268, 36.640837],
          [50.313154, 36.640837],
          [50.304821, 36.643332],
          [50.270655, 36.66028],
          [50.269822, 36.660838],
          [50.250652, 36.672221],
          [50.246485, 36.674166],
          [50.241765, 36.676111],
          [50.232042, 36.678614],
          [50.230375, 36.678614],
          [50.227595, 36.679173],
          [50.224819, 36.679173],
          [50.223985, 36.679452],
          [50.221485, 36.679722],
          [50.201206, 36.678056],
          [50.175093, 36.670005],
          [50.171482, 36.668889],
          [50.170926, 36.66861],
          [50.127593, 36.648888],
          [50.127313, 36.64833],
          [50.125646, 36.64778],
          [50.114813, 36.641116],
          [50.110923, 36.638883],
          [50.105367, 36.636109],
          [50.104813, 36.635551],
          [50.103423, 36.635002],
          [50.098423, 36.632498],
          [50.097564, 36.631352],
          [50.058154, 36.604062],
          [50.027309, 36.611327],
          [50.007314, 36.602658],
          [49.979897, 36.596222],
          [49.938971, 36.602638],
          [49.935983, 36.572496],
          [49.900847, 36.582571],
          [49.878335, 36.578183],
          [49.832208, 36.569206],
          [49.787661, 36.559383],
          [49.744241, 36.569168],
          [49.743408, 36.569447],
          [49.741741, 36.569717],
          [49.738961, 36.569717],
          [49.737294, 36.569996],
          [49.735074, 36.569996],
          [49.731461, 36.570834],
          [49.729795, 36.570834],
          [49.694166, 36.579061],
          [49.692291, 36.579444],
          [49.686181, 36.580831],
          [49.678405, 36.583605],
          [49.673405, 36.585001],
          [49.672292, 36.585001],
          [49.660625, 36.590278],
          [49.656182, 36.592773],
          [49.631179, 36.602499],
          [49.618122, 36.606111],
          [49.617012, 36.606381],
          [49.615622, 36.606939],
          [49.613679, 36.606939],
          [49.612845, 36.607218],
          [49.611456, 36.607218],
          [49.610899, 36.607497],
          [49.606179, 36.607497],
          [49.605622, 36.607218],
          [49.604232, 36.607218],
          [49.603123, 36.606939],
          [49.601179, 36.606939],
          [49.590066, 36.605003],
          [49.583676, 36.605003],
          [49.583122, 36.605273],
          [49.582009, 36.605273],
          [49.580899, 36.605831],
          [49.580066, 36.605831],
          [49.576732, 36.60666],
          [49.574509, 36.60666],
          [49.573676, 36.606381],
          [49.570066, 36.60611],
          [49.561733, 36.60666],
          [49.557286, 36.60666],
          [49.55312, 36.60666],
          [49.543396, 36.604723],
          [49.533396, 36.604724],
          [49.53312, 36.604444],
          [49.52423, 36.604444],
          [49.52312, 36.604165],
          [49.520897, 36.604165],
          [49.518673, 36.603607],
          [49.515617, 36.603607],
          [49.51534, 36.603328],
          [49.514783, 36.603607],
          [49.51395, 36.603607],
          [49.513117, 36.603328],
          [49.512564, 36.603607],
          [49.512007, 36.603328],
          [49.50923, 36.603328],
          [49.499784, 36.600833],
          [49.493117, 36.603607],
          [49.48534, 36.607497],
          [49.484227, 36.608056],
          [49.47895, 36.610829],
          [49.47506, 36.613054],
          [49.465337, 36.623888],
          [49.461727, 36.628886],
          [49.460894, 36.632776],
          [49.454504, 36.646663],
          [49.453947, 36.647221],
          [49.453671, 36.648058],
          [49.443671, 36.657775],
          [49.437838, 36.660279],
          [49.437142, 36.660418],
          [49.436724, 36.660837],
          [49.435057, 36.661386],
          [49.434504, 36.661945],
          [49.432281, 36.662494],
          [49.432004, 36.662773],
          [49.431171, 36.662773],
          [49.410891, 36.674444],
          [49.405891, 36.677776],
          [49.403944, 36.678892],
          [49.402073, 36.680765],
          [49.401261, 36.681578],
          [49.397721, 36.685121],
          [49.365521, 36.717327],
          [49.362315, 36.720532],
          [49.361261, 36.721585],
          [49.357296, 36.725549],
          [49.354585, 36.728257],
          [49.351587, 36.731254],
          [49.340608, 36.742222],
          [49.33994, 36.743371],
          [49.334529, 36.752679],
          [49.33215, 36.756772],
          [49.317832, 36.781389],
          [49.305609, 36.797776],
          [49.304714, 36.798643],
          [49.304219, 36.798892],
          [49.296719, 36.806392],
          [49.280885, 36.815279],
          [49.280605, 36.815558],
          [49.27031, 36.808873],
          [49.268297, 36.80666],
          [49.261565, 36.802015],
          [49.253253, 36.79774],
          [49.248889, 36.795368],
          [49.240108, 36.789995],
          [49.225804, 36.782973],
          [49.21237, 36.775563],
          [49.196257, 36.765288],
          [49.189149, 36.760754],
          [49.180062, 36.766586],
          [49.177299, 36.768597],
          [49.174947, 36.770071],
          [49.171271, 36.772374],
          [49.166473, 36.775486],
          [49.157837, 36.778009],
          [49.15383, 36.778333],
          [49.148246, 36.77891],
          [49.138316, 36.779175],
          [49.132809, 36.779525],
          [49.125357, 36.780524],
          [49.116301, 36.780173],
          [49.108366, 36.778069],
          [49.099411, 36.769992],
          [49.097789, 36.768528],
          [49.09186, 36.757561],
          [49.089763, 36.754932],
          [49.088166, 36.751324],
          [49.084739, 36.745537],
          [49.077891, 36.733388],
          [49.075775, 36.7303],
          [49.072225, 36.727034],
          [49.069842, 36.724495],
          [49.066486, 36.719186],
          [49.065328, 36.716421],
          [49.064163, 36.712333],
          [49.063215, 36.708135],
          [49.064114, 36.70315],
          [49.06517, 36.697299],
          [49.064996, 36.693742],
          [49.064678, 36.687214],
          [49.061816, 36.680173],
          [49.053366, 36.674107],
          [49.045416, 36.670511],
          [49.042581, 36.669939],
          [49.037301, 36.669334],
          [49.034651, 36.66768],
          [49.033928, 36.667221],
          [49.018649, 36.651111],
          [49.018372, 36.648058],
          [49.015596, 36.632775],
          [49.015039, 36.631938],
          [49.013929, 36.630272],
          [49.008372, 36.623328],
          [48.977259, 36.573607],
          [48.972813, 36.56333],
          [48.972259, 36.558053],
          [48.972536, 36.557773],
          [48.972259, 36.557215],
          [48.971979, 36.555828],
          [48.971979, 36.553324],
          [48.972259, 36.553045],
          [48.972536, 36.552775],
          [48.972905, 36.55197],
          [48.975593, 36.546101],
          [49.007003, 36.527478],
          [49.023372, 36.517766],
          [49.026706, 36.514992],
          [49.027262, 36.514722],
          [49.027816, 36.514722],
          [49.028096, 36.514442],
          [49.029482, 36.514163],
          [49.031706, 36.512776],
          [49.060595, 36.495825],
          [49.081432, 36.471659],
          [49.092542, 36.451103],
          [49.095042, 36.447491],
          [49.095598, 36.446383],
          [49.104208, 36.437772],
          [49.105041, 36.437493],
          [49.105322, 36.436934],
          [49.105875, 36.436655],
          [49.109765, 36.433601],
          [49.119212, 36.42971],
          [49.120045, 36.42944],
          [49.120598, 36.42944],
          [49.131988, 36.428323],
          [49.134211, 36.428881],
          [49.136435, 36.428881],
          [49.137821, 36.428602],
          [49.141155, 36.428602],
          [49.142545, 36.428881],
          [49.143101, 36.428602],
          [49.144768, 36.428881],
          [49.146991, 36.428881],
          [49.148378, 36.429161],
          [49.150324, 36.429161],
          [49.151435, 36.42944],
          [49.168934, 36.42971],
          [49.169491, 36.42944],
          [49.172548, 36.42944],
          [49.173381, 36.429161],
          [49.175048, 36.429161],
          [49.176158, 36.428881],
          [49.177548, 36.428881],
          [49.178381, 36.428602],
          [49.185048, 36.428602],
          [49.186157, 36.428882],
          [49.187824, 36.428881],
          [49.188657, 36.428602],
          [49.191158, 36.428602],
          [49.194771, 36.427494],
          [49.195047, 36.426936],
          [49.196157, 36.425269],
          [49.200881, 36.423882],
          [49.201157, 36.423603],
          [49.201437, 36.423324],
          [49.201865, 36.423129],
          [49.235604, 36.407767],
          [49.26644, 36.393048],
          [49.266997, 36.392768],
          [49.27033, 36.391381],
          [49.270883, 36.391102],
          [49.272962, 36.390203],
          [49.273383, 36.389994],
          [49.277553, 36.388327],
          [49.282273, 36.387489],
          [49.28783, 36.386661],
          [49.289496, 36.386102],
          [49.290886, 36.386102],
          [49.293386, 36.385544],
          [49.296996, 36.385544],
          [49.29811, 36.385264],
          [49.298943, 36.385544],
          [49.30172, 36.385544],
          [49.306719, 36.386381],
          [49.308386, 36.386661],
          [49.313386, 36.388048],
          [49.314496, 36.388048],
          [49.320333, 36.388886],
          [49.329219, 36.389715],
          [49.335609, 36.389715],
          [49.335889, 36.389435],
          [49.336999, 36.389435],
          [49.340889, 36.388048],
          [49.344499, 36.385264],
          [49.346166, 36.379715],
          [49.346723, 36.376932],
          [49.346999, 36.374436],
          [49.347556, 36.371662],
          [49.347832, 36.369986],
          [49.348109, 36.369437],
          [49.348109, 36.368878],
          [49.347833, 36.364158],
          [49.347276, 36.360825],
          [49.347832, 36.359158],
          [49.348109, 36.35805],
          [49.348666, 36.357212],
          [49.349776, 36.355825],
          [49.351166, 36.354987],
          [49.370889, 36.3386],
          [49.394502, 36.329708],
          [49.395892, 36.329708],
          [49.398112, 36.329159],
          [49.399502, 36.329159],
          [49.405612, 36.328051],
          [49.409782, 36.328051],
          [49.410615, 36.327771],
          [49.410892, 36.328051],
          [49.414502, 36.328051],
          [49.415059, 36.327771],
          [49.421169, 36.327771],
          [49.424502, 36.327213],
          [49.424502, 36.326934],
          [49.424782, 36.326384],
          [49.423392, 36.321375],
          [49.417558, 36.3086],
          [49.416725, 36.307762],
          [49.407279, 36.2936],
          [49.391445, 36.271653],
          [49.391169, 36.26804],
          [49.391725, 36.266652],
          [49.391726, 36.266103],
          [49.393112, 36.259985],
          [49.397279, 36.253876],
          [49.398112, 36.253327],
          [49.403669, 36.248047],
          [49.408392, 36.244714],
          [49.411726, 36.240542],
          [49.412282, 36.238605],
          [49.412002, 36.238037],
          [49.411449, 36.228324],
          [49.412002, 36.22554],
          [49.415336, 36.217765],
          [49.416169, 36.216936],
          [49.423115, 36.207763],
          [49.426449, 36.203871],
          [49.431725, 36.197492],
          [49.431725, 36.197212],
          [49.431725, 36.196933],
          [49.432839, 36.195545],
          [49.433115, 36.194437],
          [49.433392, 36.194149],
          [49.433672, 36.193041],
          [49.428949, 36.173317],
          [49.421169, 36.15971],
          [49.413392, 36.146654],
          [49.410892, 36.136652],
          [49.411169, 36.134985],
          [49.411449, 36.133318],
          [49.411726, 36.13248],
          [49.411726, 36.128037],
          [49.412282, 36.125541],
          [49.412283, 36.125262],
          [49.413116, 36.122766],
          [49.413116, 36.116656],
          [49.415059, 36.101932],
          [49.415893, 36.096092],
          [49.416169, 36.095543],
          [49.412836, 36.07387],
          [49.403393, 36.05582],
          [49.397836, 36.046366],
          [49.393113, 36.040544],
          [49.362833, 36.013319],
          [49.362833, 36.012481],
          [49.362833, 36.012201],
          [49.362, 36.010814],
          [49.353947, 36.004983],
          [49.34311, 35.998872],
          [49.336167, 35.996646],
          [49.301997, 35.975818],
          [49.294221, 35.973592],
          [49.292831, 35.973592],
          [49.291998, 35.973313],
          [49.290608, 35.973313],
          [49.289497, 35.973033],
          [49.286721, 35.972763],
          [49.282831, 35.972763],
          [49.281998, 35.972475],
          [49.274498, 35.972204],
          [49.273941, 35.972204],
          [49.272274, 35.972204],
          [49.271441, 35.971925],
          [49.267274, 35.971925],
          [49.266165, 35.971645],
          [49.263108, 35.971645],
          [49.262551, 35.971925],
          [49.259218, 35.971925],
          [49.258385, 35.972204],
          [49.245885, 35.97498],
          [49.223662, 35.985254],
          [49.222828, 35.985254],
          [49.222552, 35.985534],
          [49.218938, 35.985534],
          [49.208938, 35.982208],
          [49.20813, 35.98135],
          [49.186159, 35.958036],
          [49.181716, 35.95554],
          [49.180882, 35.955539],
          [49.173936, 35.951646],
          [49.163659, 35.939424],
          [49.163659, 35.939145],
          [49.152546, 35.932476],
          [49.151713, 35.930538],
          [49.151713, 35.929979],
          [49.150603, 35.9297],
          [49.147546, 35.9297],
          [49.146436, 35.929979],
          [49.141156, 35.929979],
          [49.140603, 35.930258],
          [49.137822, 35.930258],
          [49.137546, 35.930538],
          [49.135879, 35.930538],
          [49.135323, 35.930817],
          [49.131436, 35.931367],
          [49.124766, 35.931367],
          [49.124489, 35.931646],
          [49.12338, 35.931646],
          [49.1231, 35.931926],
          [49.121713, 35.931926],
          [49.12088, 35.932205],
          [49.118656, 35.932476],
          [49.111156, 35.931926],
          [49.105323, 35.931926],
          [49.10421, 35.931646],
          [49.10199, 35.931646],
          [49.101433, 35.931367],
          [49.09921, 35.931367],
          [49.098656, 35.931087],
          [49.097267, 35.931087],
          [49.095043, 35.930538],
          [49.087543, 35.92942],
          [49.08671, 35.92915],
          [49.07171, 35.923868],
          [49.06671, 35.918036],
          [49.066987, 35.91609],
          [49.0706, 35.909149],
          [49.071434, 35.90887],
          [49.076153, 35.903868],
          [49.076154, 35.903866],
          [49.077005, 35.901594],
          [49.076153, 35.890807],
          [49.075877, 35.889978],
          [49.074669, 35.888528],
          [49.0731, 35.886643],
          [49.071434, 35.885814],
          [49.07032, 35.884705],
          [49.058097, 35.878874],
          [49.057264, 35.878873],
          [49.05671, 35.878585],
          [49.055321, 35.878315],
          [49.053097, 35.878315],
          [49.052264, 35.878035],
          [49.048654, 35.878035],
          [49.047821, 35.878315],
          [49.044764, 35.878315],
          [49.032264, 35.882758],
          [49.026984, 35.887202],
          [49.026431, 35.887472],
          [49.026431, 35.887761],
          [49.021431, 35.894422],
          [49.007817, 35.904426],
          [48.991984, 35.910258],
          [48.986704, 35.911367],
          [48.983928, 35.911367],
          [48.982538, 35.911646],
          [48.980595, 35.911646],
          [48.979485, 35.911925],
          [48.977538, 35.911925],
          [48.973371, 35.912755],
          [48.971428, 35.912755],
          [48.967261, 35.913593],
          [48.953372, 35.917757],
          [48.952815, 35.918036],
          [48.952538, 35.918316],
          [48.934202, 35.930537],
          [48.921982, 35.945535],
          [48.914759, 35.961091],
          [48.914479, 35.96165],
          [48.912535, 35.966652],
          [48.912259, 35.967481],
          [48.903369, 35.982478],
          [48.902812, 35.982207],
          [48.890869, 35.97443],
          [48.847256, 35.936927],
          [48.8442, 35.933313],
          [48.827533, 35.914422],
          [48.787253, 35.887201],
          [48.784474, 35.884146],
          [48.781697, 35.879423],
          [48.780307, 35.876638],
          [48.774751, 35.844973],
          [48.771974, 35.839141],
          [48.771417, 35.838861],
          [48.759474, 35.827756],
          [48.758361, 35.826647],
          [48.758361, 35.826368],
          [48.757807, 35.826088],
          [48.756694, 35.824971],
          [48.756417, 35.8247],
          [48.756379, 35.824546],
          [48.756141, 35.823592],
          [48.756417, 35.823303],
          [48.752808, 35.806086],
          [48.748641, 35.799695],
          [48.738361, 35.782198],
          [48.736138, 35.778863],
          [48.736138, 35.778304],
          [48.725581, 35.760248],
          [48.724748, 35.756912],
          [48.724471, 35.751359],
        ],
      ],
    },
  },
  Qom: {
    properties: { value: "Qom", name: "استان قم, ایران", id: 11 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [50.086205, 34.583562],
          [50.086467, 34.583089],
          [50.086481, 34.582391],
          [50.088705, 34.578834],
          [50.091205, 34.572454],
          [50.098705, 34.564667],
          [50.100094, 34.563277],
          [50.102594, 34.560787],
          [50.104538, 34.557999],
          [50.105114, 34.557358],
          [50.110095, 34.54967],
          [50.112594, 34.545221],
          [50.117594, 34.538282],
          [50.118151, 34.536893],
          [50.144264, 34.518006],
          [50.148431, 34.516337],
          [50.157041, 34.509118],
          [50.158987, 34.503559],
          [50.157597, 34.490781],
          [50.149821, 34.483553],
          [50.147874, 34.480503],
          [50.146764, 34.478003],
          [50.145931, 34.476334],
          [50.146208, 34.476054],
          [50.146488, 34.475224],
          [50.148154, 34.473284],
          [50.159541, 34.468555],
          [50.163707, 34.467725],
          [50.165377, 34.467725],
          [50.175377, 34.465785],
          [50.177877, 34.465785],
          [50.178431, 34.465505],
          [50.185654, 34.465505],
          [50.188711, 34.464945],
          [50.190654, 34.464945],
          [50.19121, 34.464666],
          [50.193153, 34.464666],
          [50.19371, 34.464386],
          [50.1951, 34.464386],
          [50.213433, 34.460497],
          [50.214824, 34.460497],
          [50.215657, 34.460217],
          [50.221767, 34.459116],
          [50.228157, 34.457997],
          [50.238433, 34.454947],
          [50.239546, 34.454947],
          [50.239823, 34.454667],
          [50.240657, 34.454667],
          [50.24149, 34.454387],
          [50.243713, 34.454387],
          [50.24399, 34.454108],
          [50.247047, 34.453837],
          [50.251213, 34.453837],
          [50.252046, 34.453557],
          [50.25427, 34.453557],
          [50.254546, 34.453277],
          [50.259826, 34.453277],
          [50.26038, 34.452998],
          [50.266213, 34.452447],
          [50.266493, 34.452727],
          [50.269826, 34.452998],
          [50.271213, 34.452447],
          [50.275103, 34.444939],
          [50.276213, 34.444389],
          [50.277046, 34.443558],
          [50.280936, 34.442169],
          [50.281493, 34.441609],
          [50.288993, 34.438279],
          [50.289826, 34.438279],
          [50.290383, 34.437999],
          [50.291216, 34.438],
          [50.291769, 34.43772],
          [50.300939, 34.43633],
          [50.304272, 34.43605],
          [50.305106, 34.43605],
          [50.305383, 34.43633],
          [50.307049, 34.43633],
          [50.307606, 34.43661],
          [50.308439, 34.43661],
          [50.309549, 34.437169],
          [50.310659, 34.437169],
          [50.311772, 34.43772],
          [50.312606, 34.43772],
          [50.329272, 34.442169],
          [50.331496, 34.442439],
          [50.335105, 34.442169],
          [50.337328, 34.439669],
          [50.337605, 34.438],
          [50.337885, 34.437169],
          [50.337052, 34.427441],
          [50.336219, 34.424111],
          [50.335385, 34.421332],
          [50.331496, 34.408003],
          [50.331772, 34.406884],
          [50.334829, 34.401334],
          [50.335385, 34.401054],
          [50.335662, 34.400504],
          [50.336219, 34.400224],
          [50.340385, 34.395775],
          [50.349272, 34.388275],
          [50.349552, 34.387996],
          [50.353162, 34.382166],
          [50.352885, 34.381606],
          [50.352605, 34.380496],
          [50.352329, 34.377717],
          [50.353162, 34.374387],
          [50.352885, 34.374107],
          [50.360108, 34.349389],
          [50.360108, 34.348829],
          [50.361495, 34.346889],
          [50.36219, 34.346194],
          [50.362608, 34.346059],
          [50.365942, 34.342719],
          [50.370108, 34.33466],
          [50.380385, 34.326331],
          [50.380385, 34.326051],
          [50.380942, 34.324941],
          [50.380942, 34.32411],
          [50.381218, 34.323831],
          [50.381775, 34.323831],
          [50.382332, 34.323271],
          [50.383165, 34.323],
          [50.396221, 34.313551],
          [50.414555, 34.292442],
          [50.415111, 34.291052],
          [50.417611, 34.287163],
          [50.418998, 34.284942],
          [50.419278, 34.284392],
          [50.424554, 34.277443],
          [50.434834, 34.249104],
          [50.435388, 34.246604],
          [50.435668, 34.246053],
          [50.435111, 34.237163],
          [50.432334, 34.225214],
          [50.431778, 34.224663],
          [50.428721, 34.215494],
          [50.428444, 34.211324],
          [50.432334, 34.206884],
          [50.438721, 34.202994],
          [50.441501, 34.201604],
          [50.442055, 34.201053],
          [50.465111, 34.190773],
          [50.466781, 34.190773],
          [50.467614, 34.190493],
          [50.472334, 34.190493],
          [50.473167, 34.190773],
          [50.474834, 34.190773],
          [50.477057, 34.190214],
          [50.478447, 34.190214],
          [50.479281, 34.189934],
          [50.481501, 34.189663],
          [50.494004, 34.190773],
          [50.494557, 34.190493],
          [50.49539, 34.190493],
          [50.496224, 34.190214],
          [50.499004, 34.190214],
          [50.499837, 34.189934],
          [50.50567, 34.189934],
          [50.50928, 34.189934],
          [50.51706, 34.189934],
          [50.531783, 34.194383],
          [50.534003, 34.195494],
          [50.535117, 34.195494],
          [50.554559, 34.202994],
          [50.557616, 34.204104],
          [50.575116, 34.209934],
          [50.582063, 34.213833],
          [50.583173, 34.214104],
          [50.584562, 34.214104],
          [50.584562, 34.214384],
          [50.586229, 34.214384],
          [50.586506, 34.214664],
          [50.587339, 34.214664],
          [50.595672, 34.216884],
          [50.607062, 34.228545],
          [50.609562, 34.231604],
          [50.626232, 34.252994],
          [50.629565, 34.254664],
          [50.630675, 34.254664],
          [50.632065, 34.255214],
          [50.632898, 34.255214],
          [50.633452, 34.255494],
          [50.636232, 34.255494],
          [50.637065, 34.255774],
          [50.638175, 34.255774],
          [50.639288, 34.256053],
          [50.645675, 34.256053],
          [50.646232, 34.255774],
          [50.650955, 34.255774],
          [50.651788, 34.255494],
          [50.653455, 34.255494],
          [50.658178, 34.254104],
          [50.662065, 34.252164],
          [50.673731, 34.244664],
          [50.686511, 34.230774],
          [50.689568, 34.229944],
          [50.691788, 34.229944],
          [50.692345, 34.229664],
          [50.693178, 34.229944],
          [50.695401, 34.229944],
          [50.695958, 34.230223],
          [50.697901, 34.230494],
          [50.708734, 34.229113],
          [50.714568, 34.225494],
          [50.715958, 34.222714],
          [50.720958, 34.217714],
          [50.721791, 34.217443],
          [50.722901, 34.216604],
          [50.725681, 34.215494],
          [50.726791, 34.215494],
          [50.727624, 34.215214],
          [50.730681, 34.215214],
          [50.730957, 34.215494],
          [50.731791, 34.215494],
          [50.739014, 34.217714],
          [50.740404, 34.217714],
          [50.740957, 34.217994],
          [50.74429, 34.217994],
          [50.744571, 34.218274],
          [50.745404, 34.218274],
          [50.746791, 34.218554],
          [50.755127, 34.223274],
          [50.75596, 34.223274],
          [50.75707, 34.223825],
          [50.75818, 34.223824],
          [50.760127, 34.223554],
          [50.763737, 34.224384],
          [50.764014, 34.224104],
          [50.765127, 34.224104],
          [50.765127, 34.223824],
          [50.76985, 34.223824],
          [50.770127, 34.224104],
          [50.771237, 34.224104],
          [50.772904, 34.224664],
          [50.783183, 34.233274],
          [50.785403, 34.234384],
          [50.786237, 34.234384],
          [50.78735, 34.234944],
          [50.788183, 34.234944],
          [50.791793, 34.236054],
          [50.79624, 34.236054],
          [50.80124, 34.233274],
          [50.80124, 34.232164],
          [50.80096, 34.229384],
          [50.80346, 34.226045],
          [50.806517, 34.225214],
          [50.80874, 34.224104],
          [50.80985, 34.222164],
          [50.809573, 34.216054],
          [50.810683, 34.211884],
          [50.811663, 34.207597],
          [50.812511, 34.206764],
          [50.815125, 34.204776],
          [50.816174, 34.204581],
          [50.817974, 34.204319],
          [50.81895, 34.203737],
          [50.821909, 34.201359],
          [50.824931, 34.201586],
          [50.826302, 34.201515],
          [50.829852, 34.20203],
          [50.832523, 34.202466],
          [50.834135, 34.202944],
          [50.840729, 34.197992],
          [50.843048, 34.194926],
          [50.857629, 34.196054],
          [50.860409, 34.196324],
          [50.863186, 34.196054],
          [50.863743, 34.195494],
          [50.869299, 34.192994],
          [50.870409, 34.192994],
          [50.870966, 34.193274],
          [50.872352, 34.193274],
          [50.873742, 34.193833],
          [50.875132, 34.194104],
          [50.883189, 34.192443],
          [50.884856, 34.191333],
          [50.885132, 34.191044],
          [50.888466, 34.185214],
          [50.888742, 34.184943],
          [50.889576, 34.180494],
          [50.889856, 34.178824],
          [50.890132, 34.175773],
          [50.890409, 34.175494],
          [50.890409, 34.174383],
          [50.894575, 34.169104],
          [50.894856, 34.168273],
          [50.895409, 34.167163],
          [50.894856, 34.161603],
          [50.896242, 34.158553],
          [50.897355, 34.157442],
          [50.897355, 34.157163],
          [50.905412, 34.151883],
          [50.905965, 34.152162],
          [50.907912, 34.152162],
          [50.908189, 34.151883],
          [50.910412, 34.152993],
          [50.918188, 34.165493],
          [50.921245, 34.169383],
          [50.922635, 34.171324],
          [50.939302, 34.189934],
          [50.960968, 34.203834],
          [50.963748, 34.206054],
          [50.985691, 34.223554],
          [50.989024, 34.226884],
          [50.989304, 34.227435],
          [50.989574, 34.227708],
          [50.994861, 34.234664],
          [50.999861, 34.252164],
          [51.003194, 34.256884],
          [51.003471, 34.258274],
          [51.003747, 34.259104],
          [51.004861, 34.259384],
          [51.008471, 34.264384],
          [51.01211, 34.279489],
          [51.014027, 34.287443],
          [51.014584, 34.294112],
          [51.01347, 34.298832],
          [51.013194, 34.302993],
          [51.010414, 34.316052],
          [51.010137, 34.316882],
          [51.00486, 34.334111],
          [51.004304, 34.334941],
          [51.004304, 34.336051],
          [51.003747, 34.337441],
          [51.003194, 34.339381],
          [51.003194, 34.34022],
          [51.002914, 34.3405],
          [51.002637, 34.34272],
          [51.003194, 34.35327],
          [51.011527, 34.372168],
          [51.011804, 34.372447],
          [51.01847, 34.379667],
          [51.019027, 34.380217],
          [51.02125, 34.382447],
          [51.06153, 34.404114],
          [51.067916, 34.408004],
          [51.081806, 34.413003],
          [51.100142, 34.420502],
          [51.101253, 34.420782],
          [51.101809, 34.420782],
          [51.103196, 34.421062],
          [51.120142, 34.428552],
          [51.122919, 34.430222],
          [51.127365, 34.432721],
          [51.143199, 34.437721],
          [51.145975, 34.438551],
          [51.147365, 34.439111],
          [51.177368, 34.44439],
          [51.179312, 34.44439],
          [51.180702, 34.444669],
          [51.188758, 34.444669],
          [51.195978, 34.44494],
          [51.197368, 34.44522],
          [51.202091, 34.44522],
          [51.209591, 34.4455],
          [51.230981, 34.44633],
          [51.231538, 34.44661],
          [51.256814, 34.446059],
          [51.28654, 34.443839],
          [51.292093, 34.443],
          [51.294593, 34.443],
          [51.29626, 34.44272],
          [51.30015, 34.44272],
          [51.306816, 34.44217],
          [51.31015, 34.44161],
          [51.311049, 34.44161],
          [51.31154, 34.44161],
          [51.312096, 34.44134],
          [51.322096, 34.44161],
          [51.323561, 34.44106],
          [51.325986, 34.44106],
          [51.379599, 34.448],
          [51.390709, 34.450229],
          [51.405432, 34.452728],
          [51.412379, 34.454109],
          [51.432102, 34.457168],
          [51.438768, 34.458558],
          [51.501827, 34.468286],
          [51.505437, 34.468286],
          [51.506213, 34.46835],
          [51.515437, 34.469107],
          [51.572663, 34.476056],
          [51.58322, 34.477725],
          [51.604609, 34.482174],
          [51.606276, 34.482174],
          [51.628499, 34.486614],
          [51.629333, 34.486614],
          [51.630443, 34.487173],
          [51.631832, 34.487453],
          [51.632666, 34.487453],
          [51.633776, 34.488003],
          [51.668222, 34.492723],
          [51.679059, 34.493833],
          [51.681002, 34.494113],
          [51.683778, 34.494113],
          [51.694892, 34.495223],
          [51.707668, 34.497172],
          [51.710448, 34.497451],
          [51.712948, 34.498002],
          [51.714338, 34.498002],
          [51.716838, 34.498561],
          [51.800782, 34.510984],
          [51.809897, 34.509446],
          [51.82851, 34.505501],
          [51.83212, 34.505501],
          [51.834168, 34.505956],
          [51.833787, 34.506891],
          [51.83351, 34.50717],
          [51.833787, 34.50828],
          [51.834897, 34.50995],
          [51.835177, 34.5105],
          [51.83601, 34.51217],
          [51.83712, 34.516338],
          [51.838787, 34.523007],
          [51.839545, 34.523774],
          [51.840755, 34.532923],
          [51.84121, 34.554844],
          [51.840382, 34.568061],
          [51.850377, 34.567817],
          [51.855726, 34.573696],
          [51.861982, 34.583311],
          [51.884899, 34.629123],
          [51.888513, 34.635511],
          [51.913512, 34.695513],
          [51.914345, 34.696903],
          [51.915736, 34.701062],
          [51.917679, 34.704951],
          [51.927959, 34.733562],
          [51.932958, 34.752175],
          [51.934357, 34.759338],
          [51.937678, 34.776346],
          [51.937958, 34.776896],
          [51.939068, 34.784673],
          [51.940182, 34.795509],
          [51.948792, 34.825787],
          [51.950458, 34.834393],
          [51.959071, 34.858012],
          [51.964905, 34.865239],
          [51.962958, 34.867729],
          [51.952401, 34.873845],
          [51.952125, 34.874396],
          [51.935458, 34.883011],
          [51.935178, 34.883291],
          [51.909069, 34.900233],
          [51.852119, 34.929679],
          [51.850453, 34.930238],
          [51.833229, 34.939124],
          [51.83073, 34.939954],
          [51.812953, 34.949958],
          [51.811563, 34.951347],
          [51.778227, 34.971906],
          [51.777117, 34.972457],
          [51.735171, 35.000223],
          [51.733227, 35.00163],
          [51.724337, 35.005238],
          [51.723504, 35.005238],
          [51.720724, 35.006348],
          [51.711281, 35.008567],
          [51.710448, 35.008567],
          [51.709334, 35.009126],
          [51.694615, 35.011904],
          [51.692944, 35.011904],
          [51.691834, 35.012184],
          [51.689891, 35.012184],
          [51.688778, 35.012464],
          [51.687111, 35.012464],
          [51.685724, 35.012184],
          [51.681835, 35.012184],
          [51.680444, 35.011904],
          [51.677391, 35.011904],
          [51.656278, 35.008567],
          [51.651832, 35.008567],
          [51.650445, 35.008287],
          [51.647945, 35.008287],
          [51.645998, 35.008016],
          [51.643778, 35.008017],
          [51.642112, 35.007737],
          [51.638498, 35.007737],
          [51.637665, 35.007457],
          [51.636555, 35.007737],
          [51.633222, 35.007737],
          [51.632109, 35.008016],
          [51.630442, 35.008016],
          [51.619332, 35.009956],
          [51.614332, 35.011074],
          [51.599329, 35.017732],
          [51.589886, 35.027735],
          [51.581552, 35.048852],
          [51.570162, 35.056348],
          [51.567386, 35.058017],
          [51.49488, 35.104408],
          [51.48877, 35.106906],
          [51.482657, 35.108016],
          [51.482104, 35.108016],
          [51.47488, 35.109125],
          [51.472104, 35.109125],
          [51.47099, 35.109405],
          [51.467104, 35.109405],
          [51.465714, 35.109684],
          [51.460434, 35.109684],
          [51.459324, 35.109964],
          [51.457381, 35.109964],
          [51.454324, 35.110523],
          [51.453498, 35.110523],
          [51.452381, 35.110523],
          [51.447934, 35.111353],
          [51.446824, 35.111353],
          [51.412378, 35.122456],
          [51.379598, 35.133298],
          [51.351262, 35.141073],
          [51.342652, 35.143572],
          [51.333762, 35.14552],
          [51.331262, 35.14579],
          [51.308482, 35.151356],
          [51.24376, 35.166626],
          [51.242926, 35.166905],
          [51.24098, 35.166905],
          [51.2057, 35.17413],
          [51.19959, 35.174689],
          [51.17042, 35.178297],
          [51.146254, 35.180236],
          [51.145141, 35.180516],
          [51.140697, 35.180516],
          [51.139864, 35.180236],
          [51.132088, 35.180236],
          [51.131254, 35.180516],
          [51.125418, 35.180515],
          [51.112641, 35.179126],
          [51.110975, 35.179126],
          [51.110418, 35.178847],
          [51.109031, 35.178847],
          [51.106528, 35.178297],
          [51.105141, 35.178297],
          [51.104585, 35.178017],
          [51.103475, 35.178017],
          [51.090975, 35.174409],
          [51.082918, 35.171352],
          [51.034859, 35.143571],
          [51.023469, 35.140243],
          [51.017636, 35.139125],
          [51.015969, 35.138574],
          [51.014582, 35.138574],
          [51.014302, 35.138295],
          [51.013192, 35.138295],
          [51.012359, 35.138015],
          [51.007912, 35.138015],
          [50.999859, 35.139404],
          [50.994303, 35.139404],
          [50.992356, 35.139404],
          [50.991803, 35.139684],
          [50.989303, 35.139684],
          [50.988746, 35.139963],
          [50.984303, 35.139963],
          [50.983469, 35.140243],
          [50.9668, 35.140514],
          [50.966523, 35.140793],
          [50.96569, 35.140793],
          [50.965413, 35.140513],
          [50.963746, 35.140513],
          [50.962633, 35.140793],
          [50.952076, 35.140793],
          [50.950966, 35.141073],
          [50.95069, 35.140793],
          [50.945966, 35.140793],
          [50.94291, 35.140793],
          [50.935967, 35.140793],
          [50.934853, 35.141073],
          [50.93152, 35.141073],
          [50.93041, 35.141352],
          [50.925687, 35.141623],
          [50.91791, 35.143292],
          [50.91652, 35.143292],
          [50.91291, 35.144401],
          [50.911797, 35.144401],
          [50.90902, 35.14524],
          [50.90791, 35.14524],
          [50.89874, 35.148577],
          [50.887354, 35.152185],
          [50.872631, 35.158571],
          [50.869297, 35.159689],
          [50.850961, 35.171072],
          [50.849009, 35.173029],
          [50.847074, 35.170801],
          [50.833461, 35.164406],
          [50.832628, 35.164406],
          [50.831795, 35.163847],
          [50.823181, 35.160798],
          [50.820405, 35.159689],
          [50.819571, 35.15913],
          [50.816515, 35.158021],
          [50.815961, 35.157461],
          [50.806515, 35.152464],
          [50.772069, 35.11413],
          [50.770682, 35.111632],
          [50.770402, 35.110793],
          [50.767345, 35.105796],
          [50.758459, 35.097182],
          [50.757069, 35.096911],
          [50.756792, 35.096623],
          [50.754012, 35.095242],
          [50.753736, 35.094683],
          [50.751236, 35.093015],
          [50.719566, 35.070518],
          [50.7004, 35.047742],
          [50.697066, 35.044684],
          [50.695957, 35.044125],
          [50.695676, 35.043574],
          [50.67151, 35.031902],
          [50.66512, 35.029963],
          [50.654287, 35.019399],
          [50.65373, 35.01829],
          [50.651787, 35.013843],
          [50.65123, 35.010234],
          [50.650674, 35.008286],
          [50.64984, 34.99968],
          [50.653454, 34.994403],
          [50.65873, 34.988567],
          [50.660397, 34.987737],
          [50.680677, 34.971066],
          [50.680953, 34.970516],
          [50.682067, 34.969406],
          [50.682344, 34.968568],
          [50.684567, 34.966348],
          [50.684567, 34.965789],
          [50.68512, 34.965239],
          [50.692343, 34.951897],
          [50.693457, 34.948568],
          [50.693457, 34.935794],
          [50.69429, 34.932456],
          [50.69429, 34.931897],
          [50.69429, 34.931626],
          [50.694567, 34.931067],
          [50.694566, 34.929957],
          [50.696233, 34.923841],
          [50.698176, 34.913006],
          [50.698456, 34.911067],
          [50.699843, 34.905239],
          [50.700123, 34.90412],
          [50.700677, 34.901621],
          [50.700957, 34.899961],
          [50.701233, 34.898013],
          [50.70151, 34.896344],
          [50.70179, 34.894124],
          [50.702067, 34.893845],
          [50.702067, 34.890787],
          [50.702067, 34.889957],
          [50.702343, 34.889677],
          [50.702343, 34.888288],
          [50.702343, 34.885789],
          [50.702623, 34.88301],
          [50.7029, 34.881341],
          [50.703177, 34.879961],
          [50.70401, 34.877182],
          [50.70429, 34.876902],
          [50.70401, 34.876623],
          [50.704843, 34.873564],
          [50.704566, 34.872734],
          [50.704843, 34.871345],
          [50.705123, 34.868287],
          [50.70401, 34.868287],
          [50.703457, 34.868287],
          [50.7029, 34.868007],
          [50.699567, 34.868007],
          [50.689567, 34.868567],
          [50.676234, 34.868567],
          [50.675677, 34.868287],
          [50.672063, 34.868287],
          [50.67151, 34.868566],
          [50.66512, 34.868567],
          [50.664287, 34.868846],
          [50.662064, 34.868846],
          [50.66151, 34.869126],
          [50.660121, 34.869126],
          [50.659287, 34.869405],
          [50.65651, 34.869676],
          [50.653454, 34.869676],
          [50.65234, 34.869956],
          [50.650674, 34.869956],
          [50.649841, 34.870235],
          [50.642897, 34.870235],
          [50.642341, 34.869956],
          [50.639841, 34.869956],
          [50.639007, 34.869676],
          [50.629564, 34.868287],
          [50.626784, 34.868287],
          [50.625398, 34.868007],
          [50.622898, 34.868007],
          [50.622618, 34.867727],
          [50.621231, 34.867727],
          [50.602894, 34.864678],
          [50.600951, 34.863839],
          [50.599841, 34.863839],
          [50.598171, 34.862729],
          [50.592338, 34.86051],
          [50.585952, 34.858291],
          [50.584838, 34.858291],
          [50.584561, 34.858011],
          [50.583728, 34.858011],
          [50.583451, 34.857731],
          [50.582618, 34.857731],
          [50.582338, 34.857461],
          [50.580952, 34.857181],
          [50.580118, 34.856901],
          [50.576505, 34.855512],
          [50.575115, 34.855512],
          [50.574281, 34.855232],
          [50.572895, 34.855232],
          [50.572062, 34.854953],
          [50.569838, 34.854953],
          [50.569282, 34.855232],
          [50.561781, 34.855232],
          [50.561505, 34.855512],
          [50.554558, 34.857731],
          [50.547339, 34.857181],
          [50.546505, 34.857461],
          [50.546225, 34.857181],
          [50.544558, 34.857181],
          [50.544282, 34.857461],
          [50.543449, 34.857181],
          [50.542892, 34.857461],
          [50.542058, 34.857461],
          [50.536225, 34.859671],
          [50.530392, 34.862179],
          [50.522612, 34.864958],
          [50.520669, 34.864958],
          [50.520112, 34.865237],
          [50.518445, 34.865237],
          [50.517892, 34.865517],
          [50.516502, 34.865788],
          [50.508726, 34.865237],
          [50.501779, 34.863839],
          [50.494836, 34.861899],
          [50.491503, 34.86051],
          [50.490389, 34.86051],
          [50.490112, 34.86023],
          [50.489279, 34.86023],
          [50.488169, 34.859671],
          [50.481779, 34.856622],
          [50.468166, 34.844397],
          [50.464276, 34.841348],
          [50.458443, 34.83635],
          [50.451223, 34.826904],
          [50.450943, 34.824396],
          [50.450666, 34.820507],
          [50.451223, 34.818008],
          [50.45011, 34.810791],
          [50.448723, 34.803564],
          [50.440667, 34.777733],
          [50.439833, 34.775234],
          [50.434, 34.757451],
          [50.428443, 34.754122],
          [50.418164, 34.756062],
          [50.41761, 34.755782],
          [50.415664, 34.754952],
          [50.415387, 34.754672],
          [50.409274, 34.751903],
          [50.406774, 34.751903],
          [50.405941, 34.751623],
          [50.401497, 34.751623],
          [50.401221, 34.751903],
          [50.394831, 34.752733],
          [50.370941, 34.758561],
          [50.368994, 34.758561],
          [50.368441, 34.758841],
          [50.367051, 34.758841],
          [50.366494, 34.75912],
          [50.361774, 34.75912],
          [50.358718, 34.758841],
          [50.348438, 34.758841],
          [50.347885, 34.75912],
          [50.341218, 34.759951],
          [50.327881, 34.768007],
          [50.313715, 34.779953],
          [50.312048, 34.781342],
          [50.270379, 34.806342],
          [50.268712, 34.807172],
          [50.259825, 34.811341],
          [50.258992, 34.811341],
          [50.253436, 34.814119],
          [50.252602, 34.814119],
          [50.252322, 34.814399],
          [50.251769, 34.814399],
          [50.251489, 34.814679],
          [50.248436, 34.815229],
          [50.246212, 34.814119],
          [50.245656, 34.81384],
          [50.242602, 34.81301],
          [50.229266, 34.81328],
          [50.228713, 34.81301],
          [50.217876, 34.814399],
          [50.214823, 34.814399],
          [50.213989, 34.814679],
          [50.185377, 34.818567],
          [50.182876, 34.819397],
          [50.18232, 34.819397],
          [50.174263, 34.821346],
          [50.169263, 34.821346],
          [50.168987, 34.821626],
          [50.16843, 34.821626],
          [50.168153, 34.821346],
          [50.16732, 34.821346],
          [50.165586, 34.819744],
          [50.163707, 34.818008],
          [50.163153, 34.817178],
          [50.15982, 34.814399],
          [50.15954, 34.81356],
          [50.131484, 34.774675],
          [50.122041, 34.755511],
          [50.121484, 34.751063],
          [50.120374, 34.746615],
          [50.120374, 34.745226],
          [50.120094, 34.744116],
          [50.119817, 34.743557],
          [50.119541, 34.740227],
          [50.119817, 34.739397],
          [50.119541, 34.738838],
          [50.119817, 34.734678],
          [50.120094, 34.733839],
          [50.120651, 34.733009],
          [50.123151, 34.724952],
          [50.124817, 34.719394],
          [50.124541, 34.715785],
          [50.121761, 34.710227],
          [50.113151, 34.698561],
          [50.112874, 34.697171],
          [50.113428, 34.695511],
          [50.113428, 34.695232],
          [50.114541, 34.693283],
          [50.113984, 34.690504],
          [50.113428, 34.689953],
          [50.111484, 34.686335],
          [50.108151, 34.684395],
          [50.103151, 34.675508],
          [50.102038, 34.66939],
          [50.102318, 34.665501],
          [50.102594, 34.664392],
          [50.107594, 34.656614],
          [50.109674, 34.654329],
          [50.110094, 34.653835],
          [50.110374, 34.652454],
          [50.110651, 34.650785],
          [50.110928, 34.646616],
          [50.111208, 34.646337],
          [50.110928, 34.645507],
          [50.109248, 34.632399],
          [50.108151, 34.623833],
          [50.101485, 34.611616],
          [50.101485, 34.611056],
          [50.086481, 34.586062],
          [50.086205, 34.583562],
        ],
      ],
    },
  },
  Semnan: {
    properties: { value: "Semnan", name: "استان سمنان, ایران", id: 25 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [51.83351, 34.50717],
          [51.833787, 34.506891],
          [51.834168, 34.505956],
          [51.83462, 34.505781],
          [51.83462, 34.505501],
          [51.856843, 34.490503],
          [51.858786, 34.489953],
          [51.8599, 34.489953],
          [51.861286, 34.489393],
          [51.861566, 34.488834],
          [51.873233, 34.484944],
          [51.877676, 34.483004],
          [51.877956, 34.482725],
          [51.906569, 34.472446],
          [51.967125, 34.446331],
          [51.977682, 34.442441],
          [52.014628, 34.430502],
          [52.015741, 34.430502],
          [52.055741, 34.422444],
          [52.066574, 34.419393],
          [52.067964, 34.419393],
          [52.10241, 34.411335],
          [52.106577, 34.410225],
          [52.107133, 34.410225],
          [52.1088, 34.409674],
          [52.10991, 34.409674],
          [52.116857, 34.407725],
          [52.118523, 34.407725],
          [52.1188, 34.407445],
          [52.12019, 34.407445],
          [52.120743, 34.407165],
          [52.121577, 34.407165],
          [52.129356, 34.404666],
          [52.129913, 34.404666],
          [52.130746, 34.404666],
          [52.134356, 34.403556],
          [52.135466, 34.403556],
          [52.139633, 34.402725],
          [52.1413, 34.402725],
          [52.142413, 34.402446],
          [52.14408, 34.402446],
          [52.145989, 34.402187],
          [52.149356, 34.401615],
          [52.15297, 34.401615],
          [52.155189, 34.401336],
          [52.158523, 34.401336],
          [52.160746, 34.401615],
          [52.161427, 34.401616],
          [52.173803, 34.401616],
          [52.175193, 34.401336],
          [52.178246, 34.401336],
          [52.179359, 34.401056],
          [52.182969, 34.401056],
          [52.183802, 34.401336],
          [52.187136, 34.401336],
          [52.187692, 34.401616],
          [52.190749, 34.401615],
          [52.191582, 34.401895],
          [52.206582, 34.401895],
          [52.208249, 34.402166],
          [52.213525, 34.402166],
          [52.214639, 34.402446],
          [52.217972, 34.402446],
          [52.221859, 34.402996],
          [52.224082, 34.402996],
          [52.225472, 34.403276],
          [52.229362, 34.403276],
          [52.230749, 34.403556],
          [52.232972, 34.403556],
          [52.247418, 34.405505],
          [52.253528, 34.406606],
          [52.259638, 34.407445],
          [52.261585, 34.407445],
          [52.280198, 34.410225],
          [52.284085, 34.410495],
          [52.285474, 34.410775],
          [52.286865, 34.411055],
          [52.287418, 34.411335],
          [52.291584, 34.411894],
          [52.302974, 34.414114],
          [52.312974, 34.415504],
          [52.328531, 34.419944],
          [52.329644, 34.419944],
          [52.331311, 34.420504],
          [52.332421, 34.420504],
          [52.3527, 34.424393],
          [52.354644, 34.424393],
          [52.376867, 34.427723],
          [52.378813, 34.427723],
          [52.379923, 34.428003],
          [52.388533, 34.428833],
          [52.39298, 34.429113],
          [52.39437, 34.429393],
          [52.395203, 34.429113],
          [52.39548, 34.429392],
          [52.39937, 34.429392],
          [52.399923, 34.429113],
          [52.402287, 34.429113],
          [52.403813, 34.428833],
          [52.40409, 34.428553],
          [52.40548, 34.428553],
          [52.406036, 34.428273],
          [52.40687, 34.428273],
          [52.40909, 34.428274],
          [52.409646, 34.428553],
          [52.410203, 34.428274],
          [52.416036, 34.427443],
          [52.417426, 34.427173],
          [52.429646, 34.427173],
          [52.430759, 34.427443],
          [52.436869, 34.426613],
          [52.444093, 34.426613],
          [52.444649, 34.426333],
          [52.447426, 34.426333],
          [52.448536, 34.426053],
          [52.450482, 34.426054],
          [52.453259, 34.425503],
          [52.456316, 34.425503],
          [52.459092, 34.424944],
          [52.479649, 34.422164],
          [52.482149, 34.422164],
          [52.482982, 34.421884],
          [52.488819, 34.421063],
          [52.499929, 34.419114],
          [52.502512, 34.418413],
          [52.516318, 34.414665],
          [52.563544, 34.404946],
          [52.565764, 34.404115],
          [52.566878, 34.404115],
          [52.576321, 34.400776],
          [52.577711, 34.400506],
          [52.578264, 34.400226],
          [52.579098, 34.400226],
          [52.579378, 34.399946],
          [52.609657, 34.387447],
          [52.64577, 34.377448],
          [52.67327, 34.36661],
          [52.677993, 34.36439],
          [52.748552, 34.333273],
          [52.761052, 34.326612],
          [52.766609, 34.323833],
          [52.767719, 34.323002],
          [52.814945, 34.299114],
          [52.814945, 34.298834],
          [52.815498, 34.298554],
          [52.816331, 34.298058],
          [52.841611, 34.283004],
          [52.862168, 34.262996],
          [52.862168, 34.262445],
          [52.864947, 34.259945],
          [52.867447, 34.257445],
          [52.880501, 34.249106],
          [52.882167, 34.249106],
          [52.882167, 34.249386],
          [52.882724, 34.249386],
          [52.883837, 34.249386],
          [52.884114, 34.249106],
          [52.885224, 34.249106],
          [52.886337, 34.248826],
          [52.89217, 34.248555],
          [52.898557, 34.248555],
          [52.899947, 34.248826],
          [52.904394, 34.248826],
          [52.905504, 34.249106],
          [52.909394, 34.249106],
          [52.91106, 34.249386],
          [52.913837, 34.249386],
          [52.918837, 34.249945],
          [52.929393, 34.249945],
          [52.950506, 34.251886],
          [52.95384, 34.251886],
          [52.95606, 34.252165],
          [52.969673, 34.252165],
          [52.970783, 34.251886],
          [52.972449, 34.252165],
          [52.973839, 34.251886],
          [52.975229, 34.252165],
          [52.996062, 34.252165],
          [52.998009, 34.252445],
          [53.019676, 34.252445],
          [53.021619, 34.252165],
          [53.028842, 34.252165],
          [53.038289, 34.251886],
          [53.048842, 34.251886],
          [53.050508, 34.251606],
          [53.054678, 34.251606],
          [53.055512, 34.251326],
          [53.066622, 34.251326],
          [53.068012, 34.251606],
          [53.085235, 34.251606],
          [53.085788, 34.251886],
          [53.091068, 34.251886],
          [53.091625, 34.252165],
          [53.095511, 34.252166],
          [53.096068, 34.251886],
          [53.098845, 34.251886],
          [53.099958, 34.251886],
          [53.101625, 34.251886],
          [53.107178, 34.252716],
          [53.114958, 34.252445],
          [53.135237, 34.249106],
          [53.138847, 34.249106],
          [53.140791, 34.248826],
          [53.146347, 34.248826],
          [53.149681, 34.249106],
          [53.156904, 34.249106],
          [53.170237, 34.253276],
          [53.17357, 34.253276],
          [53.174127, 34.252996],
          [53.175237, 34.252996],
          [53.175517, 34.252716],
          [53.178293, 34.251886],
          [53.179403, 34.251886],
          [53.17996, 34.251606],
          [53.18107, 34.251606],
          [53.18107, 34.251326],
          [53.18357, 34.251055],
          [53.186907, 34.251055],
          [53.188017, 34.250776],
          [53.19274, 34.250776],
          [53.19385, 34.250496],
          [53.201627, 34.250496],
          [53.202183, 34.250776],
          [53.203573, 34.250776],
          [53.207183, 34.250776],
          [53.207463, 34.251055],
          [53.21385, 34.251606],
          [53.214963, 34.251326],
          [53.239129, 34.250216],
          [53.245796, 34.250216],
          [53.247186, 34.250496],
          [53.250796, 34.250496],
          [53.251909, 34.250776],
          [53.254963, 34.250776],
          [53.255519, 34.251055],
          [53.257186, 34.251055],
          [53.258019, 34.251326],
          [53.259409, 34.251326],
          [53.260243, 34.251606],
          [53.267466, 34.251606],
          [53.271076, 34.251055],
          [53.288856, 34.251055],
          [53.290522, 34.251326],
          [53.292742, 34.251326],
          [53.294132, 34.251606],
          [53.295799, 34.251606],
          [53.301912, 34.251886],
          [53.303855, 34.251886],
          [53.304965, 34.252166],
          [53.306632, 34.251886],
          [53.319968, 34.251886],
          [53.321912, 34.251606],
          [53.324412, 34.251606],
          [53.326355, 34.251886],
          [53.344135, 34.251886],
          [53.346358, 34.251606],
          [53.364691, 34.251606],
          [53.367191, 34.251886],
          [53.385248, 34.251886],
          [53.387748, 34.252166],
          [53.391081, 34.252166],
          [53.399138, 34.252996],
          [53.403027, 34.252996],
          [53.404414, 34.253276],
          [53.408304, 34.253276],
          [53.408861, 34.252996],
          [53.428027, 34.252996],
          [53.429694, 34.253276],
          [53.44664, 34.253276],
          [53.448584, 34.252996],
          [53.45553, 34.252996],
          [53.457473, 34.252716],
          [53.46664, 34.252445],
          [53.47053, 34.252445],
          [53.47192, 34.252166],
          [53.473587, 34.252445],
          [53.47414, 34.252166],
          [53.47553, 34.252166],
          [53.477197, 34.252445],
          [53.477753, 34.252166],
          [53.47942, 34.252166],
          [53.481087, 34.252445],
          [53.484973, 34.252445],
          [53.485806, 34.252166],
          [53.502196, 34.252166],
          [53.502753, 34.252445],
          [53.507753, 34.252445],
          [53.508309, 34.252166],
          [53.510253, 34.252166],
          [53.510533, 34.251886],
          [53.514699, 34.251886],
          [53.515533, 34.251606],
          [53.517476, 34.251606],
          [53.518866, 34.251606],
          [53.519143, 34.251326],
          [53.520253, 34.251326],
          [53.531366, 34.252716],
          [53.564422, 34.250776],
          [53.572479, 34.250776],
          [53.574979, 34.251056],
          [53.581369, 34.251056],
          [53.583035, 34.251326],
          [53.584978, 34.251055],
          [53.585812, 34.251326],
          [53.589978, 34.250776],
          [53.599425, 34.250776],
          [53.601925, 34.251056],
          [53.613591, 34.251056],
          [53.614701, 34.250776],
          [53.616648, 34.250776],
          [53.618868, 34.250496],
          [53.662761, 34.250496],
          [53.665538, 34.250216],
          [53.698317, 34.250216],
          [53.701097, 34.250496],
          [53.71443, 34.250496],
          [53.716654, 34.250216],
          [53.724987, 34.250216],
          [53.736097, 34.249945],
          [53.751377, 34.249945],
          [53.762766, 34.251056],
          [53.7661, 34.251055],
          [53.768043, 34.251326],
          [53.773323, 34.251326],
          [53.775543, 34.251606],
          [53.778876, 34.251606],
          [53.781099, 34.251886],
          [53.786933, 34.251886],
          [53.788043, 34.252166],
          [53.794156, 34.252166],
          [53.796379, 34.252445],
          [53.802213, 34.252445],
          [53.804432, 34.252716],
          [53.805546, 34.252445],
          [53.841379, 34.252445],
          [53.843602, 34.252716],
          [53.885271, 34.251326],
          [53.918604, 34.248276],
          [53.922218, 34.248276],
          [53.931941, 34.247996],
          [53.938051, 34.247996],
          [53.938884, 34.247716],
          [53.940831, 34.247996],
          [53.944164, 34.247996],
          [53.946664, 34.247716],
          [53.959164, 34.247166],
          [53.976664, 34.247166],
          [53.97722, 34.247445],
          [53.980277, 34.247446],
          [53.98083, 34.247716],
          [53.985554, 34.247716],
          [53.986387, 34.247996],
          [53.994163, 34.247996],
          [54.000277, 34.249106],
          [54.004443, 34.249666],
          [54.00472, 34.249945],
          [54.008887, 34.249945],
          [54.01, 34.249666],
          [54.011667, 34.249666],
          [54.012777, 34.249386],
          [54.015, 34.249386],
          [54.016667, 34.249106],
          [54.019166, 34.249106],
          [54.020833, 34.248826],
          [54.028056, 34.248276],
          [54.032776, 34.248276],
          [54.034446, 34.247996],
          [54.035833, 34.248276],
          [54.042499, 34.248276],
          [54.043889, 34.248556],
          [54.051113, 34.248556],
          [54.061669, 34.250216],
          [54.064446, 34.250216],
          [54.066389, 34.250496],
          [54.080559, 34.250496],
          [54.082226, 34.250776],
          [54.085002, 34.250776],
          [54.086946, 34.251056],
          [54.089726, 34.251056],
          [54.091669, 34.251326],
          [54.097782, 34.251326],
          [54.099725, 34.251606],
          [54.105282, 34.251606],
          [54.107502, 34.251886],
          [54.116115, 34.251886],
          [54.118059, 34.251606],
          [54.122225, 34.251606],
          [54.133062, 34.251056],
          [54.136672, 34.251056],
          [54.137228, 34.250776],
          [54.149452, 34.250776],
          [54.150562, 34.250496],
          [54.152785, 34.250496],
          [54.153338, 34.250216],
          [54.162785, 34.249106],
          [54.167785, 34.249106],
          [54.168618, 34.248826],
          [54.174451, 34.248556],
          [54.175008, 34.248826],
          [54.179174, 34.248826],
          [54.179451, 34.249106],
          [54.180841, 34.249106],
          [54.181118, 34.249386],
          [54.181674, 34.249386],
          [54.183061, 34.249945],
          [54.183894, 34.249945],
          [54.18417, 34.250212],
          [54.184174, 34.250216],
          [54.185284, 34.250216],
          [54.185284, 34.250496],
          [54.186508, 34.250496],
          [54.186951, 34.250216],
          [54.187784, 34.250216],
          [54.188341, 34.250216],
          [54.190841, 34.250216],
          [54.191398, 34.250496],
          [54.195841, 34.250496],
          [54.196398, 34.250776],
          [54.200284, 34.250776],
          [54.203897, 34.251326],
          [54.216397, 34.250496],
          [54.221674, 34.250496],
          [54.222507, 34.250216],
          [54.23973, 34.249386],
          [54.247787, 34.249386],
          [54.2539, 34.249106],
          [54.25751, 34.249106],
          [54.2589, 34.248826],
          [54.260287, 34.249106],
          [54.26279, 34.249106],
          [54.2664, 34.249666],
          [54.27501, 34.249666],
          [54.27529, 34.249945],
          [54.275843, 34.249666],
          [54.276123, 34.249945],
          [54.276677, 34.249666],
          [54.28029, 34.249666],
          [54.280847, 34.249666],
          [54.284457, 34.249666],
          [54.28779, 34.250496],
          [54.28918, 34.250496],
          [54.29029, 34.250776],
          [54.290846, 34.250496],
          [54.291956, 34.250776],
          [54.303623, 34.250776],
          [54.306123, 34.250496],
          [54.308623, 34.250496],
          [54.310013, 34.250216],
          [54.321126, 34.250216],
          [54.331679, 34.251056],
          [54.336126, 34.251056],
          [54.348069, 34.249945],
          [54.359384, 34.249946],
          [54.360849, 34.249945],
          [54.362792, 34.249666],
          [54.371405, 34.249666],
          [54.373905, 34.249945],
          [54.376405, 34.249945],
          [54.377795, 34.250216],
          [54.396962, 34.249945],
          [54.399462, 34.250216],
          [54.403905, 34.250216],
          [54.406685, 34.250496],
          [54.430018, 34.250496],
          [54.431408, 34.250216],
          [54.437241, 34.250216],
          [54.438631, 34.250496],
          [54.445575, 34.250496],
          [54.448351, 34.250776],
          [54.471687, 34.250776],
          [54.474464, 34.250496],
          [54.487244, 34.249945],
          [54.489467, 34.249945],
          [54.490854, 34.249666],
          [54.492801, 34.249666],
          [54.49502, 34.249386],
          [54.497801, 34.249386],
          [54.499744, 34.249106],
          [54.50252, 34.249106],
          [54.504467, 34.248826],
          [54.513077, 34.248826],
          [54.51419, 34.249106],
          [54.518634, 34.249106],
          [54.519743, 34.249386],
          [54.521967, 34.249386],
          [54.52419, 34.249666],
          [54.529747, 34.249666],
          [54.531133, 34.249945],
          [54.533357, 34.249666],
          [54.542523, 34.249666],
          [54.54447, 34.249386],
          [54.554746, 34.249386],
          [54.55669, 34.249106],
          [54.586969, 34.249106],
          [54.588636, 34.248826],
          [54.589193, 34.249106],
          [54.590583, 34.248826],
          [54.598359, 34.248556],
          [54.598916, 34.248826],
          [54.600026, 34.248555],
          [54.600859, 34.248826],
          [54.610026, 34.248826],
          [54.610306, 34.249106],
          [54.611416, 34.249106],
          [54.611692, 34.249106],
          [54.612526, 34.249106],
          [54.612806, 34.248826],
          [54.615582, 34.248556],
          [54.620582, 34.247716],
          [54.623362, 34.247716],
          [54.628362, 34.246886],
          [54.630582, 34.246886],
          [54.632805, 34.246606],
          [54.647805, 34.246606],
          [54.651139, 34.246326],
          [54.656419, 34.246326],
          [54.680585, 34.248276],
          [54.684198, 34.247996],
          [54.687808, 34.247996],
          [54.689475, 34.247716],
          [54.704755, 34.246056],
          [54.715588, 34.246055],
          [54.740867, 34.247716],
          [54.755867, 34.246886],
          [54.767534, 34.246886],
          [54.77031, 34.247166],
          [54.77448, 34.247166],
          [54.777534, 34.246886],
          [54.78198, 34.246886],
          [54.784757, 34.246606],
          [54.788924, 34.246606],
          [54.791704, 34.246326],
          [54.798923, 34.246055],
          [54.811427, 34.246055],
          [54.813647, 34.246326],
          [54.823093, 34.246326],
          [54.83476, 34.246326],
          [54.840316, 34.246326],
          [54.842536, 34.246606],
          [54.848373, 34.246606],
          [54.849483, 34.246886],
          [54.851983, 34.246886],
          [54.867539, 34.248276],
          [54.882539, 34.248276],
          [54.884486, 34.247996],
          [54.890039, 34.247996],
          [54.892819, 34.248276],
          [54.921152, 34.248276],
          [54.923932, 34.247996],
          [54.926432, 34.248276],
          [54.934208, 34.248276],
          [54.936985, 34.248555],
          [54.964488, 34.248555],
          [54.967265, 34.248826],
          [54.970878, 34.248826],
          [54.973098, 34.249106],
          [55.009211, 34.249106],
          [55.010877, 34.248826],
          [55.016714, 34.248555],
          [55.017547, 34.248826],
          [55.018934, 34.248555],
          [55.025881, 34.248826],
          [55.027547, 34.249106],
          [55.042824, 34.249106],
          [55.044214, 34.248826],
          [55.045047, 34.249106],
          [55.046437, 34.248826],
          [55.05477, 34.248276],
          [55.083103, 34.248276],
          [55.08366, 34.247996],
          [55.08505, 34.248276],
          [55.08616, 34.247996],
          [55.094773, 34.247996],
          [55.09616, 34.247716],
          [55.10866, 34.247445],
          [55.130886, 34.247445],
          [55.131996, 34.247716],
          [55.135886, 34.247445],
          [55.142552, 34.247445],
          [55.151442, 34.247445],
          [55.151996, 34.247716],
          [55.155329, 34.247716],
          [55.155609, 34.247996],
          [55.159496, 34.247996],
          [55.159776, 34.248276],
          [55.162829, 34.248276],
          [55.163662, 34.248555],
          [55.166162, 34.249106],
          [55.167781, 34.249183],
          [55.168385, 34.249386],
          [55.169499, 34.249386],
          [55.170052, 34.249665],
          [55.170052, 34.249386],
          [55.171166, 34.249386],
          [55.174219, 34.249945],
          [55.175332, 34.250496],
          [55.176165, 34.250496],
          [55.178385, 34.251055],
          [55.183109, 34.251606],
          [55.189499, 34.251606],
          [55.190889, 34.251326],
          [55.198389, 34.251326],
          [55.208388, 34.252716],
          [55.210888, 34.252716],
          [55.213388, 34.252996],
          [55.227001, 34.252996],
          [55.228111, 34.252716],
          [55.232555, 34.252445],
          [55.256724, 34.252445],
          [55.259224, 34.252165],
          [55.263114, 34.252165],
          [55.265334, 34.252445],
          [55.278667, 34.252445],
          [55.280891, 34.252716],
          [55.284224, 34.252716],
          [55.297557, 34.253826],
          [55.311727, 34.253555],
          [55.325894, 34.253555],
          [55.328393, 34.253275],
          [55.334503, 34.253275],
          [55.34034, 34.252716],
          [55.350893, 34.252445],
          [55.35256, 34.252445],
          [55.353673, 34.252165],
          [55.358116, 34.252165],
          [55.359783, 34.252445],
          [55.364506, 34.252445],
          [55.36645, 34.252165],
          [55.372563, 34.252165],
          [55.374506, 34.251886],
          [55.377563, 34.251886],
          [55.379506, 34.251606],
          [55.386729, 34.251606],
          [55.390619, 34.252165],
          [55.392839, 34.252165],
          [55.394229, 34.252445],
          [55.413399, 34.252445],
          [55.415066, 34.252165],
          [55.422009, 34.252165],
          [55.423399, 34.251886],
          [55.424232, 34.252165],
          [55.425899, 34.251886],
          [55.435899, 34.251886],
          [55.437289, 34.251606],
          [55.440622, 34.251606],
          [55.441175, 34.251326],
          [55.447842, 34.251055],
          [55.457565, 34.251055],
          [55.458399, 34.250775],
          [55.462845, 34.250775],
          [55.463955, 34.251055],
          [55.468122, 34.251055],
          [55.468678, 34.250775],
          [55.470622, 34.250775],
          [55.473955, 34.251055],
          [55.478678, 34.251885],
          [55.479512, 34.251885],
          [55.481455, 34.252165],
          [55.482288, 34.251885],
          [55.482845, 34.252165],
          [55.485621, 34.252165],
          [55.486178, 34.252445],
          [55.488121, 34.252445],
          [55.500068, 34.252716],
          [55.581184, 34.239386],
          [55.58646, 34.239385],
          [55.59424, 34.240216],
          [55.595627, 34.240496],
          [55.600627, 34.240496],
          [55.60174, 34.240775],
          [55.605073, 34.240775],
          [55.606183, 34.240496],
          [55.612573, 34.240496],
          [55.649796, 34.242716],
          [55.653409, 34.242445],
          [55.654796, 34.242165],
          [55.658409, 34.242165],
          [55.659799, 34.242445],
          [55.668966, 34.242716],
          [55.669799, 34.242445],
          [55.677576, 34.242445],
          [55.678689, 34.242165],
          [55.709522, 34.242716],
          [55.710912, 34.242995],
          [55.722855, 34.242996],
          [55.723688, 34.242716],
          [55.725912, 34.242716],
          [55.726745, 34.242445],
          [55.746468, 34.242716],
          [55.748968, 34.243555],
          [55.749245, 34.243826],
          [55.749802, 34.244106],
          [55.752025, 34.244106],
          [55.752582, 34.243826],
          [55.753135, 34.244106],
          [55.762301, 34.243555],
          [55.763135, 34.243275],
          [55.773138, 34.243275],
          [55.777581, 34.243555],
          [55.782305, 34.243555],
          [55.782858, 34.243275],
          [55.786191, 34.243275],
          [55.786748, 34.242995],
          [55.788691, 34.242995],
          [55.789248, 34.242716],
          [55.791194, 34.242716],
          [55.791471, 34.242445],
          [55.793694, 34.242445],
          [55.794528, 34.242165],
          [55.797304, 34.242165],
          [55.797861, 34.242445],
          [55.798694, 34.242165],
          [55.799804, 34.242445],
          [55.807581, 34.242445],
          [55.810084, 34.242716],
          [55.810637, 34.242995],
          [55.815361, 34.242995],
          [55.816471, 34.243275],
          [55.84675, 34.243275],
          [55.85175, 34.243826],
          [55.856473, 34.244385],
          [55.86064, 34.244385],
          [55.861474, 34.244106],
          [55.86314, 34.244106],
          [55.86453, 34.244385],
          [55.86592, 34.244385],
          [55.866474, 34.244665],
          [55.86814, 34.244665],
          [55.869253, 34.244945],
          [55.876197, 34.244945],
          [55.877306, 34.244665],
          [55.880087, 34.244665],
          [55.881197, 34.244385],
          [55.891753, 34.244385],
          [55.892587, 34.244106],
          [55.896753, 34.244105],
          [55.89731, 34.243826],
          [55.89981, 34.243826],
          [55.900363, 34.243555],
          [55.901196, 34.243826],
          [55.90342, 34.243555],
          [55.903976, 34.243275],
          [55.911753, 34.243275],
          [55.913976, 34.243826],
          [55.914533, 34.244105],
          [55.915086, 34.244105],
          [55.915366, 34.244385],
          [55.9162, 34.244385],
          [55.919533, 34.245775],
          [55.919809, 34.246055],
          [55.920366, 34.246605],
          [55.920366, 34.246885],
          [55.920643, 34.247165],
          [55.921199, 34.247445],
          [55.943699, 34.290215],
          [55.948979, 34.298003],
          [55.948979, 34.298554],
          [55.951756, 34.302714],
          [55.957589, 34.314104],
          [55.992035, 34.374668],
          [55.992869, 34.375499],
          [55.995369, 34.380218],
          [55.998982, 34.385498],
          [56.062871, 34.466888],
          [56.063428, 34.467167],
          [56.066761, 34.471607],
          [56.113707, 34.545223],
          [56.116487, 34.550231],
          [56.118154, 34.553281],
          [56.118431, 34.554391],
          [56.133987, 34.576896],
          [56.135097, 34.578006],
          [56.1576, 34.616617],
          [56.163433, 34.625225],
          [56.163987, 34.626343],
          [56.16899, 34.632452],
          [56.21788, 34.693285],
          [56.225659, 34.706061],
          [56.227046, 34.70801],
          [56.227326, 34.70884],
          [56.227603, 34.709119],
          [56.227974, 34.710417],
          [56.228436, 34.711898],
          [56.236769, 34.724124],
          [56.239269, 34.727174],
          [56.252049, 34.746897],
          [56.253716, 34.749396],
          [56.253993, 34.750515],
          [56.263716, 34.763841],
          [56.264549, 34.7644],
          [56.288439, 34.798567],
          [56.290939, 34.801616],
          [56.335942, 34.853573],
          [56.336218, 34.854403],
          [56.340665, 34.858572],
          [56.341775, 34.860232],
          [56.342052, 34.860511],
          [56.349552, 34.866899],
          [56.356775, 34.877454],
          [56.357055, 34.878284],
          [56.357332, 34.878563],
          [56.357332, 34.878843],
          [56.394554, 34.92496],
          [56.396221, 34.92718],
          [56.396501, 34.928019],
          [56.400111, 34.933567],
          [56.400668, 34.934126],
          [56.400668, 34.934676],
          [56.442891, 34.979682],
          [56.453724, 34.999961],
          [56.481503, 35.022188],
          [56.488447, 35.028574],
          [56.51706, 35.041347],
          [56.517893, 35.041347],
          [56.519007, 35.042186],
          [56.532896, 35.046624],
          [56.534563, 35.046904],
          [56.536783, 35.047743],
          [56.562063, 35.05246],
          [56.563729, 35.05246],
          [56.564562, 35.05274],
          [56.568729, 35.05274],
          [56.570396, 35.05302],
          [56.571786, 35.05302],
          [56.572343, 35.05302],
          [56.580119, 35.053849],
          [56.581509, 35.053849],
          [56.582062, 35.054129],
          [56.584009, 35.054129],
          [56.585119, 35.054409],
          [56.587066, 35.054409],
          [56.589566, 35.054959],
          [56.591509, 35.054959],
          [56.593732, 35.055518],
          [56.594842, 35.055518],
          [56.624012, 35.064133],
          [56.625399, 35.064403],
          [56.626512, 35.064962],
          [56.640122, 35.066622],
          [56.641235, 35.066902],
          [56.652068, 35.066902],
          [56.653178, 35.066622],
          [56.657901, 35.066622],
          [56.663458, 35.065801],
          [56.665125, 35.065233],
          [56.666791, 35.064962],
          [56.678458, 35.061905],
          [56.680401, 35.061904],
          [56.680958, 35.061625],
          [56.683735, 35.061625],
          [56.684291, 35.061905],
          [56.686234, 35.061905],
          [56.687068, 35.062184],
          [56.688734, 35.062184],
          [56.689568, 35.062464],
          [56.690958, 35.062464],
          [56.719571, 35.079963],
          [56.720404, 35.080243],
          [56.722627, 35.082462],
          [56.725127, 35.084121],
          [56.725404, 35.08468],
          [56.726794, 35.08579],
          [56.741517, 35.099681],
          [56.77374, 35.146909],
          [56.77513, 35.148298],
          [56.78235, 35.153574],
          [56.811243, 35.167464],
          [56.827076, 35.184683],
          [56.828743, 35.186071],
          [56.828743, 35.186351],
          [56.830686, 35.188299],
          [56.842909, 35.196904],
          [56.843743, 35.197743],
          [56.855689, 35.202469],
          [56.857356, 35.202469],
          [56.861243, 35.203298],
          [56.862356, 35.203298],
          [56.862909, 35.203578],
          [56.865409, 35.203578],
          [56.866246, 35.203298],
          [56.868466, 35.203298],
          [56.869579, 35.203019],
          [56.874022, 35.203019],
          [56.875132, 35.202739],
          [56.876522, 35.203019],
          [56.882632, 35.203019],
          [56.883746, 35.202739],
          [56.887079, 35.202739],
          [56.887636, 35.203019],
          [56.889855, 35.203019],
          [56.890412, 35.203298],
          [56.891246, 35.203298],
          [56.891802, 35.203578],
          [56.893469, 35.203578],
          [56.894022, 35.203858],
          [56.895135, 35.203857],
          [56.907635, 35.207465],
          [56.908192, 35.208015],
          [56.909302, 35.208304],
          [56.909859, 35.208854],
          [56.910969, 35.209134],
          [56.916525, 35.213301],
          [56.934858, 35.220516],
          [56.935415, 35.220795],
          [56.936802, 35.220795],
          [56.937082, 35.221075],
          [56.940968, 35.221075],
          [56.941525, 35.220795],
          [56.942638, 35.220795],
          [56.946525, 35.219686],
          [56.947358, 35.219686],
          [56.951525, 35.218018],
          [56.952358, 35.217747],
          [56.965695, 35.21441],
          [56.966528, 35.21413],
          [56.967638, 35.21413],
          [56.975138, 35.211632],
          [56.980695, 35.210802],
          [56.980971, 35.210523],
          [56.983195, 35.210523],
          [56.983751, 35.210802],
          [56.986804, 35.210802],
          [56.990694, 35.211912],
          [57.00023, 35.215249],
          [57.010694, 35.223573],
          [57.031251, 35.267468],
          [57.031531, 35.267748],
          [57.031807, 35.268857],
          [57.039307, 35.281078],
          [57.05014, 35.307186],
          [57.052087, 35.314969],
          [57.052364, 35.321084],
          [57.036254, 35.337461],
          [57.035974, 35.33802],
          [57.035417, 35.3383],
          [57.034307, 35.339409],
          [57.019584, 35.364136],
          [57.005418, 35.404131],
          [57.004861, 35.412472],
          [57.004585, 35.413861],
          [57.00024, 35.429695],
          [56.996528, 35.432193],
          [56.991251, 35.441914],
          [56.990971, 35.450526],
          [56.990972, 35.450805],
          [56.993751, 35.454412],
          [56.994028, 35.45581],
          [56.994305, 35.458308],
          [56.994585, 35.460526],
          [56.995418, 35.46636],
          [56.995695, 35.467748],
          [56.996251, 35.469696],
          [57.000141, 35.487469],
          [57.022918, 35.500805],
          [57.042921, 35.52497],
          [57.046254, 35.531642],
          [57.047641, 35.535528],
          [57.047641, 35.535808],
          [57.047921, 35.536358],
          [57.047921, 35.537196],
          [57.048198, 35.537746],
          [57.045141, 35.546916],
          [57.044031, 35.548305],
          [57.034864, 35.564138],
          [57.012641, 35.592196],
          [57.012085, 35.592476],
          [57.012085, 35.592755],
          [57.000111, 35.603583],
          [56.991252, 35.606919],
          [56.989305, 35.608028],
          [56.977915, 35.612194],
          [56.976528, 35.612194],
          [56.969862, 35.614691],
          [56.965139, 35.619136],
          [56.957359, 35.633029],
          [56.956805, 35.633579],
          [56.920693, 35.684697],
          [56.920413, 35.685806],
          [56.917913, 35.689701],
          [56.917636, 35.68998],
          [56.915413, 35.692757],
          [56.904023, 35.698031],
          [56.90319, 35.69859],
          [56.902356, 35.69859],
          [56.879023, 35.716647],
          [56.869857, 35.72303],
          [56.86569, 35.725256],
          [56.864577, 35.726086],
          [56.829854, 35.758313],
          [56.812077, 35.773864],
          [56.811521, 35.774152],
          [56.810131, 35.775531],
          [56.790408, 35.790261],
          [56.760408, 35.81331],
          [56.760128, 35.81359],
          [56.729015, 35.843318],
          [56.723462, 35.853035],
          [56.721795, 35.859984],
          [56.720962, 35.86998],
          [56.720962, 35.870819],
          [56.721239, 35.871089],
          [56.720682, 35.8772],
          [56.720962, 35.877759],
          [56.721239, 35.879985],
          [56.721515, 35.881373],
          [56.721795, 35.884429],
          [56.720682, 35.899427],
          [56.720962, 35.899986],
          [56.720405, 35.904979],
          [56.720129, 35.905259],
          [56.720129, 35.910811],
          [56.719849, 35.911099],
          [56.718739, 35.912487],
          [56.717629, 35.921095],
          [56.717629, 35.921374],
          [56.717629, 35.926647],
          [56.715682, 35.946647],
          [56.715962, 35.947485],
          [56.714292, 35.963591],
          [56.714572, 35.964429],
          [56.714849, 35.971648],
          [56.715405, 35.975821],
          [56.715962, 35.981373],
          [56.715406, 35.988601],
          [56.715682, 35.98915],
          [56.718739, 35.999434],
          [56.719015, 36.000542],
          [56.719295, 36.001651],
          [56.719572, 36.004706],
          [56.720405, 36.012483],
          [56.720405, 36.016647],
          [56.720129, 36.018873],
          [56.720405, 36.019702],
          [56.720129, 36.02082],
          [56.720406, 36.021369],
          [56.721516, 36.032211],
          [56.721796, 36.033869],
          [56.722072, 36.038321],
          [56.724572, 36.055822],
          [56.725129, 36.057201],
          [56.725405, 36.058598],
          [56.725682, 36.060265],
          [56.731239, 36.089985],
          [56.732072, 36.091652],
          [56.733739, 36.095545],
          [56.740405, 36.112207],
          [56.744572, 36.119993],
          [56.744572, 36.120542],
          [56.745129, 36.121101],
          [56.749295, 36.133879],
          [56.749572, 36.134149],
          [56.749937, 36.135245],
          [56.754575, 36.151099],
          [56.754852, 36.151657],
          [56.754989, 36.152207],
          [56.760685, 36.169156],
          [56.766795, 36.181374],
          [56.800132, 36.23749],
          [56.800408, 36.238607],
          [56.799855, 36.241382],
          [56.780965, 36.262492],
          [56.777352, 36.264429],
          [56.766519, 36.273322],
          [56.764019, 36.278043],
          [56.764019, 36.278322],
          [56.762352, 36.282773],
          [56.756519, 36.297215],
          [56.747629, 36.304431],
          [56.737906, 36.30944],
          [56.733182, 36.313053],
          [56.732929, 36.313495],
          [56.732072, 36.31499],
          [56.731516, 36.318323],
          [56.731796, 36.318882],
          [56.732072, 36.32444],
          [56.731239, 36.334161],
          [56.727629, 36.341107],
          [56.724573, 36.345269],
          [56.704849, 36.366106],
          [56.69846, 36.38833],
          [56.70124, 36.391942],
          [56.70235, 36.392771],
          [56.703183, 36.39333],
          [56.745963, 36.406941],
          [56.768185, 36.418327],
          [56.800408, 36.445549],
          [56.802355, 36.447494],
          [56.816798, 36.461942],
          [56.856245, 36.508888],
          [56.856801, 36.509996],
          [56.857911, 36.511104],
          [56.860968, 36.516112],
          [56.862358, 36.518327],
          [56.862358, 36.518607],
          [56.864025, 36.520831],
          [56.868191, 36.534441],
          [56.868468, 36.535837],
          [56.868468, 36.536665],
          [56.868468, 36.537494],
          [56.868748, 36.537773],
          [56.866801, 36.550553],
          [56.865411, 36.556669],
          [56.862358, 36.56527],
          [56.861801, 36.566945],
          [56.861525, 36.568053],
          [56.849302, 36.584723],
          [56.842078, 36.589722],
          [56.813189, 36.601943],
          [56.793742, 36.606383],
          [56.792909, 36.606662],
          [56.789299, 36.60722],
          [56.787909, 36.6075],
          [56.786799, 36.6075],
          [56.785966, 36.607779],
          [56.785132, 36.607779],
          [56.783742, 36.608337],
          [56.782909, 36.608337],
          [56.769296, 36.61583],
          [56.766519, 36.625835],
          [56.75402, 36.652501],
          [56.752909, 36.657228],
          [56.75263, 36.659164],
          [56.752629, 36.659444],
          [56.753186, 36.661119],
          [56.764296, 36.674725],
          [56.765963, 36.67722],
          [56.769019, 36.686945],
          [56.768743, 36.691943],
          [56.768743, 36.692501],
          [56.768186, 36.694725],
          [56.758463, 36.703892],
          [56.757076, 36.703892],
          [56.756243, 36.704171],
          [56.753186, 36.704171],
          [56.752073, 36.703892],
          [56.749296, 36.703892],
          [56.744296, 36.703334],
          [56.743186, 36.703334],
          [56.730683, 36.701947],
          [56.71374, 36.698895],
          [56.712907, 36.698615],
          [56.707073, 36.698615],
          [56.70624, 36.698336],
          [56.701793, 36.698336],
          [56.696794, 36.699723],
          [56.695127, 36.700281],
          [56.688737, 36.699444],
          [56.68596, 36.698615],
          [56.685684, 36.698336],
          [56.684294, 36.698336],
          [56.66957, 36.691943],
          [56.668737, 36.691943],
          [56.646237, 36.680561],
          [56.644571, 36.680002],
          [56.635401, 36.675833],
          [56.627624, 36.67306],
          [56.606511, 36.670007],
          [56.606235, 36.669728],
          [56.604291, 36.669728],
          [56.604011, 36.669448],
          [56.602901, 36.669448],
          [56.602068, 36.669169],
          [56.599011, 36.669169],
          [56.597901, 36.66889],
          [56.595121, 36.66889],
          [56.593735, 36.668611],
          [56.585678, 36.668611],
          [56.584845, 36.66889],
          [56.580398, 36.66889],
          [56.579845, 36.669169],
          [56.576511, 36.669169],
          [56.575678, 36.669449],
          [56.572622, 36.669449],
          [56.569288, 36.670007],
          [56.563455, 36.670007],
          [56.562065, 36.669728],
          [56.560122, 36.669728],
          [56.558455, 36.669449],
          [56.555398, 36.669449],
          [56.553732, 36.669169],
          [56.552065, 36.669169],
          [56.545122, 36.668611],
          [56.544009, 36.668332],
          [56.538732, 36.668332],
          [56.537618, 36.668053],
          [56.534842, 36.668053],
          [56.533732, 36.667774],
          [56.533175, 36.667774],
          [56.532065, 36.667503],
          [56.530675, 36.667503],
          [56.529842, 36.667224],
          [56.522895, 36.667224],
          [56.521785, 36.666945],
          [56.519842, 36.666945],
          [56.507062, 36.665],
          [56.504839, 36.665],
          [56.501786, 36.664442],
          [56.499562, 36.664442],
          [56.498729, 36.664721],
          [56.497896, 36.664721],
          [56.497339, 36.665],
          [56.496506, 36.665],
          [56.492896, 36.666666],
          [56.481506, 36.678616],
          [56.468173, 36.688062],
          [56.466783, 36.688332],
          [56.460116, 36.691115],
          [56.458173, 36.691664],
          [56.457059, 36.692222],
          [56.454274, 36.692714],
          [56.447616, 36.693888],
          [56.446503, 36.694167],
          [56.44206, 36.694167],
          [56.44067, 36.693888],
          [56.43956, 36.693888],
          [56.439003, 36.694167],
          [56.437336, 36.694167],
          [56.436226, 36.693888],
          [56.43206, 36.693888],
          [56.42817, 36.693618],
          [56.42706, 36.693339],
          [56.423726, 36.693339],
          [56.422337, 36.69306],
          [56.417893, 36.69306],
          [56.41706, 36.692781],
          [56.415113, 36.692781],
          [56.374277, 36.683893],
          [56.373167, 36.683893],
          [56.3715, 36.683335],
          [56.363167, 36.682227],
          [56.361224, 36.681669],
          [56.359557, 36.682776],
          [56.359001, 36.686387],
          [56.359277, 36.687225],
          [56.360667, 36.68917],
          [56.361224, 36.689449],
          [56.362057, 36.689449],
          [56.363724, 36.691664],
          [56.364001, 36.692502],
          [56.363444, 36.700003],
          [56.363724, 36.701119],
          [56.365111, 36.703334],
          [56.365667, 36.705],
          [56.36789, 36.711952],
          [56.368167, 36.712222],
          [56.372057, 36.72056],
          [56.37011, 36.725278],
          [56.367334, 36.726674],
          [56.367057, 36.727782],
          [56.369557, 36.731672],
          [56.369565, 36.731695],
          [56.369573, 36.731718],
          [56.369574, 36.731722],
          [56.369584, 36.731751],
          [56.369834, 36.7325],
          [56.367891, 36.73639],
          [56.36539, 36.738893],
          [56.364834, 36.741666],
          [56.365111, 36.742225],
          [56.36539, 36.751391],
          [56.365111, 36.753057],
          [56.354834, 36.75945],
          [56.354001, 36.759729],
          [56.352334, 36.762502],
          [56.351221, 36.766671],
          [56.342611, 36.776953],
          [56.340111, 36.778619],
          [56.335664, 36.780006],
          [56.317331, 36.80473],
          [56.312608, 36.808061],
          [56.308164, 36.809169],
          [56.292331, 36.816957],
          [56.291218, 36.817227],
          [56.287332, 36.81973],
          [56.282332, 36.822503],
          [56.277885, 36.82695],
          [56.270942, 36.831947],
          [56.264552, 36.83806],
          [56.254552, 36.850565],
          [56.244552, 36.868067],
          [56.240939, 36.873613],
          [56.231496, 36.894454],
          [56.226216, 36.899171],
          [56.221772, 36.900557],
          [56.221492, 36.900836],
          [56.219549, 36.900836],
          [56.216772, 36.900008],
          [56.212882, 36.897784],
          [56.210939, 36.896119],
          [56.195103, 36.886396],
          [56.189826, 36.883345],
          [56.187603, 36.881949],
          [56.168713, 36.875008],
          [56.16677, 36.875008],
          [56.163156, 36.87445],
          [56.15927, 36.87445],
          [56.14538, 36.871398],
          [56.138433, 36.870291],
          [56.13038, 36.872505],
          [56.129543, 36.873064],
          [56.114823, 36.865006],
          [56.10871, 36.862233],
          [56.076487, 36.848342],
          [56.074821, 36.847504],
          [56.059821, 36.843894],
          [56.058431, 36.843894],
          [56.029541, 36.835567],
          [56.021208, 36.833343],
          [56.020928, 36.833064],
          [56.019541, 36.833064],
          [56.018708, 36.832784],
          [56.018151, 36.833064],
          [56.017595, 36.833064],
          [56.015651, 36.833613],
          [56.014538, 36.83445],
          [56.014538, 36.835008],
          [56.013985, 36.835287],
          [56.013428, 36.836395],
          [56.012871, 36.841121],
          [56.013151, 36.841392],
          [56.013428, 36.844453],
          [56.012595, 36.848342],
          [55.998428, 36.860568],
          [55.998151, 36.861117],
          [55.997315, 36.864448],
          [55.997871, 36.865834],
          [55.999261, 36.868337],
          [56.002871, 36.872226],
          [56.005371, 36.87445],
          [56.004818, 36.879726],
          [55.993148, 36.88889],
          [55.986762, 36.894175],
          [55.984815, 36.898064],
          [55.984262, 36.900008],
          [55.980648, 36.937511],
          [55.980928, 36.938339],
          [55.980372, 36.953066],
          [55.980648, 36.957513],
          [55.980928, 36.958341],
          [55.980981, 36.958709],
          [55.980996, 36.958814],
          [55.981205, 36.960285],
          [55.981482, 36.964453],
          [55.981762, 36.967514],
          [55.982038, 36.970844],
          [55.981482, 36.973059],
          [55.978862, 36.982766],
          [55.979178, 36.996245],
          [55.983195, 37.005754],
          [55.99506, 37.003399],
          [56.000477, 37.003316],
          [56.009099, 37.004493],
          [56.013705, 37.005841],
          [56.014538, 37.006957],
          [56.014818, 37.009172],
          [56.015095, 37.011404],
          [56.015371, 37.013627],
          [56.015651, 37.015283],
          [56.017595, 37.023906],
          [56.018428, 37.026678],
          [56.022318, 37.037236],
          [56.023985, 37.041124],
          [56.030095, 37.048622],
          [56.032041, 37.051682],
          [56.032318, 37.053068],
          [56.031761, 37.062239],
          [56.026761, 37.070565],
          [56.025375, 37.07862],
          [56.026208, 37.080573],
          [56.031761, 37.086126],
          [56.038708, 37.095846],
          [56.040098, 37.100292],
          [56.039541, 37.104179],
          [56.038151, 37.105295],
          [56.034261, 37.107239],
          [56.004818, 37.114736],
          [56.003985, 37.114736],
          [56.003428, 37.115015],
          [56.001762, 37.115015],
          [55.994538, 37.118624],
          [55.994262, 37.119182],
          [56.003151, 37.125572],
          [56.008985, 37.128901],
          [56.021485, 37.135849],
          [56.036765, 37.141959],
          [56.059264, 37.153352],
          [56.062321, 37.156682],
          [56.062874, 37.157789],
          [56.062874, 37.160849],
          [56.059541, 37.16668],
          [56.058154, 37.169461],
          [56.012318, 37.229184],
          [56.010652, 37.231406],
          [56.009262, 37.23335],
          [56.008705, 37.234457],
          [55.975649, 37.216685],
          [55.955092, 37.205851],
          [55.953149, 37.205293],
          [55.951482, 37.204457],
          [55.938426, 37.201127],
          [55.937592, 37.200848],
          [55.937036, 37.200848],
          [55.935093, 37.200569],
          [55.934536, 37.200569],
          [55.928703, 37.200569],
          [55.923703, 37.200569],
          [55.922869, 37.200848],
          [55.920646, 37.200848],
          [55.908703, 37.205572],
          [55.908146, 37.210018],
          [55.909813, 37.217234],
          [55.91009, 37.218629],
          [55.910369, 37.220573],
          [55.910646, 37.224181],
          [55.910923, 37.226961],
          [55.911203, 37.228626],
          [55.911479, 37.231128],
          [55.911756, 37.235573],
          [55.912036, 37.243626],
          [55.912036, 37.250014],
          [55.912036, 37.253631],
          [55.912313, 37.25391],
          [55.91398, 37.267236],
          [55.914256, 37.27196],
          [55.914813, 37.278906],
          [55.915089, 37.281965],
          [55.91537, 37.285015],
          [55.914256, 37.301408],
          [55.91398, 37.304737],
          [55.910923, 37.322524],
          [55.908423, 37.331962],
          [55.908423, 37.332341],
          [55.900366, 37.331134],
          [55.889813, 37.329739],
          [55.88759, 37.329739],
          [55.884533, 37.329191],
          [55.88259, 37.329191],
          [55.854253, 37.326132],
          [55.851477, 37.326132],
          [55.845364, 37.325574],
          [55.841753, 37.325574],
          [55.829254, 37.323909],
          [55.827864, 37.323352],
          [55.825364, 37.323073],
          [55.822307, 37.322245],
          [55.808626, 37.317863],
          [55.788974, 37.308075],
          [55.757861, 37.292519],
          [55.751347, 37.288552],
          [55.745081, 37.284736],
          [55.720081, 37.271132],
          [55.716472, 37.266408],
          [55.715358, 37.262242],
          [55.715081, 37.257797],
          [55.713135, 37.241682],
          [55.712858, 37.240018],
          [55.712582, 37.237795],
          [55.712302, 37.236958],
          [55.712582, 37.231128],
          [55.712302, 37.224181],
          [55.712025, 37.220573],
          [55.710915, 37.213068],
          [55.710915, 37.211682],
          [55.710915, 37.210846],
          [55.710635, 37.207795],
          [55.705915, 37.191958],
          [55.700635, 37.183067],
          [55.686745, 37.163909],
          [55.677025, 37.155288],
          [55.676745, 37.15446],
          [55.675635, 37.153623],
          [55.660355, 37.140844],
          [55.654799, 37.136128],
          [55.651189, 37.133068],
          [55.641665, 37.123418],
          [55.630632, 37.112234],
          [55.630356, 37.111955],
          [55.621466, 37.099176],
          [55.607019, 37.0689],
          [55.604519, 37.055849],
          [55.604187, 37.053068],
          [55.604187, 37.053065],
          [55.603966, 37.051952],
          [55.603686, 37.051124],
          [55.602576, 37.043906],
          [55.602019, 37.038064],
          [55.601743, 37.03279],
          [55.599243, 37.013069],
          [55.598963, 37.009451],
          [55.598686, 37.009172],
          [55.598963, 37.008623],
          [55.598686, 37.008344],
          [55.598686, 37.006399],
          [55.597019, 37.001953],
          [55.596186, 36.998343],
          [55.591463, 36.988901],
          [55.585076, 36.970845],
          [55.579519, 36.957234],
          [55.579239, 36.95556],
          [55.563686, 36.929455],
          [55.562573, 36.928347],
          [55.56035, 36.925287],
          [55.553406, 36.918346],
          [55.547573, 36.913899],
          [55.523683, 36.903898],
          [55.522293, 36.903897],
          [55.522017, 36.903618],
          [55.519518, 36.90251],
          [55.518403, 36.901953],
          [55.514237, 36.900009],
          [55.51285, 36.900009],
          [55.510903, 36.89945],
          [55.50618, 36.89945],
          [55.50007, 36.901116],
          [55.470624, 36.877232],
          [55.416454, 36.827779],
          [55.412844, 36.825285],
          [55.400898, 36.816957],
          [55.373675, 36.810565],
          [55.362285, 36.80862],
          [55.360342, 36.80862],
          [55.359508, 36.808341],
          [55.354228, 36.807783],
          [55.324505, 36.803614],
          [55.322006, 36.803614],
          [55.318396, 36.803065],
          [55.316449, 36.803065],
          [55.298392, 36.800562],
          [55.296726, 36.800004],
          [55.295339, 36.800004],
          [55.294782, 36.799725],
          [55.292559, 36.799725],
          [55.287282, 36.799175],
          [55.286726, 36.799175],
          [55.284506, 36.798617],
          [55.282006, 36.798617],
          [55.281173, 36.798338],
          [55.273116, 36.798338],
          [55.272836, 36.798059],
          [55.268393, 36.795565],
          [55.266169, 36.794728],
          [55.254226, 36.792225],
          [55.231723, 36.789451],
          [55.229223, 36.789451],
          [55.228113, 36.789172],
          [55.22589, 36.789172],
          [55.22339, 36.788893],
          [55.21978, 36.788893],
          [55.21728, 36.788614],
          [55.207557, 36.788614],
          [55.198667, 36.787786],
          [55.1945, 36.787786],
          [55.19061, 36.787228],
          [55.187834, 36.787227],
          [55.18061, 36.786669],
          [55.155331, 36.785562],
          [55.141721, 36.784175],
          [55.138664, 36.784175],
          [55.137274, 36.783896],
          [55.131721, 36.783896],
          [55.131164, 36.783617],
          [55.130331, 36.783617],
          [55.129774, 36.783338],
          [55.128108, 36.783338],
          [55.127831, 36.783059],
          [55.121998, 36.781123],
          [55.116164, 36.781123],
          [55.115331, 36.781123],
          [55.112551, 36.781123],
          [55.111164, 36.781123],
          [55.109775, 36.780844],
          [55.106994, 36.780844],
          [55.095608, 36.778899],
          [55.090885, 36.777503],
          [55.084218, 36.776954],
          [55.080885, 36.776954],
          [55.079218, 36.776675],
          [55.076162, 36.776675],
          [55.072828, 36.776396],
          [55.066715, 36.776396],
          [55.065605, 36.776117],
          [55.061995, 36.776117],
          [55.061162, 36.775838],
          [55.058382, 36.775838],
          [55.057548, 36.775559],
          [55.056162, 36.775559],
          [55.052548, 36.775009],
          [55.050049, 36.775009],
          [55.040605, 36.773335],
          [55.038382, 36.773335],
          [55.037549, 36.773065],
          [55.010045, 36.767221],
          [55.001436, 36.764169],
          [54.987269, 36.754723],
          [54.980603, 36.747232],
          [54.976989, 36.742226],
          [54.958933, 36.718337],
          [54.947823, 36.705838],
          [54.947823, 36.705559],
          [54.947543, 36.70528],
          [54.947266, 36.705001],
          [54.947266, 36.704722],
          [54.923653, 36.676663],
          [54.92282, 36.675006],
          [54.912263, 36.664443],
          [54.865317, 36.629448],
          [54.847817, 36.622225],
          [54.843371, 36.620838],
          [54.788925, 36.602782],
          [54.778091, 36.600278],
          [54.767811, 36.600278],
          [54.766978, 36.600557],
          [54.765592, 36.600557],
          [54.764202, 36.600837],
          [54.761702, 36.600837],
          [54.755035, 36.601944],
          [54.747255, 36.604169],
          [54.725312, 36.615831],
          [54.721979, 36.617506],
          [54.700866, 36.632501],
          [54.699476, 36.631663],
          [54.691142, 36.629448],
          [54.690309, 36.62889],
          [54.689476, 36.628889],
          [54.686419, 36.627782],
          [54.685309, 36.627782],
          [54.675586, 36.625278],
          [54.660309, 36.618884],
          [54.649473, 36.618056],
          [54.646696, 36.616669],
          [54.644753, 36.61611],
          [54.632806, 36.605835],
          [54.631696, 36.604169],
          [54.61614, 36.587778],
          [54.613083, 36.584725],
          [54.57086, 36.544998],
          [54.570261, 36.544761],
          [54.56947, 36.544448],
          [54.569194, 36.544169],
          [54.55697, 36.539999],
          [54.537247, 36.526111],
          [54.517524, 36.517221],
          [54.508911, 36.514726],
          [54.507801, 36.514726],
          [54.507244, 36.514447],
          [54.498634, 36.512501],
          [54.490578, 36.511385],
          [54.487801, 36.511385],
          [54.486411, 36.511105],
          [54.483631, 36.510835],
          [54.479745, 36.510835],
          [54.477245, 36.510556],
          [54.475855, 36.510835],
          [54.467798, 36.510835],
          [54.455022, 36.512222],
          [54.432242, 36.517221],
          [54.427518, 36.518329],
          [54.426962, 36.518329],
          [54.407795, 36.520554],
          [54.391682, 36.520554],
          [54.390572, 36.520833],
          [54.379182, 36.520833],
          [54.378629, 36.520554],
          [54.375849, 36.520554],
          [54.374739, 36.520274],
          [54.373073, 36.520274],
          [54.368982, 36.519124],
          [54.34446, 36.512222],
          [54.34196, 36.511943],
          [54.341403, 36.511664],
          [54.34057, 36.511664],
          [54.340016, 36.511385],
          [54.337793, 36.511385],
          [54.33446, 36.510556],
          [54.332793, 36.510556],
          [54.331126, 36.510277],
          [54.325846, 36.510277],
          [54.325013, 36.509998],
          [54.32307, 36.509998],
          [54.315013, 36.50861],
          [54.31279, 36.50861],
          [54.311123, 36.508331],
          [54.30918, 36.508331],
          [54.301957, 36.507494],
          [54.299457, 36.507494],
          [54.2914, 36.506665],
          [54.276677, 36.506665],
          [54.274734, 36.506386],
          [54.259734, 36.506386],
          [54.248901, 36.505548],
          [54.234454, 36.504999],
          [54.222507, 36.504999],
          [54.199174, 36.502774],
          [54.165838, 36.500558],
          [54.160562, 36.5],
          [54.158618, 36.5],
          [54.158062, 36.499721],
          [54.155285, 36.499721],
          [54.130005, 36.492218],
          [54.128895, 36.492218],
          [54.128339, 36.491938],
          [54.127505, 36.491938],
          [54.127225, 36.491659],
          [54.126115, 36.491659],
          [54.125559, 36.49111],
          [54.104169, 36.484165],
          [54.093059, 36.478887],
          [54.073059, 36.471942],
          [54.054169, 36.46361],
          [54.03, 36.451387],
          [54.029443, 36.451108],
          [54.027634, 36.450413],
          [54.026943, 36.450279],
          [54.02639, 36.45],
          [54.020833, 36.448334],
          [54.0125, 36.44555],
          [53.999934, 36.444787],
          [53.986968, 36.44633],
          [53.981664, 36.446667],
          [53.97833, 36.446667],
          [53.977774, 36.446946],
          [53.974164, 36.446946],
          [53.97333, 36.447217],
          [53.97222, 36.446946],
          [53.97083, 36.446946],
          [53.960554, 36.444721],
          [53.939161, 36.437497],
          [53.931661, 36.432498],
          [53.913328, 36.416662],
          [53.910551, 36.413887],
          [53.881381, 36.377774],
          [53.881105, 36.376656],
          [53.879715, 36.37027],
          [53.879991, 36.36999],
          [53.879991, 36.369441],
          [53.879991, 36.367216],
          [53.881658, 36.364162],
          [53.884438, 36.361387],
          [53.885271, 36.361108],
          [53.887495, 36.359441],
          [53.889715, 36.357766],
          [53.918051, 36.343883],
          [53.940274, 36.327496],
          [53.949997, 36.318604],
          [53.950274, 36.317775],
          [53.950831, 36.317217],
          [53.951107, 36.3161],
          [53.953884, 36.309991],
          [53.953884, 36.309721],
          [53.953884, 36.305829],
          [53.949717, 36.293604],
          [53.949164, 36.293324],
          [53.929717, 36.259989],
          [53.928327, 36.258602],
          [53.927774, 36.257493],
          [53.908051, 36.236663],
          [53.887495, 36.219994],
          [53.880825, 36.215273],
          [53.878881, 36.214435],
          [53.876381, 36.212768],
          [53.867215, 36.208596],
          [53.866381, 36.208047],
          [53.864438, 36.207218],
          [53.827769, 36.193603],
          [53.789989, 36.176654],
          [53.782316, 36.172108],
          [53.781099, 36.171374],
          [53.755266, 36.152767],
          [53.746096, 36.144153],
          [53.74193, 36.140269],
          [53.741653, 36.13971],
          [53.717487, 36.119156],
          [53.714987, 36.118048],
          [53.713873, 36.117489],
          [53.711373, 36.116381],
          [53.67915, 36.098322],
          [53.659427, 36.092492],
          [53.658317, 36.092492],
          [53.656371, 36.091933],
          [53.655261, 36.091933],
          [53.648594, 36.091095],
          [53.645814, 36.090545],
          [53.635538, 36.088599],
          [53.623871, 36.087211],
          [53.621091, 36.087211],
          [53.619424, 36.086932],
          [53.616648, 36.086932],
          [53.614701, 36.086652],
          [53.609981, 36.086652],
          [53.608868, 36.086382],
          [53.595811, 36.086382],
          [53.594145, 36.086652],
          [53.584425, 36.086652],
          [53.583592, 36.086932],
          [53.579978, 36.086932],
          [53.578588, 36.087211],
          [53.574978, 36.087211],
          [53.573588, 36.08749],
          [53.569145, 36.08749],
          [53.568035, 36.087761],
          [53.567202, 36.08749],
          [53.562755, 36.08749],
          [53.511366, 36.078875],
          [53.509142, 36.078875],
          [53.508309, 36.078596],
          [53.503586, 36.078046],
          [53.494976, 36.076929],
          [53.489696, 36.076658],
          [53.484139, 36.076658],
          [53.482473, 36.07637],
          [53.474696, 36.07582],
          [53.467196, 36.074703],
          [53.452473, 36.073604],
          [53.44636, 36.073604],
          [53.444973, 36.073324],
          [53.44414, 36.073604],
          [53.43914, 36.073603],
          [53.43775, 36.073324],
          [53.43164, 36.073324],
          [53.40108, 36.068881],
          [53.390524, 36.066376],
          [53.385524, 36.064988],
          [53.370524, 36.060266],
          [53.368857, 36.059428],
          [53.361081, 36.055823],
          [53.359414, 36.055265],
          [53.348129, 36.049598],
          [53.347467, 36.049155],
          [53.346077, 36.048596],
          [53.344967, 36.047767],
          [53.322188, 36.03387],
          [53.302151, 36.018845],
          [53.295521, 36.013873],
          [53.290242, 36.008321],
          [53.289131, 36.006654],
          [53.288855, 36.006095],
          [53.284408, 36.000228],
          [53.263575, 35.993315],
          [53.224686, 35.968044],
          [53.224686, 35.967486],
          [53.223296, 35.966098],
          [53.223375, 35.965539],
          [53.223572, 35.964151],
          [53.224686, 35.959149],
          [53.224962, 35.95804],
          [53.228019, 35.949983],
          [53.228296, 35.949433],
          [53.230519, 35.941367],
          [53.228296, 35.93248],
          [53.223296, 35.928316],
          [53.220516, 35.926928],
          [53.217462, 35.926099],
          [53.216349, 35.926098],
          [53.215796, 35.925819],
          [53.214682, 35.925819],
          [53.212739, 35.92526],
          [53.186906, 35.921655],
          [53.174403, 35.920267],
          [53.16857, 35.920267],
          [53.16746, 35.919979],
          [53.163013, 35.920537],
          [53.159403, 35.920537],
          [53.15718, 35.920817],
          [53.153847, 35.920817],
          [53.150513, 35.921096],
          [53.14857, 35.920817],
          [53.14607, 35.920817],
          [53.13857, 35.919708],
          [53.134957, 35.919708],
          [53.134123, 35.919708],
          [53.133013, 35.919708],
          [53.132457, 35.919429],
          [53.12857, 35.919429],
          [53.127457, 35.919708],
          [53.120513, 35.919708],
          [53.119123, 35.919429],
          [53.116624, 35.919429],
          [53.114957, 35.919149],
          [53.112457, 35.919149],
          [53.108567, 35.91887],
          [53.107457, 35.919149],
          [53.104124, 35.91887],
          [53.10329, 35.919149],
          [53.1019, 35.919149],
          [53.101557, 35.919092],
          [53.100234, 35.91887],
          [53.097457, 35.91887],
          [53.093844, 35.91832],
          [53.090511, 35.91832],
          [53.087454, 35.917761],
          [53.078011, 35.917761],
          [53.076344, 35.917482],
          [53.074401, 35.917482],
          [53.064954, 35.914985],
          [53.045231, 35.902205],
          [53.039955, 35.897202],
          [53.039398, 35.896652],
          [53.030508, 35.889153],
          [53.024675, 35.889153],
          [53.020785, 35.884151],
          [53.022451, 35.87804],
          [53.022731, 35.877201],
          [53.022451, 35.869982],
          [53.021341, 35.866376],
          [53.015785, 35.8572],
          [53.015231, 35.854983],
          [53.017451, 35.853595],
          [53.018008, 35.853036],
          [53.020231, 35.851648],
          [53.021898, 35.84998],
          [53.022175, 35.849151],
          [53.022175, 35.848313],
          [53.022175, 35.848033],
          [53.021618, 35.844977],
          [53.020508, 35.83554],
          [53.031898, 35.826652],
          [53.033008, 35.826373],
          [53.041064, 35.822199],
          [53.047174, 35.819982],
          [53.048564, 35.819982],
          [53.049954, 35.819702],
          [53.056621, 35.819144],
          [53.058011, 35.819432],
          [53.058564, 35.819144],
          [53.060231, 35.819432],
          [53.065231, 35.819432],
          [53.065788, 35.819144],
          [53.076621, 35.818314],
          [53.080787, 35.818314],
          [53.081621, 35.818035],
          [53.083011, 35.818314],
          [53.084677, 35.818594],
          [53.085511, 35.818594],
          [53.089121, 35.819144],
          [53.091344, 35.819144],
          [53.093844, 35.819702],
          [53.097177, 35.819702],
          [53.09801, 35.819982],
          [53.105234, 35.819982],
          [53.107457, 35.820252],
          [53.118014, 35.820252],
          [53.119957, 35.820541],
          [53.12857, 35.820541],
          [53.139403, 35.818035],
          [53.139957, 35.818314],
          [53.14107, 35.817755],
          [53.153293, 35.811364],
          [53.15607, 35.807759],
          [53.156626, 35.805532],
          [53.156346, 35.805262],
          [53.156903, 35.803865],
          [53.15607, 35.796094],
          [53.15468, 35.794147],
          [53.152737, 35.79193],
          [53.148293, 35.789424],
          [53.146903, 35.788874],
          [53.13329, 35.7847],
          [53.109957, 35.772756],
          [53.096901, 35.755538],
          [53.094401, 35.749985],
          [53.093844, 35.749697],
          [53.09329, 35.748867],
          [53.088011, 35.744982],
          [53.065511, 35.734976],
          [53.064954, 35.734705],
          [53.062666, 35.733332],
          [53.051064, 35.726366],
          [53.050231, 35.726366],
          [53.049121, 35.725528],
          [53.047174, 35.724978],
          [53.046341, 35.724419],
          [53.044674, 35.724149],
          [53.043841, 35.72359],
          [53.039756, 35.722039],
          [53.035788, 35.720534],
          [53.034121, 35.719425],
          [53.020508, 35.710816],
          [53.012451, 35.700809],
          [53.001895, 35.68331],
          [53.001062, 35.68276],
          [52.999675, 35.681363],
          [52.995785, 35.678866],
          [52.995228, 35.678586],
          [52.984672, 35.672753],
          [52.975229, 35.669976],
          [52.974395, 35.669427],
          [52.929669, 35.655534],
          [52.929116, 35.654975],
          [52.926336, 35.654145],
          [52.914393, 35.649701],
          [52.899113, 35.64192],
          [52.88578, 35.632201],
          [52.88467, 35.631372],
          [52.877166, 35.626089],
          [52.829944, 35.58109],
          [52.82911, 35.579701],
          [52.82272, 35.572479],
          [52.802441, 35.550804],
          [52.802164, 35.550533],
          [52.801054, 35.549415],
          [52.794388, 35.545945],
          [52.793274, 35.545529],
          [52.792997, 35.54525],
          [52.782164, 35.541084],
          [52.778831, 35.541084],
          [52.776884, 35.541643],
          [52.766884, 35.546918],
          [52.766051, 35.546918],
          [52.734385, 35.55331],
          [52.727715, 35.555528],
          [52.726605, 35.555528],
          [52.726048, 35.555249],
          [52.725495, 35.555528],
          [52.716328, 35.555528],
          [52.715215, 35.555249],
          [52.713272, 35.555249],
          [52.712158, 35.554969],
          [52.708548, 35.554419],
          [52.707715, 35.554419],
          [52.697715, 35.551922],
          [52.672712, 35.539136],
          [52.669659, 35.536918],
          [52.667436, 35.535809],
          [52.667159, 35.53525],
          [52.659936, 35.529416],
          [52.659659, 35.528587],
          [52.659102, 35.528307],
          [52.637713, 35.508308],
          [52.634656, 35.503863],
          [52.633823, 35.502745],
          [52.61521, 35.472475],
          [52.6116, 35.46747],
          [52.608266, 35.464143],
          [52.607156, 35.462466],
          [52.606323, 35.461916],
          [52.562987, 35.417469],
          [52.555207, 35.411914],
          [52.55132, 35.409966],
          [52.550207, 35.409137],
          [52.536317, 35.401634],
          [52.534931, 35.401354],
          [52.534097, 35.400804],
          [52.532707, 35.400525],
          [52.531874, 35.399966],
          [52.531041, 35.399966],
          [52.530207, 35.399416],
          [52.524675, 35.397823],
          [52.523261, 35.397468],
          [52.522707, 35.397188],
          [52.521041, 35.396918],
          [52.518817, 35.396079],
          [52.517151, 35.3958],
          [52.499651, 35.386917],
          [52.495761, 35.386358],
          [52.494928, 35.386078],
          [52.493538, 35.386078],
          [52.421869, 35.366077],
          [52.410479, 35.358583],
          [52.408536, 35.356914],
          [52.382979, 35.338021],
          [52.380199, 35.337751],
          [52.380199, 35.337462],
          [52.379089, 35.337462],
          [52.378533, 35.337751],
          [52.368809, 35.341078],
          [52.360199, 35.347463],
          [52.3452, 35.361081],
          [52.3402, 35.366076],
          [52.333199, 35.374924],
          [52.332696, 35.375527],
          [52.324363, 35.386078],
          [52.281027, 35.425527],
          [52.28075, 35.425801],
          [52.27692, 35.428248],
          [52.257694, 35.440526],
          [52.256861, 35.440527],
          [52.254084, 35.441636],
          [52.252137, 35.442195],
          [52.251584, 35.442195],
          [52.237414, 35.445531],
          [52.214358, 35.448858],
          [52.212138, 35.448858],
          [52.206025, 35.449697],
          [52.202968, 35.449697],
          [52.202134, 35.449968],
          [52.196858, 35.450527],
          [52.149635, 35.460247],
          [52.129632, 35.465811],
          [52.125465, 35.46747],
          [52.123242, 35.468029],
          [52.106299, 35.475252],
          [52.098799, 35.478029],
          [52.081019, 35.483304],
          [52.079909, 35.483304],
          [52.037683, 35.492474],
          [52.035183, 35.493583],
          [52.025183, 35.501644],
          [52.019073, 35.516089],
          [52.01796, 35.520805],
          [52.01796, 35.522194],
          [52.017127, 35.529975],
          [52.01407, 35.539974],
          [52.009627, 35.548865],
          [51.993513, 35.568583],
          [51.985737, 35.575526],
          [51.98046, 35.577194],
          [51.979347, 35.577194],
          [51.972957, 35.578583],
          [51.970457, 35.578583],
          [51.969624, 35.578583],
          [51.969347, 35.578312],
          [51.96629, 35.578312],
          [51.965737, 35.578583],
          [51.962957, 35.578583],
          [51.960457, 35.578583],
          [51.957957, 35.578024],
          [51.936568, 35.561641],
          [51.935734, 35.561641],
          [51.929624, 35.558584],
          [51.924344, 35.548585],
          [51.923511, 35.545808],
          [51.922958, 35.537477],
          [51.922958, 35.535529],
          [51.921011, 35.509967],
          [51.921291, 35.508578],
          [51.921011, 35.508028],
          [51.921291, 35.505531],
          [51.921568, 35.503583],
          [51.922401, 35.497749],
          [51.922678, 35.49636],
          [51.922958, 35.49581],
          [51.922958, 35.495522],
          [51.923234, 35.493583],
          [51.923511, 35.493304],
          [51.923791, 35.492474],
          [51.925458, 35.487749],
          [51.925734, 35.481086],
          [51.926011, 35.479968],
          [51.925734, 35.479418],
          [51.925734, 35.476361],
          [51.925458, 35.470806],
          [51.925178, 35.470247],
          [51.919621, 35.448029],
          [51.919068, 35.447199],
          [51.917678, 35.441356],
          [51.913511, 35.42553],
          [51.913511, 35.424142],
          [51.912955, 35.422473],
          [51.912401, 35.414412],
          [51.912678, 35.413302],
          [51.916288, 35.409686],
          [51.917678, 35.409136],
          [51.918788, 35.408027],
          [51.920178, 35.406359],
          [51.920735, 35.4058],
          [51.922125, 35.401913],
          [51.923235, 35.398026],
          [51.923511, 35.396079],
          [51.923791, 35.39414],
          [51.924068, 35.391912],
          [51.924344, 35.390244],
          [51.924625, 35.387476],
          [51.924901, 35.386357],
          [51.925178, 35.38386],
          [51.925458, 35.382471],
          [51.938806, 35.37558],
          [51.940634, 35.365819],
          [51.943929, 35.361302],
          [51.93429, 35.346831],
          [51.925684, 35.346865],
          [51.917939, 35.348221],
          [51.916568, 35.345524],
          [51.916845, 35.345244],
          [51.916568, 35.344685],
          [51.916845, 35.344135],
          [51.916568, 35.339689],
          [51.906288, 35.320805],
          [51.904345, 35.318027],
          [51.902402, 35.31525],
          [51.897678, 35.309415],
          [51.884601, 35.295321],
          [51.884065, 35.294967],
          [51.872399, 35.281078],
          [51.872675, 35.27969],
          [51.872955, 35.278301],
          [51.873509, 35.276073],
          [51.873789, 35.275523],
          [51.873852, 35.274435],
          [51.874065, 35.273575],
          [51.874342, 35.273295],
          [51.881842, 35.254969],
          [51.883788, 35.252462],
          [51.884065, 35.251912],
          [51.900178, 35.234135],
          [51.937678, 35.181355],
          [51.938235, 35.179686],
          [51.946568, 35.164687],
          [51.947958, 35.16135],
          [51.951291, 35.155523],
          [51.951568, 35.154964],
          [51.952681, 35.153855],
          [51.954901, 35.150797],
          [51.968514, 35.139964],
          [51.969348, 35.139405],
          [52.007404, 35.11663],
          [52.03463, 35.090517],
          [52.03574, 35.088848],
          [52.03685, 35.08635],
          [52.03713, 35.08607],
          [52.042683, 35.072739],
          [52.042963, 35.072188],
          [52.043517, 35.068571],
          [52.043797, 35.064133],
          [52.044074, 35.055798],
          [52.04435, 35.051622],
          [52.04463, 35.049683],
          [52.040464, 35.015242],
          [52.03685, 35.003019],
          [52.03574, 34.999961],
          [52.021574, 34.967739],
          [52.021294, 34.967179],
          [52.001571, 34.918565],
          [51.998794, 34.914956],
          [51.998518, 34.914686],
          [51.985181, 34.896345],
          [51.978515, 34.88385],
          [51.977405, 34.882452],
          [51.976848, 34.881072],
          [51.969905, 34.871897],
          [51.969624, 34.871617],
          [51.965458, 34.865789],
          [51.964905, 34.865239],
          [51.959071, 34.858012],
          [51.950458, 34.834393],
          [51.948792, 34.825787],
          [51.940182, 34.795509],
          [51.939068, 34.784673],
          [51.937958, 34.776896],
          [51.937678, 34.776346],
          [51.934357, 34.759338],
          [51.932958, 34.752175],
          [51.927959, 34.733562],
          [51.917679, 34.704951],
          [51.915736, 34.701062],
          [51.914345, 34.696903],
          [51.913512, 34.695513],
          [51.888513, 34.635511],
          [51.884899, 34.629123],
          [51.861982, 34.583311],
          [51.855726, 34.573696],
          [51.850377, 34.567817],
          [51.840382, 34.568061],
          [51.84121, 34.554844],
          [51.840755, 34.532923],
          [51.839545, 34.523774],
          [51.838787, 34.523007],
          [51.83712, 34.516338],
          [51.83601, 34.51217],
          [51.835177, 34.5105],
          [51.834897, 34.50995],
          [51.833787, 34.50828],
          [51.83351, 34.50717],
        ],
      ],
    },
  },
  SistanAndBaluchestan: {
    properties: {
      value: "SistanAndBaluchestan",
      name: "استان سیستان و بلوچستان, ایران",
      id: 15,
    },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [58.831036, 27.253674],
          [58.833005, 27.183222],
          [58.833536, 27.164223],
          [58.834649, 27.157281],
          [58.834929, 27.154503],
          [58.835203, 27.150889],
          [58.838262, 27.13515],
          [58.838536, 27.134498],
          [58.838536, 27.134229],
          [58.838536, 27.13395],
          [58.848542, 27.101728],
          [58.850482, 27.096729],
          [58.855209, 27.08728],
          [58.859376, 27.074227],
          [58.859929, 27.071449],
          [58.870208, 27.048669],
          [58.871315, 27.047276],
          [58.874648, 27.040893],
          [58.875762, 27.0395],
          [58.876875, 27.037279],
          [58.889375, 27.020335],
          [58.890762, 27.018941],
          [58.904928, 27.007274],
          [58.908541, 27.005332],
          [58.912988, 26.99993],
          [58.911874, 26.988667],
          [58.898821, 26.969782],
          [58.897988, 26.969224],
          [58.876595, 26.933946],
          [58.864375, 26.908664],
          [58.850208, 26.861993],
          [58.849375, 26.857554],
          [58.850208, 26.846725],
          [58.850481, 26.836166],
          [58.850208, 26.833111],
          [58.850762, 26.831161],
          [58.851042, 26.830334],
          [58.853542, 26.824771],
          [58.860208, 26.820611],
          [58.861315, 26.819496],
          [58.862981, 26.81867],
          [58.886321, 26.800051],
          [58.899374, 26.791164],
          [58.905761, 26.78783],
          [58.956046, 26.767271],
          [58.957153, 26.766992],
          [58.958547, 26.766166],
          [58.971879, 26.761997],
          [58.972175, 26.761997],
          [58.973266, 26.761997],
          [58.973464, 26.7618],
          [58.973546, 26.761718],
          [58.974273, 26.761718],
          [58.975486, 26.761718],
          [58.975882, 26.761521],
          [58.976046, 26.761439],
          [58.976575, 26.761439],
          [58.97688, 26.761439],
          [58.97727, 26.761243],
          [58.977433, 26.761161],
          [58.977967, 26.761161],
          [58.9791, 26.761161],
          [58.979297, 26.760964],
          [58.97938, 26.760882],
          [58.980106, 26.760882],
          [58.98188, 26.760882],
          [58.982072, 26.760686],
          [58.982153, 26.760604],
          [58.982884, 26.760604],
          [58.984099, 26.760604],
          [58.98449, 26.760407],
          [58.984653, 26.760325],
          [58.985186, 26.760325],
          [58.986599, 26.760325],
          [58.986599, 26.760128],
          [58.986599, 26.760047],
          [58.987153, 26.760047],
          [58.987235, 26.760128],
          [58.987432, 26.760325],
          [58.988183, 26.759956],
          [58.988546, 26.759777],
          [58.989071, 26.759777],
          [58.989379, 26.759777],
          [58.989968, 26.75958],
          [58.990213, 26.759499],
          [58.990493, 26.75922],
          [58.990493, 26.758663],
          [58.990493, 26.758384],
          [58.992432, 26.751439],
          [58.992993, 26.750612],
          [58.993546, 26.748941],
          [58.996326, 26.734215],
          [58.996599, 26.730324],
          [58.996599, 26.723109],
          [58.996046, 26.714772],
          [58.995766, 26.714224],
          [58.996046, 26.711438],
          [58.997992, 26.68533],
          [59.002992, 26.674774],
          [59.016045, 26.662547],
          [59.017432, 26.659771],
          [59.017712, 26.656159],
          [59.017432, 26.651443],
          [59.014932, 26.636719],
          [59.011879, 26.630601],
          [59.002432, 26.618654],
          [59.001046, 26.615878],
          [58.999099, 26.604209],
          [58.999659, 26.596439],
          [59.000492, 26.59422],
          [59.002992, 26.586989],
          [59.004379, 26.585327],
          [59.026325, 26.568099],
          [59.028545, 26.566438],
          [59.034938, 26.561434],
          [59.034938, 26.561156],
          [59.035211, 26.560877],
          [59.037438, 26.550602],
          [59.037438, 26.547548],
          [59.035491, 26.543659],
          [59.033271, 26.541162],
          [59.032158, 26.540596],
          [59.031878, 26.540048],
          [59.027712, 26.538108],
          [59.013545, 26.534219],
          [59.006045, 26.528103],
          [59.006325, 26.526432],
          [59.009098, 26.523378],
          [59.009378, 26.520603],
          [59.009098, 26.518932],
          [59.008545, 26.517828],
          [59.008265, 26.517549],
          [59.008265, 26.517271],
          [59.007712, 26.516992],
          [59.007158, 26.515322],
          [59.007432, 26.515043],
          [59.007712, 26.513382],
          [59.007712, 26.510598],
          [59.002159, 26.501437],
          [59.000355, 26.500323],
          [58.999659, 26.500323],
          [58.999098, 26.500045],
          [58.997992, 26.500045],
          [58.995519, 26.499494],
          [58.995492, 26.499488],
          [58.987748, 26.50124],
          [58.986879, 26.501437],
          [58.984932, 26.501437],
          [58.983546, 26.501715],
          [58.981635, 26.501715],
          [58.981599, 26.501715],
          [58.971599, 26.503934],
          [58.96931, 26.504851],
          [58.968819, 26.505047],
          [58.968399, 26.505047],
          [58.967986, 26.505047],
          [58.964379, 26.506439],
          [58.957986, 26.508101],
          [58.956046, 26.508936],
          [58.941133, 26.513464],
          [58.940486, 26.51366],
          [58.939237, 26.51366],
          [58.939099, 26.51366],
          [58.937575, 26.514042],
          [58.936873, 26.514217],
          [58.93104, 26.514765],
          [58.928519, 26.514765],
          [58.927986, 26.514765],
          [58.927823, 26.514847],
          [58.927433, 26.515044],
          [58.925766, 26.515044],
          [58.924754, 26.514841],
          [58.924373, 26.514765],
          [58.92421, 26.514847],
          [58.92382, 26.515044],
          [58.922433, 26.515044],
          [58.920767, 26.514765],
          [58.904927, 26.512825],
          [58.897987, 26.508658],
          [58.890207, 26.501158],
          [58.879374, 26.494486],
          [58.878261, 26.494216],
          [58.857427, 26.485046],
          [58.855761, 26.485046],
          [58.855208, 26.484768],
          [58.853261, 26.484211],
          [58.852148, 26.484211],
          [58.849928, 26.483654],
          [58.844374, 26.483107],
          [58.843814, 26.483107],
          [58.843821, 26.483093],
          [58.844094, 26.482541],
          [58.855761, 26.460601],
          [58.861874, 26.45171],
          [58.88326, 26.427544],
          [58.884374, 26.423656],
          [58.884654, 26.421429],
          [58.884927, 26.40866],
          [58.88326, 26.397543],
          [58.880207, 26.388931],
          [58.877707, 26.382816],
          [58.87604, 26.374214],
          [58.87604, 26.373658],
          [58.87632, 26.368378],
          [58.879654, 26.353383],
          [58.885996, 26.346851],
          [58.889373, 26.343372],
          [58.891873, 26.341989],
          [58.897153, 26.338658],
          [58.90076, 26.337266],
          [58.90354, 26.337266],
          [58.904093, 26.336988],
          [58.904926, 26.336988],
          [58.905206, 26.33671],
          [58.90632, 26.33671],
          [58.907153, 26.336431],
          [58.908539, 26.336431],
          [58.91132, 26.335875],
          [58.915766, 26.335875],
          [58.916319, 26.335596],
          [58.926039, 26.335039],
          [58.92632, 26.335327],
          [58.928266, 26.335596],
          [58.931872, 26.335596],
          [58.933266, 26.335875],
          [58.934933, 26.335874],
          [58.937152, 26.336153],
          [58.940206, 26.336709],
          [58.943266, 26.335596],
          [58.949652, 26.326429],
          [58.959932, 26.301703],
          [58.963545, 26.297816],
          [58.971045, 26.292546],
          [58.976045, 26.28671],
          [58.979652, 26.278926],
          [58.981598, 26.261708],
          [58.981878, 26.248376],
          [58.981318, 26.242541],
          [58.981045, 26.24115],
          [58.981045, 26.240315],
          [58.980765, 26.233376],
          [58.982985, 26.217263],
          [58.990765, 26.202263],
          [59.016757, 26.180058],
          [59.017431, 26.179481],
          [59.017711, 26.179212],
          [59.017991, 26.178377],
          [59.021044, 26.174482],
          [59.028824, 26.166987],
          [59.028544, 26.164483],
          [59.028271, 26.162535],
          [59.027991, 26.162266],
          [59.027991, 26.160318],
          [59.027437, 26.157257],
          [59.027157, 26.156423],
          [59.020764, 26.14059],
          [59.020491, 26.138651],
          [59.021877, 26.13087],
          [59.02243, 26.130035],
          [59.037157, 26.097815],
          [59.041044, 26.091426],
          [59.041324, 26.090591],
          [59.051876, 26.076986],
          [59.074936, 26.05115],
          [59.077989, 26.045317],
          [59.082716, 26.030313],
          [59.082716, 26.029756],
          [59.082989, 26.0292],
          [59.083269, 26.027809],
          [59.083269, 26.025871],
          [59.08355, 26.025315],
          [59.083549, 26.017535],
          [59.081883, 26.000064],
          [59.080769, 25.996708],
          [59.080489, 25.996152],
          [59.079936, 25.995865],
          [59.074103, 25.992536],
          [59.07299, 25.992536],
          [59.072716, 25.992258],
          [59.061876, 25.992258],
          [59.061323, 25.992536],
          [59.05799, 25.992536],
          [59.052156, 25.990867],
          [59.051323, 25.990311],
          [59.04327, 25.985591],
          [59.042157, 25.985591],
          [59.041603, 25.985313],
          [59.04049, 25.985313],
          [59.04021, 25.985035],
          [59.039103, 25.985035],
          [59.038823, 25.984757],
          [59.034104, 25.983375],
          [59.033544, 25.98281],
          [59.019097, 25.973371],
          [59.01521, 25.969755],
          [59.014657, 25.969198],
          [59.00243, 25.954484],
          [59.00243, 25.953928],
          [58.998264, 25.945315],
          [58.99799, 25.943368],
          [58.997711, 25.941699],
          [58.997711, 25.938092],
          [58.997711, 25.937814],
          [59.001044, 25.931704],
          [59.008544, 25.928367],
          [59.009377, 25.928367],
          [59.01049, 25.927811],
          [59.012157, 25.927541],
          [59.012157, 25.927254],
          [59.013824, 25.926698],
          [59.023263, 25.921145],
          [59.023544, 25.920866],
          [59.029103, 25.915035],
          [59.029377, 25.913644],
          [59.03021, 25.908926],
          [59.032437, 25.907535],
          [59.03521, 25.907535],
          [59.03549, 25.907813],
          [59.036603, 25.907813],
          [59.036603, 25.908091],
          [59.037437, 25.908091],
          [59.039377, 25.908647],
          [59.041323, 25.908647],
          [59.042156, 25.908369],
          [59.042156, 25.908091],
          [59.046603, 25.905866],
          [59.054376, 25.900035],
          [59.055209, 25.900035],
          [59.055489, 25.899757],
          [59.056323, 25.899757],
          [59.056876, 25.899479],
          [59.057989, 25.899479],
          [59.058543, 25.899201],
          [59.059376, 25.899201],
          [59.059656, 25.898922],
          [59.06049, 25.898922],
          [59.06077, 25.899201],
          [59.061876, 25.899201],
          [59.062156, 25.899479],
          [59.063823, 25.899479],
          [59.064376, 25.899201],
          [59.06549, 25.899201],
          [59.067436, 25.898644],
          [59.072156, 25.894482],
          [59.077716, 25.880864],
          [59.077989, 25.880308],
          [59.078269, 25.879195],
          [59.078269, 25.874755],
          [59.077716, 25.871427],
          [59.077436, 25.86948],
          [59.077156, 25.868646],
          [59.077436, 25.867811],
          [59.077989, 25.863371],
          [59.077716, 25.863084],
          [59.077989, 25.862537],
          [59.078549, 25.860312],
          [59.079382, 25.856697],
          [59.080769, 25.854481],
          [59.083549, 25.850866],
          [59.095489, 25.840317],
          [59.095769, 25.84003],
          [59.098822, 25.838649],
          [59.099655, 25.838649],
          [59.101049, 25.838093],
          [59.101882, 25.838093],
          [59.104382, 25.836702],
          [59.105215, 25.836702],
          [59.116329, 25.824198],
          [59.117995, 25.823642],
          [59.119662, 25.823642],
          [59.119935, 25.823364],
          [59.121602, 25.823086],
          [59.123268, 25.823086],
          [59.127995, 25.82087],
          [59.128268, 25.820583],
          [59.129101, 25.820314],
          [59.139935, 25.812815],
          [59.177714, 25.780588],
          [59.182994, 25.773368],
          [59.185494, 25.770588],
          [59.185774, 25.77031],
          [59.186327, 25.770032],
          [59.21022, 25.75448],
          [59.21772, 25.743359],
          [59.218833, 25.740588],
          [59.219107, 25.73892],
          [59.219107, 25.735862],
          [59.219107, 25.735306],
          [59.219387, 25.731422],
          [59.2205, 25.720033],
          [59.2205, 25.714473],
          [59.213826, 25.701981],
          [59.212993, 25.701147],
          [59.211886, 25.697255],
          [59.211327, 25.691417],
          [59.211886, 25.68809],
          [59.21216, 25.687534],
          [59.21244, 25.685866],
          [59.218553, 25.671976],
          [59.223553, 25.663914],
          [59.22494, 25.658086],
          [59.229106, 25.65197],
          [59.235219, 25.639193],
          [59.235499, 25.638637],
          [59.235773, 25.6367],
          [59.2355, 25.634199],
          [59.235219, 25.632253],
          [59.234939, 25.631419],
          [59.234386, 25.629195],
          [59.22022, 25.592255],
          [59.219939, 25.590588],
          [59.219386, 25.589754],
          [59.214106, 25.575311],
          [59.214106, 25.574746],
          [59.213826, 25.574199],
          [59.211606, 25.563638],
          [59.211326, 25.562248],
          [59.211053, 25.560303],
          [59.210773, 25.558079],
          [59.210493, 25.557801],
          [59.210773, 25.554753],
          [59.214106, 25.520858],
          [59.214659, 25.51253],
          [59.214659, 25.504749],
          [59.214659, 25.504193],
          [59.214939, 25.500859],
          [59.214939, 25.499747],
          [59.216053, 25.490308],
          [59.217991, 25.467651],
          [59.219038, 25.458169],
          [59.221111, 25.439389],
          [59.218776, 25.199403],
          [59.388463, 25.197502],
          [59.567738, 25.195493],
          [59.670853, 25.183255],
          [60.170329, 25.123954],
          [60.259461, 25.110488],
          [60.360364, 25.095241],
          [60.388129, 25.086742],
          [60.419398, 25.079439],
          [60.451502, 25.076373],
          [60.523613, 25.074295],
          [60.576576, 25.072768],
          [60.955829, 24.972166],
          [61.116039, 24.929643],
          [61.354676, 24.866277],
          [61.397304, 24.85891],
          [61.549733, 24.84651],
          [61.575548, 25.045388],
          [61.575978, 25.046727],
          [61.617371, 25.175446],
          [61.617836, 25.176862],
          [61.629536, 25.209255],
          [61.63163, 25.220305],
          [61.63333, 25.228116],
          [61.634978, 25.234653],
          [61.637304, 25.243512],
          [61.639286, 25.252416],
          [61.640385, 25.2592],
          [61.640196, 25.268606],
          [61.641218, 25.274582],
          [61.642941, 25.282236],
          [61.644842, 25.289239],
          [61.645988, 25.29218],
          [61.648397, 25.296777],
          [61.649595, 25.301537],
          [61.650582, 25.305813],
          [61.651206, 25.310398],
          [61.651914, 25.314588],
          [61.65244, 25.322081],
          [61.65329, 25.327733],
          [61.653322, 25.330636],
          [61.653674, 25.330588],
          [61.654108, 25.332726],
          [61.650178, 25.333152],
          [61.651455, 25.34183],
          [61.652354, 25.348174],
          [61.653296, 25.3558],
          [61.654107, 25.363017],
          [61.654722, 25.369444],
          [61.655293, 25.376285],
          [61.656194, 25.376216],
          [61.656489, 25.378239],
          [61.655216, 25.378477],
          [61.655556, 25.383333],
          [61.655816, 25.386451],
          [61.655804, 25.393377],
          [61.655886, 25.39838],
          [61.655981, 25.40363],
          [61.656739, 25.407874],
          [61.65828, 25.413811],
          [61.659828, 25.419649],
          [61.660583, 25.422635],
          [61.6609, 25.424998],
          [61.66062, 25.431535],
          [61.660467, 25.438895],
          [61.660573, 25.44331],
          [61.661326, 25.443313],
          [61.661462, 25.444651],
          [61.661334, 25.445445],
          [61.661004, 25.446187],
          [61.661041, 25.447174],
          [61.661071, 25.452428],
          [61.661142, 25.455388],
          [61.661334, 25.461071],
          [61.661555, 25.465294],
          [61.661744, 25.468086],
          [61.662157, 25.473923],
          [61.66284, 25.479779],
          [61.663699, 25.486665],
          [61.66416, 25.489355],
          [61.665436, 25.491449],
          [61.666458, 25.502564],
          [61.66657, 25.50888],
          [61.666827, 25.514206],
          [61.667126, 25.521181],
          [61.667078, 25.527765],
          [61.676523, 25.64521],
          [61.676361, 25.645247],
          [61.676121, 25.645501],
          [61.675831, 25.645623],
          [61.67584, 25.645789],
          [61.675881, 25.645817],
          [61.675812, 25.64588],
          [61.675779, 25.645999],
          [61.675853, 25.646056],
          [61.675848, 25.646271],
          [61.675977, 25.646307],
          [61.675979, 25.646455],
          [61.67589, 25.64647],
          [61.675845, 25.64658],
          [61.675679, 25.646538],
          [61.675751, 25.646641],
          [61.675547, 25.646852],
          [61.675657, 25.646883],
          [61.675643, 25.646931],
          [61.675353, 25.647049],
          [61.67517, 25.647043],
          [61.675117, 25.646969],
          [61.674958, 25.647004],
          [61.674883, 25.647175],
          [61.674565, 25.646977],
          [61.674508, 25.646988],
          [61.674444, 25.647065],
          [61.674493, 25.647131],
          [61.674259, 25.647224],
          [61.67428, 25.647166],
          [61.674227, 25.647081],
          [61.674133, 25.647142],
          [61.674043, 25.647066],
          [61.673928, 25.647126],
          [61.673751, 25.647033],
          [61.673559, 25.647097],
          [61.67343, 25.646996],
          [61.673345, 25.647022],
          [61.673289, 25.646957],
          [61.672792, 25.646891],
          [61.672642, 25.647321],
          [61.672334, 25.647707],
          [61.672325, 25.647835],
          [61.672451, 25.647904],
          [61.672744, 25.648246],
          [61.672997, 25.648294],
          [61.673083, 25.648373],
          [61.673277, 25.648294],
          [61.673873, 25.64873],
          [61.67395, 25.648916],
          [61.674002, 25.648954],
          [61.674092, 25.648931],
          [61.674136, 25.649104],
          [61.674066, 25.649376],
          [61.674127, 25.649558],
          [61.674061, 25.649784],
          [61.674259, 25.649941],
          [61.674639, 25.650104],
          [61.675044, 25.649987],
          [61.675153, 25.649975],
          [61.675107, 25.650074],
          [61.675158, 25.650097],
          [61.675333, 25.650069],
          [61.675372, 25.650016],
          [61.675517, 25.65009],
          [61.675875, 25.650153],
          [61.676032, 25.650266],
          [61.676211, 25.6503],
          [61.676349, 25.65041],
          [61.676523, 25.650442],
          [61.676624, 25.650529],
          [61.676849, 25.651059],
          [61.677034, 25.65105],
          [61.677304, 25.651206],
          [61.67732, 25.651257],
          [61.677496, 25.651284],
          [61.677609, 25.65135],
          [61.678058, 25.651675],
          [61.678082, 25.651734],
          [61.678044, 25.651768],
          [61.678133, 25.651862],
          [61.678356, 25.651818],
          [61.678458, 25.651958],
          [61.678724, 25.652141],
          [61.678772, 25.652265],
          [61.678917, 25.652258],
          [61.678997, 25.65239],
          [61.679261, 25.652451],
          [61.679277, 25.652525],
          [61.679569, 25.652799],
          [61.679531, 25.652854],
          [61.679553, 25.652922],
          [61.679479, 25.652972],
          [61.679502, 25.65311],
          [61.679385, 25.653091],
          [61.679322, 25.653297],
          [61.679072, 25.6537],
          [61.678994, 25.653994],
          [61.678765, 25.654123],
          [61.678599, 25.654349],
          [61.678579, 25.654663],
          [61.678637, 25.65492],
          [61.67863, 25.655272],
          [61.678693, 25.655495],
          [61.678649, 25.655524],
          [61.678784, 25.655943],
          [61.678744, 25.656206],
          [61.67878, 25.656276],
          [61.678728, 25.656382],
          [61.678844, 25.656828],
          [61.678808, 25.657118],
          [61.678839, 25.657177],
          [61.678665, 25.657493],
          [61.678695, 25.65778],
          [61.678654, 25.658006],
          [61.678688, 25.658009],
          [61.67867, 25.658052],
          [61.678726, 25.658091],
          [61.678771, 25.658254],
          [61.678685, 25.658256],
          [61.678721, 25.658335],
          [61.679202, 25.658353],
          [61.679295, 25.658485],
          [61.67953, 25.6585],
          [61.679493, 25.658926],
          [61.679541, 25.65905],
          [61.679513, 25.659095],
          [61.679597, 25.659256],
          [61.679528, 25.659366],
          [61.679566, 25.659443],
          [61.679977, 25.659765],
          [61.679916, 25.659989],
          [61.680018, 25.660241],
          [61.680111, 25.660294],
          [61.680113, 25.660403],
          [61.680165, 25.660445],
          [61.680115, 25.660527],
          [61.680137, 25.660589],
          [61.680314, 25.660702],
          [61.680403, 25.660872],
          [61.680477, 25.660919],
          [61.680675, 25.66098],
          [61.680814, 25.660937],
          [61.681203, 25.660996],
          [61.681282, 25.661043],
          [61.681344, 25.661004],
          [61.681488, 25.661004],
          [61.681588, 25.661054],
          [61.681658, 25.661032],
          [61.68171, 25.661146],
          [61.682015, 25.661327],
          [61.682583, 25.661435],
          [61.682803, 25.661695],
          [61.683008, 25.661697],
          [61.683061, 25.661618],
          [61.683162, 25.661598],
          [61.683243, 25.661623],
          [61.683369, 25.661508],
          [61.68357, 25.661695],
          [61.683639, 25.661653],
          [61.683804, 25.661724],
          [61.683817, 25.661801],
          [61.68407, 25.661761],
          [61.684312, 25.66187],
          [61.684373, 25.661952],
          [61.684534, 25.661935],
          [61.684546, 25.661901],
          [61.684655, 25.661958],
          [61.684768, 25.661943],
          [61.684996, 25.662216],
          [61.685156, 25.662209],
          [61.685151, 25.662433],
          [61.685296, 25.662463],
          [61.685538, 25.662595],
          [61.685803, 25.662548],
          [61.686139, 25.662675],
          [61.686052, 25.662793],
          [61.686051, 25.662913],
          [61.686248, 25.663044],
          [61.686399, 25.663449],
          [61.686273, 25.663471],
          [61.686045, 25.663694],
          [61.686057, 25.663745],
          [61.685963, 25.663843],
          [61.685973, 25.66392],
          [61.685914, 25.66394],
          [61.685889, 25.664079],
          [61.685805, 25.664096],
          [61.685937, 25.664254],
          [61.686113, 25.664197],
          [61.686134, 25.664254],
          [61.686296, 25.664302],
          [61.68681, 25.664277],
          [61.686954, 25.664363],
          [61.687128, 25.664363],
          [61.68736, 25.664517],
          [61.687347, 25.664619],
          [61.687554, 25.66463],
          [61.687675, 25.664731],
          [61.687875, 25.66472],
          [61.687992, 25.664778],
          [61.688525, 25.664826],
          [61.688654, 25.664885],
          [61.688777, 25.66487],
          [61.689099, 25.664976],
          [61.689342, 25.665136],
          [61.689455, 25.665121],
          [61.689659, 25.665243],
          [61.689843, 25.66516],
          [61.689912, 25.665222],
          [61.689867, 25.665292],
          [61.689959, 25.665295],
          [61.690018, 25.665444],
          [61.690099, 25.665444],
          [61.690132, 25.665547],
          [61.690209, 25.665543],
          [61.690183, 25.665573],
          [61.690306, 25.665649],
          [61.690508, 25.665491],
          [61.690771, 25.665403],
          [61.691341, 25.665364],
          [61.692268, 25.665503],
          [61.692419, 25.66543],
          [61.69271, 25.665458],
          [61.692788, 25.665591],
          [61.692917, 25.665612],
          [61.69284, 25.665674],
          [61.692848, 25.665743],
          [61.692941, 25.665743],
          [61.69295, 25.665838],
          [61.693115, 25.665851],
          [61.693443, 25.666088],
          [61.693628, 25.666137],
          [61.693717, 25.666115],
          [61.693819, 25.666232],
          [61.694224, 25.666337],
          [61.694689, 25.666632],
          [61.69469, 25.666713],
          [61.694788, 25.666849],
          [61.695183, 25.66694],
          [61.695338, 25.667059],
          [61.695336, 25.667103],
          [61.695588, 25.667191],
          [61.695525, 25.667307],
          [61.695563, 25.667328],
          [61.695595, 25.667559],
          [61.695681, 25.667674],
          [61.695628, 25.667706],
          [61.695681, 25.667866],
          [61.695732, 25.667897],
          [61.695951, 25.667861],
          [61.69629, 25.667996],
          [61.696103, 25.668302],
          [61.696085, 25.668479],
          [61.696126, 25.668551],
          [61.695895, 25.668926],
          [61.695579, 25.66889],
          [61.695816, 25.66932],
          [61.695763, 25.669552],
          [61.695464, 25.669618],
          [61.694883, 25.669906],
          [61.694773, 25.670046],
          [61.694777, 25.67011],
          [61.694675, 25.670151],
          [61.694693, 25.670185],
          [61.694633, 25.670232],
          [61.694627, 25.67034],
          [61.694525, 25.670539],
          [61.69456, 25.670988],
          [61.694352, 25.67134],
          [61.694405, 25.671442],
          [61.694315, 25.671625],
          [61.694208, 25.671713],
          [61.694144, 25.672346],
          [61.693813, 25.672585],
          [61.69308, 25.672602],
          [61.692967, 25.672663],
          [61.692714, 25.672949],
          [61.692749, 25.673082],
          [61.692691, 25.67325],
          [61.692502, 25.673332],
          [61.692409, 25.673606],
          [61.692447, 25.673741],
          [61.692518, 25.673809],
          [61.692488, 25.673864],
          [61.692535, 25.673927],
          [61.692534, 25.674148],
          [61.692509, 25.674229],
          [61.692396, 25.674211],
          [61.692413, 25.674297],
          [61.692371, 25.674357],
          [61.692737, 25.674567],
          [61.692835, 25.674768],
          [61.692621, 25.674796],
          [61.692632, 25.674897],
          [61.692453, 25.675427],
          [61.692335, 25.675417],
          [61.692306, 25.675457],
          [61.692365, 25.675498],
          [61.692236, 25.675618],
          [61.692074, 25.675607],
          [61.691984, 25.675743],
          [61.691865, 25.675778],
          [61.691837, 25.675711],
          [61.691715, 25.675695],
          [61.691672, 25.675749],
          [61.691502, 25.675723],
          [61.691365, 25.675789],
          [61.691359, 25.675908],
          [61.691194, 25.676049],
          [61.691163, 25.676128],
          [61.691241, 25.676156],
          [61.691301, 25.67629],
          [61.691383, 25.676285],
          [61.691418, 25.676325],
          [61.691516, 25.67657],
          [61.691565, 25.676599],
          [61.691594, 25.676737],
          [61.691541, 25.676907],
          [61.691444, 25.677093],
          [61.691299, 25.677056],
          [61.691249, 25.677259],
          [61.691346, 25.6773],
          [61.691404, 25.677411],
          [61.691404, 25.677722],
          [61.691596, 25.677816],
          [61.69163, 25.677887],
          [61.691592, 25.677954],
          [61.691736, 25.678044],
          [61.691688, 25.678166],
          [61.691618, 25.678216],
          [61.691894, 25.678342],
          [61.69217, 25.678312],
          [61.692322, 25.678415],
          [61.692458, 25.678438],
          [61.692764, 25.678368],
          [61.692879, 25.678578],
          [61.692857, 25.678795],
          [61.692739, 25.678876],
          [61.692609, 25.67914],
          [61.692495, 25.679239],
          [61.692449, 25.679228],
          [61.692471, 25.679292],
          [61.69241, 25.679321],
          [61.692468, 25.679388],
          [61.692467, 25.679485],
          [61.692575, 25.679552],
          [61.692519, 25.67965],
          [61.692595, 25.679706],
          [61.692581, 25.67979],
          [61.692432, 25.679916],
          [61.692429, 25.680093],
          [61.692366, 25.680136],
          [61.692378, 25.68018],
          [61.692458, 25.680155],
          [61.692633, 25.680303],
          [61.692716, 25.680312],
          [61.692824, 25.680444],
          [61.692942, 25.680428],
          [61.693049, 25.680502],
          [61.693025, 25.680564],
          [61.693097, 25.680672],
          [61.693238, 25.680633],
          [61.693337, 25.680739],
          [61.693389, 25.680697],
          [61.693603, 25.680706],
          [61.693592, 25.680657],
          [61.693798, 25.680656],
          [61.693939, 25.680754],
          [61.694101, 25.680719],
          [61.694118, 25.680762],
          [61.694161, 25.680752],
          [61.694194, 25.680865],
          [61.694341, 25.680856],
          [61.694551, 25.680714],
          [61.694768, 25.680834],
          [61.694795, 25.680897],
          [61.695026, 25.680893],
          [61.695388, 25.680707],
          [61.69547, 25.680621],
          [61.69595, 25.680609],
          [61.696015, 25.680811],
          [61.695954, 25.680852],
          [61.696017, 25.680943],
          [61.696006, 25.681151],
          [61.696063, 25.681283],
          [61.696003, 25.681395],
          [61.696084, 25.681465],
          [61.696143, 25.681737],
          [61.696036, 25.68197],
          [61.696025, 25.682155],
          [61.696084, 25.682372],
          [61.696245, 25.682495],
          [61.696178, 25.683049],
          [61.695967, 25.683188],
          [61.695912, 25.683416],
          [61.695586, 25.683638],
          [61.695795, 25.684007],
          [61.695931, 25.684062],
          [61.696044, 25.684307],
          [61.696037, 25.684387],
          [61.696153, 25.684444],
          [61.696164, 25.684783],
          [61.696071, 25.685022],
          [61.695882, 25.685245],
          [61.695654, 25.685322],
          [61.695537, 25.685566],
          [61.695443, 25.685587],
          [61.695503, 25.68565],
          [61.69542, 25.685709],
          [61.69505, 25.685815],
          [61.694877, 25.685786],
          [61.694645, 25.685822],
          [61.694607, 25.685789],
          [61.694537, 25.685891],
          [61.694464, 25.685872],
          [61.694324, 25.685957],
          [61.69426, 25.686244],
          [61.69413, 25.686281],
          [61.693903, 25.686497],
          [61.693833, 25.686509],
          [61.69386, 25.686801],
          [61.693722, 25.687018],
          [61.693667, 25.687264],
          [61.693554, 25.687347],
          [61.693572, 25.687406],
          [61.693487, 25.687567],
          [61.693494, 25.687622],
          [61.693775, 25.687756],
          [61.693893, 25.687754],
          [61.693959, 25.687856],
          [61.694036, 25.687884],
          [61.694221, 25.68846],
          [61.69417, 25.688615],
          [61.694191, 25.688764],
          [61.694271, 25.688873],
          [61.694279, 25.689144],
          [61.694395, 25.689525],
          [61.694398, 25.68981],
          [61.694487, 25.690058],
          [61.694538, 25.690509],
          [61.694536, 25.690795],
          [61.694426, 25.690853],
          [61.694406, 25.690943],
          [61.694487, 25.691126],
          [61.694479, 25.691225],
          [61.694319, 25.691408],
          [61.694354, 25.691444],
          [61.694256, 25.69206],
          [61.694297, 25.692122],
          [61.694103, 25.692066],
          [61.693953, 25.692087],
          [61.693891, 25.691966],
          [61.693817, 25.691942],
          [61.693733, 25.69204],
          [61.693331, 25.692129],
          [61.693284, 25.692207],
          [61.69304, 25.692372],
          [61.692585, 25.69254],
          [61.692385, 25.692594],
          [61.69232, 25.692573],
          [61.692036, 25.692748],
          [61.691795, 25.692976],
          [61.69167, 25.693007],
          [61.691445, 25.693185],
          [61.691424, 25.693249],
          [61.691342, 25.693289],
          [61.691367, 25.693464],
          [61.69149, 25.693528],
          [61.691691, 25.693423],
          [61.691759, 25.693446],
          [61.691957, 25.693205],
          [61.69222, 25.693017],
          [61.692331, 25.693058],
          [61.692564, 25.693038],
          [61.692589, 25.692994],
          [61.692609, 25.693084],
          [61.692954, 25.693125],
          [61.692958, 25.693243],
          [61.693016, 25.693321],
          [61.693242, 25.693493],
          [61.6936, 25.693604],
          [61.693932, 25.693632],
          [61.694181, 25.693818],
          [61.694436, 25.693511],
          [61.694586, 25.693506],
          [61.694677, 25.693562],
          [61.694815, 25.693482],
          [61.69487, 25.69311],
          [61.695102, 25.69297],
          [61.695142, 25.692891],
          [61.695405, 25.69268],
          [61.69552, 25.692674],
          [61.695527, 25.692617],
          [61.695963, 25.692343],
          [61.696021, 25.692219],
          [61.69675, 25.691936],
          [61.697157, 25.691904],
          [61.697357, 25.691768],
          [61.697583, 25.691684],
          [61.697907, 25.691611],
          [61.69799, 25.691631],
          [61.698136, 25.69155],
          [61.698609, 25.691418],
          [61.698742, 25.691358],
          [61.698962, 25.691057],
          [61.69911, 25.690996],
          [61.69944, 25.690936],
          [61.699699, 25.690944],
          [61.699735, 25.690991],
          [61.699899, 25.690977],
          [61.699954, 25.690944],
          [61.699879, 25.690915],
          [61.699976, 25.690905],
          [61.700092, 25.691006],
          [61.700336, 25.690889],
          [61.700658, 25.690637],
          [61.70088, 25.690578],
          [61.70109, 25.690606],
          [61.701264, 25.690728],
          [61.701465, 25.69071],
          [61.701692, 25.690858],
          [61.701988, 25.690882],
          [61.702241, 25.690971],
          [61.702363, 25.690933],
          [61.703042, 25.690928],
          [61.703531, 25.690967],
          [61.703985, 25.690902],
          [61.704032, 25.691065],
          [61.703817, 25.691367],
          [61.703861, 25.691541],
          [61.70402, 25.691573],
          [61.704795, 25.69202],
          [61.704894, 25.692121],
          [61.704897, 25.692218],
          [61.704988, 25.692283],
          [61.704969, 25.692368],
          [61.705136, 25.692424],
          [61.705351, 25.692637],
          [61.705536, 25.692744],
          [61.705603, 25.692714],
          [61.705678, 25.692754],
          [61.705975, 25.693067],
          [61.706136, 25.693131],
          [61.706345, 25.693498],
          [61.706372, 25.693821],
          [61.706137, 25.694068],
          [61.706188, 25.69424],
          [61.706138, 25.694321],
          [61.706241, 25.6947],
          [61.706218, 25.694764],
          [61.706165, 25.694774],
          [61.706139, 25.694887],
          [61.706163, 25.695124],
          [61.706123, 25.695185],
          [61.706201, 25.695259],
          [61.706122, 25.695405],
          [61.706218, 25.695481],
          [61.70625, 25.695578],
          [61.705988, 25.696053],
          [61.70597, 25.696421],
          [61.705688, 25.696481],
          [61.705385, 25.696706],
          [61.705279, 25.696718],
          [61.70509, 25.696834],
          [61.705252, 25.697298],
          [61.705249, 25.697697],
          [61.70521, 25.697795],
          [61.70533, 25.697799],
          [61.705446, 25.697877],
          [61.70567, 25.698141],
          [61.70585, 25.698261],
          [61.706033, 25.698488],
          [61.706016, 25.698612],
          [61.706111, 25.698642],
          [61.706305, 25.698842],
          [61.706291, 25.698907],
          [61.706545, 25.699192],
          [61.706547, 25.699255],
          [61.706682, 25.699396],
          [61.706696, 25.699514],
          [61.706853, 25.699733],
          [61.707054, 25.699918],
          [61.707305, 25.69999],
          [61.707545, 25.700259],
          [61.707665, 25.700219],
          [61.707802, 25.700393],
          [61.707906, 25.700312],
          [61.70806, 25.700438],
          [61.708066, 25.700688],
          [61.708242, 25.700673],
          [61.708291, 25.700757],
          [61.708381, 25.700788],
          [61.708437, 25.700936],
          [61.708468, 25.700891],
          [61.7086, 25.700919],
          [61.708726, 25.700873],
          [61.708976, 25.700983],
          [61.709484, 25.70085],
          [61.70957, 25.700907],
          [61.709582, 25.70096],
          [61.7095, 25.701093],
          [61.709455, 25.701544],
          [61.709591, 25.701618],
          [61.709566, 25.701708],
          [61.709612, 25.70179],
          [61.709822, 25.701847],
          [61.710227, 25.701785],
          [61.710323, 25.701729],
          [61.710459, 25.701468],
          [61.710674, 25.701672],
          [61.711029, 25.701408],
          [61.711075, 25.701458],
          [61.711024, 25.701499],
          [61.711054, 25.701598],
          [61.710962, 25.702067],
          [61.710696, 25.702536],
          [61.710667, 25.70291],
          [61.711102, 25.703636],
          [61.711632, 25.703811],
          [61.711696, 25.70396],
          [61.711799, 25.703993],
          [61.711952, 25.703943],
          [61.712138, 25.70397],
          [61.712227, 25.70405],
          [61.712298, 25.703984],
          [61.712369, 25.703997],
          [61.712487, 25.704524],
          [61.712864, 25.704704],
          [61.712879, 25.704771],
          [61.712994, 25.704856],
          [61.71305, 25.705321],
          [61.712987, 25.705388],
          [61.713287, 25.705528],
          [61.713243, 25.705684],
          [61.713386, 25.705703],
          [61.713414, 25.705768],
          [61.713347, 25.705866],
          [61.713429, 25.706162],
          [61.713525, 25.706375],
          [61.713605, 25.70644],
          [61.713702, 25.706713],
          [61.713632, 25.706918],
          [61.713635, 25.707159],
          [61.713565, 25.707315],
          [61.714063, 25.708035],
          [61.714154, 25.70788],
          [61.714513, 25.707817],
          [61.714628, 25.708011],
          [61.715038, 25.708171],
          [61.715535, 25.708451],
          [61.715866, 25.708555],
          [61.716066, 25.708555],
          [61.716267, 25.708661],
          [61.716945, 25.708868],
          [61.71711, 25.708999],
          [61.717271, 25.709026],
          [61.717333, 25.709224],
          [61.717387, 25.70925],
          [61.717493, 25.709219],
          [61.717548, 25.709314],
          [61.717764, 25.709288],
          [61.717955, 25.709366],
          [61.718129, 25.709328],
          [61.718263, 25.709196],
          [61.718607, 25.709154],
          [61.718699, 25.709224],
          [61.719063, 25.709158],
          [61.71918, 25.709198],
          [61.719218, 25.709265],
          [61.719512, 25.709177],
          [61.719921, 25.709179],
          [61.719884, 25.70913],
          [61.719972, 25.709156],
          [61.719985, 25.70921],
          [61.720242, 25.709132],
          [61.720831, 25.709047],
          [61.721008, 25.709171],
          [61.721151, 25.709206],
          [61.721254, 25.709183],
          [61.721434, 25.709407],
          [61.72183, 25.709462],
          [61.722277, 25.709777],
          [61.722456, 25.709811],
          [61.722682, 25.709783],
          [61.722883, 25.709857],
          [61.723124, 25.709871],
          [61.723277, 25.709804],
          [61.723432, 25.709833],
          [61.723389, 25.709855],
          [61.723404, 25.709911],
          [61.723495, 25.709904],
          [61.723571, 25.710078],
          [61.723701, 25.710227],
          [61.723842, 25.710286],
          [61.723841, 25.710347],
          [61.723921, 25.71035],
          [61.723845, 25.71045],
          [61.723769, 25.710717],
          [61.723809, 25.710754],
          [61.723755, 25.710853],
          [61.723817, 25.710976],
          [61.723853, 25.710976],
          [61.723912, 25.710846],
          [61.72415, 25.710835],
          [61.724253, 25.711023],
          [61.724395, 25.711073],
          [61.72477, 25.711037],
          [61.72484, 25.711098],
          [61.72544, 25.711074],
          [61.725805, 25.711202],
          [61.726143, 25.711182],
          [61.726108, 25.711141],
          [61.72616, 25.711092],
          [61.726362, 25.711038],
          [61.726322, 25.71126],
          [61.726367, 25.711365],
          [61.726247, 25.711518],
          [61.726338, 25.711689],
          [61.726534, 25.711793],
          [61.726648, 25.711799],
          [61.726605, 25.711848],
          [61.726695, 25.711915],
          [61.726889, 25.711956],
          [61.727184, 25.71196],
          [61.72769, 25.711885],
          [61.727578, 25.712083],
          [61.727609, 25.712171],
          [61.727515, 25.712295],
          [61.72764, 25.712402],
          [61.727834, 25.712457],
          [61.728018, 25.712938],
          [61.728215, 25.712892],
          [61.728399, 25.712904],
          [61.728362, 25.713218],
          [61.728403, 25.713283],
          [61.728346, 25.713366],
          [61.728637, 25.713406],
          [61.729033, 25.713332],
          [61.729264, 25.713544],
          [61.729431, 25.713611],
          [61.729377, 25.713829],
          [61.729623, 25.714095],
          [61.729629, 25.714369],
          [61.729737, 25.714502],
          [61.7297, 25.714727],
          [61.729927, 25.714954],
          [61.729971, 25.715111],
          [61.729959, 25.715232],
          [61.729899, 25.715252],
          [61.729925, 25.715337],
          [61.730037, 25.715477],
          [61.730386, 25.715748],
          [61.730524, 25.715932],
          [61.729918, 25.716138],
          [61.729911, 25.716205],
          [61.729535, 25.716346],
          [61.729158, 25.716364],
          [61.728902, 25.716524],
          [61.728871, 25.716555],
          [61.728908, 25.716854],
          [61.729017, 25.717048],
          [61.729004, 25.717156],
          [61.729066, 25.717189],
          [61.72893, 25.717288],
          [61.728947, 25.717414],
          [61.728915, 25.717432],
          [61.728655, 25.717522],
          [61.728503, 25.717462],
          [61.728182, 25.717573],
          [61.727643, 25.717638],
          [61.7274, 25.717713],
          [61.727303, 25.717881],
          [61.727243, 25.717894],
          [61.727178, 25.7179],
          [61.727082, 25.717819],
          [61.727121, 25.717812],
          [61.727105, 25.717765],
          [61.727025, 25.71773],
          [61.726607, 25.717724],
          [61.726494, 25.717775],
          [61.726411, 25.717902],
          [61.726487, 25.717919],
          [61.726423, 25.718062],
          [61.726483, 25.718119],
          [61.726438, 25.718277],
          [61.726483, 25.718391],
          [61.726724, 25.718518],
          [61.726933, 25.718504],
          [61.726899, 25.718674],
          [61.727154, 25.71899],
          [61.727146, 25.719086],
          [61.72724, 25.719149],
          [61.727177, 25.719413],
          [61.726612, 25.719436],
          [61.726534, 25.719396],
          [61.726315, 25.71939],
          [61.726155, 25.719559],
          [61.726073, 25.719817],
          [61.726003, 25.719813],
          [61.726004, 25.719935],
          [61.725947, 25.719933],
          [61.725885, 25.720017],
          [61.726096, 25.72008],
          [61.727182, 25.72021],
          [61.727202, 25.720347],
          [61.727509, 25.720647],
          [61.727489, 25.720986],
          [61.727531, 25.721338],
          [61.727707, 25.72148],
          [61.727638, 25.721535],
          [61.727691, 25.721596],
          [61.72768, 25.721811],
          [61.72772, 25.721872],
          [61.727841, 25.721879],
          [61.727906, 25.722067],
          [61.727952, 25.722523],
          [61.727895, 25.722566],
          [61.728272, 25.722776],
          [61.728509, 25.722828],
          [61.728646, 25.722933],
          [61.728643, 25.723065],
          [61.728481, 25.723375],
          [61.728509, 25.723605],
          [61.728341, 25.723815],
          [61.728321, 25.723926],
          [61.72812, 25.724028],
          [61.728047, 25.724139],
          [61.72816, 25.724253],
          [61.728152, 25.724416],
          [61.728094, 25.724485],
          [61.728217, 25.724846],
          [61.727754, 25.725049],
          [61.727673, 25.725031],
          [61.727644, 25.725181],
          [61.72759, 25.7252],
          [61.72751, 25.725393],
          [61.727288, 25.725624],
          [61.727063, 25.725669],
          [61.726719, 25.725877],
          [61.726347, 25.726002],
          [61.725615, 25.726065],
          [61.725228, 25.726201],
          [61.725016, 25.726192],
          [61.725023, 25.726315],
          [61.725122, 25.726511],
          [61.725115, 25.726611],
          [61.725284, 25.726724],
          [61.725297, 25.726811],
          [61.72543, 25.726823],
          [61.725533, 25.726936],
          [61.725627, 25.726924],
          [61.725677, 25.727014],
          [61.725646, 25.727024],
          [61.725746, 25.727079],
          [61.725632, 25.727498],
          [61.725671, 25.727545],
          [61.725915, 25.727584],
          [61.72638, 25.727518],
          [61.726463, 25.727696],
          [61.726617, 25.727699],
          [61.726811, 25.727829],
          [61.726949, 25.728332],
          [61.727218, 25.728369],
          [61.727405, 25.728468],
          [61.727863, 25.728514],
          [61.727957, 25.728633],
          [61.728684, 25.728704],
          [61.728562, 25.728815],
          [61.728686, 25.728842],
          [61.728641, 25.728899],
          [61.728448, 25.72894],
          [61.728457, 25.729021],
          [61.728355, 25.729121],
          [61.728356, 25.729156],
          [61.728416, 25.729141],
          [61.728728, 25.72925],
          [61.728704, 25.729538],
          [61.728612, 25.729596],
          [61.728567, 25.729808],
          [61.728564, 25.729987],
          [61.72861, 25.730078],
          [61.728752, 25.730214],
          [61.729012, 25.730342],
          [61.729055, 25.730571],
          [61.729219, 25.730735],
          [61.729325, 25.730981],
          [61.729457, 25.731139],
          [61.729755, 25.731288],
          [61.729907, 25.731237],
          [61.730087, 25.731104],
          [61.730309, 25.731157],
          [61.730427, 25.731582],
          [61.730472, 25.731609],
          [61.730486, 25.73178],
          [61.730391, 25.731865],
          [61.730527, 25.731932],
          [61.730774, 25.732353],
          [61.73099, 25.73247],
          [61.731096, 25.732451],
          [61.731253, 25.732501],
          [61.731225, 25.732635],
          [61.731256, 25.732676],
          [61.731631, 25.73295],
          [61.731785, 25.732984],
          [61.732073, 25.733208],
          [61.732422, 25.733193],
          [61.732727, 25.73312],
          [61.732975, 25.733243],
          [61.733063, 25.733572],
          [61.733062, 25.733854],
          [61.733368, 25.734023],
          [61.733173, 25.734163],
          [61.733085, 25.734171],
          [61.732971, 25.734098],
          [61.73283, 25.734169],
          [61.732837, 25.734234],
          [61.733314, 25.735114],
          [61.733231, 25.735248],
          [61.733114, 25.735295],
          [61.732917, 25.735547],
          [61.732793, 25.735616],
          [61.73277, 25.735695],
          [61.732597, 25.735803],
          [61.732601, 25.735864],
          [61.732513, 25.735928],
          [61.732377, 25.73622],
          [61.732393, 25.736264],
          [61.732286, 25.736407],
          [61.732307, 25.736443],
          [61.732231, 25.736576],
          [61.732147, 25.736598],
          [61.732159, 25.736641],
          [61.732074, 25.736747],
          [61.732117, 25.736852],
          [61.732217, 25.736888],
          [61.732265, 25.736972],
          [61.732384, 25.736936],
          [61.732543, 25.737123],
          [61.732299, 25.737653],
          [61.732383, 25.737709],
          [61.732666, 25.737739],
          [61.732729, 25.737812],
          [61.73285, 25.737846],
          [61.732911, 25.73792],
          [61.732924, 25.738063],
          [61.732953, 25.738046],
          [61.733181, 25.738461],
          [61.733184, 25.738716],
          [61.732914, 25.738797],
          [61.732937, 25.738894],
          [61.733005, 25.73891],
          [61.732953, 25.73917],
          [61.733031, 25.739227],
          [61.733057, 25.739351],
          [61.733037, 25.739615],
          [61.733396, 25.739718],
          [61.733538, 25.739888],
          [61.733591, 25.739852],
          [61.733704, 25.739917],
          [61.734151, 25.739986],
          [61.734199, 25.740078],
          [61.734159, 25.740151],
          [61.734424, 25.740351],
          [61.734632, 25.740358],
          [61.734703, 25.74054],
          [61.734635, 25.740597],
          [61.73493, 25.740789],
          [61.735241, 25.740732],
          [61.735558, 25.740788],
          [61.735989, 25.740738],
          [61.736041, 25.740797],
          [61.736238, 25.741452],
          [61.736104, 25.741579],
          [61.736299, 25.741603],
          [61.736329, 25.74172],
          [61.73651, 25.74172],
          [61.736912, 25.741558],
          [61.73752, 25.741462],
          [61.738262, 25.74105],
          [61.738537, 25.741055],
          [61.738961, 25.740945],
          [61.739425, 25.740647],
          [61.739436, 25.740507],
          [61.739569, 25.740204],
          [61.739955, 25.740139],
          [61.740151, 25.739984],
          [61.740202, 25.739884],
          [61.740464, 25.739802],
          [61.740663, 25.739486],
          [61.740858, 25.739294],
          [61.740888, 25.739031],
          [61.74106, 25.738651],
          [61.741665, 25.738122],
          [61.741567, 25.737719],
          [61.741619, 25.737623],
          [61.741602, 25.737462],
          [61.741669, 25.737347],
          [61.742133, 25.737342],
          [61.74236, 25.737164],
          [61.742593, 25.737099],
          [61.7426, 25.737011],
          [61.742827, 25.737021],
          [61.743258, 25.736755],
          [61.743314, 25.736644],
          [61.743524, 25.736623],
          [61.743622, 25.736528],
          [61.743642, 25.736443],
          [61.743988, 25.736149],
          [61.74397, 25.736106],
          [61.744019, 25.736085],
          [61.743991, 25.735893],
          [61.744049, 25.735799],
          [61.74399, 25.735692],
          [61.744199, 25.735669],
          [61.744204, 25.735555],
          [61.744337, 25.735504],
          [61.744394, 25.735332],
          [61.744527, 25.73532],
          [61.744557, 25.735033],
          [61.744461, 25.734921],
          [61.744458, 25.734851],
          [61.744541, 25.734813],
          [61.744564, 25.73453],
          [61.744665, 25.734491],
          [61.744649, 25.734445],
          [61.744868, 25.734202],
          [61.745013, 25.733906],
          [61.745459, 25.733685],
          [61.745594, 25.73356],
          [61.74567, 25.733404],
          [61.745841, 25.733359],
          [61.746151, 25.733145],
          [61.74638, 25.733119],
          [61.746553, 25.733041],
          [61.74662, 25.733074],
          [61.747177, 25.733084],
          [61.747533, 25.733189],
          [61.747587, 25.73318],
          [61.747588, 25.733137],
          [61.747748, 25.733123],
          [61.74777, 25.733185],
          [61.747821, 25.733195],
          [61.747902, 25.733159],
          [61.748101, 25.733171],
          [61.748097, 25.73323],
          [61.748183, 25.733225],
          [61.748288, 25.733136],
          [61.748339, 25.73319],
          [61.748438, 25.733201],
          [61.748692, 25.732931],
          [61.748759, 25.732933],
          [61.74883, 25.732867],
          [61.749006, 25.73292],
          [61.749143, 25.732905],
          [61.749502, 25.732626],
          [61.749553, 25.732645],
          [61.749626, 25.73259],
          [61.749819, 25.73257],
          [61.749968, 25.732607],
          [61.750143, 25.73255],
          [61.750446, 25.732579],
          [61.750685, 25.73267],
          [61.750777, 25.732549],
          [61.751025, 25.732432],
          [61.751469, 25.73234],
          [61.751666, 25.732209],
          [61.751861, 25.732235],
          [61.75196, 25.732199],
          [61.751998, 25.73223],
          [61.752097, 25.732175],
          [61.752316, 25.73215],
          [61.752336, 25.732189],
          [61.752426, 25.732182],
          [61.752443, 25.732231],
          [61.752534, 25.732206],
          [61.752884, 25.732261],
          [61.7534, 25.732217],
          [61.753536, 25.732249],
          [61.753695, 25.732038],
          [61.75385, 25.731954],
          [61.754404, 25.731941],
          [61.75463, 25.731859],
          [61.754755, 25.731912],
          [61.755065, 25.731754],
          [61.755575, 25.731729],
          [61.756044, 25.731572],
          [61.75621, 25.73158],
          [61.756337, 25.731511],
          [61.756674, 25.731578],
          [61.757073, 25.731512],
          [61.75716, 25.7316],
          [61.757289, 25.731624],
          [61.757788, 25.731662],
          [61.758105, 25.73163],
          [61.758213, 25.731667],
          [61.758376, 25.731577],
          [61.758708, 25.731542],
          [61.758705, 25.731508],
          [61.758889, 25.731431],
          [61.759162, 25.731462],
          [61.759346, 25.73157],
          [61.759462, 25.731564],
          [61.759884, 25.731705],
          [61.760106, 25.731718],
          [61.760518, 25.731896],
          [61.760685, 25.732061],
          [61.761009, 25.731995],
          [61.761073, 25.732026],
          [61.761168, 25.731987],
          [61.761295, 25.732017],
          [61.76144, 25.731991],
          [61.76168, 25.732162],
          [61.761876, 25.732174],
          [61.761883, 25.732225],
          [61.762126, 25.732299],
          [61.762075, 25.732322],
          [61.762073, 25.732379],
          [61.762229, 25.732488],
          [61.762391, 25.732494],
          [61.76269, 25.732638],
          [61.762696, 25.732443],
          [61.76276, 25.732431],
          [61.762775, 25.732302],
          [61.763149, 25.732325],
          [61.763143, 25.732214],
          [61.763282, 25.732133],
          [61.763551, 25.732231],
          [61.76374, 25.732045],
          [61.763739, 25.731967],
          [61.763879, 25.732018],
          [61.764012, 25.73191],
          [61.764225, 25.731903],
          [61.764289, 25.731934],
          [61.764294, 25.732045],
          [61.764376, 25.732122],
          [61.764579, 25.732211],
          [61.764567, 25.732117],
          [61.764649, 25.732053],
          [61.76468, 25.731958],
          [61.764996, 25.731823],
          [61.76519, 25.731822],
          [61.765195, 25.731778],
          [61.765262, 25.73179],
          [61.765332, 25.731729],
          [61.765519, 25.73174],
          [61.76594, 25.731621],
          [61.766254, 25.731697],
          [61.766414, 25.731678],
          [61.766471, 25.731738],
          [61.766632, 25.731736],
          [61.766793, 25.731618],
          [61.767369, 25.731657],
          [61.767392, 25.7317],
          [61.76749, 25.731648],
          [61.767847, 25.731676],
          [61.767902, 25.731563],
          [61.768047, 25.731528],
          [61.768073, 25.731474],
          [61.76827, 25.731498],
          [61.7685, 25.731446],
          [61.76872, 25.731215],
          [61.769078, 25.731302],
          [61.769373, 25.73131],
          [61.769617, 25.731413],
          [61.769854, 25.731423],
          [61.770097, 25.731511],
          [61.770558, 25.73158],
          [61.77091, 25.73137],
          [61.771153, 25.731414],
          [61.771403, 25.7314],
          [61.771459, 25.731238],
          [61.771909, 25.731006],
          [61.771914, 25.731049],
          [61.771991, 25.731043],
          [61.772022, 25.731096],
          [61.772246, 25.731073],
          [61.77228, 25.73114],
          [61.772382, 25.731131],
          [61.772524, 25.73119],
          [61.772727, 25.73115],
          [61.772811, 25.731074],
          [61.773027, 25.730991],
          [61.773153, 25.730985],
          [61.773416, 25.73107],
          [61.773729, 25.731056],
          [61.773723, 25.730723],
          [61.773778, 25.730589],
          [61.773686, 25.730411],
          [61.773724, 25.730347],
          [61.773608, 25.730253],
          [61.773678, 25.73002],
          [61.773859, 25.729885],
          [61.773836, 25.729622],
          [61.773714, 25.72942],
          [61.773669, 25.729421],
          [61.773682, 25.729271],
          [61.773444, 25.729433],
          [61.773319, 25.729448],
          [61.773248, 25.729371],
          [61.773094, 25.729316],
          [61.773021, 25.729332],
          [61.772991, 25.729278],
          [61.7729, 25.729285],
          [61.772916, 25.729198],
          [61.772761, 25.729037],
          [61.772769, 25.728987],
          [61.772693, 25.729012],
          [61.772326, 25.728884],
          [61.772291, 25.728787],
          [61.772323, 25.728749],
          [61.772192, 25.728627],
          [61.771989, 25.728568],
          [61.771882, 25.728592],
          [61.771797, 25.728534],
          [61.771396, 25.728439],
          [61.771553, 25.728333],
          [61.771528, 25.7283],
          [61.771382, 25.728268],
          [61.771224, 25.728302],
          [61.771155, 25.728237],
          [61.771032, 25.728233],
          [61.770698, 25.728304],
          [61.770681, 25.728243],
          [61.770492, 25.72823],
          [61.770594, 25.728111],
          [61.770622, 25.727872],
          [61.770912, 25.727656],
          [61.771571, 25.727505],
          [61.771805, 25.727566],
          [61.772003, 25.727536],
          [61.772346, 25.727607],
          [61.772644, 25.72759],
          [61.772745, 25.72744],
          [61.772881, 25.727367],
          [61.772965, 25.727369],
          [61.773344, 25.727187],
          [61.773479, 25.727163],
          [61.773517, 25.727095],
          [61.773608, 25.727135],
          [61.773752, 25.727094],
          [61.773809, 25.726945],
          [61.773944, 25.726867],
          [61.774052, 25.726837],
          [61.77443, 25.726871],
          [61.774653, 25.72673],
          [61.774827, 25.72669],
          [61.77486, 25.726633],
          [61.774646, 25.726504],
          [61.774415, 25.726489],
          [61.774467, 25.726363],
          [61.774431, 25.726033],
          [61.774929, 25.725542],
          [61.774983, 25.725378],
          [61.774907, 25.725187],
          [61.774991, 25.725073],
          [61.775195, 25.72504],
          [61.775195, 25.724868],
          [61.775336, 25.724564],
          [61.775346, 25.72441],
          [61.775532, 25.724234],
          [61.775778, 25.724106],
          [61.775966, 25.72409],
          [61.776181, 25.724115],
          [61.776415, 25.724207],
          [61.776522, 25.724195],
          [61.776703, 25.724105],
          [61.776765, 25.723967],
          [61.77704, 25.723844],
          [61.777427, 25.723979],
          [61.7776, 25.723942],
          [61.777702, 25.72383],
          [61.778032, 25.723713],
          [61.77837, 25.723651],
          [61.778577, 25.723509],
          [61.778595, 25.723278],
          [61.778665, 25.723167],
          [61.778612, 25.723027],
          [61.778822, 25.722865],
          [61.778796, 25.722731],
          [61.779281, 25.722589],
          [61.779518, 25.722442],
          [61.779685, 25.722394],
          [61.779788, 25.722275],
          [61.779953, 25.722227],
          [61.779976, 25.722171],
          [61.780204, 25.722021],
          [61.780278, 25.72186],
          [61.780398, 25.721838],
          [61.780604, 25.721603],
          [61.780677, 25.721578],
          [61.780684, 25.721521],
          [61.780882, 25.721472],
          [61.780981, 25.721325],
          [61.781226, 25.721108],
          [61.781467, 25.721066],
          [61.781553, 25.721006],
          [61.781628, 25.721065],
          [61.782232, 25.721003],
          [61.782387, 25.721046],
          [61.782541, 25.721179],
          [61.7827, 25.721169],
          [61.782761, 25.721243],
          [61.782979, 25.721289],
          [61.783264, 25.721002],
          [61.783568, 25.720915],
          [61.783644, 25.720813],
          [61.783902, 25.720791],
          [61.784335, 25.720599],
          [61.784545, 25.720407],
          [61.784671, 25.720366],
          [61.784898, 25.720188],
          [61.785058, 25.719991],
          [61.785555, 25.719896],
          [61.785743, 25.71975],
          [61.786133, 25.719625],
          [61.786268, 25.719514],
          [61.786395, 25.719501],
          [61.786466, 25.719388],
          [61.786713, 25.719396],
          [61.787027, 25.719275],
          [61.787036, 25.719355],
          [61.787262, 25.719264],
          [61.787386, 25.719279],
          [61.787367, 25.719353],
          [61.787458, 25.71936],
          [61.787646, 25.719287],
          [61.78844, 25.719363],
          [61.788702, 25.719335],
          [61.788805, 25.719392],
          [61.788963, 25.7194],
          [61.789492, 25.719283],
          [61.78951, 25.719237],
          [61.789791, 25.719216],
          [61.790808, 25.71901],
          [61.791098, 25.719046],
          [61.791222, 25.718998],
          [61.791412, 25.719],
          [61.791684, 25.718908],
          [61.79181, 25.718946],
          [61.792071, 25.718816],
          [61.792405, 25.718822],
          [61.792731, 25.71896],
          [61.792844, 25.718951],
          [61.792982, 25.71903],
          [61.793311, 25.719035],
          [61.793446, 25.719077],
          [61.793606, 25.718997],
          [61.793831, 25.719021],
          [61.794003, 25.718979],
          [61.794032, 25.718908],
          [61.794354, 25.718886],
          [61.79451, 25.718659],
          [61.794483, 25.718357],
          [61.794617, 25.718329],
          [61.794574, 25.718221],
          [61.794636, 25.718081],
          [61.795041, 25.718054],
          [61.795439, 25.717963],
          [61.796125, 25.718036],
          [61.796725, 25.718001],
          [61.797262, 25.718035],
          [61.797537, 25.717965],
          [61.798234, 25.717887],
          [61.798361, 25.717907],
          [61.798331, 25.718236],
          [61.798422, 25.718459],
          [61.798368, 25.718558],
          [61.798424, 25.718707],
          [61.798327, 25.718806],
          [61.798355, 25.718947],
          [61.798656, 25.718924],
          [61.79895, 25.719241],
          [61.799128, 25.719868],
          [61.799196, 25.719877],
          [61.799229, 25.719961],
          [61.799202, 25.720144],
          [61.799269, 25.720219],
          [61.799409, 25.720154],
          [61.799567, 25.720314],
          [61.799754, 25.72034],
          [61.79985, 25.720433],
          [61.799845, 25.720539],
          [61.799668, 25.720648],
          [61.799729, 25.720701],
          [61.79981, 25.721111],
          [61.79961, 25.721292],
          [61.799524, 25.721539],
          [61.799587, 25.721565],
          [61.799601, 25.721626],
          [61.799936, 25.721769],
          [61.799868, 25.721983],
          [61.799983, 25.722125],
          [61.80009, 25.722072],
          [61.800388, 25.722226],
          [61.800485, 25.722228],
          [61.80067, 25.72209],
          [61.80083, 25.72215],
          [61.800922, 25.72228],
          [61.801035, 25.722281],
          [61.80118, 25.722237],
          [61.80137, 25.722053],
          [61.801604, 25.72204],
          [61.801989, 25.721882],
          [61.802046, 25.721995],
          [61.802227, 25.722061],
          [61.802542, 25.722019],
          [61.802607, 25.721935],
          [61.802838, 25.721984],
          [61.803516, 25.721923],
          [61.803948, 25.722095],
          [61.803997, 25.722185],
          [61.804103, 25.722236],
          [61.804421, 25.722185],
          [61.804726, 25.722244],
          [61.804939, 25.722345],
          [61.805125, 25.722344],
          [61.805097, 25.722748],
          [61.805158, 25.72292],
          [61.805237, 25.72293],
          [61.805231, 25.723004],
          [61.805022, 25.723064],
          [61.804993, 25.723283],
          [61.805054, 25.723359],
          [61.805212, 25.723392],
          [61.805194, 25.723477],
          [61.805317, 25.723477],
          [61.805281, 25.723619],
          [61.80517, 25.72369],
          [61.805223, 25.723753],
          [61.805368, 25.723761],
          [61.805255, 25.723903],
          [61.805118, 25.723917],
          [61.804953, 25.724052],
          [61.805053, 25.724173],
          [61.805013, 25.724339],
          [61.804907, 25.724391],
          [61.805079, 25.724501],
          [61.805127, 25.724598],
          [61.805392, 25.724596],
          [61.805472, 25.724652],
          [61.805671, 25.724982],
          [61.805746, 25.725235],
          [61.805874, 25.725236],
          [61.805977, 25.725391],
          [61.806121, 25.725493],
          [61.806142, 25.72558],
          [61.806246, 25.725632],
          [61.80631, 25.725919],
          [61.806464, 25.72618],
          [61.806511, 25.726315],
          [61.806462, 25.726434],
          [61.806357, 25.72664],
          [61.806126, 25.726909],
          [61.806136, 25.727008],
          [61.80621, 25.727095],
          [61.806055, 25.727306],
          [61.805884, 25.727425],
          [61.805849, 25.727527],
          [61.806084, 25.727833],
          [61.806208, 25.728177],
          [61.806142, 25.728297],
          [61.806179, 25.728401],
          [61.806076, 25.728606],
          [61.806345, 25.728683],
          [61.806413, 25.728746],
          [61.806486, 25.728914],
          [61.806541, 25.728932],
          [61.806541, 25.729148],
          [61.806711, 25.729404],
          [61.806572, 25.729403],
          [61.806553, 25.729463],
          [61.806482, 25.729487],
          [61.806385, 25.729708],
          [61.806112, 25.729763],
          [61.806168, 25.729857],
          [61.806117, 25.729969],
          [61.80608, 25.730008],
          [61.805882, 25.730001],
          [61.805734, 25.73022],
          [61.805636, 25.730436],
          [61.805667, 25.730806],
          [61.805561, 25.730968],
          [61.805533, 25.731121],
          [61.80562, 25.731216],
          [61.805395, 25.731389],
          [61.805443, 25.731665],
          [61.805419, 25.731805],
          [61.80563, 25.73192],
          [61.806145, 25.731792],
          [61.806191, 25.731873],
          [61.806464, 25.73201],
          [61.806828, 25.731906],
          [61.806858, 25.731837],
          [61.807327, 25.731766],
          [61.807539, 25.731855],
          [61.80747, 25.732151],
          [61.807598, 25.732368],
          [61.808077, 25.732384],
          [61.808159, 25.732351],
          [61.80819, 25.732268],
          [61.808411, 25.732196],
          [61.808546, 25.732421],
          [61.808485, 25.73261],
          [61.80877, 25.732801],
          [61.808842, 25.733018],
          [61.80894, 25.733057],
          [61.808958, 25.733186],
          [61.808896, 25.73337],
          [61.808667, 25.733504],
          [61.808692, 25.733589],
          [61.808653, 25.733676],
          [61.808749, 25.733802],
          [61.808694, 25.733936],
          [61.808723, 25.73406],
          [61.80866, 25.734127],
          [61.808704, 25.734274],
          [61.808825, 25.734419],
          [61.808845, 25.734592],
          [61.808805, 25.734714],
          [61.80895, 25.734963],
          [61.808691, 25.735034],
          [61.808457, 25.735008],
          [61.808256, 25.735109],
          [61.808111, 25.735101],
          [61.807957, 25.735033],
          [61.807522, 25.735116],
          [61.807436, 25.735221],
          [61.807373, 25.735814],
          [61.807245, 25.735909],
          [61.807037, 25.736516],
          [61.807191, 25.736575],
          [61.807466, 25.736942],
          [61.807474, 25.737032],
          [61.807547, 25.736993],
          [61.80765, 25.737006],
          [61.807979, 25.737133],
          [61.807956, 25.737287],
          [61.808049, 25.73728],
          [61.808354, 25.737414],
          [61.808649, 25.737737],
          [61.808635, 25.737828],
          [61.808565, 25.73789],
          [61.808829, 25.73798],
          [61.809152, 25.738181],
          [61.809354, 25.738173],
          [61.809429, 25.73823],
          [61.809681, 25.738249],
          [61.810249, 25.738512],
          [61.81038, 25.738407],
          [61.810587, 25.738342],
          [61.810606, 25.738245],
          [61.810676, 25.738186],
          [61.810969, 25.738137],
          [61.811427, 25.738161],
          [61.811925, 25.738317],
          [61.812134, 25.738325],
          [61.812145, 25.738382],
          [61.812369, 25.738518],
          [61.812564, 25.738533],
          [61.812764, 25.738406],
          [61.813535, 25.738392],
          [61.813853, 25.738014],
          [61.814065, 25.738066],
          [61.81421, 25.738043],
          [61.814344, 25.738101],
          [61.81463, 25.738091],
          [61.81485, 25.738258],
          [61.814924, 25.73843],
          [61.814891, 25.738528],
          [61.815135, 25.738729],
          [61.815152, 25.738859],
          [61.815402, 25.738843],
          [61.815566, 25.738922],
          [61.815789, 25.738841],
          [61.816181, 25.738896],
          [61.816349, 25.738807],
          [61.816701, 25.738814],
          [61.816982, 25.73898],
          [61.817112, 25.739219],
          [61.817378, 25.739381],
          [61.818191, 25.739263],
          [61.818347, 25.739395],
          [61.81844, 25.739388],
          [61.81855, 25.739477],
          [61.818713, 25.739497],
          [61.818875, 25.739436],
          [61.818951, 25.739357],
          [61.819055, 25.739341],
          [61.819494, 25.73935],
          [61.819736, 25.739455],
          [61.819948, 25.73938],
          [61.820139, 25.739398],
          [61.820309, 25.739471],
          [61.820617, 25.7394],
          [61.82102, 25.739439],
          [61.821186, 25.739387],
          [61.821452, 25.739417],
          [61.82176, 25.739335],
          [61.821891, 25.739426],
          [61.822247, 25.739441],
          [61.822311, 25.739528],
          [61.822745, 25.739646],
          [61.823004, 25.739629],
          [61.823296, 25.739523],
          [61.823772, 25.740255],
          [61.823967, 25.740456],
          [61.82431, 25.740536],
          [61.824543, 25.740724],
          [61.824705, 25.740773],
          [61.82471, 25.740911],
          [61.824811, 25.741038],
          [61.82483, 25.741314],
          [61.824987, 25.741397],
          [61.825062, 25.74155],
          [61.825391, 25.74178],
          [61.82562, 25.741787],
          [61.825611, 25.742065],
          [61.825685, 25.742247],
          [61.825801, 25.742461],
          [61.825967, 25.742568],
          [61.826022, 25.742739],
          [61.826102, 25.74271],
          [61.826243, 25.742854],
          [61.826457, 25.742906],
          [61.826787, 25.742862],
          [61.827323, 25.743285],
          [61.827669, 25.743433],
          [61.828174, 25.743482],
          [61.828391, 25.74358],
          [61.828684, 25.743564],
          [61.828785, 25.743515],
          [61.82901, 25.74359],
          [61.840067, 25.74484],
          [61.84325, 25.760278],
          [61.837583, 25.762889],
          [61.842071, 25.781864],
          [61.841903, 25.781763],
          [61.841368, 25.781775],
          [61.841197, 25.781815],
          [61.841065, 25.781746],
          [61.840907, 25.781726],
          [61.840691, 25.781751],
          [61.840375, 25.781583],
          [61.840291, 25.781581],
          [61.840139, 25.781606],
          [61.839987, 25.781736],
          [61.840011, 25.781764],
          [61.839781, 25.781805],
          [61.839603, 25.781888],
          [61.83933, 25.782108],
          [61.839136, 25.782157],
          [61.838946, 25.782017],
          [61.838838, 25.782053],
          [61.838687, 25.782207],
          [61.838212, 25.782455],
          [61.837705, 25.782845],
          [61.837716, 25.782889],
          [61.837496, 25.783107],
          [61.837479, 25.783254],
          [61.837324, 25.78333],
          [61.837376, 25.783488],
          [61.837273, 25.783636],
          [61.837361, 25.783739],
          [61.837305, 25.783763],
          [61.837327, 25.783898],
          [61.837171, 25.784],
          [61.837211, 25.784169],
          [61.83705, 25.78429],
          [61.837109, 25.784426],
          [61.83708, 25.784473],
          [61.837143, 25.784765],
          [61.837071, 25.784864],
          [61.837144, 25.785002],
          [61.83696, 25.785174],
          [61.83676, 25.785216],
          [61.836735, 25.785265],
          [61.836374, 25.785439],
          [61.836332, 25.785636],
          [61.836402, 25.785684],
          [61.836381, 25.785876],
          [61.836271, 25.786039],
          [61.836261, 25.786152],
          [61.836389, 25.786389],
          [61.836368, 25.786448],
          [61.836294, 25.786478],
          [61.836328, 25.786729],
          [61.836813, 25.786976],
          [61.83722, 25.786993],
          [61.837379, 25.787053],
          [61.837553, 25.787045],
          [61.837887, 25.787124],
          [61.837997, 25.787526],
          [61.837963, 25.78778],
          [61.837836, 25.787961],
          [61.837999, 25.788078],
          [61.83823, 25.788159],
          [61.838254, 25.788217],
          [61.838556, 25.788286],
          [61.839011, 25.788267],
          [61.839003, 25.788746],
          [61.839104, 25.788754],
          [61.839164, 25.788863],
          [61.839107, 25.788915],
          [61.839122, 25.788966],
          [61.838819, 25.789041],
          [61.838749, 25.789121],
          [61.838756, 25.789182],
          [61.838875, 25.789226],
          [61.838826, 25.789397],
          [61.83871, 25.78951],
          [61.838758, 25.789736],
          [61.83886, 25.789792],
          [61.83888, 25.789876],
          [61.838811, 25.790176],
          [61.838846, 25.790304],
          [61.838761, 25.790479],
          [61.83885, 25.79051],
          [61.838873, 25.790587],
          [61.838418, 25.79056],
          [61.838144, 25.790676],
          [61.837793, 25.790641],
          [61.837565, 25.790668],
          [61.837408, 25.790817],
          [61.836985, 25.791013],
          [61.836866, 25.791114],
          [61.836657, 25.791077],
          [61.836411, 25.790889],
          [61.836302, 25.790911],
          [61.836232, 25.791311],
          [61.836024, 25.791487],
          [61.835973, 25.791753],
          [61.836015, 25.791783],
          [61.835962, 25.791841],
          [61.835966, 25.791899],
          [61.836045, 25.791942],
          [61.835963, 25.791992],
          [61.83595, 25.792103],
          [61.835792, 25.792155],
          [61.835734, 25.792202],
          [61.835781, 25.792235],
          [61.835604, 25.792328],
          [61.835673, 25.792471],
          [61.835785, 25.792501],
          [61.835828, 25.7926],
          [61.835943, 25.792637],
          [61.835998, 25.792713],
          [61.835988, 25.792764],
          [61.835866, 25.792823],
          [61.835788, 25.793203],
          [61.836084, 25.793273],
          [61.836182, 25.793411],
          [61.836301, 25.793401],
          [61.836367, 25.793438],
          [61.836394, 25.793542],
          [61.836475, 25.793606],
          [61.83636, 25.793622],
          [61.836326, 25.793652],
          [61.836408, 25.793706],
          [61.836281, 25.793736],
          [61.836404, 25.794046],
          [61.836567, 25.794193],
          [61.836649, 25.794122],
          [61.836735, 25.794139],
          [61.836769, 25.794319],
          [61.836875, 25.794388],
          [61.836847, 25.794448],
          [61.836924, 25.794439],
          [61.83704, 25.794534],
          [61.836961, 25.79463],
          [61.836844, 25.794632],
          [61.83671, 25.79461],
          [61.836663, 25.794535],
          [61.836601, 25.794564],
          [61.836451, 25.794505],
          [61.836513, 25.794622],
          [61.836468, 25.794651],
          [61.836483, 25.794725],
          [61.836366, 25.794746],
          [61.836361, 25.79483],
          [61.836483, 25.79491],
          [61.836591, 25.795037],
          [61.836587, 25.795121],
          [61.836682, 25.795192],
          [61.836641, 25.795269],
          [61.836584, 25.79527],
          [61.836376, 25.79543],
          [61.836514, 25.795544],
          [61.836257, 25.795586],
          [61.836177, 25.79608],
          [61.835963, 25.796587],
          [61.836108, 25.796741],
          [61.836042, 25.796774],
          [61.836084, 25.796818],
          [61.836215, 25.796806],
          [61.836229, 25.796854],
          [61.836156, 25.796923],
          [61.836225, 25.797047],
          [61.83618, 25.797192],
          [61.836313, 25.797421],
          [61.836516, 25.797561],
          [61.836717, 25.797636],
          [61.836807, 25.79762],
          [61.8368, 25.797726],
          [61.836889, 25.79777],
          [61.836574, 25.79794],
          [61.836583, 25.79801],
          [61.83648, 25.798168],
          [61.836394, 25.79819],
          [61.836312, 25.798325],
          [61.836384, 25.79838],
          [61.836235, 25.798511],
          [61.836337, 25.798546],
          [61.836392, 25.798663],
          [61.836739, 25.798743],
          [61.837032, 25.799335],
          [61.837079, 25.799345],
          [61.837146, 25.799504],
          [61.837129, 25.79957],
          [61.837304, 25.799631],
          [61.837367, 25.799708],
          [61.837629, 25.799731],
          [61.83788, 25.799679],
          [61.837908, 25.799609],
          [61.838045, 25.799582],
          [61.838076, 25.799537],
          [61.838652, 25.799424],
          [61.838878, 25.799266],
          [61.838946, 25.799126],
          [61.839258, 25.799017],
          [61.839335, 25.798952],
          [61.839621, 25.798931],
          [61.839679, 25.79902],
          [61.839869, 25.799051],
          [61.840254, 25.799021],
          [61.840412, 25.798898],
          [61.840816, 25.798863],
          [61.841025, 25.798777],
          [61.841244, 25.798781],
          [61.841278, 25.79872],
          [61.841757, 25.798502],
          [61.8418, 25.798494],
          [61.84182, 25.798569],
          [61.84196, 25.79866],
          [61.842088, 25.798669],
          [61.842344, 25.798563],
          [61.842568, 25.798572],
          [61.842941, 25.798364],
          [61.843086, 25.798498],
          [61.843101, 25.798667],
          [61.843335, 25.798804],
          [61.843349, 25.798871],
          [61.843489, 25.798962],
          [61.843625, 25.798963],
          [61.843706, 25.79912],
          [61.843912, 25.799238],
          [61.843946, 25.799353],
          [61.844098, 25.799515],
          [61.844219, 25.799575],
          [61.844247, 25.799811],
          [61.844301, 25.799859],
          [61.844324, 25.800082],
          [61.844367, 25.800107],
          [61.844338, 25.800154],
          [61.844424, 25.800194],
          [61.844439, 25.800268],
          [61.844707, 25.800401],
          [61.844817, 25.800407],
          [61.844814, 25.800655],
          [61.84492, 25.800843],
          [61.844933, 25.800979],
          [61.844773, 25.801182],
          [61.844816, 25.801225],
          [61.844787, 25.801428],
          [61.844688, 25.801675],
          [61.844657, 25.801975],
          [61.844688, 25.802178],
          [61.844972, 25.802261],
          [61.845079, 25.802455],
          [61.845108, 25.802735],
          [61.845223, 25.802866],
          [61.84559, 25.803113],
          [61.845814, 25.803123],
          [61.845966, 25.803252],
          [61.846209, 25.80334],
          [61.846596, 25.803602],
          [61.84681, 25.803965],
          [61.846831, 25.804149],
          [61.846759, 25.804313],
          [61.846793, 25.80449],
          [61.846861, 25.804515],
          [61.84689, 25.804616],
          [61.847192, 25.804903],
          [61.847176, 25.805063],
          [61.846902, 25.805219],
          [61.8468, 25.805416],
          [61.846668, 25.805546],
          [61.846788, 25.805585],
          [61.846818, 25.805848],
          [61.846902, 25.806065],
          [61.84681, 25.806346],
          [61.846849, 25.806448],
          [61.846811, 25.806485],
          [61.846815, 25.806619],
          [61.846733, 25.806647],
          [61.846929, 25.807213],
          [61.846869, 25.80739],
          [61.846789, 25.807553],
          [61.846608, 25.80765],
          [61.846491, 25.807771],
          [61.846336, 25.807857],
          [61.846137, 25.807871],
          [61.846316, 25.808002],
          [61.84639, 25.808204],
          [61.846378, 25.808278],
          [61.846231, 25.808374],
          [61.846265, 25.808581],
          [61.846512, 25.808521],
          [61.846547, 25.808575],
          [61.846829, 25.808587],
          [61.84684, 25.808647],
          [61.846757, 25.808729],
          [61.84675, 25.808807],
          [61.846599, 25.808857],
          [61.84665, 25.808915],
          [61.8466, 25.808987],
          [61.846686, 25.80912],
          [61.846613, 25.809177],
          [61.846699, 25.809438],
          [61.846913, 25.809735],
          [61.846649, 25.80978],
          [61.846545, 25.809933],
          [61.846596, 25.810379],
          [61.846666, 25.810572],
          [61.846798, 25.810732],
          [61.84679, 25.810889],
          [61.846668, 25.810936],
          [61.846516, 25.81116],
          [61.846437, 25.811165],
          [61.846427, 25.81125],
          [61.846111, 25.811449],
          [61.845961, 25.811492],
          [61.845863, 25.811662],
          [61.845873, 25.811881],
          [61.845759, 25.811952],
          [61.845912, 25.812133],
          [61.845859, 25.812187],
          [61.845925, 25.812344],
          [61.845886, 25.812419],
          [61.845759, 25.812467],
          [61.845704, 25.812588],
          [61.845793, 25.812797],
          [61.846067, 25.813017],
          [61.846138, 25.813192],
          [61.846075, 25.813342],
          [61.84589, 25.813352],
          [61.845906, 25.813412],
          [61.845808, 25.813561],
          [61.84578, 25.813713],
          [61.845909, 25.813852],
          [61.846096, 25.813946],
          [61.846134, 25.814291],
          [61.846319, 25.814386],
          [61.846258, 25.814408],
          [61.846251, 25.814486],
          [61.846383, 25.814502],
          [61.846522, 25.81485],
          [61.846703, 25.815112],
          [61.846724, 25.815264],
          [61.847332, 25.815175],
          [61.847797, 25.815277],
          [61.848113, 25.815242],
          [61.848266, 25.815161],
          [61.84849, 25.8152],
          [61.84883, 25.815345],
          [61.849085, 25.81535],
          [61.849423, 25.815441],
          [61.84955, 25.815531],
          [61.849873, 25.815541],
          [61.849988, 25.815631],
          [61.85002, 25.815725],
          [61.850305, 25.815929],
          [61.850403, 25.816143],
          [61.851069, 25.81633],
          [61.851426, 25.816329],
          [61.851539, 25.816431],
          [61.851304, 25.816626],
          [61.851198, 25.817077],
          [61.851104, 25.817086],
          [61.850997, 25.817166],
          [61.85099, 25.817251],
          [61.850863, 25.817359],
          [61.8506, 25.817439],
          [61.850442, 25.817643],
          [61.85055, 25.818092],
          [61.850537, 25.818236],
          [61.850051, 25.818539],
          [61.849755, 25.81881],
          [61.849299, 25.818977],
          [61.849036, 25.818983],
          [61.849011, 25.819098],
          [61.84894, 25.819162],
          [61.849067, 25.819302],
          [61.849097, 25.819419],
          [61.849035, 25.819416],
          [61.848948, 25.819541],
          [61.849392, 25.819635],
          [61.84935, 25.819851],
          [61.849188, 25.81991],
          [61.849137, 25.820151],
          [61.849065, 25.820243],
          [61.848797, 25.820259],
          [61.848778, 25.820429],
          [61.848558, 25.820728],
          [61.848388, 25.820865],
          [61.84835, 25.820967],
          [61.848396, 25.821212],
          [61.848534, 25.821275],
          [61.848451, 25.821447],
          [61.848539, 25.821506],
          [61.848552, 25.821675],
          [61.848771, 25.821787],
          [61.848764, 25.821978],
          [61.848895, 25.822397],
          [61.848867, 25.82259],
          [61.848463, 25.822813],
          [61.848233, 25.823],
          [61.848028, 25.822979],
          [61.847926, 25.823058],
          [61.847798, 25.823039],
          [61.84759, 25.82316],
          [61.846935, 25.823342],
          [61.846524, 25.823399],
          [61.84642, 25.823379],
          [61.84632, 25.82357],
          [61.846065, 25.823649],
          [61.845693, 25.823683],
          [61.845138, 25.823861],
          [61.844642, 25.823841],
          [61.84428, 25.823929],
          [61.844164, 25.824332],
          [61.844195, 25.824443],
          [61.844141, 25.824455],
          [61.843582, 25.824483],
          [61.843422, 25.824588],
          [61.842487, 25.824661],
          [61.84218, 25.82479],
          [61.84205, 25.824772],
          [61.841957, 25.824651],
          [61.84176, 25.824654],
          [61.841396, 25.824752],
          [61.841221, 25.824857],
          [61.841111, 25.824861],
          [61.840878, 25.825002],
          [61.840203, 25.825163],
          [61.839823, 25.825166],
          [61.839746, 25.825235],
          [61.839617, 25.825245],
          [61.839117, 25.824713],
          [61.838888, 25.824756],
          [61.838852, 25.824907],
          [61.838888, 25.824916],
          [61.838794, 25.825091],
          [61.838327, 25.825369],
          [61.838369, 25.825461],
          [61.838063, 25.825744],
          [61.838201, 25.826224],
          [61.838304, 25.82644],
          [61.838406, 25.82653],
          [61.838367, 25.826623],
          [61.83832, 25.826634],
          [61.838401, 25.826812],
          [61.838343, 25.826952],
          [61.83841, 25.827225],
          [61.838339, 25.827405],
          [61.838423, 25.82758],
          [61.838423, 25.82774],
          [61.838717, 25.828234],
          [61.838936, 25.828865],
          [61.838539, 25.828982],
          [61.838457, 25.829055],
          [61.838172, 25.829097],
          [61.838164, 25.829316],
          [61.838228, 25.829358],
          [61.838216, 25.829444],
          [61.838299, 25.829554],
          [61.838191, 25.829629],
          [61.838059, 25.829834],
          [61.837965, 25.829853],
          [61.837917, 25.829996],
          [61.838256, 25.830111],
          [61.839036, 25.830231],
          [61.839375, 25.830349],
          [61.839562, 25.830334],
          [61.839935, 25.830411],
          [61.840102, 25.830555],
          [61.840166, 25.830773],
          [61.840597, 25.830865],
          [61.841338, 25.830834],
          [61.841395, 25.830868],
          [61.841376, 25.831006],
          [61.841701, 25.831056],
          [61.84174, 25.831105],
          [61.842015, 25.831067],
          [61.841989, 25.831807],
          [61.842064, 25.832016],
          [61.842089, 25.832331],
          [61.841885, 25.832567],
          [61.841917, 25.832691],
          [61.841749, 25.832949],
          [61.841757, 25.833098],
          [61.841583, 25.833396],
          [61.841627, 25.83352],
          [61.840952, 25.833483],
          [61.840892, 25.833647],
          [61.841023, 25.833795],
          [61.841658, 25.833941],
          [61.841795, 25.834229],
          [61.841721, 25.834231],
          [61.841771, 25.834505],
          [61.841745, 25.834748],
          [61.841922, 25.834747],
          [61.842039, 25.835034],
          [61.842115, 25.835034],
          [61.842178, 25.835109],
          [61.842134, 25.835175],
          [61.842454, 25.835287],
          [61.842582, 25.835266],
          [61.842764, 25.835565],
          [61.842845, 25.835574],
          [61.842844, 25.835611],
          [61.843123, 25.835779],
          [61.843254, 25.835769],
          [61.843437, 25.835878],
          [61.843429, 25.836125],
          [61.843613, 25.836495],
          [61.84359, 25.836616],
          [61.842082, 25.836635],
          [61.841071, 25.836724],
          [61.840196, 25.836704],
          [61.839885, 25.836758],
          [61.840071, 25.836843],
          [61.840195, 25.837057],
          [61.840464, 25.837139],
          [61.840566, 25.83732],
          [61.841422, 25.837437],
          [61.841824, 25.837423],
          [61.842254, 25.837509],
          [61.842857, 25.837564],
          [61.842858, 25.837757],
          [61.842794, 25.837894],
          [61.84312, 25.838137],
          [61.843087, 25.838259],
          [61.843124, 25.838435],
          [61.843226, 25.838514],
          [61.843035, 25.838811],
          [61.843115, 25.838877],
          [61.843086, 25.839184],
          [61.843044, 25.839215],
          [61.843138, 25.839362],
          [61.843023, 25.839668],
          [61.842825, 25.839699],
          [61.842586, 25.83951],
          [61.842425, 25.839465],
          [61.842408, 25.839407],
          [61.84232, 25.839407],
          [61.84227, 25.839323],
          [61.842213, 25.839331],
          [61.842137, 25.83944],
          [61.841719, 25.839403],
          [61.841652, 25.839339],
          [61.841538, 25.839376],
          [61.841472, 25.839348],
          [61.841327, 25.839207],
          [61.841125, 25.839239],
          [61.841102, 25.839192],
          [61.841023, 25.839204],
          [61.840896, 25.839136],
          [61.840777, 25.839162],
          [61.8406, 25.839312],
          [61.840448, 25.839369],
          [61.840035, 25.839177],
          [61.839962, 25.839067],
          [61.839702, 25.838968],
          [61.839582, 25.838859],
          [61.839089, 25.838954],
          [61.838811, 25.838831],
          [61.838689, 25.838845],
          [61.838415, 25.838962],
          [61.838219, 25.838925],
          [61.83777, 25.838989],
          [61.837612, 25.839113],
          [61.837239, 25.839059],
          [61.836997, 25.838965],
          [61.836848, 25.839004],
          [61.836563, 25.838955],
          [61.836171, 25.839134],
          [61.836008, 25.839115],
          [61.835946, 25.839047],
          [61.835858, 25.83923],
          [61.835955, 25.839277],
          [61.835999, 25.839439],
          [61.835945, 25.839525],
          [61.835858, 25.839552],
          [61.835436, 25.839502],
          [61.83532, 25.839753],
          [61.835061, 25.839813],
          [61.835162, 25.83992],
          [61.834935, 25.839991],
          [61.834835, 25.839923],
          [61.834526, 25.839941],
          [61.83433, 25.840313],
          [61.834151, 25.840537],
          [61.834084, 25.840732],
          [61.834124, 25.840799],
          [61.834008, 25.84086],
          [61.833968, 25.841013],
          [61.834022, 25.841182],
          [61.833927, 25.841215],
          [61.833886, 25.841181],
          [61.833775, 25.841191],
          [61.833417, 25.841439],
          [61.833009, 25.8415],
          [61.832854, 25.841732],
          [61.832659, 25.841885],
          [61.832635, 25.842024],
          [61.832554, 25.842089],
          [61.832341, 25.842097],
          [61.831925, 25.842339],
          [61.831456, 25.842224],
          [61.831291, 25.842121],
          [61.830956, 25.842019],
          [61.830895, 25.841928],
          [61.830449, 25.841908],
          [61.830102, 25.841968],
          [61.830035, 25.841859],
          [61.829928, 25.841795],
          [61.829676, 25.841773],
          [61.829522, 25.84165],
          [61.828793, 25.841676],
          [61.828078, 25.841347],
          [61.827572, 25.841543],
          [61.827511, 25.841609],
          [61.826783, 25.841325],
          [61.826212, 25.841228],
          [61.826034, 25.841102],
          [61.824968, 25.840674],
          [61.824906, 25.840588],
          [61.824302, 25.840545],
          [61.824237, 25.840045],
          [61.8243, 25.839978],
          [61.824101, 25.839884],
          [61.82398, 25.839863],
          [61.823846, 25.839966],
          [61.823725, 25.839935],
          [61.82355, 25.839987],
          [61.823367, 25.840101],
          [61.823172, 25.840159],
          [61.823199, 25.84066],
          [61.822965, 25.840975],
          [61.822473, 25.841318],
          [61.82248, 25.841692],
          [61.822413, 25.841702],
          [61.822412, 25.841841],
          [61.822298, 25.842008],
          [61.822329, 25.842053],
          [61.822152, 25.842329],
          [61.822076, 25.842852],
          [61.822357, 25.842978],
          [61.822345, 25.843031],
          [61.822269, 25.843137],
          [61.821968, 25.843284],
          [61.821753, 25.84326],
          [61.821562, 25.843621],
          [61.821585, 25.843772],
          [61.821634, 25.84378],
          [61.821594, 25.843875],
          [61.821679, 25.844045],
          [61.821537, 25.844207],
          [61.821397, 25.844156],
          [61.821356, 25.844182],
          [61.821293, 25.844312],
          [61.821359, 25.844539],
          [61.821279, 25.844766],
          [61.821351, 25.845152],
          [61.821349, 25.84601],
          [61.821098, 25.846275],
          [61.820989, 25.846501],
          [61.820958, 25.846641],
          [61.821008, 25.846873],
          [61.82094, 25.847097],
          [61.820969, 25.8472],
          [61.820927, 25.847261],
          [61.820928, 25.847537],
          [61.820747, 25.848045],
          [61.820734, 25.848411],
          [61.820661, 25.848644],
          [61.820479, 25.848929],
          [61.820502, 25.849016],
          [61.820408, 25.849211],
          [61.820531, 25.849252],
          [61.820597, 25.849333],
          [61.820785, 25.849368],
          [61.821018, 25.849498],
          [61.821125, 25.849365],
          [61.821314, 25.849267],
          [61.821483, 25.849271],
          [61.821776, 25.849341],
          [61.822412, 25.849362],
          [61.822722, 25.849515],
          [61.823029, 25.849562],
          [61.823235, 25.849671],
          [61.823345, 25.849811],
          [61.823423, 25.849809],
          [61.823423, 25.849851],
          [61.823588, 25.849847],
          [61.823988, 25.849935],
          [61.82437, 25.849818],
          [61.824421, 25.849894],
          [61.824432, 25.850066],
          [61.824712, 25.850364],
          [61.824794, 25.850377],
          [61.824831, 25.850503],
          [61.825541, 25.850816],
          [61.825642, 25.850951],
          [61.825843, 25.850977],
          [61.825844, 25.851095],
          [61.825907, 25.851089],
          [61.826087, 25.851209],
          [61.82605, 25.851262],
          [61.826061, 25.851532],
          [61.825983, 25.851703],
          [61.825792, 25.8519],
          [61.825796, 25.851989],
          [61.825709, 25.852005],
          [61.825708, 25.852098],
          [61.825915, 25.85223],
          [61.825936, 25.852298],
          [61.82603, 25.85236],
          [61.826144, 25.852377],
          [61.826291, 25.852284],
          [61.826402, 25.852569],
          [61.82664, 25.852566],
          [61.826802, 25.852507],
          [61.82702, 25.852614],
          [61.827038, 25.852564],
          [61.827111, 25.852574],
          [61.827149, 25.852514],
          [61.827459, 25.852438],
          [61.827688, 25.852466],
          [61.827947, 25.852401],
          [61.827969, 25.852432],
          [61.828411, 25.852374],
          [61.828467, 25.852007],
          [61.828857, 25.852025],
          [61.829234, 25.852245],
          [61.829546, 25.852344],
          [61.829777, 25.852359],
          [61.830074, 25.852468],
          [61.830257, 25.852616],
          [61.83062, 25.852585],
          [61.830732, 25.852704],
          [61.83069, 25.85286],
          [61.830721, 25.85291],
          [61.830662, 25.853004],
          [61.830692, 25.85308],
          [61.830628, 25.853099],
          [61.830682, 25.85314],
          [61.830657, 25.853242],
          [61.830707, 25.853381],
          [61.830612, 25.853533],
          [61.830652, 25.853669],
          [61.831261, 25.853889],
          [61.831358, 25.854036],
          [61.831475, 25.853958],
          [61.831635, 25.853929],
          [61.831774, 25.854034],
          [61.83202, 25.854097],
          [61.832041, 25.854654],
          [61.832099, 25.85468],
          [61.832058, 25.854727],
          [61.832198, 25.854973],
          [61.831834, 25.85528],
          [61.831574, 25.855713],
          [61.831391, 25.855924],
          [61.831471, 25.856077],
          [61.831522, 25.856091],
          [61.831517, 25.856206],
          [61.831479, 25.85622],
          [61.83144, 25.856402],
          [61.831321, 25.856491],
          [61.831305, 25.856654],
          [61.831058, 25.856696],
          [61.830924, 25.856639],
          [61.830498, 25.856643],
          [61.830373, 25.856438],
          [61.830287, 25.856398],
          [61.830127, 25.856337],
          [61.829882, 25.856374],
          [61.829951, 25.856644],
          [61.829923, 25.856693],
          [61.830007, 25.856852],
          [61.829951, 25.856941],
          [61.830061, 25.857102],
          [61.830087, 25.857245],
          [61.82987, 25.857535],
          [61.829622, 25.857582],
          [61.829764, 25.857767],
          [61.829807, 25.857977],
          [61.829856, 25.857996],
          [61.829908, 25.858135],
          [61.830313, 25.858355],
          [61.83035, 25.858501],
          [61.830505, 25.858552],
          [61.830572, 25.858635],
          [61.830666, 25.858911],
          [61.830728, 25.858962],
          [61.830693, 25.859151],
          [61.830634, 25.859201],
          [61.830726, 25.859271],
          [61.830708, 25.85933],
          [61.830997, 25.859394],
          [61.831034, 25.859528],
          [61.83122, 25.859488],
          [61.831407, 25.859874],
          [61.831231, 25.860006],
          [61.831216, 25.860423],
          [61.831134, 25.86041],
          [61.831155, 25.860476],
          [61.830986, 25.860451],
          [61.830987, 25.860573],
          [61.831038, 25.860569],
          [61.831068, 25.860684],
          [61.831495, 25.86077],
          [61.831574, 25.860851],
          [61.831322, 25.861112],
          [61.831333, 25.861159],
          [61.831121, 25.861308],
          [61.830809, 25.861346],
          [61.830838, 25.861572],
          [61.830968, 25.8616],
          [61.830971, 25.861655],
          [61.831082, 25.861663],
          [61.831185, 25.861746],
          [61.831197, 25.861837],
          [61.831053, 25.861877],
          [61.831076, 25.861993],
          [61.830994, 25.862295],
          [61.831043, 25.862356],
          [61.830995, 25.86255],
          [61.831104, 25.862633],
          [61.83114, 25.86283],
          [61.831521, 25.862855],
          [61.832033, 25.86282],
          [61.831979, 25.862977],
          [61.831957, 25.863509],
          [61.831988, 25.86368],
          [61.832065, 25.863777],
          [61.831864, 25.863869],
          [61.831903, 25.86401],
          [61.832077, 25.8641],
          [61.832145, 25.864264],
          [61.832088, 25.864459],
          [61.832103, 25.864586],
          [61.83216, 25.864701],
          [61.832178, 25.864925],
          [61.8323, 25.865191],
          [61.83224, 25.865355],
          [61.832516, 25.865375],
          [61.832992, 25.86528],
          [61.834603, 25.86514],
          [61.834604, 25.865316],
          [61.834704, 25.865572],
          [61.83472, 25.865782],
          [61.835168, 25.865883],
          [61.83559, 25.86591],
          [61.835565, 25.86604],
          [61.835686, 25.866324],
          [61.835637, 25.866512],
          [61.835804, 25.866756],
          [61.835806, 25.866853],
          [61.835959, 25.866872],
          [61.836843, 25.866728],
          [61.837555, 25.86667],
          [61.837834, 25.866726],
          [61.838534, 25.866679],
          [61.838752, 25.866622],
          [61.838945, 25.866643],
          [61.839591, 25.866518],
          [61.839918, 25.866543],
          [61.841004, 25.866482],
          [61.841587, 25.86636],
          [61.841881, 25.866144],
          [61.84276, 25.866145],
          [61.843375, 25.866011],
          [61.84355, 25.866086],
          [61.843555, 25.866336],
          [61.843479, 25.866619],
          [61.843607, 25.866976],
          [61.843549, 25.867148],
          [61.843568, 25.867228],
          [61.843208, 25.86733],
          [61.843082, 25.867427],
          [61.842635, 25.8676],
          [61.842622, 25.867648],
          [61.842388, 25.867765],
          [61.842281, 25.8679],
          [61.842308, 25.868159],
          [61.842485, 25.868345],
          [61.842771, 25.868447],
          [61.842821, 25.868702],
          [61.84291, 25.868784],
          [61.842676, 25.868983],
          [61.842415, 25.86909],
          [61.842322, 25.869181],
          [61.842026, 25.869244],
          [61.841514, 25.869266],
          [61.841575, 25.869619],
          [61.841772, 25.869681],
          [61.841866, 25.869808],
          [61.841895, 25.870224],
          [61.841829, 25.870487],
          [61.841851, 25.87058],
          [61.841948, 25.870637],
          [61.841901, 25.870789],
          [61.842009, 25.870997],
          [61.841995, 25.871231],
          [61.841843, 25.871254],
          [61.841652, 25.871659],
          [61.84168, 25.871839],
          [61.840912, 25.871889],
          [61.840731, 25.871833],
          [61.840361, 25.871853],
          [61.840241, 25.871805],
          [61.839971, 25.871789],
          [61.839046, 25.871783],
          [61.83887, 25.87187],
          [61.838743, 25.872106],
          [61.838775, 25.872555],
          [61.838891, 25.872984],
          [61.839023, 25.87321],
          [61.839369, 25.873566],
          [61.839722, 25.874119],
          [61.839935, 25.874352],
          [61.840005, 25.87464],
          [61.840058, 25.874686],
          [61.840173, 25.874596],
          [61.840274, 25.874587],
          [61.840306, 25.874702],
          [61.840227, 25.874888],
          [61.840274, 25.875077],
          [61.84119, 25.875112],
          [61.841783, 25.875201],
          [61.842067, 25.875294],
          [61.842077, 25.875649],
          [61.841942, 25.875724],
          [61.841992, 25.875961],
          [61.841853, 25.876227],
          [61.841843, 25.876456],
          [61.84197, 25.877036],
          [61.84219, 25.877674],
          [61.842866, 25.877821],
          [61.843375, 25.877865],
          [61.843816, 25.877832],
          [61.843918, 25.877763],
          [61.844233, 25.87778],
          [61.844454, 25.877713],
          [61.844811, 25.8777],
          [61.844961, 25.877747],
          [61.845441, 25.877712],
          [61.84566, 25.877761],
          [61.845985, 25.877717],
          [61.846345, 25.877824],
          [61.846656, 25.877759],
          [61.846695, 25.877821],
          [61.847038, 25.877894],
          [61.847105, 25.877966],
          [61.847239, 25.87797],
          [61.847221, 25.878019],
          [61.847274, 25.878037],
          [61.847459, 25.878046],
          [61.847611, 25.878125],
          [61.847927, 25.878124],
          [61.848081, 25.878254],
          [61.848589, 25.878381],
          [61.848947, 25.878391],
          [61.849251, 25.879093],
          [61.849947, 25.8792],
          [61.850508, 25.879131],
          [61.851137, 25.879162],
          [61.851296, 25.879292],
          [61.851543, 25.879252],
          [61.851662, 25.879296],
          [61.851977, 25.87929],
          [61.85235, 25.879344],
          [61.852941, 25.87953],
          [61.853103, 25.879459],
          [61.853363, 25.87958],
          [61.853379, 25.879776],
          [61.85358, 25.879559],
          [61.85381, 25.87955],
          [61.853907, 25.87919],
          [61.854343, 25.879177],
          [61.854437, 25.879114],
          [61.854375, 25.879018],
          [61.854394, 25.878973],
          [61.854553, 25.878875],
          [61.854659, 25.878884],
          [61.854967, 25.879019],
          [61.85516, 25.879021],
          [61.855715, 25.879218],
          [61.855963, 25.879225],
          [61.856382, 25.879368],
          [61.856569, 25.879367],
          [61.856725, 25.879297],
          [61.856764, 25.879397],
          [61.85707, 25.879523],
          [61.857163, 25.87952],
          [61.857364, 25.879743],
          [61.857517, 25.879804],
          [61.857591, 25.879935],
          [61.857565, 25.880301],
          [61.857872, 25.880473],
          [61.858113, 25.880472],
          [61.858331, 25.880558],
          [61.858433, 25.880697],
          [61.858541, 25.880506],
          [61.858618, 25.880437],
          [61.858742, 25.880419],
          [61.859002, 25.880535],
          [61.859107, 25.880627],
          [61.859336, 25.880614],
          [61.859702, 25.880678],
          [61.859837, 25.88077],
          [61.860155, 25.880862],
          [61.860205, 25.880931],
          [61.860642, 25.880983],
          [61.860703, 25.88104],
          [61.860907, 25.880983],
          [61.860863, 25.881041],
          [61.8611, 25.881082],
          [61.861556, 25.881343],
          [61.861738, 25.881338],
          [61.861749, 25.881429],
          [61.862064, 25.881465],
          [61.862165, 25.881414],
          [61.86279, 25.881655],
          [61.863029, 25.881542],
          [61.863488, 25.88152],
          [61.86365, 25.881454],
          [61.863783, 25.881389],
          [61.863968, 25.88117],
          [61.864238, 25.881061],
          [61.864417, 25.880501],
          [61.864382, 25.880354],
          [61.864424, 25.880198],
          [61.864611, 25.880216],
          [61.865439, 25.880128],
          [61.865617, 25.88017],
          [61.865825, 25.880114],
          [61.866066, 25.880173],
          [61.866545, 25.880175],
          [61.866968, 25.880271],
          [61.867197, 25.880258],
          [61.867641, 25.880382],
          [61.867833, 25.880362],
          [61.868127, 25.880522],
          [61.868283, 25.880494],
          [61.868282, 25.88055],
          [61.868546, 25.880456],
          [61.868809, 25.880525],
          [61.869165, 25.880508],
          [61.86948, 25.880609],
          [61.869597, 25.880727],
          [61.869577, 25.880809],
          [61.869758, 25.88093],
          [61.869933, 25.881168],
          [61.870112, 25.881173],
          [61.870498, 25.881052],
          [61.871112, 25.880956],
          [61.871506, 25.880974],
          [61.871949, 25.881159],
          [61.872049, 25.881122],
          [61.872436, 25.88115],
          [61.872718, 25.881072],
          [61.872935, 25.881195],
          [61.873212, 25.881095],
          [61.873429, 25.881158],
          [61.873661, 25.881158],
          [61.874554, 25.881114],
          [61.874676, 25.881069],
          [61.874784, 25.880938],
          [61.874862, 25.880414],
          [61.874759, 25.880103],
          [61.874788, 25.879996],
          [61.875417, 25.879837],
          [61.875721, 25.87981],
          [61.875918, 25.879682],
          [61.876264, 25.879643],
          [61.876584, 25.879668],
          [61.876694, 25.879739],
          [61.877056, 25.879794],
          [61.877384, 25.879897],
          [61.877438, 25.879873],
          [61.877529, 25.879945],
          [61.877654, 25.879862],
          [61.878332, 25.880078],
          [61.878572, 25.880039],
          [61.878899, 25.879695],
          [61.879149, 25.879659],
          [61.879456, 25.87984],
          [61.879644, 25.880175],
          [61.879748, 25.880258],
          [61.880263, 25.880433],
          [61.880301, 25.880551],
          [61.880407, 25.880495],
          [61.881123, 25.880639],
          [61.881165, 25.88071],
          [61.881383, 25.88075],
          [61.881421, 25.880932],
          [61.881629, 25.880992],
          [61.881768, 25.881096],
          [61.881861, 25.881089],
          [61.8823, 25.881241],
          [61.882655, 25.881269],
          [61.882814, 25.881223],
          [61.882907, 25.88128],
          [61.88317, 25.881297],
          [61.883332, 25.881185],
          [61.883745, 25.881287],
          [61.884346, 25.881576],
          [61.884519, 25.881614],
          [61.884613, 25.881782],
          [61.884787, 25.881866],
          [61.885551, 25.882126],
          [61.885696, 25.882276],
          [61.885747, 25.882409],
          [61.88602, 25.882384],
          [61.886852, 25.882558],
          [61.887075, 25.882555],
          [61.887416, 25.882627],
          [61.887586, 25.882611],
          [61.887678, 25.882557],
          [61.887899, 25.882564],
          [61.888052, 25.882451],
          [61.88813, 25.882299],
          [61.888119, 25.881916],
          [61.888515, 25.881993],
          [61.888665, 25.881888],
          [61.888947, 25.88222],
          [61.889089, 25.882291],
          [61.889251, 25.882304],
          [61.889355, 25.882262],
          [61.889445, 25.882302],
          [61.889619, 25.882474],
          [61.889737, 25.882517],
          [61.889873, 25.882765],
          [61.890066, 25.882928],
          [61.890354, 25.882928],
          [61.890429, 25.882993],
          [61.890539, 25.882964],
          [61.890722, 25.883199],
          [61.890657, 25.883303],
          [61.890728, 25.88335],
          [61.89069, 25.883421],
          [61.89075, 25.88353],
          [61.890675, 25.883802],
          [61.89055, 25.883884],
          [61.890398, 25.883893],
          [61.8903, 25.883961],
          [61.890161, 25.883986],
          [61.890053, 25.884098],
          [61.889827, 25.884152],
          [61.889433, 25.88455],
          [61.889122, 25.884748],
          [61.88899, 25.884924],
          [61.889008, 25.885106],
          [61.888952, 25.885166],
          [61.889184, 25.885706],
          [61.88934, 25.885835],
          [61.889235, 25.8859],
          [61.889346, 25.885926],
          [61.88917, 25.886257],
          [61.889478, 25.886465],
          [61.889563, 25.886823],
          [61.889709, 25.887083],
          [61.88966, 25.887147],
          [61.889713, 25.88729],
          [61.889851, 25.887348],
          [61.890157, 25.887607],
          [61.890263, 25.887616],
          [61.890291, 25.887819],
          [61.890255, 25.887899],
          [61.890208, 25.887907],
          [61.890254, 25.888019],
          [61.890245, 25.888339],
          [61.890625, 25.888409],
          [61.890708, 25.888547],
          [61.890644, 25.888595],
          [61.89078, 25.888686],
          [61.890808, 25.888783],
          [61.890883, 25.888723],
          [61.891131, 25.888716],
          [61.891267, 25.888788],
          [61.891361, 25.888785],
          [61.891458, 25.889114],
          [61.891521, 25.889102],
          [61.891634, 25.889175],
          [61.891496, 25.889439],
          [61.891535, 25.889773],
          [61.891496, 25.890029],
          [61.891294, 25.890102],
          [61.891158, 25.890556],
          [61.891011, 25.890568],
          [61.891013, 25.890701],
          [61.89053, 25.890594],
          [61.890418, 25.890794],
          [61.890469, 25.891159],
          [61.890206, 25.891122],
          [61.889887, 25.89099],
          [61.889743, 25.890993],
          [61.88972, 25.890919],
          [61.889599, 25.890931],
          [61.889535, 25.890873],
          [61.889302, 25.890897],
          [61.888957, 25.891037],
          [61.888799, 25.891],
          [61.888568, 25.891056],
          [61.888379, 25.891034],
          [61.888306, 25.890913],
          [61.888242, 25.890874],
          [61.888125, 25.890886],
          [61.888024, 25.890756],
          [61.887833, 25.890832],
          [61.88744, 25.890745],
          [61.887354, 25.890761],
          [61.887262, 25.890708],
          [61.887036, 25.890721],
          [61.886929, 25.890777],
          [61.886721, 25.890676],
          [61.886053, 25.890606],
          [61.884917, 25.890874],
          [61.884842, 25.89079],
          [61.884553, 25.890727],
          [61.884491, 25.890673],
          [61.884084, 25.89076],
          [61.884027, 25.890835],
          [61.883783, 25.890837],
          [61.883588, 25.890895],
          [61.883379, 25.890895],
          [61.883309, 25.890852],
          [61.882982, 25.890878],
          [61.882529, 25.890775],
          [61.88222, 25.890941],
          [61.881678, 25.891006],
          [61.881483, 25.890957],
          [61.880972, 25.891072],
          [61.880819, 25.891067],
          [61.88041, 25.890865],
          [61.880284, 25.890874],
          [61.880171, 25.890936],
          [61.879976, 25.890947],
          [61.879773, 25.891109],
          [61.879216, 25.891112],
          [61.878858, 25.89131],
          [61.87858, 25.891388],
          [61.878431, 25.891363],
          [61.878387, 25.891427],
          [61.878088, 25.891499],
          [61.878029, 25.891561],
          [61.877542, 25.891778],
          [61.877479, 25.891928],
          [61.877208, 25.892074],
          [61.876871, 25.892213],
          [61.876519, 25.89223],
          [61.876401, 25.893007],
          [61.876172, 25.893141],
          [61.875823, 25.893148],
          [61.875764, 25.89346],
          [61.875703, 25.893498],
          [61.875697, 25.893638],
          [61.875778, 25.893833],
          [61.875601, 25.89392],
          [61.875558, 25.894197],
          [61.875508, 25.894257],
          [61.875662, 25.894493],
          [61.874959, 25.894525],
          [61.874641, 25.894267],
          [61.874024, 25.894057],
          [61.873857, 25.893901],
          [61.873348, 25.894175],
          [61.873018, 25.894097],
          [61.872926, 25.894152],
          [61.872237, 25.894296],
          [61.872183, 25.894567],
          [61.872274, 25.894779],
          [61.871772, 25.894921],
          [61.871651, 25.894888],
          [61.87156, 25.895026],
          [61.870257, 25.898252],
          [61.869016, 25.899663],
          [61.868088, 25.904018],
          [61.868002, 25.905076],
          [61.867921, 25.905451],
          [61.867598, 25.905601],
          [61.867318, 25.905635],
          [61.866969, 25.905617],
          [61.866614, 25.905674],
          [61.866397, 25.905612],
          [61.865888, 25.905629],
          [61.865527, 25.905585],
          [61.8654, 25.905632],
          [61.865165, 25.906338],
          [61.865024, 25.906425],
          [61.864951, 25.906696],
          [61.865153, 25.907246],
          [61.865202, 25.907545],
          [61.865282, 25.907675],
          [61.865091, 25.907818],
          [61.865042, 25.907911],
          [61.8651, 25.907952],
          [61.865061, 25.908202],
          [61.864965, 25.908262],
          [61.864914, 25.908365],
          [61.864939, 25.908566],
          [61.865082, 25.908679],
          [61.865061, 25.908851],
          [61.865097, 25.908938],
          [61.865049, 25.908975],
          [61.865093, 25.909079],
          [61.86502, 25.909387],
          [61.864415, 25.909403],
          [61.864016, 25.90932],
          [61.863805, 25.909359],
          [61.863048, 25.909283],
          [61.862874, 25.909357],
          [61.862839, 25.909252],
          [61.862275, 25.90926],
          [61.862027, 25.909186],
          [61.861854, 25.909223],
          [61.861721, 25.909094],
          [61.86146, 25.909064],
          [61.861413, 25.90897],
          [61.861098, 25.909053],
          [61.860684, 25.909047],
          [61.860222, 25.908942],
          [61.859957, 25.908955],
          [61.85961, 25.90889],
          [61.859126, 25.908696],
          [61.859019, 25.908749],
          [61.858838, 25.908713],
          [61.858718, 25.909142],
          [61.858403, 25.909776],
          [61.858413, 25.909858],
          [61.85825, 25.909916],
          [61.858249, 25.91001],
          [61.858326, 25.910003],
          [61.858293, 25.910035],
          [61.858372, 25.910099],
          [61.858334, 25.910237],
          [61.858449, 25.910318],
          [61.85827, 25.910525],
          [61.85833, 25.910752],
          [61.858658, 25.910797],
          [61.858768, 25.910875],
          [61.858743, 25.910912],
          [61.858793, 25.910977],
          [61.858705, 25.910989],
          [61.858725, 25.911051],
          [61.858523, 25.911108],
          [61.858476, 25.911308],
          [61.858369, 25.911282],
          [61.858162, 25.911358],
          [61.858066, 25.911315],
          [61.857963, 25.911367],
          [61.857748, 25.911646],
          [61.857583, 25.911635],
          [61.857167, 25.911718],
          [61.857237, 25.911846],
          [61.857316, 25.912269],
          [61.857537, 25.912504],
          [61.857161, 25.912672],
          [61.857133, 25.912832],
          [61.857191, 25.912933],
          [61.857069, 25.912923],
          [61.856918, 25.91319],
          [61.856911, 25.913612],
          [61.85672, 25.91369],
          [61.856671, 25.914334],
          [61.85684, 25.914788],
          [61.856158, 25.914823],
          [61.855677, 25.914914],
          [61.8553, 25.915116],
          [61.855068, 25.915029],
          [61.854989, 25.915044],
          [61.854848, 25.914954],
          [61.854477, 25.91493],
          [61.854372, 25.914773],
          [61.854158, 25.914682],
          [61.853571, 25.914695],
          [61.853438, 25.914739],
          [61.853323, 25.914956],
          [61.853138, 25.914982],
          [61.85304, 25.914935],
          [61.852903, 25.914727],
          [61.852422, 25.914664],
          [61.851974, 25.914674],
          [61.851671, 25.914553],
          [61.851533, 25.914421],
          [61.851198, 25.9144],
          [61.851141, 25.914168],
          [61.851023, 25.914059],
          [61.850387, 25.914203],
          [61.849835, 25.914554],
          [61.849564, 25.914797],
          [61.849356, 25.914752],
          [61.849297, 25.914805],
          [61.848755, 25.914665],
          [61.848732, 25.91459],
          [61.848588, 25.91457],
          [61.84846, 25.914482],
          [61.84835, 25.914246],
          [61.848256, 25.914156],
          [61.84794, 25.914136],
          [61.847865, 25.914066],
          [61.847779, 25.914106],
          [61.847498, 25.914056],
          [61.847216, 25.9141],
          [61.846957, 25.914],
          [61.846494, 25.913914],
          [61.845749, 25.91398],
          [61.845611, 25.913945],
          [61.845233, 25.914068],
          [61.845019, 25.913959],
          [61.844539, 25.914054],
          [61.844013, 25.914097],
          [61.843874, 25.913904],
          [61.84371, 25.91386],
          [61.843594, 25.913658],
          [61.843449, 25.913204],
          [61.843195, 25.913168],
          [61.842515, 25.913132],
          [61.841698, 25.913259],
          [61.841309, 25.913257],
          [61.841163, 25.913285],
          [61.840895, 25.913447],
          [61.840731, 25.913422],
          [61.84066, 25.913464],
          [61.840335, 25.913371],
          [61.840112, 25.91346],
          [61.839688, 25.913255],
          [61.839354, 25.91328],
          [61.838972, 25.913208],
          [61.838783, 25.91322],
          [61.838535, 25.91315],
          [61.838311, 25.91323],
          [61.837779, 25.913115],
          [61.837075, 25.913178],
          [61.836996, 25.912992],
          [61.836785, 25.912719],
          [61.836749, 25.912492],
          [61.836502, 25.912011],
          [61.836519, 25.911629],
          [61.836489, 25.91149],
          [61.836387, 25.911388],
          [61.836256, 25.911478],
          [61.836193, 25.911457],
          [61.836126, 25.911498],
          [61.835339, 25.911473],
          [61.83508, 25.911415],
          [61.83492, 25.911487],
          [61.834691, 25.911432],
          [61.834503, 25.911328],
          [61.834281, 25.911421],
          [61.833758, 25.911323],
          [61.833135, 25.911343],
          [61.832665, 25.911282],
          [61.832446, 25.911347],
          [61.832335, 25.911307],
          [61.832101, 25.911309],
          [61.831706, 25.911402],
          [61.825024, 25.960406],
          [61.825001, 25.960903],
          [61.825075, 25.960991],
          [61.825098, 25.961221],
          [61.825, 25.961272],
          [61.8249, 25.961431],
          [61.824692, 25.961503],
          [61.824222, 25.961461],
          [61.82411, 25.961742],
          [61.823961, 25.961835],
          [61.823623, 25.961749],
          [61.823524, 25.961656],
          [61.823496, 25.961465],
          [61.823559, 25.961369],
          [61.82297, 25.961471],
          [61.822862, 25.961305],
          [61.822548, 25.961196],
          [61.822213, 25.961278],
          [61.821888, 25.961245],
          [61.821541, 25.961647],
          [61.821633, 25.961826],
          [61.822187, 25.961936],
          [61.822291, 25.962398],
          [61.822442, 25.962762],
          [61.822351, 25.963017],
          [61.822241, 25.963094],
          [61.82249, 25.963448],
          [61.822189, 25.963982],
          [61.822238, 25.964122],
          [61.821692, 25.964337],
          [61.821582, 25.964419],
          [61.821832, 25.964811],
          [61.821972, 25.964835],
          [61.822214, 25.964981],
          [61.822362, 25.965019],
          [61.822441, 25.965793],
          [61.821627, 25.965914],
          [61.821476, 25.966531],
          [61.821404, 25.966629],
          [61.821089, 25.966712],
          [61.820921, 25.966925],
          [61.820655, 25.966919],
          [61.820126, 25.967029],
          [61.819942, 25.966984],
          [61.819668, 25.966998],
          [61.81948, 25.967071],
          [61.819001, 25.967115],
          [61.818646, 25.967093],
          [61.818525, 25.967167],
          [61.818359, 25.967194],
          [61.818472, 25.967243],
          [61.818631, 25.967404],
          [61.818653, 25.967493],
          [61.818948, 25.967741],
          [61.819402, 25.967908],
          [61.819585, 25.968069],
          [61.819543, 25.968311],
          [61.819367, 25.968372],
          [61.819358, 25.968495],
          [61.819238, 25.968611],
          [61.819102, 25.968604],
          [61.819061, 25.968649],
          [61.819101, 25.968777],
          [61.818921, 25.968998],
          [61.818968, 25.96919],
          [61.819244, 25.96926],
          [61.81931, 25.96949],
          [61.819459, 25.969494],
          [61.819522, 25.969613],
          [61.819773, 25.969622],
          [61.820256, 25.969554],
          [61.820362, 25.969988],
          [61.820557, 25.969983],
          [61.820555, 25.969895],
          [61.821075, 25.969746],
          [61.821255, 25.969856],
          [61.821579, 25.969889],
          [61.821644, 25.970171],
          [61.82185, 25.970389],
          [61.821561, 25.970518],
          [61.82136, 25.970738],
          [61.821083, 25.970935],
          [61.820473, 25.971106],
          [61.820257, 25.971105],
          [61.820177, 25.971171],
          [61.81986, 25.971208],
          [61.819902, 25.97134],
          [61.819822, 25.971897],
          [61.819607, 25.971937],
          [61.819546, 25.972019],
          [61.819636, 25.972175],
          [61.819549, 25.972308],
          [61.819424, 25.972248],
          [61.819335, 25.972274],
          [61.819329, 25.972387],
          [61.819394, 25.972496],
          [61.819459, 25.97282],
          [61.81929, 25.972955],
          [61.819489, 25.973198],
          [61.819585, 25.973394],
          [61.819541, 25.973468],
          [61.819628, 25.973512],
          [61.819694, 25.973719],
          [61.819838, 25.973683],
          [61.820009, 25.973893],
          [61.820302, 25.973913],
          [61.820552, 25.97374],
          [61.820672, 25.97374],
          [61.820802, 25.973823],
          [61.821124, 25.973786],
          [61.821202, 25.973673],
          [61.821339, 25.973722],
          [61.821465, 25.973712],
          [61.821778, 25.973854],
          [61.821803, 25.973933],
          [61.821999, 25.97405],
          [61.822066, 25.974225],
          [61.822544, 25.974139],
          [61.822674, 25.974222],
          [61.822679, 25.974417],
          [61.822763, 25.974486],
          [61.82293, 25.974464],
          [61.8231, 25.974651],
          [61.823186, 25.974649],
          [61.823391, 25.97476],
          [61.823427, 25.974869],
          [61.823505, 25.974892],
          [61.823614, 25.975161],
          [61.823548, 25.975384],
          [61.823557, 25.975597],
          [61.824008, 25.975479],
          [61.824212, 25.975627],
          [61.824415, 25.975575],
          [61.824776, 25.975717],
          [61.82497, 25.975569],
          [61.825167, 25.975592],
          [61.825276, 25.975702],
          [61.825347, 25.975698],
          [61.825428, 25.975758],
          [61.825573, 25.975665],
          [61.825749, 25.975678],
          [61.825874, 25.97558],
          [61.826201, 25.975584],
          [61.826279, 25.975709],
          [61.82641, 25.975731],
          [61.826963, 25.975506],
          [61.827312, 25.975519],
          [61.827598, 25.976138],
          [61.82799, 25.976078],
          [61.828048, 25.976198],
          [61.82789, 25.976237],
          [61.827975, 25.976268],
          [61.827953, 25.976393],
          [61.82804, 25.976419],
          [61.827973, 25.976478],
          [61.82805, 25.976515],
          [61.828068, 25.97661],
          [61.828137, 25.97662],
          [61.82811, 25.976704],
          [61.828366, 25.97681],
          [61.828398, 25.97686],
          [61.828371, 25.977085],
          [61.828302, 25.977117],
          [61.828359, 25.977171],
          [61.828374, 25.977309],
          [61.828192, 25.977432],
          [61.828296, 25.977599],
          [61.828268, 25.977777],
          [61.828316, 25.977838],
          [61.828064, 25.978002],
          [61.827708, 25.977999],
          [61.827686, 25.977948],
          [61.827606, 25.977953],
          [61.827385, 25.978009],
          [61.827157, 25.978155],
          [61.827134, 25.978239],
          [61.8272, 25.97845],
          [61.827333, 25.978603],
          [61.827423, 25.978558],
          [61.827497, 25.978702],
          [61.827463, 25.978779],
          [61.827648, 25.978753],
          [61.827739, 25.978946],
          [61.827497, 25.979095],
          [61.827482, 25.979191],
          [61.827433, 25.979205],
          [61.827463, 25.979447],
          [61.827789, 25.979404],
          [61.827756, 25.979663],
          [61.827804, 25.979761],
          [61.827768, 25.97981],
          [61.827824, 25.979939],
          [61.827917, 25.979968],
          [61.828033, 25.98011],
          [61.828209, 25.980124],
          [61.828235, 25.980189],
          [61.828298, 25.980135],
          [61.828626, 25.980101],
          [61.828698, 25.980181],
          [61.828796, 25.980209],
          [61.828817, 25.980294],
          [61.829072, 25.980278],
          [61.82927, 25.980461],
          [61.829374, 25.980418],
          [61.829606, 25.980598],
          [61.829557, 25.980752],
          [61.829725, 25.98087],
          [61.829452, 25.980912],
          [61.828903, 25.981137],
          [61.829018, 25.981312],
          [61.829082, 25.981533],
          [61.829707, 25.981481],
          [61.829727, 25.981621],
          [61.829947, 25.981739],
          [61.830085, 25.981732],
          [61.830241, 25.981842],
          [61.830339, 25.982244],
          [61.830243, 25.982435],
          [61.830054, 25.982508],
          [61.830082, 25.982858],
          [61.831258, 25.982645],
          [61.831323, 25.982502],
          [61.831664, 25.982424],
          [61.832006, 25.982256],
          [61.83263, 25.982101],
          [61.833051, 25.982134],
          [61.833468, 25.982056],
          [61.833605, 25.982146],
          [61.83357, 25.9822],
          [61.83364, 25.982448],
          [61.833928, 25.982623],
          [61.833904, 25.982879],
          [61.833952, 25.982936],
          [61.833959, 25.983495],
          [61.833928, 25.983585],
          [61.833843, 25.983629],
          [61.833817, 25.983895],
          [61.833976, 25.983898],
          [61.834006, 25.983981],
          [61.834128, 25.983949],
          [61.834038, 25.984054],
          [61.834092, 25.98439],
          [61.834015, 25.984489],
          [61.834221, 25.984585],
          [61.834162, 25.984694],
          [61.834241, 25.984782],
          [61.834203, 25.984845],
          [61.834275, 25.984846],
          [61.834266, 25.984946],
          [61.834182, 25.984994],
          [61.833868, 25.985021],
          [61.833896, 25.985254],
          [61.833855, 25.98542],
          [61.833586, 25.985536],
          [61.833618, 25.985432],
          [61.833492, 25.985367],
          [61.833379, 25.985375],
          [61.833191, 25.985587],
          [61.832431, 25.985577],
          [61.832089, 25.985627],
          [61.83184, 25.985941],
          [61.831833, 25.986129],
          [61.831679, 25.986186],
          [61.831682, 25.986255],
          [61.831302, 25.98635],
          [61.831203, 25.986453],
          [61.830818, 25.986507],
          [61.830676, 25.986692],
          [61.830717, 25.986741],
          [61.830665, 25.9869],
          [61.830428, 25.987127],
          [61.830495, 25.987184],
          [61.830467, 25.987405],
          [61.830609, 25.987611],
          [61.830914, 25.987586],
          [61.830904, 25.987682],
          [61.831012, 25.987769],
          [61.831076, 25.987912],
          [61.830934, 25.988059],
          [61.830956, 25.988186],
          [61.83122, 25.988383],
          [61.83133, 25.988381],
          [61.831848, 25.988578],
          [61.831752, 25.988755],
          [61.83206, 25.988818],
          [61.83222, 25.988905],
          [61.832298, 25.989203],
          [61.832471, 25.989184],
          [61.832633, 25.989397],
          [61.832849, 25.989398],
          [61.833045, 25.989492],
          [61.833085, 25.989596],
          [61.832758, 25.989728],
          [61.832656, 25.989803],
          [61.832673, 25.989852],
          [61.832512, 25.989901],
          [61.832516, 25.989993],
          [61.832447, 25.990044],
          [61.83223, 25.99008],
          [61.832049, 25.990222],
          [61.832112, 25.990345],
          [61.83209, 25.990527],
          [61.832498, 25.990492],
          [61.832922, 25.990538],
          [61.833282, 25.990521],
          [61.833518, 25.99072],
          [61.833498, 25.990854],
          [61.833726, 25.991021],
          [61.833698, 25.991139],
          [61.833621, 25.991317],
          [61.833347, 25.991429],
          [61.833371, 25.991588],
          [61.833196, 25.991677],
          [61.833058, 25.991914],
          [61.832971, 25.991874],
          [61.832503, 25.991883],
          [61.832426, 25.991841],
          [61.832169, 25.991885],
          [61.832209, 25.991794],
          [61.832125, 25.991688],
          [61.831956, 25.991682],
          [61.831921, 25.99146],
          [61.83163, 25.991487],
          [61.831641, 25.991611],
          [61.83142, 25.991746],
          [61.831247, 25.991606],
          [61.831019, 25.991616],
          [61.830653, 25.991709],
          [61.830584, 25.991815],
          [61.830467, 25.991888],
          [61.830405, 25.992204],
          [61.830445, 25.992855],
          [61.830601, 25.992886],
          [61.830633, 25.992974],
          [61.830708, 25.992969],
          [61.830707, 25.993062],
          [61.830767, 25.993112],
          [61.831071, 25.993064],
          [61.831116, 25.993186],
          [61.831031, 25.993211],
          [61.831071, 25.993316],
          [61.830971, 25.993437],
          [61.830985, 25.993612],
          [61.831151, 25.993604],
          [61.831237, 25.993663],
          [61.831655, 25.993607],
          [61.831815, 25.993633],
          [61.831918, 25.993721],
          [61.831973, 25.993632],
          [61.832038, 25.993624],
          [61.832154, 25.993803],
          [61.832007, 25.993929],
          [61.832128, 25.994069],
          [61.832142, 25.994342],
          [61.832252, 25.994359],
          [61.832359, 25.994521],
          [61.83205, 25.994808],
          [61.8318, 25.994944],
          [61.831759, 25.995068],
          [61.831552, 25.995182],
          [61.831746, 25.995267],
          [61.831812, 25.995479],
          [61.83192, 25.995608],
          [61.832139, 25.995604],
          [61.832445, 25.995831],
          [61.832312, 25.995875],
          [61.832226, 25.995975],
          [61.832282, 25.996011],
          [61.832289, 25.996117],
          [61.832115, 25.996188],
          [61.832581, 25.996329],
          [61.83276, 25.996514],
          [61.832998, 25.996507],
          [61.833128, 25.996567],
          [61.833271, 25.996484],
          [61.833335, 25.996551],
          [61.833705, 25.996654],
          [61.834387, 25.996619],
          [61.834666, 25.996543],
          [61.834955, 25.99653],
          [61.835101, 25.996479],
          [61.83511, 25.996398],
          [61.835276, 25.996311],
          [61.835374, 25.996516],
          [61.835519, 25.996578],
          [61.835574, 25.996806],
          [61.835529, 25.99709],
          [61.835597, 25.997072],
          [61.835837, 25.997233],
          [61.835761, 25.997588],
          [61.835581, 25.997771],
          [61.835456, 25.99779],
          [61.835627, 25.997952],
          [61.835958, 25.998079],
          [61.836192, 25.998424],
          [61.836022, 25.99855],
          [61.835586, 25.998678],
          [61.835613, 25.998888],
          [61.835509, 25.999025],
          [61.835539, 25.999116],
          [61.835645, 25.999172],
          [61.835624, 25.999235],
          [61.835696, 25.999311],
          [61.83565, 25.9994],
          [61.835715, 25.999447],
          [61.835773, 25.999622],
          [61.835867, 25.999634],
          [61.835823, 25.99983],
          [61.835872, 26.000015],
          [61.835764, 26.00007],
          [61.835813, 26.000319],
          [61.83575, 26.000499],
          [61.835767, 26.000904],
          [61.835869, 26.000959],
          [61.836002, 26.000898],
          [61.836324, 26.000883],
          [61.836408, 26.001068],
          [61.836633, 26.001113],
          [61.83674, 26.001106],
          [61.836971, 26.000871],
          [61.837141, 26.000915],
          [61.837114, 26.001045],
          [61.837237, 26.001235],
          [61.837724, 26.001443],
          [61.837525, 26.001778],
          [61.837554, 26.002064],
          [61.837723, 26.00211],
          [61.83791, 26.00235],
          [61.838019, 26.00231],
          [61.838426, 26.002384],
          [61.838521, 26.002509],
          [61.8385, 26.002627],
          [61.838195, 26.003027],
          [61.838143, 26.003182],
          [61.838153, 26.003362],
          [61.838189, 26.0034],
          [61.838231, 26.003382],
          [61.83833, 26.0036],
          [61.838137, 26.003809],
          [61.837936, 26.003939],
          [61.837886, 26.004091],
          [61.837916, 26.004149],
          [61.838059, 26.00416],
          [61.838037, 26.004243],
          [61.837978, 26.004263],
          [61.838045, 26.004348],
          [61.838466, 26.004299],
          [61.838533, 26.004407],
          [61.838728, 26.004562],
          [61.838723, 26.004773],
          [61.838471, 26.004888],
          [61.838472, 26.004979],
          [61.838497, 26.005119],
          [61.838635, 26.005174],
          [61.838744, 26.005278],
          [61.838711, 26.005374],
          [61.838754, 26.005438],
          [61.838661, 26.005635],
          [61.838686, 26.005794],
          [61.838651, 26.005895],
          [61.838955, 26.005975],
          [61.838935, 26.006085],
          [61.83883, 26.006206],
          [61.838772, 26.006393],
          [61.838333, 26.006397],
          [61.838259, 26.006637],
          [61.83774, 26.006701],
          [61.837451, 26.006677],
          [61.83734, 26.006766],
          [61.837293, 26.007113],
          [61.837664, 26.007302],
          [61.837466, 26.007568],
          [61.837463, 26.007787],
          [61.837568, 26.007907],
          [61.837599, 26.008066],
          [61.837677, 26.008176],
          [61.837222, 26.008209],
          [61.83719, 26.008284],
          [61.836575, 26.00835],
          [61.836405, 26.008446],
          [61.836614, 26.00873],
          [61.836526, 26.008824],
          [61.836231, 26.00892],
          [61.836149, 26.009008],
          [61.836266, 26.009217],
          [61.836295, 26.009424],
          [61.836423, 26.009547],
          [61.836677, 26.009667],
          [61.836981, 26.009584],
          [61.83719, 26.009645],
          [61.837353, 26.009543],
          [61.837317, 26.009487],
          [61.837377, 26.009396],
          [61.837563, 26.009348],
          [61.837815, 26.009418],
          [61.838049, 26.009564],
          [61.838169, 26.009565],
          [61.838278, 26.009657],
          [61.838518, 26.009592],
          [61.83869, 26.009714],
          [61.838847, 26.009593],
          [61.839176, 26.009611],
          [61.839567, 26.0098],
          [61.839585, 26.009933],
          [61.839811, 26.010157],
          [61.839773, 26.010277],
          [61.839887, 26.010454],
          [61.839895, 26.010693],
          [61.840086, 26.010742],
          [61.840291, 26.010897],
          [61.840926, 26.010896],
          [61.841256, 26.010714],
          [61.84168, 26.010748],
          [61.842094, 26.010881],
          [61.84222, 26.011025],
          [61.842725, 26.011258],
          [61.843812, 26.011376],
          [61.844261, 26.011507],
          [61.844286, 26.011786],
          [61.84423, 26.011876],
          [61.844296, 26.012045],
          [61.844286, 26.012151],
          [61.844433, 26.012364],
          [61.844532, 26.012855],
          [61.844365, 26.012841],
          [61.844321, 26.013038],
          [61.844173, 26.013228],
          [61.844333, 26.013259],
          [61.84464, 26.013239],
          [61.844588, 26.013551],
          [61.844649, 26.013723],
          [61.8444, 26.013719],
          [61.844374, 26.013874],
          [61.844467, 26.013905],
          [61.844385, 26.014023],
          [61.844454, 26.014339],
          [61.844344, 26.014551],
          [61.844216, 26.014618],
          [61.844319, 26.014762],
          [61.844096, 26.014823],
          [61.843806, 26.014829],
          [61.843753, 26.014893],
          [61.843728, 26.015106],
          [61.843553, 26.015226],
          [61.843544, 26.015334],
          [61.843801, 26.015469],
          [61.844048, 26.015859],
          [61.844213, 26.016231],
          [61.844195, 26.016404],
          [61.844243, 26.016539],
          [61.844463, 26.016699],
          [61.844525, 26.016856],
          [61.844973, 26.01705],
          [61.845143, 26.017172],
          [61.845176, 26.017604],
          [61.845268, 26.017618],
          [61.845366, 26.017833],
          [61.845774, 26.018043],
          [61.846007, 26.018096],
          [61.846175, 26.018455],
          [61.846411, 26.018672],
          [61.846399, 26.018903],
          [61.846292, 26.018905],
          [61.84621, 26.01897],
          [61.846083, 26.018963],
          [61.845953, 26.018891],
          [61.845952, 26.0188],
          [61.845612, 26.018715],
          [61.845535, 26.018878],
          [61.845395, 26.018886],
          [61.845227, 26.018989],
          [61.845121, 26.018998],
          [61.84499, 26.019129],
          [61.844526, 26.019118],
          [61.844335, 26.019221],
          [61.844196, 26.019136],
          [61.843907, 26.019081],
          [61.843386, 26.019117],
          [61.843343, 26.019088],
          [61.843216, 26.019158],
          [61.842794, 26.01926],
          [61.842612, 26.019409],
          [61.842632, 26.019473],
          [61.842862, 26.019638],
          [61.842721, 26.0197],
          [61.842677, 26.019983],
          [61.842518, 26.020066],
          [61.842563, 26.020298],
          [61.842684, 26.020478],
          [61.842653, 26.020616],
          [61.842689, 26.020784],
          [61.842622, 26.020937],
          [61.842381, 26.021096],
          [61.841694, 26.021047],
          [61.840999, 26.021099],
          [61.841032, 26.021298],
          [61.841301, 26.021841],
          [61.841283, 26.022243],
          [61.841347, 26.022305],
          [61.8414, 26.022541],
          [61.840631, 26.022721],
          [61.840626, 26.022857],
          [61.840489, 26.022966],
          [61.840485, 26.023094],
          [61.8406, 26.023151],
          [61.840778, 26.023458],
          [61.840863, 26.023545],
          [61.841014, 26.023592],
          [61.84089, 26.02376],
          [61.840884, 26.023904],
          [61.840701, 26.024092],
          [61.840652, 26.024344],
          [61.840535, 26.024448],
          [61.840372, 26.024716],
          [61.840413, 26.02479],
          [61.840419, 26.025072],
          [61.840265, 26.02499],
          [61.840067, 26.024965],
          [61.839839, 26.025141],
          [61.839544, 26.025176],
          [61.83926, 26.025101],
          [61.839071, 26.024934],
          [61.838779, 26.024978],
          [61.838311, 26.024902],
          [61.837876, 26.024942],
          [61.837539, 26.024904],
          [61.837046, 26.024914],
          [61.836721, 26.025099],
          [61.836295, 26.024994],
          [61.835949, 26.025082],
          [61.835599, 26.025104],
          [61.83554, 26.025231],
          [61.835241, 26.025333],
          [61.835298, 26.025644],
          [61.8354, 26.025826],
          [61.835374, 26.025966],
          [61.835303, 26.026152],
          [61.835001, 26.026305],
          [61.835098, 26.026472],
          [61.835301, 26.026639],
          [61.835356, 26.026916],
          [61.834785, 26.027087],
          [61.83467, 26.027173],
          [61.833933, 26.027162],
          [61.833303, 26.027267],
          [61.833193, 26.027207],
          [61.832899, 26.027246],
          [61.832635, 26.02718],
          [61.832491, 26.027222],
          [61.832056, 26.027233],
          [61.831696, 26.027356],
          [61.831486, 26.027519],
          [61.831252, 26.027561],
          [61.831111, 26.027517],
          [61.831028, 26.027539],
          [61.830937, 26.028223],
          [61.831022, 26.028289],
          [61.831069, 26.028511],
          [61.831009, 26.028565],
          [61.831053, 26.028605],
          [61.830977, 26.028779],
          [61.830795, 26.02879],
          [61.830746, 26.028829],
          [61.830682, 26.029167],
          [61.830505, 26.029346],
          [61.830436, 26.029521],
          [61.830392, 26.029556],
          [61.830055, 26.029518],
          [61.829952, 26.029687],
          [61.830035, 26.029834],
          [61.830139, 26.029894],
          [61.83005, 26.030013],
          [61.830137, 26.030344],
          [61.830099, 26.030437],
          [61.830114, 26.03065],
          [61.829097, 26.030896],
          [61.828775, 26.031115],
          [61.828691, 26.031244],
          [61.828804, 26.031239],
          [61.829078, 26.031415],
          [61.829303, 26.031488],
          [61.829337, 26.031804],
          [61.830692, 26.031657],
          [61.831504, 26.031395],
          [61.831748, 26.031413],
          [61.832317, 26.031315],
          [61.832434, 26.031398],
          [61.832547, 26.031718],
          [61.832793, 26.031787],
          [61.833212, 26.031702],
          [61.833564, 26.031794],
          [61.833879, 26.031771],
          [61.83394, 26.031857],
          [61.83414, 26.031923],
          [61.834463, 26.03221],
          [61.834505, 26.032355],
          [61.834668, 26.032384],
          [61.835044, 26.032188],
          [61.835805, 26.032131],
          [61.836072, 26.032196],
          [61.836108, 26.032298],
          [61.836181, 26.032341],
          [61.836631, 26.032282],
          [61.837521, 26.032327],
          [61.837466, 26.032484],
          [61.83746, 26.032721],
          [61.837706, 26.032827],
          [61.837694, 26.033017],
          [61.837765, 26.033127],
          [61.837753, 26.033306],
          [61.837932, 26.033392],
          [61.838622, 26.033461],
          [61.839005, 26.033708],
          [61.839054, 26.033981],
          [61.839021, 26.034126],
          [61.83905, 26.03446],
          [61.839173, 26.034916],
          [61.839149, 26.035116],
          [61.83933, 26.035209],
          [61.839675, 26.035287],
          [61.839816, 26.035455],
          [61.84005, 26.035569],
          [61.840087, 26.035722],
          [61.840048, 26.035788],
          [61.840141, 26.035961],
          [61.84047, 26.035842],
          [61.840859, 26.03563],
          [61.840981, 26.035592],
          [61.841148, 26.03561],
          [61.841156, 26.035751],
          [61.840975, 26.035869],
          [61.840954, 26.036349],
          [61.840892, 26.036395],
          [61.840914, 26.036479],
          [61.840849, 26.0367],
          [61.840684, 26.036949],
          [61.84074, 26.037115],
          [61.840859, 26.037241],
          [61.840941, 26.037567],
          [61.841038, 26.037641],
          [61.84104, 26.037773],
          [61.84096, 26.037883],
          [61.840988, 26.038034],
          [61.840889, 26.038275],
          [61.840287, 26.03845],
          [61.840305, 26.038599],
          [61.840407, 26.038747],
          [61.840471, 26.039095],
          [61.840384, 26.039368],
          [61.840144, 26.039474],
          [61.840075, 26.039561],
          [61.840042, 26.039716],
          [61.840092, 26.039804],
          [61.840085, 26.040011],
          [61.840015, 26.040038],
          [61.840052, 26.040109],
          [61.839987, 26.040278],
          [61.839585, 26.040363],
          [61.83965, 26.0409],
          [61.839517, 26.040889],
          [61.839375, 26.040941],
          [61.839281, 26.040978],
          [61.839216, 26.041064],
          [61.83855, 26.041021],
          [61.838275, 26.040942],
          [61.838148, 26.041108],
          [61.837988, 26.041172],
          [61.837911, 26.041372],
          [61.837766, 26.041391],
          [61.837573, 26.041587],
          [61.837501, 26.041604],
          [61.837483, 26.041662],
          [61.837527, 26.041716],
          [61.83738, 26.041909],
          [61.837107, 26.041957],
          [61.836844, 26.042157],
          [61.836453, 26.042192],
          [61.836183, 26.042141],
          [61.835873, 26.042409],
          [61.83532, 26.042434],
          [61.835511, 26.043229],
          [61.835842, 26.043432],
          [61.836266, 26.043421],
          [61.836375, 26.043474],
          [61.836267, 26.043657],
          [61.836315, 26.043772],
          [61.836126, 26.043795],
          [61.836147, 26.043884],
          [61.836045, 26.04397],
          [61.836015, 26.044078],
          [61.835929, 26.044121],
          [61.835599, 26.044144],
          [61.835468, 26.044272],
          [61.835493, 26.044311],
          [61.834509, 26.04438],
          [61.834423, 26.044545],
          [61.834456, 26.044585],
          [61.834393, 26.044746],
          [61.834202, 26.04484],
          [61.834011, 26.044876],
          [61.834112, 26.045077],
          [61.834062, 26.045134],
          [61.834182, 26.045166],
          [61.834258, 26.04532],
          [61.834494, 26.045308],
          [61.834617, 26.045353],
          [61.834885, 26.045228],
          [61.835315, 26.04527],
          [61.835495, 26.045642],
          [61.835642, 26.04564],
          [61.835926, 26.045808],
          [61.835953, 26.046013],
          [61.836376, 26.046016],
          [61.83713, 26.045924],
          [61.837204, 26.046092],
          [61.837563, 26.045974],
          [61.83755, 26.046282],
          [61.837433, 26.046307],
          [61.83729, 26.046632],
          [61.83714, 26.046732],
          [61.837105, 26.046874],
          [61.836983, 26.046876],
          [61.836864, 26.046796],
          [61.836512, 26.046827],
          [61.836327, 26.046883],
          [61.836302, 26.047023],
          [61.836331, 26.04709],
          [61.836773, 26.047098],
          [61.836827, 26.047282],
          [61.836973, 26.047308],
          [61.836943, 26.047441],
          [61.837085, 26.047891],
          [61.837364, 26.048153],
          [61.837441, 26.04847],
          [61.837581, 26.048756],
          [61.837767, 26.049038],
          [61.83798, 26.049096],
          [61.838079, 26.049078],
          [61.838214, 26.04956],
          [61.838133, 26.049641],
          [61.838195, 26.049943],
          [61.838175, 26.050171],
          [61.838296, 26.050294],
          [61.838314, 26.050353],
          [61.838248, 26.050382],
          [61.838311, 26.050575],
          [61.83816, 26.050686],
          [61.838027, 26.050908],
          [61.837899, 26.050916],
          [61.838118, 26.051453],
          [61.838068, 26.05189],
          [61.838123, 26.051967],
          [61.838123, 26.052167],
          [61.838079, 26.052259],
          [61.837989, 26.052272],
          [61.83774, 26.052448],
          [61.837609, 26.052464],
          [61.837442, 26.05275],
          [61.837316, 26.052906],
          [61.837219, 26.052955],
          [61.83701, 26.053278],
          [61.836761, 26.053409],
          [61.836521, 26.053404],
          [61.836357, 26.053497],
          [61.836062, 26.053518],
          [61.836044, 26.053483],
          [61.835857, 26.053456],
          [61.835776, 26.053587],
          [61.835671, 26.053614],
          [61.835592, 26.053726],
          [61.83548, 26.053717],
          [61.835354, 26.053832],
          [61.835503, 26.054017],
          [61.835711, 26.054603],
          [61.835724, 26.05491],
          [61.83551, 26.055107],
          [61.835405, 26.055269],
          [61.835523, 26.055603],
          [61.835621, 26.05563],
          [61.835644, 26.055805],
          [61.835268, 26.056178],
          [61.835247, 26.056257],
          [61.8352, 26.05629],
          [61.835095, 26.05625],
          [61.834963, 26.056308],
          [61.834784, 26.056536],
          [61.834664, 26.056605],
          [61.834454, 26.057106],
          [61.834195, 26.05714],
          [61.833938, 26.057374],
          [61.833767, 26.057407],
          [61.833576, 26.057534],
          [61.833158, 26.057679],
          [61.833071, 26.057822],
          [61.833074, 26.057899],
          [61.832981, 26.057949],
          [61.832964, 26.05802],
          [61.832916, 26.059023],
          [61.832838, 26.059377],
          [61.83293, 26.059389],
          [61.833014, 26.059528],
          [61.833176, 26.059664],
          [61.833539, 26.05972],
          [61.833802, 26.059835],
          [61.833969, 26.059967],
          [61.834206, 26.059994],
          [61.834307, 26.059963],
          [61.834643, 26.060051],
          [61.834579, 26.060235],
          [61.834688, 26.060413],
          [61.834678, 26.060521],
          [61.834513, 26.060633],
          [61.834473, 26.060725],
          [61.834594, 26.060973],
          [61.83474, 26.06099],
          [61.834798, 26.061056],
          [61.834971, 26.061117],
          [61.834917, 26.061237],
          [61.835013, 26.061254],
          [61.834999, 26.061301],
          [61.835113, 26.061311],
          [61.835203, 26.061415],
          [61.835242, 26.061677],
          [61.83507, 26.06184],
          [61.834832, 26.062302],
          [61.834836, 26.062452],
          [61.834749, 26.062537],
          [61.834726, 26.062794],
          [61.834637, 26.062794],
          [61.83459, 26.06285],
          [61.834374, 26.06324],
          [61.834541, 26.063414],
          [61.834505, 26.063491],
          [61.83456, 26.063732],
          [61.834616, 26.063754],
          [61.834614, 26.063872],
          [61.834725, 26.063966],
          [61.834915, 26.064008],
          [61.834953, 26.064237],
          [61.83517, 26.064359],
          [61.83528, 26.064373],
          [61.835473, 26.064211],
          [61.835537, 26.064206],
          [61.835557, 26.064147],
          [61.835652, 26.064138],
          [61.835872, 26.063968],
          [61.836254, 26.063976],
          [61.836407, 26.064085],
          [61.836718, 26.064058],
          [61.83692, 26.06383],
          [61.837241, 26.063702],
          [61.83748, 26.063929],
          [61.83759, 26.063943],
          [61.838081, 26.06423],
          [61.838506, 26.064018],
          [61.838798, 26.06413],
          [61.839282, 26.064167],
          [61.839958, 26.064585],
          [61.840157, 26.064604],
          [61.840587, 26.064747],
          [61.840872, 26.064897],
          [61.841136, 26.064916],
          [61.841388, 26.065158],
          [61.854211, 26.140299],
          [61.86017, 26.17597],
          [61.893165, 26.233464],
          [61.889278, 26.251972],
          [61.889387, 26.25206],
          [61.890089, 26.25217],
          [61.89014, 26.252214],
          [61.890407, 26.252472],
          [61.890526, 26.252683],
          [61.890558, 26.252891],
          [61.890684, 26.25299],
          [61.890644, 26.253313],
          [61.890756, 26.25337],
          [61.890974, 26.253289],
          [61.891341, 26.253269],
          [61.891382, 26.253158],
          [61.891578, 26.253271],
          [61.891895, 26.253259],
          [61.892231, 26.253308],
          [61.892949, 26.253245],
          [61.89335, 26.253491],
          [61.893571, 26.253538],
          [61.893728, 26.253472],
          [61.89391, 26.253262],
          [61.893938, 26.253157],
          [61.894072, 26.253137],
          [61.894261, 26.253254],
          [61.894356, 26.253247],
          [61.894452, 26.253335],
          [61.894813, 26.253399],
          [61.894795, 26.253475],
          [61.894947, 26.253546],
          [61.894972, 26.25456],
          [61.894778, 26.255339],
          [61.894632, 26.255493],
          [61.894527, 26.255742],
          [61.894563, 26.256092],
          [61.89445, 26.256247],
          [61.894401, 26.256494],
          [61.894405, 26.256891],
          [61.894462, 26.257079],
          [61.894863, 26.257507],
          [61.895169, 26.257614],
          [61.895398, 26.257593],
          [61.895466, 26.257622],
          [61.895609, 26.257853],
          [61.895611, 26.258067],
          [61.895767, 26.258292],
          [61.89591, 26.25835],
          [61.895877, 26.258491],
          [61.895909, 26.258592],
          [61.896003, 26.258685],
          [61.896059, 26.258913],
          [61.896151, 26.259019],
          [61.896165, 26.259126],
          [61.896078, 26.259208],
          [61.8961, 26.259343],
          [61.89619, 26.259487],
          [61.896335, 26.259555],
          [61.896565, 26.259969],
          [61.896586, 26.260212],
          [61.896509, 26.260285],
          [61.896429, 26.26048],
          [61.896464, 26.260583],
          [61.896351, 26.260923],
          [61.896381, 26.261231],
          [61.896325, 26.261399],
          [61.896504, 26.261572],
          [61.896517, 26.261537],
          [61.896923, 26.262022],
          [61.897436, 26.26229],
          [61.897441, 26.262521],
          [61.897463, 26.262499],
          [61.897604, 26.262583],
          [61.897735, 26.262713],
          [61.897983, 26.262685],
          [61.898012, 26.262617],
          [61.898175, 26.262594],
          [61.898367, 26.262488],
          [61.898921, 26.262489],
          [61.899119, 26.262771],
          [61.899173, 26.262593],
          [61.899317, 26.262593],
          [61.899321, 26.262513],
          [61.899516, 26.262496],
          [61.899529, 26.2626],
          [61.899448, 26.262619],
          [61.899447, 26.262697],
          [61.899555, 26.26274],
          [61.900289, 26.263364],
          [61.900356, 26.263335],
          [61.900461, 26.263396],
          [61.900522, 26.263386],
          [61.900893, 26.263194],
          [61.900907, 26.263153],
          [61.901122, 26.263115],
          [61.901155, 26.263071],
          [61.901218, 26.263175],
          [61.901359, 26.263089],
          [61.9015, 26.263078],
          [61.901538, 26.263128],
          [61.901599, 26.263115],
          [61.901643, 26.263186],
          [61.901867, 26.263228],
          [61.901927, 26.263323],
          [61.902043, 26.263277],
          [61.902015, 26.263183],
          [61.90206, 26.263132],
          [61.902507, 26.262857],
          [61.902733, 26.262584],
          [61.902852, 26.262522],
          [61.902953, 26.262472],
          [61.903649, 26.262505],
          [61.904061, 26.2623],
          [61.904165, 26.262059],
          [61.904337, 26.26185],
          [61.904803, 26.261638],
          [61.905054, 26.261707],
          [61.905172, 26.261658],
          [61.905118, 26.261589],
          [61.905141, 26.261432],
          [61.905308, 26.261236],
          [61.905353, 26.261217],
          [61.905505, 26.261285],
          [61.905702, 26.261249],
          [61.905828, 26.261306],
          [61.906252, 26.26119],
          [61.906454, 26.261303],
          [61.906551, 26.261291],
          [61.906674, 26.261201],
          [61.906711, 26.260643],
          [61.906827, 26.26049],
          [61.90692, 26.260147],
          [61.907028, 26.259998],
          [61.906953, 26.259771],
          [61.90708, 26.259693],
          [61.907172, 26.25951],
          [61.90731, 26.259497],
          [61.907441, 26.259542],
          [61.907609, 26.259501],
          [61.90799, 26.259556],
          [61.908275, 26.25975],
          [61.908616, 26.259586],
          [61.908818, 26.259393],
          [61.908799, 26.259202],
          [61.908839, 26.259131],
          [61.909135, 26.258807],
          [61.909133, 26.258535],
          [61.90958, 26.258369],
          [61.909655, 26.258169],
          [61.90997, 26.257913],
          [61.910039, 26.257796],
          [61.910006, 26.257596],
          [61.910241, 26.257323],
          [61.910307, 26.257172],
          [61.910488, 26.257043],
          [61.910476, 26.256891],
          [61.910553, 26.256728],
          [61.910527, 26.256436],
          [61.91058, 26.256308],
          [61.910706, 26.256248],
          [61.910745, 26.256047],
          [61.910721, 26.255862],
          [61.91081, 26.255819],
          [61.910796, 26.255572],
          [61.910872, 26.25532],
          [61.910966, 26.255138],
          [61.911211, 26.255074],
          [61.911301, 26.254811],
          [61.911631, 26.254796],
          [61.911823, 26.255246],
          [61.911895, 26.255242],
          [61.912145, 26.255361],
          [61.91233, 26.255588],
          [61.912416, 26.255791],
          [61.912848, 26.255858],
          [61.912966, 26.255821],
          [61.913035, 26.255894],
          [61.913203, 26.255866],
          [61.913246, 26.255847],
          [61.913344, 26.255585],
          [61.913499, 26.255508],
          [61.91357, 26.255309],
          [61.913629, 26.25526],
          [61.913944, 26.255126],
          [61.914043, 26.255163],
          [61.914304, 26.255088],
          [61.914342, 26.254975],
          [61.914632, 26.254639],
          [61.914654, 26.254575],
          [61.914609, 26.254516],
          [61.914657, 26.254394],
          [61.91489, 26.254434],
          [61.915259, 26.254312],
          [61.915315, 26.253824],
          [61.91538, 26.253863],
          [61.915794, 26.253836],
          [61.916193, 26.253958],
          [61.916365, 26.253895],
          [61.916569, 26.253726],
          [61.916668, 26.253766],
          [61.91674, 26.253861],
          [61.916903, 26.253833],
          [61.917105, 26.253862],
          [61.917421, 26.253761],
          [61.917626, 26.253824],
          [61.917809, 26.254067],
          [61.91815, 26.254135],
          [61.918281, 26.254363],
          [61.918405, 26.254388],
          [61.918538, 26.254495],
          [61.918615, 26.25449],
          [61.918622, 26.254369],
          [61.91875, 26.254133],
          [61.918939, 26.254154],
          [61.919064, 26.254099],
          [61.919096, 26.253968],
          [61.919409, 26.253994],
          [61.919678, 26.254109],
          [61.919795, 26.25428],
          [61.919944, 26.25434],
          [61.920709, 26.254479],
          [61.920703, 26.254731],
          [61.920823, 26.254746],
          [61.921142, 26.254966],
          [61.921168, 26.255171],
          [61.921583, 26.255334],
          [61.921663, 26.255447],
          [61.921781, 26.255441],
          [61.92189, 26.255508],
          [61.922085, 26.255509],
          [61.922156, 26.255619],
          [61.92213, 26.255682],
          [61.922261, 26.255713],
          [61.922652, 26.255624],
          [61.92277, 26.255555],
          [61.922987, 26.255586],
          [61.923002, 26.255687],
          [61.923209, 26.255605],
          [61.923354, 26.255651],
          [61.923588, 26.255598],
          [61.923671, 26.255447],
          [61.923863, 26.255429],
          [61.924067, 26.255311],
          [61.924136, 26.255482],
          [61.924253, 26.255516],
          [61.924272, 26.255619],
          [61.924559, 26.255961],
          [61.924823, 26.256062],
          [61.924972, 26.256179],
          [61.925122, 26.256199],
          [61.925216, 26.256167],
          [61.9256, 26.255912],
          [61.925746, 26.256012],
          [61.925879, 26.255988],
          [61.925921, 26.255937],
          [61.92616, 26.255956],
          [61.926218, 26.255869],
          [61.926517, 26.255793],
          [61.926607, 26.255807],
          [61.928451, 26.257087],
          [61.928791, 26.25706],
          [61.928825, 26.257116],
          [61.929041, 26.257097],
          [61.929408, 26.256843],
          [61.929525, 26.256847],
          [61.92962, 26.256636],
          [61.930218, 26.256614],
          [61.930315, 26.256517],
          [61.930614, 26.256367],
          [61.930722, 26.256387],
          [61.930973, 26.256299],
          [61.931262, 26.256564],
          [61.93133, 26.256742],
          [61.931502, 26.256859],
          [61.931868, 26.257009],
          [61.932028, 26.257176],
          [61.932135, 26.257194],
          [61.932194, 26.257387],
          [61.932298, 26.257458],
          [61.932212, 26.257617],
          [61.932337, 26.257829],
          [61.93232, 26.258104],
          [61.932564, 26.25804],
          [61.932647, 26.258092],
          [61.932792, 26.258073],
          [61.932812, 26.258111],
          [61.93287, 26.258099],
          [61.933115, 26.258197],
          [61.933332, 26.258138],
          [61.933387, 26.258062],
          [61.933546, 26.258045],
          [61.933679, 26.25796],
          [61.93377, 26.258022],
          [61.93394, 26.25793],
          [61.934051, 26.258004],
          [61.934316, 26.257998],
          [61.9344, 26.258056],
          [61.93467, 26.257941],
          [61.935058, 26.258069],
          [61.93517, 26.257979],
          [61.935413, 26.257995],
          [61.935468, 26.258061],
          [61.9357, 26.258004],
          [61.935963, 26.258078],
          [61.93616, 26.258],
          [61.936234, 26.25815],
          [61.936226, 26.258373],
          [61.936131, 26.25852],
          [61.936122, 26.258646],
          [61.936313, 26.258665],
          [61.936271, 26.25873],
          [61.936289, 26.258818],
          [61.936343, 26.258811],
          [61.936566, 26.259475],
          [61.936692, 26.25948],
          [61.936879, 26.259624],
          [61.937207, 26.259602],
          [61.937307, 26.259679],
          [61.937447, 26.259706],
          [61.937688, 26.259896],
          [61.938048, 26.259925],
          [61.938195, 26.25998],
          [61.938366, 26.259968],
          [61.938428, 26.260015],
          [61.938769, 26.259983],
          [61.938935, 26.260057],
          [61.939084, 26.260296],
          [61.939362, 26.260342],
          [61.93954, 26.260495],
          [61.939778, 26.260558],
          [61.939905, 26.260523],
          [61.940329, 26.260596],
          [61.940346, 26.26064],
          [61.940455, 26.260675],
          [61.940606, 26.260682],
          [61.940763, 26.260782],
          [61.940789, 26.261044],
          [61.94093, 26.2612],
          [61.941006, 26.261217],
          [61.941056, 26.261396],
          [61.941402, 26.261351],
          [61.941458, 26.261568],
          [61.941656, 26.261603],
          [61.941788, 26.261696],
          [61.941927, 26.261705],
          [61.94192, 26.261754],
          [61.942044, 26.261814],
          [61.942087, 26.261959],
          [61.942253, 26.262078],
          [61.942303, 26.262322],
          [61.942462, 26.262623],
          [61.942703, 26.262866],
          [61.942837, 26.262954],
          [61.942916, 26.262918],
          [61.943023, 26.262968],
          [61.943109, 26.262946],
          [61.943287, 26.262992],
          [61.94342, 26.263097],
          [61.943633, 26.263106],
          [61.943589, 26.263172],
          [61.943617, 26.263226],
          [61.943921, 26.263464],
          [61.944029, 26.26336],
          [61.944248, 26.263403],
          [61.945125, 26.26311],
          [61.94542, 26.263125],
          [61.945449, 26.263159],
          [61.945546, 26.263097],
          [61.945646, 26.263097],
          [61.945766, 26.263003],
          [61.94602, 26.262968],
          [61.946289, 26.262809],
          [61.946481, 26.262768],
          [61.946632, 26.26267],
          [61.946592, 26.262568],
          [61.946974, 26.262545],
          [61.947019, 26.262579],
          [61.947024, 26.262715],
          [61.947179, 26.262918],
          [61.947304, 26.262897],
          [61.947504, 26.262971],
          [61.947622, 26.263192],
          [61.947567, 26.263298],
          [61.94772, 26.263334],
          [61.947751, 26.263442],
          [61.947874, 26.263435],
          [61.947984, 26.263515],
          [61.948049, 26.263735],
          [61.948043, 26.263966],
          [61.948207, 26.264085],
          [61.948424, 26.264131],
          [61.94852, 26.264457],
          [61.948567, 26.26449],
          [61.948825, 26.264575],
          [61.948936, 26.264542],
          [61.949205, 26.264234],
          [61.949411, 26.264172],
          [61.949519, 26.264088],
          [61.949717, 26.264063],
          [61.949806, 26.264],
          [61.950063, 26.26404],
          [61.950358, 26.263917],
          [61.950543, 26.263912],
          [61.950738, 26.263831],
          [61.951465, 26.264164],
          [61.951599, 26.264274],
          [61.952236, 26.264334],
          [61.952189, 26.264792],
          [61.952282, 26.264905],
          [61.952328, 26.265115],
          [61.952421, 26.265181],
          [61.952562, 26.265404],
          [61.952672, 26.265467],
          [61.953098, 26.265408],
          [61.953729, 26.265502],
          [61.953911, 26.265412],
          [61.954005, 26.265271],
          [61.954078, 26.265299],
          [61.954468, 26.2652],
          [61.954785, 26.265225],
          [61.955025, 26.265069],
          [61.955248, 26.264832],
          [61.955467, 26.264745],
          [61.955673, 26.264591],
          [61.955826, 26.264573],
          [61.955929, 26.264507],
          [61.956042, 26.264305],
          [61.956119, 26.264312],
          [61.956604, 26.264131],
          [61.956974, 26.263881],
          [61.957034, 26.263812],
          [61.956987, 26.263634],
          [61.957074, 26.263637],
          [61.957062, 26.26353],
          [61.957157, 26.263234],
          [61.957377, 26.263047],
          [61.957379, 26.262949],
          [61.957517, 26.262677],
          [61.957722, 26.262486],
          [61.958035, 26.262435],
          [61.958082, 26.262313],
          [61.958065, 26.261931],
          [61.957999, 26.261944],
          [61.958008, 26.26184],
          [61.957875, 26.261817],
          [61.957964, 26.261629],
          [61.957941, 26.261402],
          [61.957878, 26.261405],
          [61.957917, 26.26128],
          [61.957711, 26.261148],
          [61.957693, 26.261036],
          [61.95777, 26.260961],
          [61.957794, 26.260777],
          [61.958435, 26.260121],
          [61.958549, 26.260237],
          [61.958676, 26.260224],
          [61.958718, 26.260288],
          [61.958889, 26.26033],
          [61.959041, 26.260307],
          [61.959092, 26.260436],
          [61.959243, 26.260558],
          [61.959356, 26.260527],
          [61.959415, 26.260573],
          [61.959406, 26.260846],
          [61.959474, 26.260817],
          [61.959696, 26.260884],
          [61.960092, 26.260884],
          [61.960173, 26.260725],
          [61.960342, 26.260596],
          [61.960658, 26.260522],
          [61.961143, 26.260217],
          [61.961094, 26.260584],
          [61.961185, 26.260747],
          [61.961208, 26.260922],
          [61.961323, 26.260841],
          [61.96138, 26.260909],
          [61.96137, 26.261118],
          [61.961524, 26.261209],
          [61.961532, 26.261344],
          [61.961611, 26.261401],
          [61.961543, 26.261758],
          [61.961623, 26.261842],
          [61.961633, 26.261932],
          [61.961575, 26.262053],
          [61.961651, 26.262045],
          [61.961471, 26.262281],
          [61.961467, 26.262479],
          [61.961675, 26.262489],
          [61.962078, 26.262694],
          [61.962214, 26.262684],
          [61.96254, 26.262797],
          [61.96278, 26.263314],
          [61.96296, 26.263355],
          [61.962962, 26.263576],
          [61.962773, 26.263916],
          [61.962789, 26.26403],
          [61.962877, 26.264092],
          [61.962821, 26.264153],
          [61.962862, 26.264277],
          [61.962823, 26.264382],
          [61.963263, 26.264647],
          [61.963206, 26.2648],
          [61.963245, 26.264897],
          [61.963378, 26.265055],
          [61.963573, 26.26507],
          [61.9636, 26.265216],
          [61.963701, 26.265273],
          [61.963718, 26.265414],
          [61.963802, 26.265544],
          [61.963861, 26.26562],
          [61.963942, 26.265603],
          [61.964022, 26.265938],
          [61.963977, 26.266067],
          [61.963988, 26.266289],
          [61.9642, 26.266261],
          [61.964389, 26.266402],
          [61.964724, 26.266486],
          [61.964717, 26.266652],
          [61.964915, 26.266632],
          [61.964896, 26.266793],
          [61.965, 26.266876],
          [61.965117, 26.266857],
          [61.965242, 26.266909],
          [61.965258, 26.266981],
          [61.965615, 26.267183],
          [61.965892, 26.267127],
          [61.966007, 26.267188],
          [61.966271, 26.267114],
          [61.966372, 26.267216],
          [61.966329, 26.26723],
          [61.966312, 26.26759],
          [61.966442, 26.267695],
          [61.966811, 26.267623],
          [61.966931, 26.267756],
          [61.967127, 26.267813],
          [61.967214, 26.267923],
          [61.96743, 26.267903],
          [61.9675, 26.268031],
          [61.967601, 26.268058],
          [61.967596, 26.268112],
          [61.967802, 26.268182],
          [61.96788, 26.268301],
          [61.967979, 26.268338],
          [61.967953, 26.268612],
          [61.967867, 26.268837],
          [61.967854, 26.269071],
          [61.967919, 26.26912],
          [61.967895, 26.26926],
          [61.967971, 26.269214],
          [61.96805, 26.269248],
          [61.968241, 26.269386],
          [61.968285, 26.26949],
          [61.968406, 26.269562],
          [61.968429, 26.269627],
          [61.968532, 26.269567],
          [61.968529, 26.269607],
          [61.968751, 26.269599],
          [61.968952, 26.269519],
          [61.969459, 26.2697],
          [61.969514, 26.269763],
          [61.969649, 26.269781],
          [61.96978, 26.269899],
          [61.969899, 26.269897],
          [61.970125, 26.269999],
          [61.970238, 26.269998],
          [61.970426, 26.269898],
          [61.970859, 26.269914],
          [61.971076, 26.26997],
          [61.971146, 26.269939],
          [61.971361, 26.270032],
          [61.971545, 26.270024],
          [61.971696, 26.269849],
          [61.971773, 26.270302],
          [61.971743, 26.270381],
          [61.971879, 26.270518],
          [61.971888, 26.270615],
          [61.971881, 26.270751],
          [61.97175, 26.270937],
          [61.971746, 26.271008],
          [61.971916, 26.271158],
          [61.97196, 26.271359],
          [61.97197, 26.271645],
          [61.971859, 26.271974],
          [61.971864, 26.272215],
          [61.972002, 26.27213],
          [61.972046, 26.27235],
          [61.97212, 26.272388],
          [61.972166, 26.272353],
          [61.972207, 26.272415],
          [61.97229, 26.272435],
          [61.972477, 26.27245],
          [61.972565, 26.272651],
          [61.972784, 26.272776],
          [61.97278, 26.272869],
          [61.973404, 26.273029],
          [61.973407, 26.273108],
          [61.97354, 26.273241],
          [61.973601, 26.273191],
          [61.973853, 26.273137],
          [61.974138, 26.273219],
          [61.974266, 26.273208],
          [61.974376, 26.273238],
          [61.974396, 26.273294],
          [61.974735, 26.273362],
          [61.975123, 26.27368],
          [61.975215, 26.273704],
          [61.97567, 26.273596],
          [61.97589, 26.273689],
          [61.975937, 26.273804],
          [61.976259, 26.273838],
          [61.976331, 26.273913],
          [61.976675, 26.273891],
          [61.976849, 26.27394],
          [61.976911, 26.274032],
          [61.976964, 26.274009],
          [61.977059, 26.27409],
          [61.977239, 26.274118],
          [61.977273, 26.274198],
          [61.977713, 26.274311],
          [61.977725, 26.274401],
          [61.977824, 26.27447],
          [61.977938, 26.27445],
          [61.978103, 26.27469],
          [61.978169, 26.274722],
          [61.978157, 26.274804],
          [61.978334, 26.274823],
          [61.978356, 26.274896],
          [61.978539, 26.274976],
          [61.97888, 26.274842],
          [61.978944, 26.274759],
          [61.979011, 26.27477],
          [61.979122, 26.274611],
          [61.97927, 26.274608],
          [61.979288, 26.274567],
          [61.979372, 26.274603],
          [61.979435, 26.274557],
          [61.979601, 26.274633],
          [61.979968, 26.274668],
          [61.980269, 26.274568],
          [61.980368, 26.274577],
          [61.980601, 26.27465],
          [61.980725, 26.274881],
          [61.980956, 26.274969],
          [61.981, 26.275234],
          [61.981042, 26.275238],
          [61.981104, 26.275368],
          [61.981219, 26.27581],
          [61.981142, 26.275948],
          [61.981151, 26.276058],
          [61.981069, 26.276137],
          [61.981085, 26.276199],
          [61.981224, 26.276233],
          [61.981219, 26.276289],
          [61.981116, 26.276638],
          [61.981017, 26.276651],
          [61.981353, 26.276812],
          [61.981421, 26.27701],
          [61.981385, 26.277232],
          [61.981609, 26.277219],
          [61.981884, 26.277285],
          [61.98206, 26.277197],
          [61.982286, 26.277308],
          [61.98242, 26.277301],
          [61.982457, 26.277401],
          [61.982643, 26.277455],
          [61.982711, 26.277546],
          [61.982931, 26.277611],
          [61.983033, 26.277792],
          [61.982981, 26.277975],
          [61.983146, 26.278223],
          [61.983319, 26.278686],
          [61.983298, 26.279097],
          [61.983181, 26.279208],
          [61.983138, 26.279501],
          [61.983336, 26.279869],
          [61.983236, 26.280047],
          [61.983184, 26.28003],
          [61.983107, 26.280157],
          [61.982994, 26.280203],
          [61.98279, 26.280551],
          [61.982784, 26.280619],
          [61.982881, 26.280707],
          [61.982873, 26.280993],
          [61.982942, 26.281173],
          [61.983084, 26.281209],
          [61.983161, 26.281176],
          [61.98332, 26.281201],
          [61.983519, 26.281143],
          [61.983532, 26.281193],
          [61.983694, 26.281165],
          [61.983859, 26.281214],
          [61.983959, 26.281323],
          [61.98422, 26.281365],
          [61.984316, 26.281467],
          [61.984503, 26.281497],
          [61.984628, 26.281419],
          [61.985007, 26.281407],
          [61.98528, 26.281549],
          [61.985338, 26.281432],
          [61.985424, 26.281402],
          [61.985674, 26.281381],
          [61.985805, 26.281302],
          [61.986077, 26.281292],
          [61.986192, 26.281221],
          [61.986392, 26.281265],
          [61.986451, 26.281174],
          [61.986976, 26.281359],
          [61.987287, 26.281403],
          [61.987651, 26.281214],
          [61.987917, 26.280831],
          [61.988103, 26.280948],
          [61.988397, 26.281023],
          [61.988741, 26.280909],
          [61.988768, 26.280833],
          [61.988877, 26.280759],
          [61.989181, 26.28063],
          [61.989631, 26.280815],
          [61.989621, 26.280945],
          [61.989764, 26.281227],
          [61.989934, 26.2812],
          [61.990045, 26.281073],
          [61.990156, 26.281023],
          [61.990255, 26.281115],
          [61.99039, 26.281112],
          [61.990555, 26.281241],
          [61.990918, 26.281265],
          [61.991328, 26.281421],
          [61.991418, 26.281554],
          [61.991603, 26.281659],
          [61.991835, 26.281667],
          [61.991964, 26.281798],
          [61.992165, 26.281826],
          [61.992215, 26.281881],
          [61.992529, 26.281892],
          [61.992655, 26.281951],
          [61.993155, 26.282019],
          [61.993316, 26.282146],
          [61.993393, 26.28238],
          [61.993566, 26.282509],
          [61.993725, 26.282462],
          [61.993961, 26.282559],
          [61.994355, 26.282539],
          [61.994456, 26.282478],
          [61.994993, 26.282479],
          [61.995123, 26.282273],
          [61.995385, 26.28213],
          [61.995584, 26.282085],
          [61.995707, 26.281836],
          [61.995783, 26.281785],
          [61.995811, 26.281426],
          [61.996059, 26.281121],
          [61.996164, 26.28106],
          [61.996091, 26.280885],
          [61.996211, 26.280771],
          [61.996332, 26.280722],
          [61.996509, 26.280766],
          [61.996688, 26.280879],
          [61.996801, 26.280866],
          [61.996962, 26.280779],
          [61.996954, 26.280706],
          [61.99709, 26.280548],
          [61.997223, 26.280631],
          [61.997447, 26.281004],
          [61.997558, 26.281365],
          [61.9977, 26.281526],
          [61.997718, 26.281767],
          [61.998102, 26.282516],
          [61.998231, 26.28265],
          [61.998347, 26.282626],
          [61.998299, 26.28276],
          [61.998456, 26.282704],
          [61.998696, 26.282984],
          [61.99912, 26.282938],
          [61.999254, 26.283074],
          [61.999576, 26.283138],
          [61.999632, 26.283216],
          [61.999874, 26.28329],
          [61.99988, 26.283336],
          [62.000272, 26.28336],
          [62.00044, 26.283507],
          [62.000614, 26.283819],
          [62.00059, 26.283982],
          [62.000712, 26.28433],
          [62.000644, 26.284486],
          [62.000733, 26.28454],
          [62.000715, 26.284635],
          [62.000832, 26.284597],
          [62.001036, 26.28462],
          [62.001143, 26.285413],
          [62.001343, 26.285497],
          [62.001382, 26.285611],
          [62.001432, 26.285594],
          [62.001643, 26.285777],
          [62.001714, 26.285774],
          [62.001864, 26.285904],
          [62.001894, 26.286014],
          [62.002005, 26.28608],
          [62.00198, 26.286153],
          [62.002082, 26.286381],
          [62.002085, 26.286517],
          [62.002169, 26.286634],
          [62.002182, 26.286845],
          [62.002319, 26.286861],
          [62.002572, 26.286977],
          [62.002751, 26.286993],
          [62.002904, 26.287093],
          [62.002795, 26.28739],
          [62.002874, 26.287688],
          [62.002982, 26.287749],
          [62.003027, 26.288056],
          [62.003013, 26.288129],
          [62.002914, 26.288208],
          [62.00285, 26.288648],
          [62.002891, 26.288726],
          [62.003129, 26.288781],
          [62.003147, 26.288825],
          [62.003237, 26.288761],
          [62.003305, 26.288908],
          [62.00344, 26.28897],
          [62.003513, 26.28895],
          [62.003514, 26.289053],
          [62.003631, 26.289208],
          [62.003821, 26.289291],
          [62.003782, 26.289374],
          [62.003829, 26.289399],
          [62.003828, 26.289547],
          [62.003938, 26.289714],
          [62.003966, 26.289884],
          [62.004021, 26.28988],
          [62.00403, 26.289947],
          [62.004153, 26.290006],
          [62.00426, 26.290204],
          [62.004081, 26.290616],
          [62.004068, 26.290831],
          [62.004104, 26.29089],
          [62.004029, 26.290944],
          [62.004201, 26.291175],
          [62.004285, 26.291177],
          [62.004133, 26.291399],
          [62.004145, 26.29191],
          [62.004231, 26.291896],
          [62.004687, 26.292126],
          [62.004984, 26.292139],
          [62.005239, 26.292217],
          [62.005373, 26.292483],
          [62.005609, 26.292627],
          [62.005699, 26.292893],
          [62.005757, 26.292916],
          [62.005765, 26.292994],
          [62.005685, 26.293049],
          [62.005742, 26.293137],
          [62.005789, 26.293105],
          [62.005798, 26.293161],
          [62.005883, 26.293144],
          [62.0058, 26.293294],
          [62.005822, 26.293329],
          [62.005682, 26.293453],
          [62.005567, 26.293734],
          [62.005616, 26.293797],
          [62.005527, 26.293949],
          [62.005733, 26.294176],
          [62.005797, 26.294179],
          [62.005848, 26.29428],
          [62.005972, 26.294317],
          [62.005967, 26.294391],
          [62.006112, 26.294572],
          [62.006181, 26.294599],
          [62.006192, 26.294692],
          [62.006237, 26.294693],
          [62.006307, 26.294826],
          [62.006426, 26.29491],
          [62.006448, 26.295002],
          [62.006657, 26.295169],
          [62.00667, 26.29526],
          [62.007124, 26.295285],
          [62.007813, 26.295582],
          [62.008377, 26.295677],
          [62.008711, 26.295891],
          [62.008971, 26.295927],
          [62.009047, 26.295974],
          [62.009148, 26.295944],
          [62.009249, 26.296055],
          [62.009552, 26.296152],
          [62.009664, 26.296259],
          [62.009817, 26.296323],
          [62.00988, 26.296409],
          [62.009986, 26.296416],
          [62.01005, 26.296492],
          [62.010126, 26.296471],
          [62.010124, 26.296589],
          [62.010361, 26.296765],
          [62.010512, 26.296969],
          [62.010677, 26.296906],
          [62.010743, 26.29701],
          [62.010614, 26.297225],
          [62.010587, 26.297653],
          [62.010483, 26.297709],
          [62.010516, 26.297889],
          [62.010362, 26.298141],
          [62.010354, 26.298312],
          [62.01018, 26.298486],
          [62.009712, 26.298728],
          [62.009762, 26.298837],
          [62.009674, 26.298969],
          [62.009745, 26.298968],
          [62.009894, 26.299161],
          [62.009983, 26.29948],
          [62.009934, 26.299611],
          [62.009957, 26.299664],
          [62.009987, 26.299722],
          [62.010109, 26.299775],
          [62.01006, 26.299852],
          [62.010105, 26.299923],
          [62.010332, 26.300107],
          [62.010398, 26.300085],
          [62.010353, 26.300166],
          [62.010397, 26.300221],
          [62.010696, 26.300158],
          [62.0107, 26.300258],
          [62.010771, 26.30023],
          [62.010796, 26.300289],
          [62.011086, 26.300063],
          [62.011346, 26.300333],
          [62.011433, 26.300294],
          [62.011555, 26.300309],
          [62.011574, 26.300273],
          [62.011694, 26.300374],
          [62.011851, 26.300347],
          [62.011893, 26.300414],
          [62.012093, 26.300405],
          [62.012134, 26.300513],
          [62.012078, 26.300528],
          [62.012097, 26.30059],
          [62.012186, 26.300603],
          [62.012236, 26.300559],
          [62.012453, 26.300632],
          [62.012615, 26.300747],
          [62.012728, 26.30093],
          [62.012726, 26.301059],
          [62.012906, 26.301119],
          [62.012934, 26.301207],
          [62.013162, 26.301286],
          [62.01332, 26.30144],
          [62.01332, 26.30168],
          [62.013395, 26.301766],
          [62.013314, 26.301913],
          [62.013326, 26.301982],
          [62.013526, 26.302181],
          [62.013516, 26.302355],
          [62.013453, 26.302423],
          [62.013527, 26.302572],
          [62.013523, 26.302668],
          [62.013727, 26.302869],
          [62.013797, 26.303015],
          [62.013927, 26.303065],
          [62.013968, 26.303022],
          [62.014126, 26.303064],
          [62.014145, 26.302974],
          [62.014315, 26.302964],
          [62.014631, 26.302483],
          [62.014787, 26.302571],
          [62.014899, 26.302514],
          [62.01505, 26.302552],
          [62.015186, 26.30245],
          [62.015539, 26.302711],
          [62.015617, 26.30273],
          [62.015737, 26.302675],
          [62.015732, 26.302807],
          [62.015836, 26.302904],
          [62.015863, 26.303028],
          [62.015982, 26.303142],
          [62.016126, 26.303203],
          [62.016382, 26.303567],
          [62.016476, 26.303617],
          [62.016517, 26.303697],
          [62.016661, 26.303728],
          [62.016674, 26.303805],
          [62.016757, 26.303859],
          [62.016922, 26.303741],
          [62.017547, 26.30373],
          [62.017696, 26.303661],
          [62.017795, 26.303697],
          [62.018213, 26.303635],
          [62.018292, 26.303668],
          [62.018392, 26.30354],
          [62.018701, 26.303357],
          [62.018799, 26.303387],
          [62.01889, 26.303268],
          [62.018945, 26.303371],
          [62.019005, 26.303361],
          [62.019002, 26.303416],
          [62.019122, 26.30338],
          [62.019269, 26.303426],
          [62.019398, 26.303588],
          [62.019769, 26.303417],
          [62.019876, 26.303475],
          [62.020182, 26.303402],
          [62.020299, 26.303517],
          [62.020527, 26.303375],
          [62.020767, 26.303345],
          [62.020851, 26.303293],
          [62.021114, 26.30305],
          [62.0212, 26.302883],
          [62.021346, 26.302831],
          [62.021346, 26.302872],
          [62.021573, 26.302905],
          [62.02158, 26.302852],
          [62.021768, 26.302815],
          [62.021902, 26.303066],
          [62.02187, 26.303151],
          [62.02195, 26.303178],
          [62.022006, 26.303409],
          [62.022072, 26.303455],
          [62.02215, 26.303849],
          [62.022291, 26.303949],
          [62.022175, 26.304129],
          [62.022069, 26.304136],
          [62.022188, 26.304397],
          [62.022325, 26.304568],
          [62.022276, 26.304656],
          [62.022045, 26.304757],
          [62.022051, 26.304922],
          [62.021935, 26.305048],
          [62.021964, 26.305087],
          [62.021911, 26.305101],
          [62.021991, 26.305225],
          [62.021957, 26.305416],
          [62.02204, 26.305484],
          [62.022013, 26.305524],
          [62.022109, 26.30568],
          [62.022087, 26.305954],
          [62.02214, 26.305962],
          [62.022185, 26.306115],
          [62.022092, 26.306212],
          [62.022027, 26.306199],
          [62.021838, 26.306648],
          [62.021694, 26.306683],
          [62.021658, 26.306984],
          [62.021527, 26.307069],
          [62.021416, 26.307256],
          [62.021299, 26.307309],
          [62.02128, 26.307427],
          [62.021074, 26.307551],
          [62.021064, 26.307793],
          [62.021159, 26.308042],
          [62.021372, 26.308217],
          [62.021412, 26.30821],
          [62.021394, 26.308568],
          [62.021648, 26.308605],
          [62.02179, 26.308671],
          [62.021827, 26.308733],
          [62.022045, 26.308708],
          [62.021964, 26.308828],
          [62.022028, 26.308891],
          [62.022009, 26.30893],
          [62.022051, 26.308914],
          [62.021999, 26.309144],
          [62.022035, 26.309187],
          [62.021984, 26.309256],
          [62.02214, 26.30932],
          [62.022243, 26.30945],
          [62.02241, 26.309441],
          [62.022533, 26.309582],
          [62.022771, 26.309586],
          [62.022897, 26.309685],
          [62.023018, 26.309657],
          [62.023185, 26.309812],
          [62.023476, 26.309837],
          [62.023626, 26.309968],
          [62.023792, 26.309978],
          [62.023795, 26.310062],
          [62.024072, 26.310177],
          [62.024202, 26.310158],
          [62.024259, 26.310244],
          [62.024327, 26.310255],
          [62.024251, 26.310568],
          [62.024061, 26.310763],
          [62.02395, 26.311134],
          [62.024106, 26.311141],
          [62.024282, 26.311294],
          [62.024306, 26.311364],
          [62.024196, 26.311543],
          [62.024277, 26.311563],
          [62.024557, 26.311844],
          [62.024795, 26.311662],
          [62.024819, 26.311718],
          [62.024877, 26.31167],
          [62.025283, 26.311635],
          [62.025607, 26.311789],
          [62.025588, 26.311925],
          [62.025648, 26.31197],
          [62.025729, 26.31197],
          [62.025957, 26.312121],
          [62.026075, 26.312131],
          [62.026304, 26.312379],
          [62.026408, 26.312406],
          [62.026418, 26.312522],
          [62.02651, 26.312522],
          [62.026533, 26.312573],
          [62.026828, 26.312661],
          [62.026936, 26.312552],
          [62.027061, 26.31254],
          [62.027066, 26.312506],
          [62.027146, 26.312523],
          [62.027155, 26.312453],
          [62.027446, 26.312492],
          [62.02749, 26.312463],
          [62.027623, 26.312539],
          [62.027794, 26.312478],
          [62.02785, 26.31252],
          [62.027882, 26.312485],
          [62.027947, 26.312542],
          [62.028154, 26.312538],
          [62.028305, 26.312712],
          [62.028383, 26.312701],
          [62.028477, 26.312587],
          [62.028562, 26.312586],
          [62.028588, 26.312633],
          [62.028575, 26.31296],
          [62.028719, 26.3133],
          [62.028778, 26.313587],
          [62.028759, 26.313722],
          [62.028601, 26.313873],
          [62.028629, 26.313948],
          [62.028448, 26.314046],
          [62.028249, 26.314282],
          [62.028234, 26.314432],
          [62.028322, 26.314581],
          [62.028068, 26.31491],
          [62.028097, 26.315028],
          [62.027902, 26.315175],
          [62.027973, 26.315172],
          [62.028004, 26.315232],
          [62.027946, 26.31546],
          [62.027971, 26.315702],
          [62.028187, 26.315698],
          [62.028241, 26.316102],
          [62.028483, 26.316304],
          [62.028497, 26.316436],
          [62.028431, 26.316518],
          [62.028276, 26.316571],
          [62.028175, 26.316793],
          [62.02807, 26.316884],
          [62.028252, 26.31729],
          [62.028366, 26.317394],
          [62.028446, 26.317392],
          [62.028492, 26.31748],
          [62.028444, 26.317575],
          [62.028494, 26.317627],
          [62.028383, 26.317708],
          [62.028339, 26.317849],
          [62.02812, 26.317978],
          [62.028052, 26.318159],
          [62.027963, 26.318231],
          [62.027888, 26.318506],
          [62.027929, 26.31878],
          [62.027774, 26.319041],
          [62.02765, 26.319141],
          [62.027509, 26.319481],
          [62.027365, 26.319519],
          [62.02716, 26.319687],
          [62.027183, 26.319735],
          [62.027139, 26.319767],
          [62.026958, 26.319824],
          [62.026736, 26.319829],
          [62.026663, 26.31989],
          [62.02654, 26.320036],
          [62.02649, 26.320288],
          [62.026347, 26.320369],
          [62.026389, 26.320471],
          [62.026096, 26.320916],
          [62.026453, 26.321266],
          [62.026745, 26.321271],
          [62.027083, 26.321475],
          [62.027151, 26.321627],
          [62.027138, 26.321826],
          [62.027211, 26.32189],
          [62.027286, 26.321867],
          [62.027384, 26.321908],
          [62.027667, 26.32212],
          [62.027951, 26.322223],
          [62.028109, 26.322224],
          [62.028118, 26.322334],
          [62.028186, 26.322402],
          [62.028267, 26.322628],
          [62.028273, 26.32286],
          [62.028458, 26.323063],
          [62.028418, 26.323152],
          [62.028276, 26.323211],
          [62.027883, 26.323539],
          [62.027898, 26.32361],
          [62.027802, 26.323828],
          [62.028134, 26.324417],
          [62.028157, 26.324695],
          [62.028217, 26.324726],
          [62.028249, 26.324819],
          [62.028166, 26.324846],
          [62.028119, 26.325029],
          [62.027954, 26.325062],
          [62.027785, 26.325177],
          [62.027567, 26.325465],
          [62.027428, 26.325494],
          [62.027176, 26.32547],
          [62.026707, 26.325682],
          [62.026414, 26.325909],
          [62.026403, 26.32608],
          [62.026809, 26.326159],
          [62.026822, 26.326234],
          [62.027097, 26.3266],
          [62.02705, 26.326796],
          [62.027101, 26.327143],
          [62.027234, 26.327181],
          [62.027497, 26.327385],
          [62.027905, 26.32783],
          [62.027979, 26.327843],
          [62.027945, 26.327892],
          [62.028224, 26.328179],
          [62.028542, 26.328068],
          [62.028763, 26.328116],
          [62.02883, 26.328192],
          [62.029183, 26.328357],
          [62.029412, 26.328398],
          [62.029403, 26.328563],
          [62.030002, 26.329029],
          [62.029793, 26.329773],
          [62.02974, 26.330203],
          [62.02982, 26.330299],
          [62.029813, 26.330546],
          [62.029857, 26.33065],
          [62.029815, 26.330982],
          [62.029748, 26.331143],
          [62.02979, 26.331284],
          [62.029602, 26.331501],
          [62.02957, 26.331602],
          [62.029598, 26.331745],
          [62.02944, 26.332079],
          [62.02943, 26.332264],
          [62.029288, 26.332394],
          [62.029243, 26.332683],
          [62.02928, 26.332786],
          [62.02949, 26.33295],
          [62.029563, 26.33308],
          [62.029572, 26.333426],
          [62.029791, 26.333616],
          [62.030346, 26.333709],
          [62.0308, 26.333843],
          [62.031459, 26.333676],
          [62.031741, 26.333424],
          [62.031855, 26.333678],
          [62.032052, 26.33382],
          [62.032327, 26.333744],
          [62.032444, 26.333872],
          [62.032553, 26.333884],
          [62.032612, 26.333838],
          [62.032649, 26.333928],
          [62.032959, 26.334175],
          [62.033147, 26.334122],
          [62.033239, 26.334138],
          [62.033312, 26.334235],
          [62.033318, 26.334419],
          [62.033364, 26.334491],
          [62.033713, 26.334364],
          [62.033788, 26.334415],
          [62.033853, 26.334378],
          [62.034256, 26.334372],
          [62.034766, 26.333951],
          [62.034891, 26.334028],
          [62.035084, 26.334043],
          [62.035199, 26.334135],
          [62.03532, 26.334159],
          [62.035657, 26.333907],
          [62.035773, 26.333909],
          [62.035762, 26.333785],
          [62.035932, 26.333608],
          [62.035898, 26.333567],
          [62.036201, 26.333387],
          [62.036482, 26.333312],
          [62.036696, 26.333199],
          [62.036907, 26.332936],
          [62.037263, 26.332904],
          [62.037365, 26.332847],
          [62.03746, 26.332867],
          [62.037654, 26.332772],
          [62.037807, 26.332768],
          [62.03785, 26.332717],
          [62.038875, 26.332854],
          [62.039081, 26.33294],
          [62.039118, 26.333047],
          [62.039334, 26.333141],
          [62.039512, 26.333043],
          [62.039769, 26.333083],
          [62.039752, 26.332984],
          [62.039955, 26.33292],
          [62.040478, 26.333016],
          [62.040724, 26.333021],
          [62.040814, 26.333196],
          [62.041093, 26.333245],
          [62.041334, 26.333363],
          [62.041494, 26.333366],
          [62.041811, 26.333529],
          [62.042049, 26.333493],
          [62.042106, 26.333567],
          [62.042106, 26.333676],
          [62.042628, 26.333828],
          [62.043058, 26.333752],
          [62.043295, 26.333771],
          [62.043274, 26.333821],
          [62.043386, 26.333842],
          [62.043546, 26.333729],
          [62.043734, 26.333781],
          [62.044648, 26.333603],
          [62.04463, 26.333518],
          [62.044836, 26.333391],
          [62.044853, 26.333263],
          [62.044919, 26.333208],
          [62.044878, 26.333183],
          [62.044909, 26.333092],
          [62.044804, 26.333046],
          [62.044842, 26.333001],
          [62.044632, 26.332837],
          [62.044531, 26.332654],
          [62.044709, 26.33261],
          [62.044756, 26.332533],
          [62.045273, 26.332483],
          [62.045379, 26.332394],
          [62.045703, 26.332409],
          [62.045776, 26.332269],
          [62.045883, 26.332202],
          [62.046104, 26.332311],
          [62.046581, 26.332398],
          [62.046991, 26.332399],
          [62.047451, 26.33247],
          [62.047607, 26.332456],
          [62.048085, 26.332254],
          [62.048016, 26.331828],
          [62.048038, 26.331771],
          [62.048123, 26.331745],
          [62.048114, 26.331697],
          [62.04825, 26.331685],
          [62.048152, 26.331524],
          [62.048094, 26.331504],
          [62.048119, 26.331407],
          [62.048074, 26.331352],
          [62.048485, 26.331332],
          [62.048723, 26.331176],
          [62.048706, 26.331138],
          [62.048829, 26.331052],
          [62.048901, 26.330923],
          [62.049186, 26.330935],
          [62.04921, 26.330821],
          [62.04937, 26.330783],
          [62.049388, 26.33056],
          [62.049676, 26.330523],
          [62.049811, 26.330546],
          [62.049861, 26.330466],
          [62.049911, 26.330481],
          [62.04995, 26.330438],
          [62.049902, 26.330402],
          [62.050146, 26.330258],
          [62.050119, 26.330082],
          [62.050399, 26.329903],
          [62.050313, 26.329585],
          [62.050434, 26.329434],
          [62.050398, 26.329375],
          [62.050468, 26.329323],
          [62.050495, 26.329199],
          [62.050892, 26.329354],
          [62.050893, 26.329315],
          [62.051112, 26.329234],
          [62.051368, 26.329211],
          [62.051601, 26.329207],
          [62.051743, 26.32928],
          [62.051819, 26.329199],
          [62.051991, 26.329311],
          [62.052016, 26.329258],
          [62.052142, 26.329235],
          [62.052221, 26.329106],
          [62.052388, 26.329004],
          [62.052531, 26.329073],
          [62.052777, 26.328938],
          [62.052866, 26.329028],
          [62.053123, 26.329095],
          [62.05321, 26.329184],
          [62.053282, 26.329195],
          [62.05352, 26.329135],
          [62.05354, 26.329074],
          [62.053723, 26.329033],
          [62.053745, 26.328995],
          [62.053921, 26.329054],
          [62.054066, 26.328995],
          [62.054433, 26.329142],
          [62.054676, 26.329005],
          [62.054774, 26.329047],
          [62.055044, 26.329014],
          [62.055468, 26.329046],
          [62.055569, 26.329026],
          [62.055629, 26.328854],
          [62.055899, 26.328749],
          [62.055944, 26.328692],
          [62.056364, 26.32872],
          [62.056418, 26.328766],
          [62.056576, 26.328567],
          [62.056871, 26.328715],
          [62.057022, 26.328634],
          [62.05724, 26.328638],
          [62.057523, 26.328502],
          [62.057645, 26.328519],
          [62.057836, 26.328383],
          [62.058496, 26.328313],
          [62.05892, 26.328137],
          [62.059141, 26.327857],
          [62.059342, 26.327942],
          [62.059346, 26.328022],
          [62.059458, 26.328086],
          [62.059606, 26.328077],
          [62.05962, 26.328029],
          [62.059675, 26.328022],
          [62.060115, 26.328287],
          [62.060298, 26.328151],
          [62.060706, 26.32809],
          [62.061093, 26.327803],
          [62.06117, 26.327799],
          [62.061164, 26.327349],
          [62.061275, 26.327278],
          [62.061297, 26.327156],
          [62.061258, 26.327135],
          [62.061266, 26.327028],
          [62.061112, 26.326825],
          [62.06138, 26.326755],
          [62.06153, 26.326659],
          [62.061617, 26.326669],
          [62.061672, 26.32658],
          [62.062058, 26.326653],
          [62.062197, 26.326548],
          [62.06221, 26.326635],
          [62.062435, 26.326674],
          [62.062614, 26.326488],
          [62.06264, 26.326532],
          [62.062971, 26.326506],
          [62.062968, 26.326428],
          [62.063023, 26.326401],
          [62.063084, 26.326482],
          [62.063175, 26.326483],
          [62.063167, 26.326531],
          [62.063217, 26.326558],
          [62.063304, 26.326538],
          [62.063303, 26.326574],
          [62.063501, 26.32662],
          [62.063635, 26.326583],
          [62.06366, 26.326653],
          [62.063758, 26.326652],
          [62.063846, 26.326748],
          [62.063985, 26.326774],
          [62.06404, 26.326919],
          [62.06413, 26.326988],
          [62.064152, 26.327124],
          [62.064402, 26.327062],
          [62.06477, 26.327117],
          [62.064874, 26.327221],
          [62.065038, 26.327249],
          [62.065206, 26.32719],
          [62.065314, 26.32731],
          [62.065423, 26.327328],
          [62.065572, 26.327272],
          [62.065615, 26.327318],
          [62.065749, 26.327271],
          [62.065896, 26.327282],
          [62.065901, 26.327242],
          [62.066081, 26.327194],
          [62.066055, 26.326955],
          [62.066207, 26.326859],
          [62.06629, 26.326726],
          [62.066993, 26.326899],
          [62.067131, 26.326851],
          [62.067335, 26.326637],
          [62.067358, 26.326466],
          [62.067432, 26.32641],
          [62.067621, 26.326501],
          [62.067739, 26.326465],
          [62.068345, 26.326482],
          [62.068405, 26.326622],
          [62.068703, 26.326613],
          [62.068789, 26.326694],
          [62.068891, 26.326696],
          [62.068916, 26.326632],
          [62.069087, 26.326557],
          [62.069364, 26.326547],
          [62.069793, 26.326816],
          [62.070059, 26.32678],
          [62.070363, 26.326855],
          [62.070693, 26.3268],
          [62.070791, 26.326824],
          [62.071117, 26.326737],
          [62.071148, 26.326672],
          [62.070957, 26.32645],
          [62.071039, 26.32627],
          [62.071078, 26.325993],
          [62.071205, 26.325981],
          [62.071142, 26.325911],
          [62.071106, 26.325736],
          [62.071208, 26.325705],
          [62.071431, 26.325505],
          [62.071619, 26.325451],
          [62.071891, 26.32546],
          [62.07193, 26.325419],
          [62.072373, 26.325359],
          [62.072529, 26.325415],
          [62.072557, 26.325469],
          [62.072746, 26.325476],
          [62.072817, 26.32541],
          [62.073019, 26.325348],
          [62.073236, 26.325308],
          [62.073366, 26.325341],
          [62.073675, 26.325196],
          [62.073846, 26.325026],
          [62.073917, 26.324831],
          [62.074044, 26.324799],
          [62.074261, 26.324659],
          [62.07421, 26.324517],
          [62.074146, 26.324471],
          [62.074151, 26.324159],
          [62.074007, 26.324112],
          [62.073973, 26.323855],
          [62.073855, 26.323722],
          [62.073936, 26.323645],
          [62.074075, 26.323652],
          [62.074215, 26.323558],
          [62.074314, 26.323561],
          [62.074317, 26.323601],
          [62.074666, 26.323513],
          [62.074837, 26.32334],
          [62.074912, 26.32316],
          [62.075071, 26.323058],
          [62.075021, 26.322978],
          [62.075093, 26.322939],
          [62.075161, 26.32278],
          [62.075339, 26.322665],
          [62.075461, 26.3227],
          [62.07553, 26.322664],
          [62.07549, 26.322433],
          [62.075556, 26.322422],
          [62.075537, 26.322196],
          [62.07563, 26.322131],
          [62.07584, 26.321711],
          [62.076012, 26.321737],
          [62.076168, 26.321684],
          [62.076474, 26.321368],
          [62.076945, 26.321205],
          [62.076938, 26.321162],
          [62.077246, 26.320879],
          [62.077522, 26.320764],
          [62.077586, 26.320675],
          [62.077965, 26.320739],
          [62.078332, 26.320646],
          [62.078453, 26.320516],
          [62.078545, 26.320501],
          [62.078437, 26.320431],
          [62.078476, 26.320417],
          [62.078491, 26.32025],
          [62.078542, 26.320239],
          [62.078643, 26.320059],
          [62.078629, 26.320006],
          [62.078841, 26.319845],
          [62.079098, 26.319895],
          [62.079123, 26.319817],
          [62.079201, 26.319832],
          [62.0792, 26.319761],
          [62.079304, 26.319801],
          [62.079437, 26.31977],
          [62.079479, 26.31981],
          [62.079643, 26.319771],
          [62.079793, 26.31964],
          [62.079888, 26.319638],
          [62.080227, 26.319488],
          [62.080267, 26.319386],
          [62.080585, 26.319175],
          [62.080646, 26.319017],
          [62.080935, 26.319004],
          [62.080974, 26.31891],
          [62.081433, 26.318692],
          [62.081756, 26.318829],
          [62.081791, 26.318935],
          [62.082168, 26.319033],
          [62.082371, 26.318994],
          [62.08247, 26.319054],
          [62.08264, 26.318974],
          [62.082668, 26.319034],
          [62.082809, 26.319068],
          [62.083209, 26.318922],
          [62.083273, 26.318944],
          [62.083369, 26.318877],
          [62.083471, 26.318922],
          [62.083564, 26.31883],
          [62.083827, 26.318713],
          [62.083807, 26.318591],
          [62.083853, 26.318572],
          [62.083854, 26.318465],
          [62.084098, 26.318285],
          [62.084066, 26.31822],
          [62.084228, 26.318184],
          [62.084471, 26.318335],
          [62.084919, 26.318855],
          [62.085181, 26.318861],
          [62.085378, 26.318569],
          [62.085281, 26.318508],
          [62.085308, 26.318162],
          [62.085224, 26.317991],
          [62.085419, 26.317835],
          [62.085397, 26.317744],
          [62.085476, 26.317684],
          [62.085473, 26.317627],
          [62.085596, 26.317577],
          [62.085628, 26.317601],
          [62.085608, 26.317506],
          [62.085723, 26.317408],
          [62.085823, 26.317124],
          [62.085795, 26.316842],
          [62.08575, 26.316855],
          [62.085664, 26.316784],
          [62.085653, 26.316707],
          [62.085752, 26.316671],
          [62.085972, 26.316752],
          [62.086414, 26.316539],
          [62.086569, 26.316486],
          [62.086693, 26.316495],
          [62.086657, 26.316373],
          [62.086788, 26.316231],
          [62.086944, 26.316074],
          [62.087361, 26.315925],
          [62.087495, 26.315747],
          [62.087492, 26.315633],
          [62.087645, 26.315585],
          [62.08777, 26.315627],
          [62.088324, 26.315649],
          [62.088585, 26.315753],
          [62.088915, 26.315281],
          [62.088819, 26.314933],
          [62.089214, 26.314805],
          [62.089427, 26.31487],
          [62.089989, 26.314705],
          [62.090263, 26.314572],
          [62.090349, 26.314473],
          [62.09029, 26.314033],
          [62.09036, 26.313928],
          [62.090387, 26.313746],
          [62.090556, 26.313647],
          [62.090596, 26.313542],
          [62.090924, 26.313422],
          [62.090976, 26.313347],
          [62.091121, 26.313315],
          [62.091234, 26.313026],
          [62.091407, 26.31285],
          [62.091394, 26.312426],
          [62.091337, 26.312397],
          [62.0913, 26.312267],
          [62.091511, 26.312237],
          [62.091701, 26.312293],
          [62.091925, 26.312283],
          [62.092088, 26.312154],
          [62.092143, 26.31194],
          [62.092284, 26.311753],
          [62.092372, 26.311719],
          [62.092373, 26.311626],
          [62.092498, 26.311491],
          [62.092567, 26.311479],
          [62.092573, 26.31141],
          [62.09264, 26.311361],
          [62.092717, 26.31111],
          [62.093059, 26.31093],
          [62.093142, 26.310736],
          [62.093442, 26.310484],
          [62.093476, 26.310254],
          [62.093564, 26.310166],
          [62.093561, 26.309959],
          [62.093631, 26.309871],
          [62.093603, 26.309794],
          [62.093688, 26.309708],
          [62.093703, 26.309597],
          [62.094176, 26.3091],
          [62.094343, 26.309075],
          [62.094344, 26.308539],
          [62.094606, 26.308455],
          [62.095005, 26.308129],
          [62.095064, 26.30772],
          [62.095308, 26.307655],
          [62.095511, 26.307646],
          [62.095586, 26.30769],
          [62.096052, 26.307632],
          [62.096405, 26.307488],
          [62.096516, 26.307352],
          [62.096539, 26.307182],
          [62.096652, 26.307196],
          [62.096687, 26.307152],
          [62.096982, 26.307116],
          [62.097778, 26.30674],
          [62.09766, 26.306675],
          [62.097596, 26.306151],
          [62.097547, 26.306047],
          [62.097488, 26.306052],
          [62.097428, 26.30598],
          [62.097459, 26.305931],
          [62.097406, 26.305781],
          [62.097185, 26.305706],
          [62.096912, 26.305386],
          [62.09692, 26.30533],
          [62.097043, 26.305266],
          [62.096988, 26.305245],
          [62.096991, 26.305007],
          [62.09707, 26.304794],
          [62.097192, 26.304627],
          [62.097347, 26.304644],
          [62.097292, 26.304553],
          [62.097304, 26.304389],
          [62.097332, 26.304325],
          [62.097398, 26.304314],
          [62.09732, 26.304198],
          [62.097272, 26.304196],
          [62.097272, 26.304127],
          [62.097414, 26.304013],
          [62.097793, 26.303898],
          [62.097933, 26.30376],
          [62.097894, 26.303701],
          [62.097949, 26.303531],
          [62.097888, 26.303232],
          [62.097803, 26.303192],
          [62.097742, 26.302716],
          [62.097591, 26.302512],
          [62.097397, 26.302604],
          [62.097296, 26.30255],
          [62.097236, 26.302244],
          [62.097169, 26.302151],
          [62.096951, 26.302106],
          [62.096972, 26.302036],
          [62.096637, 26.30183],
          [62.096583, 26.301719],
          [62.096483, 26.30167],
          [62.096458, 26.301554],
          [62.096387, 26.301555],
          [62.096376, 26.301421],
          [62.09622, 26.301431],
          [62.096175, 26.301334],
          [62.09582, 26.301322],
          [62.095854, 26.30112],
          [62.09576, 26.30107],
          [62.095671, 26.300905],
          [62.095578, 26.300833],
          [62.095577, 26.300577],
          [62.095524, 26.300552],
          [62.095569, 26.30043],
          [62.095531, 26.300087],
          [62.09566, 26.300131],
          [62.096086, 26.299984],
          [62.096211, 26.299903],
          [62.096253, 26.29971],
          [62.096387, 26.299671],
          [62.096415, 26.299607],
          [62.096565, 26.299582],
          [62.096479, 26.299487],
          [62.096562, 26.29932],
          [62.096549, 26.299235],
          [62.096688, 26.299075],
          [62.096925, 26.298932],
          [62.096874, 26.298891],
          [62.096901, 26.298821],
          [62.096728, 26.298708],
          [62.096736, 26.298635],
          [62.096638, 26.298483],
          [62.096282, 26.29846],
          [62.095957, 26.298356],
          [62.095971, 26.298146],
          [62.095858, 26.298061],
          [62.095668, 26.298006],
          [62.095645, 26.29796],
          [62.095469, 26.297968],
          [62.09528, 26.297835],
          [62.095267, 26.297772],
          [62.095047, 26.297691],
          [62.095226, 26.29764],
          [62.095244, 26.297375],
          [62.095381, 26.297431],
          [62.09549, 26.297396],
          [62.095534, 26.297326],
          [62.095743, 26.297278],
          [62.095863, 26.297102],
          [62.095806, 26.296972],
          [62.095839, 26.296836],
          [62.095951, 26.296719],
          [62.095897, 26.296703],
          [62.095848, 26.296602],
          [62.095889, 26.296543],
          [62.095726, 26.296311],
          [62.09599, 26.296043],
          [62.09613, 26.296031],
          [62.096146, 26.295899],
          [62.096337, 26.295753],
          [62.096527, 26.29568],
          [62.096585, 26.295591],
          [62.096801, 26.295626],
          [62.096874, 26.295581],
          [62.097101, 26.295557],
          [62.097534, 26.295375],
          [62.097663, 26.295196],
          [62.098025, 26.295141],
          [62.098278, 26.294946],
          [62.098302, 26.294821],
          [62.09826, 26.294752],
          [62.097971, 26.294557],
          [62.098004, 26.294486],
          [62.097954, 26.294295],
          [62.097735, 26.294064],
          [62.097815, 26.293868],
          [62.097591, 26.293711],
          [62.097503, 26.293715],
          [62.097285, 26.293601],
          [62.097186, 26.293096],
          [62.097233, 26.292971],
          [62.097344, 26.292865],
          [62.097422, 26.292647],
          [62.097267, 26.292548],
          [62.097297, 26.292439],
          [62.097081, 26.292164],
          [62.097044, 26.291965],
          [62.096849, 26.291929],
          [62.096816, 26.29179],
          [62.0964, 26.291499],
          [62.096455, 26.291353],
          [62.096716, 26.291253],
          [62.096976, 26.290999],
          [62.097139, 26.290895],
          [62.097484, 26.290828],
          [62.097625, 26.290743],
          [62.097672, 26.29076],
          [62.097725, 26.290685],
          [62.097859, 26.290638],
          [62.097925, 26.290513],
          [62.097943, 26.290425],
          [62.097766, 26.29028],
          [62.097722, 26.290143],
          [62.097523, 26.289881],
          [62.097689, 26.289741],
          [62.097753, 26.289621],
          [62.097669, 26.289575],
          [62.097633, 26.289467],
          [62.09739, 26.289218],
          [62.097371, 26.288948],
          [62.097278, 26.288913],
          [62.097228, 26.288815],
          [62.097252, 26.288506],
          [62.097175, 26.288413],
          [62.097308, 26.288269],
          [62.09731, 26.28821],
          [62.097657, 26.287984],
          [62.097703, 26.287885],
          [62.097668, 26.287664],
          [62.097755, 26.287573],
          [62.097821, 26.287393],
          [62.097899, 26.287342],
          [62.098019, 26.287059],
          [62.098341, 26.286815],
          [62.098196, 26.286675],
          [62.097972, 26.286573],
          [62.097897, 26.286314],
          [62.098018, 26.2862],
          [62.09834, 26.286057],
          [62.098364, 26.285867],
          [62.098288, 26.285776],
          [62.098548, 26.285745],
          [62.098773, 26.285653],
          [62.099264, 26.285552],
          [62.099439, 26.285474],
          [62.099538, 26.285337],
          [62.099627, 26.285347],
          [62.099778, 26.28528],
          [62.099749, 26.28524],
          [62.099847, 26.284969],
          [62.099844, 26.284743],
          [62.099982, 26.284608],
          [62.099978, 26.284446],
          [62.09992, 26.284327],
          [62.100255, 26.284266],
          [62.100374, 26.284134],
          [62.100541, 26.284077],
          [62.100762, 26.283803],
          [62.100835, 26.283813],
          [62.100907, 26.283765],
          [62.101038, 26.283837],
          [62.101187, 26.283821],
          [62.101284, 26.283895],
          [62.101459, 26.283797],
          [62.101534, 26.283555],
          [62.101697, 26.283483],
          [62.101893, 26.283472],
          [62.102006, 26.283292],
          [62.102265, 26.283226],
          [62.102423, 26.283268],
          [62.102542, 26.283399],
          [62.102775, 26.283381],
          [62.103013, 26.283266],
          [62.103184, 26.283234],
          [62.10363, 26.283285],
          [62.103744, 26.283133],
          [62.103894, 26.283063],
          [62.104053, 26.282846],
          [62.104197, 26.282736],
          [62.104223, 26.282622],
          [62.104365, 26.282434],
          [62.10436, 26.28236],
          [62.104491, 26.282237],
          [62.104762, 26.282252],
          [62.105003, 26.282092],
          [62.105091, 26.282077],
          [62.105299, 26.282109],
          [62.105593, 26.281926],
          [62.105901, 26.281894],
          [62.106035, 26.281959],
          [62.106198, 26.281914],
          [62.10623, 26.282002],
          [62.106491, 26.282093],
          [62.106585, 26.282187],
          [62.106903, 26.282324],
          [62.107016, 26.282288],
          [62.107007, 26.282216],
          [62.107087, 26.282164],
          [62.107272, 26.282161],
          [62.107373, 26.28227],
          [62.10783, 26.282203],
          [62.108411, 26.282031],
          [62.108683, 26.282142],
          [62.108695, 26.282211],
          [62.108941, 26.282456],
          [62.109531, 26.282591],
          [62.109708, 26.282818],
          [62.110039, 26.282686],
          [62.110134, 26.282768],
          [62.110386, 26.282718],
          [62.110528, 26.282781],
          [62.110641, 26.28277],
          [62.11075, 26.282637],
          [62.11094, 26.282608],
          [62.111328, 26.282369],
          [62.111418, 26.282295],
          [62.111472, 26.282145],
          [62.111627, 26.282028],
          [62.111859, 26.281929],
          [62.111869, 26.281881],
          [62.112015, 26.281865],
          [62.112114, 26.281806],
          [62.112275, 26.281524],
          [62.112504, 26.281528],
          [62.112834, 26.281323],
          [62.113326, 26.280881],
          [62.113352, 26.280807],
          [62.11341, 26.28084],
          [62.11371, 26.280715],
          [62.113827, 26.280712],
          [62.113858, 26.280525],
          [62.113628, 26.280194],
          [62.11362, 26.280005],
          [62.113933, 26.279863],
          [62.114031, 26.279758],
          [62.114144, 26.279796],
          [62.114148, 26.279756],
          [62.114405, 26.279658],
          [62.114542, 26.27948],
          [62.114734, 26.279363],
          [62.114713, 26.27932],
          [62.114875, 26.279334],
          [62.11496, 26.279134],
          [62.115106, 26.278978],
          [62.115303, 26.278995],
          [62.115623, 26.278901],
          [62.115614, 26.278838],
          [62.115714, 26.278686],
          [62.116013, 26.278703],
          [62.116065, 26.278652],
          [62.116421, 26.278622],
          [62.116478, 26.278526],
          [62.116622, 26.278449],
          [62.116635, 26.278269],
          [62.116706, 26.278219],
          [62.116814, 26.278215],
          [62.116963, 26.278384],
          [62.117075, 26.278385],
          [62.117198, 26.278507],
          [62.117297, 26.278499],
          [62.117424, 26.278566],
          [62.117689, 26.278484],
          [62.117859, 26.278529],
          [62.117942, 26.278492],
          [62.118107, 26.278518],
          [62.118205, 26.278464],
          [62.118352, 26.278216],
          [62.118449, 26.278162],
          [62.118627, 26.277939],
          [62.118749, 26.277971],
          [62.118791, 26.277924],
          [62.118872, 26.277927],
          [62.119178, 26.27761],
          [62.119356, 26.277289],
          [62.119722, 26.277022],
          [62.119911, 26.277021],
          [62.120168, 26.276773],
          [62.120768, 26.276924],
          [62.121057, 26.27718],
          [62.121288, 26.277136],
          [62.121301, 26.277183],
          [62.12192, 26.277429],
          [62.121969, 26.277291],
          [62.122168, 26.27719],
          [62.122288, 26.277043],
          [62.122571, 26.27704],
          [62.122977, 26.276903],
          [62.12373, 26.276805],
          [62.124112, 26.276375],
          [62.124214, 26.276314],
          [62.124236, 26.276087],
          [62.124302, 26.275951],
          [62.124463, 26.275812],
          [62.124404, 26.275761],
          [62.124592, 26.27546],
          [62.12455, 26.275333],
          [62.124758, 26.275235],
          [62.124862, 26.275106],
          [62.125019, 26.27505],
          [62.124999, 26.275012],
          [62.125076, 26.274942],
          [62.125088, 26.274847],
          [62.125195, 26.274764],
          [62.125158, 26.274172],
          [62.125388, 26.273986],
          [62.125928, 26.273994],
          [62.126293, 26.273879],
          [62.12648, 26.273711],
          [62.126691, 26.273739],
          [62.126895, 26.273664],
          [62.127231, 26.273791],
          [62.127421, 26.273775],
          [62.127744, 26.27359],
          [62.127857, 26.273451],
          [62.128209, 26.273445],
          [62.128431, 26.273511],
          [62.128872, 26.273391],
          [62.129226, 26.273556],
          [62.129487, 26.273622],
          [62.12964, 26.273725],
          [62.130465, 26.273857],
          [62.130788, 26.273798],
          [62.131181, 26.274082],
          [62.131906, 26.273663],
          [62.132006, 26.273532],
          [62.132417, 26.273455],
          [62.132631, 26.273466],
          [62.132734, 26.273377],
          [62.132725, 26.273308],
          [62.132905, 26.273081],
          [62.13307, 26.273073],
          [62.133319, 26.27299],
          [62.133828, 26.273047],
          [62.134118, 26.27289],
          [62.134412, 26.272843],
          [62.134401, 26.272627],
          [62.134608, 26.272401],
          [62.134532, 26.272255],
          [62.134554, 26.272162],
          [62.134699, 26.272034],
          [62.13489, 26.272013],
          [62.135135, 26.271696],
          [62.13522, 26.271693],
          [62.135418, 26.271796],
          [62.135428, 26.271841],
          [62.135844, 26.27208],
          [62.135842, 26.27214],
          [62.135974, 26.272157],
          [62.136179, 26.272101],
          [62.136217, 26.271988],
          [62.136493, 26.271874],
          [62.136695, 26.271957],
          [62.136846, 26.271957],
          [62.137041, 26.272186],
          [62.137427, 26.272226],
          [62.13783, 26.272109],
          [62.137958, 26.271968],
          [62.138017, 26.271969],
          [62.13812, 26.272061],
          [62.138389, 26.272095],
          [62.138937, 26.272003],
          [62.139224, 26.272051],
          [62.139372, 26.271691],
          [62.139379, 26.271722],
          [62.139486, 26.27165],
          [62.139494, 26.271591],
          [62.139696, 26.271536],
          [62.139736, 26.271403],
          [62.139918, 26.271362],
          [62.139952, 26.271122],
          [62.140182, 26.271157],
          [62.140281, 26.271091],
          [62.140385, 26.27109],
          [62.140393, 26.271141],
          [62.140702, 26.271081],
          [62.140825, 26.271102],
          [62.140982, 26.271059],
          [62.141212, 26.2709],
          [62.141679, 26.270913],
          [62.14183, 26.271035],
          [62.142038, 26.271077],
          [62.142169, 26.271158],
          [62.142385, 26.271046],
          [62.143019, 26.270951],
          [62.143203, 26.270825],
          [62.143548, 26.270797],
          [62.14398, 26.270842],
          [62.14419, 26.270674],
          [62.144438, 26.270597],
          [62.144499, 26.270256],
          [62.144625, 26.270039],
          [62.144624, 26.269938],
          [62.144463, 26.269825],
          [62.144334, 26.269653],
          [62.144392, 26.269619],
          [62.144396, 26.269528],
          [62.144567, 26.26948],
          [62.144801, 26.269223],
          [62.144866, 26.269212],
          [62.144928, 26.269098],
          [62.145031, 26.269105],
          [62.145243, 26.268969],
          [62.14542, 26.268964],
          [62.145579, 26.269082],
          [62.145718, 26.269064],
          [62.14578, 26.269106],
          [62.146033, 26.269053],
          [62.14614, 26.269106],
          [62.146419, 26.269037],
          [62.146587, 26.269083],
          [62.146591, 26.269173],
          [62.146699, 26.269245],
          [62.146997, 26.269192],
          [62.147471, 26.269353],
          [62.147904, 26.269084],
          [62.148226, 26.26899],
          [62.148319, 26.268996],
          [62.148423, 26.269121],
          [62.148741, 26.269174],
          [62.148793, 26.26928],
          [62.1491, 26.269393],
          [62.14922, 26.269386],
          [62.14938, 26.269272],
          [62.149471, 26.269336],
          [62.149749, 26.269276],
          [62.149883, 26.269317],
          [62.150047, 26.269221],
          [62.150098, 26.269281],
          [62.150299, 26.269217],
          [62.150412, 26.269133],
          [62.150645, 26.269307],
          [62.150646, 26.26938],
          [62.150717, 26.269462],
          [62.150918, 26.269567],
          [62.150989, 26.269662],
          [62.151138, 26.269708],
          [62.1511, 26.269793],
          [62.15123, 26.269818],
          [62.151152, 26.269913],
          [62.151313, 26.270036],
          [62.151288, 26.270144],
          [62.151335, 26.27032],
          [62.15132, 26.270502],
          [62.151251, 26.270566],
          [62.151436, 26.270641],
          [62.151733, 26.270597],
          [62.15174, 26.270642],
          [62.151814, 26.270655],
          [62.152469, 26.270704],
          [62.152707, 26.2708],
          [62.152938, 26.270767],
          [62.153105, 26.270797],
          [62.153403, 26.270594],
          [62.153452, 26.27046],
          [62.153675, 26.270224],
          [62.153748, 26.27025],
          [62.154, 26.270164],
          [62.154072, 26.270193],
          [62.154374, 26.269981],
          [62.154593, 26.27],
          [62.154823, 26.269939],
          [62.155426, 26.270046],
          [62.155501, 26.269788],
          [62.155734, 26.269765],
          [62.155717, 26.269686],
          [62.155799, 26.269665],
          [62.155778, 26.269499],
          [62.156293, 26.269336],
          [62.156322, 26.269179],
          [62.156451, 26.269113],
          [62.156618, 26.26873],
          [62.156739, 26.268713],
          [62.156894, 26.268548],
          [62.157, 26.268514],
          [62.157054, 26.268557],
          [62.15715, 26.268508],
          [62.157327, 26.268306],
          [62.157747, 26.268059],
          [62.157844, 26.268032],
          [62.158002, 26.268046],
          [62.158007, 26.268081],
          [62.158077, 26.26805],
          [62.158522, 26.26814],
          [62.158904, 26.268122],
          [62.159034, 26.268155],
          [62.159027, 26.268263],
          [62.159235, 26.268277],
          [62.1593, 26.268329],
          [62.159433, 26.268299],
          [62.159628, 26.268365],
          [62.159854, 26.268144],
          [62.160053, 26.268086],
          [62.160286, 26.267895],
          [62.160397, 26.267901],
          [62.160697, 26.267774],
          [62.160737, 26.267669],
          [62.160894, 26.267596],
          [62.161047, 26.267641],
          [62.161161, 26.267764],
          [62.161212, 26.267917],
          [62.161397, 26.268038],
          [62.162113, 26.267737],
          [62.162456, 26.267776],
          [62.162621, 26.267667],
          [62.162742, 26.267646],
          [62.162849, 26.267683],
          [62.162888, 26.267506],
          [62.163006, 26.267443],
          [62.163197, 26.267454],
          [62.163423, 26.267307],
          [62.163527, 26.267363],
          [62.163657, 26.267311],
          [62.163969, 26.267351],
          [62.164129, 26.267184],
          [62.164312, 26.267076],
          [62.164828, 26.267109],
          [62.164871, 26.266949],
          [62.164968, 26.266818],
          [62.165252, 26.266693],
          [62.165672, 26.26675],
          [62.165958, 26.266898],
          [62.165983, 26.266984],
          [62.165919, 26.267154],
          [62.166012, 26.267446],
          [62.166334, 26.267785],
          [62.166555, 26.267783],
          [62.166674, 26.26793],
          [62.166894, 26.267769],
          [62.167265, 26.267717],
          [62.167471, 26.267811],
          [62.16755, 26.267773],
          [62.167617, 26.267794],
          [62.167621, 26.267865],
          [62.167741, 26.267852],
          [62.167837, 26.268003],
          [62.168077, 26.268049],
          [62.168258, 26.267904],
          [62.168714, 26.267674],
          [62.169066, 26.267764],
          [62.169192, 26.267699],
          [62.169426, 26.267717],
          [62.169523, 26.267654],
          [62.169912, 26.267569],
          [62.171167, 26.267656],
          [62.17167, 26.267492],
          [62.171744, 26.267394],
          [62.171907, 26.267441],
          [62.171985, 26.267632],
          [62.172327, 26.267812],
          [62.172381, 26.267912],
          [62.172591, 26.267953],
          [62.172735, 26.268068],
          [62.172865, 26.268059],
          [62.17324, 26.268187],
          [62.173425, 26.26832],
          [62.173638, 26.268363],
          [62.173668, 26.268322],
          [62.173795, 26.268344],
          [62.173905, 26.268407],
          [62.17407, 26.268314],
          [62.174448, 26.267935],
          [62.174655, 26.267977],
          [62.174844, 26.267937],
          [62.174959, 26.267999],
          [62.175267, 26.267996],
          [62.175296, 26.267841],
          [62.175444, 26.267685],
          [62.175397, 26.267439],
          [62.175473, 26.267267],
          [62.175436, 26.267151],
          [62.175464, 26.267086],
          [62.175513, 26.267009],
          [62.175882, 26.266769],
          [62.175907, 26.266675],
          [62.176001, 26.266601],
          [62.176004, 26.266461],
          [62.176166, 26.266393],
          [62.176325, 26.266246],
          [62.176338, 26.26614],
          [62.176573, 26.266173],
          [62.176662, 26.266243],
          [62.17726, 26.266226],
          [62.177548, 26.265912],
          [62.177647, 26.265898],
          [62.17774, 26.26594],
          [62.17784, 26.265865],
          [62.177887, 26.265683],
          [62.17808, 26.265538],
          [62.178306, 26.265614],
          [62.17833, 26.265706],
          [62.178456, 26.26578],
          [62.178502, 26.265936],
          [62.178617, 26.265941],
          [62.178772, 26.266049],
          [62.179004, 26.266108],
          [62.179113, 26.266076],
          [62.179331, 26.265809],
          [62.17949, 26.26573],
          [62.179583, 26.265731],
          [62.179694, 26.2658],
          [62.179998, 26.265811],
          [62.1801, 26.265725],
          [62.180302, 26.265664],
          [62.180511, 26.265721],
          [62.180631, 26.265679],
          [62.180923, 26.265684],
          [62.18119, 26.265895],
          [62.18124, 26.265892],
          [62.181395, 26.265628],
          [62.181542, 26.265505],
          [62.181977, 26.265415],
          [62.182121, 26.265538],
          [62.182363, 26.266016],
          [62.182384, 26.266141],
          [62.182648, 26.266118],
          [62.182713, 26.266167],
          [62.18274, 26.266277],
          [62.182857, 26.266279],
          [62.183027, 26.2664],
          [62.183407, 26.26626],
          [62.183701, 26.266319],
          [62.183994, 26.266305],
          [62.18425, 26.266502],
          [62.184592, 26.266548],
          [62.184972, 26.266412],
          [62.185398, 26.26617],
          [62.185939, 26.266136],
          [62.186035, 26.266166],
          [62.186312, 26.266021],
          [62.186433, 26.266069],
          [62.18706, 26.265744],
          [62.187305, 26.265538],
          [62.187662, 26.265557],
          [62.187722, 26.265639],
          [62.187894, 26.265687],
          [62.188197, 26.26595],
          [62.188516, 26.265945],
          [62.188558, 26.266055],
          [62.188625, 26.266033],
          [62.188647, 26.266122],
          [62.188839, 26.266166],
          [62.188955, 26.26631],
          [62.189109, 26.26636],
          [62.189287, 26.266625],
          [62.189667, 26.266913],
          [62.190167, 26.267077],
          [62.190207, 26.267002],
          [62.190312, 26.267003],
          [62.19051, 26.267076],
          [62.190541, 26.267134],
          [62.190951, 26.266998],
          [62.191033, 26.267028],
          [62.19118, 26.26688],
          [62.191256, 26.266873],
          [62.191413, 26.26674],
          [62.191718, 26.26686],
          [62.191981, 26.266875],
          [62.192135, 26.266989],
          [62.192386, 26.267015],
          [62.192635, 26.267169],
          [62.192895, 26.267057],
          [62.193114, 26.266894],
          [62.193342, 26.266937],
          [62.193567, 26.267079],
          [62.193743, 26.267019],
          [62.193889, 26.267074],
          [62.194181, 26.266983],
          [62.194421, 26.266959],
          [62.194915, 26.267192],
          [62.195068, 26.267182],
          [62.195194, 26.267278],
          [62.195561, 26.26723],
          [62.195759, 26.267057],
          [62.195864, 26.267085],
          [62.19607, 26.267042],
          [62.196175, 26.26726],
          [62.196321, 26.267328],
          [62.196384, 26.267563],
          [62.196625, 26.267694],
          [62.196851, 26.268043],
          [62.197322, 26.268117],
          [62.19769, 26.268117],
          [62.197746, 26.268332],
          [62.197572, 26.268959],
          [62.197734, 26.269388],
          [62.19803, 26.269428],
          [62.19829, 26.269673],
          [62.198545, 26.269619],
          [62.198664, 26.269687],
          [62.199351, 26.26986],
          [62.199418, 26.269671],
          [62.199544, 26.269657],
          [62.199778, 26.269525],
          [62.199955, 26.269276],
          [62.200121, 26.269202],
          [62.200285, 26.269021],
          [62.200493, 26.269054],
          [62.200626, 26.269009],
          [62.200736, 26.269073],
          [62.200845, 26.269049],
          [62.201043, 26.269149],
          [62.201162, 26.26905],
          [62.201685, 26.268988],
          [62.201953, 26.268462],
          [62.202038, 26.268401],
          [62.202199, 26.268054],
          [62.202298, 26.267969],
          [62.202453, 26.267926],
          [62.202649, 26.268057],
          [62.203167, 26.268139],
          [62.20367, 26.268373],
          [62.203968, 26.268382],
          [62.20419, 26.268205],
          [62.204743, 26.26815],
          [62.204823, 26.26809],
          [62.20513, 26.268002],
          [62.205233, 26.267884],
          [62.205527, 26.267708],
          [62.205937, 26.267775],
          [62.206028, 26.267839],
          [62.206122, 26.267812],
          [62.206254, 26.267667],
          [62.206452, 26.267559],
          [62.206988, 26.267365],
          [62.20716, 26.267219],
          [62.207177, 26.267132],
          [62.207691, 26.267044],
          [62.208229, 26.267057],
          [62.208274, 26.267189],
          [62.208459, 26.267269],
          [62.20844, 26.267308],
          [62.208899, 26.267337],
          [62.209062, 26.267399],
          [62.209453, 26.267833],
          [62.209598, 26.268117],
          [62.209785, 26.26823],
          [62.209944, 26.268506],
          [62.210079, 26.268633],
          [62.210335, 26.268738],
          [62.210471, 26.268854],
          [62.210601, 26.268777],
          [62.210762, 26.268774],
          [62.211072, 26.268643],
          [62.211142, 26.268552],
          [62.211527, 26.268309],
          [62.21193, 26.26827],
          [62.21197, 26.268205],
          [62.212166, 26.268106],
          [62.212608, 26.268054],
          [62.212765, 26.267959],
          [62.212905, 26.267941],
          [62.213019, 26.267677],
          [62.213228, 26.267646],
          [62.213361, 26.267559],
          [62.21367, 26.267823],
          [62.21401, 26.267927],
          [62.214611, 26.268028],
          [62.215011, 26.267929],
          [62.214965, 26.268183],
          [62.214942, 26.269591],
          [62.215219, 26.271521],
          [62.215618, 26.273131],
          [62.216204, 26.274305],
          [62.216653, 26.274884],
          [62.217353, 26.275459],
          [62.218226, 26.276333],
          [62.219418, 26.277076],
          [62.220532, 26.277685],
          [62.221435, 26.278313],
          [62.221962, 26.278753],
          [62.222302, 26.27916],
          [62.222875, 26.280109],
          [62.223746, 26.281554],
          [62.224223, 26.28256],
          [62.224455, 26.283652],
          [62.224466, 26.284197],
          [62.224225, 26.285068],
          [62.223853, 26.285863],
          [62.223258, 26.286674],
          [62.222567, 26.287376],
          [62.219682, 26.289494],
          [62.218674, 26.290004],
          [62.218009, 26.290248],
          [62.21785, 26.290537],
          [62.217612, 26.291628],
          [62.218254, 26.292332],
          [62.219312, 26.293726],
          [62.220861, 26.295561],
          [62.222111, 26.296791],
          [62.223234, 26.297551],
          [62.224524, 26.29825],
          [62.225883, 26.299102],
          [62.226998, 26.299983],
          [62.227659, 26.301095],
          [62.227978, 26.30256],
          [62.227887, 26.303679],
          [62.227466, 26.306198],
          [62.227714, 26.307664],
          [62.228394, 26.308805],
          [62.229063, 26.309621],
          [62.230354, 26.310822],
          [62.232714, 26.312749],
          [62.233776, 26.314001],
          [62.234399, 26.316692],
          [62.234434, 26.318719],
          [62.234262, 26.31936],
          [62.233539, 26.32027],
          [62.233105, 26.320584],
          [62.232078, 26.321807],
          [62.229531, 26.324348],
          [62.228087, 26.326068],
          [62.227549, 26.327504],
          [62.227744, 26.328946],
          [62.228244, 26.329538],
          [62.228826, 26.330063],
          [62.22978, 26.330819],
          [62.231424, 26.331327],
          [62.232594, 26.331204],
          [62.234424, 26.330533],
          [62.238604, 26.327027],
          [62.242289, 26.322129],
          [62.243442, 26.321321],
          [62.246298, 26.320749],
          [62.248494, 26.320114],
          [62.250085, 26.319833],
          [62.25211, 26.319894],
          [62.254221, 26.320738],
          [62.255773, 26.322365],
          [62.257638, 26.322956],
          [62.265627, 26.324125],
          [62.267582, 26.324634],
          [62.269246, 26.325456],
          [62.26997, 26.325595],
          [62.271207, 26.326598],
          [62.271944, 26.328003],
          [62.272133, 26.330626],
          [62.271923, 26.332465],
          [62.270684, 26.333866],
          [62.269284, 26.334858],
          [62.268182, 26.335515],
          [62.264565, 26.336773],
          [62.261741, 26.337657],
          [62.259473, 26.338183],
          [62.257467, 26.338261],
          [62.255717, 26.338744],
          [62.25446, 26.339351],
          [62.252185, 26.340611],
          [62.25116, 26.342063],
          [62.250561, 26.343569],
          [62.250452, 26.344801],
          [62.25036, 26.345122],
          [62.250133, 26.346581],
          [62.25148, 26.353423],
          [62.252812, 26.3588],
          [62.253075, 26.359691],
          [62.254049, 26.361625],
          [62.25459, 26.362948],
          [62.254806, 26.363123],
          [62.255006, 26.363378],
          [62.255494, 26.364207],
          [62.255708, 26.364444],
          [62.255989, 26.365202],
          [62.257099, 26.366524],
          [62.259616, 26.368336],
          [62.260758, 26.369267],
          [62.261734, 26.369959],
          [62.262945, 26.370746],
          [62.263717, 26.371161],
          [62.265895, 26.371929],
          [62.268453, 26.372393],
          [62.270088, 26.37278],
          [62.271457, 26.373261],
          [62.27374, 26.374206],
          [62.274934, 26.374643],
          [62.276664, 26.375166],
          [62.277669, 26.375667],
          [62.278439, 26.376157],
          [62.279904, 26.377201],
          [62.28268, 26.378488],
          [62.283469, 26.378953],
          [62.283921, 26.379275],
          [62.284351, 26.379707],
          [62.284695, 26.380344],
          [62.28457, 26.381284],
          [62.284054, 26.382502],
          [62.283581, 26.383429],
          [62.282253, 26.384719],
          [62.281828, 26.38522],
          [62.280816, 26.386028],
          [62.280501, 26.3867],
          [62.280187, 26.387862],
          [62.28017, 26.388082],
          [62.280075, 26.388299],
          [62.278484, 26.39098],
          [62.276723, 26.393459],
          [62.275764, 26.394691],
          [62.275089, 26.395704],
          [62.274117, 26.396743],
          [62.273348, 26.397467],
          [62.272509, 26.398383],
          [62.271339, 26.400089],
          [62.270402, 26.401154],
          [62.269869, 26.401921],
          [62.26975, 26.402459],
          [62.269749, 26.403767],
          [62.269597, 26.404105],
          [62.269048, 26.404395],
          [62.268282, 26.404538],
          [62.267085, 26.404593],
          [62.266263, 26.404487],
          [62.265806, 26.404498],
          [62.264991, 26.404797],
          [62.264007, 26.4053],
          [62.262891, 26.406126],
          [62.261393, 26.40705],
          [62.259498, 26.408339],
          [62.259323, 26.408838],
          [62.259388, 26.409613],
          [62.259642, 26.410487],
          [62.259865, 26.410902],
          [62.260484, 26.411571],
          [62.260649, 26.41226],
          [62.260836, 26.413689],
          [62.260759, 26.414204],
          [62.261006, 26.416308],
          [62.261293, 26.417277],
          [62.261418, 26.419207],
          [62.261536, 26.422126],
          [62.2615, 26.422448],
          [62.261554, 26.423067],
          [62.261741, 26.423451],
          [62.263305, 26.424431],
          [62.263868, 26.425009],
          [62.264343, 26.426129],
          [62.264432, 26.426531],
          [62.264281, 26.427032],
          [62.263801, 26.427526],
          [62.263283, 26.427685],
          [62.263061, 26.427813],
          [62.262876, 26.428135],
          [62.262675, 26.428676],
          [62.262687, 26.431439],
          [62.262555, 26.432137],
          [62.262725, 26.432952],
          [62.262768, 26.43371],
          [62.263098, 26.434876],
          [62.263364, 26.436044],
          [62.263388, 26.436367],
          [62.263655, 26.436658],
          [62.26448, 26.437903],
          [62.264718, 26.438521],
          [62.264992, 26.438653],
          [62.265063, 26.439459],
          [62.264952, 26.44019],
          [62.264638, 26.44111],
          [62.264266, 26.441823],
          [62.263961, 26.44214],
          [62.263431, 26.442385],
          [62.262256, 26.442672],
          [62.260937, 26.442822],
          [62.260108, 26.44306],
          [62.259134, 26.443772],
          [62.2587, 26.444391],
          [62.258507, 26.444941],
          [62.258022, 26.445147],
          [62.257764, 26.445703],
          [62.257767, 26.446225],
          [62.257893, 26.446631],
          [62.258316, 26.4469],
          [62.259036, 26.447113],
          [62.260281, 26.447351],
          [62.261631, 26.447424],
          [62.264094, 26.447461],
          [62.26499, 26.447391],
          [62.266165, 26.447129],
          [62.26955, 26.446195],
          [62.271113, 26.445238],
          [62.271623, 26.445013],
          [62.272456, 26.444151],
          [62.273125, 26.443191],
          [62.27403, 26.442549],
          [62.275664, 26.441749],
          [62.278107, 26.440408],
          [62.279206, 26.44026],
          [62.280269, 26.439976],
          [62.282089, 26.439882],
          [62.283409, 26.439712],
          [62.284365, 26.439673],
          [62.284882, 26.439747],
          [62.285381, 26.439951],
          [62.28612, 26.440329],
          [62.286175, 26.440552],
          [62.286192, 26.441272],
          [62.285982, 26.443439],
          [62.286209, 26.444028],
          [62.286821, 26.446302],
          [62.286955, 26.446584],
          [62.287915, 26.447879],
          [62.288256, 26.448831],
          [62.288263, 26.44925],
          [62.28816, 26.449467],
          [62.288201, 26.44968],
          [62.288535, 26.449914],
          [62.289052, 26.450022],
          [62.290957, 26.449978],
          [62.292075, 26.450078],
          [62.294207, 26.450367],
          [62.294497, 26.450293],
          [62.294937, 26.450342],
          [62.295278, 26.450451],
          [62.295699, 26.450802],
          [62.296396, 26.452052],
          [62.297893, 26.45418],
          [62.298456, 26.455212],
          [62.298199, 26.456876],
          [62.298201, 26.457578],
          [62.297952, 26.458145],
          [62.297825, 26.459365],
          [62.297573, 26.460548],
          [62.297299, 26.461396],
          [62.297452, 26.461832],
          [62.298337, 26.462059],
          [62.299449, 26.462183],
          [62.299995, 26.462328],
          [62.300904, 26.462377],
          [62.301744, 26.462204],
          [62.30273, 26.462118],
          [62.303926, 26.46191],
          [62.305518, 26.461837],
          [62.305962, 26.461966],
          [62.306568, 26.462389],
          [62.306943, 26.462772],
          [62.307104, 26.463173],
          [62.307066, 26.464121],
          [62.306583, 26.464615],
          [62.30566, 26.465427],
          [62.304948, 26.466166],
          [62.30434, 26.467254],
          [62.304411, 26.467789],
          [62.304602, 26.468449],
          [62.304921, 26.468978],
          [62.305176, 26.469257],
          [62.305717, 26.469516],
          [62.306497, 26.469734],
          [62.309345, 26.47032],
          [62.30965, 26.470453],
          [62.310844, 26.471173],
          [62.311165, 26.471557],
          [62.311553, 26.471876],
          [62.312921, 26.472457],
          [62.313823, 26.472918],
          [62.314159, 26.473443],
          [62.31419, 26.47403],
          [62.314081, 26.474988],
          [62.31339, 26.476585],
          [62.312954, 26.477134],
          [62.311692, 26.477777],
          [62.311102, 26.478171],
          [62.310758, 26.47877],
          [62.310592, 26.479912],
          [62.31083, 26.480826],
          [62.311174, 26.481404],
          [62.311592, 26.481874],
          [62.312067, 26.48218],
          [62.312685, 26.48245],
          [62.314161, 26.482763],
          [62.314767, 26.482992],
          [62.314983, 26.483307],
          [62.315162, 26.483753],
          [62.315223, 26.484468],
          [62.315003, 26.485137],
          [62.31491, 26.485762],
          [62.314922, 26.486499],
          [62.314784, 26.486896],
          [62.314534, 26.487048],
          [62.313883, 26.487179],
          [62.313149, 26.487081],
          [62.311783, 26.486635],
          [62.310185, 26.486213],
          [62.308705, 26.486122],
          [62.307577, 26.485986],
          [62.305813, 26.485427],
          [62.305572, 26.48548],
          [62.305001, 26.486242],
          [62.305097, 26.486847],
          [62.305399, 26.487229],
          [62.305455, 26.48747],
          [62.305413, 26.487603],
          [62.30367, 26.488573],
          [62.302742, 26.488998],
          [62.300436, 26.489221],
          [62.299849, 26.489383],
          [62.299474, 26.489653],
          [62.299141, 26.490789],
          [62.299099, 26.492051],
          [62.299309, 26.4933],
          [62.300092, 26.496394],
          [62.300084, 26.496779],
          [62.299939, 26.497544],
          [62.299894, 26.498121],
          [62.301568, 26.500154],
          [62.301752, 26.503532],
          [62.303304, 26.508287],
          [62.308438, 26.513877],
          [62.311023, 26.516539],
          [62.312261, 26.51861],
          [62.314217, 26.519898],
          [62.319102, 26.520998],
          [62.32274, 26.52099],
          [62.328443, 26.520227],
          [62.330521, 26.520647],
          [62.335595, 26.522677],
          [62.337731, 26.524174],
          [62.345272, 26.526755],
          [62.349126, 26.527994],
          [62.353272, 26.529062],
          [62.356796, 26.530514],
          [62.357025, 26.530637],
          [62.357958, 26.531404],
          [62.360062, 26.533653],
          [62.361364, 26.534868],
          [62.3634, 26.536268],
          [62.36503, 26.537282],
          [62.366782, 26.537821],
          [62.367759, 26.538244],
          [62.36926, 26.538974],
          [62.370225, 26.539378],
          [62.371392, 26.539414],
          [62.371772, 26.539487],
          [62.372402, 26.539457],
          [62.37335, 26.539514],
          [62.374445, 26.539382],
          [62.374767, 26.539382],
          [62.37541, 26.539284],
          [62.378635, 26.538281],
          [62.379806, 26.537572],
          [62.381406, 26.536374],
          [62.383007, 26.535311],
          [62.385214, 26.533565],
          [62.386146, 26.533156],
          [62.387944, 26.532752],
          [62.388766, 26.532448],
          [62.389793, 26.532202],
          [62.39026, 26.532132],
          [62.390638, 26.532135],
          [62.391412, 26.532225],
          [62.392471, 26.532571],
          [62.393463, 26.532807],
          [62.395674, 26.533704],
          [62.396336, 26.53411],
          [62.397049, 26.534828],
          [62.397537, 26.535466],
          [62.397874, 26.536731],
          [62.398267, 26.537244],
          [62.398423, 26.537388],
          [62.399114, 26.537722],
          [62.400095, 26.537945],
          [62.401227, 26.53788],
          [62.403715, 26.53746],
          [62.404411, 26.53727],
          [62.405144, 26.536823],
          [62.405781, 26.536732],
          [62.40651, 26.536702],
          [62.40739, 26.536823],
          [62.408031, 26.536784],
          [62.408534, 26.53662],
          [62.408806, 26.536485],
          [62.409707, 26.53592],
          [62.410823, 26.53532],
          [62.411242, 26.535169],
          [62.412006, 26.534894],
          [62.413105, 26.53434],
          [62.414185, 26.534044],
          [62.415779, 26.533717],
          [62.417279, 26.533572],
          [62.418089, 26.533326],
          [62.418917, 26.533085],
          [62.419863, 26.532776],
          [62.420331, 26.532814],
          [62.421176, 26.533158],
          [62.421509, 26.533349],
          [62.421694, 26.533971],
          [62.42176, 26.534205],
          [62.42276, 26.538055],
          [62.422825, 26.538592],
          [62.422761, 26.539188],
          [62.422524, 26.539861],
          [62.422235, 26.5402],
          [62.421837, 26.540454],
          [62.420602, 26.540627],
          [62.420286, 26.540783],
          [62.420286, 26.54102],
          [62.420706, 26.541435],
          [62.421142, 26.541642],
          [62.421706, 26.542156],
          [62.422091, 26.542595],
          [62.422444, 26.543074],
          [62.42283, 26.544079],
          [62.423348, 26.545995],
          [62.423467, 26.546897],
          [62.423288, 26.547822],
          [62.42332, 26.548057],
          [62.423199, 26.549858],
          [62.423236, 26.550249],
          [62.423661, 26.550926],
          [62.424549, 26.55175],
          [62.42561, 26.552564],
          [62.426834, 26.553379],
          [62.42791, 26.553667],
          [62.428511, 26.55409],
          [62.42876, 26.554203],
          [62.430889, 26.556787],
          [62.432853, 26.559063],
          [62.433651, 26.559498],
          [62.43516, 26.560083],
          [62.436275, 26.560249],
          [62.437612, 26.560302],
          [62.439885, 26.560209],
          [62.440403, 26.560376],
          [62.440691, 26.560932],
          [62.44062, 26.561692],
          [62.440231, 26.562935],
          [62.43999, 26.564125],
          [62.440021, 26.564819],
          [62.440704, 26.565295],
          [62.441469, 26.565634],
          [62.445165, 26.566895],
          [62.446431, 26.567232],
          [62.447453, 26.56737],
          [62.448218, 26.567407],
          [62.448941, 26.567362],
          [62.450807, 26.56704],
          [62.451428, 26.566867],
          [62.452407, 26.566394],
          [62.453196, 26.565375],
          [62.454482, 26.564361],
          [62.455611, 26.563801],
          [62.456765, 26.563333],
          [62.457967, 26.562968],
          [62.458445, 26.562863],
          [62.459556, 26.562954],
          [62.460777, 26.562892],
          [62.461389, 26.56271],
          [62.46231, 26.563004],
          [62.463482, 26.56361],
          [62.4673, 26.565243],
          [62.468829, 26.565787],
          [62.469863, 26.56562],
          [62.472348, 26.567038],
          [62.473732, 26.567501],
          [62.475074, 26.56822],
          [62.475855, 26.568508],
          [62.477278, 26.568525],
          [62.479068, 26.567977],
          [62.479593, 26.567717],
          [62.480883, 26.567343],
          [62.483961, 26.566998],
          [62.484838, 26.567049],
          [62.486549, 26.567359],
          [62.488527, 26.567939],
          [62.489493, 26.568283],
          [62.492853, 26.568944],
          [62.493996, 26.569406],
          [62.495839, 26.571163],
          [62.496684, 26.570968],
          [62.497594, 26.570963],
          [62.499105, 26.571403],
          [62.500479, 26.571916],
          [62.50167, 26.572255],
          [62.502788, 26.572401],
          [62.503563, 26.572693],
          [62.504296, 26.572709],
          [62.504879, 26.572833],
          [62.505576, 26.573234],
          [62.50592, 26.5736],
          [62.506011, 26.574049],
          [62.506046, 26.575113],
          [62.506558, 26.575969],
          [62.507147, 26.577245],
          [62.508263, 26.577677],
          [62.509327, 26.577862],
          [62.510476, 26.577943],
          [62.512024, 26.57773],
          [62.514761, 26.576997],
          [62.515578, 26.57652],
          [62.516305, 26.576258],
          [62.516958, 26.575751],
          [62.517283, 26.575498],
          [62.518229, 26.57454],
          [62.519264, 26.573214],
          [62.520929, 26.572006],
          [62.522331, 26.571535],
          [62.523247, 26.571726],
          [62.524433, 26.571534],
          [62.525835, 26.571166],
          [62.526835, 26.570956],
          [62.527646, 26.570906],
          [62.528924, 26.570996],
          [62.530448, 26.571338],
          [62.5319, 26.571405],
          [62.533152, 26.571576],
          [62.534207, 26.571876],
          [62.535115, 26.572301],
          [62.536011, 26.572787],
          [62.536799, 26.572974],
          [62.539298, 26.573295],
          [62.540934, 26.573822],
          [62.543209, 26.574349],
          [62.54672, 26.574436],
          [62.548093, 26.574117],
          [62.54833, 26.574167],
          [62.5505, 26.575072],
          [62.552043, 26.575738],
          [62.552462, 26.575968],
          [62.558237, 26.57681],
          [62.559429, 26.576935],
          [62.561001, 26.576816],
          [62.562715, 26.576542],
          [62.563596, 26.576337],
          [62.565713, 26.576241],
          [62.569542, 26.576172],
          [62.571718, 26.576478],
          [62.576476, 26.577442],
          [62.577197, 26.57753],
          [62.578337, 26.577578],
          [62.579633, 26.577571],
          [62.581254, 26.577438],
          [62.583973, 26.577406],
          [62.58567, 26.577073],
          [62.587378, 26.576824],
          [62.590457, 26.576873],
          [62.591526, 26.576787],
          [62.592412, 26.576774],
          [62.59445, 26.576247],
          [62.595729, 26.576007],
          [62.601445, 26.575989],
          [62.604098, 26.576273],
          [62.604424, 26.576492],
          [62.604557, 26.577937],
          [62.604697, 26.57839],
          [62.605151, 26.579441],
          [62.605833, 26.580323],
          [62.607539, 26.581549],
          [62.609283, 26.582487],
          [62.610743, 26.583143],
          [62.614006, 26.584013],
          [62.614825, 26.584316],
          [62.615597, 26.584834],
          [62.619983, 26.586231],
          [62.621932, 26.586746],
          [62.622958, 26.587143],
          [62.623721, 26.587631],
          [62.624795, 26.588749],
          [62.626824, 26.590226],
          [62.62828, 26.590743],
          [62.629425, 26.591092],
          [62.63045, 26.591231],
          [62.633609, 26.591522],
          [62.634975, 26.592231],
          [62.635831, 26.593085],
          [62.637055, 26.595075],
          [62.637436, 26.596206],
          [62.638224, 26.597291],
          [62.641782, 26.599162],
          [62.642822, 26.599523],
          [62.644306, 26.59984],
          [62.64617, 26.600902],
          [62.646892, 26.601754],
          [62.647749, 26.603082],
          [62.647913, 26.603874],
          [62.647828, 26.605271],
          [62.648111, 26.606866],
          [62.648636, 26.60859],
          [62.649324, 26.609622],
          [62.651571, 26.610976],
          [62.653724, 26.610854],
          [62.655738, 26.610623],
          [62.657836, 26.610266],
          [62.659415, 26.610239],
          [62.661921, 26.610642],
          [62.663932, 26.611208],
          [62.665505, 26.611753],
          [62.668034, 26.61228],
          [62.670853, 26.612561],
          [62.672106, 26.612291],
          [62.673134, 26.611797],
          [62.674239, 26.610968],
          [62.674896, 26.610541],
          [62.675644, 26.610309],
          [62.676465, 26.60991],
          [62.676823, 26.609276],
          [62.677578, 26.608375],
          [62.678564, 26.607551],
          [62.680177, 26.605189],
          [62.681213, 26.604037],
          [62.681912, 26.60338],
          [62.683483, 26.602275],
          [62.684569, 26.601649],
          [62.685838, 26.601123],
          [62.687096, 26.600803],
          [62.688486, 26.600529],
          [62.689236, 26.60049],
          [62.689732, 26.600632],
          [62.690577, 26.601217],
          [62.691378, 26.602027],
          [62.692421, 26.60324],
          [62.694718, 26.606484],
          [62.695751, 26.60785],
          [62.696561, 26.608879],
          [62.696979, 26.609269],
          [62.697616, 26.609444],
          [62.698575, 26.609358],
          [62.699025, 26.609257],
          [62.699875, 26.60898],
          [62.701038, 26.608311],
          [62.703983, 26.608129],
          [62.705112, 26.608011],
          [62.706109, 26.608083],
          [62.706898, 26.608261],
          [62.708731, 26.60878],
          [62.710144, 26.609088],
          [62.713637, 26.610295],
          [62.714847, 26.610835],
          [62.715952, 26.612077],
          [62.716872, 26.612925],
          [62.71759, 26.613862],
          [62.718326, 26.614337],
          [62.719142, 26.61453],
          [62.720102, 26.614573],
          [62.72077, 26.61452],
          [62.721568, 26.614314],
          [62.723213, 26.613757],
          [62.724258, 26.613744],
          [62.724828, 26.613788],
          [62.725351, 26.613983],
          [62.725668, 26.61446],
          [62.725715, 26.61523],
          [62.72598, 26.616321],
          [62.726347, 26.61704],
          [62.727204, 26.618259],
          [62.727305, 26.61834],
          [62.728322, 26.618523],
          [62.729383, 26.618387],
          [62.730194, 26.618205],
          [62.730796, 26.617484],
          [62.731632, 26.616237],
          [62.73231, 26.615047],
          [62.733059, 26.614293],
          [62.733389, 26.614025],
          [62.733826, 26.61375],
          [62.734673, 26.61363],
          [62.735578, 26.613567],
          [62.736343, 26.613433],
          [62.737316, 26.613188],
          [62.738103, 26.613237],
          [62.738544, 26.613542],
          [62.73877, 26.614121],
          [62.738858, 26.614994],
          [62.738479, 26.615762],
          [62.737362, 26.617179],
          [62.735907, 26.619442],
          [62.734547, 26.621263],
          [62.733008, 26.622856],
          [62.732201, 26.623575],
          [62.731781, 26.624411],
          [62.731681, 26.625402],
          [62.73235, 26.627629],
          [62.732607, 26.628067],
          [62.733263, 26.629538],
          [62.733898, 26.630563],
          [62.734834, 26.6313],
          [62.7354, 26.631662],
          [62.735558, 26.631713],
          [62.737552, 26.632794],
          [62.739098, 26.632894],
          [62.740947, 26.632947],
          [62.742177, 26.632908],
          [62.74355, 26.633412],
          [62.74425, 26.63414],
          [62.744453, 26.634449],
          [62.744813, 26.63513],
          [62.745423, 26.636746],
          [62.7461, 26.637764],
          [62.747259, 26.63913],
          [62.74946, 26.640942],
          [62.752246, 26.642764],
          [62.753006, 26.643198],
          [62.753537, 26.645016],
          [62.755126, 26.645809],
          [62.758818, 26.645856],
          [62.760811, 26.645467],
          [62.762256, 26.64616],
          [62.765293, 26.644437],
          [62.7664, 26.643643],
          [62.767513, 26.643084],
          [62.769029, 26.641007],
          [62.771885, 26.639641],
          [62.774508, 26.638701],
          [62.776372, 26.638798],
          [62.777227, 26.638807],
          [62.778967, 26.638991],
          [62.779941, 26.639972],
          [62.780403, 26.641686],
          [62.781358, 26.643],
          [62.782303, 26.643317],
          [62.783592, 26.643923],
          [62.785461, 26.645024],
          [62.788217, 26.647164],
          [62.790634, 26.649111],
          [62.793525, 26.650715],
          [62.7947, 26.651003],
          [62.795972, 26.650248],
          [62.796656, 26.649097],
          [62.796876, 26.64807],
          [62.797338, 26.647311],
          [62.797983, 26.647029],
          [62.800239, 26.646937],
          [62.801253, 26.64712],
          [62.802319, 26.647861],
          [62.803744, 26.648738],
          [62.805235, 26.649472],
          [62.805932, 26.649919],
          [62.806387, 26.650011],
          [62.807767, 26.650146],
          [62.809164, 26.650169],
          [62.809988, 26.649847],
          [62.811339, 26.648827],
          [62.812196, 26.647949],
          [62.813421, 26.646903],
          [62.815903, 26.645514],
          [62.81756, 26.644233],
          [62.819331, 26.643141],
          [62.821488, 26.641233],
          [62.823375, 26.639084],
          [62.823982, 26.638165],
          [62.824409, 26.637995],
          [62.825304, 26.638301],
          [62.826831, 26.639328],
          [62.828261, 26.640558],
          [62.829285, 26.641868],
          [62.830622, 26.644699],
          [62.831594, 26.645235],
          [62.833002, 26.644794],
          [62.83415, 26.644357],
          [62.836332, 26.642454],
          [62.837637, 26.641691],
          [62.838757, 26.641282],
          [62.840862, 26.641245],
          [62.842079, 26.641555],
          [62.844085, 26.642346],
          [62.845741, 26.64257],
          [62.848523, 26.642823],
          [62.851218, 26.643149],
          [62.853909, 26.64338],
          [62.856741, 26.642676],
          [62.857673, 26.642695],
          [62.8587, 26.642846],
          [62.860477, 26.642678],
          [62.861284, 26.642522],
          [62.862828, 26.641671],
          [62.863323, 26.641453],
          [62.864598, 26.641318],
          [62.866953, 26.641436],
          [62.871063, 26.642269],
          [62.873058, 26.642747],
          [62.875403, 26.642749],
          [62.877413, 26.643772],
          [62.878641, 26.644218],
          [62.879982, 26.644767],
          [62.882253, 26.645172],
          [62.883534, 26.645509],
          [62.883949, 26.645556],
          [62.884404, 26.64553],
          [62.885541, 26.646028],
          [62.886958, 26.646431],
          [62.887814, 26.646358],
          [62.889057, 26.646026],
          [62.89172, 26.64515],
          [62.893371, 26.64434],
          [62.894331, 26.643018],
          [62.895087, 26.642225],
          [62.896041, 26.641377],
          [62.89723, 26.640896],
          [62.899402, 26.640582],
          [62.90059, 26.640435],
          [62.902441, 26.640629],
          [62.9038, 26.643103],
          [62.905987, 26.643946],
          [62.90817, 26.643071],
          [62.908906, 26.64253],
          [62.910632, 26.643294],
          [62.91189, 26.643993],
          [62.916681, 26.645618],
          [62.91852, 26.645579],
          [62.918664, 26.645587],
          [62.920071, 26.645099],
          [62.92017, 26.64352],
          [62.922751, 26.641036],
          [62.925477, 26.640362],
          [62.927121, 26.639843],
          [62.929703, 26.639257],
          [62.931568, 26.639157],
          [62.933721, 26.639178],
          [62.934241, 26.639642],
          [62.934491, 26.64176],
          [62.93474, 26.643005],
          [62.935365, 26.643575],
          [62.936519, 26.644226],
          [62.937338, 26.64446],
          [62.938797, 26.644399],
          [62.939719, 26.644228],
          [62.940779, 26.643861],
          [62.942055, 26.64254],
          [62.943207, 26.641143],
          [62.943479, 26.640727],
          [62.944086, 26.640613],
          [62.944987, 26.640658],
          [62.945861, 26.641065],
          [62.94682, 26.643387],
          [62.949386, 26.645984],
          [62.95114, 26.647441],
          [62.951955, 26.647705],
          [62.953534, 26.648007],
          [62.955922, 26.647537],
          [62.956972, 26.647442],
          [62.960722, 26.64685],
          [62.962336, 26.64736],
          [62.963392, 26.647781],
          [62.96425, 26.648486],
          [62.96509, 26.64896],
          [62.96594, 26.649242],
          [62.966934, 26.649074],
          [62.96771, 26.648744],
          [62.968079, 26.648665],
          [62.970196, 26.648385],
          [62.970628, 26.648385],
          [62.972195, 26.64811],
          [62.973944, 26.647699],
          [62.974777, 26.64684],
          [62.975157, 26.64675],
          [62.975376, 26.646574],
          [62.975468, 26.646358],
          [62.975943, 26.645929],
          [62.976165, 26.645815],
          [62.976481, 26.64576],
          [62.977091, 26.645718],
          [62.977382, 26.645636],
          [62.977653, 26.645643],
          [62.97953, 26.646038],
          [62.980439, 26.646421],
          [62.980943, 26.646674],
          [62.982804, 26.647923],
          [62.983428, 26.648241],
          [62.984739, 26.649039],
          [62.986139, 26.649419],
          [62.987784, 26.649792],
          [62.989011, 26.649921],
          [62.989971, 26.649943],
          [62.992146, 26.649058],
          [62.992682, 26.648658],
          [62.993824, 26.648468],
          [62.994835, 26.64836],
          [62.995261, 26.648028],
          [62.995668, 26.647544],
          [62.996246, 26.646501],
          [62.996715, 26.644579],
          [62.99698, 26.643993],
          [62.997252, 26.642873],
          [62.998122, 26.640136],
          [62.99832, 26.639296],
          [62.998621, 26.63917],
          [62.999257, 26.639054],
          [62.99986, 26.639156],
          [63.000461, 26.639392],
          [63.000899, 26.639724],
          [63.002421, 26.641067],
          [63.003135, 26.641553],
          [63.004337, 26.642078],
          [63.004958, 26.642271],
          [63.006349, 26.642533],
          [63.008544, 26.642618],
          [63.009945, 26.642801],
          [63.010852, 26.643113],
          [63.011656, 26.643747],
          [63.012153, 26.644591],
          [63.012565, 26.645539],
          [63.013772, 26.646011],
          [63.014727, 26.646273],
          [63.015172, 26.646465],
          [63.01593, 26.64645],
          [63.016474, 26.646076],
          [63.016469, 26.645939],
          [63.016569, 26.645733],
          [63.016732, 26.645625],
          [63.016872, 26.645456],
          [63.016918, 26.645268],
          [63.017135, 26.64488],
          [63.017383, 26.644568],
          [63.018866, 26.642213],
          [63.019826, 26.641491],
          [63.021724, 26.640515],
          [63.023589, 26.639891],
          [63.02439, 26.6395],
          [63.025092, 26.638938],
          [63.02553, 26.638393],
          [63.025837, 26.637853],
          [63.026393, 26.637207],
          [63.027616, 26.636188],
          [63.028626, 26.635875],
          [63.030339, 26.635113],
          [63.030633, 26.634816],
          [63.031953, 26.634128],
          [63.032925, 26.633688],
          [63.033943, 26.633416],
          [63.034617, 26.633102],
          [63.03505, 26.632554],
          [63.035425, 26.631572],
          [63.035648, 26.631213],
          [63.035911, 26.63059],
          [63.03639, 26.629653],
          [63.036927, 26.629524],
          [63.037328, 26.629292],
          [63.038425, 26.628865],
          [63.041188, 26.62856],
          [63.042474, 26.628775],
          [63.04342, 26.629325],
          [63.044029, 26.629314],
          [63.045012, 26.629051],
          [63.046014, 26.628323],
          [63.046336, 26.628183],
          [63.048857, 26.627541],
          [63.049491, 26.627821],
          [63.050361, 26.628569],
          [63.05159, 26.629196],
          [63.053278, 26.629679],
          [63.054437, 26.630987],
          [63.054884, 26.631642],
          [63.055522, 26.634324],
          [63.055967, 26.635828],
          [63.056406, 26.636905],
          [63.057121, 26.637772],
          [63.05803, 26.638299],
          [63.058472, 26.638371],
          [63.060435, 26.638293],
          [63.062039, 26.638678],
          [63.062811, 26.639313],
          [63.063225, 26.639914],
          [63.063472, 26.641215],
          [63.063696, 26.641546],
          [63.064194, 26.64194],
          [63.064759, 26.642146],
          [63.065478, 26.642044],
          [63.066437, 26.64167],
          [63.0676, 26.641099],
          [63.069266, 26.639985],
          [63.072085, 26.638357],
          [63.072737, 26.637632],
          [63.073837, 26.637041],
          [63.075448, 26.636327],
          [63.076361, 26.636544],
          [63.077874, 26.63733],
          [63.078574, 26.637823],
          [63.079167, 26.63796],
          [63.080186, 26.638275],
          [63.081444, 26.638559],
          [63.082184, 26.638637],
          [63.082648, 26.638616],
          [63.083415, 26.638492],
          [63.084819, 26.638589],
          [63.086628, 26.638963],
          [63.086802, 26.63917],
          [63.087203, 26.639265],
          [63.088225, 26.639305],
          [63.090389, 26.639747],
          [63.091161, 26.639835],
          [63.091794, 26.639743],
          [63.092249, 26.639819],
          [63.092899, 26.640114],
          [63.093491, 26.640449],
          [63.093505, 26.640644],
          [63.093375, 26.641139],
          [63.093512, 26.641498],
          [63.09378, 26.641921],
          [63.094003, 26.642135],
          [63.09408, 26.642564],
          [63.094504, 26.643544],
          [63.095383, 26.644933],
          [63.095881, 26.645474],
          [63.096504, 26.646004],
          [63.097567, 26.647102],
          [63.097948, 26.64771],
          [63.099007, 26.649064],
          [63.1007, 26.650391],
          [63.102875, 26.65107],
          [63.103997, 26.651204],
          [63.105046, 26.651094],
          [63.105568, 26.651265],
          [63.106426, 26.651858],
          [63.107195, 26.652267],
          [63.108061, 26.652392],
          [63.108576, 26.652419],
          [63.109824, 26.652088],
          [63.111069, 26.651817],
          [63.111425, 26.651608],
          [63.112031, 26.651088],
          [63.113012, 26.650617],
          [63.114488, 26.649825],
          [63.115055, 26.649474],
          [63.11619, 26.64909],
          [63.118958, 26.648473],
          [63.119862, 26.648383],
          [63.121974, 26.64899],
          [63.122859, 26.649116],
          [63.123527, 26.649121],
          [63.124369, 26.649345],
          [63.125878, 26.650026],
          [63.126524, 26.650053],
          [63.128505, 26.649806],
          [63.129303, 26.64995],
          [63.13007, 26.650174],
          [63.130583, 26.650379],
          [63.131023, 26.650644],
          [63.131712, 26.650499],
          [63.132896, 26.650544],
          [63.13399, 26.6508],
          [63.13444, 26.65099],
          [63.137351, 26.650574],
          [63.138705, 26.651218],
          [63.147787, 26.649686],
          [63.148756, 26.649605],
          [63.149587, 26.649615],
          [63.150302, 26.649519],
          [63.151846, 26.649407],
          [63.15556, 26.649485],
          [63.157746, 26.649256],
          [63.158044, 26.649019],
          [63.158764, 26.648701],
          [63.160169, 26.648875],
          [63.161394, 26.648755],
          [63.162841, 26.648322],
          [63.163802, 26.648253],
          [63.16493, 26.647956],
          [63.165446, 26.647951],
          [63.166175, 26.647793],
          [63.168107, 26.647177],
          [63.168549, 26.647178],
          [63.169195, 26.646763],
          [63.169394, 26.64682],
          [63.169737, 26.64682],
          [63.170132, 26.646918],
          [63.17047, 26.646899],
          [63.170954, 26.646797],
          [63.171228, 26.646666],
          [63.171775, 26.646707],
          [63.172646, 26.646376],
          [63.172928, 26.646426],
          [63.173282, 26.646735],
          [63.173677, 26.646682],
          [63.174138, 26.646695],
          [63.17591, 26.646556],
          [63.177344, 26.646316],
          [63.177515, 26.646374],
          [63.177988, 26.646221],
          [63.178136, 26.646249],
          [63.179009, 26.64617],
          [63.179523, 26.646381],
          [63.180169, 26.646292],
          [63.181329, 26.645928],
          [63.182101, 26.646199],
          [63.182439, 26.646216],
          [63.18289, 26.64596],
          [63.183103, 26.645879],
          [63.183398, 26.645845],
          [63.18345, 26.645881],
          [63.183516, 26.646076],
          [63.184047, 26.646597],
          [63.184506, 26.646498],
          [63.184845, 26.646696],
          [63.184992, 26.646716],
          [63.185217, 26.646447],
          [63.18612, 26.64629],
          [63.186407, 26.64635],
          [63.186671, 26.646506],
          [63.187097, 26.646931],
          [63.187394, 26.647012],
          [63.18779, 26.647054],
          [63.188114, 26.646975],
          [63.189532, 26.646831],
          [63.189774, 26.646766],
          [63.189885, 26.646772],
          [63.189996, 26.646871],
          [63.190098, 26.646896],
          [63.190327, 26.646888],
          [63.190465, 26.646943],
          [63.190826, 26.646924],
          [63.191046, 26.646989],
          [63.191587, 26.647053],
          [63.191676, 26.647034],
          [63.191723, 26.646832],
          [63.192, 26.64647],
          [63.192216, 26.646347],
          [63.193163, 26.646129],
          [63.193439, 26.64615],
          [63.19365, 26.646265],
          [63.19391, 26.646323],
          [63.194262, 26.646248],
          [63.195087, 26.646296],
          [63.195313, 26.646544],
          [63.195746, 26.646604],
          [63.196149, 26.64673],
          [63.196532, 26.647023],
          [63.19692, 26.647126],
          [63.196871, 26.647325],
          [63.196915, 26.647571],
          [63.196698, 26.64775],
          [63.196524, 26.64781],
          [63.195053, 26.647895],
          [63.19489, 26.647958],
          [63.194732, 26.648338],
          [63.194852, 26.648744],
          [63.194827, 26.649002],
          [63.194382, 26.649361],
          [63.193631, 26.64983],
          [63.193172, 26.650779],
          [63.192929, 26.651471],
          [63.192895, 26.651734],
          [63.192575, 26.652243],
          [63.1925, 26.652472],
          [63.192467, 26.65301],
          [63.192537, 26.653214],
          [63.192726, 26.653493],
          [63.192821, 26.654041],
          [63.192642, 26.654325],
          [63.192628, 26.654734],
          [63.192178, 26.657],
          [63.19184, 26.657657],
          [63.191622, 26.658449],
          [63.191695, 26.658948],
          [63.191984, 26.659453],
          [63.192027, 26.659641],
          [63.191867, 26.659988],
          [63.191888, 26.660173],
          [63.191492, 26.660944],
          [63.191593, 26.661316],
          [63.191484, 26.661436],
          [63.191379, 26.661676],
          [63.191467, 26.662179],
          [63.191448, 26.662296],
          [63.191295, 26.662428],
          [63.19119, 26.662452],
          [63.191117, 26.662574],
          [63.191067, 26.662931],
          [63.19085, 26.663536],
          [63.190809, 26.663883],
          [63.19066, 26.664101],
          [63.19067, 26.664266],
          [63.190529, 26.664375],
          [63.190523, 26.664927],
          [63.190562, 26.665018],
          [63.190459, 26.665157],
          [63.190506, 26.665256],
          [63.190489, 26.665348],
          [63.19033, 26.66549],
          [63.190304, 26.665601],
          [63.190473, 26.666127],
          [63.201, 26.674222],
          [63.188476, 26.695306],
          [63.19065, 26.710533],
          [63.190822, 26.710655],
          [63.190999, 26.710661],
          [63.191262, 26.710557],
          [63.191668, 26.710943],
          [63.191836, 26.71088],
          [63.192199, 26.710858],
          [63.192334, 26.710769],
          [63.193243, 26.71104],
          [63.193415, 26.711153],
          [63.193643, 26.711101],
          [63.193885, 26.710925],
          [63.194258, 26.710933],
          [63.194466, 26.710878],
          [63.194685, 26.710725],
          [63.194959, 26.710797],
          [63.195365, 26.710717],
          [63.195834, 26.710691],
          [63.196106, 26.710796],
          [63.196532, 26.710691],
          [63.196702, 26.710769],
          [63.197082, 26.710803],
          [63.197364, 26.710735],
          [63.197611, 26.710824],
          [63.197941, 26.710775],
          [63.198097, 26.710822],
          [63.198436, 26.710634],
          [63.198865, 26.710539],
          [63.199027, 26.710643],
          [63.199131, 26.710666],
          [63.199357, 26.71063],
          [63.199508, 26.710709],
          [63.19964, 26.71072],
          [63.200163, 26.710636],
          [63.200394, 26.710539],
          [63.200675, 26.710529],
          [63.200815, 26.710466],
          [63.200906, 26.710337],
          [63.201131, 26.710281],
          [63.201182, 26.710553],
          [63.201154, 26.711011],
          [63.201187, 26.711154],
          [63.200864, 26.711984],
          [63.200604, 26.712294],
          [63.200501, 26.712685],
          [63.200532, 26.712753],
          [63.20047, 26.713032],
          [63.200308, 26.713162],
          [63.200147, 26.713205],
          [63.200033, 26.713377],
          [63.200137, 26.71362],
          [63.199997, 26.714182],
          [63.200299, 26.714646],
          [63.200656, 26.714769],
          [63.200904, 26.715556],
          [63.200967, 26.715663],
          [63.201218, 26.715848],
          [63.201166, 26.71613],
          [63.201357, 26.716793],
          [63.201705, 26.716936],
          [63.201972, 26.717231],
          [63.202001, 26.717422],
          [63.202328, 26.717564],
          [63.202793, 26.717659],
          [63.202919, 26.717798],
          [63.203202, 26.717776],
          [63.203318, 26.717906],
          [63.204034, 26.718447],
          [63.203814, 26.718884],
          [63.203647, 26.718976],
          [63.203666, 26.719091],
          [63.2036, 26.719153],
          [63.203215, 26.719227],
          [63.203161, 26.719259],
          [63.203143, 26.719368],
          [63.203046, 26.719427],
          [63.203018, 26.719578],
          [63.202903, 26.719722],
          [63.202982, 26.719898],
          [63.202881, 26.720041],
          [63.202883, 26.720132],
          [63.202836, 26.720104],
          [63.202632, 26.72023],
          [63.20253, 26.720187],
          [63.20251, 26.720119],
          [63.202312, 26.720051],
          [63.2021, 26.720113],
          [63.201925, 26.720091],
          [63.20175, 26.720316],
          [63.201641, 26.720342],
          [63.201581, 26.720407],
          [63.20153, 26.720478],
          [63.20153, 26.720609],
          [63.201334, 26.720744],
          [63.201046, 26.721131],
          [63.200915, 26.721431],
          [63.200789, 26.721769],
          [63.200798, 26.722006],
          [63.200664, 26.722122],
          [63.200678, 26.722279],
          [63.200535, 26.722354],
          [63.200527, 26.722847],
          [63.200487, 26.722942],
          [63.20058, 26.723071],
          [63.20057, 26.723333],
          [63.200472, 26.723374],
          [63.200425, 26.723524],
          [63.200349, 26.72357],
          [63.200295, 26.723717],
          [63.200093, 26.723951],
          [63.200118, 26.72416],
          [63.200168, 26.724174],
          [63.200244, 26.724339],
          [63.200252, 26.724506],
          [63.20016, 26.724586],
          [63.200139, 26.724707],
          [63.200029, 26.724733],
          [63.199813, 26.725187],
          [63.19988, 26.72526],
          [63.199843, 26.725331],
          [63.199775, 26.725395],
          [63.199615, 26.725382],
          [63.199665, 26.725523],
          [63.199627, 26.725951],
          [63.199722, 26.726062],
          [63.199739, 26.726286],
          [63.199849, 26.726429],
          [63.199804, 26.726681],
          [63.199753, 26.726685],
          [63.199645, 26.726824],
          [63.199699, 26.726921],
          [63.199651, 26.727018],
          [63.199647, 26.727672],
          [63.199606, 26.727801],
          [63.199435, 26.727937],
          [63.19925, 26.72837],
          [63.199114, 26.728544],
          [63.198977, 26.729074],
          [63.198739, 26.729347],
          [63.19822, 26.730223],
          [63.197837, 26.730617],
          [63.197715, 26.730826],
          [63.197776, 26.73104],
          [63.197934, 26.73116],
          [63.197997, 26.732],
          [63.198173, 26.732013],
          [63.198064, 26.732429],
          [63.198049, 26.732809],
          [63.197898, 26.733224],
          [63.197461, 26.733582],
          [63.197269, 26.733992],
          [63.197122, 26.734141],
          [63.196944, 26.734491],
          [63.196757, 26.734669],
          [63.196654, 26.73488],
          [63.196597, 26.735516],
          [63.196503, 26.735794],
          [63.196166, 26.736506],
          [63.195666, 26.737151],
          [63.195508, 26.737748],
          [63.194968, 26.73855],
          [63.194873, 26.738866],
          [63.194692, 26.73911],
          [63.194773, 26.739441],
          [63.19455, 26.740378],
          [63.194367, 26.740931],
          [63.194653, 26.741416],
          [63.19478, 26.74179],
          [63.194892, 26.741953],
          [63.195257, 26.742265],
          [63.195408, 26.742266],
          [63.195639, 26.742152],
          [63.1959, 26.742123],
          [63.196087, 26.742283],
          [63.19592, 26.742604],
          [63.195999, 26.742782],
          [63.196204, 26.742883],
          [63.196352, 26.742869],
          [63.196596, 26.742954],
          [63.196745, 26.743398],
          [63.196924, 26.743605],
          [63.197474, 26.74361],
          [63.198036, 26.743695],
          [63.19832, 26.743885],
          [63.198433, 26.744055],
          [63.19862, 26.744117],
          [63.198857, 26.744357],
          [63.199073, 26.744389],
          [63.199171, 26.744464],
          [63.199546, 26.745336],
          [63.199472, 26.745681],
          [63.199539, 26.745765],
          [63.199351, 26.746388],
          [63.199417, 26.74653],
          [63.199311, 26.746617],
          [63.199699, 26.747045],
          [63.199841, 26.747065],
          [63.200046, 26.746991],
          [63.200107, 26.747519],
          [63.199982, 26.747574],
          [63.199974, 26.747668],
          [63.200418, 26.748822],
          [63.200389, 26.749294],
          [63.200547, 26.750179],
          [63.201261, 26.750295],
          [63.201256, 26.750517],
          [63.201582, 26.75079],
          [63.20184, 26.750934],
          [63.201699, 26.751772],
          [63.201752, 26.752917],
          [63.201474, 26.752972],
          [63.201435, 26.753341],
          [63.201447, 26.753496],
          [63.201604, 26.75365],
          [63.201694, 26.753862],
          [63.201542, 26.754159],
          [63.201738, 26.754257],
          [63.201852, 26.754383],
          [63.201899, 26.754731],
          [63.201848, 26.755018],
          [63.201878, 26.755129],
          [63.20178, 26.755309],
          [63.201819, 26.755563],
          [63.201558, 26.755695],
          [63.201528, 26.755817],
          [63.201511, 26.756013],
          [63.201577, 26.756162],
          [63.201525, 26.756272],
          [63.201544, 26.756387],
          [63.201442, 26.756536],
          [63.201487, 26.756733],
          [63.201379, 26.756779],
          [63.201201, 26.756952],
          [63.201191, 26.757395],
          [63.201063, 26.757526],
          [63.200848, 26.757598],
          [63.200766, 26.757682],
          [63.200957, 26.75799],
          [63.200888, 26.758149],
          [63.200814, 26.758151],
          [63.200741, 26.758277],
          [63.200617, 26.758309],
          [63.200529, 26.758407],
          [63.200623, 26.758654],
          [63.200474, 26.7587],
          [63.200392, 26.759233],
          [63.200213, 26.759357],
          [63.200051, 26.75933],
          [63.199935, 26.759396],
          [63.199461, 26.759498],
          [63.199142, 26.759389],
          [63.198904, 26.759381],
          [63.198999, 26.759695],
          [63.19903, 26.759997],
          [63.19894, 26.760103],
          [63.199099, 26.760192],
          [63.199108, 26.76026],
          [63.199065, 26.760466],
          [63.198916, 26.760629],
          [63.199121, 26.761252],
          [63.199171, 26.761264],
          [63.198926, 26.761384],
          [63.198892, 26.761518],
          [63.198931, 26.761685],
          [63.198818, 26.761784],
          [63.198959, 26.761992],
          [63.198857, 26.762007],
          [63.19878, 26.762126],
          [63.198817, 26.7622],
          [63.198782, 26.762338],
          [63.198979, 26.762679],
          [63.199432, 26.762888],
          [63.199088, 26.76298],
          [63.199005, 26.763087],
          [63.198881, 26.763106],
          [63.198771, 26.763205],
          [63.198669, 26.763194],
          [63.198382, 26.763305],
          [63.198201, 26.763493],
          [63.197864, 26.763563],
          [63.197825, 26.763746],
          [63.197652, 26.763883],
          [63.197587, 26.764016],
          [63.197184, 26.764214],
          [63.197216, 26.764317],
          [63.197185, 26.764505],
          [63.19728, 26.76476],
          [63.197464, 26.765021],
          [63.197466, 26.765119],
          [63.197741, 26.765211],
          [63.198409, 26.765041],
          [63.198465, 26.765258],
          [63.198643, 26.765437],
          [63.198681, 26.765875],
          [63.198793, 26.765977],
          [63.198806, 26.76605],
          [63.198932, 26.766101],
          [63.19913, 26.766444],
          [63.199211, 26.766469],
          [63.199163, 26.766552],
          [63.199212, 26.766608],
          [63.199327, 26.766607],
          [63.199424, 26.766853],
          [63.19898, 26.766976],
          [63.198713, 26.767256],
          [63.198703, 26.767356],
          [63.198646, 26.767409],
          [63.198063, 26.767493],
          [63.197783, 26.767642],
          [63.197375, 26.767685],
          [63.197237, 26.767768],
          [63.196583, 26.767986],
          [63.196068, 26.768332],
          [63.195067, 26.768577],
          [63.194898, 26.768696],
          [63.194652, 26.768781],
          [63.194513, 26.768951],
          [63.194328, 26.769077],
          [63.193989, 26.769188],
          [63.193914, 26.769486],
          [63.192732, 26.769903],
          [63.192672, 26.769967],
          [63.192423, 26.770056],
          [63.192259, 26.770181],
          [63.19219, 26.770316],
          [63.191791, 26.770303],
          [63.191485, 26.770481],
          [63.19161, 26.770912],
          [63.191993, 26.770969],
          [63.192156, 26.770913],
          [63.192299, 26.770948],
          [63.192417, 26.770854],
          [63.192464, 26.770869],
          [63.192917, 26.770692],
          [63.19331, 26.770675],
          [63.193486, 26.770625],
          [63.193638, 26.770643],
          [63.193669, 26.770707],
          [63.194041, 26.770766],
          [63.194469, 26.770727],
          [63.19451, 26.770849],
          [63.194668, 26.771031],
          [63.195088, 26.77123],
          [63.195261, 26.771178],
          [63.195582, 26.771227],
          [63.19624, 26.771147],
          [63.196278, 26.771225],
          [63.196446, 26.771336],
          [63.196727, 26.7714],
          [63.196861, 26.771351],
          [63.196995, 26.771359],
          [63.197166, 26.7712],
          [63.197384, 26.771125],
          [63.197582, 26.771308],
          [63.197922, 26.771425],
          [63.197967, 26.77165],
          [63.198155, 26.771685],
          [63.198236, 26.7718],
          [63.1982, 26.771923],
          [63.198266, 26.772056],
          [63.19842, 26.772193],
          [63.198885, 26.772268],
          [63.198985, 26.772243],
          [63.199115, 26.772134],
          [63.19925, 26.772142],
          [63.199988, 26.772008],
          [63.199977, 26.771956],
          [63.200058, 26.771844],
          [63.200246, 26.771819],
          [63.200377, 26.771748],
          [63.200581, 26.771781],
          [63.200983, 26.771653],
          [63.201176, 26.771754],
          [63.201993, 26.771654],
          [63.202328, 26.771718],
          [63.202603, 26.771707],
          [63.202613, 26.771757],
          [63.202879, 26.771851],
          [63.203025, 26.771832],
          [63.203543, 26.771924],
          [63.203629, 26.771974],
          [63.203691, 26.771612],
          [63.203818, 26.771464],
          [63.20417, 26.771231],
          [63.204918, 26.770391],
          [63.204944, 26.770454],
          [63.205183, 26.770493],
          [63.205512, 26.770675],
          [63.205583, 26.770616],
          [63.205708, 26.7706],
          [63.206048, 26.770735],
          [63.206121, 26.770829],
          [63.206202, 26.770856],
          [63.206184, 26.770978],
          [63.20634, 26.771078],
          [63.206354, 26.771146],
          [63.206739, 26.771284],
          [63.206761, 26.771358],
          [63.207516, 26.771694],
          [63.207531, 26.771753],
          [63.207861, 26.771966],
          [63.20865, 26.772712],
          [63.208783, 26.772997],
          [63.208876, 26.773084],
          [63.208945, 26.77333],
          [63.208906, 26.773337],
          [63.208932, 26.773444],
          [63.208733, 26.773578],
          [63.208488, 26.773651],
          [63.208385, 26.773622],
          [63.208325, 26.773692],
          [63.208095, 26.773739],
          [63.207829, 26.773761],
          [63.207633, 26.773835],
          [63.20762, 26.773876],
          [63.207026, 26.77397],
          [63.207081, 26.774325],
          [63.207016, 26.774403],
          [63.206909, 26.774426],
          [63.206956, 26.774744],
          [63.206888, 26.77491],
          [63.206782, 26.774936],
          [63.206369, 26.774857],
          [63.205467, 26.774922],
          [63.205208, 26.774964],
          [63.20501, 26.775068],
          [63.204868, 26.775089],
          [63.204769, 26.775194],
          [63.204489, 26.775254],
          [63.203835, 26.775152],
          [63.203542, 26.775183],
          [63.203373, 26.775119],
          [63.202808, 26.77513],
          [63.202385, 26.775076],
          [63.202326, 26.775122],
          [63.202215, 26.775081],
          [63.201874, 26.775077],
          [63.201559, 26.775206],
          [63.201126, 26.775303],
          [63.20105, 26.77537],
          [63.200968, 26.775353],
          [63.200745, 26.775474],
          [63.200605, 26.775464],
          [63.200573, 26.775705],
          [63.200494, 26.775775],
          [63.200256, 26.77581],
          [63.200147, 26.775868],
          [63.199829, 26.775868],
          [63.199722, 26.775992],
          [63.199626, 26.77598],
          [63.199548, 26.776024],
          [63.199253, 26.775999],
          [63.199094, 26.776086],
          [63.1986, 26.776061],
          [63.198527, 26.776131],
          [63.198317, 26.776134],
          [63.198232, 26.7762],
          [63.198239, 26.776463],
          [63.197964, 26.776645],
          [63.197981, 26.776938],
          [63.197632, 26.777483],
          [63.197768, 26.778383],
          [63.197769, 26.778751],
          [63.197486, 26.778968],
          [63.197026, 26.779156],
          [63.197085, 26.77945],
          [63.197004, 26.779824],
          [63.196825, 26.779918],
          [63.196895, 26.780439],
          [63.196715, 26.780819],
          [63.196701, 26.781464],
          [63.196816, 26.781602],
          [63.197014, 26.782217],
          [63.196554, 26.782335],
          [63.19622, 26.782309],
          [63.195811, 26.78238],
          [63.195595, 26.782491],
          [63.195546, 26.782579],
          [63.195099, 26.782701],
          [63.19519, 26.783202],
          [63.195593, 26.783932],
          [63.195557, 26.784051],
          [63.195774, 26.784493],
          [63.195355, 26.784602],
          [63.195326, 26.784568],
          [63.194815, 26.784649],
          [63.194124, 26.784879],
          [63.19362, 26.784966],
          [63.193237, 26.784953],
          [63.19293, 26.784866],
          [63.192417, 26.784983],
          [63.192378, 26.785162],
          [63.192469, 26.785332],
          [63.19247, 26.785437],
          [63.192512, 26.78543],
          [63.192567, 26.785516],
          [63.192505, 26.785964],
          [63.192395, 26.786139],
          [63.192272, 26.786198],
          [63.192159, 26.786186],
          [63.191905, 26.786306],
          [63.192063, 26.787108],
          [63.191729, 26.787519],
          [63.191886, 26.787827],
          [63.192092, 26.78807],
          [63.191958, 26.788195],
          [63.191956, 26.788402],
          [63.191979, 26.788524],
          [63.192075, 26.788647],
          [63.192046, 26.788723],
          [63.192258, 26.789133],
          [63.192255, 26.789259],
          [63.192651, 26.789288],
          [63.193014, 26.789204],
          [63.19345, 26.78928],
          [63.193634, 26.789233],
          [63.193768, 26.789257],
          [63.194124, 26.789215],
          [63.194109, 26.789609],
          [63.194335, 26.789789],
          [63.1944, 26.78992],
          [63.194598, 26.789956],
          [63.194754, 26.790406],
          [63.194505, 26.791085],
          [63.194549, 26.79141],
          [63.19485, 26.791336],
          [63.195133, 26.791497],
          [63.195198, 26.791427],
          [63.195376, 26.791412],
          [63.195299, 26.791644],
          [63.195317, 26.791788],
          [63.195258, 26.791854],
          [63.195035, 26.791907],
          [63.194959, 26.791966],
          [63.194939, 26.792072],
          [63.194706, 26.792248],
          [63.194642, 26.792413],
          [63.194728, 26.792529],
          [63.195168, 26.792674],
          [63.195128, 26.792766],
          [63.194994, 26.792912],
          [63.19477, 26.792986],
          [63.19438, 26.792915],
          [63.194228, 26.792935],
          [63.1941, 26.793029],
          [63.193911, 26.793048],
          [63.193805, 26.793202],
          [63.193686, 26.793248],
          [63.19263, 26.793188],
          [63.192573, 26.793257],
          [63.192605, 26.793421],
          [63.192233, 26.793548],
          [63.191699, 26.79361],
          [63.191498, 26.793674],
          [63.191421, 26.793729],
          [63.191426, 26.793957],
          [63.191514, 26.794096],
          [63.19151, 26.794254],
          [63.191181, 26.794363],
          [63.19118, 26.794507],
          [63.190984, 26.794666],
          [63.190613, 26.79461],
          [63.190287, 26.794506],
          [63.189937, 26.794523],
          [63.189742, 26.794456],
          [63.189422, 26.794427],
          [63.189393, 26.794867],
          [63.189324, 26.794961],
          [63.189338, 26.795043],
          [63.189349, 26.795083],
          [63.189415, 26.795087],
          [63.189492, 26.795264],
          [63.189454, 26.795371],
          [63.189589, 26.795546],
          [63.18969, 26.795895],
          [63.189886, 26.796196],
          [63.189901, 26.796281],
          [63.189828, 26.796373],
          [63.189818, 26.796511],
          [63.189749, 26.796561],
          [63.189034, 26.796535],
          [63.189006, 26.796504],
          [63.188559, 26.796543],
          [63.188484, 26.79671],
          [63.188365, 26.796871],
          [63.188297, 26.796898],
          [63.188225, 26.797311],
          [63.188148, 26.797337],
          [63.1881, 26.797502],
          [63.188007, 26.7976],
          [63.188108, 26.79773],
          [63.188105, 26.797892],
          [63.18793, 26.797991],
          [63.187878, 26.798152],
          [63.187581, 26.798196],
          [63.187562, 26.798485],
          [63.187601, 26.798569],
          [63.187708, 26.79864],
          [63.187545, 26.798925],
          [63.187727, 26.79934],
          [63.188132, 26.799305],
          [63.188518, 26.799358],
          [63.188991, 26.799451],
          [63.189514, 26.7997],
          [63.18977, 26.799704],
          [63.190092, 26.799773],
          [63.190513, 26.799936],
          [63.190655, 26.800043],
          [63.190764, 26.80002],
          [63.191016, 26.80015],
          [63.19147, 26.800282],
          [63.191489, 26.800415],
          [63.191384, 26.800484],
          [63.191377, 26.800539],
          [63.191633, 26.800759],
          [63.192541, 26.800958],
          [63.192551, 26.801034],
          [63.192651, 26.801102],
          [63.192807, 26.801096],
          [63.19285, 26.800968],
          [63.193256, 26.800944],
          [63.193728, 26.801068],
          [63.193781, 26.80094],
          [63.194098, 26.801049],
          [63.194376, 26.801085],
          [63.194654, 26.801221],
          [63.194683, 26.801416],
          [63.194842, 26.801688],
          [63.194819, 26.801754],
          [63.194519, 26.801832],
          [63.194066, 26.802096],
          [63.193829, 26.802823],
          [63.193709, 26.802835],
          [63.193618, 26.802784],
          [63.193443, 26.80281],
          [63.19315, 26.802965],
          [63.193006, 26.802992],
          [63.192628, 26.802948],
          [63.192685, 26.803159],
          [63.192639, 26.803481],
          [63.191735, 26.803602],
          [63.19168, 26.803794],
          [63.191486, 26.804081],
          [63.191438, 26.804534],
          [63.191746, 26.804672],
          [63.191781, 26.804733],
          [63.192034, 26.804799],
          [63.192028, 26.804857],
          [63.191935, 26.804865],
          [63.191983, 26.805109],
          [63.192138, 26.805131],
          [63.192282, 26.805215],
          [63.1925, 26.805212],
          [63.192806, 26.805341],
          [63.193254, 26.805353],
          [63.193334, 26.805455],
          [63.19358, 26.805573],
          [63.193691, 26.805547],
          [63.194202, 26.805595],
          [63.194219, 26.805746],
          [63.194162, 26.805928],
          [63.194233, 26.806119],
          [63.194037, 26.806337],
          [63.193998, 26.80637],
          [63.193679, 26.806243],
          [63.193556, 26.806328],
          [63.193252, 26.806256],
          [63.193096, 26.806359],
          [63.192915, 26.806333],
          [63.192888, 26.806453],
          [63.192403, 26.806787],
          [63.192402, 26.806985],
          [63.192282, 26.807229],
          [63.192676, 26.807407],
          [63.192788, 26.80744],
          [63.192921, 26.80742],
          [63.1933, 26.807583],
          [63.193173, 26.807738],
          [63.192967, 26.80778],
          [63.192874, 26.807847],
          [63.192697, 26.808101],
          [63.192733, 26.808213],
          [63.192708, 26.808312],
          [63.192655, 26.808337],
          [63.192657, 26.808532],
          [63.193038, 26.808553],
          [63.192971, 26.808827],
          [63.19274, 26.808925],
          [63.192582, 26.809424],
          [63.192918, 26.809738],
          [63.19287, 26.8098],
          [63.192963, 26.809864],
          [63.193749, 26.809791],
          [63.194097, 26.809869],
          [63.194223, 26.810213],
          [63.194349, 26.810228],
          [63.194653, 26.810537],
          [63.194744, 26.810748],
          [63.194708, 26.810808],
          [63.194739, 26.81087],
          [63.19494, 26.81108],
          [63.194807, 26.811159],
          [63.194692, 26.811156],
          [63.194701, 26.811257],
          [63.194627, 26.811455],
          [63.194635, 26.811538],
          [63.194805, 26.811719],
          [63.194685, 26.811743],
          [63.194166, 26.812075],
          [63.193905, 26.812147],
          [63.193692, 26.812479],
          [63.193436, 26.812735],
          [63.193401, 26.812901],
          [63.193431, 26.813014],
          [63.193499, 26.813031],
          [63.193679, 26.813273],
          [63.193449, 26.814024],
          [63.192779, 26.815531],
          [63.192471, 26.816586],
          [63.192253, 26.81699],
          [63.19206, 26.817877],
          [63.192219, 26.818267],
          [63.192158, 26.818511],
          [63.192241, 26.818731],
          [63.19219, 26.818879],
          [63.192192, 26.819238],
          [63.191891, 26.819932],
          [63.19194, 26.820325],
          [63.191808, 26.820399],
          [63.191807, 26.820507],
          [63.19163, 26.820745],
          [63.191574, 26.821045],
          [63.191292, 26.821658],
          [63.19112, 26.821932],
          [63.190796, 26.822256],
          [63.190925, 26.822404],
          [63.19167, 26.822832],
          [63.192003, 26.822822],
          [63.192269, 26.822878],
          [63.192499, 26.822772],
          [63.192792, 26.82278],
          [63.192973, 26.822937],
          [63.193601, 26.823019],
          [63.1935, 26.823121],
          [63.193506, 26.82338],
          [63.193692, 26.823418],
          [63.19369, 26.823771],
          [63.19385, 26.823903],
          [63.193746, 26.82399],
          [63.19351, 26.823819],
          [63.19272, 26.823844],
          [63.191231, 26.823716],
          [63.190251, 26.823755],
          [63.189974, 26.82368],
          [63.189574, 26.823706],
          [63.189573, 26.823745],
          [63.189294, 26.823805],
          [63.189178, 26.823778],
          [63.189104, 26.823878],
          [63.18894, 26.824554],
          [63.188671, 26.825268],
          [63.188453, 26.825515],
          [63.188333, 26.825806],
          [63.188171, 26.825851],
          [63.188155, 26.825938],
          [63.187901, 26.825999],
          [63.187849, 26.826057],
          [63.188151, 26.826119],
          [63.187747, 26.826154],
          [63.187695, 26.826201],
          [63.18776, 26.826375],
          [63.187664, 26.826358],
          [63.187717, 26.826431],
          [63.187692, 26.826474],
          [63.18727, 26.826512],
          [63.186547, 26.826417],
          [63.186461, 26.826594],
          [63.186229, 26.826772],
          [63.186225, 26.826855],
          [63.186309, 26.826946],
          [63.186477, 26.826956],
          [63.186529, 26.827259],
          [63.186335, 26.827289],
          [63.186572, 26.827506],
          [63.186584, 26.827682],
          [63.18648, 26.827972],
          [63.186506, 26.82869],
          [63.186286, 26.829203],
          [63.186363, 26.829548],
          [63.186448, 26.829706],
          [63.186539, 26.829729],
          [63.186584, 26.829809],
          [63.18662, 26.829967],
          [63.186734, 26.830128],
          [63.186722, 26.830243],
          [63.187209, 26.831196],
          [63.187089, 26.832018],
          [63.186987, 26.832161],
          [63.186741, 26.832825],
          [63.186684, 26.833241],
          [63.186473, 26.833663],
          [63.186525, 26.834024],
          [63.186658, 26.834159],
          [63.186749, 26.834516],
          [63.186704, 26.834678],
          [63.186731, 26.834823],
          [63.186569, 26.834969],
          [63.186553, 26.835329],
          [63.186455, 26.83552],
          [63.186365, 26.835589],
          [63.186288, 26.835965],
          [63.186102, 26.836259],
          [63.186119, 26.836328],
          [63.186066, 26.836396],
          [63.186117, 26.836503],
          [63.185979, 26.836726],
          [63.186327, 26.836781],
          [63.186683, 26.836666],
          [63.186862, 26.836556],
          [63.18726, 26.836601],
          [63.187582, 26.836777],
          [63.187779, 26.836795],
          [63.187864, 26.836873],
          [63.188039, 26.836934],
          [63.188411, 26.836937],
          [63.188623, 26.837041],
          [63.188677, 26.837003],
          [63.188747, 26.837022],
          [63.188814, 26.837132],
          [63.188908, 26.83715],
          [63.188957, 26.837307],
          [63.189169, 26.837394],
          [63.189537, 26.837453],
          [63.189704, 26.837432],
          [63.18999, 26.837562],
          [63.190192, 26.837499],
          [63.190397, 26.83757],
          [63.190802, 26.837496],
          [63.19097, 26.837581],
          [63.191209, 26.837507],
          [63.191426, 26.837648],
          [63.191637, 26.837687],
          [63.191688, 26.837748],
          [63.191761, 26.837741],
          [63.191947, 26.837825],
          [63.192074, 26.837734],
          [63.192151, 26.837731],
          [63.192375, 26.837483],
          [63.19258, 26.837405],
          [63.192748, 26.837508],
          [63.193017, 26.837539],
          [63.193059, 26.837607],
          [63.193153, 26.837607],
          [63.193162, 26.837706],
          [63.193708, 26.837783],
          [63.193761, 26.837844],
          [63.194011, 26.837773],
          [63.194356, 26.8379],
          [63.194598, 26.837926],
          [63.194861, 26.838134],
          [63.195034, 26.838187],
          [63.195071, 26.838083],
          [63.195256, 26.838162],
          [63.195366, 26.838162],
          [63.19576, 26.837997],
          [63.195881, 26.838038],
          [63.195957, 26.838131],
          [63.19629, 26.838201],
          [63.196307, 26.838293],
          [63.197193, 26.838633],
          [63.197429, 26.838456],
          [63.197676, 26.838419],
          [63.198003, 26.838288],
          [63.198057, 26.83838],
          [63.198369, 26.838557],
          [63.198418, 26.83868],
          [63.19852, 26.838678],
          [63.19888, 26.838803],
          [63.199126, 26.838758],
          [63.19937, 26.838487],
          [63.199614, 26.838577],
          [63.199627, 26.838743],
          [63.199976, 26.838743],
          [63.200033, 26.838601],
          [63.200201, 26.838482],
          [63.200471, 26.838462],
          [63.200697, 26.838359],
          [63.200759, 26.838441],
          [63.200985, 26.838522],
          [63.20106, 26.838653],
          [63.201165, 26.838635],
          [63.201399, 26.838694],
          [63.201495, 26.838822],
          [63.201785, 26.838953],
          [63.202161, 26.838892],
          [63.20243, 26.838534],
          [63.202537, 26.838469],
          [63.202863, 26.838478],
          [63.202954, 26.838522],
          [63.203708, 26.838365],
          [63.204266, 26.838306],
          [63.204341, 26.838484],
          [63.204561, 26.838736],
          [63.204647, 26.838907],
          [63.204867, 26.838989],
          [63.204936, 26.838917],
          [63.205011, 26.838962],
          [63.205214, 26.838849],
          [63.205228, 26.838769],
          [63.20565, 26.838543],
          [63.206029, 26.838481],
          [63.206427, 26.838475],
          [63.206616, 26.838414],
          [63.207152, 26.838573],
          [63.207144, 26.838536],
          [63.207336, 26.838485],
          [63.20762, 26.838463],
          [63.208169, 26.838578],
          [63.208456, 26.838537],
          [63.208574, 26.838608],
          [63.208609, 26.838571],
          [63.208796, 26.838567],
          [63.208935, 26.838634],
          [63.209143, 26.838634],
          [63.209353, 26.838756],
          [63.209505, 26.838927],
          [63.209699, 26.838899],
          [63.209836, 26.838943],
          [63.209958, 26.838904],
          [63.210734, 26.838919],
          [63.210772, 26.838848],
          [63.210867, 26.838886],
          [63.211243, 26.838837],
          [63.211461, 26.838891],
          [63.211782, 26.838823],
          [63.211932, 26.838857],
          [63.212474, 26.838811],
          [63.213071, 26.838924],
          [63.213659, 26.838849],
          [63.213828, 26.838896],
          [63.214402, 26.838581],
          [63.21457, 26.838565],
          [63.214651, 26.838665],
          [63.214969, 26.83874],
          [63.215483, 26.838784],
          [63.216165, 26.838911],
          [63.216586, 26.838901],
          [63.21688, 26.838949],
          [63.217449, 26.839159],
          [63.218014, 26.839179],
          [63.218091, 26.839246],
          [63.21849, 26.839323],
          [63.218507, 26.839372],
          [63.218621, 26.839396],
          [63.218958, 26.839357],
          [63.219612, 26.839496],
          [63.219926, 26.83945],
          [63.22041, 26.839502],
          [63.220614, 26.839612],
          [63.221402, 26.83971],
          [63.221575, 26.839716],
          [63.221873, 26.83964],
          [63.22204, 26.839694],
          [63.222384, 26.83971],
          [63.222793, 26.839832],
          [63.223128, 26.839811],
          [63.223465, 26.839901],
          [63.223814, 26.840148],
          [63.224324, 26.840085],
          [63.224896, 26.84024],
          [63.225141, 26.840219],
          [63.225806, 26.840357],
          [63.226098, 26.840265],
          [63.226598, 26.840258],
          [63.226968, 26.840112],
          [63.227546, 26.840192],
          [63.227832, 26.84013],
          [63.228287, 26.840233],
          [63.228381, 26.84015],
          [63.228612, 26.840209],
          [63.228858, 26.840164],
          [63.229258, 26.840169],
          [63.229569, 26.840067],
          [63.22968, 26.840104],
          [63.229708, 26.840162],
          [63.229827, 26.84018],
          [63.22995, 26.840188],
          [63.23017, 26.840091],
          [63.230316, 26.840092],
          [63.230475, 26.840191],
          [63.230592, 26.840114],
          [63.230971, 26.840078],
          [63.231025, 26.839984],
          [63.231466, 26.840123],
          [63.231931, 26.840155],
          [63.232182, 26.84035],
          [63.232339, 26.840403],
          [63.232566, 26.840398],
          [63.232834, 26.840226],
          [63.23295, 26.840214],
          [63.233253, 26.84035],
          [63.233405, 26.840358],
          [63.233502, 26.840463],
          [63.233705, 26.840496],
          [63.233783, 26.840552],
          [63.234122, 26.840592],
          [63.234111, 26.840499],
          [63.234694, 26.840417],
          [63.234766, 26.840374],
          [63.234969, 26.840407],
          [63.235057, 26.840348],
          [63.235796, 26.840237],
          [63.236144, 26.840377],
          [63.236181, 26.840477],
          [63.236406, 26.840511],
          [63.236688, 26.840491],
          [63.236851, 26.840652],
          [63.237118, 26.840704],
          [63.237133, 26.840758],
          [63.237293, 26.840821],
          [63.23788, 26.840843],
          [63.238058, 26.841093],
          [63.238554, 26.84124],
          [63.238585, 26.841341],
          [63.238763, 26.841407],
          [63.238809, 26.84149],
          [63.238877, 26.841491],
          [63.238948, 26.841575],
          [63.239113, 26.841638],
          [63.239453, 26.841603],
          [63.239773, 26.841795],
          [63.24091, 26.841725],
          [63.240977, 26.841621],
          [63.241281, 26.84161],
          [63.241304, 26.841515],
          [63.241612, 26.841333],
          [63.241712, 26.841409],
          [63.241733, 26.841504],
          [63.241818, 26.841513],
          [63.242141, 26.841359],
          [63.24265, 26.84135],
          [63.243184, 26.841412],
          [63.243255, 26.841598],
          [63.243228, 26.841646],
          [63.243329, 26.84164],
          [63.243373, 26.8417],
          [63.243878, 26.841713],
          [63.243791, 26.841831],
          [63.243625, 26.842947],
          [63.243311, 26.843128],
          [63.243293, 26.843217],
          [63.243318, 26.843405],
          [63.243281, 26.843447],
          [63.243388, 26.843515],
          [63.243376, 26.843615],
          [63.243463, 26.843691],
          [63.243481, 26.84386],
          [63.243524, 26.843873],
          [63.243483, 26.844041],
          [63.243414, 26.844068],
          [63.24341, 26.844425],
          [63.243318, 26.844615],
          [63.2435, 26.844654],
          [63.243531, 26.844698],
          [63.243522, 26.844845],
          [63.243595, 26.844998],
          [63.243624, 26.845331],
          [63.243718, 26.845453],
          [63.243858, 26.845886],
          [63.243822, 26.846123],
          [63.244047, 26.846134],
          [63.244107, 26.84624],
          [63.244074, 26.84642],
          [63.24417, 26.846907],
          [63.244112, 26.846972],
          [63.244116, 26.847073],
          [63.24488, 26.847243],
          [63.244933, 26.847289],
          [63.244887, 26.847421],
          [63.244993, 26.847567],
          [63.244994, 26.847977],
          [63.245136, 26.848202],
          [63.245136, 26.848506],
          [63.245245, 26.848713],
          [63.245218, 26.848816],
          [63.24528, 26.848829],
          [63.245454, 26.849238],
          [63.24537, 26.849611],
          [63.24597, 26.849677],
          [63.246019, 26.84976],
          [63.246266, 26.849798],
          [63.246334, 26.849903],
          [63.246437, 26.850382],
          [63.246396, 26.850638],
          [63.24642, 26.850865],
          [63.246323, 26.850858],
          [63.246196, 26.850947],
          [63.246039, 26.850979],
          [63.245622, 26.851012],
          [63.245485, 26.85124],
          [63.24514, 26.851465],
          [63.245709, 26.851693],
          [63.245841, 26.8518],
          [63.245985, 26.851807],
          [63.246081, 26.851897],
          [63.246142, 26.852177],
          [63.246083, 26.852712],
          [63.246176, 26.852813],
          [63.246129, 26.852927],
          [63.246198, 26.853217],
          [63.246142, 26.853319],
          [63.246208, 26.853365],
          [63.24625, 26.853545],
          [63.24589, 26.853804],
          [63.245831, 26.853975],
          [63.245713, 26.854115],
          [63.245235, 26.854329],
          [63.245077, 26.854473],
          [63.245146, 26.854518],
          [63.245253, 26.854801],
          [63.245107, 26.855047],
          [63.245, 26.855397],
          [63.245033, 26.855607],
          [63.244998, 26.855675],
          [63.245081, 26.855775],
          [63.245474, 26.855888],
          [63.24542, 26.856255],
          [63.245442, 26.856745],
          [63.245546, 26.85693],
          [63.245945, 26.857386],
          [63.245958, 26.857506],
          [63.245854, 26.857843],
          [63.245816, 26.858634],
          [63.245988, 26.859287],
          [63.246168, 26.860573],
          [63.245992, 26.860894],
          [63.24635, 26.861752],
          [63.246275, 26.862193],
          [63.246659, 26.862193],
          [63.247293, 26.862347],
          [63.247282, 26.862556],
          [63.247137, 26.862822],
          [63.24708, 26.863144],
          [63.246903, 26.863418],
          [63.246706, 26.863927],
          [63.247372, 26.863941],
          [63.247807, 26.864011],
          [63.247852, 26.864061],
          [63.247841, 26.864325],
          [63.248032, 26.864453],
          [63.248153, 26.864638],
          [63.248626, 26.864809],
          [63.248478, 26.865071],
          [63.248525, 26.865405],
          [63.248405, 26.865683],
          [63.248413, 26.86578],
          [63.248531, 26.865893],
          [63.24853, 26.866334],
          [63.248662, 26.866709],
          [63.248836, 26.866939],
          [63.249327, 26.86706],
          [63.24937, 26.867131],
          [63.249299, 26.867234],
          [63.249492, 26.867712],
          [63.248919, 26.867961],
          [63.248924, 26.868054],
          [63.248505, 26.868107],
          [63.247927, 26.868271],
          [63.247938, 26.868526],
          [63.247874, 26.868936],
          [63.24791, 26.869003],
          [63.247732, 26.868994],
          [63.247749, 26.869088],
          [63.247184, 26.869384],
          [63.247129, 26.869534],
          [63.246954, 26.869668],
          [63.246949, 26.869803],
          [63.247125, 26.870262],
          [63.246662, 26.870398],
          [63.246301, 26.870594],
          [63.245996, 26.870571],
          [63.245838, 26.87125],
          [63.245278, 26.871296],
          [63.244819, 26.871279],
          [63.244314, 26.871393],
          [63.244225, 26.871502],
          [63.244101, 26.871528],
          [63.243918, 26.871671],
          [63.243896, 26.871828],
          [63.243672, 26.872012],
          [63.243697, 26.872213],
          [63.243286, 26.872421],
          [63.243172, 26.872523],
          [63.24316, 26.873045],
          [63.243244, 26.873105],
          [63.243239, 26.873179],
          [63.243086, 26.873294],
          [63.243148, 26.873408],
          [63.24306, 26.873719],
          [63.243068, 26.873998],
          [63.243154, 26.874357],
          [63.243068, 26.874387],
          [63.242798, 26.874343],
          [63.242351, 26.874354],
          [63.24214, 26.874494],
          [63.24203, 26.874518],
          [63.242065, 26.874566],
          [63.242041, 26.874685],
          [63.242313, 26.87468],
          [63.242426, 26.874803],
          [63.242333, 26.874978],
          [63.2424, 26.875462],
          [63.242766, 26.875522],
          [63.242812, 26.875968],
          [63.242733, 26.876244],
          [63.242951, 26.876874],
          [63.2429, 26.876982],
          [63.242948, 26.877464],
          [63.24282, 26.87747],
          [63.242785, 26.877513],
          [63.242492, 26.877589],
          [63.241229, 26.87742],
          [63.241043, 26.877449],
          [63.24077, 26.877389],
          [63.240644, 26.877408],
          [63.240092, 26.877344],
          [63.239861, 26.877384],
          [63.239548, 26.877539],
          [63.239557, 26.877625],
          [63.239309, 26.877709],
          [63.239304, 26.877816],
          [63.239227, 26.877905],
          [63.239155, 26.87819],
          [63.239159, 26.878483],
          [63.238799, 26.878466],
          [63.238791, 26.878512],
          [63.238819, 26.878803],
          [63.238942, 26.879073],
          [63.239232, 26.879111],
          [63.239215, 26.879709],
          [63.239131, 26.879765],
          [63.238711, 26.879741],
          [63.238526, 26.879645],
          [63.238269, 26.879704],
          [63.238157, 26.879659],
          [63.237862, 26.87967],
          [63.2375, 26.879745],
          [63.237412, 26.879812],
          [63.237472, 26.880087],
          [63.237051, 26.880393],
          [63.237013, 26.880547],
          [63.237146, 26.880777],
          [63.237207, 26.881028],
          [63.237115, 26.881049],
          [63.23711, 26.881185],
          [63.236996, 26.881215],
          [63.237155, 26.881567],
          [63.237167, 26.881697],
          [63.237314, 26.881934],
          [63.23735, 26.882201],
          [63.237282, 26.882433],
          [63.237374, 26.882444],
          [63.237464, 26.882384],
          [63.237865, 26.882448],
          [63.238036, 26.882409],
          [63.238267, 26.882535],
          [63.238429, 26.882523],
          [63.238586, 26.882634],
          [63.238805, 26.882589],
          [63.239214, 26.882701],
          [63.239153, 26.882911],
          [63.239219, 26.882941],
          [63.239053, 26.883004],
          [63.239015, 26.883205],
          [63.239073, 26.883418],
          [63.239226, 26.883436],
          [63.23917, 26.883638],
          [63.239182, 26.884023],
          [63.238947, 26.884055],
          [63.238904, 26.884229],
          [63.238823, 26.884242],
          [63.238835, 26.884513],
          [63.238889, 26.884504],
          [63.238908, 26.884563],
          [63.238816, 26.884872],
          [63.238675, 26.88508],
          [63.238793, 26.885097],
          [63.238942, 26.885274],
          [63.238931, 26.885639],
          [63.23899, 26.885697],
          [63.239053, 26.885932],
          [63.238874, 26.88602],
          [63.238853, 26.88611],
          [63.238774, 26.886118],
          [63.238687, 26.886289],
          [63.23878, 26.88635],
          [63.23909, 26.886394],
          [63.239122, 26.886456],
          [63.239078, 26.886681],
          [63.239323, 26.886699],
          [63.239343, 26.886792],
          [63.239619, 26.886618],
          [63.239766, 26.886642],
          [63.239915, 26.886785],
          [63.240177, 26.886852],
          [63.2405, 26.887044],
          [63.240774, 26.886981],
          [63.240949, 26.88706],
          [63.241186, 26.886981],
          [63.241467, 26.88706],
          [63.241298, 26.887268],
          [63.24108, 26.887378],
          [63.241069, 26.887471],
          [63.24085, 26.887604],
          [63.240879, 26.887654],
          [63.240841, 26.887723],
          [63.241004, 26.887848],
          [63.241001, 26.887947],
          [63.241083, 26.88797],
          [63.241078, 26.888163],
          [63.241308, 26.88838],
          [63.240909, 26.888487],
          [63.240943, 26.888687],
          [63.240868, 26.888801],
          [63.240916, 26.888891],
          [63.240866, 26.888934],
          [63.240874, 26.889028],
          [63.240629, 26.88907],
          [63.240408, 26.889009],
          [63.240303, 26.889227],
          [63.240685, 26.88959],
          [63.240826, 26.889611],
          [63.240812, 26.889763],
          [63.240871, 26.88985],
          [63.240545, 26.88989],
          [63.240493, 26.889924],
          [63.240507, 26.890016],
          [63.240659, 26.890119],
          [63.24067, 26.890216],
          [63.240956, 26.890463],
          [63.241195, 26.890799],
          [63.241271, 26.890991],
          [63.241553, 26.891224],
          [63.241605, 26.891248],
          [63.241659, 26.891213],
          [63.241858, 26.891301],
          [63.241843, 26.891364],
          [63.242029, 26.891433],
          [63.242064, 26.891539],
          [63.242073, 26.891654],
          [63.242007, 26.89172],
          [63.241582, 26.891717],
          [63.241374, 26.891771],
          [63.241411, 26.891861],
          [63.241381, 26.892107],
          [63.241233, 26.892514],
          [63.241115, 26.892628],
          [63.240941, 26.892642],
          [63.240713, 26.89298],
          [63.240537, 26.893025],
          [63.240525, 26.893082],
          [63.240939, 26.893136],
          [63.241364, 26.893284],
          [63.241258, 26.893398],
          [63.240976, 26.893384],
          [63.240927, 26.893442],
          [63.240684, 26.893423],
          [63.240713, 26.893629],
          [63.240843, 26.893827],
          [63.241015, 26.893863],
          [63.241034, 26.893938],
          [63.240559, 26.894281],
          [63.240467, 26.894302],
          [63.24024, 26.894642],
          [63.240043, 26.894673],
          [63.23997, 26.894766],
          [63.240377, 26.895047],
          [63.240285, 26.895094],
          [63.240327, 26.895219],
          [63.240523, 26.895294],
          [63.240633, 26.895388],
          [63.240611, 26.89576],
          [63.240511, 26.895889],
          [63.240401, 26.895923],
          [63.240465, 26.896063],
          [63.240119, 26.896483],
          [63.240007, 26.896512],
          [63.239835, 26.896721],
          [63.23984, 26.896829],
          [63.239668, 26.896879],
          [63.2396, 26.897184],
          [63.239332, 26.897264],
          [63.239261, 26.897338],
          [63.239168, 26.897983],
          [63.23979, 26.898326],
          [63.239999, 26.898336],
          [63.240198, 26.898469],
          [63.240575, 26.898578],
          [63.240717, 26.898736],
          [63.240932, 26.898813],
          [63.240927, 26.899019],
          [63.241099, 26.899229],
          [63.241319, 26.899272],
          [63.241457, 26.899409],
          [63.241561, 26.899407],
          [63.242184, 26.899649],
          [63.242385, 26.899996],
          [63.242469, 26.900717],
          [63.242374, 26.900883],
          [63.242875, 26.900909],
          [63.243221, 26.901061],
          [63.243288, 26.901013],
          [63.244052, 26.901077],
          [63.24423, 26.901013],
          [63.244383, 26.901047],
          [63.244692, 26.90103],
          [63.244864, 26.901152],
          [63.244907, 26.901238],
          [63.245557, 26.901352],
          [63.245803, 26.901485],
          [63.246462, 26.901499],
          [63.24678, 26.901577],
          [63.247105, 26.901706],
          [63.247251, 26.901884],
          [63.247382, 26.901903],
          [63.247536, 26.902069],
          [63.247672, 26.902098],
          [63.247833, 26.902241],
          [63.248095, 26.902337],
          [63.248222, 26.90251],
          [63.248355, 26.902524],
          [63.248363, 26.90259],
          [63.248499, 26.90253],
          [63.248707, 26.902534],
          [63.249191, 26.902695],
          [63.249385, 26.902688],
          [63.24967, 26.902894],
          [63.250452, 26.902942],
          [63.250551, 26.902914],
          [63.250747, 26.902759],
          [63.250904, 26.902797],
          [63.251095, 26.902746],
          [63.251281, 26.902473],
          [63.251566, 26.902505],
          [63.251641, 26.902552],
          [63.251639, 26.902773],
          [63.251914, 26.903087],
          [63.252271, 26.903234],
          [63.252366, 26.903376],
          [63.252657, 26.903604],
          [63.252856, 26.903562],
          [63.252985, 26.903483],
          [63.253311, 26.903519],
          [63.2528, 26.947049],
          [63.251094, 27.092151],
          [63.255673, 27.09154],
          [63.258876, 27.092236],
          [63.262908, 27.094158],
          [63.263376, 27.095199],
          [63.263576, 27.100625],
          [63.265788, 27.105559],
          [63.264837, 27.107498],
          [63.265418, 27.108725],
          [63.266958, 27.109604],
          [63.27003, 27.110129],
          [63.272826, 27.110613],
          [63.275886, 27.112961],
          [63.276333, 27.114752],
          [63.275806, 27.115971],
          [63.275631, 27.116785],
          [63.276199, 27.117815],
          [63.279469, 27.119971],
          [63.284152, 27.121106],
          [63.284712, 27.119254],
          [63.285568, 27.11834],
          [63.295226, 27.115178],
          [63.297227, 27.115605],
          [63.298839, 27.117647],
          [63.298864, 27.120092],
          [63.299668, 27.121804],
          [63.301573, 27.125988],
          [63.304862, 27.128807],
          [63.307125, 27.129646],
          [63.309217, 27.129353],
          [63.313829, 27.127412],
          [63.320018, 27.126461],
          [63.322858, 27.124021],
          [63.327237, 27.120529],
          [63.330475, 27.119145],
          [63.33327, 27.1184],
          [63.332056, 27.120917],
          [63.283296, 27.208663],
          [63.283116, 27.208824],
          [63.282898, 27.208921],
          [63.28278, 27.209079],
          [63.282772, 27.209182],
          [63.282886, 27.209324],
          [63.28281, 27.209499],
          [63.282847, 27.209678],
          [63.282701, 27.209795],
          [63.28233, 27.209881],
          [63.282265, 27.21019],
          [63.282451, 27.210743],
          [63.28241, 27.210833],
          [63.282254, 27.210851],
          [63.282104, 27.211013],
          [63.282271, 27.211719],
          [63.282352, 27.211772],
          [63.282495, 27.212091],
          [63.282588, 27.212183],
          [63.282539, 27.212436],
          [63.282489, 27.212477],
          [63.282475, 27.212631],
          [63.282525, 27.21277],
          [63.282279, 27.213058],
          [63.281982, 27.213289],
          [63.281796, 27.213607],
          [63.281777, 27.213732],
          [63.281458, 27.213738],
          [63.281338, 27.213648],
          [63.28014, 27.213823],
          [63.280119, 27.214266],
          [63.279993, 27.214433],
          [63.279522, 27.214714],
          [63.27959, 27.215139],
          [63.279488, 27.215207],
          [63.279388, 27.215362],
          [63.279323, 27.215548],
          [63.279258, 27.215605],
          [63.279221, 27.215817],
          [63.278885, 27.215934],
          [63.278787, 27.215811],
          [63.278511, 27.215809],
          [63.278275, 27.21588],
          [63.278082, 27.216202],
          [63.277932, 27.216272],
          [63.277881, 27.216352],
          [63.277681, 27.216404],
          [63.277552, 27.21653],
          [63.277392, 27.216514],
          [63.277301, 27.216554],
          [63.277369, 27.216768],
          [63.277354, 27.216947],
          [63.277206, 27.217106],
          [63.276967, 27.21725],
          [63.276908, 27.217344],
          [63.27691, 27.217489],
          [63.276822, 27.217752],
          [63.277034, 27.217915],
          [63.276901, 27.218159],
          [63.276624, 27.218201],
          [63.276459, 27.218331],
          [63.276346, 27.218312],
          [63.276224, 27.218393],
          [63.276062, 27.218431],
          [63.276037, 27.21848],
          [63.275781, 27.218455],
          [63.2756, 27.21849],
          [63.275131, 27.218705],
          [63.274923, 27.218888],
          [63.274775, 27.218892],
          [63.274131, 27.219242],
          [63.273906, 27.219252],
          [63.273857, 27.219296],
          [63.273639, 27.219335],
          [63.273552, 27.219406],
          [63.273217, 27.219393],
          [63.272885, 27.219452],
          [63.272631, 27.219628],
          [63.272485, 27.219795],
          [63.271957, 27.219647],
          [63.271835, 27.219655],
          [63.271641, 27.219743],
          [63.271477, 27.22],
          [63.271142, 27.220059],
          [63.270919, 27.220156],
          [63.270836, 27.220135],
          [63.270486, 27.219566],
          [63.270292, 27.219582],
          [63.269853, 27.219719],
          [63.269604, 27.219752],
          [63.269194, 27.219918],
          [63.269022, 27.219885],
          [63.268671, 27.220008],
          [63.268229, 27.220057],
          [63.268138, 27.220112],
          [63.268074, 27.220273],
          [63.267754, 27.220399],
          [63.267672, 27.220459],
          [63.267649, 27.220561],
          [63.267525, 27.220617],
          [63.267418, 27.220651],
          [63.267161, 27.220578],
          [63.266822, 27.22067],
          [63.26663, 27.220859],
          [63.266138, 27.221142],
          [63.266125, 27.221198],
          [63.266004, 27.221282],
          [63.265974, 27.221414],
          [63.26585, 27.221533],
          [63.265865, 27.221593],
          [63.265984, 27.22162],
          [63.266225, 27.222084],
          [63.266439, 27.222357],
          [63.266849, 27.22275],
          [63.267168, 27.222889],
          [63.267162, 27.223048],
          [63.266917, 27.223188],
          [63.266751, 27.223406],
          [63.266775, 27.223471],
          [63.266599, 27.223607],
          [63.266005, 27.223811],
          [63.265763, 27.223827],
          [63.265507, 27.223969],
          [63.265214, 27.22402],
          [63.264994, 27.223824],
          [63.264587, 27.223733],
          [63.263876, 27.223945],
          [63.263835, 27.2239],
          [63.26367, 27.223869],
          [63.263505, 27.223955],
          [63.263037, 27.224022],
          [63.262781, 27.223921],
          [63.26264, 27.22391],
          [63.26252, 27.224013],
          [63.262154, 27.223845],
          [63.261901, 27.223876],
          [63.261837, 27.223951],
          [63.261649, 27.223928],
          [63.261503, 27.22385],
          [63.261306, 27.223869],
          [63.260892, 27.223988],
          [63.260621, 27.224294],
          [63.260437, 27.224229],
          [63.260378, 27.224307],
          [63.260159, 27.224369],
          [63.260046, 27.224259],
          [63.259799, 27.224345],
          [63.259383, 27.224414],
          [63.25926, 27.224533],
          [63.25911, 27.224499],
          [63.258976, 27.22438],
          [63.258215, 27.224772],
          [63.258353, 27.225118],
          [63.258177, 27.225127],
          [63.25802, 27.225189],
          [63.257903, 27.225304],
          [63.257479, 27.225308],
          [63.257026, 27.225435],
          [63.256975, 27.225426],
          [63.256954, 27.225342],
          [63.256687, 27.225331],
          [63.256062, 27.225561],
          [63.255783, 27.225534],
          [63.255425, 27.225626],
          [63.254866, 27.225912],
          [63.254535, 27.226021],
          [63.254301, 27.226055],
          [63.25391, 27.226027],
          [63.253745, 27.226066],
          [63.253546, 27.226032],
          [63.253393, 27.226062],
          [63.253312, 27.226157],
          [63.25307, 27.226129],
          [63.25282, 27.226219],
          [63.252847, 27.226249],
          [63.252592, 27.226425],
          [63.2525, 27.226402],
          [63.252133, 27.226575],
          [63.25194, 27.226562],
          [63.251883, 27.226618],
          [63.251861, 27.226874],
          [63.251754, 27.226939],
          [63.251767, 27.226994],
          [63.250908, 27.2272],
          [63.25075, 27.227281],
          [63.250368, 27.227334],
          [63.250017, 27.227302],
          [63.249938, 27.227378],
          [63.249258, 27.227354],
          [63.249366, 27.22719],
          [63.249276, 27.227189],
          [63.249401, 27.227],
          [63.24948, 27.226717],
          [63.248977, 27.226853],
          [63.248908, 27.226816],
          [63.248757, 27.226855],
          [63.248575, 27.226961],
          [63.248186, 27.226971],
          [63.247951, 27.227093],
          [63.24733, 27.227205],
          [63.247048, 27.227194],
          [63.247003, 27.227105],
          [63.246405, 27.227024],
          [63.246333, 27.227076],
          [63.246152, 27.227039],
          [63.245749, 27.22713],
          [63.24542, 27.22711],
          [63.245079, 27.227],
          [63.244999, 27.226858],
          [63.244886, 27.226823],
          [63.244462, 27.226825],
          [63.243679, 27.226947],
          [63.243674, 27.227046],
          [63.243429, 27.227245],
          [63.242805, 27.227273],
          [63.242611, 27.227453],
          [63.242281, 27.227401],
          [63.241953, 27.227566],
          [63.241329, 27.227424],
          [63.24061, 27.227577],
          [63.239841, 27.227485],
          [63.239423, 27.227574],
          [63.239246, 27.227549],
          [63.239034, 27.227612],
          [63.238945, 27.227585],
          [63.238638, 27.227652],
          [63.238541, 27.227715],
          [63.238468, 27.227704],
          [63.238345, 27.227587],
          [63.238422, 27.22719],
          [63.238331, 27.227063],
          [63.238391, 27.226915],
          [63.238357, 27.226837],
          [63.237955, 27.226584],
          [63.237968, 27.226471],
          [63.238171, 27.226192],
          [63.238137, 27.226012],
          [63.238186, 27.225848],
          [63.238121, 27.225643],
          [63.237746, 27.225531],
          [63.237176, 27.225595],
          [63.236863, 27.225578],
          [63.236832, 27.22553],
          [63.236357, 27.22551],
          [63.23596, 27.225547],
          [63.235506, 27.225715],
          [63.23511, 27.225758],
          [63.234924, 27.225731],
          [63.234724, 27.225808],
          [63.234591, 27.225648],
          [63.234612, 27.225524],
          [63.234441, 27.225315],
          [63.234167, 27.225324],
          [63.234108, 27.225371],
          [63.233748, 27.225394],
          [63.233659, 27.22544],
          [63.23361, 27.225604],
          [63.233475, 27.225615],
          [63.23333, 27.225558],
          [63.233185, 27.225587],
          [63.233176, 27.225733],
          [63.232884, 27.225816],
          [63.232605, 27.22588],
          [63.23235, 27.225845],
          [63.232311, 27.225897],
          [63.232063, 27.22592],
          [63.232156, 27.225738],
          [63.232279, 27.225648],
          [63.232141, 27.225362],
          [63.232167, 27.225316],
          [63.23178, 27.224973],
          [63.231673, 27.224902],
          [63.231311, 27.224797],
          [63.231128, 27.224602],
          [63.23113, 27.22476],
          [63.230984, 27.224921],
          [63.230777, 27.225018],
          [63.230352, 27.225082],
          [63.229813, 27.225387],
          [63.22871, 27.225528],
          [63.227843, 27.225698],
          [63.227186, 27.225754],
          [63.225433, 27.225303],
          [63.224699, 27.225621],
          [63.224551, 27.225839],
          [63.222507, 27.227398],
          [63.222371, 27.227768],
          [63.221502, 27.228337],
          [63.221109, 27.22836],
          [63.220916, 27.228455],
          [63.22068, 27.228902],
          [63.219901, 27.230001],
          [63.219527, 27.230611],
          [63.218796, 27.231363],
          [63.218526, 27.231886],
          [63.218192, 27.232115],
          [63.217947, 27.232211],
          [63.21776, 27.232437],
          [63.217491, 27.232657],
          [63.215603, 27.233421],
          [63.215103, 27.233961],
          [63.214537, 27.234202],
          [63.214087, 27.234612],
          [63.21362, 27.234656],
          [63.213067, 27.235011],
          [63.212252, 27.23589],
          [63.211309, 27.236447],
          [63.210884, 27.237111],
          [63.210662, 27.237568],
          [63.210384, 27.237935],
          [63.210129, 27.238546],
          [63.210048, 27.238613],
          [63.209791, 27.239048],
          [63.209723, 27.239254],
          [63.209662, 27.239941],
          [63.20931, 27.240477],
          [63.209142, 27.240958],
          [63.209196, 27.24149],
          [63.209116, 27.241682],
          [63.208763, 27.24206],
          [63.20859, 27.242432],
          [63.208198, 27.243632],
          [63.207902, 27.244213],
          [63.207047, 27.245006],
          [63.206268, 27.245354],
          [63.20549, 27.246214],
          [63.205095, 27.246758],
          [63.20438, 27.247044],
          [63.203975, 27.247564],
          [63.203938, 27.247881],
          [63.203781, 27.248136],
          [63.203407, 27.248461],
          [63.203293, 27.248465],
          [63.202958, 27.248874],
          [63.202985, 27.24903],
          [63.203149, 27.249137],
          [63.203192, 27.249232],
          [63.203562, 27.2494],
          [63.203562, 27.249491],
          [63.203772, 27.24957],
          [63.203945, 27.249564],
          [63.203921, 27.249676],
          [63.204199, 27.250016],
          [63.204313, 27.250356],
          [63.204244, 27.250543],
          [63.204381, 27.250822],
          [63.20417, 27.250968],
          [63.204172, 27.251018],
          [63.203988, 27.25112],
          [63.203988, 27.251164],
          [63.20375, 27.251271],
          [63.203743, 27.251335],
          [63.203588, 27.251401],
          [63.203506, 27.251537],
          [63.203231, 27.251704],
          [63.203251, 27.252069],
          [63.203165, 27.252118],
          [63.203164, 27.252203],
          [63.203008, 27.252309],
          [63.202845, 27.252613],
          [63.202653, 27.252726],
          [63.202596, 27.252829],
          [63.202459, 27.252802],
          [63.202374, 27.252882],
          [63.202226, 27.252914],
          [63.202136, 27.253093],
          [63.202025, 27.253187],
          [63.201991, 27.253351],
          [63.201898, 27.253398],
          [63.20177, 27.253559],
          [63.201709, 27.253791],
          [63.201534, 27.25411],
          [63.20135, 27.25424],
          [63.201291, 27.254334],
          [63.201267, 27.25455],
          [63.201148, 27.254687],
          [63.201137, 27.254809],
          [63.200937, 27.255053],
          [63.200689, 27.255168],
          [63.200675, 27.255442],
          [63.200445, 27.255718],
          [63.200415, 27.256017],
          [63.200083, 27.256259],
          [63.199967, 27.256663],
          [63.199778, 27.256801],
          [63.199441, 27.257173],
          [63.199398, 27.257283],
          [63.199401, 27.257585],
          [63.199252, 27.257794],
          [63.199201, 27.258189],
          [63.199151, 27.258227],
          [63.199221, 27.258286],
          [63.199265, 27.258476],
          [63.199067, 27.2589],
          [63.198822, 27.259172],
          [63.198716, 27.259436],
          [63.198149, 27.259345],
          [63.197798, 27.259357],
          [63.197711, 27.259141],
          [63.19751, 27.258962],
          [63.197445, 27.258912],
          [63.197337, 27.258914],
          [63.197023, 27.258594],
          [63.196592, 27.258373],
          [63.196024, 27.258715],
          [63.195866, 27.258768],
          [63.195624, 27.258834],
          [63.195525, 27.258809],
          [63.195306, 27.25889],
          [63.195061, 27.258863],
          [63.194959, 27.259081],
          [63.194175, 27.25932],
          [63.194086, 27.259398],
          [63.194079, 27.259563],
          [63.194037, 27.259606],
          [63.193781, 27.259673],
          [63.193608, 27.259665],
          [63.193472, 27.259568],
          [63.193023, 27.259488],
          [63.192835, 27.259496],
          [63.192597, 27.25959],
          [63.192539, 27.259357],
          [63.192419, 27.259355],
          [63.192191, 27.259139],
          [63.19189, 27.259268],
          [63.191784, 27.259228],
          [63.19156, 27.259244],
          [63.191493, 27.259216],
          [63.191473, 27.259105],
          [63.191183, 27.258987],
          [63.190615, 27.259044],
          [63.190463, 27.259214],
          [63.190308, 27.259279],
          [63.19018, 27.259276],
          [63.190161, 27.259412],
          [63.189919, 27.259457],
          [63.189894, 27.259598],
          [63.189715, 27.259751],
          [63.189635, 27.260014],
          [63.189541, 27.260026],
          [63.189471, 27.25991],
          [63.189433, 27.260015],
          [63.189337, 27.260034],
          [63.189144, 27.259945],
          [63.189079, 27.26012],
          [63.188858, 27.260284],
          [63.188653, 27.260285],
          [63.188379, 27.26052],
          [63.188328, 27.26046],
          [63.18813, 27.260401],
          [63.187933, 27.260239],
          [63.187836, 27.260255],
          [63.187776, 27.260162],
          [63.187634, 27.260145],
          [63.187632, 27.260076],
          [63.187341, 27.260034],
          [63.187222, 27.259943],
          [63.187237, 27.259854],
          [63.187055, 27.259719],
          [63.187007, 27.259496],
          [63.186768, 27.259359],
          [63.1867, 27.259372],
          [63.186582, 27.259151],
          [63.18625, 27.259273],
          [63.185772, 27.258862],
          [63.18554, 27.258815],
          [63.185439, 27.258841],
          [63.185325, 27.258761],
          [63.18482, 27.258934],
          [63.184688, 27.258946],
          [63.184543, 27.258886],
          [63.183907, 27.259074],
          [63.183585, 27.258756],
          [63.183279, 27.258632],
          [63.18305, 27.258702],
          [63.182924, 27.258663],
          [63.182712, 27.258346],
          [63.182622, 27.2583],
          [63.182505, 27.258142],
          [63.182262, 27.258094],
          [63.182038, 27.257944],
          [63.181841, 27.257916],
          [63.181755, 27.257796],
          [63.18166, 27.257834],
          [63.181492, 27.257759],
          [63.181395, 27.257819],
          [63.181342, 27.257937],
          [63.180969, 27.258042],
          [63.180871, 27.258144],
          [63.18077, 27.258081],
          [63.180587, 27.258126],
          [63.180375, 27.258114],
          [63.180189, 27.257995],
          [63.180147, 27.257917],
          [63.179762, 27.257989],
          [63.179653, 27.257941],
          [63.179524, 27.257991],
          [63.179395, 27.257893],
          [63.179228, 27.257897],
          [63.179112, 27.257713],
          [63.178998, 27.257631],
          [63.17883, 27.257629],
          [63.178493, 27.25746],
          [63.178155, 27.257405],
          [63.17787, 27.257489],
          [63.177675, 27.257455],
          [63.177119, 27.257544],
          [63.176982, 27.257685],
          [63.17694, 27.257862],
          [63.176834, 27.257902],
          [63.176766, 27.258036],
          [63.176402, 27.258116],
          [63.176178, 27.25831],
          [63.175906, 27.258436],
          [63.17582, 27.258341],
          [63.175732, 27.25832],
          [63.175606, 27.258158],
          [63.175307, 27.258156],
          [63.175058, 27.257963],
          [63.174815, 27.25763],
          [63.174089, 27.257165],
          [63.173942, 27.257023],
          [63.17393, 27.256901],
          [63.173791, 27.256824],
          [63.173748, 27.25674],
          [63.173603, 27.256679],
          [63.17356, 27.256459],
          [63.173475, 27.256374],
          [63.173391, 27.256171],
          [63.173178, 27.256032],
          [63.17307, 27.255761],
          [63.172961, 27.255744],
          [63.17294, 27.255675],
          [63.17275, 27.25557],
          [63.172636, 27.255411],
          [63.17258, 27.255228],
          [63.172343, 27.255056],
          [63.172308, 27.254688],
          [63.172368, 27.254536],
          [63.172073, 27.254461],
          [63.171956, 27.254499],
          [63.171506, 27.254476],
          [63.171095, 27.254534],
          [63.170896, 27.254481],
          [63.170467, 27.254604],
          [63.170238, 27.254613],
          [63.169916, 27.254429],
          [63.169705, 27.254406],
          [63.169469, 27.254221],
          [63.169283, 27.254222],
          [63.169062, 27.254084],
          [63.168844, 27.254032],
          [63.168384, 27.253798],
          [63.168153, 27.253757],
          [63.167908, 27.253567],
          [63.167761, 27.253574],
          [63.167415, 27.253381],
          [63.16723, 27.253389],
          [63.166992, 27.253194],
          [63.166668, 27.253124],
          [63.166509, 27.253006],
          [63.166372, 27.253118],
          [63.166096, 27.253058],
          [63.16576, 27.253086],
          [63.164979, 27.25281],
          [63.164872, 27.252666],
          [63.164548, 27.252653],
          [63.164499, 27.252561],
          [63.164182, 27.252572],
          [63.163898, 27.252647],
          [63.163788, 27.252579],
          [63.163589, 27.252593],
          [63.16354, 27.252453],
          [63.163402, 27.252363],
          [63.163378, 27.252266],
          [63.163229, 27.252117],
          [63.163137, 27.251757],
          [63.163164, 27.251654],
          [63.163117, 27.251332],
          [63.163032, 27.251213],
          [63.162914, 27.251217],
          [63.162921, 27.251098],
          [63.162827, 27.251091],
          [63.16283, 27.250985],
          [63.162711, 27.250939],
          [63.162737, 27.250851],
          [63.162643, 27.250803],
          [63.162626, 27.250717],
          [63.162714, 27.250545],
          [63.162666, 27.250427],
          [63.162432, 27.250403],
          [63.162428, 27.250308],
          [63.161539, 27.249882],
          [63.161542, 27.249837],
          [63.161275, 27.249673],
          [63.161225, 27.249594],
          [63.16057, 27.249329],
          [63.160214, 27.249068],
          [63.16008, 27.248827],
          [63.159782, 27.248587],
          [63.159332, 27.248564],
          [63.158832, 27.248621],
          [63.158473, 27.248424],
          [63.158236, 27.248385],
          [63.158176, 27.248292],
          [63.15807, 27.248303],
          [63.158041, 27.248229],
          [63.15766, 27.248212],
          [63.157562, 27.248164],
          [63.15745, 27.248297],
          [63.157386, 27.248491],
          [63.157306, 27.248351],
          [63.156819, 27.248326],
          [63.156588, 27.248209],
          [63.156537, 27.24826],
          [63.156294, 27.248149],
          [63.156143, 27.248128],
          [63.156056, 27.248154],
          [63.156041, 27.248214],
          [63.155842, 27.248345],
          [63.155439, 27.248497],
          [63.155199, 27.248642],
          [63.155049, 27.248774],
          [63.154989, 27.248922],
          [63.154868, 27.248888],
          [63.154816, 27.248797],
          [63.154461, 27.248717],
          [63.154429, 27.24859],
          [63.154245, 27.248464],
          [63.153833, 27.248488],
          [63.153429, 27.248363],
          [63.153192, 27.248159],
          [63.152863, 27.248112],
          [63.152598, 27.247884],
          [63.152485, 27.247881],
          [63.152283, 27.247667],
          [63.152184, 27.247686],
          [63.151931, 27.247555],
          [63.151936, 27.247439],
          [63.151759, 27.247312],
          [63.151691, 27.247189],
          [63.151603, 27.247196],
          [63.151274, 27.24707],
          [63.151052, 27.246869],
          [63.150983, 27.246543],
          [63.150735, 27.246474],
          [63.150569, 27.246481],
          [63.150234, 27.246185],
          [63.150026, 27.246127],
          [63.149933, 27.246053],
          [63.149743, 27.246042],
          [63.149823, 27.24597],
          [63.149763, 27.24588],
          [63.149814, 27.245784],
          [63.149729, 27.245439],
          [63.149452, 27.245287],
          [63.149444, 27.245206],
          [63.149304, 27.245109],
          [63.149208, 27.245099],
          [63.149039, 27.244913],
          [63.148742, 27.244736],
          [63.14852, 27.244459],
          [63.148505, 27.244354],
          [63.148233, 27.243902],
          [63.147714, 27.243839],
          [63.14715, 27.243581],
          [63.146905, 27.2436],
          [63.146859, 27.24352],
          [63.146739, 27.243487],
          [63.146494, 27.243312],
          [63.146255, 27.24328],
          [63.146001, 27.243355],
          [63.145365, 27.243003],
          [63.145005, 27.242616],
          [63.144653, 27.242644],
          [63.144482, 27.242703],
          [63.144071, 27.242986],
          [63.144051, 27.24306],
          [63.143777, 27.243203],
          [63.143572, 27.243173],
          [63.143313, 27.24329],
          [63.143286, 27.243346],
          [63.143139, 27.24334],
          [63.142983, 27.243402],
          [63.142764, 27.243664],
          [63.14217, 27.243812],
          [63.14174, 27.243804],
          [63.141425, 27.243967],
          [63.141034, 27.243908],
          [63.140855, 27.244],
          [63.140611, 27.244009],
          [63.140475, 27.244093],
          [63.140161, 27.244104],
          [63.139935, 27.244202],
          [63.139833, 27.244165],
          [63.139664, 27.244191],
          [63.139495, 27.244053],
          [63.139209, 27.244131],
          [63.139013, 27.244246],
          [63.138604, 27.244354],
          [63.13848, 27.24455],
          [63.138394, 27.244595],
          [63.138256, 27.244594],
          [63.138161, 27.244663],
          [63.1378, 27.244743],
          [63.137218, 27.244349],
          [63.137227, 27.244265],
          [63.137092, 27.244142],
          [63.137058, 27.244022],
          [63.136868, 27.244005],
          [63.136383, 27.243687],
          [63.136457, 27.243533],
          [63.136222, 27.243023],
          [63.136255, 27.242763],
          [63.136319, 27.242614],
          [63.136306, 27.242489],
          [63.13637, 27.242337],
          [63.136443, 27.2423],
          [63.136455, 27.242123],
          [63.13665, 27.241977],
          [63.136378, 27.241964],
          [63.13627, 27.241842],
          [63.136154, 27.241798],
          [63.136055, 27.241652],
          [63.135678, 27.24144],
          [63.135543, 27.241181],
          [63.13533, 27.241073],
          [63.13531, 27.240992],
          [63.135148, 27.240944],
          [63.135074, 27.240974],
          [63.134658, 27.240935],
          [63.134582, 27.24087],
          [63.134026, 27.240947],
          [63.133852, 27.240772],
          [63.133831, 27.240684],
          [63.133389, 27.240707],
          [63.133231, 27.240643],
          [63.133334, 27.24094],
          [63.133329, 27.2411],
          [63.133171, 27.24135],
          [63.132902, 27.241561],
          [63.132469, 27.241528],
          [63.132408, 27.24169],
          [63.131549, 27.241899],
          [63.131354, 27.241819],
          [63.131201, 27.241862],
          [63.130598, 27.241809],
          [63.130342, 27.241862],
          [63.130116, 27.241836],
          [63.129992, 27.241937],
          [63.129672, 27.242047],
          [63.129343, 27.241788],
          [63.129169, 27.241736],
          [63.129094, 27.241655],
          [63.128792, 27.241521],
          [63.128736, 27.241433],
          [63.128614, 27.241375],
          [63.12844, 27.241126],
          [63.128206, 27.241159],
          [63.128121, 27.241103],
          [63.127998, 27.241146],
          [63.127877, 27.241036],
          [63.127623, 27.241092],
          [63.127458, 27.241013],
          [63.127361, 27.241016],
          [63.126886, 27.241223],
          [63.126678, 27.241344],
          [63.126639, 27.241415],
          [63.126464, 27.241229],
          [63.126323, 27.241247],
          [63.126248, 27.241125],
          [63.126116, 27.24104],
          [63.125875, 27.240912],
          [63.125505, 27.240804],
          [63.125251, 27.240606],
          [63.125002, 27.240565],
          [63.124645, 27.240327],
          [63.124378, 27.240259],
          [63.124006, 27.240281],
          [63.124003, 27.240208],
          [63.123799, 27.239924],
          [63.123708, 27.239615],
          [63.123615, 27.239605],
          [63.123555, 27.239543],
          [63.123358, 27.239528],
          [63.123315, 27.239432],
          [63.123407, 27.239261],
          [63.123416, 27.238935],
          [63.123499, 27.238925],
          [63.12351, 27.238727],
          [63.123576, 27.238571],
          [63.123497, 27.238494],
          [63.123471, 27.238359],
          [63.123521, 27.238318],
          [63.12346, 27.238254],
          [63.123436, 27.238112],
          [63.123167, 27.237866],
          [63.122946, 27.237862],
          [63.122445, 27.237633],
          [63.122483, 27.237559],
          [63.122453, 27.237498],
          [63.122219, 27.237414],
          [63.122069, 27.237303],
          [63.121963, 27.236905],
          [63.121111, 27.236945],
          [63.121047, 27.237005],
          [63.120945, 27.237015],
          [63.120575, 27.237011],
          [63.120326, 27.237165],
          [63.120281, 27.237288],
          [63.120195, 27.237257],
          [63.119579, 27.237314],
          [63.119547, 27.237365],
          [63.119391, 27.237383],
          [63.119287, 27.237508],
          [63.119164, 27.237538],
          [63.118636, 27.237499],
          [63.11861, 27.237409],
          [63.118508, 27.237403],
          [63.117947, 27.237564],
          [63.11778, 27.237688],
          [63.11741, 27.237446],
          [63.117348, 27.237376],
          [63.117382, 27.237303],
          [63.117167, 27.237138],
          [63.117171, 27.237052],
          [63.117071, 27.236935],
          [63.117138, 27.236827],
          [63.117162, 27.23665],
          [63.117086, 27.236465],
          [63.117115, 27.236266],
          [63.117074, 27.236191],
          [63.116934, 27.236155],
          [63.116803, 27.236212],
          [63.116741, 27.236082],
          [63.116641, 27.236387],
          [63.116407, 27.236496],
          [63.116092, 27.23658],
          [63.115977, 27.236539],
          [63.115713, 27.2366],
          [63.115406, 27.236565],
          [63.115181, 27.236682],
          [63.115116, 27.236647],
          [63.114735, 27.236766],
          [63.114507, 27.236674],
          [63.114346, 27.236715],
          [63.114123, 27.236661],
          [63.113776, 27.236417],
          [63.113584, 27.236503],
          [63.113513, 27.236424],
          [63.113381, 27.236463],
          [63.113314, 27.236603],
          [63.113102, 27.236362],
          [63.112965, 27.23629],
          [63.112755, 27.236316],
          [63.11261, 27.236229],
          [63.112554, 27.236291],
          [63.112508, 27.236497],
          [63.112305, 27.236639],
          [63.112284, 27.236735],
          [63.112178, 27.236759],
          [63.112114, 27.236908],
          [63.111993, 27.236947],
          [63.111961, 27.236998],
          [63.111993, 27.237052],
          [63.111821, 27.237216],
          [63.111661, 27.23719],
          [63.11144, 27.23723],
          [63.111237, 27.237346],
          [63.110924, 27.237617],
          [63.110831, 27.23786],
          [63.11067, 27.237936],
          [63.110461, 27.237949],
          [63.110439, 27.238055],
          [63.110271, 27.238189],
          [63.110159, 27.238192],
          [63.109401, 27.238521],
          [63.109031, 27.238502],
          [63.108856, 27.238596],
          [63.108655, 27.238566],
          [63.108428, 27.238359],
          [63.108243, 27.238364],
          [63.108109, 27.238307],
          [63.107983, 27.238307],
          [63.107749, 27.238403],
          [63.107458, 27.238374],
          [63.107394, 27.23846],
          [63.107005, 27.238638],
          [63.106683, 27.238726],
          [63.106309, 27.23866],
          [63.106072, 27.238849],
          [63.105852, 27.238473],
          [63.105458, 27.238115],
          [63.105074, 27.237679],
          [63.104615, 27.237556],
          [63.104184, 27.237294],
          [63.104058, 27.237131],
          [63.103526, 27.237048],
          [63.103235, 27.236898],
          [63.103154, 27.236797],
          [63.102882, 27.23672],
          [63.102739, 27.236732],
          [63.102621, 27.23666],
          [63.101614, 27.236647],
          [63.101363, 27.236795],
          [63.101211, 27.236831],
          [63.10088, 27.237089],
          [63.10081, 27.237078],
          [63.100623, 27.236895],
          [63.100319, 27.23684],
          [63.099875, 27.236857],
          [63.099636, 27.236977],
          [63.099387, 27.236994],
          [63.099246, 27.236971],
          [63.09907, 27.236862],
          [63.098781, 27.236916],
          [63.098548, 27.237165],
          [63.09842, 27.237218],
          [63.098321, 27.23712],
          [63.098238, 27.237133],
          [63.098016, 27.237021],
          [63.097767, 27.237054],
          [63.097666, 27.236978],
          [63.097684, 27.236914],
          [63.097614, 27.236874],
          [63.097597, 27.236789],
          [63.097455, 27.236727],
          [63.097276, 27.236759],
          [63.097098, 27.23675],
          [63.097003, 27.236695],
          [63.096555, 27.236723],
          [63.096469, 27.236679],
          [63.09624, 27.236702],
          [63.095534, 27.23691],
          [63.095348, 27.237168],
          [63.095152, 27.237207],
          [63.095076, 27.237183],
          [63.095034, 27.237242],
          [63.094875, 27.237264],
          [63.094534, 27.237077],
          [63.094321, 27.237061],
          [63.093984, 27.23674],
          [63.093532, 27.236812],
          [63.093351, 27.236683],
          [63.093206, 27.236654],
          [63.092653, 27.236667],
          [63.09255, 27.236716],
          [63.092301, 27.236647],
          [63.092088, 27.236326],
          [63.092007, 27.236269],
          [63.091761, 27.236228],
          [63.091719, 27.236148],
          [63.091523, 27.23605],
          [63.091501, 27.23599],
          [63.091419, 27.235978],
          [63.091026, 27.235623],
          [63.090611, 27.23544],
          [63.090281, 27.235416],
          [63.090256, 27.235481],
          [63.090126, 27.235469],
          [63.090031, 27.235522],
          [63.089586, 27.235444],
          [63.08941, 27.235666],
          [63.089393, 27.23593],
          [63.089428, 27.23631],
          [63.089533, 27.236604],
          [63.089432, 27.236767],
          [63.089557, 27.23706],
          [63.089519, 27.237147],
          [63.089272, 27.237208],
          [63.089187, 27.237481],
          [63.089082, 27.23754],
          [63.089066, 27.237686],
          [63.08887, 27.237845],
          [63.088955, 27.238082],
          [63.088849, 27.238122],
          [63.08886, 27.238181],
          [63.088688, 27.238405],
          [63.088603, 27.238618],
          [63.088682, 27.238984],
          [63.088766, 27.239126],
          [63.088655, 27.239313],
          [63.088496, 27.239379],
          [63.088363, 27.23937],
          [63.088157, 27.239261],
          [63.088085, 27.239269],
          [63.087892, 27.23911],
          [63.087565, 27.239082],
          [63.087351, 27.239152],
          [63.087299, 27.239108],
          [63.086955, 27.23915],
          [63.086855, 27.239275],
          [63.08691, 27.239527],
          [63.086988, 27.23963],
          [63.086962, 27.239736],
          [63.087015, 27.23977],
          [63.087064, 27.239939],
          [63.087186, 27.240042],
          [63.087408, 27.240379],
          [63.087581, 27.24046],
          [63.087724, 27.240781],
          [63.087775, 27.240809],
          [63.087656, 27.241144],
          [63.087407, 27.241386],
          [63.087552, 27.241523],
          [63.087635, 27.241735],
          [63.087779, 27.241745],
          [63.087764, 27.241805],
          [63.087548, 27.241898],
          [63.087382, 27.242055],
          [63.08738, 27.242242],
          [63.087447, 27.242363],
          [63.087456, 27.242504],
          [63.087252, 27.242617],
          [63.087213, 27.242745],
          [63.087246, 27.242894],
          [63.087102, 27.242992],
          [63.087234, 27.243538],
          [63.087114, 27.243777],
          [63.086922, 27.243999],
          [63.086889, 27.244152],
          [63.086944, 27.244252],
          [63.086937, 27.244422],
          [63.086857, 27.244701],
          [63.086594, 27.244921],
          [63.08613, 27.244989],
          [63.085884, 27.244929],
          [63.08542, 27.244715],
          [63.085178, 27.244759],
          [63.085009, 27.24471],
          [63.0847, 27.24444],
          [63.084472, 27.244348],
          [63.084236, 27.244061],
          [63.084069, 27.244002],
          [63.083555, 27.244007],
          [63.083402, 27.243884],
          [63.083271, 27.243885],
          [63.083128, 27.243728],
          [63.082976, 27.243746],
          [63.082237, 27.24349],
          [63.082184, 27.24344],
          [63.081667, 27.243494],
          [63.08139, 27.243472],
          [63.08121, 27.243364],
          [63.081055, 27.243369],
          [63.080961, 27.243308],
          [63.080791, 27.243284],
          [63.080587, 27.243425],
          [63.080568, 27.243531],
          [63.079954, 27.243657],
          [63.079384, 27.243448],
          [63.079179, 27.243237],
          [63.079041, 27.243268],
          [63.07897, 27.243205],
          [63.078464, 27.243168],
          [63.078326, 27.243049],
          [63.07817, 27.242717],
          [63.0779, 27.241808],
          [63.077733, 27.241479],
          [63.077713, 27.241232],
          [63.077778, 27.241149],
          [63.07768, 27.241054],
          [63.076136, 27.240784],
          [63.075852, 27.240497],
          [63.075287, 27.240233],
          [63.074889, 27.240251],
          [63.074661, 27.240146],
          [63.074313, 27.240109],
          [63.07355, 27.240163],
          [63.073311, 27.239981],
          [63.073062, 27.239881],
          [63.072737, 27.239665],
          [63.072742, 27.239581],
          [63.072285, 27.239334],
          [63.072017, 27.239063],
          [63.071645, 27.238964],
          [63.071612, 27.238844],
          [63.07133, 27.238943],
          [63.071238, 27.238932],
          [63.071044, 27.239053],
          [63.070604, 27.238828],
          [63.07025, 27.238783],
          [63.069977, 27.23884],
          [63.069529, 27.239184],
          [63.06942, 27.239396],
          [63.069341, 27.239485],
          [63.069278, 27.239484],
          [63.068704, 27.239139],
          [63.068484, 27.2389],
          [63.068293, 27.238791],
          [63.068131, 27.238439],
          [63.067476, 27.237973],
          [63.067342, 27.23778],
          [63.06714, 27.237645],
          [63.067055, 27.237614],
          [63.066868, 27.237689],
          [63.06677, 27.237838],
          [63.066559, 27.238009],
          [63.066389, 27.237957],
          [63.066244, 27.238046],
          [63.065789, 27.23782],
          [63.065589, 27.237783],
          [63.06533, 27.237805],
          [63.065016, 27.237686],
          [63.064718, 27.237744],
          [63.064541, 27.237709],
          [63.064515, 27.237647],
          [63.064266, 27.237652],
          [63.064143, 27.237501],
          [63.063593, 27.237374],
          [63.063524, 27.237339],
          [63.063437, 27.237182],
          [63.063335, 27.237176],
          [63.062851, 27.236909],
          [63.062277, 27.236717],
          [63.062231, 27.236561],
          [63.062258, 27.236339],
          [63.062094, 27.2362],
          [63.061663, 27.236075],
          [63.06157, 27.235788],
          [63.061399, 27.235774],
          [63.060971, 27.235613],
          [63.060637, 27.23538],
          [63.060341, 27.235301],
          [63.059969, 27.234822],
          [63.059873, 27.234764],
          [63.05971, 27.234768],
          [63.05949, 27.234709],
          [63.059322, 27.234815],
          [63.05907, 27.234864],
          [63.058819, 27.235253],
          [63.058284, 27.23558],
          [63.057699, 27.235552],
          [63.057436, 27.235457],
          [63.057558, 27.235255],
          [63.057233, 27.234998],
          [63.057095, 27.234815],
          [63.056475, 27.23454],
          [63.056021, 27.234397],
          [63.05581, 27.234238],
          [63.055345, 27.234043],
          [63.055242, 27.23393],
          [63.054946, 27.234061],
          [63.054541, 27.233889],
          [63.053949, 27.234012],
          [63.053396, 27.233917],
          [63.05328, 27.233857],
          [63.053221, 27.23377],
          [63.052835, 27.233626],
          [63.052587, 27.233649],
          [63.052538, 27.233605],
          [63.05203, 27.233676],
          [63.051823, 27.233777],
          [63.051323, 27.233776],
          [63.05125, 27.23372],
          [63.051088, 27.233714],
          [63.051031, 27.233757],
          [63.050937, 27.233721],
          [63.050765, 27.233736],
          [63.050498, 27.233909],
          [63.049922, 27.233841],
          [63.049818, 27.233858],
          [63.049613, 27.234009],
          [63.04919, 27.233959],
          [63.048809, 27.234093],
          [63.048646, 27.234236],
          [63.048258, 27.234417],
          [63.048109, 27.234446],
          [63.047974, 27.234396],
          [63.047638, 27.234456],
          [63.047315, 27.234433],
          [63.047002, 27.234482],
          [63.046871, 27.234841],
          [63.046712, 27.235028],
          [63.046383, 27.235282],
          [63.046269, 27.23547],
          [63.046059, 27.235632],
          [63.046005, 27.235747],
          [63.04578, 27.235845],
          [63.045817, 27.235876],
          [63.045773, 27.235923],
          [63.045832, 27.236267],
          [63.045784, 27.236359],
          [63.04554, 27.236429],
          [63.044822, 27.236347],
          [63.044657, 27.236373],
          [63.044349, 27.236519],
          [63.043581, 27.236275],
          [63.043247, 27.23636],
          [63.042996, 27.236355],
          [63.042886, 27.236395],
          [63.042686, 27.236641],
          [63.042484, 27.236671],
          [63.042323, 27.236636],
          [63.042067, 27.236721],
          [63.041968, 27.236788],
          [63.042061, 27.236843],
          [63.041993, 27.236992],
          [63.041594, 27.237159],
          [63.041424, 27.23712],
          [63.040552, 27.23729],
          [63.040146, 27.236931],
          [63.040059, 27.236774],
          [63.039643, 27.236617],
          [63.039585, 27.236486],
          [63.039104, 27.236249],
          [63.038871, 27.235895],
          [63.038818, 27.235651],
          [63.038891, 27.235491],
          [63.038348, 27.23541],
          [63.03807, 27.234842],
          [63.037865, 27.234571],
          [63.037646, 27.234363],
          [63.037455, 27.234317],
          [63.037284, 27.234183],
          [63.037259, 27.234038],
          [63.03733, 27.233932],
          [63.03731, 27.233851],
          [63.037179, 27.233638],
          [63.03706, 27.233261],
          [63.03681, 27.233142],
          [63.03671, 27.232479],
          [63.036269, 27.23246],
          [63.036056, 27.232581],
          [63.035924, 27.232607],
          [63.035636, 27.232514],
          [63.035571, 27.232444],
          [63.035445, 27.23245],
          [63.035217, 27.232571],
          [63.034935, 27.232499],
          [63.034695, 27.232374],
          [63.034287, 27.2324],
          [63.033894, 27.23209],
          [63.033494, 27.232016],
          [63.033387, 27.231941],
          [63.033277, 27.23195],
          [63.033166, 27.231803],
          [63.033198, 27.231435],
          [63.033037, 27.231403],
          [63.032969, 27.231309],
          [63.032844, 27.231285],
          [63.032753, 27.2311],
          [63.032383, 27.230883],
          [63.03242, 27.230809],
          [63.032356, 27.230673],
          [63.03225, 27.230617],
          [63.032201, 27.230449],
          [63.032068, 27.230364],
          [63.032071, 27.230303],
          [63.031931, 27.230235],
          [63.031889, 27.230139],
          [63.031823, 27.230129],
          [63.031679, 27.22991],
          [63.031264, 27.22993],
          [63.031154, 27.229869],
          [63.030984, 27.229953],
          [63.030888, 27.23036],
          [63.030684, 27.230276],
          [63.03009, 27.23018],
          [63.029544, 27.230315],
          [63.029424, 27.230596],
          [63.029342, 27.230643],
          [63.02928, 27.230782],
          [63.028866, 27.230755],
          [63.028595, 27.230593],
          [63.028485, 27.230465],
          [63.02816, 27.230449],
          [63.028009, 27.230533],
          [63.027618, 27.230536],
          [63.027533, 27.230524],
          [63.02733, 27.230307],
          [63.026749, 27.230328],
          [63.026576, 27.230184],
          [63.02653, 27.230088],
          [63.026439, 27.230084],
          [63.026353, 27.230021],
          [63.026276, 27.229875],
          [63.02603, 27.229846],
          [63.025931, 27.22978],
          [63.02585, 27.229828],
          [63.02566, 27.229814],
          [63.025073, 27.229646],
          [63.024876, 27.229526],
          [63.024693, 27.229663],
          [63.0243, 27.229778],
          [63.024222, 27.229854],
          [63.024105, 27.229833],
          [63.023788, 27.22991],
          [63.023417, 27.22978],
          [63.023307, 27.229792],
          [63.023115, 27.229674],
          [63.02297, 27.229709],
          [63.022823, 27.229687],
          [63.022626, 27.229598],
          [63.022525, 27.229494],
          [63.02253, 27.229436],
          [63.022277, 27.229155],
          [63.022182, 27.228923],
          [63.021986, 27.228809],
          [63.021918, 27.228667],
          [63.021947, 27.228527],
          [63.021777, 27.228218],
          [63.021563, 27.228075],
          [63.021602, 27.227979],
          [63.021551, 27.227909],
          [63.021564, 27.227567],
          [63.021376, 27.227547],
          [63.021316, 27.227457],
          [63.020904, 27.227147],
          [63.020798, 27.226809],
          [63.020677, 27.226667],
          [63.020619, 27.226314],
          [63.020238, 27.226188],
          [63.02022, 27.226087],
          [63.019823, 27.225688],
          [63.019731, 27.225481],
          [63.019193, 27.225161],
          [63.019051, 27.225192],
          [63.018879, 27.22513],
          [63.018119, 27.224726],
          [63.017745, 27.224587],
          [63.017621, 27.224462],
          [63.017396, 27.22435],
          [63.016751, 27.22446],
          [63.016631, 27.224531],
          [63.01661, 27.224628],
          [63.015993, 27.224488],
          [63.015652, 27.224469],
          [63.015089, 27.224274],
          [63.015005, 27.224278],
          [63.014683, 27.224138],
          [63.014441, 27.223899],
          [63.014343, 27.223896],
          [63.014301, 27.223831],
          [63.014199, 27.223826],
          [63.014068, 27.223718],
          [63.013167, 27.223456],
          [63.012857, 27.223422],
          [63.012657, 27.223143],
          [63.012434, 27.223073],
          [63.012169, 27.223147],
          [63.012122, 27.223229],
          [63.011551, 27.223315],
          [63.011249, 27.223438],
          [63.011029, 27.223906],
          [63.010912, 27.223932],
          [63.010685, 27.223933],
          [63.010063, 27.223803],
          [63.009579, 27.223806],
          [63.009463, 27.223743],
          [63.009324, 27.223754],
          [63.009249, 27.223709],
          [63.008898, 27.223771],
          [63.00846, 27.223704],
          [63.008328, 27.223619],
          [63.008187, 27.223656],
          [63.00811, 27.223992],
          [63.007917, 27.223953],
          [63.007729, 27.223825],
          [63.007646, 27.223682],
          [63.007565, 27.223657],
          [63.007147, 27.223612],
          [63.006935, 27.223631],
          [63.006857, 27.223573],
          [63.006443, 27.223451],
          [63.006259, 27.223462],
          [63.006269, 27.223364],
          [63.006167, 27.223086],
          [63.006192, 27.22292],
          [63.00601, 27.22241],
          [63.005939, 27.222366],
          [63.00593, 27.222277],
          [63.005665, 27.221828],
          [63.005431, 27.221639],
          [63.005258, 27.221334],
          [63.005225, 27.221153],
          [63.004787, 27.221343],
          [63.004714, 27.221332],
          [63.004209, 27.221237],
          [63.004158, 27.22118],
          [63.004081, 27.221186],
          [63.003992, 27.221114],
          [63.00399, 27.22106],
          [63.003821, 27.221011],
          [63.00356, 27.221011],
          [63.003417, 27.22096],
          [63.003407, 27.220908],
          [63.002915, 27.220756],
          [63.002788, 27.22043],
          [63.002308, 27.220323],
          [63.00227, 27.220216],
          [63.002021, 27.220128],
          [63.001665, 27.219867],
          [63.001665, 27.219823],
          [63.001474, 27.219759],
          [63.001248, 27.219615],
          [63.001243, 27.219562],
          [63.001174, 27.21957],
          [63.001084, 27.219311],
          [63.000956, 27.219323],
          [63.000729, 27.219187],
          [63.000397, 27.218798],
          [63.000235, 27.218851],
          [63.000054, 27.219039],
          [62.999901, 27.219069],
          [62.999884, 27.219181],
          [62.999566, 27.21924],
          [62.999426, 27.219337],
          [62.999254, 27.219367],
          [62.998639, 27.219204],
          [62.998403, 27.219051],
          [62.997812, 27.21898],
          [62.997484, 27.218851],
          [62.997346, 27.218907],
          [62.996942, 27.218904],
          [62.996811, 27.218602],
          [62.996591, 27.218323],
          [62.996568, 27.21812],
          [62.996374, 27.218097],
          [62.996135, 27.217979],
          [62.996054, 27.21797],
          [62.995985, 27.21803],
          [62.995922, 27.217965],
          [62.99577, 27.217964],
          [62.99535, 27.217766],
          [62.995231, 27.217558],
          [62.995062, 27.217381],
          [62.994747, 27.217203],
          [62.994558, 27.217158],
          [62.994389, 27.216981],
          [62.994306, 27.216727],
          [62.994358, 27.216662],
          [62.994244, 27.216547],
          [62.993998, 27.216441],
          [62.993566, 27.216057],
          [62.993547, 27.215944],
          [62.993357, 27.215755],
          [62.993183, 27.215804],
          [62.993176, 27.215918],
          [62.993043, 27.216006],
          [62.992836, 27.216035],
          [62.992725, 27.216207],
          [62.992491, 27.216286],
          [62.992372, 27.216476],
          [62.992144, 27.216598],
          [62.991533, 27.216579],
          [62.991083, 27.216667],
          [62.990994, 27.2167],
          [62.99079, 27.21697],
          [62.990672, 27.217055],
          [62.990473, 27.217097],
          [62.990436, 27.217181],
          [62.990289, 27.217237],
          [62.989918, 27.217229],
          [62.989732, 27.217325],
          [62.989564, 27.217356],
          [62.989334, 27.217334],
          [62.989269, 27.217179],
          [62.989153, 27.217173],
          [62.989101, 27.217013],
          [62.988969, 27.216946],
          [62.988866, 27.216965],
          [62.988817, 27.216892],
          [62.988593, 27.216886],
          [62.988502, 27.216793],
          [62.988371, 27.216779],
          [62.988402, 27.216745],
          [62.988371, 27.216717],
          [62.988245, 27.216713],
          [62.988052, 27.2166],
          [62.987745, 27.216618],
          [62.987644, 27.216569],
          [62.987392, 27.216601],
          [62.987359, 27.216516],
          [62.987177, 27.216362],
          [62.986753, 27.216237],
          [62.986505, 27.216413],
          [62.986405, 27.216399],
          [62.986243, 27.216487],
          [62.986042, 27.216523],
          [62.985803, 27.216863],
          [62.985729, 27.217213],
          [62.985537, 27.217244],
          [62.985359, 27.217459],
          [62.984984, 27.217595],
          [62.984812, 27.217748],
          [62.984647, 27.218146],
          [62.984472, 27.218202],
          [62.984216, 27.21813],
          [62.984178, 27.218167],
          [62.984074, 27.218149],
          [62.983885, 27.218031],
          [62.983714, 27.217995],
          [62.983601, 27.218023],
          [62.983543, 27.21799],
          [62.983543, 27.217917],
          [62.983398, 27.217912],
          [62.983309, 27.217857],
          [62.982942, 27.217921],
          [62.982738, 27.217901],
          [62.982388, 27.217778],
          [62.982236, 27.217787],
          [62.981857, 27.217554],
          [62.981516, 27.217541],
          [62.981126, 27.217389],
          [62.980335, 27.217219],
          [62.980136, 27.217231],
          [62.980125, 27.21713],
          [62.979679, 27.216957],
          [62.979336, 27.216744],
          [62.979265, 27.216764],
          [62.978809, 27.216578],
          [62.978753, 27.216446],
          [62.978405, 27.216242],
          [62.978237, 27.21625],
          [62.977461, 27.21598],
          [62.977371, 27.215903],
          [62.977257, 27.215886],
          [62.976965, 27.216008],
          [62.97665, 27.216072],
          [62.976143, 27.216267],
          [62.975943, 27.216424],
          [62.975646, 27.216388],
          [62.975177, 27.216453],
          [62.974938, 27.21644],
          [62.974762, 27.216491],
          [62.974721, 27.216548],
          [62.974208, 27.216409],
          [62.974123, 27.216428],
          [62.973895, 27.216372],
          [62.973794, 27.21644],
          [62.973577, 27.216368],
          [62.973348, 27.216375],
          [62.973208, 27.216249],
          [62.973005, 27.216235],
          [62.972718, 27.215959],
          [62.972723, 27.21585],
          [62.972463, 27.215556],
          [62.97229, 27.215513],
          [62.972059, 27.215295],
          [62.971777, 27.215141],
          [62.971623, 27.214959],
          [62.971312, 27.214875],
          [62.971201, 27.214795],
          [62.971165, 27.214703],
          [62.971056, 27.214696],
          [62.970995, 27.214584],
          [62.970859, 27.214479],
          [62.970847, 27.214407],
          [62.970531, 27.214273],
          [62.970508, 27.214113],
          [62.970307, 27.214072],
          [62.970161, 27.21412],
          [62.970016, 27.213974],
          [62.96994, 27.213973],
          [62.9699, 27.213876],
          [62.96982, 27.213869],
          [62.969675, 27.213715],
          [62.969676, 27.213666],
          [62.96958, 27.213584],
          [62.969596, 27.213508],
          [62.969453, 27.213479],
          [62.96948, 27.213151],
          [62.969566, 27.213127],
          [62.969629, 27.212901],
          [62.969679, 27.212882],
          [62.969604, 27.212747],
          [62.969386, 27.212561],
          [62.969398, 27.212427],
          [62.969338, 27.212451],
          [62.969384, 27.212229],
          [62.969253, 27.212207],
          [62.969279, 27.212154],
          [62.969436, 27.212098],
          [62.969419, 27.211986],
          [62.969323, 27.211946],
          [62.969325, 27.211796],
          [62.969196, 27.211672],
          [62.968883, 27.21154],
          [62.968827, 27.211438],
          [62.96883, 27.211203],
          [62.968586, 27.210782],
          [62.968558, 27.21054],
          [62.968312, 27.210024],
          [62.968271, 27.2098],
          [62.968161, 27.209791],
          [62.968177, 27.209587],
          [62.968113, 27.209409],
          [62.968112, 27.209182],
          [62.967927, 27.208874],
          [62.967734, 27.208865],
          [62.967509, 27.20865],
          [62.967243, 27.208644],
          [62.967061, 27.208717],
          [62.966917, 27.208716],
          [62.966592, 27.208854],
          [62.966432, 27.209036],
          [62.966242, 27.209094],
          [62.966037, 27.209284],
          [62.965743, 27.209292],
          [62.965665, 27.20923],
          [62.96567, 27.209173],
          [62.965558, 27.209144],
          [62.965427, 27.209176],
          [62.964667, 27.209023],
          [62.964461, 27.208844],
          [62.964158, 27.208731],
          [62.963621, 27.208752],
          [62.963431, 27.208692],
          [62.96335, 27.208755],
          [62.963353, 27.208823],
          [62.963066, 27.208698],
          [62.962746, 27.208644],
          [62.962229, 27.208435],
          [62.96206, 27.208279],
          [62.961602, 27.208064],
          [62.961205, 27.207908],
          [62.960827, 27.207849],
          [62.960773, 27.207756],
          [62.960455, 27.207591],
          [62.960351, 27.207478],
          [62.960276, 27.207202],
          [62.960162, 27.207004],
          [62.960103, 27.206673],
          [62.959789, 27.206158],
          [62.959685, 27.206214],
          [62.959534, 27.206154],
          [62.959018, 27.206133],
          [62.958736, 27.205869],
          [62.958679, 27.205883],
          [62.95864, 27.20583],
          [62.958565, 27.205867],
          [62.958129, 27.205776],
          [62.958045, 27.205956],
          [62.957838, 27.205979],
          [62.957759, 27.205909],
          [62.957681, 27.205924],
          [62.957536, 27.205867],
          [62.957256, 27.20563],
          [62.957057, 27.205601],
          [62.956904, 27.205669],
          [62.956709, 27.205633],
          [62.956553, 27.205735],
          [62.956473, 27.205847],
          [62.95639, 27.205891],
          [62.956282, 27.205878],
          [62.956016, 27.206025],
          [62.955806, 27.20596],
          [62.955608, 27.205953],
          [62.955394, 27.206051],
          [62.955068, 27.20608],
          [62.954749, 27.206201],
          [62.954511, 27.206211],
          [62.954405, 27.20615],
          [62.954409, 27.206202],
          [62.954316, 27.206229],
          [62.95361, 27.206146],
          [62.953527, 27.20615],
          [62.953535, 27.206195],
          [62.953454, 27.206238],
          [62.953303, 27.206231],
          [62.952845, 27.205824],
          [62.952814, 27.205685],
          [62.952665, 27.205645],
          [62.952693, 27.205599],
          [62.952549, 27.205489],
          [62.952089, 27.205452],
          [62.951841, 27.205388],
          [62.951133, 27.205094],
          [62.950934, 27.205053],
          [62.950781, 27.204921],
          [62.950652, 27.20497],
          [62.950602, 27.20487],
          [62.950544, 27.204869],
          [62.950483, 27.204792],
          [62.950398, 27.204788],
          [62.950247, 27.204664],
          [62.949844, 27.204502],
          [62.949793, 27.204303],
          [62.949705, 27.204211],
          [62.949365, 27.204108],
          [62.949321, 27.204034],
          [62.949147, 27.203976],
          [62.949101, 27.203872],
          [62.948599, 27.203585],
          [62.948532, 27.203386],
          [62.948424, 27.20333],
          [62.948359, 27.203205],
          [62.948198, 27.203078],
          [62.948117, 27.202919],
          [62.947844, 27.202872],
          [62.947737, 27.202616],
          [62.947566, 27.202465],
          [62.947332, 27.202137],
          [62.947272, 27.202116],
          [62.947152, 27.201895],
          [62.946869, 27.201977],
          [62.946804, 27.202064],
          [62.946678, 27.202096],
          [62.946468, 27.202384],
          [62.946472, 27.202435],
          [62.946334, 27.202462],
          [62.946234, 27.202555],
          [62.946159, 27.202438],
          [62.94582, 27.202584],
          [62.945746, 27.202582],
          [62.945654, 27.202506],
          [62.945538, 27.20271],
          [62.945211, 27.202848],
          [62.944985, 27.203103],
          [62.944987, 27.203219],
          [62.94488, 27.203327],
          [62.944692, 27.203255],
          [62.944428, 27.203255],
          [62.944211, 27.203077],
          [62.9439, 27.203114],
          [62.943855, 27.203173],
          [62.943446, 27.20313],
          [62.943377, 27.203147],
          [62.943315, 27.203281],
          [62.942916, 27.203351],
          [62.942543, 27.203527],
          [62.94243, 27.203528],
          [62.942389, 27.203435],
          [62.942178, 27.203343],
          [62.941891, 27.203369],
          [62.941842, 27.203328],
          [62.941633, 27.203364],
          [62.941609, 27.203525],
          [62.941459, 27.203574],
          [62.941208, 27.203759],
          [62.940117, 27.203612],
          [62.939863, 27.203658],
          [62.939641, 27.203541],
          [62.939322, 27.203521],
          [62.939102, 27.203402],
          [62.938612, 27.203336],
          [62.938424, 27.203254],
          [62.938312, 27.202935],
          [62.938215, 27.202794],
          [62.938048, 27.202742],
          [62.93804, 27.202678],
          [62.937988, 27.202678],
          [62.937837, 27.202445],
          [62.937731, 27.202387],
          [62.937671, 27.202249],
          [62.937588, 27.202188],
          [62.937567, 27.202035],
          [62.937482, 27.202001],
          [62.937427, 27.201743],
          [62.937346, 27.2017],
          [62.937342, 27.201639],
          [62.937255, 27.201615],
          [62.937223, 27.201484],
          [62.937162, 27.201501],
          [62.936974, 27.20144],
          [62.936677, 27.201511],
          [62.936652, 27.201439],
          [62.936478, 27.201448],
          [62.936378, 27.201522],
          [62.936002, 27.201557],
          [62.935996, 27.201629],
          [62.935944, 27.201652],
          [62.935904, 27.201457],
          [62.935479, 27.201529],
          [62.935344, 27.201503],
          [62.93534, 27.20156],
          [62.93512, 27.201638],
          [62.93502, 27.201741],
          [62.934589, 27.201652],
          [62.93445, 27.201576],
          [62.934435, 27.201524],
          [62.934335, 27.201531],
          [62.934216, 27.201461],
          [62.933724, 27.201528],
          [62.933276, 27.2017],
          [62.933153, 27.201829],
          [62.932991, 27.201863],
          [62.932965, 27.202329],
          [62.932862, 27.202496],
          [62.932492, 27.20275],
          [62.932092, 27.202669],
          [62.93178, 27.202742],
          [62.931702, 27.202699],
          [62.931477, 27.20271],
          [62.930939, 27.203009],
          [62.930579, 27.202995],
          [62.930421, 27.203074],
          [62.930403, 27.203162],
          [62.930218, 27.203112],
          [62.930093, 27.203136],
          [62.929983, 27.202965],
          [62.929878, 27.202943],
          [62.929777, 27.202797],
          [62.929781, 27.202704],
          [62.929636, 27.202658],
          [62.929272, 27.202736],
          [62.929211, 27.202808],
          [62.929257, 27.203027],
          [62.929184, 27.2032],
          [62.929073, 27.203273],
          [62.929069, 27.203353],
          [62.928871, 27.203358],
          [62.92875, 27.203579],
          [62.928645, 27.203623],
          [62.928548, 27.20375],
          [62.928405, 27.203767],
          [62.928414, 27.203804],
          [62.928292, 27.203904],
          [62.928329, 27.203983],
          [62.928296, 27.204177],
          [62.928234, 27.204181],
          [62.927964, 27.204429],
          [62.927732, 27.204488],
          [62.927761, 27.204532],
          [62.927599, 27.204597],
          [62.927564, 27.204679],
          [62.927588, 27.204918],
          [62.927536, 27.204982],
          [62.927641, 27.20522],
          [62.92724, 27.205254],
          [62.927078, 27.205317],
          [62.926811, 27.205253],
          [62.926708, 27.205122],
          [62.926622, 27.205178],
          [62.926567, 27.205122],
          [62.926347, 27.205146],
          [62.926229, 27.205326],
          [62.926011, 27.205496],
          [62.925796, 27.205507],
          [62.925499, 27.205601],
          [62.925103, 27.205511],
          [62.924732, 27.205709],
          [62.92447, 27.206029],
          [62.924275, 27.206088],
          [62.924147, 27.206046],
          [62.923689, 27.206222],
          [62.923584, 27.20623],
          [62.923105, 27.206095],
          [62.922814, 27.205963],
          [62.922376, 27.206004],
          [62.922309, 27.205964],
          [62.922169, 27.206005],
          [62.921933, 27.205969],
          [62.921697, 27.205742],
          [62.921524, 27.205676],
          [62.921308, 27.205705],
          [62.92122, 27.205893],
          [62.921128, 27.205967],
          [62.920915, 27.205994],
          [62.920764, 27.206073],
          [62.920477, 27.206046],
          [62.920451, 27.206174],
          [62.920319, 27.206169],
          [62.920308, 27.206232],
          [62.919846, 27.206549],
          [62.919711, 27.206762],
          [62.919737, 27.20689],
          [62.919166, 27.207124],
          [62.919103, 27.207329],
          [62.918882, 27.207486],
          [62.918703, 27.207538],
          [62.918616, 27.20763],
          [62.918608, 27.207758],
          [62.918409, 27.207846],
          [62.918234, 27.208053],
          [62.918085, 27.208063],
          [62.918004, 27.208137],
          [62.91766, 27.208202],
          [62.917594, 27.208112],
          [62.917494, 27.208141],
          [62.917413, 27.20807],
          [62.917242, 27.208046],
          [62.916989, 27.208092],
          [62.916926, 27.207993],
          [62.916864, 27.208017],
          [62.916668, 27.207976],
          [62.916102, 27.208277],
          [62.915582, 27.208787],
          [62.91528, 27.20872],
          [62.915179, 27.208735],
          [62.915013, 27.20869],
          [62.914931, 27.20861],
          [62.914801, 27.208625],
          [62.914662, 27.208569],
          [62.914293, 27.208603],
          [62.914237, 27.208565],
          [62.913883, 27.208576],
          [62.913846, 27.208768],
          [62.913651, 27.20883],
          [62.913582, 27.208897],
          [62.913583, 27.208969],
          [62.913286, 27.209094],
          [62.913105, 27.209093],
          [62.912973, 27.209174],
          [62.912965, 27.209238],
          [62.9128, 27.209387],
          [62.912607, 27.2095],
          [62.912452, 27.209721],
          [62.912198, 27.209841],
          [62.912101, 27.209937],
          [62.91207, 27.210045],
          [62.912152, 27.210145],
          [62.912141, 27.210241],
          [62.91227, 27.210384],
          [62.912214, 27.210518],
          [62.911833, 27.210729],
          [62.91186, 27.21095],
          [62.911733, 27.211316],
          [62.911791, 27.211545],
          [62.911665, 27.211654],
          [62.911633, 27.21192],
          [62.911377, 27.212116],
          [62.911299, 27.212279],
          [62.911491, 27.212702],
          [62.911746, 27.212928],
          [62.911563, 27.21318],
          [62.911556, 27.21342],
          [62.911205, 27.213601],
          [62.911111, 27.213774],
          [62.910629, 27.21412],
          [62.910663, 27.21442],
          [62.910769, 27.214498],
          [62.910971, 27.214478],
          [62.910989, 27.214543],
          [62.910818, 27.21468],
          [62.910685, 27.214726],
          [62.91062, 27.214833],
          [62.91075, 27.214915],
          [62.910619, 27.214928],
          [62.910492, 27.214998],
          [62.910293, 27.214984],
          [62.910074, 27.215249],
          [62.909838, 27.21532],
          [62.909626, 27.215536],
          [62.909517, 27.215518],
          [62.909425, 27.215121],
          [62.909333, 27.215096],
          [62.909265, 27.21499],
          [62.909115, 27.214934],
          [62.90864, 27.215048],
          [62.908524, 27.214992],
          [62.908313, 27.215116],
          [62.907952, 27.215211],
          [62.907697, 27.215199],
          [62.907637, 27.215136],
          [62.907423, 27.215149],
          [62.907096, 27.215324],
          [62.906539, 27.215293],
          [62.90649, 27.215209],
          [62.906031, 27.215325],
          [62.905822, 27.215298],
          [62.905596, 27.215413],
          [62.90555, 27.215491],
          [62.905522, 27.215454],
          [62.905462, 27.21554],
          [62.905105, 27.215656],
          [62.905056, 27.215581],
          [62.905056, 27.215345],
          [62.904924, 27.215211],
          [62.90466, 27.215074],
          [62.904252, 27.214946],
          [62.903911, 27.214948],
          [62.903643, 27.215039],
          [62.903347, 27.214858],
          [62.902813, 27.214792],
          [62.902733, 27.214821],
          [62.902514, 27.214641],
          [62.902061, 27.214554],
          [62.902005, 27.2145],
          [62.901797, 27.214538],
          [62.90169, 27.214604],
          [62.90155, 27.214565],
          [62.901299, 27.21461],
          [62.901217, 27.214519],
          [62.901142, 27.214169],
          [62.90094, 27.21404],
          [62.900949, 27.213959],
          [62.90102, 27.213894],
          [62.90101, 27.213813],
          [62.90095, 27.21381],
          [62.900805, 27.213677],
          [62.900726, 27.213428],
          [62.900492, 27.213428],
          [62.900014, 27.213269],
          [62.89984, 27.213276],
          [62.8997, 27.213486],
          [62.899569, 27.213466],
          [62.899469, 27.213397],
          [62.898933, 27.213349],
          [62.89854, 27.213263],
          [62.898497, 27.213215],
          [62.898044, 27.21314],
          [62.897834, 27.21317],
          [62.897817, 27.213314],
          [62.897723, 27.213402],
          [62.897638, 27.213299],
          [62.897295, 27.213268],
          [62.896931, 27.213335],
          [62.896751, 27.213294],
          [62.896752, 27.213349],
          [62.896659, 27.213379],
          [62.896593, 27.213333],
          [62.89638, 27.213363],
          [62.896076, 27.213306],
          [62.895958, 27.213254],
          [62.895967, 27.213204],
          [62.895397, 27.213141],
          [62.895249, 27.213214],
          [62.894996, 27.213175],
          [62.89468, 27.213201],
          [62.894402, 27.213153],
          [62.894363, 27.213007],
          [62.894227, 27.212824],
          [62.893863, 27.212693],
          [62.89376, 27.212779],
          [62.893745, 27.212887],
          [62.893665, 27.212892],
          [62.893556, 27.212971],
          [62.893283, 27.213011],
          [62.893227, 27.213117],
          [62.893148, 27.213125],
          [62.893057, 27.213352],
          [62.892324, 27.213176],
          [62.892261, 27.213186],
          [62.89225, 27.213278],
          [62.891922, 27.213189],
          [62.891737, 27.213202],
          [62.891437, 27.213112],
          [62.890948, 27.213105],
          [62.890759, 27.213296],
          [62.890746, 27.213339],
          [62.890789, 27.213354],
          [62.890389, 27.213507],
          [62.890394, 27.213655],
          [62.890342, 27.213734],
          [62.890213, 27.213795],
          [62.890036, 27.214038],
          [62.890005, 27.214155],
          [62.889873, 27.214133],
          [62.889773, 27.214291],
          [62.889536, 27.214383],
          [62.889416, 27.214567],
          [62.88928, 27.214675],
          [62.889197, 27.214967],
          [62.889255, 27.215099],
          [62.889182, 27.21524],
          [62.889196, 27.215318],
          [62.888822, 27.215373],
          [62.888663, 27.215464],
          [62.888522, 27.21568],
          [62.888552, 27.215803],
          [62.888471, 27.216018],
          [62.888504, 27.216155],
          [62.888397, 27.216232],
          [62.888314, 27.216427],
          [62.888192, 27.216432],
          [62.888068, 27.216558],
          [62.887924, 27.216622],
          [62.887526, 27.216708],
          [62.887314, 27.216669],
          [62.887142, 27.216575],
          [62.886578, 27.216475],
          [62.886276, 27.216848],
          [62.885989, 27.216949],
          [62.885909, 27.217027],
          [62.885892, 27.217228],
          [62.88574, 27.217182],
          [62.885461, 27.217177],
          [62.885402, 27.217095],
          [62.885328, 27.217083],
          [62.885311, 27.217014],
          [62.885194, 27.217008],
          [62.885207, 27.216963],
          [62.884884, 27.216896],
          [62.884443, 27.216879],
          [62.884115, 27.216931],
          [62.883939, 27.217017],
          [62.883666, 27.217432],
          [62.883435, 27.21757],
          [62.883016, 27.218023],
          [62.882811, 27.218074],
          [62.882599, 27.218203],
          [62.882545, 27.21839],
          [62.882354, 27.218497],
          [62.882367, 27.218607],
          [62.882315, 27.21867],
          [62.882081, 27.21873],
          [62.881924, 27.218849],
          [62.88187, 27.219037],
          [62.881799, 27.219112],
          [62.882055, 27.219229],
          [62.882126, 27.219355],
          [62.882198, 27.21974],
          [62.882085, 27.22008],
          [62.882021, 27.22014],
          [62.882063, 27.220162],
          [62.882041, 27.220277],
          [62.881946, 27.220316],
          [62.882099, 27.220502],
          [62.882311, 27.220601],
          [62.882466, 27.220765],
          [62.882472, 27.220851],
          [62.882606, 27.22096],
          [62.882516, 27.221056],
          [62.88253, 27.221198],
          [62.882542, 27.221289],
          [62.882618, 27.22129],
          [62.88261, 27.221342],
          [62.882678, 27.221376],
          [62.882657, 27.22142],
          [62.882293, 27.221535],
          [62.882211, 27.221501],
          [62.882051, 27.221563],
          [62.882014, 27.221532],
          [62.88143, 27.221576],
          [62.880972, 27.221662],
          [62.880771, 27.221631],
          [62.880706, 27.221698],
          [62.880621, 27.221682],
          [62.880546, 27.22172],
          [62.880475, 27.221913],
          [62.880261, 27.222099],
          [62.880128, 27.222284],
          [62.880174, 27.222537],
          [62.879757, 27.22265],
          [62.879713, 27.222878],
          [62.879652, 27.222888],
          [62.879566, 27.223041],
          [62.879422, 27.223103],
          [62.87944, 27.223255],
          [62.879329, 27.22351],
          [62.879368, 27.223538],
          [62.879358, 27.223895],
          [62.87912, 27.223894],
          [62.878789, 27.224096],
          [62.878469, 27.224203],
          [62.878325, 27.224148],
          [62.878253, 27.224067],
          [62.878168, 27.224085],
          [62.878102, 27.224039],
          [62.877686, 27.224473],
          [62.877635, 27.22464],
          [62.877663, 27.224947],
          [62.877516, 27.225184],
          [62.877638, 27.225457],
          [62.877749, 27.225505],
          [62.877792, 27.225595],
          [62.877756, 27.225822],
          [62.87763, 27.226035],
          [62.877692, 27.226128],
          [62.877642, 27.226142],
          [62.877641, 27.226231],
          [62.877487, 27.226318],
          [62.877297, 27.226292],
          [62.877124, 27.226377],
          [62.87707, 27.226523],
          [62.876971, 27.22661],
          [62.876997, 27.226706],
          [62.876801, 27.226771],
          [62.876734, 27.227004],
          [62.876609, 27.227072],
          [62.876582, 27.227141],
          [62.876474, 27.22714],
          [62.876438, 27.227046],
          [62.876115, 27.226966],
          [62.875914, 27.227033],
          [62.875657, 27.227225],
          [62.875728, 27.227485],
          [62.875447, 27.227581],
          [62.875338, 27.2277],
          [62.875343, 27.227818],
          [62.875024, 27.227919],
          [62.874942, 27.228035],
          [62.874639, 27.22821],
          [62.874354, 27.22823],
          [62.874285, 27.22811],
          [62.873776, 27.228441],
          [62.873709, 27.228629],
          [62.873439, 27.228732],
          [62.873169, 27.228774],
          [62.873027, 27.228859],
          [62.872937, 27.228824],
          [62.872808, 27.228669],
          [62.872716, 27.228475],
          [62.872753, 27.228272],
          [62.872632, 27.228074],
          [62.872484, 27.227951],
          [62.87238, 27.227753],
          [62.872217, 27.227695],
          [62.871941, 27.227399],
          [62.871808, 27.227146],
          [62.87183, 27.227084],
          [62.871743, 27.22677],
          [62.871675, 27.226749],
          [62.871449, 27.226886],
          [62.8713, 27.226673],
          [62.871105, 27.226635],
          [62.87108, 27.226511],
          [62.870977, 27.226543],
          [62.870868, 27.226489],
          [62.870763, 27.226511],
          [62.87066, 27.226434],
          [62.870382, 27.226472],
          [62.870259, 27.226387],
          [62.869947, 27.226414],
          [62.869835, 27.226386],
          [62.869651, 27.226243],
          [62.869458, 27.226261],
          [62.869386, 27.22617],
          [62.869337, 27.226185],
          [62.869204, 27.226068],
          [62.869073, 27.226035],
          [62.869017, 27.226089],
          [62.86862, 27.226191],
          [62.868438, 27.226145],
          [62.868014, 27.226144],
          [62.867984, 27.226086],
          [62.867843, 27.226076],
          [62.867718, 27.225984],
          [62.86766, 27.225995],
          [62.867642, 27.225894],
          [62.867592, 27.225884],
          [62.867526, 27.225751],
          [62.867475, 27.225766],
          [62.867433, 27.225678],
          [62.867089, 27.225516],
          [62.867052, 27.22556],
          [62.866992, 27.225505],
          [62.866889, 27.225517],
          [62.866817, 27.225333],
          [62.866752, 27.225326],
          [62.866767, 27.225266],
          [62.866646, 27.225235],
          [62.866578, 27.225089],
          [62.866342, 27.224961],
          [62.865635, 27.224934],
          [62.865584, 27.224805],
          [62.865731, 27.224646],
          [62.86555, 27.224604],
          [62.865575, 27.224542],
          [62.865528, 27.224511],
          [62.865574, 27.224361],
          [62.865725, 27.224163],
          [62.865802, 27.223875],
          [62.865816, 27.2235],
          [62.865878, 27.223472],
          [62.865749, 27.223273],
          [62.865422, 27.223113],
          [62.865243, 27.22297],
          [62.865017, 27.222924],
          [62.864869, 27.22269],
          [62.86485, 27.222619],
          [62.864896, 27.222559],
          [62.864794, 27.222459],
          [62.864649, 27.222448],
          [62.864615, 27.222361],
          [62.864474, 27.222396],
          [62.864563, 27.222264],
          [62.864519, 27.222163],
          [62.864546, 27.222113],
          [62.864383, 27.222014],
          [62.864372, 27.221833],
          [62.864106, 27.221395],
          [62.863998, 27.221399],
          [62.863852, 27.221292],
          [62.863004, 27.221133],
          [62.862994, 27.221047],
          [62.862533, 27.220977],
          [62.862535, 27.220897],
          [62.862485, 27.220873],
          [62.862436, 27.220732],
          [62.862259, 27.220708],
          [62.862045, 27.220742],
          [62.861966, 27.220728],
          [62.861911, 27.220662],
          [62.861793, 27.220674],
          [62.861781, 27.220615],
          [62.861533, 27.2206],
          [62.861452, 27.220479],
          [62.861355, 27.220425],
          [62.861331, 27.220311],
          [62.861103, 27.220284],
          [62.861087, 27.220204],
          [62.860846, 27.220233],
          [62.860623, 27.220146],
          [62.860445, 27.22013],
          [62.860307, 27.220168],
          [62.859976, 27.220039],
          [62.859748, 27.220006],
          [62.859797, 27.219894],
          [62.859756, 27.219753],
          [62.859788, 27.219566],
          [62.859858, 27.219528],
          [62.85973, 27.219495],
          [62.859141, 27.219422],
          [62.858944, 27.219346],
          [62.858667, 27.219308],
          [62.858423, 27.21937],
          [62.85803, 27.219389],
          [62.857817, 27.219491],
          [62.85776, 27.219595],
          [62.85752, 27.219603],
          [62.85742, 27.219648],
          [62.857156, 27.21961],
          [62.856903, 27.219643],
          [62.856947, 27.219596],
          [62.856476, 27.219374],
          [62.856311, 27.219209],
          [62.85603, 27.219103],
          [62.856004, 27.218991],
          [62.85588, 27.218922],
          [62.855808, 27.218799],
          [62.855751, 27.218798],
          [62.855641, 27.21867],
          [62.855571, 27.218348],
          [62.855387, 27.218216],
          [62.855411, 27.218131],
          [62.855362, 27.218079],
          [62.855368, 27.21797],
          [62.85542, 27.217889],
          [62.855343, 27.217784],
          [62.855269, 27.217783],
          [62.855183, 27.217655],
          [62.8551, 27.217646],
          [62.854966, 27.217429],
          [62.854908, 27.217427],
          [62.854885, 27.217327],
          [62.854936, 27.217278],
          [62.854795, 27.217202],
          [62.85455, 27.216813],
          [62.85445, 27.216824],
          [62.854273, 27.216732],
          [62.854288, 27.216673],
          [62.854239, 27.216641],
          [62.85412, 27.216651],
          [62.854038, 27.216593],
          [62.853423, 27.216692],
          [62.85325, 27.216595],
          [62.853223, 27.216534],
          [62.852957, 27.216444],
          [62.852469, 27.216507],
          [62.852438, 27.216445],
          [62.852373, 27.216435],
          [62.85208, 27.216455],
          [62.85203, 27.216289],
          [62.851761, 27.216163],
          [62.851698, 27.216177],
          [62.85167, 27.216102],
          [62.851586, 27.216114],
          [62.851504, 27.21601],
          [62.851329, 27.216028],
          [62.851177, 27.215997],
          [62.851141, 27.215945],
          [62.851034, 27.215948],
          [62.850829, 27.21603],
          [62.850606, 27.21599],
          [62.8505, 27.216061],
          [62.850262, 27.216104],
          [62.850162, 27.216284],
          [62.85001, 27.216319],
          [62.849707, 27.21653],
          [62.849175, 27.216809],
          [62.849075, 27.216754],
          [62.848861, 27.216762],
          [62.848812, 27.21672],
          [62.848701, 27.21674],
          [62.848627, 27.216705],
          [62.848421, 27.216871],
          [62.848173, 27.216801],
          [62.848027, 27.216841],
          [62.847959, 27.2168],
          [62.847857, 27.216834],
          [62.847487, 27.216805],
          [62.847107, 27.217002],
          [62.846629, 27.217076],
          [62.846546, 27.217026],
          [62.846475, 27.217059],
          [62.846441, 27.216957],
          [62.84631, 27.216949],
          [62.845953, 27.216756],
          [62.845701, 27.216936],
          [62.84564, 27.21711],
          [62.845315, 27.217206],
          [62.845258, 27.21731],
          [62.845278, 27.217633],
          [62.845179, 27.217703],
          [62.845048, 27.217648],
          [62.844816, 27.217737],
          [62.844723, 27.217833],
          [62.844608, 27.217826],
          [62.844436, 27.218124],
          [62.844187, 27.218164],
          [62.844137, 27.218273],
          [62.843453, 27.218375],
          [62.843408, 27.2184],
          [62.843409, 27.218571],
          [62.843174, 27.218489],
          [62.843058, 27.218355],
          [62.843076, 27.218318],
          [62.842842, 27.218186],
          [62.842719, 27.218244],
          [62.842341, 27.218274],
          [62.842164, 27.218213],
          [62.841961, 27.218356],
          [62.841888, 27.218328],
          [62.841834, 27.218355],
          [62.841676, 27.218268],
          [62.841578, 27.218276],
          [62.841536, 27.218162],
          [62.841434, 27.218144],
          [62.841354, 27.218071],
          [62.841179, 27.218032],
          [62.840908, 27.218067],
          [62.840808, 27.218034],
          [62.840597, 27.218055],
          [62.840657, 27.218196],
          [62.840631, 27.218465],
          [62.84047, 27.218454],
          [62.840407, 27.218668],
          [62.840029, 27.218449],
          [62.84008, 27.218318],
          [62.839966, 27.21817],
          [62.839959, 27.217939],
          [62.839442, 27.217734],
          [62.839318, 27.217722],
          [62.839325, 27.217628],
          [62.839151, 27.21764],
          [62.839041, 27.217309],
          [62.83856, 27.217122],
          [62.838216, 27.216923],
          [62.838154, 27.216953],
          [62.838121, 27.217057],
          [62.837989, 27.217067],
          [62.837949, 27.217241],
          [62.837739, 27.217435],
          [62.837377, 27.217364],
          [62.837077, 27.217429],
          [62.836912, 27.2174],
          [62.836731, 27.217436],
          [62.836645, 27.217508],
          [62.836501, 27.217768],
          [62.836388, 27.217812],
          [62.836285, 27.217757],
          [62.836002, 27.217716],
          [62.83482, 27.217193],
          [62.834716, 27.217199],
          [62.834534, 27.217029],
          [62.834267, 27.217047],
          [62.833949, 27.217158],
          [62.833706, 27.217043],
          [62.833521, 27.217364],
          [62.833364, 27.217528],
          [62.833251, 27.217415],
          [62.833116, 27.217369],
          [62.833038, 27.217484],
          [62.832731, 27.217565],
          [62.832644, 27.217633],
          [62.832668, 27.217791],
          [62.832633, 27.217822],
          [62.832268, 27.217735],
          [62.832185, 27.217752],
          [62.832163, 27.217802],
          [62.832096, 27.217755],
          [62.831605, 27.217814],
          [62.831488, 27.218145],
          [62.831501, 27.21838],
          [62.831311, 27.218634],
          [62.831053, 27.218767],
          [62.830508, 27.218802],
          [62.830273, 27.21893],
          [62.829586, 27.218576],
          [62.829489, 27.218656],
          [62.829435, 27.21865],
          [62.829351, 27.21854],
          [62.829052, 27.218422],
          [62.828796, 27.218416],
          [62.828739, 27.218635],
          [62.828783, 27.218754],
          [62.828697, 27.218756],
          [62.828624, 27.219005],
          [62.828229, 27.218917],
          [62.828078, 27.21899],
          [62.827284, 27.219184],
          [62.827062, 27.219104],
          [62.827033, 27.219048],
          [62.826477, 27.219045],
          [62.826292, 27.219008],
          [62.826022, 27.219115],
          [62.825713, 27.219167],
          [62.825527, 27.219326],
          [62.825488, 27.219316],
          [62.825503, 27.219235],
          [62.825118, 27.219388],
          [62.825081, 27.219346],
          [62.824988, 27.219394],
          [62.824865, 27.219378],
          [62.824835, 27.219563],
          [62.824711, 27.219685],
          [62.824618, 27.219637],
          [62.824508, 27.219648],
          [62.824543, 27.219608],
          [62.824503, 27.219583],
          [62.824133, 27.219465],
          [62.824127, 27.219373],
          [62.824024, 27.21935],
          [62.823983, 27.219253],
          [62.823777, 27.219263],
          [62.823708, 27.219183],
          [62.823568, 27.219213],
          [62.823607, 27.219109],
          [62.823562, 27.219044],
          [62.823339, 27.218958],
          [62.823325, 27.218885],
          [62.823068, 27.21889],
          [62.822616, 27.218703],
          [62.822517, 27.218598],
          [62.822134, 27.218524],
          [62.821877, 27.218349],
          [62.821754, 27.218411],
          [62.821409, 27.218373],
          [62.821313, 27.218438],
          [62.821108, 27.218471],
          [62.820731, 27.218458],
          [62.820704, 27.218555],
          [62.820559, 27.218665],
          [62.820482, 27.218853],
          [62.820521, 27.21901],
          [62.820296, 27.219137],
          [62.820322, 27.219202],
          [62.82028, 27.21937],
          [62.82008, 27.219715],
          [62.819565, 27.219598],
          [62.819501, 27.219641],
          [62.819356, 27.219574],
          [62.819295, 27.219624],
          [62.819035, 27.219595],
          [62.81895, 27.219704],
          [62.818693, 27.219765],
          [62.818569, 27.220039],
          [62.818362, 27.220169],
          [62.818024, 27.220218],
          [62.817944, 27.220295],
          [62.817736, 27.220349],
          [62.817698, 27.22051],
          [62.817532, 27.220577],
          [62.817422, 27.220826],
          [62.817175, 27.220819],
          [62.816901, 27.220908],
          [62.816668, 27.221135],
          [62.816682, 27.221215],
          [62.816605, 27.221314],
          [62.816429, 27.221371],
          [62.816199, 27.221345],
          [62.816024, 27.221427],
          [62.815935, 27.221555],
          [62.815766, 27.221464],
          [62.815753, 27.221551],
          [62.815656, 27.22154],
          [62.815392, 27.221639],
          [62.815079, 27.221865],
          [62.815049, 27.222206],
          [62.81496, 27.222221],
          [62.814807, 27.222342],
          [62.814695, 27.222335],
          [62.814363, 27.222424],
          [62.813845, 27.222436],
          [62.813405, 27.222615],
          [62.81346, 27.222704],
          [62.813402, 27.222746],
          [62.813159, 27.222777],
          [62.813004, 27.222901],
          [62.812778, 27.222882],
          [62.812598, 27.223099],
          [62.812076, 27.223107],
          [62.812026, 27.223116],
          [62.812027, 27.223188],
          [62.811844, 27.223151],
          [62.811744, 27.22324],
          [62.811715, 27.223397],
          [62.811622, 27.223436],
          [62.811459, 27.223618],
          [62.811264, 27.223717],
          [62.811258, 27.223757],
          [62.811383, 27.223742],
          [62.811203, 27.223891],
          [62.811015, 27.22391],
          [62.810723, 27.224169],
          [62.81069, 27.224254],
          [62.810727, 27.224364],
          [62.810974, 27.224842],
          [62.810369, 27.225245],
          [62.810288, 27.225382],
          [62.810086, 27.225484],
          [62.810094, 27.225529],
          [62.810006, 27.225548],
          [62.809919, 27.225682],
          [62.810009, 27.225953],
          [62.810112, 27.225963],
          [62.809955, 27.226126],
          [62.810014, 27.226306],
          [62.809874, 27.226351],
          [62.809782, 27.226574],
          [62.809646, 27.226658],
          [62.809617, 27.226859],
          [62.809747, 27.227028],
          [62.809602, 27.227195],
          [62.809703, 27.227298],
          [62.809667, 27.227428],
          [62.809385, 27.227557],
          [62.80863, 27.228375],
          [62.808507, 27.228423],
          [62.808214, 27.228443],
          [62.808184, 27.228506],
          [62.808103, 27.228497],
          [62.808055, 27.22864],
          [62.807974, 27.228719],
          [62.80764, 27.228932],
          [62.807574, 27.229178],
          [62.807615, 27.229186],
          [62.807632, 27.229346],
          [62.807707, 27.229463],
          [62.807924, 27.229612],
          [62.807765, 27.229811],
          [62.807682, 27.229838],
          [62.807657, 27.229901],
          [62.807557, 27.229922],
          [62.807524, 27.229982],
          [62.807562, 27.23009],
          [62.807652, 27.230168],
          [62.807502, 27.2302],
          [62.807361, 27.230307],
          [62.807143, 27.230243],
          [62.807046, 27.230321],
          [62.806905, 27.230317],
          [62.806805, 27.230562],
          [62.806725, 27.230513],
          [62.806569, 27.230567],
          [62.806519, 27.230634],
          [62.805635, 27.23049],
          [62.805537, 27.230501],
          [62.80545, 27.230589],
          [62.805141, 27.230738],
          [62.804982, 27.231091],
          [62.805065, 27.231133],
          [62.805082, 27.231328],
          [62.805165, 27.231423],
          [62.805192, 27.23176],
          [62.805001, 27.232033],
          [62.805035, 27.232121],
          [62.804941, 27.232357],
          [62.804805, 27.232508],
          [62.804697, 27.232483],
          [62.804528, 27.232536],
          [62.804529, 27.23261],
          [62.804698, 27.232747],
          [62.804336, 27.232786],
          [62.804312, 27.23285],
          [62.80413, 27.232895],
          [62.80405, 27.23299],
          [62.803713, 27.233041],
          [62.803628, 27.233004],
          [62.803453, 27.23302],
          [62.803237, 27.233112],
          [62.803213, 27.23321],
          [62.803308, 27.233313],
          [62.803307, 27.233408],
          [62.803477, 27.233561],
          [62.803415, 27.233664],
          [62.803433, 27.233975],
          [62.80353, 27.23413],
          [62.803612, 27.234133],
          [62.803676, 27.234259],
          [62.803756, 27.234295],
          [62.803761, 27.234428],
          [62.803405, 27.234458],
          [62.80331, 27.234558],
          [62.803035, 27.234641],
          [62.803002, 27.234691],
          [62.802766, 27.234785],
          [62.802703, 27.234879],
          [62.802393, 27.23497],
          [62.80215, 27.234923],
          [62.802053, 27.234954],
          [62.801853, 27.235089],
          [62.801819, 27.235247],
          [62.801638, 27.235397],
          [62.801357, 27.235396],
          [62.801197, 27.235322],
          [62.800908, 27.235392],
          [62.800921, 27.235168],
          [62.800977, 27.235104],
          [62.800903, 27.234934],
          [62.800632, 27.23488],
          [62.800542, 27.23479],
          [62.800381, 27.23473],
          [62.800155, 27.234509],
          [62.799975, 27.234517],
          [62.79995, 27.234466],
          [62.799783, 27.234464],
          [62.799543, 27.234392],
          [62.799461, 27.234525],
          [62.799146, 27.234539],
          [62.799008, 27.234705],
          [62.798872, 27.234774],
          [62.79913, 27.234985],
          [62.799126, 27.235259],
          [62.799026, 27.235673],
          [62.799241, 27.23576],
          [62.799597, 27.236039],
          [62.799726, 27.236208],
          [62.800097, 27.236509],
          [62.800082, 27.236557],
          [62.800302, 27.236639],
          [62.80035, 27.236708],
          [62.800352, 27.236862],
          [62.800509, 27.236948],
          [62.800519, 27.237148],
          [62.800395, 27.237247],
          [62.800446, 27.237341],
          [62.800451, 27.237538],
          [62.800591, 27.23762],
          [62.800413, 27.23795],
          [62.80007, 27.237832],
          [62.800006, 27.237772],
          [62.799958, 27.237837],
          [62.799744, 27.23777],
          [62.799545, 27.237783],
          [62.799508, 27.237827],
          [62.79961, 27.237935],
          [62.799635, 27.238175],
          [62.799439, 27.238326],
          [62.799467, 27.238436],
          [62.799608, 27.238596],
          [62.799564, 27.238823],
          [62.799498, 27.238922],
          [62.799124, 27.239058],
          [62.799142, 27.2391],
          [62.799032, 27.23917],
          [62.7988, 27.239245],
          [62.798749, 27.239298],
          [62.798812, 27.239488],
          [62.798684, 27.239693],
          [62.798546, 27.239711],
          [62.798199, 27.239892],
          [62.798121, 27.239894],
          [62.797925, 27.239711],
          [62.797611, 27.239776],
          [62.79754, 27.239876],
          [62.797611, 27.239955],
          [62.797561, 27.240076],
          [62.79761, 27.240149],
          [62.797271, 27.24032],
          [62.797243, 27.240389],
          [62.797149, 27.240419],
          [62.797063, 27.240586],
          [62.796741, 27.240832],
          [62.796364, 27.240738],
          [62.796249, 27.240787],
          [62.796053, 27.240648],
          [62.79599, 27.240681],
          [62.795957, 27.240609],
          [62.795832, 27.240616],
          [62.795894, 27.2408],
          [62.7958, 27.240894],
          [62.795812, 27.240933],
          [62.796234, 27.241168],
          [62.796471, 27.241741],
          [62.796588, 27.241865],
          [62.796357, 27.24208],
          [62.796338, 27.242188],
          [62.796251, 27.242256],
          [62.796269, 27.242312],
          [62.796174, 27.242334],
          [62.796198, 27.242624],
          [62.796261, 27.242647],
          [62.795975, 27.242973],
          [62.795927, 27.243227],
          [62.795856, 27.243294],
          [62.79591, 27.243467],
          [62.79576, 27.243706],
          [62.79538, 27.243954],
          [62.795407, 27.244192],
          [62.795157, 27.244472],
          [62.79495, 27.244481],
          [62.794764, 27.24456],
          [62.794702, 27.244607],
          [62.794605, 27.244846],
          [62.794489, 27.244878],
          [62.793987, 27.24489],
          [62.793641, 27.244954],
          [62.79361, 27.244867],
          [62.793514, 27.244842],
          [62.79352, 27.244777],
          [62.793431, 27.244732],
          [62.793102, 27.244769],
          [62.793023, 27.244821],
          [62.79306, 27.24488],
          [62.792937, 27.244971],
          [62.792714, 27.245264],
          [62.792717, 27.245334],
          [62.792604, 27.245368],
          [62.792576, 27.245437],
          [62.792528, 27.245415],
          [62.792342, 27.245487],
          [62.792234, 27.245631],
          [62.791747, 27.245608],
          [62.791667, 27.245658],
          [62.791545, 27.245613],
          [62.791026, 27.245688],
          [62.790752, 27.245754],
          [62.790633, 27.245896],
          [62.790459, 27.245941],
          [62.790301, 27.245872],
          [62.789494, 27.245802],
          [62.789408, 27.245882],
          [62.789082, 27.245987],
          [62.788811, 27.245964],
          [62.788687, 27.246074],
          [62.788636, 27.24619],
          [62.788113, 27.246598],
          [62.788153, 27.246712],
          [62.788004, 27.246915],
          [62.787995, 27.247069],
          [62.787864, 27.247104],
          [62.787718, 27.247076],
          [62.787588, 27.24763],
          [62.787339, 27.248004],
          [62.78729, 27.2482],
          [62.786641, 27.248786],
          [62.786434, 27.248854],
          [62.786307, 27.248997],
          [62.7863, 27.249369],
          [62.786432, 27.249651],
          [62.786385, 27.249742],
          [62.786417, 27.249823],
          [62.786365, 27.249925],
          [62.786375, 27.250055],
          [62.78633, 27.250043],
          [62.786291, 27.25074],
          [62.786065, 27.250772],
          [62.785783, 27.250929],
          [62.785758, 27.251001],
          [62.78562, 27.25105],
          [62.785386, 27.251345],
          [62.785297, 27.251381],
          [62.785151, 27.251676],
          [62.784953, 27.251773],
          [62.784838, 27.251957],
          [62.784676, 27.251996],
          [62.784665, 27.252317],
          [62.784482, 27.252558],
          [62.784484, 27.252677],
          [62.784315, 27.253174],
          [62.783963, 27.253147],
          [62.783914, 27.25326],
          [62.783728, 27.253322],
          [62.783002, 27.253212],
          [62.782884, 27.253261],
          [62.782898, 27.253309],
          [62.782599, 27.253358],
          [62.782267, 27.253288],
          [62.78212, 27.2533],
          [62.781437, 27.253029],
          [62.781008, 27.253143],
          [62.780787, 27.253139],
          [62.780607, 27.253081],
          [62.780527, 27.252886],
          [62.780434, 27.252918],
          [62.780183, 27.252903],
          [62.780254, 27.253092],
          [62.77981, 27.253198],
          [62.779488, 27.253094],
          [62.779488, 27.252938],
          [62.77909, 27.252935],
          [62.778786, 27.252842],
          [62.77869, 27.252744],
          [62.778292, 27.252721],
          [62.778212, 27.25273],
          [62.778163, 27.252978],
          [62.778, 27.253237],
          [62.777806, 27.253206],
          [62.777759, 27.253254],
          [62.777529, 27.25318],
          [62.777317, 27.253253],
          [62.776993, 27.253186],
          [62.77679, 27.253208],
          [62.776645, 27.253083],
          [62.775572, 27.252896],
          [62.775662, 27.253155],
          [62.775614, 27.253679],
          [62.77566, 27.253895],
          [62.775539, 27.254084],
          [62.775236, 27.254308],
          [62.775284, 27.254388],
          [62.77526, 27.254479],
          [62.775326, 27.25451],
          [62.775601, 27.255129],
          [62.775583, 27.255569],
          [62.775786, 27.2556],
          [62.776172, 27.255563],
          [62.776202, 27.256082],
          [62.776018, 27.256285],
          [62.776169, 27.256345],
          [62.776161, 27.256658],
          [62.776483, 27.256982],
          [62.776692, 27.257097],
          [62.776705, 27.257175],
          [62.776826, 27.257276],
          [62.776615, 27.257509],
          [62.776544, 27.257656],
          [62.776634, 27.257804],
          [62.776567, 27.257829],
          [62.776564, 27.257984],
          [62.776304, 27.258066],
          [62.776143, 27.258175],
          [62.774933, 27.258325],
          [62.774976, 27.25865],
          [62.775181, 27.258996],
          [62.775253, 27.259302],
          [62.775166, 27.25938],
          [62.775235, 27.259595],
          [62.775186, 27.259788],
          [62.775284, 27.259802],
          [62.775278, 27.26001],
          [62.775375, 27.260076],
          [62.775419, 27.26023],
          [62.775583, 27.26029],
          [62.77559, 27.260364],
          [62.775878, 27.260324],
          [62.776179, 27.260416],
          [62.776478, 27.260353],
          [62.776623, 27.260244],
          [62.777019, 27.260249],
          [62.777266, 27.260112],
          [62.778015, 27.260049],
          [62.778195, 27.259966],
          [62.77873, 27.260036],
          [62.778922, 27.259835],
          [62.779015, 27.259817],
          [62.77906, 27.259759],
          [62.779474, 27.259741],
          [62.779589, 27.25968],
          [62.77975, 27.259672],
          [62.779779, 27.259615],
          [62.780636, 27.2596],
          [62.780828, 27.259522],
          [62.781304, 27.259484],
          [62.781363, 27.259634],
          [62.781606, 27.259627],
          [62.781748, 27.25967],
          [62.782212, 27.259619],
          [62.782694, 27.259708],
          [62.78274, 27.259645],
          [62.782853, 27.259634],
          [62.782827, 27.259761],
          [62.783198, 27.25986],
          [62.783246, 27.260073],
          [62.783515, 27.259839],
          [62.783853, 27.259802],
          [62.783981, 27.259726],
          [62.784124, 27.25971],
          [62.784301, 27.259567],
          [62.784565, 27.259604],
          [62.78454, 27.259701],
          [62.784572, 27.259712],
          [62.784836, 27.259728],
          [62.784968, 27.259682],
          [62.784993, 27.259743],
          [62.785094, 27.259724],
          [62.785193, 27.259889],
          [62.785522, 27.259899],
          [62.785632, 27.259763],
          [62.786161, 27.25964],
          [62.786622, 27.259652],
          [62.786742, 27.259749],
          [62.787067, 27.259877],
          [62.787345, 27.259759],
          [62.787501, 27.259742],
          [62.787612, 27.259762],
          [62.787581, 27.259832],
          [62.787679, 27.259924],
          [62.787844, 27.259934],
          [62.788283, 27.260111],
          [62.788702, 27.260079],
          [62.788772, 27.26014],
          [62.788871, 27.260078],
          [62.78907, 27.260162],
          [62.789232, 27.260156],
          [62.78928, 27.260118],
          [62.789614, 27.260337],
          [62.789809, 27.260338],
          [62.790124, 27.260536],
          [62.790239, 27.260578],
          [62.790353, 27.26056],
          [62.79025, 27.260736],
          [62.790474, 27.260735],
          [62.790642, 27.260825],
          [62.790962, 27.260863],
          [62.791142, 27.261148],
          [62.791422, 27.26126],
          [62.791808, 27.26128],
          [62.792092, 27.261367],
          [62.792496, 27.261395],
          [62.793328, 27.261304],
          [62.793634, 27.261195],
          [62.793963, 27.261195],
          [62.79407, 27.261226],
          [62.794167, 27.261426],
          [62.794344, 27.261473],
          [62.794517, 27.261512],
          [62.794775, 27.261482],
          [62.795178, 27.261515],
          [62.795327, 27.261588],
          [62.795472, 27.261567],
          [62.795558, 27.261621],
          [62.795622, 27.261745],
          [62.796102, 27.261728],
          [62.796236, 27.261804],
          [62.796799, 27.261835],
          [62.796973, 27.261901],
          [62.797049, 27.261978],
          [62.797257, 27.261947],
          [62.797338, 27.261866],
          [62.797601, 27.261911],
          [62.797679, 27.261883],
          [62.797967, 27.261922],
          [62.798175, 27.261876],
          [62.798291, 27.261912],
          [62.798409, 27.261886],
          [62.79856, 27.261769],
          [62.798934, 27.26178],
          [62.799186, 27.261852],
          [62.799483, 27.262066],
          [62.799704, 27.262039],
          [62.800045, 27.262127],
          [62.800112, 27.262164],
          [62.800087, 27.262234],
          [62.800407, 27.26233],
          [62.800578, 27.262522],
          [62.800866, 27.262594],
          [62.800943, 27.262561],
          [62.801209, 27.26263],
          [62.801484, 27.262625],
          [62.801985, 27.262838],
          [62.80227, 27.262872],
          [62.802553, 27.262967],
          [62.802959, 27.262973],
          [62.803808, 27.263134],
          [62.804119, 27.263125],
          [62.804207, 27.263197],
          [62.804956, 27.263191],
          [62.805043, 27.263136],
          [62.805568, 27.263059],
          [62.805844, 27.26317],
          [62.805978, 27.263171],
          [62.806455, 27.262995],
          [62.806688, 27.263042],
          [62.806895, 27.263116],
          [62.806896, 27.263167],
          [62.807103, 27.263328],
          [62.807142, 27.263516],
          [62.807393, 27.263565],
          [62.807543, 27.26367],
          [62.807648, 27.263672],
          [62.807693, 27.263856],
          [62.808287, 27.263985],
          [62.808199, 27.264035],
          [62.808679, 27.264142],
          [62.80882, 27.264235],
          [62.808998, 27.264669],
          [62.808947, 27.265612],
          [62.808886, 27.265704],
          [62.808838, 27.265957],
          [62.808627, 27.266025],
          [62.808606, 27.266089],
          [62.808479, 27.266147],
          [62.808475, 27.266419],
          [62.808281, 27.266531],
          [62.808265, 27.266662],
          [62.808126, 27.266732],
          [62.808445, 27.267193],
          [62.808874, 27.267361],
          [62.808979, 27.2675],
          [62.809155, 27.267585],
          [62.809231, 27.26768],
          [62.809185, 27.26776],
          [62.809294, 27.267899],
          [62.809242, 27.267952],
          [62.80943, 27.26811],
          [62.809458, 27.268363],
          [62.809659, 27.268525],
          [62.809618, 27.26858],
          [62.809673, 27.268616],
          [62.809458, 27.268682],
          [62.809376, 27.268807],
          [62.809133, 27.268894],
          [62.809001, 27.269037],
          [62.808968, 27.269281],
          [62.808742, 27.269651],
          [62.808924, 27.269763],
          [62.808813, 27.269813],
          [62.80885, 27.27014],
          [62.8086, 27.270193],
          [62.808528, 27.270407],
          [62.808835, 27.271001],
          [62.809418, 27.271361],
          [62.809347, 27.271487],
          [62.809115, 27.271681],
          [62.809104, 27.271773],
          [62.809177, 27.271928],
          [62.809124, 27.272025],
          [62.809262, 27.272133],
          [62.809352, 27.2723],
          [62.809312, 27.272698],
          [62.809698, 27.272724],
          [62.810009, 27.273002],
          [62.81022, 27.273074],
          [62.810516, 27.273286],
          [62.811036, 27.273774],
          [62.811104, 27.273879],
          [62.811071, 27.273907],
          [62.811126, 27.274324],
          [62.811199, 27.274373],
          [62.811129, 27.274623],
          [62.81117, 27.274765],
          [62.811303, 27.27484],
          [62.811606, 27.274916],
          [62.811895, 27.274918],
          [62.812205, 27.275068],
          [62.813024, 27.275145],
          [62.81307, 27.275218],
          [62.813684, 27.275176],
          [62.813964, 27.275197],
          [62.814186, 27.275236],
          [62.814317, 27.275411],
          [62.814522, 27.275375],
          [62.814308, 27.275662],
          [62.81424, 27.275694],
          [62.814165, 27.276126],
          [62.813993, 27.276328],
          [62.814059, 27.276546],
          [62.814029, 27.276678],
          [62.814713, 27.276638],
          [62.814777, 27.276847],
          [62.814894, 27.276916],
          [62.814862, 27.27695],
          [62.814928, 27.277118],
          [62.81492, 27.277313],
          [62.814754, 27.277679],
          [62.814776, 27.277743],
          [62.814852, 27.277757],
          [62.814729, 27.27794],
          [62.81463, 27.277993],
          [62.814635, 27.278138],
          [62.814781, 27.278313],
          [62.815502, 27.278569],
          [62.815843, 27.27856],
          [62.815969, 27.278515],
          [62.816105, 27.27857],
          [62.816264, 27.278449],
          [62.816633, 27.278301],
          [62.817037, 27.278224],
          [62.817163, 27.278618],
          [62.81707, 27.27882],
          [62.817166, 27.278954],
          [62.817133, 27.279179],
          [62.817264, 27.279276],
          [62.81728, 27.279373],
          [62.817157, 27.279705],
          [62.817072, 27.279802],
          [62.81707, 27.279946],
          [62.816952, 27.280012],
          [62.81682, 27.280187],
          [62.816687, 27.280584],
          [62.816606, 27.280668],
          [62.816383, 27.280751],
          [62.816023, 27.280801],
          [62.815964, 27.280943],
          [62.815747, 27.281019],
          [62.815683, 27.281084],
          [62.815709, 27.28127],
          [62.815618, 27.281524],
          [62.815354, 27.281827],
          [62.815233, 27.281882],
          [62.81517, 27.282056],
          [62.81504, 27.282178],
          [62.814252, 27.282384],
          [62.814095, 27.282467],
          [62.814011, 27.282589],
          [62.813988, 27.282902],
          [62.813915, 27.283035],
          [62.813957, 27.28312],
          [62.813706, 27.283203],
          [62.813606, 27.2833],
          [62.813607, 27.283533],
          [62.813507, 27.283643],
          [62.813471, 27.283843],
          [62.813473, 27.284442],
          [62.813516, 27.284746],
          [62.813597, 27.284799],
          [62.813592, 27.284994],
          [62.813682, 27.285401],
          [62.813669, 27.285622],
          [62.813798, 27.285906],
          [62.814052, 27.28593],
          [62.813958, 27.286113],
          [62.813976, 27.286187],
          [62.814128, 27.286327],
          [62.814273, 27.286983],
          [62.814448, 27.287021],
          [62.814833, 27.287672],
          [62.815386, 27.287538],
          [62.815473, 27.287463],
          [62.815641, 27.287427],
          [62.816178, 27.288111],
          [62.816455, 27.287999],
          [62.816611, 27.288201],
          [62.816763, 27.288262],
          [62.817104, 27.288135],
          [62.817387, 27.288309],
          [62.818092, 27.288496],
          [62.818109, 27.28857],
          [62.818222, 27.288636],
          [62.818147, 27.288729],
          [62.818142, 27.288907],
          [62.81797, 27.288928],
          [62.817878, 27.289026],
          [62.817915, 27.289122],
          [62.817681, 27.289292],
          [62.817487, 27.289369],
          [62.817541, 27.289827],
          [62.817476, 27.289921],
          [62.817281, 27.290023],
          [62.816995, 27.290449],
          [62.816785, 27.29058],
          [62.816731, 27.290702],
          [62.816814, 27.290902],
          [62.816993, 27.291061],
          [62.817152, 27.291096],
          [62.817526, 27.291303],
          [62.817569, 27.291588],
          [62.817452, 27.291738],
          [62.817567, 27.291947],
          [62.817466, 27.292026],
          [62.817663, 27.292244],
          [62.817403, 27.292397],
          [62.817324, 27.292387],
          [62.81712, 27.292469],
          [62.817084, 27.292573],
          [62.816963, 27.292658],
          [62.816699, 27.292762],
          [62.816622, 27.292848],
          [62.816741, 27.292938],
          [62.816789, 27.293141],
          [62.816675, 27.293269],
          [62.816855, 27.293391],
          [62.81692, 27.293512],
          [62.817098, 27.293656],
          [62.817047, 27.293803],
          [62.817226, 27.293891],
          [62.817169, 27.293999],
          [62.817202, 27.294201],
          [62.817631, 27.294304],
          [62.817934, 27.29429],
          [62.818012, 27.29439],
          [62.818125, 27.294409],
          [62.818507, 27.294302],
          [62.818699, 27.294486],
          [62.818948, 27.294561],
          [62.819, 27.294751],
          [62.81937, 27.294915],
          [62.820464, 27.294656],
          [62.820685, 27.294761],
          [62.820729, 27.294899],
          [62.820847, 27.294879],
          [62.820981, 27.295016],
          [62.821078, 27.295207],
          [62.821116, 27.295659],
          [62.821259, 27.295669],
          [62.821386, 27.295595],
          [62.821766, 27.295644],
          [62.821889, 27.29583],
          [62.822257, 27.295844],
          [62.822185, 27.295967],
          [62.822386, 27.296677],
          [62.822529, 27.2969],
          [62.822585, 27.297214],
          [62.822712, 27.297321],
          [62.822812, 27.297317],
          [62.822933, 27.297388],
          [62.823101, 27.297899],
          [62.823059, 27.298072],
          [62.823786, 27.298155],
          [62.82381, 27.298197],
          [62.824083, 27.298282],
          [62.824135, 27.298382],
          [62.824265, 27.298426],
          [62.824451, 27.298602],
          [62.824446, 27.298719],
          [62.824626, 27.298825],
          [62.824803, 27.299191],
          [62.824594, 27.299237],
          [62.824591, 27.299315],
          [62.824462, 27.299396],
          [62.824394, 27.299518],
          [62.824008, 27.299579],
          [62.823744, 27.299942],
          [62.8236, 27.300325],
          [62.823408, 27.300489],
          [62.823491, 27.300595],
          [62.82336, 27.300652],
          [62.823335, 27.300747],
          [62.823405, 27.300886],
          [62.823117, 27.300951],
          [62.822849, 27.300933],
          [62.822682, 27.301002],
          [62.822514, 27.301253],
          [62.822324, 27.301367],
          [62.822361, 27.301566],
          [62.821693, 27.301737],
          [62.821845, 27.302485],
          [62.822264, 27.303248],
          [62.822247, 27.303526],
          [62.822174, 27.303596],
          [62.822439, 27.303682],
          [62.822538, 27.303795],
          [62.822601, 27.304182],
          [62.82285, 27.304472],
          [62.822793, 27.304792],
          [62.823132, 27.304868],
          [62.82336, 27.304977],
          [62.823418, 27.305162],
          [62.823505, 27.305258],
          [62.823754, 27.305306],
          [62.823872, 27.305404],
          [62.823929, 27.305571],
          [62.82388, 27.305848],
          [62.824355, 27.305936],
          [62.82507, 27.306667],
          [62.825205, 27.306872],
          [62.825166, 27.30712],
          [62.82464, 27.307657],
          [62.824659, 27.3078],
          [62.825369, 27.308251],
          [62.825465, 27.308701],
          [62.82581, 27.308926],
          [62.825991, 27.309156],
          [62.826224, 27.309636],
          [62.826318, 27.309987],
          [62.82601, 27.310042],
          [62.825939, 27.310015],
          [62.825516, 27.310105],
          [62.825283, 27.310234],
          [62.824989, 27.310209],
          [62.824849, 27.310367],
          [62.824379, 27.310544],
          [62.824245, 27.310847],
          [62.824047, 27.310987],
          [62.823857, 27.310977],
          [62.823645, 27.31107],
          [62.823338, 27.31107],
          [62.822854, 27.311237],
          [62.822292, 27.311194],
          [62.821948, 27.311281],
          [62.821557, 27.311231],
          [62.821369, 27.311442],
          [62.821026, 27.311545],
          [62.820314, 27.311465],
          [62.820095, 27.311485],
          [62.819901, 27.311397],
          [62.819424, 27.311394],
          [62.819341, 27.311457],
          [62.819324, 27.311566],
          [62.819151, 27.311677],
          [62.818878, 27.311679],
          [62.8186, 27.311754],
          [62.818353, 27.311764],
          [62.818147, 27.311915],
          [62.818012, 27.311876],
          [62.817794, 27.311948],
          [62.817667, 27.312141],
          [62.817582, 27.312544],
          [62.81746, 27.312808],
          [62.817114, 27.313083],
          [62.817317, 27.313119],
          [62.817281, 27.313132],
          [62.817514, 27.313659],
          [62.817511, 27.313859],
          [62.817451, 27.313989],
          [62.817628, 27.314033],
          [62.817569, 27.314166],
          [62.817586, 27.314238],
          [62.817728, 27.314348],
          [62.818049, 27.314365],
          [62.818089, 27.31441],
          [62.817968, 27.314526],
          [62.81815, 27.314667],
          [62.818144, 27.314734],
          [62.81799, 27.315016],
          [62.818038, 27.315258],
          [62.817984, 27.315354],
          [62.817897, 27.315396],
          [62.817694, 27.315363],
          [62.817739, 27.315523],
          [62.817822, 27.315597],
          [62.817669, 27.315639],
          [62.817809, 27.315834],
          [62.817696, 27.316074],
          [62.817521, 27.31611],
          [62.817525, 27.316156],
          [62.817328, 27.316241],
          [62.81734, 27.316369],
          [62.817286, 27.316505],
          [62.816998, 27.3166],
          [62.816496, 27.316643],
          [62.816363, 27.316615],
          [62.816092, 27.31666],
          [62.815986, 27.316656],
          [62.815863, 27.316576],
          [62.815317, 27.316509],
          [62.814847, 27.316642],
          [62.814529, 27.316565],
          [62.814187, 27.316571],
          [62.814156, 27.316652],
          [62.813788, 27.316894],
          [62.813478, 27.316834],
          [62.813226, 27.316854],
          [62.813138, 27.316815],
          [62.812734, 27.316938],
          [62.812612, 27.316849],
          [62.812095, 27.316829],
          [62.811603, 27.317085],
          [62.811378, 27.317156],
          [62.811344, 27.3173],
          [62.811161, 27.317276],
          [62.811239, 27.317514],
          [62.811171, 27.317651],
          [62.811035, 27.317746],
          [62.810928, 27.318088],
          [62.810795, 27.318135],
          [62.810681, 27.318341],
          [62.810535, 27.318394],
          [62.810465, 27.318541],
          [62.810408, 27.318558],
          [62.810412, 27.318623],
          [62.810291, 27.318674],
          [62.810334, 27.318903],
          [62.810274, 27.319011],
          [62.810325, 27.319108],
          [62.810301, 27.319159],
          [62.810037, 27.319379],
          [62.809685, 27.319517],
          [62.809648, 27.319763],
          [62.809281, 27.320239],
          [62.809156, 27.320291],
          [62.808848, 27.320284],
          [62.808683, 27.32033],
          [62.80883, 27.320343],
          [62.80913, 27.320553],
          [62.809091, 27.320762],
          [62.809009, 27.320827],
          [62.808992, 27.320905],
          [62.808923, 27.320934],
          [62.80895, 27.321028],
          [62.80969, 27.321273],
          [62.809928, 27.321582],
          [62.809889, 27.321676],
          [62.810075, 27.3217],
          [62.810127, 27.321839],
          [62.809984, 27.321872],
          [62.809922, 27.322641],
          [62.809789, 27.322731],
          [62.809594, 27.3231],
          [62.809169, 27.32341],
          [62.809056, 27.323705],
          [62.809137, 27.32386],
          [62.80908, 27.323908],
          [62.809034, 27.324193],
          [62.808695, 27.324558],
          [62.808749, 27.32465],
          [62.808586, 27.324883],
          [62.808481, 27.324822],
          [62.808318, 27.324819],
          [62.808044, 27.324877],
          [62.808003, 27.324707],
          [62.807723, 27.324683],
          [62.807521, 27.324606],
          [62.807035, 27.324642],
          [62.806907, 27.324558],
          [62.806806, 27.324394],
          [62.806648, 27.32434],
          [62.806623, 27.324146],
          [62.806476, 27.324028],
          [62.805967, 27.324056],
          [62.805824, 27.324238],
          [62.80587, 27.324279],
          [62.805828, 27.324374],
          [62.805403, 27.324612],
          [62.805371, 27.324702],
          [62.805235, 27.324811],
          [62.805312, 27.324949],
          [62.805446, 27.324924],
          [62.805531, 27.324976],
          [62.805546, 27.325069],
          [62.805149, 27.325197],
          [62.805143, 27.325307],
          [62.80491, 27.325354],
          [62.804751, 27.32534],
          [62.804356, 27.325567],
          [62.804269, 27.32567],
          [62.80343, 27.326118],
          [62.803284, 27.3263],
          [62.803323, 27.326554],
          [62.803108, 27.326616],
          [62.80292, 27.326956],
          [62.803176, 27.327087],
          [62.803149, 27.327201],
          [62.803323, 27.327221],
          [62.803368, 27.327287],
          [62.803777, 27.327262],
          [62.804134, 27.327384],
          [62.804116, 27.327515],
          [62.804205, 27.327495],
          [62.804275, 27.327547],
          [62.804904, 27.327543],
          [62.806166, 27.327395],
          [62.806055, 27.327533],
          [62.806204, 27.327552],
          [62.806192, 27.327635],
          [62.806721, 27.327591],
          [62.806936, 27.32764],
          [62.806805, 27.327786],
          [62.80731, 27.327715],
          [62.807605, 27.327858],
          [62.808165, 27.328311],
          [62.808211, 27.328678],
          [62.808206, 27.328849],
          [62.80806, 27.329048],
          [62.807891, 27.329527],
          [62.807931, 27.329926],
          [62.807831, 27.329944],
          [62.807669, 27.330065],
          [62.807766, 27.330183],
          [62.807657, 27.330325],
          [62.807522, 27.330363],
          [62.807347, 27.33052],
          [62.807057, 27.330677],
          [62.806983, 27.330815],
          [62.806816, 27.330884],
          [62.806756, 27.330995],
          [62.806305, 27.331213],
          [62.806364, 27.33128],
          [62.80634, 27.331328],
          [62.806134, 27.331236],
          [62.805808, 27.331217],
          [62.805714, 27.331142],
          [62.805485, 27.331073],
          [62.805354, 27.330962],
          [62.805254, 27.330984],
          [62.805197, 27.331052],
          [62.805051, 27.331073],
          [62.805021, 27.33116],
          [62.804847, 27.331183],
          [62.804576, 27.331315],
          [62.804389, 27.331434],
          [62.804439, 27.331552],
          [62.804343, 27.33166],
          [62.804421, 27.331714],
          [62.804539, 27.33171],
          [62.804463, 27.331771],
          [62.804494, 27.331821],
          [62.804376, 27.331827],
          [62.804372, 27.331912],
          [62.804496, 27.332007],
          [62.804467, 27.332096],
          [62.804582, 27.332111],
          [62.80451, 27.332228],
          [62.804615, 27.332394],
          [62.804608, 27.33247],
          [62.803743, 27.332438],
          [62.803722, 27.332516],
          [62.803598, 27.332524],
          [62.803293, 27.332454],
          [62.803138, 27.332605],
          [62.802786, 27.332606],
          [62.802723, 27.332807],
          [62.802756, 27.332916],
          [62.80301, 27.332967],
          [62.80304, 27.333073],
          [62.803003, 27.333099],
          [62.802576, 27.333139],
          [62.801666, 27.333336],
          [62.801291, 27.333335],
          [62.801144, 27.333382],
          [62.800487, 27.333422],
          [62.799906, 27.333374],
          [62.799753, 27.333325],
          [62.79942, 27.333332],
          [62.79915, 27.333246],
          [62.799125, 27.333307],
          [62.798894, 27.333461],
          [62.798341, 27.33332],
          [62.797745, 27.333291],
          [62.797681, 27.333223],
          [62.797542, 27.333227],
          [62.797189, 27.333347],
          [62.797072, 27.333294],
          [62.796971, 27.333326],
          [62.796529, 27.333316],
          [62.796648, 27.333375],
          [62.796594, 27.333434],
          [62.796675, 27.333469],
          [62.796602, 27.333511],
          [62.796581, 27.333604],
          [62.796772, 27.33372],
          [62.796767, 27.333777],
          [62.796693, 27.33381],
          [62.796703, 27.33391],
          [62.796757, 27.333987],
          [62.79687, 27.333991],
          [62.796902, 27.33414],
          [62.796844, 27.334269],
          [62.796465, 27.334725],
          [62.795996, 27.335229],
          [62.796066, 27.33534],
          [62.796424, 27.335642],
          [62.796344, 27.335787],
          [62.796346, 27.335986],
          [62.796471, 27.336074],
          [62.796216, 27.336606],
          [62.796443, 27.336653],
          [62.796511, 27.33674],
          [62.796456, 27.336812],
          [62.796272, 27.336897],
          [62.796198, 27.336994],
          [62.795757, 27.337016],
          [62.795667, 27.337079],
          [62.794989, 27.337226],
          [62.794772, 27.337205],
          [62.794283, 27.337285],
          [62.794217, 27.337235],
          [62.794057, 27.337231],
          [62.793786, 27.337301],
          [62.79368, 27.337296],
          [62.793542, 27.337201],
          [62.793445, 27.337201],
          [62.793392, 27.337295],
          [62.792933, 27.337441],
          [62.792808, 27.337548],
          [62.792719, 27.337552],
          [62.792826, 27.33765],
          [62.792763, 27.337718],
          [62.79238, 27.337787],
          [62.792319, 27.337876],
          [62.792241, 27.3379],
          [62.792236, 27.337997],
          [62.792097, 27.338002],
          [62.792106, 27.338159],
          [62.79195, 27.338287],
          [62.791748, 27.338183],
          [62.791767, 27.338291],
          [62.791705, 27.338515],
          [62.791308, 27.338595],
          [62.791328, 27.338783],
          [62.791617, 27.338872],
          [62.791746, 27.33899],
          [62.791793, 27.339155],
          [62.791888, 27.339218],
          [62.792199, 27.339178],
          [62.792383, 27.339313],
          [62.792727, 27.339292],
          [62.792736, 27.339399],
          [62.792959, 27.339667],
          [62.79297, 27.339755],
          [62.793163, 27.339926],
          [62.793112, 27.33997],
          [62.793201, 27.340006],
          [62.793213, 27.340075],
          [62.793075, 27.340185],
          [62.793118, 27.34032],
          [62.793248, 27.340289],
          [62.793287, 27.340378],
          [62.793386, 27.340415],
          [62.793642, 27.340416],
          [62.79373, 27.340614],
          [62.793975, 27.340599],
          [62.79422, 27.340791],
          [62.794334, 27.340829],
          [62.794281, 27.340938],
          [62.794304, 27.341076],
          [62.794265, 27.341186],
          [62.794184, 27.341338],
          [62.794063, 27.34142],
          [62.794121, 27.341539],
          [62.793895, 27.34156],
          [62.793716, 27.34164],
          [62.793467, 27.341902],
          [62.793239, 27.342047],
          [62.792881, 27.342503],
          [62.792209, 27.342748],
          [62.792348, 27.342815],
          [62.792242, 27.342928],
          [62.792353, 27.342941],
          [62.792349, 27.342969],
          [62.792257, 27.343005],
          [62.792301, 27.343155],
          [62.792105, 27.343286],
          [62.792081, 27.343405],
          [62.792629, 27.343512],
          [62.792776, 27.343435],
          [62.792951, 27.343486],
          [62.79307, 27.343414],
          [62.793157, 27.343447],
          [62.793389, 27.343406],
          [62.793514, 27.343432],
          [62.794156, 27.34333],
          [62.794224, 27.344167],
          [62.794487, 27.344568],
          [62.794612, 27.344665],
          [62.794561, 27.344737],
          [62.794732, 27.345055],
          [62.794602, 27.345101],
          [62.794474, 27.345256],
          [62.794177, 27.345324],
          [62.794269, 27.34563],
          [62.794468, 27.345589],
          [62.794904, 27.345631],
          [62.795009, 27.345735],
          [62.795553, 27.345927],
          [62.795511, 27.346097],
          [62.796142, 27.346083],
          [62.796175, 27.346679],
          [62.796305, 27.346753],
          [62.796448, 27.347223],
          [62.796827, 27.347418],
          [62.797645, 27.347434],
          [62.79779, 27.347497],
          [62.797751, 27.347765],
          [62.797795, 27.347872],
          [62.797774, 27.348083],
          [62.798025, 27.348744],
          [62.797782, 27.348827],
          [62.797427, 27.34876],
          [62.797353, 27.349094],
          [62.797388, 27.349699],
          [62.797355, 27.349857],
          [62.796942, 27.350056],
          [62.796776, 27.350087],
          [62.796576, 27.350228],
          [62.796256, 27.350319],
          [62.796282, 27.35047],
          [62.796172, 27.350565],
          [62.796336, 27.350723],
          [62.796189, 27.35077],
          [62.796167, 27.350929],
          [62.795964, 27.351001],
          [62.795941, 27.351232],
          [62.796041, 27.351347],
          [62.795808, 27.35144],
          [62.795859, 27.351549],
          [62.795822, 27.351587],
          [62.795313, 27.351792],
          [62.795241, 27.351982],
          [62.794697, 27.352237],
          [62.794659, 27.352316],
          [62.794695, 27.352526],
          [62.794482, 27.352715],
          [62.794525, 27.352788],
          [62.794652, 27.352807],
          [62.794605, 27.352925],
          [62.794681, 27.352941],
          [62.794686, 27.353108],
          [62.794806, 27.353116],
          [62.794736, 27.353154],
          [62.794752, 27.353192],
          [62.79486, 27.35319],
          [62.794943, 27.353261],
          [62.795089, 27.353258],
          [62.795026, 27.353382],
          [62.795151, 27.353439],
          [62.795343, 27.353428],
          [62.795778, 27.35351],
          [62.795708, 27.353681],
          [62.795723, 27.353772],
          [62.795559, 27.353855],
          [62.795458, 27.354048],
          [62.795515, 27.354239],
          [62.795688, 27.3543],
          [62.795798, 27.354465],
          [62.795492, 27.354796],
          [62.795388, 27.354782],
          [62.795115, 27.35501],
          [62.795293, 27.355547],
          [62.795293, 27.355727],
          [62.79554, 27.355823],
          [62.795702, 27.356044],
          [62.79599, 27.356176],
          [62.796171, 27.356608],
          [62.796365, 27.356757],
          [62.796376, 27.357382],
          [62.796257, 27.357466],
          [62.796243, 27.357779],
          [62.796095, 27.358164],
          [62.795973, 27.358254],
          [62.795936, 27.358429],
          [62.79585, 27.358519],
          [62.795973, 27.358687],
          [62.795986, 27.358874],
          [62.795941, 27.358926],
          [62.79557, 27.359061],
          [62.795055, 27.359518],
          [62.795044, 27.35989],
          [62.795111, 27.360004],
          [62.795321, 27.36011],
          [62.795705, 27.36068],
          [62.795697, 27.360843],
          [62.795455, 27.361078],
          [62.795476, 27.361149],
          [62.795603, 27.361227],
          [62.79613, 27.361097],
          [62.79684, 27.361245],
          [62.797052, 27.361242],
          [62.797321, 27.361458],
          [62.797822, 27.361593],
          [62.798016, 27.361606],
          [62.798208, 27.361551],
          [62.798753, 27.361659],
          [62.79889, 27.361792],
          [62.799359, 27.361796],
          [62.799328, 27.361845],
          [62.799404, 27.362377],
          [62.799375, 27.362498],
          [62.799436, 27.362734],
          [62.799544, 27.362912],
          [62.799631, 27.362943],
          [62.799697, 27.363228],
          [62.799685, 27.363476],
          [62.799727, 27.363514],
          [62.799654, 27.363556],
          [62.799637, 27.363662],
          [62.799534, 27.363759],
          [62.799419, 27.364131],
          [62.799226, 27.364344],
          [62.799111, 27.364807],
          [62.799193, 27.364878],
          [62.79926, 27.365033],
          [62.799455, 27.365049],
          [62.799797, 27.365316],
          [62.800135, 27.365327],
          [62.800369, 27.365149],
          [62.801029, 27.365246],
          [62.801149, 27.365417],
          [62.801218, 27.365379],
          [62.801256, 27.36524],
          [62.801428, 27.365263],
          [62.801749, 27.364998],
          [62.802105, 27.36479],
          [62.802309, 27.364547],
          [62.802385, 27.364548],
          [62.848423, 27.462939],
          [62.83866, 27.613175],
          [62.829641, 27.751782],
          [62.828955, 27.764463],
          [62.788689, 27.916457],
          [62.774822, 27.972933],
          [62.761022, 28.025923],
          [62.771142, 28.08696],
          [62.794427, 28.227253],
          [62.792961, 28.278904],
          [62.597191, 28.24842],
          [62.596553, 28.250002],
          [62.595425, 28.251245],
          [62.594813, 28.251711],
          [62.593638, 28.252734],
          [62.593176, 28.253251],
          [62.591686, 28.25452],
          [62.589056, 28.25693],
          [62.587418, 28.258302],
          [62.585457, 28.259816],
          [62.584691, 28.260655],
          [62.583976, 28.261617],
          [62.583533, 28.262641],
          [62.583517, 28.263305],
          [62.583048, 28.263814],
          [62.582845, 28.264538],
          [62.582139, 28.266141],
          [62.581257, 28.266886],
          [62.579754, 28.267865],
          [62.578442, 28.269222],
          [62.577637, 28.269762],
          [62.577363, 28.270065],
          [62.576345, 28.270984],
          [62.575098, 28.271928],
          [62.574739, 28.272272],
          [62.574422, 28.272464],
          [62.574053, 28.272601],
          [62.573609, 28.272923],
          [62.572598, 28.273526],
          [62.571815, 28.273848],
          [62.568999, 28.275669],
          [62.568666, 28.275722],
          [62.568062, 28.276036],
          [62.567689, 28.276597],
          [62.567155, 28.277729],
          [62.566915, 28.278003],
          [62.566461, 28.278395],
          [62.565884, 28.278604],
          [62.56471, 28.279472],
          [62.563746, 28.280432],
          [62.562662, 28.281298],
          [62.562034, 28.281944],
          [62.560916, 28.282652],
          [62.557161, 28.285182],
          [62.553523, 28.288791],
          [62.552973, 28.289474],
          [62.552031, 28.290379],
          [62.551601, 28.290878],
          [62.550626, 28.291534],
          [62.549285, 28.292015],
          [62.547225, 28.294741],
          [62.546515, 28.296003],
          [62.545663, 28.297299],
          [62.544475, 28.298935],
          [62.542831, 28.300532],
          [62.541618, 28.301584],
          [62.539959, 28.303184],
          [62.539299, 28.303936],
          [62.538723, 28.304874],
          [62.537687, 28.305846],
          [62.534934, 28.307825],
          [62.534376, 28.308472],
          [62.533734, 28.30908],
          [62.532666, 28.309889],
          [62.531101, 28.311409],
          [62.52977, 28.312893],
          [62.528158, 28.314899],
          [62.527146, 28.31603],
          [62.525481, 28.317739],
          [62.524638, 28.318491],
          [62.523017, 28.319463],
          [62.520958, 28.319464],
          [62.518806, 28.319644],
          [62.516372, 28.320071],
          [62.515275, 28.320489],
          [62.514381, 28.321048],
          [62.513266, 28.321956],
          [62.512278, 28.322855],
          [62.510949, 28.324561],
          [62.510534, 28.325345],
          [62.510261, 28.325989],
          [62.509009, 28.330533],
          [62.50793, 28.334694],
          [62.507802, 28.335953],
          [62.507812, 28.337323],
          [62.507575, 28.33796],
          [62.507078, 28.338537],
          [62.505756, 28.340512],
          [62.505003, 28.341124],
          [62.503506, 28.341836],
          [62.502505, 28.342373],
          [62.501797, 28.342962],
          [62.501432, 28.343599],
          [62.500673, 28.345533],
          [62.50099, 28.346707],
          [62.501628, 28.348453],
          [62.502049, 28.350369],
          [62.50216, 28.351526],
          [62.502136, 28.35223],
          [62.501816, 28.353389],
          [62.501514, 28.354033],
          [62.501025, 28.354813],
          [62.500171, 28.355894],
          [62.499443, 28.356073],
          [62.497361, 28.356906],
          [62.495457, 28.358095],
          [62.49403, 28.35982],
          [62.493077, 28.362],
          [62.492781, 28.363093],
          [62.492414, 28.365169],
          [62.491394, 28.368088],
          [62.491204, 28.368849],
          [62.490915, 28.370422],
          [62.490497, 28.371382],
          [62.489723, 28.372353],
          [62.488771, 28.373405],
          [62.48755, 28.374481],
          [62.486364, 28.374873],
          [62.485161, 28.374602],
          [62.483887, 28.374651],
          [62.482243, 28.374791],
          [62.480294, 28.374832],
          [62.47919, 28.374811],
          [62.476992, 28.374583],
          [62.47405, 28.374976],
          [62.470733, 28.375543],
          [62.468047, 28.376449],
          [62.465315, 28.376924],
          [62.464246, 28.377313],
          [62.463069, 28.377888],
          [62.461401, 28.37921],
          [62.460104, 28.380414],
          [62.456874, 28.383216],
          [62.45524, 28.384134],
          [62.451196, 28.38601],
          [62.44959, 28.386809],
          [62.448528, 28.387537],
          [62.447557, 28.388508],
          [62.446421, 28.389184],
          [62.444997, 28.389806],
          [62.443374, 28.39058],
          [62.441868, 28.391185],
          [62.43815, 28.392851],
          [62.437345, 28.393601],
          [62.437117, 28.394127],
          [62.436265, 28.397899],
          [62.435792, 28.398245],
          [62.434533, 28.399326],
          [62.434111, 28.400357],
          [62.433686, 28.400966],
          [62.432411, 28.402304],
          [62.42899, 28.406329],
          [62.425597, 28.408494],
          [62.424149, 28.409276],
          [62.42311, 28.409679],
          [62.422557, 28.409978],
          [62.421009, 28.410979],
          [62.42069, 28.411229],
          [62.420048, 28.411985],
          [62.419333, 28.412636],
          [62.418876, 28.412966],
          [62.418397, 28.413394],
          [62.418219, 28.413854],
          [62.417619, 28.414646],
          [62.416186, 28.415717],
          [62.413605, 28.417847],
          [62.412212, 28.418816],
          [62.4117, 28.419128],
          [62.410335, 28.419703],
          [62.408554, 28.420354],
          [62.406597, 28.420936],
          [62.405393, 28.421789],
          [62.404735, 28.422482],
          [62.403375, 28.423106],
          [62.402182, 28.423567],
          [62.399697, 28.424906],
          [62.398625, 28.42555],
          [62.397698, 28.426235],
          [62.396171, 28.427599],
          [62.395259, 28.428203],
          [62.392468, 28.430296],
          [62.391584, 28.431109],
          [62.390737, 28.431661],
          [62.389486, 28.432248],
          [62.388535, 28.432674],
          [62.387444, 28.432795],
          [62.38272, 28.433163],
          [62.379238, 28.433563],
          [62.377348, 28.433593],
          [62.375253, 28.433448],
          [62.374385, 28.433276],
          [62.372474, 28.433058],
          [62.371553, 28.433036],
          [62.370788, 28.432947],
          [62.368743, 28.432955],
          [62.367094, 28.432846],
          [62.365724, 28.432672],
          [62.364724, 28.432675],
          [62.364648, 28.432636],
          [62.360936, 28.433338],
          [62.358875, 28.433615],
          [62.357255, 28.434133],
          [62.353621, 28.435078],
          [62.351979, 28.435611],
          [62.350832, 28.436066],
          [62.347666, 28.437858],
          [62.345674, 28.439184],
          [62.344219, 28.440257],
          [62.342999, 28.440828],
          [62.340998, 28.442061],
          [62.338566, 28.443849],
          [62.337564, 28.444471],
          [62.336526, 28.445186],
          [62.333577, 28.446839],
          [62.332989, 28.447051],
          [62.331777, 28.447592],
          [62.331485, 28.447818],
          [62.329873, 28.448799],
          [62.328281, 28.449457],
          [62.326656, 28.449972],
          [62.325617, 28.450176],
          [62.322742, 28.450531],
          [62.321343, 28.45037],
          [62.317101, 28.450737],
          [62.313348, 28.451349],
          [62.312341, 28.451347],
          [62.310777, 28.451442],
          [62.308265, 28.451748],
          [62.306408, 28.452201],
          [62.303503, 28.452255],
          [62.300752, 28.452415],
          [62.299733, 28.452539],
          [62.296997, 28.452754],
          [62.295025, 28.452826],
          [62.292576, 28.452526],
          [62.290482, 28.452138],
          [62.287141, 28.451347],
          [62.283371, 28.450818],
          [62.282794, 28.450635],
          [62.282088, 28.450552],
          [62.28036, 28.450526],
          [62.278664, 28.450731],
          [62.278008, 28.450887],
          [62.27528, 28.451979],
          [62.272558, 28.452709],
          [62.271792, 28.453064],
          [62.269754, 28.454077],
          [62.267163, 28.456013],
          [62.263423, 28.457902],
          [62.261329, 28.458727],
          [62.256908, 28.460274],
          [62.255491, 28.460759],
          [62.251802, 28.461915],
          [62.24976, 28.462663],
          [62.248672, 28.462938],
          [62.247747, 28.462925],
          [62.247106, 28.463015],
          [62.245601, 28.46335],
          [62.243481, 28.463218],
          [62.242213, 28.463025],
          [62.240997, 28.462964],
          [62.237906, 28.462464],
          [62.232107, 28.463136],
          [62.22741, 28.464056],
          [62.22489, 28.465],
          [62.224566, 28.465031],
          [62.22326, 28.46531],
          [62.222346, 28.465413],
          [62.221634, 28.465683],
          [62.217359, 28.467014],
          [62.211277, 28.468462],
          [62.208119, 28.469562],
          [62.207078, 28.469871],
          [62.203148, 28.472155],
          [62.199046, 28.475212],
          [62.197253, 28.476149],
          [62.196264, 28.476611],
          [62.19545, 28.477071],
          [62.193877, 28.478051],
          [62.191392, 28.479761],
          [62.187734, 28.48155],
          [62.179916, 28.484527],
          [62.176291, 28.485802],
          [62.173878, 28.486409],
          [62.171533, 28.486645],
          [62.168297, 28.488768],
          [62.167533, 28.488885],
          [62.158319, 28.489831],
          [62.152109, 28.490179],
          [62.141615, 28.492939],
          [62.141329, 28.493071],
          [62.140329, 28.493827],
          [62.139144, 28.494392],
          [62.137714, 28.49466],
          [62.137105, 28.494726],
          [62.133934, 28.494611],
          [62.131517, 28.494978],
          [62.128029, 28.49526],
          [62.126625, 28.495532],
          [62.124542, 28.496276],
          [62.119445, 28.496927],
          [62.118006, 28.497187],
          [62.114543, 28.497601],
          [62.109484, 28.49758],
          [62.107581, 28.497677],
          [62.103564, 28.499483],
          [62.099416, 28.500749],
          [62.095785, 28.502009],
          [62.092969, 28.503199],
          [62.090482, 28.504364],
          [62.089495, 28.50488],
          [62.088512, 28.505486],
          [62.08764, 28.50573],
          [62.08663, 28.505948],
          [62.085381, 28.505963],
          [62.083267, 28.506307],
          [62.082571, 28.506465],
          [62.080274, 28.50646],
          [62.076939, 28.506381],
          [62.074448, 28.506476],
          [62.071979, 28.506727],
          [62.069587, 28.506913],
          [62.06552, 28.507839],
          [62.062047, 28.508255],
          [62.057555, 28.508545],
          [62.056404, 28.508595],
          [62.053927, 28.508701],
          [62.051237, 28.509218],
          [62.04778, 28.510038],
          [62.045738, 28.509891],
          [62.043906, 28.510134],
          [62.041238, 28.511889],
          [62.039443, 28.513304],
          [62.037666, 28.514558],
          [62.036393, 28.515545],
          [62.035275, 28.516521],
          [62.034614, 28.517482],
          [62.033993, 28.518496],
          [62.033382, 28.518967],
          [62.028099, 28.521185],
          [62.026827, 28.521906],
          [62.021964, 28.524955],
          [62.021144, 28.525361],
          [62.020265, 28.525713],
          [62.018954, 28.526154],
          [62.018101, 28.526619],
          [62.017284, 28.527122],
          [62.016437, 28.527571],
          [62.015386, 28.528282],
          [62.014443, 28.528674],
          [62.013299, 28.52928],
          [62.012857, 28.529737],
          [62.012412, 28.53007],
          [62.011689, 28.530741],
          [62.010775, 28.531446],
          [62.009789, 28.532056],
          [62.007879, 28.533411],
          [62.005117, 28.534734],
          [62.003177, 28.535511],
          [62.001558, 28.535894],
          [61.998214, 28.536813],
          [61.996343, 28.537391],
          [61.994272, 28.538124],
          [61.992297, 28.539012],
          [61.988238, 28.541265],
          [61.986971, 28.541671],
          [61.985809, 28.5419],
          [61.982435, 28.542321],
          [61.98109, 28.542259],
          [61.979988, 28.542338],
          [61.978902, 28.542576],
          [61.977627, 28.542627],
          [61.975447, 28.5421],
          [61.973303, 28.54152],
          [61.972447, 28.54098],
          [61.97151, 28.539838],
          [61.970588, 28.539347],
          [61.970155, 28.5393],
          [61.968803, 28.539386],
          [61.966893, 28.539584],
          [61.965243, 28.539843],
          [61.963961, 28.539926],
          [61.963024, 28.539914],
          [61.962536, 28.540038],
          [61.961846, 28.540314],
          [61.960431, 28.540974],
          [61.959285, 28.54123],
          [61.959129, 28.541559],
          [61.958786, 28.543118],
          [61.958172, 28.544276],
          [61.957947, 28.544475],
          [61.957727, 28.54458],
          [61.956531, 28.544868],
          [61.954762, 28.545593],
          [61.954391, 28.545817],
          [61.953731, 28.546006],
          [61.952666, 28.546634],
          [61.952368, 28.546867],
          [61.950786, 28.547503],
          [61.950043, 28.548012],
          [61.949215, 28.548248],
          [61.948695, 28.548533],
          [61.947721, 28.548741],
          [61.946331, 28.549174],
          [61.942525, 28.55129],
          [61.942133, 28.551466],
          [61.941409, 28.551906],
          [61.940136, 28.552395],
          [61.938638, 28.552686],
          [61.937142, 28.553065],
          [61.934974, 28.553502],
          [61.934219, 28.553735],
          [61.933721, 28.553821],
          [61.931711, 28.554671],
          [61.930031, 28.554984],
          [61.929755, 28.555078],
          [61.928243, 28.555805],
          [61.927682, 28.556146],
          [61.927028, 28.556637],
          [61.925235, 28.557425],
          [61.924611, 28.557786],
          [61.924066, 28.558025],
          [61.923146, 28.558293],
          [61.922392, 28.559012],
          [61.920795, 28.559876],
          [61.92053, 28.559954],
          [61.91925, 28.560042],
          [61.917929, 28.560425],
          [61.916891, 28.560924],
          [61.916473, 28.561009],
          [61.916111, 28.561002],
          [61.915362, 28.56114],
          [61.914099, 28.561549],
          [61.913599, 28.561827],
          [61.913082, 28.562136],
          [61.911776, 28.563062],
          [61.911354, 28.563288],
          [61.910018, 28.564377],
          [61.908863, 28.565632],
          [61.90818, 28.566256],
          [61.906142, 28.568356],
          [61.90529, 28.569044],
          [61.904465, 28.570327],
          [61.903545, 28.571593],
          [61.902994, 28.572179],
          [61.902107, 28.57289],
          [61.898557, 28.574599],
          [61.897912, 28.574996],
          [61.897227, 28.575251],
          [61.896315, 28.576139],
          [61.89547, 28.576853],
          [61.894999, 28.577431],
          [61.894093, 28.577935],
          [61.893449, 28.578756],
          [61.89181, 28.579994],
          [61.89169, 28.580285],
          [61.891551, 28.581144],
          [61.891423, 28.581467],
          [61.890657, 28.58232],
          [61.89026, 28.582885],
          [61.889783, 28.583906],
          [61.889506, 28.584705],
          [61.88926, 28.585563],
          [61.889282, 28.586435],
          [61.889215, 28.587303],
          [61.888864, 28.588336],
          [61.888667, 28.588653],
          [61.888658, 28.588896],
          [61.888745, 28.58914],
          [61.888744, 28.58953],
          [61.888636, 28.58986],
          [61.888543, 28.589951],
          [61.888392, 28.590615],
          [61.888359, 28.59091],
          [61.888387, 28.591323],
          [61.88804, 28.5918],
          [61.888071, 28.592027],
          [61.888188, 28.592331],
          [61.887924, 28.593108],
          [61.88761, 28.593338],
          [61.887338, 28.593429],
          [61.88707, 28.593339],
          [61.886688, 28.59333],
          [61.886606, 28.593478],
          [61.886329, 28.593607],
          [61.886322, 28.593772],
          [61.886174, 28.593941],
          [61.885987, 28.593992],
          [61.885975, 28.594088],
          [61.885872, 28.594146],
          [61.885846, 28.594212],
          [61.885677, 28.594348],
          [61.885326, 28.594424],
          [61.885303, 28.594561],
          [61.885059, 28.594666],
          [61.884978, 28.594817],
          [61.884862, 28.59488],
          [61.884878, 28.595055],
          [61.884783, 28.595004],
          [61.884739, 28.595048],
          [61.884748, 28.595212],
          [61.884687, 28.595221],
          [61.884616, 28.595316],
          [61.884636, 28.595478],
          [61.884341, 28.59544],
          [61.884274, 28.595365],
          [61.884117, 28.595344],
          [61.883982, 28.595199],
          [61.883875, 28.595524],
          [61.883824, 28.595533],
          [61.883717, 28.595758],
          [61.883821, 28.595922],
          [61.883559, 28.596108],
          [61.883492, 28.596262],
          [61.88323, 28.596202],
          [61.883038, 28.596206],
          [61.882181, 28.596624],
          [61.881934, 28.596857],
          [61.881558, 28.596942],
          [61.881071, 28.59769],
          [61.880735, 28.598087],
          [61.880448, 28.598327],
          [61.879879, 28.598625],
          [61.879461, 28.598955],
          [61.87944, 28.599039],
          [61.879032, 28.599278],
          [61.87882, 28.599353],
          [61.878555, 28.599632],
          [61.878373, 28.599965],
          [61.878147, 28.600116],
          [61.877715, 28.60021],
          [61.877499, 28.600344],
          [61.877409, 28.600545],
          [61.877009, 28.600844],
          [61.876852, 28.601083],
          [61.876285, 28.601114],
          [61.87622, 28.601041],
          [61.876097, 28.601019],
          [61.87592, 28.601053],
          [61.875715, 28.601305],
          [61.874448, 28.601929],
          [61.874116, 28.602325],
          [61.873862, 28.602546],
          [61.873741, 28.602735],
          [61.873447, 28.602989],
          [61.873313, 28.603267],
          [61.872859, 28.603714],
          [61.872602, 28.60404],
          [61.872365, 28.604129],
          [61.871984, 28.604507],
          [61.87102, 28.60487],
          [61.870757, 28.60482],
          [61.870301, 28.604912],
          [61.869859, 28.604891],
          [61.869405, 28.605019],
          [61.869134, 28.605244],
          [61.868933, 28.60551],
          [61.868716, 28.605656],
          [61.868349, 28.606314],
          [61.868183, 28.6065],
          [61.868016, 28.606563],
          [61.86735, 28.606453],
          [61.86689, 28.606426],
          [61.866529, 28.606531],
          [61.866282, 28.60675],
          [61.865865, 28.606924],
          [61.865552, 28.607215],
          [61.865415, 28.60726],
          [61.865087, 28.607805],
          [61.864964, 28.607927],
          [61.86475, 28.60846],
          [61.864538, 28.608728],
          [61.864499, 28.609015],
          [61.864261, 28.609362],
          [61.864208, 28.609676],
          [61.864038, 28.609844],
          [61.863917, 28.610046],
          [61.863835, 28.610393],
          [61.863656, 28.610759],
          [61.863538, 28.611466],
          [61.863093, 28.612071],
          [61.862739, 28.612435],
          [61.862289, 28.612759],
          [61.862245, 28.612951],
          [61.861998, 28.613288],
          [61.861622, 28.613502],
          [61.861468, 28.613707],
          [61.860801, 28.614291],
          [61.860636, 28.614655],
          [61.860411, 28.614836],
          [61.85818, 28.617651],
          [61.857844, 28.617975],
          [61.857248, 28.618321],
          [61.856735, 28.618725],
          [61.855896, 28.619173],
          [61.855497, 28.619291],
          [61.854818, 28.619681],
          [61.85328, 28.620404],
          [61.852476, 28.621048],
          [61.851794, 28.621421],
          [61.851531, 28.621542],
          [61.851283, 28.621499],
          [61.850752, 28.621538],
          [61.8503, 28.621868],
          [61.849848, 28.621889],
          [61.849477, 28.621964],
          [61.849305, 28.622225],
          [61.848964, 28.622181],
          [61.848161, 28.621822],
          [61.847151, 28.621],
          [61.846716, 28.620849],
          [61.846379, 28.620933],
          [61.845932, 28.621225],
          [61.845456, 28.621465],
          [61.844892, 28.621992],
          [61.842851, 28.62312],
          [61.84253, 28.623088],
          [61.841788, 28.622784],
          [61.84114, 28.62307],
          [61.840173, 28.623314],
          [61.839248, 28.62384],
          [61.838505, 28.624172],
          [61.838241, 28.624322],
          [61.8378, 28.624696],
          [61.836924, 28.625106],
          [61.836279, 28.625477],
          [61.835902, 28.625745],
          [61.834495, 28.627148],
          [61.833286, 28.628247],
          [61.831688, 28.62936],
          [61.830792, 28.630251],
          [61.830022, 28.631124],
          [61.828974, 28.632657],
          [61.828787, 28.632792],
          [61.82733, 28.635435],
          [61.825693, 28.637794],
          [61.82522, 28.638443],
          [61.823894, 28.639571],
          [61.823506, 28.640124],
          [61.823077, 28.641293],
          [61.822548, 28.641659],
          [61.821372, 28.642108],
          [61.82086, 28.642207],
          [61.820293, 28.642531],
          [61.818675, 28.642833],
          [61.817496, 28.64338],
          [61.815809, 28.643934],
          [61.813445, 28.645429],
          [61.812306, 28.646367],
          [61.811721, 28.646952],
          [61.81086, 28.647567],
          [61.810131, 28.648321],
          [61.809063, 28.649074],
          [61.808613, 28.649707],
          [61.807329, 28.651795],
          [61.80527, 28.654843],
          [61.804762, 28.656278],
          [61.804051, 28.657783],
          [61.803214, 28.658718],
          [61.802114, 28.659814],
          [61.801463, 28.660639],
          [61.801515, 28.661218],
          [61.798956, 28.663657],
          [61.796609, 28.667074],
          [61.795008, 28.668316],
          [61.794351, 28.669087],
          [61.793628, 28.670742],
          [61.793157, 28.672016],
          [61.792685, 28.672617],
          [61.791767, 28.674291],
          [61.791069, 28.675622],
          [61.790565, 28.676767],
          [61.790036, 28.677416],
          [61.7893, 28.678061],
          [61.788763, 28.67876],
          [61.788079, 28.679929],
          [61.787305, 28.681035],
          [61.78578, 28.682643],
          [61.783705, 28.684358],
          [61.782683, 28.68585],
          [61.781369, 28.68702],
          [61.78019, 28.688872],
          [61.779412, 28.689353],
          [61.778491, 28.689795],
          [61.776531, 28.691547],
          [61.774763, 28.693575],
          [61.772445, 28.696026],
          [61.771064, 28.697677],
          [61.770147, 28.698878],
          [61.768948, 28.70075],
          [61.767206, 28.703089],
          [61.766106, 28.704012],
          [61.765827, 28.704246],
          [61.765175, 28.70511],
          [61.763441, 28.706287],
          [61.761007, 28.707492],
          [61.760283, 28.707791],
          [61.75965, 28.708146],
          [61.758105, 28.709357],
          [61.757276, 28.710495],
          [61.756893, 28.710756],
          [61.756349, 28.711453],
          [61.755515, 28.713246],
          [61.754998, 28.714139],
          [61.75364, 28.715636],
          [61.752115, 28.717051],
          [61.751215, 28.718052],
          [61.748758, 28.719435],
          [61.746361, 28.721165],
          [61.742488, 28.724903],
          [61.740208, 28.726737],
          [61.737465, 28.728963],
          [61.734119, 28.732146],
          [61.733649, 28.734002],
          [61.729511, 28.735896],
          [61.726282, 28.737996],
          [61.720732, 28.744681],
          [61.72007, 28.745159],
          [61.718508, 28.745956],
          [61.710297, 28.75219],
          [61.709923, 28.752471],
          [61.709399, 28.752921],
          [61.707528, 28.754092],
          [61.706287, 28.754542],
          [61.704822, 28.754964],
          [61.703603, 28.755452],
          [61.702246, 28.755808],
          [61.70154, 28.756089],
          [61.700567, 28.75637],
          [61.698332, 28.757176],
          [61.693713, 28.760504],
          [61.692344, 28.761413],
          [61.68579, 28.765893],
          [61.680433, 28.770252],
          [61.676413, 28.773729],
          [61.662893, 28.783828],
          [61.662127, 28.784658],
          [61.650954, 28.796902],
          [61.645572, 28.802927],
          [61.641584, 28.808447],
          [61.637598, 28.81423],
          [61.636148, 28.817046],
          [61.628486, 28.831046],
          [61.625727, 28.836867],
          [61.621527, 28.844981],
          [61.615819, 28.852576],
          [61.61075, 28.859087],
          [61.609372, 28.861627],
          [61.607915, 28.864482],
          [61.606351, 28.86635],
          [61.605341, 28.867765],
          [61.603861, 28.870003],
          [61.602083, 28.872999],
          [61.598882, 28.87921],
          [61.59857, 28.879684],
          [61.598002, 28.88073],
          [61.59462, 28.887653],
          [61.593198, 28.890455],
          [61.592543, 28.89107],
          [61.592181, 28.891471],
          [61.591953, 28.891831],
          [61.590967, 28.894047],
          [61.590309, 28.895082],
          [61.590033, 28.895632],
          [61.589641, 28.896929],
          [61.589478, 28.897266],
          [61.589262, 28.897537],
          [61.588797, 28.897967],
          [61.588317, 28.898616],
          [61.588003, 28.899259],
          [61.587813, 28.899904],
          [61.587627, 28.901171],
          [61.587425, 28.901905],
          [61.586846, 28.903317],
          [61.586253, 28.904154],
          [61.585928, 28.904456],
          [61.584988, 28.904899],
          [61.58476, 28.90509],
          [61.584584, 28.905541],
          [61.584534, 28.906324],
          [61.584421, 28.906938],
          [61.584211, 28.907638],
          [61.582117, 28.90932],
          [61.581416, 28.909821],
          [61.580724, 28.910216],
          [61.578897, 28.911682],
          [61.578014, 28.912605],
          [61.576976, 28.914044],
          [61.576158, 28.915369],
          [61.576034, 28.915793],
          [61.575985, 28.916291],
          [61.576096, 28.917157],
          [61.57606, 28.919999],
          [61.575979, 28.92027],
          [61.575756, 28.920629],
          [61.57509, 28.921485],
          [61.57471, 28.921889],
          [61.574418, 28.922313],
          [61.574176, 28.922873],
          [61.57575, 28.928],
          [61.570953, 28.937308],
          [61.533361, 29.010222],
          [61.478861, 29.023639],
          [61.491773, 29.041135],
          [61.491818, 29.041459],
          [61.491948, 29.041585],
          [61.491929, 29.041688],
          [61.492125, 29.041829],
          [61.492144, 29.042024],
          [61.492261, 29.042061],
          [61.492356, 29.042246],
          [61.492347, 29.04235],
          [61.492227, 29.042514],
          [61.492194, 29.042736],
          [61.492271, 29.043148],
          [61.492355, 29.043247],
          [61.492461, 29.043279],
          [61.492442, 29.043354],
          [61.492532, 29.043612],
          [61.492355, 29.043661],
          [61.492318, 29.043792],
          [61.492356, 29.043832],
          [61.492703, 29.04392],
          [61.49291, 29.044094],
          [61.493479, 29.043825],
          [61.493837, 29.04374],
          [61.493905, 29.043787],
          [61.494164, 29.043767],
          [61.494197, 29.043683],
          [61.494353, 29.043647],
          [61.494495, 29.043527],
          [61.494576, 29.043542],
          [61.49472, 29.043458],
          [61.494949, 29.043518],
          [61.495028, 29.043469],
          [61.49524, 29.043485],
          [61.495479, 29.04341],
          [61.495507, 29.043463],
          [61.495657, 29.043477],
          [61.495857, 29.043622],
          [61.495839, 29.043714],
          [61.495912, 29.043802],
          [61.496124, 29.04387],
          [61.496313, 29.043838],
          [61.496371, 29.043885],
          [61.496582, 29.043912],
          [61.496722, 29.043998],
          [61.496791, 29.044001],
          [61.496853, 29.0439],
          [61.496951, 29.044013],
          [61.497297, 29.044184],
          [61.497491, 29.044383],
          [61.497442, 29.0445],
          [61.497522, 29.044641],
          [61.49775, 29.044787],
          [61.498045, 29.0448],
          [61.498307, 29.044699],
          [61.49855, 29.0447],
          [61.498761, 29.04478],
          [61.499011, 29.044808],
          [61.499277, 29.044667],
          [61.499365, 29.044679],
          [61.499479, 29.044486],
          [61.49987, 29.044478],
          [61.500041, 29.044532],
          [61.500165, 29.044637],
          [61.500264, 29.044593],
          [61.500759, 29.044584],
          [61.501281, 29.044481],
          [61.501554, 29.044349],
          [61.501652, 29.044242],
          [61.5023, 29.044401],
          [61.502568, 29.044303],
          [61.50281, 29.044278],
          [61.503047, 29.04433],
          [61.503183, 29.044303],
          [61.503316, 29.044178],
          [61.5034, 29.044164],
          [61.503399, 29.044004],
          [61.503578, 29.043955],
          [61.503707, 29.043833],
          [61.504006, 29.043915],
          [61.504343, 29.043688],
          [61.504431, 29.043652],
          [61.50452, 29.043673],
          [61.504559, 29.043434],
          [61.504984, 29.043264],
          [61.505247, 29.043016],
          [61.505914, 29.043113],
          [61.506005, 29.043247],
          [61.506158, 29.0432],
          [61.50626, 29.043271],
          [61.50635, 29.043002],
          [61.506571, 29.043083],
          [61.506753, 29.043091],
          [61.506836, 29.043044],
          [61.506906, 29.043155],
          [61.506907, 29.043264],
          [61.506835, 29.043389],
          [61.506917, 29.043588],
          [61.506835, 29.043855],
          [61.507092, 29.044044],
          [61.507145, 29.044135],
          [61.507217, 29.044124],
          [61.507278, 29.044195],
          [61.507138, 29.044402],
          [61.506985, 29.044517],
          [61.506993, 29.044623],
          [61.506828, 29.044752],
          [61.506859, 29.044895],
          [61.506923, 29.044918],
          [61.507065, 29.045116],
          [61.507058, 29.045322],
          [61.507266, 29.045176],
          [61.507437, 29.045114],
          [61.507625, 29.045102],
          [61.507734, 29.045141],
          [61.507873, 29.045541],
          [61.507749, 29.046035],
          [61.507859, 29.046374],
          [61.507809, 29.046412],
          [61.507763, 29.046727],
          [61.507947, 29.046906],
          [61.508335, 29.047149],
          [61.508372, 29.047263],
          [61.508624, 29.047304],
          [61.508691, 29.047426],
          [61.508919, 29.047298],
          [61.50902, 29.04732],
          [61.509124, 29.047429],
          [61.509308, 29.047431],
          [61.509361, 29.047468],
          [61.509591, 29.047778],
          [61.509604, 29.04791],
          [61.509593, 29.047989],
          [61.5094, 29.048115],
          [61.50992, 29.048005],
          [61.510054, 29.048045],
          [61.510319, 29.04796],
          [61.510541, 29.047808],
          [61.510684, 29.04778],
          [61.510784, 29.047887],
          [61.511008, 29.048],
          [61.511095, 29.048245],
          [61.511348, 29.048443],
          [61.511472, 29.04848],
          [61.511324, 29.048942],
          [61.511183, 29.04914],
          [61.511226, 29.04935],
          [61.510918, 29.049424],
          [61.510908, 29.04955],
          [61.510628, 29.049694],
          [61.510301, 29.050095],
          [61.509953, 29.050252],
          [61.509772, 29.050977],
          [61.509673, 29.051057],
          [61.509669, 29.051182],
          [61.509108, 29.051576],
          [61.508908, 29.051859],
          [61.508892, 29.052035],
          [61.5087, 29.05212],
          [61.508635, 29.052378],
          [61.508657, 29.052705],
          [61.508498, 29.05291],
          [61.508409, 29.052941],
          [61.507827, 29.052849],
          [61.507723, 29.052884],
          [61.507564, 29.052752],
          [61.50747, 29.052752],
          [61.507372, 29.052677],
          [61.507191, 29.052743],
          [61.506936, 29.052769],
          [61.506822, 29.052733],
          [61.506528, 29.052987],
          [61.506399, 29.05307],
          [61.506226, 29.053104],
          [61.506029, 29.053281],
          [61.505668, 29.053499],
          [61.505552, 29.053431],
          [61.505319, 29.053625],
          [61.5051, 29.053908],
          [61.505086, 29.054005],
          [61.505002, 29.054032],
          [61.504903, 29.054173],
          [61.504911, 29.05426],
          [61.504852, 29.054297],
          [61.504871, 29.054389],
          [61.504548, 29.054911],
          [61.504523, 29.055029],
          [61.504374, 29.055055],
          [61.504287, 29.055161],
          [61.504095, 29.055201],
          [61.504066, 29.05529],
          [61.503966, 29.055337],
          [61.503963, 29.055481],
          [61.503854, 29.055469],
          [61.503631, 29.055822],
          [61.503742, 29.055985],
          [61.50369, 29.05618],
          [61.503712, 29.0567],
          [61.503882, 29.057058],
          [61.503789, 29.057225],
          [61.503724, 29.057202],
          [61.503661, 29.057639],
          [61.503835, 29.05761],
          [61.504272, 29.057359],
          [61.504481, 29.057309],
          [61.504717, 29.057527],
          [61.505055, 29.057563],
          [61.505321, 29.057684],
          [61.505412, 29.057831],
          [61.505577, 29.05784],
          [61.505782, 29.058009],
          [61.506025, 29.058022],
          [61.506084, 29.057955],
          [61.50632, 29.058019],
          [61.506422, 29.057966],
          [61.506785, 29.05862],
          [61.506921, 29.058601],
          [61.507096, 29.058748],
          [61.507214, 29.059224],
          [61.507165, 29.059358],
          [61.507606, 29.059949],
          [61.507688, 29.060228],
          [61.507417, 29.060728],
          [61.507182, 29.061034],
          [61.50732, 29.061117],
          [61.507332, 29.061319],
          [61.50738, 29.06135],
          [61.507259, 29.061686],
          [61.507147, 29.061854],
          [61.506911, 29.061983],
          [61.506853, 29.062003],
          [61.506728, 29.061945],
          [61.506435, 29.062099],
          [61.506317, 29.062291],
          [61.505838, 29.062727],
          [61.50577, 29.062961],
          [61.505662, 29.063005],
          [61.505666, 29.063098],
          [61.505783, 29.063196],
          [61.505712, 29.06356],
          [61.505642, 29.063667],
          [61.505264, 29.063852],
          [61.505148, 29.064002],
          [61.504724, 29.06432],
          [61.504845, 29.064398],
          [61.504666, 29.064839],
          [61.504707, 29.064982],
          [61.504651, 29.065115],
          [61.504673, 29.065372],
          [61.504601, 29.065626],
          [61.504377, 29.065822],
          [61.504205, 29.065853],
          [61.504179, 29.06598],
          [61.503727, 29.0664],
          [61.503731, 29.066479],
          [61.50361, 29.066533],
          [61.503535, 29.066672],
          [61.503087, 29.066761],
          [61.503025, 29.066619],
          [61.502769, 29.066459],
          [61.502653, 29.066262],
          [61.502353, 29.066294],
          [61.502203, 29.066185],
          [61.501727, 29.066285],
          [61.501308, 29.066211],
          [61.501217, 29.066168],
          [61.501206, 29.066109],
          [61.501026, 29.066073],
          [61.500818, 29.066137],
          [61.500627, 29.066326],
          [61.500477, 29.066284],
          [61.500589, 29.066504],
          [61.50048, 29.066735],
          [61.500558, 29.06685],
          [61.500756, 29.066951],
          [61.500728, 29.066976],
          [61.500646, 29.066959],
          [61.500031, 29.067282],
          [61.499948, 29.067287],
          [61.499772, 29.067404],
          [61.49966, 29.067586],
          [61.499471, 29.067745],
          [61.499138, 29.067935],
          [61.499082, 29.068009],
          [61.498749, 29.06815],
          [61.49798, 29.068674],
          [61.49768, 29.06881],
          [61.497315, 29.068841],
          [61.497177, 29.068926],
          [61.497181, 29.069044],
          [61.497045, 29.069217],
          [61.496792, 29.069209],
          [61.49666, 29.069141],
          [61.496446, 29.068988],
          [61.496368, 29.068857],
          [61.49609, 29.068992],
          [61.495903, 29.069145],
          [61.495206, 29.069037],
          [61.495077, 29.069082],
          [61.494937, 29.069057],
          [61.494674, 29.069153],
          [61.494573, 29.069377],
          [61.494339, 29.069641],
          [61.494265, 29.069879],
          [61.494157, 29.069908],
          [61.494116, 29.070228],
          [61.493972, 29.070546],
          [61.493897, 29.07058],
          [61.493639, 29.070479],
          [61.493644, 29.070591],
          [61.493455, 29.070874],
          [61.493411, 29.071169],
          [61.493338, 29.071189],
          [61.493191, 29.07134],
          [61.49297, 29.071298],
          [61.492374, 29.071584],
          [61.492334, 29.071666],
          [61.49191, 29.072017],
          [61.491598, 29.072181],
          [61.491406, 29.072147],
          [61.490952, 29.072248],
          [61.490705, 29.07201],
          [61.490549, 29.072008],
          [61.490178, 29.071872],
          [61.490007, 29.071925],
          [61.489857, 29.071841],
          [61.489582, 29.07189],
          [61.489047, 29.071745],
          [61.48888, 29.07185],
          [61.488881, 29.07175],
          [61.488773, 29.071702],
          [61.488724, 29.071563],
          [61.488548, 29.071606],
          [61.488557, 29.071514],
          [61.488388, 29.071384],
          [61.488333, 29.071268],
          [61.488174, 29.071228],
          [61.487767, 29.071565],
          [61.487658, 29.071768],
          [61.487403, 29.071885],
          [61.487248, 29.07204],
          [61.486292, 29.072471],
          [61.486136, 29.072507],
          [61.485876, 29.072473],
          [61.485778, 29.072545],
          [61.485585, 29.07271],
          [61.485538, 29.072851],
          [61.485439, 29.072929],
          [61.485465, 29.073153],
          [61.485418, 29.073269],
          [61.485355, 29.073265],
          [61.485033, 29.073434],
          [61.484909, 29.073436],
          [61.484894, 29.073541],
          [61.484364, 29.074349],
          [61.484213, 29.074417],
          [61.484012, 29.074677],
          [61.484067, 29.074882],
          [61.483972, 29.075005],
          [61.484033, 29.075321],
          [61.483804, 29.075402],
          [61.483691, 29.07559],
          [61.483294, 29.075853],
          [61.482982, 29.075912],
          [61.48295, 29.075957],
          [61.482529, 29.076106],
          [61.482355, 29.076094],
          [61.482307, 29.076255],
          [61.48188, 29.076411],
          [61.481834, 29.076547],
          [61.481687, 29.076661],
          [61.481478, 29.076686],
          [61.481288, 29.077057],
          [61.480752, 29.07732],
          [61.480653, 29.077323],
          [61.480628, 29.077606],
          [61.480389, 29.078161],
          [61.480156, 29.078433],
          [61.480118, 29.078556],
          [61.480146, 29.078647],
          [61.479863, 29.078932],
          [61.480103, 29.079102],
          [61.480229, 29.079135],
          [61.480448, 29.07937],
          [61.480575, 29.079433],
          [61.480707, 29.079661],
          [61.480738, 29.079878],
          [61.48116, 29.080095],
          [61.481386, 29.080494],
          [61.481641, 29.080466],
          [61.48189, 29.080676],
          [61.481987, 29.08069],
          [61.482279, 29.08104],
          [61.48246, 29.081054],
          [61.482553, 29.081143],
          [61.482404, 29.081324],
          [61.482316, 29.081631],
          [61.482234, 29.081675],
          [61.482178, 29.081821],
          [61.48193, 29.081961],
          [61.481825, 29.082238],
          [61.481764, 29.0826],
          [61.481571, 29.082986],
          [61.481554, 29.08311],
          [61.481273, 29.083516],
          [61.481236, 29.083854],
          [61.481101, 29.084068],
          [61.480894, 29.084109],
          [61.480718, 29.084257],
          [61.480759, 29.084348],
          [61.480343, 29.084571],
          [61.480297, 29.08482],
          [61.480326, 29.084927],
          [61.480159, 29.085175],
          [61.479942, 29.085372],
          [61.479914, 29.085496],
          [61.479669, 29.085655],
          [61.479336, 29.085693],
          [61.47923, 29.085808],
          [61.478943, 29.085865],
          [61.478991, 29.08596],
          [61.478889, 29.08603],
          [61.478652, 29.086027],
          [61.478454, 29.086143],
          [61.478424, 29.086226],
          [61.478215, 29.08643],
          [61.477906, 29.086604],
          [61.477623, 29.086693],
          [61.477553, 29.086996],
          [61.477299, 29.087204],
          [61.477171, 29.087436],
          [61.476917, 29.08758],
          [61.476817, 29.087564],
          [61.476339, 29.087749],
          [61.47625, 29.088111],
          [61.476277, 29.088266],
          [61.476146, 29.088377],
          [61.476117, 29.088501],
          [61.475966, 29.088688],
          [61.475893, 29.088631],
          [61.475646, 29.088638],
          [61.475317, 29.088767],
          [61.47502, 29.088721],
          [61.475051, 29.088786],
          [61.47493, 29.089006],
          [61.474918, 29.089124],
          [61.474756, 29.089288],
          [61.474606, 29.089312],
          [61.474452, 29.089522],
          [61.474053, 29.089659],
          [61.473639, 29.089914],
          [61.473562, 29.089891],
          [61.473529, 29.089925],
          [61.47321, 29.089952],
          [61.473027, 29.089812],
          [61.472942, 29.089834],
          [61.472917, 29.089781],
          [61.472717, 29.089699],
          [61.472591, 29.089796],
          [61.47258, 29.089853],
          [61.472342, 29.089911],
          [61.472263, 29.090045],
          [61.471929, 29.090282],
          [61.471754, 29.090331],
          [61.471608, 29.090734],
          [61.471443, 29.090872],
          [61.471341, 29.091096],
          [61.471401, 29.091189],
          [61.471454, 29.091614],
          [61.471392, 29.091769],
          [61.471616, 29.091797],
          [61.471557, 29.091963],
          [61.471594, 29.092066],
          [61.471766, 29.092214],
          [61.471885, 29.092163],
          [61.47196, 29.092192],
          [61.472191, 29.092146],
          [61.47233, 29.092207],
          [61.472589, 29.0922],
          [61.473208, 29.092392],
          [61.473261, 29.092564],
          [61.473564, 29.092763],
          [61.473597, 29.092949],
          [61.473853, 29.09329],
          [61.474224, 29.092974],
          [61.474408, 29.093429],
          [61.47467, 29.093614],
          [61.475379, 29.093976],
          [61.475513, 29.094274],
          [61.475918, 29.094459],
          [61.476009, 29.094551],
          [61.476038, 29.0947],
          [61.476178, 29.094739],
          [61.476355, 29.09493],
          [61.476448, 29.095137],
          [61.476704, 29.095269],
          [61.476699, 29.09543],
          [61.476528, 29.095623],
          [61.476205, 29.096167],
          [61.475968, 29.096363],
          [61.475918, 29.09656],
          [61.475822, 29.09656],
          [61.475522, 29.096787],
          [61.475264, 29.097017],
          [61.475075, 29.097344],
          [61.474985, 29.097345],
          [61.474873, 29.097469],
          [61.474794, 29.097686],
          [61.475017, 29.097965],
          [61.475252, 29.098141],
          [61.475509, 29.098474],
          [61.475895, 29.098695],
          [61.475992, 29.098814],
          [61.476153, 29.09881],
          [61.476633, 29.099035],
          [61.47679, 29.099161],
          [61.476985, 29.099142],
          [61.47697, 29.09907],
          [61.477079, 29.098952],
          [61.477619, 29.098975],
          [61.47795, 29.098866],
          [61.478015, 29.098894],
          [61.478214, 29.09929],
          [61.47823, 29.099422],
          [61.478361, 29.099567],
          [61.478455, 29.099904],
          [61.478835, 29.099975],
          [61.479305, 29.09986],
          [61.479342, 29.099938],
          [61.479733, 29.10028],
          [61.479916, 29.100539],
          [61.480228, 29.100761],
          [61.480108, 29.101119],
          [61.480008, 29.101152],
          [61.479626, 29.101503],
          [61.479617, 29.101593],
          [61.479522, 29.101717],
          [61.479473, 29.101704],
          [61.479236, 29.101933],
          [61.479105, 29.101995],
          [61.479011, 29.102146],
          [61.478642, 29.102472],
          [61.478203, 29.102636],
          [61.478065, 29.102851],
          [61.478064, 29.102961],
          [61.477941, 29.103069],
          [61.477947, 29.103175],
          [61.477873, 29.103164],
          [61.477838, 29.103312],
          [61.477341, 29.103843],
          [61.476504, 29.104331],
          [61.476255, 29.104377],
          [61.476122, 29.104459],
          [61.476094, 29.10442],
          [61.476004, 29.104432],
          [61.475752, 29.104576],
          [61.475629, 29.104721],
          [61.475918, 29.104874],
          [61.475874, 29.105208],
          [61.475748, 29.105461],
          [61.47518, 29.105927],
          [61.475239, 29.105992],
          [61.475244, 29.10622],
          [61.475124, 29.106365],
          [61.474978, 29.10638],
          [61.474753, 29.10654],
          [61.474549, 29.106545],
          [61.474299, 29.106809],
          [61.47416, 29.106839],
          [61.47406, 29.106928],
          [61.474005, 29.106878],
          [61.473686, 29.107178],
          [61.473456, 29.107262],
          [61.473693, 29.107375],
          [61.474029, 29.107651],
          [61.474139, 29.107701],
          [61.474412, 29.107702],
          [61.474717, 29.107846],
          [61.474926, 29.10789],
          [61.474941, 29.108003],
          [61.475399, 29.108188],
          [61.475507, 29.108291],
          [61.475443, 29.108551],
          [61.475862, 29.108549],
          [61.475963, 29.108749],
          [61.476449, 29.109053],
          [61.476501, 29.109307],
          [61.476602, 29.109389],
          [61.476867, 29.109395],
          [61.476995, 29.109499],
          [61.477165, 29.109523],
          [61.477348, 29.109738],
          [61.477287, 29.109847],
          [61.477318, 29.109887],
          [61.476911, 29.110312],
          [61.476826, 29.110732],
          [61.476551, 29.111009],
          [61.476089, 29.111337],
          [61.475926, 29.111588],
          [61.475628, 29.111743],
          [61.475595, 29.111865],
          [61.475529, 29.1119],
          [61.475517, 29.111994],
          [61.475391, 29.112151],
          [61.475182, 29.112214],
          [61.474792, 29.112647],
          [61.474684, 29.112842],
          [61.474616, 29.112869],
          [61.474525, 29.113097],
          [61.47459, 29.113172],
          [61.47447, 29.113295],
          [61.474417, 29.113396],
          [61.474455, 29.113452],
          [61.474373, 29.113562],
          [61.474372, 29.113816],
          [61.474307, 29.113895],
          [61.474311, 29.114015],
          [61.474432, 29.114102],
          [61.474459, 29.114188],
          [61.474642, 29.114306],
          [61.474853, 29.114264],
          [61.4749, 29.114304],
          [61.474941, 29.114784],
          [61.475078, 29.114967],
          [61.475203, 29.114969],
          [61.475171, 29.115067],
          [61.475525, 29.115413],
          [61.475603, 29.115828],
          [61.475892, 29.116192],
          [61.475841, 29.116508],
          [61.47588, 29.116589],
          [61.475685, 29.116867],
          [61.475342, 29.117117],
          [61.475359, 29.117626],
          [61.475017, 29.117793],
          [61.474388, 29.117995],
          [61.474199, 29.118423],
          [61.474318, 29.118439],
          [61.474608, 29.118726],
          [61.474708, 29.118935],
          [61.474872, 29.118947],
          [61.475005, 29.119149],
          [61.47535, 29.119329],
          [61.475768, 29.119671],
          [61.476211, 29.119782],
          [61.476213, 29.119828],
          [61.476124, 29.120042],
          [61.476209, 29.120146],
          [61.476133, 29.120244],
          [61.476062, 29.120233],
          [61.476129, 29.120372],
          [61.475829, 29.120767],
          [61.475808, 29.121206],
          [61.475668, 29.121586],
          [61.475619, 29.121674],
          [61.47516, 29.122043],
          [61.474903, 29.122399],
          [61.474769, 29.122501],
          [61.474617, 29.122752],
          [61.474539, 29.123219],
          [61.47438, 29.123348],
          [61.474371, 29.123403],
          [61.473996, 29.12348],
          [61.473995, 29.123245],
          [61.473675, 29.12314],
          [61.473386, 29.1233],
          [61.473395, 29.12343],
          [61.47334, 29.123559],
          [61.473225, 29.123607],
          [61.473054, 29.123411],
          [61.472865, 29.123544],
          [61.472849, 29.123637],
          [61.472952, 29.123672],
          [61.472796, 29.123866],
          [61.472544, 29.124005],
          [61.471691, 29.123597],
          [61.471271, 29.122851],
          [61.47114, 29.122782],
          [61.471367, 29.122485],
          [61.471306, 29.12234],
          [61.471023, 29.12206],
          [61.47081, 29.122068],
          [61.47082, 29.121982],
          [61.470489, 29.121947],
          [61.470152, 29.121705],
          [61.469763, 29.121545],
          [61.469545, 29.12172],
          [61.469304, 29.121776],
          [61.468944, 29.122062],
          [61.468785, 29.122088],
          [61.468291, 29.121748],
          [61.467882, 29.121813],
          [61.467174, 29.121717],
          [61.46649, 29.121825],
          [61.465944, 29.122249],
          [61.465571, 29.122415],
          [61.465445, 29.122538],
          [61.465416, 29.122656],
          [61.465206, 29.12272],
          [61.465119, 29.122793],
          [61.464915, 29.122791],
          [61.464928, 29.12287],
          [61.464818, 29.122942],
          [61.464786, 29.123032],
          [61.464543, 29.123061],
          [61.464167, 29.123472],
          [61.464006, 29.123543],
          [61.463335, 29.124023],
          [61.463067, 29.124031],
          [61.46287, 29.124216],
          [61.462521, 29.12431],
          [61.46221, 29.12427],
          [61.462067, 29.124405],
          [61.461977, 29.124423],
          [61.461971, 29.124491],
          [61.461782, 29.12454],
          [61.461569, 29.124523],
          [61.461423, 29.124566],
          [61.461365, 29.124493],
          [61.461253, 29.124516],
          [61.461114, 29.124706],
          [61.460857, 29.124549],
          [61.46075, 29.12455],
          [61.460638, 29.124679],
          [61.460503, 29.12474],
          [61.460472, 29.124875],
          [61.460379, 29.124988],
          [61.460254, 29.125052],
          [61.46019, 29.125177],
          [61.459852, 29.125171],
          [61.459726, 29.125017],
          [61.459699, 29.124877],
          [61.459226, 29.124729],
          [61.459205, 29.12433],
          [61.458998, 29.124046],
          [61.458435, 29.123659],
          [61.457923, 29.123508],
          [61.457857, 29.123378],
          [61.457541, 29.123634],
          [61.457561, 29.123742],
          [61.457525, 29.123777],
          [61.457152, 29.123966],
          [61.457081, 29.124181],
          [61.457113, 29.124279],
          [61.45685, 29.124249],
          [61.456874, 29.124441],
          [61.456582, 29.124888],
          [61.456522, 29.125286],
          [61.455905, 29.125896],
          [61.455618, 29.126048],
          [61.455335, 29.126294],
          [61.455192, 29.12658],
          [61.454426, 29.127482],
          [61.454263, 29.127632],
          [61.454018, 29.127748],
          [61.454015, 29.127866],
          [61.45391, 29.127906],
          [61.453847, 29.128025],
          [61.453493, 29.128123],
          [61.453416, 29.12829],
          [61.453142, 29.128338],
          [61.452716, 29.128543],
          [61.452539, 29.128556],
          [61.452352, 29.129019],
          [61.452187, 29.129177],
          [61.451651, 29.129964],
          [61.451554, 29.130022],
          [61.451337, 29.130292],
          [61.451235, 29.130553],
          [61.451084, 29.130692],
          [61.450892, 29.131052],
          [61.450778, 29.131092],
          [61.450815, 29.131208],
          [61.450713, 29.131376],
          [61.450851, 29.131512],
          [61.450538, 29.131702],
          [61.450304, 29.131771],
          [61.45018, 29.131874],
          [61.449963, 29.131883],
          [61.449581, 29.131808],
          [61.44939, 29.1317],
          [61.449009, 29.13129],
          [61.448727, 29.131091],
          [61.448595, 29.131162],
          [61.448453, 29.131151],
          [61.447919, 29.131531],
          [61.447763, 29.131422],
          [61.44763, 29.13125],
          [61.44758, 29.131279],
          [61.447288, 29.13116],
          [61.447085, 29.131001],
          [61.446883, 29.130977],
          [61.446723, 29.131157],
          [61.446644, 29.131341],
          [61.446472, 29.131406],
          [61.446371, 29.131381],
          [61.446205, 29.13143],
          [61.446092, 29.131369],
          [61.445738, 29.131399],
          [61.445315, 29.131183],
          [61.445054, 29.131126],
          [61.444887, 29.131195],
          [61.444746, 29.13115],
          [61.444566, 29.131178],
          [61.444002, 29.131522],
          [61.443823, 29.131411],
          [61.4437, 29.131427],
          [61.443562, 29.131382],
          [61.443476, 29.131287],
          [61.443282, 29.131364],
          [61.443002, 29.131319],
          [61.442699, 29.131141],
          [61.442487, 29.131233],
          [61.442331, 29.131393],
          [61.442247, 29.131317],
          [61.441975, 29.131228],
          [61.441745, 29.131038],
          [61.441624, 29.131048],
          [61.441647, 29.130948],
          [61.441522, 29.130889],
          [61.441241, 29.13093],
          [61.440679, 29.130548],
          [61.440504, 29.130533],
          [61.440371, 29.130637],
          [61.43999, 29.130634],
          [61.43949, 29.130532],
          [61.439235, 29.130397],
          [61.438776, 29.130359],
          [61.438619, 29.130497],
          [61.438501, 29.130217],
          [61.438321, 29.13013],
          [61.438296, 29.129866],
          [61.437877, 29.129366],
          [61.438501, 29.129114],
          [61.438707, 29.128939],
          [61.438774, 29.128824],
          [61.438926, 29.128772],
          [61.438888, 29.128455],
          [61.438746, 29.128365],
          [61.438673, 29.128379],
          [61.438523, 29.128544],
          [61.438454, 29.128546],
          [61.437988, 29.128274],
          [61.437777, 29.128215],
          [61.437253, 29.127775],
          [61.436795, 29.127927],
          [61.436486, 29.12796],
          [61.436294, 29.127769],
          [61.435794, 29.127694],
          [61.435233, 29.127732],
          [61.434982, 29.128062],
          [61.43481, 29.128163],
          [61.434356, 29.128331],
          [61.434076, 29.128297],
          [61.433946, 29.128418],
          [61.433781, 29.128445],
          [61.433704, 29.128124],
          [61.433438, 29.127678],
          [61.433248, 29.127626],
          [61.433181, 29.127454],
          [61.43278, 29.127422],
          [61.432811, 29.127287],
          [61.432661, 29.127216],
          [61.432494, 29.127232],
          [61.43237, 29.127167],
          [61.432201, 29.127321],
          [61.432093, 29.127266],
          [61.431857, 29.127433],
          [61.43167, 29.127484],
          [61.431471, 29.127672],
          [61.431416, 29.127638],
          [61.43126, 29.127692],
          [61.43098, 29.128005],
          [61.430912, 29.128181],
          [61.430869, 29.128161],
          [61.430536, 29.128331],
          [61.430449, 29.128563],
          [61.430415, 29.128539],
          [61.430283, 29.128585],
          [61.430188, 29.128716],
          [61.430113, 29.128666],
          [61.429981, 29.128726],
          [61.429411, 29.128794],
          [61.429164, 29.128941],
          [61.428961, 29.12881],
          [61.428817, 29.128631],
          [61.428614, 29.128565],
          [61.428626, 29.128484],
          [61.428462, 29.128396],
          [61.428416, 29.12819],
          [61.428307, 29.12799],
          [61.427906, 29.127943],
          [61.427642, 29.128071],
          [61.427226, 29.12845],
          [61.427011, 29.12849],
          [61.42653, 29.128812],
          [61.426444, 29.12923],
          [61.426295, 29.12927],
          [61.426133, 29.129453],
          [61.425888, 29.12953],
          [61.425669, 29.129764],
          [61.42558, 29.129776],
          [61.425582, 29.129837],
          [61.425366, 29.129981],
          [61.425298, 29.130117],
          [61.424794, 29.13062],
          [61.424889, 29.130856],
          [61.424767, 29.131102],
          [61.42501, 29.131241],
          [61.425128, 29.1314],
          [61.425033, 29.131469],
          [61.425272, 29.131657],
          [61.425663, 29.131857],
          [61.425715, 29.131931],
          [61.425701, 29.1322],
          [61.42586, 29.132309],
          [61.425848, 29.132389],
          [61.426437, 29.132716],
          [61.426369, 29.132866],
          [61.426395, 29.132937],
          [61.426278, 29.133097],
          [61.426576, 29.133385],
          [61.426516, 29.133742],
          [61.426418, 29.133859],
          [61.426253, 29.13394],
          [61.426008, 29.134433],
          [61.425906, 29.13454],
          [61.425816, 29.13449],
          [61.425739, 29.134596],
          [61.425584, 29.134688],
          [61.42541, 29.134706],
          [61.425346, 29.134937],
          [61.425065, 29.135152],
          [61.424955, 29.135404],
          [61.424249, 29.135118],
          [61.424035, 29.135104],
          [61.423779, 29.135236],
          [61.42353, 29.135268],
          [61.423485, 29.135479],
          [61.423143, 29.135611],
          [61.422832, 29.135437],
          [61.42209, 29.134861],
          [61.421586, 29.13498],
          [61.421384, 29.13518],
          [61.421022, 29.1354],
          [61.420823, 29.135652],
          [61.420823, 29.136022],
          [61.420752, 29.136097],
          [61.420775, 29.13618],
          [61.420629, 29.136349],
          [61.420609, 29.136515],
          [61.420523, 29.136566],
          [61.420523, 29.136725],
          [61.420163, 29.137166],
          [61.420129, 29.137445],
          [61.420241, 29.137608],
          [61.420216, 29.137725],
          [61.420128, 29.137731],
          [61.420032, 29.137845],
          [61.419745, 29.137958],
          [61.419657, 29.13804],
          [61.419636, 29.138254],
          [61.419561, 29.138336],
          [61.419635, 29.138433],
          [61.419374, 29.138734],
          [61.419428, 29.138984],
          [61.419387, 29.139073],
          [61.419412, 29.139351],
          [61.419543, 29.139502],
          [61.419493, 29.139674],
          [61.419258, 29.139819],
          [61.419249, 29.139913],
          [61.419149, 29.140011],
          [61.419325, 29.140517],
          [61.419065, 29.140622],
          [61.419046, 29.140793],
          [61.418854, 29.140985],
          [61.418687, 29.140867],
          [61.418467, 29.140813],
          [61.418404, 29.140906],
          [61.418272, 29.140977],
          [61.418064, 29.140926],
          [61.417966, 29.140982],
          [61.417746, 29.141003],
          [61.417488, 29.141236],
          [61.417288, 29.141301],
          [61.417092, 29.141303],
          [61.416778, 29.141511],
          [61.416756, 29.141949],
          [61.416818, 29.141965],
          [61.416749, 29.142024],
          [61.416749, 29.142234],
          [61.416783, 29.142233],
          [61.416864, 29.14246],
          [61.41681, 29.142594],
          [61.416608, 29.142701],
          [61.41672, 29.142971],
          [61.416958, 29.143192],
          [61.416759, 29.143461],
          [61.416698, 29.143706],
          [61.416931, 29.143845],
          [61.417008, 29.143956],
          [61.416936, 29.144023],
          [61.41687, 29.144263],
          [61.416726, 29.14451],
          [61.416772, 29.1447],
          [61.416648, 29.144789],
          [61.416666, 29.14485],
          [61.416491, 29.144887],
          [61.416486, 29.145221],
          [61.416652, 29.145396],
          [61.416636, 29.145584],
          [61.4163, 29.145687],
          [61.416255, 29.145738],
          [61.416201, 29.146],
          [61.416081, 29.146198],
          [61.416065, 29.146383],
          [61.416262, 29.146675],
          [61.416363, 29.14675],
          [61.416459, 29.146756],
          [61.415886, 29.147273],
          [61.415897, 29.147399],
          [61.415748, 29.147544],
          [61.415777, 29.147661],
          [61.415676, 29.147814],
          [61.415587, 29.148185],
          [61.415454, 29.148396],
          [61.415518, 29.14858],
          [61.415622, 29.14865],
          [61.415511, 29.148818],
          [61.41552, 29.148898],
          [61.415273, 29.149065],
          [61.415281, 29.149545],
          [61.415081, 29.149977],
          [61.414834, 29.150244],
          [61.414834, 29.150507],
          [61.414645, 29.15059],
          [61.414586, 29.150748],
          [61.414546, 29.150917],
          [61.414591, 29.151049],
          [61.414559, 29.151234],
          [61.414597, 29.151417],
          [61.414821, 29.151821],
          [61.414732, 29.151979],
          [61.414759, 29.152197],
          [61.414637, 29.152322],
          [61.414356, 29.152445],
          [61.414313, 29.152661],
          [61.414342, 29.152806],
          [61.414271, 29.15296],
          [61.414032, 29.153176],
          [61.413968, 29.153334],
          [61.414061, 29.153484],
          [61.413981, 29.153679],
          [61.41407, 29.153785],
          [61.413454, 29.154226],
          [61.413273, 29.154318],
          [61.412976, 29.154348],
          [61.412949, 29.154499],
          [61.412849, 29.154609],
          [61.412878, 29.15476],
          [61.412784, 29.154818],
          [61.412674, 29.15512],
          [61.412689, 29.155447],
          [61.412743, 29.155621],
          [61.41285, 29.15575],
          [61.412666, 29.155933],
          [61.412613, 29.156106],
          [61.412668, 29.156232],
          [61.412601, 29.156327],
          [61.41252, 29.156668],
          [61.41217, 29.156976],
          [61.412221, 29.157023],
          [61.412208, 29.157152],
          [61.412127, 29.157297],
          [61.412752, 29.157552],
          [61.412761, 29.157903],
          [61.41261, 29.158118],
          [61.412617, 29.158307],
          [61.412444, 29.158403],
          [61.412479, 29.15876],
          [61.412406, 29.15896],
          [61.412684, 29.159588],
          [61.412448, 29.159921],
          [61.412476, 29.160038],
          [61.412283, 29.160211],
          [61.412774, 29.160433],
          [61.412931, 29.160716],
          [61.413182, 29.160996],
          [61.41299, 29.161645],
          [61.412967, 29.161891],
          [61.413242, 29.16221],
          [61.413394, 29.162322],
          [61.413056, 29.163078],
          [61.412995, 29.163353],
          [61.413139, 29.163448],
          [61.413008, 29.16372],
          [61.412817, 29.163769],
          [61.412752, 29.163871],
          [61.412811, 29.164196],
          [61.41287, 29.164201],
          [61.412887, 29.164405],
          [61.41311, 29.164512],
          [61.413811, 29.164502],
          [61.413876, 29.164562],
          [61.414072, 29.164585],
          [61.414411, 29.164744],
          [61.414563, 29.164862],
          [61.414613, 29.164698],
          [61.414877, 29.164426],
          [61.415134, 29.164045],
          [61.415496, 29.163626],
          [61.415556, 29.163429],
          [61.416213, 29.162946],
          [61.416325, 29.162935],
          [61.416888, 29.162588],
          [61.417211, 29.162138],
          [61.417579, 29.162217],
          [61.417999, 29.162408],
          [61.418145, 29.162407],
          [61.418398, 29.162273],
          [61.41847, 29.162444],
          [61.418569, 29.162458],
          [61.418746, 29.162669],
          [61.419083, 29.162855],
          [61.419497, 29.162997],
          [61.419761, 29.16346],
          [61.420175, 29.163675],
          [61.420348, 29.163686],
          [61.420515, 29.163752],
          [61.420639, 29.163892],
          [61.420906, 29.164029],
          [61.420783, 29.164306],
          [61.420764, 29.164545],
          [61.420689, 29.164655],
          [61.420618, 29.165016],
          [61.420417, 29.165157],
          [61.420392, 29.165711],
          [61.420201, 29.165944],
          [61.419987, 29.166502],
          [61.419826, 29.16668],
          [61.419754, 29.166851],
          [61.419533, 29.16704],
          [61.419528, 29.167204],
          [61.419225, 29.167471],
          [61.419058, 29.1677],
          [61.418704, 29.167837],
          [61.418648, 29.168078],
          [61.418435, 29.168299],
          [61.418353, 29.168484],
          [61.418131, 29.168539],
          [61.417778, 29.169073],
          [61.417675, 29.169251],
          [61.417667, 29.169512],
          [61.417454, 29.169491],
          [61.417279, 29.169553],
          [61.41709, 29.16977],
          [61.417012, 29.170077],
          [61.416709, 29.170215],
          [61.416695, 29.170341],
          [61.416568, 29.1705],
          [61.416982, 29.170748],
          [61.416828, 29.17083],
          [61.416531, 29.171528],
          [61.416622, 29.171907],
          [61.416477, 29.171964],
          [61.416309, 29.172238],
          [61.416206, 29.172286],
          [61.41614, 29.172462],
          [61.416096, 29.172829],
          [61.416221, 29.173219],
          [61.416318, 29.173352],
          [61.416211, 29.173363],
          [61.416071, 29.173469],
          [61.415685, 29.173387],
          [61.415445, 29.173417],
          [61.415241, 29.173362],
          [61.415036, 29.173196],
          [61.414677, 29.173334],
          [61.414619, 29.173278],
          [61.4145, 29.173285],
          [61.414488, 29.173422],
          [61.414339, 29.173434],
          [61.414285, 29.173339],
          [61.414028, 29.173345],
          [61.413999, 29.173499],
          [61.413858, 29.173547],
          [61.413867, 29.173623],
          [61.413548, 29.174151],
          [61.41353, 29.174446],
          [61.41339, 29.174681],
          [61.413176, 29.174712],
          [61.413157, 29.174817],
          [61.412824, 29.174776],
          [61.412833, 29.175026],
          [61.412716, 29.175094],
          [61.412662, 29.175262],
          [61.412681, 29.175421],
          [61.412863, 29.175833],
          [61.412897, 29.176134],
          [61.412862, 29.176304],
          [61.412948, 29.176449],
          [61.413029, 29.176758],
          [61.413212, 29.17689],
          [61.413299, 29.177276],
          [61.413431, 29.177507],
          [61.413375, 29.177748],
          [61.4134, 29.178012],
          [61.413982, 29.178373],
          [61.41401, 29.178491],
          [61.413849, 29.178835],
          [61.413972, 29.178838],
          [61.413934, 29.178965],
          [61.41397, 29.179115],
          [61.4139, 29.179269],
          [61.414866, 29.180042],
          [61.414927, 29.180164],
          [61.415162, 29.180297],
          [61.415323, 29.18031],
          [61.415513, 29.180491],
          [61.415833, 29.180506],
          [61.416119, 29.180455],
          [61.416584, 29.180514],
          [61.416762, 29.180546],
          [61.416915, 29.180678],
          [61.417096, 29.180458],
          [61.417435, 29.180254],
          [61.417993, 29.179703],
          [61.418298, 29.180024],
          [61.418458, 29.180267],
          [61.418542, 29.180933],
          [61.418598, 29.181006],
          [61.418946, 29.181101],
          [61.41889, 29.181196],
          [61.41899, 29.181268],
          [61.418949, 29.181416],
          [61.418979, 29.181486],
          [61.419268, 29.181521],
          [61.419651, 29.181432],
          [61.419526, 29.181596],
          [61.419462, 29.181805],
          [61.419531, 29.181903],
          [61.419926, 29.181993],
          [61.42016, 29.182205],
          [61.420233, 29.182218],
          [61.420356, 29.182438],
          [61.42075, 29.182786],
          [61.421234, 29.183334],
          [61.421259, 29.183402],
          [61.421121, 29.183511],
          [61.421103, 29.183579],
          [61.42051, 29.183928],
          [61.420484, 29.18413],
          [61.420356, 29.184238],
          [61.420423, 29.184587],
          [61.420292, 29.184722],
          [61.420275, 29.184809],
          [61.420352, 29.185102],
          [61.420623, 29.185385],
          [61.420397, 29.185631],
          [61.420169, 29.185748],
          [61.420098, 29.185871],
          [61.420262, 29.186151],
          [61.420217, 29.186227],
          [61.420283, 29.186421],
          [61.420516, 29.186706],
          [61.42048, 29.186819],
          [61.420519, 29.186929],
          [61.420449, 29.187032],
          [61.420381, 29.187017],
          [61.420216, 29.187213],
          [61.420108, 29.187459],
          [61.420347, 29.187742],
          [61.42024, 29.187875],
          [61.420278, 29.188164],
          [61.420406, 29.188436],
          [61.42057, 29.188552],
          [61.420585, 29.188607],
          [61.420311, 29.188724],
          [61.420268, 29.188952],
          [61.420383, 29.189045],
          [61.420351, 29.189122],
          [61.420148, 29.189338],
          [61.420131, 29.189504],
          [61.419991, 29.189739],
          [61.419633, 29.190015],
          [61.419606, 29.190264],
          [61.419702, 29.190336],
          [61.41972, 29.190532],
          [61.419807, 29.190663],
          [61.4197, 29.190951],
          [61.419709, 29.191095],
          [61.420056, 29.191248],
          [61.420217, 29.191412],
          [61.420221, 29.191469],
          [61.420329, 29.191522],
          [61.420859, 29.191551],
          [61.421462, 29.191426],
          [61.42169, 29.191503],
          [61.421754, 29.191566],
          [61.421963, 29.191558],
          [61.422342, 29.191812],
          [61.422534, 29.191847],
          [61.422767, 29.191812],
          [61.42302, 29.191678],
          [61.423184, 29.191517],
          [61.4238, 29.191966],
          [61.424218, 29.192203],
          [61.424454, 29.192577],
          [61.42456, 29.192877],
          [61.424189, 29.193004],
          [61.423912, 29.192801],
          [61.423755, 29.19281],
          [61.423533, 29.192994],
          [61.423304, 29.193274],
          [61.42342, 29.193342],
          [61.423499, 29.193561],
          [61.423457, 29.193889],
          [61.423403, 29.193975],
          [61.423485, 29.194158],
          [61.423674, 29.194214],
          [61.423981, 29.194759],
          [61.423927, 29.194844],
          [61.423494, 29.195175],
          [61.423384, 29.195348],
          [61.422974, 29.195648],
          [61.422966, 29.195745],
          [61.423062, 29.195859],
          [61.422734, 29.196256],
          [61.423275, 29.196423],
          [61.423504, 29.196742],
          [61.423584, 29.19676],
          [61.423786, 29.196966],
          [61.424135, 29.197071],
          [61.42441, 29.197319],
          [61.424766, 29.197506],
          [61.424948, 29.197324],
          [61.425179, 29.197281],
          [61.425282, 29.197409],
          [61.42544, 29.197452],
          [61.425599, 29.197436],
          [61.425672, 29.197508],
          [61.425562, 29.197679],
          [61.425849, 29.19795],
          [61.426247, 29.197944],
          [61.426506, 29.198148],
          [61.426562, 29.198259],
          [61.426501, 29.198269],
          [61.426484, 29.198388],
          [61.426626, 29.198481],
          [61.426763, 29.19882],
          [61.426963, 29.198836],
          [61.427096, 29.198911],
          [61.42746, 29.198895],
          [61.427855, 29.198985],
          [61.427939, 29.198884],
          [61.428005, 29.198871],
          [61.428199, 29.19862],
          [61.428515, 29.198563],
          [61.428681, 29.198463],
          [61.429093, 29.198438],
          [61.429641, 29.198105],
          [61.429825, 29.197923],
          [61.43012, 29.19833],
          [61.430255, 29.198877],
          [61.430218, 29.199095],
          [61.429889, 29.199451],
          [61.429656, 29.199814],
          [61.429225, 29.199826],
          [61.429001, 29.200019],
          [61.428798, 29.200095],
          [61.428608, 29.200318],
          [61.428252, 29.200579],
          [61.428045, 29.201064],
          [61.427793, 29.201358],
          [61.427559, 29.20115],
          [61.427142, 29.201189],
          [61.426952, 29.201089],
          [61.426828, 29.201143],
          [61.426464, 29.201577],
          [61.42636, 29.201602],
          [61.426389, 29.201695],
          [61.426277, 29.201856],
          [61.426064, 29.201964],
          [61.426026, 29.202162],
          [61.425556, 29.202915],
          [61.425486, 29.202967],
          [61.425347, 29.202865],
          [61.425064, 29.202825],
          [61.424859, 29.202972],
          [61.424585, 29.202915],
          [61.424416, 29.203241],
          [61.424004, 29.203792],
          [61.423707, 29.203877],
          [61.423515, 29.203767],
          [61.423518, 29.203707],
          [61.423293, 29.203587],
          [61.423087, 29.203522],
          [61.42305, 29.203566],
          [61.422948, 29.203557],
          [61.422688, 29.203251],
          [61.422553, 29.203193],
          [61.422341, 29.203184],
          [61.421992, 29.203596],
          [61.421654, 29.203909],
          [61.421318, 29.204015],
          [61.421095, 29.204036],
          [61.421019, 29.204113],
          [61.420935, 29.204371],
          [61.420762, 29.20455],
          [61.420788, 29.204657],
          [61.420713, 29.204874],
          [61.420746, 29.204979],
          [61.420499, 29.205431],
          [61.42027, 29.205348],
          [61.420055, 29.205379],
          [61.41979, 29.205723],
          [61.419308, 29.205936],
          [61.418447, 29.205703],
          [61.418076, 29.205927],
          [61.417614, 29.205431],
          [61.417308, 29.205687],
          [61.41696, 29.205823],
          [61.416838, 29.205842],
          [61.416432, 29.205788],
          [61.415757, 29.205932],
          [61.415733, 29.206178],
          [61.415531, 29.206313],
          [61.415598, 29.206457],
          [61.415577, 29.206584],
          [61.415439, 29.206746],
          [61.414954, 29.206853],
          [61.414807, 29.20691],
          [61.414703, 29.207048],
          [61.414525, 29.207072],
          [61.414259, 29.207416],
          [61.413937, 29.207649],
          [61.413679, 29.20794],
          [61.413462, 29.208002],
          [61.413315, 29.20797],
          [61.413085, 29.208004],
          [61.41302, 29.208051],
          [61.412584, 29.20775],
          [61.412366, 29.207651],
          [61.411904, 29.207916],
          [61.411726, 29.208141],
          [61.411658, 29.208541],
          [61.411613, 29.208618],
          [61.411516, 29.208623],
          [61.411388, 29.208805],
          [61.411484, 29.208951],
          [61.411424, 29.208966],
          [61.411383, 29.209215],
          [61.41116, 29.209368],
          [61.410798, 29.209487],
          [61.410623, 29.20964],
          [61.410623, 29.209693],
          [61.410433, 29.209798],
          [61.410367, 29.209909],
          [61.41042, 29.210142],
          [61.410368, 29.210253],
          [61.410093, 29.210193],
          [61.409926, 29.209973],
          [61.409711, 29.20987],
          [61.409467, 29.209885],
          [61.409163, 29.210096],
          [61.409164, 29.210203],
          [61.409066, 29.210217],
          [61.408764, 29.210411],
          [61.408527, 29.210614],
          [61.408281, 29.210909],
          [61.408085, 29.210988],
          [61.407805, 29.21125],
          [61.407564, 29.211326],
          [61.407436, 29.21148],
          [61.407419, 29.211674],
          [61.407287, 29.211739],
          [61.40707, 29.212092],
          [61.40692, 29.21246],
          [61.406898, 29.212658],
          [61.406713, 29.212911],
          [61.406335, 29.213196],
          [61.406183, 29.213348],
          [61.406145, 29.213455],
          [61.405945, 29.213589],
          [61.406027, 29.213955],
          [61.40592, 29.214087],
          [61.405721, 29.214149],
          [61.405584, 29.214314],
          [61.405326, 29.214443],
          [61.405377, 29.214632],
          [61.405314, 29.215021],
          [61.405024, 29.215154],
          [61.404803, 29.215319],
          [61.404691, 29.215283],
          [61.404601, 29.215329],
          [61.404487, 29.215288],
          [61.404394, 29.215359],
          [61.404305, 29.215553],
          [61.40409, 29.215549],
          [61.403845, 29.215656],
          [61.403663, 29.215659],
          [61.403525, 29.215595],
          [61.403558, 29.215494],
          [61.403468, 29.215487],
          [61.403318, 29.215389],
          [61.403052, 29.21555],
          [61.402939, 29.215447],
          [61.402927, 29.215306],
          [61.402636, 29.215239],
          [61.402362, 29.215379],
          [61.402351, 29.215437],
          [61.402209, 29.215556],
          [61.402047, 29.215602],
          [61.401712, 29.215898],
          [61.401546, 29.215887],
          [61.401411, 29.21572],
          [61.400925, 29.215738],
          [61.400817, 29.215685],
          [61.400619, 29.215843],
          [61.400522, 29.216106],
          [61.400449, 29.216115],
          [61.400309, 29.21632],
          [61.400108, 29.21636],
          [61.399858, 29.216185],
          [61.399353, 29.215942],
          [61.399313, 29.21598],
          [61.399227, 29.215944],
          [61.398989, 29.216053],
          [61.398847, 29.216225],
          [61.398876, 29.216446],
          [61.398647, 29.216674],
          [61.398503, 29.216753],
          [61.398262, 29.217011],
          [61.39823, 29.217111],
          [61.397839, 29.217422],
          [61.397672, 29.217803],
          [61.397722, 29.21806],
          [61.397538, 29.218162],
          [61.397438, 29.218293],
          [61.397264, 29.218671],
          [61.396907, 29.218808],
          [61.396811, 29.218932],
          [61.396798, 29.219035],
          [61.397288, 29.219342],
          [61.397461, 29.219568],
          [61.397392, 29.219621],
          [61.397282, 29.21987],
          [61.397002, 29.220077],
          [61.397041, 29.220131],
          [61.396788, 29.220247],
          [61.396793, 29.22036],
          [61.396601, 29.220622],
          [61.396317, 29.220803],
          [61.396124, 29.221035],
          [61.39602, 29.221022],
          [61.395779, 29.221131],
          [61.395555, 29.221104],
          [61.395464, 29.221302],
          [61.395599, 29.221402],
          [61.395481, 29.221725],
          [61.395393, 29.221778],
          [61.395221, 29.222056],
          [61.394937, 29.222132],
          [61.394918, 29.22221],
          [61.394778, 29.222346],
          [61.394448, 29.222533],
          [61.393884, 29.223135],
          [61.39356, 29.223368],
          [61.393365, 29.22359],
          [61.393221, 29.223555],
          [61.39311, 29.223684],
          [61.392781, 29.223728],
          [61.392752, 29.223858],
          [61.392702, 29.223871],
          [61.392712, 29.223941],
          [61.391649, 29.224309],
          [61.391471, 29.224275],
          [61.391219, 29.224314],
          [61.391053, 29.224291],
          [61.390997, 29.224361],
          [61.390841, 29.224405],
          [61.390777, 29.22452],
          [61.39066, 29.224544],
          [61.390306, 29.224786],
          [61.390233, 29.224875],
          [61.389894, 29.225011],
          [61.390014, 29.225184],
          [61.390089, 29.225454],
          [61.390072, 29.225724],
          [61.389887, 29.225819],
          [61.389887, 29.225938],
          [61.389765, 29.226084],
          [61.389534, 29.226116],
          [61.389297, 29.226282],
          [61.389276, 29.226394],
          [61.389182, 29.226498],
          [61.389211, 29.226578],
          [61.389054, 29.226792],
          [61.388805, 29.226853],
          [61.388723, 29.226958],
          [61.388634, 29.226903],
          [61.388164, 29.226913],
          [61.387844, 29.227126],
          [61.387622, 29.227198],
          [61.387444, 29.227349],
          [61.387385, 29.227444],
          [61.387416, 29.227599],
          [61.387358, 29.227671],
          [61.38744, 29.227777],
          [61.387316, 29.227912],
          [61.387084, 29.228545],
          [61.386998, 29.228634],
          [61.386773, 29.229139],
          [61.386754, 29.229347],
          [61.386645, 29.229391],
          [61.38649, 29.229267],
          [61.38599, 29.229155],
          [61.385843, 29.229328],
          [61.38566, 29.229431],
          [61.385484, 29.229405],
          [61.384883, 29.229862],
          [61.38496, 29.229941],
          [61.384799, 29.230023],
          [61.384586, 29.230331],
          [61.384277, 29.230276],
          [61.38402, 29.230648],
          [61.383753, 29.230634],
          [61.383316, 29.230859],
          [61.383051, 29.231095],
          [61.383049, 29.231477],
          [61.382911, 29.231632],
          [61.382776, 29.231761],
          [61.382381, 29.23192],
          [61.382262, 29.231921],
          [61.382423, 29.232017],
          [61.382552, 29.232185],
          [61.382557, 29.232339],
          [61.382414, 29.232633],
          [61.382142, 29.232759],
          [61.382098, 29.232967],
          [61.381868, 29.23324],
          [61.381949, 29.233811],
          [61.381664, 29.23399],
          [61.381521, 29.234284],
          [61.381333, 29.234471],
          [61.381171, 29.234503],
          [61.381085, 29.234669],
          [61.38119, 29.234793],
          [61.38089, 29.234802],
          [61.380413, 29.235041],
          [61.379991, 29.235385],
          [61.37995, 29.235526],
          [61.379762, 29.235663],
          [61.379782, 29.235789],
          [61.3797, 29.235883],
          [61.379554, 29.235939],
          [61.379516, 29.235978],
          [61.379557, 29.236013],
          [61.379124, 29.236121],
          [61.37905, 29.23618],
          [61.378878, 29.236198],
          [61.378637, 29.236351],
          [61.378578, 29.236495],
          [61.378407, 29.236546],
          [61.378469, 29.236722],
          [61.378435, 29.237097],
          [61.377853, 29.237516],
          [61.377791, 29.237651],
          [61.377643, 29.237678],
          [61.37763, 29.237836],
          [61.377409, 29.238367],
          [61.377086, 29.238348],
          [61.376969, 29.238213],
          [61.37687, 29.238183],
          [61.376697, 29.238279],
          [61.376638, 29.23837],
          [61.376199, 29.238435],
          [61.375949, 29.238545],
          [61.375866, 29.238778],
          [61.375764, 29.238803],
          [61.375761, 29.23892],
          [61.375597, 29.238986],
          [61.375529, 29.239282],
          [61.375357, 29.239543],
          [61.375111, 29.239669],
          [61.374907, 29.239856],
          [61.37503, 29.239898],
          [61.37511, 29.240007],
          [61.375008, 29.240183],
          [61.374773, 29.240384],
          [61.374804, 29.240644],
          [61.374665, 29.240694],
          [61.374508, 29.24093],
          [61.374605, 29.241648],
          [61.37456, 29.2418],
          [61.374289, 29.241954],
          [61.374093, 29.242214],
          [61.37416, 29.242334],
          [61.374043, 29.242424],
          [61.373956, 29.242581],
          [61.373979, 29.242763],
          [61.373767, 29.242791],
          [61.372966, 29.243313],
          [61.372883, 29.243595],
          [61.372752, 29.243732],
          [61.372683, 29.24389],
          [61.372295, 29.244133],
          [61.372372, 29.24461],
          [61.372313, 29.244705],
          [61.372373, 29.244876],
          [61.372238, 29.244892],
          [61.372123, 29.245042],
          [61.372133, 29.245162],
          [61.372662, 29.24524],
          [61.372781, 29.245366],
          [61.372911, 29.245384],
          [61.373036, 29.245537],
          [61.373531, 29.245843],
          [61.373666, 29.246088],
          [61.373648, 29.246224],
          [61.373813, 29.246413],
          [61.373878, 29.246578],
          [61.374187, 29.247026],
          [61.374324, 29.247071],
          [61.374565, 29.247677],
          [61.374712, 29.247637],
          [61.375471, 29.247752],
          [61.375252, 29.2481],
          [61.375073, 29.248141],
          [61.374893, 29.248332],
          [61.374717, 29.248389],
          [61.374463, 29.249032],
          [61.374192, 29.249213],
          [61.374006, 29.249228],
          [61.373807, 29.249395],
          [61.373572, 29.249491],
          [61.373494, 29.249717],
          [61.37312, 29.250334],
          [61.373156, 29.250532],
          [61.373241, 29.250676],
          [61.373191, 29.250785],
          [61.373232, 29.250982],
          [61.373171, 29.251066],
          [61.372827, 29.251284],
          [61.372612, 29.251332],
          [61.3725, 29.251498],
          [61.37222, 29.251432],
          [61.371057, 29.251518],
          [61.370718, 29.251419],
          [61.370553, 29.251463],
          [61.370267, 29.251348],
          [61.370039, 29.251083],
          [61.370099, 29.250833],
          [61.369629, 29.250765],
          [61.368763, 29.251169],
          [61.368932, 29.251479],
          [61.368548, 29.251506],
          [61.368297, 29.251616],
          [61.367947, 29.251625],
          [61.367779, 29.251713],
          [61.367642, 29.251979],
          [61.367483, 29.251971],
          [61.367172, 29.252144],
          [61.367015, 29.252294],
          [61.366934, 29.252468],
          [61.366763, 29.252513],
          [61.366744, 29.252799],
          [61.366833, 29.252986],
          [61.366717, 29.253081],
          [61.366541, 29.253393],
          [61.366329, 29.25343],
          [61.366217, 29.253513],
          [61.366214, 29.25384],
          [61.366086, 29.25391],
          [61.365689, 29.254509],
          [61.365698, 29.254807],
          [61.365847, 29.254907],
          [61.365839, 29.255175],
          [61.36588, 29.255214],
          [61.366094, 29.255096],
          [61.366181, 29.255116],
          [61.366482, 29.255006],
          [61.366608, 29.255059],
          [61.366814, 29.255367],
          [61.367082, 29.255641],
          [61.367234, 29.255721],
          [61.36741, 29.255642],
          [61.367491, 29.256047],
          [61.367329, 29.256093],
          [61.367199, 29.256243],
          [61.366935, 29.256337],
          [61.366817, 29.256527],
          [61.366617, 29.256572],
          [61.366474, 29.256514],
          [61.365343, 29.256985],
          [61.364969, 29.257455],
          [61.364777, 29.257573],
          [61.364722, 29.257792],
          [61.364473, 29.257958],
          [61.36435, 29.258342],
          [61.36446, 29.258822],
          [61.364738, 29.259063],
          [61.364586, 29.259226],
          [61.364227, 29.259383],
          [61.363832, 29.259456],
          [61.363722, 29.259328],
          [61.3636, 29.259388],
          [61.363393, 29.259332],
          [61.363063, 29.259425],
          [61.362624, 29.259246],
          [61.362241, 29.259333],
          [61.36197, 29.259487],
          [61.361775, 29.259697],
          [61.3619, 29.26011],
          [61.362068, 29.260209],
          [61.362063, 29.260449],
          [61.361913, 29.260506],
          [61.361831, 29.260611],
          [61.361859, 29.260832],
          [61.361608, 29.261054],
          [61.361505, 29.261271],
          [61.361186, 29.261542],
          [61.361119, 29.261888],
          [61.361232, 29.261965],
          [61.36125, 29.262094],
          [61.361397, 29.262231],
          [61.36157, 29.262549],
          [61.361515, 29.262801],
          [61.361522, 29.26322],
          [61.361299, 29.263447],
          [61.361393, 29.263733],
          [61.361298, 29.263885],
          [61.361358, 29.264084],
          [61.361033, 29.264409],
          [61.360978, 29.26499],
          [61.360911, 29.265103],
          [61.360545, 29.265286],
          [61.360348, 29.265477],
          [61.360419, 29.265585],
          [61.360423, 29.26577],
          [61.360333, 29.265816],
          [61.359961, 29.266283],
          [61.360213, 29.266388],
          [61.36032, 29.266755],
          [61.36021, 29.267068],
          [61.360348, 29.26733],
          [61.360119, 29.267464],
          [61.360165, 29.267541],
          [61.360144, 29.267675],
          [61.360051, 29.267758],
          [61.360123, 29.267916],
          [61.360621, 29.268318],
          [61.360819, 29.268567],
          [61.36097, 29.26888],
          [61.361154, 29.268966],
          [61.361259, 29.269244],
          [61.360843, 29.269293],
          [61.360858, 29.269595],
          [61.360934, 29.26974],
          [61.36089, 29.269967],
          [61.361058, 29.270658],
          [61.360994, 29.270914],
          [61.361055, 29.271093],
          [61.361014, 29.271328],
          [61.360858, 29.271802],
          [61.36091, 29.272163],
          [61.360997, 29.27234],
          [61.360321, 29.272789],
          [61.360292, 29.27287],
          [61.360453, 29.27317],
          [61.360439, 29.273381],
          [61.360567, 29.273563],
          [61.360549, 29.273827],
          [61.360354, 29.27401],
          [61.360089, 29.27399],
          [61.360078, 29.274067],
          [61.359981, 29.274161],
          [61.359838, 29.274129],
          [61.359402, 29.27416],
          [61.359314, 29.274238],
          [61.359037, 29.274188],
          [61.359039, 29.274354],
          [61.358916, 29.274467],
          [61.358571, 29.274607],
          [61.358322, 29.274828],
          [61.358121, 29.274879],
          [61.357755, 29.274747],
          [61.357602, 29.274816],
          [61.35744, 29.274665],
          [61.357291, 29.274623],
          [61.357108, 29.274648],
          [61.356945, 29.274442],
          [61.356687, 29.274443],
          [61.356464, 29.274354],
          [61.356281, 29.274352],
          [61.3559, 29.274809],
          [61.355628, 29.274332],
          [61.355511, 29.274287],
          [61.355417, 29.274362],
          [61.354837, 29.274554],
          [61.354481, 29.274564],
          [61.354131, 29.274966],
          [61.353901, 29.275019],
          [61.353629, 29.275184],
          [61.353602, 29.275386],
          [61.3534, 29.27567],
          [61.353047, 29.275851],
          [61.352909, 29.275983],
          [61.353043, 29.276228],
          [61.352999, 29.276304],
          [61.353026, 29.27642],
          [61.353277, 29.276492],
          [61.353521, 29.276643],
          [61.353492, 29.276837],
          [61.353604, 29.276909],
          [61.353777, 29.277208],
          [61.353789, 29.277288],
          [61.353593, 29.277676],
          [61.35358, 29.278011],
          [61.353945, 29.278376],
          [61.353889, 29.278565],
          [61.354308, 29.27923],
          [61.354707, 29.2794],
          [61.354727, 29.27964],
          [61.354846, 29.279821],
          [61.354886, 29.280062],
          [61.354849, 29.280225],
          [61.354748, 29.28033],
          [61.354654, 29.280293],
          [61.354067, 29.280402],
          [61.353843, 29.280518],
          [61.353762, 29.280678],
          [61.353838, 29.280813],
          [61.353962, 29.281298],
          [61.354187, 29.281503],
          [61.354205, 29.281809],
          [61.354375, 29.282152],
          [61.354427, 29.282474],
          [61.354612, 29.282578],
          [61.35458, 29.28277],
          [61.35444, 29.283],
          [61.354515, 29.283168],
          [61.354374, 29.283642],
          [61.354151, 29.283705],
          [61.353665, 29.283985],
          [61.353595, 29.284118],
          [61.353195, 29.284026],
          [61.352956, 29.284023],
          [61.352901, 29.284084],
          [61.35265, 29.284139],
          [61.352601, 29.284199],
          [61.352212, 29.284176],
          [61.351983, 29.284427],
          [61.351844, 29.284833],
          [61.351668, 29.284965],
          [61.351712, 29.285086],
          [61.35143, 29.285138],
          [61.350787, 29.2854],
          [61.350975, 29.285734],
          [61.351545, 29.286313],
          [61.351628, 29.28659],
          [61.35149, 29.286788],
          [61.351729, 29.287662],
          [61.352003, 29.287674],
          [61.352121, 29.287723],
          [61.352101, 29.287926],
          [61.352241, 29.288196],
          [61.352335, 29.288245],
          [61.352194, 29.288523],
          [61.352263, 29.288731],
          [61.352629, 29.288891],
          [61.352738, 29.288819],
          [61.352932, 29.288997],
          [61.353093, 29.289037],
          [61.353409, 29.289252],
          [61.353992, 29.289319],
          [61.354377, 29.289326],
          [61.354784, 29.288756],
          [61.35475, 29.288678],
          [61.354886, 29.28867],
          [61.35498, 29.288752],
          [61.355476, 29.288823],
          [61.356437, 29.289405],
          [61.356602, 29.29025],
          [61.356838, 29.290364],
          [61.357211, 29.290426],
          [61.357462, 29.290551],
          [61.357617, 29.290698],
          [61.357962, 29.290876],
          [61.358247, 29.291118],
          [61.359382, 29.290948],
          [61.359474, 29.291],
          [61.359794, 29.290901],
          [61.360189, 29.290851],
          [61.360332, 29.290785],
          [61.360436, 29.290643],
          [61.36048, 29.290665],
          [61.360824, 29.290575],
          [61.361498, 29.290593],
          [61.361809, 29.290698],
          [61.362326, 29.290529],
          [61.362677, 29.290584],
          [61.362939, 29.290524],
          [61.363073, 29.290572],
          [61.363217, 29.290674],
          [61.363522, 29.291065],
          [61.363642, 29.291113],
          [61.363886, 29.291331],
          [61.364077, 29.29172],
          [61.364495, 29.291911],
          [61.364636, 29.292094],
          [61.364701, 29.292452],
          [61.36466, 29.292662],
          [61.36479, 29.292877],
          [61.364741, 29.292959],
          [61.364906, 29.293043],
          [61.364984, 29.293235],
          [61.364997, 29.293357],
          [61.364864, 29.293536],
          [61.364931, 29.293594],
          [61.364923, 29.293729],
          [61.365195, 29.293828],
          [61.365336, 29.293927],
          [61.365403, 29.294061],
          [61.365503, 29.294046],
          [61.365612, 29.294105],
          [61.365604, 29.294176],
          [61.365692, 29.294242],
          [61.365752, 29.294424],
          [61.365954, 29.294494],
          [61.366263, 29.294782],
          [61.366844, 29.295077],
          [61.366834, 29.295129],
          [61.366965, 29.295344],
          [61.367445, 29.295299],
          [61.3676, 29.295368],
          [61.367809, 29.295553],
          [61.367746, 29.29566],
          [61.367918, 29.295825],
          [61.368117, 29.296129],
          [61.368151, 29.296294],
          [61.368297, 29.296432],
          [61.368513, 29.296544],
          [61.368537, 29.296694],
          [61.36862, 29.296755],
          [61.369014, 29.296899],
          [61.369808, 29.296902],
          [61.370053, 29.297081],
          [61.370346, 29.29747],
          [61.370156, 29.297807],
          [61.369993, 29.297966],
          [61.370033, 29.298108],
          [61.370001, 29.298314],
          [61.370063, 29.298573],
          [61.370025, 29.298885],
          [61.369529, 29.299388],
          [61.369529, 29.299543],
          [61.369387, 29.299843],
          [61.369242, 29.299921],
          [61.368956, 29.300354],
          [61.368814, 29.300444],
          [61.368501, 29.300528],
          [61.368113, 29.300513],
          [61.368273, 29.300647],
          [61.368212, 29.301412],
          [61.368102, 29.301739],
          [61.36763, 29.302447],
          [61.367081, 29.303121],
          [61.367065, 29.30329],
          [61.366982, 29.30339],
          [61.366582, 29.30364],
          [61.366334, 29.303967],
          [61.366148, 29.304032],
          [61.365972, 29.304338],
          [61.365919, 29.304769],
          [61.366031, 29.305002],
          [61.366035, 29.305123],
          [61.366173, 29.305223],
          [61.36616, 29.305326],
          [61.366568, 29.3054],
          [61.366705, 29.3055],
          [61.366879, 29.305515],
          [61.366974, 29.305674],
          [61.366948, 29.305829],
          [61.36688, 29.305886],
          [61.366815, 29.30627],
          [61.366998, 29.306533],
          [61.366926, 29.30678],
          [61.367003, 29.306842],
          [61.366804, 29.307375],
          [61.366779, 29.307635],
          [61.366622, 29.307688],
          [61.366515, 29.307637],
          [61.366055, 29.307872],
          [61.365979, 29.308159],
          [61.365901, 29.308279],
          [61.365496, 29.308559],
          [61.365423, 29.308961],
          [61.365325, 29.309075],
          [61.36521, 29.309358],
          [61.36505, 29.309556],
          [61.365075, 29.309684],
          [61.364909, 29.309938],
          [61.364636, 29.310109],
          [61.364817, 29.310522],
          [61.36515, 29.310717],
          [61.365126, 29.311012],
          [61.365212, 29.311298],
          [61.365305, 29.311338],
          [61.365389, 29.311292],
          [61.365514, 29.311497],
          [61.365597, 29.311527],
          [61.365598, 29.311603],
          [61.365743, 29.311669],
          [61.365876, 29.311665],
          [61.365842, 29.311801],
          [61.365941, 29.31196],
          [61.365884, 29.312025],
          [61.365942, 29.312195],
          [61.366427, 29.312245],
          [61.366545, 29.312295],
          [61.366548, 29.312341],
          [61.366713, 29.312367],
          [61.366769, 29.312507],
          [61.366711, 29.312636],
          [61.366859, 29.312856],
          [61.367002, 29.31295],
          [61.36703, 29.313057],
          [61.36749, 29.313377],
          [61.367177, 29.313828],
          [61.367403, 29.313849],
          [61.367635, 29.313989],
          [61.367944, 29.314365],
          [61.367822, 29.314546],
          [61.367877, 29.314657],
          [61.367824, 29.314773],
          [61.367873, 29.31499],
          [61.367979, 29.315045],
          [61.368011, 29.31512],
          [61.367923, 29.315254],
          [61.367951, 29.315393],
          [61.367849, 29.31547],
          [61.367846, 29.315656],
          [61.367651, 29.315807],
          [61.367665, 29.31587],
          [61.367444, 29.31609],
          [61.367291, 29.31617],
          [61.366927, 29.316494],
          [61.367198, 29.316528],
          [61.367328, 29.316615],
          [61.367538, 29.316571],
          [61.367663, 29.316634],
          [61.367808, 29.316794],
          [61.366917, 29.317728],
          [61.366699, 29.318124],
          [61.366701, 29.318211],
          [61.366544, 29.318419],
          [61.366552, 29.318488],
          [61.366404, 29.318618],
          [61.366417, 29.318681],
          [61.366218, 29.318972],
          [61.366645, 29.319098],
          [61.366918, 29.319247],
          [61.367005, 29.319361],
          [61.367048, 29.319531],
          [61.367013, 29.319665],
          [61.367359, 29.320167],
          [61.367454, 29.320179],
          [61.36748, 29.320125],
          [61.367607, 29.320093],
          [61.367802, 29.320255],
          [61.368035, 29.320526],
          [61.3681, 29.320758],
          [61.368197, 29.320885],
          [61.368326, 29.32086],
          [61.368498, 29.320623],
          [61.368501, 29.32052],
          [61.368694, 29.320334],
          [61.368863, 29.320306],
          [61.368891, 29.320259],
          [61.368979, 29.320278],
          [61.369011, 29.320188],
          [61.369329, 29.319825],
          [61.369491, 29.319836],
          [61.369624, 29.319925],
          [61.369742, 29.319787],
          [61.369855, 29.319803],
          [61.370222, 29.320039],
          [61.37028, 29.32],
          [61.370392, 29.320015],
          [61.370717, 29.320214],
          [61.370888, 29.320229],
          [61.371053, 29.320309],
          [61.371103, 29.320284],
          [61.371324, 29.320412],
          [61.371451, 29.320406],
          [61.371572, 29.32047],
          [61.371711, 29.320652],
          [61.372156, 29.321058],
          [61.371989, 29.321236],
          [61.372079, 29.321318],
          [61.371653, 29.32185],
          [61.371661, 29.321907],
          [61.371215, 29.322294],
          [61.371206, 29.322487],
          [61.371083, 29.322743],
          [61.370969, 29.322783],
          [61.370729, 29.32298],
          [61.370299, 29.32319],
          [61.37018, 29.323441],
          [61.370186, 29.323489],
          [61.370369, 29.323611],
          [61.370463, 29.323906],
          [61.370567, 29.324024],
          [61.370167, 29.324581],
          [61.370237, 29.324721],
          [61.369999, 29.325022],
          [61.370037, 29.325076],
          [61.369948, 29.325194],
          [61.370116, 29.325263],
          [61.370179, 29.325363],
          [61.370367, 29.325436],
          [61.370424, 29.325625],
          [61.370659, 29.325767],
          [61.370748, 29.325925],
          [61.37047, 29.326213],
          [61.370229, 29.326559],
          [61.370355, 29.326742],
          [61.370537, 29.326884],
          [61.370638, 29.327175],
          [61.370279, 29.327703],
          [61.370313, 29.327787],
          [61.369946, 29.328029],
          [61.369809, 29.328075],
          [61.369737, 29.328518],
          [61.369783, 29.328675],
          [61.369592, 29.328823],
          [61.369417, 29.329058],
          [61.369411, 29.329291],
          [61.369506, 29.329566],
          [61.369622, 29.329714],
          [61.369737, 29.329728],
          [61.369705, 29.329793],
          [61.3698, 29.329874],
          [61.36959, 29.330123],
          [61.369627, 29.330141],
          [61.369645, 29.330367],
          [61.369453, 29.330728],
          [61.369414, 29.330917],
          [61.369522, 29.331223],
          [61.369479, 29.331613],
          [61.369386, 29.331869],
          [61.369434, 29.331952],
          [61.369663, 29.332063],
          [61.370036, 29.332478],
          [61.370074, 29.332774],
          [61.370011, 29.3333],
          [61.369907, 29.333521],
          [61.369758, 29.333656],
          [61.369873, 29.33376],
          [61.370096, 29.333677],
          [61.370274, 29.333742],
          [61.370293, 29.334041],
          [61.369986, 29.33422],
          [61.369864, 29.334364],
          [61.369905, 29.334764],
          [61.370264, 29.334768],
          [61.370566, 29.334971],
          [61.370668, 29.335092],
          [61.370666, 29.3352],
          [61.370797, 29.335404],
          [61.370797, 29.335567],
          [61.370892, 29.335681],
          [61.370743, 29.335901],
          [61.371005, 29.336546],
          [61.371183, 29.336403],
          [61.371559, 29.336304],
          [61.371748, 29.336195],
          [61.371845, 29.33624],
          [61.371871, 29.336335],
          [61.372177, 29.336526],
          [61.372059, 29.33665],
          [61.372111, 29.336879],
          [61.37184, 29.337111],
          [61.371678, 29.337354],
          [61.37137, 29.337667],
          [61.371604, 29.337882],
          [61.371892, 29.337967],
          [61.371968, 29.338074],
          [61.372324, 29.338113],
          [61.372411, 29.338336],
          [61.372551, 29.338413],
          [61.372526, 29.338452],
          [61.372727, 29.338609],
          [61.373051, 29.339275],
          [61.372796, 29.339472],
          [61.37264, 29.339532],
          [61.372313, 29.339881],
          [61.372195, 29.339902],
          [61.371966, 29.340669],
          [61.371836, 29.340765],
          [61.371948, 29.340998],
          [61.371881, 29.341033],
          [61.371849, 29.341157],
          [61.371767, 29.341143],
          [61.371698, 29.341194],
          [61.371618, 29.341337],
          [61.371409, 29.341386],
          [61.371342, 29.341526],
          [61.371235, 29.341569],
          [61.371089, 29.341752],
          [61.370969, 29.341774],
          [61.370883, 29.341869],
          [61.370878, 29.342186],
          [61.370691, 29.342364],
          [61.370775, 29.342468],
          [61.370669, 29.342525],
          [61.370665, 29.34258],
          [61.37055, 29.342623],
          [61.370567, 29.342729],
          [61.370387, 29.342841],
          [61.370374, 29.342914],
          [61.370022, 29.34316],
          [61.370001, 29.343269],
          [61.369798, 29.343552],
          [61.369767, 29.343727],
          [61.36971, 29.343689],
          [61.369592, 29.343884],
          [61.369417, 29.344036],
          [61.369466, 29.344124],
          [61.369399, 29.344079],
          [61.369323, 29.344135],
          [61.369235, 29.344268],
          [61.369055, 29.344357],
          [61.368804, 29.344637],
          [61.368877, 29.344918],
          [61.368814, 29.34497],
          [61.3688, 29.345119],
          [61.368771, 29.345093],
          [61.368705, 29.345131],
          [61.368558, 29.345265],
          [61.368461, 29.345465],
          [61.368474, 29.345531],
          [61.368367, 29.345526],
          [61.368274, 29.345652],
          [61.368165, 29.345874],
          [61.36816, 29.345992],
          [61.367985, 29.346056],
          [61.367761, 29.346262],
          [61.367544, 29.346638],
          [61.367509, 29.346933],
          [61.367296, 29.346932],
          [61.367161, 29.347208],
          [61.367077, 29.347229],
          [61.367051, 29.347376],
          [61.366813, 29.347502],
          [61.366747, 29.347857],
          [61.366413, 29.347977],
          [61.366043, 29.348369],
          [61.366018, 29.348472],
          [61.365905, 29.348421],
          [61.36579, 29.348874],
          [61.365687, 29.348889],
          [61.365434, 29.349042],
          [61.365435, 29.349257],
          [61.365243, 29.349432],
          [61.365286, 29.349546],
          [61.365031, 29.349807],
          [61.364765, 29.349889],
          [61.364786, 29.350066],
          [61.36494, 29.350451],
          [61.365252, 29.350472],
          [61.365443, 29.350615],
          [61.365374, 29.35079],
          [61.36447, 29.351889],
          [61.364443, 29.351988],
          [61.364507, 29.352068],
          [61.364507, 29.352161],
          [61.364106, 29.352607],
          [61.364293, 29.352991],
          [61.364542, 29.352907],
          [61.364647, 29.353018],
          [61.364563, 29.353157],
          [61.364559, 29.35328],
          [61.364369, 29.353396],
          [61.364298, 29.353498],
          [61.364296, 29.353802],
          [61.364362, 29.353982],
          [61.364647, 29.35404],
          [61.364613, 29.354375],
          [61.364921, 29.354495],
          [61.364981, 29.354577],
          [61.364901, 29.354792],
          [61.364737, 29.35504],
          [61.364287, 29.355403],
          [61.364237, 29.35552],
          [61.364261, 29.355711],
          [61.363774, 29.356013],
          [61.363259, 29.356703],
          [61.362841, 29.356874],
          [61.362104, 29.358037],
          [61.361877, 29.35821],
          [61.361787, 29.358394],
          [61.361671, 29.358515],
          [61.361473, 29.358622],
          [61.361256, 29.358881],
          [61.361023, 29.358937],
          [61.36189, 29.359332],
          [61.361996, 29.359399],
          [61.362113, 29.35961],
          [61.362408, 29.359395],
          [61.362674, 29.359616],
          [61.362821, 29.3599],
          [61.363221, 29.359938],
          [61.36329, 29.359807],
          [61.363593, 29.359669],
          [61.363783, 29.35967],
          [61.36415, 29.359196],
          [61.364407, 29.359008],
          [61.364535, 29.358846],
          [61.365183, 29.359167],
          [61.365237, 29.359391],
          [61.36519, 29.359567],
          [61.365567, 29.360157],
          [61.365988, 29.360191],
          [61.366127, 29.360672],
          [61.366672, 29.361312],
          [61.367054, 29.361667],
          [61.367087, 29.361802],
          [61.366945, 29.36184],
          [61.366915, 29.361948],
          [61.366744, 29.362051],
          [61.366542, 29.362301],
          [61.366132, 29.362251],
          [61.366066, 29.362323],
          [61.365993, 29.362557],
          [61.365874, 29.362688],
          [61.365557, 29.36281],
          [61.365417, 29.36295],
          [61.36522, 29.363014],
          [61.36516, 29.363089],
          [61.364756, 29.36313],
          [61.364505, 29.363317],
          [61.364268, 29.363423],
          [61.364189, 29.363561],
          [61.364299, 29.363764],
          [61.364224, 29.364101],
          [61.364277, 29.364321],
          [61.364377, 29.364389],
          [61.364466, 29.36464],
          [61.364389, 29.364602],
          [61.364177, 29.364717],
          [61.363927, 29.365026],
          [61.3639, 29.36531],
          [61.36357, 29.365586],
          [61.363512, 29.365695],
          [61.363579, 29.366064],
          [61.363415, 29.366243],
          [61.363321, 29.366247],
          [61.3633, 29.366368],
          [61.362927, 29.366633],
          [61.36284, 29.366802],
          [61.362755, 29.366868],
          [61.362746, 29.366972],
          [61.362612, 29.36716],
          [61.362647, 29.367374],
          [61.362503, 29.367378],
          [61.362371, 29.367668],
          [61.361823, 29.367968],
          [61.361796, 29.36811],
          [61.361707, 29.368113],
          [61.36156, 29.368411],
          [61.361282, 29.36853],
          [61.361214, 29.368621],
          [61.36122, 29.368738],
          [61.361089, 29.368866],
          [61.361159, 29.369366],
          [61.361259, 29.369596],
          [61.361348, 29.369641],
          [61.36146, 29.369829],
          [61.361604, 29.370403],
          [61.361873, 29.370936],
          [61.361693, 29.371167],
          [61.361767, 29.371287],
          [61.361632, 29.371668],
          [61.36168, 29.371913],
          [61.361532, 29.372402],
          [61.361157, 29.37291],
          [61.361213, 29.373026],
          [61.361155, 29.373064],
          [61.361179, 29.37314],
          [61.361051, 29.373166],
          [61.360862, 29.373088],
          [61.360636, 29.373067],
          [61.360338, 29.373155],
          [61.360203, 29.373153],
          [61.360127, 29.373219],
          [61.36014, 29.373667],
          [61.360228, 29.373803],
          [61.360111, 29.373978],
          [61.35987, 29.374183],
          [61.359846, 29.37426],
          [61.359665, 29.374331],
          [61.359548, 29.374484],
          [61.359594, 29.374929],
          [61.359376, 29.375369],
          [61.359075, 29.375607],
          [61.359073, 29.375725],
          [61.35897, 29.375784],
          [61.358985, 29.375847],
          [61.359056, 29.375831],
          [61.359171, 29.375947],
          [61.359326, 29.376242],
          [61.359438, 29.37622],
          [61.359518, 29.376343],
          [61.359621, 29.376364],
          [61.35982, 29.376716],
          [61.359999, 29.376915],
          [61.359979, 29.377049],
          [61.359871, 29.377227],
          [61.359978, 29.377484],
          [61.359875, 29.377549],
          [61.359882, 29.37767],
          [61.359927, 29.377974],
          [61.360069, 29.378134],
          [61.360123, 29.378458],
          [61.359646, 29.37866],
          [61.359506, 29.378825],
          [61.359225, 29.379006],
          [61.359165, 29.379113],
          [61.358922, 29.379081],
          [61.358691, 29.379271],
          [61.358594, 29.379214],
          [61.358488, 29.379287],
          [61.358395, 29.379261],
          [61.358241, 29.379292],
          [61.357957, 29.37946],
          [61.357733, 29.37939],
          [61.357478, 29.379433],
          [61.357309, 29.379569],
          [61.357247, 29.379692],
          [61.357005, 29.379701],
          [61.356854, 29.379885],
          [61.356593, 29.379985],
          [61.356502, 29.380096],
          [61.356145, 29.380192],
          [61.356051, 29.380299],
          [61.355923, 29.380652],
          [61.355758, 29.380846],
          [61.355774, 29.381087],
          [61.355848, 29.381212],
          [61.355772, 29.381244],
          [61.355768, 29.381343],
          [61.355662, 29.381459],
          [61.355419, 29.381471],
          [61.355061, 29.381703],
          [61.355014, 29.381879],
          [61.355062, 29.382055],
          [61.354965, 29.38228],
          [61.355039, 29.382297],
          [61.35504, 29.382431],
          [61.354813, 29.382716],
          [61.354796, 29.383306],
          [61.354677, 29.383349],
          [61.354581, 29.383532],
          [61.354121, 29.384034],
          [61.354162, 29.384065],
          [61.354152, 29.384256],
          [61.354094, 29.38433],
          [61.354085, 29.384489],
          [61.354161, 29.384594],
          [61.354017, 29.384671],
          [61.353975, 29.384763],
          [61.353691, 29.384836],
          [61.353487, 29.385072],
          [61.353244, 29.385177],
          [61.353224, 29.38545],
          [61.353132, 29.38551],
          [61.352934, 29.38593],
          [61.352766, 29.386044],
          [61.352744, 29.386154],
          [61.35251, 29.386247],
          [61.352083, 29.386669],
          [61.351981, 29.386665],
          [61.351755, 29.386807],
          [61.351651, 29.386999],
          [61.351539, 29.387036],
          [61.351357, 29.387197],
          [61.351234, 29.387229],
          [61.351035, 29.38754],
          [61.350918, 29.387546],
          [61.350765, 29.38764],
          [61.3504, 29.388255],
          [61.350286, 29.388118],
          [61.350278, 29.387956],
          [61.349814, 29.387901],
          [61.349609, 29.387815],
          [61.349266, 29.387864],
          [61.348628, 29.387677],
          [61.348275, 29.387684],
          [61.348033, 29.387517],
          [61.347955, 29.387028],
          [61.347901, 29.38697],
          [61.348134, 29.386601],
          [61.348194, 29.386548],
          [61.348322, 29.386581],
          [61.348477, 29.386448],
          [61.348319, 29.386196],
          [61.348347, 29.385686],
          [61.348603, 29.385486],
          [61.349057, 29.385533],
          [61.349053, 29.385377],
          [61.349246, 29.385148],
          [61.349116, 29.385059],
          [61.349125, 29.385016],
          [61.35024, 29.3842],
          [61.350359, 29.384071],
          [61.350371, 29.383932],
          [61.350605, 29.383812],
          [61.350632, 29.38368],
          [61.350724, 29.383576],
          [61.350911, 29.383458],
          [61.351147, 29.383211],
          [61.351157, 29.383133],
          [61.35096, 29.382989],
          [61.3506, 29.383109],
          [61.35019, 29.3823],
          [61.350164, 29.381924],
          [61.349957, 29.381679],
          [61.349654, 29.381666],
          [61.349568, 29.381622],
          [61.349405, 29.381419],
          [61.349062, 29.381436],
          [61.348482, 29.381695],
          [61.348301, 29.381656],
          [61.34819, 29.381692],
          [61.348171, 29.381787],
          [61.34796, 29.381735],
          [61.347072, 29.381926],
          [61.346601, 29.381839],
          [61.346193, 29.381969],
          [61.346134, 29.382034],
          [61.346169, 29.382321],
          [61.346031, 29.382446],
          [61.346017, 29.382535],
          [61.345926, 29.382589],
          [61.345385, 29.382687],
          [61.345116, 29.382581],
          [61.344991, 29.382608],
          [61.344873, 29.382524],
          [61.344839, 29.382485],
          [61.344867, 29.382286],
          [61.344736, 29.381977],
          [61.344648, 29.381877],
          [61.344545, 29.381858],
          [61.344255, 29.38207],
          [61.344036, 29.382111],
          [61.343794, 29.382083],
          [61.343613, 29.382166],
          [61.343362, 29.382484],
          [61.343428, 29.382699],
          [61.343254, 29.382975],
          [61.343214, 29.383519],
          [61.343112, 29.383661],
          [61.342913, 29.383811],
          [61.342492, 29.383816],
          [61.342284, 29.383904],
          [61.342134, 29.384198],
          [61.342037, 29.384281],
          [61.341774, 29.384272],
          [61.341761, 29.384179],
          [61.341531, 29.384039],
          [61.341584, 29.383887],
          [61.341555, 29.383834],
          [61.340612, 29.383239],
          [61.340597, 29.383061],
          [61.340472, 29.382939],
          [61.340009, 29.382664],
          [61.339546, 29.382457],
          [61.339433, 29.382201],
          [61.339514, 29.381999],
          [61.339949, 29.381556],
          [61.339905, 29.381374],
          [61.339733, 29.381237],
          [61.338991, 29.380875],
          [61.338514, 29.380496],
          [61.337617, 29.379999],
          [61.336651, 29.379633],
          [61.334966, 29.379508],
          [61.334094, 29.379554],
          [61.333649, 29.379991],
          [61.333124, 29.380317],
          [61.332904, 29.380681],
          [61.332589, 29.380946],
          [61.332039, 29.381825],
          [61.331541, 29.382231],
          [61.331018, 29.382812],
          [61.330437, 29.383346],
          [61.329864, 29.383787],
          [61.329342, 29.384277],
          [61.327044, 29.386193],
          [61.325699, 29.386741],
          [61.324618, 29.387531],
          [61.323683, 29.388129],
          [61.323372, 29.38823],
          [61.322657, 29.388338],
          [61.322278, 29.38845],
          [61.32172, 29.388822],
          [61.320686, 29.389512],
          [61.320115, 29.389821],
          [61.31897, 29.390628],
          [61.318524, 29.390878],
          [61.317207, 29.391401],
          [61.316024, 29.391804],
          [61.314358, 29.392759],
          [61.311383, 29.393731],
          [61.310747, 29.394097],
          [61.308947, 29.395202],
          [61.308121, 29.395941],
          [61.30607, 29.398003],
          [61.305513, 29.398435],
          [61.303527, 29.399676],
          [61.302753, 29.400209],
          [61.302513, 29.400497],
          [61.302134, 29.400803],
          [61.301268, 29.401372],
          [61.300881, 29.401864],
          [61.300736, 29.402491],
          [61.300743, 29.403183],
          [61.300541, 29.403838],
          [61.300017, 29.404691],
          [61.299873, 29.405135],
          [61.299979, 29.405865],
          [61.30035, 29.406628],
          [61.300363, 29.407031],
          [61.300304, 29.407286],
          [61.29994, 29.407932],
          [61.299716, 29.408438],
          [61.299624, 29.409541],
          [61.299458, 29.410114],
          [61.299412, 29.411513],
          [61.299478, 29.411996],
          [61.299486, 29.412804],
          [61.299428, 29.413184],
          [61.162524, 29.533339],
          [61.121437, 29.579641],
          [61.048506, 29.661776],
          [60.977171, 29.742049],
          [60.901667, 29.826944],
          [60.895, 29.834167],
          [60.888056, 29.841111],
          [60.881389, 29.848333],
          [60.874444, 29.855556],
          [60.872855, 29.858546],
          [60.897671, 29.885175],
          [60.974386, 29.967083],
          [61.034615, 30.031341],
          [61.094819, 30.095532],
          [61.103467, 30.104732],
          [61.173654, 30.179275],
          [61.243373, 30.253266],
          [61.293325, 30.30608],
          [61.343192, 30.358776],
          [61.402836, 30.422452],
          [61.461496, 30.485037],
          [61.511715, 30.538584],
          [61.528695, 30.555962],
          [61.584683, 30.613033],
          [61.593142, 30.621864],
          [61.705951, 30.739973],
          [61.721945, 30.756707],
          [61.744569, 30.780682],
          [61.765799, 30.802723],
          [61.771535, 30.80864],
          [61.773962, 30.809327],
          [61.778384, 30.81474],
          [61.784077, 30.820665],
          [61.786307, 30.82256],
          [61.790993, 30.825799],
          [61.794515, 30.828676],
          [61.796962, 30.830675],
          [61.801624, 30.832577],
          [61.803606, 30.835296],
          [61.804808, 30.839427],
          [61.803896, 30.843388],
          [61.798141, 30.849874],
          [61.793864, 30.862947],
          [61.792721, 30.867298],
          [61.792866, 30.871505],
          [61.795968, 30.880093],
          [61.796184, 30.883449],
          [61.794538, 30.887168],
          [61.791375, 30.8907],
          [61.787345, 30.894455],
          [61.785612, 30.896612],
          [61.784965, 30.900935],
          [61.783706, 30.905646],
          [61.781965, 30.911189],
          [61.783219, 30.917785],
          [61.785101, 30.929229],
          [61.785528, 30.933259],
          [61.78808, 30.940393],
          [61.790284, 30.943641],
          [61.806111, 30.949722],
          [61.807763, 30.952436],
          [61.81395, 30.955331],
          [61.816595, 30.957632],
          [61.817154, 30.959238],
          [61.819451, 30.961281],
          [61.820621, 30.963139],
          [61.821043, 30.965424],
          [61.822403, 30.967326],
          [61.833035, 30.972275],
          [61.834702, 30.974099],
          [61.835005, 30.97811],
          [61.827726, 30.979968],
          [61.826773, 30.980896],
          [61.826944, 30.983333],
          [61.827072, 30.986441],
          [61.824072, 30.997517],
          [61.823845, 31.000868],
          [61.824737, 31.00515],
          [61.827553, 31.007267],
          [61.828246, 31.008863],
          [61.82725, 31.013654],
          [61.828246, 31.015139],
          [61.832406, 31.016327],
          [61.832969, 31.017776],
          [61.832926, 31.020486],
          [61.830326, 31.023494],
          [61.829373, 31.025537],
          [61.828679, 31.026835],
          [61.82829, 31.028023],
          [61.827943, 31.029174],
          [61.828116, 31.030511],
          [61.82803, 31.032479],
          [61.829763, 31.033927],
          [61.830716, 31.034632],
          [61.833272, 31.034818],
          [61.834052, 31.034483],
          [61.834789, 31.034335],
          [61.835482, 31.034929],
          [61.838651, 31.035222],
          [61.839035, 31.036451],
          [61.837952, 31.036414],
          [61.837475, 31.040052],
          [61.835569, 31.042057],
          [61.835222, 31.042539],
          [61.834832, 31.043542],
          [61.834095, 31.043987],
          [61.833835, 31.044692],
          [61.830716, 31.0464],
          [61.829243, 31.047625],
          [61.827596, 31.052228],
          [61.82738, 31.053712],
          [61.828376, 31.054343],
          [61.829373, 31.055865],
          [61.828853, 31.058835],
          [61.829329, 31.060319],
          [61.828853, 31.061989],
          [61.82842, 31.063066],
          [61.827813, 31.063734],
          [61.82803, 31.064402],
          [61.827553, 31.065144],
          [61.827683, 31.065812],
          [61.827163, 31.068299],
          [61.82751, 31.069412],
          [61.826643, 31.07175],
          [61.8266, 31.074422],
          [61.82764, 31.076463],
          [61.829026, 31.078727],
          [61.829546, 31.080433],
          [61.830932, 31.082252],
          [61.831279, 31.085703],
          [61.831582, 31.088485],
          [61.830369, 31.091862],
          [61.828246, 31.093791],
          [61.82582, 31.095127],
          [61.820837, 31.100247],
          [61.819364, 31.101026],
          [61.818454, 31.102139],
          [61.816288, 31.104179],
          [61.814511, 31.107407],
          [61.813688, 31.109484],
          [61.811175, 31.113898],
          [61.811349, 31.115864],
          [61.812605, 31.117534],
          [61.812302, 31.120983],
          [61.811219, 31.123505],
          [61.809919, 31.127734],
          [61.809052, 31.130367],
          [61.809745, 31.131813],
          [61.807492, 31.136338],
          [61.805543, 31.13834],
          [61.806713, 31.140788],
          [61.807232, 31.142791],
          [61.806453, 31.143977],
          [61.806063, 31.145053],
          [61.80342, 31.145201],
          [61.80303, 31.146499],
          [61.803506, 31.148279],
          [61.802336, 31.150466],
          [61.80277, 31.153025],
          [61.80433, 31.153915],
          [61.80381, 31.154768],
          [61.804849, 31.157734],
          [61.806366, 31.15981],
          [61.805933, 31.160255],
          [61.804763, 31.161145],
          [61.804589, 31.163036],
          [61.804546, 31.165779],
          [61.803593, 31.167818],
          [61.80199, 31.168597],
          [61.801253, 31.169412],
          [61.800387, 31.17008],
          [61.79939, 31.172749],
          [61.797917, 31.173787],
          [61.798394, 31.174343],
          [61.798134, 31.175196],
          [61.797137, 31.175566],
          [61.796877, 31.176456],
          [61.796054, 31.177679],
          [61.796444, 31.179681],
          [61.795274, 31.180941],
          [61.795317, 31.181979],
          [61.794451, 31.182202],
          [61.793411, 31.184166],
          [61.792198, 31.185093],
          [61.792111, 31.185945],
          [61.791808, 31.186835],
          [61.791808, 31.187428],
          [61.789338, 31.18943],
          [61.788905, 31.190764],
          [61.788992, 31.192098],
          [61.787908, 31.194507],
          [61.785872, 31.195916],
          [61.785049, 31.196064],
          [61.784529, 31.196546],
          [61.782753, 31.196842],
          [61.782709, 31.198325],
          [61.781279, 31.199955],
          [61.779156, 31.200141],
          [61.778506, 31.200771],
          [61.777857, 31.20103],
          [61.777326, 31.201865],
          [61.77631, 31.202812],
          [61.774361, 31.204702],
          [61.772324, 31.205666],
          [61.771631, 31.206259],
          [61.771501, 31.207593],
          [61.772584, 31.209705],
          [61.774231, 31.211076],
          [61.774317, 31.213151],
          [61.775834, 31.213077],
          [61.776852, 31.21392],
          [61.774217, 31.228407],
          [61.770859, 31.245056],
          [61.769906, 31.250852],
          [61.774119, 31.265426],
          [61.778385, 31.302269],
          [61.770556, 31.311667],
          [61.720451, 31.361905],
          [61.706667, 31.375896],
          [61.606245, 31.388854],
          [61.571421, 31.394233],
          [61.451528, 31.409494],
          [61.318706, 31.426561],
          [61.17415, 31.445455],
          [61.041925, 31.462594],
          [60.909528, 31.479752],
          [60.856057, 31.486519],
          [60.856971, 31.483481],
          [60.856971, 31.483202],
          [60.857251, 31.482371],
          [60.857531, 31.47876],
          [60.857251, 31.47848],
          [60.857313, 31.475852],
          [60.857531, 31.466536],
          [60.856971, 31.464586],
          [60.856971, 31.463205],
          [60.855561, 31.457559],
          [60.853084, 31.44764],
          [60.851114, 31.443736],
          [60.845849, 31.433301],
          [60.841542, 31.424764],
          [60.837251, 31.416259],
          [60.835585, 31.414038],
          [60.83453, 31.412841],
          [60.831418, 31.409308],
          [60.828638, 31.405977],
          [60.826835, 31.404309],
          [60.818767, 31.396845],
          [60.809875, 31.388619],
          [60.797172, 31.376865],
          [60.783743, 31.364438],
          [60.775923, 31.3572],
          [60.771199, 31.352829],
          [60.765313, 31.347381],
          [60.758093, 31.340697],
          [60.754192, 31.337086],
          [60.75184, 31.335176],
          [60.749745, 31.333475],
          [60.743079, 31.329034],
          [60.742416, 31.32869],
          [60.73732, 31.326047],
          [60.728689, 31.32157],
          [60.720997, 31.31758],
          [60.707067, 31.310353],
          [60.698633, 31.305977],
          [60.693339, 31.303459],
          [60.68818, 31.301005],
          [60.682933, 31.298508],
          [60.67709, 31.295728],
          [60.671572, 31.293102],
          [60.67002, 31.292364],
          [60.66974, 31.292084],
          [60.669466, 31.291533],
          [60.668732, 31.290709],
          [60.667836, 31.289702],
          [60.66724, 31.289033],
          [60.662822, 31.287512],
          [60.658354, 31.285972],
          [60.65252, 31.285142],
          [60.64974, 31.284312],
          [60.648627, 31.284311],
          [60.648074, 31.284032],
          [60.642959, 31.282919],
          [60.636316, 31.281473],
          [60.630323, 31.280169],
          [60.628907, 31.279861],
          [60.622458, 31.279058],
          [60.615574, 31.2782],
          [60.613977, 31.277748],
          [60.609671, 31.276528],
          [60.598907, 31.273479],
          [60.597794, 31.273479],
          [60.597241, 31.273199],
          [60.596408, 31.273199],
          [60.590113, 31.271967],
          [60.584331, 31.270836],
          [60.583628, 31.270698],
          [60.578628, 31.269868],
          [60.576408, 31.269308],
          [60.575015, 31.269308],
          [60.573068, 31.268748],
          [60.569734, 31.268198],
          [60.569533, 31.268198],
          [60.566961, 31.268198],
          [60.566401, 31.267918],
          [60.565295, 31.267918],
          [60.565015, 31.267918],
          [60.564735, 31.267638],
          [60.562235, 31.267367],
          [60.559461, 31.266537],
          [60.558068, 31.266537],
          [60.526682, 31.257916],
          [60.525015, 31.257085],
          [60.524182, 31.257085],
          [60.501402, 31.251533],
          [60.500009, 31.251533],
          [60.499456, 31.251253],
          [60.461956, 31.242642],
          [60.456676, 31.241531],
          [60.454456, 31.241251],
          [60.423616, 31.2357],
          [60.387783, 31.224587],
          [60.368064, 31.214865],
          [60.367784, 31.214305],
          [60.356397, 31.206804],
          [60.352231, 31.204583],
          [60.278058, 31.151811],
          [60.276112, 31.150141],
          [60.275559, 31.149861],
          [60.275559, 31.149581],
          [60.248612, 31.124306],
          [60.247499, 31.120975],
          [60.248612, 31.109863],
          [60.253612, 31.099301],
          [60.256665, 31.09486],
          [60.273891, 31.072086],
          [60.278058, 31.059024],
          [60.279445, 31.050692],
          [60.280005, 31.043191],
          [60.280278, 31.042081],
          [60.298058, 31.028189],
          [60.298891, 31.026528],
          [60.299171, 31.022638],
          [60.288338, 31.013467],
          [60.285558, 31.011526],
          [60.284171, 31.010687],
          [60.264725, 31.004025],
          [60.257498, 30.999305],
          [60.249998, 30.993194],
          [60.236392, 30.966521],
          [60.235552, 30.965691],
          [60.235552, 30.96514],
          [60.234998, 30.964301],
          [60.230279, 30.95819],
          [60.228332, 30.956249],
          [60.225552, 30.954029],
          [60.224718, 30.953189],
          [60.222218, 30.951808],
          [60.221945, 30.951249],
          [60.220552, 30.950689],
          [60.200832, 30.940137],
          [60.169439, 30.932357],
          [60.168053, 30.932357],
          [60.165553, 30.931527],
          [60.160833, 30.931527],
          [60.157493, 30.931527],
          [60.154993, 30.931527],
          [60.153326, 30.931806],
          [60.152773, 30.931527],
          [60.14972, 30.931527],
          [60.148886, 30.931247],
          [60.13944, 30.931247],
          [60.138886, 30.930967],
          [60.137493, 30.930967],
          [60.13666, 30.930687],
          [60.131939, 30.930137],
          [60.127773, 30.930137],
          [60.12722, 30.929857],
          [60.12472, 30.929857],
          [60.123887, 30.930137],
          [60.123607, 30.929857],
          [60.122773, 30.930137],
          [60.107213, 30.930137],
          [60.10666, 30.929857],
          [60.100827, 30.929586],
          [60.093327, 30.929857],
          [60.090827, 30.929586],
          [60.085274, 30.929586],
          [60.084161, 30.929306],
          [60.075827, 30.929306],
          [60.074714, 30.929027],
          [60.0686, 30.929027],
          [60.068327, 30.929306],
          [60.060267, 30.929306],
          [60.058601, 30.929027],
          [60.057494, 30.929306],
          [60.047768, 30.929306],
          [60.047494, 30.929027],
          [60.044434, 30.929027],
          [60.044154, 30.928747],
          [60.040268, 30.928747],
          [60.039714, 30.928467],
          [60.035268, 30.928467],
          [60.034435, 30.928187],
          [60.026381, 30.927917],
          [60.023321, 30.928187],
          [60.021655, 30.928187],
          [60.021101, 30.928467],
          [60.017215, 30.928467],
          [60.016935, 30.928747],
          [60.000541, 30.928747],
          [59.993602, 30.927357],
          [59.977489, 30.919856],
          [59.971655, 30.915136],
          [59.971095, 30.914025],
          [59.958322, 30.897354],
          [59.956929, 30.895134],
          [59.956929, 30.894854],
          [59.956375, 30.894304],
          [59.953322, 30.886253],
          [59.952489, 30.884303],
          [59.952489, 30.883473],
          [59.951929, 30.882353],
          [59.949429, 30.865412],
          [59.949429, 30.859861],
          [59.948875, 30.855691],
          [59.948875, 30.84458],
          [59.947489, 30.83319],
          [59.947489, 30.82513],
          [59.946929, 30.820409],
          [59.946929, 30.819299],
          [59.946929, 30.815409],
          [59.946655, 30.811799],
          [59.946375, 30.810969],
          [59.946655, 30.807358],
          [59.946095, 30.794578],
          [59.945262, 30.783748],
          [59.944989, 30.781518],
          [59.944709, 30.779578],
          [59.944429, 30.777078],
          [59.944155, 30.775137],
          [59.943875, 30.773468],
          [59.943595, 30.771798],
          [59.943322, 30.770688],
          [59.942762, 30.769027],
          [59.942489, 30.766798],
          [59.942209, 30.765688],
          [59.939708, 30.761247],
          [59.939155, 30.760688],
          [59.939155, 30.760408],
          [59.934155, 30.753188],
          [59.879703, 30.711518],
          [59.878036, 30.710408],
          [59.877483, 30.709849],
          [59.785257, 30.66319],
          [59.781644, 30.66152],
          [59.771644, 30.65708],
          [59.732751, 30.638741],
          [59.700805, 30.625683],
          [59.683865, 30.617073],
          [59.682199, 30.615963],
          [59.679699, 30.614853],
          [59.676639, 30.612904],
          [59.579966, 30.567908],
          [59.57636, 30.566518],
          [59.547187, 30.555409],
          [59.54608, 30.555409],
          [59.5458, 30.555129],
          [59.545247, 30.555129],
          [59.5358, 30.55179],
          [59.529967, 30.54902],
          [59.528581, 30.54874],
          [59.493854, 30.533733],
          [59.491634, 30.532352],
          [59.490801, 30.532073],
          [59.490241, 30.531522],
          [59.489688, 30.531522],
          [59.489134, 30.530963],
          [59.473855, 30.522904],
          [59.464052, 30.517078],
          [59.463575, 30.516795],
          [59.464961, 30.515405],
          [59.464962, 30.514845],
          [59.468295, 30.499577],
          [59.468021, 30.498737],
          [59.468021, 30.489849],
          [59.467188, 30.485129],
          [59.466628, 30.48346],
          [59.466355, 30.482621],
          [59.460241, 30.460124],
          [59.460241, 30.459014],
          [59.459688, 30.455125],
          [59.459408, 30.454015],
          [59.453855, 30.429849],
          [59.453575, 30.42707],
          [59.453295, 30.423461],
          [59.453021, 30.422631],
          [59.453021, 30.417902],
          [59.446075, 30.38346],
          [59.446075, 30.38262],
          [59.445241, 30.38068],
          [59.444408, 30.377342],
          [59.443295, 30.374572],
          [59.442181, 30.368733],
          [59.441908, 30.364844],
          [59.441628, 30.362074],
          [59.441348, 30.359566],
          [59.441075, 30.357906],
          [59.439408, 30.345679],
          [59.439128, 30.342629],
          [59.438848, 30.337901],
          [59.438575, 30.335961],
          [59.436348, 30.324294],
          [59.436075, 30.320676],
          [59.435794, 30.318177],
          [59.435241, 30.314847],
          [59.434961, 30.313178],
          [59.432181, 30.299842],
          [59.431348, 30.295403],
          [59.430795, 30.291794],
          [59.429128, 30.282626],
          [59.428848, 30.280957],
          [59.428015, 30.2729],
          [59.427741, 30.270401],
          [59.427181, 30.265402],
          [59.426908, 30.263182],
          [59.426348, 30.259573],
          [59.424961, 30.252626],
          [59.426075, 30.234842],
          [59.425794, 30.21151],
          [59.425794, 30.209011],
          [59.426074, 30.194567],
          [59.426074, 30.193736],
          [59.426348, 30.193457],
          [59.426348, 30.192627],
          [59.428574, 30.18457],
          [59.429128, 30.178732],
          [59.429681, 30.151504],
          [59.429407, 30.145676],
          [59.429681, 30.144566],
          [59.430514, 30.138178],
          [59.431347, 30.13484],
          [59.431627, 30.131232],
          [59.431347, 30.130122],
          [59.431627, 30.128733],
          [59.431908, 30.123455],
          [59.431907, 30.120676],
          [59.432181, 30.118727],
          [59.433847, 30.104563],
          [59.434407, 30.099006],
          [59.435241, 30.070949],
          [59.435794, 30.06928],
          [59.438294, 30.054566],
          [59.438574, 30.052067],
          [59.440794, 30.037065],
          [59.441627, 30.035116],
          [59.44496, 30.017615],
          [59.44746, 30.000051],
          [59.449407, 29.993448],
          [59.449127, 29.992618],
          [59.448854, 29.990949],
          [59.448293, 29.98817],
          [59.44802, 29.986501],
          [59.44774, 29.985112],
          [59.44746, 29.983732],
          [59.447187, 29.982613],
          [59.446907, 29.980954],
          [59.443294, 29.968451],
          [59.440513, 29.954838],
          [59.440513, 29.954559],
          [59.44024, 29.952899],
          [59.43996, 29.95123],
          [59.435513, 29.931222],
          [59.43524, 29.929003],
          [59.43496, 29.925675],
          [59.43468, 29.923167],
          [59.434407, 29.920668],
          [59.434127, 29.919288],
          [59.43274, 29.910394],
          [59.43218, 29.907896],
          [59.43246, 29.902059],
          [59.433014, 29.888727],
          [59.433574, 29.872338],
          [59.433574, 29.871779],
          [59.438847, 29.814836],
          [59.444959, 29.754838],
          [59.446073, 29.750392],
          [59.446626, 29.747055],
          [59.446626, 29.745666],
          [59.448573, 29.735393],
          [59.448853, 29.730387],
          [59.448573, 29.729557],
          [59.448853, 29.72733],
          [59.448573, 29.711781],
          [59.447459, 29.699001],
          [59.447739, 29.693725],
          [59.447459, 29.674551],
          [59.447739, 29.672333],
          [59.448019, 29.650661],
          [59.448853, 29.639],
          [59.449126, 29.629549],
          [59.449959, 29.623994],
          [59.449959, 29.621496],
          [59.451906, 29.611495],
          [59.451906, 29.610944],
          [59.455792, 29.591781],
          [59.456073, 29.588165],
          [59.456352, 29.578993],
          [59.456072, 29.577334],
          [59.456352, 29.574548],
          [59.456072, 29.572888],
          [59.455239, 29.504273],
          [59.457739, 29.480107],
          [59.458019, 29.472046],
          [59.458019, 29.471775],
          [59.458019, 29.466221],
          [59.457459, 29.461496],
          [59.459685, 29.443715],
          [59.460792, 29.442047],
          [59.460792, 29.441217],
          [59.461625, 29.439828],
          [59.461625, 29.439549],
          [59.461625, 29.439269],
          [59.461905, 29.438719],
          [59.463292, 29.433715],
          [59.463292, 29.418161],
          [59.462739, 29.414266],
          [59.462458, 29.404826],
          [59.461905, 29.40066],
          [59.462185, 29.397883],
          [59.463572, 29.374269],
          [59.464685, 29.370663],
          [59.465518, 29.367327],
          [59.466905, 29.363161],
          [59.469958, 29.353992],
          [59.473571, 29.32955],
          [59.474125, 29.328432],
          [59.480798, 29.298157],
          [59.483851, 29.280108],
          [59.484684, 29.276213],
          [59.491904, 29.25344],
          [59.494404, 29.244542],
          [59.508851, 29.209267],
          [59.509404, 29.207049],
          [59.510797, 29.203154],
          [59.51413, 29.192039],
          [59.51607, 29.187875],
          [59.517464, 29.183439],
          [59.523017, 29.171766],
          [59.52385, 29.170927],
          [59.523973, 29.170652],
          [59.528297, 29.16093],
          [59.55135, 29.123431],
          [59.552743, 29.120655],
          [59.569409, 29.092324],
          [59.569689, 29.090927],
          [59.569409, 29.090656],
          [59.569409, 29.08843],
          [59.570796, 29.082039],
          [59.566076, 29.072322],
          [59.565523, 29.072043],
          [59.565523, 29.071484],
          [59.564963, 29.070934],
          [59.564129, 29.069267],
          [59.537463, 29.045372],
          [59.537463, 29.045093],
          [59.536076, 29.042596],
          [59.52941, 29.032871],
          [59.508297, 29.004263],
          [59.505517, 28.99982],
          [59.496903, 28.96093],
          [59.500517, 28.947592],
          [59.514963, 28.916761],
          [59.525796, 28.897592],
          [59.526349, 28.897313],
          [59.531349, 28.889535],
          [59.531909, 28.887589],
          [59.534409, 28.884254],
          [59.539409, 28.870367],
          [59.540242, 28.864257],
          [59.540796, 28.86259],
          [59.550516, 28.815929],
          [59.550796, 28.812534],
          [59.550796, 28.809811],
          [59.551076, 28.80954],
          [59.551629, 28.803422],
          [59.551349, 28.803152],
          [59.551349, 28.801764],
          [59.551349, 28.801205],
          [59.551454, 28.800891],
          [59.551577, 28.800523],
          [59.551629, 28.800367],
          [59.551909, 28.798421],
          [59.552182, 28.789815],
          [59.555515, 28.771758],
          [59.556075, 28.766477],
          [59.556355, 28.757872],
          [59.561624, 28.724018],
          [59.562188, 28.720092],
          [59.562742, 28.713983],
          [59.563022, 28.706757],
          [59.562742, 28.690368],
          [59.555795, 28.664257],
          [59.553848, 28.660365],
          [59.546075, 28.643418],
          [59.546075, 28.635923],
          [59.545795, 28.634535],
          [59.546075, 28.623697],
          [59.550241, 28.594535],
          [59.558295, 28.569806],
          [59.558575, 28.56786],
          [59.560521, 28.561194],
          [59.564961, 28.538691],
          [59.566074, 28.525917],
          [59.566074, 28.518422],
          [59.565794, 28.515089],
          [59.565241, 28.509811],
          [59.564408, 28.506468],
          [59.564408, 28.505081],
          [59.563854, 28.503694],
          [59.560794, 28.489804],
          [59.556355, 28.484255],
          [59.547742, 28.47759],
          [59.536348, 28.47314],
          [59.533848, 28.47314],
          [59.532742, 28.472861],
          [59.529961, 28.472861],
          [59.529128, 28.472581],
          [59.526075, 28.472581],
          [59.525242, 28.472302],
          [59.516486, 28.472302],
          [59.516348, 28.472302],
          [59.516038, 28.47238],
          [59.515235, 28.472581],
          [59.513015, 28.472581],
          [59.509402, 28.47314],
          [59.507182, 28.47314],
          [59.483569, 28.477031],
          [59.480516, 28.477031],
          [59.478849, 28.47731],
          [59.469841, 28.477585],
          [59.469683, 28.47759],
          [59.469287, 28.477689],
          [59.468569, 28.477869],
          [59.45107, 28.479526],
          [59.446903, 28.480364],
          [59.439403, 28.480914],
          [59.43607, 28.481472],
          [59.434403, 28.481472],
          [59.40607, 28.483418],
          [59.399397, 28.483418],
          [59.394957, 28.483697],
          [59.373291, 28.483697],
          [59.369678, 28.483418],
          [59.364677, 28.483418],
          [59.361618, 28.483139],
          [59.351064, 28.483139],
          [59.349678, 28.483418],
          [59.346898, 28.483418],
          [59.343845, 28.483697],
          [59.339678, 28.483697],
          [59.336898, 28.483976],
          [59.332451, 28.483976],
          [59.331065, 28.484256],
          [59.324671, 28.484256],
          [59.323005, 28.483976],
          [59.319672, 28.483976],
          [59.307452, 28.483139],
          [59.302732, 28.483139],
          [59.299399, 28.48286],
          [59.279392, 28.481752],
          [59.242726, 28.477311],
          [59.230506, 28.476473],
          [59.221893, 28.475365],
          [59.212727, 28.473699],
          [59.207166, 28.472303],
          [59.193833, 28.469528],
          [59.182167, 28.467313],
          [59.172167, 28.463971],
          [59.16772, 28.461746],
          [59.165781, 28.461197],
          [59.164947, 28.460638],
          [59.162167, 28.459809],
          [59.150501, 28.45509],
          [59.148834, 28.45481],
          [59.147447, 28.453973],
          [59.073828, 28.42369],
          [59.071889, 28.422583],
          [59.064382, 28.418692],
          [59.064109, 28.418142],
          [59.062348, 28.417184],
          [59.062897, 28.416772],
          [59.061989, 28.416278],
          [59.061716, 28.414882],
          [59.061436, 28.414612],
          [59.062823, 28.411549],
          [59.066436, 28.409334],
          [59.06755, 28.409054],
          [59.099495, 28.387942],
          [59.116442, 28.37155],
          [59.122548, 28.363219],
          [59.122828, 28.362102],
          [59.125048, 28.354879],
          [59.125048, 28.348215],
          [59.125048, 28.342108],
          [59.126161, 28.331274],
          [59.125881, 28.330166],
          [59.125881, 28.325438],
          [59.125327, 28.318774],
          [59.125047, 28.315162],
          [59.124774, 28.314883],
          [59.12616, 28.298493],
          [59.14894, 28.272656],
          [59.153939, 28.265442],
          [59.161439, 28.250719],
          [59.162826, 28.245712],
          [59.164219, 28.237103],
          [59.164772, 28.235716],
          [59.165052, 28.232934],
          [59.164772, 28.231826],
          [59.164772, 28.229881],
          [59.155052, 28.196267],
          [59.154772, 28.192656],
          [59.154492, 28.189882],
          [59.150052, 28.151552],
          [59.150051, 28.143493],
          [59.149491, 28.134327],
          [59.148384, 28.127376],
          [59.146991, 28.124045],
          [59.146438, 28.123217],
          [59.141991, 28.119048],
          [59.128105, 28.112376],
          [59.126438, 28.112377],
          [59.125896, 28.112575],
          [59.1255, 28.112575],
          [59.125193, 28.112687],
          [59.124515, 28.112935],
          [59.115467, 28.111202],
          [59.092964, 28.111737],
          [59.082551, 28.111983],
          [59.07303, 28.11504],
          [59.072711, 28.115039],
          [59.072092, 28.115238],
          [59.052985, 28.115186],
          [59.033904, 28.11513],
          [59.014555, 28.107066],
          [59.006097, 28.097551],
          [59.005496, 28.097017],
          [58.980769, 28.080352],
          [58.979265, 28.078849],
          [58.978939, 28.078524],
          [58.978549, 28.078137],
          [58.978269, 28.077579],
          [58.956883, 28.060077],
          [58.956603, 28.059798],
          [58.953823, 28.057575],
          [58.95355, 28.057304],
          [58.952156, 28.055351],
          [58.951043, 28.054523],
          [58.94827, 28.050912],
          [58.933823, 28.029801],
          [58.931876, 28.022572],
          [58.931603, 28.022302],
          [58.93299, 28.004522],
          [58.933823, 27.999796],
          [58.933823, 27.997852],
          [58.934376, 27.993684],
          [58.933823, 27.988967],
          [58.933543, 27.987301],
          [58.93271, 27.985078],
          [58.93299, 27.981747],
          [58.93271, 27.981189],
          [58.93271, 27.975626],
          [58.932436, 27.974519],
          [58.932436, 27.973132],
          [58.932156, 27.972574],
          [58.931876, 27.971746],
          [58.928823, 27.96368],
          [58.890763, 27.898961],
          [58.89049, 27.898124],
          [58.891877, 27.881184],
          [58.893544, 27.875631],
          [58.89521, 27.867575],
          [58.89743, 27.854515],
          [58.898823, 27.848962],
          [58.900763, 27.837298],
          [58.902156, 27.818955],
          [58.90271, 27.802576],
          [58.902429, 27.800353],
          [58.90271, 27.797572],
          [58.90243, 27.795349],
          [58.90299, 27.784792],
          [58.903263, 27.768962],
          [58.90299, 27.761736],
          [58.903263, 27.759792],
          [58.903823, 27.749236],
          [58.904929, 27.713961],
          [58.904929, 27.711459],
          [58.905209, 27.705628],
          [58.904929, 27.704242],
          [58.904929, 27.703954],
          [58.905763, 27.679794],
          [58.905763, 27.670624],
          [58.902989, 27.645628],
          [58.902429, 27.641183],
          [58.901954, 27.637795],
          [58.90021, 27.625348],
          [58.901043, 27.60424],
          [58.900763, 27.603124],
          [58.901043, 27.599516],
          [58.900763, 27.598679],
          [58.901043, 27.594514],
          [58.901043, 27.576457],
          [58.898542, 27.558958],
          [58.897156, 27.554235],
          [58.891414, 27.535807],
          [58.891043, 27.534785],
          [58.890489, 27.533957],
          [58.890489, 27.533678],
          [58.890489, 27.532842],
          [58.886043, 27.514507],
          [58.879096, 27.497289],
          [58.878823, 27.495346],
          [58.875483, 27.481178],
          [58.87521, 27.479793],
          [58.874929, 27.478677],
          [58.874649, 27.478119],
          [58.874649, 27.477291],
          [58.874649, 27.477013],
          [58.874649, 27.476734],
          [58.874096, 27.475619],
          [58.870763, 27.462009],
          [58.865763, 27.437281],
          [58.862429, 27.426172],
          [58.854096, 27.393126],
          [58.836869, 27.301731],
          [58.835483, 27.292001],
          [58.83187, 27.262001],
          [58.831036, 27.253674],
        ],
      ],
    },
  },
  Tehran: {
    properties: { value: "Tehran", name: "استان تهران, ایران", id: 30 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [51.1080551, 35.8424472],
          [51.1105442, 35.8218498],
          [51.1145782, 35.8125235],
          [51.1196423, 35.7915702],
          [51.118784, 35.7836329],
          [51.1127758, 35.772979],
          [51.1100292, 35.7684525],
          [51.0988712, 35.764831],
          [51.0957813, 35.7622541],
          [51.0945797, 35.7581448],
          [51.0931635, 35.7552194],
          [51.0921335, 35.7490548],
          [51.0890007, 35.7428201],
          [51.0933995, 35.7421322],
          [51.0925412, 35.7402599],
          [51.0913825, 35.7403644],
          [51.0905778, 35.7374994],
          [51.0903525, 35.736045],
          [51.0905671, 35.7350087],
          [51.0855567, 35.7336413],
          [51.0805035, 35.7335717],
          [51.0776281, 35.7341726],
          [51.0755467, 35.7339984],
          [51.0729611, 35.7339462],
          [51.0703218, 35.7342945],
          [51.0680902, 35.7341813],
          [51.0642064, 35.7344948],
          [51.0631657, 35.7342336],
          [51.0586703, 35.7354964],
          [51.0567069, 35.735148],
          [51.0543251, 35.7356792],
          [51.0529625, 35.7380045],
          [51.0512245, 35.7393281],
          [51.0495132, 35.7385183],
          [51.0474747, 35.7382265],
          [51.042577, 35.7380611],
          [51.0378188, 35.7385923],
          [51.0348791, 35.7391496],
          [51.0306036, 35.7395067],
          [51.0289085, 35.739167],
          [51.0268325, 35.7376779],
          [51.0257381, 35.7389232],
          [51.0254592, 35.7387534],
          [51.0215163, 35.7333757],
          [51.020835, 35.7319953],
          [51.0188234, 35.7294174],
          [51.0109109, 35.7223144],
          [51.0085773, 35.719575],
          [51.0066515, 35.7150323],
          [51.007145, 35.7144269],
          [51.0062599, 35.7129547],
          [51.0060883, 35.712824],
          [51.00582, 35.7128502],
          [51.0055947, 35.7128284],
          [51.0050797, 35.7117525],
          [51.0045058, 35.7065255],
          [51.0023439, 35.7071702],
          [50.997591, 35.70044],
          [50.9939057, 35.7021999],
          [50.9935141, 35.7011108],
          [50.990054, 35.7018645],
          [50.9898287, 35.7013156],
          [50.956226, 35.7072704],
          [50.9555125, 35.7069088],
          [50.9486461, 35.7082505],
          [50.9440649, 35.7093133],
          [50.938561, 35.7106462],
          [50.9422195, 35.7199583],
          [50.9403956, 35.7205593],
          [50.9370697, 35.7212997],
          [50.9324133, 35.7221794],
          [50.9284222, 35.7200715],
          [50.9245169, 35.7169879],
          [50.9214163, 35.7139826],
          [50.9178436, 35.7080675],
          [50.91676, 35.7052187],
          [50.9170496, 35.702152],
          [50.9160948, 35.6943278],
          [50.9192491, 35.6928029],
          [50.9188414, 35.6918009],
          [50.9058809, 35.6950336],
          [50.9046364, 35.6955476],
          [50.9042394, 35.6960269],
          [50.9039176, 35.6965148],
          [50.9000766, 35.7064994],
          [50.9001088, 35.7072399],
          [50.9027052, 35.7194269],
          [50.8991754, 35.7218223],
          [50.8982849, 35.7218397],
          [50.894959, 35.7189479],
          [50.8769453, 35.7124495],
          [50.8732867, 35.7195837],
          [50.8537924, 35.7121271],
          [50.8508098, 35.7101061],
          [50.8445334, 35.7088342],
          [50.8370233, 35.7079978],
          [50.8319592, 35.7064645],
          [50.8295882, 35.7052448],
          [50.8285797, 35.7048789],
          [50.8266485, 35.7049748],
          [50.8163273, 35.7042952],
          [50.8153617, 35.7038073],
          [50.8139455, 35.7043126],
          [50.8118105, 35.7048179],
          [50.8110917, 35.6939008],
          [50.8019614, 35.6984229],
          [50.7849455, 35.7020997],
          [50.7696247, 35.7041297],
          [50.7594001, 35.6987279],
          [50.7366014, 35.6821369],
          [50.7140923, 35.6832611],
          [50.6575727, 35.6842807],
          [50.6021261, 35.6854658],
          [50.556078, 35.6779712],
          [50.5017471, 35.6768905],
          [50.4781866, 35.6746594],
          [50.4018402, 35.6624568],
          [50.3910255, 35.6606436],
          [50.3740311, 35.6584121],
          [50.3554916, 35.6423707],
          [50.3508568, 35.6360928],
          [50.3496552, 35.6344186],
          [50.334549, 35.610697],
          [50.3323174, 35.5912959],
          [50.3364372, 35.5794296],
          [50.3359222, 35.5716807],
          [50.333004, 35.5509433],
          [50.3351498, 35.5509433],
          [50.357337, 35.5637215],
          [50.3884077, 35.5643499],
          [50.4053164, 35.5597416],
          [50.4228687, 35.5582055],
          [50.4523087, 35.5590085],
          [50.4924774, 35.5684692],
          [50.531745, 35.5706335],
          [50.5587387, 35.5547491],
          [50.5704975, 35.5538064],
          [50.5883503, 35.552724],
          [50.5967617, 35.552724],
          [50.6062889, 35.5532477],
          [50.6180048, 35.5525494],
          [50.6270599, 35.546893],
          [50.6497622, 35.5251713],
          [50.6526375, 35.5242282],
          [50.6558132, 35.5208053],
          [50.6766701, 35.5129808],
          [50.6884289, 35.5116184],
          [50.7561493, 35.4877207],
          [50.7686377, 35.486917],
          [50.7710838, 35.486288],
          [50.7714701, 35.4860783],
          [50.7730579, 35.4861482],
          [50.7746458, 35.4854144],
          [50.7761478, 35.4856241],
          [50.7772207, 35.4849252],
          [50.7787657, 35.48503],
          [50.7823706, 35.4841214],
          [50.7843447, 35.4842263],
          [50.7850313, 35.4838069],
          [50.786705, 35.4838069],
          [50.787735, 35.4834924],
          [50.7894516, 35.4835973],
          [50.7904816, 35.483108],
          [50.7985926, 35.4832828],
          [50.7994509, 35.4836322],
          [50.8000517, 35.4833177],
          [50.8007813, 35.4835973],
          [50.8090639, 35.484366],
          [50.8104801, 35.4842263],
          [50.8113813, 35.4847854],
          [50.8215523, 35.4847155],
          [50.8224535, 35.484401],
          [50.8449841, 35.4814306],
          [50.8521509, 35.4808016],
          [50.8570433, 35.4808016],
          [50.8615065, 35.4819199],
          [50.8621931, 35.4818849],
          [50.8626652, 35.4821994],
          [50.8633089, 35.4820946],
          [50.8636522, 35.482479],
          [50.8732224, 35.4849951],
          [50.8765268, 35.4849951],
          [50.877943, 35.4846456],
          [50.8914185, 35.4851174],
          [50.8947819, 35.4829115],
          [50.8975983, 35.4815879],
          [50.8998513, 35.4800852],
          [50.903585, 35.4761012],
          [50.909636, 35.473445],
          [50.9121895, 35.4704044],
          [50.9419727, 35.4568772],
          [50.9457493, 35.4557236],
          [50.9469509, 35.4556712],
          [50.9474874, 35.4557062],
          [50.9487534, 35.4553741],
          [50.9492469, 35.4550595],
          [50.949204, 35.4543079],
          [50.9487748, 35.4541506],
          [50.9485388, 35.4537311],
          [50.9494615, 35.4531892],
          [50.9496975, 35.4528396],
          [50.9506202, 35.4526998],
          [50.9528089, 35.4531717],
          [50.9532166, 35.4526998],
          [50.9567571, 35.4516335],
          [50.957551, 35.4507945],
          [50.9581947, 35.449466],
          [50.9592676, 35.4489591],
          [50.9610271, 35.4487319],
          [50.9632587, 35.4468265],
          [50.9635377, 35.4463895],
          [50.9632587, 35.4458301],
          [50.9633875, 35.4448687],
          [50.9650826, 35.4432254],
          [50.9657907, 35.4419843],
          [50.9663701, 35.4405158],
          [50.9670568, 35.4391697],
          [50.9622288, 35.4358829],
          [50.9615421, 35.4170691],
          [50.9647608, 35.395767],
          [50.9642029, 35.395802],
          [50.9655333, 35.3940878],
          [50.9569931, 35.3842917],
          [50.9470367, 35.3817725],
          [50.8790588, 35.282902],
          [50.8735657, 35.2736529],
          [50.8666992, 35.2680469],
          [50.8471298, 35.2265506],
          [50.8433533, 35.2130877],
          [50.9054947, 35.2181365],
          [50.9123611, 35.2178561],
          [50.9858322, 35.2181365],
          [50.9913254, 35.2150512],
          [51.0383606, 35.2111242],
          [51.0713196, 35.2010253],
          [51.0953522, 35.1926087],
          [51.1114883, 35.1912058],
          [51.1351776, 35.1797014],
          [51.1444473, 35.1802627],
          [51.1774063, 35.1684761],
          [51.1870193, 35.1662308],
          [51.220665, 35.1583719],
          [51.2656403, 35.1448978],
          [51.3088989, 35.1263672],
          [51.3617706, 35.1053046],
          [51.3995361, 35.1047428],
          [51.5317154, 35.0774936],
          [51.5852737, 35.0266227],
          [51.6127396, 35.0114397],
          [51.6501617, 35.0086277],
          [51.6745377, 35.0027222],
          [51.6913605, 35.0030034],
          [51.7287827, 34.9934412],
          [51.9636154, 34.8656514],
          [51.9701385, 34.8707218],
          [51.9831848, 34.8960688],
          [52.0013809, 34.9163408],
          [52.0254135, 34.9785333],
          [52.0370865, 35.0176257],
          [52.0401764, 35.0555752],
          [52.0357132, 35.0777746],
          [52.019577, 35.0965971],
          [52.0013809, 35.1145728],
          [51.9756317, 35.1291751],
          [51.9576073, 35.1402655],
          [51.9416428, 35.1613191],
          [51.9165802, 35.1993421],
          [51.8990707, 35.2261299],
          [51.8879128, 35.2379081],
          [51.8784714, 35.2496846],
          [51.8681717, 35.2768762],
          [51.9042206, 35.3176465],
          [51.9406128, 35.3123237],
          [51.9443893, 35.314705],
          [51.9486809, 35.3177865],
          [51.9497108, 35.3212882],
          [51.9534874, 35.3235291],
          [51.9555473, 35.3261902],
          [51.955719, 35.3289912],
          [51.9593239, 35.3310919],
          [51.9601822, 35.3331926],
          [51.9618988, 35.3369736],
          [51.9631004, 35.3391442],
          [51.9634438, 35.3404044],
          [51.9631004, 35.3446752],
          [51.9667912, 35.3491558],
          [51.9673061, 35.351886],
          [51.9717693, 35.3562262],
          [51.9646347, 35.3576262],
          [51.9593668, 35.3584399],
          [51.9243479, 35.3698052],
          [51.9224167, 35.4030432],
          [51.9124603, 35.4139563],
          [51.9265366, 35.4684995],
          [51.9248199, 35.4791242],
          [51.9289398, 35.4877906],
          [51.9218159, 35.5033039],
          [51.9212151, 35.5080552],
          [51.9212151, 35.5100115],
          [51.92379, 35.5459153],
          [51.9296265, 35.5586943],
          [51.9359779, 35.5616967],
          [51.9579506, 35.5781731],
          [51.965847, 35.5784523],
          [51.9692802, 35.5783127],
          [51.9842148, 35.5755203],
          [52.0096207, 35.54815],
          [52.0120239, 35.5397695],
          [52.008934, 35.5246823],
          [52.0048141, 35.5056796],
          [52.0315933, 35.4766079],
          [52.0491028, 35.4595514],
          [52.074852, 35.4503226],
          [52.116394, 35.4550769],
          [52.129097, 35.4617885],
          [52.1476364, 35.4603903],
          [52.2571564, 35.4419318],
          [52.2805023, 35.427106],
          [52.3320007, 35.3770138],
          [52.3666763, 35.3420148],
          [52.3804092, 35.3369736],
          [52.4198914, 35.3646959],
          [52.4913025, 35.3859711],
          [52.5160217, 35.3954872],
          [52.5369644, 35.4015041],
          [52.5616837, 35.4174538],
          [52.6136971, 35.4690587],
          [52.6373863, 35.5079154],
          [52.670002, 35.5373948],
          [52.6979828, 35.5512226],
          [52.7259636, 35.5554124],
          [52.7664757, 35.5470326],
          [52.778492, 35.5414456],
          [52.7824402, 35.5411663],
          [52.8006363, 35.548569],
          [52.876339, 35.6261866],
          [52.8991699, 35.6411152],
          [52.9142761, 35.6492061],
          [52.9310989, 35.655483],
          [52.9747009, 35.6701272],
          [52.9836273, 35.6726374],
          [53.0018234, 35.6830955],
          [53.0128098, 35.7010803],
          [53.0340958, 35.7148799],
          [53.0459404, 35.7185036],
          [53.0505753, 35.7222665],
          [53.0538368, 35.722824],
          [53.0631065, 35.7328575],
          [53.0921173, 35.7481841],
          [53.1113434, 35.7707506],
          [53.1319427, 35.7843988],
          [53.1504822, 35.7896904],
          [53.156662, 35.7980447],
          [53.1552887, 35.808904],
          [53.1401825, 35.8164212],
          [53.1223297, 35.8211538],
          [53.0807877, 35.8180916],
          [53.0430222, 35.821989],
          [53.0207062, 35.8347934],
          [53.0207062, 35.8501003],
          [53.016243, 35.8534396],
          [52.9877472, 35.8353501],
          [52.9698944, 35.8317316],
          [52.9359055, 35.8211538],
          [52.9211426, 35.8200403],
          [52.9012299, 35.8244943],
          [52.8662109, 35.8503786],
          [52.8442383, 35.8581701],
          [52.8377151, 35.8665172],
          [52.8305054, 35.8729161],
          [52.7954865, 35.8909971],
          [52.7045059, 35.9279807],
          [52.6663971, 35.9371544],
          [52.5853729, 35.9252006],
          [52.5743866, 35.9235324],
          [52.540741, 35.9065712],
          [52.5352478, 35.9076835],
          [52.4689865, 35.881818],
          [52.4116516, 35.8629002],
          [52.3398972, 35.832845],
          [52.2952652, 35.8164212],
          [52.2750092, 35.8038922],
          [52.2523499, 35.7810566],
          [52.236557, 35.7696364],
          [52.2228241, 35.7526422],
          [52.2063446, 35.7518063],
          [52.1840286, 35.7362018],
          [52.170639, 35.7278409],
          [52.1685791, 35.7203154],
          [52.1613693, 35.7180855],
          [52.1438599, 35.7136255],
          [52.1321869, 35.7119529],
          [52.1232605, 35.7125104],
          [52.1023178, 35.717528],
          [52.096138, 35.7231027],
          [52.0927048, 35.7270048],
          [52.0903015, 35.7303493],
          [52.0830917, 35.7320215],
          [52.0772552, 35.7362018],
          [52.0721054, 35.7495773],
          [52.065239, 35.7548711],
          [52.0600891, 35.7618362],
          [52.0363998, 35.7740932],
          [52.0151138, 35.7816137],
          [51.9903946, 35.7944246],
          [51.8983841, 35.8990626],
          [51.8692017, 35.9238105],
          [51.8286896, 35.9346526],
          [51.7840576, 35.9660585],
          [51.7191696, 36.03272],
          [51.7071533, 36.0524299],
          [51.6992569, 36.0479886],
          [51.6683578, 36.0585361],
          [51.6549683, 36.0585361],
          [51.5554047, 36.1001573],
          [51.5035629, 36.0607565],
          [51.4915466, 36.0546504],
          [51.4651108, 36.0241127],
          [51.4572144, 36.0227244],
          [51.4438248, 36.0021738],
          [51.4283752, 35.9927299],
          [51.3923264, 35.9310387],
          [51.3772202, 35.9213082],
          [51.3535309, 35.9226984],
          [51.2694168, 35.96717],
          [51.2529373, 35.9702265],
          [51.1544037, 35.997452],
          [51.153717, 35.983007],
          [51.1839294, 35.9218643],
          [51.1276245, 35.8640131],
          [51.1247492, 35.849422],
          [51.1223674, 35.8472479],
          [51.1189342, 35.8470566],
          [51.1080551, 35.8424994],
          [51.1080551, 35.8424472],
        ],
      ],
    },
  },
  Yazd: {
    properties: { value: "Yazd", name: "استان یزد, ایران", id: 7 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [52.8005, 31.493213],
          [52.80161, 31.491543],
          [52.805777, 31.487101],
          [52.81828, 31.479599],
          [52.845779, 31.450429],
          [52.848559, 31.447649],
          [52.864669, 31.437095],
          [52.865782, 31.436824],
          [52.887406, 31.428634],
          [52.888006, 31.428483],
          [52.888559, 31.428212],
          [52.889672, 31.428212],
          [52.904115, 31.423761],
          [52.906895, 31.422651],
          [52.934672, 31.404875],
          [52.950785, 31.38988],
          [52.951618, 31.388489],
          [52.962731, 31.374596],
          [52.965508, 31.369594],
          [52.968008, 31.364322],
          [52.968284, 31.362652],
          [52.968841, 31.361821],
          [52.977731, 31.325431],
          [52.978288, 31.32265],
          [52.978008, 31.311817],
          [52.977731, 31.310707],
          [52.977731, 31.308757],
          [52.977731, 31.308206],
          [52.977451, 31.307656],
          [52.976618, 31.303205],
          [52.974674, 31.295432],
          [52.971618, 31.277089],
          [52.955508, 31.25071],
          [52.945785, 31.240988],
          [52.928838, 31.234597],
          [52.924395, 31.233757],
          [52.918005, 31.232647],
          [52.912449, 31.232096],
          [52.908562, 31.231266],
          [52.907172, 31.231266],
          [52.906339, 31.230977],
          [52.878002, 31.226815],
          [52.875502, 31.226265],
          [52.868836, 31.223764],
          [52.850503, 31.214312],
          [52.850226, 31.213762],
          [52.833556, 31.195698],
          [52.831613, 31.191257],
          [52.831613, 31.186536],
          [52.833556, 31.179043],
          [52.83939, 31.171813],
          [52.855226, 31.16098],
          [52.856893, 31.16043],
          [52.861336, 31.15848],
          [52.876616, 31.154038],
          [52.887726, 31.147097],
          [52.888282, 31.146257],
          [52.889116, 31.145977],
          [52.892725, 31.142926],
          [52.894116, 31.141536],
          [52.894949, 31.140566],
          [52.895225, 31.140426],
          [52.895506, 31.140146],
          [52.899392, 31.136264],
          [52.911895, 31.121262],
          [52.916062, 31.111811],
          [52.927452, 31.084866],
          [52.928838, 31.082925],
          [52.929118, 31.081815],
          [52.933005, 31.076534],
          [52.935228, 31.074034],
          [52.941895, 31.059311],
          [52.948842, 31.035977],
          [52.969398, 31.014593],
          [52.977174, 31.010974],
          [52.981064, 31.009593],
          [52.985508, 31.008203],
          [52.993564, 31.005973],
          [53.019401, 31.001532],
          [53.025234, 31.000016],
          [53.034957, 30.996532],
          [53.052177, 30.98792],
          [53.052457, 30.987361],
          [53.081623, 30.969868],
          [53.095793, 30.962367],
          [53.096626, 30.961537],
          [53.118849, 30.948755],
          [53.123016, 30.946255],
          [53.137182, 30.938754],
          [53.138015, 30.938754],
          [53.142182, 30.937364],
          [53.142739, 30.937085],
          [53.143292, 30.936534],
          [53.145239, 30.935424],
          [53.147462, 30.934584],
          [53.218018, 30.901811],
          [53.221351, 30.900701],
          [53.225521, 30.898751],
          [53.226908, 30.89848],
          [53.237464, 30.894861],
          [53.238854, 30.894861],
          [53.240797, 30.89403],
          [53.241631, 30.89403],
          [53.241911, 30.893751],
          [53.243021, 30.89348],
          [53.24368, 30.892981],
          [53.244131, 30.89264],
          [53.246077, 30.89153],
          [53.247744, 30.89098],
          [53.253021, 30.8882],
          [53.262467, 30.882089],
          [53.263854, 30.880699],
          [53.272467, 30.875699],
          [53.27302, 30.875419],
          [53.273577, 30.875139],
          [53.274687, 30.87458],
          [53.277467, 30.873199],
          [53.279967, 30.872359],
          [53.2858, 30.870139],
          [53.299413, 30.863748],
          [53.363582, 30.833196],
          [53.377472, 30.825416],
          [53.383305, 30.820696],
          [53.388025, 30.817365],
          [53.395529, 30.812085],
          [53.424695, 30.777364],
          [53.432751, 30.766245],
          [53.443308, 30.754025],
          [53.444418, 30.751525],
          [53.448755, 30.747187],
          [53.449418, 30.746524],
          [53.449418, 30.746245],
          [53.458584, 30.737364],
          [53.460531, 30.736804],
          [53.471088, 30.731525],
          [53.490531, 30.726534],
          [53.492197, 30.726254],
          [53.492477, 30.725974],
          [53.496921, 30.725135],
          [53.504977, 30.722635],
          [53.506367, 30.722635],
          [53.529143, 30.717915],
          [53.553313, 30.715415],
          [53.561923, 30.714864],
          [53.563036, 30.714584],
          [53.56498, 30.714584],
          [53.592203, 30.712084],
          [53.597479, 30.712084],
          [53.599426, 30.711805],
          [53.606092, 30.711805],
          [53.608316, 30.711525],
          [53.611649, 30.711525],
          [53.629705, 30.709305],
          [53.633039, 30.709305],
          [53.649982, 30.707364],
          [53.653315, 30.706805],
          [53.657762, 30.705695],
          [53.659152, 30.705695],
          [53.661371, 30.705135],
          [53.663871, 30.704864],
          [53.714431, 30.693466],
          [53.734987, 30.689025],
          [53.73971, 30.687635],
          [53.783876, 30.675136],
          [53.785543, 30.674306],
          [53.834159, 30.657916],
          [53.835546, 30.657637],
          [53.836102, 30.657637],
          [53.838879, 30.656806],
          [53.839436, 30.656247],
          [53.840826, 30.655416],
          [53.854992, 30.647917],
          [53.858602, 30.644578],
          [53.865549, 30.640137],
          [53.866382, 30.639858],
          [53.866939, 30.639027],
          [53.87713, 30.63178],
          [53.879438, 30.630138],
          [53.910828, 30.591801],
          [53.925551, 30.569854],
          [53.929161, 30.557075],
          [53.929161, 30.555965],
          [53.929718, 30.548746],
          [53.929994, 30.546246],
          [53.930274, 30.543747],
          [53.930551, 30.541247],
          [53.930828, 30.540137],
          [53.930274, 30.536798],
          [53.929161, 30.533468],
          [53.928051, 30.525409],
          [53.909995, 30.486804],
          [53.909161, 30.484575],
          [53.905325, 30.472553],
          [53.904995, 30.471518],
          [53.904438, 30.470967],
          [53.901105, 30.464019],
          [53.898881, 30.449301],
          [53.898605, 30.445683],
          [53.905551, 30.413739],
          [53.914161, 30.393743],
          [53.915828, 30.389294],
          [53.921686, 30.366516],
          [53.925828, 30.350404],
          [53.926108, 30.349014],
          [53.925551, 30.334018],
          [53.917218, 30.311794],
          [53.914718, 30.307634],
          [53.910551, 30.298187],
          [53.908605, 30.294578],
          [53.908605, 30.294019],
          [53.907495, 30.292349],
          [53.897772, 30.278743],
          [53.896938, 30.275964],
          [53.895828, 30.273735],
          [53.893328, 30.265958],
          [53.892215, 30.264857],
          [53.892215, 30.264298],
          [53.891938, 30.260409],
          [53.891381, 30.25874],
          [53.891105, 30.255961],
          [53.889995, 30.251792],
          [53.887495, 30.242075],
          [53.886105, 30.238466],
          [53.881105, 30.2304],
          [53.876939, 30.221513],
          [53.864439, 30.202629],
          [53.864159, 30.193742],
          [53.864439, 30.193183],
          [53.867215, 30.187625],
          [53.868049, 30.186795],
          [53.870825, 30.182067],
          [53.873605, 30.176798],
          [53.874158, 30.176239],
          [53.876939, 30.171241],
          [53.878882, 30.165963],
          [53.879715, 30.164853],
          [53.890548, 30.141793],
          [53.890828, 30.140403],
          [53.894715, 30.132067],
          [53.895272, 30.129848],
          [53.900271, 30.119572],
          [53.902215, 30.114014],
          [53.902771, 30.112905],
          [53.904718, 30.108737],
          [53.905551, 30.106788],
          [53.907495, 30.103739],
          [53.908051, 30.103179],
          [53.908328, 30.102629],
          [53.910828, 30.100401],
          [53.911105, 30.09985],
          [53.914995, 30.094013],
          [53.930274, 30.074292],
          [53.939998, 30.061518],
          [53.955274, 30.050124],
          [53.958884, 30.046515],
          [53.97083, 30.038739],
          [53.972497, 30.03762],
          [53.98722, 30.027065],
          [53.988887, 30.025126],
          [53.99076, 30.024055],
          [53.991103, 30.023873],
          [53.991387, 30.023728],
          [53.995165, 30.020466],
          [53.995553, 30.020119],
          [53.99991, 30.017316],
          [54.003053, 30.015681],
          [54.03139, 29.999849],
          [54.03528, 29.99818],
          [54.038613, 29.996791],
          [54.047223, 29.990954],
          [54.098059, 29.938453],
          [54.098615, 29.936234],
          [54.098615, 29.934565],
          [54.098615, 29.933176],
          [54.100282, 29.924012],
          [54.100835, 29.922902],
          [54.103335, 29.912339],
          [54.103615, 29.909011],
          [54.100002, 29.89401],
          [54.100002, 29.893171],
          [54.099921, 29.892927],
          [54.099725, 29.892341],
          [54.092225, 29.874283],
          [54.090279, 29.864009],
          [54.089725, 29.859841],
          [54.089445, 29.856784],
          [54.089169, 29.855954],
          [54.089445, 29.854565],
          [54.089169, 29.853735],
          [54.091669, 29.825674],
          [54.092225, 29.822066],
          [54.103059, 29.792897],
          [54.105282, 29.789559],
          [54.105558, 29.789009],
          [54.106314, 29.788268],
          [54.106668, 29.78762],
          [54.107502, 29.786511],
          [54.120558, 29.772062],
          [54.167228, 29.715394],
          [54.170284, 29.709279],
          [54.194174, 29.662896],
          [54.195007, 29.662057],
          [54.195007, 29.661498],
          [54.19584, 29.660668],
          [54.215007, 29.633171],
          [54.217507, 29.629834],
          [54.219453, 29.626786],
          [54.220287, 29.626227],
          [54.242786, 29.596223],
          [54.24862, 29.596223],
          [54.264176, 29.59984],
          [54.266123, 29.59984],
          [54.267509, 29.60011],
          [54.272789, 29.60011],
          [54.273343, 29.60039],
          [54.276956, 29.60039],
          [54.278623, 29.600669],
          [54.280846, 29.600669],
          [54.285013, 29.601219],
          [54.290012, 29.601219],
          [54.291956, 29.601508],
          [54.295289, 29.601508],
          [54.300289, 29.602058],
          [54.316955, 29.604556],
          [54.319735, 29.604556],
          [54.326125, 29.605395],
          [54.328345, 29.605395],
          [54.329735, 29.605665],
          [54.331402, 29.605665],
          [54.333902, 29.606224],
          [54.335568, 29.606224],
          [54.336682, 29.606504],
          [54.338068, 29.606504],
          [54.339182, 29.606775],
          [54.340568, 29.606774],
          [54.341242, 29.60691],
          [54.343348, 29.607334],
          [54.345292, 29.607334],
          [54.345848, 29.607613],
          [54.347235, 29.607613],
          [54.357515, 29.609002],
          [54.366681, 29.610391],
          [54.367515, 29.610391],
          [54.399461, 29.617335],
          [54.405294, 29.619003],
          [54.44613, 29.632612],
          [54.44835, 29.633721],
          [54.463073, 29.639006],
          [54.47002, 29.640665],
          [54.47141, 29.640665],
          [54.472243, 29.640954],
          [54.47641, 29.641224],
          [54.481686, 29.640967],
          [54.481963, 29.640954],
          [54.485853, 29.640954],
          [54.486963, 29.640665],
          [54.491133, 29.640115],
          [54.493909, 29.640115],
          [54.494743, 29.639835],
          [54.496133, 29.639835],
          [54.542522, 29.628445],
          [54.543912, 29.628445],
          [54.544469, 29.628166],
          [54.556412, 29.627336],
          [54.558635, 29.627336],
          [54.558912, 29.627056],
          [54.559468, 29.627056],
          [54.561412, 29.626497],
          [54.561968, 29.627056],
          [54.562245, 29.629834],
          [54.561968, 29.650667],
          [54.562245, 29.651776],
          [54.562522, 29.654842],
          [54.561968, 29.667892],
          [54.561412, 29.687336],
          [54.561135, 29.692062],
          [54.559468, 29.701505],
          [54.558912, 29.706222],
          [54.558355, 29.70817],
          [54.557245, 29.727335],
          [54.556968, 29.731502],
          [54.553635, 29.748449],
          [54.552802, 29.761779],
          [54.550855, 29.783733],
          [54.551135, 29.784842],
          [54.548912, 29.812893],
          [54.549189, 29.813732],
          [54.548912, 29.817619],
          [54.548912, 29.823175],
          [54.547802, 29.834288],
          [54.545302, 29.844841],
          [54.545022, 29.845391],
          [54.543912, 29.852896],
          [54.542799, 29.859562],
          [54.542522, 29.860121],
          [54.542522, 29.867617],
          [54.541689, 29.875401],
          [54.541132, 29.891782],
          [54.541412, 29.893171],
          [54.541132, 29.896508],
          [54.541412, 29.897338],
          [54.542245, 29.91095],
          [54.541965, 29.929568],
          [54.539745, 29.948457],
          [54.539465, 29.959841],
          [54.540022, 29.966228],
          [54.539746, 29.976512],
          [54.540855, 29.983737],
          [54.546689, 30.000047],
          [54.546412, 30.004846],
          [54.547245, 30.011793],
          [54.547522, 30.015121],
          [54.549745, 30.041788],
          [54.550022, 30.049565],
          [54.550302, 30.071793],
          [54.551969, 30.100401],
          [54.552245, 30.10207],
          [54.552245, 30.115954],
          [54.550855, 30.142902],
          [54.547802, 30.159575],
          [54.548079, 30.160125],
          [54.547245, 30.175679],
          [54.547522, 30.176239],
          [54.547802, 30.178188],
          [54.545022, 30.189294],
          [54.543355, 30.193182],
          [54.540579, 30.198461],
          [54.529189, 30.212346],
          [54.528356, 30.213735],
          [54.521966, 30.219573],
          [54.520299, 30.221242],
          [54.441684, 30.275684],
          [54.44113, 30.275964],
          [54.440297, 30.276794],
          [54.439879, 30.276934],
          [54.433907, 30.282352],
          [54.42835, 30.285961],
          [54.426684, 30.286791],
          [54.403071, 30.307075],
          [54.395571, 30.316242],
          [54.392794, 30.319572],
          [54.357515, 30.357352],
          [54.344182, 30.371518],
          [54.344104, 30.371608],
          [54.340848, 30.375407],
          [54.336682, 30.380966],
          [54.336682, 30.385125],
          [54.337515, 30.386515],
          [54.338068, 30.386795],
          [54.340568, 30.389854],
          [54.342515, 30.391803],
          [54.344184, 30.392773],
          [54.44974, 30.454021],
          [54.45002, 30.4543],
          [54.450574, 30.45458],
          [54.450854, 30.45513],
          [54.476686, 30.475687],
          [54.48002, 30.478466],
          [54.513909, 30.512081],
          [54.530022, 30.531528],
          [54.552522, 30.563744],
          [54.557522, 30.578192],
          [54.558635, 30.580972],
          [54.559469, 30.582632],
          [54.563079, 30.596801],
          [54.565025, 30.60791],
          [54.565302, 30.609859],
          [54.565579, 30.619859],
          [54.563079, 30.649577],
          [54.562522, 30.650967],
          [54.557802, 30.683746],
          [54.557245, 30.685975],
          [54.557245, 30.687635],
          [54.556412, 30.691525],
          [54.555855, 30.703754],
          [54.556135, 30.704305],
          [54.555855, 30.705694],
          [54.556135, 30.707084],
          [54.555855, 30.707635],
          [54.556135, 30.708745],
          [54.555855, 30.713745],
          [54.555022, 30.721525],
          [54.555579, 30.722914],
          [54.555579, 30.723745],
          [54.555579, 30.724304],
          [54.555579, 30.725694],
          [54.556412, 30.729024],
          [54.555302, 30.743754],
          [54.547522, 30.794585],
          [54.546689, 30.801255],
          [54.546689, 30.804305],
          [54.543912, 30.829026],
          [54.543912, 30.831256],
          [54.541412, 30.870139],
          [54.538632, 30.8907],
          [54.530856, 30.918193],
          [54.529746, 30.924033],
          [54.528632, 30.941535],
          [54.526413, 30.971258],
          [54.526689, 30.973199],
          [54.525579, 30.990971],
          [54.525299, 30.991811],
          [54.525856, 30.999872],
          [54.525579, 31.002092],
          [54.531966, 31.012084],
          [54.532246, 31.012924],
          [54.533079, 31.013754],
          [54.533079, 31.014034],
          [54.533356, 31.014314],
          [54.534746, 31.015424],
          [54.535299, 31.015975],
          [54.538079, 31.018204],
          [54.538632, 31.018204],
          [54.543079, 31.020145],
          [54.543636, 31.020705],
          [54.577245, 31.030146],
          [54.580578, 31.031257],
          [54.612805, 31.042919],
          [54.640584, 31.048199],
          [54.641418, 31.048479],
          [54.643084, 31.048479],
          [54.645028, 31.04903],
          [54.653084, 31.05014],
          [54.653918, 31.05042],
          [54.655304, 31.05042],
          [54.666417, 31.05292],
          [54.668361, 31.0532],
          [54.680307, 31.056811],
          [54.681974, 31.057091],
          [54.684197, 31.057921],
          [54.689474, 31.059591],
          [54.696974, 31.062371],
          [54.70892, 31.065702],
          [54.71114, 31.065702],
          [54.711697, 31.065982],
          [54.71503, 31.065982],
          [54.715517, 31.065818],
          [54.715863, 31.065702],
          [54.716353, 31.065702],
          [54.718363, 31.065702],
          [54.719113, 31.065538],
          [54.784479, 31.051259],
          [54.793646, 31.049589],
          [54.793963, 31.049545],
          [54.805589, 31.047929],
          [54.837815, 31.042648],
          [54.837975, 31.042617],
          [54.885594, 31.033477],
          [54.886536, 31.033319],
          [54.915317, 31.028476],
          [54.924764, 31.027366],
          [54.925874, 31.027366],
          [54.926382, 31.027201],
          [54.926707, 31.027095],
          [54.927174, 31.027095],
          [54.93504, 31.027095],
          [54.93865, 31.027366],
          [54.939763, 31.027646],
          [54.943373, 31.027646],
          [54.94643, 31.028196],
          [54.94754, 31.028756],
          [54.948097, 31.029036],
          [54.94893, 31.029307],
          [54.948937, 31.029313],
          [54.949207, 31.029587],
          [54.95143, 31.029586],
          [54.95643, 31.030697],
          [54.965043, 31.034587],
          [54.96532, 31.034867],
          [55.021712, 31.05626],
          [55.038655, 31.059591],
          [55.039769, 31.059591],
          [55.040602, 31.059871],
          [55.042545, 31.059871],
          [55.054492, 31.061811],
          [55.055602, 31.062091],
          [55.057545, 31.062371],
          [55.067825, 31.064032],
          [55.076715, 31.065702],
          [55.097825, 31.068473],
          [55.101991, 31.069312],
          [55.108938, 31.070143],
          [55.133661, 31.072364],
          [55.184773, 31.084315],
          [55.18894, 31.085145],
          [55.21061, 31.091257],
          [55.259499, 31.1082],
          [55.263946, 31.11015],
          [55.264222, 31.11042],
          [55.264779, 31.1107],
          [55.265612, 31.11126],
          [55.266722, 31.11181],
          [55.266999, 31.11209],
          [55.267556, 31.11209],
          [55.267832, 31.11237],
          [55.268946, 31.11237],
          [55.26926, 31.112529],
          [55.269499, 31.11265],
          [55.270889, 31.11265],
          [55.275055, 31.114031],
          [55.275151, 31.114128],
          [55.275332, 31.114311],
          [55.276165, 31.114311],
          [55.276445, 31.114591],
          [55.276999, 31.114871],
          [55.277832, 31.115141],
          [55.287835, 31.117642],
          [55.288112, 31.117922],
          [55.289222, 31.117922],
          [55.312835, 31.126253],
          [55.315058, 31.127093],
          [55.325058, 31.129593],
          [55.326168, 31.129593],
          [55.366171, 31.142096],
          [55.376727, 31.144596],
          [55.399507, 31.151818],
          [55.419507, 31.16015],
          [55.424783, 31.161811],
          [55.44312, 31.168482],
          [55.45951, 31.175423],
          [55.460063, 31.175423],
          [55.46062, 31.175983],
          [55.485066, 31.182925],
          [55.487842, 31.183485],
          [55.488676, 31.184035],
          [55.491732, 31.184866],
          [55.492566, 31.185425],
          [55.494789, 31.185976],
          [55.500066, 31.188486],
          [55.505622, 31.190986],
          [55.580348, 31.230977],
          [55.581181, 31.231536],
          [55.581181, 31.231816],
          [55.600904, 31.243759],
          [55.607014, 31.245709],
          [55.63646, 31.249871],
          [55.64896, 31.25071],
          [55.668683, 31.253761],
          [55.671183, 31.253761],
          [55.67813, 31.254592],
          [55.684796, 31.255982],
          [55.718133, 31.266544],
          [55.719519, 31.267375],
          [55.740076, 31.274597],
          [55.755079, 31.282369],
          [55.755632, 31.282649],
          [55.756465, 31.282929],
          [55.758965, 31.284039],
          [55.759522, 31.284599],
          [55.759799, 31.28487],
          [55.765632, 31.28793],
          [55.765912, 31.288481],
          [55.772855, 31.291261],
          [55.820358, 31.313207],
          [55.823135, 31.314868],
          [55.829805, 31.318759],
          [55.834248, 31.320989],
          [55.843691, 31.3246],
          [55.845638, 31.325151],
          [55.926753, 31.367373],
          [55.934806, 31.373205],
          [55.93842, 31.376816],
          [55.943537, 31.387481],
          [55.945086, 31.39071],
          [55.945086, 31.39182],
          [55.946196, 31.395431],
          [55.955643, 31.416818],
          [55.955643, 31.417658],
          [55.956476, 31.419599],
          [55.959253, 31.424871],
          [55.963976, 31.430712],
          [55.965643, 31.431543],
          [55.967033, 31.432933],
          [55.996476, 31.448488],
          [56.012865, 31.464874],
          [56.017869, 31.475987],
          [56.018145, 31.477377],
          [56.019535, 31.485431],
          [56.019535, 31.486821],
          [56.019812, 31.487651],
          [56.020089, 31.490432],
          [56.020369, 31.492653],
          [56.020645, 31.495434],
          [56.020089, 31.506818],
          [56.019812, 31.508488],
          [56.020089, 31.509039],
          [56.020645, 31.521823],
          [56.020922, 31.525985],
          [56.021202, 31.535717],
          [56.021479, 31.538489],
          [56.021755, 31.54099],
          [56.022035, 31.542931],
          [56.022312, 31.544881],
          [56.023979, 31.554324],
          [56.025922, 31.559317],
          [56.028979, 31.564048],
          [56.029255, 31.564878],
          [56.035089, 31.570431],
          [56.042035, 31.573491],
          [56.048978, 31.575153],
          [56.052592, 31.575992],
          [56.053425, 31.575992],
          [56.053702, 31.576272],
          [56.056758, 31.576272],
          [56.058148, 31.576543],
          [56.059222, 31.576543],
          [56.06281, 31.578688],
          [56.087544, 31.585668],
          [56.093491, 31.587345],
          [56.099566, 31.586764],
          [56.107804, 31.588605],
          [56.111017, 31.590085],
          [56.112387, 31.590716],
          [56.1137, 31.590709],
          [56.115575, 31.590862],
          [56.121195, 31.590671],
          [56.121878, 31.590506],
          [56.125159, 31.589715],
          [56.128949, 31.587826],
          [56.134284, 31.5851],
          [56.139341, 31.580684],
          [56.14341, 31.57195],
          [56.146939, 31.566504],
          [56.151077, 31.560119],
          [56.160502, 31.552608],
          [56.178429, 31.548317],
          [56.178671, 31.548318],
          [56.209342, 31.548474],
          [56.212876, 31.548492],
          [56.215933, 31.548763],
          [56.216766, 31.549043],
          [56.218153, 31.549043],
          [56.218986, 31.549323],
          [56.220653, 31.549323],
          [56.220933, 31.549602],
          [56.223152, 31.549602],
          [56.224823, 31.549873],
          [56.226766, 31.550433],
          [56.229266, 31.550433],
          [56.229542, 31.550713],
          [56.230932, 31.550713],
          [56.231489, 31.550993],
          [56.232043, 31.550993],
          [56.232599, 31.551264],
          [56.237599, 31.550993],
          [56.238989, 31.550993],
          [56.239542, 31.551264],
          [56.241766, 31.550993],
          [56.242042, 31.551264],
          [56.244546, 31.551264],
          [56.245656, 31.551543],
          [56.247046, 31.551543],
          [56.254822, 31.553494],
          [56.255655, 31.553494],
          [56.273435, 31.561267],
          [56.304825, 31.573771],
          [56.305935, 31.574042],
          [56.315381, 31.578493],
          [56.367051, 31.604883],
          [56.400383, 31.627101],
          [56.408164, 31.632374],
          [56.417607, 31.639326],
          [56.495112, 31.685714],
          [56.504555, 31.690436],
          [56.538725, 31.707663],
          [56.557058, 31.710155],
          [56.558448, 31.710434],
          [56.565948, 31.710435],
          [56.566781, 31.710714],
          [56.568725, 31.710714],
          [56.56908, 31.710536],
          [56.569281, 31.710434],
          [56.569907, 31.710435],
          [56.572615, 31.710435],
          [56.572891, 31.710714],
          [56.576504, 31.710714],
          [56.577058, 31.710994],
          [56.578448, 31.710994],
          [56.579004, 31.711274],
          [56.579281, 31.711545],
          [56.579281, 31.714606],
          [56.576781, 31.732654],
          [56.576781, 31.737105],
          [56.576781, 31.737385],
          [56.577058, 31.737656],
          [56.579558, 31.744608],
          [56.579838, 31.745439],
          [56.585395, 31.762656],
          [56.590395, 31.778493],
          [56.590671, 31.778773],
          [56.592061, 31.784885],
          [56.598448, 31.816549],
          [56.599004, 31.82016],
          [56.599004, 31.821271],
          [56.599281, 31.821831],
          [56.599561, 31.823492],
          [56.599838, 31.829054],
          [56.604561, 31.851274],
          [56.604838, 31.852935],
          [56.605118, 31.854606],
          [56.605951, 31.860718],
          [56.605951, 31.863779],
          [56.606504, 31.868212],
          [56.606228, 31.875164],
          [56.604838, 31.894333],
          [56.604284, 31.904328],
          [56.603728, 31.911831],
          [56.604004, 31.912662],
          [56.604284, 31.915163],
          [56.604561, 31.917944],
          [56.604838, 31.922106],
          [56.602895, 31.931279],
          [56.602618, 31.932381],
          [56.601228, 31.937943],
          [56.600114, 31.940715],
          [56.599838, 31.942385],
          [56.599281, 31.943496],
          [56.598171, 31.954051],
          [56.598448, 31.954611],
          [56.598728, 31.956272],
          [56.599004, 31.961554],
          [56.600395, 31.97461],
          [56.600671, 31.975441],
          [56.600948, 31.976271],
          [56.601505, 31.981002],
          [56.602061, 31.983494],
          [56.602338, 31.985725],
          [56.602618, 31.987946],
          [56.602618, 31.988776],
          [56.602895, 31.989327],
          [56.603171, 31.990447],
          [56.596781, 31.999611],
          [56.616228, 32.032938],
          [56.630951, 32.051836],
          [56.631227, 32.052387],
          [56.635951, 32.057669],
          [56.652897, 32.075998],
          [56.654564, 32.08128],
          [56.653451, 32.087384],
          [56.637341, 32.107673],
          [56.633451, 32.112946],
          [56.591228, 32.174056],
          [56.584005, 32.183501],
          [56.575672, 32.191555],
          [56.561502, 32.209333],
          [56.544559, 32.230173],
          [56.543726, 32.231564],
          [56.543169, 32.231835],
          [56.542612, 32.232674],
          [56.542059, 32.232945],
          [56.527612, 32.246282],
          [56.527336, 32.246841],
          [56.526223, 32.247392],
          [56.498723, 32.275726],
          [56.494556, 32.280178],
          [56.49428, 32.281],
          [56.493538, 32.281746],
          [56.493446, 32.281839],
          [56.48928, 32.284891],
          [56.481223, 32.289893],
          [56.480943, 32.290453],
          [56.48011, 32.290733],
          [56.479557, 32.291284],
          [56.416498, 32.354896],
          [56.409831, 32.36295],
          [56.400664, 32.369343],
          [56.382051, 32.388784],
          [56.375661, 32.394066],
          [56.374828, 32.394897],
          [56.374271, 32.395176],
          [56.373995, 32.395447],
          [56.362328, 32.405732],
          [56.320939, 32.455177],
          [56.311492, 32.466843],
          [56.305382, 32.477119],
          [56.304826, 32.478229],
          [56.299546, 32.483782],
          [56.27788, 32.512406],
          [56.273436, 32.51907],
          [56.262047, 32.537679],
          [56.261213, 32.53851],
          [56.248713, 32.563233],
          [56.240377, 32.576569],
          [56.221487, 32.593788],
          [56.200931, 32.608235],
          [56.199821, 32.609346],
          [56.184264, 32.618791],
          [56.181488, 32.620181],
          [56.180374, 32.621012],
          [56.168431, 32.628236],
          [56.135095, 32.646845],
          [56.113152, 32.660732],
          [56.111762, 32.661292],
          [56.085649, 32.681843],
          [56.082178, 32.683565],
          [56.053146, 32.697961],
          [56.051203, 32.698511],
          [56.038426, 32.704624],
          [56.037593, 32.705184],
          [56.030647, 32.708796],
          [56.002867, 32.722132],
          [55.99259, 32.727685],
          [55.9862, 32.731848],
          [55.972034, 32.739631],
          [55.965644, 32.741573],
          [55.963421, 32.742403],
          [55.937308, 32.748236],
          [55.908141, 32.750178],
          [55.907031, 32.750458],
          [55.898418, 32.750458],
          [55.885085, 32.751577],
          [55.881472, 32.752128],
          [55.879805, 32.752688],
          [55.876472, 32.753239],
          [55.860082, 32.759351],
          [55.857582, 32.760742],
          [55.855915, 32.761293],
          [55.855082, 32.761573],
          [55.854249, 32.761853],
          [55.852862, 32.762123],
          [55.852582, 32.762403],
          [55.851749, 32.762403],
          [55.851196, 32.762683],
          [55.850639, 32.762683],
          [55.850362, 32.762963],
          [55.849249, 32.762963],
          [55.835916, 32.767126],
          [55.831473, 32.769907],
          [55.829806, 32.770467],
          [55.827859, 32.771848],
          [55.822859, 32.774069],
          [55.80758, 32.787406],
          [55.806746, 32.788516],
          [55.782303, 32.815189],
          [55.724244, 32.886574],
          [55.721744, 32.889906],
          [55.719801, 32.891856],
          [55.717301, 32.895188],
          [55.700631, 32.911016],
          [55.699798, 32.911576],
          [55.698688, 32.913247],
          [55.697578, 32.914908],
          [55.691188, 32.924352],
          [55.686188, 32.931025],
          [55.682021, 32.937689],
          [55.674241, 32.955747],
          [55.669241, 32.98408],
          [55.668965, 32.986852],
          [55.667852, 32.993245],
          [55.667018, 32.996577],
          [55.667298, 32.998798],
          [55.661185, 33.0038],
          [55.654518, 33.010472],
          [55.640629, 33.031582],
          [55.640075, 33.032413],
          [55.636739, 33.036295],
          [55.602296, 33.092691],
          [55.601739, 33.094632],
          [55.574236, 33.147415],
          [55.573126, 33.149076],
          [55.533123, 33.218255],
          [55.53229, 33.220196],
          [55.511734, 33.251579],
          [55.500067, 33.262974],
          [55.481177, 33.282421],
          [55.478401, 33.285193],
          [55.471454, 33.291865],
          [55.428952, 33.32825],
          [55.409785, 33.347426],
          [55.408675, 33.348808],
          [55.388119, 33.364364],
          [55.386452, 33.364924],
          [55.385895, 33.364364],
          [55.383672, 33.363813],
          [55.381729, 33.363813],
          [55.380339, 33.363533],
          [55.378396, 33.363533],
          [55.377286, 33.363253],
          [55.375896, 33.363253],
          [55.367006, 33.360473],
          [55.364229, 33.360193],
          [55.362282, 33.359642],
          [55.324783, 33.35464],
          [55.323393, 33.35409],
          [55.26978, 33.343535],
          [55.268667, 33.342976],
          [55.260057, 33.340475],
          [55.259224, 33.339924],
          [55.254224, 33.338254],
          [55.232834, 33.329361],
          [55.228111, 33.32686],
          [55.224777, 33.325199],
          [55.222554, 33.324088],
          [55.183385, 33.298528],
          [55.176718, 33.292416],
          [55.175885, 33.292145],
          [55.175608, 33.291865],
          [55.154495, 33.275749],
          [55.137272, 33.261033],
          [55.109772, 33.240754],
          [55.107826, 33.238804],
          [55.104492, 33.236312],
          [55.077269, 33.211862],
          [55.03338, 33.167134],
          [55.027267, 33.15964],
          [55.01949, 33.150476],
          [54.99171, 33.125746],
          [54.990601, 33.126026],
          [54.961711, 33.143524],
          [54.954764, 33.148255],
          [54.912818, 33.175188],
          [54.901152, 33.179639],
          [54.899762, 33.179639],
          [54.899485, 33.179919],
          [54.898095, 33.179919],
          [54.897262, 33.180199],
          [54.888372, 33.176587],
          [54.886152, 33.175188],
          [54.883929, 33.174357],
          [54.878649, 33.171856],
          [54.877815, 33.171585],
          [54.800036, 33.125195],
          [54.79948, 33.124364],
          [54.790313, 33.117972],
          [54.787813, 33.115751],
          [54.7867, 33.115191],
          [54.77559, 33.104636],
          [54.771029, 33.096776],
          [54.770985, 33.096699],
          [54.770033, 33.095192],
          [54.75309, 33.06936],
          [54.752533, 33.06936],
          [54.751977, 33.0688],
          [54.744754, 33.065748],
          [54.740587, 33.064918],
          [54.73531, 33.064918],
          [54.733367, 33.065189],
          [54.731977, 33.064918],
          [54.725864, 33.064918],
          [54.724197, 33.065189],
          [54.721421, 33.065189],
          [54.704198, 33.06713],
          [54.698088, 33.068249],
          [54.688364, 33.069911],
          [54.670308, 33.072412],
          [54.668085, 33.072412],
          [54.667251, 33.072692],
          [54.632805, 33.078804],
          [54.631415, 33.079355],
          [54.628915, 33.079635],
          [54.627529, 33.080195],
          [54.622805, 33.081305],
          [54.609749, 33.084917],
          [54.564193, 33.09047],
          [54.558913, 33.09047],
          [54.557246, 33.09075],
          [54.550023, 33.09075],
          [54.548356, 33.09047],
          [54.54558, 33.09047],
          [54.543636, 33.09019],
          [54.535856, 33.089639],
          [54.52669, 33.088799],
          [54.50919, 33.085748],
          [54.507523, 33.085748],
          [54.479744, 33.080475],
          [54.478077, 33.080475],
          [54.477244, 33.080195],
          [54.475577, 33.080195],
          [54.473077, 33.079635],
          [54.471411, 33.079635],
          [54.455854, 33.076583],
          [54.453907, 33.076583],
          [54.389738, 33.065189],
          [54.381128, 33.062688],
          [54.375848, 33.061297],
          [54.375015, 33.060746],
          [54.354533, 33.054769],
          [54.348349, 33.052963],
          [54.307513, 33.030192],
          [54.305569, 33.027691],
          [54.304736, 33.026301],
          [54.304736, 33.02547],
          [54.302513, 33.017136],
          [54.301956, 33.014355],
          [54.301679, 33.012685],
          [54.301403, 33.010744],
          [54.301123, 33.010473],
          [54.301403, 33.006301],
          [54.301403, 32.999358],
          [54.299736, 32.995746],
          [54.299456, 32.994076],
          [54.299179, 32.993245],
          [54.2914, 32.972686],
          [54.290289, 32.971025],
          [54.283623, 32.96019],
          [54.272233, 32.942971],
          [54.261957, 32.930466],
          [54.24751, 32.916859],
          [54.226397, 32.900742],
          [54.22501, 32.899631],
          [54.224177, 32.8988],
          [54.206674, 32.887406],
          [54.170841, 32.868796],
          [54.168894, 32.868245],
          [54.163618, 32.865744],
          [54.162785, 32.865744],
          [54.161671, 32.865184],
          [54.160838, 32.865184],
          [54.152228, 32.862412],
          [54.150561, 32.862412],
          [54.150005, 32.862133],
          [54.149171, 32.862133],
          [54.148618, 32.861853],
          [54.146395, 32.861853],
          [54.145838, 32.861582],
          [54.141671, 32.861582],
          [54.140281, 32.861022],
          [54.058613, 32.83658],
          [54.056946, 32.83574],
          [54.02139, 32.826295],
          [54.02, 32.825745],
          [54.0175, 32.824905],
          [54.01639, 32.824354],
          [54.005277, 32.821293],
          [54.001127, 32.823499],
          [53.99972, 32.823523],
          [53.9975, 32.822693],
          [53.989443, 32.819912],
          [53.959441, 32.808246],
          [53.957774, 32.807135],
          [53.941384, 32.798801],
          [53.921941, 32.7763],
          [53.910551, 32.760743],
          [53.880825, 32.722133],
          [53.872772, 32.712409],
          [53.862061, 32.700922],
          [53.853602, 32.691849],
          [53.842492, 32.686016],
          [53.836936, 32.684345],
          [53.821656, 32.681573],
          [53.819436, 32.681573],
          [53.818879, 32.681293],
          [53.816656, 32.681293],
          [53.816102, 32.681013],
          [53.813323, 32.681013],
          [53.812489, 32.680733],
          [53.804156, 32.679903],
          [53.800546, 32.679903],
          [53.800266, 32.679623],
          [53.797213, 32.679623],
          [53.794713, 32.679623],
          [53.793599, 32.679352],
          [53.791933, 32.679352],
          [53.790546, 32.679623],
          [53.784156, 32.679623],
          [53.783043, 32.679903],
          [53.776376, 32.679903],
          [53.775823, 32.679623],
          [53.770543, 32.679072],
          [53.767766, 32.679072],
          [53.766653, 32.678792],
          [53.764433, 32.678792],
          [53.76332, 32.678512],
          [53.747487, 32.678512],
          [53.746097, 32.678792],
          [53.74221, 32.678792],
          [53.741377, 32.679072],
          [53.737763, 32.679072],
          [53.736377, 32.679352],
          [53.73471, 32.679352],
          [53.72693, 32.680462],
          [53.724707, 32.680462],
          [53.72443, 32.680733],
          [53.722487, 32.680733],
          [53.72193, 32.681013],
          [53.719987, 32.681013],
          [53.71943, 32.681293],
          [53.718597, 32.681293],
          [53.717764, 32.681573],
          [53.716654, 32.681573],
          [53.71554, 32.681844],
          [53.713597, 32.681844],
          [53.713317, 32.682124],
          [53.712484, 32.682124],
          [53.711931, 32.681844],
          [53.711097, 32.681844],
          [53.709431, 32.682404],
          [53.708317, 32.682124],
          [53.707207, 32.681937],
          [53.706651, 32.681844],
          [53.704698, 32.681688],
          [53.703874, 32.681573],
          [53.701374, 32.681293],
          [53.697764, 32.681293],
          [53.694707, 32.681013],
          [53.690541, 32.681013],
          [53.688041, 32.681293],
          [53.669984, 32.681293],
          [53.668038, 32.681573],
          [53.664984, 32.681573],
          [53.631372, 32.685185],
          [53.549423, 32.698512],
          [53.546923, 32.698512],
          [53.545813, 32.698792],
          [53.543313, 32.698792],
          [53.541089, 32.699072],
          [53.539699, 32.698792],
          [53.537476, 32.699072],
          [53.52859, 32.699072],
          [53.527476, 32.699352],
          [53.522476, 32.699352],
          [53.521086, 32.699072],
          [53.509977, 32.699072],
          [53.508033, 32.698792],
          [53.502477, 32.698792],
          [53.499143, 32.698512],
          [53.495253, 32.698241],
          [53.491643, 32.698241],
          [53.49081, 32.697961],
          [53.48414, 32.697961],
          [53.483587, 32.698241],
          [53.482754, 32.697961],
          [53.47914, 32.697961],
          [53.477197, 32.697961],
          [53.47664, 32.697682],
          [53.462474, 32.69518],
          [53.461084, 32.694629],
          [53.446084, 32.690738],
          [53.443584, 32.690467],
          [53.376638, 32.676291],
          [53.288299, 32.649347],
          [53.284409, 32.648236],
          [53.220517, 32.629907],
          [53.186627, 32.617681],
          [53.184127, 32.61629],
          [53.160515, 32.605464],
          [53.124125, 32.579071],
          [53.117182, 32.572118],
          [53.114402, 32.569346],
          [53.095792, 32.55601],
          [53.0319, 32.526294],
          [53.027453, 32.522682],
          [53.02551, 32.521291],
          [53.023286, 32.519901],
          [53.022176, 32.51879],
          [53.016066, 32.515459],
          [53.015233, 32.515459],
          [53.015233, 32.515179],
          [53.014677, 32.514619],
          [53.014677, 32.514348],
          [53.013843, 32.512678],
          [53.010787, 32.509066],
          [53.011063, 32.508795],
          [53.01301, 32.504623],
          [53.035233, 32.464623],
          [53.038079, 32.4521],
          [53.04079, 32.440171],
          [53.042456, 32.435178],
          [53.044123, 32.431286],
          [53.050233, 32.417119],
          [53.050509, 32.415458],
          [53.051066, 32.414618],
          [53.052456, 32.404342],
          [53.054956, 32.384893],
          [53.055233, 32.377399],
          [53.055513, 32.372396],
          [53.055789, 32.368784],
          [53.058566, 32.337118],
          [53.058846, 32.336008],
          [53.058566, 32.332956],
          [53.058846, 32.332116],
          [53.058566, 32.313786],
          [53.058289, 32.310175],
          [53.058013, 32.305732],
          [53.058289, 32.304621],
          [53.056066, 32.296007],
          [53.052733, 32.288784],
          [53.053013, 32.288224],
          [53.052733, 32.287113],
          [53.052456, 32.286283],
          [53.052176, 32.286012],
          [53.051623, 32.28045],
          [53.051899, 32.279619],
          [53.051066, 32.270174],
          [53.052733, 32.257118],
          [53.051899, 32.242671],
          [53.052176, 32.224621],
          [53.05579, 32.20184],
          [53.056066, 32.20017],
          [53.054123, 32.175727],
          [53.053846, 32.16212],
          [53.053846, 32.16156],
          [53.053566, 32.16128],
          [53.052953, 32.159853],
          [53.052733, 32.159339],
          [53.052456, 32.158779],
          [53.050509, 32.154888],
          [53.050233, 32.153506],
          [53.0494, 32.146843],
          [53.049123, 32.142951],
          [53.048843, 32.130446],
          [53.049123, 32.128504],
          [53.0444, 32.086834],
          [53.044123, 32.082952],
          [53.04301, 32.062392],
          [53.042733, 32.059891],
          [53.041623, 32.049056],
          [53.0419, 32.047106],
          [53.042176, 32.046835],
          [53.041623, 32.039052],
          [53.0419, 32.038781],
          [53.0419, 32.038501],
          [53.041623, 32.03795],
          [53.04079, 32.034339],
          [53.039123, 32.018781],
          [53.0394, 32.01517],
          [53.039676, 32.013779],
          [53.039956, 32.011278],
          [53.040233, 32.009608],
          [53.041066, 32.007107],
          [53.039957, 32.000046],
          [53.042456, 31.989888],
          [53.038566, 31.983496],
          [53.037457, 31.982665],
          [53.037457, 31.982664],
          [53.037457, 31.982394],
          [53.037457, 31.982114],
          [53.032177, 31.967388],
          [53.031067, 31.961835],
          [53.018843, 31.920446],
          [53.01801, 31.911552],
          [53.0169, 31.90683],
          [53.01662, 31.90627],
          [53.009397, 31.879888],
          [53.00912, 31.86183],
          [53.009397, 31.857658],
          [53.009677, 31.854607],
          [53.019953, 31.822942],
          [53.0219, 31.817661],
          [53.027453, 31.792109],
          [53.0294, 31.784886],
          [53.04079, 31.763497],
          [53.053013, 31.74599],
          [53.05468, 31.744329],
          [53.062456, 31.734325],
          [53.075789, 31.72099],
          [53.079403, 31.717938],
          [53.081069, 31.717108],
          [53.081903, 31.716268],
          [53.102459, 31.704603],
          [53.140239, 31.680434],
          [53.177185, 31.641268],
          [53.180238, 31.637657],
          [53.186908, 31.627933],
          [53.198018, 31.613488],
          [53.203851, 31.596821],
          [53.203574, 31.59321],
          [53.203419, 31.591828],
          [53.203294, 31.590718],
          [53.203574, 31.590438],
          [53.203574, 31.590158],
          [53.202908, 31.588926],
          [53.194408, 31.573212],
          [53.186075, 31.567931],
          [53.184961, 31.56766],
          [53.183295, 31.56682],
          [53.135515, 31.542381],
          [53.133572, 31.541821],
          [53.132739, 31.54127],
          [53.130792, 31.54071],
          [53.129125, 31.53988],
          [53.091346, 31.523494],
          [53.083289, 31.520153],
          [53.05968, 31.51154],
          [53.057456, 31.511269],
          [53.052457, 31.509879],
          [53.011344, 31.500427],
          [53.009397, 31.499596],
          [53.007177, 31.499325],
          [53.006344, 31.498766],
          [52.997731, 31.496265],
          [52.996621, 31.496265],
          [52.993841, 31.495434],
          [52.987174, 31.494324],
          [52.985231, 31.494324],
          [52.984397, 31.494044],
          [52.982454, 31.494044],
          [52.979397, 31.493493],
          [52.977451, 31.493493],
          [52.946061, 31.490153],
          [52.941061, 31.490153],
          [52.938562, 31.489873],
          [52.936061, 31.490153],
          [52.922728, 31.490153],
          [52.921338, 31.489873],
          [52.901615, 31.489042],
          [52.892725, 31.489322],
          [52.888282, 31.489322],
          [52.886892, 31.489602],
          [52.883282, 31.489602],
          [52.880782, 31.489873],
          [52.872726, 31.489873],
          [52.871616, 31.490153],
          [52.858559, 31.490153],
          [52.857726, 31.489873],
          [52.853559, 31.490433],
          [52.849946, 31.490433],
          [52.849113, 31.490712],
          [52.841889, 31.491263],
          [52.836889, 31.491263],
          [52.836056, 31.491543],
          [52.833003, 31.491543],
          [52.83217, 31.491823],
          [52.83078, 31.491823],
          [52.829113, 31.492103],
          [52.81328, 31.493213],
          [52.807723, 31.494324],
          [52.806057, 31.494324],
          [52.805777, 31.494603],
          [52.801057, 31.494874],
          [52.800777, 31.494324],
          [52.8005, 31.493213],
        ],
      ],
    },
  },
  Zanjan: {
    properties: { value: "Zanjan", name: "استان زنجان, ایران", id: 21 },
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [47.174115, 36.791667],
          [47.174396, 36.791388],
          [47.174396, 36.791109],
          [47.175229, 36.790271],
          [47.175229, 36.789443],
          [47.175505, 36.788606],
          [47.180229, 36.77917],
          [47.191062, 36.770553],
          [47.196339, 36.764439],
          [47.201342, 36.747224],
          [47.209952, 36.736662],
          [47.213009, 36.733609],
          [47.251622, 36.708325],
          [47.270231, 36.701112],
          [47.271345, 36.700833],
          [47.277178, 36.698608],
          [47.277735, 36.698329],
          [47.278012, 36.69805],
          [47.291345, 36.68638],
          [47.307178, 36.641939],
          [47.308845, 36.639715],
          [47.309124, 36.638877],
          [47.329401, 36.610825],
          [47.334405, 36.590273],
          [47.334681, 36.588049],
          [47.334958, 36.587211],
          [47.334958, 36.581105],
          [47.335238, 36.577764],
          [47.335238, 36.57499],
          [47.333848, 36.554437],
          [47.349681, 36.518321],
          [47.353295, 36.512214],
          [47.354128, 36.509989],
          [47.354681, 36.508323],
          [47.354961, 36.507485],
          [47.355238, 36.506098],
          [47.356071, 36.502216],
          [47.356628, 36.50055],
          [47.356071, 36.496659],
          [47.354961, 36.494155],
          [47.354961, 36.493876],
          [47.354128, 36.49193],
          [47.353848, 36.490543],
          [47.353848, 36.480545],
          [47.354128, 36.479716],
          [47.354128, 36.47832],
          [47.355238, 36.475267],
          [47.371071, 36.439714],
          [47.371351, 36.438876],
          [47.371351, 36.438048],
          [47.371904, 36.437768],
          [47.381628, 36.432211],
          [47.403294, 36.413042],
          [47.412184, 36.409988],
          [47.413298, 36.409988],
          [47.414408, 36.409709],
          [47.415798, 36.409709],
          [47.416631, 36.40943],
          [47.425518, 36.40943],
          [47.426074, 36.409709],
          [47.426631, 36.409709],
          [47.434407, 36.411655],
          [47.434964, 36.412213],
          [47.437187, 36.413042],
          [47.439687, 36.414708],
          [47.460244, 36.427491],
          [47.461354, 36.427491],
          [47.46191, 36.42777],
          [47.467467, 36.428599],
          [47.47441, 36.428599],
          [47.47552, 36.428319],
          [47.47802, 36.428319],
          [47.478577, 36.42804],
          [47.490524, 36.426374],
          [47.492467, 36.426374],
          [47.493023, 36.426104],
          [47.496076, 36.426103],
          [47.49691, 36.425824],
          [47.507747, 36.425824],
          [47.508023, 36.426104],
          [47.509967, 36.426104],
          [47.510523, 36.426104],
          [47.516913, 36.426104],
          [47.517746, 36.426374],
          [47.5183, 36.426104],
          [47.521633, 36.426104],
          [47.521913, 36.425824],
          [47.523856, 36.425824],
          [47.524413, 36.425545],
          [47.525246, 36.425545],
          [47.52719, 36.424987],
          [47.528303, 36.424987],
          [47.546913, 36.419717],
          [47.548859, 36.419717],
          [47.549413, 36.419438],
          [47.551913, 36.419438],
          [47.552469, 36.419159],
          [47.553579, 36.419159],
          [47.555526, 36.4186],
          [47.556912, 36.4186],
          [47.557469, 36.418321],
          [47.559136, 36.418321],
          [47.559416, 36.418042],
          [47.561083, 36.418042],
          [47.561636, 36.417762],
          [47.563026, 36.417762],
          [47.563583, 36.417483],
          [47.565249, 36.417213],
          [47.568583, 36.416654],
          [47.606082, 36.39499],
          [47.606916, 36.39499],
          [47.607195, 36.394711],
          [47.608029, 36.394431],
          [47.608582, 36.394431],
          [47.608862, 36.394152],
          [47.637752, 36.38554],
          [47.638862, 36.38554],
          [47.639695, 36.385261],
          [47.640809, 36.385261],
          [47.641362, 36.384982],
          [47.659699, 36.380541],
          [47.665531, 36.379712],
          [47.669975, 36.378883],
          [47.671085, 36.378883],
          [47.674141, 36.378325],
          [47.675808, 36.378325],
          [47.676921, 36.378036],
          [47.693588, 36.377487],
          [47.694421, 36.377766],
          [47.699145, 36.378325],
          [47.700254, 36.378883],
          [47.706921, 36.379991],
          [47.709421, 36.379991],
          [47.709978, 36.380271],
          [47.711088, 36.380271],
          [47.711368, 36.380271],
          [47.714144, 36.380271],
          [47.714701, 36.380271],
          [47.715254, 36.379991],
          [47.724144, 36.379991],
          [47.724701, 36.379712],
          [47.725534, 36.379712],
          [47.726644, 36.379433],
          [47.728311, 36.379433],
          [47.746367, 36.3761],
          [47.748314, 36.3761],
          [47.748867, 36.37582],
          [47.750534, 36.37582],
          [47.751367, 36.375541],
          [47.752481, 36.375541],
          [47.753314, 36.375262],
          [47.75998, 36.374712],
          [47.764424, 36.374712],
          [47.765257, 36.374433],
          [47.766647, 36.374433],
          [47.767481, 36.374154],
          [47.76859, 36.374154],
          [47.77109, 36.373595],
          [47.782203, 36.371938],
          [47.78776, 36.369434],
          [47.791927, 36.367488],
          [47.79637, 36.365542],
          [47.80026, 36.362767],
          [47.815537, 36.347209],
          [47.81665, 36.34666],
          [47.826926, 36.342209],
          [47.82776, 36.342209],
          [47.828317, 36.34193],
          [47.829706, 36.34166],
          [47.831926, 36.34166],
          [47.832207, 36.341381],
          [47.833316, 36.341381],
          [47.833873, 36.341101],
          [47.83526, 36.341101],
          [47.83554, 36.340822],
          [47.838317, 36.340822],
          [47.838593, 36.340543],
          [47.839983, 36.340543],
          [47.84026, 36.340264],
          [47.841093, 36.340264],
          [47.842206, 36.339984],
          [47.843596, 36.339984],
          [47.848316, 36.338876],
          [47.866096, 36.329705],
          [47.866929, 36.329705],
          [47.867486, 36.329426],
          [47.87082, 36.328876],
          [47.874153, 36.328876],
          [47.874706, 36.329156],
          [47.875819, 36.329156],
          [47.883596, 36.331102],
          [47.884986, 36.331102],
          [47.884986, 36.331381],
          [47.886376, 36.331381],
          [47.887486, 36.331381],
          [47.888596, 36.331651],
          [47.890819, 36.331651],
          [47.891652, 36.331651],
          [47.892762, 36.331381],
          [47.893319, 36.331381],
          [47.898042, 36.331381],
          [47.898596, 36.331102],
          [47.899432, 36.331102],
          [47.899709, 36.330813],
          [47.918599, 36.320543],
          [47.919432, 36.320543],
          [47.919709, 36.320264],
          [47.920266, 36.320264],
          [47.934155, 36.315264],
          [47.934708, 36.314705],
          [47.938599, 36.31248],
          [47.941098, 36.310543],
          [47.941379, 36.310264],
          [47.956102, 36.28165],
          [47.956379, 36.281371],
          [47.956102, 36.275542],
          [47.954432, 36.273596],
          [47.945822, 36.268595],
          [47.943322, 36.266649],
          [47.943046, 36.2661],
          [47.942212, 36.264983],
          [47.941932, 36.263595],
          [47.941655, 36.261099],
          [47.941655, 36.256928],
          [47.945546, 36.248594],
          [47.953879, 36.242206],
          [47.954712, 36.24026],
          [47.954155, 36.236097],
          [47.952765, 36.234151],
          [47.947212, 36.225538],
          [47.946932, 36.223042],
          [47.946932, 36.222213],
          [47.946932, 36.221933],
          [47.949989, 36.216374],
          [47.964712, 36.206932],
          [47.970822, 36.198038],
          [47.973322, 36.188596],
          [47.973602, 36.185541],
          [47.973879, 36.185262],
          [47.973879, 36.181649],
          [47.974435, 36.179153],
          [47.974435, 36.176098],
          [47.974435, 36.173872],
          [47.974712, 36.171097],
          [47.974989, 36.169421],
          [47.975269, 36.168313],
          [47.975826, 36.166646],
          [47.976102, 36.166376],
          [47.976102, 36.165817],
          [47.976935, 36.163871],
          [47.990602, 36.153262],
          [47.990825, 36.15304],
          [47.993882, 36.151373],
          [47.995825, 36.149147],
          [47.995269, 36.14693],
          [47.995269, 36.145263],
          [47.995269, 36.144705],
          [47.994992, 36.143596],
          [47.994715, 36.140821],
          [47.994992, 36.139704],
          [47.994715, 36.138875],
          [47.993882, 36.13637],
          [47.991935, 36.133594],
          [47.975269, 36.106921],
          [47.974436, 36.104425],
          [47.972769, 36.096369],
          [47.971936, 36.094143],
          [47.971936, 36.093314],
          [47.969712, 36.084149],
          [47.969156, 36.08332],
          [47.967769, 36.07776],
          [47.967769, 36.077201],
          [47.966656, 36.074426],
          [47.962489, 36.063035],
          [47.959156, 36.057763],
          [47.95499, 36.049977],
          [47.954156, 36.049418],
          [47.950266, 36.044696],
          [47.925266, 36.021085],
          [47.9236, 36.019976],
          [47.903044, 36.002204],
          [47.9036, 35.998311],
          [47.90402, 35.997655],
          [47.908043, 35.994147],
          [47.90832, 35.993868],
          [47.908997, 35.993658],
          [47.90971, 35.993309],
          [47.931376, 35.98609],
          [47.932767, 35.98609],
          [47.9336, 35.98581],
          [47.935543, 35.98581],
          [47.9361, 35.985531],
          [47.93888, 35.985531],
          [47.939157, 35.985252],
          [47.942766, 35.984423],
          [47.944156, 35.984422],
          [47.9461, 35.983864],
          [47.948323, 35.983593],
          [47.951933, 35.983035],
          [47.952766, 35.983035],
          [47.953323, 35.982755],
          [47.954713, 35.982755],
          [47.955266, 35.982476],
          [47.959989, 35.981926],
          [47.96277, 35.981926],
          [47.963046, 35.981647],
          [47.965823, 35.981647],
          [47.966379, 35.981367],
          [47.968323, 35.981367],
          [47.968603, 35.981088],
          [47.969436, 35.980809],
          [47.977769, 35.978312],
          [47.982213, 35.97359],
          [47.982493, 35.97331],
          [47.982213, 35.971084],
          [47.980269, 35.969426],
          [47.978603, 35.968588],
          [47.969989, 35.966091],
          [47.96888, 35.966091],
          [47.968323, 35.965812],
          [47.967489, 35.965812],
          [47.967213, 35.965532],
          [47.95749, 35.963306],
          [47.939157, 35.957475],
          [47.938046, 35.957475],
          [47.936933, 35.956925],
          [47.935823, 35.956925],
          [47.935266, 35.956646],
          [47.934433, 35.956646],
          [47.934157, 35.956366],
          [47.931657, 35.956366],
          [47.9311, 35.956087],
          [47.92749, 35.956087],
          [47.926657, 35.955807],
          [47.925823, 35.956087],
          [47.92499, 35.955807],
          [47.921377, 35.955537],
          [47.91999, 35.955537],
          [47.917767, 35.954978],
          [47.9161, 35.954978],
          [47.91582, 35.954699],
          [47.914433, 35.954699],
          [47.914153, 35.954419],
          [47.899434, 35.949976],
          [47.895264, 35.948029],
          [47.88582, 35.944974],
          [47.884711, 35.944974],
          [47.884431, 35.944694],
          [47.871931, 35.944145],
          [47.869154, 35.943586],
          [47.868321, 35.943586],
          [47.862207, 35.94136],
          [47.856374, 35.93414],
          [47.856098, 35.931923],
          [47.859984, 35.918034],
          [47.859984, 35.917475],
          [47.860818, 35.915808],
          [47.863874, 35.910805],
          [47.864708, 35.910256],
          [47.867764, 35.906921],
          [47.868321, 35.906921],
          [47.870541, 35.906082],
          [47.870821, 35.905812],
          [47.8719, 35.904725],
          [47.872207, 35.904424],
          [47.889154, 35.894978],
          [47.889988, 35.894978],
          [47.907211, 35.889417],
          [47.9086, 35.889417],
          [47.909711, 35.889147],
          [47.928324, 35.894419],
          [47.933043, 35.897475],
          [47.943047, 35.905812],
          [47.944157, 35.906641],
          [47.973323, 35.928868],
          [47.977769, 35.930256],
          [47.979713, 35.931085],
          [47.980546, 35.931085],
          [47.980546, 35.931365],
          [47.98166, 35.931365],
          [47.982213, 35.931644],
          [47.985546, 35.931644],
          [47.986103, 35.931365],
          [47.989712, 35.931365],
          [47.993602, 35.929977],
          [47.994963, 35.930157],
          [47.996175, 35.930935],
          [47.999992, 35.933032],
          [48.007493, 35.921649],
          [48.008602, 35.920531],
          [48.016659, 35.906083],
          [48.016939, 35.905533],
          [48.018049, 35.898593],
          [48.021659, 35.888867],
          [48.022492, 35.888029],
          [48.023049, 35.885812],
          [48.023606, 35.884974],
          [48.023883, 35.883586],
          [48.024439, 35.882756],
          [48.025826, 35.878313],
          [48.026659, 35.872481],
          [48.026939, 35.869137],
          [48.028606, 35.861088],
          [48.028882, 35.859141],
          [48.036939, 35.841915],
          [48.036939, 35.841366],
          [48.037216, 35.841086],
          [48.039996, 35.828584],
          [48.044996, 35.814702],
          [48.046663, 35.812196],
          [48.051106, 35.807473],
          [48.051662, 35.807473],
          [48.053053, 35.806635],
          [48.061386, 35.803858],
          [48.084442, 35.798306],
          [48.085829, 35.798305],
          [48.087495, 35.797756],
          [48.088609, 35.797756],
          [48.090552, 35.797197],
          [48.091665, 35.797197],
          [48.095275, 35.796367],
          [48.097218, 35.796367],
          [48.100832, 35.795809],
          [48.103332, 35.795809],
          [48.108889, 35.79497],
          [48.120275, 35.791635],
          [48.124442, 35.791635],
          [48.124722, 35.791923],
          [48.129998, 35.792473],
          [48.130832, 35.792753],
          [48.132221, 35.792753],
          [48.133331, 35.793032],
          [48.135555, 35.793303],
          [48.138888, 35.793303],
          [48.140278, 35.793591],
          [48.141388, 35.793303],
          [48.142778, 35.793303],
          [48.143888, 35.793591],
          [48.151388, 35.793591],
          [48.152501, 35.793303],
          [48.171668, 35.789138],
          [48.173334, 35.788309],
          [48.184448, 35.775256],
          [48.184724, 35.773859],
          [48.185001, 35.773309],
          [48.185001, 35.771362],
          [48.184724, 35.767476],
          [48.183891, 35.761085],
          [48.183615, 35.744976],
          [48.184168, 35.742199],
          [48.190558, 35.731084],
          [48.192501, 35.729146],
          [48.193058, 35.729146],
          [48.193614, 35.728587],
          [48.194448, 35.728587],
          [48.195001, 35.728308],
          [48.197781, 35.728308],
          [48.198891, 35.728587],
          [48.210557, 35.730255],
          [48.213614, 35.730525],
          [48.220004, 35.730525],
          [48.226394, 35.730525],
          [48.227504, 35.730255],
          [48.229171, 35.730255],
          [48.235004, 35.728308],
          [48.236114, 35.728308],
          [48.23667, 35.728028],
          [48.237781, 35.728028],
          [48.238337, 35.727749],
          [48.24917, 35.723584],
          [48.25167, 35.720248],
          [48.25195, 35.719689],
          [48.252227, 35.719419],
          [48.268893, 35.708033],
          [48.27056, 35.707195],
          [48.286116, 35.690246],
          [48.288063, 35.688028],
          [48.29612, 35.680528],
          [48.311953, 35.672747],
          [48.312506, 35.672188],
          [48.32501, 35.667753],
          [48.326396, 35.667473],
          [48.328063, 35.666635],
          [48.333063, 35.665526],
          [48.339733, 35.663029],
          [48.340286, 35.663029],
          [48.340566, 35.662749],
          [48.341399, 35.662749],
          [48.342233, 35.662199],
          [48.343343, 35.662199],
          [48.347509, 35.662199],
          [48.353066, 35.659693],
          [48.357509, 35.657475],
          [48.391679, 35.643023],
          [48.394736, 35.642753],
          [48.395289, 35.642473],
          [48.401679, 35.642473],
          [48.414179, 35.640806],
          [48.421678, 35.644691],
          [48.425845, 35.647468],
          [48.435015, 35.65414],
          [48.438068, 35.654969],
          [48.439182, 35.654969],
          [48.441402, 35.655799],
          [48.442235, 35.655799],
          [48.442515, 35.656087],
          [48.444735, 35.656087],
          [48.445015, 35.655799],
          [48.448905, 35.655799],
          [48.450848, 35.655528],
          [48.451405, 35.655249],
          [48.452792, 35.655249],
          [48.455015, 35.65469],
          [48.455848, 35.65469],
          [48.456958, 35.65414],
          [48.458071, 35.65414],
          [48.461125, 35.652751],
          [48.461681, 35.652751],
          [48.462238, 35.652192],
          [48.468071, 35.650525],
          [48.471961, 35.649975],
          [48.481128, 35.645809],
          [48.481681, 35.645809],
          [48.483628, 35.64525],
          [48.483904, 35.644971],
          [48.484181, 35.644691],
          [48.490571, 35.63747],
          [48.491961, 35.635523],
          [48.492518, 35.635252],
          [48.495295, 35.629689],
          [48.495851, 35.627751],
          [48.497795, 35.621918],
          [48.498071, 35.621359],
          [48.498351, 35.61997],
          [48.495018, 35.599973],
          [48.496128, 35.598864],
          [48.521128, 35.590254],
          [48.525574, 35.588306],
          [48.536407, 35.585529],
          [48.538074, 35.58497],
          [48.542797, 35.58497],
          [48.543074, 35.58525],
          [48.545021, 35.58525],
          [48.548631, 35.5858],
          [48.548907, 35.586079],
          [48.549741, 35.586079],
          [48.550297, 35.586359],
          [48.551407, 35.586359],
          [48.579187, 35.595249],
          [48.58002, 35.595249],
          [48.584467, 35.597746],
          [48.58502, 35.598305],
          [48.585585, 35.598585],
          [48.588633, 35.599694],
          [48.590577, 35.599694],
          [48.5953, 35.601362],
          [48.6078, 35.603021],
          [48.610023, 35.60275],
          [48.610856, 35.602471],
          [48.612523, 35.602471],
          [48.618633, 35.601632],
          [48.62308, 35.601362],
          [48.623913, 35.601082],
          [48.62558, 35.601082],
          [48.626133, 35.600803],
          [48.633356, 35.600803],
          [48.634466, 35.601082],
          [48.637246, 35.601082],
          [48.638356, 35.601362],
          [48.643356, 35.601362],
          [48.64419, 35.601082],
          [48.647246, 35.601082],
          [48.64808, 35.600803],
          [48.65058, 35.600803],
          [48.663913, 35.598864],
          [48.669469, 35.597187],
          [48.674749, 35.59441],
          [48.685302, 35.581085],
          [48.685582, 35.580805],
          [48.687802, 35.57719],
          [48.695306, 35.5583],
          [48.695582, 35.55803],
          [48.695859, 35.557191],
          [48.696139, 35.555803],
          [48.696692, 35.555523],
          [48.697249, 35.554414],
          [48.698639, 35.553026],
          [48.703916, 35.551637],
          [48.705025, 35.551637],
          [48.705582, 35.551358],
          [48.707249, 35.551358],
          [48.707805, 35.551078],
          [48.710306, 35.551078],
          [48.716416, 35.552187],
          [48.723362, 35.554414],
          [48.723915, 35.554694],
          [48.725028, 35.554875],
          [48.725582, 35.554964],
          [48.753085, 35.570247],
          [48.768365, 35.591354],
          [48.768918, 35.592193],
          [48.772251, 35.596917],
          [48.775031, 35.601362],
          [48.776698, 35.614138],
          [48.774198, 35.618862],
          [48.768918, 35.634414],
          [48.768918, 35.644972],
          [48.769198, 35.64581],
          [48.769751, 35.649975],
          [48.770308, 35.661912],
          [48.770031, 35.662471],
          [48.769751, 35.666365],
          [48.769198, 35.669142],
          [48.770031, 35.678581],
          [48.766694, 35.682196],
          [48.763084, 35.683026],
          [48.761975, 35.683585],
          [48.753085, 35.684135],
          [48.746695, 35.684694],
          [48.745861, 35.684973],
          [48.743638, 35.685253],
          [48.739195, 35.686641],
          [48.732805, 35.691644],
          [48.727805, 35.701083],
          [48.727528, 35.701913],
          [48.728915, 35.711919],
          [48.735028, 35.722476],
          [48.735028, 35.722746],
          [48.736324, 35.723958],
          [48.732805, 35.729976],
          [48.730861, 35.733582],
          [48.724748, 35.749421],
          [48.724471, 35.751359],
          [48.724748, 35.756912],
          [48.725581, 35.760248],
          [48.736138, 35.778304],
          [48.736138, 35.778863],
          [48.738361, 35.782198],
          [48.748641, 35.799695],
          [48.752808, 35.806086],
          [48.756417, 35.823303],
          [48.756141, 35.823592],
          [48.756379, 35.824546],
          [48.756417, 35.8247],
          [48.756694, 35.824971],
          [48.757807, 35.826088],
          [48.758361, 35.826368],
          [48.758361, 35.826647],
          [48.759474, 35.827756],
          [48.771417, 35.838861],
          [48.771974, 35.839141],
          [48.774751, 35.844973],
          [48.780307, 35.876638],
          [48.781697, 35.879423],
          [48.784474, 35.884146],
          [48.787253, 35.887201],
          [48.827533, 35.914422],
          [48.8442, 35.933313],
          [48.847256, 35.936927],
          [48.890869, 35.97443],
          [48.902812, 35.982207],
          [48.903369, 35.982478],
          [48.912259, 35.967481],
          [48.912535, 35.966652],
          [48.914479, 35.96165],
          [48.914759, 35.961091],
          [48.921982, 35.945535],
          [48.934202, 35.930537],
          [48.952538, 35.918316],
          [48.952815, 35.918036],
          [48.953372, 35.917757],
          [48.967261, 35.913593],
          [48.971428, 35.912755],
          [48.973371, 35.912755],
          [48.977538, 35.911925],
          [48.979485, 35.911925],
          [48.980595, 35.911646],
          [48.982538, 35.911646],
          [48.983928, 35.911367],
          [48.986704, 35.911367],
          [48.991984, 35.910258],
          [49.007817, 35.904426],
          [49.021431, 35.894422],
          [49.026431, 35.887761],
          [49.026431, 35.887472],
          [49.026984, 35.887202],
          [49.032264, 35.882758],
          [49.044764, 35.878315],
          [49.047821, 35.878315],
          [49.048654, 35.878035],
          [49.052264, 35.878035],
          [49.053097, 35.878315],
          [49.055321, 35.878315],
          [49.05671, 35.878585],
          [49.057264, 35.878873],
          [49.058097, 35.878874],
          [49.07032, 35.884705],
          [49.071434, 35.885814],
          [49.0731, 35.886643],
          [49.074669, 35.888528],
          [49.075877, 35.889978],
          [49.076153, 35.890807],
          [49.077005, 35.901594],
          [49.076154, 35.903866],
          [49.076153, 35.903868],
          [49.071434, 35.90887],
          [49.0706, 35.909149],
          [49.066987, 35.91609],
          [49.06671, 35.918036],
          [49.07171, 35.923868],
          [49.08671, 35.92915],
          [49.087543, 35.92942],
          [49.095043, 35.930538],
          [49.097267, 35.931087],
          [49.098656, 35.931087],
          [49.09921, 35.931367],
          [49.101433, 35.931367],
          [49.10199, 35.931646],
          [49.10421, 35.931646],
          [49.105323, 35.931926],
          [49.111156, 35.931926],
          [49.118656, 35.932476],
          [49.12088, 35.932205],
          [49.121713, 35.931926],
          [49.1231, 35.931926],
          [49.12338, 35.931646],
          [49.124489, 35.931646],
          [49.124766, 35.931367],
          [49.131436, 35.931367],
          [49.135323, 35.930817],
          [49.135879, 35.930538],
          [49.137546, 35.930538],
          [49.137822, 35.930258],
          [49.140603, 35.930258],
          [49.141156, 35.929979],
          [49.146436, 35.929979],
          [49.147546, 35.9297],
          [49.150603, 35.9297],
          [49.151713, 35.929979],
          [49.151713, 35.930538],
          [49.152546, 35.932476],
          [49.163659, 35.939145],
          [49.163659, 35.939424],
          [49.173936, 35.951646],
          [49.180882, 35.955539],
          [49.181716, 35.95554],
          [49.186159, 35.958036],
          [49.20813, 35.98135],
          [49.208938, 35.982208],
          [49.218938, 35.985534],
          [49.222552, 35.985534],
          [49.222828, 35.985254],
          [49.223662, 35.985254],
          [49.245885, 35.97498],
          [49.258385, 35.972204],
          [49.259218, 35.971925],
          [49.262551, 35.971925],
          [49.263108, 35.971645],
          [49.266165, 35.971645],
          [49.267274, 35.971925],
          [49.271441, 35.971925],
          [49.272274, 35.972204],
          [49.273941, 35.972204],
          [49.274498, 35.972204],
          [49.281998, 35.972475],
          [49.282831, 35.972763],
          [49.286721, 35.972763],
          [49.289497, 35.973033],
          [49.290608, 35.973313],
          [49.291998, 35.973313],
          [49.292831, 35.973592],
          [49.294221, 35.973592],
          [49.301997, 35.975818],
          [49.336167, 35.996646],
          [49.34311, 35.998872],
          [49.353947, 36.004983],
          [49.362, 36.010814],
          [49.362833, 36.012201],
          [49.362833, 36.012481],
          [49.362833, 36.013319],
          [49.393113, 36.040544],
          [49.397836, 36.046366],
          [49.403393, 36.05582],
          [49.412836, 36.07387],
          [49.416169, 36.095543],
          [49.415893, 36.096092],
          [49.415059, 36.101932],
          [49.413116, 36.116656],
          [49.413116, 36.122766],
          [49.412283, 36.125262],
          [49.412282, 36.125541],
          [49.411726, 36.128037],
          [49.411726, 36.13248],
          [49.411449, 36.133318],
          [49.411169, 36.134985],
          [49.410892, 36.136652],
          [49.413392, 36.146654],
          [49.421169, 36.15971],
          [49.428949, 36.173317],
          [49.433672, 36.193041],
          [49.433392, 36.194149],
          [49.433115, 36.194437],
          [49.432839, 36.195545],
          [49.431725, 36.196933],
          [49.431725, 36.197212],
          [49.431725, 36.197492],
          [49.426449, 36.203871],
          [49.423115, 36.207763],
          [49.416169, 36.216936],
          [49.415336, 36.217765],
          [49.412002, 36.22554],
          [49.411449, 36.228324],
          [49.412002, 36.238037],
          [49.412282, 36.238605],
          [49.411726, 36.240542],
          [49.408392, 36.244714],
          [49.403669, 36.248047],
          [49.398112, 36.253327],
          [49.397279, 36.253876],
          [49.393112, 36.259985],
          [49.391726, 36.266103],
          [49.391725, 36.266652],
          [49.391169, 36.26804],
          [49.391445, 36.271653],
          [49.407279, 36.2936],
          [49.416725, 36.307762],
          [49.417558, 36.3086],
          [49.423392, 36.321375],
          [49.424782, 36.326384],
          [49.424502, 36.326934],
          [49.424502, 36.327213],
          [49.421169, 36.327771],
          [49.415059, 36.327771],
          [49.414502, 36.328051],
          [49.410892, 36.328051],
          [49.410615, 36.327771],
          [49.409782, 36.328051],
          [49.405612, 36.328051],
          [49.399502, 36.329159],
          [49.398112, 36.329159],
          [49.395892, 36.329708],
          [49.394502, 36.329708],
          [49.370889, 36.3386],
          [49.351166, 36.354987],
          [49.349776, 36.355825],
          [49.348666, 36.357212],
          [49.348109, 36.35805],
          [49.347832, 36.359158],
          [49.347276, 36.360825],
          [49.347833, 36.364158],
          [49.348109, 36.368878],
          [49.348109, 36.369437],
          [49.347832, 36.369986],
          [49.347556, 36.371662],
          [49.346999, 36.374436],
          [49.346723, 36.376932],
          [49.346166, 36.379715],
          [49.344499, 36.385264],
          [49.340889, 36.388048],
          [49.336999, 36.389435],
          [49.335889, 36.389435],
          [49.335609, 36.389715],
          [49.329219, 36.389715],
          [49.320333, 36.388886],
          [49.314496, 36.388048],
          [49.313386, 36.388048],
          [49.308386, 36.386661],
          [49.306719, 36.386381],
          [49.30172, 36.385544],
          [49.298943, 36.385544],
          [49.29811, 36.385264],
          [49.296996, 36.385544],
          [49.293386, 36.385544],
          [49.290886, 36.386102],
          [49.289496, 36.386102],
          [49.28783, 36.386661],
          [49.282273, 36.387489],
          [49.277553, 36.388327],
          [49.273383, 36.389994],
          [49.272962, 36.390203],
          [49.270883, 36.391102],
          [49.27033, 36.391381],
          [49.266997, 36.392768],
          [49.26644, 36.393048],
          [49.235604, 36.407767],
          [49.201865, 36.423129],
          [49.201437, 36.423324],
          [49.201157, 36.423603],
          [49.200881, 36.423882],
          [49.196157, 36.425269],
          [49.195047, 36.426936],
          [49.194771, 36.427494],
          [49.191158, 36.428602],
          [49.188657, 36.428602],
          [49.187824, 36.428881],
          [49.186157, 36.428882],
          [49.185048, 36.428602],
          [49.178381, 36.428602],
          [49.177548, 36.428881],
          [49.176158, 36.428881],
          [49.175048, 36.429161],
          [49.173381, 36.429161],
          [49.172548, 36.42944],
          [49.169491, 36.42944],
          [49.168934, 36.42971],
          [49.151435, 36.42944],
          [49.150324, 36.429161],
          [49.148378, 36.429161],
          [49.146991, 36.428881],
          [49.144768, 36.428881],
          [49.143101, 36.428602],
          [49.142545, 36.428881],
          [49.141155, 36.428602],
          [49.137821, 36.428602],
          [49.136435, 36.428881],
          [49.134211, 36.428881],
          [49.131988, 36.428323],
          [49.120598, 36.42944],
          [49.120045, 36.42944],
          [49.119212, 36.42971],
          [49.109765, 36.433601],
          [49.105875, 36.436655],
          [49.105322, 36.436934],
          [49.105041, 36.437493],
          [49.104208, 36.437772],
          [49.095598, 36.446383],
          [49.095042, 36.447491],
          [49.092542, 36.451103],
          [49.081432, 36.471659],
          [49.060595, 36.495825],
          [49.031706, 36.512776],
          [49.029482, 36.514163],
          [49.028096, 36.514442],
          [49.027816, 36.514722],
          [49.027262, 36.514722],
          [49.026706, 36.514992],
          [49.023372, 36.517766],
          [49.007003, 36.527478],
          [48.975593, 36.546101],
          [48.972905, 36.55197],
          [48.972536, 36.552775],
          [48.972259, 36.553045],
          [48.971979, 36.553324],
          [48.971979, 36.555828],
          [48.972259, 36.557215],
          [48.972536, 36.557773],
          [48.972259, 36.558053],
          [48.972813, 36.56333],
          [48.977259, 36.573607],
          [49.008372, 36.623328],
          [49.013929, 36.630272],
          [49.015039, 36.631938],
          [49.015596, 36.632775],
          [49.018372, 36.648058],
          [49.018649, 36.651111],
          [49.033928, 36.667221],
          [49.034651, 36.66768],
          [49.037301, 36.669334],
          [49.042581, 36.669939],
          [49.045416, 36.670511],
          [49.053366, 36.674107],
          [49.061816, 36.680173],
          [49.064678, 36.687214],
          [49.064996, 36.693742],
          [49.06517, 36.697299],
          [49.064114, 36.70315],
          [49.063215, 36.708135],
          [49.064163, 36.712333],
          [49.065328, 36.716421],
          [49.066486, 36.719186],
          [49.069842, 36.724495],
          [49.072225, 36.727034],
          [49.075775, 36.7303],
          [49.077891, 36.733388],
          [49.084739, 36.745537],
          [49.088166, 36.751324],
          [49.089763, 36.754932],
          [49.09186, 36.757561],
          [49.097789, 36.768528],
          [49.099411, 36.769992],
          [49.108366, 36.778069],
          [49.116301, 36.780173],
          [49.125357, 36.780524],
          [49.132809, 36.779525],
          [49.138316, 36.779175],
          [49.148246, 36.77891],
          [49.15383, 36.778333],
          [49.157837, 36.778009],
          [49.166473, 36.775486],
          [49.171271, 36.772374],
          [49.174947, 36.770071],
          [49.177299, 36.768597],
          [49.180062, 36.766586],
          [49.189149, 36.760754],
          [49.196257, 36.765288],
          [49.21237, 36.775563],
          [49.225804, 36.782973],
          [49.240108, 36.789995],
          [49.248889, 36.795368],
          [49.253253, 36.79774],
          [49.261565, 36.802015],
          [49.268297, 36.80666],
          [49.27031, 36.808873],
          [49.280605, 36.815558],
          [49.280329, 36.815837],
          [49.280052, 36.816116],
          [49.237549, 36.850004],
          [49.231436, 36.857503],
          [49.228382, 36.861672],
          [49.216993, 36.896674],
          [49.214493, 36.900833],
          [49.192269, 36.914453],
          [49.177546, 36.916389],
          [49.176713, 36.916668],
          [49.175599, 36.916668],
          [49.175046, 36.916947],
          [49.173933, 36.916947],
          [49.170323, 36.9189],
          [49.170046, 36.919449],
          [49.163933, 36.925841],
          [49.152267, 36.937228],
          [49.120597, 36.978897],
          [49.12032, 36.979446],
          [49.120044, 36.979725],
          [49.115597, 36.985009],
          [49.11532, 36.985288],
          [49.103374, 36.995558],
          [49.095874, 36.999735],
          [49.09532, 37.001391],
          [49.09504, 37.00167],
          [49.094764, 37.002133],
          [49.09254, 37.00473],
          [49.091707, 37.005837],
          [49.07754, 37.021949],
          [49.07615, 37.026116],
          [49.07365, 37.033614],
          [49.066427, 37.042228],
          [49.056707, 37.05445],
          [49.054484, 37.055845],
          [49.049204, 37.059175],
          [49.025038, 37.0739],
          [49.014761, 37.084457],
          [49.013928, 37.085843],
          [49.013371, 37.086122],
          [49.012538, 37.087508],
          [48.992258, 37.105292],
          [48.991424, 37.10612],
          [48.985591, 37.109728],
          [48.967535, 37.123903],
          [48.940312, 37.142783],
          [48.931978, 37.150289],
          [48.931422, 37.151396],
          [48.930865, 37.151675],
          [48.926145, 37.160008],
          [48.924755, 37.16223],
          [48.922812, 37.167234],
          [48.923365, 37.172228],
          [48.923088, 37.172516],
          [48.922255, 37.176125],
          [48.922255, 37.176674],
          [48.921422, 37.176404],
          [48.913922, 37.176674],
          [48.912808, 37.176404],
          [48.911975, 37.176674],
          [48.908365, 37.176404],
          [48.907532, 37.176125],
          [48.906422, 37.176125],
          [48.905589, 37.175846],
          [48.904475, 37.175846],
          [48.893918, 37.168062],
          [48.878642, 37.157236],
          [48.878086, 37.156957],
          [48.871696, 37.151396],
          [48.869752, 37.146401],
          [48.863919, 37.147508],
          [48.862253, 37.147508],
          [48.861419, 37.147508],
          [48.860862, 37.147508],
          [48.860862, 37.147787],
          [48.858919, 37.147787],
          [48.858639, 37.148066],
          [48.858086, 37.147787],
          [48.856419, 37.147787],
          [48.856139, 37.148066],
          [48.855029, 37.148066],
          [48.843639, 37.152232],
          [48.843083, 37.152232],
          [48.842806, 37.152511],
          [48.841972, 37.152511],
          [48.841416, 37.15279],
          [48.839473, 37.15279],
          [48.838639, 37.153069],
          [48.832806, 37.153069],
          [48.831973, 37.15279],
          [48.826696, 37.15279],
          [48.822249, 37.153618],
          [48.821693, 37.153897],
          [48.81975, 37.153897],
          [48.819193, 37.154176],
          [48.816416, 37.154176],
          [48.815306, 37.154176],
          [48.812806, 37.153348],
          [48.80197, 37.141955],
          [48.794193, 37.139453],
          [48.793916, 37.139174],
          [48.790026, 37.139174],
          [48.788636, 37.139732],
          [48.78836, 37.140011],
          [48.787527, 37.140011],
          [48.78725, 37.14029],
          [48.786693, 37.14029],
          [48.78336, 37.14362],
          [48.77697, 37.14668],
          [48.776413, 37.14695],
          [48.76808, 37.150288],
          [48.764747, 37.156678],
          [48.758913, 37.164732],
          [48.757247, 37.165839],
          [48.755304, 37.166397],
          [48.755304, 37.166676],
          [48.753913, 37.166676],
          [48.752247, 37.169456],
          [48.75197, 37.179454],
          [48.75197, 37.180291],
          [48.75197, 37.18084],
          [48.75169, 37.181119],
          [48.751137, 37.182514],
          [48.751137, 37.182793],
          [48.746133, 37.185843],
          [48.745024, 37.185843],
          [48.744467, 37.186122],
          [48.743633, 37.186122],
          [48.74308, 37.185843],
          [48.739467, 37.185843],
          [48.73919, 37.185564],
          [48.737524, 37.185564],
          [48.733634, 37.184178],
          [48.7278, 37.180291],
          [48.726967, 37.180012],
          [48.726226, 37.17964],
          [48.695854, 37.160007],
          [48.694744, 37.159449],
          [48.683631, 37.152232],
          [48.665298, 37.135844],
          [48.655855, 37.129455],
          [48.655855, 37.129176],
          [48.654741, 37.129176],
          [48.648075, 37.127232],
          [48.647241, 37.127232],
          [48.646685, 37.126953],
          [48.643908, 37.126953],
          [48.643075, 37.126674],
          [48.640298, 37.126674],
          [48.639741, 37.126395],
          [48.638352, 37.126395],
          [48.637241, 37.126116],
          [48.636408, 37.126116],
          [48.633908, 37.125846],
          [48.627795, 37.12473],
          [48.626128, 37.12473],
          [48.620018, 37.123623],
          [48.609738, 37.120005],
          [48.588629, 37.110286],
          [48.587515, 37.109728],
          [48.587238, 37.109452],
          [48.578072, 37.103059],
          [48.577516, 37.103059],
          [48.576126, 37.105012],
          [48.575016, 37.106119],
          [48.574459, 37.106398],
          [48.573349, 37.106398],
          [48.573349, 37.106677],
          [48.572792, 37.106677],
          [48.572516, 37.106956],
          [48.572239, 37.107235],
          [48.571126, 37.109728],
          [48.570849, 37.111393],
          [48.570572, 37.112229],
          [48.570292, 37.113615],
          [48.570572, 37.117512],
          [48.569459, 37.118898],
          [48.568716, 37.119243],
          [48.566682, 37.120005],
          [48.566682, 37.120284],
          [48.565292, 37.120284],
          [48.563626, 37.119177],
          [48.561682, 37.11834],
          [48.559739, 37.11834],
          [48.556683, 37.119456],
          [48.556126, 37.119456],
          [48.555849, 37.119726],
          [48.549736, 37.119456],
          [48.548902, 37.119726],
          [48.547516, 37.121121],
          [48.547236, 37.121958],
          [48.547236, 37.122507],
          [48.546959, 37.123065],
          [48.547793, 37.12806],
          [48.548349, 37.129176],
          [48.548902, 37.132505],
          [48.546403, 37.135286],
          [48.541403, 37.135286],
          [48.540849, 37.135007],
          [48.536679, 37.135007],
          [48.534179, 37.136123],
          [48.533346, 37.136123],
          [48.533069, 37.136402],
          [48.531679, 37.136402],
          [48.529459, 37.135286],
          [48.528626, 37.135286],
          [48.52446, 37.13723],
          [48.522793, 37.137788],
          [48.521679, 37.137788],
          [48.520846, 37.138067],
          [48.517789, 37.138067],
          [48.51529, 37.136123],
          [48.513623, 37.136123],
          [48.50918, 37.138346],
          [48.507513, 37.141118],
          [48.507513, 37.141676],
          [48.4964, 37.157514],
          [48.496123, 37.157784],
          [48.495846, 37.1589],
          [48.495566, 37.159449],
          [48.49529, 37.160286],
          [48.495013, 37.162788],
          [48.495846, 37.167233],
          [48.493899, 37.169456],
          [48.493899, 37.169735],
          [48.49279, 37.169735],
          [48.4914, 37.170284],
          [48.491123, 37.170563],
          [48.490566, 37.170563],
          [48.489456, 37.172227],
          [48.49418, 37.175845],
          [48.494456, 37.176403],
          [48.491956, 37.178068],
          [48.488067, 37.178068],
          [48.487233, 37.178347],
          [48.48529, 37.178347],
          [48.485013, 37.178626],
          [48.484456, 37.178626],
          [48.483623, 37.178896],
          [48.48029, 37.180569],
          [48.479733, 37.18029],
          [48.478623, 37.180569],
          [48.478343, 37.180839],
          [48.487513, 37.194454],
          [48.487789, 37.19557],
          [48.487513, 37.19584],
          [48.4839, 37.199457],
          [48.483623, 37.200006],
          [48.479733, 37.201401],
          [48.479176, 37.20168],
          [48.477233, 37.203624],
          [48.47751, 37.205567],
          [48.478066, 37.206404],
          [48.478066, 37.206953],
          [48.474176, 37.210292],
          [48.468066, 37.211677],
          [48.466957, 37.211677],
          [48.465843, 37.212514],
          [48.459733, 37.208348],
          [48.458067, 37.209176],
          [48.456677, 37.209455],
          [48.455843, 37.209734],
          [48.455287, 37.209734],
          [48.45501, 37.210013],
          [48.454177, 37.210013],
          [48.451953, 37.208348],
          [48.449733, 37.208618],
          [48.449734, 37.208897],
          [48.449177, 37.208897],
          [48.4489, 37.209176],
          [48.446677, 37.209176],
          [48.440843, 37.211677],
          [48.432787, 37.218066],
          [48.429453, 37.220289],
          [48.429453, 37.220568],
          [48.42862, 37.220847],
          [48.426397, 37.221395],
          [48.425563, 37.221674],
          [48.421673, 37.221674],
          [48.42084, 37.221953],
          [48.418897, 37.224176],
          [48.423064, 37.23779],
          [48.42362, 37.240849],
          [48.41834, 37.241119],
          [48.417787, 37.241398],
          [48.417787, 37.241677],
          [48.41723, 37.241677],
          [48.416397, 37.243351],
          [48.416954, 37.245843],
          [48.415287, 37.24668],
          [48.41334, 37.24668],
          [48.413246, 37.24668],
          [48.412782, 37.24668],
          [48.406674, 37.244179],
          [48.406117, 37.243621],
          [48.404731, 37.243063],
          [48.403897, 37.243063],
          [48.403064, 37.243621],
          [48.402507, 37.244736],
          [48.402507, 37.24668],
          [48.40195, 37.247238],
          [48.401117, 37.248623],
          [48.400007, 37.250846],
          [48.39945, 37.251404],
          [48.39945, 37.251683],
          [48.395007, 37.251683],
          [48.39445, 37.251683],
          [48.392231, 37.251683],
          [48.389174, 37.250009],
          [48.388341, 37.250009],
          [48.385561, 37.248902],
          [48.385561, 37.248623],
          [48.383894, 37.247508],
          [48.379174, 37.243063],
          [48.372507, 37.248066],
          [48.372508, 37.248344],
          [48.371674, 37.248344],
          [48.371394, 37.248623],
          [48.369171, 37.248623],
          [48.368894, 37.248344],
          [48.368061, 37.248344],
          [48.365284, 37.246959],
          [48.364171, 37.246959],
          [48.363617, 37.24668],
          [48.358618, 37.247508],
          [48.357784, 37.248065],
          [48.356951, 37.248065],
          [48.356671, 37.248344],
          [48.355838, 37.248066],
          [48.353338, 37.248065],
          [48.351671, 37.247238],
          [48.348614, 37.242235],
          [48.347781, 37.241956],
          [48.346948, 37.241956],
          [48.346671, 37.242235],
          [48.346114, 37.242235],
          [48.345838, 37.242514],
          [48.345005, 37.242514],
          [48.344728, 37.242235],
          [48.343338, 37.242235],
          [48.338895, 37.239454],
          [48.335004, 37.239454],
          [48.333058, 37.241398],
          [48.332505, 37.242514],
          [48.331391, 37.242514],
          [48.331391, 37.242793],
          [48.320558, 37.245006],
          [48.318058, 37.245294],
          [48.317225, 37.245564],
          [48.308335, 37.245294],
          [48.294725, 37.245294],
          [48.275835, 37.237511],
          [48.273335, 37.237511],
          [48.273055, 37.237232],
          [48.270835, 37.237232],
          [48.270002, 37.237511],
          [48.268055, 37.237511],
          [48.267222, 37.23779],
          [48.265002, 37.23779],
          [48.264445, 37.238069],
          [48.261669, 37.238069],
          [48.260835, 37.23779],
          [48.260555, 37.238069],
          [48.259722, 37.23779],
          [48.258055, 37.23779],
          [48.257499, 37.237511],
          [48.256945, 37.23779],
          [48.256112, 37.237511],
          [48.249722, 37.237511],
          [48.248889, 37.23779],
          [48.247222, 37.23779],
          [48.246389, 37.238069],
          [48.241666, 37.238068],
          [48.240555, 37.238069],
          [48.239999, 37.23779],
          [48.237776, 37.23779],
          [48.236942, 37.237511],
          [48.235832, 37.237511],
          [48.234999, 37.237232],
          [48.233609, 37.237232],
          [48.225276, 37.235009],
          [48.219719, 37.232787],
          [48.213052, 37.231122],
          [48.207499, 37.229457],
          [48.206386, 37.229457],
          [48.205553, 37.229178],
          [48.204442, 37.229178],
          [48.202776, 37.22862],
          [48.201386, 37.228621],
          [48.191386, 37.226956],
          [48.187219, 37.226956],
          [48.186386, 37.226677],
          [48.18583, 37.226956],
          [48.184996, 37.226677],
          [48.177496, 37.226677],
          [48.176386, 37.226398],
          [48.173606, 37.226398],
          [48.173053, 37.226119],
          [48.16583, 37.225012],
          [48.16444, 37.224733],
          [48.161383, 37.223896],
          [48.160273, 37.223896],
          [48.15944, 37.223617],
          [48.150273, 37.221125],
          [48.140273, 37.213341],
          [48.13194, 37.205567],
          [48.131384, 37.204451],
          [48.116937, 37.188343],
          [48.116384, 37.187237],
          [48.109437, 37.171678],
          [48.105827, 37.16501],
          [48.105547, 37.164731],
          [48.099714, 37.15584],
          [48.096661, 37.154175],
          [48.095547, 37.154175],
          [48.095547, 37.153896],
          [48.094714, 37.153896],
          [48.094161, 37.154175],
          [48.093327, 37.153896],
          [48.089994, 37.153896],
          [48.089438, 37.153617],
          [48.083604, 37.151952],
          [48.082491, 37.151115],
          [48.081104, 37.150845],
          [48.064434, 37.15251],
          [48.056101, 37.15251],
          [48.055548, 37.152231],
          [48.053601, 37.152231],
          [48.053048, 37.151952],
          [48.051934, 37.151952],
          [48.050825, 37.151673],
          [48.049435, 37.151673],
          [48.047211, 37.151115],
          [48.044991, 37.150845],
          [48.044158, 37.150566],
          [48.041101, 37.14945],
          [48.033601, 37.145283],
          [48.022768, 37.131676],
          [47.999333, 37.112217],
          [47.991099, 37.130011],
          [47.988322, 37.13695],
          [47.988042, 37.138066],
          [47.987765, 37.138345],
          [47.983322, 37.147785],
          [47.975542, 37.153346],
          [47.972485, 37.155281],
          [47.948319, 37.165288],
          [47.947209, 37.165288],
          [47.946929, 37.165567],
          [47.938596, 37.166953],
          [47.938042, 37.166674],
          [47.932763, 37.166674],
          [47.932206, 37.166953],
          [47.930262, 37.166953],
          [47.929986, 37.167232],
          [47.928873, 37.167232],
          [47.928596, 37.167511],
          [47.927486, 37.167511],
          [47.927206, 37.16779],
          [47.905816, 37.172784],
          [47.904429, 37.172784],
          [47.902206, 37.173342],
          [47.900539, 37.173342],
          [47.898039, 37.1739],
          [47.896093, 37.1739],
          [47.894983, 37.174179],
          [47.892206, 37.174449],
          [47.889149, 37.174449],
          [47.88804, 37.174728],
          [47.87887, 37.174728],
          [47.876926, 37.174728],
          [47.871926, 37.174728],
          [47.87137, 37.174449],
          [47.86415, 37.173621],
          [47.860813, 37.173621],
          [47.860537, 37.173342],
          [47.859146, 37.173342],
          [47.85887, 37.173063],
          [47.856927, 37.173063],
          [47.85498, 37.172514],
          [47.85387, 37.172514],
          [47.85248, 37.171956],
          [47.850814, 37.171956],
          [47.84776, 37.17084],
          [47.84748, 37.170561],
          [47.847203, 37.170282],
          [47.846093, 37.170282],
          [47.844147, 37.169454],
          [47.843313, 37.169454],
          [47.843313, 37.169175],
          [47.84248, 37.169175],
          [47.8072, 37.151393],
          [47.805534, 37.150565],
          [47.793591, 37.144455],
          [47.793311, 37.143897],
          [47.792758, 37.143618],
          [47.778868, 37.132225],
          [47.778587, 37.131676],
          [47.778311, 37.13056],
          [47.778034, 37.123621],
          [47.778587, 37.119724],
          [47.777754, 37.110005],
          [47.775811, 37.091951],
          [47.775534, 37.090844],
          [47.773034, 37.081394],
          [47.772201, 37.080287],
          [47.768311, 37.077227],
          [47.766088, 37.076399],
          [47.764978, 37.076399],
          [47.764421, 37.07612],
          [47.759698, 37.07612],
          [47.759145, 37.076399],
          [47.742198, 37.078613],
          [47.740255, 37.07945],
          [47.737755, 37.080287],
          [47.729418, 37.084175],
          [47.726641, 37.086947],
          [47.726641, 37.087505],
          [47.721642, 37.093058],
          [47.719975, 37.093616],
          [47.717752, 37.093616],
          [47.706085, 37.087784],
          [47.705252, 37.087784],
          [47.704975, 37.087505],
          [47.703862, 37.087505],
          [47.703309, 37.087226],
          [47.701919, 37.087226],
          [47.697195, 37.086398],
          [47.696642, 37.086398],
          [47.696085, 37.086119],
          [47.693862, 37.086119],
          [47.693309, 37.086398],
          [47.687472, 37.086398],
          [47.687472, 37.086677],
          [47.685252, 37.086947],
          [47.68477, 37.087188],
          [47.684419, 37.087226],
          [47.679139, 37.089449],
          [47.678862, 37.090286],
          [47.668305, 37.098899],
          [47.666362, 37.099169],
          [47.663306, 37.099169],
          [47.663029, 37.099448],
          [47.658862, 37.098341],
          [47.649139, 37.087784],
          [47.648859, 37.087505],
          [47.637473, 37.081673],
          [47.633859, 37.079171],
          [47.633583, 37.078892],
          [47.633026, 37.078613],
          [47.633026, 37.078064],
          [47.632193, 37.076669],
          [47.63025, 37.073618],
          [47.62997, 37.070279],
          [47.63025, 37.067228],
          [47.630526, 37.066391],
          [47.631359, 37.064177],
          [47.62997, 37.058614],
          [47.624693, 37.056112],
          [47.61747, 37.055005],
          [47.611636, 37.050001],
          [47.610247, 37.048615],
          [47.604693, 37.041396],
          [47.59886, 37.037508],
          [47.596356, 37.037508],
          [47.59469, 37.04028],
          [47.594413, 37.041954],
          [47.594136, 37.043062],
          [47.593857, 37.044448],
          [47.59358, 37.044727],
          [47.59358, 37.045285],
          [47.59219, 37.047778],
          [47.584414, 37.052782],
          [47.582747, 37.05334],
          [47.58219, 37.05334],
          [47.58219, 37.053619],
          [47.581637, 37.053619],
          [47.58108, 37.053898],
          [47.575523, 37.055563],
          [47.56969, 37.059172],
          [47.567747, 37.059172],
          [47.56719, 37.058893],
          [47.5658, 37.058893],
          [47.563857, 37.058056],
          [47.559967, 37.057228],
          [47.558577, 37.057228],
          [47.557743, 37.057507],
          [47.556634, 37.057507],
          [47.549967, 37.060837],
          [47.5483, 37.060837],
          [47.547744, 37.060558],
          [47.5433, 37.060558],
          [47.542467, 37.060279],
          [47.541357, 37.060279],
          [47.538854, 37.060837],
          [47.537187, 37.061674],
          [47.53691, 37.062232],
          [47.529411, 37.068334],
          [47.524134, 37.067227],
          [47.517464, 37.061674],
          [47.515521, 37.056949],
          [47.515244, 37.055563],
          [47.514964, 37.050838],
          [47.514964, 37.039731],
          [47.515244, 37.036671],
          [47.512744, 37.021397],
          [47.509964, 37.017229],
          [47.494131, 37.002504],
          [47.493575, 37.001946],
          [47.493575, 37.001667],
          [47.492464, 37.000001],
          [47.487465, 36.996671],
          [47.486908, 36.996671],
          [47.458575, 36.98334],
          [47.450795, 36.977228],
          [47.450518, 36.976949],
          [47.438295, 36.963339],
          [47.437462, 36.96306],
          [47.429129, 36.957776],
          [47.428572, 36.957506],
          [47.428185, 36.957342],
          [47.412182, 36.950556],
          [47.411349, 36.950556],
          [47.409406, 36.949728],
          [47.408572, 36.949728],
          [47.408016, 36.949449],
          [47.407182, 36.949449],
          [47.401073, 36.947496],
          [47.395516, 36.946668],
          [47.372736, 36.937225],
          [47.37135, 36.936666],
          [47.318847, 36.905555],
          [47.3169, 36.904448],
          [47.302733, 36.897228],
          [47.300511, 36.895273],
          [47.299957, 36.894716],
          [47.295511, 36.883337],
          [47.291068, 36.878062],
          [47.269398, 36.87139],
          [47.257731, 36.866385],
          [47.256897, 36.865827],
          [47.243288, 36.84611],
          [47.241064, 36.839169],
          [47.240508, 36.838332],
          [47.226341, 36.827501],
          [47.223285, 36.825277],
          [47.209118, 36.811943],
          [47.198285, 36.799437],
          [47.190785, 36.795557],
          [47.182172, 36.792495],
          [47.181062, 36.792495],
          [47.179395, 36.792216],
          [47.178839, 36.792216],
          [47.178562, 36.791937],
          [47.174115, 36.791667],
        ],
      ],
    },
  },
};
