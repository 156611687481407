import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import NotFound from "components/NotFound/NotFound";

const Dashboard = () => {
  return (
    <div>
      <Breadcrumb beforePage="برگشت" curentPage="داشبورد" />

      <NotFound />
    </div>
  );
};
export default Dashboard;
