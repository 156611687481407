import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentHub } from "redux/CurrentHub/CurrentHubReducer";
import { RootState } from "redux/store/Store";
import { getUserInfo } from "redux/userInfo/userInfoReducer";
import { filterDataAPI } from "services/CRUDServices";
import { filterUrls } from "services/api.enums";

export default function useSetPermissionRoutesDispatches() {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.authenticate);
  const isEmptyStorage = localStorage.getItem("currenthub");
  useEffect(() => {
    if (token && !isEmptyStorage) {
      filterDataAPI(
        filterUrls.fetchPermissionsByUserName,
        {
          in: "",
        },
        true,
        false
      ).then((res) => {
        const perms = res.data.permissionArray
          .map(({ permission }) => permission)
          .flat(1);
        localStorage.setItem("currenthub", JSON.stringify(res.data.currenthub));
        localStorage.setItem("hublist", JSON.stringify(res.data.hublist));
        dispatch(getUserInfo({ ...res.data, perms }));
        dispatch(setCurrentHub(res.data.currenthub));
      });
    }
  }, [dispatch, token]);
  return {};
}
