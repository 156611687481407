import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import AddressCorrectionForm, {
  addressModalType,
} from "pages/address-correction/views/AddressCorrectionForm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionChangeView } from "redux/optime/optime";
import { addEditDataAPI, getAPI } from "services/CRUDServices";
import * as turf from "@turf/turf";
import { addEditUrls, getUrls } from "services/api.enums";
import { useParams } from "react-router-dom";
import { checkConsignmentType, convertResponse } from "pages/optimePanel/utils";

import { ConsignmentHeader } from "pages/optimePanel/components/consignment-card/header";
import { ConsignmentCardBody } from "pages/optimePanel/components/consignment-card/card-body";
import { Confirm } from "pages/optimePanel/components/confirm-modal";
export default function PudoConsignment({
  consignments,
  optimeData,
  setOptimeData,
}) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { view } = useSelector((state: any) => state.optimeModal);
  const [addressModal, setAddressModal] = useState<addressModalType>({
    isOpen: false,
    data: undefined,
    isDifficult: false,
    isCloseAfterSave: true,
  });
  const [consignmentInfo, setConsignmentInfo] = useState({
    consignmentId: "",
    planingId: "",
  });
  const [polygontoAdd, setPolygonToAdd] = useState<any>();
  const [addressState, setAddressState] = useState<any>();
  const [inittilaAddress, setInitialAddress] = useState();
  const [add, setAdd] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  // const {openDelete, addressState, add, polygontoAdd,setOpenDelete,checkAddress}=useAcceptChange()

  function updateAddress(addressData) {
    const { latitude, longtitude } = addressData;
    optimeData.polygons.forEach((element) => {
      const polygon = turf.polygon([element.polygonCordinate]);
      if (turf.booleanPointInPolygon([latitude, longtitude], polygon)) {
        setOpenDelete(true);

        setAddressState(addressData);
        // acceptChange(addressData);
        setAdd(true);
        setPolygonToAdd(element);
        throw new Error("");
      }
    });
    optimeData.polygons.forEach((element) => {
      const polygon = turf.polygon([element.polygonCordinate]);
      if (!turf.booleanPointInPolygon([latitude, longtitude], polygon)) {
        setOpenDelete(true);
        setAddressState(addressData);
        // acceptChange(addressData);
        setAdd(false);
        setPolygonToAdd("");
        throw new Error("");
      }
    });
    acceptChange(addressData);
  }

  function acceptChange(address) {
    const finalAddress = address
      ? { ...address, address: inittilaAddress }
      : { ...addressState, address: inittilaAddress };
    addEditDataAPI(
      addEditUrls.updateAddressInPlanning +
        "/" +
        id +
        "/" +
        consignmentInfo.consignmentId,
      "put",
      { ...finalAddress }
    ).then(() => {
      getAPI(getUrls.planingConsignment + id).then((res) => {
        const convert = convertResponse(res);
        setOptimeData(convert);
        setAddressModal({ ...addressModal, isOpen: false, data: undefined });
      });
    });

    if (!address && add) {
      addToPolygon();
    }
    if (!address && !add) {
      deleteFromPolygon();
    }

    setOpenDelete(false);
  }

  function addToPolygon() {
    addEditDataAPI(addEditUrls.addPinToPolygon, "post", {
      pudoId: consignmentInfo.planingId,
      mdlVehicleId: polygontoAdd?.pudoMdlVehicle?.[0]?.vehicleId,
      pudoVehicleId: polygontoAdd?.pudoVehicle?.[0]?.vehicleId,
      polygoneId: polygontoAdd.polygonId,
    });
  }

  function deleteFromPolygon() {
    addEditDataAPI(addEditUrls.addPinToPolygon, "post", {
      pudoId: consignmentInfo.planingId,
      mdlVehicleId: null,
      pudoVehicleId: null,
      polygoneId: null,
    });
  }

  useEffect(() => {
    if (view.lat && view.lng) {
      dispatch(ActionChangeView({ lat: null, lng: null, zoom: 11 }));
    }
  }, [view]);

  return (
    <>
      {consignments?.map((consignment) => (
        <Accordion
          open={open === consignment.consignmentId}
          icon={
            <>
              <ConsignmentHeader
                setOptimeData={setOptimeData}
                item={consignment}
                setInitialAddress={setInitialAddress}
                setConsignmentInfo={setConsignmentInfo}
                setAddressModal={setAddressModal}
                customClass="!inline-flex "
                hasDelete={checkConsignmentType(consignment?.consignmentType)}
              />
            </>
          }
          //   animate={CUSTOM_ANIMATION}
          className={`my-6 rounded-lg border  border-blue-gray-100 px-2 z-[0]  overflow-hidden  pb-2 `}
        >
          <AccordionHeader
            className={`border-b-0 transition-colors   flex  w-full `}
            onClick={() => handleOpen(consignment.consignmentId)}
          >
            <span className="text-white text-sm ">{consignment.cprNumber}</span>
          </AccordionHeader>
          <span className="text-white text-sm">{consignment.address}</span>
          <AccordionBody className=" ">
            <ConsignmentCardBody
              item={consignment}
              hasAddress={false}
              hasHr={false}
              hasConsignment={false}
            />
          </AccordionBody>
        </Accordion>
      ))}

      <AddressCorrectionForm
        addressModal={addressModal}
        setIsOpen={setAddressModal}
        handleGetTableData={updateAddress}
        ignoreSubmit
      />

      <Confirm
        open={openDelete}
        setOpen={setOpenDelete}
        title={
          add
            ? `مرسوله به راننده ${
                polygontoAdd && polygontoAdd?.pudoVehicle?.[0]?.driver?.text
              } تخصیص داده شود؟`
            : "مرسوله از منطقه تخصیص خارج شود؟"
        }
        handleClick={acceptChange}
      />
    </>
  );
}
