import { lazy } from "react";

const ConsignmentManageOperation = lazy(
  () => import("pages/ConsignmentManageOperation/ConsignmentManageOperation")
);
const CRMManagment = lazy(() => import("pages/CRMManagment/CRMManagment"));
const SaleChannel = lazy(() => import("pages/SalesChannel/index"));
const AddressCorrection = lazy(
  () => import("pages/address-correction/AddressCorrection")
);
export const childs: Array<ISideBarChild> = [
  {
    code: "",
    to: "/consignment-manage-operation",
    component: <ConsignmentManageOperation />,
    label: "مدیریت مرسوله",
    permissions: "view_crm_consignment_management",
  },
  {
    code: "",
    to: "/CRM-managment/consignment",
    component: <CRMManagment />,
    label: "گروه بندی مشتریان",
    permissions: "view_customer_group",
  },
  {
    code: "",
    to: "/CRM-managment/salesChannel",
    component: <SaleChannel />,
    label: "کانال فروش",
    permissions: "view_sales_channel",
  },
  {
    code: "",
    to: "/CRM-managment/address-correction",
    component: <AddressCorrection />,
    label: "اصلاح آدرس ",
    permissions: "view_edite_address",
  },
];
