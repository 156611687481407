import  { AxiosRequestConfig, AxiosResponse } from "axios";
import requestInstance from "./MainService";

const request = {
  get: <T>(
    endpoint: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    return requestInstance.get(endpoint, options);
  },
  post: (
    endpoint: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return requestInstance.post(endpoint, data, options);
  },
  put: (
    endpoint: string,
    data: unknown,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return requestInstance.put(endpoint, data, options);
  },
  patch: (
    endpoint: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return requestInstance.patch(endpoint, Object.assign({}, data), options);
  },
  delete: (
    endpoint: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return requestInstance.delete(endpoint, {
      data: data,
      headers: options,
    });
  },
  options: (
    endpoint: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return requestInstance.options(endpoint, options);
  },

  head: (
    endpoint: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    return requestInstance.head(endpoint, options);
  },
};

export default request;
