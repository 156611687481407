import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as turf from "@turf/turf";
import AutocompleteInput from "../../global/Autocomplete/AutocompleteInput";
import {
  fetchAddressLocationNew,
  fetchSearchAddressNew,
  getAPI,
} from "../../services/CRUDServices";
import DragableMarker, { positionProps } from "../map/DragableMarker";
import MapComponent from "../map/MapComponent";
import { getUrls } from "../../services/api.enums";
import { sampleDatajson } from "../../models/Location";
import ChangeFlyView from "components/map/ChangeFlyView";

interface AddressSearchMapProps {
  formik: any;
  wrapperClassName?: string;
  id: number | string;
  notSearch?: boolean;
  notChangeAddress?: boolean;
  isDefaultLoc?: boolean;
}

const AddressSearchMap = ({
  formik,
  wrapperClassName,
  id,
  notSearch,
  notChangeAddress,
  isDefaultLoc,
}: AddressSearchMapProps) => {
  const [ProposalAddressOption, setProposalAddressOption] = useState([]);
  const [currentPrivince, setcurrentPrivince] = useState();
  const [currentCityCode, setCurrentCityCode] = useState();
  const [zoom, setZoom] = useState(6);

  const { values, errors, touched, setFieldValue } = formik;

  const handleSearchAddress = async (text: string) => {
    try {
      const res = await fetchAddressLocationNew({
        text: text,
        district: currentCityCode,
      });
      setProposalAddressOption(res?.data?.results);
    } catch (error) {}
  };

  const handleGetAddressByLocation = useCallback(
    async ({ lat, lng }: positionProps) => {
      setFieldValue("lat", lat);
      setFieldValue("lng", lng);
      var pt = turf.point([lng, lat]);

      var poly = turf.polygon([
        currentPrivince
          ? sampleDatajson[currentPrivince].geometry.coordinates[0]
          : [],
      ]);
      const isinside = turf.booleanPointInPolygon(pt, poly);

      if (!isinside) {
        toast.warning(
          "مکان انتخاب شده باید داخل محدوده استان انتخاب شده باشد!"
        );
        return;
      }
      setFieldValue("lat", lat);
      setFieldValue("lng", lng);
      try {
        if (!notChangeAddress) {
          const res = await fetchSearchAddressNew({ lat, long: lng });
          setFieldValue("address", res?.data?.address);
        }
      } catch (error) {}
    },
    [currentPrivince, setFieldValue]
  );

  const fetchPrivanceDataHandler = useCallback(async () => {
    try {
      const res = await getAPI(getUrls.countryDevisionfindById + `/${id}`);
      setcurrentPrivince(res.data?.parent?.code);
      setCurrentCityCode(res.data?.parent?.shahrCode);
      if (isDefaultLoc) {
        setFieldValue("lat", res?.data?.latitude);
        setFieldValue("lng", res?.data?.longtitude);
      }
    } catch (error) {}
  }, [id]);

  const handleSelectTheLocation = (val: any) => {
    const found: any = ProposalAddressOption.find(
      (element: any) => element.description === val
    );

    var pt = turf.point([
      found?.geo_location.center.lng,
      found?.geo_location.center.lat,
    ]);

    if (currentPrivince && currentPrivince in sampleDatajson) {
      var poly = turf.polygon([
        currentPrivince
          ? sampleDatajson[currentPrivince].geometry?.coordinates[0]
          : [],
      ]);
      const isinside = turf.booleanPointInPolygon(pt, poly);
      if (!isinside) {
        toast.warning(
          "مکان انتخاب شده باید داخل محدوده استان انتخاب شده باشد!"
        );
        return;
      }
    }
    setFieldValue("address", val);
    if (found) {
      setFieldValue("lat", found?.geo_location.center.lat);
      setFieldValue("lng", found?.geo_location.center.lng);
    }
  };

  useEffect(() => {
    fetchPrivanceDataHandler();
  }, [fetchPrivanceDataHandler]);

  // useEffect(() => {
  //     handleSearchAddress(debouncedValue);
  // }, [address, debouncedValue,setProposalAddressOption]);

  useEffect(() => {
    if (!values.lat && !values.lng) {
      setZoom(17);
    }
  }, [values.lat]);

  useEffect(() => {
    if (values.lat && values.lng) {
      setZoom(17);
    }
  }, []);

  return (
    <div className={`${wrapperClassName || "w-[50rem] h-[38rem]"} relative `}>
      {!notSearch && (
        <AutocompleteInput
          placeholder="جستجوی آدرس"
          error={touched.address && errors.address}
          wrapperClassName="w-[70%] absolute top-5 left-0 right-0 z-50 m-auto"
          items={ProposalAddressOption?.map((item: any) => {
            return { label: item.description };
          })}
          value={values.address}
          onChange={(e) => {
            setFieldValue("address", e.target.value);
            handleSearchAddress(e.target.value);
          }}
          onSelect={handleSelectTheLocation}
        />
      )}
      <MapComponent zoom={zoom} setZoom={setZoom}>
        <DragableMarker
          canOnClickMove
          position={{ lat: Number(values.lat), lng: Number(values.lng) }}
          setNewPositionAction={handleGetAddressByLocation}
        />
        {values.lat && values.lng && (
          <ChangeFlyView
            center={[Number(values.lat), Number(values.lng)]}
            zoom={zoom}
          />
        )}
      </MapComponent>
    </div>
  );
};

export default AddressSearchMap;
