import { memo } from "react";
import PixiOverlay from "react-leaflet-pixi-overlay";
import { useDispatch } from "react-redux";
import { ActionSelectUnAssignPin, changeSelectTab } from "redux/optime/optime";
import {
  checkConsignmentTypeColor,
  checkConsignmentTypeText
} from "../utils";

const RenderUnAssignPins = ({ pins }) => {
  const dispatch = useDispatch();
  const convertMarker = pins
    ?.filter((pin) => pin.latitude !== null && pin.longitude !== null)
    ?.map((pin) => {
      return {
        id: pin.consignmentId,
        tooltip: checkConsignmentTypeText(pin),
        iconColor: checkConsignmentTypeColor(pin),
        position: [pin.latitude, pin.longitude],
        onClick: () => {
          dispatch(ActionSelectUnAssignPin(pin));
          dispatch(changeSelectTab(3));
        },
      };
    });

  return (
    <PixiOverlay markers={(convertMarker && convertMarker?.flat(1)) || []} />
  );
};

export default memo(RenderUnAssignPins);
