import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store/Store";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-tailwind/react";
import { Suspense } from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { QueryClient, QueryClientProvider } from "react-query";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, "/");
};

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  scope: "profile",
  disablePKCE: true,
  client_authentication: "client_secret_basic",
  loadUserInfo: true,
  revokeTokensOnSignout: true,
  automaticSilentRenew: true,
  monitorSession: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, "/");
  },
};
//to main

export const userManagerMain = new UserManager(oidcConfig);
const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  // <React.StrictMode>
  <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense>
              <ToastContainer
                rtl={true}
                closeButton={false}
                autoClose={3000}
                toastClassName="rounded-md p-4 font-medium"
              />

              <ThemeProvider>
                <App />
              </ThemeProvider>
            </Suspense>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </QueryClientProvider>
  </AuthProvider>
  //</React.StrictMode>
);

reportWebVitals();
