import { useFormik } from "formik";
import InputText from "../../../global/InputText/InputText";
import { RecoveryValidation } from "global/Validation/Validation";
import SimpleButton from "../../../global/SimpleButton/SimpleButton";

interface ThirdStepRecoveryProps {
  loading: boolean;
  handleChangePass: any;
}

const ThirdStepRecovery = ({ handleChangePass, loading }: ThirdStepRecoveryProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: RecoveryValidation,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (values) => {
      handleChangePass(values);
    },
  });
  const { values, errors, touched, handleChange, handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit} className="mt-2 flex flex-col gap-8">
      <p className="text-base">رمز عبور جدید را وارد نمایید.</p>
      <InputText
        wrapperClassName="w-full"
        password
        important
        label="گذر واژه "
        values={values.password}
        name="password"
        handleChange={handleChange}
        error={touched.password && errors.password}
      />
      <InputText
        wrapperClassName="w-full"
        password
        important
        label="تایید گذرواژه"
        values={values.confirmPassword}
        name="confirmPassword"
        handleChange={handleChange}
        error={touched.confirmPassword && errors.confirmPassword}
      />
      <SimpleButton
        text="تغییر رمز عبور"
        className="full-tomato-btn w-full"
        loading={loading}
        type="submit"
      />
    </form>
  );
};

export default ThirdStepRecovery;
