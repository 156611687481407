import { createSlice,createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { getUrls } from "../../services/api.enums";
import { filterTableDataAPI, getAPI } from "../../services/CRUDServices";
import { ErrorAlert } from "global/alert/Alert";


export const VehicleMoveData: AsyncThunk<{}, {}, {}>=createAsyncThunk('VehicleMove',async(body:any)=>{

    try{
        const vehicleApi=await filterTableDataAPI(getUrls.vehicleMove,body?.pageNumber,{
            licenseNo: body?.licenseNo ? body?.licenseNo : null,
            licenseDate: null,
            selectSourceHub:body?.hub ? body?.hub : null,
            selectDestinationHub: null,
            validateFrom: null,
            validateTo: null,
            selectVehicle: body.selectVehicle ? {id:body.selectVehicle?.id,text:null} : null 
            })
             
            let hubUrl = "core-api/hub/findById/";
        
             let ids=vehicleApi.data.payload.content 
          
           
           const fetchHub = async (item: any) =>
             await getAPI(`${hubUrl}`+ item.selectSourceHub.id, true).then((res: any) => {
               
                return {
                     ...item,
                    selectSourceHub2:{
                        id:res.data.id, 
                        text: res.data.name
                      }
                    
                    };
               })
               .catch((error) => {
                ErrorAlert(error?.response?.data?.errors?.message)
               });
        
        
        
               const fetchSecondHub = async (item: any) =>
               await getAPI(`${hubUrl}`+ item.selectDestinationHub.id, true).then((res: any) => {
                 
                  return {
                       ...item,
                       selectDestinationHub2:{
                          id:res.data.id, 
                          text: res.data.name
                        }
                      
                      };
                 })
                 .catch((error) => {
                  ErrorAlert(error?.response?.data?.errors?.message)
                 });
        
               let hubData: any = [];
               let desHub:any=[];
               hubData = await Promise.all(
                ids.filter((hub: any) => hub.id !== null)
                  .map((item: any) => fetchHub(item))
              ).then((values) => values.filter(Boolean));
        
              desHub = await Promise.all(
                ids.filter((hub: any) => hub.id !== null)
                  .map((item: any) => fetchSecondHub(item))
              ).then((values) => values.filter(Boolean));
        
         
        
              let result = [] as any;
        
              result = [
                ...[...ids,...hubData,...desHub].reduce((acc, curr) => acc.set(curr.id, { ...acc.get(curr.id), ...curr }),
                    new Map()
                  )
                  .values(),
              ];
              vehicleApi.data.payload.content = result;
            
            
            return vehicleApi.data?.payload
    }catch(error){
      ErrorAlert(error?.response?.data?.errors?.message);
    }



  
})

const initialState={
    VehicleMoveLists: [],
     fetchpost: false,
     errorMessage: "",
 }

 
const VehicleMoveSlice=createSlice({
    name:"VehicleMove",
    initialState,
    reducers:{
        clearVehicle:(state)=>{
            state.VehicleMoveLists=[]
        },
    
    },
    extraReducers:{
        [VehicleMoveData.fulfilled as any]: (state, action) => { 
            state.VehicleMoveLists = action.payload;
            state.fetchpost = false;
          },
          [VehicleMoveData.pending as any]: (state) => {
            state.fetchpost = true;
          },
          [VehicleMoveData.rejected as any]: (state) => {
            state.fetchpost = false;
            state.errorMessage = "wrong";
          },
    }
})

export const {clearVehicle}=VehicleMoveSlice.actions
export default VehicleMoveSlice.reducer